import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import NumberFormat from 'react-number-format';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import {
  module18Dynamicbargraph,
} from '../../calculations/existing-service';

import * as existingGraphData from '../../graph-services/existing-show-me';
import styles from './styles';
import alertIcon from '../../assets/img/alert_icon.png';
import PwiSave from '../../components/pwi-save/pwi-save';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/pwi-existing/existing_assets_img_1.jpg';
import sliderImageThree from '../../assets/img/pwi-existing/existing_assets_img_2.jpg';
import sliderImageFour from '../../assets/img/pwi-existing/existing_assets_img_3.jpg';
import sliderImageFive from '../../assets/img/pwi-existing/existing_assets_img_4.jpg';
import accruedInterest from '../../assets/img/pwi-existing/Accrued_Interest.png';
import capitalizedInterestGraph from '../../assets/img/pwi-existing/Capitalized_Interest_Graph.PNG';
import capitalizedInterestTable from '../../assets/img/pwi-existing/Capitalized_Interest_Table.png';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import * as moduleServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import arrowDown from '../../assets/img/pwi-existing/arrow_down.png';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import SimulatorPanel from '../../components/documents/simulator-panel';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import { defaultValues } from '../../calculations/default-module-values';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import WalkThruStudent from './wallk-thru/walkThruStudent';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

HCExporting(Highcharts);

const ExistingAssetsLiabilitiesSheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const { typeOfEducation } = defaultValues;

  const { paymentPlanTypes } = defaultValues;

  const monthRange = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ];

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedExistingAssetsLiabilitiesValues, setUpdatedExistingAssetsLiabilitiesValues] = useState(defaultValues.module18);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imagePopup, setImagePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = useState(true);
  const [yearRange, setYearRange] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState('');
  const [showMeData, setShowMeData] = React.useState({});
  const [openInputPopupStatus, setOpenInputPopupStatus] = React.useState({ creditcard_interest_rate: false, existing_car_interest_rate: false, existig_home_mortgage_interest_rate: false });
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);

  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);

  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(2);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module18' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedExistingAssetsLiabilitiesValues(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedExistingAssetsLiabilitiesValues(defaultValues.module18);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(() => {
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module18' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }
  }, [loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module18') {
      dropdownvalues.savedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 });
    }
  }, [myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(existingGraphData.existingShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top' });
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleImagePopup = (url) => {
    setImagePopup(!imagePopup);
    setImageURL(url);
  };

  const handleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedExistingAssetsLiabilitiesValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if ((field === 'fed_sub_months' || field === 'existig_home_mortgage_months_to_maturity' || field === 'existing_car_months_to_maturity' || field === 'ideal_repayment_schedule' || field === 'private_months' || field === 'fed_unsub_months' || field === 'fed_sub_term' || field === 'fed_unsub_term' || field === 'private_term') && valuesUpdated[field] > 360) {
        valuesUpdated[field] = 360;
      } else if ((field === 'fed_sub_interest_rate' || field === 'existing_car_interest_rate' || field === 'existing_home_selling_costs' || field === 'existig_home_mortgage_interest_rate' || field === 'existing_home_annual_appreciation' || field === 'creditcard_interest_rate' || field === 'fed_unsub_interest_rate' || field === 'private_interest_rate') && valuesUpdated[field] > 100) {
        valuesUpdated[field] = 100;
      }
    } else if (fieldType === 'input' || fieldType === 'radio') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      valuesUpdated[field] = e.value;
    }
    if (field !== 'existing_total_short_term_investments' || field !== 'existing_total_long_term_investments') {
      setUpdatedExistingAssetsLiabilitiesValues(valuesUpdated);
      moduleServices.setModule18Data(valuesUpdated);
    } else {
      if (field === 'existing_total_long_term_investments') {
        let v = { ...valuesUpdated };
        v.long_term_managed_accounts = 0
        v.long_term_stocks_and_bonds = 0
        v.long_term_etfs = 0
        v.long_term_mutual_funds = 0
        v.long_term_reits = 0
        v.long_term_other = valuesUpdated[field];
        setUpdatedExistingAssetsLiabilitiesValues(v);
        moduleServices.setModule18Data(v);
      } else {
        let vl = { ...valuesUpdated };
        vl.short_term_traditional_savings_account = 0
        vl.short_term_high_yield_savings_account = 0
        vl.short_term_certificates_of_deposit = 0
        vl.short_term_money_market_funds = 0
        vl.short_term_other = valuesUpdated[field];
        setUpdatedExistingAssetsLiabilitiesValues(vl);
        moduleServices.setModule18Data(vl);
      }
    }
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
    const valuesUpdated = { ...updatedExistingAssetsLiabilitiesValues };
    if (field === 'existig_home_mortgage_interest_rate' || field === 'creditcard_interest_rate' || field === 'existing_car_interest_rate') {
      let text = '';
      let popmin = 0;
      let popmax = 0;
      if (openInputPopupStatus[field] === false) {
        const inputPopupStatus = { ...openInputPopupStatus };
        inputPopupStatus[field] = true;
        setOpenInputPopupStatus(inputPopupStatus);
        if (field === 'existig_home_mortgage_interest_rate') {
          popmin = 3;
          popmax = 8;
          text = 'Mortgage loan interest rates typically vary from 3 - 8%. Make sure you can support an interest rate outside these boundaries with research.';
        } else if (field === 'creditcard_interest_rate') {
          popmin = 10;
          popmax = 25;
          text = 'Credit card interest rates typically vary from 10 - 25%. Make sure you can support an interest rate outside these boundaries with research.';
        } else if (field === 'existing_car_interest_rate') {
          popmin = 2;
          popmax = 12;
          text = 'Car loan interest rates typically vary from 2 - 12%. Make sure you can support an interest rate outside these boundaries with research.';
        }
      }
      const tempValue = updatedExistingAssetsLiabilitiesValues[field];
      if (tempValue > 0 && text !== '' && (tempValue < popmin || tempValue > popmax)) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.redoPopup}>
              <div className={classes.redoPopupNew}>
                <h3>Important Tip</h3>
                <div className={classes.NoFiDiv}>
                  <p className={classes.zeroLeftPadding}>{text}</p>
                </div>
              </div>
              <div className={classes.buttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button className={classes.hideBtn} type="button" onClick={onClose}>OK</button>
              </div>
            </div>
          ),
        });
      }
    }
    setUpdatedExistingAssetsLiabilitiesValues(valuesUpdated);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module18';
    objectParams.moduleNumber = 'module18Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module18";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module18', 'quick-save', activeScenarioData);
      finalObject['module18Data'] = pwiObject.getfilteredExistingObject(moduleServices.module18Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -280, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -250, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(7);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  };

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -160, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 300, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(7);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 13) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >

      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet} id="slide-three">
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 13 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classNames(classes.existingSlider, classes.existingSliderNew)}>
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Existing Assets & Liabilities
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your existing assets & liabilities details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonOrange)}>START WITH A WALK-THRU</span>

                            </Grid>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Existing Assets + Liabilities
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <Typography variant="h2" component="h2">
                                Enter Your Details
                              </Typography>
                              <ul>
                                <li>Develop a plan to effectively manage your existing assets and liabilities</li>
                                <li>Repay federal and private student loans on time or ahead of schedule</li>
                                <li>Analyze how different monthly payments get you out of credit card debt</li>
                                <li>Account for your existing car and auto loan</li>
                                <li>Manage your existing home&apos;s monthly expenses and mortgage repayment</li>
                                <li>See the net cash flow from selling your home and possible tax effects</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Existing Assets + Liabilities
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <Typography variant="h2" component="h2">
                                Credit Cards and Vehicles
                              </Typography>
                              <ul>
                                <li>Carrying a balance on your high interest rate credit card? Build a repayment plan and make sure you maintain a positive cash flow</li>
                                <li>Enter the value of your existing car</li>
                                <li>Account for your monthly auto-related expenses such as gas, insurance and maintenance.</li>
                              </ul>

                            </Grid>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Existing Assets + Liabilities
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <Typography variant="h2" component="h2">
                                Student Loans
                              </Typography>
                              <ul>
                                <li>See what your monthly payment should be to make sure you repay your loan on time</li>
                                <li>Tweak your student loan repayment schedule and instantly see how much you could save</li>
                                <li>What if you refinanced your student loans and invested the annual savings? Check out the refinancing tool in the Show Me section.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageFour} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Existing Assets + Liabilities
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <Typography variant="h2" component="h2">
                                Are You a Homeowner?
                              </Typography>
                              <ul>
                                <li>Use Inkwiry to build a plan to manage your home finances</li>
                                <li>View your mortgage repayment schedule</li>
                                <li>Understand how taxes come into play when you go to sell your home.</li>
                              </ul>

                            </Grid>
                            <Grid item sm={6} className={classes.noBottomPadding}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageFive} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module18" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={0}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classNames(classes.leftIconPuzzle, classes.existingLeftIcon)}>
                    <Link to="/plan-with-inkwiry/existing" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>

                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.existingassetsImg) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Existing Credit Card Debt</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Credit Card Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_credit_card_balance} onValueChange={(e) => updateValue(e, 'existing_credit_card_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="For example, if you have a $5,000 credit card balance that you want to repay, input $5,000. The FC assumes all expenses going forward are paid in cash. So, this balance will never increase and only be repaid like a standard loan.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Ideal Repayment Schedule (in Months)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.ideal_repayment_schedule} onValueChange={(e) => updateValue(e, 'ideal_repayment_schedule', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The amount of months that you would like to repay this credit card debt in. For example, if you carry a $5,000 balance and you'd like that gone a year from now, enter 12 months. See how different Repayment Schedules can affect Total Interest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.creditcard_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'creditcard_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='creditcard_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='creditcard_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('creditcard_interest_rate'); }}
                                            onBlur={() => { setFixedDecimal('creditcard_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="When you carry a credit card balance, you typically are charged a very high interest rate. You can find that interest rate through your credit card's mobile app or by calling your credit card company. Input that interest rate here."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.existingCreditCardMonthlyPayment() !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.existingCreditCardMonthlyPayment()} prefix={moduleServices.existingCreditCardMonthlyPayment() >= 0 ? '$' : '($'} suffix={moduleServices.existingCreditCardMonthlyPayment() < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCreditCardAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCreditCardAnnualPayment} prefix={moduleServices.module18Data.existingCreditCardAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCreditCardAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCreditCardTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCreditCardTotalInterest} prefix={moduleServices.module18Data.existingCreditCardTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCreditCardTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCreditCardTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCreditCardTotalPrincipalInterest} prefix={moduleServices.module18Data.existingCreditCardTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCreditCardTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Personal Loans</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Balanace</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.personal_loan_current_personal_balance} onValueChange={(e) => updateValue(e, 'personal_loan_current_personal_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="For example, if you have a $5,000 credit card balance that you want to repay, input $5,000. The FC assumes all expenses going forward are paid in cash. So, this balance will never increase and only be repaid like a standard loan.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months Remaining on Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.personal_loan_months_remaining_on_loan} onValueChange={(e) => updateValue(e, 'personal_loan_months_remaining_on_loan', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The amount of months that you would like to repay this credit card debt in. For example, if you carry a $5,000 balance and you'd like that gone a year from now, enter 12 months. See how different Repayment Schedules can affect Total Interest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            fixedDecimalScale
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.personal_loans_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'personal_loans_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='personal_loans_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='personal_loans_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('personal_loans_interest_rate'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you carry a credit card balance, you typically are charged a very high interest rate. You can find that interest rate through your credit card's mobile app or by calling your credit card company. Input that interest rate here.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.personalLoansMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.personalLoansMonthlyPayment} prefix={moduleServices.module18Data.personalLoansMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.personalLoansMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.personalLoansAnnualPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.personalLoansAnnualPaid} prefix={moduleServices.module18Data.personalLoansAnnualPaid >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.personalLoansAnnualPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.personalLoansTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.personalLoansTotalInterest} prefix={moduleServices.module18Data.personalLoansTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.personalLoansTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.personalLoansTotalRepaymentAmount !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.personalLoansTotalRepaymentAmount} prefix={moduleServices.module18Data.personalLoansTotalRepaymentAmount >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.personalLoansTotalRepaymentAmount < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {/* *************************** */}
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Existing Federal Subsidized Student Loan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Type of Higher Education</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_sub_type_of_higher_education', 'select'); }}
                                              options={typeOfEducation}
                                              value={_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.fed_sub_type_of_higher_education }) >= 0 ? typeOfEducation[_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.fed_sub_type_of_higher_education })] : typeOfEducation[0]}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What Higher Education did you attend that you took these loans for? It is important to know because your existing loans can impact how much loans you can take in the future.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Years Loans Were Taken</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_sub_years', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedExistingAssetsLiabilitiesValues.fed_sub_years, value: updatedExistingAssetsLiabilitiesValues.fed_sub_years }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many years have you taken loans? This number is important in determining your capacity to take Federal Loans in the future. Input the number of years you have been in Higher Education for.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months Until Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_sub_months} onValueChange={(e) => updateValue(e, 'fed_sub_months', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many months are remaining until your repayment of this loan begins? If you are already repaying this loan, input 0. If not, find out the exact number of months until repayment and input that here. The FC will then schedule out your repayment for you.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Loan Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_sub_current_balance} onValueChange={(e) => updateValue(e, 'fed_sub_current_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much loans do you have outstanding? Input your Current Loan balance to the exact penny.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan (in months) or Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_sub_term} onValueChange={(e) => updateValue(e, 'fed_sub_term', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you are not repaying your loan already, then input the # of months for repayment once you begin repaying your loan. If you have already begun repaying your loan, then input the # of months reamining until repayment ends.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.fed_sub_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'fed_sub_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='fed_sub_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_sub_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_sub_interest_rate'); }}
                                            onBlur={() => { setFixedDecimal('fed_sub_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The Interest Rate you pay on your loan is essential in determining your payback schedule. Input your interest rate here to the exact decimal point."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Beginning Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedSubBeginningBalance} prefix={moduleServices.module18Data.existingStudentLoansFedSubBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedSubMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedSubMonthlyPayment} prefix={moduleServices.module18Data.existingStudentLoansFedSubMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedSubMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                            {' '}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedSubAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedSubAnnualPayment} prefix={moduleServices.module18Data.existingStudentLoansFedSubAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedSubAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedSubTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedSubTotalInterest} prefix={moduleServices.module18Data.existingStudentLoansFedSubTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedSubTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedSubTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedSubTotalPrincipalInterest} prefix={moduleServices.module18Data.existingStudentLoansFedSubTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedSubTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Existing Federal Unsubsidized Student Loan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Type of Higher Education</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_unsub_type_of_higher_education', 'select'); }}
                                              options={typeOfEducation}
                                              value={_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.fed_unsub_type_of_higher_education }) >= 0 ? typeOfEducation[_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.fed_unsub_type_of_higher_education })] : typeOfEducation[0]}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What Higher Education did you attend that you took these loans for? It is important to know because your existing loans can impact how much loans you can take in the future.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Years Loans Were Taken</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_unsub_years', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedExistingAssetsLiabilitiesValues.fed_unsub_years, value: updatedExistingAssetsLiabilitiesValues.fed_unsub_years }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many years have you taken loans? This number is important in determining your capacity to take Federal Loans in the future. Input the number of years you have been in Higher Education for.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months Until Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_unsub_months} onValueChange={(e) => updateValue(e, 'fed_unsub_months', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many months are remaining until your repayment of this loan begins? If you are already repaying this loan, input 0. If not, find out the exact number of months until repayment and input that here. The FC will then schedule out your repayment for you.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Outstanding Loan Amount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_unsub_loan_amount} onValueChange={(e) => updateValue(e, 'fed_unsub_loan_amount', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Input your Outstanding Loan Amount to the penny. This amount does not include any accrued interest on the loan and is only the amount you have taken. For example, if you took $10,000 of loans during Higher Education, that is the value that goes here.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Existing Accrued Interest</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_unsub_accrued_interest} onValueChange={(e) => updateValue(e, 'fed_unsub_accrued_interest', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have not been making interest payments on your loan during Higher Education or during repayment, you probably have accrued interest. Contact your loan provider and input the amount of accrued interest to the penny here.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Current Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubCurrentLoan !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubCurrentLoan} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubCurrentLoan >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubCurrentLoan < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan (in months) or Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.fed_unsub_term} onValueChange={(e) => updateValue(e, 'fed_unsub_term', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you are not repaying your loan already, then input the # of months for repayment once you begin repaying your loan. If you have already begun repaying your loan, then input the # of months reamining until repayment ends.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.fed_unsub_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'fed_unsub_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='fed_unsub_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_unsub_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_interest_rate'); }}
                                            onBlur={() => { setFixedDecimal('fed_unsub_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The Interest Rate you pay on your loan is essential in determining your payback schedule. Input your interest rate here to the exact decimal point."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Payment Plan Before Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_unsub_payment_plan', 'select'); }}
                                              options={paymentPlanTypes}
                                              value={_.findIndex(paymentPlanTypes, { value: updatedExistingAssetsLiabilitiesValues.fed_unsub_payment_plan }) >= 0 ? paymentPlanTypes[_.findIndex(paymentPlanTypes, { value: updatedExistingAssetsLiabilitiesValues.fed_unsub_payment_plan })] : paymentPlanTypes[0]}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you receive your loan, interest will immediately begin to accrue. If you are already repaying this loan, then leave this input blank. If not, see how Deferring or Making Interest Payments impacts your Accrued Interest and Total Principal & Interest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Principal</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipal} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Accrued Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.module18Data.existingStudentLoansFedUnSubTotalAccruedInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubTotalAccruedInterest} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalAccruedInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalAccruedInterest < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Beginning Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubBeginningBalance} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubAnnualPayment} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubTotalInterest} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest} prefix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Existing Private Student Loan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Type of Higher Education</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'private_type_of_higher_education', 'select'); }}
                                              options={typeOfEducation}
                                              value={_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.private_type_of_higher_education }) >= 0 ? typeOfEducation[_.findIndex(typeOfEducation, { value: updatedExistingAssetsLiabilitiesValues.private_type_of_higher_education })] : typeOfEducation[0]}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What Higher Education did you attend that you took these loans for? It is important to know because your existing loans can impact how much loans you can take in the future.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Years Loans Were Taken</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'private_years', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedExistingAssetsLiabilitiesValues.private_years, value: updatedExistingAssetsLiabilitiesValues.private_years }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many years have you taken loans? This number is important in determining your capacity to take Federal Loans in the future. Input the number of years you have been in Higher Education for.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months Until Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.private_months} onValueChange={(e) => updateValue(e, 'private_months', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="How many months are remaining until your repayment of this loan begins? If you are already repaying this loan, input 0. If not, find out the exact number of months until repayment and input that here. The FC will then schedule out your repayment for you.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Outstanding Loan Amount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.private_loan_amount} onValueChange={(e) => updateValue(e, 'private_loan_amount', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Input your Outstanding Loan Amount to the penny. This amount does not include any accrued interest on the loan and is only the amount you have taken. For example, if you took $10,000 of loans during Higher Education, that is the value that goes here.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Existing Accrued Interest</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.private_accrued_interest} onValueChange={(e) => updateValue(e, 'private_accrued_interest', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have not been making interest payments on your loan during Higher Education or during repayment, you probably have accrued interest. Contact your loan provider and input the amount of accrued interest to the penny here.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Current Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.existingStudentLoansPrivateCurrentLoan !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateCurrentLoan} prefix={moduleServices.module18Data.existingStudentLoansPrivateCurrentLoan >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateCurrentLoan < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan (in months) or Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.private_term} onValueChange={(e) => updateValue(e, 'private_term', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you are not repaying your loan already, then input the # of months for repayment once you begin repaying your loan. If you have already begun repaying your loan, then input the # of months reamining until repayment ends.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.private_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'private_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='private_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='private_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('private_interest_rate'); }}
                                            onBlur={() => { setFixedDecimal('private_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The Interest Rate you pay on your loan is essential in determining your payback schedule. Input your interest rate here to the exact decimal point."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Payment Plan Before Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'private_payment_plan', 'select'); }}
                                              options={paymentPlanTypes}
                                              value={_.findIndex(paymentPlanTypes, { value: updatedExistingAssetsLiabilitiesValues.private_payment_plan }) >= 0 ? paymentPlanTypes[_.findIndex(paymentPlanTypes, { value: updatedExistingAssetsLiabilitiesValues.private_payment_plan })] : paymentPlanTypes[0]}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you receive your loan, interest will immediately begin to accrue. If you are already repaying this loan, then leave this input blank. If not, see how Deferring or Making Interest Payments impacts your Accrued Interest and Total Principal & Interest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Principal</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipal} prefix={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Accrued Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateTotalAccruedInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateTotalAccruedInterest} prefix={moduleServices.module18Data.existingStudentLoansPrivateTotalAccruedInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateTotalAccruedInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Beginning Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateBeginningBalance} prefix={moduleServices.module18Data.existingStudentLoansPrivateBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateMonthlyPayment} prefix={moduleServices.module18Data.existingStudentLoansPrivateMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateAnnualPayment} prefix={moduleServices.module18Data.existingStudentLoansPrivateAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateTotalInterest} prefix={moduleServices.module18Data.existingStudentLoansPrivateTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipalInterest} prefix={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansPrivateTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Existing Student Loans Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Beginning Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment} prefix={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalInterest} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Principal & Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div><strong>Debt-to-Income Ratio</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.inputRight, classes.spanGreenText)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryIncomeRatio !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryIncomeRatio} prefix={moduleServices.module18Data.existingStudentLoansSummaryIncomeRatio >= 0 ? '' : '('} suffix={moduleServices.module18Data.existingStudentLoansSummaryIncomeRatio < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIconWhite}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="Calculated by taking Total Debt Payments divided by Total Gross Income in a given year. The lower the ratio, the better the debt management state of an individual. A healthy ratio is no more than 36%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Income Needed to Support Existing Student Loans Analysis</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Existing Student Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Payment</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment} prefix={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Paid</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment * 12} prefix={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Divided by 12.5%</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>12.5%</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup, classes.inputGroup)}>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Income Needed to Support Existing Student Loans</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.incomeNeededExistingStudentLoans !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.incomeNeededExistingStudentLoans} prefix={moduleServices.module18Data.incomeNeededExistingStudentLoans >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.incomeNeededExistingStudentLoans < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0} className={classes.cfButton}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Cash</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Cash</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_cash} onValueChange={(e) => updateValue(e, 'existing_cash', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Checking Accounts</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_checking_accounts} onValueChange={(e) => updateValue(e, 'existing_checking_accounts', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Current Cash Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCurrentCashBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCurrentCashBalance} prefix={moduleServices.module18Data.existingCurrentCashBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCurrentCashBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Max Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_max_balance} onValueChange={(e) => updateValue(e, 'existing_max_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Investments</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Total Short-Term Investments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_total_short_term_investments} onValueChange={(e) => updateValue(e, 'existing_total_short_term_investments', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Total Long-Term Investments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_total_long_term_investments} onValueChange={(e) => updateValue(e, 'existing_total_long_term_investments', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingTotalInvestments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingTotalInvestments} prefix={moduleServices.module18Data.existingTotalInvestments >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingTotalInvestments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classNames(classes.spanText, classes.spanTextSmall, classes.textControl)}>Max Short-Term Investments Balance before Long-Term Investments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_max_short_term_before_long_term} onValueChange={(e) => updateValue(e, 'existing_max_short_term_before_long_term', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Other Investments</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Total Value of HSA</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_total_value_of_hsa} onValueChange={(e) => updateValue(e, 'existing_total_value_of_hsa', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>529 Plan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_529_plan} onValueChange={(e) => updateValue(e, 'existing_529_plan', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Other Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingTotalOtherInvestments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingTotalOtherInvestments} prefix={moduleServices.module18Data.existingTotalOtherInvestments >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingTotalOtherInvestments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Insurance</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Cash Value of Life Insurance Policies</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_cash_value_life_insurance_policies} onValueChange={(e) => updateValue(e, 'existing_cash_value_life_insurance_policies', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Retirement Accounts</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Traditional IRA</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_traditional_ra} onValueChange={(e) => updateValue(e, 'existing_traditional_ra', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>401(k) / 403b</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_401k_403b} onValueChange={(e) => updateValue(e, 'existing_401k_403b', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Roth IRA</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_roth_ira} onValueChange={(e) => updateValue(e, 'existing_roth_ira', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Roth 401(k) / 403b</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_roth_401k_403b} onValueChange={(e) => updateValue(e, 'existing_roth_401k_403b', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>SEP IRA</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_sep_ira} onValueChange={(e) => updateValue(e, 'existing_sep_ira', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Pension</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_pension} onValueChange={(e) => updateValue(e, 'existing_pension', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Retirement Accounts</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingTotalRetirementAccounts !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingTotalRetirementAccounts} prefix={moduleServices.module18Data.existingTotalRetirementAccounts >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingTotalRetirementAccounts < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Business</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Value of Business</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_value_of_business} onValueChange={(e) => updateValue(e, 'existing_value_of_business', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Expected Growth Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            fixedDecimalScale
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_expected_growth_rate}
                                            onValueChange={(e) => updateValue(e, 'existing_expected_growth_rate', 'number')}
                                            decimalScale = { isFocus!=='existing_expected_growth_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_expected_growth_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_expected_growth_rate'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Other Valuables</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Art</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_art} onValueChange={(e) => updateValue(e, 'existing_art', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Jewelry</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_jewelry} onValueChange={(e) => updateValue(e, 'existing_jewelry', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Other</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_other} onValueChange={(e) => updateValue(e, 'existing_other', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Valuables</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingTotalValuables !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingTotalValuables} prefix={moduleServices.module18Data.existingTotalValuables >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingTotalValuables < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Expected Growth Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            fixedDecimalScale
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_other_expected_growth_rate}
                                            onValueChange={(e) => updateValue(e, 'existing_other_expected_growth_rate', 'number')}
                                            decimalScale = { isFocus!=='existing_other_expected_growth_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_other_expected_growth_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_other_expected_growth_rate'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classNames(classes.moduleRow)}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Vehicle 1</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Own" className={updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Own' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Own"
                                                  name="Own"
                                                  value="Own"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Own'}
                                                  onClick={(e) => updateValue(e, 'vehicle_1_type', 'radio')}
                                                />
                                                Own
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Lease" className={updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Lease' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Lease"
                                                  name="Lease"
                                                  value="Lease"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Lease'}
                                                  onClick={(e) => updateValue(e, 'vehicle_1_type', 'radio')}
                                                />
                                                Lease
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            {updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Own' ? (
                              <>
                                <div className={classNames(classes.moduleTitle, classes.moduleTitleTop)}>
                                  <h3>Vehicle 1 Own</h3>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Value</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_value} onValueChange={(e) => updateValue(e, 'existing_car_current_value', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Total Loan Balance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_loan_balance} onValueChange={(e) => updateValue(e, 'existing_car_current_loan_balance', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Loan</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_months_to_maturity} onValueChange={(e) => updateValue(e, 'existing_car_months_to_maturity', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Interest Rate</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat
                                                className={classes.formInput}
                                                allowNegative={false}
                                                fixedDecimalScale
                                                thousandSeparator
                                                customInput={TextField}
                                                value={updatedExistingAssetsLiabilitiesValues.existing_car_interest_rate}
                                                onValueChange={(e) => updateValue(e, 'existing_car_interest_rate', 'number')}
                                                decimalScale = { isFocus!=='existing_car_interest_rate'? 2: 5 }
                                                fixedDecimalScale={isFocus!=='existing_car_interest_rate'}
                                                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_interest_rate'); }}
                                                onBlur={(e) => { 
                                                  setIsFocus('');
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansMonthlyPayment !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMonthlyPayment} prefix={moduleServices.module18Data.existingCarLoansMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansAnnualPayment !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansAnnualPayment} prefix={moduleServices.module18Data.existingCarLoansAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansAnnualPayment < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Interest</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalInterest !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalInterest} prefix={moduleServices.module18Data.existingCarLoansTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalInterest < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalPrincipalInterest !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest} prefix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle Expenses</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_maintenance} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansMaintenanceCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMaintenanceCalcs} prefix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    {/* <Grid item sm={3} className={classes.calcBlock} /> */}
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_insurance} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansInsuranceCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansInsuranceCalcs} prefix={moduleServices.module18Data.existingCarLoansInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansInsuranceCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_gas} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_gas', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansGasCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansGasCalcs} prefix={moduleServices.module18Data.existingCarLoansGasCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansGasCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Loan Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    {/* <Grid item sm={3} className={classes.calcBlock} /> */}
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses} prefix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansTotalAnnualExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses} prefix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : updatedExistingAssetsLiabilitiesValues.vehicle_1_type === 'Lease' && (
                              <>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle 1 Lease</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Lease</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_months_remaining} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_months_remaining', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Lease Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_payment} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_payment', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_maintenance} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_insurance} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_gas} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_gas', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanGasCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanGasCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanGasCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanGasCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalLeaseExpenses !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses} prefix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs} prefix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Vehicle 2</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Own2" className={updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Own' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Own2"
                                                  name="Own2"
                                                  value="Own"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Own'}
                                                  onClick={(e) => updateValue(e, 'vehicle_2_type', 'radio')}
                                                />
                                                Own
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Lease2" className={updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Lease' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Lease2"
                                                  name="Lease2"
                                                  value="Lease"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Lease'}
                                                  onClick={(e) => updateValue(e, 'vehicle_2_type', 'radio')}
                                                />
                                                Lease
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            {/* ******************* */}
                            {updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Own' ? (
                              <>
                                <div className={classNames(classes.moduleTitle, classes.moduleTitleTop)}>
                                  <h3>Vehicle 2 Own</h3>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Value</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_value_2} onValueChange={(e) => updateValue(e, 'existing_car_current_value_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Total Loan Balance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_loan_balance_2} onValueChange={(e) => updateValue(e, 'existing_car_current_loan_balance_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Loan</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_months_to_maturity_2} onValueChange={(e) => updateValue(e, 'existing_car_months_to_maturity_2', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Interest Rate</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat
                                                className={classes.formInput}
                                                allowNegative={false}
                                                fixedDecimalScale
                                                thousandSeparator
                                                customInput={TextField}
                                                value={updatedExistingAssetsLiabilitiesValues.existing_car_interest_rate_2}
                                                onValueChange={(e) => updateValue(e, 'existing_car_interest_rate_2', 'number')}
                                                decimalScale = { isFocus!=='existing_car_interest_rate_2'? 2: 5 }
                                                fixedDecimalScale={isFocus!=='existing_car_interest_rate_2'}
                                                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_interest_rate_2'); }}
                                                onBlur={(e) => { 
                                                  setIsFocus('');
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest) </strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansMonthlyPayment2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMonthlyPayment2} prefix={moduleServices.module18Data.existingCarLoansMonthlyPayment2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMonthlyPayment2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansAnnualPayment2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansAnnualPayment2} prefix={moduleServices.module18Data.existingCarLoansAnnualPayment2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansAnnualPayment2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Interest</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalInterest2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalInterest2} prefix={moduleServices.module18Data.existingCarLoansTotalInterest2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalInterest2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalPrincipalInterest2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest2} prefix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle Expenses</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_maintenance_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_maintenance_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansMaintenanceCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMaintenanceCalcs2} prefix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_insurance_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_insurance_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansInsuranceCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansInsuranceCalcs2} prefix={moduleServices.module18Data.existingCarLoansInsuranceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansInsuranceCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_gas_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_gas_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansGasCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansGasCalcs2} prefix={moduleServices.module18Data.existingCarLoansGasCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansGasCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Loan Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses2} prefix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansTotalAnnualExpenses2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses2} prefix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : updatedExistingAssetsLiabilitiesValues.vehicle_2_type === 'Lease' && (
                              <>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle 2 Lease</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Lease</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_months_remaining_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_months_remaining_2', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Lease Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_payment_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_payment_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_maintenance_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_maintenance_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_insurance_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_insurance_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_gas_2} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_gas_2', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanGasCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanGasCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanGasCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanGasCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalLeaseExpenses2 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses2} prefix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses2 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2} prefix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Vehicle 3</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Own3" className={updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Own' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Own3"
                                                  name="Own3"
                                                  value="Own"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Own'}
                                                  onClick={(e) => updateValue(e, 'vehicle_3_type', 'radio')}
                                                />
                                                Own
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Lease3" className={updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Lease' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Lease3"
                                                  name="Lease3"
                                                  value="Lease"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Lease'}
                                                  onClick={(e) => updateValue(e, 'vehicle_3_type', 'radio')}
                                                />
                                                Lease
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            {updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Own' ? (
                              <>
                                <div className={classNames(classes.moduleTitle, classes.moduleTitleTop)}>
                                  <h3>Vehicle 3 Own</h3>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Value</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_value_3} onValueChange={(e) => updateValue(e, 'existing_car_current_value_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Total Loan Balance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_loan_balance_3} onValueChange={(e) => updateValue(e, 'existing_car_current_loan_balance_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Loan</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_months_to_maturity_3} onValueChange={(e) => updateValue(e, 'existing_car_months_to_maturity_3', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Interest Rate</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat
                                                className={classes.formInput}
                                                allowNegative={false}
                                                fixedDecimalScale
                                                thousandSeparator
                                                customInput={TextField}
                                                value={updatedExistingAssetsLiabilitiesValues.existing_car_interest_rate_3}
                                                onValueChange={(e) => updateValue(e, 'existing_car_interest_rate_3', 'number')}
                                                decimalScale = { isFocus!=='existing_car_interest_rate_3'? 2: 5 }
                                                fixedDecimalScale={isFocus!=='existing_car_interest_rate_3'}
                                                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_interest_rate_3'); }}
                                                onBlur={(e) => { 
                                                  setIsFocus('');
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansMonthlyPayment3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMonthlyPayment3} prefix={moduleServices.module18Data.existingCarLoansMonthlyPayment3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMonthlyPayment3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansAnnualPayment3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansAnnualPayment3} prefix={moduleServices.module18Data.existingCarLoansAnnualPayment3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansAnnualPayment3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Interest</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalInterest3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalInterest3} prefix={moduleServices.module18Data.existingCarLoansTotalInterest3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalInterest3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalPrincipalInterest3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest3} prefix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle Expenses</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_maintenance_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_maintenance_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansMaintenanceCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMaintenanceCalcs3} prefix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_insurance_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_insurance_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansInsuranceCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansInsuranceCalcs3} prefix={moduleServices.module18Data.existingCarLoansInsuranceCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansInsuranceCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_gas_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_gas_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansGasCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansGasCalcs3} prefix={moduleServices.module18Data.existingCarLoansGasCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansGasCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Loan Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses3} prefix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansTotalAnnualExpenses3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses3} prefix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : updatedExistingAssetsLiabilitiesValues.vehicle_3_type === 'Lease' && (
                              <>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle 3 Lease</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Lease</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_months_remaining_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_months_remaining_3', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Lease Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_payment_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_payment_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_maintenance_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_maintenance_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_insurance_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_insurance_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_gas_3} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_gas_3', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanGasCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanGasCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanGasCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanGasCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalLeaseExpenses3 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses3} prefix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses3 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3} prefix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Vehicle 4</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Own4" className={updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Own' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Own4"
                                                  name="Own4"
                                                  value="Own"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Own'}
                                                  onClick={(e) => updateValue(e, 'vehicle_4_type', 'radio')}
                                                />
                                                Own
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Lease4" className={updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Lease' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Lease4"
                                                  name="Lease4"
                                                  value="Lease"
                                                  checked={updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Lease'}
                                                  onClick={(e) => updateValue(e, 'vehicle_4_type', 'radio')}
                                                />
                                                Lease
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            {updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Own' ? (
                              <>
                                <div className={classNames(classes.moduleTitle, classes.moduleTitleTop)}>
                                  <h3>Vehicle 4 Own</h3>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Value</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_value_4} onValueChange={(e) => updateValue(e, 'existing_car_current_value_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Total Loan Balance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_loan_balance_4} onValueChange={(e) => updateValue(e, 'existing_car_current_loan_balance_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Loan</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_months_to_maturity_4} onValueChange={(e) => updateValue(e, 'existing_car_months_to_maturity_4', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Interest Rate</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat
                                                className={classes.formInput}
                                                allowNegative={false}
                                                fixedDecimalScale
                                                thousandSeparator
                                                customInput={TextField}
                                                value={updatedExistingAssetsLiabilitiesValues.existing_car_interest_rate_4}
                                                onValueChange={(e) => updateValue(e, 'existing_car_interest_rate_4', 'number')}
                                                decimalScale = { isFocus!=='existing_car_interest_rate_4'? 2: 5 }
                                                fixedDecimalScale={isFocus!=='existing_car_interest_rate_4'}
                                                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_interest_rate_4'); }}
                                                onBlur={(e) => { 
                                                  setIsFocus('');
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansMonthlyPayment4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMonthlyPayment4} prefix={moduleServices.module18Data.existingCarLoansMonthlyPayment4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMonthlyPayment4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansAnnualPayment4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansAnnualPayment4} prefix={moduleServices.module18Data.existingCarLoansAnnualPayment4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansAnnualPayment4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Interest</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalInterest4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalInterest4} prefix={moduleServices.module18Data.existingCarLoansTotalInterest4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalInterest4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalPrincipalInterest4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest4} prefix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle Expenses</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_maintenance_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_maintenance_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansMaintenanceCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMaintenanceCalcs4} prefix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_insurance_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_insurance_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansInsuranceCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansInsuranceCalcs4} prefix={moduleServices.module18Data.existingCarLoansInsuranceCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansInsuranceCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_own_gas_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_own_gas_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansGasCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansGasCalcs4} prefix={moduleServices.module18Data.existingCarLoansGasCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansGasCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Loan Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsCalcs4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanPaymentsYearCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses4} prefix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansTotalAnnualExpenses4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses4} prefix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : updatedExistingAssetsLiabilitiesValues.vehicle_4_type === 'Lease' && (
                              <>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} className={classes.noRightSpace}>
                                          <div className={classes.moduleTitle}>
                                            <h3>Vehicle 4 Lease</h3>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} className={classes.noSpace}>
                                          <div className={classes.labelTextCenter}>
                                            <label>Monthly</label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock}>
                                      <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                        <label>Annual</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Months Remaining on Lease</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_months_remaining_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_months_remaining_4', 'number')} onFocus={(e) => e.target.select()} />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Lease Payment</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_payment_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_payment_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanLeasePaymentCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Maintenance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_maintenance_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_maintenance_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanMaintenanceCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Insurance</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_insurance_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_insurance_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanInsuranceCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>Gas / Electricity</div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lease_gas_4} onValueChange={(e) => updateValue(e, 'existing_car_loans_lease_gas_4', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classes.dollerCount}>
                                          {moduleServices.module18Data.existingCarLoansLoanGasCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanGasCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanGasCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanGasCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                      <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                        <Grid container spacing={4} className={classes.noSpace}>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                              <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module18Data.existingCarLoansTotalLeaseExpenses4 !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses4} prefix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalLeaseExpenses4 < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                      <div className={classes.dollerRight}>
                                        <span className={classNames(classes.dollerCount, classes.dollerCountLast)}>
                                          {moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4 !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4} prefix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}



                            {/* ******************* */}
                            {/* <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Existing Car</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Car Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_value} onValueChange={(e) => updateValue(e, 'existing_car_current_value', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you own a car right now, how much would that car sell for today? Use the Kelley Blue Book link in the Boost My Awareness section to put a value on your current car. If you don't own a car, this section can be left blank.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Loan Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_current_loan_balance} onValueChange={(e) => updateValue(e, 'existing_car_current_loan_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you own a car and have a car loan, how much of that car loan still has to be repaid? Enter your Current Loan Balance - see the Boost My Awareness section for more information. If you don't own a car and don't have a car loan, leave this blank.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_months_to_maturity} onValueChange={(e) => updateValue(e, 'existing_car_months_to_maturity', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you own a car and have an outstanding car loan, enter how many months you have left to repay that loan. For more information on Months to Maturity, check out the Boost My Awareness section. If you don't own a car or have a car loan, enter $0.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_car_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'existing_car_interest_rate', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={2}
                                            onBlur={() => { setFixedDecimal('existing_car_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="If you own a car and have an outstanding car loan, enter the Interest Rate you are paying on that loan. The FC will take this rate and the data above to calculate what you should be paying monthly to repay your loan on time."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMonthlyPayment} prefix={moduleServices.module18Data.existingCarLoansMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansAnnualPayment} prefix={moduleServices.module18Data.existingCarLoansAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalInterest} prefix={moduleServices.module18Data.existingCarLoansTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest} prefix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Monthly Car Expenses</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_maintenance} onValueChange={(e) => updateValue(e, 'existing_car_loans_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on maintenance for this car. Maintenance includes things like new tires, fixing a cracked windshield, or replacing a flat tire. Average maintenance costs run about $100 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.existingCarLoansMaintenanceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansMaintenanceCalcs} prefix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansMaintenanceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_lnsurance} onValueChange={(e) => updateValue(e, 'existing_car_loans_lnsurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on insurance for this car. Average insurance costs can range from roughly $100 - $200 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.existingCarLoansInsuranceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansInsuranceCalcs} prefix={moduleServices.module18Data.existingCarLoansInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Gas</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_car_loans_gas} onValueChange={(e) => updateValue(e, 'existing_car_loans_gas', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on gas for this car. Think about the distance you plan on commuting to work or school and how often you may take longer trips to get a good estimate of your monthly gas expense.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.existingCarLoansGasCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansGasCalcs} prefix={moduleServices.module18Data.existingCarLoansGasCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansGasCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses} prefix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingCarLoansTotalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses} prefix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingCarLoansTotalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div> */}
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Current Home</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Home Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_current_value} onValueChange={(e) => updateValue(e, 'existig_home_current_value', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Use Trulia or Zillow or contact your local real estate agent to understand what your current home value might be. Or, get your home appraised. But, appraisal can cost money so for a quick estimate, Trulia or Zillow will do.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Basis (Purchase Price, Closing Costs, Fit Out Costs)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_basis_costs} onValueChange={(e) => updateValue(e, 'existig_home_basis_costs', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Your Basis is how much you bought your home for plus any Closing Costs and Fit Out Costs (improvements to the home that add value) that you've added over the life of the home. Your Basis is important for taxes. See the Boost My Awareness section for more.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Appreciation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_home_annual_appreciation}
                                            onValueChange={(e) => updateValue(e, 'existing_home_annual_appreciation', 'number')}
                                            decimalScale = { isFocus!=='existing_home_annual_appreciation'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_home_annual_appreciation'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_home_annual_appreciation'); }}
                                            onBlur={() => { setFixedDecimal('existing_home_annual_appreciation'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="What type of home do you want? How many bedrooms? Where do you want to live? All these questions will factor into the price of your home. Use the Boost My Awareness section to find how much your future home might cost."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Home Mortgage</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Mortgage Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_current_mortgage_balance} onValueChange={(e) => updateValue(e, 'existig_home_current_mortgage_balance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  {moduleServices.module18Data.existigHomeCurrentMortgageBalanceCalcs !== undefined ? (
                                    moduleServices.module18Data.existigHomeCurrentMortgageBalanceCalcs
                                  ) : ('$0')}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_mortgage_months_to_maturity} onValueChange={(e) => updateValue(e, 'existig_home_mortgage_months_to_maturity', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many months to maturity are remaining on your mortgage? You can also play with the number of months to maturity to see how your repayment will change. The FC will then schedule out your repayment for you.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>

                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existig_home_mortgage_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'existig_home_mortgage_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='existig_home_mortgage_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existig_home_mortgage_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existig_home_mortgage_interest_rate'); }}
                                            onBlur={() => { setFixedDecimal('existig_home_mortgage_interest_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="What is your interest rate on your mortgage? Only input the interest rate, not any rate that includes fees and other expenses. Use the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageMonthlyPayment} prefix={moduleServices.module18Data.existingHomeMortgageMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageAnnualPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageAnnualPayment} prefix={moduleServices.module18Data.existingHomeMortgageAnnualPayment >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageAnnualPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageTotalInterest} prefix={moduleServices.module18Data.existingHomeMortgageTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest} prefix={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Debt-to-Income Ratio</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.spanGreenText, classes.spanGreenBtn)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomedebtIncomeRatio !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module18Data.existingHomedebtIncomeRatio} prefix={moduleServices.module18Data.existingHomedebtIncomeRatio >= 0 ? '' : '('} suffix={moduleServices.module18Data.existingHomedebtIncomeRatio < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIconWhite}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="Calculated by taking Total Debt Payments divided by Total Gross Income in a given year. The lower the ratio, the better the debt management state of an individual. A healthy ratio is no more than 36%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <Grid container spacing={4} className={ classes.inputBlockSpacing }>
                                    <Grid item sm={6} className={classes.noRightSpace}>
                                      <div className={classNames(classes.moduleTitle, classes.moduleTitleNowrap)}>
                                        <h3>Monthly Home Expenses</h3>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} className={classes.noSpace}>
                                      <div className={classes.labelTextCenter}>
                                        <label>Monthly</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                    <label>Annual</label>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_property_taxes} onValueChange={(e) => updateValue(e, 'existing_home_monthly_property_taxes', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Input your last monthly property tax payment and let the FC do the rest. Property taxes are based off your home value so it will grow at the appreciation rate above. See Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_homeowner_insurance} onValueChange={(e) => updateValue(e, 'existing_home_monthly_homeowner_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="You need to have insurance on your important assets just in case of emergency. What if a fire strikes? Input your last Homeowner's Insurance monthly payment. Insurance is based off your home value so it will grow at the appreciation rate above.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Private Mortgage Insurance (PMI)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_private_mortgage_insurance} onValueChange={(e) => updateValue(e, 'existing_home_monthly_private_mortgage_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Just like owning a car, you need to have insurance on your important assets just in case of emergency. What if a tree falls on your home? Or a fire strikes? Homeowner's Insurance is a percentage based fee paid annually to protect against emergencies.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_maintenance} onValueChange={(e) => updateValue(e, 'existing_home_monthly_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Each year your home will need some repairs and improvements. Estimate your Annual Maintenance expense. See Boost My Awareness section for more details. Maintenance is based off your home value so it will grow at the appreciation rate above.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_association_fees} onValueChange={(e) => updateValue(e, 'existing_home_monthly_association_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Some residential communities charge Annual Association Fees for the general upkeep of the neighborhood or community. A company takes care of the lawn, trash, and so forth. If you pay Association Fees, input your last monthly payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_utilities} onValueChange={(e) => updateValue(e, 'existing_home_monthly_utilities', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Input your latest monthly utilities payment and the FC will do the rest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs} prefix={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Mortgage Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.mortgagePaymentPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterest} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      {/* <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {' '}
                                      {moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterestAnnual < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.mortgagetotalMonthlyExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpenses} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      {/* <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {' '}
                                      {moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpensesAnnual < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Home Sale</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Sale Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.selectBlockLayer)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'existing_home_sale_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedExistingAssetsLiabilitiesValues.existing_home_sale_year, value: updatedExistingAssetsLiabilitiesValues.existing_home_sale_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The length of time that you are invested in a Real Estate property can impact your returns. See how changing your Sale Year can impact your Sale Price and returns below. The longer you are invested, the more risk you take!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.saleYearCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.saleYearCalcs} prefix={moduleServices.module18Data.saleYearCalcs >= 0 ? '' : ''} suffix={moduleServices.module18Data.saleYearCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                        {' '}
                                        years
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Sale Price in Year
                                            {' '}
                                            {' '}
                                            {updatedExistingAssetsLiabilitiesValues.existing_home_sale_year}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.existingHomeSalesPriceYear !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeSalesPriceYear} prefix={moduleServices.module18Data.existingHomeSalesPriceYear >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeSalesPriceYear < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Primary Residence Last 2-of-5 Years</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="p_Yes" className={updatedExistingAssetsLiabilitiesValues.primary_residence === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="p_Yes"
                                                  name="p_Yes"
                                                  value="Yes"
                                                  checked={updatedExistingAssetsLiabilitiesValues.primary_residence === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'primary_residence', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="p_No" className={updatedExistingAssetsLiabilitiesValues.primary_residence === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="p_No"
                                                  name="p_No"
                                                  value="No"
                                                  checked={updatedExistingAssetsLiabilitiesValues.primary_residence === 'No'}
                                                  onClick={(e) => updateValue(e, 'primary_residence', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Married Status</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="m_Yes" className={updatedExistingAssetsLiabilitiesValues.married_status === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="m_Yes"
                                                  name="m_Yes"
                                                  value="Yes"
                                                  checked={updatedExistingAssetsLiabilitiesValues.married_status === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'married_status', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="m_No" className={updatedExistingAssetsLiabilitiesValues.married_status === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="m_No"
                                                  name="m_No"
                                                  value="No"
                                                  checked={updatedExistingAssetsLiabilitiesValues.married_status === 'No'}
                                                  onClick={(e) => updateValue(e, 'married_status', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Selling Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_home_selling_costs}
                                            onValueChange={(e) => updateValue(e, 'existing_home_selling_costs', 'number')}
                                            decimalScale = { isFocus!=='existing_home_selling_costs'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_home_selling_costs'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_home_selling_costs'); }}
                                            onBlur={() => { setFixedDecimal('existing_home_selling_costs'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="Please specify, or estimate, what your selling costs will be at final sale. If you do not have to use a real estate agent, typical costs are around 2-3%. If you do have to use a real estate agent, typical costs are around 7-9%."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.existigHomeSellingCostsCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existigHomeSellingCostsCalcs} prefix={moduleServices.module18Data.existigHomeSellingCostsCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existigHomeSellingCostsCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Balance Remaining at Sale</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.existingHomeMortgageBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageBalance} prefix={moduleServices.module18Data.existingHomeMortgageBalance >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip='Please see "Real Estate Investment Property - Investment Analysis" summary table for a detailed breakdown of Taxes in Sale Year. Taxes in Sale Year include a 15% tax on the Gain on Sale as well as a 25% tax on Accumulated Depreciation.'>
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Cash Flow from Sale (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {' '}
                                            {moduleServices.module18Data.existingHomeNetCashFlowFromSale !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeNetCashFlowFromSale} prefix={moduleServices.module18Data.existingHomeNetCashFlowFromSale >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeNetCashFlowFromSale < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Taxes on Sale</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeSaleSellingPrice !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeSaleSellingPrice} prefix={moduleServices.module18Data.existingHomeSaleSellingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeSaleSellingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Selling Costs</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.sellingCosts !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.sellingCosts} prefix={moduleServices.module18Data.sellingCosts >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.sellingCosts < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.netSellingPrice !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.netSellingPrice} prefix={moduleServices.module18Data.netSellingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.netSellingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Basis</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingLoansBasis !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingLoansBasis} prefix={moduleServices.module18Data.existingLoansBasis >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingLoansBasis < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Profit (Loss) on Sale</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.gainOnSale !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.gainOnSale} prefix={moduleServices.module18Data.gainOnSale >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.gainOnSale < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Profit Exclusion</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxableGainUp !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxableGainUp} prefix={moduleServices.module18Data.taxableGainUp >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxableGainUp < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Taxable Gain (Up to $250,000 is Tax-Free if Single)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxableGainUp1 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxableGainUp1} prefix={moduleServices.module18Data.taxableGainUp1 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxableGainUp1 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Tax on Taxable Gain (15%)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxOnGain !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxOnGain} prefix={moduleServices.module18Data.taxOnGain >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxOnGain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Existing Second Home Value</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Home Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_current_value_2} onValueChange={(e) => updateValue(e, 'existig_home_current_value_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Use Trulia or Zillow or contact your local real estate agent to understand what your current home value might be. Or, get your home appraised. But, appraisal can cost money so for a quick estimate, Trulia or Zillow will do.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Basis (Purchase Price, Closing Costs, Fit Out Costs)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_basis_costs_2} onValueChange={(e) => updateValue(e, 'existig_home_basis_costs_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Your Basis is how much you bought your home for plus any Closing Costs and Fit Out Costs (improvements to the home that add value) that you've added over the life of the home. Your Basis is important for taxes. See the Boost My Awareness section for more.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Appreciation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_home_annual_appreciation_2}
                                            onValueChange={(e) => updateValue(e, 'existing_home_annual_appreciation_2', 'number')}
                                            decimalScale = { isFocus!=='existing_home_annual_appreciation_2'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_home_annual_appreciation_2'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_home_annual_appreciation_2'); }}
                                            onBlur={() => { setFixedDecimal('existing_home_annual_appreciation_2'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="What type of home do you want? How many bedrooms? Where do you want to live? All these questions will factor into the price of your home. Use the Boost My Awareness section to find how much your future home might cost."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Existing Home Mortgage</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Current Mortgage Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_current_mortgage_balance_2} onValueChange={(e) => updateValue(e, 'existig_home_current_mortgage_balance_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="As of your last month, input your mortgage balance that does not include any other fees or expenses. Strictly enter your latest mortgage balance and the FC will autocalculate your home equity">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  {moduleServices.module18Data.existigHomeCurrentMortgageBalanceCalcs !== undefined ? (
                                    moduleServices.module18Data.existigHomeCurrentMortgageBalanceCalcs
                                  ) : ('$0')}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Months to Maturity</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existig_home_mortgage_months_to_maturity_2} onValueChange={(e) => updateValue(e, 'existig_home_mortgage_months_to_maturity_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How many months to maturity are remaining on your mortgage? You can also play with the number of months to maturity to see how your repayment will change. The FC will then schedule out your repayment for you.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>

                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existig_home_mortgage_interest_rate_2}
                                            onValueChange={(e) => updateValue(e, 'existig_home_mortgage_interest_rate_2', 'number')}
                                            decimalScale = { isFocus!=='existig_home_mortgage_interest_rate_2'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existig_home_mortgage_interest_rate_2'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existig_home_mortgage_interest_rate_2'); }}
                                            onBlur={() => { setFixedDecimal('existig_home_mortgage_interest_rate_2'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="What is your interest rate on your mortgage? Only input the interest rate, not any rate that includes fees and other expenses. Use the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageMonthlyPayment2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageMonthlyPayment2} prefix={moduleServices.module18Data.existingHomeMortgageMonthlyPayment2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageMonthlyPayment2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageAnnualPayment2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageAnnualPayment2} prefix={moduleServices.module18Data.existingHomeMortgageAnnualPayment2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageAnnualPayment2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageTotalInterest2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageTotalInterest2} prefix={moduleServices.module18Data.existingHomeMortgageTotalInterest2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageTotalInterest2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest2} prefix={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageTotalPrincipalInterest2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Debt-to-Income Ratio</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.spanGreenText, classes.spanGreenBtn)}>
                                          <span className={classes.noInputText}>
                                            0%
                                          </span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIconWhite}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="Calculated by taking Total Debt Payments divided by Total Gross Income in a given year. The lower the ratio, the better the debt management state of an individual. A healthy ratio is no more than 36%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <Grid container spacing={4} className={ classes.inputBlockSpacing }>
                                    <Grid item sm={6} className={classes.noRightSpace}>
                                      <div className={classNames(classes.moduleTitle, classes.moduleTitleNowrap)}>
                                        <h3>Monthly Home Expenses</h3>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} className={classes.noSpace}>
                                      <div className={classes.labelTextCenter}>
                                        <label>Monthly</label>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classNames(classes.labelText, classes.labelTextAnual)}>
                                    <label>Annual</label>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_property_taxes_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_property_taxes_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Input your last monthly property tax payment and let the FC do the rest. Property taxes are based off your home value so it will grow at the appreciation rate above. See Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_homeowner_insurance_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_homeowner_insurance_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="You need to have insurance on your important assets just in case of emergency. What if a fire strikes? Input your last Homeowner's Insurance monthly payment. Insurance is based off your home value so it will grow at the appreciation rate above.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Private Mortgage Insurance (PMI)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_private_mortgage_insurance_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_private_mortgage_insurance_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Just like owning a car, you need to have insurance on your important assets just in case of emergency. What if a tree falls on your home? Or a fire strikes? Homeowner's Insurance is a percentage based fee paid annually to protect against emergencies.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_maintenance_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_maintenance_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Each year your home will need some repairs and improvements. Estimate your Annual Maintenance expense. See Boost My Awareness section for more details. Maintenance is based off your home value so it will grow at the appreciation rate above.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyMaintenanceCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_association_fees_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_association_fees_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Some residential communities charge Annual Association Fees for the general upkeep of the neighborhood or community. A company takes care of the lawn, trash, and so forth. If you pay Association Fees, input your last monthly payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyAssociationFeesCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedExistingAssetsLiabilitiesValues.existing_home_monthly_utilities_2} onValueChange={(e) => updateValue(e, 'existing_home_monthly_utilities_2', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Input your latest monthly utilities payment and the FC will do the rest.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classes.dollerRight}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs2 !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs2} prefix={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMonthlyUtilitiesCalcs2 < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Mortgage Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.mortgagePaymentPrincipalInterest2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterest2} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      {/* <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classNames(classes.dollerRight, classes.dollerRightHeight)}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual} prefix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagePaymentPrincipalInterest2Annual < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.mortgagetotalMonthlyExpenses2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpenses2} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      {/* <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.noSpaceDoller}>
                                  <div className={classNames(classes.dollerRight, classes.dollerRightHeight)}>
                                    <span className={classes.dollerCount}>
                                      {moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual} prefix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.mortgagetotalMonthlyExpenses2Annual < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Home Sale</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Sale Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.selectBlockLayer)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'existing_home_sale_year_2', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedExistingAssetsLiabilitiesValues.existing_home_sale_year_2, value: updatedExistingAssetsLiabilitiesValues.existing_home_sale_year_2 }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The length of time that you are invested in a Real Estate property can impact your returns. See how changing your Sale Year can impact your Sale Price and returns below. The longer you are invested, the more risk you take!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.saleYearCalcs2 !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.saleYearCalcs2} prefix={moduleServices.module18Data.saleYearCalcs2 >= 0 ? '' : ''} suffix={moduleServices.module18Data.saleYearCalcs2 < 0 && ')'} />
                                        ) : ('$0')}
                                        {' '}
                                        years
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Sale Price in Year
                                            {' '}
                                            {' '}
                                            {updatedExistingAssetsLiabilitiesValues.existing_home_sale_year_2}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeSalesPriceYear2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeSalesPriceYear2} prefix={moduleServices.module18Data.existingHomeSalesPriceYear2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeSalesPriceYear2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Primary Residence Last 2-of-5 Years</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="p_Yes_2" className={updatedExistingAssetsLiabilitiesValues.primary_residence_2 === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="p_Yes_2"
                                                  name="p_Yes_2"
                                                  value="Yes"
                                                  checked={updatedExistingAssetsLiabilitiesValues.primary_residence_2 === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'primary_residence_2', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="p_No_2" className={updatedExistingAssetsLiabilitiesValues.primary_residence_2 === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="p_No_2"
                                                  name="p_No_2"
                                                  value="No"
                                                  checked={updatedExistingAssetsLiabilitiesValues.primary_residence_2 === 'No'}
                                                  onClick={(e) => updateValue(e, 'primary_residence_2', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Married Status</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classes.radioGroup}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="m_Yes_2" className={updatedExistingAssetsLiabilitiesValues.married_status_2 === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="m_Yes_2"
                                                  name="m_Yes_2"
                                                  value="Yes"
                                                  checked={updatedExistingAssetsLiabilitiesValues.married_status_2 === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'married_status_2', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="m_No_2" className={updatedExistingAssetsLiabilitiesValues.married_status_2 === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="m_No_2"
                                                  name="m_No_2"
                                                  value="No"
                                                  checked={updatedExistingAssetsLiabilitiesValues.married_status_2 === 'No'}
                                                  onClick={(e) => updateValue(e, 'married_status_2', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Selling Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedExistingAssetsLiabilitiesValues.existing_home_selling_costs_2}
                                            onValueChange={(e) => updateValue(e, 'existing_home_selling_costs_2', 'number')}
                                            decimalScale = { isFocus!=='existing_home_selling_costs_2'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='existing_home_selling_costs_2'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_home_selling_costs_2'); }}
                                            onBlur={() => { setFixedDecimal('existing_home_selling_costs_2'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="Please specify, or estimate, what your selling costs will be at final sale. If you do not have to use a real estate agent, typical costs are around 2-3%. If you do have to use a real estate agent, typical costs are around 7-9%."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module18Data.existigHomeSellingCostsCalcs2 !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existigHomeSellingCostsCalcs2} prefix={moduleServices.module18Data.existigHomeSellingCostsCalcs2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existigHomeSellingCostsCalcs2 < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Balance Remaining at Sale</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeMortgageBalance2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeMortgageBalance2} prefix={moduleServices.module18Data.existingHomeMortgageBalance2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeMortgageBalance2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip='Please see "Real Estate Investment Property - Investment Analysis" summary table for a detailed breakdown of Taxes in Sale Year. Taxes in Sale Year include a 15% tax on the Gain on Sale as well as a 25% tax on Accumulated Depreciation.'>
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Cash Flow from Sale (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeNetCashFlowFromSale2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeNetCashFlowFromSale2} prefix={moduleServices.module18Data.existingHomeNetCashFlowFromSale2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeNetCashFlowFromSale2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Taxes on Existing Home Sale</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingHomeSaleSellingPrice2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingHomeSaleSellingPrice2} prefix={moduleServices.module18Data.existingHomeSaleSellingPrice2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingHomeSaleSellingPrice2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Selling Costs</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.sellingCosts2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.sellingCosts2} prefix={moduleServices.module18Data.sellingCosts2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.sellingCosts2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.netSellingPrice2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.netSellingPrice2} prefix={moduleServices.module18Data.netSellingPrice2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.netSellingPrice2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Basis</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.existingLoansBasis2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.existingLoansBasis2} prefix={moduleServices.module18Data.existingLoansBasis2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existingLoansBasis2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Profit (loss) on Sale</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.gainOnSale2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.gainOnSale2} prefix={moduleServices.module18Data.gainOnSale2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.gainOnSale2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Profit Exclusion</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxableGainUp2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxableGainUp2} prefix={moduleServices.module18Data.taxableGainUp2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxableGainUp2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Taxable Profit</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxableGainUp12 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxableGainUp12} prefix={moduleServices.module18Data.taxableGainUp12 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxableGainUp12 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Tax on Taxable Profit at 15%</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.widthHalf}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.taxOnGain2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.taxOnGain2} prefix={moduleServices.module18Data.taxOnGain2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.taxOnGain2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText, classes.insideSpanTextAuto)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><h3>Current Net Worth</h3></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><h3>Assets</h3></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><h3>Liabilities</h3></div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Cash</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthCash !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthCash} prefix={moduleServices.module18Data.currentNetWorthCash >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthCash < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Investments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthInvestments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthInvestments} prefix={moduleServices.module18Data.currentNetWorthInvestments >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthInvestments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Accounts</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthRetirementAccounts !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthRetirementAccounts} prefix={moduleServices.module18Data.currentNetWorthRetirementAccounts >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthRetirementAccounts < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Life Insurance Cash Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthLifeInsuranceCash !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthLifeInsuranceCash} prefix={moduleServices.module18Data.currentNetWorthLifeInsuranceCash >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthLifeInsuranceCash < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Business</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthBusiness !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthBusiness} prefix={moduleServices.module18Data.currentNetWorthBusiness >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthBusiness < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Valuables</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthOtherValuables !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthOtherValuables} prefix={moduleServices.module18Data.currentNetWorthOtherValuables >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthOtherValuables < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Vehicles</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthVehicles !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthVehicles} prefix={moduleServices.module18Data.currentNetWorthVehicles >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthVehicles < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Homes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthHomes !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthHomes} prefix={moduleServices.module18Data.currentNetWorthHomes >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthHomes < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Credit Card Debt</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthCreditCardDebt !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthCreditCardDebt} prefix={moduleServices.module18Data.currentNetWorthCreditCardDebt >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthCreditCardDebt < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Personal Loans</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthPersonalLoans !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthPersonalLoans} prefix={moduleServices.module18Data.currentNetWorthPersonalLoans >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthPersonalLoans < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Student Loans</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthStudentLoans !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthStudentLoans} prefix={moduleServices.module18Data.currentNetWorthStudentLoans >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthStudentLoans < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Auto Loans</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthAutoLoans !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthAutoLoans} prefix={moduleServices.module18Data.currentNetWorthAutoLoans >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthAutoLoans < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Mortgages</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthMortgages !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthMortgages} prefix={moduleServices.module18Data.currentNetWorthMortgages >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthMortgages < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Assets / Liabilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities} prefix={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2} prefix={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Current Net Worth</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth} prefix={moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            --
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0} className={classes.cfButton}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
              {/* {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)} */}
            </div>
            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.dummyImgShowmeExisting) : classNames(classes.showMe, classes.showSection) && slidecount === 7 ? classNames(classes.showMe, classes.showMeScroll, classes.whatIfExisting) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : '' || slidecount === 7 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruStudent walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                <div>
                  {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                    <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                  )}
                  {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Refresh</Button>)}
                </div>
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock} >
                        <h3>Existing Federal Subsidized Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph1Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Existing Federal Unsubsidized Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph2Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph3 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Existing Private Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph3Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph4 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Existing Credit Card Debt</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph4Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph5 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Existing Car Loan</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph5Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph6 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Existing Home Value and Mortgage Repayment</h3>
                        <hr className={classes.blueRow} />
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph6Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.showDescriptionCntnt}>
                    <div className={classes.existingGraphBlock}>
                      <div className={classes.existingGraphRow}>
                        <div className={classes.graphColumn}>
                          <div className={classes.refinancedData}>
                            <ReactTooltip id="showTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.showTooltip)} />
                            <table className={classes.refinancedTable}>
                              <tbody>
                                <tr>
                                  <td colSpan="3">
                                    <h2>What If I Refinanced My Student Loans and Invested the Annual Savings?</h2>
                                    <hr className={classes.blueRule} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className={classes.leftMinusMargin}>
                                    <h3>
                                      <span>1. </span>
                                      Existing Student Loans
                                    </h3>
                                    <hr className={classes.blueRule} />
                                  </td>
                                  <td className={classes.refTitle}>
                                    <h3>
                                      <span>2. </span>
                                      Refinanced Student Loans
                                    </h3>
                                    <hr className={classes.blueRule} />
                                  </td>
                                  <td className={classNames(classes.leftMinusMargin, classes.refTitle)}>
                                    <h3>
                                      <span>3. </span>
                                      Savings
                                    </h3>
                                    <hr className={classes.blueRule} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className={classes.esGraph}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Existing Student Loans Balance
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="The total amount of loans you have entered in the My Details section above.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Weighted Average Interest Rate
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="Check out the Boost My Awareness section for a detailed explanation.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>0.00%</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Weighted Average Years to Maturity
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="Check out the Boost My Awareness section for a detailed explanation.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Existing Monthly Payment
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="The sum of your monthly payments for each of the loans you have entered in the My Details section above.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Annual Paid (Principal & Interest)
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="The sum of your annual paid (principal & interest) for each of the loans you have entered in the My Details section above.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Total Interest Paid
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="The sum of your total interest paid for each of the loans you have entered in the My Details section above.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Total Principal & Interest
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="The sum of your total principal & interest for each of the loans you have entered in the My Details section above.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className={classes.refTitleNew}>
                                    <h3>
                                      <span>2.</span>
                                      Refinanced Student Loans
                                    </h3>
                                    <hr className={classes.blueRule} />
                                  </td>
                                  <td className={classNames(classes.esGraph, classes.rsLoans)}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Existing Student Loans Balance
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            New Interest Rate
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="Input the interest rate you expect to get by refinancing your student loans. Ideally, you'd like to get a lower interest rate when refinancing your student loans.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.rsInput}>
                                            <input type="text" />
                                            <span className={classes.rsPercent}>%</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            New Term of the Loan
                                            <div
                                              className={classes.infoIconNew}
                                              data-for="showTooltip"
                                              data-tip="When refinancing your student loans, you can choose a new term of the loan ranging from 5 to 25 years. Input the number of years you want to repay your loans in. If you shorten the term of the loan, your monthly payment will increase, but your total interest paid will decrease (assuming you keep your other inputs the same).
                                             If you lengthen the term of the loan, your monthly payment will decrease, but your total interest paid will increase (assuming you keep your other inputs the same)."
                                            >
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.rsInput}>
                                            <input type="text" />
                                            <span className={classes.rsPercent}>%</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            New Monthly Payment
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            New Annual Paid (Principal & Interest)
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            New Total Interest Paid
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>New Total Principal & Interest</td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className={classes.refTitleNew}>
                                    <h3>
                                      <span>3. </span>
                                      Savings
                                    </h3>
                                    <hr className={classes.blueRule} />
                                  </td>
                                  <td className={classNames(classes.esGraph, classes.rsLoans)}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.downArrow}>
                                            <img src={arrowDown} alt="arrow" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr />
                                        <tr>
                                          <td>Total Monthly Savings</td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>Annual Savings</td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Total Savings Over 0 Year
                                            <div
                                              className={classes.infoIconNew}
                                              data-for="showTooltip"
                                              data-tip="When you refinance your student loans, you still have to repay your existing student loans balance, which is also called your principal. However, when you refinance you can get a new interest rate and a new term of the loan. Those two inputs will affect the total interest paid and that is where you can achieve savings.
                                            Adjust the new interest rate and new term of the loan to see how that changes your savings."
                                            >
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><b>$0</b></td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td>
                                            Annual Expected Pre-Tax Return
                                            <div className={classes.infoIconNew} data-for="showTooltip" data-tip="This tool asks, 'What if I refinanced my student loans and invested the annual savings?' When you invest your annual savings, you will earn a return on those invested dollars. Input the percentage return you might expect to earn on these investments. Anywhere from 4-6% would be a good conservative estimate.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.rsInput}>
                                            <input type="text" />
                                            <span className={classes.rsPercent}>%</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                        <tr>
                                          <td className={classes.heightField} />
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className={classes.showGraph}>
                              <div className={classes.descriptionRow}>
                                <div className={classes.graphBlock}>
                                  <h3>Invested Annual Savings</h3>
                                  <hr className={classes.blueRow} />
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={module18Dynamicbargraph()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not Sure of Your Details or Seem Overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links Will Provide all the Information You Need and More</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Existing Assets & Liabilities
                    </h3>
                  </div>
                  <div className={classNames(classes.panelRow, classes.panelMargin)}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.autoCursor}>
                        <span className={classes.pointerCursor} aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How does the &apos;What If Refinancing&apos; tool work in the Show Me section?</h3>
                          <p>
                            The &apos;What If Refinancing&apos; tool in the Show Me section allows you to ask, &quot;What if I refinanced my existing student loans and got a lower rate or a different term of the loan?&quot; Just enter your existing student loans into the My Details section and save that scenario. Then, go to the Show Me section and you&apos;ll find the &apos;What If Refinancing&apos; tool has consolidated and loaded your existing student
                            loan data. Then, enter a new interest rate and a new term of the loan to see how that changes your repayment figures.
                          </p>
                          <p>There&apos;s many reasons to consider refinancing:</p>
                          <p>
                            <span>1) Consolidate multiple loans into one loan, which means you make one payment to one loan servicer</span>
                            <span>2) Potential to receive a lower interest rate, which would lower your total interest paid</span>
                            <span>3) Potential to change the term of the loan to a lower or higher number of years, depending on your need</span>
                          </p>
                          <p>Check out the Quick Links section for some good resources on student loan refinancing.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How is the Monthly Payment calculated for Existing Student Loans?</h3>
                          <p>
                            The Monthly Payment is calculated based on a number of factors: Beginning Balance, Term of the Loan, and Interest Rate. For Federal Subsidized Loans, there is no Accrued Interest so the Beginning Balance will always be the total amount you have borrowed.
                            However, for Federal Unsubsidized Student Loans and Private Student Loans, if you choose to Defer Interest Payments, interest will accrue on a monthly basis. Depending on how many months are left until repayment begins, we calculate how much interest will accrue until repayment. The Total Accrued Interest is added to the Outstanding Loan
                            Amount and that is your Beginning Balance. Then, based on the Term of the Loan and Interest Rate, the FC calculates the Monthly Payment that is required to repay the loan in the appropriate time frame. If you feel you can afford to pay your
                            loan down sooner, shorten the Term of the Loan and see how that might affect your Monthly Payment.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How can I see the numbers that support the Existing Student Loans graphs in the Show Me section?</h3>
                          <p>Just click anywhere on any of the Existing Student Loans graphs in the Show Me section and a table will pop up allowing you to look at the repayment of your Student Loans month by month.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>If I want to repay my loans by a certain date, how can I adjust my details above to do that?</h3>
                          <p>Simple. Just adjust the Term of the Loan (in months) or Months to Maturity. You can change that input to the number of months that gets you to your desired repayment date. As you change that input, the FC instantly configures how much you will have to pay monthly as well as on a total basis to hit that target. See how changing this input can impact your repayment schedule as well as your 5, 10, 15 year number.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the credit card repayment work?</h3>
                          <p>
                            The FC lets you repay your Current Credit Card Balance and see how that impacts your 5, 10, 15 year plan. Think of your credit card balance like a simple loan that has a balance and an interest rate. The moving piece here is that you decide the Ideal Repayment
                            Schedule by inputting the number of months. Once you input the number of months that you would like to have your balance paid off by, the FC instantly calculates your monthly payment to achieve that schedule. It&apos;s a great tool when budgeting how much you can afford to pay each month. Pick different repayment schedules and make sure you stay cash flow positive.
                          </p>
                          <p>It&apos;s important to understand that the FC does not assume that you carry a credit card balance. Your Current Credit Card Balance can only be repaid and cannot be added to. In the FC, all expenses are assumed to be paid in cash.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>What if I want to trade-in my existing car for a new one? Does the FC account for this? Where can I do that?</h3>
                          <p>If you have an Existing Car and you go to buy your first car in the Buying a Car module, you can opt to trade that in to lower the cost of buying a car. If you select &quot;Yes&quot; for Trade-In when completing Buying a Car, the FC will assume you are trading in your Existing Car. That will take your Existing Car value to 0.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>When I go to sell my existing home, how is the Sale Price calculated?</h3>
                          <p>
                            When you select to sell your Existing Home, the Sale Price depends on the Sale Year. Each year, the FC appreciates your home by the annual appreciation figure you input into the module. Your home value will grow, or appreciate, based on your current home value. If you sell your home,
                            the FC assumes the Sale Price happens on the last day of the year and gives you full credit for appreciation in that year. For example, if your home is worth $500,000 today in Year 2018, appreciation in your area is roughly 5%, and you go to sell the home in 2019 or 2 years, the Sale Price calculation would be as follows:
                          </p>
                          <p>
                            <span>2018: $500,000 x (1+5% appreciation) = $525,000 at the end of Year 2018</span>
                            <span>2019: $525,000 x (1+5% appreciation) = $551,250 at the end of Year 2019</span>
                            <span>Sale Price = $551,250</span>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>When I go to sell my existing home, how do taxes come into play? Why do I have a gain but no tax on that gain?</h3>
                          <p>On May 6, 1997, the Taxpayer Relief Act of 1997 went into effect and changed the tax scene around home ownership dramatically. Under the old rules, you could defer taxes so long as you sold your home and then bought a new home that was higher in value right after.</p>
                          <p>
                            Under the new rules, you can now sell your home and exclude up to $250,000 of profits on the home sale if you are single. That exclusion jumps to $500,000 for married couples. There are some conditions.. 1) You must&apos;ve owned the home as your primary residence for at least two out of the last five years,
                            and 2) You cannot have used this exclusion on a different home sale within the same two year period. The FC assumes when you go to sell your Existing Home, both of these are true.
                          </p>
                          <p>
                            So what does this exclusion mean exactly? Easiest to explain with an example of a couple selling their home. Let&apos;s assume your Basis (see Definitions) in your home is $250,000. You sell your home for $500,000 and
                            had $25,000 of Selling Costs. Your gain on that sale would be equal to $225,000 (calculation below). Under the new rules, this gain would be excluded from your taxable income. You would pay no taxes on this sale since the profits are below the $500,000 threshhold.
                          </p>
                          <p>$500,000 Sale Price - $25,000 Selling Costs - $250,000 Basis = $225,000 Gain</p>
                          <p>If the profits of the sale were $600,000 for a married couple, the $100,000 amount above the $500,000 threshhold would be taxed at the capital gains rate of 15%.</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.autoCursor}>
                        <span className={classes.pointerCursor} aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3># of Years Loans Were Taken</h3>
                          <p>
                            The number of years loans were taken is an important number for the FC to understand when calculating the limits of Federal Student Loans you can take going forward. This input should reflect how many years you have been going to Higher Education. For example, if you are a rising junior in College with Existing Student Loans, you would have spent 2 years in College. Your loan limits will change based on the amount of loans you have taken. Take a look at loan limits
                            {' '}
                            <a href="https://www.edvisors.com/media/images/article-charts/direct-loans-annual-borrowing-limits-bar-chart-1700x1142.png" target="_blank" rel="noopener noreferrer">here.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">Accrued Interest</a></h3>
                          <p>
                            Interest typically begins to accrue as soon as you receive your loan depending on the type of student loan. In the FC, interest accrues on a monthly basis for unsubsidized and private student loans. Each month the beginning balance (the amount of loans you have to repay) multiplied by the interest rate divided by 12 (to convert to a monthly rate) is the minimum interest payment that needs to be made at the end of the month to avoid accrued interest.
                            If you don&apos;t pay that minimum interest,
                            that amount will accrue and be capitalized (added to your loan balance). If you can afford to make minimum interest payments on your student loans while you&apos;re in school, plan to make them as this could save you thousands of dollars in accrued interest.
                            Watch this
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer" className={classes.noLink}>video here</a>
                            {' '}
                            for a great explanation of interest!
                          </p>
                          <p>
                            For example, let&apos;s say you have a $10,000 loan balance at the beginning of this month. The interest rate on that loan is 6%, which is an annual number. At the end of the month, assuming you don&apos;t plan to make any payment, the $10,000 would be multiplied by 6% and divided by 12 (interest rates are annual numbers so divide by 12 to make that a monthly number) and get $50 of accrued interest. Now, at the end of that month, your beginning balance of
                            $10,000 plus $50 of accrued interest equals a higher $10,050 loan balance. And, the cycle repeats itself with interest growing every month you don&apos;t make the minimum interest payment. Review the formula and table below to understand how to calculate accrued interest if you do not make a payment on your loan.
                          </p>
                          <p>
                            <span>If a loan accrues on a monthly basis, payment is due monthly, no payment is made, and the interest is capitalized at the end of the month:</span>
                            <span>Beginning Balance x Annual Interest Rate / 12 = Monthly Accrued Interest</span>
                            <span>$10,000 x 6% / 12 = $50.00 Monthly Accrued Interest</span>
                          </p>
                          <p>
                            <span>Beginning Balance + Accrued Interest Capitalized = Next Month Beginning Balance</span>
                            <span>$10,000.00 + $50.00 = $10,050.00 Next Month Beginning Balance</span>
                          </p>
                          <p>How does this add up over months or years of schooling? In 6 months, your balance has grown to $10,303; in 12 months, $10,616; and, in 18 months without making a minimum interest payment, the balance grows by almost $1,000! Again, if you can afford to make minimum interest payments on your student loans while you&apos;re in school, plan to make them as this could save you thousands of dollars in accrued interest.</p>
                          <div aria-hidden="true" className={classNames(classes.heImage, classes.fullWidth)} onClick={() => handleImagePopup(accruedInterest)}>
                            <img src={accruedInterest} alt="" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/ask/answers/12/what-is-an-asset.asp" target="_blank" rel="noopener noreferrer">Asset</a></h3>
                          <p>Anything that you own that has value and can be converted into cash. For example, your car is an asset. The cash in your pocket is an asset. In Inkwiry, there are two types of assets: tangible assets and investments. You can find your assets on the balance sheet.</p>
                          <p>
                            <a href="https://www.thesimpledollar.com/personal-finance-101-what-is-an-asset/" target="_blank" rel="noopener noreferrer">Tangible assets</a>
                            {' '}
                            {' '}
                            {' '}
                            are cash, cars, and real estate property. Investments are retirement accounts such as 401(k), Roth IRA, SEP IRA, or Pension. There are also short-term investments and long-term investments.
                          </p>
                          <p>Remember, assets minus liabilities equals net worth. Or, more simply put, what you own minus what you owe equals net worth.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/a/auto-insurance.asp" target="_blank" rel="noopener noreferrer">Car Insurance</a></h3>
                          <p>
                            Monthly costs, known as a premium, paid to an insurance company in case of an accident. Instead of paying with your own money, or out of pocket,
                            your insurance company will then pay for some or all of the costs of your accident depending on a number of different factors. See why car insurance is
                            {' '}
                            <a href="https://www.thebalance.com/what-is-car-insurance-for-527458" target="_blank" rel="noopener noreferrer" className={classes.noLink}>important here</a>
                            . Also, check out
                            {' '}
                            <a href="http://www.insure.com/car-insurance/car-insurance-rates.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>average annual car insurance by state</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Car Maintenance</h3>
                          <p>
                            The costs incurred to keep your car in good condition and good working order. Maintenance includes
                            {' '}
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/routine-car-maintenance-cost/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>general repairs</a>
                            {' '}
                            such as oil changes, tire rotations and new tires, inspections, and many more. Budget about $100 per month or $1,200 per year
                            {' '}
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/car-maintenance-bills/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>according to AAA</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentloanhero.com/featured/capitalized-interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">Capitalized Interest</a></h3>
                          <p>Interest starts to accrue the day your loan is given to you. In the FC, interest accrues on a monthly basis. Before reviewing this definition, make sure you review the accrued interest definition above.</p>
                          <p>
                            If you choose to defer interest payments until repayment begins, which means you are choosing not to make interest payments while you are in school, that interest will accrue and capitalize at the end of each month. Capitalize means that accrued interest is added to your loan&apos;s current principal (see definition below). When interest capitalizes, you now have a larger loan balance. And, your interest will now be calculated off that larger loan balance.
                            If you don&apos;t make minimum interest payments, this process will continue to repeat and your loan balance will grow larger. If you can afford to make interest payments during school, it could save you thousands of dollars over the life of the loan. Easiest to see in an example.
                          </p>
                          <p>Let&apos;s say we have two different loans: Loan A and Loan B. They share the same $10,000 beginning balance, 8% interest rate, 10 year maturity, and are paid on a monthly basis. Both loans will start the repayment period after 5 years. Loan A shows the effects of not making minimum interest payments during that 5 year period and Loan B the effects of making the $800 minimum interest payments each year.</p>
                          <p>In the following graph, you can see that each year interest is accruing at a faster rate. And, you can see in the below table that Loan B pays much less over the life of the loan than Loan A.</p>
                          <div aria-hidden="true" className={classNames(classes.heImage, classes.fullWidth)} onClick={() => handleImagePopup(capitalizedInterestGraph)}>
                            <img src={capitalizedInterestGraph} alt="" />
                          </div>
                          <div aria-hidden="true" className={classes.heImage} onClick={() => handleImagePopup(capitalizedInterestTable)}>
                            <img src={capitalizedInterestTable} alt="" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.learnhowtobecome.org/vocational-trade-schools/" target="_blank" rel="noopener noreferrer">Career School</a></h3>
                          <p>Schools, institutions, and educational programs that specialize in the skilled trades, applied sciences, modern technologies, and career preparation.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.creditkarma.com/credit-scores" target="_blank" rel="noopener noreferrer">Credit Score</a></h3>
                          <p>
                            A three-digit number ranging from 300 - 850 (higher the better) that relates to how likely you are to repay debt. Your Credit Score is very important to maintain and improve as banks and lenders will use your score when deciding whether or not to approve you for a credit card or loans such as auto loans, student loans, and mortgages. According to Credit Karma, a good credit score is
                            {' '}
                            <a href="https://www.creditkarma.com/what-is-a-good-credit-score" target="_blank" rel="noopener noreferrer" className={classes.noLink}>700 and higher</a>
                            . Usually you can check your credit score with your credit card company. If not, check for free with
                            {' '}
                            <a href="https://www.creditkarma.com/free-credit-score" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Credit Karma</a>
                            {' '}
                            or
                            {' '}
                            <a href="https://www.experian.com/consumer-products/free-credit-report.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Experian</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://en.wikipedia.org/wiki/College" target="_blank" rel="noopener noreferrer">College</a>
                            {' '}
                          </h3>
                          <p>An institution of higher learning that follows High School and offers a bachelor&apos;s degree - also known as undergraduate School.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Current Loan Balance</h3>
                          <p>For Federal Unsubsidized Student Loans and Private Student Loans, the Current Loan Balance is the Outstanding Loan Amount plus Existing Accrued Interest. For Federal Subsidized Student Loans, there is no Accrued Interest so your Current Loan Balance will equal your Outstanding Loan Amount.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/d/dti.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Debt-to-Income Ratio</a>
                            {' '}
                          </h3>
                          <p>
                            Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year. This ratio indicates the percentage of gross income being accounted for debt repayment and the
                            percentage of gross income left over for other household expenses and savings. You can find the debt-to-income ratio on the dashboard or on the summary financials.
                          </p>
                          <p>The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentloanhero.com/featured/how-student-loan-Interest-works/" target="_blank" rel="noopener noreferrer">Defer Interest Payments</a>
                            {' '}
                          </h3>
                          <p>
                            When you do not make minimum Interest payments while you are in school or in a Grace Period. Each month you Defer Interest Payments, that Interest accrues,
                            {' '}
                            <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>capitalizes</a>
                            , and adds to your balance.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Existing Accrued Interest</h3>
                          <p>
                            The amount of accrued interest you have accumulated on federal unsubsidized student loans or private student loans. If you defer interest payments on your student loans, you will have existing accrued interest. It&apos;s important to know how much interest has accrued on your loan because accrued interest will drive higher monthly payments when
                            it comes time to repay that loan. You can find your existing accrued interest by contacting your loan servicer. Federal loan servicers can be found
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/repay-loans/understand/servicers" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            . For private student loans, you will need to contact the financial institution that provided you with that student loan.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/glossary#letter_f" target="_blank" rel="noopener noreferrer">Federal Student Loan</a>
                            {' '}
                          </h3>
                          <p>A loan funded by the federal government to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans.</p>
                          <p>
                            <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                            {' '}
                            {' '}
                            {' '}
                            from the Federal Student Aid office for a fact sheet on federal student loans.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/glossary#letter_s" target="_blank" rel="noopener noreferrer">Federal Subsidized Student Loan</a>
                            {' '}
                          </h3>
                          <p>A loan funded by the federal government to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans.</p>
                          <p>
                            The federal subsidized student loan has one crucial difference than other loans. The government pays the interest while you are in school and in a grace period. What does that mean exactly? That means no interest will accrue on your loan until repayment period begins. For example, if you borrow $10,000 in federal subsidized student loans during higher education, you will have to repay $10,000. For other loans,
                            that balance has the potential to grow because of accrued interest.
                          </p>
                          <p>
                            <a href="https://studentaid.ed.gov/sa/types/loans/interest-rates" target="_blank" rel="noopener noreferrer">Click here to check current interest rates</a>
                            . Then, use CTRL + F to search for &quot;Direct Subsidized Loans and Direct Unsubsidized Loans&quot; on that page. This will take you directly to the current interest rate for federal subsidized and unsubsidized loans and the current fees for those loans.
                          </p>
                          <p>
                            <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                            {' '}
                            {' '}
                            {' '}
                            from the Federal Student Aid office for a fact sheet on federal student loans.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/glossary#letter_g" target="_blank" rel="noopener noreferrer">Grace Period</a>
                            {' '}
                          </h3>
                          <p>
                            A six-month period after you graduate, leave school, or drop below half-time status before your student loan repayment period begins. For example, if you graduate at the end of May, you will have to start repaying your student loan six months after that, which would be the beginning of December. It&apos;s important to note that interest on unsubsidized and
                            private student loans continues to accrue even during the grace period. Again, if you can afford in your higher education scenario to make interest payments before your repayment begins that could save you thousands of dollars in accrued interest.
                          </p>
                          <p>
                            Review the definition of accrued interest above to understand how quickly
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">accrued interest</a>
                            {' '}
                            can add to your student loan balance.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://en.wikipedia.org/wiki/Graduate_school" target="_blank" rel="noopener noreferrer">Graduate School</a>
                            {' '}
                          </h3>
                          <p>A school that awards advanced academic degrees (i.e. master&apos;s and doctoral degrees) with the general requirement that students must have earned a previous undergraduate (bachelor&apos;s) degree.</p>
                          <p>
                            <a href="https://www.princetonreview.com/grad-school-search" target="_blank" rel="noopener noreferrer">Use this link</a>
                            {' '}
                            {' '}
                            {' '}
                            to search for graduate schools.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/h/hoa.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Home Association Fees</a>
                            {' '}
                          </h3>
                          <p>
                            Monthly fee that is paid by owners of certain types of residential properties for the general upkeep, maintenance, and improvement of properties such as lawn care, snow removal, trash removal, and any other maintenance activities of common areas. This fee varies based off size and amenities of the property as well as the type and location of the community. However, Annual Association Fees
                            {' '}
                            <a href="http://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>usually range</a>
                            {' '}
                            {' '}
                            {' '}
                            from $200 to $400 a month or $2,400 to $4,800 per year!
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Home Appreciation</h3>
                          <p>
                            The increase in a property&apos;s value over time. How much the property appreciates each year depends on the local real estate market and any improvements made to the property. For example, a property that is purchased for $200,000.00 and appreciates at 2.5% annually would be worth $327,723.29 after 20 years.
                            The
                            {' '}
                            <a href="https://www.cnbc.com/2017/06/23/how-much-housing-prices-have-risen-since-1940.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>median home value</a>
                            {' '}
                            {' '}
                            in the U.S. has gone from
                            {' '}
                            <a href="https://www.census.gov/hhes/www/housing/census/historic/values.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>$2,938 in 1940 to $119,600 in 2000</a>
                            {' '}
                            {' '}
                            {' '}
                            to $199,200 in 2017! That would put historical appreciation from 1940 to 2017 at a staggering 5.63%. Now history is not always the best indicator of the future, but that does give you a reference as to the history of housing prices in the U.S.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.nolo.com/legal-encyclopedia/determining-your-homes-tax-basis.html" target="_blank" rel="noopener noreferrer">Home Basis</a>
                            {' '}
                          </h3>
                          <p>The amount your home is worth for tax purposes. When you go to sell your home, the gain on your property is determined by taking the Sale Price minus Selling Costs minus your Basis. Higher your basis, lower your tax liability. But, the IRS allows you to take a $250,000 gain if single and a $500,000 gain if married on your home tax-free. Every dollar over those limits will be taxed at the capital gains rate of 15%.</p>
                          <p>
                            How do you calculate your basis? Simple.. Your initial basis would be equal to the Purchase Price plus Closing Costs plus any Fit Out Costs paid for at sale. Then, to get your adjusted basis, increase your initial basis for the cost of any additions or improvements (additional Fit Out Costs), restoration costs due to fire or theft or other casualty,
                            any tax credits received after 2005, any cost of extending utility service lines to the property, and any legal fees relating to the property. Finally, reduce your adjusted basis for insurance payments received due to casualty or theft, gain you postponed (meaning you were not taxed on) before May 7, 1997, any loss not covered by insurance due to casualty, and any amount you receive for an easement on your property (an easement is you allowing
                            someone to use a portion of your property for a specific purpose).
                          </p>
                          <p>Understanding your Basis is crucial for any homeowner and essential when considering a sale.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Home Maintenance</h3>
                          <p>
                            The costs incurred to keep a property in good condition and good working order. Maintenance includes
                            {' '}
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>general repairs</a>
                            {' '}
                            {' '}
                            such as fixing doors, leaky faucets, monthly cleaning service, lawncare and snow removal, maintaining paint, and can also include some major repairs such as replacing a refrigerator or roof. A good
                            {' '}
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer" className={classes.noLink}>rule of thumb</a>
                            {' '}
                            {' '}
                            is to take 1-2% of the Purchase Price as an estimate for Annual Maintenance or $1-$2 for every square foot.
                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/h/homeowners-insurance.asp#ixzz4jL52gtFj" target="_blank" rel="noopener noreferrer">Homeowner&apos;s Insurance</a>
                            {' '}
                          </h3>
                          <p>Property insurance that covers losses and damages to your house and to assets in the property. Homeowner&apos;s Insurance also provides liability coverage against accidents in or on the property. According to the Federal Reserve Bureau, Homeowner&apos;s Insurance can be estimated by multiplying the Purchase Price by 0.35%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Ideal Repayment Schedule (in Months)</h3>
                          <p>The length of time you choose to pay off your credit card in months. Credit cards do not have a specific repayment timeline. It is up to you to repay your credit cards! You should aim to repay your credit card as quickly as possible. Change the repayment schedule in months and see how that affects your monthly payment.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.gov/help-center/answers/topic/glossary/articles#letter_i" target="_blank" rel="noopener noreferrer">Interest</a>
                            {' '}
                          </h3>
                          <p>
                            The fee a lender charges you for borrowing money. Monthly loan payments are made up of two parts: principal repayment and interest. Interest is a
                            {' '}
                            <a href="https://studentloanhero.com/featured/how-student-loan-Interest-works/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>loan expense</a>
                            {' '}
                            {' '}
                            calculated as a percentage of the outstanding principal. See an example of a typical loan amortization schedule
                            {' '}
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            . Amortization is the process of paying off your loans in regular payments over a period of time.
                          </p>
                          <p>Interest is calculated by multiplying the outstanding principal by the interest rate. For example, let&apos;s say we have a $6,000 Federal Subsidized Student Loan outstanding with a 5% annual interest rate that we pay on a monthly basis. The calculation for next month&apos;s interest payment would be as follows:</p>
                          <p>$6,000 Balance x 5% Interest Rate / 12 (to make the interest rate on a monthly basis) = $25</p>
                          <p>Don&apos;t forget, as you make monthly payments and start paying down your loan, your outstanding balance will decrease, which will decrease your interest portion of the payment.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest Rate</a>
                            {' '}
                          </h3>
                          <p>The amount a lender charges you for using their money, typically expressed as an annual percentage of the principal - loan amount outstanding. Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank, it’s very similar to renting an apartment.</p>
                          <p>Instead of borrowing a place to live, you are borrowing money usually from a bank. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank uses the interest rate in the formula below:</p>
                          <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Liability</h3>
                          <p>Money you owe to another person or institution. For example, student loans, car loans, and mortgages are all liabilities. In Inkwiry, there are four types of liabilities: credit card debt, student loans, car loans, and mortgages. You can find your liabilities on the balance sheet.</p>
                          <p>Remember, assets minus liabilities equals net worth. Or, more simply put, what you own minus what you owe equals net worth.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentloanhero.com/featured/how-student-loan-Interest-works/" target="_blank" rel="noopener noreferrer">Make Interest Payments</a>
                            {' '}
                          </h3>
                          <p>Making monthly minimum Interest payments on the loan while you are in school or in a Grace Period. By doing this, no Interest will accrue to your total balance.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.usa.gov/military-Colleges" target="_blank" rel="noopener noreferrer">Military School</a>
                            {' '}
                          </h3>
                          <p>An educational institution which prepares candidates for service in the officer corps of the army, the navy, marine corps, air force or coast guard.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Months Until Repayment Begins</h3>
                          <p>The number of months until you have to begin repaying this Student Loan.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/m/mortgage.asp" target="_blank" rel="noopener noreferrer">Mortgage</a>
                            {' '}
                          </h3>
                          <p>
                            A loan that a bank or mortgage lender gives you to help finance the purchase of real estate that bears an Interest Rate and requires repayment. The Mortgage amount is calculated by taking the Purchase Price less the Down Payment. Mortgage interest is tax deductible on the first $750,000 of your mortgage. See the Tax Statement for more details on how the FC calculates this deduction and this link
                            {' '}
                            <a href="http://mazarsledger.com/article/the-home-mortgage-interest-deduction/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            {' '}
                            for more information. Note, the FC does not take into account home equity loans, which is why the limit is $750,000.
                          </p>
                          <p>
                            Prior to
                            {' '}
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer">2018 tax reform</a>
                            , the mortgage value limit was $1,000,000. Now, on all homes purchased in or later than 2018, the mortgage value limit was dropped to $750,000. However, for tax purposes, the FC assumes all homes are subject to the $750,000 limit.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Outstanding Loan Amount</h3>
                          <p>If you have already begun repaying your student loans, the outstanding loan amount would be the amount of money that still needs to be repaid. If you have already begun repaying your student loans, enter the total amount you still owe in this field.</p>
                          <p>If you have not begun repaying your student loans, then the outstanding loan amount would be the total sum of money borrowed and does not include accrued interest. For example, if you borrowed $10,000 in private student loans and have $500 of existing accrued interest, your outstanding loan amount would be $10,000. The $500 of accrued interest should be entered in the existing accrued interest field.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/glossary#letter_p" target="_blank" rel="noopener noreferrer">Principal</a>
                            {' '}
                          </h3>
                          <p>
                            The total sum of money borrowed. For unsubsidized student loans or private student loans, principal is the total sum of money borrowed plus any accrued interest that has been
                            {' '}
                            <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>capitalized</a>
                            .
                          </p>
                          <p>Every time you make a monthly payment, a portion of that payment goes toward the interest and the rest is used to repay your principal.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.valuepenguin.com/mortgage-insurance-pmi" target="_blank" rel="noopener noreferrer">Private Mortgage Insurance (PMI)</a>
                            {' '}
                          </h3>
                          <p>
                            When you make a Down Payment of less than 20% on real estate, the lender requires Private Mortgage Insurance, or PMI. The insurance protects the lender from losing money if you cannot repay your Mortgage. Once you have 20%
                            {' '}
                            <a href="https://www.bankrate.com/home-equity/what-is-home-equity/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>equity</a>
                            {' '}
                            {' '}
                            of the real estate, you are no longer considered high risk and can request that the mortgage insurance policy be cancelled. Equity is the difference between how much the real estate is worth and how much you owe on the Mortgage.
                            Once your Mortgage balance is less than 80% of the real estate value - remember that your real estate value will go up every year by the Annual Appreciation rate you set - the FC will assume you no longer have to pay insurance and stop those payments. By law, mortgage lenders must cancel PMI once you own 22% of the real estate. PMI typically ranges between 0.3% - 1.5% of the original Mortgage. These fees are
                            {' '}
                            <a href="https://www.houselogic.com/finances-taxes/taxes/deducting-private-mortgage-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>sometimes tax deductible</a>
                            {' '}
                            {' '}
                            - see the Tax Statement for more details.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://studentaid.ed.gov/sa/glossary#letter_p" target="_blank" rel="noopener noreferrer">Private Student Loans</a>
                            {' '}
                          </h3>
                          <p>A loan provided by banks, credit unions or other lending institutions to help pay for your education that charges an interest rate and requires repayment. This loan will usually charge the highest interest rate of all student loans.</p>
                          <p>
                            Unlike federal subsidized loans, interest on
                            {' '}
                            <a href="http://www.investopedia.com/university/student-loans/student-loans3.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                            {' '}
                            {' '}
                            {' '}
                            accrues starting from the day you receive any money and continues throughout the life of the loan until that loan is fully repaid. Interest accrues on a monthly basis. And, unlike federal unsubsidized loans, interest
                            {' '}
                            <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">capitalizes</a>
                            {' '}
                            {' '}
                            at the end of each month. Every month that you don&apos;t make payments including during higher education, you are accruing interest. That accrued interest
                            {' '}
                            <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">capitalizes</a>
                            {' '}
                            {' '}
                            every month, or more simply put, is added to your loan balance. Go to the accrued interest definition above to see how accrued interest works and how quickly it can affect your loan repayment.
                          </p>
                          <p>
                            <a href="https://www.credible.com/student-loans" target="_blank" rel="noopener noreferrer">Click here to check current interest rates</a>
                            . Click on &quot;Get My Loan&quot; in the &quot;I&apos;m a student&quot; section and go through the process to find
                            {' '}
                            <a href="http://www.investopedia.com/university/student-loans/student-loans3.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                            {' '}
                            {' '}
                            that work for you. To determine how much
                            {' '}
                            <a href="http://www.investopedia.com/university/student-loans/student-loans3.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                            {' '}
                            {' '}
                            {' '}
                            you might need, build your entire higher education scenario without any
                            {' '}
                            <a href="http://www.investopedia.com/university/student-loans/student-loans3.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                            . Then, see how much more money you need each year to finance your education.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/propertytax.asp#ixzz4jL4PM7gn" target="_blank" rel="noopener noreferrer">Property Taxes</a>
                            {' '}
                          </h3>
                          <p>
                            The tax
                            {' '}
                            <a href="http://www.investopedia.com/articles/tax/09/calculate-property-tax.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>assessed</a>
                            {' '}
                            {' '}
                            on real estate each year based on the value of the property you own. Your local or municipal government will assess the value of your property and determine the amount owed. The average property tax in the U.S. is
                            {' '}
                            {' '}
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>approximately 1.31%</a>
                            {' '}
                            {' '}
                            {' '}
                            of the property&apos;s value.
                          </p>
                          <p>Property taxes are tax deductible. Due to 2018 tax reform, property taxes plus state and local income taxes are capped at a $10,000 max deduction. See the Tax Statement for more details.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/s/student-debt.asp" target="_blank" rel="noopener noreferrer">Student Loan</a>
                            {' '}
                          </h3>
                          <p>
                            Money owed on a loan taken out to help pay for your education that bears an Interest Rate and requires repayment. There are limits on the amount of Student Loans available during
                            {' '}
                            <a href="https://www.edvisors.com/College-loans/terms/loan-limits/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Undergraduate</a>
                            {' '}
                            {' '}
                            {' '}
                            and
                            {' '}
                            <a href="https://www.edvisors.com/College-loans/federal/stafford/graduate/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Graduate</a>
                            {' '}
                            {' '}
                            {' '}
                            programs.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Student Loan Interest Tax Deduction</h3>
                          <p>
                            A number of factors are used to determine if you are qualified to take a Student Loan Interest Tax Deduction such as how much annual income you have earned or if you paid back any capitalized Interest in the year. Please click
                            {' '}
                            <a href="https://www.nolo.com/legal-encyclopedia/direct-indirect-ffel-student-loans-whats-the-difference.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            for a detailed understanding of possible Student Loan Tax Deductions that Inkwiry&apos;s FC calculates for you.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/t/termtomaturity.asp" target="_blank" rel="noopener noreferrer">Term of the Loan (in months) or Months to Maturity</a>
                            {' '}
                          </h3>
                          <p>The length of time you choose to pay off your loan in months. Student loans typically give 10 years or 120 months until you have to repay that loan. If you have already begun repaying your student loan, then the months to maturity is how many months are remaining to pay off your loan. For example, if you took a 10 year student loan and you have been repaying it for 2 months, there would be 118 months to maturity.</p>
                          <p>If you are in still in school, calculate this number with the following formula. You need to count the number of months until graduation and then add six more months for the grace period. Remember, the grace period is usually a six month period after you graduate (or stop going to school) that you have before repayment begins.</p>
                          <p># of Months Until Graduation + Six Months</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Principal & Interest</h3>
                          <p>Calculated by taking the principal, or total sum of money borrowed, plus the total interest, which is the total cost of borrowing the principal. In other words, the total principal and interest is the amount of money you would pay over the term of the loan to fully repay your credit card, student loan, car loan, or mortgage.</p>
                          <p>See the definitions for principal and interest above.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.nationwide.com/average-cost-of-utilities.jsp" target="_blank" rel="noopener noreferrer">Utilities</a>
                            {' '}
                          </h3>
                          <p>The total price you pay for water, electricity and gas, trash and recycling, home phone, and internet and cable. Some or all utilities can be included in your rent when renting a home or an apartment. If you own real estate, you will be responsible for paying all utilities.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Weighted Average Interest Rate</h3>
                          <p>The aggregate (which means a whole formed by combining several parts) rate of interest paid on all debt expressed as a percentage. To calculate the weighted average interest rate, sum all interest payments in the year and divide that by the total amount of debt. See the formula below:</p>
                          <p>Total Interest Payments / Total Debt Outstanding = Weighted Average Interest Rate</p>
                          <p>For example, a person has a $10,000 Federal Subsidized Student Loan that has an interest rate of 5% and a $20,000 Private Student Loan that has an interest rate of 8%. The total interest for the year would be calculated as the following:</p>
                          <p>($10,000 x 5%) + ($20,000 x 8%) = $2,100</p>
                          <p>Then, the weighted average interest rate would be calculated as the following:</p>
                          <p>$2,100 / $30,000 = 0.07 or 7%</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Weighted Average Years to Maturity</h3>
                          <p>The weighted average number of years until multiple loans have to be repaid. To calculate the weighted average years to maturity, first calculate the percentage value of each loan as part of the total loans. Easiest to show with the formula below:</p>
                          <p>Student Loan A / Total Student Loans = Percentage Value, also known as &quot;Weight&quot;</p>
                          <p>Let&apos;s walk through an example. A person has a $10,000 Federal Subsidized Student Loan that has a maturity of 6 years and a $20,000 Private Student Loan that has a maturity of 8 years. The weight for each loan would be calculated as the following:</p>
                          <p>
                            <span>Federal Loan = $10,000 / $30,000 = 1/3 or 33.33%</span>
                            <span>Private Loan = $20,000 / $30,000 = 2/3 or 66.66%</span>
                          </p>
                          <p>Then, the weighted average years to maturity would be calculated as the following:</p>
                          <p>
                            <span>Federal Loan = 1/3 x 6 years = 2 years</span>
                            <span>Private Loan = 2/3 x 8 years = 5.33 years</span>
                            <span>Weighted Average Years to Maturity = 2 + 5.33 = 7.33 years</span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.autoCursor}>
                        <span className={classes.pointerCursor} aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Student Loans</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.edvisors.com/college-loans/terms/loan-limits/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much loans can dependent and independent students take?</a>
                            <a href="https://studentaid.ed.gov/sa/types/loans/subsidized-unsubsidized" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Federal Subsidized and Federal Unsubsidized Student Loans - interest rates, origination fees, and more</a>
                            <a href="https://studentloanhero.com/featured/whats-the-difference-between-subsidized-and-unsubsidized-student-loans/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Difference between Subsidized and Unsubsidized Student Loans</a>
                            <a href="https://www.cnbc.com/2017/07/18/student-loan-interest-rates-edge-higher-and-higher.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average interest rate of Private Student Loans</a>
                            <a href="https://studentaid.ed.gov/sa/types/loans/interest-rates" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Federal Student Loans&apos; interest and fees</a>
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a loan get repaid? What is amortization?</a>
                            <a href="https://www.thebalance.com/what-is-an-amortization-table-315520" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What does an example repayment schedule look like?</a>
                            <a href="https://studentloanhero.com/featured/how-student-loan-interest-works/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Understanding student loan interest</a>
                            <a href="https://www.commonbond.co/post/why-its-a-good-idea-to-make-in-school-student-loan-payments" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Benefits of making interest payments during school and grace period</a>
                            <a href="https://studentloanhero.com/featured/student-loan-definitions-terms-to-know/#principalloan" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Understanding student loan principal</a>
                            <a href="https://studentloanhero.com/featured/hidden-student-loan-fees/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Origination fees and other hidden student loan costs</a>
                            <a href="https://studentaid.ed.gov/sa/repay-loans/understand" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Grace period, repayment and more</a>
                            <a href="https://studentloanhero.com/5-banks-to-refinance-your-student-loans-0087/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Where to Refinance Student Loans Today</a>
                            <a href="https://studentloanhero.com/featured/how-to-refinance-student-loans/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to Refinance Student Loans Today</a>
                            <a href="https://www.thebalance.com/refinancing-federal-student-loans-4136471" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is student loan refinancing?</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Existing Car</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.kbb.com/whats-my-car-worth/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Determine Your Car&apos;s Worth</a>
                            <a href="http://www.insure.com/car-insurance/car-insurance-rates.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average annual car insurance by state</a>
                            <a href="http://www.automotive-fleet.com/channel/maintenance/article/story/2017/10/maintenance-costs-remain-flat-but-labor-rates-rise.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average car maintenance per month (first graph on the left)</a>
                            <a href="https://inflationdata.com/articles/cost-of-living/cost-of-gas/average-cost-of-gas-per-month/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimate your monthly gas cost with this simple calculator</a>
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/trade-in-car-when-you-owe-money/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What happens if I trade my car in and it still has a loan balance?</a>
                            <a href="https://instamotor.com/buy-used-car/a-glossary-of-car-buying-terms" target="_blank" rel="noopener noreferrer" className={classes.noLink}>General glossary of car terms</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Credit Card Debt</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.consolidatedcredit.org/credit-card-debt/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What you need to know and more</a>
                            <a href="https://www.thebalance.com/how-to-read-your-credit-score-960488" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to read your credit score</a>
                            <a href="https://www.thebalance.com/reasons-why-good-credit-matters-960178" target="_blank" rel="noopener noreferrer" className={classes.noLink}>5 reasons why good credit would matter</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Home Appreciation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://resources.point.com/8-biggest-factors-affect-real-estate-prices/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>8 factors that affect real estate prices</a>
                            <a href="https://www.zillow.com/home-values/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Appreciation data - search by state, city, and more</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Home Mortgage</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.moneyunder30.com/percentage-income-mortgage-payments" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much mortgage payments can you afford</a>
                            <a href="https://www.zillow.com/mortgage-learning/private-mortgage-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Private Mortgage Insurance (PMI): What is it?</a>
                            <a href="https://www.investopedia.com/ask/answers/081214/average-what-can-i-expect-my-private-mortgage-insurance-pmi-rate-be.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Private Mortgage Insurance (PMI): Why do I need it and what will it cost me?</a>
                            <a href="https://smartasset.com/credit-cards/what-is-a-good-debt-to-income-ratio" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Debt-to-Income ratio: Why it&apos;s important for buying real estate and financial health</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Expenses of Owning a Home</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://wallethub.com/edu/states-with-the-highest-and-lowest-property-taxes/11585/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Property taxes by state</a>
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Transfer taxes by state</a>
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average monthly costs of owning a home</a>
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimating annual maintenance costs</a>
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Common DIY repair jobs and their costs</a>
                            <a href="https://www.nerdwallet.com/blog/insurance/understanding-homeowners-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Homeowner&apos;s Insurance in detail</a>
                            <a href="https://www.quickenloans.com/blog/what-do-hoa-fees-cover" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Association Fees (HOAs) and what they cover</a>
                            <a href="https://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Some things you need to know about HOAs</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Taxes & Home Ownership (See Your Income Statement for Detail)</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://turbotax.intuit.com/tax-tips/home-ownership/tax-breaks-and-home-ownership/L1EXrbgIg" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax breaks of home ownership</a>
                            <a href="https://www.nolo.com/legal-encyclopedia/determining-your-homes-tax-basis.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Determining your existing home&apos;s tax basis</a>
                            <a href="https://turbotax.intuit.com/tax-tips/home-ownership/tax-aspects-of-home-ownership-selling-a-home/L6tbMe3Dy" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Selling a home and the tax consequences</a>
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax reform and how it affects home ownership</a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.dummyImgCompareExisting) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Existing Assets & Liabilities" moduleSelected="module18" getModuleData={getModuleData} setUpdatedScenario={setUpdatedExistingAssetsLiabilitiesValues} />
              )}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 12 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />

              )}
            </div>
          </Container>
        </section>

      </div>
      <div>
        <Dialog
          open={popupOpen}
          onClose={() => { setPopupOpen(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedExistingAssetsLiabilitiesValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}

            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={imagePopup}
          onClose={handleImagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.maxModule}
        >
          <DialogContent className={classes.maxModal}>
            <div className={classes.maxHeader}>
              <div className={classes.modalClose} aria-hidden="true" onClick={handleImagePopup}>
                ×

              </div>
            </div>
            <div className={classes.maxModalBody}>
              <div>
                {/* <span onClick={ handleImagePopup } aria-hidden="true">close</span> */}
                <img src={imageURL} alt="" />
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleImagePopup}>Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={editPopup}
          onClose={handleEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              <span onClick={handleEditPopup} aria-hidden="true">close</span>
              Design this edit popup
            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module18' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

ExistingAssetsLiabilitiesSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(ExistingAssetsLiabilitiesSheet);
