const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  netWorthSection: {
    minHeight: 'calc(100vh - 101px)',
    width: '60%',
    margin: '0 auto',
    paddingBottom: '20px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      width: '80%', 
    }, 
    [theme.breakpoints.down( 'sm' )]: {
      width: '90%', 
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%', 
    }, 
  },
  showMeContent: {
    padding: '0 35px',
    background: '#fff',
    boxSizing: 'border-box',  
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 15px',
    },
  },
  showPortion: {
    padding: '20px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0px',
    },
  },
  nwSectionRow: {
    margin: '0px -15px',
  },
  netWorthContent: {
    padding: '0px 25px',    
  },
  tableRow: {
    border: '2px solid #0069aa',
    display: 'flex',
    padding: '10px',
    margin: '20px -15px',
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
      margin: '0px -15px',
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      backgroundColor: 'transparent',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '21px',
            padding: '5px 0',
            paddingLeft: '15px',
            color: theme.palette.common.pureBlack,
            fontFamily: '"MuseoSans-300"',
            position: 'relative',
            [theme.breakpoints.down( 'sm' )]: {
              fontSize: '17px',
              paddingLeft: '10px',
            },
            '&:nth-child(1)': {
              [theme.breakpoints.down( 'sm' )]: {
                paddingLeft: '0px',
              },
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '& span': {
              paddingRight: '4px',
            },
            '& input': {
              width: '135px',
              textAlign: 'right',
              border: '1px solid #333',
              transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              padding: '1px 2px',
              backgroundImage: 'none',
              lineHeight: 'inherit',
              color: 'inherit',
              boxSizing: 'border-box',
              height: '36px',
              font: 'inherit',
              fontSize: '21px',
              borderRadius: '2px', 
              '@media (max-width: 420px)': {
                width: '120px',
              },            
              '&:focus': {
                outline: 'auto',
              },
            },
            '&:last-child': {
              textAlign: 'center!important',
            },
            '& div': {
              [theme.breakpoints.down( 'sm' )]: {
                verticalAlign: 'middle',
              },
              '&::before': {
                border: 'none !important',
              },
              '&::after': {
                border: 'none !important',
              },
            },
          },
        },
      },
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  sectionTitle: {
    fontWeight: '700',
    paddingLeft: '0!important',
    textDecoration: 'underline',
  },
  totalAssets: {
    '& td': {
      borderTop: '1px solid #000',
      paddingLeft: '0px',
      fontWeight: '700',
      fontFamily: '"MuseoSans-500"',
    },
  },
  finalNetWorth: {
    marginTop: '20px',
    border: '2px solid #000',
    background: '#ddebf7',
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '18px',
    }, 
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '18px',
      }, 
    },
  },
  timeValueMoney: {
    '& div': {
      '& button': {
        backgroundColor: '#fd9840',
        fontSize: '21px',
        color: theme.palette.common.white,
        lineHeight: '19px',
        fontFamily: 'MuseoSans-300',
        borderColor: '#fd9840',
        boxShadow: 'none',
        borderRadius: '3px',
        marginLeft: '15px',
        padding: '12px 22px',
        borderWidth: '2px',
        textTransform: 'uppercase',
        display: 'inline-block',
        marginBottom: '0',
        fontWeight: '400',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        touchAction: 'manipulation',
        userSelect: 'none',
        border: '1px solid transparent',
        cursor: 'pointer',
        [theme.breakpoints.down( 'sm' )]: {
          padding: '8px 20px',
          fontSize: '15px',
        },
        [theme.breakpoints.down( 'sm' )]: {
          padding: '8px 10px',
          fontSize: '15px',
        },
        '&:hover': {
          backgroundColor: '#fd9840',
        },
      },
    },
  },
  moneyInput: {
    textAlign: 'center!important',
    textDecoration: 'underline',
    paddingLeft: '49px!important',
  },
  calculateBtn: {
    textAlign: 'center!important',
    textDecoration: 'underline',
    paddingLeft: '26px!important',
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    zIndex: '99',
    '&::after': {
      opacity: '1 !important',
    },
    '&::before': {
      borderTop: 'none',
      borderBottom: 'none',
      borderLeftColor: '#0069aa',
    },
  },
  compoundSelect: {
    fontSize: '17px',
    height: '36px',
    fontFamily: 'inherit',
    color: 'inherit',   
  },
  percentSymbol: {
    marginTop: '3px',
    left: 'auto',
    right: '-25px',
    fontFamily: 'MuseoSans-300',
    fontSize: '20px',
    top: 'initial',
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    color: '#000',
  },
  cpSelect: {
    [theme.breakpoints.down( 'xs' )]: {
      display: 'flex',
      alignItems: 'center',
    },     
  },
} );

export default styles;
