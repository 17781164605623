import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi-aca/styles';

const inputElementArray = ['Rent', 'Utilities', 'Food', 'Clothing', 'Entertainment', 'Technology', 'Transportation', 'Miscellaneous', 'Healthcare Costs other than Premium', 'Health Insurance Premium'];

const CareerBottom = (props) => {
  const { classes, handleCareerObject } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState(false);
  const [otherExpenses, setOtherExpenses] = React.useState(false);
  const [tableObj, setTableObj] = React.useState({});

 
  const individualLivingExpensesCalc = function () {
    let incomeSheetData = moduleServices.incomeStatementCompleteData();
    let valueData = {};
    if (incomeSheetData !== undefined && incomeSheetData !== '') {
      inputElementArray.forEach(element => {
        if (incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== undefined && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== ''
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== '') {
          valueData[element] = incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element];
        } else {
          valueData[element] = 0
        }

        let yearsAmount = 0;
        let startYear = parseInt(handleCareerObject.start_year);
        let endYear = parseInt(handleCareerObject.end_year);
        for (let i = startYear; i <= endYear; i++) {
          if (incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i]['livingExpenses'] !== undefined && incomeSheetData[i]['livingExpenses'] !== ''
            && incomeSheetData[i]['livingExpenses'][element] !== undefined && incomeSheetData[i]['livingExpenses'][element] !== '') {
            yearsAmount = yearsAmount + incomeSheetData[i]['livingExpenses'][element];
            valueData[element + 'year'] = yearsAmount;
          }
        }
        valueData[element + 'year'] = yearsAmount;
      });

    }
    setTableObj(valueData);
  }

  const d = new Date();

  useEffect(() => {
    individualLivingExpensesCalc();
    // eslint-disable-next-line
  },[handleCareerObject]);
  return (

    <div className={classNames(classes.vacationTable, classes.budgetTable)}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>
            {parseInt(handleCareerObject.start_year) > 0 ? parseInt(handleCareerObject.start_year) : d.getFullYear()}
              {' '}
              -
              {' '}
              {parseInt(handleCareerObject.end_year) > 0 ? parseInt(handleCareerObject.end_year) : d.getFullYear()+(2-1) }
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv) && moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv} prefix={moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.totalIncomeAddCareerAdv) && moduleServices.module8Data.totalIncomeAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalIncomeAddCareerAdv} prefix={moduleServices.module8Data.totalIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalIncomeAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.moreHigherincomeEarned) && moduleServices.module8Data.moreHigherincomeEarned !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherincomeEarned} prefix={moduleServices.module8Data.moreHigherincomeEarned >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherincomeEarned < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Total Taxes Paid
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.totalTaxesPaidAddCareerAdv) && moduleServices.module8Data.totalTaxesPaidAddCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalTaxesPaidAddCareerAdv} prefix={moduleServices.module8Data.totalTaxesPaidAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalTaxesPaidAddCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.annualTotalTaxesPaidAddCareerAdv) && moduleServices.module8Data.annualTotalTaxesPaidAddCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.annualTotalTaxesPaidAddCareerAdv} prefix={moduleServices.module8Data.annualTotalTaxesPaidAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.annualTotalTaxesPaidAddCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.moreHigherEstimatedTaxesPaid) && moduleServices.module8Data.moreHigherEstimatedTaxesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherEstimatedTaxesPaid} prefix={moduleServices.module8Data.moreHigherEstimatedTaxesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherEstimatedTaxesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classNames(classes.livingRow, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setExpandLivingExpenses(!expandLivingExpenses)}}>
            <td>
              <span>
                {!expandLivingExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {expandLivingExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Living Expenses</b>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.livingExpensesAddCareerAdv) && moduleServices.module8Data.livingExpensesAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.livingExpensesAddCareerAdv} prefix={moduleServices.module8Data.livingExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.livingExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.addCareerAdvLivingExpenses) && moduleServices.module8Data.addCareerAdvLivingExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvLivingExpenses} prefix={moduleServices.module8Data.addCareerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvLivingExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.moreHigherlivingExpensesPaid) && moduleServices.module8Data.moreHigherlivingExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherlivingExpensesPaid} prefix={moduleServices.module8Data.moreHigherlivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherlivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Rent
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent / 12} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rentyear} prefix={tableObj.Rentyear >= 0 ? '$' : '($'} suffix={tableObj.Rentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Utilities
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities / 12} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilitiesyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilitiesyear} prefix={tableObj.Utilitiesyear >= 0 ? '$' : '($'} suffix={tableObj.Utilitiesyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Food
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food / 12} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Foodyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Foodyear} prefix={tableObj.Foodyear >= 0 ? '$' : '($'} suffix={tableObj.Foodyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Clothing & Personal Care
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing / 12} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothingyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothingyear} prefix={tableObj.Clothingyear >= 0 ? '$' : '($'} suffix={tableObj.Clothingyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Entertainment
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment / 12} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainmentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainmentyear} prefix={tableObj.Entertainmentyear >= 0 ? '$' : '($'} suffix={tableObj.Entertainmentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Technology
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology / 12} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technologyyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technologyyear} prefix={tableObj.Technologyyear >= 0 ? '$' : '($'} suffix={tableObj.Technologyyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Transportation
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation / 12} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportationyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportationyear} prefix={tableObj.Transportationyear >= 0 ? '$' : '($'} suffix={tableObj.Transportationyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Miscellaneous
              </td>
              <td>
                <span />
                <span>

                   {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous / 12} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                   {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneousyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneousyear} prefix={tableObj.Miscellaneousyear >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneousyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Costs outside Premium
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium'] / 12} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium']} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premiumyear']} prefix={tableObj['Healthcare Costs other than Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Premium
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium'] / 12} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium']} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premiumyear']} prefix={tableObj['Health Insurance Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classNames(classes.otherExpenses, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setOtherExpenses(!otherExpenses)}}>
            <td>
              <span>
                {!otherExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {otherExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Other Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module8Data.addCareerAdvOtherExpenses) && moduleServices.module8Data.addCareerAdvOtherExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module8Data.addCareerAdvOtherExpenses}
                    prefix={moduleServices.module8Data.addCareerAdvOtherExpenses >= 0 ? '$' : '($'}
                    suffix={moduleServices.module8Data.addCareerAdvOtherExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module8Data.addOtherAnnaulLivingExpensesCareerPath) && moduleServices.module8Data.addOtherAnnaulLivingExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module8Data.addOtherAnnaulLivingExpensesCareerPath} prefix={moduleServices.module8Data.addOtherAnnaulLivingExpensesCareerPath >= 0 ? '$' : '($'}
                    suffix={moduleServices.module8Data.addOtherAnnaulLivingExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module8Data.addOtherEducationLivingExpensesPaid) && moduleServices.module8Data.addOtherEducationLivingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addOtherEducationLivingExpensesPaid} prefix={moduleServices.module8Data.addOtherEducationLivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addOtherEducationLivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Higher Education Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.higherEducationExpensesAddCareerAdv) && moduleServices.module8Data.higherEducationExpensesAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.higherEducationExpensesAddCareerAdv} prefix={moduleServices.module8Data.higherEducationExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.higherEducationExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.addCareerAdvHigherEducationExpenses) && moduleServices.module8Data.addCareerAdvHigherEducationExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvHigherEducationExpenses} prefix={moduleServices.module8Data.addCareerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvHigherEducationExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.higherEducationExpensesPaid) && moduleServices.module8Data.higherEducationExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.higherEducationExpensesPaid} prefix={moduleServices.module8Data.higherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.higherEducationExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Children Expenses
              </td>
              <td>
                <span />
                <span>
                  { !isNaN(moduleServices.module8Data.moreChildrenExpensesPaid) && moduleServices.module8Data.moreChildrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreChildrenExpensesPaid} prefix={moduleServices.module8Data.moreChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreChildrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.addCareerAdvChildrenExpenses) && moduleServices.module8Data.addCareerAdvChildrenExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvChildrenExpenses} prefix={moduleServices.module8Data.addCareerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvChildrenExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.moreChildrenExpensesPaid) && moduleServices.module8Data.moreChildrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreChildrenExpensesPaid} prefix={moduleServices.module8Data.moreChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreChildrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Pet Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.petExpensesAddCareerAdv) && moduleServices.module8Data.petExpensesAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.petExpensesAddCareerAdv} prefix={moduleServices.module8Data.petExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.petExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.addCareerAdvPetExpenses) && moduleServices.module8Data.addCareerAdvPetExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvPetExpenses} prefix={moduleServices.module8Data.addCareerAdvPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvPetExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.morePetExpensesPaid) && moduleServices.module8Data.morePetExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.morePetExpensesPaid} prefix={moduleServices.module8Data.morePetExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.morePetExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Car Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.carExpensesAddCareerAdv) && moduleServices.module8Data.carExpensesAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.carExpensesAddCareerAdv} prefix={moduleServices.module8Data.carExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.carExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.addCareerAdvCarExpenses) && moduleServices.module8Data.addCareerAdvCarExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvCarExpenses} prefix={moduleServices.module8Data.addCareerAdvCarExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvCarExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module8Data.moreHigherCarExpensesPaid) && moduleServices.module8Data.moreHigherCarExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherCarExpensesPaid} prefix={moduleServices.module8Data.moreHigherCarExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherCarExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Real Estate Expenses
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module8Data.realEstateExpensesAddCareerAdv) && moduleServices.module8Data.realEstateExpensesAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.realEstateExpensesAddCareerAdv} prefix={moduleServices.module8Data.realEstateExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.realEstateExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module8Data.addCareerAdvHomeExpenses) && moduleServices.module8Data.addCareerAdvHomeExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvHomeExpenses} prefix={moduleServices.module8Data.addCareerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvHomeExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module8Data.moreHigherRealEstateExpensesPaid) && moduleServices.module8Data.moreHigherRealEstateExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherRealEstateExpensesPaid} prefix={moduleServices.module8Data.moreHigherRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherRealEstateExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Loan Payments
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.internetExpensesAddCareerPathAdv) && moduleServices.module8Data.internetExpensesAddCareerPathAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.internetExpensesAddCareerPathAdv} prefix={moduleServices.module8Data.internetExpensesAddCareerPathAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.internetExpensesAddCareerPathAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.addCareerAdvFinancingExpenses) && moduleServices.module8Data.addCareerAdvFinancingExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvFinancingExpenses} prefix={moduleServices.module8Data.addCareerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvFinancingExpenses < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module8Data.moreHigherfinancingExpensesPaid) && moduleServices.module8Data.moreHigherfinancingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherfinancingExpensesPaid} prefix={moduleServices.module8Data.moreHigherfinancingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherfinancingExpensesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Total Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv) && moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv !== undefined &&  !isNaN(moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv) && moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv !== null &&  !isNaN(moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv) && moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv} prefix={moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalMonthlyExpensesAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.addCareerAdvTotalAnnualExpenses) && moduleServices.module8Data.addCareerAdvTotalAnnualExpenses !== undefined &&  !isNaN(moduleServices.module8Data.addCareerAdvTotalAnnualExpenses) && moduleServices.module8Data.addCareerAdvTotalAnnualExpenses !== null &&  !isNaN(moduleServices.module8Data.addCareerAdvTotalAnnualExpenses) && moduleServices.module8Data.addCareerAdvTotalAnnualExpenses !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvTotalAnnualExpenses} prefix={moduleServices.module8Data.addCareerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvTotalAnnualExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv) && moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv !== undefined &&  !isNaN(moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv) && moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv !== null &&  !isNaN(moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv) && moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv} prefix={moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalExpensesPaidFromAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.monthlyNetIncomeAddCareerAdv) && moduleServices.module8Data.monthlyNetIncomeAddCareerAdv !== undefined &&  !isNaN(moduleServices.module8Data.monthlyNetIncomeAddCareerAdv) && moduleServices.module8Data.monthlyNetIncomeAddCareerAdv !== null &&  !isNaN(moduleServices.module8Data.monthlyNetIncomeAddCareerAdv) && moduleServices.module8Data.monthlyNetIncomeAddCareerAdv !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.monthlyNetIncomeAddCareerAdv} prefix={moduleServices.module8Data.monthlyNetIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.monthlyNetIncomeAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.addCareerAdvAnnualNetIncome) && moduleServices.module8Data.addCareerAdvAnnualNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerAdvAnnualNetIncome} prefix={moduleServices.module8Data.addCareerAdvAnnualNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerAdvAnnualNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module8Data.moreHigherNetIncome) && moduleServices.module8Data.moreHigherNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.moreHigherNetIncome} prefix={moduleServices.module8Data.moreHigherNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.moreHigherNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.retirementContributionsAddCareerAdv) && moduleServices.module8Data.retirementContributionsAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.retirementContributionsAddCareerAdv} prefix={moduleServices.module8Data.retirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.retirementContributionsAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.annualRetirementContributionsAddCareerAdv) && moduleServices.module8Data.annualRetirementContributionsAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.annualRetirementContributionsAddCareerAdv} prefix={moduleServices.module8Data.annualRetirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.annualRetirementContributionsAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.finalRetirementContributionsAddCareerAdv) && moduleServices.module8Data.finalRetirementContributionsAddCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.finalRetirementContributionsAddCareerAdv} prefix={moduleServices.module8Data.finalRetirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.finalRetirementContributionsAddCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>HSA Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.careerAdditionalHSAContributions) && moduleServices.module8Data.careerAdditionalHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.careerAdditionalHSAContributions / 12} prefix={moduleServices.module8Data.careerAdditionalHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.careerAdditionalHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.careerAdditionalHSAContributions) && moduleServices.module8Data.careerAdditionalHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.careerAdditionalHSAContributions} prefix={moduleServices.module8Data.careerAdditionalHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.careerAdditionalHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module8Data.finalAdditionalcareerHSAContributions) && moduleServices.module8Data.finalAdditionalcareerHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.finalAdditionalcareerHSAContributions} prefix={moduleServices.module8Data.finalAdditionalcareerHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.finalAdditionalcareerHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i><b>% of Total Income</b></i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv) && moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv !== 0 && !isNaN(moduleServices.module8Data.monthlyNetIncomeAddCareerAdv) && moduleServices.module8Data.monthlyNetIncomeAddCareerAdv !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv / moduleServices.module8Data.monthlyNetIncomeAddCareerAdv) * 100} prefix={moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv / moduleServices.module8Data.monthlyNetIncomeAddCareerAdv >= 0 ? '' : '('} suffix={moduleServices.module8Data.totalMonthlyIncomeAddCareerAdv / moduleServices.module8Data.monthlyNetIncomeAddCareerAdv < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module8Data.totalIncomeAddCareerAdv) && moduleServices.module8Data.totalIncomeAddCareerAdv !== 0 &&  !isNaN(moduleServices.module8Data.addCareerAdvAnnualNetIncome) && moduleServices.module8Data.addCareerAdvAnnualNetIncome !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module8Data.totalIncomeAddCareerAdv / moduleServices.module8Data.addCareerAdvAnnualNetIncome) * 100} prefix={moduleServices.module8Data.totalIncomeAddCareerAdv / moduleServices.module8Data.addCareerAdvAnnualNetIncome >= 0 ? '' : '('} suffix={moduleServices.module8Data.totalIncomeAddCareerAdv / moduleServices.module8Data.addCareerAdvAnnualNetIncome < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module8Data.finalNetCashFlowAddCareerAdv) && moduleServices.module8Data.finalNetCashFlowAddCareerAdv !== 0 &&  !isNaN(moduleServices.module8Data.moreHigherincomeEarned) && moduleServices.module8Data.moreHigherincomeEarned !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module8Data.finalNetCashFlowAddCareerAdv / moduleServices.module8Data.moreHigherincomeEarned) * 100} prefix={moduleServices.module8Data.finalNetCashFlowAddCareerAdv / moduleServices.module8Data.moreHigherincomeEarned >= 0 ? '' : '('} suffix={moduleServices.module8Data.finalNetCashFlowAddCareerAdv / moduleServices.module8Data.moreHigherincomeEarned < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CareerBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};
export default withStyles(styles)(CareerBottom);
