const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #1f96e0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-700',
      fontSize: '36px',
      fontWeight: 'normal',
      color: '#0097e6',    
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',      
      width: '100%',
      '& > img': {
        marginRight: '25px',
        height: '50px',
        cursor: 'pointer',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
          height: '40px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
    '@media (max-width: 767px)': {
      width: '20px',
      '& span': {
        height: '3px',
        width: '20px',
      }
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '20px',
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },
  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '0 auto 0',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      color: '#333',
      lineHeight: '25px',
    },    
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },  
  },
  startButton: {
    paddingTop: '20px',
    float: 'right',
    paddingBottom: '50px',
    '& button': {
      padding: '10px 20px',
      background: '#337ab7',
      textTransform: 'uppercase',
      border: '1px solid #337ab7',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '18px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',   
      fontFamily: 'MuseoSans-300', 
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  }, 
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsLogoImage: {   
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '200px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '180px',
        },
      },
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing:'border-box',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  mcsDevelopVision: {
    background:'#004375',
    padding: '10px 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px',
    },
  },
  backtoLevel: {
    '& a': {
      textDecoration: 'underline',
      color: '#000',
      fontFamily: 'MuseoSans-300',
      '&:hover':{
        color: '#f89b22 !important',
      },
    },
  },
  nextButton: {
    paddingTop: '20px',
    float: 'right',
    paddingBottom: '50px',
    boxSizing: 'border-box',
    '& button': {
      padding: '10px 20px',
      background: '#337ab7',
      textTransform: 'uppercase',
      border: '1px solid #337ab7',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '18px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',   
      fontFamily: 'MuseoSans-300', 
      boxSizing: 'border-box',
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },
  Buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginTopZero: {
    marginTop: '0px',
  },
  activityStatus: {
    '& h4': {
      fontSize:'22px',
      color: '#fff !important',
      margin: '0px',
      fontFamily: 'MuseoSans-300',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
      [theme.breakpoints.down('xs')]: {
        display:'block',
        fontSize:'16px',
      },
      '& a': {
        color: '#fff !important',
        borderBottom: '1px solid',
        lineHeight: 'normal',
        marginLeft: '5px',
        '&:hover':{
          color: '#f89b22 !important',
        },
      },
    },    
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap:'wrap',
      justifyContent: 'center',
    },
  },
  activityImage: {
    '& img': {
      width: '30px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  greenTick: {
    '& img': {
      width: '20px',
      verticalAlign: 'middle',
      marginright: '5px',
    },
  },
  marginBottomZero: {
    marginBottom: '0px',
  },
  procesSteps: {
    listStyle: 'none',
    paddingLeft: '40px',
    '& li':{
      margin:'10px 0px',
    },
  },
  reviewLevels: {   
    position: 'absolute',
    right: '0',
    minWidth: '300px',
    padding: '10px 0',
    background: '#fff',
    marginTop: '20px',
    zIndex: '9999',
    color: '#333',
    '&::before': {
      borderBottom: '11px solid #fff',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '92%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    '& i': {
      position: 'absolute',
      top: '3px',
      right: '3px',
      cursor: 'pointer',
    },
  },
  bookBlock: {
    position: 'relative',
  },
  panelDefault: {
    borderColor: '#0069aa',
    textAlign: 'left',
    background: '0 0',
    width: '90%',
    margin: '0 auto 3px',
    borderRadius: '0',
    border: '1px solid transparent',   
    webkitBoxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
    boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
  },
  panelBody: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  titleName: {
    width: '96%',
    display: 'flex',
  },
  mcsNumber: {
    paddingRight: '5px',
  },
  greentickMark: {
    width: '4%',
    '& img': {
      width: '10px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '12px !important',
    textAlign: 'center',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    maxWidth: '200px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },    
  },
});

export default styles;
