import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const DollarCostAveraging = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Dollar-Cost Averaging (DCA)</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dollar-cost-averaging.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Dollar-Cost Averaging (DCA)
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dollar-cost-averagaing-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Buy low and sell high. That&apos;s the dream for all
                          investors. In reality, markets move up and down and
                          it&apos;s extremely hard to know if you are buying
                          low. That&apos;s where dollar-cost averaging comes
                          into play.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Dollar-cost averaging (DCA) is an investment strategy
                          that can take some emotion out of investing, avoid
                          trying to perfectly time the market, and keep you
                          focused on the long-term. With the DCA strategy, you
                          make investments at regular intervals regardless of
                          market conditions.
                        </p>
                        <p>
                          A perfect example of DCA is a company sponsored 401(k)
                          retirement plan. Every two weeks when you get paid, a
                          portion of your paycheck is automatically invested
                          into your retirement fund. If the market conditions
                          are bad and stock prices are falling - you invest. If
                          the market conditions are good and stock prices are
                          rising - you invest. The question you should be asking
                          is, &quot;Do I make more money with DCA?&quot;
                        </p>
                        <p className={classes.noBottomMargin}>
                          <strong>Do I make more money with DCA?</strong>
                          <br />
                          Great question. Let&apos;s assume you have $12,000 to
                          invest and look at two scenarios over a 12-month
                          period of time.
                        </p>
                        <ul className={classes.circleList}>
                          <li>
                            <strong>Scenario A:</strong> dollar-cost average at
                            $1,000 per month
                          </li>
                          <li>
                            <strong>Scenario B:</strong> invest all $12,000
                            right now.
                          </li>
                        </ul>
                        <p className={classes.noBottomMargin}>
                          <strong>
                            Scenario A: Dollar-Cost Average at $1,000 per month
                          </strong>
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dollar-cost-averaging-table-1.png"
                            alt=""
                          />
                        </div>
                        <p className={classes.noBottomMargin}>
                          <strong>Scenario B: Invest $12,000 right away</strong>
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dollar-cost-averaging-table-2.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Which scenario ends up with a higher value?
                          Dollar-cost averaging. Markets go up and down. Unless
                          you have a crystal ball, it&apos;s hard to predict the
                          future. Dollar-cost averaging helps you establish a
                          consistent investing plan, removes some of the
                          emotions from investing and allows you to capitalize
                          on market changes.
                        </p>
                        <p>
                          Will you be using dollar-cost averaging in your future
                          investment strategy?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DollarCostAveraging.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DollarCostAveraging);
