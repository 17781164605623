const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    overflow: 'hidden',
    padding: '0 15px',
    margin: '0 auto',
  },
  footer: {
    flexWrap: 'wrap',
    padding: '20px 0 15px',
    background: '#252525',
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    fontSize: '14px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      padding: '20px 15px 15px', 
      width: '100%', 
      position: 'relative', 
    },
  },
  copyRight: {
    textAlign: 'left',
    fontFamily: 'MuseoSans-100',
    fontSize: '16px',
    lineHeight: '35px',
    color: '#fff',
    fontWeight: '100',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '14px',   
    },
    [theme.breakpoints.down( 'xs' )]: {
      textAlign: 'center', 
      width: '100%',
    },
    '& p': {
      margin: '0',
    },
  },
  copyrightText: {
    fontSize: '16px',
    margin: '0',
    textAlign: 'right',
    lineHeight: '35px',
    color: ' #fff',
    fontWeight: '100',
    [theme.breakpoints.down( 'xs' )]: {
      textAlign: 'center',
      width: '100%', 
    },
    '& img': {
      marginLeft: '12px',
      maxWidth: '100px',
      verticalAlign: 'top',
    },
    '& p': {
      margin: '0',
    },
  },
  grayFooter: {
    borderTop: '1px solid #555',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    paddingTop: '10px',
    textAlign: 'center',
    width: '100%',
    marginTop: '0px',
    lineHeight: '1.42857143',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
} );

export default styles;
