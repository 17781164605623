import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';

const CombinedBudgetSummary = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>You now have a plan for your combined living expenses! Remember, you build a budget so you can see exactly where you are spending, how you can save more, and make smarter choices about your money. Review your details below before continuing.</p>
    </div>
  );
};

CombinedBudgetSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( CombinedBudgetSummary );
