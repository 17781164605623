import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CarInsuranceRequirements = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Car Insurance Requirements</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/how-does-car-ins-work.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Car Insurance Requirements
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/car-insurance-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          In almost every state, it’s illegal to drive a car
                          without car insurance. And, that’s a good thing. Car
                          insurance helps pay for repairs and bodily injuries in
                          case of an auto accident. Instead of paying for auto
                          accidents out of your own pocket, you pay an annual or
                          semi-annual premium to an insurance provider.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Think about it. If you accidentally hit another
                          vehicle and that other vehicle is worth $50,000 and
                          needs to be replaced, could you pay for that out of
                          your own pocket? That’s why car insurance is so
                          important. But you have to understand what car
                          insurance covers before you buy a policy.
                        </p>
                        <p>
                          Car insurance coverage is typically shown in a
                          three-number format. For example, 20/40/15. What this
                          stands for is $20,000 bodily injury per person per
                          accident, $40,000 bodily injury for all persons per
                          accident, and $15,000 property damage liability per
                          accident.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <strong>Bodily injury per person per accident</strong>
                        </p>
                        <p className={classes.noTopMargin}>
                          This is how much your insurance company will help pay
                          for another person’s injuries if an accident is your
                          fault. Bodily injury will cover medical expenses such
                          as emergency and hospital services and follow-up
                          doctor visits. Plus, if you injure another person and
                          that person can’t work, your insurance will pay that
                          individual for lost wages. Last, but not least, legal
                          fees might be covered as well.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <strong>
                            Bodily injury for all persons per accident
                          </strong>
                        </p>
                        <p className={classes.noTopMargin}>
                          This is how much your insurance company will help pay
                          for total personal injuries if an accident is your
                          fault. This includes everything from the above
                          paragraph. For example, if your coverage is $300,000,
                          four people are injured in an accident that is your
                          fault, and each person requires $25,000 of medical
                          expenses, your insurance will cover the $100,000 bill.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <strong>
                            Property damage liability per accident
                          </strong>
                        </p>
                        <p className={classes.noTopMargin}>
                          This is how much your insurance company will help pay
                          for total property damage if an accident is your
                          fault. For example, if you have $100,000 of coverage,
                          rear end another car, and cause $10,000 of damage to
                          that car, your insurance will cover the bill. Or, if
                          you drive off the road to avoid another vehicle and
                          damage a $5,000 fence, your insurance will cover the
                          bill.
                        </p>
                        <p>
                          Your car insurance provider will cover your costs up
                          to those amounts. Anything greater than those amounts
                          will be your responsibility. Each state has{' '}
                          <a
                            href="https://www.thebalance.com/understanding-minimum-car-insurance-requirements-2645473"
                            target="_blank"
                            rel="noopener noreferrer">
                            minimum insurance requirements
                          </a>
                          . But{' '}
                          <a
                            href="https://www.carinsurance.com/Articles/how-much-car-insurance-should-you-buy.aspx"
                            target="_blank"
                            rel="noopener noreferrer">
                            Carinsurance.com
                          </a>{' '}
                          recommends that you get at least 100/300/100.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link href="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CarInsuranceRequirements.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarInsuranceRequirements);
