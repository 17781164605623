import bgRight from '../../assets/img/life-event-library/blueprint_user_popup_bg.png';
import bgRightTwo from '../../assets/img/life-event-library/non_member_popup_bg.png';
import checkIcon from '../../assets/img/life-event-library/guest_popup_check.png';

const styles = ( ) => ( {
  DailogLifeEvent: {
    height: '100%',
    borderBottom: '40px solid #0069aa',
    '@media (min-width: 1500px)': {
      overflow: 'hidden',
    },
    '& > div': {
      height: 'calc(100% - 50px)',
    },
  },
  continuePlanLeft: {
    padding: '85px 50px 20px',
    textAlign: 'center',
    '@media (max-width: 599px)': {
      padding: '50px 20px 20px',  
    },
    '& h3': {
      fontSize: '36px',
      lineHeight: '48px',
      fontFamily: 'MuseoSans-100',
      color: '#333',
      margin: '0',
      fontWeight: 'normal',
    },
    '& img': {
      maxWidth: '80px',
      marginBottom: '15px',
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '34px',
      fontFamily: 'MuseoSans-100',
      color: '#000',
    },
    '& button': {
      fontSize: '24px',
      padding: '8px 40px',
      backgroundColor: '#84a84d',
      color: '#fff',
      textDecoration: 'none',
      borderRadius: '10px',
      fontFamily: 'MuseoSans-300',
      border: '1px solid #6f6f6f',
      marginTop: '15px',
      '&:hover': {
        backgroundColor: '#f69a34',
        borderColor: '#f69a34',
      },
    },
    '& a': {
      color: '#333',
      display: 'block',
      marginTop: '30px',
      lineHeight: '18px',
      cursor: 'pointer',
      fontSize: '14px',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f69a34',
      },
    },
  },
  continuePlanRight: {
    backgroundImage: `url(${bgRight})`,
    backgroundSize: '100% 100%',
    padding: '85px 50px 20px',
    textAlign: 'center',
    color: '#fff',
    height: 'calc(100% - 105px)',
    '@media (max-width: 599px)': {
      padding: '50px 20px 20px',
      height: 'calc(100% - 68px)',
    },
    '@media (min-width: 1500px)': {
      height: '100%',
    },
    '& img': {
      maxWidth: '130px',
    },
    '& h2': {
      fontSize: '40px',
      lineHeight: '48px',
      fontFamily: 'MuseoSans-300',
      color: '#fff',
      fontWeight: 'normal',
      margin: '20px 0 0 0',
    },
    '& span': {
      fontSize: '14px',
      display: 'block',
      paddingBottom: '8px',
      '& a': {
        color: '#fff',
        cursor: 'pointer',
        borderBottom: '1px solid #fff',
        '&:hover': {
          color: '#f69a34',
          borderBottom: '1px solid #f69a34',
        },
      },
    },
    '& p': {
      fontSize: '27px',
      margin: '0',
      lineHeight: '36px',
      fontFamily: 'MuseoSans-300',
      color: '#fff',
      padding: '25px 0',
    },
    '& h4':{
      fontSize: '20px',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-300',
      padding: '0px',
      margin: '0 0 30px',
      fontWeight: 'normal',
    },
    '& li': {
      fontSize: '16px',
      lineHeight: '24px',
      listStyle: 'none',
      textAlign: 'left',
      fontFamily: 'MuseoSans-300',
      color: '#fff',
      paddingBottom: '15px',
      paddingRight: '25px',
      position: 'relative',
      '&::before': {
        content: `url(${checkIcon})`,
        position: 'absolute',
        left: '-45px',
        top: '0',
        transform: 'scale(0.78)',
      },
    },
  },
  continuePlanRightTwo:{
    backgroundImage: `url(${bgRightTwo})`,
  }

} );

export default styles;
