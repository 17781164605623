import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi-fca/styles';


const inputElementArray = ['Rent', 'Utilities', 'Food', 'Clothing', 'Entertainment', 'Technology', 'Transportation', 'Miscellaneous', 'Healthcare Costs other than Premium', 'Health Insurance Premium'];

const CareerBottom = (props) => {
  const { classes, handleCareerObject } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState(false);
  const [otherExpenses, setOtherExpenses] = React.useState(false);
  const [tableObj, setTableObj] = React.useState({});

 
  const individualLivingExpensesCalc = function () {
    let incomeSheetData = moduleServices.incomeStatementCompleteData();
    let valueData = {};
    if (incomeSheetData !== undefined && incomeSheetData !== '') {
      inputElementArray.forEach(element => {
        if (incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== undefined && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== ''
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== '') {
          valueData[element] = incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element];
        } else {
          valueData[element] = 0
        }

        let yearsAmount = 0;
        let startYear = parseInt(handleCareerObject.start_year);
        let endYear = parseInt(handleCareerObject.end_year);
        for (let i = startYear; i <= endYear; i++) {
          if (incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i]['livingExpenses'] !== undefined && incomeSheetData[i]['livingExpenses'] !== ''
            && incomeSheetData[i]['livingExpenses'][element] !== undefined && incomeSheetData[i]['livingExpenses'][element] !== '') {
            yearsAmount = yearsAmount + incomeSheetData[i]['livingExpenses'][element];
            valueData[element + 'year'] = yearsAmount;
          }
        }
        valueData[element + 'year'] = yearsAmount;
      });

    }
    setTableObj(valueData);
  }

  const d = new Date();

  useEffect(() => {
    individualLivingExpensesCalc();
    // eslint-disable-next-line
  },[handleCareerObject]);
  return (

    <div className={classNames(classes.vacationTable, classes.budgetTable)}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>
            {parseInt(handleCareerObject.start_year) > 0 ? parseInt(handleCareerObject.start_year) : d.getFullYear()}
              {' '}
              -
              {' '}
              {parseInt(handleCareerObject.end_year) > 0 ? parseInt(handleCareerObject.end_year) : d.getFullYear()+(2-1) }
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv) && moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv} prefix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.totalIncomeFurCareerAdv) && moduleServices.module17Data.totalIncomeFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalIncomeFurCareerAdv} prefix={moduleServices.module17Data.totalIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalIncomeFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherincomeEarned) && moduleServices.module17Data.furthermoreHigherincomeEarned !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherincomeEarned} prefix={moduleServices.module17Data.furthermoreHigherincomeEarned >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherincomeEarned < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Total Taxes Paid
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.totalTaxesPaidFurCareerAdv) && moduleServices.module17Data.totalTaxesPaidFurCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalTaxesPaidFurCareerAdv} prefix={moduleServices.module17Data.totalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalTaxesPaidFurCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv) && moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv} prefix={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid) && moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid} prefix={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classNames(classes.livingRow, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setExpandLivingExpenses(!expandLivingExpenses)}}>
            <td>
              <span>
                {!expandLivingExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {expandLivingExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Living Expenses</b>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.livingExpensesFurCareerAdv) && moduleServices.module17Data.livingExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.livingExpensesFurCareerAdv} prefix={moduleServices.module17Data.livingExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.livingExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furCareerAdvLivingExpenses) && moduleServices.module17Data.furCareerAdvLivingExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvLivingExpenses} prefix={moduleServices.module17Data.furCareerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvLivingExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherlivingExpensesPaid) && moduleServices.module17Data.furthermoreHigherlivingExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Rent
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent / 12} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rentyear} prefix={tableObj.Rentyear >= 0 ? '$' : '($'} suffix={tableObj.Rentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Utilities
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities / 12} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilitiesyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilitiesyear} prefix={tableObj.Utilitiesyear >= 0 ? '$' : '($'} suffix={tableObj.Utilitiesyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Food
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food / 12} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Foodyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Foodyear} prefix={tableObj.Foodyear >= 0 ? '$' : '($'} suffix={tableObj.Foodyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Clothing & Personal Care
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing / 12} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothingyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothingyear} prefix={tableObj.Clothingyear >= 0 ? '$' : '($'} suffix={tableObj.Clothingyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Entertainment
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment / 12} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainmentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainmentyear} prefix={tableObj.Entertainmentyear >= 0 ? '$' : '($'} suffix={tableObj.Entertainmentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Technology
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology / 12} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technologyyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technologyyear} prefix={tableObj.Technologyyear >= 0 ? '$' : '($'} suffix={tableObj.Technologyyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Transportation
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation / 12} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportationyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportationyear} prefix={tableObj.Transportationyear >= 0 ? '$' : '($'} suffix={tableObj.Transportationyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Miscellaneous
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous / 12} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneousyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneousyear} prefix={tableObj.Miscellaneousyear >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneousyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Costs outside Premium
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium'] / 12} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium']} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premiumyear']} prefix={tableObj['Healthcare Costs other than Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Premium
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium'] / 12} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium']} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premiumyear']} prefix={tableObj['Health Insurance Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classNames(classes.otherExpenses, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setOtherExpenses(!otherExpenses)}}>
            <td>
              <span>
                {!otherExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {otherExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Other Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module17Data.furCareerAdvOtherExpenses) && moduleServices.module17Data.furCareerAdvOtherExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module17Data.furCareerAdvOtherExpenses}
                    prefix={moduleServices.module17Data.furCareerAdvOtherExpenses >= 0 ? '$' : '($'}
                    suffix={moduleServices.module17Data.furCareerAdvOtherExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module17Data.furOtherAnnaulLivingExpensesCareerPath) && moduleServices.module17Data.furOtherAnnaulLivingExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module17Data.furOtherAnnaulLivingExpensesCareerPath} prefix={moduleServices.module17Data.furOtherAnnaulLivingExpensesCareerPath >= 0 ? '$' : '($'}
                    suffix={moduleServices.module17Data.furOtherAnnaulLivingExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module17Data.furOtherEducationLivingExpensesPaid) && moduleServices.module17Data.furOtherEducationLivingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furOtherEducationLivingExpensesPaid} prefix={moduleServices.module17Data.furOtherEducationLivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furOtherEducationLivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Higher Education Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.higherEducationExpensesFurCareerAdv) && moduleServices.module17Data.higherEducationExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.higherEducationExpensesFurCareerAdv} prefix={moduleServices.module17Data.higherEducationExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.higherEducationExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furCareerAdvHigherEducationExpenses) && moduleServices.module17Data.furCareerAdvHigherEducationExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHigherEducationExpenses} prefix={moduleServices.module17Data.furCareerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHigherEducationExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherEducationExpensesPaid) && moduleServices.module17Data.furthermoreHigherEducationExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Children Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid) && moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furCareerAdvChildrenExpenses) && moduleServices.module17Data.furCareerAdvChildrenExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvChildrenExpenses} prefix={moduleServices.module17Data.furCareerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvChildrenExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid) && moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Pet Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.petExpensesFurCareerAdv) && moduleServices.module17Data.petExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.petExpensesFurCareerAdv} prefix={moduleServices.module17Data.petExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.petExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furCareerAdvPetExpenses) && moduleServices.module17Data.furCareerAdvPetExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvPetExpenses} prefix={moduleServices.module17Data.furCareerAdvPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvPetExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherPetExpensesPaid) && moduleServices.module17Data.furthermoreHigherPetExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherPetExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherPetExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherPetExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Car Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.carExpensesFurCareerAdv) && moduleServices.module17Data.carExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.carExpensesFurCareerAdv} prefix={moduleServices.module17Data.carExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.carExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furCareerAdvCarExpenses) && moduleServices.module17Data.furCareerAdvCarExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvCarExpenses} prefix={moduleServices.module17Data.furCareerAdvCarExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvCarExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherCarExpensesPaid) && moduleServices.module17Data.furthermoreHigherCarExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherCarExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherCarExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherCarExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Real Estate Expenses
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module17Data.realEstateExpensesFurCareerAdv) && moduleServices.module17Data.realEstateExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.realEstateExpensesFurCareerAdv} prefix={moduleServices.module17Data.realEstateExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.realEstateExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module17Data.furCareerAdvHomeExpenses) && moduleServices.module17Data.furCareerAdvHomeExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHomeExpenses} prefix={moduleServices.module17Data.furCareerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHomeExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid) && moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Loan Payments
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.internetExpensesFurCareerAdv) && moduleServices.module17Data.internetExpensesFurCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.internetExpensesFurCareerAdv} prefix={moduleServices.module17Data.internetExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.internetExpensesFurCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.furCareerAdvFinancingExpenses) && moduleServices.module17Data.furCareerAdvFinancingExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvFinancingExpenses} prefix={moduleServices.module17Data.furCareerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvFinancingExpenses < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid) && moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Total Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv) && moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv} prefix={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furCareerAdvTotalAnnualExpenses) && moduleServices.module17Data.furCareerAdvTotalAnnualExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses} prefix={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furCareerAdvTotalExpenses) && moduleServices.module17Data.furCareerAdvTotalExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvTotalExpenses} prefix={moduleServices.module17Data.furCareerAdvTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvTotalExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.monthlyNetIncomeFurCareerAdv) && moduleServices.module17Data.monthlyNetIncomeFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv} prefix={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furCareerAdvAnnualNetIncome) && moduleServices.module17Data.furCareerAdvAnnualNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvAnnualNetIncome} prefix={moduleServices.module17Data.furCareerAdvAnnualNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvAnnualNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module17Data.furthermoreHigherNetIncome) && moduleServices.module17Data.furthermoreHigherNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherNetIncome} prefix={moduleServices.module17Data.furthermoreHigherNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>

          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.retirementContributionsFurCareerAdv) && moduleServices.module17Data.retirementContributionsFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.retirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.retirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.retirementContributionsFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.annualRetirementContributionsFurCareerAdv) && moduleServices.module17Data.annualRetirementContributionsFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.finalRetirementContributionsFurCareerAdv) && moduleServices.module17Data.finalRetirementContributionsFurCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>HSA Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.careerNewHSAContributions) && moduleServices.module17Data.careerNewHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.careerNewHSAContributions / 12} prefix={moduleServices.module17Data.careerNewHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.careerNewHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.careerNewHSAContributions) && moduleServices.module17Data.careerNewHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.careerNewHSAContributions} prefix={moduleServices.module17Data.careerNewHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.careerNewHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module17Data.finalcareerNewHSAContributions) && moduleServices.module17Data.finalcareerNewHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.finalcareerNewHSAContributions} prefix={moduleServices.module17Data.finalcareerNewHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.finalcareerNewHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i><b>% of Total Income</b></i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv) && moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv !== 0 && !isNaN(moduleServices.module17Data.monthlyNetIncomeFurCareerAdv) && moduleServices.module17Data.monthlyNetIncomeFurCareerAdv !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv / moduleServices.module17Data.monthlyNetIncomeFurCareerAdv) * 100} prefix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv / moduleServices.module17Data.monthlyNetIncomeFurCareerAdv >= 0 ? '' : '('} suffix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv / moduleServices.module17Data.monthlyNetIncomeFurCareerAdv < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module17Data.totalIncomeFurCareerAdv) && moduleServices.module17Data.totalIncomeFurCareerAdv !== 0 && !isNaN(moduleServices.module17Data.furCareerAdvAnnualNetIncome) && moduleServices.module17Data.furCareerAdvAnnualNetIncome !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module17Data.totalIncomeFurCareerAdv / moduleServices.module17Data.furCareerAdvAnnualNetIncome) * 100} prefix={moduleServices.module17Data.totalIncomeFurCareerAdv / moduleServices.module17Data.furCareerAdvAnnualNetIncome >= 0 ? '' : '('} suffix={moduleServices.module17Data.totalIncomeFurCareerAdv / moduleServices.module17Data.furCareerAdvAnnualNetIncome < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {(!isNaN(moduleServices.module17Data.finalNetCashFlowFurCareerAdv) && moduleServices.module17Data.finalNetCashFlowFurCareerAdv !== 0 && !isNaN(moduleServices.module17Data.furthermoreHigherincomeEarned) && moduleServices.module17Data.furthermoreHigherincomeEarned !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module17Data.finalNetCashFlowFurCareerAdv / moduleServices.module17Data.furthermoreHigherincomeEarned) * 100} prefix={moduleServices.module17Data.finalNetCashFlowFurCareerAdv / moduleServices.module17Data.furthermoreHigherincomeEarned >= 0 ? '' : '('} suffix={moduleServices.module17Data.finalNetCashFlowFurCareerAdv / moduleServices.module17Data.furthermoreHigherincomeEarned < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CareerBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};
export default withStyles(styles)(CareerBottom);
