function reiOutputBackendFile( module14Data, module13Data, invPropertyRoiData, mortgage2Data ) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;
  let homeValue = 0;
  let mortgageBalanceValue = 0;
  let paymentValue = 0;
  let principalPaybackValue = 0;
  let interestValue = 0;

  const reiOutputBackend = {};
  year = parseInt( module14Data.year_of_purchase, 10 );
  yearLimit = parseInt( module14Data.year_of_purchase, 10 ) + 30;

  // reiOutputBackend = {};

  const homeValArr = [];
  const mortgageBalanceArr = [];
  const paymanetArr = [];
  const principalPaybackArr = [];
  const interestArr = [];

  const rentalIncomeValArr = [];
  const mortgagePaymentValArr = [];
  const associationValArr = [];
  const managementFeeValArr = [];
  const homeownersInsuranceValArr = [];
  const propertymaintenanceValArr = [];
  const utilitiesValArr = [];
  const propertyTaxesValArr = [];
  const barGraphYearsListArr = [];
  const barGraphSecondYearsListArr = [];

  // let k = 0;
  for ( year; year < yearLimit; year += 1 ) {
    reiOutputBackend[year] = {};

    // Home Value
    if ( module14Data.year_of_sale !== undefined && module14Data.year_of_sale !== '' ) {
      if ( year <= module14Data.year_of_sale && invPropertyRoiData['Real Estate'] !== undefined && invPropertyRoiData['Real Estate'][year] !== undefined && invPropertyRoiData['Real Estate'][year]['Asset Value'] !== '' ) {
        homeValue = invPropertyRoiData['Real Estate'][year]['Asset Value'];
      } else {
        homeValue = 0;
      }
    } else {
      homeValue *= ( 1 + ( module13Data.appreciation_in_percentage ) / 100 );
    }

    // Mortgage Balance
    if ( mortgage2Data['Summation Data'][year] !== undefined && mortgage2Data['Summation Data'][year].EndingBalance !== undefined ) {
      mortgageBalanceValue = mortgage2Data['Summation Data'][year].EndingBalance;
    } else {
      mortgageBalanceValue = 0;
    }

    // Payment value
    if ( mortgage2Data['Summation Data'][year] !== undefined
                     && mortgage2Data['Summation Data'][year].LaggingInterest !== undefined
                     && mortgage2Data['Summation Data'][year].LaggingPrincipal !== undefined ) {
      paymentValue = mortgage2Data['Summation Data'][year].LaggingInterest + mortgage2Data['Summation Data'][year].LaggingPrincipal;
    } else {
      paymentValue = 0;
    }

    // Principal Payback value
    if ( mortgage2Data['Summation Data'][year] !== undefined && mortgage2Data['Summation Data'][year].LaggingPrincipal !== undefined ) {
      principalPaybackValue = mortgage2Data['Summation Data'][year].LaggingPrincipal;
    } else {
      principalPaybackValue = 0;
    }

    // Interest Value
    if ( mortgage2Data['Summation Data'][year] !== undefined && mortgage2Data['Summation Data'][year].LaggingInterest !== undefined ) {
      interestValue = mortgage2Data['Summation Data'][year].LaggingInterest;
    } else {
      interestValue = 0;
    }
    if ( homeValue > 0 || mortgageBalanceValue > 0 || paymentValue > 0 || principalPaybackValue > 0 || interestValue > 0 ) {
      homeValArr.push( homeValue );
      mortgageBalanceArr.push( mortgageBalanceValue );
      paymanetArr.push( paymentValue );
      principalPaybackArr.push( principalPaybackValue );
      interestArr.push( interestValue );
      barGraphSecondYearsListArr.push( year );
    }

    reiOutputBackend[year]['Rental Income'] = homeValue;
    reiOutputBackend[year]['Mortgage Payment'] = mortgageBalanceValue;
    reiOutputBackend[year].Payment = paymentValue;
    reiOutputBackend[year]['Principal Payback'] = principalPaybackValue;
    reiOutputBackend[year].Interest = interestValue;

    // Rental Income
    let rentalIncomeVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Rental Income'] !== '' ) {
      rentalIncomeVal = Math.round( invPropertyRoiData.Data1[year]['Rental Income'] );
    } else {
      rentalIncomeVal = 0;
    }

    // Mortgage Payment
    let mortgagePaymentVal = 0;
    if ( reiOutputBackend[year] !== undefined && reiOutputBackend[year].Interest !== '' ) {
      mortgagePaymentVal = Math.round( reiOutputBackend[year].Interest );
    } else {
      mortgagePaymentVal = 0;
    }

    // Association Fee
    let associationVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Association Fees'] !== '' ) {
      associationVal = Math.round( invPropertyRoiData.Data1[year]['Association Fees'] );
    } else {
      associationVal = 0;
    }

    // Management Fee
    let managementFeeVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Management Fees'] !== '' ) {
      managementFeeVal = Math.round( invPropertyRoiData.Data1[year]['Management Fees'] );
    } else {
      managementFeeVal = 0;
    }

    // Houseowners Insurance Fee
    let homeownersInsuranceVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Houseowners Insurance'] !== '' ) {
      homeownersInsuranceVal = Math.round( invPropertyRoiData.Data1[year]['Houseowners Insurance'] );
    } else {
      homeownersInsuranceVal = 0;
    }

    // Property Maintenance Fee
    let propertymaintenanceVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Property Maintenance'] !== '' ) {
      propertymaintenanceVal = Math.round( invPropertyRoiData.Data1[year]['Property Maintenance'] );
    } else {
      propertymaintenanceVal = 0;
    }

    // Utilities
    let utilitiesVal = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year].Utilities !== '' ) {
      utilitiesVal = Math.round( invPropertyRoiData.Data1[year].Utilities );
    } else {
      utilitiesVal = 0;
    }

    // Property Taxes
    let propertyTaxesVal = 0;
    if ( invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year]['Property Taxes'] !== '' ) {
      propertyTaxesVal = Math.round( invPropertyRoiData.Data1[year]['Property Taxes'] );
    } else {
      propertyTaxesVal = 0;
    }

    // k += 1;

    if ( rentalIncomeVal > 0 || mortgagePaymentVal > 0 || associationVal > 0 || managementFeeVal > 0 || homeownersInsuranceVal > 0 || propertymaintenanceVal > 0 || utilitiesVal > 0 || propertyTaxesVal ) {
      rentalIncomeValArr.push( rentalIncomeVal );
      mortgagePaymentValArr.push( mortgagePaymentVal );
      associationValArr.push( associationVal );
      managementFeeValArr.push( managementFeeVal );
      homeownersInsuranceValArr.push( homeownersInsuranceVal );
      propertymaintenanceValArr.push( propertymaintenanceVal );
      utilitiesValArr.push( utilitiesVal );
      propertyTaxesValArr.push( propertyTaxesVal );
      barGraphYearsListArr.push( year );
    }
  }
  // To return Output backend Complete Data
  function reiOutputBackendData() {
    const graphvaluesData = {};
    graphvaluesData['Bar Graph1'] = {};
    graphvaluesData['Bar Graph2'] = {};
    graphvaluesData['Pie Chart1'] = {};

    graphvaluesData['Bar Graph1']['Years List'] = barGraphSecondYearsListArr;
    graphvaluesData['Bar Graph1']['Home Value'] = homeValArr;
    graphvaluesData['Bar Graph1']['Mortgage Balance'] = mortgageBalanceArr;
    graphvaluesData['Bar Graph1'].Payment = paymanetArr;
    graphvaluesData['Bar Graph1']['Principal Payback'] = principalPaybackArr;
    graphvaluesData['Bar Graph1'].Interest = interestArr;

    graphvaluesData['Bar Graph2']['Years List'] = barGraphYearsListArr;
    graphvaluesData['Bar Graph2']['Rental Income'] = rentalIncomeValArr;
    graphvaluesData['Bar Graph2']['Mortgage Payment'] = mortgagePaymentValArr;
    graphvaluesData['Bar Graph2']['Association Fees'] = associationValArr;
    graphvaluesData['Bar Graph2']['Management Fee'] = managementFeeValArr;
    graphvaluesData['Bar Graph2']['Homeowners Insurance'] = homeownersInsuranceValArr;
    graphvaluesData['Bar Graph2']['Property Maintenance'] = propertymaintenanceValArr;
    graphvaluesData['Bar Graph2'].Utilities = utilitiesValArr;
    graphvaluesData['Bar Graph2']['Property Taxes'] = propertyTaxesValArr;

    // Second Pie Chart Values
    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== ''
                     && invPropertyRoiData['Buying Expenses'] !== undefined && invPropertyRoiData['Buying Expenses'] !== ''
                     && invPropertyRoiData['Buying Expenses']['Down Payment'] !== undefined && invPropertyRoiData['Buying Expenses']['Down Payment'] !== '' ) {
      graphvaluesData['Pie Chart1']['Down Payment'] = invPropertyRoiData['Buying Expenses']['Down Payment'];
    } else {
      graphvaluesData['Pie Chart1']['Down Payment'] = 0;
    }

    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== ''
                     && invPropertyRoiData['Buying Expenses'] !== undefined && invPropertyRoiData['Buying Expenses'] !== ''
                     && invPropertyRoiData['Buying Expenses']['Closing Costs'] !== undefined && invPropertyRoiData['Buying Expenses']['Closing Costs'] !== '' ) {
      graphvaluesData['Pie Chart1']['Closing Costs'] = invPropertyRoiData['Buying Expenses']['Closing Costs'];
    } else {
      graphvaluesData['Pie Chart1']['Closing Costs'] = 0;
    }

    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== ''
                     && invPropertyRoiData['Buying Expenses'] !== undefined && invPropertyRoiData['Buying Expenses'] !== ''
                     && invPropertyRoiData['Buying Expenses']['Fit Out Costs'] !== undefined && invPropertyRoiData['Buying Expenses']['Fit Out Costs'] !== '' ) {
      graphvaluesData['Pie Chart1']['Fit Out Costs'] = invPropertyRoiData['Buying Expenses']['Fit Out Costs'];
    } else {
      graphvaluesData['Pie Chart1']['Fit Out Costs'] = 0;
    }

    // Total Amount
    graphvaluesData['Pie Chart1'].Total = parseFloat( graphvaluesData['Pie Chart1']['Down Payment'] )
                + parseFloat( graphvaluesData['Pie Chart1']['Closing Costs'] )
                + parseFloat( graphvaluesData['Pie Chart1']['Fit Out Costs'] );

    graphvaluesData['REI Output Backend'] = reiOutputBackend;
    return graphvaluesData;
  }
  return reiOutputBackendData();
}

export default reiOutputBackendFile;
