import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from '../../../screens/pwi/financial-independence/styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

/* eslint-disable no-template-curly-in-string */
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

HCExporting(Highcharts);



const StartYear = (props) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [planYear, setPlanYear] = React.useState('');
  const [graphData, setGraphData] = React.useState({});
  const [yearOption, setYearOption] = React.useState([]);

  const d = new Date();
  const currentYear = d.getFullYear();
 

  useEffect(() => {
    const years = [];
    if (inputData.start_year !== undefined) {
      setPlanYear(inputData.start_year);      
      for (let year = inputData.start_year; year <= (inputData.start_year + 14); year += 1) {
        years.push(year);
      }
      setYearOption(years);
    } else {
      for (let year = currentYear; year <= (currentYear+14); year += 1) {
        years.push(year);
      }
      setYearOption(years);
      updateData('start_year', currentYear);
    }
  },[]);

  const handlePlanYear = (e) => {
    setPlanYear(e.target.value);
    updateData('start_year', e.target.value);
  };

  useEffect(() => {
    let startYearGraphData = {};
    startYearGraphData['Start Year Graph Data'] = {};
    startYearGraphData['Start Year Graph Data']['First'] = {};
    startYearGraphData['Start Year Graph Data']['Second'] = {};
    startYearGraphData['Start Year Graph Data']['Third'] = {};

    let firstMonthlySavings = 500;
    let secondMonthlySavings = 500;
    let thirdMonthlySavings = 500;
    let annualRate = 6;
    let monthlyRate = annualRate / 12;


    let firstBB = 0;
    let secondBB = 0;
    let thirdBB = 0;

    const firstFinalGraph = [];
    const secondFinalGraph = [];
    const thirdFinalGraph = [];
    const yearsList = [];

    for (let year = 1; year < 41; year += 1) {

      startYearGraphData['Start Year Graph Data']['First'][year] = {};
      startYearGraphData['Start Year Graph Data']['Second'][year] = {};
      startYearGraphData['Start Year Graph Data']['Third'][year] = {};

      for (let month = 1; month <= 12; month += 1) {
        startYearGraphData['Start Year Graph Data']['First'][year][month] = {};
        startYearGraphData['Start Year Graph Data']['Second'][year][month] = {};
        startYearGraphData['Start Year Graph Data']['Third'][year][month] = {};

        if (year === 1 && month === 1) {
          startYearGraphData['Start Year Graph Data']['First'][year][month]['BB'] = 0;
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['BB'] = 0;
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['BB'] = 0;
        } else {
          startYearGraphData['Start Year Graph Data']['First'][year][month]['BB'] = firstBB;
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['BB'] = secondBB;
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['BB'] = thirdBB;
        }


        startYearGraphData['Start Year Graph Data']['First'][year][month]['PMT'] = firstMonthlySavings;

        if (year >= 6) {
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['PMT'] = secondMonthlySavings;
        } else {
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['PMT'] = 0;
        }
        if (year >= 11) {
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['PMT'] = thirdMonthlySavings;
        } else {
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['PMT'] = 0;
        }

        startYearGraphData['Start Year Graph Data']['First'][year][month]['Earnings'] = ((startYearGraphData['Start Year Graph Data']['First'][year][month]['BB'] + startYearGraphData['Start Year Graph Data']['First'][year][month]['PMT']) / 100) * monthlyRate;

        startYearGraphData['Start Year Graph Data']['Second'][year][month]['Earnings'] = ((startYearGraphData['Start Year Graph Data']['Second'][year][month]['BB'] + startYearGraphData['Start Year Graph Data']['Second'][year][month]['PMT']) / 100) * monthlyRate;

        startYearGraphData['Start Year Graph Data']['Third'][year][month]['Earnings'] = ((startYearGraphData['Start Year Graph Data']['Third'][year][month]['BB'] + startYearGraphData['Start Year Graph Data']['Third'][year][month]['PMT']) / 100) * monthlyRate;


        firstBB = startYearGraphData['Start Year Graph Data']['First'][year][month]['BB'] +
          startYearGraphData['Start Year Graph Data']['First'][year][month]['PMT'] +
          startYearGraphData['Start Year Graph Data']['First'][year][month]['Earnings'];

        startYearGraphData['Start Year Graph Data']['First'][year][month]['EB'] = firstBB;

        secondBB = startYearGraphData['Start Year Graph Data']['Second'][year][month]['BB'] +
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['PMT'] +
          startYearGraphData['Start Year Graph Data']['Second'][year][month]['Earnings'];
        startYearGraphData['Start Year Graph Data']['Second'][year][month]['EB'] = secondBB;

        thirdBB = startYearGraphData['Start Year Graph Data']['Third'][year][month]['BB'] +
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['PMT'] +
          startYearGraphData['Start Year Graph Data']['Third'][year][month]['Earnings'];

        startYearGraphData['Start Year Graph Data']['Third'][year][month]['EB'] = thirdBB;

        if (month === 12) {
          firstFinalGraph.push(startYearGraphData['Start Year Graph Data']['First'][year][month]['EB']);
          secondFinalGraph.push(startYearGraphData['Start Year Graph Data']['Second'][year][month]['EB']);
          thirdFinalGraph.push(startYearGraphData['Start Year Graph Data']['Third'][year][month]['EB']);
          yearsList.push(year);
        }
        if (year === 40 && month === 12) {
          setGraphData({ firstData: firstFinalGraph, secondData: secondFinalGraph, thirdData: thirdFinalGraph, yearsList });
        }
      }
    }
    // eslint-disable-next-lines
  }, [])

  return (
    <div className={classes.contentBlock}>
      <p className={classes.lineHeight35}>Time is your most valuable asset. And the best time to start planning is now. For example, the chart below shows when it comes to investing and compounding interest, the earlier you start, the more you will earn over your lifetime.</p>
      <p>In what year will your plan begin? Choose a year to start your timeline and plan your life events.</p>
      <ul className={classes.myPlanYear}>
        <li className={classes.displayFlex}>
          <span>My plan will start in year </span>
          <select value={planYear} onChange={handlePlanYear}>
            {yearOption.map((year) => <option value={year} selected={year === planYear ? "selected" : ''}>{year}</option>)}
          </select>
          .
        </li>
      </ul>
      <div className={classes.graphBox}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'spline',
              scrollablePlotArea: {
                width: 600,
                scrollPositionX: 0,
              },
              events: {
                load() {
                  const chart = this;
                  const text = chart.renderer.text(
                    '<span style="font-size:14px;">Each line below represents a person starting </span><br/> <span style="font-size:14px;"> to save $500 per month at different ages </span><br/> <span style="font-size:14px;">and earning a 6% return each year. Starting </span> <br/> <span style="font-size:14px;">early can make a big difference. How big? </span> <br/> <span style="font-size:14px;">Hover over the lines below and find out.</span>',
                    chart.plotLeft + 120,
                    chart.plotTop + 35
                  ).attr({
                    zIndex: 5
                  }).add();
                  const box = text.getBBox();
                  chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
                    .attr({
                      fill: '#FFFFFF',
                      stroke: '#2f75b5',

                      'stroke-width': 1,
                      zIndex: 4
                    })
                    .add();
                }
              }
            },
            navigation: {
              menuItemStyle: {
                fontSize: '14px',
                textAlign: 'left',
              },
              menuItemHoverStyle: {
                background: '#f5f5f5',
                color: '#4c4c4c',
                fontSize: '14px',
              },
              buttonOptions: {
                height: 40,
                width: 48,
                symbolSize: 24,
                symbolX: 24,
                symbolY: 21,
                symbolStrokeWidth: 2,
                verticalAlign: 'bottom',
                _titleKey: 'y',
              },

            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                },
              },
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'The Power of Compounding Interest and Time',
              enabled: true,
            },
            xAxis: [{
              tickInterval: 1,
              categories: graphData.yearsList && graphData.yearsList,
              labels: {
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            }],
            yAxis: [{ // Primary yAxis
              labels: {
                formatter: function () {
                  if (this.value < 0) {
                    return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(this.value)) + ')</span>';
                  } else {
                    return formatDollar(Math.round(this.value));
                  }
                },
                style: {
                  color: '#000000',
                  fontSize: '14px'
                }
              },
              title: {
                text: '',
                style: {
                  color: '#000000',
                  fontSize: '14px'
                }
              }
            }],
            tooltip: {
              headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
              crosshairs: true,
              shared: true,
              valueDecimals: 0,
              valuePrefix: '$',
              style: {
                color: '#000000',
                fontSize: '14px',
              },
            },
            legend: {
              itemStyle: {
                fontSize: '14px',
              },
            },
            plotOptions: {
              spline: {
                lineWidth: 2,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
                marker: {
                  enabled: false,
                },

              },
            },
            series: [
              {
                name: 'Starts at 21',
                color: '#4472C4',
                data: graphData.firstData && graphData.firstData,
              },
              {
                name: 'Starts at 26',
                color: '#Ed7D31',
                data: graphData.secondData && graphData.secondData,

              },
              {
                name: 'Starts at 31',
                color: '#A5A5A5',
                data: graphData.thirdData && graphData.thirdData,

              },
            ],
          }}
        />
      </div>

    </div>
  );
};

StartYear.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles(styles)(StartYear);
