import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Table,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();
const HowToStartInvesting = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>How to Start Investing</h3>
                    </div>
                    <div className={ classNames( classes.pfVideo, classes.iframeHeight ) }>
                      <YouTube videoId="_-Zqz75r9PQ" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/start-investing.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>How to Start Investing</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/start-investing-300px.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            You&apos;ve learned that small amounts of money invested consistently over time can grow to large amounts of money. You&apos;ve also learned how to build a budget and discover exactly how much you can save and invest each month. Remember, a good benchmark for savings and investments would be 10-20% of your after-tax income. But, how do you choose what to invest in? There are three main goals in investing: liquidity, income, and growth.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Liquidity</b>
                          {' '}
                          is a measure of how easily an asset can be converted into cash. For example, your savings account is a highly liquid asset. Why? Because you can go to the bank and withdraw cash in one day. Your home is highly illiquid. Why? Because you have to hire a real estate agent,
                          list your home for sale, and go through a long process to sell your home and receive cash. Having liquid investments is a safety net for unplanned financial emergencies or other investment opportunities that might suddenly come along.
                          <p>
                            <b>Income investing</b>
                            {' '}
                            is focused on preserving your investments and generating more predictable, consistent income. Bonds would be an example of income investing as they pay regularly scheduled interest to you.
                            {' '}
                            <b>Growth investing,</b>
                            {' '}
                            on the other hand, is focused on growing your investments and taking more risk for the long term. Individual stocks would be an example of growth investing as you hope the stock price goes up in the long run.
                          </p>
                          <p>Your investing strategy can have financial assets that are liquid, that earn consistent income, and that have the potential for growth. By having different investments with different goals, you are diversifying, or spreading out, your risk. Don&apos;t put all your eggs in one basket is wise investment advice.</p>
                        </p>
                      </div>
                      <div className={ classes.howToInvest }>
                        <Table>
                          <thead>
                            <tr>
                              <th />
                              <th>Checking and Savings Accounts</th>
                              <th>High-Yield Savings Accounts</th>
                              <th>Certificates of Deposit</th>
                              <th>Treasury Securities</th>
                              <th>Money Market Accounts</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Level of Risk</td>
                              <td>Very low; insured by FDIC up to $250k</td>
                              <td>Very low; insured by FDIC up to $250k</td>
                              <td>Very low; insured by FDIC up to $250k</td>
                              <td>Very low; backed by the government</td>
                              <td>Very low; insured by FDIC up to $250k</td>
                            </tr>
                            <tr>
                              <td>Level of Reward</td>
                              <td>Lowest</td>
                              <td>Low, but higher than checking and savings</td>
                              <td>Low to medium; higher than checking and savings</td>
                              <td>Low to medium; higher than checking and savings</td>
                              <td>Low to medium; higher than checking and savings</td>
                            </tr>
                            <tr>
                              <td>Estimated Return* (Annual)</td>
                              <td>0.1%</td>
                              <td>1.5-2.5%</td>
                              <td>1.5-2.5%</td>
                              <td>1.5-2.5%</td>
                              <td>1.5-2.5%</td>
                            </tr>
                            <tr>
                              <td>Fees</td>
                              <td>Low; maintain minimum balance and do not overdraft</td>
                              <td>Low to none</td>
                              <td>None; unless you withdraw your money early (10% fee)</td>
                              <td>Low to none; fees to purchase the security</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Liquidity</td>
                              <td>Checking: highly liquid Savings: low; limited to six transactions per month</td>
                              <td>Low; limited to six transactions per month</td>
                              <td>Not liquid; money is locked up for certain amount of time</td>
                              <td>Highly liquid</td>
                              <td>Low; limited to six transactions per month</td>
                            </tr>
                            <tr>
                              <td>Taxes on Interest Earned</td>
                              <td>Federal, state, and local</td>
                              <td>Federal, state, and local</td>
                              <td>Federal, state, and local</td>
                              <td>
                                Interest earned is federally taxable;
                                <br />
                                {' '}
                                not state or local
                              </td>
                              <td>Federal, state, and local</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.noteText }>
                          Note:
                          <i>
                            The material presented above is purely educational and not meant to be investment advice.
                            *Estimated returns are as of July 2019
                          </i>
                        </p>
                      </div>
                      <div className={ classes.howToInvest }>
                        <Table>
                          <thead>
                            <tr>
                              <th />
                              <th>Company Stock</th>
                              <th>Interest-Paying Corporate Bond</th>
                              <th>Mutual Funds</th>
                              <th>Exchange-Traded Funds (ETFs)</th>
                              <th>Alternative Investments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Example</td>
                              <td>Boeing</td>
                              <td>Boeing&apos;s Bonds</td>
                              <td>A mix of investments that can include stocks, bonds, money markets</td>
                              <td>A mix of investments that can include stocks, bonds</td>
                              <td>Commodities like gold, hedge funds, real estate</td>
                            </tr>
                            <tr>
                              <td>Level of Risk</td>
                              <td>High</td>
                              <td>Low to medium</td>
                              <td>Medium</td>
                              <td>Medium</td>
                              <td>High</td>
                            </tr>
                            <tr>
                              <td>Level of Reward</td>
                              <td>High</td>
                              <td>Low to medium</td>
                              <td>Medium to high</td>
                              <td>Medium to high</td>
                              <td>High</td>
                            </tr>
                            <tr>
                              <td>Type of Reward</td>
                              <td>Price appreciation and dividend</td>
                              <td>Price appreciation and periodic interest payments</td>
                              <td>Price appreciation and dividends</td>
                              <td>Price appreciation and dividends</td>
                              <td>Price appreciation and dividends</td>
                            </tr>
                            <tr>
                              <td>Estimated Return* (Annual)</td>
                              <td>5 - 10%</td>
                              <td>1.5 - 7.5%</td>
                              <td>5 - 10%</td>
                              <td>5 - 10%</td>
                              <td>1.5 - 2.5%</td>
                            </tr>
                            <tr>
                              <td>Fees</td>
                              <td>Low; trading fees</td>
                              <td>Low; trading fees</td>
                              <td>Low to medium; management fees</td>
                              <td>Low</td>
                              <td>Medium to high; management fees</td>
                            </tr>
                            <tr>
                              <td>Liquidity</td>
                              <td>Medium liquid</td>
                              <td>Medium liquid</td>
                              <td>Medium liquid</td>
                              <td>Highly liquid</td>
                              <td>Low to medium</td>
                            </tr>
                            <tr>
                              <td>Taxes on Earnings</td>
                              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income</td>
                              <td>Price appreciation taxed at capital gains rate (∼10-25%); Interest is taxed as ordinary income income</td>
                              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income</td>
                              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income  </td>
                              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className={ classes.fiRow }>
                        <p className={ classes.noteText }>
                          Note:
                          <i>
                            The material presented above is purely educational and not meant to be investment advice.
                            *Estimated returns are as of July 2019
                          </i>
                        </p>
                        <div className={ classes.creditText }>
                          <p>Investing requires a long-term game plan. Money doesn&apos;t double overnight. But, how long does it take for money to double? The Rule of 72 is a quick and easy way to estimate how long an investment will take to double given a fixed annual rate of return or interest. Check out the formula and the table below to see the Rule of 72 in action.</p>
                          <p># of Years to Double in Value = 72 / Annual Rate of Return (or interest rate)</p>
                        </div>
                      </div>
                      <div className={ classes.ruleImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/start-investing-rule_of_72.png" alt="template" />
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                            {' '}
                            Return on your investment is equal to any gain in price or income received less fees. That&apos;s why fees are so important to focus on!
                          </p>
                          <p>
                            <b>How do I make investments?</b>
                            <br />
                            You can start investing by opening a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.nerdwallet.com/article/investing/what-is-how-to-open-brokerage-account" target="_blank" rel="budget-fy2020 noopener noreferrer">brokerage account</a>

                            , which is just a financial term for an investment account with a bank or other financial institution such as
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.tdameritrade.com/home.page" target="_blank" rel="budget-fy2020 noopener noreferrer">TD Ameritrade</a>
                            ,
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.fidelity.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Fidelity</a>
                            , or
                            {' '}
                            <a className={ classes.anchorStyle } href="https://investor.vanguard.com/home" target="_blank" rel="budget-fy2020 noopener noreferrer">Vanguard.</a>
                            {' '}
                            A brokerage account allows you to trade stocks, bonds, funds and alternative investments. Look for a brokerage account with low or no fees, sign up online, start making cash contributions to the account and select investments.
                          </p>
                          <p>
                            Or, you can start investing right from your phone with a free trading app like
                            {' '}
                            <a className={ classes.anchorStyle } href="https://robinhood.com/us/en/" target="_blank" rel="budget-fy2020 noopener noreferrer">Robinhood</a>
                            ,
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.acorns.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Acorns</a>
                            ,
                            {' '}
                            or
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.m1finance.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">M1 Finance</a>
                            .
                          </p>
                          <p>
                            Do your homework before making an investment. Never make an investment you don&apos;t fully understand. Remember, money doesn&apos;t double in value overnight so have a long-term and patient mindset when it comes to investing.
                          </p>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level7/how-to-start-investing/exercise" startTime={ startTime } level={ 7 } exercise={ 5 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
HowToStartInvesting.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( HowToStartInvesting );
