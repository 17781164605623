import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Typography,
  Tabs,
  Tab,
  Box,
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, useTheme } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/financial-independence/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 10,
    label: '10%',
  },
];

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const IncomeGrowth = (props) => {
  const {
    classes, handleDefinationPopup, incomeGrowthGraphData, inputData, updateData,
  } = props;

  const [growthValue, setGrowthValue] = React.useState(3.5);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(() => {
    if (inputData.long_term_growth_in_percentage !== undefined) {
      setGrowthValue(inputData.long_term_growth_in_percentage);
    } else {
      updateData('long_term_growth_in_percentage', 3.5);
    }
  }, [inputData.long_term_growth_in_percentage]);

  const theme = useTheme();

  const [valueTab, setValueTab] = useState(0);

  const overallGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[3];
  const educationGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[0];
  const ageGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[1];
  const industryGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[2];

  const updateValue = (e, value, field, type) => {
    let newvalue = 0;
    if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setGrowthValue(newvalue);
    updateData(field, newvalue);
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setValueTab(index);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>Choose a rate of annual income growth to allow the Financial Configurator (FC) to forecast your future earnings. For example, if you specify 3.0% for income growth and make $50,000 in year 1, your income would grow to $51,500 in year 2.</p>
      <div className={classes.AnnualIncomeGrowth}>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Income Growth')}>Annual Income Growth</span></p>
          <div className={classes.pwisliderInputs}>
            <PwiSlider
              value={growthValue}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={percentFormatWithTwoDecimal}
              min={0}
              max={10}
              step={0.1}
              marks={marks}
              ValueLabelComponent={ValueLabelComponent}
              onChange={(e, value) => updateValue(e, value, 'long_term_growth_in_percentage', 'slider')}
            />
          </div>
          <div className={classes.sliderValues}>
            <NumberFormat
              allowNegative={false}
              customInput={TextField}
              value={growthValue}
              decimalScale={isFocus !== 'long_term_growth_in_percentage' ? 2 : 5}
              fixedDecimalScale={isFocus !== 'long_term_growth_in_percentage'}
              onValueChange={(e) => updateValue(e, '', 'long_term_growth_in_percentage', 'number')}
              onFocus={(e) => { e.target.select(); setIsFocus('long_term_growth_in_percentage'); }}
              onBlur={emptyIsFocus} />
            <span className={classes.dollarSymbol}>%</span>
          </div>
        </div>
      </div>
      <div className={`${classes.grayBox} ${classes.incomeGrowth}`}>
        <p>If you wish to use a different percentage, use the graphs below that show historical income growth rates filtered by categories and find a number that works for your plan. Or, stick with the default 3.5%.</p>
      </div>
      <div className={classes.tabSection}>
        <Tabs
          value={valueTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabButtons}
          aria-label="full width tabs example"
        >
          <Tab className={valueTab === 0 ? classes.activeTab : ''} label="Overall Income Growth " {...a11yProps(0)} />
          <Tab className={valueTab === 1 ? classes.activeTab : ''} label="Income Growth by Education" {...a11yProps(1)} />
          <Tab className={valueTab === 2 ? classes.activeTab : ''} label="Income Growth by Age" {...a11yProps(2)} />
          <Tab className={valueTab === 3 ? classes.activeTab : ''} label="Income Growth by Industry" {...a11yProps(3)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={valueTab}
          className={classes.tabsContent}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={valueTab} index={0} dir={theme.direction}>
            {/* <img src={ Graph1 } alt="" /> */}
            <div className={classes.graphBox}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                    scrollablePlotArea: {
                      scrollPositionX: 0,
                    },
                  },
                  navigation: {
                    menuItemStyle: {
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                    menuItemHoverStyle: {
                      background: '#f5f5f5',
                      color: '#4c4c4c',
                      fontSize: '14px',
                    },
                    buttonOptions: {
                      height: 40,
                      width: 48,
                      symbolSize: 24,
                      symbolX: 24,
                      symbolY: 21,
                      symbolStrokeWidth: 2,
                      verticalAlign: 'bottom',
                      _titleKey: 'y',
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  title: {
                    text: 'Overall Income Growth Tracker (12-month moving average)',
                    style: {
                      color: '#000000',
                      fontFamily: 'museo-sans, sans-serif',
                      fontWeight: '500'
                    },
                  },
                  xAxis: [{
                    tickInterval: 24,
                    categories: overallGraphData.dates,
                    labels: {
                      formatter() {
                        const chart = this;
                        return chart.value.split(' ')[1];
                      },
                      style: {
                        color: '#000000',
                        fontSize: '13px',
                      },
                    },
                  }],
                  yAxis: [{ // Primary yAxis
                    tickInterval: 1,
                    labels: {
                      format: '{value:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                    title: {
                      text: '',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                  }],
                  tooltip: {
                    headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                    crosshairs: true,
                    shared: true,
                    valueDecimals: 1,
                    valueSuffix: '%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  legend: {
                    itemStyle: {
                      fontSize: '14px',
                    },
                  },
                  plotOptions: {
                    spline: {
                      lineWidth: 2,
                      states: {
                        hover: {
                          lineWidth: 2,
                        },
                      },
                      marker: {
                        enabled: false,
                      },

                    },
                  },
                  series: overallGraphData.details,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={1} dir={theme.direction}>
            {/* <img src={ Graph2 } alt="" /> */}
            <div className={classes.graphBox}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                    scrollablePlotArea: {
                      scrollPositionX: 0,
                    },
                  },
                  navigation: {
                    menuItemStyle: {
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                    menuItemHoverStyle: {
                      background: '#f5f5f5',
                      color: '#4c4c4c',
                      fontSize: '14px',
                    },
                    buttonOptions: {
                      height: 40,
                      width: 48,
                      symbolSize: 24,
                      symbolX: 24,
                      symbolY: 21,
                      symbolStrokeWidth: 2,
                      verticalAlign: 'bottom',
                      _titleKey: 'y',
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  title: {
                    text: 'Income Growth Tracker by Education (12-month moving average)',
                    style: {
                      color: '#000000',
                      fontFamily: 'museo-sans, sans-serif',
                      fontWeight: '500'
                    },
                  },
                  xAxis: [{
                    tickInterval: 24,
                    categories: educationGraphData.dates,
                    labels: {
                      formatter() {
                        const chart = this;
                        return chart.value.split(' ')[1];
                      },
                      style: {
                        color: '#000000',
                        fontSize: '13px',
                      },
                    },
                  }],
                  yAxis: [{ // Primary
                    tickInterval: 1,
                    labels: {
                      format: '{value:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                    title: {
                      text: '',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                  }],
                  tooltip: {
                    headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                    crosshairs: true,
                    shared: true,
                    valueDecimals: 1,
                    valueSuffix: '%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  legend: {
                    itemStyle: {
                      fontSize: '14px',
                    },
                  },
                  plotOptions: {
                    spline: {
                      lineWidth: 2,
                      states: {
                        hover: {
                          lineWidth: 2,
                        },
                      },
                      marker: {
                        enabled: false,
                      },

                    },
                  },
                  series: educationGraphData.details,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={2} dir={theme.direction}>
            {/* <img src={ Graph3 } alt="" /> */}
            <div className={classes.graphBox}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                    scrollablePlotArea: {
                      scrollPositionX: 0,
                    },
                  },
                  navigation: {
                    menuItemStyle: {
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                    menuItemHoverStyle: {
                      background: '#f5f5f5',
                      color: '#4c4c4c',
                      fontSize: '14px',
                    },
                    buttonOptions: {
                      height: 40,
                      width: 48,
                      symbolSize: 24,
                      symbolX: 24,
                      symbolY: 21,
                      symbolStrokeWidth: 2,
                      verticalAlign: 'bottom',
                      _titleKey: 'y',
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  title: {
                    text: 'Income Growth Tracker by Age (12-month moving average)',
                    style: {
                      color: '#000000',
                      fontFamily: 'museo-sans, sans-serif',
                      fontWeight: '500'
                    },
                  },
                  xAxis: [{
                    tickInterval: 24,
                    categories: ageGraphData.dates,
                    labels: {
                      formatter() {
                        const chart = this;
                        return chart.value.split(' ')[1];
                      },
                      style: {
                        color: '#000000',
                        fontSize: '13px',
                      },
                    },
                  }],
                  yAxis: [{ // Primary
                    tickInterval: 1,
                    labels: {
                      format: '{value:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                    title: {
                      text: '',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                  }],
                  tooltip: {
                    headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                    crosshairs: true,
                    shared: true,
                    valueDecimals: 1,
                    valueSuffix: '%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  legend: {
                    itemStyle: {
                      fontSize: '14px',
                    },
                  },
                  plotOptions: {
                    spline: {
                      lineWidth: 2,
                      states: {
                        hover: {
                          lineWidth: 2,
                        },
                      },
                      marker: {
                        enabled: false,
                      },

                    },
                  },
                  series: ageGraphData.details,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={3} dir={theme.direction}>
            {/* <img src={ Graph4 } alt="" /> */}
            <div className={classes.graphBox}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                    scrollablePlotArea: {
                      scrollPositionX: 0,
                    },
                  },
                  navigation: {
                    menuItemStyle: {
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                    menuItemHoverStyle: {
                      background: '#f5f5f5',
                      color: '#4c4c4c',
                      fontSize: '14px',
                    },
                    buttonOptions: {
                      height: 40,
                      width: 48,
                      symbolSize: 24,
                      symbolX: 24,
                      symbolY: 21,
                      symbolStrokeWidth: 2,
                      verticalAlign: 'bottom',
                      _titleKey: 'y',
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  title: {
                    text: 'Income Growth Tracker by Industry (12-month moving average)',
                    style: {
                      color: '#000000',
                      fontFamily: 'museo-sans, sans-serif',
                      fontWeight: '500'
                    },
                  },
                  xAxis: [{
                    tickInterval: 24,
                    categories: industryGraphData.dates,
                    labels: {
                      formatter() {
                        const chart = this;
                        return chart.value.split(' ')[1];
                      },
                      style: {
                        color: '#000000',
                        fontSize: '13px',
                      },
                    },
                  }],
                  yAxis: [{ // Primary
                    tickInterval: 1,
                    labels: {
                      format: '{value:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                    title: {
                      text: '',
                      style: {
                        color: '#000000',
                        fontSize: '14px',
                      },
                    },
                  }],
                  tooltip: {
                    headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                    crosshairs: true,
                    shared: true,
                    valueDecimals: 1,
                    valueSuffix: '%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  legend: {
                    itemStyle: {
                      fontSize: '14px',
                    },
                  },
                  plotOptions: {
                    spline: {
                      lineWidth: 2,
                      states: {
                        hover: {
                          lineWidth: 2,
                        },
                      },
                      marker: {
                        enabled: false,
                      },

                    },
                  },
                  series: industryGraphData.details,
                }}
              />
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
    </div>
  );
};

IncomeGrowth.propTypes = {
  classes: PropTypes.object.isRequired,
  incomeGrowthGraphData: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles(styles)(IncomeGrowth);
