export const definitions = [
  {
    label: 'A',
    id: 3,
  },{
    label: "B",
    id: 19,
  },{
    label: "C",
    id: 22,
  },{
    label: "D",
    id: 43,
  },{
    label: "E",
    id: 52,
  },{
    label: "F",
    id: 57,
  },{
    label: "G",
    id: 67,
  },{
    label: "H",
    id: 70,
  },{
    label: "I",
    id: 75,
  },{
    label: "J",
    id: 0,
  },{
    label: "K",
    id: 0,
  },{
    label: "L",
    id: 86,
  },{
    label: "M",
    id: 91,
  },{
    label: "N",
    id: 102,
  },{
    label: "O",
    id: 104,
  },{
    label: "P",
    id: 111,
  },{
    label: "Q",
    id: 0,
  },{
    label: "R",
    id: 120,
  },{
    label: "S",
    id: 131,
  },{
    label: "T",
    id: 148,
  },{
    label: "U",
    id: 160,
  },{
    label: "V",
    id: 161,
  },{
    label: "W",
    id: 163,
  },{
    label: "X",
    id: 0,
  },{
    label: "Y",
    id: 0,
  },{
    label: "Z",
    id: 0,
  },
];
export const links = [
  {
    label: 'A',
    id: 'a',
  },{
    label: "B",
    id: 'b',
  },{
    label: "C",
    id: 'c',
  },{
    label: "D",
    id: 'd',
  },{
    label: "E",
    id: 'e',
  },{
    label: "F",
    id: 'f',
  },{
    label: "G",
    id: 'g',
  },{
    label: "H",
    id: 0,
  },{
    label: "I",
    id: 0,
  },{
    label: "J",
    id: 0,
  },{
    label: "K",
    id: 0,
  },{
    label: "L",
    id: 'l',
  },{
    label: "M",
    id: 'm',
  },{
    label: "N",
    id: 'n',
  },{
    label: "O",
    id: 0,
  },{
    label: "P",
    id: 0,
  },{
    label: "Q",
    id: 0,
  },{
    label: "R",
    id: 'r',
  },{
    label: "S",
    id: 's',
  },{
    label: "T",
    id: 't',
  },{
    label: "U",
    id: 0,
  },{
    label: "V",
    id: 0,
  },{
    label: "W",
    id: 0,
  },{
    label: "X",
    id: 0,
  },{
    label: "Y",
    id: 0,
  },{
    label: "Z",
    id: 0,
  },
];
