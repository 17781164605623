import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruSix = ( props ) => {
  const {
    classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,
  } = props;

  return (
    <div className={ classNames( classes.pwiWalkthru, classes.pwiWalkthruSix, classes.pwiActiveWalkthru ) }>
      <h4>Plan With Inkwiry Menu</h4>
      <p>Want to navigate to a different module in your career sketch? Click on the module icon and all modules will appear.</p>
      <div className={ classes.walkthruBottom }>
        <WalkThruDots completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } handleWalkThrus={ handleWalkThrus } />
        <div className={ classes.walkThruBtns }>
          <Button className={ classes.startWalkThru } onClick={ () => { handleWalkThrus( 6 ); } }>Next</Button>
          <Button className={ classes.skipWalkthru } onClick={ handleWalkThruClose }>Close</Button>
        </div>
      </div>
    </div>
  );
};

WalkThruSix.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  handleWalkThruClose: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruSix );
