import * as modulesServices from './modules-services';

export default function initializeExcelSheetsData() {
  // Existing Student Loan Excel Sheet Formulas Initialize
  modulesServices.existingStudentLoanInitialize();

  // Fed Sub Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedSubHigherEdLoanInitialize();

  // Fed UnSub Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedUnSubHigherEdLoanInitialize();

  // Private Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.privateHigherEdLoanInitialize();

  // Fed Sub Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedSubMoreHigherEdLoanInitialize();

  // Fed UnSub Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedUnSubMoreHigherEdLoanInitialize();

  // Private Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.privateMoreHigherEdLoanInitialize();

  // Fed Sub New Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedSubNewHigherEdLoanInitialize();

  // Fed UnSub New Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.fedUnSubNewHigherEdLoanInitialize();

  // Private New Higher Education Loan Excel Sheet Formulas Initialize
  modulesServices.privateNewHigherEdLoanInitialize();

  // Fed Sub Existing Loan Excel Sheet Formulas Initialize
  modulesServices.fedSubExistingLoanInitialize();

  // Fed UnSub Existing Loan Excel Sheet Formulas Initialize
  modulesServices.fedUnSubExistingLoanInitialize();

  // Private Existing Loan Excel Sheet Formulas Initialize
  modulesServices.privateExistingLoanInitialize();

  // Student Loan 1 Excel Sheet Formulas Initialize
  modulesServices.studentLoan1Initialize();

  // Student Loan 2 Excel Sheet Formulas Initialize
  modulesServices.studentLoan2Initialize();

  // Student Loan Tax Excel Sheet Formulas Initialize
  modulesServices.studentLoanTaxInitialize();

  // Estimated Income Taxes Excel Sheet Formulas Initialize
  modulesServices.estimatedIncomeTaxesInitialize();

  // Database Sheet Initialize
  modulesServices.databaseSheetInitialize();

  // Income Statement Excel Sheet Formulas Initialize
  modulesServices.incomeStatementInitialize();

  // Balance Sheet Formulas Initialize
  modulesServices.balanceSheetInitialize();

  // Cash Flow Statement Formulas Initialize
  modulesServices.cashflowStatementInitialize();

  // Input Sheet Formulas Initialize
  modulesServices.inputsSheetInitialize();

  // Expense Calculations Formulas Initialize
  modulesServices.expenseCalculationsInitialize();

  // Investments Calculations Formulas Initialize
  modulesServices.investmentsInitialize();

  // Income Taxes Excel Sheet Formulas Initialize
  modulesServices.incomeTaxesInitialize();

  // Assumptions Sheet Formulas Initialize
  modulesServices.assumptionsSheetInitialize();

  // Output Backend Formulas Initialize
  modulesServices.outputBackendInitialize();

  // FC Master Output Formulas Initialize
  modulesServices.fcMasterOutputInitialize();

  // Existing Car Loan Initialize
  modulesServices.existingCarLoanInitialize();

  // Existing Home Mortgage Initialize
  modulesServices.existingHomeMortgageLoanInitialize();

  // Existing Home Initialize
  modulesServices.existingHomeInitialize();

  // Car Loan 1 Initialize
  modulesServices.carLoan1Initialize();

  // Car Loan 2 Initialize
  modulesServices.carLoan2Initialize();

  // Mortage 1 Initialize
  modulesServices.mortage1Initialize();

  // Mortage 2 Initialize
  modulesServices.mortage2Initialize();

  // Depreciation Schedule Sheet
  modulesServices.depreciationSheduleInitialize();

  // Buying a Home Sheet
  modulesServices.buyingAHome();

  // Inv Property ROI Sheet
  modulesServices.invPropertyRoi();

  // Higher Education Payback Sheetgraph
  // modulesServices.higherEducationPaybackInitialize(data);

  // Higher Education Output Backend Sheet
  modulesServices.higherEducationOutputInitialize();

  // Career What If Output Backend Sheet
  // modulesServices.careerWhatIfOutputInitialize();

  // Career What If Output Backend Sheet
  modulesServices.careerOutputBackendInitialize();

  // Career Advancement Output Backend Sheet
  modulesServices.careerAdvOutputBackendInitialize();

  // More Higher Student Loan Excel Sheet Formulas Initialize
  modulesServices.moreHigherStudentLoanInitialize();

  // More Higher Education Payback Sheetgraph
  // modulesServices.moreHigherEducationPaybackInitialize($rootScope.graph);

  // More Higher Education Output Backend Sheet
  modulesServices.moreHigherEducationOutputInitialize();

  // New Higher Education Output Backend Sheet
  modulesServices.newHigherEducationOutputInitialize();

  // Additional Career Advancement Output Backend Sheet
  modulesServices.addnCareerAdvOutputBackendInitialize();

  // Investments Output Backend Sheet
  modulesServices.investmentsOutputBackendInitialize();

  // Family Output Backend Sheet
  modulesServices.familyBackendInitialize();

  // Buying A Home Output Backend Sheet
  modulesServices.buyingaHomeOutputBackendInitialize();

  // REI Output Backend Sheet
  modulesServices.reiOutputBackendInitialize();

  // Buying A Car backend Data
  modulesServices.buyingaCarBackendInitialize();

  // New Higher Education Payback Sheetgraph
  // modulesServices.newHigherEducationPaybackInitialize($rootScope.graph);

  // Further Career Advancement Output Backend Sheet
  modulesServices.furtherCareerAdvOutputBackendInitialize();

  // Simulator Engine Data
  // modulesServices.simulatorEngineInitialize();

  // Investments Row Table
  // modulesServices.investmentsShowTableInitialize($rootScope.graph);

  // Existing Student Loan backend sheet
  modulesServices.existingStudentLoanBackendOutputInitialize();

  // Existing Student Loan WhatIf Output Sheet
  // modulesServices.existingStudentLoanOutputInitialize($rootScope.graph18);

  // Refinanced Student Loans
  // modulesServices.refinedStudentLoansInitialize($rootScope.graph18);

  // Existing Credit Card Debt
  modulesServices.existingCreditCardDebtInitialize();

  // Simulator calculations data
  modulesServices.simPanelCalcsInitialize();

  // Dashboard calculations data
  modulesServices.dashBoardCalcsInitialize();

  // Tax Statement Excel Sheet Formulas Initialize
  modulesServices.taxStatementInitialize();

  // Cars existing vehicle Sheet Formulas Initialize
  modulesServices.carsExistingVehicleDepreciationInitialize();
  modulesServices.carsExistingVehicleDepreciation();

  /// Spouse Student Lons Data
  modulesServices.spouseStudentLoanDataInitialize();

  /// Short term Table data
  modulesServices.shortTermTableInitialize();

  /// Long term Table data
  modulesServices.longTermTableInitialize();

  // modulesServices.cashFlowCheck();
  // modulesServices.startYearGraphInitialize();
}

function workSheetCalc() {
  // Higher education Functions

  modulesServices.fetchRoomBoard();

  modulesServices.onCampusMonthlyExpensesYears();

  modulesServices.offCampusYearDiff();

  modulesServices.higherEduMarriageYear();

  modulesServices.onCampusTotalExpenses();

  modulesServices.higherEducationOffCampusRentMultiplier();

  modulesServices.higherEducationOffCampusRentExpenses();

  modulesServices.higherEducationOffCampusUtilitiesMultiplier();

  modulesServices.higherEducationOffCampusUtilitiesExpenses();

  modulesServices.higherEducationOffCampusFoodMultiplier();

  modulesServices.higherEducationOffCampusFoodExpenses();

  modulesServices.higherEducationOffCampusClothingMultiplier();

  modulesServices.higherEducationOffCampusClothingExpenses();

  modulesServices.higherEducationOffCampusEntertainmentMultiplier();

  modulesServices.higherEducationOffCampusEntertainmentExpenses();

  modulesServices.higherEducationOffCampusTechnologyMultiplier();

  modulesServices.higherEducationOffCampusTechnologyExpenses();

  modulesServices.higherEducationOffCampusTransportationMultiplier();

  modulesServices.higherEducationOffCampusTransportationExpenses();

  modulesServices.higherEducationOffCampusMiscellaneousMultiplier();

  modulesServices.higherEducationOffCampusMiscellaneousExpenses();

  modulesServices.higherEducationOffCampusHealthInsuranceMultiplier();

  modulesServices.higherEducationOffCampusOutsidePremiumExpenses();

  modulesServices.higherEducationOffCampusHealthInsurancePremiumMultiplier();

  modulesServices.higherEducationOffCampusPremiumExpenses();

  modulesServices.higherEducationOffCampusTotalExpenses();

  modulesServices.offCampusTotalExpenses();

  modulesServices.monthlyPriceOffCampus();

  modulesServices.monthlyPercentageOffCampus();

  modulesServices.existingSubLoanPayments();

  modulesServices.collegeStudentLoanPayments();

  modulesServices.totalStudentsLoanPayments();

  modulesServices.totalTuitionAndFees();

  modulesServices.totalRoomAndBoard();

  modulesServices.higherOnCampusLivingExpenses();

  modulesServices.higherOffCampusLivingExpenses();

  modulesServices.totalStudentsLoanPayments();

  modulesServices.totalCostOfAttendance();

  modulesServices.otherLoanPaymentsonOtherDebt();

  modulesServices.higherCarExpense();

  modulesServices.higherRealEstateExpenses();

  modulesServices.higherChildrenAndPetExpenses();

  modulesServices.higherTotalExpenses();

  modulesServices.calEstimateTaxRate();

  modulesServices.calEstimateTaxPaid();

  modulesServices.calEstimateIncomeAfterTax();

  modulesServices.fedSubBeginningBalance();

  modulesServices.fedSubMonthlyPayment();

  modulesServices.fedSubYearlyPayment();

  modulesServices.fedSubTotalInterestPaid();

  modulesServices.fedSubTotalPrincipalInterest();

  modulesServices.fedUnSubPrincipal();

  modulesServices.fedUnSubAccruedInterest();

  modulesServices.fedUnSubBeginningBalance();

  modulesServices.fedUnSubMonthlyPayment();

  modulesServices.fedUnSubYearlyPayment();

  modulesServices.fedUnSubTotalInterestPaid();

  modulesServices.fedUnSubTotalPrincipalInterest();

  modulesServices.fedSubOrganizationValue();

  modulesServices.fedUnSubOrganizationValue();

  modulesServices.fedSubPaymentYearBegins();

  modulesServices.existingStudentLoansBalance();

  modulesServices.totalStudentLoanBalance();

  modulesServices.totalMonthlyPayment();

  modulesServices.totalAnnualPaymentPaid();

  modulesServices.incomeNeedToSupportLoans();

  modulesServices.totalTuitionAndFees();

  modulesServices.incomeEarnedAfterTaxesVal();

  modulesServices.summaryLoanPoints();

  modulesServices.investmentAnalysisTotalCashAvailable();

  modulesServices.higherTotalExpensesFinal();

  modulesServices.investmentAnalysisTotalLiquidAssetsAvailable();

  modulesServices.privateAccruedInterest();

  modulesServices.privateBeginningBalance();

  modulesServices.privateMonthlyPayment();

  modulesServices.privateYearlyPayment();

  modulesServices.privatePrincipal();

  modulesServices.privateTotalInterestPaid();

  modulesServices.privateTotalPrincipalInterest();

  modulesServices.fedPrivateOrganizationValue();

  modulesServices.higherHighertTotalCostOfAttendance();

  modulesServices.totalBeforeTaxIncome();
  modulesServices.estimatedTaxRateConversation();


  // More Higher education Functions
  modulesServices.moreOnCampusMonthlyExpensesYears();
  modulesServices.moreFetchRoomBoard();
  modulesServices.livingExpensestotal();
  modulesServices.moreOffCampusMonthlyExpensesYears();
  modulesServices.moreHigherMarriageYear();
  modulesServices.moreHigherOffCampusRentMultiplier();
  modulesServices.moreHigherOffCampusRentExpenses();
  modulesServices.moreHigherOffCampusUtilitiesMultiplier();
  modulesServices.moreHigherOffCampusUtilitiesExpenses();
  modulesServices.moreHigherOffCampusFoodMultiplier();
  modulesServices.moreHigherOffCampusFoodExpenses();
  modulesServices.moreHigherOffCampusClothingMultiplier();
  modulesServices.moreHigherOffCampusClothingExpenses();
  modulesServices.moreHigherOffCampusEntertainmentMultiplier();
  modulesServices.moreHigherOffCampusEntertainmentExpenses();
  modulesServices.moreHigherOffCampusTechnologyMultiplier();
  modulesServices.moreHigherOffCampusTechnologyExpenses();
  modulesServices.moreHigherOffCampusTransportationMultiplier();
  modulesServices.moreHigherOffCampusTransportationExpenses();
  modulesServices.moreHigherOffCampusMiscellaneousMultiplier();
  modulesServices.moreHigherOffCampusMiscellaneousExpenses();
  modulesServices.moreHigherOffCampusHealthInsuranceMultiplier();
  modulesServices.moreHigherOffCampusOutsidePremiumExpenses();
  modulesServices.moreHigherOffCampusHealthInsurancePremiumMultiplier();
  modulesServices.moreHigherOffCampusPremiumExpenses();
  modulesServices.morelivingExpensestotal();
  modulesServices.moreEducationOffCampusTotalExpenses();
  modulesServices.moremonthlyPriceOffCampus();
  modulesServices.moremonthlyPercentageOffCampus();
  modulesServices.moreTotalTuitionAndFees();
  modulesServices.moreTotalRoomAndBoard();
  modulesServices.moreHigherOnCampusLivingExpenses();
  modulesServices.moreHigherOffCampusLivingExpenses();
  modulesServices.moreHigherTotalStudentsLoanPayments();
  modulesServices.moreHighertTotalCostOfAttendance();
  modulesServices.moreHigherTotalStudentsLoanPayments();
  modulesServices.moreHigherOtherLoanPaymentsonOtherDebt();
  modulesServices.moreHigherCarExpense();
  modulesServices.moreHigherRealEstateExpenses();
  modulesServices.moreHigherChildrenAndPetExpenses();
  modulesServices.moreHigherTotalExpenses();
  modulesServices.earnedYearsDiff1();
  modulesServices.moreHigherEarnedEstimatedTaxRate();
  modulesServices.moreHigherestimatedTaxesPaid();
  modulesServices.moreHigherEstimatedIncomeafterTaxes();
  modulesServices.moreFedSubOrganizationValue();
  modulesServices.moreFedSubPaymentYearBegins();
  modulesServices.moreFedSubBeginningBalance();
  modulesServices.moreFedSubMonthlyPayment();
  modulesServices.moreFedSubYearlyPayment();
  modulesServices.moreFedSubTotalInterestPaid();
  modulesServices.moreFedSubTotalPrincipalInterest();
  modulesServices.moreFedUnSubOrganizationValue();
  modulesServices.moreFedSubPaymentYearBegins();
  modulesServices.moreFedUnSubPrincipal();
  modulesServices.moreFedUnSubAccruedInterest();
  modulesServices.moreFedUnSubBeginningBalance();
  modulesServices.moreFedUnSubMonthlyPayment();
  modulesServices.moreFedUnSubYearlyPayment();
  modulesServices.moreFedUnSubTotalInterestPaid();
  modulesServices.moreFedUnSubTotalPrincipalInterest();
  modulesServices.morePrivateOrganizationValue();
  modulesServices.moreFedSubPaymentYearBegins();
  modulesServices.morePrivatePrincipal();
  modulesServices.morePrivateAccruedInterest();
  modulesServices.morePrivateBeginningBalance();
  modulesServices.morePrivateMonthlyPayment();
  modulesServices.morePrivateYearlyPayment();
  modulesServices.morePrivateTotalInterestPaid();
  modulesServices.morePrivateTotalPrincipalInterest();
  modulesServices.moreTotalBeforeTaxIncome();
  modulesServices.moreEstimatedTaxRateConversation();
  modulesServices.moreHigherTotalStudentLoanBalance();
  modulesServices.moreHigherTotalMonthlyPayment();
  modulesServices.moreHigherTotalAnnualPaymentPaid();
  modulesServices.moreHigherIncomeNeedToSupportLoans();
  modulesServices.moreHigherinvestmentAnalysisScholships();
  modulesServices.moreHigherinvestmentAnalysisGrants();
  modulesServices.moreHigherinvestmentAnalysisFinancialAid();
  modulesServices.moreHigherinvestmentAnalysisIncomeEarned();
  modulesServices.moreHigherinvestmentAnalysisFamilyContribution();
  modulesServices.moreSummaryLoanPoints();
  modulesServices.moreHigherinvestmentAnalysisTotalCashAvailable();
  modulesServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
  modulesServices.morehigherHighertTotalCostOfAttendance();
  // Further Higher education calculations

  modulesServices.newOnCampusMonthlyExpensesYears();

  modulesServices.newFetchRoomBoard();

  modulesServices.newlivingExpensestotal();

  modulesServices.newOffCampusMonthlyExpensesYears();

  modulesServices.furtherHigherMarriageYear();

  modulesServices.newTotalBeforeTaxIncome();

  modulesServices.newEstimatedTaxRateConversation();

  modulesServices.furtherHigherOffCampusRentMultiplier();

  modulesServices.furtherHigherOffCampusRentExpenses();

  modulesServices.furtherHigherOffCampusUtilitiesMultiplier();

  modulesServices.furtherHigherOffCampusUtilitiesExpenses();

  modulesServices.furtherHigherOffCampusFoodMultiplier();

  modulesServices.furtherHigherOffCampusFoodExpenses();

  modulesServices.furtherHigherOffCampusClothingMultiplier();

  modulesServices.furtherHigherOffCampusClothingExpenses();

  modulesServices.furtherHigherOffCampusEntertainmentMultiplier();

  modulesServices.furtherHigherOffCampusEntertainmentExpenses();

  modulesServices.furtherHigherOffCampusTechnologyMultiplier();

  modulesServices.furtherHigherOffCampusTechnologyExpenses();

  modulesServices.furtherHigherOffCampusTransportationMultiplier();

  modulesServices.furtherHigherOffCampusTransportationExpenses();

  modulesServices.furtherHigherOffCampusMiscellaneousMultiplier();

  modulesServices.furtherHigherOffCampusMiscellaneousExpenses();

  modulesServices.furtherHigherOffCampusHealthInsuranceMultiplier();

  modulesServices.furtherHigherOffCampusOutsidePremiumExpenses();

  modulesServices.furtherHigherOffCampusHealthInsurancePremiumMultiplier();

  modulesServices.furtherHigherOffCampusPremiumExpenses();

  modulesServices.newmorelivingExpensestotal();

  modulesServices.furtherEducationOffCampusTotalExpenses();

  modulesServices.newmonthlyPriceOffCampus();

  modulesServices.newmonthlyPercentageOffCampus();

  modulesServices.furtherTotalBeforeTaxIncome();

  modulesServices.newlivingExpensesCashExcess();

  modulesServices.newHigherlivingExpensesTotal();

  modulesServices.newHigherCashExcess();

  modulesServices.newTotalTuitionAndFees();

  modulesServices.newTotalRoomAndBoard();

  modulesServices.newOnCampusLivingExpenses();

  modulesServices.newOffCampusLivingExpenses();

  modulesServices.newHigherTotalStudentsLoanPayments();

  modulesServices.newTotalCostOfAttendance();

  modulesServices.newHigherOtherLoanPaymentsonOtherDebt();

  modulesServices.newHigherCarExpense();

  modulesServices.newHigherRealEstateExpenses();

  modulesServices.newHigherChildrenAndPetExpenses();

  modulesServices.newHigherTotalExpenses();

  modulesServices.newHigherEarnedEstimatedTaxRate();

  modulesServices.newHigherestimatedTaxesPaid();

  modulesServices.newHigherEstimatedIncomeafterTaxes();

  modulesServices.furFedSubOrganizationValue();

  modulesServices.newFedSubPaymentYearBegins();

  modulesServices.newFedSubBeginningBalance();

  modulesServices.newFedSubMonthlyPayment();

  modulesServices.newFedSubYearlyPayment();

  modulesServices.newFedSubTotalInterestPaid();

  modulesServices.newFedSubTotalPrincipalInterest();

  modulesServices.furFedUnSubOrganizationValue();

  modulesServices.newFedUnSubPrincipal();

  modulesServices.newFedUnSubAccruedInterest();

  modulesServices.newFedUnSubBeginningBalance();

  modulesServices.newFedUnSubMonthlyPayment();

  modulesServices.newFedUnSubYearlyPayment();

  modulesServices.newFedUnSubTotalInterestPaid();

  modulesServices.newFedUnSubTotalPrincipalInterest();

  modulesServices.furPrivateOrganizationValue();

  modulesServices.newPrivatePrincipal();

  modulesServices.newPrivateAccruedInterest();

  modulesServices.newPrivateBeginningBalance();

  modulesServices.newPrivateMonthlyPayment();

  modulesServices.newPrivateYearlyPayment();

  modulesServices.newPrivateTotalInterestPaid();

  modulesServices.newPrivateTotalPrincipalInterest();

  modulesServices.newHigherTotalStudentLoanBalance();

  modulesServices.newHigherTotalMonthlyPayment();

  modulesServices.newHigherTotalAnnualPaymentPaid();

  modulesServices.newHigherIncomeNeedToSupportLoans();

  modulesServices.newHigherinvestmentAnalysisScholships();

  modulesServices.newHigherinvestmentAnalysisGrants();

  modulesServices.newHigherinvestmentAnalysisFinancialAid();

  modulesServices.newHigherinvestmentAnalysisIncomeEarned();

  modulesServices.newHigherinvestmentAnalysisFamilyContribution();

  modulesServices.newSummaryLoanPoints();

  modulesServices.newHigherinvestmentAnalysisTotalCashAvailable();

  modulesServices.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable();

  modulesServices.furtherHigherHighertTotalCostOfAttendance();

  // Vacation calculations
  modulesServices.vacationTotalAmountSpent();

  modulesServices.cashFlowCheck();

  // REI module working by Sateesh
  modulesServices.realEstatedownPaymentCalcs();
  modulesServices.realEstateFinancedAmount();
  modulesServices.realEstateTransferTaxesCalcs();
  modulesServices.reiTitleInsuranceCalcs();
  modulesServices.realEstateFitOutCostsCalcs();
  modulesServices.realEstatePropertyTaxesCalcs();
  modulesServices.realEstateMortage();
  modulesServices.loanPointsCalculation();
  modulesServices.originationFeesCalculation();
  modulesServices.realEstateMonthlyPayment();
  modulesServices.realEstateYearlyPayment();
  modulesServices.totalInterest();
  modulesServices.totalPrincipalInterest();
  modulesServices.annualRentalIncomeCalcs();
  modulesServices.vacancyRateCalcs();
  modulesServices.realEstateAdjustedRentalIncome();
  modulesServices.annualRentalIncomeGrowthRateCalcs();
  modulesServices.realEstateYearlyMaintenanceCalcs();
  modulesServices.managementFeeCalcs();
  modulesServices.monthlyUtilitiesCalcs();
  modulesServices.realEstatehomeownerInsuranceCalcs();
  modulesServices.realEstatepropertyTaxesRentingVal();
  modulesServices.realEstatePropertyTaxesExpensesCalcs();
  modulesServices.realEstateClosingCosts();
  modulesServices.reiClosingCosts();
  modulesServices.reiPurchasePrice();
  modulesServices.reiDownPayment();
  modulesServices.reiClosingCosts();
  modulesServices.reiFitOutCosts();
  modulesServices.realEstatecashNeededAtPurchase();
  modulesServices.realEstatetotalCashAvailable();
  modulesServices.realEstatetotalLiquid();
  modulesServices.reiDebt();
  modulesServices.appreciationCalcs();
  modulesServices.salesPrice();
  modulesServices.sellingCostCalcs();
  modulesServices.mortgageBalanceRemaining();
  modulesServices.netCashFlowFromSale();
  modulesServices.investmentSummaryTotalRental();
  modulesServices.investmentSummaryTotalProperty();
  modulesServices.investmentSummaryTotalFinancingExpenses();
  modulesServices.investmentSummaryNetRental();
  modulesServices.totalCashInvested();
  modulesServices.totalCashReturned();
  modulesServices.totalyearlyExpenses();
  modulesServices.ROICal();
  modulesServices.MoMCal();
  modulesServices.cashOnCashReturn();
  modulesServices.IRRCal();
  modulesServices.adjustedRentalIncome();
  modulesServices.mortgagepaymentInterest();
  modulesServices.propertytaxesValue();
  modulesServices.homeOwnersInsuranceValue();
  modulesServices.reiPitiValue();
  modulesServices.managementValue();
  modulesServices.maintenanceValue();
  modulesServices.realEstateYearlyAssociationFeesCalcs();
  modulesServices.associationFeesValue();
  modulesServices.utilitiesExpenseValue();
  modulesServices.reiTotalMonthlyExpenses();
  modulesServices.monthlyNetIncomeExpenses();
  modulesServices.moreREISellingPrice();
  modulesServices.moreREISellingCost();
  modulesServices.moreREINetSellingPrice();
  modulesServices.moreREIPurchasePrice();
  modulesServices.moreREIClosingCost();
  modulesServices.moreREIGain();
  modulesServices.moreREITaxCapital();
  modulesServices.moreREITaxAccumulated();




  // Buying a Home module by Sateesh
  modulesServices.downPaymentCalcs();
  modulesServices.transferTaxesCalcs();
  modulesServices.titleInsuranceCalcs();
  modulesServices.buyingHomeValue();
  modulesServices.propertyTaxesCalcs();
  modulesServices.homeownerInsuranceCalcs();
  modulesServices.yearlyMaintenanceCalcs();
  modulesServices.yearlyAssociationCalcs();
  modulesServices.homeMonthlyUtilitiesCalcs();
  modulesServices.Mortage();
  modulesServices.privateMortgageInsuranceCalcs();
  modulesServices.buyingAhomeLoanPointsCalcs();
  modulesServices.originationFeesCalcs();
  modulesServices.moreHighermonthlyPayment();
  modulesServices.yearlyPayment();
  modulesServices.biyingaHomeTotalInterest();
  modulesServices.biyingaHomeTotalPrncipalInterest();
  modulesServices.closingCosts();
  modulesServices.mortgagePaymentExpenses();
  modulesServices.propertyTaxesExpenses();
  modulesServices.privateMortgageInsExpenses();
  modulesServices.homeOwnersInsExpenses();
  modulesServices.pitiExpenses();
  modulesServices.maintenanceExpenses();
  modulesServices.assiciationFeesExpenses();
  modulesServices.utilitiesExpenses();
  modulesServices.totalMonthlyExpenses();
  modulesServices.totalIncomeBeforweTaxesBuyingHomePercentage();
  modulesServices.totalIncomeBeforweTaxesBuyingHomeValue();
  modulesServices.homePurchasePrice();
  modulesServices.homeDownPayment();
  modulesServices.homeClosingCosts();
  modulesServices.homeFitOutCosts();
  modulesServices.cashNeedsPurchase();
  modulesServices.moreHigherTotalCashAvailable();
  modulesServices.moreHighermoreTotalLiquid();
  modulesServices.debtToIncomeRatio();
  modulesServices.homeTotalIncomeinYear();


  // career path Functions

  modulesServices.careerPathMainTitle();
  modulesServices.totalPreTaxExpected();
  modulesServices.careerPathsuppYearsDiff();
  modulesServices.supplementarytotalPreTaxExpected();
  modulesServices.careerPathExpensesStartYear();
  modulesServices.careerPathCarPaymentExpenses();
  modulesServices.careerPathCarMaintenanceExpenses();
  modulesServices.careerPathCarInsuranceExpenses();
  modulesServices.careerPathGasExpenses();
  modulesServices.totalMonthlyLivingExpenses();
  modulesServices.existingCreditCardInterestInterest();
  modulesServices.carLoanInterest();
  modulesServices.mortgageInterest();
  modulesServices.totalFinancingExpenses();
  modulesServices.incomeEarned();
  modulesServices.livingExpensesPaid();
  modulesServices.higherEducationExpensesPaid();
  modulesServices.childrenExpensesPaid();
  modulesServices.petExpensesPaid();
  modulesServices.carExpensesPaid();
  modulesServices.realEstateExpensesPaid();
  modulesServices.financingExpensesPaid();
  modulesServices.estimatedTaxesPaid();
  modulesServices.netIncomePaid();
  modulesServices.finalRetirementContributionsCareerPath();
  modulesServices.finalNetCashFlowCareerPath();
  modulesServices.singleEstimatedTaxRate();
  modulesServices.singleEstimatedTaxesPaid();
  modulesServices.singleIncomeAfterLivingExpenses();
  modulesServices.marriedEstimatedTaxRate();
  modulesServices.marriedEstimatedTaxesPaid();
  modulesServices.marriedIncomeAfterLivingExpenses();
  modulesServices.total401kContributions();
  modulesServices.personalContributionBaseIncomeCalcs();
  modulesServices.personalContributionBaseIncomeRothCalcs();
  modulesServices.employerMatchingrateCalcs();
  modulesServices.employerMatchingLimitCalcs();
  modulesServices.careerPathPersonalContribution401k();
  modulesServices.careerPathPersonalContribution();
  modulesServices.employerContribution();
  modulesServices.totalAnnualContribution();
  modulesServices.beginningBalanceInYear();
  // New modules 
  modulesServices.BeginningBalanceOf401k();
  modulesServices.BeginningRothBalanceOf401k();
  modulesServices.careerPathRothIRAPersonalContributionCalcsRoth();
  modulesServices.careerPathTotalIraContributions();
  modulesServices.careerPathiraBeginningBalance();
  modulesServices.careerPathPensionBalance();
  modulesServices.rothBeginningBalanceIra();
  modulesServices.rothBeginningBalanceCal();
  modulesServices.rothBeginningBalance();
  modulesServices.pathCareerHsaBeginningBalance();
  modulesServices.careerPathHsaPersonalContributionCalcs();
  modulesServices.careerPathHsaTotalEndingBalanceSummary();
  modulesServices.maxHsaContributionByLawCareerPath();
  modulesServices.careerPathHsaBeginningBalanceSummary();
  modulesServices.careerPathHsaTotalContributionSummary();
  modulesServices.careerPathHsaTotalEarningsSummary();
  modulesServices.beginningBalanceInYearRoth();
  modulesServices.personalContribuitionafterYearsRoth();
  modulesServices.earningOnPersonalContributionRoth();
  modulesServices.totalPersonalContributionEarningsRoth();
  modulesServices.employerContributionAfterYearsRoth();
  modulesServices.earningsOnEmployerContributionAfterYearsRoth();
  modulesServices.totalEmployersContributionsEarningsRoth();
  modulesServices.balanceBeforeVestingReductionsCareerPathRoth();
  modulesServices.employerContributionsEarningsNotVestedRoth();
  modulesServices.ending401BalanceRoth();
  modulesServices.careerPathRothIRATotalContributionRoth();
  modulesServices.careerPathRothIRATotalEarningsRoth();
  modulesServices.careerPathRothIRAEndingBalanceRoth()
  //New modules add by phrudhvi
  modulesServices.personalContribuitionafterYears();
  modulesServices.earningOnPersonalContribution();
  modulesServices.totalPersonalContributionEarnings();
  modulesServices.employerContributionAfterYears();
  modulesServices.earningsOnEmployerContributionAfterYears();
  modulesServices.totalEmployersContributionsEarnings();
  modulesServices.balanceBeforeVestingReductionsCareerPath();
  modulesServices.vestedAfterFewyears();
  modulesServices.employerContributionsEarningsNotVested();
  modulesServices.ending401Balance();
  modulesServices.careerPathRothIRAPersonalContributionCalcs();
  modulesServices.careerPathRothIRAPersonalContributionrothIra();
  modulesServices.careerPathRothIRAPersonalContribution();
  modulesServices.careerPathRothIRATotalContribution();
  modulesServices.careerPathRothIRATotalEarnings();
  modulesServices.careerPathRothIRAEndingBalance();
  modulesServices.careerPathSEPIRAPersonalContributionCalcs();
  modulesServices.careerPathSEPIRAPersonalContributionsepIra();
  modulesServices.careerPathSEPIRAPersonalContribution();
  modulesServices.careerPathSEPIRABeginningBalance();
  modulesServices.careerPathSEPIRATotalContribution();
  modulesServices.careerPathSEPIRATotalEarnings();
  modulesServices.careerPathEndingSEPIRABalance();
  modulesServices.careerPathPensionEmployerContributionCalc();
  modulesServices.careerPathPensionPersonalContributionCalc();
  modulesServices.careerPathPensionPersonalContribution();
  modulesServices.careerPathPensionTotalContributionpension();
  modulesServices.careerPathPensionTotalContribution();
  modulesServices.careerPathPensionBeginningBalance();
  modulesServices.careerPathPensionTotalEmployerContributions();
  modulesServices.careerPathPensionTotalPersonalContributions();
  modulesServices.careerPathPensionTotalEarnings();
  modulesServices.careerPathEndingPensionBalance();
  modulesServices.careerPathExpensesRentMultiplier();
  modulesServices.careerPathRentExpenses();
  modulesServices.careerPathExpensesUtilitiesMultiplier();
  modulesServices.careerPathUtilitiesExpenses();
  modulesServices.careerPathExpensesFoodMultiplier();
  modulesServices.careerPathFoodExpenses();
  modulesServices.careerPathExpensesClothingMultiplier();
  modulesServices.careerPathClothingExpenses();
  modulesServices.careerPathExpensesEntertainmentMultiplier();
  modulesServices.careerPathEntertainmentExpenses();
  modulesServices.careerPathExpensesTechnologyMultiplier();
  modulesServices.careerPathTechnologyExpenses();
  modulesServices.careerPathExpensesTransportationMultiplier();
  modulesServices.careerPathTransportationExpenses();
  modulesServices.careerPathExpensesMiscellaneousMultiplier();
  modulesServices.careerPathMiscellaneousExpenses();
  modulesServices.careerPathExpensesMedicalMultiplier();
  modulesServices.careerPathOutsidePremiumExpenses();
  modulesServices.careerPathExpensesHealthMultiplier();
  modulesServices.careerPathPremiumExpenses();
  modulesServices.careerPathLivingExpenses();
  modulesServices.careerPathHigherEducationExpenses();
  modulesServices.careerPathCarExpenses();
  modulesServices.careerPathChildrenExpenses();
  modulesServices.careerPathPetExpenses();
  modulesServices.careerPathHomeExpenses();
  modulesServices.careerPathFinancingExpenses();
  modulesServices.careerPathTotalAnnualExpenses();
  modulesServices.monthlyIncomeFromCareerPath();
  modulesServices.totalMonthlyIncomeCareerPath();
  modulesServices.otherMonthlyIncomeCareerPath();
  modulesServices.totalTaxesPaidCareerPath();
  modulesServices.livingExpensesCareerPath();
  modulesServices.higherEducationExpensesCareerPath();
  modulesServices.childrenExpensesCareerPath();
  modulesServices.petExpensesCareerPath();
  modulesServices.carExpensesCareerPath();
  modulesServices.realEstateExpensesCareerPath();
  modulesServices.internetExpensesCareerPath();
  modulesServices.totalMonthlyExpensesCareerPath();
  modulesServices.monthlyNetIncomeCareerPath();
  modulesServices.retirementContributionsCareerPath();
  modulesServices.monthlyNetCashFlowCareerPath();

  //New methods by Sateesh
  // modulesServices.BeginningBalanceOf401k();
  //New methods done 
  modulesServices.totalIncomeCareerPath();
  modulesServices.totalIncomeCareerPathCalcs();
  modulesServices.annualTotalTaxesPaidCareerPath();
  modulesServices.annualTotalExpensesCareerPathCalcs();
  modulesServices.careerPathIconCalcs();
  modulesServices.careerPathLivingExpensesCalcs();
  modulesServices.careerPathHigherEducationExpensesCalcs();
  modulesServices.careerPathCarExpensesCalcs();
  modulesServices.careerPathChildrenExpensesCalcs();
  modulesServices.careerPathPetExpensesCalcs();
  modulesServices.careerPathHomeExpensesCalcs();
  modulesServices.careerPathFinancingExpensesCalcs();
  modulesServices.careerPathTotalAnnualExpensesCalcs();
  modulesServices.careerPathAnnualNetIncome();
  modulesServices.careerPathAnnualNetIncomeCalcs();
  modulesServices.annualRetirementContributionsCareerPath();
  modulesServices.annualHsaContributionsCareerPath();
  modulesServices.finalHsaContributionsCareerPath();
  modulesServices.annualNetCashFlowCareerPath();
  modulesServices.careerPathTotalMonthlyLivingExpenses();
  modulesServices.careerPathMarriageYear();
  modulesServices.careerOtherLivingExpensesCareerPath();
  modulesServices.careerOtherAnnaulLivingExpensesCareerPath();
  modulesServices.careerOtherEducationLivingExpensesPaid();

  // career advancement methods

  modulesServices.careerAdvsuppYearsDiff();
  modulesServices.advanceTotalPreTaxExpected();
  modulesServices.advanceSupplementaryTotalPreTaxExpected();
  modulesServices.advanceTotalMonthlyLivingExpenses();
  modulesServices.advanceExistingCreditCardInterestInterest();
  modulesServices.advanceStudentLoanPrincipalInterest();
  modulesServices.advanceTotalFinancingExpenses();
  modulesServices.advanceIncomeEarned();
  modulesServices.advanceLivingExpensesPaid();
  modulesServices.advanceHigherEducationExpensesPaid();
  modulesServices.advanceChildrenExpensesPaid();
  modulesServices.advancePetExpensesPaid();
  modulesServices.advanceCarExpensesPaid();
  modulesServices.advanceRealEstateExpensesPaid();
  modulesServices.advanceFinancingExpensesPaid();
  modulesServices.advanceEstimatedTaxesPaid();
  modulesServices.totalExpensesPaid();
  modulesServices.advanceNetIncome();
  modulesServices.finalRetirementContributionsCareerAdv();
  modulesServices.finalHsaContributionsCareerAdv();
  modulesServices.finalNetCashFlowCareerAdv();
  modulesServices.advanceSingleEstimatedTaxRate();
  modulesServices.advanceSingleEstimatedTaxesPaid();
  modulesServices.advanceSingleIncomeAfterLivingExpenses();
  modulesServices.advanceMarriedEstimatedTaxRate();
  modulesServices.advanceMarriedEstimatedTaxesPaid();
  modulesServices.advanceMarriedIncomeAfterLivingExpenses();
  modulesServices.careerAdvpersonal401ContributionBaseIncomeCalcs();
  modulesServices.careerAdv401EmployerMatchingrateCalcs();
  modulesServices.careerAdv401EmployerMatchingLimitCalcs();
  modulesServices.careerAdv401PersonalContribution401k();
  modulesServices.careerAdv401PersonalContribution();
  modulesServices.careerAdv401EmployerContribution();
  modulesServices.careerAdv401TotalAnnualContribution();
  modulesServices.beginningBalanceInYearSecond();
  modulesServices.beginningBalanceInYearNext();
  modulesServices.careerAdv401PersonalContribution_401k();
  //New by SRINU
  modulesServices.advCareerRothIRAPersonalContributionCalcsRoth();
  modulesServices.studentLoanPrincipalInterest();
  modulesServices.furtherCareerSEPIRAPersonalContributionCalcsNew();
  modulesServices.totalVehicleValues();
  modulesServices.totalVehicleLoanValues();
  modulesServices.totalVehicleLoanPayments()
  modulesServices.totalVehicleLeaseValues();
  modulesServices.totalVehicleMaintenanceValues();
  modulesServices.totalVehicleInsuranceValues();
  modulesServices.totalVehicleGasValues();
  modulesServices.totalMonthlyHomeExpenses2();
  modulesServices.currentNetWorthCash();
  modulesServices.BeginningRothBalanceOf401kAdv();
  modulesServices.careerAdvpersonal401ContributionBaseIncomeRothCalcs();
  modulesServices.careerAdv401PersonalContributionTotal();
  modulesServices.advCareerRothIRABeginningBalanceIra();
  modulesServices.advCareerTotalContribution();
  modulesServices.advCareerHsaBeginningBalance();
  modulesServices.advCareerHsaPersonalContributionCalcs();
  modulesServices.maxHsaContributionByLaw();
  modulesServices.advCareerHsaBeginningBalanceSummary();
  modulesServices.advCareerHsaTotalContributionSummary();
  modulesServices.advCareerHsaTotalEarningsSummary();
  modulesServices.advCareerHsaTotalEndingBalanceSummary();
  modulesServices.beginningBalanceInYearNextRoth();
  modulesServices.personalContributionAetrFewYearsRoth();
  modulesServices.earningsOnPersonalContributionsRoth();
  modulesServices.totalPersonalContributionsAndEarningsCareerAdvRoth();
  modulesServices.employerContributionAfterFewYearsRoth();
  modulesServices.earningsOnEmployerContributionsAfterRoth();
  modulesServices.totalEmployerContributionAndEarningsRoth();
  modulesServices.balanceBeforeVestingReductionsCareerAdvRoth();
  modulesServices.employerContributionsAndEarningsNotVestedRoth();
  modulesServices.endingBalanceInyearLastRoth();
  modulesServices.advCareerRothIRATotalContributionRoth();
  modulesServices.advCareerRothIRAEndingBalanceRoth();
  modulesServices.advCareerRothIRATotalEarningsRoth();
  modulesServices.careerAdvHSAContributions();
  modulesServices.finalcareerAdvHSAContributions();
  modulesServices.careerAdditionalHSAContributions();
  modulesServices.finalAdditionalcareerHSAContributions();
  modulesServices.careerPathHSAContributions();
  modulesServices.finalCareerPathHSAContributions();
  modulesServices.careerNewHSAContributions();
  modulesServices.finalcareerNewHSAContributions();
  //New Done

  modulesServices.personalContributionAetrFewYears();
  modulesServices.earningsOnPersonalContributions();
  modulesServices.totalPersonalContributionsAndEarningsCareerAdv();
  modulesServices.employerContributionAfterFewYears();
  modulesServices.earningsOnEmployerContributionsAfter();
  modulesServices.totalEmployerContributionAndEarnings();
  modulesServices.balanceBeforeVestingReductionsCareerAdv();
  modulesServices.vestedAfteryearAtSchedule();
  modulesServices.employerContributionsAndEarningsNotVested();
  modulesServices.endingBalanceInyearLast();
  modulesServices.advCareerRothIRABeginningBalance();
  modulesServices.advCareerRothIRAPersonalContributionCalcs();
  modulesServices.advCareerRothIRAPersonalContributionrothIra();
  modulesServices.advCareerRothIRAPersonalContribution();
  modulesServices.advCareerRothIRATotalContribution();
  modulesServices.advCareerRothIRATotalEarnings();
  modulesServices.advCareerRothIRAEndingBalance();
  modulesServices.advCareerSEPIRABeginningBalance();
  modulesServices.advCareerSEPIRAPersonalContributionCalcs();
  modulesServices.advCareerSEPIRAPersonalContributionsepIra();
  modulesServices.advCareerSEPIRAPersonalContribution();
  modulesServices.advCareerSEPIRATotalContribution();
  modulesServices.advCareerSEPIRATotalEarnings();
  modulesServices.advCareerEndingSEPIRABalance();
  modulesServices.advCareerPensionBeginningBalance();
  modulesServices.advCareerPensionEmployerContributionCalc();
  modulesServices.advCareerPensionPersonalContributionCalc();
  modulesServices.advCareerPensionEmployerContribution();
  modulesServices.advCareerPensionTotalContributionpension();
  modulesServices.advCareerPensionTotalContribution();
  modulesServices.advCareerPensionTotalEmployerContributions();
  modulesServices.advCareerPensionTotalPersonalContributions();
  modulesServices.advCareerPensionTotalEarnings();
  modulesServices.advCareerEndingPensionBalance();
  modulesServices.careerAdvRentMultiplier();
  modulesServices.careerAdvanceRentExpenses();
  modulesServices.careerAdvUtilitiesMultiplier();
  modulesServices.careerAdvanceUtilitiesExpenses();
  modulesServices.careerAdvFoodMultiplier();
  modulesServices.careerAdvanceFoodExpenses();
  modulesServices.careerAdvClothingMultiplier();
  modulesServices.careerAdvanceClothingExpenses();
  modulesServices.careerAdvEntertainmentMultiplier();
  modulesServices.careerAdvanceEntertainmentExpenses();
  modulesServices.careerAdvTechnologyMultiplier();
  modulesServices.careerAdvanceTechnologyExpenses();
  modulesServices.careerAdvTransportationMultiplier();
  modulesServices.careerAdvanceTransportationExpenses();
  modulesServices.careerAdvMiscellaneousMultiplier();
  modulesServices.careerAdvanceMiscellaneousExpenses();
  modulesServices.careerAdvMedicalMultiplier();
  modulesServices.careerAdvanceOutsidePremiumExpenses();
  modulesServices.careerAdvHealthMultiplier();
  modulesServices.careerAdvancePremiumExpenses();
  modulesServices.careerAdvLivingExpenses();
  modulesServices.careerAdvHigherEducationExpenses();
  modulesServices.careerAdvCarExpenses();
  modulesServices.careerAdvChildrenExpenses();
  modulesServices.careerAdvPetExpenses();
  modulesServices.careerAdvHomeExpenses();
  modulesServices.careerAdvFinancingExpenses();
  modulesServices.careerAdvTotalAnnualExpenses();
  modulesServices.careerAdvIconCalcs();
  modulesServices.monthlyIncomeFromCareerAdv();
  modulesServices.totalMonthlyIncomeCareerAdv();
  modulesServices.otherMonthlyIncomeCareerAdv();
  modulesServices.totalTaxesPaidCareerAdv();
  modulesServices.livingExpensesCareerAdv();
  modulesServices.otherAnnaulLivingExpensesCareerPath();
  modulesServices.otherEducationLivingExpensesPaid();
  modulesServices.higherEducationExpensesCareerAdv();
  modulesServices.childrenExpensesCareerAdv();
  modulesServices.petExpensesCareerAdv();
  modulesServices.carExpensesCareerAdv();
  modulesServices.realEstateExpensesCareerAdv();
  modulesServices.interestExpensesCareerAdv();
  modulesServices.careerAdvOtherExpenses();
  modulesServices.totalMonthlyExpensesCareerAdv();
  modulesServices.monthlyNetIncomeCareerAdv();
  modulesServices.monthlyRetirementContributionsCareerAdv();
  modulesServices.monthlyNetCashFlowCareerAdv();
  modulesServices.totalIncomeCareerAdv();
  modulesServices.totalIncomeCareerAdvCalcs();
  modulesServices.annualTotalTaxesPaidCareerAdv();
  modulesServices.annualTotalTaxesPaidCareerAdvCalcs();
  modulesServices.careerAdvLivingExpensesCalcs();
  modulesServices.careerAdvHigherEducationExpensesCalcs();
  modulesServices.careerAdvCarExpensesCalcs();
  modulesServices.careerAdvChildrenExpensesCalcs();
  modulesServices.careerAdvPetExpensesCalcs();
  modulesServices.careerAdvHomeExpensesCalcs();
  modulesServices.careerAdvFinancingExpensesCalcs();
  modulesServices.careerAdvTotalAnnualExpensesCalcs();
  modulesServices.annualNetIncomeCareerAdv();
  modulesServices.annualNetIncomeCareerAdvCalcs();
  modulesServices.annualRetirementContributionsCareerAdv();
  modulesServices.annualHsaContributionsCareerAdv();
  modulesServices.annualNetCashFlowCareerAdv();
  modulesServices.totalExpensesCareerAdv();
  modulesServices.careerAdvanceTotalMonthlyLivingExpenses();
  modulesServices.careerAdvMarriageYear();

  // Module 8 Additional Career Advancement functions
  modulesServices.careerAddAdvsuppYearsDiff();
  modulesServices.jobtotalPreTaxExpected();
  modulesServices.moreSupplementarytotalPreTaxExpected();
  modulesServices.livingTotalMonthlyLivingExpenses();
  modulesServices.moreHigherExistingCreditCardInterestInterest();
  modulesServices.moreHigherstudentLoanPrincipalInterest();
  modulesServices.moreHighercarLoanInterest();
  modulesServices.moreHighermortgageInterest();
  modulesServices.moreHighertotalFinancingExpenses();
  modulesServices.moreHigherincomeEarned();
  modulesServices.moreHigherlivingExpensesPaid();
  modulesServices.moreHigherEducationExpensesPaid();
  modulesServices.moreChildrenExpensesPaid();
  modulesServices.morePetExpensesPaid();
  modulesServices.moreHigherCarExpensesPaid();
  modulesServices.moreHigherRealEstateExpensesPaid();
  modulesServices.moreHigherEstimatedTaxesPaid();
  modulesServices.moreHigherfinancingExpensesPaid();
  modulesServices.moreHigherNetIncome();
  modulesServices.addCareerAdvOtherExpenses();
  modulesServices.addOtherAnnaulLivingExpensesCareerPath();
  modulesServices.addOtherEducationLivingExpensesPaid();
  modulesServices.finalRetirementContributionsAddCareerAdv();
  modulesServices.finalHsaContributionsAddCareerAdv();
  modulesServices.finalNetCashFlowAddCareerAdv();
  modulesServices.moreHighersingleEstimatedTaxRate();
  modulesServices.moreHighersingleEstimatedTaxesPaid();
  modulesServices.moreHighersingleIncomeAfterLivingExpenses();
  modulesServices.moreHighermarriedEstimatedTaxRate();
  modulesServices.moreHighermarriedEstimatedTaxesPaid();
  modulesServices.moreHighermarriedIncomeAfterLivingExpenses();
  modulesServices.addCareerpersonal401ContributionBaseIncomeCalcs();
  modulesServices.AddCareer401EmployerMatchingrateCalcs();
  modulesServices.AddCareer401EmployerMatchingLimitCalcs();
  modulesServices.addCareer401PersonalContribution401k();
  modulesServices.addCareer401PersonalContribution();
  modulesServices.AddCareer401EmployerContribution();
  modulesServices.addCareer401TotalAnnualContribution();
  modulesServices.beginningBalanceInYearThird();
  modulesServices.beginningBalanceYearThird();

    //Methods by Akhil ACA
    modulesServices.beginningBalanceYearThirdRoth();
    modulesServices.personalContributionAddCareerAdvRoth();
    modulesServices.earningsOnPersonalContributionsAfterRoth();
    modulesServices.totalPersonalContributionsAndEarningsRoth();
    modulesServices.employerContributionAddCarrerAdvRoth();
    modulesServices.beginningBalanceInYearThirdRoth();
    modulesServices.maxContributionByLawAddCarrAdv();
    modulesServices.addCareer401PersonalContribution();
    modulesServices.addCareerRothIRABeginningBalance();
    modulesServices.addCareerRothIRABeginningBalanceRoth();
    modulesServices.addCareerRothIRATotalContributionRoth();
    modulesServices.addCareerRothIRATotalEarningsRoth();
    modulesServices.addCareerMaxIraContribution();
    modulesServices.addCareerCareerHsaBeginningBalance();
    modulesServices.addCareer401ContributionBaseIncomeCalcs();
    modulesServices.addCareerRothIRAEndingBalanceRoth();
    modulesServices.addCareerContributionBaseIncomeCalcs();
    modulesServices.totalEmployerContributionsAndEarningsRoth();
    modulesServices.balanceBeforeVestingReductionsAddRoth();
    modulesServices.employerContributionsAndEarningsNotVestedAdd();
	modulesServices.earningsOnEmployerContributionsAddRoth();
    modulesServices.employerContributionsAndEarningsNotVestedAddRoth();
    modulesServices.endingBalanceInYearAddCareerAdv();
    modulesServices.endingBalanceInYearAddCareerAdvRoth();
    modulesServices.addCareerRothIRAPersonalContributionCalcs();
    modulesServices.addCareerRothIRAPersonalContribution();
    modulesServices.addCareerRothIRAPersonalContributionCalcsRoth();
    modulesServices.additionalCareerSEPIRAPersonalContributionCalcs();
    modulesServices.additionalCareerHsaBeginningBalanceSummary();
    modulesServices.additionalCareerHsaTotalContributionSummary();
    modulesServices.additionalCareerHsaTotalEarningsSummary();
    modulesServices.additionalCareerHsaTotalEndingBalanceSummary();

    //Methods by Akhil FCA
    modulesServices.beginningBalanceInYearFourthRoth();
    modulesServices.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth();
    modulesServices.furtherCareer401PersonalContributionRoth();
    modulesServices.further401kSummaryPersonalContributionRoth();
    modulesServices.further401kSummaryEarningsOnPersonalContributionRoth();
    modulesServices.further401kSummaryTotalPersonalContributionsAndEarningsRoth();
    modulesServices.further401kSummaryEmployerContributionRoth();
    modulesServices.furtherCareerRothIRABeginningBalanceRoth();
    modulesServices.furtherCareerRothIRATotalContributionIra();
    modulesServices.furtherCareerHsaBeginningBalance();
    modulesServices.maxHsaContributionByLawFurtherCareer();
    modulesServices.furtherCareerHsaBeginningBalanceSummary();
    modulesServices.furtherCareerHsaTotalContributionSummary();
    modulesServices.furtherCareerHsaTotalEarningsSummary();
    modulesServices.furtherCareerHsaTotalEndingBalanceSummary();
    modulesServices.further401kSummaryTotalEmployerContributionsAndEarningsRoth();
    modulesServices.furtherBalanceBeforeVestingReductionsRoth();
    modulesServices.furtherCareer401kEndingBalanceRoth();
    modulesServices.employerContributionsAndEarningsNotVestedFurRoth();
    modulesServices.furtherCareerRothIRAPersonalContributionCalcsRoth();
    modulesServices.furtherCareerRothIRABeginningBalanceRoth();
    modulesServices.furtherCareerRothIRATotalContributionRoth();
    modulesServices.furtherCareerRothIRATotalEarningsRoth();
    modulesServices.furtherCareerRothIRAEndingBalanceRoth();



  modulesServices.personalContributionAddCareerAdv();
  modulesServices.earningsOnPersonalContributionsAfter();
  modulesServices.totalPersonalContributionsAndEarnings();
  modulesServices.employerContributionAddCarrerAdv();
  modulesServices.earningsOnEmployerContributionsAdd();
  modulesServices.totalEmployerContributionsAndEarnings();
  modulesServices.balanceBeforeVestingReductionsAdd();
  modulesServices.vestedAfterFewYearsScheduleAdd();
  modulesServices.employerContributionsAndEarningsNotVestedAdd();
  modulesServices.endingBalanceInYearAddCareerAdv();
  modulesServices.addCareerRothIRABeginningBalance();
  modulesServices.addCareerRothIRAPersonalContributionCalcs();
  modulesServices.addCareerRothIRAPersonalContributionrothIra();
  modulesServices.addCareerRothIRAPersonalContribution();
  modulesServices.addCareerRothIRATotalContribution();
  modulesServices.addCareerRothIRATotalEarnings();
  modulesServices.addCareerRothIRAEndingBalance();
  modulesServices.addCareerSEPIRABeginningBalance();
  modulesServices.addCareerSEPIRAPersonalContributionCalcs();
  modulesServices.addCareerSEPIRAPersonalContributionsepIra();
  modulesServices.addCareerSEPIRAPersonalContribution();
  modulesServices.addCareerSEPIRATotalContribution();
  modulesServices.addCareerSEPIRATotalEarnings();
  modulesServices.addCareerEndingSEPIRABalance();
  modulesServices.addCareerPensionBeginningBalance();
  modulesServices.addCareerPensionPersonalContributionCalc();
  modulesServices.addCareerPensionEmployerContributionCalc();
  modulesServices.addCareerPensionEmployerContribution();
  modulesServices.addCareerPensionTotalContributionpension();
  modulesServices.addCareerPensionTotalContribution();
  modulesServices.addCareerPensionTotalEmployerContributions();
  modulesServices.addCareerPensionTotalPersonalContributions();
  modulesServices.addCareerPensionTotalEarnings();
  modulesServices.addCareerEndingPensionBalance();
  modulesServices.additionalCareerRentMultiplier();
  modulesServices.additionalCareerRentExpenses();
  modulesServices.additionalCareerUtilitiesMultiplier();
  modulesServices.additionalCareerUtilitiesExpenses();
  modulesServices.additionalCareerFoodMultiplier();
  modulesServices.additionalCareerFoodExpenses();
  modulesServices.additionalCareerClothingMultiplier();
  modulesServices.additionalCareerClothingExpenses();
  modulesServices.additionalCareerEntertainmentMultiplier();
  modulesServices.additionalCareerEntertainmentExpenses();
  modulesServices.additionalCareerTechnologyMultiplier();
  modulesServices.additionalCareerTechnologyExpenses();
  modulesServices.additionalCareerTransportationMultiplier();
  modulesServices.additionalCareerTransportationExpenses();
  modulesServices.additionalCareerMiscellaneousMultiplier();
  modulesServices.additionalCareerMiscellaneousExpenses();
  modulesServices.additionalCareerOutsidePremiumMultiplier();
  modulesServices.additionalCareerOutsidePremiumExpenses();
  modulesServices.additionalCareerPremiumMultiplier();
  modulesServices.additionalCareerPremiumExpenses();
  modulesServices.addCareerAdvLivingExpenses();
  modulesServices.addCareerAdvHigherEducationExpenses();
  modulesServices.addCareerAdvCarExpenses();
  modulesServices.addCareerAdvChildrenExpenses();
  modulesServices.addCareerAdvPetExpenses();
  modulesServices.addCareerAdvHomeExpenses();
  modulesServices.addCareerAdvFinancingExpenses();
  modulesServices.addCareerAdvTotalAnnualExpenses();
  modulesServices.addCareerAdvIconCalcs();
  modulesServices.monthlyIncomeFromAddCareerAdv();
  modulesServices.totalMonthlyIncomeAddCareerAdv();
  modulesServices.otherMonthlyIncomeAddCareerAdv();
  modulesServices.totalTaxesPaidAddCareerAdv();
  modulesServices.livingExpensesAddCareerAdv();
  modulesServices.higherEducationExpensesAddCareerAdv();
  modulesServices.childrenExpensesAddCareerAdv();
  modulesServices.petExpensesAddCareerAdv();
  modulesServices.carExpensesAddCareerAdv();
  modulesServices.realEstateExpensesAddCareerAdv();
  modulesServices.internetExpensesAddCareerPathAdv();
  modulesServices.totalMonthlyExpensesAddCareerAdv();
  modulesServices.monthlyNetIncomeAddCareerAdv();
  modulesServices.retirementContributionsAddCareerAdv();
  modulesServices.monthlyNetCashFlowAddCareerAdv();
  modulesServices.totalIncomeAddCareerAdv();
  modulesServices.totalIncomeAddCareerAdvCalcs();
  modulesServices.annualTotalTaxesPaidAddCareerAdv();
  modulesServices.annualTotalExpensesAddCareerAdvCalcs();
  modulesServices.addCareerAdvAnnualNetIncome();
  modulesServices.AddCareerAdvAnnualNetIncomeCalcs();
  modulesServices.annualRetirementContributionsAddCareerAdv();
  modulesServices.annualHsaContributionsAddCareerAdv();
  modulesServices.annualNetCashFlowAddCareerAdv();
  modulesServices.totalExpensesPaidFromAddCareerAdv();
  modulesServices.addCareerAdvLivingExpensesCalcs();
  modulesServices.addCareerAdvHigherEducationExpensesCalcs();
  modulesServices.addCareerAdvCarExpensesCalcs();
  modulesServices.addCareerAdvChildrenExpensesCalcs();
  modulesServices.addCareerAdvPetExpensesCalcs();
  modulesServices.addCareerAdvHomeExpensesCalcs();
  modulesServices.addCareerAdvFinancingExpensesCalcs();
  modulesServices.addCareerAdvTotalAnnualExpensesCalcs();
  modulesServices.additionalCareerTotalMonthlyLivingExpenses();
  modulesServices.addCareerMarriageYear();

  // Further Career Advancement functions
  modulesServices.careerFurAdvsuppYearsDiff();
  modulesServices.furtherjobtotalPreTaxExpected();
  modulesServices.furthermoreSupplementarytotalPreTaxExpected();
  modulesServices.furtherlivingTotalMonthlyLivingExpenses();
  modulesServices.furthermoreHigherExistingCreditCardInterestInterest();
  modulesServices.furthermoreHigherstudentLoanPrincipalInterest();
  modulesServices.furthermoreHighercarLoanInterest();
  modulesServices.furthermoreHighermortgageInterest();
  modulesServices.furthermoreHighertotalFinancingExpenses();
  modulesServices.furthermoreHigherincomeEarned();
  modulesServices.furthermoreHigherlivingExpensesPaid();
  modulesServices.furthermoreHigherEducationExpensesPaid();
  modulesServices.furthermoreHigherChildrenExpensesPaid();
  modulesServices.furthermoreHigherPetExpensesPaid();
  modulesServices.furthermoreHigherCarExpensesPaid();
  modulesServices.furthermoreHigherRealEstateExpensesPaid();
  modulesServices.furthermoreHigherEstimatedTaxesPaid();
  modulesServices.furthermoreHigherNetIncome();
  modulesServices.finalRetirementContributionsFurCareerAdv();
  modulesServices.finalHsaContributionsFurCareerAdv();
  modulesServices.finalNetCashFlowFurCareerAdv();
  modulesServices.furCareerAdvOtherExpenses();
  modulesServices.furOtherAnnaulLivingExpensesCareerPath();
  modulesServices.furOtherEducationLivingExpensesPaid();
  modulesServices.furthermoreHigherfinancingExpensesPaid();
  modulesServices.furthermoreHighersingleEstimatedTaxRate();
  modulesServices.furthermoreHighersingleEstimatedTaxesPaid();
  modulesServices.furthermoreHighersingleIncomeAfterLivingExpenses();
  modulesServices.furthermoreHighermarriedEstimatedTaxRate();
  modulesServices.furthermoreHighermarriedEstimatedTaxesPaid();
  modulesServices.furthermoreHighermarriedIncomeAfterLivingExpenses();
  modulesServices.beginningBalanceInYearFourth();
  modulesServices.furtherCareerPersonal401ContributionBaseIncomeCalcs();
  modulesServices.furtherCareer401EmployerMatchingrateCalcs();
  modulesServices.furtherCareer401EmployerMatchingLimitCalcs();
  modulesServices.furtherCareer401PersonalContribution401k();
  modulesServices.furtherCareer401PersonalContribution();
  modulesServices.furtherCareer401EmployerContribution();
  modulesServices.furtherCareer401TotalAnnualContribution();
  modulesServices.further401kSummaryPersonalContribution();
  modulesServices.further401kSummaryEarningsOnPersonalContribution();
  modulesServices.further401kSummaryTotalPersonalContributionsAndEarnings();
  modulesServices.further401kSummaryEmployerContribution();
  modulesServices.further401kSummaryEarningsOnEmployerContribution();
  modulesServices.further401kSummaryEarningsOnEmployerContributionRoth();
  modulesServices.further401kSummaryTotalEmployerContributionsAndEarnings();
  modulesServices.furtherBalanceBeforeVestingReductions();
  modulesServices.vestedAfterFewYearsScheduleFurther();
  modulesServices.employerContributionsAndEarningsNotVestedFur();
  modulesServices.furtherCareer401kEndingBalance();
  modulesServices.furtherCareerRothIRABeginningBalance();
  modulesServices.furtherCareerRothIRAPersonalContributionCalcs();
  modulesServices.furtherCareerRothIRAPersonalContributionrothIra();
  modulesServices.furtherCareerRothIRAPersonalContribution();
  modulesServices.furtherCareerRothIRATotalContribution();
  modulesServices.furtherCareerRothIRATotalEarnings();
  modulesServices.furtherCareerRothIRAEndingBalance();
  modulesServices.furtherCareerSEPIRABeginningBalance();
  modulesServices.furtherCareerSEPIRAPersonalContributionCalcs();
  modulesServices.furtherCareerSEPIRAPersonalContributionsepIra();
  modulesServices.furtherCareerSEPIRAPersonalContribution();
  modulesServices.furtherCareerSEPIRATotalContribution();
  modulesServices.furtherCareerSEPIRATotalEarnings();
  modulesServices.furtherCareerEndingSEPIRABalance();
  modulesServices.furtherCareerPensionBeginningBalance();
  modulesServices.furtherCareerPensionEmployerContributionCalc();
  modulesServices.furtherCareerPensionPersonalContributionCalc();
  modulesServices.furtherCareerPensionEmployerContribution();
  modulesServices.furtherCareerPensionTotalContributionpension();
  modulesServices.furtherCareerPensionTotalContribution();
  modulesServices.furtherCareerPensionTotalEmployerContributions();
  modulesServices.furtherCareerPensionTotalPersonalContributions();
  modulesServices.furtherCareerPensionTotalEarnings();
  modulesServices.furtherCareerEndingPensionBalance();
  modulesServices.furtherCareerRentMultiplier();
  modulesServices.furtherCareerRentExpenses();
  modulesServices.furtherCareerUtilitiesMultiplier();
  modulesServices.furtherCareerUtilitiesExpenses();
  modulesServices.furtherCareerFoodMultiplier();
  modulesServices.furtherCareerFoodExpenses();
  modulesServices.furtherCareerClothingMultiplier();
  modulesServices.furtherCareerClothingExpenses();
  modulesServices.furtherCareerEntertainmentMultiplier();
  modulesServices.furtherCareerEntertainmentExpenses();
  modulesServices.furtherCareerTechnologyMultiplier();
  modulesServices.furtherCareerTechnologyExpenses();
  modulesServices.furtherCareerTransportationMultiplier();
  modulesServices.furtherCareerTransportationExpenses();
  modulesServices.furtherCareerMiscellaneousMultiplier();
  modulesServices.furtherCareerMiscellaneousExpenses();
  modulesServices.furtherCareerOutsidePremiumMultiplier();
  modulesServices.furtherCareerOutsidePremiumExpenses();
  modulesServices.furtherCareerPremiumMultiplier();
  modulesServices.furtherCareerPremiumExpenses();
  modulesServices.furCareerAdvLivingExpenses();
  modulesServices.furCareerAdvHigherEducationExpenses();
  modulesServices.furCareerAdvCarExpenses();
  modulesServices.furCareerAdvChildrenExpenses();
  modulesServices.furCareerAdvPetExpenses();
  modulesServices.furCareerAdvHomeExpenses();
  modulesServices.furCareerAdvFinancingExpenses();
  modulesServices.furCareerAdvTotalAnnualExpenses();
  modulesServices.furCareerAdvIconCalcs();
  modulesServices.furCareerAdvLivingExpensesCalcs();
  modulesServices.furCareerAdvHigherEducationExpensesCalcs();
  modulesServices.furCareerAdvCarExpensesCalcs();
  modulesServices.furCareerAdvChildrenExpensesCalcs();
  modulesServices.furCareerAdvPetExpensesCalcs();
  modulesServices.furCareerAdvHomeExpensesCalcs();
  modulesServices.furCareerAdvFinancingExpensesCalcs();
  modulesServices.furCareerAdvTotalAnnualExpensesCalcs();
  modulesServices.furtherCareerTotalMonthlyLivingExpenses();
  modulesServices.furtherCareerMarriageYear();
  modulesServices.monthlyIncomeFromFurCareerAdv();
  modulesServices.totalMonthlyIncomeFurCareerAdv();
  modulesServices.otherMonthlyIncomeFurCareerAdv();
  modulesServices.totalTaxesPaidFurCareerAdv();
  modulesServices.livingExpensesFurCareerAdv();
  modulesServices.higherEducationExpensesFurCareerAdv();
  modulesServices.childrenExpensesFurCareerAdv();
  modulesServices.petExpensesFurCareerAdv();
  modulesServices.carExpensesFurCareerAdv();
  modulesServices.realEstateExpensesFurCareerAdv();
  modulesServices.internetExpensesFurCareerAdv();
  modulesServices.totalMonthlyExpensesFurCareerAdv();
  modulesServices.monthlyNetIncomeFurCareerAdv();
  modulesServices.retirementContributionsFurCareerAdv();
  modulesServices.monthlyNetCashFlowFurCareerAdv();
  modulesServices.totalIncomeFurCareerAdv();
  modulesServices.totalIncomeFurCareerAdvCalcs();
  modulesServices.annualTotalTaxesPaidFurCareerAdv();
  modulesServices.annualTotalExpensesFurCareerAdvCalcs();
  modulesServices.furCareerAdvAnnualNetIncome();
  modulesServices.furCareerAdvAnnualNetIncomeCalcs();
  modulesServices.annualRetirementContributionsFurCareerAdv();
  modulesServices.annualHsaContributionsFurCareerAdv();
  modulesServices.annualNetCashFlowFurCareerAdv();
  modulesServices.furCareerAdvTotalExpenses();

  // Module 18 Existing and Liabilities
  modulesServices.existingStudentLoansFedSubBeginningBalance();
  modulesServices.existingStudentLoansFedSubMonthlyPayment();
  modulesServices.existingStudentLoansFedSubAnnualPayment();
  modulesServices.existingStudentLoansFedSubTotalInterest();
  modulesServices.existingStudentLoansFedSubTotalPrincipalInterest();
  modulesServices.existingStudentLoansFedUnSubCurrentLoan();
  modulesServices.existingStudentLoansFedUnSubTotalPrincipal();
  modulesServices.existingStudentLoansFedUnSubTotalAccruedInterest();
  modulesServices.existingStudentLoansFedUnSubBeginningBalance();
  modulesServices.existingStudentLoansFedUnSubMonthlyPayment();
  modulesServices.existingStudentLoansFedUnSubAnnualPayment();
  modulesServices.existingStudentLoansFedUnSubTotalInterest();
  modulesServices.existingStudentLoansFedUnSubTotalPrincipalInterest();
  modulesServices.existingStudentLoansPrivateCurrentLoan();
  modulesServices.existingStudentLoansPrivateTotalPrincipal();
  modulesServices.existingStudentLoansPrivateTotalAccruedInterest();
  modulesServices.existingStudentLoansPrivateBeginningBalance();
  modulesServices.existingStudentLoansPrivateMonthlyPayment();
  modulesServices.existingStudentLoansPrivateAnnualPayment();
  modulesServices.existingStudentLoansPrivateTotalInterest();
  modulesServices.existingStudentLoansPrivateTotalPrincipalInterest();
  modulesServices.existingStudentLoansSummaryTotalBeginningBalance();
  modulesServices.existingStudentLoansSummaryMonthlyPayment();
  modulesServices.existingStudentLoansSummaryTotalAnnualPayment();
  modulesServices.existingStudentLoansSummaryTotalInterest();
  modulesServices.existingStudentLoansSummaryTotalPrincipalInterest();
  modulesServices.existingStudentLoansSummaryIncomeRatio();
  modulesServices.existingCreditCardMonthlyPayment();
  //New methods by Sateesh
  modulesServices.existingCurrentCashBalance();
  modulesServices.existingTotalInvestments();
  modulesServices.existingTotalOtherInvestments();
  modulesServices.existingTotalRetirementAccounts();
  modulesServices.existingTotalValuables();
  modulesServices.existingCarLoansLoanLeasePaymentCalcs();
  modulesServices.existingCarLoansLoanMaintenanceCalcs();
  modulesServices.existingCarLoansLoanInsuranceCalcs();
  modulesServices.existingCarLoansLoanGasCalcs();
  modulesServices.existingCarLoansTotalLeaseExpenses();
  modulesServices.existingCarLoansLoanAnnualTotalExpensesCalcs();
  modulesServices.existingCarLoansLoanLeasePaymentCalcs2();
  modulesServices.existingCarLoansLoanMaintenanceCalcs2();
  modulesServices.existingCarLoansLoanInsuranceCalcs2();
  modulesServices.existingCarLoansLoanGasCalcs2();
  modulesServices.existingCarLoansTotalLeaseExpenses2();
  modulesServices.existingCarLoansLoanAnnualTotalExpensesCalcs2();
  modulesServices.existingCarLoansLoanLeasePaymentCalcs3();
  modulesServices.existingCarLoansLoanMaintenanceCalcs3();
  modulesServices.existingCarLoansLoanInsuranceCalcs3();
  modulesServices.existingCarLoansLoanGasCalcs3();
  modulesServices.existingCarLoansTotalLeaseExpenses3();
  modulesServices.existingCarLoansLoanAnnualTotalExpensesCalcs3();
  modulesServices.existingCarLoansLoanLeasePaymentCalcs4();
  modulesServices.existingCarLoansLoanMaintenanceCalcs4();
  modulesServices.existingCarLoansLoanInsuranceCalcs4();
  modulesServices.existingCarLoansLoanGasCalcs4();
  modulesServices.existingCarLoansTotalLeaseExpenses4();
  modulesServices.existingCarLoansLoanAnnualTotalExpensesCalcs4();
  modulesServices.existingCarLoansLoanPaymentsCalcs();
  modulesServices.existingCarLoansLoanPaymentsYearCalcs();
  modulesServices.existingCarLoansLoanPaymentsYearCalcs2();
  modulesServices.existingCarLoansLoanPaymentsYearCalcs3();
  modulesServices.existingCarLoansLoanPaymentsYearCalcs4();

  modulesServices.existingCarLoansMonthlyPayment2();
  modulesServices.existingCarLoansAnnualPayment2();
  modulesServices.existingCarLoansTotalInterest2();
  modulesServices.existingCarLoansTotalPrincipalInterest2();
  modulesServices.existingCarLoansMaintenanceCalcs2();
  modulesServices.existingCarLoansInsuranceCalcs2();
  modulesServices.existingCarLoansGasCalcs2();
  modulesServices.existingCarLoansLoanPaymentsCalcs2();
  modulesServices.existingCarLoansTotalMonthlyExpenses2();

  modulesServices.existingCarLoansMonthlyPayment3();
  modulesServices.existingCarLoansAnnualPayment3();
  modulesServices.existingCarLoansTotalInterest3();
  modulesServices.existingCarLoansTotalPrincipalInterest3();
  modulesServices.existingCarLoansMaintenanceCalcs3();
  modulesServices.existingCarLoansInsuranceCalcs3();
  modulesServices.existingCarLoansGasCalcs3();
  modulesServices.existingCarLoansLoanPaymentsCalcs3();
  modulesServices.existingCarLoansTotalMonthlyExpenses3();

  modulesServices.totalVehicleValues();
  modulesServices.totalVehicleLoanValues();
  modulesServices.totalVehicleLoanPayments()
  modulesServices.totalVehicleLeaseValues();
  modulesServices.totalVehicleMaintenanceValues();
  modulesServices.totalVehicleInsuranceValues();
  modulesServices.totalVehicleGasValues();



  //New methods
  // modulesServices.existingCurrentCashBalance();
  // modulesServices.existingTotalInvestments();
  // modulesServices.existingTotalOtherInvestments();
  // modulesServices.existingTotalRetirementAccounts();
  // modulesServices.existingTotalValuables();
  // modulesServices.existingCarLoansLoanLeasePaymentCalcs();
  // modulesServices.existingCarLoansLoanMaintenanceCalcs();
  // modulesServices.existingCarLoansLoanInsuranceCalcs();
  // // modulesServices.existingCarLoansLoanGasCalcs();
  // modulesServices.existingCarLoansTotalLeaseExpenses();
  // modulesServices.existingCarLoansLoanAnnualTotalExpensesCalcs();


  modulesServices.existingCarLoansMonthlyPayment4();
  modulesServices.existingCarLoansAnnualPayment4();
  modulesServices.existingCarLoansTotalInterest4();
  modulesServices.existingCarLoansTotalPrincipalInterest4();
  modulesServices.existingCarLoansMaintenanceCalcs4();
  modulesServices.existingCarLoansInsuranceCalcs4();
  modulesServices.existingCarLoansGasCalcs4();
  modulesServices.existingCarLoansLoanPaymentsCalcs4();
  modulesServices.existingCarLoansTotalMonthlyExpenses4();
  modulesServices.taxableGainUp1();
  modulesServices.existingHomeMortgageMonthlyPayment2();
  modulesServices.existingHomeMortgageAnnualPayment2();
  modulesServices.existingHomeMortgageTotalInterest2();
  modulesServices.existingHomeMortgageTotalPrincipalInterest2();
  modulesServices.existingHomeMonthlyPropertyTaxesCalcs2();
  modulesServices.existingHomeMonthlyHomeownerInsuranceCalcs2();
  modulesServices.existingHomeMonthlyPrivateMortgageInsuranceCalcs2();
  modulesServices.existingHomeMonthlyMaintenanceCalcs2();
  modulesServices.existingHomeMonthlyAssociationFeesCalcs2();
  modulesServices.existingHomeMonthlyUtilitiesCalcs2();
  modulesServices.mortgagePaymentPrincipalInterest2();
  modulesServices.mortgagePaymentPrincipalInterest2Annual();
  modulesServices.mortgagetotalMonthlyExpenses2();
  modulesServices.mortgagetotalMonthlyExpenses2Annual();
  modulesServices.saleYearCalcs2();
  modulesServices.existingHomeSalesPriceYear2();
  modulesServices.existigHomeSellingCostsCalcs2();
  modulesServices.existingHomeMortgageBalance2();
  modulesServices.existingHomeNetCashFlowFromSale2();
  modulesServices.existingHomeSaleSellingPrice2();
  modulesServices.sellingCosts2();
  modulesServices.netSellingPrice2();
  modulesServices.existingLoansBasis2();
  modulesServices.gainOnSale2();
  modulesServices.taxOnGain2();
  modulesServices.taxableGainUp2();
  modulesServices.taxableGainUp12();
  modulesServices.currentNetWorthCash();
  modulesServices.currentNetWorthInvestments();
  modulesServices.currentNetWorthRetirementAccounts();
  modulesServices.currentNetWorthLifeInsuranceCash();
  modulesServices.currentNetWorthBusiness();
  modulesServices.currentNetWorthOtherValuables();
  modulesServices.currentNetWorthVehicles();
  modulesServices.currentNetWorthHomes();
  modulesServices.currentNetWorthCreditCardDebt();
  modulesServices.currentNetWorthPersonalLoans();
  modulesServices.currentNetWorthStudentLoans();
  modulesServices.currentNetWorthAutoLoans();
  modulesServices.currentNetWorthMortgages();
  modulesServices.currentNetWorthTotalAssetsLiabilities();
  modulesServices.currentNetWorthTotalAssetsLiabilities2();
  modulesServices.currentNetWorthTotalCurrentNetWorth();
  modulesServices.personalLoansMonthlyPayment();
  modulesServices.personalLoansAnnualPaid();
  modulesServices.personalLoansTotalInterest();
  modulesServices.personalLoansTotalRepaymentAmount();

  modulesServices.existingCreditCardAnnualPayment();
  modulesServices.existingCreditCardTotalInterest();
  modulesServices.existingCreditCardTotalPrincipalInterest();
  modulesServices.existingCarLoansMonthlyPayment();
  modulesServices.existingCarLoansAnnualPayment();
  modulesServices.existingCarLoansTotalInterest();
  modulesServices.existingCarLoansTotalPrincipalInterest();
  modulesServices.existingCarLoansMaintenanceCalcs();
  modulesServices.existingCarLoansInsuranceCalcs();
  modulesServices.existingCarLoansGasCalcs();
  modulesServices.existingCarLoansTotalMonthlyExpenses();
  modulesServices.existingCarLoansTotalAnnualExpenses();
  modulesServices.existingCarLoansTotalAnnualExpenses2();
  modulesServices.existingCarLoansTotalAnnualExpenses3();
  modulesServices.existingCarLoansTotalAnnualExpenses4();
  modulesServices.existingHomeMonthlyPropertyTaxesCalcs();
  modulesServices.existingHomeMonthlyHomeownerInsuranceCalcs();
  modulesServices.existingHomeMonthlyPrivateMortgageInsuranceCalcs();
  modulesServices.existingHomeMonthlyMaintenanceCalcs();
  modulesServices.existingHomeMonthlyAssociationFeesCalcs();
  modulesServices.existingHomeMonthlyUtilitiesCalcs();
  modulesServices.mortgagePaymentPrincipalInterest();
  modulesServices.mortgagePaymentPrincipalInterestAnnual();
  modulesServices.mortgagetotalMonthlyExpenses();
  modulesServices.mortgagetotalMonthlyExpensesAnnual();
  modulesServices.existigHomeCurrentMortgageBalanceCalcs();
  modulesServices.existingHomeMortgageMonthlyPayment();
  modulesServices.existingHomeMortgageAnnualPayment();
  modulesServices.existingHomeMortgageTotalInterest();
  modulesServices.existingHomeMortgageTotalPrincipalInterest();
  modulesServices.existingHomeSalesPriceYear();
  modulesServices.saleYearCalcs();
  modulesServices.existigHomeSellingCostsCalcs();
  modulesServices.existingHomeMortgageBalance();
  modulesServices.existingHomeNetCashFlowFromSale();
  modulesServices.existingHomeSaleSellingPrice();
  modulesServices.sellingCosts();
  modulesServices.netSellingPrice();
  modulesServices.existingLoansBasis();
  modulesServices.gainOnSale();
  modulesServices.existingHomedebtIncomeRatio();
  modulesServices.taxableGainUpLabel();
  modulesServices.incomeNeededExistingStudentLoans();
  modulesServices.taxableGainUp();
  modulesServices.taxOnGain();
  modulesServices.netGainOnSale();

  // Family Module functions Start
  modulesServices.careerSpouseYearDiff();
  modulesServices.careerAdvancementSpouseYearDiff();
  modulesServices.MaritalIncomeStatementSummary();
  modulesServices.childrenAverageCost();
  modulesServices.preTaxExpectedIncome();
  modulesServices.totalPreTaxExpectedIncome();
  modulesServices.incomeEarnedSpouse();
  modulesServices.spouseEstimatedTaxRate();
  modulesServices.spouseEstimatedTaxesPaid();
  modulesServices.spouseEstimatedIncomeafterTaxes();
  modulesServices.morePreTaxExpectedIncome();
  modulesServices.totalMorePreTaxExpectedIncome();
  modulesServices.moreIncomeEarnedSpouse();
  modulesServices.moreSpouseEstimatedTaxRate();
  modulesServices.moreSpouseEstimatedTaxesPaid();
  modulesServices.moreSpouseLivingExpensesPaid();
  modulesServices.moreSpouseFinancingExpensesPaid();
  modulesServices.moreSpouseEstimatedIncomeafterTaxes();
  modulesServices.marritalSpouseIncome();
  modulesServices.marritalSpouseIncomeMain();
  modulesServices.marritalOtherIncome();
  modulesServices.marritalOtherIncomeMain();
  modulesServices.marritalTotalIncome();
  modulesServices.marritalTotalIncomeMain();
  modulesServices.marritalTaxesPaid();
  modulesServices.marritalTaxesPaidMain();
  modulesServices.marritalTaxesRate();
  modulesServices.marritalTaxesRateMain();
  modulesServices.marritalExpensesPaid();
  modulesServices.marritalExpensesPaidMain();
  modulesServices.higherEducationExpenses();
  modulesServices.higherEducationExpensesMain();
  modulesServices.childrenExpenses();
  modulesServices.childrenExpensesMain();
  modulesServices.petExpenses();
  modulesServices.petExpensesMain();
  modulesServices.marritalCarPaid();
  modulesServices.marritalCarPaidMain();
  modulesServices.marritalRealEstatePaid();
  modulesServices.marritalRealEstatePaidMain();
  modulesServices.marritalFinancingExpensesPaid();
  modulesServices.marritalFinancingExpensesPaidMain();
  modulesServices.totalAnnualExpenses();
  modulesServices.totalAnnualExpensesMain();
  modulesServices.annualCashFlowBeforeRetirementContributions();
  modulesServices.annualCashFlowBeforeRetirementContributionsMain();
  modulesServices.retirementContributions();
  modulesServices.retirementContributionsMain();
  modulesServices.netCashFlowForSavingsAndInvestments();
  modulesServices.netCashFlowForSavingsAndInvestmentsMain();
  modulesServices.marritalIncomeAfter();
  modulesServices.spouseMonthlyPaymentInterest();
  modulesServices.spouseAnnualPaidInterest();
  modulesServices.spouseTotalInterest();
  modulesServices.spouseTotalPrincipalInterest();
  modulesServices.childernYearDropdowns();
  modulesServices.averageCostRaisingChild();
  modulesServices.annualCostOfOwingPet();
  modulesServices.totalYearlyCostOfOwningAPet();
  modulesServices.familyRentMultiplier();
  modulesServices.familyRentMultiplierCalc();
  modulesServices.familyUtilitiesMultiplier();
  modulesServices.familyUtilitiesMultiplierCalc();
  modulesServices.familyFoodMultiplier();
  modulesServices.familyFoodMultiplierCalc();
  modulesServices.familyClothingMultiplier();
  modulesServices.familyClothingMultiplierCalc();
  modulesServices.familyEntertainmentMultiplier();
  modulesServices.familyEntertainmentMultiplierCalc();
  modulesServices.familyTechnologyMultiplier();
  modulesServices.familyTechnologyMultiplierCalc();
  modulesServices.familyTransportationMultiplier();
  modulesServices.familyTransportationMultiplierCalc();
  modulesServices.familyMiscellaneousMultiplier();
  modulesServices.familyMiscellaneousMultiplierCalc();
  modulesServices.familyHealthInsuranceOutsidePremiumMultiplier();
  modulesServices.familyHealthInsuranceOutsidePremiumMultiplierCalc();
  modulesServices.familyHealthInsurancePremiumMultiplier();
  modulesServices.familyHealthInsurancePremiumMultiplierCalc();
  modulesServices.sumOfFamilyMultiplier();
  modulesServices.sumOfFamilyMultiplierCalc();
  modulesServices.familyExpensesMarriageYearLable();

  // module12 Buying a car functions
  modulesServices.askingPrice();
  modulesServices.discountCalcs();
  modulesServices.rebateCalcs();
  modulesServices.netPurchasePrice();
  modulesServices.tradeInValue();
  modulesServices.tradeInValue2();
  modulesServices.tradeInValue3();
  modulesServices.tradeInValue4();
  modulesServices.adjustedPurchasePrice();
  modulesServices.salesTaxCalcs();
  modulesServices.originationFeesCalculations();
  modulesServices.downPaymentCar();
  modulesServices.totalPurchasePrice();
  modulesServices.totalBuyingCosts();
  modulesServices.carLoan();
  modulesServices.termOfTheLoanYears();
  modulesServices.monthlyPayment();
  modulesServices.yearlyPayment();
  modulesServices.totalCarInterest();
  modulesServices.totalPrincipalAndInterest();
  modulesServices.firstCarMaintenanceCalcs();
  modulesServices.firstCarInsuranceCalcs();
  modulesServices.firstCarGasCalcs();
  modulesServices.totalMonthlyExpensesFirstCar();
  modulesServices.totalAnnualExpensesFirstCar();
  modulesServices.destinationChargesSummary();
  modulesServices.getCarOptions();
  modulesServices.investmentAnalysisPurchasePrice();
  modulesServices.investmentAnalysisDiscount();
  modulesServices.investmentAnalysisRebate();
  modulesServices.investmentAnalysisTradeIn();
  modulesServices.investmentAnalysisSalesTax();
  modulesServices.investmentAnalysisRegistrationFees();
  modulesServices.investmentAnalysisTotalPurchasePrice();
  modulesServices.investmentAnalysisAdjustedPurchasePrice();
  modulesServices.carDownPayment();
  modulesServices.carLoanPoints();
  modulesServices.cashNeededAtPurchase();
  modulesServices.totalCashAvailable();
  modulesServices.totalLiquid();
  modulesServices.outstandingCarLoan();
  modulesServices.investmentAnalysisTotalPrincipal();
  modulesServices.moreAskingPrice();
  modulesServices.moreDiscountCalcs();
  modulesServices.moreRebateCalcs();
  modulesServices.moreNetPurchasePrice();
  modulesServices.moreTradeInValue();
  modulesServices.moreTradeInValue2();
  modulesServices.moreTradeInValue3();
  modulesServices.moreTradeInValue4();
  modulesServices.moreTradeInValue5();

  modulesServices.moreAdjustedPurchasePrice();
  modulesServices.moreDownPaymentCar();
  modulesServices.moreTotalPurchasePrice();
  modulesServices.moreSalesTaxCalcs();
  modulesServices.secondOriginationFeesCalculations();
  modulesServices.moreTotalBuyingCosts();
  modulesServices.moreCarLoan();
  modulesServices.moreTermOfTheLoanYears();
  modulesServices.moreMonthlyPayment();
  modulesServices.moreYearlyPayment();
  modulesServices.moreTotalCarInterest();
  modulesServices.moreTotalPrincipalAndInterest();
  modulesServices.secondCarMaintenanceCalcs();
  modulesServices.secondCarInsuranceCalcs();
  modulesServices.secondCarGasCalcs();
  modulesServices.totalMonthlyExpensesSecondCar();
  modulesServices.totalAnnualExpensesSecondCar();
  modulesServices.moreInvestmentAnalysisPurchasePrice();
  modulesServices.moreDestinationChargesSummary();
  modulesServices.getMoreCarOptions();
  modulesServices.moreInvestmentAnalysisDiscount();
  modulesServices.moreInvestmentAnalysisRebate();
  modulesServices.moreInvestmentAnalysisTradeIn();
  modulesServices.moreInvestmentAnalysisSalesTax();
  modulesServices.moreInvestmentAnalysisRegistrationFees();
  modulesServices.moreInvestmentAnalysisTotalPurchasePrice();
  modulesServices.moreInvestmentAnalysisAdjustedPurchasePrice();
  modulesServices.moreCarDownPayment();
  modulesServices.moreCarLoanPoints();
  modulesServices.moreCashNeededAtPurchase();
  modulesServices.moreTotalCashAvailable();
  modulesServices.moreTotalLiquid();
  modulesServices.moreOutstandingCarLoan();
  modulesServices.moreInvestmentAnalysisTotalPrincipal();
  modulesServices.leasingCarEndYearVal();
  modulesServices.cashNeededToStartLease();
  modulesServices.leasingCarAnnualPayment();
  modulesServices.leasingCarLeasePaymentCalc();
  modulesServices.leasingCarMaintenanceCalc();
  modulesServices.leasingCarInsuranceCalc();
  modulesServices.leasingCarGasCalc();
  modulesServices.leasingCarTotalMonthlyExpenses();
  modulesServices.leasingCarTotalAnnualExpenses();
  modulesServices.leasingCarDownPaymentSummary();
  modulesServices.leasingCarTotalPaymentSummary();
  modulesServices.leasingCarTotalExpensesSummary();
  modulesServices.leasingCarTotalCostsSummary();
  modulesServices.leasingSecondCarEndYearVal();
  modulesServices.leasingSecondCarAnnualPayment();
  modulesServices.cashNeededToSecondStartLease();
  modulesServices.leasingSecondCarLeasePaymentCalc();
  modulesServices.leasingSecondCarMaintenanceCalc();
  modulesServices.leasingSecondCarInsuranceCalc();
  modulesServices.leasingSecondCarGasCalc();
  modulesServices.leasingSecondCarTotalMonthlyExpenses();
  modulesServices.leasingSecondCarTotalAnnualExpenses();
  modulesServices.leasingSecondCarDownPaymentSummary();
  modulesServices.leasingSecondCarTotalPaymentSummary();
  modulesServices.leasingSecondCarTotalExpensesSummary();
  modulesServices.leasingSecondCarTotalCostsSummary();
  modulesServices.carYearlyPayment();

  // module 9 Investments
  modulesServices.shortAnnualNetIncomeInYear();
  modulesServices.initialCashBalance();
  modulesServices.currentYearCashBalance();
  modulesServices.shortTermInvestmentsPeriod();
  modulesServices.shortTermInvestmentsPeriodYears();
  modulesServices.shortTermSummaryBeginningBalance();
  modulesServices.shortTermSummaryContributions();
  modulesServices.shortTermSummaryEarnings();
  modulesServices.shortTermInvestmentsBeforeTransfers();
  modulesServices.shortTermSummaryExcessCash();
  modulesServices.shortTermSummaryCashWithDrawn();
  modulesServices.shortTermSummaryCashDeposited();
  modulesServices.shortTermSummaryEndingBalance();
  modulesServices.longAnnualNetIncomeInYear();
  modulesServices.longTermInitialCashBalance();
  modulesServices.longTermCashBalance();
  modulesServices.longTermInvestmentsPeriod();
  modulesServices.longTermInvestmentsPeriodYears();
  modulesServices.longTermSummaryBeginningBalance();
  modulesServices.longTermSummaryContributions();
  modulesServices.longTermSummaryEarnings();
  modulesServices.longTermInvestmentsBeforeTransfers();
  modulesServices.longTermSummaryExcessCash();
  modulesServices.longTermSummaryCashWithDrawn();
  modulesServices.longTermSummaryEndingBalance();
  modulesServices.totalFinancialGift();
  modulesServices.charitableAnnualContributionTotalIncome();
  modulesServices.charitableAnnualContribution();
  modulesServices.charitableTotalContribution();
  modulesServices.charitableTaxSavings();
  //New added by Phrudhvi
  modulesServices.shortTermBeginningBalance();
  modulesServices.afterTaxIncomeInYearShortTerm();
  modulesServices.shortTermAnnualContribution();
  modulesServices.longTermBeginningBalance();
  modulesServices.afterTaxIncomeInYearLongTerm();
  modulesServices.longTermAnnualContribution();
  modulesServices.beginning529Balance();
  modulesServices.afterTaxIncomeInYear529Balance();
  modulesServices.afterTaxIncomeInYearInsurenceBalance();
  modulesServices.annualContributionInYear529();
  modulesServices.cashBalanceAtTheEndOfPreviourYear529();
  modulesServices.cashBalanceAtTheEndOfCurrentYear529();
  modulesServices.longTermInvestmentsPeriod529();
  modulesServices.longTermInvestmentsPeriodYears529();
  modulesServices.totalContribution529();
  modulesServices.totalEarnings529();
  modulesServices.endingBalance529();
  modulesServices.beginningLifeInsBalance();
  modulesServices.monthlyPremiumCalcs();
  modulesServices.monthlyContributionCashBuilder();
  modulesServices.monthlyContributionCashBuilderAnnualVal();
  modulesServices.annualNetCashFlowForSavingsLifeIns();
  modulesServices.cashBalanceAtTheEndYearLifeIns();
  modulesServices.cashBalanceAtTheCurrentYearLifeIns();
  modulesServices.longTermInvestmentsPeriodLife();
  modulesServices.longTermInvestmentsPeriodYearsLife();
  modulesServices.beginningLifeInsBalanceAnalysis();
  modulesServices.totalContributionLifeIns();
  modulesServices.totalEarningsLifeIns();
  modulesServices.endingBalanceLifeIns();
  modulesServices.annualNetCashFlowDorSavingsAndInvestments529();
  modulesServices.beginning529BalanceAnalysis();
  //end

  // compare saved notes functions
  modulesServices.get5thYearNetworth();
  modulesServices.get10thYearNetworth();
  modulesServices.get15thYearNetworth();
  modulesServices.investmentAnalysisTuitionCostsCal();
  modulesServices.newHigherinvestmentAnalysisTuitionCosts();
  modulesServices.newHigherStudentLoanTotalInterestPaid();
  modulesServices.investmentAnalysisCashExcess();
  modulesServices.investmentAnalysisTotalLiving();
  modulesServices.investmentAnalysisCashExcessAfterLiving();
  modulesServices.investmentAnalysisCashExcessBeforeLiving();
  modulesServices.familyContriYearsDiff();
  modulesServices.investmentAnalysisFamilyContribution();
  modulesServices.aidYearsDiff();
  modulesServices.investmentAnalysisFinancialAid();
  modulesServices.investmentAnalysisGrants();
  modulesServices.investmentAnalysisOutstandingLoan();
  modulesServices.investmentAnalysisScholships();
  modulesServices.investmentAnalysisTotalPrincipalInterest();
  modulesServices.exisitngHomeSummary(0);
  modulesServices.totalCashAvailableYear();
  modulesServices.totalLiquidYear();
}

export {
  initializeExcelSheetsData,
  workSheetCalc,
};
