import { formatDollar } from '../utilities/chartCommonFunctions';
// eslint-disable-next-line

const existingStudentLoanGraphData = [];

existingStudentLoanGraphData['Annual Contribution'] = 100;
existingStudentLoanGraphData['Low Analysis'] = 200;
existingStudentLoanGraphData['Medium Analysis'] = 300;
existingStudentLoanGraphData['High Analysis'] = 400;
existingStudentLoanGraphData.Low = [100, 200, 300];
existingStudentLoanGraphData.High = [100, 200, 300];
existingStudentLoanGraphData.Medium = [100, 200, 300];
existingStudentLoanGraphData['Years List'] = [2019, 2020, 2021, 2022, 2023];

function module18Dynamicbargraph() {
  const module18Dynamicbar = {
    chart: {
      height: 530,
      // width:graphMainWidth
    },
    navigation: {
      menuItemStyle: {
        fontSize: '14px',
        textAlign: 'left',
      },
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#4c4c4c',
        fontSize: '14px',
      },
      buttonOptions: {
        height: 40,
        width: 48,
        symbolSize: 24,
        symbolX: 24,
        symbolY: 21,
        symbolStrokeWidth: 2,
        verticalAlign: 'bottom',
        _titleKey: 'y',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'downloadPNG',
            onclick() {
              this.exportChart();
            },
          }, {
            textKey: 'downloadJPEG',
            onclick() {
              this.exportChart( {
                type: 'image/jpeg',
              } );
            },
          }, {
            textKey: 'downloadPDF',
            onclick() {
              this.exportChart( {
                type: 'application/pdf',
              } );
            },
          }, {
            textKey: 'downloadSVG',
            onclick() {
              this.exportChart( {
                type: 'image/svg+xml',
              } );
            },
          }],
        },
      },
    },
    title: {
      text: '',
      style: {
        color: '#0171C9',
        fontWeight: 'bold',
      },
    },
    xAxis: [{
      categories: existingStudentLoanGraphData['Years List'],
      crosshair: true,
      labels: {
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
    }],
    yAxis: [{ // Primary yAxis
      labels: {
        formatter() {
          if ( this.value < 0 ) {
            return `<span style="color:#bf0000;">(${formatDollar( -Math.round( this.value ) )})</span>`;
          }
          return formatDollar( Math.round( this.value ) );
        },
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      title: {
        text: 'Invested Annual Savings Balance',
        style: {
          color: '#000000',
          fontSize: '16px',
        },
      },
      opposite: true,
    }, { // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Annual Savings Invested Each Year',
        style: {
          color: '#000000',
          fontSize: '16px',
        },
      },
      labels: {
        formatter() {
          if ( this.value < 0 ) {
            return `<span style="color:#bf0000;">(${formatDollar( -Math.round( this.value ) )})</span>`;
          }
          return formatDollar( Math.round( this.value ) );
        },
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
    }],
    tooltip: {},
    legend: {
      itemStyle: {
        fontSize: '14px',
      },
    },
    series: [{
      name: 'Annual Savings Invested',
      data: existingStudentLoanGraphData['Annual Contribution'],
      type: 'column',
      yAxis: 1,
      color: '#DDDAD6',
      tooltip: {
        valuePrefix: '$',
      },
    }, {
      name: `Low (${parseFloat( existingStudentLoanGraphData['Low Analysis'] ).toFixed( 2 )}% Return)`,
      data: existingStudentLoanGraphData.Low,
      type: 'spline',
      yAxis: 0,
      color: '#58AD4B',
      tooltip: {
        valuePrefix: '$',
      },
    }, {
      name: `Medium (${parseFloat( existingStudentLoanGraphData['Medium Analysis'] ).toFixed( 2 )}% Return)`,
      data: existingStudentLoanGraphData.Medium,
      type: 'spline',
      yAxis: 0,
      color: '#FF7322',
      tooltip: {
        valuePrefix: '$',
      },
    }, {
      name: `High (${parseFloat( existingStudentLoanGraphData['High Analysis'] ).toFixed( 2 )}% Return)`,
      data: existingStudentLoanGraphData.High,
      type: 'spline',
      yAxis: 0,
      color: '#006FBB',
      tooltip: {
        valuePrefix: '$',
      },
    }],
  };

  return module18Dynamicbar;
}

export {
  module18Dynamicbargraph,
};

// export default loadGraphs;
