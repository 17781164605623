import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';
import _ from 'lodash';
// import $ from 'jquery';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import xpGraphic from '../../../../assets/img/pf101-dashboard/xp-graphic.png';
import beginner from '../../../../assets/img/pf101-dashboard/pf101-rank-beginner.png';
import intermediate from '../../../../assets/img/pf101-dashboard/pf101-icons-intermediate.png';
import expert from '../../../../assets/img/pf101-dashboard/pf101-rank-expert.png';
import legend from '../../../../assets/img/pf101-dashboard/pf101-rank-legend.png';
import master from '../../../../assets/img/pf101-dashboard/pf101-rank-master.png';
import powerupLogo from '../../../../assets/img/pf101/powerup.png';
import xpPowerupLogo from '../../../../assets/img/pf101/xp-powerup.png';
import XpLabelComponent, { PwiSlider } from '../../../../components/common/pwi/slider-custome-tool-tip';
import BitCoinPopups from '../bit-coin-questions-popups/bit-questions-1';
import BitCoinPopups2 from '../bit-coin-questions-popups/bit-questions-2';
import BitCoinPopups3 from '../bit-coin-questions-popups/bit-questions-3';
import BitCoinPopups4 from '../bit-coin-questions-popups/bit-questions-4';
import BitCoinAnswerPopups from '../bit-coin-questions-popups/bit-coin-answers-popup';
import BitCoinAnswer2 from '../bit-coin-questions-popups/bit-coin-answers2';
import BitCoinAnswer3 from '../bit-coin-questions-popups/bit-coin-answers3';
import BitCoinAnswer4 from '../bit-coin-questions-popups/bit-coin-answers4';
import ConfettiGenerator from "confetti-js";

const SAVE_ACTIVITY = loader('../../../../graphql/schema/pf101/savePf101Activity.graphql');


const xpPoints = [
  {
    value: 0,
    label: '0XP',
  },
  {
    value: 50,
    label: '50XP',
  },
  {
    value: 100,
    label: '100XP',
  },
  {
    value: 150,
    label: '150XP',
  },
  {
    value: 200,
    label: '200XP',
  },
  {
    value: 250,
    label: '250XP',
  },
];

const Pf101PopupsLog = (props) => {
  const { classes, details, history, level } = props;
  const [popup, setPopup] = React.useState(1);
  const [xpValue, setXpValue] = React.useState(0);
  const [challengeIndex, setChallengeIndex] = React.useState(1);
  const [challengeAnswer, setChallengeAnswer] = React.useState();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [selectedAnswer, setSelectedAnswer] = React.useState();
  const [draggedKeys, setDraggedKeys] = React.useState([]);
  const [rightAnswer, setRightAnswer] = React.useState();


  const updatePopup = (number) => {   
    history.push('/');   
};

  
  const handleGrandChange = (eve, val) => {
    setXpValue(val);
  }

  React.useEffect(() => {
    const pathUrl = window.location.pathname.split('/');
    if ((pathUrl[2] === "level6" && draggedKeys.length > 4) || (pathUrl[2] === "level8" && draggedKeys.length > 2)) {
      setChallengeAnswer(true);
    }
  }, [draggedKeys]);

  const checkAnswer = () => {
    const pathUrl = window.location.pathname.split('/');
    if (pathUrl[2] === "level6" && challengeIndex === 1) {
      let answwer = _.isEqual(draggedKeys, [true, true, true, true, true]);
      setRightAnswer(answwer);
    } else if (pathUrl[2] === "level8" && challengeIndex === 2) {
      let answwer = _.isEqual(draggedKeys, [true, true, true]);
      setRightAnswer(answwer);
    }
    else {
      if (challengeAnswer === selectedAnswer) {
        setRightAnswer(true);
      } else {
        setRightAnswer(false);
      }
    }
    setBtnDisabled(true);
    let datee = new Date().toLocaleDateString();
    let levelCount;

    if (level === 2) {
      levelCount = 14
    } else if (level === 4) {
      levelCount = 15
    }
    else if (level === 6) {
      levelCount = 16
    }
    else {
      levelCount = 17
    }
    saveActivity(
      {
        variables: {
          data: {
            completed_date: datee,
            level: 1,
            exercise: levelCount,
            points: rightAnswer === true ? xpValue : "-" + xpValue,
            total_attempts: 0,
          },
        },
      }
    );
  }

  const [saveActivity] = useMutation(SAVE_ACTIVITY, {
    onCompleted(response) {
      let confettiSettings = { target: 'drawing_canvas', "max": "100", "size": "1", "animate": true, "props": ["circle","square","triangle","line"], "colors": [[165, 104, 246], [230, 61, 135], [0, 199, 228], [253, 214, 126]], "rotate": false, "width": "300", "height": "300", "start_from_edge": false, "respawn": true, "spread":100 };
      let confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      setTimeout(() => {
        confetti.clear();
      }, 1000);
    },
    onError() {
      return false;
    },
  });

  return (
    <Typography variant="body1" component="div">

      { /* Activity complete Popup */}
      {popup === 1 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.completeActivity}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classes.xpIcon}>
                <p className={classes.graphIcon}><img src={xpGraphic} alt="logo" /></p>
                {details.type !== undefined && details.type === 'challenge' ? (
                  <p className={classes.graphTitle}>
                    Challenge Complete
                    <br />
                    {' '}
                    +
                    {details.exercisePoints}
                    {' '}
                    {' '}
                    XP
                  </p>
                ) : (
                  <p className={classes.graphTitle}>
                    Activity Complete +
                    {details.exercisePoints}
                    {' '}
                    {' '}
                    XP
                  </p>
                )}
              </div>
              {details.type !== undefined && details.type === 'challenge' ? (
                <p className={classes.niceWork}>Well done!</p>
              ) : (
                <p className={classes.niceWork}>Nice Work</p>
              )}

              {details.type !== undefined && details.type === 'challenge' ? (
                <p className={classes.activityDesc}>
                  You&apos;ve earned +
                  {details.exercisePoints}
                  {' '}
                  {' '}
                  PowerUp XP
                  <br />
                  {' '}
                  and move to the next level.
                </p>
              ) : (
                <p className={classes.activityDesc}>
                  <span>You&apos;ve completed this activity</span>
                  <span>
                    and earned +
                    {details.exercisePoints}
                    {' '}
                    {' '}
                    PowerUp XP.
                  </span>
                </p>
              )}
              <div className={classes.footerButton}>
                <Button onClick={() => updatePopup(1)}>Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      { /* Activity Precision Popup */}
      {popup === 2 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.completeActivity}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classes.xpIcon}>
                <p className={classes.graphIcon}><img src={xpGraphic} alt="logo" /></p>
                <p className={classes.graphTitle}>
                  Precision Points +
                  {details.precisionPoints}
                  {' '}
                  {' '}
                  XP
                </p>
              </div>
              <p className={classes.niceWork}>Great Job!</p>
              <p className={classes.activityDesc}>
                <span>You answered all questions right</span>
                <span>on the first try.</span>
              </p>
              <div className={classes.footerButton}>
                <Button onClick={() => updatePopup(2)}>Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      { /* Level Complete Popup */}
      {popup === 3 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.completeActivity}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classes.xpIcon}>
                <p className={classes.graphIcon}><img src={xpGraphic} alt="logo" /></p>
                <p className={classes.graphTitle}>
                  Level
                  {' '}
                  {details.levelNumber}
                  {' '}
                  Complete +
                  {details.levelPoints}
                  {' '}
                  {' '}
                  XP
                </p>
              </div>
              <p className={classes.niceWork}>Excellent work!</p>
              <p className={classes.activityDesc}>
                <span>
                  You&apos;ve earned +
                  {details.levelPoints}
                  {' '}
                  PowerUp XP
                </span>
                <span>
                  and advance to Level
                  {details.levelNumber + 1}
                  .
                </span>
              </p>
              <div className={classes.footerButton}>
                <Button onClick={() => updatePopup(3)}>Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      { /* Rankup Complete Popup */}
      {popup === 4 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.completeActivity}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classNames(classes.xpIcon, classes.rankIcon)}>
                <p className={classes.rankImg}>
                  {details.rank === 'beginner' && <img src={beginner} alt="logo" />}
                  {details.rank === 'Intermediate' && <img src={intermediate} alt="logo" />}
                  {details.rank === 'Pro' && <img src={expert} alt="logo" />}
                  {details.rank === 'Expert' && <img src={legend} alt="logo" />}
                  {details.rank === 'Legend' && <img src={master} alt="logo" />}
                </p>
                <p className={classes.graphTitle}>
                  Rank Up to
                  {' '}
                  {details.rank}
                </p>
              </div>
              <p className={classes.niceWork}>Congrats!</p>
              <p className={classes.activityDesc}>
                <span>You&apos;re making great progress.</span>
                <span>
                  Your rank is now
                  {' '}
                  {details.rank}
                  .
                </span>
              </p>
              <div className={classes.footerButton}>
                <Button onClick={() => updatePopup(4)}>Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      { /* bitcoin Popup */}
      {popup === 5 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.completeActivity, classes.completeActivityFive)}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classes.completePopupLogo}>
                <img src={powerupLogo} alt="logo" />
              </div>
              <div className={classes.xpPowerupGraphic}>
                <div className={classes.xpPowerupGraphicLogo}>
                  <img src={xpPowerupLogo} alt="logo" />
                </div>
                <div className={classes.bitCodeContent}>
                  <p>Are you willing to take a risk and bet your hard earned PowerUp XP? Test your skills and wager your XP with a challenge question.</p>
                  <p>First, place your bet - you can risk up to 250 XP. If you get the question right on the first try, you'll add the amount you bet to your score. But, if you don't get the question right on the first try, you'll lose all the XP you wagered.</p>
                  <p>Are you up for the challenge? If so, click "Continue". Or, sit this one out and hit "No Thanks".</p>
                  <div className={classes.modalFooter}>
                    <Button onClick={() => { history.push('/pf101/dashboard') }}>No Thanks</Button>
                    <Button onClick={() => updatePopup(5)}>Continue &nbsp;&nbsp;&#62;</Button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      { /* bitcoin XP selection  Popup */}
      {popup === 6 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.completeActivity, classes.completeActivityFive, "complete-popup")}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classNames(classes.completePopupLogo, classes.mrAuto)}>
                <img src={powerupLogo} alt="logo" />
              </div>
              <div className={classes.completePopupChallenge}>
                <h1>POWERUP CHALLENGE EXPLANATION</h1>
                <p>PLACE YOUR BET...</p>
                <div className={classes.completePopupSlider}>
                  <div className={classes.sliderLeft}>
                    <p>PowerUp XP</p>
                  </div>
                  <div className={classes.sliderRight}>
                    <PwiSlider
                      value={xpValue}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={` ${xpValue}XP`}
                      min={0}
                      max={250}
                      step={5}
                      marks={xpPoints}
                      ValueLabelComponent={XpLabelComponent}
                      onChange={(e, value) => { handleGrandChange(e, value); }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <Button className="mr0" disabled={xpValue === 0} onClick={() => updatePopup(6)}>Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      { /* bitcoin XP questions  Popup */}
      {popup === 7 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.completeActivity, classes.completeActivityFive, "complete-popup")}
        >
          <DialogContent>
            <div className={classes.completePopup}>
            <div className={classes.canvasAnimation}>
                <canvas id="drawing_canvas"></canvas>
              </div>
              <div className={classNames(classes.completePopupLogo, classes.mrAuto)}>
                <img src={powerupLogo} alt="logo" />
              </div>
              <div className={classes.xpIcon}>
                {level === 2 && (<BitCoinPopups challengeIndex={challengeIndex} level={2} setChallengeAnswer={setChallengeAnswer} challengeAnswer={challengeAnswer} btnDisabled={btnDisabled} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} />)}
                {level === 4 && (<BitCoinPopups2 challengeIndex={challengeIndex} level={4} setChallengeAnswer={setChallengeAnswer} challengeAnswer={challengeAnswer} btnDisabled={btnDisabled} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} />)}
                {level === 6 && (<BitCoinPopups3 challengeIndex={challengeIndex} level={6} setChallengeAnswer={setChallengeAnswer} challengeAnswer={challengeAnswer} btnDisabled={btnDisabled} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} setDraggedKeys={setDraggedKeys} draggedKeys={draggedKeys} />)}
                {level === 8 && (<BitCoinPopups4 challengeIndex={challengeIndex} level={8} setChallengeAnswer={setChallengeAnswer} challengeAnswer={challengeAnswer} btnDisabled={btnDisabled} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} setDraggedKeys={setDraggedKeys} draggedKeys={draggedKeys} />)}
              </div>
              <div className={classNames(classes.modalFooter, classes.modalFooterBtnRow, classes.mt30)}>
                {(btnDisabled && rightAnswer === true) && (<span className={classes.pcQuestStatus}>Correct! You earned +{xpValue} XP.</span>)}
                {(btnDisabled && rightAnswer === false) && (<span className={classes.pcQuestStatus}>Incorrect. You lost -{xpValue} XP.</span>)}
                <Button disabled={!challengeAnswer} onClick={() => { checkAnswer() }}>Check Answer</Button>
              </div>
              <div className={classNames(classes.modalFooter, classes.modalFooterBtnRow2)}>
                <Button disabled={rightAnswer === undefined} onClick={() => updatePopup(7)}>Continue &nbsp;&nbsp;&#62;</Button>
              </div>

            </div>
          </DialogContent>
        </Dialog>
      )}
      { /* bitcoin XP questions  Popup */}
      {popup === 8 && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.completeActivity, classes.completeActivityFive, "complete-popup")}
        >
          <DialogContent>
            <div className={classes.completePopup}>
              <div className={classNames(classes.completePopupLogo, classes.mrAuto)}>
                <img src={powerupLogo} alt="logo" />
              </div>
              <div className={classes.completePopupChallenge}>
                <h1>POWERUP CHALLENGE EXPLANATION</h1>
              </div>
              <div className={classes.xpIcon}>
                {level === 2 && (<BitCoinAnswerPopups challengeIndex={challengeIndex} challengeAnswer={challengeAnswer} />)}
                {level === 4 && (<BitCoinAnswer2 challengeIndex={challengeIndex} challengeAnswer={challengeAnswer} />)}
                {level === 6 && (<BitCoinAnswer3 challengeIndex={challengeIndex} challengeAnswer={challengeAnswer} />)}
                {level === 8 && (<BitCoinAnswer4 challengeIndex={challengeIndex} challengeAnswer={challengeAnswer} />)}
              </div>
              <div className={classes.modalFooter}>
                <Button onClick={() => { history.push('/pf101/dashboard') }}>GO TO DASHBOARD</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
     

    </Typography>
  );
};

Pf101PopupsLog.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired
}; 

const enhance = compose( 
  withStyles(styles),
  withRouter, 
);

export default enhance(Pf101PopupsLog);
 