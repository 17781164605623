import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Tabs, Tab, Box,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';

const INCOME_GROWTH_GRAPH_DATA = loader( '../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql' );
function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
      <Box p={ 3 }>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const IncomeGrowthGraphs = ( props ) => {
  const { classes } = props;
  const { data: incomeGrowthGraphData } = useQuery( INCOME_GROWTH_GRAPH_DATA );

  const overallGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[3];
  const educationGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[0];
  const ageGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[1];
  const industryGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[2];
  const theme = useTheme();
  const [valueTab, setValueTab] = useState( 0 );
  const handleChange = ( event, newValue ) => {
    setValueTab( newValue );
  };
  const handleChangeIndex = ( index ) => {
    setValueTab( index );
  };
  return (
    <div className={ classes.tabSection }>
      <Tabs
        value={ valueTab }
        onChange={ handleChange }
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={ classes.tabButtons }
        aria-label="full width tabs example"
      >
        <Tab className={ valueTab === 0 ? classes.activeTab : '' } label="Overall Income Growth " { ...a11yProps( 0 ) } />
        <Tab className={ valueTab === 1 ? classes.activeTab : '' } label="Income Growth by Education" { ...a11yProps( 1 ) } />
        <Tab className={ valueTab === 2 ? classes.activeTab : '' } label="Income Growth by Age" { ...a11yProps( 2 ) } />
        <Tab className={ valueTab === 3 ? classes.activeTab : '' } label="Income Growth by Industry" { ...a11yProps( 3 ) } />
      </Tabs>
      <SwipeableViews
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ valueTab }
        className={ classes.tabsContent }
        onChangeIndex={ handleChangeIndex }
      >
        <TabPanel value={ valueTab } index={ 0 } dir={ theme.direction }>
          <div className={ classes.graphBox }>
            <HighchartsReact
              highcharts={ Highcharts }
              options={ {
                chart: {
                  type: 'spline',
                  scrollablePlotArea: {
                    scrollPositionX: 0,
                  },
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px',
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y',
                  },
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: 'Overall Income Growth Tracker (12-month moving average)',
                },
                xAxis: [{
                  tickInterval: 24,
                  categories: overallGraphData.dates,
                  labels: {
                    formatter() {
                      const chart = this;
                      return chart.value.split( ' ' )[1];
                    },
                    style: {
                      color: '#000000',
                      fontSize: '13px',
                    },
                  },
                }],
                yAxis: [{ // Primary yAxis
                  tickInterval: 1,
                  labels: {
                    format: '{value:.1f}%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                }],
                tooltip: {
                  headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true,
                  valueDecimals: 1,
                  valueSuffix: '%',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                legend: {
                  itemStyle: {
                    fontSize: '14px',
                  },
                },
                plotOptions: {
                  spline: {
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2,
                      },
                    },
                    marker: {
                      enabled: false,
                    },

                  },
                },
                series: overallGraphData.details,
              } }
            />
          </div>
        </TabPanel>
        <TabPanel value={ valueTab } index={ 1 } dir={ theme.direction }>
          {/* <img src={ Graph2 } alt="" /> */}
          <div className={ classes.graphBox }>
            <HighchartsReact
              highcharts={ Highcharts }
              options={ {
                chart: {
                  type: 'spline',
                  scrollablePlotArea: {
                    scrollPositionX: 0,
                  },
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px',
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y',
                  },
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: 'Income Growth Tracker by Education (12-month moving average)',
                },
                xAxis: [{
                  tickInterval: 24,
                  categories: educationGraphData.dates,
                  labels: {
                    formatter() {
                      const chart = this;
                      return chart.value.split( ' ' )[1];
                    },
                    style: {
                      color: '#000000',
                      fontSize: '13px',
                    },
                  },
                }],
                yAxis: [{ // Primary
                  tickInterval: 1,
                  labels: {
                    format: '{value:.1f}%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                }],
                tooltip: {
                  headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true,
                  valueDecimals: 1,
                  valueSuffix: '%',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                legend: {
                  itemStyle: {
                    fontSize: '14px',
                  },
                },
                plotOptions: {
                  spline: {
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2,
                      },
                    },
                    marker: {
                      enabled: false,
                    },

                  },
                },
                series: educationGraphData.details,
              } }
            />
          </div>
        </TabPanel>
        <TabPanel value={ valueTab } index={ 2 } dir={ theme.direction }>
          {/* <img src={ Graph3 } alt="" /> */}
          <div className={ classes.graphBox }>
            <HighchartsReact
              highcharts={ Highcharts }
              options={ {
                chart: {
                  type: 'spline',
                  scrollablePlotArea: {
                    scrollPositionX: 0,
                  },
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px',
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y',
                  },
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: 'Income Growth Tracker by Age (12-month moving average)',
                },
                xAxis: [{
                  tickInterval: 24,
                  categories: ageGraphData.dates,
                  labels: {
                    formatter() {
                      const chart = this;
                      return chart.value.split( ' ' )[1];
                    },
                    style: {
                      color: '#000000',
                      fontSize: '13px',
                    },
                  },
                }],
                yAxis: [{ // Primary
                  tickInterval: 1,
                  labels: {
                    format: '{value:.1f}%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                }],
                tooltip: {
                  headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true,
                  valueDecimals: 1,
                  valueSuffix: '%',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                legend: {
                  itemStyle: {
                    fontSize: '14px',
                  },
                },
                plotOptions: {
                  spline: {
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2,
                      },
                    },
                    marker: {
                      enabled: false,
                    },

                  },
                },
                series: ageGraphData.details,
              } }
            />
          </div>
        </TabPanel>
        <TabPanel value={ valueTab } index={ 3 } dir={ theme.direction }>
          {/* <img src={ Graph4 } alt="" /> */}
          <div className={ classes.graphBox }>
            <HighchartsReact
              highcharts={ Highcharts }
              options={ {
                chart: {
                  type: 'spline',
                  scrollablePlotArea: {
                    scrollPositionX: 0,
                  },
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px',
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y',
                  },
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: 'Income Growth Tracker by Industry (12-month moving average)',
                },
                xAxis: [{
                  tickInterval: 24,
                  categories: industryGraphData.dates,
                  labels: {
                    formatter() {
                      const chart = this;
                      return chart.value.split( ' ' )[1];
                    },
                    style: {
                      color: '#000000',
                      fontSize: '13px',
                    },
                  },
                }],
                yAxis: [{ // Primary
                  tickInterval: 1,
                  labels: {
                    format: '{value:.1f}%',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px',
                    },
                  },
                }],
                tooltip: {
                  headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true,
                  valueDecimals: 1,
                  valueSuffix: '%',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                legend: {
                  itemStyle: {
                    fontSize: '14px',
                  },
                },
                plotOptions: {
                  spline: {
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2,
                      },
                    },
                    marker: {
                      enabled: false,
                    },

                  },
                },
                series: industryGraphData.details,
              } }
            />
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

IncomeGrowthGraphs.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( IncomeGrowthGraphs );
