import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import styles from '../../worksheet-modules/styles';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Sight from '../../../components/sight';
import styles from '../styles';
import CompareFI from './financial-independence';
import CompareInvestments from './investments';
import CompareFamily from './family';
import CompareCareerPath from './career-path';
import CompareCareerAdvancement from './career-advancement';
import HigherEducation from './higher-education';
import { defaultValues } from '../../../calculations/default-module-values';
import CompareFurtherCareerAdvancement from './further-career-advancement';
import CompareAdditionalCareerAdvancement from './additional-career-advancement';
import CompareExistingAssetsLiabilities from './existing-assets-liabilities';
import CompareVacation from './vacation';
import CompareBuyingACar from './buying-a-car';
import CompareBuyingAHome from './buying-a-home';
import CompareREI from './rei';
import * as moduleServices from '../../../calculations/modules-services';
import Pageloader from '../../../components/ui/pageloader';
import PwiSave from '../../../components/pwi-save/pwi-save';
import * as locationData from '../../../calculations/locations';

const COMPARE_SCENARIOS = loader('../../../graphql/schema/worksheet/compare-scenarios.graphql');
const CompareScenarios = (props) => {
  const {
    classes, moduleSelected, getModuleData, setUpdatedScenario, moduleName
  } = props;
  const [sightData, setSightData] = React.useState({});
  const [openSightPopup, setOpenSightPopup] = React.useState(false);
  const [scenarioOne, setScenarioOne] = React.useState({});
  const [scenarioTwo, setScenarioTwo] = React.useState({});
  const [selectedScenarioLabelOne, setSelectedScenarioLabelOne] = React.useState('');
  const [selectedScenarioLabelTwo, setSelectedScenarioLabelTwo] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [savePopup, setSavePopup] = React.useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = React.useState({});
  const currModule = locationData.getLocationKey();
  // const [moduleParams, setModuleParams] = React.useState( {} );
  const openSightPage = (module, type, scenarioId, forType, scenarioType) => {
    const sightPassData = {
      module,
      type,
      scenarioId,
      forType,
      from: 'popup',
      scenarioType,
      slug: 'my_comparisons',
    };
    setSightData(sightPassData);
    setOpenSightPopup(true);
  };

  const swapColumns = () => {
    setScenarioTwo(scenarioOne);
    setScenarioOne(scenarioTwo);
  };

  const [compareScenarioMethod] = useMutation(COMPARE_SCENARIOS, {
    onCompleted(data) {
      if (data) {
        setOpenSightPopup(false);
        const compareScenarioData = data.getSelectedModulesScenariosInfo;
        for (let i = 0; i < compareScenarioData.length; i += 1) {
          let compareObj = {};
          setUpdatedScenario(compareScenarioData[i].data[moduleSelected]);
          compareObj = compareScenarioData[i].data[moduleSelected];
          compareObj.scenarioId = compareScenarioData[i].id;
          compareObj.name = compareScenarioData[i].name;
          switch (moduleSelected) {
            case 'module2': {
              if (compareScenarioData[i].data.module2 !== 'undefined' && compareScenarioData[i].data.module2 !== '' && compareScenarioData[i].data.module2 !== null) {
                compareObj.type_of_higher_education = defaultValues.typeOfEducation[compareScenarioData[i].data.module2.type_of_higher_education].label;
                compareObj.total_cash_available = moduleServices.investmentAnalysisTotalCashAvailable();
                compareObj.tuition_costs = moduleServices.investmentAnalysisTuitionCostsCal();
                compareObj.cash_excess_deficit = moduleServices.investmentAnalysisCashExcess();
                compareObj.liquid_assets_available = moduleServices.investmentAnalysisTotalLiquidAssetsAvailable();
                compareObj.outstanding_loan_obligations = moduleServices.investmentAnalysisOutstandingLoan();
                compareObj.totalprincipal_interest = moduleServices.investmentAnalysisTotalPrincipalInterest();
                compareObj.summary_loan_points = moduleServices.summaryLoanPoints();
                compareObj.federal_subsidized_student_loan = moduleServices.fedSubBeginningBalance();
                compareObj.federal_unsubsidized_student_loan = moduleServices.fedUnSubPrincipal();
                compareObj.private_student_loan = moduleServices.privatePrincipal();
                compareObj.cash_excess_deficit_expenses = moduleServices.investmentAnalysisCashExcessBeforeLiving();
                compareObj.total_living_expenses = moduleServices.investmentAnalysisTotalLiving();
                compareObj.cash_excess_deficit_after_expenses = moduleServices.investmentAnalysisCashExcessAfterLiving();
                compareObj.number_of_years = moduleServices.module2Data.years_diff;
                compareObj.annual_tution_fess = compareObj.tuition;
                compareObj.tution_fees_higher = moduleServices.totalTuitionAndFees();
                compareObj.room_board = moduleServices.totalRoomAndBoard();
                compareObj.oncampus_living_expenses = moduleServices.higherOnCampusLivingExpenses();
                compareObj.offcampus_living_expenses = moduleServices.higherOffCampusLivingExpenses();
                compareObj.total_student_loan_payments = moduleServices.totalStudentsLoanPayments();
                compareObj.other_loan_payments_on_other_debt = moduleServices.otherLoanPaymentsonOtherDebt();
                compareObj.car_expenses_higher = moduleServices.higherCarExpense();
                compareObj.real_estate_expenses_higher = moduleServices.higherRealEstateExpenses();
                compareObj.children_pet_expenses = moduleServices.higherChildrenAndPetExpenses();
                compareObj.total_expenses_higher = moduleServices.higherTotalExpenses();
                compareObj.scholarships_higher = moduleServices.investmentAnalysisScholships();
                compareObj.grants_higher = moduleServices.investmentAnalysisGrants();
                compareObj.other_financial_aid = moduleServices.investmentAnalysisFinancialAid();
                compareObj.income_earned_after_taxes = moduleServices.incomeEarnedAfterTaxesVal();
                compareObj.family_contribution_higher = moduleServices.investmentAnalysisFamilyContribution();
                compareObj.federal_subsidized_student_load = moduleServices.fedSubBeginningBalance();
                compareObj.federal_unsubsidized_student_load = moduleServices.fedUnSubPrincipal();
                compareObj.private_student_loan_higher = moduleServices.privatePrincipal();
                compareObj.origination_fees = moduleServices.summaryLoanPoints();
                compareObj.total_financing_sources = compareObj.scholarships_higher
                  + compareObj.grants_higher
                  + compareObj.other_financial_aid
                  + compareObj.income_earned_after_taxes
                  + compareObj.family_contribution_higher
                  + compareObj.federal_subsidized_student_load
                  + compareObj.federal_unsubsidized_student_load
                  + compareObj.private_student_loan_higher
                  + compareObj.origination_fees;
                compareObj.total_expenses_higher_second = moduleServices.higherTotalExpenses();
                compareObj.cash_leftover = compareObj.total_financing_sources - compareObj.total_expenses_higher_second;
              }
              getModuleData();
              break;
            }
            case 'module4': {
              compareObj.total_expected_income = moduleServices.totalPreTaxExpected();
              compareObj.second_total_expected_income = moduleServices.supplementarytotalPreTaxExpected();
              compareObj.total_monthly_living_expenses = moduleServices.totalMonthlyLivingExpenses();
              compareObj.student_loan_interest = moduleServices.studentLoanPrincipalInterest();
              compareObj.car_loan_interest = moduleServices.carLoanInterest();
              compareObj.mortgage_interest = moduleServices.mortgageInterest();
              compareObj.total_financing_expenses = moduleServices.totalFinancingExpenses();
              compareObj.income_earned = moduleServices.incomeEarned();
              compareObj.living_expenses_paid = moduleServices.livingExpensesPaid();
              compareObj.financing_expenses_paid = moduleServices.financingExpensesPaid();
              compareObj.income_after_expenses_paid = moduleServices.module4Data.incomeEarned - moduleServices.module4Data.livingExpensesPaid - moduleServices.module4Data.financingExpensesPaid;
              compareObj.beginning_bal_rothira = compareObj.roth_beginning_balance;
              compareObj.total_contribution_after_rothira = moduleServices.careerPathRothIRATotalContribution();
              compareObj.total_earnings_after_rothira = moduleServices.careerPathRothIRATotalEarnings();
              compareObj.ending_rothira_bal_inyear = moduleServices.careerPathRothIRAEndingBalance();
              compareObj.beginning_bal_sephira = moduleServices.careerPathSEPIRABeginningBalance();
              compareObj.total_contribution_after_sephira = moduleServices.careerPathSEPIRATotalContribution();
              compareObj.total_earnings_after_sephira = moduleServices.careerPathSEPIRATotalEarnings();
              compareObj.ending_sephira_bal_inyear = moduleServices.careerPathEndingSEPIRABalance();
              compareObj.beginning_bal_pension = moduleServices.careerPathPensionBeginningBalance();
              compareObj.total_personal_contribution_after_pension = moduleServices.careerPathPensionTotalPersonalContributions();
              compareObj.total_employer_contribution_after_pension = moduleServices.careerPathPensionTotalEmployerContributions();
              compareObj.total_earning_after_pension = moduleServices.careerPathPensionTotalEarnings();
              compareObj.ending_pension_bal_inyear = moduleServices.careerPathEndingPensionBalance();
              compareObj.beginning_bal_401k = moduleServices.beginningBalanceInYear();
              compareObj.total_personal_contribution_earnings_401k = moduleServices.totalPersonalContributionEarnings();
              compareObj.total_employer_contribution_earnings_401k = moduleServices.totalEmployersContributionsEarnings();
              compareObj.employer_contributions_earnings_not_vested_401k = moduleServices.employerContributionsEarningsNotVested();
              compareObj.ending_balace_inyear_401k = moduleServices.ending401Balance();
              compareObj.monthly_base_income = moduleServices.monthlyIncomeFromCareerPath();
              compareObj.other_monthly_income = moduleServices.otherMonthlyIncomeCareerPath();
              compareObj.total_monthly_income = moduleServices.totalMonthlyIncomeCareerPath();
              compareObj.total_taxes_paid = moduleServices.totalTaxesPaidCareerPath();
              compareObj.living_expenses_cpath = moduleServices.livingExpensesCareerPath();
              compareObj.higher_education_expenses = moduleServices.higherEducationExpensesCareerPath();
              compareObj.children_expenses = moduleServices.childrenExpensesCareerPath();
              compareObj.pet_expenses = moduleServices.petExpensesCareerPath();
              compareObj.car_expenses_cpath = moduleServices.carExpensesCareerPath();
              compareObj.real_estate_expenses = moduleServices.realEstateExpensesCareerPath();
              compareObj.interest_expenses = moduleServices.internetExpensesCareerPath();
              compareObj.total_monthly_expenses_cpath = moduleServices.totalMonthlyExpensesCareerPath();
              compareObj.monthly_net_income = moduleServices.monthlyNetIncomeCareerPath();
              getModuleData();

              break;
            }
            case 'module5': {
              compareObj.total_expected_income = moduleServices.advanceTotalPreTaxExpected();
              compareObj.second_total_expected_income = moduleServices.advanceSupplementaryTotalPreTaxExpected();
              compareObj.total_monthly_living_expenses = moduleServices.advanceTotalMonthlyLivingExpenses();
              compareObj.student_loan_interest = moduleServices.advanceStudentLoanPrincipalInterest();
              compareObj.car_loan_interest = moduleServices.advanceCarLoanInterest();
              compareObj.mortgage_interest = moduleServices.advanceMortgageInterest();
              compareObj.total_financing_expenses = moduleServices.advanceTotalFinancingExpenses();
              compareObj.income_earned = moduleServices.advanceIncomeEarned();
              compareObj.living_expenses_paid = moduleServices.advanceLivingExpensesPaid();
              compareObj.financing_expenses_paid = moduleServices.advanceFinancingExpensesPaid();
              compareObj.income_after_expenses_paid = moduleServices.module5Data.advanceIncomeEarned - moduleServices.module5Data.advanceLivingExpensesPaid - moduleServices.module5Data.advanceFinancingExpensesPaid;
              compareObj.beginning_bal_rothira = moduleServices.advCareerRothIRABeginningBalance();
              compareObj.total_contribution_after_rothira = moduleServices.advCareerRothIRATotalContribution();
              compareObj.total_earnings_after_rothira = moduleServices.advCareerRothIRATotalEarnings();
              compareObj.ending_rothira_bal_inyear = moduleServices.advCareerRothIRAEndingBalance();
              compareObj.beginning_bal_sephira = moduleServices.advCareerSEPIRABeginningBalance();
              compareObj.total_contribution_after_sephira = moduleServices.advCareerSEPIRATotalContribution();
              compareObj.total_earnings_after_sephira = moduleServices.advCareerSEPIRATotalEarnings();
              compareObj.ending_sephira_bal_inyear = moduleServices.advCareerEndingSEPIRABalance();
              compareObj.beginning_bal_pension = moduleServices.advCareerPensionBeginningBalance();
              compareObj.total_personal_contribution_after_pension = moduleServices.advCareerPensionTotalPersonalContributions();
              compareObj.total_employer_contribution_after_pension = moduleServices.advCareerPensionTotalEmployerContributions();
              compareObj.total_earning_after_pension = moduleServices.advCareerPensionTotalEarnings();
              compareObj.ending_pension_bal_inyear = moduleServices.advCareerEndingPensionBalance();
              compareObj.beginning_bal_401k = moduleServices.beginningBalanceInYearNext();
              compareObj.total_personal_contribution_earnings_401k = moduleServices.totalPersonalContributionsAndEarningsCareerAdv();
              compareObj.total_employer_contribution_earnings_401k = moduleServices.totalEmployerContributionAndEarnings();
              compareObj.employer_contributions_earnings_not_vested_401k = moduleServices.employerContributionsAndEarningsNotVested();
              compareObj.ending_balace_inyear_401k = moduleServices.endingBalanceInyearLast();
              compareObj.monthly_base_income = moduleServices.monthlyIncomeFromCareerAdv();
              compareObj.other_monthly_income = moduleServices.otherMonthlyIncomeCareerAdv();
              compareObj.total_monthly_income = moduleServices.totalMonthlyIncomeCareerAdv();
              compareObj.total_taxes_paid = moduleServices.totalTaxesPaidCareerAdv();
              compareObj.living_expenses_cpath = moduleServices.livingExpensesCareerAdv();
              compareObj.higher_education_expenses = moduleServices.higherEducationExpensesCareerAdv();
              compareObj.children_expenses = moduleServices.childrenExpensesCareerAdv();
              compareObj.pet_expenses = moduleServices.petExpensesCareerAdv();
              compareObj.car_expenses_cpath = moduleServices.carExpensesCareerAdv();
              compareObj.real_estate_expenses = moduleServices.realEstateExpensesCareerAdv();
              compareObj.interest_expenses = moduleServices.interestExpensesCareerAdv();
              compareObj.total_monthly_expenses_cpath = moduleServices.totalMonthlyExpensesCareerAdv();
              compareObj.monthly_net_income = moduleServices.monthlyNetIncomeCareerAdv();
              getModuleData();

              break;
            }
            case 'module6':  {
              if (compareScenarioData[i].data.module6 !== 'undefined' && compareScenarioData[i].data.module6 !== '' && compareScenarioData[i].data.module6 !== null) {
                compareObj.type_of_higher_education = defaultValues.typeOfEducation[compareScenarioData[i].data.module6.type_of_higher_education].label;
                compareObj.total_cash_available = moduleServices.moreHigherinvestmentAnalysisTotalCashAvailable();
                compareObj.tuition_costs = moduleServices.moreHigherinvestmentAnalysisTuitionCosts();
                compareObj.cash_excess_deficit = moduleServices.moreHigherinvestmentAnalysisCashExcess();
                compareObj.liquid_assets_available = moduleServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
                compareObj.outstanding_loan_obligations = moduleServices.moreHigherinvestmentAnalysisOutstandingLoan();
                compareObj.totalprincipal_interest = moduleServices.moreHigherStudentLoanTotalPrincipalInterest();
                compareObj.summary_loan_points = moduleServices.moreSummaryLoanPoints();
                compareObj.federal_subsidized_student_loan = moduleServices.moreFedSubBeginningBalance();
                compareObj.federal_unsubsidized_student_loan = moduleServices.moreFedUnSubPrincipal();
                compareObj.private_student_loan = moduleServices.morePrivatePrincipal();
                compareObj.cash_excess_deficit_expenses = moduleServices.livingExpensesCashExcess();
                compareObj.total_living_expenses = moduleServices.moreHigherlivingExpensesTotal();
                compareObj.cash_excess_deficit_after_expenses = moduleServices.moreHigherCashExcess();
                compareObj.number_of_years = moduleServices.module6Data.years_diff;
                compareObj.annual_tution_fess = compareObj.tuition;
                compareObj.tution_fees_higher = moduleServices.moreTotalTuitionAndFees();
                compareObj.room_board = moduleServices.moreTotalRoomAndBoard();
                compareObj.oncampus_living_expenses = moduleServices.moreHigherOnCampusLivingExpenses();
                compareObj.offcampus_living_expenses = moduleServices.moreHigherOffCampusLivingExpenses();
                compareObj.total_student_loan_payments = moduleServices.moreHigherTotalStudentsLoanPayments();
                compareObj.other_loan_payments_on_other_debt = moduleServices.moreHigherOtherLoanPaymentsonOtherDebt();
                compareObj.car_expenses_higher = moduleServices.moreHigherCarExpense();
                compareObj.real_estate_expenses_higher = moduleServices.moreHigherRealEstateExpenses();
                compareObj.children_pet_expenses = moduleServices.moreHigherChildrenAndPetExpenses();
                compareObj.total_expenses_higher = moduleServices.moreHigherTotalExpenses();
                compareObj.scholarships_higher = moduleServices.moreHigherinvestmentAnalysisScholships();
                compareObj.grants_higher = moduleServices.moreHigherinvestmentAnalysisGrants();
                compareObj.other_financial_aid = moduleServices.moreHigherinvestmentAnalysisFinancialAid();
                compareObj.income_earned_after_taxes = moduleServices.moreHigherinvestmentAnalysisIncomeEarned();
                compareObj.family_contribution_higher = moduleServices.moreHigherinvestmentAnalysisFamilyContribution();
                compareObj.federal_subsidized_student_load = moduleServices.moreFedSubBeginningBalance();
                compareObj.federal_unsubsidized_student_load = moduleServices.moreFedUnSubPrincipal();
                compareObj.private_student_loan_higher = moduleServices.morePrivatePrincipal();
                compareObj.origination_fees = moduleServices.moreSummaryLoanPoints();
                compareObj.total_financing_sources = compareObj.scholarships_higher
                  + compareObj.grants_higher
                  + compareObj.other_financial_aid
                  + compareObj.income_earned_after_taxes
                  + compareObj.family_contribution_higher
                  + compareObj.federal_subsidized_student_load
                  + compareObj.federal_unsubsidized_student_load
                  + compareObj.private_student_loan_higher
                  + compareObj.origination_fees;
                compareObj.total_expenses_higher_second = moduleServices.moreHigherTotalExpenses();
                compareObj.cash_leftover = compareObj.total_financing_sources - compareObj.total_expenses_higher_second;
                getModuleData();
              }
              break;
            }
            case 'module8': {
              compareObj.total_expected_income = moduleServices.jobtotalPreTaxExpected();
              compareObj.second_total_expected_income = moduleServices.moreSupplementarytotalPreTaxExpected();
              compareObj.total_monthly_living_expenses = moduleServices.livingTotalMonthlyLivingExpenses();
              compareObj.student_loan_interest = moduleServices.moreHigherstudentLoanPrincipalInterest();
              compareObj.car_loan_interest = moduleServices.moreHighercarLoanInterest();
              compareObj.mortgage_interest = moduleServices.moreHighermortgageInterest();
              compareObj.total_financing_expenses = moduleServices.moreHighertotalFinancingExpenses();
              compareObj.income_earned = moduleServices.moreHigherincomeEarned();
              compareObj.living_expenses_paid = moduleServices.moreHigherlivingExpensesPaid();
              compareObj.financing_expenses_paid = moduleServices.moreHigherfinancingExpensesPaid();
              compareObj.income_after_expenses_paid = moduleServices.module8Data.moreHigherincomeEarned - moduleServices.module8Data.moreHigherlivingExpensesPaid - moduleServices.module8Data.moreHigherfinancingExpensesPaid;

              compareObj.beginning_bal_rothira = moduleServices.addCareerRothIRABeginningBalance();
              compareObj.total_contribution_after_rothira = moduleServices.addCareerRothIRATotalContribution();
              compareObj.total_earnings_after_rothira = moduleServices.addCareerRothIRATotalEarnings();
              compareObj.ending_rothira_bal_inyear = moduleServices.addCareerRothIRAEndingBalance();

              compareObj.beginning_bal_sephira = moduleServices.addCareerSEPIRABeginningBalance();
              compareObj.total_contribution_after_sephira = moduleServices.addCareerSEPIRATotalContribution();
              compareObj.total_earnings_after_sephira = moduleServices.addCareerSEPIRATotalEarnings();
              compareObj.ending_sephira_bal_inyear = moduleServices.addCareerEndingSEPIRABalance();

              compareObj.beginning_bal_pension = moduleServices.addCareerPensionBeginningBalance();
              compareObj.total_personal_contribution_after_pension = moduleServices.addCareerPensionTotalPersonalContributions();
              compareObj.total_employer_contribution_after_pension = moduleServices.addCareerPensionTotalEmployerContributions();
              compareObj.total_earning_after_pension = moduleServices.addCareerPensionTotalEarnings();
              compareObj.ending_pension_bal_inyear = moduleServices.addCareerEndingPensionBalance();
              compareObj.beginning_bal_401k = moduleServices.beginningBalanceYearThird();
              compareObj.total_personal_contribution_earnings_401k = moduleServices.totalPersonalContributionsAndEarnings();
              compareObj.total_employer_contribution_earnings_401k = moduleServices.totalEmployerContributionsAndEarnings();
              compareObj.employer_contributions_earnings_not_vested_401k = moduleServices.employerContributionsAndEarningsNotVestedAdd();
              compareObj.ending_balace_inyear_401k = moduleServices.endingBalanceInYearAddCareerAdv();
              compareObj.monthly_base_income = moduleServices.monthlyIncomeFromAddCareerAdv();
              compareObj.other_monthly_income = moduleServices.otherMonthlyIncomeAddCareerAdv();
              compareObj.total_monthly_income = moduleServices.totalMonthlyIncomeAddCareerAdv();
              compareObj.total_taxes_paid = moduleServices.totalTaxesPaidAddCareerAdv();
              compareObj.living_expenses_cpath = moduleServices.livingExpensesAddCareerAdv();
              compareObj.higher_education_expenses = moduleServices.higherEducationExpensesAddCareerAdv();
              compareObj.children_expenses = moduleServices.childrenExpensesAddCareerAdv();
              compareObj.pet_expenses = moduleServices.petExpensesAddCareerAdv();
              compareObj.car_expenses_cpath = moduleServices.carExpensesAddCareerAdv();
              compareObj.real_estate_expenses = moduleServices.realEstateExpensesAddCareerAdv();
              compareObj.interest_expenses = moduleServices.internetExpensesAddCareerPathAdv();
              compareObj.total_monthly_expenses_cpath = moduleServices.totalMonthlyExpensesAddCareerAdv();
              compareObj.monthly_net_income = moduleServices.monthlyNetIncomeAddCareerAdv();
              getModuleData();
              break;
            }
            case 'module9': {
              compareObj.begining_balance = moduleServices.shortTermSummaryBeginningBalance();
              compareObj.short_term_summary_contributions = moduleServices.shortTermSummaryContributions();
              compareObj.short_term_summary_excess_cash = moduleServices.shortTermSummaryExcessCash();
              // compareObj['short_term_summary_total_cash'] = moduleServices.shortTermSummaryTotalCash();
              compareObj.short_term_summary_earnings = moduleServices.shortTermSummaryEarnings();
              // compareObj['short_term_summary_total_investments'] = moduleServices.shortTermSummaryTotalInvestments();
              compareObj.short_term_summary_cash_with_drawn = moduleServices.shortTermSummaryCashWithDrawn();
              compareObj.short_term_summary_cash_deposited = moduleServices.shortTermSummaryCashDeposited();
              compareObj.short_term_summary_ending_balance = moduleServices.shortTermSummaryEndingBalance();
              compareObj.short_term_investments_period = moduleServices.shortTermInvestmentsPeriodYears();
              compareObj.short_term_investments_before_transfers = moduleServices.shortTermInvestmentsBeforeTransfers();

              compareObj.second_begining_balance = moduleServices.longTermSummaryBeginningBalance();
              compareObj.long_term_summary_contributions = moduleServices.longTermSummaryContributions();
              compareObj.long_term_summary_excess_cash = moduleServices.longTermSummaryExcessCash();
              // compareObj['long_term_summary_total_cash'] = moduleServices.longTermSummaryTotalCash();
              compareObj.long_term_summary_earnings = moduleServices.longTermSummaryEarnings();
              // compareObj['long_term_summary_total_investments'] = moduleServices.longTermSummaryTotalInvestments();
              compareObj.long_term_summary_cash_with_drawn = moduleServices.longTermSummaryCashWithDrawn();
              compareObj.long_term_summary_ending_balance = Math.round(moduleServices.longTermSummaryEndingBalance(), 0, 2);
              compareObj.long_term_investments_period = moduleServices.longTermInvestmentsPeriodYears();
              compareObj.long_term_investments_balance_before_transfers = moduleServices.longTermInvestmentsBeforeTransfers();
              getModuleData();
              break;
            }
            case 'module10': {
              compareObj.marrital_spouse_income = moduleServices.marritalSpouseIncome();
              compareObj.marrital_other_income = moduleServices.marritalOtherIncome();
              compareObj.marrital_total_income = moduleServices.marritalTotalIncome();
              compareObj.marrital_taxes_paid = moduleServices.marritalTaxesPaid();
              compareObj.marrital_taxes_rate = moduleServices.marritalTaxesRate();
              compareObj.marrital_expenses_paid = moduleServices.marritalExpensesPaid();
              compareObj.marrital_financing_expenses_paid = moduleServices.marritalFinancingExpensesPaid();
              compareObj.marrital_income_after_expenses = moduleServices.marritalIncomeAfter();
              getModuleData();
              break;
            }
            case 'module11': {
              compareObj.vacation_total_amount = moduleServices.vacationTotalAmountSpent();
              getModuleData();
              break;
            }
            case 'module12': {
              compareObj.leasing_car_downpayment_calcs =
                moduleServices.leasingCarDownPaymentSummary();
              compareObj.leasing_car_total_payments_calcs =
                moduleServices.leasingCarTotalPaymentSummary();
              compareObj.leasing_car_total_expenses_calcs =
                moduleServices.leasingCarTotalExpensesSummary();
              compareObj.leasing_car_total_cost_calcs =
                moduleServices.leasingCarTotalCostsSummary();
              compareObj.leasing_second_car_downpayment_calcs =
                moduleServices.leasingSecondCarDownPaymentSummary();
              compareObj.leasing_second_car_total_payments_calcs =
                moduleServices.leasingSecondCarTotalPaymentSummary();
              compareObj.leasing_second_car_total_expenses_calcs =
                moduleServices.leasingSecondCarTotalExpensesSummary();
              compareObj.leasing_second_car_total_cost_calcs =
                moduleServices.leasingSecondCarTotalCostsSummary();

              compareObj.retail_value = moduleServices.askingPrice();
              compareObj.investment_analysis_discount =
                moduleServices.discountCalcs();
              compareObj.investment_analysis_rebate =
                moduleServices.investmentAnalysisRebate();
              compareObj.trade_in_value = moduleServices.tradeInValue();
              compareObj.salestax_calcs = moduleServices.salesTaxCalcs();
              compareObj.total_purchase_price =
                moduleServices.investmentAnalysisTotalPurchasePrice();
              compareObj.car_down_payment = moduleServices.carDownPayment();
              compareObj.car_loan_points = moduleServices.carLoanPoints();
              compareObj.cashneeded_at_purchase =
                moduleServices.cashNeededAtPurchase();
              compareObj.total_cash_available =
                moduleServices.totalCashAvailable();
              compareObj.total_liquid_assets = moduleServices.totalLiquid();

              compareObj.loan = moduleServices.outstandingCarLoan();
              compareObj.investment_analysis_total_principal =
                moduleServices.investmentAnalysisTotalPrincipal();

              compareObj.second_retail_value = moduleServices.moreAskingPrice();
              compareObj.second_discount_calcs =
                moduleServices.moreDiscountCalcs();
              compareObj.second_investment_analysis_discount =
                moduleServices.moreInvestmentAnalysisDiscount();
              compareObj.second_investment_analysis_rebate =
                moduleServices.moreInvestmentAnalysisRebate();
              compareObj.second_salestax_calcs =
                moduleServices.moreInvestmentAnalysisSalesTax();
              compareObj.second_total_purchase_price =
                moduleServices.moreInvestmentAnalysisTotalPurchasePrice();
              compareObj.second_tradeInValue =
                moduleServices.moreTradeInValue();
              compareObj.second_car_down_payment =
                moduleServices.moreCarDownPayment();
              compareObj.second_car_loan_points =
                moduleServices.moreCarLoanPoints();
              compareObj.second_cashneeded_at_purchase =
                moduleServices.moreCashNeededAtPurchase();
              compareObj.second_total_cash_available =
                moduleServices.moreTotalCashAvailable();
              compareObj.second_total_liquid_assets =
                moduleServices.moreTotalLiquid();
              compareObj.second_loan = moduleServices.moreOutstandingCarLoan();
              compareObj.second_investment_analysis_total_principal =
                moduleServices.moreInvestmentAnalysisTotalPrincipal();

              getModuleData();
              break;
            }
            case 'module13': {
              compareObj.downpayment_calcs = moduleServices.downPaymentCalcs();
              compareObj.closing_costs = moduleServices.closingCosts();
              compareObj.closing_costs = moduleServices.closingCosts();
              compareObj.buyingahome_annual_property_taxes =
                moduleServices.propertyTaxesCalcs();
              compareObj.buyingahome_private_mortgage_insurance =
                moduleServices.privateMortgageInsuranceCalcs();
              compareObj.buyingahome_annual_homeowners_insurance =
                moduleServices.homeownerInsuranceCalcs();
              compareObj.buyingahome_annual_maintenance =
                moduleServices.yearlyMaintenanceCalcs();
              compareObj.buyingahome_annual_associal_fees =
                moduleServices.yearlyAssociationCalcs();
              compareObj.buyingahome_monthly_utilities =
                moduleServices.homeMonthlyUtilitiesCalcs();
              compareObj.buyingahome_annual_appreciation =
                moduleServices.appreciation_in_percentage();
              compareObj.cashneeded_at_purchase =
                moduleServices.cashNeedsPurchase();
              compareObj.total_cash_available =
                moduleServices.moreHigherTotalCashAvailable();
              compareObj.total_liquid_assets =
                moduleServices.moreHighermoreTotalLiquid();
              compareObj.mortgage = moduleServices.homeOutstandingMortgage();
              compareObj.total_principal_interest =
                moduleServices.biyingaHomeTotalPrncipalInterest();
              compareObj.debt_to_income_ratio =
                moduleServices.debtToIncomeRatio();
              getModuleData();

              break;
            }
            case 'module14': {
              compareObj.years_invested = (compareObj.year_of_sale > 0 && compareObj.year_of_purchase > 0) ? (compareObj.year_of_sale - compareObj.year_of_purchase) + 1 : 0;
              compareObj.purchase_price = moduleServices.reiPurchasePrice();
              compareObj.downpayment_calcs = moduleServices.reiDownPayment();
              compareObj.closing_costs = moduleServices.reiClosingCosts();
              compareObj.external_and_internal_fit_out_costs = moduleServices.reiFitOutCosts();
              compareObj.cashneeded_at_purchase = moduleServices.realEstatecashNeededAtPurchase();
              compareObj.total_cash_available = moduleServices.realEstatetotalCashAvailable();
              compareObj.total_liquid_assets = moduleServices.realEstatetotalLiquid();
              compareObj.mortgage = moduleServices.reiOutstandingMortgage();
              compareObj.rei_total_principal = moduleServices.reiTotalPrincipal();
              compareObj.rei_debt_income_ratio = moduleServices.reiDebt();

              compareObj.total_rental_revenue = moduleServices.investmentSummaryTotalRental();
              compareObj.total_property_expenses = moduleServices.investmentSummaryTotalProperty();
              compareObj.net_rental_income = moduleServices.investmentSummaryNetRental();
              compareObj.salesprice_end_year = moduleServices.investmentSummarySalePrice();
              compareObj.return_investment = moduleServices.ROICal();
              compareObj.internal_rate = moduleServices.IRRCal();

              compareObj.more_rei_selling_cost = moduleServices.moreREISellingCost();
              compareObj.more_rei_net_selling_price = moduleServices.moreREINetSellingPrice();
              compareObj.more_rei_closing_cost = moduleServices.moreREIClosingCost();
              compareObj.more_rei_gain = moduleServices.moreREIGain();
              compareObj.rei_tax_capital = moduleServices.moreREITaxCapital();
              compareObj.rei_tax_accumulated = moduleServices.moreREITaxAccumulated();
              compareObj.more_rei_net_gain = moduleServices.moreREINetGain();

              compareObj.mortgage = moduleServices.realEstateMortage();
              compareObj.rate_of_mortgage_prcentage = compareObj.rate_of_mortgage_in_percentage;
              compareObj.mortgage_total_principle_and_interest = moduleServices.totalPrincipalInterest();

              compareObj.total_cash_invested = moduleServices.totalCashInvested();
              compareObj.total_cash_returned = moduleServices.totalCashReturned();
              compareObj.return_on_investment_before_taxes = moduleServices.ROICal();
              compareObj.multiple_on_money_before_taxes = moduleServices.MoMCal();
              compareObj.cash_on_cash_return_before_taxes = moduleServices.cashOnCashReturn();
              compareObj.internal_ate_of_return_before_taxes = moduleServices.IRRCal();

              compareObj.additional_rental_income = moduleServices.adjustedRentalIncome();
              compareObj.piti = moduleServices.reiPitiValue();
              compareObj.total_monthly_expenses = moduleServices.reiTotalMonthlyExpenses();
              compareObj.monthly_net_cash_flow = moduleServices.monthlyNetIncomeExpenses();
              getModuleData();

              break;
            }
            case 'module15':
              {
                if (compareScenarioData[i].data.module15 !== 'undefined' && compareScenarioData[i].data.module15 !== '' && compareScenarioData[i].data.module15 !== null) {
                  compareObj.type_of_higher_education = defaultValues.typeOfEducation[compareScenarioData[i].data.module15.type_of_higher_education].label;
                  compareObj.total_cash_available = moduleServices.newHigherinvestmentAnalysisTotalCashAvailable();
                  compareObj.tuition_costs = moduleServices.newHigherinvestmentAnalysisTuitionCosts();
                  compareObj.cash_excess_deficit = moduleServices.newHigherinvestmentAnalysisCashExcess();
                  compareObj.liquid_assets_available = moduleServices.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
                  compareObj.outstanding_loan_obligations = moduleServices.newHigherinvestmentAnalysisOutstandingLoan();
                  compareObj.totalprincipal_interest = moduleServices.newHigherStudentLoanTotalPrincipalInterest();
                  compareObj.summary_loan_points = moduleServices.newSummaryLoanPoints();
                  compareObj.federal_subsidized_student_loan = moduleServices.newFedSubBeginningBalance();
                  compareObj.federal_unsubsidized_student_loan = moduleServices.newFedUnSubPrincipal();
                  compareObj.private_student_loan = moduleServices.newPrivatePrincipal();
                  compareObj.cash_excess_deficit_expenses = moduleServices.newlivingExpensesCashExcess();
                  compareObj.total_living_expenses = moduleServices.newHigherlivingExpensesTotal();
                  compareObj.cash_excess_deficit_after_expenses = moduleServices.newHigherCashExcess();
                  compareObj.number_of_years = moduleServices.module15Data.years_diff;
                  compareObj.annual_tution_fess = compareObj.tuition;
                  compareObj.tution_fees_higher = moduleServices.newTotalTuitionAndFees();
                  compareObj.room_board = moduleServices.newTotalRoomAndBoard();
                  compareObj.oncampus_living_expenses = moduleServices.newOnCampusLivingExpenses();
                  compareObj.offcampus_living_expenses = moduleServices.newOffCampusLivingExpenses();
                  compareObj.total_student_loan_payments = moduleServices.newHigherTotalStudentsLoanPayments();
                  compareObj.other_loan_payments_on_other_debt = moduleServices.newHigherOtherLoanPaymentsonOtherDebt();
                  compareObj.car_expenses_higher = moduleServices.newHigherCarExpense();
                  compareObj.real_estate_expenses_higher = moduleServices.newHigherRealEstateExpenses();
                  compareObj.children_pet_expenses = moduleServices.newHigherChildrenAndPetExpenses();
                  compareObj.total_expenses_higher = moduleServices.newHigherTotalExpenses();
                  compareObj.scholarships_higher = moduleServices.newHigherinvestmentAnalysisScholships();
                  compareObj.grants_higher = moduleServices.newHigherinvestmentAnalysisGrants();
                  compareObj.other_financial_aid = moduleServices.newHigherinvestmentAnalysisFinancialAid();
                  compareObj.income_earned_after_taxes = moduleServices.newHigherinvestmentAnalysisIncomeEarned();
                  compareObj.family_contribution_higher = moduleServices.newHigherinvestmentAnalysisFamilyContribution();
                  compareObj.federal_subsidized_student_load = moduleServices.newFedSubBeginningBalance();
                  compareObj.federal_unsubsidized_student_load = moduleServices.newFedUnSubPrincipal();
                  compareObj.private_student_loan_higher = moduleServices.newPrivatePrincipal();
                  compareObj.origination_fees = moduleServices.newSummaryLoanPoints();
                  compareObj.total_financing_sources = compareObj.scholarships_higher
                    + compareObj.grants_higher
                    + compareObj.other_financial_aid
                    + compareObj.income_earned_after_taxes
                    + compareObj.family_contribution_higher
                    + compareObj.federal_subsidized_student_load
                    + compareObj.federal_unsubsidized_student_load
                    + compareObj.private_student_loan_higher
                    + compareObj.origination_fees;
                  compareObj.total_expenses_higher_second = moduleServices.newHigherTotalExpenses();
                  compareObj.cash_leftover = compareObj.total_financing_sources - compareObj.total_expenses_higher_second;
                }
                getModuleData();
                break;
              }
            case 'module17': {
              compareObj.total_expected_income = moduleServices.jobtotalPreTaxExpected();
              compareObj.second_total_expected_income = moduleServices.furthermoreSupplementarytotalPreTaxExpected();
              compareObj.total_monthly_living_expenses = moduleServices.furtherlivingTotalMonthlyLivingExpenses();
              compareObj.student_loan_interest = moduleServices.furthermoreHigherstudentLoanPrincipalInterest();
              compareObj.car_loan_interest = moduleServices.furthermoreHighercarLoanInterest();
              compareObj.mortgage_interest = moduleServices.furthermoreHighermortgageInterest();
              compareObj.total_financing_expenses = moduleServices.furthermoreHighertotalFinancingExpenses();
              compareObj.income_earned = moduleServices.furthermoreHigherincomeEarned();
              compareObj.living_expenses_paid = moduleServices.furthermoreHigherlivingExpensesPaid();
              compareObj.financing_expenses_paid = moduleServices.furthermoreHigherfinancingExpensesPaid();
              compareObj.income_after_expenses_paid = moduleServices.module17Data.furthermoreHigherincomeEarned - moduleServices.module17Data.furthermoreHigherlivingExpensesPaid - moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid;

              compareObj.beginning_bal_rothira = moduleServices.furtherCareerRothIRABeginningBalance();
              compareObj.total_contribution_after_rothira = moduleServices.furtherCareerRothIRATotalContribution();
              compareObj.total_earnings_after_rothira = moduleServices.furtherCareerRothIRATotalEarnings();
              compareObj.ending_rothira_bal_inyear = moduleServices.furtherCareerRothIRAEndingBalance();

              compareObj.beginning_bal_sephira = moduleServices.furtherCareerSEPIRABeginningBalance();
              compareObj.total_contribution_after_sephira = moduleServices.furtherCareerSEPIRATotalContribution();
              compareObj.total_earnings_after_sephira = moduleServices.furtherCareerSEPIRATotalEarnings();
              compareObj.ending_sephira_bal_inyear = moduleServices.furtherCareerEndingSEPIRABalance();

              compareObj.beginning_bal_pension = moduleServices.furtherCareerPensionBeginningBalance();
              compareObj.total_personal_contribution_after_pension = moduleServices.furtherCareerPensionTotalPersonalContributions();
              compareObj.total_employer_contribution_after_pension = moduleServices.furtherCareerPensionTotalEmployerContributions();
              compareObj.total_earning_after_pension = moduleServices.furtherCareerPensionTotalEarnings();
              compareObj.ending_pension_bal_inyear = moduleServices.furtherCareerEndingPensionBalance();

              compareObj.beginning_bal_401k = moduleServices.beginningBalanceInYearFourth();
              compareObj.total_personal_contribution_earnings_401k = moduleServices.further401kSummaryTotalPersonalContributionsAndEarnings();
              compareObj.total_employer_contribution_earnings_401k = moduleServices.further401kSummaryEmployerContribution();
              compareObj.employer_contributions_earnings_not_vested_401k = moduleServices.employerContributionsAndEarningsNotVestedFur();
              compareObj.ending_balace_inyear_401k = moduleServices.furtherCareer401kEndingBalance();
              compareObj.monthly_base_income = moduleServices.monthlyIncomeFromFurCareerAdv();
              compareObj.other_monthly_income = moduleServices.otherMonthlyIncomeFurCareerAdv();
              compareObj.total_monthly_income = moduleServices.totalMonthlyIncomeFurCareerAdv();
              compareObj.total_taxes_paid = moduleServices.totalTaxesPaidFurCareerAdv();
              compareObj.living_expenses_cpath = moduleServices.livingExpensesFurCareerAdv();
              compareObj.higher_education_expenses = moduleServices.higherEducationExpensesFurCareerAdv();
              compareObj.children_expenses = moduleServices.childrenExpensesFurCareerAdv();
              compareObj.pet_expenses = moduleServices.petExpensesFurCareerAdv();
              compareObj.car_expenses_cpath = moduleServices.carExpensesFurCareerAdv();
              compareObj.real_estate_expenses = moduleServices.realEstateExpensesFurCareerAdv();
              compareObj.interest_expenses = moduleServices.internetExpensesFurCareerAdv();
              compareObj.total_monthly_expenses_cpath = moduleServices.totalMonthlyExpensesFurCareerAdv();
              compareObj.monthly_net_income = moduleServices.monthlyNetIncomeFurCareerAdv();

              getModuleData();
              break;
            }
            case 'module18': {
              compareObj.fed_sub_monthly_payment = moduleServices.existingStudentLoansFedSubMonthlyPayment();
              compareObj.fed_sub_annual_payment = moduleServices.existingStudentLoansFedSubAnnualPayment();
              compareObj.fed_sub_total_interest = moduleServices.existingStudentLoansFedSubTotalInterest();
              compareObj.fed_sub_total_principle_interest = moduleServices.existingStudentLoansFedSubTotalPrincipalInterest();
              compareObj.fed_unsub_total_accrued_interest = moduleServices.existingStudentLoansFedUnSubTotalAccruedInterest();
              compareObj.fed_unsub_beginning_balance = moduleServices.existingStudentLoansFedUnSubBeginningBalance();
              compareObj.fed_unsub_monthly_payment = moduleServices.existingStudentLoansFedUnSubMonthlyPayment();
              compareObj.fed_unsub_annual_payment = moduleServices.existingStudentLoansFedUnSubAnnualPayment();
              compareObj.fed_unsub_total_interest = moduleServices.existingStudentLoansFedUnSubTotalInterest();
              compareObj.fed_unsub_total_principle_interest = moduleServices.existingStudentLoansFedUnSubTotalPrincipalInterest();

              compareObj.private_total_accrued_interest = moduleServices.existingStudentLoansPrivateTotalAccruedInterest();
              compareObj.private_beginning_balance = moduleServices.existingStudentLoansPrivateBeginningBalance();
              compareObj.private_monthly_payment = moduleServices.existingStudentLoansPrivateMonthlyPayment();
              compareObj.private_annual_payment = moduleServices.existingStudentLoansPrivateAnnualPayment();
              compareObj.private_total_interest = moduleServices.existingStudentLoansPrivateTotalInterest();
              compareObj.private_total_principle_interest = moduleServices.existingStudentLoansPrivateTotalPrincipalInterest();

              compareObj.existing_student_loans_summary_total_interest = moduleServices.existingStudentLoansSummaryTotalInterest();
              compareObj.existing_student_loans_summary_total_principal_interest = moduleServices.existingStudentLoansSummaryTotalPrincipalInterest();
              compareObj.debt_service_ratio = moduleServices.existingStudentLoansSummaryIncomeRatio();

              compareObj.creditcard_monthly_payment = moduleServices.existingCreditCardMonthlyPayment();
              compareObj.creditcard_total_principal_interest = moduleServices.existingCreditCardTotalPrincipalInterest();

              compareObj.existing_car_monthly_payment = moduleServices.existingCarLoansMonthlyPayment();
              compareObj.existing_car_principal_interest = moduleServices.existingCarLoansTotalPrincipalInterest();
              compareObj.monthly_car_expenses = moduleServices.existingCarLoansTotalMonthlyExpenses();
              compareObj.monthly_annual_expenses = moduleServices.existingCarLoansTotalAnnualExpenses();
              compareObj.existing_home_monthly_payment = moduleServices.existingHomeMortgageMonthlyPayment();
              compareObj.existing_home_total_principal_interest = moduleServices.existingHomeMortgageTotalPrincipalInterest();
              compareObj.existing_home_debt_ratio = moduleServices.existingHomedebtIncomeRatio();

              compareObj.existing_home_selling_price = moduleServices.existingHomeSaleSellingPrice();
              compareObj.existing_home_selling_costs = moduleServices.sellingCosts();
              compareObj.existing_home_net_selling_price = moduleServices.netSellingPrice();
              compareObj.existing_home_basis = moduleServices.existingLoansBasis();
              compareObj.existing_home_gain_on_sale = moduleServices.gainOnSale();
              compareObj.existing_home_taxble_gain = moduleServices.taxableGainUp();
              compareObj.existing_home_taxon_taxble_gain = moduleServices.taxOnGain();
              compareObj.existing_home_net_gain = moduleServices.netGainOnSale();
              getModuleData();
              break;
            }
            default: {
              break;
            }
          }
          if (sightData.scenarioType === 'scenarioA') {
            compareObj.label = 'Scenario A';
            setSelectedScenarioLabelOne(compareScenarioData[i].name);
            setScenarioOne(compareObj);
          } else if (sightData.scenarioType === 'scenarioB') {
            compareObj.label = 'Scenario B';
            setSelectedScenarioLabelTwo(compareScenarioData[i].name);
            setScenarioTwo(compareObj);
          } else if (sightData.scenarioType === 'compareScenarios') {
            if (i === 0) {
              compareObj.label = 'Scenario A';
              setSelectedScenarioLabelOne(compareScenarioData[i].name);
              setScenarioOne(compareObj);
            } else if (i === 1) {
              compareObj.label = 'Scenario B';
              setSelectedScenarioLabelTwo(compareScenarioData[i].name);
              setScenarioTwo(compareObj);
            }
          } else {
            let scenario1Data = compareScenarioData[0].data[moduleSelected];
            let scenario2Data = compareScenarioData[1].data[moduleSelected] !== undefined ? compareScenarioData[1].data[moduleSelected] :[];
            scenario1Data.label = 'Scenario A';
            scenario2Data.label = 'Scenario B';
            setSelectedScenarioLabelOne(compareScenarioData[0].name);
            setSelectedScenarioLabelTwo(compareScenarioData[1].name !== undefined ? compareScenarioData[1].name : []);
            setScenarioOne(scenario1Data);
            setScenarioTwo(scenario2Data);
          }
        }
      }
      setLoading(false);
    },
  });

  const compareCallback = async (data) => {
    setLoading(true);
    await compareScenarioMethod({
      variables: {
        relation_year: data.relation_year,
        selected_module: moduleSelected,
        scenario_ids: data.slug,
      },
    });
  };

  const handleSave = () => {
    let presentModule = locationData.getLocationKey();
    console.log('sv',scenarioOne.start_year ? scenarioOne.start_year : scenarioOne.year_of_purchase);
    if (!selectedScenarioLabelTwo || !selectedScenarioLabelOne) {
      alert('Please Select Scenario To Save');
    } else {
      const objectParams = {};
      setSavePopup(true);
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = presentModule;
      objectParams.moduleNumber = 'module1Data';
      objectParams.categeory = 'My Comparisons';
      objectParams.filesHeader = 'Scenarios';

      if (presentModule === "module9") {
        objectParams.start_year_relation = scenarioOne.short_term_start_year !== undefined ? scenarioOne.short_term_start_year  : (scenarioOne.long_term_start_year !== undefined ? scenarioOne.long_term_start_year : moduleServices.module1Data.start_year);
        objectParams.end_year_relation = scenarioTwo.short_term_start_year !== undefined ? scenarioTwo.short_term_start_year  : (scenarioTwo.long_term_start_year !== undefined ? scenarioTwo.long_term_start_year : moduleServices.module1Data.end_year);
      }else if(presentModule === "module18") {
        objectParams.start_year_relation = (scenarioOne.fed_sub_years !== undefined ? scenarioOne.fed_sub_years : 
          scenarioOne.fed_unsub_years !== undefined ? scenarioOne.fed_unsub_years : (
            scenarioOne.private_years !== undefined ? scenarioOne.private_years : moduleServices.module1Data.start_year
          )
        )
        objectParams.end_year_relation = (scenarioTwo.fed_sub_years !== undefined ? scenarioTwo.fed_sub_years : 
          scenarioTwo.fed_unsub_years !== undefined ? scenarioTwo.fed_unsub_years : (
            scenarioTwo.private_years !== undefined ? scenarioTwo.private_years : moduleServices.module1Data.end_year
          )
        )
      }else if(scenarioOne.start_year !== undefined && scenarioTwo.start_year !== undefined) {
        objectParams.start_year_relation = scenarioOne.start_year;
        objectParams.end_year_relation = scenarioTwo.start_year;
      }else if(scenarioOne.year_of_purchase !== undefined && scenarioTwo.year_of_purchase !== undefined){
        objectParams.start_year_relation = scenarioOne.year_of_purchase;
        objectParams.end_year_relation = scenarioTwo.year_of_purchase;
      }else if(scenarioOne.purchase_year !== undefined && scenarioTwo.purchase_year !== undefined){
        objectParams.start_year_relation = scenarioOne.purchase_year;
        objectParams.end_year_relation = scenarioTwo.purchase_year;
      }else{
        objectParams.start_year_relation = moduleServices.module1Data.start_year;
        objectParams.end_year_relation = moduleServices.module1Data.end_year;
      }

      objectParams.scenarioId1 = scenarioOne.scenarioId;
      objectParams.scenarioId2 = scenarioTwo.scenarioId;        
      console.log("chanduuu", scenarioOne, scenarioTwo, presentModule);
      setmoduleObjectParams(objectParams);
    }
  };

  return (
    <div>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.sectionContent}>
        <div className={classes.panelRow}>
          <div className={classes.panelInfo}>
            <div className={classes.panelHeading}>
              <div className={classes.rowBlock}>
                <div className={classes.scenarioNotes}>
                  <div className={classes.notesLeft}>
                    <ul className={classes.listUnstyled}>
                      <li />
                    </ul>
                  </div>
                  <div className={classNames(classes.notesRight, classes.scenarioNotesRight)}>
                    <ul className={classes.listUnstyled}>
                      <li><Button className={classNames(classes.loadButton, classes.btnWarning)} onClick={() => { openSightPage(currModule, 'load', '', 'compareScenario', 'compareScenario'); }}>Load</Button></li>
                      <li><Button className={classNames(classes.saveButton, classes.btnWarning)} onClick={handleSave}>Save</Button></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(classes.panelBody, classes.scenarioBlock)}>
              <Grid container>
                <Grid item sm={3} className={classNames(classes.gridPadding, classes.scenarioDiv)} />
                <Grid item sm={3} className={classNames(classes.gridPadding, classes.scenarioGrid)}>
                  <h6>{selectedScenarioLabelOne !== '' ? scenarioOne.label : 'Scenario A'}</h6>
                  <div className={classes.scenarioPopup}>
                    <small aria-hidden="true" onClick={() => { openSightPage(moduleSelected, 'load', '', 'scenario', 'scenarioA'); }}>
                      {(selectedScenarioLabelOne === '') && (<font>Select Scenario</font>)}
                      {selectedScenarioLabelOne !== '' && (
                        <font>{scenarioOne.name !== '' ? scenarioOne.name : selectedScenarioLabelOne}</font>
                      )}
                    </small>
                  </div>
                </Grid>
                <Grid item sm={3} className={classNames(classes.gridPadding, classes.scenarioGrid)}>
                  <h6>{selectedScenarioLabelTwo !== '' ? scenarioTwo.label : 'Scenario B'}</h6>
                  <div className={classes.scenarioPopup}>
                    <small aria-hidden="true" onClick={() => { openSightPage(moduleSelected, 'load', '', 'scenario', 'scenarioB'); }}>
                      {(!selectedScenarioLabelTwo || selectedScenarioLabelTwo === '') && (<font>Select Scenario</font>)}
                      {(selectedScenarioLabelTwo !== '') && (
                        <font>{scenarioTwo.name !== '' ? scenarioTwo.name : selectedScenarioLabelTwo}</font>
                      )}
                    </small>
                  </div>
                </Grid>
                <Grid item sm={3} className={classNames(classes.gridPadding, classes.scenarioGrid)}>
                  <h6>Differences</h6>
                  <div>
                    <select onChange={swapColumns}>
                      <option value="Scenario A - Scenario B">Scenario A - Scenario B</option>
                      <option value="Scenario B - Scenario A">Scenario B - Scenario A</option>
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.compareScenario}>
              {/* Module 1 Portion */}
              {(moduleSelected === 'module1' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareFI scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* Module 2 Portion */}
              {((moduleSelected === 'module2' || moduleSelected === 'module6' || moduleSelected === 'module15') && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <HigherEducation scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}

              {/* Module 9 Portion */}
              {(moduleSelected === 'module9' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (

                <CompareInvestments scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* Module 10 Portion */}
              {(moduleSelected === 'module10' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareFamily scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* Module 4 Portion */}
              {(moduleSelected === 'module4' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareCareerPath scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {(moduleSelected === 'module5' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareCareerAdvancement scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {(moduleSelected === 'module17' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareFurtherCareerAdvancement scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {(moduleSelected === 'module8' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareAdditionalCareerAdvancement scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* module 18 Portion */}
              {(moduleSelected === 'module18' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareExistingAssetsLiabilities scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* module 11 Portion */}
              {(moduleSelected === 'module11' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareVacation scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* module 12 Portion */}
              {(moduleSelected === 'module12' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareBuyingACar scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* module 13 Portion */}
              {(moduleSelected === 'module13' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareBuyingAHome scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
              {/* module 14 Portion */}
              {(moduleSelected === 'module14' && (Object.keys(scenarioOne).length !== 0 || Object.keys(scenarioTwo).length !== 0)) && (
                <CompareREI scenarioOne={scenarioOne} scenarioTwo={scenarioTwo} />
              )}
            </div>
            <Dialog
              open={openSightPopup}
              onClose={() => { setOpenSightPopup(false); }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={false}
            >
              <DialogTitle className={classes.compareHeader} >
                <div className={classes.sightPopupClose}>
                  <button className={classes.sightButton} type="button" onClick={() => { setOpenSightPopup(false); }}>×</button>
                </div>
              </DialogTitle>
              <DialogContent className={classes.compareModalBlock}>
                <Sight setOpenSightPopup={setOpenSightPopup} passData={sightData} compareCallback={compareCallback} moduleName={moduleName} />
              </DialogContent>
              <DialogActions className={classNames(classes.modalFooter, classes.compareFooter)}>
                <div>
                  <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { setOpenSightPopup(false); }}>Close</Button>
                </div>
              </DialogActions>

            </Dialog>

            {/* <Dialog
              open={ infoPopup }
              onClose={ () => { setInfoPopup( false ); } }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={ false }
              className={ classes.intrestModal }
            >
              <DialogTitle className={ classes.intrestModalHeader }>
                <h2>inkwiry.com says</h2>
              </DialogTitle>
              <DialogContent className={ classes.intrestModalBody }>
                <p>Please Select Scenario To Save</p>
              </DialogContent>
              <DialogActions className={ classes.intrestModalFooter }>
                <div className={ classes.modalFooter }>
                  <Button className={classes.okBtn} onClick={ () => { setInfoPopup( false ); } }>Ok</Button>
                </div>
              </DialogActions>
            </Dialog> */}
            <Dialog
              open={savePopup}
              onClose={() => { setSavePopup(false); }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={false}
              className={classes.SavePopup}
            >
              {/* <DialogTitle className={ classes.intrestModalHeader }>
                <h2>inkwiry.com says</h2>
              </DialogTitle> */}
              <DialogContent>
                <div className={classes.popupClose}>
                  <span aria-hidden="true" onClick={() => { setSavePopup(false) }}>
                    ×
                  </span>
                </div>
                <PwiSave setPopupOpen={setSavePopup} moduleParams={moduleObjectParams} />
              </DialogContent>
              {/* <DialogActions className={ classes.intrestModalFooter }>
                <div className={ classes.modalFooter }>
                  <Button className={classes.okBtn} onClick={ () => { setSavePopup( false ); } }>Ok</Button>
                </div>
              </DialogActions> */}
            </Dialog>
          </div>
        </div>
      </div>

    </div>
  );
};

CompareScenarios.propTypes = {
  classes: PropTypes.object.isRequired,
  moduleSelected: PropTypes.string.isRequired,
  getModuleData: PropTypes.func.isRequired,
  setUpdatedScenario: PropTypes.func.isRequired,
};
export default withStyles(styles)(CompareScenarios);
