const data = [
  {
   title: "Career Advancement",
   children: [
     {
       label: "Self-Employment",
       type: "text",
       prefix: "-",
       suffix: "",
       key: "self_employment"
     },{
       label: "Job Title",
       type: "text",
       prefix: "-",
       suffix: "",
       key: "occupation"
     },{
       label: "State",
       type: "text",
       prefix: "-",
       suffix: "",
       key: "state"
     },{
       label: "City",
       type: "text",
       prefix: "-",
       suffix: "",
       key: "city"
     },
   ] 
  },{
   title: "Base Income",
   children: [
     {
       label: "Start year",
       type: "date",
       prefix: "",
       suffix: "",
       key: "start_year"
     },{
       label: "End year",
       type: "date",
       prefix: "",
       suffix: "",
       key: "end_year"
     },{
       label: "Annual Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "yearly_income"
     },{
       label: "Bonus / Tips / Commission",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "bonus_or_tips_or_commission"
     },{
       label: "Total Pre-Tax Base Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_expected_income"
     },
   ] 
  },{
   title: "Supplementary Income",
   children: [
     {
       label: "Start year",
       type: "date",
       prefix: "",
       suffix: "",
       key: "second_start_year"
     },{
       label: "End year",
       type: "date",
       prefix: "",
       suffix: "",
       key: "second_end_year"
     },{
       label: "Annual Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "second_yearly_income"
     },
   ] 
  },{
   title: "Monthly Income Statement",
   children: [
     {
       label: "Monthly Base Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "monthly_base_income"
     },{
       label: "Other Monthly Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "other_monthly_income"
     },{
       label: "Total Monthly Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_monthly_income"
     },{
       label: "Total Taxes Paid",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_taxes_paid"
     },{
       label: "Living Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "living_expenses_cpath"
     },{
       label: "Higher Education Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "higher_education_expenses"
     },{
       label: "Children Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "children_expenses"
     },{
       label: "Pet Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "pet_expenses"
     },{
       label: "Car Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "car_expenses_cpath"
     },{
       label: "Real Estate Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "real_estate_expenses"
     },{
       label: "Interest Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "interest_expenses"
     },{
       label: "Total Monthly Expenses",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_monthly_expenses_cpath"
     },{
       label: "Monthly Net Income",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "monthly_net_income"
     },
   ] 
  },{
   title: "401(k)",
   children: [
     {
       label: "Beginning 401(k) Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "beginning_bal_401k"
     },{
       label: "Total Personal Contributions & Earnings",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_personal_contribution_earnings_401k"
     },{
       label: "Total Employer Contributions & Earnings",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_employer_contribution_earnings_401k"
     },{
       label: "% of Employer Contributions & Earnings Not Vested",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "employer_contributions_earnings_not_vested_401k"
     },{
       label: "Ending 401(k) Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "ending_balace_inyear_401k"
     },
   ] 
  },{
   title: "Roth IRA",
   children: [
     {
       label: "Beginning Roth IRA Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "beginning_bal_rothira"
     },{
       label: "Total Contributions",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_contribution_after_rothira"
     },{
       label: "Total Earnings",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_earnings_after_rothira"
     },{
       label: "Ending Roth IRA Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "ending_rothira_bal_inyear"
     },
   ] 
  },{
   title: "SEP IRA",
   children: [
     {
       label: "Beginning SEP IRA Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "beginning_bal_sephira"
     },{
       label: "Total Contribution",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_contribution_after_sephira"
     },{
       label: "Total Earnings",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "total_earnings_after_sephira"
     },{
       label: "Ending SEP IRA Balance",
       type: "number",
       prefix: "$",
       suffix: "",
       key: "ending_sephira_bal_inyear"
     },
   ] 
  },{
    title: "Pension",
    children: [
      {
        label: "Beginning Pension Balance",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "beginning_bal_pension"
      },{
        label: "Total Personal Contributions",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_personal_contribution_after_pension"
      },{
        label: "Total Employer Contributions",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_employer_contribution_after_pension"
      },{
        label: "Total Earnings",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_earning_after_pension"
      },{
        label: "Ending Pension Balance",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "ending_pension_bal_inyear"
      },
    ],
  },
];

export default data;