import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { timeline } from '../../../calculations/time-line';
import styles from './styles';
import fi from '../../../assets/img/financial-independence/begin-journey-icon.png';
import newfi from '../../../assets/img/financial-independence/begin-journey-icon_new.png';
import he from '../../../assets/img/financial-independence/higher-education-icon.png';
import newhe from '../../../assets/img/financial-independence/higher-education-icon_new.png';
import cp from '../../../assets/img/financial-independence/career-path.png';
import newcp from '../../../assets/img/financial-independence/career-path_new.png';
import ca from '../../../assets/img/financial-independence/career-advancement.png';
import newca from '../../../assets/img/financial-independence/career-advancement_new.png';
import mhe from '../../../assets/img/financial-independence/more-higher-education-icon.png';
import newmhe from '../../../assets/img/financial-independence/more-higher-education-icon_new.png';
import aca from '../../../assets/img/financial-independence/addt-career-advancement-icon.png';
import newaca from '../../../assets/img/financial-independence/addt-career-advancement-icon_new.png';
import investments from '../../../assets/img/financial-independence/investment-icon.png';
import newinvestments from '../../../assets/img/financial-independence/investment-icon_new.png';
import family from '../../../assets/img/financial-independence/family-icon.png';
import newfamily from '../../../assets/img/financial-independence/family-icon_new.png';

import vacation from '../../../assets/img/financial-independence/vacation-icon.png';
import newvacation from '../../../assets/img/financial-independence/vacation-icon_new.png';
import car from '../../../assets/img/financial-independence/buy-car-icon.png';
import newcar from '../../../assets/img/financial-independence/buy-car-icon_new.png';
import home from '../../../assets/img/financial-independence/buying-home-icon.png';
import newhome from '../../../assets/img/financial-independence/buying-home-icon_new.png';

import rei from '../../../assets/img/financial-independence/buying-realestate-icon.png';
import newrei from '../../../assets/img/financial-independence/buying-realestate-icon_new.png';

import fhe from '../../../assets/img/financial-independence/further_higher_edu.png';
import newfhe from '../../../assets/img/financial-independence/further_higher_edu_new.png';

import fca from '../../../assets/img/financial-independence/furthercareeradvancement.png';
import newfca from '../../../assets/img/financial-independence/furthercareeradvancement_new.png';

import existing from '../../../assets/img/financial-independence/students_loan.png';
import newexisting from '../../../assets/img/financial-independence/students_loan_new.png';

const moduleData = {
  module1: {
    color: '#1c6645',
    link: '/plan-with-inkwiry/fi',
    toolTip: 'Financial Independence',
    activeImg: fi,
    inactiveImg: newfi,
  },
  module2: {
    color: '#86A940',
    link: '/plan-with-inkwiry/he',
    toolTip: 'Higher Education',
    activeImg: he,
    inactiveImg: newhe,
  },
  module4: {
    color: '#2268AE',
    link: '/plan-with-inkwiry/cp',
    toolTip: 'Career Path',
    activeImg: cp,
    inactiveImg: newcp,
  },
  module5: {
    color: '#3795E6',
    link: '/plan-with-inkwiry/ca',
    toolTip: 'Career Advancement',
    activeImg: ca,
    inactiveImg: newca,
  },
  module6: {
    color: '#529C6F',
    link: '/plan-with-inkwiry/mhe',
    toolTip: 'More Higher Education',
    activeImg: mhe,
    inactiveImg: newmhe,
  },
  module8: {
    color: '#81C3DC',
    link: '/plan-with-inkwiry/aca',
    toolTip: 'Additional Career Advancement',
    activeImg: aca,
    inactiveImg: newaca,
  },
  module9: {
    color: '#CAA336',
    link: '/plan-with-inkwiry/investments',
    toolTip: 'Investments',
    activeImg: investments,
    inactiveImg: newinvestments,
  },
  module10: {
    color: '#3299AC',
    link: '/plan-with-inkwiry/family',
    toolTip: 'Family',
    activeImg: family,
    inactiveImg: newfamily,
  },
  module11: {
    color: '#B94606',
    link: '/plan-with-inkwiry/vacation',
    toolTip: 'Vacation',
    activeImg: vacation,
    inactiveImg: newvacation,
  },
  module12: {
    color: '#EF9300',
    link: '/plan-with-inkwiry/car',
    toolTip: 'Buying a Car',
    activeImg: car,
    inactiveImg: newcar,
  },
  module13: {
    color: '#E66E1D',
    link: '/plan-with-inkwiry/home',
    toolTip: 'Buying a Home',
    activeImg: home,
    inactiveImg: newhome,
  },
  module14: {
    color: '#134376',
    link: '/plan-with-inkwiry/rei',
    toolTip: 'Real Estate Investment',
    activeImg: rei,
    inactiveImg: newrei,
  },
  module15: {
    color: '#86A940',
    link: '/plan-with-inkwiry/fhe',
    toolTip: 'Further Higher Education',
    activeImg: fhe,
    inactiveImg: newfhe,
  },
  module17: {
    color: '#8790b8',
    link: '/plan-with-inkwiry/fca',
    toolTip: 'Further Career Advancement',
    activeImg: fca,
    inactiveImg: newfca,
  },
  module18: {
    color: '#cab488',
    link: '/plan-with-inkwiry/existing',
    toolTip: 'Existing Assets & Liabilities',
    activeImg: existing,
    inactiveImg: newexisting,
  },
};


const ModuleNavBar = ( props ) => {
  const { classes, moduleName, fromPageType, slidecount } = props;

  return (
    <Typography component="div">
      
      <div className={slidecount === 1 ? classNames(classes.fiModuleIcons, classes.fiModuleIconsVisible) : classes.fiModuleIcons} >
        <ul>
          {fromPageType !== 'pwi' && (
            <li className={ classes.fiLeftArrow }>
              <Link to="/timeline-sketcher" data-for="career_build" data-tip="Timeline Sketcher" />
              <ReactTooltip id="career_build" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.tooltipModules ) } />
            </li>
          )}
          {
            timeline.map( ( data ) => (
              <li key = { data.selectedModule} style={ { borderColor: ( (moduleName === data.selectedModule && fromPageType !== 'pwi') ? moduleData[data.selectedModule].color : 'transparent' ) } }>
                <ReactTooltip id="career_timeline" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                <Link to={ moduleData[data.selectedModule].link }><img src={ ( moduleName === data.selectedModule || data.imageSlug === 'active' ) ? moduleData[data.selectedModule].activeImg : moduleData[data.selectedModule].inactiveImg } alt="fi" data-for="career_timeline" data-tip={ moduleData[data.selectedModule].toolTip } /></Link>
                <ul className={ classes.timelineBar }>
                  {data.startYear !== '' && data.startYear > 0 && ( <li>{data.startYear}</li> )}
                  {data.endYear !== '' && data.endYear > 0 && ( <li>{data.endYear}</li> )}
                  {data.startYear !== '' && data.startYear > 0 && data.endYear !== '' && data.endYear > 0 && ( <li>{data.endYear - data.startYear + 1}</li> )}
                </ul>
              </li>
            ) )
          }
        </ul>
      </div>
      
    </Typography>
  );
};

ModuleNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  moduleName: PropTypes.string.isRequired,
  fromPageType: PropTypes.string,
  slidecount: PropTypes.string.isRequired
};
ModuleNavBar.defaultProps = {
  fromPageType: 'worksheet'
}

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( ModuleNavBar );
