export const defaultValues = {
  module1: {
    start_year: 0,
    long_term_growth_in_percentage: 0,
    inflation_in_percentage: 0,
  },
  module2: {
    type_of_higher_education: 0,
    name_of_college: '',
    state_id: 0,
    state: "",
    start_year: 0,
    years_diff: 0,
    graduation_year: 0,
    tuition: 0,
    room_board: 0,
    yearly_scholarship_amount: 0,
    yearly_grant_amount: 0,
    year_aid_begins: 0,
    year_aid_ends: 0,
    expected_yearly_finance_aid: 0,
    income_start_year: 0,
    income_end_year: 0,
    yearly_income: 0,
    family_start_year: 0,
    family_end_year: 0,
    yearly_contribution: 0,
    dependent_status: 'Yes',
    fed_sub_firstyear: 0,
    fed_sub_secondyear: 0,
    fed_sub_thirdyear: 0,
    fed_sub_fourthyear: 0,
    fed_sub_fifthyear: 0,
    fed_sub_sixthyear: 0,
    fed_sub_term: 0,
    fed_sub_interest_rate: 0.00,
    fed_sub_origination_fee: 0.00,
    fed_unsub_firstyear: 0,
    fed_unsub_secondyear: 0,
    fed_unsub_thirdyear: 0,
    fed_unsub_fourthyear: 0,
    fed_unsub_fifthyear: 0,
    fed_unsub_sixthyear: 0,
    fed_unsub_term: 0,
    fed_unsub_interest_rate: 0.00,
    fed_unsub_paymentplan: 0,
    fed_unsub_origination_fee: 0.00,
    private_firstyear: 0,
    private_secondyear: 0,
    private_thirdyear: 0,
    private_fourthyear: 0,
    private_fifthyear: 0,
    private_sixthyear: 0,
    private_term: 0,
    private_interest_rate: 0.00,
    private_paymentplan: 0,
    private_origination_fee: 0.00,
    on_campus_start_year: 0,
    off_campus_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    off_campus_start_year: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    second_clothing: 0,
    second_entertainment: 0,
    second_technology: 0,
    second_transportation: 0,
    second_miscellaneous: 0,
    second_medical_costs_before_deductible: 0,
    second_health_insurance_premium: 0,
  },
  module4: {
    roth_contribute_401k:'No',
    rent_insurance: 0,
    rent_total: 0,
    base_roth_income: 0,
    vested_amount_at_end_year: 0,
    ira_roth_contribute: 'No',
    ira_roth_personal_contribution: 0,
    contribute_hsa_savings_account: 'No',
    hsa_personal_contribution: 0,
    hsa_annual_expected: 0,
    self_employment: 'No',
    occupation: '',
    occupation_title: 'Expected Income',
    occupation_title1: 'Monthly Living Expenses',
    state_id: 0,
    state: "",
    state_title: '',
    state_tax: 0,
    city: '',
    city_taxes: 0,
    start_year: 0,
    end_year: 0,
    yearly_income: 0,
    bonus_or_tips_or_commission: 0,
    second_start_year: 0,
    second_end_year: 0,
    second_yearly_income: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    grocery_shopping: 0,
    meals_eaten_not_at_home: 0,
    other_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    contribute_401k: 'No',
    beginning_balance_of401k: 0,
    base_income: 0,
    employer_matching_rate: 0,
    employer_matching_limit: 0,
    Annual_expected401k: 0,
    roth_contribute: 'No',
    roth_beginning_balance: 0,
    roth_personal_contribution: 0,
    roth_annual_expected_pre_tax: 0,
    ira_contribute: 'No',
    ira_beginning_balance: 0,
    ira_personal_contribution: 0.0,
    ira_annual_expected_pre_tax: 0.0,
    pension_contribute: 'No',
    pension_beginning_balance: 0,
    pension_employer_contribution: 0,
    pension_personal_contribution: 0,
    pension_annual_expected_pre_tax: 0,
  },
  module5: {
    roth_contribute_401k:'No',
    vested_amount_at_end_year: 0,
    rent_insurance: 0,
    rent_total: 0,
    base_roth_income: 0,
    ira_roth_contribute:'No',
    ira_roth_personal_contribution: 0,
    contribute_hsa_savings_account: 'No',
    hsa_personal_contribution: 0,
    hsa_annual_expected: 0,
    self_employment: 'No',
    occupation: '',
    occupation_title: 'Expected Income',
    occupation_title1: 'Monthly Living Expenses',
    state_id: 0,
    state: "",
    state_tax: 0,
    city: '',
    city_taxes: 0,
    start_year: 0,
    end_year: 0,
    yearly_income: 0,
    bonus_or_tips_or_commission: 0,
    second_start_year: 0,
    second_end_year: 0,
    second_yearly_income: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    grocery_shopping: 0,
    meals_eaten_not_at_home: 0,
    other_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    contribute_401k: 'No',
    base_income: 0,
    employer_matching_rate: 0,
    employer_matching_limit: 0,
    Annual_expected401k: 0,
    roth_contribute: 'No',
    roth_personal_contribution: 0,
    roth_annual_expected_pre_tax: 0,
    ira_contribute: 'No',
    ira_personal_contribution: 0.0,
    ira_annual_expected_pre_tax: 0.0,
    pension_contribute: 'No',
    pension_employer_contribution: 0,
    pension_personal_contribution: 0,
    pension_annual_expected_pre_tax: 0,
  },
  module6: {
    type_of_higher_education: 0,
    name_of_college: '',
    state_id: 0,
    state: "",
    start_year: 0,
    years_diff: 0,
    graduation_year: 0,
    tuition: 0,
    room_board: 0,
    yearly_scholarship_amount: 0,
    yearly_grant_amount: 0,
    year_aid_begins: 0,
    year_aid_ends: 0,
    expected_yearly_finance_aid: 0,
    income_start_year: 0,
    income_end_year: 0,
    yearly_income: 0,
    family_start_year: 0,
    family_end_year: 0,
    yearly_contribution: 0,
    dependent_status: 'Yes',
    fed_sub_firstyear: 0,
    fed_sub_secondyear: 0,
    fed_sub_thirdyear: 0,
    fed_sub_fourthyear: 0,
    fed_sub_fifthyear: 0,
    fed_sub_sixthyear: 0,
    fed_sub_term: 0,
    fed_sub_interest_rate: 0.00,
    fed_sub_origination_fee: 0.00,
    fed_unsub_firstyear: 0,
    fed_unsub_secondyear: 0,
    fed_unsub_thirdyear: 0,
    fed_unsub_fourthyear: 0,
    fed_unsub_fifthyear: 0,
    fed_unsub_sixthyear: 0,
    fed_unsub_term: 0,
    fed_unsub_interest_rate: 0.00,
    fed_unsub_paymentplan: 0,
    fed_unsub_origination_fee: 0.00,
    private_firstyear: 0,
    private_secondyear: 0,
    private_thirdyear: 0,
    private_fourthyear: 0,
    private_fifthyear: 0,
    private_sixthyear: 0,
    private_term: 0,
    private_interest_rate: 0.00,
    private_paymentplan: 0,
    private_origination_fee: 0.00,
    on_campus_start_year: 0,
    off_campus_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    off_campus_start_year: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    second_clothing: 0,
    second_entertainment: 0,
    second_technology: 0,
    second_transportation: 0,
    second_miscellaneous: 0,
    second_medical_costs_before_deductible: 0,
    second_health_insurance_premium: 0,
  },
  module8: {
    roth_contribute_401k:'No',
    vested_amount_at_end_year:0,
    base_roth_income:0,
    ira_roth_contribute:'No',
    ira_roth_personal_contribution:0,
    contribute_hsa_savings_account:'No',
    hsa_personal_contribution:0,
    hsa_annual_expected:0,
    rent_insurance: 0,
    rent_total: 0,
    self_employment: 'No',
    occupation: '',
    occupation_title: 'Expected Income',
    occupation_title1: 'Monthly Living Expenses',
    state_id: 0,
    state: "",
    state_tax: 0,
    city: '',
    city_taxes: 0,
    start_year: 0,
    end_year: 0,
    yearly_income: 0,
    bonus_or_tips_or_commission: 0,
    second_start_year: 0,
    second_end_year: 0,
    second_yearly_income: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    grocery_shopping: 0,
    meals_eaten_not_at_home: 0,
    other_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    contribute_401k: 'No',
    base_income: 0,
    employer_matching_rate: 0,
    employer_matching_limit: 0,
    Annual_expected401k: 0,
    roth_contribute: 'No',
    roth_personal_contribution: 0,
    roth_annual_expected_pre_tax: 0,
    ira_contribute: 'No',
    ira_personal_contribution: 0.0,
    ira_annual_expected_pre_tax: 0.0,
    pension_contribute: 'No',
    pension_employer_contribution: 0,
    pension_personal_contribution: 0,
    pension_annual_expected_pre_tax: 0,
  },
  module9: {
    short_term_start_year: 0,
    short_term_begining_balance: 0,
    short_term_max_sti_balance_before_lt_investment: 0,
    short_term_annual_contribution: 0,
    short_term_annual_contribution_growth_in_percentage: 0,
    short_term_annual_expected_return_in_percentage: 0,
    long_term_start_year: 0,
    long_term_begining_balance: 0,
    long_term_annual_contribution: 0,
    long_term_annual_contribution_in_percentage_growth: 0.00,
    long_term_annual_expected_return_in_percentage: 0.00,
    financial_gift_start_year: 0,
    financial_gift_end_year: 0,
    yearly_financial_gift: 0,
    charitable_start_year: 0,
    charitable_end_year: 0,
    charitable_yearly_contribution: 0,
    plan_529_start_year:0,
    plan_529_annual_contribution_percentage:0,
    plan_529_annual_expected_pre_tax_return:0,
    life_insurance_start_year:0,
    monthly_premium:0,
    monthly_premium_percentage:0,
    annual_expected_pre_tax_return_life_ins:0,
    graph9: {
      annualExpectedReturn: 0,
      annualPreTaxReturn: 0,
      rothAnnualPreTaxReturn: 0,
      beginningBalance: 0,
      annualContribution: 0,
      beginningBalance401k: 0,
      rothBeginningBalance: 0,
      annualGrowth: 0,
    },
  },
  module10: {
    marriege_year: 0,
    wedding_costs: 0,
    occupation: '',
    start_year: 0,
    end_year: 0,
    spouse_income: 0,
    spouse_bonus_or_tips_or_commission: 0,
    second_occupation: '',
    second_start_year: 0,
    second_end_year: 0,
    second_spouse_income: 0,
    second_spouse_bonus_or_tips_or_commission: 0,
    rent_multiplier: 1.0,
    utilities_multiplier: 1.0,
    food_multiplier: 1.0,
    clothing_multiplier: 1.0,
    entertainment_multiplier: 1.0,
    technology_multiplier: 1.0,
    transportation_multiplier: 1.0,
    miscellaneous_multiplier: 1.0,
    health_insurance_costs_multiplier: 1.0,
    health_insurance_premium_multiplier: 1.0,
    spouse_student_loan: 0,
    spouse_ideal_repayment_schedule: 0,
    spouses_interest_rate: 0,
    number_of_children: 0,
    first_born: 0,
    second_born: 0,
    third_born: 0,
    fourth_born: 0,
    fifth_born: 0,
    sixth_born: 0,
    seventh_born: 0,
    save_average_cost_raising_child: 'No',
    housing_cost: 0,
    food_cost: 0,
    transportation_cost: 0,
    clothing_cost: 0,
    health_care_cost: 0,
    child_care_and_education: 0,
    other_costs: 0,
    year_of_purchase: 0,
    type_of_pet: '',
    pet_name: '',
    purchase_price: 0,
    save_annual_pet_new_values: 'No',
    default_annual_pet_vet_visits: 0,
    default_annual_pet_food: 0,
    default_annual_pet_toys: 0,
    default_annual_pet_emergency_fund: 0,
    default_annual_pet_health_insurance: 0,
    default_annual_pet_registration: 0,
    default_annual_pet_miscellaneous: 0,
    save_buying_pet_new_values: 'No',
    default_pet_first_vet_visit: 0,
    default_pet_new_supplies: 0,
    default_pet_miscellaneous: 0,
  },
  module11: {
    start_year: 0,
    end_year: 0,
    yearly_cost: 0,
    purchase_item: '',
    second_start_year: 0,
    second_end_year: 0,
    second_yearly_cost: 0,
    second_purchase_item: '',
    third_start_year: 0,
    third_end_year: 0,
    third_yearly_cost: 0,
    other_acquisition1: 'Special Purchase 1',
    other_acquisition2: 'Special Purchase 2',
  },
  module12: {
    leasing_car_start_year: 0,
    leasing_car_number_of_years: 0,
    leasing_car_down_payment: 0,
    leasing_car_drive_off_fees: 0,
    leasing_car_lease_payment: 0,
    leasing_car_maintenance: 0,
    leasing_car_insurance: 0,
    leasing_car_gas: 0,
    purchase_year: 0,
    base_price: 0,
    destination_charges: 0,
    car_options: 0,
    discount_in_percentage: 0,
    rebate: 0,
    tradein_existing_car: '',
    tradein_existing_car_2: 'No',
    tradein_existing_car_3: 'No',
    tradein_existing_car_4: 'No',
    moretradein_existing_car_2: 'No',
    moretradein_existing_car_3: 'No',
    moretradein_existing_car_4: 'No',
    save_change_existing_car_trade_value: 'No',
    existing_car_trade_value: 0,
    down_payment_in_percentage: 0,
    sales_tax_in_percentage: 0,
    registration_and_documentation_fees: 0,
    term_of_the_loan: 0,
    interest_rate: 0,
    origination_fees: 0,
    first_car_maintenance: 0,
    first_car_insurance: 0,
    first_car_gas: 0,
    leasing_second_car_start_year: 0,
    leasing_second_car_number_of_years: 0,
    leasing_second_car_down_payment: 0,
    leasing_second_car_drive_off_fees: 0,
    leasing_second_car_lease_payment: 0,
    leasing_second_car_maintenance: 0,
    leasing_second_car_insurance: 0,
    leasing_second_car_gas: 0,
    second_purchase_year: 0,
    second_base_price: 0,
    second_destination_charges: 0,
    second_options: 0,
    second_discount: 0,
    second_rebate: 0,
    moretradein_existing_car: '',
    save_change_first_car_trade_value: 'No',
    first_car_trade_value: 0,
    second_down_payment_in_percentage: 0,
    second_sales_tax: 0,
    second_registration_and_documentation_fees: 0,
    second_term_of_the_loan: 0,
    second_interest_rate: 0,
    second_origination_fees: 0,
    second_car_maintenance: 0,
    second_car_insurance: 0,
    second_car_gas: 0,
  },
  module13: {
    year_of_purchase: 0,
    purchase_price: 0,
    down_payment_in_percentage: 0,
    transfer_taxes_in_percentage: 0,
    title_insurance: 0,
    external_and_internal_fit_out_costs: 0,
    property_tax_in_percentage: 0,
    home_owners_insurance_in_percentage: 0,
    yearly_maintanance_in_percentage: 0,
    yearly_association_fees: 0,
    monthly_utilities: 0,
    appreciation_in_percentage: 0,
    private_mortgage_insurance: 0,
    term_of_mortgage: 0,
    rate_of_mortgage_in_percentage: 0,
    points_for_the_mortgage: 0,
    origination_fees: 0,
    save_home_closing_costs_new_values: 'No',
    closing_appraisal_fee: 0,
    closing_credit_report_fee: 0,
    closing_escrow_account: 0,
    closing_flood_determination: 0,
    closing_tax_service_fee: 0,
    closing_recording_mortgage: 0,
    closing_processing_fee: 0,
    closing_underwriting_fee: 0,
    closing_pest_Inspection: 0,
    closing_home_owners_association_transfer_fees: 0,
    closing_costs_others: 0,
  },
  module14: {
    year_of_purchase: 0,
    year_of_sale: 0,
    purchase_price: 0,
    down_payment_in_percentage: 0,
    transfer_taxes_in_percentage: 0,
    title_insurance: 0,
    property_taxes_in_percentage: 0,
    external_and_internal_fit_out_costs: 0,
    term_of_mortgage: 0,
    rate_of_mortgage_prcentage: 0,
    points_of_mortgage: 0,
    origination_fees: 0,
    yearly_rental_income: 0,
    vacancy_rate_in_percentage: 0,
    annual_income_growth_rate: 0,
    yearly_association_fees: 0,
    yearly_maintanance_percantage: 0,
    managemtnt_fee_in_percentage: 0,
    monthly_utilities: 0,
    home_owners_insurance_in_percentage: 0,
    appreciation_in_percentage: 0,
    selling_costs_in_percentage: 0,
    save_rei_closing_costs_new_values: 'No',
    closing_appraisal_fee: 0,
    closing_credit_report_fee: 0,
    closing_escrow_account: 0,
    closing_flood_determination: 0,
    closing_tax_service_fee: 0,
    closing_recording_mortgage: 0,
    closing_processing_fee: 0,
    closing_underwriting_fee: 0,
    closing_pest_Inspection: 0,
    closing_home_owners_association_transfer_fees: 0,
    closing_costs_others: 0,
  },
  module15: {
    type_of_higher_education: 0,
    name_of_college: '',
    state_id: 0,
    state: "",
    start_year: 0,
    years_diff: 0,
    graduation_year: 0,
    tuition: 0,
    room_board: 0,
    yearly_scholarship_amount: 0,
    yearly_grant_amount: 0,
    year_aid_begins: 0,
    year_aid_ends: 0,
    expected_yearly_finance_aid: 0,
    income_start_year: 0,
    income_end_year: 0,
    yearly_income: 0,
    family_start_year: 0,
    family_end_year: 0,
    yearly_contribution: 0,
    dependent_status: 'Yes',
    fed_sub_firstyear: 0,
    fed_sub_secondyear: 0,
    fed_sub_thirdyear: 0,
    fed_sub_fourthyear: 0,
    fed_sub_fifthyear: 0,
    fed_sub_sixthyear: 0,
    fed_sub_term: 0,
    fed_sub_interest_rate: 0.00,
    fed_sub_origination_fee: 0.00,
    fed_unsub_firstyear: 0,
    fed_unsub_secondyear: 0,
    fed_unsub_thirdyear: 0,
    fed_unsub_fourthyear: 0,
    fed_unsub_fifthyear: 0,
    fed_unsub_sixthyear: 0,
    fed_unsub_term: 0,
    fed_unsub_interest_rate: 0.00,
    fed_unsub_paymentplan: 0,
    fed_unsub_origination_fee: 0.00,
    private_firstyear: 0,
    private_secondyear: 0,
    private_thirdyear: 0,
    private_fourthyear: 0,
    private_fifthyear: 0,
    private_sixthyear: 0,
    private_term: 0,
    private_interest_rate: 0.00,
    private_paymentplan: 0,
    private_origination_fee: 0.00,
    on_campus_start_year: 0,
    off_campus_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    off_campus_start_year: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    second_clothing: 0,
    second_entertainment: 0,
    second_technology: 0,
    second_transportation: 0,
    second_miscellaneous: 0,
    second_medical_costs_before_deductible: 0,
    second_health_insurance_premium: 0,
  },
  module17: {
    roth_contribute_401k:'No',
    rent_insurance: 0,
    rent_total: 0,
    vested_amount_at_end_year:0,
    base_roth_income:0,
    ira_roth_contribute:'No',
    ira_roth_personal_contribution:0,
    contribute_hsa_savings_account:'No',
    hsa_personal_contribution:0,
    hsa_annual_expected:0,
    self_employment: 'No',
    occupation_title: 'Expected Income',
    occupation_title1: 'Monthly Living Expenses',
    occupation: '',
    state_id: 0,
    state: "",
    state_tax: 0,
    city: '',
    city_taxes: 0,
    start_year: 0,
    end_year: 0,
    yearly_income: 0,
    bonus_or_tips_or_commission: 0,
    second_start_year: 0,
    second_end_year: 0,
    second_yearly_income: 0,
    rent: 0,
    utilities: 0,
    food: 0,
    grocery_shopping: 0,
    meals_eaten_not_at_home: 0,
    other_food: 0,
    clothing: 0,
    entertainment: 0,
    technology: 0,
    transportation: 0,
    miscellaneous: 0,
    medical_costs_before_deductible: 0,
    health_insurance_premium: 0,
    contribute_401k: 'No',
    base_income: 0,
    employer_matching_rate: 0,
    employer_matching_limit: 0,
    Annual_expected401k: 0,
    roth_contribute: 'No',
    roth_personal_contribution: 0,
    roth_annual_expected_pre_tax: 0,
    ira_contribute: 'No',
    ira_personal_contribution: 0.0,
    ira_annual_expected_pre_tax: 0.0,
    pension_contribute: 'No',
    pension_employer_contribution: 0,
    pension_personal_contribution: 0,
    pension_annual_expected_pre_tax: 0,
  },
  module18: {
     // # New in pwi
     short_term_traditional_savings_account: 0,
     short_term_high_yield_savings_account: 0,
     short_term_certificates_of_deposit: 0,
     short_term_money_market_funds: 0,
     short_term_other: 0,
     max_term_balance_val: 0,
     long_term_managed_accounts: 0,
     long_term_stocks_and_bonds: 0,
     long_term_etfs: 0,
     long_term_mutual_funds: 0,
     long_term_reits: 0,
     long_term_other: 0,
     // # Done
    personal_loan_current_personal_balance: 0,
    personal_loan_months_remaining_on_loan:0,
    personal_loans_interest_rate: 0,
    existing_cash: 0,
    existing_checking_accounts: 0,
    existing_max_balance: 0,
    existing_total_short_term_investments: 0,
    existing_total_long_term_investments: 0,
    existing_max_short_term_before_long_term: 0,
    existing_total_value_of_hsa: 0,
    existing_529_plan: 0,
    existing_cash_value_life_insurance_policies: 0,
    existing_traditional_ra: 0,
    existing_401k_403b: 0,
    existing_roth_ira: 0,
    existing_roth_401k_403b: 0,
    existing_sep_ira: 0,
    existing_pension: 0,
    existing_art: 0,
    existing_jewelry: 0,
    existing_other: 0,
    existing_value_of_business:0,
    existing_expected_growth_rate:0,
    vehicle_1_type: "",
    existing_other_expected_growth_rate:0,
    existing_car_current_value:0,
    existing_car_current_loan_balance:0,
    existing_car_months_to_maturity:0,
    existing_car_interest_rate:0,
    existing_car_loans_lease_months_remaining:0,
    existing_car_loans_lease_maintenance:0,
    existing_car_loans_lease_insurance:0,
    existing_car_loans_lease_gas:0,
    vehicle_2_type: "",
    existing_other_expected_growth_rate_2:0,
    existing_car_current_value_2:0,
    existing_car_current_loan_balance_2:0,
    existing_car_months_to_maturity_2:0,
    existing_car_interest_rate_2:0,
    existing_car_loans_lease_months_remaining_2:0,
    existing_car_loans_lease_maintenance_2:0,
    existing_car_loans_lease_insurance_2:0,
    existing_car_loans_lease_gas_2:0,
    vehicle_3_type: "",
    existing_other_expected_growth_rate_3:0,
    existing_car_current_value_3:0,
    existing_car_current_loan_balance_3:0,
    existing_car_months_to_maturity_3:0,
    existing_car_interest_rate_3:0,
    existing_car_loans_lease_months_remaining_3:0,
    existing_car_loans_lease_maintenance_3:0,
    existing_car_loans_lease_insurance_3:0,
    existing_car_loans_lease_gas_3:0,
    vehicle_4_type: "",
    existing_other_expected_growth_rate_4:0,
    existing_car_current_value_4:0,
    existing_car_current_loan_balance_4:0,
    existing_car_months_to_maturity_4:0,
    existing_car_interest_rate_4:0,
    existing_car_loans_lease_months_remaining_4:0,
    existing_car_loans_lease_maintenance_4:0,
    existing_car_loans_lease_insurance_4:0,
    existing_car_loans_lease_gas_4:0,
    existing_car_loans_lease_payment:0,
    existing_car_loans_lease_payment_2:0,
    existing_car_loans_lease_payment_3:0,
    existing_car_loans_lease_payment_4:0,
    married_status:'No',
    primary_residence:'No',
    fed_sub_type_of_higher_education: 0,
    fed_sub_years: 0,
    fed_sub_months: 0,
    fed_sub_current_balance: 0,
    fed_sub_term: 0,
    fed_sub_interest_rate: 0.00,
    fed_unsub_type_of_higher_education: 0,
    fed_unsub_years: 0,
    fed_unsub_months: 0,
    fed_unsub_loan_amount: 0,
    fed_unsub_accrued_interest: 0,
    fed_unsub_term: 0,
    fed_unsub_interest_rate: 0.00,
    fed_unsub_payment_plan: 0,
    private_type_of_higher_education: 0,
    private_years: 0,
    private_months: 0,
    private_loan_amount: 0,
    private_accrued_interest: 0,
    private_term: 0,
    private_interest_rate: 0.00,
    private_payment_plan: 0,
    existing_credit_card_balance: 0,
    ideal_repayment_schedule: 0,
    creditcard_interest_rate: 0,
    // existing_car_current_value: 0,
    // existing_car_current_loan_balance: 0,
    // existing_car_months_to_maturity: 0,
    // existing_car_interest_rate: 0,
    existing_car_loans_maintenance: 0,
    existing_car_loans_lnsurance: 0,
    existing_car_loans_gas: 0,
    existig_home_current_value: 0,
    existig_home_basis_costs: 0,
    existing_home_annual_appreciation: 0,
    existig_home_current_mortgage_balance: 0,
    existig_home_mortgage_months_to_maturity: 0,
    existig_home_mortgage_interest_rate: 0,
    existing_home_monthly_property_taxes: 0,
    existing_home_monthly_homeowner_insurance: 0,
    existing_home_monthly_private_mortgage_insurance: 0,
    existing_home_monthly_maintenance: 0,
    existing_home_monthly_association_fees: 0,
    existing_home_monthly_utilities: 0,
    existing_home_sale_year: 0,
    existing_home_selling_costs: 0,
    existing_home_selling_costs_2: 0,
    married_status_2: 'No',
    primary_residence_2: "No",
    existing_home_sale_year_2: 0,
    existing_home_monthly_utilities_2: 0,
    existing_home_monthly_private_mortgage_insurance_2: 0,
    existig_home_mortgage_interest_rate_2: 0,
    existig_home_mortgage_months_to_maturity_2: 0,
    existing_home_annual_appreciation_2: 0,
    existig_home_current_mortgage_balance_2: 0,
    existig_home_basis_costs_2: 0,
    existig_home_current_value_2: 0,
    existing_home_monthly_association_fees_2: 0,
    existing_home_monthly_maintenance_2: 0,
    current_home_sale_in_15_years_1: "No",
    current_home_sale_in_15_years_2: "No",
    credit_card_status: false,
    personal_loans_status: false,
    federal_student_loans_status: false,
    private_student_loans_status: false,
    existig_home_current_status: false,
    existig_home_current_status_2: false,
    existing_home_monthly_homeowner_insurance_2: 0,
    existing_home_monthly_property_taxes_2: 0,  
    existing_car_loans_own_maintenance: 0, 
    existing_car_loans_own_insurance: 0, 
    existing_car_loans_own_gas: 0, 
    existing_car_loans_own_maintenance_2: 0, 
    existing_car_loans_own_insurance_2: 0, 
    existing_car_loans_own_gas_2: 0,   
    existing_car_loans_own_maintenance_3: 0, 
    existing_car_loans_own_insurance_3: 0, 
    existing_car_loans_own_gas_3: 0,  
    existing_car_loans_own_maintenance_4: 0, 
    existing_car_loans_own_insurance_4: 0, 
    existing_car_loans_own_gas_4: 0, 
  },
  typeOfEducation: [{ value: 0, label: 'Please select' },
  { value: 1, label: 'Career School' },
  { value: 2, label: 'College' },
  { value: 3, label: 'Graduate School' },
  { value: 4, label: 'Military School' }],
  paymentPlanTypes: [
    { value: 0, label: 'Please select' },
    { value: 1, label: 'Make Interest Payments' },
    { value: 2, label: 'Defer Interest Payments' },
  ],
  graphInitValues: {
    higherEducationSalary: 0,
    highSchoolSalary: 0,
    highEducationGrowthRateOnSalary: 0,
    highSchoolGrowthRateOnSalary: 0,
    capitalInvestment: 0,
    excessFamilyContribution: 0,
    loanPoints: 0
  },

  loans: ['fed_sub_type_of_higher_education', 'fed_sub_years', 'fed_sub_months', 'fed_sub_current_balance', 'fed_sub_term', 'fed_sub_interest_rate', 'fed_unsub_type_of_higher_education', 'fed_unsub_years',
    'fed_unsub_months', 'fed_unsub_loan_amount', 'fed_unsub_accrued_interest', 'fed_unsub_term', 'fed_unsub_interest_rate', 'fed_unsub_payment_plan', 'private_type_of_higher_education', 'private_years', 'private_months', 'private_loan_amount', 'private_accrued_interest', 'private_term', 'private_interest_rate', 'private_payment_plan'],
  credit: ['existing_credit_card_balance', 'ideal_repayment_schedule', 'creditcard_interest_rate'],
  car: ['existing_car_current_value', 'existing_car_current_loan_balance', 'existing_car_months_to_maturity', 'existing_car_interest_rate', 'existing_car_loans_maintenance', 'existing_car_loans_lnsurance', 'existing_car_loans_gas'],
  home: ['existig_home_current_value', 'existig_home_basis_costs', 'existing_home_annual_appreciation', 'existing_home_monthly_property_taxes', 'existing_home_monthly_homeowner_insurance', 'existing_home_monthly_private_mortgage_insurance', 'existing_home_monthly_maintenance', 'existing_home_monthly_association_fees', 'existing_home_monthly_utilities', 'existig_home_current_mortgage_balance', 'existig_home_mortgage_months_to_maturity', 'existig_home_mortgage_interest_rate', 'existing_home_sale_year', 'existig_home_selling_costs'],
};

export default defaultValues;
