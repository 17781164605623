const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '@media (max-width: 320px)': {
      fontSize: '12px',
    },
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  csBox: {
    background: '#fff',
    padding: '35px 40px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  blueBorder: {
    border: '2px solid #0069aa',
  },
  mortgageRow: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  leftPanel: {
    background: '#e7f1f9!important',
    borderRight: '2px solid #0069aa',
    paddingTop: '18px',
    verticalAlign: 'top',
    display: 'block',
    width: '25%',
    padding: '0px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '40%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      borderRight: 'none',
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      backgroundColor: 'transparent',
      '& tbody': {
        '& tr': {
          '& td': {
            paddingLeft: '10px',
            fontSize: '17px',
            paddingRight: '10px',
            color: '#000',
            boxSizing: 'border-box',
            '& div': {
              '&::before': {
                border: 'none !important',
              },
              '&::after': {
                border: 'none !important',
              },
            },
          },
        },
      },
    },
  },
  dollarField: {
    background: '#fff',
    position: 'relative',
    height: '45px',
    margin: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    paddingLeft: '2px !important',
    boxSizing: 'border-box',
    '& span': {
      position: 'absolute',
      left: '9px',
    },
  },
  formInput: {
    width: '100%',
    boxSizing: 'border-box',
    '& input': {
      padding: '0 5px',
      width: '100%',
      border: 'none',
      fontFamily: 'MuseoSans-300',
      fontSize: '17px',
      lineHeight: '1.42857143',
      margin: '0',
      font: 'inherit',
      color: '#000',
      boxSizing: 'border-box',
      paddingLeft: '17px',
    },
  },
  heightDiv: {
    height: '18px',
  },
  currencySym: {
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    marginTop: '0px',
    left: 'auto !important',
    right: '15px',
    textAlign: 'right',
    color: '#333',
    fontSize: '16px',
  },
  noField: {
    background: 'transparent',
    paddingLeft: '10px !important',
    fontFamily: 'MuseoSans-500',
    fontweight: '500',
  },
  blueDiv: {
    height: '2px',
    background: '#2f75b5',
  },
  rightPanel: {
    padding: '0px 15px',
    width: '75%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '60%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& h3': {
      marginBottom: '0',
      color: theme.palette.common.black,
      fontSize: '22px',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      fontWeight: '600',
      textAlign: 'center',
      marginTop: '0',
      width: 'calc(100% - 200px)',
      '@media (max-width: 767px)': {
        width: '100%',
        fontSize: '20px',
        marginTop: '15px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        width: '100%',
        fontSize: '19px',
      },
    },
  },
  hrLine: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
  },
  graphImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  percentField: {
    '& input': {
      paddingLeft: '5px !important',
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
    minHeight: 'calc(100vh - 101px)',
  },
  loanHead: {
    display: 'inline-flex',
    margin: '20px 0 10px',
    alignItems: 'center',
    width: '100%',
    padding: '0 15px',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  showButton: {
    '& span': {
      padding: '6px 13px',
      borderRadius: '4px',
      marginBottom: '0px',
      fontSize: '16px',
      borderWidth: '2px',
      color: theme.palette.common.white,
      backgroundColor: '#f0ad4e',
      border: '2px solid #eea236',
      display: 'inline-block',
      fontWeight: '400',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '@media (max-width: 992px)': {
        fontSize: '14px',
      },
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: '#ec971f',
        borderColor: '#d58512',
      },
    },
  },
  closeButton: {
    padding: '4px 5px',
    minWidth: '64px',
    borderRadius: '6px',
    fontSize: '14px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    backgroundImage: 'none',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  showTable: {
    '& > div > div': {
      maxWidth: '76%',
      width: '76%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
        margin:'15px',
      },
    },
    '& > div > div > div': {
      padding: '0px',
    },
  },
  popupClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    '&:hover': {
      color: ' #000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
    },
  },
  logoPic: {
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  modalHeader: {
    padding: '10px 15px',
    borderBottom: '1px solid #e5e5e5',
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
  },
  tableResponsive: {
    minHeight: '.01%',
    overflowX: 'auto',
    fontFamily: '"MuseoSans-300"',
    '& p': {
      fontSize: '17px',
      position: 'relative',
      margin: '0 0 10px',
      color: '#000',
      '&::before': {
        borderBottom: '2px solid #337ab7',
        bottom: '0',
        content: '""',
        left: '0',
        position: 'absolute',
        width: '100%',
        zIndex: '999',
      },
    },
  },
  federalLoanTable: {
    maxHeight: '457px',
    height: '100%',
    overflowY: 'scroll',
    fontFamily: '"MuseoSans-300"',
  },
  loanRepayTable: {
    padding: '0 15px 20px',
    borderLeft: '15px solid #0070c0',
    borderRight: '15px solid #0070c0',
    borderBottom: '4px solid #b5afaf',
    '& table': {
      transform: 'scale(1)',
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            color: '#000',
            padding: '0 3px',
            position: 'static',
            verticalAlign: 'bottom',
            backgroundColor: '#fff',
            textAlign: 'center',
            fontSize: '17px',
            lineHeight: '1.42857143',
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
  valueOne: {
    padding: '0',
    borderRight: '0',
    width: '33.33%',
    '& table': {
      width: '284px !important',
    },
  },
  loanRetailTable: {
    '& td': {
      '& table': {
        border: '2px solid #000',
        width: 'auto',
        borderSpacing: '0',
        borderCollapse: 'collapse',
        '& tr': {
          '& td': {
            verticalAlign: 'bottom',
            backgroundColor: '#fff',
            padding: '0 3px',
            position: 'static',
            fontSize: '17px',
            textAlign: 'left',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  loanRow: {
    borderBottom: '2px solid #000',
  },
  middleRow: {
    width: '4%',
  },
  intrestTable: {
    width: '25%',
    padding: '0 !important',
    verticalAlign: 'top !important',
    '& table': {
      border: '2px solid #000',
      transform: 'scale(1)',
      float: 'right',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      // width: '260px !important',
      width: '280px !important',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          backgroundColor: '#fff',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          textAlign: 'left',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  fixedData: {
    '& table': {
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          textAlign: 'left',
        },
      },
    },
  },
  fixedTable: {
    padding: '0px !important',
    '& table': {
      '& tr': {
        '& td': {
          background: '#0070c0 !important',
          color: '#fff !important',
          '&:nth-child(1)': {
            width: '2%',
          },
          '&:nth-child(2)': {
            width: '4%',
          },
          '&:nth-child(3)': {
            width: '11%',
          },
          '&:nth-child(4)': {
            width: '8%',
          },
          '&:nth-child(5)': {
            width: '8%',
          },
          '&:nth-child(6)': {
            width: '10%',
          },
          '&:nth-child(7)': {
            width: '12%',
          },
          '&:nth-child(8)': {
            width: '11%',
          },
          '&:nth-child(9)': {
            width: '8%',
          },
        },
      },
    },
  },
  borderLine: {
    borderBottom: '2px solid #000',
  },
  tableMainData: {
    '& td': {
      '& table': {
        '& tbody': {
          '& tr': {
            '& td': {
              textAlign: 'right',
              '&:nth-child(1)': {
                width: '2%',
                textAlign: 'left',
              },
              '&:nth-child(2)': {
                width: '3%',
              },
              '&:nth-child(3)': {
                width: '7%',
              },
              '&:nth-child(4)': {
                width: '5%',
              },
              '&:nth-child(5)': {
                width: '6%',
              },
              '&:nth-child(6)': {
                width: '7%',
              },
              '&:nth-child(7)': {
                width: '8%',
              },
              '&:nth-child(8)': {
                width: '8%',
              },
              '&:nth-child(9)': {
                width: '8%',
              },
            },
          },
        },
      },
    },
  },
  leftBorder: {
    borderLeft: '2px solid #000',
    borderRight: '2px solid #000',
  },
  bottomBorder: {
    borderBottom: '2px solid #000',
  },
  noPadding: {
    padding: '0px !important',
  },
  piText: {
    display: 'block',
  },
} );

export default styles;
