import * as moduleServices from '../calculations/modules-services';


function taxStatementGraph() {

  var taxStatementCompleteData = moduleServices.taxStatementCompleteData();
  let graphsData = {};
  //Total Gross Income (excluding Rental Income)
  graphsData[1] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {

        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Gross Income Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Adjustments  
  graphsData[2] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Adjustments Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Adjusted Gross Income
  graphsData[3] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Adjustments Gross Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Passive Losses Taken
  graphsData[4] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['REI Property Taxable Income Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //REI Property Taxable Income (Loss)
  graphsData[5] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Passive Losses Taken Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Deductions = Greater of (1) or (2)  
  graphsData[6] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['rei Property Taxable Income Graph Loss'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Itemized Deductions (1)
  graphsData[7] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Itemized Deductions Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Standard Deduction (2)
  graphsData[8] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },

    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Standard Deductions Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Deductions = Greater of (1) or (2)
  graphsData[9] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Deductions Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Federal Taxable Income
  graphsData[10] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Federal Taxable Income Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Taxes before Credits
  graphsData[11] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Taxes before Credits Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Tax Credits
  graphsData[12] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Taxes Credits Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  //Total Taxes
  graphsData[13] = {
    chart: {
      renderTo: "container",
      defaultSeriesType: 'bar',
      type: 'column',
      width: 100,
      height: 30,
      spacingBottom: -8,
      spacingTop: -10,
      spacingLeft: 0,
      spacingRight: 0,
      verticalAlign: 'top',
      top: 0,
      plotBackgroundColor: '#DDDAD6'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      labels:
      {
        enabled: false
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels:
      {
        enabled: false
      }
    },
    
    legend: {
      enabled: false
    },
    credits: {
      verticalAlign: 'top',
      enabled: false
    },
    plotOptions: {
      allowPointSelect: false,
      series: {
        negativeColor: '#BF0000'
      }
    },
    
    tooltip: {
      enabled: false
    },
    series: [{
      data: taxStatementCompleteData['Total Taxes Graph'],
      color: '#2F75B5',
      pointWidth: 5
    }]
  }

  return graphsData; 
}
export default taxStatementGraph;
