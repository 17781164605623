import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const NetWorth = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Net Worth</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/net-worth-banner.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      net worth
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/net-worth.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p
                          className={classNames(
                            classes.bigText,
                            classes.noTopMargin,
                          )}>
                          Net worth is the value of what you own (assets) minus
                          what you owe (liabilities)
                        </p>
                        <p>
                          To calculate your net worth, start by adding up the
                          value of all the things that you own – the value of
                          your checking and savings accounts, investments,
                          retirement accounts, cars and real estate. The value
                          of an asset is the price that you can sell it for
                          today.
                        </p>
                        <p>
                          Then, add up the value of all the things that you owe
                          - credit card balances, student loans, car loans, and
                          mortgages. The value of a liability is the outstanding
                          amount on that loan that still needs to be repaid.
                        </p>
                        <p>
                          Lastly, take the total value of your assets and
                          subtract the total value of your liabilities to get
                          your net worth. Check out the example below.
                        </p>
                        <div
                          className={classNames(
                            classes.galleryImage,
                            classes.autoImage,
                          )}>
                          <img
                            src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/net-worth-chart.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Knowing your net worth is important for two main
                          reasons:
                        </p>
                        <ol>
                          <li>
                            Net worth shows your current financial situation in
                            one, clean number, and
                          </li>
                          <li>
                            Net worth provides a reference point for measuring
                            your future financial progress.
                          </li>
                        </ol>
                        <p>
                          Net worth allows you to track your financial progress
                          over time. Are you making smart money moves that grow
                          your net worth? Leverage <Link to="/">Inkwiry</Link>{' '}
                          to find out and see what you might be worth in 5, 10,
                          15 years.
                        </p>
                        <p>
                          Calculate your net worth for free with Inkwiry&apos;s{' '}
                          <Link
                            to="/financial-calculators/net-worth"
                            target="_blank"
                            rel="noopener noreferrer">
                            Net Worth Calculator
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
NetWorth.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NetWorth);
