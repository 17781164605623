import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const DebunkingCreditScoreMyths = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-myths.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>5 Credit Score Myths</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-myths-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Credit scores shouldn&apos;t be a mystery. Check out 5 common credit score myths debunked!
                        </p>
                        <p><b>Myth #1 – Checking your credit score hurts your score</b></p>
                        <p>
                          You can check your credit score as much as you want using free services like
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.creditkarma.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Credit Karma</a>
                          {' '}
                          or
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.annualcreditreport.com/index.action" target="_blank" rel="budget-fy2020 noopener noreferrer">annualcreditreport.com.</a>
                          {' '}
                          {' '}
                          If a lender or creditor, such as a mortgage lender, checks your score, that can slightly lower your credit score as it signals you are applying for more debt.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>
                          <b>Myth #2 – You need a high income to have a great credit score</b>
                          <br />
                          Repaying your debts on time and using credit responsibly is what goes into your credit score. Your income does not impact your credit score. Check out the
                          {' '}
                          <a className={ classes.anchorStyle } href="https://inkwiry.com/finance-explained/credit-score-calculated" target="_blank" rel="budget-fy2020 noopener noreferrer">five variables</a>
                          {' '}
                          that are used to calculate your credit score.
                        </p>
                        <p>
                          <b>Myth #3 – Cancelling and closing out credit cards will improve your score</b>
                          <br />
                          Closing a credit card changes your
                          {' '}
                          <a className={ classes.anchorStyle } href="https://inkwiry.com/finance-explained/credit-score-calculated" target="_blank" rel="budget-fy2020 noopener noreferrer">utilization rate</a>
                          {' '}
                          and can potentially hurt your credit score. For example, let&apos;s say you have two credit cards and those cards each have a $2,500 limit. You have $1,000 outstanding on the first card and $0 on the second card, which means you are using $1,000 of your $5,000 total available credit or 20%. You cancel and close the second card.
                          <p>
                            {' '}
                            Now you are using $1,000 of your $2,500 total available credit or 40%. Your utilization rate has doubled and will most likely have a negative impact on your credit score! Always try and keep your utilization rate at 30% or below.
                          </p>
                        </p>
                        <p>
                          <b>Myth #4 – There is only one way to calculate a credit score</b>
                          <br />
                          There isn&apos;t just one way to calculate a credit score. The three credit bureaus – TransUnion, Experian and Equifax – all use different scoring models that result in different scores. Having different credit scores is normal.
                        </p>
                        <p>
                          <b>Myth #5 – All debt is equal</b>
                          <br />
                          Not all debt is equal. There is good debt and bad debt. If you have $150,000 in credit card debt, that is not good debt. But, if you have a $150,000 mortgage on your home that you are repaying on time, that would be good debt.
                        </p>
                        <p>
                          Now that you know some insider tips about your credit, you have the tools to maintain and improve your credit score!
                        </p>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level6/debunking-credit-score-myths/exercise" startTime={ startTime } level={ 6 } exercise={ 2 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DebunkingCreditScoreMyths.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( DebunkingCreditScoreMyths );
