import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import InvestmentBottomTwo from './investmment-bottomTwo';

const LongInvestmentSettingTimeline = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData, 
  } = props;

  const [startYear, setStartYear] = React.useState();

  const d = new Date();
  const currentYear = d.getFullYear();
  const startYearOptions = [];
  const loopYear = modulesServices.module1Data.start_year > 0 ? modulesServices.module1Data.start_year : currentYear;
  for (let year = 0; year <= 14; year += 1) {
    startYearOptions.push(loopYear + year);
  }

  const handleStartYear = (event) => {
    let InvObj = {...getInvestmentObject};
    let start_year = 0;
    start_year = parseInt(event.target.value);
    setStartYear(start_year);
    InvObj['long_term_start_year']=parseInt(event.target.value, 10);
    handleInvestmentData(InvObj);
  };

  useEffect(() => {
    if(getInvestmentObject.long_term_start_year !== undefined && getInvestmentObject.long_term_start_year !== '' && getInvestmentObject.long_term_start_year !== null){
    setStartYear(getInvestmentObject.long_term_start_year);
    }else{
      setStartYear(currentYear);
    }
  }, [getInvestmentObject]);

  return (
    <div className={classes.contentBlock}>
      <p>Only 55% of Americans own stock as of <a href="https://www.forbes.com/sites/teresaghilarducci/2020/08/31/most-americans-dont-have-a-real-stake-in-the-stock-market/?sh=1d8b65611545" target="_blank" rel="noreferrer">April 2020</a>. Most Americans aren’t capitalizing on the power of compounding interest when it comes to long-term investments.</p>
      <p>When do you plan on contributing to long-term investments? If you already are contributing, enter today's year. If not, enter the year you plan to start.</p>
      <div className={classNames(classes.grayBox, classes.investGrayDesign)}>
        <p>Make sure your net cash flow is positive in the year you plan to start making long-term investments.</p>
        <ul className={classes.yearsList}>
          <li>
            <span>I plan to start long-term investing in year&nbsp;</span>
            <select value={startYear} onChange={handleStartYear}>
              {startYearOptions.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>
            .
          </li>
          <li>Beginning long-term investments balance in year &nbsp;
            {startYear}:
            {modulesServices.module9Data.longTermBeginningBalance ?
              <NumberFormat id="beginning" type="text" displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={modulesServices.module9Data.longTermBeginningBalance} prefix={'$'} />
              :
              '$0'
            }.
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module9" />
      <div className={classNames(classes.borderDivider, classes.borderDividerMT30)} />
      {startYear !== '' && startYear !== undefined &&
        <InvestmentBottomTwo activeSlide={7} getInvestmentObject={getInvestmentObject} startYear={startYear} />
      }
    </div>
  );
};

LongInvestmentSettingTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
  // setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(LongInvestmentSettingTimeline);
