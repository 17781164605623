import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import Slide1 from '../../../assets/img/pwi-family/pwi-family-slide1.png';
import Slide2 from '../../../assets/img/pwi-family/pwi-family-slide2.png';
import Slide3 from '../../../assets/img/pwi-family/pwi-family-slide3.png';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Introducation = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Getting married and starting a family is exciting. Marriage changes your income status and requires some adjustments to your living expenses. Plus, if you want to have children, there are definiely added expenses you need to take into consideration.</p>
      <p>A solid plan that accounts for these changes will provide peace of mind for your personal finances as you get ready for this major life event. Let&apos;s start with when you&apos;re planning to get married.</p>
      <div className={ classes.sliderIntruduction }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <img alt="" src={ Slide1 } />
            </div>
            <div className={ classes.testimonialInner }>
              <img alt="" src={ Slide2 } />
            </div>
            <div className={ classes.testimonialInner }>
              <img alt="" src={ Slide3 } />
            </div>
          </Slider>
        </div>
      </div>

    </div>
  );
};

Introducation.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( Introducation );
