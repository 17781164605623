import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';

const RetirementAccounts = ( props ) => {
  const { classes, retirementList, activeRetirementList, setActiveRetirementList} = props;
  const update= (v, index) => {
    if(!activeRetirementList.includes(v) && v !== undefined) {
      let val = [...activeRetirementList];
      val[index] = retirementList[index];
      setActiveRetirementList([...val])
    }else{
      let val = [...activeRetirementList];
      val[index] = undefined;
      setActiveRetirementList([...val])
    }
  }
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        Select the retirement accounts below that you have.
      </p>
      <ul className={ classes.retirementList}>
        {
          retirementList.map((v, index) => (
            <li  className={  activeRetirementList.includes(v)  ? classes.activeVehicle : classes.activeDisable } onClick={ () => update(v, index)}>
              {v}
              <span>
                {activeRetirementList.includes(v) && <img src={BlueTick} alt={v} />}
              </span>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

RetirementAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( RetirementAccounts );
