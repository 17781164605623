import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Highcharts from 'highcharts';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import * as timeLineActions from '../../../calculations/time-line';
import NumberFormat from 'react-number-format';
import HCExporting from 'highcharts/modules/exporting';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { defaultValues } from '../../../calculations/default-module-values';
import Grid from '@material-ui/core/Grid';
import HighchartsReact from 'highcharts-react-official';
import * as modulesServices from '../../../calculations/modules-services';
import styles from './styles';
import infoIconWhite from '../../../assets/img/higher-education/info-icon-contrast.png';
import ReactTooltip from 'react-tooltip';
import familyImage from '../../../assets/img/higher-education/family_icon_modules.png';
import infoIcon from '../../../assets/img/financial-independence/info-icon.svg';


const moduleYears = [];
for (let i = 0; i < 15; i += 1) {
  moduleYears.push(i);
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#005F89', '#B04400', '#63625D', '#A46E00', '#1A466F', '#366731', '#66AFDB', '#FF8F4E', '#BBB7B0', '#FFCB30'],
});

HCExporting(Highcharts);

const SummaryTables = (props) => {
  const {
    classes, linkId
  } = props;
  const [shortTermSummaryLabel1] = React.useState('Total Annual Contributions');
  const [shortTermSummaryLabel2] = React.useState('Excess Cash Moved into Short-Term Investments');
  const [shortTermSummaryLabel4] = React.useState('Total Earnings');
  const [shortTermSummaryLabel5] = React.useState('Total Withdrawals for Cash Need');
  const [shortTermSummaryLabel6] = React.useState('Excess Short-Term Investments Moved into Long-Term');
  const [longTermSummaryLabel1] = React.useState('Total Annual Contributions');
  const [longTermSummaryLabel2] = React.useState('Excess Short-Term Investments Moved into Long-Term Investments');
  const [longTermSummaryLabel4] = React.useState('Total Earnings');
  const [longTermSummaryLabel5] = React.useState('Total Withdrawals for Cash Need');

  const educationTypes = defaultValues.typeOfEducation;
  const summaryTableData = timeLineActions.workSheetData();
  let outputSheetData = modulesServices.outputSheetCompleteData();
  // const module2Data = summaryTableData.module2Info;
  const module4Info = summaryTableData.module4Info;
  const module6Info = summaryTableData.module6Info;
  const module8Info = summaryTableData.module8Info;
  const module9Info = summaryTableData.module9Info;
  const module10Info = summaryTableData.module10Info;
  const module11Info = summaryTableData.module11Info;
  // const module12Info = summaryTableData.module12Info;
  const module14Info = summaryTableData.module14Info;
  const module15Info = summaryTableData.module15Info;
  const module13Info = summaryTableData.module13Info;
  const module17Info = summaryTableData.module17Info;
  // const totalCharitableYearModule4 = (module4Info.end_year - ((module4Info.end_year !== 0) ? (module4Info.start_year) + 1 : 0));
  // const totalCharitableYearModule5 = (summaryTableData.module5Info.end_year - ((summaryTableData.module5Info.end_year !== 0) ? (summaryTableData.module5Info.start_year) + 1 : 0));
  // const totalCharitableYearModule8 = (summaryTableData.module8Info.end_year - ((summaryTableData.module8Info.end_year !== 0) ? (summaryTableData.module8Info.start_year) + 1 : 0));

  React.useEffect(() => {
    switch (linkId) {
      case 1:
        document.getElementById('summary_table_financial').scrollIntoView();
        break;
      case 18:
        document.getElementById('summary_table_existing').scrollIntoView();
        break;
      case 2:
        document.getElementById('summary_table_higher_education').scrollIntoView();
        break;

      case 4:
        document.getElementById('summary_table_career_path').scrollIntoView();
        break;
      case 5:
        document.getElementById('summary_table_career_adv').scrollIntoView();
        break;
      case 6:
        document.getElementById('summary_table_more_edu').scrollIntoView();
        break;
      case 8:
        document.getElementById('summary_table_add_career').scrollIntoView();
        break;
      case 9:
        document.getElementById('summary_table_inv').scrollIntoView();
        break;
      case 10:
        document.getElementById('summary_table_family').scrollIntoView();
        break;
      case 11:
        document.getElementById('summary_table_vacation').scrollIntoView();
        break;
      case 12:
        document.getElementById('summary_table_car').scrollIntoView();
        break;
      case 13:
        document.getElementById('summary_table_home').scrollIntoView();
        break;
      case 14:
        document.getElementById('summary_table_rei').scrollIntoView();
        break;
      case 15:
        document.getElementById('summary_table_fur_edu').scrollIntoView();
        break;
      case 17:
        document.getElementById('fur_career_summary').scrollIntoView();
        break;
      default:
        break;
    }

  }, [linkId]);

  return (
    <Typography variant="body1" component="div">
      <div className={classes.tableSummaryResponsive} id="summary_table_financial">
        <div className={classes.tableResponsive}>
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.financeHeading)}>Financial Independence<span>()</span></h3>
                      <div className={classes.summaryGrid}>
                        <Grid container spacing={2}>
                          <Grid item sm={4} xs={4}>
                            <p className={classes.selectHeading}>Select Scenario</p>
                            <div className={classes.summarySelect}>
                              <small >
                                <font>Select Scenario</font>
                              </small>
                            </div>
                            <div className={classes.formGroup}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6}>
                                  <span className={classes.insideSpan}>
                                    <span className={classes.currencyContent}>Start Year</span>
                                  </span>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                    <span className={classes.currencyContent}>{summaryTableData.module1Info.start_year}</span>
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formGroup}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6}>
                                  <span className={classes.insideSpan}>
                                    <span className={classes.currencyContent}>Annual Income Growth</span>
                                  </span>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                    <span className={classes.currencyContent}>{summaryTableData.module1Info.long_term_growth_in_percentage}</span>
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formGroup}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6}>
                                  <span className={classes.insideSpan}>
                                    <span className={classes.currencyContent}>Annual Inflation</span>
                                  </span>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                    <span className={classes.currencyContent}>{summaryTableData.module1Info.inflation_in_percentage}%</span>
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                            {/* <div className={classes.formGroup}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6}>
                                  <span className={classes.insideSpan}>
                                    <span className={classes.currencyContent}>Current Cash Balance</span>
                                  </span>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                    <span className={classes.currencyContent}>
                                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={summaryTableData.module1Info.current_cash_balance} prefix={'$'} />
                                    </span>
                                  </span>
                                </Grid>
                              </Grid>
                            </div> */}
                            {/* <div className={classes.formGroup}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6}>
                                  <span className={classes.insideSpan}>
                                    <span className={classes.currencyContent}>Max Cash Balance before Investment</span>
                                  </span>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                    <span className={classes.currencyContent}>
                                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={summaryTableData.module1Info.max_cash_balance} prefix={'$'} /></span>
                                  </span>
                                </Grid>
                              </Grid>
                            </div> */}
                          </Grid>
                          <Grid item sm={8} xs={8}>
                            <h3 className={classes.summaryTableGraph}>Grow Your Net Worth: What's Your 5, 10, 15 Year Number?</h3>
                            <hr className={classes.blueLine} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={
                                {
                                  chart: {
                                    plotBackgroundColor: '#ffffff',
                                    plotBorderColor: '#cccccc',
                                    plotBorderWidth: 1,
                                    plotShadow: false,
                                    type: 'column',
                                    height: 400,
                                    width: 750
                                  },
                                  exporting: {
                                    enabled: false
                                  },
                                  title: {
                                    text: ''
                                  },
                                  credits: {
                                    enabled: false
                                  },
                                  xAxis: {
                                    categories: outputSheetData['yearsList'] ? outputSheetData['yearsList'] : [],
                                    labels: {
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px'
                                      }
                                    }
                                  },
                                  yAxis: [{ // Primary yAxis
                                    min: 0,
                                    //tickInterval: 10000,
                                    labels: {
                                      formatter() {
                                        const chart = this;
                                        if (chart.value < 0) {
                                          return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                                        } else {
                                          return formatDollar(Math.round(chart.value));
                                        }
                                      },
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px'
                                      }
                                    },
                                    title: {
                                      text: 'Income & Expeneses',
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px'
                                      }
                                    }
                                  }, { // Tertiary yAxis
                                    //tickInterval: 10000,
                                    gridLineWidth: 0,
                                    title: {
                                      text: 'Net Worth',
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px'
                                      }
                                    },
                                    labels: {
                                      formatter() {
                                        const chart = this;
                                        if (chart.value < 0) {
                                          return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                                        } else {
                                          return formatDollar(Math.round(chart.value));
                                        }
                                      },
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px'
                                      }
                                    },
                                    opposite: true
                                  }],
                                  tooltip: {
                                    formatter() {
                                      const chart = this;
                                      if (chart.y < 0) {
                                        return `${chart.x}<br/>${chart.series.name} <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
                                      } else {
                                        return `${chart.x}<br/>${chart.series.name} <b>${formatDollar(Math.round(chart.y))}</b>`;
                                      }
                                    },
                                  },
                                  plotOptions: {
                                    column: {
                                      stacking: 'normal'
                                    }
                                  },
                                  legend: {
                                    itemStyle: {
                                      fontSize: '14px'
                                    }
                                  },
                                  series: [{
                                    name: 'Gross Income',
                                    yAxis: 0,
                                    data: outputSheetData['grossIncomeGraphData'] ? outputSheetData['grossIncomeGraphData'] : [],
                                    stack: 'male',
                                    color: '#5B9BD5'
                                  }, {
                                    name: 'Total Expenses',
                                    yAxis: 0,
                                    data: outputSheetData['incomeAfterTaxesGraphData'] ? outputSheetData['incomeAfterTaxesGraphData'] : [],
                                    stack: 'female',
                                    color: '#FF0000'
                                  }, {
                                    name: 'Taxes',
                                    yAxis: 0,
                                    data: outputSheetData['totalExpensesGraphData'] ? outputSheetData['totalExpensesGraphData'] : [],
                                    stack: 'female',
                                    color: '#F4B183'
                                  }, {
                                    type: 'spline',
                                    name: 'Net Worth',
                                    yAxis: 1,
                                    data: outputSheetData['netWorthGraphData'] ? outputSheetData['netWorthGraphData'] : [],
                                    color: '#00B050'
                                  }]
                                }
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Higher Education */}
        <div className={classes.tableResponsive} id="summary_table_higher_education">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td  >
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.higherHeading)}>Higher Education</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Type of Higher Education</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module2Info.type_of_higher_education > 0 ? educationTypes[summaryTableData.module2Info.type_of_higher_education].label : 'Higher Education'}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Name of {summaryTableData.module2Info.type_of_higher_education > 0 ? educationTypes[summaryTableData.module2Info.type_of_higher_education].label : 'Higher Education'}</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module2Info.name_of_college}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module2Info.start_year}</span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>Years</span>
                                </span>
                              </Grid>

                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End year</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module2Info.graduation_year}</span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module2Info.years_diff > 0 ? summaryTableData.module2Info.years_diff  : 0}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Room & Board</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={summaryTableData.module2Info.room_board} prefix={'$'} />
                                  </span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Tuition & Fees</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}> 
                                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={summaryTableData.module2Info.tuition} prefix={'$'} />
                                  </span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Student Loan Payments during
                                    {' '}
                                    {' '}
                                    {modulesServices.module2Data.type_of_higher_education > 0 ? educationTypes[modulesServices.module2Data.type_of_higher_education].label : 'Higher Education'}
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Existing Student Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.existingSubLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.existingSubLoanPayments} prefix={modulesServices.module2Data.existingSubLoanPayments >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.existingSubLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>College Student Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.collegeStudentLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.collegeStudentLoanPayments} prefix={modulesServices.module2Data.collegeStudentLoanPayments >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.collegeStudentLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during College</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalStudentsLoanPayments} prefix={modulesServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Total Cost of Attendance </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalTuitionAndFees} prefix={modulesServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalRoomAndBoard} prefix={modulesServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherOnCampusLivingExpenses} prefix={modulesServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherOffCampusLivingExpenses} prefix={modulesServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during {summaryTableData.module2Info.type_of_higher_education > 0 ? educationTypes[summaryTableData.module2Info.type_of_higher_education].label : 'Higher Education'}</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalStudentsLoanPayments} prefix={modulesServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cost of Attendance</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalCostOfAttendance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalCostOfAttendance} prefix={modulesServices.module2Data.totalCostOfAttendance >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalCostOfAttendance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Total Expenses </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalTuitionAndFees} prefix={modulesServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalRoomAndBoard} prefix={modulesServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherOnCampusLivingExpenses} prefix={modulesServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherOffCampusLivingExpenses} prefix={modulesServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during College</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalStudentsLoanPayments} prefix={modulesServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Loan Payments on Other Debt</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.otherLoanPaymentsonOtherDebt !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.otherLoanPaymentsonOtherDebt} prefix={modulesServices.module2Data.otherLoanPaymentsonOtherDebt >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.otherLoanPaymentsonOtherDebt < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Car Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherCarExpense !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherCarExpense} prefix={modulesServices.module2Data.higherCarExpense >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherCarExpense < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Real Estate Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherRealEstateExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherRealEstateExpenses} prefix={modulesServices.module2Data.higherRealEstateExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherRealEstateExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Children and Pet Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherChildrenAndPetExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherChildrenAndPetExpenses} prefix={modulesServices.module2Data.higherChildrenAndPetExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherChildrenAndPetExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherTotalExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherTotalExpenses} prefix={modulesServices.module2Data.higherTotalExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherTotalExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <ReactTooltip id="analysisTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)} />
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Income Needed to Support Student Loans Analysis&nbsp;
                                    <div className={classes.infoIcon}>
                                      <img src={infoIconWhite} alt="infoicon"  data-for="analysisTooltip" data-tip="How do I know if I am taking too much in student loans? Great question! As a benchmark, your total student loan payments in one full year should not be more than 12.5% of your total gross income after graduation. See below for how much income this rule would suggest you need to make in your first career move after graduation to support your student loan payments."/>
                                    </div>
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Balance (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalStudentLoanBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalStudentLoanBalance} prefix={modulesServices.module2Data.totalStudentLoanBalance >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalStudentLoanBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Monthly Payment (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalMonthlyPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalMonthlyPayment} prefix={modulesServices.module2Data.totalMonthlyPayment >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalMonthlyPayment < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Annual Paid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.totalAnnualPaymentPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.totalAnnualPaymentPaid} prefix={modulesServices.module2Data.totalAnnualPaymentPaid >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.totalAnnualPaymentPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Divided by 12.5%</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>12.5%</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Income Needed to Support Student Loans</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.incomeNeedToSupportLoans !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.incomeNeedToSupportLoans} prefix={modulesServices.module2Data.incomeNeedToSupportLoans >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.incomeNeedToSupportLoans < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Investment Analysis&nbsp;
                                    <div
                                      className={classes.infoIcon}
                                      data-for="investTooltip"
                                      data-tip="Are you financing your education effectively? Do you minimize student loans and family contributions with scholarships, grants, and income?
                                      Look for ways to improve your financing strategy. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                    >
                                      <img src={infoIconWhite} alt="infoicon" />
                                    </div>
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Scholarships</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <NumberFormat decimalScale={1} value={modulesServices.module2Data.yearly_scholarship_amount * modulesServices.module2Data.years_diff} displayType="text" thousandSeparator prefix={'$'} />
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Grants</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <NumberFormat decimalScale={1} value={modulesServices.module2Data.yearly_grant_amount * modulesServices.module2Data.years_diff} displayType="text" thousandSeparator prefix={'$'} />
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Financial Aid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <NumberFormat decimalScale={1} value={modulesServices.module2Data.year_aid_ends * modulesServices.module2Data.years_diff} displayType="text" thousandSeparator prefix={'$'} />
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Income Earned after Taxes</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.incomeEarnedAfterTaxesVal !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.incomeEarnedAfterTaxesVal} prefix={modulesServices.module2Data.incomeEarnedAfterTaxesVal >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.incomeEarnedAfterTaxesVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Family Contribution</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <NumberFormat decimalScale={1} value={modulesServices.module2Data.family_end_year * modulesServices.module2Data.years_diff} displayType="text" thousandSeparator prefix={'$'} />
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Federal Subsidized Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.fedSubBeginningBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.fedSubBeginningBalance} prefix={modulesServices.module2Data.fedSubBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.fedSubBeginningBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Federal Unsubsidized Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.fedUnSubPrincipal !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.fedUnSubPrincipal} prefix={modulesServices.module2Data.fedUnSubPrincipal >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.fedUnSubPrincipal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Private Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.privatePrincipal !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.privatePrincipal} prefix={modulesServices.module2Data.privatePrincipal >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.privatePrincipal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Origination Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.summaryLoanPoints !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.summaryLoanPoints} prefix={modulesServices.module2Data.summaryLoanPoints >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.summaryLoanPoints < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Financing Sources</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.investmentAnalysisTotalCashAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.investmentAnalysisTotalCashAvailable} prefix={modulesServices.module2Data.investmentAnalysisTotalCashAvailable >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.investmentAnalysisTotalCashAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.higherTotalExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.higherTotalExpenses} prefix={modulesServices.module2Data.higherTotalExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.higherTotalExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Cash Left Over</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classNames(classes.noInputText, classes.spanBlueText)}>
                                        {(modulesServices.module2Data.investmentAnalysisTotalCashAvailable !== undefined && modulesServices.module2Data.higherTotalExpensesFinal !== undefined) ? (
                                          <NumberFormat
                                            decimalScale={0}
                                            fixedDecimalScale
                                            className={classes.formInput}
                                            displayType="text"
                                            allowNegative={false}
                                            thousandSeparator
                                            value={modulesServices.module2Data.investmentAnalysisTotalCashAvailable + modulesServices.module2Data.higherTotalExpensesFinal}
                                            prefix={(modulesServices.module2Data.investmentAnalysisTotalCashAvailable + modulesServices.module2Data.higherTotalExpensesFinal) >= 0 ? '$' : '($'}
                                            suffix={(modulesServices.module2Data.investmentAnalysisTotalCashAvailable + modulesServices.module2Data.higherTotalExpensesFinal) < 0 && ')'}
                                          />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Liquid Assets Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable} prefix={modulesServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable >= 0 ? '$' : '($'} suffix={modulesServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Career Path */}
        <div className={classes.tableResponsive} id="summary_table_career_path">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion}  >
                      <h3 className={classNames(classes.moduleHeading, classes.pathHeading)}>Career Path </h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Career</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Occupation</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{
                                    summaryTableData.module4Info.occupation !== undefined ? summaryTableData.module4Info.occupation : ""
                                  }</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module4Info.start_year}</span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>Year</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={4} xs={4}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module4Info.end_year}</span>
                                </span>
                              </Grid>
                              <Grid item sm={2} xs={2}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {(module4Info.end_year > 0 && module4Info.start_year > 0) ? (module4Info.end_year - module4Info.start_year) + 1 : 0}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                  <div className={classes.spanText}><strong>Total Pre-Tax Base Income</strong></div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} className={classes.noSpace}>
                                <div className={classes.inputRight}>
                                  <span className={classes.noInputText}>
                                    ${module4Info.totalPreTaxExpected_val}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Supplementary Income</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module4Info.second_start_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module4Info.second_end_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module4Info.second_yearly_income !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.second_yearly_income} prefix={module4Info.second_yearly_income >= 0 ? '$' : '($'} suffix={module4Info.second_yearly_income < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          {module4Info.contribute_401k === 'Yes' && (
                            <div>
                              <p className={classes.selectHeading}>401(k)</p>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Contribute to / Already Have a 401(k)?</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>{module4Info.contribute_401k}</span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.base_income !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.base_income} prefix={module4Info.base_income < 0 && '('} suffix={module4Info.base_income >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Employer Matching Rate on Personal Contribution</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.employer_matching_rate !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.employer_matching_rate} prefix={module4Info.employer_matching_rate < 0 && '('} suffix={module4Info.employer_matching_rate >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Employer Matching Limit as a % of Base Income</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.employer_matching_limit !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.employer_matching_limit} prefix={module4Info.employer_matching_limit < 0 && '('} suffix={module4Info.employer_matching_limit >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          )}
                          {module4Info.roth_contribute === 'Yes' && (
                            <div>
                              <p className={classes.selectHeading}>Roth IRA</p>

                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Contribute to / Already Have a Roth IRA?</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>{module4Info.roth_contribute}</span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.roth_personal_contribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.roth_personal_contribution} prefix={module4Info.roth_personal_contribution < 0 && '('} suffix={module4Info.roth_personal_contribution >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          )}

                          {module4Info.ira_contribute === 'Yes' && (
                            <div>
                              <p className={classes.selectHeading}>SEP IRA</p>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Contribute to / Already Have a SEP IRA?</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>{module4Info.ira_contribute}%</span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Personal Contribution as a % of Base Income (25% Limit)</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.ira_personal_contribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.ira_personal_contribution} prefix={module4Info.ira_personal_contribution < 0 && '('} suffix={module4Info.ira_personal_contribution >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          )}
                          {module4Info.pension_contribute === 'Yes' && (
                            <div>
                              <p className={classes.selectHeading}>Pension</p>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Contribute to / Already Have a Pension?</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>{module4Info.pension_contribute}</span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.pension_personal_contribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.pension_personal_contribution} prefix={module4Info.pension_personal_contribution < 0 && '('} suffix={module4Info.pension_personal_contribution >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.formGroup}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classes.insideSpan}>
                                      <span className={classes.currencyContent}>Employer Contribution as a % of Base Income</span>
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                      <span className={classes.currencyContent}>
                                        {module4Info.pension_employer_contribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module4Info.pension_employer_contribution} prefix={module4Info.pension_employer_contribution < 0 && '('} suffix={module4Info.pension_employer_contribution >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          )}
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Monthly Budget</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Monthly Base Income</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.monthlyIncomeFromCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.monthlyIncomeFromCareerPath} prefix={modulesServices.module4Data.monthlyIncomeFromCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.monthlyIncomeFromCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Monthly Income</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.otherMonthlyIncomeCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.otherMonthlyIncomeCareerPath} prefix={modulesServices.module4Data.otherMonthlyIncomeCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.otherMonthlyIncomeCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Monthly Income</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalMonthlyIncomeCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalMonthlyIncomeCareerPath} prefix={modulesServices.module4Data.totalMonthlyIncomeCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalMonthlyIncomeCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalTaxesPaidCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalTaxesPaidCareerPath} prefix={modulesServices.module4Data.totalTaxesPaidCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalTaxesPaidCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.livingExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.livingExpensesCareerPath} prefix={modulesServices.module4Data.livingExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.livingExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.higherEducationExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.higherEducationExpensesCareerPath} prefix={modulesServices.module4Data.higherEducationExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.higherEducationExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.childrenExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.childrenExpensesCareerPath} prefix={modulesServices.module4Data.childrenExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.childrenExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.petExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.petExpensesCareerPath} prefix={modulesServices.module4Data.petExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.petExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.carExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.carExpensesCareerPath} prefix={modulesServices.module4Data.carExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.carExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.realEstateExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.realEstateExpensesCareerPath} prefix={modulesServices.module4Data.realEstateExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.realEstateExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.internetExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.internetExpensesCareerPath} prefix={modulesServices.module4Data.internetExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.internetExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Monthly Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalMonthlyExpensesCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalMonthlyExpensesCareerPath} prefix={modulesServices.module4Data.totalMonthlyExpensesCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalMonthlyExpensesCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Monthly Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.monthlyNetIncomeCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.monthlyNetIncomeCareerPath} prefix={modulesServices.module4Data.monthlyNetIncomeCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.monthlyNetIncomeCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.retirementContributionsCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.retirementContributionsCareerPath} prefix={modulesServices.module4Data.retirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.retirementContributionsCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.monthlyNetCashFlowCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.monthlyNetCashFlowCareerPath} prefix={modulesServices.module4Data.monthlyNetCashFlowCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.monthlyNetCashFlowCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Annual Budget</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      &nbsp;
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      &nbsp;
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                      {modulesServices.module4Data.careerPathIconCalcs !== '' && (
                                        <div className={classes.formMainRow}>
                                          <div className={classes.labelText}>
                                            <div className={classes.calcText}>
                                              <span className={classes.familyImage}>
                                                <img src={familyImage} alt="familyImage" />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)} />
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            Year
                                            {' '}
                                            {modulesServices.module4Data.start_year}
                                            {' '}

                                          </u>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {(modulesServices.module4Data.careerPathIconCalcs !== 0 && modulesServices.module4Data.careerPathIconCalcs !== undefined) && (
                                            <span>
                                              {modulesServices.module4Data.careerPathIconCalcs}
                                            </span>
                                          )}

                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalIncomeCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalIncomeCareerPath} prefix={modulesServices.module4Data.totalIncomeCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalIncomeCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalIncomeCareerPathCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalIncomeCareerPathCalcs} prefix={modulesServices.module4Data.totalIncomeCareerPathCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalIncomeCareerPathCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.annualTotalTaxesPaidCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.annualTotalTaxesPaidCareerPath} prefix={modulesServices.module4Data.annualTotalTaxesPaidCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.annualTotalTaxesPaidCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.annualTotalExpensesCareerPathCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.annualTotalExpensesCareerPathCalcs} prefix={modulesServices.module4Data.annualTotalExpensesCareerPathCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.annualTotalExpensesCareerPathCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathLivingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathLivingExpenses} prefix={modulesServices.module4Data.careerPathLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathLivingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathLivingExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathLivingExpensesCalcs} prefix={modulesServices.module4Data.careerPathLivingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathLivingExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHigherEducationExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHigherEducationExpenses} prefix={modulesServices.module4Data.careerPathHigherEducationExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHigherEducationExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHigherEducationExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHigherEducationExpensesCalcs} prefix={modulesServices.module4Data.careerPathHigherEducationExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHigherEducationExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathChildrenExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathChildrenExpenses} prefix={modulesServices.module4Data.careerPathChildrenExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathChildrenExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathChildrenExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathChildrenExpensesCalcs} prefix={modulesServices.module4Data.careerPathChildrenExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathChildrenExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPetExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPetExpenses} prefix={modulesServices.module4Data.careerPathPetExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPetExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPetExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPetExpensesCalcs} prefix={modulesServices.module4Data.careerPathPetExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPetExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathCarExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathCarExpenses} prefix={modulesServices.module4Data.careerPathCarExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathCarExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathCarExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathCarExpensesCalcs} prefix={modulesServices.module4Data.careerPathCarExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathCarExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHomeExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHomeExpenses} prefix={modulesServices.module4Data.careerPathHomeExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHomeExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHomeExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHomeExpensesCalcs} prefix={modulesServices.module4Data.careerPathHomeExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHomeExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathFinancingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathFinancingExpenses} prefix={modulesServices.module4Data.careerPathFinancingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathFinancingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathFinancingExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathFinancingExpensesCalcs} prefix={modulesServices.module4Data.careerPathFinancingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathFinancingExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Annual Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathTotalAnnualExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathTotalAnnualExpenses} prefix={modulesServices.module4Data.careerPathTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathTotalAnnualExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathTotalAnnualExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathTotalAnnualExpensesCalcs} prefix={modulesServices.module4Data.careerPathTotalAnnualExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathTotalAnnualExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Annual Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathAnnualNetIncome !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathAnnualNetIncome} prefix={modulesServices.module4Data.careerPathAnnualNetIncome >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathAnnualNetIncome < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathAnnualNetIncomeCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathAnnualNetIncomeCalcs} prefix={modulesServices.module4Data.careerPathAnnualNetIncomeCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathAnnualNetIncomeCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.annualRetirementContributionsCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.annualRetirementContributionsCareerPath} prefix={modulesServices.module4Data.annualRetirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.annualRetirementContributionsCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.annualNetCashFlowCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.annualNetCashFlowCareerPath} prefix={modulesServices.module4Data.annualNetCashFlowCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.annualNetCashFlowCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                            <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>
                                      {(modulesServices.module4Data.start_year > 0 && modulesServices.module4Data.end_year > 0) && (
                                        <span>
                                          {' '}
                                          Budget Summary in years
                                          {' '}
                                          {modulesServices.module4Data.start_year}
                                          {' '}
                                          to
                                          {' '}
                                          {modulesServices.module4Data.end_year}
                                        </span>
                                      )}
                                      {(modulesServices.module4Data.start_year === 0 || modulesServices.module4Data.end_year === 0) && (
                                        <span>Budget Summary</span>
                                      )}
                                      <div
                                        className={classes.infoIcon}
                                        data-for="investTooltip"
                                        data-tip="Net income is income that is left over after all expenses are paid. Positive net income allows you to make investments, contribute to charity, or save for a car and a home. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                      >
                                        <img src={infoIconWhite} alt="infoicon" />
                                      </div>
                                    </h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.incomeEarned !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.incomeEarned} prefix={modulesServices.module4Data.incomeEarned >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.incomeEarned < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.estimatedTaxesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.estimatedTaxesPaid} prefix={modulesServices.module4Data.estimatedTaxesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.estimatedTaxesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.livingExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.livingExpensesPaid} prefix={modulesServices.module4Data.livingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.livingExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.higherEducationExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.higherEducationExpensesPaid} prefix={modulesServices.module4Data.higherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.higherEducationExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.childrenExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.childrenExpensesPaid} prefix={modulesServices.module4Data.childrenExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.childrenExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.petExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.petExpensesPaid} prefix={modulesServices.module4Data.petExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.petExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.carExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.carExpensesPaid} prefix={modulesServices.module4Data.carExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.carExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.realEstateExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.realEstateExpensesPaid} prefix={modulesServices.module4Data.realEstateExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.realEstateExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.financingExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.financingExpensesPaid} prefix={modulesServices.module4Data.financingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.financingExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalExpensesPaid} prefix={modulesServices.module4Data.totalExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.netIncomePaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.netIncomePaid} prefix={modulesServices.module4Data.netIncomePaid >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.netIncomePaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.finalRetirementContributionsCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.finalRetirementContributionsCareerPath} prefix={modulesServices.module4Data.finalRetirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.finalRetirementContributionsCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.finalNetCashFlowCareerPath !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.finalNetCashFlowCareerPath} prefix={modulesServices.module4Data.finalNetCashFlowCareerPath >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.finalNetCashFlowCareerPath < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <ReactTooltip id="detailsTooltip21" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <ReactTooltip id="detailsTooltip20" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <Grid item sm={4} xs={4}>

                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>401(k) Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.contribute_401k === 'Yes' && (

                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.contribute_401k === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.beginningBalanceInYear !== undefined && modulesServices.module4Data.beginningBalanceInYear <= 0 && modulesServices.module4Data.beginningBalanceInYear > 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.beginningBalanceInYear} prefix={modulesServices.module4Data.beginningBalanceInYear >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.beginningBalanceInYear < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.beginningBalanceInYearRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.beginningBalanceInYearRoth} prefix={modulesServices.module4Data.beginningBalanceInYearRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.beginningBalanceInYearRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Personal Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.personalContribuitionafterYears !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.personalContribuitionafterYears} prefix={modulesServices.module4Data.personalContribuitionafterYears >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.personalContribuitionafterYears < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.personalContribuitionafterYearsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.personalContribuitionafterYearsRoth} prefix={modulesServices.module4Data.personalContribuitionafterYearsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.personalContribuitionafterYearsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Personal Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.earningOnPersonalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.earningOnPersonalContribution} prefix={modulesServices.module4Data.earningOnPersonalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.earningOnPersonalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.earningOnPersonalContributionRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.earningOnPersonalContributionRoth} prefix={modulesServices.module4Data.earningOnPersonalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.earningOnPersonalContributionRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Personal Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalPersonalContributionEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalPersonalContributionEarnings} prefix={modulesServices.module4Data.totalPersonalContributionEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalPersonalContributionEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalPersonalContributionEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalPersonalContributionEarningsRoth} prefix={modulesServices.module4Data.totalPersonalContributionEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalPersonalContributionEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Employer Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.employerContributionAfterYears !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.employerContributionAfterYears} prefix={modulesServices.module4Data.employerContributionAfterYears >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.employerContributionAfterYears < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.employerContributionAfterYearsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.employerContributionAfterYearsRoth} prefix={modulesServices.module4Data.employerContributionAfterYearsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.employerContributionAfterYearsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Employer Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.earningsOnEmployerContributionAfterYears !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.earningsOnEmployerContributionAfterYears} prefix={modulesServices.module4Data.earningsOnEmployerContributionAfterYears >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.earningsOnEmployerContributionAfterYears < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.earningsOnEmployerContributionAfterYearsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.earningsOnEmployerContributionAfterYearsRoth} prefix={modulesServices.module4Data.earningsOnEmployerContributionAfterYearsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.earningsOnEmployerContributionAfterYearsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Employer Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalEmployersContributionsEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalEmployersContributionsEarnings} prefix={modulesServices.module4Data.totalEmployersContributionsEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalEmployersContributionsEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.totalEmployersContributionsEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.totalEmployersContributionsEarningsRoth} prefix={modulesServices.module4Data.totalEmployersContributionsEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.totalEmployersContributionsEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>401(k) Balance before Vesting Reductions</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.balanceBeforeVestingReductions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.balanceBeforeVestingReductions} prefix={modulesServices.module4Data.balanceBeforeVestingReductions >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.balanceBeforeVestingReductions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.balanceBeforeVestingReductionsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.balanceBeforeVestingReductionsRoth} prefix={modulesServices.module4Data.balanceBeforeVestingReductionsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.balanceBeforeVestingReductionsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>

                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>% of Employer Contributions & Earnings Not Vested</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.employerContributionsEarningsNotVested !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.employerContributionsEarningsNotVested} prefix={modulesServices.module4Data.employerContributionsEarningsNotVested >= 0 ? '' : '('} suffix={modulesServices.module4Data.employerContributionsEarningsNotVested < 0 && '%)'} />
                                          ) : ('0%')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip21" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.employerContributionsEarningsNotVestedRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.employerContributionsEarningsNotVestedRoth} prefix={modulesServices.module4Data.employerContributionsEarningsNotVestedRoth >= 0 ? '' : '('} suffix={modulesServices.module4Data.employerContributionsEarningsNotVestedRoth < 0 && '%)'} />
                                          ) : ('0%')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip20" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.contribute_401k === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.end_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.contribute_401k === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance

                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.ending401Balance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.ending401Balance} prefix={modulesServices.module4Data.ending401Balance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.ending401Balance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.ending401BalanceRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.ending401BalanceRoth} prefix={modulesServices.module4Data.ending401BalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.ending401BalanceRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <ReactTooltip id="detailsTooltip19" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                          <ReactTooltip id="detailsTooltip18" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Roth IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.roth_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.roth_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.rothBeginningBalance !== undefined && modulesServices.module4Data.rothBeginningBalance > 0 && modulesServices.module4Data.rothBeginningBalance <= 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.rothBeginningBalance} prefix={modulesServices.module4Data.rothBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.rothBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.rothBeginningBalanceIra !== undefined && modulesServices.module4Data.rothBeginningBalanceIra <= 0 && modulesServices.module4Data.rothBeginningBalanceIra > 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.rothBeginningBalanceIra} prefix={modulesServices.module4Data.rothBeginningBalanceIra >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.rothBeginningBalanceIra < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>

                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRATotalContribution} prefix={modulesServices.module4Data.careerPathRothIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip19" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRATotalContributionRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRATotalContributionRoth} prefix={modulesServices.module4Data.careerPathRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRATotalContributionRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip18" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRATotalEarnings} prefix={modulesServices.module4Data.careerPathRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRATotalEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRATotalEarningsRoth} prefix={modulesServices.module4Data.careerPathRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRATotalEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.roth_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.roth_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRAEndingBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRAEndingBalance} prefix={modulesServices.module4Data.careerPathRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRAEndingBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathRothIRAEndingBalanceRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathRothIRAEndingBalanceRoth} prefix={modulesServices.module4Data.careerPathRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathRothIRAEndingBalanceRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>SEP IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance in Year
                                              {modulesServices.module4Data.start_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathSEPIRABeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathSEPIRABeginningBalance} prefix={modulesServices.module4Data.careerPathSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathSEPIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathSEPIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathSEPIRATotalContribution} prefix={modulesServices.module4Data.careerPathSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathSEPIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathSEPIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathSEPIRATotalEarnings} prefix={modulesServices.module4Data.careerPathSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathSEPIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathEndingSEPIRABalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathEndingSEPIRABalance} prefix={modulesServices.module4Data.careerPathEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathEndingSEPIRABalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Pension Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.pension_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Pension Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.pension_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Pension Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPensionBeginningBalance !== undefined && modulesServices.module4Data.careerPathPensionBeginningBalance < 0 && modulesServices.module4Data.careerPathPensionBeginningBalance >= 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPensionBeginningBalance} prefix={modulesServices.module4Data.careerPathPensionBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPensionBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Personal Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPensionTotalEmployerContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPensionTotalEmployerContributions} prefix={modulesServices.module4Data.careerPathPensionTotalEmployerContributions >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPensionTotalEmployerContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Employer Contributions After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPensionTotalPersonalContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPensionTotalPersonalContributions} prefix={modulesServices.module4Data.careerPathPensionTotalPersonalContributions >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPensionTotalPersonalContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module4Data.end_year - ((modulesServices.module4Data.end_year !== 0) ? (modulesServices.module4Data.start_year) + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathPensionTotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathPensionTotalEarnings} prefix={modulesServices.module4Data.careerPathPensionTotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathPensionTotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.pension_contribute === 'Yes' && (

                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Pension Balance in Year
                                              {' '}
                                              {modulesServices.module4Data.end_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.pension_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Pension Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathEndingPensionBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathEndingPensionBalance} prefix={modulesServices.module4Data.careerPathEndingPensionBalance >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathEndingPensionBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>HSA Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        Beginning HSA Balance
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHsaBeginningBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHsaBeginningBalanceSummary} prefix={modulesServices.module4Data.careerPathHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHsaBeginningBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHsaTotalContributionSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHsaTotalContributionSummary} prefix={modulesServices.module4Data.careerPathHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHsaTotalContributionSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHsaTotalEarningsSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHsaTotalEarningsSummary} prefix={modulesServices.module4Data.careerPathHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHsaTotalEarningsSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module4Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                              {modulesServices.module4Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module4Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module4Data.careerPathHsaTotalEndingBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module4Data.careerPathHsaTotalEndingBalanceSummary} prefix={modulesServices.module4Data.careerPathHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module4Data.careerPathHsaTotalEndingBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Career Advancement */}

        <div className={classes.tableResponsive} id="summary_table_career_adv">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.careerAdvHeading)}>Career Advancement</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Career</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Job title</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{
                                    summaryTableData.module5Info.occupation !== undefined ? summaryTableData.module5Info.occupation : ""
                                  }</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.start_year !== null ? summaryTableData.module5Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.end_year !== undefined ? summaryTableData.module5Info.end_year : '0'}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                  <div className={classes.spanText}><strong>Total Pre-Tax Base Income</strong></div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} className={classes.noSpace}>
                                <div className={classes.inputRight}>
                                  <span className={classes.noInputText}>
                                    {summaryTableData.module5Info.advanceTotalPreTaxExpected_val !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module5Info.advanceTotalPreTaxExpected_val} prefix={summaryTableData.module5Info.advanceTotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={summaryTableData.module5Info.advanceTotalPreTaxExpected_val < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Supplementary Income</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.second_start_year !== undefined ? summaryTableData.module5Info.second_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.second_end_year !== undefined ? summaryTableData.module5Info.second_end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module5Info.second_yearly_income !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module5Info.second_yearly_income} prefix={summaryTableData.module5Info.second_yearly_income >= 0 ? '$' : '($'} suffix={summaryTableData.module5Info.second_yearly_income < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>401(k)</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a 401(k)?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module5Info.contribute_401k !== undefined ? summaryTableData.module5Info.contribute_401k : "No"}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.base_income !== undefined ? summaryTableData.module5Info.base_income : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Rate on Personal Contribution</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.employer_matching_rate !== null ? summaryTableData.module5Info.employer_matching_rate : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Limit as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.employer_matching_limit !== null ? summaryTableData.module5Info.employer_matching_limit : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Roth IRA</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Roth IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.roth_contribute !== undefined ? summaryTableData.module5Info.roth_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.roth_personal_contribution !== undefined ? summaryTableData.module5Info.roth_personal_contribution : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>SEP IRA</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a SEP IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.ira_contribute !== undefined ? summaryTableData.module5Info.ira_contribute : "$0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income (25% Limit)</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.ira_personal_contribution !== undefined ? summaryTableData.module5Info.ira_personal_contribution : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Pension</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Pension?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.pension_contribute !== null ? summaryTableData.module5Info.pension_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.pension_employer_contribution !== null ? summaryTableData.module5Info.pension_employer_contribution : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module5Info.pension_personal_contribution !== null ? summaryTableData.module5Info.pension_personal_contribution : "0"}%</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Monthly Budget</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Monthly Base Income</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.monthlyIncomeFromCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.monthlyIncomeFromCareerAdv} prefix={modulesServices.module5Data.monthlyIncomeFromCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.monthlyIncomeFromCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Monthly Income</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.otherMonthlyIncomeCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.otherMonthlyIncomeCareerAdv} prefix={modulesServices.module5Data.otherMonthlyIncomeCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.otherMonthlyIncomeCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Monthly Income</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalMonthlyIncomeCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalMonthlyIncomeCareerAdv} prefix={modulesServices.module5Data.totalMonthlyIncomeCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalMonthlyIncomeCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalTaxesPaidCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalTaxesPaidCareerAdv} prefix={modulesServices.module5Data.totalTaxesPaidCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalTaxesPaidCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.livingExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.livingExpensesCareerAdv} prefix={modulesServices.module5Data.livingExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.livingExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.higherEducationExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.higherEducationExpensesCareerAdv} prefix={modulesServices.module5Data.higherEducationExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.higherEducationExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.childrenExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.childrenExpensesCareerAdv} prefix={modulesServices.module5Data.childrenExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.childrenExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.petExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.petExpensesCareerAdv} prefix={modulesServices.module5Data.petExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.petExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.carExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.carExpensesCareerAdv} prefix={modulesServices.module5Data.carExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.carExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.realEstateExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.realEstateExpensesCareerAdv} prefix={modulesServices.module5Data.realEstateExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.realEstateExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.interestExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.interestExpensesCareerAdv} prefix={modulesServices.module5Data.interestExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.interestExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Monthly Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalMonthlyExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalMonthlyExpensesCareerAdv} prefix={modulesServices.module5Data.totalMonthlyExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalMonthlyExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Monthly Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.monthlyNetIncomeCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.monthlyNetIncomeCareerAdv} prefix={modulesServices.module5Data.monthlyNetIncomeCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.monthlyNetIncomeCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.monthlyRetirementContributionsCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.monthlyRetirementContributionsCareerAdv} prefix={modulesServices.module5Data.monthlyRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.monthlyRetirementContributionsCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.monthlyNetCashFlowCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.monthlyNetCashFlowCareerAdv} prefix={modulesServices.module5Data.monthlyNetCashFlowCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.monthlyNetCashFlowCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Annual Budget</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      &nbsp;
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      &nbsp;
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                      {modulesServices.module5Data.careerAdvIconCalcs !== '' && (
                                        <div className={classes.formMainRow}>
                                          <div className={classes.labelText}>
                                            <div className={classes.calcText}>
                                              <span className={classes.familyImage}>
                                                <img src={familyImage} alt="familyImage" />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)} />
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            Year
                                            {' '}
                                            {modulesServices.module5Data.start_year}
                                            {' '}

                                          </u>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {(modulesServices.module5Data.careerAdvIconCalcs !== 0 && modulesServices.module5Data.careerAdvIconCalcs !== undefined) && (
                                            <span>
                                              {modulesServices.module5Data.careerAdvIconCalcs}
                                            </span>
                                          )}

                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalIncomeCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalIncomeCareerAdv} prefix={modulesServices.module5Data.totalIncomeCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalIncomeCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalIncomeCareerAdvCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalIncomeCareerAdvCalcs} prefix={modulesServices.module5Data.totalIncomeCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalIncomeCareerAdvCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualTotalTaxesPaidCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualTotalTaxesPaidCareerAdv} prefix={modulesServices.module5Data.annualTotalTaxesPaidCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualTotalTaxesPaidCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualTotalTaxesPaidCareerAdvCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualTotalTaxesPaidCareerAdvCalcs} prefix={modulesServices.module5Data.annualTotalTaxesPaidCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualTotalTaxesPaidCareerAdvCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvLivingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvLivingExpenses} prefix={modulesServices.module5Data.careerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvLivingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvLivingExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvLivingExpensesCalcs} prefix={modulesServices.module5Data.careerAdvLivingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvLivingExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvHigherEducationExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvHigherEducationExpenses} prefix={modulesServices.module5Data.careerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvHigherEducationExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvHigherEducationExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvHigherEducationExpensesCalcs} prefix={modulesServices.module5Data.careerAdvHigherEducationExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvHigherEducationExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvChildrenExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvChildrenExpenses} prefix={modulesServices.module5Data.careerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvChildrenExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvChildrenExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvChildrenExpensesCalcs} prefix={modulesServices.module5Data.careerAdvChildrenExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvChildrenExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvPetExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvPetExpenses} prefix={modulesServices.module5Data.careerAdvPetExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvPetExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvPetExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvPetExpensesCalcs} prefix={modulesServices.module5Data.careerAdvPetExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvPetExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvCarExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvCarExpenses} prefix={modulesServices.module5Data.careerAdvCarExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvCarExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvCarExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvCarExpensesCalcs} prefix={modulesServices.module5Data.careerAdvCarExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvCarExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvHomeExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvHomeExpenses} prefix={modulesServices.module5Data.careerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvHomeExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvHomeExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvHomeExpensesCalcs} prefix={modulesServices.module5Data.careerAdvHomeExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvHomeExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvFinancingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvFinancingExpenses} prefix={modulesServices.module5Data.careerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvFinancingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvFinancingExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvFinancingExpensesCalcs} prefix={modulesServices.module5Data.careerAdvFinancingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvFinancingExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Annual Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvTotalAnnualExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvTotalAnnualExpenses} prefix={modulesServices.module5Data.careerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvTotalAnnualExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.careerAdvTotalAnnualExpensesCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.careerAdvTotalAnnualExpensesCalcs} prefix={modulesServices.module5Data.careerAdvTotalAnnualExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.careerAdvTotalAnnualExpensesCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Annual Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualNetIncomeCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualNetIncomeCareerAdv} prefix={modulesServices.module5Data.annualNetIncomeCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualNetIncomeCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualNetIncomeCareerAdvCalcs !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualNetIncomeCareerAdvCalcs} prefix={modulesServices.module5Data.annualNetIncomeCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualNetIncomeCareerAdvCalcs < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualRetirementContributionsCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualRetirementContributionsCareerAdv} prefix={modulesServices.module5Data.annualRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualRetirementContributionsCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.annualNetCashFlowCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.annualNetCashFlowCareerAdv} prefix={modulesServices.module5Data.annualNetCashFlowCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.annualNetCashFlowCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                            <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>
                                      {(modulesServices.module5Data.start_year > 0 && modulesServices.module5Data.end_year > 0) && (
                                        <span>
                                          {' '}
                                          Budget Summary in years
                                          {' '}
                                          {modulesServices.module5Data.start_year}
                                          {' '}
                                          to
                                          {' '}
                                          {modulesServices.module5Data.end_year}
                                        </span>
                                      )}
                                      {(modulesServices.module5Data.start_year === 0 || modulesServices.module5Data.end_year === 0) && (
                                        <span>Budget Summary</span>
                                      )}
                                      <div
                                        className={classes.infoIcon}
                                        data-for="investTooltip"
                                        data-tip="Net income is income that is left over after all expenses are paid. Positive net income allows you to make investments, contribute to charity, or save for a car and a home. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                      >
                                        <img src={infoIconWhite} alt="infoicon" />
                                      </div>
                                    </h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceIncomeEarned !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceIncomeEarned} prefix={modulesServices.module5Data.advanceIncomeEarned >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceIncomeEarned < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceEstimatedTaxesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceEstimatedTaxesPaid} prefix={modulesServices.module5Data.advanceEstimatedTaxesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceEstimatedTaxesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceLivingExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceLivingExpensesPaid} prefix={modulesServices.module5Data.advanceLivingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceLivingExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceHigherEducationExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceHigherEducationExpensesPaid} prefix={modulesServices.module5Data.advanceHigherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceHigherEducationExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceChildrenExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceChildrenExpensesPaid} prefix={modulesServices.module5Data.advanceChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceChildrenExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advancePetExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advancePetExpensesPaid} prefix={modulesServices.module5Data.advancePetExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advancePetExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceCarExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceCarExpensesPaid} prefix={modulesServices.module5Data.advanceCarExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceCarExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceRealEstateExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceRealEstateExpensesPaid} prefix={modulesServices.module5Data.advanceRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceRealEstateExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceFinancingExpensesPaid !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceFinancingExpensesPaid} prefix={modulesServices.module5Data.advanceFinancingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceFinancingExpensesPaid < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Total Expenses</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalExpensesCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalExpensesCareerAdv} prefix={modulesServices.module5Data.totalExpensesCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalExpensesCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Cash Flow before Retirement Contributions</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advanceNetIncome !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advanceNetIncome} prefix={modulesServices.module5Data.advanceNetIncome >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advanceNetIncome < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.finalRetirementContributionsCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.finalRetirementContributionsCareerAdv} prefix={modulesServices.module5Data.finalRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.finalRetirementContributionsCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.finalNetCashFlowCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.finalNetCashFlowCareerAdv} prefix={modulesServices.module5Data.finalNetCashFlowCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.finalNetCashFlowCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                        </Grid>

                        <ReactTooltip id="detailsTooltip17" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <Grid item sm={4} xs={4}>

                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>401(k) Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.contribute_401k === 'yes' ? (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.start_year}
                                            </strong>
                                          </div>
                                        )
                                          : (<div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance
                                            </strong>
                                          </div>
                                          )
                                        }

                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.beginningBalanceInYearNext !== undefined && modulesServices.module5Data.beginningBalanceInYearNext !== null && modulesServices.module5Data.beginningBalanceInYearNext !== '' ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.beginningBalanceInYearNext} prefix={modulesServices.module5Data.beginningBalanceInYearNext >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.beginningBalanceInYearNext < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.beginningBalanceInYearNextRoth !== undefined && modulesServices.module5Data.beginningBalanceInYearNextRoth !== null && modulesServices.module5Data.beginningBalanceInYearNextRoth !== '' ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.beginningBalanceInYearNextRoth} prefix={modulesServices.module5Data.beginningBalanceInYearNextRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.beginningBalanceInYearNextRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Personal Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.personalContributionAddCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.personalContributionAddCareerAdv} prefix={modulesServices.module5Data.personalContributionAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.personalContributionAddCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.personalContributionAetrFewYearsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.personalContributionAetrFewYearsRoth} prefix={modulesServices.module5Data.personalContributionAetrFewYearsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.personalContributionAetrFewYearsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Personal Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.earningsOnPersonalContributionsAfter !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.earningsOnPersonalContributionsAfter} prefix={modulesServices.module5Data.earningsOnPersonalContributionsAfter >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.earningsOnPersonalContributionsAfter < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.earningsOnPersonalContributionsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.earningsOnPersonalContributionsRoth} prefix={modulesServices.module5Data.earningsOnPersonalContributionsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.earningsOnPersonalContributionsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Personal Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalPersonalContributionsAndEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalPersonalContributionsAndEarnings} prefix={modulesServices.module5Data.totalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalPersonalContributionsAndEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth} prefix={modulesServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Employer Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.employerContributionAddCarrerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.employerContributionAddCarrerAdv} prefix={modulesServices.module5Data.employerContributionAddCarrerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.employerContributionAddCarrerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.employerContributionAfterFewYearsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.employerContributionAfterFewYearsRoth} prefix={modulesServices.module5Data.employerContributionAfterFewYearsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.employerContributionAfterFewYearsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Employer Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.earningsOnEmployerContributionsAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.earningsOnEmployerContributionsAdd} prefix={modulesServices.module5Data.earningsOnEmployerContributionsAdd >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.earningsOnEmployerContributionsAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.earningsOnEmployerContributionsAfterRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.earningsOnEmployerContributionsAfterRoth} prefix={modulesServices.module5Data.earningsOnEmployerContributionsAfterRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.earningsOnEmployerContributionsAfterRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Employer Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalEmployerContributionsAndEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalEmployerContributionsAndEarnings} prefix={modulesServices.module5Data.totalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalEmployerContributionsAndEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.totalEmployerContributionAndEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.totalEmployerContributionAndEarningsRoth} prefix={modulesServices.module5Data.totalEmployerContributionAndEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.totalEmployerContributionAndEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>401(k) Balance before Vesting Reductions</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.balanceBeforeVestingReductionsAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.balanceBeforeVestingReductionsAdd} prefix={modulesServices.module5Data.balanceBeforeVestingReductionsAdd >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.balanceBeforeVestingReductionsAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth} prefix={modulesServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.contribute_401k === 'yes' && (
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            % of Employer Contributions & Earnings Not Vested
                                          </div>
                                        )}
                                        {modulesServices.module5Data.contribute_401k !== 'yes' && (
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Vesting Reductions
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.employerContributionsAndEarningsNotVestedAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.employerContributionsAndEarningsNotVestedAdd} prefix={modulesServices.module5Data.employerContributionsAndEarningsNotVestedAdd >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.employerContributionsAndEarningsNotVestedAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip17" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.employerContributionsAndEarningsNotVestedRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.employerContributionsAndEarningsNotVestedRoth} prefix={modulesServices.module5Data.employerContributionsAndEarningsNotVestedRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.employerContributionsAndEarningsNotVestedRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.contribute_401k === 'yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.end_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module5Data.contribute_401k !== 'yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.endingBalanceInYearAddCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.endingBalanceInYearAddCareerAdv} prefix={modulesServices.module5Data.endingBalanceInYearAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.endingBalanceInYearAddCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.endingBalanceInyearLastRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.endingBalanceInyearLastRoth} prefix={modulesServices.module5Data.endingBalanceInyearLastRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.endingBalanceInyearLastRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <ReactTooltip id="detailsTooltip16" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Roth IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.roth_contribute === 'yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module5Data.roth_contribute !== 'yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.addCareerRothIRABeginningBalance !== undefined && modulesServices.module5Data.addCareerRothIRABeginningBalance < 0 && modulesServices.module5Data.addCareerRothIRABeginningBalance >= 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.addCareerRothIRABeginningBalance} prefix={modulesServices.module5Data.addCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.addCareerRothIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerRothIRABeginningBalanceIra !== undefined && modulesServices.module5Data.advCareerRothIRABeginningBalanceIra <= 0 && modulesServices.module5Data.advCareerRothIRABeginningBalanceIra > 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerRothIRABeginningBalanceIra} prefix={modulesServices.module5Data.advCareerRothIRABeginningBalanceIra >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerRothIRABeginningBalanceIra < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.addCareerRothIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.addCareerRothIRATotalContribution} prefix={modulesServices.module5Data.addCareerRothIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.addCareerRothIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerRothIRATotalContributionRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerRothIRATotalContributionRoth} prefix={modulesServices.module5Data.advCareerRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerRothIRATotalContributionRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip16" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.addCareerRothIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.addCareerRothIRATotalEarnings} prefix={modulesServices.module5Data.addCareerRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.addCareerRothIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerRothIRATotalEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerRothIRATotalEarningsRoth} prefix={modulesServices.module5Data.advCareerRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerRothIRATotalEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.roth_contribute === 'yes' ? (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )
                                          :
                                          (<div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance
                                            </strong>
                                          </div>
                                          )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.addCareerRothIRAEndingBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.addCareerRothIRAEndingBalance} prefix={modulesServices.module5Data.addCareerRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.addCareerRothIRAEndingBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerRothIRAEndingBalanceRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerRothIRAEndingBalanceRoth} prefix={modulesServices.module5Data.advCareerRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerRothIRAEndingBalanceRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>SEP IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance in Year
                                              {modulesServices.module5Data.start_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module5Data.ira_contribute === 'No' && modulesServices.module5Data.ira_contribute !== undefined && modulesServices.module5Data.ira_contribute !== null && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerSEPIRABeginningBalance !== undefined && modulesServices.module5Data.advCareerSEPIRABeginningBalance !== null && modulesServices.module5Data.advCareerSEPIRABeginningBalance !== '' ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerSEPIRABeginningBalance} prefix={modulesServices.module5Data.advCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerSEPIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerSEPIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerSEPIRATotalContribution} prefix={modulesServices.module5Data.advCareerSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerSEPIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerSEPIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerSEPIRATotalEarnings} prefix={modulesServices.module5Data.advCareerSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerSEPIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module5Data.ira_contribute === 'No' && modulesServices.module5Data.ira_contribute !== undefined && modulesServices.module5Data.ira_contribute !== null && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerEndingSEPIRABalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerEndingSEPIRABalance} prefix={modulesServices.module5Data.advCareerEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerEndingSEPIRABalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Pension Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.pension_contribute === 'yes' ? (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Pension Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.start_year}
                                            </strong>
                                          </div>
                                        )
                                        :
                                        ( <div className={classes.spanText}>
                                          <strong>
                                            Beginning Pension Balance
                                          </strong>
                                        </div>
                                      )}
                                       
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerPensionBeginningBalance !== undefined && modulesServices.module5Data.advCareerPensionBeginningBalance <= 0 && modulesServices.module5Data.advCareerPensionBeginningBalance > 0 ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerPensionBeginningBalance} prefix={modulesServices.module5Data.advCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerPensionBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Personal Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerPensionTotalPersonalContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerPensionTotalPersonalContributions} prefix={modulesServices.module5Data.advCareerPensionTotalPersonalContributions >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerPensionTotalPersonalContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Employer Contributions After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerPensionTotalEmployerContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerPensionTotalEmployerContributions} prefix={modulesServices.module5Data.advCareerPensionTotalEmployerContributions >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerPensionTotalEmployerContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module5Data.end_year - ((modulesServices.module5Data.end_year !== 0) ? modulesServices.module5Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerPensionTotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerPensionTotalEarnings} prefix={modulesServices.module5Data.advCareerPensionTotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerPensionTotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.pension_contribute === 'yes' ? (

                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Pension Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.end_year}
                                            </strong>
                                          </div>
                                        )
                                        :
                                        ( <div className={classes.spanText}>
                                          <strong>
                                            Ending Pension Balance
                                          </strong>
                                        </div>
                                      )}
                                       
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerEndingPensionBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerEndingPensionBalance} prefix={modulesServices.module5Data.advCareerEndingPensionBalance >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerEndingPensionBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>HSA Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.contribute_hsa_savings_account === 'Yes' ? (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Balance in Year
                                              {' '}
                                              {modulesServices.module5Data.start_year}s
                                            </strong>
                                          </div>
                                        )
                                          :
                                          (<div className={classes.spanText}>
                                            <strong>
                                              Beginning Balance
                                            </strong>
                                          </div>)
                                        }
                                        {/* {modulesServices.module5Data.contribute_hsa_savings_account === 'No' || modulesServices.module5Data.contribute_hsa_savings_account !== undefined || modulesServices.module5Data.contribute_hsa_savings_account !== null && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Balance
                                            </strong>
                                          </div>
                                        )} */}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerHsaBeginningBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerHsaBeginningBalanceSummary} prefix={modulesServices.module5Data.advCareerHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerHsaBeginningBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerHsaTotalContributionSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerHsaTotalContributionSummary} prefix={modulesServices.module5Data.advCareerHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerHsaTotalContributionSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerHsaTotalEarningsSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerHsaTotalEarningsSummary} prefix={modulesServices.module5Data.advCareerHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerHsaTotalEarningsSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module5Data.contribute_hsa_savings_account === 'Yes' ? (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                              {modulesServices.module5Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )
                                          :
                                          (<div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                            </strong>
                                          </div>)
                                        }
                                        {/* {modulesServices.module5Data.contribute_hsa_savings_account === 'No' || modulesServices.module5Data.contribute_hsa_savings_account !== undefined || modulesServices.module5Data.contribute_hsa_savings_account !== null && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )} */}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module5Data.advCareerHsaTotalEndingBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module5Data.advCareerHsaTotalEndingBalanceSummary} prefix={modulesServices.module5Data.advCareerHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module5Data.advCareerHsaTotalEndingBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* More Higher Education */}

        <div className={classes.tableResponsive} id="summary_table_more_edu">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion}  >
                      <h3 className={classNames(classes.moduleHeading, classes.moreHeader)}>More Higher Education</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Type of Higher Education</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module6Info.type_of_higher_education > 0 ? educationTypes[summaryTableData.module6Info.type_of_higher_education].label : 'More Higher Education'}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Name of More Higher Education</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module6Info.name_of_college !== undefined ? module6Info.name_of_college : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module6Info.start_year !== undefined ? module6Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>                                 
                                  <span className={classes.currencyContent}>{module6Info.graduation_year !== undefined ? module6Info.graduation_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Room & Board</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module6Info.room_board !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.room_board} prefix={module6Info.room_board >= 0 ? '$' : '($'} suffix={module6Info.room_board < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Tuition & Fees</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module6Info.tuition !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.tuition} prefix={module6Info.tuition >= 0 ? '$' : '($'} suffix={module6Info.tuition < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            {/* <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Total Student Loan Payments during
                                    {module6Info.type_of_higher_education > 0 ? educationTypes[module6Info.type_of_higher_education].lable : 'More Higher Education'}
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Existing Student Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Student Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div> */}
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Total Cost of Attendance </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreTotalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreTotalTuitionAndFees} prefix={module6Info.moreTotalTuitionAndFees >= 0 ? '$' : '($'} suffix={module6Info.moreTotalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreTotalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreTotalRoomAndBoard} prefix={module6Info.moreTotalRoomAndBoard >= 0 ? '$' : '($'} suffix={module6Info.moreTotalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherOnCampusLivingExpenses} prefix={module6Info.moreHigherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherOffCampusLivingExpenses} prefix={module6Info.moreHigherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        Total Student Loan Payments during
                                        {module6Info.type_of_higher_education > 0 ? module6Info.type_of_higher_education : 'More Higher Education'}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalStudentsLoanPayments} prefix={module6Info.moreHigherTotalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Cost of Attendance</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHighertTotalCostOfAttendance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHighertTotalCostOfAttendance} prefix={module6Info.moreHighertTotalCostOfAttendance >= 0 ? '$' : '($'} suffix={module6Info.moreHighertTotalCostOfAttendance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Total Expenses </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreTotalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreTotalTuitionAndFees} prefix={module6Info.moreTotalTuitionAndFees >= 0 ? '$' : '($'} suffix={module6Info.moreTotalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreTotalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreTotalRoomAndBoard} prefix={module6Info.moreTotalRoomAndBoard >= 0 ? '$' : '($'} suffix={module6Info.moreTotalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherOnCampusLivingExpenses} prefix={module6Info.moreHigherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherOffCampusLivingExpenses} prefix={module6Info.moreHigherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        Total Student Loan Payments during
                                        {module6Info.type_of_higher_education > 0 ? module6Info.type_of_higher_education : 'More Higher Education'}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalStudentsLoanPayments} prefix={module6Info.moreHigherTotalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Loan Payments on Other Debt</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherOtherLoanPaymentsonOtherDebt !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherOtherLoanPaymentsonOtherDebt} prefix={module6Info.moreHigherOtherLoanPaymentsonOtherDebt >= 0 ? '$' : '($'} suffix={module6Info.moreHigherOtherLoanPaymentsonOtherDebt < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Car Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherCarExpense !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherCarExpense} prefix={module6Info.moreHigherCarExpense >= 0 ? '$' : '($'} suffix={module6Info.moreHigherCarExpense < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Real Estate Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherRealEstateExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherRealEstateExpenses} prefix={module6Info.moreHigherRealEstateExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherRealEstateExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Children and Pet Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherChildrenAndPetExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherChildrenAndPetExpenses} prefix={module6Info.moreHigherChildrenAndPetExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherChildrenAndPetExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalExpenses} prefix={module6Info.moreHigherTotalExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Income Needed to Support Student Loans Analysis&nbsp;
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Balance (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalStudentLoanBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalStudentLoanBalance} prefix={module6Info.moreHigherTotalStudentLoanBalance >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalStudentLoanBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Monthly Payment (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalMonthlyPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalMonthlyPayment} prefix={module6Info.moreHigherTotalMonthlyPayment >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalMonthlyPayment < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Annual Paid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalAnnualPaymentPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalAnnualPaymentPaid} prefix={module6Info.moreHigherTotalAnnualPaymentPaid >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalAnnualPaymentPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Divided by 12.5%</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {(module6Info.start_year > 0 && module6Info.graduation_year > 0) ? 12.5 : 0}
                                        %
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Income Needed to Support Student Loans</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherIncomeNeedToSupportLoans !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherIncomeNeedToSupportLoans} prefix={module6Info.moreHigherIncomeNeedToSupportLoans >= 0 ? '$' : '($'} suffix={module6Info.moreHigherIncomeNeedToSupportLoans < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Investment Analysis&nbsp;
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Scholarships</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisScholships !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisScholships} prefix={module6Info.moreHigherinvestmentAnalysisScholships >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisScholships < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Grants</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisGrants !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisGrants} prefix={module6Info.moreHigherinvestmentAnalysisGrants >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisGrants < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Financial Aid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisFinancialAid !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisFinancialAid} prefix={module6Info.moreHigherinvestmentAnalysisFinancialAid >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisFinancialAid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Income Earned after Taxes</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisIncomeEarned !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisIncomeEarned} prefix={module6Info.moreHigherinvestmentAnalysisIncomeEarned >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisIncomeEarned < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Family Contribution</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisFamilyContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisFamilyContribution} prefix={module6Info.moreHigherinvestmentAnalysisFamilyContribution >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisFamilyContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Federal Subsidized Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreFedSubBeginningBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreFedSubBeginningBalance} prefix={module6Info.moreFedSubBeginningBalance >= 0 ? '$' : '($'} suffix={module6Info.moreFedSubBeginningBalance < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Federal Unsubsidized Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreFedUnSubPrincipal !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreFedUnSubPrincipal} prefix={module6Info.moreFedUnSubPrincipal >= 0 ? '$' : '($'} suffix={module6Info.moreFedUnSubPrincipal < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Private Student Loan</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.morePrivatePrincipal !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.morePrivatePrincipal} prefix={module6Info.morePrivatePrincipal >= 0 ? '$' : '($'} suffix={module6Info.morePrivatePrincipal < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Origination Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreSummaryLoanPoints !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreSummaryLoanPoints} prefix={module6Info.moreSummaryLoanPoints >= 0 ? '$' : '($'} suffix={module6Info.moreSummaryLoanPoints < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Financing Sources</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisTotalCashAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisTotalCashAvailable} prefix={module6Info.moreHigherinvestmentAnalysisTotalCashAvailable >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisTotalCashAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherTotalExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherTotalExpenses} prefix={module6Info.moreHigherTotalExpenses >= 0 ? '$' : '($'} suffix={module6Info.moreHigherTotalExpenses < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Cash Left Over</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classNames(classes.noInputText, classes.spanBlueText)}>
                                        {(module6Info.moreHigherinvestmentAnalysisTotalCashAvailable !== undefined && module6Info.moreHigherTotalExpenses !== undefined) ? (
                                          <NumberFormat
                                            decimalScale={0}
                                            fixedDecimalScale
                                            className={classes.formInput}
                                            displayType="text"
                                            allowNegative={false}
                                            thousandSeparator
                                            value={module6Info.moreHigherinvestmentAnalysisTotalCashAvailable + module6Info.moreHigherTotalExpenses}
                                            prefix={(module6Info.moreHigherinvestmentAnalysisTotalCashAvailable + module6Info.moreHigherTotalExpenses) >= 0 ? '$' : '($'}
                                            suffix={(module6Info.moreHigherinvestmentAnalysisTotalCashAvailable + module6Info.moreHigherTotalExpenses) < 0 && ')'}
                                          />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cash and Investments Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module6Info.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module6Info.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable} prefix={module6Info.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable >= 0 ? '$' : '($'} suffix={module6Info.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} spacing={0} xs={6} >
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} spacing={0} xs={6} >
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Additional Career Advancement */}

        <div className={classes.tableResponsive} id="summary_table_add_career">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.addHigherHeader)}>Additional Career Advancement</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Career</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Job Title</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.occupation !== undefined ? module8Info.occupation : ""}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.start_year !== undefined ? module8Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.end_year !== undefined ? module8Info.end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={4} className={classes.noSpace}>
                              <Grid item sm={6} xs={6} className={classes.noSpace}>
                                <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                  <div className={classes.spanText}><strong>Total Pre-Tax Base Income</strong></div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} className={classes.noSpace}>
                                <div className={classes.inputRight}>
                                  <span className={classes.noInputText}>
                                    {module8Info.jobtotalPreTaxExpected_val !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module8Info.jobtotalPreTaxExpected_val} prefix={module8Info.jobtotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={module8Info.jobtotalPreTaxExpected_val < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Supplementary Income</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.second_start_year !== undefined ? module8Info.second_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.second_end_year !== undefined ? module8Info.second_end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <div className={classes.insideSpan}>
                                  <div className={classes.spanText}>Annual Income</div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.second_yearly_income !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.second_yearly_income} prefix={module8Info.second_yearly_income >= 0 ? '$' : '($'} suffix={module8Info.second_yearly_income < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>401(k)</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a 401(k)?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.contribute_401k !== undefined ? module8Info.contribute_401k : "NO"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.base_income !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.base_income} prefix={module8Info.base_income < 0 && '('} suffix={module8Info.base_income >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Rate on Personal Contribution</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.employer_matching_rate !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.employer_matching_rate} prefix={module8Info.employer_matching_rate < 0 && '('} suffix={module8Info.employer_matching_rate >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Limit as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.employer_matching_limit !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.employer_matching_limit} prefix={module8Info.employer_matching_limit < 0 && '('} suffix={module8Info.employer_matching_limit >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Roth IRA</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Roth IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.roth_contribute !== undefined ? module8Info.roth_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.roth_personal_contribution !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.roth_personal_contribution} prefix={module8Info.roth_personal_contribution < 0 && '('} suffix={module8Info.roth_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>SEP IRA</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a SEP IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.ira_contribute !== undefined ? module8Info.ira_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income (25% Limit)</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.ira_personal_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.ira_personal_contribution} prefix={module8Info.ira_personal_contribution < 0 && '('} suffix={module8Info.ira_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Pension</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Pension?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module8Info.pension_contribute !== undefined ? module8Info.pension_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.pension_employer_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.pension_employer_contribution} prefix={module8Info.pension_employer_contribution < 0 && '('} suffix={module8Info.pension_employer_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module8Info.pension_personal_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module8Info.pension_personal_contribution} prefix={module8Info.pension_personal_contribution < 0 && '('} suffix={module8Info.pension_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Monthly Budget</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Monthly Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.monthlyIncomeFromAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.monthlyIncomeFromAddCareerAdv} prefix={modulesServices.module8Data.monthlyIncomeFromAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.monthlyIncomeFromAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Monthly Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.otherMonthlyIncomeAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.otherMonthlyIncomeAddCareerAdv} prefix={modulesServices.module8Data.otherMonthlyIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.otherMonthlyIncomeAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Monthly Income</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalMonthlyIncomeAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalMonthlyIncomeAddCareerAdv} prefix={modulesServices.module8Data.totalMonthlyIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalMonthlyIncomeAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalTaxesPaidAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalTaxesPaidAddCareerAdv} prefix={modulesServices.module8Data.totalTaxesPaidAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalTaxesPaidAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.livingExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.livingExpensesAddCareerAdv} prefix={modulesServices.module8Data.livingExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.livingExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.higherEducationExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.higherEducationExpensesAddCareerAdv} prefix={modulesServices.module8Data.higherEducationExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.higherEducationExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.childrenExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.childrenExpensesAddCareerAdv} prefix={modulesServices.module8Data.childrenExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.childrenExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.petExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.petExpensesAddCareerAdv} prefix={modulesServices.module8Data.petExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.petExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.carExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.carExpensesAddCareerAdv} prefix={modulesServices.module8Data.carExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.carExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.realEstateExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.realEstateExpensesAddCareerAdv} prefix={modulesServices.module8Data.realEstateExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.realEstateExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.internetExpensesAddCareerPathAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.internetExpensesAddCareerPathAdv} prefix={modulesServices.module8Data.internetExpensesAddCareerPathAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.internetExpensesAddCareerPathAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalMonthlyExpensesAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalMonthlyExpensesAddCareerAdv} prefix={modulesServices.module8Data.totalMonthlyExpensesAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalMonthlyExpensesAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Monthly Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.monthlyNetIncomeAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.monthlyNetIncomeAddCareerAdv} prefix={modulesServices.module8Data.monthlyNetIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.monthlyNetIncomeAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.retirementContributionsAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.retirementContributionsAddCareerAdv} prefix={modulesServices.module8Data.retirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.retirementContributionsAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.monthlyNetCashFlowAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.monthlyNetCashFlowAddCareerAdv} prefix={modulesServices.module8Data.monthlyNetCashFlowAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.monthlyNetCashFlowAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Annual Budget</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        &nbsp;
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        &nbsp;
                                      </Grid>
                                      <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                        {modulesServices.module8Data.addCareerAdvIconCalcs !== '' && (
                                          <div className={classes.formMainRow}>
                                            <div className={classes.labelText}>
                                              <div className={classes.calcText}>
                                                <span className={classes.familyImage}>
                                                  <img src={familyImage} alt="familyImage" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)} />
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            <u>
                                              Year
                                              {' '}
                                              {modulesServices.module8Data.start_year}
                                              {' '}

                                            </u>
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(modulesServices.module8Data.addCareerAdvIconCalcs !== 0 && modulesServices.module8Data.addCareerAdvIconCalcs !== undefined) && (
                                              <span>
                                                {modulesServices.module8Data.addCareerAdvIconCalcs}
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalIncomeAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalIncomeAddCareerAdv} prefix={modulesServices.module8Data.totalIncomeAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalIncomeAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalIncomeAddCareerAdvCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalIncomeAddCareerAdvCalcs} prefix={modulesServices.module8Data.totalIncomeAddCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalIncomeAddCareerAdvCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.annualTotalTaxesPaidAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.annualTotalTaxesPaidAddCareerAdv} prefix={modulesServices.module8Data.annualTotalTaxesPaidAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.annualTotalTaxesPaidAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.annualTotalExpensesAddCareerAdvCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.annualTotalExpensesAddCareerAdvCalcs} prefix={modulesServices.module8Data.annualTotalExpensesAddCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.annualTotalExpensesAddCareerAdvCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvLivingExpenses} prefix={modulesServices.module8Data.addCareerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvLivingExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvLivingExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvLivingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvLivingExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvHigherEducationExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvHigherEducationExpenses} prefix={modulesServices.module8Data.addCareerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvHigherEducationExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvHigherEducationExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvHigherEducationExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvHigherEducationExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvHigherEducationExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvChildrenExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvChildrenExpenses} prefix={modulesServices.module8Data.addCareerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvChildrenExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvChildrenExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvChildrenExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvChildrenExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvChildrenExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvPetExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvPetExpenses} prefix={modulesServices.module8Data.addCareerAdvPetExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvPetExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvPetExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvPetExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvPetExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvPetExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvCarExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvCarExpenses} prefix={modulesServices.module8Data.addCareerAdvCarExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvCarExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvCarExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvCarExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvCarExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvCarExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvHomeExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvHomeExpenses} prefix={modulesServices.module8Data.addCareerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvHomeExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvHomeExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvHomeExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvHomeExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvHomeExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvFinancingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvFinancingExpenses} prefix={modulesServices.module8Data.addCareerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvFinancingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvFinancingExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvFinancingExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvFinancingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvFinancingExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvTotalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvTotalAnnualExpenses} prefix={modulesServices.module8Data.addCareerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvTotalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvTotalAnnualExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvTotalAnnualExpensesCalcs} prefix={modulesServices.module8Data.addCareerAdvTotalAnnualExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvTotalAnnualExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Annual Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.addCareerAdvAnnualNetIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerAdvAnnualNetIncome} prefix={modulesServices.module8Data.addCareerAdvAnnualNetIncome >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerAdvAnnualNetIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.AddCareerAdvAnnualNetIncomeCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.AddCareerAdvAnnualNetIncomeCalcs} prefix={modulesServices.module8Data.AddCareerAdvAnnualNetIncomeCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.AddCareerAdvAnnualNetIncomeCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.annualRetirementContributionsAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.annualRetirementContributionsAddCareerAdv} prefix={modulesServices.module8Data.annualRetirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.annualRetirementContributionsAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.annualNetCashFlowAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.annualNetCashFlowAddCareerAdv} prefix={modulesServices.module8Data.annualNetCashFlowAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.annualNetCashFlowAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        {(modulesServices.module8Data.start_year > 0 && modulesServices.module8Data.end_year > 0) && (
                                          <span>
                                            {' '}
                                            Budget Summary in years
                                            {' '}
                                            {modulesServices.module8Data.start_year}
                                            {' '}
                                            to
                                            {' '}
                                            {modulesServices.module8Data.end_year}
                                          </span>
                                        )}
                                        {(modulesServices.module8Data.start_year === 0 || modulesServices.module8Data.end_year === 0) && (
                                          <span>Budget Summary</span>
                                        )}
                                        <div
                                          className={classes.infoIcon}
                                          data-for="investTooltip"
                                          data-tip="Net income is income that is left over after all expenses are paid. Positive net income allows you to make investments, contribute to charity, or save for a car and a home. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                        >
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherincomeEarned !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherincomeEarned} prefix={modulesServices.module8Data.moreHigherincomeEarned >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherincomeEarned < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherEstimatedTaxesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherEstimatedTaxesPaid} prefix={modulesServices.module8Data.moreHigherEstimatedTaxesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherEstimatedTaxesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherlivingExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherlivingExpensesPaid} prefix={modulesServices.module8Data.moreHigherlivingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherlivingExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherEducationExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherEducationExpensesPaid} prefix={modulesServices.module8Data.moreHigherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherEducationExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreChildrenExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreChildrenExpensesPaid} prefix={modulesServices.module8Data.moreChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreChildrenExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.morePetExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.morePetExpensesPaid} prefix={modulesServices.module8Data.morePetExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.morePetExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherCarExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherCarExpensesPaid} prefix={modulesServices.module8Data.moreHigherCarExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherCarExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherRealEstateExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherRealEstateExpensesPaid} prefix={modulesServices.module8Data.moreHigherRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherRealEstateExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherfinancingExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherfinancingExpensesPaid} prefix={modulesServices.module8Data.moreHigherfinancingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherfinancingExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.totalExpensesPaidFromAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalExpensesPaidFromAddCareerAdv} prefix={modulesServices.module8Data.totalExpensesPaidFromAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalExpensesPaidFromAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.moreHigherNetIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.moreHigherNetIncome} prefix={modulesServices.module8Data.moreHigherNetIncome >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.moreHigherNetIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.finalRetirementContributionsAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.finalRetirementContributionsAddCareerAdv} prefix={modulesServices.module8Data.finalRetirementContributionsAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.finalRetirementContributionsAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module8Data.finalNetCashFlowAddCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.finalNetCashFlowAddCareerAdv} prefix={modulesServices.module8Data.finalNetCashFlowAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.finalNetCashFlowAddCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <ReactTooltip id="detailsTooltip15" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <ReactTooltip id="detailsTooltip14" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <Grid item sm={4} xs={4}>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>401(k) Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.contribute_401k === 'Yes' && (

                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.contribute_401k === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning 401(k) Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.beginningBalanceYearThird !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.beginningBalanceYearThird} prefix={modulesServices.module8Data.beginningBalanceYearThird >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.beginningBalanceYearThird < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.beginningBalanceYearThirdRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.beginningBalanceYearThirdRoth} prefix={modulesServices.module8Data.beginningBalanceYearThirdRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.beginningBalanceYearThirdRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Personal Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.personalContributionAddCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.personalContributionAddCareerAdv} prefix={modulesServices.module8Data.personalContributionAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.personalContributionAddCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.personalContributionAddCareerAdvRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.personalContributionAddCareerAdvRoth} prefix={modulesServices.module8Data.personalContributionAddCareerAdvRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.personalContributionAddCareerAdvRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Personal Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.earningsOnPersonalContributionsAfter !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.earningsOnPersonalContributionsAfter} prefix={modulesServices.module8Data.earningsOnPersonalContributionsAfter >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.earningsOnPersonalContributionsAfter < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.earningsOnPersonalContributionsAfterRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.earningsOnPersonalContributionsAfterRoth} prefix={modulesServices.module8Data.earningsOnPersonalContributionsAfterRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.earningsOnPersonalContributionsAfterRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Personal Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.totalPersonalContributionsAndEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalPersonalContributionsAndEarnings} prefix={modulesServices.module8Data.totalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalPersonalContributionsAndEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.totalPersonalContributionsAndEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalPersonalContributionsAndEarningsRoth} prefix={modulesServices.module8Data.totalPersonalContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalPersonalContributionsAndEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Employer Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.employerContributionAddCarrerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.employerContributionAddCarrerAdv} prefix={modulesServices.module8Data.employerContributionAddCarrerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.employerContributionAddCarrerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.employerContributionAddCarrerAdvRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.employerContributionAddCarrerAdvRoth} prefix={modulesServices.module8Data.employerContributionAddCarrerAdvRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.employerContributionAddCarrerAdvRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Earnings on Employer Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.earningsOnEmployerContributionsAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.earningsOnEmployerContributionsAdd} prefix={modulesServices.module8Data.earningsOnEmployerContributionsAdd >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.earningsOnEmployerContributionsAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.earningsOnEmployerContributionsAddRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.earningsOnEmployerContributionsAddRoth} prefix={modulesServices.module8Data.earningsOnEmployerContributionsAddRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.earningsOnEmployerContributionsAddRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>Total Employer Contributions & Earnings</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.totalEmployerContributionsAndEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalEmployerContributionsAndEarnings} prefix={modulesServices.module8Data.totalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalEmployerContributionsAndEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.totalEmployerContributionsAndEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.totalEmployerContributionsAndEarningsRoth} prefix={modulesServices.module8Data.totalEmployerContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.totalEmployerContributionsAndEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>
                                          <strong>401(k) Balance before Vesting Reductions</strong>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.balanceBeforeVestingReductionsAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.balanceBeforeVestingReductionsAdd} prefix={modulesServices.module8Data.balanceBeforeVestingReductionsAdd >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.balanceBeforeVestingReductionsAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.balanceBeforeVestingReductionsAddRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.balanceBeforeVestingReductionsAddRoth} prefix={modulesServices.module8Data.balanceBeforeVestingReductionsAddRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.balanceBeforeVestingReductionsAddRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.contribute_401k === 'Yes' && (
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            % of Employer Contributions & Earnings Not Vested
                                          </div>
                                        )}
                                        {modulesServices.module8Data.contribute_401k === 'No' && (
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            % of Employer Contributions & Earnings Not Vested
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAdd} prefix={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAdd >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAdd < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip15" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>

                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth} prefix={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip14" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.contribute_401k === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.end_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.contribute_401k === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending 401(k) Balance

                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.endingBalanceInYearAddCareerAdv !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.endingBalanceInYearAddCareerAdv} prefix={modulesServices.module8Data.endingBalanceInYearAddCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.endingBalanceInYearAddCareerAdv < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.endingBalanceInYearAddCareerAdvRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.endingBalanceInYearAddCareerAdvRoth} prefix={modulesServices.module8Data.endingBalanceInYearAddCareerAdvRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.endingBalanceInYearAddCareerAdvRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <ReactTooltip id="detailsTooltip13" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Roth IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.roth_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.roth_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Roth IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRABeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRABeginningBalance} prefix={modulesServices.module8Data.addCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRABeginningBalanceRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRABeginningBalanceRoth} prefix={modulesServices.module8Data.addCareerRothIRABeginningBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRABeginningBalanceRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRATotalContribution} prefix={modulesServices.module8Data.addCareerRothIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRATotalContributionRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRATotalContributionRoth} prefix={modulesServices.module8Data.addCareerRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRATotalContributionRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip13" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRATotalEarnings} prefix={modulesServices.module8Data.addCareerRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRATotalEarningsRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRATotalEarningsRoth} prefix={modulesServices.module8Data.addCareerRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRATotalEarningsRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.roth_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.roth_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Roth IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRAEndingBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRAEndingBalance} prefix={modulesServices.module8Data.addCareerRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRAEndingBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerRothIRAEndingBalanceRoth !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerRothIRAEndingBalanceRoth} prefix={modulesServices.module8Data.addCareerRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerRothIRAEndingBalanceRoth < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>SEP IRA Retirement Account Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance in Year
                                              {modulesServices.module8Data.start_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning SEP IRA Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerSEPIRABeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerSEPIRABeginningBalance} prefix={modulesServices.module8Data.addCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerSEPIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerSEPIRATotalContribution !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerSEPIRATotalContribution} prefix={modulesServices.module8Data.addCareerSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerSEPIRATotalContribution < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerSEPIRATotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerSEPIRATotalEarnings} prefix={modulesServices.module8Data.addCareerSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerSEPIRATotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending SEP IRA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerEndingSEPIRABalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerEndingSEPIRABalance} prefix={modulesServices.module8Data.addCareerEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerEndingSEPIRABalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>Pension Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.pension_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Pension Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.start_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.pension_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Beginning Pension Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerPensionBeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerPensionBeginningBalance} prefix={modulesServices.module8Data.addCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerPensionBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Personal Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerPensionTotalEmployerContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerPensionTotalEmployerContributions} prefix={modulesServices.module8Data.addCareerPensionTotalEmployerContributions >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerPensionTotalEmployerContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Employer Contributions After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerPensionTotalPersonalContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerPensionTotalPersonalContributions} prefix={modulesServices.module8Data.addCareerPensionTotalPersonalContributions >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerPensionTotalPersonalContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings After
                                          {' '}
                                          {modulesServices.module8Data.end_year - ((modulesServices.module8Data.end_year !== 0) ? modulesServices.module8Data.start_year + 1 : 0)}
                                          {' '}
                                          Years
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerPensionTotalEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerPensionTotalEarnings} prefix={modulesServices.module8Data.addCareerPensionTotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerPensionTotalEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.pension_contribute === 'Yes' && (

                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Pension Balance in Year
                                              {' '}
                                              {modulesServices.module8Data.end_year}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.pension_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending Pension Balance
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.addCareerEndingPensionBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.addCareerEndingPensionBalance} prefix={modulesServices.module8Data.addCareerEndingPensionBalance >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.addCareerEndingPensionBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classes.analysisBlock}>
                                    <h3>HSA Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        Beginning HSA Balance
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.additionalCareerHsaBeginningBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.additionalCareerHsaBeginningBalanceSummary} prefix={modulesServices.module8Data.additionalCareerHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.additionalCareerHsaBeginningBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Contributions
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.additionalCareerHsaTotalContributionSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.additionalCareerHsaTotalContributionSummary} prefix={modulesServices.module8Data.additionalCareerHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.additionalCareerHsaTotalContributionSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                          Total Earnings
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.additionalCareerHsaTotalEarningsSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.additionalCareerHsaTotalEarningsSummary} prefix={modulesServices.module8Data.additionalCareerHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.additionalCareerHsaTotalEarningsSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6}>
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        {modulesServices.module8Data.ira_contribute === 'Yes' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                              {modulesServices.module8Data.end_year}
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                        {modulesServices.module8Data.ira_contribute === 'No' && (
                                          <div className={classes.spanText}>
                                            <strong>
                                              Ending HSA Balance
                                              {' '}
                                            </strong>
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module8Data.additionalCareerHsaTotalEndingBalanceSummary !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module8Data.additionalCareerHsaTotalEndingBalanceSummary} prefix={modulesServices.module8Data.additionalCareerHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module8Data.additionalCareerHsaTotalEndingBalanceSummary < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={2} className={classes.analysisSection}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </div>

                        </Grid>

                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Investments */}

        <div className={classes.tableResponsive} id="summary_table_inv">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion}  >
                      <h3 className={classNames(classes.moduleHeading, classes.invHeader)}>Investments</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Short-Term Investments</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module9Info.short_term_start_year !== undefined ? module9Info.short_term_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Contribution</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module9Info.short_term_start_year !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module9Info.short_term_start_year} prefix={module9Info.short_term_start_year >= 0 ? '$' : '($'} suffix={module9Info.short_term_start_year < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Max Short-Term Investments Balance before Long-Term Investments</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module9Info.short_term_max_sti_balance_before_lt_investment !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module9Info.short_term_max_sti_balance_before_lt_investment} prefix={module9Info.short_term_max_sti_balance_before_lt_investment >= 0 ? '$' : '($'} suffix={module9Info.short_term_max_sti_balance_before_lt_investment < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Expected Pre-Tax Return</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module9Info.short_term_annual_expected_return_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module9Info.short_term_annual_expected_return_in_percentage} prefix={module9Info.short_term_annual_expected_return_in_percentage >= 0 ? '$' : '($'} suffix={module9Info.short_term_annual_expected_return_in_percentage < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Long-Term Investments</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module9Info.long_term_start_year !== undefined ? module9Info.long_term_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Contribution</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module9Info.long_term_annual_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module9Info.long_term_annual_contribution} prefix={module9Info.long_term_annual_contribution >= 0 ? '$' : '($'} suffix={module9Info.long_term_annual_contribution < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Expected Pre-Tax Return</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module9Info.long_term_annual_expected_return_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module9Info.long_term_annual_expected_return_in_percentage} prefix={module9Info.long_term_annual_expected_return_in_percentage >= 0 ? '$' : '($'} suffix={module9Info.long_term_annual_expected_return_in_percentage < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                    <h3>Short-Term Investments Summary </h3>
                                    {/* <div><Button onClick={() => { setOpenWorkSheet('short') }} className={classes.showWorksheet}>SHOW WORKSHEET</Button></div> */}
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}><strong>Short-Term Investments Period</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.inputRight, classes.investText)}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            {modulesServices.module9Data.shortTermInvestmentsPeriod !== undefined ? modulesServices.module9Data.shortTermInvestmentsPeriod : 0}

                                          </u>
                                          &nbsp;&nbsp;
                                        </span>
                                        <span className={classes.noInputTextNew}>
                                          {modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />
                                          ) : ('0')}
                                          {' '}
                                          Years
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Beginning Short-Term Investments Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.shortTermSummaryBeginningBalance <= 0 && modulesServices.module9Data.shortTermSummaryBeginningBalance > 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryBeginningBalance} prefix={modulesServices.module9Data.shortTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel1}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryContributions} prefix={modulesServices.module9Data.shortTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip12" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>

                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel4}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryEarnings} prefix={modulesServices.module9Data.shortTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip12" data-tip="To see how earnings are calculated each year, click on the 'Show Table' button above to see each year in detail.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Short-Term Investments Balance before Transfers and Withdrawals</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip11" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel2}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryExcessCash !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryExcessCash} prefix={modulesServices.module9Data.shortTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryExcessCash < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip11" data-tip="Once your cash balance is greater than the max cash balance that you inputted in the Financial Independence module, the FC will automatically move the excess cash to short-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of excess cash.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip10" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>

                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel5}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryCashWithDrawn !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.shortTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashWithDrawn < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div
                                          className={classes.infoIcon}
                                          data-for="detailsTooltip10"
                                          data-tip="If your cash balance goes negative in any year, the FC will automatically withdraw cash from your short-term investments at the end of the year to try and bring you back to cash flow positive.
                                          Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of cash need. Click on the 'Show Table' button above to see each year in detail."
                                        >
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip9" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>

                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel6}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryCashDeposited !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashDeposited} prefix={modulesServices.module9Data.shortTermSummaryCashDeposited >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashDeposited < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip9" data-tip="Once your short-term investments are greater than the max short-term investments balance that you inputted above, the FC will automatically move the excess amount to long-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Ending Short-Term Investments Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.shortTermSummaryEndingBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.shortTermSummaryEndingBalance} prefix={modulesServices.module9Data.shortTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEndingBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                    <h3>Long-Term Investments Summary</h3>
                                    {/* <div><Button onClick={() => { setOpenWorkSheet('long') }} className={classes.showWorksheet}>SHOW WORKSHEET</Button></div> */}
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}><strong>Long-Term Investments Period</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.inputRight, classes.investText)}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            {modulesServices.module9Data.longTermInvestmentsPeriod !== undefined ? modulesServices.module9Data.longTermInvestmentsPeriod : 0}

                                          </u>
                                          &nbsp;&nbsp;
                                        </span>
                                        <span className={classes.noInputTextNew}>
                                          {modulesServices.module9Data.longTermInvestmentsPeriodYears !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" value={modulesServices.module9Data.longTermInvestmentsPeriodYears} />
                                          ) : ('0')}
                                          {' '}
                                          Years
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Beginning Long-Term Investments Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.longTermSummaryBeginningBalance <= 0 && modulesServices.module9Data.longTermSummaryBeginningBalance > 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryBeginningBalance} prefix={modulesServices.module9Data.longTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel1}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryContributions !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryContributions} prefix={modulesServices.module9Data.longTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryContributions < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip8" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel4}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >

                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryEarnings !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryEarnings} prefix={modulesServices.module9Data.longTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEarnings < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip8" data-tip="To see how earnings are calculated each year, click on the 'Show Table' button above to see each year in detail.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Long-Term Investments Balance before Transfers and Withdrawals</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip7" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>

                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel2}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryExcessCash !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryExcessCash} prefix={modulesServices.module9Data.longTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryExcessCash < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip7" data-tip="Once your short-term investments are greater than the max short-term investments balance that you inputted above, the FC will automatically move the excess amount to long-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <ReactTooltip id="detailsTooltip6" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel5}</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryCashWithDrawn !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.longTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryCashWithDrawn < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                        <div
                                          className={classes.infoIcon}
                                          data-for="detailsTooltip6"
                                          data-tip="If your cash balance goes negative in any year, the FC will automatically withdraw cash from your
                                          long-term investments at the end of the year to try and bring you back to cash flow positive. Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of cash need. Click on the 'Show Table' button above to see each year in detail."
                                        >
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Ending Long-Term Investments Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.longTermSummaryEndingBalance !== undefined && modulesServices.module9Data.longTermSummaryEndingBalance <= 0 && modulesServices.module9Data.longTermSummaryEndingBalance > 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermSummaryEndingBalance} prefix={modulesServices.module9Data.longTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEndingBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                    <h3>529 Plan Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}><strong>529 Investments Period</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.inputRight, classes.investText)}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            {modulesServices.module9Data.longTermInvestmentsPeriod529 !== undefined ? modulesServices.module9Data.longTermInvestmentsPeriod529 : 0}
                                            {/* <span>years</span> */}
                                          </u>
                                          &nbsp;&nbsp;
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Beginning 529 Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.beginning529BalanceAnalysis !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.beginning529BalanceAnalysis} prefix={modulesServices.module9Data.beginning529BalanceAnalysis >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.beginning529BalanceAnalysis < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Total Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.totalContribution529 !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.totalContribution529} prefix={modulesServices.module9Data.totalContribution529 >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.totalContribution529 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Total Earnings</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.totalEarnings529 !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.totalEarnings529} prefix={modulesServices.module9Data.totalEarnings529 >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.totalEarnings529 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Ending 529 Balance</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.endingBalance529 !== undefined && modulesServices.module9Data.endingBalance529 > 0 && modulesServices.module9Data.endingBalance529 <= 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.endingBalance529} prefix={modulesServices.module9Data.endingBalance529 >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.endingBalance529 < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                              </div>
                            </Grid>
                          </div>
                          <div className={classes.moduleRow}>
                            <Grid container className={classes.analysisSection}>
                              <div className={classes.analysisPortion}>
                                <div className={classes.analysisHeading}>
                                  <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                    <h3>Life Insurance Summary</h3>
                                  </div>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}><strong>Life Insurance Period</strong></div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.inputRight, classes.investText)}>
                                        <span className={classes.noInputText}>
                                          <u>
                                            {modulesServices.module9Data.longTermInvestmentsPeriodLife !== undefined ? modulesServices.module9Data.longTermInvestmentsPeriodLife : 0}

                                          </u>
                                          &nbsp;&nbsp;
                                        </span>
                                        {/* <span className={classes.noInputTextNew}>
                                          {modulesServices.module9Data.longTermInvestmentsPeriodYearsLife !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.longTermInvestmentsPeriodYearsLife} prefix={modulesServices.module9Data.longTermInvestmentsPeriodYearsLife >= 0 ? '' : ''} suffix={modulesServices.module9Data.longTermInvestmentsPeriodYearsLife < 0 && 'years'} />
                                          ) : ('0')}
                                        </span> */}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Beginning Life Insurance Cash Value</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.beginningLifeInsBalanceAnalysis !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.beginningLifeInsBalanceAnalysis} prefix={modulesServices.module9Data.beginningLifeInsBalanceAnalysis >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.beginningLifeInsBalanceAnalysis < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Total Contributions</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.totalContributionLifeIns !== undefined ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.totalContributionLifeIns} prefix={modulesServices.module9Data.totalContributionLifeIns >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.totalContributionLifeIns < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Total Earnings</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.totalEarningsLifeIns !== undefined && modulesServices.module9Data.totalEarningsLifeIns >= 0 && modulesServices.module9Data.totalEarningsLifeIns < 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.totalEarningsLifeIns} prefix={modulesServices.module9Data.totalEarningsLifeIns >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.totalEarningsLifeIns < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.horizontalLine} />
                                <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                  <Grid container>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                        <div className={classes.spanText}>Ending Life Insurance Cash Value</div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} >
                                      <div className={classes.inputRight}>
                                        <span className={classes.noInputText}>
                                          {modulesServices.module9Data.endingBalanceLifeIns !== undefined && modulesServices.module9Data.endingBalanceLifeIns < 0 && modulesServices.module9Data.endingBalanceLifeIns >= 0 ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module9Data.endingBalanceLifeIns} prefix={modulesServices.module9Data.endingBalanceLifeIns >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.endingBalanceLifeIns < 0 && ')'} />
                                          ) : ('$0')}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                              </div>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Charitable Contributions Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {module9Info.charitable_start_year > 0 && (
                                        <div className={classes.spanText}>
                                          Annual Contribution in the Year
                                          {' '}
                                          {module9Info.charitable_start_year}
                                        </div>
                                      )}
                                      {module9Info.charitable_start_year === 0 && (
                                        <div className={classes.spanText}>
                                          Annual Contribution in the Year
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module9Info.charitableAnnualContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module9Info.charitableAnnualContribution} prefix={module9Info.charitableAnnualContribution >= 0 ? '$' : '($'} suffix={module9Info.charitableAnnualContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Contributions After 0 Years</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module9Info.charitableTotalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module9Info.charitableTotalContribution} prefix={module9Info.charitableTotalContribution >= 0 ? '$' : '($'} suffix={module9Info.charitableTotalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Estimated Tax Savings at 20% Tax Rate</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module9Info.charitableTaxSavings !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module9Info.charitableTaxSavings} prefix={module9Info.charitableTaxSavings >= 0 ? '$' : '($'} suffix={module9Info.charitableTaxSavings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Family */}
        <div className={classes.tableResponsive} id="summary_table_family">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion}>
                      <h3 className={classNames(classes.moduleHeading, classes.familyHeader)}>Family</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Marriage</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Marriage Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module10Info.marriege_year !== undefined ? module10Info.marriege_year : "0"}</span>

                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Spouse's Career</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Occupation</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module10Info.occupation !== undefined ? module10Info.occupation : ""}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Spouse's Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.spouse_income !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.spouse_income} prefix={summaryTableData.module10Info.spouse_income >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.spouse_income < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Spouse's Career Advancement</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Occupation</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module10Info.second_occupation !== undefined ? summaryTableData.module10Info.second_occupation : ""}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Spouse's Student Loan Debt</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Student Loan Balance</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.spouse_student_loan !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.spouse_student_loan} prefix={summaryTableData.module10Info.spouse_student_loan >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.spouse_student_loan < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Interest Rate</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.spouses_interest_rate !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.spouses_interest_rate} prefix={summaryTableData.module10Info.spouses_interest_rate < 0 && '('} suffix={summaryTableData.module10Info.spouses_interest_rate >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>$0</span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Children</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Number of Children</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.number_of_children !== undefined ? 
                                      <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ module10Info.number_of_children  }/>
                                    : "0"}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Pet</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Year of Purchase</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module10Info.year_of_purchase !== undefined ? module10Info.year_of_purchase : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Marriage Multiples - Monthly Living Expenses</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Rent</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyRentMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyRentMultiplier} prefix={summaryTableData.module10Info.familyRentMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyRentMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>

                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Utilities</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyUtilitiesMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyUtilitiesMultiplier} prefix={summaryTableData.module10Info.familyUtilitiesMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyUtilitiesMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Food</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyFoodMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyFoodMultiplier} prefix={summaryTableData.module10Info.familyFoodMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyFoodMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Clothing & Personal Care</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyClothingMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyClothingMultiplier} prefix={summaryTableData.module10Info.familyClothingMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyClothingMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Entertainment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyEntertainmentMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyEntertainmentMultiplier} prefix={summaryTableData.module10Info.familyEntertainmentMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyEntertainmentMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Technology</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyTechnologyMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyTechnologyMultiplier} prefix={summaryTableData.module10Info.familyTechnologyMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyTechnologyMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Transportation</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyTransportationMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyTransportationMultiplier} prefix={summaryTableData.module10Info.familyTransportationMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyTransportationMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Miscellaneous</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyMiscellaneousMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyMiscellaneousMultiplier} prefix={summaryTableData.module10Info.familyMiscellaneousMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyMiscellaneousMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Health Insurance Costs outside Premium</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyHealthInsuranceOutsidePremiumMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyHealthInsuranceOutsidePremiumMultiplier} prefix={summaryTableData.module10Info.familyHealthInsuranceOutsidePremiumMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyHealthInsuranceOutsidePremiumMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Health Insurance Premium</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module10Info.familyHealthInsurancePremiumMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module10Info.familyHealthInsurancePremiumMultiplier} prefix={summaryTableData.module10Info.familyHealthInsurancePremiumMultiplier >= 0 ? '$' : '($'} suffix={summaryTableData.module10Info.familyHealthInsurancePremiumMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <ReactTooltip id="detailsTooltip5" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>

                                  {modulesServices.module10Data.marriege_year > 0 && (
                                    <div>
                                      <h3>
                                        <span>Marital Budget Summary Year
                                          {' '}
                                          {modulesServices.module10Data.marriege_year}
                                        </span>
                                        <div className={classes.infoIcon} data-for="detailsTooltip5" data-tip="Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Income Statement for more details on the summary below.">
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                        {' '}
                                        <span>&nbsp;{modulesServices.module10Data.MaritalIncomeStatementSummary}</span>
                                      </h3>

                                    </div>
                                  )}
                                  {modulesServices.module10Data.marriege_year <= 0 && (
                                    <h3>Marital Budget Summary</h3>
                                  )}
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>Spouse&apos;s Income Earned</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalSpouseIncomeMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalSpouseIncomeMain} prefix={modulesServices.module10Data.marritalSpouseIncomeMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalSpouseIncomeMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalSpouseIncome !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalSpouseIncome} prefix={modulesServices.module10Data.marritalSpouseIncome >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalSpouseIncome < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>My Income Earned</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalOtherIncomeMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalOtherIncomeMain} prefix={modulesServices.module10Data.marritalOtherIncomeMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalOtherIncomeMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalOtherIncome !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalOtherIncome} prefix={modulesServices.module10Data.marritalOtherIncome >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalOtherIncome < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Income Earned</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalTotalIncomeMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTotalIncomeMain} prefix={modulesServices.module10Data.marritalTotalIncomeMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalTotalIncomeMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalTotalIncome !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTotalIncome} prefix={modulesServices.module10Data.marritalTotalIncome >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalTotalIncome < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>Total Taxes Paid</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalTaxesPaidMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTaxesPaidMain} prefix={modulesServices.module10Data.marritalTaxesPaidMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalTaxesPaidMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalTaxesPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTaxesPaid} prefix={modulesServices.module10Data.marritalTaxesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalTaxesPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.Bluepadding)}><strong><i>Total Tax Rate</i></strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <i>
                                          {modulesServices.module10Data.marritalTaxesRateMain !== undefined ? (
                                            <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTaxesRateMain} prefix={modulesServices.module10Data.marritalTaxesRateMain >= 0 ? '' : '('} suffix={modulesServices.module10Data.marritalTaxesRateMain < 0 ? '%)' : '%'} />
                                          ) : ('0%')}
                                        </i>
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <i>
                                          {modulesServices.module10Data.marritalTaxesRate !== undefined ? (
                                            <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalTaxesRate} prefix={modulesServices.module10Data.marritalTaxesRate >= 0 ? '' : '('} suffix={modulesServices.module10Data.marritalTaxesRate < 0 ? '%)' : '%'} />
                                          ) : ('0%')}
                                        </i>
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Living Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalExpensesPaidMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalExpensesPaidMain} prefix={modulesServices.module10Data.marritalExpensesPaidMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalExpensesPaidMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalExpensesPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalExpensesPaid} prefix={modulesServices.module10Data.marritalExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalExpensesPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Higher Education Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.higherEducationExpensesMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.higherEducationExpensesMain} prefix={modulesServices.module10Data.higherEducationExpensesMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.higherEducationExpensesMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.higherEducationExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.higherEducationExpenses} prefix={modulesServices.module10Data.higherEducationExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.higherEducationExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Children Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.childrenExpensesMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.childrenExpensesMain} prefix={modulesServices.module10Data.childrenExpensesMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.childrenExpensesMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.childrenExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.childrenExpenses} prefix={modulesServices.module10Data.childrenExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.childrenExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Pet Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.petExpensesMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.petExpensesMain} prefix={modulesServices.module10Data.petExpensesMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.petExpensesMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.petExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.petExpenses} prefix={modulesServices.module10Data.petExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.petExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Car Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalCarPaidMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalCarPaidMain} prefix={modulesServices.module10Data.marritalCarPaidMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalCarPaidMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalCarPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalCarPaid} prefix={modulesServices.module10Data.marritalCarPaid >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalCarPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Real Estate Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalRealEstatePaidMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalRealEstatePaidMain} prefix={modulesServices.module10Data.marritalRealEstatePaidMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalRealEstatePaidMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalRealEstatePaid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalRealEstatePaid} prefix={modulesServices.module10Data.marritalRealEstatePaid >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalRealEstatePaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Loan Payments</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalFinancingExpensesPaidMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalFinancingExpensesPaidMain} prefix={modulesServices.module10Data.marritalFinancingExpensesPaidMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalFinancingExpensesPaidMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.marritalFinancingExpensesPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.marritalFinancingExpensesPaid} prefix={modulesServices.module10Data.marritalFinancingExpensesPaid >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.marritalFinancingExpensesPaid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Total Annual Expenses</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.totalAnnualExpensesMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.totalAnnualExpensesMain} prefix={modulesServices.module10Data.totalAnnualExpensesMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.totalAnnualExpensesMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.totalAnnualExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.totalAnnualExpenses} prefix={modulesServices.module10Data.totalAnnualExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.totalAnnualExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Annual Cash Flow before Retirement Contributions</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain} prefix={modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.annualCashFlowBeforeRetirementContributions !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.annualCashFlowBeforeRetirementContributions} prefix={modulesServices.module10Data.annualCashFlowBeforeRetirementContributions >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.annualCashFlowBeforeRetirementContributions < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        <strong>Retirement Contributions</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.retirementContributionsMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.retirementContributionsMain} prefix={modulesServices.module10Data.retirementContributionsMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.retirementContributionsMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.retirementContributions !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.retirementContributions} prefix={modulesServices.module10Data.retirementContributions >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.retirementContributions < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Net Cash Flow for Savings and Investments</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain} prefix={modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module10Data.netCashFlowForSavingsAndInvestments !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module10Data.netCashFlowForSavingsAndInvestments} prefix={modulesServices.module10Data.netCashFlowForSavingsAndInvestments >= 0 ? '$' : '($'} suffix={modulesServices.module10Data.netCashFlowForSavingsAndInvestments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {modulesServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {modulesServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{modulesServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Vacation */}
        <div className={classes.tableResponsive} id="summary_table_vacation" >
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.vacationHeader)}>Vacation</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Vacation</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.start_year !== undefined ? module11Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.end_year !== undefined ? module11Info.end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Cost</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module11Info.yearly_cost !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module11Info.yearly_cost} prefix={summaryTableData.module11Info.yearly_cost >= 0 ? '$' : '($'} suffix={summaryTableData.module11Info.yearly_cost < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Vacation 1</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Special Purchase</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module11Info.purchase_item !== undefined ? summaryTableData.module11Info.purchase_item : "0"}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.second_start_year !== undefined ? module11Info.second_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.second_end_year !== undefined ? module11Info.second_end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Cost</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module11Info.second_yearly_cost !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module11Info.second_yearly_cost} prefix={summaryTableData.module11Info.second_yearly_cost >= 0 ? '$' : '($'} suffix={summaryTableData.module11Info.second_yearly_cost < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Vacation 2</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Special Purchase</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module11Info.second_purchase_item !== undefined ? summaryTableData.module11Info.second_purchase_item : "0"}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.third_start_year !== undefined ? module11Info.third_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module11Info.third_end_year !== undefined ? module11Info.third_end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Cost</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module11Info.third_yearly_cost !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module11Info.third_yearly_cost} prefix={summaryTableData.module11Info.third_yearly_cost >= 0 ? '$' : '($'} suffix={summaryTableData.module11Info.third_yearly_cost < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Vacation + Special Purchase Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Amount Spent</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module11Info.vacationTotalAmountSpent !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module11Info.vacationTotalAmountSpent} prefix={summaryTableData.module11Info.vacationTotalAmountSpent >= 0 ? '$' : '($'} suffix={summaryTableData.module11Info.vacationTotalAmountSpent < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Buying a car */}
        <div className={classes.tableResponsive} id="summary_table_car">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.carHeader)}>Buying a Car</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Lease a Car</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.leasing_car_start_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}># of Months</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.leasing_car_number_of_years}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Monthly Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.leasing_car_down_payment !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasing_car_down_payment} prefix={summaryTableData.module12Info.leasing_car_down_payment >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasing_car_down_payment < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Leasing a Second Car</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.leasing_second_car_start_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}># of Months</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.leasing_second_car_number_of_years}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.leasingSecondCarEndYear}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Monthly Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.leasing_second_car_down_payment !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasing_second_car_down_payment} prefix={summaryTableData.module12Info.leasing_second_car_down_payment >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasing_second_car_down_payment < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Buying a Car</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.purchase_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Total Purchase Price</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.askingPrice !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.askingPrice} prefix={summaryTableData.module12Info.askingPrice >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.askingPrice < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Down Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.down_payment_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.down_payment_in_percentage} prefix={summaryTableData.module12Info.down_payment_in_percentage >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.down_payment_in_percentage < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Buying a Second Car</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module12Info.second_purchase_year}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Total Purchase Price</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.moreAskingPrice !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreAskingPrice} prefix={summaryTableData.module12Info.moreAskingPrice >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreAskingPrice < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Down Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module12Info.second_down_payment_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.second_down_payment_in_percentage} prefix={summaryTableData.module12Info.second_down_payment_in_percentage >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.second_down_payment_in_percentage < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Leasing a Car Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingCarDownPaymentSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingCarDownPaymentSummary} prefix={summaryTableData.module12Info.leasingCarDownPaymentSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingCarDownPaymentSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingCarTotalPaymentSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingCarTotalPaymentSummary} prefix={summaryTableData.module12Info.leasingCarTotalPaymentSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingCarTotalPaymentSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingCarTotalExpensesSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingCarTotalExpensesSummary} prefix={summaryTableData.module12Info.leasingCarTotalExpensesSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingCarTotalExpensesSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cost of Lease</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingCarTotalCostsSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingCarTotalCostsSummary} prefix={summaryTableData.module12Info.leasingCarTotalCostsSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingCarTotalCostsSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Buying a Car Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Retail Value</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classNames(classes.noInputText, classes.textUnderline)}>
                                        {summaryTableData.module12Info.investmentAnalysisPurchasePrice !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisPurchasePrice} prefix={summaryTableData.module12Info.investmentAnalysisPurchasePrice >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisPurchasePrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Destination Charges</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.destinationChargesSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.destinationChargesSummary} prefix={summaryTableData.module12Info.destinationChargesSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.destinationChargesSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Discount</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.investmentAnalysisDiscount !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisDiscount} prefix={summaryTableData.module12Info.investmentAnalysisDiscount >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisDiscount < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Rebate</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.investmentAnalysisRebate !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisRebate} prefix={summaryTableData.module12Info.investmentAnalysisRebate >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisRebate < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Trade-In Value</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.investmentAnalysisTradeIn !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisTradeIn} prefix={summaryTableData.module12Info.investmentAnalysisTradeIn >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisTradeIn < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Sales Tax</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.investmentAnalysisSalesTax !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisSalesTax} prefix={summaryTableData.module12Info.investmentAnalysisSalesTax >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisSalesTax < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Registration & Documentation Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.investmentAnalysisRegistrationFees !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.investmentAnalysisRegistrationFees} prefix={summaryTableData.module12Info.investmentAnalysisRegistrationFees >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.investmentAnalysisRegistrationFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Purchase Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasing_second_car_start_year}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasing_second_car_start_year}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Origination Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.carLoanPoints !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.carLoanPoints} prefix={summaryTableData.module12Info.carLoanPoints >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.carLoanPoints < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Cash Needed at Purchase</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.cashNeededAtPurchase !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.cashNeededAtPurchase} prefix={summaryTableData.module12Info.cashNeededAtPurchase >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.cashNeededAtPurchase < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cash Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.totalCashAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.totalCashAvailable} prefix={summaryTableData.module12Info.totalCashAvailable >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.totalCashAvailable < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Liquid Assets Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Leasing a Second Car Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingSecondCarDownPaymentSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingSecondCarDownPaymentSummary} prefix={summaryTableData.module12Info.leasingSecondCarDownPaymentSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingSecondCarDownPaymentSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingSecondCarTotalPaymentSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingSecondCarTotalPaymentSummary} prefix={summaryTableData.module12Info.leasingSecondCarTotalPaymentSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingSecondCarTotalPaymentSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingSecondCarTotalExpensesSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingSecondCarTotalExpensesSummary} prefix={summaryTableData.module12Info.leasingSecondCarTotalExpensesSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingSecondCarTotalExpensesSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cost of Lease</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.leasingSecondCarTotalCostsSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.leasingSecondCarTotalCostsSummary} prefix={summaryTableData.module12Info.leasingSecondCarTotalCostsSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.leasingSecondCarTotalCostsSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Buying a Second Car Summary
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Purchase Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classNames(classes.noInputText, classes.textUnderline)}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisPurchasePrice !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisPurchasePrice} prefix={summaryTableData.module12Info.moreInvestmentAnalysisPurchasePrice >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisPurchasePrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Destination Charges</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreDestinationChargesSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreDestinationChargesSummary} prefix={summaryTableData.module12Info.moreDestinationChargesSummary >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreDestinationChargesSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Discount</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisDiscount !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisDiscount} prefix={summaryTableData.module12Info.moreInvestmentAnalysisDiscount >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisDiscount < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Rebate</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisRebate !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisRebate} prefix={summaryTableData.module12Info.moreInvestmentAnalysisRebate >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisRebate < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Trade-In Value</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisTradeIn !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisTradeIn} prefix={summaryTableData.module12Info.moreInvestmentAnalysisTradeIn >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisTradeIn < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Sales Tax</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisSalesTax !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisSalesTax} prefix={summaryTableData.module12Info.moreInvestmentAnalysisSalesTax >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisSalesTax < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Registration & Documentation Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreInvestmentAnalysisRegistrationFees !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreInvestmentAnalysisRegistrationFees} prefix={summaryTableData.module12Info.moreInvestmentAnalysisRegistrationFees >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreInvestmentAnalysisRegistrationFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Purchase Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreCarDownPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreCarDownPayment} prefix={summaryTableData.module12Info.moreCarDownPayment >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreCarDownPayment < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Origination Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreCarLoanPoints !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreCarLoanPoints} prefix={summaryTableData.module12Info.moreCarLoanPoints >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreCarLoanPoints < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Cash Needed at Purchase</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cash Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module12Info.moreCashNeededAtPurchase !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module12Info.moreCashNeededAtPurchase} prefix={summaryTableData.module12Info.moreCashNeededAtPurchase >= 0 ? '$' : '($'} suffix={summaryTableData.module12Info.moreCashNeededAtPurchase < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Liquid Assets Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        $0
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Buying a Home */}

        <div className={classes.tableResponsive} id="summary_table_home">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.homeHeader)}>Buying a Home</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Buying a Home</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module13Info.year_of_purchase !== undefined ? module13Info.year_of_purchase : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Price</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module13Info.purchase_price !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.purchase_price} prefix={module13Info.purchase_price >= 0 ? '$' : '($'} suffix={module13Info.purchase_price < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Down Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module13Info.down_payment_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module13Info.down_payment_in_percentage} prefix={module13Info.down_payment_in_percentage < 0 && '('} suffix={module13Info.down_payment_in_percentage >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Home Mortgage</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Mortgage</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.noInputText}>
                                      {module13Info.Mortage !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.Mortage} prefix={module13Info.Mortage >= 0 ? '$' : '($'} suffix={module13Info.Mortage < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Term of the Loan</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.noInputText}>
                                      {module13Info.term_of_mortgage !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module13Info.term_of_mortgage} prefix={module13Info.term_of_mortgage < 0 && '('} suffix={module13Info.term_of_mortgage >= 0 ? '%' : '%)'} />
                                      ) : ('0%')}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Interest Rate</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.noInputText}>
                                      {module13Info.rate_of_mortgage_in_percentage !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module13Info.rate_of_mortgage_in_percentage} prefix={module13Info.rate_of_mortgage_in_percentage < 0 && '('} suffix={module13Info.rate_of_mortgage_in_percentage >= 0 ? '%' : '%)'} />
                                      ) : ('0%')}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {module13Info.moreHighermonthlyPayment !== undefined ? (
                                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.moreHighermonthlyPayment} prefix={module13Info.moreHighermonthlyPayment >= 0 ? '$' : '($'} suffix={module13Info.moreHighermonthlyPayment < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Total Interest</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {module13Info.biyingaHomeTotalInterest !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.biyingaHomeTotalInterest} prefix={module13Info.biyingaHomeTotalInterest >= 0 ? '$' : '($'} suffix={module13Info.biyingaHomeTotalInterest < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Total Principal & Interest</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {module13Info.biyingaHomeTotalPrncipalInterest !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.biyingaHomeTotalPrncipalInterest} prefix={module13Info.biyingaHomeTotalPrncipalInterest >= 0 ? '$' : '($'} suffix={module13Info.biyingaHomeTotalPrncipalInterest < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Buying a Home - Monthly Expenses</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Mortgage Payment (Principal & Interest)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.mortgagePaymentExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.mortgagePaymentExpenses} prefix={modulesServices.module13Data.mortgagePaymentExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.mortgagePaymentExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Property Taxes</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.propertyTaxesExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.propertyTaxesExpenses} prefix={modulesServices.module13Data.propertyTaxesExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.propertyTaxesExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Private Mortgage Insurance (PMI)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.privateMortgageInsExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.privateMortgageInsExpenses} prefix={modulesServices.module13Data.privateMortgageInsExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.privateMortgageInsExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.homeOwnersInsExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.homeOwnersInsExpenses} prefix={modulesServices.module13Data.homeOwnersInsExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.homeOwnersInsExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>PITI (Principal, Interest, Taxes, & Insurance)</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.pitiExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.pitiExpenses} prefix={modulesServices.module13Data.pitiExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.pitiExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Maintenance</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.maintenanceExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.maintenanceExpenses} prefix={modulesServices.module13Data.maintenanceExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.maintenanceExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Association Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.assiciationFeesExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.assiciationFeesExpenses} prefix={modulesServices.module13Data.assiciationFeesExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.assiciationFeesExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Utilities</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.utilitiesExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.utilitiesExpenses} prefix={modulesServices.module13Data.utilitiesExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.utilitiesExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module13Data.totalMonthlyExpenses} prefix={modulesServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module13Data.totalMonthlyExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>% of Total Income Before Taxes</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage} prefix={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage < 0 && '('} suffix={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage >= 0 ? '%' : '%)'} />
                                        ) : ('0%')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Income Before Taxes</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue !== undefined ? (
                                          <NumberFormat
                                            decimalScale={0}
                                            fixedDecimalScale
                                            className={classes.formInput}
                                            displayType="text"
                                            thousandSeparator
                                            value={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue}
                                            prefix={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue >= 0 ? '$' : '($'}
                                            suffix={modulesServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue < 0 && ')'}
                                          />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                            </div>
                          </Grid>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Buying a Home - Investment Analysis
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Purchase Price</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.homePurchasePrice !== undefined ? (
                                          <NumberFormat
                                            decimalScale={0}
                                            fixedDecimalScale
                                            className={classes.formInput}
                                            displayType="text"
                                            thousandSeparator
                                            value={module13Info.homePurchasePrice}
                                            prefix={module13Info.homePurchasePrice >= 0 ? '$' : '($'}
                                            suffix={module13Info.homePurchasePrice < 0 && ')'}
                                          />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.homeDownPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.homeDownPayment} prefix={module13Info.homeDownPayment >= 0 ? '$' : '($'} suffix={module13Info.homeDownPayment < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Closing Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.homeClosingCosts !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.homeClosingCosts} prefix={module13Info.homeClosingCosts >= 0 ? '$' : '($'} suffix={module13Info.homeClosingCosts < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Fit Out Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.homeFitOutCosts !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.homeFitOutCosts} prefix={module13Info.homeFitOutCosts >= 0 ? '$' : '($'} suffix={module13Info.homeFitOutCosts < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Cash Needed at Purchase</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.cashNeedsPurchase !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.cashNeedsPurchase} prefix={module13Info.cashNeedsPurchase >= 0 ? '$' : '($'} suffix={module13Info.cashNeedsPurchase < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>

                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cash Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.moreHigherTotalCashAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.moreHigherTotalCashAvailable} prefix={module13Info.moreHigherTotalCashAvailable >= 0 ? '$' : '($'} suffix={module13Info.moreHigherTotalCashAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>

                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Liquid Assets Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module13Info.moreHighermoreTotalLiquid !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module13Info.moreHighermoreTotalLiquid} prefix={module13Info.moreHighermoreTotalLiquid >= 0 ? '$' : '($'} suffix={module13Info.moreHighermoreTotalLiquid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Debt-to-Income Ratio</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.spanBlueText}>
                                      <span>
                                        {module13Info.debtToIncomeRatio !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={module13Info.debtToIncomeRatio} prefix={module13Info.debtToIncomeRatio >= 0 ? '' : '('} suffix={module13Info.debtToIncomeRatio < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Buying an Investment Property */}
        <div className={classes.tableResponsive} id="summary_table_rei" >
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.reiHeader)}>Buying an Investment Property</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Buying a Real Estate Investment Property</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module14Info.year_of_purchase !== undefined ? module14Info.year_of_purchase : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Sale Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module14Info.year_of_sale !== undefined ? module14Info.year_of_sale : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Purchase Price</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module14Info.purchase_price !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.purchase_price} prefix={summaryTableData.module14Info.purchase_price >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.purchase_price < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Down Payment</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module14Info.down_payment_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={summaryTableData.module14Info.down_payment_in_percentage} prefix={summaryTableData.module14Info.down_payment_in_percentage < 0 && '('} suffix={summaryTableData.module14Info.down_payment_in_percentage >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Rental Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module14Info.annualRentalIncomeCalcs !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.annualRentalIncomeCalcs} prefix={summaryTableData.module14Info.annualRentalIncomeCalcs >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.annualRentalIncomeCalcs < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Vacancy Rate</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module14Info.vacancy_rate_in_percentage !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.vacancy_rate_in_percentage} prefix={summaryTableData.module14Info.vacancy_rate_in_percentage < 0 && '('} suffix={summaryTableData.module14Info.vacancy_rate_in_percentage >= 0 ? "%" : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Rental Income Growth</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {summaryTableData.module14Info.annual_income_growth_rate !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.annual_income_growth_rate} prefix={summaryTableData.module14Info.annual_income_growth_rate < 0 && '('} suffix={summaryTableData.module14Info.annual_income_growth_rate >= 0 ? "%" : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Real Estate Investment Property Mortgage</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Mortgage</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {summaryTableData.module14Info.mortage !== undefined ? (
                                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.mortage} prefix={summaryTableData.module14Info.mortage >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.mortage < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Term of the Loan</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.noInputText}>
                                      {summaryTableData.module14Info.term_of_mortgage !== undefined ? summaryTableData.module14Info.term_of_mortgage : "0"}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Interest Rate</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.noInputText}>
                                      {summaryTableData.module14Info.rate_of_mortgage_prcentage !== undefined ? (
                                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.rate_of_mortgage_prcentage} prefix={summaryTableData.module14Info.rate_of_mortgage_prcentage < 0 && '('} suffix={summaryTableData.module14Info.rate_of_mortgage_prcentage >= 0 ? "%" : '%)'} />
                                      ) : ('0%')}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {summaryTableData.module14Info.realEstateMonthlyPayment !== undefined ? (
                                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.realEstateMonthlyPayment} prefix={summaryTableData.module14Info.realEstateMonthlyPayment >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.realEstateMonthlyPayment < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Total Interest</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {summaryTableData.module14Info.totalInterest !== undefined ? (
                                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.totalInterest} prefix={summaryTableData.module14Info.totalInterest >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.totalInterest < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.spanText}><strong>Total Principal & Interest</strong></span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.noInputText}>
                                  {summaryTableData.module14Info.realEstateYearlyPayment !== undefined ? (
                                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.realEstateYearlyPayment} prefix={summaryTableData.module14Info.realEstateYearlyPayment >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.realEstateYearlyPayment < 0 && ')'} />
                                  ) : ('$0')}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Real Estate Investment Property - Purchase Analysis</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Purchase Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.reiPurchasePrice !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.reiPurchasePrice} prefix={summaryTableData.module14Info.reiPurchasePrice >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.reiPurchasePrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Down Payment</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.reiDownPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.reiDownPayment} prefix={summaryTableData.module14Info.reiDownPayment >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.reiDownPayment < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Closing Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.reiClosingCosts !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.reiClosingCosts} prefix={summaryTableData.module14Info.reiClosingCosts >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.reiClosingCosts < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Fit Out Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.reiFitOutCosts !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.reiFitOutCosts} prefix={summaryTableData.module14Info.reiFitOutCosts >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.reiFitOutCosts < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Cash Needed at Purchase</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.realEstatecashNeededAtPurchase !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.realEstatecashNeededAtPurchase} prefix={summaryTableData.module14Info.realEstatecashNeededAtPurchase >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.realEstatecashNeededAtPurchase < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Cash Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.realEstatetotalCashAvailable !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.realEstatetotalCashAvailable} prefix={summaryTableData.module14Info.realEstatetotalCashAvailable >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.realEstatetotalCashAvailable < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Liquid Assets Available</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.realEstatetotalLiquid !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.realEstatetotalLiquid} prefix={summaryTableData.module14Info.realEstatetotalLiquid >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.realEstatetotalLiquid < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Debt-to-Income Ratio</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.reiDebt !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.reiDebt} prefix={summaryTableData.module14Info.reiDebt >= 0 ? '' : '('} suffix={summaryTableData.module14Info.reiDebt < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Real Estate Investment Property - Investment Analysis
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Rental Income</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.investmentSummaryTotalRental !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.investmentSummaryTotalRental} prefix={summaryTableData.module14Info.investmentSummaryTotalRental >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.investmentSummaryTotalRental < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Operating Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.investmentSummaryTotalPropertyVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.investmentSummaryTotalPropertyVal} prefix={summaryTableData.module14Info.investmentSummaryTotalPropertyVal >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.investmentSummaryTotalPropertyVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Financing Costs (Principal & Interest)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.investmentSummaryTotalFinancingExpensesVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.investmentSummaryTotalFinancingExpensesVal} prefix={summaryTableData.module14Info.investmentSummaryTotalFinancingExpensesVal >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.investmentSummaryTotalFinancingExpensesVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Net Cash Flow</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.investmentSummaryNetRentalVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.investmentSummaryNetRentalVal} prefix={summaryTableData.module14Info.investmentSummaryNetRentalVal >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.investmentSummaryNetRentalVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Cash Invested</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module14Data.totalCashInvested !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.totalCashInvested} prefix={modulesServices.module14Data.totalCashInvested >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.totalCashInvested < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Cash Returned</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.totalCashReturnedVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.totalCashReturnedVal} prefix={summaryTableData.module14Info.totalCashReturnedVal >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.totalCashReturnedVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Return on Investment (before taxes)</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.ROICalVal !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.ROICalVal} prefix={summaryTableData.module14Info.ROICalVal >= 0 ? '' : '('} suffix={summaryTableData.module14Info.ROICalVal < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Multiple on Money (before taxes)</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.MoMCalVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.MoMCalVal} prefix={summaryTableData.module14Info.MoMCalVal >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.MoMCalVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Internal Rate of Return (before taxes)</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.IRRCalVal !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.IRRCalVal} prefix={summaryTableData.module14Info.IRRCalVal >= 0 ? '' : '('} suffix={summaryTableData.module14Info.IRRCalVal < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Real Estate Investment Property - Monthly Cash Flow Statement</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Adjusted Rental Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.adjustedRentalIncome !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.adjustedRentalIncome} prefix={modulesServices.module14Data.adjustedRentalIncome >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.adjustedRentalIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.mortgagepaymentInterest !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.mortgagepaymentInterest} prefix={modulesServices.module14Data.mortgagepaymentInterest >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.mortgagepaymentInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.propertytaxesValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.propertytaxesValue} prefix={modulesServices.module14Data.propertytaxesValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.propertytaxesValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.homeOwnersInsuranceValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.homeOwnersInsuranceValue} prefix={modulesServices.module14Data.homeOwnersInsuranceValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.homeOwnersInsuranceValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>PITI (Principal, Interest, Taxes, & Insurance)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.reiPitiValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.reiPitiValue} prefix={modulesServices.module14Data.reiPitiValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.reiPitiValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Management Fee</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.managementValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.managementValue} prefix={modulesServices.module14Data.managementValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.managementValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.maintenanceValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.maintenanceValue} prefix={modulesServices.module14Data.maintenanceValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.maintenanceValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.associationFeesValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.associationFeesValue} prefix={modulesServices.module14Data.associationFeesValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.associationFeesValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.utilitiesExpenseValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.utilitiesExpenseValue} prefix={modulesServices.module14Data.utilitiesExpenseValue >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.utilitiesExpenseValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.reiTotalMonthlyExpenses} prefix={modulesServices.module14Data.reiTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.reiTotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Net Cash Flow</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module14Data.monthlyNetIncomeExpenses !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module14Data.monthlyNetIncomeExpenses} prefix={modulesServices.module14Data.monthlyNetIncomeExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.monthlyNetIncomeExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Sale on Real Estate Investment Property
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Selling Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREISellingPrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREISellingPrice} prefix={summaryTableData.module14Info.moreREISellingPrice >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREISellingPrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Selling Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREISellingCost !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREISellingCost} prefix={summaryTableData.module14Info.moreREISellingCost >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREISellingCost < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Net Selling Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREINetSellingPrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREINetSellingPrice} prefix={summaryTableData.module14Info.moreREINetSellingPrice >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREINetSellingPrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Purchase Price</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREIPurchasePrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREIPurchasePrice} prefix={summaryTableData.module14Info.moreREIPurchasePrice >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREIPurchasePrice < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Closing Costs, Fit Out Costs</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREIClosingCost !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREIClosingCost} prefix={summaryTableData.module14Info.moreREIClosingCost >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREIClosingCost < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Gain (loss) on Sale</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREIGain !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREIGain} prefix={summaryTableData.module14Info.moreREIGain >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREIGain < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>pending</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREITaxCapital !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREITaxCapital} prefix={summaryTableData.module14Info.moreREITaxCapital >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREITaxCapital < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>pending</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {summaryTableData.module14Info.moreREITaxAccumulated !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={summaryTableData.module14Info.moreREITaxAccumulated} prefix={summaryTableData.module14Info.moreREITaxAccumulated >= 0 ? '$' : '($'} suffix={summaryTableData.module14Info.moreREITaxAccumulated < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>

                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Further Higher Education */}
        <div className={classes.tableResponsive} id="summary_table_fur_edu" >
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion} >
                      <h3 className={classNames(classes.moduleHeading, classes.furtherHighHeader)}>Further Higher Education</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Type of Further Higher Education</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{summaryTableData.module15Info.type_of_higher_education > 0 ? educationTypes[summaryTableData.module15Info.type_of_higher_education].label : 'Further Higher Education'}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Name of
                                    {' '}
                                    {module15Info.type_of_higher_education > 0 ? module15Info.type_of_higher_education : 'Further Higher Education'}
                                  </span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module15Info.name_of_college !== 'undefined' ? module15Info.name_of_college : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module15Info.start_year !== 'undefined' ? module15Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module15Info.graduation_year !== 'undefined' ? module15Info.graduation_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Room & Board</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module15Info.room_board !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.room_board} prefix={module15Info.room_board >= 0 ? '$' : '($'} suffix={module15Info.room_board < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Tuition & Fees</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module15Info.tuition !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.tuition} prefix={module15Info.tuition >= 0 ? '$' : '($'} suffix={module15Info.tuition < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3> Total Cost of Attendance </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newTotalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newTotalTuitionAndFees} prefix={module15Info.newTotalTuitionAndFees >= 0 ? '$' : '($'} suffix={module15Info.newTotalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newTotalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newTotalRoomAndBoard} prefix={module15Info.newTotalRoomAndBoard >= 0 ? '$' : '($'} suffix={module15Info.newTotalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newOnCampusLivingExpenses} prefix={module15Info.newOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module15Info.newOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newOffCampusLivingExpenses} prefix={module15Info.newOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module15Info.newOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during Further Higher Education</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalStudentsLoanPayments} prefix={module15Info.newHigherTotalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Cost of Attendance</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newTotalCostOfAttendance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newTotalCostOfAttendance} prefix={module15Info.newTotalCostOfAttendance >= 0 ? '$' : '($'} suffix={module15Info.newTotalCostOfAttendance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3> Total Expenses </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Tuition & Fees</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newTotalTuitionAndFees !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newTotalTuitionAndFees} prefix={module15Info.newTotalTuitionAndFees >= 0 ? '$' : '($'} suffix={module15Info.newTotalTuitionAndFees < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Room & Board</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newTotalRoomAndBoard !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newTotalRoomAndBoard} prefix={module15Info.newTotalRoomAndBoard >= 0 ? '$' : '($'} suffix={module15Info.newTotalRoomAndBoard < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>On Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newOnCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newOnCampusLivingExpenses} prefix={module15Info.newOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module15Info.newOnCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Off Campus Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newOffCampusLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newOffCampusLivingExpenses} prefix={module15Info.newOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={module15Info.newOffCampusLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Payments during Further Higher Education</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalStudentsLoanPayments !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalStudentsLoanPayments} prefix={module15Info.newHigherTotalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalStudentsLoanPayments < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Other Loan Payments on Other Debt Further Higher Education</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherOtherLoanPaymentsonOtherDebt !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherOtherLoanPaymentsonOtherDebt} prefix={module15Info.newHigherOtherLoanPaymentsonOtherDebt >= 0 ? '$' : '($'} suffix={module15Info.newHigherOtherLoanPaymentsonOtherDebt < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Car Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherCarExpense !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherCarExpense} prefix={module15Info.newHigherCarExpense >= 0 ? '$' : '($'} suffix={module15Info.newHigherCarExpense < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Real Estate Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherRealEstateExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherRealEstateExpenses} prefix={module15Info.newHigherRealEstateExpenses >= 0 ? '$' : '($'} suffix={module15Info.newHigherRealEstateExpenses < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Children and Pet Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherChildrenAndPetExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherChildrenAndPetExpenses} prefix={module15Info.newHigherChildrenAndPetExpenses >= 0 ? '$' : '($'} suffix={module15Info.newHigherChildrenAndPetExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalExpenses} prefix={module15Info.newHigherTotalExpenses >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>
                                    Income Needed to Support Student Loans Analysis&nbsp;
                                  </h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Student Loan Balance (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalStudentLoanBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalStudentLoanBalance} prefix={module15Info.newHigherTotalStudentLoanBalance >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalStudentLoanBalance < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Monthly Payment (incl. Existing Loans)</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalMonthlyPayment !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalMonthlyPayment} prefix={module15Info.newHigherTotalMonthlyPayment >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalMonthlyPayment < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Total Annual Paid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherTotalAnnualPaymentPaid !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherTotalAnnualPaymentPaid} prefix={module15Info.newHigherTotalAnnualPaymentPaid >= 0 ? '$' : '($'} suffix={module15Info.newHigherTotalAnnualPaymentPaid < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>Divided by 12.5%</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {(module15Info.start_year > 0 && module15Info.graduation_year > 0) ? 12.5 : 0}
                                        %
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}><strong>Income Needed to Support Student Loans</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {module15Info.newHigherIncomeNeedToSupportLoans !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module15Info.newHigherIncomeNeedToSupportLoans} prefix={module15Info.newHigherIncomeNeedToSupportLoans >= 0 ? '$' : '($'} suffix={module15Info.newHigherIncomeNeedToSupportLoans < 0 && ')'} />
                                        ) : ('$0')}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                              <ReactTooltip id="detailsTooltip4" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        Investment Analysis&nbsp;
                                        <div
                                          className={classes.infoIcon}
                                          data-for="investTooltip"
                                          data-tip="Are you financing your education effectively? Do you minimize student loans and family contributions with scholarships, grants, and income?
                                      Look for ways to improve your financing strategy. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                        >
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Scholarships</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisScholships !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisScholships} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisScholships >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisScholships < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Grants</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisGrants !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisGrants} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisGrants >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisGrants < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Other Financial Aid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisFinancialAid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisFinancialAid} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisFinancialAid >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisFinancialAid < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Income Earned after Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >

                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisIncomeEarned !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisIncomeEarned} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisIncomeEarned >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisIncomeEarned < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip4" data-tip="Income earned after taxes includes all income from any module that shares the same timeline as this higher education module. For example, if your career path module overlaps with this module, that income will be calculated in this number. Go to the Income Statement for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Family Contribution</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisFamilyContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisFamilyContribution} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisFamilyContribution >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisFamilyContribution < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Federal Subsidized Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newFedSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newFedSubBeginningBalance} prefix={modulesServices.module15Data.newFedSubBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newFedSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Federal Unsubsidized Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newFedUnSubPrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newFedUnSubPrincipal} prefix={modulesServices.module15Data.newFedUnSubPrincipal >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newFedUnSubPrincipal < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Private Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newPrivatePrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newPrivatePrincipal} prefix={modulesServices.module15Data.newPrivatePrincipal >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newPrivatePrincipal < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newSummaryLoanPoints !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newSummaryLoanPoints} prefix={modulesServices.module15Data.newSummaryLoanPoints >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newSummaryLoanPoints < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Financing Sources</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailable} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailable >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherTotalExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherTotalExpenses} prefix={modulesServices.module15Data.newHigherTotalExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherTotalExpenses < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Left Over</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classNames(classes.noInputText, classes.spanBlueText)}>
                                            {(modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailableVal !== undefined && modulesServices.module15Data.totalExpenses !== undefined) ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                allowNegative={false}
                                                thousandSeparator
                                                value={modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailableVal + modulesServices.module15Data.totalExpenses}
                                                prefix={(modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailableVal + modulesServices.module15Data.totalExpenses) >= 0 ? '$' : '($'}
                                                suffix={(modulesServices.module15Data.newHigherinvestmentAnalysisTotalCashAvailableVal + modulesServices.module15Data.totalExpenses) < 0 && ')'}
                                              />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Liquid Assets Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable} prefix={modulesServices.module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable >= 0 ? '$' : '($'} suffix={modulesServices.module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                                <div className={classes.cashFlowDiv}>
                                  <Grid container spacing={0}>
                                    <Grid item sm={6} xs={6} spacing={0}>
                                      <div className={classes.whiteLeftBox}>
                                        <div className={classes.currencyTextNew}>
                                          <p><strong>Cash Flow Check</strong></p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6} spacing={0}>
                                      {modulesServices.cashFlowCheckVal < 0 && (
                                        <div className={classes.cashFlowGreen}>
                                          <div>
                                            <span>Cash Flow Positive</span>
                                          </div>
                                        </div>
                                      )}
                                      {modulesServices.cashFlowCheckVal > 0 && (
                                        <div className={classes.cashFlowRed}>
                                          <div>
                                            <span>{modulesServices.cashFlowCheckVal}</span>
                                          </div>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>

                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Further Career Advancement */}
        <div className={classes.tableResponsive} id="fur_career_summary">
          <div className={classes.summaryBlock}>
            <table className={classes.popupTable}>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={classes.modulePortion}>
                      <h3 className={classNames(classes.moduleHeading, classes.furtherCareerHeader)}>Further Career Advancement</h3>
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={4}>
                          <p className={classes.selectHeading}>Select Scenario</p>
                          <div className={classes.summarySelect}>
                            <small >
                              <font>Select Scenario</font>
                            </small>
                          </div>
                          <p className={classes.selectHeading}>Career</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Job Title</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.occupation !== undefined ? module17Info.occupation : ""}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.start_year !== undefined ? module17Info.start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.end_year ? module17Info.end_year : 0}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                  <div className={classes.spanText}><strong>Total Pre-Tax Base Income</strong></div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} className={classes.noSpace}>
                                <div className={classes.inputRight}>
                                  <span className={classes.noInputText}>
                                    {module17Info.jobtotalPreTaxExpected_val !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module17Info.jobtotalPreTaxExpected_val} prefix={module17Info.jobtotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={module17Info.jobtotalPreTaxExpected_val < 0 && ')'} />
                                    ) : ('$0')}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Supplementary Income</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Start Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.second_start_year !== undefined ? module17Info.second_start_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>End Year</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.second_end_year !== undefined ? module17Info.second_end_year : "0"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Annual Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    <span className={classes.currencyContent}>
                                      {module17Info.second_yearly_income !== undefined ? (
                                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={module17Info.second_yearly_income} prefix={module17Info.second_yearly_income >= 0 ? '$' : '($'} suffix={module17Info.second_yearly_income < 0 && ')'} />
                                      ) : ('$0')}
                                    </span>
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>401(k)</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a 401(k)?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.contribute_401k !== undefined ? module17Info.contribute_401k : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.base_income !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.base_income} prefix={module17Info.base_income < 0 && '('} suffix={module17Info.base_income >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Rate on Personal Contribution</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.employer_matching_rate !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.employer_matching_rate} prefix={module17Info.employer_matching_rate < 0 && '('} suffix={module17Info.employer_matching_rate >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Matching Limit as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>  
                                    {module17Info.employer_matching_limit !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.employer_matching_limit} prefix={module17Info.employer_matching_limit < 0 && '('} suffix={module17Info.employer_matching_limit >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Roth IRA</p>

                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Roth IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.roth_contribute !== undefined ? module17Info.roth_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.roth_personal_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.roth_personal_contribution} prefix={module17Info.roth_personal_contribution < 0 && '('} suffix={module17Info.roth_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>SEP IRA</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a SEP IRA?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.ira_contribute !== undefined ? module17Info.ira_contribute : "No"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income (25% Limit)</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.ira_personal_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.ira_personal_contribution} prefix={module17Info.ira_personal_contribution < 0 && '('} suffix={module17Info.ira_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <p className={classes.selectHeading}>Pension</p>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Contribute to / Already Have a Pension?</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>{module17Info.pension_contribute !== undefined ? module17Info.pension_contribute : "no"}</span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Personal Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.pension_employer_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.pension_employer_contribution} prefix={module17Info.pension_employer_contribution < 0 && '('} suffix={module17Info.pension_employer_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formGroup}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6}>
                                <span className={classes.insideSpan}>
                                  <span className={classes.currencyContent}>Employer Contribution as a % of Base Income</span>
                                </span>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <span className={classNames(classes.insideSpan, classes.summaryValue)}>
                                  <span className={classes.currencyContent}>
                                    {module17Info.pension_personal_contribution !== undefined ? (
                                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={module17Info.pension_personal_contribution} prefix={module17Info.pension_personal_contribution < 0 && '('} suffix={module17Info.pension_personal_contribution >= 0 ? '%' : '%)'} />
                                    ) : ('0%')}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Monthly Budget</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Monthly Base Income</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.monthlyIncomeFromFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.monthlyIncomeFromFurCareerAdv} prefix={modulesServices.module17Data.monthlyIncomeFromFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.monthlyIncomeFromFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Monthly Income</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.otherMonthlyIncomeFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.otherMonthlyIncomeFurCareerAdv} prefix={modulesServices.module17Data.otherMonthlyIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.otherMonthlyIncomeFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Total Monthly Income</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.totalMonthlyIncomeFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.totalMonthlyIncomeFurCareerAdv} prefix={modulesServices.module17Data.totalMonthlyIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.totalMonthlyIncomeFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.totalTaxesPaidFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.totalTaxesPaidFurCareerAdv} prefix={modulesServices.module17Data.totalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.totalTaxesPaidFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.livingExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.livingExpensesFurCareerAdv} prefix={modulesServices.module17Data.livingExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.livingExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.higherEducationExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.higherEducationExpensesFurCareerAdv} prefix={modulesServices.module17Data.higherEducationExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.higherEducationExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.childrenExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.childrenExpensesFurCareerAdv} prefix={modulesServices.module17Data.childrenExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.childrenExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.petExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.petExpensesFurCareerAdv} prefix={modulesServices.module17Data.petExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.petExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.carExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.carExpensesFurCareerAdv} prefix={modulesServices.module17Data.carExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.carExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.realEstateExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.realEstateExpensesFurCareerAdv} prefix={modulesServices.module17Data.realEstateExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.realEstateExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.internetExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.internetExpensesFurCareerAdv} prefix={modulesServices.module17Data.internetExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.internetExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Total Monthly Expenses</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.totalMonthlyExpensesFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.totalMonthlyExpensesFurCareerAdv} prefix={modulesServices.module17Data.totalMonthlyExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.totalMonthlyExpensesFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Monthly Cash Flow before Retirement Contributions</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.monthlyNetIncomeFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.monthlyNetIncomeFurCareerAdv} prefix={modulesServices.module17Data.monthlyNetIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.monthlyNetIncomeFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.retirementContributionsFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.retirementContributionsFurCareerAdv} prefix={modulesServices.module17Data.retirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.retirementContributionsFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.monthlyNetCashFlowFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.monthlyNetCashFlowFurCareerAdv} prefix={modulesServices.module17Data.monthlyNetCashFlowFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.monthlyNetCashFlowFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Annual Budget</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    &nbsp;
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    &nbsp;
                                  </Grid>
                                  <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                    {modulesServices.module17Data.furCareerAdvIconCalcs !== '' && (
                                      <div className={classes.formMainRow}>
                                        <div className={classes.labelText}>
                                          <div className={classes.calcText}>
                                            <span className={classes.familyImage}>
                                              <img src={familyImage} alt="familyImage" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)} />
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        <u>
                                          Year
                                          {' '}
                                          {modulesServices.module17Data.start_year}
                                          {' '}

                                        </u>
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {(modulesServices.module17Data.furCareerAdvIconCalcs !== 0 && modulesServices.module17Data.furCareerAdvIconCalcs !== undefined) && (
                                          <span>
                                            {modulesServices.module17Data.furCareerAdvIconCalcs}
                                          </span>
                                        )}

                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.totalIncomeFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.totalIncomeFurCareerAdv} prefix={modulesServices.module17Data.totalIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.totalIncomeFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.totalIncomeFurCareerAdvCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.totalIncomeFurCareerAdvCalcs} prefix={modulesServices.module17Data.totalIncomeFurCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.totalIncomeFurCareerAdvCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.annualTotalTaxesPaidFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.annualTotalTaxesPaidFurCareerAdv} prefix={modulesServices.module17Data.annualTotalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.annualTotalTaxesPaidFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.annualTotalExpensesFurCareerAdvCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.annualTotalExpensesFurCareerAdvCalcs} prefix={modulesServices.module17Data.annualTotalExpensesFurCareerAdvCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.annualTotalExpensesFurCareerAdvCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvLivingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvLivingExpenses} prefix={modulesServices.module17Data.furCareerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvLivingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvLivingExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvLivingExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvLivingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvLivingExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvHigherEducationExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvHigherEducationExpenses} prefix={modulesServices.module17Data.furCareerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvHigherEducationExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvHigherEducationExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvHigherEducationExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvHigherEducationExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvHigherEducationExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvChildrenExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvChildrenExpenses} prefix={modulesServices.module17Data.furCareerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvChildrenExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvChildrenExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvChildrenExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvChildrenExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvChildrenExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvPetExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvPetExpenses} prefix={modulesServices.module17Data.furCareerAdvPetExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvPetExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvPetExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvPetExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvPetExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvPetExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvCarExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvCarExpenses} prefix={modulesServices.module17Data.furCareerAdvCarExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvCarExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvCarExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvCarExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvCarExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvCarExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvHomeExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvHomeExpenses} prefix={modulesServices.module17Data.furCareerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvHomeExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvHomeExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvHomeExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvHomeExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvHomeExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvFinancingExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvFinancingExpenses} prefix={modulesServices.module17Data.furCareerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvFinancingExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvFinancingExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvFinancingExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvFinancingExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvFinancingExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Total Annual Expenses</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvTotalAnnualExpenses !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvTotalAnnualExpenses} prefix={modulesServices.module17Data.furCareerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvTotalAnnualExpenses < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs} prefix={modulesServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Annual Cash Flow before Retirement Contributions</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvAnnualNetIncome !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvAnnualNetIncome} prefix={modulesServices.module17Data.furCareerAdvAnnualNetIncome >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvAnnualNetIncome < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furCareerAdvAnnualNetIncomeCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furCareerAdvAnnualNetIncomeCalcs} prefix={modulesServices.module17Data.furCareerAdvAnnualNetIncomeCalcs >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furCareerAdvAnnualNetIncomeCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.annualRetirementContributionsFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.annualRetirementContributionsFurCareerAdv} prefix={modulesServices.module17Data.annualRetirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.annualRetirementContributionsFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6} >
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} >
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.annualNetCashFlowFurCareerAdv !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.annualNetCashFlowFurCareerAdv} prefix={modulesServices.module17Data.annualNetCashFlowFurCareerAdv >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.annualNetCashFlowFurCareerAdv < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <ReactTooltip id="detailsTooltip2" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <ReactTooltip id="detailsTooltip3" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                        <Grid item sm={4} xs={4}>

                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>401(k) Retirement Account Summary</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.contribute_401k === 'Yes' && (

                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning 401(k) Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.start_year}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.contribute_401k === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning 401(k) Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.beginningBalanceInYearFourth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.beginningBalanceInYearFourth} prefix={modulesServices.module17Data.beginningBalanceInYearFourth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.beginningBalanceInYearFourth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.beginningBalanceInYearFourthRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.beginningBalanceInYearFourthRoth} prefix={modulesServices.module17Data.beginningBalanceInYearFourthRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.beginningBalanceInYearFourthRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Personal Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryPersonalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryPersonalContribution} prefix={modulesServices.module17Data.further401kSummaryPersonalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryPersonalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryPersonalContributionRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryPersonalContributionRoth} prefix={modulesServices.module17Data.further401kSummaryPersonalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryPersonalContributionRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>


                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Earnings on Personal Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEarningsOnPersonalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContribution} prefix={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth} prefix={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Total Personal Contributions & Earnings</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings} prefix={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth} prefix={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Employer Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEmployerContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEmployerContribution} prefix={modulesServices.module17Data.further401kSummaryEmployerContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEmployerContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEmployerContributionRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEmployerContributionRoth} prefix={modulesServices.module17Data.further401kSummaryEmployerContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEmployerContributionRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Earnings on Employer Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEarningsOnEmployerContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContribution} prefix={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth} prefix={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>Total Employer Contributions & Earnings</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings} prefix={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth} prefix={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classes.spanText}>
                                        <strong>401(k) Balance before Vesting Reductions</strong>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherBalanceBeforeVestingReductions !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherBalanceBeforeVestingReductions} prefix={modulesServices.module17Data.furtherBalanceBeforeVestingReductions >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherBalanceBeforeVestingReductions < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherBalanceBeforeVestingReductionsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherBalanceBeforeVestingReductionsRoth} prefix={modulesServices.module17Data.furtherBalanceBeforeVestingReductionsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.balanceBeforeVestingReductionsAdd < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        % Vested after
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        years at 25%/yr Schedule
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.employerContributionsAndEarningsNotVestedFur !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFur} prefix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFur >= 0 ? '' : '('} suffix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFur < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                      <div className={classes.infoIcon} data-for="detailsTooltip3" data-tip="The FC vests your 401(k) contributions at 25% per year. Vesting means how much of your 401(k) funds, that were contributed by your employer, you can take with you when you leave. Your Personal Contributions & Earnings are 100% vested (yours to take).">
                                        <img src={infoIcon} alt="infoicon" />
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>

                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth} prefix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth >= 0 ? '' : '('} suffix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth < 0 ? '%)' : '%'} />
                                        ) : ('0%')}
                                      </span>
                                      <div className={classes.infoIcon} data-for="detailsTooltip2" data-tip="The FC vests your 401(k) contributions at 25% per year. Vesting means how much of your 401(k) funds, that were contributed by your employer, you can take with you when you leave. Your Personal Contributions & Earnings are 100% vested (yours to take).">
                                        <img src={infoIcon} alt="infoicon" />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              {/* <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {modulesServices.module17Data.contribute_401k === 'Yes' && (
                                            <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                              % Not Vested Returns to Employer
                                            </div>
                                          )}
                                          {modulesServices.module17Data.contribute_401k === 'No' && (
                                            <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                              Vesting Reductions
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {modulesServices.module17Data.employerContributionsAndEarningsNotVestedAdd !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.employerContributionsAndEarningsNotVestedAdd} prefix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedAdd >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.employerContributionsAndEarningsNotVestedAdd < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.contribute_401k === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending 401(k) Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.end_year}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.contribute_401k === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending 401(k) Balance

                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareer401kEndingBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareer401kEndingBalance} prefix={modulesServices.module17Data.furtherCareer401kEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareer401kEndingBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareer401kEndingBalanceRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareer401kEndingBalanceRoth} prefix={modulesServices.module17Data.furtherCareer401kEndingBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareer401kEndingBalanceRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                          <ReactTooltip id="detailsTooltip1" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)}/>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Roth IRA Retirement Account Summary</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.roth_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning Roth IRA Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.start_year}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.roth_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning Roth IRA Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRABeginningBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRABeginningBalance} prefix={modulesServices.module17Data.furtherCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRABeginningBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRABeginningBalanceRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRABeginningBalanceRoth} prefix={modulesServices.module17Data.furtherCareerRothIRABeginningBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRABeginningBalanceRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRATotalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRATotalContribution} prefix={modulesServices.module17Data.furtherCareerRothIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRATotalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>

                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRATotalContributionRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRATotalContributionRoth} prefix={modulesServices.module17Data.furtherCareerRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRATotalContributionRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                      <div className={classes.infoIcon} data-for="detailsTooltip1" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                        <img src={infoIcon} alt="infoicon" />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Earnings After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRATotalEarnings !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRATotalEarnings} prefix={modulesServices.module17Data.furtherCareerRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRATotalEarnings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRATotalEarningsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRATotalEarningsRoth} prefix={modulesServices.module17Data.furtherCareerRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRATotalEarningsRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.roth_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending Roth IRA Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.end_year}
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.roth_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending Roth IRA Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRAEndingBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRAEndingBalance} prefix={modulesServices.module17Data.furtherCareerRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRAEndingBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>

                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerRothIRAEndingBalanceRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerRothIRAEndingBalanceRoth} prefix={modulesServices.module17Data.furtherCareerRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerRothIRAEndingBalanceRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>

                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>SEP IRA Retirement Account Summary</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.ira_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning SEP IRA Balance in Year
                                            {modulesServices.module17Data.start_year}
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.ira_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning SEP IRA Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.addCareerSEPIRABeginningBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.addCareerSEPIRABeginningBalance} prefix={modulesServices.module17Data.addCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.addCareerSEPIRABeginningBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerSEPIRATotalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerSEPIRATotalContribution} prefix={modulesServices.module17Data.furtherCareerSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerSEPIRATotalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Earnings After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerSEPIRATotalEarnings !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerSEPIRATotalEarnings} prefix={modulesServices.module17Data.furtherCareerSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerSEPIRATotalEarnings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.ira_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending SEP IRA Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.end_year}
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.ira_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending SEP IRA Balance
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerEndingSEPIRABalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerEndingSEPIRABalance} prefix={modulesServices.module17Data.furtherCareerEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerEndingSEPIRABalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>Pension Summary</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.pension_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning Pension Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.start_year}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.pension_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Beginning Pension Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerPensionBeginningBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerPensionBeginningBalance} prefix={modulesServices.module17Data.furtherCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerPensionBeginningBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Personal Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerPensionTotalPersonalContributions !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerPensionTotalPersonalContributions} prefix={modulesServices.module17Data.furtherCareerPensionTotalPersonalContributions >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerPensionTotalPersonalContributions < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Employer Contributions After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerPensionTotalPersonalContributions !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerPensionTotalEmployerContributions} prefix={modulesServices.module17Data.furtherCareerPensionTotalEmployerContributions >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerPensionTotalEmployerContributions < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Earnings After
                                        {' '}
                                        {modulesServices.module17Data.end_year - ((modulesServices.module17Data.end_year !== 0) ? modulesServices.module17Data.start_year + 1 : 0)}
                                        {' '}
                                        Years
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerPensionTotalEarnings !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerPensionTotalEarnings} prefix={modulesServices.module17Data.furtherCareerPensionTotalEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerPensionTotalEarnings < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.pension_contribute === 'Yes' && (

                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending Pension Balance in Year
                                            {' '}
                                            {modulesServices.module17Data.end_year}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.pension_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending Pension Balance
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerEndingPensionBalance !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerEndingPensionBalance} prefix={modulesServices.module17Data.furtherCareerEndingPensionBalance >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerEndingPensionBalance < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.analysisSection}>
                            <div className={classes.analysisPortion}>
                              <div className={classes.analysisHeading}>
                                <div className={classes.analysisBlock}>
                                  <h3>HSA Summary</h3>
                                </div>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      Beginning HSA Balance
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerHsaBeginningBalanceSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerHsaBeginningBalanceSummary} prefix={modulesServices.module17Data.furtherCareerHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerHsaBeginningBalanceSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Contributions
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerHsaTotalContributionSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerHsaTotalContributionSummary} prefix={modulesServices.module17Data.furtherCareerHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerHsaTotalContributionSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                        Total Earnings
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerHsaTotalEarningsSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerHsaTotalEarningsSummary} prefix={modulesServices.module17Data.furtherCareerHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerHsaTotalEarningsSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.horizontalLine} />
                              <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                <Grid container>
                                  <Grid item sm={6} xs={6}>
                                    <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                      {modulesServices.module17Data.ira_contribute === 'Yes' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending HSA Balance
                                            {' '}
                                            {modulesServices.module17Data.end_year}
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                      {modulesServices.module17Data.ira_contribute === 'No' && (
                                        <div className={classes.spanText}>
                                          <strong>
                                            Ending HSA Balance
                                            {' '}
                                          </strong>
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3}>
                                    <div className={classes.inputRight}>
                                      <span className={classes.noInputText}>
                                        {modulesServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary} prefix={modulesServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={modulesServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary < 0 && ')'} />
                                        ) : ('$0')}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.cashFlowDiv}>
                                <Grid container spacing={0}>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    <div className={classes.whiteLeftBox}>
                                      <div className={classes.currencyTextNew}>
                                        <p><strong>Cash Flow Check</strong></p>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={6} xs={6} spacing={0}>
                                    {modulesServices.cashFlowCheckVal < 0 && (
                                      <div className={classes.cashFlowGreen}>
                                        <div>
                                          <span>Cash Flow Positive</span>
                                        </div>
                                      </div>
                                    )}
                                    {modulesServices.cashFlowCheckVal > 0 && (
                                      <div className={classes.cashFlowRed}>
                                        <div>
                                          <span>{modulesServices.cashFlowCheckVal}</span>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </Grid>


                      </Grid>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Typography>
  );
};

SummaryTables.propTypes = {
  classes: PropTypes.object.isRequired,
  linkId: PropTypes.number.isRequired
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(SummaryTables);
