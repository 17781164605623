import Sprite from '../../../assets/img/sprites-1.png';

const styles = (theme) => ({
  header: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: '0 0 0',
    height: '56px',
    boxShadow: ' 0 0 4px #333333',
    position: 'sticky',
    top: '0',
    '& > div': {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px',
      height: 'auto',
    },
  },
  bigHeader: {
    // padding: '23px 0 22px',
    padding: '21px 0 22px',
    height: 'auto',
    '@media (min-width: 960px)': {
      height: '86px',
    },
    '& > div > div': {
      alignItems: 'flex-end',
      '& > div:first-child': {
        position: 'relative',
        top: '8px',
        [theme.breakpoints.down('sm')]: {
          position: 'initial',
        }
      },
      '& > div:nth-child(3)': {
        paddingRight: '7px',
        position: 'relative',
        top: '14px',
        '@media (max-width: 399px)': {
          paddingRight: '0px',
        },
        '@media (min-width: 960px)': {
          top: '12px',
        },
        [theme.breakpoints.down('sm')]: {
          position: 'initial',
        }
      },

      '& > div:nth-child(2)': {
        '& ul': {
          position: 'relative',
          top: '6px',
          // padding: '0px 0 0 51px',
          padding: '0px 0 0 0px',
          '@media (max-width: 959px)': {
            top: '62px',
            padding: '0px',
            position: 'fixed',
            height: 'calc(100vh - 62px)',
            overflowY: 'auto',
          },
          '@media (min-width: 960px) and (max-width: 1099px)': {
            padding: '0px 0 0 30px',
          }
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 12px',
      height: 'auto',
    },
    '@media (max-width: 499px)': {
      alignItems: 'center',
    }
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // minHeight: '56px',
  },
  headerLeft: {
    cursor: 'pointer',
    '& img': {
      maxWidth: '140px',
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80px',
      },
      '@media (max-width: 399px)': {
        maxWidth: '80px',
      },
      '@media (min-width: 960px) and (max-width: 1139px)': {
        maxWidth: '100px',
      }
    },
  },
  headerMiddle: {
    margin: '0 auto 0 auto',
      '@media (min-width: 960px)': {
        position: 'relative',
        top: '-6px',
      },
    '& ul': {
      listStyle: 'none',
      padding: '0 0 0 30px',
      margin: '0',
      '& li': {
        display: 'inline-block',
        cursor: 'pointer',
        '& button': {
          color: '#000',
        },
        '& > div > div > div > div > div > div > div': {
          '@media (min-width: 1024px)': {
            overflow: 'auto',
            maxHeight: 'calc(100vh - 100px)',
          }
        },
        '& a': {
          color: '#333',
          fontSize: '16px',
          padding: ' 0 15px',
          lineHeight: '16px',
          textDecoration: 'none',
          [theme.breakpoints.down('md')]: {
            padding: ' 0 10px',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

  },
  headerRight: {
    // margin: '0 0 0 auto',
    margin: '0 0 0 0',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      '& li': {
        display: 'inline-block',
        cursor: 'pointer',
        '&:nth-child(2)': {
          '& span': {
            fontSize: '16px',
            padding: ' 0px 20px',
            lineHeight: '16px',
            textDecoration: 'none',
            textTransform: 'uppercase',
            borderRadius: '4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
              padding: ' 0px 10px !important',
              fontSize: '14px',
              lineHeight: '14px',
              maxWidth: '150px',
            },
            '@media (max-width: 376px)': {
              fontSize: '13px',
              padding: ' 0px 2px !important',
              maxWidth: '110px',
            },
          },
        },
        '&:last-child': {
          // padding: ' 0px 0px !important',
          '& span': {
            [theme.breakpoints.down('sm')]: {
              padding: ' 0px 5px !important',
            },
            '@media (max-width: 376px)': {
              padding: ' 0px 5px !important',
            }
          }
        }
      },

    },
  },
  headerRightNew: {
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        '&:first-child': {
          '& span': {
            [theme.breakpoints.down('sm')]: {
              padding: '5px 15px !important',
            }
          }
        },
        '&:last-child': {
          '& span': {
            [theme.breakpoints.down('sm')]: {
              padding: '5px 15px !important',
            }
          }
        },
      }
    }
  },
  headerRightList:{
    display: 'flex',
    alignItems: 'center',
    '& li':{
      '@media (min-width: 1200px)': {
        marginLeft: '10px',
      },
      '& ul':{
        '& li':{
          '@media (min-width: 1200px)': {
            marginLeft: '0px',
          },
        }
      }
    }
  },
  joinButton: {
    background: theme.palette.common.orange,
    color: theme.palette.common.white,
    textAlign: 'center',
    border: '2px solid #f89b22',
    padding: '9px 25px !important',
    borderRadius: '6px !important',
    lineHeight: '17px !important',
    '&:hover': {
      border: '2px solid #d47c0a',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '9px 25px !important',
    }
  },
  signIn: {
    position: 'relative',
    '& img': {
      width: '30px',
      height: '30px',
      display: 'inline-block',
      // padding: '10px 5px',
      borderRadius: '50%',
      verticalAlign: 'middle',
      position: 'relative',
      boxShadow: '0 0 0 2px rgb(255 255 255 / 80%)',
      [theme.breakpoints.down('xs')]: {
        width: '25px',
        height: '25px',
        top: '0px',
      },
      '@media (max-width: 399px)': {
        width: '20px',
        height: '20px',
      }
    },
    '& span': {
      color: '#333',
      paddingLeft: '5px !important',
      paddingRight: '10px !important',
      display: 'inline-block',
      padding: '10px 5px',
    },
  },
  menuToggle: {
    width: '0',
    overflow: 'hidden',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: 'auto',
    },
    '& i': {
      fontSize: '30px',
      verticalAlign: 'middle',
      paddingLeft: '15px',
      color: theme.palette.common.black,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '5px',
      },
      '@media (max-width: 399px)': {
        fontSize: '25px',
      }
    },
  },
  menuToggleClass: {
    '& ul': {
      display: 'block',
      position: 'absolute',
      background: theme.palette.common.white,
      width: '100%',
      left: '0',
      top: '69px',
      padding: '0',
      '@media (max-width: 959px)': {
        // height: 'calc(100vh - 65px)',
        top: '65px',
        height: 'calc(100vh - 65px)',
        padding: '0px',
        position: 'fixed',
        overflowY: 'auto',
      },
      '& li': {
        display: 'block',
        padding: '10px 5px',
      },
    },
  },
  headerInnerMegaMenu: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center !important',
    },
    '@media (min-width: 960px)': {
      position: 'relative',
    },
    '& ul': {
      '& li': {
        color: '#333',
        fontFamily: 'MuseoSans-100',
        fontWeight: '100',
        lineHeight: '23px',
        '& button': {
          color: '#333',
          paddingTop: '0',
          paddingBottom: '0',
          fontWeight: 'normal',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          lineHeight: '16px',
          [theme.breakpoints.down('md')]: {
            fontSize: '15px',
          },
          '@media (min-width: 960px) and (max-width: 1023px)': {
            paddingLeft: '3px',
            paddingRight: '3px',
          },
          '@media (min-width: 1024px) and (max-width: 1121px)': {
            paddingLeft: '8px',
            paddingRight: '8px',
          }
          // '& + div': {
          //   background: '#fff',
          // }
        },
      },
    },
  },
  walkthru: {
    padding: '5px 10px 5px',
    lineHeight: '15px !important',
    borderRadius: '4px',
    '&:hover': {
      background: '#333',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
    '& img': {
      width: '100%',
      cursor: 'pointer',
      maxWidth: '25px',
    },
  },
  accountMenu: {
    position: 'absolute',
    marginTop: '0',
    maxHeight: '500px',
    width: '314px !important',
    background: 'rgba(0, 105, 170, 0.9)',
    boxSizing: 'border-box',
    padding: '0px',
    top: '37px',
    left: 'auto',
    right: '10px',
    boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
    borderRadius: '4px',
    TransformOrigin: '93% -20px 0',
    MozTransformOrigin: '93% -20px 0',
    WebkitTransformOrigin: '93% -20px 0',
    MozTransition: 'transform .3s cubic-bezier(.3,1,.7,1) 0s,opacity .3s ease-out 0s',
    WebkitTransition: 'transform .3s cubic-bezier(.3,1,.7,1) 0s,opacity .3s ease-out 0s',
    transition: 'transform .3s cubic-bezier(.3,1,.7,1) 0s,opacity .3s ease-out 0s',
    transform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    transitionTimingFunction: 'ease',
    transitionDelay: '0s',
    transitionProperty: 'transform',
    [theme.breakpoints.down('xs')]: {
      width: '250px !important',
    },
    '& li': {
      borderBottom: '1px solid #448fc7',
      float: 'left',
      padding: '7px 10px',
      width: '100%',
      color: '#fff !important',
      boxSizing: 'border-box',
      display: 'flex !important',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#005495',
        color: '#fff',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      '& span': {
        fontSize: '16px',
        color: '#fff !important',
        padding: '0px !important',
        '&::before': {
          backgroundImage: `url(${Sprite})`,
          verticalAlign: 'middle',
          marginRight: '5px',
          content: '""',
          width: '27px',
          height: '20px',
          display: 'inline-block',
        },
      },
    },
    '&::before': {
      borderBottom: '11px solid #0069aa',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '90%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
  },
  accountIcon: {
    '&::before': {
      backgroundPosition: '-30px -3px',
      backgroundSize: '93px',
    },
  },
  bookIcon: {
    '&::before': {
      backgroundPosition: '0px -3px',
      backgroundSize: '80px',
    },
  },
  desktopIcon: {
    '&::before': {
      backgroundPosition: '-20px -26px',
      backgroundSize: '72px',
    },
  },
  dashboardIcon: {
    '&::before': {
      backgroundPosition: '-40px -3px',
      backgroundSize: '65px',
    },
  },
  logoutIcon: {
    '&::before': {
      backgroundPosition: '2px -28px',
      backgroundSize: '80px',
    },
  },
  accountMenuHide: {
    display: 'none',
  },
  notificationDropDown: {
    display: 'none',
  },
  notificationDropDownActive: {
    display: 'block',
    paddingTop: '0',
    border: '0',
    color: '#000',
    right: '-17px',
    left: 'auto',
    top: '48px',
    background: '#fff',
    width: '380px',
    zIndex: '99999',
    position: 'absolute',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
    cursor: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
    '@media (max-width: 1023px)': {
      right: '10px',
    },
    '&::before': {
      left: '88%',
      borderBottom: '11px solid #0069aa',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
  },
  resetNotifications: {
    marginRight: '10px',
  },
  notifyTitle: {
    background: '#0069aa',
    color: '#fff',
    fontSize: '18px',
    padding: '8px 6px',
    '& span': {
      background: '#fff',
      borderRadius: '2px !important',
      color: '#0082c0',
      cursor: 'pointer',
      float: 'right',
      fontSize: '11px !important',
      marginTop: '3px',
      padding: '3px 5px !important',
      textTransform: 'none !important',
      lineHeight: '1.42857143',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  notifyDate: {
    display: 'block',
    backgroundColor: '#f1f3f7',
    color: '#93a0b2',
    padding: '14px 8px',
    fontSize: '12px',
    lineHeight: '12px',
  },
  notiPortion: {
    padding: '15px 10px 25px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#e7e7e7',
      color: '#000',
    }
  },
  profileImg: {
    width: 'auto',
    height: 'auto',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& img': {
      width: '36px',
      height: '36px',
      cursor: 'default',
      display: 'inline-block',
      marginTop: '0',
      borderRadius: '50%',
      maxWidth: 'inherit',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  },
  notificationMid: {
    width: '88%',
    padding: '0 9px',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& span': {
      textTransform: 'none !important',
      textAlign: 'left',
      padding: '0 !important',
      fontSize: 'inherit !important',
    },
  },
  noteDate: {
    display: 'inline-block',
    fontSize: '12px !important',
    marginRight: '5px',
    textAlign: 'right',
    position: 'absolute',
    width: 'auto',
    right: '10px',
    bottom: '0',
    color: '#f89b23 !important',
    background: 'transparent',
  },
  join: {
    padding: '5px 10px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '4px 0px',
      marginLeft: '5px',
    },
    '@media (min-width: 960px) and (max-width: 1139px)': {
      padding: '4px 5px',
    },
    '& i': {
      fontSize: '24px',
      textAlign: 'center',
      width: '25px',
      '@media (max-width: 399px)': {
        fontSize: '18px',
        width: '20px',
      }
    },
    '&:hover': {
      backgroundColor: '#333',
      borderRadius: '4px',
      color: '#fff',
    },
  },
  notificationCount: {
    background: '#f89b23',
    borderRadius: '50% !important',
    color: '#fff',
    content: '""',
    fontSize: '13px !important',
    height: '22px',
    lineHeight: '22px !important',
    padding: '0 !important',
    position: 'absolute',
    right: '24px',
    textAlign: 'center',
    top: '-2px',
    width: '22px',
    '@media (max-width: 959px)': {
      width: '15px',
      height: '15px',
      right: '10px',
      fontSize: '10px !important',
      lineHeight: '16px !important',
    }
  },
  joinLink: {
    marginLeft: '16px',
    '@media (max-width: 959px)': {
      marginLeft: '5px',
    }
  },
  signLink: {
    paddingRight: '10px !important',
    padding: '8px 20px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    '@media (max-width: 376px)': {
      fontSize: '13px',
    }
  },
  signInLink: {
    '& span': {
      textTransform: 'uppercase',
    },
    '&:hover': {
      '&::before': {
        width: '100%',
        opacity: '1',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      background: '#0069aa',
      width: '0',
      height: '2px',
      opacity: '0',
      transition: 'all 0.5s ease-in-out',
      right: '0',
      margin: 'auto',
      boxSizing: 'border-box',
    },
  },
  shareIcon: {
    marginRight: '0px',
    // '@media (max-width: 499px)': {
    //   position: 'absolute',
    //   bottom: '2px',
    //   right: '0px',
    // },
    '@media (min-width: 960px) and (max-width: 1093px)': {
      marginRight: '0px',
    }
  },
  shareIconSpan: {
    display: 'flex !important',
    alignItems: 'center',
    textTransform: 'capitalize !important',
    padding: '7px 5px 7px 0px !important',
    borderRadius: '4px',
    '&:hover': {
      background: '#333',
      color: '#fff',
      '& img': {
        filter: 'brightness(0) invert(1)',
      }
    },
    '@media (min-width: 500px) and (max-width: 639px)': {
      padding: '5px 10px !important',
    },
    '@media (min-width: 960px) and (max-width: 1093px)': {
      padding: '5px 8px !important',
    },
    '& em':{
      fontStyle: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    }
  },
  profileLayer:{
    display: 'flex !important',
    alignItems: 'center',
    textTransform: 'capitalize !important',
    padding: '3.5px 10px !important',
    borderRadius: '4px',
    '& span':{
      paddingLeft: '0px !important',
      marginLeft: '15px',
      paddingRight: '0px !important',
      textTransform: 'capitalize !important',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '5px',
      },
    },
    '&:hover': {
      background: '#333',
      color: '#fff',
      '& span':{
        color: '#fff',
      }
    },
  },
  shareIconImg: {
    display: 'inline-block',
    padding: '0 !important',
    lineHeight: '0px',
    '& img': {
      width: '14px',
      marginRight: '5px',
      '@media (max-width: 1199px)': {
        width: '10px',
      },
    }
  },
  notificationSpanButton: {
    display: 'inline-block',
    marginLeft: '5px',
    '& button': {
      padding: '3px 8px !important',
      fontSize: '12px !important',
      borderRadius: '4px',
      textTransform: 'capitalize',
      lineHeight: '10px',
      background: '#0069aa',
      border: '2px solid #0069aa',
      color: '#fff !important',
      margin: '2px',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
      '&:last-child': {
        background: '#d9534f',
        border: '2px solid #d9534f',
      }
    }
  },
  notificationSpanCnt: {
    display: 'contents',
    '& div':{
      '& b': {
        color: theme.palette.common.blue,
        fontWeight: 'normal',
        fontFamily: '"MuseoSans-500"',
      },
    }
  },
  notificationDate: {
    display: 'block',
    backgroundColor: '#f1f3f7',
    color: '#93a0b2',
    padding: '14px 8px',
    fontSize: '12px',
    lineHeight: '12px',
  },
  bodyContentScroll: {
    maxHeight: '460px',
    // height: '395px',
    // minHeight: '220px',
    overflow: 'auto',
  },
  arrowIcon: {
    marginLeft: '10px',
    cursor: 'pointer',
    opacity: '0.5',
    transition: 'all 0.5s ease',
    '&:hover': {
      opacity: '0.8',
    },
    '@media (min-width: 960px)': {
      display: 'none',
    }
  },
  arrowIconActive: {
    transform: 'rotate(180deg)',
  },
  inkwirySpan: {

  },
  univestSpan: {
    position: 'relative',
    display: 'block',
    '& img': {
      maxWidth: '315px',
    }
  },
  inwiryLink: {
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    left: '0px',
  },
  univestLink: {
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    right: '0px',
  },
  noNotificationText: {
    padding: '10px',
    display: 'inline-block',
  },
  noNotificationBox: {
    textAlign: 'center',
    padding: '10px',
    '& img': {
      display: 'inline-block',
      marginTop: '0',
      maxWidth: '150px',
    },
    '& h3': {
      margin: '10px 0px',
      color: '#0069aa',
      fontFamily: '"MuseoSans-500"',
      fontSize: '24px',
      lineHeight: '1.1',
    },
    '& p': {
      fontSize: '18px',
      margin: '0 0 10px',
      fontFamily: '"MuseoSans-300"',
    }
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      minWidth: '600px',
      width: '600px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  popupHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  successText: {
    padding: '15px',
    '& p': {
      margin: '0px',
      color: 'green',

    }
  },
  rejectText: {
    padding: '15px',
    '& p': {
      margin: '0px',
      color: 'red',

    }
  },
  popupFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: ' 1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '6px 12px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '1.4',
      textTransform: 'capitalize',
      borderRadius: '4px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:first-child': {
        color: theme.palette.common.white,
        backgroundColor: '#337ab7',
        border: '1px solid transparent',
        marginRight: '5px',
        '&:hover': {
          backgroundColor: '#204d74',
          border: '1px solid transparent',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },
  },
  anchorSpan: {
    color: theme.palette.common.blue,
    cursor: 'pointer',
    textDecoration: 'underline',
    '& b': {
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  messagePopup: {
    position: 'relative',
    padding: '0px',
    minWidth: '600px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '100%',
    },
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '10px',
    color: '#000',
    float: 'none',
    right: '0',
    width: 'calc(100% - 17px)',
    border: '1px solid #f59331',
    cursor: 'pointer',
    height: '35px',
    opacity: '1',
    outline: 'none',
    zIndex: '99',
    position: 'relative',
    fontSize: '25px',
    background: '#fff',
    textAlign: 'right',
    lineHeight: '26px',
    textShadow: 'none',
    borderColor: '#fff',
    borderRadius: '0',
    borderBottom: '1px solid #ccc',
    left: '0',
    overflow: 'hidden',
    paddingRight: '15px',
  },
  popupBody: {
    padding: '35px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0px 0 20px',
    },
  },
  popupRejectCheck:{
    width: '142px',
    height: '142px',
    border: '4px solid red',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: 'red',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid red',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
});

export default styles;
