import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-car/styles';

const BuyingSecondCarTableBottom = ( props ) => {
  const { classes, handleBuyingCarObject, activeStep } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState( false );
  const [otherExpenses, setOtherExpenses] = React.useState( false );
  const [totalExpenses, setTotalExpenses] = React.useState( false );
  const handleExpandLiving = () => {
    setExpandLivingExpenses( !expandLivingExpenses );
  };

  const handleOtherExpenses = () => {
    setOtherExpenses( !otherExpenses );
  };

  const handleTotalExpenses = () => {
    setTotalExpenses( !totalExpenses );
  };

  const totalIncomeInYear = ( year ) => {
    const incomeData = moduleServices.incomeStatementCompleteData();
    if ( typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[year] !== 'undefined' && incomeData[year] !== '' && typeof incomeData[year]['Total Income'] !== 'undefined' && incomeData[year]['Total Income'] !== '' ) {
      return incomeData[year]['Total Income'];
    }
    return 0;
  };

  const totalIncomePercentage = ( year ) => {
    const totalIncomeInYr = totalIncomeInYear( year );
    if ( totalIncomeInYr > 0 ) {
      const expenses = moduleServices.totalAnnualExpensesSecondCar() + ( moduleServices.monthlyPayment() * 12 );
      return ( expenses / totalIncomeInYr ) * 100;
    }
    return 0;
  };

  React.useEffect( () => {
    if ( activeStep === 12 || activeStep === 26 ) {
      setExpandLivingExpenses( true );
      setOtherExpenses( true );
      setTotalExpenses( true );
    }
  }, [activeStep] );

  return (
    <div className={ classes.bottomTables }>
      <div className={ classes.leftTable }>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Purchase Year</th>
              <th>{handleBuyingCarObject.second_purchase_year}</th>
            </tr>
          </thead>
          <tbody>

            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Base Price</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice } prefix={ moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Destination Charges</td>
                <td>
                  {moduleServices.module12Data.moreDestinationChargesSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreDestinationChargesSummary } prefix={ moduleServices.module12Data.moreDestinationChargesSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreDestinationChargesSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Options</td>
                <td>
                  {moduleServices.module12Data.getMoreCarOptions !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.getMoreCarOptions } prefix={ moduleServices.module12Data.getMoreCarOptions >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.getMoreCarOptions < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Discount</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisDiscount !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisDiscount } prefix={ moduleServices.module12Data.moreInvestmentAnalysisDiscount >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisDiscount < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Rebate</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisRebate !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisRebate } prefix={ moduleServices.module12Data.moreInvestmentAnalysisRebate >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisRebate < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Trade-In Value</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisTradeIn !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisTradeIn } prefix={ moduleServices.module12Data.moreInvestmentAnalysisTradeIn >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisTradeIn < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleExpandLiving }>
              <td>
                <span>
                  {( !expandLivingExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( expandLivingExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Net Purchase Price</td>
              <td>
                {moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice } prefix={ moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>

            {otherExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Down Payment</td>
                <td>
                  {moduleServices.module12Data.moreCarDownPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreCarDownPayment } prefix={ moduleServices.module12Data.moreCarDownPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreCarDownPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Sales Tax</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisSalesTax !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisSalesTax } prefix={ moduleServices.module12Data.moreInvestmentAnalysisSalesTax >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisSalesTax < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Registration & Doc Fees</td>
                <td>
                  {moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees } prefix={ moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Origination Fees</td>
                <td>
                  {moduleServices.module12Data.moreCarLoanPoints !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreCarLoanPoints } prefix={ moduleServices.module12Data.moreCarLoanPoints >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreCarLoanPoints < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleOtherExpenses }>
              <td>
                <span>
                  {( !otherExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( otherExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Total Cash Needed at Purchase</td>
              <td>
                {moduleServices.module12Data.moreCashNeededAtPurchase !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreCashNeededAtPurchase } prefix={ moduleServices.module12Data.moreCashNeededAtPurchase >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreCashNeededAtPurchase < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Cash Balance at {' '}
                {handleBuyingCarObject.second_purchase_year - 1}
                {' '}
                Year End
              </td>
              <td>
                {moduleServices.module12Data.moreTotalCashAvailable !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreTotalCashAvailable } prefix={ moduleServices.module12Data.moreTotalCashAvailable >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreTotalCashAvailable < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Total Investments at {' '}
                {handleBuyingCarObject.second_purchase_year - 1}
                {' '}
                Year End
              </td>
              <td>
                {moduleServices.module12Data.moreTotalLiquid !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreTotalLiquid } prefix={ moduleServices.module12Data.moreTotalLiquid >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreTotalLiquid < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={ classNames( classes.leftTable, classes.rightTable ) }>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Monthly Expenses</th>
              <th>Monthly</th>
              <th>Annual</th>
            </tr>
          </thead>
          <tbody>
            {totalExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Loan Payment (Principal & Interest)</td>
                <td>
                  {moduleServices.module12Data.moreMonthlyPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreMonthlyPayment } prefix={ moduleServices.module12Data.moreMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreMonthlyPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.moreMonthlyPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreMonthlyPayment * 12 } prefix={ moduleServices.module12Data.moreMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.moreMonthlyPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Maintenance</td>
                <td>
                  {moduleServices.module12Data.secondCarMaintenanceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarMaintenanceCalcs / 12 } prefix={ moduleServices.module12Data.secondCarMaintenanceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarMaintenanceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.secondCarMaintenanceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarMaintenanceCalcs } prefix={ moduleServices.module12Data.secondCarMaintenanceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarMaintenanceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Car Insurance</td>
                <td>
                  {moduleServices.module12Data.secondCarInsuranceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarInsuranceCalcs / 12 } prefix={ moduleServices.module12Data.secondCarInsuranceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarInsuranceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.secondCarInsuranceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarInsuranceCalcs } prefix={ moduleServices.module12Data.secondCarInsuranceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarInsuranceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Gas</td>
                <td>
                  {moduleServices.module12Data.secondCarGasCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarGasCalcs / 12 } prefix={ moduleServices.module12Data.secondCarGasCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarGasCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.secondCarGasCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.secondCarGasCalcs } prefix={ moduleServices.module12Data.secondCarGasCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.secondCarGasCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleTotalExpenses }>
              <td>
                <span>
                  {( !totalExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( totalExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Total Expenses</td>
              <td>
                {moduleServices.module12Data.totalMonthlyExpensesSecondCar !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalMonthlyExpensesSecondCar } prefix={ moduleServices.module12Data.totalMonthlyExpensesSecondCar >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalMonthlyExpensesSecondCar < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {moduleServices.module12Data.totalAnnualExpensesSecondCar !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalAnnualExpensesSecondCar } prefix={ moduleServices.module12Data.totalAnnualExpensesSecondCar >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalAnnualExpensesSecondCar < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classes.blueRow }>
              <td />
              <td className={ classes.leftPadding }><i>% of Total Income</i></td>
              <td>
                {totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) } prefix={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) < 0 ? '%)' : '%' } />
                ) : ( '0.00%' )}
              </td>
              <td>
                {totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) } prefix={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentage( handleBuyingCarObject.second_purchase_year ) < 0 ? '%)' : '%' } />
                ) : ( '0.00%' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Total Income in Year {' '}
                {handleBuyingCarObject.second_purchase_year}
              </td>
              <td>
                {totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) /12 } prefix={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) } prefix={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.second_purchase_year ) < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

BuyingSecondCarTableBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default withStyles( styles )( BuyingSecondCarTableBottom );
