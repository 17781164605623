import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
// import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const LifeInsurance = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject } = props;
  const [LifeInsurances, setLifeInsurances] = React.useState({});
  const handleChanges = (e, name) => {
    const inputValues = {...handleAssestObject};
    const v = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[name] = v;
    handleUpdatedObject( inputValues );
    setLifeInsurances({...LifeInsurances, [name]: v})
  }
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        Enter the total cash value for all of your life insurance policies below.
      </p>
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Life Insurance</th>
          </thead>
          <tbody>
            <tr>
              <td><a href="https://www.investopedia.com/terms/c/cash-value-life-insurance.asp" target="_blank" rel="noreferrer">Life Insurance Total Cash Value</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale = {0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_cash_value_life_insurance_policies } name="existing_cash_value_life_insurance_policies" onValueChange={ (e)=> handleChanges(e, 'existing_cash_value_life_insurance_policies') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

LifeInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( LifeInsurance );
