import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Link,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import headerFI from '../../../assets/img/pwi-fi/vocation.png';
import styles from './styles';
import CareerPath from '../../../assets/img/career-path/cp.png';
import WorkSheet from '../../../assets/img/pwi-fi/worksheet-icon.png';
import fixMenuImage from '../../../assets/img/pwi-fi/mega-menu-pricing_img.png';
import Simulator from '../../../assets/img/pwi-fi/simulator-engine-blue.png';
import TimeLine from '../../../assets/img/pwi-fi/timeline_icon.png';
import Dollar from '../../../assets/img/pwi-fi/dollar-icon.png';
import Dashboard from '../../../assets/img/pwi-fi/dashboard.png';
import Clear from '../../../assets/img/pwi-fi/clear-timeline.png';
import Load from '../../../assets/img/pwi-fi/load.png';
import Download from '../../../assets/img/pwi-fi/download.png';
import Save from '../../../assets/img/pwi-fi/save.png';
import AssetsLiabilities from '../../../assets/img/pwi-fi/assets_liabilities.png';
import HigherEducation from '../../../assets/img/pwi-fi/higher_education.png';
import MoreHigherEducation from '../../../assets/img/pwi-fi/more_higher_education.png';
import FurtherHigherEducation from '../../../assets/img/pwi-fi/further_high_education.png';
import careerAdvancement from '../../../assets/img/pwi-fi/career_advancement.png';
import careerAchive from '../../../assets/img/pwi-fi/additional_career_advancement.png';
import furtherAareerAdvance from '../../../assets/img/pwi-fi/further_career_advance.png';
import Investments from '../../../assets/img/pwi-fi/investments.png';
import Family from '../../../assets/img/pwi-home/family.png';
import BuyCar from '../../../assets/img/pwi-fi/buy_car.png';
import fiImage from '../../../assets/img/pwi-fi/fi.png';
import BuyHome from '../../../assets/img/pwi-fi/buy_home.png';
import DocumentsList from './documents-list';
import RealEstateInvestment from '../../../assets/img/pwi-fi/real_estate_investment.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


function renderThumb({ style, ...props }) {
  const thumbStyle = {
    backgroundColor: '#eca041',
    borderRadius: '3px',
    width: '3px',
  };
  return (
    <div
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
}
renderThumb.propTypes = {
  style: PropTypes.object.isRequired,
};

const PwiLeftMenu = (props) => {
  const { classes, setMainMenu, openBeforeLeavePopup, setOpenPopup, setOpenDocsPopup, clearScenario, saveScenario } = props;
  
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = React.useState( false );

  const handleMainMenu = () => {
    setMainMenu(false);
  };

  const usePathname = (event,url,moduleName) => {
    event.preventDefault();
    openBeforeLeavePopup(url,moduleName);
  }

  return (
    <Typography variant="body1" component="div">
      <ReactTooltip id="worksheet" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.worksheetToolTip)} />
      <div>
        <div className={classes.fixedMenuHeader}>
          <Link href="/plan-with-inkwiry/fi" onClick={(event)=>{usePathname(event,'/worksheet', 'Worksheet')}}>
            <img src={WorkSheet} alt="" data-for="worksheet" data-tip="Switch to Worksheet View" />
          </Link>
          <i className="la la-close" aria-hidden="true" onClick={handleMainMenu} />
        </div>
        <div className={classes.planWithInkwiry}>
          <div className={classes.powerUpLogo}>
            <img src={fixMenuImage} alt="" />
          </div>
          <h1>Plan With Inkwiry</h1>
        </div>
        <div>
          <Scrollbars renderThumbVertical={renderThumb} className={classes.MainMenuNew} style={{ height: '100vh' }}>
            <ul>
              <li>
                <Link href="/plan-with-inkwiry/fi" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/fi', 'Financial Independence')}}>
                  <figure>
                    <img src={fiImage} alt="" />
                  </figure>
                  <span>Financial Independence</span>
                </Link>
              </li>
              <li >
                <Link href="/plan-with-inkwiry/existing" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/existing', 'Existing Assets + Liabilities')}}>
                  <figure>
                    <img src={AssetsLiabilities} alt="" />
                  </figure>
                  <span>Existing Assets + Liabilities</span>
                </Link>
              </li>
              <div className={classes.menuBoxNew}>
                <h5>Education + Training</h5>
                <li >
                  <Link href="/plan-with-inkwiry/he" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/he', 'Higher Education')}}>
                    <figure>
                      <img src={HigherEducation} alt="" />
                    </figure>
                    <span>Higher Education</span>
                  </Link>
                </li>
                <li>
                  <Link href="/plan-with-inkwiry/mhe" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/mhe', 'More Higher Education')}}>
                    <figure>
                      <img src={MoreHigherEducation} alt="" />
                    </figure>
                    <span>More Higher Education</span>
                  </Link>
                </li>
                <li>
                  <Link href="/plan-with-inkwiry/fhe" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/fhe', 'Further Higher Education')}}>
                    <figure>
                      <img src={FurtherHigherEducation} alt="" />
                    </figure>
                    <span>Further Higher Education</span>
                  </Link>
                </li>

              </div>
              <div className={`${classes.menuBoxNew} ${classes.menuBoxNewBg}`}>
                <h5 className={classes.menuBoxNewCareerBg}>CAREER MOVES</h5>
                <li>
                  <Link href="/plan-with-inkwiry/cp" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/cp', 'Career Path')}}>
                    <figure>
                      <img src={CareerPath} alt="" />
                    </figure>
                    <span>Career Path</span>
                  </Link>
                </li>
                <li>
                  <Link href="/plan-with-inkwiry/ca" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/ca', 'Career Advancement')}}>
                    <figure>
                      <img src={careerAdvancement} alt="" />
                    </figure>
                    <span>Career Advancement</span>
                  </Link>
                </li>
                <li>
                  <Link href="/plan-with-inkwiry/aca" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/aca', 'Additional Career Advancement')}}>
                    <figure>
                      <img src={careerAchive} alt="" />
                    </figure>
                    <span>Additional Career Advancement</span>
                  </Link>
                </li>
                <li>
                  <Link href="/plan-with-inkwiry/fca" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/fca', 'Further Career Advancement')}}>
                    <figure>
                      <img src={furtherAareerAdvance} alt="" />
                    </figure>
                    <span>Further Career Advancement</span>
                  </Link>
                </li>
              </div>
              <li>
                <Link href="/plan-with-inkwiry/investments" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/investments', 'Investments')}}>
                  <figure>
                    <img src={Investments} alt="" />
                  </figure>
                  <span>Investments</span>
                </Link>
              </li>
              <li>
                <Link href="/plan-with-inkwiry/family" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/family', 'Family')}}>
                  <figure>
                    <img src={Family} alt="" />
                  </figure>
                  <span>Family</span>
                </Link>
              </li>
              <li>
                <Link href="/plan-with-inkwiry/vacation" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/vacation', 'Vacation + Special Purchases')}}>
                  <figure>
                    <img src={headerFI} alt="" />
                  </figure>
                  <span>Vacation + Special Purchases</span>
                </Link>
              </li>
              <li>
                <Link href="/plan-with-inkwiry/car" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/car', 'Buying a Car')}}>
                  <figure>
                    <img src={BuyCar} alt="" />
                  </figure>
                  <span>Buying a Car</span>
                </Link>
              </li>
              <li>
                <Link href="/plan-with-inkwiry/home" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/home', 'Buying a Home')}}>
                  <figure>
                    <img src={BuyHome} alt="" />
                  </figure>
                  <span>Buying a Home</span>
                </Link>
              </li>
              <li>
                <Link href="/plan-with-inkwiry/rei" onClick={(event)=>{usePathname(event,'/plan-with-inkwiry/rei', 'Real Estate Investment')}}>
                  <figure>
                    <img src={RealEstateInvestment} alt="" />
                  </figure>
                  <span>Real Estate Investment</span>
                </Link>
              </li>
            </ul>
          </Scrollbars>
          <div className={classes.footerMenuFixed}>
            <ul>
              <li>
                <Link component="button" onClick={ () => { handleMainMenu();setOpenPopup( 'simulator' ); } } data-for="bottomTooltip" data-tip="Simulator Panel">
                  <img src={Simulator} alt="" />
                </Link>
              </li>
              <li>
                <Link href="/timeline-sketcher" onClick={(event)=>{usePathname(event,'/timeline-sketcher', 'Career Sketch Timeline')}} data-for="bottomTooltip" data-tip="Go to Timeline">
                  <img src={TimeLine} alt="" />
                </Link>
              </li>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={()=>{setSummaryDocumentsOpen(false)}}
              >
                <li className={classes.documentsMenu} > 
                  <Link component="button" onClick={ () => { setSummaryDocumentsOpen( !summaryDocumentsOpen ); } } data-for="bottomTooltip" data-tip="Summary Documents">
                    <img src={Dollar} alt="" />
                  </Link>
                  {summaryDocumentsOpen && (
                    <DocumentsList hideMenu={setMainMenu} setOpenDocsPopup={setOpenDocsPopup} setSummaryDocumentsOpen={setSummaryDocumentsOpen}/>
                  )}
                </li>
              </ClickAwayListener>
              <li>
                <Link href="/dashboard" onClick={(event)=>{usePathname(event,'/dashboard', 'Dashboard')}} data-for="bottomTooltip" data-tip="Go to Dashboard">
                  <img src={Dashboard} alt="" />
                </Link>
              </li>
              <li>
                <Link component="button" onClick={clearScenario} data-for="bottomTooltip" data-tip="Clear Inputs">
                  <img src={Clear} alt="" />
                </Link>
              </li>
              <li>
                <Link component="button" onClick={()=>openBeforeLeavePopup('','loadScenario','load')} data-for="bottomTooltip" data-tip="Load">
                  <img src={Load} alt="" />
                </Link>
              </li>
              <li>
                <Link component="button" data-for="bottomTooltip" data-tip="Download">
                  <img src={Download} alt="" />
                </Link>
              </li>
              <li>
                <Link component="button" onClick={saveScenario} data-for="bottomTooltip" data-tip="Save">
                  <img src={Save} alt="" />
                </Link>
              </li>
            </ul>
          </div>
          <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.moduleToolTip)} />
        </div>
      </div>
    </Typography>
  );
};

PwiLeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  mainMenu: PropTypes.bool.isRequired,
  setMainMenu: PropTypes.func.isRequired,
  openBeforeLeavePopup: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenario: PropTypes.func.isRequired,
};

export default withStyles( styles )( PwiLeftMenu );
