import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';
export let graphsData = {};

export function barGraph(data){
  // Pie Chart 2
  let higherEducationPaybackData = {};
  let paybackHTML = '';
   higherEducationPaybackData = moduleServices.higherEducationPaybackInitialize(data);
  higherEducationPaybackData = higherEducationPaybackData['Dynamic Bar Graph'];
    if(higherEducationPaybackData['Payback Break Year'] > 0) {
      let paybackPeriod = higherEducationPaybackData['Payback Break Year'];
       paybackHTML = '<div><a href="javascript:void(0);" ng-click="showHigherEducationPayback()"><strong>Payback Period: '+paybackPeriod+' years</strong></a></div>';
  } else {
       paybackHTML = '<div><a href="javascript:void(0);" ng-click="showHigherEducationPayback()"><strong>Payback Period: [  ] years</strong></a></div>';
  }

  graphsData['graph7Data'] = {
    chart: {
      height: 600
    },
    title: {
      text: ' '
    },
    navigation: {
      menuItemStyle: {
        fontSize: '14px',
        textAlign: 'left'
      },
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#4c4c4c',
        fontSize: '14px'
      },
      buttonOptions: {
        height: 40,
        width: 48,
        symbolSize: 24,
        symbolX: 24,
        symbolY: 21,
        symbolStrokeWidth: 2,
        verticalAlign: 'bottom',
        _titleKey: 'y'
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'downloadPNG',
            onclick(){
            const chart = this;
              chart.exportChart();
            },
          }, {
            textKey: 'downloadJPEG',
            onclick(){
              const chart = this;
          chart.exportChart({
                type: 'image/jpeg'
              });
            }
          }, {
            textKey: 'downloadPDF',
            onclick(){
              const chart = this;
          chart.exportChart({
                type: 'application/pdf'
              });
            }
          }, {
            textKey: 'downloadSVG',
            onclick(){
              const chart = this;
          chart.exportChart({
                type: 'image/svg+xml'
              });
            }
          }]
        }
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: higherEducationPaybackData['Years List'],
      labels: {
        style: {
          color: '#000000',
          fontSize: '14px'
        }
      }
    },
    yAxis: {
      title: {
        text: ' '
      },
      labels: {
        formatter() {
          const chart = this;
          return '$' + chart.value.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        },
        style: {
          color: '#000000',
          fontSize: '14px'
        }
      }
    },
    labels: {
      items: [{
        html: paybackHTML,
        style: {
          left: '300px',
          top: '3px',
          color: 'black',
          fontSize: '14px',
          borderColor: 'black',
          borderWidth: 2
        }
      }]
    },
    tooltip: {
      formatter() {
        let text = '';
        const chart = this;
        if (chart.series.name === 'Year') {
          text = chart.series.name + ': <b>' + chart.x + '</b>';
        } else {
          text = chart.x + '<br/>' + chart.series.name + ': $' + Highcharts.numberFormat(chart.y, 0);
        }
        return text;
      }
    },
    legend: {
      itemStyle: {
        fontSize: '14px'
      }
    },
    series: [{
      showInLegend: false,
      type: 'column',
      name: 'Year',
      data: higherEducationPaybackData['Payback Year'],
      color: '#70AD47'
    }, {
      type: 'spline',
      name: 'Higher Education Income After Taxes',
      data: higherEducationPaybackData['Higher Education Net Income'],
      marker: {
        lineWidth: 2,
        lineColor: '#5b9bd5',
        fillColor: 'white'
      }
    }, {
      type: 'spline',
      name: 'Without Higher Education Income After Taxes',
      data: higherEducationPaybackData['High School Net Income'],
      marker: {
        lineWidth: 2,
        lineColor: '#ed7d31',
        fillColor: 'white'
      }
    }]
  }
return graphsData['graph7Data'];
}

export function heShowMeGraphData() {
  const outputSheetCompleteData = moduleServices.investmentOutputBackendCompleteData();
  let totalHigherEducationValues = 0;
  let higherEducationOutputPieChartData = {};
  higherEducationOutputPieChartData = outputSheetCompleteData['Pie Chart'];
  if (higherEducationOutputPieChartData !== undefined && higherEducationOutputPieChartData['Total'] !== undefined ) {
  totalHigherEducationValues = higherEducationOutputPieChartData['Total'];
  graphsData['Total'] = totalHigherEducationValues;
}
   //Bar Graph1

   let fedPieChartCondition = true;
   if (totalHigherEducationValues === 0) {
     fedPieChartCondition = false;
   }
 
   //Federal subsidized graph
   let fedSubGraphCondition = true;
   if (outputSheetCompleteData['Federal Subsidized Graph'] !== undefined && outputSheetCompleteData['Federal Subsidized Graph']['yearsList'].length === 0) {
     fedSubGraphCondition = false
   }
 
   //Federal Unsubsidized graph
   let fedUnsubGraphCondition = true;
   if (outputSheetCompleteData['Federal Unsubsidized Graph'] !== undefined && outputSheetCompleteData['Federal Unsubsidized Graph']['yearsList'].length === 0) {
     fedUnsubGraphCondition = false
   }
   
   //Private graph
   let privateGraphCondition = true;
   if (outputSheetCompleteData['Private Graph'] !== undefined && outputSheetCompleteData['Private Graph']['yearsList'].length === 0) {
     privateGraphCondition = false
   }

     // Bar Graph 2
  let secondGraphCondition = true;
  if (outputSheetCompleteData['Bar Graph2 YearsList'] !== undefined && outputSheetCompleteData['Bar Graph2 YearsList'].length === 0) {
    secondGraphCondition = false
  }

  if(fedPieChartCondition === true ) {
  graphsData['graph1'] = true;
  graphsData['graph1Data'] = {
    chart: {
      plotBackgroundColor: '#ffffff',
      plotShadow: false,
      type: 'pie',
      zoomType: false,
    },
    navigation: {
      menuItemStyle: {
        fontSize: '14px',
        textAlign: 'left'
      },
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#4c4c4c',
        fontSize: '14px'
      },
      buttonOptions: {
        height: 40,
        width: 48,
        symbolSize: 24,
        symbolX: 24,
        symbolY: 21,
        symbolStrokeWidth: 2,
        _titleKey: 'y'
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'downloadPNG',
            onclick() {
              const chart = this;
              chart.exportChart();
            },
          }, {
            textKey: 'downloadJPEG',
            onclick() {
              const chart = this;
              chart.exportChart({
                type: 'image/jpeg'
              });
            }
          }, {
            textKey: 'downloadPDF',
            onclick() {
              const chart = this;
              chart.exportChart({
                type: 'application/pdf'
              });
            }
          }, {
            textKey: 'downloadSVG',
            onclick() {
              const chart = this;
              chart.exportChart({
                type: 'image/svg+xml'
              });
            }
          }]
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      // eslint-disable-next-line
      pointFormat: '${point.y:,.0f} , ({point.percentage:.0f}%)'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          //format: '<b>{point.name}</b>: {point.percentage:.0f}%',
          // eslint-disable-next-line
          format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
          style: {
            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            fontSize: '14px'
          }
        }
      }
    },
    series: [{
      name: moduleServices.module2Data.name_of_college,
      type: 'pie',
      data: [
        ['Room & Board', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalCollegeValue'] : 0],
        ['Rent', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalRentValue'] : 0],
        ['Utilities', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalUtilitiesValue'] : 0],
        ['Food', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalFoodValue'] : 0],
        ['Clothing & Personal Care', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalClothingValue'] : 0],
        ['Entertainment', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalEntertainmentValue'] : 0],
        ['Technology', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalTechnologyValue'] : 0],
        ['Transportation', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalTransportationValue'] : 0],
        ['Miscellaneous', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalMiscellaneousValue'] : 0],
        ['Car', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalCarValue'] : 0],
        ['Gas', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalGasValue'] : 0],
        ['Health Insurance', higherEducationOutputPieChartData !== undefined ? higherEducationOutputPieChartData['totalHealthInsuranceValue'] : 0],
      ].filter((d) => {
        return d[1] > 0
      })
    }]
  }
}else {
  graphsData['graph1'] = false;
}

  if (fedSubGraphCondition === true && outputSheetCompleteData['Federal Subsidized Graph'] !== undefined) {
    let subPaymentDataValue = moduleServices.fedSubMonthlyPayment();
    subPaymentDataValue = subPaymentDataValue.toFixed(2);
    if (subPaymentDataValue === '-Infinity') {
      subPaymentDataValue = '-';
    }
    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: outputSheetCompleteData['Federal Subsidized Graph'] !== undefined && outputSheetCompleteData['Federal Subsidized Graph']['yearsList'].length > 0 ? outputSheetCompleteData['Federal Subsidized Graph']['yearsList'] : [] ,
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Federal Subsidized Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Federal Subsidized Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#ed7d31'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Federal Subsidized Graph']['Principal Payback'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Federal Subsidized Graph']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#70AD47'
      }]
    }
    // ,()=> {
    //   const chart = this;
    //   let point = chart.series[0].data[8],
    //     text = chart.renderer.text(
    //       '<strong>Monthly Payment: ' + formatDollar(Math.round(subPaymentDataValue)) + '</strong>',
    //       chart.plotLeft + 30,
    //       chart.plotTop + 35
    //     ).attr({
    //       zIndex: 5
    //     }).add(),
    //     box = text.getBBox();

    //   chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
    //     .attr({
    //       fill: '#FFFFFF',
    //       stroke: '#2f75b5',
    //       'stroke-width': 1,
    //       zIndex: 4
    //     })
    //     .add();
    // }
  } else {
    graphsData['graph2'] = false;
  }

  if (fedUnsubGraphCondition === true && outputSheetCompleteData['Federal Unsubsidized Graph'] !== undefined) {
    graphsData['graph3'] = true;
    let unsubPaymentDataValue = moduleServices.fedUnSubMonthlyPayment();
    unsubPaymentDataValue = unsubPaymentDataValue.toFixed(2);
    if (unsubPaymentDataValue === '-Infinity') {
      unsubPaymentDataValue = '-';
    }
    graphsData['graph3Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: outputSheetCompleteData['Federal Unsubsidized Graph']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Federal Unsubsidized Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Federal Unsubsidized Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#FFCAA4'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Federal Unsubsidized Graph']['Principal Payback'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Federal Unsubsidized Graph']['Interest'],
        marker: {
          enabled: false
        },
        dashStyle: 'shortdot',
        tooltip: {
          valuePrefix: '$'
        },
        color: '#70AD47'
      }]
    }
    // , (chart)=> {
    //   let point = chart.series[0].data[8],
    //     text = chart.renderer.text(
    //       '<strong>Monthly Payment: ' + formatDollar(Math.round(unsubPaymentDataValue)) + '</strong>',
    //       chart.plotLeft + 30,
    //       chart.plotTop + 35
    //     ).attr({
    //       zIndex: 5
    //     }).add(),
    //     box = text.getBBox();

    //   chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
    //     .attr({
    //       fill: '#FFFFFF',
    //       stroke: '#2f75b5',
    //       'stroke-width': 1,
    //       zIndex: 4
    //     })
    //     .add();
    // }
  } else {
    graphsData['graph3'] = false;

  }

  if (privateGraphCondition === true && outputSheetCompleteData['Private Graph'] !== undefined) {
    graphsData['graph4'] = true;
    let paymentDataValue = moduleServices.privateMonthlyPayment();
    paymentDataValue = paymentDataValue.toFixed(2);
    if (paymentDataValue === '-Infinity') {
      paymentDataValue = '-';
    }
    graphsData['graph4Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: outputSheetCompleteData['Private Graph']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Private Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: outputSheetCompleteData['Private Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#FFBE00'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Private Graph']['Principal Payback'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: outputSheetCompleteData['Private Graph']['Interest'],
        marker: {
          enabled: false
        },
        dashStyle: 'shortdot',
        tooltip: {
          valuePrefix: '$'
        },
        color: '#70AD47'
      }]
    }
    // ,  (chart)=> {
    //   let point = chart.series[0].data[8],
    //     text = chart.renderer.text(
    //       '<strong>Monthly Payment: ' + formatDollar(Math.round(paymentDataValue)) + '</strong>',
    //       chart.plotLeft + 30,
    //       chart.plotTop + 35
    //     ).attr({
    //       zIndex: 5
    //     }).add(),
    //     box = text.getBBox();

    //   chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
    //     .attr({
    //       fill: '#FFFFFF',
    //       stroke: '#2f75b5',
    //       'stroke-width': 1,
    //       zIndex: 4
    //     })
    //     .add();
    // }

  } else {
    graphsData['graph4'] = false;
  }

  // Bar Graph 2

  if (secondGraphCondition === true && outputSheetCompleteData['Bar Graph2'] !== undefined && outputSheetCompleteData['Bar Graph2 YearsList'].length > 0) {
    graphsData['graph5'] = true;
    graphsData['graph5Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        zoomType: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: outputSheetCompleteData['Bar Graph2 YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000000'
          }
        }
      }],
      tooltip: {
        formatter() {
          const chart = this;
          return '<b>' + chart.x + '</b><br/>' +
            chart.series.name + ': $' + chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + '<br/>' +
            'Total: $' + chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        },
        itemWidth: 300
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Private Student Loan',
        data: outputSheetCompleteData['Bar Graph2']['Private Student Loan'],
        stack: 'male',
        color: '#FFC000',
        legendIndex: 14
      }, {
        name: 'Federal Unsubsidized Student Loan',
        data: outputSheetCompleteData['Bar Graph2']['Federal Unsubsidized Student Loan'],
        stack: 'male',
        color: '#F8CBAD',
        legendIndex: 12
      }, {
        name: 'Federal Subsidized Student Loan',
        data: outputSheetCompleteData['Bar Graph2']['Federal Subsidized Student Loan'],
        stack: 'male',
        color: '#ED7D31',
        legendIndex: 10
      }, {
        name: 'Family Contribution',
        data: outputSheetCompleteData['Bar Graph2']['Family Contribution'],
        stack: 'male',
        color: '#2E75B6',
        legendIndex: 8
      }, {
        name: 'Income Earned',
        data: outputSheetCompleteData['Bar Graph2']['Income Earned During'],
        stack: 'male',
        color: '#BDD7EE',
        legendIndex: 6
      }, {
        name: 'Financial Aid',
        data: outputSheetCompleteData['Bar Graph2']['Financial Aid'],
        stack: 'male',
        color: '#E2F0D7',
        legendIndex: 4
      }, {
        name: 'Grants',
        data: outputSheetCompleteData['Bar Graph2']['Grants'],
        stack: 'male',
        color: '#A9D18E',
        legendIndex: 2

      }, {
        name: 'Scholarships',
        data: outputSheetCompleteData['Bar Graph2']['Scholarships'],
        stack: 'male',
        color: '#548235',
        legendIndex: 0
      }, {
        name: 'Tuition & Fees',
        data: outputSheetCompleteData['Bar Graph2']['Tuition Cost'],
        stack: 'female',
        color: '#7F7F7F',
        legendIndex: 1
      }, {
        name: 'Living Expenses',
        data: outputSheetCompleteData['Bar Graph2']['Living Expenses'],
        stack: 'female',
        color: '#996600',
        legendIndex: 3
      }, {
        name: 'Estimated Taxes',
        data: outputSheetCompleteData['Bar Graph2']['Estimated Taxes'],
        stack: 'female',
        color: '#C80000',
        legendIndex: 5
      }, {
        name: 'Student Loan Payments',
        data: outputSheetCompleteData['Bar Graph2']['Student Loan Payments during School'],
        stack: 'female',
        color: '#A00FF1',
        legendIndex: 7
      }, {
        name: 'Other Loan Payments',
        data: outputSheetCompleteData['Bar Graph2']['Other Loan Payments on Other Debt'],
        stack: 'female',
        color: '#01FFCC',
        legendIndex: 9
      }, {
        name: 'Car Expenses',
        data: outputSheetCompleteData['Bar Graph2']['Car Expenses'],
        stack: 'female',
        color: '#FF66FF',
        legendIndex: 11
      }, {
        name: 'Real Estate Expenses',
        data: outputSheetCompleteData['Bar Graph2']['Real Estate Expenses'],
        stack: 'female',
        color: '#9999FF',
        legendIndex: 13
      }, {
        name: 'Children and Pet Expenses',
        data: outputSheetCompleteData['Bar Graph2']['Children and Pet Expenses'],
        stack: 'female',
        color: '#FFFFFF',
        legendIndex: 15,
        borderColor: '#000',
      }]
    }
  }
  else {
    graphsData['graph5'] = false;
  }

  if (outputSheetCompleteData['Bar Graph2'] !== undefined && outputSheetCompleteData['Bar Graph2 YearsList'] !== undefined) {
    let graph = {};
    graph.higherEducationSalary = 0;
    graph.highSchoolSalary = 0;
    graph.highEducationGrowthRateOnSalary = 0;
    graph.highSchoolGrowthRateOnSalary = 0;
    graph.tuitionCosts = -(moduleServices.module2Data.investmentAnalysisTuitionCostsCal);
    graph.scholarships = moduleServices.module2Data.investmentAnalysisScholships;
    graph.grants = moduleServices.module2Data.investmentAnalysisGrants;
    graph.financialAid = moduleServices.module2Data.investmentAnalysisFinancialAid;
    graph.capitalInvestmentMinus = graph.tuitionCosts -
      moduleServices.module2Data.investmentAnalysisScholships -
      moduleServices.module2Data.investmentAnalysisGrants -
      moduleServices.module2Data.investmentAnalysisFinancialAid;
    if (graph.capitalInvestmentMinus > 0) {
      graph.capitalInvestment = graph.capitalInvestmentMinus;
    } else {
      graph.capitalInvestment = 0;
    }
  
  
    graph.loan = moduleServices.module2Data.studentLoanTotalInterestPaid;
    graph.loanPoints = moduleServices.module2Data.fedSubTotalInterestPaid + moduleServices.module2Data.fedUnSubTotalInterestPaid + moduleServices.module2Data.privateTotalInterestPaid + moduleServices.module2Data.summaryLoanPoints;
  
    let higherEducationPaybackData = moduleServices.higherEducationPaybackInitialize(graph);
    graph.excessFamilyContribution = higherEducationPaybackData['Excess Family Contribution Total'];
    
    graphsData['graph6'] = true;
    graphsData['graph6Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'pie',
        height: 300
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
      // eslint-disable-next-line
      pointFormat: '{series.name}: <b>${point.y:,.0f}</b> (<b>{point.percentage:.0f}%</b>)'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer'
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter() {
              const chart = this;
              return Math.round((chart.percentage * 100) / 100) + '%';
            },
            distance: -50,
            color: '#000',
            style: {
              fontSize: '14px'
            }
          }
        }
      },
      series: [{
        name: ' ',
        data: [{
          name: 'Excess Family Contribution',
          y: graph.excessFamilyContribution,
          color: '#0070B0'
        }, {
          name: 'Loan (Interest) + Points',
          y: graph.loanPoints,
          color: '#FF7322'
        }, {
          name: 'Capital<br/>Investment',
          y: graph.capitalInvestment,
          color: '#58AD4B'
        }
        ].filter((d) => {
          return d['y'] > 0
        })
      }]
    }
    graphsData['calculationsData'] = graph;
    graphsData['graph7Data'] = barGraph(graph);
  }
  else {
    graphsData['graph6'] = false;
  }



  // 'module2-bargraph3'


    // graphsData['graph7'] = true;
    // graphsData['graph7Data'] = 

  return graphsData;
}


