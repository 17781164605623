import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../../screens/pwi-family/styles';
import * as modulesServices from '../../../calculations/modules-services';

const SpouseCareerAdvancementFirst = (props) => {
  const {
    classes, handleDefinationPopup, updateData, inputData
  } = props;

  const [spouseOccupation, setSpouseOccupation] = useState((inputData.second_occupation !== undefined && inputData.second_occupation !== '') ? inputData.second_occupation : '');
  const [spouseStartCareerYear, setSpouseStartCareerYear] = useState(0);
  const [spouseYearDiff, setSpouseYearDiff] = useState(0);
  const [startYearOptions, setStartYearOptions] = useState([]);
  const [noOfYears, setNoOfYears] = useState([]);
  const [endYear, setEndYear] = useState('');

  useEffect(() => {
    let start_year = parseInt(modulesServices.module1Data.start_year);
    const yearsDiffOptions = [0];
    let inputDataValues = { ...inputData };
    inputDataValues['second_start_year'] = spouseStartCareerYear;
    if (inputData.marriege_year !== undefined && inputData.marriege_year !== null && inputData.marriege_year > 0 && start_year !== undefined && start_year > 0) {
      if (spouseStartCareerYear > 0) {
        const diff = start_year - spouseStartCareerYear + 15;
        for (let i = 1; i <= diff; i += 1) {
          yearsDiffOptions.push(i);
        }
        if (spouseStartCareerYear !== undefined && inputData['second_end_year'] !== undefined && spouseStartCareerYear > 0 && inputData['second_end_year'] > 0) {
          inputDataValues['second_years_diff'] = inputData['second_end_year'] - inputData['second_start_year'] + 1;
        } else {
          inputDataValues['second_years_diff'] = 0;
        }
        let index = yearsDiffOptions.indexOf(inputDataValues['second_years_diff']);
        if (inputDataValues['second_years_diff'] > 0 && index === -1) {
          inputDataValues['second_years_diff'] = yearsDiffOptions[yearsDiffOptions.length - 1];
        }
      } else {
        inputDataValues['second_end_year'] = 0;
        inputDataValues['second_years_diff'] = 0;
      }
    } else {
      inputDataValues['second_end_year'] = 0;
      inputDataValues['second_years_diff'] = 0;
    }
    if (spouseStartCareerYear > 0 && inputDataValues['second_years_diff'] > 0) {
      inputDataValues['second_end_year'] = spouseStartCareerYear + inputDataValues['second_years_diff'] - 1;
    }
    setNoOfYears(yearsDiffOptions);
    setSpouseYearDiff(inputDataValues['second_years_diff']);
    updateData('total_obj', inputDataValues);
    // eslint-disable-next-line
  }, [spouseStartCareerYear])

  useEffect(() => {
    let inputDetails = { ...inputData }
    const familtStartYearOptions = [0];
    if (inputData.marriege_year !== undefined && inputData.marriege_year !== null && inputData.marriege_year !== '' && inputData.marriege_year > 0 && modulesServices.module10Data.end_year !== undefined && modulesServices.module10Data.end_year > 0) {
      for (let i = modulesServices.module10Data.end_year + 1; i <= modulesServices.module1Data.start_year + 14; i += 1) {
        familtStartYearOptions.push(i);
      }
      if (inputData['second_start_year'] === undefined || inputData['second_start_year'] === null) {
        inputDetails['second_start_year'] = 0;
      }
      if (inputData['second_start_year'] > 0) {
        const yearIndex = familtStartYearOptions.indexOf(inputData['second_start_year']);
        if (yearIndex === -1) {
          inputDetails['second_start_year'] = familtStartYearOptions[1];
        }
      }
      if (inputData['second_end_year'] === undefined || inputData['second_end_year'] === null) {
        inputDetails['second_end_year'] = 0;
      }
      if (inputData['second_start_year'] !== undefined && inputData['second_end_year'] !== undefined && inputData['second_start_year'] > 0 && inputData['second_end_year'] > 0) {
        inputDetails['second_years_diff'] = inputData['second_end_year'] - inputData['second_start_year'] + 1;
      } else {
        inputDetails['second_years_diff'] = 0;
      }
    } else {
      inputDetails['second_start_year'] = 0;
      inputDetails['second_end_year'] = 0;
      inputDetails['second_years_diff'] = 0;
    }
    updateData('total_obj', inputDetails);

    setSpouseStartCareerYear(inputDetails['second_start_year']);
    setSpouseYearDiff(inputDetails['second_years_diff']);
    setEndYear(inputDetails['second_end_year']);
    setStartYearOptions(familtStartYearOptions);
    // eslint-disable-next-line
  }, []);

  const handleSpousesOccupation = (e) => {
    setSpouseOccupation(e.target.value);
    updateData('second_occupation', e.target.value);
  };

  const handleSpouseStartCareerYear = (e) => {
    setSpouseStartCareerYear(parseInt(e.target.value, 10));
  };

  const handleSpouseCareerYearCount = (e) => {
    let inputDetails = { ...inputData };
    inputDetails['second_years_diff'] = parseInt(e.target.value, 10);
    inputDetails['second_end_year'] = inputDetails['second_start_year'] + inputDetails['second_years_diff'] - 1;
    setEndYear(inputDetails['second_start_year'] + inputDetails['second_years_diff'] - 1);
    setSpouseYearDiff(parseInt(e.target.value, 10));
    updateData('total_obj', inputDetails);
  };


  return (
    <div className={classes.contentBlock}>
      <p>Will your spouse make another career move? What will that next move be and what&apos;s the potential earnings increase?</p>
      <p>If your spouse is not planning another career move, please use the skip button below.</p>
      <div className={`${classes.grayBox} ${classes.grayBoxHalf}`}>
        <div className={classes.occupationName}>
          <span>
            <span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Occupation')}>Occupation:</span>
            {' '}
            <input type="text" placeholder="ex. Real Estate Broker" value={spouseOccupation} onChange={handleSpousesOccupation} />
          </span>
        </div>
        <ul className={classes.yearsList}>
          <li>
            <span>My spouse will start this career move in year&nbsp;</span>            
              <select value={spouseStartCareerYear} onChange={handleSpouseStartCareerYear}>
                {
                  startYearOptions && startYearOptions.map((yr) => <option value={yr}>{yr}</option>)
                }
              </select>            
          </li>
          <li>
            <span>My spouse will be in this career move for&nbsp;</span>
            <select value={spouseYearDiff} onChange={handleSpouseCareerYearCount}>
              {
                noOfYears && noOfYears.map((cnt) => <option value={cnt}>{cnt}</option>)
              }
            </select>
            &nbsp;years.
          </li>
          {!Number.isNaN(endYear) && endYear > 0 && (
            <li>
              <span>
                The end year for this career move is &nbsp;
                {endYear}
                .
              </span>
            </li>
          )}
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module10" />
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
    </div>
  );
};

SpouseCareerAdvancementFirst.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles(styles)(SpouseCareerAdvancementFirst);
