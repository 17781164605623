import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const SecondCarBuyingTotalPurchase = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject,
  } = props;
  const [basePrice, setBasePrice] = React.useState( 40000 );
  const [destination, setDestination] = React.useState(600);
  const [options, setOptions] = React.useState(2500);

  useEffect( () => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.base_price !== undefined ) {
      setBasePrice( handleBuyingCarObject.base_price );
    }else {
      setBasePrice( 40000 );
      totalPurchaseObj.base_price = 40000;
    }
    if ( handleBuyingCarObject.destination_charges !== undefined ) {
      setDestination( handleBuyingCarObject.destination_charges );
    }else {
      setDestination( 600 );
      totalPurchaseObj.destination_charges = 600;
    }
    if ( handleBuyingCarObject.car_options !== undefined ) {
      setOptions( handleBuyingCarObject.car_options );
    }else {
      setOptions( 2500 );
      totalPurchaseObj.car_options = 2500;
    }
    handleUpdatedObject( totalPurchaseObj );
    // eslint-disable-next-line
  }, []);

  const updateValue = ( e, value, field, type ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };

    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    totalPurchaseObj[field] = newvalue;
    switch ( field ) {
      case 'second_base_price': {
        setBasePrice( newvalue );
        break;
      }
      case 'second_destination_charges': {
        setDestination( newvalue );
        break;
      }
      case 'second_options': {
        setOptions( newvalue );
        break;
      }
      default: {
        break;
      }
    }
    handleUpdatedObject( totalPurchaseObj );
  };

  return (
    <div className={ classes.contentBlock }>
      <div>
        <p>
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Base price' ) }>Base price</span>
          , also known as Manufacturer&apos;s Suggested Retail Price (MSRP) or Sticker Price, is the cost of the vehicle before destination charges, options, discounts, and rebates. For example, this would be the price before you configure your or negotiate the price of your vehicle.
        </p>
        <p>
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Destination Charges: Buying a Car' ) }>Destination charges</span>
          {' '}
          are fees that the dealership charge for getting your vehicle to the lot. Ever pass by trucks on the highway carrying multiple cars? You&apos;re witnessing the destination charges in action. These fees are almost always non-negotiable and can range from $400 to $1,200.
        </p>
        <p>
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Options' ) }>Options</span>
          {' '}
          are additional upgrades that you plan to purchase that are not included in the base price. For example, blind spot protection, an upgraded technology package, extended warranty, seat warmers, automatic headlights, and more would be considered options.
        </p>
      </div>
      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>  
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Base price' ) }>Base Price</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.carImageArray() }
                  min={ 0 }
                  max={ 140000 }
                  step={ 100 }
                  value={ basePrice }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( basePrice )}` }
                  onChange={ ( e, value ) => { updateValue( e, value, 'second_base_price', 'slider' ); } }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ basePrice === '' ? 0 : basePrice } onValueChange={ ( e ) => updateValue( e, '', 'second_base_price', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Destination Charges: Buying a Car' ) }>Destination Charges</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.DestinationValues() }
                  min={ 0 }
                  max={ 1400 }
                  step={ 10 }
                  value={ destination }
                  valueLabelFormat={ destination }
                  onChange={ ( e, value ) => { updateValue( e, value, 'second_destination_charges', 'slider' ); } }

                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ destination === '' ? 0 : destination } onValueChange={ ( e ) => updateValue( e, '', 'second_destination_charges', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />

              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Options' ) }>Options</span></p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ sliderValues.optionArray() }
              min={ 0 }
              max={ 8000 }
              step={ 50 }
              value={ options }
              valueLabelFormat={ `${commonFunctions.thousandFormatter( options )}` }
              onChange={ ( e, value ) => { updateValue( e, value, 'second_options', 'slider' ); } }

            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            $
            <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ options === '' ? 0 : options } onValueChange={ ( e ) => updateValue( e, '', 'second_options', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />

          </div>
        </div>
      </div>
        </div>
      </div>
      <div className={ classes.priceSliders }>
        <div className={ classes.priceText }>
          <p>&nbsp;</p>
        </div>
        <div className={ classes.slideGraphNew }>
          <p><b>Total Purchase Price</b></p>
        </div>
        <div className={ classes.priceValue }>
          <span className={ classes.dollarSymbol }>$</span>
          <span className={ classes.inputValue }>
          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ basePrice + destination + options } prefix={'$'} />
            </span>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SecondCarBuyingTotalPurchase.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( SecondCarBuyingTotalPurchase );
