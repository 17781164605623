import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import styles from './styles';
import instagram from '../../../assets/img/instagram.png';
import facebook from '../../../assets/img/f.png';
import twitter from '../../../assets/img/twitter.png';
import youtube from '../../../assets/img/youtube.png';
import { Dialog, IconButton } from '@material-ui/core';
import pf101FinancialTookit from '../../../../src/assets/img/pf101/PF101_Financial_Tookit.pdf';
import inkwirysCareerSketchingToolkit from '../../../../src/assets/img/news/Inkwirys_Career_Sketching_Toolkit.pdf';
// import CancelRounded from '@material-ui/icons/CancelRounded';

const Footer = (props) => {
  const { classes } = props;

  const [open, setOpen] = React.useState(false);
  const [introduction, setIntroduction] = React.useState(false);
  const [pcs, setPcs] = React.useState(false);
  const [qti, setQti] = React.useState(false);
  const [mp, setmp] = React.useState(false);
  const [ib, setIb] = React.useState(false);
  const [getGuide, setGetGuide] = React.useState(false);
  const [join, setJoin] = React.useState(false);

  const handlePopup = (type) => {
    setOpen(true)
    if (type === 0) {
      setIntroduction(true);
      setPcs(false);
      setQti(false);
      setmp(false);
      setIb(false);
      setGetGuide(false);
      setJoin(false);
    } else if (type === 1) {
      setPcs(true);
      setIntroduction(false);
      setQti(false);
      setmp(false);
      setIb(false);
      setGetGuide(false);
      setJoin(false);
    } else if (type === 2) {
      setQti(true);
      setPcs(false);
      setIntroduction(false);
      setmp(false);
      setIb(false);
      setGetGuide(false);
      setJoin(false);
    } else if (type === 3) {
      setQti(false);
      setPcs(false);
      setGetGuide(false);
      setJoin(false);
      setIntroduction(false);
      setIb(false);
      setmp(true);
    } else if (type === 4) {
      setIb(true);
      setQti(false);
      setPcs(false);
      setGetGuide(false);
      setJoin(false);
      setIntroduction(false);
      setmp(false);
    }
    // else if (type === 5) {
    //   setIb(false);
    //   setQti(false);
    //   setPcs(false);
    //   setIntroduction(false);
    //   setmp(false);
    //   setGetGuide(true);
    //   setJoin(false);
    // }
    // else if (type === 6) {
    //   setIb(false);
    //   setQti(false);
    //   setPcs(false);
    //   setIntroduction(false);
    //   setmp(false);
    //   setGetGuide(false);
    //   setJoin(true);
    // }
  }

  const handleGuide = () =>{
    var hiddenElement = document.createElement('a');
      hiddenElement.href = 'https://inkwiry.com/guide';
      hiddenElement.target = '_blank';
      hiddenElement.click();
  }

  const handleWaitlist = () =>{
    var hiddenElement = document.createElement('a');
      hiddenElement.href = 'https://inkwiry.com/waitlist';
      hiddenElement.target = '_blank';
      hiddenElement.click();
  }





  // const redirectToJoin = () => {
  //   history.push('/pricing');
  // };

  // const redirectToLogin = () => {
  //   history.push('/login');
  // };

  // const redirectToAppTools = () => {
  //   history.push('/awareness');
  // };

  // const redirectToNews = () => {
  //   history.push('/news');
  // };

  // const redirectToAbout = () => {
  //   history.push('/about');
  // };

  // const redirectToContact = () => {
  //   history.push('/contact');
  // };

  // const redirectToWhyInnkwiry = () => {
  //   history.push('/why-inkwiry/students');
  // };

  return (
    <div className={classes.newFooterDark} id="footer">
      <div className={classes.footerOne} >
        <Container className={classes.container}>
          <Grid container spacing={2} className={classes.containerRow}>
            <Grid item xs={12} sm={4} md={4} className={classes.footerDesc}>
              <div className={classes.buildBest}>
                <Typography variant="h3" component="h3">
                  <span>See and build</span>
                  <span>your best</span>
                  <span>tomorrows now</span>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8} className={classes.footerRightBlock}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4} >
                  <p>APPS + TOOLS</p>
                  <ul className={classes.appToolsMenu}>
                    <li><Link to="/fc-blueprint">Plan With Inkwiry</Link></li>
                    <li><Link to="/personal-finance-101">Personal Finance 101</Link></li>
                    <li><Link to="/my-career-sketch">My Career Sketch</Link></li>
                    <li><Link to="/awareness">Awareness Challenge</Link></li>
                    <li><Link to="/discovery">Discovery Challenge</Link></li>
                    <li><Link to="/questions">Powerful Questions</Link></li>
                    <li><Link to="/career-sketch">Career Sketch Stories</Link></li>
                    <li><Link to="/finance-explained">Finance Explained</Link></li>
                    <li><Link to="/financial-calculators">Financial Calculators</Link></li>
                    <li><Link to="/financial-calculators/compare-schools">Compare Schools</Link></li>
                    <li><Link to="/financial-calculators/compare-careers">Compare Careers</Link></li>
                  </ul>
                  <div className={classes.footerTopMargin}>
                    <p>VIDEOS</p>
                    <ul className={classes.appToolsMenu}>
                      <li><span className={classes.clickHere} onClick={() => { handlePopup(0) }}>Introducing Inkwiry</span></li>
                      <li><span className={classes.clickHere} onClick={() => { handlePopup(1) }}>Power of Career Sketching</span></li>
                      <li><span className={classes.clickHere} onClick={() => { handlePopup(2) }}>Quick Tour of Inkwiry</span></li>
                      <li><span className={classes.clickHere} onClick={() => { handlePopup(3) }}>A Message for Parents</span></li>
                      <li><span className={classes.clickHere} onClick={() => { handlePopup(4) }}>Inkwiry for Businesses</span></li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} >
                  <p>WHY INKWIRY</p>
                  <ul className={classes.appToolsMenu}>
                    <li><Link to="/why-inkwiry/benefits">Inkwiry Benefits</Link></li>
                    <li><Link to="/why-inkwiry/students">Inkwiry for Students</Link></li>
                    <li><Link to="/why-inkwiry/parents">Inkwiry for Parents</Link></li>
                    <li><Link to="/why-inkwiry/young-professionals">Inkwiry for Young Professionals</Link></li>
                    <li><Link to="/why-inkwiry/schools">Inkwiry for Schools</Link></li>
                  </ul>
                  <div className={classes.footerTopMargin}>
                    <p>FREE TEST DRIVES</p>
                    <ul className={classes.appToolsMenu}>
                      <li><Link to='/plan-with-inkwiry/he'>Higher Education Module</Link></li>
                      <li><Link to='/plan-with-inkwiry/cp'>Career Path Module</Link></li>
                      <li><Link to="/pf101/test-drive-budgeting-101">Personal Finance 101 - Budgeting</Link></li>
                    </ul>
                  </div>
                  <div className={classes.footerTopMargin}>
                    <p>RESOURCES</p>
                    <ul className={classes.appToolsMenu}>
                      <li><Link to="/tutorials">Tutorials</Link></li>
                      <li><Link to="/life-event-library">Life Event Library</Link></li>
                      <li><a href={pf101FinancialTookit} download>Personal Finance 101 Toolkit</a></li>
                      <li><a href={inkwirysCareerSketchingToolkit} download>Career Sketching Toolkit</a></li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} >
                  <p>COMPANY</p>
                  <ul className={classes.appToolsMenu}>
                    <li><Link to="/aboutus">About Inkwiry</Link></li>
                    <li><Link to="/team">Leadership Team</Link></li>
                    <li><Link to="/news">News</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                  <div className={classes.footerTopMargin}>
                    <p>PRICING</p>
                    <ul className={classes.appToolsMenu}>
                      <li><Link to="/pricing">For Individuals</Link></li>
                      <li><Link to="/pricing/schools">For Schools + Universisties</Link></li>
                      <li><Link to="/pricing/business">For Busineses</Link></li>
                    </ul>
                  </div>
                  <div className={classes.footerTopMargin}>
                    <p>PROMOTIONS</p>
                    <ul className={classes.appToolsMenu}>
                      <li><span className={classes.clickHere} onClick={handleGuide }>Get the Guide</span></li>
                      <li><span className={classes.clickHere} onClick={ handleWaitlist }>Join the Waitlist</span></li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.socialLinks}>
            <ul>
              <li><Link to="https://www.instagram.com/inkwiryfc"> <img src={instagram} alt="instagram" /></Link></li>
              <li><Link to="https://www.facebook.com/inkwiry"><img src={facebook} alt="" /></Link></li>
              {/* <li><Link to="https://www.instagram.com/inkwiryfc"><img src={twitter} alt="" /></Link></li> */}
              <li><Link to="https://www.youtube.com/channel/UCy876qbkaDzXn28luoWUY2w"><img src={youtube} alt="" /></Link></li>
            </ul>
          </div>

          {/* <div className={ classes.footerInner }>
            <div className={ classes.footerTop }>
              <ul>
                <li><span aria-hidden="true" onClick={ redirectToHome }>Home</span></li>
                <li><span aria-hidden="true" onClick={ redirectToAppTools }>Apps + Tools</span></li>
                <li><span aria-hidden="true" onClick={ redirectToAbout }>About</span></li>
                <li><span aria-hidden="true" onClick={ redirectToWhyInnkwiry }>Why Inkwiry</span></li>
                <li><span aria-hidden="true" onClick={ redirectToNews }>News</span></li>
                <li><span aria-hidden="true" onClick={ redirectToJoin }>Pricing</span></li>
                <li><span aria-hidden="true" onClick={ redirectToContact }>Contact</span></li>
                <li><span aria-hidden="true" onClick={ redirectToLogin }>Sign In</span></li>
              </ul>
            </div>
            <div className={ classes.footerBottom }>
              <Grid container>
                <Grid item sm={ 4 }>
                  <div className={ classes.copyrights }>
                    <p>Copyright © 2020 • All rights reserved</p>
                  </div>
                </Grid>
                <Grid item sm={ 4 }>
                  <div className={ classes.footerLinks }>
                  <ul>
                      <li><Link to="/termsofservice">Terms Of Services</Link></li>
                      <li><Link to="/privacy" >Privacy Policy</Link></li>
                      <li><Link to="/security">Security</Link></li>
                    </ul>
                  </div>
                </Grid>
                <Grid item sm={ 4 }>
                  <div className={ classes.poweredBy }>
                    <p>
                      Informed and Powered by
                      <img src={ LogoFooter } alt="Footer Logo" />
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div> */}
        </Container>
      </div>
      <div className={classes.footerTwo} >
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} >
              <div className={classes.copyRightLeft}>
                <p>Copyright © 2022 Inkwiry  LLC  All rights reserved.</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.copyRightCenter}>
                <p>
                  <Link to="/privacy" >Privacy Policy</Link>
                  <span>{'//'}</span>
                  <Link to="/termsofservice" >Terms of Service</Link>
                  <span>{'//'}</span>
                  <Link to="/security" >Security</Link>
                  {/* <span>{'//'}</span>
                  <Link to="" >Site Map</Link> */}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.copyRightRight}>
                <p>Informed and powered by Inkwiry</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        className={classes.dialogVideo}
        maxWidth='lg'
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          className={classes.closeIcon}
        >
         X
        </IconButton>
        {introduction ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/V6nRimAMD9c?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ""
        }
        {pcs ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/54vDPdwMRzE?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ''
        }
        {qti &&
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/9dscV917BEw?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        }
        {mp ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/AwghHcuDP7k?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ''
        }
        {ib ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/RFVcLEtfjkg?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ''
        }
        {getGuide ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/XMLtemzlHUs?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ''
        }
        {join ?
          <iframe
            width="750"
            height="430"
            src="https://www.youtube.com/embed/1KMIIyZ3x0g?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          :
          ''
        }
      </Dialog>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Footer);
