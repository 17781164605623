const styles = ( theme ) => ( {
  modalBody: {
    maxHeight: '407px',
    overflowY: 'auto',
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
    '& formInput': {
      width: '100%',
    },
  },
  modalFooterDiv:{
    background: '#fff',
  },
  modalFooter: {
    position: 'relative',
    padding: '7px 15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
    },
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    textTransform: 'capitalize',
  },
  resetButton: {
    color: '#fff',
    backgroundColor: '#d58512',
    borderColor: '#985f0d',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  okButton: {
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    margin: '0 5px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  fullInput: {
    '& div': {
      width: '100%',
    },
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  showWorksheet: {
    backgroundColor: '#f0ad4e',
    borderRadius: '4px',
    border: '1px solid #eea236',
    padding: '6px 12px',
    marginLeft: '0',
    fontSize: '14px',
    fontFamily: 'MuseoSans-100',
    fontWeight: '400',
    display: 'inline-block',
    height: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#fff',
    textTransform: 'uppercase',
    width: 'auto',
    minWidth: 'auto',
    lineHeight: '1.42857143',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      width: 'calc(89% - 120px)',
    },
  },
  maxModule: {
    '& > div': {
      alignItems: 'baseline',
    },
    '& > div > div ': {
      width: '80%',
      maxWidth: '100%',
      verticalAlign: 'top',
      maxHeight: '100%',
      overflow: 'auto',
      margin: '30px auto',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  maxModalBody: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  maxHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '18px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  modalClose: {
    textAlign: 'right',
    lineHeight: '1',
    fontSize: '20px',
    zIndex: '9',
    cursor: 'pointer',
    '&:hover': {
      opacity: '.5',
    },

  },
  maxClose: {
    fontSize: '21px',
    lineHeight: '1',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    opacity: '.5',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-700"',
    top: '15px',
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:last-child': {
        color: '#333',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  backButton: {
    backgroundColor: '#fff !important',
    border: '2px solid #d5d5d5 !important',
    color: '#333 !important',
    '&:hover': {
      backgroundColor: ' #d5d5d5 !important',
    },
  },
  NoFiDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  closingCosts: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody': {
      '& tr': {
        background: '#F2F2F2',
        borderBottom: '2px solid #fff',
        height: '43px',
        '& td': {
          fontSize: '16px',
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          borderTop: '1px solid #ddd',
          position: 'relative',
          color: '#000',
          '&:nth-child(2)': {
            textAlign: 'right',
            verticalAlign: 'bottom',
          },
          '& input': {
            width: '85px',
            border: 'none',
            outline: '0',
            background: '#f2f2f2',
            borderBottom: '1px solid #0069aa',
            textAlign: 'right',
            boxSizing: 'border-box',
            height: '25px',
            fontSize: '16px',
            color: '#000',
            '&:focus': {
              borderBottom: '1px solid #f89b22 !important',
            },
          },
          '& div': {
            '&::before': {
              border: 'none !important',
            },
            '&::after': {
              border: 'none !important',
            },
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        background: '#dae3f3',
        fontWeight: 'normal',
        fontSize: '16px',
        borderBottom: '2px solid #4472c4',
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          borderTop: '1px solid #ddd',
          '&:nth-child(2)': {
            textAlign: 'right',
            verticalAlign: 'bottom',
          },
        },
      },
    },
  },
  closingCostsModal: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      maxWidth: '600px',
      width: '600px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& h2': {
      fontSize: '22px',
      margin: '0px',
      color: '#0069aa',
      fontWeight: '500',
    },
  },
  homeSlider: {
    background: 'linear-gradient(to bottom,#de6d03 0,#de6d03 100%)',
  },
  summaryListMenu: {
    position: 'absolute',
    left: 'auto',
    zIndex: '9999!important',
    top: 'auto',
    bottom: '100%',
    marginBottom: '8px',
    display: 'block',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    borderRadius: '4px',
    WebkitBoxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxSizing: 'border-box',
    '& li': {
      '& p': {
        padding: '4px 20px',
        width: '100%',
        textAlign: 'left',
        background: '0 0',
        border: 'none',
        outline: '0',
        clear: 'both',
        fontWeight: '400',
        color: '#333',
        boxSizing: 'border-box',
        display: 'block',
        height: 'auto',
        margin: '0px',
        cursor: 'pointer',
        '&:hover': {
          color: '#262626',
          textDecoration: 'none',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    '&::before': {
      top: '100%',
      borderTop: '11px solid #fff',
      left: '44%',
      borderBottom: '11px solid transparent',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      width: '0',
    },
  },
  logoPic: {
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  downloadPdf: {
    borderRadius: '4px',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    transition: 'all .5s ease 0s',
    background: '#efefef',
    textTransform: 'none',
    padding: '6px 12px',
    [theme.breakpoints.down( 'xs' )]: {
     marginleft: '10px',
    },
    '&:hover': {
      color: '#333',
      textDecoration: 'none',
      backgroundImage: 'none',
      outline: '0',
      background: '#efefef',
    },
  },
  incomeStatementPopup: {
    padding: '10px',
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      maxWidth: '98.5%',
      width: '98.5%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
      transform: 'translate(0,0)',
      overflow: 'hidden',
      margin: 'auto',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  popupTable: {
    maxHeight: '450px',
    height: '100%',
    overflow: 'auto',
    paddingRight: '17px',
    boxSizing: 'border-box',
  },
  topIncomeBg: {
    color: 'white',
    background: 'rgb(0, 51, 86)',
    height: '28px',
  },
  incomeSheetTable: {
    borderCollapse: 'collapse',
    borderSpacing: '0px',
    margin: '0',
    outline: 'none',
    color: '#333333',
    width: '100%',
    fontFamily: 'MuseoSans-300',
    '& thead': {
      position: 'sticky',
      top: '0px',
      '& tr': {
        '& th': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          fontWeight: 'normal',
          lineHeight: '1.42857143',
          textAlign: 'right',
          '&:nth-child(1)': {
            padding: '3px',
            minWidth: '382px',
            textAlign: 'left',
            boxSizing: 'border-box',
          },
          '&:nth-child(2)': {
            width: '115px',
            textAlign: 'right',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        border: '1px solid #dddddd',
        '& td': {
          verticalAlign: 'bottom',
          borderBottom: '1px solid #DDDAD6',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          cursor: 'pointer',
          textAlign: 'right',
          borderCollapse: 'collapse',
          lineHeight: '1.42857143',
          '&:nth-child(1)': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  headerTable: {
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: '0px',
      margin: '0',
      outline: 'none',
      width: '100%',
      fontFamily: 'MuseoSans-300',
    },
  },
  blueBorderLine: {
    '& td': {
      borderBottom: '2px solid #006FBB !important',     
    },
  },
  blankSpace: {
    background: '#fff',
    '& td': {
      fontSize: '0px',
      height: '15px',
      background: '#fff',
    },
  },
  blueRowData: {
    '& td': {
      backgroundColor: ' #006FBB',
      color: '#FFF',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-700"',
    },
  },
  subHeadingData: {
    backgroundColor: '#DDDAD6',
    color: '#006FBB',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-700"',
    fontSize: '17px',
    borderBottom: '2px solid transparent ',
    width: '350px',
    paddingLeft: '30px !important',
  },
  headingData: {
    backgroundColor: '#DDDAD6',
    color: '#006FBB',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-700"',
    fontSize: '16px !important',
    borderBottom: '1px solid transparent !important',
  },
  lightBlueBg: {
    backgroundColor: '#DDDAD6',
    borderBottom: '1px solid transparent',
  },
  emptyRowdata: {
    '& td': {
      height: '13px',
      lineHeight: '13px !important',
      borderBottom: '2px solid #006FBB !important',
    },
  },
  emptyRowdataGray:{
    '& td':{
      borderBottom: '1px solid #DDDAD6 !important',
    }
  },
  emptyRowdataTop:{
    '& td':{
      borderTop: '2px solid #006FBB !important',
      borderBottom: '0px !important',
    }
  },
  modalBodyData: {
    padding: '15px',
    position: 'relative',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    margin: '0 -24px',
  },
  closeButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  modalFooterNew: {
    padding: '10px 15px',
  },
  incomePopupClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    padding: '0',
    background: 'transparent',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
      background: 'transparent',
    },
  },
  whiteInputSmall: {
    '& input': {
      padding: '1px 2px',
      height: 'auto',
      minHeight: '24px',
      boxSizing: 'border-box',
    },
  },
  rightPanel: {
    paddingLeft: '15px',
    paddingRight: '15px',
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontWeight: '600',
      fontSize: '22px',
      textAlign: 'center',
      marginTop: '20px',
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      backgroundColor: 'transparent',
      color: '#000',
      '& tr': {
        '& td': {
          fontWeight: '600',
          padding: '0',
          lineHeight: '1.42857143',
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
        },
      },
    },
  },
  greenBox: {
    background: '#58AD4B',
  },
  squareBox: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'transparent',
    fontSize: '11px',
    border: '1px solid #3475b0',
    marginRight: '5px',
    boxSizing: 'border-box',
    width: '21px',
  },
  blueBox: {
    background: '#0074B0',
  },
  yellowBox: {
    background: '#FFBE00',
  },
  heightOneEight: {
    height: '18px',
  },
  symCurrency: {
    paddingLeft: '17px !important',
  },
  piBreakdown: {
    '& tbody': {
      '& tr': {
        '& td': {
          '& small': {
            fontSize: '16px',
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '177px',
          },
        },
      },
    },
  },
  containerRowNew: {
    margin: '0 -15px',
  },
  rowSpace: {
    padding: '0 15px',
  },
  orangeBox: {
    background: '#FF7322',
  },
  tooltipInfoNew: {
    width: '400px',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: '400px',
  },
  inputsTitleNew: {
    fontSize: '22px',
  },
  valueField: {
    padding: '5px !important',
  },
  chartImage: {
    marginTop: '200px',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  activeCP: {
    borderTop: '2px solid #2268AE',
    paddingTop: '2px',
  },
  cpSlider: {
    background: 'linear-gradient(to bottom,#3169b0 0,#3169b0 100%)',
  },
  activeCareerAdvancement: {
    borderTop: '2px solid #3795e6',
    paddingTop: '2px',
  },
  caSlider: {
    background: 'linear-gradient(to bottom,#4a96e9 0,#4a96e9 100%)',
  },
  activeAdditionalCareerAdvancement: {
    borderTop: '2px solid #81c3dc',
    paddingTop: '2px',
  },
  addcaSlider: {
    background: 'linear-gradient(to bottom,#8ac3dd 0,#8ac3dd 100%)',
  },
  activeFurtherCareerAdvancement: {
    borderTop: '2px solid #8790b8',
    paddingTop: '2px',
  },
  furthercaSlider: {
    background: 'linear-gradient(to bottom,#8790b8 0,#8790b8 100%)',
  },
  graphColumn: {
    padding: '0px 10px',
  },
  refinancedData: {
    padding: '0px',
    margin: '0px',
    background: '0 0',
  },
  refinancedTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    marginBottom: '15px',
    width: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    '& tbody': {
      '& tr': {
        '& td': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          padding: '0 15px',
          lineHeight: '28px',
          '& h2': {
            marginBottom: '0',
            color: '#000',
            fontWeight: '600',
            fontSize: '22px',
            fontFamily: 'MuseoSans-500',
            textAlign: 'center',
            width: '100%',
          },
          '& h3': {
            fontSize: '18px!important',
            fontFamily: 'MuseoSans-500',
            marginTop: '28px',
            marginBottom: '0',
            color: '#000',
            fontWeight: '600',
            '& span': {
              fontSize: '26px',
            },
          },
        },
        '&:nth-child(3)': {
          '& td': {
            '& table': {
              '&::before': {
                content: '""',
                width: 'calc(100% - 0px)',
                position: 'absolute',
                top: '350px',
                height: '2px',
                background: '#2f75b5',
                left: '0',
                right: '0',
                margin: 'auto',
              },
            },
          },
        },
      },
    },
  },
  blueRule: {
    borderTop: '2px solid #2f75b5',
    margin: '0 0 10px',
  },
  esGraph: {
    padding: '0px !important',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      background: '#f2f2f2',
      '& tr': {
        '& td': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          padding: '0 15px',
          lineHeight: '28px',
          color: '#000',
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
        },
      },
    },
  },
  showTooltip: {
    width: '300px',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: '300',
  },
  rsLoans: {
    '& table': {
      background: '#e7f1f9',
    },
  },
  rsInput: {
    position: 'relative',
    '& input': {
      fontFamily: 'MuseoSans-500',
      padding: '0 5px',
      width: '100%',
      border: 'none',
      background: '#fff',
      height: 'inherit',
      lineHeight: 'inherit',
    },
  },
  rsPercent: {
    fontSize: '13px',
    right: '25px',
    marginTop: '-11px',
    textAlign: 'right',
    color: '#333',
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    top: '11px',
    fontFamily: 'MuseoSans-700',
  },
  downArrow: {
    '& img': {
      height: '207px',
      padding: '20px 20px 20px 0',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  },
  leftMinusMargin: {
    '& h3': {
      marginLeft: '-15px',
      marginRight: '-15px',
    },
    '& hr': {
      marginLeft: '-15px',
      marginRight: '-15px',
    },
  },
  radioBlock: {
    display: 'inline-block',
    padding: '0px 0px 0px 18px',
  },
  graphHead: {
    '& h3': {
      color: '#70ad47',
      fontSize: '40px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-700"',
      marginBottom: '0',
      marginTop: '20px',
      textAlign: 'center',
      boxSizing: 'border-box',
      lineheight: '1.1',
    },
  },
  blueDivider: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
  },
  moreSavings: {
    '& h3': {
      color: '#000',
      fontSize: '18px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-700"',
      marginTop: '20px',
      textAlign: 'center',
      '& span': {
        display: 'block',
      },
    },
    '& ul': {
      paddingLeft: '0',
      listStyle: 'none',
      marginTop: '0px',
      marginBottom: '0px',
      '& li': {
        textAlign: 'center',
        border: '2px solid #ccc',
        fontSize: '13px',
        float: 'left',
        wordBreak: 'break-word',
        width: '18%',
        margin: '1% 1% 2%',
        color: '#000',
        boxSizing: 'border-box',
        '& span': {
          display: 'block',
          padding: '3px',
          '&:first-child': {
            height: '58px',
            background: '#f2f2f2',
          },
          '& i': {
            display: 'block',
          },
        },
      },
    },
  },
  whatIfScenario: {
    padding: '5px 10px',
    width: '30%',
    margin: '0 auto',
    fontWeight: '600',
    background: '#2f75b5',
    color: '#fff',
    textAlign: 'center',
    fontSize: '30px',
    lineHeight: '1.1',
  },
  blockListRow: {
    display: 'flex',
    '& ul': {
      border: '2px solid #ccc',
      width: '98%',
      margin: '30px auto',
      paddingLeft: '0px',
      listStyle: 'none',
      '& li': {
        width: '30%',
        margin: '2% 10%',
        '& span': {
          padding: '8px',
        },
        '& input[type="range"]': {
          maxWidth: '290px',
          width: '100%',
          height: '35px',
          padding: '0',
          cursor: 'pointer',
          margin: '0 auto',
        },
      },
    },
  },
  titleSpan: {
    background: '#2f75b5 !important',
    color: '#fff',
    height: 'auto !important',
  },
  greyColorSpan: {
    background: '#f2f2f2',
    display: 'block',
  },

  moneySpan: {
    padding: '12px 3px !important',
    display: 'block',
    background: 'transparent',
  },
  borderInput: {
    border: '1px solid #ccc',
    padding: '4px',
    '& input': {
      color: '#000',
      border: 'none',
      margin: '10px 3px 6px',
      textAlign: 'center',
    },
  },
  versusRow: {
    display: 'block',
    '& ul': {
      width: '80%',
      margin: '44px auto',
      '& li': {
        width: '43%',
        margin: '1%',
        '&:nth-child(2)': {
          width: '8%',
          border: '2px solid #2f75b5',
          borderRadius: '100%',
          fontSize: '25px',
          fontWeight: '600',
          padding: '14px',
          marginTop: '23px',
        },
        '& span': {
          height: 'auto !important',
        },
      },
    },
  },
  savingsTitle: {
    color: '#a6a6a6',
    fontSize: '34px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    marginBottom: '0',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '30px',
    lineHeight: '1.1',
    boxSizing: 'border-box',
  },
  blackBorder: {
    borderBottom: 'solid 2px #000',
  },
  currentTable: {
    width: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    color: '#000',
    '& tbody': {
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          fontSize: '17px',
          borderTop: 'none',
          textAlign: 'left',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          fontSize: '17px',
          borderTop: 'none',
          position: 'relative',
        },
      },
    },
  },
  heightThirty: {
    height: '30px',
  },
  borderBlueDash: {
    border: '2px dashed #337ab7!important',
  },
  currentBlock: {
    padding: '0px 10px',
  },
  flexDisplay: {
    display: 'flex',
  },
  boldText: {
    '& td': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  clearFix: {
    clear: 'both',
  },
  translateList: {
    width: '100%',
  },
  curvesTitle: {
    marginBottom: '0',
    color: '#000',
    fontWeight: '600',
    fontSize: '22px',
    paddingTop: '30px',
    textAlign: 'center',
    marginTop: '20px',
  },
  graphSpace: {
    marginLeft: '5px',
  },
  topTable: {
    verticalAlign: 'top',
    width: '30%',
    backgroundColor: '#fff',
    padding: '0 3px',
    position: 'static',
    fontSize: '17px',
    cursor: 'default',
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          cursor: 'pointer',
          lineHeight: '1.42857143',
          color: '#000',
        },
      },
    },
  },
  blueTableRow: {
    '& td': {
      backgroundColor: '#006fbb',
      color: '#fff !important',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-700"',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent',
    },
  },
  blackTopBorder: {
    '& td': {
      borderTop: '2px solid #000',
      fontFamily: 'MuseoSans-700',
      fontWeight: 'normal',
    },
  },
  borderStrip: {
    position: 'relative',
    margin: '0 0 10px',
    fontSize: '17px',
    lineHeight: '1.42857143',
    color: '#000',
    '&::before': {
      borderBottom: '2px solid #337ab7',
      bottom: '0',
      content: '""',
      left: '0',
      position: 'absolute',
      width: '100%',
      zIndex: '999',
    },
  },
  heightTen: {
    height: '10px',
  },
  heightFifty: {
    height: '50px',
  },
  noteRei: {
    fontStyle: 'italic',
    margin: '0 0 10px',
  },
  blueTableRowNew: {
    backgroundColor: '#003356',
    color: '#fff',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-700"',
    fontSize: '17px',
    borderBottom: '1px solid transparent',
  },
  greyBackgroundRow: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        backgroundColor: '#dddad6',
        color: '#006fbb',
        fontWeight: 'normal',
        fontFamily: '"MuseoSans-700"',
        fontSize: '17px',
        lineHeight: '1.42857143',
        borderBottom: '1px solid #dddad6',
        boxSizing: 'border-box',
        '&:first-child': {
          width: '435px',
          position: 'relative',
          zIndex: '1',
        },
      },
    },
  },
  cashFlowBlue: {
    '& td': {
      backgroundColor: '#003356 !important',
      color: '#fff !important',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-700"',
      fontSize: '17px !important',
      borderBottom: '1px solid transparent !important',
      lineHeight: '1.42857143',
    },
  },
  incomeCashTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        padding: '0px 30px',
      },
    },
  },
  emptySpaceRow: {
    '& td': {
      height: '13px',
      lineHeight: '13px !important',
    },
  },
  blueTopLine: {
    '& td': {
      borderBottom: '2px solid #006fbb !important',
    },
  },
  leftSpaceData: {
    '& td': {
      padding: '0px 3px !important',
    },
  },
  blueRowBackground: {
    '& td': {
      backgroundColor: '#006fbb !important',
      color: '#fff !important',
      fontWeight: '700 !important',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent  !important',
      lineHeight: '1.42857143',
    },
  },
  emptySpaceRowNew: {
    backgroundColor: '#fff',
    '& td': {
      backgroundColor: '#fff !important',
    },
  },
  leftItalic: {
    paddingLeft: '40px !important',
    '& i': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal !important',
    },
  },
  notePara: {
    marginTop: '20px',
  },
  reiModal: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
  reiPopup: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      borderRadius: '6px',
      maxHeight: 'calc(100% - 20px)',
    },
    '& > div > div > div': {
      padding: '0px',
    },
  },
  buyingHomeOne: {
    width: '32%',
  },
  buyingHomeTwo: {
    width: '43%',
  },
  blueRei: {
    borderTop: '2px solid #2f75b5',
    marginBottom: '10px',
    marginTop: '0',
    height: '0',
    boxSizing: 'content-box',
  },
  borderLine: {
    borderBottom: '2px solid #006fbb',
  },
  upFronttable: {
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  homeModalBody: {
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
  },
  tableResponsive: {
    minHeight: '0.01%',
    overflowy: 'auto',
  },
  annualCashTable: {
    width: '100%',
    maxWidth: '350px',
  },
  summaryFooterNew: {
    padding: '10px 15px',
    bordertop: 'none',
  },
  familyImage: {
    '& img': {
      width: 'auto',
      height: '35px',
      display: 'block',
      margin: '6px 0 0',
      verticalAlign: 'middle',
      visibility: 'hidden',
    },
  },
  exText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '20px',
    padding: '10px 9px',
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
  },
  dollarSign: {
    float: 'right',
    left: '0',
    paddingLeft: '5px',
    position: 'absolute',
    color: '#0069aa',
    fontFamily: 'MuseoSans-700',
  },
  calcTextSpace: {
    paddingLeft: '0px',
    lineHeight: '60px',
  },
  exampleText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
  },
  checkboxRadio: {
    margin: '3px 0px',
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      position: 'relative',
      fontWeight: '400',
      padding: '0 0 0 30px',
      margin: '0',
      color: '#000',
      '&::after': {
        background: '#fff',
        border: '1px solid #898d8f',
        content: '""',
        height: '20px',
        left: '0',
        position: 'absolute',
        top: '0%',
        width: '20px',
        boxSizing: 'border-box',
      },
      '& input[type=radio]': {
        display: 'none',
      },
    },
  },
  checkedEmployment: {
    '&::before': {
      borderColor: '#0069aa',
      borderImage: 'none',
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      content: '""',
      display: 'block',
      height: '14px',
      left: '7px',
      opacity: '1',
      position: 'absolute',
      top: '2px',
      transform: 'rotate(45deg)',
      visibility: 'visible',
      width: '6px',
      zIndex: '9',
      boxSizing: 'border-box',
    },
  },
  tooltipModules: {
    fontSize: '12px',
    boxSizing: 'border-box',
    '&::after': {
      opacity: '0.9 !important',
      borderTopColor: '#0069aa !important',
      borderLeftWidth: '6px !important',
      borderRightWidth: '6px !important',
    },
  },
  leftPuzzleTooltip: {
    '&::after': {
      borderTopColor: 'transparent !important',
      borderLeftColor: '#0069aa !important',
    },
  },
  activeExisting: {
    borderTop: '2px solid #cab488',
    paddingTop: '2px',
  },
  activeInvestments: {
    borderTop: '2px solid #CAA336',
    paddingTop: '2px',
  },
  activeRei: {
    borderTop: '2px solid #134376',
    paddingTop: '2px',
  },
  withoutBullet: {
    marginLeft: '-15px !important',
  },
  activemHe: {
    borderTop: '2px solid #529C6F',
    paddingTop: '2px',
  },
  activeVacation: {
    borderTop: '2px solid #b94606',
    paddingTop: '2px',
  },
  fheSlider: {
    background: 'linear-gradient(to bottom,#8fad81 0,#8fad81 100%)',
  },
  buttonGreenNew: {
    fontFamily: 'MuseoSans-300 !important',
  },
  existingSliderNew: {
    '& > div': {
      '& ul': {
        bottom: '2%',
      },
    },
  },
  existingLeftIcon: {
    marginTop: '62px',
  },
  boldData: {
    fontWeight: 'normal',
    zIndex: '1',
    fontFamily: '"MuseoSans-700"',
  },
  investImage: {
    minHeight: '510px',
  },
  leftPadding: {
    paddingLeft: '10px',
  },
  investText: {
    width: 'auto !important',
    display: 'inline-block !important',
    minWidth: '70%',
  },
  noInputTextNew: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    paddingLeft: '9px',
    paddingRight: '3px',
    float: 'right',
  },
  lightBlack: {
    color: '#333',
  },
  multipleText: {
    paddingLeft: '15px !important',
    '& > div': {
      padding: '10px 0px',
    },
    '& span': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  calcHeight: {
    lineHeight: '60px',
  },
  yearLeftText: {
    textAlign: 'left',
    float: 'none',
    display: 'table-cell',
  },
  dependentFlex: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  activeCar: {
    borderTop: '2px solid #ef9300',
    paddingTop: '2px',
  },
  newInput: {
    width: '65% !important',
  },
  percentSign: {
    left: '55%',
  },
  displayRadio: {
    display: 'flex',
    alignItems: 'center',
  },
  radioCheckbox: {
    width: '33.33%',
    marginRight: '4px',
  },
  noLeftPadding: {
    paddingLeft: '0px',
  },
  noBottomPadding: {
    paddingBottom: '0px',
    '& div': {
      '& img': {
        minHeight: '480px',
      },
    },
  },
  activeHome: {
    borderTop: '2px solid #df6e04',
    paddingTop: '2px',
  },
  careerTooltip: {
    fontSize: '12px !important',
  },
  activeFhe: {
    borderTop: '2px solid #86a940',
    paddingTop: '2px',
  },
  minusValue: {
    color: '#bf0000!important',
  },
  italicText: {
    '& td': {
      fontStyle: 'italic',
    },
  },
  blueBorderRow: {
    '&:nth-child(7)': {
      borderBottom: '2px solid #0069aa',
    },
    '&:nth-child(8)': {
      '& td': {
        '&:first-child': {
          padding: '0px 3px !important',
        },
      },
    },
  },
  incomeTablePopup: {
    '& tbody': {
      '& tr': {
        '&:nth-child(19)': {
          borderTop: '2px solid #0069aa',
          '& td': {
            '&:first-child': {
              padding: '0px 3px !important',
            },
          },
        },
        '&:nth-child(27)': {
          borderBottom: '2px solid #0069aa',
        },
        '&:nth-child(31)': {
          borderTop: '2px solid #0069aa',
          '& td': {
            '&:first-child': {
              padding: '0px 3px !important',
            },
          },
        },
        '&:nth-child(38)': {
          borderTop: '2px solid #0069aa',
          '& td': {
            '&:first-child': {
              padding: '0px 3px !important',
            },
          },
        },
        '&:nth-child(41)': {
          borderTop: '2px solid #0069aa',
          '& td': {
            '&:first-child': {
              padding: '0px 3px !important',
            },
          },
        },
      },
    },
  },
  summaryPopup: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '100%',
      maxWidth: '100%',
      background: 'transparent',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '15px',
      },
      '& h4': {
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '25px',
          marginBottom:'10px',
        },
      }
    },
  },
  modalBody1: {
    position: 'relative',
    padding: '15px',
    background: '#fff',
  },
  // modalFooterDiv:{
  //   background: '#fff',
  // },
  modalHeader: {
    borderBottom: '1px solid #e5e5e5',
  },
  summayModalHeader: {
    padding: '0px',
    '& h2 > div': {
      borderBottom: '1px solid #e5e5e5',
      padding: '16px 24px'
    },
    '& p': {
      paddingTop: '10px',
      fontSize: "19px",
      lineHeight: 1.42857143,
      color: '#333',
      borderBottom: '2px solid #337ab7',
      margin: '0 24px !important',
      marginBottom: '10px !important',
      '& sup': {
        fontSize: '10px',
        paddingLeft: '1px',
      }
    }
  },
  modalHeader1: {
    background: '#0f6aac',
    padding: '20px',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    '& h4': {
      float: 'right',
      color: '#fff',
      fontSize: '35px',
      marginRight: '45px',
      margin: '0',
      lineHeight: '1.42857143',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',             
      },
    },
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
      border: '0px',
      [theme.breakpoints.down( 'xs' )]: {
        margin:'0 auto',            
      },
    },
   },
   closePopup: {
    right: '15px',
    top: '9px',
    color: '#fff',
    position: 'absolute',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    marginTop: '2px',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    opacity: '0.4',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
    '&:hover':{
      opacity: '0.8',
    }
  },
  summaryGraphSection: {
    '& h4':{
      fontSize: '22px',
      textAlign: 'center',
      fontWeight: 'bold',
      margin: '0',
      lineHeight: '1.42857143',
      color: '#0069aa',
      fontFamily: 'inherit'
    },
    '& hr': {
      marginTop: '10px',
      marginBottom: '10px',
      borderTop: '2px solid #2f75b5',
    },
    '& hr + div': {
      paddingTop: '30px',
    },
    '& span': {
      display: 'block',
      textAlign: 'left',
      fontStyle: 'italic',
      padding: '0 0 0 20px',
      fontSize: '14px',
    },
  },
  totalValuesDiv: {
    border: '1px solid #2f75b5',
    margin: '13px auto 0',
    padding: '10px',
    textAlign: 'center',
    width: '575px',
    fontWeight: 'bold',
    fontSize: '22px',
    '& span': {
      display: 'initial',
      fontSize: '22px',
      fontWeight: 'bold',
      padding: 0,
      fontStyle: 'inherit',
    }
  },
  downloadAllPdf: {
    padding: '15px !important',
    '& span': {
      display: 'block',
      textAlign: 'center',
    },
  },
  summayModalBlock: {
    '& > div > div ': {
      [theme.breakpoints.down( 'xs' )]: {
        width: 'calc(100% - 30px)',
        margin: '15px',
        maxWidth: '100%',       
      }, 
    },       
  },
  fullWidthModal: {
    '& > div > div ': {
      [theme.breakpoints.down( 'xs' )]: {
        width: 'calc(100% - 30px)',
        margin: '15px',
        maxWidth: '100%',       
      }, 
    },    
  },
  incomeEducation: {
    width: '100%',
    border: '1px solid #000',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  trBlue: {
    backgroundColor: '#006FBB',
    color: '#FFF',
  },
  netIncomeTable: {
    width: '100%',
    border: '1px solid #000',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        padding:' 0 8px 0 5px',
        fontSize: '16px',
        lineHeight: '1.42857143',
        color:'#000',
        '&:last-child': {
          textAlign: 'right',
        },
        '&:first-child': {
          textAlign: 'center',
        },
      },
    },
  },
  popupHeader: {
    backgroundColor: '#006FBB',
    color: '#FFF !important',
    '& td': {
      color: '#FFF !important',
      '&:nth-child(2)': {
        fontWeight: 'normal',    
        fontFamily: '"MuseoSans-700"',
        textAlign: 'center',
      },
      '&:last-child': {
        fontWeight: 'normal',    
        fontFamily: '"MuseoSans-700"',
        textAlign: 'right',
      },
    },
  },
  incomeStatementDialog: {   
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '630px',
      maxWidth: '630px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  incomeStatementDialogNew: { 
    '& > div':{
      alignItems: 'start',
    },  
    '& > div > div': {
      width: '650px',
      maxWidth: '650px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  studentLoanTable: {
    '& > div':{
      alignItems: 'start',
    },  
    '& > div > div': {
      width: '850px',
      maxWidth: '850px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  textCenter: {
    textAlign: 'center !important',
  },
  textRight: {
    textAlign: 'right !important',
  },
  strongText: {
    fontWeight: 'normal',    
    fontFamily: '"MuseoSans-700"',
  },
  incomeBorder: {
    borderTop: '1px solid #000',
    '& td': {
      '&:first-child': {
        textAlign: 'center',
        fontWeight: 'normal',    
        fontFamily: '"MuseoSans-300"',
      },
      fontWeight: 'normal',    
      fontFamily: '"MuseoSans-700"',
    },
  },
  incomeBorderNew: {
    borderTop: '1px solid #000',
  },
  borderTopBottom:{
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
  },
  incomeFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    '& button': {
      color: '#333',
      backgroundColor: '#fff',
      borderColor: '#ccc',
      display: 'inline-block',
      marginBottom: '0',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',    
      touchAction: 'manipulation',
      cursor: 'pointer',  
      userSelect: 'none',
      border: '1px solid transparent',
      borderRadius: '4px',
      padding: '6px 12px',
      backgroundImage: 'none',
      textTransform: 'capitalize',
      '&:hover': {
        color: '#333',
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
    },
  },
  modalBottomFooter: {
    padding: '0px !important',
  },
  bottomBorder: {
    borderBottom: '1px solid #000',
  },
  leftPaddingSpace: {
    paddingLeft: '10px !important',
  },
  balanceReiTable: {    
    '&:nth-child(7)': {
      '& td': {
        color: '#ccc',
        '&:first-child': {
          fontSize: '14px',
          fontStyle: 'italic',
          paddingLeft: '40px !important',
          color: '#006FBB',
        },
      },       
    },    
  },
  cashFlowSheetTable: {
    '& tr': {
      '& th': {
        '&:first-child': {
          width: '520px !important',
          minWidth: '520px !important',
        },
      },
    },
  },
  dataLeftPadding: {
    paddingLeft: '30px !important',
  },
  topSingleBorder: {
    borderTop: '1px solid #000',
  },
  taxSheetTable: {
    '& tr': {
      '& th': {
        fontWeight: 'bold',
        '&:first-child': {
          width: '555px !important',
          minWidth: '555px !important',
        },
      },
    },
  },
  taxStatementDialog: {   
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '800px',
      maxWidth: '800px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  mortgageDialogNew: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '890px',
      maxWidth: '890px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  widthTwoZero: {
    width: '20%',
  },
  widthThreeZero: {
    width: '30%',
  },
  widthOneZero: {
    width: '10%',
  },
  valuesTableRow: {
    '& td': {
      border: '1px solid #000',
      textAlign: 'right',
    },
  },
  taxDialogSixZero: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '600px',
      maxWidth: '600px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  tHeader: {
    borderBottom: '1px solid #000',
  },
  feHeader: {
    '& td': {
      fontWeight: 'normal',    
      fontFamily: '"MuseoSans-700"',
      textAlign: 'center !important',
    },
  },
  federalBody: {
    '& tr': {
      '&:first-child': {
        '& td': {
          borderTop: '1px solid #000',
        },
      },
      '&:last-child': {
        '& td': {
          borderBottom: '1px solid #000',
          '&:first-child': {
            borderBottom: '0 !important',
          }, 
        },
      },
      '& td': {
        borderRight: '1px solid #000',
        textAlign: 'right',
        '&:first-child': {
          borderTop: '0 !important',
        },       
        '&:nth-child|(3)': {
          borderRight: '0 !important',
        },
      },
    },
  },
  saltDeductionDialog: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '790px',
      maxWidth: '790px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  sepDialog: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '670px',
      maxWidth: '670px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  childDialog: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '870px',
      maxWidth: '870px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  incomeRangesData: {
    '& td': {
      fontWeight: 'normal',    
      fontFamily: '"MuseoSans-700"',
      textAlign: 'center !important',
      '&:nth-child(2)': {
        width:'30%',
      },
      '&:nth-child(3)': {
        width:'15%',
      },
      '&:nth-child(4)': {
        width:'12%',
      },
      '&:nth-child(5)': {
        width:'15%',
      },
      '&:nth-child(6)': {
        width:'12%',
      },
      '&:nth-child(7)': {
        width:'20%',
      },
    },
  },
  valuesRow: {   
    '& td': {
      textAlign: 'right',
      border: '1px solid #000', 
      '&:first-child':{     
        borderTop: '0px',
        borderRight: '0px', 
        borderBottom: '0px',
        background: 'transparent',       
      },     
      '&:nth-child(2)': {
        borderRight: '1px solid transparent',
      },
    },
  },
  blueRowBackgroundNew: {
    '& td': {
      backgroundColor: '#ddebf7',
    },
  },
  tdBlue:{
    backgroundColor: '#ddebf7',
  },
  textLeft: {
    textAlign: 'left !important',
  },
  americanOppurtunity: {
    '& > div':{
      alignItems: 'start',
    },
    '& > div > div': {
      width: '850px',
      maxWidth: '850px',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '15px',   
    },
  },
  rowBorder: {
    '& td': {
      border: '1px solid #000',
      borderRight: '1px solid transparent',
      textAlign: 'center !important', 
      '&:first-child':{
        border: '0px',
      },
      '&:last-child': {
        borderRight: '1px solid #000',
      },
    },
  },
  noCursorData: {
    '& td': {
      cursor: 'default !important'
    },
  },
  grayBlankSpace: {
    backgroundColor: '#DDDAD6 !important',
  },
  infoImage: {
    display: 'inline-block',
    width: '25px',
    position: 'relative',
    '& img': {
      marginTop: '-2px',
      maxWidth: '20px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  tdBorder:{
    border: '1px solid #000',
  },
  bdRightZero:{
    borderRight: '1px solid transparent',
  },
  trFirstRow:{
    '& td':{
      '&:first-child':{
        fontSize: '16px !important',
      }
    }
  },
  padLeft10:{
    paddingLeft: '10px !important',
  },
  // stiky:{
  //   position: 'sticky';
  // }
} );

export default styles;
