import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import * as moduleServices from '../../../calculations/modules-services';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
  colors: ['#5B9BD5', '#ED7D31', '#A5A5A5', '#FFC000', '#4472C4', '#70AD47', '#255E91', '#9E480E', '#264488', '#43682B', '#264478', '#5E9DD5', '#E9F2E9', '#6EA6D9', '#4F7239'],
} );
HCExporting( Highcharts );

const TotalCostAttendance = ( props ) => {
  const {
    classes, inputData
  } = props;

  const [expanded, setExpanded] = useState( {} );
  const [graphData, setGraphData] = useState({});
  const [tableDisplayData, setTableDisplayData] = useState({});

  const handleChange = ( panel ) => {
    let expandedData  = {...expanded};
    if(expanded[panel] === undefined) {
      expandedData[panel] = true;
    } else {
      expandedData[panel] = !expandedData[panel];
    }
    setExpanded( expandedData );
  };

  useEffect(()=>{
    const pieChartOptions = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick: function () {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick: function () {
                this.exportChart({
                  type: 'image/jpeg'
                });
              }
            },{
              textKey: 'downloadPDF',
              onclick: function () {
                this.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick: function () {
                this.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        formatter() {
          const chart = this;
          if ( chart.y < 0 ) {
            return `${chart.key}<br/>(${formatDollar( -Math.round( chart.y ) )})   (${parseFloat(chart.percentage.toFixed(2))}%)`;
          } else {
            return `${chart.key}<br/>${formatDollar( -Math.round( chart.y ) )}   (${parseFloat(chart.percentage.toFixed(2))}%)`;
          }
        },
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            distance: -30,
            enabled: true,
            format: '{point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px',
              textOutline: false
            }
          },
          showInLegend: true
        }
      },
      legend: {
        backgroundColor: '#F5F5F5',
        align: 'center'
      },
      series: [{
        name: 'Expenses',
        type: 'pie',      
        data: [
        ['Tuition & Fees ('+inputData.years_diff+' yrs)', moduleServices.totalTuitionAndFees()],
        ['Room & Board ('+inputData.on_campus_year+' yrs)', moduleServices.totalRoomAndBoard()],
        ['On-Campus Living Expenses ('+inputData.on_campus_year+' yrs)', moduleServices.higherOnCampusLivingExpenses()],
        ['Off-Campus Living Expenses ('+inputData.off_campus_year+' yrs)', moduleServices.higherOffCampusLivingExpenses()],
        ].filter(function(d) {
          return d[1] > 0
        })
      }]
    };
    setGraphData(pieChartOptions);

    const incomeSheetData = moduleServices.incomeStatementCompleteData();

    let startYear = moduleServices.module2Data.start_year;

    let oncampusEndYear = 0;
    let oncampusStartYear = parseInt(moduleServices.module2Data.on_campus_start_year);
    if( typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year > 0 && typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year === 0 && typeof moduleServices.module2Data.graduation_year !== 'undefined' && moduleServices.module2Data.graduation_year !== '' && moduleServices.module2Data.graduation_year > 0){
        oncampusEndYear = moduleServices.module2Data.graduation_year;
    } else{
        if( typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year > 0 && typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year > 0 && moduleServices.module2Data.off_campus_start_year > moduleServices.module2Data.on_campus_start_year ){
            oncampusEndYear = parseInt(moduleServices.module2Data.off_campus_start_year) - 1;
        } else{
            if( typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year > 0 && typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year > 0 && moduleServices.module2Data.on_campus_start_year > moduleServices.module2Data.off_campus_start_year && typeof moduleServices.module2Data.graduation_year !== 'undefined' && moduleServices.module2Data.graduation_year !== '' && moduleServices.module2Data.graduation_year > 0){
                oncampusEndYear = moduleServices.module2Data.graduation_year;
            } else{
                oncampusEndYear = 0;    
            }
        }
    }

    let offCampusStartYear = parseInt(moduleServices.module2Data.off_campus_start_year);
    let offCampusEndYear = 0;
    if( typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year > 0 && typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year === 0 && typeof moduleServices.module2Data.graduation_year !== 'undefined' && moduleServices.module2Data.graduation_year !== '' && moduleServices.module2Data.graduation_year > 0){
        offCampusEndYear = moduleServices.module2Data.graduation_year;
    } else{
        if( typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year > 0 && typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year > 0 && moduleServices.module2Data.on_campus_start_year > moduleServices.module2Data.off_campus_start_year ){
            offCampusEndYear = parseInt(moduleServices.module2Data.on_campus_start_year) - 1;
        } else{
            if( typeof moduleServices.module2Data.off_campus_start_year !== 'undefined' && moduleServices.module2Data.off_campus_start_year > 0 && typeof moduleServices.module2Data.on_campus_start_year !== 'undefined' && moduleServices.module2Data.on_campus_start_year > 0 && moduleServices.module2Data.off_campus_start_year > moduleServices.module2Data.on_campus_start_year && typeof moduleServices.module2Data.graduation_year !== 'undefined' && moduleServices.module2Data.graduation_year !== '' && moduleServices.module2Data.graduation_year > 0){
                offCampusEndYear = moduleServices.module2Data.graduation_year;
            } else{
                offCampusEndYear = 0;    
            }
        }
    }

    let tableData = {};
    if(inputData.start_year !== '' && parseInt(inputData.start_year,10) > 0 && inputData.graduation_year !== '' && parseInt(inputData.graduation_year, 10) > 0) {
      for(let i = 0; i < parseInt(inputData.years_diff,10); i++) {
        if(typeof incomeSheetData !== 'undefined' && incomeSheetData !== '' && typeof incomeSheetData[i+startYear] !== 'undefined' && incomeSheetData[i+startYear] !== '' && typeof incomeSheetData[i+startYear]['livingExpenses'] !== 'undefined' && incomeSheetData[i+startYear]['livingExpenses'] !== '') {
          tableData[i+startYear] = {};
          if(typeof incomeSheetData[i+startYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 'undefined' && incomeSheetData[i+startYear]['livingExpenses']['Higher Education Tuition and Fees'] !== '' && -incomeSheetData[i+startYear]['livingExpenses']['Higher Education Tuition and Fees'] > 0 ) {
              tableData[i+startYear]['Tuition and Fees'] = -(incomeSheetData[i+startYear]['livingExpenses']['Higher Education Tuition and Fees']);
          } 
          if(typeof incomeSheetData[i+startYear]['livingExpenses']['Higher Education Room & Board'] !== 'undefined' && incomeSheetData[i+startYear]['livingExpenses']['Higher Education Room & Board'] !== '' && -incomeSheetData[i+startYear]['livingExpenses']['Higher Education Room & Board'] > 0) {
              tableData[i+startYear]['Room & Board'] = -(incomeSheetData[i+startYear]['livingExpenses']['Higher Education Room & Board']);
          } else {
              tableData[i+startYear]['Room & Board'] = 'Nan';
          }

          const higherTotalLivingExpenses = incomeSheetData[i+startYear]['Total Living Expenses'];
          const higherRoomandBoardValue = incomeSheetData[i+startYear]['livingExpenses']['Higher Education Room & Board'];
          const higherTuitionAndFeesValue = incomeSheetData[i+startYear]['livingExpenses']['Higher Education Tuition and Fees'];
          const totalCampusExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);

          if ((i+startYear) >= oncampusStartYear && (i+startYear) <= oncampusEndYear) {          
              tableData[i+startYear]['oncampus'] =  totalCampusExpenses;  
          } else {
              tableData[i+startYear]['oncampus'] = 'Nan';
          }

          if ((i+startYear) >= offCampusStartYear && (i+startYear) <= offCampusEndYear ) {
             tableData[i+startYear]['offcampus'] =  totalCampusExpenses;
          } else {
              tableData[i+startYear]['offcampus'] =  'Nan';
          }

        }
      }
    }
    setTableDisplayData(tableData)

  },[])

  return (
    <div className={ classes.contentBlock }>
      <p>
        Based on your school selection and number of years to graduation, we've calculated your total cost of attendance. Review your totals below.
      </p>
      <p className={ classes.topGapNew }>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          <br />
          Location of Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.location !== undefined && inputData.school_details.location}
        </i>
      </p>
      <div className={ classes.heigherEducationSearch }>
        <div className={ `${classes.higherEducationStepFour} ${classes.higherEducationStepSix} ${classes.TotalCostAttendence}` }>
          <Grid container>
            <Grid sm={ 6 }>
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <th colSpan={ 3 } className={ `${classes.totalCostImage} ${classes.totalCostImageLeftGap}` }>
                    Total Cost of Attendance Breakdown
                    <img src={ infoIcon } alt="" data-for="totalCost" data-tip="Don't forget that in the Financial Independence module you set income growth and inflation assumptions that grow income and expenses each year. Tuition and fees, room and board, and living expenses will grow each year by inflation to better estimate your true cost of attendance." />
                  </th>
                </thead>
                <Accordion
                  square
                  expanded={ expanded['panel1'] === true }
                  onChange={ ()=>handleChange( 'panel1' ) }
                  className={ classes.accorodianTotalCost }
                >
                  <AccordionSummary className={ classes.iconsTextLeft } aria-controls="panel1d-content" id="panel1d-header" expandIcon={ expanded['panel1'] === true ? <i style={ { color: '#f89b22', border: 'none' } } className="fa fa-minus" /> : <i style={ { color: '#000', border: 'none' } } className="fa fa-plus" /> }>
                    <Typography>
                      Tuition & Fees (
                      {inputData.years_diff}
                      &nbsp;yrs)
                      {moduleServices.module2Data.totalTuitionAndFees !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.totalTuitionAndFees } prefix={ moduleServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.totalTuitionAndFees < 0 && ')' } />
                      ) : ( '$0' )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={ classes.totalCotBox }>
                    {
                      Object.keys(tableDisplayData).length > 0 && Object.keys(tableDisplayData).map( ( value ) => (
                        <div>
                          {value}
                          {' '}
                          Tuition & Fees
                          <span><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ tableDisplayData[value]['Tuition and Fees'] } displayType="text" thousandSeparator prefix={ tableDisplayData[value]['Tuition and Fees'] >= 0 ? '$' : '($' } suffix={ tableDisplayData[value]['Tuition and Fees'] < 0 && ')' } /></span>
                        </div>
                      ) )
                    }
                  </AccordionDetails>
                </Accordion>
                {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                  <Accordion className={ classes.accorodianTotalCost } square expanded={ expanded['panel2'] === true } onChange={ ()=>handleChange( 'panel2' ) }>
                    <AccordionSummary
                      className={ classes.iconsTextLeft }
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={ expanded['panel2'] === true ? <i style={ { color: '#f89b22', border: 'none' } } className="fa fa-minus" /> : <i style={ { color: '#000', border: 'none' } } className="fa fa-plus" /> }
                    >
                      <Typography>
                        <font>
                          Room & Board (
                          {inputData.on_campus_year}
                          &nbsp;yrs)
                        </font>
                        {moduleServices.module2Data.totalRoomAndBoard !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.totalRoomAndBoard } prefix={ moduleServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.totalRoomAndBoard < 0 && ')' } />
                        ) : ( '$0' )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={ classes.totalCotBox }>
                      {
                        Object.keys(tableDisplayData).length > 0 && Object.keys(tableDisplayData).map( ( value ) => (
                          inputData.on_campus_year > 0 && tableDisplayData[value]['Room & Board'] !== 'Nan' && (
                            <div>
                              {value}
                              {' '}
                              Room & Board
                              <span><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ tableDisplayData[value]['Room & Board'] } displayType="text" thousandSeparator prefix={ tableDisplayData[value]['Room & Board'] >= 0 ? '$' : '($' } suffix={ tableDisplayData[value]['Room & Board'] < 0 && ')' } /></span>
                            </div>
                          )
                        ) )
                      }
                    </AccordionDetails>
                  </Accordion>
                )}
                 {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                  <Accordion className={ classes.accorodianTotalCost } square expanded={ expanded['panel3'] === true } onChange={ ()=>handleChange( 'panel3' ) }>
                    <AccordionSummary className={ classes.iconsTextLeft } aria-controls="panel1d-content" id="panel1d-header" expandIcon={ expanded['panel3'] === true ? <i style={ { color: '#f89b22', border: 'none' } } className="fa fa-minus" /> : <i style={ { color: '#000', border: 'none' } } className="fa fa-plus" /> }>
                      <Typography>
                        On-Campus Living Expenses (
                        {inputData.on_campus_year}
                        &nbsp;yrs)
                        {moduleServices.module2Data.higherOnCampusLivingExpenses !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherOnCampusLivingExpenses } prefix={ moduleServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherOnCampusLivingExpenses < 0 && ')' } />
                        ) : ( '$0' )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={ classes.totalCotBox }>
                      {
                        Object.keys(tableDisplayData).length > 0 && Object.keys(tableDisplayData).map( ( value ) => (
                          inputData.on_campus_year > 0 && tableDisplayData[value]['oncampus'] !== 'Nan' && (
                            <div>
                              {value}
                              {' '}
                              On-Campus Living Expenses
                              <span><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ tableDisplayData[value]['oncampus'] } displayType="text" thousandSeparator prefix={ tableDisplayData[value]['oncampus'] >= 0 ? '$' : '($' } suffix={ tableDisplayData[value]['oncampus'] < 0 && ')' } /></span>
                            </div>

                          )
                        ) )
                      }
                    </AccordionDetails>
                  </Accordion>
                 )}
                <Accordion className={ classes.accorodianTotalCost } square expanded={ expanded['panel4'] === true } onChange={ ()=>handleChange( 'panel4' ) }>
                  <AccordionSummary className={ classes.iconsTextLeft } aria-controls="panel1d-content" id="panel1d-header" expandIcon={ expanded['panel4'] === true ? <i style={ { color: '#f89b22', border: 'none' } } className="fa la-minus" /> : <i style={ { color: '#000', border: 'none' } } className="fa fa-plus" /> }>
                    <Typography>
                      Off-Campus Living Expenses (
                      {inputData.off_campus_year}
                      &nbsp;yrs)
                      {moduleServices.module2Data.higherOffCampusLivingExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherOffCampusLivingExpenses } prefix={ moduleServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherOffCampusLivingExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={ classes.totalCotBox }>
                    {
                      Object.keys(tableDisplayData).length > 0 && Object.keys(tableDisplayData).map( ( value ) => (
                        inputData.off_campus_year > 0 && tableDisplayData[value]['offcampus'] !== 'Nan' && (
                          <div>
                            {value}
                            {' '}
                             Off-Campus Living Expenses
                            <span><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ tableDisplayData[value]['offcampus'] } displayType="text" thousandSeparator prefix={ tableDisplayData[value]['offcampus'] >= 0 ? '$' : '($' } suffix={ tableDisplayData[value]['offcampus'] < 0 && ')' } /></span>
                          </div>

                        )
                      ) )
                    }
                  </AccordionDetails>
                </Accordion>
              </table>
              <div className={ `${classes.totalCostAttendenceNew} ${classes.totalCostAttendenceNewLeftSpace} ${classes.totalCostAttendenceFlex}` }>
                Total Cost of Attendance (
                {inputData.years_diff}
                &nbsp;yrs)
                {moduleServices.module2Data.educationTotalAttendance !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.educationTotalAttendance } prefix={ moduleServices.module2Data.educationTotalAttendance >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.educationTotalAttendance < 0 && ')' } />
                ) : ( '$0' )}
              </div>
            </Grid>
            <Grid sm={ 6 }>
              <div className={ classes.graphRight }>
                <div className={ classes.graphBox }>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={ graphData }
                  />
                </div>
              </div>
            </Grid>
          </Grid>

        </div>

      </div>
      <ReactTooltip id="totalCost" place="top" type="light" effect="solid" className={ classes.tooltipInfoLiving } />
    </div>
  );
};

TotalCostAttendance.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired
};

export default withStyles( styles )( TotalCostAttendance );
