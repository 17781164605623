import { PMT } from '../../utilities/commonFunctions';
// eslint-disable-next-line
let summationData = {};

function existingHomeMortgageSheetData( module1Data, module18Data ) {
  let existingHomeMortgage1Data = {};
  
  // Identifier
  let identifier = 0;
  let identifier2 = 0;
  if ( module18Data.existig_home_current_mortgage_balance !== undefined && module18Data.existig_home_current_mortgage_balance > 0 ) {
    identifier = 1;
  } else {
    identifier = 0;
  }

  // Loan Amount
  let loanAmount = 0;
  if ( module18Data.existig_home_current_mortgage_balance !== undefined
    && module18Data.existig_home_current_mortgage_balance !== ''  && module18Data.existig_home_current_mortgage_balance !== null) {
    loanAmount = module18Data.existig_home_current_mortgage_balance;
  } else {
    loanAmount = 0;
  }
  
  // Second Loan Amount
	let loanAmount2 = 0;            
	if( module18Data.existig_home_current_mortgage_balance_2 !== undefined && 
		module18Data.existig_home_current_mortgage_balance_2 !== '' && 
		module18Data.existig_home_current_mortgage_balance_2 !== null) {                
		loanAmount2 = module18Data.existig_home_current_mortgage_balance_2;
	} else {
		loanAmount2 = 0;
	}

  // Terms in Years
  let termsOfYear = 0;
  if ( module18Data.existig_home_mortgage_months_to_maturity !== undefined && module18Data.existig_home_mortgage_months_to_maturity !== '' ) {
    termsOfYear = parseInt( module18Data.existig_home_mortgage_months_to_maturity, 10 ) / 12;
  } else {
    termsOfYear = 0;
  }
  let termsOfYear2 = 0;
  if ( module18Data.existig_home_mortgage_months_to_maturity_2 !== undefined && module18Data.existig_home_mortgage_months_to_maturity_2 !== '' ) {
    termsOfYear2 = parseInt( module18Data.existig_home_mortgage_months_to_maturity_2, 10 ) / 12;
  } else {
    termsOfYear2 = 0;
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( module18Data.existig_home_mortgage_interest_rate !== undefined && module18Data.existig_home_mortgage_interest_rate !== '' ) {
    annualInterestRate = module18Data.existig_home_mortgage_interest_rate;
  } else {
    annualInterestRate = 0;
  }
  let annualInterestRate2 = 0;
  if ( module18Data.existig_home_mortgage_interest_rate_2 !== undefined && module18Data.existig_home_mortgage_interest_rate_2 !== '' ) {
    annualInterestRate2 = module18Data.existig_home_mortgage_interest_rate_2;
  } else {
    annualInterestRate2 = 0;
  }

  // Original Payment
  let originalPayment = 0;
  originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( loanAmount ), 0, 0 );
  let originalPayment2 = 0;
  originalPayment2 = PMT( ( parseFloat( annualInterestRate2 ) / 100 ) / 12, termsOfYear2 * 12, -( loanAmount2 ), 0, 0 );

  // Total Interest Paid
  let totalInterestPaid = 0;
  let totalInterestPaid2 = 0;

  // Total Paid
  let totalPaid = 0;
  let totalPaid2 = 0;  

  // Tabular Data
  let startYear = 0;

  startYear = parseInt( module1Data.start_year, 10 );
  if ( startYear > 0 ) {
    let year = parseInt( startYear, 10 );
    const yearLimit = year + parseFloat( termsOfYear );

    // Existing Student Loan Data
    existingHomeMortgage1Data = {};
    existingHomeMortgage1Data.Identifier = identifier;
    existingHomeMortgage1Data['Loan Amount'] = loanAmount;
    existingHomeMortgage1Data['Term in Years'] = termsOfYear;
    existingHomeMortgage1Data['Annual Interest Rate'] = annualInterestRate;
    existingHomeMortgage1Data['Original Payment'] = originalPayment;
	
	//Second Home
	existingHomeMortgage1Data.Identifier2 = identifier2 ;
	existingHomeMortgage1Data['Loan Amount2'] = loanAmount2;
	existingHomeMortgage1Data['Term in Years2'] = termsOfYear2;
	existingHomeMortgage1Data['Annual Interest Rate2'] = annualInterestRate2;
	existingHomeMortgage1Data['Original Payment2'] = originalPayment2;
				
				
    let Balance = loanAmount;
	let Balance2 = loanAmount2;

    const Payment = originalPayment;
	const Payment2 = originalPayment2;
	
    let Interest = 0;
	let Interest2 = 0;
	
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
	if ( annualInterestRate2 > 0 ) {
      Interest2 = Balance2 * ( ( annualInterestRate2 / 100 ) / 12 );
    }
	
    let Principal = Payment - Interest;
	let Principal2 = Payment2 - Interest2;
	
    let Equity = Principal;
	let Equity2 = Principal2;
	
    let TotalInterest = Interest;
	let TotalInterest2 = Interest2;
	
    const TotalPayment = Equity + TotalInterest;
	const TotalPayment2 = Equity2 + TotalInterest2;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];
	const totalInterestPaidArray2 = [];
    const totalPaidArray2 = [];

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      existingHomeMortgage1Data[year] = {};

      existingHomeMortgage1Data[year].LaggingInterest = 0;
      existingHomeMortgage1Data[year].LaggingPrincipal = 0;
      existingHomeMortgage1Data[year]['Months Total Balance'] = 0;
	  
	  existingHomeMortgage1Data[year].LaggingInterest2 = 0;
      existingHomeMortgage1Data[year].LaggingPrincipal2 = 0;
      existingHomeMortgage1Data[year]['Months Total Balance2'] = 0;

      // For Loop for 12 months
      for ( let month = 1; month <= 12; month += 1 ) {
        existingHomeMortgage1Data[year][month] = {};
        if ( year === startYear && month === 1 ) {
          existingHomeMortgage1Data[year][month].YearIdentifier = year;
          existingHomeMortgage1Data[year][month].Balance = Balance;
          existingHomeMortgage1Data[year][month].Payment = Payment;
          existingHomeMortgage1Data[year][month].Interest = Interest;
          existingHomeMortgage1Data[year][month].Principal = Principal;
          existingHomeMortgage1Data[year][month].Equity = Equity;
          existingHomeMortgage1Data[year][month].TotalInterest = TotalInterest;
          existingHomeMortgage1Data[year][month].TotalPayment = TotalPayment;
		  
		  existingHomeMortgage1Data[year][month].YearIdentifier2 = year;
          existingHomeMortgage1Data[year][month].Balance2 = Balance2;
          existingHomeMortgage1Data[year][month].Payment2 = Payment2;
          existingHomeMortgage1Data[year][month].Interest2 = Interest2;
          existingHomeMortgage1Data[year][month].Principal2 = Principal2;
          existingHomeMortgage1Data[year][month].Equity2 = Equity2;
          existingHomeMortgage1Data[year][month].TotalInterest2 = TotalInterest2;
          existingHomeMortgage1Data[year][month].TotalPayment2 = TotalPayment2;
		  
        } else {
          // Year Identifier
          existingHomeMortgage1Data[year][month].YearIdentifier = year;
		  existingHomeMortgage1Data[year][month].YearIdentifier2 = year;

          // Balance
          let tempBalance;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempBalance = ( Balance - Principal );
          } else {
            tempBalance = 0;
          }
          existingHomeMortgage1Data[year][month].Balance = tempBalance;
		  
		        let tempBalance2;
          if ( Balance2 === 0 ) {
            tempBalance2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempBalance2 = ( Balance2 - Principal2 );
          } else {
            tempBalance2 = 0;
          }
          existingHomeMortgage1Data[year][month].Balance2 = tempBalance2;

          // Payment
          existingHomeMortgage1Data[year][month].Payment = Payment;
		  existingHomeMortgage1Data[year][month].Payment2 = Payment2;

          // Interest
          let tempInterest;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            tempInterest = 0;
          }
          existingHomeMortgage1Data[year][month].Interest = tempInterest;
		  
		  let tempInterest2;
          if ( Balance2 === 0 ) {
            tempInterest2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempInterest2 = tempBalance2 * ( ( annualInterestRate2 / 100 ) / 12 );
          } else {
            tempInterest2 = 0;
          }
          existingHomeMortgage1Data[year][month].Interest2 = tempInterest2;

          // Principal
          let tempPrincipal;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempPrincipal = Payment - tempInterest;
          } else {
            tempPrincipal = 0;
          }
          existingHomeMortgage1Data[year][month].Principal = tempPrincipal;
		  
		  let tempPrincipal2;
          if ( Balance2 === 0 ) {
            tempPrincipal2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempPrincipal2 = Payment2 - tempInterest2;
          } else {
            tempPrincipal2 = 0;
          }
          existingHomeMortgage1Data[year][month].Principal2 = tempPrincipal2;

          // Equity
          let tempEquity;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempEquity = Equity + tempPrincipal;
          } else {
            tempEquity = 0;
          }
          existingHomeMortgage1Data[year][month].Equity = tempEquity;
		  
		  let tempEquity2;
          if ( Balance2 === 0 ) {
            tempEquity2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempEquity2 = Equity2 + tempPrincipal2;
          } else {
            tempEquity2 = 0;
          }
          existingHomeMortgage1Data[year][month].Equity2 = tempEquity2;

          // Total Interest
          let tempTotalInterest;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          } else {
            tempTotalInterest = 0;
          }
          existingHomeMortgage1Data[year][month].TotalInterest = tempTotalInterest;
		  
		  let tempTotalInterest2;
          if ( Balance2 === 0 ) {
            tempTotalInterest2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempTotalInterest2 = TotalInterest2 + tempInterest2;
          } else {
            tempTotalInterest2 = 0;
          }
          existingHomeMortgage1Data[year][month].TotalInterest2 = tempTotalInterest2;

          // Total Payments
          let tempTotalPayment;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          } else {
            tempTotalPayment = 0;
          }
          existingHomeMortgage1Data[year][month].TotalPayment = tempTotalPayment;
		  
		  let tempTotalPayment2;
          if ( Balance2 === 0 ) {
            tempTotalPayment2 = 0;
          } else if ( ( Balance2.toFixed( 6 ) - Principal2.toFixed( 6 ) ) > 1 ) {
            tempTotalPayment2 = tempEquity2 + tempTotalInterest2;
          } else {
            tempTotalPayment2 = 0;
          }
          existingHomeMortgage1Data[year][month].TotalPayment2 = tempTotalPayment2;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
		  
		  Balance2 = tempBalance2;
          Principal2 = tempPrincipal2;
          Equity2 = tempEquity2;
          TotalInterest2 = tempTotalInterest2;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( existingHomeMortgage1Data[year][month].TotalInterest );
		totalInterestPaidArray2.push( existingHomeMortgage1Data[year][month].TotalInterest2 );

        // Total Paid Array
        totalPaidArray.push( existingHomeMortgage1Data[year][month].TotalPayment );
		totalPaidArray2.push( existingHomeMortgage1Data[year][month].TotalPayment2 );

        existingHomeMortgage1Data[year].LaggingInterest = parseFloat( existingHomeMortgage1Data[year].LaggingInterest ) + parseFloat( existingHomeMortgage1Data[year][month].Interest );
		existingHomeMortgage1Data[year].LaggingInterest2 = parseFloat( existingHomeMortgage1Data[year].LaggingInterest2 ) + parseFloat( existingHomeMortgage1Data[year][month].Interest2 );
        existingHomeMortgage1Data[year].LaggingPrincipal = parseFloat( existingHomeMortgage1Data[year].LaggingPrincipal ) + parseFloat( existingHomeMortgage1Data[year][month].Principal );
		existingHomeMortgage1Data[year].LaggingPrincipal2 = parseFloat( existingHomeMortgage1Data[year].LaggingPrincipal2 ) + parseFloat( existingHomeMortgage1Data[year][month].Principal2 );
        existingHomeMortgage1Data[year]['Months Total Balance'] = parseFloat( existingHomeMortgage1Data[year]['Months Total Balance'] ) + parseFloat( existingHomeMortgage1Data[year][month].Balance );
		existingHomeMortgage1Data[year]['Months Total Balance2'] = parseFloat( existingHomeMortgage1Data[year]['Months Total Balance2'] ) + parseFloat( existingHomeMortgage1Data[year][month].Balance2 );
      }
    }

    // Total Interest Paid
    if ( totalInterestPaidArray.length > 0 ) {
      totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
    }
	if ( totalInterestPaidArray2.length > 0 ) {
      totalInterestPaid2 = Math.max.apply( null, totalInterestPaidArray2 );
    }

    // Total Paid
    if ( totalPaidArray.length > 0 ) {
      totalPaid = Math.max.apply( null, totalPaidArray );
    }
	if ( totalPaidArray2.length > 0 ) {
      totalPaid2 = Math.max.apply( null, totalPaidArray2 );
    }
  }

  // Summation Data
  if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 30;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;
	
	let summationBeginningBalance2 = 0;
    let summationLaggingInterest2 = 0;
    let summationLaggingPrincipal2 = 0;
    let summationTotalInterest2 = 0;
    let summationTotalPrincipal2 = 0;
    let summationEndingBalance2 = 0;
	
    let k = 0;
    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
      if ( k === 0 ) {
        summationBeginningBalance = loanAmount;
      } else if ( module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && summationYear <= module18Data.existing_home_sale_year ) {
        if ( existingHomeMortgage1Data !== undefined && existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear][1].Balance !== undefined ) {
          summationBeginningBalance = existingHomeMortgage1Data[summationYear][1].Balance;
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;
	  
	  if ( k === 0 ) {
        summationBeginningBalance2 = loanAmount2;
      } else if ( module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && summationYear <= module18Data.existing_home_sale_year_2 ) {
        if ( existingHomeMortgage1Data !== undefined && existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear][1].Balance2 !== undefined ) {
          summationBeginningBalance2 = existingHomeMortgage1Data[summationYear][1].Balance2;
        } else {
          summationBeginningBalance2 = 0;
        }
      } else {
        summationBeginningBalance2 = 0;
      }
      summationData[summationYear].BeginningBalance2 = summationBeginningBalance2;
	  

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear].LaggingInterest !== undefined ) {
          LaggingVal1 = existingHomeMortgage1Data[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;
	  
	  let LaggingVal12 = 0;
      if ( summationData[summationYear].BeginningBalance2 > 1 ) {
        if ( existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear].LaggingInterest2 !== undefined ) {
          LaggingVal12 = existingHomeMortgage1Data[summationYear].LaggingInterest2;
        } else {
          LaggingVal12 = 0;
        }
      } else {
        LaggingVal12 = 0;
      }
      summationLaggingInterest2 = LaggingVal12;
      summationData[summationYear].LaggingInterest2 = summationLaggingInterest2;
	  
	  

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear].LaggingPrincipal !== undefined ) {
          summationLaggingPrincipal = existingHomeMortgage1Data[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;
	  
	  if ( summationData[summationYear].BeginningBalance2 > 1 ) {
        if ( existingHomeMortgage1Data[summationYear] !== undefined && existingHomeMortgage1Data[summationYear].LaggingPrincipal2 !== undefined ) {
          summationLaggingPrincipal2 = existingHomeMortgage1Data[summationYear].LaggingPrincipal2;
        } else {
          summationLaggingPrincipal2 = 0;
        }
      } else {
        summationLaggingPrincipal2 = 0;
      }
      summationData[summationYear].LaggingPrincipal2 = summationLaggingPrincipal2;
	  
	  

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;
	  
	  if ( summationData[summationYear].BeginningBalance2 > 1 ) {
        summationTotalInterest2 += summationData[summationYear].LaggingInterest2;
      } else {
        summationTotalInterest2 = 0;
      }
      summationData[summationYear].TotalInterest2 = summationTotalInterest2;

      // Cumulative Principal

      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }

      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;
	  
	  if ( summationData[summationYear].BeginningBalance2 > 1 ) {
        summationTotalPrincipal2 += summationData[summationYear].LaggingPrincipal2;
      } else {
        summationTotalPrincipal2 = 0;
      }

      summationData[summationYear].TotalPrincipal2 = summationTotalPrincipal2;

      // Ending Balance
      if ( module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && summationYear <= module18Data.existing_home_sale_year ) {
        summationEndingBalance = parseFloat( summationData[summationYear].BeginningBalance ) - parseFloat( summationData[summationYear].LaggingPrincipal );
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
	  
	  if ( module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && summationYear <= module18Data.existing_home_sale_year_2 ) {
        summationEndingBalance2 = parseFloat( summationData[summationYear].BeginningBalance2 ) - parseFloat( summationData[summationYear].LaggingPrincipal2 );
      } else {
        summationEndingBalance2 = 0;
      }
      summationData[summationYear].EndingBalance2 = summationEndingBalance2;

      k += 1;
    }
  }

  // To return Car Loan Complete Data
  function existingHomeMortgageMainData() {
    const existingHomeMortgageData = {};
    existingHomeMortgageData['Existing Home Data'] = existingHomeMortgage1Data;
    existingHomeMortgageData['Summation Data'] = summationData;
    existingHomeMortgageData['Total Paid'] = totalPaid;
    existingHomeMortgageData['Total Interest Paid'] = totalInterestPaid;
    existingHomeMortgageData['Annual Interest Rate'] = annualInterestRate;
	
	existingHomeMortgageData['Total Paid2'] = totalPaid2;
    existingHomeMortgageData['Total Interest Paid2'] = totalInterestPaid2;
    existingHomeMortgageData['Annual Interest Rate2'] = annualInterestRate2;
	
	//console.log('existingHomeMortgageData', existingHomeMortgageData);
	return existingHomeMortgageData;
  }
  return existingHomeMortgageMainData();
}
export default existingHomeMortgageSheetData;
