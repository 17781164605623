import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruTable = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack,walkThruNo
    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruEight)}>
            <h4>Show Table</h4>
            <p>The Show Table provides a complete picture of your real estate investment. Annual income, cash flow, closing costs, returns, and more all on one downloadable PDF. Thought real estate investing was too complicated? Let Inkwiry streamline your analysis in the REI module.</p>
            <div className={classes.walkthruBottom}>
                <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                <div className={classes.walkThruBtns}>
                    <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                    <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                    <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

WalkThruTable.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruTable);