import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
// import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const VehicleOwn2 = ( props ) => {
  const { classes, handleAssestObject, handleUpdatedObject, handleDefinationPopup, vehicle} = props;

  const [isFocus, setIsFocus] = React.useState('');

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
    <p className={ classes.existingSpace }>Enter the loan details about Vehicle {vehicle} below. If you do not have a loan, skip the below and continue.</p>
    <div className={classes.section4TableScroll}>
        <div className={ `${classes.section4Table} ${classes.sliderBottomSpace}` }>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }>Current Loan Balance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Value() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Intrest() }
                  min={ 0 }
                  max={ 15 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'number' ) } onFocus={(e) => e.target.select()} />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <NumberFormat
                customInput={ TextField }
                thousandSeparator 
                value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] }
                decimalScale={isFocus!=='existing_car_interest_rate'?2:5}
                fixedDecimalScale={isFocus!=='existing_car_interest_rate'} 
                onValueChange={ ( e) => updateValue( e, '', vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'number' ) }
                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_interest_rate') } }
                onBlur={emptyIsFocus} />
              <span className={classes.textPer}>%</span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
          <div className={ classes.sectionClear }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) }>Months Remaining on Loan</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Months() }
                  min={ 0 }
                  max={ 300 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  decimalScale={isFocus!=='existing_car_months_to_maturity'?2:5}
                  fixedDecimalScale={isFocus!=='existing_car_months_to_maturity'}
                  value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] }
                  onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_car_months_to_maturity') } }
                  onBlur={emptyIsFocus} /> 
                <span className={classes.textMonts}>months </span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.sectionLeft50}` }>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.sectionLRight30}` }>
            <NumberFormat displayType="text" thousandSeparator decimalScale={2} fixedDecimalScale value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity / 12 :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] / 12 } /> years
            </div>
          </div>
          <div className={ classes.sectionClear }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Monthly Payment (Principal & Interest)</p>
            </div>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Repayment Amount (Principal & Interest)</p>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              {
                vehicle === 1 ?
                (modulesServices.module18Data.existingCarLoansMonthlyPayment !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansMonthlyPayment } prefix={ modulesServices.module18Data.existingCarLoansMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                ) : ( '$0' ))
                  :
                (modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] < 0 && ')' } />
                ) : ( '$0' ))
              }
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              {
                vehicle === 1 ?
                (modulesServices.module18Data.existingCarLoansTotalPrincipalInterest !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest } prefix={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                ) : ( '$0' ))
                  :
                (modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] < 0 && ')' } />
                ) : ( '$0' ))
              }
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ classes.sliderBottomSpaceNewOne }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Current Loan Balance</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Value() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'number' ) } onFocus={(e) => e.target.select()} />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Intrest() }
                  min={ 0 }
                  max={ 15 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat customInput={ TextField } thousandSeparator 
                  value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] } 
                  onValueChange={ ( e) => updateValue( e, '', vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                  %
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftMonts}` }>
            
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) }>Months Remaining on Loan</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          ValueLabelComponent={ ValueLabelComponent }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          marks={ sliderValues.vehicleOwn2Months() }
                          min={ 0 }
                          max={ 300 }
                          step={ 1 }
                          value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] }
                          valueLabelFormat={ `${commonFunctions.mosFormatter( vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] )}` }
                          onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'slider' ) }
                        />
                        </div>
                      </div>
                    </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightMonts}` }>
                <div>
                    <div className={ `${classes.inputRightBlock} ${classes.annualInputMonts}` }>
                      <div className={ classes.annualInput }>
                      <NumberFormat customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } /> months
                      </div>
                    </div>
                  </div>
                  <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                      <div className={ `${classes.inputRightBlock} ${classes.width70}` }>
                      <NumberFormat displayType="text" thousandSeparator decimalScale={1} value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity / 12 :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] / 12 } /> years
                    </div>
                  </div>
              </div>
            </div>
           
          </div>
          <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne} ${classes.garphLeftMonts}` }>
                <p>Monthly Payment (Principal & Interest)</p>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP} ${classes.garphRightMonts}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo} ${classes.vehicleOwnTotalTwoNew}` }>
                      {
                        vehicle === 1 ?
                        (modulesServices.module18Data.existingCarLoansMonthlyPayment !== undefined ? (
                          <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansMonthlyPayment } prefix={ modulesServices.module18Data.existingCarLoansMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                        ) : ( '$0' ))
                          :
                        (modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] !== undefined ? (
                          <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] < 0 && ')' } />
                        ) : ( '$0' ))
                      }
                    </div>
                  </div>
                </div>
                <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                  <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne} ${classes.garphLeftMonts}` }>
                <p>Total Repayment Amount (Principal & Interest)</p>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP} ${classes.garphRightMonts}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo} ${classes.vehicleOwnTotalTwoNew}` }>
                      {
                        vehicle === 1 ?
                        (modulesServices.module18Data.existingCarLoansTotalPrincipalInterest !== undefined ? (
                          <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest } prefix={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                        ) : ( '$0' ))
                          :
                        (modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] !== undefined ? (
                          <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] < 0 && ')' } />
                        ) : ( '$0' ))
                      }
                    </div>
                  </div>
                </div>
                <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                  <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
        </div> */}
      {/* <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
        <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
          <h3>&nbsp;</h3>
          <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
            <div>
              <p>Current Loan Balance</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Value() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
          <div>
            <div className={ classes.inputRightBlock }>
              $
              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === 1 ? handleAssestObject.existing_car_current_loan_balance :  handleAssestObject[`existing_car_current_loan_balance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_current_loan_balance' : `existing_car_current_loan_balance_${vehicle}`, 'number' ) } onFocus={(e) => e.target.select()} />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
        <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
          <h3>&nbsp;</h3>
          <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Intrest() }
                  min={ 0 }
                  max={ 15 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
          <div>
            <div className={ classes.inputRightBlock }>
              <NumberFormat customInput={ TextField } thousandSeparator 
              value={ vehicle === 1 ? handleAssestObject.existing_car_interest_rate :  handleAssestObject[`existing_car_interest_rate_${vehicle}`] } 
              onValueChange={ ( e) => updateValue( e, '', vehicle === 1 ? 'existing_car_interest_rate' :  `existing_car_interest_rate_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              %
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) }>Months Remaining on Loan</span></p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn2Months() }
                      min={ 0 }
                      max={ 300 }
                      step={ 1 }
                      value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.mosFormatter( vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <NumberFormat customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_months_to_maturity' :  `existing_car_months_to_maturity_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                  months
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                  <NumberFormat thousandSeparator decimalScale={1} value={ vehicle === 1 ? handleAssestObject.existing_car_months_to_maturity / 12 :  handleAssestObject[`existing_car_months_to_maturity_${vehicle}`] / 12 } />
                  years
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
       {/* <div className={ `${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
        <span>Monthly Payment (Principal & Interest)</span>
        {' '}
        <span>
          {
            vehicle === 1 ?
            (modulesServices.module18Data.existingCarLoansMonthlyPayment !== undefined ? (
              <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansMonthlyPayment } prefix={ modulesServices.module18Data.existingCarLoansMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
            ) : ( '$0' ))
              :
            (modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] !== undefined ? (
              <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansMonthlyPayment${vehicle}`] < 0 && ')' } />
            ) : ( '$0' ))
          }
        </span>
      </div>
      <div className={ `${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
        <span>Total Repayment Amount (Principal & Interest)</span>
        {' '}
        <span>
          {
            vehicle === 1 ?
            (modulesServices.module18Data.existingCarLoansTotalPrincipalInterest !== undefined ? (
              <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest } prefix={ modulesServices.module18Data.existingCarLoansTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
            ) : ( '$0' ))
              :
            (modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] !== undefined ? (
              <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalPrincipalInterest${vehicle}`] < 0 && ')' } />
            ) : ( '$0' ))
          }
        </span>
      </div> */}
    </div>
  );
};

VehicleOwn2.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( VehicleOwn2 );
