const styles = ( theme ) => ( {
  closeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1,
    padding: '0px',
    margin: '0px',
    color: "#f5f5f5",
    fontSize: '20px'
  },
  containerFluid: {
    maxWidth: '100%',
    padding: '30px',
    margin: '0px auto',
    background: '#014372',
    minHeight: '100vh',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 15px',
    },
  },
  containerFluidRow:{
    background: '#014372',
    padding: '30px 0px',
    minHeight: '100vh',
  },
  container: {
    maxWidth: '1170px',
    padding: '0 0px',
    margin: '0px auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 15px',
    },
    '@media (max-width: 1199px)': {
      padding: '0 15px',
    }
  },
  img: {
    verticalAlign: 'middle',
  },
  dashboardLogo: {
    width: '150px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto',
    },
    '& a': {
      display: 'flex',
      '& img': {
        width: '100%',
      },
    },
  },
  phraseContent:{
    width: '70%',
    margin: '0 auto',
    // display: 'none',
    [theme.breakpoints.down( 'md' )]: {
      width: '100%',
    },
    '& h1':{
      fontSize: '49px',
      color: '#fff',
      margin: '50px 0px 20px',
      fontFamily: '"MuseoSans-100"',
      paddingLeft: '30px',
      textAlign: 'center',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '35px',
      },
      [theme.breakpoints.down( 'md' )]: {
        paddingLeft: '0px',
      }
    }
  },
  quickTourCover:{
    width: '75%',
    margin: '50px auto',
    position: 'relative',
    [theme.breakpoints.down( 'md' )]: {
      width: '100%',
      maxWidth: '600px',
    },
    '& img':{
      width: '100%',
    }
  },
  tourVideoIcon:{
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span':{
      cursor: 'pointer',
    }
  },
  quickTourBtnRow:{
    textAlign: 'center',
  },
  quickTourBtn:{
    background: '#84a84d',
    color: '#fff',
    padding: '8px 45px',
    borderRadius: '6px',
    textTransform: 'uppercase',
    margin: '50px 0px',
    fontSize: '20px',
    border: '2px solid #fff',
    lineHeight: '1.42857143',
    fontFamily: '"MuseoSans-300"',
    cursor: 'pointer',
    '&:hover':{
      background: '#d09924',
      border: '2px solid #d09924',
    }
  },
  quickTourBtnDis:{
    color: '#fff',
    background: '#a19c9c',
    cursor: 'no-drop',
  },
  welcomeText:{
    width: '70%',
    margin: '0 auto',
    // display: 'none',
    [theme.breakpoints.down( 'md' )]: {
      width: '100%',
    },
  },
  powerUpLogo:{
    marginLeft: '12%',
    margin: '80px auto 30px',
    width: 'auto',
    [theme.breakpoints.down( 'md' )]: {
      marginLeft: '0',
    },
    '& h1':{
      fontSize: '49px',
      color: '#fff',
      margin: '50px 0px 50px',
      fontFamily: '"MuseoSans-100"',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '35px',
      },
      [theme.breakpoints.down( 'md' )]: {
        paddingLeft: '0px',
      }
    }
  },
  welcomeDesc:{
    width: '85%',
    margin: '0 auto',
    [theme.breakpoints.down( 'md' )]: {
      width: '100%',
    },
    '& p':{
      marginBottom: '40px',
      fontFamily: '"MuseoSans-100"',
      fontSize: '22px',
      color: '#fff',
      lineHeight: '1.42857143',
      '&:last-child':{
        marginBottom: '10px',
      }
    }
  },
  logoSec:{
    padding: '0px 15px',
  },
  logoRow:{
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'inline-block',
      width: '100%',
    }
  },
  logoRowOne:{
    width: '41%',
    marginLeft: '2.5%',
    marginRight: '1.5%',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginLeft: '0',
      marginRight: '0',
      textAlign: 'center',
    },
    '& img':{
      maxWidth: 'calc(300px - 40px)',
      padding: '55px 20px',
      width: '100%',
      float: 'right',
      [theme.breakpoints.down( 'xs' )]: {
        float: 'none',
      },
      '@media (min-width: 600px) and (max-width: 1023px)': {
        maxWidth: '300px',
        width: 'calc(100% - 40px)',
      },
      '&:hover':{
        borderRadius: '10px',
        background: '#789',
      }
    }
  },
  logoRowTwo:{
    width: '45%',
    marginRight: '3%',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginRight: '0',
      textAlign: 'center',
    },
    '& img':{
      maxWidth: 'calc(300px - 40px)',
      padding: '30px 20px',
      width: '100%',
      float: 'right',
      [theme.breakpoints.down( 'xs' )]: {
        float: 'none',
      },
      '@media (min-width: 600px) and (max-width: 1023px)': {
        maxWidth: '300px',
        width: 'calc(100% - 40px)',
      },
      '&:hover':{
        borderRadius: '10px',
        background: '#789',
      }
    }
  },
  logoRowFull:{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
  },
  logoRowThree:{
    position: 'relative',
    textAlign: 'center',
    '& img':{
      maxWidth: 'calc(280px - 100px)',
      padding: '15px 50px',
      '&:hover':{
        borderRadius: '10px',
        background: '#789',
      }
    }
  },
  jumpDesktopBtn:{
    marginTop: '40px',
    textAlign: 'center',
    marginBottom: '45px',
    '& p':{
      color: '#fff',
      fontSize: '20px',
      margin: '10px 0px',
    },
    '& a':{
      fontSize: '18px',
      lineHeight: '22px',
      padding: '16px 25px',
      color: '#fff',
      borderRadius: '10px',
      background: '#83a947',
      maxWidth: 'calc(224px - 50px)',
      margin: '0 auto',
      display: 'flex',
      '&:hover':{
        background: '#f69a34',
      }
    }
  },
  dialogVideo:{
    '& > div > div':{
      '@media (max-width: 959px)': {
        height: '0',
        position: 'relative',
        paddingBottom: '46.25%',
        width: '100% !important',
      }
    },
    '& iframe':{
      '@media (max-width: 959px)': {
        top: '0',
        left: '0',
        width: '100%',
        border: 'none',
        height: '100%',
        position: 'absolute',
      }
    }
  }
} );

export default styles;
