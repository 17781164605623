function FcMasterOutput( module1Data, outputBackendData, balanceSheetData ) {

  let year = parseInt(module1Data.start_year);            
  let yearLimit = parseInt(module1Data.start_year) + 15;
  
  let fcMasterOutputData = {};
  let tangibleAssetsInvestmentsValue = 0;
  let loansOutstandingValue = 0;
  let assetsLiabilitiesValue = 0;
  let incomeIncomeValue = 0;
  let supplementaryIncomeValue = 0;
  let taxesValue = 0;
  let incomeAfterTaxesValue = 0;
  let shortTermValue = 0;
  let longTermValue = 0;
  let retirementValue = 0;
  let totalInvestmentsValue = 0;
  let livingValue = 0;
  let interestValue = 0;
  let depreciationValue = 0;
  let totalExpensesValue = 0;
  let studentLoanValue = 0;
  let carValue = 0;
  let mortgageValue = 0;
  let cashInvestmentVal1 = 0;
  let cashInvestmentVal2 = 0;
  let carsValue = 0;
  let realEstateValue = 0;
  let debtValue = 0;
  let netWorthValue = 0;
  let expensesArr = [];
  let taxesArr = [];
  let grossIncomeArr = [];
  let cashInvestmentsArr = [];
  let carsRealEstateArr = [];
  let debtArr = [];
  let netWorthArr = [];
  let yearsList = [];

  for (year; year < yearLimit; year+=1) {
      yearsList.push(year);
      fcMasterOutputData[year] = {};
      fcMasterOutputData[year]['Balance Sheet'] = {} ;
      fcMasterOutputData[year]['Lifetime Earnings Breakdown'] = {} ;
      fcMasterOutputData[year]['Income'] = {} ;
      fcMasterOutputData[year]['Investments'] = {} ;
      fcMasterOutputData[year]['Expenses'] = {} ;
      fcMasterOutputData[year]['Liabilities'] = {} ;

      fcMasterOutputData[year]['Balance Sheet']['Assets'] = {} ;
      fcMasterOutputData[year]['Balance Sheet']['Liabilities'] = {} ;
      fcMasterOutputData[year]['Balance Sheet']['Net Worth'] = {} ;


      if( typeof outputBackendData !== 'undefined' && 
          typeof outputBackendData[year] !== 'undefined' && 
          typeof outputBackendData[year]['Back End Data'] !== 'undefined' ) {
          if( typeof outputBackendData[year]['Back End Data']['Assets'] !== 'undefined' ) {  
              fcMasterOutputData[year]['Balance Sheet']['Assets']['Tangible Assets + Investments'] = outputBackendData[year]['Back End Data']['Assets'] ;
          }
          else {
            fcMasterOutputData[year]['Balance Sheet']['Assets']['Tangible Assets + Investments'] = tangibleAssetsInvestmentsValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Liabilities'] !== 'undefined') {  
              fcMasterOutputData[year]['Balance Sheet']['Liabilities']['Loans Outstanding'] = outputBackendData[year]['Back End Data']['Liabilities'] ;
          }
          else {
            fcMasterOutputData[year]['Balance Sheet']['Liabilities']['Loans Outstanding'] = loansOutstandingValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Net Worth'] !== 'undefined') {  
              fcMasterOutputData[year]['Balance Sheet']['Net Worth']['Assets - Liabilities'] = outputBackendData[year]['Back End Data']['Net Worth'] ;
          }
          else {
            fcMasterOutputData[year]['Balance Sheet']['Net Worth']['Assets - Liabilities'] = assetsLiabilitiesValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Income'] !== 'undefined') {  
              fcMasterOutputData[year]['Income']['Income'] = outputBackendData[year]['Back End Data']['Income'] ;
          }
          else {
            fcMasterOutputData[year]['Income']['Income'] = incomeIncomeValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Supplementary Income'] !== 'undefined') {  
              fcMasterOutputData[year]['Income']['Supplementary Income'] = outputBackendData[year]['Back End Data']['Supplementary Income'] ;
          }
          else {
            fcMasterOutputData[year]['Income']['Supplementary Income'] = supplementaryIncomeValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Taxes'] !== 'undefined') {  
              fcMasterOutputData[year]['Income']['Taxes'] = outputBackendData[year]['Back End Data']['Taxes'] ;
          }
          else {
            fcMasterOutputData[year]['Income']['Taxes'] = taxesValue ;
          }
              
  
          if( typeof fcMasterOutputData[year]['Income']['Income'] !== 'undefined' && typeof fcMasterOutputData[year]['Income']['Supplementary Income'] !== 'undefined' && typeof fcMasterOutputData[year]['Income']['Taxes'] !== 'undefined') {  
              fcMasterOutputData[year]['Income']['Income after Taxes'] = ( parseFloat(fcMasterOutputData[year]['Income']['Income'])+parseFloat(fcMasterOutputData[year]['Income']['Supplementary Income']) ) - parseFloat(fcMasterOutputData[year]['Income']['Taxes']) ;
          }
          else {
            fcMasterOutputData[year]['Income']['Income after Taxes'] = incomeAfterTaxesValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Short-Term'] !== 'undefined') {  
              fcMasterOutputData[year]['Investments']['Short-Term'] = outputBackendData[year]['Back End Data']['Short-Term'] ;
          }
          else {
            fcMasterOutputData[year]['Investments']['Short-Term'] = shortTermValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Long-Term'] !== 'undefined') {  
              fcMasterOutputData[year]['Investments']['Long-Term'] = outputBackendData[year]['Back End Data']['Long-Term'] ;
          }
          else {
            fcMasterOutputData[year]['Investments']['Long-Term'] = longTermValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Retirement'] !== 'undefined') {  
              fcMasterOutputData[year]['Investments']['Retirement'] = outputBackendData[year]['Back End Data']['Retirement'] ;
          }
          else {
            fcMasterOutputData[year]['Investments']['Retirement'] = retirementValue ;
          }
              
  
          if( typeof fcMasterOutputData[year]['Investments']['Short-Term'] !== 'undefined' && typeof fcMasterOutputData[year]['Investments']['Long-Term'] !== 'undefined' && typeof fcMasterOutputData[year]['Investments']['Retirement'] !== 'undefined') {  
              fcMasterOutputData[year]['Income']['Total Investments'] =  parseFloat(fcMasterOutputData[year]['Investments']['Short-Term']) + parseFloat(fcMasterOutputData[year]['Investments']['Long-Term']) + parseFloat(fcMasterOutputData[year]['Investments']['Retirement']) ;
          }
          else {
            fcMasterOutputData[year]['Income']['Total Investments'] = totalInvestmentsValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Living'] !== 'undefined') {  
              fcMasterOutputData[year]['Expenses']['Living'] = outputBackendData[year]['Back End Data']['Living'] ;
          }
          else {
            fcMasterOutputData[year]['Expenses']['Interest'] = livingValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Interest'] !== 'undefined') {  
              fcMasterOutputData[year]['Expenses']['Interest'] = outputBackendData[year]['Back End Data']['Interest'] ;
          }
          else{
            fcMasterOutputData[year]['Expenses']['Interest'] = interestValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Depreciation'] !== 'undefined') {  
              fcMasterOutputData[year]['Expenses']['Depreciation'] = outputBackendData[year]['Back End Data']['Depreciation'] ;
          }
          else {
            fcMasterOutputData[year]['Expenses']['Depreciation'] = depreciationValue ;
          }
              
  
          if( typeof fcMasterOutputData[year]['Expenses']['Depreciation'] !== 'undefined' && typeof fcMasterOutputData[year]['Expenses']['Interest'] !== 'undefined' && typeof fcMasterOutputData[year]['Expenses']['Living'] !== 'undefined') {  
              fcMasterOutputData[year]['Expenses']['Total Expenses'] = ( parseFloat(fcMasterOutputData[year]['Expenses']['Living'])+parseFloat(fcMasterOutputData[year]['Expenses']['Interest']) ) - parseFloat(fcMasterOutputData[year]['Expenses']['Depreciation']) ;
          }
          else {
            fcMasterOutputData[year]['Expenses']['Total Expenses'] = totalExpensesValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Student Loan'] !== 'undefined') {  
              fcMasterOutputData[year]['Liabilities']['Student Loan'] = outputBackendData[year]['Back End Data']['Student Loan'] ;
          }
          else {
            fcMasterOutputData[year]['Liabilities']['Student Loan'] = studentLoanValue ;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Car Loan'] !== 'undefined') {  
              fcMasterOutputData[year]['Liabilities']['Car Loan'] = outputBackendData[year]['Back End Data']['Car Loan'] ;
          }
          else{
            fcMasterOutputData[year]['Liabilities']['Car Loan'] = carValue;
          }
              
  
          if( typeof outputBackendData[year]['Back End Data']['Mortgage'] !== 'undefined') {  
              fcMasterOutputData[year]['Liabilities']['Mortgage'] = outputBackendData[year]['Back End Data']['Mortgage'] ;
          } else {
            fcMasterOutputData[year]['Liabilities']['Mortgage'] = mortgageValue ;
          }
              
  
          if( typeof fcMasterOutputData[year]['Liabilities']['Student Loan'] !== 'undefined' && typeof fcMasterOutputData[year]['Liabilities']['Car Loan'] !== 'undefined' && typeof fcMasterOutputData[year]['Liabilities']['Mortgage'] !== 'undefined') {
            fcMasterOutputData[year]['Liabilities']['Total Liabilities'] = ( parseFloat(fcMasterOutputData[year]['Liabilities']['Student Loan'])+parseFloat(fcMasterOutputData[year]['Liabilities']['Car Loan']) ) - parseFloat(fcMasterOutputData[year]['Liabilities']['Mortgage']) ;
          } else {
            fcMasterOutputData[year]['Liabilities']['Total Liabilities'] = totalExpensesValue ;
          }
          
  
          if( typeof outputBackendData[year]['Back End Data']['Mortgage'] !== 'undefined') {  
              fcMasterOutputData[year]['Lifetime Earnings Breakdown']['Gross Income'] = outputBackendData[year]['Back End Data']['Mortgage'] ;
          } else {
            fcMasterOutputData[year]['Lifetime Earnings Breakdown']['Gross Income'] = mortgageValue;
          }
          
      }

      // To get the new graph - What does your picasso looks like
      fcMasterOutputData[year]['Picasso'] = {};

      fcMasterOutputData[year]['Picasso']['Expenses'] = -fcMasterOutputData[year]['Expenses']['Total Expenses'];
      expensesArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Expenses']));
      fcMasterOutputData[year]['Picasso']['Taxes'] = fcMasterOutputData[year]['Income']['Taxes'];
      taxesArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Taxes']));
      fcMasterOutputData[year]['Picasso']['Gross Income'] = fcMasterOutputData[year]['Income']['Income'];
      grossIncomeArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Gross Income']));
      // Cash - Investments Value
      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Assets']['Cash'] !== 'undefined' ){
          cashInvestmentVal1 = balanceSheetData[year]['Assets']['Cash'];
      }
      else{
          cashInvestmentVal1 = 0;  
      }
      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Total Investments'] !== 'undefined' ){
          cashInvestmentVal2 = balanceSheetData[year]['Total Investments'];
      }
      else{
          cashInvestmentVal2 = 0;  
      }
      fcMasterOutputData[year]['Picasso']['Cash - Investments'] = cashInvestmentVal1 + cashInvestmentVal2;
      cashInvestmentsArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Cash - Investments']));
      // Cars - Real Estate Value

      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Assets']['Auto Property'] !== 'undefined' ){
          carsValue = balanceSheetData[year]['Assets']['Auto Property'];
      }
      else{
          carsValue = 0;  
      }
      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Assets']['Real Estate Property'] !== 'undefined' ){
          realEstateValue = balanceSheetData[year]['Assets']['Real Estate Property'];
      }
      else{
          realEstateValue = 0;  
      }
      fcMasterOutputData[year]['Picasso']['Cars - Real Estate'] = carsValue + realEstateValue;
      carsRealEstateArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Cars - Real Estate']));
      //Debt Value
      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Total Liabilities'] !== 'undefined' ){
          debtValue = balanceSheetData[year]['Total Liabilities'];
      }
      else{
          debtValue = 0;  
      }
      fcMasterOutputData[year]['Picasso']['Debt'] = debtValue;
      debtArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Debt']));
      //Net Worth Value
      if( typeof balanceSheetData !== 'undefined' && balanceSheetData !== '' && typeof balanceSheetData[year]['Net Worth'] !== 'undefined' ){
          netWorthValue = balanceSheetData[year]['Net Worth'];
      }
      else{
          netWorthValue = 0;  
      }
      fcMasterOutputData[year]['Picasso']['Net Worth'] = netWorthValue;
      netWorthArr.push(Math.round(fcMasterOutputData[year]['Picasso']['Net Worth']));
  }
  let graphvaluesData = {};
  graphvaluesData['YearsList'] = yearsList;
  graphvaluesData['Expenses'] = expensesArr;
  graphvaluesData['Taxes'] = taxesArr;
  graphvaluesData['Gross Income'] = grossIncomeArr;
  graphvaluesData['Cash Investments'] = cashInvestmentsArr;
  graphvaluesData['Cars RealEstate'] = carsRealEstateArr;
  graphvaluesData['Debt'] = debtArr;
  graphvaluesData['Net Worth'] = netWorthArr;
  return graphvaluesData;
}

export default FcMasterOutput;
