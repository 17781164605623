import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import { percentFormatWithTwoDecimal, yearFormatter } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';

const originationFeesMarks = [
  {
    value: 0,
    label: '0.00% of Mortgage',
  },
  {
    value: 0.5,
    label: '0.5%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 1.5,
    label: '1.5%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 2.5,
    label: '2.5%',
  },
  {
    value: 3,
    label: '3%',
  },
];

const interestRateMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 12,
    label: '12%',
  },
];

const termsOfLoanMarks = [
  {
    value: 0,
    label: '0 yrs',
  },
  {
    value: 5,
    label: '5 yrs',
  },
  {
    value: 10,
    label: '10 yrs',
  },
  {
    value: 15,
    label: '15 yrs',
  },
  {
    value: 20,
    label: '20 yrs',
  },
  {
    value: 25,
    label: '25 yrs',
  },
  {
    value: 30,
    label: '30 yrs',
  },
];

const Mortgage = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, slideType, percentageFirstVal, percentageSecondVal
  } = props;
  const mortgageAmount = ( inputData.purchase_price !== undefined && inputData.purchase_price > 0 && inputData.down_payment_in_percentage !== undefined ) ? ( ( inputData.purchase_price / 100 ) * ( 100 - inputData.down_payment_in_percentage ) ) : 0;
  const loanPoints = inputData.points_for_the_mortgage !== undefined ? inputData.points_for_the_mortgage : 0;
  const loanPointsValue = ( mortgageAmount / 100 ) * loanPoints;

  const [origination, setOrigination] = React.useState( 0 );
  const [originationValue, setOriginationValue] = React.useState( 0 );
  const [interestRate, setInterestRate] = React.useState( 0 );
  const [termsOfLoan, setTermsOfLoan] = React.useState( 0 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(()=>{
    $( '#percentage1' ).css( 'height', `${percentageFirstVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageSecondVal}%` );
  },[percentageFirstVal, percentageSecondVal])

  useEffect( () => {
    let originationVal = 0.5;
    if ( inputData.origination_fees !== undefined ) {
      originationVal = inputData.origination_fees;
    }
    if ( mortgageAmount <= 0 ) {
      originationVal = 0;
    }
    let inputDetails  = {...inputData};
    inputDetails['origination_fees'] = originationVal;

    setOrigination( originationVal );
    setOriginationValue( ( mortgageAmount / 100 ) * originationVal );

    if ( inputData.rate_of_mortgage_in_percentage !== undefined ) {
      setInterestRate( inputData.rate_of_mortgage_in_percentage );
    } else {
      setInterestRate( 4 );
      inputDetails['rate_of_mortgage_in_percentage'] = 4;
      // updateData( 'rate_of_mortgage_in_percentage', 4 );
    }

    if ( inputData.term_of_mortgage !== undefined ) {
      setTermsOfLoan( inputData.term_of_mortgage );
    } else {
      setTermsOfLoan( 30 );
      inputDetails['term_of_mortgage'] = 30;
      // updateData( 'term_of_mortgage', 30 );
    }
    updateData('total_obj',inputDetails);
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'origination_slider': {
        setOrigination( newvalue );
        setOriginationValue( ( mortgageAmount / 100 ) * newvalue );
        updateData( mainField, newvalue );
        break;
      }
      case 'origination': {
        if ( newvalue > mortgageAmount ) {
          newvalue = mortgageAmount;
        }
        setOriginationValue( newvalue );
        let originationVal = 0.0;
        if ( newvalue >= 0 && mortgageAmount >= 0 ) {
          originationVal = ( newvalue / mortgageAmount ) * 100;
        }
        setOrigination( originationVal );
        updateData( mainField, originationVal );
        break;
      }
      case 'interest_rate': {
        if ( newvalue > 100 ) {
          newvalue = 100;
        }
        setInterestRate( newvalue );
        updateData( mainField, newvalue );
        break;
      }
      case 'terms_of_loan': {
        setTermsOfLoan( newvalue );
        updateData( mainField, newvalue );
        break;
      }
      default:
        break;
    }
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      {slideType === 'summary' ? (
        <p>Repaying the mortgage on a home is the largest cost of home ownership. Review the details below and make any final changes for your home mortgage before continuing.</p>
      ) : (
        <div>
          <p>A mortgage is a loan that a bank or mortgage lender provides to help finance the purchase of real estate. In exchange for providing you with money to purchase your home, the bank has a right to take your property if you fail to make your monthly loan payments.</p>
          <p>
            Mortgage interest rates can
            {' '}
            <a className={ classes.anchorStyle } href="https://www.bankrate.com/national-mortgage-rates/" target="_blank" rel="budget-fy2020 noopener noreferrer">vary by state</a>
            {' '}
            and can change dramatically based on
            {' '}
            <a className={ classes.anchorStyle } href="https://www.myfico.com/credit-education/calculators/loan-savings-calculator/" target="_blank" rel="budget-fy2020 noopener noreferrer">credit score.</a>
            {' '}
            Most common mortgage terms are 15 years and 30 years. And,
            {' '}
            <span aria-hidden="true" className={ classes.dottedBorder } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Origination Fees: Mortgage' ) }>origination fees</span>
            {' '}
            typically range from 0.5% to 1.0% of the mortgage amount with a maximum typically around $2,000.
          </p>
        </div>
      )}
       <div className={ classes.purchaseSlideScroll }>
        <div className={ classNames( classes.mortgageFlexRow, classes.topMargin ) }>
          <div className={ classes.sliderLeftBlock }>
            <div className={ classes.sliderSection }>
              <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin ) }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.fullwidthDiv ) }>
                  <div>
                    <div className={ classes.pwisliderInputs }>
                      <p><b>Mortgage Amount</b></p>
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.boldValues, classes.sliderRightSpace ) }>
                  <div>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ mortgageAmount } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
              <div className={ classes.flexDisplayNew }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay ) }>
                  <div>
                    <span aria-hidden="true" className={ classes.dottedBorder } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Origination Fees: Mortgage' ) }>
                      Origination Fees
                    </span>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ origination }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        min={ 0 }
                        max={ 3 }
                        step={ 0.1 }
                        valueLabelFormat={ percentFormatWithTwoDecimal }
                        disabled={ mortgageAmount <= 0 }
                        marks={ originationFeesMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ ( e, value ) => updateValue( e, value, 'origination_slider', 'origination_fees', 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.sliderRightSpace ) }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale disabled={ mortgageAmount <= 0 } allowNegative={ false } customInput={ TextField } thousandSeparator value={ originationValue } onValueChange={ ( e ) => updateValue( e, '', 'origination', 'origination_fees', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
              </div>

              {slideType === 'summary' && (
              <div className={ classes.flexDisplayNew }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay ) }>
                  <div>
                    <span className={ classes.dottedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Loan Points' ) }>
                      Loan Points
                    </span>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ loanPoints }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        disabled
                        min={ 0 }
                        max={ 3 }
                        step={ 0.1 }
                        marks={ originationFeesMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                      />
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.sliderRightSpace ) }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale disabled allowNegative={ false } customInput={ TextField } thousandSeparator value={ loanPointsValue } onFocus={ ( e ) => {e.target.select()} } />
                  </div>
                </div>
              </div>
              )}

              <div className={ classes.flexDisplayNew }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay ) }>
                  <div>
                    <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>
                      Interest Rate
                    </span>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ interestRate }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        min={ 0 }
                        max={ 12 }
                        step={ 0.05 }
                        marks={ interestRateMarks }
                        valueLabelFormat={ percentFormatWithTwoDecimal }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ ( e, value ) => updateValue( e, value, 'interest_rate', 'rate_of_mortgage_in_percentage', 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.sliderRightSpace ) }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div>
                    <NumberFormat
                      decimalScale={ isFocus!=='interest_rate'?2:5 }
                      fixedDecimalScale={ isFocus!=='interest_rate' }
                      disabled={ mortgageAmount <= 0 }
                      allowNegative={ false }
                      customInput={ TextField }
                      thousandSeparator
                      value={ interestRate }
                      onValueChange={ ( e ) => updateValue( e, '', 'interest_rate', 'rate_of_mortgage_in_percentage', 'number' ) }
                      onFocus={ ( e ) => {e.target.select();setIsFocus('interest_rate')} }
                      onBlur={emptyIsFocus}
                    />
                    <span className={ classNames( classes.percentSymbol, classes.percentIcon ) }>%</span>
                  </div>
                </div>
              </div>

              <div className={ classes.flexDisplayNew }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay ) }>
                  <div>
                    <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Mortgage' ) }>
                      Term of the Loan
                    </span>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ termsOfLoan }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        min={ 0 }
                        max={ 30 }
                        step={ 1 }
                        marks={ termsOfLoanMarks }
                        valueLabelFormat={ yearFormatter }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ ( e, value ) => updateValue( e, value, 'terms_of_loan', 'term_of_mortgage', 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.sliderData, classes.sliderRightSpace ) }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div>
                    <span className={ classes.termLoan }>{termsOfLoan}</span>
                    {' '}
                    <span className={ classes.yearSymbol }>yrs</span>
                  </div>
                </div>
              </div>
              <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.topPadding ) }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.fullwidthDiv ) }>
                  <div>
                    <div className={ classes.pwisliderInputs }>
                      <p><b>Monthly Payment (Principal & Interest)</b></p>
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.boldValues, classes.sliderRightSpace ) }>
                  <div>
                    {moduleServices.module13Data.moreHighermonthlyPayment !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.moreHighermonthlyPayment } prefix={ moduleServices.module13Data.moreHighermonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.moreHighermonthlyPayment < 0 && ')' } />
                    ) : ( '$0' )}
                  </div>
                </div>
              </div>
              <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.topTen ) }>
                <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.fullwidthDiv ) }>
                  <div>
                    <div className={ classes.pwisliderInputs }>
                      <p><b>Total Repayment Amount (Principal & Interest)</b></p>
                    </div>
                  </div>
                </div>
                <div className={ classNames( classes.sliderValues, classes.boldValues, classes.sliderRightSpace ) }>
                  <div>
                    {moduleServices.module13Data.biyingaHomeTotalPrncipalInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.biyingaHomeTotalPrncipalInterest } prefix={ moduleServices.module13Data.biyingaHomeTotalPrncipalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.biyingaHomeTotalPrncipalInterest < 0 && ')' } />
                    ) : ( '$0' )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classes.utilitiesChart }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Home Expenses
                  <span className={ classes.chartSpan }>
                    {moduleServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalMonthlyExpenses } prefix={ moduleServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classes.percentageBar } id="percentage1" />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {moduleServices.module13Data.totalIncomeYear !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalIncomeYear } prefix={ moduleServices.module13Data.totalIncomeYear >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalIncomeYear < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

Mortgage.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  slideType: PropTypes.string.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( Mortgage );
