import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CreditCard = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Credit Card</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/credit-card.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      credit card
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/credit-card-swipe.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          A payment card issued by a bank or other financial
                          institution that allows you to borrow money up to a
                          certain limit to pay for goods and services. By using
                          a credit card, you agree to pay back the money that
                          you spend on or before the payment due date.
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <Table className={classes.prosConsTable}>
                          <thead>
                            <tr>
                              <th>Pros</th>
                              <th>Cons</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                If you use your credit card responsibly and
                                always make your payments on time, you can build
                                and improve your credit score
                              </td>
                              <td>
                                Missing payments and/or using all of your credit
                                limit can hurt your credit score
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Credit cards provide fraud protection and other
                                protections that debit cards and cash do not
                              </td>
                              <td>
                                Credit cards sometimes charge annual fees, late
                                fees, and other fees
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Credit cards are more convenient to carry around
                                than cash
                              </td>
                              <td>
                                The convenience of credit cards can lead to
                                spending more than you should
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Credit cards offer rewards such as 1% cash back
                                on all purchases and more
                              </td>
                              <td>
                                Missing payments leads to very high interest
                                charges of 15-30%
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <p>
                          <strong>
                            What happens if I don&apos;t repay my credit card on
                            time?
                          </strong>
                          <br />
                          Let&apos;s say you have a fancy night out with friends
                          and spend $300 for dinner and a movie on your credit
                          card. When your payment comes due, you forget to or
                          can&apos;t repay your $300 credit card balance. What
                          happens?
                        </p>

                        <p>
                          When you miss a payment, your credit card company
                          immediately starts charging you interest the next day
                          and every day after that until you repay your late
                          balance in full. Every day interest is added to your
                          balance and then recalculated. See the formulas below
                          for daily interest:
                        </p>

                        <p>
                          Daily Interest Rate = Annual Interest Rate ÷ 365 days
                          <br />
                          One Day of Interest = Late Balance x Daily Interest
                          Rate
                          <br />
                          Next Day Balance = One Day of Interest + Late Balance
                        </p>

                        <p>
                          If the credit card company charged you 30% annual
                          interest rate on your $300 late balance, check out the
                          table below to see how the amount owed grows over
                          time.
                        </p>
                        <Table className={classes.creditTable}>
                          <thead>
                            <tr>
                              <th>Period</th>
                              <th>Beginning Balance</th>
                              <th># of days</th>
                              <th>Interest Charged</th>
                              <th>Ending Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1 day</td>
                              <td>$300.00</td>
                              <td>1</td>
                              <td>$0.25</td>
                              <td>$300.25</td>
                            </tr>
                            <tr>
                              <td>30 days</td>
                              <td>$300.00</td>
                              <td>30</td>
                              <td>$7.49</td>
                              <td>$307.49</td>
                            </tr>
                            <tr>
                              <td>180 days</td>
                              <td>$300.00</td>
                              <td>180</td>
                              <td>$47.81</td>
                              <td>$347.81</td>
                            </tr>
                            <tr>
                              <td>365 days</td>
                              <td>$300.00</td>
                              <td>365</td>
                              <td>$104.91</td>
                              <td>$404.91</td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          Every day after your payment due date that you
                          don&apos;t repay your credit card balance, interest
                          accrues and the amount you owe will continue to grow.
                          If you didn&apos;t repay that $300 night out until 12
                          months later, you would owe $404.91!
                        </p>

                        <p>
                          A good rule of thumb is to only make purchases on your
                          credit card if you have the money in your checking
                          account to repay that amount. And, you should never
                          carry a balance on your credit card unless for an
                          emergency. Carrying a balance leads to high interest
                          charges.
                        </p>
                      </div>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CreditCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditCard);
