import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import spacesBanner from '../../assets/img/spaces/spaces_banner.jpg';

const Spaces = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.spacesPage }>
        <div className={ classes.spacesBanner }>
          <div className={ classes.spacesImage }>
            <img src={ spacesBanner } alt="banner" />
            <div className={ classes.container }>
              <div className={ classes.bannerCaption }>
                <div className={ classes.bannerCaptionTitle }>
                  <Typography variant="h3" component="h3">Make Your Best Moves.</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.spacesContent }>
          <div className={ classes.container }>
            <div className={ classes.sevenDimensions }>
              <Typography variant="h2" component="h2">Seven-dimensional planning made easy.</Typography>
              <h3>
                Me
                <span>•</span>
                Us
                <span>•</span>
                Pro
                <span>•</span>
                Money
                <span>•</span>
                Fun
                <span>•</span>
                Fit
                <span>•</span>
                Spirit
              </h3>
            </div>
            <div className={ classes.innovateBlock }>
              <div className={ classes.innovateRow }>
                <Grid container spacing={ 4 }>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Explore your spaces </Typography>
                    <p>Discover all seven of your dimensions and how they work together to synchronize your strategies.</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Plan 5, 10, 15 years </Typography>
                    <p>Build confidence as you develop a mindset for the future. Form your big picture and then construct the details.</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Logical planning</Typography>
                    <p>Work with your seven dimensions as you move through a logical planning process.</p>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.innovateRow }>
                <Grid container spacing={ 4 }>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Building blocks</Typography>
                    <p>Activate building blocks in each dimension to develop knowledge, skills, and form a stronger path.</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Assess + validate</Typography>
                    <p>Once you&apos;ve created your plan, toss it into Inkwiry&apos;s FC to see if your ideas pencil out. Financial modeling is essential to success.</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <Typography variant="h4" component="h4">Integrated tool</Typography>
                    <p>Inkwiry Sense, the FC and Connect help raise your awareness and offer insights to your own best solutions.</p>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.buttonRow }>
                <a href={ () => false }> COMING SOON </a>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.testimonialBlock }>
          <div className={ classes.container }>
            <Typography variant="h4" component="h4">
              <i>
                HOW CAN I LIVE MY DREAM
                <span>&nbsp;(ME)</span>
              </i>
              <i>
                to do something I&apos;m passionate about
                <span>&nbsp;(PRO)</span>
              </i>
              <i>
                that rewards me financially
                <span>&nbsp;(MONEY)</span>
                {' '}
                and personally
                <span>&nbsp;(SPIRIT)</span>
              </i>
              <i>and gives me the freedom to do </i>
              {' '}
              <i>
                everything else I want to do
                <span>&nbsp;(US, FUN, FIT)</span>
                ?
              </i>
            </Typography>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Spaces.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Spaces );
