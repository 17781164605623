import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const RetirementSummary = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>You're on your way to building your retirement savings. Review your 401(k) contributions during this career move.</p>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
          <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70}` }>
              <table cellPadding="0" cellSpacing="0">
                  <thead>
                      <tr className={ classes.trHead }>
                          <td>401(k) Retirement Accounts</td>
                          <td>401(k)</td>
                          <td>Roth 401(k)</td>
                      </tr>
                  </thead>
                <tbody>
                  <tr>
                    <td>
                      <span><strong>Beginning 401(k) Balance in Year {(moduleServices.module8Data.start_year !== undefined && moduleServices.module8Data.start_year !== null )&& moduleServices.module8Data.start_year}</strong></span>
                    </td>
                    <td>
                      <span>{moduleServices.module8Data.beginningBalanceInYearThird !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.beginningBalanceInYearThird} prefix={moduleServices.module8Data.beginningBalanceInYearThird >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.beginningBalanceInYearThird < 0 && ')'} />
                        ) : ('$0')}</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.beginningBalanceYearThirdRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.beginningBalanceYearThirdRoth} prefix={moduleServices.module8Data.beginningBalanceYearThirdRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.beginningBalanceYearThirdRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Personal Contributions After {(moduleServices.module8Data.end_year > 0 && moduleServices.module8Data.start_year > 0) ? (moduleServices.module8Data.end_year - moduleServices.module8Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.personalContributionAddCareerAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.personalContributionAddCareerAdv} prefix={moduleServices.module8Data.personalContributionAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.personalContributionAddCareerAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.personalContributionAddCareerAdvRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.personalContributionAddCareerAdvRoth} prefix={moduleServices.module8Data.personalContributionAddCareerAdvRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.personalContributionAddCareerAdvRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Personal Contributions After {(moduleServices.module8Data.end_year > 0 && moduleServices.module8Data.start_year > 0) ? (moduleServices.module8Data.end_year - moduleServices.module8Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.earningsOnPersonalContributionsAfter !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.earningsOnPersonalContributionsAfter} prefix={moduleServices.module8Data.earningsOnPersonalContributionsAfter >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.earningsOnPersonalContributionsAfter < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.earningsOnPersonalContributionsAfterRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.earningsOnPersonalContributionsAfterRoth} prefix={moduleServices.module8Data.earningsOnPersonalContributionsAfterRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.earningsOnPersonalContributionsAfterRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Personal Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.totalPersonalContributionsAndEarnings !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalPersonalContributionsAndEarnings} prefix={moduleServices.module8Data.totalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalPersonalContributionsAndEarnings < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module8Data.totalPersonalContributionsAndEarningsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalPersonalContributionsAndEarningsRoth} prefix={moduleServices.module8Data.totalPersonalContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalPersonalContributionsAndEarningsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Employer Contributions After {(moduleServices.module8Data.end_year > 0 && moduleServices.module8Data.start_year > 0) ? (moduleServices.module8Data.end_year - moduleServices.module8Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module8Data.employerContributionAddCarrerAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.employerContributionAddCarrerAdv} prefix={moduleServices.module8Data.employerContributionAddCarrerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.employerContributionAddCarrerAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module8Data.employerContributionAddCarrerAdvRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.employerContributionAddCarrerAdvRoth} prefix={moduleServices.module8Data.employerContributionAddCarrerAdvRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.employerContributionAddCarrerAdvRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Employer Contributions After {(moduleServices.module8Data.end_year > 0 && moduleServices.module8Data.start_year > 0) ? (moduleServices.module8Data.end_year - moduleServices.module8Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>{moduleServices.module8Data.earningsOnEmployerContributionsAdd !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.earningsOnEmployerContributionsAdd} prefix={moduleServices.module8Data.earningsOnEmployerContributionsAdd >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.earningsOnEmployerContributionsAdd < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>{moduleServices.module8Data.earningsOnEmployerContributionsAddRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.earningsOnEmployerContributionsAddRoth} prefix={moduleServices.module8Data.earningsOnEmployerContributionsAddRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.earningsOnEmployerContributionsAddRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Employer Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.totalEmployerContributionsAndEarnings !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalEmployerContributionsAndEarnings} prefix={moduleServices.module8Data.totalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalEmployerContributionsAndEarnings < 0 && ')'} />
                        ) : ('$0')}  
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.totalEmployerContributionsAndEarningsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.totalEmployerContributionsAndEarningsRoth} prefix={moduleServices.module8Data.totalEmployerContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.totalEmployerContributionsAndEarningsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      401(k) Balance before <span onClick={ () => handleDefinationPopup('Vesting') }>Vesting</span> Reductions
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.balanceBeforeVestingReductionsAdd !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.balanceBeforeVestingReductionsAdd} prefix={moduleServices.module8Data.balanceBeforeVestingReductionsAdd >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.balanceBeforeVestingReductionsAdd < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.balanceBeforeVestingReductionsAddRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.balanceBeforeVestingReductionsAddRoth} prefix={moduleServices.module8Data.balanceBeforeVestingReductionsAddRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.balanceBeforeVestingReductionsAddRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      % of Employer Contributions & Earnings Not Vested
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAdd} prefix={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAdd >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAdd < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth} prefix={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.employerContributionsAndEarningsNotVestedAddRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Ending 401(k) Balance in Year {(moduleServices.module8Data.end_year > 0 ) ? moduleServices.module8Data.end_year : 0}
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.endingBalanceInYearAddCareerAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.endingBalanceInYearAddCareerAdv} prefix={moduleServices.module8Data.endingBalanceInYearAddCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.endingBalanceInYearAddCareerAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module8Data.endingBalanceInYearAddCareerAdvRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.endingBalanceInYearAddCareerAdvRoth} prefix={moduleServices.module8Data.endingBalanceInYearAddCareerAdvRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.endingBalanceInYearAddCareerAdvRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
  </div>

  );
};

RetirementSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( RetirementSummary );
