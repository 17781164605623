import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const MonetaryPolicyTwo = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Monetary Policy: Part 2</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/monetary-policy-part2.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Monetary Policy: Part 2
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/monetary-policy-part2-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The{' '}
                          <Link
                            to="/finance-explained/federal-reserve"
                            target="_blank">
                            Federal Reserve{' '}
                          </Link>{' '}
                          can take action using its{' '}
                          <Link
                            to="/finance-explained/monetary-policy"
                            target="_blank">
                            monetary policy
                          </Link>{' '}
                          tools that can directly affect your personal finances.
                          We&apos;re going to explore how the Fed can affect the
                          interest you might earn on a savings account to the
                          interest rate you might pay on a mortgage.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The Fed&apos;s job is to promote economic stability
                          and a safe financial system. One of the tools the Fed
                          uses to do that is called the federal funds rate. Why
                          would the Fed raise or lower interest rates?
                          Let&apos;s find out.
                        </p>
                        <p>
                          <strong>
                            What happens when the Fed lowers interest rates?
                          </strong>
                          <br />
                          When the economy looks to be slowing, the Fed will
                          lower interest rates to try and stimulate economic
                          growth. But what does that really mean and how does
                          that actually work?
                        </p>
                        <p>
                          Banks look to the federal funds rate to determine the
                          interest rate they charge on loans such as a car loan
                          or a mortgage. When the federal funds rate decreases,
                          the interest rate on a mortgage should decrease as
                          well. Lower interest rates mean less interest to pay
                          each month. Less interest to pay equals more money in
                          your pocket. And more money in your pocket should lead
                          you to spend more at stores or invest more money.
                        </p>
                        <p>
                          Now imagine the impact of hundreds of millions of
                          people having more money in their pocket because of
                          lower interest rates. The Fed lowers interest rates
                          when the economy seems to be slowing to encourage
                          spending, which should spur growth.
                        </p>
                        <p>
                          <strong>
                            What happens when the Fed raises interest rates?
                          </strong>
                          <br />
                          The effect is exactly the opposite. When the Fed
                          raises rates, the interest rate on a mortgage should
                          increase. Higher interest equals more interest to pay
                          each month and less money in your pocket. Less money
                          in your pocket means less money to spend or invest.
                        </p>
                        <p>
                          Higher rates also encourage savings. Savings accounts,
                          Certificates of Deposit, and money market funds pay
                          you interest for the money you deposit. The rates on
                          these savings accounts are directly impacted by the
                          federal funds rate. When rates rise, savings accounts
                          pay more interest to you on your deposit. Instead of
                          investing in riskier stocks or bonds or spending at
                          your local stores or online, a higher interest rate
                          may persuade you to save your money.
                        </p>
                        <p>
                          The Fed hopes that by raising rates, consumers and
                          businesses have less money to spend and save more to
                          slow the economic growth.
                        </p>
                        <div className={classes.grayBox}>
                          <p classNames={classes.grayboxMargin}>
                            Next time you hear on TV about the Federal Reserve
                            and interest rates, you&apos;ll know that:
                          </p>
                          <ul>
                            <li>
                              When the Fed lowers rates, they are trying to
                              stimulate growth by lowering the cost of borrowing
                              money such as credit cards, car loans, mortgages
                              and business loans
                            </li>
                            <li>
                              When the Fed lowers rates, the interest you earn
                              on your savings accounts also decreases
                            </li>
                            <li>
                              When the Fed raises rates, they are trying to slow
                              growth by raising the cost of borrowing money such
                              as credit cards, car loans, mortgages and business
                              loans
                            </li>
                            <li>
                              When the Fed raises rates, the interest you earn
                              on your savings accounts also increases,
                              encouraging you to save rather than spend or
                              invest
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
MonetaryPolicyTwo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MonetaryPolicyTwo);
