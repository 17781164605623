const styles = ( ) => ( {
  containerFluid: {
    maxWidth: '100%',
    padding: '30px',
    margin: '0px auto',
    background: '#014372',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  img: {
    verticalAlign: 'middle',
  },
  dashboardLogo: {
    width: '150px',
    '& a': {
      display: 'flex',
      '& img': {
        width: '100%',
      },
    },
  },
  flexDisplay: {
    display: 'flex',
  },
  nextButton: {
    background: '#cea347',
    border: '2px solid #fff',
    color: '#fff',
    padding: '7px 45px',
    borderRadius: '6px',
    textTransform: 'uppercase',
    marginTop: '50px',
    marginRight: '20px',
    fontSize: '20px',
    lineHeight: '1.42857143',
    '&:hover': {
      background: '#d09924',
      border: '2px solid #d09924',
    },
  },
  welcomePageThree: {
    width: '70%',
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  screenTitle: {
    textAlign: 'center',
    fontSize: '30px',
    color: '#fff',
    marginBottom: '30px',
    marginTop: '0px',
    fontWeight: 'normal',
  },
  certificateScreen: {
    display: 'flex',
    color: '#fff',
    fontSize: '22px',
    paddingLeft: '10%',
    '& p': {
      margin: '0px auto 40px auto',
      '&:last-child': {
        marginBottom: '0px',
      },
    },
  },
  challengeImg: {
    marginRight: '55px',
    '& img': {
      height: '200px',
    },
  },
  challengeBtn: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& a': {
      display: 'flex',
    },
  },
  mainLogo: {
    width: '50%',
    margin: '80px auto 30px auto',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    '& img': {
      width: '100%',
    },
  },
  imageTopSpace: {
    paddingTop: '60px',
  },
  finalToolkit: {
    width: '78%',
  },
  newCertificate: {
    paddingLeft: '0',
  },
  galleryImage: {
    margin: '30px 0px',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  newChallengeImage: {
    display: 'flex',
    alignItems: 'center',
  },
  nologoPadding: {
    paddingBottom: '0px',
  },
} );

export default styles;
