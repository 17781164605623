import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import InvestmentBottomTwo from './investmment-bottomTwo';

const LifeSettingTimeline = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData,
  } = props;

  const [startYear, setStartYear] = React.useState();

  const d = new Date();
  const currentYear = d.getFullYear();
  const startYearOptions = [];
  const loopYear = modulesServices.module1Data.start_year > 0 ? modulesServices.module1Data.start_year : currentYear;
  for (let year = 0; year <= 14; year += 1) {
    startYearOptions.push(loopYear + year);
  }

  const handleStartYear = (event) => {
    let InvObj = {...getInvestmentObject};
    let start_year = 0;
    start_year = parseInt(event.target.value);
    setStartYear(start_year);
    InvObj['life_insurance_start_year']=parseInt(event.target.value, 10);
    handleInvestmentData(InvObj);
  };

  useEffect(() => {
    if(getInvestmentObject.life_insurance_start_year !== undefined && getInvestmentObject.life_insurance_start_year !== '' && getInvestmentObject.life_insurance_start_year !== null){
    setStartYear(getInvestmentObject.life_insurance_start_year);
    }else{
      setStartYear(currentYear);
    }
  }, [getInvestmentObject]);

  return (
    <div className={classes.contentBlock}>
      <p>Historically, 50% of Americans have a life insurance policy. Life insurance provides financial protection for your family or those you provide for in case of an untimely death. In the event of an untimely death, a lump sum payment would be made to your beneficiary.</p>
      <p>When do you plan on contributing to a life insurance policy? If you already are contributing, enter today’s year. If not, enter the year you plan to start.</p>
      <div className={classNames(classes.grayBox, classes.investGrayDesign)}>
        <p>Make sure your net cash flow is positive in the year you plan to start.</p>
        <ul className={classes.yearsList}>
          <li>
            <span>I plan to start making contributions to a life insurance plan in year &nbsp;</span>
            <select value={startYear} onChange={handleStartYear}>
              {startYearOptions.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>
            .
          </li>
          <li> Beginning life insurance cash value in year &nbsp;
            {startYear}:
            {modulesServices.module9Data.beginningLifeInsBalance ?
              <NumberFormat id="beginning" displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={modulesServices.module9Data.beginningLifeInsBalance} prefix={'$'} />
              :
              '$0'
            }.
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module9" />
      <div className={ `${classes.borderDivider} ${classes.borderDividerMT30}` } />
      {startYear !== '' && startYear !== undefined &&
        <InvestmentBottomTwo activeSlide={13} getInvestmentObject={getInvestmentObject} startYear={startYear} />
      }
    </div>
  );
};

LifeSettingTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,

};

export default withStyles(styles)(LifeSettingTimeline);
