const styles = ( theme ) => ( {
  confirmDialogContent: {
    fontSize: '20px',
    padding: '15px',
    paddingTop: '0px',
    lineHeight: '30px',
    overflow: 'initial',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& p': {
      margin: '0',
    }
  },
  confirmDialogButtons: {
    padding: '15px',
    borderTop: '1px solid #ccc',
    '@media (max-width: 767px)': {
      maxWidth: '400px',
      margin: '0px auto',
      display: 'block',
      textAlign: 'center',
    },
    '& button': {
      "padding": "10px 18px",
      "fontSize": "17px",
      "lineHeight": "17px",
      "borderRadius": "4px",
      "color": "#333",
      "background": "#fff",
      "borderColor": "#ccc",
      "border": '2px solid',
      fontFamily:'MuseoSans-300',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
        "fontSize": "16px",
        "padding": "8px 15px",
          margin: "3px auto 3px auto !important",
          minWidth: '220px',
      }
    }
  },
  confirmBlueBtn: {
    "color": "#fff !important",
    "background": "#0069aa !important",
    "borderColor": "#0069aa !important", 
  },
  confirmGreenBtn: {
    "color": "#fff !important",
    "background": "#84a84d !important",
    "borderColor": "#84a84d !important", 
  },
  confirmGoldBtn: {
    "color": "#fff !important",
    "background": "#caa336 !important",
    "borderColor": "#caa336 !important", 
  },
  confirmDialogNew: {
    zIndex: '9999 !important',
    '& > div > div': {
      maxWidth: '750px',
      '& h2': {
        fontSize: '21px',
        borderBottom: '1px solid#ccc',
        margin: '0 -24px',
        padding: '10px 15px',
        color: '#0069aa',
        fontFamily: 'MuseoSans-500',
        marginTop: '-13px',
        lineHeight: '28px',
      }
    }
  },
  clearConfirmDialog: {
     '& > div > div': {
        width: '645px',
        [theme.breakpoints.down( 'sm' )]: {
           width: 'auto', 
        },
      },
      '& p':{
        fontSize:'16px',
        lineHeight: '1.42857143'
      }
  }
} );

export default styles;
