import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-aca/styles';
// import { Dialog, DialogContent } from '@material-ui/core/DialogContent';

const InvestmentBottomTwo = (props) => {
  const { classes, getInvestmentObject,  activeSlide } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState(false);
  const [otherExpenses, setOtherExpenses] = React.useState(false);
  // const [tableObj, setTableObj] = React.useState({});
  const [year, setYear] = React.useState('');

  const d = new Date();
  const currentYear = d.getFullYear();

  useEffect(() => {
    if (activeSlide === 3 || activeSlide === 4) {
      if (getInvestmentObject && getInvestmentObject.short_term_start_year > 0 && getInvestmentObject.short_term_start_year !== undefined && getInvestmentObject.short_term_start_year !== '' ) {
        setYear(getInvestmentObject.short_term_start_year !== null ? parseInt(getInvestmentObject.short_term_start_year) : currentYear );
      } else {
        setYear('');
      }
    }
    if (activeSlide === 7 || activeSlide === 8) {
      if (getInvestmentObject && getInvestmentObject.long_term_start_year > 0 && getInvestmentObject.long_term_start_year !== undefined && getInvestmentObject.long_term_start_year !== '') {
        setYear(getInvestmentObject.long_term_start_year !== null ? parseInt(getInvestmentObject.long_term_start_year) : currentYear );
      } else {
        setYear('');
      }
    }
    if (activeSlide === 11 || activeSlide === 12) {
      if (getInvestmentObject && getInvestmentObject.plan_529_start_year  > 0 && getInvestmentObject.plan_529_start_year !== undefined && getInvestmentObject.plan_529_start_year !== '') {
        setYear(getInvestmentObject.plan_529_start_year !== null ? parseInt(getInvestmentObject.plan_529_start_year) : currentYear );
      } else {
        setYear('');
      }
    }
    if (activeSlide === 13 || activeSlide === 14) {
      if (getInvestmentObject && getInvestmentObject.life_insurance_start_year  > 0 && getInvestmentObject.life_insurance_start_year !== undefined && getInvestmentObject.life_insurance_start_year !== '') {
        setYear(getInvestmentObject.life_insurance_start_year !== null ? parseInt(getInvestmentObject.life_insurance_start_year) : currentYear);
      } else {
        setYear('');
      }
    }
  }, [getInvestmentObject]);

  const individualLivingCalc = (label) => {
    let incomeStatementData = moduleServices.incomeStatementCompleteData();
    if (incomeStatementData !== undefined && incomeStatementData !== ''
      && incomeStatementData[year] !== undefined && incomeStatementData[year] !== ''
      && incomeStatementData[year][label] !== undefined) {
      return incomeStatementData[year][label];
    } else {
      return 0;
    }
  }

  const careerPathLivingExpenses = () => {
    return individualLivingExpensesCalc('Rent') +
      individualLivingExpensesCalc('Utilities') +
      individualLivingExpensesCalc('Food') +
      individualLivingExpensesCalc('Clothing') +
      individualLivingExpensesCalc('Entertainment') +
      individualLivingCalc('Technology') +
      individualLivingCalc('Transportation') +
      individualLivingExpensesCalc('Miscellaneous') +
      individualLivingExpensesCalc('Healthcare Costs other than Premium') +
      individualLivingCalc('Health Insurance Premium');
  }

  const individualLivingExpensesCalc = (label, type) => {
    let incomeSheetData = moduleServices.incomeStatementCompleteData();
    if (incomeSheetData !== undefined && incomeSheetData !== '') {
      if (incomeSheetData[year] !== undefined && incomeSheetData[year] !== ''
        && incomeSheetData[year] !== undefined && incomeSheetData[year] !== ''
        && incomeSheetData[year]['livingExpenses'] !== undefined && incomeSheetData[year]['livingExpenses'] !== ''
        && incomeSheetData[year]['livingExpenses'][label] !== undefined
        && incomeSheetData[year]['livingExpenses'][label] !== '') {
        return incomeSheetData[year]['livingExpenses'][label];
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const otherAnnaulLivingExpenses = () => {
    return individualLivingExpensesCalc('Higher Education Tuition and Fees') +
      individualLivingExpensesCalc('Higher Education Room & Board') +
      individualLivingExpensesCalc('Off Campus Food') +
      individualLivingExpensesCalc('Children') +
      individualLivingExpensesCalc('Pet') +
      individualLivingCalc('Total Car Expenses') +
      individualLivingCalc('Total Real Estate Expenses');
  }

  const higherEducationExpenses = () => {
    return individualLivingExpensesCalc('Higher Education Tuition and Fees') +
      individualLivingExpensesCalc('Higher Education Room & Board') +
      individualLivingExpensesCalc('Off Campus Food');
  }

  const totalAnnualExpenses = () => {
    return careerPathLivingExpenses() + otherAnnaulLivingExpenses() + individualLivingCalc('Total Interest Expenses') + individualLivingCalc('Total Taxes');
  }

  const annualNetIncome = () => {
    return individualLivingCalc('Total Income') + totalAnnualExpenses();
  }

  const annualRetirementContributions = () => {
    let annualRetirementContributions = 0;
    let cashFlowData = moduleServices.cashFlowCompleteData();
    if (year !== undefined && year !== '' &&
      cashFlowData !== undefined && cashFlowData !== '' &&
      cashFlowData[year] !== undefined && cashFlowData[year] !== '' && cashFlowData[year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
      annualRetirementContributions = -cashFlowData[year]['Cash from Investing Activities']['Retirement Contributions'];
    }
    return annualRetirementContributions;
  }

  const HSAContributions = () => {
    let annualRetirementContributions = 0;
    let cashFlowData = moduleServices.cashFlowCompleteData();
    if (year !== undefined && year !== '' &&
      cashFlowData !== undefined && cashFlowData !== '' &&
      cashFlowData[year] !== undefined && cashFlowData[year] !== '' && cashFlowData[year]['Cash from Investing Activities']['HSA Contributions'] !== undefined && cashFlowData[year]['Cash from Investing Activities']['HSA Contributions'] !== '') {
      annualRetirementContributions = -cashFlowData[year]['Cash from Investing Activities']['HSA Contributions'];
    }
    return annualRetirementContributions;
  }

  const annualNetCashFlow = () => {
    return annualNetIncome() - annualRetirementContributions() - HSAContributions();
  }

  const percentageOfAfterTaxIncome = () => {
    return annualNetCashFlow() / (individualLivingCalc('Total Income') - individualLivingCalc('Total Taxes'));
  }

  const shortTermInvestmentContributon = (label) => {
    let investmentsData = moduleServices.investmentsCompleteData();
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData[label] !== undefined && investmentsData[label] !== '' && investmentsData[label][year] !== undefined && investmentsData[label][year] !== '' && investmentsData[label][year]['Cash Contibution'] !== undefined && investmentsData[label][year]['Cash Contibution'] !== '') {
      return investmentsData[label][year]['Cash Contibution'];
    }
    return 0;
  }

  const annualNetCashFlowAfterVacation = () => {
    return shortTermInvestmentContributon('Long-Term Investments') - shortTermInvestmentContributon('529 Plan') - shortTermInvestmentContributon('Life Insurance');
  }

  const percentageOfIncome = () => {
    var finalIncome = annualNetCashFlowAfterVacation();
    var startingIncome = individualLivingCalc('Total Income') - individualLivingCalc('Total Taxes');
    if (startingIncome !== 0 && finalIncome !== 0) {
      return (finalIncome / startingIncome);
    } else {
      return 0;
    }
  }


  return (
    <div className={classNames(classes.vacationTable, classes.budgetTable)}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            <th>Monthly</th>
            <th>Year <span>{year}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <span className={individualLivingCalc('Total Income') / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {individualLivingCalc('Total Income') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Income') / 12} prefix={individualLivingCalc('Total Income') / 12 >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Income') / 12 < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span className={individualLivingCalc('Total Income') < 0 ? classes.dangerText : ''}>
                <b>
                  {individualLivingCalc('Total Income') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Income')} prefix={individualLivingCalc('Total Income') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Income') < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Total Taxes Paid
            </td>
            <td>
              <span className={individualLivingCalc('Total Taxes') / 12 < 0 ? classes.dangerText : ''} >
                {individualLivingCalc('Total Taxes') / 12 !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Taxes') / 12} prefix={individualLivingCalc('Total Taxes') / 12 >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Taxes') / 12 < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span className={individualLivingCalc('Total Taxes') < 0 ? classes.dangerText : ''}>
                {individualLivingCalc('Total Taxes') !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Taxes')} prefix={individualLivingCalc('Total Taxes') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Taxes') < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classNames(classes.livingRow, classes.cursorPointer)} aria-hidden="true" onClick={() => { setExpandLivingExpenses(!expandLivingExpenses) }}>
            <td>
              <span>
                {!expandLivingExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {expandLivingExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Living Expenses</b>
            </td>
            <td>
              <span className={careerPathLivingExpenses() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {careerPathLivingExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={careerPathLivingExpenses() / 12} prefix={careerPathLivingExpenses() >= 0 ? '$' : '($'} suffix={careerPathLivingExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span className={careerPathLivingExpenses() < 0 ? classes.dangerText : ''}>
                <b>
                  {careerPathLivingExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={careerPathLivingExpenses()} prefix={careerPathLivingExpenses() >= 0 ? '$' : '($'} suffix={careerPathLivingExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Rent
              </td>
              <td>
                <span />
                <span />
                <span className={individualLivingExpensesCalc('Rent', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Rent', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Rent', 'annual') / 12} prefix={individualLivingExpensesCalc('Rent', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Rent', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span className={individualLivingExpensesCalc('Rent', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Rent', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Rent', 'annual')} prefix={individualLivingExpensesCalc('Rent', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Rent', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Utilities
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Utilities', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Utilities', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Utilities', 'annual') / 12} prefix={individualLivingExpensesCalc('Utilities', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Utilities', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Utilities', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Utilities', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Utilities', 'annual')} prefix={individualLivingExpensesCalc('Utilities', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Utilities', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Food
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Food', 'annual') / 12 < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Food', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Food', 'annual') / 12} prefix={individualLivingExpensesCalc('Food', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Food', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Food', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Food', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Food', 'annual')} prefix={individualLivingExpensesCalc('Food', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Food', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Clothing & Personal Care
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Clothing', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Clothing', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Clothing', 'annual') / 12} prefix={individualLivingExpensesCalc('Clothing', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Clothing', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Clothing', 'annual') / 12 < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Clothing', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Clothing', 'annual')} prefix={individualLivingExpensesCalc('Clothing', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Clothing', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Entertainment
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Entertainment', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Entertainment', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Entertainment', 'annual') / 12} prefix={individualLivingExpensesCalc('Entertainment', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Entertainment', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Entertainment', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Entertainment', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Entertainment', 'annual')} prefix={individualLivingExpensesCalc('Entertainment', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Entertainment', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Technology
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Technology', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Technology', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Technology', 'annual') / 12} prefix={individualLivingExpensesCalc('Technology', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Technology', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Technology', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Technology', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Technology', 'annual')} prefix={individualLivingExpensesCalc('Technology', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Technology', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Transportation
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Transportation', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Transportation', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Transportation', 'annual') / 12} prefix={individualLivingExpensesCalc('Transportation', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Transportation', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Transportation', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Transportation', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Transportation', 'annual')} prefix={individualLivingExpensesCalc('Transportation', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Transportation', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Miscellaneous
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Miscellaneous', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Miscellaneous', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Miscellaneous', 'annual') / 12} prefix={individualLivingExpensesCalc('Miscellaneous', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Miscellaneous', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Miscellaneous', 'annual') / 12 < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Miscellaneous', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Miscellaneous', 'annual')} prefix={individualLivingExpensesCalc('Miscellaneous', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Miscellaneous', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Costs outside Premium
              </td>
              <td>
                <span className={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') / 12} prefix={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span className={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') < 0 ? classes.dangerText : ''}>

                  {individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual')} prefix={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Healthcare Costs other than Premium', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Premium
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Health Insurance Premium', 'annual') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Health Insurance Premium', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Health Insurance Premium', 'annual') / 12} prefix={individualLivingExpensesCalc('Health Insurance Premium', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Health Insurance Premium', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Health Insurance Premium', 'annual') < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Health Insurance Premium', 'annual') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Health Insurance Premium', 'annual')} prefix={individualLivingExpensesCalc('Health Insurance Premium', 'annual') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Health Insurance Premium', 'annual') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          <tr className={classNames(classes.otherExpenses, classes.cursorPointer)} aria-hidden="true" onClick={() => { setOtherExpenses(!otherExpenses) }}>
            <td>
              <span>
                {!otherExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {otherExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Other Expenses</b>
            </td>
            <td>
              <span />
              <span className={otherAnnaulLivingExpenses() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {otherAnnaulLivingExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                      value={otherAnnaulLivingExpenses() / 12}
                      prefix={otherAnnaulLivingExpenses() >= 0 ? '$' : '($'}
                      suffix={otherAnnaulLivingExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span className={otherAnnaulLivingExpenses() < 0 ? classes.dangerText : ''}>
                <b>
                  {otherAnnaulLivingExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                      value={otherAnnaulLivingExpenses()}
                      prefix={otherAnnaulLivingExpenses() >= 0 ? '$' : '($'}
                      suffix={otherAnnaulLivingExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>

          </tr>
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Higher Education Expenses
              </td>
              <td>
                <span />
                <span className={higherEducationExpenses() / 12 < 0 ? classes.dangerText : ''}>
                  {higherEducationExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={higherEducationExpenses() / 12} prefix={higherEducationExpenses() >= 0 ? '$' : '($'} suffix={higherEducationExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={higherEducationExpenses() < 0 ? classes.dangerText : ''}>
                  {higherEducationExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={higherEducationExpenses()} prefix={higherEducationExpenses() >= 0 ? '$' : '($'} suffix={higherEducationExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Children Expenses
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Children') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Children') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Children') / 12} prefix={individualLivingExpensesCalc('Children') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Children') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Children') < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Children') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Children')} prefix={individualLivingExpensesCalc('Children') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Children') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Pet Expenses
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Pet') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Pet') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Pet') / 12} prefix={individualLivingExpensesCalc('Pet') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Pet') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingExpensesCalc('Pet') < 0 ? classes.dangerText : ''}>
                  {individualLivingExpensesCalc('Pet') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingExpensesCalc('Pet')} prefix={individualLivingExpensesCalc('Pet') >= 0 ? '$' : '($'} suffix={individualLivingExpensesCalc('Pet') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Car Expenses
              </td>
              <td>
                <span />
                <span className={individualLivingCalc('Total Car Expenses') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingCalc('Total Car Expenses') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Car Expenses') / 12} prefix={individualLivingCalc('Total Car Expenses') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Car Expenses') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span className={individualLivingCalc('Total Car Expenses') < 0 ? classes.dangerText : ''}>
                  {individualLivingCalc('Total Car Expenses') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Car Expenses')} prefix={individualLivingCalc('Total Car Expenses') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Car Expenses') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Real Estate Expenses
              </td>
              <td>
                <span className={individualLivingCalc('Total Real Estate Expenses') / 12 < 0 ? classes.dangerText : ''}>
                  {individualLivingCalc('Total Real Estate Expenses') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Real Estate Expenses') / 12} prefix={individualLivingCalc('Total Real Estate Expenses') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Real Estate Expenses') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span className={individualLivingCalc('Total Real Estate Expenses') < 0 ? classes.dangerText : ''}>
                  {individualLivingCalc('Total Real Estate Expenses') !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Real Estate Expenses')} prefix={individualLivingCalc('Total Real Estate Expenses') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Real Estate Expenses') < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>

            </tr>
          )}
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Loan Payments
            </td>
            <td>
              <span className={individualLivingCalc('Total Interest Expenses') / 12 < 0 ? classes.dangerText : ''}>
                {individualLivingCalc('Total Interest Expenses') !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Interest Expenses') / 12} prefix={individualLivingCalc('Total Interest Expenses') / 12 >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Interest Expenses') / 12 < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span className={individualLivingCalc('Total Interest Expenses') < 0 ? classes.dangerText : ''}>
                {individualLivingCalc('Total Interest Expenses') !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={individualLivingCalc('Total Interest Expenses')} prefix={individualLivingCalc('Total Interest Expenses') >= 0 ? '$' : '($'} suffix={individualLivingCalc('Total Interest Expenses') < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Total Annual Expenses</b>
            </td>
            <td>
              <span />
              <span className={totalAnnualExpenses() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {totalAnnualExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={totalAnnualExpenses() / 12} prefix={totalAnnualExpenses() / 12 >= 0 ? '$' : '($'} suffix={totalAnnualExpenses() / 12 < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span className={totalAnnualExpenses() < 0 ? classes.dangerText : ''}>
                <b>
                  {totalAnnualExpenses() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={totalAnnualExpenses()} prefix={totalAnnualExpenses() >= 0 ? '$' : '($'} suffix={totalAnnualExpenses() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Annual Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span className={annualNetIncome() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetIncome() / 12 !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetIncome() / 12} prefix={annualNetIncome() / 12 >= 0 ? '$' : '($'} suffix={annualNetIncome() / 12 < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span className={annualNetIncome() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetIncome() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetIncome()} prefix={annualNetIncome() >= 0 ? '$' : '($'} suffix={annualNetIncome() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              Retirement Contributions
            </td>
            <td>
              <span />
              <span className={annualRetirementContributions() / 12 < 0 ? classes.dangerText : ''}>
               
                  {annualRetirementContributions() / 12 !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualRetirementContributions() / 12} prefix={annualRetirementContributions() / 12 >= 0 ? '$' : '($'} suffix={annualRetirementContributions() / 12 < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
            <td>
              <span />
              <span className={annualRetirementContributions() < 0 ? classes.dangerText : ''}>
              
                  {annualRetirementContributions() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualRetirementContributions()} prefix={annualRetirementContributions() >= 0 ? '$' : '($'} suffix={annualRetirementContributions() < 0 && ')'} />
                  ) : ('$0')}
                
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
             HSA Contributions
            </td>
            <td>
              <span />
              <span className={HSAContributions() / 12 < 0 ? classes.dangerText : ''}>
               
                  {HSAContributions() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={HSAContributions() / 12} prefix={HSAContributions() / 12 >= 0 ? '$' : '($'} suffix={HSAContributions() / 12 < 0 && ')'} />
                  ) : ('$0')}
                
              </span>
            </td>
            <td>
              <span />
              <span className={HSAContributions() < 0 ? classes.dangerText : ''}>
              
                  {HSAContributions() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={HSAContributions()} prefix={HSAContributions() >= 0 ? '$' : '($'} suffix={HSAContributions() < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Net Cash Flow for Savings and Investments</b>
            </td>
            <td>
              <span />
              <span className={annualNetCashFlow() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetCashFlow() !== undefined && annualNetCashFlow() !== null && annualNetCashFlow() !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetCashFlow() / 12} prefix={annualNetCashFlow() >= 0 ? '$' : '($'} suffix={annualNetCashFlow() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span className={annualNetCashFlow() < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetCashFlow() !== undefined && annualNetCashFlow() !== null && annualNetCashFlow() !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetCashFlow()} prefix={annualNetCashFlow() >= 0 ? '$' : '($'} suffix={annualNetCashFlow() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i>% of After-Tax Income</i>
              { }
            </td>
            <td>
              <i>
               
                  <span />
                  <span className={percentageOfAfterTaxIncome() < 0 ? classes.dangerText : ''}>
                    {(percentageOfAfterTaxIncome() < 0 && percentageOfAfterTaxIncome() >= 0 && percentageOfAfterTaxIncome() !== '' && percentageOfAfterTaxIncome() !== null) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={percentageOfAfterTaxIncome()} prefix={percentageOfAfterTaxIncome() >= 0 ? '' : '('} suffix={percentageOfAfterTaxIncome() < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </span>
                
              </i>
            </td>
            <td>
              <i>
              
                  <span />
                  <span className={percentageOfAfterTaxIncome() < 0 ? classes.dangerText : ''}>
                    {(percentageOfAfterTaxIncome() !== 0 && percentageOfAfterTaxIncome() < 0 && percentageOfAfterTaxIncome() >= 0 && percentageOfAfterTaxIncome() !== '' && percentageOfAfterTaxIncome() !== null) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={percentageOfAfterTaxIncome()} prefix={percentageOfAfterTaxIncome() >= 0 ? '' : '('} suffix={percentageOfAfterTaxIncome() < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </span>
               
              </i>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Short-Term Investment Contributons
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Short-Term Investments') / 12 < 0 ? classes.dangerText : ''}>
               
                  {shortTermInvestmentContributon('Short-Term Investments') !== undefined && shortTermInvestmentContributon('Short-Term Investments') !== null && shortTermInvestmentContributon('Short-Term Investments') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Short-Term Investments') / 12} prefix={shortTermInvestmentContributon('Short-Term Investments') / 12 >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Short-Term Investments') / 12 < 0 && ')'} />
                  ) : ('$0')}
                
              </span>
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Short-Term Investments') < 0 ? classes.dangerText : ''}>
               
                  {shortTermInvestmentContributon('Short-Term Investments') !== undefined && shortTermInvestmentContributon('Short-Term Investments') !== null && shortTermInvestmentContributon('Short-Term Investments') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Short-Term Investments') / 12} prefix={shortTermInvestmentContributon('Short-Term Investments') >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Short-Term Investments') < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Long-Term Investment Contributons
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Long-Term Investments') / 12 < 0 ? classes.dangerText : ''}>
              
                  {shortTermInvestmentContributon('Long-Term Investments') !== undefined && shortTermInvestmentContributon('Long-Term Investments') !== null && shortTermInvestmentContributon('Long-Term Investments') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Long-Term Investments') / 12} prefix={shortTermInvestmentContributon('Long-Term Investments') / 12 >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Long-Term Investments') / 12 < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Long-Term Investments') < 0 ? classes.dangerText : ''}>
              
                  {shortTermInvestmentContributon('Long-Term Investments') !== undefined && shortTermInvestmentContributon('Long-Term Investments') !== null && shortTermInvestmentContributon('Long-Term Investments') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Long-Term Investments')} prefix={shortTermInvestmentContributon('Long-Term Investments') >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Long-Term Investments') < 0 && ')'} />
                  ) : ('$0')}
              
              </span>
            </td>

          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              529 Plan
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('529 Plan') / 12 < 0 ? classes.dangerText : ''}>
              
                  {shortTermInvestmentContributon('529 Plan') !== undefined && shortTermInvestmentContributon('529 Plan') !== null && shortTermInvestmentContributon('529 Plan') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('529 Plan') / 12} prefix={shortTermInvestmentContributon('529 Plan') / 12 >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('529 Plan') / 12 < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('529 Plan') < 0 ? classes.dangerText : ''}>
              
                  {shortTermInvestmentContributon('529 Plan') !== undefined && shortTermInvestmentContributon('529 Plan') !== null && shortTermInvestmentContributon('529 Plan') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('529 Plan')} prefix={shortTermInvestmentContributon('529 Plan') >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('529 Plan') < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>

          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Life Insurance Premium
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Life Insurance') / 12 < 0 ? classes.dangerText : ''}>
                
                  {shortTermInvestmentContributon('Life Insurance') !== undefined && shortTermInvestmentContributon('Life Insurance') !== null && shortTermInvestmentContributon('Life Insurance') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Life Insurance') / 12} prefix={shortTermInvestmentContributon('Life Insurance') / 12 >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Life Insurance') / 12 < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>
            <td>
              <span />
              <span className={shortTermInvestmentContributon('Life Insurance') < 0 ? classes.dangerText : ''}>
              
                  {shortTermInvestmentContributon('Life Insurance') !== undefined && shortTermInvestmentContributon('Life Insurance') !== null && shortTermInvestmentContributon('Life Insurance') !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={shortTermInvestmentContributon('Life Insurance')} prefix={shortTermInvestmentContributon('Life Insurance') >= 0 ? '$' : '($'} suffix={shortTermInvestmentContributon('Life Insurance') < 0 && ')'} />
                  ) : ('$0')}
               
              </span>
            </td>

          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Net Cash Flow for Savings and Investments</b>
            </td>
            <td>
              <span />
              <span className={annualNetCashFlowAfterVacation() / 12 < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetCashFlowAfterVacation() !== undefined && annualNetCashFlowAfterVacation() !== null && annualNetCashFlowAfterVacation() !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetCashFlowAfterVacation() / 12} prefix={annualNetCashFlowAfterVacation() >= 0 ? '$' : '($'} suffix={annualNetCashFlowAfterVacation() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span className={annualNetCashFlowAfterVacation() < 0 ? classes.dangerText : ''}>
                <b>
                  {annualNetCashFlowAfterVacation() !== undefined && annualNetCashFlowAfterVacation() !== null && annualNetCashFlowAfterVacation() !== '' ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={annualNetCashFlowAfterVacation()} prefix={annualNetCashFlowAfterVacation() >= 0 ? '$' : '($'} suffix={annualNetCashFlowAfterVacation() < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i>% of After-Tax Income</i>
            </td>
            <td>
              <i>
               
                  <span />
                  <span className={percentageOfIncome() / 12 < 0 ? classes.dangerText : ''}>
                    {(percentageOfIncome() !== 0 && percentageOfIncome() !== 0) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={percentageOfIncome() / 12} prefix={percentageOfIncome() >= 0 ? '' : '('} suffix={percentageOfIncome() < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </span>
              
              </i>
            </td>
            <td>
              <i>
                
                  <span />
                  <span className={percentageOfIncome() / 12 < 0 ? classes.dangerText : ''}>
                    {(percentageOfIncome() !== 0 && percentageOfIncome() !== 0) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={percentageOfIncome()} prefix={percentageOfIncome() >= 0 ? '' : '('} suffix={percentageOfIncome() < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </span>
               
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

InvestmentBottomTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  // startYear: PropTypes.func.isRequired,
  activeSlide: PropTypes.func.isRequired,
};
export default withStyles(styles)(InvestmentBottomTwo);
