import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import ReactParser from 'html-react-parser';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import Pageloader from '../../../components/ui/pageloader';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import Sidebar from './sidebar';
import DefinationPopup from '../../../components/ui/pwi-sidebar/defination-popup';
import StartYear from '../../../components/pwi/financial-independence/start-year';
import IncomeGrowth from '../../../components/pwi/financial-independence/income-growth';
import Inflation from '../../../components/pwi/financial-independence/inflation';
// import CurrentCashBalance from '../../../components/pwi/financial-independence/current-cash-balance';
// import AutomatingInvestments from '../../../components/pwi/financial-independence/automating-investments';
import SummerySave from '../../../components/pwi/financial-independence/summery-save';
import styles from './styles';
import PwiLeftMenu from '../../../components/pwi/pwi-left-menu';
import LeaveDialogPopup from '../../../components/pwi/pwi-left-menu/leave-dialog-popup';
import PwiSave from '../../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../../components/common/save-popup-success';
import headerFI from '../../../assets/img/pwi-fi/fi.png';
import LogoImage from '../../../assets/img/logo.png';
import Walk from '../../../assets/img/pwi-fi/walk.png';
import save from '../../../assets/img/build-career-sketch/icon-save-blue.png';
import * as moduleServices from '../../../calculations/modules-services';
import { initializeExcelSheetsData, workSheetCalc } from '../../../calculations';
import WalkThruOne from '../wallk-thru/walkThruOne';
import WalkThruTwo from '../wallk-thru/walkThruTwo';
import WalkThruThree from '../wallk-thru/walkThruThree';
import WalkThruFour from '../wallk-thru/walkThruFour';
import WalkThruFive from '../wallk-thru/walkThruFive';
import WalkThruSix from '../wallk-thru/walkThruSix';
import WalkThruSeven from '../wallk-thru/walkThruSeven';
import WalkThruEight from '../wallk-thru/walkThruEight';
import * as timeLineActions from '../../../calculations/time-line';
import * as pwiObject from '../../../utilities/pwiObjectFormat';
import messages from '../../../utilities/pwi-popup-messages';
import SimulatorPanel from '../../../components/documents/simulator-panel';
import SummaryDocs from '../../../components/documents/documents';

const SAVESCENARIO = loader('../../../graphql/schema/pwi/save.graphql');
const PWI_DEFINATION = loader('../../../graphql/schema/financial-independence/getPWiDefination.graphql');
const INCOME_GROWTH_GRAPH_DATA = loader('../../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql');
const MODULE_DATA = loader('../../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../../graphql/schema/fc/check-module-exists.graphql');

const quotes = ['<i>Planning is bringing the future into the present so you can do something about it now.</i><i> – Alan Lakein</i>',
  '<i>Our goals can only be reached through a vehicle of a plan, in which we must fervently believe, and upon which we must vigorously act. There is no other route to success.</i><i> - Pablo Picasso</i>',
  '<i>A goal without a plan is just a wish.</i><i> – Antoine de Saint-Exupery</i>',
  '<i>Good fortune is what happens when opportunity meets with planning.</i><i> - Thomas Edison</i>',
  '<i>By failing to prepare, you are preparing to fail.</i><i> - Benjamin Franklin</i>',
  '<i>Good planning without good working is nothing.</i><i> - Dwight D. Eisenhower</i>',
  '<i>Plan your work and work your plan.</i><i> - Napoleon Hill</i>',
  '<i>The backbone of success is hard work, determination, good planning, and perseverance.</i><i> - Mia Hamm</i>',
  "<i>If you don't know where you are going, you'll end up someplace else.</i><i> - Yogi Berra</i>",
  "<i>Someone's sitting in the shade today because someone planted a tree a long time ago.</i><i> – Warren Buffett</i>",
  '<i>All successful people men and women are big dreamers. They imagine what their future could be, ideal in every respect, and then they work every day toward their distant vision, that goal or purpose.</i><i> – Brian Tracy</i>'];
const titleText = [
  'Welcome!',
  'Start Year',
  'Income Growth',
  'Inflation',
  'Summary',
];

const FI = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [quotesText, setQuotes] = useState('');
  const [completedSlides, setCompletedSlides] = useState({ 0: true });
  const [inputData, setInputData] = useState({ start_year: new Date().getFullYear() });
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: true });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module1' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 0; c <= 6; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          const responseData = data;
          responseData.long_term_growth_in_percentage = parseFloat(responseData.long_term_growth_in_percentage).toFixed(2);
          responseData.inflation_in_percentage = parseFloat(responseData.inflation_in_percentage).toFixed(2);
          setInputData(responseData);
        } else {
          setInputData({ start_year: new Date().getFullYear() });
          setActiveScenarioData({});
        }
        setActiveStep(0);
        setLoading(false);
      });
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setInputData({ start_year: new Date().getFullYear() });
        }
        setActiveStep(0);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module1',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/financial-independence' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const { data: incomeGrowthGraphData } = useQuery(INCOME_GROWTH_GRAPH_DATA);

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });



  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  useEffect(() => {
    const data = Math.floor(Math.random() * quotes.length);
    setQuotes(quotes[data]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let progressBar = (activeStep + 1) * (100 / 5);
    progressBar = progressBar.toFixed(0);
    if (progressBar > 77) {
      progressBar = 100;
    }
    setProgress(progressBar);
  }, [activeStep]);

  const updateInputValue = (field, value) => {
    let inputDetails = { ...inputData };
    if (field === 'total_obj') {
      inputDetails = value;
    } else {
      inputDetails[field] = value;
    }
    moduleServices.setModule1Data(inputDetails);
    setInputData(inputDetails);
  };

  const handleSlide = (slide) => {
    const completedSlide = { ...completedSlides };
    completedSlide[slide] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
    setActiveStep(slide);
    setMenu(false);
    setMainMenu(false);
  };



  const handleFISave = (data) => {
    const objectParams = inputData;
    if (data === 'save') {
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module1';
      objectParams.moduleNumber = 'module1Data';
      objectParams.categeory = 'My Scenarios';
      objectParams.filesHeader = 'Scenarios';
      setmoduleObjectParams(objectParams);
    }
    setSaveKey(data);
    setPopupClose(true);
    setMenu(false);
    setMainMenu(false);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleFISave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };


  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 60,
        align: 'bottom',
        duration: 500,
        bottom: bodySection.scrollHeight,
        overflowY: 'hidden',
      });
    } else if (slide === 3) {
      setMenu(true);
    } else if (slide === 4) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };

  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module1";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module1', 'quick-save', activeScenarioData);
      finalObject['module1Data'] = pwiObject.getfilteredPWIFI(inputData);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSlide} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}

        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={headerFI} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Financial Independence</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" onClick={handleMenu} className={classes.menuIcon}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 0) && (
              <div className={classes.contentBlock}>
                <p>
                  {ReactParser(`${quotesText}`)}
                </p>
                <p className={classes.MarginTopZero}>
                  Welcome to
                  {' '}
                  <font>Plan with Inkwiry!</font>
                  {' '}
                  The revolutionary way to build your roadmap to the future.
                </p>
                <p>
                  The first thing we&apos;ll do is ask you a few questions to define basic settings on your 5,
                  10, 15 year timeline. Let&apos;s start building your plan right now.
                </p>
              </div>
            )}
            {(activeStep === 1)
              && (
                <StartYear inputData={inputData} updateData={updateInputValue} />
              )}
            {(activeStep === 2)
              && (
                <IncomeGrowth
                  handleDefinationPopup={handleDefinationPopup}
                  incomeGrowthGraphData={incomeGrowthGraphData}
                  inputData={inputData}
                  updateData={updateInputValue}
                />
              )}
            {(activeStep === 3)
              && (
                <Inflation
                  handleDefinationPopup={handleDefinationPopup}
                  incomeGrowthGraphData={incomeGrowthGraphData}
                  inputData={inputData}
                  updateData={updateInputValue}
                />
              )}
            {(activeStep === 4)
              && (
                <SummerySave
                  handleDefinationPopup={handleDefinationPopup}
                  inputData={inputData}
                  updateData={updateInputValue}
                />
              )}
          </div>
          <div className={classes.btnGroup}>
            <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="fi" />)}
            {activeStep === 0 ? '' : <Button className={classes.cancel} onClick={() => handleSlide(activeStep - 1)}>Back</Button>}
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walk" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
            )}
            {activeStep === 4 ? <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => handleFISave('save')}>Save</Button> : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => handleSlide(activeStep + 1)}>Next</Button>}
          </div>

          {(activeStep === 2) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Sources: &nbsp;
                  <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://www.frbatlanta.org/chcs/wage-growth-tracker.aspx">
                    Federal Reserve Bank of Atlanta,
                  </a>
                  {' '}
                  &nbsp;Current Population Survey, Bureau of Labor Statistics
                </i>
              </p>
            </div>
          )}

          {(activeStep === 3) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Source: &nbsp;
                  <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://www.bls.gov/cpi/">U.S. Bureau of Labor Statistics </a>
                </i>
              </p>
            </div>
          )}
        </div>
        {definationResult && definationResult.getPWiDefination
          && (
            <DefinationPopup
              definationPopup={definationPopup}
              handleDefinationPopupClose={handleDefinationPopupClose}
              getPWiDefination={definationResult.getPWiDefination}
            />
          )}
        <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                    </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={inputData} setLoadValue={setLoadValue} />
                </div>
              )}

            </DialogContent>
          </Dialog>
          <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />

        </div>
      </div>
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module1' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

FI.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(FI);
