import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link, scroller } from 'react-scroll';
import { loader } from 'graphql.macro';
import styles from './styles';
import { useMutation } from '@apollo/react-hooks';

import inkwiryHigherEducation from '../../assets/img/Inkwirys-Higher-Education-Toolkit-for-Parents.pdf';

const DOWNLOAD_TOOLKIT = loader('../../graphql/schema/why-inkwiry/download-toolkit.graphql');

const Parents = (props) => {
  const { classes } = props;

  const [resetTokens, setResetTokens] = React.useState({ access: '' });

  React.useEffect(() => {
    const path = window.location.href;
    const token = path.split('=');
    console.log(path,window.location);
    setResetTokens({ access: token[1] });
  }, []); 

  const [downloadToolkit] = useMutation(DOWNLOAD_TOOLKIT, {
    onCompleted({
      downloadToolkit: {
        status
      }
    }) {
      if (status) {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = inkwiryHigherEducation;
        hiddenElement.download = 'Inkwirys-Higher-Education-Toolkit-for-Parents.pdf';
        hiddenElement.click();
      }
    },
  })

  React.useEffect(() => {
    if (resetTokens.access !== '') {
      downloadToolkit({
        variables: {
          key:resetTokens.access
        },
      });
    }
  }, [resetTokens]);

  return (
    <Typography variant="body1" component="div">
      <div className={classes.parentsPage}>
        <div className={classes.bannerBlock}>
          <div className={classes.bannerCaptionNew}>
            <div className={classes.container}>
              <div className={classes.bannerIntro}>
                <Typography variant="h3" component="h3">Your toolkit is on the way!</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.bannerCaptionCnt}>
          <div className={classes.container}>
            <div className={classes.toolkitContent}>
              <Typography variant="h4" component="h4">
                <span>Congratulations! You've taken the first step toward</span>
                <span>developing a plan for your student’s higher education.</span>
              </Typography>
              <p className={classes.toolkitP1}>Your Higher Education Toolkit should be downloading automatically.</p>
              <p className={classes.toolkitP2}>If not, you can start the download manually:</p>
              <div className={classes.toolkitBtn}>
                <a href={inkwiryHigherEducation} download>DOWNLOAD HIGHER <span>EDUCATION TOOLKIT <i className="las la-greater-than"></i></span></a>
              </div>
              <div className={classes.toolkitFooter}>
                <p>Take the next step: </p>
                <p>Get started with a  <Link to="/join">free test drive in Plan With Inkwiry</Link></p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </Typography>
  );
};

Parents.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Parents);
