import TickMark from '../../assets/img/pwi-car/black-tick-icon.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #ef9300',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 20px',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '30px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
  },
  clearBtn: {
    margin: '0 15px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '@media (max-width: 399px)': {
      margin: '0 10px',
    },
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      '@media (max-width: 1199px)': { 
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        left: '-10px',
        top: '40%',
        marginTop: '-4px',
        borderRight: '10px solid #337AB7',
        borderTopColor: 'transparent !important',
      }
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  blueText: {
    color: '#0069aa',
  },
  downPayment: {
    listStyleType: 'none',
    paddingLeft: '0',
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '16px',
      paddingLeft: '30px',
      backgroundPosition: 'left top',
      lineHeight: '24px',
      marginBottom: '5px',
    },
  },
  sliderRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '30px',
  },
  carBlock: {
    width: '16%',
    paddingRight: '15px',
  },
  carInput: {
    display: 'flex',
    position: 'relative',
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '95px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      color: '#333',
      position: 'relative',
    },
  },
  dollarSymbol: {
    marginLeft: '2%',
    lineHeight: '25px',
    position: 'relative',
    left: '0',
    fontSize: '18px',
  },
  percentSymbol: {
    position: 'absolute',
    fontSize: '18px',
    right: '-18px',
    left: 'auto',
    [theme.breakpoints.down( 'xs' )]: {
      left: '105px',
      right: 'auto',
    }
  },
  sliderLeftTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '19px',
  },
  resetIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '-50px',
    padding: '7px',
    [theme.breakpoints.down( 'xs' )]: {
      left: '120px',
    },
    '& img': {
      marginTop: '-4px',
      maxWidth: '20px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      marginTop: '-4px',
      maxWidth: '20px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
  },
  cashTable: {
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      lineHeight: '1.42857143',
      color: '#333',
      marginTop: '30px',
      width: '100%',
      '& i': {
        color: '#333',
      },
      '& thead': {
        '& tr': {
          '& th': {
            background: '#dae3f3',
            borderBottom: '2px solid #fff',
            fontSize: '18px',
            padding: '7px 10px',
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
            textAlign: 'left',
            '@media (max-width: 767px)': {
              fontSize: '16px',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '19px',
            padding: '7px 10px',
            background: '#f2f2f2',
            borderBottom: '1px solid #fff',
            '@media (max-width: 767px)': {
              fontSize: '16px',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '& b': {
              fontFamily: 'MuseoSans-500',
              fontWeight: 'normal',
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& td': {
            background: '#dae3f3',
            fontSize: '19px',
            padding: '7px 10px',
            fontFamily: 'MuseoSans-300',
            fontWeight: 'normal',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '17px',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '& b': {
              fontFamily: 'MuseoSans-500',
              fontWeight: 'normal',
            },
          },
          '&:last-child': {
            borderBottom: '2px solid #0069aa',
          },
        },
      },
    },
  },
  boldText: {
    fontFamily: 'MuseoSans-500',
    '& td': {
      fontFamily: 'MuseoSans-500 !important',
    },
  },
  clickNone: {
    pointerEvents: 'none',
  },
  purchaseTable: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  firstTable: {
    width: '40%',
    marginRight: '30px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginRight: '0px',
    },
    '& tfoot': {
      '& tr': {
        '&:first-child': {
          borderBottom: '1px solid #fff',
        },
      },
    },
  },
  homeTable: {
    width: '57%',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      overflow: 'auto',
    },
    '& tfoot': {
      '& tr': {
        '&:first-child': {
          borderTop: '2px solid #0069aa',
          borderBottom: '1px solid #fff',
        },
      },
    },
  },
  carLeaseTable: {
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      marginTop: '30px',
      marginBottom: '25px',
      textAlign: 'center',
      '@media (max-width: 1199px)': {
        minWidth: '950px',
      },
      '& tr': {
        '& th': {
          background: '#4472c4',
          color: '#fff',
          fontSize: '16px',
          padding: '8px',
          lineHeight: '1.42857143',
          [theme.breakpoints.down( 'xs' )]: {
            padding: '5px',
            fontSize: '14px',
          },
          '@media (max-width: 350px)': {
            fontSize: '11px',
          },
          '&:first-child': {
            borderLeft: '0',
            borderRight: '0',
            background: '0 0',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            height: 'auto',
            padding: '8px',
            fontSize: '16px',
            lineHeight: '1.42857143',
            borderRight: '1px solid #fff',
            borderBottom: '1px solid #fff',
            width: '20%',
            background: '#f2f2f2',
            [theme.breakpoints.down( 'xs' )]: {
              padding: '5px',
              fontSize: '14px',
            },
            '@media (max-width: 400px)': {
              padding: '3px',
              fontSize: '13px',
            },
            '&:first-child': {
              background: '#cfd5ea',
            },
          },
          '&:first-child': {
            '& td': {
              background: '#cfd5ea',
              '& span': {
                borderBottom: '1px solid #333',
              },
            },
          },
        },
      },
    },
  },
  fourIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '57%',
      margin: '20px auto',
      [theme.breakpoints.down( 'sm' )]: {
        width: '70%',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '90%',
      },
      '& li': {
        width: 'calc(50% - 56px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        position: 'relative',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
          margin: '8px 0px',
          boxSizing: 'border-box',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '& span + i': {
          width: '30px',
          border: '2px solid green',
          padding: '5px',
          borderRadius: '50%',
          position: 'absolute',
          background: '#d5d5d5',
          color: 'green',
          fontSize: '30px',
          right: '0px',
          top: '0px',
        },
        '& figure': {
          margin: '0',
        },
      },
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      padding:'15px',
    },
    '& p': {
      margin: '0',
    },
  },
  purchaseSlide: {
    display: 'flex',
    flexwrap: 'wrap',
    alignItems: 'center',
    '& slideText': {
      maxWidth: '170px',
      minWidth: '170px',
      paddingRight: '20px',
      marginTop: '-6px',
      '& p': {
        fontSize: '20px',
      },
    },

  },
  slideValue: {
    paddingLeft: '70px',
    // [theme.breakpoints.down( 'xs' )]: {
    //   paddingLeft: '0px',
    //   paddingTop: '15px',
    // },
    '& span': {
      alignItems: 'center',
      marginTop: '0',
      fontSize: '18px',
      position: 'relative',
      textAlign: 'right',
      paddingTop: '0',
      paddingRight: '10px',
      display: 'inline-block',
    },
  },
  slideGraphNew: {
    paddingLeft: '0',
    width: '61%',
    textAlign: 'right',
    paddingRight: '0',
    marginRight: '53px',
    fontSize: '18px',
    '& span': {
      alignItems: 'center',
      marginTop: '0',
      fontSize: '18px',
      position: 'relative',
      textAlign: 'right',
      paddingTop: '0',
      paddingRight: '10px',
      display: 'inline-block',
    },
    '& p': {
      margin: '0',
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  priceSlidersScroll:{
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  priceSlidersScrollDiv:{
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    }
  },
  textRowNew:{
    '@media (max-width: 1199px)': {
      display: 'flex',
    }
  },
  priceSliders: {
    display: 'flex',
    flexwrap: 'wrap',
    alignItems: 'center',
    marginTop: '20px',
  },
  priceText: {
    maxWidth: '170px',
    minWidth: '170px',
    paddingRight: '20px',
    fontSize: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: 'auto',
      minWidth: 'auto',
    },
    '& p': {
      fontSize: '20px',
    },
  },
  priceValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '80px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
    },
    '& span': {
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontSize: '18px',
        fontWeight: 'normal',
      },
    },
  },
  slideGraph: {
    width: '100%',
    paddingRight: '30px',
    color: '#333',
    '& p': {
      textAlign: 'right',
      fontFamily: 'MuseoSans-300',
      fontSize: '18px',
    },
  },
  inputValue: {
    alignItems: 'center',
    marginTop: '0',
    fontSize: '18px',
    position: 'relative',
    textAlign: 'right',
    display: 'inline-block',
    color: '#333',
    float: 'right',
    width: '80px',
  },
  tradeRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tiText: {
    width: '60%',
    marginRight: '5%',
    textAlign: 'right',
    fontSize: '19px',
    '@media (max-width: 767px)': { 
      fontSize: '16px',
    },
    '& p': {
      marginBottom: '0px',
    },
    '& span': {
      borderBottom: '1px dashed #0069aa',
      cursor: 'pointer',
      fontSize: '19px',
      textAlign: 'right',
      display: 'inline-block',
      '@media (max-width: 767px)': { 
        fontSize: '16px',
      },
    },
  },
  tradeIn: {
    width: '58.33333333%',
    paddingTop: '20px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '76%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  tradeInChoose:{
    cursor: "pointer"
  },
  tiValue: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '35%',
    fontSize: '19px',
    '@media (max-width: 767px)': { 
      fontSize: '16px',
    },
    '& input': {
      width: '100px',
      border: '0',
      borderBottom: '1px solid #0069aa',
      background: '0 0',
      outline: '0',
      textAlign: 'right',
      fontSize: '19px',
    },
  },
  tiDollar: {
    marginRight: '5px',
    position: 'relative',
  },
  radioWrapper: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    marginLeft: '5%',
    width: '175px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  activeWrapper: {
    backgroundColor: '#d9d9d9',
    '& a': {
      fontWeight: '500',
    },
  },
  yesWrapper: {
    width: '50%',
    '& span': {
      color: '#333',
      textDecoration: 'none',
    },
  },
  noWrapper: {
    width: '50%',
    '& a': {
      color: '#333',
      textDecoration: 'none',
    },
  },
  borderDivider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  leftTable: {
    width: '45%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      marginTop: '0',
      marginBottom: '25px',
      textAlign: 'left',
      paddingRight: '10px',
      width: '100%',
      maxwidth: '100%',
      '& tr': {
        '& th': {
          background: '#dae3f3',
          fontSize: '17px',
          padding: '8px',
          lineHeight: '1.42857143',
          color: '#333',
          fontFamily: 'MuseoSans-300',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            height: 'auto',
            padding: '8px',
            fontSize: '17px',
            lineHeight: '1.42857143',
            borderBottom: '1px solid #fff',
            background: '#f2f2f2',
            color: '#333',
            textAlign: 'left',
            '@media (max-width: 767px)': {
              fontSize: '16px',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:last-child': {
              textAlign: 'right',
            },
            '& i': {
              color: '#333',
            },
            '& span': {
              '& i': {
                cursor: 'pointer',
              },
            },
          },
          '&:last-child': {
            '& td': {
              borderBottom: '2px solid #0069aa',
            },
          },
        },
      },
    },
  },
  blueRow: {
    '& td': {
      background: '#dae3f3 !important',
      fontFamily: 'MuseoSans-300',
    },
  },
  bottomTables: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap:'wrap',
    },
  },
  rightTable: {
    paddingLeft: '10px',
    width: '55% !important',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100% !important',
      paddingLeft: '0px',
    },
    '& table': {
      '& tr': {
        '& th': {
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
        '& td': {
          textAlign: 'left',
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:last-child': {
            textAlign: 'right',
          },
          '& i': {
            color: '#333 !important',
          },
          '& span': {
            '& i': {
              cursor: 'pointer',
            },
          },
        },
      },
    },
  },
  carLoan: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '0px 30px',
    // [theme.breakpoints.down( 'xs' )]: {
    //   flexWrap: 'wrap',
    // },
    // '@media (min-width: 600px) and (max-width: 767px)': {
    //   overflow: 'auto',
    // }
  },
  textInput: {
    height: '60px',
  },
  slideTitle: {
    '@media (max-width: 1199px)': {
      minWidth: '170px',
    },
    '& span': {
      fontSize: '20px',
      paddingRight: '0',
      marginTop: '-6px',
      minWidth: '150px',
      maxWidth: '150px',
      '@media (max-width: 1199px)': {
        fontSize: '16px',
      }
    },
  },
  leftBlock: {
    width: '75%',
    // [theme.breakpoints.down( 'xs' )]: {
    //   width: '100%',
    // },
    // '@media (min-width: 600px) and (max-width: 767px)': {
    //   minWidth: '600px',
    // }
  },
  rightBlock: {
    width: '25%',
    display: 'inherit',
    padding: '0 15px',
    // [theme.breakpoints.down( 'xs' )]: {
    //   width: '100%',
    // }
  },
  slideText: {
    width: '90%',
    paddingRight: '20px',
    textAlign: 'right',
    marginRight: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% 40px)',
    },
    '& p': {
      textAlign: 'right',
      fontSize: '18px',
      width: '100%',
    },
    '& span': {
      minHeight: '4px',
      // color: '#0069aa',
    },
  },
  b: {
    fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
  },
  slideAmount: {
    width: '10%',
    textAlign: 'right',
    '& span': {
      width: '92px',
      paddingLeft: '16px',
      float: 'right',
      fontSize: '18px',
      display: 'inline-block',
    },
  },
  flexDisplay: {
    display: 'flex',
    width: '100%',
  },
  slideInput: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    '& input': {
      width: '80px',
      border: '0',
      borderBottom: '1px solid #0069aa',
      background: '0 0',
      outline: '0',
      textAlign: 'right',
      fontSize: '18px',
    },
  },
  slideRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'end',
  },
  utilitiesChart: {
    display: 'table',
    tableLayout: 'fixed',
    width: 'calc(100% - 20px)',
    height: '295px',
    margin: '54px auto 0',
    verticalAlign: 'bottom',
    paddingLeft: '20px',
    // [theme.breakpoints.down( 'xs' )]: {
    //   height: 'auto',
    //   padding: '0px',
    // }
  },
  chartBar: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'bottom',
    height: 'inherit',
    borderBottom: '1px solid #ccc',
    width: '50%',
  },
  chartSpan: {
    color: '#a6a6a6',
    fontWeight: '500',
    fontSize: '16px',
    marginLeft: '5px',
  },
  chartText: {
    position: 'relative',
    top: '0',
    textAlign: 'center',
    fontSize: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    lineHeight: '1.4',
  },
  percentageBar: {
    height: '100%',
    background: '#a6a6a6',
    margin: '0 1em',
    display: 'block',
    animation: 'draw 1s ease-in-out',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      padding: '5px 1em 0',
      display: 'block',
      textAlign: 'center',
      content: 'attr(title)',
    },
  },

  graphPercentage: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    fontWeight: '700',
    paddingTop: '3px',
    display: 'flex',
    fontSize: '14px',
  },
  summaryTables: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  summaryLeftTable: {
    width: '45%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      marginTop: '30px',
      marginBottom: '25px',
      textAlign: 'left',
      paddingRight: '10px',
      width: '100%',
      maxwidth: '100%',
      '& tr': {
        '& th': {
          background: '#dae3f3',
          fontSize: '17px',
          padding: '8px',
          lineHeight: '1.42857143',
          color: '#333',
          fontFamily: 'MuseoSans-300',
          '&:nth-child(2)': {
            textAlign: 'right',
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            height: 'auto',
            padding: '8px',
            fontSize: '17px',
            lineHeight: '1.42857143',
            borderBottom: '1px solid #fff',
            background: '#f2f2f2',
            color: '#333',
            '&:last-child': {
              textAlign: 'right',
            },
            '&:nth-child(2)': {
              textAlign: 'right',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
          },
          '&:last-child': {
            '& td': {
              borderBottom: '2px solid #0069aa',
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& td': {
            height: 'auto',
            padding: '8px',
            fontSize: '17px',
            lineHeight: '1.42857143',
            borderBottom: '1px solid #fff',
            background: '#f2f2f2',
            color: '#333',
            '&:last-child': {
              textAlign: 'right',
            },
            '&:nth-child(2)': {
              textAlign: 'right',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '& i': {
              color: '#333',
            },
          },
          '&:last-child': {
            '& td': {
              borderBottom: '2px solid #0069aa',
            },
          },
        },
      },
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '18px',
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      position: 'relative',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    '@media (max-width: 1400px)': {
      minWidth: '950px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      // [theme.breakpoints.down( 'xs' )]: {
      //   flexWrap: 'wrap',
      // },
      '& p': {
        margin: '0',
        minWidth: '150px',
        maxWidth: '150px',
        // [theme.breakpoints.down( 'xs' )]: {
        //   minWidth: '100%',
        //   maxWidth: '100%',
        // },
        // '@media (min-width: 600px) and (max-width: 767px)': {
        //   maxWidth: '120px',
        //   minWidth: '120px',
        // },
        '& span': {
          fontSize: '20px',
          display: 'initial',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },
    // '& > div > div':{
    //   '&:nth-child(3)':{
    //     [theme.breakpoints.down( 'xs' )]: {
    //       margin: '45px 0px 0px',
    //       width: '100%',
    //     }
    //   }
    // },
  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  AnnualIncomeGrowthMain: {
    marginBottom: '50px',
    '& > div': {
      height: '60px',
      '& p': {
        lineHeight: '27px',
      },
    },
  },
  incomeGrowth: {
    marginTop: '10px',
  },
  annuvalIncomeGrowthGraph: {
    width: '62%',
    margin: '10px 25px 0',
    // [theme.breakpoints.down( 'xs' )]: {
    //   margin: '0 15px',
    //   width: 'calc(100% - 30px)',
    // },
    // '@media (min-width: 600px) and (max-width: 767px)': {
    //   margin: '10px 5px 0',
    // },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      minHeight: '4px',
      // color: '#005da5',
    },
  },
  annuvalIncomeGraph: {
    margin: '0 25px 0 12px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      minHeight: '4px',
      // color: '#005da5',
    },
  },
  mainBlock: {
    width: '100%',
    overflow: 'hidden',
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 auto',
    display: 'flex',
    position: 'relative',
    // [theme.breakpoints.down( 'sm' )]: {
    //  marginRight: '0px',
    // },
    // '@media (max-width: 1199px)': {
    //   margin: '0 25px 0 40px',
    // },
    // '@media (min-width: 600px) and (max-width: 767px)': {
    //   margin: '0 10px 0 10px',
    // },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  active: {
    background: '#d9d9d9 !important',
    '& span + img': {
      // width: '30px',
      // border: '2px solid #70ad47',
      // padding: '5px',
      // borderRadius: '50%',
      // position: 'absolute',
      // right: '-15px',
      // top: '-15px',
      // background: '#fff',
    },
  },
  unactive: {
    background: '#f0f0f0 !important',
  },
  unactiveBox: {
    background: '#fffff',
  },
  livingExpencesRange: {
    width: '60%',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '19px',
    },
    '& p': {
      margin: '-19px 0 0',
    },
    '& img': {
      width: '100%',
    },
  },
  opacityZero: {
    opacity: '0',
  },
  livingExpencesMothly: {
    padding: '0 25px',
    width: '100px',
    position: 'relative',
    // [theme.breakpoints.down( 'xs' )]: {
    //   padding: '0px 5px',
    // },
    '&:last-child': {
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '4px',
        height: 'calc(100% + 80px)',
        background: '#046bab',
        left: '0',
        top: '0',
        [theme.breakpoints.down( 'xs' )]: {
          left: '10px',
          height: 'calc(100% + 95px)',
      },
    }
  },
    '& h3': {
      textAlign: 'center',
      fontFamily: 'MuseoSans-700',
    },
  },
  livingExpences: {
    display: 'flex',
    // [theme.breakpoints.down( 'xs' )]: {
    //   flexWrap: 'wrap',
    // },
    // '@media (max-width: 1199px)': { 
    //   overflow: 'auto',
    // }
  },
  textRight: {
    textAlign: 'right',
    fontFamily: 'MuseoSans-500',
  },
  livingExpencesRangePlain: {
    '& p': {
      marginTop: '0',
    },
  },
  rentGraphBlockNew: {
    marginBottom: '0',
    fontSize: '18px',
  },
  rentGraphBlockItalic: {
    marginBottom: '15px',
    fontSize: '18px',
    fontStyle: 'italic',
    '& p': {
      fontFamily: 'MuseoSans-300',
    },

  },
  rentGraphBlock: {
    display: 'flex',
    marginBottom: '30px',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'normal',
      fontSize: '18px',
      marginTop: '0',
    },
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },

  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  caretIcon: {
    verticalAlign: 'baseline',
    color: '#0069aa',
    margin: '0 5px 0 0',
    fontFamily: 'MuseoSans-300',
    transition: 'unset',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    borderTop: '7px dashed',
    marginTop: '3px',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
  },
  bottomText: {
    color: '#0069aa',
    fontSize: '16px',
    borderTop: '1px solid #ccc',
    paddingTop: '15px',
    paddingBottom: '25px',
    '& a': {
      borderBottom: '1px solid #0069aa',
      '&:hover': {
        color: '#f89b23',
        borderBottom: '1px solid #f89b23',
      },
    },
  },
  annualInput: {
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      fontSize: '18px',
      marginLeft: '5px',
    },
  },
  activeImageNew: {

  },
  btnGroupSpan: {
    margin: '0 0 0 auto',
  },
  anuuvalGroupGraphScroll:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  anuuvalGroupGraph: {
    marginTop: '50px',
    '@media (max-width: 1199px)': {
      minWidth: '800px',
    },
    '& > div': {
      '& > div': {
        marginTop: '0',
        marginBottom: '10px',
      },
    },
  },
  anuuvalGroupGraphNew: {
    marginBottom: '100px',
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    },
    '& > div': {
      '& > div': {
        marginTop: '0',
        marginBottom: '10px',
      },
    },
  },
  rebateSlidersScroll:{
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  rebateSliders: {
    marginBottom: '100px',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '& > div': {
      '& > div': {
        marginTop: '0',
        marginBottom: '20px',
      },
    },
  },
  groupAnnualBox: {
    marginBottom: '100px',
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  flexText: {
    alignItems: 'center',
  },
  leftPadding: {
    paddingLeft: '40px !important',
  },
  smallLeftpadding: {
    paddingLeft: '25px !important',
  },
  subListMenu: {
    width: '100%',
    listStyle: 'disc !important',
    listStylePosition: 'inside !important',
    paddingLeft: '28px !important',
    '& li': {
      display: 'list-item !important',
      paddingLeft: '0px !important',
      whiteSpace: 'nowrap',
    },
  },
  fourCarsBlock: {
    marginTop: '40px !important',
    '& li': {
      '& span + i': {
        right: '-19px !important',
        top: '-12px !important',
        background: 'transparent !important',
      },
    },
  },
  rebateTop: {
    marginTop: '30px',
  },
  tiInput: {
    '& input': {
      '&:disabled': {
        background: '#ebebe4',
        color: '#333',
      },
    },
  },
  hoveredRow: {
    '&:hover': {
      '& td': {
        backgroundColor: '#d5d5d5',
        cursor: 'pointer',
      },
    },
  },
  linedText: {
    borderBottom: '1px solid',
  },
  bottomSummaryTable: {
    '& leftTable': {
      '& thead': {
        '& tr': {
          '& th': {
            '&:first-child': {
              display: 'none',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            '&:first-child': {
              display: 'none',
            },
          },
        },
      },
    },
    '& rightTable': {
      '& thead': {
        '& tr': {
          '& th': {
            '&:first-child': {
              display: 'none',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            '&:first-child': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  benchMark: {
    '& p': {
      marginBottom: '10px',
    },
    '& ul': {
      marginTop: '0px',
    },
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  downPaymentNew: {
    listStyleType: 'none',
    paddingLeft: '0',
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '16px',
      paddingLeft: '30px',
      backgroundPosition: 'left 8px',
    },
  },
  leasePay: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '30px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '@media (max-width: 1199px)': {
      overflow: 'auto',
      padding: '0px 20px',
    }
  },
  leaseChart: {
    height: '150px',
  },
  rightText: {
    alignItems: 'center',
    '& span': {
      fontSize: '18px',
    },
    '& input': {
      textAlign: 'right',
    },
  },
  rightBoldText: {
    fontFamily: 'MuseoSans-500',
    textAlign: 'right',
    justifyContent: 'flex-end',
    '& span': {
      fontSize: '18px',
      fontFamily: 'MuseoSans-500',
    },
  },
  annualRightText: {
    justifyContent: 'flex-end',
    textAlign: 'right',
    fontSize: '18px',
  },
  annualEnd: {
    justifyContent: 'flex-end',
    textAlign: 'right !important',
  },
  mainRightPadding: {
    paddingRight: '30px',
    // [theme.breakpoints.down( 'xs' )]: {
    //   paddingRight: '0px',
    // },
    // '@media (max-width: 1199px)': {
    //   minWidth: '600px',
    // }
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '2px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  secondGraph: {
    margin: '0 1em',
    display: 'block',
    background: '#0069aa',
    animation: 'draw 1s ease-in-out',
  },
  secondPercentageBarColor: {
    background: '#0069aa !important',
  },
  secondPercentageTextColor: {
    color: '#0069aa !important',
  },
  annualIgraph: {
    // [theme.breakpoints.down( 'xs' )]: {
    //   width: '90%',
    // },
  },
  // annualSgraph: {
  //   [theme.breakpoints.down( 'xs' )]: {
  //     width: 'calc(100% - 30px)',
  //     marginRight: '0px',
  //   },
  // },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  
  blueTick:{
    width: '16px',
    height: '16px',
    verticalAlign: 'middle',
    paddingLeft: '10px',
  },
  retirementList: {
    listStyle: 'none',
    padding: '0px',
    maxWidth: '300px',
    margin: '0px 0px 35px',
    '& li': {
      cursor: 'pointer',
      // background: '#bdd597',
      // fontFamily: 'MuseoSans-500',
      position: 'relative',
      display: 'inline-block',
      width: 'calc(100% - 22px)',
      fontSize: '21px',
      padding: '4px 10px',
      border: '1px solid',
      margin: '5px auto',
      borderRadius: '6px',
      '& span':{
        display: 'inline-block',
        width: '38px',
        border: 'none',
        position: 'absolute',
        right: '1px',
        top: '0',
        background: '0 0',
        '& img':{
          width: '100%',
        }
      }
    }
  },
  // blueTick:{
  //   width: '16px',
  //   height: '16px',
  //   verticalAlign: 'middle',
  //   paddingLeft: '10px',
  // },
  vehicleCheck:{
    fontFamily: 'MuseoSans-500',
    background: '#bdd597',
  },
  vehicleUnCheck:{
    background: '#f2f2f2',
  },
  selectRetireAccount:{
    maxWidth: '270px',
    width: '100%', 
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '240px',
    }
  },
  selectRetireAccountSpan:{
    display: 'inline-block',
    width: 'calc(100% - 17px)',
    fontSize: '21px',
    padding: '4px 10px',
    border: '1px solid',
    margin: '5px auto',
    borderRadius: '6px',
    background: '#bdd597',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
    },
    '& span':{
      width: '32px',
      position: 'absolute',
      right: '1px',
      top: '1px',
      [theme.breakpoints.down( 'xs' )]: {
        width: '28px',
      },
      '& img':{
        width: '100%',
      }
    }
  },
  deselectRetireAccountSpan:{
    background: '#f2f2f2',
  },
  cursorDisable:{
    background: '#f2f2f2',
    cursor: 'no-drop',
  },
  tradeInTable:{
    '& thead':{
      '& tr':{
        '& th':{
          '& u':{
            fontSize: '18px',
          }
        }
      }
    },
    '& tbody':{
      '& tr':{
        '& td':{
          verticalAlign: 'middle',
          height: '38px',
          padding: '0 8px',
          lineHeight: '1.2',
          fontSize: '18px',
          '&:first-child':{
            width: '350px',
            [theme.breakpoints.down( 'xs' )]: {
              width: 'auto',
              minWidth: '300px',
            }
          },
          '&:last-child':{
            textAlign: 'right',
            fontSize: '18px',
            [theme.breakpoints.down( 'xs' )]: {
              minWidth: '130px',
            }
          }
        }
      }
    }
  },
  textRightBold:{
    '& td':{
      fontFamily: 'MuseoSans-500',
      '&:first-child':{
        textAlign: 'right',
      }
    }
  },
  textRightLight:{
    '& td':{
      '&:first-child':{
        textAlign: 'right',
      }
    }
  },
  tradeInTableScroll:{
    [theme.breakpoints.down( 'xs' )]: {
      overflowX: 'auto',
    }
  }
} );

export default styles;
