
function carsExistingVehicleDepreciation(module1Data, module12Data, module18Data, assumptionsData) {
	/********************** Cars ************************/
	let year = 0;
	let yearLimit = 0;
	let initialYear = 0;
	let carsExistingVehicleData = {};
		year = parseInt(module1Data.start_year);
		initialYear = year;
		yearLimit = parseInt(module1Data.start_year) + 30;

		let tradeInYear1 = 0;
		if (typeof module12Data.purchase_year !== 'undefined' && module12Data.purchase_year !== '' && typeof module12Data.tradein_existing_car !== 'undefined' && module12Data.tradein_existing_car === 'Yes') {
			tradeInYear1 = module12Data.purchase_year;
		} else {
			if (typeof module12Data.second_purchase_year !== 'undefined' && module12Data.second_purchase_year !== '' && typeof module12Data.moretradein_existing_car !== 'undefined' && module12Data.moretradein_existing_car === 'Yes') {
				tradeInYear1 = module12Data.second_purchase_year;
			} else {
				tradeInYear1 = 0;
			}
		}
		carsExistingVehicleData['Trade-In Year 1'] = tradeInYear1;

		//Trade-In Year 2
		let tradeInYear2 = 0;
		if (typeof module12Data.purchase_year !== 'undefined' && module12Data.purchase_year !== '' && typeof module12Data.tradein_existing_car_2 !== 'undefined' && module12Data.tradein_existing_car_2 === 'Yes') {
			tradeInYear2 = module12Data.purchase_year;
		} else {
			if (typeof module12Data.second_purchase_year !== 'undefined' && module12Data.second_purchase_year !== '' && typeof module12Data.moretradein_existing_car_2 !== 'undefined' && module12Data.moretradein_existing_car_2 === 'Yes') {
				tradeInYear2 = module12Data.second_purchase_year;
			} else {
				tradeInYear2 = 0;
			}
		}
		carsExistingVehicleData['Trade-In Year 2'] = tradeInYear2;

		//Trade-In Year 3
		let tradeInYear3 = 0;
		if (typeof module12Data.purchase_year !== 'undefined' && module12Data.purchase_year !== '' && typeof module12Data.tradein_existing_car_3 !== 'undefined' && module12Data.tradein_existing_car_3 === 'Yes') {
			tradeInYear3 = module12Data.purchase_year;
		} else {
			if (typeof module12Data.second_purchase_year !== 'undefined' && module12Data.second_purchase_year !== '' && typeof module12Data.moretradein_existing_car_3 !== 'undefined' && module12Data.moretradein_existing_car_3 === 'Yes') {
				tradeInYear3 = module12Data.second_purchase_year;
			} else {
				tradeInYear3 = 0;
			}
		}
		carsExistingVehicleData['Trade-In Year 3'] = tradeInYear3;

		//Trade-In Year 4
		let tradeInYear4 = 0;
		if (typeof module12Data.purchase_year !== 'undefined' && module12Data.purchase_year !== '' && typeof module12Data.tradein_existing_car_4 !== 'undefined' && module12Data.tradein_existing_car_4 === 'Yes') {
			tradeInYear4 = module12Data.purchase_year;
		} else {
			if (typeof module12Data.second_purchase_year !== 'undefined' && module12Data.second_purchase_year !== '' && typeof module12Data.moretradein_existing_car_4 !== 'undefined' && module12Data.moretradein_existing_car_4 === 'Yes') {
				tradeInYear4 = module12Data.second_purchase_year;
			} else {
				tradeInYear4 = 0;
			}
		}
		carsExistingVehicleData['Trade-In Year 4'] = tradeInYear4;

		//Lease end Year 1
		let leaseEndYear1 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining > 0) {
			leaseEndYear1 = initialYear + (module18Data.existing_car_loans_lease_months_remaining / 12);
		}
		carsExistingVehicleData['Lease end Year 1'] = leaseEndYear1;

		//Lease end Year 2
		let leaseEndYear2 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_2 > 0) {
			leaseEndYear2 = initialYear + (module18Data.existing_car_loans_lease_months_remaining_2 / 12);
		}
		carsExistingVehicleData['Lease end Year 2'] = leaseEndYear2;

		//Lease end Year 3
		let leaseEndYear3 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_3 > 0) {
			leaseEndYear3 = initialYear + (module18Data.existing_car_loans_lease_months_remaining_3 / 12);
		}
		carsExistingVehicleData['Lease end Year 3'] = leaseEndYear3;

		//Lease end Year 4
		let leaseEndYear4 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_4 > 0) {
			leaseEndYear4 = initialYear + (module18Data.existing_car_loans_lease_months_remaining_4 / 12);
		}
		carsExistingVehicleData['Lease end Year 4'] = leaseEndYear4;

		//Lease End Year (rounded) 1
		let leaseEndYearRound1 = 0;
		leaseEndYearRound1 = Math.floor(leaseEndYear1);
		carsExistingVehicleData['Lease end Year Rounded 1'] = leaseEndYearRound1;

		//Lease End Year (rounded) 2
		let leaseEndYearRound2 = 0;
		leaseEndYearRound2 = Math.floor(leaseEndYear2);
		carsExistingVehicleData['Lease end Year Rounded 2'] = leaseEndYearRound2;

		//Lease End Year (rounded) 3
		let leaseEndYearRound3 = 0;
		leaseEndYearRound3 = Math.floor(leaseEndYear3);
		carsExistingVehicleData['Lease end Year Rounded 3'] = leaseEndYearRound3;

		//Lease End Year (rounded) 4
		let leaseEndYearRound4 = 0;
		leaseEndYearRound4 = Math.floor(leaseEndYear4);
		carsExistingVehicleData['Lease end Year Rounded 4'] = leaseEndYearRound4;

		//Integer Test
		let integerTest1 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining) {
			integerTest1 = module18Data.existing_car_loans_lease_months_remaining / 12;
		}
		carsExistingVehicleData['Integer Test 1'] = integerTest1;

		//Integer Test 2
		let integerTest2 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_2) {
			integerTest2 = module18Data.existing_car_loans_lease_months_remaining_2 / 12;
		}
		carsExistingVehicleData['Integer Test 2'] = integerTest2;

		//Integer Test 3
		let integerTest3 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_3) {
			integerTest3 = module18Data.existing_car_loans_lease_months_remaining_3 / 12;
		}
		carsExistingVehicleData['Integer Test 3'] = integerTest3;

		//Integer Test 4
		let integerTest4 = 0;
		if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_4) {
			integerTest4 = module18Data.existing_car_loans_lease_months_remaining_4 / 12;
		}
		carsExistingVehicleData['Integer Test 4'] = integerTest4;

		let endingBalance1 = 0;
		let newMaintenance1 = 0;
		let newInsurance1 = 0;
		let newGas1 = 0;

		let endingBalance2 = 0;
		let newMaintenance2 = 0;
		let newInsurance2 = 0;
		let newGas2 = 0;

		let endingBalance3 = 0;
		let newMaintenance3 = 0;
		let newInsurance3 = 0;
		let newGas3 = 0;

		let endingBalance4 = 0;
		let newMaintenance4 = 0;
		let newInsurance4 = 0;
		let newGas4 = 0;

		let beginningValue1 = 0;
		let beginningValue2 = 0;
		let beginningValue3 = 0;
		let beginningValue4 = 0;
		let depreciationValue1 = 0;
		let depreciationValue2 = 0;
		let depreciationValue3 = 0;
		let depreciationValue4 = 0;
		// let endingBalance1 = 0; commented by chandrakala
		// let endingBalance2 = 0; commented by chandrakala
		// let endingBalance3 = 0; commented by chandrakala
		// let endingBalance4 = 0; commented by chandrakala
		let maintenance1 = 0;
		let maintenance2 = 0;
		let maintenance3 = 0;
		let maintenance4 = 0;
		let insurance1 = 0;
		let insurance2 = 0;
		let insurance3 = 0;
		let insurance4 = 0;
		let gas1 = 0;
		let gas2 = 0;
		let gas3 = 0;
		let gas4 = 0;
		let leasePayment1 = 0;
		let leasePayment2 = 0;
		let leasePayment3 = 0;
		let leasePayment4 = 0;

		//Beginning Value
		for (year; year < yearLimit; year += 1) {
			carsExistingVehicleData[year] = {};
			carsExistingVehicleData[year]['Existing Vehicle 1'] = {};
			carsExistingVehicleData[year]['Existing Vehicle 2'] = {};
			carsExistingVehicleData[year]['Existing Vehicle 3'] = {};
			carsExistingVehicleData[year]['Existing Vehicle 4'] = {};

			
			if (year === tradeInYear1) {
				beginningValue1 = 0;
			} else {
				if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value !== '') {
					beginningValue1 = module18Data.existing_car_current_value;
				} else {
					if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year < (module1Data.start_year + 10)) {
						beginningValue1 = endingBalance1;
					} else {
						beginningValue1 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Beginning Value'] = beginningValue1;


			if (year === tradeInYear2) {
				beginningValue2 = 0;
			} else {
				if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 !== '') {
					beginningValue2 = module18Data.existing_car_current_value_2;
				} else {
					if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year < (module1Data.start_year + 10)) {
						beginningValue2 = endingBalance2;
					} else {
						beginningValue2 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Beginning Value'] = beginningValue2;


			if (year === tradeInYear3) {
				beginningValue3 = 0;
			} else {
				if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 !== '') {
					beginningValue3 = module18Data.existing_car_current_value_3;
				} else {
					if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year < (module1Data.start_year + 10)) {
						beginningValue3 = endingBalance3;
					} else {
						beginningValue3 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Beginning Value'] = beginningValue3;

			if (year === tradeInYear4) {
				beginningValue4 = 0;
			} else {
				if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 !== '') {
					beginningValue4 = module18Data.existing_car_current_value_4;
				} else {
					if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year < (module1Data.start_year + 10)) {
						beginningValue4 = endingBalance4;
					} else {
						beginningValue4 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Beginning Value'] = beginningValue4;

			//Depreciation
			if (carsExistingVehicleData[year]['Existing Vehicle 1']['Beginning Value'] > 0) {
				depreciationValue1 = carsExistingVehicleData[module1Data.start_year]['Existing Vehicle 1']['Beginning Value'] / 10;
			} else {
				depreciationValue1 = 0;
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Depreciation'] = depreciationValue1;

			//Depreciation2

			if (carsExistingVehicleData[year]['Existing Vehicle 2']['Beginning Value'] > 0) {
				depreciationValue2 = carsExistingVehicleData[module1Data.start_year]['Existing Vehicle 2']['Beginning Value'] / 10;
			} else {
				depreciationValue2 = 0;
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Depreciation'] = depreciationValue2;

			//Depreciation3

			if (carsExistingVehicleData[year]['Existing Vehicle 3']['Beginning Value'] > 0) {
				depreciationValue3 = carsExistingVehicleData[module1Data.start_year]['Existing Vehicle 3']['Beginning Value'] / 10;
			} else {
				depreciationValue3 = 0;
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Depreciation'] = depreciationValue3;

			//Depreciation 4

			if (carsExistingVehicleData[year]['Existing Vehicle 4']['Beginning Value'] > 0) {
				depreciationValue4 = carsExistingVehicleData[module1Data.start_year]['Existing Vehicle 4']['Beginning Value'] / 10;
			} else {
				depreciationValue4 = 0;
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Depreciation'] = depreciationValue4;

			//Ending Balance
			endingBalance1 = beginningValue1 - depreciationValue1;
			carsExistingVehicleData[year]['Existing Vehicle 1']['Ending Balance'] = endingBalance1;

			//Ending Balance2
			endingBalance2 = beginningValue2 - depreciationValue2;
			carsExistingVehicleData[year]['Existing Vehicle 2']['Ending Balance'] = endingBalance2;

			//Ending Balance 3
			endingBalance3 = beginningValue3 - depreciationValue3;
			carsExistingVehicleData[year]['Existing Vehicle 3']['Ending Balance'] = endingBalance3;

			//Ending Balance 4
			endingBalance4 = beginningValue4 - depreciationValue4;
			carsExistingVehicleData[year]['Existing Vehicle 4']['Ending Balance'] = endingBalance4;

			//Maintenance
			if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && year !== tradeInYear1 && typeof module18Data.existingCarLoansMaintenanceCalcs !== 'undefined') {
				maintenance1 = module18Data.existingCarLoansMaintenanceCalcs;
			} else {
				if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && year !== tradeInYear1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					maintenance1 = maintenance1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					maintenance1 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Maintenance'] = maintenance1;

			//Maintenance2
			if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && year !== tradeInYear2 && typeof module18Data.existingCarLoansMaintenanceCalcs2 !== 'undefined') {
				maintenance2 = module18Data.existingCarLoansMaintenanceCalcs2;
			} else {
				if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && year !== tradeInYear2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					maintenance2 = maintenance2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					maintenance2 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Maintenance'] = maintenance2;

			//Maintenance3
			if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && year !== tradeInYear3 && typeof module18Data.existingCarLoansMaintenanceCalcs3 !== 'undefined') {
				maintenance3 = module18Data.existingCarLoansMaintenanceCalcs3;
			} else {
				if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && year !== tradeInYear3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					maintenance3 = maintenance3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					maintenance3 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Maintenance'] = maintenance3;

			//Maintenance 4
			if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && year !== tradeInYear4 && typeof module18Data.existingCarLoansMaintenanceCalcs4 !== 'undefined') {
				maintenance4 = module18Data.existingCarLoansMaintenanceCalcs4;
			} else {
				if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && year !== tradeInYear4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					maintenance4 = maintenance4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					maintenance4 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Maintenance'] = maintenance4;

			//Insurance
			if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear1 && typeof module18Data.existingCarLoansInsuranceCalcs !== 'undefined') {
				insurance1 = module18Data.existingCarLoansInsuranceCalcs;
			} else {
				if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && year !== tradeInYear1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					insurance1 = insurance1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					insurance1 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Insurance'] = insurance1;

			//Insurance 2
			if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear2 && typeof module18Data.existingCarLoansInsuranceCalcs2 !== 'undefined') {
				insurance2 = module18Data.existingCarLoansInsuranceCalcs2;
			} else {
				if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && year !== tradeInYear2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					insurance2 = insurance2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					insurance2 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Insurance'] = insurance2;

			//Insurance 3
			if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear3 && typeof module18Data.existingCarLoansInsuranceCalcs3 !== 'undefined') {
				insurance3 = module18Data.existingCarLoansInsuranceCalcs3;
			} else {
				if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && year !== tradeInYear3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					insurance3 = insurance3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					insurance3 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Insurance'] = insurance3;

			//Insurance 4
			if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear4 && typeof module18Data.existingCarLoansInsuranceCalcs4 !== 'undefined') {
				insurance4 = module18Data.existingCarLoansInsuranceCalcs4;
			} else {
				if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && year !== tradeInYear4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					insurance4 = insurance4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					insurance4 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Insurance'] = insurance4;

			//Gas
			if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear1 && typeof module18Data.existingCarLoansGasCalcs !== 'undefined') {
				gas1 = module18Data.existingCarLoansGasCalcs;
			} else {
				if (typeof module18Data.existing_car_current_value !== 'undefined' && module18Data.existing_car_current_value > 0 && year !== tradeInYear1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					gas1 = gas1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					gas1 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Gas'] = gas1;

			//Gas
			if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear2 && typeof module18Data.existingCarLoansGasCalcs2 !== 'undefined') {
				gas2 = module18Data.existingCarLoansGasCalcs2;
			} else {
				if (typeof module18Data.existing_car_current_value_2 !== 'undefined' && module18Data.existing_car_current_value_2 > 0 && year !== tradeInYear2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					gas2 = gas2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					gas2 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Gas'] = gas2;

			//Gas
			if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear3 && typeof module18Data.existingCarLoansGasCalcs3 !== 'undefined') {
				gas3 = module18Data.existingCarLoansGasCalcs3;
			} else {
				if (typeof module18Data.existing_car_current_value_3 !== 'undefined' && module18Data.existing_car_current_value_3 > 0 && year !== tradeInYear3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					gas3 = gas3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					gas3 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Gas'] = gas3;

			//Gas 4
			if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year ===module1Data.start_year && year !== tradeInYear4 && typeof module18Data.existingCarLoansGasCalcs4 !== 'undefined') {
				gas4 = module18Data.existingCarLoansGasCalcs4;
			} else {
				if (typeof module18Data.existing_car_current_value_4 !== 'undefined' && module18Data.existing_car_current_value_4 > 0 && year !== tradeInYear4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					gas4 = gas4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					gas4 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Gas'] = gas4;

			//Lease Payment
			if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining > 0 && year < leaseEndYear1 && typeof module18Data.existingCarLoansLoanLeasePaymentCalcs !== 'undefined') {
				leasePayment1 = module18Data.existingCarLoansLoanLeasePaymentCalcs;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining > 0 && year ===leaseEndYear1) {
					leasePayment1 = module18Data.existingCarLoansLoanLeasePaymentCalcs * (integerTest1 - parseInt(integerTest1));
				} else {
					leasePayment1 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['Lease Payment'] = leasePayment1;

			//Lease Payment 2
			if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_2 > 0 && year < leaseEndYearRound2 && typeof module18Data.existingCarLoansLoanLeasePaymentCalcs2 !== 'undefined') {
				leasePayment2 = module18Data.existingCarLoansLoanLeasePaymentCalcs2;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_2 > 0 && year ===leaseEndYearRound2) {
					leasePayment2 = module18Data.existingCarLoansLoanLeasePaymentCalcs2 * (integerTest2 - parseInt(integerTest2));
				} else {
					leasePayment2 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['Lease Payment'] = leasePayment2;

			//Lease Payment 3
			if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_3 > 0 && year < leaseEndYearRound3 && typeof module18Data.existingCarLoansLoanLeasePaymentCalcs3 !== 'undefined') {
				leasePayment3 = module18Data.existingCarLoansLoanLeasePaymentCalcs3;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_3 > 0 && year ===leaseEndYearRound3) {
					leasePayment3 = module18Data.existingCarLoansLoanLeasePaymentCalcs3 * (integerTest3 - parseInt(integerTest3));
				} else {
					leasePayment3 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['Lease Payment'] = leasePayment3;

			//Lease Payment 4
			if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_4 > 0 && year < leaseEndYearRound4 && typeof module18Data.existingCarLoansLoanLeasePaymentCalcs4 !== 'undefined') {
				leasePayment4 = module18Data.existingCarLoansLoanLeasePaymentCalcs4;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' && module18Data.existing_car_loans_lease_months_remaining_4 > 0 && year ===leaseEndYearRound4) {
					leasePayment4 = module18Data.existingCarLoansLoanLeasePaymentCalcs4 * (integerTest4 - parseInt(integerTest4));
				} else {
					leasePayment4 = 0;
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['Lease Payment'] = leasePayment4;
			
			//Maintenance
			if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanMaintenanceCalcs !== 'undefined') {
				newMaintenance1 = module18Data.existingCarLoansLoanMaintenanceCalcs;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining > 0 &&
					year < leaseEndYearRound1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newMaintenance1 = newMaintenance1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining > 0 &&
						year ===leaseEndYearRound1) {
						newMaintenance1 = newMaintenance1 * (integerTest1 - parseInt(integerTest1));
					} else {
						newMaintenance1 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['New Maintenance'] = newMaintenance1;

			//Maintenance 2
			if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanMaintenanceCalcs2 !== 'undefined') {
				newMaintenance2 = module18Data.existingCarLoansLoanMaintenanceCalcs2;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
					year < leaseEndYearRound2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newMaintenance2 = newMaintenance2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
						year ===leaseEndYearRound2) {
						newMaintenance2 = newMaintenance2 * (integerTest2 - parseInt(integerTest2));
					} else {
						newMaintenance2 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['New Maintenance'] = newMaintenance2;

			//Maintenance 3
			if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanMaintenanceCalcs3 !== 'undefined') {
				newMaintenance3 = module18Data.existingCarLoansLoanMaintenanceCalcs3;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
					year < leaseEndYearRound3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newMaintenance3 = newMaintenance3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
						year ===leaseEndYearRound3) {
						newMaintenance3 = newMaintenance3 * (integerTest3 - parseInt(integerTest3));
					} else {
						newMaintenance3 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['New Maintenance'] = newMaintenance3;

			//Maintenance 4
			if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanMaintenanceCalcs4 !== 'undefined') {
				newMaintenance4 = module18Data.existingCarLoansLoanMaintenanceCalcs4;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
					year < leaseEndYearRound4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newMaintenance4 = newMaintenance4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
						year ===leaseEndYearRound4) {
						newMaintenance4 = newMaintenance4 * (integerTest4 - parseInt(integerTest4));
					} else {
						newMaintenance4 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['New Maintenance'] = newMaintenance4;

			//Insurance
			if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanInsuranceCalcs !== 'undefined') {
				newInsurance1 = module18Data.existingCarLoansLoanInsuranceCalcs;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining > 0 &&
					year < leaseEndYearRound1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newInsurance1 = newInsurance1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining > 0 &&
						year ===leaseEndYearRound1) {
						newInsurance1 = newInsurance1 * (integerTest1 - parseInt(integerTest1));
					} else {
						newInsurance1 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['New Insurance'] = newInsurance1;

			//Insurance 2
			if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanInsuranceCalcs2 !== 'undefined') {
				newInsurance2 = module18Data.existingCarLoansLoanInsuranceCalcs2;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
					year < leaseEndYearRound2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newInsurance2 = newInsurance2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
						year ===leaseEndYearRound2) {
						newInsurance2 = newInsurance2 * (integerTest2 - parseInt(integerTest2));
					} else {
						newInsurance2 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['New Insurance'] = newInsurance2;

			//Insurance 3
			if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanInsuranceCalcs3 !== 'undefined') {
				newInsurance3 = module18Data.existingCarLoansLoanInsuranceCalcs3;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
					year < leaseEndYearRound3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newInsurance3 = newInsurance3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
						year ===leaseEndYearRound3) {
						newInsurance3 = newInsurance3 * (integerTest3 - parseInt(integerTest3));
					} else {
						newInsurance3 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['New Insurance'] = newInsurance3;

			//Insurance 4
			if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanInsuranceCalcs4 !== 'undefined') {
				newInsurance4 = module18Data.existingCarLoansLoanInsuranceCalcs4;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
					year < leaseEndYearRound4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newInsurance4 = newInsurance4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
						year ===leaseEndYearRound4) {
						newInsurance4 = newInsurance4 * (integerTest4 - parseInt(integerTest4));
					} else {
						newInsurance4 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['New Insurance'] = newInsurance4;

			//Gas
			if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanGasCalcs !== 'undefined') {
				newGas1 = module18Data.existingCarLoansLoanGasCalcs;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining > 0 &&
					year < leaseEndYearRound1 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newGas1 = newGas1 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining > 0 &&
						year ===leaseEndYearRound1) {
						newGas1 = newGas1 * (integerTest1 - parseInt(integerTest1));
					} else {
						newGas1 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 1']['New Gas'] = newGas1;

			//Gas 2
			if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanGasCalcs2 !== 'undefined') {
				newGas2 = module18Data.existingCarLoansLoanGasCalcs2;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
					year < leaseEndYearRound2 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newGas2 = newGas2 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_2 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_2 > 0 &&
						year ===leaseEndYearRound2) {
						newGas2 = newGas2 * (integerTest2 - parseInt(integerTest2));
					} else {
						newGas2 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 2']['New Gas'] = newGas2;

			//Gas 3
			if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanGasCalcs3 !== 'undefined') {
				newGas3 = module18Data.existingCarLoansLoanGasCalcs3;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
					year < leaseEndYearRound3 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newGas3 = newGas3 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_3 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_3 > 0 &&
						year ===leaseEndYearRound3) {
						newGas3 = newGas3 * (integerTest3 - parseInt(integerTest3));
					} else {
						newGas3 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 3']['New Gas'] = newGas3;

			//Gas 4
			if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
				module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
				typeof module1Data.start_year !== 'undefined' &&
				module1Data.start_year !== '' &&
				module1Data.start_year ===year &&
				typeof module18Data.existingCarLoansLoanGasCalcs4 !== 'undefined') {
				newGas4 = module18Data.existingCarLoansLoanGasCalcs4;
			} else {
				if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
					module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
					year < leaseEndYearRound4 && typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined') {
					newGas4 = newGas4 * ((assumptionsData[year].Expenses) / 100);
				} else {
					if (typeof module18Data.existing_car_loans_lease_months_remaining_4 !== 'undefined' &&
						module18Data.existing_car_loans_lease_months_remaining_4 > 0 &&
						year ===leaseEndYearRound4) {
						newGas4 = newGas4 * (integerTest4 - parseInt(integerTest4));
					} else {
						newGas4 = 0;
					}
				}
			}
			carsExistingVehicleData[year]['Existing Vehicle 4']['New Gas'] = newGas4;
		}
		return carsExistingVehicleData;
	// To return Depreciation Schedule Sheet Complete Data
	/* function carsExistingVehicleDepreciationData() {
		return carsExistingVehicleData;
	}
	carsExistingVehicleDepreciationData();*/
}

export default carsExistingVehicleDepreciation;