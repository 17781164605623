import React from 'react';
import PropTypes from 'prop-types';
import Component from './UI/component';
import data from './constants/rei'
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

const CompareREI = ( props ) => {
  const { classes, scenarioOne, scenarioTwo } = props;
  console.log(data);
  return (
    <div>
      {
        data.map(parent => (
          <Component parent={parent} scenarioTwo={ scenarioTwo}  scenarioOne={ scenarioOne } classes= {classes }/>
        ))
      }
    </div>
  );
};

CompareREI.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired,
};
export default withStyles( styles )( CompareREI );