import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const DebitCard = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Debit Card</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-card.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      debit card
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-card-left.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p
                          className={classNames(
                            classes.bigText,
                            classes.noTopMargin,
                          )}>
                          A payment card issued by a bank or other financial
                          institution that is linked to your checking account
                          and pulls money directly from your checking account to
                          purchase goods and services. Plus, debit cards are
                          most commonly used to withdraw cash from an ATM.
                        </p>
                        <p>
                          Most banks and other financial institutions give you a
                          free debit card when you open a checking account.
                          Then, you have to activate that card by creating a
                          4-digit security pin.
                        </p>
                        <p>
                          When you use your debit card to make a purchase, the
                          store places a hold on your account for the amount of
                          your purchase. Then, usually in a day or two, the
                          money from your checking account is sent to the
                          store&apos;s bank account and the transaction is
                          completed.
                        </p>
                        <p>
                          For example, you buy $100 worth of groceries at the
                          store. You review your checking account when you get
                          home via your bank&apos;s mobile app and you will see
                          (-$100) pending on your account. A day or two later,
                          that transaction will be completed, and your checking
                          account balance will have decreased by $100.
                        </p>
                        <p>
                          It&apos;s important to always know your checking
                          account balance when making purchases with a debit
                          card so that you don&apos;t spend more than you have.
                          If you spend more than you have, you will be charged
                          overdraft fees.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DebitCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DebitCard);
