import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Dialog from '@material-ui/core/Dialog';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import inkwForSchool from '../../assets/img/students-parents/inkw_for_school.png';
import percentageStudent from '../../assets/img/students-parents/percentage_student.jpg';
import percenteFormerStudents from '../../assets/img/students-parents/percente_former_students.jpg';
import studentSudentNew from '../../assets/img/students-parents/student_sudent_new2_1.jpg';
import percenteFormerStudentsTwo from '../../assets/img/students-parents/percente_former_students_1.jpg';
import schoolsInner from '../../assets/img/students-parents/schools_inner.jpg';
import mathTeacherSchool from '../../assets/img/students-parents/math_teacher_school.jpg';
import buildYourCareerSketch from '../../assets/img/students-parents/build-your-career-sketch.jpg';
import inkwirySchoolTimeline from '../../assets/img/students-parents/inkwiry_school_timeline.png';
import dashboardSchool from '../../assets/img/students-parents/dashboard_school.jpg';
import lftArrw from '../../assets/img/students-parents/lft_arrw.png';
import financialSchool from '../../assets/img/students-parents/financial_school.jpg';

// import CareerImageSeven from '../../assets/img/home_page/see_and_build_trns_img_7.png';
// import CareerImageEight from '../../assets/img/home_page/see_and_build_trns_img_8.png';

const School = ( props ) => {
  const { classes, history } = props;

  const [age, setAge] = React.useState( '' );
  const [studentReadMore, setStudentReadMore] = useState( true );
  const [parentsReadMore, setParentsReadMore] = useState( true );
  const [sectionclass1, setSectionclass1] = useState( false );
  const [sectionclass2, setSectionclass2] = useState( false );
  const [sectionclass3, setSectionclass3] = useState( false );
  const [sectionclass4, setSectionclass4] = useState( false );
  const [sectionclass5, setSectionclass5] = useState( false );
  const [sectionclass6, setSectionclass6] = useState( false );
  const [sectionclass7, setSectionclass7] = useState( false );
  const [sectionclass8, setSectionclass8] = useState( false );
  const [sectionclass9, setSectionclass9] = useState( false );
  const [bottomReadMore, setBottomReadMore] = useState( false );
  const [studentPercentagePopup, setStudentPercentagePopup] = useState( false );
  const [studentPercentageGraph, setStudentpercentageGraphPopup] = useState( false );
  const [percentageFormate, setPercentageFormatePopup] = useState( false );
  const [leftMenuClass, setLeftmenuClass] = useState( false );
  const [schoolLeftMenu, setSchoolLeftMenu] = useState( false );
  const [dropdown, setDropDown] = useState(false);

  const schoolsRef = useRef( null );

 

  const joinInk = bottomReadMore ? `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen} ${classes.studentBottomFixed}` : `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen}`;
  const ReadMoreCondition = parentsReadMore ? joinInk : classes.studentsPageReadmoreOpen;

  const getschoolLeftMenu = schoolLeftMenu ? `${classes.readmoreLftCnt} ${classes.leftmenuClass}` : `${classes.readmoreLftCnt}`;
  
  const handleChange = ( event ) => {
    setAge( event.target.value );
    var element = document.getElementById(event.target.value);
    var elemRect = element.offsetTop + 150;
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 700,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: elemRect,
      } );
    }, 300 );
  };

  useScrollPosition( ( { currPos } ) => {
    if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top > 54  ) {
      setParentsReadMore( false );
      setSectionclass1( false );
      setSectionclass2( false );
      setSectionclass3( false );
      setSectionclass4( false );
      setSectionclass5( false );
      setSectionclass6( false );
      setSectionclass7( false );
      setSectionclass8( false );
      setSectionclass9( false );
    }
    if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < 54 && schoolsRef.current.getBoundingClientRect( ).top > -16906  ) {
      setParentsReadMore( true );
    }
    if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < 54 && schoolsRef.current.getBoundingClientRect( ).top > -2186 && age === 10 ) {
      setSectionclass2( false );
      setSectionclass1( true );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -2376 && schoolsRef.current.getBoundingClientRect( ).top > -4126 && age === 20 ) {
      setSectionclass1( false );
      setSectionclass2( true );
      setLeftmenuClass( false );
      setSchoolLeftMenu( false );
      setSectionclass3( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -4251 && schoolsRef.current.getBoundingClientRect( ).top > -5271 && age === 30) {
      setSectionclass2( false );
      setSectionclass3( true );
      setLeftmenuClass( false );
      setSchoolLeftMenu( false );
      setSectionclass4( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -5371 && schoolsRef.current.getBoundingClientRect( ).top > -6064 ) {
      setSectionclass3( false );
      setSectionclass4( true );
      setLeftmenuClass( false );
      setSchoolLeftMenu( false );
      setSectionclass5( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -6189 && schoolsRef.current.getBoundingClientRect( ).top > -8764 ) {
      setSectionclass4( false );
      setSectionclass5( true );
      setLeftmenuClass( false );
      setSectionclass6( false );
      setSchoolLeftMenu( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -8984 && schoolsRef.current.getBoundingClientRect( ).top > -10773 ) {
      setSectionclass5( false );
      setSectionclass6( true );
      if ( schoolsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setLeftmenuClass( true );
        setSchoolLeftMenu( false );
      } else {
        setSchoolLeftMenu( true );
        setLeftmenuClass( false );
      }
      setSectionclass7( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -10973 && schoolsRef.current.getBoundingClientRect( ).top > -14297 ) {
      setSectionclass6( false );
      setSectionclass7( true );
      if ( schoolsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setSchoolLeftMenu( false );
        setLeftmenuClass( true );
      } else {
        setSchoolLeftMenu( true );
        setLeftmenuClass( false );
      }
      setSectionclass8( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -14497 && schoolsRef.current.getBoundingClientRect( ).top > -15608 ) {
      setSectionclass7( false );
      setSectionclass8( true );
      if ( schoolsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setSchoolLeftMenu( false );
        setLeftmenuClass( true );
      } else {
        setSchoolLeftMenu( true );
        setLeftmenuClass( false );
      }
      setSectionclass9( false );
    } else if ( schoolsRef.current.getBoundingClientRect( ).top !== 0 && schoolsRef.current.getBoundingClientRect( ).top < -15808 && schoolsRef.current.getBoundingClientRect( ).top > -17036 ) {
      setSectionclass1( false );
      setSectionclass2( false );
      setSectionclass3( false );
      setSectionclass4( false );
      setSectionclass5( false );
      setSectionclass6( false );
      setSectionclass7( false );
      setSectionclass8( false );
      setSectionclass9( true );
      if ( schoolsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setLeftmenuClass( true );
        setSchoolLeftMenu( false );
      } else {
        setSchoolLeftMenu( true );
        setLeftmenuClass( false );
      }
    }

    if ( currPos.y > -1435 ) {
      setDropDown( false );
    }
    if ( currPos.y < -1434 ) {
      setDropDown( true );
    }
    if ( currPos.y > -1500 ) {
      setParentsReadMore( true );
    }
    if ( currPos.y < -18000 && currPos.y > -19000 ) {
      setBottomReadMore( true );
    } else {
      setBottomReadMore( false );
    }
  } );

  

  const scrollToReadMore = () => {
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -57,
      } );
    }, 700 );
    setParentsReadMore( true );
  };

  const handleReadMore = () => {
    setStudentReadMore( false );
    scrollToReadMore();
  };

  const handleStudentPercentagePopup = () => {
    setStudentPercentagePopup( true );
  };

  const handleStudentPercentagePopupClose = () => {
    setStudentPercentagePopup( false );
  };

  const handlePercentageFormatePopup = () => {
    setPercentageFormatePopup( true );
  };

  const handlePercentageFormatePopupClose = () => {
    setPercentageFormatePopup( false );
  };

  const handleStudentpercentageGraphPopup = () => {
    setStudentpercentageGraphPopup( true );
  };

  const handleStudentpercentageGraphPopupClose = () => {
    setStudentpercentageGraphPopup( false );
  };

  const handleMail = () => {
    window.open( 'mailto:jgdeluca@inkwiry.com' );
  };

  const redirectToHHigherEducation = () => {
    history.push( '/plan-with-inkwiry/he' );
  };

  const redirectToPricing = () => {
    history.push( '/inkwiry-benefits' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.studentPage }>
        <div className={ classes.parentstBanner }>
          <img src={ inkwForSchool } alt="School Banner" />
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ `${classes.bannerCaptionTitle} ${classes.bannerBgtransparent}` }>
                <div className={ classes.bannerCaptionTitleFlex }>
                  <Typography variant="h3" component="h3">Inkwiry + Schools</Typography>
                  <p>Through financial literacy, we have the power to change lives. Right now.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.parentstContent }>
          <div className={ classes.container }>
            <div className={ `${classes.parentstContentRow} ${classes.titleNew}` }>
              <Typography variant="h3" component="h3">Now is the time to teach financial literacy</Typography>
              <Typography variant="h4" component="h4">
                and foster critical thinking so students make smart career choices,
                <span className={ classes.spanBlock }>graduate on time and understand how to budget and manage debt.</span>
              </Typography>
              <p>What if your students could map out a detailed career path and plan 5, 10, 15 years into the future?</p>
              <p>What if your students could experiment and validate their strategies with financial modeling, allowing them to run scenarios, avoid costly mistakes and confidently make smart moves to reach their individual goals?</p>
              <p>And what if, at the same time, your students develop critical thinking and vital skills for the new economy, while building confidence, independence and the ability to create win-win solutions to further their own success and resolve the greater issues of today and tomorrow?</p>
              <p>All of these outcomes are made possible by Inkwiry, revolutionary software designed to help students create a personal and financial plan with the ultimate goal of financial independence. Inkwiry teaches personal finance via hands-on planning, builds financial literacy and encourages lifelong learning.</p>
              <p>Test drive our Higher Education module and experience how planning with Inkwiry can help your students navigate higher education with confidence.</p>
              <div className={ classes.parantMoreInfo }>
                <Button onClick={ redirectToHHigherEducation } className={ classes.testDriveBtn }>
                  Take a Test Drive
                  <i className="las la-greater-than" />
                </Button>
                <Button className={ classes.moreInfoBtn } onClick={ handleReadMore }>
                  Read More
                  <i className="las la-greater-than" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={ !studentReadMore ? ReadMoreCondition : classes.studentsPageReadmore } name="scroll-to-element">
          <Grid container>
            <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
              <div className={ leftMenuClass ? `${classes.readmoreLftCnt} ${classes.schoolLeftmenuClass}` : getschoolLeftMenu }>
                <Typography variant="h4" component="h4">Inkwiry for Schools</Typography>
                <ul className={ classes.inkwiryListTab }>
                  <li>
                    <Link
                      to="section1"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass1 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>This time is different.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section2"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass2 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>U.S. students lack basic financial literacy.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section3"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass3 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Students yearn to learn more about personal finance.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section4"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass4 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Finance courses have lasting benefits.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section5"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass5 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Schools have an important role to play.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section6"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass6 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Inkwiry can form the foundation of your financial literacy program.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section7"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -60 }
                      duration={ 1000 }
                      className={ sectionclass7 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Career Sketching is the key to planning.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section8"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -60 }
                      duration={ 1000 }
                      className={ sectionclass8 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Financial planning: Easy to present in class and easy to administrate.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section9"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -90 }
                      duration={ 1000 }
                      className={ sectionclass9 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Let&apos;s make sure students graduate with financial planning skills.</p>
                    </Link>
                  </li>
                </ul>
                <div className={ dropdown === true ? `${classes.selectDiv} ${classes.selectDivFixed}`:`${classes.selectDiv}` } >
                  <FormControl className={ classes.formControl }>
                    <Select
                      value={ age }
                      onChange={ handleChange }
                      displayEmpty
                      className={ classes.selectEmpty }
                      inputProps={ { 'aria-label': 'Without label' } }
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section1" }>This time is different.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section2" }>U.S. students lack basic financial literacy.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section3" }>Students yearn to learn more about personal finance.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section4" }>Finance courses have lasting benefits.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section5" }>Schools have an important role to play.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section6" }>Inkwiry can form the foundation of your financial literacy program.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section7" }>Career Sketching is the key to planning.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section8" }>Financial planning: Easy to present in class and easy to administrate.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={ "section9" }>Let&apos;s make sure students graduate with financial planning skills.</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 9 }>
              <div className={ classes.readmoreRightCnt } ref={ schoolsRef }>
                <div id="section1">
                  <div className={ classes.readmoreRightCntSec }>
                    <div className={ classes.whereStartCnt }>
                      <Typography variant="h4" component="h4">This time is different.</Typography>
                      <p>In today&apos;s world, students confront challenges like never before. Technology and globalization are displacing jobs. Careers and the workplace are undergoing monumental change, which is accelerating. The new economy demands new skills, a new mindset and new ways of working and collaborating.</p>
                      <p>At the same time, making a mistake can be more costly than ever. Choosing to attend college is now the second largest financial decision students will make, and the consequences of starting out on the wrong path or taking on too much debt can impose a financial burden that can hinder them for most of their lives.</p>
                      <p>The National Center for Education Statistics reports only 40% of students at four-year schools graduate on time. And according to Complete College America, one extra year of college costs about $68,153, which includes additional tuition, fees, and living expenses, plus the foregone income from a full-time job.</p>
                      <p>When approximately 62% of adults have less than $1,000 in their savings accounts, that costly mistake usually turns into a mountain of debt with lifelong effects, including postponement of key life events: .</p>
                      <p>Now, more than ever, schools can and should play an important role in ensuring graduates are financially literate, think critically while modeling and testing their ideas in real-time, and can actually apply this knowledge in building their own plan.</p>
                    </div>
                  </div>
                  <div className={ classes.findSolutionsList }>
                    <ul>
                      <li>
                        Student loan debt is at
                        {' '}
                        <span>$1.64 trillion</span>
                      </li>
                      <li>
                        <span>40%</span>
                        {' '}
                        of borrowers could default by 2023
                      </li>
                      <li>
                        <span>50%</span>
                        {' '}
                        of college students pursue a career unrelated to their major
                      </li>
                      <li>
                        <span>60%</span>
                        {' '}
                        of students that started college in 1995-1996 are still repaying their student loans
                      </li>
                      <li>
                        <span>60%</span>
                        {' '}
                        pay the high price of not graduating on time
                      </li>
                      <li>
                        <span>52%</span>
                        {' '}
                        of college students graduate within six years
                      </li>
                      <li>
                        <span>80%</span>
                        {' '}
                        of graduates wish they’d learned strategies to get the best education at the most reasonable cost
                      </li>
                      <li>
                        <span>79%</span>
                        {' '}
                        of graduates wish they knew how to make a budget
                      </li>
                      <li>
                        <span>60%</span>
                        {' '}
                        of workplace managers believe graduates lack critical thinking skills.
                      </li>
                    </ul>
                  </div>
                  <div className={ classes.studentGraphSec }>
                    <Typography variant="h4" component="h4">Percent of student loan borrowers who delay key life events due to debt</Typography>
                    <figure className={ classes.figureImg } onClick={ handleStudentPercentagePopup } aria-hidden="true">
                      <img src={ percentageStudent } alt="Students Banner" />
                    </figure>
                    <p>Source: Life Delayed: The Impact of Student Debt on the Daily Lives of Young</p>
                    <p>Americans, American Student Association, 2015</p>
                  </div>
                  <div className={ classes.testimonialSec }>
                    <Typography variant="h5" component="h5">
                      <span />
                      <p>To be successful, most kids ... need to know how to open a bank account, how much they need to save each month to reach their goals and, if they borrow this amount of money, how much money they will need to earn to pay it back.&quot;</p>
                      <Typography variant="h6" component="h6">
                        - Nan Morrison, president and CEO,
                        <span>Council for Economic Education</span>
                      </Typography>
                    </Typography>
                  </div>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section2">
                  <div className={ classes.usStudentsLiteracy }>
                    <Typography variant="h4" component="h4">U.S. students lack basic financial literacy.</Typography>
                    <p>Once they are launched into the real world, graduates are at a distinct disadvantage due to the lack of financial literacy curriculum offered in schools.</p>
                    <ul>
                      <li>An international financial literacy test of 15-year-olds ranked the U.S. 7th out of 15 countries. The U.S. trailed China, Canada, Russia and Australia, and was just slightly better than Poland.</li>
                      <li>According to a Google Customer Survey of more than 5,000 adults, 62% of Americans have less than $1,000 in their savings accounts and approximately 21% don’t even have a savings account.</li>
                      <li>The number of financial decisions an individual must make is on the rise, and the variety and complexity of financial products continues to grow. Young people often do not understand debit and credit cards, credit scores and reports, mortgages, banking, investment and insurance products and services, rent-to-own and other financial products.</li>
                      <li>Most college students who borrow to finance their education often do so without fully understanding the long-term impact of student loans, or even the mechanics of a loan such as principal and interest. They attend college without understanding financial aid, loans, debt, credit, inflation and budgeting.</li>
                      <li>Many students also do not comprehend the relationship between their area of study and the income level that they can expect upon graduation, and how much student loan debt their future career path will be able to support. As a result, most students regret taking on student loan debt and 23% are not able to make their payments.</li>
                    </ul>
                    <figure aria-hidden="true" className={ `${classes.figureImg} ${classes.figureImgTwo}` } onClick={ handlePercentageFormatePopup }>
                      <img src={ percenteFormerStudents } alt="" />
                    </figure>
                    <p>
                      <span>Bottom line:</span>
&nbsp;Students are making decisions on higher education without even a basic understanding of the financial landscape, which is leading to debt-saddled young Americans struggling to make ends meet.
                    </p>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ studentSudentNew } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section3">
                  <div className={ classes.usStudentsLiteracy }>
                    <Typography variant="h4" component="h4">Students yearn to know more about personal finance.</Typography>
                    <p>Students not only lack financial planning, but want financial planning. Unfortunately, when they realize they need planning, it’s often too late.</p>
                    <p>When young Americans with student loans were asked about the importance of learning about financial planning during school, the responses were clear.</p>
                    <p>Schools can and should give young people the tools they need to build a dream for the future.</p>
                  </div>
                </div>
                <div className={ `${classes.studentGraphSec} ${classes.padtb0} ${classes.studentGraphicsecNew}` }>
                  <Typography variant="h4" component="h4">Percent of former students with student loans</Typography>
                  <figure aria-hidden="true" className={ classes.figureImg } onClick={ handleStudentpercentageGraphPopup }>
                    <img src={ percenteFormerStudentsTwo } alt="" />
                  </figure>
                  <p>Source: “Life Delayed: The Impact of Student Debt on the Daily Lives of Young Americans,” American Student Association, 2015 Edition.</p>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section4">
                  <div className={ `${classes.whereStartCnt} ${classes.FinanceCnt}` }>
                    <Typography variant="h4" component="h4">Finance courses have lasting benefits.</Typography>
                    <p>
                      The Financial Industry Regulatory Authority&apos;s (FINRA) Investor Education Foundation, which seeks to promote financial literacy, reports high school students who are required to take personal finance courses have
                      {' '}
                      <span className={ classes.textBold }>
                        better credit scores and lower debt delinquency rates as young adults.
                        {' '}
                      </span>
                      {' '}
                      Yet, a 2017 Next Gen Personal Finance study shows only 16.4% of high school students nationwide (or one in six) are required to take a personal finance course in order to graduate.
                    </p>
                    <p>According to J. Michael Collins, co-author of the FINRA report and professor of consumer finance for the University of Wisconsin-Madison Center for Financial Security, students develop better credit behaviors in early adulthood in states where personal finance is a formal course, when teachers are trained, and students are held accountable for meeting learning objectives.</p>
                    <p>FINRA’s Investor Education Foundation goes one step further in analyzing the issue. Dr. Gary Mottola, Research Director, says the problem is a combination of lack of financial education and, “They don’t want to budget more than a few months or a year ahead. That is why we see no improvement in retirement savings.”</p>
                    <p>Why can’t students look past a few months or a year? They don’t have the tools or the incentive to do so, and professional help is expensive. The cost of a one-time, static financial plan is more than $1,500.</p>
                    <p>The answer is Inkwiry, easy-to-use, intelligent, financial planning software for young people. Inkwiry helps students crystallize their vision, build their own incentives and design dynamic personal and financial plans for 5, 10, 15 years.</p>
                  </div>
                </div>
                <div className={ `${classes.readmoreRightCntSec} ${classes.readmoreRightCntSecNew}` } id="section5">
                  <div className={ `${classes.whereStartCnt} ${classes.schoolRoleCnt}` }>
                    <Typography variant="h4" component="h4">Schools have an important role to play.</Typography>
                    <p>The foundation for good lifelong habits are set during the high school years — and even earlier. High school is the place where students can best establish the basics of financial literacy and learn to design a plan for a successful future.</p>
                    <p>According to the 2017 National Report Card on State Efforts to Improve Financial Literacy in High Schools by Champlain College, there is much room for improvement. “Sadly, when ranked A to F on financial literacy education in high school, 53% of states (or 27 states) received grades C, D, or F. And, 30% had grades D or F,” the report says.</p>
                  </div>
                </div>
                <div className={ classes.studentGraphSecTwo }>
                  <figure className={ classes.figureImg }>
                    <img src={ schoolsInner } alt="" />
                  </figure>
                  <p>
                    Source: 2017 National Report Card on State Efforts to Improve Financial Literacy in
                    <span>High Schools, Champlain College&apos;s Center for Financial Literacy.</span>
                  </p>
                </div>
                <div className={ classes.readmoreRightCntSec }>
                  <div className={ classes.whereStartCnt }>
                    <p>If schools truly aim to prepare students for today&apos;s world, Inkwiry can be a vital component of the curriculum. The methodology of creating a financial plan in Inkwiry, called Career Sketching, fosters critical thinking, promotes financial and life planning awareness, and provides students with the tools they need to successfully navigate the future and find financial independence.</p>
                    <p>It’s proven that, in order to gain control of one&apos;s life events, planning is key. Successful people everywhere have one thing in common: a strategy and a plan that works for them. And just writing down your goals makes it 42% more likely you will achieve them. In fact, an HSBC study finds nearly 44% of those who have a financial plan in place save more money for retirement. And, over the long run, having a financial plan equates to 250% more in retirement savings.</p>
                    <p>A diploma must be a launching pad to the next stage of life. And for that to happen, students need a plan. For example, in order to from graduate public high school in Chicago, students now are required to provide evidence that they have a plan for the future. The new program, Learn.Plan.Succeed., expects students to produce either acceptance to college or a gap-year program, a trade apprenticeship, military enlistment or a job offer in order to graduate.</p>
                    <p>At Inkwiry, we believe everyone needs a strategy and a plan. With planning, we believe everyone can achieve financial independence, which we define as:</p>
                    <div className={ classes.incomeExpensiveBox }>
                      <p>
                        INCOME
                        {' '}
                        <i className="las la-greater-than" />
                        {' '}
                        EXPENSES
                      </p>
                      <p>
                        EXPENSES
                        {' '}
                        <i className="las la-equals" />
                        {' '}
                        WISHES
                      </p>
                    </div>
                    <p>Before now, it was difficult to assemble and organize the massive amount of information needed to create a 5, 10, 15 year plan. Inkwiry&apos;s revolutionary software has simplified the process, providing tools to make financial planning easy, fast, efficient and rewarding.</p>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ mathTeacherSchool } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section6">
                  <div className={ classes.usStudentsLiteracy }>
                    <Typography variant="h4" component="h4">Inkwiry can form the foundation of your financial literacy program.</Typography>
                    <p>Students begin by developing awareness of their world, then look inward and uncover individual goals and aspirations, research a chosen path, test the validity of their strategy with financial modeling, and find their own best solutions and road to financial independence.</p>
                    <p>We’ve rolled this process into an equation:</p>
                    <Typography variant="h5" component="h5">awareness + discovery + due diligence + strategies + financial modeling = solutions</Typography>
                    <p>A personal and financial plan needs all of these layers to be effective. Numbers alone provide a superficial plan, and academic learning about finance works best when combined with hands-on experimentation in a meaningful way.</p>
                    <p>To address these components, Inkwiry provides four integrated applications:</p>
                    <ul>
                      <li>
                        <span>Personal Finance 101:</span>
                        {' '}
                        in five hours or less, learn the language of finance along with concepts and strategies for a lifetime of smart money management, all in a gamified experience. Standards aligned with Jumpstart National Standards.
                      </li>
                      <li>
                        <span>My Career Sketch: </span>
                        {' '}
                        spend just four hours in My Career Sketch and learn a few smart moves that can help you add $100,000+ to your 15-year net worth. My Career Sketch will jumpstart your career sketch and your financial life plan.
                      </li>
                      <li>
                        <span>Plan With Inkwiry: </span>
                        {' '}
                        dynamic, personalized financial life planning for the next 5, 10, 15 years with a focus on higher education and career planning. In just 15 minutes, students and parents can build a plan for higher education discovering the potential return and cost of that investment. Plan With Inkwiry offers a step-by-step, guided interface that demystifies the planning process and allows for planning at the speed of thought.
                      </li>
                      <li>
                        <span>Finance Explained by Inkwiry:</span>
                        {' '}
                        a weekly series that demystifies the language of finance and helps you get smarter about your money. Every Thursday, Inkwiry explores a financial concept, phrase, term or computation in an easy-to-understand email and Instagram post.
                      </li>

                    </ul>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ buildYourCareerSketch } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section7">
                  <div className={ `${classes.usStudentsLiteracy} ${classes.sketchinImg}` }>
                    <Typography variant="h4" component="h4">Career Sketching is the key to planning.</Typography>
                    <p>Inkwiry’s approach comes to life in Career Sketches, where students explore examples of various careers and trace a number of young people through the next 15 years of life events as they reach their own version of financial independence. Students can study these different career paths, find one or more that interests them and build their own sketch.</p>
                    <p>In the sample sketches, students view the reasoning behind higher education decisions, follow a career path (and even changing career paths), learn ways to manage their expenses see how investing comes into play. Each sample career sketch opens into the Financial Configurator for detailed exploration.</p>
                    <p>
                      In building a Career Sketch, students are empowered to think ahead and navigate
                      potential life events such as:
                    </p>
                    <ul>
                      <li>Higher education</li>
                      <li>Career path</li>
                      <li>Buying/leasing a car</li>
                      <li>Investing and saving for retirement</li>
                      <li>Budgeting for vacation and special purchases</li>
                      <li>Buying a home, and</li>
                      <li>Buying a real estate investment property.</li>
                    </ul>
                    <p>And, by planning with Inkwiry’s Financial Configurator, students work through:</p>
                    <ul>
                      <li>Building a monthly and annual budget</li>
                      <li>Learning how state, local, and federal taxes are calculated</li>
                      <li>Learning to read personal financial statements: income statement, balance sheet, and cash flow statement</li>
                      <li>Learning financial ratios such as debt-to-income, savings rate, and more</li>
                      <li>Interacting with visualizations such as charts, graphs, and tables</li>
                      <li>Balancing sources and uses of funds for higher education</li>
                      <li>Calculating payback on higher education</li>
                      <li>Taking on debt such as mortgages, car loans, and/or student loans</li>
                      <li>Understanding real estate returns, and much more.</li>
                    </ul>
                    <p>Inkwiry is dynamic, financial planning at the speed of thought. Through an easy to use, intuitive user interface packed with supplementary learning resources and analytical tools, Inkwiry empowers its users to ask “what if?”, receive real-time feedback, and boost their financial literacy in the process.</p>
                    <figure className={ `${classes.figureImg} ${classes.figureImgTwo}` }>
                      <img src={ inkwirySchoolTimeline } alt="" />
                    </figure>
                    <p>
                      Students can use and modify sample sketches or simply drag and drop their own anticipated life events to a timeline, click through to the Financial Configurator and enter data. Then, Inkwiry runs the numbers, including tax estimations, to produce an infinite number of 5, 10, 15 year Career Sketches. Students can run any number of scenarios for comparison to find their own best solutions. And because the plans are dynamic,
                      students can return to Inkwiry at any time to update data and instantly view changes to their sketches.
                    </p>
                    <p>Collaboration is built right into the interface – students can share and compare their scenarios for feedback, group learning and interaction. Using a set of metrics, teachers can quickly review scenarios to validate students’ work.</p>
                  </div>
                </div>
                <div className={ classes.InkwiryImgCntTwo }>
                  <p>The Inkwiry Dashboard shows a user&apos;s active data, including key performance indicators, and compares career sketches side-by-side.</p>
                  <figure className={ classes.figureImg }>
                    <img src={ lftArrw } className={ classes.arrowTransform } alt="" />
                  </figure>
                  <figure className={ classes.figureImg }>
                    <img src={ dashboardSchool } className={ classes.dashboardImg } alt="" />
                  </figure>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section8">
                  <div className={ classes.usStudentsLiteracy }>
                    {/* <Typography variant="h4" component="h4">Financial literacy: Easy to present in class and easy to administrate</Typography> */}
                    <p>
                      Inkwiry’s online courses equip students with the financial skills and knowledge
                      needed for a lifetime of success. The combination of Personal Finance
                      101 and My Career Sketch delivers the ultimate personal finance experience.
                      Students learn the value and benefits of planning, enhance their critical
                      thinking skills, select and explore a career path, and build a plan for their
                      next moves in life. Focusing on higher education and career planning,
                      the course combines financial literacy with statistics, analytics, graphical analysis, quantitative comparisons and financial modeling.
                    </p>
                    <p>
                      Inkwiry’s fully prepared lessons, online interface and user-friendly
                      admin tool let educators spend more time teaching and less time administrating.
                      Setup and management is effortless. The customizable PowerUp Dashboard allows for
                      real-time tracking of student progress, provides email and in-app communication,
                      promotes student-to-teacher collaboration, and minimizes overhead and cost.
                      In addition, Inkwiry is fully committed to assisting teachers, guidance counselors
                      and students by providing on-site support.
                    </p>
                    <figure className={ classes.figureImgFull }>
                      <img src={ financialSchool } alt="" />
                    </figure>
                  </div>
                </div>
                <div className={ `${classes.readmoreRightCntSec} ${classes.padtb0}` } id="section9">
                  <div className={ `${classes.usStudentsLiteracy} ${classes.studentsGraduateCnt}` }>
                    <Typography variant="h4" component="h4">Let&apos;s make sure students graduate with financial planning skills.</Typography>
                    <p>In short, we know students are graduating without even basic financial literacy skills. This lack of skills is putting them at a critical disadvantage as they make uninformed decisions, and often take on a burden of debt that hinders them throughout life.</p>
                    <p>At the same time, we know finance courses have lasting benefits, helping students develop better credit behaviors and giving them the knowledge to successfully navigate financial decisions.</p>
                    <p>We all benefit when schools step in to teach financial literacy. Graduates make good financial decisions, they save more, they prosper and give back to the economy, and our communities are strengthened. In turn, financially healthy communities strengthen our schools.</p>
                    <p>Inkwiry can help your school offer a financial literacy program. Every young person with access to Career Sketching can leave secondary and post-secondary education with a 5, 10, 15 year personal and financial plan. And in building this strategy, validated by financial modeling, each student learns the basics of personal finance, budgeting and the importance of planning.</p>
                    <p>As we launch students to the next stage of life, let&apos;s make sure they have the necessary skills to navigate their futures. Building a personal and financial plan using Inkwiry can do just that.</p>
                    <p>So take the next step: explore our website to learn more about Inkwiry, our philosophy and our groundbreaking software.</p>
                    <p><Link to="/" onClick={ handleMail }>Let&apos;s discuss how Inkwiry can help your school better serve its students.</Link></p>
                  </div>
                </div>
                <div className={ `${classes.joinBtn} ${classes.joinBtnNew}` }>
                  <Button onClick={ redirectToPricing }>
                    FIND OUT MORE
                    <i className="las la-greater-than" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
      <Dialog
        onClose={ handleStudentPercentagePopupClose }
        aria-labelledby="simple-dialog-title"
        open={ studentPercentagePopup }
        className={ classes.PopupSchool }
      >
        <div className={ classes.popupHeader }>
          <div aria-hidden="true" onClick={ handleStudentPercentagePopupClose }><i className="la la-times" /></div>
        </div>
        <div className={ classes.popupContent }>
          <p>Percent of student loan borrowers who delay key life events due to debt</p>
          <img src={ percentageStudent } alt="" />
        </div>
        <div className={ classes.popupFooter }>
          <p>
            Source: Life Delayed: The Impact of Student Debt on the Daily Lives of Young Americans, American Student Association, 2015
          </p>
          <Button onClick={ handleStudentPercentagePopupClose }>Close</Button>
        </div>

      </Dialog>
      <Dialog
        onClose={ handleStudentpercentageGraphPopupClose }
        aria-labelledby="simple-dialog-title"
        open={ studentPercentageGraph }
        className={ classes.PopupSchool }
      >
        <div className={ classes.popupHeader }>
          <div aria-hidden="true" onClick={ handleStudentpercentageGraphPopupClose }><i className="la la-times" /></div>
        </div>
        <div className={ classes.popupContent }>
          <p>Percent of former students with student loans</p>
          <img src={ percenteFormerStudentsTwo } alt="" />
        </div>
        <div className={ classes.popupFooter }>
          <p>Source: “Life Delayed: The Impact of Student Debt on the Daily Lives of Young Americans,” American Student Association, 2015 Edition.</p>
          <Button onClick={ handleStudentpercentageGraphPopupClose }>Close</Button>
        </div>

      </Dialog>
      <Dialog
        onClose={ handlePercentageFormatePopupClose }
        aria-labelledby="simple-dialog-title"
        open={ percentageFormate }
        className={ classes.PopupSchool }
      >
        <div className={ classes.popupHeader }>
          <div aria-hidden="true" onClick={ handlePercentageFormatePopupClose }><i className="la la-times" /></div>
        </div>
        <div className={ classes.popupContent }>
          <img src={ percenteFormerStudents } alt="" />
        </div>
        <div className={ classes.popupFooter }>
          <Button onClick={ handlePercentageFormatePopupClose }>Close</Button>
        </div>

      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

School.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( School );
