import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';



const BitCoinAnswerPopups = (props) => {
  const { classes, challengeIndex } = props;
  return (
    <Typography variant="body1" component="div">

      { /* bitcoin XP questions  Popup */}
      <div className={classes.completeActivity}>
        <div className={classNames(classes.completePopup, classes.completePopupTable)}>
          {challengeIndex === 1 && (
            <div className={classes.completePopupChallengeTable}>
              <h3>Mary's cash left over for savings and investment</h3>
              <table>
                <thead>
                  <tr>
                    <th>Mary’s Budget</th>
                    <th>Monthly</th>
                    <th>Annual</th>
                    <th>% of After-Tax<br /> Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><u>Income</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Salary / Wage</td>
                    <td>$4,500</td>
                    <td>$54,000</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Taxes</td>
                    <td className={classes.textRed}>($420)</td>
                    <td className={classes.textRed}>($5,040)</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total After-Tax Income</td>
                    <td className={classes.textBold}>$4,080</td>
                    <td className={classes.textBold}>$48,960</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><u>Expenses and Loan Payments</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Total Living Expenses</td>
                    <td className={classes.textRed}>($3,200)</td>
                    <td className={classes.textRed}>($29,376)</td>
                    <td>78.4%</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Total Loan Payments</td>
                    <td className={classes.textRed}>($300)</td>
                    <td className={classes.textRed}>($3,600)</td>
                    <td>7.4%</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total Expenses</td>
                    <td className={classes.textRed}>($3,500)</td>
                    <td className={classes.textRed}>($42,00)</td>
                    <td className={classes.textBold}>85.8%</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Cash Left Over for Savings and Investments</td>
                    <td className={classes.textBold}>$580</td>
                    <td className={classes.textBold}>$6,960</td>
                    <td className={classes.textBold}>14.2%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {challengeIndex === 2 && (
             <div className={classes.completePopupChallengeTable}>
              <h3>Mary's cash left over for savings and investment</h3>
              <table>
                <thead>
                  <tr>
                    <th>Mary’s Budget</th>
                    <th>Monthly</th>
                    <th>Annual</th>
                    <th>% of After-Tax<br /> Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><u>Income</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Salary / Wage</td>
                    <td>$5,000</td>
                    <td>$60,000</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Taxes</td>
                    <td className={classes.textRed}>($520)</td>
                    <td className={classes.textRed}>($6,240)</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total After-Tax Income</td>
                    <td className={classes.textBold}>$4,480</td>
                    <td className={classes.textBold}>$53,760</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><u>Expenses and Loan Payments</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >Total Living Expenses</td>
                    <td className={classes.textRed}>($3,136)</td>
                    <td className={classes.textRed}>($37,632)</td>
                    <td>70.0%</td>
                  </tr>
                  <tr>
                    <td>Total Loan Payments</td>
                    <td className={classes.textRed}>($250)</td>
                    <td className={classes.textRed}>($3,000)</td>
                    <td>5.6%</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total Expenses</td>
                    <td className={classes.textRed}>($3,386)</td>
                    <td className={classes.textRed}>($40,632)</td>
                    <td className={classes.textBold}>75.6%</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Cash Left Over for Savings and Investments</td>
                    <td className={classes.textBold}>$1,094</td>
                    <td className={classes.textBold}>$13,128</td>
                    <td className={classes.textBold}>24.4%</td>
                  </tr>
                </tbody>
              </table>

            </div>
          )}

          {challengeIndex === 3 && (
             <div className={classes.completePopupChallengeTable}>
              {/* <h1>POWERUP CHALLENGE EXPLANATION</h1> */}
              <h3>Mary's cash left over for savings and investment</h3>
              <table>
                <thead>
                  <tr>
                    <th>Mary’s Budget</th>
                    <th>Monthly</th>
                    <th>Annual</th>
                    <th>% of After-Tax<br /> Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><u>Income</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Salary / Wage</td>
                    <td>$6,000</td>
                    <td>$72,000</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Taxes</td>
                    <td className={classes.textRed}>($750)</td>
                    <td className={classes.textRed}>($9,000)</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total After-Tax Income</td>
                    <td className={classes.textBold}>$5,250</td>
                    <td className={classes.textBold}>$63,000</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><u>Expenses and Loan Payments</u></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Total Living Expenses</td>
                    <td className={classes.textRed}>($3,885)</td>
                    <td className={classes.textRed}>($46,620)</td>
                    <td>74.0%</td>
                  </tr>
                  <tr>
                    <td className={classes.textindent50}>Total Loan Payments</td>
                    <td className={classes.textRed}>($200)</td>
                    <td className={classes.textRed}>($2,400)</td>
                    <td>3.8%</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Total Expenses</td>
                    <td className={classes.textRed}>($4,085)</td>
                    <td className={classes.textRed}>($49,020)</td>
                    <td className={classes.textBold}>77.8%</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={classes.textBold}>Cash Left Over for Savings and Investments</td>
                    <td className={classes.textBold}>$1,165</td>
                    <td className={classes.textBold}>$13,980</td>
                    <td className={classes.textBold}>22.2%</td>
                  </tr>
                </tbody>
              </table>

            </div>
          )}
        </div>
      </div>
    </Typography>
  );
};

BitCoinAnswerPopups.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinAnswerPopups);
