import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import infoIcon from '../../assets/img/info-icon.svg';
import styles from './styles';
import Pageloader from '../../components/ui/pageloader';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import ContactList from '../../components/address-book/contact-list';
import UserDetails from '../../components/address-book/user-details';

const ADDRESS_BOOK_CONTACT = loader('../../graphql/schema/address-book/getAddressBookContact.graphql');
const SEARCH_ADDRESS_BOOK_CONTACT = loader('../../graphql/schema/address-book/searchAdressBookContact.graphql');
const ADD_GROUP = loader('../../graphql/schema/address-book/addGroup.graphql');
const VALIDATE_GROUP_NAME = loader('../../graphql/schema/address-book/checkExistingGroupName.graphql');
const DISPLAY_GROUP_NAME = loader('../../graphql/schema/address-book/display-group-name.graphql');
const DELETE_GROUP = loader('../../graphql/schema/address-book/delete-group.graphql');
const GROUP_MEMBER_LIST = loader('../../graphql/schema/address-book/getAddressBookGroupMember.graphql');

const Addressbook = (props) => {
  const { classes } = props;

  const [addGroup, setAddGroup] = useState(false);
  const [tabs, setTabs] = useState('People');
  const [searchText, setSearchText] = useState('');
  const [displayUser, setDisplayUser] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [groupID, setGroupID] = useState('');
  const [deleteID, setDeleteID] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(tabs || '');
  const [listID, setListID] = useState(null);
  const [searchIcon, setSearchIcon] = useState(false);
  const [searchSuccessMessage, setSearchSuccessMessage] = useState('');

  let result = [];
  let addressBookResult = [];

  const { data: addressBookContact, loading } = useQuery(ADDRESS_BOOK_CONTACT, {
    fetchPolicy: 'network-only',
  });

  const { data: displayGroupName } = useQuery(DISPLAY_GROUP_NAME);

  const [searchContactList,{ data: searchContact, loading: searchLoading }] = useLazyQuery(SEARCH_ADDRESS_BOOK_CONTACT, {
    variables: {
      search_text: searchText,
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setSearchSuccessMessage('Sorry, no results found.');
      setDisplayUser(null);
    },
  });

  const [groupContactLists, { data: groupContactList }] = useLazyQuery(GROUP_MEMBER_LIST, {
    variables: {
      data: groupID,
    },
    fetchPolicy: 'network-only',
  });
  if (tabs === 'People') {
    result = searchContact && searchContact.SearchAddressBookContact;
    // addressBookResult = addressBookContact && addressBookContact.getAddressBookContact;
  } else if (tabs === 'My Contact') {
    addressBookResult = addressBookContact && addressBookContact.getAddressBookContact;
    const data = addressBookResult.map((info) => {
      if (info.status === 'A') {
        return info;
      }
      return null;
    }).filter((el) => el != null);
    result = data.map((book) => book.user);
  } else if (tabs === 'group_name') {
    result = groupContactList && groupContactList.getAddressBookGroupMember.map((info) => info.user);
  }

  const [addGroupName, { loading: addLoading }] = useMutation(ADD_GROUP, {
    onCompleted() {
      setSuccessMessage('New group created successfully.');
      setGroupName('');
      // setAddGroup( false );
    },
    refetchQueries: [
      {
        query: DISPLAY_GROUP_NAME,
        fetchPolicy: 'network-only',
      },
    ],
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setErrorMessage(formatedErrors);
    },
  });

  const [deleteGroupName, { loading: deleteLoading }] = useMutation(DELETE_GROUP, {
    onCompleted() {
      setDeletePopup(false);
      setPlaceholderText('')
    },
    refetchQueries: [
      {
        query: DISPLAY_GROUP_NAME,
        fetchPolicy: 'network-only',
      },
    ],
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setErrorMessage(formatedErrors);
    },
  });

  const { data: validateGroupName } = useQuery(VALIDATE_GROUP_NAME, {
    variables: {
      data: groupName,
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkExistingGroupName.status === false) {
        setErrorMessage(response.checkExistingGroupName.message);
        setSuccessMessage('');
      } else {
        // setSuccessMessage( response.checkExistingGroupName.message );
        setErrorMessage('');
      }
    },
  });

  const handleAddGroupPopupClose = () => {
    setAddGroup(false);
  };

  const addTeamGroup = () => {
    setAddGroup(true);
    setGroupName('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleTabs = (tab) => {
    setTabs(tab);
    setPlaceholderText(tab);
    setDisplayUser(null);
    setSearchText('');
    setSearchSuccessMessage('');
    setSearchIcon(false);
    if(tab === 'People'){
      searchContactList();
    }
  };

  const handleDisplayUser = (data) => {
    setListID(data.id);
    setDisplayUser(data);
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const handleCreateGroup = () => {
    if (groupName !== '') {
      addGroupName({
        variables: {
          data: groupName,
        },
      });
    } else {
      setErrorMessage('Please provide group name.');
    }
  };

  const deleteGroup = (id) => {
    setDeleteID(id);
    setDeletePopup(true);
  };

  const handleDeleteGroup = () => {
    deleteGroupName({
      variables: {
        id: deleteID,
      },
    });
  };

  const handleDeleteClose = () => {
    setDeleteID('');
    setDeletePopup(false);
  };

  const handleGroupList = (group) => {
    setGroupID(group.id);
    setPlaceholderText(group.group_name);
    setTabs('group_name');
    setDisplayUser(null);
    setSearchText('');
    setSearchIcon(false);
    setSearchSuccessMessage('');
    groupContactLists();
  };

  return (
    <Typography variant="body1" component="div" className={classes.addressBookContainer}>
      {loading && <Pageloader loading={loading} />}
      {deleteLoading && <Pageloader loading={deleteLoading} />}
      {addLoading && <Pageloader loading={addLoading} />}
      {searchLoading && <Pageloader loading={searchLoading} />}
      <div className={classes.addressBook}>
        <div className={classes.container}>
          <div className={classes.addressCntnt}>
            <div className={classes.addressTitle}>
              <Typography variant="h2" component="h2">Address Book </Typography>
              <div className={classes.infoIcon}>
                <img
                  src={infoIcon}
                  alt="infoicon"
                  data-for="infoIcon"
                  data-tip="In order to add people to 'My Contacts', you need to search for them using the 'Find People' tool. The person you are trying to find must configure their
                'Collaboration Settings' in the 'My Account' page. Once they adjust those settings, search for their account details. Then, you can use the plus sign to send a request to add them to your contacts. After they accept your request, you can begin collaborating and sharing an unlimited number of scenarios and sketches with that person to enhance your financial life planning."
                />
              </div>
            </div>
            <div className={classes.addressBlocks}>
              <Grid container>
                <Grid item md={3} lg={3}>
                  <div className={classes.tabsBlock}>
                    <div className={classes.addressTabs}>
                      <ul className={classes.addressList}>
                        <li aria-hidden="true" className={placeholderText === 'People' && classes.activeClass} onClick={() => handleTabs('People')}>
                          <span><Icon className="fa fa-search" /></span>
                          Find People
                        </li>
                        <li aria-hidden="true" className={placeholderText === 'My Contact' && classes.activeClass} onClick={() => handleTabs('My Contact')}>
                          <span><Icon className="fa fa-book" /></span>
                          My Contacts
                        </li>
                        {
                          displayGroupName && displayGroupName.getAddressBookGroup.map((group) => (
                            <li className={placeholderText === group.group_name && classes.activeClass} aria-hidden="true" onClick={() => handleGroupList(group)}>
                              <span><i className="fa fa-group" /></span>
                              {group.group_name}
                              <span className={classes.pullRight} aria-hidden="true" onClick={() => deleteGroup(group.id)}><i className="fa fa-trash-o" /></span>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className={classes.addressScroll}>
                      <ul />
                    </div>
                    <div className={classes.addressBtn}>
                      <span aria-hidden="true" className={classes.addAddress} onClick={addTeamGroup}>
                        <i className="fa fa-plus" aria-hidden="true" data-for="addTeam" data-tip="Create a Group" />
                      </span>
                    </div>
                  </div>
                </Grid>
                {
                  tabs
                  && (
                    <ContactList
                      tabs={tabs}
                      displayContactList={result && result}
                      handleDisplayUser={handleDisplayUser}
                      listID={listID}
                      searchText={searchText}
                      placeholderText={placeholderText}
                      searchIcon={searchIcon}
                      setSearchText={setSearchText}
                      setSearchIcon={setSearchIcon}
                      searchContactList={searchContactList}
                      searchSuccessMessage={searchSuccessMessage}
                      setSearchSuccessMessage={setSearchSuccessMessage}
                    />
                  )
                }
                {displayUser && <UserDetails groupIDValue={groupID} tabs={tabs} addressBookResult={addressBookResult} displayUser={displayUser} displayGroupName={displayGroupName && displayGroupName.getAddressBookGroup} setDisplayUser={setDisplayUser} groupContactList={groupContactLists} searchContactList={searchContactList}/>}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={handleAddGroupPopupClose}
        aria-labelledby="simple-dialog-title"
        open={addGroup}
        className={classes.addGroup}
      >
        <div className={classes.popupHeader}>
          <div aria-hidden="true" onClick={handleAddGroupPopupClose}>
            <i className="la la-times" />
            <h4>Add Group</h4>
          </div>
        </div>
        <div className={classes.popupContent}>
          <div className={classes.popupInput}>
            <Grid container spacing={1} className={classes.GridPad}>
              <Grid item xs={12} sm={4}>
                <span>Create New Group</span>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.GridInput}>
                <TextField
                  placeholder="Group Name"
                  variant="outlined"
                  value={groupName}
                  onChange={handleGroupName}
                  onBlur={validateGroupName}
                />
              </Grid>
              <div className={classes.textSuccessPopup}>{sucessMessage && <span>{sucessMessage}</span>}</div>
              <div className={classes.textDangerPopup}>{errorMessage && <span>{errorMessage}</span>}</div>
            </Grid>
          </div>
        </div>
        <div className={classes.popupFooter}>
          <Button className={classes.saveButton} onClick={handleCreateGroup}>Save</Button>
          <Button onClick={handleAddGroupPopupClose}>Close</Button>
        </div>
      </Dialog>

      <Dialog
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        open={deletePopup}
        className={classes.addGroup}
      >
        <div className={classes.popupHeader}>
          <h4>Delete Group</h4>
        </div>
        <div className={classes.popupContent}>
          Are you sure you want to delete this group?
        </div>
        <div className={classes.popupFooter}>
          <Button className={classes.saveButton} onClick={handleDeleteGroup}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </div>
      </Dialog>

      <ReactTooltip id="infoIcon" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
      <ReactTooltip id="addTeam" place="top" type="light" effect="solid" className={classes.tooltipInfoNew} />

      <ScrollToTop />
    </Typography>
  );
};

Addressbook.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Addressbook);
