import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import arrowImage from '../../../assets/img/mcs/level6/arrow-image.png';
import Pageloader from '../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import tickMark from '../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../assets/img/mcs/icon-book-rev.png';
import timelineImage from '../../../assets/img/mcs/level6/Image_1_Net_Worth_at_Start.png';
import { useQuery, useMutation } from '@apollo/react-hooks';
import he from '../../../assets/img/mcs/level6/module-icons/higher-education-icon.png';
import cp from '../../../assets/img/mcs/level6/module-icons/career-path.png';
import ca from '../../../assets/img/mcs/level6/module-icons/career-advancement.png';
import aca from '../../../assets/img/mcs/level6/module-icons/addt-career-advancement-icon.png';
import invest from '../../../assets/img/mcs/level6/module-icons/investment-icon.png';
import home from '../../../assets/img/mcs/level6/module-icons/buying-home-icon.png';
import arrowIcon from '../../../assets/img/mcs/level6/arrow-level4.png';
import bracketIcon from '../../../assets/img/mcs/level6/braces_icon.png';
import plusIcon from '../../../assets/img/mcs/level6/plus_icon.png';
import equalIcon from '../../../assets/img/mcs/level6/equal_icon.png';
import blueCircle from '../../../assets/img/mcs/level6/blue_circle_arrow.png';
import spotLight from '../../../assets/img/mcs/level6/Image_3_Net_Worth_with_Investments_spotlighted.png';
import timelineNew from '../../../assets/img/mcs/level6/Image_5_Lumen_updated_final_timeline_sketcher.png';
import graph1 from '../../../assets/img/mcs/level6/graph1.png';
import graph2 from '../../../assets/img/mcs/level6/graph2.png';
import mcsLogoImage from '../../../assets/img/mcs/my-career-sketch-blue.png';
import csIcon from '../../../assets/img/mcs/icon.png';

// const MY_DETAILS = loader('../../../graphql/schema/auth/me.graphql');
const PUBLISH = loader('../../../graphql/schema/career-sketch/publish.graphql');
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');

const titleText = [
  'Activity: Add $100k to Your Net Worth (1 of 22)',
  'Activity: Add $100k to Your Net Worth (2 of 22)',
  'Activity: Add $100k to Your Net Worth (3 of 22)',
  'Activity: Add $100k to Your Net Worth (4 of 22)',
  'Activity: Add $100k to Your Net Worth (5 of 22)',
  'Activity: Add $100k to Your Net Worth (6 of 22)',
  'Activity: Add $100k to Your Net Worth (7 of 22)',
  'Activity: Add $100k to Your Net Worth (8 of 22)',
  'Activity: Add $100k to Your Net Worth (9 of 22)',
  'Activity: Add $100k to Your Net Worth (10 of 22)',
  'Activity: Add $100k to Your Net Worth (11 of 22)',
  'Activity: Add $100k to Your Net Worth (12 of 22)',
  'Activity: Add $100k to Your Net Worth (13 of 22)',
  'Activity: Add $100k to Your Net Worth (14 of 22)',
  'Activity: Add $100k to Your Net Worth (15 of 22)',
  'Activity: Add $100k to Your Net Worth (16 of 22)',
  'Activity: Add $100k to Your Net Worth (17 of 22)',
  'Activity: Add $100k to Your Net Worth (18 of 22)',
  'Activity: Add $100k to Your Net Worth (19 of 22)',
  'Activity: Add $100k to Your Net Worth (20 of 22)',
  'Activity: Add $100k to Your Net Worth (21 of 22)',
  'Activity: Add $100k to Your Net Worth (22 of 22)'
];

const McsPowerOfCareerSketching = (props) => {
  const { classes, history } = props;
  // const { data: myDetails } = useQuery(MY_DETAILS);

  const [loading] = React.useState(false);
  const [bookSection, setBookSection] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [sideMenu, setSideMenu] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [completedSlides, setCompletedSlides] = React.useState({ 1: true });
  const [levelList, setLevelList] = React.useState([]);


  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "6",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      if(mcsLevelWiseData.getMCSLevelActivityData.level_percent === 100){
        let completedSlide = {};
        for (let c = 1; c <= 22; c += 1) {
          completedSlide[c] = true;
        }
        setCompletedSlides(completedSlide);
        setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);

      }
    },
    onError() {
    },
  });

  const [saveMyCareerSketch] = useMutation(PUBLISH, {
    onCompleted(response) {
      history.push('/my-career-sketch/level6');
    },
    onError(errors) {

    },
  });


  const handleSlide = (step, type) => {
    const completedSlide = { ...completedSlides };
    let progressBar = (step) * (100 / 22);
    progressBar = progressBar.toFixed(0);
    setProgress(progressBar);
    if (type === "next") {
      setActiveStep(step + 1);
    } else if (type === "back") {
      setActiveStep(step - 1);
    } else if (type === "save") {
      setActiveStep(step);
      saveMyCareerSketch({
        variables: {
          data: {
            level: 6,
            option_id: 1
          },
        },
      });
    }
    completedSlide[step] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
    setSideMenu(false);
  }

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      {sideMenu && (

      <div className={classes.mcsSidebar}>
        <span className={classes.mcsClose} onClick={()=>(setSideMenu(false))}></span>
        <span className={classes.mcsLevel}>
          <a href="/my-career-sketch/level6"><span> {'<'} Back to Level 06 </span></a>
        </span>
        <div className={classes.mcsLogoImageBlue}>
          <a href="/"><img src={mcsLogoImage} alt="logo" /></a>
        </div>
        <h3 className={classes.subTitle}>Why Career Sketch?</h3>
        <div className={classes.mcsContent}>
          <h3 className={classes.subLevel}>
            <span><img src={csIcon} alt="icon" /></span>
            Level 06
          </h3>
          <ul className={classes.activityList}>
            <li>
              <ul>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 1 );setSideMenu(false)} }>
                  <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>01</span>
                      <font>Net worth</font>
                      <span className={classes.menuCheck}>
                      {completedSlides[1] !== undefined && completedSlides[1] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 3 );setSideMenu(false)} }>
                  <div className={classes.anchorList}>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>02</span>
                      <font>Get started</font>
                      <span className={classes.menuCheck}>
                      {completedSlides[3] !== undefined && completedSlides[3] === true
              && <CheckIcon fontSize="small" />}
              </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 4 );setSideMenu(false)} }>
                  <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>03</span>
                      <font>Lumen&apos;s smart moves</font>
                      <span className={classes.menuCheck}>
                        
                        {completedSlides[4] !== undefined && completedSlides[4] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep(6 );setSideMenu(false)} }>
                  <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>04</span>
                      <font>Lumen graduates on time</font>
                      <span className={classes.menuCheck}>
                        {completedSlides[6] !== undefined && completedSlides[6] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 7 );setSideMenu(false)} }>
                 <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>05</span>
                      <font>Lumen saves her family money</font>
                      <span className={classes.menuCheck}>
                        {completedSlides[7] !== undefined && completedSlides[7] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 13 );setSideMenu(false)} }>
                  <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>06</span>
                      <font>Lumen&apos;s career starts on time</font>
                      <span className={classes.menuCheck}>
                        {completedSlides[13] !== undefined && completedSlides[13] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 17 );setSideMenu(false)} }>
                  <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>07</span>
                      <font>Lumen invests early and often</font>
                      <span className={classes.menuCheck}>
                        {completedSlides[17] !== undefined && completedSlides[17] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" onClick={ () => {setActiveStep( 22 );setSideMenu(false)} }>
                 <div className={ classes.anchorList }>
                    <div className={classes.flexMenu}>
                      <span className={classes.flexSpan}>08</span>
                      <font>Lumen adds $1,000,000 to her retirement</font>
                      <span className={classes.menuCheck}>
                        {completedSlides[22] !== undefined && completedSlides[22] === true
              && <CheckIcon fontSize="small" />}
                        </span>
                    </div>
                  </div>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </div>
      )}
      <div>
        {/* <div className={classes.topImage}>
                    <img src={mcsTopImage} alt="mcs" />
                </div> */}
        <div className={classes.mcsDevelopVision}>
          <div className={classes.topRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.activityStatus}>
              <h4><span className={classes.greenTick}><img src={tickMark} alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level6">LEVEL 06</a></h4>
            </div>
            <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
            <div className={classes.bookBlock}>
              <div className={classes.activityImage} aria-hidden="true" onClick={() => { setBookSection(true) }} data-for="book" data-tip="Activity Tracker">
                <img src={bookImage} alt="book" />
              </div>
              {bookSection && (
                <div className={classes.reviewLevels}>
                  <i className="fa fa-times" onClick={() => { setBookSection(false) }} />
                  <div className={classes.panelDefault}>
                    <div className={classes.panelBody}>
                      <span className={classes.titleName}>
                        <span className={classes.mcsNumber}>1.</span>
                        <span> Explore Lumen/Neal&apos;s smart moves</span>
                      </span>
                       {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}   
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  <div className={classNames(classes.container, classes.activityContainer)}>
    <div className={classes.pageBreadcrumbs}>
      <div className={classes.headingBlock}>
        <Link to="/my-career-sketch/level6">
          <span> {'<'} Back to level 06</span>
        </Link>
        <h3>
          <span>Level 06:</span>&nbsp;Why Career Sketch?
        </h3>
        <div className={classes.arrowHeader}>
          <img src={arrowImage} alt="arrow" />
        </div>
      </div>
    </div>

      <div className={classes.mainContainer}>
        <div className={classes.fiContentHeader}>
          <div className={classes.pwiWalkTitle}>
            <div aria-hidden="true" className={classes.menuIcon} onClick={()=>{setSideMenu(true)}}>
              <span />
              <span />
              <span />
            </div>
            <h3>{titleText[activeStep - 1]}</h3>
          </div>
        </div>
        <div className={classes.ProgressBar}>
          <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
        </div>
        {activeStep === 1 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Net Worth = Assets – Liabilities</b></span>
          </h3>
          <h3> In other words, net worth is the value of everything that you own minus the value of everything that you owe. If you sold all your assets today for cash – investments, jewelry, insurance policies, cars, houses – and then repaid all your debts – credit cards, student loans, car loans, mortgages – with that cash, how much is left? That’s your net worth. The higher the worth, the stronger you are financially. </h3>
          <div className={classes.grayBox}>
            <h3 className={classes.noMargin}>Knowing your net worth is important for two main reasons:</h3>
            <h3>
              <ol>
                <li>Net worth shows your current financial situation in one, clean number, and</li>
                <li>Net worth provides a reference point for measuring your future financial progress.</li>
              </ol>
            </h3>
          </div>
          <h3>Let&apos;s take a look at the median net worth numbers by age in America.</h3>
        </div>
       
    )}
        {activeStep === 2 && (
        <div className={classes.mainContent}>
          <h3> Review the graph below. Do the median net worth values surprise you?</h3>
          <img src={graph1} alt="graph" />
          <span className={classes.graphNote}>
            Source: 2016 Federal Reserve Board&apos;s triennial Survey of Consumer Finances (SCF)
          </span>
        </div>
    )}

    {activeStep === 3 && (

    
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Get started</b></span>
          </h3>
          <h3>In this activity, we&apos;re going to show you the power of career sketching. Many of the decisions you make today have predictable outcomes. Why leave your future up to chance? Career sketching makes it easy to see the financial outcome of your decisions before you even make them.</h3>
          <h3>Growing your net worth and personal happiness means everything. There are many different paths to success, but they all start with a plan.</h3>
          <h3>
            <div className={classes.grayBox}>
              <h3 className={classes.noTitleMargin}>"With a plan, you make things happen. Without a plan, things happen to you."
                <span className={classes.authorTitle}>- James DeLuca, Inkwiry CEO</span>
              </h3>
            </div>
          </h3>
          <h3>Ready to see the power of career sketching?</h3>
        </div>
    )}
    {activeStep === 4 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Lumen&apos;s smart moves</b></span>
          </h3>
          <h3>Wea&apos;re going to take you on a virtual journey of the future for one of Inkwiry's career sketches. Lumen will add $110,000 to her net worth by making a few smart moves. Just as Lumen does, you can build your sketch to include smart money moves that grow your wealth, health and happiness.</h3>
          <h3>Take a look at Lumen&apos;s before and after summary to get started. Ready to launch? Get started by carefully reading the six steps below.</h3>
          <div className={classes.virtualBlocks}>
            <table className={classes.virtualTable}>
              <thead>
                <tr>
                  <th>Module Name</th>
                  <th>Lumen Before
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>1<span>.</span></div>
                    <img src={he} alt="he" />
                    Higher Education
                  </td>
                  <td>
                    Lumen graduates
                    <span>college in five years</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>2<span>.</span></div>
                    <img src={cp} alt="he" />
                    Career Path
                  </td>
                  <td>
                    2025 - 2026
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>3<span>.</span></div>
                    <img src={ca} alt="he" />
                    Career<br /> Advancement
                  </td>
                  <td>
                    2027 - 2030
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>4<span>.</span></div>
                    <img src={aca} alt="he" />
                    Additional Career<br /> Advancement
                  </td>
                  <td>
                    2031 - 2034
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>5<span>.</span></div>
                    <img src={invest} alt="he" />
                    Investments
                  </td>
                  <td>
                    Invests $5 per day
                    <span>starting in 2027</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>6<span>.</span></div>
                    <img src={home} alt="he" />
                    Buying a Home
                  </td>
                  <td>
                    Buys a $350k home
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>15-year Net Worth</td>
                  <td>$137,196 <i> <img src={arrowIcon} alt="arrow" /></i></td>
                </tr>
              </tfoot>
            </table>
            <table className={classNames(classes.virtualTable, classes.virtualRight)}>
              <thead>
                <tr>
                  <th>Lumen After</th>
                  <th>Net Worth Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen graduates on<span>time, or in four years</span></td>
                  <td>+$47,559</td>
                </tr>
                <tr>
                  <td>Starts career one year<span>earlier in 2024</span></td>
                  <td>+$685</td>
                </tr>
                <tr>
                  <td>2026 - 2029</td>
                  <td>+6,628</td>
                </tr>
                <tr>
                  <td>2030 - 2034; adds an<span>extra year of income</span></td>
                  <td>+$52,352</td>
                </tr>
                <tr>
                  <td>Invests $10 per day<span>starting in 2024</span></td>
                  <td>+$52,352</td>
                </tr>
                <tr>
                  <td>Buys a $450k home</td>
                  <td>($1,944)</td>
                </tr>
              </tbody>
              <tfoot> <tr> <td>$247,563</td> <td>+$110,367</td> </tr> </tfoot>
            </table>
          </div>
        </div>
    )}
    {activeStep === 5 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>The power of making smart money moves</b></span>
          </h3>
          <h3>One smart move can put $110,000 in your pocket. Don&apos;t believe it? We&apos;re going to make some changes to an Inkwiry Career Sketch – Lumen. Her 15-year net worth is $137,196.</h3>
          <h3>The image below is Lumen&apos;s existing timeline. Find the blue arrow to see Lumen&apos;s starting net worth. Let&apos;s see what happens to that 15-year number when Lumen graduates in four years instead of five.</h3>
          <div className={classes.shadowImage}>
            <img src={timelineImage} alt="arrow" />
          </div>
        </div>
    )}
    {activeStep === 6 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Only 40% of students graduate college on time</b></span>
          </h3>
          <h3>That means 60% of students spend at least one more year at school than they need to. Plus, more than 50% of college students do not graduate. Planning gives you the best chance of hitting your targets on time.</h3>
          <h3>Take a look at Lumen&apos;s before and after summary to get started. Ready to launch? Get started by carefully reading the six steps below.</h3>
          <h3>
            <ul className={classes.savingsList}>
              <li>More tuition and fees</li>
              <li>Starting your career and earning income one year later.</li>
            </ul>
          </h3>
          <h3>Lumen takes five years to graduate. Let&apos;s change that to four years and see the savings.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Savings</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Years to Graduation</td>
                  <td>5 years</td>
                  <td>4 years</td>
                  <td className={classes.greenText}>1 year</td>
                </tr>
                <tr>
                  <td>Tuition + Fees</td>
                  <td>$135,408</td>
                  <td>$106,162</td>
                  <td className={classes.greenText}>$29,246</td>
                </tr>
                <tr>
                  <td>Room + Board</td>
                  <td>$20,400</td>
                  <td>$20,400</td>
                  <td className={classes.greenText}>$0</td>
                </tr>
                <tr>
                  <td>Living Expenses</td>
                  <td>$73,230</td>
                  <td>$51,631</td>
                  <td className={classes.greenText}>$21,599</td>
                </tr>
                <tr className={classes.topBorder}>
                  <td><strong>Total Cost of Attendance</strong></td>
                  <td><strong>$229,038</strong></td>
                  <td><strong>$178,193</strong></td>
                  <td className={classes.greenText}><strong>$50,845</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    )}
    {activeStep === 7 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Lumen saves her family money too</b></span>
          </h3>
          <h3>Lumen&apos;s family is contributing $15,000 a year to her education. When she takes an extra year to graduate, that impacts Lumen&apos;s family as well.</h3>
          <h3>When Lumen graduates in four years, not only does she save herself time and money, but Lumen also saves her family money as well.</h3>

          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Savings</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.topBorder}>
                  <td><strong>Family Contribution</strong></td>
                  <td><strong>$75,000	</strong></td>
                  <td><strong>$60,000</strong></td>
                  <td className={classes.greenText}><strong>$15,000</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    )}
    {activeStep === 8 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Earning more income = less debt</b></span>
          </h3>
          <h3>What happens if Lumen earns $10,000 before tax per year instead of $7,875? Lumen picks up part-time hours during the semester to earn more. She works two 4-hour days a week for 10 to 11 weeks each semester earning $12.50 per hour.</h3>
          <h3>
            <div className={classes.grayBox}>
              170 hours x $12.50 per hour = $2,125 earned
            </div>
          </h3>
          <h3>
            On top of summer internships, working during school is a great way to:
            <ul className={classes.savingsList}>
              <li>Earn money,</li>
              <li>Take fewer student loans,</li>
              <li>Gain experience, and</li>
              <li>Build a resume.</li>
            </ul>
          </h3>
        </div>
    )}
    {activeStep === 9 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Minimize the cost of student loans by repaying on time</b></span>
          </h3>
          <h3>By graduating in four years, Lumen reduces her federal student loans from $31,000 to $24,000.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Federal Student Loans Taken</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                  <th>2023</th>
                  <th>2024</th>
                  <th>Total Loans</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen Before</td>
                  <td>$5,500</td>
                  <td>$6,000</td>
                  <td>$6,500</td>
                  <td>$6,500</td>
                  <td>$6,500</td>
                  <td>$31,000</td>
                </tr>
                <tr>
                  <td>Lumen After</td>
                  <td>$5,500</td>
                  <td>$6,000</td>
                  <td>$6,250</td>
                  <td>$6,250</td>
                  <td>$0</td>
                  <td>$24,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 className={classes.grayBox}>
            DID YOU KNOW: Only 40% of people repay their student loans in 20 years?
          </h3>
          <h3>
            Lumen is going to repay her student loans on time, which saves her tens of thousands of dollars. When Lumen updates her plan to repay her federal student loans in 10 years instead of 20, she saves $18,665.
          </h3>
          <h3>For $58 more each month, or just $1.95 per day, Lumen saves over $18,600 on her student loans. That’s a smart money move.</h3>
          <div className={classes.lumensTables}>
            <div className={classes.lumenLeftTable}>
              <p>Lumen Before: $31,000</p>
              <table>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Annual Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>$2,563</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>$2,563</td>
                  </tr>
                  <tr className={classes.boldText}>
                    <td>Total Paid</td>
                    <td>$51,251</td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className={classes.lumenButton}>Show Table</button>
            </div>
            <div className={classes.lumenRightTable}>
              <p>Lumen After: $24,000</p>
              <table>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Annual Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$3,260</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>$3,260</td>
                  </tr>
                  <tr className={classes.boldText}>
                    <td>Total Paid</td>
                    <td>$32,597</td>
                  </tr>
                  <tr className={classNames(classes.boldText, classes.greenBackground)}>
                    <td>Savings</td>
                    <td>$18,655</td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className={classes.lumenButton}>Show Table</button>
            </div>
          </div>
          
        </div>
    )}
    {activeStep === 10 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Graduating on time = less debt</b></span>
          </h3>
          <h3>Lumen needed $40,000 in private student loans before changing to a four-year graduation plan.</h3>
          <h3 className={classes.grayBox}>
            By earning extra income, Lumen can reduce her private student loans from $40,000 to $20,000.
          </h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Private Student Loans Taken</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                  <th>2023</th>
                  <th>2024</th>
                  <th>Total Loans</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen Before</td>
                  <td>$8,000</td>
                  <td>$8,000</td>
                  <td>$8,000</td>
                  <td>$8,000</td>
                  <td>$8,000</td>
                  <td>$40,000</td>
                </tr>
                <tr>
                  <td>Lumen After</td>
                  <td>$5,000</td>
                  <td>$5,000</td>
                  <td>$5,000</td>
                  <td>$5,000</td>
                  <td>$0</td>
                  <td>$20,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>
            Lumen is also going to repay her student loans on time. When Lumen updates her plan to repay her private student loans in 10 years instead of 20, she saves $45,170.
          </h3>

          <div className={classes.lumensTables}>
            <div className={classes.lumenLeftTable}>
              <p>Lumen Before: $40,000</p>
              <table>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Annual Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>$3,722</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>$3,722</td>
                  </tr>
                  <tr className={classes.boldText}>
                    <td>Total Paid</td>
                    <td>$74,443</td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className={classes.lumenButton}>Show Table</button>
            </div>
            <div className={classes.lumenRightTable}>
              <p>Lumen After: $20,000</p>
              <table>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Annual Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$2,927</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>$2,927</td>
                  </tr>
                  <tr className={classes.boldText}>
                    <td>Total Paid</td>
                    <td>$29,274</td>
                  </tr>
                  <tr className={classNames(classes.boldText, classes.greenBackground)}>
                    <td>Savings</td>
                    <td>$45,169</td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className={classes.lumenButton}>Show Table</button>
            </div>
          </div>
        </div>
    )}
    {activeStep === 11 && (

     
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Less debt = less interest you have to pay = more money in your pocket</b></span>
          </h3>

          <h3 className={classes.grayBox}>
            Lumen added more than $45,000 to her 15-year net worth by:
            <ul className={classes.savingsList}>
              <li>Graduating in four years,</li>
              <li>Earning a little more income,</li>
              <li>Using less student loans, and</li>
              <li>Repaying those loans on time.</li>
            </ul>
          </h3>
          <h3>Look at the huge difference in Lumen’s student loan snapshot. Almost $65,000 in savings.</h3>
          <div className={classNames(classes.lumensSahdowTable, classes.bracketTable)}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Savings</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Student Loans</td>
                  <td>$71,000	</td>
                  <td>$44,000</td>
                  <td className={classes.greenText}>$27,000</td>
                </tr>
                <tr>
                  <td><a href="/">Accrued Interest</a> during School</td>
                  <td>$11,240	</td>
                  <td>$5,830</td>
                  <td className={classes.greenText}>$5,410</td>
                </tr>
                <tr className={classNames(classes.boldText, classes.topBorder)}>
                  <td>Loan Balance after Graduation</td>
                  <td>$82,240	</td>
                  <td>$49,830</td>
                  <td className={classes.greenText}>$32,410</td>
                </tr>
                <tr>
                  <td>Monthly Payment</td>
                  <td>$524</td>
                  <td>$516</td>
                  <td className={classes.greenText}>$8</td>
                </tr>
                <tr>
                  <td>Total Interest Paid</td>
                  <td>$54,694</td>
                  <td>$17,871</td>
                  <td className={classes.greenText}>$36,823</td>
                </tr>
                <tr >
                  <td><strong>Total Repayment Amount <br />(Principal & Interest)</strong></td>
                  <td><strong>$125,694</strong></td>
                  <td><strong>$61,871</strong></td>
                  <td className={classes.greenText}><strong>$63,823</strong></td>
                </tr>
              </tbody>
            </table>
            <div className={classes.plusBracket}>
              <span className={classes.bracketImage}>
                <img src={bracketIcon} alt="tickmark" />
              </span>
              <span className={classes.plusIcon}>
                <img src={plusIcon} alt="tickmark" />
              </span>
            </div>
            <div className={classes.equalIcon}>
              <span >
                <img src={equalIcon} alt="tickmark" />
              </span>
            </div>
          </div>
        </div>
    )}
    {activeStep === 12 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Lumen&apos;s higher education</b></span>
          </h3>
          <h3>Graduating on time has added $47,559 to Lumen&apos;s net worth. Remember, net worth equals assets minus liabilities, which means there are two ways to increase net worth. Either Lumen can grow her assets or reduce her liabilities. Graduating on time reduced her student loans (a liability) and increased her net worth making Lumen financially stronger.</h3>
          <h3>Let&apos;s update her career moves now that she starts her career one year earlier and discover how Lumen grows her assets.</h3>
          <div className={classes.virtualBlocks}>
            <table className={classes.virtualTable}>
              <thead>
                <tr>
                  <th>Module Name</th>
                  <th>Lumen Before
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>1<span>.</span></div>
                    <img src={he} alt="he" />
                    Higher Education
                  </td>
                  <td>
                    Lumen graduates
                    <span>college in five years</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>2<span>.</span></div>
                    <img src={cp} alt="he" />
                    Career Path
                  </td>
                  <td>
                    2025 - 2026
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>3<span>.</span></div>
                    <img src={ca} alt="he" />
                    Career<br /> Advancement
                  </td>
                  <td>
                    2027 - 2030
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>4<span>.</span></div>
                    <img src={aca} alt="he" />
                    Additional Career<br /> Advancement
                  </td>
                  <td>
                    2031 - 2034
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>5<span>.</span></div>
                    <img src={invest} alt="he" />
                    Investments
                  </td>
                  <td>
                    Invests $5 per day
                    <span>starting in 2027</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>6<span>.</span></div>
                    <img src={home} alt="he" />
                    Buying a Home
                  </td>
                  <td>
                    Buys a $350k home
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>15-year Net Worth</td>
                  <td>$137,196 <i> <img src={arrowIcon} alt="arrow" /></i></td>
                </tr>
              </tfoot>
            </table>
            <table className={classNames(classes.virtualTable, classes.virtualRight)}>
              <thead>
                <tr>
                  <th>Lumen After</th>
                  <th>Net Worth Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen graduates on<span>time, or in four years</span></td>
                  <td className={classes.tableImage}>+$47,558
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Starts career one year<span>earlier in 2024</span></td>
                  <td>+$685</td>
                </tr>
                <tr>
                  <td>2026 - 2029</td>
                  <td>+6,628</td>
                </tr>
                <tr>
                  <td>2030 - 2034; adds an<span>extra year of income</span></td>
                  <td>+$52,352</td>
                </tr>
                <tr>
                  <td>Invests $10 per day<span>starting in 2024</span></td>
                  <td>+$5,087</td>
                </tr>
                <tr>
                  <td>Buys a $450k home</td>
                  <td className={classes.redText}>($1,944)</td>
                </tr>
              </tbody>
              <tfoot> <tr> <td>$247,563</td> <td>+$110,367</td> </tr> </tfoot>
            </table>
          </div>
        </div>
    )}
    {activeStep === 13 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Lumen&apos;s career starts on time</b></span>
          </h3>

          <h3>
            Now that Lumen graduates on time, her career path starts one year earlier. Lumen now starts her first career move in 2024 versus 2025.
          </h3>
          <h3>Instead of borrowing more student loans to pay for an extra year of college, Lumen has launched her career and started earning, spending, saving and investing. Just look at the dramatic change to her finances in year 2024 in the table below.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Year 2024</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>$443</td>
                  <td>$13,651</td>
                  <td className={classes.greenText}>$27,000</td>
                </tr>
                <tr>
                  <td>Cars and Real Estate</td>
                  <td>$0</td>
                  <td>$0</td>
                  <td>$0</td>
                </tr>
                <tr >
                  <td>Retirement Savings</td>
                  <td>$0</td>
                  <td>$5,668</td>
                  <td className={classes.greenText}>$5,668</td>
                </tr>
                <tr>
                  <td>Investments</td>
                  <td>$0</td>
                  <td>$0</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Total Loans</td>
                  <td className={classes.redText}>($80,620)</td>
                  <td className={classes.redText}>($47,830)	</td>
                  <td className={classes.greenText}>$32,790</td>
                </tr>
                <tr >
                  <td><strong>Net Worth</strong></td>
                  <td className={classes.redText}><strong>($80,177)</strong></td>
                  <td className={classes.redText}><strong>($28,068)</strong></td>
                  <td className={classes.greenText}><strong>$52,109</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    )}
    {activeStep === 14 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Reach your goals faster</b></span>
          </h3>
          <h3>Lumen&apos;s second career move also starts one year earlier. Update her career advancement start year to 2026. Lastly, we’ll update the additional career advancement start year.</h3>

          <h3>What&apos;s the change to her finances in year 2026? Look at the difference in total loans and net worth.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Year 2026</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>$24,270</td>
                  <td>$38,341</td>
                  <td className={classes.greenText}>$14,071</td>
                </tr>
                <tr>
                  <td>Cars and Real Estate</td>
                  <td>$0</td>
                  <td>$0</td>
                  <td>$0</td>
                </tr>
                <tr >
                  <td>Retirement Savings</td>
                  <td>$9,530	</td>
                  <td>$17,074</td>
                  <td className={classes.greenText}>$7,544</td>
                </tr>
                <tr>
                  <td>Investments</td>
                  <td>$0</td>
                  <td>$0</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Total Loans</td>
                  <td className={classes.redText}>($78,328)	</td>
                  <td className={classes.redText}>($39,368)	</td>
                  <td className={classes.greenText}>$38,960</td>
                </tr>
                <tr >
                  <td><strong>Net Worth</strong></td>
                  <td className={classes.redText}><strong>($44,528)</strong></td>
                  <td className={classes.redText}><strong>$16,046</strong></td>
                  <td className={classes.greenText}><strong>$60,574</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

    )}
    {activeStep === 15 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Add an extra year of income to your career sketch</b></span>
          </h3>
          <h3>When Lumen graduates on time, that means her final career move starts one year earlier. Stop and think about that for a second. Lumen just added an extra year of income in her life.</h3>

          <h3>Rather than spending additional time and money at school, Lumen is earning, spending, saving and investing in her career.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Year 2030</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>$15,000</td>
                  <td>$15,000</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Cars and Real Estate</td>
                  <td>$9,932	</td>
                  <td>$9,932	</td>
                  <td>$0</td>
                </tr>
                <tr >
                  <td>Retirement Savings</td>
                  <td>$44,398</td>
                  <td>$56,412</td>
                  <td className={classes.greenText}>$12,014</td>
                </tr>
                <tr>
                  <td>Investments</td>
                  <td>$31,515</td>
                  <td>$51,126</td>
                  <td>$19,611</td>
                </tr>
                <tr>
                  <td>Total Loans</td>
                  <td className={classes.redText}>($66,494)</td>
                  <td className={classes.redText}>($20,011)	</td>
                  <td className={classes.greenText}>$46,483</td>
                </tr>
                <tr >
                  <td><strong>Net Worth</strong></td>
                  <td ><strong>$34,351		</strong></td>
                  <td ><strong>$112,458	</strong></td>
                  <td className={classes.greenText}><strong>$78,107</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    )}
    {activeStep === 16 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Hitting targets saves precious time and puts money in your pocket</b></span>
          </h3>
          <h3>By graduating in four years and starting her career one year earlier,
            Lumen has added $107,224 to her 15-year net worth. <b>One simple move just added over $100,000 in wealth.</b> But we&apos;re not done updating her plan yet.</h3>
          <div className={classes.virtualBlocks}>
            <table className={classes.virtualTable}>
              <thead>
                <tr>
                  <th>Module Name</th>
                  <th>Lumen Before
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>1<span>.</span></div>
                    <img src={he} alt="he" />
                    Higher Education
                  </td>
                  <td>
                    Lumen graduates
                    <span>college in five years</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>2<span>.</span></div>
                    <img src={cp} alt="he" />
                    Career Path
                  </td>
                  <td>
                    2025 - 2026
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>3<span>.</span></div>
                    <img src={ca} alt="he" />
                    Career<br /> Advancement
                  </td>
                  <td>
                    2027 - 2030
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>4<span>.</span></div>
                    <img src={aca} alt="he" />
                    Additional Career<br /> Advancement
                  </td>
                  <td>
                    2031 - 2034
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>5<span>.</span></div>
                    <img src={invest} alt="he" />
                    Investments
                  </td>
                  <td>
                    Invests $5 per day
                    <span>starting in 2027</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>6<span>.</span></div>
                    <img src={home} alt="he" />
                    Buying a Home
                  </td>
                  <td>
                    Buys a $350k home
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                    <img src={arrowIcon} className={classes.alignImage} alt="arrow" />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>15-year Net Worth</td>
                  <td>$137,196 <i> <img src={arrowIcon} alt="arrow" /></i></td>
                </tr>
              </tfoot>
            </table>
            <table className={classNames(classes.virtualTable, classes.virtualRight)}>
              <thead>
                <tr>
                  <th>Lumen After</th>
                  <th>Net Worth Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen graduates on<span>time, or in four years</span></td>
                  <td className={classes.tableImage}>+$47,559
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Starts career one year<span>earlier in 2024</span></td>
                  <td className={classes.tableImage}>+$685
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>2026 - 2029</td>
                  <td className={classes.tableImage}>+6,628<img src={blueCircle} alt="arrow" /></td>
                </tr>
                <tr>
                  <td>2030 - 2034; adds an<span>extra year of income</span></td>
                  <td className={classes.tableImage}>+$52,352
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Invests $10 per day<span>starting in 2024</span></td>
                  <td>+$5,087</td>
                </tr>
                <tr>
                  <td>Buys a $450k home</td>
                  <td className={classes.redText}>($1,944)</td>
                </tr>
              </tbody>
              <tfoot> <tr> <td>$247,563</td> <td>+$110,367</td> </tr> </tfoot>
            </table>
          </div>
        </div>

    )}
    {activeStep === 17 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Small investments over time pay big dividends</b></span>
          </h3>
          <h3>Growing your savings as early as possible gives the power of compounding interest to go to work. Small amounts of money saved and invested over time is the foundation for financial freedom.</h3>
          <h3>What happens if Lumen starts investing $10 per day instead of $5 and starts three years earlier? Let&apos;s find out.</h3>
          <div className={classes.shadowImage}>
            <img src={spotLight} alt="arrow" />
          </div>
        </div>

    )}
    {activeStep === 18 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Just $5 more per day</b></span>
          </h3>
          <h3>Lumen jumpstarts her investing strategy in 2024 – three years ahead of schedule. She increases her monthly short-term contribution to $100 per month and her monthly long-term contribution to $200 per month.</h3>
          <h3>How does $5 more per day add up? Check out the graph below showing Lumen’s investments before and after the change. Notice the drop in year 2033? Lumen sells some of her investments and uses the cash to buy a home that year.</h3>
          <div className={classes.lumensSahdowTable}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Investments</th>
                  <th>2024</th>
                  <th>2027</th>
                  <th>2034</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumen Before</td>
                  <td>$0</td>
                  <td>$4,452</td>
                  <td>$16,569</td>
                </tr>
                <tr >
                  <td>Lumen After</td>
                  <td>$3,690</td>
                  <td>$29,707</td>
                  <td>$56,202</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    )}
    {activeStep === 19 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Save and invest for the major life events</b></span>
          </h3>
          <h3>Just by adding $5 more per day and investing earlier, Lumen added almost $5,000 to her 15-year net worth.</h3>
          <h3>Now this is the cool part. Lumen made all these changes to her plan, but for what? A great education that she earned on time? Yes, absolutely. A great career path? Of course. But what does that mean for Lumen long-term?</h3>
          <div className={classes.virtualBlocks}>
            <table className={classNames(classes.virtualTable, classes.virtualNewTable)}>
              <thead>
                <tr>
                  <th>Module Name</th>
                  <th>Lumen Before
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </th>
                  <th>Lumen After</th>
                  <th>Net Worth Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>

                    <img src={he} alt="he" />
                    Higher Education
                  </td>
                  <td>
                    Lumen graduates
                    <span>college in five years</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                  <td>Lumen graduates on<span>time, or in four years</span></td>
                  <td className={classes.tableImage}>+$45,641
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={cp} alt="he" />
                    Career Path
                  </td>
                  <td>
                    2025 - 2026
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                  <td>Starts career one year<span>earlier in 2024</span></td>
                  <td className={classes.tableImage}>+$777
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={ca} alt="he" />
                    Career<br /> Advancement
                  </td>
                  <td>
                    2027 - 2030
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                  <td>2026 - 2029</td>
                  <td className={classes.tableImage}>+$6,227<img src={blueCircle} alt="arrow" /></td>
                </tr>
                <tr>
                  <td>
                    <img src={aca} alt="he" />
                    Additional Career<br /> Advancement
                  </td>
                  <td>
                    2031 - 2034
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                  <td>2030 - 2034; adds an<span>extra year of income</span></td>
                  <td className={classes.tableImage}>+$52,352
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={invest} alt="he" />
                    Investments
                  </td>
                  <td>
                    Invests $5 per day
                    <span>starting in 2027</span>
                    <i> <img src={arrowIcon} alt="arrow" /></i>
                  </td>
                  <td>Invests $10 per day<span>starting in 2024</span></td>
                  <td className={classes.tableImage}>+$4,897
                    <img src={blueCircle} alt="arrow" />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>15-year Net Worth</td>
                  <td>$137,886 <i> <img src={arrowIcon} alt="arrow" /></i></td>
                  <td>$247,781</td>
                  <td>+$109,895</td>
                </tr>
              </tfoot>
            </table>

          </div>
        </div>
    )}
    {activeStep === 20 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Achieve financial freedom faster</b></span>
          </h3>
          <h3>Remember, one smart move can put $110,000 in your pocket. You just saw it in action. Being able to explore your future and build a roadmap to achieving your goals and dreams. Lumen’s net worth is now $247,563 at the age of 33. That’s higher than the median net worth of all age groups except 75+. Where will Lumen be at 67, which is retirement age? Let’s take a look.</h3>

          <div className={classes.shadowImage}>
            <img src={timelineNew} alt="arrow" />
          </div>
        </div>
    )}
    {activeStep === 21 && (
        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Add $1,000,000 or more to retirement</b></span>
          </h3>
          <h3>Remember, there are two ways to increase your net worth: increase your assets or decrease liabilities. In Lumen’s case, she has done both.</h3>
          <h3>Lumen has increased her total assets by roughly $57,500 and decreased her debt by more than $52,000. What if Lumen invests the extra $57,500?</h3>
          <div className={classNames(classes.lumensSahdowTable, classes.lsTable)}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Year 2034</th>
                  <th>Lumen Before</th>
                  <th>Lumen After</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>$14,023</td>
                  <td>$15,000	</td>
                  <td className={classes.greenText}>$977</td>
                </tr>
                <tr>
                  <td>Cars and Real Estate</td>
                  <td>$363,810	</td>
                  <td>$363,811</td>
                  <td>$0</td>
                </tr>
                <tr >
                  <td>Retirement Savings</td>
                  <td>$99,462</td>
                  <td>$116,447</td>
                  <td className={classes.greenText}>$16,985</td>
                </tr>
                <tr>
                  <td>Investments</td>
                  <td>$16,569	</td>
                  <td>$56,202</td>
                  <td>$39,633</td>
                </tr>
                <tr>
                  <td>Total Loans</td>
                  <td className={classes.redText}>($355,978)	</td>
                  <td className={classes.redText}>($303,679)</td>
                  <td className={classes.greenText}>$52,299</td>
                </tr>
                <tr >
                  <td><strong>Net Worth</strong></td>
                  <td className={classes.redText}><strong>$137,886	</strong></td>
                  <td className={classes.redText}><strong>$247,781</strong></td>
                  <td className={classes.greenText}><strong>$109,985</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

    )}
    {activeStep === 22 && (

        <div className={classes.mainContent}>
          <h3 className={classes.mainTitle}>
            <span><b>Add $1,000,000 or more to retiremen</b></span>
          </h3>
          <h3>Add $1,000,000 or more to retirement</h3>
          <h3>Let&apos;s make some assumptions. Lumen is going to:
            <ul className={classes.savingsList}>
              <li>Invest the extra $57,500,</li>
              <li>Contribute $195 per month, and</li>
              <li>Earn an annual return of 7%.</li>
            </ul>
          </h3>
          <h3 className={classes.grayBox}>
            That investment grows to $1,012,809 in 35 years. The simple move of graduating on time and adding $110,000 in net worth to her 15-year number. In reality, Lumen just added over $1,000,000 to her lifetime.
          </h3>
          <h3>That’s the power of career sketching. Many of the decisions you make today have predictable outcomes. Why leave your future up to chance?</h3>
          <img src={graph2} alt="graph" />
        </div>

    )}
      </div>
    
    <div className={classes.bottomButtons}>
      {activeStep !== 1 && (
        <button type="button" className={classes.backButton} onClick={() => { handleSlide(activeStep, "back") }}>Back</button>
      )}
      {activeStep !== 22 && (

        <button type="button" className={classes.nextButton} onClick={() => { handleSlide(activeStep, "next") }}>Next</button>
      )}
      {(activeStep === 22 && mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent !== 100) &&  (
        <button type="button" className={classes.nextButton} onClick={() => { handleSlide(activeStep, "save") }}>Publish</button>
      )}
       {(activeStep === 22 && mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent === 100) && (
        <button type="button" className={classes.nextButton} onClick={() => { history.push('/my-career-sketch/dashboard') }}>Go to dashboard</button>
      )}
    </div>
  </div>
    </Typography >
  );
};

McsPowerOfCareerSketching.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsPowerOfCareerSketching);