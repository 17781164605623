import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const RetirementAccounts = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Retirement Accounts</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/retirement.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Retirement Accounts
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/retirement-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The first step to thinking about retirement –
                          something that might be very far away – is realizing
                          that the best time to start planning is now. The
                          earlier you start planning and saving, the more time
                          there is for compounding interest to go to work for
                          you! Small amounts of money can grow into large
                          amounts of money over time. Remember Mike, Mary, and
                          Molly?
                        </p>
                      </div>
                    </div>

                    <div className={classes.accountGraph}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: 'spline',
                            scrollablePlotArea: {
                              width: 600,
                              scrollPositionX: 0,
                              height: 1000,
                            },
                          },
                          credits: {
                            enabled: false,
                          },
                          title: {
                            text: 'The power of compounding interest and time',
                            enabled: false,
                          },
                          exporting: {
                            enabled: false,
                          },
                          xAxis: [
                            {
                              tickInterval: 1,
                              categories: [
                                2021, 2222, 2023, 2024, 2025, 2026, 2027, 2028,
                                2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
                                2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
                                2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052,
                                2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060,
                              ],
                              labels: {
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                            },
                          ],
                          yAxis: [
                            {
                              // Primary yAxis
                              labels: {
                                formatter() {
                                  const chart = this;
                                  if (chart.value < 0) {
                                    return `<span style="color:#bf0000;">(${formatDollar(
                                      -Math.round(chart.value),
                                    )})</span>`;
                                  }
                                  return formatDollar(Math.round(chart.value));
                                },
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                              title: {
                                text: '',
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                            },
                          ],
                          tooltip: {
                            headerFormat:
                              '<span style="font-size:14px">{point.key}</span><br/>',
                            crosshairs: true,
                            shared: true,
                            valueDecimals: 0,
                            valuePrefix: '$',
                            style: {
                              color: '#000000',
                              fontSize: '13px',
                            },
                          },
                          plotOptions: {
                            spline: {
                              lineWidth: 2,
                              states: {
                                hover: {
                                  lineWidth: 2,
                                },
                              },
                              marker: {
                                enabled: false,
                              },
                            },
                          },
                          legend: {
                            itemStyle: {
                              fontSize: '14px',
                            },
                          },
                          series: [
                            {
                              name: 'Molly',
                              data: [
                                6199, 12780, 19766, 27184, 35059, 43420, 52297,
                                61721, 71727, 82349, 93627, 105600, 118312,
                                131808, 146136, 161348, 177499, 194645, 212849,
                                232176, 252694, 274478, 297606, 322161, 348229,
                                375906, 405290, 436486, 469606, 504769, 542100,
                                581735, 623813, 668487, 715917, 766272, 819732,
                                876490, 936749, 1000724,
                              ],
                              color: '#0069aa',
                            },
                            {
                              name: 'Mike',
                              data: [
                                5579, 11502, 17790, 24466, 31553, 39078, 47067,
                                55549, 64554, 74114, 84264, 95040, 100902,
                                107126, 113733, 120748, 128195, 136102, 144497,
                                153409, 162871, 172916, 183581, 194904, 206925,
                                219688, 233238, 247624, 262897, 279111, 296326,
                                314603, 334007, 354608, 376480, 399700, 424353,
                                450526, 478313, 507814,
                              ],
                              color: '#39b54a',
                            },
                            {
                              name: 'Mary',
                              data: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5579, 11502,
                                17790, 24466, 31553, 39078, 47067, 55549, 64554,
                                74114, 84264, 95040, 106481, 118627, 131523,
                                145214, 159749, 175180, 191564, 208958, 227425,
                                247031, 267846, 289945, 313407, 338316, 364761,
                                392837, 422645,
                              ],
                              color: '#ff0000',
                            },
                          ],
                        }}
                      />
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div className={classes.blueBox}>
                          <p>
                            Molly saves $500 per month from age 21–60 and earns
                            a 6% return.
                          </p>
                          <p>
                            <b>
                              Molly contributes a total of $240,000 and grows
                              her savings to $1,000,724!
                            </b>
                          </p>
                        </div>
                        <div className={classes.greenBox}>
                          <p>
                            Mike saves $450 per month from age 21–32 and then
                            stops. He earns a 6% return. Mike contributes a
                            total of $64,800 and grows his savings to $507,814!
                          </p>
                          <p>
                            <b>Mary never catches up to Mike.</b>
                          </p>
                        </div>
                        <div className={classes.redBox}>
                          <p>
                            Mary saves $450 per month from age 32–60 and earns a
                            6% return.
                          </p>
                          <p>
                            Mary contributes a total of $156,600 and grows her
                            savings to $422,645!
                          </p>
                        </div>
                        <p>
                          <span className={classes.diduKnow}>
                            DID YOU KNOW:
                          </span>{' '}
                          <a
                            href="https://www.forbes.com/sites/jamiehopkins/2014/08/28/not-enough-people-have-financial-advisers-and-new-research-shows-they-should/?sh=4f717b5952e5"
                            target="_blank"
                            rel="noopener noreferrer">
                            According to HSBC
                          </a>
                          , people with financial plans accumulated nearly 250%
                          more retirement savings than those without a plan.
                        </p>
                        <p>
                          <b>401(k)</b>
                          <br />A 401(k), or 403(b) if you work in education or
                          for a nonprofit, is a retirement plan sponsored by
                          your employer that lets you save and invest a portion
                          of your paycheck <b>before taxes</b> are taken out.
                          Contributing to a 401(k) lowers your taxes. For
                          example, if you earned $75,000 last year and invested
                          $10,000 in your 401(k), you&apos;d only have to pay
                          taxes on the remaining $65,000.
                        </p>
                        <p>
                          <i>
                            Although a 401(k) allows you to invest pre-tax
                            income now, you will have to pay taxes later when
                            you withdraw from your 401(k) in retirement.
                          </i>
                        </p>
                        <p>
                          One of the biggest advantages of a 401(k) is called
                          employer matching. Your employer will usually match
                          your contributions up to a certain limit. Let&apos;s
                          walk through an example. You earn $75,000 a year,
                          contribute 10% to your 401(k), and your employer
                          matches $0.50 for every $1.00 you invest up to a
                          maximum of 4% of your income.
                        </p>
                        <Table className={classes.retirementTable}>
                          <thead>
                            <tr>
                              <th>401(k) Example</th>
                              <th />
                              <th>Year 2019</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Salary</td>
                              <td />
                              <td>$75,000</td>
                            </tr>
                            <tr className={classes.maxContribution}>
                              <td colSpan="2">
                                <i>Max Personal Contribution by Law.</i>
                              </td>
                              <td>$19,000</td>
                            </tr>
                            <tr className={classes.noTopBorder}>
                              <td>
                                <b>Personal Contribution</b>
                              </td>
                              <td>
                                <b>10% of Salary</b>
                              </td>
                              <td>
                                <b>$7,500</b>
                              </td>
                            </tr>
                            <tr className={classes.employerMatch}>
                              <td>Employer Matching</td>
                              <td>50% of Personal Contribution</td>
                              <td>$3,750</td>
                            </tr>
                            <tr className={classes.employerMatch}>
                              <td>Employer Matching Limit</td>
                              <td>4% of Salary</td>
                              <td>$3,000</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Total Employer Contribution</b>
                              </td>
                              <td>
                                <b>Lesser of Employer Matching and Limit</b>
                              </td>
                              <td>
                                <b>$3,000</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Total 401(k) Contribution</b>
                              </td>
                              <td>
                                <b>14% of Salary</b>
                              </td>
                              <td>
                                <b>$10,500</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          Employer matching is a great benefit of a 401(k). Most
                          employers have a{' '}
                          <a
                            href="https://www.thebalance.com/what-does-it-mean-to-be-vested-in-my-401-k-2385773"
                            target="_blank"
                            rel="noopener noreferrer">
                            vesting schedule
                          </a>{' '}
                          that determines how much of your employer
                          contributions you can take with you when you leave. A
                          common vesting schedule is you own 50% of your
                          employer contributions after two years, 75% after
                          three years, and 100% after four years.
                        </p>
                        <p>
                          <b>Traditional Individual Retirement Account (IRA)</b>
                          <br />
                          Traditional IRAs can be used when your company
                          doesn&apos;t have a 401(k) program, or you want to
                          contribute more than the $19,000 max 401(k)
                          contribution limit. Similar to a 401(k), IRA
                          contributions are made <b>with pre-tax income</b>{' '}
                          (taxes are paid in retirement) and can be used to
                          invest in stocks, bonds, and funds. IRAs are not
                          sponsored by employers, which means there is no
                          employer matching. IRAs (which includes Roth IRAs)
                          have a max contribution limit of $6,000 per year by
                          law as of 2019. IRAs can be opened with many{' '}
                          <a
                            href="https://www.nerdwallet.com/best/investing/ira-accounts"
                            target="_blank"
                            rel="noopener noreferrer">
                            different financial institutions.
                          </a>
                        </p>
                        <p>
                          Both 401(k)s and traditional IRAs charge a 10% penalty
                          if you need to withdraw money before 59 ½ years of
                          age.
                        </p>
                        <p>
                          <b>Roth IRA</b>
                          <br />
                          Unlike a 401(k) and traditional IRA, Roth IRA
                          contributions are made with <b>after-tax income</b>.
                          When you go to withdraw money from your Roth IRA in
                          retirement, you pay no taxes on your contributions and
                          the gains you have made on your investments. This is
                          the main difference between a 401(k) and Roth IRA.
                        </p>
                        <p>
                          On the other hand, when you go to withdraw money from
                          your 401(k) in retirement, you will pay ordinary
                          income taxes on that money. As you learned in the
                          Income & Taxes level, that could be 30% taxes on your
                          401(k) withdrawals. That&apos;s the advantage of a
                          Roth IRA. Pay taxes now, but don&apos;t pay taxes
                          later in retirement.
                        </p>
                        <p>
                          This is not an employer sponsored plan and there is no
                          employer matching. The max limit by law is $6,000 per
                          year including your traditional IRA contributions as
                          of 2019.
                        </p>
                        <p>
                          <b>SEP IRA</b>
                          <br />
                          Like a 401(k), contributions to a SEP IRA are made
                          with <b>pre-tax income</b>. SEP IRAs are for
                          self-employed individuals like a real estate agent.
                          The annual contribution limits to a SEP IRA are much
                          higher than the other accounts at $56,000 or 25
                          percent of income, whichever is less, as of 2019.
                        </p>
                        <p>
                          <b>Pension</b>
                          <br />
                          Not as common today, a pension is a defined benefit
                          retirement plan that is used mostly for employees
                          working in public education, police, fire and
                          government. Exactly as it sounds, you get a defined
                          benefit when you retire, which is a specific amount of
                          income paid to you each year in retirement.
                        </p>
                        <p>
                          Pension investments are fully managed by employers.
                          Just like a 401(k), pension contributions are usually
                          made by both the employee and the employer. But for
                          each paycheck, the employer is almost always making a
                          larger contribution than the employee. That&apos;s one
                          of the reasons why pensions are disappearing because
                          pensions require more money from companies than a
                          401(k) program.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
RetirementAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RetirementAccounts);
