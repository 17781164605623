import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../../screens/pwi/higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import * as moduleServices from '../../../calculations/modules-services';
import { timeline, sortTimeLine } from '../../../calculations/time-line';

const YearsToGraduation = ( props ) => {
  const {
    classes, inputData, updateData, 
  } = props;

  const [dropDownOption, setDropDownOption] = React.useState({});
  const [startYear, setStartYear] = React.useState(0)
  const [yearDiff, setYearDiff] = React.useState(0);

  const [onCampusYear, setOnCampusYear] = React.useState(0);
  const [onCampusYearOptions, setOnCampusYearOptions] = React.useState([]);
  const [offCampusYear, setOffCampusYear] = React.useState(0);
  const [offCampusYearOptions, setOffCampusYearOptions] = React.useState([]);

  const updateTimeLine = (startYearVal, yearDiffVal) => {
    if(startYearVal > 0 && yearDiffVal > 0) {
      const graduateYear = startYearVal+yearDiffVal-1;
      let timelineData = [...timeline];
      const index = _.findIndex( timeline, { selectedModule: 'module2' } );
      if(index >= 0) {
        timelineData[index].startYear = startYearVal;
        timelineData[index].endYear = graduateYear;
        timelineData[index].imageSlug = 'active';
        timelineData[index].timelineOrder = startYearVal;
        sortTimeLine(timelineData);
      }
    }
  }


  const changeGraduationYears = (type, value) => {
    let inputDataVal = {...inputData};
    if(type === 'year') {
        if(inputData['room_board'] > 0) {
          inputDataVal['years_diff'] = value;
          switch(parseInt(value,10)) {
            case 1:
              inputDataVal['on_campus_year'] = 1;
              inputDataVal['off_campus_year'] = 0;
              break;
            case 2:
              inputDataVal['on_campus_year'] = 2;
              inputDataVal['off_campus_year'] = 0;
              break;
            case 3:
              inputDataVal['on_campus_year'] = 2;
              inputDataVal['off_campus_year'] = 1;
              break;
            case 4:
              inputDataVal['on_campus_year'] = 2;
              inputDataVal['off_campus_year'] = 2;
              break;
            case 5:
              inputDataVal['on_campus_year'] = 2;
              inputDataVal['off_campus_year'] = 3;
              break; 
            case 6:
              inputDataVal['on_campus_year'] = 2;
              inputDataVal['off_campus_year'] = 4;
              break; 
            default:
              break;
          }
          let years = [0];
          for (let i = 1; i <= parseInt(value,10); i+=1) {
              years.push(i);
          }
          inputDataVal['on_campus_years'] = years;
          inputDataVal['off_campus_years'] = years;
      } else {
        inputDataVal['years_diff'] = value;
        inputDataVal['on_campus_year'] = 0;
        inputDataVal['off_campus_year'] = parseInt(value,10);

        let years = [0];
        for (let i = 1; i <= parseInt(value,10); i++) {
            years.push(i);
        }
        inputDataVal['on_campus_years'] = [0];
        inputDataVal['off_campus_years'] = years;
      }
      updateTimeLine(inputDataVal['start_year'], inputDataVal['years_diff']);
    } else if(type === 'on') {
      if(inputData['room_board'] !== undefined && (inputData['room_board'] > 0 || inputData['type_of_higher_education'] === 4)) {
          inputDataVal['off_campus_year'] = yearDiff-value;    
          inputDataVal['on_campus_year'] = value;
      }
    } else if(type === 'off') {
      if(inputData['room_board'] !== undefined && (inputData['room_board'] > 0 || inputData['type_of_higher_education'] === 4)) {
          inputDataVal['on_campus_year'] = yearDiff-value;
          inputDataVal['off_campus_year'] = value;    
      }
    }
    setOnCampusYear(inputDataVal['on_campus_year']);
    setOnCampusYearOptions(inputDataVal['on_campus_years']);
    setOffCampusYear(inputDataVal['off_campus_year']);
    setOffCampusYearOptions(inputDataVal['off_campus_years']);

    updateData('total_obj',inputDataVal);
  }

  const setRangeYears =()=> {
    let dropDownData = {...dropDownOption};

    const rangeNumbers = [];
    const endYear = parseInt(moduleServices.module1Data.start_year)+14;
    let yearsDiff = endYear-parseInt(startYear)+1;

    if(yearsDiff > 6) {
        yearsDiff = 6;
    }
    for (let y = 1; y <= yearsDiff; y+=1) {
        rangeNumbers.push(y);
    }
    dropDownData['range'] = rangeNumbers; 
    setDropDownOption(dropDownData);

    if(yearDiff !== undefined  && parseInt(yearDiff,10) > 0) {
        let yearsDiffIndex = dropDownData['range'].indexOf(yearsDiff);
        if(yearsDiffIndex === -1) {
          const diffNewValue = dropDownData['range'][dropDownData['range'].length-1];
          setYearDiff(diffNewValue);
          changeGraduationYears('year',diffNewValue);
        }
    }

  }

  const changeDropDown = (e, inputLabel) => {
    let inputPropValues = {...inputData};
    inputPropValues[inputLabel] = parseInt(e.target.value, 10);
    let functionVariable = '';
    updateData('total_obj',inputPropValues);
    if(inputLabel === 'start_year') {
      setStartYear(inputPropValues[inputLabel])
    } else if(inputLabel === 'years_diff') {
      setYearDiff(inputPropValues[inputLabel])
      functionVariable = 'year';
    } else if(inputLabel === 'on_campus_year') {
      setOnCampusYear(inputPropValues[inputLabel])
      functionVariable = 'on';
    } else if(inputLabel === 'off_campus_year') {
      setOffCampusYear(inputPropValues[inputLabel])
      functionVariable = 'off';
    }
    changeGraduationYears(functionVariable,inputPropValues[inputLabel]);
  } 

  useEffect(()=>{
    setRangeYears();
  },[startYear])

  useEffect(()=>{
    const startYear = moduleServices.module1Data.start_year;
    let dropDownData = {...dropDownOption};
     if(startYear !== '' && parseInt(startYear) > 0) {
       const yearsList = [];
        for (var i = 0; i <= 14; i+=1) {
            yearsList.push(startYear+i);
        }
        dropDownData['startYearOptions'] = yearsList;
        setDropDownOption(dropDownData);
     }

     let inputPropValues = {...inputData};
     if(inputPropValues['start_year'] !== undefined && inputPropValues['start_year'] !== '' && inputPropValues['start_year']>0) {
       setStartYear(parseInt(inputPropValues['start_year']));
     } else {
        setStartYear(parseInt(moduleServices.module1Data.start_year));
        inputPropValues['start_year'] = moduleServices.module1Data.start_year;
     }
     if(inputPropValues['years_diff'] !== undefined && inputPropValues['years_diff'] !== '' && inputPropValues['years_diff'] > 0) {
       setYearDiff(parseInt(inputPropValues['years_diff']));
     } else {
       if(inputPropValues['room_board'] !== undefined && inputPropValues['room_board'] > 0) {
         if(inputPropValues['years_diff'] === undefined) {
          inputPropValues['years_diff'] = 4;
          inputPropValues['on_campus_years'] = [0,1,2,3,4];
          inputPropValues['on_campus_year'] = 2;
          inputPropValues['off_campus_years'] = [0,1,2,3,4];
          inputPropValues['off_campus_year'] = 2;
         }
       } else {
         if( inputPropValues['years_diff'] !== undefined && inputPropValues['years_diff'] > 0) {
           if(inputPropValues['room_board'] <= 0 && inputData.type_of_higher_education === 4) {
              inputPropValues['off_campus_year'] = 0;
              inputPropValues['off_campus_years'] = [0];
              const onCampusYearsList = [0];
              for(let on=1;on<=parseInt(inputPropValues['years_diff']);on+=1) {
                onCampusYearsList.push(on)
              }

              inputPropValues['on_campus_years'] = onCampusYearsList;
              inputPropValues['on_campus_year'] = 0;

           }
         } else {
            if(inputData.type_of_higher_education === 1 || inputData.type_of_higher_education === 3) {
                inputPropValues['years_diff'] = 2;
            } else {
                inputPropValues['years_diff'] = 4;
            }
            if(inputData.type_of_higher_education === 4) {
              inputPropValues['on_campus_years'] = [0];
              inputPropValues['on_campus_year'] = inputPropValues['years_diff'];
              inputPropValues['off_campus_year'] = 0;
              inputPropValues['off_campus_years'] = [0]
              for(let i=1;i<=parseInt(inputPropValues['years_diff']);i+=1) {
                inputPropValues['on_campus_years'].push(i);
                inputPropValues['off_campus_years'].push(i);
              }
            } else {
              inputPropValues['on_campus_years'] = [0];
              inputPropValues['on_campus_year'] = 0;
              inputPropValues['off_campus_year'] = inputPropValues['years_diff'];
              inputPropValues.off_campus_years = [0];
              for(let i=1;i<=parseInt(inputPropValues['years_diff']);i+=1) {
                  inputPropValues['off_campus_years'].push(i);    
              }
            }
         }
       }
     }
    updateTimeLine(inputPropValues['start_year'], inputPropValues['years_diff']);
    setYearDiff(inputPropValues['years_diff'])
    setOnCampusYear(inputPropValues['on_campus_year']);
    setOnCampusYearOptions(inputPropValues['on_campus_years']);
    setOffCampusYear(inputPropValues['off_campus_year']);
    setOffCampusYearOptions(inputPropValues['off_campus_years']);     
    updateData('total_obj',inputPropValues);
  },[])
  
  return (
    <div className={ classes.contentBlock }>
      {/*Career School*/}
      {
        (inputData.type_of_higher_education === 1) && (<p>Career school takes an average of two years to complete. Graduating on time can save you tens of thousands of dollars.</p>)
      }
      {/*College*/}
      {
        (inputData.type_of_higher_education === 2) && (
          <p>
            Graduating on time will save you tens of thousands of dollars. How much exactly? An extra year of college could cost you another $68,000, according to<a  href="https://completecollege.org/article/new-report-4-year-degrees-now-a-myth-in-american-higher-education/" target="_blank" rel="noopener noreferrer"><u>Complete College America</u></a><u className={ classes.spaceLeft }>.</u> That figure includes lost income that could have been earned working in your career.
          </p>
        )
      }
      
      {/*Graduate School*/}
      {
        (inputData.type_of_higher_education === 3) && (<p>Graduating on time will save you tens of thousands of dollars. How much exactly? An extra year of tuition, fees, books and supplies, plus lost income that could have been earned working in your career.</p>)
      }

      {/*Military School*/}
      {
        (inputData.type_of_higher_education === 4) && (<p>Graduating on time will save you tens of thousands of dollars. The first step is developing a solid plan.</p>)
      }

      <div className={ `${classes.grayBox} ${classes.grayBoxYearGradu}` }>
        <p>How many years until you graduate? If you have not started your education yet, enter the number of years to graduate on time. If you are currently in school, enter the number of years until you graduate.</p>
        <ul className={ classes.yearOfGradutionList }>
          <li>
            {/*Career School*/}
            {
              (inputData.type_of_higher_education === 1) && (<span>I plan to start career school in year</span>)
            }
            {/*College*/}
            {
              (inputData.type_of_higher_education === 2) && (
                <span>
                  I plan to start college in year
                </span>
              )
            }
            
            {/*Graduate School*/}
            {
              (inputData.type_of_higher_education === 3) && (<span>I plan to start graduate school in year</span>)
            }

            {/*Military School*/}
            {
              (inputData.type_of_higher_education === 4) && (<span>I plan to start career school in year</span>)
            }
            {' '}&nbsp;

            <select value={ startYear } onChange={ (e)=>changeDropDown(e, 'start_year') }>
              {dropDownOption.startYearOptions !== undefined && dropDownOption.startYearOptions.map( ( year ) => <option value={ year }>{year}</option> )}
            </select>
            <img src={ infoIcon } alt="" data-for="compareSchool" data-tip="If you are already in higher education, select today's year in the drop down." />
          </li>
          <li>
            <span>I plan to graduate in&nbsp;</span>
                {' '}
                <select value={ yearDiff } onChange={ (e)=>changeDropDown(e, 'years_diff') }>
                  {dropDownOption.range !== undefined && dropDownOption.range.map( ( range ) => <option value={ range }>{range}</option> )}
                </select>
                {' '}
                <span>
                  {' '}
                  &nbsp;
                  {' '}
                  years.
                </span>
          </li>
          {( inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 ) && (
            <li>
              <span>I plan to live on campus for the first&nbsp;</span>
              {' '}
              <select value={ onCampusYear } onChange={ (e)=>changeDropDown(e, 'on_campus_year') } disabled={onCampusYearOptions && onCampusYearOptions.length <= 1}>
                {onCampusYearOptions && onCampusYearOptions.map( ( value ) => <option value={ value }>{value}</option> )}
              </select>

              <span>
                {' '}
                &nbsp;
                {' '}
                years.
              </span>
              <img src={ infoIcon } alt="" data-for="compareSchool" data-tip="If room and board equals $0, we assume that you don't plan on living on campus unless you're planning on attending military school." />
            </li>
            )}
            {( inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 ) && (
              <li>
                <span>
                  I plan to live off campus for the last&nbsp;
                  {' '}
                </span>
                {' '}
                <select value={ offCampusYear } onChange={ (e)=>changeDropDown(e, 'off_campus_year') } disabled={onCampusYearOptions && onCampusYearOptions.length <= 1}>
                  {offCampusYearOptions && offCampusYearOptions.map( ( value ) => <option value={ value }>{value}</option> )}
                </select>

                <span>
                  {' '}
                  &nbsp;
                  {' '}
                  years.
                </span>
              </li>
              )}
        </ul>

      </div>
      
      <CareerSketchTimeline moduleName="module2"/>

      <ReactTooltip id="compareSchool" place="top" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.tooltipInfoTopSmall}` } />

    </div>
  );
};

YearsToGraduation.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData:PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( YearsToGraduation );
