const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: theme.palette.common.pureBlack,
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  netWorthSection: {
    minHeight: 'calc(100vh - 101px)',
    width: '68%',
    margin: '0 auto',
    paddingBottom: '20px',
    boxSizing: 'border-box',   
    [theme.breakpoints.down( 'sm' )]: {
      width: '90%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  showMeContent: {
    padding: '0 35px',
    background: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 15px',
    },
  },
  showPortion: {
    padding: '20px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0px',
    },
  },
  nwSectionRow: {
    margin: '0px -15px',
  },
  netWorthContent: {
    padding: '0px 25px',
  },
  mainRow: {
    margin: '20px -15px',
    border: '2px solid #0069aa',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
      margin: '0px -15px',
    },
  },
  leftPanel: {
    background: '#e7f1f9',
    borderRight: '2px solid #0069aa',
    display: 'block',
    width: '50%',
    padding: '10px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      borderRight: '0px',
    },
    '& table': {
      width: '100%',
      marginBottom: '0px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr': {
        display: 'table-row',
        '& td': {
          fontSize: '17px',
          padding: '0px',
          paddingRight: '10px',
          color: theme.palette.common.pureBlack,
          '& > div': {
            '& div::after,& div::before': {
              left: 'auto',
              borderBottom: 'none !important',
            },
            width: '100%',
            '& input': {
              padding: '0 5px 0px 20px',
              height: '45px',
              maxWidth: '100%',
              marginLeft: '0',
              width: '100%',
              border: 'none',
              fontFamily: 'inherit',
              fontSize: '17px',
              fontWeight: '700 !important',
              lineHeight: 'inherit',
              margin: '0',
              font: 'inherit',
              color: 'inherit',
              boxSizing: 'border-box',
              background: '#fff',
              '&:focus': {
                outline: 'none',
              },
            },
          },
        },
      },
    },
  },
  dollarField: {
    background: '0 0',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    marginBottom: '10px',
    fontWeight: '700',
    padding: '5px 5px 5px 2px',
    color: '#000',
    wordBreak: 'break-all',
    '& span': {
      position: 'absolute',
      left: '9px',
      top: '10px',
      zIndex: '9',
      [theme.breakpoints.down( 'xs' )]: {
        top: '11px',        
      },  
    },
  },
  percentSym: {
    position: 'absolute',
    left: 'auto !important',
    right: '20px',
    textAlign: 'right',
    color: 'inherit',
    fontWeight: 'inherit',
    marginTop: '0px',
    fontFamily: '"MuseoSans-300"',
    paddingLeft: '5px',
    fontSize: '13px',
    top: 'auto !important',
  },
  inputValue: {
    '& span': {
      '&:first-child': {
        left: '0px',
      },
      '&:last-child': {
        // height: '45px',
        maxWidth: '100%',
        marginLeft: '0',
        paddingLeft: '3px',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        color: '#333',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
  },
  noInput: {
    '& input': {
      paddingLeft: '7px !important',
      fontWeight: '700 !important',
    },
  },
  percentField: {
    '& input': {
      paddingLeft: '7px !important',
    },
  },
} );

export default styles;
