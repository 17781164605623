import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StandardItemizedDeductions = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Standard or Itemized Deductions</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tax-deductions.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Standard or Itemized Deductions
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tax-deductions-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          When the IRS calculates your taxes, they start with
                          gross income, which is your total earned income. Then,
                          they subtract any adjustments to your income such as
                          pre-tax retirement contributions or qualified student
                          loan interest. Gross income minus adjustments equals
                          adjusted gross income. Now comes the question. Do you
                          take the standard deduction or itemized deductions to
                          arrive at your federal taxable income?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The standard deduction is a portion of your income
                          that is not subject to federal income taxes. The
                          standard deduction changes year by year and typically
                          rises with{' '}
                          <Link
                            to="/finance-explained?type=definitions"
                            target="_blank">
                            inflation
                          </Link>
                          . For example, in 2019 the standard deduction was
                          $12,200 and in 2020 that increased to $12,400. That
                          means $12,400 of your 2020 income is not automatically
                          subject to federal income taxes.
                        </p>
                        <ul>
                          <li>
                            Standard deduction if filing your taxes as single:
                            $12,400
                          </li>
                          <li>
                            Standard deduction if filing your taxes as married:
                            $24,800.
                          </li>
                        </ul>
                        <p>
                          But, when it comes to filing your taxes, you have a
                          choice. Take the standard or itemized? Once you
                          understand the two, there&apos;s an obvious choice.
                        </p>
                        <p>
                          Itemized deductions are specific expenses that reduce
                          your federal taxable income. If these expenses are
                          greater than the standard deduction, it&apos;s an easy
                          choice. If itemized deductions are greater than the
                          standard deduction, then you should choose to itemize
                          your deductions. Since both options lower your federal
                          taxable income, and you have to choose between the
                          two, the larger deduction leads to lower taxes.
                        </p>
                        <p className={classes.noBottomMargin}>
                          The most common itemized deductions are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Interest on your home mortgage</li>
                          <li>Private mortgage insurance</li>
                          <li>
                            Property taxes on your home and state and local
                            income taxes up to $10,000
                          </li>
                          <li>
                            Health care costs that exceed 10% of your adjusted
                            gross income
                          </li>
                        </ul>
                        <p>
                          When it comes to itemizing deductions, there are
                          limitations and rules. A tax professional or tax
                          software will automate these calculations for you when
                          preparing your taxes.
                        </p>
                        <p>
                          If itemized deductions are greater than the standard
                          deduction, choose to itemize. If not, choose the
                          standard deduction. That way, you maximize your
                          deduction and lower your federal taxable income.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StandardItemizedDeductions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StandardItemizedDeductions);
