import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const BondProfit = ( props ) => {
  const { classes } = props;

  const bondProfitDefaultValues = {
    numberBonds: 1,
    valueBond: 1000,
    maturity: 10,
    couponRate: 7.50,
    IncTaxRate: 30.00,
    buyingFeesBond: 5,
  };

  const [bondProfitValues, setBondProfitValues] = useState( bondProfitDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...bondProfitValues };
    if ( field === 'couponRate' || field === 'IncTaxRate' ) {
      newValue = ( e.value !== undefined && e.value !== '' ) ? e.value : 0;
    } else {
      newValue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    valuesUpdated[field] = newValue;
    setBondProfitValues( valuesUpdated );
  };

  const setFixedDecimal = ( field ) => {
    const valuesUpdated = { ...bondProfitValues };
    valuesUpdated[field] = parseFloat( valuesUpdated[field] ).toFixed( 2 );
    setBondProfitValues( valuesUpdated );
  };

  useEffect( () => {
    const bondProfit = {};
    bondProfit.totInvestment = bondProfitValues.numberBonds * bondProfitValues.valueBond;
    bondProfit.yrInterEarned = ( bondProfit.totInvestment * bondProfitValues.couponRate ) / 100;
    bondProfit.totIntrEarned = bondProfit.yrInterEarned * bondProfitValues.maturity;
    bondProfit.taxIntrErnd = ( bondProfit.totIntrEarned * bondProfitValues.IncTaxRate ) / 100;
    bondProfit.totBuyingFee = bondProfitValues.numberBonds * bondProfitValues.buyingFeesBond;
    bondProfit.profitCouPayment = bondProfit.totIntrEarned - bondProfit.taxIntrErnd - bondProfit.totBuyingFee;
    bondProfit.finalData = {};
    bondProfit.finalData.Year = [];
    bondProfit.finalData.Value = [];
    bondProfit.finalData.CouponRate = [];
    bondProfit.finalData.InterestEarned = [];
    bondProfit.finalData.Taxs = [];
    bondProfit.finalData.IncomeAfterTaxes = [];
    bondProfit.finalData.afterTaxReturn = [];

    let yearValue = 0;
    let valueValue = 0;
    let couponRate = 0;
    let interestEarned = 0;
    let taxesValue = 0;
    let incomeAfterTaxes = 0;
    let afterTaxReturn = 0;
    let totalIncomeAfterTaxes = 0;

    for ( let i = 1; i <= bondProfitValues.maturity; i += 1 ) {
      if ( i === 1 ) {
        yearValue = 1;
        valueValue = bondProfit.totInvestment;
        // eslint-disable-next-line
        couponRate = bondProfitValues.couponRate;
        interestEarned = ( bondProfit.totInvestment / 100 ) * bondProfitValues.couponRate;
        taxesValue = ( interestEarned / 100 ) * bondProfitValues.IncTaxRate;
        incomeAfterTaxes = interestEarned - taxesValue;
        totalIncomeAfterTaxes += incomeAfterTaxes;
      } else {
        if ( ( yearValue + 1 ) > bondProfitValues.maturity ) {
          yearValue = 0;
        } else {
          yearValue += 1;
        }
        if ( yearValue === 0 ) {
          valueValue = 0;
          couponRate = 0;
          interestEarned = 0;
          taxesValue = 0;
          incomeAfterTaxes = 0;
          totalIncomeAfterTaxes += incomeAfterTaxes;
        } else {
          // eslint-disable-next-line
          valueValue = bondProfit.totInvestment;
          // eslint-disable-next-line
          couponRate = bondProfitValues.couponRate;
          interestEarned = ( bondProfit.totInvestment / 100 ) * bondProfitValues.couponRate;
          taxesValue = ( interestEarned / 100 ) * bondProfitValues.IncTaxRate;
          incomeAfterTaxes = interestEarned - taxesValue;
          totalIncomeAfterTaxes += incomeAfterTaxes;
        }
      }
      if ( incomeAfterTaxes !== 0 ) {
        afterTaxReturn = ( incomeAfterTaxes / valueValue ) * 100;
      }
      bondProfit.finalData.Year.push( yearValue );
      bondProfit.finalData.Value.push( valueValue );
      bondProfit.finalData.CouponRate.push( couponRate );
      bondProfit.finalData.InterestEarned.push( interestEarned );
      bondProfit.finalData.Taxs.push( taxesValue );
      bondProfit.finalData.IncomeAfterTaxes.push( incomeAfterTaxes );
      bondProfit.totalIncomeTaxes = totalIncomeAfterTaxes;
      bondProfit.finalData.afterTaxReturn.push( afterTaxReturn );
    }
    setCalculatedValues( bondProfit );
    // eslint-disable-next-line
  },[bondProfitValues] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>
              <span> &gt; </span>
              <li>Bond Profit</li>
            </ul>
            <div className={ classes.bondProfitCalculator }>
              <div className={ classes.bondProfitSection }>
                <div className={ classes.showMeContent }>
                  <div className={ classes.showMePortion }>
                    <div className={ classes.mainRow }>
                      <div className={ classes.showSection }>
                        <div className={ classes.mainRowNew }>
                          <div className={ classes.leftPanel }>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Number of Bonds</td>
                                </tr>
                                <tr>
                                  <td className={ classes.fieldData }>
                                    <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ bondProfitValues.numberBonds } onValueChange={ ( e ) => updateValue( e, 'numberBonds' ) } onFocus={ ( e ) => e.target.select() } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Value for 1 Bond</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.fieldData, classes.dollarField ) }>
                                    <span className={ classes.dollarSymbol }>$</span>
                                    <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ bondProfitValues.valueBond } onValueChange={ ( e ) => updateValue( e, 'valueBond' ) } onFocus={ ( e ) => e.target.select() } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Maturity</td>
                                </tr>
                                <tr>
                                  <td className={ classes.fieldData }>
                                    <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ bondProfitValues.maturity } onValueChange={ ( e ) => updateValue( e, 'maturity' ) } onFocus={ ( e ) => e.target.select() } />
                                    <span className={ classes.yearSymbol }>years</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Coupon (Interest) Rate</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.fieldData, classes.percentField ) }>
                                    <div className={ classes.inputData }>
                                      <NumberFormat
                                        allowNegative={ false }
                                        customInput={ TextField }
                                        value={ bondProfitValues.couponRate }
                                        onValueChange={ ( e ) => updateValue( e, 'couponRate' ) }
                                        onFocus={ ( e ) => e.target.select() }
                                        onBlur={ () => { setFixedDecimal( 'couponRate' ); } }
                                      />
                                      <span>%</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Income Tax Rate</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.fieldData, classes.percentField ) }>
                                    <div className={ classes.inputData }>
                                      <NumberFormat
                                        allowNegative={ false }
                                        customInput={ TextField }
                                        value={ bondProfitValues.IncTaxRate }
                                        onValueChange={ ( e ) => updateValue( e, 'IncTaxRate' ) }
                                        onFocus={ ( e ) => e.target.select() }
                                        onBlur={ () => { setFixedDecimal( 'IncTaxRate' ); } }
                                      />
                                      <span>%</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Buying Fees for 1 Bond</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.fieldData, classes.dollarField ) }>
                                    <span className={ classes.dollarSymbol }>$</span>
                                    <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ bondProfitValues.buyingFeesBond } onValueChange={ ( e ) => updateValue( e, 'buyingFeesBond' ) } onFocus={ ( e ) => e.target.select() } />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className={ classes.leftPanel }>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total Investment</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totInvestment !== undefined ? calculatedValues.totInvestment : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Yearly Interest Earned</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.yrInterEarned !== undefined ? calculatedValues.yrInterEarned : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Interest Earned</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totIntrEarned !== undefined ? calculatedValues.totIntrEarned : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Taxes on Interest Earned</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.taxIntrErnd !== undefined ? calculatedValues.taxIntrErnd : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Buying Fees</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totBuyingFee !== undefined ? calculatedValues.totBuyingFee : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Profit (Coupon Payments - Taxes - Fees)</td>
                                </tr>
                                <tr>
                                  <td className={ classes.inputField }>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.profitCouPayment !== undefined ? calculatedValues.profitCouPayment : 0 } displayType="text" prefix="$" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className={ classes.rightPanel }>
                            <div className={ classes.mainRow }>
                              <div className={ classes.rightSection }>
                                <div className={ classes.bondProfitTable }>
                                  <table>
                                    <thead>
                                      <tr>
                                        <td>Year</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.Year.map( ( yearVal ) => (
                                            <td>{yearVal}</td>
                                          ) )
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Value</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.Value.map( ( valueVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ valueVal } displayType="text" prefix="$" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                      <tr>
                                        <td>Coupon Rate</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.CouponRate.map( ( couponRateVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ couponRateVal } displayType="text" suffix="%" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                      <tr>
                                        <td>Interest Earned</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.InterestEarned.map( ( InterestEarnedVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ InterestEarnedVal } displayType="text" prefix="$" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                      <tr>
                                        <td>Taxes (30.00%)</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.Taxs.map( ( TaxsVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ TaxsVal } displayType="text" prefix="$" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                      <tr className={ classes.incomeAfterTax }>
                                        <td>Income after Taxes</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.IncomeAfterTaxes.map( ( IncomeAfterTaxesVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ IncomeAfterTaxesVal } displayType="text" prefix="$" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>After-Tax Return</td>
                                        {
                                          calculatedValues.finalData !== undefined && calculatedValues.finalData.afterTaxReturn.map( ( afterTaxReturnVal ) => (
                                            <td>
                                              <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ afterTaxReturnVal } displayType="text" suffix="%" />
                                            </td>
                                          ) )
                                        }
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                                <table className={ classes.totalIncomeTable }>
                                  <tbody>
                                    <tr>
                                      <td>Total Income after Taxes </td>
                                      <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalIncomeTaxes !== undefined ? calculatedValues.totalIncomeTaxes : 0 } displayType="text" prefix="$" /></td>
                                    </tr>
                                    <tr>
                                      <td>Total Buying Fees</td>
                                      <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totBuyingFee !== undefined ? calculatedValues.totBuyingFee : 0 } displayType="text" prefix="$" /></td>
                                    </tr>
                                    <tr>
                                      <td>Profit</td>
                                      <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.profitCouPayment !== undefined ? ( calculatedValues.totalIncomeTaxes - calculatedValues.totBuyingFee ) : 0 } displayType="text" prefix="$" /></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

BondProfit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( BondProfit );
