import React from 'react'
import {Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import cloudInfo from '../../assets/img/terms-security/cf-logo-v-rgb.png';
import stripeInfo from '../../assets/img/terms-security/stripe-logo- slate-sm.png';
import cloudflareInfo from '../../assets/img/terms-security/logo-cloudflare-new.png';
import rustedTree from '../../assets/img/terms-security/trustedsite.png';

const Security = (props) => {
	
    const { classes } = props;    

    return (
			<div className={ classes.containerRow }>
        <div className={ classes.container }>
					<Grid container spacing={ 2 }>
						<Grid item xs={ 12 } sm={ 3 }>
							<div className={ classes.readmoreLftCnt }>
								<ul>
										<li><Link to="/termsofservice">Terms Of Services</Link></li>
										<li><Link to="/privacy">Privacy Policy</Link></li>
										<li><Link to="/security" className={ classes.active }>Security</Link></li>
									</ul>
							</div>
            </Grid>
						<Grid item xs={ 12 } sm={ 9 }>
							<div className={ classes.securityRgt }>
								<Typography variant="h2" component="h2">Security</Typography>
                <div>
                    <p>At Inkwiry, we take protecting your personal information seriously. That's why we use industry-leading online security practices to protect your personal information and make sure that information is only accessible by you.</p>
                    <p>Inkwiry uses <a target="blank" href="https://aws.amazon.com/" className={ classes.textBlue}><u>Amazon Web Services' (AWS)</u></a>, virtual servers in the cloud to run our application and provide our services to you. The AWS infrastructure's safeguards help to protect your privacy. All data is stored in AWS' data centers. To read more about AWS' security measures, click <a target="blank" href="https://aws.amazon.com/security/" className={ classes.textBlue}><u>here</u></a>.</p>
                    <p><b>Remember, the data that runs Inkwiry is mostly hypothetical, forward-looking information. We do not link to your bank accounts or any other personal financial accounts. No one can access your money via Inkwiry, including you.</b></p>
                    <p>Inkwiry uses the following security measures to protect your privacy and keep your data secure.</p>
                    <p><em>Supported up-to-date browsers: Chrome, Mozilla, Safari, Internet Explorer, Microsoft Edge</em></p>
                </div>
                <div className={ classes.comdoLogo}>
                    <div className={ classes.comdoLogoDiv}>
											<figure><img src={stripeInfo} alt=""/></figure>
											<p><a target="blank" href="https://stripe.com/" className={ classes.textBlue }><u>Stripe</u></a> handles billions of dollars every year for businesses around the world. Stripe has been audited by a <a target="blank" href="https://stripe.com/docs/security/stripe" className={ classes.textBlue }><u>PCI-certified auditor and is certified to PCI Service Provider Level 1</u></a>. This is the most stringent level of certification available in the payments industry.</p> 
                    </div>
                    <div className={ classes.comdoLogoDiv}>
                        <figure><img src={cloudInfo} alt=""/></figure>
                        <p>
                            <span><a target="blank" href="https://www.cloudflare.com/ssl/" className={ classes.textBlue }><u>SSL</u></a>, which stands for "Secure Sockets Layer", is the standard security technology for establishing an encrypted link between a web server and browser(s). Using SSL ensures that all communication between you and Inkwiry is encrypted and kept private. SSL is used by millions of websites worldwide to protect data that's transmitted online through websites.</span>
                            <span>Inkwiry's SSL is provided by <a target="blank" href="https://www.cloudflare.com/ssl/" className={ classes.textBlue }><u>Cloudflare</u></a>, an industry leader in internet data security.</span>
                        </p>
                    </div>
										<div className={ classes.comdoLogoDiv}>
                        <figure><img src={cloudflareInfo} alt=""/></figure>
                        <p>
                            <span><a target="blank" href="https://www.cloudflare.com/ssl/" className={ classes.textBlue }><u>Cloudflare</u></a> protects Inkwiry from DDoS attacks, which stands for "Distributed Denial of Service". A DDoS attack attempts to make a web application unavailable by flooding the site with traffic from multiple sources. By integrating DDoS protection via Cloudflare, Inkwiry's security measures protect your user experience by blocking attacks and work to ensure that the site is always accessible.</span>
                            <span><a target="blank" href="https://www.cloudflare.com/ssl/" className={ classes.textBlue }><u>Cloudflare</u></a> also protects Inkwiry by using a custom WAF, which stands for "Web Application Firewall". Cloudflare's WAF blocks hacking attacks with great accuracy keeping your personal information safer. Cloudflare serves over 7,000,000 domains across the globe enabling them to continually identify and help block new potential threats.</span>
                        </p>
                    </div>
										<div className={ classes.comdoLogoDiv}>
                      <figure><img src={rustedTree} alt="" /></figure>
											<p><a target="blank" href="https://usa.kaspersky.com/resource-center/preemptive-safety/what-is-malware-and-how-to-protect-against-it" className={ classes.textBlue }><u>Malware</u></a>, which is short for "malicious software", is a type of computer program designed to infect and inflict harm, such as stealing personal information, to a person's computer. <a target="blank" href="https://www.mcafeesecure.com/for-websites" className={ classes.textBlue }><u>McAfee Secure</u></a>scans and tests Inkwiry daily with strict security network controls checking for malware. The McAfee Secure logo confirms Inkwiry has passed its daily malware inspection.</p>
                		</div>
									</div>
								</div>
							</Grid>
						</Grid>
				</div>
      </div>
    )
}

export default withStyles(styles)(Security)

