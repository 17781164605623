import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import NumberFormat from 'react-number-format';
import Slider from 'react-slick';
import Select from 'react-select';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import HCExporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styles from './styles';
import * as heGraphData from '../../graph-services/he-show-me';
import sliderOne from '../../assets/img/higher-education/higher-education-slide1_new.jpg';
import sliderTwo from '../../assets/img/higher-education/higher-education-slide2.jpg';
import sliderThree from '../../assets/img/higher-education/higher-education-slide3.jpg';
import sliderFour from '../../assets/img/higher-education/higher-education-slide4.jpg';
import sliderFive from '../../assets/img/higher-education/higher-education-slide5.jpg';
import sliderSix from '../../assets/img/higher-education/higher-education-slide6.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import heImage from '../../assets/img/higher-education/Higher_Education_Image.png';
import yearRepay from '../../assets/img/higher-education/year_repayment_begins.png';
import higherImage from '../../assets/img/higher-education/HigherEducationPayback.png';
import accruedImg from '../../assets/img/higher-education/Accrued_Interest.png';
import capitalGraph from '../../assets/img/higher-education/Capitalized_Interest_Graph.png';
import capitalTable from '../../assets/img/higher-education/Capitalized_Interest_Table.png';
import familyImage from '../../assets/img/higher-education/family_icon_modules.png';
import alertIcon from '../../assets/img/alert_icon.png';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import PwiSave from '../../components/pwi-save/pwi-save';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as moduleServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import MySavedNotes from './my-saved-notes';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { defaultValues } from '../../calculations/default-module-values';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';
import HigherEducationIncome from '../../components/documents/higher-education-income-popup';
import StudentLoanPopups from '../../components/documents/student-loan-popups';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const LIST_OF_STATES = loader('../../graphql/schema/auth/list-of-states.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#5B9BD5', '#ED7D31', '#A5A5A5', '#FFC000', '#4472C4', '#70AD47', '#255E91', '#9E480E', '#264488', '#43682B', '#264478', '#5E9DD5', '#E9F2E9', '#6EA6D9', '#4F7239'],
});

HCExporting(Highcharts);

const rangeNumbers = [];
const termLoansOptions = [];
for (let val = 0; val <= 30; val += 1) {
  if (val <= 6) {
    rangeNumbers.push({ label: val, value: val });
  }
  termLoansOptions.push({ label: val, value: val });
}

const HE = (props) => {
  const toggleIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
    showmeGraph: false,
  };

  const educationTypes = defaultValues.typeOfEducation;
  const { paymentPlanTypes } = defaultValues;
  const { classes, history } = props;

  const [loading, setLoading] = useState(true);
  const [toggleValues, setToggelValues] = useState(toggleIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [updatedHevalues, setUpdatedHEValues] = useState(defaultValues.module2);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([{ label: 0, value: 0 }]);
  const [offUpdatedOptions, setOffUpdatedOptions] = useState([{ label: 0, value: 0 }]);
  const [onUpdatedOptions, setOnUpdatedOptions] = useState([{ label: 0, value: 0 }]);
  const [yearaidOptions, setYearAidOptions] = useState([{ label: 0, value: 0 }]);
  const [incomeYearOptions, setIncomeYearOptions] = useState([{ label: 0, value: 0 }]);
  const [familyYearOptions, setFamilyYearOptions] = useState([{ label: 0, value: 0 }]);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [yearOption, setYearOptions] = useState([]);
  const [openPopup, setOpenPopup] = React.useState('');
  const [statesList, setStatesList] = useState([{ label: 'Please select', value: 0 }]);
  const [higherEduIncm, setHigherEduIncm] = useState(false);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [showMeData, setShowMeData] = React.useState({});
  const [graphUpdatedData, setGraphUpdatedData] = React.useState(defaultValues.graphInitValues);
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(9);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const [studentLoanType, setStudentLoanType] = React.useState('');
  const yrsCount = [];
  for (let val = 1; val <= updatedHevalues.years_diff; val += 1) {
    if (updatedHevalues.years_diff > 0) {
      yrsCount.push(val);
    }
  }
  const [yearsCount, setyearsCount] = useState(yrsCount);

  const generateYearRange = () => {
    const start_year = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (start_year > 0) {
      currentYear = start_year;
    }
    const yearRange = [{ label: 0, value: 0 }];
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      yearRange.push({ label: year, value: year });
    }
    setYearOptions(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const updateYearsList = (options, valuesUpdated, type = 'all') => {
    const updatedHevaluesYears = valuesUpdated;
    const gradYear = updatedHevaluesYears.start_year + updatedHevaluesYears.years_diff - 1;
    if (type === 'all' || type === 'aid') {
      if (updatedHevaluesYears.year_aid_begins > 0 && (updatedHevaluesYears.year_aid_begins < updatedHevaluesYears.start_year || updatedHevaluesYears.year_aid_begins > gradYear)) {
        updatedHevaluesYears.year_aid_begins = updatedHevaluesYears.start_year;
      } else if (updatedHevaluesYears.year_aid_begins <= 0) {
        updatedHevaluesYears.year_aid_ends = 0;
        updatedHevaluesYears.expected_yearly_finance_aid = 0;
      }

      const yearAidOption = [{ label: 0, value: 0 }];
      if (updatedHevaluesYears.year_aid_begins > 0) {
        for (let year = updatedHevaluesYears.year_aid_begins; year < gradYear + 1; year += 1) {
          yearAidOption.push({ label: year, value: year });
        }
        setYearAidOptions(yearAidOption);
      }

      if (updatedHevaluesYears.year_aid_ends > 0) {
        const yearIndex = _.findIndex(yearAidOption, { value: updatedHevaluesYears.year_aid_ends });
        if (yearIndex < 0) {
          updatedHevaluesYears.year_aid_ends = yearAidOption[yearAidOption.length - 1].value;
        }
      }
    }

    if (type === 'all' || type === 'income') {
      if (updatedHevaluesYears.income_start_year > 0 && (updatedHevaluesYears.income_start_year < updatedHevaluesYears.start_year || updatedHevaluesYears.income_start_year > gradYear)) {
        updatedHevaluesYears.income_start_year = updatedHevaluesYears.start_year;
      } else if (updatedHevaluesYears.income_start_year <= 0) {
        updatedHevaluesYears.income_end_year = 0;
        updatedHevaluesYears.yearly_income = 0;
      }

      const yearAidOption = [];

      if (updatedHevaluesYears.income_start_year > 0) {
        for (let year = updatedHevaluesYears.income_start_year; year < gradYear + 1; year += 1) {
          yearAidOption.push({ label: year, value: year });
        }
        setIncomeYearOptions(yearAidOption);
      }

      if (updatedHevaluesYears.income_end_year > 0) {
        const yearIndex = _.findIndex(yearAidOption, { value: updatedHevaluesYears.income_end_year });
        if (yearIndex < 0) {
          updatedHevaluesYears.income_end_year = yearAidOption[yearAidOption.length - 1].value;
        }
      }
    }

    if (type === 'all' || type === 'family') {
      if (updatedHevaluesYears.family_start_year > 0 && (updatedHevaluesYears.family_start_year < updatedHevaluesYears.start_year || updatedHevaluesYears.family_start_year > gradYear)) {
        updatedHevaluesYears.family_start_year = updatedHevaluesYears.start_year;
      } else if (updatedHevaluesYears.family_start_year <= 0) {
        updatedHevaluesYears.family_end_year = 0;
        updatedHevaluesYears.yearly_contribution = 0;
      }

      const yearAidOption = [];
      if (updatedHevaluesYears.family_start_year > 0) {
        for (let year = updatedHevaluesYears.family_start_year; year < gradYear + 1; year += 1) {
          yearAidOption.push({ label: year, value: year });
        }
        setFamilyYearOptions(yearAidOption);
      }

      if (updatedHevaluesYears.family_end_year > 0) {
        const yearIndex = _.findIndex(yearAidOption, { value: updatedHevaluesYears.family_end_year });
        if (yearIndex < 0) {
          updatedHevaluesYears.family_end_year = yearAidOption[yearAidOption.length - 1].value;
        }
      }
    }
    return updatedHevaluesYears;
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module2' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const countYrs = [];
          if (data.years_diff > 0) {
            for (let val = 1; val <= data.years_diff; val += 1) {
              countYrs.push(val);
            }
            setyearsCount(countYrs);
          }
          const options = [];
          if (data.years_diff > 0 && data.start_year > 0) {
            const gradYear = (data.start_year > 0 && data.years_diff) ? data.start_year + (data.years_diff - 1) : 0;
            for (let year = data.start_year; year <= gradYear; year += 1) {
              if (options.length === 0) {
                options.push({ label: 0, value: 0 }, { label: year, value: year });
              } else {
                options.push({ label: year, value: year });
              }
            }
            setUpdatedOptions(options);
          }

          const updatedData = updateYearsList(options, data, 'all');
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedHEValues(updatedData);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setUpdatedHEValues(defaultValues.module2)
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedHEValues(defaultValues.module2);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(()=>{
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module2' && loadCompareScenario.relation_year !== undefined) {
      toggleValues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }     
  },[loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(()=>{     
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module2' ) {
      toggleValues.savedNotes = true;    
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 }); 
    }
  },[myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const fedYearsName = ['fed_sub_firstyear', 'fed_sub_secondyear', 'fed_sub_thirdyear', 'fed_sub_fourthyear', 'fed_sub_fifthyear', 'fed_sub_sixthyear'];
  const fedunSubYearsName = ['fed_unsub_firstyear', 'fed_unsub_secondyear', 'fed_unsub_thirdyear', 'fed_unsub_fourthyear', 'fed_unsub_fifthyear', 'fed_unsub_sixthyear'];
  const PrivateYearsName = ['private_firstyear', 'private_secondyear', 'private_thirdyear', 'private_fourthyear', 'private_fifthyear', 'private_sixthyear'];

  const joinInk = modalType === 'video' ? classes.pendinglistPopup : classes.instructionsPopup;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useQuery(LIST_OF_STATES, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const statesListData = _.map(response.States ? response.States : [], (state) => ({ label: state.state_name, value: parseInt(state.id, 10) }));
      setStatesList([...statesList, ...statesListData]);
    },
  });

  const handleToggle = (type, subType) => {
    const dropValues = toggleIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !toggleValues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(heGraphData.heShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !toggleValues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !toggleValues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top'});
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !toggleValues.definations;
      } else {
        dropValues.awareness = !toggleValues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !toggleValues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !toggleValues.savedNotes;
    }
    setToggelValues(dropValues);
  };

  const updateValue = (e, field, fieldType) => {
    let newvalue = 0;
    let valuesUpdated = { ...updatedHevalues };
    if (fieldType === 'input' || fieldType === 'radio') {
      newvalue = e.target.value;
    } else if (fieldType === 'number') {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
      if (newvalue > 100 && (field === 'fed_sub_interest_rate'
        || field === 'fed_sub_origination_fee'
        || field === 'fed_unsub_interest_rate'
        || field === 'fed_unsub_origination_fee'
        || field === 'private_interest_rate'
        || field === 'private_origination_fee')) {
        newvalue = 100;
      }
    } else if (field === 'start_year' || field === 'years_diff') {
      valuesUpdated[field] = e.value;
      newvalue = e.value;
      const options = [];
      const gradYear = (valuesUpdated.start_year > 0 && valuesUpdated.years_diff > 0) ? valuesUpdated.start_year + (valuesUpdated.years_diff - 1) : 0;
      valuesUpdated.graduation_year = gradYear;
      for (let year = valuesUpdated.start_year; year <= gradYear; year += 1) {
        if (options.length === 0) {
          options.push({ label: 0, value: 0 }, { label: year, value: year });
        } else {
          options.push({ label: year, value: year });
        }
      }
      setUpdatedOptions(options);

      const countYrs = [];
      for (let val = 1; val <= valuesUpdated.years_diff; val += 1) {
        countYrs.push(val);
      }
      setyearsCount(countYrs);
      valuesUpdated = updateYearsList(options, valuesUpdated);
    } else if (field === 'type_of_higher_education' || field === 'state_id') {
      newvalue = e.value;
    } else if (field === 'year_aid_begins') {
      valuesUpdated[field] = e.value;
      valuesUpdated = updateYearsList(updatedOptions, valuesUpdated, 'aid');
      newvalue = e.value;
    } else if (field === 'income_start_year') {
      valuesUpdated[field] = e.value;
      valuesUpdated = updateYearsList(updatedOptions, valuesUpdated, 'income');
      newvalue = e.value;
    } else if (field === 'family_start_year') {
      valuesUpdated[field] = e.value;
      valuesUpdated = updateYearsList(updatedOptions, valuesUpdated, 'family');
      newvalue = e.value;
    } else if (field === 'fed_unsub_paymentplan' || field === 'private_paymentplan') {
      newvalue = e.value;
    } else {
      newvalue = e.label;
    }

    if ((field === 'type_of_higher_education' || field === 'dependent_status') && (valuesUpdated.dependent_status !== 'Yes' || valuesUpdated.type_of_higher_education !== 2)) {
      valuesUpdated.fed_sub_firstyear = 0;
      valuesUpdated.fed_sub_secondyear = 0;
      valuesUpdated.fed_sub_thirdyear = 0;
      valuesUpdated.fed_sub_fourthyear = 0;
      valuesUpdated.fed_sub_fifthyear = 0;
      valuesUpdated.fed_sub_sixthyear = 0;
      valuesUpdated.fed_sub_term = 0;
      valuesUpdated.fed_sub_interest_rate = 0;
      valuesUpdated.fed_sub_origination_fee = 0;
    }
    valuesUpdated[field] = newvalue;
    setUpdatedHEValues(valuesUpdated);
    moduleServices.setModule2Data(valuesUpdated);
  };

  const updateShowMeValues = (e, field) => {
    let prevGraphData = { ...graphUpdatedData };
    let newvalue = 0;
    newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    prevGraphData[field] = newvalue;
    prevGraphData = heGraphData.barGraph(prevGraphData);
    setGraphUpdatedData(prevGraphData);

  }

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module2';
    objectParams.moduleNumber = 'module2Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module2";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module2', 'quick-save', activeScenarioData);
      finalObject['module2Data'] = pwiObject.getfilteredPWIhigherEducation(moduleServices.module2Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = toggleIntialValues;
      dropValues.awareness = false;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = toggleIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !toggleValues.quickLinks;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = toggleIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !toggleValues.quickLinks;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = toggleIntialValues;
      dropValues.savedNotes = !toggleValues.savedNotes;
      setToggelValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = toggleIntialValues;
      dropValues.savedNotes = !toggleValues.savedNotes;
      setToggelValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = toggleIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setToggelValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -130, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 450, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = toggleIntialValues;
      dropValues.awareness = false;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = toggleIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !toggleValues.quickLinks;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = toggleIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = toggleValues.quickLinks;
      setToggelValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = toggleIntialValues;
      dropValues.savedNotes = !toggleValues.savedNotes;
      setToggelValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  React.useEffect(() => {
    var services = moduleServices.databaseCompleteData();
    if(services !== undefined && services !== '' 
    && services['Student Loans Limit'] !== undefined && services['Student Loans Limit'] !== ''
    && services['Student Loans Limit']['Higher Ed'] !== undefined 
    && services['Student Loans Limit']['Higher Ed'] !== ''){

      var onCampusYears = services['Student Loans Limit']['Higher Ed']['HE On Campus'];
      const onCampusYearsOptions = [];

      if (onCampusYears.length > 0) {
        for (let year = onCampusYears[1]; year <= onCampusYears[onCampusYears.length-1]; year += 1) {
          if (onCampusYearsOptions.length === 0) {
            onCampusYearsOptions.push({ label: 0, value: 0 }, { label: year, value: year });
          } else {
            onCampusYearsOptions.push({ label: year, value: year });
          }
        }
        setOnUpdatedOptions(onCampusYearsOptions)        
      }else{
        onCampusYearsOptions.push({ label: 0, value: 0 });
        setOnUpdatedOptions(onCampusYearsOptions)        
      }

      var offCampusYears = services['Student Loans Limit']['Higher Ed']['HE Off Campus'];
      const offCampusYearsOptions = [];

      if (offCampusYears.length > 0) {
        for (let year = offCampusYears[1]; year <= offCampusYears[offCampusYears.length-1]; year += 1) {
          if (offCampusYearsOptions.length === 0) {
            offCampusYearsOptions.push({ label: 0, value: 0 }, { label: year, value: year });
          } else {
            offCampusYearsOptions.push({ label: year, value: year });
          }
        }
        setOffUpdatedOptions(onCampusYearsOptions)
      }else{
        offCampusYearsOptions.push({ label: 0, value: 0 });
        setOffUpdatedOptions(onCampusYearsOptions)
      }
    }
  }, [updatedHevalues.start_year, updatedHevalues.graduation_year, updatedHevalues.years_diff, updatedHevalues.on_campus_start_year, updatedHevalues.off_campus_start_year])


  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.heSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your higher education details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classes.start_year}>START WITH A WALK-THRU</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Choose a School
                              </Typography>
                              <ul>
                                <li>Select the type of education that interests you</li>
                                <li>Identify a specific school, research cost of attendance, and input your tuition and fees</li>
                                <li>Fill in your sources of funding such as grants, scholarships, financial aid, and family contributions</li>
                                <li>See real-time results as you change and adjust your selected schools and funding sources.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Enter Your Expenses
                              </Typography>
                              <ul>
                                <li>List expenses for on-campus living</li>
                                <li>List expenses for off-campus housing</li>
                                <li>Compare outcomes to find your own best plan</li>
                                <li>If you are unsure of costs, Inkwiry suggests a value based on collective intelligence</li>
                                <li>Go back and experiment with other schools and funding alternatives to create additional possibilities.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Boost Your Awareness
                              </Typography>
                              <ul>
                                <li className={classes.listHeading}>What&apos;s involved in planning for higher education?</li>
                                <li>Check your QI (Questioning Intelligence)</li>
                                <li>Scan the Frequently Asked Questions</li>
                                <li>Understand what all the financial terms mean</li>
                                <li>Find quick links to additional resources.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Check Your Spaces
                              </Typography>
                              <ul>
                                <li>Click on the Spaces that need to be supportive of your higher education plan</li>
                                <li>Activate building blocks to develop your skills and knowledge</li>
                                <li>Open the scheduler to put building blocks in play.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderFour} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Build Your Scenarios
                              </Typography>
                              <ul>
                                <li>Create &quot;what if&quot; possibilities</li>
                                <li>Compare those possibilities</li>
                                <li>Blend possibilities to find hybrid solutions</li>
                                <li>Add individual scenarios to the Simulator Engine</li>
                                <li>Minimize risk while maximizing rewards</li>
                                <li>Find the scenario that works best for you.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderFive} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Higher Education
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Collaborate and Share
                              </Typography>
                              <ul>
                                <li className={classes.listHeading}>Use My Notes to:</li>
                                <li>Sketch out ideas</li>
                                <li>Make to-do lists</li>
                                <li>Share and publish your data to collaborate with family, friends and trusted colleagues.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderSix} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <section className={classes.fiContentSection}>
          <ReactTooltip id="puzzleTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)} />

          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ModuleNavBar moduleName="module2" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/he" ><img src={megamenu} alt="puzzle" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.dummyImg) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} 
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes = {classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>{updatedHevalues.type_of_higher_education > 0 ? educationTypes[updatedHevalues.type_of_higher_education].label : 'Higher Education'}</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Type of Higher Education</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'type_of_higher_education', 'select'); }}
                                              value={_.findIndex(educationTypes, { value: updatedHevalues.type_of_higher_education }) >= 0 ? educationTypes[_.findIndex(educationTypes, { value: updatedHevalues.type_of_higher_education })] : educationTypes[0]}
                                              options={educationTypes}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Are you planning for college, career school, military school, or some type of graduate school? Select one from the drop down. If you want to learn more about these options, scroll down to the Boost My Awareness section.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            Name of
                                            {' '}
                                            {updatedHevalues.type_of_higher_education > 0 ? educationTypes[updatedHevalues.type_of_higher_education].label : 'Higher Education'}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} id="standard-basic" value={updatedHevalues.name_of_college} onChange={(e) => updateValue(e, 'name_of_college', 'input')} onFocus={(e) => e.target.select()} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>State</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'state_id', 'select'); }}
                                              value={_.findIndex(statesList, { value: updatedHevalues.state_id }) >= 0 ? statesList[_.findIndex(statesList, { value: updatedHevalues.state_id })] : statesList[0]}
                                              options={statesList}
                                              placeholder="Please select"
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'start_year', 'select'); }}
                                              value={{ value: updatedHevalues.start_year, label: updatedHevalues.start_year }}
                                              options={yearOption}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Select the year from the drop down that you plan to start attending higher education.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>End Year</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Years</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'years_diff', 'select'); }}
                                              value={{ label: updatedHevalues.years_diff, value: updatedHevalues.years_diff }}
                                              options={rangeNumbers}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Select the number of years from the drop down that you plan to take to complete higher education. For example, if you are going to college for a bachelor&apos;s degree, that is typically a four-year program.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>{(updatedHevalues.start_year > 0 && updatedHevalues.years_diff) ? updatedHevalues.start_year + updatedHevalues.years_diff - 1 : 0}</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}>Annual Tuition & Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.tuition} onValueChange={(e) => updateValue(e, 'tuition', 'number')} onFocus={(e) => e.target.select()} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}>Annual Room & Board</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.room_board} onValueChange={(e) => updateValue(e, 'room_board', 'number')} onFocus={(e) => e.target.select()} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>On Campus Monthly Living Expenses</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classNames(classes.calcBlock, classes.displayEnd)}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew, classes.calcHeight)}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'on_campus_start_year', 'select'); }}
                                              value={{ label: updatedHevalues.on_campus_start_year, value: updatedHevalues.on_campus_start_year }}
                                              options={onUpdatedOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to start living on campus and paying room & board. The drop down options will make sure you cover all years for this module. The FC automatically calculates the years on and off campus based on your start year for both sections.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>

                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        <NumberFormat value={moduleServices.module2Data.onCampusYearsDiff > 0 ? moduleServices.module2Data.onCampusYearsDiff : 0} displayType="text" allowNegative={false} decimalScale={0} />
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Room & Board</strong></div>
                                        </div>
                                      </Grid>

                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fetchRoomBoard !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module2Data.fetchRoomBoard} prefix={moduleServices.module2Data.fetchRoomBoard >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fetchRoomBoard < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When comparing living on and off campus, the main difference is paying room and board versus rent, utilities, and food. The FC automatically calculates your room and board on a monthly basis so that you can compare your monthly expenses when living on and off campus.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Off Campus Food</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.off_campus_food} onValueChange={(e) => updateValue(e, 'off_campus_food', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to spend on food outside of your meal plan. Think of this input as a place for all off campus eating, delivery, take out, or any other meals that you would get outside of your meal plan.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Clothing & Personal Care</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.clothing} onValueChange={(e) => updateValue(e, 'clothing', 'number')} onFocus={(e) => e.target.select()} />
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="Enter how much money you expect to spend on clothing and personal care.
                                        This input should account for all clothes such as shoes, shirts, pants, accessories, jewelry, etc. And, any personal care items such as toothbrushes, hair and body washes, facial care, etc.
                                        If you don't buy clothes and personal care items every month, just estimate what you might spend each year and divide that number by 12 using Inkwiry&apos;s calculator above."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Entertainment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.entertainment} onValueChange={(e) => updateValue(e, 'entertainment', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to spend on entertainment activities such as movies, books, golfing, going out at night with friends, concerts, or just about anything you do for fun that costs money.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Technology</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.technology} onValueChange={(e) => updateValue(e, 'technology', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on technology such as a smartphone bill, laptop / desktop / tablet expenses, streaming services, Inkwiry subscription, other subscriptions, or any other dollars you spend on technology.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transportation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.transportation} onValueChange={(e) => updateValue(e, 'transportation', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on transportation such as ride sharing services, taxis, subway rides, trains, buses, or any other dollars you spend on transportation. Do not include any expenses related to your own car. Those expenses will be captured in the 'Existing Assets & Liabilities&apos; or &apos;Buying a Car&apos; modules.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Miscellaneous</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.miscellaneous} onValueChange={(e) => updateValue(e, 'miscellaneous', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on miscellaneous items such as costs of books and other supplies, household items, gym memberships, monthly parking, or any repeat monthly expense that might not be captured in the above categories.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Healthcare Costs other than Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.medical_costs_before_deductible} onValueChange={(e) => updateValue(e, 'medical_costs_before_deductible', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on healthcare outside of your monthly premium. Some expenses that you can plan for are copay payments for routine checkups, pharmacy costs, or any other out of pocket healthcare costs that are expected. Check out the Boost My Awareness section below for definitions of a premium and costs outside the premium.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Health Insurance Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.health_insurance_premium} onValueChange={(e) => updateValue(e, 'health_insurance_premium', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much your health insurance premium costs each month. If your parents pay for your health insurance, you can leave this as $0. If not, a good estimate of what health insurance might cost can be found in Quick Links in the Boost My Awareness section below.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.horizontalSmall} />
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Living Expenses On Campus</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.onCampusTotalExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.onCampusTotalExpenses} prefix={moduleServices.module2Data.onCampusTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.onCampusTotalExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.emptyBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.yearBlock}>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Off Campus Monthly Living Expenses</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.emptyBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.yearText, classes.yearLeftText)}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={12} >
                                            <div className={classNames(classes.inputRight, classes.smallInput, classes.selectBox)}>
                                              <div className={classes.selectBlock}>
                                                <Select
                                                  onChange={(value) => { updateValue(value, 'off_campus_start_year', 'select'); }}
                                                  value={{ value: updatedHevalues.off_campus_start_year, label: updatedHevalues.off_campus_start_year }}
                                                  options={offUpdatedOptions}
                                                  placeholder="Please select"
                                                />
                                              </div>
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to start living off campus. Make sure that between on and off campus expenses, you cover all the years in this module. The FC automatically calculates the years on and off campus based on your start year for both sections.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={0} >
                                            <div className={classes.inputRight}>
                                              &nbsp;
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.emptyBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classNames(classes.labelText, classes.expensesLabel)}>
                                      <div className={classNames(classes.calcText, classes.expensesText)}>{(moduleServices.module2Data.offCampusYearDiff > 0) ? moduleServices.module2Data.offCampusYearDiff : 0}</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {moduleServices.module2Data.higherEduMarriageYearVal && (
                              <div className={classes.moduleRow}>
                                <Grid container>
                                  <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                    <div className={classes.inputGroup}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                          <div>
                                            <div className={classes.spanText}>&nbsp;</div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className={classes.noSpace}>
                                          <Grid container>
                                            <Grid item sm={8} xs={8} >
                                              <div>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                            <Grid item sm={4} xs={4} >
                                              <div className={classes.inputRight}>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                    {moduleServices.module2Data.higherEduMarriageYearVal !== '' && (
                                      <div className={classes.formMainRow}>
                                        <div className={classes.labelText}>
                                          <div className={classes.calcText}>
                                            <span className={classes.familyImage}>
                                              <img src={familyImage} alt="familyImage" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {moduleServices.module2Data.higherEduMarriageYearVal !== '' && (
                              <div className={classes.moduleRow}>
                                <Grid container>
                                  <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                    <div className={classes.inputGroup}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                          <div>
                                            <div className={classes.spanText}>&nbsp;</div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className={classes.noSpace}>
                                          <Grid container>
                                            <Grid item sm={8} xs={8} >
                                              <div>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                            <Grid item sm={4} xs={4} >
                                              <div className={classes.inputRight}>
                                                <span className={classes.exampleText}>
                                                  X
                                                </span>
                                                <div
                                                  className={classes.infoIcon}
                                                  data-for="detailsTooltip"
                                                  data-tip="If your Marriage year overlaps with the time you live off-campus, the Marriage Multiples that grow your expenses to account for your partner will show in this section here. To adjust the multiples, which will adjust your expenses go to the Family Module."
                                                >
                                                  <img src={infoIcon} alt="infoicon" />
                                                </div>

                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          <span>
                                            {moduleServices.module2Data.higherEduMarriageYearVal}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            )}

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Rent</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.rent} onValueChange={(e) => updateValue(e, 'rent', 'number')} onFocus={(e) => e.target.select()} />
                                              <div
                                                className={classes.infoIcon}
                                                data-for="detailsTooltip"
                                                data-tip="Enter how much you expect to spend on rent when living off campus. This input depends on your location and will require a little research to figure out rent costs in your planned living area during higher education.
                                            Check out the Boost My Awareness section below for links to research average rents by location. Your planned school will also have links to student housing options for you to explore."
                                              >
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusRentMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusRentMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusRentMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          <NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusRentExpenses} prefix='$'/>
                                          {' '}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.utilities} onValueChange={(e) => updateValue(e, 'utilities', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Check the Quick Links in the Boost My Awareness section below for tips on estimating your monthly utilities bill.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusUtilitiesMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusUtilitiesMultiplier}
                                                  {' '}
                                                  x
                                                </span>
                                              </div>
                                            )}

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusUtilitiesMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusUtilitiesExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Food</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.food} onValueChange={(e) => updateValue(e, 'food', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to spend on food on a monthly basis. Factor in things such as grocery shopping, dining out, delivery, take out, and any other food. Don't forget, you can use Inkwiry's calculator to do some quick math to get to your total monthly food expense when living off campus.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusFoodMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusFoodMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusFoodMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusFoodExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Clothing & Personal Care</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_clothing} onValueChange={(e) => updateValue(e, 'second_clothing', 'number')} onFocus={(e) => e.target.select()} />
                                              <div
                                                className={classes.infoIcon}
                                                data-for="detailsTooltip"
                                                data-tip="Enter how much money you expect to spend on clothing and personal care. This input should account for all clothes such as shoes, shirts, pants, accesories, jewelry, etc. And, any personal care items such as toothbrushes, hair and body washes, facial care, etc.
                                            If you don't buy clothes and personal care items every month, just estimate what you might spend each year and divide that number by 12 using Inkwiry&apos;s calculator above."
                                              >
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusClothingMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusClothingMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusClothingMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} thousandSeparator displayType={'text'} value={moduleServices.module2Data.higherEducationOffCampusClothingExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Entertainment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_entertainment} onValueChange={(e) => updateValue(e, 'second_entertainment', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to spend on entertainment activities such as movies, books, golfing, going out at night with friends, concerts, or just about anything you do for fun that costs money.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusEntertainmentMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusEntertainmentMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusEntertainmentMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusEntertainmentExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Technology</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_technology} onValueChange={(e) => updateValue(e, 'second_technology', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on technology such as a smartphone bill, laptop / desktop / tablet expenses, streaming services, Inkwiry subscription, other subscriptions, or any other dollars you spend on technology.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusTechnologyMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusTechnologyMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusTechnologyMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusTechnologyExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transportation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_transportation} onValueChange={(e) => updateValue(e, 'second_transportation', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on transportation such as ride sharing services, taxis, subway rides, trains, buses, or any other dollars you spend on transportation. Do not include any expenses related to your own car. Those expenses will be captured in the 'Existing Assets & Liabilities' or 'Buying a Car' modules.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusTransportationMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusTransportationMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusTransportationMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusTransportationExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Miscellaneous</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_miscellaneous} onValueChange={(e) => updateValue(e, 'second_miscellaneous', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on miscellaneous items such as costs of books and other supplies, household items, gym memberships, monthly parking, or any repeat monthly expense that might not be captured in the above categories.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusMiscellaneousMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusMiscellaneousMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>

                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusMiscellaneousMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusMiscellaneousExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Healthcare Costs other than Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput, classes.bigInputNew, classes.cpBigInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_medical_costs_before_deductible} onValueChange={(e) => updateValue(e, 'second_medical_costs_before_deductible', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much you expect to spend on healthcare outside of your monthly premium. Some expenses that you can plan for are copay payments for routine checkups, pharmacy costs, or any other out of pocket healthcare costs that are expected. Check out the Boost My Awareness section below for definitions of a premium and costs outside the premium.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusHealthInsuranceMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusHealthInsuranceMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusHealthInsuranceMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusOutsidePremiumExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Health Insurance Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <span className={classes.dollarSign}>$</span>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues.second_health_insurance_premium} onValueChange={(e) => updateValue(e, 'second_health_insurance_premium', 'number')} onFocus={(e) => e.target.select()} />
                                              <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much your health insurance premium costs each month. If your parents pay for your health insurance, you can leave this as $0. If not, a good estimate of what health insurance might cost can be found in Quick Links in the Boost My Awareness section below.">
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier && (

                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier && (

                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}><NumberFormat decimalScale={0} displayType={'text'} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusPremiumExpenses} prefix='$'/></div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.horizontalSmall} />
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Living Expenses Off Campus</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.offCampusTotalExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.offCampusTotalExpenses} prefix={moduleServices.module2Data.offCampusTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.offCampusTotalExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.calcText}>
                                      {moduleServices.module2Data.higherEducationOffCampusTotalExpenses !== undefined ? (
                                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherEducationOffCampusTotalExpenses} prefix={moduleServices.module2Data.higherEducationOffCampusTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherEducationOffCampusTotalExpenses < 0 && ')'} />
                                      ) : ('$0')}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.horizontalSmall} />
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Monthly $ Change from On to Off Campus</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.monthlyPriceOffCampus !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.monthlyPriceOffCampus} prefix={moduleServices.module2Data.monthlyPriceOffCampus >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.monthlyPriceOffCampus < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Monthly % Change from On to Off Campus</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.monthlyPercentageOffCampus !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module2Data.monthlyPercentageOffCampus} prefix={moduleServices.module2Data.monthlyPercentageOffCampus >= 0 ? '' : '('} suffix={moduleServices.module2Data.monthlyPercentageOffCampus < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.emptyBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        Student Loan Payments during
                                        {' '}
                                        {' '}
                                        {updatedHevalues.type_of_higher_education > 0 ? educationTypes[updatedHevalues.type_of_higher_education].label : 'Higher Education'}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Existing Student Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.existingSubLoanPayments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.existingSubLoanPayments} prefix={moduleServices.module2Data.existingSubLoanPayments >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.existingSubLoanPayments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>College Student Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.collegeStudentLoanPayments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.collegeStudentLoanPayments} prefix={moduleServices.module2Data.collegeStudentLoanPayments >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.collegeStudentLoanPayments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Student Loan Payments during College</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalStudentsLoanPayments} prefix={moduleServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Total Cost of Attendance</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Tuition & Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalTuitionAndFees !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalTuitionAndFees} prefix={moduleServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalTuitionAndFees < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Room & Board</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalRoomAndBoard !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalRoomAndBoard} prefix={moduleServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalRoomAndBoard < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>On Campus Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherOnCampusLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherOnCampusLivingExpenses} prefix={moduleServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherOnCampusLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Off Campus Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherOffCampusLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherOffCampusLivingExpenses} prefix={moduleServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherOffCampusLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Student Loan Payments during College</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalStudentsLoanPayments} prefix={moduleServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Cost of Attendance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalCostOfAttendance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalCostOfAttendance} prefix={moduleServices.module2Data.totalCostOfAttendance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalCostOfAttendance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Total Expenses</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Tuition & Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalTuitionAndFees !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalTuitionAndFees} prefix={moduleServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalTuitionAndFees < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Room & Board</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.module2Data.totalRoomAndBoard !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalRoomAndBoard} prefix={moduleServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalRoomAndBoard < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>On Campus Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherOnCampusLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherOnCampusLivingExpenses} prefix={moduleServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherOnCampusLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Off Campus Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherOffCampusLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherOffCampusLivingExpenses} prefix={moduleServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherOffCampusLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Student Loan Payments during College</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalStudentsLoanPayments !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalStudentsLoanPayments} prefix={moduleServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalStudentsLoanPayments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Other Loan Payments on Other Debt</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.otherLoanPaymentsonOtherDebt !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.otherLoanPaymentsonOtherDebt} prefix={moduleServices.module2Data.otherLoanPaymentsonOtherDebt >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.otherLoanPaymentsonOtherDebt < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherCarExpense !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherCarExpense} prefix={moduleServices.module2Data.higherCarExpense >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherCarExpense < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherRealEstateExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherRealEstateExpenses} prefix={moduleServices.module2Data.higherRealEstateExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherRealEstateExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Children and Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherChildrenAndPetExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherChildrenAndPetExpenses} prefix={moduleServices.module2Data.higherChildrenAndPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherChildrenAndPetExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.higherTotalExpenses() !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.higherTotalExpenses()} prefix={moduleServices.higherTotalExpenses() >= 0 ? '$' : '($'} suffix={moduleServices.higherTotalExpenses() < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsRightBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Scholarships</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}><strong>Annual Scholarship Amount</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedHevalues.yearly_scholarship_amount} onValueChange={(e) => updateValue(e, 'yearly_scholarship_amount', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to receive from a scholarship each year. A scholarship is money offered to you by a school or other institution that does not require repayment. See the Boost My Awareness section below for more detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Grants</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}><strong>Annual Grant Amount</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedHevalues.yearly_grant_amount} onValueChange={(e) => updateValue(e, 'yearly_grant_amount', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much money you expect to receive from grants each year. A grant, like a scholarship, is money offered to you by a school or other institution that does not require repayment. See the Boost My Awareness section below for more detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Other Financial Aid</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Year Aid Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_aid_begins', 'select'); }}
                                              value={{ value: updatedHevalues.year_aid_begins, label: updatedHevalues.year_aid_begins }}
                                              options={updatedOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Planning to receive financial aid in addition to scholarships or grants? Specify what year you expect to receive that financial aid.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Year Aid Ends</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_aid_ends', 'select'); }}
                                              value={{ value: updatedHevalues.year_aid_ends, label: updatedHevalues.year_aid_ends }}
                                              options={yearaidOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Planning to receive financial aid in addition to scholarships or grants? Will you receive that aid every year? Specify what year you expect to stop receiving that financial aid.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                    <div className={classes.calcText}>{(updatedHevalues.start_year > 0 && updatedHevalues.years_diff && updatedHevalues.year_aid_begins > 0 && updatedHevalues.year_aid_ends > 0) ? (updatedHevalues.year_aid_ends) - (updatedHevalues.year_aid_begins) + 1 : 0}</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}><strong>Annual Financial Aid</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedHevalues.expected_yearly_finance_aid} onValueChange={(e) => updateValue(e, 'expected_yearly_finance_aid', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Planning to receive financial aid in addition to scholarships or grants? Other financial aid would be any additional money offered to you by a school or other institution that does not require repayment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>
                                            Income Earned during{''}
                                            {updatedHevalues.type_of_higher_education > 0 ? educationTypes[updatedHevalues.type_of_higher_education].label : 'Higher Education'}
                                          </h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'income_start_year', 'select'); }}
                                              value={{ value: updatedHevalues.income_start_year, label: updatedHevalues.income_start_year }}
                                              options={updatedOptions}
                                              className={classes.selectOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Plan on working during higher education? Earning income is a great way to lower student loans and to have some disposable income while in school. Enter what years you plan on working. Estimated taxes below will show you how much cash you can expect to have left over. Check out the Boost My Awareness section below for Quick Links on earning income during school.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'income_end_year', 'select'); }}
                                              value={{ value: updatedHevalues.income_end_year, label: updatedHevalues.income_end_year }}
                                              options={incomeYearOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Select the year you plan to stop earning income during higher education. See the effects of working one year, multiple years, or not working might have on funding your education. You might be able to save thousands of dollars in student loans by working instead.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>{(updatedHevalues.start_year > 0 && updatedHevalues.years_diff && updatedHevalues.income_start_year > 0 && updatedHevalues.income_end_year > 0) ? (updatedHevalues.income_end_year) - (updatedHevalues.income_start_year) + 1 : 0}</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}>Annual Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedHevalues.yearly_income} onValueChange={(e) => updateValue(e, 'yearly_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter the amount of income you expect to earn each year from your job or multiple jobs before taxes.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Estimated Tax Rate</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.estimateTaxRate !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module2Data.estimateTaxRate} prefix={moduleServices.module2Data.estimateTaxRate >= 0 ? '' : '('} suffix={moduleServices.module2Data.estimateTaxRate < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Estimated tax rate gives no effect to possible tax adjustments, deductions, or credits.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Estimated Taxes Paid</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.estimateTaxPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.estimateTaxPaid} prefix={moduleServices.module2Data.estimateTaxPaid >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.estimateTaxPaid < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Estimated Income after Taxes</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.estimateIncomeAfterTax !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.estimateIncomeAfterTax} prefix={moduleServices.module2Data.estimateIncomeAfterTax >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.estimateIncomeAfterTax < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Estimated taxes gives no effect to possible tax adjustments, deductions, or credits.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Family Contribution</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'family_start_year', 'select'); }}
                                              value={{ value: updatedHevalues.family_start_year, label: updatedHevalues.family_start_year }}
                                              options={updatedOptions}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Family can play a large role financially in higher education. Select the year you expect your family to contribute money to help pay for your higher education.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'family_end_year', 'select'); }}
                                              value={{ value: updatedHevalues.family_end_year, label: updatedHevalues.family_end_year }}
                                              options={familyYearOptions}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Family can play a large role financially in higher education. Select the year you expect your family to stop contributing money to help pay for your higher education.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>{moduleServices.module2Data.familyContriYearsDiff}</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.blueColorRow)}>
                                          <div className={classes.spanText}>Annual Contribution</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.dollarSign}>$</span>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedHevalues.yearly_contribution} onValueChange={(e) => updateValue(e, 'yearly_contribution', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Learn, understand, and input how much your family plans on contributing to your higher education each year. Leverage the higher education module to minimize loans and family contributions while maximizing scholarships, grants, other financial aid, and income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Student Status</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classNames(classes.inputGroup, classes.dependentFlex)}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Dependent / Independent Student</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.radioGroupFull)}>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="Dependent" className={updatedHevalues.dependent_status === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Dependent"
                                                  name="Dependent"
                                                  value="Yes"
                                                  checked={updatedHevalues.dependent_status === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'dependent_status', 'radio')}
                                                />
                                                Dependent
                                              </label>
                                            </div>
                                            <div className={classes.checkboxRadio}>
                                              <label htmlFor="InDependent" className={updatedHevalues.dependent_status === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="InDependent"
                                                  name="InDependent"
                                                  value="No"
                                                  checked={updatedHevalues.dependent_status === 'No'}
                                                  onClick={(e) => updateValue(e, 'dependent_status', 'radio')}
                                                />
                                                InDependent
                                              </label>
                                            </div>
                                          </div>
                                          <div className={classNames(classes.infoIcon, classes.checkInfoIcon)} data-for="detailsTooltip" data-tip="See the Boost My Awareness section below to understand the difference between dependent and independent student status. This selection is important because it determines the max amount of federal student loans you can take.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Federal Subsidized Student Loan</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew2)}>Max Loan Limits</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            {
                              yearsCount.length > 0 && yearsCount.map((data) => (
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>
                                                {updatedHevalues.start_year + data - 1}
                                                {' '}
                                                Distribution
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} xs={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat disabled={updatedHevalues.dependent_status !== 'Yes' || updatedHevalues.type_of_higher_education !== 2} className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues[fedYearsName[data - 1]]} onValueChange={(e) => updateValue(e, fedYearsName[data - 1], 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div
                                                className={classes.infoIcon}
                                                data-for="detailsTooltip"
                                                data-tip="Enter what interest rate you expect to receive on this loan. Federal student loans have lower interest rates than private student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower.
                                        Pay attention to the total interest paid to see the differences. Explore the Boost My Awareness section below for the latest federal student loans' interest rates."
                                              >
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                              ))
                            }

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_sub_term', 'select'); }}
                                              value={{ label: updatedHevalues.fed_sub_term, value: updatedHevalues.fed_sub_term }}
                                              options={termLoansOptions}
                                              isDisabled={updatedHevalues.dependent_status !== 'Yes' || updatedHevalues.type_of_higher_education !== 2}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The # of years for repayment, which is typically 10 years for student loans. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.fed_sub_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'fed_sub_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='fed_sub_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_sub_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_sub_interest_rate'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                            disabled={updatedHevalues.dependent_status !== 'Yes' || updatedHevalues.type_of_higher_education !== 2}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="Enter what interest rate you expect to receive on this loan. Federal student loans have lower interest rates than private student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower.
                                        Pay attention to the total interest paid to see the differences. Explore the Boost My Awareness section below for the latest federal student loans' interest rates."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.fed_sub_origination_fee}
                                            onValueChange={(e) => updateValue(e, 'fed_sub_origination_fee', 'number')}
                                            decimalScale = { isFocus!=='fed_sub_origination_fee'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_sub_origination_fee'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_sub_origination_fee'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                            disabled={updatedHevalues.dependent_status !== 'Yes' || updatedHevalues.type_of_higher_education !== 2}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are usually calculated as a percentage of the loan and are usually required for all student loans. See the Boost My Awareness section below for the latest federal student loans' origination fees.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module2Data.fedSubOrganizationValue !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubOrganizationValue} prefix={moduleServices.module2Data.fedSubOrganizationValue >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubOrganizationValue < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Year Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.module2Data.fedSubPaymentYearBegins !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} value={moduleServices.module2Data.fedSubPaymentYearBegins} prefix={moduleServices.module2Data.fedSubPaymentYearBegins >= 0 ? '' : '('} suffix={moduleServices.module2Data.fedSubPaymentYearBegins < 0 && ')'} />
                                            ) : ('0')}
                                          </span>

                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="This will be one year after your end year with an exception. If you activate another higher education module and that module begins right after this module, the FC calculates your repayment year differently. For example, if you plan on completing your undergraduate program and immediately going to graduate school, the year repayment begins would be after that other higher education module.
                                        Check out the FAQs in the Boost My Awareness section below for more details."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Beginning Loan Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubBeginningBalance} prefix={moduleServices.module2Data.fedSubBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Monthly Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubMonthlyPayment} prefix={moduleServices.module2Data.fedSubMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Annual Paid (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubYearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubYearlyPayment} prefix={moduleServices.module2Data.fedSubYearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubYearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Interest</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubTotalInterestPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubTotalInterestPaid} prefix={moduleServices.module2Data.fedSubTotalInterestPaid >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubTotalInterestPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Repayment Amount (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubTotalPrincipalInterest} prefix={moduleServices.module2Data.fedSubTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Federal Unsubsidized Student Loan</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew2)}>Max Loan Limits</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {
                              yearsCount.length > 0 && yearsCount.map((data) => (
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>
                                                {updatedHevalues.start_year + data - 1}
                                                {' '}
                                                Distribution
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} xs={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues[fedunSubYearsName[data - 1]]} onValueChange={(e) => updateValue(e, fedunSubYearsName[data - 1], 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div
                                                className={classes.infoIcon}
                                                data-for="detailsTooltip"
                                                data-tip="Enter what interest rate you expect to receive on this loan. Federal student loans have lower interest rates than private student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower.
                                        Pay attention to the total interest paid to see the differences. Explore the Boost My Awareness section below for the latest federal student loans' interest rates."
                                              >
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                              ))
                            }
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_unsub_term', 'select'); }}
                                              value={{ label: updatedHevalues.fed_unsub_term, value: updatedHevalues.fed_unsub_term }}
                                              options={termLoansOptions}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The # of years for repayment, which is typically 10 years for student loans. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.fed_unsub_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'fed_unsub_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='fed_unsub_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_unsub_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_interest_rate'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="Enter what interest rate you expect to receive on this loan. Federal student loans have lower interest rates than private student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower. Pay attention to the total interest paid to see the differences.
                                         Explore the Boost My Awareness section below for the latest federal student loans' interest rates."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Payment Plan Before Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox, classes.selectInputNew)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'fed_unsub_paymentplan', 'select'); }}
                                              value={_.findIndex(paymentPlanTypes, { value: updatedHevalues.fed_unsub_paymentplan }) >= 0 ? paymentPlanTypes[_.findIndex(paymentPlanTypes, { value: updatedHevalues.fed_unsub_paymentplan })] : paymentPlanTypes[0]}
                                              options={paymentPlanTypes}
                                            />
                                          </div>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="When you receive your loan, interest will immediately begin to accrue. If you choose to make interest payments, the FC will automatically factor in your plan the minimum interest payments required during school. If you defere interest payments,
                                        the FC wil calculate the accrued interest that will be added to your loan. See how deferring or making interest payments during school affects acrrued interest and total interest paid. Check out the Boost My Awareness section below for more detail."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.fed_unsub_origination_fee}
                                            onValueChange={(e) => updateValue(e, 'fed_unsub_origination_fee', 'number')}
                                            decimalScale = { isFocus!=='fed_unsub_origination_fee'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='fed_unsub_origination_fee'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_origination_fee'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are usually calculated as a percentage of the loan and are usually required for all student loans. See the Boost My Awareness section below for the latest federal student loans' origination fees.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module2Data.fedUnSubOrganizationValue !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubOrganizationValue} prefix={moduleServices.module2Data.fedUnSubOrganizationValue >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubOrganizationValue < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Year Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.module2Data.fedSubPaymentYearBegins !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} value={moduleServices.module2Data.fedSubPaymentYearBegins} prefix={moduleServices.module2Data.fedSubPaymentYearBegins >= 0 ? '' : '('} suffix={moduleServices.module2Data.fedSubPaymentYearBegins < 0 && ')'} />
                                            ) : ('0')}

                                          </span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="This will be one year after your end year with an exception. If you activate another higher education module and that module begins right after this module, the FC calculates your repayment year differently.
                                        For example, if you plan on completing your undergraduate program and immediately going to graduate school, the year repayment begins would be after that other higher education module. Check out the FAQs in the Boost My Awareness section below for more details."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Principal</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubPrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubPrincipal} prefix={moduleServices.module2Data.fedUnSubPrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubPrincipal < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Accrued Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubAccruedInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubAccruedInterest} prefix={moduleServices.module2Data.fedUnSubAccruedInterest >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubAccruedInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Beginning Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubBeginningBalance} prefix={moduleServices.module2Data.fedUnSubBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={6} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubMonthlyPayment} prefix={moduleServices.module2Data.fedUnSubMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubYearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubYearlyPayment} prefix={moduleServices.module2Data.fedUnSubYearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubYearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubTotalInterestPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubTotalInterestPaid} prefix={moduleServices.module2Data.fedUnSubTotalInterestPaid >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubTotalInterestPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubTotalPrincipalInterest} prefix={moduleServices.module2Data.fedUnSubTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Private Student Loan</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {
                              yearsCount.length > 0 && yearsCount.map((data) => (
                                <div className={classes.moduleRow}>
                                  <Grid container>
                                    <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                      <div className={classes.inputGroup}>
                                        <Grid container spacing={4}>
                                          <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                            <div className={classes.insideSpan}>
                                              <div className={classes.spanText}>
                                                {updatedHevalues.start_year + data - 1}
                                                {' '}
                                                Distribution
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={6} xs={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedHevalues[PrivateYearsName[data - 1]]} onValueChange={(e) => updateValue(e, PrivateYearsName[data - 1], 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div
                                                className={classes.infoIcon}
                                                data-for="detailsTooltip"
                                                data-tip="Enter what interest rate you expect to receive on this loan. Federal student loans have lower interest rates than private student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower.
                                        Pay attention to the total interest paid to see the differences. Explore the Boost My Awareness section below for the latest federal student loans' interest rates."
                                              >
                                                <img src={infoIcon} alt="infoicon" />
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item sm={3} xs={3} className={classes.calcBlock} />
                                  </Grid>
                                </div>
                              ))
                            }
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'private_term', 'select'); }}
                                              value={{ label: updatedHevalues.private_term, value: updatedHevalues.private_term }}
                                              options={termLoansOptions}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The # of years for repayment, which is typically 10 years for student loans. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.private_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'private_interest_rate', 'number')}
                                            decimalScale = { isFocus!=='private_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='private_interest_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('private_interest_rate'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="Enter what interest rate you expect to receive on this loan. Private student loans have higher interest rates than federal student loans. To see how interest rate affects the amount of money you have to repay, make your interest rate higher and lower. Pay attention to the total interest paid to see the differences.
                                        Explore the Boost My Awareness section below for the latest private student loans' interest rates."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Payment Plan Before Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.selectInputNew)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'private_paymentplan', 'select'); }}
                                              value={_.findIndex(paymentPlanTypes, { value: updatedHevalues.private_paymentplan }) >= 0 ? paymentPlanTypes[_.findIndex(paymentPlanTypes, { value: updatedHevalues.private_paymentplan })] : paymentPlanTypes[0]}
                                              options={paymentPlanTypes}
                                            />
                                          </div>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="When you receive your loan, interest will immediately begin to accrue. If you choose to make interest payments, the FC will automatically factor in your plan the minimum interest payments required during school. If you defere interest payments, the FC wil calculate the accrued interest that will be added to your loan.
                                        See how deferring or making interest payments during school affects acrrued interest and total interest paid. Check out the Boost My Awareness section below for more detail."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedHevalues.private_origination_fee}
                                            onValueChange={(e) => updateValue(e, 'private_origination_fee', 'number')}
                                            decimalScale = { isFocus!=='private_origination_fee'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='private_origination_fee'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('private_origination_fee'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="One-time fee paid to the lender for administrative costs associated with your loan.
                                         Origination fees are usually calculated as a percentage of the loan and are usually required for all student loans. See the Boost My Awareness section below for the latest private student loans' origination fees."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module2Data.fedPrivateOrganizationValue !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedPrivateOrganizationValue} prefix={moduleServices.module2Data.fedPrivateOrganizationValue >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedPrivateOrganizationValue < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Year Repayment Begins</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubPaymentYearBegins !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} value={moduleServices.module2Data.fedSubPaymentYearBegins} prefix={moduleServices.module2Data.fedSubPaymentYearBegins >= 0 ? '' : '('} suffix={moduleServices.module2Data.fedSubPaymentYearBegins < 0 && ')'} />
                                            ) : ('0')}
                                          </span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="This will be one year after your end year with an exception. If you activate another higher education module and that module begins right after this module, the FC calculates your repayment year differently.
                                        For example, if you plan on completing your undergraduate program and immediately going to graduate school, the year repayment begins would be after that other higher education module. Check out the FAQs in the Boost My Awareness section below for more details."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Principal</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privatePrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privatePrincipal} prefix={moduleServices.module2Data.privatePrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privatePrincipal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={6} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Accrued Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateAccruedInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateAccruedInterest} prefix={moduleServices.module2Data.privateAccruedInterest >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateAccruedInterest < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Beginning Loan Balance</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateBeginningBalance} prefix={moduleServices.module2Data.privateBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateMonthlyPayment} prefix={moduleServices.module2Data.privateMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateYearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateYearlyPayment} prefix={moduleServices.module2Data.privateYearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateYearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateTotalInterestPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateTotalInterestPaid} prefix={moduleServices.module2Data.privateTotalInterestPaid >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateTotalInterestPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privateTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privateTotalPrincipalInterest} prefix={moduleServices.module2Data.privateTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privateTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Existing Student Loans</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>
                                              Existing Student Loans Balance at Year End
                                              {updatedHevalues.graduation_year}
                                            </strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.existingStudentLoansBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.existingStudentLoansBalance} prefix={moduleServices.module2Data.existingStudentLoansBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.existingStudentLoansBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <ReactTooltip id="analysisTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />

                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        Income Needed to Support Student Loans Analysis&nbsp;
                                        <div className={classes.infoIcon} data-for="analysisTooltip" data-tip="How do I know if I am taking too much in student loans? Great question! As a benchmark, your total student loan payments in one full year should not be more than 12.5% of your total gross income after graduation. See below for how much income this rule would suggest you need to make in your first career move after graduation to support your student loan payments.">
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Student Loan Balance (incl. Existing Loans)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalStudentLoanBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalStudentLoanBalance} prefix={moduleServices.module2Data.totalStudentLoanBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalStudentLoanBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Monthly Payment (incl. Existing Loans)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalMonthlyPayment} prefix={moduleServices.module2Data.totalMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Annual Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.totalAnnualPaymentPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.totalAnnualPaymentPaid} prefix={moduleServices.module2Data.totalAnnualPaymentPaid >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalAnnualPaymentPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Divided by 12.5%</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>12.5%</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Income Needed to Support Student Loans</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.incomeNeedToSupportLoans !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.incomeNeedToSupportLoans} prefix={moduleServices.module2Data.incomeNeedToSupportLoans >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.incomeNeedToSupportLoans < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classes.tooltipInfoTop} />
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        Investment Analysis&nbsp;
                                        <div
                                          className={classes.infoIcon}
                                          data-for="investTooltip"
                                          data-tip="Are you financing your education effectively? Do you minimize student loans and family contributions with scholarships, grants, and income?
                                      Look for ways to improve your financing strategy. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                        >
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Scholarships</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            $
                                            <NumberFormat decimalScale={0} value={updatedHevalues.yearly_scholarship_amount * updatedHevalues.years_diff} displayType="text" thousandSeparator />

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Grants</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            $
                                            <NumberFormat decimalScale={0} value={updatedHevalues.yearly_grant_amount * updatedHevalues.years_diff} displayType="text" thousandSeparator />

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Other Financial Aid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            $
                                            <NumberFormat value={(updatedHevalues.year_aid_ends > 0 && updatedHevalues.year_aid_begins > 0) ? updatedHevalues.expected_yearly_finance_aid * (updatedHevalues.year_aid_ends - updatedHevalues.year_aid_begins + 1) : 0} displayType="text" thousandSeparator />
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Income Earned after Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.incomeEarnedAfterTaxesVal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.incomeEarnedAfterTaxesVal} prefix={moduleServices.module2Data.incomeEarnedAfterTaxesVal >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.incomeEarnedAfterTaxesVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Income earned after taxes includes all income from any module that shares the same timeline as this higher education module. For example, if your career path module overlaps with this module, that income will be calculated in this number. Go to the Income Statement for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Family Contribution</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            $
                                            <NumberFormat value={(updatedHevalues.family_end_year > 0 && updatedHevalues.family_start_year > 0) ? updatedHevalues.yearly_contribution * (updatedHevalues.family_end_year - updatedHevalues.family_start_year + 1) : 0} displayType="text" thousandSeparator />
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Federal Subsidized Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedSubBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedSubBeginningBalance} prefix={moduleServices.module2Data.fedSubBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedSubBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Federal Unsubsidized Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.fedUnSubPrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.fedUnSubPrincipal} prefix={moduleServices.module2Data.fedUnSubPrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.fedUnSubPrincipal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Private Student Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.privatePrincipal !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.privatePrincipal} prefix={moduleServices.module2Data.privatePrincipal >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.privatePrincipal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.summaryLoanPoints !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.summaryLoanPoints} prefix={moduleServices.module2Data.summaryLoanPoints >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.summaryLoanPoints < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Financing Sources</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.investmentAnalysisTotalCashAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.investmentAnalysisTotalCashAvailable} prefix={moduleServices.module2Data.investmentAnalysisTotalCashAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.investmentAnalysisTotalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.higherTotalExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.higherTotalExpenses} prefix={moduleServices.module2Data.higherTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.higherTotalExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Left Over</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classNames(classes.noInputText, classes.spanBlueText)}>
                                            {(moduleServices.module2Data.investmentAnalysisTotalCashAvailable !== undefined && moduleServices.module2Data.higherTotalExpenses !== undefined) ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                allowNegative={false}
                                                thousandSeparator
                                                value={moduleServices.module2Data.investmentAnalysisTotalCashAvailable + moduleServices.module2Data.higherTotalExpenses}
                                                prefix={(moduleServices.module2Data.investmentAnalysisTotalCashAvailable + moduleServices.module2Data.higherTotalExpenses) >= 0 ? '$' : '($'}
                                                suffix={(moduleServices.module2Data.investmentAnalysisTotalCashAvailable + moduleServices.module2Data.higherTotalExpenses) < 0 && ')'}	
                                              />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Liquid Assets Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable} prefix={moduleServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.investmentAnalysisTotalLiquidAssetsAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>

            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.dummyImgShowme) : classNames(classes.showMe, classes.showSection)} id="slide-five">

              <div className={(showMeHoverActive && !toggleValues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={toggleValues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !toggleValues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !toggleValues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                <div>
                  {(toggleValues.showMe && !toggleValues.showmeGraph) && (
                    <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                  )}
                  {(toggleValues.showMe && toggleValues.showmeGraph) && (<Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Refresh</Button>)}
                </div>
              </div>
              {(toggleValues.showMe && toggleValues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph2 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock} >
                        <h3>Federal Subsidized Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div aria-hidden="true" onClick={() => { setStudentLoanType('subsidized-table') }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph2Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph3 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Federal Unsubsidized Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div aria-hidden="true" onClick={() => { setStudentLoanType('unsubsidized-table') }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph3Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph4 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Private Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <div aria-hidden="true" onClick={() => { setStudentLoanType('private-table') }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={showMeData.graph4Data}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMeData.graph1 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Living Expenses during {educationTypes[moduleServices.module2Data.type_of_higher_education].label}</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                      {(showMeData.graph1 === true && updatedHevalues.start_year !== '' && updatedHevalues.graduation_year) && (
                        <div className={classes.totalValues}>Total Living Expenses from  {updatedHevalues.start_year} -{updatedHevalues.graduation_year}: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.Total} prefix='$' /></div>)}
                      {(showMeData.graph1 === true && (updatedHevalues.start_year === '' || updatedHevalues.graduation_year === '')) && (
                        <div className={classes.totalValues}>Total Living Expenses: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.Total} prefix='$' /></div>
                      )}
                    </div>
                  )}
                  {showMeData.graph5 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Understanding the True Cost of an Education</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph5Data}
                        />
                      </div>
                      <span>Note: Student Loans are shown as Loans Taken less Origination Fees paid</span>
                    </div>
                  )}
                  <hr className={classes.blueRow} />

                  <div className={classes.showDescriptionCntnt}>
                    <ReactTooltip id="leftTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.tooltipInfoNew)} />
                    <Grid container spacing={0}>
                      <Grid item sm={4} xs={12} spacing={0}>
                        <div className={classNames(classes.leftPanel, classes.leftMobile)}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h3>Higher Education Information</h3>
                                  <hr className={classes.blueLine} />
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.heightField} />
                              </tr>
                              <tr>
                                {' '}
                                <td> Tuition Costs </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.tuitionCosts !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.tuitionCosts} prefix='$' /> : '$0'}
                                </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td> Scholarships </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.scholarships !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.scholarships} prefix='$' /> : '$0'}</td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td> Grants </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.grants !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.grants} prefix='$' /> : '$0'}
                                </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td> Other Financial Aid </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.financialAid !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.financialAid} prefix='$' /> : '$0'} </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <hr className={classes.blueLine} />
                              <tr>
                                {' '}
                                <td> Capital Investment </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.capitalInvestment !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.capitalInvestment} prefix='$' /> : '$0'}
                                </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td> Excess Family Contribution </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.excessFamilyContribution !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.excessFamilyContribution} prefix='$' /> : '$0'} </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td> Loan (Interest only) + Origination Fees </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  {showMeData.calculationsData.loanPoints !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.loanPoints} prefix='$' /> : '$0'}
                                </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <hr className={classes.blueLine} />
                              <tr>
                                {' '}
                                <td> Total Personal Investment </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.valueField}>
                                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.capitalInvestment + showMeData.calculationsData.excessFamilyContribution + showMeData.calculationsData.loanPoints} prefix='$' />
                                </td>
                                {' '}
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                <td>
                                  <h3 className={classNames(classes.inputsTitle, classes.inputsTitleNew)}>Inputs</h3>
                                  <hr className={classes.blueLine} />
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.inputsData}>
                                  Higher Education Salary
                                  <div className={classes.infoIconNew} data-for="leftTooltip" data-tip="Input the higher income that you expect to earn after completing higher education. The best way to estimate this number would be to check average salaries of graduates from your school or to use sources like payscale.com to research careers and their expected pay. This income should be greater than Without Higher Education Salary.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall, classes.symCurrency)}>
                                  <span className={classes.dollarSymbol}>$</span>
                                  <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} customInput={TextField} thousandSeparator value={graphUpdatedData.higherEducationSalary} onValueChange={(e) => updateShowMeValues(e, 'higherEducationSalary')} onFocus={(e) => e.target.select()} />
                                </td>
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                <td className={classes.inputsData}>
                                  Without Higher Education Salary
                                  <div className={classes.infoIconNew} data-for="leftTooltip" data-tip="Input the income that you would expect to earn if you did not attend higher education and went right to the workforce. A good estimate for this number might be researching the average salary for a worker with a high school degree. This income should be less than Higher Education Salary.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall, classes.symCurrency)}>
                                  <span className={classes.dollarSymbol}>$</span>
                                  <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} customInput={TextField} thousandSeparator value={graphUpdatedData.highSchoolSalary} onValueChange={(e) => updateShowMeValues(e, 'highSchoolSalary')} onFocus={(e) => e.target.select()} />
                                </td>
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                <td className={classes.inputsData}>
                                  Higher Education Growth Rate on Salary
                                  <div className={classes.infoIconNew} data-for="leftTooltip" data-tip="Input the rate that you expect your income to grow by over the long-run. By attending higher education, you are increasing your lifetime earnings potential. So, this growth rate should be higher than the Without Higher Education Growth Rate on Salary.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall)}>
                                  <NumberFormat
                                    className={classes.formInput}
                                    allowNegative={false}
                                    customInput={TextField}
                                    value={graphUpdatedData.highEducationGrowthRateOnSalary}
                                    onValueChange={(e) => updateShowMeValues(e, 'highEducationGrowthRateOnSalary')}
                                    decimalScale = { isFocus!=='highEducationGrowthRateOnSalary'? 2: 5 }
                                    fixedDecimalScale={isFocus!=='highEducationGrowthRateOnSalary'}
                                    onFocus={ ( e ) => { e.target.select(); setIsFocus('highEducationGrowthRateOnSalary'); }}
                                    onBlur={() => { setIsFocus(''); }}
                                  />
                                  <span className={classes.percentSymbolNew}>%</span>
                                </td>
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                              <tr>
                                <td className={classes.inputsData}>
                                  Without Higher Education Growth Rate on Salary
                                  <div className={classes.infoIconNew} data-for="leftTooltip" data-tip="Input the rate that you expect your income to grow by over the long-run if you did not complete higher education. This rate should be lower than the Higher Education Growth Rate on Salary as studies show that higher levels of education lead to higher salaries over the long-run.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall)}>
                                  <NumberFormat
                                    className={classes.formInput}
                                    allowNegative={false}
                                    customInput={TextField}
                                    value={graphUpdatedData.highSchoolGrowthRateOnSalary}
                                    onValueChange={(e) => updateShowMeValues(e, 'highSchoolGrowthRateOnSalary')}
                                    decimalScale = { isFocus!=='highSchoolGrowthRateOnSalary'? 2: 5 }
                                    fixedDecimalScale={isFocus!=='highSchoolGrowthRateOnSalary'}
                                    onFocus={ ( e ) => { e.target.select(); setIsFocus('highSchoolGrowthRateOnSalary'); }}
                                    onBlur={() => { setIsFocus(''); }}
                                  />
                                  <span className={classes.percentSymbolNew}>%</span>
                                </td>
                              </tr>
                              <tr>
                                {' '}
                                <td className={classes.heightField} />
                                {' '}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                      <Grid item sm={8} xs={12} spacing={0}>
                        <ReactTooltip id="investTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.tooltipInfoNew)} />
                        <div className={classes.rightPanel}>
                          <h3>Higher Education Payback Analysis</h3>
                          <hr className={classes.blueLine} />
                          <button className={classes.tempNone} type="button" onClick={() => { setHigherEduIncm(true) }}>Temparory Button</button>
                          <div className={classes.containerRowNew}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={12} spacing={0} className={classNames(classes.rowSpace, classes.rowhSpace)}>
                                <table className={classes.piBreakdown}>
                                  <tbody>
                                    <tr>
                                      <td colSpan="2">
                                        <h3>Personal Investment Breakdown</h3>
                                        <hr className={classes.blueLine} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className={classNames(classes.squareBox, classes.greenBox)}>&nsbp;</span>
                                        <small>Capital Investment </small>
                                        <div
                                          className={classes.infoIcon}
                                          data-for="investTooltip"
                                          data-tip="Tuition Costs less Scholarships, Grants, and Other Financial Aid equals the amount of Personal Investment contributed to higher education. Your Personal Investment can be made up of the following sources of capital: Student Loans (Principal only),
                                            Income Earned during Higher Education, and Family Contribution. Student Loans are included because you will have to repay that Principal over the life of the loan. Click on the graph to see data on a yearly basis."
                                        >
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </td>
                                      <td>
                                        {showMeData.calculationsData.capitalInvestment !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.capitalInvestment} prefix='$' /> : '$0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className={classes.heightOneEight} />
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className={classNames(classes.squareBox, classes.blueBox)}>&nsbp;</span>
                                        <small>Excess Family Contribution</small>
                                        <div className={classes.infoIcon} data-for="investTooltip" data-tip="If the Annual Family Contribution is greater than the Annual Capital Investment, the difference will equal the excess contribution in that year. The sum of excess contributions in each year will equal the Excess Family Contribution. Click on the graph to see data on a yearly basis.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </td>
                                      <td>
                                        {showMeData.calculationsData.excessFamilyContribution !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.excessFamilyContribution} prefix='$' /> : '$0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className={classes.heightOneEight} />
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className={classNames(classes.squareBox, classes.orangeBox)}>&nsbp;</span>
                                        <small>Loan (Interest only) + Origination Fees</small>
                                        <div className={classes.infoIcon} data-for="investTooltip" data-tip="The cost of borrowing student loans is included in your Total Personal Investment. The Interest is the cost of borrowing, plus any Loan Points you may have paid upfront to receive those loans. See how changing the amount of student loans and the interest rates in the My Details section above can affect your payback period. Click on the graph to see data on a yearly basis.">
                                          <img src={infoIcon} alt="infoicon" />
                                        </div>
                                      </td>
                                      <td>
                                        {showMeData.calculationsData.loanPoints !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.loanPoints} prefix='$' /> : '$0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2"><hr className={classes.blueLine} /></td>
                                    </tr>
                                    <tr>
                                      <td>Total Personal Investment</td>
                                      <td>
                                        {showMeData.calculationsData.loanPoints !== undefined ? <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.calculationsData.capitalInvestment + showMeData.calculationsData.excessFamilyContribution + showMeData.calculationsData.loanPoints} prefix='$' /> : '$0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td />
                                    </tr>
                                  </tbody>
                                </table>
                              </Grid>
                              <Grid item sm={6} xs={12} spacing={0} className={classNames(classes.rowSpace, classes.rowhSpace)}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={showMeData.graph6Data}
                                />
                              </Grid>
                            </Grid>
                            <div className={classes.chartImage}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={showMeData.graph7Data}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                  </div>
                </div>

              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !toggleValues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={toggleValues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !toggleValues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !toggleValues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {toggleValues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Higher Education
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={toggleValues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {toggleValues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC handle Higher Education years considering Higher Education typically starts in the Fall and ends in the Spring?</h3>
                          <p>The FC works on an annual basis. We assume that Higher Education happens for a number of years rather than thinking from month to month. For example, if you plan to start Higher Education in 2020 and are going to attend for 3 years, you would be going to Higher Education from 2020-2022.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How do Student Loan Limits work? Why do they change?</h3>
                          <p>
                            All Federal Student Loans are subject to
                            {' '}
                            <a href="https://www.edvisors.com/college-loans/terms/loan-limits/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>borrowing limits</a>
                            .
                            {' '}
                            Annual Limits specify how much you can borrow in a given year. Aggregate Limits, or Cumulative Limits, specify how much you can borrow during your academic career. When you take out a Student Loan, you are subject to both the Annual Limits and Aggregate Limits. Those limits change based on Student Status as Independent or Dependent, what type of Higher Education you are attending such as Undergraduate, Graduate,
                            or Career programs, and how many years you have been in Higher Education. The FC takes all this information into account and estimates your Federal Student Loan Limits.
                          </p>
                          <p className={classes.spaceRow}>
                            For example, let&apos;s assume we have a Dependent Student in a 5-year Undergraduate Architecture program from Virginia Tech. See the chart below that shows how this student can borrow the max amount of loans during the 5-year program. For the 5th year, the Annual Limit would be $7,500 (shown with red border). However, the Remaining Aggregate Loan Eligibility is only $4,000 (shown with green border) after the 4th year.
                            The student can&apos;t borrow the $7,500 Annual Limit in the 5th year and can only borrow $4,000 (shown in yellow) to not exceed the $31,000 Aggregate Limit.
                          </p>
                          <div className={classes.heImage}>
                            <img src={heImage} alt="table" />
                          </div>
                          <p>
                            Private Student Loans usually have Annual Limits equal to the
                            {' '}
                            {' '}
                            <a href="http://www.investopedia.com/terms/c/cost-of-attendance.asp?ad=dirN&qo=serpSearchTopBox&qsrc=1&o=40186" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Cost of Attendance</a>
                            , which includes tuition and living expenses such as rent, food, and transportation, minus other Financial Aid.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>If I want to repay my loans by a certain date, how can I adjust my details above to do that?</h3>
                          <p>Easy. Just adjust the Term of the Loan. You can change that input to the number of years that gets you to your desired repayment year. As you change that input, the FC instantly configures how much you will have to pay monthly as well as on a total basis to hit that target. See how changing this input can impact your repayment schedule as well as your 5, 10, 15 year number..</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>The &apos;Year Repayment Begins&apos; field is not showing the year after I graduate. Why is this?</h3>
                          <p>The FC automatically calculates the year repayment begins for your student loans based on one condition. The FC assumes that repayment begins one year after your end year with an exception. If you activate another higher education module and that module begins right after this module, the FC calculates your repayment year differently. For an example of this exception, see the image below of the simulator panel.</p>
                          <p>What&apos;s a real life example of this exception? Let&apos;s say you plan on completing your undergraduate program in four years and then you plan to immediately go back to graduate school. The year repayment begins would be after graduate school, or that other higher education module.</p>
                          <p>It&apos;s important to note that if the other higher education module does not immediately follow this higher education module, the year repayment begins will be one year after the end year.</p>
                          <div className={classes.yearRepay}>
                            <img src={yearRepay} alt="yearimage" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>If I only plan to rent for 9 months out of the year, how should I input my monthly rent expense?</h3>
                          <p>
                            Renting for only 9 months out of the year is a great way to save on money. To enter your monthly rent expense requires a quick calculation since the FC runs on a yearly basis. Let&apos;s say you are renting an apartment for $800 per month for 9 months. That means you will pay a total of $7,200 in rent that year. But, if you enter $800 per month in the FC, the FC will calculate $800 multiplied by 12 months or $9,600 in rent expense for the year.
                            To make sure you enter the right monthly rent expense, use the quick formula below:
                            <div className={classes.fcTerms}>
                              <span>Monthly Rent Expense = (Monthly Rent Expense x 9 months) / 12</span>
                              <span>For the example above, the calculation for a 9 month rent scenario would be as follows:</span>
                              <span>($800 x 9 months) = $7,200 / 12 = $600 Monthly Rent Expense</span>
                            </div>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How can I see the numbers that support the Student Loans graphs in the Show Me section?</h3>
                          <p>Just click anywhere on any of the Student Loans graphs in the Show Me section and a table will pop up allowing you to look at the repayment of your Student Loans month by month.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the payback analysis work?</h3>
                          <p>The payback analysis is a unique tool that empowers you to find the number of years it takes to pay back your education. There&apos;s a lot packed into that sentence. Let&apos;s break it down.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              What does the word &apos;payback&apos; mean in this situation? Payback is the period of time (in years) that it takes for you to recoup your total investment, or total amount of dollars you spent on your education. It&apos;s important to note that this does not include living expenses such as room and board, food, entertainment, etc.
                              because you would have living expenses regardless of going to school or not. We are looking to determine how quickly you recoup your investment.
                            </span>
                            <span>The FC calculates your &apos;Total Personal Investment&apos; by the following formula:</span>
                            <span>Tuition Costs - (Scholarships + Grants + Other Financial Aid) + Excess Family Contribution (see Definitions) + Loan (Interest only) + Loan Points</span>
                            <span>Tuition Costs less free money sources (Scholarships, Grants, Other Financial Aid) equals the amount of money that you personally need to contribute to your education. And, if that consists of loans, we need to factor in the cost of borrowing those loans, which is interest and origination fees to make sure we accurately calculate your total investment.</span>
                            <span>Then, the FC walks you through 4 inputs: Higher Education Salary, Higher Education Growth Rate on Salary, Without Higher Education Salary, and Without Higher Education Growth Rate on Salary. The reason that you complete Higher Education is to earn your degree and obtain a higher paying job than if you only completed high school. That&apos;s the key comparison here. How much more income will I earn by going to school versus not going to school?</span>
                            <span>Based on your inputs, the FC will compare the Higher Education Net Income and Without Higher Education Net Income each year into the future, which includes estimated taxes. The difference between the Higher Education Net Income and Without Higher Education Net Income is how you will recoup your investment!</span>
                          </div>
                          <div className={classes.higherImage}>
                            <img src={higherImage} alt="table" />
                          </div>
                          <div className={classes.paybackRow}>
                            <p>* Payback is defined as the year that Cumulative Gained Income (the additional income earned by going to Higher Education vs not going to Higher Education) is greater than Cumulative Investment (the total amount of money invested in Higher Education).</p>
                            <p>** Payback Period begins in and includes Year 2019, which is the year after Higher Education ends.</p>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How can I use the payback analysis to compare different scenarios?</h3>
                          <p>
                            The payback analysis is a great tool for comparing the relative cost of higher education. To use the payback analysis to compare scenarios, it&apos;s simple. Just keep the &quot;Without Higher Education Salary&quot; and &quot;Without Higher Education Growth Rate on Salary&quot; the same when using the analysis.
                            Let&apos;s say we have two higher education scenarios that are fully completed. For this example, we&apos;ll just call them Scenario A and Scenario B.
                          </p>
                          <div className={classes.orderPoints}>
                            <span>1) Load Scenario A into the higher education module</span>
                            <span>2) Go to the Show Me section and scroll to the Higher Education Payback Analysis</span>
                            <span>3) Double check the Higher Education Information to make sure you have the right scenario loaded</span>
                            <span>4) Explore the Inputs section of the analysis and tweak your inputs until you feel they&apos;re complete</span>
                            <span>5) See your Payback Period automatically calculate</span>
                            <span>6) Then, go back up to the My Details section and load Scenario B into the higher education module</span>
                            <span>7) Go to the Show Me section and scroll to the Higher Education Payback Analysis</span>
                            <span>8) Double check the Higher Education Information to make sure you have the right scenario loaded</span>
                            <span>9) Complete Inputs section of the analysis while keeping the same inputs used in Scenario A for Without Higher Education Salary and Growth Rate on Salary</span>
                            <span>10) See your Payback Period automatically calculate</span>
                          </div>
                          <div className={classes.fcTerms}>
                            <span>
                              Now, since we have set the opportunity cost of going to higher education - which is the missed opportunity of going straight to work and earning income - equal to each other in both scenarios, the difference in payback period will be strictly due to the cost of that education and how you choose to finance that cost in each scenario.
                              For example, more scholarships will lower your personal investment and lower your payback period. Why? The lower your personal investment, the less money you are trying to payback.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={toggleValues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {toggleValues.definations && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">Accrued Interest</a></h3>
                          <p>
                            Interest typically begins to accrue as soon as you receive your loan depending on the type of student loan. In the FC, interest accrues on a monthly basis for unsubsidized and private student loans.
                            Each month the beginning balance (the amount of loans you have to repay) multiplied by the interest rate divided by 12 (to convert to a monthly rate) is the minimum interest payment that needs to be made at the end of the month to avoid accrued interest.
                            If you don&apos;t pay that minimum interest, that amount will accrue and be capitalized (added to your loan balance). If you can afford to make minimum interest payments on your student loans while you&apos;re in school,
                            plan to make them as this could save you thousands of dollars in accrued interest. Watch this
                            {' '}
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer" className={classes.noLink}>video here</a>
                            {' '}
                            for a great explanation of interest!
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              For example, let&apos;s say you have a $10,000 loan balance at the beginning of this month. The interest rate on that loan is 6%, which is an annual number.
                              At the end of the month, assuming you don&apos;t plan to make any payment, the $10,000 would be multiplied by 6% and divided by 12 (interest rates are annual numbers so divide by 12 to make that a monthly number) and get $50 of accrued interest. Now, at the end of that month,
                              your beginning balance of $10,000 plus $50 of accrued interest equals a higher $10,050 loan balance. And, the cycle repeats itself with interest growing every month you don&apos;t make the minimum interest payment.
                              Review the formula and table below to understand how to calculate accrued interest if you do not make a payment on your loan
                            </span>
                            <span><u>If a loan accrues on a monthly basis, payment is due monthly, no payment is made, and the interest is capitalized at the end of the month:</u></span>
                            <span className={classes.noTopmargin}>Beginning Balance x Annual Interest Rate / 12 = Monthly Accrued Interest</span>
                            <span className={classes.noTopmargin}>$10,000 x 6% / 12 = $50.00 Monthly Accrued Interest</span>
                            <span>Beginning Balance + Accrued Interest Capitalized = Next Month Beginning Balance</span>
                            <span className={classes.noTopmargin}>$10,000.00 + $50.00 = $10,050.00 Next Month Beginning Balance</span>
                            <span>How does this add up over months or years of schooling? In 6 months, your balance has grown to $10,303; in 12 months, $10,616; and, in 18 months without making a minimum interest payment, the balance grows by almost $1,000! Again, if you can afford to make minimum interest payments on your student loans while you&apos;re in school, plan to make them as this could save you thousands of dollars in accrued interest.</span>
                          </div>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={accruedImg} alt="table" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://turbotax.intuit.com/tax-tools/tax-tips/Tax-Deductions-and-Credits/What-is-the-American-Opportunity-Tax-Credit-/INF14140.html" target="_blank" rel="noopener noreferrer">American Opportunity Tax Credit (AOTC)</a></h3>
                          <p>
                            A
                            {' '}
                            <a href="https://turbotax.intuit.com/tax-tools/tax-tips/Taxes-101/What-Are-Tax-Credits-/INF14393.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax Credit</a>
                            &nbsp;
                            is a dollar-for-dollar reduction of the Federal Income Taxes that you owe. For example, if you owe $10,000 in Federal Income Taxes and qualify for a $2,000 Tax Credit, your income taxes would be reduced dollar-for-dollar and you would owe $8,000.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              The American Opportunity Tax Credit (AOTC) is available for Independent students who are paying for their education and can only be applied for four years of Higher Education. The student must be enrolled in at least one academic semester and maintain half-time status or more in a program leading to degree or other credential. Only one of the AOTC or Lifetime Learning Credit can be applied in a given year. The FC assumes that the AOTC will be applied
                              four times before the Lifetime Learning Credit will be applied since the AOTC will always be higher.
                            </span>
                            <span>AOTC is calculated by taking 100% of your first $2,000 of educational expenses plus 25% of the expenses in excess of the first $2,000. The maximum credit is $2,500. Important to note that this credit is refundable, which means even if your income taxes are $0, you will receive a cash refund. That refund is limited to 40% of the credit. For example, if your educational expenses total $4,000 in a year, the calculation would be as follows.</span>
                            <span>$2,000 x 100% = $2,000 ; ($4,000 - $2,000) x 25% = $500 + $2,000 = $2,500 AOTC</span>
                            <span>And, if you had no income taxes in the above example, you would receive 40% of the $2,500 credit, which equals a $1,000 cash refund.</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentloanhero.com/featured/capitalized-interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">Capitalized Interest</a></h3>
                          <p>Interest starts to accrue the day your loan is given to you. In the FC, interest accrues on a monthly basis. Before reviewing this definition, make sure you review the accrued interest definition above.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              If you choose to defer interest payments until repayment begins, which means you are choosing not to make interest payments while you are in school, that interest will accrue and capitalize at the end of each month. Capitalize means that accrued interest is added to your loan&apos;s current principal (see definition below). When interest capitalizes, you now have a larger loan balance. And, your interest will now be calculated off that larger loan balance.
                              If you don&apos;t make minimum interest payments, this process will continue to repeat and your loan balance will grow larger. If you can afford to make interest payments during school,
                              it could save you thousands of dollars over the life of the loan. Easiest to see in an example.
                            </span>
                            <span>Let&apos;s say we have two different loans: Loan A and Loan B. They share the same $10,000 beginning balance, 8% interest rate, 10 year maturity, and are paid on a monthly basis. Both loans will start the repayment period after 5 years. Loan A shows the effects of not making minimum interest payments during that 5 year period and Loan B the effects of making the $800 minimum interest payments each year.</span>
                            <span>In the following graph, you can see that each year interest is accruing at a faster rate. And, you can see in the below table that Loan B pays much less over the life of the loan than Loan A.</span>
                          </div>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={capitalGraph} alt="table" />
                          </div>
                          <div className={classes.galleryImage}>
                            <img src={capitalTable} alt="table" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.learnhowtobecome.org/vocational-trade-schools/" target="_blank" rel="noopener noreferrer">Career School</a></h3>
                          <p>Schools, institutions, and educational programs that specialize in the skilled trades, applied sciences, modern technologies, and career preparation.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Car Payment</h3>
                          <p>
                            The monthly payment made to
                            {' '}
                            <a href="https://www.edmunds.com/car-leasing/quick-guide-to-leasing-a-new-car.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>lease a car </a>
                            {' '}
                            over a period of time. Thinking of leasing instead of buying? Check out some automaker and dealership websites for current leasing offers to get a benchmark for how much your lease could charge for a monthly Car Payment.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://en.wikipedia.org/wiki/College" target="_blank" rel="noopener noreferrer">College</a></h3>
                          <p>An institution of higher learning that follows High School and offers a bachelor&apos;s degree - also known as undergraduate school.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentloanhero.com/featured/how-student-loan-interest-works/" target="_blank" rel="noopener noreferrer">Defer Interest Payments</a></h3>
                          <p>
                            When you do not make minimum interest payments while you are in school or in a grace period. Each month you defer interest payments, that interest accrues,
                            {' '}
                            {' '}
                            <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>capitalizes</a>
                            , and adds to your balance.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/fafsa/filling-out/dependency" target="_blank" rel="noopener noreferrer">Dependent Student</a></h3>
                          <p>
                            A student who does not meet any of the criteria for an independent student (see Independent Student definition). A Dependent Student is subject to different
                            {' '}
                            {' '}
                            <a href="https://www.edvisors.com/media/images/article-charts/direct-loans-annual-borrowing-limits-bar-chart-1700x1142.png" target="_blank" rel="noopener noreferrer" className={classes.noLink}>loan limitations</a>
                            {' '}
                            than an Independent Student.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Excess Family Contribution</h3>
                          <p>
                            Calculated by analyzing whether the annual Family Contribution is greater than annual Tuition Costs less Scholarships, Grants, & Other Financial Aid. If Family Contribution is greater than the Capital Invested,
                            the FC considers this Excess Family Contribution as additional investment. In Higher Education&apos;s Show Me section under the Payback analysis, use the Show Table button to see the details of this calculation
                            and its importance in the Payback analysis.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentloanhero.com/featured/expected-family-contribution-financial-aid/" target="_blank" rel="noopener noreferrer">Family Contribution</a></h3>
                          <p>The amount of money your family will contribute towards your education.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/types/loans/subsidized-unsubsidized" target="_blank" rel="noopener noreferrer">Federal Student Loan</a></h3>
                          <p>A loan funded by the federal government to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                              {' '}
                              from the Federal Student Aid office for a fact sheet on federal student loans.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/types/loans/subsidized-unsubsidized" target="_blank" rel="noopener noreferrer">Federal Subsidized Student Loan</a></h3>
                          <p>A loan funded by the federal government to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              The federal subsidized student loan has one crucial difference than other loans. The government pays the interest while you are in school and in a grace period. What does that mean exactly?
                              That means no interest will accrue on your loan until repayment period begins. For example, if you borrow $10,000 in federal subsidized student loans during higher education,
                              you will have to repay $10,000. For other loans, that balance has the potential to grow because of accrued interest.
                            </span>
                            <span>
                              <a target="_blank" rel="noopener noreferrer" href="https://studentaid.ed.gov/sa/types/loans/interest-rates">Click here to check current interest rates</a>
                              . Then, use CTRL + F to search for &quot;Direct Subsidized Loans and Direct Unsubsidized Loans&quot; on that page. This will take you directly to the current interest rate for federal subsidized and unsubsidized loans and the current fees for those loans.
                            </span>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                              {' '}
                              from the Federal Student Aid office for a fact sheet on federal student loans.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/types/loans/subsidized-unsubsidized" target="_blank" rel="noopener noreferrer">Federal Unsubsidized Student Loan</a></h3>
                          <p>A loan funded by the federal government to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              Unlike federal subsidized loans, interest on federal unsubsidized student loans accrues starting from the day you receive any money and continues throughout the life of the loan until that loan is fully repaid. Interest accrues on a monthly basis. However, interest does not
                              &nbsp;
                              <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">capitalize</a>
                              &nbsp;
                              until repayment begins. In other words, every month that you don&apos;t make payments, you are accruing interest, but your loan balance is not growing. On the day repayment begins, that accrued interest capitalizes, which means that interest is added to the loan balance. Go to the accrued interest definition above to see how accrued interest works and how quickly it can affect your loan repayment.
                            </span>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/types/loans/interest-rates" target="_blank" rel="noopener noreferrer">Click here to check current interest rates</a>
                              . Then, use CTRL + F to search for &quot;Direct Subsidized Loans and Direct Unsubsidized Loans&quot; on that page. This will take you directly to the current interest rate for federal subsidized and unsubsidized loans and the current fees for those loans.
                            </span>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                              {' '}
                              from the Federal Student Aid office for a fact sheet on federal student loans.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/repay-loans/understand" target="_blank" rel="noopener noreferrer">Grace Period</a></h3>
                          <p>
                            A six-month period after you graduate, leave school, or drop below half-time status before your student loan repayment period begins. For example,
                            if you graduate at the end of May, you will have to start repaying your student loan six months after that, which would be the beginning of December.
                            It&apos;s important to note that interest on unsubsidized and private student loans continues to accrue even during the grace period. Again, if you can afford in your higher education
                            scenario to make interest payments before your repayment begins that could save you thousands of dollars in&nbsp;
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">accrued interest</a>
                            .
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              Review the definition of&nbsp;
                              <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">accrued interest</a>
                              &nbsp;
                              above to understand how quickly&nbsp;
                              <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/Interest-tutorial/Interest-basics-tutorial/v/introduction-to-Interest" target="_blank" rel="noopener noreferrer">accrued interest</a>
                              &nbsp;
                              can add to your student loan balance.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://en.wikipedia.org/wiki/Graduate_school" target="_blank" rel="noopener noreferrer">Graduate School</a></h3>
                          <p>A school that awards advanced academic degrees (i.e. master&apos;s and doctoral degrees) with the general requirement that students must have earned a previous undergraduate (bachelor&apos;s) degree.</p>
                          <div className={classes.fcTerms}>
                            <span><a href="https://www.princetonreview.com/grad-school-search" target="_blank" rel="noopener noreferrer">Use this link to search for graduate schools.</a></span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/repay-loans/understand" target="_blank" rel="noopener noreferrer">Grant</a></h3>
                          <p>
                            Money offered to help pay for your education by a school or other institution such as a government organization, corporation, foundation or trust. This money does not need to be repaid.
                            Check out
                            {' '}
                            <a href="https://www.goingmerry.com/" target="_blank" rel="noopener noreferrer">this link</a>
                            &nbsp;
                            to find some scholarships and grants.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/how-does-health-insurance-work-3306069" target="_blank" rel="noopener noreferrer">Health Insurance Costs outside Premium</a></h3>
                          <p>
                            These costs include paying your
                            {' '}
                            <a href="https://www.healthcare.gov/glossary/deductible/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>deductible</a>
                            {' '}
                            and
                            {' '}
                            <a href="http://www.investopedia.com/terms/c/copay.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>co-pay</a>
                            . A deductible is the amount of money an individual pays for expenses before their insurance plan starts to pay.
                            A co-pay is an out of pocket expense for health care services that is charged at the time the service is rendered.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.verywellhealth.com/health-insurance-premiums-1738769" target="_blank" rel="noopener noreferrer">Health Insurance Premium</a></h3>
                          <p>
                            Just like a monthly car insurance premium or monthly home insurance premium, your health insurance premium is the monthly fee that you pay for your health insurance coverage. Even if you do not require any health services such as a doctor&apos;s visit or a surgery,
                            you have to pay your monthly health insurance premium to maintain your coverage.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/fafsa/filling-out/dependency" target="_blank" rel="noopener noreferrer">Independent Student</a></h3>
                          <p>
                            An independent student is one of the following: at least 24 years old, married, a graduate or professional student, a veteran, a member of the armed forces, an orphan, a ward of the court, or someone with legal dependents other than a spouse such as children or other family members.
                            An Independent Student is subject to different
                            {' '}
                            {' '}
                            <a href="https://www.edvisors.com/college-loans/terms/loan-limits/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>loan limitations</a>
                            {' '}
                            than a Dependent Student.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest</a></h3>
                          <p>
                            The fee a lender charges you for borrowing money. Monthly loan payments are made up of two parts: principal repayment and interest. Interest is a
                            {' '}
                            {' '}
                            <a href="https://studentloanhero.com/featured/how-student-loan-Interest-works/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>loan expense</a>
                            {' '}
                            calculated as a percentage of the outstanding principal.
                            See an example of a typical loan amortization schedule
                            {' '}
                            {' '}
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            . Amortization is the process of paying off your loans in regular payments over a period of time.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>Interest is calculated by multiplying the outstanding principal by the interest rate. For example, let&apos;s say we have a $6,000 Federal Subsidized Student Loan outstanding with a 5% annual interest rate that we pay on a monthly basis. The calculation for next month&apos;s interest payment would be as follows:</span>
                            <span>$6,000 Balance x 5% Interest Rate / 12 (to make the interest rate on a monthly basis) = $25</span>
                            <span>Don&apos;t forget, as you make monthly payments and start paying down your loan, your outstanding balance will decrease, which will decrease your interest portion of the payment.</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest Rate</a></h3>
                          <p>
                            The amount a lender charges you for using their money, typically expressed as an annual percentage of the principal - loan amount outstanding. Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank,
                            it&apos;s very similar to renting an apartment.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>Instead of borrowing a place to live, you are borrowing money usually from a bank. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank uses the interest rate in the formula below:</span>
                            <span>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</span>
                            <span>
                              To learn more about how student loan interest works,
                              {' '}
                              {'  '}
                              <a href="https://studentloanhero.com/featured/how-student-loan-Interest-works/" target="_blank" rel="noopener noreferrer">click here.</a>
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://turbotax.intuit.com/tax-tools/tax-tips/Tax-Deductions-and-Credits/What-is-the-Lifetime-Learning-Tax-Credit-/INF14341.html" target="_blank" rel="noopener noreferrer">Lifetime Learning Tax Credit</a></h3>
                          <p>
                            A
                            {' '}
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://turbotax.intuit.com/tax-tools/tax-tips/Taxes-101/What-Are-Tax-Credits-/INF14393.html" className={classes.noLink}>Tax Credit</a>
                            {' '}
                            is a dollar-for-dollar reduction of the Federal Income Taxes that you owe. For example, if you owe $10,000 in Federal Income Taxes and qualify for a $2,000 Tax Credit, your income taxes would be reduced dollar-for-dollar and you would owe $8,000.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              The Lifetime Learning Tax Credit is available for Independent students who are paying for their education and, unlike the AOTC, can be applied for unlimited years of Higher Education. The student must be enrolled in at least one academic semester and maintain half-time status or more in a program leading to degree or other credential. Only one of the AOTC or Lifetime Learning Credit can be applied in a given year.
                              The FC assumes that the AOTC will be applied four times before the Lifetime Learning Credit will be applied since the AOTC will always be higher.
                              Once AOTC has been applied four times, the Lifetime Learning Credit will be applied.
                            </span>
                            <span>The credit is calculated by taking 20% of a maximum $10,000 of educational expenses. The maximum credit is $2,000. The credit is non-refundable meaning if you owe no income taxes, you will not receive a credit in that year. For an example of the calculation, say your educational expenses total $8,000 in a year, the calculation would be as follows.</span>
                            <span>$8,000 x 20% = $1,600 credit</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.investopedia.com/terms/o/originationpoints.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Origination Fees</a></h3>
                          <p>
                            One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are calculated as a percentage of the loan and are typically required for all loans. For student loans, origination fees can vary, but a
                            {' '}
                            {' '}
                            <a href="https://studentloanhero.com/featured/student-loan-origination-fee/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>good estimate</a>
                            {' '}
                            would be anywhere from 0.5% to 4.5% depending on the loan.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/types/loans/interest-rates" target="_blank" rel="noopener noreferrer">Click here to check origination fees on federal student loans</a>
                              .
                              {' '}
                              Then, use CTRL + F to search for &quot;Direct Subsidized Loans and Direct Unsubsidized Loans&quot; on that page. This will take you directly to the current interest rate for federal subsidized and unsubsidized loans and the current fees for those loans.
                            </span>
                            <span>For example, if you take a $10,000 student loan and pay 2.0% origination fees, the fee calculation would be 2.0% x $10,000 = $200</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentloanhero.com/featured/how-student-loan-interest-works/" target="_blank" rel="noopener noreferrer">Make Interest Payments</a></h3>
                          <p>If you decide to take a student loan to pay for your education, avoiding accrued interest should be a priority. How do you avoid accrued interest? By making interest payments before your repayment period begins. Your repayment period typically begins six months after graduation. All the time between when you first receive your student loan and six months after graduation interest will accrue (see accrued interest definition above).</p>
                          <div className={classes.fcTerms}>
                            <span>To avoid accrued interest being capitalized and add to your student loan balance, you can make interest payments while you&apos;re in school. Toggle this option on and off to see the effects on accrued interest and total interest paid. If you can afford to make interest payments during school, it could save you thousands of dollars when you go to repay that loan.</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.usa.gov/military-colleges" target="_blank" rel="noopener noreferrer">Military School</a></h3>
                          <p>An educational institution which prepares candidates for service in the officer corps of the army, the navy, marine corps, air force or coast guard.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Other Financial Aid</h3>
                          <p>Any other financial aid in addition to scholarships or grants. Other financial aid would be any additional money offered to help pay for your education by a school or other institution that does not require repayment.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://inkwiry.com/finance-explained/loan-principal" target="_blank" rel="noopener noreferrer">Principal</a>
                          </h3>
                          <p>
                            The total sum of money borrowed. For unsubsidized student loans or private student loans, principal is the total sum of money borrowed plus any accrued interest that has been
                            {' '}
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" className={classes.noLink}>capitalized</a>
                            .
                          </p>
                          <div className={classes.fcTerms}>
                            <span>Every time you make a monthly payment, a portion of that payment goes toward the interest and the rest is used to repay your principal.</span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/an-overview-of-private-student-loans-315835" target="_blank" rel="noopener noreferrer">Private Student Loans</a></h3>
                          <p>A loan provided by banks, credit unions or other lending institutions to help pay for your education that charges an interest rate and requires repayment. This loan will usually charge the highest interest rate of all student loans</p>
                          <div className={classes.fcTerms}>
                            <span>
                              Unlike federal subsidized loans, interest on
                              {' '}
                              {' '}
                              <a href="http://www.investopedia.com/university/student-loans/student-loans3.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                              {' '}
                              accrues starting from the day you receive any money and continues throughout the life of the loan until that loan is fully repaid. Interest accrues on a monthly basis. And, unlike federal unsubsidized loans, interest
                              {' '}
                              {' '}
                              <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">capitalizes</a>
                              {' '}
                              at the end of each month. Every month that you don&apos;t make payments including during higher education, you are accruing interest.
                              That accrued interest
                              {' '}
                              {' '}
                              <a href="https://studentloanhero.com/featured/capitalized-Interest-affect-student-loans/" target="_blank" rel="noopener noreferrer">capitalizes</a>
                              {' '}
                              every month, or more simply put, is added to your loan balance. Go to the accrued interest definition above to see how accrued interest works and how quickly it can affect your loan repayment.
                            </span>
                            <span>
                              <a href="https://www.credible.com/student-loans" target="_blank" rel="noopener noreferrer">Click here to check current interest rates.</a>
                              {' '}
                              Click on &quot;Get My Loan&quot; in the &quot;I&apos;m a student&quot; section and go through the process to find
                              {' '}
                              <a href="https://www.investopedia.com/articles/personal-finance/042415/earn-credit-card-rewards-paying-student-loans.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                              {' '}
                              that work for you. To determine how much
                              {' '}
                              <a href="https://www.investopedia.com/articles/personal-finance/042415/earn-credit-card-rewards-paying-student-loans.asp" target="_blank" rel="noopener noreferrer">private student loans</a>
                              {' '}
                              you might need, build your entire higher education scenario without any
                              {' '}
                              <a href="https://www.investopedia.com/articles/personal-finance/042415/earn-credit-card-rewards-paying-student-loans.asp" target="_blank" rel="noopener noreferrer">private student loans.</a>
                              {' '}
                              Then, see how much more money you need each year to finance your education.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/understanding-college-tuition-room-and-board-795382" target="_blank" rel="noopener noreferrer">Room and Board</a></h3>
                          <p>When living on campus, room and board is the cost of the room provided to you by the school such as a dorm room or an on campus apartment. Plus, the cost of your meal plan.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://studentaid.ed.gov/sa/glossary#letter_s" target="_blank" rel="noopener noreferrer">Scholarship</a></h3>
                          <p>
                            Money offered to help pay for your education by a school or other institution that does not require repayment. Scholarships are typically
                            {' '}
                            <a href="https://bigfuture.Collegeboard.org/pay-for-College/grants-and-scholarships/where-to-find-College-scholarships" target="_blank" rel="noopener noreferrer">awarded </a>
                            {' '}
                            based on academic or other individual achievements.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.investopedia.com/terms/s/student-debt.asp" target="_blank" rel="noopener noreferrer">Student Loan</a></h3>
                          <p>
                            A loan provided by the federal government or a financial institution to help pay for your education that charges an interest rate and requires repayment. Federal student loans usually charge a lower interest rate than private student loans. Private student loans are provided by a financial institution.
                            {' '}
                            {' '}
                            <a href="https://www.edvisors.com/College-loans/terms/loan-limits/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Undergraduate</a>
                            {' '}
                            student loans and
                            {' '}
                            {' '}
                            <a href="https://www.edvisors.com/College-loans/federal/stafford/graduate/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>graduate</a>
                            {' '}
                            student loans have limits on how much loans you can take.
                          </p>
                          <div className={classes.fcTerms}>
                            <span>
                              <a href="https://studentaid.ed.gov/sa/sites/default/files/federal-loan-programs.pdf" target="_blank" rel="noopener noreferrer">Check out this link</a>
                              {' '}
                              from the Federal Student Aid office for a fact sheet on federal student loans.
                            </span>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Student Loan Interest Tax Deduction</h3>
                          <p>
                            A number of factors are used to determine if you are qualified to take a Student Loan Interest Tax Deduction such as how much annual income you have earned or if you paid back any capitalized interest in the year. Please click
                            {' '}
                            {' '}
                            <a href="http://www.nolo.com/legal-encyclopedia/tax-deductions-student-loans.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            for a detailed understanding of possible Student Loan Tax Deductions that Inkwiry&apos;s FC calculates for you.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.investopedia.com/terms/t/termtomaturity.asp" target="_blank" rel="noopener noreferrer">Term of the Loan</a></h3>
                          <p>The length of time in years that you have to repay your loan. Student loans are typically 10 years in length, but you can repay your loans early if you can afford to. Change your term of the loan and see how that affects total interest repaid. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Liquid Assets Available</h3>
                          <p>Calculated as cash plus short-term investments plus long-term investments at the end of the year before the start year. For example, if you plan to attend higher education in year 2025, total liquid assets available would show you how much cash, short-term, and long-term investments you have at the end of year 2024.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Principal & Interest</h3>
                          <p>Calculated by taking the principal, or total sum of money borrowed, plus the total interest, which is the total cost of borrowing the principal. In other words, the total principal and interest is the amount of money you would pay over the term of the loan to fully repay your credit card, student loan, car loan, or mortgage.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <p>See the definitions for principal and interest above.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/understanding-college-tuition-room-and-board-795382" target="_blank" rel="noopener noreferrer">Tuition and Fees</a></h3>
                          <p>
                            The price that a school, college, or university charges for classes. Be sure to note the difference between cost of attendance and tuition.
                            {' '}
                            <a href="http://www.investopedia.com/terms/c/cost-of-attendance.asp?ad=dirN&qo=serpSearchTopBox&qsrc=1&o=40186" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Cost of attendance</a>
                            {' '}
                            includes tuition and living expenses such as rent, food, and transportation. You can find the tuition for all schools, colleges, or universities
                            {' '}
                            <a href="https://nces.ed.gov/collegenavigator/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.nationwide.com/average-cost-of-utilities.jsp" target="_blank" rel="noopener noreferrer">Utilities</a></h3>
                          <p>The total price you pay for water, electricity and gas, trash and recycling, home phone, and internet and cable. Some or all utilities can be included in your rent when renting a home or an apartment. If you own real estate, you will be responsible for paying all utilities.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={toggleValues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {toggleValues.quickLinks && (

                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>College</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://collegescorecard.ed.gov/" className={classes.noLink}>Finding a college that&apos;s right for you: compare schools by programs/degrees, size, and more</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.collegedata.com/" className={classes.noLink}>Finding a college that&apos;s right for you: tuition, room & board, admissions details, and more</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.wsj.com/articles/compare-any-two-colleges-from-the-wsj-the-college-rankings-11551886824" className={classes.noLink}>Compare two colleges side-by-side</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://bigfuture.collegeboard.org/get-started/know-yourself/college-what-its-all-about-and-why-it-matters" className={classes.noLink}>What college can do for you</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.payscale.com/college-salary-report" className={classes.noLink}>College salary report: the best colleges based on salary potential for 2018-19</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Career School</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.careercolleges.com/" className={classes.noLink}>Search for career schools near you</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://careerschoolnow.org/" className={classes.noLink}>Explore trades and find the education needed to get there</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://careerschoolnow.org/careers/trade-school-vs-traditional-college#skilled-trade-salaries" className={classes.noLink}>Why you should consider career school</a>
                            <a target="_blank" rel="noopener noreferrer" href="http://mycollegeguide.org/blog/2016/08/trade-school-vs-college/" className={classes.noLink}>What&apos;s the difference between career (trade) school or college</a>
                            <a target="_blank" rel="noopener noreferrer" href="http://mycollegeguide.org/blog/2011/04/vocational-school/" className={classes.noLink}>Different types of trades at career school</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Military School</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.bestcolleges.com/resources/rotc-programs/" className={classes.noLink}>How to know if military school is right for you</a>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.military.com/education/money-for-school/education-benefits-in-the-military.html" className={classes.noLink}>Higher education and the military&apos;s financial assistance</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.goarmy.com/rotc/scholarships.html" className={classes.noLink}>Scholarships in the military</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Graduate School</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/is-grad-school-worth-it/" className={classes.noLink}>Factors to consider when planning for graduate school</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.investopedia.com/articles/personal-finance/042914/when-grad-school-worth-it.asp" className={classes.noLink}>Is graduate school right for me?</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Scholarships</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://myscholly.com/" className={classes.noLink}>Recommended scholarship search</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.goingmerry.com/" className={classes.noLink}>Another scholarship search</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.cappex.com/" className={classes.noLink}>Another recommended scholarship search tool </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.ed.gov/sa/types/grants-scholarships/finding-scholarships" className={classes.noLink}>Scholarships are free money. So, how do you find them?</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://bigfuture.collegeboard.org/scholarship-search" className={classes.noLink}>Search for scholarships that could be available to you here</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Grants</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.ed.gov/sa/types/grants-scholarships" className={classes.noLink}>Grants are free money. So, how do you find them?</a>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.collegescholarships.org/grants/federal.htm" className={classes.noLink}>Federal grants and how to get them</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.salliemae.com/college-planning/financial-aid/understand-college-grants/" className={classes.noLink}>Additional resources on available grants</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Financial Aid</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://fafsa.ed.gov/" className={classes.noLink}>Apply for financial aid: Free Application for Federal Student Aid (FASFA)</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Dependent or Independent</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.ed.gov/sa/fafsa/filling-out/dependency" className={classes.noLink}>Most students are dependent. What are you?</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.edvisors.com/fafsa/forms/tutorial/dependency-overrides/" className={classes.noLink}>Independent students qualify for more financial aid. But, do I qualify as independent?</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Student Loans</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.nerdwallet.com/article/finance/budgeting-for-college-students" className={classes.noLink}>6 college money lessons</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.edvisors.com/college-loans/terms/loan-limits/" className={classes.noLink}>How much loans can dependent and independent students take?</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.gov/understand-aid/types/loans/subsidized-unsubsidized" className={classes.noLink}>Federal Subsidized and Federal Unsubsidized Student Loans - interest rates, origination fees, and more</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/whats-the-difference-between-subsidized-and-unsubsidized-student-loans/" className={classes.noLink}>Difference between Subsidized and Unsubsidized Student Loans</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.cnbc.com/2017/07/18/student-loan-interest-rates-edge-higher-and-higher.html" className={classes.noLink}>Average interest rate of Private Student Loans</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.gov/understand-aid/types/loans/interest-rates" className={classes.noLink}>Federal Student Loans&apos; interest and fees</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.thebalance.com/how-amortization-works-315522" className={classes.noLink}>How does a loan get repaid? What is amortization?</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.thebalance.com/how-amortization-works-315522" className={classes.noLink}>What does an example repayment schedule look like?</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/how-student-loan-interest-works/" className={classes.noLink}>Understanding student loan interest</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.commonbond.co/post/why-its-a-good-idea-to-make-in-school-student-loan-payments" className={classes.noLink}>Benefits of making interest payments during school and grace period</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/student-loan-definitions-terms-to-know/#principalloan" className={classes.noLink}>Understanding student loan principal</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/hidden-student-loan-fees/" className={classes.noLink}>Origination fees and other hidden student loan costs</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.gov/manage-loans/repayment" className={classes.noLink}>Grace period, repayment and more</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Earning Income during Higher Education</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.thebalancecareers.com/ways-to-find-an-internship-1986877" className={classes.noLink}>8 ways to find an internship</a>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.internshipprograms.com/" className={classes.noLink}>Search for internships by keywords such as job title, location, and more</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.goabroad.com/intern-abroad" className={classes.noLink}>Find an internship abroad - work and learn in a different country</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentaid.ed.gov/sa/types/work-study" className={classes.noLink}>Work study on and off-campus</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.thebalance.com/best-part-time-jobs-for-college-students-2059855" className={classes.noLink}>Great part-time jobs for students</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.payscale.com/data-packages/best-jobs/college-students" className={classes.noLink}>More part-time jobs for students</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.monster.com/career-advice/article/part-time-jobs-college-students" className={classes.noLink}>Even more part-time jobs for students</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Living Expenses</h3>
                          <p className={classes.quickLinksText}>
                            <a target="_blank" rel="noopener noreferrer" href="https://studentloanhero.com/featured/how-to-pay-for-college-ways-housing-shrink-costs/" className={classes.noLink}>Pros and cons of living on or off-campus</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.collegedata.com/cs/content/content_payarticle_tmpl.jhtml?articleId=10064" className={classes.noLink}>Looking at the total cost of a higher education</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.businessinsider.com/heres-what-the-typical-1-bedroom-apartment-costs-in-50-us-cities-2016-6?IR=T#-1" className={classes.noLink}>Average rent for a one bedroom apartment in 50 U.S. cities</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.businessinsider.in/personal-finance/heres-how-much-you-need-to-earn-to-rent-a-2-bedroom-apartment-in-15-of-americas-biggest-cities/articleshow/59551596.cms#15-phoenix-arizona-1" className={classes.noLink}>Average rent for a two bedroom apartment in 15 major U.S. cities</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.apartmentguide.com/college/" className={classes.noLink}>Search for available student housing by location</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.apartments.com/" className={classes.noLink}>Another resource to search for available student housing by location</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.zillow.com/blog/rules-of-thumb-for-estimating-apartment-utility-costs-100024/" className={classes.noLink}>Estimating monthly utilities</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.moneyunder30.com/understanding-your-health-insurance" className={classes.noLink}>Health insurance costs explained</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.kff.org/health-reform/state-indicator/marketplace-average-benchmark-premiums/?currentTimeframe=0&selectedDistributions=2014--2015--2016--2017--2018--2019&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" className={classes.noLink}>Average monthly health insurance premium by state, 2014-2019</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.kff.org/health-reform/state-indicator/average-marketplace-premiums-by-metal-tier/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" className={classes.noLink}>Average Marketplace Premiums by Metal Tier, 2017-2019</a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.kff.org/other/state-indicator/single-coverage/?dataView=0&currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" className={classes.noLink}>Average monthly health insurance premium by state for employer-based plans</a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.dummyImgCompare) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={ ( compareHoverActive && !toggleValues.compareSavedNotes ) ? classNames( classes.sectionHeader, classes.activeHover ) : classes.sectionHeader }>
                <div className={ classes.sectionTitle }>
                  <h1 className={slidecount === 5 ?  classes.opacityZero  : ''}>
                    <span aria-hidden="true" onClick={ () => { handleToggle( 'compareSavedNotes' ); } } onMouseEnter={ () => { setCompareHoverActive( true ); } } onMouseLeave={ () => { setCompareHoverActive( false ); } }>
                      <i className={ toggleValues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus' } />
                      <strong className={ ( compareHoverActive && !toggleValues.compareSavedNotes ) ? classNames( classes.showText, classes.activeHide ) : classes.showText }>Compare Saved Scenarios</strong>
                      <strong className={ ( compareHoverActive && !toggleValues.compareSavedNotes ) ? classNames( classes.hideText, classes.activeShow ) : classes.hideText }>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {toggleValues.compareSavedNotes && (
                <CompareScenarios moduleName="Higher Education" moduleSelected="module2" getModuleData={getModuleData} setUpdatedScenario={setUpdatedHEValues} />
              )}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !toggleValues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={toggleValues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !toggleValues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !toggleValues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {toggleValues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>
        <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={modalType === 'save' ? classes.SavePopup : joinInk}
          >
            <DialogContent className={classes.videoPopup}>
              <div className={classes.popupClose}>
                <span aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                  X
                </span>
              </div>
              <PwiSave moduleParams={moduleObjectParams} getSaveData={updatedHevalues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg= {SetNotesSuccessMsg}/>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module2' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {higherEduIncm === true && (<HigherEducationIncome setHigherEduIncm={setHigherEduIncm} higherEduIncm={higherEduIncm} />)}
      {studentLoanType !== '' && (<StudentLoanPopups setStudentLoanType={setStudentLoanType} studentLoanType={studentLoanType} moduleName={'module2'} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={ classes.modelPopupRow}
      >
        <DialogContent className={ classes.modelPopup}>
          <div className={ classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={ classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={ classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

HE.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(HE);
