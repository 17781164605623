import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField
} from '@material-ui/core';
import styles from './styles';
import Picture1 from '../../../assets/img/pwi-existing/weighted-interest-rate-arrow.png';
import _ from 'lodash';

const fed = [];
for(var i=1;i<=6;i++) {
    fed.push({
      key: i,
      balance: 0,
      total: 0,
      interset: 0,
      weight: 0,
      focus_interest: false,
    })    
}


const WeightedAverageInterestRate = (props) => {

  const { classes, title, openStatus, closePopup, previousData } = props;

  const [inputData, setInputData] = React.useState((previousData !== undefined && previousData.length > 0) ? previousData : fed);
  const [calculations, setCalculations] = React.useState({});
  const [isFocus, setIsFocus] = React.useState('');

  const getCalculations = (data) => {
    let values = [...data];
    let totalWeight = 0;
    let totalInterest = 0;
    const totalAmount = _.sum(_.map(values, 'balance'));

    let totalObj = {};
    totalObj['balance'] = totalAmount;
    for(let i=0;i<6; i+=1 ) {
      const balance = values[i]['balance'];
      var interestVal = 0;
      if(balance > 0) {
        interestVal = (balance/totalAmount)*100;
      }
      totalInterest = totalInterest+interestVal;
      values[i]['total'] = interestVal;
      const interest = values[i]['interset'];
      const weight = (interestVal*interest)/100;
      values[i]['weight'] = weight;
      totalWeight = totalWeight+weight;
    }
    totalObj['total_interest'] = totalInterest;
    totalObj['weight_total_interest'] = totalWeight;
    setInputData(values);
    setCalculations(totalObj);
  }

  useEffect(()=>{
    getCalculations(inputData);
  },[])
    
  const updateValue = ( e, value, field ) => {
    let inputValues = [...inputData];
    if(field === 'focus_interest') {
      inputValues[value][field] = e;
      setInputData(inputValues);
      return false;
    }
    let newvalue = 0;
    newvalue = e.value;
    if(newvalue === '') {
      newvalue = 0;
    }
    if(field === 'interset' && newvalue > 100) {
      newvalue = 100;
    }
    newvalue = convertCurrencyToInteger(newvalue);
    inputValues[value][field] = newvalue;
    getCalculations(inputData);
  }

  const closeModalPopup = (type) => {
    closePopup(type,inputData, calculations)
  }

  const clearData = () => {
    getCalculations((previousData !== undefined && previousData.length > 0) ? previousData : fed);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <Dialog
      open={ openStatus }
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={ classes.intrestModal }
    >
      <DialogTitle className={ classes.intrestModalHeader }>
        {title}
      </DialogTitle>
      <DialogContent className={ classes.intrestModalBody }>
        <div className={ classes.intrestModalBody }>
          <div className={ classes.modalHeadRow }>
            <Grid container>
              <Grid xs={ 2 } spacing={ 2 } className={ classes.loanHead }>
                <h3>&nbsp;</h3>
              </Grid>
              <Grid xs={ 2 } spacing={ 2 } className={ classes.loanHead }>
                <h3>Loan Balance</h3>
              </Grid>
              <Grid xs={ 2 } spacing={ 2 } className={ `${classes.loanHead} ${classes.textAlignRight}` }>
                <h3>% of Total</h3>
              </Grid>
              <Grid xs={ 2 } spacing={ 2 } className={ classes.loanHead }>
                <h3>Interest Rate</h3>
              </Grid>
              <Grid xs={ 3 } spacing={ 2 } className={ `${classes.loanHead} ${classes.textAlignRight}` }>
                <h3>Weighted Interest Rate</h3>
              </Grid>
              <Grid xs={ 1 } spacing={ 2 } className={ classes.loanHead }>
                <h3>&nbsp;</h3>
              </Grid>
            </Grid>
          </div>
          {
            inputData.map((loan, index)=>(
              <div className={ classes.modalBodyRow } key={loan.key}>
                <Grid container>
                  <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                    Loan {index+1}
                  </Grid>
                  <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                    <span>
                      <span>$</span>
                      <NumberFormat onValueChange={ ( e ) => updateValue( e, index, 'balance' ) } decimalScale={ 0 } fixedDecimalScale thousandSeparator allowNegative={ false } customInput={ TextField } value={ loan['balance'] } onFocus={ ( e ) => { e.target.select(); } } />
                    </span>
                  </Grid>
                  <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ loan['total'] } suffix="%"/>
                  </Grid>
                  <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                    <span className={classes.percentageInput}>
                      <NumberFormat
                        onValueChange = { ( e ) => updateValue( e, index, 'interset' ) }
                        decimalScale={ isFocus!=='interset'?2:5 }
                        fixedDecimalScale={isFocus!=='interset'}
                        allowNegative={ false }
                        customInput={ TextField }
                        thousandSeparator
                        value={ loan['interset'] }
                        onBlur={emptyIsFocus}
                        onFocus={ ( e ) => { e.target.select();setIsFocus('interset') } } />
                      <span>%</span>
                      </span>
                  </Grid>
                  <Grid xs={ 3 } className={ classes.loanBlock } spacing={ 2 }>
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ loan['weight'] } suffix="%"/>
                  </Grid>
                  <Grid xs={ 1 } className={ classes.loanBlock } spacing={ 2 } />
                </Grid>
              </div>
            ))
          }
          
          <div className={ classes.totalRow }>
            <Grid container>
              <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                Total
              </Grid>
              <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ calculations['balance'] } prefix="$"/>
              </Grid>
              <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ calculations['total_interest'] } suffix="%"/>
              </Grid>
              <Grid xs={ 2 } className={ classes.loanBlock } spacing={ 2 }>
                --
              </Grid>
              <Grid xs={ 3 } className={ classes.loanBlock } spacing={ 3 }>
                <img className={ classes.arrowImage } src={ Picture1 } alt="" />
              </Grid>
              <Grid xs={ 1 } className={ classes.loanBlock } spacing={ 2 } />
            </Grid>
          </div>
          <div className={ classes.modalAverage }>
            <p>
              Weighted Average Interest Rate
              <span>
                <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ calculations['weight_total_interest'] } suffix="%"/>
              </span>
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={ classes.intrestModalFooter }>
        <Button color="primary" className={classes.clearData} onClick={clearData}>
          CLEAR DATA
        </Button>
        <Button color="primary" className={classes.okBtn} onClick={()=>closeModalPopup('ok')}>
          OK
        </Button>
        <Button color="primary" className={classes.cancelBtn} onClick={()=>closeModalPopup('cancel')}>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WeightedAverageInterestRate.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  openStatus: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  previousData: PropTypes.any.isRequired
};

export default withStyles( styles )( WeightedAverageInterestRate );
