import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Asset = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Asset</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/finance-asset-banner.jpg"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      asset
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/finance-asset-img.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p
                          className={classNames(
                            classes.noTopMargin,
                            classes.bigText,
                          )}>
                          An asset is anything that you own that has value and
                          can be converted into cash. For example, if you own a
                          car, it&apos;s an asset. The cash in your pocket is an
                          asset.
                        </p>
                        <p>
                          Your assets can be found on the balance sheet. There
                          are two different types of assets: tangible and
                          intangible assets.
                        </p>
                        <p className={classes.noBottomMargin}>
                          Some examples of tangible assets are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Cash on hand</li>
                          <li>Cars that you own (not leases)</li>
                          <li>Real estate (land and property)</li>
                          <li>
                            Jewelry, art, furniture, boats, and other physical
                            items that can be sold for cash
                          </li>
                        </ul>
                        <p className={classes.noBottomMargin}>
                          Some examples of intangible assets are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Checking and savings accounts</li>
                          <li>
                            Certificates of deposit and money market funds
                          </li>
                          <li>Stocks, bonds, mutual funds, and annuities</li>
                          <li>
                            Retirement accounts such as 401(k), Roth IRA, SEP
                            IRA, and pensions
                          </li>
                        </ul>
                        <p>
                          The opposite of an asset is a liability, which is
                          another name for debt. Assets and liabilities are used
                          to calculate your net worth:
                        </p>
                        <p>
                          Net Worth = Assets (what you own) - Liabilities (what
                          you owe)
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Asset.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Asset);
