import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (  ) => ( {
  summaryListMenu: {
    position: 'absolute !important',
    // left: 'auto',
    left: '-61px',
    zIndex: '9999!important',
    top: 'auto',
    bottom: '60px',
    marginBottom: '8px',
    display: 'block !important',
    minWidth: '162px',
    padding: '5px 0',
    margin: '2px 0 0',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    borderRadius: '4px',
    WebkitBoxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxSizing: 'border-box',
    fontSize:'12px',
    '& li': {
      padding: '0px !important',
      '& p': {
        padding: '4px 20px',
        width: '100%',
        textAlign: 'left',
        background: '0 0',
        border: 'none',
        outline: '0',
        clear: 'both',
        fontWeight: '400',
        color: '#333',
        boxSizing: 'border-box',
        display: 'block',
        height: 'auto',
        margin: '0px',
        cursor: 'pointer',
        fontSize: '12px',
        '&:hover': {
          color: '#262626',
          textDecoration: 'none',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    '&::before': {
      top: '100%',
      borderTop: '11px solid #fff',
      left: '44%',
      borderBottom: '11px solid transparent',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      width: '0',
    },
  },
} );


const DocumentsList = (props) => {
  const { classes, setOpenDocsPopup, setSummaryDocumentsOpen, hideMenu } = props;
  
  return (
    <ul className={ classes.summaryListMenu }>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'summary-output' ); setSummaryDocumentsOpen( false ); } }>Summary Output</p></li>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'inputsheet-statement' ); setSummaryDocumentsOpen( false ); } }>Summary Financials</p></li>
      <li aria-hidden="true" onClick ={()=>{hideMenu(false);setOpenDocsPopup( 'summary-table' ); setSummaryDocumentsOpen( false ); } }><p>Summary Tables</p></li>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'income-statement' ); setSummaryDocumentsOpen( false ); } }>Income Statement</p></li>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'tax-statement' ); setSummaryDocumentsOpen( false ); } }>Tax Statement</p></li>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'balance-statement' ); setSummaryDocumentsOpen( false ); } }>Balance Sheet</p></li>
      <li><p aria-hidden="true" onClick={ () => { hideMenu(false);setOpenDocsPopup( 'cashflow-statement' ); setSummaryDocumentsOpen( false ); } }>Cash Flow Statement</p></li>
    </ul>
  );
};

DocumentsList.propTypes = {
  classes: PropTypes.object.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  setSummaryDocumentsOpen: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
};

export default withStyles( styles )( DocumentsList );
