const styles = ( theme ) => ( {
  summayModalHeader: {
    padding: '0px',
    '& h2 > div': {
      borderBottom: '1px solid #e5e5e5',
      padding: '10px 15px'
    },
    '& p': {
      paddingTop: '10px',
      fontSize: "19px",
      lineHeight: 1.42857143,
      color: '#333',
      borderBottom: '2px solid #337ab7',
      margin: '0 24px !important',
      marginBottom: '10px !important',
      '& sup': {
        fontSize: '10px',
        paddingLeft: '1px',
      }
    }
  },
  logoPic: {
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  incomePopupClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    padding: '0',
    background: 'transparent',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    opacity: '0.3',    
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
      background: 'transparent',
    },
  },
  modalFooter: {
    position: 'relative',
    padding: '7px 15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
    },
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    textTransform: 'none',
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
  },
  borderStrip: {
    position: 'relative',
    margin: '0 0 10px',
    fontSize: '17px',
    lineHeight: '1.42857143',
    color: '#000',
    '&::before': {
      borderBottom: '2px solid #337ab7',
      bottom: '0',
      content: '""',
      left: '0',
      position: 'absolute',
      width: '100%',
      zIndex: '999',
    },
  },
  heightTen: {
    height: '10px',
  },
  heightFifty: {
    height: '50px',
  },
  topTable: {
    verticalAlign: 'top',
    width: '30%',
    backgroundColor: '#fff',
    padding: '0 3px',
    position: 'static',
    fontSize: '17px',
    cursor: 'default',
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          cursor: 'pointer',
          lineHeight: '1.42857143',
          color: '#000',
          fontFamily: 'MuseoSans-300',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  buyingHomeOne: {
    width: '32%',
  },
  buyingHomeTwo: {
    width: '43%',
  },
  borderLine: {
    borderBottom: '2px solid #006fbb',
  },
  blueTableRow: {
    '& td': {
      backgroundColor: '#006fbb',
      color: '#fff !important',
      fontWeight: '700',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent',
      fontFamily: '"MuseoSans-300"',
    },
  },
  blackTopBorder: {
    '& td': {
      borderTop: '2px solid #000',
      fontFamily: 'MuseoSans-700',
      fontWeight: 'normal',
    },
  },
  noteRei: {
    fontStyle: 'italic',
    margin: '0 0 10px',
    color: '#000',
    fontSize: '16px',
    fontFamily: '"MuseoSans-300"',
    lineHeight: '1.42857143',
  },
  notePara: {
    marginTop: '20px',
  },
  subHeading: {
    backgroundColor: '#dddad6',
    color: '#006fbb',
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '1.42857143',
    borderBottom: '1px solid transparent',
    boxSizing: 'border-box',
    width: '350px',
    paddingLeft: '30px!important',
  },
  cashFlowBlue: {
    '& td': {
      backgroundColor: '#003356 !important',
      color: '#fff !important',
      fontWeight: '700',
      fontSize: '17px !important',
      borderBottom: '1px solid transparent !important',
      lineHeight: '1.42857143',
    },
  },
  incomeCashTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // padding: '0px 30px',
      },
    },
  },
  emptySpaceRow: {
    '& td': {
      height: '13px',
      lineHeight: '13px !important',
    },
  },
  blueTopLine: {
    '& td': {
      borderBottom: '2px solid #006fbb !important',
    },
  },
  leftSpaceData: {
    '& td': {
      padding: '0px 3px !important',
    },
  },
  blueRowBackground: {
    '& td': {
      backgroundColor: '#006fbb !important',
      color: '#fff !important',
      fontWeight: '700 !important',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent  !important',
      lineHeight: '1.42857143',
      padding: '0px 3px',
    },
  },
  emptySpaceRowNew: {
    backgroundColor: '#fff',
    '& td': {
      backgroundColor: '#fff !important',
    },
  },
  leftItalic: {
    paddingLeft: '40px !important',
    '& i': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal !important',
    },
  },
  annualCashTable: {    
    '& td': {
      '&:first-child': {
        textAlign: 'left',
        minWidth:'350px'
      },
      textAlign: 'right',
      padding: '0px 3px',
    },
  },
  modalBlock: {
    padding: '0px',
  },
  analiseTable: {
    width: '100%',
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
    },
  },
  redText: {
    color: '#bf0000',
    '& span': {
      color: '#bf0000',
    },
  },
  heightTwoZero: {
    height: '20px',
  },
  strongText: {
    '& td': {
      fontFamily: 'MuseoSans-700 !important',
    },
  },
  blueRei: {
    borderTop: '2px solid #2f75b5',
    marginBottom: '10px',
    marginTop: '0',
  },
  emptyRow: {
    '& td': {
    fontSize: '0px',
    height: '13px',
    },
  },
  borderTitle: {
    padding: '15px 15px 0px 15px',
    marginBottom: '0px',
    '& p': {
      marginBottom: '0px',
    },
  },
  worksheetModal:{
    '& > div > div': {
      width: 'calc(100% - 20px)',
      margin: '10px 20px',
      maxWidth: '100%',
      maxHeight: 'calc(100% - 20px)',
    },
  },
  downloadBtn: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',   
    touchAction: 'manipulation',
    cursor: 'pointer',  
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    background: '#efefef',
    padding: '6px 12px',
    backgroundImage: 'none',
    textTransform: 'none',
    fontFamily: 'MuseoSans-300',
    '&:hover': {
      background: '#efefef',
    },
  },
  modalRei: {
    paddingTop: '0px',
  },
  annualReiTable: {    
    '& td': {
      '&:first-child': {
        textAlign: 'left',
        minWidth:'435px'
      },
      textAlign: 'right',
      padding: '0px 3px',
      boxSizing: 'border-box',  
      lineHeight: '1.5',   
    },
  },
  lightBlueBg: {
    backgroundColor: '#DDDAD6',
    borderBottom: '1px solid transparent',
  },
  reiTableData: {
    width: '30% !important',
    boxSizing: 'border-box',
  },
  italicLeft: {
    paddingLeft: '40px !important',
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-500',
  },
  investAssumptions: {
    width: '100% !important',
    boxSizing: 'border-box',
  },
  iAtable: {
    width: '40% !important',
    boxSizing: 'border-box',
  },
  investSubTable: {
    '& td': {
      '&:first-child': {
        minWidth: '550px',
      },
    },
  },
})

export default styles;