import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
import logo1 from '../../assets/img/logo-footer.png';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import styles from './styles';
import Slide1 from '../../assets/img/jeremy-story/jeremy_story_img_1.jpg';
import mikeStory3 from '../../assets/img/jeremy-story/jeremy_story_img_3.jpg';
import SlideMike from '../../assets/img/jeremy-story/jeremy_story_img_2.png';
import MikeSlide5 from '../../assets/img/jeremy-story/jeremy_story_img_5.jpg';
import mikeStory4 from '../../assets/img/jeremy-story/jeremy_story_img_4.jpg';
import MikeSlide6 from '../../assets/img/jeremy-story/jeremy_story_img_6.jpg';
import MikeSlide7 from '../../assets/img/jeremy-story/jeremy_story_img_7.jpg';
import MikeSlide8 from '../../assets/img/jeremy-story/jeremy_story_img_8.jpg';
import MikeSlide9 from '../../assets/img/jeremy-story/jeremy_story_img_10.jpg';
import MikeSlide10 from '../../assets/img/jeremy-story/jeremy_story_img_9.jpg';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Jeremy = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );
  
  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'jeremy',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  const handleForwordPopup = () => {
    
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=jeremy`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };

  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryTool" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>JEREMY</h2>
                    <h2>Real Estate Broker</h2>
                    <p>15-Year Net Worth: $440,588*</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities). Because Jeremy gets married, this figure includes his partner&apos;s income/expenses.
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src={ SlideMike } alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Jeremy&apos;s Life</h3>
                    <p>Jeremy majors in Finance and switches from mortgage originator into residential real estate. He earns his broker license, builds a realty team, gets married and buys a home.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Jeremy&apos;s Start Year – 2019</h3>
                  <h4>(18 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      {' '}
                      $1k in savings
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2022: Earns Finance degree in four years at NC State</p>
                      </li>
                      <li>
                        <p>2023 – 2025: Mortgage Originator in Raleigh, N.C.; lives home, invests savings, leases car</p>
                      </li>
                      <li>
                        <p>2026 – 2028: Career move to real estate agent, moves into apartment</p>
                      </li>
                      <li>
                        <p>2029 – 2031: Transitions into a new residential real estate role</p>
                      </li>
                      <li>
                        <p>2029: Buys a SUV and begins taking annual vacations</p>
                      </li>
                      <li>
                        <p>2030: Gets married</p>
                      </li>
                      <li>
                        <p>2031: Purchases safe, fuel-efficient hybrid sedan for his partner and future family</p>
                      </li>
                      <li>
                        <p>2032 – 2033: Earns Real Estate Broker license and builds a new realty team</p>
                      </li>
                      <li>
                        <p>2033: Buys a home in the suburbs in preparation for his first child</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $440,588</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Jeremy</h3>
                    <p>
                      Jeremy graduates from high school with $1k in savings. He is accepted into NC State in Raleigh, just a few miles from where he grew up, and majors in finance. His parents contribute to his living expenses while he&apos;s in school. The rest of Jeremy’s $36k undergraduate
                      education is mostly funded by student loans, with a scholarship boost of $2.5k per annum. Jeremy also contributes to the cost of his education, drawing on income from side jobs and summer jobs. He makes the largest contribution he can, each year, in an effort to keep his overall debt as low as possible.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Leveraging all sources of funding before taking on debt can save thousands of dollars in interest. Also, working during school years grows your financial independence.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy supplements his education funding with scholarships, grants and work income, minimizing the need for family contribution and loans.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Starts as loan originator</h3>
                    <p>
                      After graduation from NC State, Jeremy becomes licensed as a loan originator in his home state, working with borrowers to complete mortgage transactions. He starts his career at a local firm, earning $60k per year with an annual bonus of $5k. His parents
                      invite him to live at home for a time, and by doing so, Jeremy is able to pay down his loans ahead of schedule. He also begins making small contributions to short-term and long-term investments.
                    </p>

                  </div>

                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>When beginning a career while in debt, keep expenses low and save when reasonable to build a foundation for financial independence.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy lives frugally as he begins to pay down his debt. He also starts saving as soon as possible to capitalize on compounding interest.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Jeremy changes careers</h3>
                    <p>
                      Over time as a loan originator, Jeremy learns more about the financing side of real estate, and he begins to imagine how new opportunities could open up if he actually were to become a real estate agent. In 2026, Jeremy passes the exam and earns his real estate license in North Carolina. He enthusastically dives into his new career. Very quickly,
                      his income rises to $80k annually. Jeremy soon sets his sights on becoming a Real Estate Broker with management of his own team.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Exploring opportunities early on can pay dividends down the road.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy&apos;s skillset allows him to take a well-calculated risk and he ventures down a new career path.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>New apartment, new car</h3>
                    <p>
                      Settling into his new career, Jeremy moves into an apartment closer to his office. He hones his real estate knowledge and sales skills, working long hours listing properties for sellers, showing homes to prospective buyers and closing deals. Jeremy decides to lease a vehicle designated primarily for business use, as he wants
                      to make a good impression as he drives prospective buyers to see properties for sale in the region.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>With an improving financial situation, prioritize expenses that matter most.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy prioritizes his living situation and makes an upgrade while investing additional resources in a car to benefit his new career.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Jeremy sets priorities</h3>
                    <p>
                      In 2029, Jeremy buys a luxury SUV, again designated primarily for business purposes as he drives potential clients to and from the residential real estate properties he is showing. By this time, Jeremy is earning $110k, and he prioritizes spending to maintain and improve his vehicle. After six hard years of work,
                      he begins to take an annual vacation to places such as Nashville and New Orleans for long weekend trips filled with music events.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Always maintain a good work-life balance. Reward hard work.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy makes time for fun and relaxation while continuing to focus on his plan and long-term goals.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Jeremy gets married</h3>
                    <p>In 2030, Jeremy marries his girlfriend of many years and her parents offer to cover their wedding expenses, including a two-week honeymoon. His partner&apos;s earnings as a real estate agent contribute to the couple&apos;s income.</p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Make time for love and relaxation. Save for important goals.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>The couple&apos;s combined income will accelerate their savings. Jeremy focuses on his goal to become a real estate broker.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A real estate broker</h3>
                    <p>One year later, and after 10 years in the industry, Jeremy realizes his most important professional goal: To become a broker in a management role. Five individual real estate agents consent to join a new firm with Jeremy at the helm. Also in 2031, Jeremy and his wife take the first step to prepare for a future family – they purchase a hybrid sedan with a high safety rating.</p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Combine hard work with an actionable plan and results will follow.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy achieves mastery in his career at a young age after taking calculated risks early in his career.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A new townhome</h3>
                    <p>
                      In 2033, Jeremy and his wife purchase a $450k townhome in the suburbs of Raleigh. They continue to build their net worth and plan for the future,
                      and Jeremy works hard to expand his real estate business.
                    </p>
                    <p>What happens next?</p>
                  </div>

                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>With financial independence comes freedom. Spend it on the things that matter most.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Jeremy prepares for the next stages of his life.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Jeremy&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Jeremy earns 10% more income in his plan?</li>
                        <li>What happens if he spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on his net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 9 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Jeremy&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>

                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Jeremy&apos;s financial life plan by module to see how he acheives his goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Jeremy - Real Estate Broker</h4>
                      <h4>Net Worth:  $440,588</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide10 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Jeremy&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(32 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Education</li>
                      <li>Work Experience</li>
                      <li>A Career</li>
                      <li>A Business</li>
                      <li>A Family</li>
                      <li>An SUV</li>
                      <li>A Hybrid Sedan</li>
                      <li>A Townhome</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $440,588</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Jeremy&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 8.5%</li>
                      <li>Monthly Liquidity Ratio: 1.9x</li>
                      <li>Current Ratio: 0.7x</li>
                      <li>Savings Rate: (16.7%)</li>
                      <li>Investment Assets to Total Assets: 38.1%</li>
                      <li>Total Assets to Total Debt: 2.3x</li>
                      <li>Solvency Ratio: 0.6x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>
      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}
    </Typography>
  );
};

Jeremy.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Jeremy );
