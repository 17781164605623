import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import * as modulesServices from '../../../calculations/modules-services';

const CashFlowSheetPopup = (props) => {
    const {
        classes, openCashFlowSheetPopup, setOpenCashFlowSheetPopup, detailedInfo, currentColumn
    } = props;

    return (
        <div className={classes.modalBodyData}>
            <Dialog
                open={openCashFlowSheetPopup}
                onClose={() => setOpenCashFlowSheetPopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={ classes.incomeStatementDialog }
            >
                <DialogContent>
                    <div>
                        <div>
                            <div>
                                {currentColumn === 'Net Income' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }> Net Income </td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Income After Taxes</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['incomeAfterTaxes'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['incomeAfterTaxes'] !== '' && detailedInfo['incomeAfterTaxes'] !== null && detailedInfo['incomeAfterTaxes'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['incomeAfterTaxes']} prefix={detailedInfo['incomeAfterTaxes'] >= 0 ? '$' : '($'} suffix={detailedInfo['incomeAfterTaxes'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Total Expenses</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalExpenses'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalExpenses'] !== '' && detailedInfo['totalExpenses'] !== null && detailedInfo['totalExpenses'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalExpenses']} prefix={detailedInfo['totalExpenses'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalExpenses'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Net Income</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] !== '' && detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] !== null && detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses']} prefix={detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] >= 0 ? '$' : '($'} suffix={detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Retirement Contributions' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>                                                
                                                <td colSpan="2" className={ classes.strongText }>Retirement Contributions</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>401(k)</td>
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Base Income</td>
																										<td>
																											<span className={classNames(detailedInfo['baseIncome401k1'] < 0 && classes.labelColor)}>
																													{(detailedInfo['baseIncome401k1'] !== '' && detailedInfo['baseIncome401k1'] !== null && detailedInfo['baseIncome401k1'] !== undefined) ? (
																															<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['baseIncome401k1']} prefix={detailedInfo['baseIncome401k1'] >= 0 ? '$' : '($'} suffix={detailedInfo['baseIncome401k1'] < 0 && ')'} />
																													) : ('')}
																											</span>
																										</td>
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>+</td>
                                                    <td>Personal Contribution %</td>
                                                    <td>
                                                        <span>{detailedInfo['personalContribution401k1']}%</span>
                                                    </td>
                                                    {/* <span className={classNames(detailedInfo['baseIncome401k1'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['baseIncome401k1'] !== '' && detailedInfo['baseIncome401k1'] !== null && detailedInfo['baseIncome401k1'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['baseIncome401k1']} prefix={detailedInfo['baseIncome401k1'] >= 0 ? '$' : '($'} suffix={detailedInfo['baseIncome401k1'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span> */}
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td>{detailedInfo['Year']} Personal Contribution</td>
																										<td>
																											<span className={classNames(detailedInfo['personalContributionValue401k1'] < 0 && classes.labelColor)}>
																													{(detailedInfo['personalContributionValue401k1'] !== '' && detailedInfo['personalContributionValue401k1'] !== null && detailedInfo['personalContributionValue401k1'] !== undefined) ? (
																															<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['personalContributionValue401k1']} prefix={detailedInfo['personalContributionValue401k1'] >= 0 ? '$' : '($'} suffix={detailedInfo['personalContributionValue401k1'] < 0 && ')'} />
																													) : ('')}
																											</span>
																										</td>
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Max Personal Contribution by Law</i></td>
																										<td>
																											<span className={classNames(detailedInfo['database401k1'] < 0 && classes.labelColor)}>
																													{(detailedInfo['database401k1'] !== '' && detailedInfo['database401k1'] !== null && detailedInfo['database401k1'] !== undefined) ? (
																															<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['database401k1']} prefix={detailedInfo['database401k1'] >= 0 ? '$' : '($'} suffix={detailedInfo['database401k1'] < 0 && ')'} />
																													) : ('')}
																											</span>
																										</td>
                                                </tr>
                                            }
                                            {detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>{detailedInfo['Year']} Total 401(k) Personal Contribution</td>
																										<td>
																											<span className={classNames(detailedInfo['final401kValue1'] < 0 && classes.labelColor)}>
																													{(detailedInfo['final401kValue1'] !== '' && detailedInfo['final401kValue1'] !== null && detailedInfo['final401kValue1'] !== undefined) ? (
																															<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['final401kValue1']} prefix={detailedInfo['final401kValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['final401kValue1'] < 0 && ')'} />
																													) : ('')}
																											</span>
																										</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 && detailedInfo.final401kValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {/* Roth IRA Section */}
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>Roth IRA</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Base Income</td>
																										<td>
																											<span className={classNames(detailedInfo['baseIncomeRothIra1'] < 0 && classes.labelColor)}>
																													{(detailedInfo['baseIncomeRothIra1'] !== '' && detailedInfo['baseIncomeRothIra1'] !== null && detailedInfo['baseIncomeRothIra1'] !== undefined) ? (
																															<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['baseIncomeRothIra1']} prefix={detailedInfo['baseIncomeRothIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['baseIncomeRothIra1'] < 0 && ')'} />
																													) : ('')}
																											</span>
																										</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Personal Contribution %</td>
                                                    <td>
                                                        <span>{detailedInfo['personalContributionRothIra1']} %</span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td>{detailedInfo['Year']} Personal Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['personalContributionValueRothIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['personalContributionValueRothIra1'] !== '' && detailedInfo['personalContributionValueRothIra1'] !== null && detailedInfo['personalContributionValueRothIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['personalContributionValueRothIra1']} prefix={detailedInfo['personalContributionValueRothIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['personalContributionValueRothIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td><i>Max Personal Contribution by Law</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['databaseRothIRA1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['databaseRothIRA1'] !== '' && detailedInfo['databaseRothIRA1'] !== null && detailedInfo['databaseRothIRA1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['databaseRothIRA1']} prefix={detailedInfo['databaseRothIRA1'] >= 0 ? '$' : '($'} suffix={detailedInfo['databaseRothIRA1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td><i>{detailedInfo['Year']} Personal Contribution</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['maxContributionByLawRothIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['maxContributionByLawRothIra1'] !== '' && detailedInfo['maxContributionByLawRothIra1'] !== null && detailedInfo['maxContributionByLawRothIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['maxContributionByLawRothIra1']} prefix={detailedInfo['maxContributionByLawRothIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['maxContributionByLawRothIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>{detailedInfo['Year']} Total Gross Income (excluding Rental Income)</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['excludingNetRentalIncome1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['excludingNetRentalIncome1'] !== '' && detailedInfo['excludingNetRentalIncome1'] !== null && detailedInfo['excludingNetRentalIncome1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['excludingNetRentalIncome1']} prefix={detailedInfo['excludingNetRentalIncome1'] >= 0 ? '$' : '($'} suffix={detailedInfo['excludingNetRentalIncome1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Phaseout Reduction due to Total Income</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['phaseOutReductionDueValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['phaseOutReductionDueValue'] !== '' && detailedInfo['phaseOutReductionDueValue'] !== null && detailedInfo['phaseOutReductionDueValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['phaseOutReductionDueValue']} prefix={detailedInfo['phaseOutReductionDueValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['phaseOutReductionDueValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalRothIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td><i>{detailedInfo['Year']} Total Roth IRA Personal Contribution</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['finalRothIraValue1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['finalRothIraValue1'] !== '' && detailedInfo['finalRothIraValue1'] !== null && detailedInfo['finalRothIraValue1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalRothIraValue1']} prefix={detailedInfo['finalRothIraValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalRothIraValue1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 && (detailedInfo.finalRothIraValueCondition1 === 1 || detailedInfo.final401kValueCondition1 === 1 || detailedInfo.finalRothIraValueCondition1 === 1) &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {/* Seph IRA Section */}
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>SEP IRA</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>{detailedInfo['Year']} Base Income</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['baseIncomeSephIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['baseIncomeSephIra1'] !== '' && detailedInfo['baseIncomeSephIra1'] !== null && detailedInfo['baseIncomeSephIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['baseIncomeSephIra1']} prefix={detailedInfo['baseIncomeSephIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['baseIncomeSephIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Personal Contribution %</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['personalContributionSephIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['personalContributionSephIra1'] !== '' && detailedInfo['personalContributionSephIra1'] !== null && detailedInfo['personalContributionSephIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['personalContributionSephIra1']} prefix={detailedInfo['personalContributionSephIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['personalContributionSephIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td>{detailedInfo['Year']} Personal Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['personalContributionValueSephIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['personalContributionValueSephIra1'] !== '' && detailedInfo['personalContributionValueSephIra1'] !== null && detailedInfo['personalContributionValueSephIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['personalContributionValueSephIra1']} prefix={detailedInfo['personalContributionValueSephIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['personalContributionValueSephIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>=</td>
                                                    <td><i>Max Personal Contribution by Law (lesser of 1 &amp; 2)</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['maxContributionByLawSephIra1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['maxContributionByLawSephIra1'] !== '' && detailedInfo['maxContributionByLawSephIra1'] !== null && detailedInfo['maxContributionByLawSephIra1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['maxContributionByLawSephIra1']} prefix={detailedInfo['maxContributionByLawSephIra1'] >= 0 ? '$' : '($'} suffix={detailedInfo['maxContributionByLawSephIra1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>(1) 25% of Income, limited to $275,000 of Income</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['firstSephIraValue1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['firstSephIraValue1'] !== '' && detailedInfo['firstSephIraValue1'] !== null && detailedInfo['firstSephIraValue1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['firstSephIraValue1']} prefix={detailedInfo['firstSephIraValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['firstSephIraValue1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>(2) $55,000</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['secondSephIraValue1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['secondSephIraValue1'] !== '' && detailedInfo['secondSephIraValue1'] !== null && detailedInfo['secondSephIraValue1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['secondSephIraValue1']} prefix={detailedInfo['secondSephIraValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['secondSephIraValue1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalSephIraValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Total SEP IRA Personal Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['finalSephIraValue1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['finalSephIraValue1'] !== '' && detailedInfo['finalSephIraValue1'] !== null && detailedInfo['finalSephIraValue1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalSephIraValue1']} prefix={detailedInfo['finalSephIraValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalSephIraValue1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 && (detailedInfo.finalSephIraValueCondition1 === 1 || detailedInfo.final401kValueCondition1 === 1) &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {/* Pension Section */}
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>Pension</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Base Income</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['baseIncomePension1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['baseIncomePension1'] !== '' && detailedInfo['baseIncomePension1'] !== null && detailedInfo['baseIncomePension1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['baseIncomePension1']} prefix={detailedInfo['baseIncomePension1'] >= 0 ? '$' : '($'} suffix={detailedInfo['baseIncomePension1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>X</td>
                                                    <td>Personal Contribution %</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['personalContributionPension1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['personalContributionPension1'] !== '' && detailedInfo['personalContributionPension1'] !== null && detailedInfo['personalContributionPension1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['personalContributionPension1']} prefix={detailedInfo['personalContributionPension1'] >= 0 ? '$' : '($'} suffix={detailedInfo['personalContributionPension1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Max Contribution by Law (including Employer Contribution)</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['databasePension1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['databasePension1'] !== '' && detailedInfo['databasePension1'] !== null && detailedInfo['databasePension1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['databasePension1']} prefix={detailedInfo['databasePension1'] >= 0 ? '$' : '($'} suffix={detailedInfo['databasePension1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Employer Contribution</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['employerContributionPension1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['employerContributionPension1'] !== '' && detailedInfo['employerContributionPension1'] !== null && detailedInfo['employerContributionPension1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['employerContributionPension1']} prefix={detailedInfo['employerContributionPension1'] >= 0 ? '$' : '($'} suffix={detailedInfo['employerContributionPension1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalPensionValueCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Total Pension Personal Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['finalPensionValue1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['finalPensionValue1'] !== '' && detailedInfo['finalPensionValue1'] !== null && detailedInfo['finalPensionValue1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalPensionValue1']} prefix={detailedInfo['finalPensionValue1'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalPensionValue1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} Retirement Contributions</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalRetirementContriutionsValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalRetirementContriutionsValue'] !== '' && detailedInfo['totalRetirementContriutionsValue'] !== null && detailedInfo['totalRetirementContriutionsValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalRetirementContriutionsValue']} prefix={detailedInfo['totalRetirementContriutionsValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalRetirementContriutionsValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Contribution to Investment Accounts' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>                                                
                                                <td colSpan="2" className={ classes.strongText }>Contributions to Investment Accounts from Cash</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>Short-Term Investments</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 && detailedInfo['shortTermInvestmentsStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>Annual Contribution</td>
                                                    <td>Contribution Growth</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 && detailedInfo['shortTermInvestmentsStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['shortTermInvestmentsStartYear']}</td>
                                                    <td>{detailedInfo['shortTermAnnualContribution']}</td>
                                                    <td>{detailedInfo['shortTermAnnualContributionGrowthOPercentage']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionPreviousValueCondition === 1 && detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']-1} Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['shorttermCashContributionPreviousValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['shorttermCashContributionPreviousValue'] !== '' && detailedInfo['shorttermCashContributionPreviousValue'] !== null && detailedInfo['shorttermCashContributionPreviousValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shorttermCashContributionPreviousValue']} prefix={detailedInfo['shorttermCashContributionPreviousValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shorttermCashContributionPreviousValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionPreviousValueCondition === 1 && detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Contribution Growth</td>
                                                    <td>{detailedInfo['shortTermAnnualContributionGrowthOPercentage']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.shorttermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>{detailedInfo['Year']} Contribution to Short-Term Investments</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['shorttermCashContributionValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['shorttermCashContributionValue'] !== '' && detailedInfo['shorttermCashContributionValue'] !== null && detailedInfo['shorttermCashContributionValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shorttermCashContributionValue']} prefix={detailedInfo['shorttermCashContributionValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shorttermCashContributionValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {(detailedInfo.longtermCashContributionValueCondition === 1 || detailedInfo.shorttermCashContributionValueCondition === 1) &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>Long-Term Investments</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 && detailedInfo['longTermInvestmentsStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>Annual Contribution</td>
                                                    <td>Contribution Growth</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 && detailedInfo['longTermInvestmentsStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['longTermInvestmentsStartYear']}</td>
                                                    <td>{detailedInfo['longTermAnnualContribution']}</td>
                                                    <td>{detailedInfo['longTermAnnualContributionGrowthOPercentage']}%</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionPreviousValueCondition === 1 && detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>{detailedInfo['Year']-1} Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longtermCashContributionPreviousValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longtermCashContributionPreviousValue'] !== '' && detailedInfo['longtermCashContributionPreviousValue'] !== null && detailedInfo['longtermCashContributionPreviousValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longtermCashContributionPreviousValue']} prefix={detailedInfo['longtermCashContributionPreviousValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longtermCashContributionPreviousValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionPreviousValueCondition === 1 && detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>X</td>
                                                    <td>Contribution Growth</td>
                                                    <td>{detailedInfo['longTermAnnualContributionGrowthOPercentage']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>{detailedInfo['Year']} Contribution to Long-Term Investments</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longtermCashContributionValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longtermCashContributionValue'] !== '' && detailedInfo['longtermCashContributionValue'] !== null && detailedInfo['longtermCashContributionValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longtermCashContributionValue']} prefix={detailedInfo['longtermCashContributionValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longtermCashContributionValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longtermCashContributionValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Total Contribution to Investment Accounts</td>
                                                <td>
                                                        <span className={classNames((detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) < 0 && classes.labelColor)}>
                                                            {((detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) !== '' && (detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) !== null && (detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={(detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue'])} prefix={(detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) >= 0 ? '$' : '($'} suffix={(detailedInfo['shorttermCashContributionValue'] + detailedInfo['longtermCashContributionValue']) < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Charitable Contributions' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>                                                
                                                <td colSpan="2" className={ classes.strongText }>Charitable Contributions</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.charitableContributionCondition === 1 && detailedInfo['charitableStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>*/

                                                    <td>End Year</td>
                                                    <td>% of Gross Income</td>
                                                </tr>
                                            }
                                            {detailedInfo.charitableContributionCondition === 1 && detailedInfo['charitableStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['charitableStartYear']}</td>
                                                    <td>{detailedInfo['charitableEndYear']}</td>
                                                    <td>{detailedInfo['grossIncomePercentageValue']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.charitableContributionCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.charitableContributionCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']} Gross Income</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['grossIncomeValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['grossIncomeValue'] !== '' && detailedInfo['grossIncomeValue'] !== null && detailedInfo['grossIncomeValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['grossIncomeValue']} prefix={detailedInfo['grossIncomeValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['grossIncomeValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.charitableContributionCondition === 1 &&
                                                <tr>
                                                    <td>X</td>
                                                    <td>Contribution %</td>
                                                    <td>{detailedInfo['grossIncomePercentageValue']} %</td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} Charitable Contributions</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['charitableContributionValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['charitableContributionValue'] !== '' && detailedInfo['charitableContributionValue'] !== null && detailedInfo['charitableContributionValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['charitableContributionValue']} prefix={detailedInfo['charitableContributionValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['charitableContributionValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}                                
                                {currentColumn === 'Vacation' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo.vacationLabel}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>Annual Cost</td>
                                                </tr>
                                            } 
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['vacationStartYear']}</td>
                                                    <td>{detailedInfo['vacationEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['vacationCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['vacationCost'] !== '' && detailedInfo['vacationCost'] !== null && detailedInfo['vacationCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['vacationCost']} prefix={detailedInfo['vacationCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['vacationCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']-1} {detailedInfo.vacationLabel}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['previousYearVacationValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['previousYearVacationValue'] !== '' && detailedInfo['previousYearVacationValue'] !== null && detailedInfo['previousYearVacationValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['previousYearVacationValue']} prefix={detailedInfo['previousYearVacationValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['previousYearVacationValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Annual Inflation</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['annualInflation'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['annualInflation'] !== '' && detailedInfo['annualInflation'] !== null && detailedInfo['annualInflation'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['annualInflation']} prefix={detailedInfo['annualInflation'] >= 0 ? '$' : '($'} suffix={detailedInfo['annualInflation'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>x</td>
                                                <td>{detailedInfo['Year']} {detailedInfo.vacationLabel}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['Cash from Investing Activities']['Vacation']< 0 && classes.labelColor)}>
                                                        {(detailedInfo['Cash from Investing Activities']['Vacation'] !== '' && detailedInfo['Cash from Investing Activities']['Vacation'] !== null && detailedInfo['Cash from Investing Activities']['Vacation'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['Cash from Investing Activities']['Vacation']} prefix={detailedInfo['Cash from Investing Activities']['Vacation'] >= 0 ? '$' : '($'} suffix={detailedInfo['Cash from Investing Activities']['Vacation'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === modulesServices.module11Data.other_acquisition2 +' - Second' && (
                                    <table className={ classes.netIncomeTable }>
                                       <tbody>
                                            <tr className={ classes.popupHeader }>                                                
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo.vacationLabel}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 &&
                                            <tr>
                                            <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                            <tr>
                                            <td>&nbsp;</td>
                                            <td>Start Year</td>
                                            <td>End Year</td>
                                            <td>Annual Cost</td>
                                            </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['vacationStartYear']}</td>
                                                    <td>{detailedInfo['vacationEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['vacationCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['vacationCost'] !== '' && detailedInfo['vacationCost'] !== null && detailedInfo['vacationCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['vacationCost']} prefix={detailedInfo['vacationCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['vacationCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']-1} {detailedInfo.vacationLabel}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['previousYearVacationValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['previousYearVacationValue'] !== '' && detailedInfo['previousYearVacationValue'] !== null && detailedInfo['previousYearVacationValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['previousYearVacationValue']} prefix={detailedInfo['previousYearVacationValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['previousYearVacationValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Annual Inflation</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['annualInflation'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['annualInflation'] !== '' && detailedInfo['annualInflation'] !== null && detailedInfo['annualInflation'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['annualInflation']} prefix={detailedInfo['annualInflation'] >= 0 ? '$' : '($'} suffix={detailedInfo['annualInflation'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']}{' '}{detailedInfo.vacationLabel}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['finalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['finalValue'] !== '' && detailedInfo['finalValue'] !== null && detailedInfo['finalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalValue']} prefix={detailedInfo['finalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === modulesServices.module11Data.other_acquisition1 && (
                                    <table className={ classes.netIncomeTable }>
                                          <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td className={ classes.strongText } colSpan="3">{modulesServices.module11Data.other_acquisition1}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 &&
                                            <tr>
                                            <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                            <tr>
                                            <td>&nbsp;</td>
                                            <td>Start Year</td>
                                            <td>End Year</td>
                                            <td>Annual Cost</td>
                                            </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo['vacationStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['vacationStartYear']}</td>
                                                    <td>{detailedInfo['vacationEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['vacationCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['vacationCost'] !== '' && detailedInfo['vacationCost'] !== null && detailedInfo['vacationCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['vacationCost']} prefix={detailedInfo['vacationCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['vacationCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['Year']-1} {detailedInfo.vacationLabel}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['previousYearVacationValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['previousYearVacationValue'] !== '' && detailedInfo['previousYearVacationValue'] !== null && detailedInfo['previousYearVacationValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['previousYearVacationValue']} prefix={detailedInfo['previousYearVacationValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['previousYearVacationValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.finalValueCondition === 1 && detailedInfo.previousYearValueCondition === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Annual Inflation</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['annualInflation'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['annualInflation'] !== '' && detailedInfo['annualInflation'] !== null && detailedInfo['annualInflation'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['annualInflation']} prefix={detailedInfo['annualInflation'] >= 0 ? '$' : '($'} suffix={detailedInfo['annualInflation'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} {modulesServices.module11Data.other_acquisition1}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['finalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['finalValue'] !== '' && detailedInfo['finalValue'] !== null && detailedInfo['finalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalValue']} prefix={detailedInfo['finalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Pet Buying Costs' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }>Pet Buying Costs</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.petBuyingCostsCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Purchase Price</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['purchasePriceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['purchasePriceValue'] !== '' && detailedInfo['purchasePriceValue'] !== null && detailedInfo['purchasePriceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['purchasePriceValue']} prefix={detailedInfo['purchasePriceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['purchasePriceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.petBuyingCostsCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>First Vet Visit</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['firstVetVisitValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['firstVetVisitValue'] !== '' && detailedInfo['firstVetVisitValue'] !== null && detailedInfo['firstVetVisitValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['firstVetVisitValue']} prefix={detailedInfo['firstVetVisitValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['firstVetVisitValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.petBuyingCostsCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>New Supplies</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newSuppliesValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newSuppliesValue'] !== '' && detailedInfo['newSuppliesValue'] !== null && detailedInfo['newSuppliesValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newSuppliesValue']} prefix={detailedInfo['newSuppliesValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newSuppliesValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.petBuyingCostsCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Miscellaneous</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['miscellaneousValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['miscellaneousValue'] !== '' && detailedInfo['miscellaneousValue'] !== null && detailedInfo['miscellaneousValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['miscellaneousValue']} prefix={detailedInfo['miscellaneousValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['miscellaneousValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Total Pet Buying Costs</td>
                                                {/* {console.log('phrudhvi',detailedInfo)} */}
                                                <td>
                                                    <span className={classNames(detailedInfo['petBuyingCostValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['petBuyingCostValue'] !== '' && detailedInfo['petBuyingCostValue'] !== null && detailedInfo['petBuyingCostValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['petBuyingCostValue']} prefix={detailedInfo['petBuyingCostValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['petBuyingCostValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Home & REI Property Down Payments' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }>Home & Real Estate Investment Property Down Payment</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.purchaseYearCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Home Purchase Price</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['purchasePrice'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['purchasePrice'] !== '' && detailedInfo['purchasePrice'] !== null && detailedInfo['purchasePrice'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['purchasePrice']} prefix={detailedInfo['purchasePrice'] >= 0 ? '$' : '($'} suffix={detailedInfo['purchasePrice'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.downPaymentCondition === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Down Payment</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['downPayment'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['downPayment'] !== '' && detailedInfo['downPayment'] !== null && detailedInfo['downPayment'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['downPayment']} prefix={detailedInfo['downPayment'] >= 0 ? '$' : '($'} suffix={detailedInfo['downPayment'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.downPaymentCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Down Payment on Home</td>
                                                    <td>
                                                        <span className={classNames(( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] < 0 && classes.labelColor)}>
                                                            {(( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] !== '' && ( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] !== null && ( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment']} prefix={( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] >= 0 ? '$' : '($'} suffix={( -detailedInfo['purchasePrice'] / 100 ) * detailedInfo['downPayment'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.purchaseYearCondition1 === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>REI Property Purchase Price</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['purchasePrice1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['purchasePrice1'] !== '' && detailedInfo['purchasePrice1'] !== null && detailedInfo['purchasePrice1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['purchasePrice1']} prefix={detailedInfo['purchasePrice1'] >= 0 ? '$' : '($'} suffix={detailedInfo['purchasePrice1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.downPaymentCondition1 === 1 &&
                                                <tr>
                                                    <td>x</td>
                                                    <td>Down Payment</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['downPayment1'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['downPayment1'] !== '' && detailedInfo['downPayment1'] !== null && detailedInfo['downPayment1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['downPayment1']} prefix={detailedInfo['downPayment1'] >= 0 ? '$' : '($'} suffix={detailedInfo['downPayment1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.downPaymentCondition1 === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Down Payment on Rental Property</td>
                                                    <td>
                                                        <span className={classNames(-( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] < 0 && classes.labelColor)}>
                                                            {(-( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] !== '' && -( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] !== null && -( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1']} prefix={-( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] >= 0 ? '$' : '($'} suffix={-( detailedInfo['purchasePrice1'] / 100 ) * detailedInfo['downPayment1'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Home & REI Property Down Payment</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['finalDownPayment'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['finalDownPayment'] !== '' && detailedInfo['finalDownPayment'] !== null && detailedInfo['finalDownPayment'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalDownPayment']} prefix={detailedInfo['finalDownPayment'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalDownPayment'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Home & REI Property Closing Costs' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }>Home & Real Estate Investment Property Closing Costs</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                            <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.buyingAHomeClosingCostCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Home Closing Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['buyingAHomeClosingCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['buyingAHomeClosingCost'] !== '' && detailedInfo['buyingAHomeClosingCost'] !== null && detailedInfo['buyingAHomeClosingCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['buyingAHomeClosingCost']} prefix={detailedInfo['buyingAHomeClosingCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['buyingAHomeClosingCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.reiClosingCostCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>REI Property Closing Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['reiClosingCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['reiClosingCost'] !== '' && detailedInfo['reiClosingCost'] !== null && detailedInfo['reiClosingCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['reiClosingCost']} prefix={detailedInfo['reiClosingCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['reiClosingCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Home & REI Property Closing Costs</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] !== '' && detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] !== null && detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost']} prefix={detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['buyingAHomeClosingCost'] + detailedInfo['reiClosingCost'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Home & REI Property Fit Out Costs' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Home & Real Estate Investment Property Fit Out Costs</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                            <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.buyingAHomeFitOutCostCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Home Fit Out Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['buyingAHomeFitOutCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['buyingAHomeFitOutCost'] !== '' && detailedInfo['buyingAHomeFitOutCost'] !== null && detailedInfo['buyingAHomeFitOutCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['buyingAHomeFitOutCost']} prefix={detailedInfo['buyingAHomeFitOutCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['buyingAHomeFitOutCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.reiFitOutCostCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>REI Property Fit Out Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['reiFitOutCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['reiFitOutCost'] !== '' && detailedInfo['reiFitOutCost'] !== null && detailedInfo['reiFitOutCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['reiFitOutCost']} prefix={detailedInfo['reiFitOutCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['reiFitOutCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>                                                    
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Home & REI Property Fit Out Costs</td>
                                                <td>
                                                    <span className={classNames((detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) < 0 && classes.labelColor)}>
                                                        {((detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) !== '' && (detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) !== null && (detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={(detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost'])} prefix={(detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) >= 0 ? '$' : '($'} suffix={(detailedInfo['buyingAHomeFitOutCost'] + detailedInfo['reiFitOutCost']) < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Home & REI Property Net Cash Flow from Sale' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }>Home & REI Property Net Cash Flow from Sale</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.reiFitOutCostCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>  
                                                    <td>Sale Year</td>                                               
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>  
                                                    <td>{detailedInfo['existingHomeSaleYear']}</td>                                               
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Selling Price</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['existingHomeSellingPrice'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['existingHomeSellingPrice'] !== '' && detailedInfo['existingHomeSellingPrice'] !== null && detailedInfo['existingHomeSellingPrice'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['existingHomeSellingPrice']} prefix={detailedInfo['existingHomeSellingPrice'] >= 0 ? '$' : '($'} suffix={detailedInfo['existingHomeSellingPrice'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Selling Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['existingHomeSellingCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['existingHomeSellingCost'] !== '' && detailedInfo['existingHomeSellingCost'] !== null && detailedInfo['existingHomeSellingCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['existingHomeSellingCost']} prefix={detailedInfo['existingHomeSellingCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['existingHomeSellingCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Mortgage Balance Remaining</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['existingHomeMortgageBalanceRemaining'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['existingHomeMortgageBalanceRemaining'] !== '' && detailedInfo['existingHomeMortgageBalanceRemaining'] !== null && detailedInfo['existingHomeMortgageBalanceRemaining'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['existingHomeMortgageBalanceRemaining']} prefix={detailedInfo['existingHomeMortgageBalanceRemaining'] >= 0 ? '$' : '($'} suffix={detailedInfo['existingHomeMortgageBalanceRemaining'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Existing Home Net Cash Flow from Sale</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['existingHomeNetCashFlowFromSale'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['existingHomeNetCashFlowFromSale'] !== '' && detailedInfo['existingHomeNetCashFlowFromSale'] !== null && detailedInfo['existingHomeNetCashFlowFromSale'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['existingHomeNetCashFlowFromSale']} prefix={detailedInfo['existingHomeNetCashFlowFromSale'] >= 0 ? '$' : '($'} suffix={detailedInfo['existingHomeNetCashFlowFromSale'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.existingHomeNetCashFlowFromSaleCondition === 1 && detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>Real Estate Investment Property</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Sale Year</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['realEstateInvPropertySaleYear']}</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>                                                 
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Selling Price</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['realEstateInvPropertySellingPrice'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['realEstateInvPropertySellingPrice'] !== '' && detailedInfo['realEstateInvPropertySellingPrice'] !== null && detailedInfo['realEstateInvPropertySellingPrice'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['realEstateInvPropertySellingPrice']} prefix={detailedInfo['realEstateInvPropertySellingPrice'] >= 0 ? '$' : '($'} suffix={detailedInfo['realEstateInvPropertySellingPrice'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Closing Costs</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['realEstateInvPropertyClosingCost'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['realEstateInvPropertyClosingCost'] !== '' && detailedInfo['realEstateInvPropertyClosingCost'] !== null && detailedInfo['realEstateInvPropertyClosingCost'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['realEstateInvPropertyClosingCost']} prefix={detailedInfo['realEstateInvPropertyClosingCost'] >= 0 ? '$' : '($'} suffix={detailedInfo['realEstateInvPropertyClosingCost'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Mortgage Payment</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['realEstateInvPropertyMortgagePayment'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['realEstateInvPropertyMortgagePayment'] !== '' && detailedInfo['realEstateInvPropertyMortgagePayment'] !== null && detailedInfo['realEstateInvPropertyMortgagePayment'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['realEstateInvPropertyMortgagePayment']} prefix={detailedInfo['realEstateInvPropertyMortgagePayment'] >= 0 ? '$' : '($'} suffix={detailedInfo['realEstateInvPropertyMortgagePayment'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>REI Property Net Cash Flow From Sale</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] !== '' && detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] !== null && detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['realEstateInvPropertyNetCashFlowFromSale']} prefix={detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] >= 0 ? '$' : '($'} suffix={detailedInfo['realEstateInvPropertyNetCashFlowFromSale'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Home & REI Property Net Cash Flow from Sale</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['finalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['finalValue'] !== '' && detailedInfo['finalValue'] !== null && detailedInfo['finalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalValue']} prefix={detailedInfo['finalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'One-Time Financial Gift' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>One-Time Financial Gift</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.oneTimeGiftValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.oneTimeGiftValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>Financial Gift</td>
                                                </tr>
                                            }
                                            {detailedInfo.oneTimeGiftValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['financialStartYear']}</td>
                                                    <td>{detailedInfo['financialEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['oneTimeFinancialGiftValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['oneTimeFinancialGiftValue'] !== '' && detailedInfo['oneTimeFinancialGiftValue'] !== null && detailedInfo['oneTimeFinancialGiftValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['oneTimeFinancialGiftValue']} prefix={detailedInfo['oneTimeFinancialGiftValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['oneTimeFinancialGiftValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} Financial Gift</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['sheetOneTimeFinancialGiftValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['sheetOneTimeFinancialGiftValue'] !== '' && detailedInfo['sheetOneTimeFinancialGiftValue'] !== null && detailedInfo['sheetOneTimeFinancialGiftValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['sheetOneTimeFinancialGiftValue']} prefix={detailedInfo['sheetOneTimeFinancialGiftValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['sheetOneTimeFinancialGiftValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Scholarships' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo['label1']}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.tableValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>{detailedInfo['label2']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['financialStartYear']}</td>
                                                    <td>{detailedInfo['financialEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['tableValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['tableValue'] !== '' && detailedInfo['tableValue'] !== null && detailedInfo['tableValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['tableValue']} prefix={detailedInfo['tableValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['tableValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} {detailedInfo['label2']}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['financingFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['financingFinalValue'] !== '' && detailedInfo['financingFinalValue'] !== null && detailedInfo['financingFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['financingFinalValue']} prefix={detailedInfo['financingFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['financingFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Grants' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo['label1']}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.tableValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>{detailedInfo['label2']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['financialStartYear']}</td>
                                                    <td>{detailedInfo['financialEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['tableValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['tableValue'] !== '' && detailedInfo['tableValue'] !== null && detailedInfo['tableValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['tableValue']} prefix={detailedInfo['tableValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['tableValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} {detailedInfo['label2']}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['financingFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['financingFinalValue'] !== '' && detailedInfo['financingFinalValue'] !== null && detailedInfo['financingFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['financingFinalValue']} prefix={detailedInfo['financingFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['financingFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Other Financial Aid' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo['label1']}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.tableValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>{detailedInfo['label2']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['financialStartYear']}</td>
                                                    <td>{detailedInfo['financialEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['tableValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['tableValue'] !== '' && detailedInfo['tableValue'] !== null && detailedInfo['tableValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['tableValue']} prefix={detailedInfo['tableValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['tableValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} {detailedInfo['label2']}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['financingFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['financingFinalValue'] !== '' && detailedInfo['financingFinalValue'] !== null && detailedInfo['financingFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['financingFinalValue']} prefix={detailedInfo['financingFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['financingFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Student Loans (Net of Origination Fees)' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>Student Loans (Net of Origination Fees)</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.higherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Higher Education Federal Subsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalSubValue'] !== '' && detailedInfo['higherEdFederalSubValue'] !== null && detailedInfo['higherEdFederalSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalSubValue']} prefix={detailedInfo['higherEdFederalSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.higherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalSubPointsPaidValue'] !== '' && detailedInfo['higherEdFederalSubPointsPaidValue'] !== null && detailedInfo['higherEdFederalSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalSubPointsPaidValue']} prefix={detailedInfo['higherEdFederalSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.higherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Higher Education Federal Unsubsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalUnSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalUnSubValue'] !== '' && detailedInfo['higherEdFederalUnSubValue'] !== null && detailedInfo['higherEdFederalUnSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalUnSubValue']} prefix={detailedInfo['higherEdFederalUnSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalUnSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.higherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalUnSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalUnSubPointsPaidValue'] !== '' && detailedInfo['higherEdFederalUnSubPointsPaidValue'] !== null && detailedInfo['higherEdFederalUnSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalUnSubPointsPaidValue']} prefix={detailedInfo['higherEdFederalUnSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalUnSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.higherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Higher Education Private Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalPrivateValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalPrivateValue'] !== '' && detailedInfo['higherEdFederalPrivateValue'] !== null && detailedInfo['higherEdFederalPrivateValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalPrivateValue']} prefix={detailedInfo['higherEdFederalPrivateValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalPrivateValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.higherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['higherEdFederalPrivatePointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['higherEdFederalPrivatePointsPaidValue'] !== '' && detailedInfo['higherEdFederalPrivatePointsPaidValue'] !== null && detailedInfo['higherEdFederalPrivatePointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['higherEdFederalPrivatePointsPaidValue']} prefix={detailedInfo['higherEdFederalPrivatePointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['higherEdFederalPrivatePointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.totalHigherEducationLoanCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Higher Education Student Loans Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['totalHigherEducationLoanValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['totalHigherEducationLoanValue'] !== '' && detailedInfo['totalHigherEducationLoanValue'] !== null && detailedInfo['totalHigherEducationLoanValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalHigherEducationLoanValue']} prefix={detailedInfo['totalHigherEducationLoanValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalHigherEducationLoanValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>More Higher Education Federal Subsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalSubValue'] !== '' && detailedInfo['moreHigherEdFederalSubValue'] !== null && detailedInfo['moreHigherEdFederalSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalSubValue']} prefix={detailedInfo['moreHigherEdFederalSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalSubPointsPaidValue'] !== '' && detailedInfo['moreHigherEdFederalSubPointsPaidValue'] !== null && detailedInfo['moreHigherEdFederalSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalSubPointsPaidValue']} prefix={detailedInfo['moreHigherEdFederalSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>More Higher Education Federal Unsubsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalUnSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalUnSubValue'] !== '' && detailedInfo['moreHigherEdFederalUnSubValue'] !== null && detailedInfo['moreHigherEdFederalUnSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalUnSubValue']} prefix={detailedInfo['moreHigherEdFederalUnSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalUnSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] !== '' && detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] !== null && detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalUnSubPointsPaidValue']} prefix={detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalUnSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>More Higher Education Private Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalPrivateValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalPrivateValue'] !== '' && detailedInfo['moreHigherEdFederalPrivateValue'] !== null && detailedInfo['moreHigherEdFederalPrivateValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalPrivateValue']} prefix={detailedInfo['moreHigherEdFederalPrivateValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalPrivateValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.moreHigherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] !== '' && detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] !== null && detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['moreHigherEdFederalPrivatePointsPaidValue']} prefix={detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['moreHigherEdFederalPrivatePointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.totalMoreHigherEducationLoanCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>More Higher Education Student Loans Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['totalMoreHigherEducationLoanValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['totalMoreHigherEducationLoanValue'] !== '' && detailedInfo['totalMoreHigherEducationLoanValue'] !== null && detailedInfo['totalMoreHigherEducationLoanValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalMoreHigherEducationLoanValue']} prefix={detailedInfo['totalMoreHigherEducationLoanValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalMoreHigherEducationLoanValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Further Higher Education Federal Subsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalSubValue'] !== '' && detailedInfo['newHigherEdFederalSubValue'] !== null && detailedInfo['newHigherEdFederalSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalSubValue']} prefix={detailedInfo['newHigherEdFederalSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalSubPointsPaidValue'] !== '' && detailedInfo['newHigherEdFederalSubPointsPaidValue'] !== null && detailedInfo['newHigherEdFederalSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalSubPointsPaidValue']} prefix={detailedInfo['newHigherEdFederalSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Further Higher Education Federal Unsubsidized Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalUnSubValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalUnSubValue'] !== '' && detailedInfo['newHigherEdFederalUnSubValue'] !== null && detailedInfo['newHigherEdFederalUnSubValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalUnSubValue']} prefix={detailedInfo['newHigherEdFederalUnSubValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalUnSubValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalUnSubLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] !== '' && detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] !== null && detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalUnSubPointsPaidValue']} prefix={detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalUnSubPointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Further Higher Education Private Student Loan Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalPrivateValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalPrivateValue'] !== '' && detailedInfo['newHigherEdFederalPrivateValue'] !== null && detailedInfo['newHigherEdFederalPrivateValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalPrivateValue']} prefix={detailedInfo['newHigherEdFederalPrivateValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalPrivateValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.newHigherEdFederalPrivateLoansTakenCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Less: Origination Fees</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] !== '' && detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] !== null && detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['newHigherEdFederalPrivatePointsPaidValue']} prefix={detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['newHigherEdFederalPrivatePointsPaidValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.totalNewHigherEducationLoanCondition === 1 &&
                                                <tr>
                                                    <td>=/+</td>
                                                    <td>Further Higher Education Student Loans Taken in {detailedInfo['Year']}</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['totalNewHigherEducationLoanValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['totalNewHigherEducationLoanValue'] !== '' && detailedInfo['totalNewHigherEducationLoanValue'] !== null && detailedInfo['totalNewHigherEducationLoanValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalNewHigherEducationLoanValue']} prefix={detailedInfo['totalNewHigherEducationLoanValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalNewHigherEducationLoanValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td className={ classes.strongText }>Total Student Loans (Net of Fees)</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['studentLoansLoanPointsValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['studentLoansLoanPointsValue'] !== '' && detailedInfo['studentLoansLoanPointsValue'] !== null && detailedInfo['studentLoansLoanPointsValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['studentLoansLoanPointsValue']} prefix={detailedInfo['studentLoansLoanPointsValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['studentLoansLoanPointsValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Family Contribution' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="3" className={ classes.strongText }>{detailedInfo['label1']}</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.tableValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Start Year</td>
                                                    <td>End Year</td>
                                                    <td>{detailedInfo['label2']}</td>
                                                </tr>
                                            }
                                            {detailedInfo.tableValueCondition === 1 && detailedInfo['financialStartYear'] > 0 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>{detailedInfo['financialStartYear']}</td>
                                                    <td>{detailedInfo['financialEndYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['tableValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['tableValue'] !== '' && detailedInfo['tableValue'] !== null && detailedInfo['tableValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['tableValue']} prefix={detailedInfo['tableValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['tableValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>{detailedInfo['Year']} {detailedInfo['label2']}</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['financingFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['financingFinalValue'] !== '' && detailedInfo['financingFinalValue'] !== null && detailedInfo['financingFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['financingFinalValue']} prefix={detailedInfo['financingFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['financingFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Net Change in Cash' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Net Change in Cash</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Total Cash from Operating Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromFinancingOperatingValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromFinancingOperatingValue'] !== '' && detailedInfo['totalCashFromFinancingOperatingValue'] !== null && detailedInfo['totalCashFromFinancingOperatingValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromFinancingOperatingValue']} prefix={detailedInfo['totalCashFromFinancingOperatingValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromFinancingOperatingValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Total Cash from Investing Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromFinancingInvestingValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromFinancingInvestingValue'] !== '' && detailedInfo['totalCashFromFinancingInvestingValue'] !== null && detailedInfo['totalCashFromFinancingInvestingValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromFinancingInvestingValue']} prefix={detailedInfo['totalCashFromFinancingInvestingValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromFinancingInvestingValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Total Cash from Financing Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromFinancingActivitiesValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromFinancingActivitiesValue'] !== '' && detailedInfo['totalCashFromFinancingActivitiesValue'] !== null && detailedInfo['totalCashFromFinancingActivitiesValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromFinancingActivitiesValue']} prefix={detailedInfo['totalCashFromFinancingActivitiesValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromFinancingActivitiesValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Net Change in Cash</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue'] !== '' && detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue']} prefix={detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromFinancingActivitiesValue'] + detailedInfo['totalCashFromFinancingInvestingValue'] + detailedInfo['totalCashFromFinancingOperatingValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Ending Cash Surplus / (Deficit)' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Ending Cash Surplus / (Deficit)</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Net Change in Cash</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['netChangeInCashValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['netChangeInCashValue'] !== '' && detailedInfo['netChangeInCashValue'] !== null && detailedInfo['netChangeInCashValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['netChangeInCashValue']} prefix={detailedInfo['netChangeInCashValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['netChangeInCashValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>+</td>
                                                <td>Beginning Cash Balance</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['beginningCashValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['beginningCashValue'] !== '' && detailedInfo['beginningCashValue'] !== null && detailedInfo['beginningCashValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['beginningCashValue']} prefix={detailedInfo['beginningCashValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['beginningCashValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Ending Cash Surplus / (Deficit)</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] !== '' && detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] !== null && detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue']} prefix={detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['netChangeInCashValue'] + detailedInfo['beginningCashValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Cash Injection from Short-Term Investments' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Short-Term Investments</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.cashInjectionShortTermCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Cash Need</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['cashNeedValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['cashNeedValue'] !== '' && detailedInfo['cashNeedValue'] !== null && detailedInfo['cashNeedValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashNeedValue']} prefix={detailedInfo['cashNeedValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashNeedValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.cashInjectionShortTermCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Short-Term Investments Balance</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['shortTermInvestmentBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['shortTermInvestmentBalanceValue'] !== '' && detailedInfo['shortTermInvestmentBalanceValue'] !== null && detailedInfo['shortTermInvestmentBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shortTermInvestmentBalanceValue']} prefix={detailedInfo['shortTermInvestmentBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shortTermInvestmentBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Short-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['cashInjectionShortTermInvestments'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['cashInjectionShortTermInvestments'] !== '' && detailedInfo['cashInjectionShortTermInvestments'] !== null && detailedInfo['cashInjectionShortTermInvestments'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashInjectionShortTermInvestments']} prefix={detailedInfo['cashInjectionShortTermInvestments'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashInjectionShortTermInvestments'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Cash Injection from Long-Term Investments' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Long-Term Investments</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.cashInjectionLongTermCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Cash Need</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['cashNeedValueLongTerm'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['cashNeedValueLongTerm'] !== '' && detailedInfo['cashNeedValueLongTerm'] !== null && detailedInfo['cashNeedValueLongTerm'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashNeedValueLongTerm']} prefix={detailedInfo['cashNeedValueLongTerm'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashNeedValueLongTerm'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.cashInjectionLongTermCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Long-Term Investments Balance</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermInvestmentBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermInvestmentBalanceValue'] !== '' && detailedInfo['longTermInvestmentBalanceValue'] !== null && detailedInfo['longTermInvestmentBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermInvestmentBalanceValue']} prefix={detailedInfo['longTermInvestmentBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermInvestmentBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Long-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['cashInjectionLongTermInvestments'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['cashInjectionLongTermInvestments'] !== '' && detailedInfo['cashInjectionLongTermInvestments'] !== null && detailedInfo['cashInjectionLongTermInvestments'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashInjectionLongTermInvestments']} prefix={detailedInfo['cashInjectionLongTermInvestments'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashInjectionLongTermInvestments'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Ending Cash Balance after Transfers' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td>&nbsp;</td>
                                                <td>Ending Cash Balance after Transfers</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Ending Cash Surplus / (Deficit)</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['endingCashSurplusValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['endingCashSurplusValue'] !== '' && detailedInfo['endingCashSurplusValue'] !== null && detailedInfo['endingCashSurplusValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['endingCashSurplusValue']} prefix={detailedInfo['endingCashSurplusValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['endingCashSurplusValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Short-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['cashInjectionShortTermValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['cashInjectionShortTermValue'] !== '' && detailedInfo['cashInjectionShortTermValue'] !== null && detailedInfo['cashInjectionShortTermValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashInjectionShortTermValue']} prefix={detailedInfo['cashInjectionShortTermValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashInjectionShortTermValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>Cash Injection from Long-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['cashInjectionLongTermValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['cashInjectionLongTermValue'] !== '' && detailedInfo['cashInjectionLongTermValue'] !== null && detailedInfo['cashInjectionLongTermValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashInjectionLongTermValue']} prefix={detailedInfo['cashInjectionLongTermValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashInjectionLongTermValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Ending Cash Balance After Transfers</td>
                                                <td>
                                                    <span className={classNames( detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] < 0 && classes.labelColor)}>
                                                        {( detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] !== '' &&  detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] !== null &&  detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={ detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue']} prefix={ detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] >= 0 ? '$' : '($'} suffix={ detailedInfo['endingCashSurplusValue'] + detailedInfo['cashInjectionShortTermValue'] + detailedInfo['cashInjectionLongTermValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                )}
                                {currentColumn === 'Excess Cash Put into STI' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td colSpan="2" className={ classes.strongText }>Excess Cash Moved into Short-Term Investments</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            {detailedInfo.excessCashPutintoSTICondition === 1 && 
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.excessCashPutintoSTICondition === 1 && 
                                            <tr>
                                                <td colspan="3" className={classNames(classes.strongText, classes.textCenter, classes.bottomBorder)}>Short-Term Investments Begin in Year {detailedInfo['shortTermInvestmentBeginYearsti']}</td>
                                            </tr>
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.excessCashPutintoSTICondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Ending Cash Balance after Transfers</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['endingCashBalanceAfterTransferValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['endingCashBalanceAfterTransferValue'] !== '' && detailedInfo['endingCashBalanceAfterTransferValue'] !== null && detailedInfo['endingCashBalanceAfterTransferValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['endingCashBalanceAfterTransferValue']} prefix={detailedInfo['endingCashBalanceAfterTransferValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['endingCashBalanceAfterTransferValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.excessCashPutintoSTICondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Maximum Cash Balance</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['maximumCashBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['maximumCashBalanceValue'] !== '' && detailedInfo['maximumCashBalanceValue'] !== null && detailedInfo['maximumCashBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['maximumCashBalanceValue']} prefix={detailedInfo['maximumCashBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['maximumCashBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Excess Cash Moved into Short-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['excessCashPutintoSTIValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['excessCashPutintoSTIValue'] !== '' && detailedInfo['excessCashPutintoSTIValue'] !== null && detailedInfo['excessCashPutintoSTIValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['excessCashPutintoSTIValue']} prefix={detailedInfo['excessCashPutintoSTIValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['excessCashPutintoSTIValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Ending Cash Balance' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td className={ classes.strongText } colSpan="2">Ending Cash Balance</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className={ classes.strongText }>Beginning Cash Balances</td>
                                                <td className={ classNames( classes.strongText, classes.bottomBorder )}>
                                                    <span className={classNames(detailedInfo['beginingCashBalance'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['beginingCashBalance'] !== '' && detailedInfo['beginingCashBalance'] !== null && detailedInfo['beginingCashBalance'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['beginingCashBalance']} prefix={detailedInfo['beginingCashBalance'] >= 0 ? '$' : '($'} suffix={detailedInfo['beginingCashBalance'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className={ classes.leftPaddingSpace }>Total Cash from Operating Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromOperatingActivities'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromOperatingActivities'] !== '' && detailedInfo['totalCashFromOperatingActivities'] !== null && detailedInfo['totalCashFromOperatingActivities'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromOperatingActivities']} prefix={detailedInfo['totalCashFromOperatingActivities'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromOperatingActivities'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className={ classes.leftPaddingSpace }>Total Cash from Investing Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromInvestingActivities'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromInvestingActivities'] !== '' && detailedInfo['totalCashFromInvestingActivities'] !== null && detailedInfo['totalCashFromInvestingActivities'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromInvestingActivities']} prefix={detailedInfo['totalCashFromInvestingActivities'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromInvestingActivities'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className={ classes.leftPaddingSpace }>Total Cash from Financing Activities</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['totalCashFromFinancingActivities'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['totalCashFromFinancingActivities'] !== '' && detailedInfo['totalCashFromFinancingActivities'] !== null && detailedInfo['totalCashFromFinancingActivities'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['totalCashFromFinancingActivities']} prefix={detailedInfo['totalCashFromFinancingActivities'] >= 0 ? '$' : '($'} suffix={detailedInfo['totalCashFromFinancingActivities'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td className={ classes.strongText }>Ending Cash Balance before Transfers</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['endingCashSurplus'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['endingCashSurplus'] !== '' && detailedInfo['endingCashSurplus'] !== null && detailedInfo['endingCashSurplus'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['endingCashSurplus']} prefix={detailedInfo['endingCashSurplus'] >= 0 ? '$' : '($'} suffix={detailedInfo['endingCashSurplus'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.topSingleBorder }>
                                                <td>&nbsp;</td>
                                                <td className={ classes.leftPaddingSpace }>Cash Injection from Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['cashInjectionFromInvestments'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['cashInjectionFromInvestments'] !== '' && detailedInfo['cashInjectionFromInvestments'] !== null && detailedInfo['cashInjectionFromInvestments'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['cashInjectionFromInvestments']} prefix={detailedInfo['cashInjectionFromInvestments'] >= 0 ? '$' : '($'} suffix={detailedInfo['cashInjectionFromInvestments'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td className={ classes.strongText }>Ending Cash Balance after Transfers</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['endingCashCashBalAfterTransfer'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['endingCashCashBalAfterTransfer'] !== '' && detailedInfo['endingCashCashBalAfterTransfer'] !== null && detailedInfo['endingCashCashBalAfterTransfer'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['endingCashCashBalAfterTransfer']} prefix={detailedInfo['endingCashCashBalAfterTransfer'] >= 0 ? '$' : '($'} suffix={detailedInfo['endingCashCashBalAfterTransfer'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.topSingleBorder }>
                                                <td>&nbsp;</td>
                                                <td className={ classes.leftPaddingSpace }>Excess Cash Moved into Short-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['excessCashPutIntoSTI'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['excessCashPutIntoSTI'] !== '' && detailedInfo['excessCashPutIntoSTI'] !== null && detailedInfo['excessCashPutIntoSTI'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['excessCashPutIntoSTI']} prefix={detailedInfo['excessCashPutIntoSTI'] >= 0 ? '$' : '($'} suffix={detailedInfo['excessCashPutIntoSTI'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td className={ classes.strongText }>Ending Cash Balance</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['finalEndingCashBalance'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['finalEndingCashBalance'] !== '' && detailedInfo['finalEndingCashBalance'] !== null && detailedInfo['finalEndingCashBalance'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['finalEndingCashBalance']} prefix={detailedInfo['finalEndingCashBalance'] >= 0 ? '$' : '($'} suffix={detailedInfo['finalEndingCashBalance'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Short-Term Investments Balance' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td className={ classes.strongText } colSpan="2">Short-Term Investments Balance</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 && 
                                            <tr>
                                                <td colspan="3" className={classNames(classes.strongText, classes.textCenter, classes.bottomBorder)}>Short-Term Investments Begin in Year {detailedInfo['shortTermInvestmentBeginYear']}</td>
                                            </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 && 
                                            <tr>
                                            <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 && detailedInfo.shortTermInvestmentBalanceCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Short-Term Investments Balance in Year {detailedInfo['shortTermInvestmentBalanceYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['shortTermInvestmentBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['shortTermInvestmentBalanceValue'] !== '' && detailedInfo['shortTermInvestmentBalanceValue'] !== null && detailedInfo['shortTermInvestmentBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shortTermInvestmentBalanceValue']} prefix={detailedInfo['shortTermInvestmentBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shortTermInvestmentBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Annual Cash Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['annualCashContributionValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['annualCashContributionValue'] !== '' && detailedInfo['annualCashContributionValue'] !== null && detailedInfo['annualCashContributionValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['annualCashContributionValue']} prefix={detailedInfo['annualCashContributionValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['annualCashContributionValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Annual Earnings</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['annualEarningsValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['annualEarningsValue'] !== '' && detailedInfo['annualEarningsValue'] !== null && detailedInfo['annualEarningsValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['annualEarningsValue']} prefix={detailedInfo['annualEarningsValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['annualEarningsValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Transfer to Cash Amount</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['transferToCashAmountValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['transferToCashAmountValue'] !== '' && detailedInfo['transferToCashAmountValue'] !== null && detailedInfo['transferToCashAmountValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['transferToCashAmountValue']} prefix={detailedInfo['transferToCashAmountValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['transferToCashAmountValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.shortTermBalanceFinalCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Contribution from Excess Cash</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['contributionExcessCashValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['contributionExcessCashValue'] !== '' && detailedInfo['contributionExcessCashValue'] !== null && detailedInfo['contributionExcessCashValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['contributionExcessCashValue']} prefix={detailedInfo['contributionExcessCashValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['contributionExcessCashValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Short-Term Investments Balance in Year {detailedInfo['Year']}</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['shortTermBalanceFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['shortTermBalanceFinalValue'] !== '' && detailedInfo['shortTermBalanceFinalValue'] !== null && detailedInfo['shortTermBalanceFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shortTermBalanceFinalValue']} prefix={detailedInfo['shortTermBalanceFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shortTermBalanceFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Excess STI Put into LTI' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td className={ classes.strongText } colSpan="2">Excess Short-Term Investments Moved into Long-Term Investments</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.excessCashPutintoLTICondition === 1 &&
                                            <tr>
                                                <td colspan="3" className={classNames(classes.strongText, classes.textCenter, classes.bottomBorder)}>Long-Term Investments Begin in Year {detailedInfo['longTermInvestmentBeginYearsti']}</td>
                                            </tr>
                                            }
                                            {detailedInfo.excessCashPutintoLTICondition === 1 &&
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.excessCashPutintoLTICondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Short-term Investments Balance</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['shortTermInvestmentsBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['shortTermInvestmentsBalanceValue'] !== '' && detailedInfo['shortTermInvestmentsBalanceValue'] !== null && detailedInfo['shortTermInvestmentsBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['shortTermInvestmentsBalanceValue']} prefix={detailedInfo['shortTermInvestmentsBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['shortTermInvestmentsBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.excessCashPutintoLTICondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><i>Maximum Short-Term Investments Balance</i></td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['maximumShortTermInvestmentBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['maximumShortTermInvestmentBalanceValue'] !== '' && detailedInfo['maximumShortTermInvestmentBalanceValue'] !== null && detailedInfo['maximumShortTermInvestmentBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['maximumShortTermInvestmentBalanceValue']} prefix={detailedInfo['maximumShortTermInvestmentBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['maximumShortTermInvestmentBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>&nbsp;</td>
                                                <td>Excess Short-Term Investments Moved into Long-Term Investments</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['excessCashPutintoLTIValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['excessCashPutintoLTIValue'] !== '' && detailedInfo['excessCashPutintoLTIValue'] !== null && detailedInfo['excessCashPutintoLTIValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['excessCashPutintoLTIValue']} prefix={detailedInfo['excessCashPutintoLTIValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['excessCashPutintoLTIValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {currentColumn === 'Long-Term Investments Balance' && (
                                    <table className={ classes.netIncomeTable }>
                                        <tbody>
                                            <tr className={ classes.popupHeader }>
                                                <td className={ classes.strongText } colSpan="2">Long-Term Investments Balance</td>
                                                <td>Year {detailedInfo['Year']}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                            <tr>
                                                <td colspan="3" className={classNames(classes.strongText, classes.textCenter, classes.bottomBorder)}>Long-Term Investments Begin in Year {detailedInfo['longTermInvestmentBeginYear']}</td>
                                            </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Long-Term Investments Balance in Year {detailedInfo['longTermInvestmentBalanceYear']}</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermInvestmentBalanceValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermInvestmentBalanceValue'] !== '' && detailedInfo['longTermInvestmentBalanceValue'] !== null && detailedInfo['longTermInvestmentBalanceValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermInvestmentBalanceValue']} prefix={detailedInfo['longTermInvestmentBalanceValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermInvestmentBalanceValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Annual Cash Contribution</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermAnnualCashContributionValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermAnnualCashContributionValue'] !== '' && detailedInfo['longTermAnnualCashContributionValue'] !== null && detailedInfo['longTermAnnualCashContributionValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermAnnualCashContributionValue']} prefix={detailedInfo['longTermAnnualCashContributionValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermAnnualCashContributionValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Annual Earnings</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermAnnualEarningsValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermAnnualEarningsValue'] !== '' && detailedInfo['longTermAnnualEarningsValue'] !== null && detailedInfo['longTermAnnualEarningsValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermAnnualEarningsValue']} prefix={detailedInfo['longTermAnnualEarningsValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermAnnualEarningsValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Transfer to Cash Amount</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermTransferToCashAmountValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermTransferToCashAmountValue'] !== '' && detailedInfo['longTermTransferToCashAmountValue'] !== null && detailedInfo['longTermTransferToCashAmountValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermTransferToCashAmountValue']} prefix={detailedInfo['longTermTransferToCashAmountValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermTransferToCashAmountValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            {detailedInfo.longTermBalanceFinalValueCondition === 1 &&
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Contribution from Excess Short-Term Investments</td>
                                                    <td>
                                                        <span className={classNames(detailedInfo['longTermcontributionExcessCashValue'] < 0 && classes.labelColor)}>
                                                            {(detailedInfo['longTermcontributionExcessCashValue'] !== '' && detailedInfo['longTermcontributionExcessCashValue'] !== null && detailedInfo['longTermcontributionExcessCashValue'] !== undefined) ? (
                                                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermcontributionExcessCashValue']} prefix={detailedInfo['longTermcontributionExcessCashValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermcontributionExcessCashValue'] < 0 && ')'} />
                                                            ) : ('')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={ classes.incomeBorder }>
                                                <td>=</td>
                                                <td>Long-Term Investments Balance in Year {detailedInfo['Year']}</td>
                                                <td>
                                                    <span className={classNames(detailedInfo['longTermBalanceFinalValue'] < 0 && classes.labelColor)}>
                                                        {(detailedInfo['longTermBalanceFinalValue'] !== '' && detailedInfo['longTermBalanceFinalValue'] !== null && detailedInfo['longTermBalanceFinalValue'] !== undefined) ? (
                                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['longTermBalanceFinalValue']} prefix={detailedInfo['longTermBalanceFinalValue'] >= 0 ? '$' : '($'} suffix={detailedInfo['longTermBalanceFinalValue'] < 0 && ')'} />
                                                        ) : ('')}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>                  
                </DialogContent>
                <DialogActions className={ classes.modalBottomFooter }>
                    <div className={ classes.incomeFooter }>
                        <Button onClick={() => setOpenCashFlowSheetPopup(false)}>
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

CashFlowSheetPopup.propTypes = {
    classes: PropTypes.object.isRequired,
    dispalyObj: PropTypes.string.isRequired,
    rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
    openCashFlowSheetPopup: PropTypes.bool.isRequired,
    setOpenCashFlowSheetPopup: PropTypes.func.isRequired,
    currentColumn: PropTypes.string.isRequired

};

const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(CashFlowSheetPopup);
