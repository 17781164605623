import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const FitOutCosts = ( props ) => {
  const { classes, inputData, updateData } = props;

  const [fitoutCost, setFitoutCost] = React.useState( {} );
  const [percentOfFitOut, setPercentOfFitOut] = React.useState( 0 );



  useEffect(()=>{
    let fittingcost = {};
    fittingcost['fitting_furniture'] = 0;
    fittingcost['fitting_window_treatments'] = 0;
    fittingcost['fitting_landscaping'] = 0;
    fittingcost['fitting_home_improvements'] = 0;
    fittingcost['fitting_other'] = inputData.external_and_internal_fit_out_costs;
    // fittingcost['external_and_internal_fit_out_costs'] = 0;
    setFitoutCost(fittingcost);
    // eslint-disable-next-line
  },[])

 
  useEffect( () => {
    let totalFitClost = 0;
    const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;

    if ( fitoutCost.fitting_furniture !== undefined ) {
      totalFitClost += fitoutCost.fitting_furniture;
    }
    if ( fitoutCost.fitting_window_treatments !== undefined ) {
      totalFitClost += fitoutCost.fitting_window_treatments;
    }
    if ( fitoutCost.fitting_landscaping !== undefined ) {
      totalFitClost += fitoutCost.fitting_landscaping;
    }
    if ( fitoutCost.fitting_home_improvements !== undefined ) {
      totalFitClost += fitoutCost.fitting_home_improvements;
    }
    if ( fitoutCost.fitting_other !== undefined ) {
      totalFitClost += fitoutCost.fitting_other;
    }

    updateData( 'total_obj', {...inputData,...fitoutCost,...{external_and_internal_fit_out_costs:totalFitClost}} );

    let percent = 0;
    if ( purchasePrice > 0 ) {
      percent = ( totalFitClost / purchasePrice ) * 100;
    }
    setPercentOfFitOut( percent );

    // eslint-disable-next-line
},[fitoutCost])

  const updateValue = ( e, field ) => {

    let newvalue = e.target.value;
    if(newvalue === '') {
      newvalue = 0;
    }
    newvalue = convertCurrencyToInteger(newvalue);

    const closing = { ...fitoutCost };
    closing[field] = newvalue;
    setFitoutCost( closing );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>When you buy a home, there are other upfront costs needed to make the home livable. You’ll most likely need some furniture, blinds for the windows, landscaping, and home improvements such as new appliances in the kitchen or new floors.</p>
      <p>Fit out costs can vary depending on if you buy a new or used home. You want to make the home livable without stressing your financial resources. Make sure you have the cash and investments to support these additional costs.</p>

      <div className={ classNames( classes.closingCosts, classes.fitoutCosts ) }>
        <table>
          <thead>
            <tr>
              <th colSpan="3">Fit Out Costs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When you buy a property, you’ll need to furnish the place. How much furniture will you buy upfront to make the property livable? You don’t need to furnish the whole house upfront, but will you be buying furniture for the owner’s bedroom, family room, and kitchen?" />
              </td>
              <td>
                Furniture
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ fitoutCost.fitting_furniture !== undefined ? fitoutCost.fitting_furniture : 0 } onChange={ ( e ) => updateValue( e, 'fitting_furniture' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When you buy a property, there may not be any shades in the place! You could use wood or vinyl shades, blinds, or shutters. Or, something softer such as sheers, curtains, or drapes. Window treatments could range from $100 per window to as high as $1,000 per window depending on the quality used." />
              </td>
              <td>
                Window Treatments
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ fitoutCost.fitting_window_treatments !== undefined ? fitoutCost.fitting_window_treatments : 0 } onChange={ ( e ) => updateValue( e, 'fitting_window_treatments' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When you buy a property, the landscaping may not be in perfect shape. For example, you might want to take down some trees and plant new trees, make some changes to the lawn configuration, add new mulch bedding, or add some new shrubs." />
              </td>
              <td>
                Landscaping
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ fitoutCost.fitting_landscaping !== undefined ? fitoutCost.fitting_landscaping : 0 } onChange={ ( e ) => updateValue( e, 'fitting_landscaping' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When you buy a property, you may want to make some improvements. For example, you could upgrade the appliances in the kitchen, rip up the carpet and lay new hardwood floors, remodel a bathroom, or more. Input the amount of home improvements you need to complete to make the home livable." />
              </td>
              <td>
                Home Improvements
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ fitoutCost.fitting_home_improvements !== undefined ? fitoutCost.fitting_home_improvements : 0 } onChange={ ( e ) => updateValue( e, 'fitting_home_improvements' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Capture any other one-time, upfront costs you expect to pay for your purchase." />
              </td>
              <td>
                Other
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ fitoutCost.fitting_other !== undefined ? fitoutCost.fitting_other : 0 } onChange={ ( e ) => updateValue( e, 'fitting_other' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>&nbsp;</th>
              <th>Total Fit Out Costs</th>
              <th><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.external_and_internal_fit_out_costs } displayType="text" thousandSeparator prefix="$" /></th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th className={ classes.leftSpace }><i>% of Purchase Price</i></th>
              <th>
                <i>
                  {parseFloat( percentOfFitOut ).toFixed( 2 ) > 0 ? parseFloat( percentOfFitOut ).toFixed( 2 ) : 1.00}
                  %
                </i>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

FitOutCosts.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( FitOutCosts );
