import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  Container,
} from '@material-ui/core';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import NewsBanner from '../../assets/img/news/news_images/contact_b-m.jpg';
import WelcomeBrochure from '../../assets/img/news/news_images/weelcome_brochure.png';
import inkSchools from '../../assets/img/news/news_images/ink_schools.png';
import inkBusiness from '../../assets/img/news/news_images/ink_business.png';
import studentHandout from '../../assets/img/news/news_images/student_handout.png';
import parentsHandout from '../../assets/img/news/news_images/parents_handout.png';
import financialBrochure from '../../assets/img/news/news_images/financial_brochure.png';
import davidPhyscian from '../../assets/img/news/news_images/david_physcian.png';
import financialConfigurator from '../../assets/img/news/news_images/financial_configurator.png';
import powerup101 from '../../assets/img/news/news_images/powerup101.jpg';
import careerSketchToolkit from '../../assets/img/news/news_images/career-sketching-toolkit.jpg';
import Pageloader from '../../components/ui/pageloader';

import WelcomeBrochurePDF from '../../assets/img/news/news_pdfs/Inkwiry_Welcome_Brochure.pdf';
import inkSchoolsPDF from '../../assets/img/news/news_pdfs/Inkwiry_Schools.pdf';
import inkBusinessPDF from '../../assets/img/news/news_pdfs/Inkwiry_Businesses.pdf';
import studentHandoutPDF from '../../assets/img/news/news_pdfs/Inkwiry_Students_Handout.pdf';
import parentsHandoutPDF from '../../assets/img/news/news_pdfs/Inkwiry_Parents_Handout.pdf';
import financialBrochurePDF from '../../assets/img/news/news_pdfs/Inkwiry_Schools_Personal_Finance_Brochure.pdf';
import davidPhyscianPDF from '../../assets/img/news/news_pdfs/David_Navy_Physician_Inkwiry_Career_Sketch.pdf';
import financialConfiguratorPDF from '../../assets/img/news/news_pdfs/Inkwirys_Financial_Configurator_and_Taxes_for_2019.pdf';
import careerSketchToolkitPDF from '../../assets/img/news/news_pdfs/Inkwirys_Career_Sketching_Toolkit_May_2020.pdf';
import powerup101PDF from '../../assets/img/news/news_pdfs/PF101-Financial-Tookit.pdf';

const News = (props) => {
  const { classes } = props;

  const [filter, setFilter] = useState(['all']);
  const [loading, setloading] = useState(false);

  if (localStorage.getItem('loading') === 'true' || localStorage.getItem('loading') === true) {
    setloading(true)
    localStorage.removeItem('loading');
  }

  React.useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, [loading]);

  React.useEffect(() => {
    let PortID = window.location.href.split("?");
    if (PortID[1]) {
      handleFilters(PortID[1], "fromRoute");
    }
  }, [window.location.href])

  const handleFilters = (value, from) => {
    if (from === "fromRoute") {
      setFilter([value]);
    } else {
      if (value === 'all') {
        setFilter(['all']);
      } else {
        let updatedFilters = filter.filter((item) => item !== 'all');
        const index = updatedFilters.indexOf(value);
        if (index > -1) {
          updatedFilters = updatedFilters.filter((item) => item !== value);
          setFilter(updatedFilters);
        } else {
          setFilter(updatedFilters.concat(value));
        }
      }
    }

  };

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.inkwiryBenfitsBannerOne}>
        <img src={NewsBanner} alt="Benfits" />
        <Typography variant="h3">News</Typography>
      </div>

      <div className={classes.Categories}>
        <Container classsName={classes.container}>
          <div className={classes.CategoriesList}>
            <p>CATEGORIES</p>
            <ul>
              <li className={filter.indexOf('all') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('all')}>All</span>
              </li>
              <li className={filter.indexOf('inkwiry') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('inkwiry')}>Inkwiry</span>
              </li>
              <li className={filter.indexOf('planning') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('planning')}>Planning</span>
              </li>
              <li className={filter.indexOf('money') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('money')}>Money</span>
              </li>
              <li className={filter.indexOf('taxes') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('taxes')}>Taxes</span>
              </li>
              <li className={filter.indexOf('career') !== -1 ? classes.activeClass : classes.CategoryNormal}>
                <span aria-hidden="true" onClick={() => handleFilters('career')}>Career</span>
              </li>
            </ul>
          </div>

          <Grid container className={classes.categoriesListAll}>
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={WelcomeBrochurePDF} target="_blank" rel="noopener noreferrer" download><img src={WelcomeBrochure} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={WelcomeBrochurePDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Welcome brochure</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={inkSchoolsPDF} target="_blank" rel="noopener noreferrer" download><img src={inkSchools} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={inkSchoolsPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Ink + Schools</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={inkBusinessPDF} target="_blank" rel="noopener noreferrer" download><img src={inkBusiness} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={inkBusinessPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Ink + Businesses</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={studentHandoutPDF} target="_blank" rel="noopener noreferrer" download><img src={studentHandout} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={studentHandoutPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Student Handout</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={parentsHandoutPDF} target="_blank" rel="noopener noreferrer" download><img src={parentsHandout} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={parentsHandoutPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Parent Handout</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={financialBrochurePDF} target="_blank" rel="noopener noreferrer" download><img src={financialBrochure} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={financialBrochurePDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Inkwiry + Schools Personal Finance Brochure</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('career')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={davidPhyscianPDF} target="_blank" rel="noopener noreferrer" download><img src={davidPhyscian} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={davidPhyscianPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">David Navy Physician Career Sketch</Typography></a>
                    <p>
                      Inkwiry, Career
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('taxes')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={financialConfiguratorPDF} target="_blank" rel="noopener noreferrer" download><img src={financialConfigurator} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={financialConfiguratorPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Inkwiry&apos;s Financial Configurator + Taxes</Typography></a>
                    <p>
                      Inkwiry, Taxes
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={careerSketchToolkitPDF} target="_blank" rel="noopener noreferrer" download><img src={careerSketchToolkit} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={careerSketchToolkitPDF} target="_blank" rel="noopener noreferrer" download><Typography variant="h3">Career Sketching Toolkit</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {(filter.includes('all') || filter.includes('inkwiry') || filter.includes('money') || filter.includes('planning')) && (
              <Grid item sm={4}>
                <div className={classes.newsBoxMain}>
                  <figure>
                    <a href={powerup101PDF}  rel="noopener noreferrer" download><img src={powerup101} alt="" /></a>
                  </figure>
                  <div className={classes.newsBox}>
                    <a href={powerup101PDF}  rel="noopener noreferrer" download><Typography variant="h3">Personal Finance 101 Toolkit</Typography></a>
                    <p>
                      Inkwiry, Planning
                      <font>Jan 2020</font>
                    </p>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

News.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(News);