import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-career-path/styles';
import CareerBottomTabs from './career-bottom-tab-graphs';

const MyBudget = ( props ) => {
  const {
    classes, handleCareerObject,
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Well done. Check out your detailed budget. You&apos;ll see that this budget includes any expenses from other modules that overlap with this career module. Are you cash flow positive? If not, use the back button to readjust your spending.</p>
      <p>Next up, retirement accounts.</p>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>
  );
};

MyBudget.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( MyBudget );
