import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import powerImg from '../../assets/img/personal-finance101/PF101-icon.png';
import greenLine from '../../assets/img/my-career-sketch/green-hand-drawn-line.png';
import certificateImg from '../../assets/img/personal-finance101/PF101-certificate-v3.png';
import quoteMark from '../../assets/img/personal-finance101/dollar.png';
import greenCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check_bg.png';
import powerLogo from '../../assets/img/personal-finance101/powerup-101-gold.png';
import beginner from '../../assets/img/personal-finance101/beginner.png';
import intermediate from '../../assets/img/personal-finance101/intermediate.png';
import pro from '../../assets/img/personal-finance101/expert.png';
import expert from '../../assets/img/personal-finance101/master.png';
import legend from '../../assets/img/personal-finance101/legend.png';
import c1 from '../../assets/img/personal-finance101/pf101-personal-finance.png';
import c2 from '../../assets/img/personal-finance101/pf101-credit-score.png';
import c3 from '../../assets/img/personal-finance101/pf101-income-expenses.png';
import c4 from '../../assets/img/personal-finance101/pf101-investing.png';
import c5 from '../../assets/img/personal-finance101/pf101-budgeting.png';
import c6 from '../../assets/img/personal-finance101/pf101-insurance.png';
import c7 from '../../assets/img/personal-finance101/pf101-banking+cards.png';
import c8 from '../../assets/img/personal-finance101/pf101-goals.png';
import c9 from '../../assets/img/personal-finance101/pf101-online-mobile-banking.png';
import c10 from '../../assets/img/personal-finance101/pf101-wrapup.png';
import quoteImage from '../../assets/img/personal-finance101/quote_mark.png';
import powerupliteCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check.png';

const PersonalFinance101 = ( props ) => {
  const { classes, history } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.getPowerUpLite }>
        <div className={ classes.powerBanner }>
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3" className={ classes.bannerTitleNew }>
                  Game On: Rack Up Your Money Skills
                </Typography>
                <p>The essentials of personal finance for a lifetime of smart money management.</p>
                <span className={classes.clickHere} onClick={()=>{history.push('/join')}}>
                  <span className={ classes.showMoney }>Let&apos;s Play</span>
                  {'>'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSmartMoves }>
            <div className={ classes.container }>
              <Typography variant="h2" component="h2">
                Invest four hours in Inkwiry&apos;s
                {' '}
                <b>Personal Finance 101</b>
                <span className={ classes.blockSpan }>
                  and
                  {' '}
                  <b>gain fundamental money skills and knowledge for life</b>
                </span>
                <span className={ classes.blockSpan }>
                  via an online, gamified experience.
                </span>
              </Typography>
            </div>
          </div>
          <div className={ classes.powerUpClaim }>
            <Grid container>
              <Grid item sm={ 4 } xs={ 12 }>
                <div className={ classes.leftImg }>
                  <figure>
                    <img src={ powerImg } alt="" />
                  </figure>
                </div>
              </Grid>
              <Grid item sm={ 8 } xs={ 12 }>
                <div className={ classes.rightContent }>
                  <p>
                    <span className={ classes.bluesSpanColor }>You just landed your dream job.</span>
                    {' '}
                    But now you need to think about opening a new bank account in a strange city, arrange direct deposit of your paycheck, consider 401(k) options and buy renter’s insurance for your new apartment. Oh and by the way, you don’t have a budget, an investing strategy or even a sketchy plan to reach your goals.
                  </p>
                  <p>Personal Finance 101 helps you sharpen your money game so you know the rules, the language of finance, and fundamental concepts and strategies. Why? So you can make smart moves to reach your goals and achieve financial freedom.</p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.circlesBlock }>
            <div className={ classes.circlesSection }>
              <div className={ classNames( classes.roundCircle, classes.circleOne ) }>
                <img src={ quoteMark } alt="" />
                <span>
                  <b>78%</b>
                  {' '}
                  of American workers
                  {' '}
                  <b>live paycheck to paycheck</b>
                </span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
              <div className={ classNames( classes.roundCircle, classes.circletwo ) }>
                <img src={ quoteMark } alt="" />
                <span>
                  <b>69%</b>
                  {' '}
                  of Americans say they
                  {' '}
                  <b>have less than $1,000</b>
                  {' '}
                  in a savings account.
                </span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
              <div className={ classNames( classes.roundCircle, classes.circleThree ) }>
                <img src={ quoteMark } alt="" />
                <span>
                  <b>42%</b>
                  {' '}
                  of Americans will
                  {' '}
                  <b>retire with less than $10,000</b>
                  {' '}
                  saved
                </span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
            </div>
            <div className={ classes.circlesBottom }>
              <img className={ classes.greenLine } src={ greenLine } alt="" />
              <div className={ classes.bestIntrest }>
                “An investment in knowledge pays the best interest.”
                <span>— Benjamin Franklin</span>
              </div>
            </div>
          </div>
          <div className={ classes.financialFuture }>
            <Typography variant="h2" component="h2">
              How can Personal Finance 101
              {' '}
              <b>dramatically</b>
              {' '}
              <span className={ classes.blockSpan }>
                <b>improve</b>
                {' '}
                your financial future?
              </span>
            </Typography>
            <div className={ classes.skillsKnowledge }>
              <div className={ classes.skillsContent }>
                <img className={ classes.checkImage } src={ greenCheck } alt="" />
                When it comes to your money, Personal Finance 101 takes you from bumbling to brilliant in 10 levels of bite-size lessons and interactive activities rooted in real-world experience. The easy-to-understand content gives you the tools you need to skillfully manage your money throughout your life.
              </div>
              <p className={ classes.wealthText }>Personal Finance 101 helps you grow your wealth and achieve financial freedom</p>
              <p>Thanks to Personal Finance 101, you’ll understand the terminology of finance and the fundamental concepts and strategies of money management. You can start to apply your knowledge right away, and go on to grow your wealth and achieve financial freedom.</p>
              <div className={ classes.financialList }>
                <Typography variant="h3" component="h3">In Personal Finance 101, you’ll:</Typography>
                <ul>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Gain the money skills and knowledge you need to make informed financial life decisions, grow your net worth and achieve financial freedom.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Learn the language of money so you are open to opportunities and can grow your wealth.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Build a financial life toolkit of essential money management strategies such as saving and investing to capitalize on compounding growth, budgeting income and expenses, learning to manage debt and more.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Discover smart moves you can start using immediately to avoid life&apos;s traps and pitfalls, which means you end up with money saved plus money not lost.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Improve your financial lifestyle by managing your money like a pro.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Learn how to ask “what if?” and use Inkwiry&apos;s tools to find your own best answers, minimizing your risk and maximizing reward.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Grow your confidence about planning and how it can provide peace of mind.</li>
                </ul>
              </div>
              <a href="/" className={ classes.startPf101 }>
                <span>START PERSONAL FINANCE 101 </span>
                {'>'}
                {' '}
              </a>
            </div>
          </div>
        </Container>
        <div className={ classes.powerLife }>
          <div className={ classes.eightBlocks }>
            <Container>
              <div className={ classes.rankPictures }>
                <div className={ classes.rankImages }>
                  <img className={ classes.rankPic } src={ beginner } alt="" />
                  <p>Beginner</p>
                </div>
                <div className={ classes.rankImages }>
                  <img className={ classes.rankPic } src={ intermediate } alt="" />
                  <p>Intermediate</p>
                </div>
                <div className={ classes.rankImages }>
                  <img className={ classes.rankPic } src={ pro } alt="" />
                  <p>Pro</p>
                </div>
                <div className={ classes.rankImages }>
                  <img className={ classes.rankPic } src={ expert } alt="" />
                  <p>Expert</p>
                </div>
                <div className={ classes.rankImages }>
                  <img className={ classes.rankPic } src={ legend } alt="" />
                  <p>Legend</p>
                </div>
              </div>
              <p className={ classes.titleText }>
                Go from 
                {' '}
                <b>Beginner to Legend</b>
                {' '}
                as you rack up points
                <span>and improve your personal finance game.</span>
              </p>
              <p className={ classes.subTitle }>Here&apos;s what you&apos;ll cover in Personal Finance 101:</p>
              <div className={ classes.creditRow }>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c1 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>What is Personal Finance?</h4>
                    <p>Learning and understanding how to manage your money</p>
                  </div>
                </div>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c2 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Credit Score + Debt</h4>
                    <p>
                      <span>Credit Score 101, Loans 101</span>
                      <span>Debunking credit score myths</span>
                      <span>Lower the cost of a loan</span>
                      <span>Student loans | Loan repayment</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.creditRow }>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c3 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Income + Taxes</h4>
                    <p>
                      <span>Types of income</span>
                      <span>Personal income taxes</span>
                      <span>Federal income taxes</span>
                      <span>Filing your taxes</span>
                    </p>
                  </div>
                </div>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c4 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Basics of Investing</h4>
                    <p>
                      <span>Investing 101</span>
                      <span>Stocks + bonds | Retirement</span>
                      <span>Funds + alternative investments</span>
                      <span>How to start investing</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.creditRow }>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c5 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Budgeting + Saving</h4>
                    <p>
                      <span>Budgeting 101</span>
                      <span>Budgeting guidelines</span>
                      <span>Save and invest to grow wealth</span>
                    </p>
                  </div>
                </div>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c6 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Basics of Insurance</h4>
                    <p>
                      <span>Auto, homeowner’s +</span>
                      <span>renter’s insurance</span>
                      <span>Life insurance</span>
                      <span>Health insurance</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.creditRow }>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c7 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Bank Accounts + Bank Cards</h4>
                    <p>
                      <span>7 tips to bank responsibly</span>
                      <span>Checking vs savings accounts</span>
                      <span>Debit vs credit cards</span>
                    </p>
                  </div>
                </div>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c8 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Goals + Purpose</h4>
                    <p>
                      <span>SMART goals</span>
                      <span>What are you trying to achieve?</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.creditRow }>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c9 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Online + Mobile Banking</h4>
                    <p>
                      <span>7 tips to safe online banking</span>
                      <span>Manage your money from your phone</span>
                      <span>Ins and outs of online banking</span>
                    </p>
                  </div>
                </div>
                <div className={ classes.creditLevel }>
                  <div className={ classes.creditImage }>
                    <img src={ c10 } alt="" />
                  </div>
                  <div className={ classes.creditContent }>
                    <h4>Wrapup + Review</h4>
                    <p>
                      <span>Financial life toolkit</span>
                      <span>Certificate of completion</span>
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className={ classes.salesCertificate }>
          <Container className={ classes.container }>
            <div className={ classes.powerToday }>
              <div className={ classes.powerGold }>
                <img className={ classes.powerLogo } src={ powerLogo } alt="" />
              </div>
              <Typography variant="h2" component="h2">Start Personal Finance 101 today!</Typography>
              <p>
                <span className={ classes.bluesSpanColor }>Personal Finance 101 is bundled with your Inkwiry membership.</span>
                Choose one of three plans ranging in cost from an affordable $5 to $7 to $9 per month. Invest a little bit of time and money in your financial future now – it will pay dividends for the rest of your life. If you choose the 30-day membership plan for $9, you&apos;ll have plenty of time to complete this four-hour course <b>PLUS</b> use Plan With Inkwiry to quickly build a financial life plan.
                (We hope your financial life is forever transformed with the financial skills and knowledge you develop through this course, and that you&apos;ll continue on in Inkwiry to create a roadmap to your future.)
              </p>
              <div className={ classes.completeCertificate }>
                <div className={ classes.certificateLeft }>
                  <p>
                    And when you’ve finished Personal Finance 101, you’ll receive a personalized
                    {' '}
                    <b>Certificate of Completion</b>
                    {' '}
                    with a reminder of everything you achieved in the course.
                    {' '}
                  </p>
                  <p>Just click on the button to sign up!</p>
                </div>
                <div className={ classes.certificateRight }>
                  <img src={ certificateImg } alt="" />
                </div>
              </div>
              <a href="/" className={ classes.startPf101 }>
                <span>START PERSONAL FINANCE 101</span>
                {'>'}
                {' '}
              </a>
            </div>
          </Container>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.schoolsBlock }>
            <h3>
              High School and College Students,
              <span>Teachers and Guidance Counselors Agree:</span>
            </h3>
            <h2>Personal Finance 101 “is Fantastic!”</h2>
            <div className={ classes.counselorSection }>
              <div className={ classNames( classes.counselorBlock, classes.leftBlock ) }>
                <img src={ quoteImage } alt="" />
                <p>“I learned more about life in this [Personal Finance 101] class than all my other classes I&apos;ve taken in my life.”</p>
                <p>“Seeing the statistics of how effective money planning was made me feel like I was doing was important and I was excited to start saving because of it.”</p>
                <p>“I liked how useful the knowledge is in the real world. I feel like schools focus too much on material that won&apos;t benefit students in their future careers.”</p>
                <p>“I liked learning about mortgages and better ways to not spend your money. It was all straightforward and simple to learn.”</p>
                <p className={ classes.authorBlock }>– Devon Preparatory School Students, Devon, PA</p>
              </div>
              <div className={ classNames( classes.counselorBlock, classes.rightBlock ) }>
                <img src={ quoteImage } alt="" />
                <p>“The sections were brief, yet full of information. It made it easy to follow and I was never confused about the content. I would not change anything about this. I enjoyed every part of it. It is fantastic!”</p>
                <p>“The modules were easy to use and presented the information in a way that was simple to understand. The quizzes and XP points provided motivation for grasping the personal finance concepts.”</p>
                <p>“I really enjoyed how it wasn’t informational overload, meaning that I could easily understand what was being taught without having a strong finance background.”</p>
                <p className={ classes.authorBlock }>
                  – Villanova School of Business Honors Students,
                  <span>Villanova, PA</span>
                </p>
              </div>
            </div>
            <div className={ classes.counselorSection }>
              <div className={ classNames( classes.counselorBlock, classes.leftBlockNew ) }>
                <img src={ quoteImage } alt="" />
                <p>“The [Personal Finance 101] modules perfectly align with the book I&apos;m using, Financial Literacy for Millennials. Great way to hammer it in as they read/hear it from someone who is not me! And Inkwiry&apos;s practical use of the concepts is something that definitely saves me time as a teacher. Originating scenarios and coming up with hypotheticals to put these concepts into use would be a huge time burden.”</p>
                <p className={ classes.authorBlock }>
                  – Shane, Economics and Professional Studies Teacher, Devon
                  <span>Preparatory School, Devon, PA</span>
                </p>
              </div>
              <div className={ classNames( classes.counselorBlock, classes.rightBlockNew ) }>
                <img src={ quoteImage } alt="" />
                <p>“The guys get excited about the [Personal Finance 101] &quot;competition,&quot; and of course, guess what the most popular question is: &quot;What is the prize given to the winner?&quot; But I really think most of them are actually learning something. Lots of chatter on income taxes! Classes end up in discussions as the students are really interested in a subject.”</p>
                <p className={ classes.authorBlock }>
                  – Karen, Associate Director of College Guidance,
                  <span>Holy Ghost Preparatory School, Bensalem, PA</span>
                </p>
              </div>
            </div>
          </div>
        </Container>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSaleslast }>

            <div className={ classes.loseNothing }>
              <h2>
                There&apos;s nothing to
                {' '}
                <b>lose</b>
                , everything to
                {' '}
                <b>gain</b>
              </h2>
              <h3>Our 100% Satisfaction Guarantee</h3>
              <p>
                Personal Finance 101 and your Inkwiry membership comes with a {' '}
                <b>100% Satisfaction Guarantee.</b>
              </p>
              <p>We hope your financial life is forever transformed with the beginning financial skills and knowledge you develop through this course, and that you’ll continue on in Inkwiry to build your financial life plan. That said, we’ll give you seven days to request a full refund if you believe Personal Finance 101 and an Inkwiry membership is not for you.</p>
            </div>
            <div className={ classes.fewMoves }>
              <div className={ classes.movesImg }>
                <img src={ powerImg } alt="" />
              </div>
              <div className={ classes.fewMovesRight }>
                <h2>
                  <span>
                    <b>Improve your personal finance</b>
                    {' '}
                  </span>
                  <span>
                    <b>skills</b>
                    {' '}
                    for a lifetime of smart
                  </span>
                  <span>money management</span>
                </h2>
                <p>Get started with Personal Finance 101 today. </p>
              </div>
            </div>
            <a href="/" className={ classes.startPf101 }>
              <span>GET PERSONAL FINANCE 101 </span>
              {'>'}
              {' '}
            </a>
          </div>
        </Container>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

PersonalFinance101.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance( PersonalFinance101 );
