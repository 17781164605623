import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Sight from '../../sight';
import * as moduleServices from '../../../calculations/modules-services';
import * as timeLineActions from '../../../calculations/time-line';
import styles from './styles';
import logo from '../../../assets/img/build-career-sketch/logo-w.png';
import infoIcon from '../../../assets/img/build-career-sketch/info-icon.png';
import shareIcon from '../../../assets/img/build-career-sketch/share.png';

import fiIcon from '../../../assets/img/build-career-sketch/s_financial_indep.png';
import fiIconDisable from '../../../assets/img/build-career-sketch/s_financial_indep_disabled.png';
import heIcon from '../../../assets/img/build-career-sketch/s_higher_edu_bg.png';
import heIconDisable from '../../../assets/img/build-career-sketch/s_higher_edu_bg_disabled.png';
import mheIcon from '../../../assets/img/build-career-sketch/s_more_high_edu.png';
import mheIconDisable from '../../../assets/img/build-career-sketch/s_more_high_edu_disabled.png';
import fheIcon from '../../../assets/img/build-career-sketch/s_fur_higher_bg_new.png';
import fheIconDisable from '../../../assets/img/build-career-sketch/s_fur_higher_bg_new_disabled.png';
import cpIcon from '../../../assets/img/build-career-sketch/s_career_path.png';
import cpIconDisable from '../../../assets/img/build-career-sketch/s_career_path_disabled.png';
import caIcon from '../../../assets/img/build-career-sketch/s_career_advancement.png';
import caIconDisable from '../../../assets/img/build-career-sketch/s_career_advancement_disabled.png';
import acaIcon from '../../../assets/img/build-career-sketch/s_career_3_bg_new.png';
import acaIconDisable from '../../../assets/img/build-career-sketch/s_career_3_bg_new_disabled.png';
import inIcon from '../../../assets/img/build-career-sketch/s_investments_icon.png';
import inIconDisable from '../../../assets/img/build-career-sketch/s_investments_icon_disabled.png';
import faIcon from '../../../assets/img/build-career-sketch/s_family_icon.png';
import faIconDisable from '../../../assets/img/build-career-sketch/s_family_icon_disabled.png';
import vaIcon from '../../../assets/img/build-career-sketch/s_vacation.png';
import vaIconDisable from '../../../assets/img/build-career-sketch/s_vacation_disabled.png';
import carIcon from '../../../assets/img/build-career-sketch/s_buying_car.png';
import carIconDisable from '../../../assets/img/build-career-sketch/s_buying_car_disabled.png';
import homeIcon from '../../../assets/img/build-career-sketch/s_buying_home.png';
import homeIconDisable from '../../../assets/img/build-career-sketch/s_buying_home_disabled.png';
import riIcon from '../../../assets/img/build-career-sketch/s_rei_icon.png';
import riIconDisable from '../../../assets/img/build-career-sketch/s_rei_icon_disabled.png';
import fcIcon from '../../../assets/img/build-career-sketch/s_career_4_bg_new.png';
import fcIconDisable from '../../../assets/img/build-career-sketch/s_career_4_bg_new_disabled.png';
import exIcon from '../../../assets/img/build-career-sketch/s_student_loan_new.png';
import exIconDisable from '../../../assets/img/build-career-sketch/s_student_loan_new_disabled.png';
import Pageloader from '../../ui/pageloader';

const CLEAR_TIMELINE = loader('../../../graphql/schema/pwi/clear-timeline.graphql');
const CLEAR_SCENARIO = loader('../../../graphql/schema/pwi/remove-scenario.graphql');
const SHARE_SCENARIO = loader('../../../graphql/schema/pwi/share-scenario.graphql');

const ADDRESS_BOOK_CONTACT = loader('../../../graphql/schema/address-book/getAddressBookContact.graphql');
const DISPLAY_GROUP_NAME = loader('../../../graphql/schema/address-book/display-group-name.graphql');

const moduleYears = [];
for (let i = 0; i < 15; i += 1) {
  moduleYears.push(i);
}

const SimulatorPanel = (props) => {
  const {
    classes, popupStatus, setOpenPopup, recallDataApi, otherData,
  } = props;
  const [openSimulatorPanel, setOpenSimulatorPanel] = React.useState(false);
  const [popupType, setPopupType] = React.useState('');
  const [shareScenario, setShareScenario] = React.useState({});
  const [sightData, setSightData] = React.useState({});
  const [openSightPopup, setOpenSightPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [shareData, setShareData] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [groupList, setGroupList] = React.useState([]);
  const [searchList, setSearchList] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [selectType, setSelectType] = React.useState('Contacts');
  const [shareErrorMessage, setShareErrorMessage] = React.useState('');
  const [shareSuccessMessage, setShareSuccessMessage] = React.useState('');
  const [simulatorPanelDetails] = React.useState(moduleServices.simPanelCalcsCompleteData());
  const liabilites = moduleServices.module18Data.existing_credit_card_balance + moduleServices.module18Data.existing_car_current_loan_balance + moduleServices.module18Data.existig_home_current_mortgage_balance
console.log('phrudhvi',simulatorPanelDetails);
  
  const [getGroupList] = useLazyQuery(DISPLAY_GROUP_NAME, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      if (response.getAddressBookGroup !== null && response.getAddressBookGroup.length > 0) {
        setGroupList(response.getAddressBookGroup);
      } else {
        setGroupList([]);
      }
    },
  });

  const [getContactList] = useLazyQuery(ADDRESS_BOOK_CONTACT, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      if (response.getAddressBookContact !== null && response.getAddressBookContact.length > 0) {
        let data = response.getAddressBookContact;
        data = _.reject(data, { 'user': null })
        data = _.map(data, 'user');
        setUserList(data);
        let sampleData = data.map((user) => {
          user.firstLetter = user.user_fname.charAt(0).toUpperCase();
          return user;
        });
        sampleData = _.mapValues(_.groupBy(sampleData, 'firstLetter'),
          (clist) => clist.map((car) => _.omit(car, 'firstLetter')));
        setSearchList(sampleData);
      } else {
        setUserList([]);
        setSelectedList([]);
        setSearchList([]);
      }
      setLoading(false);
    },
  });

  const closeSimulator = () => {
    setOpenPopup('');
    setOpenSimulatorPanel(false);
  };

  const closeSightPopup = () => {
    if (popupStatus === 'sight-load-career' || popupStatus === 'sight-load-scenario') {
      setOpenPopup('');
    }
    setOpenSightPopup(false);
  };

  const closeFullPopup = () => {
    if (popupStatus === 'sight-save-career') {
      setOpenPopup('');
    }
  };

  const closeSharePopup = () => {
    if (popupStatus === 'share') {
      setOpenPopup('');
    }
    setOpenShare(false);
  };

  const updateCheckBox = (module) => {
    const shareDataStatus = { ...shareScenario };
    if (shareDataStatus[module] !== undefined) {
      shareDataStatus[module] = !shareDataStatus[module];
    } else {
      shareDataStatus[module] = true;
    }
    setShareScenario(shareDataStatus);
  };

  const selectAllScenarios = () => {
    const shareDataStatus = { ...shareScenario };
    let values = Object.values(shareDataStatus);
    values = _.filter(values, (value) => value === true);
    let status = true;
    if (values.length === 15) {
      status = false;
    }
    for (let c = 2; c <= 18; c += 1) {
      if (c !== 3 && c !== 7 && c !== 16) {
        shareDataStatus[`module${c}`] = status;
      }
    }
    setShareScenario(shareDataStatus);
  };

  const printYearList = (classVal, data) => {
    let startYear = moduleServices.module1Data.start_year;
    const mainStartYear = moduleServices.module1Data.start_year;

    if (startYear > 0) {
      startYear = startYear.toString().substr(-2);
      startYear = parseInt(startYear, 10);
    }

    return moduleYears.map((value) => {
      let colorStatus = false;
      if (data.selectedModule === 'module1' || data.selectedModule === 'module18') {
        if (data.startYear !== '' && data.startYear > 0) {
          colorStatus = true;
        }
      } else if (data.selectedModule === 'module4' || data.selectedModule === 'module5' || data.selectedModule === 'module8' || data.selectedModule === 'module17' || data.selectedModule === 'module2' || data.selectedModule === 'module6' || data.selectedModule === 'module15') {
        if (data.startYear !== '' && data.endYear !== '' && data.startYear <= (mainStartYear + value) && (data.endYear + 1) > (mainStartYear + value)) {
          colorStatus = true;
        }
      } else if (data.startYear !== '' && data.startYear <= (mainStartYear + value)) {
        colorStatus = true;
      }
      return (
        <div className={colorStatus && classVal}>
          {' '}
          {startYear > 0 && (value === 4 || value === 9 || value === 14) && (<i>{startYear + value}</i>)}
          {' '}
        </div>
      );
    });
  };

  const openSightPage = (module, type, scenarioId, forType) => {
    const sightPassData = {
      module,
      type,
      scenarioId,
      forType,
      from: 'popup',
    };
    setSightData(sightPassData);
    setOpenSightPopup(true);
  };

  const sightPathCallback = (type, data = '') => {
    if (type === 'sketch-load') {
      recallDataApi(type);
      closeSightPopup();
    } else if (type === 'scenario-load') {
      recallDataApi(type);
      closeSightPopup();
    } else if (type === 'scenario-clear') {
      recallDataApi(type);
      closeSightPopup();
    } else if (type === 'sketch-delete') {
      closeSightPopup();
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classNames(classes.impPopup, classes.minPopupDiv)}>
            <div>
              <h3>Delete Career Sketch</h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <p className={classes.successText}>Career Sketch deleted successfully</p>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkDefault, classes.impbuttonOkRedo)}>
              <button
                type="button"
                className={classes.hideButton}
                onClick={onClose}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>Close</button>
            </div>
          </div>
        ),
      });
    } else if (type === 'sketch-save') {
      closeSightPopup();
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classNames(classes.impPopup, classes.minPopupDiv)}>
            <div>
              <h3>Save Career Sketch</h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <p className={classes.successText}>Career Sketch saved successfully</p>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkDefault, classes.impbuttonOkRedo)}>
              <button
                type="button"
                className={classes.hideButton}
                onClick={onClose}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>Close</button>
            </div>
          </div>
        ),
        afterClose: () => {
          closeFullPopup();
        },
      });
    } else if (type === 'sketch-share') {
      setShareData(data);
      setOpenShare(true);
      setLoading(true);
      getContactList();
      getGroupList();
      setSelectType('Contacts');
      setShareErrorMessage('');
      setShareSuccessMessage('');
    }
  };

  const [clearScenario] = useMutation(CLEAR_SCENARIO, {
    onCompleted({
      removeScenario: {
        status,
      },
    }) {
      if (status === true) {
        setLoading(false);
        sightPathCallback('scenario-clear');
      }
    },
  });

  const clearScenarioData = () => {
    setLoading(true);
    clearScenario({
      variables: {
        module: sightData.module,
      },
    });
  };

  const displayData = (module, data) => {
    let htmlContent = '';
    switch (module) {
      case 'module1': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/fi">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? fiIcon : fiIconDisable} alt="fi" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Financial Independence</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.fiColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        disabled
                      />
                    </td>
                    <td>
                      {console.log('phrudhvi', moduleServices)}
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Income Growth:{moduleServices.module1Data.long_term_growth_in_percentage !== undefined && moduleServices.module1Data.long_term_growth_in_percentage !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module1Data.long_term_growth_in_percentage} prefix={moduleServices.module1Data.long_term_growth_in_percentage < 0 && '('} suffix={moduleServices.module1Data.long_term_growth_in_percentage >= 0 ? '%' : '%)'} /> : '0%'}
                            <br />
                            {' '}
                            Inflation: {moduleServices.module1Data.inflation_in_percentage !== undefined && moduleServices.module1Data.inflation_in_percentage !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module1Data.inflation_in_percentage} prefix={moduleServices.module1Data.inflation_in_percentage < 0 && '('} suffix={moduleServices.module1Data.inflation_in_percentage >= 0 ? '%' : '%)'} /> : '0%'}
                            {' '}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="Represents the Current Cash Balance."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module2': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/he">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? heIcon : heIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Higher Education</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.heColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name={module}
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Career School: {moduleServices.module2Data.name_of_college}
                            <br />
                            Tution: {moduleServices.module2Data.totalTuitionAndFees !== undefined && moduleServices.module2Data.totalTuitionAndFees !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module2Data.totalTuitionAndFees} prefix={moduleServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($'} suffix={moduleServices.module2Data.totalTuitionAndFees < 0 && ')'} /> : '0%'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Scholarships, Grants, Other Financial Aid, Income, Student Loans (net of Origination Fees), and Family Contributions less Tuition and Fees, Room and Board, Living expenses, and Student Loan payments."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module4': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/cp">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? cpIcon : cpIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Career Path</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.cpColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.module4Data.occupation}
                            <br />
                            {moduleServices.module4Data.city} | Income: {moduleServices.module4Data.totalPreTaxExpected_val !== undefined && moduleServices.module4Data.totalPreTaxExpected_val !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module4Data.totalPreTaxExpected_val} prefix={moduleServices.module4Data.totalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalPreTaxExpected_val < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Base Income and Supplementary Income less the sum of Living Expenses and Personal Retirement Contributions."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module5': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/ca">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? caIcon : caIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Career Advancement</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.caColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.module5Data.occupation}
                            <br />
                            {moduleServices.module5Data.city} | Income: {moduleServices.module5Data.totalPreTaxExpected_val !== undefined && moduleServices.module5Data.totalPreTaxExpected_val !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module5Data.totalPreTaxExpected_val} prefix={moduleServices.module5Data.totalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalPreTaxExpected_val < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Base Income and Supplementary Income less the sum of Living Expenses and Personal Retirement Contributions."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module6': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/mhe">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? mheIcon : mheIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>More Higher Education</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.mheColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Career School: {moduleServices.module6Data.name_of_college}
                            <br />
                            Tution: {moduleServices.module6Data.tuition !== undefined && moduleServices.module6Data.tuition !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module6Data.tuition} prefix={moduleServices.module6Data.tuition >= 0 ? '$' : '($'} suffix={moduleServices.module6Data.tuition < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Scholarships, Grants, Other Financial Aid, Income, Student Loans (net of Origination Fees), and Family Contributions less Tuition and Fees, Room and Board, Living expenses, and Student Loan payments."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module8': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/aca">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? acaIcon : acaIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Additional Career Advancement</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.acaColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.module8Data.occupation}
                            <br />
                            {moduleServices.module8Data.city} | Income: {moduleServices.module8Data.advanceTotalPreTaxExpected_val !== undefined && moduleServices.module8Data.advanceTotalPreTaxExpected_val !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module8Data.advanceTotalPreTaxExpected_val} prefix={moduleServices.module8Data.advanceTotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.advanceTotalPreTaxExpected_val < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Base Income and Supplementary Income less the sum of Living Expenses and Personal Retirement Contributions."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module9': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/investments">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? inIcon : inIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Investments</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.investmentColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Short-Term
                            <br />
                            Long-Term
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Financial Gifts less Charitable Contributions and Investment Contributions."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module10': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/family">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? faIcon : faIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Family</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.familyColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.module10Data.occupation}| Income: {moduleServices.module10Data.preTaxExpectedIncome !== undefined && moduleServices.module10Data.preTaxExpectedIncome !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.preTaxExpectedIncome} prefix={moduleServices.module10Data.preTaxExpectedIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.preTaxExpectedIncome < 0 && ')'} /> : '$0'}
                            <br />
                            # of Children : {moduleServices.module10Data.number_of_children}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of My Income and Spouse's Income less Children expenses, Pet expenses, and Living Expenses."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module11': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/vacation">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? vaIcon : vaIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Vacation + Special Purchases</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.vacationColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.module11Data.other_acquisition1}|{moduleServices.module11Data.other_acquisition2}
                            <br />
                            Total Amount: {moduleServices.module11Data.vacationTotalAmountSpent !== undefined && moduleServices.module11Data.vacationTotalAmountSpent !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module11Data.vacationTotalAmountSpent} prefix={moduleServices.module11Data.vacationTotalAmountSpent >= 0 ? '$' : '($'} suffix={moduleServices.module11Data.vacationTotalAmountSpent < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The costs of Vacation and Special Purchases."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module12': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/car">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? carIcon : carIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Buying a Car</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.carColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            {moduleServices.totalPurchasePrice() !== '' &&
                              <span>Car #1: {moduleServices.totalPurchasePrice() !== undefined && moduleServices.totalPurchasePrice() !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.totalPurchasePrice()} prefix={moduleServices.totalPurchasePrice() >= 0 ? '$' : '($'} suffix={moduleServices.totalPurchasePrice() < 0 && ')'} /> : '$0'}</span>
                            }
                            {moduleServices.moreTotalPurchasePrice() !== '' &&
                              <span>Car #1: {moduleServices.moreTotalPurchasePrice() !== undefined && moduleServices.moreTotalPurchasePrice() !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.moreTotalPurchasePrice()} prefix={moduleServices.moreTotalPurchasePrice() >= 0 ? '$' : '($'} suffix={moduleServices.moreTotalPurchasePrice() < 0 && ')'} /> : '$0'}</span>
                            }
                            {moduleServices.module12Data.leasing_car_start_year > 0 &&
                              <span>Lease #1</span>
                            }
                            {moduleServices.module12Data.leasing_second_car_start_year > 0 &&
                              <span>{'&'} Lease #2</span>
                            }
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Car #1 & Car #2 down payments, expenses, and loan payments, and Leasing expenses."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module13': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/home">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? homeIcon : homeIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Buying a Home</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.homeColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Purchase Price: {moduleServices.module13Data.purchase_price !== undefined && moduleServices.module13Data.purchase_price !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.purchase_price} prefix={moduleServices.module13Data.purchase_price >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.purchase_price < 0 && ')'} /> : '$0'}
                            <br />
                            Mortgage: {moduleServices.module13Data.Mortage !== undefined && moduleServices.module13Data.Mortage !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.Mortage} prefix={moduleServices.module13Data.Mortage >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.Mortage < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Home Buying Costs and property expenses."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module14': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/rei">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? riIcon : riIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Real Estate Investment Property</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.reiColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Purchase Price: {moduleServices.module14Data.purchase_price !== undefined && moduleServices.module14Data.purchase_price !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.purchase_price} prefix={moduleServices.module14Data.purchase_price >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.purchase_price < 0 && ')'} /> : '$0'}
                            <br />
                            Mortgage: {moduleServices.module14Data.Mortage !== undefined && moduleServices.module14Data.Mortage !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.Mortage} prefix={moduleServices.module14Data.Mortage >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.Mortage < 0 && ')'} /> : '$0'} | IRR:{moduleServices.module14Data.IRRCalVal !== undefined && moduleServices.module14Data.IRRCalVal !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.IRRCalVal} prefix={moduleServices.module14Data.IRRCalVal < 0 && '('} suffix={moduleServices.module14Data.IRRCalVal > 0 ? '%' : '%)'} /> : '0%'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="Rental Income less Real Estate Property Buying Costs and property expenses."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module15': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/fhe">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? fheIcon : fheIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Further Higher Education</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.fheColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Career School: {moduleServices.module15Data.name_of_college}
                            <br />
                            Tution: {moduleServices.module15Data.tuition !== undefined && moduleServices.module15Data.tuition !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module15Data.tuition} prefix={moduleServices.module15Data.tuition >= 0 ? '$' : '($'} suffix={moduleServices.module15Data.tuition < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Scholarships, Grants, Other Financial Aid, Income, Student Loans (net of Origination Fees), and Family Contributions less Tuition and Fees, Room and Board, Living expenses, and Student Loan payments."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module17': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/fca">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? fcIcon : fcIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Further Career Advancement</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.endYear !== '' && data.startYear > 0 && data.endYear > 0 ? (
                                <span>
                                  {data.startYear}
                                  -
                                  {data.endYear}
                                </span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.fcaColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        <span>
                          {moduleServices.module17Data.occupation}
                          <br />
                          {moduleServices.module17Data.city} | Income: {moduleServices.module17Data.jobtotalPreTaxExpected_val !== undefined && moduleServices.module17Data.jobtotalPreTaxExpected_val !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module17Data.jobtotalPreTaxExpected_val} prefix={moduleServices.module17Data.jobtotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.jobtotalPreTaxExpected_val < 0 && ')'} /> : '$0'}
                        </span>
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Base Income and Supplementary Income less the sum of Living Expenses and Personal Retirement Contributions."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      case 'module18': {
        htmlContent = (
          <tr>
            <td className={classes.leftModule}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.simPanel}>
                        <tbody>
                          <tr>
                            <td className={classes.moduleDiv}>
                              <Link to="/plan-with-inkwiry/existing">
                                <figure>
                                  <img className={classes.moduleImage} src={data.imageSlug === 'active' ? exIcon : exIconDisable} alt="cp" />
                                </figure>
                              </Link>
                              <div className={classes.moduleHead}>
                                <span>Existing Assets + Liabilities</span>
                              </div>
                            </td>
                            <td>
                              {data.startYear !== '' && data.startYear > 0 ? (
                                <span>{data.startYear}</span>
                              ) : (<span />)}
                            </td>
                            <td className={classes.smallFont}>
                              <small aria-hidden="true" onClick={() => { openSightPage(data.selectedModule, 'load', data.scenarioId, 'scenario'); }}>
                                {data.scenarioName !== undefined && data.scenarioName !== '' ? (<font>{data.scenarioName}</font>) : (<font>Select Scenario</font>)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={classes.simPanelLineCount}>
                        {printYearList(classes.existingColor, data)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className={classes.rightModule}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.moduleSelection}>
                      <Checkbox
                        name="shareCheck"
                        color="primary"
                        checked={shareScenario[module] === true}
                        onChange={() => updateCheckBox(module)}
                      />
                    </td>
                    <td>
                      <div className={classes.detailsGrowth}>
                        {data.startYear !== '' && data.startYear > 0 ? (
                          <span>
                            Assets: {moduleServices.module18Data.existing_car_current_value + moduleServices.module18Data.existig_home_current_value !== undefined && moduleServices.module18Data.existing_car_current_value + moduleServices.module18Data.existig_home_current_value !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module18Data.existing_car_current_value + moduleServices.module18Data.existig_home_current_value} prefix={moduleServices.module18Data.existing_car_current_value + moduleServices.module18Data.existig_home_current_value >= 0 ? '$' : '($'} suffix={moduleServices.module18Data.existing_car_current_value + moduleServices.module18Data.existig_home_current_value < 0 && ')'} /> : '$0'}
                            <br />
                            Liabilities: {liabilites !== undefined && liabilites !== '' ? <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={liabilites} prefix={liabilites >= 0 ? '$' : '($'} suffix={liabilites < 0 && ')'} /> : '$0'}
                          </span>
                        ) : (<span />)}
                      </div>
                      <div className={classes.detailsGrowthWrap}>
                        <div className={classes.infoFig} data-for="infoTooltip" data-tip="The sum of Existing Student Loan payments, Existing Credit Card payments, Existing Car expenses and loan payments, and Existing Home expenses and mortgage payments."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                        <p>CASH FLOW</p>
                        <span>$0</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
        break;
      }
      default:
        htmlContent = '';
        break;
    }
    return htmlContent;
  };

  const [clearTimeLine] = useLazyQuery(CLEAR_TIMELINE, {
    onCompleted() {
      setLoading(false);
      recallDataApi('clear-timeline');
    },
  });

  const clearSimulatorPanel = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classNames(classes.impPopup, classes.minPopupDiv)}>
          <div>
            <h3><b>Clear</b></h3>
            <div className={classNames(classes.impDiv, classes.noPadding)}>
              <div>
                <p>Are you sure you want to clear the Simulator Panel? This will reset all modules to no selection.</p>
              </div>
            </div>
          </div>
          <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
            <button
              type="button"
              onClick={() => { onClose(); setLoading(true); clearTimeLine(); }}
            >
              YES
            </button>
            <button type="button" onClick={onClose}>NO</button>
          </div>
        </div>
      ),
    });
  };

  useEffect(() => {
    if (selectType === 'Contacts') {
      if (userList.length > 0) {
        let selectedData = _.filter(selectedList, { key: 'Contacts' });
        selectedData = _.map(selectedData, 'id');
        let users = [...userList];
        if (selectedData.length > 0) {
          users = _.reject(users, (k) => selectedData.includes(k.id));
        }

        let sampleData = users.map((user) => {
          user.firstLetter = user.user_fname.charAt(0).toUpperCase();
          return user;
        });
        sampleData = _.mapValues(_.groupBy(sampleData, 'firstLetter'),
          (clist) => clist.map((car) => _.omit(car, 'firstLetter')));
        setSearchList(sampleData);
      } else {
        setSearchList([]);
      }
    } else if (selectType === 'Groups') {
      if (groupList.length > 0) {
        let selectedData = _.filter(selectedList, { key: 'Groups' });
        selectedData = _.map(selectedData, 'id');

        let groups = [...groupList];
        if (selectedData.length > 0) {
          groups = _.reject(groups, (k) => selectedData.includes(k.id));
        }

        let sampleData = groups.map((group) => {
          group.firstLetter = group.group_name.charAt(0).toUpperCase();
          return group;
        });
        sampleData = _.mapValues(_.groupBy(sampleData, 'firstLetter'),
          (clist) => clist.map((car) => _.omit(car, 'firstLetter')));
        setSearchList(sampleData);
      } else {
        setSearchList([]);
      }
    }
    // eslint-disable-next-line
  }, [selectType])

  const selectList = (key, index) => {
    const dummyList = { ...searchList };
    if (dummyList[key][index].status === undefined) {
      dummyList[key][index].status = true;
    } else {
      dummyList[key][index].status = !dummyList[key][index].status;
    }
    setSearchList(dummyList);
  };

  const addList = () => {
    let data = _.flatten(Object.values(searchList));
    data = data.map((user) => {
      user.key = selectType;
      return user;
    });
    data = _.filter(data, { status: true });
    setSelectedList([...selectedList, ...data]);
  };

  const removeList = () => {
    let dummyList = [...selectedList];
    dummyList = _.reject(dummyList, { status1: true });
    setSelectedList(dummyList);
  };

  useEffect(() => {
    if (selectType === 'Contacts') {
      if (userList.length > 0) {
        let selectedData = _.filter(selectedList, { key: 'Contacts' });
        selectedData = _.map(selectedData, 'id');
        let users = [...userList];

        users = _.reject(users, (k) => selectedData.includes(k.id));

        let sampleData = users.map((user) => {
          user.firstLetter = user.user_fname.charAt(0).toUpperCase();
          return user;
        });
        sampleData = _.mapValues(_.groupBy(sampleData, 'firstLetter'),
          (clist) => clist.map((car) => _.omit(car, 'firstLetter')));
        setSearchList(sampleData);
      }
    } else if (selectType === 'Groups') {
      if (groupList.length > 0) {
        let selectedData = _.filter(selectedList, { key: 'Groups' });
        selectedData = _.map(selectedData, 'id');

        let groups = [...groupList];

        groups = _.reject(groups, (k) => selectedData.includes(k.id));

        let sampleData = groups.map((group) => {
          group.firstLetter = group.group_name.charAt(0).toUpperCase();
          return group;
        });
        sampleData = _.mapValues(_.groupBy(sampleData, 'firstLetter'),
          (clist) => clist.map((car) => _.omit(car, 'firstLetter')));
        setSearchList(sampleData);
      }
    }
    // eslint-disable-next-line
  }, [selectedList])

  const unSelectList = (index) => {
    const dummyList = [...selectedList];
    if (dummyList[index].status1 === undefined) {
      dummyList[index].status1 = true;
    } else {
      dummyList[index].status1 = !dummyList[index].status1;
    }
    setSelectedList(dummyList);
  };

  const [shareScenarioApi] = useMutation(SHARE_SCENARIO, {
    onCompleted({
      ShareScenario: {
        status,
      },
    }) {
      if (status === true) {
        setLoading(false);
        setSelectedList([]);
        setShareSuccessMessage(shareData.type === 'sketch' ? 'Career sketch shared to user(s) successfully.' : 'Scenario shared to user(s) successfully.');
      }
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setShareErrorMessage(formatedErrors);
    },
  });

  const submitShareData = () => {
    if (selectedList.length > 0) {
      let groups = _.filter(selectedList, { key: 'Groups' });
      groups = _.map(groups, 'id');
      let contacts = _.filter(selectedList, { key: 'Contacts' });
      contacts = _.map(contacts, 'id');
      setShareErrorMessage('');

      setLoading(true);
      shareScenarioApi({
        variables: {
          data: {
            groups,
            contacts,
            type: shareData.type,
            ids: shareData.id,
          },
        },
      });
    } else {
      setShareErrorMessage('Please select groups/users to share.');
    }
  };

  const shareScenarioData = (data = '') => {
    let shareScenarioVal = {};
    if (data !== '') {
      shareScenarioVal = data;
    } else {
      shareScenarioVal = { ...shareScenario };
    }
    const scenarioids = [];
    for (let i = 1; i <= 10; i += 1) {
      if (shareScenarioVal[`module${i}`] !== undefined && shareScenarioVal[`module${i}`] === true) {
        const moduleIndex = _.findIndex(timeLineActions.timeline, { selectedModule: `module${i}` });
        if (moduleIndex >= 0 && timeLineActions.timeline[moduleIndex].scenarioId !== '' && parseInt(timeLineActions.timeline[moduleIndex].scenarioId, 10) > 0) {
          scenarioids.push(timeLineActions.timeline[moduleIndex].scenarioId);
        }
      }
    }

    if (scenarioids.length > 0) {
      setShareData({ type: 'scenario', id: scenarioids });
      setOpenShare(true);
      setLoading(true);
      getContactList();
      getGroupList();
      setSelectType('Contacts');
      setShareErrorMessage('');
      setShareSuccessMessage('');
    }
  };

  useEffect(() => {
    if (popupStatus !== popupType) {
      setPopupType(popupStatus);
      if (popupStatus === 'simulator') {
        setOpenSimulatorPanel(true);
        setShareScenario({ module1: true });
      } else if (popupStatus === 'sight-load-career') {
        openSightPage('', 'load', '', 'sketch');
      } else if (popupStatus === 'sight-save-career') {
        openSightPage('', 'save', '', 'sketch');
      } else if (popupStatus === 'sight-load-scenario') {
        openSightPage(otherData.module, 'load', '', 'scenario');
      } else if (popupStatus === 'share') {
        let shareScenarioDataVal = { ...shareScenario };
        shareScenarioDataVal['module1'] = true;
        if (otherData.module !== 'module1') {
          shareScenarioDataVal[otherData.module] = true;
        }
        setShareScenario(shareScenarioDataVal);
        shareScenarioData(shareScenarioDataVal);
      }
    }
    // eslint-disable-next-line
  }, [popupStatus, setPopupType])

  return (
    <Typography variant="body1" component="div" className={classes.simulatorPanel}>
      {loading && <Pageloader loading={loading} />}
      <Dialog
        open={openSimulatorPanel}
        onClose={closeSimulator}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classNames(classes.simulatorPopup, classes.spResponsive)}
      >
        <DialogTitle>
          <div className={classes.modalHeader}>
            <div className={classes.modalLogo}><img src={logo} alt="logo" /></div>
            <h4>Active Simulator</h4>
            <Button className={classes.simulatorClose} onClick={closeSimulator}>x</Button>
          </div>
          <table className={classes.simluatorBtns}>
            <tbody>
              <tr>
                <td>
                  <Button className={classes.simulatorButton} onClick={() => { openSightPage('', 'load', '', 'sketch'); }}>Load Sketch</Button>
                  <Button className={classes.simulatorButton} onClick={() => { openSightPage('', 'save', '', 'sketch'); }}>Save Sketch</Button>
                  <Button className={classes.simulatorButton} onClick={() => { openSightPage('', 'delete', '', 'sketch'); }}>Delete Sketch</Button>
                  <Button className={classes.simulatorButton} onClick={() => { openSightPage('', 'share', '', 'sketch'); }}>Share Sketch</Button>
                  <Button className={classes.simulatorButton} onClick={() => { shareScenarioData(shareScenario) }}>Share Scenario</Button>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={classes.simulatorSticky}>
            <tbody>
              <tr>
                <td className={classes.leftSimulator}>
                  <table>
                    <tr>
                      <td>Module</td>
                      <td>TIMELINE</td>
                      <td>
                        {timeLineActions.careerSketchName !== undefined && timeLineActions.careerSketchName !== '' ? timeLineActions.careerSketchName : 'ACTIVE SELECTION'}
                        &nbsp;
                        <span data-for="iconTooltip" data-tip="Click here  to see how a scenario name is built and what that scenario name means in the FC. ">
                          {' '}
                          <img className={classes.infoImage} src={infoIcon} alt="info" />
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td className={classes.rightSimulator}>
                  <table>
                    <tr>
                      <td className={classes.shareBlock}>
                        <span aria-hidden="true" onClick={selectAllScenarios} data-for="shareTooltip" data-tip="Click here to select (and unselect) all scenarios.">
                          <img className={classes.shareImage} src={shareIcon} alt="share" />
                        </span>
                      </td>
                      <td className={classes.detailsBlock}>
                        Details&nbsp;
                        <div data-for="detailTooltip" data-tip="Cash flow numbers below are before taxes and represent the net cash effect of that module. A positive number means that cash is coming in from the entirety of that life event and a negative number means cash is going out. This is a good benchmark to use in coordination with the details above as you change scenarios and build a Career Sketch."><img className={classes.infoImage} src={infoIcon} alt="share" /></div>
                      </td>

                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogTitle>
        <DialogContent>
          <div className={classes.simulatorModal}>
            <div className={classes.modalBody}>
              <ReactTooltip id="sharescenario" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.scenarioTooltip)} />
              <ReactTooltip id="iconTooltip" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.activeTooltip)} />
              <ReactTooltip id="detailTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoLeft, classes.detailTooltip)} />

              <ReactTooltip id="shareTooltip" place="right" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.shareTooltip)} />
              <div>
                <ReactTooltip id="infoTooltip" place="left" type="info" effect="solid" className={classes.tooltipInfoLeft} />
                <table className={classes.simulatorTable}>
                  <tbody>
                    {timeLineActions.timeline.map((data) => displayData(data.selectedModule, data))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.modalFooter}>
            <Button className={classNames(classes.clearButton, classes.footerButton)} onClick={clearSimulatorPanel}>Clear Simulator Panel</Button>
            <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={closeSimulator}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSightPopup}
        onClose={() => { closeSightPopup(); closeFullPopup(); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={false}
        className={classes.sightSavePopup}
      >
        <DialogTitle className={classes.sightHeader}>
          <button type="button" onClick={() => { closeSightPopup(); closeFullPopup(); }}>×</button>
        </DialogTitle>
        <DialogContent className={classes.sightSaveContent}>
          <Sight passData={sightData} sightPathCallback={sightPathCallback} />
        </DialogContent>
        <DialogActions className={classes.sightSaveFooter}>
          <div className={classes.modalFooter}>
            {sightData.forType !== 'undefined' && sightData.forType === 'scenario' && sightData.scenarioId !== '' && sightData.scenarioId !== '0' && sightData.scenarioId !== 0 && (
              <Button className={classNames(classes.clearButton, classes.footerButton)} onClick={clearScenarioData}>No Selection</Button>
            )}
            <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { closeSightPopup(); closeFullPopup(); }}>Close</Button>
          </div>
        </DialogActions>

      </Dialog>

      <Dialog
        open={openShare}
        onClose={closeSharePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.shareScenarioPopup}
      >
        <DialogTitle className={classes.shareTitle}>
          <h3>Share Scenario</h3>
          <button className={classNames(classes.simulatorClose, classes.simulatorBlack)} type="button" onClick={closeSharePopup}>×</button>
        </DialogTitle>
        <DialogContent className={classes.shareModal}>
          {shareErrorMessage !== '' && (<p className={classes.shareText}>{shareErrorMessage}</p>)}
          {shareSuccessMessage !== '' && (<p className={classes.shareText2}>{shareSuccessMessage}</p>)}
          <div className={classes.shareContent}>
            <Grid container spacing={0}>
              <Grid item sm={5} xs={12} className={classes.shareGrid}>
                <div className={classes.shareFirst}>
                  <p><b>Select users from My Contacts</b></p>
                  <select value={selectType} onChange={(e) => { setSelectType(e.target.value); }}>
                    <option value="Contacts">My Contacts</option>
                    <option value="Groups">My Groups</option>
                  </select>
                  <input placeholder={selectType === 'Groups' ? 'Search My Groups' : 'Search My Contacts'} />
                </div>
              </Grid>
              <Grid item sm={2} xs={12}>
                &nbsp;
              </Grid>
              <Grid item sm={5} xs={12} className={classes.shareGrid}>
                <Button className={classes.backToSimulator} onClick={closeSharePopup}>Back to Simulator Panel</Button>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={0}>
            <Grid item sm={5} xs={12} className={classes.shareGrid}>
              <ul className={classes.shareUnList}>
                {
                  Object.keys(searchList).map((value) => (
                    <li>
                      <span>{value}</span>
                      <ul>
                        {
                          searchList[value].map((userValue, index) => (
                            (selectType === 'Contacts' ? (
                              <li aria-hidden="true" className={classNames(userValue.status === true && classes.activeList)} onClick={() => selectList(value, index)}>
                                {userValue.user_fname}
                                {' '}
                                {userValue.user_lname}
                              </li>
                            ) : (
                              <li aria-hidden="true" className={classNames(userValue.status === true && classes.activeList)} onClick={() => selectList(value, index)}>{userValue.group_name}</li>
                            ))
                          ))
                        }
                      </ul>
                    </li>
                  ))
                }
              </ul>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.addRemoveBtn}>
              <Button onClick={addList}>Add &gt;&gt;</Button>
              <Button onClick={removeList}>&lt;&lt; Remove</Button>
            </Grid>
            <Grid item sm={5} xs={12} className={classNames(classes.shareGrid, classes.selectedUsers)}>
              <ul className={classes.shareUnList}>
                {selectedList.map((selectedValue, index1) => (
                  (selectedValue.group_name === undefined ? (
                    <li aria-hidden="true" className={classNames(selectedValue.status1 === true && classes.activeList)} onClick={() => unSelectList(index1)}>
                      {selectedValue.user_fname}
                      {' '}
                      {selectedValue.user_lname}
                    </li>
                  ) : (
                    <li aria-hidden="true" className={classNames(selectedValue.status1 === true && classes.activeList)} onClick={() => unSelectList(index1)}>{selectedValue.group_name}</li>
                  ))
                ))}
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.modalFooterNew}>
          <div>
            <Button className={classNames(classes.shareButton, classes.footerButton)} onClick={submitShareData}>SHARE</Button>
            <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={closeSharePopup}>ClOSE</Button>
          </div>
        </DialogActions>

      </Dialog>

    </Typography>
  );
};

SimulatorPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  otherData: PropTypes.object,
  popupStatus: PropTypes.string.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  recallDataApi: PropTypes.func.isRequired,
};
SimulatorPanel.defaultProps = {
  otherData: null,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(SimulatorPanel);
