import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles';
import WorkSheet from '../../../assets/img/pwi-fi/worksheet-icon.png';
import fixMenuImage from '../../../assets/img/pwi-fi/mega-menu-pricing_img.png';
import Simulator from '../../../assets/img/pwi-fi/simulator-engine-blue.png';
import TimeLine from '../../../assets/img/pwi-fi/timeline_icon.png';
import Dollar from '../../../assets/img/pwi-fi/dollar-icon.png';
import Dashboard from '../../../assets/img/pwi-fi/dashboard.png';
import HigherEducation from '../../../assets/img/pwi-fi/higher_education.png';
import Clear from '../../../assets/img/pwi-fi/clear-timeline.png';
import Load from '../../../assets/img/pwi-fi/load.png';
import Download from '../../../assets/img/pwi-fi/download.png';
import Save from '../../../assets/img/pwi-fi/save.png';
import DocumentsList from '../../../components/pwi/pwi-left-menu/documents-list';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Sidebar = ( props ) => {
  const {
    classes, menu, handleMenu, handleSideMenu, completedSlides, openBeforeLeavePopup,setOpenPopup, setOpenDocsPopup, clearScenario, saveScenario, sketchName, activeScenario, inputData
  } = props;

  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = React.useState( false );

  const usePathname = (event,url,moduleName) => {
    event.preventDefault();
    openBeforeLeavePopup(url,moduleName);
  }

  return (
    <div className={ menu ? classes.fixedMenuDisplay : classes.fixedMenu }>
      <div className={ classes.fixedMenuHeader }>
        <Link href="/plan-with-inkwiry/fi" onClick={(event)=>{usePathname(event,'/worksheet', 'Worksheet')}}>
          <img src={ WorkSheet } alt="" data-for="topTooltipTopIcon" data-tip="Switch to Worksheet view" />
        </Link>
        <i className="la la-close" aria-hidden="true" onClick={ ()=>{handleMenu(false)} } />
      </div>
      <div className={ classes.planWithInkwiry }>
        <div className={ classes.powerUpLogo }>
          <img src={ fixMenuImage } alt="" />
        </div>
        <h1>Plan With Inkwiry</h1>
      </div>
      <div className={ classes.powerUpCnt }>
        <h4>
          <figure>
            <img src={ HigherEducation } alt="" />
          </figure>
          Higher Education
        </h4>
        <ul className={ classes.homeMenuList }>
          <li className={ classes.activeMenu } aria-hidden="true" onClick={ () => handleSideMenu( 1, 'next' ) }>
              <span>01</span>
              <font>Choose Your Path</font>
              <span className={ classes.menuCheck }>
                {completedSlides[1] !== undefined && completedSlides[1] === true
                    && <CheckIcon fontSize="small" />}
              </span>
          </li>
          <li className={ `${classes.activeMenu} ${completedSlides[1] !== true && classes.disableActiveMenu}` } aria-hidden="true" onClick={ () => handleSideMenu( 2, 'next' ) }>
            <span>02</span>
            <font>Choose Your School</font>
            <span className={ classes.menuCheck }>
              {completedSlides[2] !== undefined && completedSlides[2] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          {( inputData && inputData.name_of_college !== undefined && inputData.name_of_college !== '' ) && ( <p>{ inputData.name_of_college}</p> )}
          <li className={ `${classes.activeMenu} ${completedSlides[2] !== true && classes.disableActiveMenu}` } aria-hidden="true" onClick={ () => handleSideMenu( 6,'next','side' ) }>
            <span>03</span>
            <font>First Year Tuition, Room and Board</font>
            <span className={ classes.menuCheck }>
              {completedSlides[3] !== undefined && completedSlides[3] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>

          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[3] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 7, 'next' ) }>
            <span>04</span>
            <font>Years to Graduation</font>
            <span className={ classes.menuCheck }>
              {completedSlides[4] !== undefined && completedSlides[4] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>

          <li aria-hidden="true"  className={ `${classes.activeMenu} ${completedSlides[4] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 8, 'next' ) }>
            <span>05</span>
            <font>Living Expenses</font>
            <span className={ classes.menuCheck }>
              {completedSlides[5] !== undefined && completedSlides[5] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true"  className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 9, 'next' ) }>
            <span>06</span>
            <font>Living Expenses Breakdown</font>
            <span className={ classes.menuCheck }>
              {completedSlides[6] !== undefined && completedSlides[6] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` }  onClick={ () => handleSideMenu( 10, 'next' ) }>
            <span>07</span>
            <font>Total Cost of Attendance</font>
            <span className={ classes.menuCheck }>
              {completedSlides[7] !== undefined && completedSlides[7] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>

          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 12, 'next' ) }>
            <span>08</span>
            <font>How to Navigate Financial Aid</font>
            <span className={ classes.menuCheck }>
              {completedSlides[8] !== undefined && completedSlides[8] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 15, 'next' ) }>
            <span>09</span>
            <font>Scholarships and Grants</font>
            <span className={ classes.menuCheck }>
              {completedSlides[9] !== undefined && completedSlides[9] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 16, 'next' ) }>
            <span>10</span>
            <font>Family Contribution and Savings</font>
            <span className={ classes.menuCheck }>
              {completedSlides[10] !== undefined && completedSlides[10] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 17, 'next' ) }>
            <span>11</span>
            <font>Earning Income</font>
            <span className={ classes.menuCheck }>
              {completedSlides[11] !== undefined && completedSlides[11] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 18, 'next' ) }>
            <span>12</span>
            <font>Federal Student Loans</font>
            <span className={ classes.menuCheck }>
              {completedSlides[12] !== undefined && completedSlides[12] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 21, 'next' ) }>
            <span>13</span>
            <font>Private Student Loans</font>
            <span className={ classes.menuCheck }>
              {completedSlides[13] !== undefined && completedSlides[13] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 24, 'next' ) }>
            <span>14</span>
            <font>Student Loan Repayment</font>
            <span className={ classes.menuCheck }>
              {completedSlides[14] !== undefined && completedSlides[14] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ `${classes.activeMenu} ${completedSlides[5] !== true && classes.disableActiveMenu}` } onClick={ () => handleSideMenu( 26, 'next' ) }>
            <span>15</span>
            <font>Summary</font>
            <span className={ classes.menuCheck }>
              {completedSlides[15] !== undefined && completedSlides[15] === true
                  && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li>
            <div>
              {activeScenario.id !== undefined && activeScenario.id !== '' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Scenario</span>
                  <span>{activeScenario.name}</span>
                </div>
              )}
              {sketchName !== '' && sketchName !== 'Active Selection' && (
                <div className={classes.scenarioNameBlock}> 
                  <span>Active Career Sketch</span>
                  <span>{sketchName}</span>
                </div>
              )}
            </div>
          </li>
        </ul>
        <div className={ classes.footerMenuFixed }>
          <ul>
            <li>
              <Link component="button" onClick={ () => { handleMenu(false);setOpenPopup( 'simulator' ); } } data-for="bottomTooltip" data-tip="Simulator Panel">
                <img src={Simulator} alt="" />
              </Link>
            </li>
            <li>
              <Link href="/timeline-sketcher" onClick={(event)=>{usePathname(event,'/timeline-sketcher', 'Career Sketch Timeline')}} data-for="bottomTooltip" data-tip="Go to Timeline">
                <img src={ TimeLine } alt="" />
              </Link>
            </li>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={()=>{setSummaryDocumentsOpen(false)}}
              >
                <li className={classes.documentsMenu} > 
                  <Link component="button" onClick={ () => { setSummaryDocumentsOpen( !summaryDocumentsOpen ); } } data-for="bottomTooltip" data-tip="Summary Documents">
                    <img src={Dollar} alt="" />
                  </Link>
                  {summaryDocumentsOpen && (
                    <DocumentsList hideMenu={handleMenu} setOpenDocsPopup={setOpenDocsPopup} setSummaryDocumentsOpen={setSummaryDocumentsOpen}/>
                  )}
                </li>
            </ClickAwayListener>
            <li>
              <Link href="/dashboard" onClick={(event)=>{usePathname(event,'/dashboard', 'Dashboard')}} data-for="bottomTooltip" data-tip="Go to Dashboard">
                <img src={ Dashboard } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={clearScenario} data-for="bottomTooltip" data-tip="Clear Inputs">
                <img src={ Clear } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={()=>openBeforeLeavePopup('','loadScenario','load')} data-for="bottomTooltip" data-tip="Load">
                <img src={ Load } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" data-for="bottomTooltip" data-tip="Download">
                <img src={ Download } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={saveScenario} data-for="bottomTooltip" data-tip="Save">
                <img src={ Save } alt="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <ReactTooltip id="topTooltipTopIcon" place="right" type="info" effect="solid" className={ classNames( classes.tooltipInfoTopNew, classes.worksheetToolTip ) } />
      <ReactTooltip id="topTooltip" place="right" type="info" effect="solid" className={ classes.tooltipInfoTop } />
      <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.moduleToolTip ) } />
    </div>

  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  menu: PropTypes.bool.isRequired,
  handleMenu: PropTypes.func.isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  completedSlides: PropTypes.object.isRequired,
  openBeforeLeavePopup: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenario: PropTypes.func.isRequired,
  sketchName: PropTypes.string.isRequired,
  activeScenario: PropTypes.object.isRequired,
};

export default withStyles( styles )( Sidebar );
