import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FilingYourTaxes = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Filing Your Taxes</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/filing-your-taxes.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Filing Your Taxes
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/tax-filing-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Every year you earn income, you need to file your
                          taxes. Tax Day in the United States is April 15th. If
                          that day is a national holiday or falls on a weekend,
                          Tax Day will most likely be the next business day. If
                          you don&apos;t file your taxes by this date, you might
                          get penalized and have to pay extra fees. Always plan
                          to file your taxes before April. That way, you avoid
                          unneeded fees and give yourself enough time for any
                          last-minute complications.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div className={classes.pfMargin}>
                          <div
                            className={classNames(
                              classes.pfImage,
                              classes.pfImageSpace,
                              classes.pfMobile,
                            )}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/filling-taxes.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <p>
                          The first thing you need to do is gather the tax
                          information you&apos;ll need to file:
                        </p>
                        <ul>
                          <li>
                            <b>Social security number</b>
                          </li>
                          <li>
                            <b>1040 form from previous year:</b> the
                            comprehensive income tax form that is divided into
                            sections and details every number in your income
                            taxes calculations. This form will be a great
                            starting point for filing this year&apos;s taxes.
                          </li>
                          <li>
                            <b>W-2 form:</b>
                            details exactly how much you earned in the past year
                            and how much you have already paid in taxes. Each
                            job you have requires a W-2 form.
                          </li>
                          <li>
                            <b>1099 forms:</b> a record that details payment to
                            you from another company or person that is not your
                            employer. For example, savings accounts,
                            investments, freelance work and more requires a 1099
                            form.
                          </li>
                          <li>
                            <b>Retirement account contributions:</b>{' '}
                            contributing to retirement accounts can lower your
                            taxes. You need to know how much you contributed to
                            file your taxes.
                          </li>
                          <li>
                            <b>Education expenses:</b> if you&apos;re paying for
                            higher education, you may qualify for tax benefits.
                            You&apos;ll need receipts of your expenses such as
                            tuition and fees.
                          </li>
                          <li>
                            <b>Property taxes and mortgage interest</b>
                          </li>
                          <li>
                            <b>Charitable donations</b>
                          </li>
                          <li>
                            <b>State and local taxes</b>
                          </li>
                        </ul>
                        <p>
                          After you gather your tax information, you have two
                          options for filing: do it yourself via online
                          resources or hire a tax professional to do it for you.
                          If you are comfortable with tax forms, confident in
                          your ability to use online software, and have the time
                          to devote to your taxes, you can save a little bit of
                          money by filing your taxes online. If you&apos;re not
                          comfortable or don&apos;t have the time, services of a
                          tax professional are relatively inexpensive and will
                          help make sure you file correctly.
                        </p>
                        <p>
                          According to the{' '}
                          <a
                            href="https://connect.nsacct.org/blogs/nsa-blogger/2017/01/27/national-society-of-accountants-reports-on-average-tax-return-preparation-fees"
                            target="_blank"
                            rel="noopener noreferrer">
                            National Society of Accountants in January 2017
                          </a>
                          , the average fee for a tax professional to file basic
                          federal and state returns is $176. More complicated
                          returns that include investments or real estate
                          property cost more. Online resources such as{' '}
                          <a
                            href="https://turbotax.intuit.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            TurboTax
                          </a>
                          ,{' '}
                          <a
                            href="https://www.hrblock.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            H&R Block
                          </a>
                          , and{' '}
                          <a
                            href="https://www.taxact.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            TaxAct
                          </a>{' '}
                          can cost anywhere from $0 to $100 or even more for
                          complicated returns.{' '}
                          <strong>
                            Most Americans opt for a tax professional to make
                            sure taxes are filed correctly and without hassle.
                          </strong>
                        </p>
                        <p>
                          Online software or your tax professional will let you
                          know anything else you might need to properly file
                          your taxes. Want more details on taxes?{' '}
                          <a
                            href="/external/images/news_pdfs/Inkwiry FC and Taxes_Sep 2019.pdf"
                            download
                            target="_blank"
                            rel="noopener noreferrer">
                            Click here
                          </a>{' '}
                          to download Inkwiry&apos;s 2019 tax document.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FilingYourTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilingYourTaxes);
