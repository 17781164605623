import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Button,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-career-path/styles';
import CareerBottomTabs from './career-bottom-tab-graphs';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import SideGraphDetails from './career-path-side-graph';

const arrayValues = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 500,
    scaledValue: 500,
    label: '$500',
  },
  {
    value: 1000,
    scaledValue: 1000,
    label: '$1k',
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: '$1.5k',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '$2k',
  },
  {
    value: 2500,
    scaledValue: 2500,
    label: '$2.5k',
  },
];

const HealthcareInsurance = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject, sideGraphData,
  } = props;
  const [healthCareCosts, setHealthCareCosts] = React.useState( 0 );
  const [healthInsurance, setHealthInsurance] = React.useState( 0 );

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.medical_costs_before_deductible !== undefined ) {
      setHealthCareCosts( handleCareerObject.medical_costs_before_deductible );
    } else {
     updatedValues['medical_costs_before_deductible'] = 0;
    }

    if ( handleCareerObject.health_insurance_premium !== undefined ) {
      setHealthInsurance( handleCareerObject.health_insurance_premium );
    } else {
     updatedValues['health_insurance_premium'] = 0;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'medical_costs_before_deductible': {
        setHealthCareCosts( newvalue );
        break;
      }
      case 'health_insurance_premium': {
        setHealthInsurance( newvalue );
        break;
      }
      default:
        break;
    }
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
    $( '#percentage1' ).css( 'height', `${sideGraphData.percentageValue}%` );
    $( '#percentage2' ).css( 'height', `${sideGraphData.percentageValue1}%` );
  };

  const handleBenchMarks = () => {
    const incomeSheetData = moduleServices.incomeStatementCompleteData();
    let incomeAfterTaxesValue = 0;
    if (incomeSheetData !== undefined && incomeSheetData !== '' && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== '' && incomeSheetData[handleCareerObject.start_year]['Income After Taxes'] !== undefined) {
      incomeAfterTaxesValue = incomeSheetData[handleCareerObject.start_year]['Income After Taxes'];
    } 
    setHealthInsurance( ((incomeAfterTaxesValue/12)/100)*4 );
    setHealthCareCosts(  ((incomeAfterTaxesValue/12)/100)*1 );
    handleCareerObject.medical_costs_before_deductible = ((incomeAfterTaxesValue/12)/100)*1;
    handleCareerObject.health_insurance_premium = ((incomeAfterTaxesValue/12)/100)*4;
  };

  return (
    <div className={ classes.contentMainBlock }>
      <ReactTooltip
        id="definationTitle"
        place="top"
        type="info"
        effect="solid"
        className={ classes.tooltipInfoTop }
      />
      <p>
        How much do you spend on healthcare costs other than your monthly premium? This could include over the counter medicines, co pays for routine doctor visits, or any other cost for your health. And, how much do you spend on your monthly health insurance premium?
      </p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          <font>FINANCE EXPLAINED TIP:</font>
          {' '}
          As a benchmark, healthcare and health insurance should be 5.0% or less of your income after taxes. If you're not sure what your expenses might be for this career move, click on 'Use Benchmark Values &gt;' to automate your budget.
        </p>
        <Button onClick={ handleBenchMarks } className={ classes.BahanceBtn }>USE BENCHMARK VALUE</Button>
      </div>
      <div className={ `${classes.livingExpences} ${classes.livingExpencesFlexEnd}` }>
        <div className={ classes.mainBlockRow }>
          <div className={ classes.mainBlock }>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                <h3 className={ classes.opacityZero }>Monthly</h3>
                <span
                  className={ classes.dottedText }
                  aria-hidden="true"
                  data-for="definationTitle"
                  data-tip="Click for a definition."
                  onClick={ () => handleDefinationPopup(
                    'Health Insurance Costs outside Premium',
                  ) }
                >
                  Healthcare Costs other than Premium
                </span>
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ arrayValues }
                    min={ 0 }
                    max={ 2500 }
                    step={ 10 }
                    value={ healthCareCosts }
                    valueLabelFormat={ `${commonFunctions.numFormatter( healthCareCosts )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'medical_costs_before_deductible', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Monthly</span></h3>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    decimalScale={0}
                    value={ handleCareerObject.medical_costs_before_deductible }
                    onValueChange={ ( e ) => updateValue( e, '', 'medical_costs_before_deductible', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Annual</span></h3>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ healthCareCosts * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                <span
                  className={ classes.dottedText }
                  aria-hidden="true"
                  data-for="definationTitle"
                  data-tip="Click for a definition."
                  onClick={ () => handleDefinationPopup(
                    'Health Insurance Premium',
                  ) }
                >
                  Health Insurance Premium
                </span>
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ arrayValues }
                    min={ 0 }
                    max={ 2500 }
                    step={ 10 }
                    value={ healthInsurance }
                    valueLabelFormat={ `${commonFunctions.numFormatter( healthInsurance )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'health_insurance_premium', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    value={ healthInsurance }
                    decimalScale={0}
                    onValueChange={ ( e ) => updateValue( e, '', 'health_insurance_premium', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ healthInsurance * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew} ${classes.rentPlusUntils}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>Healthcare Costs</p>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <b>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale  displayType="text" allowNegative={ false } thousandSeparator value={ healthCareCosts + healthInsurance } prefix={'$'} />
                  </b>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <b>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale  displayType="text" allowNegative={ false } thousandSeparator value={ (healthCareCosts + healthInsurance) * 12 } prefix={'$'} />
                  </b>
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockItalic}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>% of Income after Taxes</p>
              </div>
              <div className={ classes.livingExpencesMothly }>

                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( ( healthCareCosts + healthInsurance ) * 12 ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( healthCareCosts + healthInsurance ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>Total Living Expenses</p>
              </div>
              <div className={ classes.livingExpencesMothly }>

                <div className={ classes.annualInput }>
                <span>
                    <b>
                    {moduleServices.module4Data.livingExpensesCareerPath !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module4Data.livingExpensesCareerPath < 0 ? classes.textDanger : '' } allowNegative={ false } thousandSeparator value={ moduleServices.module4Data.livingExpensesCareerPath } prefix={ moduleServices.module4Data.livingExpensesCareerPath >= 0 ? '$' : '($' } suffix={ moduleServices.module4Data.livingExpensesCareerPath < 0 && ')' } />
                    ) : ( '$0' )}
                  
                  </b>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                <span>
                    <b>
                    {moduleServices.module4Data.careerPathLivingExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module4Data.careerPathLivingExpenses < 0 ? classes.textDanger : '' }  allowNegative={ false } thousandSeparator value={ moduleServices.module4Data.careerPathLivingExpenses * 12 } prefix={ moduleServices.module4Data.careerPathLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module4Data.careerPathLivingExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                    </b>
                  </span>
                </div>
              </div>
            </div>

          </div>

          <SideGraphDetails sideGraphData={ sideGraphData } />
        </div>
      </div>
      <div className={ classes.borderDevider } />
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />

    </div>
  );
};

HealthcareInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  sideGraphData: PropTypes.object.isRequired,

};

export default withStyles( styles )( HealthcareInsurance );
