import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import WalkThruDots from './walkThruDots';

const WalkThruOne = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, walkThruNo,

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruOne)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Get Started in the Financial Independence Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Financial Independence module. Want the confidence, the happiness, and the peace of mind that comes with knowing you have your financial life in order? Illuminate your path, create your road map, and build your plan for financial independence. In this module, you are taking the first step towards financial independence - entering your start year and making your first assumptions. You can build an unlimited number of career sketches in Inkwiry, but all sketches begin with entering your start year in the Financial Independence module. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Get Started in the Existing Assets & Liabilities Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Existing Assets & Liabilities module. The second step to building your career sketch after inputting your start year in the Financial Independence module is to record your existing assets and liabilities. This module is unique because it allows you to develop a plan to manage your existing assets such as a car or a home as well as strategizing on repayment options for your existing liabilities such as a student loan, credit card balance, car loan, or mortgage. To get started, click through these quick tips to help you navigate this module with confidence</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Get Started in the Career Path Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Career Path module. After setting your start year in the Financial Independence module and accounting for your existing assets and liabilities, enter your details for your current career move if you're already working or plan your next career move if you're a student. What career are you pursuing? Will that career financially support your life goals? Enter your income and expenses in the Career Path module and review your monthly net income, which includes how much you might pay in taxes. Plus, strategize to contribute a portion of your income for retirement. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Get Started in the Career Advancement Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Career Advancement module. Identifying the stepping stones in your career for the next 5, 10, 15 years is crucial to developing a complete financial life plan. What is your next career move? Explore Inkwiry’s resources in the Boost My Awareness section and discover the stepping stones in your career. Then, enter your income and expenses and review your monthly net income, which includes how much you might pay in taxes. Plus, continue contributing to retirement accounts. To get started, click through these quick tips to help you navigate this module with confidence</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Get Started in the Additional Career Advancement Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Additional Career Advancement module. Financial life planning is a lot like a navigation system. When you’re trying to get from point A to point B, you need to know what turns to make, how much time it will take to get there, and making sure you have enough gas. In 5, 10, 15 years, where do you want to be? What steps do you need to take to achieve your goals and dreams? Plan that next step in the Additional Career Advancement module. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Get Started in the Further Career Advancement Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Further Career Advancement module. What’s your next career move? How much will you be earning? What city will you be living in? How much will you have saved for retirement? What’s your 5, 10, 15 year net worth? Plan your next career move and discover how much you will be worth in 5, 10, 15 years. Not sure of your next career move? Leverage the resources in the Boost My Awareness section to discover your career path. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Get Started in the Investments Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Worksheets view of the Investments module. You can build an unlimited number of scenarios and develop many different investment strategies, learn essential financial terms to navigate this life event, transform your data with interactive visualizations and intelligent tables, compare scenarios side-by-side, and more. Build a short-term emergency fund and grow your long-term investments. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Get Started in the Real Estate Investment (REI) Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the REI module. Making an investment into real estate requires a really good strategy and sound financial modeling. Input the details of the property you are looking to buy and watch the FC go to work. The REI module will generate monthly and annual cash flows, return metrics, and more to help you efficiently analyze your real estate investments. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Get Started in the Worksheets view of the Higher Education Module</h4>
                    <p>Welcome to the Worksheets view of the Higher Education module. You can build an unlimited number of scenarios for higher education, learn essential financial terms to navigate this life event, transform your data with interactive visualizations and intelligent tables, compare scenarios side-by-side, and more. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>Get Started in the More Higher Education Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the More Higher Education module. In this module, you can build an unlimited number of scenarios for higher education, learn essential financial terms to navigate this life event, transform your data with interactive visualizations and intelligent tables, compare scenarios side-by-side, and more. Use this module when you have more than one higher education move in your next 5, 10, 15 years. For example, planning to get your bachelor's degree and master's degree? You’ll need both the higher education and more higher education modules to plan those moves. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Get Started in the Further Higher Education Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Further Higher Education module. In this module, you can build an unlimited number of scenarios for higher education, learn essential financial terms to navigate this life event, transform your data with interactive visualizations and intelligent tables, compare scenarios side-by-side, and more. Use this module when you have more than two higher education moves in your next 5, 10, 15 years. For example, planning to get your bachelor's degree, master's degree, and doctorate? You'll need the higher education, more higher education, and further higher education modules to plan those moves. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Get Started in the Family Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Worksheets view of the Family module. You can build an unlimited number of scenarios as you plan for your future family, learn essential financial terms to navigate this life event, allocate dollars in your plan to save for your future family now and later, compare scenarios side-by-side, and more. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                <div>
                    <h4>Get Started in the Vacation Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Worksheets view of the Vacation + Special Purchases module. You can build an unlimited number of scenarios for vacation and special purchases, learn essential financial terms to navigate this life event, allocate dollars in your plan for vacations and those things you've always wanted, compare scenarios side-by-side, and more. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Get Started in the Buying a Car Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Worksheets view of the Buying a Car module. When will you be able to buy the car you've always wanted? How much car can you afford? How much will car ownership cost on a monthly or annual basis? Leverage the Buying a Car module to discover answers to those questions and more. What's the difference in cost between buying and leasing? Strategize on car ownership whether you lease or you buy and see what works best for you. With a plan in hand, you'll be confident in your decision. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Get Started in the Buying a Home Module with an Inkwiry Walk-Thru</h4>
                    <p>Welcome to the Buying a Home module. When will you be able to buy the dream home you've always wanted? How much house can you afford? How much will home ownership cost on a monthly or annual basis? Leverage the Buying a Home module to input your data and ask your data questions. For example, what happens if your mortgage interest rate is 5.0% versus 4.5%? What if you put 25% down instead of 20%? Strategize on home buying and see what works best for you. With a plan in hand, you'll be confident in your decision. To get started, click through these quick tips to help you navigate this module with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(1); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Skip Walkthru</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruOne.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruOne);
