import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const SAVE_ACTIVITY = loader( '../../../graphql/schema/pf101/save-activity.graphql' );

const styles = ( ) => ( {
  continueButton: {
    background: '#0069aa',
    borderColor: '#0069aa',
    marginTop: '10px',
    marginLeft: '0px',
    padding: '12px 22px',
    marginBottom: '15px',
    fontFamily: '"MuseoSans-100"',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    border: '2px solid #f89b22',
    lineHeight: 'inherit',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      border: '2px solid #d5d5d5',
      color: '#fff',
      background: '#0069aa',
    },
    '&:disabled': {
      cursor: 'no-drop',
      pointerEvents: 'all',
      background: '#d5d5d5',
      borderColor: '#d5d5d5',
      color: '#333333',
      opacity: 0.65,
      '&:hover': {
        background: '#d5d5d5',
        borderColor: '#d5d5d5',
      },
    },
  },
  reviewButton: {
    marginBottom: '50px',
  },
} );

const ContinueButton = ( props ) => {
  const {
    classes, history, revisit, url, level, exercise, startTime,
  } = props;
  const [loading, setLoading] = React.useState( false );

  const [saveActivity] = useMutation( SAVE_ACTIVITY, {
    onCompleted( ) {
      // setLoading( false );
      history.push( `${url}` );
    },
    onError( ) {
      history.push( `${url}` );
      return false;
    },
  } );

  const goToActivity = () => {
    console.log('revisit',revisit)
    if(revisit === false){
      history.push( `${url}` );
    }else{
      setLoading( true );

      let totalSeconds = 0;
      const endTime = new Date();
      const res = Math.abs( endTime - startTime ) / 1000;
      // Days Calculation
      const days = Math.floor( res / 86400 );
      // Hours calculation
      const hours = Math.floor( res / 3600 ) % 24;
      // Minutes calculation
      const minutes = Math.floor( res / 60 ) % 60;
      // Seconds calculation
      const seconds = res % 60;
  
      totalSeconds = ( days * 86400 ) + ( hours * 3600 ) + ( minutes * 60 ) + seconds;
      // if(totalSeconds > )
  
      saveActivity( {
        variables: {
          data: {
            level,
            exercise,
            activity_time: totalSeconds,
          },
        },
      } );
    }   
  };

  return (
    <div>
      { loading && <Pageloader loading={ loading } /> }
      {revisit !== undefined && revisit === 'yes' ? (
        <Button className={ classNames( classes.continueButton, classes.reviewButton ) } onClick={ () => { history.push( `${url}?revisit=yes` ); } }>Review Activity</Button>
      ) : (
        <Button className={ classNames( classes.continueButton, classes.reviewButton ) } onClick={ goToActivity }>Continue</Button>
      )}
    </div>
  );
};

ContinueButton.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  revisit: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  exercise: PropTypes.number.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( ContinueButton );
