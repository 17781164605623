import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';

import styles from './styles';

const TypesofIncome = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>3 Types of Income</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/3-types-of-income.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>3 Types of Income
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/types-income-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          There are three different ways to earn income in life:
                          earned, portfolio, and passive income. The key
                          difference between these three types is what produces
                          the income.
                        </p>
                        <p className={classes.smallText}>
                          <b>Earned income</b> is money derived from spending
                          your time working. Examples are jobs that pay salary
                          and wages, bonuses, tips and commission. Your time is
                          what produces earned income. Earned income is taxed at
                          the highest rate (~ 40%).
                        </p>
                        <p className={classes.smallText}>
                          <b>Portfolio income</b> is money you receive from
                          selling an investment such as a stock, bond, or other
                          fund for more than you bought it for. For example, if
                          you bought Apple stock at $100 and sold for $150, the
                          $50 gain would be portfolio income. Your initial
                          investment of money is what produces portfolio income.
                          Portfolio income is usually taxed at a lower rate than
                          earned income, which means you keep more of the money
                          you make.
                        </p>
                        <p className={classes.smallText}>
                          <b>Passive income</b> is money generated from assets
                          you own that you don&apos;t actively work on. For
                          example, if you own real estate and receive rent
                          payments each month, the rent would be passive income.
                          The asset that you own (not your time or selling the
                          asset) is what produces passive income. Passive income
                          is taxed at the lowest rate of the three.
                        </p>
                        <p>
                          The benefit of portfolio and passive income is that
                          neither types of income require your time! Earned
                          income always requires your time and is limited
                          because there are only so many hours in the day.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
TypesofIncome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TypesofIncome);
