import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-career-path/styles';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import * as modulesServices from '../../calculations/modules-services';

const SettingTimelines = (props) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;

  const currentYear = (modulesServices.module1Data.start_year !== undefined && modulesServices.module1Data.start_year > 0) ? modulesServices.module1Data.start_year : new Date().getFullYear();
  const yearOption = [];
  const numbers = [];
  for (let i = 1; i <= 15; i += 1) {
    numbers.push(i.toString());
  }
  const [numOfYears, SetNumOfYears] = React.useState(numbers);
  const [careerStartYear, setCareerStartYear] = React.useState(new Date().getFullYear());
  const [careerTotalYears, setCareerTotalYears] = React.useState(2);
  const [careerEndYear, setCareerEndYear] = React.useState(0);

  // const intialYear = currentYear;
  const yearsList = [];
  for (let i = 0; i <= 14; i += 1) {
    yearsList.push(currentYear + i);
  }
  for (let year = currentYear; year < (currentYear + 14); year += 1) {
    if (yearOption.length === 0) {
      yearOption.push(0);
    } else {
      yearOption.push(year);
    }
  }

  useEffect(() => {
    const updatedObj = { ...handleCareerObject };
    if ((updatedObj.start_year !== undefined && updatedObj.start_year > 0 && updatedObj.start_year !== '') || (updatedObj.total_career_years !== undefined && updatedObj.total_career_years > 0 && updatedObj.total_career_years !== '')) {
      setCareerStartYear(Number(updatedObj.start_year));
      setCareerTotalYears(Number(updatedObj.total_career_years));
      setCareerEndYear(Number(updatedObj.end_year));
    } else if (modulesServices.module2Data !== undefined && modulesServices.module2Data !== ''
      && modulesServices.module2Data.start_year !== undefined && modulesServices.module2Data.start_year !== '' && modulesServices.module2Data.start_year > 0) {
      updatedObj.start_year = modulesServices.module2Data.start_year + 1;
      setCareerStartYear(Number(modulesServices.module2Data.start_year + 1));
      updatedObj.total_career_years = 2;
      setCareerTotalYears(2);
    } else if (modulesServices.module1Data.start_year !== undefined && modulesServices.module1Data.start_year > 0) {
      updatedObj.start_year = modulesServices.module1Data.start_year;
      updatedObj.total_career_years = 2;
      setCareerStartYear(Number(modulesServices.module1Data.start_year));
      setCareerTotalYears(2);
    } else {
      updatedObj.start_year = new Date().getFullYear();
    }
    if (updatedObj.total_career_years === undefined || updatedObj.total_career_years >= 0) {
      updatedObj.total_career_years = 2;
    }
    else {
      setCareerTotalYears(Number((updatedObj.end_year - updatedObj.start_year) + 1));
    }
    if (updatedObj.end_year === undefined) {
      updatedObj.end_year = updatedObj.start_year + (updatedObj.total_career_years - 1);
      setCareerEndYear(updatedObj.start_year + (updatedObj.total_career_years - 1));
    } else {
      updatedObj.end_year = Number(updatedObj.end_year) > 0 ? Number(updatedObj.end_year) : updatedObj.start_year + (updatedObj.total_career_years - 1);
      setCareerEndYear(Number(updatedObj.end_year) > 0 ? Number(updatedObj.end_year) : updatedObj.start_year + (updatedObj.total_career_years - 1));
      setCareerTotalYears(Number((updatedObj.end_year - updatedObj.start_year) + 1));
    }
    handleUpdatedObject(updatedObj);
    // eslint-disable-next-line
  }, []);

  const handleNumberOfYears = (event, data) => {
    const updatedObj = { ...handleCareerObject };
    if (data === 'bottom') {
      updatedObj.total_career_years = event.target.value;
      setCareerTotalYears(event.target.value);
      const numOfYear = [];
      const mainEndYear = currentYear + 14;
      const cpStartYear = handleCareerObject.start_year;
      const diff = (mainEndYear - parseInt(cpStartYear, 10)) + 1;
      for (let i = 1; i <= diff; i += 1) {
        numOfYear.push(i.toString());
      }
      SetNumOfYears(numOfYear);
      setCareerEndYear(parseInt(updatedObj.start_year) + parseInt(event.target.value) - 1);
      updatedObj.end_year = parseInt(updatedObj.start_year) + parseInt(event.target.value) - 1;
    }
    if (data === 'top') {
      updatedObj.start_year = event.target.value;
      setCareerStartYear(event.target.value);
      const numOfYear = [];
      const mainEndYear = currentYear + 14;
      const diff = (mainEndYear - parseInt(event.target.value)) + 1;
      for (let i = 1; i <= diff; i += 1) {
        numOfYear.push(i.toString());
      }
      SetNumOfYears(numOfYear);
      const indexValue = numOfYear.indexOf(careerTotalYears);
      if (indexValue === -1) {
        updatedObj.total_career_years =  parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears;
        setCareerTotalYears(parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears);        
      }
        setCareerEndYear(parseInt(event.target.value) + parseInt(parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears) > mainEndYear ? mainEndYear : parseInt(event.target.value) + (parseInt(parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears-1)));
        updatedObj.end_year = parseInt(event.target.value) + parseInt(parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears) > mainEndYear ? mainEndYear : parseInt(parseInt(event.target.value)+(parseInt(parseInt(event.target.value)+careerTotalYears > mainEndYear  ? diff : careerTotalYears-1)));
      
    }
    // const numOfYear = [];
    // const mainEndYear = currentYear + 14;
    // const cpStartYear = data === 'top' ? parseInt(event.target.value, 10) : handleCareerObject.start_year;
    // const diff = (mainEndYear - parseInt(updatedObj.start_year)) + 1;
    // for (let i = 1; i <= diff; i += 1) {
    //   numOfYear.push(i.toString());
    // }
    // SetNumOfYears(numOfYear);
    // const indexValue = numOfYear.indexOf(handleCareerObject.total_career_years);
    // if (indexValue === -1) {
    //   updatedObj.total_career_years = parseInt(numOfYear[numOfYears.length - 1]);
    //   setCareerTotalYears(parseInt(numOfYear[numOfYears.length - 1]));
    // }

    // setCareerEndYear(parseInt(updatedObj.start_year, 10) + parseInt(updatedObj.total_career_years) - 1);
    // updatedObj.end_year = parseInt(updatedObj.start_year, 10) + parseInt(updatedObj.total_career_years) - 1;
    handleUpdatedObject(updatedObj);
  };


  // const handleNumberOfYears = (event, data, field) => {
  //   const updatedObj = { ...handleCareerObject };
  //   const value = Number(event.target.value);   
  //     if (field === 'start_year') {
  //       updatedObj[field] = value
  //       updatedObj.end_year = (careerTotalYears-1)+value;
  //       setCareerStartYear(value);
  //       setCareerEndYear((careerTotalYears-1)+value)
  //     }
  //     if(field === 'total_career_years'){
  //       updatedObj[field] = value;
  //       updatedObj.end_year = careerStartYear+(value-1);
  //       setCareerTotalYears(value);
  //       setCareerEndYear(careerStartYear+(value-1))
  //     }    
  //   handleUpdatedObject(updatedObj);
  // };

  return (
    <div className={classes.contentBlock}>
      {handleCareerObject.role === 'currentRole' && (
        <div>
          <p>Building a plan and running your data for your career path starts with setting timelines.</p>
          <p>How long do you expect to work at your existing role?</p>
        </div>
      )}
      {handleCareerObject.role !== 'currentRole' && (
        <div>
          <p>Building a plan and running your data for your career path starts with setting timelines.</p>
          <p>When do you plan on starting this career move? How long do you expect to work in this position?</p>
        </div>
      )}

      <div className={`${classes.grayBox} ${classes.grayBoxHigher} ${classes.grayBoxHigherHalf}`}>
        <ul>
          <li>
            {handleCareerObject.role !== 'currentRole' && (
              <span>I plan to start this career move in year&nbsp;</span>
            )}
            {handleCareerObject.role === 'currentRole' && (
              <span>My plan for my existing career path starts in year&nbsp;</span>
            )}
            {' '}
            <select value={careerStartYear} onChange={(e) => { handleNumberOfYears(e, 'top', 'start_year'); }}>
              {yearsList.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>

          </li>
          <li>
            <span>I plan to be in this career move for&nbsp; </span>
            {' '}
            <select value={careerTotalYears} onChange={(e) => { handleNumberOfYears(e, 'bottom', 'total_career_years'); }}>
              {numOfYears.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>
            <span>&nbsp;more years.</span>
          </li>
          <li>
            <span>
              The end year for this career move is
              {' '}
              <span>{careerEndYear}</span>
              .&nbsp;
            </span>
          </li>

        </ul>
      </div>
      <CareerSketchTimeline />

    </div>

  );
};

SettingTimelines.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles(styles)(SettingTimelines);
