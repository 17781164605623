import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import circleArrow from '../../../assets/img/career-path/circle-arrow.png';

const FirstYearTuitionRoomBoard = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup
  } = props;

  const [inputs, setInputs] = React.useState({});
  const [defaultValues, setDefaultValues] = React.useState({});

  useEffect(()=>{
    let inputValues = {...inputs};
    if(inputData.tuition !== undefined) {
      inputValues.tuition = inputData.tuition;  
    } else {
      inputValues.tuition = 0;
    }
    if(inputData.room_board !== undefined) {
      inputValues.room_board = inputData.room_board;  
    } else {
      inputValues.room_board = 0;
    }
    setInputs(inputValues);
    setDefaultValues(inputValues);
  },[])

  const updateValue = ( e, field ) => {
    const inputValues = { ...inputs };
    const newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    setInputs( inputValues );
    updateData( field, newvalue );
  };

  const resetInputs = () => {
    setInputs( defaultValues );
    let allinputData = {...inputData};
    allinputData['tuition'] = defaultValues.tuition;
    allinputData['room_board'] = defaultValues.room_board;
  }

  const total = parseInt( inputData.tuition !== undefined ? inputData.tuition : 0, 10 ) + parseInt( inputData.room_board !== undefined ? inputData.room_board : 0, 10 );

  return (
    <div className={ classes.contentBlock }>
      {/*College or Military*/}
      {
        (inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4) && (
          <p>Based on your school selection, we&apos;ve calculated your first year tuition and fees, plus room and board. Double check the figures below and make any changes needed before continuing.</p>)
      }
      {/*Career School*/}
      {
        (inputData.type_of_higher_education === 1) && (<p> Based on your school selection, we&apos;ve calculated your first year tuition and fees. Double check the figures below and make any changes needed before continuing.</p>)
      }
      {/*Military School*/}
      {
        (inputData.type_of_higher_education === 3) && (<p>Based on your school selection, we&apos;ve calculated your first year tuition and fees, plus room and board. Double check the figures below and make any changes needed before continuing.</p>)
      }
      <p className={ classes.moreGap }>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          <br />
          Location of Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.location !== undefined && inputData.school_details.location}
        </i>
      </p>

      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ classes.higherEducationStepFour }>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className={ classes.trHead }>
                <td>
                  {(inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4) ? 'First Year Tuition & Fees + Room & Board' : 'First Year Tuition & Fees' }
                </td>
                <td className={ classes.resetIcon }>
                  {((defaultValues.tuition !== inputs.tuition) || (defaultValues.room_board !== inputs.room_board)) && (
                    <span>
                      <img src={ circleArrow } alt=""  aria-hidden="true" onClick={resetInputs} data-for="resetTitle" data-tip="Reset to default values" />
                      <ReactTooltip id="resetTitle" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td><p aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Tuition and Fees' ) }>Tuition & Fees</p></td>
                <td>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.tuition !== undefined ? inputs.tuition : 0 } onValueChange={ (e)=>{updateValue(e,'tuition')} } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              {
                (inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4) && (
              <tr>
                <td>
                  <ReactTooltip id="definationTitle1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                  <p aria-hidden="true" data-for="definationTitle1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Room and Board' ) }>Room & Board</p></td>
                <td>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.room_board !== undefined ? inputs.room_board : 0 } onValueChange={ (e)=>{updateValue(e,'room_board')} } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              )}
              <tr className={ classes.trFoot }>
                <td>

                  {(inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4) ? ('First Year Tuition & Fees + Room & Board'):('First Year Tuition & Fees')}
                </td>
                <td>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } value={ total } displayType="text" thousandSeparator prefix="$" />
                    </span>

                  </div>
                </td>
              </tr>

            </tbody>
          </table>
          <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
        </div>
      </div>
      
    </div>
  );
};

FirstYearTuitionRoomBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( FirstYearTuitionRoomBoard );
