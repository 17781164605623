import shadowImage from '../../assets/img/mike-story/shadow_img_mike.png';
import arrowImage from '../../assets/img/mike-story/career_page_right_arrow.png';
import listArrow from '../../assets/img/mike-story/tick_mark_blue.png';
import selectDrop from '../../assets/img/mike-story/select_drop.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  testimonialInner: {
    '& img': {
      maxWidth: '100%',
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
  },
  sliderStory: {
    height: '100vh',
    maxWidth: '86vw',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      height: 'auto',
      margin: '50px auto',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      right: '0',
      height: '100%',
      background: '#fff',
      zIndex: '9',
    },
    '& div': {
      outline: 'none',
      border: 'none',
      height: '100%',
      [theme.breakpoints.down( 'xs' )]: {
       display: 'block',
      },
      '& > button': {
        backgroundImage: `url(${arrowImage})`,
        width: '60px',
        height: '60px',
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: 'contain !important',
        '&::before': {
          display: 'none',
        },
        '&:hover': {
          backgroundImage: `url(${arrowImage})`,
        },
        '&:focus': {
          backgroundImage: `url(${arrowImage})`,
        },
        '&:first-child': {
          transform: 'rotate(180deg)',
          transformOrigin: 'top',
          left: '-90px',
          marinTop: '-15px',
          [theme.breakpoints.down( 'xs' )]: {
            left: '0px',
            zIndex: '9',
          },   
          '@media screen and (min-width: 600px) and (max-width: 1024px)': {
            left: '-60px',
          },       
        },
      },
      '& > button + div + button': {
        right: '-90px',
        [theme.breakpoints.down( 'md' )]: {
          right: '0px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          marginTop: '-25px',
        },
        '@media screen and (min-width: 600px) and (max-width: 1024px)': {
          marginTop: '-25px',
          right: '-60px',
        },   
      },
    },
    '& button + ul': {
      bottom: '35px',
      width: '50%',
      margin: '0 0 0 auto',
      left: 'inherit',
      right: '0',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',       
       },
    },
    '& button': {
      display: 'block',

      '&::before': {
        display: 'none',
      },
    },
    '& li': {
      margin: '0',
    },
    '& li button': {
      display: 'block !important',
      borderRadius: '50%',
      background: '#0069aa',
      width: '1.5vh',
      height: '1.5vh',
      border: '2px solid #0069aa ',
    },
  },
  InnerStoryLEft: {
    bottom: '0',
    padding: '2vw 3vw 3.6vw',
    left: '0',
    width: '100%',
    zIndex: '2',
    position: 'absolute',
    color: '#fff',
    boxSizing: 'border-box',
    height: 'auto !important',
    backgroundImage: `url(${shadowImage})`,
    fontFamily: 'MuseoSans-100',
    '& h2': {
      fontSize: '2.81vw',
      lineHeight: '2.81vw',
      padding: '0 0 1vw 0',
      fontWeight: 'normal',
      margin: '0',
    },
    '& p': {
      fontSize: '2.11vw',
      lineHeight: '2.81vw',
      padding: '2vw 0 5vw',
      margin: '0',
    },
  },
  leftBlock: {
    position: 'relative',
    height: 'calc(100vh - 6px)',
  },
  bottomSpanTextLeft: {
    position: 'absolute',
    bottom: '0',
    background: '#a5a5a5',
    width: '100%',
    zIndex: '999',
    color: '#ffffff',
    padding: '0.8vw 1.7vw 0.8vw 3vw',
    fontSize: '1.1vw',
    lineHeight: '1.24vw',
    fontFamily: "'MuseoSans-100'",
    boxSizing: 'border-box',
    height: 'auto !important',
  },
  gridContainer: {
    display: 'flex',
    '& > div': {
      paddingRight: '0',
    },
  },
  imageBlock: {
    padding: '7vw 0 3vw',
    '& img': {
      width: '22%',
      height: 'auto',
      margin: '0 auto',
    },
  },
  rightBlockNew: {
    height: 'auto !important',
    textAlign: 'center',
    '& h3': {
      fontSize: '2.11vw',
      lineHeight: '4.2vw',
      padding: '0 0 2.5vw',
      fontFamily: "'MuseoSans-300'",
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
    },
    '& p': {
      maxWidth: '65vh',
      fontSize: '1.6vw',
      color: '#333',
      lineHeight: '2.31vw',
      fontFamily: "'MuseoSans-100'",
      margin: '0 auto',
      padding: '0',
    },
  },
  rightBlock: {
    position: 'relative',
    '& div': {
      height: 'auto',
    },
  },
  blueBox: {
    '& div': {
      height: 'auto',
    },
    position: 'static',
    background: '#0069aa',
    overflow: 'auto',
    padding: '3.6vw 2.1vw 0vw 6vw',
    color: '#fff',
    boxSizing: 'border-box',
    '& h3': {
      fontSize: '2.3vw',
      lineHeight: '2.65vW',
      padding: '0 0 0.5vw',
      margin: '0',
      fontWeight: 'normal',
    },
    '& h4': {
      padding: '0 0 2.8vw',
      fontSize: '1.75vw',
      lineHeight: '1.823vw',
      fontWeight: 'normal',
      margin: '0',
    },
    '& p': {
      margin: '0',
    },
  },
  careerSave: {
    '& > p': {
      fontSize: '1.75vw',
      padding: '0 0 1vw',
      lineHeight: '1.823vw',
      '& span': {
        fontFamily: "'MuseoSans-500'",
        marginRight: '0.4vw',
      },
    },
    '& h3': {
      fontSize: '2.3vw',
      lineHeight: '2.65vW',
      padding: '0 0 0.5vw',
      fontWeight: 'normal',
      marginTop: '3vw',
      fontFamily: "'MuseoSans-100'",
    },
    '& ul': {
      padding: '0',
      margin: '0',
      '& li': {
        fontSize: '3vw',
        padding: '0 0 0.5vw 1.6vw',
        textIndent: '-1.6vw',
        display: 'inline-block',
        width: '100%',
        '& p': {
          fontSize: '1.1vw',
          lineHeight: '1.45vw',
          paddingBottom: '0.2vw',
          fontFamily: "'MuseoSans-100'",
        },
      },
    },
  },
  rightFullContent: {
    padding: '0 5vw 1vw 4vw',
    overflow: 'auto',
    position: 'relative',
    '& div': {
      height: 'auto',
    },

  },
  rightFullContentTop: {
    padding: '3.6vw 0 1vw',
    '& h3': {
      padding: '0 0 0.7vw',
      fontFamily: "'MuseoSans-300'",
      fontSize: '1.62vw',
      lineHeight: '2.5vw',
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
    },
    '& p': {
      maxWidth: '80vh',
      fontSize: '1.15vw',
      lineHeight: '1.6vw',
      margin: '0 auto',
      padding: '0',
      fontFamily: "'MuseoSans-100'",
      color: '#333',
    },
  },
  bottomTextRight: {
    '& h3': {
      fontSize: '1.45vw',
      fontFamily: "'MuseoSans-300'",
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
      marginTop: '0.2vw',
    },
    '& p': {
      margin: '0 0 10px',
      fontSize: '1.1vw',
      lineHeight: '1.55vw',
      color: '#0069aa',
      fontFamily: "'MuseoSans-100'",
    },
  },
  bottomTextRightLast: {
    padding: '0vh 0 3vw 0',
    '& h3': {
      fontSize: '1.45vw',

      fontFamily: "'MuseoSans-300'",
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
    },
    '& p': {
      margin: '0 0 10px',
      fontSize: '1.1vw',
      lineHeight: '1.55vw',
      color: '#0069aa',
      fontFamily: "'MuseoSans-100'",
    },
  },
  iconBlock: {
    textAlign: 'center',
    '& figure': {
      width: '4.5vw',
      transition: 'all .5s ease 0s',
      MozTransition: 'all .5s ease 0s',
      WebkitTransition: 'all .5s ease 0s',
      display: 'inline-block',
      margin: '0 2px',
      '& img': {
        cursor: 'pointer',
      },
      '&:hover': {
        transform: 'scale(1.06)',
      },
    },
  },
  closeIcon: {
    top: '3.3vw',
    right: '3.4vw',
    fontSize: '1.5vw',
    lineHeight: '1.6vw',
    position: 'absolute',
    cursor: 'pointer',
  },
  paddingBottomPara: {
    padding: '0 0 1.5vh 0 !important',
  },
  blueBoxLeftBorder: {

    '& div': {
      height: 'auto',
    },
    position: 'relative',
    background: '#fff',
    overflow: 'auto',
    padding: '3.6vw 2.1vw 0vw 6vw',
    boxSizing: 'border-box',
    borderLeft: '15vh solid #0069aa !important',
    '& h3': {
      fontSize: '2.5vw',
      lineHeight: '3vw',
      textAlign: 'left',
      fontFamily: "'MuseoSans-100'",
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        maxWidth: '25px',
        marginLeft: '5px',
      },
    },
    '& ul': {
      padding: '0',
      maxWidth: '450px',
      '& li': {
        fontFamily: 'MuseoSans-100',
        listStyle: 'none',
        backgroundImage: `url(${listArrow})`,
        padding: '0 0 0 20px',
        backgroundSize: '8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: '#0069aa',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top 6px left',

      },
    },
    '& h4': {
      padding: '0 0 2.8vw',
      fontSize: '1.75vw',
      lineHeight: '1.823vw',
      fontWeight: 'normal',
      margin: '0',
    },
    '& p': {
      margin: '0',
    },
  },
  blueBoxLeftBorderLeft: {
    padding: '50px 9vw 30px 4vw ',
    '& ul': {
      marginTop: '20px',
    },
  },
  blueBoxLeftBorderLeftNew: {
    padding: '4vh 4.3vh 1vh 6.3vh ',
    '& ul': {
      marginTop: '20px',
    },
  },
  mikestoryFullTop: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: '6vw',
  },
  rangeBlock: {
    textAlign: 'right',
    margin: '0 0 0 auto',
    '& h4': {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#0069aa',
      margin: '0',
      padding: '0',
    },
  },
  ovelayBoxLast: {
    padding: '3vw 3vw 3vw 1vw',
    top: '6.2vw',
    bottom: 'auto',
    position: 'absolute',
    zIndex: '2',
    left: 'auto',
    right: '0',
    width: 'auto',
    background: 'rgba(0, 0, 0, 0.5)',
    height: 'auto !important',
    color: '#fff',

    '& h3': {
      fontSize: '1.4vw',
      lineHeight: '2.1vw',
      padding: '0',
      fontFamily: "'MuseoSans-100'",
      margin: '0',
      fontWeight: 'normal',
      '& span': {
        fontSize: '1.3vw',
        lineHeight: '2.1vw',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '2vw 0 2vw 2vw',
      margin: '0',
      '& li': {
        padding: '0',
        fontSize: '1.3vw',
        lineHeight: '1.9vw',
      },
    },
    '& p': {
      fontSize: '1.4vw',
      lineHeight: '2.1vw',
      padding: '0',
      fontFamily: "'MuseoSans-300'",
      margin: '0',
    },
  },
  mikestoryLast: {
    padding: '10vw 0 0 3vw',
    '& h3': {
      fontSize: '2.12vw',
      padding: '1.6vw 0 3vw',
      fontFamily: "'MuseoSans-100'",
      lineHeight: '2.12vw',
      margin: '0.9vw 0 0.2vw',
      color: '#0069aa',
      fontWeight: 'normal',
    },
    '& p': {
      padding: '0 0 1vh 0',
      fontSize: '1.4vw',
      lineHeight: '2.1vw',
      color: '#0069aa',
      fontFamily: "'MuseoSans-100'",
      margin: '0',
    },
    '& a': {
      fontSize: '1.06vw',
      lineHeight: '2.1vw',
      margin: '3vw 0 0',
      transition: 'none',
      display: 'block',
      textDecoration: 'underline',
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      '& li': {
        padding: '0 0 1vh 0',
        fontSize: '1.4vw',
        lineHeight: '2.1vw',
        color: '#0069aa',
        fontFamily: "'MuseoSans-100'",
        margin: '0',

      },
    },
  },
  mikeStoryIcons: {
    position: 'fixed',
    zIndex: '1',
    width: '100px',
    paddingLeft: '35px',
    marginTop: '30px',
    '& > div:nth-child(2)': {
      '& img': {
        width: '2.3vw',
        marginLeft: '-5px',
      },
    },
    '& div': {
      display: 'block',
      marginTop: '25px',
      margin: '0 0 1.8vw 0',
      width: '2.4vw',
      height: '2.15vw',
      backgroundSize: 'cover !important',
      transition: 'none',
      '& img': {
        width: '100%',
        cursor: 'pointer',
      },
    },
  },
  closeIconPop: {
    display: 'block',
    borderBottom: '1px solid #e5e5e5',
    padding: '15px 20px',
    textAlign: 'right',
    color: '#e5e5e5',
    '& i': {
      cursor: 'pointer',
      '&:hover': {
        color: '#000',
      },
    },
  },
  replayPopupInner: {
    padding: '0 !important',
    minWidth: '600px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
    },
  },
  paymentPoupIcon: {
    padding: '25px',
    background: '#fff',
    maxWidth: '600px',
    margin: '0 auto',
    borderRadius: '15px',
    textAlign: 'center',
    '& span': {
      display: 'block',
      width: '150px',
      height: '150px',
      background: '#fff',
      border: '4px solid #008000',
      borderRadius: '100%',
      position: 'relative',
      margin: '0 auto 30px auto',
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '13px',
        left: '60px',
        width: '27px',
        height: '92px',
        border: 'solid #008000',
        borderWidth: '0 4px 4px 0',
        transform: 'rotate(45deg)',
      },
    },

  },
  higherEducationPopupNew: {

  },
  tooltipInfoTopNewText2: {
    width: 'auto',
    maxWidth: '180px',
    background: '#0069aa !important',
    fontSize: '15px',
    fontFamily: "'MuseoSans-300'",
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 6px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
  },
  tooltipInfoTopNewTextBottom: {
    width: 'auto',
    maxWidth: '180px',
    background: '#0069aa !important',
    fontSize: '15px',
    fontFamily: "'MuseoSans-300'",
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 6px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      borderBottomColor: '#0069aa !important',
    },
  },
  tooltipInfoTopNewTextRight: {
    padding: '3px 10px !important',
    fontSize: '15px !important',
  },
  mikeStoryToolBottom: {},
  infoIcon: {
    maxWidth: '20px',
    '& img': {
      maxWidth: '20px',
    },
  },

  // summary tables start here
  summaryTablePopup: {
    display: 'inline-block',
    '& > div > div': {
      width: '100%',
      maxWidth: '100%',
      margin: '0 20px',
      '& > div': {
        padding: '0 !important',
      },
    },
  },
  summaryTableHeader: {
    background: '#0f6aac',
    padding: '20px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      margin: '0 0 0 auto',
      fontSize: '35px',
      marginRight: '45px',
    },
  },
  summaryTableContent: {
    padding: '20px 40px',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  firstBlockLeft: {

  },
  mainHead: {
    background: '#0069aa',
    padding: '15px',
    margin: '10px 0',
    color: '#fff',
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 'normal',
    marginBottom: '30px',
    fontFamily: 'MuseoSans-500',
  },
  senarioBox: {
    marginBottom: '40px',
    '& span': {
      fontSize: '13px',
      display: 'block',
      marginBottom: '15px',
      color: '#0069aa',
    },
    '& p': {
      margin: '0',
      lineHeight: '41px',
      fontSize: '14px',
      backgroundImage: `url(${selectDrop})`,
      backgroundSize: '12px',
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      height: '43px',
      overflow: 'hidden',
      wordBreak: 'break-all',
      border: '1px solid #898d8f',
      padding: '0 15px',
    },

  },
  formGroup: {
    display: 'flex',
    border: '1px solid #898d8f',
    marginBottom: '15px',
    height: '38px',
    '& span': {
      background: '#e7e7e7',
      width: '50%',
      padding: '0 10px',
      color: '#898d8f',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '11px',
      '&:last-child': {
        background: '#fff',
        borderLeft: '1px solid #898d8f',
        color: '#0069aa',
      },
    },
  },
  RowBox: {
    display: 'flex',
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
    '& > div': {
      padding: '0 15px',
      boxSizing: 'border-box',
    },
  },
  graphBox: {
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontWeight: '600',
      fontSize: '22px!important',
      textAlign: 'center',
      margin: '0',
      borderBottom: '2px solid #0069aa',
      paddingBottom: '5px',
    },
  },
  highrEducationHead: {
    color: '#000',
    background: '#86a940',
  },
  senarioGroup: {
    marginBottom: '15px',
  },
  formGroupMain: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '& > div': {
      width: 'calc(100% - 80px)',
      marginBottom: '0',
    },
    '& p': {
      margin: '0 0 0 15px',
      textAlign: 'left',
      fontSize: '14px',

    },
  },
  tableBox: {
    marginBottom: '20px',
    '& table': {
      width: '100%',
      border: '1px solid #0069aa',
      tableLayout: 'fixed',
      background: '#e5f0f6',
      fontSize: '13px',
      borderCollapse: 'collapse',
      '& th': {
        background: '#000',
        textAlign: 'left',
        color: '#fff',
        fontWeight: 'normal',
        backgroundColor: '#0069aa',
        padding: '20px 15px',
        width: '100%',
        fontFamily: 'MuseoSans-500',
        fontSize: '16px',
        lineHeight: '15px',
      },
      '& tbody': {
        padding: '10px',
        '& tr': {
          '&:first-child': {
            '& td': {
              paddingTop: '20px',
            },
          },
          '& td': {
            padding: '5px 0 5px 25px',
            lineHeight: '15px',
            '&:last-child': {
              color: '#0069aa',
              fontFamily: 'MuseoSans-500',
            },
          },
        },

      },
      '& tfoot': {
        padding: '10px',
        borderTop: '2px solid #0069aa',
        '& tr': {

          '& td': {
            padding: '5px 0 5px 25px',
            lineHeight: '15px',
            fontFamily: 'MuseoSans-500',
            '&:last-child': {
              color: '#0069aa',
              fontFamily: 'MuseoSans-500',
            },
          },
        },
      },

    },

  },
  redBg: {
    background: '#bf0000',
    display: 'inline-block',
    width: '80px',
    padding: '5px 15px',
    color: '#fff',
    marginBottom: '0',
  },
  greenBg: {
    background: '#73ab43',
    display: 'inline-block',
    width: '80px',
    padding: '5px 15px',
    color: '#fff',
    marginBottom: '0',
  },
  borderBlue: {
    borderTop: '2px solid #0069aa',
    '& td': {
      fontFamily: 'MuseoSans-500',
    },
  },
  careerPathHead: {
    background: '#0069aa',
  },
  subHead: {
    color: '#0069aa',
    marginTop: '15px',
    fontSize: '14px',
    marginBottom: '10px',
  },
  noborder: {
    border: 'none',
    '& span': {
      background: 'none',
      border: 'none',
      '&:first-child': {
        paddingLeft: '0',
      },
      '&:last-child': {
        border: 'none',
      },
    },
  },
  summaryTableFooter: {
    padding: '15px',
    borderTop: '1px solid #ccc',
    textAlign: 'right',
    '& button': {
      border: '1px solid #ccc',
      minWidth: '80px',
      borderRadius: '5px',
      '&:hover': {
        background: '#f2f2f2',
      },
    },
  },
  investHead: {
    background: '#caa336',
  },
  tableHeadNew: {
    paddingLeft: '15px !important',
  },
  VacationHead: {
    background: '#b94606',
  },
  AddcareerPathHead: {
    background: '#81c3dc',
  },
  buyingHomeHead: {
    background: '#e66e1d',
  },
  moreHighrEducationHead: {
    background: '#529c6f',
  },
  furtherEducationHead: {
    background: '#529c6f',
  },
  assetsHead: {
    background: '#caa336',
  },
  buyingHead: {
    background: '#134376',
  },
  marginZero: {
    margin: '0 0 15px',
    minHeight: 'auto',
    height: 'auto',
  },
  newText: {
    background: '#fff',
    padding: '5px 0',
    margin: '0',
    listStyle: 'none',
    minWidth: '190px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
    top: '-49px',
    left: '50px',
    display: 'block',
    '&:before': {
      content: "''",
      height: '0',
      left: '-1.4vw',
      position: 'absolute',
      top: '1.3vw',
      width: '0',
      borderRight: '1vw solid #fff',
      borderBottom: '0.7vw solid transparent',
      borderTop: '0.7vw solid transparent',
      borderLeft: '0.5vw solid transparent',

    },
    '& li': {
      padding: '3px 15px',

      '&:hover': {
        background: '#f2f2f2',
      },
      '& p': {
        margin: '0',
      },
    },
  },
  careerSketchTimelineInCnt: {
    width: '100%',
    display: 'block',
  },
  relativeBlock: {
    position: 'relative',
  },
  netRangeBlock: {
    width: '57vh',
    float: 'right',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& small': {
      fontSize: '2vh',
      lineHeight: '2vh',
      margin: '0 0 0 2vh',
      display: 'inline-block',
      width: '45%',
      textAlign: 'center',
      color: '#0069aa',
      fontFamily: 'MuseoSans-500',
      position: 'relative',
      fontWeight: '400',
      textTransform: 'uppercase',
      '& input': {
        width: '100%',
        maxWidth: 'inherit',
        background: '#fbfdfd',
        height: '35px',
        padding: '0',
        cursor: 'pointer',
        margin: '0 auto',
        WebkitAppearance: 'none',
        display: 'block',
      },
      '& span': {
        position: 'absolute',
        left: '0',
        right: '0',
        margin: 'auto',
        top: '-1.6vh',
        width: '2vh',
        float: 'right',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
  },
  summaryPopup: {
    '& > div > div': {
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '15px',
      },
      '& h4': {
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '25px',
          marginBottom:'10px',
        },
      }
    },
  },
  modalHeader1: {
    background: '#0f6aac',
    padding: '20px',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    '& h4': {
      float: 'right',
      color: '#fff',
      fontSize: '35px',
      marginRight: '45px',
      margin: '0',
      lineHeight: '1.42857143',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',             
      },
    },
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
      border: '0px',
      [theme.breakpoints.down( 'xs' )]: {
        margin:'0 auto',            
      },
    },
   },
   closePopup: {
    right: '15px',
    top: '9px',
    color: '#fff',
    position: 'absolute',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    marginTop: '2px',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    opacity: '0.4',
    fontWeight: '700',
    '&:hover':{
      opacity: '0.8',
    }
  },
  modalBody1: {
    position: 'relative',
    padding: '15px',
  },
  modalFooter: {
    position: 'relative',
    padding: '7px 15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
    },
  },
} );

export default styles;
