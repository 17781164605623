import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const BestandWorst2021Investments = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Best and Worst 2021 Investments </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/best-worst-of-2021.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeadingTitle}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Best and Worst 2021 Investments
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/best-worst-2021-187px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          To start off 2022, let&apos;s look at the best and
                          worst performers in 2021.{' '}
                        </p>
                      </div>
                    </div>
                    <div className={classes.graphBig}>
                      <p>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'bar',
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: '2021 Performance by Asset Class',
                            },
                            tooltip: {
                              enabled: false,
                            },
                            exporting: {
                              enabled: false,
                            },
                            legend: false,
                            yAxis: {
                              tickInterval: 10,

                              title: {
                                text: '',
                              },
                              labels: {
                                formatter: function () {
                                  return (
                                    this.axis.defaultLabelFormatter.call(this) +
                                    ' %'
                                  );
                                },
                                style: {
                                  color: 'black',
                                  fontSize: '14px',
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                stackings: 'normal',
                                dataLabels: {
                                  enabled: true,
                                  formatter: function () {
                                    if (this.y !== 0) {
                                      return this.y + ' %';
                                    }
                                  },
                                },
                              },
                              series: {
                                grouping: false,
                              },
                            },
                            xAxis: {
                              categories: [
                                'Bitcoin (Cryptocurrency)',
                                'WTI Crude Oil',
                                'S&P GSCI (Commodities)',
                                'Dow Jones Real Estate Index',
                                'S&P 500 (U.S. Stocks)',
                                'S&P/TSX Composite (Canadian Stocks)',
                                'Russell 2000 (U.S. Stocks)',
                                'MSCI EAFE (Europe, Australia, and Far East Stocks)',
                                'U.S. Dollar',
                                'Bloomberg Barclays Corporate Bonds',
                                'Bloomberg U.S. Treasury Index',
                                'Gold',
                                'MSCI Emerging Markets Stocks',
                                'Silver',
                              ],
                              labels: {
                                style: {
                                  color: 'black',
                                  fontSize: '14px',
                                },
                              },
                            },

                            series: [
                              {
                                name: 'Bitcoin (Cryptocurrency)',
                                data: [
                                  59.8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#385723',
                                dataLabels: {
                                  color: '#385723',
                                },
                              },
                              {
                                name: 'WTI Crude Oil',
                                data: [
                                  0, 56.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#385723',
                                dataLabels: {
                                  color: '#385723',
                                },
                              },
                              {
                                name: 'S&P GSCI (Commodities)',
                                data: [
                                  0, 0, 37.1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#548235',
                                dataLabels: {
                                  color: '#548235',
                                },
                              },
                              {
                                name: 'Dow Jones Real Estate Index',
                                data: [
                                  0, 0, 0, 35.1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#548235',
                                dataLabels: {
                                  color: '#548235',
                                },
                              },
                              {
                                name: 'S&P 500 (U.S. Stocks)',
                                data: [
                                  0, 0, 0, 0, 26.9, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#548235',
                                dataLabels: {
                                  color: '#548235',
                                },
                              },
                              {
                                name: 'S&P/TSX Composite (Canadian Stocks)',
                                data: [
                                  0, 0, 0, 0, 0, 21.7, 0, 0, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#548235',
                                dataLabels: {
                                  color: '#548235',
                                },
                              },
                              {
                                name: 'Russell 2000 (U.S. Stocks)',
                                data: [
                                  0, 0, 0, 0, 0, 0, 13.7, 0, 0, 0, 0, 0, 0, 0,
                                ],

                                color: '#548235',
                                dataLabels: {
                                  color: '#548235',
                                },
                              },
                              {
                                name: 'MSCI EAFE (Europe, Australia, and Far East Stocks)',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 7.8, 0, 0, 0, 0, 0, 0,
                                ],
                                color: '#A9D18E',
                                dataLabels: {
                                  color: '#A9D18E',
                                },
                              },
                              {
                                name: 'U.S. Dollar',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 6.4, 0, 0, 0, 0, 0,
                                ],
                                color: '#C5E0B4',
                                dataLabels: {
                                  color: '#C5E0B4',
                                },
                              },
                              {
                                name: 'Bloomberg Barclays Corporate Bonds',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 0, -1.2, 0, 0, 0, 0,
                                ],
                                color: '#F8CBAD',
                                dataLabels: {
                                  color: '#F8CBAD',
                                },
                              },
                              {
                                name: 'Bloomberg U.S. Treasury Index',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2.5, 0, 0, 0,
                                ],
                                color: '#F4B183',
                                dataLabels: {
                                  color: '#F4B183',
                                },
                              },
                              {
                                name: 'Gold',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -3.6, 0, 0,
                                ],
                                color: '#F4B183',
                                dataLabels: {
                                  color: '#F4B183',
                                },
                              },
                              {
                                name: 'MSCI Emerging Markets Stocks',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -5.5, 0,
                                ],
                                color: '#C55A11',
                                dataLabels: {
                                  color: '#C55A11',
                                },
                              },
                              {
                                name: 'Silver',
                                data: [
                                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -11.7,
                                ],
                                color: '#C00000',
                                dataLabels: {
                                  color: '#C00000',
                                },
                              },
                            ],
                          }}
                        />
                      </p>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <i>Source: Visual Capitalist</i>
                        </p>
                        <p>
                          2021 was a great year to be invested in the markets.
                          Despite Covid-19 continuing to rear its ugly head into
                          our personal lives as well as businesses globally, the
                          U.S. stock market more than doubled its average
                          performance over the last 100 years with a 27% return.
                        </p>
                        <p>
                          Bitcoin took the crown for best performance in 2021.
                          For those of you unfamiliar with bitcoin, it is the
                          most prominent and popular cryptocurrency. To learn
                          more about crypto,{' '}
                          <a
                            href="https://www.youtube.com/watch?v=1YyAzVmP9xQ"
                            target="_blank"
                            rel="noopener noreferrer">
                            watch this video
                          </a>
                          . Check out the price action in the chart below. Over
                          the past year, the price of one Bitcoin has gone from
                          a low of $28,600 to a high of $69,000 and now back to
                          $43,562 as of 6:49 PM EST 1/5/22. Although the best
                          performer, Bitcoin has been super{' '}
                          <Link
                            to="/finance-explained/volatility"
                            target="_blank">
                            volatile
                          </Link>
                          . over the past year.
                        </p>
                        <p>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/graph2-image-invest.png"
                            alt=""
                            className={classes.imgFull}
                          />
                          <br />
                          <i>Source: Trading View</i>
                        </p>
                        <p>
                          The S&P 500 is broken down into different sectors that
                          represent the U.S. economy. Which sectors performed
                          the best in 2021?
                        </p>
                        <p>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'bar',
                              },
                              credits: {
                                enabled: false,
                              },
                              tooltip: {
                                enabled: false,
                              },
                              exporting: {
                                enabled: false,
                              },
                              legend: false,
                              title: {
                                text: 'S&P 500 Performance by Sector',
                              },
                              yAxis: {
                                tickInterval: 20,
                                title: {
                                  text: '',
                                },
                                labels: {
                                  formatter: function () {
                                    return (
                                      this.axis.defaultLabelFormatter.call(
                                        this,
                                      ) + ' %'
                                    );
                                  },
                                  style: {
                                    color: 'black',
                                    fontSize: '14px',
                                  },
                                },
                              },
                              plotOptions: {
                                bar: {
                                  stackings: 'normal',
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      if (this.y !== 0) {
                                        return this.y + ' %';
                                      }
                                    },
                                  },
                                  marker: {
                                    enabled: false,
                                  },
                                },
                                series: {
                                  grouping: false,
                                },
                              },
                              xAxis: {
                                categories: [
                                  'Energy',
                                  'Real Estate',
                                  'Information Technology',
                                  'Financials',
                                  'Healthcare',
                                  'Consumer Discretionary',
                                  'Materials',
                                  'Communication Services',
                                  'Industrials',
                                  'Consumer Staples',
                                  'Utilities',
                                ],
                                dataLabels: {
                                  enabled: false,
                                  format: '{y} %',
                                },
                                labels: {
                                  style: {
                                    color: 'black',
                                    fontSize: '14px',
                                  },
                                },
                              },
                              series: [
                                {
                                  name: 'Energy',
                                  data: [59.8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                  color: '#385723',
                                  width: 6,
                                  dataLabels: {
                                    color: '#385723',
                                  },
                                },
                                {
                                  name: 'Real Estate',
                                  data: [0, 56.4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                  color: '#385723',
                                  dataLabels: {
                                    color: '#385723',
                                  },
                                },
                                {
                                  name: 'Information Technology',
                                  data: [0, 0, 37.1, 0, 0, 0, 0, 0, 0, 0, 0],
                                  color: '#548235',
                                  dataLabels: {
                                    color: '#548235',
                                  },
                                },
                                {
                                  name: 'Financials',
                                  data: [0, 0, 0, 35.1, 0, 0, 0, 0, 0, 0, 0],
                                  color: '#548235',
                                  dataLabels: {
                                    color: '#548235',
                                  },
                                },
                                {
                                  name: 'Healthcare',
                                  data: [0, 0, 0, 0, 26.9, 0, 0, 0, 0, 0, 0],
                                  color: '#548235',
                                  dataLabels: {
                                    color: '#548235',
                                  },
                                },
                                {
                                  name: 'Consumer Discretionary',
                                  data: [0, 0, 0, 0, 0, 21.7, 0, 0, 0, 0, 0],
                                  color: '#548235',
                                  dataLabels: {
                                    color: '#548235',
                                  },
                                },
                                {
                                  name: 'Materials',
                                  data: [0, 0, 0, 0, 0, 0, 13.7, 0, 0, 0, 0],
                                  color: '#548235',
                                  dataLabels: {
                                    color: '#548235',
                                  },
                                },
                                {
                                  name: 'Communication Services',
                                  data: [0, 0, 0, 0, 0, 0, 0, 7.8, 0, 0, 0],
                                  color: '#A9D18E',
                                  dataLabels: {
                                    color: '#A9D18E',
                                  },
                                },
                                {
                                  name: 'Industrials',
                                  data: [0, 0, 0, 0, 0, 0, 0, 0, 6.4, 0, 0],
                                  color: '#C5E0B4',
                                  dataLabels: {
                                    color: '#C5E0B4',
                                  },
                                },
                                {
                                  name: 'Consumer Staples',
                                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, -1.2, 0],
                                  color: '#F8CBAD',
                                  dataLabels: {
                                    color: '#F8CBAD',
                                  },
                                },
                                {
                                  name: 'Utilities',
                                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2.5],
                                  color: '#F4B183',
                                  dataLabels: {
                                    color: '#F4B183',
                                  },
                                },
                              ],
                            }}
                          />
                        </p>
                        <p>
                          Which asset classes and which sectors of the U.S.
                          economy do you think will perform the best in 2022?
                          The worst? Check out Wall Street&apos;s predictions in
                          'Just hit the tape' below. Send me an email and
                          let&apos;s discuss.
                        </p>
                      </div>
                    </div>

                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>
                      <ul>
                        <li>
                          {' '}
                          <a
                            href="https://www.visualcapitalist.com/the-worlds-biggest-startups-top-unicorns-of-2021/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Wall Street&apos;s prediction for 2022 investment
                            performance
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://www.visualcapitalist.com/the-worlds-biggest-startups-top-unicorns-of-2021/"
                            target="_blank"
                            rel="noopener noreferrer">
                            There&apos;s more to investing to risk and reward
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://grow.acorns.com/how-much-average-millennial-pays-for-a-checking-account/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Millennials pay 8x more than their parents for a
                            checking account - for no reason
                          </a>{' '}
                        </li>
                      </ul>

                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained">Finance Explained.</Link>{' '}
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
BestandWorst2021Investments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BestandWorst2021Investments);
