import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const SecondCarDiscountRebate = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject,
  } = props;

  const [discountValue, setDiscountValue] = React.useState( handleBuyingCarObject.discount_in_percentage === undefined ? 6.50 : handleBuyingCarObject.discount_in_percentage );
  const [rebateValue, setRebateValue] = React.useState( handleBuyingCarObject.rebate === undefined ? 0 : handleBuyingCarObject.rebate );
  const totalPurchaseValue = handleBuyingCarObject.base_price + handleBuyingCarObject.destination_charges + handleBuyingCarObject.car_options;
  const [discountInput, setDiscountInputValue] = React.useState( ( totalPurchaseValue * ( discountValue / 100 ) ) );
  const [rebateInputValue, setRebateInputValue] = React.useState( totalPurchaseValue * ( rebateValue / 100 ) );
  handleBuyingCarObject.second_adjusted_purchase_price = totalPurchaseValue - ( discountInput + rebateInputValue );

  const DiscountArray = sliderValues.interestCarIncome();
  DiscountArray[0].label = '0% of PurchasePrice';

  useEffect( () => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.discount_in_percentage === undefined ) {
      totalPurchaseObj.second_discount = 6.50;
      setDiscountValue( 6.50 );
      setDiscountInputValue( totalPurchaseValue * ( 6.5 / 100 ) );
    }
    if ( handleBuyingCarObject.rebate === undefined ) {
      totalPurchaseObj.second_rebate = 0;
      setRebateValue( 0 );
      setRebateInputValue( 0 );
    }

    handleUpdatedObject( totalPurchaseObj );

    // eslint-disable-next-line
  }, []);
  const handleBasePriceSlider = ( event, newValue ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    setDiscountValue( newValue );
    setDiscountInputValue( totalPurchaseValue * ( newValue / 100 ) );
    totalPurchaseObj.second_discount = newValue;
    totalPurchaseObj.second_adjusted_purchase_price = totalPurchaseValue - ( discountInput + rebateInputValue );
    handleUpdatedObject( totalPurchaseObj );
  };

  const handleBasePriceInput = ( event ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setDiscountValue( ( updatedValue * 100 ) / totalPurchaseValue );
    setDiscountInputValue( updatedValue );
    totalPurchaseObj.second_discount = ( updatedValue * 100 ) / totalPurchaseValue;
    totalPurchaseObj.second_adjusted_purchase_price = totalPurchaseValue - ( discountInput + rebateInputValue );
    handleUpdatedObject( totalPurchaseObj );
  };

  const handleDestinationSlider = ( event, newValue ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    setRebateValue( newValue );
    setRebateInputValue( totalPurchaseValue * ( newValue / 100 ) );
    totalPurchaseObj.second_adjusted_purchase_price = totalPurchaseValue - ( discountInput + rebateInputValue );
    totalPurchaseObj.second_rebate = newValue;
    handleUpdatedObject( totalPurchaseObj );
  };

  const handleDestInput = ( event ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setRebateValue( ( updatedValue * 100 ) / totalPurchaseValue );
    setRebateInputValue( updatedValue );
    totalPurchaseObj.second_rebate = ( updatedValue * 100 ) / totalPurchaseValue;
    totalPurchaseObj.second_adjusted_purchase_price = totalPurchaseValue - ( discountInput + rebateInputValue );
    handleUpdatedObject( totalPurchaseObj );
  };
  return (
    <div className={ classes.contentBlock }>
      <p>
        Whether it&apos;s a new or used vehicle, discounts are a crucial part of the buying process. A
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Discount: Buying a Car' ) }>discount</span>
        {' '}
        is a price reduction on your vehicle given by the dealership. Negotiating a discount on a vehicle takes preparation and practice.
        {' '}
        {' '}
        <a href="https://www.consumerreports.org/car-pricing-negotiation/how-to-negotiate-a-new-car-price-effectively/" target="_blank" rel="noopener noreferrer">Click here</a>
        {' '}
        for a helpful guide from Consumer Reports on negotiating
      </p>
      <p>
        A
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Rebate: Buying a Car' ) }>rebate</span>
        {' '}
        is a fixed amount of money paid to you, the buyer, to incentivize you to buy that vehicle. The manufacturer of the vehicle typically offers the
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Rebate: Buying a Car' ) }>rebate</span>
        . But you have to always ask the dealership if there are any rebates currently offered on the vehicle you are considering. To see the latest incentives and rebates, check out
        {' '}
        <a href="https://www.edmunds.com/car-incentives/" target="_blank" rel="noopener noreferrer">Edmunds.com</a>
      </p>
     
      <div className={ classes.priceSliders }>
        <div className={ classes.priceText }>
          <p>&nbsp;</p>
        </div>
        <div className={ classes.slideGraphNew }>
          <p><b>Total Purchase Price</b></p>
        </div>
        <div className={ classNames( classes.priceValue, classes.boldText ) }>
          $
          <span className={ classes.inputValue }><NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalPurchaseValue } prefix="$" /></span>
        </div>
      </div>
      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Discount: Buying a Car' ) }>Discount</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ DiscountArray }
                  min={ 0 }
                  max={ 14 }
                  step={ 0.01 }
                  value={ discountValue }
                  valueLabelFormat={percentFormatWithTwoDecimal}
                  onChange={ handleBasePriceSlider }
                />
              </div>
              <div className={ classNames( classes.annuvalIncomeGrowthInput, classes.flexText ) }>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ discountInput } onValueChange={ handleBasePriceInput } onFocus={ ( e ) => { e.target.select(); } } />

              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Rebate: Buying a Car' ) }>Rebate</span></p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ DiscountArray }
              min={ 0 }
              max={ 14 }
              step={ 0.01 }
              value={ rebateValue }
              valueLabelFormat={ percentFormatWithTwoDecimal }
              onChange={ handleDestinationSlider }
            />
          </div>
          <div className={ classNames( classes.annuvalIncomeGrowthInput, classes.flexText ) }>
            <NumberFormat decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ rebateInputValue } onValueChange={ handleDestInput } onFocus={ ( e ) => { e.target.select(); } } />
          </div>
        </div>
      </div>
        </div>
      </div>
      <div className={ classes.priceSliders }>
        <div className={ classes.priceText }>
          <p>&nbsp;</p>
        </div>
        <div className={ classes.slideGraphNew }>
          <p><b>Adjusted Purchase Price</b></p>
        </div>
        <div className={ classNames( classes.priceValue, classes.boldText ) }>
          <span className={ classes.inputValue }>
            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalPurchaseValue - ( rebateInputValue + discountInput ) } prefix="$" />
          </span>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SecondCarDiscountRebate.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( SecondCarDiscountRebate );
