import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import seperatordots from '../../../assets/img/pf101/dots.png';
import xpGraphic from '../../../assets/img/pf101/xp-graphic.png';

const styles = ( theme ) => ( {
  seperatorDots: {
    margin: '40px auto',
  },
  nextStep: {
    fontSize: '24px',
    margin: '30px 0px',
    color: '#0069aa',
    fontWeight: 'normal',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
    },
  },
  applyLearned: {
    fontSize: '20px',
    color: '#000',
  },
  graphicImage: {
    '& img': {
      width: '100px',
      margin: '30px 0px',
    },
  },
} );

const BottomContent = ( props ) => {
  const { classes } = props;
  return (
    <div className={ classes.seperatorDotBlock }>
      <div className={ classes.seperatorDots }>
        <img src={ seperatordots } alt="logo" />
      </div>
      <h2 className={ classes.nextStep }>Are you ready for the next step?</h2>
      <p className={ classes.applyLearned }>
        Apply what you&apos;ve learned and
        <br />
        {' '}
        earn PowerUp XP.
      </p>
      <div className={ classes.graphicImage }>
        <img src={ xpGraphic } alt="graphic" />
      </div>
    </div>
  );
};

BottomContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( BottomContent );
