import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Dialog, IconButton} from '@material-ui/core';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import styles from './styles';
import Pageloader from '../../components/ui/pageloader';
import dummyUser from '../../assets/img/desktop/user.png';
import desktopArrow from '../../assets/img/desktop/desktop_arrow.png';
import powerUp from '../../assets/img/desktop/powerup-101-gold.png';
import myCareerSketch from '../../assets/img/desktop/my-career-sketch-blue.png';
import planInkwiry from '../../assets/img/desktop/pwi_text_logo.png';
import inkwiryRev from '../../assets/img/desktop/inkwiry-final-rev.png';
import greenLine from '../../assets/img/desktop/green_line_small.png';
import videoIcon from '../../assets/img/desktop/video_ic.png';
import NumberFormat from 'react-number-format';
import * as modulesServices from '../../calculations/modules-services';

const MY_DETAILS = loader( '../../graphql/schema/auth/me.graphql' );

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const Desktop = (props) => {
  const { classes } = props;
  const [videoPlayer, setVideoPlayer] = React.useState( null );
  // const [buttonDisabled, setButtonDisabled] = React.useState( true );

  const handleClickOpen = ( tile ) => {
    setVideoPlayer( tile );    
  };

  const handleClose = () => {
    setVideoPlayer( null );
  };

  const { data: myDetails, loading } = useQuery( MY_DETAILS );

  const year_5th = modulesServices.get5thYearNetworth();
  const year_10th = modulesServices.get10thYearNetworth();
  const year_15th = modulesServices.get15thYearNetworth();
  const year_current = modulesServices.getCurrentYearNetworth();

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={loading} />}
      <div className={classes.desktopPage}>
        <div className={classes.welcomeSection}>
          <div className={classes.container}>
            <div className={classes.welcomeContent}>
              <div className={classes.dashboardImage}>
                <div className={classes.userProfile}>
                  <img src={ myDetails && myDetails.me.profile_image ? IMAGE_PATH + myDetails.me.profile_image : dummyUser }  alt="" />
                </div>
                <Typography variant="h2" component="h2">Welcome, {' '}
                  {myDetails && myDetails.me.user_fname}
                .</Typography>
              </div>
              <div className={classes.dashboardContent}>
                <Grid container spacing={3} className={classes.GridPad}>
                  <Grid item sm={3} xs={12} className={classes.noPadding}>
                    <div className={classes.currentNetworth}>
                      <Typography variant="h2" component="h2">Current Net Worth:</Typography>
                      <p><span className={year_current < 0 && classes.redText}>
                           <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInputLeft} displayType="text" allowNegative={false} thousandSeparator value={year_current} prefix={year_current >= 0 ? '$' : '($'} suffix={year_current < 0 && ')'} />
                          </span></p>
                    </div>
                  </Grid>
                  <Grid item sm={5} xs={12} className={classes.noPadding}>
                    <div className={classes.dashboardNetWorth}>
                      <Typography variant="h2" component="h2">Net Worth:</Typography>
                      <ul>
                        <li>
                          <span className={classes.yearCount}>5 Years</span>
                          <span className={year_5th < 0 && classes.redText}>
                           <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_5th} prefix={year_5th >= 0 ? '$' : '($'} suffix={year_5th < 0 && ')'} />
                          </span>
                        </li>
                        <li>
                          <span className={classes.yearCount}>10 Years</span>
                          <span className={year_10th < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_10th} prefix={year_10th >= 0 ? '$' : '($'} suffix={year_10th < 0 && ')'} />
                    </span>
                        </li>
                        <li>
                          <span className={classes.yearCount}>15 Years</span>
                          <span className={year_15th < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_15th} prefix={year_15th >= 0 ? '$' : '($'} suffix={year_15th < 0 && ')'} />
                    </span>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12} className={classes.noPadding}>
                    <div className={classes.activeSketch}>
                      <Typography variant="h2" component="h2">Active Career Sketch:</Typography>
                      <p>Active Selection</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.uncoverPath}>
          <div className={classes.desktopArrow}>
            <img src={desktopArrow} alt="" />
          </div>
          <div className={classes.container}>
            <div className={classes.arrowRow}>
              <div className={classes.arrowContent}>
                <Typography variant="h4" component="h4">Uncover your path to financial independence. </Typography>
                <div className={classes.desktopLogos}>
                  <div className={classes.powerUp}>
                    <Link to="/pf101/dashboard">
                      <img src={powerUp} alt="" />
                    </Link>
                  </div>
                  <div className={classes.careerSketch}>
                    <Link to="/my-career-sketch/dashboard">
                      <img src={myCareerSketch} alt="" />
                    </Link>
                  </div>
                  <div className={classes.planInkwiry}>
                    <Link to="/fc-blueprint">
                      <img src={planInkwiry} alt="" />
                    </Link>
                  </div>
                </div>

                <div className={classes.quickTourButton} onClick={ handleClickOpen }>
                  <div className={classes.quickTourLogo}>
                    <img src={inkwiryRev} alt="greenLine" />
                  </div>
                  <div className={classes.quickTourLine}>
                    <img src={greenLine} alt="greenLine" />
                  </div>
                  <div className={classes.quickText}>
                    <h3>QUICK TOUR</h3>
                  </div>
                  <div className={classes.videoIcon}>
                    <span>
                      <img src={videoIcon} alt="videoicon" onClick={ handleClickOpen }/>
                    </span>
                    
                  </div>
                </div>
                <Dialog
                      open={(videoPlayer !== null)}
                      onClose={handleClose}
                      className={classes.dialog}
                      maxWidth='lg'
                     >
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          setVideoPlayer( null );
                        }}
                        className={classes.closeIcon}
                      >
                        <CancelRounded />
                      </IconButton>
                      <iframe
                        width="800"
                        height="480"
                        src="https://www.youtube.com/embed/V6nRimAMD9c?autoplay=1" 
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

Desktop.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Desktop);
