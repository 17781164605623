import tickmark from '../../../assets/img/mcs/level6/black-tick-icon.png';
import arrowMark from '../../../assets/img/mcs/level6/arrow-level4.png';

const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  activityContainer:{
    maxWidth: '1040px',   
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #1f96e0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-700',
      fontSize: '30px',
      fontWeight: 'normal',
      color: '#0097e6',    
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      '& > img': {
        marginRight: '25px',
        height: '50px',
        cursor: 'pointer',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
          height: '40px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
    '@media (max-width: 767px)': {
      width: '20px',
      '& span': {
        height: '3px',
        width: '20px',
      }
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '20px',
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '0 auto 0',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      color: '#333',
      lineHeight: '25px',
    },    
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },  
  },
  startButton: {
    paddingTop: '20px',
    float: 'right',
    paddingBottom: '50px',
    '& button': {
      padding: '10px 20px',
      background: '#337ab7',
      textTransform: 'uppercase',
      border: '1px solid #337ab7',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '18px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',   
      fontFamily: 'MuseoSans-300', 
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  }, 
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsLogoImage: {   
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '200px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '180px',
        },
      },
    },
  },
  
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  mcsDevelopVision: {
    background:'#004375',
    padding: '10px 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 30px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 20px',
    },
  },
  backtoLevel: {
    '& a': {
      textDecoration: 'underline',
      color: '#000',
      fontFamily: 'MuseoSans-300',
      '&:hover':{
        color: '#f89b22 !important',
      },
    },
  }, 
  marginTopZero: {
    marginTop: '0px',
  },
  activityStatus: {
    '& h4': {
      fontSize:'22px',
      color: '#fff !important',
      margin: '0px',
      fontFamily: 'MuseoSans-300',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
      [theme.breakpoints.down('xs')]: {
        display:'block',
        fontSize:'16px',
      },
      '& a': {
        color: '#fff !important',
        borderBottom: '1px solid',
        lineHeight: 'normal',
        marginLeft: '5px',
        '&:hover':{
          color: '#f89b22 !important',
        },
      },
    },    
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap:'wrap',
      justifyContent: 'center',
    },
  },
  activityImage: {
    paddingTop: '10px',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '10px',
     },
    '& img': {
      width: '30px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  greenTick: {
    '& img': {
      width: '20px',
      verticalAlign: 'middle',
      marginright: '5px',
    },
  },
  marginBottomZero: {
    marginBottom: '0px',
  },
  reviewLevels: {   
    position: 'absolute',
    right: '0',
    minWidth: '300px',
    padding: '10px 0',
    background: '#fff',
    marginTop: '20px',
    zIndex: '9999',
    color: '#333',
    '&::before': {
      borderBottom: '11px solid #fff',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '92%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    '& i': {
      position: 'absolute',
      top: '3px',
      right: '3px',
      cursor: 'pointer',
    },
  },
  bookBlock: {
    position: 'relative',
  },
  panelDefault: {
    borderColor: '#0069aa',
    textAlign: 'left',
    background: '0 0',
    width: '90%',
    margin: '0 auto 3px',
    borderRadius: '0',
    border: '1px solid transparent',   
    webkitBoxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
    boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
  },
  panelBody: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  titleName: {
    width: '96%',
    display: 'flex',
  },
  mcsNumber: {
    paddingRight: '5px',
  },
  greentickMark: {
    width: '4%',
    '& img': {
      width: '10px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '12px !important',
    textAlign: 'center',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    maxWidth: '200px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },    
  },
  pageBreadcrumbs:{   
    borderBottom: 'none',
    paddingBottom: '0',
    position:'relative',
    marginBottom: '0',
    padding: '10px 2px',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  headingBlock:{
    position:'relative',
    width:'100%',
    padding:'0px',
    '& a':{
      color: '#333',
      padding: '0',
      margin: '0 0 20px',
      textDecoration: 'underline',
      display:'block',
      boxSizing:'border-box',
      '&:hover':{
        color: '#f89b23',
      },
    },
    '& h3':{
      color: '#3ba2e4',
      fontSize: '30px',
      fontWeight: '700',
      margin: '0',
      fontFamily:'inherit',
      lineHeight: '1.1',
    },
  },
  arrowHeader:{
    '& img':{
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  mainContainer:{
    maxWidth: '950px',
    margin: '0 auto',
    fontWeight: '300',
    boxSizing:'border-box',
    lineHeight: '1.42857143',
  },
  conversationTitle:{
    display: 'flex',
    marginTop: '10px',
    height: '50px',
  },
  mainTitle:{
    marginTop: '20px',
    color: '#333',
    textAlign: 'left',
    lineHeight: '30px',
    fontWeight: '300',
    fontSize: '21px',
    marginBottom: '10px',
    '& span':{
      color: '#066cac',
      '& b':{
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  mainContent:{
    '& h3':{
      marginTop: '20px',
      color: '#333',
      textAlign: 'left',     
      fontWeight: '300',
      fontSize: '21px',
      marginBottom: '10px',
      lineHeight:'35px',
    },
  },
  grayBox:{    
    fontWeight: '300',
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    boxSizing:'border-box',
    '& ol':{
      marginTop:'0px',
      marginBottom:'10px',
    },
  },
  noMargin:{
    margin:'0px',
  },
  bottomButtons:{
    marginTop: '10px',
    position: 'relative',
    margin: '30px 0px',
    display: 'inline-block',
    width: '100%',
    '& button':{     
      textTransform: 'uppercase',     
      backgroundImage: 'none',
      outline: '0',
      boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 13%)',
      display: 'inline-block',
      marginBottom: '0',     
      fontWeight: '400',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',     
      touchAction: 'manipulation',
      cursor: 'pointer',
      userSelect: 'none',      
      '&:hover':{
        border: '2px solid #d5d5d5',
      },
    },
  },
  nextButton:{
    backgroundColor: '#0069aa',
    color: '#fff',
    float: 'right',
    borderRadius: '3px',
    padding: '8px 20px',
    fontSize: '18px',
    border: '1px solid transparent',
  },
  backButton:{
    float: 'left!important',
    backgroundColor: '#fff',
    color: '#333',
    border: '2px solid #d5d5d5',
    borderRadius: '3px',
    padding: '8px 20px',
    textTransform: 'uppercase !important',
    fontSize: '18px',
    boxShadow:'none !important',
    fontFamily:'inherit',
  },
  graphNote:{
    fontSize: '16px',
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'block',
    boxSizing:'border-box',
  },
  authorTitle:{
    display: 'inline-block',
    width: '100%',
  },
  noTitleMargin:{
    marginTop:'0px !important',
    marginBottom:'0px !important',
  },
  shadowImage:{
    marginTop: '35px',
    marginBottom: '20px',
    '& img':{
      width:' 90%',
      marginLeft: '5%',
      boxShadow: '0 0 30px #ccc',
      verticalAlign: 'middle',
      border:'0',
    },
  },
  savingsList:{
    listStyleType: 'none',
    paddingLeft: '0',
    marginBottom:'10px',
    marginTop:'0px',
    '& li':{
      backgroundImage: `url(${tickmark})`,
      backgroundPosition:'left 10px',
      backgroundRepeat:'no-repeat',
      backgroundSize: '16px',
      paddingLeft: '30px',
    },
  },
  lumensSahdowTable:{
    width: '78%',
    margin: '35px auto 20px',
    boxShadow: '0 0 30px #ccc',
    boxSizing:'border-box',
    '& table':{
      width: '100%',
      margin: '0 auto',
      maxWidth: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      boxSizing:'border-box',
      '& tr':{
        '& th':{
          textAlign: 'center',
          borderBottom: '2px solid #a1b8e1',
          borderTop: '2px solid #a1b8e1',
          padding: '8px',
          lineHeight: '1.42857143',
          fontSize: '18px',
          verticalAlign: 'middle',         
        },
      },
      '& tbody':{
        '& tr':{
          '&:nth-child(odd)':{
            background: '#dae3f3',
          },
          '& td':{
            '&:nth-child(1)':{
              textAlign: 'left',
            },
            padding: '8px',
            lineHeight: '1.42857143',
            borderTop: '1px solid #ddd',
            fontSize: '18px',
            verticalAlign: 'middle',
            textAlign:'right',
          },
          '&:last-child':{
            '& td':{
              borderBottom: '2px solid #a1b8e1!important',
            },
          },
        },
      },
    },
  },
  topBorder:{
    '& td':{
      borderTop: '2px solid #a1b8e1 !important',
      borderBottom: '2px solid #a1b8e1',
    },
  },
  greenText:{
    color: '#759840!important',
  },
  lumensTables:{
    display: 'flex',
    width: '100%',
    marginBottom: '30px',
  },
  lumenLeftTable:{
    width: '30%',
    position: 'relative',
    '& p':{
      fontSize: '20px',
      fontWeight: '300',
      fontFamily: 'MuseoSans-500',
      paddingTop: '20px',
      margin: '0 0 10px',
    },
    '& table':{
      width: '100%',
      fontSize: '18px',    
      border: '1px solid #000',
      padding: '10px',
      fontWeight: '300',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr':{
        border: '1px solid #000',
        '& th':{
          border: '1px solid #000',
          textAlign: 'center',
          padding:'0px',
          fontFamily: 'MuseoSans-300',
        },
        '& td':{
          border: '1px solid #000',
          textAlign: 'center',
          padding:'0px',
        }
      },
    },
  },
  lumenRightTable:{
    width: '30%',
    position: 'relative',
    paddingLeft: '8%',    
    '& p':{
      fontSize: '20px',
      fontWeight: '300',
      fontFamily: 'MuseoSans-500',
      paddingTop: '20px',
      margin: '0 0 10px',
    },
    '& table':{
      width: '285px',
      fontSize: '18px',    
      border: '1px solid #000',
      padding: '10px',
      fontWeight: '300',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr':{
        border: '1px solid #000',
        '& th':{
          border: '1px solid #000',
          textAlign: 'center',
          padding:'0px',
          fontFamily: 'MuseoSans-300',
        },
        '& td':{
          border: '1px solid #000',
          textAlign: 'center',
          padding:'0px',
        }
      },
    },
    '&::before':{
      backgroundImage: `url(${arrowMark})`,
      position: 'absolute',
      backgroundRepeat:'no-repeat',
      width: '62px',
      height: '62px',
      left: '10px',
      content: '""',
      top: '55px',
    },
    '&::after':{
      backgroundImage: `url(${arrowMark})`,
      position: 'absolute',
      backgroundRepeat:'no-repeat',
      width: '62px',
      height: '62px',
      left: '10px',
      content: '""',
      top: '340px',
    },
  },
  boldText:{
    '& td':{     
      fontFamily: 'MuseoSans-500',
      fontWeight: '400',
    },
  },
  lumenButton:{
    background: '#f89b22',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '18px',
    borderRadius: '4px',
    padding: '10px 20px',
    display: 'inline-block',
    fontWeight: '400',
    border: '2px solid #f89b22',
    marginTop: '10px',
    fontFamily:'inherit',
    cursor: 'pointer',    
    boxSizing:'border-box',
    textTransform:'uppercase',
    '&:hover':{
      border: '2px solid #d47c0a',
    }
  },
  greenBackground:{
    '& td':{
      background: '#c5e0b4',
    },
  },
  virtualBlocks:{
    display:'flex',
    '& table':{
      '&:nth-child(1)':{
        paddingRight: '15px',
      },
      '&:nth-child(2)':{
        paddingLeft: '15px',
      },
    },
  },
  virtualTable:{
    width: '50%',
    borderSpacing: '26px 10px',
    borderCollapse: 'separate',  
    fontSize: '21px',
    borderRadius: '5px', 
    margin:'0 auto',
    tableLayout: 'fixed',
    maxWidth: '100%',
    boxSizing:'border-box',
    '& i':{
      position: 'absolute',
      right: '-54px',
      top: '0',
      height: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      '& img':{
        verticalAlign:'middle',
        maxWidth: '23px',
        border: '0',
      },
    },
    '& th':{
      padding: '8px',
      borderRadius: '5px',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      position: 'relative',
      lineHeight: '1.42857143',
      border: 'none',
      fontFamily: 'MuseoSans-300',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontWeight: '400',
      boxSizing:'border-box',
      color:'#fff',
      fontSize: '17px',
      '&:nth-child(1)':{
        background: '#7f7f7f',
      },
      '&:nth-child(2)':{
        background: '#4472c4',
      },
    },   
    '& tbody':{
      '& tr':{
        height:'58px',    
        '& td':{
          height:'50px',
          lineHeight:'1.2',
          '&:nth-child(1)':{
            height:'58px',  
            lineHeight: '1.2',
            display: 'flex',
             textAlign: 'left',
             color: '#000',
             background: '#d9d9d9',            
             alignItems: 'center',
             '& img':{
              width: '35px',
              display: 'inline-block',
              paddingRight: '5px',
              verticalAlign: 'middle',
              boxSizing:'border-box',
            },
          },
        },
      },
    },
    '& tr':{     
      '& td':{        
        '&:nth-child(2)':{
          background: '#d3d8eb',
          color: '#000',
        },
        fontSize: '17px',
        padding: '8px',
        lineHeight: '1.42857143',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        position: 'relative',
        border: 'none',
        fontFamily: 'MuseoSans-300',
        boxSizing:'border-box',
        textAlign:'center',
        '& div':{
          position: 'absolute',
          background: '#d9d9d9',
          padding: '8px 20px',
          fontSize: '18px',
          height: 'inherit',
          fontFamily: 'MuseoSans-300',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          left: '-60px',
          boxSizing:'border-box',
          '& span':{
            fontFamily: 'MuseoSans-300',
            fontSize: '18px',             
          },
        },
        '& span':{
          display: 'block',
          width: '100%',
        },
      },
    }, 
    '& tfoot':{
      '& tr':{
        '& td':{          
          '&:nth-child(1)':{
            background: '#7f7f7f',
            color: '#fff !important',
          },
          '&:nth-child(2)':{
            background: '#4472c4',
          color: '#fff !important',
          },
        },
      },
    },
  },
  virtualRight:{
    '& tr':{
      '& th':{       
          background: '#658e49 !important',
      }, 
      '& td':{
        background: '#c5e0b4 !important',
        color: '#000',
        '&:nth-child(1)':{
          textAlign: 'center !important',
          display: 'table-cell !important',
        },
      },    
    },
    '& tfoot':{
      '& tr':{
        '& td':{          
          background: '#658e49 !important',
          color: '#fff',
        },
      },
    },
  },
  plusBracket:{
    position: 'absolute',
    right: '-100px',
    top: '40px',
    transform: 'scale(.7)',
    '& span':{
      paddingRight: '10px',
      '& img':{
        maxWidth: '30px',
        verticalAlign:'middle',
        border: '0',
      },
    },
  },
  equalIcon:{
    position: 'absolute',
    right: '-80px',
    top: '140px',
    '& img':{
      maxWidth: '20px',
    },
  },
  bracketTable:{
    position:'relative',
  },
  redText:{
    color: 'red !important',
  },
  blueCircleImage:{
    position: 'absolute',
    width: '35px',
    top: '-5px',
    right: '0',
  },
  tableImage:{
    position:'relative',
    '& img':{
      position: 'absolute',
      width: '35px',
      top: '-5px',
      right: '0',
    },
  },
  alignImage:{
    verticalAlign:'middle !important',
  },
  virtualNewTable:{
    borderCollapse: 'separate',
    borderSpacing: '30px 10px',
    fontSize: '21px',
    borderRadius: '5px',
    width: '100%',
    margin: '0 auto',
    '& tr':{
      '& th':{
        '&:nth-child(3)':{
          background: '#658e49',
        },
        '&:nth-child(4)':{
          background: '#658e49',
        },
      },
    },
      '& tbody':{
        '& tr':{
          '& td':{
            '&:nth-child(3)':{
              background: '#c5e0b4',
              color: '#000',
            },
            '&:nth-child(4)':{
              background: '#c5e0b4',
              color: '#000',
            },
          },
        },
      }, 
      '& tfoot':{
        '& tr':{
          '& td':{
            '&:nth-child(3)':{
              background: '#658e49',
              color: '#fff',
            },
            '&:nth-child(4)':{
              background: '#658e49',
              color: '#fff',
            },
          },
        },
      }, 
  },
  lsTable:{
    margin: '0 auto',
    width: '70%',
  },
  mcsSidebar:{
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '4vh .5vh 0 0',
    opacity: '1',
    visibility: 'visible',
    top: '0',
    boxSizing:'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '&::before':{
      content: '""',
      position: 'absolute',
      top: '0',
      left: '-100vh',
      height: '100%',
      width: '100vh',
    },
  },
  mcsClose:{
    position: 'absolute',
    top: '5px',
    right: '8px',
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    '&::before, &::after':{
      position: 'absolute',
      left: '8px',
      content: '""',
      height: '18px',
      width: '2px',
      backgroundColor: '#333',      
    },
    '&::before':{
      transform: 'rotate(45deg)',
      WebkitTransform: 'rotate(45deg)',
    },
    '&::after':{
      transform: 'rotate(-45deg)',
      WebkitTransform: 'rotate(-45deg)',
    },
  },
  mcsLevel:{
    width: '150px',
    textDecoration: 'underline',
    position: 'absolute',
    top: '3px',
    left: '11px',
    cursor: 'pointer', 
    height: '20px',
    display: 'inline-block',
    '& a':{
      textDecoration: 'none',
      verticalAlign: 'top',
      position: 'relative',
      color: '#333',
      transition: 'all 0.5s ease 0s',
      '&:hover':{
        color: '#eca041',
      },
    },
  },
  mcsLogoImageBlue:{
    width: '200px',
    margin: '0 auto',
    padding: '0',
    '& a':{
      textDecoration: 'none',
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      padding: '0 0 15px',
      transition: 'unset',
      cursor: 'default',
    },
    '& img':{
      border: 'none',
      maxWidth: 'inherit',
      width:'100%',
    },
  },
  subTitle:{
    marginTop: '0',
    marginBottom: '10px',
    fontSize: '28px',
    textAlign: 'center',
    fontWeight: '300',
    color: '#0069aa',
    lineHeight: '1.1',
    fontFamily:'inherit',
  },
  mcsContent:{
    padding: '0 .5vh 0 4.9vh',
    position: 'relative',   
  },
  activityList:{
    overflow: 'auto',
    height: '66vh',
    padding: '0 0 32px 0',
    margin: '0',
    display: 'block',
    listStyle:'none',
    boxSizing:'border-box',
    '& li':{
      display: 'block',
      padding: '0 0 .53vh',
      margin: '0',
      lineHeight: '3vh',
      '@media (max-width: 3500px)': {
        padding: '0 0 .53vh',
        lineHeight: '2.4vh',
      },
      '@media (max-width: 1400px)': {
        padding: '0 0 .53vh',
        lineHeight: '3vh',
      },
      '& ul':{
        padding: '0.7vh 0 0vh 0',
        margin: '0',
        display: 'block',
        '& li':{
          padding: '0 0 .7vh !important',
          display: 'block',       
          margin: '0',
          lineHeight: '3vh',
          '@media (max-width: 3500px)': {
            padding: '0 0 .53vh',
            lineHeight: '2.4vh',
          },
          '@media (max-width: 1400px)': {
            padding: '0 0 .53vh',
            lineHeight: '3vh',
          },         
        },
      },
    },
  },
  subLevel:{
    color: '#333',
    marginTop: '30px',
    marginBottom: '15px',
    fontWeight: '300',
    fontSize: '18px',
    fontFamily: 'inherit',
    lineHeight: '1.1',
    '& img':{
      width: '30px',
      height: '30px',
      marginRight: '8px',
      verticalAlign:'middle',
    },
  },
  flexMenu:{
    display: 'flex',
    paddingLeft: '35px',   

  },
  menuCheck: {
    left: '0',
    color: 'green !important',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '-3px',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1.1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  flexSpan:{
    width: '18px',
    display: 'inline-block',
    verticalAlign: 'top',
    color: '#0069aa',
    margin: '0 5px 0 0',
    fontFamily: 'MuseoSans-300',
    transition: 'unset',
    boxSizing: 'border-box',
  },
  anchorList:{
    textDecoration: 'none',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block',
    color: '#333',
    fontFamily: 'MuseoSans-100',
    margin: '0',
    transition: 'unset',
    border: '0',
    outline: '0',           
    '@media (max-width: 3500px)': {
      fontSize: '1.58vh',
      lineHeight: '1.78vh',
    },
    '@media (max-width: 1400px)': {
      fontSize: '2.08vh',
      lineHeight: '2.08vh',
    },
    '&:hover':{
      color: '#eca041',
      '& span':{
        '&:first-child':{
          color: '#eca041 !important',
        },
      },
    },
  },
});

export default styles;
