const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  csBox: {
    background: '#fff',
    padding: '35px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px',
    },
  },
  blueBorder: {
    border: '2px solid #0069aa',
    padding: '0',
  },
  leftPanel: {
    background: '#e7f1f9',
    display: 'block',
  },
  extraLoanTable: {
    width: '100%',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      boxSizing: 'border-box',
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '17px',
            minHeight: '34px',
            lineHeight: '24px',
            padding: '0px 15px',
            fontFamily: '"MuseoSans-300"',
            color: '#000',
            [theme.breakpoints.down( 'sm' )]: {              
              fontSize: '15px',
              padding: '0 7px',
            },
            '& b': {
              fontFamily: '"MuseoSans-500"',
              fontWeight: 'normal',
            },
          },
          '& th': {
            paddingTop: '15px',
            paddingLeft: '15px',
            textAlign: 'left',
            color: '#000',
            '@media screen and (min-width: 701px) and (max-width: 992px)': {
              paddingLeft: '7px',
            },
            '@media (max-width: 700px)': {
              paddingLeft: '5px',
            },      
            '& h3': {
              margin: '0',
              maxWidth: '300px',
              fontSize: '17px',
              fontFamily: '"MuseoSans-500"',
              fontWeight: '600',
              lineHeight: '1.1',
              '@media (max-width: 700px)': {
                fontSize: '14px',
              },
              '@media screen and (min-width: 701px) and (max-width: 992px)': {
                fontSize: '13px',
              },
            },
          },
        },
      },
    },
  },
  repaymentTable: {
    background: '#f2f2f2',
  },
  emptyData: {
    height: '10px',   
  },
  fieldData: {
    display: 'flex',
    height: '45px',
    border: 'none',
    alignItems: 'center',
    position: 'relative',
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
    '& input': {
      padding: '0 5px 0px 17px !important',
      height: '45px',
      boxSizing: 'border-box',
      width: '100%',
      border: 'none',
      background: '#fff',
      fontSize: '17px',
      fontFamily: 'inherit',
    },
  },
  percentField: {
    '& input': {
      padding: '0px 5px 0px 7px !important',
    },
    '& span': {
      left: 'auto !important',
      right: '7px !important',
    },
  },
  extstingTable: {
    borderRight: '2px solid #0069aa',
    padding: '0',
    verticalAlign: 'top',
    '@media (max-width: 700px)': {
      display: 'block',
      borderRight: 'none',
    },
    '& table': {
      tableLayout: 'fixed',
    },
  },
  timeSavings: {
    borderRight: 'none',
  },
  noField: {
    padding: '0px 15px !important',
    marginLeft: '10px',
  },
  inputData: {
    boxSizing: 'border-box',
    position: 'relative',
    maxWidth: '54%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {   
      maxWidth: '100%',
    },
    '& span': {
      position: 'absolute',
      left: '7px',
      top: 'inherit',
      marginTop: '0',
      zIndex: '1',
      color: '#000',
    },
  },
  borderTop: {
    borderTop: '2px solid #0069aa',
    '& td': {
      paddingTop: '15px !important',
    },
  },
  negativeValue: {
    color: '#f00 !important',
  },
  graphRow: {
    marginTop: '0',
    position: 'relative',
    width: '100%',
    borderTop: '2px solid #0069aa',
    '& h3': {
      marginBottom: '0',
      color: theme.palette.common.pureBlack,
      fontWeight: '600',
      fontSize: '22px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {        
        fontSize: '16px',
      },
    },
  },
  graphImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
  },
  emptyInput: {
    '@media (max-width: 767px)': {
      height: '0px',
      lineHeight: '0px',
      minHeight: '0px',
    },
    '& td': {
      '@media (max-width: 767px)': {
        height: '0px',
        lineHeight: '0px !important',
        minHeight: '0px !important',
        padding: '0px',
      },
    },
  },
  makeTable: {
    '@media (max-width: 767px)': {       
      marginBottom: '0px !important',
    },
    '& th': {
      '& h3': {
        '@media (max-width: 767px)': { 
          paddingTop: '0px',    
        },
      },
    },
  },
} );

export default styles;
