import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const SpendingSmart = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Spending Smart</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/spending-smart.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Spending Smart
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/spending-smart-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The most effective strategy for building wealth and
                          reaching financial freedom is actually remarkably
                          simple. The secret? Spend less than you earn.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          If you spend less than you earn, that means you have
                          money left over for savings and investments. Those
                          savings and investments will grow over time. But how
                          do you protect yourself from spending stupid?
                          Here&apos;s a simple strategy.
                        </p>
                        <p>
                          Determine what you earn per one hour of work. For
                          example, let&apos;s say Mike makes $65,000 per year.
                          The average work year is 2,000 hours. Mike&apos;s
                          hourly wage can be calculated as follows:
                        </p>
                        <p
                          className={classNames(
                            classes.grayBox,
                            classes.grayboxMargin,
                          )}>
                          $65,000 salary ÷ 2,000 hours worked = $32.50 per hour
                          before taxes
                        </p>
                        <p>
                          If Mike spends one hour at work, he earns $32.50 per
                          hour. But that $32.50 per hour is before taxes.{' '}
                          <strong>
                            We have to always plan with after-tax dollars.
                          </strong>
                        </p>
                        <p>
                          Let&apos;s assume Mike pays 20% in{' '}
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            income taxes
                          </Link>{' '}
                          and calculate his after-tax wage per hour:
                        </p>
                        <p
                          className={classNames(
                            classes.grayBox,
                            classes.grayboxMargin,
                          )}>
                          $32.50 per hour x (1 - 20% income taxes) = $26.00 per
                          hour after taxes
                        </p>
                        <p>
                          This is the first step to understanding what your time
                          is worth, which is important. The next step is to
                          compare a product or service you are thinking about
                          purchasing. Mike is considering buying new noise
                          cancelling headphones that cost $520. But how many
                          hours of work do those headphones cost? Let&apos;s
                          find out.
                        </p>
                        <p
                          className={classNames(
                            classes.grayBox,
                            classes.grayboxMargin,
                          )}>
                          $520 headphones ÷ $26.00 per hour after taxes = 20
                          hours of work
                        </p>
                        <p>
                          The headphones cost 20 hours of work for Mike, or two
                          and a half eight-hour days. Are the headphones still
                          worth it? Can Mike justify the purchase?
                        </p>
                        <p>
                          When looking at your spending, start to ask yourself,
                          &quot;How many hours do I need to work to pay for
                          this? Is that justified?&quot; This simple strategy
                          can help you spend smart.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
SpendingSmart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpendingSmart);
