import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CaresActUnemploymentBenefits = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>CARES Act Unemployment Benefits</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/cares-unemployment.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      CARES Act Unemployment Benefits
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/cares-unemployment-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p>
                          In addition to{' '}
                          <Link
                            to="/finance-explained/federal-student-loan-relief"
                            target="_blank">
                            federal student loan relief
                          </Link>{' '}
                          and a one-time{' '}
                          <Link
                            to="/finance-explained/cares-act-tax-credit"
                            target="_blank">
                            tax credit
                          </Link>
                          , the stimulus package also provides expanded
                          unemployment benefits. Let&apos;s look at the changes
                          and understand how much, for how long, and who
                          qualifies.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <b>How unemployment works</b>
                          <br />
                          There are two main requirements for qualifying for
                          unemployment. You must be unemployed due to no fault
                          of your own, such as being laid off. If you quit your
                          job or get fired for cause, you do not qualify.
                          Secondly, you have to meet your state&apos;s minimum
                          requirement for time worked. If you get laid off from
                          a full-time job, you most likely qualify.
                        </p>
                        <p>
                          Unemployment is processed at the state level. If
                          you&apos;re laid off and want to file for unemployment
                          while you look for a new job, you should contact your
                          state&apos;s unemployment office to learn more.
                        </p>
                        <p>
                          CARES Act Unemployment Benefits The CARES Act adds
                          $600 per week to your state&apos;s unemployment
                          benefits. Let&apos;s look at an example to understand
                          what this means. Jake was working in financial
                          services earning $50,000 per year, but recently got
                          laid off due to the coronavirus. He lives in New
                          Jersey and files for unemployment. New Jersey{' '}
                          <a
                            href="https://www.myunemployment.nj.gov/before/about/calculator/"
                            target="_blank"
                            rel="noopener noreferrer">
                            calculates Jake&apos;s unemployment benefits at 60%
                            of his weekly wage up to a maximum of $713 per week
                          </a>
                          .
                        </p>
                        <p className={classes.spText}>
                          <span>
                            Estimated New Jersey Unemployment Benefit for Jake
                          </span>
                          <span>
                            $50,000 annual income / 50 weeks = $1,000 weekly
                            wage
                          </span>
                          <span>
                            $1,000 weekly wage * 60% = $600 unemployment benefit
                            from New Jersey
                          </span>
                        </p>
                        <p>
                          With the new CARES Act, the federal government adds
                          $600 and the unemployment benefit for Jake becomes
                          $1,200 per week. Also, when state benefits stop after
                          26 weeks, the federal government will fund an
                          additional 13 weeks of unemployment benefits through
                          December 31, 2020.
                        </p>
                        <p>
                          <b>Self-Employed Individuals</b>
                          <br />
                          In the past, self-employed individuals who lose work
                          due to no fault of their own did not qualify for
                          unemployment benefits. Under the new CARES Act,
                          self-employed individuals may qualify. For example, if
                          you&apos;re a freelance graphic artist and you can no
                          longer get work due to the coronavirus, contact your
                          state&apos;s unemployment office. You may qualify for
                          benefits until you find work.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CaresActUnemploymentBenefits.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CaresActUnemploymentBenefits);
