import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import { percentFormatWithZeroDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';

const followersMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const ScholarshipGrants = ( props ) => {
  const {
    classes, inputData, handleDefinationPopup, updateData
  } = props;

  const [inputs, setInputs] = React.useState();

  useEffect(()=>{
    let inputValues = {...inputs};
    inputValues['yearly_scholarship_percentage'] =  inputData.yearly_scholarship_percentage !== undefined ? inputData.yearly_scholarship_percentage : 0;
    inputValues['yearly_scholarship_amount'] =  inputData.yearly_scholarship_amount !== undefined ? inputData.yearly_scholarship_amount : 0;

    inputValues['yearly_grant_percentage'] =  inputData.yearly_grant_percentage !== undefined ? inputData.yearly_grant_percentage : 0;
    inputValues['yearly_grant_amount'] =  inputData.yearly_grant_amount !== undefined ? inputData.yearly_grant_amount : 0;
    const totalAttendance =  moduleServices.module6Data.educationTotalAttendance/inputData.years_diff;

    if(inputData.yearly_scholarship_percentage === undefined && inputData.yearly_scholarship_amount !== undefined && inputData.years_diff > 0) {
      if(totalAttendance > 0) {
        let scholarshipsSlider = (inputValues['yearly_scholarship_amount']/totalAttendance)*100;
        inputValues['yearly_scholarship_percentage'] = scholarshipsSlider; 
      }
    }
    if(inputData.yearly_grant_percentage === undefined && inputData.yearly_grant_amount !== undefined && inputData.years_diff > 0) {
      if(totalAttendance > 0) {
        let scholarshipsSlider = (inputValues['yearly_grant_amount']/totalAttendance)*100;
        inputValues['yearly_grant_percentage'] = scholarshipsSlider; 
      }
    }
    setInputs(inputValues);
  },[]);

  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputs};
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;
    const totalAttendance =  moduleServices.module6Data.educationTotalAttendance/inputData.years_diff; 
    if(field === 'yearly_scholarship_percentage') {
      if(inputData.years_diff > 0) {
         inputValues['yearly_scholarship_amount'] = (totalAttendance/100)*inputValues[field];
      } else {
        inputValues['yearly_scholarship_amount'] = 0;
      }
    } else if(field === 'yearly_scholarship_amount') {
      if(totalAttendance < inputValues['yearly_scholarship_amount']) {
        inputValues['yearly_scholarship_amount'] = totalAttendance;
      }
      if(inputData.years_diff > 0 && totalAttendance > 0) {
         inputValues['yearly_scholarship_percentage'] = (inputValues['yearly_scholarship_amount']/totalAttendance)*100;
      } else {
        inputValues['yearly_scholarship_percentage'] = 0;
      }
    } else if(field === 'yearly_grant_percentage') {
      if(inputData.years_diff > 0) {
         inputValues['yearly_grant_amount'] = (totalAttendance/100)*inputValues[field];
      } else {
        inputValues['yearly_grant_amount'] = 0;
      }
    } else if(field === 'yearly_grant_amount') {
      if(totalAttendance < inputValues['yearly_grant_amount']) {
        inputValues['yearly_grant_amount'] = totalAttendance;
      }
      if(inputData.years_diff > 0 && totalAttendance > 0) {
         inputValues['yearly_grant_percentage'] = (inputValues['yearly_grant_amount']/totalAttendance)*100;
      } else {
        inputValues['yearly_grant_percentage'] = 0;
      }
    } 
    setInputs(inputValues);

    updateData( 'total_obj' ,{...inputData,...inputValues} );
  };

  return (
    <div className={ classes.contentBlock }>

      {/*Career School*/}
      {
        (inputData.type_of_higher_education === 1) && (<p>Now that we&apos;ve calculated your total cost of attendance for career school, let&apos;s build a plan to pay for that education starting with scholarships and grants. Try to maximize scholarships and grants. Why? Because, unlike student loans, they don&apos;t need to be repaid.</p>)
      }
      {/*College*/}
      {
        (inputData.type_of_higher_education === 2) && (
          <React.Fragment>
            <p>
              Now that we&apos;ve calculated the total amount of money you need for college, let&apos;s build a plan to pay for that education starting with scholarships and grants. Try to maximize scholarships and grants. Why? Because, unlike student loans, they don&apos;t need to be repaid. That’s why scholarships and grants are also referred to as Gift Aid.

            </p>

            <p>
              Check out these free resources to search for scholarships:
               <a rel="noopener noreferrer goingmerry" href="https://www.goingmerry.com/" target="_blank" className={ classes.textBlue }>Going Merry</a> and
               <a rel="noopener noreferrer go" href="https://studentaid.ed.gov/sa/types/grants-scholarships/finding-scholarships" target="_blank" className={ classes.textBlue }>Federal Student Aid.</a>

            </p>
          </React.Fragment>
        )
      }
      
      {/*Graduate School*/}
      {
        (inputData.type_of_higher_education === 3) && (<p>Now that we&apos;ve calculated your total cost of attendance for graduate school, let&apos;s build a plan to pay for that education starting with scholarships and grants. Try to maximize scholarships and grants. Why? Because, unlike student loans, they don&apos;t need to be repaid.</p>)
      }

      {/*Military School*/}
      {
        (inputData.type_of_higher_education === 4) && (<p>Now that we've calculated your total cost of attendance for military school, let's build a plan to pay for that education starting with scholarships and grants. Try to maximize scholarships and grants. Why? Because, unlike student loans, they don't need to be repaid.</p>)
      }

      {inputData.school !== 'new' && inputData.type_of_higher_education !== 3 && (
        <p className={ classes.topGapNew }>
          <i>
            Selected School:
            {' '}
            {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          </i>
        </p>
      )}

      {inputData.school !== 'new' && inputData.type_of_higher_education !== 3 && (
        <div className={ `${classes.giftSources} ${classes.giftSourcesNew}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead>

              <th>Gift Aid by Source</th>
              <th>
                % of Students
                <br />
                {' '}
                Receiving Aid
              </th>
              <th>
                Average Amount of Aid
                <br />
                {' '}
                per Student per Year
              </th>
            </thead>
            <tbody>
              <tr>
                <td>Federal</td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.grant_aid_percentage !== undefined) ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale value={ inputData.school_details.grant_aid_percentage } displayType="text" thousandSeparator suffix="%" />
                    ):('0.00%')
                  }                
                 </td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.grant_aid_average_amount !== undefined) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.grant_aid_average_amount } displayType="text" thousandSeparator prefix="$" />
                    ):('$0')
                  }   
                </td>
              </tr>
              <tr>
                <td>State / Local</td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.percentage_state_local_grant_aid !== undefined) ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale value={ inputData.school_details.percentage_state_local_grant_aid } displayType="text" thousandSeparator suffix="%" />
                    ):('0.00%')
                  }
                </td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.average_state_local_grant_aid !== undefined) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.average_state_local_grant_aid } displayType="text" thousandSeparator prefix="$" />
                    ):('$0')
                  }   
                </td>
              </tr>
              <tr>
                <td>School</td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.percentage_institutional_grant_aid !== undefined) ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale value={ inputData.school_details.percentage_institutional_grant_aid } displayType="text" thousandSeparator suffix="%" />
                    ):('0.00%')
                  }
                </td>
                <td>
                  {
                    (inputData.school_details !== undefined && inputData.school_details.average_institutional_grant_aid !== undefined) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.average_institutional_grant_aid } displayType="text" thousandSeparator prefix="$" />
                    ):('$0')
                  }   
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      )}
      {inputs !== undefined &&  inputs !== '' && inputs.yearly_scholarship_percentage !== undefined && (
        <div className={ classes.innnerFlowGroupMainFull }>
          <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleScholar" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Scholarship' ) }>Scholarships</span></p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.yearly_scholarship_percentage }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithZeroDecimal }
                        min={ 0 }
                        max={ 100 }
                        step={ 1 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'yearly_scholarship_percentage', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <h3>Annual</h3>
                  <div className={ classes.inputRightBlock }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.yearly_scholarship_amount } onChange={ ( e ) => updateValue( e, '', 'yearly_scholarship_amount', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <h3>
                    Total (
                    {inputData.years_diff}
                    {' '}
                    years)
                  </h3>
                  <div className={ classes.inputRightBlock }>
                    {(inputs.yearly_scholarship_amount !== undefined && inputData.years_diff !== undefined &&  inputs.yearly_scholarship_amount > 0 && inputData.years_diff > 0) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputs.yearly_scholarship_amount*inputData.years_diff } displayType="text" thousandSeparator prefix="$" />
                    ): ('$0') 
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleScholar" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grant' ) }>Grants</span></p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.yearly_grant_percentage }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithZeroDecimal }
                        min={ 0 }
                        max={ 100 }
                        step={ 1 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'yearly_grant_percentage', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.yearly_grant_amount } onChange={ ( e ) => updateValue( e, '', 'yearly_grant_amount', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <div className={ classes.inputRightBlock }>
                    {(inputs.yearly_grant_amount !== undefined && inputData.years_diff !== undefined &&  inputs.yearly_grant_amount > 0 && inputData.years_diff > 0) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputs.yearly_grant_amount*inputData.years_diff } displayType="text" thousandSeparator prefix="$" />
                    ): ('$0') 
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip id="definationTitleScholar" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}`} />
        </div>
      )}

      
      

    </div>
  );
};

ScholarshipGrants.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( ScholarshipGrants );
