import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-vacation/styles';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import { timeline, sortTimeLine } from '../../calculations/time-line';
import * as modulesServices from '../../calculations/modules-services';
import _ from 'lodash';

const SpecialPurchaseTimelineOne = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;


  const [vacationStartYear, setVacationStartYear] = React.useState( (inputData.second_start_year === undefined && inputData.second_start_year !== null ) ? 0 : inputData.second_start_year );
  const [vacationEndYear, setVacationEndYear] = React.useState( (inputData.second_end_year === undefined && inputData.second_end_year !== null) ? 0 : inputData.second_end_year );
  const [yearStartOptions, SetYearStartOptions] = React.useState( [] );
  const [yearEndOptions, SetYearEndOptions] = React.useState( [] );
  const [purchaseItem, setPurchaseItem ] = React.useState( inputData.purchase_item === undefined ? '' : inputData.purchase_item );
  
  const updateTimeLine = (startYearVal) => {
    const years = [];
    years.push(startYearVal);
    if(inputData.start_year !== undefined && inputData.start_year !== null && inputData.start_year !== '' && parseInt(inputData.start_year, 10) > 0) {
      years.push(inputData.start_year);
    }
    if(inputData.third_start_year !== undefined && inputData.third_start_year !== null && inputData.third_start_year !== '' && parseInt(inputData.third_start_year, 10) > 0) {
      years.push(inputData.third_start_year);
    }
    var start_year = '';
    if(years.length > 0) {
        start_year = Math.min.apply(Math,years);
    }
    let timelineData = [...timeline];
    const index = _.findIndex( timeline, { selectedModule: 'module11' } );
    if(index >= 0 && start_year !== '' && start_year > 0) {
      timelineData[index].startYear = start_year;
      timelineData[index].endYear = '';
      timelineData[index].imageSlug = 'active';
      timelineData[index].timelineOrder = start_year;
      sortTimeLine(timelineData);
    }
  }


  useEffect(()=>{
    let start_year = parseInt(modulesServices.module1Data.start_year);

    if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        start_year = vacationStartYear;
    }
    if(start_year <=  0) {
      start_year = new Date().getFullYear();
    }
    const vacationEndYearOptions = [0];
    for (let i = start_year; i < parseInt(modulesServices.module1Data.start_year)+15; i+=1) {
        vacationEndYearOptions.push(i);
    }
    let endYearValue = vacationEndYear;
    SetYearEndOptions(vacationEndYearOptions);
    if(vacationEndYear !== undefined && vacationEndYear !== '' && vacationEndYear > 0) {
      if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        const endIndex = vacationEndYearOptions.indexOf(parseInt(vacationEndYear, 10));
        if(endIndex === -1) {
          endYearValue = vacationEndYearOptions[1];
        }
      }
    } else {
      endYearValue = 0;
    }
    setVacationEndYear(endYearValue);

    let inputDataValues = {...inputData};
    inputDataValues['second_start_year'] = vacationStartYear;
    inputDataValues['second_end_year'] = endYearValue;
    inputDataValues['purchase_item'] =  purchaseItem;
    updateData('total_obj',inputDataValues);

    updateTimeLine(vacationStartYear);
    // eslint-disable-next-line
  },[vacationStartYear])

  useEffect( () => {
    const vacationStartYearOptions = [0];
    for (let i = parseInt(modulesServices.module1Data.start_year, 10); i < parseInt(modulesServices.module1Data.start_year, 10)+15; i+=1) {
        vacationStartYearOptions.push(i);
    }
    SetYearStartOptions(vacationStartYearOptions);
    // eslint-disable-next-line
  }, [] );

  const handleSpclPurchase = ( event ) => {
    setPurchaseItem(event.target.value);
    updateData('purchase_item' , event.target.value);    
  };

  const handleVacationStartYear = ( event ) => {
    setVacationStartYear( parseInt(event.target.value, 10) );
  };

  const handleVacationEndYear = ( event ) => {
    let vacationEnd = parseInt(event.target.value, 10);
    if(vacationStartYear !== 'undefined' && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0 ) {
        if(vacationStartYear > 0 && vacationStartYear > vacationEnd) {
             vacationEnd = vacationStartYear;
        }
    } else {
      vacationEnd = 0;
    }
    updateTimeLine(vacationStartYear);
    setVacationEndYear(vacationEnd);
    updateData( 'second_end_year', vacationEnd );
  };


  return (
    <div className={ classes.contentBlock }>
      <p>We all have things that we like to buy each year or hobbies that we enjoy. These things and hobbies cost money and you have to make sure you account for them in your career sketch. Why? They make you happy and you want to make sure you budget for the stuff that brings you joy!</p>
      <p>What&apos;s your special purchase that you plan to make each year? Do you enjoy watches or jewelry? Dresses and suits? What about a hobby such as painting, recording new songs, or playing golf? Put a name to your special purchase and set the timeline below.</p>
      <div className={ classes.grayBox }>
        <div className={ classes.specialPurchase }>
          <span>
            <span className={ classes.spanBlock } >Name of Special Purchase:</span>
            <input type="text" value={ purchaseItem } onChange={ handleSpclPurchase } />
          </span>
        </div>
        <ul className={ classes.yearsList }>
          <li>
            <span>
              I plan to start making this special purchase in year
              {' '}
            </span>
            <select value={ vacationStartYear } onChange={ handleVacationStartYear }>
              {yearStartOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> )}
            </select>
            .
          </li>
          <li>
            <span>
              I plan to stop making this special purchase in year
              {' '}
            </span>
            <select value={ vacationEndYear } onChange={ handleVacationEndYear }>
              { ( yearEndOptions.length > 0 && yearEndOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> ) )}
            </select>
            .
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module11"/>
    </div>
  );
};

SpecialPurchaseTimelineOne.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( SpecialPurchaseTimelineOne );
