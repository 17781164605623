import React from 'react';
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import g1 from '../../assets/img/pwi-vacation/rome_italy1.jpg';
import g2 from '../../assets/img/pwi-vacation/london-england.jpg';
import g3 from '../../assets/img/pwi-vacation/paris-france.jpg';
import g4 from '../../assets/img/pwi-vacation/new-york-city.jpg';
import g5 from '../../assets/img/pwi-vacation/chicago-illinois.jpg';
import g6 from '../../assets/img/pwi-vacation/orlando-florida.jpg';
import g7 from '../../assets/img/pwi-vacation/vail-colorado.jpg';
import g8 from '../../assets/img/pwi-vacation/laketahoe-california.jpg';
import g9 from '../../assets/img/pwi-vacation/maui-hawaii.jpg';
import g10 from '../../assets/img/pwi-vacation/tokyo-japan.jpg';
import g11 from '../../assets/img/pwi-vacation/sydney-australia.jpg';
import g12 from '../../assets/img/pwi-vacation/maasai-mara-kenya.jpg';
import styles from '../../screens/pwi-vacation/styles';

const VacationBottom = ( props ) => {
  const {
    classes,
  } = props;

  const [popupName, setPopupName] = React.useState('');

  const openPopup = (popup) => {
    setPopupName(popup)
  }

  return (
    <div className={ classes.contentBlock }>
      <p>
        Click on the images below to learn more about some of the most popular vacation spots in the U.S. and the world. Ever dreamed of visiting one of these places? Find out what they might cost, and, more importantly, plan to get there in your near future!
      </p>
      <div className={ classes.vacationGallery }>
        <div className={ classes.vacationGalleryRow }>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imageone' ); } }>
                  <figure>
                    <img src={ g1 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Rome,
                          <span>Italy</span>
                        </p>
                        <p>$3,000</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagetwo' ); } }>
                  <figure>
                    <img src={ g2 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          London,
                          <span>England</span>
                        </p>
                        <p>$2,575</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagethree' ); } }>
                  <figure>
                    <img src={ g3 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Paris,
                          <span>France</span>
                        </p>
                        <p>$2,300</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.vacationGalleryRow }>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagefour' ); } }>
                  <figure>
                    <img src={ g4 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>New York City</p>
                        <p>$2,690</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagefive' ); } }>
                  <figure>
                    <img src={ g5 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Chicago,
                          <span>Illinois</span>
                        </p>
                        <p>$2,000</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagesix' ); } }>
                  <figure>
                    <img src={ g6 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Orlando,
                          <span>Florida</span>
                        </p>
                        <p>$2,050</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.vacationGalleryRow }>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imageseven' ); } }>
                  <figure>
                    <img src={ g7 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Vail,
                          <span>Colorado</span>
                        </p>
                        <p>$2,900</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imageeight' ); } }>
                  <figure>
                    <img src={ g8 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Lake Tahoe,
                          <span>California</span>
                        </p>
                        <p>$2,225</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagenine' ); } }>
                  <figure>
                    <img src={ g9 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Maui,
                          <span>Hawaii</span>
                        </p>
                        <p>$3,050</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.vacationGalleryRow }>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imageten' ); } }>
                  <figure>
                    <img src={ g10 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Tokyo,
                          <span>Japan</span>
                        </p>
                        <p>$3,650</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imageeleven' ); } }>
                  <figure>
                    <img src={ g11 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Sydney,
                          <span>Australia</span>
                        </p>
                        <p>$3,200</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
              <div className={ classes.vacationImage }>
                <Link to="/" aria-hidden="true" onClick={ () => { openPopup( 'imagetwelve' ); } }>
                  <figure>
                    <img src={ g12 } alt="" />
                    <div className={ classes.overlayText }>
                      <div className={ classes.overlayPlace }>
                        <p>
                          Maasai Mara,
                          <span>Kenya</span>
                        </p>
                        <p>$3,600</p>
                      </div>
                    </div>
                  </figure>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={ classes.videoModalPopup }>
          <Dialog
            open={ popupName !== '' }
            onClose={ ()=>{setPopupName('')} }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={ classes.pendinglistPopup }
          >
            <DialogContent className={ classes.visitPlaceModal }>
              <div className={ classes.popupClose }>
                <span aria-hidden="true" onClick={ ()=>{setPopupName('')}}>
                  ×
                </span>
              </div>
              {popupName === 'imageone' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Rome, Italy</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>
                            Gaze upon the legendary architecture, monuments, art, and more as you walk the streets of Rome. Spend the day in the Vatican discovering the grandeur of the Sistine Chapel and St. Peter&apos;s Basilica. Enjoy incredible Italian food and culture on every corner.
                          </p>
                          <p><a href="https://www.rome.net/" target="_blank" rel="noopener noreferrer">Explore Rome</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$1,000</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,050</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$600</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$150</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$3,000</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagetwo' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit London, England</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Whether you&apos;re looking to stare in awe at Big Ben or venture the Tower Bridge and cross the famous Thames River, London has plenty of free attractions. Visit the Piccadilly Circus, the Times Square of London, or drop by Westminster Abbey, a legendary medieval church.</p>
                          <p><a href="https://www.visitlondon.com/" target="_blank" rel="noopener noreferrer">Explore London</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$500</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,050</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$75</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$150</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,575</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagethree' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Paris, France</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Tour the city of love and romance. Climb the Eiffel Tower. Pass through the Arc de Triomphe. Explore the French cuisine and visit the 850-year-old Notre Dame cathedral. Art fan? Say no more and visit the Louvre Museum.</p>
                          <p><a href="https://en.parisinfo.com/" target="_blank" rel="noopener noreferrer">Explore Paris</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$450</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$900</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$600</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$150</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,300</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagefour' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit New York City</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>The Big Apple. The city that never sleeps. Spring, summer, winter, or fall, New York has an unlimited number of places to explore. From the Empire State Building to the Statue to Liberty, from Central Park to One World Trade, and from Broadway to underground Comedy Clubs, New York has it all. Every cuisine and all cultures on the planet are at your fingertips.</p>
                          <p><a href="https://www.nycgo.com/" target="_blank" rel="noopener noreferrer">Explore New York City</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$75</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,190</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$875</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$250</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,690</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagefive' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Chicago</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Voted best large city three years in a row (2019, 2018, and 2017), Chicago’s midwestern soul, welcoming residents, and warm vibe make it a must see. From award-winning dining to the magical bean (aka Cloud Gate), head to Chicago for a famous Chicago styled hot dog, deep dish pizza and a river boat cruise that will leave you coming back for more.</p>
                          <p><a href="https://www.choosechicago.com/" target="_blank" rel="noopener noreferrer">Explore Chicago</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,000</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagesix' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Orlando</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Walt Disney World Resort, Universal Studios, Seaworld Orlando, and Legoland are just a few of the attractions that draw tens of million people each year to Orlando, Florida. From theme parks and water parks to world class golf courses, Orlando has it all. Whether you&apos;re thinking family vacation or a long weekend with friends, Orlando is ready for you.</p>
                          <p><a href="https://www.visitorlando.com/en" target="_blank" rel="noopener noreferrer">Explore Orlando</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$600</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$300</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$50</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,050</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imageseven' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Vail</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>
                            Vail in the winter offers some of the best skiing trails in the world. In the summer, Vail transforms into an outdoorsy person’s dream playground. Whether you’re a beginner or expert on the slopes or just looking to dine and shop at the legendary Vail Village, Vail is a must visit in the U.S.

                          </p>
                          <p><a href="https://www.visitvailvalley.com/" target="_blank" rel="noopener noreferrer">Explore Vail</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$350</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,400</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$300</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$50</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,900</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imageeight' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Lake Tahoe, California</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Imagine skiing down the mountain as you stare at the largest alpine lake in North America. With an average 300 days of sunshine each year and snow from November to April, hitting the slopes here is magical. Not a fan of the cold weather? Lake Tahoe has crystal clear emerald water and beaches to sunbathe. Jet ski or snowboard? Tough call.</p>
                          <p><a href="https://visitinglaketahoe.com/" target="_blank" rel="noopener noreferrer">Explore Lake Tahoe</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$300</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$875</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$650</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$300</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$50</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$50</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$2,225</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagenine' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Maui</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>The winter is cold in the land locked states. Head to Maui, Hawaii, and experience the great migration of the humpback whales, magnificent sunrises and sunsets, the freshest farm-to-table cuisine, and much more on the second largest, beautiful Hawaiian island. Relax on beautiful beaches or adventure into the crystal-clear water and scenic mountain trails.</p>
                          <p><a href="https://www.gohawaii.com/islands/maui" target="_blank" rel="noopener noreferrer">Explore Maui</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$800</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,050</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$800</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$3,050</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imageten' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Tokyo</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>You won’t find cherry blossom trees and other blooms anywhere like this in the world. Head to Tokyo and immerse yourself in Japanese culture with the infamous spring Sumo Tournament or a trip to Sensoji Temple. Marvel at the renowned Hachiko Statue built for an Akita dog that waited at the same train station for 9 years after his owner tragically passed. A story that embodies the Japanese culture of enduring loyalty.</p>
                          <p><a href="https://www.gohawaii.com/islands/maui" target="_blank" rel="noopener noreferrer">Explore Tokyo</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$1,500</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,150</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$700</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$3,650</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imageeleven' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Sydney, Australia</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Whether its skiing in the north, beaching in the south, or wine tasting and golfing a couple hours outside the city, Sydney has it all. Ever dream of dining in the world-famous Sydney Opera House or traversing the outback marveling at wildlife in the open plains? What about gazing upon the Blue Mountains from a panoramic cable car? Head to Sydney. You won’t regret it.</p>
                          <p><a href="https://us.sydney.com/" target="_blank" rel="noopener noreferrer">Explore Sydney</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$1,200</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$800</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$800</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$200</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$3,200</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {popupName === 'imagetwelve' && (
                <div className={ classes.visitPlaceModal }>
                  <div className={ classes.visitPlace }>
                    <div className={ classes.placeHeader }>
                      <div className={ classes.modalTitle }>
                        <Typography component="h4" variant="h4">Visit Maasai Mara National Reserve</Typography>
                      </div>
                    </div>
                    <div className={ classes.placeContent }>
                      <div className={ classes.floorDetails }>
                        <div className={ classes.placeDescription }>
                          <h3>Description:</h3>
                          <p>Situated in southwest Kenya, Maasai Mara is one of Africa’s greatest wildlife reserves. Home to Africa’s most diverse, raw, natural, and spectacular ecosystems including big game like lions and cheetahs, Maasai Mara is not for the feint of heart. Ever seen two million wildebeest, zebra, and gazelles migrate in search of greener pastures? Do you want to?</p>
                          <p><a href="https://www.maasaimara.com/" target="_blank" rel="noopener noreferrer">Explore the Safari in Kenya</a></p>
                        </div>
                        <div className={ classes.estimationDetails }>
                          <h3>Estimated Costs:</h3>
                          <table className={ classes.estimationTable }>
                            <tbody>
                              <tr>
                                <td>Flights:</td>
                                <td>$1,100</td>
                              </tr>
                              <tr>
                                <td>Hotels:</td>
                                <td>$1,400</td>
                              </tr>
                              <tr>
                                <td>Food and Drink:</td>
                                <td>$600</td>
                              </tr>
                              <tr>
                                <td>Activities:</td>
                                <td>$250</td>
                              </tr>
                              <tr>
                                <td>Transportation:</td>
                                <td>$100</td>
                              </tr>
                              <tr>
                                <td>Souvenirs / Gifts:</td>
                                <td>$150</td>
                              </tr>
                              <tr>
                                <td><b>Estimated Total Cost of 7-day Trip:</b></td>
                                <td><b>$3,600</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div />
            </DialogContent>
          </Dialog>
        </div>

    </div>
  );
};

VacationBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  getStandardYear: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default withStyles( styles )( VacationBottom );
