import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import { withRouter, Link } from 'react-router-dom';
import styles from './styles';
import PwiSave from '../../components/pwi-save/pwi-save';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/buying-a-car/buyingACar-slide1.jpg';
import sliderImageThree from '../../assets/img/buying-a-car/buyingACar-slide2.jpg';
import leasevsBuyProsandCons from '../../assets/img/buying-a-car/lease_vs_buy_prosandCons.png';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import * as moduleServices from '../../calculations/modules-services';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { defaultValues } from '../../calculations/default-module-values';
import alertIcon from '../../assets/img/alert_icon.png';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import * as carGraphData from '../../graph-services/car-show-me';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';

import scrollToComponent from 'react-scroll-to-component';
import IconBar from './IconBar';


const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

const monthRange = [
  { value: 0, label: 0 },
  { value: 6, label: 6 },
  { value: 12, label: 12 },
  { value: 18, label: 18 },
  { value: 24, label: 24 },
  { value: 30, label: 30 },
  { value: 36, label: 36 },
  { value: 42, label: 42 },
  { value: 48, label: 48 },
  { value: 54, label: 54 },
  { value: 60, label: 60 },
  { value: 66, label: 66 },
  { value: 72, label: 72 },
];

const BuyingACarWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedBuyingACarValues, setUpdatedBuyingACarValues] = useState(defaultValues.module12);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imagePopup, setImagePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [firstCarTradeInEdit, setFirstCarTradeInEdit] = useState('');
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = React.useState('');
  const [yearRange, setYearRange] = React.useState([]);
  const [openInputPopupStatus, setOpenInputPopupStatus] = React.useState({ interest_rate: false, second_interest_rate: false });
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [showMeData, setShowMeData] = React.useState({});
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);

  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);

  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(14);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module12' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedBuyingACarValues(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedBuyingACarValues(defaultValues.module12);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(()=>{
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module12' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }     
  },[loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(()=>{     
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module12' ) {
      dropdownvalues.savedNotes = true;    
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 }); 
    }
  },[myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(carGraphData.carShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top'});
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleImagePopup = (url) => {
    setImagePopup(!imagePopup);
    setImageURL(url);
  };

  const handleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const handleFirstCarTradeEditPopup = () => {
    setFirstCarTradeInEdit(!firstCarTradeInEdit);
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
    if (field === 'interest_rate' || field === 'second_interest_rate') {
      let text = '';
      const popmin = 2;
      const popmax = 12;
      if (openInputPopupStatus[field] === false) {
        const inputPopupStatus = { ...openInputPopupStatus };
        inputPopupStatus[field] = true;
        setOpenInputPopupStatus(inputPopupStatus);
        text = 'Car loan interest rates typically vary from 2 - 12%. Make sure you can support an interest rate outside these boundaries with research.';
      }
      const tempValue = updatedBuyingACarValues[field];
      if (tempValue > 0 && text !== '' && (tempValue < popmin || tempValue > popmax)) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.redoPopup}>
              <div className={classes.redoPopupNew}>
                <h3>Important Tip</h3>
                <div className={classes.NoFiDiv}>
                  <p className={classes.zeroLeftPadding}>{text}</p>
                </div>
              </div>
              <div className={classes.buttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button className={classes.hideBtn} type="button" onClick={onClose}>OK</button>
              </div>
            </div>
          ),
        });
      }
    }
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedBuyingACarValues };
    if (fieldType === 'number') {
      if (field === 'discount_in_percentage' || field === 'rebate' || field === 'down_payment_in_percentage'
        || field === 'sales_tax_in_percentage' || field === 'interest_rate' || field === 'origination_fees'
        || field === 'second_discount' || field === 'second_rebate' || field === 'second_down_payment_in_percentage'
        || field === 'second_sales_tax' || field === 'second_interest_rate' || field === 'second_origination_fees') {
        if (e.floatValue > 100) {
          valuesUpdated[field] = 100;
        } else {
          valuesUpdated[field] = e.floatValue;
        }
      } else {
        valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      }
      console.log("chandrakala",typeof(e.floatValue));
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      if (field === 'financial_gift_end_year') {
        if (updatedBuyingACarValues.financial_gift_start_year <= e.value) {
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = updatedBuyingACarValues.financial_gift_start_year;
        }
      } else if (field === 'financial_gift_start_year') {
        if (updatedBuyingACarValues.financial_gift_end_year >= e.value) {
          valuesUpdated.financial_gift_end_year = updatedBuyingACarValues.financial_gift_end_year;
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = e.value;
          valuesUpdated.financial_gift_end_year = updatedBuyingACarValues.financial_gift_end_year !== 0 ? e.value : 0;
        }
      } else if (field === 'charitable_end_year') {
        if (updatedBuyingACarValues.charitable_start_year <= e.value) {
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = updatedBuyingACarValues.charitable_start_year;
        }
      } else if (field === 'charitable_start_year') {
        if (updatedBuyingACarValues.charitable_end_year >= e.value) {
          valuesUpdated.charitable_end_year = updatedBuyingACarValues.charitable_end_year;
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = e.value;
          valuesUpdated.charitable_end_year = updatedBuyingACarValues.charitable_end_year !== 0 ? e.value : 0;
        }
      } else {
        valuesUpdated[field] = e.value;
      }
    }
    setUpdatedBuyingACarValues(valuesUpdated);
    moduleServices.setModule12Data(valuesUpdated);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module12';
    objectParams.moduleNumber = 'module12Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module12";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module12', 'quick-save', activeScenarioData);
      finalObject['module12Data'] = pwiObject.getfilteredBuyingCarObject(moduleServices.module12Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -130, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 450, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classNames(classes.fiWorksheet, "radioBtnCheck")}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.buyACarSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying a Car
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} >
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your buying a car details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}> Enter My Start Year </span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying A Car
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} xs={6}>
                              <Typography variant="h2" component="h2">
                                Find Your Best Deal
                              </Typography>
                              <ul>
                                <li>Enter your purchase price, down payment and the value of any rebate, discount, trade-in</li>
                                <li>Specify your loan terms and rate; find the best scenario for you</li>
                                <li>Employ competitive market analysis</li>
                                <li>Perform diligent research so you know when you&apos;re getting a good deal.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}> GET STARTED </span>

                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying A Car
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6} xs={6}>
                              <Typography variant="h2" component="h2">
                                Check Spaces, Awareness
                              </Typography>
                              <ul>
                                <li>Consider what type of car is right for your situation, explore your options</li>
                                <li>Assess how your vehicle choice might affect your personal and professional priorities</li>
                                <li>Learn the basics of auto loans</li>
                                <li>Decide how much money to spend on a car as you maximize reward and maximize risk.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}> SEE MY SPACES </span>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

              </Slider>
            </div>
          </div>
        </div>
        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module12" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                  <Link to="/plan-with-inkwiry/car" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>

              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.carDetails) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} 
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes = {classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Leasing a Car</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'leasing_car_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedBuyingACarValues.leasing_car_start_year, value: updatedBuyingACarValues.leasing_car_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect to begin leasing a car. If you are currently leasing a car, select Today's Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>End Year</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Months</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'leasing_car_number_of_years', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedBuyingACarValues.leasing_car_number_of_years, value: updatedBuyingACarValues.leasing_car_number_of_years }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The number of months you expect to lease this vehicle. If this is an existing lease, select the number of months remaining on the lease.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {
                                          moduleServices.module12Data.leasing_car_start_year !== undefined && moduleServices.module12Data.leasing_car_start_year > 0 && moduleServices.module12Data.leasing_car_number_of_years !== undefined && moduleServices.module12Data.leasing_car_number_of_years > 0  ? (
                                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" value={moduleServices.module12Data.leasing_car_start_year + ( moduleServices.module12Data.leasing_car_number_of_years / 12 ) - 1} />
                                          ) : 0
                                        }
                                        {/* {moduleServices.module12Data.leasingCarEndYear !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" value={moduleServices.module12Data.leasingCarEndYear} />
                                        ) : ('0')} */}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_down_payment} onValueChange={(e) => updateValue(e, 'leasing_car_down_payment', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Money that is due at signing. Most leases will require a certain amount of money up front as a down payment for the lease. Making a big down payment will lower your monthly payments. See the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Drive-Off Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_drive_off_fees} onValueChange={(e) => updateValue(e, 'leasing_car_drive_off_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on a car payment for leasing a car. If this is an existing lease, enter your monthly payment. Go to the Boost My Awareness section for more information on how to estimate a lease payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed to Start Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.cashNeededToStartLease !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.cashNeededToStartLease} prefix={moduleServices.module12Data.cashNeededToStartLease >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.cashNeededToStartLease < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Monthly Expenses while Leasing</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Lease Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_lease_payment} onValueChange={(e) => updateValue(e, 'leasing_car_lease_payment', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on a car payment for leasing a car. If this is an existing lease, enter your monthly payment. Go to the Boost My Awareness section for more information on how to estimate a lease payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingCarLeasePaymentCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarLeasePaymentCalc} prefix={moduleServices.module12Data.leasingCarLeasePaymentCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarLeasePaymentCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_maintenance} onValueChange={(e) => updateValue(e, 'leasing_car_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on maintenance for this car. Maintenance includes things like new tires, fixing a cracked windshield, or replacing a flat tire. Average maintenance costs run about $100 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingCarMaintenanceCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarMaintenanceCalc} prefix={moduleServices.module12Data.leasingCarMaintenanceCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarMaintenanceCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_insurance} onValueChange={(e) => updateValue(e, 'leasing_car_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on insurance for this car. Average insurance costs can range from roughly $100 - $200 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>

                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingCarInsuranceCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarInsuranceCalc} prefix={moduleServices.module12Data.leasingCarInsuranceCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarInsuranceCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Gas</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_car_gas} onValueChange={(e) => updateValue(e, 'leasing_car_gas', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on gas for this car. Think about the distance you plan on commuting to work or school and how often you may take longer trips to get a good estimate of your monthly gas expense.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingCarGasCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarGasCalc} prefix={moduleServices.module12Data.leasingCarGasCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarGasCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarTotalMonthlyExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarTotalMonthlyExpenses} prefix={moduleServices.module12Data.leasingCarTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarTotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarTotalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarTotalAnnualExpenses} prefix={moduleServices.module12Data.leasingCarTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarTotalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Leasing a Car Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed to Start Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarDownPaymentSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarDownPaymentSummary} prefix={moduleServices.module12Data.leasingCarDownPaymentSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarDownPaymentSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Lease Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarTotalPaymentSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarTotalPaymentSummary} prefix={moduleServices.module12Data.leasingCarTotalPaymentSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarTotalPaymentSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Other Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarTotalExpensesSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarTotalExpensesSummary} prefix={moduleServices.module12Data.leasingCarTotalExpensesSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarTotalExpensesSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Cost of Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingCarTotalCostsSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingCarTotalCostsSummary} prefix={moduleServices.module12Data.leasingCarTotalCostsSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingCarTotalCostsSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Buying a Car</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'purchase_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedBuyingACarValues.purchase_year, value: updatedBuyingACarValues.purchase_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect to buy your first car. Have you always wanted your own car? Or have you been contemplating buying a car for work or family or fun? See what you can afford in different years by changing the Purchase Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Base Price</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.base_price} onValueChange={(e) => updateValue(e, 'base_price', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Destination Charges</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.destination_charges} onValueChange={(e) => updateValue(e, 'destination_charges', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The cost of delivering the car to the dealership. You know those large trucks on the highway transporting a bunch of vehicles? Well, this is the fee that pays for your car to get to the dealership near you. See Boost My Awareness section for specifics.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                             <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Options</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.car_options} onValueChange={(e) => updateValue(e, 'car_options', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.askingPrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.askingPrice} prefix={moduleServices.module12Data.askingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.askingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Discount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.discount_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'discount_in_percentage', 'number')}
                                            onFocus={(e) => { e.target.select(); setIsFocus('discount_in_percentage');}}
                                            decimalScale = { isFocus!=='discount_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='discount_in_percentage'}
                                            onBlur={() => { setFixedDecimal('discount_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="When buying a car, you should always try and negotiate a discount. A discount is a percentage of the Asking Price that the place/person that is selling you the car takes off the price. See the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.discountCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.discountCalcs} prefix={moduleServices.module12Data.discountCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.discountCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Rebate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.rebate}
                                            onValueChange={(e) => updateValue(e, 'rebate', 'number')}
                                            onFocus={(e) => { e.target.select(); setIsFocus('rebate'); }}
                                            onBlur={() => { setFixedDecimal('rebate'); }}
                                            decimalScale = { isFocus!=='rebate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='rebate'}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="When buying a car, always ask if there are any rebates for the vehicle you are planning to purchase. Think of rebates like promotions that manufacturers tell dealerships to use to sell their cars. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.rebateCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.rebateCalcs} prefix={moduleServices.module12Data.rebateCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.rebateCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                             <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Adjusted Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.netPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.netPurchasePrice} prefix={moduleServices.module12Data.netPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.netPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 1</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <FormControl component="fieldset">
                                              <div className={classes.radioBlock}>
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car === 'Yes'}
                                                      color="primary"
                                                      value="Yes"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car', 'input')}
                                                    />
                                                  )}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car === 'No'}
                                                      color="primary"
                                                      value="No"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car', 'input')}
                                                    />
                                                  )}
                                                  label="No"
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                        <div className={classes.spanText}>Vehicle Trade-In Value after Loan Repayment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                              {moduleServices.module12Data.tradeInValue !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue} prefix={moduleServices.module12Data.tradeInValue >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue < 0 && ')'} />
                                        ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 2</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <FormControl component="fieldset">
                                              <div className={classes.radioBlock}>
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_2 === 'Yes'}
                                                      color="primary"
                                                      value="Yes"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_2', 'input')}
                                                    />
                                                  )}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_2 === 'No'}
                                                      color="primary"
                                                      value="No"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_2', 'input')}
                                                    />
                                                  )}
                                                  label="No"
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                        <div className={classes.spanText}>Vehicle Trade-In Value after Loan Repayment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.tradeInValue2 !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue2} prefix={moduleServices.module12Data.tradeInValue2 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue2 < 0 && ')'} />
                                        ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 3</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <FormControl component="fieldset">
                                              <div className={classes.radioBlock}>
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_3 === 'Yes'}
                                                      color="primary"
                                                      value="Yes"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_3', 'input')}
                                                    />
                                                  )}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_3 === 'No'}
                                                      color="primary"
                                                      value="No"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_3', 'input')}
                                                    />
                                                  )}
                                                  label="No"
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                        <div className={classes.spanText}>Vehicle Trade-In Value after Loan Repayment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.tradeInValue3 !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue3} prefix={moduleServices.module12Data.tradeInValue3 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue3 < 0 && ')'} />
                                        ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 4</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <FormControl component="fieldset">
                                              <div className={classes.radioBlock}>
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_4 === 'Yes'}
                                                      color="primary"
                                                      value="Yes"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_4', 'input')}
                                                    />
                                                  )}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  control={(
                                                    <Checkbox
                                                      checked={updatedBuyingACarValues.tradein_existing_car_4 === 'No'}
                                                      color="primary"
                                                      value="No"
                                                      onChange={(e) => updateValue(e, 'tradein_existing_car_4', 'input')}
                                                    />
                                                  )}
                                                  label="No"
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                         
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                        <div className={classes.spanText}>Vehicle Trade-In Value after Loan Repayment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.tradeInValue4 !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue4} prefix={moduleServices.module12Data.tradeInValue4 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue4 < 0 && ')'} />
                                        ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          {updatedBuyingACarValues.purchase_year > 0 && (
                                            <div className={classNames(classes.spanText, classes.spanTextSmall)}>
                                              Existing Car Trade-In Value after Loan Repayment in Year
                                              {' '}
                                              {updatedBuyingACarValues.purchase_year}
                                            </div>
                                          )}
                                          {updatedBuyingACarValues.purchase_year === 0 && (
                                            <div className={classes.spanText}>
                                              Trade-In Value after Loan Repayment
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.tradeInValue !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue} prefix={moduleServices.module12Data.tradeInValue >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          {updatedBuyingACarValues.tradein_existing_car === 'Yes' && <Button className={classes.editButton} onClick={handleEditPopup}>EDIT</Button>}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.adjustedPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.adjustedPurchasePrice} prefix={moduleServices.module12Data.adjustedPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.adjustedPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.down_payment_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'down_payment_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('down_payment_in_percentage'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('down_payment_in_percentage'); }}
                                            decimalScale = { isFocus!=='down_payment_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='down_payment_in_percentage'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Percentage of the Total Purchase Price you expect to pay upfront. If you don't plan to pay in full, the rest of the Total Purchase Price will be financed through a loan and automatically calculated below. See the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Down Payment</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.downPaymentCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.downPaymentCar} prefix={moduleServices.module12Data.downPaymentCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.downPaymentCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Sales Tax</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.sales_tax_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'sales_tax_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('sales_tax_in_percentage'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('sales_tax_in_percentage'); }}
                                            decimalScale = { isFocus!=='sales_tax_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='sales_tax_in_percentage'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Depending on what state you are in, you will most likely pay Sales Tax when buying a car. Calculated as a percentage of Adjusted Purchase Price. Use the Boost My Awareness section for more details. Research your state's Sales Tax rate."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.salesTaxCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.salesTaxCalcs} prefix={moduleServices.module12Data.salesTaxCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.salesTaxCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Registration & Documentation Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.registration_and_documentation_fees} onValueChange={(e) => updateValue(e, 'registration_and_documentation_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Fees charged by the dealership for completing the necessary paperwork to validate the transaction. Usually ranges anywhere from $0 - $1,000 depending on the state. Use the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed before Loan Fees</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalPurchasePrice} prefix={moduleServices.module12Data.totalPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Car Loan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Car Loan</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.carLoan !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.carLoan} prefix={moduleServices.module12Data.carLoan >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.carLoan < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan (months)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.term_of_the_loan} onValueChange={(e) => updateValue(e, 'term_of_the_loan', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Fees charged by the dealership for completing the necessary paperwork to validate the transaction. Usually ranges anywhere from $0 - $1,000 depending on the state. Use the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>

                                        {moduleServices.module12Data.termOfTheLoanYears !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.termOfTheLoanYears} prefix={moduleServices.module12Data.termOfTheLoanYears >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.termOfTheLoanYears < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.interest_rate}
                                            onValueChange={(e) => updateValue(e, 'interest_rate', 'number')}
                                            onBlur={() => { setFixedDecimal('interest_rate'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('interest_rate'); }}
                                            decimalScale = { isFocus!=='interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='interest_rate'}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The Interest Rate you plan to get on this loan. The national average for U.S. car loan interest rates in 2017 is 4.21% on 5 year loans. Change the Interest Rate to see the effects on Total Interest Paid. Use the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.origination_fees}
                                            onValueChange={(e) => updateValue(e, 'origination_fees', 'number')}
                                            onBlur={() => { setFixedDecimal('origination_fees'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('origination_fees'); }}
                                            decimalScale = { isFocus!=='origination_fees'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='origination_fees'}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender for administrative costs associated with your loan. Origination Fees are calculated as a percentage of the loan and are typically required for all loans. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.originationFeesCalculations !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.originationFeesCalculations} prefix={moduleServices.module12Data.originationFeesCalculations >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.originationFeesCalculations < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.monthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.monthlyPayment} prefix={moduleServices.module12Data.monthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.monthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.yearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.yearlyPayment} prefix={moduleServices.module12Data.yearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.yearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalCarInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalCarInterest} prefix={moduleServices.module12Data.totalCarInterest >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalCarInterest < 0 && ')'} />
                                            ) : ('$0')}
                                            {' '}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalPrincipalAndInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalPrincipalAndInterest} prefix={moduleServices.module12Data.totalPrincipalAndInterest >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalPrincipalAndInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Monthly Expenses</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.first_car_maintenance} onValueChange={(e) => updateValue(e, 'first_car_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on maintenance for this car. Maintenance includes things like new tires, fixing a cracked windshield, or replacing a flat tire. Average maintenance costs run about $100 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.firstCarMaintenanceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.firstCarMaintenanceCalcs} prefix={moduleServices.module12Data.firstCarMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.firstCarMaintenanceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.first_car_insurance} onValueChange={(e) => updateValue(e, 'first_car_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on insurance for this car. Average insurance costs can range from roughly $100 - $200 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.firstCarInsuranceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.firstCarInsuranceCalcs} prefix={moduleServices.module12Data.firstCarInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.firstCarInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Gas</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.first_car_gas} onValueChange={(e) => updateValue(e, 'first_car_gas', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on gas for this car. Think about the distance you plan on commuting to work or school and how often you may take longer trips to get a good estimate of your monthly gas expense.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.firstCarGasCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.firstCarGasCalcs} prefix={moduleServices.module12Data.firstCarGasCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.firstCarGasCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalMonthlyExpensesFirstCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalMonthlyExpensesFirstCar} prefix={moduleServices.module12Data.totalMonthlyExpensesFirstCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalMonthlyExpensesFirstCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalAnnualExpensesFirstCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalAnnualExpensesFirstCar} prefix={moduleServices.module12Data.totalAnnualExpensesFirstCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalAnnualExpensesFirstCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Buying a Car Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Base Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classNames(classes.noInputText, classes.textUnderline)}>
                                            {moduleServices.module12Data.investmentAnalysisPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisPurchasePrice} prefix={moduleServices.module12Data.investmentAnalysisPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Destination Charges</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.destinationChargesSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.destinationChargesSummary} prefix={moduleServices.module12Data.destinationChargesSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.destinationChargesSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Options</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.getCarOptions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.getCarOptions} prefix={moduleServices.module12Data.getCarOptions >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.getCarOptions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Discount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisDiscount !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisDiscount} prefix={moduleServices.module12Data.investmentAnalysisDiscount >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisDiscount < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Rebate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisRebate !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisRebate} prefix={moduleServices.module12Data.investmentAnalysisRebate >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisRebate < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Trade-In Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisTradeIn !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisTradeIn} prefix={moduleServices.module12Data.investmentAnalysisTradeIn >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisTradeIn < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisTotalPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisTotalPurchasePrice} prefix={moduleServices.module12Data.investmentAnalysisTotalPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisTotalPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.carDownPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.carDownPayment} prefix={moduleServices.module12Data.carDownPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.carDownPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Sales Tax</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisSalesTax !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisSalesTax} prefix={moduleServices.module12Data.investmentAnalysisSalesTax >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisSalesTax < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Registration & Documentation Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.investmentAnalysisRegistrationFees !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.investmentAnalysisRegistrationFees} prefix={moduleServices.module12Data.investmentAnalysisRegistrationFees >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.investmentAnalysisRegistrationFees < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.carLoanPoints !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.carLoanPoints} prefix={moduleServices.module12Data.carLoanPoints >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.carLoanPoints < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed at Purchase</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.cashNeededAtPurchase !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.cashNeededAtPurchase} prefix={moduleServices.module12Data.cashNeededAtPurchase >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.cashNeededAtPurchase < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Cash Balance at {' '}
                                            {updatedBuyingACarValues.purchase_year - 1}
                                            {' '}
                                            Year End
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalCashAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalCashAvailable} prefix={moduleServices.module12Data.totalCashAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Total Investments at {' '}
                                            {updatedBuyingACarValues.purchase_year - 1}
                                            {' '}
                                            Year End
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalLiquid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalLiquid} prefix={moduleServices.module12Data.totalLiquid >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalLiquid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>

                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Leasing a Second Car</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'leasing_second_car_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedBuyingACarValues.leasing_second_car_start_year, value: updatedBuyingACarValues.leasing_second_car_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect to begin leasing a car. If you are currently leasing a car, select Today's Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>End Year</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}># of Months</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'leasing_second_car_number_of_years', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedBuyingACarValues.leasing_second_car_number_of_years, value: updatedBuyingACarValues.leasing_second_car_number_of_years }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The number of months you expect to lease this vehicle. If this is an existing lease, select the number of months remaining on the lease.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingSecondCarEndYear !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" value={moduleServices.module12Data.leasingSecondCarEndYear} />
                                        ) : ('0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_down_payment} onValueChange={(e) => updateValue(e, 'leasing_second_car_down_payment', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Money that is due at signing. Most leases will require a certain amount of money up front as a down payment for the lease. Making a big down payment will lower your monthly payments. See the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Drive-Off Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_drive_off_fees} onValueChange={(e) => updateValue(e, 'leasing_second_car_drive_off_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on a car payment for leasing a car. If this is an existing lease, enter your monthly payment. Go to the Boost My Awareness section for more information on how to estimate a lease payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed to Start Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.cashNeededToSecondStartLease !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.cashNeededToSecondStartLease} prefix={moduleServices.module12Data.cashNeededToSecondStartLease >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.cashNeededToSecondStartLease < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Monthly Expenses while Leasing</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Lease Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_lease_payment} onValueChange={(e) => updateValue(e, 'leasing_second_car_lease_payment', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on a car payment for leasing a car. If this is an existing lease, enter your monthly payment. Go to the Boost My Awareness section for more information on how to estimate a lease payment.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>

                                        {moduleServices.module12Data.leasingSecondCarLeasePaymentCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarLeasePaymentCalc} prefix={moduleServices.module12Data.leasingSecondCarLeasePaymentCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarLeasePaymentCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_maintenance} onValueChange={(e) => updateValue(e, 'leasing_second_car_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on maintenance for this car. Maintenance includes things like new tires, fixing a cracked windshield, or replacing a flat tire. Average maintenance costs run about $100 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingSecondCarMaintenanceCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarMaintenanceCalc} prefix={moduleServices.module12Data.leasingSecondCarMaintenanceCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarMaintenanceCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_insurance} onValueChange={(e) => updateValue(e, 'leasing_second_car_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on insurance for this car. Average insurance costs can range from roughly $100 - $200 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingSecondCarInsuranceCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarInsuranceCalc} prefix={moduleServices.module12Data.leasingSecondCarInsuranceCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarInsuranceCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Gas</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.leasing_second_car_gas} onValueChange={(e) => updateValue(e, 'leasing_second_car_gas', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on gas for this car. Think about the distance you plan on commuting to work or school and how often you may take longer trips to get a good estimate of your monthly gas expense.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.leasingSecondCarGasCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarGasCalc} prefix={moduleServices.module12Data.leasingSecondCarGasCalc >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarGasCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses} prefix={moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarTotalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarTotalAnnualExpenses} prefix={moduleServices.module12Data.leasingSecondCarTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarTotalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Leasing a Second Car Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed to Start Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarDownPaymentSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarDownPaymentSummary} prefix={moduleServices.module12Data.leasingSecondCarDownPaymentSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarDownPaymentSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Lease Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarTotalPaymentSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarTotalPaymentSummary} prefix={moduleServices.module12Data.leasingSecondCarTotalPaymentSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarTotalPaymentSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Other Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarTotalExpensesSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarTotalExpensesSummary} prefix={moduleServices.module12Data.leasingSecondCarTotalExpensesSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarTotalExpensesSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Cost of Lease</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.leasingSecondCarTotalCostsSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.leasingSecondCarTotalCostsSummary} prefix={moduleServices.module12Data.leasingSecondCarTotalCostsSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.leasingSecondCarTotalCostsSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Buying a Second Car</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_purchase_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedBuyingACarValues.second_purchase_year, value: updatedBuyingACarValues.second_purchase_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect to buy your first car. Have you always wanted your own car? Or have you been contemplating buying a car for work or family or fun? See what you can afford in different years by changing the Purchase Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Base Price</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_base_price} onValueChange={(e) => updateValue(e, 'second_base_price', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Destination Charges</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_destination_charges} onValueChange={(e) => updateValue(e, 'second_destination_charges', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The cost of delivering the car to the dealership. You know those large trucks on the highway transporting a bunch of vehicles? Well, this is the fee that pays for your car to get to the dealership near you. See Boost My Awareness section for specifics.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Options</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_options} onValueChange={(e) => updateValue(e, 'second_options', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreAskingPrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreAskingPrice} prefix={moduleServices.module12Data.moreAskingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreAskingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Discount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_discount}
                                            onValueChange={(e) => updateValue(e, 'second_discount', 'number')}
                                            onBlur={() => { setFixedDecimal('second_discount'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_discount'); }}
                                            decimalScale = { isFocus!=='second_discount'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_discount'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="When buying a car, you should always try and negotiate a discount. A discount is a percentage of the Asking Price that the place/person that is selling you the car takes off the price. See the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.moreDiscountCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreDiscountCalcs} prefix={moduleServices.module12Data.moreDiscountCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreDiscountCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Rebate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_rebate}
                                            onValueChange={(e) => updateValue(e, 'second_rebate', 'number')}
                                            onBlur={() => { setFixedDecimal('second_rebate'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_rebate'); }}
                                            decimalScale = { isFocus!=='second_rebate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_rebate'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="When buying a car, always ask if there are any rebates for the vehicle you are planning to purchase. Think of rebates like promotions that manufacturers tell dealerships to use to sell their cars. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.moreRebateCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreRebateCalcs} prefix={moduleServices.module12Data.moreRebateCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreRebateCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Adjusted Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreNetPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreNetPurchasePrice} prefix={moduleServices.module12Data.moreNetPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreNetPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 1</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <div className={classes.radioBlock}>
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car === 'Yes'}
                                                    color="primary"
                                                    value="Yes"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car', 'input')}
                                                  />
                                                )}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car === 'No'}
                                                    color="primary"
                                                    value="No"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car', 'input')}
                                                  />
                                                )}
                                                label="No"
                                              />
                                            </div>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          Trade-In Value after Loan Repayment
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.moreTradeInValue !== undefined ? (
                                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTradeInValue} prefix={moduleServices.module12Data.moreTradeInValue >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTradeInValue < 0 && ')'} />
                                      ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 2</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <div className={classes.radioBlock}>
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_2 === 'Yes'}
                                                    color="primary"
                                                    value="Yes"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_2', 'input')}
                                                  />
                                                )}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_2 === 'No'}
                                                    color="primary"
                                                    value="No"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_2', 'input')}
                                                  />
                                                )}
                                                label="No"
                                              />
                                            </div>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          Trade-In Value after Loan Repayment
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.moreTradeInValue2 !== undefined ? (
                                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTradeInValue2} prefix={moduleServices.module12Data.moreTradeInValue2 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTradeInValue2 < 0 && ')'} />
                                      ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 3</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <div className={classes.radioBlock}>
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_3 === 'Yes'}
                                                    color="primary"
                                                    value="Yes"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_3', 'input')}
                                                  />
                                                )}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_3 === 'No'}
                                                    color="primary"
                                                    value="No"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_3', 'input')}
                                                  />
                                                )}
                                                label="No"
                                              />
                                            </div>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          Trade-In Value after Loan Repayment
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.moreTradeInValue3 !== undefined ? (
                                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTradeInValue3} prefix={moduleServices.module12Data.moreTradeInValue3 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTradeInValue3 < 0 && ')'} />
                                      ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Trade-In Existing Car 4</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectRadio}>
                                            <div className={classes.radioBlock}>
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_4 === 'Yes'}
                                                    color="primary"
                                                    value="Yes"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_4', 'input')}
                                                  />
                                                )}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                control={(
                                                  <Checkbox
                                                    checked={updatedBuyingACarValues.moretradein_existing_car_4 === 'No'}
                                                    color="primary"
                                                    value="No"
                                                    onChange={(e) => updateValue(e, 'moretradein_existing_car_4', 'input')}
                                                  />
                                                )}
                                                label="No"
                                              />
                                            </div>
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If you have an Existing Car, then you can trade that in when planning on buying another car. The Trade-In Value below will automatically update based on the Purchase Year. If you select 'Yes', this will stop all expenses related to the Existing Car.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                             
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          Trade-In Value after Loan Repayment
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classNames(classes.noInputText, classes.cpBigSpan)}>
                                            {moduleServices.module12Data.moreTradeInValue4 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTradeInValue4} prefix={moduleServices.module12Data.moreTradeInValue4 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTradeInValue4 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                                          {updatedBuyingACarValues.second_purchase_year > 0 && (
                                            <div className={classes.spanText}>

                                              First Car Trade-In Value after Loan Repayment in Year
                                              {' '}
                                              {updatedBuyingACarValues.second_purchase_year}
                                            </div>
                                          )}
                                          {updatedBuyingACarValues.second_purchase_year === 0 && (
                                            <div className={classes.spanText}>
                                              Trade-In Value after Loan Repayment
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInputNew)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTradeInValue5 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTradeInValue5} prefix={moduleServices.module12Data.moreTradeInValue5 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTradeInValue5 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Existing Car Value less Loan Balance Remaining at the beginning of the Purchase Year. If you don't want to trade your Existing Car in with the Purchase of your Second Car, make sure that you select 'No' in the input above!">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          {updatedBuyingACarValues.moretradein_existing_car === 'Yes' && <Button className={classes.editButton} onClick={handleEditPopup}>EDIT</Button>}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreAdjustedPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreAdjustedPurchasePrice} prefix={moduleServices.module12Data.moreAdjustedPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreAdjustedPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_down_payment_in_percentage}
                                            onBlur={() => { setFixedDecimal('second_down_payment_in_percentage'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_down_payment_in_percentage'); }}
                                            decimalScale = { isFocus!=='second_down_payment_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_down_payment_in_percentage'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Percentage of the Total Purchase Price you expect to pay upfront. If you don't plan to pay in full, the rest of the Total Purchase Price will be financed through a loan and automatically calculated below. See the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Down Payment</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreDownPaymentCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreDownPaymentCar} prefix={moduleServices.module12Data.moreDownPaymentCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreDownPaymentCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Sales Tax</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_sales_tax}
                                            onValueChange={(e) => updateValue(e, 'second_sales_tax', 'number')}
                                            onBlur={() => { setFixedDecimal('second_sales_tax'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_sales_tax'); }}
                                            decimalScale = { isFocus!=='second_sales_tax'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_sales_tax'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Depending on what state you are in, you will most likely pay Sales Tax when buying a car. Calculated as a percentage of Adjusted Purchase Price. Use the Boost My Awareness section for more details. Research your state's Sales Tax rate."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.moreSalesTaxCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreSalesTaxCalcs} prefix={moduleServices.module12Data.moreSalesTaxCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreSalesTaxCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Registration & Documentation Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_registration_and_documentation_fees} onValueChange={(e) => updateValue(e, 'second_registration_and_documentation_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Fees charged by the dealership for completing the necessary paperwork to validate the transaction. Usually ranges anywhere from $0 - $1,000 depending on the state. Use the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed before Loan Fees</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTotalPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTotalPurchasePrice} prefix={moduleServices.module12Data.moreTotalPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTotalPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Car Loan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Car Loan</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreCarLoan !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreCarLoan} prefix={moduleServices.module12Data.moreCarLoan >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreCarLoan < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan (months)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_term_of_the_loan} onValueChange={(e) => updateValue(e, 'second_term_of_the_loan', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Fees charged by the dealership for completing the necessary paperwork to validate the transaction. Usually ranges anywhere from $0 - $1,000 depending on the state. Use the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>

                                        {moduleServices.module12Data.moreTermOfTheLoanYears !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTermOfTheLoanYears} prefix={moduleServices.module12Data.moreTermOfTheLoanYears >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTermOfTheLoanYears < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'second_interest_rate', 'number')}
                                            onBlur={() => { setFixedDecimal('second_interest_rate'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_interest_rate'); }}
                                            decimalScale = { isFocus!=='second_interest_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_interest_rate'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The Interest Rate you plan to get on this loan. The national average for U.S. car loan interest rates in 2017 is 4.21% on 5 year loans. Change the Interest Rate to see the effects on Total Interest Paid. Use the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingACarValues.second_origination_fees}
                                            onValueChange={(e) => updateValue(e, 'second_origination_fees', 'number')}
                                            onBlur={() => { setFixedDecimal('second_origination_fees'); }}
                                            onFocus={(e) => { e.target.select(); setIsFocus('second_origination_fees'); }}
                                            decimalScale = { isFocus!=='second_origination_fees'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='second_origination_fees'}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender for administrative costs associated with your loan. Origination Fees are calculated as a percentage of the loan and are typically required for all loans. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.secondOriginationFeesCalculations !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.secondOriginationFeesCalculations} prefix={moduleServices.module12Data.secondOriginationFeesCalculations >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.secondOriginationFeesCalculations < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreMonthlyPayment} prefix={moduleServices.module12Data.moreMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreYearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreYearlyPayment} prefix={moduleServices.module12Data.moreYearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreYearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTotalCarInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTotalCarInterest} prefix={moduleServices.module12Data.moreTotalCarInterest >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTotalCarInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTotalPrincipalAndInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTotalPrincipalAndInterest} prefix={moduleServices.module12Data.moreTotalPrincipalAndInterest >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTotalPrincipalAndInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Monthly Expenses</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_car_maintenance} onValueChange={(e) => updateValue(e, 'second_car_maintenance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on maintenance for this car. Maintenance includes things like new tires, fixing a cracked windshield, or replacing a flat tire. Average maintenance costs run about $100 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.secondCarMaintenanceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.secondCarMaintenanceCalcs} prefix={moduleServices.module12Data.secondCarMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.secondCarMaintenanceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_car_insurance} onValueChange={(e) => updateValue(e, 'second_car_insurance', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on insurance for this car. Average insurance costs can range from roughly $100 - $200 per month.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.secondCarInsuranceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.secondCarInsuranceCalcs} prefix={moduleServices.module12Data.secondCarInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.secondCarInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Gas</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingACarValues.second_car_gas} onValueChange={(e) => updateValue(e, 'second_car_gas', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on gas for this car. Think about the distance you plan on commuting to work or school and how often you may take longer trips to get a good estimate of your monthly gas expense.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module12Data.secondCarGasCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.secondCarGasCalcs} prefix={moduleServices.module12Data.secondCarGasCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.secondCarGasCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalMonthlyExpensesSecondCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalMonthlyExpensesSecondCar} prefix={moduleServices.module12Data.totalMonthlyExpensesSecondCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalMonthlyExpensesSecondCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.totalAnnualExpensesSecondCar !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.totalAnnualExpensesSecondCar} prefix={moduleServices.module12Data.totalAnnualExpensesSecondCar >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.totalAnnualExpensesSecondCar < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The price of a car you are planning to purchase as it appears on the manufacturer's website which is known as the MSRP. Find the car you want to buy and input the price before any discounts or added fees. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Buying a Car Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Base Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classNames(classes.noInputText, classes.textUnderline)}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice} prefix={moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Destination Charges</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreDestinationChargesSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreDestinationChargesSummary} prefix={moduleServices.module12Data.moreDestinationChargesSummary >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreDestinationChargesSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Options</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.getMoreCarOptions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.getMoreCarOptions} prefix={moduleServices.module12Data.getMoreCarOptions >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.getMoreCarOptions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Discount</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisDiscount !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisDiscount} prefix={moduleServices.module12Data.moreInvestmentAnalysisDiscount >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisDiscount < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Rebate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisRebate !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisRebate} prefix={moduleServices.module12Data.moreInvestmentAnalysisRebate >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisRebate < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Trade-In Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisTradeIn !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisTradeIn} prefix={moduleServices.module12Data.moreInvestmentAnalysisTradeIn >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisTradeIn < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice} prefix={moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisTotalPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreCarDownPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreCarDownPayment} prefix={moduleServices.module12Data.moreCarDownPayment >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreCarDownPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Sales Tax</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisSalesTax !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisSalesTax} prefix={moduleServices.module12Data.moreInvestmentAnalysisSalesTax >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisSalesTax < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Registration & Documentation Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees} prefix={moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreInvestmentAnalysisRegistrationFees < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreCarLoanPoints !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreCarLoanPoints} prefix={moduleServices.module12Data.moreCarLoanPoints >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreCarLoanPoints < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed at Purchase</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreCashNeededAtPurchase !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreCashNeededAtPurchase} prefix={moduleServices.module12Data.moreCashNeededAtPurchase >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreCashNeededAtPurchase < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Cash Balance at {' '}
                                            {updatedBuyingACarValues.second_purchase_year - 1}
                                            {' '}
                                            Year End
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTotalCashAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTotalCashAvailable} prefix={moduleServices.module12Data.moreTotalCashAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTotalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Total Investments at {' '}
                                            {updatedBuyingACarValues.second_purchase_year - 1}
                                            {' '}
                                            Year End
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module12Data.moreTotalLiquid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.moreTotalLiquid} prefix={moduleServices.module12Data.moreTotalLiquid >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.moreTotalLiquid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>

                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>


            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.carShowMe) : classNames(classes.showMe, classes.showSection)} id="slide-five">

              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ?  classes.opacityZero  : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                  <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                )}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton}>Refresh</Button>)}
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Car Loan #1</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.showGraph}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Buying a Car
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>There&apos;s options to lease two cars and buy two cars? Do they depend on each other? What&apos;s going on here?</h3>
                          <p>
                            The Buying a Car module allows you to have two leases and two cars, all at the same time. They do not depend on each other nor do they cancel each other out. The only time that they overlap is if you choose to trade-in a car. Otherwise, set a timeline you want to lease or buy, add your expenses, and see if leasing or buying is right for you.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Where does the Trade-In Value come from? What happens to my Existing Car if I choose to trade-in when buying a car?</h3>
                          <p>
                            If you have an Existing Car when you go to buy your first car in the FC and that Existing Car still has value when you go to Buying a Car, you can opt to trade that in to lower the cost of buying a car. If you select &quot;Yes&quot; for Trade-In when completing Buying a Car, the FC will assume you are trading in your Existing Car. That will take your Existing Car value to 0.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>If the FC assumes my Existing Car is sold if I have a Trade-In Value for Buying a Car, does that same thing happen when I go to Buy a Second Car?</h3>
                          <p>
                            Yes, if you select &quot;Yes&quot; for Trade-In when Buying a Second Car, the FC assumes that you are trading in your first car. That will take your First Car value to 0.
                          </p>
                        </div>

                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://santanderconsumerusa.com/blog/heres-question-ask-buying-new-car" target="_blank" rel="noopener noreferrer">20/4/10 Rule</a>
                            {' '}
                          </h3>
                          <p>
                            The 20/4/10 rule is a great way to determine how much car you can afford. The rule suggests that you make a 20% down payment, finance the purchase over 4 years, and keep annual expenses less than 10% of your gross income. Let&apos;s look at an example to see the rule in action.
                          </p>
                          <p>
                            Let&apos;s say a family makes $120,000 in gross income and is looking to finance the purchase of a $50,000 car. The 20-4-10 rule would suggest the family pays no more than $12,000 a year on that car, or $1,000 per month. By making a 20% down payment, the family will pay $10,000 upfront and take a $40,000 loan
                            to cover the remaining cost of the car. And lastly, the $40,000 car loan will be financed over a 4 year period. Assuming a 5% interest rate, the monthly loan payment on that car would be $921, which leaves only $79 for other expenses such as maintenance and insurance. In this example, the family&apos;s purchase of a $50,000 car meets the standards of the 20/4/10 rule and would be an affordable purchase.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Adjusted Purchase Price</h3>
                          <p>Adjusted purchase price is equal to the base price plus destination charges and options less discounts and rebates.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.consumerreports.org/car-pricing-negotiation/guide-to-car-pricing-terms/" target="_blank" rel="noopener noreferrer">Base Price</a>
                            {' '}
                          </h3>
                          <p>Base price, also known as Manufacturer&apos;s Suggested Retail Price (MSRP) or Sticker Price, is the cost of the vehicle before destination charges, options, discounts, and rebates. For example, this would be the price before you configure your or negotiate the price of your vehicle.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.kbb.com/car-advice/articles/destination-charges/" target="_blank" rel="noopener noreferrer">Destination Charges</a>
                            {' '}
                          </h3>
                          <p>
                            Destination charges are fees that the dealership charge for getting your vehicle to the lot. Ever pass by trucks on the highway carrying multiple cars? You&apos;re witnessing the destination charges in action. These fees are almost always non-negotiable and can range from $400 to $1,200.
                          </p>

                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Discount</h3>
                          <p>
                            Car Dealerships often sell cars for a discounted price, or lower than the Base Price. The discount is a percentage of the Base Price. For example, if a car is selling for $10,000 and the dealership offers a 2% discount, the Adjusted Purchase Price would be $9,800, or $10,000 less the $200 discount.
                            Discounts on car sales in the U.S. can vary based on a number of factors such as buying new or used, make, model, and negotiation. A reasonable assumption would be anywhere from 2-5%.
                          </p>
                          <p>
                            Negotiating a discount on a vehicle takes preparation and practice.
                            {' '}
                            <a href="https://www.consumerreports.org/car-pricing-negotiation/how-to-negotiate-a-new-car-price-effectively/" target="_blank" rel="noopener noreferrer">Click here</a>
                            {' '}
                            for a helpful guide from Consumer Reports on negotiating.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.edmunds.com/car-buying/what-fees-should-you-pay.html" target="_blank" rel="noopener noreferrer">Documentation Fees</a>
                            {' '}
                          </h3>
                          <p>Documentation fees are charged by the dealer for the time spent processing the paperwork to complete your purchase. These fees typically range from $100 to $200, but can vary depending on the seller of the vehicle.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Down Payment - Buying</h3>
                          <p>
                            The amount of money you pay upfront to the seller expressed as a percentage of the total purchase price. A bank or other financial institution covers the remainder of the total purchase price through a car loan. The higher your down payment,
                            the
                            {' '}
                            <a href="https://www.bankofamerica.com/auto-loans/how-car-loans-work/" target="_blank" rel="noopener noreferrer" className={classes.noLink}> lower your monthly payment </a>

                            {' '}
                            will be. The average down payment when buying a car is
                            {' '}
                            {' '}
                            <a href="https://www.edmunds.com/car-buying/how-much-should-a-car-down-payment-be.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>around 12%</a>

                            {' '}
                            of the total purchase price.
                            A good rule of thumb is the
                            {' '}
                            <a href="https://santanderconsumerusa.com/blog/heres-question-ask-buying-new-car" target="_blank" rel="noopener noreferrer" className={classes.noLink}>20-4-10 rule.</a>

                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Down Payment - Leasing</h3>
                          <p>
                            The amount of money you pay upfront to the seller expressed as a dollar amount. Do you need to make a down payment to obtain a lease? In some cases, no. Read more about the ins and outs of a down payment for a lease
                            {' '}
                            {' '}
                            <a href="https://www.edmunds.com/car-leasing/should-you-make-a-down-payment-when-you-lease.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here.</a>

                          </p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.investopedia.com/terms/a/auto-insurance.asp" target="_blank" rel="noopener noreferrer">Insurance</a>
                            {' '}
                          </h3>
                          <p>
                            Monthly costs, known as a premium, paid to an insurance company in case of an accident. Instead of paying with your own money, or out of pocket, your insurance company will then pay for some or all of the costs of your accident depending on a number of different factors. See why car insurance is
                            {' '}
                            <a href="https://www.thebalance.com/what-is-car-insurance-for-527458" target="_blank" rel="noopener noreferrer" className={classes.noLink}>important here</a>
                            .
                            {' '}
                            Also, check out
                            {' '}
                            <a href="https://www.insure.com/car-insurance/car-insurance-rates.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>average annual car insurance by state</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.bankrate.com/banking/checking-vs-savings-accounts/" target="_blank" rel="noopener noreferrer">Interest</a>
                            {' '}
                          </h3>
                          <p>
                            Monthly loan payments are made up of two parts: interest and principal repayment. Interest is a loan expense calculated as a percentage of the outstanding Principal. Interest is the cost of borrowing the Principal. See an example of a typical loan amortization schedule, or in other words, loan repayment schedule
                            {' '}
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            .

                          </p>
                          <p>Interest is calculated by multiplying the Outstanding Balance by the Interest Rate. For example, let&apos;s say we have a $60,000 Car Loan outstanding with a 5% annual Interest Rate that we pay on a monthly basis. The calculation for next month&apos;s interest payment would be as follows:</p>
                          <p>$60,000 Balance x 5% Interest Rate / 12 (to make the interest rate on a monthly basis) = $250</p>
                          <p>Don&apos;t forget, as you make monthly payments and start paying down your loan, your outstanding balance will decrease, which will decrease your interest portion of the payment.</p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest Rate</a>
                            {' '}
                          </h3>
                          <p>The amount a lender charges you for using their money, typically expressed as an annual percentage of the principal - loan amount outstanding. Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank, it’s very similar to renting an apartment.</p>
                          <p>Instead of borrowing a place to live, you are borrowing money usually from a bank. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank uses the interest rate in the formula below:</p>
                          <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Lease</h3>
                          <p>A contract to borrow a car for a certain period of time - usually 3 years - from another party such as a car dealership in exchange for a payment of money. A lease will typically require an upfront cash payment, which is called a down payment. And, a lease will always require a monthly payment.</p>
                          <p>
                            Think of leasing as renting a car for a 3 year period and the monthly payment as paying rent to drive the car. When
                            {' '}
                            <a href="https://www.thebalance.com/should-i-lease-a-car-2385821" target="_blank" rel="noopener noreferrer">deciding to lease or buy</a>
                            {' '}
                            a car there are many factors to consider. Leasing will typically result in a lower monthly payment than financing a car purchase. But, you will not own the car outright. Check out
                            {' '}
                            <a href="https://www.thebalance.com/pros-and-cons-of-leasing-vs-buying-a-car-527145" target="_blank" rel="noopener noreferrer">this link</a>
                            {' '}
                            for some pros and cons on leasing and buying.
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={leasevsBuyProsandCons} alt="" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Maintenance</h3>
                          <p>
                            The costs incurred to keep your car in good condition and good working order. Maintenance includes
                            {' '}
                            {' '}
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/routine-car-maintenance-cost/" target="_blank" rel="noopener noreferrer">general repairs</a>

                            {' '}
                            such as oil changes, tire rotations and new tires, inspections, and many more. Budget about $100 per month or $1,200 per year
                            {' '}
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/car-maintenance-bills/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>according to AAA</a>
                            .

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.edmunds.com/car-leasing/calculate-your-own-lease-payment.html" target="_blank" rel="noopener noreferrer">Monthly Payment - Leasing</a>
                            {' '}
                          </h3>
                          <p>
                            The monthly payment made to
                            {' '}
                            <a href="https://www.edmunds.com/car-leasing/quick-guide-to-leasing-a-new-car.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>lease a car</a>

                            {' '}
                            over a period of time. Thinking of leasing instead of buying? Check out some automaker and dealership websites for current leasing offers to get a benchmark for how much your lease could charge for a monthly Car Payment.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/o/originationpoints.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Origination Fees</a>
                            {' '}
                          </h3>
                          <p>
                            One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are calculated as a percentage of the loan and are typically required for all loans. For student loans, origination fees can vary, but a
                            {' '}
                            <a href="https://studentloanhero.com/featured/student-loan-origination-fee/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>good estimate</a>

                            {' '}
                            would be anywhere from 0.5% to 4.5% depending on the loan.
                          </p>
                          <p>For example, if you take a $10,000 student loan and pay 2.0% origination fees, the fee calculation would be 2.0% x $10,000 = $200</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Other Liquid Assets Available</h3>
                          <p>Calculated as Short-Term Investments plus Long-Term Investments at the end of the year before the Purchase Year. For example, if you plan to buy in year 2025, Other Liquid Assets Available would show you Short-Term and Long-Term Investments available at the end of year 2024.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Options</h3>
                          <p>Options are additional upgrades that you plan to purchase that are not included in the base price. For example, blind spot protection, an upgraded technology package, extended warranty, seat warmers, automatic headlights, and more would be considered options.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Principal</h3>
                          <p>Total sum of money borrowed.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.lendingtree.com/glossary/what-is-rebate" target="_blank" rel="noopener noreferrer">Rebate</a>
                            {' '}
                          </h3>
                          <p>
                            A rebate is a fixed amount of money paid to you, the buyer, to incentivize you to buy that vehicle. The manufacturer of the vehicle typically offers the rebate. Rebates can also come from the dealership or government. You have to always ask the dealership if there are any rebates currently offered on the vehicle you are considering. To see the latest incentives and rebates, check out
                            {' '}
                            {' '}
                            <a href="https://www.edmunds.com/car-incentives/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Edmunds.com</a>

                          </p>
                          <p>
                            Rebates typically range from
                            {' '}
                            <a href="https://www.angieslist.com/articles/how-do-new-car-rebates-and-incentives-work.htm" target="_blank" rel="noopener noreferrer">$500 to $2,000.</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.edmunds.com/car-buying/what-fees-should-you-pay.html" target="_blank" rel="noopener noreferrer">Registration Fees</a>
                            {' '}
                          </h3>
                          <p>When you purchase or lease a new vehicle, you have to register that car with the state. Registration fees are the amount charged by the state to register the new car, assign a title (a legal term that means proof of ownership), and cover the cost of license plates. These fees can range anywhere from $0 to $1,000.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.consumerreports.org/car-pricing-negotiation/guide-to-car-pricing-terms/" target="_blank" rel="noopener noreferrer">Retail Value</a>
                            {' '}
                          </h3>
                          <p>
                            The Manufacturer&apos;s Suggested Retail Price (MSRP) of the car before any Destination Charges, Discounts, Rebates, Trade-Ins, Sales Tax, or Registration & Documentation Fees.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://taxfoundation.org/sales-tax-rates-2019/" target="_blank" rel="noopener noreferrer">Sales Tax</a>
                            {' '}
                          </h3>
                          <p>
                            Calculated as a percentage of the Net Purchase Price in the FC, when you purchase a car, most states and even some local governments, will charge sales tax. Sales tax rates in the U.S. can vary based on location. The
                            {' '}
                            <a href="https://taxfoundation.org/sales-tax-rates-2019/" target="_blank" rel="noopener noreferrer" className={classes.noLink}> average sales tax rate (state and local) in the U.S. is roughly 7%.</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/t/termtomaturity.asp" target="_blank" rel="noopener noreferrer">Term of the Loan</a>
                            {' '}
                          </h3>
                          <p>The length of time in years that you have to repay your loan. Car loans are typically 3-6 years in length, but you can repay your loans early if you can afford to. Change your term of the loan and see how that affects total interest repaid. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Principal & Interest</h3>
                          <p>Calculated by taking the principal, or total sum of money borrowed, plus the total interest, which is the total cost of borrowing the principal. In other words, the total principal and interest is the amount of money you would pay over the term of the loan to fully repay your credit card, student loan, car loan, or mortgage.</p>
                          <p>See the definitions for principal and interest above.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.kbb.com/what-is/trade-in-value/" target="_blank" rel="noopener noreferrer">Trade-In Value</a>
                            {' '}
                          </h3>
                          <p>The dealer will pay you for your existing car and reduce your purchase price based on the trade-in value less the amount of car loan outstanding. For example, if you own a car that&apos;s worth $15,000 and have a current loan balance of $5,000, that would be a $10,000 net trade-in value.</p>
                          <p>Research what your car might be worth on Kelley Blue Book.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Buying vs Leasing</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.consumerreports.org/buying-a-car/leasing-vs-buying-a-new-car/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Leasing vs buying; find out what&apos;s right for you</a>
                            <a href="https://www.kbb.com/car-advice/car-buying/step-6-leasing-vs-buying/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Second opinion on buying vs leasing</a>
                            <a href="https://www.edmunds.com/car-leasing/should-you-lease-or-buy-your-car.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Third opinion on buying vs leasing</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Leasing</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.edmunds.com/car-leasing/should-you-make-a-down-payment-when-you-lease.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Time to lease a car. Do I make a large or small down payment?</a>
                            <a href="http://www.magnifymoney.com/blog/auto-loan/11-things-know-lease-car/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>11 things to know when leasing</a>
                            <a href="https://www.edmunds.com/car-leasing/calculate-your-own-lease-payment.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to estimate a monthly car payment when leasing a car</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Buying</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.kbb.com/car-advice/car-buying/step-1-know-your-shopping-style/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Kelley Blue Book&apos;s 10 steps to car buying</a>
                            <a href="https://www.kbb.com/car-advice/articles/destination-charges/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Destination charges</a>
                            <a href="https://www.carwale.com/tipsadvice/new-car-discount-negotiation-guide-8915/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What type of discounts a dealership can offer</a>
                            <a href="https://www.edmunds.com/car-buying/how-much-should-a-car-down-payment-be.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Time to buy a car. Do I make a large or small down payment?</a>
                            <a href="https://www.nerdwallet.com/blog/loans/auto-loans/trade-in-car-when-you-owe-money/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What happens if I trade my car in and it still has a loan balance?</a>
                            <a href="https://www.edmunds.com/car-incentives/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Incentives and rebates could be available in your area: use this link to find out</a>
                            <a href="https://www.thebalance.com/before-you-get-an-auto-loan-315589" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Before you get an auto loan</a>
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a loan get repaid? What is amortization?</a>
                            <a href="https://www.thebalance.com/what-is-an-amortization-table-315520" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a loan get repaid? What is amortization? What does an example repayment schedule look like?</a>
                            <a href="https://www.bankrate.com/loans/auto-loans/current-auto-loan-interest-rates/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Current auto loan interest rates</a>
                            <a href="http://www.factorywarrantylist.com/car-tax-by-state.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Sales tax by state</a>
                            <a href="https://www.edmunds.com/car-buying/what-fees-should-you-pay.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Registration & Documentation fees plus other fees you can expect</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Monthly Expenses</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://www.insure.com/car-insurance/car-insurance-rates.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average annual car insurance by state</a>
                            <a href="http://www.automotive-fleet.com/channel/maintenance/article/story/2017/10/maintenance-costs-remain-flat-but-labor-rates-rise.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average car maintenance per month (first graph on the left)</a>
                            <a href="https://inflationdata.com/articles/cost-of-living/cost-of-gas/average-cost-of-gas-per-month/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimate your monthly gas cost with this simple calculator</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Glossary</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://instamotor.com/buy-used-car/a-glossary-of-car-buying-terms" target="_blank" rel="noopener noreferrer" className={classes.noLink}>General glossary of car terms</a>
                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.careerAdvancementSavedScenareos) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ?  classes.opacityZero  : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Buying a Car" moduleSelected="module12" getModuleData={getModuleData} setUpdatedScenario={setUpdatedBuyingACarValues} />
              )}
              {/* {dropdownvalues.compareSavedNotes && (
                <div className={ classes.sectionContent }>
                  <div className={ classes.panelRow }>
                    <div className={ classes.panelInfo }>
                      <div className={ classes.panelHeading }>
                        <div className={ classes.rowBlock }>
                          <div className={ classes.scenarioNotes }>
                            <div className={ classes.notesLeft }>
                              <ul className={ classes.listUnstyled }>
                                <li />
                              </ul>
                            </div>
                            <div className={ classNames( classes.notesRight, classes.scenarioNotesRight ) }>
                              <ul className={ classes.listUnstyled }>
                                <li><Button className={ classNames( classes.loadButton, classes.btnWarning ) }>Load</Button></li>
                                <li><Button className={ classNames( classes.saveButton, classes.btnWarning ) }>Save</Button></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classNames( classes.panelBody, classes.scenarioBlock ) }>
                        <Grid container>
                          <Grid item sm={ 3 } className={ classes.gridPadding } />
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario A</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario B</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Differences</h6>
                            <div>
                              <select>
                                <option value="Scenario A - Scenario B">Scenario A - Scenario B</option>
                                <option value="Scenario B - Scenario A">Scenario B - Scenario A</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>

      </div>
      <div>
        <Dialog
          open={popupOpen}
          onClose={() => { setPopupOpen(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>
            <div className={classes.popupClose}>
              <span aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                X
              </span>
            </div>
            <div>
              {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedBuyingACarValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg= {SetNotesSuccessMsg}/>)}

            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={imagePopup}
          onClose={handleImagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              <span onClick={handleImagePopup} aria-hidden="true">close</span>
              <img src={imageURL} alt="" />
            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={editPopup}
          onClose={handleEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.editPopup}
        >
          <DialogContent className={classes.videoPopup}>
            <div className={classes.modalHeader}>
              <h3>Existing Car Trade-In Value</h3>
            </div>
            <div className={classes.modalBody}>
              <div className={classes.moduleRowNew}>
                <Grid container>
                  <Grid item sm={12} className={classes.inputBlock}>
                    <div className={classes.inputGroup}>
                      <Grid container spacing={4}>
                        <Grid item sm={6} className={classes.noSpace}>
                          <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                            <div className={classes.spanText}>
                              Existing Car Trade-In Value after Loan Repayment in Year 2027
                            </div>
                          </div>
                        </Grid>
                        <Grid item sm={6} className={classes.noSpace}>
                          <div className={classNames(classes.inputRight, classes.fullInput, classes.bigInputNew, classes.cpBigInput)}>
                            <NumberFormat className={classes.formInput} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingACarValues.tradein_existing_car} onValueChange={(e) => updateValue(e, 'tradein_existing_car', 'number')} onFocus={(e) => e.target.select()} prefix="$" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <div>
                <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={handleEditPopup}>Reset to default values</Button>
                <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleEditPopup}>Ok</Button>
                <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleEditPopup}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={firstCarTradeInEdit}
          onClose={handleFirstCarTradeEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.editPopup}
        >
          <DialogContent className={classes.videoPopup}>
            <div className={classes.modalHeader}>
              <h3>Existing Car Trade-In Value</h3>
            </div>
            <div className={classes.modalBody}>
              <div className={classes.moduleRowNew}>
                <Grid container>
                  <Grid item sm={12} className={classes.inputBlock}>
                    <div className={classes.inputGroup}>
                      <Grid container spacing={4}>
                        <Grid item sm={6} className={classes.noSpace}>
                          <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputboxNew)}>
                            <div className={classes.spanText}>
                              Existing Car Trade-In Value after Loan Repayment in Year 0
                            </div>
                          </div>
                        </Grid>
                        <Grid item sm={6} className={classes.noSpace}>
                          <div className={classNames(classes.inputRight, classes.fullInput, classes.bigInputNew, classes.cpBigInput)}>
                            <NumberFormat className={classes.formInput} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingACarValues.first_car_trade_value} onValueChange={(e) => updateValue(e, 'first_car_trade_value', 'number')} onFocus={(e) => e.target.select()} prefix="$" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <div>
                <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={handleFirstCarTradeEditPopup}>Reset to default values</Button>
                <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleFirstCarTradeEditPopup}>Ok</Button>
                <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleFirstCarTradeEditPopup}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module12' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
       <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={ classes.modelPopupRow}
      >
        <DialogContent className={ classes.modelPopup}>
          <div className={ classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={ classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={ classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

BuyingACarWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BuyingACarWorksheet);
