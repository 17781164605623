import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';

const PrivateStudentLoans = ( props ) => {
  const { classes, handleDefinationPopup } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Other than federal student loans, there are also private student loans.</p>
      <Typography variant="h3" component="h3" className={ classes.federalStudent }><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Private Student Loans' ) }><b>Private Student Loans</b></span></Typography>
      <ul className={ classes.federalStudentLoans }>
        <li>Should only be taken after all other funding sources are used</li>
        <li>Higher borrowing limits; usually can borrow up to 100% of your total cost of attendance</li>
        <li>Issued by a bank or other financial institution</li>
        <li>
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan' ) }>Term of the loan&nbsp;</span>
          {' '}
          is typically 10 years (120 months)
        </li>
        <li>
          Interest will&nbsp;
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue&nbsp;</span>
          {' '}
          on this loan while you are in school and in&nbsp;

          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grace Period' ) }>grace period</span>
        </li>
        <li>Example: if you borrow $10,000 in private student loans and make no payments during school, your balance will be higher than $10,000 when the grace period ends</li>
      </ul>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>

  );
};

PrivateStudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( PrivateStudentLoans );
