import LayoutWithoutSidebar from '../components/layout/without-sidebar';
import LayoutForInkwiryChallenge from '../components/layout/without-footer-for-inkChallenge.jsx';
import AuthFooter from '../components/layout/auth-footer';
import LayoutWithOutHeader from '../components/layout/without-header';
import LayoutWithFooter from '../components/layout/with-footer';
import Home from '../screens/home';
import Challenges from '../screens/challenges';
import WhyInkwiryStudent from '../screens/why-inkwiry/student';
import WhyInkwiryParents from '../screens/why-inkwiry/parents';
import getMyHigherEdToolkit from '../screens/parents/get-my-higher-ed-toolkit';
import WhyInkwiryParentsNew from '../screens/why-inkwiry/parents-new';
import WhyInkwiryBenefits from '../screens/why-inkwiry/benefits';
import WhyInkwirySchool from '../screens/why-inkwiry/school';
import StudentParent from '../screens/student-parent';
import YoungPros from '../screens/young-pros';
import YoungProfessionals from '../screens/why-inkwiry/young-professionals';
import InkwiryBenfits from '../screens/inkwiry-benefits';
import About from '../screens/about';
import Aboutus from '../screens/aboutus';
import Team from '../screens/team';
import InkwiryQuestions from '../screens/inkwiry-questions';
import Contact from '../screens/contact';
import News from '../screens/news';
import Sense from '../screens/sense';
import Awareness from '../screens/sense/awareness';
import Discovery from '../screens/sense/discovery';
import Connect from '../screens/connect';
import GetPowerUpLite from '../screens/get-powerup-lite';
import Pricing from '../screens/pricing';
import Login from '../components/auth/forms/login/login-form';
import ForgotPassword from '../components/auth/forms/forgot-password/forgot-password-form';
import ResetPassword from '../components/auth/forms/reset-password/reset-password-form';
import Addressbook from '../screens/addressbook';
import Spaces from '../screens/spaces';
import Desktop from '../screens/desktop';
import DesktopNew from '../screens/desktop-new';
import MyAccount from '../screens/my-account';
import FinancialConfigurator from '../screens/financial-configurator';
import NavigationTool from '../screens/navigation-tool';
import Tutorials from '../screens/tutorials';
import CareerSketch from '../screens/career-sketch';
import LifeEventLibrary from '../screens/life-event-library';
import Notifications from '../screens/notifications';
import Register from '../screens/register';
import studentsParents from '../screens/students-parents-new';
import ActivateAccount from '../screens/register/success';
import Sight from '../screens/sight';
import BuildCareerSketch from '../screens/timeline-sketcher';
import Dashboard from '../screens/dashboard';
import FinancialIndependencePwi from '../screens/pwi/financial-independence';
// import HigherEducation from '../screens/pwi-higher-education';
import PwiHigherEducation from '../screens/pwi/higher-education';
import pwiMoreHigherEducation from '../screens/pwi/more-higher-education';
import Vacation from '../screens/pwi-vacation';
import Investments from '../screens/pwi-investments';
import CareerPath from '../screens/pwi-career-path';
import CareerAdvancement from '../screens/pwi-career-advancement';
import AdditionalCareer from '../screens/pwi-aca';
import FurtherCareer from '../screens/pwi-fca';
import Family from '../screens/pwi-family';
import Existing from '../screens/pwi-existing';
import BluePrint from '../screens/blueprint';
import BuyingACar from '../screens/pwi-car';
import PwiBuyingHome from '../screens/pwi/buying-a-home';
import PwiRei from '../screens/pwi/rei';
import Pf101Dashboard from '../screens/pf101/dashboard';
import Pf101NewMemberWelcome from '../screens/pf101/new-member-welcome';
import Pf101Baseline from '../screens/pf101/baseline-challenge';
import Pf101StudentFinalChallenge from '../screens/pf101/student-final-challenge';
import ChallangeOne from '../screens/pf101/challenge1';
import ChallangeTwo from '../screens/pf101/challenge2';
import ChallangeThree from '../screens/pf101/challenge3';
import Pf101Survey from '../screens/pf101/survey';
import FurtherHigherEducation from '../screens/pwi/further-higher-education';
// import MoreHigherEducation from '../screens/pwi-more-higher-education';
import FCTest from '../screens/pwi-assumptions-sheet/estimated-income-taxes';
import FI from '../screens/worksheet-modules/financial-independence';
import HE from '../screens/worksheet-modules/higher-education';
import FamilyWorksheet from '../screens/worksheet-modules/family';
import FinanceExplained from '../screens/finance-explained';
import VacationWorksheet from '../screens/worksheet-modules/vacation';
import InvestmentsWorksheet from '../screens/worksheet-modules/investments';
import BuyingACarWorksheet from '../screens/worksheet-modules/buying-a-car';
import BuyingAHomeWorksheet from '../screens/worksheet-modules/buying-a-home';
import reiWorksheet from '../screens/worksheet-modules/rei';
import existingAssetsLiabilitiesSheet from '../screens/worksheet-modules/existing-assets-liabilities';
import moreHigherEducationWorksheet from '../screens/worksheet-modules/more-higher-education';
import furtherHigherEducationWorksheet from '../screens/worksheet-modules/further-higher-education';
import CPWorksheet from '../screens/worksheet-modules/career-path';
import CareeradvancementWorksheet from '../screens/worksheet-modules/career-advancement';
import AdditionalCareerAdvancementWorksheet from '../screens/worksheet-modules/additional-career-advancement';
import FurtherCareerAdvancementWorksheet from '../screens/worksheet-modules/further-career-advancement';
import Ipo from '../screens/finance-explained/activities/ipo';
import StartedWithInvest from '../screens/finance-explained/activities/getting-started-with-investing';
import WillHigherInterestRatesCooltheStockMarket from '../screens/finance-explained/activities/will-higher-interest-rates-cool-the-stock-market';
import InterestRatesandHomeBuying from '../screens/finance-explained/activities/interest-rates-and-home-buying';
import StockMarketTurbulence from '../screens/finance-explained/activities/stock-market-turbulence';
import BestandWorst2021Investments from '../screens/finance-explained/activities/best-and-worst-2021-investments';
import InvestingduringHighInflation from '../screens/finance-explained/activities/inflation-invest';
import KeepingUpWithInflation from '../screens/finance-explained/activities/keeping-up-with-inflation';
import InflationAndYourMoney from '../screens/finance-explained/activities/inflation-and-your-money';
import ExtraLoanPaymentt from '../screens/finance-explained/activities/extra-loan-payment';
import RefinanceLoan from '../screens/finance-explained/activities/refinance-a-loan';
import EmergencyFund from '../screens/finance-explained/activities/emergency-fund';
import AmericanRescuePlan from '../screens/finance-explained/activities/american-rescue-plan';
import GoingLongStocks from '../screens/finance-explained/activities/going-long-stocks';
import FederalIncomeTaxes2021 from '../screens/finance-explained/activities/federal-income-taxes';
import Roth401 from '../screens/finance-explained/activities/401k-vs-roth401k';
import FinancialCalculators from '../screens/financial-calculators';
import BondProfit from '../screens/financial-calculators/bond-profit';
import Budget from '../screens/financial-calculators/budget';
import SavingsGrowth from '../screens/financial-calculators/savings-growth';
import NetWorthCalcs from '../screens/financial-calculators/net-worth';
import timeValue from '../screens/financial-calculators/time-value-of-money';
import StockProfit from '../screens/financial-calculators/stock-profit';
import CarAffordability from '../screens/financial-calculators/car-affordability-calcs';
import CompareSchools from '../screens/financial-calculators/compare-schools';
import CompareCareers from '../screens/financial-calculators/compare-careers';
import MortgageRepayment from '../screens/financial-calculators/mortgage-repayment';
import RealEstateCMA from '../screens/financial-calculators/realestate-comparative-market-analysis-calcs';
import ExtraLoanPayment from '../screens/financial-calculators/extra-loan-payment';
import RealEstateAffordability from '../screens/financial-calculators/home-affordability-calcs';
import StandardLoanPayment from '../screens/financial-calculators/standard-loan-repayment';
import FederalIncomeTax from '../screens/financial-calculators/federal-income-tax';
import HowBecomeMillionaire from '../screens/finance-explained/activities/how-to-become-a-millionaire';
import SpendingSmart from '../screens/finance-explained/activities/spending-smart';
import MonetaryPolicyTwo from '../screens/finance-explained/activities/monetary-policy-part2';
import MonetaryPolicy from '../screens/finance-explained/activities/monetary-policy';
import FederalReserve from '../screens/finance-explained/activities/federal-reserve';
import Correction from '../screens/finance-explained/activities/correction';
import Dividend from '../screens/finance-explained/activities/dividend';
import StockSplit from '../screens/finance-explained/activities/stock-split';
import StandardItemizedDeductions from '../screens/finance-explained/activities/standard-or-itemized-deductions';
import FederalIncomeTaxBrackets from '../screens/finance-explained/activities/federal-income-tax-brackets';
import HEALSActHighlights from '../screens/finance-explained/activities/heals-act-highlights';
import OpportunityCost from '../screens/finance-explained/activities/opportunity-cost';
import GapYearAnalysis from '../screens/finance-explained/activities/gap-year-analysis';
import GapYear from '../screens/finance-explained/activities/gap-year';
import BullBearMarket from '../screens/finance-explained/activities/bull-vs-bear-market';
import Volatility from '../screens/finance-explained/activities/volatility';
import StandardPoor from '../screens/finance-explained/activities/sandp-500';
import MarketCapitalization from '../screens/finance-explained/activities/market-capitalization';
import DowJonesIndustrialAverage from '../screens/finance-explained/activities/dow-jones-industrial-average';
import UnemploymentRate from '../screens/finance-explained/activities/unemployment-rate';
import DollarCostAveraging from '../screens/finance-explained/activities/dollar-cost-averaging';
import RiskReward from '../screens/finance-explained/activities/risk-v-reward';
import CaresActUnemploymentBenefits from '../screens/finance-explained/activities/cares-act-unemployment-benefits';
import CaresActTaxCredit from '../screens/finance-explained/activities/cares-act-tax-credit';
import FederalStudentLoanRelief from '../screens/finance-explained/activities/federal-student-loan-relief';
import CovidYourMoney from '../screens/finance-explained/activities/covid-19-and-your-money';
import IncomeBasedRepayment from '../screens/finance-explained/activities/income-based-repayment';
import StudentLoanRepaymentPlans from '../screens/finance-explained/activities/student-loan-repayment-plans';
import GracePeriod from '../screens/finance-explained/activities/grace-period';
import SubsidizedUnsubsidizedLoans from '../screens/finance-explained/activities/subsidized-vs-unsubsidized-loans';
import StudentLoan from '../screens/finance-explained/activities/student-loan';
import CarInsuranceRequirements from '../screens/finance-explained/activities/car_insurance_requirements';
import WhatCarLeasePayment from '../screens/finance-explained/activities/whats_in_a_car_lease_payment';
import BuyLeaseCostAnalysis from '../screens/finance-explained/activities/buy-or-lease-a-cost-analysis';
import LeasingBuyingCar from '../screens/finance-explained/activities/leasing-vs-buying-a-car';
import CarLease from '../screens/finance-explained/activities/car-lease';
import SaveNowSaveLater from '../screens/finance-explained/activities/save-now-save-later';
import MoneyResolutions from '../screens/finance-explained/activities/money-resolutions';
import PowerFinancialLiteracy from '../screens/finance-explained/activities/power-of-financial-literacy';
import RiskTolerance from '../screens/finance-explained/activities/risk-tolerance';
import Diversification from '../screens/finance-explained/activities/diversification';
import InvestmentPortfolio from '../screens/finance-explained/activities/investment-portfolio';
import StockExchange from '../screens/finance-explained/activities/stock-exchange';
import StockMarket from '../screens/finance-explained/activities/stock_market';
import SmartGoals from '../screens/finance-explained/activities/smart_goals';
import InsuringYourCarHome from '../screens/finance-explained/activities/asset_insurance';
import LifeInsurance from '../screens/finance-explained/activities/life_insurance_101';
import HealthInsurance from '../screens/finance-explained/activities/health_insurance';
import RetirementAccounts from '../screens/finance-explained/activities/retirement-accounts';
import FundsAltInvestments from '../screens/finance-explained/activities/funds-alternative-investments';
import StocksBonds from '../screens/finance-explained/activities/stocks-bonds';
import Investing from '../screens/finance-explained/activities/investing-101';
import FilingYourTaxes from '../screens/finance-explained/activities/filing-your-taxes';
import FederalIncomeTaxes from '../screens/finance-explained/activities/federal_income_taxes';
import PersonalIncomeTaxes from '../screens/finance-explained/activities/personal-income-taxes';
import TypesofIncome from '../screens/finance-explained/activities/three-types-of-income';
import CreditScoreMyths from '../screens/finance-explained/activities/credit-score-myths';
import CreditScoreCalculated from '../screens/finance-explained/activities/credit-score-calculated';
import InterestRate from '../screens/finance-explained/activities/interest-rate';
import LowerCostLoan from '../screens/finance-explained/activities/lower-cost-of-a-loan';
import Interest from '../screens/finance-explained/activities/interest';
import LoanPayment from '../screens/finance-explained/activities/loan-payment';
import LoanPrincipal from '../screens/finance-explained/activities/loan-principal';
import WhatLoan from '../screens/finance-explained/activities/loan';
import WhatBudget from '../screens/finance-explained/activities/budget';
import WriteCheck from '../screens/finance-explained/activities/write-a-check';
import HighYieldSavingsAccount from '../screens/finance-explained/activities/high-yield-savings-account';
import CheckingSavingsAccount from '../screens/finance-explained/activities/checking-account-vs-savings-account';
import SavingsAccount from '../screens/finance-explained/activities/savings-account';
import CheckingsAccount from '../screens/finance-explained/activities/checking-account';
import DebitCard from '../screens/finance-explained/activities/debit-card';
import CreditCard from '../screens/finance-explained/activities/credit-card';
import DebitCardCreditCard from '../screens/finance-explained/activities/debit-card-vs-credit-card';
import BalanceSheet from '../screens/finance-explained/activities/balance-sheet';
import FENetWorth from '../screens/finance-explained/activities/net-worth';
import liability from '../screens/finance-explained/activities/liability';
import Asset from '../screens/finance-explained/activities/asset';
import PersonalFinance from '../screens/finance-explained/activities/personal-finance';
import TurbulentMarkets from '../screens/finance-explained/activities/turbulent-markets';
import MutualFund from '../screens/finance-explained/activities/mutual-fund';
import ETF from '../screens/finance-explained/activities/etf';
import ShortSellingStocks from '../screens/finance-explained/activities/short-selling-stocks';
import CreditScore from '../screens/finance-explained/activities/credit-score';
import ReferenceGuide from '../screens/reference-guide';
import MyCareerSketch from '../screens/my-career-sketch';
import PersonalFinance101 from '../screens/personal-finance-101';
import CollegeBudget from '../screens/financial-calculators/college-budget';
import McsDashboard from '../screens/mcs/dashboard';
import McsLevelOne from '../screens/mcs/level1';
import McsLevelTwo from '../screens/mcs/level2';
import McsLevelThree from '../screens/mcs/level3';
import McsLevelFour from '../screens/mcs/level4';
import McsLevelFive from '../screens/mcs/level5';
import McsLevelSix from '../screens/mcs/level6';
import McsLevelSeven from '../screens/mcs/level7';
import WelcomeScreen from '../screens/mcs/welcome-screens';
import McsLevelOneActivityOne from '../screens/mcs/level1-activities/level1-activity1/level1-activity1';
import McsLevelOneActivityTwo from '../screens/mcs/level1-activities/level1-activity2/level1-activity2';
import McsLevelOneActivityTwoQuestions from '../screens/mcs/level1-activities/level1-activity-questions/level1-activity2-questions';
import McsLevelTwoActivityOne from '../screens/mcs/level2-activities/level2-activity1';
import McsLevelThreeActivityOne from '../screens/mcs/level3-activities/level3-activity1/level3-activity1';
import McsLevelThreeActivityTwo from '../screens/mcs/level3-activities/level3-activity2/level3-activity2';
import McsLevelThreeActivityTwoTimeline from '../screens/mcs/level3-activities/level3-activity-timeline/level3-activity2-timeline';
import McsPowerOfCareerSketching from '../screens/mcs/level6-activities';
import Renew from '../screens/renew';
import ShareEarn from '../screens/share-earn/index';
import Terms from '../components/terms-services/terms';
import Privacy from '../components/terms-services/privacy';
import Security from '../components/terms-services/security';
import PNF from '../screens/page-not-found/index';
import FrankStory from '../screens/career-stories/frank';
import FinishRegistration from '../components/student-registration/index';

import PricingSchools from '../screens/pricing/schools';
import PricingBusiness from '../screens/pricing/business';

import Pf101Level1PersonalFinance101Activity, {
  Pf101Level2ThreeTypesOfIncomeActivity,
  Pf101Level2IncomeTaxes101Activity,
  Pf101Level2FederalIncomeTaxesActivity,
  Pf101Level2FilingTaxesActivity,
  Pf101Level3Budgeting101Activity,
  Pf101Level3BudgetingGuideActivity,
  Pf101Level4CheckingSavingsActivity,
  Pf101Level4DebitCreditActivity,
  Pf101Level4BankResponsiblyActivity,
  Pf101Level5OnlineBankingActivity,
  Pf101Level5MobileBankingAppsActivity,
  Pf101Level5SafeOnlineBankingTipsActivity,
  Pf101Level6CreditScore101Activity,
  Pf101Level6DebunkingCreditScoreMythsActivity,
  Pf101Level6Loan101Activity,
  Pf101Level6StudentLoansActivity,
  Pf101Level6LoanRepaymentActivity,
  Pf101Level6LowerCostOfLoanActivity,
  Pf101Level7Investing101Activity,
  Pf101Level7StocksAndBondsActivity,
  Pf101Level7FundsAlternativeInvestmentsActivity,
  Pf101Level7RetirementAccountsActivity,
  Pf101Level7HowToStartInvestingActivity,
  Pf101Level8HealthInsuranceActivity,
  Pf101Level8LifeInsuranceActivity,
  Pf101Level8AssetInsuranceActivity,
  Pf101Level9SmartGoalsActivity,
  Pf101Level9PersonalFinanceWithAPurposeActivity,
} from '../screens/pf101/activities';
import Pf101Level1PersonalFinance101Exercise, {
  Pf101Level2ThreeTypesOfIncomeExercise,
  Pf101Level2IncomeTaxes101Exercise,
  Pf101Level2FederalIncomeTaxesExercise,
  Pf101Level2FilingTaxesExercise,
  Pf101Level3Budgeting101Exercise,
  Pf101Level3BudgetingGuideExercise,
  Pf101Level4CheckingSavingsExercise,
  Pf101Level4DebitCreditExercise,
  Pf101Level4BankResponsiblyExercise,
  Pf101Level5OnlineBankingExercise,
  Pf101Level5MobileBankingExercise,
  Pf101Level5SafeOnlineBankingTipsExercise,
  Pf101Level6CreditScore101Exercise,
  Pf101Level6DebunkingCreditScoreMythsExercise,
  Pf101Level6Loan101Exercise,
  Pf101Level6StudentLoansExercise,
  Pf101Level6LoanRepaymentExercise,
  Pf101Level6LowerCostOfLoanExercise,
  Pf101Level7Investing101Exercise,
  Pf101Level7StockAndBoundsExercise,
  Pf101Level7FundsAlternativeInvestmentsExercise,
  Pf101Level7RetirementAccountsExercise,
  Pf101Level7HowToStartInvestingExercise,
  Pf101Level8HealthInsuranceExercise,
  Pf101Level8LifeInsuranceExercise,
  Pf101Level8AssetInsuranceExercise,
  Pf101Level9SmartGoalsExercise,
  Pf101Level9PersonalFinancePurposeExercise,
  Pf101Level9Pf101BluePrintExercise,
  Pf101Level10FinancialLifeToolkit,
} from '../screens/pf101/exercises';

import {
  MikeStory,
  EricaStory,
  TimStory,
  AmberStory,
  JayStory,
  KatrinaStory,
  LauraStory,
  DavidStory,
  MichelleStory,
  TaylorStory,
  RobertStory,
  SantiagoStory,
  JenniferStory,
  MonicaStory,
  JeremyStory,
  SamsoftStory,
  KatelynStory,
  AaronStory,
  AnitaStory,
  BrookeStory,
  CalvinStory,
} from '../screens/career-stories';
import Budgeting101Log from '../screens/pf101/budgeting101';
import Budgeting101LogEx from '../screens/pf101/exercises/without-login-budget/budgeting-101/index';

export const publicRoutes = [
  {
    key: 'home',
    exact: true,
    path: '/',
    component: Home,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Terms',
    exact: true,
    path: '/termsofservice',
    component: Terms,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Privacy',
    exact: true,
    path: '/privacy',
    component: Privacy,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Security',
    exact: true,
    path: '/security',
    component: Security,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Chanllenges',
    exact: true,
    path: '/inkwiry-challenge',
    component: Challenges,
    layout: LayoutForInkwiryChallenge,
  },
  {
    key: 'WhyInkwiryStudent',
    exact: true,
    path: '/why-inkwiry/students',
    component: WhyInkwiryStudent,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhyInkwiryParents',
    exact: true,
    path: '/why-inkwiry/parents',
    component: WhyInkwiryParentsNew,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'getMyHigherEdToolkit',
    exact: true,
    path: '/parents/get-my-higher-ed-toolkit',
    component: getMyHigherEdToolkit,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhyInkwiryParentsNew',
    exact: true,
    path: '/why-inkwiry/parentsNew',
    component: WhyInkwiryParentsNew,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhyInkwiryBenefits',
    exact: true,
    path: '/why-inkwiry/benefits',
    component: WhyInkwiryBenefits,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhyInkwirySchool',
    exact: true,
    path: '/why-inkwiry/schools',
    component: WhyInkwirySchool,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StudentParent',
    exact: true,
    path: '/students-parents',
    component: studentsParents,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'YoungPros',
    exact: true,
    path: '/young-pros',
    component: YoungPros,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'YoungProfessionals',
    exact: true,
    path: '/why-inkwiry/young-professionals',
    component: YoungProfessionals,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ShareEarn',
    exact: true,
    path: '/share-and-earn',
    component: ShareEarn,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InkwiryBenfits',
    exact: true,
    path: '/inkwiry-benefits',
    component: InkwiryBenfits,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'About',
    exact: true,
    path: '/about',
    component: About,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Contact',
    exact: true,
    path: '/contact',
    component: Contact,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'news',
    exact: true,
    path: '/news',
    component: News,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InkwiryQuestions',
    exact: true,
    path: '/questions',
    component: InkwiryQuestions,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Sense',
    exact: true,
    path: '/sense',
    component: Sense,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Connect',
    exact: true,
    path: '/connect',
    component: Connect,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Awareness',
    exact: true,
    path: '/awareness',
    component: Awareness,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Discovery',
    exact: true,
    path: '/discovery',
    component: Discovery,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Connect',
    exact: true,
    path: '/connect',
    component: Connect,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'GetPowerUpLite',
    exact: true,
    path: '/get-powerup-lite',
    component: GetPowerUpLite,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Pricing',
    exact: true,
    path: '/pricing',
    component: Pricing,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ReferanlPricing',
    exact: true,
    path: '/r/:id',
    component: Pricing,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Login',
    exact: true,
    path: '/login',
    component: Login,
    layout: AuthFooter,
  },
  {
    key: 'ForgotPassword',
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
    layout: AuthFooter,
  },
  {
    key: 'ResetPassword',
    exact: true,
    path: '/reset-password/:id',
    component: ResetPassword,
    layout: AuthFooter,
  },
  {
    key: 'Spaces',
    exact: true,
    path: '/spaces',
    component: Spaces,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Addressbook',
    exact: true,
    path: '/addressbook',
    component: Addressbook,
    layout: AuthFooter,
  },
  {
    key: 'Desktop',
    exact: true,
    path: '/desktop',
    component: Desktop,
    layout: AuthFooter,
  },
  {
    key: 'DesktopNew',
    exact: true,
    path: '/desktop-new',
    component: DesktopNew,
    layout: AuthFooter,
  },
  {
    key: 'MyAccount',
    exact: true,
    path: '/my-account',
    component: MyAccount,
    layout: AuthFooter,
  },
  {
    key: 'FinancialConfigurator',
    exact: true,
    path: '/financial-configurator',
    component: FinancialConfigurator,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'NavigationTool',
    exact: true,
    path: '/navigation-tool',
    component: NavigationTool,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Tutorials',
    exact: true,
    path: '/tutorials',
    component: Tutorials,
    layout: AuthFooter,
  },
  {
    key: 'CareerSketch',
    exact: true,
    path: '/career-sketch',
    component: CareerSketch,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LifeEventLibrary',
    exact: true,
    path: '/life-event-library',
    component: LifeEventLibrary,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Notifications',
    exact: true,
    path: '/notifications',
    component: Notifications,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Register',
    exact: true,
    path: '/join',
    component: Register,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'studentsParents',
    exact: true,
    path: '/students-parents',
    component: studentsParents,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'SuccessPop',
    exact: true,
    path: '/activate_account',
    component: ActivateAccount,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Sight',
    exact: true,
    path: '/sight',
    component: Sight,
    layout: AuthFooter,
  },
  {
    key: 'BuildCareerSketch',
    exact: true,
    path: '/timeline-sketcher',
    component: BuildCareerSketch,
    layout: AuthFooter,
  },
  {
    key: 'Dashboard',
    exact: true,
    path: '/dashboard',
    component: Dashboard,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FinancialIndependence',
    exact: true,
    path: '/plan-with-inkwiry/fi',
    component: FinancialIndependencePwi,
    layout: LayoutWithOutHeader,
  },
  // {
  //   key: 'HigherEducation',
  //   exact: true,
  //   path: '/plan-with-inkwiry/he',
  //   component: HigherEducation,
  //   layout: LayoutWithOutHeader,
  // },
  {
    key: 'PwiHigherEducation',
    exact: true,
    path: '/plan-with-inkwiry/he',
    component: PwiHigherEducation,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Vacation',
    exact: true,
    path: '/plan-with-inkwiry/vacation',
    component: Vacation,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'CareerPath',
    exact: true,
    path: '/plan-with-inkwiry/cp',
    component: CareerPath,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'CareerAdvancement',
    exact: true,
    path: '/plan-with-inkwiry/ca',
    component: CareerAdvancement,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Aca',
    exact: true,
    path: '/plan-with-inkwiry/aca',
    component: AdditionalCareer,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Fca',
    exact: true,
    path: '/plan-with-inkwiry/fca',
    component: FurtherCareer,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Investments',
    exact: true,
    path: '/plan-with-inkwiry/investments',
    component: Investments,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Family',
    exact: true,
    path: '/plan-with-inkwiry/family',
    component: Family,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'BluePrint',
    exact: true,
    path: '/fc-blueprint',
    component: BluePrint,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Pf101Dashboard',
    exact: true,
    path: '/pf101/dashboard',
    component: Pf101Dashboard,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101NewMemberWelcome',
    exact: true,
    path: '/new-member-welcome',
    component: Pf101NewMemberWelcome,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101NewMemberWelcome',
    exact: true,
    path: '/new-student-welcome',
    component: Pf101NewMemberWelcome,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Baseline',
    exact: true,
    path: '/pf101/baseline-challenge',
    component: Pf101Baseline,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101StudentFinalChallenge',
    exact: true,
    path: '/pf101/student-final-challenge',
    component: Pf101StudentFinalChallenge,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101StudentSurvey',
    exact: true,
    path: '/pf101/student/survey',
    component: Pf101Survey,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101MemberSurvey',
    exact: true,
    path: '/pf101/member/survey',
    component: Pf101Survey,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'ChallangeOne',
    exact: true,
    path: '/pf101/challenge01',
    component: ChallangeOne,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'ChallangeTwo',
    exact: true,
    path: '/pf101/challenge02',
    component: ChallangeTwo,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'ChallangeThree',
    exact: true,
    path: '/pf101/challenge03',
    component: ChallangeThree,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level1PersonalFinance101',
    exact: true,
    path: '/pf101/level1/personal-finance-101',
    component: Pf101Level1PersonalFinance101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level2ThreeTypesOfIncome',
    exact: true,
    path: '/pf101/level2/3-types-of-income',
    component: Pf101Level2ThreeTypesOfIncomeActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level2IncomeTaxes101',
    exact: true,
    path: '/pf101/level2/income-taxes-101',
    component: Pf101Level2IncomeTaxes101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level2FederalIncomeTaxes',
    exact: true,
    path: '/pf101/level2/federal-income-taxes',
    component: Pf101Level2FederalIncomeTaxesActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level2FilingTaxes',
    exact: true,
    path: '/pf101/level2/filing-taxes',
    component: Pf101Level2FilingTaxesActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level3Budgeting101',
    exact: true,
    path: '/pf101/level3/budgeting101',
    component: Pf101Level3Budgeting101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level3BudgetingGuide',
    exact: true,
    path: '/pf101/level3/budgeting-guide',
    component: Pf101Level3BudgetingGuideActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level4CheckingSavings',
    exact: true,
    path: '/pf101/level4/checking-savings',
    component: Pf101Level4CheckingSavingsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level4DebitCredit',
    exact: true,
    path: '/pf101/level4/debit-credit',
    component: Pf101Level4DebitCreditActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level4BankResponsibly',
    exact: true,
    path: '/pf101/level4/bank-responsibly',
    component: Pf101Level4BankResponsiblyActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level5OnlineBanking',
    exact: true,
    path: '/pf101/level5/online-banking',
    component: Pf101Level5OnlineBankingActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level5MobileBankingApps',
    exact: true,
    path: '/pf101/level5/mobile-banking-apps',
    component: Pf101Level5MobileBankingAppsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level5SafeOnlineBankingTips',
    exact: true,
    path: '/pf101/level5/safe-online-banking-tips',
    component: Pf101Level5SafeOnlineBankingTipsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6CreditScore101',
    exact: true,
    path: '/pf101/level6/credit-score-101',
    component: Pf101Level6CreditScore101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6DebunkingCreditScoreMyths',
    exact: true,
    path: '/pf101/level6/debunking-credit-score-myths',
    component: Pf101Level6DebunkingCreditScoreMythsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6Loan101',
    exact: true,
    path: '/pf101/level6/loan-101',
    component: Pf101Level6Loan101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6StudentLoans',
    exact: true,
    path: '/pf101/level6/student-loans',
    component: Pf101Level6StudentLoansActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6LoanRepayment',
    exact: true,
    path: '/pf101/level6/loan-repayment',
    component: Pf101Level6LoanRepaymentActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level6LowerCostOfLoan',
    exact: true,
    path: '/pf101/level6/lower-cost-of-loan',
    component: Pf101Level6LowerCostOfLoanActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level7Investing101',
    exact: true,
    path: '/pf101/level7/investing-101',
    component: Pf101Level7Investing101Activity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level7StocksAndBonds',
    exact: true,
    path: '/pf101/level7/stocks-and-bonds',
    component: Pf101Level7StocksAndBondsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level7FundsAlternativeInvestments',
    exact: true,
    path: '/pf101/level7/funds-alternative-investments',
    component: Pf101Level7FundsAlternativeInvestmentsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level7RetirementAccounts',
    exact: true,
    path: '/pf101/level7/retirement-accounts',
    component: Pf101Level7RetirementAccountsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level7HowToStartInvesting',
    exact: true,
    path: '/pf101/level7/how-to-start-investing',
    component: Pf101Level7HowToStartInvestingActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level8HealthInsurance',
    exact: true,
    path: '/pf101/level8/health-insurance',
    component: Pf101Level8HealthInsuranceActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level8LifeInsurance',
    exact: true,
    path: '/pf101/level8/life-insurance',
    component: Pf101Level8LifeInsuranceActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level8AssetInsurance',
    exact: true,
    path: '/pf101/level8/asset-insurance',
    component: Pf101Level8AssetInsuranceActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level9SmartGoals',
    exact: true,
    path: '/pf101/level9/smart-goals',
    component: Pf101Level9SmartGoalsActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level9PersonalFinanceWithAPurpose',
    exact: true,
    path: '/pf101/level9/personal-finance-with-a-purpose',
    component: Pf101Level9PersonalFinanceWithAPurposeActivity,
    layout: LayoutWithFooter,
  },
  {
    key: 'Pf101Level1PersonalFinance101Exercise',
    exact: true,
    path: '/pf101/level1/personal-finance-101/exercise',
    component: Pf101Level1PersonalFinance101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level2ThreeTypesOfIncomeExercise',
    exact: true,
    path: '/pf101/level2/3-types-of-income/exercise',
    component: Pf101Level2ThreeTypesOfIncomeExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level2IncomeTaxes101Exercise',
    exact: true,
    path: '/pf101/level2/income-taxes-101/exercise',
    component: Pf101Level2IncomeTaxes101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level2FederalIncomeTaxesExercise',
    exact: true,
    path: '/pf101/level2/federal-income-taxes/exercise',
    component: Pf101Level2FederalIncomeTaxesExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level2FilingTaxesExercise',
    exact: true,
    path: '/pf101/level2/filing-taxes/exercise',
    component: Pf101Level2FilingTaxesExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level3Budgeting101Exercise',
    exact: true,
    path: '/pf101/level3/budgeting101/exercise',
    component: Pf101Level3Budgeting101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level3BudgetingGuideExercise',
    exact: true,
    path: '/pf101/level3/budgeting-guide/exercise',
    component: Pf101Level3BudgetingGuideExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level4CheckingSavingsExercise',
    exact: true,
    path: '/pf101/level4/checking-savings/exercise',
    component: Pf101Level4CheckingSavingsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level4DebitCreditExercise',
    exact: true,
    path: '/pf101/level4/debit-credit/exercise',
    component: Pf101Level4DebitCreditExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level4BankResponsiblyExercise',
    exact: true,
    path: '/pf101/level4/bank-responsibly/exercise',
    component: Pf101Level4BankResponsiblyExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level5OnlineBankingExercise',
    exact: true,
    path: '/pf101/level5/online-banking/exercise',
    component: Pf101Level5OnlineBankingExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level5MobileBankingExercise',
    exact: true,
    path: '/pf101/level5/mobile-banking/exercise',
    component: Pf101Level5MobileBankingExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level5SafeOnlineBankingTipsExercise',
    exact: true,
    path: '/pf101/level5/safe-online-banking-tips/exercise',
    component: Pf101Level5SafeOnlineBankingTipsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6CreditScore101Exercise',
    exact: true,
    path: '/pf101/level6/credit-score-101/exercise',
    component: Pf101Level6CreditScore101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6DebunkingCreditScoreMythsExercise',
    exact: true,
    path: '/pf101/level6/debunking-credit-score-myths/exercise',
    component: Pf101Level6DebunkingCreditScoreMythsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6Loan101Exercise',
    exact: true,
    path: '/pf101/level6/loan-101/exercise',
    component: Pf101Level6Loan101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6StudentLoansExercise',
    exact: true,
    path: '/pf101/level6/student-loans/exercise',
    component: Pf101Level6StudentLoansExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6LoanRepaymentExercise',
    exact: true,
    path: '/pf101/level6/loan-repayment/exercise',
    component: Pf101Level6LoanRepaymentExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level6LowerCostOfLoanExercise',
    exact: true,
    path: '/pf101/level6/lower-cost-of-loan/exercise',
    component: Pf101Level6LowerCostOfLoanExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level7Investing101Exercise',
    exact: true,
    path: '/pf101/level7/investing-101/exercise',
    component: Pf101Level7Investing101Exercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level7StockAndBoundsExercise',
    exact: true,
    path: '/pf101/level7/stock-and-bounds/exercise',
    component: Pf101Level7StockAndBoundsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level7FundsAlternativeInvestmentsExercise',
    exact: true,
    path: '/pf101/level7/funds-alternative-investments/exercise',
    component: Pf101Level7FundsAlternativeInvestmentsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level7RetirementAccountsExercise',
    exact: true,
    path: '/pf101/level7/retirement-accounts/exercise',
    component: Pf101Level7RetirementAccountsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level7HowToStartInvestingExercise',
    exact: true,
    path: '/pf101/level7/how-to-start-investing/exercise',
    component: Pf101Level7HowToStartInvestingExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level8HealthInsuranceExercise',
    exact: true,
    path: '/pf101/level8/health-insurance/exercise',
    component: Pf101Level8HealthInsuranceExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level8LifeInsuranceExercise',
    exact: true,
    path: '/pf101/level8/life-insurance/exercise',
    component: Pf101Level8LifeInsuranceExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level8AssetInsuranceExercise',
    exact: true,
    path: '/pf101/level8/asset-insurance/exercise',
    component: Pf101Level8AssetInsuranceExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level9SmartGoalsExercise',
    exact: true,
    path: '/pf101/level9/smart-goals/exercise',
    component: Pf101Level9SmartGoalsExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level9PersonalFinancePurposeExercise',
    exact: true,
    path: '/pf101/level9/personal-finance-purpose/exercise',
    component: Pf101Level9PersonalFinancePurposeExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level9Pf101BluePrintExercise',
    exact: true,
    path: '/pf101/blueprint',
    component: Pf101Level9Pf101BluePrintExercise,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Existing',
    exact: true,
    path: '/plan-with-inkwiry/existing',
    component: Existing,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'BuyingACar',
    exact: true,
    path: '/plan-with-inkwiry/car',
    component: BuyingACar,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'PwiBuyingHome',
    exact: true,
    path: '/plan-with-inkwiry/home',
    component: PwiBuyingHome,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'PwiRei',
    exact: true,
    path: '/plan-with-inkwiry/rei',
    component: PwiRei,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'FurtherHigherEducation',
    exact: true,
    path: '/plan-with-inkwiry/fhe',
    component: FurtherHigherEducation,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'MoreHigherEducation',
    exact: true,
    path: '/plan-with-inkwiry/mhe',
    component: pwiMoreHigherEducation,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Level10FinancialLifeToolkit',
    exact: true,
    path: '/pf101/level10/financial-life-toolkit',
    component: Pf101Level10FinancialLifeToolkit,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'FI',
    exact: true,
    path: '/financial-independence',
    component: FI,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FCTest',
    exact: true,
    path: '/fc-test',
    component: FCTest,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'HE',
    exact: true,
    path: '/higher-education',
    component: HE,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FamilyWorksheet',
    exact: true,
    path: '/family',
    component: FamilyWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'VacationWorksheet',
    exact: true,
    path: '/vacation',
    component: VacationWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InvestmentsWorksheet',
    exact: true,
    path: '/investments',
    component: InvestmentsWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FinanceExplained',
    exact: true,
    path: '/finance-explained',
    component: FinanceExplained,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BuyingACarWorksheet',
    exact: true,
    path: '/buying-a-car',
    component: BuyingACarWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MikeStory',
    exact: true,
    path: '/career-story-mike',
    component: MikeStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'EricaStory',
    exact: true,
    path: '/career-story-erica',
    component: EricaStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'TimStory',
    exact: true,
    path: '/career-story-tim',
    component: TimStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'AmberStory',
    exact: true,
    path: '/career-story-amber',
    component: AmberStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'JayStory',
    exact: true,
    path: '/career-story-jay',
    component: JayStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'FrankStory',
    exact: true,
    path: '/career-story-frank',
    component: FrankStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'KatrinaStory',
    exact: true,
    path: '/career-story-katrina',
    component: KatrinaStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'LauraStory',
    exact: true,
    path: '/career-story-laura',
    component: LauraStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'DavidStory',
    exact: true,
    path: '/career-story-david',
    component: DavidStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'MichelleStory',
    exact: true,
    path: '/career-story-michelle',
    component: MichelleStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'TaylorStory',
    exact: true,
    path: '/career-story-taylor',
    component: TaylorStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'RobertStory',
    exact: true,
    path: '/career-story-robert',
    component: RobertStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'SantiagoStory',
    exact: true,
    path: '/career-story-santiago',
    component: SantiagoStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'JenniferStory',
    exact: true,
    path: '/career-story-jennifer',
    component: JenniferStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'MonicaStory',
    exact: true,
    path: '/career-sketch-story',
    component: MonicaStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'JeremyStory',
    exact: true,
    path: '/career-story-jeremy',
    component: JeremyStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'SamsoftStory',
    exact: true,
    path: '/career-story-samsoft',
    component: SamsoftStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'KatelynStory',
    exact: true,
    path: '/career-story-katelyn',
    component: KatelynStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'AaronStory',
    exact: true,
    path: '/career-story-aaron',
    component: AaronStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'AnitaStory',
    exact: true,
    path: '/career-story-anita',
    component: AnitaStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'BrookeStory',
    exact: true,
    path: '/career-story-brooke',
    component: BrookeStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'CalvinStory',
    exact: true,
    path: '/career-story-calvin',
    component: CalvinStory,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'BuyingAHomeWorksheet',
    exact: true,
    path: '/buying-a-home',
    component: BuyingAHomeWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WillHigherInterestRatesCooltheStockMarket',
    exact: true,
    path: '/finance-explained/will-higher-interest-rates-cool-the-stock-market',
    component: WillHigherInterestRatesCooltheStockMarket,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StartedWithInvest',
    exact: true,
    path: '/finance-explained/getting-started-with-investing',
    component: StartedWithInvest,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InterestRatesandHomeBuying',
    exact: true,
    path: '/finance-explained/interest-rates-and-home-buying',
    component: InterestRatesandHomeBuying,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StockMarketTurbulence',
    exact: true,
    path: '/finance-explained/stock-market-turbulence',
    component: StockMarketTurbulence,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BestandWorst2021Investments',
    exact: true,
    path: '/finance-explained/best-and-worst-2021-investments',
    component: BestandWorst2021Investments,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InvestingduringHighInflation',
    exact: true,
    path: '/finance-explained/inflation-invest',
    component: InvestingduringHighInflation,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'KeepingUpWithInflation',
    exact: true,
    path: '/finance-explained/keeping-up-with-inflation',
    component: KeepingUpWithInflation,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InflationAndYourMoney',
    exact: true,
    path: '/finance-explained/inflation-and-your-money',
    component: InflationAndYourMoney,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ExtraLoanPaymentt',
    exact: true,
    path: '/finance-explained/extra-loan-payment',
    component: ExtraLoanPaymentt,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RefinanceLoan',
    exact: true,
    path: '/finance-explained/refinance-a-loan',
    component: RefinanceLoan,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'AmericanRescuePlan',
    exact: true,
    path: '/finance-explained/american-rescue-plan',
    component: AmericanRescuePlan,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'EmergencyFund',
    exact: true,
    path: '/finance-explained/emergency-fund',
    component: EmergencyFund,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'GoingLongStocks',
    exact: true,
    path: '/finance-explained/going-long-stocks',
    component: GoingLongStocks,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Ipo',
    exact: true,
    path: '/finance-explained/ipo',
    component: Ipo,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalIncomeTaxes2021',
    exact: true,
    path: '/finance-explained/federal-income-taxes',
    component: FederalIncomeTaxes2021,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'HowBecomeMillionaire',
    exact: true,
    path: '/finance-explained/how-to-become-a-millionaire',
    component: HowBecomeMillionaire,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SpendingSmart',
    exact: true,
    path: '/finance-explained/spending-smart',
    component: SpendingSmart,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MonetaryPolicyTwo',
    exact: true,
    path: '/finance-explained/monetary-policy-part2',
    component: MonetaryPolicyTwo,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MonetaryPolicy',
    exact: true,
    path: '/finance-explained/monetary-policy',
    component: MonetaryPolicy,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalReserve',
    exact: true,
    path: '/finance-explained/federal-reserve',
    component: FederalReserve,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Correction',
    exact: true,
    path: '/finance-explained/correction',
    component: Correction,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Dividend',
    exact: true,
    path: '/finance-explained/dividend',
    component: Dividend,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StockSplit',
    exact: true,
    path: '/finance-explained/stock-split',
    component: StockSplit,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StandardItemizedDeductions',
    exact: true,
    path: '/finance-explained/standard-or-itemized-deductions',
    component: StandardItemizedDeductions,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalIncomeTaxBrackets',
    exact: true,
    path: '/finance-explained/federal-income-tax-brackets',
    component: FederalIncomeTaxBrackets,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'HEALSActHighlights',
    exact: true,
    path: '/finance-explained/heals-act-highlights',
    component: HEALSActHighlights,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'OpportunityCost',
    exact: true,
    path: '/finance-explained/opportunity-cost',
    component: OpportunityCost,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'GapYearAnalysis',
    exact: true,
    path: '/finance-explained/gap-year-analysis',
    component: GapYearAnalysis,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'GapYear',
    exact: true,
    path: '/finance-explained/gap-year',
    component: GapYear,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BullBearMarket',
    exact: true,
    path: '/finance-explained/bull-vs-bear-market',
    component: BullBearMarket,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Volatility',
    exact: true,
    path: '/finance-explained/volatility',
    component: Volatility,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StandardPoor',
    exact: true,
    path: '/finance-explained/sandp-500',
    component: StandardPoor,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MarketCapitalization',
    exact: true,
    path: '/finance-explained/market-capitalization',
    component: MarketCapitalization,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'DowJonesIndustrialAverage',
    exact: true,
    path: '/finance-explained/dow-jones-industrial-average',
    component: DowJonesIndustrialAverage,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'UnemploymentRate',
    exact: true,
    path: '/finance-explained/unemployment-rate',
    component: UnemploymentRate,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'DollarCostAveraging',
    exact: true,
    path: '/finance-explained/dollar-cost-averaging',
    component: DollarCostAveraging,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RiskReward',
    exact: true,
    path: '/finance-explained/risk-v-reward',
    component: RiskReward,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CaresActUnemploymentBenefits',
    exact: true,
    path: '/finance-explained/cares-act-unemployment-benefits',
    component: CaresActUnemploymentBenefits,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CaresActTaxCredit',
    exact: true,
    path: '/finance-explained/cares-act-tax-credit',
    component: CaresActTaxCredit,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalStudentLoanRelief',
    exact: true,
    path: '/finance-explained/federal-student-loan-relief',
    component: FederalStudentLoanRelief,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CovidYourMoney',
    exact: true,
    path: '/finance-explained/covid-19-and-your-money',
    component: CovidYourMoney,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'IncomeBasedRepayment',
    exact: true,
    path: '/finance-explained/income-based-repayment',
    component: IncomeBasedRepayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StudentLoanRepaymentPlans',
    exact: true,
    path: '/finance-explained/student-loan-repayment-plans',
    component: StudentLoanRepaymentPlans,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'GracePeriod',
    exact: true,
    path: '/finance-explained/grace-period',
    component: GracePeriod,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SubsidizedUnsubsidizedLoans',
    exact: true,
    path: '/finance-explained/subsidized-vs-unsubsidized-loans',
    component: SubsidizedUnsubsidizedLoans,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StudentLoan',
    exact: true,
    path: '/finance-explained/student-loan',
    component: StudentLoan,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CarInsuranceRequirements',
    exact: true,
    path: '/finance-explained/car_insurance_requirements',
    component: CarInsuranceRequirements,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhatCarLeasePayment',
    exact: true,
    path: '/finance-explained/whats_in_a_car_lease_payment',
    component: WhatCarLeasePayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BuyLeaseCostAnalysis',
    exact: true,
    path: '/finance-explained/buy-or-lease-a-cost-analysis',
    component: BuyLeaseCostAnalysis,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LeasingBuyingCar',
    exact: true,
    path: '/finance-explained/leasing-vs-buying-a-car',
    component: LeasingBuyingCar,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CarLease',
    exact: true,
    path: '/finance-explained/car-lease',
    component: CarLease,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SaveNowSaveLater',
    exact: true,
    path: '/finance-explained/save-now-save-later',
    component: SaveNowSaveLater,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MoneyResolutions',
    exact: true,
    path: '/finance-explained/money-resolutions',
    component: MoneyResolutions,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PowerFinancialLiteracy',
    exact: true,
    path: '/finance-explained/power-of-financial-literacy',
    component: PowerFinancialLiteracy,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RiskTolerance',
    exact: true,
    path: '/finance-explained/risk-tolerance',
    component: RiskTolerance,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Diversification',
    exact: true,
    path: '/finance-explained/diversification',
    component: Diversification,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InvestmentPortfolio',
    exact: true,
    path: '/finance-explained/investment-portfolio',
    component: InvestmentPortfolio,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StockExchange',
    exact: true,
    path: '/finance-explained/stock-exchange',
    component: StockExchange,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StockMarket',
    exact: true,
    path: '/finance-explained/stock_market',
    component: StockMarket,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SmartGoals',
    exact: true,
    path: '/finance-explained/smart_goals',
    component: SmartGoals,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InsuringYourCarHome',
    exact: true,
    path: '/finance-explained/asset_insurance',
    component: InsuringYourCarHome,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LifeInsurance',
    exact: true,
    path: '/finance-explained/life_insurance_101',
    component: LifeInsurance,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'HealthInsurance',
    exact: true,
    path: '/finance-explained/health_insurance',
    component: HealthInsurance,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RetirementAccounts',
    exact: true,
    path: '/finance-explained/retirement-accounts',
    component: RetirementAccounts,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FundsAltInvestments',
    exact: true,
    path: '/finance-explained/funds-alternative-investments',
    component: FundsAltInvestments,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StocksBonds',
    exact: true,
    path: '/finance-explained/stocks-bonds',
    component: StocksBonds,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Investing',
    exact: true,
    path: '/finance-explained/investing-101',
    component: Investing,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FilingYourTaxes',
    exact: true,
    path: '/finance-explained/filing-your-taxes',
    component: FilingYourTaxes,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalIncomeTaxes',
    exact: true,
    path: '/finance-explained/federal_income_taxes',
    component: FederalIncomeTaxes,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PersonalIncomeTaxes',
    exact: true,
    path: '/finance-explained/personal-income-taxes',
    component: PersonalIncomeTaxes,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'TypesofIncome',
    exact: true,
    path: '/finance-explained/three-types-of-income',
    component: TypesofIncome,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CreditScoreMyths',
    exact: true,
    path: '/finance-explained/credit-score-myths',
    component: CreditScoreMyths,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CreditScoreCalculated',
    exact: true,
    path: '/finance-explained/credit-score-calculated',
    component: CreditScoreCalculated,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'InterestRate',
    exact: true,
    path: '/finance-explained/interest-rate',
    component: InterestRate,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LowerCostLoan',
    exact: true,
    path: '/finance-explained/lower-cost-of-a-loan',
    component: LowerCostLoan,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Interest',
    exact: true,
    path: '/finance-explained/interest',
    component: Interest,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LoanPayment',
    exact: true,
    path: '/finance-explained/loan-payment',
    component: LoanPayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'LoanPrincipal',
    exact: true,
    path: '/finance-explained/loan-principal',
    component: LoanPrincipal,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhatLoan',
    exact: true,
    path: '/finance-explained/loan',
    component: WhatLoan,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WhatBudget',
    exact: true,
    path: '/finance-explained/budget',
    component: WhatBudget,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'WriteCheck',
    exact: true,
    path: '/finance-explained/write-a-check',
    component: WriteCheck,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'HighYieldSavingsAccount',
    exact: true,
    path: '/finance-explained/high-yield-savings-account',
    component: HighYieldSavingsAccount,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CheckingSavingsAccount',
    exact: true,
    path: '/finance-explained/checking-account-vs-savings-account',
    component: CheckingSavingsAccount,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SavingsAccount',
    exact: true,
    path: '/finance-explained/savings-account',
    component: SavingsAccount,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CheckingsAccount',
    exact: true,
    path: '/finance-explained/checking-account',
    component: CheckingsAccount,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'DebitCard',
    exact: true,
    path: '/finance-explained/debit-card',
    component: DebitCard,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CreditCard',
    exact: true,
    path: '/finance-explained/credit-card',
    component: CreditCard,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'DebitCardCreditCard',
    exact: true,
    path: '/finance-explained/debit-card-vs-credit-card',
    component: DebitCardCreditCard,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BalanceSheet',
    exact: true,
    path: '/finance-explained/balance-sheet',
    component: BalanceSheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FENetWorth',
    exact: true,
    path: '/finance-explained/net-worth',
    component: FENetWorth,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'liability',
    exact: true,
    path: '/finance-explained/liability',
    component: liability,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Asset',
    exact: true,
    path: '/finance-explained/asset',
    component: Asset,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PersonalFinance',
    exact: true,
    path: '/finance-explained/personal-finance',
    component: PersonalFinance,
    layout: LayoutWithoutSidebar,
  },

  {
    key: 'reiWorksheet',
    exact: true,
    path: '/rei',
    component: reiWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'existingAssetsLiabilitiesSheet',
    exact: true,
    path: '/existing-assets-liabilities',
    component: existingAssetsLiabilitiesSheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'moreHigherEducationWorksheet',
    exact: true,
    path: '/more-higher-education',
    component: moreHigherEducationWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'furtherHigherEducationWorksheet',
    exact: true,
    path: '/further-higher-education',
    component: furtherHigherEducationWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CPWorksheet',
    exact: true,
    path: '/career-path',
    component: CPWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'roth401K',
    exact: true,
    path: '/finance-explained/401k-vs-roth401k',
    component: Roth401,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CareeradvancementWorksheet',
    exact: true,
    path: '/career-advancement',
    component: CareeradvancementWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'AdditionalCareerAdvancementWorksheet',
    exact: true,
    path: '/additional-career-advancement',
    component: AdditionalCareerAdvancementWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FurtherCareerAdvancementWorksheet',
    exact: true,
    path: '/further-career-advancement',
    component: FurtherCareerAdvancementWorksheet,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FinancialCalculators',
    exact: true,
    path: '/financial-calculators',
    component: FinancialCalculators,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'BondProfit',
    exact: true,
    path: '/financial-calculators/bond-profit',
    component: BondProfit,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Budget',
    exact: true,
    path: '/financial-calculators/budget',
    component: Budget,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'SavingsGrowth',
    exact: true,
    path: '/financial-calculators/savings-growth',
    component: SavingsGrowth,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'NetWorthCalcs',
    exact: true,
    path: '/financial-calculators/net-worth',
    component: NetWorthCalcs,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'timeValue',
    exact: true,
    path: '/financial-calculators/time-value-of-money',
    component: timeValue,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StockProfit',
    exact: true,
    path: '/financial-calculators/stock-profit',
    component: StockProfit,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CarAffordability',
    exact: true,
    path: '/financial-calculators/car-affordability-calcs',
    component: CarAffordability,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CompareSchools',
    exact: true,
    path: '/financial-calculators/compare-schools',
    component: CompareSchools,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CompareCareers',
    exact: true,
    path: '/financial-calculators/compare-careers',
    component: CompareCareers,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MortgageRepayment',
    exact: true,
    path: '/financial-calculators/mortgage-repayment',
    component: MortgageRepayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RealEstateCMA',
    exact: true,
    path: '/financial-calculators/realestate-comparative-market-analysis-calcs',
    component: RealEstateCMA,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ExtraLoanPayment',
    exact: true,
    path: '/financial-calculators/extra-loan-payment',
    component: ExtraLoanPayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'RealEstateAffordability',
    exact: true,
    path: '/financial-calculators/home-affordability-calcs',
    component: RealEstateAffordability,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'StandardLoanPayment',
    exact: true,
    path: '/financial-calculators/loan-repayment',
    component: StandardLoanPayment,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'FederalIncomeTax',
    exact: true,
    path: '/financial-calculators/federal-income-tax',
    component: FederalIncomeTax,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'TurbulentMarkets',
    exact: true,
    path: '/finance-explained/turbulent-markets',
    component: TurbulentMarkets,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MutualFund',
    exact: true,
    path: '/finance-explained/mutual-fund',
    component: MutualFund,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ETF',
    exact: true,
    path: '/finance-explained/etf',
    component: ETF,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ShortSellingStocks',
    exact: true,
    path: '/finance-explained/short-selling-stocks',
    component: ShortSellingStocks,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'ReferenceGuide',
    exact: true,
    path: '/kpi-glossary',
    component: ReferenceGuide,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CreditScore',
    exact: true,
    path: '/finance-explained/credit-score',
    component: CreditScore,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'MyCareerSketch',
    exact: true,
    path: '/my-career-sketch',
    component: MyCareerSketch,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PersonalFinance101',
    exact: true,
    path: '/personal-finance-101',
    component: PersonalFinance101,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'CollegeBudget',
    exact: true,
    path: '/financial-calculators/college-budget',
    component: CollegeBudget,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'McsDashboard',
    exact: true,
    path: '/my-career-sketch/dashboard',
    component: McsDashboard,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelOne',
    exact: true,
    path: '/my-career-sketch/level1',
    component: McsLevelOne,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelTwo',
    exact: true,
    path: '/my-career-sketch/level2',
    component: McsLevelTwo,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelThree',
    exact: true,
    path: '/my-career-sketch/level3',
    component: McsLevelThree,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelFour',
    exact: true,
    path: '/my-career-sketch/level4',
    component: McsLevelFour,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelFive',
    exact: true,
    path: '/my-career-sketch/level5',
    component: McsLevelFive,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelSix',
    exact: true,
    path: '/my-career-sketch/level6',
    component: McsLevelSix,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelSeven',
    exact: true,
    path: '/my-career-sketch/level7',
    component: McsLevelSeven,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelOneActivityOne',
    exact: true,
    path: '/my-career-sketch/level1/activity1',
    component: McsLevelOneActivityOne,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelOneActivityTwo',
    exact: true,
    path: '/my-career-sketch/level1/activity2',
    component: McsLevelOneActivityTwo,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelOneActivityTwoQuestions',
    exact: true,
    path: '/my-career-sketch/level1/activity2/questions',
    component: McsLevelOneActivityTwoQuestions,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelTwoActivityOne',
    exact: true,
    path: '/my-career-sketch/level2/activity1',
    component: McsLevelTwoActivityOne,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelThreeActivityOne',
    exact: true,
    path: '/my-career-sketch/level3/activity1',
    component: McsLevelThreeActivityOne,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelThreeActivityTwo',
    exact: true,
    path: '/my-career-sketch/level3/activity2',
    component: McsLevelThreeActivityTwo,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsLevelThreeActivityTwoTimeline',
    exact: true,
    path: '/my-career-sketch/level3/activity2/timeline',
    component: McsLevelThreeActivityTwoTimeline,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'WelcomeScreen',
    exact: true,
    path: '/my-career-sketch/welcome',
    component: WelcomeScreen,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'McsPowerOfCareerSketching',
    exact: true,
    path: '/my-career-sketch/level06/activity/power-of-career-sketching',
    component: McsPowerOfCareerSketching,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Renew',
    exact: true,
    path: '/renew',
    component: Renew,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Aboutus',
    exact: true,
    path: '/aboutus',
    component: Aboutus,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Team',
    exact: true,
    path: '/team',
    component: Team,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PricingSchools',
    exact: true,
    path: '/pricing/schools',
    component: PricingSchools,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'PricingBusiness',
    exact: true,
    path: '/pricing/business',
    component: PricingBusiness,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'Budgeting101Log',
    exact: true,
    path: '/pf101/test-drive-budgeting-101',
    component: Budgeting101Log,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'Pf101Budgeting101Exercise',
    exact: true,
    path: '/pf101/test-drive-budgeting-101/exercise',
    component: Budgeting101LogEx,
    layout: LayoutWithOutHeader,
  },
  {
    key: 'FinishRegistration',
    exact: true,
    path: '/finish-registration',
    component: FinishRegistration,
    layout: LayoutWithOutHeader,
  },
  {
    path: '*',
    component: PNF,
    layout: LayoutWithOutHeader,
  },
];

export const privateRoutes = [];
