import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import Cash_Flow_Movement_1 from '../../assets/img/pwi-existing/Cash_Flow_Movement_1.png';

const MaxCashBalance = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject, handleDefinationPopup } = props;
  const [maxCash, setMaxCash] = React.useState(0);

  React.useEffect(() => {
    setMaxCash(modulesServices.module18Data.existing_max_balance);
  }, [])

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    setMaxCash(newvalue);
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        In your financial life plan, as explore your future, your cash balance will grow each year that you spend less than you earn. 
        But holding a lot of cash in your checking account usually isn’t the best use of your money. 
        Checking accounts typically pay very little to no interest on your balance.
      </p>
      <p className={ classes.existingSpace }>
        You should hold enough cash in your checking account to pay two to three months of monthly living expenses. 
        The financial engine that allows you to plan – the Financial Configurator – will automatically invest your cash 
        when it reaches the maximum balance you enter below.
      </p>
      
      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ `${classes.sliderBottomSpace} ${classes.sliderBottomSpaceNewOne} ${classes.sliderBottom30}` }> */}
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Max Cash Balance before Investment' ) }>Max cash balance before Investment</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.maxCashArray() }
                  min={ 0 }
                  max={ 50000 }
                  step={ 1 }
                  value={ maxCash }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( maxCash )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'existing_max_balance', 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ maxCash } onValueChange={ ( e ) => updateValue( e, '', 'existing_max_balance', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
      <p className={classes.mr40}>Review the graphic below to discover how the Financial Configurator automates investing with a max cash balance.</p>
      <div class="image_fi"> 
        <div class="light_gallery_img"> 
          <div class="image_black_border"> 
            <div> 
              <img class="image_flow_moment" src={Cash_Flow_Movement_1} alt="Cash_Flow_Movement_1"/> 
            </div> 
          </div> 
        </div> 
      </div>
    </div>
  );
};

MaxCashBalance.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( MaxCashBalance );
