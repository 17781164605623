import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();

const DebitCredit = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-card-credit-card.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Debit vs Credit Card</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-credit-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classNames( classes.coverAnything, classes.noTopSpace ) }><b>What&apos;s the difference?</b></p>
                        <p className={ classNames( classes.purchaseText, classes.noBottomSpace ) }>
                          When you make a purchase with your debit card, you pull money directly from your checking account. When you make a purchase with a credit card, you pay with borrowed money that you agree to pay back later. Debit and credit cards are a part of everyday life. Understanding the difference between the two and how they work is essential to financial independence.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>Debit card</b>
                        <br />
                        A debit card is a payment card issued by a bank or other financial institution that is linked to your checking account and pulls money directly from your checking account to purchase goods and services. Plus, debit cards are most commonly used to withdraw cash from an ATM. What does that actually mean? When you buy $20 of groceries with your debit card, the grocery store will be paid $20 from your checking account.
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          The main benefits of a debit card are:
                          <ol className={ classes.noMargin }>
                            <li>
                              Avoid fees such as an annual fee, interest charges, foreign transaction fees and more that you might incur with a credit card,
                            </li>
                            <li>
                              You don&apos;t carry any debt like a credit card, and
                            </li>
                            <li>
                              The money leaves your account right away helping you stay more accountable for your spending.
                            </li>
                          </ol>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>Credit card</b>
                        <br />
                        Credit is much different. A credit card allows you to make purchases with borrowed money that you agree to pay back to the bank or financial institution that issued you the credit card. What does that actually mean? When you buy $20 of groceries with your credit card,
                        your credit card company pays the grocery store the $20 and your balance owed goes up by $20. If you don&apos;t repay your credit card balance before the payment due date, your bank will charge you interest on the outstanding balance.
                      </p>
                    </div>
                    <div className={ classNames( classes.prosCons, classes.proTable ) }>
                      <Table>
                        <thead>
                          <tr>
                            <th>Pros</th>
                            <th>Cons</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>If you use your credit card responsibly and always make your payments on time, you can build and improve your credit score</td>
                            <td>Missing payments and/or using all of your credit limit can hurt your credit score</td>
                          </tr>
                          <tr>
                            <td>Credit cards provide fraud protection and other protections that debit cards and cash do not</td>
                            <td>Credit cards sometimes charge annual fees, late fees, and other fees</td>
                          </tr>
                          <tr>
                            <td>Credit cards are more convenient to carry around than cash</td>
                            <td>The convenience of credit cards can lead to spending more than you should</td>
                          </tr>
                          <tr>
                            <td>Credit cards offer rewards such as 1% cash back on all purchases and more</td>
                            <td>Missing payments leads to very high interest charges of 15-30%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>What happens if I don&apos;t repay my credit card on time?</b>
                        <br />
                        Let&apos;s say you have a fancy night out with friends and spend $300 for dinner and a movie on your credit card. When your payment comes due, you forget to or can&apos;t repay your $300 credit card balance. What happens?
                        <p>
                          When you miss a payment, your credit card company immediately starts charging you interest the next day and every day after that until you repay your late balance in full. Every day interest is added to your balance and then recalculated. See the formulas below for daily interest:
                        </p>
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          Daily Interest Rate = Annual Interest Rate ÷ 365 days
                          <br />
                          One Day of Interest = Late Balance x Daily Interest Rate
                          <br />
                          Next Day Balance = One Day of Interest + Late Balance
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        How much would you owe in a day, 10 days, or a year if the credit card company charged you 30% annual interest rate on your $300 late balance? Check out the table below to see how the amount you would owe grows over time.
                      </p>
                    </div>
                    <div className={ classes.balanceTable }>
                      <Table>
                        <thead>
                          <tr>
                            <th />
                            <th>Beginning Balance</th>
                            <th>Interest Charged</th>
                            <th>Ending Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Day 1</td>
                            <td>$300.00</td>
                            <td>$0.2466</td>
                            <td>$300.25</td>
                          </tr>
                          <tr>
                            <td>Day 10</td>
                            <td>$302.23</td>
                            <td>$0.2484</td>
                            <td>$302.47</td>
                          </tr>
                          <tr>
                            <td>Day 20</td>
                            <td>$304.72</td>
                            <td>$0.2505</td>
                            <td>$304.97</td>
                          </tr>
                          <tr>
                            <td>Day 30</td>
                            <td>$307.23</td>
                            <td>$0.2525</td>
                            <td>$307.49</td>
                          </tr>
                          <tr>
                            <td>Day 180</td>
                            <td>$347.53</td>
                            <td>$0.2856</td>
                            <td>$347.81</td>
                          </tr>
                          <tr>
                            <td>Day 365</td>
                            <td>$404.58</td>
                            <td>$0.3325</td>
                            <td>$404.91</td>
                          </tr>
                          <tr>
                            <td>Day 730</td>
                            <td>$546.05</td>
                            <td>$0.4488</td>
                            <td>$546.50</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        Every day after your payment due date that you don&apos;t repay your credit card balance, interest accrues and the amount you owe will continue to grow. If you didn&apos;t repay that $300 night out until 12 months later, you would owe $404.91!
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level4/debit-credit/exercise" startTime={ startTime } level={ 4 } exercise={ 2 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DebitCredit.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( DebitCredit );
