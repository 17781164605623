import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StudentLoan = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Student Loan</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/whats-student-loan.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span> Student Loan
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/whats-student-loans-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          A student loan is money provided by the federal
                          government or a financial institution to help you pay
                          for the cost of education. Among the Class of 2018,
                          69% of college students took out student loans – both
                          federal and private - and they graduated with an
                          average debt of $29,800 and a median monthly payment
                          of $222, according to{' '}
                          <a
                            href="https://studentloanhero.com/student-loan-debt-statistics/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Student Loan Hero
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Before you choose to attend college or other higher
                          education, it&apos;s crucial to understand student
                          loans and how they will play a part in funding your
                          education. Let’s start with the two types of student
                          loans – federal and private.
                        </p>
                        <p>
                          <strong>Federal Student Loans</strong>
                          <br />A federal student loan is money provided by the
                          government to help you pay for your education. Like
                          any other loan, you will have to repay this money over
                          time with{' '}
                          <Link
                            to="/finance-explained/interest"
                            target="_blank">
                            interest
                          </Link>
                          . There are also two types of federal student loans -
                          subsidized and unsubsidized.
                        </p>
                        <p>
                          Subsidized loans do not charge{' '}
                          <Link
                            to="/finance-explained/interest"
                            target="_blank">
                            interest
                          </Link>{' '}
                          during school or in your grace period and are the
                          cheapest of all student loans. Unsubsidized loans
                          charge interest from the day you receive the money,
                          which means interest will start to accrue during
                          school. An easy way to think of{' '}
                          <Link
                            to="/finance-explained?type=definitions"
                            target="_blank">
                            accrued interest
                          </Link>{' '}
                          is if you don’t make monthly payments on your loan,
                          the amount that you owe will increase.
                        </p>
                        <p>
                          <strong>Private Student Loans</strong>
                          <br />
                          The main difference between federal and private
                          student loans is the institution that loans you money.
                          For federal loans, the government loans you the money.
                          But for private student loans, a financial institution
                          such as a bank loans you the money. What do you think
                          the pros and cons are of a private student loan versus
                          a federal student loan?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StudentLoan.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentLoan);
