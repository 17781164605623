import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../../screens/pwi-family/styles';
import * as modulesServices from '../../../calculations/modules-services';

const SpouseCareerSecond = ( props ) => {
  const {
    classes, handleDefinationPopup, updateData, inputData
  } = props;

  const [spouseOccupation, setSpouseOccupation] = useState( (inputData.occupation !== undefined && inputData.occupation !== '') ? inputData.occupation :'' );
  const [spouseStartCareerYear, setSpouseStartCareerYear] = useState( 0 );
  const [spouseYearDiff, setSpouseYearDiff] = useState( 0 );
  const [startYearOptions, setStartYearOptions] = useState([]);
  const [noOfYears, setNoOfYears] = useState([]);
  const [endYear,setEndYear] = useState('');
  
  useEffect(()=>{
    let start_year = parseInt(modulesServices.module1Data.start_year);
    const yearsDiffOptions = [0];
    let inputDataValues = {...inputData};
    inputDataValues['start_year'] =  spouseStartCareerYear;
    if(inputData.marriege_year !== undefined && inputData.marriege_year !== null && inputData.marriege_year > 0 ) {
      if(spouseStartCareerYear > 0) {
        const diff = start_year-spouseStartCareerYear+15;
        for (let i = 1; i <= diff; i+=1) {
          yearsDiffOptions.push(i);
        }
        if(spouseStartCareerYear !== undefined  && inputData['end_year'] !== undefined && spouseStartCareerYear >= 0  && inputData['end_year'] >= 0) {
          inputDataValues['years_diff'] = inputData['end_year']-inputData['start_year']+1;
        } else {
          inputDataValues['years_diff'] = 0;
        }
        let index = yearsDiffOptions.indexOf(inputDataValues['years_diff']);
        if(inputDataValues['years_diff'] > 0 && index === -1) {
          inputDataValues['years_diff'] = yearsDiffOptions[yearsDiffOptions.length-1];
        }
      } else {
        inputDataValues['end_year'] = 0;
        inputDataValues['years_diff'] = 0;  
      }
    } else {
      inputDataValues['end_year'] = 0;
      inputDataValues['years_diff'] = 0;
    }
    if(spouseStartCareerYear > 0 && inputDataValues['years_diff'] > 0) {
      inputDataValues['end_year'] = spouseStartCareerYear+inputDataValues['years_diff']-1;
    }
    setNoOfYears(yearsDiffOptions);
    setSpouseYearDiff(inputDataValues['years_diff']);
    setEndYear( inputDataValues['end_year']);
    updateData('total_obj',inputDataValues);

    // eslint-disable-next-line
  },[spouseStartCareerYear])


  useEffect( () => {
    let inputDetails = {...inputData}
    const familtStartYearOptions = [0];
    if(inputData.marriege_year !== undefined && inputData.marriege_year !== null && inputData.marriege_year !== '' && inputData.marriege_year > 0) {
      for (let i = inputData.marriege_year; i <= modulesServices.module1Data.start_year+14; i+=1) {
        familtStartYearOptions.push(i);
      }
      if(inputData['start_year'] === undefined || inputData['start_year'] === null) {
        inputData['start_year'] = 0;
      }
      if(inputData['start_year'] > 0 ) {
        const yearIndex = familtStartYearOptions.indexOf(inputData['start_year']);
        if(yearIndex === -1) {
          inputData['start_year'] = familtStartYearOptions[1];
        }
      }
      if(inputData['end_year'] === undefined || inputData['end_year'] === null) {
        inputData['end_year'] = 0;
      }
      if(inputData['start_year'] !== undefined  && inputData['end_year'] !== undefined && inputData['start_year'] >= 0  && inputData['end_year'] >= 0) {
        inputDetails['years_diff'] = inputData['end_year']-inputData['start_year']+1;
      } else {
        inputDetails['years_diff'] = 0;
      }

    } else {
      inputDetails['start_year'] = 0;
      inputDetails['end_year'] = 0;
      inputDetails['years_diff'] = 0;
    }
    updateData('total_obj',inputDetails);

    setSpouseStartCareerYear(inputDetails['start_year']);
    setSpouseYearDiff(inputDetails['years_diff']);
    setEndYear( inputDetails['end_year']);
    setStartYearOptions(familtStartYearOptions);
    // eslint-disable-next-line
  }, [] );
    

  const handleSpousesOccupation = ( e ) => {
    setSpouseOccupation( e.target.value );
    updateData( 'occupation', e.target.value );
  };

  const handleSpouseStartCareerYear = ( e ) => {
    setSpouseStartCareerYear( parseInt(e.target.value, 10) );
  };

  const handleSpouseCareerYearCount = ( e ) => {
    let inputDetails = {...inputData};
    inputDetails['years_diff'] = parseInt(e.target.value, 10);
    inputDetails['end_year'] =  inputDetails['start_year']+inputDetails['years_diff']-1;
    setEndYear(inputDetails['start_year']+inputDetails['years_diff']-1);
    setSpouseYearDiff( parseInt(e.target.value, 10) );
    updateData( 'total_obj', inputDetails );
  };  

  return (
    <div className={ classes.contentBlock }>
      <p>When you get married, will your spouse be working? If so, how long do you expect your spouse to work in this position before making another career move?</p>
      <p>If your spouse is not planning to work after you get married, please use the skip button below.</p>
      <div className={ `${classes.grayBox} ${classes.grayBoxHalf}` }>
        <div className={ classes.occupationName }>
          <span>
            <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Occupation' ) }>Occupation:</span>
            {' '}
            <input type="text" placeholder="ex. Real Estate Broker" value={ spouseOccupation } onChange={ handleSpousesOccupation } />
          </span>
        </div>
        <ul className={ classes.yearsList }>
          <li>
            <span>My spouse will start this career move in year&nbsp;</span>
            <select value={ spouseStartCareerYear } onChange={ handleSpouseStartCareerYear }>
              {
                startYearOptions.map( ( yr ) => <option value={ yr }>{yr}</option> )
              }
            </select>
          </li>
          <li>
            <span>My spouse will be in this career move for&nbsp;</span>
            <select value={ spouseYearDiff } onChange={ handleSpouseCareerYearCount }>
              {
                noOfYears && noOfYears.map( ( cnt ) => <option value={ cnt }>{cnt}</option> )
              }
            </select>
                  &nbsp;years.
          </li>
          {!Number.isNaN( endYear ) && endYear > 0 && (
          <li>
            <span>
              The end year for this career move is &nbsp;
              {endYear}
              .
            </span>
          </li>
          ) }
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module10"/>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SpouseCareerSecond.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles( styles )( SpouseCareerSecond );
