import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField
} from '@material-ui/core';
// import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-aca/styles';
// import * as sliderValues from '../../../utilities/existingsliders';
import * as commonFunctions from '../../../utilities/commonFunctions';
// import TickIcon from '../../../assets/img/career-path/401k_1_pic.png';
// import WrongIcon from '../../../assets/img/career-path/401k_2_pic.png';
// import tableImage from '../../../assets/img/pwi-car/pwi-retirement-table.png';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];

const PersonalArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
  {
    value: 40,
    scaledValue: 40,
    label: '40%',
  },
  {
    value: 60,
    scaledValue: 60,
    label: '60%',
  },
  {
    value: 80,
    scaledValue: 80,
    label: '80%',
  },
  {
    value: 100,
    scaledValue: 100,
    label: '100%',
  },
];

const RetirementPlanning401 = (props) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;

  // const [iconStatus, setIconStatus] = React.useState( false );
  const [personalValue, setPeronalValue] = React.useState(5.00);
  const [personalRothValue, setPeronalRothValue] = React.useState(5.00);
  const [macthingValue, setMatchingValue] = React.useState(0);
  const [limitValue, setLimitValue] = React.useState(5.00);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(() => {
    const updatedValues = { ...handleCareerObject };
    if (handleCareerObject.base_income !== undefined) {
      setPeronalValue(handleCareerObject.base_income);
    } else {
      updatedValues['base_income'] = 5.00;
    }
    if (handleCareerObject.base_roth_income !== undefined) {
      setPeronalRothValue(handleCareerObject.base_roth_income);
    } else {
      updatedValues['base_roth_income'] = 5.00;
    }
    if (handleCareerObject.employer_matching_rate !== undefined) {
      setMatchingValue(handleCareerObject.employer_matching_rate);
    } else {
      updatedValues['employer_matching_rate'] = 0;
    }
    if (handleCareerObject.employer_matching_limit !== undefined) {
      setLimitValue(handleCareerObject.employer_matching_limit);
    } else {
      updatedValues['employer_matching_limit'] = 5.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
  }, []);

  const updateValue = (e, value, field, type) => {
    const updatedValues = { ...handleCareerObject };
    let newvalue = 0;
    if (type === 'input') {
      newvalue = e.target.value;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
      newvalue = newvalue > 100 ? 100 : newvalue;
    }

    switch (field) {
      case 'base_income': {
        setPeronalValue(newvalue);
        break;
      }
      case 'base_roth_income': {
        setPeronalRothValue(newvalue);
        break;
      }
      case 'employer_matching_rate': {
        setMatchingValue(newvalue);
        break;
      }
      case 'employer_matching_limit': {
        setLimitValue(newvalue);
        break;
      }
      default:
        break;
    }
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>
        There are two types of 401(k)s – traditional{' '}
        <span className={classes.dottedText} onClick={() => handleDefinationPopup('401(k) Retirement Account')}>401(k)</span> and a <span className={classes.dottedText} onClick={() => handleDefinationPopup('401(k) Retirement Account')}>Roth 401(k)</span>.
        A traditional 401(k) is a tax-deductible retirement account while a Roth 401(k) is an after-tax account. How much do you plan to contribute to 401(k)s? A 403(b) has the same features of a 401(k) so enter your details for a 403(b) below as well.
      </p>
      <p>
        Change the percentage in the table below to contribute to a 401(k) or Roth 401(k) and the contribution numbers will update automatically. Then, see how your employer matching adds up.
      </p>
      <div className={`${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}`}>
        <p>
          Total Pre-Tax Base Income:{' '}
          {moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined && (moduleServices.module8Data.jobtotalPreTaxExpected_val > 0 || moduleServices.module8Data.jobtotalPreTaxExpected_val <= 0) ? (
            <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.jobtotalPreTaxExpected_val} prefix={moduleServices.module8Data.jobtotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.jobtotalPreTaxExpected_val < 0 && ')'} />
          ) : ('$0')}
          <br />
          401(k) Balance:{' '}
          {moduleServices.module8Data.beginningBalanceInYearThird !== undefined && (moduleServices.module8Data.beginningBalanceInYearThird > 0 || moduleServices.module8Data.beginningBalanceInYearThird <= 0) ? (
            <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.beginningBalanceInYearThird} prefix={moduleServices.module8Data.beginningBalanceInYearThird >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.beginningBalanceInYearThird < 0 && ')'} />
          ) : ('$0')}<br />
          Roth 401(k) Balance:{' '}
          {moduleServices.module8Data.beginningBalanceInYearThirdRoth !== undefined && (moduleServices.module8Data.beginningBalanceInYearThirdRoth > 0 || moduleServices.module8Data.beginningBalanceInYearThirdRoth <= 0) ? (
            <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.beginningBalanceInYearThirdRoth} prefix={moduleServices.module8Data.beginningBalanceInYearThirdRoth >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.beginningBalanceInYearThirdRoth < 0 && ')'} />
          ) : ('$0')}
        </p>
      </div>
      <div className={classes.contentBlock}>
        <p>Enter your monthly home expenses below.</p>
        <div className={`${classes.heigherEducationSearch} ${classes.tutionFeesTable}`}>
          <div className={`${classes.higherEducationStepFour} ${classes.tutionFeesTable70}`}>
            <table cellPadding="0" cellSpacing="0" className={classes.mb30}>
              <tbody>
                <tr className={classes.trHead}>
                  <td>401(k) Accounts</td>
                  <td>401(k)</td>
                  <td>Roth 401(k)</td>
                </tr>
                <tr>
                  <td>Personal Contribution (% of Base Income)</td>
                  <td>
                    <div className={classes.amountDollar}>
                      <NumberFormat
                        decimalScale={isFocus!=='base_income'?2:5}
                        fixedDecimalScale={isFocus!=='base_income'}
                        customInput={TextField}
                        thousandSeparator
                        value={personalValue}
                        onValueChange={(e) => updateValue(e, '', 'base_income', 'number')}
                        onFocus={(e) => { e.target.select(); setIsFocus('base_income') }}
                        onBlur={emptyIsFocus} />
                      %
                    </div>
                  </td>
                  <td>
                    <div className={classes.amountDollar}>
                      <NumberFormat
                        decimalScale={isFocus!=='base_roth_income'?2:5}
                        fixedDecimalScale={isFocus!=='base_roth_income'}
                        customInput={TextField}
                        thousandSeparator
                        value={personalRothValue}
                        onValueChange={(e) => updateValue(e, '', 'base_roth_income', 'number')}
                        onFocus={(e) => { e.target.select(); setIsFocus('base_roth_income') }}
                        onBlur={emptyIsFocus} />
                      %
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Annual Contribution</td>
                  <td>
                    <div className={classes.amountDollarRight}>
                      <span>
                        {moduleServices.module8Data.addCareerContributionBaseIncomeCalcs !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerContributionBaseIncomeCalcs} prefix={moduleServices.module8Data.addCareerContributionBaseIncomeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerContributionBaseIncomeCalcs < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={classes.amountDollarRight}>
                      <span>
                        {moduleServices.module8Data.addCareer401ContributionBaseIncomeCalcs !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401ContributionBaseIncomeCalcs} prefix={moduleServices.module8Data.addCareer401ContributionBaseIncomeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401ContributionBaseIncomeCalcs < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Max Personal Contribution by Law to 401(k) Accounts per Year</td>
                  {/* <td></td> */}
                  <td colspan="2">
                    <div className={classes.amountDollarCenter}>
                      <span>
                        {moduleServices.module8Data.maxContributionByLawAddCarrAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.maxContributionByLawAddCarrAdv} prefix={moduleServices.module8Data.maxContributionByLawAddCarrAdv >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.maxContributionByLawAddCarrAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </div>
                  </td>
                  {/* <td></td> */}
                </tr>
                <tr className={`${classes.trFoot} ${classes.unBoldText}`}>
                  <td>Total Personal Contribution</td>
                  {/* <td></td> */}
                  <td colspan="2">
                    <div className={classes.amountDollarCenter}>
                      <span>

                        {moduleServices.module8Data.addCareer401PersonalContribution !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401PersonalContribution} prefix={moduleServices.module8Data.addCareer401PersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401PersonalContribution < 0 && ')'} />
                        ) : ('$0')}

                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={classes.livingExpencesRow}>
        <div className={classes.livingExpencesDiv}>
          <div className={classes.livingExpences}>
            <div className={`${classes.mainBlock} ${classes.livingExpencesRangeFlex} ${classes.mainFullBlock} ${classes.mainFullBlockFull}`}>
              <div className={`${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleFull}`}>
                {' '}
                <span>Monthly</span>
                {' '}
              </div>
              <div className={classes.rentGraphBlock}>
                <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}`}>
                  <p><span>Personal Contribution as a % of Base Income</span></p>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={classes.annuvalIncomeGraph}>
                    <NumberFormat decimalScale={2} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={personalRothValue + personalValue} />
                    %
                  </div>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={classes.annualInput}>
                    <span>
                      {moduleServices.module8Data.addCareer401PersonalContribution !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401PersonalContribution / 12} prefix={moduleServices.module8Data.addCareer401PersonalContribution / 12 >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401PersonalContribution / 12 < 0 && ')'} />
                      ) : ('$0')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.rentGraphBlock}>
                <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}`}>
                  <p>
                    <span
                      className={classes.dottedText}
                      aria-hidden="true"
                      data-for="definationTitle"
                      data-tip="Click for a definition."
                      onClick={() => handleDefinationPopup(
                        'Employer Matching Rate on Personal Contribution: Retirement Account',
                      )}
                    >
                      Employer Matching Rate on Personal Contribution
                    </span>
                  </p>
                  <div className={classes.annuvalIncomeGraph}>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={PersonalArray}
                      min={0}
                      max={100}
                      step={0.1}
                      value={macthingValue}
                      valueLabelFormat={`${commonFunctions.perFormatter(parseFloat(macthingValue).toFixed(2))}`}
                      onChange={(e, value) => updateValue(e, value, 'employer_matching_rate', 'slider')}
                    />
                  </div>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={`${classes.annualInput} ${classes.annualInputRight}`}>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      value={macthingValue}
                      decimalScale={isFocus!=='employer_matching_rate'?2:5}
                      fixedDecimalScale={isFocus!=='employer_matching_rate'}
                      onValueChange={(e) => updateValue(e, '', 'employer_matching_rate', 'number')}
                      onFocus={(e) => {
                        e.target.select();
                        setIsFocus('employer_matching_rate')
                      }}
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={classes.annualInput}>
                    <span>
                      {moduleServices.module8Data.AddCareer401EmployerMatchingrateCalcs !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.AddCareer401EmployerMatchingrateCalcs / 12} prefix={moduleServices.module8Data.AddCareer401EmployerMatchingrateCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.AddCareer401EmployerMatchingrateCalcs < 0 && ')'} />
                      ) : ('$0')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.rentGraphBlock}>
                <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}`}>
                  <p>
                    <span
                      className={classes.dottedText}
                      aria-hidden="true"
                      data-for="definationTitle"
                      data-tip="Click for a definition."
                      onClick={() => handleDefinationPopup(
                        'Employer Matching Limit as a % of Base Income: Retirement Account',
                      )}
                    >
                      Employer Matching Limit as a % of Base Income
                    </span>
                  </p>
                  <div className={classes.annuvalIncomeGraph}>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={arrayValuesMatching}
                      min={0}
                      max={20}
                      step={0.1}
                      value={limitValue}
                      valueLabelFormat={`${commonFunctions.perFormatter(parseFloat(limitValue).toFixed(2))}`}
                      onChange={(e, value) => updateValue(e, value, 'employer_matching_limit', 'slider')}
                    />
                  </div>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={`${classes.annualInput} ${classes.annualInputRight}`}>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      value={limitValue}
                      decimalScale={isFocus!=='employer_matching_limit'?2:5}
                      fixedDecimalScale={isFocus!=='employer_matching_limit'}
                      onValueChange={(e) => updateValue(e, '', 'employer_matching_limit', 'number')}
                      onFocus={(e) => {
                        e.target.select();
                        setIsFocus('employer_matching_limit')
                      }}
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className={classes.livingExpencesMothly}>
                  <div className={classes.annualInput}>
                    <span>
                      {moduleServices.module8Data.AddCareer401EmployerMatchingLimitCalcs !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.AddCareer401EmployerMatchingLimitCalcs / 12} prefix={moduleServices.module8Data.AddCareer401EmployerMatchingLimitCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.AddCareer401EmployerMatchingLimitCalcs < 0 && ')'} />
                      ) : ('$0')}
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={classes.retirementBottomPage}>
        <div className={`${classes.rentGraphBlock} ${classes.rentGraphBlockNew}`}>
          <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}`}>
            &nbsp;
          </div>
          <div className={`${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}`}>
            {' '}
            <span>Monthly</span>
            {' '}
          </div>
          <div className={`${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}`}>
            {' '}
            <span>Annual</span>
            {' '}
          </div>
        </div>
        <div className={`${classes.rentGraphBlock} ${classes.rentGraphBlockNew}`}>
          <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}`}>
            <p className={classes.textRight}>
              <span
                className={classes.dottedText}
                aria-hidden="true"
                data-for="definationTitle"
                data-tip="Click for a definition."
                onClick={() => handleDefinationPopup(
                  '401(k) Retirement Account',
                )}
              >
                Total 401(k) Contribution
                <br />
                (
                {moduleServices.module8Data.addCareer401PersonalContribution_401k !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401PersonalContribution_401k} prefix={moduleServices.module8Data.addCareer401PersonalContribution_401k >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401PersonalContribution_401k < 0 && ')'} />
                ) : ('$0')}
                {' '}
                max contribution per year by law)
              </span>
            </p>
          </div>
          <div className={classes.livingExpencesMothly}>
            <div className={classes.annualInput}>
              <span>
                {moduleServices.module8Data.addCareer401TotalAnnualContribution / 12 !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401TotalAnnualContribution / 12} prefix={moduleServices.module8Data.addCareer401TotalAnnualContribution / 12 >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401TotalAnnualContribution / 12 < 0 && ')'} />
                ) : ('$0')}
              </span>
            </div>
          </div>
          <div className={`${classes.livingExpencesMothly} ${classes.livingExpencesMothlyNoBefore}`}>
            <div className={classes.annualInput}>

              <span>
                {moduleServices.module8Data.addCareer401TotalAnnualContribution !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareer401TotalAnnualContribution} prefix={moduleServices.module8Data.addCareer401TotalAnnualContribution >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareer401TotalAnnualContribution < 0 && ')'} />
                ) : ('$0')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={handleCareerObject} />
    </div>
  );
};

RetirementPlanning401.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setRetirementConfirmation: PropTypes.func.isRequired,
  // retirementConfirmation: PropTypes.bool.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles(styles)(RetirementPlanning401);
