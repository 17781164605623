import TickMarkBlack from '../../assets/img/pwi-existing/tick_mark.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
 fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #84a84d',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      width: '100%',
      maxWidth: '500px',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    minHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    '& > div': {
      margin: '0 0 0  auto',
    },
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '30px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  clickHere:{
    color: '#337ab7',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#f89b23',
    },
  },

  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:disabled': {
      color: '#333',
      backgroundColor: '#d5d5d5',
      border: '2px solid #d5d5d5',
      opacity: '0.8',
      cursor: 'no-drop',
      pointerEvents: 'all',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  clearBtn: {
    margin: '0 15px 0px 20px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  blueText: {
    color: '#0069aa',
  },
  fourIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '52%',
      margin: '20px auto',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {
        width: '80%',              
      },
      '& li': {
        width: 'calc(50% - 56px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        position: 'relative',       
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%', 
          margin:'15px auto',  
          boxSizing: 'border-box',    
          maxWidth: '350px',
          display: 'block',
        },
        '& span + i': {
          width: '30px',
          border: '2px solid green',
          padding: '5px',
          borderRadius: '50%',
          position: 'absolute',
          background: '#d5d5d5',
          color: 'green',
          fontSize: '30px',
          right: '0px',
          top: '0px',
        },
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',

        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#0062aa',
        },
        '&:nth-child(2)': {
          color: '#0062aa',
        },
        '&:nth-child(3)': {
          color: '#0062aa',
        },
        '&:nth-child(4)': {
          color: '#0062aa',
        },
        '& figure': {
          margin: '0',
        },
      },
    },
  },
  twoIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '52%',
      margin: '20px auto',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {
        width: '80%',            
      },
      '& li': {
        width: 'calc(50% - 80px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        boxSizing: 'border-box',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%', 
          margin:'8px 0px',      
        },
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',
        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#65b248',
          textTransform: 'uppercase',
        },
        '&:nth-child(2)': {
          color: '#f17d23',
          textTransform: 'uppercase',
        },
        '&:nth-child(3)': {
          color: '#0069aa',
        },
        '&:nth-child(4)': {
          color: '#0069aa',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '120px',
            height: '111px',
          },
        },
      },
    },
  },
  marriageSection: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '30px 0px',
  },
  marriageYear: {
    maxWidth: '170px',
    minWidth: '170px',
    paddingRight: '20px',
    '& p': {
      fontSize: '20px',
      marginBottom: '0px',
    },
  },
  yearNumber: {
    padding: '0px 30px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
      position: 'absolute',
      bottom: '-50px',    
    },
    '@media (min-width: 600px) and (max-width: 991px)': {
      padding: '0px 10px',
    },
    '& span': {
      fontSize: '18px',
    },
  },
  federalStudent: {
    marginBottom: '5px',
    marginTop: '20px',
    fontSize: '19px',
    '& b': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
  },
  federalStudentLoans: {
    paddingLeft: '0px',
    listStyle: 'none',
    marginTop: '10px',
    boxSizing: 'border-box',
    '& li': {
      width: '100%',
      fontSize: '19px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundImage: `url(${TickMarkBlack})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      paddingLeft: '35px',
      alignItems: 'center',
      paddingBottom: '13px',
      lineHeight: '1.42857143',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {
       display: 'block',       
      },
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '24px',
      }
    },
  },
  studentAnalytics: {
    width: '75%',
    margin: '0 auto',
    padding: '50px 40px 26px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',    
      boxSizing: 'border-box',
      paddingLeft: '0px',
      paddingRight: '0px',   
    },
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          borderTop: '1px solid #fff',
          fontSize: '20px',
          verticalAlign: 'middle',
          backgroundColor: '#f2f2f2',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            textAlign: 'left',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  leftPadding: {
    '& td': {
      paddingLeft: '45px !important',
      '& i': {
        color: '#333',
      },
    },
  },
  creditSummary: {
    width: '60%',
    margin: '0 auto',
    padding: '50px 40px 26px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%', 
      paddingLeft: '0px',      
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      width: '80%', 
    },
    '& table': {
      borderSpcing: '0px',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          borderTop: '1px solid #fff',
          fontSize: '20px',
          verticalAlign: 'middle',
          backgroundColor: '#f2f2f2',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            textAlign: 'left',
            borderTop: '2px solid #5686da',
            borderBottom: 'none',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  homeSummary: {
    display: 'flex',
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',      
    },
  },
  homeTable: {
    width: '50%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '15px 10px 0 0',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',      
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
    },
  },
  loanSummary: {
    width: '60%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',       
    },
  },
  amountDollar: {
    textAlign: 'right',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'flex',
      alignItems: 'center',      
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  annualAppreciation: {
    display: 'flex',
  },
  annualGraph: {
    '& img': {
      width: '100%',
    },
  },
 
  annualTxt: {
    maxWidth: '200px',
    minWidth: '200px',
    paddingRight: '20px',
    fontSize: '20px',
    boxSizing: 'border-box',
  },
  homeValue: {
    '& img': {
      width: '100%',
    },
  },
  expensesGroupScroll:{
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  livingExpenses: {
    position: 'relative',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '130px',
    },
  },
  expensesGroup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  leftBlock: {
    width: '70%',
    '& h3': {
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      textAlign: 'right',
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
    },
  },
  rightBlock: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '30%',
    '& h3': {
      marginBottom: '10px',
      fontSize: '18px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      '&:last-child': {
        textALign: 'right',
      },
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
    },
  },
  expensesGrowth: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    marginTop: '10px',
    '& p': {
      margin: '0',
      maxWidth: '200px',
      minWidth: '200px',
      '& span': {
        borderBottom: '1px dashed #0069aa',
        fontSize: '20px',
        display: 'initial',
      },
    },
    '& input': {
      width: '80px',
      border: 'none',
      borderBottom: '1px solid #0069aa',
      textAlign: 'right',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
    },
  },
  expensesGrowthNew: {
    '& > div': {
      margin: '0',
      width: '100%',
    },
  },
  expensesGraphNew: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      width: '100%',
      textAlign: 'right',
      maxWidth: '100%',
      marginTop: '0',
      fontSize: 'inherit',
    },
  },
  graphInputBlock: {
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'flex-end',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  loanBalance: {
    display: 'flex',
    position: 'relative',
  },
  loanText: {
    [theme.breakpoints.down( 'xs' )]: {
      display: 'none',
    },
    '& p': {
      minWidth: '200px',
      maxWidth: '200px',
      fontSize: '20px',
    },
  },
  loanInput: {
    position: 'relative',
    width: '100px',
    margin: '0 25px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0 5px',
    },
    '& input': {
      width: '80px',
      border: 'none',
      borderBottom: '1px solid #0069aa',
      textAlign: 'right',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
    },
    '& p': {
      margin: '10px 0px',
    },
  },
  inputDollar: {
    lineHeight: '25px',
    position: 'relative',
    left: '0',
    fontSize: '18px',
    minWidth: '10px',
    display: 'inline-block',
  },
  inputPercent: {
    position: 'absolute',
    right: '0px',
    left: 'calc(100% + 5px)',
  },
  principalValue: {
    display: 'flex',
  },
  principleText: {
    '& p': {
      width: '50px',
      minWidth: '50px',
      maxWidth: '50px',
    },
  },
  principleGraph: {
    textAlign: 'right',
    fontSize: '18px',
    width: '74%',
    paddingRight: '50px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '60%',
      paddingRight: '0px',
    },
    '& p': {
      margin: '10px 0 0 0',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal !important',
      },
    },
  },
  principleInput: {
    position: 'relative',
    '& p': {
      width: '80px',
      border: 'none',
      textAlign: 'right',
      margin: '10px 0 0 auto',
      outline: 'none',
      fontSize: '18px',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal !important',
      },
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal !important',
    },
  },
  newInput: {
    position: 'absolute',
    paddingLeft: '8px',
    minWidth: '90px',
    right: '-70px',
    textALign: 'right',
    width: '90px',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: 'calc(100% + 40px)',
      background: '#0069aa',
      left: '-30px',
      top: '-20px',
    },
  },
  newInputBorder: {

  },
  loanNumber: {
    width: '80px',
    border: 'none',
    textAlign: 'right',
    margin: '0 0 0 auto',
    outline: 'none',
    fontSize: '18px',
    display: 'inline-block',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal !important',
    },
  },
  principalIntrest: {
    textAlign: 'right',
    fontSize: '18px',
    width: '100%',
    paddingRight: '30px',
    maxWidth: '495px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '60%',
      paddingRight: '0px',
    },
    '& p': {
      margin: '10px 0px 0px auto',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal !important',
      },
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '400px',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '18px',
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        position: 'relative',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    display: 'table',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '20px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      paddingBottom: '15px',
      paddingTop: '0',
      position: 'relative',     
      [theme.breakpoints.down( 'xs' )]: {
        flexWrap: 'wrap',     
      },
      '& p': {
        margin: '0',
        minWidth: '185px',
        maxWidth: '185px',
        lineHeight: '1.42857143',
        fontSize: '20px',
        [theme.breakpoints.down( 'xs' )]: {
          minWidth: '100%',
          maxWidth: '100%',     
        },
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
          cursor: 'pointer',
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '18px',  
          },
          '& + img': {
            verticalAlign: 'middle',
          },
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
        padding: '4px 2px',
      },
    },

  },
  AnnualIncomeGrowthFull:{
    '& > div': {
      '& p':{
        [theme.breakpoints.down( 'xs' )]: {
          minWidth: '190px',
          maxWidth: '190px',    
        },
      }
    }
  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  active: {
    background: '#d9d9d9 !important',
    fontFamily: 'MuseoSans-500',
    fontweight: '500',
  },
  unactive: {
    background: '#f0f0f0 !important',
  },
  unactiveBox: {
    background: '#fffff',
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  annuvalIncomeGrowthGraph: {
    width: '42%',
    margin: '10px 25px 0',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',     
      marginLeft: '0px',   
    },
    '@media (min-width: 600px) and (max-width: 991px)': {
      margin: '10px 10px 0',
    },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      // color: '#0069aa',
      minHeight: '4px',
    },
  },
  annuvalIncomeGrowthGraphFull:{
    [theme.breakpoints.down( 'xs' )]: {
      width: '50%',
      margin: '10px 10px 0',  
    },
  },
   annuvalIncomeGraph: {
    width: '100%',
    margin: '0 25px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  mainBlock: {
    width: '100%',
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 25px',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '0px',
      marginRight: '0px',   
      marginTop: '20px',  
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  caretIcon: {
    verticalAlign: 'baseline',
    color: '#0069aa',
    margin: '0 5px 0 0',
    fontFamily: 'MuseoSans-300',
    transition: 'unset',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    borderTop: '7px dashed',
    marginTop: '3px',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
  popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  tooltipInfoTop: {
    maxWidth: '180px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  fouIconsDashBoard: {
    marginTop: '40px',    
    '& ul': {
      width: '52%',
      [theme.breakpoints.down( 'sm' )]: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',    
       },
      '@media (max-width: 767px)': {
        width: '100%', 
        paddingLeft: '0px',      
      },
    },
  },
  fourIconsBlockHigher: {
    '& ul': {
      '& li img': {
        width: '120px',
      },
    },
  },
  ofYearEducation: {
    marginBottom: '110px',
    '& > div': {
      '& P': {
        maxWidth: '180px',
        minWidth: '180px',
        paddingRight: '20px',
        [theme.breakpoints.down( 'xs' )]: {
          maxWidth: '100%',
          minWidth: '100%', 
        },
        '@media (min-width: 600px) and (max-width: 991px)': {
          paddingRight: '5px',
        },
        '& span': {
          border: 'none',
        },
        '& schoolYears': {
          maxWidth: '150px',
          minWidth: '150px',
        },
      },
    },
  },
  annuvalIncomeGrowthGraphHigher: {
    width: '56%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '50%',       
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',       
    },
  },
  opcityZero: {
    opacity: '0',
  },
  noDottedText: {
    border: 'none',
  },
  newInputCarValue: {
    position: 'relative',
    right: '0',
    textAlign: 'right',
    margin: '0 25px',
    '&:before': {
      display: 'none',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  fourIconsBlockHigherNew: {
    '& ul': {
      '& li': {
        '&:first-child': {
          color: '#84a84d',
        },
        '&:nth-child(2)': {
          color: '#1f96e0',
        },
      },
    },
  },
  deferText: {
    fontSize: '20px',
    paddingLeft: '60px',
    float: 'left',
    width: '100%',
    display: 'flex',
    marginBottom: '15px',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
     paddingLeft: '0px', 
     display: 'block',
    },
  },
  deferDesc: {
    width: '180px',
    display: 'inline-block',
    border: 'none',
    color: '#333',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& font': {
      color: '#333 !important',
    },
  },
  radioDefer: {
    height: '30px',
    lineHeight: 'inherit',
    marginTop: '25px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    marginLeft: '5%',
    width: '175px',
    textAlign: 'center',
    listStyleType: 'none',
    paddingLeft: '0px',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '0px',
    },
    '& li': {
      width: '50%',
      color: '#333',
      '& active': {
        backgroundColor: '#d9d9d9',
        fontFamily: 'MuseoSans-500',
        fontweight: '500',
      },
    },
  },
  blueBottomBorder: {
    '& td': {
      borderBottom: '2px solid #5686da',
    },
  },
  mainIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    '& img': {
      marginLeft: '5px',
      maxWidth: '20px',
      verticalAlign: 'middle',
    },
  },
  noBottomBorder: {
    '& span': {
      borderBottom: 'none !important',
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
    boxShadow: 'none',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h2': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  subListMenu: {
    width: '100%',
    listStyle: 'disc !important',
    listStylePosition: 'outside !important',
    paddingLeft: '42px !important',
    '& li': {
      display: 'list-item !important',
      fontSize: '2.0vh !important',
      paddingLeft: '0px !important',
      '@media (max-width: 3500px)': {
        fontSize: '1.58vh !important',
        lineHeight: '2.3vh',
      },
      '@media (max-width: 1400px)': {
        fontSize: '2.0vh !important',
      },
      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '11px !important',
        lineHeight: '16px',
      },
    },
  },
  poniterNone: {
    pointerEvents: 'none',
    '&:hover': {
      cursor: 'default',
    },
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  existingSpace: {
    marginBottom: '30px !important',
    '& b':{
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    }
  },
  deferSpace: {
    paddingTop: '50px',
  },
  studentAnalyticsNew: {
    '& tfoot': {
      '& tr': {
        '& th': {
          borderBottom: '2px solid #5686da',
        },
      },
    },
  },
  blueTopBorder: {
    '& th': {
      borderTop: '2px solid #5686da',
    },
  },
  aprreciationValue: {
    maxWidth: '150px',
    minWidth: '150px',
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  relativePosition: {
    position: 'relative',
  },
  perSentSymbol: {
    position: 'absolute',
    right: '-11px',
  },
  sliderBottomSpace: {
    marginBottom: '100px',
    '@media (max-width: 767px)': {
      marginBottom: '50px',
    }
  },
  noDisplay: {
    visibility: 'hidden',
  },
  graphInputPara: {
    margin: '10px 0px 0px 0px !important',
  },
  intrestModalHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      margin: '0',
      color: '#0069aa',
    },
  },
  intrestModal: {
    '& > div > div': {
      width: '100%',
      maxWidth: '1080px',
    },
    '& > div > div > div': {
      padding: '0px !important',
      overflow: 'hidden',
    },
  },
  intrestClose: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    '& span': {
      fontSize: '21px',
    },
  },
  imodalFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
  },
  imodalButton: {
    padding: '4px 5px',
    minWidth: '64px',
    borderRadius: '6px',
    fontSize: '14px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '2px solid transparent',
  },
  clearData: {
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f0ad4e',
      borderColor: '#eea236',
    },
  },
  okBtn: {
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    color: '#fff',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: '#799e41',
      borderColor: '#799e41',
    },
  },
  cancelBtn: {
    color: '#333',
    backgroundColor: '#e6e6e6',
    borderColor: '#adadad',
    '&:hover': {
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  intrestModalBody: {
    overflow: 'auto',
    padding: '35px',
    maxHeight: '407px',
    boxSizing: 'border-box',
  },
  loanHead: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '0px 15px',
    '& h3': {
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: 'MuseoSans-500',
      color: '#333',
      fontWeight: 'normal',
    },
    '&:nth-child(5)': {
      width: '25%',
      float: 'right',
      textAlign: 'right',
    },
    '&:first-child': {
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      paddingRight: '55px',
      float: 'none',
      textAlign: 'right',
    },
  },
  loanBlock: {
    color: '#333',
    fontSize: '18px',
    fontFamily: 'MuseoSans-100',
    marginTop: '7px',
    display: 'inline-block',
    boxSizing: 'border-box',
    fontWeight: '700',
    padding: '0px 15px',
    '&:nth-child(5)': {
      width: '25%',
      float: 'right',
      textAlign: 'right',
    },
    '&:first-child': {
      textAlign: 'center',
    },
    '& input': {
      borderRadius: '0',
      fontSize: '18px',
      height: '38px',
      textAlign: 'right',
      paddingRight: '10px',
      paddingLeft: '15px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
    },
    '&:nth-child(3)': {
      paddingRight: '55px',
      float: 'none',
      textAlign: 'right',
    },
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
  },
  totalRow: {
    borderTop: '2px solid #333',
    padding: '10px 0',
    fontSize: '20px',
    fontFamily: 'MuseoSans-500',
    color: '#333',
    textAlign: 'right',
  },
  modalBodyRow: {
    paddingBottom: '25px',
  },
  arrowImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  modalAverage: {
    padding: '10px 0',
    fontSize: '20px',
    fontFamily: 'MuseoSans-500',
    color: '#333',
    textAlign: 'right',
    '& p': {
      paddingRight: '96px',
      width: '100%',
      fontSize: '19px',
      boxSizing: 'border-box',
    },
  },
  clickLink: {
    color: '#337ab7',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all 0.5s ease 0s',
    '&:hover': {
      color: '#f89b23',
      textDecoration: 'underline',
    },
  },
  yesNoIcons: {
    '& ul': {
      '& li': {
        width: 'calc(50% - 33px) !important',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100% !important',  
          margin: '15px auto',
          display: 'block',
          maxWidth: '350px',
          boxSizing: 'border-box',    
        },
      },
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  twentyFont: {
    fontSize: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  sliderBottomSpaceNewOneScroll:{
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  sliderBottomSpaceNewOne: {
    maxWidth: '89%',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
      padding: '20px 15px 0px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',  
      minWidth: '700px',   
    },
  },
  newInputRightNew: {
    right: '-120px',
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  aiGraph: {
    '@media (min-width: 600px) and (max-width: 991px)': {
      marginLeft: '20px',
    }
  },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  
  tableBox: {
    display: 'table',
    width: '100%',
    margin: '60px auto',
    '@media (max-width: 767px)': {
      display: 'block',
      overflowX: 'auto',
    },
    '& table': {
      width: '100%',
      maxWidth: '550px',
      margin: '0 auto',
      fontSize: '20px',
      borderCollapse: 'collapse',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: '500',
        padding: '8px',
        fontFamily: 'MuseoSans-500',
        lineHeight: '1.42857143',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
      },
      '& tr': {
        backgroundColor: '#f2f2f2',
        borderTop: '1px solid #fff',
        '& td': {
          padding: '8px',
          '& input': {
            width: '95px',
          },
          '&:last-child': {
            textAlign: 'right',
            '& > div > div': {
              '&:hover': {
                '&:before': {
                  borderColor: '#5686da',
                },
              },
              '&:before': {
                borderColor: '#5686da',
              },
              '&:after': {
                borderColor: '#f89b22',
              },

            },
          },
        },
      },
    },
  },
  tableBoxPWINew:{
    margin: '60px auto 60px',
    '@media (max-width: 767px)': {
      margin: '30px auto 30px',
    },
    '& table':{
      margin: '0px',
      maxWidth: '600px',
    }
  },
  tableBoxPWINewHomeExpensive:{
    margin: '60px auto 60px',
    '& table':{
      margin: '0px',
      maxWidth: '760px',
    }
  },
  // amountDollar: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   width: '95px',
  //   margin: '0 0 0 auto',
  //   justifyContent: 'flex-end',
  //   '& > div': {
  //     '& > div': {
  //       '&::before': {
  //         borderBottom: '1px solid #0069aa',
  //       },
  //       '&::after': {
  //         borderBottom: '2px solid #f89b22',
  //       },
  //       '&:hover': {
  //         '&::after': {
  //           borderBottom: '2px solid #f89b22',
  //         },
  //         '&::before': {
  //           borderBottom: '2px solid #f89b22 !important',
  //         },
  //       },
  //     },
  //   },
  //   '& input': {
  //     border: 'none',
  //     borderBottom: '1px solid #5686da',
  //     fontSize: '20px',
  //     display: 'inline-block',
  //     width: '95px',
  //     textAlign: 'right',
  //     background: 'transparent',
  //     fontFamily: 'MuseoSans-300',
  //     outline: 'none',
  //     paddingTop: '0',
  //     paddingBottom: '2px',
  //     paddingLeft: '5px',
  //   },
  // },
  trFoot: {
    background: '#ccc',
    // cursor: 'pointer',
    backgroundColor: '#dae3f3 !important',
    borderTop: '2px solid #5686da !important',
    fontFamily: 'MuseoSans-500',
    '& td': {
      padding: '8px !important',
      lineHeight: '1.42857143',
      fontFamily: 'MuseoSans-500',
    },
  },
  firstYearTutuin: {
    fontFamily: 'MuseoSans-300',
  },
  amountDollarRight: {
    textAlign: 'right',
  },
  definationTittle: {
    borderBottom: '1px dashed #0069aa',
    display: 'inline-block !important',
    cursor: 'pointer',
    margin: '0',
    lineHeight: '25px',
  },
  retirementList: {
    listStyle: 'none',
    padding: '0px',
    maxWidth: '300px',
    margin: '0px 0px 35px',
    '& li': {
      cursor: 'pointer',
      // background: '#bdd597',
      // fontFamily: 'MuseoSans-500',
      position: 'relative',
      display: 'inline-block',
      width: 'calc(100% - 22px)',
      fontSize: '21px',
      padding: '4px 10px',
      border: '1px solid',
      margin: '5px auto',
      borderRadius: '6px',
      '& span':{
        display: 'inline-block',
        width: '38px',
        border: 'none',
        position: 'absolute',
        right: '1px',
        top: '0',
        background: '0 0',
        '& img':{
          width: '100%',
        }
      }
    }
  },
  blueTick:{
    width: '16px',
    height: '16px',
    verticalAlign: 'middle',
    paddingLeft: '10px',
  },
  // active: {
  //   backgroundColor: '#83A947',
  //   fontFamily: 'MuseoSans-500',
  // },
  activeDisable: {
    backgroundColor: '#f2f2f2',
  },
  dialog: {
      position: 'fixed',
      transform: 'translateX(-50%)',
      top: "5%",
      left: "50%",
      maxWidth: '550px',
      backgroundColor: "#fff",
      borderRadius: "5px",
      padding: "10px",
      boxSizing: 'border-box',
      zIndex: '2',
      boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
  },
  title: {
      display: 'flex',
      justifyContent: "space-between",
      borderBottom: "1px solid #ddd",
      '& h2': {
          margin: '10px 0px 0px 10px',
      }
  },
  content: {
      padding: '10px',
      borderBottom: "1px solid #ddd",
      width: '100%',
      marginBottom: '10px',
      '& label': {
          
      },
      '& input': {
          width: '90%',
          borderRadius: "5px",
          border: "1px solid #ddd",
          padding: '10px',
          margin: "0px",
          color: "#777"
      }

  },
  moduleBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      maxWidth: '150px',
      margin: '10px',
    }
  },
  sliderBottom30: {
    marginBottom: '30px',
  },
  tableHeadBg:{
    '& table':{
      '& tr':{
        '& th':{
          backgroundColor: '#bdd597',
          textAlign: 'right',
          '&:first-child':{
            textAlign: 'left',
          }
        },
        '& td':{
          textAlign: 'right',
          '&:first-child':{
            textAlign: 'left',
          }
        }
      }
    }
  },
  sourceText:{
    '& a':{
      fontSize: '14px',
      fontStyle: 'italic',
      color: '#337ab7',
    }
  },
  mb0:{
    marginBottom: '0px',
  },
  mb5:{
    marginBottom: '5px !important',
  },
  mbt30:{
    marginTop: '30px',
    marginBottom: '10px',
  },
  retirementListBlock:{
    margin: '30px 0',
  },
  retirementListFlex:{
    fontSize: '21px',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '10px',
    },
    '& span':{
      display: 'inline-block',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
      },
      '& em':{
        width: '38px',
        position: 'absolute',
        right: '1px',
        top: '0',
        fontStyle:'normal',
        '& img':{
          width: '100%',
        }
      },
      '&:nth-child(1)':{
        marginLeft: '2px',
        marginRight: '5px',
      },
      '&:nth-child(2)':{
        width: 'calc(200px - 20px)',
        fontSize: '21px',
        padding: '4px 10px',
        border: '1px solid',
        margin: '5px auto',
        borderRadius: '6px',
        background: '#f2f2f2',
        cursor: 'pointer',
        position: 'relative',
        marginRight: '20px',
        [theme.breakpoints.down( 'xs' )]: {
          margin: '5px 0',
        }
      },
      '&:nth-child(3)':{
        width: 'calc(200px - 22px)',
        fontSize: '21px',
        padding: '4px 10px',
        border: '1px solid',
        margin: '5px auto',
        borderRadius: '6px',
        cursor: 'pointer',
        position: 'relative',
        [theme.breakpoints.down( 'xs' )]: {
          margin: '5px 0',
        }
      }
    },
  },
  activeVehicle: { 
    marginRight: '20px',
    background: '#bdd597 !important',
    cursor: 'pointer',
    position: 'relative',
    marginLeft: '3px',
    fontFamily: 'MuseoSans-500',
  },
  section4TableScroll:{
    '@media (max-width: 991px)': {
      overflowX: 'auto'
    }
  },
  section4Table:{
    position: 'relative',
    paddingTop: '15px',
    '@media (max-width: 991px)': {
      minWidth: '950px'
    }
  },
  section4TableLeft:{
    paddingBottom: '0',
    width: '70%',
    display: 'inline-block',
    float: 'left',
  },
  section4TableLeftDiv:{
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    position: 'relative',
  },
  section4TableLeftTitle:{
    maxWidth: '195px',
    minWidth: '195px',
    fontSize: '20px',
    marginRight: '20px',
    marginTop: '-6px',
    fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
  },
  section4TableLeftSlide:{
    width: '100%',
    margin: '10px 25px 0',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',     
      marginLeft: '0px',   
    },
    '@media (min-width: 600px) and (max-width: 991px)': {
      margin: '10px 10px 0',
    },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      minHeight: '4px',
    },
  },
  section4TableRightOne:{
    position: 'relative',
    display: 'inline-table',
    paddingRight: '30px',
    width: 'calc(15% - 35px)',
  },
  section4TableRightOneDiv:{
    alignItems: 'center',
    marginTop: '0',
    fontSize: '18px',
    position: 'relative',
    height: '45px',
    textAlign: 'right',
    paddingTop: '16px',
    paddingRight: '10px',
    '& div':{
      '&:after':{
        display: 'none',
      },
      '&:before':{
        display: 'none',
      },
    },
    '& input':{
      float: 'right',
      fontSize: '18px',
      width: '80px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      position: 'relative',
      zIndex: '9',
      '&:focus':{
        borderBottom: '1px solid #f89b22',
      }
    }
  },
  section4TableRightOneDoller:{
    marginLeft: '2%',
    lineHeight: '25px',
    position: 'relative',
    left: '0',
    fontSize: '18px',
  },
  section4TableRightTwo:{
    position: 'relative',
    display: 'inline-table',
    paddingRight: '0px',
    width: 'calc(15% - 25px)',
    '&:before':{
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      left: '-13px',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
    }
  },
  section4TableRightTwo10:{
    '&:before':{
      left: '10px',
    }
  },
  noAfter:{
    '&:before':{
      display: 'none',
    }
  },
  sectionClear:{
    clear: 'both',
  },
  sectionClear30:{
    clear: 'both',
    height: '30px',
  },
  sectionNoData:{
    height: '30px',
    lineHeight: '30px',
    fontSize: '18px',
    marginBottom: '15px',
    textAlign: 'right',
    '& span':{
      border: 'none',
    }
  },
  newFlexBox:{
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    position: 'relative',
  },
  section4TableRightOneMonth:{
    textAlign: 'center',
    height: '30px',
    lineHeight: '30px',
    fontSize: '18px',
    marginBottom: '15px',
    fontFamily: 'MuseoSans-700',
    fontWeight: 'normal',
    '& span':{
      borderBottom: '1px solid #000',
    }
  },
  section4TableRightOneMYear:{
    textAlign: 'right',
    height: '30px',
    lineHeight: '30px',
    fontSize: '18px',
    marginBottom: '15px',
    fontFamily: 'MuseoSans-700',
    fontWeight: 'normal',
    paddingRight: '15px',
    '& span':{
      borderBottom: '1px solid #000',
    }
  },
  mr40:{
    margin: '40px 0px !important',
  },
  vehicleOwnTotalOne:{
    '& p':{
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      margin: '0px',
      textAlign: 'right',
      width: '100%',
      marginRight: '4%',
      fontSize: '18px',
      height: '40px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'end',
    }
  },
  totalText:{
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  textUnderline:{
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
  },
  textPer:{
    position: 'absolute',
    right: '-9px',
    bottom: '13px',
  },
  textMonts:{
    position: 'absolute',
    right: '-56px',
    bottom: '13px',
  },
  textMos:{
    position: 'absolute',
    right: '-30px',
    bottom: '13px',
  },
  sectionLeft50:{
    left: '50px',
  },
  sectionLRight30:{
    paddingRight: '30px',
  },
  schoolOptions:{
    display: 'flex',
    '& span':{
      width: 'calc(150px - 20px)',
      border: '1px solid #000',
      borderRadius: '6px',
      marginRight: '10px',
      cursor: 'pointer',
      fontSize: '21px',
      padding: '4px 10px',
    }
  },
  // active:{
  //   background: '#83a947',
  //   fontFamily: 'MuseoSans-500',
  //   fontWeight: 'normal',
  // },
  salesPara:{
    marginTop: '50px',
    '& p':{
      color: '#333',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-300',
      fontWeight: 'normal',
      '& i':{
        color: '#333',
        display: 'initial',
      }
    }
  },
  mt30:{
    marginTop: '30px !important',
  },
  tabButtons: {
    paddingTop: '0px',
    margin: '25px 0px 0px',
    '@media (max-width: 479px)': {
      margin: '20px 0px 0px',
    },
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      color: '#888',
      textTransform: 'inherit',
      minHeight: 'inherit',
      padding: '8px 25px',
      minWidth: '180px',
      fontSize: '16px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '150px',
      },
      '&:hover': {
        '& span': {
          color: '#3f51b5',
        }
      }
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflow: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },
  },
  mbot0:{
    margin: '0px 0px 30px !important',
  },
  activeTab:{
    top: '1px',
    position: 'relative',
    background: '#fff',
    borderColor: '#000 !important',
  },
  
  infoIcon: {
    margin: '9px 1px',
    textAlign: 'center',
    display: 'inline-block',
    width: '25px !important',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '20px',
      position: 'relative',
      left: '2px',
     },
    '& img': {
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      border: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'sm' )]: {
       maxWidth: '15px',
      },
    },
  },
  infoIconNew:{
    top: '6px',
  },
  /* persanal loan popup design*/
  moduleBodyhead:{
    display: 'flex',
    marginBottom: '10px',
    '& > div':{
      width: 'calc(16.66666667% - 30px)',
      paddingRight: '15px',
      paddingLeft: '15px',
      textAlign: 'center',
    },
    '& h4':{
      textAlign: 'center',
      fontSize: '21px',
      fontFamily: 'MuseoSans-500',
      color: '#333',
      fontWeight: 'normal',
      margin: '10px 0px',
    }
  },
  mobileScroll:{
    '@media (max-width: 1099px)': {
      overflowX: 'auto',
    }
  },
  moduleBodyScroll:{
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 165px)',
    '@media (max-width: 1099px)': {
      minWidth: '1080px',
      maxHeight: 'calc(100vh - 235px)',
    },
  },
  // moduleBody:{
  //   display: 'flex',
  //   marginBottom: '25px',
  //   '& > div':{
  //     width: 'calc(16.66666667% - 30px)',
  //     paddingRight: '15px',
  //     paddingLeft: '15px',
  //     position: 'relative',
  //     textAlign: 'center',
  //   },
  //   '& div':{
  //     '&:before':{
  //       display: 'none',
  //     },
  //     '&:after':{
  //       display: 'none',
  //     },
  //   },
  //   '& label':{
  //     fontSize: '18px',
  //     fontFamily: 'MuseoSans-500',
  //     fontWeight: 'normal',
  //   },
  //   '& input':{
  //     borderRadius: '0',
  //     fontSize: '18px',
  //     height: 'calc(38px - 14px)',
  //     textAlign: 'right',
  //     paddingRight: '10px',
  //     width: '100%',
  //     padding: '6px 12px',
  //     backgroundColor: '#fff',
  //     border: '1px solid #ccc',
  //     transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
  //     WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
  //     boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
  //     WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
  //     OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
  //     paddingLeft: '15px',
  //     '&:focus':{
  //       borderColor: '#66afe9',
  //       outline: '0',
  //       WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
  //       boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
  //     }
  //   }
  // },
  dollerCount:{
    top: '8px',
    fontSize: '16px',
    color: '#555',
    position: 'absolute',
    left: '16px',
    paddingLeft: '5px',
  },
  dollerPercenatge:{
    top: '8px',
    fontSize: '16px',
    color: '#555',
    position: 'absolute',
    fontFamily: 'MuseoSans-100',
    paddingRight: '5px',
    right: '16px',
  },
  BoxOne:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BoxThree:{
    paddingRight: '55px !important',
    textAlign: 'right !important',
    width: 'calc(16.66666667% - 70px) !important',
    '& h4':{
      textAlign: 'right !important',
    }
  },
  BoxFour:{
    '& input':{
      paddingRight: '25px !important',
      textAlign: 'right !important',
    },
  },
  BoxFive:{
    textAlign: 'right !important',
    width: 'calc(25% - 30px) !important',
    '& img':{
      maxWidth: '60px',
    }
  },
  boxTotal:{
    width: '90%',
    padding: '0px 15px 60px',
    '& p':{
      fontSize: '18px',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      textAlign: 'right',
      margin: '0px',
      '& span':{
        display: 'inline-block',
        marginLeft: '30px',
      }
    }
  },
  modelBodyFooter:{
    borderTop: '2px solid',
    padding: '20px 0px 0px',
  },
  BoxOneFooter:{
    alignItems: 'start',
  },
  buttonFooter:{
    padding: '15px 25px',
    borderTop: '1px solid #ddd',
    textAlign: 'right',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '15px 15px',
    },
    '& button':{
      position: 'relative',
      textAlign: 'center',
      padding: '6px 5px',
      minWidth: '64px',
      borderRadius: '6px',
      fontSize: '14px',
      borderWidth: '2px',
      WebkitTransition: 'all .5s ease 0s',
      MozTransition: 'all .5s ease 0s',
      transition: 'all .5s ease 0s',
      textTransform: 'uppercase',
      cursor: 'pointer',
    }
  },
  clearDatbtn:{
    color: '#fff',
    backgroundColor: '#f0ad4e',
    border: '2px solid #eea236',
    '&:hover':{
      backgroundColor: '#ec971f',
      border: '2px solid #d58512',
    }
  },
  okbtn:{
    color: '#fff',
    backgroundColor: '#84a84d',
    border: '2px solid #84a84d',
    marginLeft: '5px',
    '&:hover':{
      backgroundColor: '#799e41',
      border: '2px solid #799e41',
    }
  },
  cancelbtn:{
    color: '#333',
    backgroundColor: '#fff',
    border: '2px solid #ccc',
    marginLeft: '5px',
    '&:hover':{
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    }
  }
} );

export default styles;
