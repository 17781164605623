import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-career-path/styles';
import * as moduleServices from '../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const RetirementSummary = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>You're on your way to building your retirement savings. Review your 401(k) contributions during this career move.</p>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
          <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70}` }>
              <table cellPadding="0" cellSpacing="0">
                  <thead>
                      <tr className={ classes.trHead }>
                          <td>401(k) Retirement Accounts</td>
                          <td>401(k)</td>
                          <td>Roth 401(k)</td>
                      </tr>
                  </thead>
                <tbody>
                  <tr>
                    <td>
                      <span><strong>Beginning 401(k) Balance in Year {(moduleServices.module4Data.start_year !== undefined && moduleServices.module4Data.start_year !== null )&& moduleServices.module4Data.start_year}</strong></span>
                    </td>
                    <td>
                      <span>{moduleServices.beginningBalanceInYear() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.beginningBalanceInYear()} prefix={moduleServices.beginningBalanceInYear() >= 0 ? '$' : '($'} suffix={moduleServices.beginningBalanceInYear() < 0 && ')'} />
                        ) : ('$0')}</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.beginningBalanceInYearRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.beginningBalanceInYearRoth()} prefix={moduleServices.beginningBalanceInYearRoth() >= 0 ? '$' : '($'} suffix={moduleServices.beginningBalanceInYearRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Personal Contributions After {(moduleServices.module4Data.end_year > 0 && moduleServices.module4Data.start_year > 0) ? (moduleServices.module4Data.end_year - moduleServices.module4Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module4Data.personalContribuitionafterYears !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.personalContribuitionafterYears} prefix={moduleServices.module4Data.personalContribuitionafterYears >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.personalContribuitionafterYears < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.personalContribuitionafterYearsRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.personalContribuitionafterYearsRoth()} prefix={moduleServices.personalContribuitionafterYearsRoth() >= 0 ? '$' : '($'} suffix={moduleServices.personalContribuitionafterYearsRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Personal Contributions After {(moduleServices.module4Data.end_year > 0 && moduleServices.module4Data.start_year > 0) ? (moduleServices.module4Data.end_year - moduleServices.module4Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.earningOnPersonalContribution() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.earningOnPersonalContribution()} prefix={moduleServices.earningOnPersonalContribution() >= 0 ? '$' : '($'} suffix={moduleServices.earningOnPersonalContribution() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.earningOnPersonalContributionRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.earningOnPersonalContributionRoth()} prefix={moduleServices.earningOnPersonalContributionRoth() >= 0 ? '$' : '($'} suffix={moduleServices.earningOnPersonalContributionRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={classes.trFoot}>
                    <td>
                      Total Personal Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.totalPersonalContributionEarnings() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.totalPersonalContributionEarnings()} prefix={moduleServices.totalPersonalContributionEarnings() >= 0 ? '$' : '($'} suffix={moduleServices.totalPersonalContributionEarnings() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.totalPersonalContributionEarningsRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.totalPersonalContributionEarningsRoth()} prefix={moduleServices.totalPersonalContributionEarningsRoth() >= 0 ? '$' : '($'} suffix={moduleServices.totalPersonalContributionEarningsRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Employer Contributions After {(moduleServices.module4Data.end_year > 0 && moduleServices.module4Data.start_year > 0) ? (moduleServices.module4Data.end_year - moduleServices.module4Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.employerContributionAfterYears() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.employerContributionAfterYears()} prefix={moduleServices.employerContributionAfterYears() >= 0 ? '$' : '($'} suffix={moduleServices.employerContributionAfterYears() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.employerContributionAfterYearsRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.employerContributionAfterYearsRoth()} prefix={moduleServices.employerContributionAfterYearsRoth() >= 0 ? '$' : '($'} suffix={moduleServices.employerContributionAfterYearsRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Employer Contributions After {(moduleServices.module4Data.end_year > 0 && moduleServices.module4Data.start_year > 0) ? (moduleServices.module4Data.end_year - moduleServices.module4Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>{moduleServices.earningsOnEmployerContributionAfterYears() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.earningsOnEmployerContributionAfterYears()} prefix={moduleServices.earningsOnEmployerContributionAfterYears() >= 0 ? '$' : '($'} suffix={moduleServices.earningsOnEmployerContributionAfterYears() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>{moduleServices.earningsOnEmployerContributionAfterYearsRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.earningsOnEmployerContributionAfterYearsRoth()} prefix={moduleServices.earningsOnEmployerContributionAfterYearsRoth() >= 0 ? '$' : '($'} suffix={moduleServices.earningsOnEmployerContributionAfterYearsRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={classes.trFoot}>
                    <td>
                      Total Employer Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.totalEmployersContributionsEarnings() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.totalEmployersContributionsEarnings()} prefix={moduleServices.totalEmployersContributionsEarnings() >= 0 ? '$' : '($'} suffix={moduleServices.totalEmployersContributionsEarnings() < 0 && ')'} />
                        ) : ('$0')}  
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.totalEmployersContributionsEarningsRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.totalEmployersContributionsEarningsRoth()} prefix={moduleServices.totalEmployersContributionsEarningsRoth() >= 0 ? '$' : '($'} suffix={moduleServices.totalEmployersContributionsEarningsRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={classes.trFoot}>
                    <td>
                      401(k) Balance before <span onClick={ () => handleDefinationPopup('Vesting') }>Vesting</span> Reductions
                    </td>
                    <td>
                      <span>
                        {moduleServices.balanceBeforeVestingReductionsCareerPath() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.balanceBeforeVestingReductionsCareerPath()} prefix={moduleServices.balanceBeforeVestingReductionsCareerPath() >= 0 ? '$' : '($'} suffix={moduleServices.balanceBeforeVestingReductionsCareerPath() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.balanceBeforeVestingReductionsCareerPathRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.balanceBeforeVestingReductionsCareerPathRoth()} prefix={moduleServices.balanceBeforeVestingReductionsCareerPathRoth() >= 0 ? '$' : '($'} suffix={moduleServices.balanceBeforeVestingReductionsCareerPathRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      % of Employer Contributions & Earnings Not Vested
                    </td>
                    <td>
                      <span>
                        {moduleServices.employerContributionsEarningsNotVested() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.employerContributionsEarningsNotVested()} prefix={moduleServices.employerContributionsEarningsNotVested() >= 0 ? '$' : '($'} suffix={moduleServices.employerContributionsEarningsNotVested() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.employerContributionsEarningsNotVestedRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.employerContributionsEarningsNotVestedRoth()} prefix={moduleServices.employerContributionsEarningsNotVestedRoth() >= 0 ? '$' : '($'} suffix={moduleServices.employerContributionsEarningsNotVestedRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={classes.trFoot}>
                    <td>
                      Ending 401(k) Balance in Year {moduleServices.module4Data.end_year > 0  ? moduleServices.module4Data.end_year  : 0}
                    </td>
                    <td>
                      <span>
                        {moduleServices.ending401Balance() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.ending401Balance()} prefix={moduleServices.ending401Balance() >= 0 ? '$' : '($'} suffix={moduleServices.ending401Balance() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.ending401BalanceRoth() !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.ending401BalanceRoth()} prefix={moduleServices.ending401BalanceRoth() >= 0 ? '$' : '($'} suffix={moduleServices.ending401BalanceRoth() < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
  </div>

  );
};

RetirementSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( RetirementSummary );
