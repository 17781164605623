import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import * as modulesServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-vacation/styles';
import _ from 'lodash';

const livingexpensesKeys = ['Rent','Utilities','Food','Clothing','Entertainment','Technology','Transportation','Miscellaneous','Healthcare Costs other than Premium','Health Insurance Premium'];
const livingexpensesHigherKeys = ['Higher Education Tuition and Fees','Higher Education Room & Board','Off Campus Food']; 
const otherExpensesKeys = ['Children','Pet','Total Car Expenses','Total Real Estate Expenses'];

const VacationBottom = ( props ) => {
  const {
    classes, inputData, activeStep, updateData
  } = props;

  const [startYear, setStartYear] = React.useState();
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState( false );
  const [otherExpenses, setOtherExpenses] = React.useState( false );
  const [presetStep, setPresetStep] = React.useState('');

  const yearOption = [];
  for ( let year = parseInt(modulesServices.module1Data.start_year, 10); year <= parseInt(modulesServices.module1Data.start_year, 10) + 14; year += 1 ) {
    if ( yearOption.length === 0 ) {
      yearOption.push( 0 );
      yearOption.push( year );
    } else {
      yearOption.push( year );
    }
  }


  useEffect(()=>{
    let start_year = new Date().getFullYear();
      if ( activeStep < 3 ) {
        if ( typeof inputData.start_year !== 'undefined' && inputData.start_year !== '' && parseInt( inputData.start_year, 10 ) > 0 ) {
          start_year = parseInt( inputData.start_year, 10 );
        }
      } else if(activeStep < 5) {

      } else if(activeStep < 7) {

      } else  {
        if(inputData.bottom_year !== undefined && inputData.bottom_year > 0) {
          start_year = inputData.bottom_year
        } else {
          updateData('bottom_year',start_year);
        }
      }
      if(startYear !== start_year) {
        setStartYear(start_year);
      }
      if(presetStep !== activeStep) {
        setPresetStep(activeStep);
        setExpandLivingExpenses(false);
        setOtherExpenses(false);
      }
  })

  const handleSummaryYear = ( event ) => {
    setStartYear( parseInt(event.target.value, 10) );
    updateData('bottom_year',parseInt(event.target.value, 10));

  };

  const handleExpandLiving = () => {
    setExpandLivingExpenses( !expandLivingExpenses );
  };

  const handleOtherExpenses = () => {
    setOtherExpenses( !otherExpenses );
  };



  const individualLivingExpensesCalc = (label,from='table') => {
      const incomeSheetData = modulesServices.incomeStatementCompleteData();
      let displayValue = 0;

      if(typeof incomeSheetData !== 'undefined' && incomeSheetData !== ''){
          if(typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== '' 
              && typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== ''
              && typeof incomeSheetData[startYear]['livingExpenses'] !== 'undefined' && incomeSheetData[startYear]['livingExpenses'] !== ''
              && typeof incomeSheetData[startYear]['livingExpenses'][label] !== 'undefined' 
              && incomeSheetData[startYear]['livingExpenses'][label] !== '') {
              displayValue = incomeSheetData[startYear]['livingExpenses'][label];
          } else {
              displayValue =  0;
          }
      } else {
          displayValue =  0;
      }
      if(from === 'table') {
        return (
          <span className={displayValue < 0 && classes.dangerText}>
              <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
          </span>
        )
      } else {
        return displayValue;
      }
  }

   const individualLivingCalc = (label, from='table') => {
    const incomeStatementData = modulesServices.incomeStatementCompleteData();
    let displayValue = 0;
    if( incomeStatementData !== 'undefined' && incomeStatementData !== '' 
        && typeof incomeStatementData[startYear] !== 'undefined' && incomeStatementData[startYear] !== '' 
        && typeof incomeStatementData[startYear][label] !== 'undefined' ){
        displayValue = incomeStatementData[startYear][label];
    }
    if(from === 'table') {
      return (
        <span className={displayValue < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
        </span>
      )
    } else {
      return displayValue;
    }
  }

  const careerPathLivingExpenses = (from = 'table') => {
    const values = livingexpensesKeys.map((key)=>{
      return individualLivingExpensesCalc(key,'')
    })  
    let total = _.sum(values);
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const careerPathLivingOtherExpenses = (from = 'table') => {
    const values = [...otherExpensesKeys, ...livingexpensesHigherKeys].map((key)=>{
      if(key === 'Total Car Expenses' ||  key === 'Total Real Estate Expenses') {
        return individualLivingCalc(key,'');
      } else {
        return individualLivingExpensesCalc(key,'')
      }
    })  
    let total = _.sum(values);
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const careerPathLivingEducationExpenses = (from = 'table') => {
    const values = livingexpensesHigherKeys.map((key)=>{
      return individualLivingExpensesCalc(key,'')
    })  
    let total = _.sum(values);
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const totalAnnualExpenses = (from = 'table') => {
    const total = careerPathLivingExpenses('')+careerPathLivingOtherExpenses('')+individualLivingCalc('Total Interest Expenses','')+individualLivingCalc('Total Taxes','');
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  } 

  const annualNetIncome = (from='table') => {
    const total = individualLivingCalc('Total Income','')+totalAnnualExpenses('');
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const annualRetirementContributions = (from='table') => {
      let total = 0;
      const cashFlowData = modulesServices.cashFlowCompleteData();
      if( typeof startYear !== 'undefined' && startYear !== '' && 
          typeof cashFlowData !== 'undefined' && cashFlowData !== '' && 
          typeof cashFlowData[startYear] !== 'undefined' && cashFlowData[startYear] !== '' && typeof cashFlowData[startYear]['Cash from Investing Activities']['Retirement Contributions'] !== 'undefined' && cashFlowData[startYear]['Cash from Investing Activities']['Retirement Contributions'] !== '' ){
          total = -cashFlowData[startYear]['Cash from Investing Activities']['Retirement Contributions'];
      }
      if(from === 'table') {
        return (
          <span className={total < 0 && classes.dangerText}>
              <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
          </span>
        )
      } else {
        return total;
      }
  }

  const annualNetCashFlow = (from='table') => {
    const total = annualNetIncome('')-annualRetirementContributions('');
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const individualCashFlowCalc = (label, from='table') => {
    var cashFlowData = modulesServices.cashFlowCompleteData();
    let total = 0;
    if( typeof label !== 'undefined' && label !== '' &&  typeof startYear !== 'undefined' && startYear !== '' && 
        typeof cashFlowData !== 'undefined' && cashFlowData !== '' && 
        typeof cashFlowData[startYear] !== 'undefined' 
        && cashFlowData[startYear] !== '' 
        && typeof cashFlowData[startYear]['Cash from Investing Activities'][label] !== 'undefined' && cashFlowData[startYear]['Cash from Investing Activities'][label] !== '' ){
        total = cashFlowData[startYear]['Cash from Investing Activities'][label];
    }
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }

  const annualNetCashFlowAfterVacation = (from='table') => {
    const total = annualNetCashFlow('')+(individualCashFlowCalc('Vacation','')+individualCashFlowCalc(modulesServices.module11Data.other_acquisition1,'')+individualCashFlowCalc(modulesServices.module11Data.other_acquisition2+' - Second',''))
    if(from === 'table') {
      return (
        <span className={total < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
        </span>
      )
    } else {
      return total;
    }
  }
  const percentageOfIncome = () => {
    var finalIncome = annualNetCashFlowAfterVacation('');
    var startingIncome = individualLivingCalc('Total Income','');
    let total = 0;
    if(startingIncome !== 0 && finalIncome !== 0) {
        total = (finalIncome/startingIncome)*100;
    }
    return (
      <span className={total < 0 && classes.dangerText}>
          <NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '' : '(' } suffix={ total < 0 ? '%)' : '%' } />
      </span>
    )
  }

  return (
    <div className={ classes.vacationTable }>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            {activeStep !== 7 && (
              <th>
                Year
                {' '}
                {' '}
                {startYear}
              </th>
            )}
            {activeStep === 7
              && (
                <th>
                  Year
                  {' '}
                  {' '}
                  <select value={ startYear } onChange={ ( e ) => handleSummaryYear( e ) }>
                    {
                yearOption.map( ( data ) => (
                  <option value={ data }>{data}</option>
                ) )
                }
                  </select>
                  .
                </th>
              )}
          </tr>
        </thead>
        <tbody>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <b>
                {individualLivingCalc('Total Income')}
              </b>
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              Total Taxes Paid
            </td>
            <td>
              {individualLivingCalc('Total Taxes')}
            </td>
          </tr>
          <tr className={ classes.livingRow } aria-hidden="true" onClick={ handleExpandLiving }>
            <td>
              <span>
                {!expandLivingExpenses && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                {expandLivingExpenses && ( <i className="fa fa-minus" aria-hidden="true" /> )}
              </span>
            </td>
            <td className={ classes.leftPadding }>
              <b>Living Expenses</b>
            </td>
            <td>
              <b>
                {careerPathLivingExpenses()}
              </b>
            </td>
          </tr>
          {expandLivingExpenses && (
            <React.Fragment>
              {
                livingexpensesKeys.map((keyValue)=>(
                   <tr className={ classes.livingRow }>
                      <td>&nbsp;</td>
                      <td className={ classes.leftSubPadding }>
                        {keyValue === 'Clothing' && 'Clothing & Personal Care'}
                        {keyValue === 'Healthcare Costs other than Premium' && 'Health Insurance Costs outside Premium'}
                        {keyValue !== 'Clothing' && keyValue !== 'Healthcare Costs other than Premium' && keyValue}
                      </td>
                      <td>
                        {individualLivingExpensesCalc(keyValue)}
                      </td>
                   </tr>
                ))
              }
            </React.Fragment>
          )}
          <tr className={ classes.otherExpenses } aria-hidden="true" onClick={ handleOtherExpenses }>
            <td>
              <span>
                {!otherExpenses && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                {otherExpenses && ( <i className="fa fa-minus" aria-hidden="true" /> )}
              </span>
            </td>
            <td className={ classes.leftPadding }>
              <b>Other Expenses</b>
            </td>
            <td>
              <b>
                {careerPathLivingOtherExpenses()}
              </b>
            </td>
          </tr>

          {otherExpenses && (
            <React.Fragment>
              <tr className={ classes.otherExpenses }>
                <td>&nbsp;</td>
                <td className={ classes.leftSubPadding }>
                  Higher Education Expenses
                </td>
                <td>
                  {careerPathLivingEducationExpenses()}
                </td>
              </tr>
              {
                otherExpensesKeys.map((keyValue)=>(
                   <tr className={ classes.otherExpenses }>
                      <td>&nbsp;</td>
                      <td className={ classes.leftSubPadding }>
                        {keyValue === 'Total Car Expenses' && 'Car Expenses'}
                        {keyValue === 'Total Real Estate Expenses' && 'Real Estate Expenses'}
                        {keyValue !== 'Total Car Expenses' && keyValue !== 'Total Real Estate Expenses' && keyValue}
                      </td>
                      <td>
                        {
                          (keyValue === 'Total Car Expenses' ||  keyValue === 'Total Real Estate Expenses') ? (
                            individualLivingCalc(keyValue)
                          ) : (
                            individualLivingExpensesCalc(keyValue)
                          )
                        }
                      </td>
                   </tr>
                ))
              }
            </React.Fragment>
          )}
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              Loan Payments
            </td>
            <td>
              {individualLivingCalc('Total Interest Expenses')}
            </td>
          </tr>

          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td>
              <b>Total Expenses</b>
            </td>
            <td>
              <b>
                {totalAnnualExpenses()}
              </b>
            </td>
          </tr>
          
          <tr className={ classes.blueRow }>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
                <b>
                  {annualNetIncome()}
                </b>
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td>
              Retirement Contributions
            </td>
            <td>
                {annualRetirementContributions()}
            </td>
          </tr>
          <tr className={ classes.blueRow }>
            <td>&nbsp;</td>
            <td>
              <b>Net Cash Flow for Savings and Investments</b>
            </td>
            <td>
                <b>
                  {annualNetCashFlow()}
                </b>
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              Vacation
            </td>
            <td>
              {individualCashFlowCalc('Vacation')}
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              {modulesServices.module11Data.other_acquisition1}
            </td>
            <td>
              {individualCashFlowCalc(modulesServices.module11Data.other_acquisition1)}
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              {modulesServices.module11Data.other_acquisition2}
            </td>
            <td>
              {individualCashFlowCalc(modulesServices.module11Data.other_acquisition2+' - Second')}
            </td>
          </tr>
          <tr className={ classes.blueRow }>
            <td>&nbsp;</td>
            <td>
              <b>Net Cash Flow after Vacation & Special Purchases</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  {annualNetCashFlowAfterVacation()}
                </b>
              </span>
            </td>
          </tr>
          <tr className={ classes.blueRow }>
            <td>&nbsp;</td>
            <td className={ classes.leftPadding }>
              <i>% of Total Income</i>
            </td>
            <td>
              <i>
                {percentageOfIncome()}
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

VacationBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( VacationBottom );
