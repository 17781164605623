import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
import logo1 from '../../assets/img/logo-footer.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles';
import Slide1 from '../../assets/img/sam-story/sam_story_img_1.jpg';
import SlideInner1 from '../../assets/img/sam-story/sam_story_img_2.png';
import mikeStory3 from '../../assets/img/sam-story/sam_story_img_3.jpg';
import MikeSlide5 from '../../assets/img/sam-story/sam_story_img_5.jpg';
import mikeStory4 from '../../assets/img/sam-story/sam_story_img_4.jpg';
import MikeSlide6 from '../../assets/img/sam-story/sam_story_img_6.jpg';
import MikeSlide7 from '../../assets/img/sam-story/sam_story_img_7.jpg';
import MikeSlide8 from '../../assets/img/sam-story/sam_story_img_8.jpg';
import MikeSlide9 from '../../assets/img/sam-story/sam_story_img_9.jpg';
import MikeSlide10 from '../../assets/img/sam-story/sam_story_img_10.jpg';
import MikeSlide12 from '../../assets/img/sam-story/sam_story_img_11.jpg';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Jennifer = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );

  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'sam',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  const handleForwordPopup = () => {
    
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=samsoft`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };

  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryTool" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>SAM</h2>
                    <h2>Software Architect</h2>
                    <p>15-Year Net Worth: $1,344,293*</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities). Because Sam gets married, this figure includes his partner&apos;s income/expenses.
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src={ SlideInner1 } alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Sam&apos;s Life</h3>
                    <p>Sam attends USC and climbs the career ladder at leading tech firms in Seattle. He accelerates his student loan payments, invests in real estate and starts a family.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Sam&apos;s Start Year – 2019</h3>
                  <h4>(24 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      {' '}
                      $15k in savings, $60k in student loans
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2021: Software engineer at tech company in Seattle with freelance income on the side</p>
                      </li>
                      <li>
                        <p>2019: Begins making investments and giving to charity, starts taking vacations</p>
                      </li>
                      <li>
                        <p>2022 – 2027: Accepts job at another tech company in Seattle, advances to Senior Software Engineer</p>
                      </li>
                      <li>
                        <p>2022 – 2027: Leases car #1 and car #2</p>
                      </li>
                      <li>
                        <p>2028 – 2033: Promoted to Software Architect, stays in Seattle, purchases sports sedan</p>
                      </li>
                      <li>
                        <p>2029: Gets married, adopts a puppy</p>
                      </li>
                      <li>
                        <p>2031: Purchases real estate investment property</p>
                      </li>
                      <li>
                        <p>2032: Welcomes twins, buys a house</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $1,344,293</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Sam</h3>
                    <p>
                      Even during high school, Sam felt engaged in a quest for financial independence. He assessed math and problem-solving skills to be among his biggest strengths. Extracurricular experiments, creative impulses, and impromptu problem-solving in the world of digital coding
                      convinced Sam that a career in software would be both possible and likely profitable, all while offering a type of work that he felt would provide the types of challenges that he finds fulfilling.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Early research to match skills and interests can reveal a fulfilling career path.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>To use his skills and interests to best advantage, Sam targets a career in the software industry.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam aims for success</h3>
                    <p>
                      Sam attends the University of Southern California and earns a degree in Computer Science, funded by $60k in private student loans and family contributions. In several ways, Sam sacrifices short-term benefits to improve his chances for long-term success. Moving to Seattle after graduation to work as a software engineer, Sam devotes much of his free time to completing
                      freelance work relevant to his career, generating $6.5k in supplemental income, and improving his capabilities in the process. He takes vacations to provide needed breaks from his rigorous schedule.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Diversifying income with multiple jobs provides added financial security. Keep expenses low where possible.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam grows his skills and knowledge with freelance work and generates supplemental income.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam keeps expenses low</h3>
                    <p>
                      Sam initially chooses a bicycle over a car and he lives with two housemates to reduce expenses while remaining within walking distance of work. Sam also strives to accelerate his loan payments two years ahead of schedule.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Staying on top of debt is crucial to financial independence. Accelerating repayment of student loans, if possible, is a great investment.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam decides to repay his private student loans two years early to get himself out of debt. He lives close to work, keeping transportation costs down.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam saves and invests</h3>
                    <p>
                      Sam recognizes the tech industry can be volatile at times, and he sees saving as a way to ensure he always will be financially secure. In 2019, Sam starts making regular
                      contributions to a short-term investments account, begins making long-term investments, and dedicates some of his resources to charitable giving. He saves $10k in his first two years after college.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Save early and watch as compounding interest grows your investments. Charitable giving is investing, too.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam starts investing right out of college and continues to set aside cash for investments while paying down his student loans.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam accepts an offer</h3>
                    <p>
                      In 2022, Sam accepts an offer from a competing tech firm, joining the ranks as a Senior Software Engineer. With an annual salary of $140k, he takes a hiatus from his freelance work. For the first time,
                      he moves into his own small apartment, and he installs a home office. He leases a car to provide transportation to and from work and area parks.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Take calculated risks and embrace new opportunities.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam capitalizes on a career advancement opportunity and focuses on mastering his career.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam lands his dream job</h3>
                    <p className={ classes.paddingBottomPara }>Ten years post-graduation, Sam finally lands his dream job — a promotion to Software Architect with responsibility for directing a research and development team, which brings a pay increase to $190k. He upgrades his apartment to something slightly larger and closer to his work downtown. And for the first time, he chooses to purchase a vehicle, mostly for weekend trips with his girlfriend.</p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Hard work coupled with vision and a plan improves your chances of success.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam starts planning and saving for his next move while enjoying the rewards of his hard work.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam gets married</h3>
                    <p>Sam and the woman he loves decide to get married in 2029. They share their apartment with a puppy. Both focus intensely on their respective careers, but also enjoy weekend trips, mostly to vineyards in Central Washington.</p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Always make time for the things that matter most. Two incomes can have a huge impact on net worth when sharing expenses.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam marries his longtime girlfriend, starts sharing his rent expense, and puts those extra dollars toward vacation time and hobbies.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Sam invests in real estate</h3>
                    <p className={ classes.paddingBottomPara }>Two years later, Sam chooses to upgrade his investment portfolio by diversifying into real estate, purchasing a rental property and hiring a property manager for a seven-year investment period. Sam estimates his investment property will have an IRR (Internal Rate of Return) of 10.5% before taxes.</p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Never stop looking for ways to put your assets to work for you and grow your net worth.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam reaches for a higher return on his money by investing in real estate and continuing to expand his skills and knowledge base.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide10 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A home for twins</h3>
                    <p className={ classes.paddingBottomPara }>
                      In 2032, Sam and his wife welcome twins into the world. They make a quick decision to buy a four-bedroom home with a nice yard, just outside of Seattle. Sam&apos;s wife chooses to take a sabbatical from work to
                      raise the infants while the couple considers their options and imagines what might come next.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Saving early gives you financial freedom down the road. Now, enjoy it.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Sam&apos;s hard work and planning, diversification of income, and ability to save brings him to financial independence.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Sam&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Sam earns 10% more income in his plan?</li>
                        <li>What happens if he spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on his net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 10 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Sam&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>

                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Sam&apos;s financial life plan by module to see how he acheives his goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Sam - Software Architect</h4>
                      <h4>Net Worth:  $1,344,293</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide12 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Sam&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(38 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Education</li>
                      <li>Work Experience</li>
                      <li>A Career</li>
                      <li>A Family</li>
                      <li>A Dog</li>
                      <li>A Sports Sedan</li>
                      <li>A Home</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $1,344,293</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Sam&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 12.7%</li>
                      <li>Monthly Liquidity Ratio: 5.5x</li>
                      <li>Current Ratio: 1.6x</li>
                      <li>Savings Rate: 42.6%</li>
                      <li>Investment Assets to Total Assets: 48.6%</li>
                      <li>Total Assets to Total Debt: 2.7x</li>
                      <li>Solvency Ratio: 0.6x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}

      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>

      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}

    </Typography>
  );
};

Jennifer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Jennifer );
