import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const GapYearAnalysis = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Gap Year Analysis</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/gap-year-analysis.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Gap Year Analysis
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/gap-year-analysis-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Taking a year off before or during higher education is
                          commonly referred to a gap year. Earning income,
                          saving and investing, and gaining experience for a
                          year can prepare you for college and your career. But
                          how does this impact your financial life? Does your 5,
                          10, 15 year net worth increase or decrease by taking a
                          gap year? Let&apos;s look at an example.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          We updated one of Inkwiry&apos;s career sketches -
                          Jay, the Construction Manager - to take a gap year and
                          see the change in his net worth. Instead of going
                          right to college after high school, Jay takes a year
                          off, lives at home and earns $25,000.
                        </p>
                        <p>
                          <strong>
                            <u>Jay, the Construction Manager: no gap year</u>
                          </strong>
                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/jay-nogap-timeline.png"
                              alt=""
                            />
                            <br />
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/jay-gap1.png"
                              alt=""
                            />
                          </div>
                        </p>
                        <p>
                          <strong>
                            <u>
                              Jay, the Construction Manager: with a gap year
                            </u>
                          </strong>
                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/jay-gap-timeline.png"
                              alt=""
                            />
                            <br />
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/jay-nogap1.png"
                              alt=""
                            />
                          </div>
                        </p>
                        <p>
                          And the results are in. Starting college on time leads
                          to a higher 5, 10, 15 year net worth. When you start
                          college right away and graduate on time, you get to
                          your career earlier than if you took a gap year.
                          Starting your career earlier leads to a higher
                          earnings potential in that 15-year period.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
GapYearAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GapYearAnalysis);
