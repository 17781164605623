export function modulesData( ) {
  const intialValues = {
    module1Data: {
      start_year: 2020,
      long_term_growth_in_percentage: 4.5,
      inflation_in_percentage: 2.0,
      // current_cash_balance: 1500,
      // max_cash_balance: 10000,
    },
    module2Data: {
      type_of_higher_education: 'college',
      name_of_college: 'Penn State University',
      state_id: 2,
      state: "",
      start_year: 2020,
      years_diff: 4,
      graduation_year: 2023,
      tuition: 25000,
      room_board: 12500,
      yearly_scholarship_amount: 20000,
      yearly_grant_amount: 4000,
      year_aid_begins: 2020,
      year_aid_ends: 0,
      expected_yearly_finance_aid: 0,
      income_start_year: 2020,
      income_end_year: 2021,
      yearly_income: 8300,
      family_start_year: 2020,
      family_end_year: 2021,
      yearly_contribution: 17000,
      dependent_status: 'Dependent',
      fed_sub_firstyear: 0,
      fed_sub_secondyear: 0,
      fed_sub_thirdyear: 0,
      fed_sub_fourthyear: 0,
      fed_sub_fifthyear: 0,
      fed_sub_sixthyear: 0,
      fed_sub_term: 0,
      fed_sub_interest_rate: 0.00,
      fed_sub_origination_fee: 0.00,
      fed_unsub_firstyear: 5000,
      fed_unsub_secondyear: 5000,
      fed_unsub_thirdyear: 5000,
      fed_unsub_fourthyear: 5000,
      fed_unsub_fifthyear: 5000,
      fed_unsub_sixthyear: 5000,
      fed_unsub_term: 10,
      fed_unsub_interest_rate: 4.53,
      fed_unsub_paymentplan: 'Defer Investment Payments',
      fed_unsub_origination_fee: 1.10,
      private_firstyear: 5500,
      private_secondyear: 6500,
      private_thirdyear: 7500,
      private_fourthyear: 7500,
      private_fifthyear: 0,
      private_sixthyear: 0,
      private_term: 10,
      private_interest_rate: 4.53,
      private_paymentplan: 'Defer Investment Payments',
      private_origination_fee: 1.00,
      on_campus_start_year: 2020,
      off_campus_food: 125,
      clothing: 50,
      entertainment: 100,
      technology: 40,
      transportation: 30,
      miscellaneous: 125,
      medical_costs_before_deductible: 0,
      health_insurance_premium: 0,
      off_campus_start_year: 2022,
      rent: 500,
      utilities: 100,
      food: 600,
      second_clothing: 70,
      second_entertainment: 125,
      second_technology: 70,
      second_transportation: 40,
      second_miscellaneous: 150,
      second_medical_costs_before_deductible: 0,
      second_health_insurance_premium: 0,
      fetchRoomBoard: 0,
      onCampusOffCampusFood: 0,
      onCampusClothing: 0,
      onCampusEntertainment: 0,
      onCampusTechnology: 0,
      onCampusTransportation: 0,
      onCarPayment: 0,
      onCampusCarMaintenance: 0,
      onCampusCarInsurance: 0,
      onCampusGas: 0,
      onCampusMiscellaneous: 0,
      onCampusMedical: 0,
      onCampusHealth: 0,
      offCampusRent: 0,
      offCampusUtilities: 0,
      offCampusClothing: 0,
      offCampusEntertainment: 0,
      offCampusTechnology: 0,
      offCampusTransportation: 0,
      offCarPayment: 0,
      offCampusMaintenance: 0,
      offCampusCarInsurance: 0,
      offCampusGas: 0,
      offCampusCarMiscellaneous: 0,
      offCampusCarMedical: 0,
      offCampusCarHealth: 0,
    },
    module4Data: {
      roth_contribute_401k:'No',
      base_roth_income:0,
      vested_amount_at_end_year:0,
      ira_roth_contribute:'',
      ira_roth_personal_contribution:0,
      rent_insurance: 0,
      rent_total: 0,
      contribute_hsa_savings_account:'No',
      hsa_personal_contribution:0,
      hsa_annual_expected:0,
      self_employment: 'No',
      occupation: 'Entry-level Accountant',
      state_id: 2,
      state: "",
      state_tax: 0.00,
      city: 'Philadelphia',
      city_taxes: 0.00,
      start_year: 2024,
      end_year: 2024,
      yearly_income: 285000,
      bonus_or_tips_or_commission: 5000,
      second_start_year: 0,
      second_end_year: 0,
      second_yearly_income: 0,
      rent: 700,
      utilities: 150,
      food: 800,
      grocery_shopping: 0,
      meals_eaten_not_at_home: 0,
      other_food: 0,
      clothing: 100,
      entertainment: 200,
      technology: 100,
      transportation: 100,
      miscellaneous: 50,
      medical_costs_before_deductible: 20,
      health_insurance_premium: 100,
      contribute_401k: 'Yes',
      beginning_balance_of401k: 0,
      base_income: 5.00,
      employer_matching_rate: 100.00,
      employer_matching_limit: 4.00,
      Annual_expected401k: 6.00,
      roth_contribute: 0,
      roth_beginning_balance: 0,
      roth_personal_contribution: 0.00,
      roth_annual_expected_pre_tax: 0.00,
      ira_contribute: 0,
      ira_beginning_balance: 0,
      ira_personal_contribution: 0.00,
      ira_annual_expected_pre_tax: 0.00,
      pension_contribute: 0,
      pension_beginning_balance: 0,
      pension_employer_contribution: 0.00,
      pension_personal_contribution: 0.00,
      pension_annual_expected_pre_tax: 7.00,
      annualTotalTaxesPaidCareerPath: 0, // Calculated values
      careerPathLivingExpenses: 0,
      careerPathHigherEducationExpenses: 0,
      careerPathChildrenExpenses: 0,
      careerPathPetExpenses: 0,
      careerPathCarExpenses: 0,
      careerPathHomeExpenses: 0,
      careerPathFinancingExpenses: 0,
      annualRetirementContributionsCareerPath: 0,
      income_end_year: 2024,
      supplementary_start_year: 2026,
      monthlyLivingExpensesRent: 0,
      monthlyLivingExpensesUtilities: 0,
      monthlyLivingExpensesFood: 0,
      monthlyLivingExpensesClothing: 0,
      monthlyLivingExpensesEntertainment: 0,
      monthlyLivingExpensesTechnology: 0,
      monthlyLivingExpensesTransportation: 0,
      monthlyLivingExpensesCarPayment: 0,
      monthlyLivingExpensesCarMaintenance: 0,
      monthlyLivingExpensesCarInsurance: 0,
      monthlyLivingExpensesGas: 0,
      monthlyLivingExpensesMiscellaneous: 0,
      monthlyLivingExpensesMedical: 0,
      monthlyLivingExpensesHealth: 0,
      occupation_title: 'Software',
    },
    module5Data: {
      roth_contribute_401k:'No',
      rent_insurance: 0,
      rent_total: 0,
      base_roth_income:0,
      vested_amount_at_end_year:0,
      ira_roth_contribute:'',
      ira_roth_personal_contribution:0,
      contribute_hsa_savings_account:'',
      hsa_personal_contribution:0,
      hsa_annual_expected:0,
      self_employment: '1',
      occupation_title: 'Software',
      occupation: 'Accountant',
      state_id: 2,
      state: "",
      state_tax: 0.00,
      city: 'Atlanta',
      city_taxes: 0.00,
      start_year: 2025,
      end_year: 2027,
      yearly_income: 145000,
      bonus_or_tips_or_commission: 0,
      second_start_year: 0,
      second_end_year: 0,
      second_yearly_income: 0,
      rent: 1000,
      utilities: 200,
      food: 200,
      grocery_shopping: 0,
      meals_eaten_not_at_home: 0,
      other_food: 0,
      clothing: 200,
      entertainment: 100,
      technology: 100,
      transportation: 100,
      miscellaneous: 2000,
      medical_costs_before_deductible: 200,
      health_insurance_premium: 200,
      contribute_401k: '',
      beginning_balance_of401k: 17931,
      base_income: 0.00,
      employer_matching_rate: 0.00,
      employer_matching_limit: 0.00,
      Annual_expected401k: 0.00,
      roth_contribute: 'Yes',
      roth_beginning_balance: 0,
      roth_personal_contribution: 5.00,
      roth_annual_expected_pre_tax: 7.00,
      ira_contribute: 0,
      ira_beginning_balance: 0,
      ira_personal_contribution: 0.00,
      ira_annual_expected_pre_tax: 0.00,
      pension_contribute: 0,
      pension_beginning_balance: 0,
      pension_employer_contribution: 0.00,
      pension_personal_contribution: 0.00,
      pension_annual_expected_pre_tax: 0.00,
      income_end_year: 2024,
      supplementary_start_year: 2026,
      monthlyLivingExpensesUtilities: 0,
      monthlyLivingExpensesFood: 0,
      monthlyLivingExpensesClothing: 0,
      monthlyLivingExpensesEntertainment: 0,
      monthlyLivingExpensesTechnology: 0,
      monthlyLivingExpensesTransportation: 0,
      monthlyLivingExpensesCarPayment: 0,
      monthlyLivingExpensesCarMaintenance: 0,
      monthlyLivingExpensesCarInsurance: 0,
      monthlyLivingExpensesGas: 0,
      monthlyLivingExpensesMiscellaneous: 0,
      monthlyLivingExpensesHealth: 0,
      monthlyLivingExpensesMedical: 0,

    },
    module6Data: {
      type_of_higher_education: 'college',
      name_of_college: 'Penn State University',
      state_id: 2,
      state: "",
      start_year: 2020,
      years_diff: 4,
      graduation_year: 2023,
      tuition: 25000,
      room_board: 12500,
      yearly_scholarship_amount: 20000,
      yearly_grant_amount: 4000,
      year_aid_begins: 0,
      year_aid_ends: 0,
      expected_yearly_finance_aid: 0,
      income_start_year: 2020,
      income_end_year: 2021,
      yearly_income: 8300,
      family_start_year: 2020,
      family_end_year: 2021,
      yearly_contribution: 17000,
      dependent_status: 'Dependent',
      fed_sub_firstyear: 0,
      fed_sub_secondyear: 0,
      fed_sub_thirdyear: 0,
      fed_sub_fourthyear: 0,
      fed_sub_fifthyear: 0,
      fed_sub_sixthyear: 0,
      fed_sub_term: 0,
      fed_sub_interest_rate: 0.00,
      fed_sub_origination_fee: 0.00,
      fed_unsub_firstyear: 5000,
      fed_unsub_secondyear: 5000,
      fed_unsub_thirdyear: 5000,
      fed_unsub_fourthyear: 5000,
      fed_unsub_fifthyear: 5000,
      fed_unsub_sixthyear: 5000,
      fed_unsub_term: 10,
      fed_unsub_interest_rate: 4.53,
      fed_unsub_paymentplan: 'Defer Investment Payments',
      fed_unsub_origination_fee: 1.10,
      private_firstyear: 5500,
      private_secondyear: 6500,
      private_thirdyear: 7500,
      private_fourthyear: 7500,
      private_fifthyear: 0,
      private_sixthyear: 0,
      private_term: 10,
      private_interest_rate: 4.53,
      private_paymentplan: 'Defer Investment Payments',
      private_origination_fee: 1.00,
      on_campus_start_year: 2020,
      off_campus_food: 125,
      clothing: 50,
      entertainment: 100,
      technology: 40,
      transportation: 30,
      miscellaneous: 125,
      medical_costs_before_deductible: 0,
      health_insurance_premium: 0,
      off_campus_start_year: 2022,
      rent: 500,
      utilities: 100,
      food: 600,
      second_clothing: 70,
      second_entertainment: 125,
      second_technology: 70,
      second_transportation: 40,
      second_miscellaneous: 150,
      second_medical_costs_before_deductible: 0,
      second_health_insurance_premium: 0,
      fetchRoomBoard: 0,
      livingExpensesFood: 0,
      livingExpensesClothing: 0,
      livingExpensesEntertainment: 0,
      livingExpensesTechnology: 0,
      livingExpensesTransportation: 0,
      livingExpensesCarPayment: 0,
      livingExpensesCarMaintenance: 0,
      livingExpensesCarInsurance: 0,
      livingExpensesGas: 0,
      livingExpensesMiscellaneous: 0,
      livingExpensesMedicalCost: 0,
      livingExpensesHealthInsurance: 0,
      morelivingExpensesRent: 0,
      morelivingExpensesUtilities: 0,
      morelivingExpensesFood: 0,
      morelivingExpensesClothing: 0,
      morelivingExpensesEntertainment: 0,
      morelivingExpensesTechnology: 0,
      morelivingExpensesTransportation: 0,
      morelivingExpensesCarPayment: 0,
      morelivingExpensesCarMaintenance: 0,
      morelivingExpensesCarInsurance: 0,
      morelivingExpensesGas: 0,
      morelivingExpensesMiscellaneous: 0,
      morelivingExpensesMedicalCost: 0,
      morelivingExpensesHealthInsurance: 0,

    },
    module8Data: {
      roth_contribute_401k:'No',
      rent_insurance: 0,
      rent_total: 0,
      base_roth_income:0,
      vested_amount_at_end_year:0,
      ira_roth_contribute:'',
      ira_roth_personal_contribution:0,
      contribute_hsa_savings_account:'',
      hsa_personal_contribution:0,
      hsa_annual_expected:0,
      self_employment: 0,
      occupation: 'Accountant',
      state_id: 2,
      state: "",
      state_tax: 0.00,
      city: 'Albuquerque',
      city_taxes: 0.00,
      start_year: 2028,
      end_year: 2028,
      yearly_income: 125000,
      bonus_or_tips_or_commission: 0,
      second_start_year: 0,
      second_end_year: 0,
      second_yearly_income: 0,
      rent: 2000,
      utilities: 300,
      food: 300,
      grocery_shopping: 0,
      meals_eaten_not_at_home: 0,
      other_food: 0,
      clothing: 300,
      entertainment: 300,
      technology: 400,
      transportation: 400,
      miscellaneous: 400,
      medical_costs_before_deductible: 400,
      health_insurance_premium: 400,
      contribute_401k: 'Yes',
      beginning_balance_of401k: 20827,
      base_income: 6.00,
      employer_matching_rate: 100.00,
      employer_matching_limit: 4.00,
      Annual_expected401k: 7.00,
      roth_contribute: 'Yes',
      roth_beginning_balance: 6808,
      roth_personal_contribution: 0.00,
      roth_annual_expected_pre_tax: 0.00,
      ira_contribute: 0,
      ira_beginning_balance: 0,
      ira_personal_contribution: 0.00,
      ira_annual_expected_pre_tax: 0.00,
      pension_contribute: 0,
      pension_beginning_balance: 0,
      pension_employer_contribution: 0.00,
      pension_personal_contribution: 0.00,
      pension_annual_expected_pre_tax: 0.00,
      annual_total_taxes_paid_fur_career_adv: 0,
      fur_career_adv_living_expenses: 0,
      fur_career_adv_higher_education_expenses: 0,
      fur_career_adv_children_expenses: 0,
      fur_career_adv_pet_expenses: 0,
      fur_career_adv_car_expenses: 0,
      fur_career_adv_home_expenses: 0,
      fur_career_adv_financing_expenses: 0,
      annual_retirement_contributions_fur_career_adv: 0,
      occupation_title: 'Software',
    },
    module9Data: {
      short_term_start_year: 2025,
      short_term_begining_balance: 0,
      short_term_max_sti_balance_before_lt_investment: 10000,
      short_term_annual_contribution: 10000,
      short_term_annual_contribution_growth_in_percentage: 2.00,
      short_term_annual_expected_return_in_percentage: 5.00,
      long_term_start_year: 0,
      long_term_begining_balance: 0,
      long_term_annual_contribution: 0,
      long_term_annual_contribution_in_percentage_growth: 0.00,
      long_term_annual_expected_return_in_percentage: 0.00,
      financial_gift_start_year: 0,
      financial_gift_end_year: 0,
      yearly_financial_gift: 0,
      charitable_start_year: 0,
      charitable_end_year: 0,
      charitable_yearly_contribution: 0,
      plan_529_start_year:0,
      plan_529_annual_contribution_percentage:0,
      plan_529_annual_expected_pre_tax_return:0,
      life_insurance_start_year:0,
      monthly_premium:0,
      monthly_premium_percentage:0,
      annual_expected_pre_tax_return_life_ins:0,
      graph9: {
        annualExpectedReturn: 0,
        annualPreTaxReturn: 0,
        rothAnnualPreTaxReturn: 0,
        beginningBalance: 0,
        annualContribution: 0,
        beginningBalance401k: 0,
        rothBeginningBalance: 0,
        annualGrowth: 0,
      },
    },
    module10Data: {
      marriege_year: 2028,
      wedding_costs: 0.00,
      occupation: '',
      start_year: 2028,
      end_year: 2030,
      spouse_income: 10000,
      spouse_bonus_or_tips_or_commission: 0.00,
      second_occupation: '',
      second_start_year: 2028,
      second_end_year: 2030,
      second_spouse_income: 0,
      second_spouse_bonus_or_tips_or_commission: 4.00,
      rent_multiplier: 2000,
      utilities_multiplier: 300,
      food_multiplier: 300,
      clothing_multiplier: 300,
      entertainment_multiplier: 300,
      technology_multiplier: 400,
      transportation_multiplier: 400,
      miscellaneous_multiplier: 400,
      health_insurance_costs_multiplier: 400,
      health_insurance_premium_multiplier: 400,
      spouse_student_loan: 0,
      spouse_ideal_repayment_schedule: 0,
      spouses_interest_rate: 0,
      number_of_children: 0,
      first_born: 2031,
      second_born: 2032,
      third_born: 2033,
      fourth_born: 2034,
      fifth_born: 2035,
      sixth_born: 2036,
      seventh_born: 2037,
      save_average_cost_raising_child: 12794,
      housing_cost: 0,
      food_cost: 0,
      transportation_cost: 6000,
      clothing_cost: 0,
      health_care_cost: 0,
      child_care_and_education: 0,
      other_costs: 0,
      year_of_purchase: 2031,
      type_of_pet: 'Dog',
      pet_name: '',
      purchase_price: 0,
      save_annual_pet_new_values: 0,
      default_annual_pet_vet_visits: 0,
      default_annual_pet_food: 0,
      default_annual_pet_toys: 0,
      default_annual_pet_emergency_fund: 0,
      default_annual_pet_health_insurance: 0,
      default_annual_pet_registration: 0,
      default_annual_pet_miscellaneous: 0,
      save_buying_pet_new_values: 0,
      default_pet_first_vet_visit: 0,
      default_pet_new_supplies: 0,
      default_pet_miscellaneous: 0,
      buyingPetNewValues: 0,
      petFirstVetVisit: 0,
      petNewSupplies: 0,
      petMiscellaneous: 0,
      saveBuyingPetNewValues: 0,
      defaultPetFirstVetVisit: 0,
      defaultPetNewSupplies: 0,
      defaultPetMiscellaneous: 0,
      annualPetNewValues: 0,
      annualPetVetVisits: 0,
      annualPetFood: 0,
      annualPetToys: 0,
      annualPetEmergencyFund: 0,
      annualPetHealthInsurance: 0,
      annualPetMiscellaneous: 0,
      annualPetRegistration: 0,
      saveAnnualPetNewValues: 0,
      defaultAnnualPetVetVisits: 0,
      defaultAnnualPetFood: 0,
      defaultAnnualPetToys: 0,
      defaultAnnualPetEmergencyFund: 0,
      defaultAnnualPetHealthInsurance: 0,
      defaultAnnualPetMiscellaneous: 0,
      defaultAnnualPetRegistration: 0,

    },
    module11Data: {
      start_year: 2024,
      end_year: 2034,
      yearly_cost: 3500,
      purchase_item: '',
      second_start_year: 0,
      second_end_year: 0,
      second_yearly_cost: 0,
      second_purchase_item: '',
      third_start_year: 0,
      third_end_year: 0,
      third_yearly_cost: 0,
    },
    module12Data: {
      tradein_existing_car_2: 0,
      tradein_existing_car_3: 0,
      tradein_existing_car_4: 0,
      moretradein_existing_car_2: 0,
      moretradein_existing_car_3: 0,
      moretradein_existing_car_4: 0,
      leasing_car_start_year: 2027,
      leasing_car_number_of_years: 48,
      leasing_car_down_payment: 2500,
      leasing_car_drive_off_fees: 500,
      leasing_car_lease_payment: 250,
      leasing_car_maintenance: 100,
      leasing_car_insurance: 100,
      leasing_car_gas: 100,
      purchase_year: 2028,
      base_price: 40000,
      destination_charges: 600,
      car_options: 2500,
      discount_in_percentage: 6.50,
      rebate: 0.00,
      tradein_existing_car: 0,
      save_change_existing_car_trade_value: 0,
      existing_car_trade_value: 0,
      down_payment_in_percentage: 20.00,
      sales_tax_in_percentage: 5.00,
      registration_and_documentation_fees: 250,
      term_of_the_loan: 36,
      interest_rate: 4.50,
      origination_fees: 0.50,
      first_car_maintenance: 150,
      first_car_insurance: 150,
      first_car_gas: 150,
      leasing_second_car_start_year: 2030,
      leasing_second_car_number_of_years: 36,
      leasing_second_car_down_payment: 1000,
      leasing_second_car_drive_off_fees: 500,
      leasing_second_car_lease_payment: 200,
      leasing_second_car_maintenance: 75,
      leasing_second_car_insurance: 75,
      leasing_second_car_gas: 75,
      second_purchase_year: 2030,
      second_base_price: 0,
      second_destination_charges: 0,
      second_options: 0,
      second_discount: 0.00,
      second_rebate: 0.00,
      moretradein_existing_car: 0,
      save_change_first_car_trade_value: 0,
      first_car_trade_value: 0,
      second_down_payment_in_percentage: 0.00,
      second_sales_tax: 0.00,
      second_registration_and_documentation_fees: 0,
      second_term_of_the_loan: 0,
      second_interest_rate: 0.00,
      second_origination_fees: 0.00,
      second_car_maintenance: 0,
      second_car_insurance: 0,
      second_car_gas: 0,
      carLoan: 0,
      rateOfLoan: 0,
      moreCarLoan: 0,
      moreTermOfTheLoan: 0,
      moreRateOfLoan: 0,
      net_purchase_price: 0,
      firstCarInsuranceCalcs: 0,
      firstCarGasCalcs: 0,
      moreNetPurchasePrice: 0,
      secondCarMaintenanceCalcs: 0,
      secondCarInsuranceCalcs: 0,
      secondCarGasCalcs: 0,
      leasingCarMonthlyPayment: 0,
      leasingCarLeasePaymentCalc: 0,
      leasingCarInsuranceCalc: 0,
      leasingCarGasCalc: 0,
      leasingSecondCarMonthlyPayment: 0,
      leasingSecondCarLeasePaymentCalc: 0,
      leasingSecondCarMaintenanceCalc: 0,
      leasingSecondCarInsuranceCalc: 0,
      leasingSecondCarGasCalc: 0,
      firstCarMaintenanceCalcs: 0,

    },
    module13Data: {
      year_of_purchase: 2032,
      purchase_price: 500000,
      down_payment_in_percentage: 20.00,
      transfer_taxes_in_percentage: 1.00,
      title_insurance: 1.00,
      external_and_internal_fit_out_costs: 10000,
      property_tax_in_percentage: 1.00,
      home_owners_insurance_in_percentage: 1.00,
      yearly_maintanance_in_percentage: 1.00,
      yearly_association_fees: 1000,
      monthly_utilities: 100,
      appreciation_in_percentage: 5.00,
      private_mortgage_insurance: 0.00,
      term_of_mortgage: 400000,
      rate_of_mortgage_in_percentage: 0.00,
      points_for_the_mortgage: 0.00,
      origination_fees: 0.00,
      save_home_closing_costs_new_values: 0,
      closing_appraisal_fee: 0,
      closing_credit_report_fee: 0,
      closing_escrow_account: 0,
      closing_flood_determination: 0,
      closing_tax_service_fee: 0,
      closing_recording_mortgage: 0,
      closing_processing_fee: 0,
      closing_underwriting_fee: 0,
      closing_pest_Inspection: 0,
      closing_home_owners_association_transfer_fees: 0,
      closing_costs_others: 0,
      fitOfCosts: 0,

    },
    module14Data: {
      year_of_purchase: 2020,
      year_of_sale: 2029,
      purchase_price: 730000,
      down_payment_in_percentage: 20.00,
      transfer_taxes_in_percentage: 1.83,
      title_insurance: 0.35,
      property_taxes_in_percentage: 0.00,
      external_and_internal_fit_out_costs: 0,
      term_of_mortgage: 584000,
      rate_of_mortgage_prcentage: 3.75,
      points_of_mortgage: 0.00,
      origination_fees: 0.14,
      yearly_rental_income: 4250,
      vacancy_rate_in_percentage: 0.00,
      annual_income_growth_rate: 0.00,
      yearly_association_fees: 0,
      yearly_maintanance_percantage: 0.00,
      managemtnt_fee_in_percentage: 0.00,
      monthly_utilities: 0,
      home_owners_insurance_in_percentage: 0.00,
      appreciation_in_percentage: 0.00,
      selling_costs_in_percentage: 0.00,
      save_rei_closing_costs_new_values: 0,
      closing_appraisal_fee: 0,
      closing_credit_report_fee: 0,
      closing_escrow_account: 0,
      closing_flood_determination: 0,
      closing_tax_service_fee: 0,
      closing_recording_mortgage: 0,
      closing_processing_fee: 0,
      closing_underwriting_fee: 0,
      closing_pest_Inspection: 0,
      closing_home_owners_association_transfer_fees: 0,
      closing_costs_others: 0,
      annualRentalIncomeCalcs: 1200,

    },
    module15Data: {
      type_of_higher_education: '',
      name_of_college: '',
      state_id: 0,
      state: "",
      start_year: 2020,
      years_diff: 4,
      graduation_year: 2023,
      tuition: 0,
      room_board: 0,
      yearly_scholarship_amount: 0,
      yearly_grant_amount: 0,
      year_aid_begins: 0,
      year_aid_ends: 0,
      expected_yearly_finance_aid: 0,
      income_start_year: 2020,
      income_end_year: 2021,
      yearly_income: 0,
      family_start_year: 2020,
      family_end_year: 2021,
      yearly_contribution: 0,
      dependent_status: '',
      fed_sub_firstyear: 0,
      fed_sub_secondyear: 0,
      fed_sub_thirdyear: 0,
      fed_sub_fourthyear: 0,
      fed_sub_fifthyear: 0,
      fed_sub_sixthyear: 0,
      fed_sub_term: 0,
      fed_sub_interest_rate: 0.00,
      fed_sub_origination_fee: 0.00,
      fed_unsub_firstyear: 0,
      fed_unsub_secondyear: 0,
      fed_unsub_thirdyear: 0,
      fed_unsub_fourthyear: 0,
      fed_unsub_fifthyear: 0,
      fed_unsub_sixthyear: 0,
      fed_unsub_term: 0,
      fed_unsub_interest_rate: 0.00,
      fed_unsub_paymentplan: '',
      fed_unsub_origination_fee: 0,
      private_firstyear: 0,
      private_secondyear: 0,
      private_thirdyear: 0,
      private_fourthyear: 0,
      private_fifthyear: 0,
      private_sixthyear: 0,
      private_term: 10,
      private_interest_rate: 0.00,
      private_paymentplan: '',
      private_origination_fee: 0.00,
      on_campus_start_year: 2020,
      off_campus_food: 0,
      clothing: 0,
      entertainment: 0,
      technology: 0,
      transportation: 0,
      miscellaneous: 0,
      medical_costs_before_deductible: 0,
      health_insurance_premium: 0,
      off_campus_start_year: 2022,
      rent: 0,
      utilities: 0,
      food: 0,
      second_clothing: 0,
      second_entertainment: 0,
      second_technology: 0,
      second_transportation: 0,
      second_miscellaneous: 0,
      second_medical_costs_before_deductible: 0,
      second_health_insurance_premium: 0,
      fetchRoomBoard: 0,
      livingExpensesFood: 0,
      livingExpensesClothing: 0,
      livingExpensesEntertainment: 0,
      livingExpensesTechnology: 0,
      livingExpensesTransportation: 0,
      livingExpensesCarPayment: 0,
      livingExpensesCarMaintenance: 0,
      livingExpensesCarInsurance: 0,
      livingExpensesGas: 0,
      livingExpensesMiscellaneous: 0,
      livingExpensesMedicalCost: 0,
      livingExpensesHealthInsurance: 0,
      morelivingExpensesRent: 0,
      morelivingExpensesUtilities: 0,
      morelivingExpensesFood: 0,
      morelivingExpensesClothing: 0,
      morelivingExpensesEntertainment: 0,
      morelivingExpensesTechnology: 0,
      morelivingExpensesTransportation: 0,
      morelivingExpensesCarPayment: 0,
      morelivingExpensesCarMaintenance: 0,
      morelivingExpensesCarInsurance: 0,
      morelivingExpensesGas: 0,
      morelivingExpensesMiscellaneous: 0,
      morelivingExpensesMedicalCost: 0,
      morelivingExpensesHealthInsurance: 0,

    },
    module17Data: {
      roth_contribute_401k:'No',
      rent_insurance: 0,
      rent_total: 0,
      base_roth_income:0,
      vested_amount_at_end_year:0,
      ira_roth_contribute:'',
      ira_roth_personal_contribution:0,
      contribute_hsa_savings_account:'',
      hsa_personal_contribution:0,
      hsa_annual_expected:0,
      self_employment: 0,
      occupation: 'Accountant',
      state_id: 2,
      state: "",
      state_tax: 0.00,
      city: 'Albuquerque',
      city_taxes: 0.00,
      start_year: 2031,
      end_year: 2034,
      yearly_income: 175000,
      bonus_or_tips_or_commission: 0,
      second_start_year: 0,
      second_end_year: 0,
      second_yearly_income: 0,
      rent: 3000,
      utilities: 350,
      food: 350,
      grocery_shopping: 0,
      meals_eaten_not_at_home: 0,
      other_food: 0,
      clothing: 350,
      entertainment: 350,
      technology: 350,
      transportation: 350,
      miscellaneous: 350,
      medical_costs_before_deductible: 350,
      health_insurance_premium: 350,
      contribute_401k: 'Yes',
      beginning_balance_of401k: 34482,
      base_income: 10.00,
      employer_matching_rate: 100.00,
      employer_matching_limit: 6.00,
      Annual_expected401k: 10.00,
      roth_contribute: 'Yes',
      roth_beginning_balance: 7907,
      roth_personal_contribution: 0.00,
      roth_annual_expected_pre_tax: 0.00,
      ira_contribute: 0,
      ira_beginning_balance: 0,
      ira_personal_contribution: 0.00,
      ira_annual_expected_pre_tax: 0.00,
      pension_contribute: 0,
      pension_beginning_balance: 0,
      pension_employer_contribution: 0.00,
      pension_personal_contribution: 0.00,
      pension_annual_expected_pre_tax: 0.00,
      monthlyLivingExpensesRent: 0,
      monthlyLivingExpensesUtilities: 0,
      monthlyLivingExpensesFood: 0,
      monthlyLivingExpensesClothing: 0,
      monthlyLivingExpensesEntertainment: 0,
      monthlyLivingExpensesTechnology: 0,
      monthlyLivingExpensesTransportation: 0,
      monthlyLivingExpensesCarPayment: 0,
      monthlyLivingExpensesCarMaintenance: 0,
      monthlyLivingExpensesCarInsurance: 0,
      monthlyLivingExpensesGas: 0,
      monthlyLivingExpensesMiscellaneous: 0,
      monthlyLivingExpensesMedical: 0,
      monthlyLivingExpensesHealth: 0,

    },
    module18Data: {
      // # New in pwi
      short_term_traditional_savings_account: 0,
      short_term_high_yield_savings_account: 0,
      short_term_certificates_of_deposit: 0,
      short_term_money_market_funds: 0,
      short_term_other: 0,
      max_term_balance_val: 0,
      long_term_managed_accounts: 0,
      long_term_stocks_and_bonds: 0,
      long_term_etfs: 0,
      long_term_mutual_funds: 0,
      long_term_reits: 0,
      long_term_other: 0,
      // # Done
      personal_loan_current_personal_balance: 0,
      personal_loan_months_remaining_on_loan: 0,
      personal_loans_interest_rate:0,
      existing_cash: 0,
      existing_checking_accounts: 0,
      existing_max_balance: 0,
      existing_total_short_term_investments: 0,
      existing_total_long_term_investments: 0,
      existing_max_short_term_before_long_term: 0,
      existing_total_value_of_hsa: 0,
      existing_529_plan: 0,
      existing_cash_value_life_insurance_policies: 0,
      existing_traditional_ra: 0,
      existing_401k_403b: 0,
      existing_roth_ira: 0,
      existing_roth_401k_403b: 0,
      existing_sep_ira: 0,
      existing_pension: 0,
      existing_art: 0,
      existing_jewelry: 0,
      existing_other:0,
      existing_value_of_business:0,
      existing_expected_growth_rate:0,
      existing_car_loans_lease_payment:0,
      existing_car_loans_lease_payment_2:0,
      existing_car_loans_lease_payment_3:0,
      existing_car_loans_lease_payment_4:0,
      vehicle_1_type:0,
      existing_other_expected_growth_rate:0,
      existing_car_current_value:0,
      existing_car_current_loan_balance:0,
      existing_car_months_to_maturity:0,
      existing_car_interest_rate:0,
      existing_car_loans_lease_months_remaining:0,
      existing_car_loans_lease_maintenance:0,
      existing_car_loans_lease_insurance:0,
      existing_car_loans_lease_gas:0,
      vehicle_2_type:0,
      existing_other_expected_growth_rate_2:0,
      existing_car_current_value_2:0,
      existing_car_current_loan_balance_2:0,
      existing_car_months_to_maturity_2:0,
      existing_car_interest_rate_2:0,
      existing_car_loans_lease_months_remaining_2:0,
      existing_car_loans_lease_maintenance_2:0,
      existing_car_loans_lease_insurance_2:0,
      existing_car_loans_lease_gas_2:0,
      vehicle_3_type:0,
      existing_other_expected_growth_rate_3:0,
      existing_car_current_value_3:0,
      existing_car_current_loan_balance_3:0,
      existing_car_months_to_maturity_3:0,
      existing_car_interest_rate_3:0,
      existing_car_loans_lease_months_remaining_3:0,
      existing_car_loans_lease_maintenance_3:0,
      existing_car_loans_lease_insurance_3:0,
      existing_car_loans_lease_gas_3:0,
      vehicle_4_type:0,
      existing_other_expected_growth_rate_4:0,
      existing_car_current_value_4:0,
      existing_car_current_loan_balance_4:0,
      existing_car_months_to_maturity_4:0,
      existing_car_interest_rate_4:0,
      existing_car_loans_lease_months_remaining_4:0,
      existing_car_loans_lease_maintenance_4:0,
      existing_car_loans_lease_insurance_4:0,
      existing_car_loans_lease_gas_4:0,
      existing_car_loans_own_maintenance: 0, 
      existing_car_loans_own_insurance: 0, 
      existing_car_loans_own_gas: 0, 
      existing_car_loans_own_maintenance_2: 0, 
      existing_car_loans_own_insurance_2: 0, 
      existing_car_loans_own_gas_2: 0,   
      existing_car_loans_own_maintenance_3: 0, 
      existing_car_loans_own_insurance_3: 0, 
      existing_car_loans_own_gas_3: 0,  
      existing_car_loans_own_maintenance_4: 0, 
      existing_car_loans_own_insurance_4: 0, 
      existing_car_loans_own_gas_4: 0, 
      married_status:0,
      primary_residence:0,
      fed_sub_type_of_higher_education: 0,
      fed_sub_years: 0,
      fed_sub_months: 0,
      fed_sub_current_balance: 0,
      fed_sub_term: 0,
      fed_sub_interest_rate: 0.00,
      fed_unsub_type_of_higher_education: 0,
      fed_unsub_years: 0,
      fed_unsub_months: 0,
      fed_unsub_loan_amount: 0,
      fed_unsub_accrued_interest: 0,
      fed_unsub_term: 0,
      fed_unsub_interest_rate: 0.00,
      fed_unsub_payment_plan: '',
      private_type_of_higher_education: 0,
      private_years: 0,
      private_months: 0,
      private_loan_amount: 0,
      private_accrued_interest: 0,
      private_term: 0,
      private_interest_rate: 0.00,
      private_payment_plan: 0,
      existing_credit_card_balance: 5000,
      ideal_repayment_schedule: 120,
      creditcard_interest_rate: 25.00,
      // existing_car_current_value: 0,
      // existing_car_current_loan_balance: 5000,
      // existing_car_months_to_maturity: 0,
      // existing_car_interest_rate: 5.00,
      existing_car_loans_maintenance: 120,
      existing_car_loans_lnsurance: 0,
      existing_car_loans_gas: 0,
      existig_home_current_value: 500000,
      existig_home_basis_costs: 400000,
      existing_home_annual_appreciation: 3.00,
      existig_home_current_mortgage_balance: 300000,
      existig_home_mortgage_months_to_maturity: 360,
      existig_home_mortgage_interest_rate: 5.00,
      existing_home_monthly_property_taxes: 5,
      existing_home_monthly_homeowner_insurance: 5,
      existing_home_monthly_private_mortgage_insurance: 5,
      existing_home_monthly_maintenance: 5,
      existing_home_monthly_association_fees: 5,
      existing_home_monthly_utilities: 5,
      existing_home_sale_year: 2033,
      existing_home_selling_costs: 7.00,
      existing_home_selling_costs_2: 0,
      married_status_2: "No",
      primary_residence_2: "No",
      existing_home_sale_year_2: 0,
      existing_home_monthly_utilities_2: 0,
      existing_home_monthly_private_mortgage_insurance_2: 0,
      existig_home_mortgage_interest_rate_2: 0,
      existig_home_mortgage_months_to_maturity_2: 0,
      existing_home_annual_appreciation_2: 0,
      existig_home_current_mortgage_balance_2: 0,
      existig_home_basis_costs_2: 0,
      existig_home_current_value_2: 0,
      current_home_sale_in_15_years_1: "No",
      current_home_sale_in_15_years_2: "No",
      existing_home_monthly_association_fees_2: 0,
      existing_home_monthly_maintenance_2: 0,
      existing_home_monthly_homeowner_insurance_2: 0,
      existing_home_monthly_property_taxes_2: 0,
      existingStudentLoansFedUnSubCurrentLoan: 0,
      existingStudentLoansPrivateCurrentLoan: 0,
      existing_car_loans_maintenance_calcs: 0,
      currentCreditCardBalance: 0,
      credit_card_status: false,
      personal_loans_status: false,
      federal_student_loans_status: false,
      private_student_loans_status: false,
      existig_home_current_status: false,
      existig_home_current_status_2: false,
      occupation_title: 'Software',
    },
    investmentsSheetData: {},
    inputData4: {
      annualExpectedPreTaxReturn: 0,
      newTermOfTheLoan: 0,
      annualSavings: 0,
      newInterestRate: 0,
    },
    inputData3: {
      currentAnnualIncome: 0,
      addMoreIncome: 0,
      currentAnnualExpenses: 0,
      percentInvestedMonthly: 0,
      addSavedExpenses: 0,
      returnSavedCapital: 0,
    },
    incomeTaxData: {
      percentageArray: [10, 12, 22, 24, 32, 35, 37],
      singleRangeArray: [0, 9525, 38700, 82500, 157500, 200000, 500000],
      singleRange2Array: [9525, 38700, 82500, 157500, 200000, 500000, 0],
      originalRangeArray: [0, 9525, 38700, 82500, 157500, 200000, 500000],
      originalRange2Array: [9525, 38700, 82500, 157500, 200000, 500000, 0],
      marriedRangeArray: [0, 9525, 38700, 82500, 157500, 200000, 500000],
      marriedRange2Array: [9525, 38700, 82500, 157500, 200000, 500000, 0],
    },
    carLoan1Data: {
      'Summation Data': {
        2028: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2029: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2030: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2031: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2032: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2033: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2034: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2035: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2036: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2037: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2038: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2039: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2040: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2041: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2042: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
      },
    },
    carLoan2Data: {
      'Summation Data': {
        2030: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2031: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2032: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2033: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2034: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2035: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2036: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2037: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2038: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2039: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2040: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2041: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2042: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2043: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2044: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
      },
    },
    fedSubExistingLoanData: {
      'Summation Data': {
        2030: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2031: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2032: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2033: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2034: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2035: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2036: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2037: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2038: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2039: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2040: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2041: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2042: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2043: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2044: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
      },
    },
    depreciationSheduleData: {},
    buyingAHomeData: {},
    mortgage1Data: {},
    fedUnSubExistingLoanData: {
      'Summation Data': {
        2030: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2031: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2032: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2033: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2034: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2035: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2036: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2037: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2038: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2039: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2040: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2041: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2042: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2043: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2044: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
      },
    },
    privateExistingLoanData: {
      'Summation Data': {
        2030: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2031: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2032: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2033: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2034: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2035: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2036: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2037: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2038: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2039: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2040: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2041: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2042: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2043: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
        2044: { BeginningBalance: 50, LaggingInterest: 3, LaggingPrincipal: 5000 },
      },
    },
    existingHomeData: {},
    existingHomeMortgageData: {},
    existingCarLoanData: {},
    existingCreditCardDebtData: {},
    // incomeStatementnetData: {},
    balanceSheetData: {},
    // taxStatementData: {},
    // investmentsData: {},
    // expenseCalculationsData: {},
    incomeStatementnetData: {
      2028: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2029: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2030: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2031: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2032: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2033: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2034: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2035: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2036: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2037: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2038: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2039: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2040: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2041: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2042: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2043: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2044: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2045: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2046: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2047: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2048: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2049: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2050: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2051: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2052: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2053: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2054: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2055: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2056: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
      2057: {
        livingExpenses: { 'Higher Education Tuition and Fees': 0, 'Rental Operating Expenses': 0 },
        'Gross Income': {
          Third: 0, 'Bonus / Tips / Commission - Third': 0, 'Supplementary Income - Third': 0, 'Spouses Income': 0, 'Rental Income': 0, 'Total Taxes': 0,
        },
        'Total Car Expenses': 0,
        'Total Depreciation Expenses': 0,
        'Total Interest Expenses': 0,
        'Net Income': 0,
      },
    },
    investmentsData: {
      '401k Second': {
        2028: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2029: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2030: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2031: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2032: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2033: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2034: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2035: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2036: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2037: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2038: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2039: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2040: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2041: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2042: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2043: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2044: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2045: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2046: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2047: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2048: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2049: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2050: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2051: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2052: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2053: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2054: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2055: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2056: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },
        2057: {
          'Personal Earnings': 0, 'Employer Contribution': 0, 'Personal Contribution': 0, 'Vesting Reductions': 0, 'Ending Total Balance': 0, 'Employer Earnings': 0,
        },

      },
      'Roth IRA Second': {
        2028: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2029: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2030: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2031: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2032: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2033: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2034: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2035: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2036: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2037: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2038: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2039: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2040: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2041: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2042: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2043: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2044: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2045: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2046: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2047: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2048: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2049: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2050: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2051: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2052: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2053: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2054: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2055: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2056: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2057: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },

      },
      'Seph IRA Second': {
        2028: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2029: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2030: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2031: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2032: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2033: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2034: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2035: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2036: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2037: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2038: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2039: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2040: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2041: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2042: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2043: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2044: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2045: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2046: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2047: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2048: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2049: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2050: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2051: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2052: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2053: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2054: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2055: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2056: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },
        2057: {
          Earnings: 0, 'Ending Balance': 0, 'Annual Contribution': 0,
        },

      },
      'Pension Second': {
        2028: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2029: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2030: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2031: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2032: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2033: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2034: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2035: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2036: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2037: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2038: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2039: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2040: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2041: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2042: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2043: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2044: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2045: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2046: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2047: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2048: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2049: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2050: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2051: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2052: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2053: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2054: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2055: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2056: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },
        2057: {
          'Personal Contribution': 0, 'Employer Contribution': 0, Earnings: 0, 'Ending Balance': 0,
        },

      },

    },
    expenseCalculationsData: {
      'Further Career Advancement': {
        2028: { Yearly: 0 },
      },
    },
    cashFlowStatementnetData: {},
    studentLoan1Data: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    studentLoan2Data: {},
    cashFlowData: {},
    fedSubHigherEdLoanData: {
      'Summation Data': {
        2020: { 'W/O Accrual': 0 },
        2021: { 'W/O Accrual': 0 },
        2022: { 'W/O Accrual': 0 },
        2023: { 'W/O Accrual': 0 },
        2024: { 'W/O Accrual': 0 },
        2025: { 'W/O Accrual': 0 },
        2026: { 'W/O Accrual': 0 },
        2027: { 'W/O Accrual': 0 },
        2028: { 'W/O Accrual': 0 },
        2029: { 'W/O Accrual': 0 },
        2030: { 'W/O Accrual': 0 },
        2031: { 'W/O Accrual': 0 },
        2032: { 'W/O Accrual': 0 },
        2033: { 'W/O Accrual': 0 },
        2034: { 'W/O Accrual': 0 },
        2035: { 'W/O Accrual': 0 },
      },
    },
    fedUnSubHigherEdLoanData: {
      'Summation Data': {
        2020: { 'Loans Taken': 0 },
        2021: { 'Loans Taken': 0 },
        2022: { 'Loans Taken': 0 },
        2023: { 'Loans Taken': 0 },
        2024: { 'Loans Taken': 0 },
        2025: { 'Loans Taken': 0 },
        2026: { 'Loans Taken': 0 },
        2027: { 'Loans Taken': 0 },
        2028: { 'Loans Taken': 0 },
        2029: { 'Loans Taken': 0 },
        2030: { 'Loans Taken': 0 },
        2031: { 'Loans Taken': 0 },
        2032: { 'Loans Taken': 0 },
        2033: { 'Loans Taken': 0 },
        2034: { 'Loans Taken': 0 },
        2035: { 'Loans Taken': 0 },
      },
    },
    privateHigherEdLoanData: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    databaseData: {},
    moduleServices: {},
    moreHigherStudentLoanData: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    assumptionsData: {
      2020: { Expenses: 0, value: 0 },
      2021: { Expenses: 0, value: 0 },
      2022: { Expenses: 0, value: 0 },
      2023: { Expenses: 0, value: 0 },
      2024: { Expenses: 0, value: 0 },
      2025: { Expenses: 0, value: 0 },
      2026: { Expenses: 0, value: 0 },
      2027: { Expenses: 0, value: 0 },
      2028: { Expenses: 0, value: 0 },
      2029: { Expenses: 0, value: 0 },
      2030: { Expenses: 0, value: 0 },
      2031: { Expenses: 0, value: 0 },
      2032: { Expenses: 0, value: 0 },
      2033: { Expenses: 0, value: 0 },
      2034: { Expenses: 0, value: 0 },
      2035: { Expenses: 0, value: 0 },
      2036: { Expenses: 0, value: 0 },
      2037: { Expenses: 0, value: 0 },
      2038: { Expenses: 0, value: 0 },
      2039: { Expenses: 0, value: 0 },
      2040: { Expenses: 0, value: 0 },
      2041: { Expenses: 0, value: 0 },
      2042: { Expenses: 0, value: 0 },
      2043: { Expenses: 0, value: 0 },
      2044: { Expenses: 0, value: 0 },
      2045: { Expenses: 0, value: 0 },
      2046: { Expenses: 0, value: 0 },
      2047: { Expenses: 0, value: 0 },
      2048: { Expenses: 0, value: 0 },
      2049: { Expenses: 0, value: 0 },
      2050: { Expenses: 0, value: 0 },
    },
    fedSubMoreHigherEdLoanData: {
      'Summation Data': {
        2020: { 'W/O Accrual': 0 },
        2021: { 'W/O Accrual': 0 },
        2022: { 'W/O Accrual': 0 },
        2023: { 'W/O Accrual': 0 },
        2024: { 'W/O Accrual': 0 },
        2025: { 'W/O Accrual': 0 },
        2026: { 'W/O Accrual': 0 },
        2027: { 'W/O Accrual': 0 },
        2028: { 'W/O Accrual': 0 },
        2029: { 'W/O Accrual': 0 },
        2030: { 'W/O Accrual': 0 },
        2031: { 'W/O Accrual': 0 },
        2032: { 'W/O Accrual': 0 },
        2033: { 'W/O Accrual': 0 },
        2034: { 'W/O Accrual': 0 },
        2035: { 'W/O Accrual': 0 },
      },
    },
    fedUnSubMoreHigherEdLoanData: {
      'Summation Data': {
        2020: { 'Loans Taken': 0 },
        2021: { 'Loans Taken': 0 },
        2022: { 'Loans Taken': 0 },
        2023: { 'Loans Taken': 0 },
        2024: { 'Loans Taken': 0 },
        2025: { 'Loans Taken': 0 },
        2026: { 'Loans Taken': 0 },
        2027: { 'Loans Taken': 0 },
        2028: { 'Loans Taken': 0 },
        2029: { 'Loans Taken': 0 },
        2030: { 'Loans Taken': 0 },
        2031: { 'Loans Taken': 0 },
        2032: { 'Loans Taken': 0 },
        2033: { 'Loans Taken': 0 },
        2034: { 'Loans Taken': 0 },
        2035: { 'Loans Taken': 0 },
      },
    },
    privateMoreHigherEdLoanData: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    inputData: {
      higherEducationSalary: 0,
      highSchoolSalary: 0,
      highEducationGrowthRateOnSalary: 0,
      highSchoolGrowthRateOnSalary: 0,
      capitalInvestment: 0,
    },
    invPropertyRoiData: {
      'Real Estate': {
        2020: { 'Asset Value': 0 },
        2021: { 'Asset Value': 0 },
        2022: { 'Asset Value': 0 },
        2023: { 'Asset Value': 0 },
        2024: { 'Asset Value': 0 },
        2025: { 'Asset Value': 0 },
        2026: { 'Asset Value': 0 },
        2027: { 'Asset Value': 0 },
        2028: { 'Asset Value': 0 },
        2029: { 'Asset Value': 0 },
        2030: { 'Asset Value': 0 },
        2031: { 'Asset Value': 0 },
        2032: { 'Asset Value': 0 },
        2033: { 'Asset Value': 0 },
        2034: { 'Asset Value': 0 },
        2035: { 'Asset Value': 0 },
        2036: { 'Asset Value': 0 },
        2037: { 'Asset Value': 0 },
        2038: { 'Asset Value': 0 },
        2039: { 'Asset Value': 0 },
        2040: { 'Asset Value': 0 },
        2041: { 'Asset Value': 0 },
        2042: { 'Asset Value': 0 },
        2043: { 'Asset Value': 0 },
        2044: { 'Asset Value': 0 },
        2045: { 'Asset Value': 0 },
        2046: { 'Asset Value': 0 },
        2047: { 'Asset Value': 0 },
        2048: { 'Asset Value': 0 },
        2049: { 'Asset Value': 0 },
        2050: { 'Asset Value': 0 },
      },
      Data1: {
        2020: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2021: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2022: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2023: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2024: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2025: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2026: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2027: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2028: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2029: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2030: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2031: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2032: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2033: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2034: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2035: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2036: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2037: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2038: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2039: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2040: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2041: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2042: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2043: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2044: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2045: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2046: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2047: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2048: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2049: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
        2050: {
          'Rental Income': 0, 'Association Fees': 0, 'Management Fees': 0, 'Houseowners Insurance': 0, 'Property Maintenance': 0, Utilities: 0, 'Property Taxes': 0,
        },
      },
      'Buying Expenses': { 'Down Payment': 0, 'Closing Costs': 0, 'Fit Out Costs': 0 },

    },
    reiOutputBackendData: {},
    mortgage2Data: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    newHigherStudentLoanData: {},
    fedSubNewHigherEdLoanData: {
      'Summation Data': {
        2020: { 'W/O Accrual': 0 },
        2021: { 'W/O Accrual': 0 },
        2022: { 'W/O Accrual': 0 },
        2023: { 'W/O Accrual': 0 },
        2024: { 'W/O Accrual': 0 },
        2025: { 'W/O Accrual': 0 },
        2026: { 'W/O Accrual': 0 },
        2027: { 'W/O Accrual': 0 },
        2028: { 'W/O Accrual': 0 },
        2029: { 'W/O Accrual': 0 },
        2030: { 'W/O Accrual': 0 },
        2031: { 'W/O Accrual': 0 },
        2032: { 'W/O Accrual': 0 },
        2033: { 'W/O Accrual': 0 },
        2034: { 'W/O Accrual': 0 },
        2035: { 'W/O Accrual': 0 },
      },
    },
    fedUnSubNewHigherEdLoanData: {
      'Summation Data': {
        2020: { 'Loans Taken': 0 },
        2021: { 'Loans Taken': 0 },
        2022: { 'Loans Taken': 0 },
        2023: { 'Loans Taken': 0 },
        2024: { 'Loans Taken': 0 },
        2025: { 'Loans Taken': 0 },
        2026: { 'Loans Taken': 0 },
        2027: { 'Loans Taken': 0 },
        2028: { 'Loans Taken': 0 },
        2029: { 'Loans Taken': 0 },
        2030: { 'Loans Taken': 0 },
        2031: { 'Loans Taken': 0 },
        2032: { 'Loans Taken': 0 },
        2033: { 'Loans Taken': 0 },
        2034: { 'Loans Taken': 0 },
        2035: { 'Loans Taken': 0 },
      },
    },
    privateNewHigherEdLoanData: {
      'Summation Data': {
        2020: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2021: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2022: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2023: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2024: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2025: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2026: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2027: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2028: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2029: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2030: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2031: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2032: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2033: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2034: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2035: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2036: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2037: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2038: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2039: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2040: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2041: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2042: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2043: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2044: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2045: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2046: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2047: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2048: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2049: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
        2050: { EndingBalance: 0, LaggingInterest: 0, LaggingPrincipal: 0 },
      },
    },
    incomeStatementData: {
      2020: { 'Adjusted Gross Income': 0 },
      2021: { 'Adjusted Gross Income': 0 },
      2022: { 'Adjusted Gross Income': 0 },
      2023: { 'Adjusted Gross Income': 0 },
      2024: { 'Adjusted Gross Income': 0 },
      2025: { 'Adjusted Gross Income': 0 },
      2026: { 'Adjusted Gross Income': 0 },
      2027: { 'Adjusted Gross Income': 0 },
      2028: { 'Adjusted Gross Income': 0 },
      2029: { 'Adjusted Gross Income': 0 },
      2030: { 'Adjusted Gross Income': 0 },
      2031: { 'Adjusted Gross Income': 0 },
      2032: { 'Adjusted Gross Income': 0 },
      2033: { 'Adjusted Gross Income': 0 },
      2034: { 'Adjusted Gross Income': 0 },
      2035: { 'Adjusted Gross Income': 0 },
    },

    taxStatementData: {
      2020: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2021: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2022: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2023: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2024: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2025: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2026: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2027: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2028: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2029: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2030: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2031: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2032: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2033: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2034: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
      2035: { 'Gross Income': { 'Total Gross Income (excluding Rental Income)': 0 }, taxes: { 'Federal Income Tax (x Federal Taxable Income)': 0 } },
    },
    cashFlowStatementData: {},
    spouseStudentLoanData: {
      'Summation Data': {
        2028: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2029: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2030: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2031: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2032: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2033: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2034: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2035: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2036: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2037: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2038: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2039: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2040: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2041: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
        2042: { BeginningBalance: 60, LaggingInterest: 2, LaggingPrincipal: 6000 },
      },
    },
    buyingAHomeMortgageData: {},
    existingStudentLoanData: {},
    studentLoanTaxData: {},
    inputTaxesData: {},
    incomeStatementnetIncomeData: {
      2020: { 'Net Income': 0 },
      2021: { 'Net Income': 0 },
      2022: { 'Net Income': 0 },
      2023: { 'Net Income': 0 },
      2024: { 'Net Income': 0 },
      2025: { 'Net Income': 0 },
      2026: { 'Net Income': 0 },
      2027: { 'Net Income': 0 },
      2028: { 'Net Income': 0 },
      2029: { 'Net Income': 0 },
      2030: { 'Net Income': 0 },
      2031: { 'Net Income': 0 },
      2032: { 'Net Income': 0 },
      2033: { 'Net Income': 0 },
      2034: { 'Net Income': 0 },
      2035: { 'Net Income': 0 },
    },
  };
  return intialValues;
}
export default modulesData;
