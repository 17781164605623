import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import Pageloader from '../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import styles from './styles';
import { confirmAlert } from 'react-confirm-alert';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import infoIcon from '../../assets/img/sight/info-icon-contrast.png';
import folderIcon from '../../assets/img/sight/explorer_folder_icon.png';
import profileIcon from '../../assets/img/sight/1586854644.jpg';
import alertIcon from '../../assets/img/alert_icon.png';
import fileIcon from '../../assets/img/sight/explorer_file_icon.png';
import * as timeLineActions from '../../calculations/time-line';
import * as moduleServices from '../../calculations/modules-services';

// import { withRouter } from 'react-router-dom';
// import compose from 'recompose/compose';

const SAVE_TIMELINE = loader('../../graphql/schema/fc/save-timeline.graphql');
const SAVEFOLDER = loader('../../graphql/schema/pwi/save-folder.graphql');
const SAVENOTES = loader('../../graphql/schema/pwi/save-note.graphql');
const SIGHTDETAILS = loader('../../graphql/schema/pwi/sight-data.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const SAVECOMPARESCENARIO = loader('../../graphql/schema/pwi/saveCompareScenario.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
function renderThumb({ style, ...props }) {
  const thumbStyle = {
    backgroundColor: '#eca041',
    borderRadius: '3px',
    width: '3px',
  };
  return (
    <div
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
}
renderThumb.propTypes = {
  style: PropTypes.object.isRequired,
};

const PwiSave = (props) => {
  const {
    classes, getSaveData, moduleParams, setLoadScenarioName, loadScenarioName, setPopupOpen, history, setLoadValue, SetNotesSuccessMsg
  } = props;
  const [folderName, setFolderName] = React.useState('');
  const [foldersData, setFoldersData] = React.useState([]);
  const [scenariosData, setScenariosData] = React.useState([]);
  const [filteredModules, setFilteredModules] = React.useState([]);
  const [scenarioList, setScenariosList] = React.useState([]);
  const [scenarioFormat, setScenarioFormat] = React.useState('');
  const [scenarioInput, setScenarioInput] = React.useState('');
  const [scenarioId, setScenarioId] = React.useState({});
  const [inputErrorMsg, setInputErrorMsg] = React.useState('');
  // const [errorMessage, setErrorMessage] = React.useState('');
  const [folderErrorMessage, setFolderErrorMessage] = React.useState('');
  const [headerFolderName, setHeaderFolderName] = React.useState('');
  const [filepath, setFilePath] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [type, setType] = React.useState('');
  const [optionId, setOptionId] = React.useState('');
  const [scId,setScId] = React.useState('');

  const [myDetails, setMyDetails] = React.useState({})

  const intialDisplayItems = {
    fc: true,
    path: true,
    categeory: true,
    folder: true,
    modules: false,
    scenario: false,
  };
  const [displayItems, setDisplayItems] = React.useState(intialDisplayItems);

  const moduleNames = [];
  moduleNames.module1 = 'Financial Independence';
  moduleNames.module2 = 'Higher Education';
  moduleNames.module4 = 'Career Path';
  moduleNames.module5 = 'Career Advancement';
  moduleNames.module6 = 'More Higher Education';
  moduleNames.module8 = 'Additional Career Advancement';
  moduleNames.module9 = 'Investments';
  moduleNames.module10 = 'Family';
  moduleNames.module11 = 'Vacation';
  moduleNames.module12 = 'Buying a Car';
  moduleNames.module13 = 'Buying a Home';
  moduleNames.module14 = 'Real Estate Investment';
  moduleNames.module15 = 'Further Higher Education';
  moduleNames.module17 = 'Further Career Advancement';
  moduleNames.module18 = 'Existing Assets & Liabilities';

  useQuery(MY_DETAILS, {
    onCompleted(response) {
      setLoading(false)
      setMyDetails(response);
    },
    onError(errors) {
      setLoading(false);
    },
  });

  useQuery(SIGHTDETAILS, {
    variables: {
      operation_type: 'save',
      type: 'scenarios',
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.SightData) {
        setLoading(false);
        if (moduleParams.categeory === 'My Saved Notes') {
          setFoldersData(response.SightData.notesFolder);
          setScenariosData(response.SightData.notes);
        } else if (moduleParams.categeory === 'My Comparisons') {
          setFoldersData(response.SightData.compare_scenario);
          setScenariosData(response.SightData.compare_scenario_notes);
        } else {
          setFoldersData(response.SightData.folders);
          setScenariosData(response.SightData.scenarios);
        }
      }
    },
  });


  const [saveTimeLine] = useMutation(SAVE_TIMELINE, {
    onCompleted({
      saveTimeLine: {
        status,
        message
      },
    }) {
      if (status) {
        setLoading(false);
        if (type === "mcs4") {
          history.push('/my-career-sketch/level4');
        } else if (type === "mcs5") {
          history.push('/my-career-sketch/level5');
        }
      }
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setFolderErrorMessage(formatedErrors);
    },
  });


  const [submitFolderName] = useMutation(SAVEFOLDER, {
    onCompleted({
      saveFolder: {
        id,
        name,
      },
    },) {

      setLoading(false);
      const folderObject = foldersData;
      folderObject.push({ id, name });
      setFoldersData(folderObject);

      const valuesUpdated = displayItems;
      valuesUpdated.folder = true;
      setDisplayItems(valuesUpdated);

      const obj = {
        type: moduleParams.categeory === 'My Saved Notes' ? 'notes' : moduleParams.categeory === 'My Comparisons' ? 'comparisons' : 'scenarios',
        id,
        name,
      }
      handleSelectedModules(obj);

      setFolderName('');
      setFolderErrorMessage('');
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setFolderErrorMessage(formatedErrors);
    },
  });
  const [submitSaveNotes] = useMutation(SAVENOTES, {
    onCompleted({
      saveNote: {
        status,
        message,
        id
      },
    }) {
      if (status) {
        let updateObject = { ...loadScenarioName };
        updateObject.currentId = id;
        setLoadScenarioName(updateObject);
        setLoading(false);
        setPopupOpen(false);
        // setSaveSuccessMessage( message );
        SetNotesSuccessMsg(true);
      }
    },
    onError(errors) {
      setLoading(false);
      if (errors) {
        // const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        //   <span key={`login-error-${Math.random()}`}>{message}</span>
        // ));
        // setErrorMessage(formatedErrors);
      }
    },
  });

  const handleSaveFolder = () => {
    if (folderName !== '') {
      setLoading(true);
      const obj = {
        type: moduleParams.categeory === 'My Saved Notes' ? 'notes' : moduleParams.categeory === 'My Comparisons' ? 'comparisons' : 'scenarios',
        id: '',
        name: folderName,
      }

      submitFolderName({
        variables: {
          data: obj,
        },
      }, () => {
        // const valuesUpdated = displayItems;
        // valuesUpdated.folder = true;
        // setDisplayItems(valuesUpdated);
        // handleSelectedModules(obj)
      });
    } else {
      setFolderErrorMessage('Please provide a folder name');
    }
  };

  const handleNewFolder = (event) => {
    setFolderName(event.target.value);
  };

  const handleSelectedModules = (dirObj) => {
    setHeaderFolderName(dirObj.name);
    setScenarioId({});
    moduleParams.folderId = dirObj.id;
    setFilteredModules([]);
    setScenariosList([]);
    let filteredModule = [];
    filteredModule = scenariosData;

    let newUpdatedArray = [];
    // eslint-disable-next-line
    filteredModule = _.filter(filteredModule, { directory_id: dirObj.id });
    const findModule = _.find(filteredModule, { selected_module: moduleParams.module });
    setScenarioId(findModule ? findModule : {})
    if (findModule) {
      const valuesUpdated = displayItems;
      valuesUpdated.modules = true;
      setDisplayItems(valuesUpdated);
      handleGetScenarios(findModule)
    }
    if (filteredModule.length === 0 || findModule === undefined) {
      const obj = {
        directory_id: dirObj.id,
        moduleName: moduleNames[moduleParams.module],
        selected_module: moduleParams.module,
      }
      setScenarioId(obj)
      const valuesUpdated = displayItems;
      valuesUpdated.modules = true;
      setDisplayItems(valuesUpdated);
      handleGetScenarios(obj)
      newUpdatedArray.push(obj);
    }
    filteredModule.forEach((data) => {
      const obj = data;
      obj.moduleName = moduleNames[obj.selected_module];
      newUpdatedArray.push(obj);
    });
    newUpdatedArray = _.uniqBy(newUpdatedArray, 'selected_module');
    setFilteredModules(newUpdatedArray);
    const valuesUpdated = displayItems;
    valuesUpdated.folder = true;
    setDisplayItems(valuesUpdated);
  };

  const handleGetScenarios = (data) => {
    setScenarioId(data);
    let scenarios = [];
    scenarios = _.filter(scenariosData, { directory_id: moduleParams.folderId, selected_module: data.selected_module });
    setScenariosList(scenarios);
    setScenarioFormat('');
    setFilePath('');
    const valuesUpdated = displayItems;
    valuesUpdated.modules = true;
    setDisplayItems(valuesUpdated);
  };
  const handleFocusScenario = () => {
    let format = '';
    if (moduleParams.categeory !== 'My Saved Notes') {
      format = timeLineActions.generateScenarioName(moduleParams.module, 'save', { name: '' });
    } else {
      // eslint-disable-next-line
      format = "-" + commonFunctions.formatDate(new Date());
    }
    setScenarioFormat(format);
    setFilePath('');
  };

  const handleScenarioName = (event) => {
    if (event.target.value) {
      // eslint-disable-next-line
      setScId('');
      setScenarioInput(event.target.value);
      let format = '';
      if (moduleParams.categeory !== 'My Saved Notes') {
        format = timeLineActions.generateScenarioName(moduleParams.module, 'save', { name: event.target.value });
      } else {
        format = `${event.target.value}-${commonFunctions.formatDate(new Date())}`;
      }
      setScenarioFormat(format);
      setInputErrorMsg('');
    } else {
      setScenarioFormat('');
    }
  };

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoading(false);
        if (timeLineActions.moduleTimelineData.length > 0) {
          const moduleTimeLine = timeLineActions.moduleTimelineData;
          const index = _.findIndex(moduleTimeLine, { selected_module: moduleParams.module });
          moduleTimeLine[index].scenario_id = id;
          timeLineActions.setModuleTimeLineData(moduleTimeLine, 'save');
          const timeLineVal = moduleTimeLine.map((data) => _.pick(data, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id']));
          saveTimeLine({
            variables: {
              data: {
                name: 'Active Selection',
                order: timeLineVal,
              },
            },
          });
        }
        setPopupOpen(false);
        // setSaveSuccessMessage( message );
        setLoadValue(true);
      }
    },
    onError(errors) {
      setLoading(false);
      // if(errors && errors.graphQLErrors){
      //   const formatedErrors = errors.graphQLErrors.map(({ message }) => (
      //   <span key={`login-error-${Math.random()}`}>{message}</span>
      // ));
      // setErrorMessage(formatedErrors);
      // }
    },
  });

  const [SaveCompareScenario] = useMutation(SAVECOMPARESCENARIO, {
    onCompleted({
      SaveCompareScenario: {
        status,
        message,
      },
    }) {
      if (status) {
        setPopupOpen(false);
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className="compare-popup">
              <div>
                <div className="compare-header">
                  <div aria-hidden="true">
                    <i className="la la-times" onClick={() => { onClose() }} />
                    <h4>Compare Saved Scenarios</h4>
                  </div>
                </div>
                <div className="compare-body">
                  <p>Comparison saved successfully</p>
                </div>
              </div>
              <div className="compare-footer">
                <button
                  type="button"
                  onClick={() => { onClose() }}
                >
                  Close
                </button>
              </div>
            </div>
          ),
        });
      }
    },
    onError(errors) {
      setLoading(false);
      // if(errors && errors.graphQLErrors){
      //   const formatedErrors = errors.graphQLErrors.map(({ message }) => (
      //   <span key={`login-error-${Math.random()}`}>{message}</span>
      // ));
      // // setErrorMessage(formatedErrors);
      // }
    },
  });

  useEffect(() => {
    setType(window.location ? window.location.search.split('=')[1] : "");
    setOptionId(window.location ? window.location.hash.split('=')[1] : "");
  }, []);

  const handleSave = () => {
    let finalObject = {};
    if (moduleParams.categeory === 'My Saved Notes' && moduleParams.type === 'save') {
      if (scenarioInput) {
        setLoading(true);
        finalObject = {
          selected_module: moduleParams.module,
          id: '',
          note_title: scenarioFormat,
          content: moduleParams.content,
          directory_id: moduleParams.folderId,
        };
        submitSaveNotes({
          variables: {
            data: finalObject,
          },
        });
        setLoadScenarioName(finalObject);
      } else {
        setInputErrorMsg('Please provide a note name.');
      }
    } else if (moduleParams.categeory === 'My Comparisons' && moduleParams.type === 'save') {
      SaveCompareScenario({
        variables: {
          data: {
            "name": scenarioFormat,
            "scenario_1": moduleParams.scenarioId1,
            "scenario_2": moduleParams.scenarioId2,
            "folder_id": moduleParams.folderId,
            "selected_module": moduleParams.module,
            "difference_type": 1,
            "relation_year": moduleServices.module1Data.start_year,
            "start_year_relation": moduleParams.start_year_relation,
            "end_year_relation": moduleParams.end_year_relation,
          },
        },
      });
    } else if (moduleParams.categeory === 'My Saved Notes' && moduleParams.type === 'load') {
      setLoadScenarioName(filepath);
      setPopupOpen(false);
    } else if (scenarioInput) {

      setLoading(true);
      if (moduleParams.module === "module1" && getSaveData.start_year < new Date().getFullYear()) {
        setLoading(false);
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.impPopup}>
              <div>
                <h3>Important Tip</h3>
                <div className={classes.impDiv}>
                  <span><img src={alertIcon} alt="" /></span>
                  <p>
                    For new scenarios, please select a start year that is greater than or equal to today&apos;s year.
                  </p>
                </div>
              </div>
              <div className={classes.impbuttonOkRedo}>
                <button
                  type="button"
                  onClick={() => { onClose() }}
                >
                  Close
                </button>
              </div>
            </div>
          ),
        });
      } else {
        setLoading(true);
        finalObject = {
          module: moduleParams.module,
          folder_id: moduleParams.folderId,
          relation_year: moduleServices.module1Data.start_year,
          scenario_name: scenarioFormat !== '' ? scenarioFormat : scenarioInput,
          type: type === "mcs4" || type === "mcs5" ? "mcs" : "",
          level: type ? type.split("")[3] : "",
          option_id: optionId,
          id:scId ,        
        };
        if (moduleParams.module === 'module1') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredPWIFI(getSaveData);
        } else if (moduleParams.module === 'module2') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredPWIhigherEducation(getSaveData);
        } else if (moduleParams.module === 'module4') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredCareerPath(getSaveData);
        } else if (moduleParams.module === 'module5') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredCareerPath(getSaveData);
        } else if (moduleParams.module === 'module6') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredPWIhigherEducation(getSaveData);
        } else if (moduleParams.module === 'module8') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredCareerPath(getSaveData);
        } else if (moduleParams.module === 'module9') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredInvestObject(getSaveData);
        } else if (moduleParams.module === 'module10') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredPWIFamily(getSaveData);
        } else if (moduleParams.module === 'module11') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredVacationObject(getSaveData);
        } else if (moduleParams.module === 'module12') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredBuyingCarObject(getSaveData);
        } else if (moduleParams.module === 'module13') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredBuyingHomeObject(getSaveData);
        } else if (moduleParams.module === 'module14') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredBuyingReiObject(getSaveData);
        } else if (moduleParams.module === 'module15') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredPWIhigherEducation(getSaveData);
        } else if (moduleParams.module === 'module17') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredCareerPath(getSaveData);
        } else if (moduleParams.module === 'module18') {
          finalObject[moduleParams.moduleNumber] = pwiObject.getfilteredExistingObject(getSaveData);
        }
        saveScenarios({
          variables: {
            data: finalObject,
          },
        });
      }

    } else {
      setInputErrorMsg('Give your scenario a name to continue.');
    }
    // } else {
    //   if(moduleParams.categeory === 'My Saved Notes' && moduleParams.type === 'save') {
    //     setInputErrorMsg('Give your scenario a name to continue.')
    //   }else {
    //     setInputErrorMsg('Please provide a note name');

    //   }

    // }
  };

  const displayPathItems = (item) => {
    const valuesUpdated = {
      fc: true,
      path: false,
      categeory: false,
      folder: false,
      modules: false,
      scenario: false,
    };
    if (item === 'finance') {
      valuesUpdated.path = false;
    } else if (item === 'own') {
      valuesUpdated.path = true;
    } else if (item === 'categeory') {
      valuesUpdated.path = true;
      valuesUpdated.categeory = true;
    } else if (item === 'folder') {
      valuesUpdated.categeory = true;
      valuesUpdated.path = true;
      valuesUpdated.folder = true;
      // valuesUpdated.modules = true;
    } else if (item === 'module') {
      valuesUpdated.modules = true;
      valuesUpdated.folder = true;
      valuesUpdated.categeory = true;
      valuesUpdated.path = true;
    } else if (item === 'scenario') {
      valuesUpdated.modules = true;
      valuesUpdated.folder = true;
      valuesUpdated.categeory = true;
      valuesUpdated.path = true;
      valuesUpdated.scenario = true;
    }

    setDisplayItems(valuesUpdated);
  };
  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classNames(classes.senseBody, classes.sightPopupBlock)}>
        <ReactTooltip id="infoIcon" place="right" type="info" effect="solid" className={classes.tooltipInfo} />
        <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
        <div className={classes.explorerHead}>
          <div className={classes.containerFluild}>
            <div className={classes.explorerTitle}>
              <Typography variant="h2" component="h2">
                Inkwiry Sight
              </Typography>
              <div className={classes.relationYear}>
                <ReactTooltip id="infoIcon4" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
                <span
                  className={classes.infoIcon}
                  data-for="infoIcon4"
                  data-tip="To load a scenario, the start year (and end year) of that scenario has to fall within the Current Timeline. You set your timeline in the Financial Independence module. To change your timeline, change your start year in the Financial Independence module."
                >
                  <img src={infoIcon} alt="info" />
                </span>
                {/* <p>
                  Current Timeline:{' '}
                  {getSaveData.start_year}
                  {' '}
                  -
                  {' '}
                  {getSaveData.end_year}
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.breadCrumbs}>
          <div className={classNames(classes.breadCrumbsLeft, classes.sightBreadCrumbs)}>
            <div className={classNames(classes.folderIcon, classes.folderSection)}>
              <ul>
                <li>
                  <p>
                    <img src={folderIcon} alt="folder" />
                  </p>
                </li>
              </ul>
            </div>
            <div className={classes.breadcrumbsList}>
              <ul>
                <li>
                  <p aria-hidden="true" onClick={() => { displayPathItems('finance'); }}>Financial Configurator</p>
                </li>
                {displayItems.path && (
                  <li>
                    <p aria-hidden="true" onClick={() => { displayPathItems('own'); }}>My</p>
                  </li>
                )}

                {displayItems.categeory && (
                  <li>
                    <p aria-hidden="true" onClick={() => { displayPathItems('categeory'); }}>{moduleParams.categeory}</p>
                  </li>
                )}

                {/* <li>
                  <a href="/">My Saved Notes</a>
                </li> */}
                {headerFolderName !== '' && displayItems.folder && (
                  <li>
                    <p aria-hidden="true" onClick={() => { displayPathItems('folder'); }}>{headerFolderName}</p>
                  </li>
                )}
                {scenarioId && scenarioId.moduleName && displayItems.modules && (
                  <li>
                    <p aria-hidden="true" onClick={() => { displayPathItems('module'); }}>{scenarioId.moduleName}</p>
                  </li>
                )}
                {/* {filepath && scenarioId.selected_module === moduleParams.module && displayItems.scenario &&(
                <li>
                  <p>{filepath}</p>
                </li>
                )} */}

              </ul>
            </div>
          </div>
        </div>
        <div className={classes.explorerMainContent}>
          <div className={classes.explorerContentIn}>
            <div className={classNames(classes.explorerContent, classes.exploreOne)}>
              <Typography variant="h2" component="h2">Application</Typography>
              <ul>
                <li className={classes.activeTab}><p><span>Financial Configurator</span></p></li>
              </ul>
            </div>
            <div className={classNames(classes.explorerContent, classes.exploreTwo)}>
              {displayItems.fc && (
                <Typography variant="h2" component="h2">
                  <span>Path</span>
                  {moduleParams.categeory === 'My Saved Notes' && (
                    <span
                      className={classes.infoImage}
                      data-for="infoIcon"
                      data-tip="There are three paths in Inkwiry: My, Shared, and Inkwiry. In the 'My' path, you will find all scenarios and career sketches that you have created. In the 'Shared' path, you will find all scenarios and career sketches that were shared with you. Lastly, in the 'Inkwiry' path, you will find all scenarios and career sketches that Inkwiry has created for you."
                    >
                      <img src={infoIcon} alt="info" />
                    </span>
                  )}

                </Typography>
              )}

              <ul>
                <li className={classes.activeTab}>
                  <p>
                    <img src={profileIcon} alt="info" className={classes.borderIcon} />
                    <span aria-hidden="true" onClick={() => { displayPathItems('own'); }}>{myDetails.me !== undefined && myDetails.me.user_fname}</span>
                  </p>
                </li>
              </ul>
            </div>
            <div className={classNames(classes.explorerContent, classes.exploreThird)}>
              {displayItems.path && (<Typography variant="h2" component="h2">Category</Typography>)}
              {displayItems.path && (
                <ul>
                  <li className={classes.activeTab}>
                    <p>
                      <img src={folderIcon} alt="info" />
                      <span aria-hidden="true" onClick={() => { displayPathItems('categeory'); }}>{moduleParams.categeory}</span>
                    </p>

                  </li>
                </ul>
              )}

            </div>
            {displayItems.categeory && (

              <div className={classNames(classes.explorerContent, classes.sketchContent, classes.exploreFour)}>

                <Typography variant="h2" component="h2">
                  <span>{moduleParams.sketchName}</span>
                  {moduleParams.type === 'save'
                    && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon2"
                        data-position="left"
                        data-tip="Folders are created to store the scenarios that you build. Just like saving any file on your computer, you save a scenario to a folder. You can save an infinite number of scenarios from any module to a folder."
                      >
                        <img src={infoIcon} alt="info" />
                      </span>
                    )}
                </Typography>
                <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.scrollMenu)}>

                  <ul className={classes.sketchFolders}>
                    {foldersData && foldersData.length > 0 && foldersData.map((data) => (
                      <li className={data.name === headerFolderName ? classes.activeTab : ''} aria-hidden="true"
                        onClick={() => { handleSelectedModules(data); }}>
                        <p>
                          <img src={folderIcon} alt="info" />

                          {data.name}
                        </p>
                      </li>
                    ))}
                    {moduleParams.type === 'save' && (
                      <li className={classes.newFolder}>
                        <div><input type="text" placeholder="New Folder Name" value={folderName} onChange={(e) => { handleNewFolder(e); }} /></div>
                      </li>
                    )}
                  </ul>
                </Scrollbars>
                {moduleParams.type === 'save'
                  && (
                    <div className={classes.addNewBtn}>
                      <ul className={classes.saveSketchBtn}>
                        {folderErrorMessage && <p>{folderErrorMessage}</p>}

                        <li>
                          <Button className={classes.loadBtn} onClick={handleSaveFolder}>
                            Save
                          </Button>
                        </li>

                      </ul>
                      <ReactTooltip id="infoLeftIcon" place="top" type="info" effect="solid" className={classes.tooltipInfo} />
                    </div>
                  )}
              </div>
            )}
            {filteredModules && filteredModules.length > 0
              && displayItems.folder && (
                <div className={classNames(classes.explorerContent, classes.modulesContent, classes.exploreFive)}>
                  <ReactTooltip id="infoIcon3" place="bottom" type="info" effect="solid" className={classes.tooltipInfo} />
                  <Typography variant="h2" component="h2">
                    Modules
                    {moduleParams.filesHeader === 'Scenarios' && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon3"
                        data-tip="Modules will appear here if the selected folder or career sketch has a scenario in that module. If you are currently working in a module, that module will appear in blue."
                      >
                        <img src={infoIcon} alt="info" />
                      </span>
                    )}
                  </Typography>
                  <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.scrollMenu)}>
                    <ul className={classes.sketchFolders}>
                      {displayItems.folder && filteredModules.map((data) => (
                        <li className={data.moduleName === scenarioId.moduleName ? classes.activeTab : ''} aria-hidden="true" onClick={() => { handleGetScenarios(data); }}>
                          <p>
                            <img src={fileIcon} alt="info" className={data.selected_module !== moduleParams.module ? classes.backgroundBlur : ''} />
                            <span className={data.selected_module !== moduleParams.module ? classes.greyBackground : ''}>{data.moduleName}</span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </Scrollbars>
                </div>
              )}
            {displayItems.modules && filteredModules.length > 0
              && (
                <div className={classNames(classes.explorerContent, classes.scenariosContent)}>
                  <ReactTooltip id="infoIcon3" place="bottom" type="info" effect="solid" className={classes.tooltipInfo} />
                  <Typography variant="h2" component="h2">
                    {moduleParams.filesHeader !== undefined ? moduleParams.filesHeader : <>Scenarios</>}
                    {moduleParams.filesHeader === 'Scenarios' && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon3"
                        data-tip=" Click this icon to see how a scenario name is built and what that scenario name means in the FC. "
                      >
                        <img src={infoIcon} alt="info" />
                      </span>
                    )}
                  </Typography>
                  <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.scrollMenu)}>
                    <ul className={classes.sketchFolders}>
                      {displayItems.modules && scenarioList.length > 0 && scenarioList.map((data) => (
                        <li className={moduleParams.categeory === 'My Saved Notes' ? classNames((data.note_title === filepath.note_title) && classes.activeTab) : classNames((data.name === filepath.name) && classes.activeTab)} aria-hidden="true" onClick={() => { setScId(data.id); setScenarioInput(data.name); setFilePath(data); displayPathItems('scenario'); }}>
                          <p>
                            <img src={fileIcon} alt="info" className={data.selected_module !== moduleParams.module ? classes.backgroundBlur : ''} />
                            <span className={data.selected_module !== moduleParams.module ? classes.greyBackground : ''}>{moduleParams.categeory === 'My Saved Notes' ? data.note_title : data.name}</span>
                          </p>
                        </li>
                      ))}
                      {(scenarioId && scenarioId.selected_module === moduleParams.module && moduleParams.type === 'save')
                        && (
                          <li className={classes.newScenario}>
                            <div><input type="text" placeholder={moduleParams.categeory === 'My Saved Notes' ? 'New Note Name' : 'New Scenario Name'} onFocus={handleFocusScenario} onChange={(e) => { handleScenarioName(e); }} /></div>
                          </li>
                        )}
                      {inputErrorMsg && <p>{inputErrorMsg}</p>}
                      {scenarioFormat !== ''
                        && scenarioId.selected_module === moduleParams.module && (
                          <li className={classes.scenarioFormat}>
                            {scenarioFormat}
                          </li>
                        )}
                    </ul>
                  </Scrollbars>

                  {(scenarioId && scenarioId.selected_module === moduleParams.module && (scenarioFormat || filepath))
                    && (
                      <div className={classes.saveScenarioBtn}>
                        <div className={classes.scenarioSave}>
                          <Button className={classes.loadBtn} onClick={handleSave}>
                            {moduleParams.type === 'load' ? 'LOAD' : 'SAVE'}
                          </Button>
                        </div>
                      </div>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className={classNames(classes.sightFooter, classes.pwiFooter)}>
        <button type="button" className={classNames(classes.footerButton, classes.closeButton)} onClick={() => { setPopupOpen(false); }}>Close</button>
      </div>


    </Typography>
  );
};

PwiSave.propTypes = {
  classes: PropTypes.object.isRequired,
  getSaveData: PropTypes.object.isRequired,
  moduleParams: PropTypes.object.isRequired,
  setLoadScenarioName: PropTypes.func.isRequired,
  loadScenarioName: PropTypes.object,
  setPopupOpen: PropTypes.func.isRequired,
  SetNotesSuccessMsg: PropTypes.func.isRequired,
  setLoadValue: PropTypes.func.isRequired
};

// const enhance = compose(
//   withStyles(styles),
//   withRouter,
// );

export default withStyles(styles)(PwiSave);