import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';

const BalanceStatementPopup = (props) => {
  const {
    classes, openIncomeEarnedDuringHigher, setOpenIncomeEarnedDuringHigher, detailedInfo, currentColumn,
  } = props;

  return (
    <div className={classes.modalBodyData}>
      <Dialog
        open={openIncomeEarnedDuringHigher}
        onClose={() => setOpenIncomeEarnedDuringHigher(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <div>
            <div>
              <div>
                {currentColumn === 'Cash' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}> Cash </td>
                        <td>
                          Year
                          {detailedInfo.Year && detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td className={classes.strongText}>Beginning Cash Balance</td>
                            <td className={classNames(classes.strongText, classes.bottomBorder)}>
                              <span className={classNames(detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] < 0 && classes.labelColor)}>
                                {(detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] !== '' && detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] !== null && detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Beginning Cash Balance']} prefix={detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Beginning Cash Balance'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td className={classes.leftPaddingSpace}>Total Cash from Operating Activities</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] !== '' && detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] !== null && detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities']} prefix={detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Total Cash from Operating Activities'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td className={classes.leftPaddingSpace}>Total Cash from Investing Activities</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] !== '' && detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] !== null && detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities']} prefix={detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Total Cash from Investing Activities'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td className={classes.leftPaddingSpace}>Total Cash from Financing Activities</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] !== '' && detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] !== null && detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities']} prefix={detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Total Cash from Financing Activities'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.incomeBorder}>
                            <td>&nbsp;</td>
                            <td>Ending Cash Surplus / (Deficit)</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] !== '' && detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] !== null && detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Ending Cash Surplus']} prefix={detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Ending Cash Surplus'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.topSingleBorder}>
                            <td>&nbsp;</td>
                            <td className={classes.leftPaddingSpace}>Cash Injection from Investments</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] !== '' && detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] !== null && detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments']} prefix={detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Cash Injection from Short-Term Investments'] + detailedInfo.cashFlowCompleteData['Cash Injection from Long-Term Investments'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.incomeBorder}>
                            <td>&nbsp;</td>
                            <td>Ending Cash Balance after Transfers</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] !== '' && detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] !== null && detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers']} prefix={detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Ending Cash Balance after Transfers'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.topSingleBorder}>
                            <td>&nbsp;</td>
                            <td className={classes.leftPaddingSpace}>Excess Cash Put into Short-Term Investments</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] !== '' && detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] !== null && detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance]} prefix={detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Excess Cash Put into STI'][detailedInfo.module1MaxCashBalance] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {(detailedInfo.condition === 0 || detailedInfo.condition === 1)
                        && (
                          <tr className={classes.incomeBorder}>
                            {/* {console.log('phrudhvi',detailedInfo)} */}
                            <td>&nbsp;</td>
                            <td>Ending Cash Balance</td>
                            <td><span className={classNames(detailedInfo.cashFlowCompleteData['Ending Cash Balance'] < 0 && classes.labelColor)}>
                              {(detailedInfo.cashFlowCompleteData['Ending Cash Balance'] !== '' && detailedInfo.cashFlowCompleteData['Ending Cash Balance'] !== null && detailedInfo.cashFlowCompleteData['Ending Cash Balance'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.cashFlowCompleteData['Ending Cash Balance']} prefix={detailedInfo.cashFlowCompleteData['Ending Cash Balance'] >= 0 ? '$' : '($'} suffix={detailedInfo.cashFlowCompleteData['Ending Cash Balance'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                )}
                {/* Auto Property colum in Assets */}
                {currentColumn === 'Auto Property' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}> Auto Property </td>
                        <td>
                          Year
                          {detailedInfo.Year && detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Car Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] !== null && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value']} prefix={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] >= 0 ? '$' : '($'} suffix={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] < 0 && ')'} />
                              ) : ('')}
                            </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Depreciation =
                              {detailedInfo.depreciation1}
                              {' '}
                              / 10 yrs Useful Life
                            </td>
                            <td><span className={classNames(-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second'].Depreciation < 0 && classes.labelColor)}>
                              {(-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second'].Depreciation !== '' && -detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second'].Depreciation !== null && -detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Beginning Value']} prefix={-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second'].Depreciation >= 0 ? '$' : '($'} suffix={-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second'].Depreciation < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Car Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation Second']['Ending Value'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Car #1 Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value'] !== null && detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value']} prefix={detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Beginning Value'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Depreciation =
                              {detailedInfo.depreciation2}
                              {' '}
                              / 10 yrs Useful Life
                            </td>
                            <td><span className={classNames(-detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation < 0 && classes.labelColor)}>
                              {(-detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation !== '' && -detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation !== null && -detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation} prefix={-detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation'].Depreciation < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Car #1 Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== null && detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value']} prefix={detailedInfo.depreciationScheduleCompleteData['Understanding Depreciation']['Ending Value'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Car #2 Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value'] !== null && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value']} prefix={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Beginning Value'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Depreciation =
                              {detailedInfo.depreciation3}
                              {' '}
                              / 10 yrs Useful Life
                            </td>
                            <td><span className={classNames(-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation < 0 && classes.labelColor)}>
                              {(-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation !== '' && -detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation !== null && -detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation} prefix={-detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation'].Depreciation < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      {detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== 0
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Car #2 Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td><span className={classNames(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] < 0 && classes.labelColor)}>
                              {(detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== '' && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== null && detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value']} prefix={detailedInfo.depreciationScheduleCompleteData['Automobile Straight-Line Depreciation']['Ending Value'] < 0 && ')'} />
                              ) : ('')}
                            </span></td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Total Auto Property</td>
                        <td><span className={classNames(detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] < 0 && classes.labelColor)}>
                          {(detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] !== '' && detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] !== null && detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Assets['Auto Property']} prefix={detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Assets['Auto Property'] < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {/* Real Estate Property column in Assets */}
                {currentColumn === 'Real Estate Property' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Real Estate Property</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Home Value in Year
                              {detailedInfo.startYearVal}
                            </td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <span className={classNames(detailedInfo.existingHomeValueFirstYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingHomeValueFirstYear !== '' && detailedInfo.existingHomeValueFirstYear !== null && detailedInfo.existingHomeValueFirstYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingHomeValueFirstYear} prefix={detailedInfo.existingHomeValueFirstYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingHomeValueFirstYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Home Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.existingHomePreviousYearValue < 0 && classes.labelColor)}>
                                {(detailedInfo.existingHomePreviousYearValue !== '' && detailedInfo.existingHomePreviousYearValue !== null && detailedInfo.existingHomePreviousYearValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingHomePreviousYearValue} prefix={detailedInfo.existingHomePreviousYearValue >= 0 ? '$' : '($'} suffix={detailedInfo.existingHomePreviousYearValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>X</td>
                            <td>Appreciation</td>
                            <td>{detailedInfo.previousYearAppreciation}</td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Home Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.existingHomeCurrentYearValue < 0 && classes.labelColor)}>
                                {(detailedInfo.existingHomeCurrentYearValue !== '' && detailedInfo.existingHomeCurrentYearValue !== null && detailedInfo.existingHomeCurrentYearValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingHomeCurrentYearValue} prefix={detailedInfo.existingHomeCurrentYearValue >= 0 ? '$' : '($'} suffix={detailedInfo.existingHomeCurrentYearValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.startYearValCondition === 1 && detailedInfo.existingHomeCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Home Purchase Price in Year
                              {detailedInfo.purchaseYear}
                            </td>
                            <td>Fit Out Costs</td>
                            <td>Home Value</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>
                              <span className={classNames(detailedInfo.purchasePrice < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasePrice !== '' && detailedInfo.purchasePrice !== null && detailedInfo.purchasePrice !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasePrice} prefix={detailedInfo.purchasePrice >= 0 ? '$' : '($'} suffix={detailedInfo.purchasePrice < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.fitOutCost < 0 && classes.labelColor)}>
                                {(detailedInfo.fitOutCost !== '' && detailedInfo.fitOutCost !== null && detailedInfo.fitOutCost !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fitOutCost} prefix={detailedInfo.fitOutCost >= 0 ? '$' : '($'} suffix={detailedInfo.fitOutCost < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.homeValue < 0 && classes.labelColor)}>
                                {(detailedInfo.homeValue !== '' && detailedInfo.homeValue !== null && detailedInfo.homeValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.homeValue} prefix={detailedInfo.homeValue >= 0 ? '$' : '($'} suffix={detailedInfo.homeValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1 && detailedInfo.previousHomeValueCondition === 1
                        && (
                          <tr>
                            <td>
                              Home Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] < 0 && classes.labelColor)}>
                                {(detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] !== '' && detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] !== null && detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value']} prefix={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] >= 0 ? '$' : '($'} suffix={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year - 1]['Asset Value'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1 && detailedInfo.previousHomeValueCondition === 1
                        && (
                          <tr>
                            <td>x</td>
                            <td>Appreciation</td>
                            <td>{detailedInfo.appreciation}</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Home Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value'] < 0 && classes.labelColor)}>
                                {(detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value'] !== null && detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value']} prefix={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value'] >= 0 ? '$' : '($'} suffix={detailedInfo.buyingAHomeCompleteData[detailedInfo.Year]['Asset Value'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1 && detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              REI Property Purchase Price in Year
                              {detailedInfo.purchaseYear1}
                            </td>
                            <td>Fit Out Costs</td>
                            <td>REI Property Value</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>
                              <span className={classNames(detailedInfo.purchasePrice1 < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasePrice1 !== '' && detailedInfo.purchasePrice1 !== null && detailedInfo.purchasePrice1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasePrice1} prefix={detailedInfo.purchasePrice1 >= 0 ? '$' : '($'} suffix={detailedInfo.purchasePrice1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.fitOutCost1 < 0 && classes.labelColor)}>
                                {(detailedInfo.fitOutCost1 !== '' && detailedInfo.fitOutCost1 !== null && detailedInfo.fitOutCost1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fitOutCost1} prefix={detailedInfo.fitOutCost1 >= 0 ? '$' : '($'} suffix={detailedInfo.fitOutCost1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.homeValue1 < 0 && classes.labelColor)}>
                                {(detailedInfo.homeValue1 !== '' && detailedInfo.homeValue1 !== null && detailedInfo.homeValue1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.homeValue1} prefix={detailedInfo.homeValue1 >= 0 ? '$' : '($'} suffix={detailedInfo.homeValue1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1 && detailedInfo.previousRealEstateYearValueCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              REI Property Value in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value'] < 0 && classes.labelColor)}>
                                {(detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value'] !== null && detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value']} prefix={detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value'] >= 0 ? '$' : '($'} suffix={detailedInfo.reiCompleteData[detailedInfo.Year - 1]['Asset Value'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1 && detailedInfo.previousRealEstateYearValueCondition === 1
                        && (
                          <tr>
                            <td>X</td>
                            <td>Appreciation</td>
                            <td>{detailedInfo.appreciation1}</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              REI Property Value in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value'] < 0 && classes.labelColor)}>
                                {(detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value'] !== null && detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value']} prefix={detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value'] >= 0 ? '$' : '($'} suffix={detailedInfo.reiCompleteData[detailedInfo.Year]['Asset Value'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Total Real Estate Property</td>
                        <td>
                          <span className={classNames(detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property'] < 0 && classes.labelColor)}>
                            {(detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property'] !== null && detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property'] !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property']} prefix={detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Assets['Real Estate Property'] < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                )}
                {/* REI Property Accumulated Depreciation in Assets */}
                {currentColumn === 'REI Property Accumulated Depreciation' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}>REI Property Depreciation</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      {detailedInfo.beginningDepreciationBalanceCurrentCondition === 1
                        && (
                          <tr>
                            <td>
                              Beginning Depreciation Balance in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.beginningDepreciationBalanceVal < 0 && classes.labelColor)}>
                                {(detailedInfo.beginningDepreciationBalanceVal !== '' && detailedInfo.beginningDepreciationBalanceVal !== null && detailedInfo.beginningDepreciationBalanceVal !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.beginningDepreciationBalanceVal} prefix={detailedInfo.beginningDepreciationBalanceVal >= 0 ? '$' : '($'} suffix={detailedInfo.beginningDepreciationBalanceVal < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.beginningDepreciationBalanceCurrentCondition === 1
                        && (
                          <tr>
                            <td>Depreciation</td>
                            <td>
                              <span className={classNames(detailedInfo.reiDepreciationValue < 0 && classes.labelColor)}>
                                {(detailedInfo.reiDepreciationValue !== '' && detailedInfo.reiDepreciationValue !== null && detailedInfo.reiDepreciationValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.beginningDepreciationBalanceVal} prefix={detailedInfo.reiDepreciationValue >= 0 ? '$' : '($'} suffix={detailedInfo.reiDepreciationValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr>
                        <td>=</td>
                        <td className={classes.strongText}>
                          Depreciation Balance at end of Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classNames(classes.strongText, classes.textRight)}>
                          <span className={classNames(detailedInfo.beginningDepreciationBalanceCurrentVal < 0 && classes.labelColor)}>
                            {(detailedInfo.beginningDepreciationBalanceCurrentVal !== '' && detailedInfo.beginningDepreciationBalanceCurrentVal !== null && detailedInfo.beginningDepreciationBalanceCurrentVal !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.beginningDepreciationBalanceCurrentVal} prefix={detailedInfo.beginningDepreciationBalanceCurrentVal >= 0 ? '$' : '($'} suffix={detailedInfo.beginningDepreciationBalanceCurrentVal < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                {/* Investments section */}
                {currentColumn === 'for401k' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>401(k)</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr><td>&nbsp;</td></tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td className={classes.strongText}>
                          Beginning 401(k) Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classNames(classes.bottomBorder, classes.strongText)}><span className={classNames(detailedInfo.totalBeginningBal401k < 0 && classes.labelColor)}>
                          {(detailedInfo.totalBeginningBal401k !== '' && detailedInfo.totalBeginningBal401k !== null && detailedInfo.totalBeginningBal401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.totalBeginningBal401k} prefix={detailedInfo.totalBeginningBal401k >= 0 ? '$' : '($'} suffix={detailedInfo.totalBeginningBal401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Contribution</td>
                        <td><span className={classNames(detailedInfo.personalContribution401k < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContribution401k !== '' && detailedInfo.personalContribution401k !== null && detailedInfo.personalContribution401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContribution401k} prefix={detailedInfo.personalContribution401k >= 0 ? '$' : '($'} suffix={detailedInfo.personalContribution401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Earnings</td>
                        <td><span className={classNames(detailedInfo.personalEarnings401k < 0 && classes.labelColor)}>
                          {(detailedInfo.personalEarnings401k !== '' && detailedInfo.personalEarnings401k !== null && detailedInfo.personalEarnings401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalEarnings401k} prefix={detailedInfo.personalEarnings401k >= 0 ? '$' : '($'} suffix={detailedInfo.personalEarnings401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Employer Contribution</td>
                        <td><span className={classNames(detailedInfo.employerContribution401k < 0 && classes.labelColor)}>
                          {(detailedInfo.employerContribution401k !== '' && detailedInfo.employerContribution401k !== null && detailedInfo.employerContribution401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.employerContribution401k} prefix={detailedInfo.employerContribution401k >= 0 ? '$' : '($'} suffix={detailedInfo.employerContribution401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Employer Earnings</td>
                        <td><span className={classNames(detailedInfo.employerEarnings401k < 0 && classes.labelColor)}>
                          {(detailedInfo.employerEarnings401k !== '' && detailedInfo.employerEarnings401k !== null && detailedInfo.employerEarnings401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.employerEarnings401k} prefix={detailedInfo.employerEarnings401k >= 0 ? '$' : '($'} suffix={detailedInfo.employerEarnings401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=/+</td>
                        <td>
                          {' '}
                          {detailedInfo.Year}
                          {' '}
                          Total Annual Contribution + Earnings
                        </td>
                        <td><span className={classNames(detailedInfo.personalContribution401k < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContribution401k !== '' && detailedInfo.personalContribution401k !== null && detailedInfo.personalContribution401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContribution401k} prefix={detailedInfo.personalContribution401k >= 0 ? '$' : '($'} suffix={detailedInfo.personalContribution401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>Employer Vesting Reductions</td>
                        <td><span className={classNames(detailedInfo.vestingReductions401k < 0 && classes.labelColor)}>
                          {(detailedInfo.vestingReductions401k !== '' && detailedInfo.vestingReductions401k !== null && detailedInfo.vestingReductions401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.vestingReductions401k} prefix={detailedInfo.vestingReductions401k >= 0 ? '$' : '($'} suffix={detailedInfo.vestingReductions401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>
                          Ending 401(k) Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td><span className={classNames(detailedInfo.balanceSheetCompleteData.Investments.for401k < 0 && classes.labelColor)}>
                          {(detailedInfo.balanceSheetCompleteData.Investments.for401k !== '' && detailedInfo.balanceSheetCompleteData.Investments.for401k !== null && detailedInfo.balanceSheetCompleteData.Investments.for401k !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Investments.for401k} prefix={detailedInfo.balanceSheetCompleteData.Investments.for401k >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Investments.for401k < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Roth IRA' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Roth IRA</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td className={classes.strongText}>
                          Beginning Roth IRA Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classNames(classes.strongText, classes.bottomBorder)}><span className={classNames(detailedInfo.totalBeginningBalRothIra < 0 && classes.labelColor)}>
                          {(detailedInfo.totalBeginningBalRothIra !== '' && detailedInfo.totalBeginningBalRothIra !== null && detailedInfo.totalBeginningBalRothIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.totalBeginningBalRothIra} prefix={detailedInfo.totalBeginningBalRothIra >= 0 ? '$' : '($'} suffix={detailedInfo.totalBeginningBalRothIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Contribution</td>
                        <td><span className={classNames(detailedInfo.personalContributionRothIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionRothIra !== '' && detailedInfo.personalContributionRothIra !== null && detailedInfo.personalContributionRothIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionRothIra} prefix={detailedInfo.personalContributionRothIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionRothIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Earnings</td>
                        <td><span className={classNames(detailedInfo.personalEarningsRothIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalEarningsRothIra !== '' && detailedInfo.personalEarningsRothIra !== null && detailedInfo.personalEarningsRothIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalEarningsRothIra} prefix={detailedInfo.personalEarningsRothIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalEarningsRothIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=/+</td>
                        <td>
                          {detailedInfo.Year}
                          {' '}
                          Total Annual Contribution + Earnings
                        </td>
                        <td><span className={classNames(detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra !== '' && detailedInfo.personalContributionRothIra + detailedInfo.personalContributionRothIra + detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra !== null && detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra} prefix={detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionRothIra + detailedInfo.personalEarningsRothIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>
                          Ending Roth IRA Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td><span className={classNames(detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] < 0 && classes.labelColor)}>
                          {(detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] !== '' && detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] !== null && detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Investments['Roth IRA']} prefix={detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Investments['Roth IRA'] < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Seph IRA' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>SEP IRA</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td className={classes.strongText}>
                          {' '}
                          Beginning SEP IRA Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classNames(classes.strongText, classes.bottomBorder)}><span className={classNames(detailedInfo.totalBeginningBalSephIra < 0 && classes.labelColor)}>
                          {(detailedInfo.totalBeginningBalSephIra !== '' && detailedInfo.totalBeginningBalSephIra !== null && detailedInfo.totalBeginningBalSephIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.totalBeginningBalSephIra} prefix={detailedInfo.totalBeginningBalSephIra >= 0 ? '$' : '($'} suffix={detailedInfo.totalBeginningBalSephIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Contribution</td>
                        <td><span className={classNames(detailedInfo.personalContributionSephIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionSephIra !== '' && detailedInfo.personalContributionSephIra !== null && detailedInfo.personalContributionSephIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionSephIra} prefix={detailedInfo.personalContributionSephIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionSephIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td>Personal Earnings</td>
                        <td><span className={classNames(detailedInfo.personalEarningsSephIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalEarningsSephIra !== '' && detailedInfo.personalEarningsSephIra !== null && detailedInfo.personalEarningsSephIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalEarningsSephIra} prefix={detailedInfo.personalEarningsSephIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalEarningsSephIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=/+</td>
                        <td>
                          {' '}
                          {detailedInfo.Year}
                          {' '}
                          Total Annual Contribution + Earnings
                          {' '}
                        </td>
                        <td><span className={classNames(detailedInfo.personalContributionSephIra < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionSephIra !== '' && detailedInfo.personalContributionSephIra !== null && detailedInfo.personalContributionSephIra !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionSephIra} prefix={detailedInfo.personalContributionSephIra >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionSephIra < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>
                          Ending SEP IRA Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td><span className={classNames(detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] < 0 && classes.labelColor)}>
                          {(detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] !== '' && detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] !== null && detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Investments['Seph IRA']} prefix={detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Investments['Seph IRA'] < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Pension' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Pension</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td className={classes.strongText}>
                          {' '}
                          Beginning Pension Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classNames(classes.strongText, classes.bottomBorder)}><span className={classNames(detailedInfo.totalBeginningBalPension < 0 && classes.labelColor)}>
                          {(detailedInfo.totalBeginningBalPension !== '' && detailedInfo.totalBeginningBalPension !== null && detailedInfo.totalBeginningBalPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.totalBeginningBalPension} prefix={detailedInfo.totalBeginningBalPension >= 0 ? '$' : '($'} suffix={detailedInfo.totalBeginningBalPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td colSpan="3">&nbsp;</td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td> Personal Contribution </td>
                        <td><span className={classNames(detailedInfo.personalContributionPension < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionPension !== '' && detailedInfo.personalContributionPension !== null && detailedInfo.personalContributionPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionPension} prefix={detailedInfo.personalContributionPension >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td> Employer Contribution </td>
                        <td><span className={classNames(detailedInfo.employerContributionPension < 0 && classes.labelColor)}>
                          {(detailedInfo.employerContributionPension !== '' && detailedInfo.employerContributionPension !== null && detailedInfo.employerContributionPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.employerContributionPension} prefix={detailedInfo.employerContributionPension >= 0 ? '$' : '($'} suffix={detailedInfo.employerContributionPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr>
                        <td>+</td>
                        <td> Earnings </td>
                        <td><span className={classNames(detailedInfo.personalEarningsPension < 0 && classes.labelColor)}>
                          {(detailedInfo.personalEarningsPension !== '' && detailedInfo.personalEarningsPension !== null && detailedInfo.personalEarningsPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalEarningsPension} prefix={detailedInfo.personalEarningsPension >= 0 ? '$' : '($'} suffix={detailedInfo.personalEarningsPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=/+</td>
                        <td>
                          {' '}
                          {detailedInfo.Year}
                          {' '}
                          Total Annual Contribution + Earnings
                          {' '}
                        </td>
                        <td><span className={classNames(detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension < 0 && classes.labelColor)}>
                          {(detailedInfo.personalContributionPension + detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension + detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension !== '' && detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension !== null && detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension} prefix={detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension >= 0 ? '$' : '($'} suffix={detailedInfo.personalContributionPension + detailedInfo.employerContributionPension + detailedInfo.personalEarningsPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>
                          {' '}
                          Ending Pension Balance in Year
                          {''}
                          {detailedInfo.Year}
                        </td>
                        <td><span className={classNames(detailedInfo.personalEarningsPension < 0 && classes.labelColor)}>
                          {(detailedInfo.personalEarningsPension !== '' && detailedInfo.personalEarningsPension !== null && detailedInfo.personalEarningsPension !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.personalEarningsPension + detailedInfo.totalBeginningBalPension} prefix={detailedInfo.personalEarningsPension >= 0 ? '$' : '($'} suffix={detailedInfo.personalEarningsPension < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Short-Term Investments' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Short-Term Investments Balance</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td colspan="3" className={classNames(classes.strongText, classes.textCenter)}>
                              Short-Term Investments Begin in Year
                              {detailedInfo.BeginYear}
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.incomeBorderNew}>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td className={classes.strongText}>
                              Short-Term Investments Balance in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td className={classNames(classes.strongText, classes.textRight, classes.bottomBorder)}>
                              <span className={classNames(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance'] < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance'] !== null && detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance']} prefix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance'] >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Beg Balance'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Annual Cash Contribution</td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution'] < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution'] !== null && detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution']} prefix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution'] >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Contibution'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Annual Earnings</td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings !== null && detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings} prefix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Earnings < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Transfer to Cash Account</td>
                            <td className={classes.textRight}>
                              <span className={classNames(-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer'] < 0 && classes.labelColor)}>
                                {(-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer'] !== null && -detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer']} prefix={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer'] >= 0 ? '$' : '($'} suffix={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year]['Cash Transfer'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Contribution from Excess Cash</td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution !== null && detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution} prefix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Contribution < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.borderTopBottom}>
                            <td className={classes.strongText}>=</td>
                            <td className={classes.strongText}>Short-Term Investments Balance before Long-Term Investing</td>
                            <td className={classNames(classes.strongText, classes.textRight)}>
                              <span className={classNames(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4 < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4 !== null && detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4} prefix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4 >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Balance4 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td><em>Max Short-Term Investments Balance</em></td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.MaxValue < 0 && classes.labelColor)}>
                                {(detailedInfo.MaxValue !== null && detailedInfo.MaxValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.MaxValue} prefix={detailedInfo.MaxValue >= 0 ? '$' : '($'} suffix={detailedInfo.MaxValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Excess Short-Term Investments Put into Long-Term Investment</td>
                            <td>
                              <span className={classNames(-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess < 0 && classes.labelColor)}>
                                {(-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess !== null && -detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess} prefix={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess >= 0 ? '$' : '($'} suffix={-detailedInfo.investmentData['Short-Term Investments'][detailedInfo.Year].Excess < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td className={classes.strongText}>=</td>
                        <td>
                          Short-Term Investments Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td>
                          <span className={classNames(detailedInfo.finalEndingBal < 0 && classes.labelColor)}>
                            {(detailedInfo.finalEndingBal !== null && detailedInfo.finalEndingBal !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.finalEndingBal} prefix={detailedInfo.finalEndingBal >= 0 ? '$' : '($'} suffix={detailedInfo.finalEndingBal < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Long-Term Investments' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Long-Term Investments</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>
                              Long-Term Investments Begin in Year
                              {detailedInfo.BeginYear}
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr className={classes.incomeBorder}>
                            <td>&nbsp;</td>
                            <td>
                              Long-Term Investments Balance in Year
                              {detailedInfo.Year - 1}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance'] < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance'] !== null && detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance']} prefix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance'] >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Beg Balance'] < 0 && ')'} />
                                ) : ('0')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Annual Cash Contribution</td>
                            <td>
                              <span className={classNames(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution'] < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution'] !== null && detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution']} prefix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution'] >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Contibution'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Annual Earnings</td>
                            <td>
                              <span className={classNames(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings !== null && detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings} prefix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Earnings < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Transfer to Cash Account</td>
                            <td>
                              <span className={classNames(-detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer'] < 0 && classes.labelColor)}>
                                {(-detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer'] !== null && -detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer']} prefix={-detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer'] >= 0 ? '$' : '($'} suffix={-detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year]['Cash Transfer'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Contribution from Excess Short-Term Investments</td>
                            <td>
                              <span className={classNames(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution < 0 && classes.labelColor)}>
                                {(detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution !== null && detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution} prefix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution >= 0 ? '$' : '($'} suffix={detailedInfo.investmentData['Long-Term Investments'][detailedInfo.Year].Contribution < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>
                          Long-Term Investments Balance in Year
                          {detailedInfo.Year}
                        </td>
                        <td>
                          <span className={classNames(detailedInfo.finalEndingLongTermBal < 0 && classes.labelColor)}>
                            {(detailedInfo.finalEndingLongTermBal !== null && detailedInfo.finalEndingLongTermBal !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.finalEndingLongTermBal} prefix={detailedInfo.finalEndingLongTermBal >= 0 ? '$' : '($'} suffix={detailedInfo.finalEndingLongTermBal < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                {/* Liabilities */}
                {currentColumn === 'Existing Credit Card Debt' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Existing Credit Card Debt</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.endingBalanceValueCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Beginning Balance</td>
                            <span className={classNames(detailedInfo.beginningBalanceValue < 0 && classes.labelColor)}>
                              {(detailedInfo.beginningBalanceValue !== '' && detailedInfo.beginningBalanceValue !== null && detailedInfo.beginningBalanceValue !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.beginningBalanceValue} prefix={detailedInfo.beginningBalanceValue >= 0 ? '$' : '($'} suffix={detailedInfo.beginningBalanceValue < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.endingBalanceValueCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.principalPaybackValue < 0 && classes.labelColor)}>
                              {(detailedInfo.principalPaybackValue !== '' && detailedInfo.principalPaybackValue !== null && detailedInfo.principalPaybackValue !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.principalPaybackValue} prefix={detailedInfo.principalPaybackValue >= 0 ? '$' : '($'} suffix={detailedInfo.principalPaybackValue < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      <tr>
                        <td>=</td>
                        <td className={classes.strongText}>
                          Existing Credit Card Debt at end of Year
                          {detailedInfo.Year}
                        </td>
                        <td className={classes.strongText}><span className={classNames(detailedInfo.endingBalanceValue < 0 && classes.labelColor)}>
                          {(detailedInfo.endingBalanceValue !== '' && detailedInfo.endingBalanceValue !== null && detailedInfo.endingBalanceValue !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.endingBalanceValue} prefix={detailedInfo.endingBalanceValue >= 0 ? '$' : '($'} suffix={detailedInfo.endingBalanceValue < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Student Loans' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}>Student Loans</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {/* Existing Fed Subsidized Student Loan Balance */}
                      {detailedInfo.existingFedSubStudentLoanBalPreviousYearCondition === 1
                        && (
                          <tr>
                            <td>=</td>
                            <td>
                              Existing Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingFedSubStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingFedSubStudentLoanBalPreviousYear !== '' && detailedInfo.existingFedSubStudentLoanBalPreviousYear !== null && detailedInfo.existingFedSubStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedSubStudentLoanBalPreviousYear} prefix={detailedInfo.existingFedSubStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedSubStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingFedSubLoansTakenCondition === 1 && detailedInfo.existingFedSubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.existingFedSubLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.existingFedSubLoansTaken !== '' && detailedInfo.existingFedSubLoansTaken !== null && detailedInfo.existingFedSubLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedSubLoansTaken} prefix={detailedInfo.existingFedSubLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedSubLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingFedSubLoansTakenCondition === 1 && detailedInfo.existingFedSubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.existingFedSubPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.existingFedSubPrincipalPayback !== '' && detailedInfo.existingFedSubPrincipalPayback !== null && detailedInfo.existingFedSubPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedSubPrincipalPayback} prefix={detailedInfo.existingFedSubPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedSubPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.existingFedSubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingFedSubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingFedSubStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingFedSubStudentLoanBalCurrentYear !== '' && detailedInfo.existingFedSubStudentLoanBalCurrentYear !== null && detailedInfo.existingFedSubStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedSubStudentLoanBalCurrentYear} prefix={detailedInfo.existingFedSubStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedSubStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingFedUnsubStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingFedUnsubStudentLoanBalPreviousYear !== '' && detailedInfo.existingFedUnsubStudentLoanBalPreviousYear !== null && detailedInfo.existingFedUnsubStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedUnsubStudentLoanBalPreviousYear} prefix={detailedInfo.existingFedUnsubStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedUnsubStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingFedUnsubLoansTakenCondition === 1 && detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.existingFedUnsubLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.existingFedUnsubLoansTaken !== '' && detailedInfo.existingFedUnsubLoansTaken !== null && detailedInfo.existingFedUnsubLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedUnsubLoansTaken} prefix={detailedInfo.existingFedUnsubLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedUnsubLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingFedUnsubAccruedInterestCondition === 1 && detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.existingFedUnsubAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.existingFedUnsubAccruedInterest !== '' && detailedInfo.existingFedUnsubAccruedInterest !== null && detailedInfo.existingFedUnsubAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedUnsubAccruedInterest} prefix={detailedInfo.existingFedUnsubAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedUnsubAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingFedUnsubPrincipalPaybackCondition === 1 && (detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.existingFedUnsubPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.existingFedUnsubPrincipalPayback !== '' && detailedInfo.existingFedUnsubPrincipalPayback !== null && detailedInfo.existingFedUnsubPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedUnsubPrincipalPayback} prefix={detailedInfo.existingFedUnsubPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedUnsubPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingFedUnsubStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingFedUnsubStudentLoanBalCurrentYear !== '' && detailedInfo.existingFedUnsubStudentLoanBalCurrentYear !== null && detailedInfo.existingFedUnsubStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingFedUnsubStudentLoanBalCurrentYear} prefix={detailedInfo.existingFedUnsubStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingFedUnsubStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {(detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingPrivateStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingPrivateStudentLoanBalPreviousYear !== '' && detailedInfo.existingPrivateStudentLoanBalPreviousYear !== null && detailedInfo.existingPrivateStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingPrivateStudentLoanBalPreviousYear} prefix={detailedInfo.existingPrivateStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingPrivateStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingPrivateLoansTakenCondition === 1 && detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.existingPrivateLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.existingPrivateLoansTaken !== '' && detailedInfo.existingPrivateLoansTaken !== null && detailedInfo.existingPrivateLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingPrivateLoansTaken} prefix={detailedInfo.existingPrivateLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.existingPrivateLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingPrivateAccruedInterestCondition === 1 && detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.existingPrivateAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.existingPrivateAccruedInterest !== '' && detailedInfo.existingPrivateAccruedInterest !== null && detailedInfo.existingPrivateAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingPrivateAccruedInterest} prefix={detailedInfo.existingPrivateAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.existingPrivateAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingPrivatePrincipalPaybackCondition === 1 && (detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingPrivateStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.existingPrivatePrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.existingPrivatePrincipalPayback !== '' && detailedInfo.existingPrivatePrincipalPayback !== null && detailedInfo.existingPrivatePrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingPrivatePrincipalPayback} prefix={detailedInfo.existingPrivatePrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.existingPrivatePrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.existingPrivatePrincipalPaybackCondition === 1 && (detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition === 1 || detailedInfo.existingPrivateStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.existingPrivateStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.existingPrivateStudentLoanBalCurrentYear !== '' && detailedInfo.existingPrivateStudentLoanBalCurrentYear !== null && detailedInfo.existingPrivateStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingPrivateStudentLoanBalCurrentYear} prefix={detailedInfo.existingPrivateStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.existingPrivateStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Fed Subsidized Higher Education Student Loan Balance */}
                      {(detailedInfo.fedSubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubStudentLoanBalPreviousYear !== '' && detailedInfo.fedSubStudentLoanBalPreviousYear !== null && detailedInfo.fedSubStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubStudentLoanBalPreviousYear} prefix={detailedInfo.fedSubStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedSubLoansTakenCondition === 1 && detailedInfo.fedSubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.fedSubLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubLoansTaken !== '' && detailedInfo.fedSubLoansTaken !== null && detailedInfo.fedSubLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubLoansTaken} prefix={detailedInfo.fedSubLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedSubPrincipalPaybackCondition === 1 && (detailedInfo.fedSubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedSubPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubPrincipalPayback !== '' && detailedInfo.fedSubPrincipalPayback !== null && detailedInfo.fedSubPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubPrincipalPayback} prefix={detailedInfo.fedSubPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedSubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubStudentLoanBalCurrentYear !== '' && detailedInfo.fedSubStudentLoanBalCurrentYear !== null && detailedInfo.fedSubStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubStudentLoanBalCurrentYear} prefix={detailedInfo.fedSubStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Fed Unsubsidized Higher Education Student Loan Balance */}
                      {(detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubStudentLoanBalPreviousYear !== '' && detailedInfo.fedUnsubStudentLoanBalPreviousYear !== null && detailedInfo.fedUnsubStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubStudentLoanBalPreviousYear} prefix={detailedInfo.fedUnsubStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubLoansTakenCondition === 1 && detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubLoansTaken < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubLoansTaken !== '' && detailedInfo.fedUnsubLoansTaken !== null && detailedInfo.fedUnsubLoansTaken !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubLoansTaken} prefix={detailedInfo.fedUnsubLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubLoansTaken < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubAccruedInterestCondition === 1 && detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubAccruedInterest < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubAccruedInterest !== '' && detailedInfo.fedUnsubAccruedInterest !== null && detailedInfo.fedUnsubAccruedInterest !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubAccruedInterest} prefix={detailedInfo.fedUnsubAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubAccruedInterest < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubPrincipalPaybackCondition === 1 && (detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedUnsubPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubPrincipalPayback !== '' && detailedInfo.fedUnsubPrincipalPayback !== null && detailedInfo.fedUnsubPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubPrincipalPayback} prefix={detailedInfo.fedUnsubPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubStudentLoanBalCurrentYear !== '' && detailedInfo.fedUnsubStudentLoanBalCurrentYear !== null && detailedInfo.fedUnsubStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubStudentLoanBalCurrentYear} prefix={detailedInfo.fedUnsubStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Private Higher Education Student Loan Balance */}
                      {(detailedInfo.privateStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateStudentLoanBalPreviousYear !== '' && detailedInfo.privateStudentLoanBalPreviousYear !== null && detailedInfo.privateStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateStudentLoanBalPreviousYear} prefix={detailedInfo.privateStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.privateLoansTakenCondition === 1 && detailedInfo.privateStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.privateLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.privateLoansTaken !== '' && detailedInfo.privateLoansTaken !== null && detailedInfo.privateLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateLoansTaken} prefix={detailedInfo.privateLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.privateLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privateAccruedInterestCondition === 1 && detailedInfo.privateStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.privateAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.privateAccruedInterest !== '' && detailedInfo.privateAccruedInterest !== null && detailedInfo.privateAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateAccruedInterest} prefix={detailedInfo.privateAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.privateAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privatePrincipalPaybackCondition === 1 && (detailedInfo.privateStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.privatePrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.privatePrincipalPayback !== '' && detailedInfo.privatePrincipalPayback !== null && detailedInfo.privatePrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privatePrincipalPayback} prefix={detailedInfo.privatePrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.privatePrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.privateStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateStudentLoanBalCurrentYear !== '' && detailedInfo.privateStudentLoanBalCurrentYear !== null && detailedInfo.privateStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateStudentLoanBalCurrentYear} prefix={detailedInfo.privateStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Fed Subsidized More Higher Education Student Loan Balance */}
                      {(detailedInfo.fedSubMoreStudentLoanBalPreviousYearCondition === 1 || detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              More Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubMoreStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubMoreStudentLoanBalPreviousYear !== '' && detailedInfo.fedSubMoreStudentLoanBalPreviousYear !== null && detailedInfo.fedSubMoreStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubMoreStudentLoanBalPreviousYear} prefix={detailedInfo.fedSubMoreStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubMoreStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedSubMoreLoansTakenCondition === 1 && detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.fedSubMoreLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubMoreLoansTaken !== '' && detailedInfo.fedSubMoreLoansTaken !== null && detailedInfo.fedSubMoreLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubMoreLoansTaken} prefix={detailedInfo.fedSubMoreLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubMoreLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedSubMorePrincipalPaybackCondition === 1 && (detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedSubMorePrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubMorePrincipalPayback !== '' && detailedInfo.fedSubMorePrincipalPayback !== null && detailedInfo.fedSubMorePrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubMorePrincipalPayback} prefix={detailedInfo.fedSubMorePrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubMorePrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              More Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubMoreStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubMoreStudentLoanBalCurrentYear !== '' && detailedInfo.fedSubMoreStudentLoanBalCurrentYear !== null && detailedInfo.fedSubMoreStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubMoreStudentLoanBalCurrentYear} prefix={detailedInfo.fedSubMoreStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubMoreStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Fed Unsubsidized More Higher Education Student Loan Balance */}
                      {(detailedInfo.fedUnsubMoreStudentLoanBalPreviousYearCondition === 1 || detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              More Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear !== '' && detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear !== null && detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear} prefix={detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubMoreLoansTakenCondition === 1 && detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.fedUnsubMoreLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubMoreLoansTaken !== '' && detailedInfo.fedUnsubMoreLoansTaken !== null && detailedInfo.fedUnsubMoreLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubMoreLoansTaken} prefix={detailedInfo.fedUnsubMoreLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubMoreLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubMoreAccruedInterestCondition === 1 && detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.fedUnsubMoreAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubMoreAccruedInterest !== '' && detailedInfo.fedUnsubMoreAccruedInterest !== null && detailedInfo.fedUnsubMoreAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubMoreAccruedInterest} prefix={detailedInfo.fedUnsubMoreAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubMoreAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubMorePrincipalPaybackCondition === 1 && (detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedUnsubMorePrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubMorePrincipalPayback !== '' && detailedInfo.fedUnsubMorePrincipalPayback !== null && detailedInfo.fedUnsubMorePrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubMorePrincipalPayback} prefix={detailedInfo.fedUnsubMorePrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubMorePrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              More Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear !== '' && detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear !== null && detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear} prefix={detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Private More Higher Education Student Loan Balance */}
                      {(detailedInfo.privateMoreStudentLoanBalPreviousYearCondition === 1 || detailedInfo.privateMoreStudentLoanBalCurrentYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              More Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateMoreStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateMoreStudentLoanBalPreviousYear !== '' && detailedInfo.privateMoreStudentLoanBalPreviousYear !== null && detailedInfo.privateMoreStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateMoreStudentLoanBalPreviousYear} prefix={detailedInfo.privateMoreStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateMoreStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.privateMoreLoansTakenCondition === 1 && detailedInfo.privateMoreStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.privateMoreLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.privateMoreLoansTaken !== '' && detailedInfo.privateMoreLoansTaken !== null && detailedInfo.privateMoreLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateMoreLoansTaken} prefix={detailedInfo.privateMoreLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.privateMoreLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privateMoreAccruedInterestCondition === 1 && detailedInfo.privateMoreStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.privateMoreAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.privateMoreAccruedInterest !== '' && detailedInfo.privateMoreAccruedInterest !== null && detailedInfo.privateMoreAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateMoreAccruedInterest} prefix={detailedInfo.privateMoreAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.privateMoreAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privateMorePrincipalPaybackCondition === 1 && (detailedInfo.privateMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.privateMorePrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.privateMorePrincipalPayback !== '' && detailedInfo.privateMorePrincipalPayback !== null && detailedInfo.privateMorePrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateMorePrincipalPayback} prefix={detailedInfo.privateMorePrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.privateMorePrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.privateMoreStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateMoreStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              More Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateMoreStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateMoreStudentLoanBalCurrentYear !== '' && detailedInfo.privateMoreStudentLoanBalCurrentYear !== null && detailedInfo.privateMoreStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateMoreStudentLoanBalCurrentYear} prefix={detailedInfo.privateMoreStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateMoreStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {(detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Further Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubNewStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubNewStudentLoanBalPreviousYear !== '' && detailedInfo.fedSubNewStudentLoanBalPreviousYear !== null && detailedInfo.fedSubNewStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubNewStudentLoanBalPreviousYear} prefix={detailedInfo.fedSubNewStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubNewStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedSubNewLoansTakenCondition === 1 && detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.fedSubNewLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubNewLoansTaken !== '' && detailedInfo.fedSubNewLoansTaken !== null && detailedInfo.fedSubNewLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubNewLoansTaken} prefix={detailedInfo.fedSubNewLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubNewLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedSubNewPrincipalPaybackCondition === 1 && (detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedSubNewPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedSubNewPrincipalPayback !== '' && detailedInfo.fedSubNewPrincipalPayback !== null && detailedInfo.fedSubNewPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubNewPrincipalPayback} prefix={detailedInfo.fedSubNewPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubNewPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedSubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Further Higher Education Federal Subsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedSubNewStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedSubNewStudentLoanBalCurrentYear !== '' && detailedInfo.fedSubNewStudentLoanBalCurrentYear !== null && detailedInfo.fedSubNewStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedSubNewStudentLoanBalCurrentYear} prefix={detailedInfo.fedSubNewStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedSubNewStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Fed Unsubsidized More Higher Education Student Loan Balance */}
                      {(detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Further Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubNewStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubNewStudentLoanBalPreviousYear !== '' && detailedInfo.fedUnsubNewStudentLoanBalPreviousYear !== null && detailedInfo.fedUnsubNewStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubNewStudentLoanBalPreviousYear} prefix={detailedInfo.fedUnsubNewStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubNewStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubNewLoansTakenCondition === 1 && detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.fedUnsubNewLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubNewLoansTaken !== '' && detailedInfo.fedUnsubNewLoansTaken !== null && detailedInfo.fedUnsubNewLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubNewLoansTaken} prefix={detailedInfo.fedUnsubNewLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubNewLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubNewAccruedInterestCondition === 1 && detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.fedUnsubNewAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubNewAccruedInterest !== '' && detailedInfo.fedUnsubNewAccruedInterest !== null && detailedInfo.fedUnsubNewAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubNewAccruedInterest} prefix={detailedInfo.fedUnsubNewAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubNewAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.fedUnsubNewPrincipalPaybackCondition === 1 && (detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.fedUnsubNewPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.fedUnsubNewPrincipalPayback !== '' && detailedInfo.fedUnsubNewPrincipalPayback !== null && detailedInfo.fedUnsubNewPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubNewPrincipalPayback} prefix={detailedInfo.fedUnsubNewPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubNewPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.fedUnsubNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Further Higher Education Federal Unsubsidized Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.fedUnsubNewStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.fedUnsubNewStudentLoanBalCurrentYear !== '' && detailedInfo.fedUnsubNewStudentLoanBalCurrentYear !== null && detailedInfo.fedUnsubNewStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.fedUnsubNewStudentLoanBalCurrentYear} prefix={detailedInfo.fedUnsubNewStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.fedUnsubNewStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Private More Higher Education Student Loan Balance */}
                      {(detailedInfo.privateNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Further Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year - 1}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateNewStudentLoanBalPreviousYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateNewStudentLoanBalPreviousYear !== '' && detailedInfo.privateNewStudentLoanBalPreviousYear !== null && detailedInfo.privateNewStudentLoanBalPreviousYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateNewStudentLoanBalPreviousYear} prefix={detailedInfo.privateNewStudentLoanBalPreviousYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateNewStudentLoanBalPreviousYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.privateNewLoansTakenCondition === 1 && detailedInfo.privateNewStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Loans Taken</td>
                            <span className={classNames(detailedInfo.privateNewLoansTaken < 0 && classes.labelColor)}>
                              {(detailedInfo.privateNewLoansTaken !== '' && detailedInfo.privateNewLoansTaken !== null && detailedInfo.privateNewLoansTaken !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateNewLoansTaken} prefix={detailedInfo.privateNewLoansTaken >= 0 ? '$' : '($'} suffix={detailedInfo.privateNewLoansTaken < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privateNewAccruedInterestCondition === 1 && detailedInfo.privateNewStudentLoanBalCurrentYearCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Accrued Interest</td>
                            <span className={classNames(detailedInfo.privateNewAccruedInterest < 0 && classes.labelColor)}>
                              {(detailedInfo.privateNewAccruedInterest !== '' && detailedInfo.privateNewAccruedInterest !== null && detailedInfo.privateNewAccruedInterest !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateNewAccruedInterest} prefix={detailedInfo.privateNewAccruedInterest >= 0 ? '$' : '($'} suffix={detailedInfo.privateNewAccruedInterest < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.privateNewPrincipalPaybackCondition === 1 && (detailedInfo.privateNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.privateNewPrincipalPayback < 0 && classes.labelColor)}>
                              {(detailedInfo.privateNewPrincipalPayback !== '' && detailedInfo.privateNewPrincipalPayback !== null && detailedInfo.privateNewPrincipalPayback !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateNewPrincipalPayback} prefix={detailedInfo.privateNewPrincipalPayback >= 0 ? '$' : '($'} suffix={detailedInfo.privateNewPrincipalPayback < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {(detailedInfo.privateNewStudentLoanBalCurrentYearCondition === 1 || detailedInfo.privateNewStudentLoanBalPreviousYearCondition === 1)
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Further Higher Education Private Student Loan Balance at
                              {' '}
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <td className={classes.textRight}>
                              <span className={classNames(detailedInfo.privateNewStudentLoanBalCurrentYear < 0 && classes.labelColor)}>
                                {(detailedInfo.privateNewStudentLoanBalCurrentYear !== '' && detailedInfo.privateNewStudentLoanBalCurrentYear !== null && detailedInfo.privateNewStudentLoanBalCurrentYear !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.privateNewStudentLoanBalCurrentYear} prefix={detailedInfo.privateNewStudentLoanBalCurrentYear >= 0 ? '$' : '($'} suffix={detailedInfo.privateNewStudentLoanBalCurrentYear < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {/* Spouse Student Loans */}
                      {detailedInfo.spouseStudentLoanBalanceValCondition === 1 && detailedInfo.spouseStudentLoanBalanceFinalValCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Spouse Student Loan Balance at
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <span className={classNames(detailedInfo.spouseStudentLoanBalanceValue < 0 && classes.labelColor)}>
                              {(detailedInfo.spouseStudentLoanBalanceValue !== '' && detailedInfo.spouseStudentLoanBalanceValue !== null && detailedInfo.spouseStudentLoanBalanceValue !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.spouseStudentLoanBalanceValue} prefix={detailedInfo.spouseStudentLoanBalanceValue >= 0 ? '$' : '($'} suffix={detailedInfo.spouseStudentLoanBalanceValue < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.spousePrincipalPaybackValCondition === 1 && detailedInfo.spouseStudentLoanBalanceFinalValCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>Principal Payback</td>
                            <span className={classNames(detailedInfo.spousePrincipalPaybackValue < 0 && classes.labelColor)}>
                              {(detailedInfo.spousePrincipalPaybackValue !== '' && detailedInfo.spousePrincipalPaybackValue !== null && detailedInfo.spousePrincipalPaybackValue !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.spousePrincipalPaybackValue} prefix={detailedInfo.spousePrincipalPaybackValue >= 0 ? '$' : '($'} suffix={detailedInfo.spousePrincipalPaybackValue < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      {detailedInfo.spouseStudentLoanBalanceFinalValCondition === 1 && detailedInfo.spouseStudentLoanBalanceFinalValCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Spouse Student Loan Balance at
                              {detailedInfo.Year}
                              {' '}
                              Year End
                            </td>
                            <span className={classNames(detailedInfo.spouseStudentLoanBalanceFinalValue < 0 && classes.labelColor)}>
                              {(detailedInfo.spouseStudentLoanBalanceFinalValue !== '' && detailedInfo.spouseStudentLoanBalanceFinalValue !== null && detailedInfo.spouseStudentLoanBalanceFinalValue !== undefined) ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.spouseStudentLoanBalanceFinalValue} prefix={detailedInfo.spouseStudentLoanBalanceFinalValue >= 0 ? '$' : '($'} suffix={detailedInfo.spouseStudentLoanBalanceFinalValue < 0 && ')'} />
                              ) : ('')}
                            </span>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Total Student Loans</td>
                        <td>
                          <span className={classNames(detailedInfo.finalTotalStudentLoans < 0 && classes.labelColor)}>
                            {(detailedInfo.finalTotalStudentLoans !== '' && detailedInfo.finalTotalStudentLoans !== null && detailedInfo.finalTotalStudentLoans !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.finalTotalStudentLoans} prefix={detailedInfo.finalTotalStudentLoans >= 0 ? '$' : '($'} suffix={detailedInfo.finalTotalStudentLoans < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Car Loans' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}>Car Loans</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.existingCarLoanCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Existing Car Loan Beginning Balance</td>
                            <td>
                              <span className={classNames(detailedInfo.existingCarLoanPreviousValue < 0 && classes.labelColor)}>
                                {(detailedInfo.existingCarLoanPreviousValue !== null && detailedInfo.existingCarLoanPreviousValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingCarLoanPreviousValue} prefix={detailedInfo.existingCarLoanPreviousValue >= 0 ? '$' : '($'} suffix={detailedInfo.existingCarLoanPreviousValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingCarLoanCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.existingPrincipalPayback < 0 && classes.labelColor)}>
                                {(-detailedInfo.existingPrincipalPayback !== null && -detailedInfo.existingPrincipalPayback !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.existingPrincipalPayback} prefix={-detailedInfo.existingPrincipalPayback >= 0 ? '$' : '($'} suffix={-detailedInfo.existingPrincipalPayback < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.existingCarLoanCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Car Loan at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.existingCarLoanValue < 0 && classes.labelColor)}>
                                {(detailedInfo.existingCarLoanValue !== null && detailedInfo.existingCarLoanValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.existingCarLoanValue} prefix={detailedInfo.existingCarLoanValue >= 0 ? '$' : '($'} suffix={detailedInfo.existingCarLoanValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.firstCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Car #1 Price in Year
                              {detailedInfo.purchasingFirstYear}
                            </td>
                            <td>Down Payment</td>
                            <td>Car Loan #1</td>
                          </tr>
                        )}
                      {detailedInfo.firstCarCondition === 1
                        && (
                          <tr>
                            <td>
                              <span className={classNames(detailedInfo.purchasingFirstPrice < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingFirstPrice !== null && detailedInfo.purchasingFirstPrice !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingFirstPrice} prefix={detailedInfo.purchasingFirstPrice >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingFirstPrice < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>{detailedInfo.purchasingFirstDownPayment}</td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingFirstCarLoan < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingFirstCarLoan !== null && detailedInfo.purchasingFirstCarLoan !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingFirstCarLoan} prefix={detailedInfo.purchasingFirstCarLoan >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingFirstCarLoan < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.firstCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.existingCarLoanCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Car Loan #1 Beginning Balance</td>
                            <td>
                              <span className={classNames(detailedInfo.CarLoan1 < 0 && classes.labelColor)}>
                                {(detailedInfo.CarLoan1 !== null && detailedInfo.CarLoan1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.CarLoan1} prefix={detailedInfo.CarLoan1 >= 0 ? '$' : '($'} suffix={detailedInfo.CarLoan1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.firstCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.PrincipalPayback1 < 0 && classes.labelColor)}>
                                {(-detailedInfo.PrincipalPayback1 !== null && -detailedInfo.PrincipalPayback1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.PrincipalPayback1} prefix={-detailedInfo.PrincipalPayback1 >= 0 ? '$' : '($'} suffix={-detailedInfo.PrincipalPayback1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.firstCarCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Car Loan #1 at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.CarLoanTotal1 < 0 && classes.labelColor)}>
                                {(detailedInfo.CarLoanTotal1 !== null && detailedInfo.CarLoanTotal1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.CarLoanTotal1} prefix={detailedInfo.CarLoanTotal1 >= 0 ? '$' : '($'} suffix={detailedInfo.CarLoanTotal1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Car #2 Price in Year
                              {detailedInfo.purchasingSecondYear}
                            </td>
                            <td>Down Payment</td>
                            <td>Car Loan #2</td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingSecondPrice < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingSecondPrice !== null && detailedInfo.purchasingSecondPrice !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingSecondPrice} prefix={detailedInfo.purchasingSecondPrice >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingSecondPrice < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>{detailedInfo.purchasingSecondDownPayment}</td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingSecondCarLoan < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingSecondCarLoan !== null && detailedInfo.purchasingSecondCarLoan !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingSecondCarLoan} prefix={detailedInfo.purchasingSecondCarLoan >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingSecondCarLoan < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Car Loan #2 Beginning Balance</td>
                            <td>
                              <span className={classNames(detailedInfo.CarLoan2 < 0 && classes.labelColor)}>
                                {(detailedInfo.CarLoan2 !== null && detailedInfo.CarLoan2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.CarLoan2} prefix={detailedInfo.CarLoan2 >= 0 ? '$' : '($'} suffix={detailedInfo.CarLoan2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.PrincipalPayback2 < 0 && classes.labelColor)}>
                                {(-detailedInfo.PrincipalPayback2 !== null && -detailedInfo.PrincipalPayback2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.PrincipalPayback2} prefix={-detailedInfo.PrincipalPayback2 >= 0 ? '$' : '($'} suffix={-detailedInfo.PrincipalPayback2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.secondCarCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Car Loan #2 at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.CarLoanTotal2 < 0 && classes.labelColor)}>
                                {(detailedInfo.CarLoanTotal2 !== null && detailedInfo.CarLoanTotal2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.CarLoanTotal2} prefix={detailedInfo.CarLoanTotal2 >= 0 ? '$' : '($'} suffix={detailedInfo.CarLoanTotal2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Total Car Loans</td>
                        <td>
                          <span className={classNames(detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans'] < 0 && classes.labelColor)}>
                            {(detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans'] !== null && detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans'] !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans']} prefix={detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData.Liabilities['Car Loans'] < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Mortgages' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td colSpan="2" className={classes.strongText}>Mortgages</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.firstYearExHomeValue !== 0 && detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Existing Home Value in Year
                              {detailedInfo.startYearVal}
                            </td>
                            <td>&nbsp;</td>
                            <td>Mortgage</td>
                          </tr>
                        )}
                      {detailedInfo.firstYearExHomeValue !== 0 && detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <span className={classNames(detailedInfo.firstYearExHomeValue < 0 && classes.labelColor)}>
                                {(detailedInfo.firstYearExHomeValue !== null && detailedInfo.firstYearExHomeValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.firstYearExHomeValue} prefix={detailedInfo.firstYearExHomeValue >= 0 ? '$' : '($'} suffix={detailedInfo.firstYearExHomeValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                              <span className={classNames(detailedInfo.firstYearMortgageValue < 0 && classes.labelColor)}>
                                {(detailedInfo.firstYearMortgageValue !== null && detailedInfo.firstYearMortgageValue !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.firstYearMortgageValue} prefix={detailedInfo.firstYearMortgageValue >= 0 ? '$' : '($'} suffix={detailedInfo.firstYearMortgageValue < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.firstYearExHomeValue !== 0 && detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && <td>&nbsp;</td>}
                      {detailedInfo.endingBalanceValueCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Beginning Mortgage Balance in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.beginningMortgageBalance < 0 && classes.labelColor)}>
                                {(detailedInfo.beginningMortgageBalance !== null && detailedInfo.beginningMortgageBalance !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.beginningMortgageBalance} prefix={detailedInfo.beginningMortgageBalance >= 0 ? '$' : '($'} suffix={detailedInfo.beginningMortgageBalance < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.beginningMortgagePrincipal < 0 && classes.labelColor)}>
                                {(-detailedInfo.beginningMortgagePrincipal !== null && -detailedInfo.beginningMortgagePrincipal !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.beginningMortgagePrincipal} prefix={-detailedInfo.beginningMortgagePrincipal >= 0 ? '$' : '($'} suffix={-detailedInfo.beginningMortgagePrincipal < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Existing Home Mortgage at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.endingMortgageBalance < 0 && classes.labelColor)}>
                                {(detailedInfo.endingMortgageBalance !== null && detailedInfo.endingMortgageBalance !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.endingMortgageBalance} prefix={detailedInfo.endingMortgageBalance >= 0 ? '$' : '($'} suffix={detailedInfo.endingMortgageBalance < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.finalMortgageCondition === 1 && detailedInfo.endingMortgageBalanceCondition === 1
                        && <td>&nbsp;</td>}
                      {detailedInfo.purchasingMortgageCondition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Home Purchase Price in Year
                              {detailedInfo.purchasingMortgageFirstYear}
                            </td>
                            <td>Down Payment</td>
                            <td>Mortgage</td>
                          </tr>
                        )}
                      {detailedInfo.purchasingMortgageCondition === 1
                        && <td>&nbsp;</td>}
                      {detailedInfo.condition1 === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Beginning Mortgage Balance in Yearr
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.MortgageLoan1 < 0 && classes.labelColor)}>
                                {(detailedInfo.MortgageLoan1 !== null && detailedInfo.MortgageLoan1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.MortgageLoan1} prefix={detailedInfo.MortgageLoan1 >= 0 ? '$' : '($'} suffix={detailedInfo.MortgageLoan1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1 && detailedInfo.principalCondition1 === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.PrincipalPayback1 < 0 && classes.labelColor)}>
                                {(-detailedInfo.PrincipalPayback1 !== null && -detailedInfo.PrincipalPayback1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.PrincipalPayback1} prefix={-detailedInfo.PrincipalPayback1 >= 0 ? '$' : '($'} suffix={-detailedInfo.PrincipalPayback1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition1 === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              Home Mortgage at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.MortgageLoanTotal1 < 0 && classes.labelColor)}>
                                {(detailedInfo.MortgageLoanTotal1 !== null && detailedInfo.MortgageLoanTotal1 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.MortgageLoanTotal1} prefix={detailedInfo.MortgageLoanTotal1 >= 0 ? '$' : '($'} suffix={detailedInfo.MortgageLoanTotal1 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.purchasingMortgageCondition === 1 && detailedInfo.MortgageLoanTotal2Condition === 1
                        && <td>&nbsp;</td>}
                      {detailedInfo.condition1 === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              REI Property Purchase Price in Year
                              {detailedInfo.purchasingMortgageSecondYear}
                              {' '}
                              / Sale Year
                            </td>
                            <td>Down Payment</td>
                            <td>Mortgage</td>
                          </tr>
                        )}
                      {detailedInfo.propertyPurchasingCondition === 1 && detailedInfo.condition2 === 1 && detailedInfo.MortgageLoanTotal2Condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingMortgageSecondPrice < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingMortgageSecondPrice !== null && detailedInfo.purchasingMortgageSecondPrice !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingMortgageSecondPrice} prefix={detailedInfo.purchasingMortgageSecondPrice >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingMortgageSecondPrice < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingMortgageSecondDownPayment < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingMortgageSecondDownPayment !== null && detailedInfo.purchasingMortgageSecondDownPayment !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingMortgageSecondDownPayment} prefix={detailedInfo.purchasingMortgageSecondDownPayment >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingMortgageSecondDownPayment < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.purchasingMortgageSecond < 0 && classes.labelColor)}>
                                {(detailedInfo.purchasingMortgageSecond !== null && detailedInfo.purchasingMortgageSecond !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.purchasingMortgageSecond} prefix={detailedInfo.purchasingMortgageSecond >= 0 ? '$' : '($'} suffix={detailedInfo.purchasingMortgageSecond < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.purchasingMortgageCondition === 1 && detailedInfo.MortgageLoanTotal2Condition === 1
                        && <td>&nbsp;</td>}
                      {detailedInfo.condition2 === 1 && detailedInfo.MortgageLoanTotal2Condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              Beginning Mortgage Balance in Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.MortgageLoan2 < 0 && classes.labelColor)}>
                                {(detailedInfo.MortgageLoan2 !== null && detailedInfo.MortgageLoan2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.MortgageLoan2} prefix={detailedInfo.MortgageLoan2 >= 0 ? '$' : '($'} suffix={detailedInfo.MortgageLoan2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition2 === 1 && detailedInfo.principalCondition2 === 1 && detailedInfo.MortgageLoanTotal2Condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Principal Payback</td>
                            <td>
                              <span className={classNames(-detailedInfo.PrincipalPayback2 < 0 && classes.labelColor)}>
                                {(-detailedInfo.PrincipalPayback2 !== null && -detailedInfo.PrincipalPayback2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.PrincipalPayback2} prefix={-detailedInfo.PrincipalPayback2 >= 0 ? '$' : '($'} suffix={-detailedInfo.PrincipalPayback2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.MortgageLoanTotal2Condition === 1
                        && (
                          <tr>
                            <td>=/+</td>
                            <td>
                              REI Property Mortgage at end of Year
                              {detailedInfo.Year}
                            </td>
                            <td>
                              <span className={classNames(detailedInfo.MortgageLoanTotal2 < 0 && classes.labelColor)}>
                                {(detailedInfo.MortgageLoanTotal2 !== null && detailedInfo.MortgageLoanTotal2 !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.MortgageLoanTotal2} prefix={detailedInfo.MortgageLoanTotal2 >= 0 ? '$' : '($'} suffix={detailedInfo.MortgageLoanTotal2 < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>&nbsp;</td>
                        <td className={classes.strongText}>Total Mortgages</td>
                        <td>
                          <span className={classNames(detailedInfo.finalMortgageValue < 0 && classes.labelColor)}>
                            {(detailedInfo.finalMortgageValue !== null && detailedInfo.finalMortgageValue !== undefined) ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.finalMortgageValue} prefix={detailedInfo.finalMortgageValue >= 0 ? '$' : '($'} suffix={detailedInfo.finalMortgageValue < 0 && ')'} />
                            ) : ('')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Net Worth' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Net Worth</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Total Assets</td>
                            <td>
                              <span className={classNames(detailedInfo.balanceSheetCompleteData['Total Assets'] < 0 && classes.labelColor)}>
                                {(detailedInfo.balanceSheetCompleteData['Total Assets'] !== null && detailedInfo.balanceSheetCompleteData['Total Assets'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData['Total Assets']} prefix={detailedInfo.balanceSheetCompleteData['Total Assets'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData['Total Assets'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Total Liabilities</td>
                            <td>
                              <span className={classNames(-detailedInfo.balanceSheetCompleteData['Total Liabilities'] < 0 && classes.labelColor)}>
                                {(-detailedInfo.balanceSheetCompleteData['Total Liabilities'] !== null && -detailedInfo.balanceSheetCompleteData['Total Liabilities'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.balanceSheetCompleteData['Total Liabilities']} prefix={-detailedInfo.balanceSheetCompleteData['Total Liabilities'] >= 0 ? '$' : '($'} suffix={-detailedInfo.balanceSheetCompleteData['Total Liabilities'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Net Worth</td>
                        <td><span className={classNames(detailedInfo.balanceSheetCompleteData['Net Worth'] < 0 && classes.labelColor)}>
                          {(detailedInfo.balanceSheetCompleteData['Net Worth'] !== null && detailedInfo.balanceSheetCompleteData['Net Worth'] !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData['Net Worth']} prefix={detailedInfo.balanceSheetCompleteData['Net Worth'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData['Net Worth'] < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {currentColumn === 'Total Assets' && (
                  <table className={classes.netIncomeTable}>
                    <tbody>
                      <tr className={classes.popupHeader}>
                        <td>&nbsp;</td>
                        <td>Total Assets</td>
                        <td>
                          Year
                          {detailedInfo.Year}
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Total Tangible Assets</td>
                            <td>
                              <span className={classNames(detailedInfo.balanceSheetCompleteData['Total Tangible Assets'] < 0 && classes.labelColor)}>
                                {(detailedInfo.balanceSheetCompleteData['Total Tangible Assets'] !== null && detailedInfo.balanceSheetCompleteData['Total Tangible Assets'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.balanceSheetCompleteData['Total Tangible Assets']} prefix={detailedInfo.balanceSheetCompleteData['Total Tangible Assets'] >= 0 ? '$' : '($'} suffix={detailedInfo.balanceSheetCompleteData['Total Tangible Assets'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      {detailedInfo.condition === 1
                        && (
                          <tr>
                            <td>&nbsp;</td>
                            <td>Total Investments</td>
                            <td>
                              <span className={classNames(-detailedInfo.balanceSheetCompleteData['Total Investments'] < 0 && classes.labelColor)}>
                                {(-detailedInfo.balanceSheetCompleteData['Total Investments'] !== null && -detailedInfo.balanceSheetCompleteData['Total Investments'] !== undefined) ? (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={-detailedInfo.balanceSheetCompleteData['Total Investments']} prefix={-detailedInfo.balanceSheetCompleteData['Total Investments'] >= 0 ? '$' : '($'} suffix={-detailedInfo.balanceSheetCompleteData['Total Investments'] < 0 && ')'} />
                                ) : ('')}
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr className={classes.incomeBorder}>
                        <td>=</td>
                        <td>Total Assets</td>
                        <td><span className={classNames(detailedInfo.totalAssetsValue < 0 && classes.labelColor)}>
                          {(detailedInfo.totalAssetsValue !== null && detailedInfo.totalAssetsValue !== undefined) ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.totalAssetsValue} prefix={detailedInfo.totalAssetsValue >= 0 ? '$' : '($'} suffix={detailedInfo.totalAssetsValue < 0 && ')'} />
                          ) : ('')}
                        </span></td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenIncomeEarnedDuringHigher(false)}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BalanceStatementPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  openIncomeEarnedDuringHigher: PropTypes.bool.isRequired,
  setOpenIncomeEarnedDuringHigher: PropTypes.func.isRequired,
  currentColumn: PropTypes.string.isRequired,

};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BalanceStatementPopup);
