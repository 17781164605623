import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const LowerCostOfLoan = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );
  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/lower-loan-cost.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>How to Lower the Cost of a Loan</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/lower-loan-costs-final.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Making extra payments on your loans can lower the amount of interest you have to pay and allow you to repay your loans faster. How effective is this strategy? Let&apos;s take a look at an example. What happens if you make an extra $200 payment every month on your 30-year, $200,000 mortgage with a 4.5% interest rate?
                        </p>
                      </div>
                    </div>
                    <div className={ classes.repaymentTable }>
                      <Table>
                        <thead>
                          <tr>
                            <th />
                            <th>Standard Repayment</th>
                            <th>Extra $200/month</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Loan Amount</td>
                            <td>$200,000</td>
                            <td>$200,000</td>
                          </tr>
                          <tr>
                            <td>Interest Rate</td>
                            <td>4.50%</td>
                            <td>4.50%</td>
                          </tr>
                          <tr>
                            <td>Term of the Loan (Years)</td>
                            <td>30 yrs (360 months)</td>
                            <td>30 yrs (360 months)</td>
                          </tr>
                          <tr>
                            <td>Monthly Payment</td>
                            <td>$1,013.37</td>
                            <td>$1,213.37</td>
                          </tr>
                          <tr className={ classes.emptyRow }>
                            <td className={ classes.emptyData }>&nbsp;</td>
                            <td className={ classes.emptyData }>&nbsp;</td>
                            <td className={ classes.emptyData }>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>Total Repayment Time</td>
                            <td>360 months</td>
                            <td>258 months</td>
                          </tr>
                          <tr className={ classes.orangeRow }>
                            <td>Total Time Saved</td>
                            <td>--</td>
                            <td>102 months (8.5 yrs)</td>
                          </tr>
                          <tr>
                            <td>Total Interest Paid</td>
                            <td>$164,813.42</td>
                            <td>112,057.91</td>
                          </tr>
                          <tr className={ classes.orangeRow }>
                            <td>Total Interest Saved</td>
                            <td>--</td>
                            <td>+$52,755.51</td>
                          </tr>
                          <tr>
                            <td><b>Total Repayment Amount (Principal & Interest)</b></td>
                            <td><b>$364,813.42</b></td>
                            <td><b>$312,057.91</b></td>
                          </tr>
                          <tr className={ classes.emptyRow }>
                            <td className={ classes.emptyData }>&nbsp;</td>
                            <td className={ classes.emptyData }>&nbsp;</td>
                            <td className={ classes.emptyData }>&nbsp;</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>By paying $200 more each month, you save more than $50,000 and repay your mortgage 8.5 years ahead of schedule!</b>
                        {' '}
                        Making extra payments on your loans is a simple, yet powerful strategy to getting out of debt faster and saving money on interest.
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          Another strategy to lower the cost of a loan is to refinance that loan. Refinancing is replacing the existing loan with a new loan that has a lower interest rate or other better features. The process of refinancing usually follows the below steps:
                          <ol>
                            <li>You want to see if you can get a better rate on an existing loan</li>
                            <li>You ask your lender and other lenders to provide you with the details for a new loan</li>
                            <li>If you get a lower interest rate, you can apply for that new loan</li>
                            <li>You get approved and the new loan replaces your existing loan</li>
                            <li>You repay your new loan each month</li>
                          </ol>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        When looking to refinance an existing loan, pay attention to the amount of fees the lender will charge you on the new loan. These fees might offset the interest savings on a new loan.
                        <p>Making extra payments on your loan or refinancing an existing loan to receive a new, lower interest rate loan are great strategies to lower the cost of borrowing.</p>
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level6/lower-cost-of-loan/exercise" startTime={ startTime } level={ 6 } exercise={ 6 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
LowerCostOfLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( LowerCostOfLoan );
