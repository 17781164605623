const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  notificationHead: {
    padding: '8px 10px',
    background: '#efefef',
    borderRadius: '4px',
    margin: '30px 0 15px',
    '& h2': {
      margin: '0',
      color: '#0069aa',
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  userImage: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 'auto',
    height: 'auto',
    '& img': {
      width: '36px',
      height: '36px',
      cursor: 'default',
      borderRadius: '50%',
    },
  },
  notificationText: {
    padding: '13px 11px',
    position: 'relative',
    width: 'calc(100% - 130px)',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      paddingBottom: '0px',
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '0px',
      lineHeight: '18px',
    },
    '& a': {
      color: theme.palette.common.blue,
      '& b': {
        color: theme.palette.common.blue,
        fontWeight: 'normal',
        fontFamily: '"MuseoSans-500"',
      },
    },
    '& span':{
      '& b': {
        color: theme.palette.common.blue,
        fontWeight: 'normal',
        fontFamily: '"MuseoSans-500"',
      },
    }
  },
  anchorSpan: {
    color: theme.palette.common.blue,
    cursor: 'pointer',
    textDecoration: 'underline',
    '& b': {
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  rejectText: {
    padding: '15px',
    '& p':{
      margin: '0px',
      color: 'red',

    }
  },
  notificationDate: {
    color: '#fff',
    display: 'inline-block',
    padding: '3px 8px',
    fontSize: '12px',
    background: '#efbd4c',
    width: 'calc(105px - 16px)',
    textAlign: 'center',
    // position: 'absolute',
    // width: 'auto',
    // right: '10px',
    // display: 'inline-block',
    // fontSize: '12px',
    // marginRight: '5px',
    // textAlign: 'right',
    // verticalAlign: 'middle',
    // background: '#efbd4c',
    // color: '#fff',
    // padding: '3px 8px',
    // bottom: 'auto',
    // marginTop: '-11px',
    // top: '50%',
    // [theme.breakpoints.down( 'xs' )]: {
    //   position: 'relative',
    //   right: '0px',
    //   top: '0px',
    //   marginTop: '10px',
    //   marginBottom: '10px',
    //   display: 'block',
    //   maxWidth: '100%',
    //   marginLeft: '10px',
    // },
  },
  allNotifications: {
    position: 'absolute',
    bottom: '-39px',
    left: '12px',
    width: '32px',
    height: '32px',
    background: '#d6d6d6',
    borderRadius: '100%',
    '& img': {
      display: 'none',
    },
    '&:before': {
      content: '""',
      background: '#e8ebef',
      width: '2px',
      height: '100%',
      position: 'absolute',
      left: '15px',
      top: '-18px',
      zIndex: '-1',
    },
    '&:after': {
      content: '""',
      width: '8px',
      height: '8px',
      borderTop: '1px solid #7b7b7b',
      borderLeft: '1px solid #7b7b7b',
      transform: 'rotate(-138deg)',
      left: '12px',
      position: 'absolute',
      top: '10px',
    },
  },
  notificationContent: {
    margin: '0 0 75px',
    padding: '0',
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },
  notificationRow: {
    display: 'flex',
    alignItems: 'center',
    width: '96%',
    padding: '0px 10px',
    '&:after': {
      content: '""',
      background: '#e8ebef',
      width: '2px',
      height: '100%',
      position: 'absolute',
      left: '27px',
      top: '-8px',
      zIndex: '-1',
    },
  },
  mainContent: {
    borderBottom: '1px solid #e8ebef',
    width: 'calc(100% - 36px)',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
  },
  tooltipInfo: {
    fontSize: '12px',
    padding: '3px 8px',
    borderRadius: '4px',
    fontWeight: '400',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
  },
  tooltipInfoNotification:{
    background: '#0069aa !important',
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      minWidth: '600px',
      width: '600px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  popupHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  successText: {
    padding: '15px',
    '& p':{
      margin: '0px',
      color: 'green',

    }
  },
  popupFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: ' 1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '6px 12px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '1.4',
      textTransform: 'capitalize',
      borderRadius: '4px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:first-child': {
        color: theme.palette.common.white,
        backgroundColor: '#337ab7',
        border: '1px solid transparent',
        marginRight: '5px',
        '&:hover': {
          backgroundColor: '#204d74',
          border: '1px solid transparent',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },
  },
  notificationSpanButton:{
    '& button':{
      padding: '3px 8px !important',
      fontSize: '12px !important',
      borderRadius: '4px',
      textTransform: 'capitalize',
      lineHeight: '10px',
      background: '#0069aa',
      border: '2px solid #0069aa',
      color: '#fff !important',
      margin: '2px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.8',
      },
      '&:last-child':{
        background: '#d9534f',
        border: '2px solid #d9534f',
      }
    }
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  messagePopup: {
    position: 'relative',
    padding: '0px',
    minWidth: '600px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '100%',
    },
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '10px',
    color: '#000',
    float: 'none',
    right: '0',
    width: 'calc(100% - 17px)',
    border: '1px solid #f59331',
    cursor: 'pointer',
    height: '35px',
    opacity: '1',
    outline: 'none',
    zIndex: '99',
    position: 'relative',
    fontSize: '25px',
    background: '#fff',
    textAlign: 'right',
    lineHeight: '26px',
    textShadow: 'none',
    borderColor: '#fff',
    borderRadius: '0',
    borderBottom: '1px solid #ccc',
    left: '0',
    overflow: 'hidden',
    paddingRight: '15px',
  },
  popupBody: {
    padding: '35px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0px 0 20px',
    },
  },
  popupRejectCheck:{
    width: '142px',
    height: '142px',
    border: '4px solid red',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: 'red',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid red',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  noNoitificationText:{
    textAlign: 'center',
    padding: '100px 0px',
    '& h3':{
      color: '#0069aa',
      fontSize: '24px',
      margin: '0px',
    }
  }
} );

export default styles;
