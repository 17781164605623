const styles = ( theme ) => ( {
  container: {
    maxWidth: '80%',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  topImage: {
    '& img': {
      width: '100%',
      position: 'absolute',
      zIndex: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        background: '#004375',
        height: '200px',
      },
    },
  },
  mcsLogoImage: {
    width: '50%',
    '& a': {
      '& img': {
        maxWidth: '300px',
        verticalAlign: 'middle',
        [theme.breakpoints.down( 'sm' )]: {
          maxWidth: '220px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          maxWidth: '250px',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  introductionMsg: {
    width: '50%',
    textAlign: 'right',
    '& h3': {
      fontSize: '24px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '1.1',
      color: '#fff',
      fontWeight: '500',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0px 0px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  mcsTitle: {
    width: '100%',
    marginTop: '150px',
    '& h1': {
      fontSize: '85px',
      color: '#1f96e0',
      lineHeight: '1.1',
      marginBottom: '30px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '50px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '50px',
    },
  },
  subTitle: {
    fontSize: '18px',
    fontFamily: 'MuseoSans-300',
    color: '#1f96e0',
  },
  descBlock: {
    '& p': {
      fontSize: '16px',
      color: '#333',
      fontFamily: 'MuseoSans-300',
      lineHeight: '25px',
    },
  },
  leftBlock: {
    width: '50%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },
  },
  startButton: {
    paddingTop: '50px',
    '& button': {
      padding: '7px 30px',
      background: '#8ba659',
      textTransform: 'uppercase',
      border: '1px solid #8ba659',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '17px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',
      letterSpacing: '1px',
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },
  blueBlock: {
    color: '#fff',
    padding: '20px',
    background: '#1f96e0',
    maxWidth: '275px',
    borderRadius: '15px',
    margin: '40px 0px 0px 0px',
    float: 'right',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '30px auto 0px auto',
      float: 'none',
    },
    '& ul': {
      '& li': {
        fontSize: '16px',
        fontFamily: 'MuseoSans-300',
        marginBottom: '10px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '20px',
      },
    },
    '& p': {
      fontSize: '18px',
      paddingLeft: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
        paddingLeft: '0px',
      },
    },
  },
  rightBlock: {
    width: '50%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },
  },
  mcsRow: {
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
  },
  blueWelcome: {
    '& p': {
      fontSize: '24px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down( 'xs' )]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down( 'xs' )]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
} );

export default styles;
