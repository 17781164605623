import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles';
import CareerPath from '../../assets/img/career-path/cp.png';
import WorkSheet from '../../assets/img/pwi-fi/worksheet-icon.png';
import fixMenuImage from '../../assets/img/pwi-fi/mega-menu-pricing_img.png';
import Simulator from '../../assets/img/pwi-fi/simulator-engine-blue.png';
import TimeLine from '../../assets/img/pwi-fi/timeline_icon.png';
import Dollar from '../../assets/img/pwi-fi/dollar-icon.png';
import Dashboard from '../../assets/img/pwi-fi/dashboard.png';
import Clear from '../../assets/img/pwi-fi/clear-timeline.png';
import Load from '../../assets/img/pwi-fi/load.png';
// import Download from '../../assets/img/pwi-fi/download.png';
import Save from '../../assets/img/pwi-fi/save.png';
import DocumentsList from '../../components/pwi/pwi-left-menu/documents-list';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Sidebar = ( props ) => {
  const {
    classes, menu, handleMenu, handleCareerObject, handleSideMenu, completedSlides, openBeforeLeavePopup,setOpenPopup, setOpenDocsPopup, clearScenario, saveScenario, sketchName, activeScenario, selfEmployment
  } = props;

  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = React.useState( false );
  const [expensesOpen, setExpensesOpen] = React.useState( false );

  const usePathname = (event,url,moduleName) => {
    event.preventDefault();
    openBeforeLeavePopup(url,moduleName);
  }

  return (
    <div className={ menu ? classes.fixedMenuDisplay : classes.fixedMenu }>
      <ReactTooltip id="worksheetView" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.worksheetToolTip ) } />
      <div className={ classes.fixedMenuHeader }>
        <Link href="/plan-with-inkwiry/fi" onClick={(event)=>{usePathname(event,'/worksheet', 'Worksheet')}}>
          <img src={ WorkSheet } alt="" data-for="topTooltipTopIcon" data-tip="Switch to Worksheet view" />
        </Link>
        <i className="la la-close" aria-hidden="true" onClick={ ()=>handleMenu(false) } />
      </div>
      <div className={ classes.planWithInkwiry }>
        <div className={ classes.powerUpLogo }>
          <img src={ fixMenuImage } alt="" />
        </div>
        <h1>Plan With Inkwiry</h1>
      </div>
      <div className={ classes.powerUpCnt }>
        <h4>
          <figure>
            <img src={ CareerPath } alt="" />
          </figure>
          Career Path
        </h4>
        <ul className={ classes.homeMenuList }>
          <li className={ classes.activeMenu } aria-hidden="true" onClick={ () => handleSideMenu( 4 ) }>
            <span className={ classes.numberColor }>01</span>
            <font>Career Exploration</font>
            <span className={ classes.menuCheck }>
              {completedSlides[2] !== undefined && completedSlides[2] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[4] !== undefined && completedSlides[4] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 5 ) }>
            <span className={ classes.numberColor }>02</span>
            <font>Setting Timelines</font>
            <span className={ classes.menuCheck }>
              {completedSlides[5] !== undefined && completedSlides[5] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[5] !== undefined && completedSlides[5] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 6 ) }>
            <span className={ classes.numberColor }>03</span>
            <font>Base Income</font>
            <span className={ classes.menuCheck }>
              {completedSlides[6] !== undefined && completedSlides[6] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 7 ) }>
            <span className={ classes.numberColor }>04</span>
            <font>Bonus / Tips / Commission</font>
            <span className={ classes.menuCheck }>
              {completedSlides[7] !== undefined && completedSlides[7] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 8 ) }>
            <span className={ classes.numberColor }>05</span>
            <font>Supplementary Income</font>
            <span className={ classes.menuCheck }>
              {completedSlides[8] !== undefined && completedSlides[8] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 9 ) }>
            <span className={ classes.numberColor }>06</span>
            <font>Income After Taxes</font>
            <span className={ classes.menuCheck }>
              {completedSlides[9] !== undefined && completedSlides[9] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li>
            <span className={ classes.numberColor }>08</span>
            <font>
              <span aria-hidden="true" onClick={ () => handleSideMenu( 11 ) }>Living Expenses &nbsp;</span>
              <span className={ classes.caretIcon } aria-hidden="true" onClick={ () => { setExpensesOpen( !expensesOpen ); } }>{' '}</span>
            </font>
            <span className={ classes.menuCheck }>
              {completedSlides[11] !== undefined && completedSlides[11] === true
              && <CheckIcon fontSize="small" />}
            </span>
            {expensesOpen && (
            <ul className={ classes.subListMenu }>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 11 ) }>Rent and Utilities</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 12 ) }>Food</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 13 ) }>Clothing and Personal Care</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 14 ) }>Entertainment</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 15 ) }>Technology</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 16 ) }>Transportation</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 17 ) }>Miscellaneous</li>
              <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 18 ) }>Healthcare and Insurance</li>
            </ul>
            )}

          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 19 ) }>
            <span className={ classes.numberColor }>09</span>
            <font>My Budget Summary</font>
            <span className={ classes.menuCheck }>
              {completedSlides[19] !== undefined && completedSlides[19] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[4] !== undefined && completedSlides[4] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 20 ) }>
            <span className={ classes.numberColor }>10</span>
            <font>Retirement Planning</font>
            <span className={ classes.menuCheck }>
              {completedSlides[20] !== undefined && completedSlides[20] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ ( selfEmployment === 'No' || completedSlides[5] !== true ) ? classes.clickNone : '' } onClick={ () => handleCareerObject.self_employment === "No" &&  handleSideMenu( 22 ) }>
            <span className={ classes.numberColor }>11</span>
            <font>401(k)</font>
            <span className={ classes.menuCheck }>
              {((completedSlides[22] !== undefined && completedSlides[22] === true) && selfEmployment === 'No')
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 25 ) }>
            <span className={ classes.numberColor }>12</span>
            <font>Roth IRA</font>
            <span className={ classes.menuCheck }>
              {completedSlides[25] !== undefined && completedSlides[25] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ ( selfEmployment === 'Yes' || completedSlides[5] !== true ) ? classes.clickNone : '' } onClick={ () => handleCareerObject.self_employment === "Yes" && handleSideMenu( 27 ) }>
            <span className={ classes.numberColor }>13</span>
            <font>SEP IRA</font>
            <span className={ classes.menuCheck }>
              {((completedSlides[27] !== undefined && completedSlides[27] === true) && selfEmployment === 'Yes')
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ ( selfEmployment === 'No' || completedSlides[5] !== true ) ? classes.clickNone : '' } onClick={ () => handleCareerObject.self_employment === "No" && handleSideMenu( 29 ) }>
            <span className={ classes.numberColor }>14</span>
            <font>Pension</font>
            <span className={ classes.menuCheck }>
              {((completedSlides[29] !== undefined && completedSlides[29] === true) && selfEmployment === 'No' )
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" className={ ( completedSlides[5] !== true ) ? classes.clickNone : '' } onClick={ () => handleSideMenu( 31 ) }>
            <span className={ classes.numberColor }>14</span>
            <font>HSA</font>
            <span className={ classes.menuCheck }>
              {((completedSlides[31] !== undefined && completedSlides[31] === true))
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={ ( completedSlides[6] !== undefined && completedSlides[6] === true ) ? '' : classes.clickNone } aria-hidden="true" onClick={ () => handleSideMenu( 33 ) }>
            <span className={ classes.numberColor }>15</span>
            <font>My Career Path Summary</font>
            <span className={ classes.menuCheck }>
              {completedSlides[33] !== undefined && completedSlides[33] === true
              && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li>
            <div>
              {activeScenario.id !== undefined && activeScenario.id !== '' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Scenario</span>
                  <span>{activeScenario.name}</span>
                </div>
              )}
              {sketchName !== '' && sketchName !== 'Active Selection' && (
                <div className={classes.scenarioNameBlock}> 
                  <span>Active Career Sketch</span>
                  <span>{sketchName}</span>
                </div>
              )}
            </div>
          </li>
        </ul>
        <div className={ classes.footerMenuFixed }>
          <ul>
            <li>
              <Link component="button" onClick={ () => { handleMenu(false);setOpenPopup( 'simulator' ); } } data-for="bottomTooltip" data-tip="Simulator Panel">
                <img src={Simulator} alt="" />
              </Link>
            </li>
            <li>
              <Link href="/timeline-sketcher" onClick={(event)=>{usePathname(event,'/timeline-sketcher', 'Career Sketch Timeline')}} data-for="bottomTooltip" data-tip="Go to Timeline">
                <img src={ TimeLine } alt="" />
              </Link>
            </li>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={()=>{setSummaryDocumentsOpen(false)}}
              >
                <li className={classes.documentsMenu} > 
                  <Link component="button" onClick={ () => { setSummaryDocumentsOpen( !summaryDocumentsOpen ); } } data-for="bottomTooltip" data-tip="Summary Documents">
                    <img src={Dollar} alt="" />
                  </Link>
                  {summaryDocumentsOpen && (
                    <DocumentsList hideMenu={handleMenu} setOpenDocsPopup={setOpenDocsPopup} setSummaryDocumentsOpen={setSummaryDocumentsOpen}/>
                  )}
                </li>
            </ClickAwayListener>
            <li>
              <Link href="/dashboard" onClick={(event)=>{usePathname(event,'/dashboard', 'Dashboard')}} data-for="bottomTooltip" data-tip="Go to Dashboard">
                <img src={ Dashboard } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={clearScenario} data-for="bottomTooltip" data-tip="Clear Inputs">
                <img src={ Clear } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={()=>openBeforeLeavePopup('','loadScenario','load')} data-for="bottomTooltip" data-tip="Load">
                <img src={ Load } alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={saveScenario} data-for="bottomTooltip" data-tip="Save">
                <img src={ Save } alt="" />
              </Link>
            </li>
          </ul>
        </div>
        <ReactTooltip id="topTooltipTopIcon" place="right" type="info" effect="solid" className={ classNames( classes.tooltipInfoTopNew, classes.worksheetToolTip ) } />
        <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.moduleToolTip ) } />
      </div>
    </div>

  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  menu: PropTypes.bool.isRequired,
  handleMenu: PropTypes.func.isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  completedSlides: PropTypes.object.isRequired,
  openBeforeLeavePopup: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenario: PropTypes.func.isRequired,
  sketchName: PropTypes.string.isRequired,
  activeScenario: PropTypes.object.isRequired,
  selfEmployment: PropTypes.string.isRequired,
};

export default withStyles( styles )( Sidebar );
