import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../styles';
import { useState } from 'react';

const CostRaisingChild = (props) => {
  const {
    classes, updatedFamilyValues, setUpdatedFamilyValues, setPopupOpen, handleResetValue,
  } = props;

  const [familyValues, setFamilyValues] = useState(updatedFamilyValues);
 
  useEffect(()=>{
    setFamilyValues(updatedFamilyValues);
  },[updatedFamilyValues]);

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...familyValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
    }
    moduleServices.setModule10Data(valuesUpdated);
    setFamilyValues(valuesUpdated);
  };

  const handleOk = () => {
    setUpdatedFamilyValues(familyValues);
    setPopupOpen(false);
  }

  return (
    <div>
      <div className={classes.modalHeader}>
        <h3>Costs of Raising a Child</h3>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Housing
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>
                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.housing_cost} onValueChange={(e) => updateValue(e, 'housing_cost', 'number')} onFocus={(e) => e.target.select()} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Food
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.food_cost} onValueChange={(e) => updateValue(e, 'food_cost', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Transportation
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.transportation_cost} onValueChange={(e) => updateValue(e, 'transportation_cost', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Clothing
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.clothing_cost} onValueChange={(e) => updateValue(e, 'clothing_cost', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Health Care
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.health_care_cost} onValueChange={(e) => updateValue(e, 'health_care_cost', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Child Care and Education
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.child_care_and_education} onValueChange={(e) => updateValue(e, 'child_care_and_education', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Other
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>

                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.other_costs} onValueChange={(e) => updateValue(e, 'other_costs', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.modalFooter}>
        <Grid item sm={12} xs={12}>
          <h3>
            Annual Cost of Raising a Child in {updatedFamilyValues.first_born > 0 && ('year')}:{' '}
            {moduleServices.module10Data.averageCostRaisingChild !== undefined ? (
              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.averageCostRaisingChild} prefix={moduleServices.module10Data.averageCostRaisingChild >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.averageCostRaisingChild < 0 && ')'} />
            ) : ('$0')}
          </h3>
        </Grid>
        <div>
          <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={handleResetValue}>Reset to default values</Button>
          <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleOk}>Ok</Button>
          <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { setPopupOpen(false); }}>Cancel</Button>
        </div>
      </div>


    </div>
  );
};

CostRaisingChild.propTypes = {
  classes: PropTypes.object.isRequired,
  updatedFamilyValues: PropTypes.object.isRequired,
  setUpdatedFamilyValues: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  handleResetValue: PropTypes.func.isRequired,
};

export default withStyles(styles)(CostRaisingChild);
