import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/rei/styles';
import converyIntoThousands, { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const mortgageFollowersMarks = [];
const downpaymentMarks = [];
for ( let i = 0; i <= 80; i += 20 ) {
  if(i <= 80) {
    mortgageFollowersMarks.push(
      {
        value: i,
        label: i !== 0 ? `${i}%` : '0% of Purchase Price',
      },
    );
  }
  if(i >= 20 ) {
    downpaymentMarks.push(
      {
        value: i,
        label: i !== 20 ? `${i}%` : '20% of Purchase Price',
      },
    );
  }
}


const purchasePriceMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 100000,
    label: '$100K',
  },
  {
    value: 200000,
    label: '$200K',
  },
  {
    value: 300000,
    label: '$300K',
  },
  {
    value: 400000,
    label: '$400K',
  },
  {
    value: 500000,
    label: '$500K',
  },
  {
    value: 600000,
    label: '$600K',
  },
  {
    value: 700000,
    label: '$700K',
  },
  {
    value: 800000,
    label: '$800K',
  },
];

const PurchasePricePayment = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [purchasePrice, setPurchasePrice] = React.useState( 0 );
  const [downpayment, setDownpayment] = React.useState( 0 );
  const [downpaymentValue, setDownpaymentValue] = React.useState( 0 );
  const [mortgage, setMortgage] = React.useState( 0 );
  const [mortgageValue, setMortgageValue] = React.useState( 0 );

  useEffect( () => {

    let inputValues = {...inputData};

    let purchaseVal = 0;
    if ( inputData.purchase_price === undefined ) {
      purchaseVal = inputData.affordability_rei_value !== undefined ? inputData.affordability_rei_value : 400000;
    } else {
      purchaseVal = inputData.purchase_price;
    }
    setPurchasePrice( purchaseVal );

    let down_payment = 0;

    if ( inputData.down_payment_in_percentage === undefined ) {
      if ( purchaseVal >= 0 ) {
        const down_payment_val = ( purchaseVal / 100 ) * 20;
        down_payment = ( down_payment_val / purchaseVal ) * 100;
      }
    } else {
      down_payment = inputData.down_payment_in_percentage;
    }

    setDownpayment( down_payment );
    setMortgage( 100 - down_payment );
    const downpaymentVal = ( purchaseVal / 100 ) * down_payment;
    setDownpaymentValue( downpaymentVal );
    setMortgageValue( purchaseVal - downpaymentVal );

    inputValues.purchase_price = purchaseVal;
    inputValues.down_payment_in_percentage = down_payment;

    updateData( 'total_obj', inputValues );

    // eslint-disable-next-line
}, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    let inputDetails = {...inputData};
    switch ( field ) {
      case 'purchase_price': {
        setPurchasePrice( newvalue );
        setDownpaymentValue( ( newvalue / 100 ) * downpayment );
        setMortgageValue( ( newvalue / 100 ) * mortgage );

        inputDetails.purchase_price = newvalue;
        updateData( 'total_obj', inputDetails );

        break;
      }
      case 'downpayment_slider': {
        setDownpayment( newvalue );
        setDownpaymentValue( ( purchasePrice / 100 ) * newvalue );
        setMortgage( 100 - newvalue );
        setMortgageValue( ( purchasePrice / 100 ) * ( 100 - newvalue ) );

        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );

        break;
      }
      case 'downpayment': {
        if ( newvalue > purchasePrice ) {
          newvalue = purchasePrice;
        }
        setDownpaymentValue( newvalue );
        let downpaymentVal = 0;
        if ( purchasePrice > 0 ) {
          downpaymentVal = ( newvalue / purchasePrice ) * 100;
        }
        setDownpayment( downpaymentVal );
        setMortgage( 100 - downpaymentVal );
        setMortgageValue( ( purchasePrice / 100 ) * ( 100 - downpaymentVal ) );
        inputDetails[mainField] = downpaymentVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'mortgage_slider': {
        setMortgage( newvalue );
        setMortgageValue( ( purchasePrice / 100 ) * newvalue );
        setDownpayment( 100 - newvalue );
        setDownpaymentValue( ( purchasePrice / 100 ) * ( 100 - newvalue ) );
        inputDetails[mainField] = 100 - newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'mortgage': {
        if ( newvalue > purchasePrice ) {
          newvalue = purchasePrice;
        }
        setMortgageValue( newvalue );
        let mortgageVal = 0;
        if ( purchasePrice > 0 ) {
          mortgageVal = ( newvalue / purchasePrice ) * 100;
        }
        setMortgage( mortgageVal );
        setDownpayment( 100 - mortgageVal );
        setDownpaymentValue( ( purchasePrice / 100 ) * ( 100 - mortgageVal ) );

        inputDetails[mainField] = 100 - mortgageVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Buying an investment property is much different than buying a home to live in. Your investment goals should be to minimize risk and maximize reward. Minimizing risk starts with paying the right amount for the property. Double check your CMA and input your purchase price below.</p>
      <p>For an investment property, your mortgage lender will require at least 20% down payment.</p>
      <div className={ classes.purchaseSlideScroll }>
        <div className={ classes.purchaseSlide }>
          <div className={ classNames( classes.flexDisplayNew, classes.flexDisplayNewFull ) }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Purchase Price
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ purchasePrice }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ converyIntoThousands }
                    min={ 0 }
                    max={ 800000 }
                    step={ 1000 }
                    marks={ purchasePriceMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'purchase_price', 'purchase_price', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ purchasePrice } onChange={ ( e ) => updateValue( e, '', 'purchase_price', 'purchase_price', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classNames( classes.flexDisplayNew, classes.flexDisplayNewFull ) }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Down Payment
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ downpayment }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    min={ 20 }
                    max={ 100 }
                    step={ 0.5 }
                    marks={ downpaymentMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'downpayment_slider', 'down_payment_in_percentage', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ downpaymentValue } onChange={ ( e ) => updateValue( e, '', 'downpayment', 'down_payment_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classNames( classes.flexDisplayNew, classes.mortgageHeight, classes.flexDisplayNewFull ) }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Mortgage
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ mortgage }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    min={ 0 }
                    max={ 80 }
                    step={ 0.5 }
                    marks={ mortgageFollowersMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'mortgage_slider', 'down_payment_in_percentage', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ mortgageValue } onChange={ ( e ) => updateValue( e, '', 'mortgage', 'down_payment_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PurchasePricePayment.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( PurchasePricePayment );
