import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-family/styles';
import circleArrow from '../../../assets/img/career-path/circle-arrow.png';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import * as modulesServices from '../../../calculations/modules-services';


const AverageCostRaisingChild = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [costData, setCostData] = React.useState( {} );
  const [defaultCostData, setDefaultCostData] = React.useState( {} );
  const [showRestIcon, setShowRestIcon] = React.useState(false);


  useEffect(()=>{
    if(costData['housing_cost'] !== defaultCostData['housing_cost'] || costData['food_cost'] !== defaultCostData['food_cost'] || costData['transportation_cost'] !== defaultCostData['transportation_cost']
      || costData['clothing_cost'] !== defaultCostData['clothing_cost'] || costData['health_care_cost'] !== defaultCostData['health_care_cost'] || costData['child_care_and_education'] !== defaultCostData['child_care_and_education']
      || costData['other_costs'] !== defaultCostData['other_costs']) {
      setShowRestIcon(true);
    } else {
      setShowRestIcon(false);
    }
  },[costData])


  useEffect( () => {
    let assumptionsData = modulesServices.assumptionsCompleteData();
    assumptionsData = assumptionsData['Average'];
    const childCost = {};
    childCost.housing_cost = assumptionsData['Housing'];
    childCost.food_cost = assumptionsData['Food'];
    childCost.transportation_cost = assumptionsData['Transportation'];
    childCost.clothing_cost = assumptionsData['Clothing'];
    childCost.health_care_cost = assumptionsData['Health Care'];
    childCost.child_care_and_education = assumptionsData['Child Care and Education'];
    childCost.other_costs = assumptionsData['Other'];
    childCost.save_average_cost_raising_child = 'Yes';

    setCostData( childCost );
    setDefaultCostData( childCost );
    updateData('total_obj',{...inputData,...childCost});
    // eslint-disable-next-line
  },[])

  const resetInputs = () => {
    updateData('total_obj',{...inputData,...defaultCostData});
    setCostData(defaultCostData);
    setShowRestIcon(false)
  }

   const updateValue = ( e, field ) => {
    const cost = { ...costData };
    let newvalue = e.target.value;
    if(newvalue === '') {
      newvalue = 0;
    }
    newvalue = convertCurrencyToInteger(newvalue);
    cost[field] = newvalue;
    setCostData( cost );
    updateData( field, newvalue );
  };  

  const childTotalCost = _.sum(Object.values(costData)); 

  return (
    <div className={ classes.contentBlock }>
      <p>
        Plan With Inkwiry uses{' '}<a target="_blank" rel="college_navigator noopener noreferrer" href="https://fns-prod.azureedge.net/sites/default/files/CRC_Infographic-2015.2_0.pdf">U.S. Department of Agriculture (USDA)</a>{' '}data to estimate the current and future costs of raising a child. Each year, the Financial Configurator grows the cost of having a child by taking the current estimated cost and multiplying it by inflation. Meaning, if you plan to have a child in 10 years, the current estimated cost will have grown by the annual inflation rate for 10 years (just like all other expenses in the Financial Configurator).
        This is called{' '}<a target="_blank" rel="college_navigator noopener noreferrer" href="https://www.investopedia.com/terms/f/forecasting.asp">forecasting!</a>
      </p>
      <p>Plan With Inkwiry uses these assumptions to help you in the planning process. If you want to change the default costs of having a child, edit the table below. If you currently have a child, make these numbers your own and adjust them below.</p>
      <div className={ classes.raisingChild }>
        <table cellPadding="0" cellSpacing="0" className={ classes.raisingChildTable }>
          <thead>
            <tr>
              <th>Cost of Raising a Child</th>
              <th className={ classes.textRight }>
                {
                  inputData.first_born !== undefined && inputData.first_born !== null && inputData.first_born > 0 &&(
                     `Year ${inputData.first_born}`
                  )
                }
                {showRestIcon && (
                  <span className={ classes.resetIcon }>
                    <ReactTooltip id="reset1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                    <img src={ circleArrow } alt=""  aria-hidden="true" onClick={resetInputs} data-for="reset1" data-tip="Reset to default values" />
                  </span>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Housing</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.housing_cost !== undefined ? costData.housing_cost : 0 } onChange={ ( e ) => updateValue( e, 'housing_cost' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Food</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.food_cost !== undefined ? costData.food_cost : 0 } onChange={ ( e ) => updateValue( e, 'food_cost' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Transportation</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.transportation_cost !== undefined ? costData.transportation_cost : 0 } onChange={ ( e ) => updateValue( e, 'transportation_cost' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Clothing</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.clothing_cost !== undefined ? costData.clothing_cost : 0 } onChange={ ( e ) => updateValue( e, 'clothing_cost' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Health Care</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.health_care_cost !== undefined ? costData.health_care_cost : 0 } onChange={ ( e ) => updateValue( e, 'health_care_cost' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Child Care and Education</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.child_care_and_education !== undefined ? costData.child_care_and_education : 0 } onChange={ ( e ) => updateValue( e, 'child_care_and_education' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Other</td>
              <td>
                <div className={ classes.amountDollar }>
                  $
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.other_costs !== undefined ? costData.other_costs : 0 } onChange={ ( e ) => updateValue( e, 'other_costs' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total Costs of Raising a Child</th>
              <th><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ childTotalCost } displayType="text" thousandSeparator prefix="$" /></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

AverageCostRaisingChild.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( AverageCostRaisingChild );
