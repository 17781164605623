import { formatDollar } from './chartCommonFunctions';

const data = [
  {
    id: 'compounding_interest_and_time',
    options: {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          width: 600,
          scrollPositionX: 0,
          height: 1000,
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
        enabled: false,
      },
      xAxis: [{
        tickInterval: 1,
        categories: [2021, 2222, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            if ( this.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( this.value ) )})</span>`;
            }
            return formatDollar( Math.round( this.value ) );
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      tooltip: {
        headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
        crosshairs: true,
        shared: true,
        valueDecimals: 0,
        valuePrefix: '$',
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          marker: {
            enabled: false,
          },

        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        name: 'Molly',
        data: [6199, 12780, 19766, 27184, 35059, 43420, 52297, 61721, 71727, 82349, 93627, 105600, 118312, 131808, 146136, 161348, 177499, 194645, 212849, 232176, 252694, 274478, 297606, 322161, 348229, 375906, 405290, 436486, 469606, 504769, 542100, 581735, 623813, 668487, 715917, 766272, 819732, 876490, 936749, 1000724],
        color: '#0069aa',
      }, {
        name: 'Mike',
        data: [5579, 11502, 17790, 24466, 31553, 39078, 47067, 55549, 64554, 74114, 84264, 95040, 100902, 107126, 113733, 120748, 128195, 136102, 144497, 153409, 162871, 172916, 183581, 194904, 206925, 219688, 233238, 247624, 262897, 279111, 296326, 314603, 334007, 354608, 376480, 399700, 424353, 450526, 478313, 507814],
        color: '#39b54a',
      }, {
        name: 'Mary',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5579, 11502, 17790, 24466, 31553, 39078, 47067, 55549, 64554, 74114, 84264, 95040, 106481, 118627, 131523, 145214, 159749, 175180, 191564, 208958, 227425, 247031, 267846, 289945, 313407, 338316, 364761, 392837, 422645],
        color: '#ff0000',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'time_and_money_missing_target',
    options: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      credits: {
        enabled: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      title: {
        text: 'Missed Target',
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px"></span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b><br/>',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },

      legend: {

        itemStyle: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            format: '{point.percentage:.1f} %',
            style: {
              color: '#000000',
              fontSize: '14px',
              textOutline: false,
            },
          },
          showInLegend: true,
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter() {
              return `${Math.round( ( this.percentage * 100 ) / 100 )}%`;
            },
            distance: -50,
            color: '#ffffff',
            style: {
              fontSize: '14px',
            },
          },
        },
      },
      series: [{
        colorByPoint: true,
        data: [{
          name: 'Students that graduate on time',
          y: 41,
          color: '#4472C4',
        }, {
          name: 'Not on time',
          y: 59,
          color: '#ED7D31',
        }],
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'time_and_money_cost_of_missed_target',
    options: {
      chart: {

        type: 'column',
      },
      credits: {
        enabled: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      title: {
        text: 'Cost of Missed Target',
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          enabled: false,
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },

      },
      legend: {
        enabled: false,
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      plotOptions: {
        series: {
          pointWidth: 80,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter() {
              return formatDollar( Math.round( this.y ) );
            },
            style: {
              color: '#000000',
              fontSize: '14px',
            },
          },
        },
      },

      tooltip: {
        headerFormat: '',
        formatter() {
          return `<span>${this.point.name}</span>: <b>${formatDollar( Math.round( this.y ) )}</b><br/>`;
        },
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Extra year of college',
              y: 100000,
              drilldown: 'Apprenticeship Starting Salary',
              color: '#ca2420',
            },
          ],
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'higher_education_continues_to_rise',
    options: {
      chart: {
        type: 'bar',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      title: {
        text: '% of Borrowers Delaying Life Events due to Student Loans',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: ['Getting Married', 'Starting a Family', 'Buying a Car', 'Saving for Retirement', 'Buying a Home'],
        title: {
          text: null,
        },
        labels: {

          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          format: '{value:.0f}%',
          overflow: 'justify',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      tooltip: {
        enabled: false,
        formatter() {
          return '<b>{this.series.name}:{this.y}%</b>';
        },
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            textOutline: false,
            enabled: true,
            color: 'black',
            formatter() {
              return ( this.y !== 0 && this.y !== '0' ) ? `${this.y}%` : '';
            },
            style: {
              textOutline: false,
              color: '#ffffff',
              fontSize: '14px',
            },
          },
        },
        series: {
          dataLabels: {
            align: 'right',
            enabled: true,
            x: 40,
          },
          stacking: 'normal',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true,
        enabled: false,
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      credits: {
        enabled: false,
      },
      series: [{
        name: 'Getting Married',
        data: [30, 0, 0, 0, 0],

        color: '#0069aa',
      }, {
        name: 'Starting a Family',
        data: [0, 40, 0, 0, 0],

        color: '#0069aa',
      }, {
        name: 'Buying a Car',
        data: [0, 0, 60, 0, 0],
        color: '#0069aa',
      }, {
        name: 'Saving for Retirement',
        data: [0, 0, 0, 70, 0],
        color: '#0069aa',
      }, {
        name: 'Buying a Home',
        data: [0, 0, 0, 0, 75],
        color: '#0069aa',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'retirement_savings_are_way_off_track',
    options: {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: ['$0', 'Less than $10k', '$10,000 to $49,999', '$50,000 to $99,999', '$100,000 to $499,999', '$500,000 or more'],
        type: 'category',
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          formatter() {
            if ( this.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( this.value ) )})</span>`;
            }
            return `${this.value}%`;
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },

      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            textOutline: false,
            enabled: true,
            color: 'black',
            formatter() {
              return ( this.y !== 0 && this.y !== '0' ) ? `${parseFloat( this.y ).toFixed( 1 )}%` : '';
            },
            style: {
              textOutline: false,
              color: '#ffffff',
              fontSize: '14px',
            },
          },
        },
        series: {
          dataLabels: {
            align: 'center',
            verticalAlign: 'top',
            enabled: true,
            y: -28,
          },
          stacking: 'normal',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      tooltip: {
        enabled: false,
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',

        style: {
          color: '#000000',
          fontSize: '14px',
        },

      },
      series: [{
        name: '$0',
        data: [46, 0, 0, 0, 0, 0],

        color: '#0069aa',
      }, {
        name: 'Less than $10k',
        data: [0, 19, 0, 0, 0, 0],

        color: '#0069aa',
      }, {
        name: '$10,000 to $49,999',
        data: [0, 0, 12, 0, 0, 0],
        color: '#0069aa',
      }, {
        name: '$50,000 to $99,999',
        data: [0, 0, 0, 7, 0, 0],
        color: '#0069aa',
      }, {
        name: '$100,000 to $499,999',
        data: [0, 0, 0, 0, 12, 0],
        color: '#0069aa',
      }, {
        name: '$500,000 or more',
        data: [0, 0, 0, 0, 0, 4],
        color: '#0069aa',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'median_net_worth_by_age_group',
    options: {
      chart: {
        type: 'column',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 32,
          width: 40,
          symbolSize: 20,
          symbolX: 22,
          symbolY: 15,
          symbolStrokeWidth: 2,
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                this.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                this.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                this.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                this.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: ['35 or younger', '35 to 44', '45 to 54', '55 to 64', '65 to 74', '75 and older'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {

          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },

      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            textOutline: false,
            enabled: true,
            color: 'black',
            formatter() {
              return ( this.y !== 0 && this.y !== '0' ) ? `${formatDollar( Math.round( this.y ) )}` : '';
            },
            style: {
              textOutline: false,
              color: '#ffffff',
              fontSize: '14px',
            },
          },
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            align: 'center',
            verticalAlign: 'top',
            enabled: true,
            y: -32,
            style: {
              color: '#000000',
              fontSize: '14px',
            },

          },
        },
      },

      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: '35 or younger',
          data: [11100, 0, 0, 0, 0, 0],
          color: '#0069aa',
        },
        {
          name: '35 to 44',
          data: [0, 59800, 0, 0, 0, 0],
          color: '#0069aa',
        },
        {
          name: '45 to 54',
          data: [0, 0, 124200, 0, 0, 0],
          color: '#0069aa',
        },
        {
          name: '55 to 64',
          data: [0, 0, 0, 187300, 0, 0],
          color: '#0069aa',
        },
        {
          name: '65 to 74',
          data: [0, 0, 0, 0, 224100, 0],
          color: '#0069aa',
        },
        {
          name: '75 and older',
          data: [0, 0, 0, 0, 0, 264800],
          color: '#0069aa',
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'industry_pays_for_skills_and_knowledge',
    options: {
      chart: {
        type: 'column',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Median Annual Earnings by Education Level',
      },
      xAxis: {
        categories: ['Less than a high school diploma', 'High school diploma', 'Some college, no degree', "Associate's degree", "Bachelor's degree", "Master's degree", 'Doctoral degree', 'Professional degree'],
        type: 'category',
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
            textOutline: false,
          },
        },
      },
      yAxis: {
        max: 125000,
        title: {
          text: '',
        },
        labels: {
          enabled: false,
          style: {
            color: '#000000',
            fontSize: '14px',
            textOutline: false,
          },
        },

      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            textOutline: false,
            enabled: true,
            color: 'black',
            formatter() {
              return ( this.y !== 0 && this.y !== '0' ) ? `${formatDollar( Math.round( this.y ) )}` : '';
            },
            style: {
              textOutline: false,
              color: '#ffffff',
              fontSize: '14px',
            },
          },
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            align: 'center',
            verticalAlign: 'top',
            enabled: true,
            y: -32,
            // formatter() {
            //   return formatDollar( Math.round( this.y ) );
            // },
            style: {
              color: '#000000',
              fontSize: '14px',
            },
          },

        },
      },

      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: 'Less than a high school diploma',
          data: [26000, 0, 0, 0, 0, 0, 0, 0],
          drilldown: 'Less than a high school diploma',
          color: '#0069aa',
        },
        {
          name: 'High school diploma',
          data: [0, 35600, 0, 0, 0, 0, 0, 0],
          drilldown: 'High school diploma',
          color: '#0069aa',
        },
        {
          name: 'Some college, no degree',
          data: [0, 0, 35700, 0, 0, 0, 0, 0],
          drilldown: 'Some college, no degree',
          color: '#0069aa',
        },
        {
          name: "Associate's degree",
          data: [0, 0, 0, 41800, 0, 0, 0, 0],
          drilldown: "Associate's degree",
          color: '#0069aa',
        },
        {
          name: "Bachelor's degree",
          data: [0, 0, 0, 0, 58650, 0, 0, 0],
          drilldown: "Bachelor's degree",
          color: '#0069aa',
        },
        {
          name: "Master's degree",
          data: [0, 0, 0, 0, 0, 70050, 0, 0],
          drilldown: "Master's degree",
          color: '#0069aa',
        },
        {
          name: 'Doctoral degree',
          data: [0, 0, 0, 0, 0, 0, 87150, 0],
          drilldown: 'Doctoral degree',
          color: '#0069aa',
        },
        {
          name: 'Professional degree',
          data: [0, 0, 0, 0, 0, 0, 0, 91800],
          drilldown: 'Professional degree',
          color: '#0069aa',
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'average_salary',
    options: {
      chart: {
        type: 'column',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Average Salary',
      },
      xAxis: {

        type: 'category',
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        min: 0,
        tickInterval: 5000,
        max: 55000,
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          enabled: false,
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },

      },
      legend: {
        enabled: false,
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      plotOptions: {
        series: {
          pointWidth: 80,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter() {
              return formatDollar( Math.round( this.y ) );
            },
            style: {
              color: '#000000',
              fontSize: '14px',
            },
          },
        },
      },

      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Apprenticeship Starting Salary',
              y: 30000,
              drilldown: 'Apprenticeship Starting Salary',
              color: '#0069aa',
            },
            {
              name: 'Completed Apprenticeship Salary',
              y: 50000,
              drilldown: 'Completed Apprenticeship Salary',
              color: '#0069aa',
            },
          ],
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
  {
    id: 'your_career_could_be_at_risk',
    options: {
      chart: {
        type: 'column',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '% of the Labor Force in Agriculture',
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          formatter() {
            if ( this.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( this.value ) )})</span>`;
            }
            return `${this.value}%`;
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },

      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',

            style: {
              color: '#000000',
              fontSize: '14px',
            },

          },
        },
      },

      tooltip: {
        enabled: false,
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',

        style: {
          color: '#000000',
          fontSize: '14px',
        },

      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: [
            {
              name: '1900',
              y: 41,
              color: '#0069aa',
            },
            {
              name: '1930',
              y: 22,
              color: '#0069aa',
            },
            {
              name: '1970',
              y: 4,
              color: '#0069aa',
            },
            {
              name: '2000',
              y: 2,
              color: '#0069aa',
            },
          ],
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },

    },
  }, {
    id: 'the_skills_gap_presents_new_opportunities',
    options: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px"></span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b><br/>',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },

      legend: {
        align: 'right',
        verticalAlign: 'middle',
        floating: true,
        width: 200,
        itemStyle: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            format: '{point.percentage:.1f} %',
            style: {
              color: '#000000',
              fontSize: '14px',
            },
          },
          showInLegend: true,
        },
      },
      series: [{
        colorByPoint: true,
        data: [{
          name: 'Manufacturing',
          y: 30,
          color: '#4472C4',
        }, {
          name: 'Technology',
          y: 21,
          color: '#ED7D31',
        }, {
          name: 'Professional Services',
          y: 19,
          color: '#A5A5A5',
        }, {
          name: 'Engineering',
          y: 12,
          color: '#FFC000',
        }, {
          name: 'Healthcare',
          y: 11,
          color: '#5B9BD5',
        }, {
          name: 'Construction',
          y: 4,
          color: '#70AD47',
        }, {
          name: 'Leisure & Hospitality',
          y: 2,
          color: '#264478',
        }, {
          name: 'Finance',
          y: 2,
          color: '#9E480E',
        }],
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    },
  },
];

export default data;
