import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-car/styles';

const BuyingCarTableBottom = ( props ) => {
  const { classes, handleBuyingCarObject, activeStep } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState( false );
  const [otherExpenses, setOtherExpenses] = React.useState( false );
  const [totalExpenses, setTotalExpenses] = React.useState( false );
  const handleExpandLiving = () => {
    setExpandLivingExpenses( !expandLivingExpenses );
  };

  const handleOtherExpenses = () => {
    setOtherExpenses( !otherExpenses );
  };

  const handleTotalExpenses = () => {
    setTotalExpenses( !totalExpenses );
  };

  const totalIncomeInYear = ( year ) => {
    const incomeData = moduleServices.incomeStatementCompleteData();
    if ( typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[year] !== 'undefined' && incomeData[year] !== '' && typeof incomeData[year]['Total Income'] !== 'undefined' && incomeData[year]['Total Income'] !== '' ) {
      return incomeData[year]['Total Income'];
    }
    return 0;
  };

  const totalIncomePercentage = ( year ) => {
    const totalIncomeInYr = totalIncomeInYear( year );
    if ( totalIncomeInYr > 0 ) {
      const expenses = moduleServices.totalAnnualExpensesFirstCar() + ( moduleServices.monthlyPayment() * 12 );
      return ( expenses / totalIncomeInYr ) * 100;
    }
    return 0;
  };

  React.useEffect( () => {
    if ( activeStep === 12 || activeStep === 26 ) {
      setExpandLivingExpenses( true );
      setOtherExpenses( true );
      setTotalExpenses( true );
    }
  }, [activeStep] );

  return (
    <div className={ classes.bottomTables }>
      <div className={ classes.leftTable }>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Purchase Year</th>
              <th>{handleBuyingCarObject.purchase_year}</th>
            </tr>
          </thead>
          <tbody>

            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Base Price</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisPurchasePrice !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisPurchasePrice } prefix={ moduleServices.module12Data.investmentAnalysisPurchasePrice >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisPurchasePrice < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Destination Charges</td>
                <td>
                  {moduleServices.module12Data.destinationChargesSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.destinationChargesSummary } prefix={ moduleServices.module12Data.destinationChargesSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.destinationChargesSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Options</td>
                <td>
                  {moduleServices.module12Data.getCarOptions !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.getCarOptions } prefix={ moduleServices.module12Data.getCarOptions >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.getCarOptions < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Discount</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisDiscount !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisDiscount } prefix={ moduleServices.module12Data.investmentAnalysisDiscount >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisDiscount < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Rebate</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisRebate !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisRebate } prefix={ moduleServices.module12Data.investmentAnalysisRebate >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisRebate < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {expandLivingExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Trade-In Value</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisTradeIn !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisTradeIn } prefix={ moduleServices.module12Data.investmentAnalysisTradeIn >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisTradeIn < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleExpandLiving }>
              <td>
                <span>
                  {( !expandLivingExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( expandLivingExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Net Purchase Price</td>
              <td>
                {moduleServices.module12Data.investmentAnalysisTotalPurchasePrice !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisTotalPurchasePrice } prefix={ moduleServices.module12Data.investmentAnalysisTotalPurchasePrice >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisTotalPurchasePrice < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>

            {otherExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Down Payment</td>
                <td>
                  {moduleServices.module12Data.carDownPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.carDownPayment } prefix={ moduleServices.module12Data.carDownPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.carDownPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Sales Tax</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisSalesTax !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisSalesTax } prefix={ moduleServices.module12Data.investmentAnalysisSalesTax >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisSalesTax < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Registration & Doc Fees</td>
                <td>
                  {moduleServices.module12Data.investmentAnalysisRegistrationFees !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.investmentAnalysisRegistrationFees } prefix={ moduleServices.module12Data.investmentAnalysisRegistrationFees >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.investmentAnalysisRegistrationFees < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {otherExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Origination Fees</td>
                <td>
                  {moduleServices.module12Data.carLoanPoints !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.carLoanPoints } prefix={ moduleServices.module12Data.carLoanPoints >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.carLoanPoints < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleOtherExpenses }>
              <td>
                <span>
                  {( !otherExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( otherExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Total Cash Needed at Purchase</td>
              <td>
                {moduleServices.module12Data.cashNeededAtPurchase !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.cashNeededAtPurchase } prefix={ moduleServices.module12Data.cashNeededAtPurchase >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.cashNeededAtPurchase < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Cash Balance at {' '}
                {handleBuyingCarObject.purchase_year - 1}
                {' '}
                Year End
              </td>
              <td>
                {moduleServices.module12Data.totalCashAvailable !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalCashAvailable } prefix={ moduleServices.module12Data.totalCashAvailable >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalCashAvailable < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Total Investments at {' '}
                {handleBuyingCarObject.purchase_year - 1}
                {' '}
                Year End
              </td>
              <td>
                {moduleServices.module12Data.totalLiquid !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalLiquid } prefix={ moduleServices.module12Data.totalLiquid >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalLiquid < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={ classNames( classes.leftTable, classes.rightTable ) }>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Monthly Expenses</th>
              <th>Monthly</th>
              <th>Annual</th>
            </tr>
          </thead>
          <tbody>
            {totalExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Loan Payment (Principal & Interest)</td>
                <td>
                  {moduleServices.module12Data.monthlyPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.monthlyPayment } prefix={ moduleServices.module12Data.monthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.monthlyPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.monthlyPayment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.monthlyPayment * 12 } prefix={ moduleServices.module12Data.monthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.monthlyPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (
              <tr className={ classes.hoveredRow }>
                <td />
                <td>Maintenance</td>
                <td>
                  {moduleServices.module12Data.firstCarMaintenanceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarMaintenanceCalcs / 12 } prefix={ moduleServices.module12Data.firstCarMaintenanceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarMaintenanceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.firstCarMaintenanceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarMaintenanceCalcs } prefix={ moduleServices.module12Data.firstCarMaintenanceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarMaintenanceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Car Insurance</td>
                <td>
                  {moduleServices.module12Data.firstCarInsuranceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarInsuranceCalcs / 12 } prefix={ moduleServices.module12Data.firstCarInsuranceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarInsuranceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.firstCarInsuranceCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarInsuranceCalcs } prefix={ moduleServices.module12Data.firstCarInsuranceCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarInsuranceCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            {totalExpenses && (

              <tr className={ classes.hoveredRow }>
                <td />
                <td>Gas</td>
                <td>
                  {moduleServices.module12Data.firstCarGasCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarGasCalcs / 12 } prefix={ moduleServices.module12Data.firstCarGasCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarGasCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.firstCarGasCalcs !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.firstCarGasCalcs } prefix={ moduleServices.module12Data.firstCarGasCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.firstCarGasCalcs < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            )}
            <tr className={ ( activeStep === 12 || activeStep === 26 ) ? classNames( classes.clickNone, classes.boldText ) : classes.boldText } aria-hidden="true" onClick={ handleTotalExpenses }>
              <td>
                <span>
                  {( !totalExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                  {( totalExpenses && ( activeStep !== 12 && activeStep !== 26 ) ) && ( <i className="fa fa-minus" aria-hidden="true" /> )}
                </span>
              </td>
              <td>Total Expenses</td>
              <td>
                {moduleServices.module12Data.totalMonthlyExpensesFirstCar !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalMonthlyExpensesFirstCar } prefix={ moduleServices.module12Data.totalMonthlyExpensesFirstCar >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalMonthlyExpensesFirstCar < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {moduleServices.module12Data.totalAnnualExpensesFirstCar !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.totalAnnualExpensesFirstCar } prefix={ moduleServices.module12Data.totalAnnualExpensesFirstCar >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.totalAnnualExpensesFirstCar < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classes.blueRow }>
              <td />
              <td className={ classes.leftPadding }><i>% of Total Income</i></td>
              <td>
                {totalIncomePercentage( handleBuyingCarObject.purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) } prefix={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) < 0 ? '%)' : '%' } />
                ) : ( '0.00%' )}
              </td>
              <td>
                {totalIncomePercentage( handleBuyingCarObject.purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) } prefix={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentage( handleBuyingCarObject.purchase_year ) < 0 ? '%)' : '%' } />
                ) : ( '0.00%' )}
              </td>
            </tr>
            <tr className={ classNames( classes.blueRow, classes.boldText ) }>
              <td />
              <td>
                Total Income in Year {''}
                {handleBuyingCarObject.purchase_year}
              </td>
              <td>
                {totalIncomeInYear( handleBuyingCarObject.purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) /12 } prefix={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {totalIncomeInYear( handleBuyingCarObject.purchase_year ) !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) } prefix={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.purchase_year ) < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

BuyingCarTableBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default withStyles( styles )( BuyingCarTableBottom );
