import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Pageloader from '../ui/pageloader';
import dummyProfile from '../../assets/img/addressbook/user.png';
import styles from './styles';

const ADD_MEMBER = loader('../../graphql/schema/address-book/add-member.graphql');
const REMOVE_MEMBER = loader('../../graphql/schema/address-book/remove-member.graphql');
const GROUP_MEMBER_LIST = loader('../../graphql/schema/address-book/getAddressBookGroupMember.graphql');
const SEND_FRIEND_INVITATION = loader('../../graphql/schema/address-book/sendFriendInvitation.graphql');
// const LIST_OF_STATES = loader( '../../graphql/schema/auth/list-of-states.graphql' );

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const UserDetails = (props) => {
  const {
    classes, displayUser, setDisplayUser, displayGroupName, tabs, groupIDValue, addressBookResult, groupContactList, searchContactList
  } = props;

  const [addMember, setAddMember] = useState(false);
  const [removeMember, setRemoveMember] = useState(false);
  const [groupID, setGroupID] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectErrorMessage, setSelectErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [successSendRequest, setSuccessSendRequest] = useState(false);
  const [sendRequestPopup, setSendRequestPopup] = useState(false);
  const status = addressBookResult.map((res) => {
    if (res.user.id === displayUser.id) {
      return res.status;
    }
    return null;
  }).filter((el) => el != null);

  const [addMemberName, { loading }] = useMutation(ADD_MEMBER, {
    onCompleted() {
      setAddMember(true);
      setSuccessMessage('User added successfully into your group(s).');
      setSelectErrorMessage('');
      setErrorMessage('');
      groupContactList();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setErrorMessage(formatedErrors);
    },
  });

  const [removeMemberName] = useMutation(REMOVE_MEMBER, {
    onCompleted() {
      setRemoveMember(false);
      setDisplayUser(null);
      setSelectErrorMessage('');
      setErrorMessage('');
    },
    refetchQueries: [
      {
        query: GROUP_MEMBER_LIST,
        variables: {
          data: groupIDValue,
        },
        fetchPolicy: 'network-only',
      },
    ],
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setErrorMessage(formatedErrors);
    },
  });

  const [sendFriendInvitation] = useMutation(SEND_FRIEND_INVITATION, {
    onCompleted() {
      setSendRequestPopup(false);
      setSuccessSendRequest(true);
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setErrorMessage(formatedErrors);
    },
  });

  // const { data: listOfStates } = useQuery( LIST_OF_STATES );

  // const stateName = listOfStates && listOfStates.States.map( ( info ) => {
  //   if ( info.id === displayUser.state ) {
  //     return info;
  //   }
  //   return null;
  // } ).filter( ( el ) => el != null );

  const handleGroupNameSelect = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setGroupID(value);
  };

  const addMemberGroup = () => {
    setAddMember(true);
  };

  const removeMemberGroup = () => {
    setRemoveMember(true);
  };

  const handleAddMember = () => {
    if (groupID.length !== 0) {
      addMemberName({
        variables: {
          data: {
            member_id: displayUser.id,
            group_id: groupID,
          },
        },
      });
    } else {
      setSelectErrorMessage('Please select group');
    }
  };

  const handleRemoveMember = () => {
    removeMemberName({
      variables: {
        data: {
          member_id: displayUser.id,
          group_id: groupIDValue,
        },
      },
    });
  };

  const sendRequest = () => {
    setSendRequestPopup(true);
  };

  const handleSendRequestPopupClose = () => {
    setSendRequestPopup(false);
  };

  const handleAddMemberPopupClose = () => {
    setAddMember(false);
  };

  const handleRemoveMemberPopupClose = () => {
    setRemoveMember(false);
  };

  const handleSuccessSendRequestPopup = () => {
    setSuccessSendRequest(false);
    searchContactList();
  };

  const handleSendRequest = () => {
    const name = `${displayUser.user_fname} ${displayUser.user_lname}`;
    sendFriendInvitation({
      variables: {
        data: {
          member_id: displayUser.id,
          email: displayUser.email,
          friend_name: name,
        },
      },
    });
  };

  React.useEffect(() => {
    if (successSendRequest === true) {
      setTimeout(() => {
        setSuccessSendRequest(false);
      }, 10000);
    }
  }, [successSendRequest]);

  return (
    <React.Fragment>
      {loading && <Pageloader loading={loading} />}
      <Grid item md={6} lg={5}>
        <div className={classes.userDescription}>
          <div className={classes.profileTopBlock}>
            <div className={classes.userProfile}>
              <div className={classes.profileImageBlock}>
                <div className={classes.profileImage}>
                  <img src={(displayUser.profile_image && IMAGE_PATH + displayUser.profile_image) || dummyProfile} alt="profile" />
                </div>
                <div className={classes.profileContent}>
                  {displayUser && (
                    <p>
                      {displayUser.user_fname}
                      {' '}
                      {displayUser.user_lname}
                    </p>
                  )}
                  <span>Software Developer</span>
                </div>
              </div>
            </div>
          </div>
          <ul className={classes.userDetails}>
            <li>
              <span>Email:</span>
              <span>{displayUser && displayUser.email}</span>
            </li>
            <li>
              <span>Street Address:</span>
              <span>{displayUser.streetaddress ? displayUser.streetaddress : 'N/A'}</span>
            </li>

            <li>
              <span>State:</span>
              <span>{displayUser.state_name ? displayUser.state_name : 'N/A'}</span>
            </li>

            <li>
              <span>City:</span>
              <span>{displayUser.city ? displayUser.city : 'N/A'}</span>
            </li>

            <li>
              <span>Zip Code:</span>
              <span>{displayUser.zip_code ? displayUser.zip_code : 'N/A'}</span>
            </li>

          </ul>
          {
            tabs === 'People'
            && (
              <>
                {displayUser.invitation_status === 'A' ?
                  <div className={classes.addAddressBook}>
                    <span aria-hidden="true" className={classes.addAddressBtn} onClick={addMemberGroup}>
                      <i className="fa fa-plus" aria-hidden="true" data-for="addMemberThree" data-tip="Add User to Group" />
                    </span>
                    <ReactTooltip id="addMemberThree" place="top" type="info" effect="solid" />
                  </div>
                  :
                  ''
                }
                {displayUser.invitation_status === "P" ?
                  <div className={`${classes.addAddressBookBtn} ${classes.AddAdrresPoprightBottom}`}>
                    <Button className={classes.requestPending}>Request Pending</Button>
                  </div>
                  :
                  ''
                }
                {displayUser.invitation_status === "" ?
                  <div className={`${classes.addAddressBookBtn} ${classes.AddAdrresPoprightBottom}`}>
                    <span aria-hidden="true" className={classes.addAddressBtnNew} onClick={sendRequest}>
                      <i className="fa fa-plus" aria-hidden="true" data-for="addMemberTwo" data-tip="Send Request to add to My Contacts" />
                    </span>
                    <ReactTooltip id="addMemberTwo" place="top" type="info" effect="solid" />
                  </div>
                  :
                  ''
                }
              </>
            )
          }
          {
            tabs === 'My Contact'
            && (
              <div className={classes.addAddressBook}>
                <span aria-hidden="true" className={classes.addAddressBtn} onClick={addMemberGroup}>
                  <i className="fa fa-plus" aria-hidden="true" data-for="addMember" data-tip="Add User to Group" />
                </span>
              </div>
            )
          }
          {
            tabs === 'group_name'
            && (
              <div className={classes.addAddressBookNew}>
                <span aria-hidden="true" onClick={addMemberGroup}>
                  <i className="fa fa-plus" aria-hidden="true" data-for="addMember" data-tip="Add User to Group" />

                </span>
                <span aria-hidden="true" onClick={removeMemberGroup}>
                  <i className="fa fa-minus" aria-hidden="true" data-for="addMember" data-tip="Remove User from Group" />
                </span>
              </div>
            )
          }
          {/* <div className={ classes.addAddressBook }>
            <span aria-hidden="true" className={ classes.addAddressBtn } onClick={ addMemberGroup }>
              <i className="fa fa-plus" aria-hidden="true" data-for="addMember" data-tip="Add User to Group" />
            </span>
          </div> */}
        </div>
      </Grid>
      <Dialog
        onClose={handleAddMemberPopupClose}
        aria-labelledby="simple-dialog-title"
        open={addMember}
        className={classes.addMember}
      >
        <div className={classes.popupHeader}>
          <h3>Add User</h3>
        </div>
        {successMessage === ''
          ? (
            <React.Fragment>
              <div className={`${classes.popupContent} ${classes.popupAddedUser}`}>
                <ul>
                  {errorMessage && <span>{errorMessage}</span>}
                  <li>
                    <font>Fullname:</font>
                    <span>
                      {displayUser.user_fname && displayUser.user_fname}
                      {' '}
                      {displayUser.user_lname && displayUser.user_lname}
                    </span>
                  </li>

                  <li>
                    <font>Email:</font>
                    <span>{displayUser.email ? displayUser.email : 'N/A'}</span>
                  </li>
                  <li>
                    <div className={classes.formGroupPopup}>
                      <font>Select Group:</font>
                      <FormControl className={classes.formControl}>
                        <Select
                          multiple
                          native
                          value={groupID}
                          onChange={handleGroupNameSelect}
                          inputProps={{
                            id: 'select-multiple-native',
                          }}
                        >
                          {displayGroupName.map((name) => (
                            <option key={name.id} value={name.id}>
                              {name.group_name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  </li>
                  {selectErrorMessage && <span className={classes.errorText}>{selectErrorMessage}</span>}
                </ul>

              </div>

              <div className={classes.popupFooter}>
                <Button className={classes.saveButton} onClick={handleAddMember}>Yes</Button>
                <Button onClick={handleAddMemberPopupClose}>No</Button>
              </div>
            </React.Fragment>
          )
          : (
            <React.Fragment>
              {successMessage && <span className={classes.successMsg}>{successMessage}</span>}
              <div className={classes.popupFooter}>
                <Button onClick={handleAddMemberPopupClose}>Close</Button>
              </div>
            </React.Fragment>
          )}
      </Dialog>
      <Dialog
        onClose={handleRemoveMemberPopupClose}
        aria-labelledby="simple-dialog-title"
        open={removeMember}
        className={classes.addMember}
      >
        <div className={classes.popupHeader}>
          <div aria-hidden="true">
            {/* <i className="la la-times" /> */}
            <h4> Remove User</h4>
          </div>
        </div>

        <div className={classes.popupContent}>
          <div className={classes.popupInputNew}>
            Are you sure you want to remove this user from the selected group?
          </div>
        </div>
        <div className={classes.popupFooter}>
          <Button className={classes.saveButton} onClick={handleRemoveMember}>Yes</Button>
          <Button onClick={handleRemoveMemberPopupClose}>No</Button>
        </div>
      </Dialog>

      <Dialog
        onClose={handleSendRequestPopupClose}
        aria-labelledby="simple-dialog-title"
        open={sendRequestPopup}
        className={classes.addMember}
      >
        <div className={classes.popupHeader}>
          <div aria-hidden="true">
            <i className="la la-times" onClick={handleSendRequestPopupClose} />
            <h4> Send Request</h4>
          </div>
        </div>

        <div className={classes.popupContent}>
          <div className={classes.popupInputNew}>
            Would you like to send a request to add this user to your contacts?
          </div>
        </div>
        <div className={classes.popupFooter}>
          <Button className={classes.saveButton} onClick={handleSendRequest}>Yes</Button>
          <Button onClick={handleSendRequestPopupClose}>No</Button>
        </div>
      </Dialog>

      <Dialog
        open={successSendRequest}
        onClose={handleSuccessSendRequestPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={handleSuccessSendRequestPopup}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupCheck}>
              <i className="las la-check" />
            </span>
            <p>Invitation sent!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>
      
      <ReactTooltip id="addMember" place="top" type="info" effect="solid" className={classes.addMemberNew} />
    </React.Fragment >
  );
};

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  displayUser: PropTypes.object.isRequired,
  tabs: PropTypes.string.isRequired,
  groupIDValue: PropTypes.string.isRequired,
  setDisplayUser: PropTypes.func.isRequired,
  groupContactList: PropTypes.func.isRequired,
  searchContactList: PropTypes.func.isRequired,
  displayGroupName: PropTypes.arrayOf(PropTypes.object).isRequired,
  addressBookResult: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(UserDetails);
