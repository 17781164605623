/* eslint-disable no-unused-vars */
// converter is not there so removed ---- mahananda

function estimatedIncomeTaxes( module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module15Data, module17Data, databaseData, incomeTaxData ) {
  // Year
  if ( typeof module1Data !== 'undefined' && typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' ) {
    const year = parseInt( module1Data.start_year, 10 );
  }
  // Percentage Array
  let percentageArray1 = [];
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.percentageArray !== 'undefined' && incomeTaxData.percentageArray !== '' ) {
    percentageArray1 = incomeTaxData.percentageArray;
  }

  // Single Range Arrays
  let singleRangeArray1 = [];
  let singleRange2Array1 = [];
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRangeArray !== 'undefined' && incomeTaxData.singleRangeArray !== '' ) {
    singleRangeArray1 = incomeTaxData.singleRangeArray;
  }
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRange2Array !== 'undefined' && incomeTaxData.singleRange2Array !== '' ) {
    singleRange2Array1 = incomeTaxData.singleRange2Array;
  }

  // Married Range Arrays
  let marriedRangeArray1 = [];
  let marriedRange2Array1 = [];
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.marriedRangeArray !== 'undefined' && incomeTaxData.marriedRangeArray !== '' ) {
    marriedRangeArray1 = incomeTaxData.marriedRangeArray;
  }
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.marriedRange2Array !== 'undefined' && incomeTaxData.marriedRange2Array !== '' ) {
    marriedRange2Array1 = incomeTaxData.marriedRange2Array;
  }
  const singleDataArray = percentageArray1 && percentageArray1.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = singleRangeArray1[index];
    returnData.Range2 = singleRange2Array1[index];
    if ( singleRange2Array1[index] > 0 ) {
      returnData.Difference = singleRange2Array1[index] - singleRangeArray1[index];
      returnData.Tax = returnData.Difference * ( percentage / 100 );
    } else {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }
    return returnData;
  } );

  const marriedDataArray = percentageArray1 && percentageArray1.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = marriedRangeArray1[index];
    returnData.Range2 = marriedRange2Array1[index];
    if ( index === 0 ) {
      returnData.Difference = 9052;
      returnData.Tax = 908;
    } else if ( index > 1 && index < 6 ) {
      returnData.Difference = marriedRange2Array1[index] - marriedRangeArray1[index];
      returnData.Tax = returnData.Difference * ( percentage / 100 );
    } else if ( index === 6 ) {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }
    return returnData;
  } );

  function singleCalculation( income, condition, module, value ) {
    // Total Taxes
    let totalTaxes = 0;

    const singleIteration1Array = percentageArray1 && percentageArray1.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= singleDataArray[index].Range ) {
        range = singleDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( index === 6 ) {
        if ( income >= singleDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else if ( income >= singleDataArray[index].Range2 ) {
        range2 = singleDataArray[index].Range2;
      } else if ( income <= singleDataArray[index].Range2
            && income >= singleDataArray[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      totalTaxes += returnData.Tax;
      return returnData;
    } );

    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / value ) * 100;
    }

    // State & Local
    const stateLocal = 5;

    // Social Security
    let socialSecurity = 7.65;
    if ( typeof condition !== 'undefined' && condition === 'career'
      && typeof module !== 'undefined' && module !== '' ) {
      switch ( module ) {
        case 'module4':
          if ( typeof module4Data !== 'undefined'
            && typeof module4Data.self_employment !== 'undefined'
            && module4Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module5':
          if ( typeof module5Data !== 'undefined'
            && typeof module5Data.self_employment !== 'undefined'
            && module5Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module8':
          if ( typeof module8Data !== 'undefined'
            && typeof module8Data.self_employment !== 'undefined'
            && module8Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module17':
          if ( typeof module17Data !== 'undefined'
            && typeof module17Data.self_employment !== 'undefined'
            && module17Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        default:
        /* code */
          break;
      }
    }

    // Total Tax
    const totalTax = parseFloat( federalIncomeTax + stateLocal + socialSecurity ).toFixed( 7 );

    return totalTax;
  }

  function marriedCalculation( income, condition, module, value ) {
    // Total Taxes
    let totalTaxes = 0;

    const marriedIteration1Array = percentageArray1 && percentageArray1.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= marriedDataArray[index].Range ) {
        range = marriedDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( index === 6 ) {
        if ( income >= marriedDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else if ( income >= marriedDataArray[index].Range2 ) {
        range2 = marriedDataArray[index].Range2;
      } else if ( income <= marriedDataArray[index].Range2
            && income >= marriedDataArray[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      totalTaxes += returnData.Tax;
      return returnData;
    } );

    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / value ) * 100;
    }

    // State & Local
    const stateLocal = 5;

    // Social Security
    let socialSecurity = 7.65;
    if ( typeof condition !== 'undefined' && condition === 'career'
      && typeof module !== 'undefined' && module !== '' ) {
      switch ( module ) {
        case 'module4':
          if ( typeof module4Data !== 'undefined'
            && typeof module4Data.self_employment !== 'undefined'
            && module4Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module5':
          if ( typeof module5Data !== 'undefined'
            && typeof module5Data.self_employment !== 'undefined'
            && module5Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module8':
          if ( typeof module8Data !== 'undefined'
            && typeof module8Data.self_employment !== 'undefined'
            && module8Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        case 'module17':
          if ( typeof module17Data !== 'undefined'
            && typeof module17Data.self_employment !== 'undefined'
            && module17Data.self_employment === 'Yes' ) {
            socialSecurity = 7.65 * 2;
          } else {
            socialSecurity = 7.65;
          }
          break;
        default:
        /* code */
          break;
      }
    }

    // Total Tax
    const totalTax = parseFloat( federalIncomeTax + stateLocal + socialSecurity ).toFixed( 7 );

    return totalTax;
  }

  /** Higher Education * */
  // Single Iteration1
  function singleIteration1() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module2Data.yearly_income !== 'undefined' && module2Data.yearly_income !== '' ) {
      value = module2Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'higher-education', 'module2', value );
  }

  // Married iteration1
  function marriedIteration1() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module2Data.yearly_income !== 'undefined' && module2Data.yearly_income !== '' ) {
      value = module2Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'higher-education', 'module2', value );
  }

  /** End of Higher Education * */

  /** Career Path * */
  // Single Iteration2

  function singleIteration2() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module4Data.yearly_income !== 'undefined' && module4Data.yearly_income !== ''
      && typeof module4Data.bonus_or_tips_or_commission !== 'undefined' && module4Data.bonus_or_tips_or_commission !== ''
      && typeof module4Data.second_yearly_income !== 'undefined' && module4Data.second_yearly_income !== '' ) {
      value = module4Data.yearly_income
        + module4Data.bonus_or_tips_or_commission
        + module4Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'career', 'module4', value );
  }

  // Married iteration2
  function marriedIteration2() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module4Data.yearly_income !== 'undefined' && module4Data.yearly_income !== ''
      && typeof module4Data.bonus_or_tips_or_commission !== 'undefined' && module4Data.bonus_or_tips_or_commission !== ''
      && typeof module4Data.second_yearly_income !== 'undefined' && module4Data.second_yearly_income !== '' ) {
      value = module4Data.yearly_income
        + module4Data.bonus_or_tips_or_commission
        + module4Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'career', 'module4', value );
  }

  /** End of Career Path * */

  /** Career Advancement * */
  // Single Iteration3

  function singleIteration3() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module5Data.yearly_income !== 'undefined' && module5Data.yearly_income !== ''
      && typeof module5Data.bonus_or_tips_or_commission !== 'undefined' && module5Data.bonus_or_tips_or_commission !== ''
      && typeof module5Data.second_yearly_income !== 'undefined' && module5Data.second_yearly_income !== '' ) {
      value = module5Data.yearly_income
        + module5Data.bonus_or_tips_or_commission
        + module5Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'career', 'module5', value );
  }

  // Married iteration3
  function marriedIteration3() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module5Data.yearly_income !== 'undefined' && module5Data.yearly_income !== ''
      && typeof module5Data.bonus_or_tips_or_commission !== 'undefined' && module5Data.bonus_or_tips_or_commission !== ''
      && typeof module5Data.second_yearly_income !== 'undefined' && module5Data.second_yearly_income !== '' ) {
      value = module5Data.yearly_income
        + module5Data.bonus_or_tips_or_commission
        + module5Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'career', 'module5', value );
  }

  /** End of Career Advancement * */

  /** Additional Career Advancement * */
  // Single Iteration4

  function singleIteration4() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module8Data.yearly_income !== 'undefined' && module8Data.yearly_income !== ''
      && typeof module8Data.bonus_or_tips_or_commission !== 'undefined' && module8Data.bonus_or_tips_or_commission !== ''
      && typeof module8Data.second_yearly_income !== 'undefined' && module8Data.second_yearly_income !== '' ) {
      value = module8Data.yearly_income
        + module8Data.bonus_or_tips_or_commission
        + module8Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'career', 'module8', value );
  }

  // Married iteration4
  function marriedIteration4() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module8Data.yearly_income !== 'undefined' && module8Data.yearly_income !== ''
      && typeof module8Data.bonus_or_tips_or_commission !== 'undefined' && module8Data.bonus_or_tips_or_commission !== ''
      && typeof module8Data.second_yearly_income !== 'undefined' && module8Data.second_yearly_income !== '' ) {
      value = module8Data.yearly_income
        + module8Data.bonus_or_tips_or_commission
        + module8Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'career', 'module8', value );
  }

  /** End of Additional Career Advancement * */

  /** Family * */
  // Single Iteration5
  function singleIteration5() {
    // Income
    let income = 0;
    if ( typeof module10Data.preTaxExpectedIncome !== 'undefined'
      && module10Data.preTaxExpectedIncome !== '' ) {
      income = module10Data.preTaxExpectedIncome;
    }
    return singleCalculation( income );
  }

  // Married iteration5
  function marriedIteration5() {
    // Income
    let income = 0;
    if ( typeof module10Data.preTaxExpectedIncome !== 'undefined'
      && module10Data.preTaxExpectedIncome !== '' ) {
      income = module10Data.preTaxExpectedIncome;
    }
    return marriedCalculation( income );
  }

  // Single Iteration6
  function singleIteration6() {
    // Income
    let income = 0;
    if ( typeof module10Data.morePreTaxExpectedIncome !== 'undefined'
      && module10Data.morePreTaxExpectedIncome !== '' ) {
      income = module10Data.morePreTaxExpectedIncome;
    }
    return singleCalculation( income );
  }

  // Married iteration6
  function marriedIteration6() {
    // Income
    let income = 0;
    if ( typeof module10Data.morePreTaxExpectedIncome !== 'undefined'
      && module10Data.morePreTaxExpectedIncome !== '' ) {
      income = module10Data.morePreTaxExpectedIncome;
    }
    return marriedCalculation( income );
  }

  /** End of Family * */

  /** More Higher Education * */
  // Single Iteration7
  function singleIteration7() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module6Data.yearly_income !== 'undefined' && module6Data.yearly_income !== '' ) {
      value = module6Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'more-higher-education', 'module6', value );
  }

  // Married iteration7
  function marriedIteration7() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module6Data.yearly_income !== 'undefined' && module6Data.yearly_income !== '' ) {
      value = module6Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'more-higher-education', 'module6', value );
  }

  /** End of More Higher Education * */

  /** Further Higher Education * */
  // Single Iteration8
  function singleIteration8() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module15Data.yearly_income !== 'undefined' && module15Data.yearly_income !== '' ) {
      value = module15Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'further-higher-education', 'module15', value );
  }

  // Married iteration8
  function marriedIteration8() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module15Data.yearly_income !== 'undefined' && module15Data.yearly_income !== '' ) {
      value = module15Data.yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'further-higher-education', 'module15', value );
  }

  /** End of Further Higher Education * */

  /** Further Career Advancement * */
  // Single Iteration9

  function singleIteration9() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module17Data.yearly_income !== 'undefined' && module17Data.yearly_income !== ''
      && typeof module17Data.bonus_or_tips_or_commission !== 'undefined' && module17Data.bonus_or_tips_or_commission !== ''
      && typeof module17Data.second_yearly_income !== 'undefined' && module17Data.second_yearly_income !== '' ) {
      value = module17Data.yearly_income
        + module17Data.bonus_or_tips_or_commission
        + module17Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Single ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Single );
      }
    }
    return singleCalculation( income, 'career', 'module17', value );
  }

  // Married iteration9
  function marriedIteration9() {
    // Income
    let income = 0;
    let value = 0;
    if ( typeof module17Data.yearly_income !== 'undefined' && module17Data.yearly_income !== ''
      && typeof module17Data.bonus_or_tips_or_commission !== 'undefined' && module17Data.bonus_or_tips_or_commission !== ''
      && typeof module17Data.second_yearly_income !== 'undefined' && module17Data.second_yearly_income !== '' ) {
      value = module17Data.yearly_income
        + module17Data.bonus_or_tips_or_commission
        + module17Data.second_yearly_income;

      if ( ( value - databaseData.Data['Standard Deductions'].Married ) > 0 ) {
        income = ( value - databaseData.Data['Standard Deductions'].Married );
      }
    }
    return marriedCalculation( income, 'career', 'module17', value );
  }
  /** End of Further Career Advancement * */
}
export default estimatedIncomeTaxes;
