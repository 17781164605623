import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';
import CareerBottomTabs from './career-bottom-tab-graphs';

const MyCareerPath = ( props ) => {
  const {
    classes, handleCareerObject,
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>You now have a plan for your career move! Remember, you build a budget so you can see exactly where you are spending, how you can save more, and make smarter choices about your money.</p>
      <p>Use the button below to save your scenario.</p>
      <div className={ classes.grayBox }>
        {handleCareerObject.occupation && (
        <p className={ classes.marginBottomZero }>
          <i>
            Career Move:
            {' '}
            {' '}
            {handleCareerObject.occupation}

          </i>
        </p>
        )}
        <p className={ classes.marginBottomZero }>
          <i>
            Location:
            {' '}
            {handleCareerObject.city}
            ,
            {' '}
            {handleCareerObject.state_code}
          </i>
        </p>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>

  );
};

MyCareerPath.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};

export default withStyles( styles )( MyCareerPath );
