import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Container, Dialog, DialogContent, Button,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { loader } from 'graphql.macro';
import { useQuery,  useMutation } from '@apollo/react-hooks';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import ReactTooltip from 'react-tooltip';
import Pageloader from '../../components/ui/pageloader';
// import { parseGraphQLErrorMessage } from '../../utilities/commonFunctions';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import CardOne from '../../assets/img/register/visa_img.png';
import CardTwo from '../../assets/img/register/master_img.png';
import CardThree from '../../assets/img/register/amex_img.png';
import CardFour from '../../assets/img/register/last_card_img.png';
import LogoImage from '../../assets/img/logo.png';
import trustedImage from '../../assets/img/register/trustedsite.png';
import LockIcon from '../../assets/img/register/lock_icon.png';

import infoIcon from '../../assets/img/info-icon.svg';

import * as commonFunctions from '../../utilities/commonFunctions';

// const CHECK_EMAIL = loader( '../../graphql/schema/register/checkEmail.graphql' );
// const CHECK_USERNAME = loader( '../../graphql/schema/register/check-username.graphql' );
const VERIFY_PROMOCODE = loader( '../../graphql/schema/register/verify-promocode.graphql' );
const REGISTER = loader( '../../graphql/schema/register/register.graphql' );
const STATES_DETAILS = loader( '../../graphql/schema/auth/list-of-states.graphql' );

const Renew = ( props ) => {
  const { classes, stripe } = props;

  let pricingPlan = localStorage.getItem( 'inkwiry_pricing_plan' );
  if ( pricingPlan === null || pricingPlan === undefined || pricingPlan === '' ) {
    pricingPlan = 'monthly';
  } else {
    localStorage.removeItem( 'inkwiry_pricing_plan' );
  }

  const { data: stateDetails } = useQuery( STATES_DETAILS );
  const [profession] = React.useState( '' );
  const [submitClicked, setSubmitClicked] = useState( false );
  // const [birthYearError, setBirthYearError] = useState( false );
  const [stateError, setStateError] = useState( false );
  const [checked, setChecked] = useState( false );
  const [checkedUpdates, setCheckedUpdates] = useState( true );
  // const [emailValue, setEmailValue] = useState( '' );
  // const [usernameValue, setUsernameValue] = useState( '' );
  // const [forgotPasswordError, setForgotPasswordError] = useState( '' );
  // const [confirmEmailError, setConfirmEmailError] = useState( false );
  // const [confirmationSuccess, setconfirmationSuccess] = useState( false );

  // const [emailSuccess, setemailSuccess] = useState( false );
  // const [usernameError, setUsernameError] = useState( '' );
  // const [passwordLength, setPasswordLength] = useState( false );
  // const [alphabetsExist, setAlphabetsExist] = useState( false );
  // const [numericExist, setNumericExist] = useState( false );
  // const [passwordMatch, setPasswordMatch] = useState( false );
  const [promocodePlan, setPromocodePlan] = useState( pricingPlan );
  const [stateName, setStateName] = useState( null );
  const [totalPaymentamount, setTotalPaymentamount] = useState( 9 );
  const [salesTax, setSalesTax] = useState( 0 );

  const [stripeTokenError, setStripeTokenError] = useState( '' );
  // const [registerFormErrors, setRegisterFormErrors] = useState( '' );
  const [registerFormSuccess, setRegisterFormSuccess] = useState( false );
  const [cardNumberStatus, setCardNumberStatus] = useState( false );
  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState( '' );
  const [cvvStatus, setCvvStatus] = useState( false );
  const [expiryStatus, setExpiryStatus] = useState( false );
  const [cardType, setCardType] = useState( false );
  // const [passCheck, setPassCheck] = useState( false );

  const [promocodeValue, setPromocodeValue] = useState( '' );
  const [promocode, setPromocode] = useState( false );
  const [promocodeType, setPromocodeType] = useState( '' );
  const [promocodePer, setPromocodePer] = useState( 0 );
  const [promocodeerror, setPromocodeError] = useState( '' );
  const [successPromocode, setSuccessPromocode] = useState( '' );
  const [discountAmount, setDiscountAmount] = useState( 0 );
  const [loading, setLoading] = useState( false );

  const [cardEmpty, setCardEmpty] = useState( true );
  const [cardDateEmpty, setCardDateEmpty] = useState( true );
  const [cardCvvEmpty, setCardCvvEmpty] = useState( true );
  const [countryError, setCountryError] = useState( false );

  const birthYears = [];

  for ( let i = 2020; i >= ( 2020 - 90 ); i -= 1 ) {
    birthYears.push( i );
  }

  // const handleProfession = ( event ) => {
  //   setProfession( event.target.value );
  // };

  const handleGetPromocode = ( event, type, promocodestatus, promotype, value ) => {
    let paymentPlan = '';
    let stateVal = '';
    if ( type === 'plan' ) {
      paymentPlan = event.target.value;
      stateVal = stateName;
      setPromocodePlan( paymentPlan );
    } else if ( type === 'state' ) {
      paymentPlan = promocodePlan;
      stateVal = event;
      setStateName( stateVal );
    } else {
      paymentPlan = promocodePlan;
      stateVal = stateName;
    }

    let codeType = '';
    let codeStaus = false;
    let percent = 0;
    if ( type === 'promocode' ) {
      codeType = promotype;
      codeStaus = promocodestatus;
      percent = value;
    } else {
      codeType = promocodeType;
      codeStaus = promocode;
      percent = promocodePer;
    }

    let finalTotalPaymentamount = 0;

    if ( paymentPlan === 'monthly' ) {
      finalTotalPaymentamount = 9;
    } else if ( paymentPlan === 'semi_year' ) {
      finalTotalPaymentamount = 42;
    } else if ( paymentPlan === 'year' ) {
      finalTotalPaymentamount = 60;
    }

    let promocodeDiscountAmount = 0;
    if ( codeStaus === true && codeType === 'percentage' ) {
      promocodeDiscountAmount = ( finalTotalPaymentamount / 100 ) * percent;
    }

    let salesTaxVal = 0;
    if ( stateVal === 2 || stateVal === '2' ) {
      salesTaxVal = ( ( finalTotalPaymentamount - promocodeDiscountAmount ) / 100 ) * 6;
    }
    setDiscountAmount( promocodeDiscountAmount );
    setSalesTax( salesTaxVal );
    setTotalPaymentamount( finalTotalPaymentamount );
  };

  const handleSuccessPopupClose = () => {
    setRegisterFormSuccess( false );
  };

  const [submitPromoCode] = useMutation( VERIFY_PROMOCODE, {
    onCompleted( {
      verifyPromocode: {
        status,
        type,
        percent,
      },
    } ) {
      if ( status ) {
        setPromocodeError( '' );
        setPromocodeType( type );
        setPromocodePer( percent );
        setSuccessPromocode( promocodeValue );
        setPromocode( true );
        handleGetPromocode( '', 'promocode', true, type, percent );
      }
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        message
      ) );
      setPromocodePer( 0 );
      setPromocodeType( '' );
      setSuccessPromocode( '' );
      if ( formatedErrors.length > 0 ) {
        setPromocodeError( formatedErrors[0] );
      } else {
        setPromocodeError( 'Please enter a valid promotion code.' );
      }
      setPromocode( false );
      handleGetPromocode( '', 'promocode', false, '', '' );
    },
  } );
  const handlePromoCode = () => {
    if ( promocodeValue ) {
      submitPromoCode( {
        variables: {
          data: {
            plan: promocodePlan,
            promocode: promocodeValue,
          },
        },
      } );
    } else {
      setPromocodePer( 0 );
      setPromocodeType( '' );
      setPromocodeError( 'Please enter a valid promotion code.' );
      setSuccessPromocode( '' );
      setPromocode( false );
      handleGetPromocode( '', 'promocode', false, '', '' );
    }
  };

  const hanndleAgreeTerms = ( event ) => {
    setChecked( event.target.checked );
  };
  const hanndleNewsUpdates = ( event ) => {
    setCheckedUpdates( event.target.checked );
  };

  // const [checkEmailExist] = useLazyQuery( CHECK_EMAIL, {
  //   variables: {
  //     email: emailValue,
  //   },
  //   fetchPolicy: 'network-only',
  //   onCompleted( {
  //     CheckEmail: {
  //       status,
  //     },
  //   } ) {
  //     if ( status ) {
  //       setemailSuccess( status );
  //       setForgotPasswordError( '' );
  //     }
  //   },
  //   onError( errors ) {
  //     const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
  //       <span key={ `forgot-password-error-${Math.random()}` }>{message}</span>
  //     ) );
  //     if ( emailValue !== '' && ( commonFunctions.validateEmail( emailValue ) ) ) {
  //       setForgotPasswordError( formatedErrors );
  //       setemailSuccess( false );
  //     }
  //   },

  // } );
  // const checkConfirmEmail = ( e ) => {

  //   if ( e.target.value ) {
  //     if ( e.target.value === emailValue ) {
  //       setconfirmationSuccess( true );
  //       setConfirmEmailError( false );
  //     } else {
  //       setconfirmationSuccess( false );
  //       setConfirmEmailError( true );
  //     }
  //   }
  // };

  // const [checkUserNameExist] = useLazyQuery( CHECK_USERNAME, {
  //   variables: {
  //     user_name: usernameValue,
  //   },
  //   fetchPolicy: 'network-only',
  //   onCompleted( {
  //     CheckUserName: {
  //       status,
  //     },
  //   } ) {
  //     if ( status ) {
  //       setUsernameError( '' );
  //     }
  //   },
  //   onError( errors ) {
  //     const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
  //       <span key={ `forgot-password-error-${Math.random()}` }>{message}</span>
  //     ) );
  //     if ( usernameValue !== '' ) {
  //       setUsernameError( formatedErrors );
  //     }
  //   },

  // } );

  const validate = ( values ) => {
    const errors = {};
    if ( !values.first_name ) {
      errors.first_name = 'Firstname is required.';
    }

    if ( values.email ) {
      if ( !commonFunctions.validateEmail( values.email ) ) {
        errors.email = 'Enter valid email.';
      }     
    }
    if ( !values.email ) {
      errors.email = 'email is required.';     
    }
    if ( !values.confirmEmail ) {
      errors.confirmEmail = 'confirm email is required.';
    }
    // if ( values.confirmEmail ) {
    //   if ( values.confirmEmail === values.email ) {
    //     setconfirmationSuccess( true );
    //     setConfirmEmailError( false );
    //   } else {
    //     setconfirmationSuccess( false );
    //     setConfirmEmailError( true );
    //   }
    // }

    if ( !values.last_name ) {
      errors.last_name = 'lastname is required.';
    }
    if ( !values.birth_year ) {     
      errors.birth_year = 'Birth Year is Required';
    } 
    if ( profession === 'student' ) {
      if ( !values.school ) {
        errors.school = 'School is Required';
      }
    }
    if ( !values.state ) {
      if ( submitClicked ) {
        setStateError( true );
      }
      errors.state = 'State is Required';
      handleGetPromocode( '', 'state' );
    } else {
      handleGetPromocode( values.state, 'state' );
      setStateError( false );
    }
    // if ( values.user_name ) {
    //   setUsernameValue( values.user_name );
    // }
    if ( !values.user_name ) {
      errors.user_name = 'username is required.';
    }
    if ( !values.password ) {
      errors.password = 'password is required.';
    }
    // if ( values.password ) {
    //   // if ( values.password.length > 8 ) {
    //   //   setPasswordLength( true );
    //   // } else {
    //   //   setPasswordLength( false );
    //   // }
    //   if ( !commonFunctions.validateAlpha( values.password ) ) {
    //     setAlphabetsExist( true );
    //   } else {
    //     setAlphabetsExist( false );
    //   }
    //   if ( !commonFunctions.validateNumeric( values.password ) ) {
    //     setNumericExist( true );
    //   } else {
    //     setNumericExist( false );
    //   }
    // }
    if ( !values.password_confirmation ) {
      errors.password_confirmation = 'confirm password is required.';
    }
    // if ( values.password_confirmation ) {
    //   if ( values.password === values.password_confirmation ) {
    //     setPasswordMatch( true );
    //   } else {
    //     setPasswordMatch( false );
    //   }
    // }
    if ( !values.address ) {
      errors.address = 'street address is required.';
    }
    if ( !values.city ) {
      errors.city = 'city is required.';
    }
    if ( !values.zip_code ) {
      errors.zip_code = 'zipcode is required.';
    }
    if ( !values.cardHolderName ) {
      errors.cardHolderName = 'card holder name is required.';
    }
    if ( !values.country ) {
      if ( submitClicked ) {
        setCountryError( true );
      }
      errors.country = 'Country is Required';
    } else {
      setCountryError( false );
    }

    return errors;
  };

  const handlenumber = ( e ) => {
    setCardType( e.brand );
    setCardEmpty( e.empty );
    if ( e.complete === true ) {
      setCardNumberStatus( true );
      setCardNumberErrorMsg( '' );
    } else {
      setCardNumberStatus( false );
      if ( e.error ) {
        setCardNumberErrorMsg( e.error.message );
      }
    }
  };

  const handlecvv = ( e ) => {
    setCardCvvEmpty( e.empty );
    if ( e.complete === true ) {
      setCvvStatus( true );
    } else {
      setCvvStatus( false );
    }
  };

  const handleexpiry = ( e ) => {
    setCardDateEmpty( e.empty );
    if ( e.complete === true ) {
      setExpiryStatus( true );
    } else {
      setExpiryStatus( false );
    }
  };

  const handleSubmitClick = () => {
    setSubmitClicked( true );
  };

  // const processErrors = ( errors ) => {
  //   if ( !errors ) {
  //     const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
  //       <span key={ `reset-password-error-${Math.random()}` }>{message}</span>
  //     ) );

  //     // setRegisterFormErrors( formatedErrors );
  //   } else {
  //     const typeError = parseGraphQLErrorMessage( errors );
  //     // setRegisterFormErrors( typeError );
  //   }
  // };

  const [submitConfirmPayment] = useMutation( REGISTER, {
    onCompleted( {
      register: {
        status,
      },
    } ) {
      if ( status ) {
        setLoading( false );
        setRegisterFormSuccess( true );
      }
    },
    onError( ) {
      // errors
      setLoading( false );
      // processErrors( errors );
    },
  } );

  const onSubmit = ( values ) => {
    setSubmitClicked( true );
    if ( cardNumberStatus === true && cvvStatus === true && expiryStatus === true ) {
      setLoading( true );
      stripe.createToken( { type: 'card', name: values.cardHolderName } )
        .then( ( response ) => {
          if ( response.error ) {
            setLoading( false );
            setStripeTokenError( response.error );
          } else {
            submitConfirmPayment( {
              variables: {
                data: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  birth_year: values.birth_year,
                  email: values.email,
                  password: values.password,
                  password_confirmation: values.password_confirmation,
                  identity: profession,
                  user_name: values.user_name,
                  address: values.address,
                  address2: values.address2,
                  city: values.city,
                  country: values.country,
                  state: values.state,
                  zip_code: values.zip_code,
                  token_id: response.token.id,
                  plan_id: promocodePlan,
                  promocode: successPromocode,
                },
              },
            } );
          }
        } );
    }
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.MyAccount }>
        <Container className={ classes.container }>
          <div className={ classes.rootOne }>
            <Form
              validate={ validate }
              onSubmit={ onSubmit }
              initialValues={ { country: 'US' } }
              render={ ( { handleSubmit, values } ) => (
                <form
                  className={ classes.formContact }
                  onSubmit={ handleSubmit }
                  noValidate
                >
                  <div className={ classes.accountInformation }>
                    <Link href="/" className={ classes.logoImage }>
                      <img src={ LogoImage } alt="" />
                    </Link>
                    <Grid container>
                      <Grid sm={ 6 } xs={ 12 }>
                        <div className={ classes.rightGap }> 
                          <div className={ classes.registrationPageTop }>
                            <Typography variant="h3">Billing Address</Typography>
                            <div className={ classes.radioButtonGroup }>
                              <Grid sm={ 12 } xs={ 12 }>
                                <div className={ classes.formGroup }>
                                  <Field
                                    id="filled-password-input"
                                    label="Street Address"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="filled"
                                    name="address"
                                    fullWidth
                                    required
                                    component={ TextField }
                                  />
                                </div>
                                <div className={ classes.formGroup }>
                                  <Field
                                    id="filled-password-input"
                                    label="Apartment, suite, unit, floor, etc."
                                    type="text"
                                    autoComplete="current-password"
                                    variant="filled"
                                    name="address2"
                                    fullWidth
                                    component={ TextField }
                                  />
                                </div>
                                <div className={ classes.formGroup }>
                                  <Field
                                    id="filled-password-input"
                                    label="City"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="filled"
                                    name="city"
                                    fullWidth
                                    required
                                    component={ TextField }
                                  />
                                </div>
                              </Grid>
                              <Grid container>
                                <Grid
                                  sm={ 4 }
                                  xs={ 12 }
                                  spacing={ 1 }
                                  className={ classes.paddingLeft }
                                >
                                  <div className={ classes.formGroup }>
                                    <span className={ classes.selectLable }>
                                      Country
                                      <span>*</span>
                                      {' '}
                                    </span>
                                    <Field
                                      id={ countryError ? 'countryError' : 'country' }
                                      name="country"
                                      className={ classes.formSelect }
                                      component="select"
                                    >
                                      <option value="">Select country</option>
                                      <option value="US">United States</option>
                                    </Field>

                                  </div>
                                </Grid>
                                <Grid
                                  sm={ 4 }
                                  xs={ 12 }
                                  spacing={ 2 }
                                  className={ classes.paddingLeft }
                                >
                                  <div className={ classes.formGroup }>
                                    <span className={ classes.selectLable }>
                                      State
                                      <span>*</span>
                                    </span>
                                    <Field
                                      id={
                                    stateError
                                      ? 'stateError'
                                      : 'state'
                                  }
                                      name="state"
                                      className={ classes.formSelect }
                                      component="select"
                                    >
                                      <option value="">Select State</option>
                                      {stateDetails && stateDetails.States.map( ( value ) => (
                                        <option key={ value.id } value={ value.id }>{value.state_name}</option>
                                      ) )}
                                    </Field>
                                  </div>
                                </Grid>
                                <Grid
                                  sm={ 4 }
                                  xs={ 12 }
                                  className={ classes.paddingLeft }
                                >
                                  <div className={ classes.formGroup }>
                                    <Field
                                      id="filled-password-input"
                                      label="Zipcode"
                                      type="text"
                                      autoComplete="current-password"
                                      variant="filled"
                                      name="zip_code"
                                      maxLength={ 5 }
                                      fullWidth
                                      required
                                      component={ TextField }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <div className={ classes.formGroup }>
                                  <Field
                                    id=""
                                    label="Phone (optional)"
                                    type="text"                                    
                                    variant="filled"
                                    name="phone"
                                    fullWidth
                                    component={ TextField }
                                  />
                                </div>
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid sm={ 6 } xs={ 12 }>
                        <div className={ classes.borderLeft }>
                          <div className={ classes.registrationPage }>
                            <Typography variant="h3">
                              Payment Details
                            </Typography>
                            <p className={ classes.blackFont }>All transactions are secure and encrypted.</p>
                            <div className={ classes.cardDetails }>
                              <div className={ classes.cardDetailsHeader }>
                                <h4>Credit Card</h4>
                                <ul>
                                  <li className={ cardType === 'visa' ? classes.selectedCard : '' }>
                                    <img src={ CardOne } alt="" />
                                  </li>
                                  <li className={ cardType === 'mastercard' ? classes.selectedCard : '' }>
                                    <img src={ CardTwo } alt="" />
                                  </li>
                                  <li className={ cardType === 'amex' ? classes.selectedCard : '' }>
                                    <img src={ CardThree } alt="" />
                                  </li>
                                  <li className={ cardType === 'diners' ? classes.selectedCard : '' }>
                                    <img src={ CardFour } alt="" />
                                  </li>
                                  <li className={ cardType !== 'diners' && cardType !== 'visa' && cardType !== 'mastercard' && cardType !== 'amex' && cardType !== '' && cardType !== 'unknown' ? classes.selectedCard : '' }>and more...</li>
                                </ul>
                              </div>
                              <div className={ classes.cardDetailsContent }>
                                {
                                  stripeTokenError && ( <div>{stripeTokenError}</div> )
                                }
                                <div className={ classes.formGroup }>
                                  <CardNumberElement
                                    className={ ( !cardNumberStatus && submitClicked ) ? `${classes.cardnumber} ${classes.cardBorder}` : classes.cardnumber }
                                    onChange={ handlenumber }
                                    placeholder="Card Number"
                                  />
                                  <img src={ LockIcon } alt="" className={ classes.lockIcon } />
                                  {cardEmpty === true && ( <span className={ classes.cardNumberStar }>*</span> )}
                                </div>
                                {
                                  ( cardNumberErrorMsg !== '' && submitClicked ) && ( <div className={ classes.errorText }>{cardNumberErrorMsg}</div> )
                                }
                                <Grid container>
                                  <Grid
                                    sm={ 6 }
                                    xs={ 12 }
                                    spacing={ 1 }
                                    className={ classes.paddingLeft }
                                  >
                                    <div className={ `${classes.formGroup} ${classes.cardHolder}` }>
                                      <Field
                                        id="filled-password-input"
                                        placeholder="Cardholder name"
                                        type="text"
                                        autoComplete="current-password"
                                        variant="filled"
                                        name="cardHolderName"
                                        fullWidth
                                        component={ TextField }
                                        required
                                      />
                                      {( values.cardHolderName === undefined || values.cardHolderName === '' ) && ( <span className={ classes.cardHolderName }>*</span> )}
                                    </div>
                                  </Grid>
                                  <Grid
                                    sm={ 3 }
                                    xs={ 12 }
                                    spacing={ 1 }
                                    className={ classes.paddingLeft }
                                  >
                                    <div className={ classes.formGroup }>
                                      <CardExpiryElement
                                        placeholder="MM / YY"
                                        className={ ( !expiryStatus && submitClicked ) ? `${classes.cardnumber} ${classes.cardBorder}` : classes.cardnumber }
                                        name="carexpiry"
                                        onChange={ handleexpiry }
                                      />
                                      {cardDateEmpty === true && ( <span className={ classes.cardMMYYStar }>*</span> )}

                                    </div>
                                  </Grid>
                                  <Grid
                                    sm={ 3 }
                                    xs={ 12 }
                                    spacing={ 1 }
                                    className={ classes.paddingLeft }
                                  >
                                    <div className={ classes.formGroup }>
                                      <CardCvcElement
                                        placeholder="CVV"
                                        className={ ( !cvvStatus && submitClicked ) ? `${classes.cardnumber} ${classes.cardBorder}` : classes.cardnumber }
                                        name="cardcvv"
                                        onChange={ handlecvv }
                                      />
                                      {cardCvvEmpty === true && ( <span className={ classes.cardCVVStar }>*</span> )}

                                      <img data-tip="3-digit security code typically found on the back of the card. For American Express cards, the code is 4 digits and found on the front." data-for="cvvTooltip" src={ infoIcon } alt="" className={ classes.lockIcon } />
                                      <ReactTooltip id="cvvTooltip" place="top" type="info" effect="solid" className={ classes.tooltipScoreInfo } />

                                    </div>
                                  </Grid>

                                </Grid>

                              </div>
                            </div>
                           

                            <div
                              className={ `${classes.radioButtonGroup} ${classes.memberShipMain}` }
                            >
                              <div className={ classes.MeberShip }>
                                <h3>Membership Details</h3>
                              </div>

                              <ul>
                                <FormControl component="fieldset" className={ classes.formControl }>
                                  <RadioGroup aria-label="memberShipDetails" className={ classes.radioGroup } name="memberShipDetails" value={ promocodePlan } onChange={ ( e ) => handleGetPromocode( e, 'plan' ) }>
                                    <div>
                                      <div>
                                        <FormControlLabel className={ classes.cotrolLabel } value="monthly" control={ <Radio /> } />
                                        {promocodePlan === 'monthly' ? (
                                          <span>
                                            <span>
                                              Plan With Inkwiry for 30 days
                                              <font>{promocodePlan === 'monthly' && '$9.00'}</font>
                                            </span>
                                            <small className={ classes.topGap }>(current billing period starts from today&apos;s date)</small>
                                          </span>
                                        ) : (
                                          <span>
                                            Plan With Inkwiry for 30 days
                                            <small>($9 for 30 days)</small>
                                          </span>
                                        )}
                                      </div>

                                    </div>
                                    <div>
                                      <div>
                                        <FormControlLabel className={ classes.cotrolLabel } value="semi_year" control={ <Radio /> } />
                                        {promocodePlan === 'semi_year' ? (
                                          <span>
                                            <span>
                                              GOLD Member ($42 for 6 months, $7/mo)
                                              <font>
                                                {promocodePlan === 'semi_year' && '$42.00'}
                                              </font>
                                            </span>
                                            <small className={ classes.topGap }>(current billing period starts from today&apos;s date)</small>
                                          </span>
                                        ) : (
                                          <span>
                                            GOLD Member
                                            <small>($42 for 6 months, $7/mo)</small>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <FormControlLabel className={ classes.cotrolLabel } value="year" control={ <Radio /> } />
                                        {promocodePlan === 'year' ? (
                                          <span>
                                            <span>
                                              PLATINUM Member ($60 for 1 year, $5/mo)
                                              <font>
                                                {promocodePlan === 'year' && '$60.00'}
                                              </font>
                                            </span>
                                            <small className={ classes.topGap }>(current billing period starts from today&apos;s date)</small>
                                          </span>
                                        ) : (
                                          <span>
                                            PLATINUM Member
                                            <small>($60 for 1 year, $5/mo)</small>
                                          </span>
                                        )}
                                      </div>

                                    </div>
                                  </RadioGroup>
                                </FormControl>

                              </ul>
                              <div className={ classes.applyCoupon }>
                                <div
                                  className={ `${classes.formGroup} ${classes.applyCoupon}` }
                                >
                                  <input placeholder="Enter Promo Code" value={ promocodeValue } onChange={ ( e ) => { setPromocodeValue( e.target.value ); } } />
                                  <Button onClick={ handlePromoCode }>Apply</Button>
                                </div>
                              </div>
                              { promocodeerror !== '' && ( <div className={ classes.errorText }>{promocodeerror}</div> ) }
                              { promocode === true && promocodeType === 'days' && (
                              <div className={ classes.successText }>
                                Promo code successful! You will get an extra
                                {promocodePer}
                                {' '}
                                days free with your purchase.
                              </div>
                              ) }
                              { promocode === true && promocodeType === 'percentage' && (
                              <div className={ classes.successText }>
                                Promo code applied successfully.
                                {promocodePer}
                                % discount has been applied.
                              </div>
                              ) }
                              {
                                ( promocode === true && promocodeType === 'percentage' ) && (
                                <div className={ `${classes.totalBill} ${classes.billNoBorder}` }>
                                  <h4>
                                    Discount
                                    <span>
                                      $
                                      { parseFloat( discountAmount ).toFixed( 2 ) }
                                    </span>
                                  </h4>
                                </div>
                                )
                              }

                              {
                                ( stateName === 2 || stateName === '2' ) && (
                                <div className={ `${classes.totalBill} ${classes.billNoBorder}` }>
                                  <h4>
                                    Sales Tax
                                    <span>
                                      $
                                      { parseFloat( salesTax ).toFixed( 2 ) }
                                    </span>
                                  </h4>
                                </div>
                                )
                              }
                              <div className={ classes.totalBill }>
                                <h4>
                                  Total Bill
                                  <span>
                                    $
                                    {parseFloat( totalPaymentamount - discountAmount + salesTax ).toFixed( 2 )}
                                  </span>
                                </h4>
                              </div>
                              <div className={ classes.agreeCondition }>
                                <ul>
                                  <li>
                                    <Checkbox
                                      checked={ checked }
                                      name="agreeTerms"
                                      onChange={ hanndleAgreeTerms }
                                    />
                                    {' '}
                                    I agree to the
                                    {' '}
                                    <a href="/">Terms of Service</a>
                                    {' '}
                                    and
                                    {' '}
                                    <a href="/">Privacy Policy</a>
                                  </li>
                                  <li>
                                    <Checkbox
                                      checked={ checkedUpdates }
                                      name="agreeUpdates"
                                      onClick={ hanndleNewsUpdates }
                                    />
                                    {' '}
                                    Keep me up to date on news
                                  </li>
                                </ul>
                              </div>
                              <div className={ classes.btnGroup }>
                                
                                <Button
                                  className={ classes.payment }
                                  disabled={ !checked }
                                  onClick={ handleSubmitClick }
                                  type="submit"
                                >
                                  Confirm Payment
                                </Button>
                              </div>
                              <img src={ trustedImage } alt="" className={ classes.trusteImage } />
                            </div>
                          </div>
                          {/* {
                            registerFormErrors && ( <p>{registerFormErrors}</p> )
                          } */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              ) }
            />
          </div>
          <div className={ classes.footerJoin }>
            <ul>
              <li><Link to="/">Inkwiry</Link></li>
              <li><Link to="/">Terms of Service</Link></li>
              <li><Link to="/">Privacy Policy</Link></li>
              <li><Link to="/">Security</Link></li>
              <li>Copyright © 2022 Inkwiry. All rights reserved.</li>

            </ul>
          </div>
        </Container>

      </div>
      <Dialog
        open={ registerFormSuccess }
        onClose={ handleSuccessPopupClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent className={ classes.messagePopup }>
          <div className={ classes.popupClose }>
            <Link aria-hidden="true" onClick={ handleSuccessPopupClose } href="/">X</Link>
          </div>
          <div className={ classes.popupBody }>
            <span className={ classes.popupCheck }>
              <i className="las la-check" />
            </span>
            <h2>Payment confirmed!</h2>
            <p>
              Please check your email for next steps to activate your account.
            </p>
          </div>
          <div />
        </DialogContent>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Renew.propTypes = {
  classes: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
};

export default injectStripe( withStyles( styles )( Renew ) );
