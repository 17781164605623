import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CreditScore = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>What&apos;s a Credit Score?</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      What&apos;s a Credit Score?
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          A credit score is a three-digit number ranging from
                          300 - 850 (the higher, the better) that relates to how
                          likely you are to repay debt. If you have a higher
                          score, which means you are more likely to repay your
                          debt on time and in full, banks and other lenders will
                          give you lower interest rates on loans. How important
                          are lower interest rates? Click{' '}
                          <Link
                            to="/finance-explained/interest-rate"
                            target="_blank">
                            here
                          </Link>{' '}
                          to find out.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Your credit score is very important to maintain and
                          improve as a better credit score typically equates to
                          lower interest rates and vice versa. And, banks and
                          lenders will use your credit score when deciding
                          whether or not to approve you for a credit card or
                          loans such as auto loans, student loans and mortgages.
                        </p>
                        <p>
                          There are three major credit reporting agencies that
                          track your credit score:
                        </p>
                        <p className={classes.anchorText}>
                          <span>
                            1)
                            <a
                              href="https://www.equifax.com/personal/"
                              target="_blank"
                              rel="noopener noreferrer">
                              Equifax
                            </a>
                          </span>
                          <span>
                            2)
                            <a
                              href="https://www.experian.com/"
                              target="_blank"
                              rel="noopener noreferrer">
                              Experian
                            </a>
                          </span>
                          <span>
                            3)
                            <a
                              href="https://www.transunion.com/"
                              target="_blank"
                              rel="noopener noreferrer">
                              TransUnion
                            </a>
                          </span>
                        </p>
                        <p>
                          According to
                          <a
                            href="https://www.creditkarma.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Credit Karma
                          </a>
                          , a good credit score is 700 and higher. Do you know
                          your score? Go to{' '}
                          <a
                            href="https://www.creditkarma.com/auth/logon"
                            target="_blank"
                            rel="noopener noreferrer">
                            Credit Karma
                          </a>{' '}
                          and find out your score for free. You might even be
                          able to check your score for free with your credit
                          card company. And, checking does not hurt your credit
                          score.
                        </p>
                        <p>
                          How is your credit score calculated? What goes into
                          that magical number? What are some actions that can
                          improve or hurt my credit score?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CreditScore.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditScore);
