import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import styles from './common-dailog-style';
import MegaImage from '../../assets/img/life-event-library/mega-menu-pricing_img.png';
import MegaImageRight from '../../assets/img/life-event-library/guest_popup_img_right.png';

const CommonDialoginfo = ( props ) => {
  const { classes, history } = props;

  const goToJoin = () => {
    history.push( '/join' );
  };

  return (
    <div className={ classes.DailogLifeEvent }>
      <Grid container>
        <Grid md={ 5 } sm={ 12 } xs={ 12 }>
          <div className={ classes.continuePlanLeft }>
            <img src={ MegaImage } alt="" />
            <h3>
              Continue to the
              <br />
              {' '}
              Career Sketch Stories
            </h3>
            <p>and Plan With Inkwiry.</p>
            <p>For $9, get all the career sketch stories, save an unlimited number of scenarios and build your financial life plan for the next 5, 10, 15 years.</p>
            <Button onClick={ goToJoin }>Join for 30 Days</Button>
            <Link to="/finance-explained">
              Not right now, but I do want to get
              <br />
              smarter about my money.
            </Link>
          </div>

        </Grid>
        <Grid md={ 7 } sm={ 12 } xs={ 12 }>
          <div className={classNames(classes.continuePlanRight, classes.continuePlanRightTwo)}>
            <img src={ MegaImageRight } alt="" />
            <h2>Get the backstory
              <br />
              {' '}
              on all the career sketches
            </h2>
            
            <ul>
              <li>See exactly how these career sketches achieve financial independence and help you plan your own smart moves</li>
              <li>Learn why they chose a specific career, how much they earned and how they acquired education and training</li>
              <li>Understand the vision, the insights and the strategies, and how stepping stones can lift you toward your life goals.</li>
            </ul>
            <h4>Save your favorite sketch and customize it
            <br />
            to build your own financial life plan.</h4>
            <span>
              I&apos;m already a member,
              <Link to="/login">
                {' '}
                sign in
              </Link>
            </span>
            <span>
              <Link to="/pricing">
                {' '}
                {' '}
                Compare membership levels
              </Link>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

CommonDialoginfo.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CommonDialoginfo );
