import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruTwo = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruTwo)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Financial Independence module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Existing Assets & Liabilities module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Career Path module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Career Advancement module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Additional Career Advancement module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Futher Career Advancement module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Investments module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the REI module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Higher Education module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the More Higher Education module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Further Higher Education module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Family module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Vacation module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Buying a Car module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Switch to Plan With Inkwiry</h4>
                    <p>Right now, you are in the Worksheets view of the Buyinh a Home module. At any point in time, if you would like to switch to Plan With Inkwiry - a more guided, step-by-step process - for this module, click on the puzzle icon. Remember to save your data before you switch!</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(2) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(2); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruTwo.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruTwo);
