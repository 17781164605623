const styles = ( ) => ( {
  rightMegamenu: {
    background: '#f2f2f2',
    '& h3': {
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '18px',
    },
    '& h4': {
      margin: '20px 0 10px',
      fontWeight: 'normal',
    },
    '& ul':{
      '& ol':{
        marginBottom: '10px',
        '& figure':{
          lineHeight: '0px',
        },
        '& > figure':{
          marginRight: '5px',
        },
        '& span':{
          position: 'relative',
          zIndex: '9',
        }
      }
    }
  },
  loginMain:{
    '& div':{
      '& ul':{
        '& li':{
          '& a':{
            '@media (min-width: 960px)': {
              fontSize: '18px',
              // lineHeight: '31px',
            }
          }
        },
        '& ol':{
          '& span':{
            '& a':{
              '@media (min-width: 960px)': {
                fontSize: '18px',
                // lineHeight: '31px',
              }
            }
          }
        }
      }
    }
  },
  LeftMegamenu: {
    padding: '25px 35px 25px 60px',
    alignItems: 'center',
    '@media (max-width: 959px)': {
      padding: '25px 20px 30px 30px',
    },
   
    '& h3': {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    '& li': {
      fontFamily: "'MuseoSans-100'",
      fontSize: '15px',
    },
  },
  LeftMegamenuThree:{
    '@media (min-width: 1200px)': {
      paddingLeft: '100px',
    },
  },
  rightMegamenuforlogout:{
     background: '#f2f2f2',
   },
  LeftMegamenuFrst: {
    paddingLeft: '60px',
     '@media (max-width: 959)':{
       padding:'25px 20px 30px 30px',
     },
     '& h3, li':{
       fontSize:'30px',
     },
  },
  LeftMegamenuTwo:{
         paddingLeft: '30px',
     '@media (max-width: 959)':{
       padding:'25px 20px 30px 30px',
     },
     '& h3, li':{
       fontSize:'30px',
     },
  },

  listGroup: {
    marginTop: '25px',
    '& a': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  megamenuContainer: {
    overflow: 'hidden',

  },
  blueBox: {
    maxWidth: '300px',
    padding: '40px 30px',
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#0069AA',
    borderRadius: '15px',
    // margin: '0 auto 50px',
    margin: '0 auto 0px',
    '& h3': {
      fontSize: '36px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        lineHeight: '45px',
        color: '#fff',
        fontFamily: "'MuseoSans-300'",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '23px',
      padding: '30px 0 40px',
      maxWidth: '275px',
      margin: '0 auto',
      fontFamily: "'MuseoSans-300'",
    },
  },

  btnstartQuiz: {
    fontSize: '21px !important',
    backgroundColor: '#1F96E0 !important',
    color: '#fff !important',
    padding: '10px 28px !important',
    borderRadius: '10px',
    lineHeight: '36px !important',
    fontFamily: 'MuseoSans-500',
    border: '1px solid #fff !important',
    maxWidth: '275px !important',
    '& a': {
      color: '#fff',
      padding: '14px 28px',
    },
    '&:hover': {
      background: '#8FB251 !important'
    },
  },

    btnstartQuizNews: {
    fontSize: '21px !important',
    color: '#0069aa !important',
    border: '1px solid #0069aa !important',
    backgroundColor: 'transparent',
    padding: '8px 28px !important',
    borderRadius: '10px',
    lineHeight: '36px !important',
    fontFamily: 'MuseoSans-300',
    width: 'auto',
    display: 'inline-block',
    '&:hover': {
      background: '#0069aa !important',
      color: '#fff !important',
    },
  },

  blueBoxMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    height: '100%',
    // padding: '25px',
  },
    whyinkwryblueBox: {
    backgroundColor: '#0069aa',
    // marginRight:'2% 2%',
    maxWidth:'300px',
    // padding: '30px 20px 30px',
    padding: '50px 45px 10px',
    borderRadius: '20px',
    color: '#fff',
    margin: '70px 50px 50px 50px',
    '& h3':{  
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
    },
    '& p': {
      fontSize: '21px',
      lineHeight: '36px',
      textAlign: 'left',
      margin: '0',
      fontFamily: "'MuseoSans-300'",
      fontWeight: 'normal',
      '& small': {
        fontSize: '12px',
        lineHeight: '36px',
        float: 'right',
        fontFamily: "'MuseoSans-300'",
        fontWeight: 'normal',
      },
    },
  },

  marginLeftNew: {
    marginLeft: '600px',
    background: '#fafafa',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  marginLeftNewDevoloper: {
    background: '#fafafa',
    borderRadius: '15px',
    overflow: 'hidden',
    marginLeft: '395px',
    '@media (max-width: 959px)': {
      marginLeft: '0px',
    }
  },
  aboutMegaMenu: {
    padding: '50px',
    textAlign: 'center',
    '& img': {
      maxWidth: '200px',
      display: 'flex',
      margin: '0 auto',
    },
    '& h4': {
      fontSize: '24px',
      lineHeight: '36px',
      color: '#333',
      fontFamily: "'MuseoSans-300'",
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: '18px',
      lineHeight: '27px',
      padding: '10px 0 20px',
      maxWidth: '375px',
      margin: '0 auto',
      fontFamily: "'MuseoSans-300'",
      fontWeight: 'normal',
    },
  },
  priceDropdownImg: {
    '& img': {
      maxWidth: '125px',
    },
  },
  contactDropDown: {
    paddingBottom: '10px',
    '& img': {
      maxWidth: '280px',
    },
  },
  btnstartQuizAbout: {
    fontSize: '21px !important',
    color: '#cda349 !important',
    border: '1px solid #cda349 !important',
    backgroundColor: 'transparent',
    padding: '14px 28px !important',
    borderRadius: '10px',
    lineHeight: '24px !important',
    fontFamily: 'MuseoSans-300',
    width: 'auto',
    display: 'inline-block',
    '&:hover': {
      background: '#cda349 !important',
      color: '#fff !important ',
    },
  },
  marginLeftNewCompany: {
    background: '#fafafa',
    borderRadius: '15px',
    overflow: 'hidden',
    // marginLeft: '-100px',
  },
 
  loginMegaMenu: {
    '& ul': {
      marginBottom: '25px !important',
      '& li': {
        fontSize: '18px',
        marginBottom: '10px',
        paddingTop: '0',
        '@media (max-width: 959px)': {
          marginBottom: '0',
        }
      },
    },
  },
  MegamenuPaddingSixty: {
    paddingTop: '60px',
    '@media (max-width: 959px)': {
      paddingTop: '30px',
    }
  },
  MegamenuPaddingSixtyLeft: {
    paddingLeft: '60px',
  },
  finacialMegaMenuMew: {
    marginBottom: '40px',
    '& h3': {
      marginBottom: '10px',
    },
    '& a': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  hoverSubMenu: {
    position: 'absolute',
    top: '12px',
    left: '38px',
    display: 'none',
    '@media (max-width: 959px)': {
      display: 'block',
      top: '16px',
    },
    '& ul': {
      padding: '0',
      display: 'flex',
      fontSize: '10px',
      top: '-3px',
      position: 'relative',
      '& li': {
        fontSize: '11px',
        whiteSpace: 'nowrap',
        padding: '2px 2.5px !important',
        '& a': {
          fontSize: '11px !important',
          whiteSpace: 'nowrap',
          textDecoration: 'underline !important',
          padding: '0 !important',
          '&:hover': {
            color: '#f59a34',
          },
        },
        '& span':{
          lineHeight: '30px',
        }
      },
    },
  },
  blueText: {
    color: '#0069aa',
    fontFamily: "'MuseoSans-500'",
    fontWeight: 'normal',
  },
  paddingTopFisty: {
    paddingTop: '40px',
    '@media (max-width: 959px)': {
      paddingTop: '0px',
    }
  },
  paddingTopFisty80: {
    paddingTop: '80px',
    '@media (max-width: 959px)': {
      paddingTop: '0px',
    }
  },
  paddingTopFisty20: {
    paddingTop: '20px',
    '@media (max-width: 959px)': {
      paddingTop: '0px',
    }
  },
  spanDivide:{
    position: 'relative',
    top: '-2px',
  }
} );

export default styles;
