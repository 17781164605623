import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import styles from './styles';

const StockProfit = ( props ) => {
  const { classes } = props;

  const stockProfitDefaultValues = {
    numberShares: 30,
    purchasePrice: 125.00,
    salePrice: 150.00,
    divPerShare: 0.73,
    divReceived: 6,
    buyingFees: 4.50,
    sellingFees: 4.50,
    cgtr: 15.00,
  };

  const [stockProfitValues, setStockProfitValues] = useState( stockProfitDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  useEffect( () => {
    stockProfitValues.totalPurPrice = ( stockProfitValues.numberShares ) * ( stockProfitValues.purchasePrice );

    stockProfitValues.totSalePrice = ( stockProfitValues.salePrice ) * ( stockProfitValues.numberShares );

    stockProfitValues.totalGainSale = stockProfitValues.totSalePrice - stockProfitValues.totalPurPrice;

    stockProfitValues.totalodp = ( stockProfitValues.divPerShare ) * ( stockProfitValues.numberShares );

    stockProfitValues.totaltdr = ( stockProfitValues.divReceived ) * ( stockProfitValues.totalodp );

    stockProfitValues.totaltgd = ( ( stockProfitValues.totalGainSale + stockProfitValues.totaltdr ) * ( stockProfitValues.cgtr ) ) / 100;

    stockProfitValues.totalFees = ( stockProfitValues.buyingFees ) + ( stockProfitValues.sellingFees );

    stockProfitValues.totalpgdtf = stockProfitValues.totalGainSale + stockProfitValues.totaltdr - stockProfitValues.totaltgd - stockProfitValues.totalFees;

    setCalculatedValues( stockProfitValues );
    // eslint-disable-next-line
  }, [stockProfitValues]);

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...stockProfitValues };
    newValue = e.floatValue !== undefined ? e.floatValue : 0;
    valuesUpdated[field] = newValue;
    setStockProfitValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Stock Profit</li>
            </ul>
            <div className={ classes.netWorthSection }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showPortion }>
                  <div className={ classes.nwSection }>
                    <div className={ classes.nwSectionRow }>
                      <div className={ classes.netWorthContent }>
                        <div className={ classes.mainRow }>
                          <div className={ classes.leftPanel }>
                            <table>
                              <tbody>
                                <tr>
                                  {' '}
                                  <td> Number of Shares </td>
                                  {' '}
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.dollarField, classes.noInput ) }>
                                    <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.numberShares } onValueChange={ ( e ) => updateValue( e, 'numberShares' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Purchase Price</td>
                                </tr>
                                <tr>
                                  <td className={ classes.dollarField }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.purchasePrice } onValueChange={ ( e ) => updateValue( e, 'purchasePrice' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Sale Price</td>
                                </tr>
                                <tr>
                                  <td className={ classes.dollarField }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.salePrice } onValueChange={ ( e ) => updateValue( e, 'salePrice' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Dividend per Share</td>
                                </tr>
                                <tr>
                                  <td className={ classes.dollarField }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.divPerShare } onValueChange={ ( e ) => updateValue( e, 'divPerShare' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td># of Dividends Received</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.dollarField, classes.noInput ) }>
                                    <NumberFormat className={ classes.formInput } decimalScale={ 2 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.divReceived } onValueChange={ ( e ) => updateValue( e, 'divReceived' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Buying Fees (Commission)</td>
                                </tr>
                                <tr>
                                  <td className={ classes.dollarField }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.buyingFees } onValueChange={ ( e ) => updateValue( e, 'buyingFees' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Selling Fees (Commission)</td>
                                </tr>
                                <tr>
                                  <td className={ classes.dollarField }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.sellingFees } onValueChange={ ( e ) => updateValue( e, 'sellingFees' ) } onFocus={ ( e ) => e.target.select() } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Capital Gains Tax Rate</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.dollarField, classes.percentField ) }>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ stockProfitValues.cgtr } onValueChange={ ( e ) => updateValue( e, 'cgtr' ) } onFocus={ ( e ) => e.target.select() } />

                                    <span className={ classes.percentSym }>%</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className={ classes.leftPanel }>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total Purchase Price</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalPurPrice } displayType="text" prefix={ calculatedValues.totalPurPrice >= 0 ? '$' : '($' } suffix={ calculatedValues.totalPurPrice < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Sale Price</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totSalePrice } displayType="text" prefix={ calculatedValues.totSalePrice >= 0 ? '$' : '($' } suffix={ calculatedValues.totSalePrice < 0 && ')' } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Gain on Sale</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalGainSale } allowNegative={ false } displayType="text" prefix={ calculatedValues.totalGainSale >= 0 ? '$' : '($' } suffix={ calculatedValues.totalGainSale < 0 && ')' } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>One Dividend Payment</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalodp } displayType="text" prefix={ calculatedValues.totalodp >= 0 ? '$' : '($' } suffix={ calculatedValues.totalodp < 0 && ')' } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Dividends Received</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totaltdr } displayType="text" prefix={ calculatedValues.totaltdr >= 0 ? '$' : '($' } suffix={ calculatedValues.totaltdr < 0 && ')' } />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Tax on Gain and Dividends</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totaltgd } allowNegative={ false } displayType="text" prefix={ calculatedValues.totaltgd >= 0 ? '$' : '($' } suffix={ calculatedValues.totaltgd < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Fees</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalFees } displayType="text" prefix={ calculatedValues.totalFees >= 0 ? '$' : '($' } suffix={ calculatedValues.totalFees < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Profit (Gain + Dividends - Taxes - Fees)</td>
                                </tr>
                                <tr>
                                  <td className={ classNames( classes.inputValue, classes.dollarField ) }>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalpgdtf } allowNegative={ false } displayType="text" prefix={ calculatedValues.totalpgdtf >= 0 ? '$' : '($' } suffix={ calculatedValues.totalpgdtf < 0 && ')' } />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

StockProfit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( StockProfit );
