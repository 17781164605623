import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const SubsidizedUnsubsidizedLoans = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Subsidized vs Unsubsidized Loans</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/sub-vs-unsub-loan.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Subsidized vs Unsubsidized Loans
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/subsidized-vs-unsubsidized-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          There are two types of federal student loans - federal
                          subsidized and federal unsubsidized student loan.
                          Federal subsidized student loans are only available
                          for undergraduate students who have financial need.
                          Plus, subsidized student loans have one major
                          advantage – the government picks up your interest
                          during school. But what does that even mean?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Let&apos;s look at an example. Student A takes $10,000
                          of federal subsidized student loans in freshman year
                          of college and then doesn&apos;t take any more loans
                          through college. Student B takes $10,000 of
                          unsubsidized student loans in freshman year as well
                          and then doesn&apos;t take any more loans through
                          college. Both loans have a 5.0% interest rate and a
                          10-year term of the loan. So what&apos;s the
                          difference? They seem the same, don&apos;t they?
                        </p>
                        <p>
                          Student A graduates and, six months after graduation,
                          starts repaying $10,000 of student loans. Student B
                          graduates and, six months after graduation, starts
                          repaying $12,250 of student loans. How did Student
                          B&apos;s balance grow to $12,250?{' '}
                          <Link
                            to="/finance-explained?type=definitions"
                            target="_blank">
                            Accrued interest
                          </Link>
                          .
                        </p>
                        <p>
                          Federal subsidized student loans do not charge
                          interest until repayment begins. On the other hand,
                          unsubsidized student loans start charging interest the
                          day you receive your money based off a simple
                          calculation:
                        </p>
                        <p className={classes.netWorthLink}>
                          <span>
                            Total Balance x Interest Rate ÷ 12 = Monthly
                            Interest
                          </span>
                          <span>
                            $10,000 x 5.0% ÷ 12 = $41.67 of monthly interest
                          </span>
                        </p>
                        <p>
                          Repayment begins six months after graduation, or 54
                          months from the day Student B received the loan. To
                          calculate the total balance Student B would need to
                          repay, multiply the monthly interest by the number of
                          months to get the total accrued interest. Then, add
                          that to the balance.
                        </p>
                        <p className={classes.netWorthLink}>
                          <span>
                            Monthly Interest x # of Months = Total Accrued
                            Interest{' '}
                          </span>
                          <span>
                            $41.67 x 54 = $2,250 of total accrued interest
                          </span>
                          <span>
                            <b>
                              $10,000 + $2,250 = $12,250 balance when repayment
                              begins
                            </b>
                          </span>
                        </p>
                        <p>
                          What&apos;s the main difference between subsidized and
                          unsubsidized? Accrued interest!
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
SubsidizedUnsubsidizedLoans.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubsidizedUnsubsidizedLoans);
