import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from '../../../screens/pwi/buying-a-home/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import * as modulesServices from '../../../calculations/modules-services';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );

const marks = [];
for ( let i = 0; i <= 10; i += 2 ) {
  marks.push( {
    value: i,
    label: i === 0 ? '0% of Home Value' : `${i}%`,
  } );
}

const Appreciation = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;
  const [appreciation, setAppreciation] = React.useState( 0 );
  const [isFocus, setIsFocus] = React.useState('');

  const homeGraphData = modulesServices.buyingaHomeOutputBackendGraphData();

  useEffect( () => {
    let appreciationVal = 0;
    if ( inputData.appreciation_in_percentage !== undefined ) {
      appreciationVal = inputData.appreciation_in_percentage;
    } else {
      appreciationVal = 3;
      updateData( 'appreciation_in_percentage', appreciationVal );
    }
    setAppreciation( appreciationVal );
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    if ( newvalue > 100 ) {
      newvalue = 100;
    }
    setAppreciation( newvalue );
    updateData( mainField, newvalue );
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      {
      }
      <p>The increase in a property’s value over time. How much the property appreciates each year depends on several factors including the location and any improvements made to the property.</p>
      <p>
        <a className={ classes.anchorStyle } href="https://www.zillow.com/home-values/" target="_blank" rel="budget-fy2020 noopener noreferrer">According to Zillow</a>
        ,
        {' '}
        home prices increased 4.8% over the last year and are estimated to appreciate 2.8% next year. Use Zillow to research the home you want and discover the appreciation in your area. Then, input that below and see how your home value grows over time.
      </p>
      <div className={ classes.purchaseSlideScroll }>
        <div className={ classes.appSlider }>
          <div className={ classes.flexDisplayNew }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Appreciation: Real Estate' ) } className={ classes.dottedText }>Appreciation</span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ appreciation }
                    ValueLabelComponent={ ValueLabelComponent }
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ 0 }
                    max={ 10 }
                    step={ 0.1 }
                    onChange={ ( e, value ) => updateValue( e, value, 'appreciation_in_percentage', 'slider' ) }
                    marks={ marks }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.appreciationValues ) }>
              <div>
                <NumberFormat
                  decimalScale={ isFocus!=='appreciation_in_percentage'?2:5 }
                  fixedDecimalScale={isFocus!=='appreciation_in_percentage'}
                  allowNegative={ false }
                  customInput={ TextField }
                  thousandSeparator
                  value={ appreciation }
                  onValueChange={ ( e ) => updateValue( e, '', 'appreciation_in_percentage', 'number' ) }
                  onFocus={ ( e ) => {e.target.select(); setIsFocus('appreciation_in_percentage')} }
                  onBlur={emptyIsFocus} 
                />
                <span className={ classes.percentSymbol }>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classNames( classes.higraphDetails, classes.appricationHigraphDetails ) }>
        <h3>Home Value and Mortgage Repayment</h3>
        <hr className={ classes.blueBar } />
        <HighchartsReact
          highcharts={ Highcharts }
          options={ {
            chart: {
              plotBackgroundColor: '#ffffff',
              plotBorderColor: '#cccccc',
              plotBorderWidth: 1,
              plotShadow: false,
              zoomType: false,
              events: {
                load() {
                  const chart = this;
                  const text = chart.renderer.text(
                    `<strong>Monthly Payment: ${formatDollar( modulesServices.moreHighermonthlyPayment() )}</strong>`,
                    chart.plotLeft + 30,
                    chart.plotTop + 35,
                  ).attr( {
                    zIndex: 5,
                  } ).add();
                  const box = text.getBBox();

                  chart.renderer.rect( box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0 )
                    .attr( {
                      fill: '#FFFFFF',
                      stroke: '#2f75b5',
                      'stroke-width': 1,
                      zIndex: 4,
                    } )
                    .add();
                },
              },
            },
            navigation: {
              menuItemStyle: {
                fontSize: '14px',
                textAlign: 'left',
              },
              menuItemHoverStyle: {
                background: '#f5f5f5',
                color: '#4c4c4c',
                fontSize: '14px',
              },
              buttonOptions: {
                height: 40,
                width: 48,
                symbolSize: 24,
                symbolX: 24,
                symbolY: 21,
                symbolStrokeWidth: 2,
                verticalAlign: 'bottom',
                _titleKey: 'y',
              },
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [{
                    textKey: 'downloadPNG',
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  }, {
                    textKey: 'downloadJPEG',
                    onclick() {
                      const chart = this;
                      chart.exportChart( {
                        type: 'image/jpeg',
                      } );
                    },
                  }, {
                    textKey: 'downloadPDF',
                    onclick() {
                      const chart = this;
                      chart.exportChart( {
                        type: 'application/pdf',
                      } );
                    },
                  }, {
                    textKey: 'downloadSVG',
                    onclick() {
                      const chart = this;
                      chart.exportChart( {
                        type: 'image/svg+xml',
                      } );
                    },
                  }],
                },
              },
            },
            credits: {
              enabled: false,
            },
            title: {
              text: '',
            },
            xAxis: [{
              categories: homeGraphData !== undefined && homeGraphData['Bar Graph'] !== undefined ? homeGraphData['Bar Graph']['Years List'] : [],
              crosshair: true,
              labels: {
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            }],
            yAxis: [{ // Primary yAxis
              labels: {
                formatter() {
                  const chart = this;
                  if ( chart.value < 0 ) {
                    return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                  }
                  return formatDollar( Math.round( chart.value ) );
                },
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              title: {
                text: 'Principal and Interest',
                style: {
                  color: '#000000',
                  fontSize: '14px;',
                },
              },
              opposite: true,
            }, { // Secondary yAxis
              gridLineWidth: 0,
              title: {
                text: 'Home Value and Mortgage Balance',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              labels: {
                formatter() {
                  const chart = this;
                  if ( chart.value < 0 ) {
                    return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                  }
                  return formatDollar( Math.round( chart.value ) );
                },
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            }],
            tooltip: {},
            legend: {
              itemStyle: {
                fontSize: '14px',
              },
            },
            series: [{
              name: 'Mortgage Balance',
              type: 'column',
              yAxis: 1,
              data: homeGraphData !== undefined && homeGraphData['Bar Graph'] !== undefined ? homeGraphData['Bar Graph']['Mortgage Balance'] : [],
              tooltip: { 
                valuePrefix: '$',
              },
              color: '#C00000',
            }, {
              name: 'Home Value',
              type: 'column',
              yAxis: 1,
              data: homeGraphData !== undefined  && homeGraphData['Bar Graph'] !== undefined  ? homeGraphData['Bar Graph']['Home Value'] : [],
              tooltip: {
                valuePrefix: '$',
              },
              color: '#4472C4',
            }, {
              name: 'Principal Payback',
              type: 'spline',
              yAxis: 0,
              data: homeGraphData !== undefined  && homeGraphData['Bar Graph'] !== undefined ? homeGraphData['Bar Graph']['Principal Payback'] : [],
              tooltip: {
                valuePrefix: '$',
              },
              color: '#548235',
            }, {
              name: 'Interest',
              type: 'spline',
              yAxis: 0,
              data: homeGraphData !== undefined && homeGraphData['Bar Graph'] !== undefined ? homeGraphData['Bar Graph'].Interest : [],
              marker: {
                enabled: true,
              },
              tooltip: {
                valuePrefix: '$',
              },
              color: '#A9D18E',
            }],
          } }
        />
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

Appreciation.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( Appreciation );
