import tickMark from '../../../assets/img/financial-calculators/black-tick-icon.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  carAffordability: {
    paddingBottom: '20px',
  },
  showMeContent: {
    padding: '0 35px',
    background: '#fff',
    [theme.breakpoints.down( 'xs' )]: {
     padding: '0 10px',
    },
  },
  showMePortion: {
    padding: '20px 0px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0px',
    },
  },
  carAffordableCalcs: {
    border: '2px solid #2f75b5',
    padding: '20px',
    margin: '20px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
      margin:'0px',
    },
  },
  graphHead: {
    textAlign: 'center',
    fontSize: '22px',
    marginTop: '0',
    marginBottom: '0',
    color: '#000',
    fontWeight: '600',
    fontFamily: 'inherit',
    lineHeight: '1.1',
    '& strong': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  blueLine: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
    height: '0',
    boxSizing: 'content-box',
  },
  benchMark: {
    textAlign: 'left',
    lineHeight: '30px',
    paddingTop: '20px',
    paddingLeft: '15px',
    color: '#000',
    fontSize: '22px',
    marginTop: '0',
    fontWeight: '500',
    marginBottom: '10px',
    fontFamily: 'inherit',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
    },
  },
  homeOwner: {
    fontSize: '21px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '18px',
    },
    '& ul': {
      listStyleType: 'none',
      paddingLeft: '15px',
      marginBottom: '10px',
      marginTop: '0',
      '& li': {
        backgroundSize: '16px',
        paddingLeft: '30px',
        backgroundPositionX: 'left',
        backgroundPositionY: '8px',
        backgroundImage: `url(${tickMark})`,
        backgroundRepeat: 'no-repeat',
        color: '#000',
      },
    },
  },
  affordableTables: {
    marginBottom: '30px',
    marginTop: '20px',
    display: 'flex',
    width: '100%',
    position: 'relative',
    padding: '0px 15px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {  
      flexWrap: 'wrap',
    },
    '@media (max-width: 600px)': {     
      overflow: 'auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
    '& table': {
      textAlign: 'right',
      marginTop: '30px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '&:first-child': {
        [theme.breakpoints.down( 'xs' )]: {
          marginTop: '0px',
        },
      },      
    },
  },
  affordableCashTable: {
    width: '40%',    
    fontFamily: 'MuseoSans-300',
    '@media (max-width: 768px)': {  
      width: '100%',
      marginRight: '0%',
    },
    '& thead': {
      '& tr': {
        '& td': {
          fontFamily: 'MuseoSans-500',
          background: '#dae3f3',
          borderBottom: '2px solid #fff',
          fontWeight: '500',
        },
      },
    },
    '& tr': {
      '& td': {
        fontSize: '19px',
        padding: '7px 10px',
        textAlign: 'left',
        color: theme.palette.common.pureBlack,
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '14px',
          padding: '7px 5px',
        },
        '&:first-child': {
          width: '20px',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          background: '#f2f2f2',
          borderBottom: '1px solid #fff',
          textAlign: 'right',
          '&:nth-child(2)': {
            textAlign: 'left',
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        '& td': {
          background: '#dae3f3',
          fontFamily: 'MuseoSans-500',
          fontWeight: '500',
          borderBottom: '1px solid #fff',
          '&:last-child': {
            textAlign: 'right',
          },
        },
        '&:last-child': {
          '& td': {
            borderBottom: '2px solid #0069aa',
            fontStyle: 'italic',
            '&:nth-child(2)': {
              paddingLeft: '25px',
            },
          },
        },
      },
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    zIndex: '99',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      [theme.breakpoints.down( 'xs' )]: {
        borderTopColor: 'transparent !important',
        borderRightColor: '#0069aa !important',
      },
    },
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '18px',
      },
    },
  },
  affordableIncomeTable: {
    width: '57%',
    '@media (max-width: 768px)': {  
      width: '100%',      
    },
    '& thead': {
      '& tr': {
        '& td': {
          textAlign: 'right',
          '&:nth-child(2)': {
            textAlign: 'left',
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        '& td': {
          fontStyle: 'normal !important',
          fontFamily: 'MuseoSans-500',
          textAlign: 'right',
          '&:nth-child(2)': {
            paddingLeft: '10px !important',
            textAlign: 'left',
          },
        },
      },
    },
  },
  carExpenses: {
    '& td': {
      borderBottom: '2px solid #0069aa !important',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  italicFont: {
    '& td': {
      background: '#fff !important',
      fontStyle: 'italic',
      '&:nth-child(2)': {
        paddingLeft: '25px !important',
      },
    },
  },
  bottomText: {
    color: '#0069aa',
    fontStyle: 'italic',
    padding: '0',
    borderTop: '0',
    fontSize: '16px',
  },
  footText: {
    paddingTop: '30px',
    clear: 'both',
    borderTop: '1px solid #d5d5d5',
    margin: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px',
    },
  },
  affordableGraph: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '30px',
    position: 'relative',
    padding: '0px 15px',
    marginTop: '30px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
  },
  carSpacing: {
    '& img': {
      display: 'inline-flex',
      marginTop: '15px',
      marginLeft: '-5px',
      margin: '0 auto',
      verticalAlign: 'middle',
    },
  },
  flexDisplayNew: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  sliderFlex: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& p': {
      minWidth: '170px',
      boxSizing: 'border-box',
      fontSize: '20px',
      margin: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
        minWidth: '140px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
      '& span': {
        margin: '0',
      },
    },
  },
  pwisliderInputs: {
    width: '65%',
    marginRight: '2%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '70%',
    },
    '@media (max-width: 420px)': {    
      width: '52%',
    },
    '& > span': {
      margin: '0',
    },
    '& p': {
      fontSize: '18px !important',
      textAlign: 'right',
      width: '100%',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
    },
  },
  sliderValues: {
    // display: 'inline-table',
    marginTop: '0',
    fontSize: '18px',
    textAlign: 'right',
    paddingTop: '0px',
    width: '12%',
    '@media (max-width: 768px)': {    
      width: '19%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '90px',
    },
    '@media (max-width: 320px)': {    
      width: '70px',
    },
    '& > div': {
      margin: '0 0px 0px 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '10px',
      position: 'relative',
    },
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '100%',
      textAlign: 'right',
      background: '0 0',
      padding: '2px 2px',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
      '&:disabled': {
        background: '#ebebe4',
        cursor: 'not-allowed',
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
    '& div': {
      '&:before': {
        border: 'none !important',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 10px',
      cursor: 'pointer',
      alignItems: 'center',
      marginTop: '0',
      position: 'relative',
      fontSize: '18px',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
    },
  },
  boldValues: {
    '& > div': {
      paddingBottom: '0px',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
    },
  },
  affordableValues: {
    '& > div': {
      paddingRight: '10px',
      marginRight: '5px',
      [theme.breakpoints.down( 'sm' )]: {
        paddingRight: '0px',
      },
    },
  },
  dollarSymbol: {
    marginLeft: '2%',
    position: 'relative',
    left: '0',
    fontSize: '18px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '14px',
    },
  },
  percentSymbol: {
    position: 'absolute',
    fontSize: '18px',
    right: '-10px',
  },
  resetIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    width: '34px',
    cursor: 'pointer',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '18px',
      },
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  resetImage: {
    position: 'absolute',
    left: 'auto',
    boxSizing: 'border-box',
    right: '-50px',
    '@media screen and (min-width: 500px) and (max-width: 767px)': {
      right: '-40px',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
  },
  affordFirst: {
    marginRight: '3%',
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '0%',
    },
  },
} );

export default styles;
