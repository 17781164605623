import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Link } from 'react-scroll';
import ReactTooltip from 'react-tooltip';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import YouTube from 'react-youtube';
import styles from './styles';
import i1 from '../../assets/img/tutorials/begin-journey-icon.png';
import i2 from '../../assets/img/tutorials/students_loan.png';
import i3 from '../../assets/img/tutorials/higher-education-icon.png';
import i4 from '../../assets/img/tutorials/career-path.png';
import i5 from '../../assets/img/tutorials/career-advancement.png';
import i6 from '../../assets/img/tutorials/more-higher-education-icon.png';
import i7 from '../../assets/img/tutorials/addt-career-advancement-icon.png';
import i8 from '../../assets/img/tutorials/investment-icon.png';
import i9 from '../../assets/img/tutorials/family-icon.png';
import i10 from '../../assets/img/tutorials/vacation-icon.png';
import i11 from '../../assets/img/tutorials/buy-car-icon.png';
import i12 from '../../assets/img/tutorials/buying-home-icon.png';
import i13 from '../../assets/img/tutorials/buying-realestate-icon.png';
import i14 from '../../assets/img/tutorials/further_higher_edu.png';
import i15 from '../../assets/img/tutorials/furthercareeradvancement.png';
import fi from '../../assets/img/tutorials/financial_ind_tutorial_img1.jpg';
import videoIcon from '../../assets/img/tutorials/video_ic.png';
import existingAssets from '../../assets/img/tutorials/existing_assets_tutorial.png';
import careerPath from '../../assets/img/tutorials/career-path-tutorial1.jpg';
import higherEducation from '../../assets/img/tutorials/higher_ed-tutorials_1_new.jpg';
import investmentOne from '../../assets/img/tutorials/investments_tutorial_1.jpg';
import investmentTwo from '../../assets/img/tutorials/investments_tutorial_2.jpg';
import csVideo from '../../assets/img/tutorials/career-sketch-video-img.jpg';
import dashboardVideo from '../../assets/img/tutorials/tutorial_dashboard.jpg';
import taxStatement from '../../assets/img/tutorials/title-screen-Tax-Statement.jpg';
import collegeImage from '../../assets/img/tutorials/college_image.png';
import careersImage from '../../assets/img/tutorials/careers_image.png';
import comingSoon from '../../assets/img/tutorials/comingsoon_pic.png';
import planningCollege from '../../assets/img/tutorials/Power_of_Financial_Life_Planning_College_Step-by-Step_Guide_Jan_2019.pdf';
import planningCareer from '../../assets/img/tutorials/Power_of_Financial_Life_Planning_Careers_Step-by-Step_Guide_Jan_2019.pdf';

const Tutorials = ( props ) => {
  const { classes } = props;

  const opts = {
    host: 'https://www.youtube-nocookie.com',
    width: '780',
    height: '450',
    playerVars: {
      autoplay: 1,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  const [tutorialVideo, setTutorialVideo] = React.useState( false );
  const [currentVideo, setCurrentVideo] = React.useState( '' );
  const [currentTab, setCurrentTab] = React.useState( 'modules' );

  const playVideo = ( data ) => {
    setCurrentVideo( data );
    setTutorialVideo( true );
  };

  const handleTutorialVideo = () => {
    setTutorialVideo( false );
  };

  const handleTabs = ( value ) => {
    setCurrentTab( value );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bannerBlock }>
        <div className={ classes.bannerImage } />
        <div className={ classes.bannerCaption }>
          <Typography variant="h2" component="h2">
            Tutorials
          </Typography>
        </div>
      </div>
      <div className={ classes.tabsSection }>
        <div className={ classes.mainTab }>
          <Tabs>
            <div className={ classes.tutorialsBlock }>
              <TabList className={ classes.tabsRow }>
                <Tab className={ currentTab === 'modules' ? classes.activeModule : classes.unActiveModule } onClick={ () => handleTabs( 'modules' ) }>Modules</Tab>
                <Tab className={ currentTab === 'tools' ? classes.activeModule : classes.unActiveModule } onClick={ () => handleTabs( 'tools' ) }>Tools</Tab>
                <Tab className={ currentTab === 'financialStatements' ? classes.activeModule : classes.unActiveModule } onClick={ () => handleTabs( 'financialStatements' ) }>Financial Statements</Tab>
                <Tab className={ currentTab === 'powerOfFinancialLifePlanning' ? classes.activeModule : classes.unActiveModule } onClick={ () => handleTabs( 'powerOfFinancialLifePlanning' ) }>Power of Financial Life Planning</Tab>
              </TabList>
            </div>
            <div className={ classes.container }>
              <TabPanel>
                <div className={ classes.modulesBlock }>
                  <Typography variant="h2" component="h2">
                    Tutorials on Using Inkwiry Modules
                  </Typography>
                  <div className={ classes.iconsRow }>
                    <ul>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Financial Independence"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section1"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i1 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Existing Assets & liabilities"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section2"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i2 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Higher Education"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section3"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i3 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Career Path"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section4"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i4 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Carrer Advancement"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section4"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i5 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="More Higher Education"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section3"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i6 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Additional Career Advancement"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section4"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i7 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Investments"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">
                          <img src={ i8 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Family"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">
                          <img src={ i9 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Vacation"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">
                          <img src={ i10 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Buying a Car"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">
                          <img src={ i11 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Buying a Home"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">
                          <img src={ i12 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Real Estate Investment"
                        data-offset="{'top': -2}"
                      >
                        <a href="/">

                          <img src={ i13 } alt="" />
                        </a>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Further Higher Education"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section3"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i14 } alt="" />
                        </Link>
                      </li>
                      <li
                        data-for="modulesTooltip"
                        data-tip="Further Career Advancement"
                        data-offset="{'top': -2}"
                      >
                        <Link
                          to="section4"
                          spy
                          delay={ 0 }
                          smooth
                          duration={ 600 }
                          offset={ -80 }
                        >
                          <img src={ i15 } alt="" />
                        </Link>
                      </li>
                    </ul>
                    <ReactTooltip id="modulesTooltip" place="top" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </div>
                </div>
                <div className={ classes.careerPathContent }>
                  <div className={ classes.careerRow } id="section1">
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'm1FDvn09MsA' ) }>
                            Financial Independence
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'm1FDvn09MsA' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>My Dashboard (0:20)</li>
                          <li>Highly intelligent timeline (1:10)</li>
                          <li>How to use the Simulator Panel (1:30)</li>
                          <li>
                            How to save / load / clear / delete with Inkwiry
                            Sight (2:55)
                          </li>
                          <li>Start inputting your data (3:50)</li>
                          <li>Cash flow check (4:50)</li>
                          <li>Navigation bar (5:10)</li>
                          <li>
                            Summary documents and personal financial statements
                            (5:30)
                          </li>
                          <li>
                            Show me visualizations – charts &amp; graphs (6:10)
                          </li>
                          <li>
                            Boosting my financial literacy with the FC (6:50)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ fi } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'm1FDvn09MsA' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={ classes.careerRow } id="section2">
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'T1Uruoj6W5I' ) }>
                            Existing Assets & Liabilities
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'T1Uruoj6W5I' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>
                            Ask &quot;What If?&quot; and see results instantly (0:35)
                          </li>
                          <li>Highly intelligent timeline (1:40)</li>
                          <li>
                            Start your inputs with existing student loans (2:00)
                          </li>
                          <li>
                            How to adjust your inputs to repay a loan early
                            (2:30)
                          </li>
                          <li>Existing credit card debt (3:00)</li>
                          <li>
                            How the FC calculates your car loan monthly payment
                            (3:25)
                          </li>
                          <li>
                            Show me visualizations - charts & graphs (4:05)
                          </li>
                          <li>
                            Existing liabilities by average interest rate (4:55)
                          </li>
                          <li>
                            What if I refinanced my student loans and invested
                            the annual savings? (5:30)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ existingAssets } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'T1Uruoj6W5I' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={ classes.careerRow } id="section4">
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'QDY58xs3xuc' ) }>
                            Career
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'QDY58xs3xuc' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>Highly intelligent timeline (0:40)</li>
                          <li>Review of the Summary Documents (1:35)</li>
                          <li>Start by inputting your career move (3:00)</li>
                          <li>
                            Summary tables including estimated taxes (4:15)
                          </li>
                          <li>Retirement accounts (5:15)</li>
                          <li>
                            Show me visualizations starts with sources and uses
                            (6:05)
                          </li>
                          <li>Breakdown of living expenses (6:50)</li>
                          <li>
                            Retirement account contributions and earnings (7:10)
                          </li>
                          <li>What if savings tool (7:55)</li>
                          <li>
                            Boosting my financial literacy with the FC (8:56)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ careerPath } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'QDY58xs3xuc' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={ classes.careerRow } id="section3">
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'y6XEzO1Vmzc' ) }>
                            Higher Education
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'y6XEzO1Vmzc' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>Highly intelligent timeline (0:16)</li>
                          <li>
                            Supporting tools and features of a module (0:45)
                          </li>
                          <li>
                            Start by inputting your school of choice (1:40)
                          </li>
                          <li>Three types of student loans (2:18)</li>
                          <li>
                            Working during school, estimated taxes, and family
                            contribution (2:43)
                          </li>
                          <li>Living expenses: on and off-campus (3:30)</li>
                          <li>
                            Show me visualizations – charts & graphs (4:10)
                          </li>
                          <li>
                            Balancing your sources and uses of funds (5:00)
                          </li>
                          <li>
                            Calculating the payback period on your investment
                            (5:40)
                          </li>
                          <li>
                            Boosting my financial literacy with the FC (6:20)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ higherEducation } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'y6XEzO1Vmzc' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={ classes.careerRow } id="section5">
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'dA1XQyKO-uE' ) }>
                            Investments: Part 1
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'dA1XQyKO-uE' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>Why the Investments module is unique (0:10)</li>
                          <li>How cash flows to investments (1:20)</li>
                          <li>
                            Two ways to grow your investments in the FC (1:45)
                          </li>
                          <li>
                            Automating investments with max balances (2:00)
                          </li>
                          <li>
                            Walkthrough an example of cash flowing to
                            investments at year end (2:45)
                          </li>
                          <li>
                            How cash flows from investments back to your cash
                            account (4:00)
                          </li>
                          <li>What are you investing for? (5:45)</li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ investmentOne } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'dA1XQyKO-uE' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={ classes.careerRow }>
                    <Grid container>
                      <Grid item sm={ 5 }>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={ classes.mainTitle }
                        >
                          <span aria-hidden="true" onClick={ () => playVideo( 'c0FWrlQyj_I' ) }>
                            Investments: Part 2
                          </span>
                        </Typography>
                        <h4 className={ classes.subTitle }>
                          <span aria-hidden="true" onClick={ () => playVideo( 'c0FWrlQyj_I' ) }>
                            Quickstart Tutorial
                          </span>
                        </h4>
                        <ul className={ classes.modulesList }>
                          <li>Highly intelligent timeline (0:05)</li>
                          <li>Review of the Summary Documents (1:00)</li>
                          <li>Short-term Investments (2:05)</li>
                          <li>Understanding liquidity (2:40)</li>
                          <li>What if investments tool (4:25)</li>
                          <li>Long-term Investments (6:20)</li>
                          <li>
                            Financial gifts and charitable contributions (7:15)
                          </li>
                          <li>
                            Show me visualizations - charts & graphs (7:40)
                          </li>
                          <li>
                            Get your money to work as hard as you do (8:15)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item sm={ 7 }>
                        <div className={ classes.modulesImage }>
                          <figure>
                            <img src={ investmentTwo } alt="" />
                            <img
                              aria-hidden="true"
                              className={ classes.videoIcon }
                              src={ videoIcon }
                              alt=""
                              onClick={ () => playVideo( 'c0FWrlQyj_I' ) }
                            />
                          </figure>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={ classes.toolsBlock }>
                  <div className={ classes.toolsRow }>
                    <Typography variant="h2" component="h2">
                      Tutorials on Using Inkwiry Tools
                    </Typography>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            <span aria-hidden="true" onClick={ () => playVideo( 'EmCH87cPPs4' ) }>
                              Career Sketching
                            </span>
                          </Typography>
                          <h4 className={ classes.subTitle }>
                            <span aria-hidden="true" onClick={ () => playVideo( 'EmCH87cPPs4' ) }>
                              Quickstart Tutorial
                            </span>
                          </h4>
                          <ul className={ classes.modulesList }>
                            <li>3-step process to career sketching (0:24)</li>
                            <li>Step 1: Drag and drop life events (0:44)</li>
                            <li>
                              Step 2: Double click and enter your details (0:54)
                            </li>
                            <li>Career sketch cards (1:40)</li>
                            <li>Career sketch timeline (2:04)</li>
                            <li>
                              How to create and save your own scenario (2:35)
                            </li>
                            <li>Summary documents and output (3:25)</li>
                            <li>How to use the Simulator Panel (3:44)</li>
                            <li>
                              Ask &quot;What If?&quot; and see results instantly (4:00)
                            </li>
                            <li>
                              Clear the timeline and start building your own
                              sketch (4:34)
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.modulesImage }>
                            <figure>
                              <img
                                aria-hidden="true"
                                src={ csVideo }
                                alt=""
                                onClick={ () => playVideo( 'EmCH87cPPs4' ) }
                              />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            <span aria-hidden="true" onClick={ () => playVideo( 'Ux-Z87zPTgc' ) }>
                              Dashboard
                            </span>
                          </Typography>
                          <h4 className={ classes.subTitle }>
                            <span aria-hidden="true" onClick={ () => playVideo( 'Ux-Z87zPTgc' ) }>
                              Quickstart Tutorial
                            </span>
                          </h4>
                          <ul className={ classes.modulesList }>
                            <li>Start with the top panel (0:50)</li>
                            <li>The two tabs of the dashboard (1:40)</li>
                            <li>My Activity panel (2:30)</li>
                            <li>
                              The three major charts and graphs of the dashboard
                              (3:00)
                            </li>
                            <li>Breakdown of income and expenses (3:50)</li>
                            <li>
                              Assets, Liabilities, Taxes and Net Change in Cash
                              (4:20)
                            </li>
                            <li>Key Performance Indicators, or KPIs (5:50)</li>
                            <li>
                              Career Sketches tab: compare sketches side-by-side
                              (6:40)
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.modulesImage }>
                            <figure>
                              <img
                                aria-hidden="true"
                                src={ dashboardVideo }
                                alt=""
                                onClick={ () => playVideo( 'Ux-Z87zPTgc' ) }
                              />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={ classes.toolsBlock }>
                  <div className={ classes.toolsRow }>
                    <Typography variant="h2" component="h2">
                      Tutorials on Understanding Your Financial Statements
                    </Typography>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            <span aria-hidden="true" onClick={ () => playVideo( 'LVI5nQWlkIM' ) }>
                              Tax Statement
                            </span>
                          </Typography>
                          <h4 className={ classes.subTitle }>
                            <span aria-hidden="true" onClick={ () => playVideo( 'LVI5nQWlkIM' ) }>
                              Quickstart Tutorial
                            </span>
                          </h4>
                          <ul className={ classes.modulesList }>
                            <li>
                              Overview of the sections of the Tax Statement
                              (2:00)
                            </li>
                            <li>
                              How the FC estimates Federal Taxable Income (2:35)
                            </li>
                            <li>How the FC estimates Total Taxes (2:50)</li>
                            <li>
                              Walk through an example of how taxes are estimated
                              (3:50)
                            </li>
                            <li>Adjustments to Income (4:25)</li>
                            <li>
                              Example of interactive pop ups: Student Loan
                              Interest Adjustment (5:25)
                            </li>
                            <li>
                              Itemized Deductions vs Standard Deduction (8:20)
                            </li>
                            <li>
                              How Federal Income Taxes are calculated in the FC
                              (9:25)
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.modulesImage }>
                            <figure>
                              <img
                                aria-hidden="true"
                                src={ taxStatement }
                                alt=""
                                onClick={ () => playVideo( 'LVI5nQWlkIM' ) }
                              />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={ classes.financialBlock }>
                  <Typography variant="h2" component="h2">
                    Tutorials on the Power of Financial Life Planning
                  </Typography>
                  <div className={ classes.toolsRow }>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            College
                          </Typography>
                          <h4 className={ classes.subTitle }>
                            <a target="_blank" rel="noopener noreferrer" href={ planningCollege } download>Step-by-Step Guide</a>
                          </h4>
                          <ul className={ classes.financeList }>
                            <li>
                              <span>PART 1</span>
                              :
                              <span className={ classes.tutorialsIntro } aria-hidden="true" onClick={ () => playVideo( 'EZJxdJAPuks' ) }>Introduction</span>
                            </li>
                            <li>
                              <span>PART 2</span>
                              :
                              <span>
                                Video Coming Soon: Access the Step-by-Step Guide
                                Above
                              </span>
                            </li>
                            <li>
                              <span>PART 3</span>
                              :
                              <span>
                                Video Coming Soon: Access the Step-by-Step Guide
                                Above
                              </span>
                            </li>
                            <li>
                              <span>PART 4</span>
                              :
                              <span>
                                Video Coming Soon: Access the Step-by-Step Guide
                                Above
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.financeImage }>
                            <figure>
                              <img src={ collegeImage } alt="" />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={ classes.toolsRow }>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            Careers
                          </Typography>
                          <h4 className={ classes.subTitle }>
                            <a target="_blank" rel="noopener noreferrer" download href={ planningCareer }>Step-by-Step Guide</a>
                          </h4>
                          <ul className={ classes.financeCareer }>
                            <li>
                              <span>PART 1</span>
                              :
                              <span aria-hidden="true" onClick={ () => playVideo( 'kfZdb3zZ_i4' ) }>Introduction</span>
                            </li>
                            <li>
                              <span>PART 2</span>
                              :
                              <span aria-hidden="true" onClick={ () => playVideo( 'NeUNGMHylLQ' ) }>Managing student loans effectively</span>
                            </li>
                            <li>
                              <span>PART 3</span>
                              :
                              <span aria-hidden="true" onClick={ () => playVideo( '4GCyjA4VzTE' ) }>
                                Acquiring skills and knowledge sets in your
                                career
                              </span>
                            </li>
                            <li>
                              <span>PART 4</span>
                              :
                              <span aria-hidden="true" onClick={ () => playVideo( 'm9Z5Vt7t2O8' ) }>
                                Investments and the power of &quot;What if&quot;
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.financeImage }>
                            <figure>
                              <img src={ careersImage } alt="" />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={ classes.toolsRow }>
                    <div className={ classes.toolsContent }>
                      <Grid container>
                        <Grid item sm={ 5 }>
                          <Typography
                            variant="h3"
                            component="h3"
                            className={ classes.mainTitle }
                          >
                            Apprenticeship
                          </Typography>

                          <ul className={ classes.financeList }>
                            <li>
                              <span>PART 1</span>
                              :
                              <span>Coming Soon</span>
                            </li>
                            <li>
                              <span>PART 2</span>
                              :
                              <span>Coming Soon</span>
                            </li>
                            <li>
                              <span>PART 3</span>
                              :
                              <span>Coming Soon</span>
                            </li>
                            <li>
                              <span>PART 4</span>
                              :
                              <span>Coming Soon</span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item sm={ 7 }>
                          <div className={ classes.financeImage }>
                            <figure>
                              <img src={ comingSoon } alt="" />
                            </figure>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
      <div className={ classes.videoModalPopup }>
        <Dialog
          open={ tutorialVideo }
          onClose={ handleTutorialVideo }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.pendinglistPopup }
        >
          <DialogContent className={ classes.videoPopup }>
            <div className={ classes.popupClose }>
              <span aria-hidden="true" onClick={ handleTutorialVideo }>
                X
              </span>
            </div>
            <div className={ classes.pendingNumList }>
              {/* <YouTube
                video={ currentVideo }
                autoplay="1"
                rel="0"
                disablekb="0"
                showinfo="0"
                ccLoadPolicy="0"
                ivLoadPolicy="0"
                modestbranding="1"
              /> */}
              <YouTube videoId={ currentVideo } opts={ opts } />
            </div>
            <div />
          </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

Tutorials.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Tutorials );
