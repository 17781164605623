import React, {
  useState, useRef, useEffect,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import Highcharts from 'highcharts';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import Rating from '../../../components/common/rating';
import { renderIcons } from '../../../utilities/chartCommonFunctions';
import highchartsData from '../../../utilities/highchartsData';
import q16 from '../../../assets/img/awareness/check-img.png';
import q22 from '../../../assets/img/awareness/q22.jpg';
import marketStrength from '../../../assets/img/awareness/market_strength.png';
import infoIconContrast from '../../../assets/img/awareness/info-icon-contrast.png';
import feedbackIcon from '../../../assets/img/awareness/feedback-icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pageloader from '../../../components/ui/pageloader';

const GRAPH_DATE = loader( '../../../graphql/schema/graph-data/data.graphql' );

const SAVE_QUESTIONS = loader( '../../../graphql/schema/awareness/save-challenge.graphql' );
const GET_QUESTIONS = loader( '../../../graphql/schema/awareness/get-challenge.graphql' );
const REDO_QUESTIONS = loader( '../../../graphql/schema/awareness/redo-challenge.graphql' );

/* eslint react/prop-types: 0 */

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
HighchartsMore( Highcharts );
SolidGauge( Highcharts );

const Awareness = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -130,
    align: 'top',
    duration: 500,
  };

  let scoreValSection = useRef( null );
  let ratingValSection = useRef( null );

  const correctAnswers = ['42', '46', '500', '41', '20k-30k', '50', '5', '1000000', '30k-40k', '50', 'yes', '60000', 'Chandler'];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 13; i += 1 ) {
    const questionsObject = {
      qId: i + 1, answer: '', correctAns: correctAnswers[i], rating: 0, scroll: defaultReference, status: '',
    };
    questionMainAns.push( questionsObject );
  }
  let quesionSection = useRef( null );
  let feedBackSection = useRef( null );

  const [name, setName] = React.useState( '' );
  const [age, setAge] = React.useState( '' );
  const [validation, setValidation] = React.useState( false );
  const [awarenessId, setAwarenessId] = React.useState( '' );
  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [myScoreContent, setMyscoreContent] = React.useState( false );
  const [questionContent, setQuestionContent] = React.useState( false );
  const [avgScore, setAvgScore] = React.useState( 0 );
  const [avgRating, setAvgRating] = React.useState( 0 );
  const [unemploymentData, setUnemploymentData] = React.useState( '' );
  const [loading, setLoading] = React.useState( true );

  const [saveQuestion] = useMutation( SAVE_QUESTIONS, {
    onCompleted( response ) {
      if ( response.awarenessChallengeQuestion.status === true ) {
        setAwarenessId( response.awarenessChallengeQuestion.awarenessId );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const [redoQuestions] = useMutation( REDO_QUESTIONS, {
    onCompleted( response ) {
      if ( response.redoAwarenessChallengeQuestion.status === true ) {
        setQuestions( questionMainAns );
        setMyscoreContent( false );
        setAwarenessId( '' );
        setLoading( false );
      }
    },
    onError( ) {
      setLoading( false );
    },
  } );

  useQuery( GRAPH_DATE, {
    variables: {
      from: ['unemployment'],
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      const unemploymentIndex = _.findIndex( response.getIncomeGrowthGraphData.data, { name: 'unemployment' } );
      if ( unemploymentIndex >= 0 ) {
        setUnemploymentData( response.getIncomeGrowthGraphData.data[unemploymentIndex] );
      }
    },
  } );

  useQuery( GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( response.getAwarenessQuestion.status === true ) {
        setAge( response.getAwarenessQuestion.age );
        setName( response.getAwarenessQuestion.name );
        setAwarenessId( response.getAwarenessQuestion.awarenessId );
      }
      let updateQuestions = [...questions];
      const questionsData = response.getAwarenessQuestion.questions;
      if ( questionsData.length > 0 ) {
        updateQuestions = updateQuestions.map( ( question ) => {
          const data = question;
          const questionIndex = _.findIndex( questionsData, { question_id: data.qId } );
          if ( questionIndex >= 0 ) {
            data.answer = questionsData[questionIndex].answer;
            data.rating = questionsData[questionIndex].rating;
            if ( data.answer === data.correctAns ) {
              data.status = 'C';
            } else {
              data.status = 'W';
            }
          }
          return data;
        } );
        setQuestions( updateQuestions );
      }
      setLoading( false );
    },
    onError( ) {
      setLoading( false );
    },
  } );

  // Use Effect for update progress bar while answering the quesions
  useEffect( () => {
    let answerQuestions = _.reject( questions, ['answer', ''] ).length;
    answerQuestions += _.reject( questions, ['rating', 0] ).length;
    let progressBar = answerQuestions * ( 100 / 28 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
  }, [questions] );

  useEffect( () => {
    if ( questionContent ) {
      scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } );
    }
  }, [questionContent] );

  useEffect( () => {
    if ( myScoreContent ) {
      scrollToComponent( feedBackSection, { offset: -56, align: 'top', duration: 500 } );
    }
  }, [myScoreContent] );
  const handleAgeChange = ( event ) => {
    setAge( event.target.value );
  };

  const handleNameChange = ( event ) => {
    setName( event.target.value );
  };

  const startChallenge = () => {
    setValidation( true );
    if ( name !== '' && age !== '' ) {
      setQuestionContent( true );
      if ( questionContent ) {
        scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } );
      }
      if ( awarenessId === '' ) {
        saveQuestion( {
          variables: {
            data: {
              name,
              age,
              type: 'details',
              awareness_analytics_id: awarenessId,
            },
          },
        } );
      }
    }
  };

  const redoChallengeDetails = ( ) => {
    if ( awarenessId !== '' ) {
      setLoading( true );
      redoQuestions( {
        variables: {
          id: awarenessId,
        },
      } );
    }
  };

  const redoChallenge = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Redo Challenge</h3>
            <p>Are you sure you want to redo the Awareness Challenge? This will clear all your answers, reset the feedback and start fresh.</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => {
                redoChallengeDetails();
                onClose();
              } }
            >
              YES
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    setQuestions( newQuesions );

    saveQuestion( {
      variables: {
        data: {
          name,
          age,
          type: 'answer',
          question_id: index + 1,
          answer: event.target.value,
          question_status: newQuesions[index].status,
          awareness_analytics_id: awarenessId,
        },
      },
    } );
  };

  const handleRating = ( index, rating ) => {
    const newQuesions = [...questions];
    newQuesions[index].rating = rating;
    setQuestions( newQuesions );

    saveQuestion( {
      variables: {
        data: {
          type: 'rating',
          question_id: index + 1,
          rating,
          awareness_analytics_id: awarenessId,
        },
      },
    } );
  };

  const handleMyScore = () => {
    let unAnswerQuestions = _.filter( questions, ['answer', ''] );
    unAnswerQuestions = _.filter( questions, ['rating', 0] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'qId' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      setMyscoreContent( true );
      const correctAns = _.filter( questions, ['status', 'C'] ).length;
      setAvgScore( correctAns * ( 100 / 14 ) );
      setAvgRating( _.meanBy( questions, 'rating' ) );
      if ( myScoreContent ) {
        scrollToComponent( feedBackSection, { offset: -56, align: 'top', duration: 500 } );
      }

      saveQuestion( {
        variables: {
          data: {
            type: 'score',
            score: correctAns * ( 100 / 14 ),
            avg_rating: _.meanBy( questions, 'rating' ),
            awareness_analytics_id: awarenessId,
          },
        },
      } );
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport2 = ( el ) => {
    let element = el;
    if ( element !== null && element.offsetTop !== undefined ) {
      let top = element.offsetTop;
      let left = element.offsetLeft;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      while ( element.offsetParent ) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top < ( window.pageYOffset + window.innerHeight )
        && left < ( window.pageXOffset + window.innerWidth )
        && ( top + height ) > window.pageYOffset
        && ( left + width ) > window.pageXOffset
      );
    }
    return false;
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( questionContent ) {
        let feedBackSectionCondition = true;
        if ( feedBackSection !== null && feedBackSection.current === undefined ) {
          if ( feedBackSection !== null && feedBackSection.getBoundingClientRect().top < ( window.innerHeight / 2 ) ) {
            feedBackSectionCondition = false;
          }
        }

        if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 && feedBackSectionCondition === true ) {
          document.getElementById( 'awareness_title' ).style.position = 'fixed';
          document.getElementById( 'awareness_title_sub' ).style.display = 'block';
        } else {
          document.getElementById( 'awareness_title' ).style.position = '';
          document.getElementById( 'awareness_title_sub' ).style.display = 'none';
        }

        if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 && feedBackSectionCondition === true ) {
          document.getElementById( 'sticky_numbers' ).style.left = '30px';
        } else {
          document.getElementById( 'sticky_numbers' ).style.left = '-11em';
        }

        for ( let i = 0; i < 14; i += 1 ) {
          const element = $( questionMainAns[i].scroll );
          let subElement = false;
          if ( questions[i].answer !== '' ) {
            subElement = true;
          }

          if ( elementInViewport( element ) === true || ( subElement === true && ( elementInViewport( element.find( '>div:first-child' ) ) || elementInViewport( element.find( '>div:last-child' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h3' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h2' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'div' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'ul' ) ) ) ) ) {
            element.css( 'opacity', '1' );
            element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
            $( '#sticky_numbers li ' ).removeClass( 'stick_number_active_class' );
            if ( i <= 6 ) {
              $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).first().addClass( 'stick_number_active_class' );
            } else {
              $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).first().addClass( 'stick_number_active_class' );
            }
            break;
          }
        }

        if ( ( myScoreContent === true && elementInViewport2( feedBackSection ) ) || elementInViewport2( document.getElementById( 'footer' ) ) || ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
          document.getElementById( 'progress-bar' ).style.display = 'none';
        } else {
          document.getElementById( 'progress-bar' ).style.display = 'block';
        }
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.awarenessBody }>
        <div>
          <div className={ classes.awarenessBannerSec }>
            <div className={ classes.container }>
              <div className={ classes.bannerContent }>
                <Typography variant="h2" component="h2">Awareness Challenge</Typography>
              </div>
            </div>
          </div>

          <div className={ classes.awarenessContentRow }>
            <div className={ classes.container }>
              <div className={ classes.awarenessTitle }>
                <Typography variant="h2" component="h2">
                  What&apos;s your awareness score?
                  <span className={ classes.textSup }>TM</span>
                </Typography>
                <p>
                  Raise your awareness and elevate your worldview so you can see general conditions and trends that will change
                  the way you think about planning. Test your knowledge of financial life data, concepts, and trends that are important to your future.
                </p>
              </div>
              <div className={ classes.awarenessForm }>
                <Typography variant="h1" component="h1">What&apos;s your first name?*</Typography>
                <form className={ classes.awernessForm } noValidate autoComplete="off">
                  <div className={ classes.awareFormInput }>
                    <input className={ classes.awareInput } value={ name } onChange={ ( e ) => handleNameChange( e ) } />
                  </div>
                  {validation === true && name === '' ? ( <p className={ classes.errorText }>Please enter your first name</p> ) : ''}
                  <div className={ classes.ageRange }>
                    <p className={ classes.ageRangeTitle }>What&apos;s your age range?*</p>
                    <ul className={ `${classes.optionList} ${classes.awernesssOptionList}` }>
                      <li>
                        <p>
                          <label className={ classNames( classes.spanRadio, age === '15years' && classes.ageChecked ) } htmlFor="15years">
                            15 or under
                            <input type="radio" name="age" value="15years" id="15years" onChange={ ( e ) => handleAgeChange( e, '15years' ) } checked={ age === '15years' } />
                          </label>
                        </p>
                      </li>
                      <li>
                        <p>
                          <label htmlFor="16to18" className={ classNames( classes.spanRadio, age === '16-18' && classes.ageChecked ) }>
                            16 - 18
                            <input type="radio" id="16to18" name="age" value="16-18" onChange={ ( e ) => handleAgeChange( e, '16-18' ) } checked={ age === '16-18' } />
                          </label>
                        </p>
                      </li>
                      <li>
                        <p>

                          <label htmlFor="19to24" className={ classNames( classes.spanRadio, age === '19-24' && classes.ageChecked ) }>
                            19 - 24
                            <input type="radio" id="19to24" name="age" value="19-24" onChange={ ( e ) => handleAgeChange( e, '19-24' ) } checked={ age === '19-24' } />
                          </label>
                        </p>
                      </li>
                      <li>
                        <p>
                          <label htmlFor="25to32" className={ classNames( classes.spanRadio, age === '25-32' && classes.ageChecked ) }>
                            25 - 32
                            <input type="radio" id="25to32" name="age" value="25-32" onChange={ ( e ) => handleAgeChange( e, '25-32' ) } checked={ age === '25-32' } />
                          </label>
                        </p>
                      </li>
                      <li>
                        <p>
                          <label htmlFor="33plus" className={ classNames( classes.spanRadio, age === '33+' && classes.ageChecked ) }>
                            33+
                            <input type="radio" id="33plus" name="age" value="33+" onChange={ ( e ) => handleAgeChange( e, '33+' ) } checked={ age === '33+' } />
                          </label>
                        </p>
                      </li>
                    </ul>
                    {validation === true && age === '' ? ( <p className={ classes.errorText }>Please select your age range</p> ) : ''}
                  </div>
                  <div className={ classes.scBtn }>
                    <Button onClick={ () => startChallenge() } className={ classNames( classes.startChallenge, progress > 0 && progress < 100 && classes.resumeChallenge, progress === 100 && classes.finishChallenge ) }>
                      {progress === 0 && (
                        <span>
                          START THE CHALLENGE
                        </span>
                      )}
                      {progress > 0 && progress < 100 && (
                        <span>
                          RESUME THE CHALLENGE
                        </span>
                      )}
                      {progress === 100 && (
                        <span>
                          REVIEW YOUR FEEDBACK
                        </span>
                      )}
                    </Button>
                    {awarenessId !== '' && questionContent === true && ( <span role="button" aria-hidden="true" onClick={ () => redoChallenge() } className={ classes.redoAwarness }>REDO</span> )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {questionContent === true && (
        <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
          <div className={ classNames( classes.ChallengesQuizHead ) } id="awareness_title">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">INKWIRY AWARENESS CHALLENGE</Typography>
            </div>
          </div>

          <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">&nbsp;</Typography>
            </div>
          </div>

          <div id="sticky_numbers" className={ classNames( classes.ChallengesNumbers ) }>
            <ul>
              {
                  [1, 2, 3, 4, 5, 6, 7].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].rating !== 0 && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].rating !== 0 && questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <ul>
              {
                  [8, 9, 10, 11, 12, 13, 14].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].rating !== 0 && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].rating !== 0 && questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <div className={ classes.stickyBottom }>
              <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                <span>
                  <i
                    className="las la-arrow-up"
                    data-for="returnStart"
                    data-tip="Return to start"
                    data-offset="{'right': 3}"
                  />
                </span>
                <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
              </Button>
            </div>
            {
              progress === 100 && (
                <div className={ classes.feedbackImg }>
                  <Button onClick={ handleMyScore } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <figure>
                        <img
                          src={ feedbackIcon }
                          alt="Feedback"
                          data-for="returnFeedback"
                          data-tip="Go to Feedback"
                        />
                        <ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
                      </figure>
                    </span>
                  </Button>
                </div>
              )
           }
          </div>
          <div className={ classes.questionRow }>
            <div className={ classes.container }>
              <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>1</span>
                  <p className={ classes.questionTitle }>Just by writing down your goals on a regular basis, you increase your chances of success by how much?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[0].answer } onChange={ ( e ) => handleAnswer( e, 0 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === '12' && classes.ansWrong ) } value="12" control={ <Radio /> } label="12%" disabled={ questions[0].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === '28' && classes.ansWrong ) } value="28" control={ <Radio /> } label="28%" disabled={ questions[0].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer !== '' && classes.ansRight ) } value="42" control={ <Radio checked={ questions[0].answer !== '' } /> } label="42%" disabled={ questions[0].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === '60' && classes.ansWrong ) } value="60" control={ <Radio /> } label="60%" disabled={ questions[0].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {
                  questions[0].answer !== ''
                    && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, questions[0].answer === questions[0].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[0].answer === questions[0].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                        <div className={ classes.ansBox }>
                          <Typography className={ classes.boxTitle } variant="h2" component="h2">It&apos;s easy to increase your chances for success</Typography>
                          <p className={ classes.boxPara }>
                            You become
                            {' '}
                            <span className={ classes.spanColor }>42% more likely</span>
                            {' '}
                            to achieve your goals by writing them down on a regular basis. Inkwiry empowers you to build your plan for financial independence increasing your chances of success.
                          </p>
                          <p className={ classes.boxPara }>
                            If you were making a bet and had the opportunity to increase your chances -
                            <span className={ classes.spanStrong }>wouldn&apos;t you take it?</span>
                          </p>
                          <p className={ classes.boxPara }>
                            Especially if that bet is on
                            {' '}
                            <span className={ classes.spanColor }>your future.</span>
                          </p>
                          <div className={ classes.senseRatingComments }>
                            <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                            <Rating value={ questions[0].rating } questionIndex={ 0 } handleRating={ handleRating } />
                          </div>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[1].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )
                  }
              </div>

              <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>2</span>
                  <p className={ classes.questionTitle }>What % of Americans have $0 saved for retirement?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[1].answer } onChange={ ( e ) => handleAnswer( e, 1 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === '33' && classes.ansWrong ) } value="33" control={ <Radio /> } label="33%" disabled={ questions[1].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === '72' && classes.ansWrong ) } value="72" control={ <Radio /> } label="72%" disabled={ questions[1].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer !== '' && classes.ansRight ) } value="46" control={ <Radio checked={ questions[1].answer !== '' } /> } label="46%" disabled={ questions[1].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === '58' && classes.ansWrong ) } value="58" control={ <Radio /> } label="58%" disabled={ questions[1].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[1].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[1].answer === questions[1].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[1].answer === questions[1].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Retirement savings are way off track</Typography>

                        <p className={ classes.boxPara }>
                          How powerful is planning?
                          {' '}
                          <span className={ classes.spanColor }>250% is how much more your savings can grow over your lifetime when you have a ﬁnancial plan in place.</span>
                          {' '}
                          That&apos;s 2.5x more money.
                        </p>
                        <p className={ classes.boxPara }>
                          But, a
                          {' '}
                          <a href="https://www.gobankingrates.com/retirement/planning/1-3-americans-0-saved-retirement/" rel="noopener noreferrer" target="_blank" className={ classes.textBlue }>2019 GOBankingRates survey</a>
                          {' '}
                          has illuminated some alarming statistics on Americans and their retirement savings. 46% of Americans have $0 saved for retirement.
                        </p>
                        <div className={ classes.graphBlock }>
                          <Grid container spacing={ 1 } className={ classes.GridPad }>
                            <Grid item xs={ 12 }>
                              <div className={ classes.graphBox }>
                                <HighchartsReact highcharts={ Highcharts } options={ highchartsData[4].options } />
                              </div>
                            </Grid>
                          </Grid>
                          <p className={ classes.boxPara }>
                            Only 16% have $100k or more saved for retirement. Fidelity estimates that a 65-year old couple, retiring in 2019, will need
                            {' '}
                            <span className={ classes.spanColor }>$285,000 to cover health care and medical expenses</span>
                            {' '}
                            throughout retirement.
                          </p>
                          <p className={ classes.boxPara }>Few Americans have saved enough for basic healthcare needs in retirement - what about fun and relaxation?</p>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[1].rating } questionIndex={ 1 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[2].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>3</span>
                  <p className={ classes.questionTitle }>Molly starts saving and investing at the age of 21, earns a 6% annual return, and stops saving when she turns 60. How much money does Molly need to save and invest each month to become a millionaire?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[2].answer } onChange={ ( e ) => handleAnswer( e, 2 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === '250' && classes.ansWrong ) } value="250" control={ <Radio /> } label="$250" disabled={ questions[2].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer !== '' && classes.ansRight ) } value="500" control={ <Radio checked={ questions[2].answer !== '' } /> } label="$500" disabled={ questions[2].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === '750' && classes.ansWrong ) } value="750" control={ <Radio /> } label="$750" disabled={ questions[2].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === '1000' && classes.ansWrong ) } value="1000" control={ <Radio /> } label="$1,000" disabled={ questions[2].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[2].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[2].answer === questions[2].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[2].answer === questions[2].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">The power of compounding interest and time</Typography>
                        <div className={ classes.graphBlock }>
                          <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
                              <div className={ classes.graphBox }>
                                <HighchartsReact highcharts={ Highcharts } options={ highchartsData[0].options } />
                              </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                              <div className={ classes.graphBoxRight }>
                                <div className={ classes.BoxBlue }>
                                  <p>Molly saves $500 per month from age 21–60 and earns a 6% return.</p>
                                  <p><span>Molly contributes a total of $240,000 and grows her savings to $1,000,724!</span></p>
                                </div>
                                <div className={ classes.BoxGreen }>
                                  <p>Mike saves $450 per month from age 21–32 and then stops. He earns a 6% return.</p>
                                  <p>Mike contributes a total of $64,800 and grows his savings to $507,814!</p>
                                  <p><span>Mary never catches up to Mike.</span></p>
                                </div>
                                <div className={ classes.BoxRed }>
                                  <p>Mary saves $450 per month from age 32–60 and earns a 6% return. Mary contributes a total of $156,600 and grows her savings to $422,645!</p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <p className={ classes.boxPara }>There&apos;s two financial life lessons to learn by analyzing this graph:</p>
                          <p className={ classes.boxPara }><span className={ classes.spanColor }>1) Consistent investing + compounding interest + time = powerful wealth building machine</span></p>
                          <p className={ classes.boxPara }><span className={ classes.spanColor }>2) The earlier you start saving, the better</span></p>
                          <p className={ classes.boxPara }>Mike contributes $90,000 less than Mary but has almost $100,000 more savings over the same period. Why? Because he starts saving earlier, which gives his investments more time to grow! That’s the power of compounding interest and time.</p>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[2].rating } questionIndex={ 2 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[3].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>4</span>
                  <p className={ classes.questionTitle }>What % of students do you think graduate college on time (4 years)?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[3].answer } onChange={ ( e ) => handleAnswer( e, 3 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === '75' && classes.ansWrong ) } value="75" control={ <Radio /> } label="75%" disabled={ questions[3].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer !== '' && classes.ansRight ) } value="41" control={ <Radio checked={ questions[3].answer !== '' } /> } label="41%" disabled={ questions[3].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === '59' && classes.ansWrong ) } value="59" control={ <Radio /> } label="59%" disabled={ questions[3].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === '22' && classes.ansWrong ) } value="22" control={ <Radio /> } label="22%" disabled={ questions[3].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[3].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[3].answer === questions[3].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[3].answer === questions[3].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">You can&apos;t afford to miss your targets</Typography>
                        <p className={ classes.boxPara }>
                          Missing or delaying your targets can cost you a lot of
                          {' '}
                          <span className={ classes.spanColor }>time and money</span>
                          .
                        </p>
                        <div className={ classes.graphBlock }>
                          <Grid container spacing={ 2 } className={ classes.GridPad }>
                            <Grid item xs={ 6 }>
                              <div className={ `${classes.graphBox} ${classes.borderLine}` }>
                                <HighchartsReact highcharts={ Highcharts } options={ highchartsData[1].options } />
                              </div>
                            </Grid>
                            <Grid item xs={ 6 }>
                              <div className={ classes.graphBox }>
                                <HighchartsReact highcharts={ Highcharts } options={ highchartsData[2].options } />
                              </div>
                            </Grid>
                          </Grid>
                          <p className={ classes.boxPara }>When you fail to graduate on time, not only do you add an extra year of college costs – tuition, room and board, fees and more – you also delay starting your career! Adding an extra year of college costs plus missing out on a year of income lowers your net worth by $100,000 over the next 10 years.</p>
                          <p className={ classes.boxPara }>
                            Having a
                            {' '}
                            <span className={ classes.spanColor }>strategy and a plan</span>
                            {' '}
                            to navigate higher education can save you time and money.
                            {' '}
                            <span className={ classes.spanColor }>Graduate on time and add $100k or more to your net worth</span>
                            .
                          </p>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[3].rating } questionIndex={ 3 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[4].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>5</span>
                  <p className={ classes.questionTitle }>How much did the average 2019 college graduate have in student loans?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[4].answer } onChange={ ( e ) => handleAnswer( e, 4 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === '<10k' && classes.ansWrong ) } value="<10k" control={ <Radio /> } label="Less than $10k" disabled={ questions[4].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === '10k-20k' && classes.ansWrong ) } value="10k-20k" control={ <Radio /> } label="$10k to $20k" disabled={ questions[4].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer !== '' && classes.ansRight ) } value="20k-30k" control={ <Radio checked={ questions[4].answer !== '' } /> } label="$20k to $30k" disabled={ questions[4].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === '30k-40k' && classes.ansWrong ) } value="30k-40k" control={ <Radio /> } label="$30k to 40k" disabled={ questions[4].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[4].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[4].answer === questions[4].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[4].answer === questions[4].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Cost of higher education continues to rise</Typography>
                        <p className={ classes.boxPara }>
                          14% of their parents took out an
                          {' '}
                          <span className={ classes.spanColor }>average of $37,200</span>
                          {' '}
                          in federal parent PLUS loans.
                        </p>
                        <p className={ classes.boxPara }>
                          Americans owe over
                          {' '}
                          <span className={ classes.spanColor }>$1.64 trillion</span>
                          {' '}
                          in student loan debt spread out among 45 million borrowers. Student loans are not bad. Taking student loans without a plan can lead to statistics like the below.
                        </p>
                        <div className={ classes.graphBlock }>
                          <Grid container spacing={ 1 } className={ classes.GridPad }>
                            <Grid item xs={ 12 }>
                              <div className={ classes.graphBox }>
                                <HighchartsReact highcharts={ Highcharts } options={ highchartsData[3].options } />
                              </div>
                            </Grid>
                          </Grid>
                          <p className={ classes.boxPara }>
                            Delaying life events due to debt has become the new norm. But, with a strategy and a plan, you can break the norm.
                          </p>

                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[4].rating } questionIndex={ 4 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[5].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>6</span>
                  <p className={ classes.questionTitle }>What % of college graduates do you think pursue a job related to their major?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[5].answer } onChange={ ( e ) => handleAnswer( e, 5 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer !== '' && classes.ansRight ) } value="50" control={ <Radio checked={ questions[5].answer !== '' } /> } label="50%" disabled={ questions[5].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer === '60' && classes.ansWrong ) } value="60" control={ <Radio /> } label="60%" disabled={ questions[5].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer === '65' && classes.ansWrong ) } value="65" control={ <Radio /> } label="65%" disabled={ questions[5].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer === '80' && classes.ansWrong ) } value="80" control={ <Radio /> } label="80%" disabled={ questions[5].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[5].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[5].answer === questions[5].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[5].answer === questions[5].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Pursue higher education with a purpose</Typography>
                        <p className={ classes.boxPara }>
                          A large portion of your learning at college revolves around your major choice.
                          {' '}
                          {' '}
                          <b>Invest</b>
                          {' '}
                          {' '}
                          in a major that will be a launching pad for your career.
                        </p>
                        <p className={ classes.boxPara }>
                          <span>
                            <small className={ classes.halfScreen }>
                              <span>50%</span>
                              {' '}
                              of college graduatess
                              <br />
                              {' '}
                              pursue a job unrelated to
                              <br />
                              {' '}
                              their major
                            </small>
                          </span>
                        </p>
                        <p className={ classes.boxPara }>
                          selecting a major is crucial to lifetime earnings potential. Check out the research by
                          {' '}
                          <i>The Center on Education and the Workforce</i>
                          {' '}
                          at Georgetown University on the
                          {' '}
                          <span><u><a href="https://cew.georgetown.edu/cew-reports/whats-it-worth-the-economic-value-of-college-majors/" rel="noopener noreferrer" target="_blank" className={ classes.linkText }>value of college majors.</a></u></span>
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[5].rating } questionIndex={ 5 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[6].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>7</span>
                  <p className={ classes.questionTitle }>Since the year 2000, what was the highest unemployment rate for people with a bachelor&apos;s degree or higher?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[6].answer } onChange={ ( e ) => handleAnswer( e, 6 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[6].answer === '8' && classes.ansWrong ) } value="8" control={ <Radio /> } label="8.0%" disabled={ questions[6].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[6].answer === '6.5' && classes.ansWrong ) } value="6.5" control={ <Radio /> } label="6.5%" disabled={ questions[6].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[6].answer !== '' && classes.ansRight ) } value="5" control={ <Radio checked={ questions[6].answer !== '' } /> } label="5.0%" disabled={ questions[6].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[6].answer === '3' && classes.ansWrong ) } value="3" control={ <Radio /> } label="3.5%" disabled={ questions[6].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[6].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[6].answer === questions[6].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[6].answer === questions[6].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Education can provide job security</Typography>

                        <div className={ classes.graphBox }>
                          <HighchartsReact
                            highcharts={ Highcharts }
                            options={ {
                              chart: {
                                type: 'spline',
                                scrollablePlotArea: {
                                  scrollPositionX: 0,
                                },
                              },
                              exporting: {
                                enabled: false,
                              },
                              credits: {
                                enabled: false,
                              },
                              title: {
                                text: 'Unemployment Rate by Education level',
                              },
                              xAxis: [{
                                tickInterval: 4,
                                categories: unemploymentData.dates,
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    return chart.value;
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '13px',
                                  },
                                },
                              }],
                              yAxis: [{ // Primary yAxis
                                labels: {
                                  format: '{value:.1f}%',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              }],
                              tooltip: {
                                headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                                crosshairs: true,
                                shared: true,
                                valueDecimals: 1,
                                valueSuffix: '%',
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                              legend: {
                                lign: 'right',
                                verticalAlign: 'middle',
                                layout: 'vertical',
                                x: 300,
                                y: -100,
                                itemStyle: {
                                  fontSize: '14px',
                                },
                              },
                              plotOptions: {
                                spline: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  marker: {
                                    enabled: false,
                                  },

                                },
                              },
                              series: unemploymentData.details,
                            } }
                          />
                        </div>

                        <p className={ classes.boxPara }>The highest unemployment rate for people with a bachelor&apos;s degree or higher was only 5.0% in September 2009. For reference, that means out of 100 people with a bachelor&apos;s degree or higher, only 5 of them would be out of work.</p>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanColor }> Skills and knowledge are always in high demand.</span>
                          {' '}
                          In good times and in bad, skills and knowledge can provide job security.
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[6].rating } questionIndex={ 6 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[7].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>8</span>
                  <p className={ classes.questionTitle }>By obtaining a bachelor&apos;s degree, how much more money will a person earn in their lifetime compared to just having a high school diploma?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[7].answer } onChange={ ( e ) => handleAnswer( e, 7 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer === '300000' && classes.ansWrong ) } value="300000" control={ <Radio /> } label="$300,000" disabled={ questions[7].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer === '550000' && classes.ansWrong ) } value="550000" control={ <Radio /> } label="$550,000" disabled={ questions[7].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer === '800000' && classes.ansWrong ) } value="800000" control={ <Radio /> } label="$800,000" disabled={ questions[7].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer !== '' && classes.ansRight ) } value="1000000" control={ <Radio checked={ questions[7].answer !== '' } /> } label="$1,000,000" disabled={ questions[7].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[7].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[7].answer === questions[7].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[7].answer === questions[7].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Industry pays for skills and knowledge</Typography>
                        <div className={ classes.graphBox }>
                          <HighchartsReact highcharts={ Highcharts } options={ highchartsData[6].options } />
                        </div>
                        <p className={ classes.boxPara }>
                          On average, a person with a
                          {' '}
                          <span className={ classes.spanColor }>bachelor&apos;s degree will earn $1,000,000 more in their lifetime</span>
                          {' '}
                          than a person with a high school diploma.
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[7].rating } questionIndex={ 7 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[8].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>9</span>
                  <p className={ classes.questionTitle }>What do you think the average career school - typically a 2-year program that results in an associate&apos;s degree - costs in total tuition?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[8].answer } onChange={ ( e ) => handleAnswer( e, 8 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === '30k' && classes.ansWrong ) } value="30k" control={ <Radio /> } label="< $30k" disabled={ questions[8].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer !== '' && classes.ansRight ) } value="30k-40k" control={ <Radio checked={ questions[8].answer !== '' } /> } label="$30k to $40k" disabled={ questions[8].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === '40k-50k' && classes.ansWrong ) } value="40k-50k" control={ <Radio /> } label="$40k to $50k" disabled={ questions[8].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === '50k' && classes.ansWrong ) } value="50k" control={ <Radio /> } label="> $50k" disabled={ questions[8].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[8].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[8].answer === questions[8].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[8].answer === questions[8].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Career school offers another path to gaining skills and knowledge</Typography>
                        <p className={ classes.boxPara }>
                          <img className={ classes.checkMarkImg } src={ q16 } alt="Checkmark" />
                          The total tuition for a 2-year career school
                          {' '}
                          <span className={ classes.spanColor }>costs on average $33,000.</span>
                        </p>
                        <p className={ classes.boxPara }>
                          <img className={ classes.checkMarkImg } src={ q16 } alt="Checkmark" />
                          In the U.S.,
                          {' '}
                          <span className={ classes.spanColor }>62% of firms</span>
                          {' '}
                          are struggling to fill important skilled trade positions.
                        </p>
                        <p className={ classes.boxPara }>
                          <img className={ classes.checkMarkImg } src={ q16 } alt="Checkmark" />
                          On average, a person with an
                          {' '}
                          <span className={ classes.spanColor }>associate&apos;s degree will earn $400,000 more in their lifetime</span>
                          {' '}
                          than a person with a high school diploma.
                        </p>
                        <br />
                        <p className={ classes.boxPara }>
                          Weigh the
                          {' '}
                          <b>Cost of entry</b>
                          {' '}
                          and
                          {' '}
                          <b>potential earnings</b>
                          {' '}
                          when exploring higher education or training for your career.
                          {' '}
                          <b>Cost of entry</b>
                          {' '}
                          is the total cost of gaining the necessary education or training to enter a career path.
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[8].rating } questionIndex={ 8 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[9].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>10</span>
                  <p className={ classes.questionTitle }>What is the average starting salary after completing an apprenticeship? Apprenticeships are typically three to five-year programs.</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[9].answer } onChange={ ( e ) => handleAnswer( e, 9 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === '60' && classes.ansWrong ) } value="60" control={ <Radio /> } label="$60k" disabled={ questions[9].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer !== '' && classes.ansRight ) } value="50" control={ <Radio checked={ questions[9].answer !== '' } /> } label="$50k" disabled={ questions[9].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === '40' && classes.ansWrong ) } value="40" control={ <Radio /> } label="$40k" disabled={ questions[9].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === '30' && classes.ansWrong ) } value="30" control={ <Radio /> } label="$30k" disabled={ questions[9].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[9].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[9].answer === questions[9].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[9].answer === questions[9].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Apprenticeships are in high demand and offer competitive salaries</Typography>
                        <p className={ classes.boxPara }>
                          More than
                          {' '}
                          <span className={ classes.spanColor }>533,000 apprenticeships</span>
                          {' '}
                          are available nationwide in
                          {' '}
                          <span className={ classes.spanColor }>more than 1,000 occupations.</span>
                          {' '}
                          An apprenticeship usually lasts between 3-5 years.
                          {' '}
                          <span className={ classes.spanColor }>Over 150,000 companies</span>
                          {' '}
                          participate in apprenticeships.
                        </p>
                        <div className={ classNames( classes.graphBox, classes.avgSalaryGraph ) }>
                          <HighchartsReact highcharts={ Highcharts } options={ highchartsData[7].options } />
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[9].rating } questionIndex={ 9 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[10].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[10].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>11</span>
                  <p className={ classes.questionTitle }>Do you think that the military offers competitive pay when compared to civilian jobs?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup, classes.radioGroupTwo ) } name="questionOne" value={ questions[10].answer } onChange={ ( e ) => handleAnswer( e, 10 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer !== '' && classes.ansRight ) } value="yes" control={ <Radio checked={ questions[10].answer !== '' } /> } label="Yes" disabled={ questions[10].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer === 'no' && classes.ansWrong ) } value="no" control={ <Radio /> } label="No" disabled={ questions[10].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[10].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[10].answer === questions[10].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[10].answer === questions[10].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Military offers another path to gaining skills and knowledge</Typography>
                       <div className={ classes.inflationBlock }>
                          <table className={ classes.inflationTable } cellSpacing="0" cellPadding="0">
                            {' '}
                            <thead>
                              {' '}
                              <tr className={ classes.headerRow }>
                                {' '}
                                <th>&nbsp;</th>
                                {' '}
                                <th colSpan="2" className={ classes.textCenter }>Enlisted Solider</th>
                                {' '}
                                <th colSpan="2" className={ classes.textCenter }>Officer</th>
                                {' '}
                              </tr>
                              {' '}
                            </thead>
                            {' '}
                            <tbody>
                              {' '}
                              <tr>
                                {' '}
                                <td>Category</td>
                                {' '}
                                <td>Civilian</td>
                                {' '}
                                <td>Army</td>
                                {' '}
                                <td>Civilian</td>
                                {' '}
                                <td>Army</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Occupation</td>
                                {' '}
                                <td>Police Patrol Officer</td>
                                {' '}
                                <td>Military Police Sergeant</td>
                                {' '}
                                <td>Telecom. Engineer II</td>
                                {' '}
                                <td>Signal Corps Officer</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Family</td>
                                {' '}
                                <td>Single</td>
                                {' '}
                                <td>Single</td>
                                {' '}
                                <td>Single</td>
                                {' '}
                                <td>Single</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Rank / Years of Service</td>
                                {' '}
                                <td>N/A</td>
                                {' '}
                                <td>E5 / 4 yrs</td>
                                {' '}
                                <td>N/A</td>
                                {' '}
                                <td>O2 / 4 yrs</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Salary</td>
                                {' '}
                                <td>$52,259</td>
                                {' '}
                                <td>$32,800</td>
                                {' '}
                                <td>$74,807</td>
                                {' '}
                                <td>$58,259</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Housing Allowance</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$14,292</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$17,280</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Food Allowance</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$4,433</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$3,053</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Tax Advantages</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$2,553</td>
                                {' '}
                                <td>$0</td>
                                {' '}
                                <td>$5,735</td>
                                {' '}
                              </tr>
                              {' '}
                              <tr>
                                {' '}
                                <td>Health Care Costs</td>
                                {' '}
                                <td className={ classes.redText }>($9,044)</td>
                                {' '}
                                <td>Included</td>
                                {' '}
                                <td className={ classes.redText }>($9,044)</td>
                                {' '}
                                <td>Included</td>
                                {' '}
                              </tr>
                              {' '}
                            </tbody>
                            {' '}
                            <tfoot className="tfooter">
                              {' '}
                              <tr>
                                {' '}
                                <td>Total Compensation</td>
                                {' '}
                                <td>$43,215</td>
                                {' '}
                                <td>$54,078</td>
                                {' '}
                                <td>$65,763</td>
                                {' '}
                                <td>$84,327 </td>
                                {' '}
                              </tr>
                              {' '}
                            </tfoot>
                            {' '}
                          </table>
                        </div>
                        <p className={ classes.boxPara }>
                          {' '}
                          The military offers competitive pay and great benefits such as providing funding for higher education. For example, check out the Army&apos;s benefits
                          {' '}
                          <span><a href="https://www.goarmy.com/benefits/total-compensation.html" rel="noopener noreferrer" target="_blank" className={ classes.linkText }><u>here.</u></a></span>
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[10].rating } questionIndex={ 10 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[11].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[11].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>12</span>
                  <p className={ classes.questionTitle }>What is the median net worth of 35 to 44-year-old Americans? Net worth equals what you own (assets) minus what you owe (liabilities).</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[11].answer } onChange={ ( e ) => handleAnswer( e, 11 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === '10000' && classes.ansWrong ) } value="10000" control={ <Radio /> } label="$10,000" disabled={ questions[11].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer !== '' && classes.ansRight ) } value="60000" control={ <Radio checked={ questions[11].answer !== '' } /> } label="$60,000" disabled={ questions[11].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === '110000' && classes.ansWrong ) } value="110000" control={ <Radio /> } label="$110,000" disabled={ questions[11].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === '160000' && classes.ansWrong ) } value="160000" control={ <Radio /> } label="$160,000" disabled={ questions[11].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[11].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[11].answer === questions[11].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[11].answer === questions[11].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Median net worth by age group</Typography>
                        <p className={ classes.boxPara }>
                          After working, saving, and investing for 15 to 25 years, the
                          <span className={ classes.spanColor }>&nbsp;median net worth of 35 to 44-year-old Americans is $59,800. Does that surprise you?</span>
                        </p>
                        <div className={ classes.graphBox }>
                          <HighchartsReact highcharts={ Highcharts } options={ highchartsData[5].options } />
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[11].rating } questionIndex={ 11 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[12].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[12].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>13</span>
                  <p className={ classes.questionTitle }>The digital landscape has changed the world forever. How much more do workers earn in high digital occupations versus low digital occupations?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[12].answer } onChange={ ( e ) => handleAnswer( e, 12 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer !== '' && classes.ansRight ) } value="40000" control={ <Radio checked={ questions[12].answer !== '' } /> } label="$40,000" disabled={ questions[12].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === '30000' && classes.ansWrong ) } value="30000" control={ <Radio /> } label="$30,000" disabled={ questions[12].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === '20000' && classes.ansWrong ) } value="20000" control={ <Radio /> } label="$20,000" disabled={ questions[12].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === '10000' && classes.ansWrong ) } value="10000" control={ <Radio /> } label="$10,000" disabled={ questions[12].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[12].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[12].answer === questions[12].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[12].answer === questions[12].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <div className={ classes.graphBox }>
                          <div className={ classes.halfWidth }>
                            <img src={ q22 } alt="Graph" />
                          </div>
                        </div>
                        <p className={ classes.boxPara }>
                          <em>
                            Source:
                            {' '}
                            <a href="https://www.customhomeonline.com/builders/digital-talent-and-housings-labor-shortages_s?utm_source=newsletter&utm_content=Article&utm_medium=email&utm_campaign=CH_112317A%20(1)%20A&he=a45af5d29ac52c87254fa74521ec8e507a37f759" rel="noopener noreferrer" target="_blank" className={ classes.linkText }>Brookings Institution</a>
                            {' '}
                            &quot;Digitalization and the American workforce&quot;
                          </em>
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[12].rating } questionIndex={ 12 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[13].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
              </div>

              <div ref={ ( section ) => { questionMainAns[13].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>14</span>
                  <p className={ classes.questionTitle }>Which of the following cities is rated #1 for job-market strength?</p>
                  <ul className={ classes.optionList }>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[13].answer } onChange={ ( e ) => handleAnswer( e, 13 ) }>
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'ny' && classes.ansWrong ) } value="ny" control={ <Radio /> } label="New York, NY" disabled={ questions[13].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'PA' && classes.ansWrong ) } value="PA" control={ <Radio /> } label="Philadelphia, PA" disabled={ questions[13].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'FL' && classes.ansWrong ) } value="FL" control={ <Radio /> } label="Orlando, FL" disabled={ questions[13].answer !== '' } />
                        <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer !== '' && classes.ansRight ) } value="Chandler" control={ <Radio checked={ questions[13].answer !== '' } /> } label="Scottsdale, AZ" disabled={ questions[13].answer !== '' } />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[13].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[13].answer === questions[13].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[13].answer === questions[13].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">It&apos;s not just what you do, but where you do it</Typography>
                        <p className={ classes.boxPara }>
                          <a href="https://wallethub.com/edu/best-cities-for-jobs/2173/#main-findings" rel="noopener noreferrer" target="_blank" className={ classes.linkText }>WalletHub</a>
                          {' '}
                          compared 182 of the most populated U.S. cities across 26 key indicators of job-market strength. Surprised by the results?
                        </p>
                        <div className={ classes.graphBox }>
                          <img src={ marketStrength } alt="Graph" />
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[13].rating } questionIndex={ 13 } handleRating={ handleRating } />
                        </div>
                      </div>
                    </div>
                    )}
              </div>

              <div className={ classes.progressBar } id="progress-bar">
                <p>
                  {progress}
                  % complete
                </p>
                <div>
                  <span style={ { width: `${progress}%` } } />
                </div>
              </div>

              <div className={ classes.whatsMyScore } id="my-score-button">
                <Button onClick={ handleMyScore }>What&apos;s My Score?</Button>
              </div>
            </div>
          </div>
        </div>
        )}
        {myScoreContent === true && (
          <div ref={ ( section ) => { feedBackSection = section; } }>
            <div className={ classes.whatmyScoreRow }>
              <div className={ classes.myScoreTitle }>
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">INKWIRY AWARENESS FEEDBACK</Typography>
                </div>
              </div>
              <div className={ classes.graphScoreRow }>
                <div className={ classes.container }>
                  <div className={ classes.graphhRow }>
                    <div className={ classes.inkImportRank }>
                      <span>MY RANK OF IMPORTANCE</span>
                      <p>5</p>
                      <p>4</p>
                      <p>3</p>
                      <p>2</p>
                      <p>1</p>
                    </div>
                    <div className={ classes.inkAwaerenessScoreCntIn }>
                      <div className={ classes.inkAwaerenessCateCnt }>
                        {
                          questions.map( ( questionValue, index ) => (
                            <div key={ questionValue.qId } className={ classes.inkAwaerenessCateIn }>
                              <div role="button" tabIndex={ index } aria-hidden="true" onClick={ () => handleQuestionScroll( questionMainAns[index].scroll ) } className={ questionValue.status === 'C' ? classes.inkAwaerenessCateScoreBlue : classes.inkAwaerenessCateScoreRed } style={ { height: `${questionValue.rating * 20}%` } } />
                              <p>{questionValue.qId}</p>
                            </div>
                          ) )
                        }
                      </div>
                    </div>
                    <div className={ classes.inkScoreBottomSec }>
                      <p className={ classes.quetionsPara }>QUESTIONS</p>
                      <ul>
                        <li>
                          <p>
                            <span className={ classes.correctBox } />
                            { ' ' }
                            Correct
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className={ classes.wrongBox } />
                            { ' ' }
                            Incorrect
                          </p>
                        </li>
                      </ul>
                      <p className={ classes.quetionsParaTwo }>NOTE: Click on a number to toggle back to that question - then hit Feedback to return to this page.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.whatmyScoreRow }>
              <div className={ classes.myScoreTitle }>
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">
                    <span>INKWIRY AWARENESS SCORE</span>
                    <div className={ classes.infoIcon }>
                      <img
                        src={ infoIconContrast }
                        alt="Info"
                        data-for="infoIcon"
                        data-tip="The meter below shows you two scores: Awareness and Importance. Awareness is the % of questions you answered correctly for the challenge. Importance is the average importance rating you gave each question for the challenge."
                      />
                      <ReactTooltip id="infoIcon" place="bottom" type="info" effect="solid" className={ `${classes.tooltipInfo} ${classes.tooltipScoreInfo}` } />
                    </div>
                  </Typography>
                </div>
              </div>
              <div className={ classes.container }>
                <div className={ classes.myScoreCnt }>
                  <p>Revisit each question and make sure you learn the valuable insights that are crucial to developing a quality financial life plan. Inkwiry will continuously update this challenge with the latest data and new information to keep you informed on conditions and trends that might affect your financial life plan.</p>
                </div>
                <div className={ classes.awarenessGraph }>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={ {
                      chart: {
                        type: 'solidgauge',
                        height: 500,
                        events: {
                          render: renderIcons,
                        },
                      },
                      title: {
                        text: '',
                      },
                      tooltip: {
                        enabled: false,
                      },
                      pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                          outerRadius: '112%',
                          innerRadius: '88%',
                          backgroundColor: Highcharts.Color( '#84aa47' )
                            .setOpacity( 0.3 )
                            .get(),
                          borderWidth: 0,
                        }, { // Track for Exercise
                          outerRadius: '87%',
                          innerRadius: '63%',
                          backgroundColor: Highcharts.Color( '#f79b22' )
                            .setOpacity( 0.3 )
                            .get(),
                          borderWidth: 0,
                        }],
                      },
                      credits: {
                        enabled: false,
                      },

                      yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                      },
                      plotOptions: {
                        series: {
                          cursor: 'pointer',
                        },
                        solidgauge: {
                          dataLabels: {
                            enabled: false,
                          },
                          linecap: 'round',
                          stickyTracking: false,
                          rounded: true,
                        },
                      },
                      series: [{
                        name: 'DISCOVERY',
                        id: 'discovery',
                        data: [{
                          color: '#84aa47',
                          radius: '112%',
                          innerRadius: '88%',
                          y: avgScore,
                        }],
                        events: {
                          mouseOver() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                          mouseOut() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                        },
                      }, {
                        name: 'IMPORTANCE',
                        id: 'importance',
                        data: [{
                          color: '#f79b22',
                          radius: '87%',
                          innerRadius: '63%',
                          y: avgRating * 20,
                        }],
                        events: {
                          mouseOver() {
                            scoreValSection.style.setProperty( 'display', 'none' );
                            ratingValSection.style.setProperty( 'display', 'flex' );
                          },
                          mouseOut() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                        },
                      }],
                    } }
                  />
                  <div className={ classNames( classes.scoreNumbers, classes.scoreDetails ) } ref={ ( section ) => { scoreValSection = section; } }>
                    <p>
                      AWARENESS
                      {' '}
                      <font>
                        { avgScore.toFixed( 1 ) }
                        %
                      </font>
                    </p>
                  </div>
                  <div className={ classNames( classes.scoreNumbers, classes.ratingDetails ) } ref={ ( section ) => { ratingValSection = section; } }>
                    <p>
                      AVERAGE IMPORTANCE
                      {' '}
                      <font>{ avgRating.toFixed( 1 ) }</font>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumListNew }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Awareness.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Awareness );
