import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const HEALSActHighlights = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>HEALS Act Highlights</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/heals-act.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      HEALS Act Highlights
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/heals-act-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p className={classes.noBottomMargin}>
                          Covid-19 continues to disrupt the global economy and
                          the US economy. Businesses and individuals are
                          struggling. Senate Majority Leader Mitch McConnell
                          (R-Ky.) announced the HEALS Act or the Health,
                          Economic Assistance, Liability Protection and Schools
                          Act. The highlights of the bill are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            A second round of economic impact payments, which
                            are direct $1,200 payments for single tax filers and
                            $2,400 for married couples filing jointly,
                          </li>
                          <li>
                            $200 per week of additional unemployment insurance,
                            which is $400 less than the previous stimulus
                            package, and
                          </li>
                          <li>
                            $105 billion to help students return to school next
                            semester.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          It&apos;s important to note that this bill has only
                          been passed in the Senate. To become law and go into
                          effect, after both the House and Senate have approved
                          a bill, the bill is sent to the President. If the
                          President signs the bill, the bill becomes law. To
                          learn more about how a bill becomes law, check out
                          this{' '}
                          <a
                            href="https://www.usa.gov/how-laws-are-made#item-213608"
                            target="_blank"
                            rel="noopener noreferrer">
                            infographic here
                          </a>
                          .
                        </p>
                        <p>
                          Next up, this bill will go to the House of
                          Representatives where the HEALS Act will be reviewed
                          and negotiated. If the Senate and House of
                          Representatives can agree on a stimulus package, the
                          bill will be sent to the President for his approval.
                          This negotiation process could take days or weeks,
                          depending on the bill.
                        </p>
                        <p>
                          If the HEALS Act is passed into law, the second round
                          of economic impact payments will follow the same
                          guidelines as the first payment. If you file your
                          taxes as single and earned less than $75,000 in{' '}
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            adjusted gross income
                          </Link>{' '}
                          on your latest tax return, you will receive $1,200. If
                          you earned between $75,000 and $99,000 you will
                          receive a portion of the $1,200. For every $1,000
                          earned over $75,000, the IRS will deduct $50 from the
                          credit. For example, if your adjusted gross income on
                          your latest tax return was $90,000, your tax credit
                          would be calculated as follows:
                        </p>
                        <p>
                          $90,000 - $75,000 = $15,000 / $1,000 = 15 x $50 = $750
                          deduction
                          <br />
                          $1,200 - $750 deduction = $450 tax credit
                        </p>
                        <div className={classes.fillingtaxTable}>
                          <Table className={classes.taxTable}>
                            <thead>
                              <tr>
                                <th>Tax Filing Status</th>
                                <th>Tax Credit Max Amount</th>
                                <th colSpan="2">Income Phaseout Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Single</td>
                                <td>$1,200</td>
                                <td>$75,000</td>
                                <td>$99,000</td>
                              </tr>
                              <tr>
                                <td>Married, No Children</td>
                                <td>$2,400</td>
                                <td>$150,000</td>
                                <td>$198,000</td>
                              </tr>
                              <tr>
                                <td>Married, One Child</td>
                                <td>$2,900</td>
                                <td>$150,000</td>
                                <td>$208,000</td>
                              </tr>
                              <tr>
                                <td>Married, Two Children</td>
                                <td>$3,400</td>
                                <td>$150,000</td>
                                <td>$218,000</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <p>
                          <i>
                            For every $1,000 earned over phaseout range, deduct
                            $50 from credit
                          </i>
                        </p>
                        <p>
                          It&apos;s important to note that the government will
                          actually look at your 2020 tax return to determine if
                          you should have qualified for a cash payment. I know
                          this is confusing, but it&apos;s easiest to understand
                          in an example.
                        </p>
                        <p>
                          Let&apos;s assume 2019 is your latest tax return and
                          you earned $150,000. You do not qualify for the tax
                          credit based on that year. However, in 2020 you earn
                          $50,000. The IRS would then give you a $1,200 tax
                          credit on your 2020 income tax returns.
                        </p>
                        <p>
                          On the other hand, let&apos;s say you earned $50,000
                          on your latest tax return in 2019. You receive a
                          $1,200 cash payment. In 2020, you earn $150,000.
                          Technically, you would not qualify for the tax credit,
                          but the government will not claw back that payment.
                          You will get to keep your $1,200 in that scenario.
                        </p>
                        <p>
                          <i>
                            It&apos;s important to note that if you do not have
                            your bank account details filed with the IRS, it
                            could take much longer to receive your cash payment.
                            If the IRS doesn&apos;t have your bank account on
                            file, your payment could be delayed. Consult a tax
                            professional to make sure your direct deposit is set
                            up so you can receive your payment as soon as
                            possible.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
HEALSActHighlights.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HEALSActHighlights);
