import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-vacation/styles';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 500,
    scaledValue: 500,
    label: '$500',
  },
  {
    value: 1000,
    scaledValue: 1000,
    label: '$1k',
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: '$1.5k',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '$2k',
  },
  {
    value: 2500,
    scaledValue: 2500,
    label: '$2.5k',
  },
  {
    value: 3000,
    scaledValue: 3000,
    label: '$3.0k',
  },
  {
    value: 3500,
    scaledValue: 3500,
    label: '$3.5k',
  },
];

const SpecialPurchaseCostTwo = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [sliderValue, setSliderValue] = React.useState( (inputData.third_yearly_cost === undefined || inputData.third_yearly_cost === null ) ? 500 : inputData.third_yearly_cost );
    
  useEffect(()=>{
    if(inputData.third_yearly_cost === undefined || inputData.third_yearly_cost === null ) {
      updateData({...inputData,...{'third_yearly_cost':500}});
    }
  },[])

  const handleSliderChange = ( event, newValue ) => {
    setSliderValue( newValue );
    updateData( 'third_yearly_cost',newValue );
  };

  const handleInputChange = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setSliderValue( updatedValue );
    updateData( 'third_yearly_cost',updatedValue );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>What will that special purchase cost? Do some research and input an annual special purchase number below.</p>

      <div className={ classes.AnnualIncomeGrowth }>
        <div className={ classes.annuvalText }>Annual</div>
        <div>
          <p><span>Annual Special Purchase</span></p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              valueLabelFormat={ `${commonFunctions.thousandFormatter( sliderValue )}` }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ marks }
              min={ 0 }
              max={ 3500 }
              step={ 100 }
              value={ sliderValue }
              onChange={ handleSliderChange }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            $
            <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ sliderValue } onValueChange={ handleInputChange } onFocus={ ( e ) => { e.target.select(); } } />
          </div>
        </div>
      </div>
    </div>
  );
};

SpecialPurchaseCostTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( SpecialPurchaseCostTwo );
