import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StocksBonds = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Stocks and Bonds</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stocks-bonds.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Stocks and Bonds
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stocks-bonds-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Stocks and bonds are both securities, which is a
                          financial asset that has monetary value and can be
                          bought and sold by investors. Securities have the
                          potential to earn investors a return on their money,
                          but also have the risk of losing some of or all their
                          money. Stocks are equity, which is a financial term
                          for being an owner. And, bonds are debt. Let&apos;s
                          explore stocks and bonds.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <b>Stocks</b>
                          <br />A stock is a security that represents one share
                          of ownership in a specific company. For example,
                          let&apos;s say you bought 10 shares of Microsoft.
                          Given Microsoft has 7.635 billion shares of stock
                          outstanding, you would own 0.00000013% of the company.
                          Even though you own a small fraction of the company,
                          those 10 shares have value because Microsoft is a huge
                          company. How much value? On March 5, 2019, when you
                          hypothetically made the purchase, one share of
                          Microsoft stock was{' '}
                          <a
                            href="https://finance.yahoo.com/quote/MSFT/"
                            target="_blank"
                            rel="noopener noreferrer">
                            worth $112
                          </a>
                          .
                        </p>
                        <p>
                          10 shares of Microsoft x $112 share price = $1,120
                          value on March 5, 2019
                        </p>
                        <p>
                          There are two ways to profit from investing in stocks:
                          1) the share price increases, or 2) the company pays a{' '}
                          <a
                            href="https://www.thebalance.com/what-is-a-dividend-356103"
                            target="_blank"
                            rel="noopener noreferrer">
                            dividend
                          </a>
                          , which is when a company decides to pay a portion of
                          their after-tax income to stockholders. Continuing our
                          example, you sold your 10 shares of Microsoft on June
                          21, 2019.
                        </p>
                        <p>
                          10 shares of Microsoft x $137 share price = $1,370
                          value on June 21, 2019 $1,370 sale price - $1,120
                          purchase price = $250 return before taxes and fees
                        </p>
                        <p>
                          Stocks are riskier investments when compared to bonds.
                          Unlike bonds, stocks do not have periodic interest
                          payments. Dividend payments are not guaranteed; the
                          company chooses to pay a dividend when they want to.
                          Stock prices are hard to predict and can quickly move
                          up and down. Lastly, if a company goes bankrupt, the
                          stock of that company usually becomes worthless and
                          you will lose your investment.
                        </p>
                        <p>
                          <b>Bonds</b>
                          <br />A bond is a loan given to a company or
                          government by an investor. In other words, when you
                          buy a bond, you are lending money to a company in
                          exchange for periodic interest payments (usually
                          quarterly or twice a year) plus your money back at a
                          later date. Let&apos;s look at an example.
                        </p>
                        <p>
                          The city of Philadelphia wants to rebuild the I-95
                          highway, so it issues bonds to raise money for the
                          project. Each bond is a loan for $1,000, pays an
                          annual interest rate of 4% (also called a{' '}
                          <a
                            href="https://www.thebalance.com/what-is-a-bond-coupon-and-how-did-it-get-its-name-357374"
                            target="_blank"
                            rel="noopener noreferrer">
                            coupon rate
                          </a>
                          ), and has a maturity of 15 years. Maturity is a
                          financial term that means how long until a loan gets
                          repaid. Each year, a bond investor receives $40 of
                          interest from the city of Philadelphia. And, after 15
                          years, the bond investor receives their original
                          $1,000 back and the city has rebuilt the highway. A
                          win-win solution.
                        </p>
                        <p>
                          A good bond investing guideline is the higher the
                          coupon rate, the riskier the investment.
                        </p>
                        <p>
                          Bonds are less risky investments when compared to
                          stocks because they pay regularly scheduled interest
                          payments and return the original investment at a
                          specific date. With bonds, you run the risk of the
                          borrower not having the cash to make interest payments
                          to you. But, if a company does go bankrupt, bond
                          investors are repaid before stockholders.
                        </p>
                        <p>
                          <b>Return Comparison</b>
                          <br />
                          Remember, stocks are riskier so that should mean a
                          higher, but less likely reward. How much higher
                          reward? And, how much riskier?
                        </p>
                        <p>
                          The tables and graphs below compare the S&P 500, which
                          is an index that measures the performance of the 500
                          largest companies in the US stock market, and the
                          10-year US Treasury Bond.
                        </p>
                        <Table className={classes.sandpTable}>
                          <thead>
                            <tr>
                              <th />
                              <th>
                                S&P 500
                                <br />
                                (including dividends)
                              </th>
                              <th>
                                10-yr US
                                <br />
                                Treasury Bond
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Average Annual
                                <br />
                                Return from
                                <br />
                                1928-2018
                              </td>
                              <td>11.36%</td>
                              <td>5.10%</td>
                            </tr>
                            <tr>
                              <td>Worst Year&apos;s Return</td>
                              <td>-43.84%</td>
                              <td>-11.12%</td>
                            </tr>
                            <tr>
                              <td>Best Year&apos;s Return</td>
                              <td>52.56%</td>
                              <td>32.81%</td>
                            </tr>
                          </tbody>
                        </Table>
                        <p className={classes.noTopMargin}>
                          <i>
                            Source:{' '}
                            <a
                              href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html"
                              target="_blank"
                              rel="noopener noreferrer">
                              Aswath Damodaran, NYU Stern School of Business
                            </a>
                          </i>
                        </p>
                        <p>
                          <i>
                            Source:{' '}
                            <a
                              href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html"
                              target="_blank"
                              rel="noopener noreferrer">
                              Aswath Damodaran, NYU Stern School of Business
                            </a>
                          </i>
                        </p>
                        <p className={classes.noBottomMargin}>
                          Main takeaways:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            Stocks are riskier than bonds as their returns
                            fluctuate more in value
                          </li>
                          <li>
                            Bonds typically return less, which makes sense given
                            they are less risky
                          </li>
                          <li>
                            A healthy mix of both stocks and bonds over the long
                            run is usually a winning strategy
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StocksBonds.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StocksBonds);
