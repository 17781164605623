import senseChallengeBanner from '../../assets/img/sense/sense_challenge_banner.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  senseBannerSec: {
    backgroundImage: `url(${senseChallengeBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: '378px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9',
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: '300px',
    },
    '&:before': {
      content: '""',
      background: 'rgba(0, 0, 0, 0.3)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0px',
      top: '0px',
      zIndex: '-1',
    },
  },
  bannerContent: {
    '& h2': {
      fontSize: '54px',
      textAlign: 'center',
      fontWeight: '300',
      color: theme.palette.common.white,
      '@media (max-width: 767px)': {
        fontSize: '50px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '28px',
      },
      '@media (max-width: 360px)': {
        fontSize: '26px',
      },
      '@media (max-width: 320px)': {
        fontSize: '24px',
      },
    },
  },
  senseContentRow: {
    padding: '40px 0px 50px 0px',
  },
  senseContentTitle: {
    textAlign: 'center',
    '& h2': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      padding: '0 0 12px 0',     
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '28px',
      },
      '@media (max-width: 320px)': {
        fontSize: '26px',
      },
    },
    '& h3': {
      padding: '0 0 40px 0',
      fontFamily: '"MuseoSans-100"',
      fontSize: '36px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '28px',
        padding: '0 0 0px 0',
      },
      '@media (max-width: 767px)': {
        fontSize: '24px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
  },
  senseContent: {
    marginTop: '35px',
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '20px',
    },
  },
  sensecontentLeft: {
    textAlign: 'center',
    paddingRight: '0px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingRight: '0px',
    },
    '& ul': {
      padding: '0px',
      margin: '0px',
      '& li': {
        listStyle: 'none',
        '& a': {
          display: 'inline-block',
          fontSize: '24px',
          lineHeight: '34px',
          textTransform: 'uppercase',
          cursor: 'pointer',
          padding: '12px 22px',
          transition: 'all 0.5s ease 0s',
          MozTransition: 'all 0.5s ease 0s',
          WebkitTransition: 'all 0.5s ease 0s',
          borderRadius: '10px',
          [theme.breakpoints.down( 'md' )]: {
            fontSize: '20px',
            lineHeight: '30px',
          },
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '14px',
            lineHeight: '24px',
          },
          '@media (max-width: 767px)': {
            fontSize: '14px',
            padding: '12px 11px',
          },
          [theme.breakpoints.down( 'xs' )]: {
            maxWidth: '300px',
            fontSize: '15px',
            lineHeight: '24px',
          },
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  },
  discoverBtn: {
    marginTop: '45px',
    background: '#84a84d',
    border: '2px solid #84a84d',
    color: theme.palette.common.white,
    '& span': {
      display: 'block',
      width: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '0px',
    },
    '&:hover': {
      background: '#6a8e33',
      border: '2px solid #6a8e33',
    },
  },
  avernessBtn: {
    marginTop: '70px',
    '& span': {
      display: 'block',
      width: '100%',
    },
    background: theme.palette.common.bluelight,
    border: '2px solid #539cc8',
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '0px',
    },
    '&:hover': {
      background: theme.palette.common.blue,
      border: '2px solid #3b81ad',
    },
  },
  sensecontentRight: {
    paddingLeft: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      textAlign: 'center',
    },
    '& h3': {
      fontSize: '30px',
      lineHeight: '37px',
      fontFamily: '"MuseoSans-300"',
      padding: '0 0 26px 0',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      '@media (max-width: 767px)': {
        fontSize: '23px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
        lineHeight: '30px',
      },
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      padding: '0 0 0px 0',
      fontSize: '24px',
      lineHeight: '36px',
      margin: '0 auto',
      color: theme.palette.common.blue,
      maxWidth: '850px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
  },

} );

export default styles;
