import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Picture1 from '../../assets/img/pwi-existing/dollor.png';
// import Picture2 from '../../assets/img/pwi-existing/credit_cards.png';
// import Picture3 from '../../assets/img/pwi-existing/existing_car.png';
// import Picture4 from '../../assets/img/pwi-existing/existing_home.png';
import styles from '../../screens/pwi-existing/styles';
import { defaultValues } from '../../calculations/default-module-values';

const ExistingDashboard = (props) => {
  const {
    classes, handleAssestObject, displayContinue,
  } = props;

  // const [creditCheck,setCreditCheck] = useState(false);
  // const [loanCheck,setLoanCheck] = useState(false);
  // const [carCheck,setCarCheck] = useState(false);
  // const [homeCheck,setHomeCheck] = useState(false);

  // const handleClickType = ( type ) => {
  //   const inputValues = {...handleAssestObject};
  //   inputValues.existingDashType = type;
  //   handleUpdatedObject( inputValues );
  // };

  useEffect(() => {
    let loans;
    let credit;
    let car;
    let home;
    if (handleAssestObject !== undefined) {     
      loans = defaultValues.loans.some((e) => {
        return handleAssestObject[e] > 0;
      });
      // setLoanCheck(loans);
      credit = defaultValues.credit.some((e) => {
        return handleAssestObject[e] > 0;
      });
      // setCreditCheck(credit);
      car = defaultValues.car.some((e) => {
        return handleAssestObject[e] > 0;
      });
      // setCarCheck(car);

      home = defaultValues.home.some((e) => {
        return handleAssestObject[e] > 0;
      });
      // setHomeCheck(home);
    }
    // eslint-disable-next-line
  }, [displayContinue]);

  return (
    <div className={classes.contentBlock}>
      <p>
        The first step in building your financial life plan is building a snapshot
        of where you are today. That starts by accounting for your
        {' '}
        <span className={classes.blueText}>Existing Assets & Liabilities</span>
        {' '}
        such as cash and savings, and student loans and auto loans, and more.
        At the end of this module, you’ll discover your current net worth, benchmark your retirement savings
        to your peers, and take control of your future by owning today.
      </p>
      <p>Let's get started with cash and investments.</p>
      {/* <div className={ `${classes.fourIconsBlock} ${classes.fouIconsDashBoard}` }>
        <ul>
          <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'studentLoans' ) } onClick={ () => handleClickType( 'studentLoans' ) } className={ ( handleAssestObject.existingDashType === 'studentLoans' || completedSlides[12] || loanCheck ) ? classes.active : classes.unactiveBox }>
            <figure>
              <img src={ Picture1 } alt="" />
            </figure>
            <span>Existing Student Loans</span>
            {(completedSlides[12] === true || loanCheck) && ( <i className="fa fa-check" /> )}

          </li>
          <li className={ ( handleAssestObject.existingDashType === 'creditCards' ||  completedSlides[14] || creditCheck) ? classes.active : classes.unactiveBox } aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'creditCards' ) } onClick={ () => handleClickType( 'creditCards' ) }>
            <figure>
              <img src={ Picture2 } alt="" />
            </figure>
            <span>Existing Credit Cards</span>
            {(completedSlides[14] === true  || creditCheck) && ( <i className="fa fa-check" /> )}
          </li>
          <li className={ ( handleAssestObject.existingDashType === 'car' ||  completedSlides[17] || carCheck ) ? classes.active : classes.unactiveBox } aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'car' ) } onClick={ () => handleClickType( 'car' ) }>
            <figure>
              <img src={ Picture3 } alt="" />
            </figure>
            <span>Existing Car</span>
            {(completedSlides[17] === true  || carCheck) && ( <i className="fa fa-check" /> )}
          </li>
          <li className={ ( handleAssestObject.existingDashType === 'home'  || completedSlides[22] || homeCheck ) ? classes.active : classes.unactiveBox } aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'home' ) } onClick={ () => handleClickType( 'home' ) }>
            <figure>
              <img src={ Picture4 } alt="" />
            </figure>
            <span>Existing Home</span>
            {(completedSlides[22] === true  || homeCheck) && ( <i className="fa fa-check" /> )}
          </li>
        </ul>
      </div> */}
    </div>
  );
};

ExistingDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDoubleClickType: PropTypes.func.isRequired,
  // handleUpdatedObject: PropTypes.func.isRequired,
  displayContinue: PropTypes.bool.isRequired,
  // completedSlides:PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExistingDashboard);
