import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import $ from 'jquery';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import BuyingSecondCarTableBottom from './buying-second-car-table-bottom';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import * as modulesServices from '../../calculations/modules-services';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const rateArrayValues = [
  {
    value: 0.00,
    scaledValue: 0.00,
    label: '0% of Car Loan',
  },
  {
    value: 0.5,
    scaledValue: 0.5,
    label: '0.5%',
  },
  {
    value: 1,
    scaledValue: 1,
    label: '1%',
  },
  {
    value: 1.5,
    scaledValue: 1.5,
    label: '1.5%',
  },
  {
    value: 2,
    scaledValue: 2,
    label: '2%',
  },
  {
    value: 2.5,
    scaledValue: 2.5,
    label: '2.5%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },

];

const interestRateValues = [
  {
    value: 0.00,
    scaledValue: 0.00,
    label: '0%',
  },
  {
    value: 2.00,
    scaledValue: 2.00,
    label: '2%',
  },
  {
    value: 4.00,
    scaledValue: 4.00,
    label: '4%',
  },
  {
    value: 6.00,
    scaledValue: 6.00,
    label: '6%',
  },
  {
    value: 8.00,
    scaledValue: 8.00,
    label: '8%',
  },
  {
    value: 10.00,
    scaledValue: 10.00,
    label: '10%',
  },
  {
    value: 12.00,
    scaledValue: 12.00,
    label: '12%',
  },
];

const SecondCarLoan = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject, activeStep, percentageVal, percentageVal2, finalMnthlyAfterIncome, finalMnthlyExpenses,
  } = props;
  const [basePrice, setBasePrice] = React.useState( handleBuyingCarObject.second_origination_fees === undefined ? 0.5 : handleBuyingCarObject.second_origination_fees );
  const [destination, setDestination] = React.useState( handleBuyingCarObject.second_interest_rate === undefined ? 5 : handleBuyingCarObject.second_interest_rate );
  const [options, setOptions] = React.useState( handleBuyingCarObject.second_term_of_the_loan === undefined ? 48 : handleBuyingCarObject.second_term_of_the_loan );
  const pervalue = modulesServices.module12Data.moreAdjustedPurchasePrice * ( ( 100 - handleBuyingCarObject.second_down_payment_in_percentage ) / 100 );
  const [discountInput, setDiscountInputValue] = React.useState( pervalue * ( basePrice / 100 ) );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${percentageVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageVal2}%` );
  }, [percentageVal,percentageVal2] );

  useEffect( () => {
    const carLoanObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.second_origination_fees === undefined ) {
      carLoanObj.second_origination_fees = 0.5;
      setBasePrice( 0.5 );
    }else {
      setBasePrice( handleBuyingCarObject.second_origination_fees );
    }
    if ( handleBuyingCarObject.second_interest_rate === undefined ) {
      carLoanObj.second_interest_rate = 5;
      setDestination( 5 );
    }else {
      setDestination( handleBuyingCarObject.second_interest_rate );
    }
    if ( handleBuyingCarObject.second_term_of_the_loan === undefined ) {
      carLoanObj.second_term_of_the_loan = 48;
      setOptions( 48 );
    }else {
      if(handleBuyingCarObject.second_term_of_the_loan === '') {
        setOptions( 0 );
      }else {
      setOptions( handleBuyingCarObject.second_term_of_the_loan );
      }
    }
    handleUpdatedObject( carLoanObj );
    // eslint-disable-next-line
  }, []);

  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${percentageVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageVal2}%` );
  } );

  const handleBasePriceSlider = ( event, newValue ) => {
    setBasePrice( newValue );
    setDiscountInputValue( pervalue * ( newValue / 100 ) );
    handleBuyingCarObject.second_origination_fees = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleBasePriceInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setDiscountInputValue( updatedValue );
    setBasePrice( updatedValue > 0 ? ( updatedValue * 100 ) / pervalue : 0 );
    handleBuyingCarObject.second_origination_fees = updatedValue > 0 ? ( updatedValue * 100 ) / pervalue : 0;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestinationSlider = ( event, newValue ) => {
    setDestination( newValue );
    handleBuyingCarObject.second_interest_rate = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setDestination( updatedValue );
    handleBuyingCarObject.second_interest_rate = updatedValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleOptionsSlider = ( event, newValue ) => {
    setOptions( newValue );
    handleBuyingCarObject.second_term_of_the_loan = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Car loans typically have the following terms:</p>
      <ul className={ classes.downPayment }>
        <li>36 to 60 months</li>
        <li>4-8% interest rates</li>
        <li>0.5% or less in origination fees</li>
      </ul>
      <p>
        Adjust the loan terms below and see how they affect your monthly payment. Remember, the
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( '20/4/10 Rule' ) }>20-4-10 rule</span>
        {' '}
        and always look to have a loan with a term of 4 years or less.
      </p>
      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>
          <div className={ classes.carLoan }>
            <div className={ classes.leftBlock }>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span>&nbsp;</span>
                </div>
                <div className={ classes.flexDisplay }>
                  <div className={ classes.slideText }>
                    <p><b>Car Loan Amount</b></p>
                  </div>
                  <div className={ classes.slideAmount }>
                    <span>
                      <b>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module12Data.moreCarLoan } prefix="$" />
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Origination Fees' ) }>Origination Fees</span>
                </div>
                <div className={ classes.slideRow }>
                  <div className={ classes.slideText }>
                    <span>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ rateArrayValues }
                        min={ 0 }
                        max={ 3 }
                        step={ 0.01 }
                        value={ basePrice }
                        valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( basePrice ).toFixed( 2 ) )}` }
                        onChange={ handleBasePriceSlider }
                      />
                    </span>
                  </div>
                  <div className={ classes.slideInput }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <span>
                      {' '}
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ parseInt( discountInput, 10 ) } onValueChange={ handleBasePriceInput } onFocus={ ( e ) => { e.target.select(); } } disabled= {modulesServices.module12Data.moreCarLoan <= 0}/>
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span>
                </div>
                <div className={ classes.slideRow }>
                  <div className={ classes.slideText }>
                    <span>
                      {' '}
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ interestRateValues }
                        min={ 0 }
                        max={ 12 }
                        step={ 0.05 }
                        value={ destination }
                        valueLabelFormat={ `${commonFunctions.perFormatter( destination )}` }
                        onChange={ handleDestinationSlider }
                      />
                    </span>
                  </div>
                  <div className={ classes.slideInput }>
                    <span className={ classes.dollarSymbol } />
                    <span>
                      {' '}
                      <NumberFormat
                        customInput={ TextField }
                        thousandSeparator
                        value={ destination }
                        decimalScale={isFocus!=='second_car_interest_rate'?2:5}
                        fixedDecimalScale={isFocus!=='second_car_interest_rate'}
                        onValueChange={ (e) => {handleDestInput(e, 'second_car_interest_rate')} }
                        onFocus={ ( e ) => { e.target.select(); setIsFocus('second_car_interest_rate') } }
                        onBlur={emptyIsFocus} />
                    </span>
                    <span className={ classes.percentSymbol }>%</span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan' ) }>Term of the Loan</span>
                </div>
                <div className={ classes.slideRow }>
                  <div className={ classes.slideText }>
                    <span>
                      {' '}
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.idealRepayment() }
                        min={ 0 }
                        max={ 72 }
                        step={ 1 }
                        value={ options }
                        valueLabelFormat={ `${commonFunctions.mosFormatter( options )}` }
                        onChange={ handleOptionsSlider }
                      />
                    </span>
                  </div>
                  <div className={ classes.slideInput }>
                    <span className={ classes.dollarSymbol } />
                    <span>
                      {options}
                      {' '}
                      months
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span>&nbsp;</span>
                </div>
                <div className={ classes.flexDisplay }>
                  <div className={ classes.slideText }>
                    <p><b>Monthly Payment (Principal & Interest)</b></p>
                  </div>
                  <div className={ classes.slideAmount }>
                    <span>
                      <b>
                        {modulesServices.module12Data.monthlyPayment !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module12Data.monthlyPayment } prefix={ modulesServices.module12Data.monthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module12Data.monthlyPayment < 0 && ')' } />
                        ) : ( '$0' )}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span>&nbsp;</span>
                </div>
                <div className={ classes.flexDisplay }>
                  <div className={ classes.slideText }>
                    <p><b>Total Repayment Amount (Principal & Interest)</b></p>
                  </div>
                  <div className={ classes.slideAmount }>
                    <span>
                      <b>
                        {modulesServices.module12Data.totalPrincipalAndInterest !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module12Data.totalPrincipalAndInterest } prefix={ modulesServices.module12Data.totalPrincipalAndInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module12Data.totalPrincipalAndInterest < 0 && ')' } />
                        ) : ( '$0' )}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <ul className={ classes.utilitiesChart }>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Car Expenses
                    <span  className={ classes.chartSpan }>
                      {finalMnthlyExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyExpenses } prefix={ finalMnthlyExpenses >= 0 ? '$' : '($' } suffix={ finalMnthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span className={ classes.percentageBar } id="percentage1" />
                  <div className={ classes.graphPercentage }>
                    {percentageVal !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal } prefix={ percentageVal >= 0 ? '' : '(' } suffix={ percentageVal < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Income
                    <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                      {finalMnthlyAfterIncome !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyAfterIncome } prefix={ finalMnthlyAfterIncome >= 0 ? '$' : '($' } suffix={ finalMnthlyAfterIncome < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                  <div  className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                    {percentageVal2 !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal2 } prefix={ percentageVal2 >= 0 ? '' : '(' } suffix={ percentageVal2 < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.borderDivider } />
      <BuyingSecondCarTableBottom handleBuyingCarObject={ handleBuyingCarObject } activeStep={ activeStep } />
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SecondCarLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
  percentageVal: PropTypes.number.isRequired,
  percentageVal2: PropTypes.number.isRequired,
  finalMnthlyAfterIncome: PropTypes.number.isRequired,
  finalMnthlyExpenses: PropTypes.number.isRequired,

};

export default withStyles( styles )( SecondCarLoan );
