import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const DebitCardCreditCard = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Debit Card vs Credit Card</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-card-credit-card.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.debtCard}>
                      Finance Explained
                      <span> | </span>
                      Debit Card vs Credit Card
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/debit-credit-left.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p className={classes.noTopMargin}>
                          <b>
                            Debit Card vs Credit Card: What’s the difference?
                          </b>
                        </p>
                        <p>
                          When you make a purchase with your debit card, you
                          pull money directly from your checking account. When
                          you make a purchase with a credit card, you pay with
                          borrowed money that you agree to pay back later.
                        </p>
                        <p>
                          A debit card is linked to your checking account and
                          pulls money directly from your checking account to
                          purchase goods and services. What does that actually
                          mean? When you buy $20 of groceries with your debit
                          card, the grocery store will be paid $20 from your
                          checking account.{' '}
                          <i>
                            It&apos;s important to always know your checking
                            account balance when making purchases with a debit
                            card so that you don’t spend more than you have.
                          </i>
                        </p>
                        <p>
                          Credit is much different. A credit card allows you to
                          make purchases with borrowed money that you agree to
                          pay back to the bank or financial institution that
                          issued you the credit card. What does that actually
                          mean? When you buy $20 of groceries with your credit
                          card, your credit card balance goes up by $20. If you
                          don’t pay back your credit card balance by the payment
                          due date, your bank will charge you relatively high
                          interest on the outstanding balance.
                        </p>
                        <p>
                          A good rule of thumb is to only make purchases on your
                          credit card if you have the money in your checking
                          account to repay that amount. And, you should never
                          carry a balance on your credit card unless for an
                          emergency. Carrying a balance leads to high interest
                          charges.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DebitCardCreditCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DebitCardCreditCard);
