import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';
import logo from '../../../assets/img/logo.png';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';


const ReiTable = (props) => {
  const {
    classes, closeWorkSheet, openWorkSheet
  } = props;

  const closePopup = () => {
    closeWorkSheet('');
  };
  // const d = new Date();
  // const currentYear = d.getFullYear();

  let shortTermsInvestmentsData = moduleServices.shortTermTableCompleteData();
  let longTermsInvestmentsData = moduleServices.longTermTableCompleteData();
  let yearsList = [];
  let longYearList = [];
  if (openWorkSheet === 'short') {
    yearsList = shortTermsInvestmentsData['Years List'];
  } else {
    longYearList = longTermsInvestmentsData['Years List'];
  }
  const shortTermsInvestmentsStartYear = moduleServices.module9Data.short_term_start_year <= 0;
  const shortTermsInvestmentsLoopData = shortTermsInvestmentsData['Short Term Table Loop Data'];
  shortTermsInvestmentsData = shortTermsInvestmentsData['Short Term Table'];


  const longTermsInvestmentsStartYear = moduleServices.module9Data.long_term_start_year <= 0;
  const longTermsInvestmentsLoopData = longTermsInvestmentsData['Long Term Table Loop Data'];
  longTermsInvestmentsData = longTermsInvestmentsData['Long Term Table'];

  return (
    <Typography variant="body1" component="div">

      <Dialog
        open={openWorkSheet === 'short'}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={classes.worksheetModal}
      >
        <DialogTitle className={classes.summayModalHeader}>
          <div >
            <div>
              <Grid container>
                <Grid item sm={4}>
                  <div className={classes.logoPic}><img src={logo} alt="logo" /></div>
                </Grid>
                <Grid item sm={4}>
                </Grid>
              </Grid>
              <Button className={classes.incomePopupClose} onClick={closePopup}>×</Button>
            </div>
          </div>
        </DialogTitle>
        <div className={classes.borderTitle} ><p className={classes.borderStrip}>Short-Term Investments Worksheet</p></div>
        <DialogContent className={classes.modalBlock}>
          <div className={classNames(classes.modalBody, classes.modalRei)}>
            <table className={classes.analiseTable}>
              <tr>
                <td>
                  <table >
                    <tbody>
                      <tr className={classes.heightTen}></tr>
                      <tr>
                        <td className={classNames(classes.topTable, classes.buyingHomeOne, classes.reiTableData)}>
                          <table>
                            <tr className={classes.blueTableRow}>
                              <td>Short-Term Investments Assumptions</td>
                              <td >
                                Start Year&nbsp;{shortTermsInvestmentsStartYear}
                              </td>
                            </tr>
                            <tr>
                              <td>Beginning Balance</td>
                              <td className={shortTermsInvestmentsData['Beginning Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Beginning Balance']>0 || shortTermsInvestmentsData['Beginning Balance']<0 ? shortTermsInvestmentsData['Beginning Balance'] : 0} prefix={shortTermsInvestmentsData['Beginning Balance'] >= 0 ? '$' : "($"} suffix={shortTermsInvestmentsData['Beginning Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Max Cash Balance</td>
                              <td className={shortTermsInvestmentsData['Max Cash Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Max Cash Balance'] >0 || shortTermsInvestmentsData['Max Cash Balance'] <= 0 ? shortTermsInvestmentsData['Max Cash Balance'] : 0} prefix={shortTermsInvestmentsData['Max Cash Balance'] >= 0 ? '$' : "($"} suffix={shortTermsInvestmentsData['Max Cash Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Max Short-Term Investments Balance</td>
                              <td className={shortTermsInvestmentsData['Max Short-Term Investments Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Max Short-Term Investments Balance']>0 || shortTermsInvestmentsData['Max Short-Term Investments Balance'] <= 0 ? shortTermsInvestmentsData['Max Short-Term Investments Balance'] : 0} prefix={shortTermsInvestmentsData['Max Short-Term Investments Balance'] >= 0 ? '$' : "($"} suffix={shortTermsInvestmentsData['Max Short-Term Investments Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Contribution	</td>
                              <td className={shortTermsInvestmentsData['Annual Contribution'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Annual Contribution'] > 0 || shortTermsInvestmentsData['Annual Contribution'] <= 0 ? shortTermsInvestmentsData['Annual Contribution'] : 0} prefix={shortTermsInvestmentsData['Annual Contribution'] >= 0 ? '$' : "($"} suffix={shortTermsInvestmentsData['Annual Contribution'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Contribution Growth</td>
                              <td className={shortTermsInvestmentsData['Annual Contribution Growth'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Annual Contribution Growth'] > 0 || shortTermsInvestmentsData['Annual Contribution Growth'] <= 0 ? shortTermsInvestmentsData['Annual Contribution Growth'] : 0} prefix={shortTermsInvestmentsData['Annual Contribution Growth'] < 0 && '('} suffix={shortTermsInvestmentsData['Annual Contribution Growth'] >= 0 ? '%' : '%)'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Expected Pre-Tax Return</td>
                              <td className={shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] > 0 || shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] <= 0 ? shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] : 0} prefix={shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] < 0 && '('} suffix={shortTermsInvestmentsData['Annual Expected Pre-Tax Return'] >= 0 ? '%' : '%)'} />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr className={classes.heightFifty}></tr>
                    </tbody>
                  </table>
                  <table className={classNames(classes.incomeCashTable, classes.annualReiTable)}>
                    <thead>
                      <tr className={classNames(classes.blueRowBackground, classes.leftSpaceData)}>
                        <td>Short-Term Investments Summary</td>
                        <td>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td>{year}</td>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Beginning Short-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Annual Contribution</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Earnings*</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Transfer to Cash Account for Cash Need (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Contributions from Excess Cash (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Balance before Transfer to Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Max Short-Term Investments Balance</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              <i><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && ')'} /></i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Transfer to Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Short-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.blueRowBackground, classes.leftSpaceData)}>
                        <td>Cash Flow Statement Summary</td>
                        <td>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td>{year}</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance before Transfers</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance before Transfers'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance before Transfers']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance before Transfers'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance before Transfers'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Cash Need</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Need'] < 0 && classes.redText}>
                              <i><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Need']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Need'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Need'] < 0 && ')'} /></i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Cash Injection from Short-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Short-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Short-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Short-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Short-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Cash Injection from Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Long-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Cash Injection from Long-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance after Transfers</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance after Transfers'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance after Transfers']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance after Transfers'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance after Transfers'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Max Cash Balance</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Max Cash Balance'] < 0 && classes.redText}>
                              <i><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Max Cash Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Max Cash Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Max Cash Balance'] < 0 && ')'} /></i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Excess Cash Put into Short-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Excess Cash Put into Short-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          yearsList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <div className={classes.notePara}>
              <p className={classes.noteRei}>*Earnings are calculated with the following formula: (Beginning Balance + (Beginning Balance + Annual Contribution)) / 2 * Annual Return</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.modalFooter}>
            <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={closePopup}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWorkSheet === 'long'}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={classes.worksheetModal}
      >
        <DialogTitle className={classes.summayModalHeader}>
          <div >
            <div>
              <Grid container>
                <Grid item sm={4}>
                  <div className={classes.logoPic}><img src={logo} alt="logo" /></div>
                </Grid>
                <Grid item sm={4}>
                </Grid>
              </Grid>
              <Button className={classes.incomePopupClose} onClick={closePopup}>×</Button>
            </div>
          </div>
        </DialogTitle>
        <div className={classes.borderTitle} ><p className={classes.borderStrip}>Long-Term Investments Worksheet</p></div>
        <DialogContent className={classes.modalBlock}>
          <div className={classNames(classes.modalBody, classes.modalRei)}>
            <table className={classes.analiseTable}>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr className={classes.heightTen}></tr>
                      <tr>
                        {/* <td className={classNames(classes.topTable, classes.investAssumptions)}> */}
                        <td className={classNames(classes.topTable, classes.buyingHomeOne, classes.reiTableData)}>
                          <table>
                            <tr className={classes.blueTableRow}>
                              <td>Long-Term Investments Assumptions	</td>
                              <td >
                                Start Year&nbsp;{longTermsInvestmentsStartYear}
                              </td>
                            </tr>
                            <tr>
                              <td>Beginning Balance</td>
                              <td className={longTermsInvestmentsData['Beginning Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Beginning Balance'] < 0 || longTermsInvestmentsData['Beginning Balance']>=0 ? longTermsInvestmentsData['Beginning Balance'] : 0 } prefix={longTermsInvestmentsData['Beginning Balance'] >= 0 ? '$' : "($"} suffix={longTermsInvestmentsData['Beginning Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Max Cash Balance</td>
                              <td className={longTermsInvestmentsData['Max Cash Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Max Cash Balance'] < 0 || longTermsInvestmentsData['Max Cash Balance'] >= 0 ? longTermsInvestmentsData['Max Cash Balance'] : 0} prefix={longTermsInvestmentsData['Max Cash Balance'] >= 0 ? '$' : "($"} suffix={longTermsInvestmentsData['Max Cash Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Max Short-Term Investments Balance</td>
                              <td className={longTermsInvestmentsData['Max Short-Term Investments Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Max Short-Term Investments Balance'] < 0 || longTermsInvestmentsData['Max Short-Term Investments Balance']>=0 ? longTermsInvestmentsData['Max Short-Term Investments Balance'] : 0} prefix={longTermsInvestmentsData['Max Short-Term Investments Balance'] >= 0 ? '$' : "($"} suffix={longTermsInvestmentsData['Max Short-Term Investments Balance'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Contribution	</td>
                              <td className={longTermsInvestmentsData['Annual Contribution'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Annual Contribution'] < 0 || longTermsInvestmentsData['Annual Contribution'] >= 0 ? longTermsInvestmentsData['Annual Contribution'] : 0} prefix={longTermsInvestmentsData['Annual Contribution'] >= 0 ? '$' : "($"} suffix={longTermsInvestmentsData['Annual Contribution'] < 0 && ')'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Contribution Growth</td>
                              <td className={longTermsInvestmentsData['Annual Contribution Growth'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Annual Contribution Growth'] < 0 || longTermsInvestmentsData['Annual Contribution Growth'] >= 0 ? longTermsInvestmentsData['Annual Contribution Growth'] : 0} prefix={longTermsInvestmentsData['Annual Contribution Growth'] < 0 && '('} suffix={longTermsInvestmentsData['Annual Contribution Growth'] >= 0 ? '%' : '%)'} />
                              </td>
                            </tr>
                            <tr>
                              <td>Annual Expected Pre-Tax Return</td>
                              <td className={longTermsInvestmentsData['Annual Expected Pre-Tax Return'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsData['Annual Expected Pre-Tax Return'] < 0 || longTermsInvestmentsData['Annual Expected Pre-Tax Return'] >= 0 ? longTermsInvestmentsData['Annual Expected Pre-Tax Return'] : 0} prefix={longTermsInvestmentsData['Annual Expected Pre-Tax Return'] < 0 && '('} suffix={longTermsInvestmentsData['Annual Expected Pre-Tax Return'] >= 0 ? '%' : '%)'} />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr className={classes.heightFifty}></tr>
                    </tbody>
                  </table>
                  <table className={classNames(classes.incomeCashTable, classes.annualReiTable, classes.investSubTable)}>
                    <thead>
                      <tr className={classNames(classes.blueRowBackground, classes.leftSpaceData)}>
                        <td>Long-Term Investments Summary</td>
                        <td>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td>{year}</td>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Beginning Long-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Beginning Balance'] < 0 && longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Beginning Balance'] !== undefined && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Beginning Balance']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Beginning Balance'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Beginning Balance'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Annual Contribution</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Annual Contribution'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Annual Contribution']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Annual Contribution'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Annual Contribution'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Earnings*</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Earnings'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Earnings']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Earnings'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Earnings'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Transfer to Cash Account for Cash Need (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Contributions from Excess Short-Term Investments (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Contributions from Excess Cash (see below)']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Contributions from Excess Cash (see below)'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Long-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Balance before Transfer to Long-Term Investments']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.blueRowBackground, classes.leftSpaceData)}>
                        <td>Short-Term Investments Summary</td>
                        <td>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td>{year}</td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Beginning Short-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] < 0 && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] !== undefined && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Annual Contribution</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Earnings*</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Earnings'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Transfer to Cash Account for Cash Need (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Contributions from Excess Cash (see below)</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Balance before Transfer to Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Max Short-Term Investments Balance</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              <i>
                                {shortTermsInvestmentsLoopData !== undefined ?
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && ')'} />
                                  :
                                  '$0'
                                }
                              </i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Transfer to Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Short-Term Investments Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={shortTermsInvestmentsLoopData !== undefined && shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] < 0 && classes.redText}>
                              {shortTermsInvestmentsLoopData !== undefined ?
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance']} prefix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] >= 0 ? '$' : '($'} suffix={shortTermsInvestmentsLoopData[year]['Short-Term Investments Summary']['Ending Balance'] < 0 && ')'} />
                                :
                                '$0'
                              }
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.blueRowBackground, classes.leftSpaceData)}>
                        <td>Cash Flow Statement Summary</td>
                        <td>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td>{year}</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance before Transfers</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance before Transfers'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance before Transfers']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance before Transfers'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance before Transfers'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Cash Need</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Need'] < 0 && classes.redText}>
                              <i><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Need']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Need'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Need'] < 0 && ')'} /></i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Cash Injection from Short-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Short-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Short-Term Investments']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Short-Term Investments'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Short-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Cash Injection from Long-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Long-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Long-Term Investments']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Long-Term Investments'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Cash Injection from Long-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance after Transfers</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance after Transfers'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance after Transfers']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance after Transfers'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance after Transfers'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}><i>Max Cash Balance</i></td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Max Cash Balance'] < 0 && classes.redText}>
                              <i><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Max Cash Balance']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Max Cash Balance'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Max Cash Balance'] < 0 && ')'} /></i>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className={classes.subHeading}>Excess Cash Put into Short-Term Investments</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Excess Cash Put into Short-Term Investments']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                      <tr className={classes.emptyRow}>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map(() => (
                            <td>&nbsp;</td>
                          ))
                        }
                      </tr>
                      <tr className={classNames(classes.borderLine, classes.leftSpaceData, classes.strongText)}>
                        <td className={classes.subHeading}>Ending Cash Balance</td>
                        <td className={classes.lightBlueBg}>&nbsp;</td>
                        {
                          longYearList.map((year) => (
                            <td className={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance'] < 0 && classes.redText}>
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance']} prefix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance'] >= 0 ? '$' : '($'} suffix={longTermsInvestmentsLoopData[year]['Long-Term Investments Summary']['Ending Cash Balance'] < 0 && ')'} />
                            </td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <div className={classes.notePara}>
              <p className={classes.noteRei}>*Earnings are calculated with the following formula: (Beginning Balance + (Beginning Balance + Annual Contribution)) / 2 * Annual Return</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.modalFooter}>
            <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={closePopup}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};

ReiTable.propTypes = {
  classes: PropTypes.object.isRequired,
  closeWorkSheet: PropTypes.func.isRequired,
  openWorkSheet: PropTypes.bool.isRequired,
};


export default withStyles(styles)(ReiTable);

