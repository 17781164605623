import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import * as modulesServices from '../../../calculations/modules-services';
import infoIcon from '../../../assets/img/pwi-rei/info-icon.svg';

const ReiBottom = ( props ) => {
  const {
    classes, handleDefinationPopup, handleSlide, activeStep, inputData, setOpenWorkSheet
  } = props;

  const [openPerchaseTable, setOpenPerchaseTable] = useState( false );
  const [openExpensesTable, setOpenExpensesTable] = useState( false );
  const [slideNumber, setSlideNumber] = useState();

  useEffect( () => {
    if ( activeStep !== slideNumber ) {
      setSlideNumber( activeStep );
      if ( slideNumber === 16 ) {
        setOpenPerchaseTable( true );
        setOpenExpensesTable( true );
      } else {
        setOpenPerchaseTable( false );
        setOpenExpensesTable( false );
      }
    }
    // eslint-disable-next-line
  }, [activeStep, slideNumber]);

  return (
    <div className={ classes.contentBlock }>
      {activeStep !== 17 && ( <Button className={ classes.worksheetButton } onClick={()=>setOpenWorkSheet(true)}>SUMMARY WORKSHEET</Button> )}
      <div className={ classes.worksheetRow }>
        <div className={ classes.worksheetTable }>
          <table className={ classNames( classes.yearTable, classes.debtToIncome, activeStep === 17 && classes.hideFirstColumn ) }>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Purchase Analysis </th>
                <th>
                  Year
                  {inputData.year_of_purchase && inputData.year_of_purchase}
                </th>
              </tr>
            </thead>
            {( openPerchaseTable === true || activeStep === 17 ) && (
              <tbody>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 5 ); } }>
                  <td />
                  <td>Purchase Price</td>
                  <td className={ classNames( modulesServices.module14Data.reiPurchasePrice < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiPurchasePrice } prefix={ modulesServices.module14Data.reiPurchasePrice >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiPurchasePrice < 0 && ')' } />
                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 5 ); } }>
                  <td />
                  <td className={ classes.leftData }>Down Payment</td>
                  <td className={ classNames( modulesServices.module14Data.reiDownPayment < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiDownPayment } prefix={ modulesServices.module14Data.reiDownPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiDownPayment < 0 && ')' } />
                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 14 ); } }>
                  <td />
                  <td className={ classes.leftData }><span aria-hidden="true" data-for="definationTitleBottom1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Closing Costs' ) } className={ classes.dashedBorder }>Closing Costs</span></td>
                  <td className={ classNames( modulesServices.module14Data.reiClosingCosts < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiClosingCosts } prefix={ modulesServices.module14Data.reiClosingCosts >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiClosingCosts < 0 && ')' } />
                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 15 ); } }>
                  <td />
                  <td className={ classes.leftData }><span aria-hidden="true" data-for="definationTitleBottom1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Fit Out Costs: Real Estate' ) } className={ classes.dashedBorder }>Fit Out Costs</span></td>
                  <td className={ classNames( modulesServices.module14Data.reiFitOutCosts < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiFitOutCosts } prefix={ modulesServices.module14Data.reiFitOutCosts >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiFitOutCosts < 0 && ')' } />
                    <ReactTooltip id="definationTitleBottom1" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
                  </td>
                </tr>
              </tbody>
            )}

            <tbody>
              <tr className={ classNames( classes.blueBottomBorder, classes.cursorPointer ) } onClick={ () => { setOpenPerchaseTable( !openPerchaseTable ); } }>
                <td>
                  {openPerchaseTable === true ? (
                    <span className="plus">
                      <i className="fa fa-minus" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="plus">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  )}
                </td>
                <td><b>Cash Needed at Purchase</b></td>
                <td className={ classNames( modulesServices.module14Data.realEstatecashNeededAtPurchase < 0 && classes.redText ) }>
                  <b>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.realEstatecashNeededAtPurchase } prefix={ modulesServices.module14Data.realEstatecashNeededAtPurchase >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.realEstatecashNeededAtPurchase < 0 && ')' } />
                  </b>
                </td>
              </tr>
              <tr>
                <td />
                <td>Total Cash Available</td>
                <td className={ classNames( modulesServices.module14Data.realEstatetotalCashAvailable < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.realEstatetotalCashAvailable } prefix={ modulesServices.module14Data.realEstatetotalCashAvailable >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.realEstatetotalCashAvailable < 0 && ')' } />
                </td>
              </tr>
              <tr>
                <td />
                <td>Total Investments Available</td>
                <td className={ classNames( modulesServices.module14Data.realEstatetotalLiquid < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.realEstatetotalLiquid } prefix={ modulesServices.module14Data.realEstatetotalLiquid >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.realEstatetotalLiquid < 0 && ')' } />

                </td>
              </tr>
            </tbody>
            <tbody>
              <tr className={ classNames( classes.blueBottomBorder, classes.blueBgTr ) }>
                <td />
                <td><span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Debt-to-Income Ratio (KPI)' ) } className={ classes.dashedBorder }><b>Debt-to-Income Ratio</b></span></td>
                <td className={ `${modulesServices.module14Data.reiDebt > 36 && classes.redData} ${modulesServices.module14Data.reiDebt <= 36 && modulesServices.module14Data.reiDebt !== 0  && classes.greenData} ${modulesServices.module14Data.reiDebt === 0 && classes.grayData}` }>
                  <b>
                    {modulesServices.module14Data.reiDebt !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ modulesServices.module14Data.reiDebt } prefix={ modulesServices.module14Data.reiDebt >= 0 ? '' : '(' } suffix={ modulesServices.module14Data.reiDebt < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <table className={ classNames( classes.yearTable, classes.cashFlow, activeStep === 17 && classes.hideFirstColumn ) }>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Cash Flow Summary</th>
                <th>Monthly</th>
                <th>
                  Year
                  {inputData.year_of_purchase}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={ classNames( classes.blueBoldText, classes.cursorPointer ) } onClick={ () => { handleSlide( 10 ); } }>
                <td />
                <td>Adjusted Rental Income</td>
                <td className={ classNames( modulesServices.module14Data.adjustedRentalIncome < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.adjustedRentalIncome } prefix={ modulesServices.module14Data.adjustedRentalIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.adjustedRentalIncome < 0 && ')' } />

                </td>
                <td className={ classNames( modulesServices.module14Data.adjustedRentalIncome < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.adjustedRentalIncome * 12 } prefix={ modulesServices.module14Data.adjustedRentalIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.adjustedRentalIncome < 0 && ')' } />
                </td>
              </tr>
            </tbody>
            {( openExpensesTable === true || activeStep === 17 ) && (
              <tbody>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 6 ); } }>
                  <td />
                  <td className={ classes.leftData }>Mortgage Payment (Principal & Interest)</td>
                  <td className={ classNames( modulesServices.module14Data.mortgagepaymentInterest < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.mortgagepaymentInterest } prefix={ modulesServices.module14Data.mortgagepaymentInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.mortgagepaymentInterest < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.mortgagepaymentInterest < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.mortgagepaymentInterest * 12 } prefix={ modulesServices.module14Data.mortgagepaymentInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.mortgagepaymentInterest < 0 && ')' } />

                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 11 ); } }>
                  <td />
                  <td className={ classes.leftData }>Property Taxes</td>
                  <td className={ classNames( modulesServices.module14Data.propertytaxesValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.propertytaxesValue } prefix={ modulesServices.module14Data.propertytaxesValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.propertytaxesValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.propertytaxesValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.propertytaxesValue * 12 } prefix={ modulesServices.module14Data.propertytaxesValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.propertytaxesValue < 0 && ')' } />

                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 12 ); } }>
                  <td />
                  <td className={ classes.leftData }>Homeowner&apos;s Insurance</td>
                  <td className={ classNames( modulesServices.module14Data.homeOwnersInsuranceValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.homeOwnersInsuranceValue } prefix={ modulesServices.module14Data.homeOwnersInsuranceValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.homeOwnersInsuranceValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.homeOwnersInsuranceValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.homeOwnersInsuranceValue * 12 } prefix={ modulesServices.module14Data.homeOwnersInsuranceValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.homeOwnersInsuranceValue < 0 && ')' } />
                  </td>
                </tr>
                <tr className={ classNames( classes.blueBoldText, classes.cursorPointer ) } onClick={ () => { handleSlide( 8 ); } }>
                  <td />
                  <td>PITI (Principal, Interest, Taxes, & Insurance)</td>
                  <td className={ classNames( modulesServices.module14Data.reiPitiValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiPitiValue } prefix={ modulesServices.module14Data.reiPitiValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiPitiValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.reiPitiValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiPitiValue * 12 } prefix={ modulesServices.module14Data.reiPitiValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiPitiValue < 0 && ')' } />
                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 13 ); } }>
                  <td />
                  <td className={ classes.leftData }>Management Fee</td>
                  <td className={ classNames( modulesServices.module14Data.managementValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.managementValue } prefix={ modulesServices.module14Data.managementValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.managementValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.managementValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.managementValue * 12 } prefix={ modulesServices.module14Data.managementValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.managementValue < 0 && ')' } />

                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 13 ); } }>
                  <td />
                  <td className={ classes.leftData }>Maintenance</td>
                  <td className={ classNames( modulesServices.module14Data.maintenanceValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.maintenanceValue } prefix={ modulesServices.module14Data.maintenanceValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.maintenanceValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.maintenanceValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.maintenanceValue * 12 } prefix={ modulesServices.module14Data.maintenanceValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.maintenanceValue < 0 && ')' } />

                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 13 ); } }>
                  <td />
                  <td className={ classes.leftData }>Association Fees</td>
                  <td className={ classNames( modulesServices.module14Data.associationFeesValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.associationFeesValue } prefix={ modulesServices.module14Data.associationFeesValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.associationFeesValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.associationFeesValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.associationFeesValue * 12 } prefix={ modulesServices.module14Data.associationFeesValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.associationFeesValue < 0 && ')' } />

                  </td>
                </tr>
                <tr className={ classes.cursorPointer } onClick={ () => { handleSlide( 13 ); } }>
                  <td />
                  <td className={ classes.leftData }>Utilities</td>
                  <td className={ classNames( modulesServices.module14Data.utilitiesExpenseValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.utilitiesExpenseValue } prefix={ modulesServices.module14Data.utilitiesExpenseValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.utilitiesExpenseValue < 0 && ')' } />

                  </td>
                  <td className={ classNames( modulesServices.module14Data.utilitiesExpenseValue < 0 && classes.redText ) }>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.utilitiesExpenseValue * 12 } prefix={ modulesServices.module14Data.utilitiesExpenseValue >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.utilitiesExpenseValue < 0 && ')' } />

                  </td>
                </tr>
              </tbody>
            )}
            <tbody>
              <tr className={ classNames( classes.blueBottomBorder, classes.cursorPointer ) } onClick={ () => { setOpenExpensesTable( !openExpensesTable ); } }>
                <td>
                  {openExpensesTable === true ? (
                    <span className="plus">
                      <i className="fa fa-minus" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="plus">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  )}
                </td>
                <td><b>Total Expenses</b></td>
                <td className={ classNames( modulesServices.module14Data.reiTotalMonthlyExpenses < 0 && classes.redText ) }>
                  <b>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiTotalMonthlyExpenses } prefix={ modulesServices.module14Data.reiTotalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiTotalMonthlyExpenses < 0 && ')' } />
                  </b>
                </td>
                <td className={ classNames( modulesServices.module14Data.reiTotalMonthlyExpenses < 0 && classes.redText ) }>
                  <b>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.reiTotalMonthlyExpenses * 12 } prefix={ modulesServices.module14Data.reiTotalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.reiTotalMonthlyExpenses < 0 && ')' } />
                  </b>
                </td>
              </tr>
              <tr className={ classes.blueBoldText }>
                <td />
                <td>Net Cash Flow</td>
                <td className={ classNames( modulesServices.module14Data.monthlyNetIncomeExpenses < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.monthlyNetIncomeExpenses } prefix={ modulesServices.module14Data.monthlyNetIncomeExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.monthlyNetIncomeExpenses < 0 && ')' } />
                </td>
                <td className={ classNames( modulesServices.module14Data.monthlyNetIncomeExpenses < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.monthlyNetIncomeExpenses * 12 } prefix={ modulesServices.module14Data.monthlyNetIncomeExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.monthlyNetIncomeExpenses < 0 && ')' } />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div className={ classes.expensesTable }>
          <table className={ classes.annualTable }>
            <thead>
              <tr>
                <th />
                <th colSpan="2">Investment Analysis (before taxes)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td className={ classes.leftData }>Total Rental Income</td>
                <td className={ classNames( modulesServices.module14Data.investmentSummaryTotalRentalVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.investmentSummaryTotalRentalVal } prefix={ modulesServices.module14Data.investmentSummaryTotalRentalVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.investmentSummaryTotalRentalVal < 0 && ')' } />
                </td>
              </tr>
              <tr>
                <td />
                <td className={ classes.leftData }>Total Operating Expenses</td>
                <td className={ classNames( modulesServices.module14Data.investmentSummaryTotalPropertyVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.investmentSummaryTotalPropertyVal } prefix={ modulesServices.module14Data.investmentSummaryTotalPropertyVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.investmentSummaryTotalPropertyVal < 0 && ')' } />
                </td>
              </tr>
              <tr>
                <td />
                <td className={ classes.leftData }>Total Financing Costs</td>
                <td className={ classNames( modulesServices.module14Data.investmentSummaryTotalFinancingExpensesVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.investmentSummaryTotalFinancingExpensesVal } prefix={ modulesServices.module14Data.investmentSummaryTotalFinancingExpensesVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.investmentSummaryTotalFinancingExpensesVal < 0 && ')' } />
                </td>
              </tr>
              <tr className={ classes.blueBoldText }>
                <td />
                <td>Total Net Rental Income</td>
                <td className={ classNames( modulesServices.module14Data.investmentSummaryNetRentalVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.investmentSummaryNetRentalVal } prefix={ modulesServices.module14Data.investmentSummaryNetRentalVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.investmentSummaryNetRentalVal < 0 && ')' } />
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  <img aria-hidden="true" data-for="definationTitleBottom" data-tip="Selling price of the rental property minus selling cost minus mortgage balance remaining at time of sale equals net cash flow from sale. Click on the summary worksheet button above to see more details." src={ infoIcon } alt="" />
                </td>
                <td className={ classes.leftData }>Net Cash Flow from Sale</td>
                <td className={ classNames( modulesServices.module14Data.netCashFlowFromSale < 0 && classes.redText ) }>
                  <b>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.netCashFlowFromSale } prefix={ modulesServices.module14Data.netCashFlowFromSale >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.netCashFlowFromSale < 0 && ')' } />
                  </b>
                </td>
              </tr>
              <tr className={ classes.blueBoldText }>
                <td />
                <td>Total Cash Returned </td>
                <td className={ classNames( modulesServices.module14Data.totalCashReturnedVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.totalCashReturnedVal } prefix={ modulesServices.module14Data.totalCashReturnedVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.totalCashReturnedVal < 0 && ')' } />
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  <img aria-hidden="true" data-for="definationTitleBottom" data-tip="Down payment plus closing costs plus fit out costs equals total cash invested. Click on the summary worksheet button above to see more details." src={ infoIcon } alt="" />
                </td>
                <td>Total Cash Invested</td>
                <td className={ classNames( modulesServices.module14Data.totalCashInvestedVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.totalCashInvestedVal } prefix={ modulesServices.module14Data.totalCashInvestedVal >= 0 ? '$' : '($' } suffix={ modulesServices.module14Data.totalCashInvestedVal < 0 && ')' } />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classes.blueItalic }>
                <th />
                <th><span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Return on Investment (ROI)' ) } className={ classes.dashedBorder }>Return on Investment</span></th>
                <th className={ classNames( modulesServices.module14Data.ROICalVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.ROICalVal } prefix={ modulesServices.module14Data.ROICalVal >= 0 ? '' : '(' } suffix={ modulesServices.module14Data.ROICalVal < 0 ? '%)' : '%' } />
                </th>
              </tr>
              <tr className={ classes.blueItalic }>
                <th />
                <th><span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Multiple of Money (MoM)' ) } className={ classes.dashedBorder }>Multiple of Money</span></th>
                <th className={ classNames( modulesServices.module14Data.MoMCalVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.MoMCalVal } prefix={modulesServices.module14Data.MoMCalVal >= 0 ? '':'('} suffix={ modulesServices.module14Data.MoMCalVal < 0 ? ')' : 'x' } />
                </th>
              </tr>
              <tr className={ classes.blueItalic }>
                <th />
                <th><span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Cash-on-Cash Return (CoC)' ) } className={ classes.dashedBorder }>Cash-on-Cash Return</span></th>
                <th className={ classNames( modulesServices.module14Data.cashOnCashReturn < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.cashOnCashReturn } prefix={ modulesServices.module14Data.cashOnCashReturn >= 0 ? '' : '(' } suffix={ modulesServices.module14Data.cashOnCashReturn < 0 ? '%)' : '%' } />
                </th>
              </tr>
              <tr className={ classes.blueItalic }>
                <th />
                <th><span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Internal Rate of Return (IRR)' ) } className={ classes.dashedBorder }>Internal Rate of Return</span></th>
                <th className={ classNames( modulesServices.module14Data.IRRCalVal < 0 && classes.redText ) }>
                  <NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module14Data.IRRCalVal } prefix={ modulesServices.module14Data.IRRCalVal >= 0 ? '' : '(' } suffix={ modulesServices.module14Data.IRRCalVal < 0 ? '%)' : '%' } />
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div />
      <ReactTooltip id="definationTitleBottom" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

ReiBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default withStyles( styles )( ReiBottom );
