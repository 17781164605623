import React from 'react';
import styled from 'styled-components';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import LogoMain from '../../../../assets/img/menu/submenu_right_news_img.png';

const NewsDropdownEl = styled.div`
  width: 50rem;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  h3 {
    margin:0 0 0;
  }
`;

const NewsDropdown = ( props ) => {
  const { classes,history } = props;

  const redirectToSignupFree = () => {
    history.push('/finance-explained');
  };

  const handleNews = () =>{
    localStorage.setItem('loading',true);
  }
  
  return (
    <NewsDropdownEl className={ classes.marginLeftNewCompany }>
      <Grid container>
        <Grid sm={ 6 }>
          <Grid container className={ classes.megamenuContainer }>
            <Grid sm={ 12 }>
              <div className={ classes.LeftMegamenu }>
                <div className={ classes.paddingTopFisty } />
                <div className={ classes.listGroup }>
                  <ul>
                  <li><Link to={"/news"+"?inkwiry"} onClick={handleNews}>Inkwiry</Link></li>
                    <li><Link to={"/news"+"?planning"} onClick={handleNews}>Planning</Link></li>
                    <li><Link to={"/news"+"?money"} onClick={handleNews}>Money</Link></li>
                    <li><Link to={"/news"+"?taxes"} onClick={handleNews}>Taxes</Link></li>
                    <li><Link to={"/news"+"?career"} onClick={handleNews}>Career</Link></li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={ 6 } className={ classes.rightMegamenu }>
          <div className={ classes.aboutMegaMenu }>
            <img src={ LogoMain } alt="" />
            <h4>Inkwiry | finance explained</h4>
            <p>Get smarter about your money with just one email per week.</p>
            <Button className={ classes.btnstartQuizNews } onClick={redirectToSignupFree}>SIGN UP FOR FREE</Button>
          </div> 
        </Grid>

      </Grid>
    </NewsDropdownEl>
  );
};

NewsDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( NewsDropdown );
