import React from 'react';
import PropTypes from 'prop-types';
import {
	Typography, Button,
	Tabs,
	Tab,
	Box,
} from '@material-ui/core';
import InvestmentBottomTwo from './investmment-bottomTwo';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
import { withStyles, useTheme } from '@material-ui/core/styles';
// import infoIcon from '../../assets/img/pwi-he/info-icon.svg';
import * as modulesServices from '../../calculations/modules-services';
// import { formatDollar } from '../../utilities/chartCommonFunctions';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}


const InvestmentBottomThree = (props) => {
	const { classes, getInvestmentObject, setOpenWorkSheet, activeSlide } = props;
	const [value, setValue] = React.useState(0);
	const startYear = getInvestmentObject.short_term_start_year;
	const theme = useTheme();

	console.log('getInvestmentObject', getInvestmentObject, props);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// const handlelong = () => {
	// 	setOpenWorkSheet('long');
	// }

	// const handleshort = () => {
	// 	setOpenWorkSheet('short');
	// }

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	return (
		<div>
			<div className={classes.tabSection}>
				<Tabs value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					indicatorColor="primary"
					textColor="primary"
					className={classes.tabButtons}
				>
					<Tab label="My Budget" {...a11yProps(0)} className={value === 0 ? classes.activeTab : ''} />
					<Tab label="Investments Summary" {...a11yProps(1)} className={value === 1 ? classes.activeTab : ''} />
					<Tab label="Plan Summary" {...a11yProps(2)} className={value === 2 ? classes.activeTab : ''} />
				</Tabs>
				<SwipeableViews
					className={`${classes.tabsContent} ${classes.tabContentBaseIncome}`}
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<InvestmentBottomTwo activeSlide={activeSlide} getInvestmentObject={getInvestmentObject} startYear={startYear} />
					</TabPanel>
					<TabPanel value={value} index={1} dir={theme.direction}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} className={classes.isummaryTableLeft}>
								<div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTableInvestNew)}>
									<table>
										<thead>
											<tr className={classes.mainRow}>

												<th>Short-Term Investments Summary</th>
												<th>
													<Button onClick={() => { setOpenWorkSheet('short') }} className={classes.showWorkSheet}>SHOW TABLE</Button>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr className={classes.grayRow}>
												<td>
													<i>Investment Period</i>
												</td>
												<td>
													<i>
														{' '}
														{modulesServices.module9Data.shortTermInvestmentsPeriod}
													</i>
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td>
													<span>
														Beginning Short-Term Investments Balance
													</span>
												</td>
												<td>
													{modulesServices.module9Data.shortTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.shortTermSummaryBeginningBalance !== null && modulesServices.module9Data.shortTermSummaryBeginningBalance !== ''  && modulesServices.module9Data.shortTermSummaryBeginningBalance !== isNaN ? (
														<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryBeginningBalance} prefix={modulesServices.module9Data.shortTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryBeginningBalance < 0 && ')'} />) : ('$0')}
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
												<td>
													<span>Total Annual Contributions</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.shortTermSummaryContributions !== undefined && modulesServices.module9Data.shortTermSummaryContributions !== null && modulesServices.module9Data.shortTermSummaryContributions !== '' && modulesServices.module9Data.shortTermSummaryContributions !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryContributions} prefix={modulesServices.module9Data.shortTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryContributions < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.bottomBlueBorder, classes.leftdataPadding)}>

												<td>
													<span>Total Earnings</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.shortTermSummaryEarnings !== undefined && modulesServices.module9Data.shortTermSummaryEarnings !== null && modulesServices.module9Data.shortTermSummaryEarnings !== '' && modulesServices.module9Data.shortTermSummaryEarnings !== isNaN  ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryEarnings} prefix={modulesServices.module9Data.shortTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEarnings < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td>
													<b><span>Short-Term Investments Balance before Transfers and Withdrawals</span></b>
												</td>
												<td>
													<b><span>
														{modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== undefined && modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== null && modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== '' && modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers < 0 && ')'} />) : ('$0')}
													</span></b>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
												<td>
													<span>

														Excess Cash Moved Into Short-Term Investments for
														{' '}
														{modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== null && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== '' && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
														{' '}
														Years
													</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.shortTermSummaryExcessCash !== undefined && modulesServices.module9Data.shortTermSummaryExcessCash !== null && modulesServices.module9Data.shortTermSummaryExcessCash !== '' && modulesServices.module9Data.shortTermSummaryExcessCash !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryExcessCash} prefix={modulesServices.module9Data.shortTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryExcessCash < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
												<td>
													<span>
														Total Withdrawals for Cash Need for
														{' '}
														{modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== null && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== '' && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
														{' '}
														Years
													</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.shortTermSummaryCashWithDrawn !== undefined && modulesServices.module9Data.shortTermSummaryCashWithDrawn !== null && modulesServices.module9Data.shortTermSummaryCashWithDrawn !== '' && modulesServices.module9Data.shortTermSummaryCashWithDrawn !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.shortTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashWithDrawn < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
												<td>
													<span>
														Excess Short-Term Investments Moved into Long-Term Investments for
														{' '}
														{modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== null && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== '' && modulesServices.module9Data.shortTermInvestmentsPeriodYears !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
														{' '}
														Years
													</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.shortTermSummaryCashDeposited !== undefined && modulesServices.module9Data.shortTermSummaryCashDeposited !== null && modulesServices.module9Data.shortTermSummaryCashDeposited !== '' && modulesServices.module9Data.shortTermSummaryCashDeposited !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashDeposited} prefix={modulesServices.module9Data.shortTermSummaryCashDeposited >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashDeposited < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.blueRow, classes.bottomBlueBorder)}>
												<td>
													<b><span>Ending Short-Term Investments Balance</span></b>
												</td>
												<td>
													<b><span>
														{modulesServices.module9Data.shortTermSummaryEndingBalance !== undefined && modulesServices.module9Data.shortTermSummaryEndingBalance !== null && modulesServices.module9Data.shortTermSummaryEndingBalance !== '' && modulesServices.module9Data.shortTermSummaryEndingBalance !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryEndingBalance} prefix={modulesServices.module9Data.shortTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEndingBalance < 0 && ')'} />) : ('$0')}
													</span></b>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} className={classes.isummaryTableRight}>
								<div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTableInvestNew)}>
									<table>
										<thead>
											<tr className={classes.mainRow}>
												<th>Long-Term Investments Summary</th>
												<th>
													<Button onClick={() => { setOpenWorkSheet('long') }} className={classes.showWorkSheet}>SHOW TABLE</Button>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr className={classes.grayRow}>

												<td>
													<i>Investment Period</i>
												</td>
												<td>
													<i>
														{modulesServices.module9Data.longTermInvestmentsPeriod}
													</i>
												</td>
											</tr>
											<tr className={classes.grayRow}>

												<td>
													<span>Beginning Long-Term Investments Balance</span>
												</td>
												<td>
													{modulesServices.module9Data.longTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.longTermSummaryBeginningBalance !== null && modulesServices.module9Data.longTermSummaryBeginningBalance !== '' && modulesServices.module9Data.longTermSummaryBeginningBalance !== isNaN ? (
														<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryBeginningBalance} prefix={modulesServices.module9Data.longTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryBeginningBalance < 0 && ')'} />) : ('$0')}
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>

												<td>
													<span>Total Annual Contributions</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.longTermSummaryContributions !== undefined && modulesServices.module9Data.longTermSummaryContributions !== null && modulesServices.module9Data.longTermSummaryContributions !== '' && modulesServices.module9Data.longTermSummaryContributions !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryContributions} prefix={modulesServices.module9Data.longTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryContributions < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.bottomBlueBorder, classes.leftdataPadding)}>

												<td>
													<span>Total Earnings</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.longTermSummaryEarnings !== undefined && modulesServices.module9Data.longTermSummaryEarnings !== '' && modulesServices.module9Data.longTermSummaryEarnings !== null && modulesServices.module9Data.longTermSummaryEarnings !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryEarnings} prefix={modulesServices.module9Data.longTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEarnings < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classes.grayRow}>

												<td>
													<b><span>Long-Term Investments Balance before Transfers and Withdrawals</span></b>
												</td>
												<td>
													<b>
														<span>
															{modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== undefined && modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== null && modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== '' && modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== isNaN ? (
																<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers < 0 && ')'} />) : ('$0')}
														</span>
													</b>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>

												<td>
													<span>
														Excess Short-Term Investments Moved Into Long-Term Investments for
														{' '}
														{modulesServices.module9Data.longTermInvestmentsPeriodYears !== undefined && modulesServices.module9Data.longTermInvestmentsPeriodYears !== null && modulesServices.module9Data.longTermInvestmentsPeriodYears !== ''  && modulesServices.module9Data.longTermInvestmentsPeriodYears !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermInvestmentsPeriodYears} />) : ('$0')}
														{' '}
														Years
													</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.longTermSummaryExcessCash !== undefined && modulesServices.module9Data.longTermSummaryExcessCash !== null && modulesServices.module9Data.longTermSummaryExcessCash !== null && modulesServices.module9Data.longTermSummaryExcessCash !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryExcessCash} prefix={modulesServices.module9Data.longTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryExcessCash < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>
											<tr className={classNames(classes.grayRow, classes.leftdataPadding)}>

												<td>
													<span>
														{' '}
														Total Withdrawals for Cash Need for
														{' '}
														{modulesServices.module9Data.longTermInvestmentsPeriodYears !== undefined && modulesServices.module9Data.longTermInvestmentsPeriodYears !== null && modulesServices.module9Data.longTermInvestmentsPeriodYears !== '' && modulesServices.module9Data.longTermInvestmentsPeriodYears !== isNaN  ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermInvestmentsPeriodYears} />) : ('$0')}
														{' '}
														Years
													</span>
												</td>
												<td>
													<span>
														{modulesServices.module9Data.longTermSummaryCashWithDrawn !== undefined && modulesServices.module9Data.longTermSummaryCashWithDrawn !== null && modulesServices.module9Data.longTermSummaryCashWithDrawn !== '' && modulesServices.module9Data.longTermSummaryCashWithDrawn !== isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.longTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryCashWithDrawn < 0 && ')'} />) : ('$0')}
													</span>
												</td>
											</tr>

											<tr className={classNames(classes.blueRow, classes.bottomBlueBorder)}>
											
												<td>
													<b><span>Ending Long-Term Investments Balance</span></b>
												</td>
												<td>
													<b>
														<span>
															{(modulesServices.module9Data.longTermSummaryEndingBalance !== undefined && modulesServices.module9Data.longTermSummaryEndingBalance !== null && modulesServices.module9Data.longTermSummaryEndingBalance !== ''  && modulesServices.module9Data.longTermSummaryEndingBalance !== isNaN && modulesServices.module9Data.longTermSummaryEndingBalance < 0) || (modulesServices.module9Data.longTermSummaryEndingBalance >= 0) ? (
																<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryEndingBalance} prefix={modulesServices.module9Data.longTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEndingBalance < 0 && ')'} />) : ('$0')}
														</span>
													</b>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
						</Grid>
					</TabPanel>
					<TabPanel value={value} index={2} dir={theme.direction}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} className={classes.isummaryTableLeft}>
								<div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTablePlan)}>
									<table>
										<thead>
											<th colspan="2" className={classNames(classes.grayRow, classes.textCenter)}>
												529 Plan Summary
											</th>
										</thead>
										<tbody>
											<tr className={classes.grayRow}>
												<td>
													<i>Investment Period</i>
												</td>
												<td>
													{modulesServices.module9Data.longTermInvestmentsPeriod529}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td>Beginning 529 Plan Balance</td>
												<td>
													{" "}
													{modulesServices.module9Data.beginning529BalanceAnalysis !== undefined && modulesServices.module9Data.beginning529BalanceAnalysis !== null && modulesServices.module9Data.beginning529BalanceAnalysis !== '' && modulesServices.module9Data.beginning529BalanceAnalysis !== isNaN ?
														(<NumberFormat
															decimalScale={0}
															allowNegative={false}
															fixedDecimalScale
															displayType="text"
															thousandSeparator
															value={modulesServices.module9Data.beginning529BalanceAnalysis}
															prefix={modulesServices.module9Data.beginning529BalanceAnalysis >= 0 ? "$" : "($"}
															suffix={modulesServices.module9Data.beginning529BalanceAnalysis < 0 && ")"}
														/>)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td className={classes.leftPadding}>
													{" "}
													Total Annual Contributions
												</td>
												<td>
													{" "}
													{modulesServices.module9Data.totalContribution529 !== undefined && modulesServices.module9Data.totalContribution529 !== null && modulesServices.module9Data.totalContribution529 !== ''  && modulesServices.module9Data.totalContribution529 !== isNaN ?
														(
															<NumberFormat
																decimalScale={0}
																allowNegative={false}
																fixedDecimalScale
																displayType="text"
																thousandSeparator
																value={modulesServices.module9Data.totalContribution529}
																prefix={modulesServices.module9Data.totalContribution529 >= 0 ? "$" : "($"}
																suffix={modulesServices.module9Data.totalContribution529 < 0 && ")"
																}
															/>)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td className={classes.leftPadding}>
													Total Earnings
												</td>
												<td>
													{" "}
													{modulesServices.module9Data.totalEarnings529 !== undefined && modulesServices.module9Data.totalEarnings529 !== null && modulesServices.module9Data.totalEarnings529 !== '' && modulesServices.module9Data.totalEarnings529 !== isNaN  ?
														(
															<NumberFormat
																decimalScale={0}
																allowNegative={false}
																fixedDecimalScale
																displayType="text"
																thousandSeparator
																value={modulesServices.module9Data.totalEarnings529}
																prefix={modulesServices.module9Data.totalEarnings529 >= 0 ? "$" : "($"}
																suffix={modulesServices.module9Data.totalEarnings529 < 0 && ")"}
															/>
														)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classNames(classes.blueRow, classes.bottomBlueBorder)}>
												<td>
													<b>Ending 529 Plan Balance</b>
												</td>
												<td>
													<b>
														{(modulesServices.module9Data.endingBalance529 !== undefined && modulesServices.module9Data.endingBalance529 !== null && modulesServices.module9Data.endingBalance529 !== ''  && modulesServices.module9Data.endingBalance529 !== isNaN && modulesServices.module9Data.endingBalance529 < 0) || (modulesServices.module9Data.endingBalance529 >= 0) ?
															(
																<NumberFormat
																	decimalScale={0}
																	allowNegative={false}
																	fixedDecimalScale
																	displayType="text"
																	thousandSeparator
																	value={modulesServices.module9Data.endingBalance529}
																	prefix={modulesServices.module9Data.endingBalance529 >= 0 ? "$" : "($"}
																	suffix={modulesServices.module9Data.endingBalance529 < 0 && ")"}
																/>
															)
															:
															'$0'
														}
													</b>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} className={classes.isummaryTableRight}>
								<div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTablePlan)}>
									<table>
										<thead>
											<th colspan="2" className={classNames(classes.grayRow, classes.textCenter)}>
												Life Insurance Cash Value Summary
											</th>
										</thead>
										<tbody>
											<tr className={classes.grayRow}>
												<td>
													<i>Investment Period</i>
												</td>
												<td>
													{modulesServices.module9Data.longTermInvestmentsPeriodLife}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td>Beginning Life Insurance Cash Value</td>
												<td>
													{" "}
													{modulesServices.module9Data.beginningLifeInsBalanceAnalysis !== undefined && modulesServices.module9Data.beginningLifeInsBalanceAnalysis !== null && modulesServices.module9Data.beginningLifeInsBalanceAnalysis !== ''  && modulesServices.module9Data.beginningLifeInsBalanceAnalysis !== isNaN ?
														(
															<NumberFormat
																decimalScale={0}
																allowNegative={false}
																fixedDecimalScale
																displayType="text"
																thousandSeparator
																value={modulesServices.module9Data.beginningLifeInsBalanceAnalysis}
																prefix={modulesServices.module9Data.beginningLifeInsBalanceAnalysis >= 0 ? "$" : "($"}
																suffix={modulesServices.module9Data.beginningLifeInsBalanceAnalysis < 0 && ")"}
															/>
														)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td className={classes.leftPadding}>
													{" "}
													Total Annual Contributions
												</td>
												<td>
													{" "}
													{modulesServices.module9Data.totalContributionLifeIns !== undefined && modulesServices.module9Data.totalContributionLifeIns !== null && modulesServices.module9Data.totalContributionLifeIns !== ''  && modulesServices.module9Data.totalContributionLifeIns !== isNaN  ?
														(
															<NumberFormat
																decimalScale={0}
																allowNegative={false}
																fixedDecimalScale
																displayType="text"
																thousandSeparator
																value={modulesServices.module9Data.totalContributionLifeIns}
																prefix={modulesServices.module9Data.totalContributionLifeIns >= 0 ? "$" : "($"}
																suffix={modulesServices.module9Data.totalContributionLifeIns < 0 && ")"
																}
															/>
														)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classes.grayRow}>
												<td className={classes.leftPadding}>
													Total Earnings
												</td>
												<td>
													{console.log('index1',modulesServices.module9Data.totalEarningsLifeIns)}
													{" "}
													{(modulesServices.module9Data.totalEarningsLifeIns !== undefined && modulesServices.module9Data.totalEarningsLifeIns !== null && modulesServices.module9Data.totalEarningsLifeIns !== '' && modulesServices.module9Data.totalEarningsLifeIns !== isNaN && modulesServices.module9Data.totalEarningsLifeIns < 0) || (modulesServices.module9Data.totalEarningsLifeIns >= 0)  ?
														(
															<NumberFormat
																decimalScale={0}
																allowNegative={false}
																fixedDecimalScale
																displayType="text"
																thousandSeparator
																value={modulesServices.module9Data.totalEarningsLifeIns}
																prefix={modulesServices.module9Data.totalEarningsLifeIns >= 0 ? "$" : "($"}
																suffix={modulesServices.module9Data.totalEarningsLifeIns < 0 && ")"}
															/>
														)
														:
														'$0'
													}
												</td>
											</tr>
											<tr className={classNames(classes.blueRow, classes.bottomBlueBorder)}>
												<td>
													<b>Ending Life Insurance Cash Value</b>
												</td>
												<td>
													<b>
														{(modulesServices.module9Data.endingBalanceLifeIns !== undefined && modulesServices.module9Data.endingBalanceLifeIns !== null && modulesServices.module9Data.endingBalanceLifeIns !== '' && modulesServices.module9Data.endingBalanceLifeIns !== isNaN && modulesServices.module9Data.endingBalanceLifeIns < 0) || (modulesServices.module9Data.endingBalanceLifeIns >= 0)  ?
															(
																<NumberFormat
																	decimalScale={0}
																	allowNegative={false}
																	fixedDecimalScale
																	displayType="text"
																	thousandSeparator
																	value={modulesServices.module9Data.endingBalanceLifeIns}
																	prefix={modulesServices.module9Data.endingBalanceLifeIns >= 0 ? "$" : "($"}
																	suffix={modulesServices.module9Data.endingBalanceLifeIns < 0 && ")"}
																/>
															)
															:
															'$0'
														}
													</b>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
						</Grid>
					</TabPanel>
				</SwipeableViews>
			</div>
		</div>
	)
}

InvestmentBottomThree.propTypes = {
	classes: PropTypes.object.isRequired,
	getInvestmentObject: PropTypes.object.isRequired,
	setOpenWorkSheet: PropTypes.func.isRequired,
	activeSlide: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvestmentBottomThree)