import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';



export function reiShowMeGraphData() {
  let graphsData = {};
  var reiOutputBackendData = moduleServices.reiOutputBackendGraphData();
  var reiData = moduleServices.invPropertyRoiCompleteData();
  graphsData['advValues'] = 0;
  graphsData['total'] = 0;
  if (reiOutputBackendData !== undefined && reiOutputBackendData['Pie Chart1']  !== undefined && reiOutputBackendData['Pie Chart1']['Total'] !== undefined) {
    graphsData['advValues'] = reiOutputBackendData['Pie Chart1']['Total'];

  }
  if (reiData !== undefined && reiData['Closing Cost'] !== undefined && reiData['Closing Cost']['Total Initial Costs'] !== undefined) {
    graphsData['total'] = reiData['Closing Cost']['Total Initial Costs'];

  }
  //Bar Graph1

  if (reiOutputBackendData['Pie Chart1'] !== undefined) {
    graphsData['graph3'] = true;
    graphsData['graph3Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotShadow: false,
        type: 'pie',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
        // eslint-disable-next-line
        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px'
            }
          },
          showInLegend: false
        }
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
        data: [
          ['Down Payment', reiOutputBackendData['Pie Chart1']['Down Payment']],
          ['Closing Costs', reiOutputBackendData['Pie Chart1']['Closing Costs']],
          ['Fit Out Costs', reiOutputBackendData['Pie Chart1']['Fit Out Costs']]
        ].filter((d) => {
          return d[1] > 0
        })
      }]
    }
  } else {
    graphsData['graph3'] = false;
  }

  if (reiOutputBackendData['Bar Graph1'] !== undefined && reiOutputBackendData['Bar Graph1']['Years List'] !== undefined && reiOutputBackendData['Bar Graph1']['Years List'].length > 0) {
    let reiMonthlyPaymentDataValue = moduleServices.realEstateMonthlyPayment();
    reiMonthlyPaymentDataValue = reiMonthlyPaymentDataValue.toFixed(0);
    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        events: {
          load() {
            const chart = this;
            const text = chart.renderer.text(
              '<strong>Monthly Payment: ' + formatDollar(Math.round(reiMonthlyPaymentDataValue)) + '</strong>',
              chart.plotLeft + 30,
              chart.plotTop + 35
            ).attr({
              zIndex: 5
            }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: reiOutputBackendData['Bar Graph1']['Years List'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Property Value and Mortgage Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '{series.name} <b>${point.y:,.0f}</b>'
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Mortgage Balance',
        type: 'column',
        yAxis: 1,
        data: reiOutputBackendData['Bar Graph1']['Mortgage Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C00000'
      }, {
        name: 'Real Estate Value',
        type: 'column',
        yAxis: 1,
        data: reiOutputBackendData['Bar Graph1']['Home Value'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#0070C0'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        yAxis: 0,
        data: reiOutputBackendData['Bar Graph1']['Principal Payback'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#A5A5A5'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: reiOutputBackendData['Bar Graph1']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#FFC000'
      }]
    }
  } else {
    graphsData['graph2'] = false;
  }
  if (reiOutputBackendData['Bar Graph2'] !== undefined && reiOutputBackendData['Bar Graph2']['Years List'] !== undefined && reiOutputBackendData['Bar Graph2']['Years List'].length > 0) {

    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: reiOutputBackendData['Bar Graph2']['Years List'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        }

      }],
      tooltip: {
        formatter() {
          const chart = this;
          return '<b>' + chart.x + '</b><br/>' +
            chart.series.name + ': $' + chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + '<br/>' +
            'Total: $' + chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Rental Income',
        data: reiOutputBackendData['Bar Graph2']['Rental Income'],
        stack: 'male',
        color: '#5B9BD5'
      }, {
        name: 'Property Taxes',
        data: reiOutputBackendData['Bar Graph2']['Property Taxes'],
        stack: 'female',
        color: '#9E480E'
      }, {
        name: 'Utilities',
        data: reiOutputBackendData['Bar Graph2']['Utilities'],
        stack: 'female',
        color: '#255E91'
      }, {
        name: 'Property Maintenance',
        data: reiOutputBackendData['Bar Graph2']['Property Maintenance'],
        stack: 'female',
        color: '#70AD47'
      }, {
        name: 'Homeowners Insurance',
        data: reiOutputBackendData['Bar Graph2']['Homeowners Insurance'],
        stack: 'female',
        color: '#4472C4'
      }, {
        name: 'Management Fee',
        data: reiOutputBackendData['Bar Graph2']['Management Fee'],
        stack: 'female',
        color: '#FFC000'
      }, {
        name: 'Association Fees',
        data: reiOutputBackendData['Bar Graph2']['Association Fees'],
        stack: 'female',
        color: '#A5A5A5'
      }, {
        name: 'Mortgage Interest',
        data: reiOutputBackendData['Bar Graph2']['Mortgage Payment'],
        stack: 'female',
        color: '#ED7D31'
      }]
    }
  } else {
    graphsData['graph1'] = false;

  }


  if (reiData['Closing Cost'] !== undefined) {
    graphsData['graph4'] = true;

    graphsData['graph4Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotShadow: false,
        type: 'pie',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
      },
      plotOptions: {
        pie: {
          // showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // format: '${point.y:,.0f} , {point.percentage:.0f}%',
        // eslint-disable-next-line
        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px',
              width: '300px'
            }
          }
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 20
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
        data: [
          ['Appraisal Fee', reiData['Closing Cost']['Appraisal Fee']],
          ['Credit Report Fee', reiData['Closing Cost']['Credit Report Fee']],
          ['Title Closing/Escrow Fee', reiData['Closing Cost']['Title Closing/Escrow Fee']],
          ['Lenders Title Insurance', reiData['Closing Cost']['Lenders Title Insurance']],
          ['Flood Determination', reiData['Closing Cost']['Flood Determination']],
          ['Tax Service Fee', reiData['Closing Cost']['Tax Service Fee']],
          ['Owners Title Insurance', reiData['Closing Cost']['Owners Title Insurance']],
          ['Recording Mortgage', reiData['Closing Cost']['Recording Mortgage']],
          ['Homeowner`s Insurance (3 Months prepaid upfront)', reiData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)']],
          ['Transfer Taxes', reiData['Closing Cost']['Transfer Taxes']],
          ['Processing Fee', reiData['Closing Cost']['Processing Fee']],
          ['Underwriting Fee', reiData['Closing Cost']['Underwriting Fee']],
          ['Loan Points', reiData['Closing Cost']['Loan Acquisition Points']],
          ['Origination Fees', reiData['Closing Cost']['Origination Points']],
          ['Mortgage Interest(1month prepaid upfront)', reiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)']],
          ['Property Taxes(3 months prepaid upfront)', reiData['Closing Cost']['Property Taxes(3 months prepaid upfront)']],
          ['Wood Destroying Pest Inspection', reiData['Closing Cost']['Wood Destroying Pest Inspection']],
          ['Home Owners Association Transfer Fees', reiData['Closing Cost']['Home Owners Association Transfer Fees']],
          ['Other', reiData['Closing Cost']['Others']]
        ].filter((d) => {
          return d[1] > 0
        })
      }]
    }
  } else {
    graphsData['graph4'] = false;
  }

  return graphsData;
}


