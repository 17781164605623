import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';

import financeExplained from '../../../assets/img/challenges/finance-explained.png';

// import feedbackIcon from '../../../assets/img/awareness/feedback-icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pageloader from '../../../components/ui/pageloader';
import { getTotalTime } from '../exercises/pf101-service';

const SAVE_QUESTIONS = loader( '../../../graphql/schema/pf101/save-final-challenge.graphql' );
const GET_QUESTIONS = loader( '../../../graphql/schema/pf101/get-final-challenge.graphql' );

/* eslint react/prop-types: 0 */
const startTime = new Date();
const StudentFinalChallenge = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const correctAns = ['A', 'Make extra payments on the loan each month', 'Standard Deduction',
    'Mutual Fund', 'Exchange-Traded Fund (ETF)', '10 years', 'Your income is a factor in your credit score calculation',
    'Mortgage, Car Loan, Credit Card', 'Shorter term of the loan = higher monthly payment and lower total interest paid', '9 years', 'Principal Repayment', '401(k)', '', '', ''];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 14; i += 1 ) {
    const questionsObject = {
      qId: i + 1, answer: '', correctAns: correctAns[i], scroll: defaultReference, status: '',
    };
    questionMainAns.push( questionsObject );
  }
  questionMainAns[0].sub = { 1: { val: '', status: '', ans: 9988.00 }, 2: { val: '', status: '', ans: 15103.50 }, 3: { val: '', status: '', ans: 15.10 } };

  let quesionSection = useRef( null );
  let feedBackSection = useRef( null );

  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [myScoreContent, setMyscoreContent] = React.useState( false );
  const [avgScore, setAvgScore] = React.useState( 0 );
  const [loading, setLoading] = React.useState( false );
  const [challengeSubmitted, setChallengeSubmitted] = React.useState( false );

  useQuery( GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      const updateQuestions = [...questions];
      Object.keys( response.getPf101FinalChallenge ).forEach( ( question ) => {
        let questionVal = question;
        questionVal = questionVal.replace( 'question_', '' );
        questionVal = parseInt( questionVal, 10 );
        // eslint-disable-next-line
        if ( !isNaN( questionVal ) ) {
          updateQuestions[questionVal - 1].answer = response.getPf101FinalChallenge[question];
          if ( questionVal === 1 ) {
            updateQuestions[0].sub = JSON.parse( response.getPf101FinalChallenge.question_1_data );
          }
          if ( questionVal <= 12 ) {
            if ( updateQuestions[questionVal - 1].answer === updateQuestions[questionVal - 1].correctAns ) {
              updateQuestions[questionVal - 1].status = 'C';
            } else {
              updateQuestions[questionVal - 1].status = 'W';
            }
          } else {
            updateQuestions[questionVal - 1].status = 'C';
            updateQuestions[questionVal - 1].correctAns = response.getPf101FinalChallenge[question];
          }
        }
      } );
      setQuestions( updateQuestions );
      setChallengeSubmitted( true );
      setLoading( false );
    },
    onError( ) {
      setLoading( false );
    },
  } );

  const [saveChallenge] = useMutation( SAVE_QUESTIONS, {
    onCompleted( ) {
      setLoading( false );
      setChallengeSubmitted( true );
    },
    onError( ) {
      return false;
    },
  } );

  useEffect( () => {
    const answerQuestions = _.reject( questions, ['answer', ''] ).length;
    let progressBar = answerQuestions * ( 100 / 15 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
  }, [questions] );

  useEffect( () => {
    if ( myScoreContent ) {
      scrollToComponent( feedBackSection, { offset: -0, align: 'top', duration: 500 } );
    }
  }, [myScoreContent] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    setQuestions( newQuesions );
  };

  const selectRating = ( rating, index ) => {
    if ( challengeSubmitted === false ) {
      const newQuesions = [...questions];
      newQuesions[index].answer = rating;
      newQuesions[index].correctAns = rating;
      newQuesions[index].status = 'C';
      setQuestions( newQuesions );
    }
  };

  const handleInputChange = ( e, number ) => {
    const newQuesions = [...questions];
    newQuesions[0].sub[number].val = e.floatValue !== undefined ? e.floatValue : 0;
    if ( parseFloat( newQuesions[0].sub[number].val ) === parseFloat( newQuesions[0].sub[number].ans ) ) {
      newQuesions[0].sub[number].status = 'C';
    } else {
      newQuesions[0].sub[number].status = 'W';
    }

    if ( newQuesions[0].sub[1].status !== '' && newQuesions[0].sub[2].status !== '' && newQuesions[0].sub[3].status !== '' ) {
      if ( newQuesions[0].sub[1].status === 'C' && newQuesions[0].sub[2].status === 'C' && newQuesions[0].sub[3].status === 'C' ) {
        newQuesions[0].answer = 'A';
        newQuesions[0].status = 'C';
      } else {
        newQuesions[0].answer = 'B';
        newQuesions[0].status = 'W';
      }
    }
    setQuestions( newQuesions );
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.filter( questions, ['answer', ''] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'qId' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      setMyscoreContent( true );
      const totalCorrectAns = _.filter( questions, ['status', 'C'] ).length;
      setAvgScore( totalCorrectAns * ( 100 / 12 ) );
      if ( myScoreContent ) {
        scrollToComponent( feedBackSection, { offset: -0, align: 'top', duration: 500 } );
      }
      if ( challengeSubmitted === false ) {
        setLoading( true );
        const saveObject = {};
        for ( let i = 0; i < 15; i += 1 ) {
          saveObject[`question_${i + 1}`] = questions[i].answer;
          if ( i === 0 ) {
            saveObject[`question_${i + 1}_data`] = JSON.stringify( questions[i].sub );
          }
          if ( i < 12 ) {
            saveObject[`question_${i + 1}_status`] = questions[i].status;
          }
        }
        saveObject.total_time = getTotalTime( startTime );

        saveChallenge( {
          variables: {
            data: saveObject,
          },
        } );
      }
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport2 = ( el ) => {
    let element = el;
    if ( element !== null && element.offsetTop !== undefined ) {
      let top = element.offsetTop;
      let left = element.offsetLeft;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      while ( element.offsetParent ) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top < ( window.pageYOffset + window.innerHeight )
        && left < ( window.pageXOffset + window.innerWidth )
        && ( top + height ) > window.pageYOffset
        && ( left + width ) > window.pageXOffset
      );
    }
    return false;
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      let feedBackSectionCondition = true;
      if ( feedBackSection.current === undefined ) {
        if ( feedBackSection.getBoundingClientRect().top < ( window.innerHeight / 2 ) ) {
          feedBackSectionCondition = false;
        }
      }

      if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 && feedBackSectionCondition === true ) {
        document.getElementById( 'awareness_title' ).style.position = 'fixed';
        document.getElementById( 'awareness_title_sub' ).style.display = 'block';
      } else {
        document.getElementById( 'awareness_title' ).style.position = '';
        document.getElementById( 'awareness_title_sub' ).style.display = 'none';
      }

      if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 && feedBackSectionCondition === true ) {
        document.getElementById( 'sticky_numbers' ).style.left = '30px';
      } else {
        document.getElementById( 'sticky_numbers' ).style.left = '-11em';
      }

      for ( let i = 0; i < 15; i += 1 ) {
        const element = $( questionMainAns[i].scroll );
        if ( ( elementInViewport( element ) === true || elementInViewport( element.find( 'ul' ) ) || elementInViewport( element.find( 'td' ) ) || elementInViewport( element.find( 'table' ) ) || elementInViewport( element.find( 'p' ) ) ) ) {
          element.css( 'opacity', '1' );
          element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
          $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
          if ( i <= 6 ) {
            $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
          } else {
            $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
          }
          break;
        }
      }

      if ( ( myScoreContent === true && elementInViewport2( feedBackSection ) ) || elementInViewport2( document.getElementById( 'footer' ) )
        || ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
        document.getElementById( 'progress-bar' ).style.display = 'none';
      } else {
        document.getElementById( 'progress-bar' ).style.display = 'block';
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.awarenessBody }>
        <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
          <div className={ classes.ChallengesQuizHead } id="awareness_title">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">Wrapup + Review: Final Challenge</Typography>
            </div>
          </div>

          <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">&nbsp;</Typography>
            </div>
          </div>

          <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
            <ul>
              {
                  [1, 2, 3, 4, 5, 6, 7].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <ul>
              {
                  [8, 9, 10, 11, 12, 13, 14].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <div className={ classes.stickyBottom }>
              <li>
                <Button onClick={ () => handleQuestionScroll( questionMainAns[14].scroll ) } className={ classNames( classes.listNum, ( questions[14].answer !== '' && questions[14].answer === questions[14].correctAns ) ? classes.listNumRight : ( ( questions[14].answer !== '' ) && classes.listNumWrong ) ) }>
                  <span>
                    15
                    <i className="las la-check" />
                  </span>
                </Button>
              </li>
            </div>
            <div className={ classes.stickyBottom }>
              <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                <span>
                  <i
                    className="las la-arrow-up"
                    data-for="returnStart"
                    data-tip="Return to start"
                  />
                </span>
                <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
              </Button>
            </div>
            {
              /* progress === 100 && (
                <div className={ classes.feedbackImg }>
                  <Button onClick={ handleMyScore } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <figure>
                        <img
                          src={ feedbackIcon }
                          alt="Feedback"
                          data-for="returnFeedback"
                          data-tip="Go to Feedback"
                        />
                        <ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={ `${classes.tooltipInfo} ${classes.feedbackTooltip}` } />
                      </figure>
                    </span>
                  </Button>
                </div>
              ) */
            }
          </div>

          <div className={ classes.questionRow }>
            <div className={ classes.container }>
              <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>1</span>
                  <p className={ classes.questionTitle }>Complete the table below to calculate Mike’s federal income taxes and effective federal income tax rate for 2020. Round to two decimals for the Tax Rate percentage.</p>
                  <div className={ classes.tableResponsive }>
                    <table cellPadding="0" cellSpacing="0">
                      <thead>
                        <tr className={ classes.popupHeading }>
                          <th>&nbsp;</th>
                          <th>Mike&apos;s Federal Income Taxes</th>
                          <th>Year 2020</th>
                        </tr>
                        <tr className={ `${classes.popupRight} ${classes.popupRightTop}` }>
                          <th>&nbsp;</th>
                          <th>Mike&apos;s Total Income</th>
                          <th>$100,000.00</th>
                        </tr>
                        <tr className={ classes.popupRight }>
                          <th>&nbsp;</th>
                          <th>
                            <div className={ classes.whiteBg } />
                            Standard Deduction (Single)
                          </th>
                          <th><div className={ classes.textDanger }>($12,4000.00)</div></th>
                        </tr>
                        <tr className={ classes.popupRow }>
                          <th>&nbsp;</th>
                          <th>Mike&apos;s Federal Taxable Income</th>
                          <th>$87,600.00</th>
                        </tr>
                      </thead>
                    </table>
                    <table className={ classes.tbodyBorder }>
                      <thead>
                        <th>Rate</th>
                        <th>Income Ranges (Single)</th>
                        <th>Taxable Income</th>
                        <th>Taxes</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>10.00%</td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$0.00</span>
                              <span>$9,875.00</span>
                            </div>
                          </td>
                          <td>$9,875.00</td>
                          <td>$987.50</td>
                        </tr>
                        <tr>
                          <td>12.00%</td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$9,875.00</span>
                              <span>$40,125.00</span>
                            </div>
                          </td>
                          <td>$30,250.00</td>
                          <td>$3,630.00</td>
                        </tr>
                        <tr>
                          <td>22.00%</td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$40,125.00</span>
                              <span>$85,525.00</span>
                            </div>
                          </td>
                          <td>$45,400.00</td>
                          <td>
                            <div className={ classes.flexClassInput }>
                              <span>$</span>
                              <NumberFormat disabled={ challengeSubmitted === true } customInput={ TextField } thousandSeparator value={ questions[0].sub[1].val } onValueChange={ ( e ) => handleInputChange( e, 1 ) } onFocus={ ( e ) => e.target.select() } />
                              {questions[0].sub[1].val > 0 && questions[0].sub[1].status !== 'C' && (
                              <span className={ classes.colorRed }><i className="la la-times" /></span>
                              )}
                              {questions[0].sub[1].val > 0 && questions[0].sub[1].status === 'C' && (
                              <span className={ classes.colorGreen }><i className="la la-check" /></span>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>24.00%</td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$85,525.00</span>
                              <span>$163,300.00</span>
                            </div>
                          </td>
                          <td>$2,075.00</td>
                          <td>$498.00</td>
                        </tr>
                        <tr>
                          <td>32.00%  </td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$163,300.00</span>
                              <span>$207,350.00</span>
                            </div>
                          </td>
                          <td>$0.00</td>
                          <td>$0.00</td>
                        </tr>
                        <tr>
                          <td>35.00%  </td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$207,350.00</span>
                              <span>$518,400.00  </span>
                            </div>
                          </td>
                          <td>$0.00</td>
                          <td>$0.00 </td>
                        </tr>
                        <tr>
                          <td>37.00%  </td>
                          <td>
                            <div className={ classes.flexClass }>
                              <span>$518,400.00</span>
                              <span>&nbsp;</span>
                            </div>
                          </td>
                          <td>$0.00</td>
                          <td>$0.00</td>
                        </tr>

                      </tbody>
                      <tfoot>
                        <tr>
                          <td>&nbsp;</td>
                          <td>
                                &nbsp;
                          </td>
                          <td className={ classes.fontBold }>$87,600.00</td>
                          <td>
                            <div className={ classes.flexClassInput }>
                              <span>$</span>
                              <NumberFormat disabled={ challengeSubmitted === true } customInput={ TextField } thousandSeparator value={ questions[0].sub[2].val } onValueChange={ ( e ) => handleInputChange( e, 2 ) } onFocus={ ( e ) => e.target.select() } />
                              {questions[0].sub[2].val > 0 && questions[0].sub[2].status !== 'C' && (
                              <span className={ classes.colorRed }><i className="la la-times" /></span>
                              )}
                              {questions[0].sub[2].val > 0 && questions[0].sub[2].status === 'C' && (
                              <span className={ classes.colorGreen }><i className="la la-check" /></span>
                              )}
                            </div>

                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3" className={ classes.fontBold }>Federal Income Tax Rate (Taxes / Total Income)</td>
                          <td>
                            <div className={ classes.flexClassInput }>
                              <NumberFormat disabled={ challengeSubmitted === true } customInput={ TextField } thousandSeparator value={ questions[0].sub[3].val } onValueChange={ ( e ) => handleInputChange( e, 3 ) } onFocus={ ( e ) => e.target.select() } />
                              <span className={ classes.percentage }>%</span>
                              {questions[0].sub[3].val > 0 && questions[0].sub[3].status !== 'C' && (
                              <span className={ classes.colorRed }><i className="la la-times" /></span>
                              )}
                              {questions[0].sub[3].val > 0 && questions[0].sub[3].status === 'C' && (
                              <span className={ classes.colorGreen }><i className="la la-check" /></span>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tfoot>

                    </table>
                  </div>

                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>2</span>
                  <p className={ classes.questionTitle }>There are two common ways to lower the cost of a loan. One is to refinance and receive a new loan with a lower interest rate. What is the other way to lower the cost of a loan?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[1].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="2_1">
                          Make extra payments on the loan each month
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Make extra payments on the loan each month" id="2_1" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Refinance and receive a new loan with a longer repayment term to lower the monthly payment' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_2">
                          Refinance and receive a new loan with a longer repayment term to lower the monthly payment
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Refinance and receive a new loan with a longer repayment term to lower the monthly payment" id="2_2" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Refinance using a personal loan since personal loans have very low interest rates' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_3">
                          Refinance using a personal loan since personal loans have very low interest rates
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Refinance using a personal loan since personal loans have very low interest rates" id="2_3" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Refinancing is the only way to lower the cost of a loan' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_4">
                          Refinancing is the only way to lower the cost of a loan
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Refinancing is the only way to lower the cost of a loan" id="2_4" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>3</span>
                  <p className={ classes.questionTitle }>Given to all taxpayers, __________ is a portion of your income that is not subject to federal income taxes.</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[2].answer === 'Adjustments to Income' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_1">
                          Adjustments to Income
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Adjustments to Income" id="3_1" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="3_2">
                          Standard Deduction
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Standard Deduction" id="3_2" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer === 'Itemized Deductions' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_3">
                          Itemized Deductions
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Itemized Deductions" id="3_3" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer === 'Interest Tax Deduction' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_4">
                          Interest Tax Deduction
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Interest Tax Deduction" id="3_4" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>4</span>
                  <p className={ classes.questionTitle }>Given to all taxpayers, __________ is a portion of your income that is not subject to federal income taxes.</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[3].answer === 'Brokerage Account' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_1">
                          Brokerage Account
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="Brokerage Account" id="4_1" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer === '401(k) Retirement Account' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_2">
                          401(k) Retirement Account
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="401(k) Retirement Account" id="4_2" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="4_3">
                          Mutual Fund
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="Mutual Fund" id="4_3" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer === 'Exchange-Traded Fund (ETF)' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_4">
                          Exchange-Traded Fund (ETF)
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="Exchange-Traded Fund (ETF)" id="4_4" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>5</span>
                  <p className={ classes.questionTitle }>Mike thinks that technology companies are great investments. He wants to invest in a basket of technology companies rather than picking individual stocks on his own. He wants an investment with low fees that can be traded daily because liquidity is important to him. What investment would you suggest to Mike?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[4].answer === 'Brokerage Account' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_1">
                          Brokerage Account
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="Brokerage Account" id="4_1" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer === '401(k) Retirement Account' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_2">
                          401(k) Retirement Account
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="401(k) Retirement Account" id="4_2" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer === 'Mutual Fund' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_3">
                          Mutual Fund
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="Mutual Fund" id="4_3" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="4_4">
                          Exchange-Traded Fund (ETF)
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="Exchange-Traded Fund (ETF)" id="4_4" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>6</span>
                  <p className={ classes.questionTitle }>What is the standard repayment time for a student loan?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[5].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="5_1">
                          10 years
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="10 years" id="5_1" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer === '15 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_2">
                          15 years
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="15 years" id="5_2" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer === '20 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_3">
                          20 years
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="20 years" id="5_3" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer === '30 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_4">
                          30 years
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="30 years" id="5_4" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>7</span>
                  <p className={ classes.questionTitle }>Which of the following is a myth about credit scores?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[6].answer === 'Checking your credit score does not hurt your score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_1">
                          Checking your credit score does not hurt your score
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Checking your credit score does not hurt your score" id="7_1" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="7_2">
                          Your income is a factor in your credit score calculation
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Your income is a factor in your credit score calculation" id="7_2" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer === 'There are many ways to calculate a credit score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_3">
                          There are many ways to calculate a credit score
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="There are many ways to calculate a credit score" id="7_3" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer === 'Different debts are weighed differently when calculating your score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_4">
                          Different debts are weighed differently when calculating your score
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Different debts are weighed differently when calculating your score" id="7_4" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>8</span>
                  <p className={ classes.questionTitle }>Which of the following represents the correct order going from the lowest interest rate to the highest interest rate?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[7].answer === 'Mortgage, Credit Card, Car Loan' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_1">
                          Mortgage, Credit Card, Car Loan
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="Mortgage, Credit Card, Car Loan" id="8_1" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer === 'Credit Card, Car Loan, Mortgage' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_2">
                          Credit Card, Car Loan, Mortgage
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="Credit Card, Car Loan, Mortgage" id="8_2" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="8_3">
                          Mortgage, Car Loan, Credit Card
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="Mortgage, Car Loan, Credit Card" id="8_3" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer === 'Credit Card, Mortgage, Car Loan' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_4">
                          Credit Card, Mortgage, Car Loan
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="Credit Card, Mortgage, Car Loan" id="8_4" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>9</span>
                  <p className={ classes.questionTitle }>Which of the following best describes the relationship between term of the loan, monthly payment, and total interest paid?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[8].answer === 'Longer term of the loan = higher monthly payment and higher total interest paid' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_1">
                          Longer term of the loan = higher monthly payment and higher total interest paid
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Longer term of the loan = higher monthly payment and higher total interest paid" id="9_1" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer === 'Longer term of the loan = lower monthly payment and lower total interest paid' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_2">
                          Longer term of the loan = lower monthly payment and lower total interest paid
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Longer term of the loan = lower monthly payment and lower total interest paid" id="9_2" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer === 'Shorter term of the loan = lower monthly payment and higher total interest paid' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_3">
                          Shorter term of the loan = lower monthly payment and higher total interest paid
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Shorter term of the loan = lower monthly payment and higher total interest paid" id="9_3" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="9_4">
                          Shorter term of the loan = higher monthly payment and lower total interest paid
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Shorter term of the loan = higher monthly payment and lower total interest paid" id="9_4" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>10</span>
                  <p className={ classes.questionTitle }>Use the Rule of 72 to estimate how long would it take a $10,000 investment to grow to $20,000 if that investment earns 8% per year?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[9].answer === '6 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_1">
                          6 years
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="6 years" id="10_1" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer === '7 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_2">
                          7 years
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="7 years" id="10_2" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer === '8 years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_3">
                          8 years
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="8 years" id="10_3" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="10_4">
                          9 years
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="9 years" id="10_4" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[10].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>11</span>
                  <p className={ classes.questionTitle }>Use the Rule of 72 to estimate how long would it take a $10,000 investment to grow to $20,000 if that investment earns 8% per year?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[10].answer === 'Balance' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_1">
                          Balance
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="Balance" id="11_1" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer === 'Capital' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_2">
                          Capital
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="Capital" id="11_2" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer === 'Principal Repayment' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_3">
                          Principal Repayment
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="Principal Repayment" id="11_3" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="11_4">
                          Amortization
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="Amortization" id="11_4" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[11].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>12</span>
                  <p className={ classes.questionTitle }>Use the Rule of 72 to estimate how long would it take a $10,000 investment to grow to $20,000 if that investment earns 8% per year?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[11].answer === 'Balance' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="12_1">
                          Balance
                          <input type="radio" disabled={ questions[11].answer !== '' } name="equation" value="Balance" id="12_1" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[11].answer === 'Capital' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="12_2">
                          Capital
                          <input type="radio" disabled={ questions[11].answer !== '' } name="equation" value="Capital" id="12_2" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[11].answer === 'Principal Repayment' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="12_3">
                          Principal Repayment
                          <input type="radio" disabled={ questions[11].answer !== '' } name="equation" value="Principal Repayment" id="12_3" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[11].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="12_4">
                          Amortization
                          <input type="radio" disabled={ questions[11].answer !== '' } name="equation" value="Amortization" id="12_4" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[12].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>13</span>
                  <p className={ classes.questionTitle }>
                    Additional personal financial education such as going into more detail on Personal Finance 101 topics or building my own financial life plan would benefit me.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[12].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 12 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[13].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>14</span>
                  <p className={ classes.questionTitle }>
                    I feel more confident in my financial life skills and knowledge after completing Personal Finance 101.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[13].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 13 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[14].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>15</span>
                  <p className={ classes.questionTitle }>
                    Future students would benefit from taking a personal finance course.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` } role="menu">
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[14].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 14 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>

              <div className={ classes.progressBar } id="progress-bar">
                <p>
                  {progress}
                  % complete
                </p>
                <div>
                  <span style={ { width: `${progress}%` } } />
                </div>
              </div>
              <div className={ classes.whatsMyScore } id="my-score-button">
                <Button onClick={ handleMyScore }>What&apos;s My Score?</Button>
              </div>
            </div>
          </div>
        </div>
        {myScoreContent === true && (
          <div ref={ ( section ) => { feedBackSection = section; } }>
            <div className={ classes.awernessScoreSec }>
              <Typography className={ classes.awernessTitle } variant="h2" component="h2">WHAT&apos;S MY SCORE?</Typography>
              <div className={ classes.container }>
                <div className={ classes.awernessBlock }>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } sm={ 4 }>
                      <div className={ classes.awernessBlockLeft }>
                        <Typography variant="h3" component="h3">YOUR SCORE:</Typography>
                        <span>
                          {avgScore.toFixed( 1 )}
                          %
                          <span>Correct</span>
                        </span>
                        <img src={ financeExplained } alt="Graph" />
                      </div>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 8 }>
                      <div className={ classes.awernessBlockRight }>
                        <div className={ classes.moneyfreeRow }>
                          <Typography variant="h3" component="h3">Be the CEO of Your Own Life</Typography>
                          <p>Personal finance skills and knowledge are used every day when making big and small decisions. You now have a financial life toolkit packed with skills and knowledge that you can put to work in your own life. From budgeting, saving, and investing to understanding insurance and repaying loans, Personal Finance 101 has equipped you to be the CEO of your own life.</p>
                          <p>You&apos;re ready to navigate your future with confidence.</p>
                          <Link href="/pf101/level10/financial-life-toolkit?revisit=download">
                            CONTINUE
                          </Link>
                        </div>

                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

StudentFinalChallenge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( StudentFinalChallenge );
