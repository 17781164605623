const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          color: '#f89b23',
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  csBox: {
    background: '#fff',
    padding: '35px 30px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  blueBorder: {
    border: '2px solid #0069aa',
  },
  mortgageRow: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    }, 
  },
  leftPanel: {
    background: '#e7f1f9!important',
    borderRight: '2px solid #0069aa',
    paddingTop: '18px',
    verticalAlign: 'top',
    display: 'block',
    width: '25%',
    padding: '0px', 
    boxSizing: 'border-box',   
    '@media (max-width: 767px)': {
      width: '100%',
      borderRight: 'none',
    }, 
    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '40%',
    }, 
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      backgroundColor: 'transparent',     
      '& tbody': {
        '& tr': {
          [theme.breakpoints.down( 'xs' )]: {
            width: '100%'
          },
          '& td': {
            paddingLeft: '10px',
            fontSize: '17px',
            paddingRight: '10px',
            color: theme.palette.common.pureBlack,
            boxSizing: 'border-box',           
            '& div': {
              '&::before': {
                border: 'none !important',
              },
              '&::after': {
                border: 'none !important',
              },
            },
          },
        },
      },
    },
  },
  dollarField: {
    background: '#fff',
    position: 'relative',
    height: '45px',
    margin: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    paddingLeft: '2px !important',
    boxSizing: 'border-box',
    '& span': {
      position: 'absolute',
      left: '9px',
      lineHeight: 'initial',
    },
  },
  formInput: {
    width: '100%',
    boxSizing: 'border-box',
    '& input': {
      padding: '0 5px',
      width: '100%',
      border: 'none',
      fontFamily: 'MuseoSans-300',
      fontSize: '17px',
      lineHeight: '1.42857143',
      margin: '0',
      font: 'inherit',
      color: '#000',
      boxSizing: 'border-box',
      paddingLeft: '17px',
    },
  },
  heightDiv: {
    height: '18px',
  },
  currencySym: {
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    marginTop: '0px',
    left: 'auto !important',
    right: '15px',
    textAlign: 'right',
    color: '#333',
    fontSize: '16px',
  },
  noField: {
    background: 'transparent',
    paddingLeft: '10px !important',
    fontFamily: 'MuseoSans-500',
    fontweight: '500',
  },
  blueDiv: {
    height: '2px',
    background: '#2f75b5',
  },
  rightPanel: {
    padding: '0px 15px',
    width: '75%',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      width: '100%',
    }, 
    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '60%',
    },  
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontSize: '22px',
      lineHeight: '1.1',
      marginTop: '20px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '600',
      textAlign: 'center',
    },
  },
  hrLine: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
  },
  graphImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  percentField: {
    '& input': {
      paddingLeft: '5px !important',
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
    minHeight: '90vh',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down( 'sm' )]: {
      whiteSpace: 'pre-wrap',
    },
  },
} );

export default styles;
