import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import YouTube from 'react-youtube';
import scrollToComponent from 'react-scroll-to-component';
import styles from './styles';
import CareerImageOne from '../../../assets/img/home_page/mike.jpg';
import CareerImageTwo from '../../../assets/img/home_page/erica.jpg';
import CareerImageThree from '../../../assets/img/home_page/santiago.jpg';
import CareerImageFour from '../../../assets/img/home_page/monica.jpg';
import CareerImageFive from '../../../assets/img/home_page/jeremy.jpg';
import CareerImageSix from '../../../assets/img/home_page/jennifer.jpg';
import CareerImageSeven from '../../../assets/img/home_page/sam.jpg';
import CareerImageEight from '../../../assets/img/home_page/katelyn.jpg';
import CareerImageNine from '../../../assets/img/home_page/aron.jpg';
import CareerImageTen from '../../../assets/img/home_page/executive_chef1.jpg';
import CareerImageEleven from '../../../assets/img/home_page/executive_chef2.jpg';
import CareerImageTweleve from '../../../assets/img/home_page/executive_chef3.jpg';
import CareerImageThirteen from '../../../assets/img/home_page/new_corporate_attr.jpg';
import CareerImageFourteen from '../../../assets/img/home_page/brooke.jpg';
import CareerImageFifteen from '../../../assets/img/home_page/calvin.jpg';
import CareerImageSixteen from '../../../assets/img/home_page/car_salesman.jpg';
import CareerImageSeventeen from '../../../assets/img/home_page/executive_chef4.jpg';
import CareerImageEighteen from '../../../assets/img/home_page/executive_chef5.jpg';
import CareerImageNineteen from '../../../assets/img/home_page/executive_chef6.jpg';
import CareerImageTwenty from '../../../assets/img/home_page/taylor.jpg';
import CareerImageTwentyone from '../../../assets/img/home_page/michelle.jpg';
import CareerImageTwentyTwo from '../../../assets/img/home_page/david.jpg';
import CareerImageTwentyThree from '../../../assets/img/home_page/laura.jpg';
import CareerImageTwentyFour from '../../../assets/img/home_page/frank.jpg';
import CareerImageTwentyFive from '../../../assets/img/home_page/katrina.jpg';
import CareerImageTwentySix from '../../../assets/img/home_page/jay.jpg';
import CareerImageTwentySeven from '../../../assets/img/home_page/amber.jpg';
import CareerImageTwentyEight from '../../../assets/img/home_page/tim.jpg';
import one from '../../../assets/img/career-sketch/1.png';
import Two from '../../../assets/img/career-sketch/2.png';
import Three from '../../../assets/img/career-sketch/3.png';
import Four from '../../../assets/img/career-sketch/4.png';
import Five from '../../../assets/img/career-sketch/5.png';
import infoIcon from '../../../assets/img/info-icon.svg';
import MikeCard from './mike-card';
import EricaCard from './erica-card';
import SantiagoCard from './santiago-card';
import MonicaCard from './monica-card';
import JeremyCard from './jeremy-card';
import JenniferCard from './jennifer-card';
import SamCard from './sam-card';
import KatelynCard from './katelyn-card';
import AaronCard from './aaron-card';
import AnitaCard from './anita-card';
import BrookeCard from './brooke-card';
import CalvinCard from './calvin-card';
import RobertCard from './robert-card';
import TaylorCard from './taylor-card';
import MichelleCard from './michelle-card';
import DavidCard from './david-card';
import LauraCard from './laura-card';
import FrankCard from './frank-card';
import KatrinaCard from './katrina-card';
import JayCard from './jay-card';
import AmberCard from './amber-card';
import TimCard from './tim-card';
import * as NetworthServices from '../../../calculations/net-worth-service';

const CareerSketchCards = ( props ) => {
  const { classes, history, fromPage } = props;

  const [openCard, setOpenCard] = React.useState( '' );
  const [openCardType, setOpenCardType] = React.useState( '' );
  const [openAllCard, setOpenAllCard] = React.useState( false );
  const [seeItAction, setSeeItAction] = useState( false );
  let cardSection = useRef( null );

  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      autoplay: 1,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  const linkToPricing = () => {
    history.push( '/pricing' );
  };

  const openCardModel = ( card ) => {
    setOpenCardType( card );
    setOpenCard( card );
    NetworthServices.loadGraphs( card );
    // console.log("NetworthServices()",NetworthServices.loadGraphs(card));
  };

  const handleSeeItAction = () => {
    setSeeItAction( true );
    scrollToComponent( cardSection, { offset: -56, align: 'top', duration: 500 } );
  };

  const handleSeeItActionClose = () => {
    setSeeItAction( false );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.careerSketch } ref={ ( section ) => { cardSection = section; } }>
        <div className={ `${seeItAction && `${classes.hideCards}`}` }>
          <ul>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'mike' ) }>
              <figure>
                <img src={ CareerImageOne } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (../assets) minus what you owe (liabilities). Because Mike gets married, this figure includes his partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'erica' ) }>
              <figure>
                <img src={ CareerImageTwo } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Erica
                    <span>Neuroradiologist</span>
                  </h3>
                  <span>$67,574</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'santiago' ) }>
              <figure>
                <img src={ CareerImageThree } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Santiago
                    <span>Wall Street Banker</span>
                  </h3>
                  <span>$921,963</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Santiago gets married, this figure includes his partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'monica' ) }>
              <figure>
                <img src={ CareerImageFour } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Monica
                    {' '}
                    <span>Executive Chef</span>
                  </h3>
                  <span>$453,644</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Monica gets married, this figure includes her partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'jeremy' ) }>
              <figure>
                <img src={ CareerImageFive } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Jeremy
                    <span>Real Estate Broker</span>
                  </h3>
                  <span>$442,791</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Jeremy gets married, this figure includes his partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'jennifer' ) }>
              <figure>
                <img src={ CareerImageSix } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Jennifer
                    <span>Master Plumber</span>
                  </h3>
                  <span>$398,951</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'sam' ) }>
              <figure>
                <img src={ CareerImageSeven } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Sam
                    <span>Software Architect</span>
                  </h3>
                  <span>$1,344,293</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNewRight"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Sam gets married, this figure includes his partner's income/expenses."
                    />
                    <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'katelyn' ) }>
              <figure>
                <img src={ CareerImageEight } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Katelyn
                    <span>Fashion Designer</span>
                  </h3>
                  <span>$393,580</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Katelyn gets married, this figure includes her partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'aaron' ) }>
              <figure>
                <img src={ CareerImageNine } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Aaron
                    <span>Math Teacher</span>
                  </h3>
                  <span>$309,195</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Aaron gets married, this figure includes his partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <figure>
                <img src={ CareerImageTen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>

            <li>
              <figure>
                <img src={ CareerImageEleven } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>
            <li>
              <figure>
                <img src={ CareerImageTweleve } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'anita' ) }>
              <figure>
                <img src={ CareerImageThirteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Anita
                    <span>Corporate Attorney</span>
                  </h3>
                  <span>$1,306,826</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Anita gets married, this figure includes her partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'brooke' ) }>
              <figure>
                <img src={ CareerImageFourteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Brooke
                    <span>Army Engineer</span>
                  </h3>
                  <span>$433,549</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNewRight"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Brooke gets married, this figure includes her partner's income/expenses."
                    />
                    <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'calvin' ) }>
              <figure>
                <img src={ CareerImageFifteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Calvin
                    <span>Master Electrician</span>
                  </h3>
                  <span>$710,218</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'robert' ) }>
              <figure>
                <img src={ CareerImageSixteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Robert
                    <span>Car Salesman</span>
                  </h3>
                  <span>$635,997</span>
                </div>
              </div>
            </li>
            <li>
              <figure>
                <img src={ CareerImageSeventeen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>

            <li>
              <figure>
                <img src={ CareerImageEighteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>
            <li>
              <figure>
                <img src={ CareerImageNineteen } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Mike
                    <span>Registered Architect</span>
                  </h3>
                  <span>$315,148</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'taylor' ) }>
              <figure>
                <img src={ CareerImageTwenty } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Taylor
                    <span>Dairy Farmer</span>
                  </h3>
                  <span>$664,239</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Taylor gets married, this figure includes her partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'michelle' ) }>
              <figure>
                <img src={ CareerImageTwentyone } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Michelle
                    <span>Financial Advisor</span>
                  </h3>
                  <span>$407,371</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'david' ) }>
              <figure>
                <img src={ CareerImageTwentyTwo } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    David
                    <span>Navy Physician</span>
                  </h3>
                  <span>$247,410</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'laura' ) }>
              <figure>
                <img src={ CareerImageTwentyThree } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Laura
                    <span>Paramedic</span>
                  </h3>
                  <span>$262,693</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'frank' ) }>
              <figure>
                <img src={ CareerImageTwentyFour } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Frank
                    <span>Police Officer</span>
                  </h3>
                  <span>$764,152</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Frank gets married, this figure includes his partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'katrina' ) }>
              <figure>
                <img src={ CareerImageTwentyFive } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Katrina
                    <span>Graphic Designer</span>
                  </h3>
                  <span>$348,831</span>
                  <div className={ classes.infoIcon }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIconNew"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Katrina gets married, this figure includes her partner's income/expenses."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'jay' ) }>
              <figure>
                <img src={ CareerImageTwentySix } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Jay
                    <span>Construction Manager</span>
                  </h3>
                  <span>$350,740</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'amber' ) }>
              <figure>
                <img src={ CareerImageTwentySeven } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Amber
                    <span>Cosmetologist</span>
                  </h3>
                  <span>$238,354</span>
                </div>
              </div>
            </li>
            <li role="presentation" aria-hidden="true" onClick={ () => openCardModel( 'tim' ) }>
              <figure>
                <img src={ CareerImageTwentyEight } alt="" />
              </figure>
              <div className={ `${classes.careerCaption} ${classes.careerCaptionPWI}` }>
                <div>
                  <h3>
                    Tim
                    <span>Mechanical Engineer</span>
                  </h3>
                  <span>$363,497</span>
                </div>
              </div>
            </li>
          </ul>
          {fromPage === 'career-sketch' && (
            <div className={ classes.careerMiddleSecond }>
              <div>
                <h3>What might you achieve?</h3>
                <p className={ classes.careerMiddlePara }>Compare career scenarios and sketches to discover valuable insights and inspire powerful strategies.</p>
                <p className={ `${classes.careerMiddlePara} ${classes.careerMiddleParaLast}` }>What will you be worth in 5, 10, 15 years? Now is the time to design your plan for the future. See and build your best tomorrows now.</p>
                <span role="button" className={ classes.buttonAction } aria-hidden="true" onClick={ linkToPricing }>
                  BUILD MY CAREER SKETCH
                  {' '}
                  <i className="la la-greater-than" />
                </span>
              </div>
            </div>
          )}
          {
            fromPage === 'home-page' && (
              <div className={ classes.careerMiddleSecond }>
                <div>
                  <h3>Achieve your financial goals with the power of planning</h3>
                  <p>
                    Inkwiry&apos;s revolutionary software allows you to hone goals and aspirations, explore and organize huge amounts of data, analyze multiple scenarios and compare options. It&apos;s the smart way to build your financial life plan. We call it
                    {' '}
                    <span>
                      Career Sketching
                      <sup>TM</sup>
                      .
                    </span>
                  </p>
                  <span role="button" className={ classes.button } onClick={ handleSeeItAction } aria-hidden="true">SEE IT IN ACTION</span>
                </div>
              </div>
            )
          }
          {
            fromPage === 'career-path' && (
              <div className={ classes.careerMiddleSecond }>
                <div className={ classes.orderListData }>
                  <h3>Explore Inkwiry&apos;s Career Sketches</h3>
                  <ol>
                    <li>Watch the short &apos;SEE IT IN ACTION&apos; video</li>
                    <li>Click on a Career Sketch that interests you</li>
                    <li>Explore both sides of the Career Sketch card</li>
                    <li>Interested in a sketch? Read the full story to learn more.</li>
                  </ol>
                </div>
              </div>
            )
          }
        </div>
        <div className={ `${classes.popupContent} ${classes.popupContentRelative}  ${seeItAction && `${classes.popupContent} ${classes.watchIntroPopup}`}` }>
          <div className={ classes.modalVideoInner }>
            <button type="button" onClick={ handleSeeItActionClose } className={ classes.closeIcon }><i className="la la-close" /></button>
            {seeItAction && <YouTube videoId="54vDPdwMRzE" opts={ youtubeOpts } />}
          </div>
        </div>
        <ReactTooltip id="infoIconNew" backgroundColor="#0069aa" place="right" type="info" effect="solid" className={ classes.tooltipInfoNew } />
        <ReactTooltip id="infoIconNewRight" backgroundColor="#0069aa" place="left" type="info" effect="solid" className={ classes.tooltipInfoNew } />

      </div>

      <Dialog
        open={ openCard !== '' }
        onClose={ () => openCardModel( '' ) }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.careerPopupCnt }
      >
        <DialogContent>
          {openCardType === 'mike' && <MikeCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'erica' && <EricaCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'santiago' && <SantiagoCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'monica' && <MonicaCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'jeremy' && <JeremyCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'jennifer' && <JenniferCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'sam' && <SamCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'katelyn' && <KatelynCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'aaron' && <AaronCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'anita' && <AnitaCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'brooke' && <BrookeCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'calvin' && <CalvinCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'robert' && <RobertCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'taylor' && <TaylorCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'michelle' && <MichelleCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'david' && <DavidCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'laura' && <LauraCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'frank' && <FrankCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'katrina' && <KatrinaCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'jay' && <JayCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'amber' && <AmberCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
          {openCardType === 'tim' && <TimCard closeCard={ openCardModel } openAllSteepingStones={ setOpenAllCard } />}
        </DialogContent>
      </Dialog>

      <Dialog
        open={ openAllCard }
        onClose={ () => { setOpenAllCard( false ); } }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.careerPopupCntAll }
      >
        <DialogContent>
          <div>
          <Button className={ classes.cardClose } onClick={()=>{setOpenAllCard( false )}}><i className="las la-times" /></Button>
            <div className={ classes.carrerSketchTable }>
              <table className={ classes.classescarreerSketchHead } cellPadding="0" cellSpacing="0">
                <thead>
                  <tr className={ classes.firstRow }>
                    <td rowSpan="2">
                      <div className={ classes.tableTittle }>
                        {' '}
                        <p>Career Sketch</p>
                        {' '}
                      </div>
                    </td>
                    <td colSpan="5"><p className={ classes.stepingStonesTopCenter }><span className={ classes.stepingStonesTop }>Stepping Stones</span></p></td>
                  </tr>
                  <tr>
                    <td>
                      <div className={ `${classes.tableStep} ${classes.tableStepFirst}` }>
                        {' '}
                        <img src={ one } alt="" />
                        {' '}
                      </div>
                    </td>
                    <td>
                      <div className={ classes.tableStep }>
                        {' '}
                        <img src={ Two } alt="" />
                        {' '}
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className={ classes.tableStep }>
                        <img src={ Three } alt="" />
                        {' '}
                      </div>
                      {' '}
                    </td>
                    <td>
                      {' '}
                      <div className={ classes.tableStep }>
                        {' '}
                        <img src={ Four } alt="" />
                        {' '}
                      </div>
                      {' '}
                    </td>
                    <td>
                      {' '}
                      <div className={ `${classes.tableStep} ${classes.tableStepLast}` }>
                        {' '}
                        <img src={ Five } alt="" />
                        {' '}
                      </div>
                      {' '}
                    </td>
                  </tr>
                </thead>
              </table>
              <div className={ classes.careerSketchLsit }>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageEight } alt="logo" />
                      Fashion Designer
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Apparel Design</li>
                  <li>Entry-Level Apparel Designer</li>
                  <li>Associate Apparel Designer</li>
                  <li>Senior Apparel Designer</li>
                  <li>Launch Apparel Startup</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageFourteen } alt="logo" />
                      Army Engineer
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Civil Engineering through ROTC</li>
                  <li>Four Years Active Duty</li>
                  <li>Increases Rank</li>
                  <li>Four Years Reserves</li>
                  <li>Professional Civil Engineer</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageSixteen } alt="logo" />
                      Car Salesman
                    </span>
                  </li>
                  <li>Car Salesman Training Program</li>
                  <li>Car Salesman</li>
                  <li>Car Salesman Selling Higher End Vehicles</li>
                  <li>Aspiring Sales Manager</li>
                  <li>Sales Manager</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentySix } alt="logo" />
                      Construction Manager
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Construction Science & Management</li>
                  <li>Assistant Construction Manager</li>
                  <li>Construction Manager</li>
                  <li>Senior Construction Manager</li>
                  <li>Future Construction Company Owner</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageThirteen } alt="logo" />
                      Corporate Attorney
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Legal Studies</li>
                  <li>Juris Doctor Degree</li>
                  <li>Junior Associate</li>
                  <li>Mid-Level Associate</li>
                  <li>Senior Associate</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentySeven } alt="logo" />
                      Cosmetologist
                    </span>
                  </li>
                  <li>One-Year Specialized Cosmetology School</li>
                  <li>Licensed Cosmetologist</li>
                  <li>Hair Stylist</li>
                  <li>Salon Manager</li>
                  <li>Salon Owner</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwenty } alt="logo" />
                      Dairy Farmer
                    </span>
                  </li>
                  <li>Dairy Farm Hand at the Family Farm</li>
                  <li>Dairy Farm Manager at Local Farm</li>
                  <li>Business Courses at Community College</li>
                  <li>Starts a Cooperative with Local Farmers</li>
                  <li>Future Family Farm Owner</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageFour } alt="logo" />
                      Executive Chef
                    </span>
                  </li>
                  <li>One-Year Specialized Culinary Program</li>
                  <li>Line Cook</li>
                  <li>Junior Sous Chef</li>
                  <li>Sous Chef</li>
                  <li>Future Executive Chef</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyone } alt="logo" />
                      Financial Advisor
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Finance</li>
                  <li>Equity Trading Analyst</li>
                  <li>Financial Advisor Training Program</li>
                  <li>Financial Advisor</li>
                  <li />
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyFive } alt="logo" />
                      Graphic Designer
                    </span>
                  </li>
                  <li>In-State College for Two Years</li>
                  <li>Private Art & Design College for Two Years</li>
                  <li>Graphic Designer</li>
                  <li>Senior Graphic Designer</li>
                  <li>Freelance Graphic Designer</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageFifteen } alt="logo" />
                      Master Electrician
                    </span>
                  </li>
                  <li>Four-Year Electrical Lineman Apprenticeship</li>
                  <li>Journeyman Lineman</li>
                  <li>Master Electrician</li>
                  <li>Future Electrical Company Owner</li>
                  <li />
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageSix } alt="logo" />
                      Master Plumber
                    </span>
                  </li>
                  <li>Two-Year Associate&apos;s Degree in Plumbing Technology</li>
                  <li>Five-Year Plumbing Apprenticeship</li>
                  <li>Journeyman Plumber</li>
                  <li>Master Plumber</li>
                  <li>Plumbing Company Owner</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageNine } alt="logo" />
                      Math Teacher
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Education</li>
                  <li>Middle School Math Teacher</li>
                  <li>Master&apos;s Degree in STEM Education</li>
                  <li>High School Math Teacher</li>
                  <li>Curriculum Developer</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyEight } alt="logo" />
                      Mechanical Engineer
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Mechanical Engineering</li>
                  <li>Master&apos;s Degree in Mechanical Engineering</li>
                  <li>Licensed Mechanical Engineer</li>
                  <li>Professional Mechanical Engineer</li>
                  <li>Manager at a Renewable Energy Startup</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyTwo } alt="logo" />
                      Navy Physician
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Biology</li>
                  <li>Medical School Funded by Naval Scholarship</li>
                  <li>Four Years Active Duty in Undersea Medicine</li>
                  <li>Joins Navy Reserves and Starts Residency</li>
                  <li>Aspiring Licensed Physician</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwo } alt="logo" />
                      Neuroradiologist
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Biology</li>
                  <li>Medical School</li>
                  <li>Radiology Residency</li>
                  <li>Radiology Fellowship</li>
                  <li>Licensed Physician Specialized in Neuroradiology</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyThree } alt="logo" />
                      Paramedic
                    </span>
                  </li>
                  <li>Two-Year EMS Program at Community College</li>
                  <li>State Certified Ambulance Paramedic</li>
                  <li>Emergency Room Paramedic</li>
                  <li>Emergency Room Supervisor</li>
                  <li>Aspiring Physician&apos;s Assistant</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageTwentyFour } alt="logo" />
                      Police Officer
                    </span>
                  </li>
                  <li>Police Academy</li>
                  <li>Police Officer</li>
                  <li>Police Detective</li>
                  <li>First-Line Supervisor</li>
                  <li>Future Police Sergeant</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageFive } alt="logo" />
                      Real Estate Broker
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Finance</li>
                  <li>Mortgage Originator</li>
                  <li>Residential Real Estate Agent</li>
                  <li>Real Estate Broker License</li>
                  <li>Builds Realty Team</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageOne } alt="logo" />
                      Registered Architect
                    </span>
                  </li>
                  <li>Professional Degree in Architecture</li>
                  <li>Entry-Level Architect</li>
                  <li>Registered Architect</li>
                  <li>Lead Design Architect</li>
                  <li>Project Architect</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageSeven } alt="logo" />
                      Software Architect
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Computer Science</li>
                  <li>Software Engineer</li>
                  <li>Senior Software Engineer</li>
                  <li>Software Architect</li>
                  <li>Future Chief Technology Officer</li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <img src={ CareerImageThree } alt="logo" />
                      Wall Street Banker
                    </span>
                  </li>
                  <li>Bachelor&apos;s Degree in Finance</li>
                  <li>Investment Banking Analyst</li>
                  <li>Investment Banking Associate</li>
                  <li>Investment Banking Vice President</li>
                  <li>Future Executive Director</li>
                </ul>
              </div>
            </div>
          </div>

        </DialogContent>
      </Dialog>

    </Typography>
  );
};

CareerSketchCards.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fromPage: PropTypes.string.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CareerSketchCards );
