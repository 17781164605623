import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import * as modulesServices from '../../../calculations/modules-services';

import _ from 'lodash';

const livingexpensesKeys = ['Rent','Utilities','Food','Clothing','Entertainment','Technology','Transportation','Miscellaneous','Healthcare Costs other than Premium','Health Insurance Premium'];
const livingexpensesHigherKeys = ['Higher Education Tuition and Fees','Higher Education Room & Board','Off Campus Food']; 
const otherExpensesKeys = ['Children','Pet','Total Car Expenses','Total Real Estate Expenses'];

const MyCombinedBudget = ( props ) => {
  const { classes, activeStep } = props;

  const [expandLivingExpenses, setExpandLivingExpenses] = useState( false );
  const [otherExpenses, setOtherExpenses] = useState( false );
  const [presetStep, setPresetStep] = React.useState('');
  useEffect(()=>{
    if(presetStep !== activeStep) {
      setPresetStep(activeStep);
      setExpandLivingExpenses(false);
      setOtherExpenses(false);
    }
  })

  const startYear = modulesServices.module10Data.marriege_year;
  const endYear = modulesServices.module10Data.start_year+14;;

  const handleExpandLiving = () => {
    setExpandLivingExpenses( !expandLivingExpenses );
  };

  const handleOtherExpenses = () => {
    setOtherExpenses( !otherExpenses );
  };

  const individualLivingExpensesCalc = (label,type="monthly",from='table') => {
    const incomeSheetData = modulesServices.incomeStatementCompleteData();
    let displayValue = 0;

    if(typeof incomeSheetData !== 'undefined' && incomeSheetData !== ''){
      if(type === 'monthly' || type === 'yearly') {
        if(typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== '' 
            && typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== ''
            && typeof incomeSheetData[startYear]['livingExpenses'] !== 'undefined' && incomeSheetData[startYear]['livingExpenses'] !== ''
            && typeof incomeSheetData[startYear]['livingExpenses'][label] !== 'undefined' 
            && incomeSheetData[startYear]['livingExpenses'][label] !== '') {
            displayValue = incomeSheetData[startYear]['livingExpenses'][label];
        }
      } else {
        if(startYear > 0 && endYear > 0 ){
          for(let i=startYear; i<=endYear;i+=1) {
            if(typeof incomeSheetData[i] !== 'undefined' && incomeSheetData[i] !== '' 
                && typeof incomeSheetData[i] !== 'undefined' && incomeSheetData[i] !== ''
                && typeof incomeSheetData[i]['livingExpenses'] !== 'undefined' && incomeSheetData[i]['livingExpenses'] !== ''
                && typeof incomeSheetData[i]['livingExpenses'][label] !== 'undefined' && incomeSheetData[i]['livingExpenses'][label] !== '') {
                  displayValue = displayValue+incomeSheetData[i]['livingExpenses'][label];
            }   
          }
        }
      }
    }
    if(type === 'monthly') {
      displayValue = displayValue/12;
    }
    if(from === 'table') {
      return (
        <span className={displayValue < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
        </span>
      )
    } else {
      return displayValue;
    }
}

 const individualLivingCalc = (label, type='monthly', from='table') => {
  const incomeStatementData = modulesServices.incomeStatementCompleteData();
  let displayValue = 0;
  if(type === 'monthly' || type === 'yearly') {
    if( incomeStatementData !== 'undefined' && incomeStatementData !== '' 
        && typeof incomeStatementData[startYear] !== 'undefined' && incomeStatementData[startYear] !== '' 
        && typeof incomeStatementData[startYear][label] !== 'undefined' ){
        displayValue = incomeStatementData[startYear][label];
    }
    if(type === 'monthly') {
      displayValue = displayValue/12;
    }
  } else {
    if(startYear > 0 && endYear > 0) {
      for(let i=startYear; i<=endYear;i+=1) {
        if(typeof incomeStatementData[i] !== 'undefined' && incomeStatementData[i] !== '' 
            && typeof incomeStatementData[i] !== 'undefined' && incomeStatementData[i] !== ''
            && typeof incomeStatementData[i][label] !== 'undefined' && incomeStatementData[i][label] !== '') {
              displayValue = displayValue+incomeStatementData[i][label];
        }   
      }
    }
  }
  
  if(from === 'table') {
    return (
      <span className={displayValue < 0 && classes.dangerText}>
          <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
      </span>
    )
  } else {
    return displayValue;
  }
}

const careerPathLivingEducationExpenses = (type='monthly', from = 'table') => {
  const values = livingexpensesHigherKeys.map((key)=>{
    return individualLivingExpensesCalc(key,type,'')
  })  
  let total = _.sum(values);
  if(from === 'table') {
    return (
      <span className={total < 0 && classes.dangerText}>
          <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
      </span>
    )
  } else {
    return total;
  }
}

  const MaritalIncomeStatementSummary = () => { 
    if ( typeof modulesServices.module10Data !== 'undefined' && modulesServices.module10Data !== '' && modulesServices.module10Data.marriege_year > 0 && modulesServices.module10Data.marriege_year !== '' && typeof modulesServices.module1Data !== 'undefined' && modulesServices.module1Data !== '' && typeof modulesServices.module1Data.start_year !== 'undefined' && modulesServices.module1Data.start_year !== '' ) {
      return `${modulesServices.module10Data.marriege_year} - ${modulesServices.module1Data.start_year + 14}`;
    }
    return 'Years';
  };
  const otherExpensesVal = modulesServices.module10Data.higherEducationExpensesMain+modulesServices.module10Data.childrenExpensesMain+modulesServices.module10Data.petExpensesMain+modulesServices.module10Data.marritalCarPaidMain+modulesServices.module10Data.marritalRealEstatePaidMain;
  const otherOtherExpensesVal = modulesServices.module10Data.higherEducationExpenses+modulesServices.module10Data.childrenExpenses+modulesServices.module10Data.petExpenses+modulesServices.module10Data.marritalCarPaid+modulesServices.module10Data.marritalRealEstatePaid;
  return (
    <div className={ classNames( classes.vacationTable, classes.budgetTable ) }>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Combined Budget</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>{MaritalIncomeStatementSummary()}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalTotalIncomeMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTotalIncomeMain/12 } prefix={ modulesServices.module10Data.marritalTotalIncomeMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTotalIncomeMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalTotalIncomeMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTotalIncomeMain } prefix={ modulesServices.module10Data.marritalTotalIncomeMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTotalIncomeMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalTotalIncome < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTotalIncome } prefix={ modulesServices.module10Data.marritalTotalIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTotalIncome < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.dataFourZero }>Total Taxes Paid</td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesPaidMain < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesPaidMain/12 } prefix={ modulesServices.module10Data.marritalTaxesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTaxesPaidMain < 0 && ')' } />
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesPaidMain < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesPaidMain } prefix={ modulesServices.module10Data.marritalTaxesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTaxesPaidMain < 0 && ')' } />
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesPaid < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesPaid } prefix={ modulesServices.module10Data.marritalTaxesPaid >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalTaxesPaid < 0 && ')' } />
              </span>
            </td>
          </tr>
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.dataSixZero }>Tax Rate</td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesRateMain < 0 && classes.dangerText}>
                  <i><NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesRateMain } prefix={ modulesServices.module10Data.marritalTaxesRateMain >= 0 ? '' : '(' } suffix={ modulesServices.module10Data.marritalTaxesRateMain < 0 ? '%)' : '%' } /></i>
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesRateMain < 0 && classes.dangerText}>
                  <i><NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesRateMain } prefix={ modulesServices.module10Data.marritalTaxesRateMain >= 0 ? '' : '(' } suffix={ modulesServices.module10Data.marritalTaxesRateMain < 0 ? '%)' : '%' } /></i>
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalTaxesRate < 0 && classes.dangerText}>
                  <i><NumberFormat decimalScale={ 2 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalTaxesRate } prefix={ modulesServices.module10Data.marritalTaxesRate >= 0 ? '' : '(' } suffix={ modulesServices.module10Data.marritalTaxesRate < 0 ? '%)' : '%' } /></i>
              </span>
            </td>
          </tr>
          <tr className={ classes.livingRow } aria-hidden="true" onClick={ handleExpandLiving }>
            <td>
              <span>
                {!expandLivingExpenses && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                {expandLivingExpenses && ( <i className="fa fa-minus" aria-hidden="true" /> )}
              </span>
            </td>
            <td className={ classes.dataFourZero }>
              <b>Living Expenses</b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalExpensesPaidMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalExpensesPaidMain/12 } prefix={ modulesServices.module10Data.marritalExpensesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalExpensesPaidMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalExpensesPaidMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalExpensesPaidMain } prefix={ modulesServices.module10Data.marritalExpensesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalExpensesPaidMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.marritalExpensesPaid < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalExpensesPaid } prefix={ modulesServices.module10Data.marritalExpensesPaid >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalExpensesPaid < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
          {expandLivingExpenses && (
            <React.Fragment>
              {
                livingexpensesKeys.map((keyValue)=>(
                   <tr className={ classes.livingRow }>
                      <td>&nbsp;</td>
                      <td className={ classes.leftSubPadding }>
                        {keyValue === 'Clothing' && 'Clothing & Personal Care'}
                        {keyValue === 'Healthcare Costs other than Premium' && 'Health Insurance Costs outside Premium'}
                        {keyValue !== 'Clothing' && keyValue !== 'Healthcare Costs other than Premium' && keyValue}
                      </td>
                      <td>
                        {individualLivingExpensesCalc(keyValue,'monthly')}
                      </td>
                      <td>
                        {individualLivingExpensesCalc(keyValue,'yearly')}
                      </td>
                      <td>
                      {individualLivingExpensesCalc(keyValue,'years')}
                      </td>
                   </tr>
                ))
              }
            </React.Fragment>
          )}

          <tr className={ classes.otherExpenses } aria-hidden="true" onClick={ handleOtherExpenses }>
            <td>
              <span>
                {!otherExpenses && ( <i className="fa fa-plus" aria-hidden="true" /> )}
                {otherExpenses && ( <i className="fa fa-minus" aria-hidden="true" /> )}
              </span>
            </td>
            <td className={ classes.dataFourZero }>
              <b>Other Expenses</b>
            </td>
            <td>
              <b>
                <span className={otherExpensesVal < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ otherExpensesVal/12 } prefix={ otherExpensesVal >= 0 ? '$' : '($' } suffix={ otherExpensesVal < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={otherExpensesVal < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ otherExpensesVal } prefix={ otherExpensesVal >= 0 ? '$' : '($' } suffix={ otherExpensesVal < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={otherOtherExpensesVal < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ otherOtherExpensesVal } prefix={ otherOtherExpensesVal >= 0 ? '$' : '($' } suffix={ otherOtherExpensesVal < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
          {otherExpenses && (
            <React.Fragment>
              <tr className={ classes.otherExpenses }>
                <td>&nbsp;</td>
                <td className={ classes.leftSubPadding }>
                  Higher Education Expenses
                </td>
                <td>
                  {careerPathLivingEducationExpenses('monthly')}
                </td>
                <td>
                  {careerPathLivingEducationExpenses('annual')}
                </td>
                <td>
                  {careerPathLivingEducationExpenses('years')}
                </td>
              </tr>
              {
                otherExpensesKeys.map((keyValue)=>(
                   <tr className={ classes.otherExpenses }>
                      <td>&nbsp;</td>
                      <td className={ classes.leftSubPadding }>
                        {keyValue === 'Total Car Expenses' && 'Car Expenses'}
                        {keyValue === 'Total Real Estate Expenses' && 'Real Estate Expenses'}
                        {keyValue !== 'Total Car Expenses' && keyValue !== 'Total Real Estate Expenses' && keyValue}
                      </td>
                      <td>
                        {
                          (keyValue === 'Total Car Expenses' ||  keyValue === 'Total Real Estate Expenses') ? (
                            individualLivingCalc(keyValue,'monthly')
                          ) : (
                            individualLivingExpensesCalc(keyValue,'monthly')
                          )
                        }
                      </td>
                      <td>
                        {
                          (keyValue === 'Total Car Expenses' ||  keyValue === 'Total Real Estate Expenses') ? (
                            individualLivingCalc(keyValue,'yearly')
                          ) : (
                            individualLivingExpensesCalc(keyValue,'yearly')
                          )
                        }
                      </td>
                      <td>
                        {
                          (keyValue === 'Total Car Expenses' ||  keyValue === 'Total Real Estate Expenses') ? (
                            individualLivingCalc(keyValue,'years')
                          ) : (
                            individualLivingExpensesCalc(keyValue,'years')
                          )
                        }
                      </td>
                   </tr>
                ))
              }
            </React.Fragment>
          )}
          <tr className={ classes.grayRow }>
            <td>&nbsp;</td>
            <td className={ classes.dataFourZero }>Loan Payments</td>
            <td>
              <span className={modulesServices.module10Data.marritalFinancingExpensesPaidMain < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalFinancingExpensesPaidMain/12 } prefix={ modulesServices.module10Data.marritalFinancingExpensesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalFinancingExpensesPaidMain < 0 && ')' } />
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalFinancingExpensesPaidMain < 0 && classes.dangerText}>
                <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalFinancingExpensesPaidMain } prefix={ modulesServices.module10Data.marritalFinancingExpensesPaidMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalFinancingExpensesPaidMain < 0 && ')' } />
              </span>
            </td>
            <td>
              <span className={modulesServices.module10Data.marritalFinancingExpensesPaid < 0 && classes.dangerText}>
                <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.marritalFinancingExpensesPaid } prefix={ modulesServices.module10Data.marritalFinancingExpensesPaid >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.marritalFinancingExpensesPaid < 0 && ')' } />
              </span>
            </td>
          </tr>
          <tr className={ `${classes.grayRow} ${classes.blackBorder}`}>
            <td>&nbsp;</td>
            <td><b>Total Expenses</b></td>
            <td>
              <b>
                <span className={modulesServices.module10Data.totalAnnualExpensesMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.totalAnnualExpensesMain/12 } prefix={ modulesServices.module10Data.totalAnnualExpensesMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.totalAnnualExpensesMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.totalAnnualExpensesMain < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.totalAnnualExpensesMain } prefix={ modulesServices.module10Data.totalAnnualExpensesMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.totalAnnualExpensesMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.totalAnnualExpenses < 0 && classes.dangerText}>
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.totalAnnualExpenses } prefix={ modulesServices.module10Data.totalAnnualExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.totalAnnualExpenses < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
          <tr className={ `${classes.blueRow}` }>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain/12 } prefix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain } prefix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.annualCashFlowBeforeRetirementContributions < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributions } prefix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributions >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.annualCashFlowBeforeRetirementContributions < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
          <tr className={ `${classes.grayRow} ${classes.blackBorder}` }>
            <td>&nbsp;</td>
            <td>
              Retirement Contributions
            </td>
            <td>
                <span className={modulesServices.module10Data.retirementContributionsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.retirementContributionsMain/12 } prefix={ modulesServices.module10Data.retirementContributionsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.retirementContributionsMain < 0 && ')' } />
                </span>
            </td>
            <td>
                <span className={modulesServices.module10Data.retirementContributionsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.retirementContributionsMain } prefix={ modulesServices.module10Data.retirementContributionsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.retirementContributionsMain < 0 && ')' } />
                </span>
            </td>
            <td>
              <span>
                <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.retirementContributions } prefix={ modulesServices.module10Data.retirementContributions >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.retirementContributions < 0 && ')' } />
              </span>
            </td>
          </tr>
          <tr className={ classes.blueRow }>
            <td>&nbsp;</td>
            <td>
              <b>Net Cash Flow for Savings and Investments</b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain/12 } prefix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain } prefix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && ')' } />
                </span>
              </b>
            </td>
            <td>
              <b>
                <span className={modulesServices.module10Data.netCashFlowForSavingsAndInvestments < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.netCashFlowForSavingsAndInvestments } prefix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestments >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.netCashFlowForSavingsAndInvestments < 0 && ')' } />
                </span>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

MyCombinedBudget.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( MyCombinedBudget );
