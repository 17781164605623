import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import eachQuestionXp, { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import Pf101Popups from '../../popups';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );

/* eslint react/prop-types: 0 */
const startTime = new Date();
const IncomeTaxes101 = ( props ) => {
  const { classes, history } = props;
  const defaultCorrectAnswer = ['4/15/20', '2', '1', 'Hire a tax professional', 'All of the above', 'W-2 form', '1040 form'];
  const defaultQuestionList = [];

  for ( let i = 0; i <= 6; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: defaultCorrectAnswer[i],
      status: '',
      answer: '',
    } );
  }

  let bodySection = useRef( null );

  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [questionProgressData, setQuestionProgressData] = React.useState( {
    questions_progress_val: eachQuestionXp, questions_progress: 100, total_progress: 0, total_progress_val: 0,
  } );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 2,
      exercise: 4,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      setLoading( false );
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  useEffect( () => {
    const questionsData = questionList;

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questionsData[activeSlide - 1].xp_earned;
    progressData.questions_progress = ( questionsData[activeSlide - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questionsData, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( questionsData.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
    // eslint-disable-next-line
  }, [activeSlide, exerciseStatus] );

  const answerQuestion = ( e ) => {
    const questions = [...questionList];
    questions[activeSlide - 1].answer = e.target.value;
    questions[activeSlide - 1].status = '';
    setQuestionList( questions );
  };

  const checkAnswer = ( question ) => {
    const questions = [...questionList];
    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
      questions[question - 1].question_attempts = questions[question - 1].question_attempts + 1;
    }
    questions[question - 1].xp_earned = xpPoints( questions[question - 1].question_attempts );
    setQuestionList( questions );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questions[question - 1].xp_earned;
    progressData.questions_progress = ( questions[question - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questions, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( defaultQuestionList.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( questionList, 'xp_earned' );
    const totalAttempts = _.sumBy( questionList, 'question_attempts' );
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    // Adding 50 points for all questions answered single attempt
    if ( totalAttempts === defaultQuestionList.length ) {
      totalPoints += 50;
      popupValue.precision = true;
      popupValue.precisionPoints = 50;
    } else {
      popupValue.precision = false;
      popupValue.precisionPoints = 0;
    }

    // Adding 500 ponits for completing level
    totalPoints += 500;

    popupValue.level = true;
    popupValue.levelPoints = 500;
    popupValue.levelNumber = 2;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 2,
          exercise: 4,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 2:</span>
              {' '}
              Income and Taxes
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                Filing Your Taxes 101 (
                {activeSlide}
                {' '}
                of
                {' '}
                {defaultQuestionList.length}
                )
              </h3>
            </div>
            <Link to="/pf101/level2/federal-income-taxes" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>

          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>
          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, answer the following questions. Each question is worth
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP.</span>
                  {' '}
                  If you get the question right on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP</span>
                  . On the second try,
                  {' '}
                  <span className={ classes.bonusXp }>+15 XP</span>
                  . Third try,
                  {' '}
                  <span className={ classes.bonusXp }>+12 XP</span>
                  . And fourth try,
                  {' '}
                  <span className={ classes.bonusXp }>+10 XP</span>
                  .
                </h3>
                <h3>
                  <span className={ classes.bonusXp }>BONUS XP:</span>
                  {' '}
                  If you answer all questions correctly on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+50 XP</span>
                  {' '}
                  at the end of the activity. Good luck!
                </h3>
                <h3>A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve answered all correctly.</h3>
              </div>
              <h3>What day is Tax Day in 2020?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4/13/20' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4/13/20' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        4/13/20
                        <input type="radio" name="equation" value="4/13/20" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '4/15/20' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '4/15/20' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        4/15/20
                        <input type="radio" name="equation" value="4/15/20" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4/17/20' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4/17/20' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        4/17/20
                        <input type="radio" name="equation" value="4/17/20" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4/21/20' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4/21/20' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        4/21/20
                        <input type="radio" name="equation" value="4/21/20" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>Step 1 to filing your taxes is ________.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Decide if you&apos;re going to use online software or a tax professional
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Gather tax information
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        File before tax day
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Step 2 to filing your taxes is ________.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Decide if you&apos;re going to use online software or a tax professional
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Gather tax information
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        File before tax day
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>Most Americans ________ to do their taxes.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === 'Call the IRS' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Call the IRS' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Call the IRS
                        <input type="radio" name="equation" value="Call the IRS" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === 'Hire a tax professional' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === 'Hire a tax professional' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Hire a tax professional
                        <input type="radio" name="equation" value="Hire a tax professional" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === 'Use online tax software' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Use online tax software' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Use online tax software
                        <input type="radio" name="equation" value="Use online tax software" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === 'Print out and complete IRS tax forms' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Print out and complete IRS tax forms' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Print out and complete IRS tax forms
                        <input type="radio" name="equation" value="Print out and complete IRS tax forms" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
          { activeSlide === 5 && (
            <div className={ classes.contentBlock }>
              <h3>Which of the following is a piece of information you&apos;ll need to file your taxes?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        W-2 form
                        <input type="radio" name="equation" value="W-2 form" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '1099 forms' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '1099 forms' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        1099 forms
                        <input type="radio" name="equation" value="1099 forms" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Social Security Number' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Social Security Number' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Social Security Number
                        <input type="radio" name="equation" value="Social Security Number" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'All of the above' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'All of the above' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        All of the above
                        <input type="radio" name="equation" value="All of the above" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
          { activeSlide === 6 && (
            <div className={ classes.contentBlock }>
              <h3>A form that details exactly how much you earned in the past year and how much you have already paid in taxes. Each job is required to supply you with this form no later than January 31st so you can file your taxes on time.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1099 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1099 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        1099 form
                        <input type="radio" name="equation" value="1099 form" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        W-2 form
                        <input type="radio" name="equation" value="W-2 form" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'W-4 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'W-4 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        W-4 form
                        <input type="radio" name="equation" value="W-4 form" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1040 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1040 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        1040 form
                        <input type="radio" name="equation" value="1040 form" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 7 && (
            <div className={ classes.contentBlock }>
              <h3>The comprehensive income tax form that is divided into sections and details every number in your income taxes calculations. This form will be a great starting point for filing this year&apos;s taxes.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1099 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1099 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        1099 form
                        <input type="radio" name="equation" value="1099 form" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === 'W-2 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        W-2 form
                        <input type="radio" name="equation" value="W-2 form" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'W-4 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'W-4 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        W-4 form
                        <input type="radio" name="equation" value="W-4 form" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1040 form' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1040 form' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        1040 form
                        <input type="radio" name="equation" value="1040 form" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={ classes.answerStatus }>
          {questionList[activeSlide - 1].status === 'C' && (
          <h2 className={ classes.correctAnswer }>
            Correct! You earned +
            {questionList[activeSlide - 1].xp_earned}
            {' '}
            XP.
          </h2>
          )}
          {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
        </div>
        <div className={ classes.btnGroup }>
          {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}
          <div className={ classes.progressXp }>
            <div className={ classes.questionXp }>
              <p>
                <span>Question XP:</span>
                {' '}
                +
                {questionList[activeSlide - 1].xp_earned}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.questions_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp}
                {' '}
                XP
              </span>
            </div>
            <div className={ classNames( classes.questionXp, classes.totalQuestion ) }>
              <p>
                <span>Total XP:</span>
                {' '}
                +
                {questionProgressData.total_progress_val}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.total_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp * defaultQuestionList.length}
                {' '}
                XP
              </span>
            </div>
          </div>
          <Button className={ `${classes.manageButton} ${classes.checkAnswer}` } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
          {defaultQuestionList.length === activeSlide ? (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
          ) : (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
          )}

        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } level = {4}/>
    )}
    </Typography> 
  );
};

IncomeTaxes101.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( IncomeTaxes101 );
