import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();

const BankResponsibly = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>7 Tips to Avoid Fees and Bank Responsibly</h3>
                    </div>
                    <div className={ classNames( classes.pfVideo, classes.iframeHeight ) }>
                      <YouTube videoId="Dk11EbaFXgE" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/avoid_fees_bank_responsibilities.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>7 Tips to Avoid Fees and Bank Responsibly</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/high-yield-savings-vert.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            <a className={ classes.anchorStyle } href="https://www.chimebank.com/no-fees/" target="_blank" rel="budget-fy2020 noopener noreferrer">According to Chime</a>
                            , the average US household pays over $329 in bank fees every year, or $27 per month. Check out the seven tips below to avoid unnecessary fees and bank responsibly
                          </p>
                          <p>
                            <b>Tip #1 - Spend less than you earn</b>
                            <br />
                            If you spend less than you earn, you will save money each month,
                            avoid unnecessary fees such as credit card interest, overdraft fees, or minimum balance fees,
                            and grow your wealth.
                            {' '}
                            <b>This is the fundamental rule of personal finance!</b>
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Tip #2 - Always know how much money you have in the bank</b>
                          <br />
                          If you always know how much money you have in the bank, you won&apos;t overspend and will avoid overdraft fees. And, make sure that you have enough money in your account to satisfy any minimum balance requirements.
                          <p>
                            <b>Tip #3 - Never spend money on your credit card that you don&apos;t already have in the bank</b>
                            <br />
                            Credit card debt is the most expensive debt with interest rates ranging from 15-30%. Never make purchases on your credit card that are greater than the amount of money you have in the bank unless it&apos;s a real financial life emergency. Follow this tip and you&apos;ll stay out of credit card debt.
                          </p>
                          <p>
                            <b>Tip #4 - Repay your credit card balance in full each month</b>
                            <br />
                            Again, credit card debt is the most expensive debt with interest rates ranging from 15-30%. When you don&apos;t repay your credit card in full each month, you carry a balance and get charged interest. Follow this tip and tip #3 and stay out of credit card debt.
                          </p>
                          <p>
                            <b>Tip #5 - Pay yourself and your debts first</b>
                            <br />
                            When you get paid, set aside a portion of your income for savings and debt repayment before you do anything else. Strive to save 15-20% of your after-tax income and repay your highest interest rate debts first.
                          </p>
                          <p>
                            <b>Tip #6 - Build and maintain a budget</b>
                            <br />
                            Building a budget is the most valuable personal finance activity you can do. This process will allow you to discover where you could be overspending and where you have opportunities to save more. Once you have your budget, you have a short-term game plan to achieve your goals.
                          </p>
                          <p>
                            <b>Tip #7 - Keep banking as simple as possible</b>
                            <br />
                            Don&apos;t overcomplicate banking. Only open bank accounts and credit cards that you absolutely need. The more accounts you have, you&apos;re more likely to lose focus and make a mistake. Keep it simple when it comes to banking!
                          </p>
                          <p>
                            $27 in bank fees each month may not seem like much. But, imagine if you invested $27 each month over the next 30 years and earned 5% annually instead. That could grow to over $22,500 in savings just by avoiding fees and banking responsibly!
                          </p>
                        </p>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level4/bank-responsibly/exercise" startTime={ startTime } level={ 4 } exercise={ 3 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
BankResponsibly.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( BankResponsibly );
