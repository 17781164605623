import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';

import financeExplained from '../../../assets/img/challenges/finance-explained.png';

// import feedbackIcon from '../../../assets/img/awareness/feedback-icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pageloader from '../../../components/ui/pageloader';
import { getTotalTime } from '../exercises/pf101-service';

const SAVE_QUESTIONS = loader( '../../../graphql/schema/pf101/save-baseline-challenge.graphql' );
const GET_QUESTIONS = loader( '../../../graphql/schema/pf101/get-baseline-challenge.graphql' );
const startTime = new Date();
const Pf101BaselineChallenge = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const correctAns = ['False', 'Pay off $5,000 credit card balance', 'Your history of making debt payments is the most important factor',
    '$4,400', 'You invest in a basket of investments rather than one stock or one bond to lower your risk while gaining exposure to many different investments',
    'High-yield savings accounts pay up to 24x more interest to you than a traditional savings account', 'Principal Repayment',
    '$119,500', 'Inflation', 'W-2', 'A pool of money given by investors that is actively managed by a fund manager', 'False', '', '', ''];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 14; i += 1 ) {
    const questionsObject = {
      qId: i + 1, answer: '', correctAns: correctAns[i], scroll: defaultReference, status: '',
    };
    questionMainAns.push( questionsObject );
  }

  let quesionSection = useRef( null );
  let feedBackSection = useRef( null );

  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [myScoreContent, setMyscoreContent] = React.useState( false );
  const [avgScore, setAvgScore] = React.useState( 0 );
  const [loading, setLoading] = React.useState( true );
  const [challengeSubmitted, setChallengeSubmitted] = React.useState( false );

  useQuery( GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      const updateQuestions = [...questions];
      Object.keys( response.getPf101BaselineChallenge ).forEach( ( question ) => {
        let questionVal = question;
        questionVal = questionVal.replace( 'question_', '' );
        questionVal = parseInt( questionVal, 10 );
        // eslint-disable-next-line
        if ( !isNaN( questionVal ) ) {
          updateQuestions[questionVal - 1].answer = response.getPf101BaselineChallenge[question];
          if ( questionVal <= 12 ) {
            if ( updateQuestions[questionVal - 1].answer === updateQuestions[questionVal - 1].correctAns ) {
              updateQuestions[questionVal - 1].status = 'C';
            } else {
              updateQuestions[questionVal - 1].status = 'W';
            }
          } else {
            updateQuestions[questionVal - 1].status = 'C';
            updateQuestions[questionVal - 1].correctAns = response.getPf101BaselineChallenge[question];
          }
        }
      } );
      setQuestions( updateQuestions );
      setChallengeSubmitted( true );
      setLoading( false );
    },
    onError( ) {
      setLoading( false );
    },
  } );

  const [saveChallenge] = useMutation( SAVE_QUESTIONS, {
    onCompleted( ) {
      setLoading( false );
      setChallengeSubmitted( true );
    },
    onError( ) {
      return false;
    },
  } );

  useEffect( () => {
    const answerQuestions = _.reject( questions, ['answer', ''] ).length;
    let progressBar = answerQuestions * ( 100 / 15 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
  }, [questions] );

  useEffect( () => {
    if ( myScoreContent ) {
      scrollToComponent( feedBackSection, { offset: -0, align: 'top', duration: 500 } );
    }
  }, [myScoreContent] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    setQuestions( newQuesions );
  };

  const selectRating = ( rating, index ) => {
    if ( challengeSubmitted === false ) {
      const newQuesions = [...questions];
      newQuesions[index].answer = rating;
      newQuesions[index].correctAns = rating;
      newQuesions[index].status = 'C';
      setQuestions( newQuesions );
    }
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.filter( questions, ['answer', ''] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'qId' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      setMyscoreContent( true );
      const totalCorrectAns = _.filter( questions, ['status', 'C'] ).length;
      setAvgScore( totalCorrectAns * ( 100 / 12 ) );
      if ( myScoreContent ) {
        scrollToComponent( feedBackSection, { offset: -0, align: 'top', duration: 500 } );
      }
      if ( challengeSubmitted === false ) {
        setLoading( true );
        const saveObject = {};
        for ( let i = 0; i < 15; i += 1 ) {
          saveObject[`question_${i + 1}`] = questions[i].answer;
          if ( i < 12 ) {
            saveObject[`question_${i + 1}_status`] = questions[i].status;
          }
        }
        saveObject.total_time = getTotalTime( startTime );

        saveChallenge( {
          variables: {
            data: saveObject,
          },
        } );
      }
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport2 = ( el ) => {
    let element = el;
    if ( element !== null && element.offsetTop !== undefined ) {
      let top = element.offsetTop;
      let left = element.offsetLeft;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      while ( element.offsetParent ) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top < ( window.pageYOffset + window.innerHeight )
        && left < ( window.pageXOffset + window.innerWidth )
        && ( top + height ) > window.pageYOffset
        && ( left + width ) > window.pageXOffset
      );
    }
    return false;
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      let feedBackSectionCondition = true;
      if ( feedBackSection.current === undefined ) {
        if ( feedBackSection.getBoundingClientRect().top < ( window.innerHeight / 2 ) ) {
          feedBackSectionCondition = false;
        }
      }

      if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 && feedBackSectionCondition === true ) {
        document.getElementById( 'awareness_title' ).style.position = 'fixed';
        document.getElementById( 'awareness_title_sub' ).style.display = 'block';
      } else {
        document.getElementById( 'awareness_title' ).style.position = '';
        document.getElementById( 'awareness_title_sub' ).style.display = 'none';
      }

      if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 && feedBackSectionCondition === true ) {
        document.getElementById( 'sticky_numbers' ).style.left = '30px';
      } else {
        document.getElementById( 'sticky_numbers' ).style.left = '-11em';
      }

      for ( let i = 0; i < 15; i += 1 ) {
        const element = $( questionMainAns[i].scroll );
        if ( ( elementInViewport( element ) === true || elementInViewport( element.find( 'ul' ) ) || elementInViewport( element.find( 'table' ) ) || elementInViewport( element.find( 'p' ) ) ) ) {
          element.css( 'opacity', '1' );
          element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
          $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
          if ( i <= 6 ) {
            $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
          } else {
            $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
          }
          break;
        }
      }

      if ( ( myScoreContent === true && elementInViewport2( feedBackSection ) ) || elementInViewport2( document.getElementById( 'footer' ) )
        || ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
        document.getElementById( 'progress-bar' ).style.display = 'none';
      } else {
        document.getElementById( 'progress-bar' ).style.display = 'block';
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.awarenessBody }>
        <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
          <div className={ classes.ChallengesQuizHead } id="awareness_title">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">Baseline Challenge</Typography>
            </div>
          </div>

          <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">&nbsp;</Typography>
            </div>
          </div>

          <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
            <ul>
              {
                  [1, 2, 3, 4, 5, 6, 7].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <ul>
              {
                  [8, 9, 10, 11, 12, 13, 14].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>
            <div className={ `${classes.stickyBottom} ${classes.stickyBottomNewOne}` }>
              <li>
                <Button onClick={ () => handleQuestionScroll( questionMainAns[14].scroll ) } className={ classNames( classes.listNum, ( questions[14].answer !== '' && questions[14].answer === questions[14].correctAns ) ? classes.listNumRight : ( ( questions[14].answer !== '' ) && classes.listNumWrong ) ) }>
                  <span>
                    15
                    <i className="las la-check" />
                  </span>
                </Button>
              </li>
            </div>
            <div className={ classes.stickyBottom }>
              <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                <span>
                  <i
                    className="las la-arrow-up"
                    data-for="returnStart"
                    data-tip="Return to start"
                  />
                </span>
                <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
              </Button>
            </div>
            {
              /*
                progress === 100 && (
                  <div className={ classes.feedbackImg }>
                    <Button onClick={ handleMyScore } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                      <span>
                        <figure>
                          <img
                            src={ feedbackIcon }
                            alt="Feedback"
                            data-for="returnFeedback"
                            data-tip="Go to Feedback"
                          />
                          <ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={ `${classes.tooltipInfo} ${classes.feedbackTooltip}` } />
                        </figure>
                      </span>
                    </Button>
                  </div>
                )
              */
            }
          </div>
          <div className={ classes.questionRow }>
            <div className={ classes.container }>
              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[0].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>1</span>
                  <p className={ classes.questionTitle }>True or False: A Roth IRA is an employer-sponsored retirement account in which contributions are made with before-tax dollars, and your money grows tax-deferred until you make withdrawals in retirement.</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[0].answer === 'True' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="true1">
                          True
                          <input type="radio" name="equation" value="True" id="true1" disabled={ questions[0].answer !== '' } onChange={ ( e ) => handleAnswer( e, 0 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[0].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="true1">
                          False
                          <input type="radio" name="equation" value="False" id="true1" disabled={ questions[0].answer !== '' } onChange={ ( e ) => handleAnswer( e, 0 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[1].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>2</span>
                  <p className={ classes.questionTitle }>You earned a $5,000 after-tax year-end bonus. Which of the following options below would be the best use of that money?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[1].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="2_1">
                          Pay off $5,000 credit card balance
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Pay off $5,000 credit card balance" id="2_1" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Invest in a bond paying a 6% coupon every six months' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_2">
                          Invest in a bond paying a 6% coupon every six months
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Invest in a bond paying a 6% coupon every six months" id="2_2" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Repay $5,000 car loan balance' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_3">
                          Repay $5,000 car loan balance
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Repay $5,000 car loan balance" id="2_3" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[1].answer === 'Invest in an ETF that has returned 8% per year for the past five years' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="2_4">
                          Invest in an ETF that has returned 8% per year for the past five years
                          <input type="radio" disabled={ questions[1].answer !== '' } name="equation" value="Invest in an ETF that has returned 8% per year for the past five years" id="2_4" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[2].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>3</span>
                  <p className={ classes.questionTitle }>Which of the following is true about a credit score?</p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[2].answer === 'When you check your credit score, you hurt your score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_1">
                          When you check your credit score, you hurt your score
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="When you check your credit score, you hurt your score" id="3_1" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer === 'Having a high income will translate into a good credit score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_2">
                          Having a high income will translate into a good credit score
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Having a high income will translate into a good credit score" id="3_2" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="3_3">
                          Your history of making debt payments is the most important factor
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="Your history of making debt payments is the most important factor" id="3_3" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[2].answer === 'When you cancel and close out credit cards, you improve your credit score' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="3_4">
                          When you cancel and close out credit cards, you improve your credit score
                          <input type="radio" disabled={ questions[2].answer !== '' } name="equation" value="When you cancel and close out credit cards, you improve your credit score" id="3_4" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[3].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>4</span>
                  <p className={ classes.questionTitle }>
                    <span className={ classes.questionSubTitle }>
                      Mike breaks his hand playing in his weekly basketball league and needs surgery. The hand surgery costs $10,000 total.
                    </span>
                    <span>
                      If Mike&apos;s health insurance plan has a $5,000 deductible, an 80%/20% coinsurance rate, and he has used $500 of his deductible so far this year, how much of the new medical bill will Mike&apos;s insurance company pay for?
                    </span>
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[3].answer === '$4,000' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_1">
                          $4,000
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="$4,000" id="4_1" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="4_2">
                          $4,400
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="$4,400" id="4_2" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer === '$7,600' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_3">
                          $7,600
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="$7,600" id="4_3" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[3].answer === '$8,000' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="4_4">
                          $8,000
                          <input type="radio" disabled={ questions[3].answer !== '' } name="equation" value="$8,000" id="4_4" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[4].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>5</span>
                  <p className={ classes.questionTitle }>
                    Which of the following best describes diversification when it comes to making investments?
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[4].answer === 'You make investments that are riskier, but also have the chance for higher returns in the long term' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_1">
                          You make investments that are riskier, but also have the chance for higher returns in the long term
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="You make investments that are riskier, but also have the chance for higher returns in the long term" id="5_1" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer === 'You make investments focused on preserving your investments and generating more predictable, consistent income' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_2">
                          You make investments focused on preserving your investments and generating more predictable, consistent income
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="You make investments focused on preserving your investments and generating more predictable, consistent income" id="5_2" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="5_3">
                          You invest in a basket of investments rather than one stock or one bond to lower your risk while gaining exposure to many different investments
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="You invest in a basket of investments rather than one stock or one bond to lower your risk while gaining exposure to many different investments" id="5_3" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[4].answer === 'Both A and C' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="5_4">
                          Both A and C
                          <input type="radio" disabled={ questions[4].answer !== '' } name="equation" value="Both A and C" id="5_4" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[5].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>6</span>
                  <p className={ classes.questionTitle }>
                    Which of the following is the main reason to use a high-yield savings account instead of a traditional savings account?
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[5].answer === "High-yield savings accounts don't require minimum balances" && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="6_1">
                          High-yield savings accounts don&apos;t require minimum balances
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="High-yield savings accounts don't require minimum balances" id="6_1" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer === "High-yield savings accounts are all online and don't charge fees" && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="6_2">
                          High-yield savings accounts are all online and don&apos;t charge fees
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="High-yield savings accounts are all online and don't charge fees" id="6_2" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer === 'High-yield savings accounts allow you to make an unlimited number of transactions a month' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="6_3">
                          High-yield savings accounts allow you to make an unlimited number of transactions a month
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="High-yield savings accounts allow you to make an unlimited number of transactions a month" id="6_3" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[5].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="6_4">
                          High-yield savings accounts pay up to 24x more interest to you than a traditional savings account
                          <input type="radio" disabled={ questions[5].answer !== '' } name="equation" value="High-yield savings accounts pay up to 24x more interest to you than a traditional savings account" id="6_4" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[6].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>7</span>
                  <p className={ classes.questionTitle }>
                    Student loan monthly payments are made up of two parts: __________ and interest.
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[6].answer === 'Balance' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_1">
                          Balance
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Balance" id="7_1" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer === 'Capital' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_2">
                          Capital
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Capital" id="7_2" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="7_3">
                          Principal Repayment
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Principal Repayment" id="7_3" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[6].answer === 'Amortization' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="7_4">
                          Amortization
                          <input type="radio" disabled={ questions[6].answer !== '' } name="equation" value="Amortization" id="7_4" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>8</span>
                  <p className={ classes.questionTitle }>
                    Use the following details to calculate Mike&apos;s net worth:
                    {' '}
                    <i>DON&apos;T</i>
                    {' '}
                    make your payment, what happens next?
                  </p>
                  <div className={ classes.tableMike }>
                    <table>
                      <thead>
                        <th colSpan="2">Mike&apos;s Assets and Liabilities</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Car Loan</td>
                          <td>$5,000</td>
                        </tr>
                        <tr>
                          <td>Checking Account</td>
                          <td>$5,000</td>
                        </tr>
                        <tr>
                          <td>High-Yield Savings Account</td>
                          <td>$5,000</td>
                        </tr>
                        <tr>
                          <td>Student Loans</td>
                          <td>$5,000</td>
                        </tr>
                        <tr>
                          <td>401(k) Retirement Account</td>
                          <td>$5,000</td>
                        </tr>
                        <tr>
                          <td>Credit Card Balance</td>
                          <td>$5,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[7].answer === '$128,500' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_1">
                          $128,500
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="$128,500" id="8_1" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer === '$99,500' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_2">
                          $99,500
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="$99,500" id="8_2" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer === '$138,500' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="8_3">
                          $138,500
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="$138,500" id="8_3" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[7].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="8_4">
                          $119,500
                          <input type="radio" disabled={ questions[7].answer !== '' } name="equation" value="$119,500" id="8_4" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[8].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>9</span>
                  <p className={ classes.questionTitle }>
                    The increase in the general level of prices for goods and services is called __________.
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[8].answer === 'Wage growth' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_1">
                          Wage growth
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Wage growth" id="9_1" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="9_2">
                          Inflation
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Inflation" id="9_2" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer === 'Price of goods growth' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_3">
                          Price of goods growth
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Price of goods growth" id="9_3" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[8].answer === 'Purchasing power' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="9_4">
                          Purchasing power
                          <input type="radio" disabled={ questions[8].answer !== '' } name="equation" value="Purchasing power" id="9_4" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[9].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>10</span>
                  <p className={ classes.questionTitle }>
                    What is the tax form that details exactly how much you earned at your job in the past year and how much you have already paid in taxes?
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[9].answer === '1040' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_1">
                          1040
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="1040" id="10_1" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer === '1099' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_2">
                          1099
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="1099" id="10_2" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer === 'W-4' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="10_3">
                          W-4
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="W-4" id="10_3" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[9].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="10_4">
                          W-2
                          <input type="radio" disabled={ questions[9].answer !== '' } name="equation" value="W-2" id="10_4" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[10].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>11</span>
                  <p className={ classes.questionTitle }>
                    What is a mutual fund?
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[10].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="11_1">
                          A pool of money given by investors that is actively managed by a fund manager
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="A pool of money given by investors that is actively managed by a fund manager" id="11_1" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer === 'A basket of financial assets that are not stocks, bonds, or cash' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_2">
                          A basket of financial assets that are not stocks, bonds, or cash
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="A basket of financial assets that are not stocks, bonds, or cash" id="11_2" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer === 'A loan you give to your local municipality with an interest rate and maturity date' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_3">
                          A loan you give to your local municipality with an interest rate and maturity date
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="A loan you give to your local municipality with an interest rate and maturity date" id="11_3" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[10].answer === 'A basket of different investments that is traded daily on a stock exchange' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="11_4">
                          A basket of different investments that is traded daily on a stock exchange
                          <input type="radio" disabled={ questions[10].answer !== '' } name="equation" value="A basket of different investments that is traded daily on a stock exchange" id="11_4" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={ classNames( classes.questionBlock ) } ref={ ( section ) => { questionMainAns[11].scroll = section; } }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>12</span>
                  <p className={ classes.questionTitle }>
                    True or False: Federal income taxes are calculated by taxing your entire income at one rate.
                  </p>
                  <ul className={ classes.optionList }>
                    <li className={ ( questions[11].answer === 'True' && classes.WrongAnswer ) }>
                      <p>
                        <label htmlFor="true12">
                          True
                          <input type="radio" name="equation" value="True" id="true12" disabled={ questions[11].answer !== '' } onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                    <li className={ ( questions[11].answer !== '' && classes.correctAnswer ) }>
                      <p>
                        <label htmlFor="false12">
                          False
                          <input type="radio" name="equation" value="False" id="false12" disabled={ questions[11].answer !== '' } onChange={ ( e ) => handleAnswer( e, 11 ) } />
                        </label>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div ref={ ( section ) => { questionMainAns[12].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>13</span>
                  <p className={ classes.questionTitle }>
                    How confident are you in managing your personal finances—budgeting and saving, making investments, repaying debt, and selecting insurance?
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[12].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 12 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[13].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>14</span>
                  <p className={ classes.questionTitle }>
                    How confident are you in achieving your financial life goals?
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[13].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 13 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[14].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>15</span>
                  <p className={ classes.questionTitle }>
                    How important do you feel personal finance skills and knowledge are to your future?
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` } role="menu">
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[14].answer === data && classes.activeClass } key={ data }>
                        <font role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 14 ) }>{data}</font>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>

              <div className={ classes.progressBar } id="progress-bar">
                <p>
                  {progress}
                  % complete
                </p>
                <div>
                  <span style={ { width: `${progress}%` } } />
                </div>
              </div>
              <div className={ classes.whatsMyScore } id="my-score-button">
                <Button onClick={ handleMyScore }>What&apos;s My Score?</Button>
              </div>
            </div>
          </div>
        </div>
        {myScoreContent === true && (
          <div ref={ ( section ) => { feedBackSection = section; } }>
            <div className={ classes.awernessScoreSec }>
              <Typography className={ classes.awernessTitle } variant="h2" component="h2">WHAT&apos;S MY SCORE?</Typography>
              <div className={ classes.container }>
                <div className={ classes.awernessBlock }>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } sm={ 4 }>
                      <div className={ classes.awernessBlockLeft }>
                        <Typography variant="h3" component="h3">YOUR SCORE:</Typography>
                        <span>
                          {avgScore.toFixed( 1 )}
                          %
                          <span>Correct</span>
                        </span>
                        <img src={ financeExplained } alt="Graph" />
                      </div>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 8 }>
                      <div className={ classes.awernessBlockRight }>
                        <div className={ classes.moneyfreeRow }>
                          <Typography variant="h3" component="h3">Get Smarter About Your Money for Free</Typography>
                          <p>Personal finance skills and knowledge are used every day when making big and small decisions. In Personal Finance 101, you will develop a financial life toolkit packed with skills and knowledge that you can implement in your own life. From budgeting, saving, and investing to understanding insurance and repaying loans, Personal Finance 101 will walk you through real life examples and demonstrate strategies for financial fitness.</p>
                          <p>Are you ready to get started?</p>
                          <Link href="/pf101/dashboard">
                            I WANT TO BE SMARTER ABOUT MY MONEY
                            {' >'}
                          </Link>
                        </div>

                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Pf101BaselineChallenge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Pf101BaselineChallenge );
