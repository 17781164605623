import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {  TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from '../../screens/pwi-investments/styles';
import InvestmentBottomThree from './Investment-bottomThree';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import MaxBalance from '../../assets/img/pwi-investments/max-balance.png';
// import converyIntoThousands from '../common/pwi/slider-functions';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];

const marksTwo = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 2,
    scaledValue: 2,
    label: '2%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 10,
    scaledValue: 10,
    label: '10%',
  },
];

const BeiginingMaxBalances = (props) => {
  const {
    classes,  getInvestmentObject, handleInvestmentData, setOpenWorkSheet
  } = props;
  const [begBalPercentage, setBegBalPercentage] = React.useState(getInvestmentObject.short_term_annual_contribution_growth_in_percentage === undefined ? 0 : getInvestmentObject.short_term_annual_contribution_growth_in_percentage);
  const [begBalValue, setBegBalValue] = React.useState(0);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState('');
  const [sliderValue, setSliderValue] = React.useState(getInvestmentObject.short_term_annual_expected_return_in_percentage !== undefined ? getInvestmentObject.short_term_annual_expected_return_in_percentage : 7);

  React.useEffect(() => {
    let bignningBalance = getInvestmentObject.short_term_annual_contribution_growth_in_percentage;
    var preamount = modulesServices.afterTaxIncomeInYearShortTerm();
    let value = (preamount / 100) * bignningBalance;
    let sValue = 0;
    if (value >= 0) {
      sValue = value;
    } else {
      sValue = 0;
    }
    setBegBalValue(sValue / 12);
  }, []);

  const updateValue = (e, value, field, type) => {
    const beginingBalanceObj = { ...getInvestmentObject };
    var preamount = modulesServices.afterTaxIncomeInYearShortTerm();
    if (type === 'slider') {
      let bignningBalance = begBalPercentage;
      if (bignningBalance > 0 && preamount > 0) {
        setBegBalValue(((preamount / 100) * bignningBalance) / 12);
      }
      setBegBalPercentage(value);
      beginingBalanceObj['short_term_annual_contribution_growth_in_percentage'] = value;
    } else if (type === 'number') {
      let Invalue = e.floatValue ? e.floatValue : 0;
      let bignningBalance = Invalue * 12;
      if (bignningBalance > 0 && preamount > 0) {
        setBegBalPercentage((bignningBalance / preamount) * 100);
        beginingBalanceObj['short_term_annual_contribution_growth_in_percentage'] = (bignningBalance / preamount) * 100;
      }else{
        setBegBalPercentage(0);
        beginingBalanceObj['short_term_annual_contribution_growth_in_percentage'] = 0;
      }
      setBegBalValue(Invalue);
    } else if (type === 'sliderTwo') {
      setSliderValue(value);
      beginingBalanceObj['short_term_annual_expected_return_in_percentage'] = value;
    } else if (type === 'numberTwo') {
      let Invalue = e.floatValue ? e.floatValue : 0;
      let ivalue = 0;
      if (Invalue >= 100) {
        ivalue = 100;
      } else {
        ivalue = Invalue
      }
      setSliderValue(ivalue);
      beginingBalanceObj['short_term_annual_expected_return_in_percentage'] = ivalue;
    }
    handleInvestmentData(beginingBalanceObj);
  };
  const emptyisFocus = () => {
    setIsFocus('');
  }
  return (
    <div className={classes.contentBlock}>
      <p>
        How much are you contributing to short-term investments as a percentage of after-tax income? Remember, once your short-term investments reach your max balance, the extra preamount will automatically be invested into long-term investments.
      </p>
      <p>How much do you expect to earn on short-term investments? Use the default value or go back to the types of short-term investments for more info.</p>
      <div className={classes.balanceBox}>
        <Typography variant="h3" component="h3">
          After-Tax Income in year {getInvestmentObject.short_term_start_year}&nbsp; : {''}
          <span className={modulesServices.module9Data.afterTaxIncomeInYearShortTerm < 0 ? classes.dangerText : ''}><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={modulesServices.module9Data.afterTaxIncomeInYearShortTerm ? modulesServices.module9Data.afterTaxIncomeInYearShortTerm : 0} prefix="$" /></span>
        </Typography>
      </div>
      <div className={classes.innnerFlowGroupMainFull}>
        <div className={`${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}`}>
          <div className={`${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}`}>
            <div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
              <h3>&nbsp;</h3>
              <div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}`}>
                <div>
                  <p><span>Contribution as a % of After-Tax Income</span></p>
                  <div className={classes.pwisliderInputs}>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      valueLabelFormat={percentFormatWithTwoDecimal}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={marks}
                      min={0}
                      max={20}
                      step={0.60}
                      value={begBalPercentage}
                      onChange={(e, value) => { updateValue(e, value, 'short_term_annual_contribution_growth_in_percentage', 'slider'); }}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
              <div>
                <h3>Monthly</h3>
                <div className={classes.inputRightBlock}>
                  <div className={classes.annualInput}>
                    $
                    <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={begBalValue} onValueChange={(e) => updateValue(e, '', 'short_term_annual_contribution', 'number')} onFocus={(e) => { e.target.select(); }} />
                  </div>
                </div>
              </div>
              <div className={classes.rightBoxNogap}>
                <h3>
                  Annual
                </h3>
                <div className={classes.inputRightBlock}>
                  {/* <NumberFormat allowNegative={false} fixedDecimalScale customInput={TextField} thousandSeparator value={begBalValue*12} /> */}
                  <NumberFormat displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={begBalValue * 12} prefix={'$'} />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.innnerFlowGroup}>
            <div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
              <div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}`}>
                <div>
                  <p><span>Annual Expected Pre-Tax Return</span></p>
                  <div className={classes.pwisliderInputs}>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      valueLabelFormat={percentFormatWithTwoDecimal}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={marksTwo}
                      min={0}
                      max={10}
                      step={0.1}
                      value={sliderValue}
                      onChange={(e, value) => { updateValue(e, value, 'short_term_annual_expected_return_in_percentage', 'sliderTwo'); }}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
              <div>
                <div className={classes.inputRightBlock}>
                  <div className={classes.annualInput}>
                    <NumberFormat
                      customInput={TextField}
                      decimalScale={isFocus!=='short_term_annual_expected_return_in_percentage'?2:5}
                      fixedDecimalScale={isFocus!=='short_term_annual_expected_return_in_percentage'}
                      value={sliderValue}
                      onValueChange={(e) => updateValue(e, '', 'short_term_annual_expected_return_in_percentage', 'numberTwo')}
                      onFocus={(e) => { e.target.select(); setIsFocus('short_term_annual_expected_return_in_percentage') }}
                      onBlur={emptyisFocus}
                    />
                    <span className={classes.percentSymbl}>%</span>
                  </div>
                </div>
              </div>
              <div className={classes.rightBoxNogap}>
                <div className={classes.inputRightBlock}>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={classes.lefBlock}>
          <div className={classes.lefSlider}>
            <div className={classes.sliderName}><span>Contribution as a % of After-Tax Income</span></div>
            <div className={classes.rzSlider}>
              <PwiSlider
                ValueLabelComponent={ValueLabelComponent}
                valueLabelFormat={percentFormatWithTwoDecimal}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={marks}
                min={0}
                max={20}
                step={0.60}
                value={begBalPercentage}
                onChange={(e, value) => { updateValue(e, value, 'short_term_annual_contribution_growth_in_percentage', 'slider'); }}
              />
            </div>
          </div>
        </div>
        <div className={classes.annualSlider}>
          <div className={classes.annualInput}>
            <span className={classes.dollarInput}>$</span>
            <NumberFormat allowNegative={false} fixedDecimalScale customInput={TextField} thousandSeparator value={begBalValue} onValueChange={(e) => updateValue(e, '', 'short_term_annual_contribution', 'number')} onFocus={(e) => { e.target.select(); }} />
          </div>
        </div>
        <div className={classes.annualSlider}>
          <div className={classes.annualInput}>
            <span className={classes.dollarInput}>$</span>
            <NumberFormat allowNegative={false} fixedDecimalScale customInput={TextField} thousandSeparator value={begBalValue*12} />
          </div>
        </div>
      </div> */}
      {/* <div className={classes.investSlider}>
        <div className={classes.lefBlock}>
          <div className={classes.lefSlider}>
            <div className={classes.sliderName}><span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return</span></div>
            <div className={classes.rzSlider}>
              <PwiSlider
                ValueLabelComponent={ValueLabelComponent}
                valueLabelFormat={percentFormatWithTwoDecimal}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={marksTwo}
                min={0}
                max={10}
                step={0.1}
                value={sliderValue}
                onChange={(e, value) => { updateValue(e, value, 'short_term_annual_expected_return_in_percentage', 'sliderTwo'); }}
              />
            </div>
          </div>
        </div>
        <div className={classes.annualSlider}>
          <div className={classes.annualInput}>
            <NumberFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale={!isFocus}
              value={sliderValue !== null ? sliderValue : 0}
              onValueChange={(e) => updateValue(e, '', 'short_term_annual_expected_return_in_percentage', 'numberTwo')}
              onFocus={(e) => { e.target.select(); setIsFocus(true) }}
              onBlur={() => { setIsFocus(false) }}
            />
            <span className={classes.percentSymbl}>%</span>
          </div>
        </div>
      </div> */}

      <div className={classes.borderDivider} />
      {getInvestmentObject &&
        <InvestmentBottomThree activeSlide={4} getInvestmentObject={getInvestmentObject} setOpenWorkSheet={setOpenWorkSheet} />
      }
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
      <Dialog
        open={popupOpen}
        onClose={() => { setPopupOpen(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.maxModule}
      >
        <DialogContent className={classes.maxModal}>
          <div className={classes.maxHeader}>
            <h3>MaxBalance</h3>
            <div className={classes.popupClose}>
              <span className={classes.maxClose} aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                ×
              </span>
            </div>
          </div>
          <div className={classes.maxModalBody}>
            <div>
              <img src={MaxBalance} alt="" />
            </div>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

BeiginingMaxBalances.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(BeiginingMaxBalances);
