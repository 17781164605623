import LeftArrow from '../../assets/img/testimonial-left-arrow.png';
import RightArrow from '../../assets/img/testimonial-right-arrow.png';
// import BannerImage from '../../assets/img/home_page/banner-home.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0',
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    '& video': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: ' 100%',
      border: 'none',
    },
  },
  canvasBanner:{
    margin: '0',
    padding: '0',
    overflow: 'hidden',
    position: 'relative',
    top: 'auto',
    '@media (max-width: 767px)': {
      display: 'none',
    },
    '& img':{
      objectFit: 'cover',
      zIndex: '3',
      bottom: '0px',
      top: '0px',
      right: '0',
      // marginTop: '13px',
      position: 'static',
      width: '100%',
      height: 'auto',
    }
  },
  canvasBannerMobile:{
    '@media (min-width: 768px)': {
      display: 'none',
    },
    '& img':{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }
  },
  bannerVideo: {
    overflow: 'hidden',
    // maxHeight: '540px',
    // position: 'relative',
    // backgroundImage: `url(${BannerImage})`,
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      position: 'relative',
    }
  },
  BannerVideoContent: {
    position: 'absolute',
    zIndex: '1',
    // background: 'rgba(0,0,0,0.1)',
    width: '100%',
    height: '100%',
    top: '50%',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // top: '50%',
    transform: 'translateY(-50%)',
    webkittransform: 'translateY(-50%)',
    mozTransform: 'translateY(-50%)',
    MsTransform: 'translateY(-50%)',
    OTransform: 'translateY(-50%)',
    '& > div':{
      '@media (max-width: 767px)': {
        padding: '0px 25px',
      }
    },
    '& h3': {
      fontSize: '3.75vw',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      margin: '0 0 30px 0',
      lineHeight: '75px',
      color: '#014372',
      '@media (min-width: 2000px)': {
        lineHeight: '5vw',
      }
    },
    '& p': {
      // fontSize: '1.875vw',
      fontSize: '3.5vw',
      margin: '0 0 50px',
      fontFamily: 'MuseoSans-100',
      color: '#014372',
      lineHeight: '36px',
      '@media (min-width: 2000px)': {
        lineHeight: '4vw',
      }
    },
    '& button': {
      background: theme.palette.common.green,
      borderColor: theme.palette.common.green,
      fontSize: '24px',
      lineHeight: '24px',
      padding: '18px 40px',
      color: theme.palette.common.white,
      borderRadius: '10px',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      marginTop: '28px',
      textTransform: 'uppercase',
      fontFamily: 'MuseoSans-300',
      '@media (min-width: 1400px) and (max-width: 2200px)': {
        margin: '5vw 0 0 0',
      },
      '& span': {
        paddingLeft: '15px',
      },
      '&:hover': {
        background: theme.palette.common.orange,
        borderColor: theme.palette.common.orange,
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& h3': {
        fontSize: '31px',
        lineHeight: '37px',
      },
      '& p': {
        fontSize: '23px',
        marginBottom: '18px',
      },
      '& button': {
        padding: '10px 40px',
        fontSize: '15px',
        marginTop: '0px',
        lineHeight: '23px',
        fontFamily: 'MuseoSans-500',
      },
    },
    '@media (max-width: 767px)': {
      transform: 'initial',
      webkittransform: 'initial',
      MozTransform: 'initial',
      MsTransform: 'initial',
      OTransform: 'initial', 
      top: '0',
      // '& h3': {
      //   textAlign: 'left',
      // }
    },
    '@media (min-width: 960px) and (max-width: 1023px)': {
      '& h3':{
        margin: '0 0 15px 0',
        lineHeight: '40px',
      },
      '& p':{
        margin: '0 0 40px',
      }
    }
    // '@media (min-width: 960px) and (max-width: 1023px)': {
    //   top: '40%',
    // }
  },
  whatIsAwareness: {
    marginTop: '-70px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '-40px',
      padding: '0px 20px',
    },
  },
  awarenessInner: {
    background: theme.palette.common.blue,
    minHeight: '100px',
    maxWidth: '780px',
    padding: '40px 0 40px 0px',
    textAlign: 'center',
    margin: '0 auto',
    color: theme.palette.common.white,
    position: 'relative',
    zIndex: '2',
    '@media (min-width: 1400px) and (max-width: 2200px)': {
      minWidth: '57vw',
    },
    '& h3':{
      '@media (max-width: 767px)': {
        fontSize: '22px',
      },
      '@media (min-width: 1400px) and (max-width: 2200px)': {
        fontSize: '2.6vw',
        marginTop: '20px',
        marginBottom: '10px',
      }
    },
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        lineHeight: '24px',
      },
      '@media (min-width: 1400px) and (max-width: 2200px)': {
        fontSize: '1.6vw',
        padding: '1vw 0px 4vw 0px',
        lineHeight: '1.42',
        margin: '0 0 10px',
      }
    },
    '& span': {
      fontSize: '24px',
      color: theme.palette.common.white,
      padding: '14px 28px',
      borderRadius: '10px',
      lineHeight: '24px',
      background: theme.palette.common.mediumBlue,
      borderColor: theme.palette.common.mediumBlue,
      boxShadow: 'none',
      border: '1px solid',
      cursor: 'pointer',
      outline: 'none',
      marginTop: '7px',
      textDecoration: 'none',
      display: 'inline-block',
      '& span': {
        paddingLeft: '15px',
      },
      '&:hover': {
        background: theme.palette.common.green,
        borderColor: theme.palette.common.green,
      },
      '@media (min-width: 1400px) and (max-width: 2200px)': {
        fontSize: '1.8vw',
        display: 'inline',
      }
    },
    [theme.breakpoints.down( 'sm' )]: {
      top: '0',
      padding: '50px 10px',
      '& span': {
        padding: '14px 15px',
        borderRadius: '10px',
        fontSize: '15px',
      },
    },
  },
  planWithInkwiry: {
    paddingBottom: '70px',
  },
  planInkwiryBox: {
    textAlign: 'center',
    fontSize: '18px',
    paddingBottom: '0',
    paddingTop: '15px',
    '& p': {
      padding: '0 26px',
      color: theme.palette.common.black,
    },
    '& span': {
      color: theme.palette.common.orange,
      fontFamily: 'MuseoSans-500',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.blue,
        textDecoration: 'underline',
      },
    },
    '& figure': {
      width: '100%',
      margin: '0',
    },
    '& img': {
      width: '100%',
      maxWidth: '250px',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  careerSketch: {
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      display: 'flex',
      flexWrap: 'wrap',
      '& li': {
        width: '14.285%',
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        '& figure': {
          width: '100%',
          display: 'flex',
          margin: '0',
          '& img': {
            width: '100%',
            height: '100%',
          },
        },
        '&:hover': {
          '& figure + div': {
            opacity: '1',
          },
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
    },
  },
  careerCaption: {
    position: 'absolute',
    top: '0',
    height: '100%',
    color: theme.palette.common.white,
    background: 'rgba(51, 51, 51, 0.65)',
    width: '100%',
    left: '0',
    textAlign: 'center',
    opacity: '0',
    display: 'flex',
    alignItems: 'center',
    transition: 'all ease-in-out 0.3s',
    '& > div': {
      padding: '15px',
      width: '100%',
    },
    '& h3': {
      fontSize: '24px',
      fontWeight: 'normal',
      lineHeight: '24px',
      margin: '0',
      '& span': {
        display: 'block',
        marginTop: '5px',
      },
    },
    '& span': {
      fontSize: '24px',
      marginTop: '15px',
      display: 'block',
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& h3': {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '20px',
        margin: '0',
      },
      '& span': {
        fontSize: '14px',
      },
    },

  },
  buildBox: {
    '& figure': {
      width: '100%',
      margin: '0',
      cursor: 'pointer',
    },
    '& img': {
      width: '100%',
      maxWidth: '250px',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& span': {
      color: '#fd9840',
      fontSize: '18px',
      margin: '10px 0 5px 0',
      padding: '0',
      fontFamily: 'MuseoSans-500',
      float: 'inherit',
      '&:hover': {
        color: '#0069aa',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  },
  buildTomorrow: {
    padding: '70px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '40px 0',
    },
    '& h1': {
      textAlign: 'center',
      marginBottom: '50px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      color: theme.palette.common.blue,
      '& sub': {
        fontSize: '11px',
        paddingLeft: '5px',
        top: '-2.3em',
        position: 'relative',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
      },
    },
    '& ul': {
      display: 'flex',
      padding: '0',
      listStyle: 'none',
      margin: '0 -15px',
      [theme.breakpoints.down( 'sm' )]: {
        display: 'block',
      },
      '& li': {
        width: '20%',
        padding: '0 10px',
        '& a': {
          color: theme.palette.common.orange,
          fontFamily: 'MuseoSans-500',
          textDecoration: 'none',
          fontSize: '18px',
          letterSpacing: '-0.5px',
          '&:hover': {
            color: theme.palette.common.blue,
            textDecoration: 'underline',
          },

        },
        [theme.breakpoints.down( 'sm' )]: {
          width: ' calc(100% - 100px)',
          marginBottom: '30px',
          padding: ' 0 50px',
          textAlign: 'center',
        },
      },
    },

  },
  containerNew: {
    maxWidth: '1250px',
    padding: '0 10px',
  },
  bluePrintBox: {
    width: '80%',
    margin: '0 auto 70px',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% + 30px)',
      margin: '0 -15px 0',
      '& img': {
        maxWidth: '100%',
        height: '350px',
        objectFit: 'cover',
      },
    },
  },
  bluePrintContent: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '100%',
    top: '0',
    color: theme.palette.common.white,
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    '& p': {
      fontSize: '25px',
      maxWidth: '600px',
      fontFamily: 'MuseoSans-100',
      margin: '10px auto 15px',
    },
    '& span': {
      cursor: 'pointer',
      fontSize: '23px',
      lineHeight: '24px',
      padding: '14px 28px',
      color: theme.palette.common.white,
      display: 'inline-block',
      borderRadius: '10px',
      margin: '28px 0 0 0',
      border: '2px solid',
      borderColor: theme.palette.common.white,
      textDecoration: 'none',
      '&:hover': {
        background: theme.palette.common.green,
        border: '2px solid',
        borderColor: theme.palette.common.green,
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& > div': {
        padding: '30px 15px',
      },
      '& h2': {
        fontSize: '28px',
      },
      '& p': {
        fontSize: '18px',
      },
      '& span': {
        fontSize: '18px',
        padding: '10px 20px',
        marginTop: '10px',
      },
    },
  },
  buildCareerSketch: {
    padding: '70px 0',
    background: theme.palette.common.mediumBlue,
    color: theme.palette.common.white,
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '18px',
      padding: '30px 15px',
    },
    '& h3': {
      fontSize: '36px',
      fontWeight: 'normal',
      '&:first-child': {
        fontFamily: 'MuseoSans-100',
      },
      '&:nth-child(2)': {
        fontFamily: 'MuseoSans-700',
        marginTop: '20px',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
      },
    },
    '& p': {
      fontSize: '24px',
      maxWidth: '850px',
      margin: '30px auto',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
      },
    },
    '& span': {
      cursor: 'pointer',
      fontSize: '23px',
      lineHeight: '24px',
      padding: '14px 28px',
      color: theme.palette.common.white,
      display: 'inline-block',
      borderRadius: '10px',
      margin: '28px 0 0 0',
      border: '2px solid',
      borderColor: theme.palette.common.white,
      textDecoration: 'none',
      minWidth: '213px',
      fontFamily: 'MuseoSans-500',
      '&:hover': {
        background: theme.palette.common.green,
        border: '2px solid',
        borderColor: theme.palette.common.green,
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        padding: '9px 15px',
      },
    },

  },
  testimonilas: {
    background: '#e5e5e5',
    padding: '80px 0 120px',
    fontSize: '24px',
    minHeight: '376px',
    position: 'relative',
    color: theme.palette.common.black,
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0 50px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '-5px',
    }
  },
  testimonialInner: {
    '&:focus': {
      outline: 'none',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      position: 'static',
      '& ul': {
        bottom: '100px',
        left: '0',
        [theme.breakpoints.down( 'xs' )]: {
          bottom: '20px',
        },
      },
      '& li': {
        top: '80px',
        margin: ' 0 1px',
        [theme.breakpoints.down( 'xs' )]: {
          top: '11px',
        },
        '& button': {
          background: '#fd9740',
          borderRadius: '50%',
          height: '13px',
          width: '13px',
          '&::before': {
            display: 'none',
          },
        },
      },
      '& button + div + button': {
        right: '10px',
        left: 'inherit',
        backgroundImage: `url(${RightArrow})`,
        [theme.breakpoints.down( 'xs' )]: {
          right: '-110px',
        },
        '&:hover, &:focus': {
          backgroundImage: `url(${RightArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      },
      '& button': {
        backgroundImage: `url(${LeftArrow})`,
        height: '80px',
        width: '80px',
        left: '10px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '0',
        backgroundSize: '40px',
        [theme.breakpoints.down( 'xs' )]: {
          left: '-110px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${LeftArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0',
          backgroundSize: '40px',
        },
        '&::before': {
          display: 'none',
        },
      },

    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      fontSize: '30px',
      position: 'relative',
      '& img': {
        display: 'inline-block',
        position: 'absolute',
        left: '-65px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '20px',
          left: '-30px',
        },
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      paddingBottom: '30px',
      marginBottom: '10px',
    },
    '& h5': {
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      textTransform: 'uppercase',
    },
    '& figure img': {
      width: '175px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& > div': {
        display: 'block',
      },
      '& li': {
        top: '11px',
      },
      '& h3': {
        fontSize: '20px',
      },
      '& p': {
        fontSize: '14px',
      },
      '& h5': {
        fontSize: '14px',
      },
      '& figure img': {
        margin: '0 auto',
      },
    },
  },
  tstimonialContent: {
    paddingLeft: '60px',
    paddingRight: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '50px',
    },
  },
  popupContent: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    zIndex: '99',
    transition: ' height ease-in-out 0.5s',
    height: '0',
    overflow: 'hidden',
    background: '#000',
    '& > div': {
      height: '100%',
      width: '100%',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '0',
          transition: ' height ease-in-out 0.5s',
        },
      },

    },
  },
  watchIntroPopup: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    '& > div': {
      height: '100%',
      maxWidth: '80%',
      margin: '0 auto',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '100%',
        },
      },

    },
  },
  closeIcon: {
    right: '10px',
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    top: '10px',
    color: theme.palette.common.orange,
    fontSize: '30px',
    cursor: 'pointer',
    outLine: 'none',
  },
  tooltipInfo: {
    width: '200px',
    background: '#0069aa !important',
    fontSize: '14px',
    fontWeight: '500',
    padding: '7px',
    textAlign: 'initial',
  },
  infoIcon: {
    '& img': {
      filter: 'brightness(0) invert(1)',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  jamesMessage: {
    position: "fixed",
    bottom: "0",
    background: "#014372",
    right: "20px",
    color: "#fff",
    borderRadius: "20px 20px 0px 0px",
    padding: "30px 20px",
    zIndex: "3",
    maxWidth: "calc(360px - 40px)",
    textAlign: "center",
    '@media (max-width: 399px)': {
      left: '10px',
    },
    '@media (max-width: 479px)': {
      right: "10px",
      maxWidth: "calc(360px - 20px)",
      padding: "30px 10px",
    },
    '& p': {
      fontSize: '18px',
      fontFamily: '"MuseoSans-300"',
      marginBottom: '10px',
      lineHeight: '1.42857143',
      '& span': {
        display: 'inline-block',
        width: '100%',
      }
    },
  },
  messageSec: {
    position: 'relative',
    marginBottom: '45px',
  },
  ceoImage: {
    position: 'absolute',
    border: '3px solid #fff',
    borderRadius: '50%',
    top: '-98px',
    left: '105px',
    '@media (max-width: 374px)': {
      left: '80px',
    },
    '& img': {
      width: '100%',
      position: 'relative',
      top: '0px',
      verticalAlign: 'middle',
    },
  },
  messageButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 0',
    // justifyContent: 'center',
  },
  watchVideoBtn: {
    '& button': {
      color: '#014372',
      display: 'table',
      textTransform: 'uppercase',
      backgroundColor: '#fff',
      border: 'none',
      cursor: 'pointer',
      padding: '14px 15px',
      borderRadius: '15px',
      outline: '0',
      fontFamily: '"MuseoSans-500"',
      fontSize: '16px!important',
      '&:hover':{
        backgroundColor: '#1f96e0',
        color: '#fff',
      },
      '@media (max-width: 374px)': {
        fontSize: '14px!important',
      }
    }
  },
  videoMargin: {
    marginRight: '15px',
  },
  noThanksBtn: {
    marginTop: '20px',
    textAlign: 'center',
    width: '100%',
    '& a': {
      cursor: 'pointer',
      padding: '14px 15px',
      outline: '0',
      textDecoration: 'none',
      color: '#fff',
      fontSize: '17px!important',
      '&:hover':{
        textDecoration: 'underline',
      }

    }
  },
} );

export default styles;
