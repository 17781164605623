import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import BuyingCarTableBottom from './buying-cat-table-bottom';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../calculations/modules-services';

const FirstBuyingSalesTaxDocumentationFee = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, activeStep, handleUpdatedObject,
  } = props;
  const [basePrice, setBasePrice] = React.useState( 0 );
  const [destination, setDestination] = React.useState( 0 );
  const [options, setOptions] = React.useState( 0 );
  const [salesTax, setSalesInput] = React.useState( ( moduleServices.module12Data.adjustedPurchasePrice * ( basePrice / 100 ) ) );

  const DiscountArray = sliderValues.interestCarIncome();
  DiscountArray[0].label = '0% of PurchasePrice';

  useEffect( () => {
    const documentationObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.sales_tax_in_percentage === undefined ) {
      documentationObj.sales_tax_in_percentage = 5;
      setBasePrice( 5 );
    }else {
      setBasePrice( handleBuyingCarObject.sales_tax_in_percentage );
      setSalesInput(( moduleServices.module12Data.adjustedPurchasePrice * ( handleBuyingCarObject.sales_tax_in_percentage / 100 ) ))
    }
    if ( handleBuyingCarObject.registration_fees === undefined ) {
      documentationObj.registration_fees = 200;
      setDestination( 200 );
    }else {
      setDestination( handleBuyingCarObject.registration_fees );
    }
    if ( handleBuyingCarObject.documentation_fees === undefined ) {
      documentationObj.documentation_fees = 50;
      setOptions( 50 );
    }else {
      setOptions( handleBuyingCarObject.documentation_fees );
    }
    handleUpdatedObject( documentationObj );
    // eslint-disable-next-line
  }, []);

  const handleBasePriceSlider = ( event, newValue ) => {
    setBasePrice( newValue );
    setSalesInput( moduleServices.module12Data.adjustedPurchasePrice * ( newValue / 100 ) );
    handleBuyingCarObject.sales_tax_in_percentage = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleBasePriceInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setSalesInput( event.floatValue === undefined ? 0 : event.floatValue );
    setBasePrice( updatedValue > 0 ? ( ( updatedValue * 100 ) / moduleServices.module12Data.adjustedPurchasePrice ) : 0 );
    handleBuyingCarObject.sales_tax_in_percentage = updatedValue > 0 ? ( ( updatedValue * 100 ) / moduleServices.module12Data.adjustedPurchasePrice ) : 0;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestinationSlider = ( event, newValue ) => {
    setDestination( newValue );
    handleBuyingCarObject.registration_fees = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestInput = ( event ) => {
    setDestination( event.floatValue );
    handleBuyingCarObject.registration_fees = event.floatValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleOptionsSlider = ( event, newValue ) => {
    setOptions( newValue );
    handleBuyingCarObject.documentation_fees = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleOptionsInput = ( event ) => {
    setOptions( event.floatValue );
    handleBuyingCarObject.documentation_fees = event.floatValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        On your car purchase, your state and sometimes city will charge you sales tax. Most states and cities charge different sales taxes. You can find your sales tax
        {' '}
        {' '}
        <a target="_blank" rel="noopener noreferrer" href="https://taxfoundation.org/sales-tax-rates-2019/">here</a>
        . The average state and local sales tax is roughly 7%, according to the Tax Foundation.
      </p>
      <p>
        You have to register your vehicle with your state when you make the purchase. The dealer does this for you and will charge you a few hundred-dollar fee to process this called
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Registration Fees' ) }>registration fees</span>
        .
      </p>
      <p>
        The dealer will also charge you
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Documentation Fees' ) }>documentation fees</span>
        {' '}
        for processing the paperwork for completing the transaction. These fees are typically $100 to $150.
      </p>
      <div className={ classes.priceSliders }>
        <div className={ classes.priceText }>
          <p>&nbsp;</p>
        </div>
        <div className={ classes.slideGraphNew }>
          <p><b>Net Purchase Price</b></p>
        </div>
        <div className={ classes.priceValue }>
          <span className={ classes.inputValue }>
           {moduleServices.module12Data.adjustedPurchasePrice !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.adjustedPurchasePrice } prefix={'$'} />
                  ) : ( '$0' )}
           
          </span>
        </div>
      </div>

      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Sales Tax' ) }>Sales Tax</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ DiscountArray }
                  min={ 0 }
                  max={ 14 }
                  step={ 0.01 }
                  value={ basePrice }
                  valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( basePrice ).toFixed( 2 ) )}` }
                  onChange={ handleBasePriceSlider }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ parseInt( salesTax, 10 ) } onValueChange={ handleBasePriceInput } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Registration Fees' ) }>Registration Fees</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.RegistartionArray() }
                  min={ 0 }
                  max={ 350 }
                  step={ 10 }
                  value={ destination }
                  valueLabelFormat={ `${commonFunctions.numFormatter( destination )}` }
                  onChange={ handleDestinationSlider }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ destination === '' ? 0 : destination } onValueChange={ handleDestInput } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Documentation Fees' ) }>Documentation Fees</span></p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ sliderValues.RegistartionArray() }
              min={ 0 }
              max={ 350 }
              step={ 10 }
              value={ options }
              valueLabelFormat={ `${commonFunctions.numFormatter( options )}` }
              onChange={ handleOptionsSlider }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            $
            <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ options === '' ? 0 : options } onValueChange={ handleOptionsInput } onFocus={ ( e ) => { e.target.select(); } } />
          </div>
        </div>
      </div>
        </div>
      </div>
      <div className={ classes.priceSliders }>
        <div className={ classes.priceText }>
          <p>&nbsp;</p>
        </div>
        <div className={ classes.slideGraphNew }>
          <p><b>Additional Buying Fees</b></p>
        </div>
        <div className={ classes.priceValue }>
          <span className={ classes.inputValue }>
            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ options + destination + salesTax} prefix={'$'} />
          </span>
        </div>
      </div>
      <div className={ classes.borderDivider } />
      <BuyingCarTableBottom handleBuyingCarObject={ handleBuyingCarObject } activeStep={ activeStep } />

      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

FirstBuyingSalesTaxDocumentationFee.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default withStyles( styles )( FirstBuyingSalesTaxDocumentationFee );
