import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const RiskReward = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Risk v Reward</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/risk-reward.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Risk v Reward
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/risk-reward-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          There are always going to be risks in investing. Risk
                          is the chance of you losing some or all your
                          investment. Reward is the amount of money you expect
                          to make on your investment. All investments require
                          taking risk so before making any investment, make sure
                          that you can handle the risk and that the
                          risk-to-reward makes sense.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Investing is a powerful tool to build wealth and
                          achieve financial freedom faster. Investing is
                          committing money today to buy assets with the
                          expectation of getting more money back in the future
                          by selling those assets or earning income on those
                          assets.
                        </p>
                        <p>
                          But how much risk is appropriate? Why wouldn&apos;t I
                          always take more risk if the potential reward is
                          greater? Go big or go home - right? It&apos;s a little
                          more complicated than that.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <strong>
                            Investing for the long-term versus short-term
                          </strong>
                        </p>
                        <p className={classes.noMargin}>
                          Let&apos;s look at two scenarios and determine how
                          much risk would be appropriate in each scenario. Each
                          person has $50,000 saved and is looking to invest for
                          different reasons.
                        </p>
                        <ol className={classes.noTopMargin}>
                          <li>
                            &nbsp;Brian is planning to attend graduate school in
                            three years
                          </li>
                          <li>
                            &nbsp;Sara is planning for retirement in 30 years
                          </li>
                        </ol>
                        <p>
                          Who would take more risk? Sara would because she
                          doesn&apos;t need her money back for another 30 years!
                          If Sara invested all her money in stocks and, the
                          following day, the value of those stocks dropped by
                          40%, she would have 29 years and 364 days to recover.
                          Over the last 100 years, stocks have{' '}
                          <a
                            href="https://www.nerdwallet.com/article/investing/average-stock-market-return"
                            target="_blank"
                            rel="noopener noreferrer">
                            {' '}
                            returned about 10% on average
                          </a>{' '}
                          per year. Sara would recover in time.
                        </p>
                        <p>
                          However, Brian needs his money in three years for
                          graduate school. If he invested in stocks and, the
                          following day, the value of those stocks dropped by
                          40%, he has much less time to make up those losses.
                          Since he needs this money in the near future, he
                          should take less risk and look to preserve his money.
                        </p>
                        <p>
                          Another good investing guideline is to understand the
                          time aspect of your investing strategy. What&apos;s
                          the goal? Is that goal one year, five years, or 20
                          years away? The longer the time, the more risk you can
                          take because you have more time to recover.
                        </p>
                        <p>
                          What are you saving and investing for? How much risk
                          are you taking? Does the risk you&apos;re taking
                          correlate to your time horizon?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
RiskReward.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RiskReward);
