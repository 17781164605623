import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter, Link } from 'react-router-dom';
import styles from './styles';
import _ from 'lodash';
import PwiSave from '../../components/pwi-save/pwi-save';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/pwi-rei/rei-slider2.jpg';
import buyingHomeLoanPoints from '../../assets/img/pwi-rei/buying-a-home-loanpoints.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import ReiWorksheetTable from '../../components/documents/worksheet-tables/rei-worksheet-table';
import * as moduleServices from '../../calculations/modules-services';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { defaultValues } from '../../calculations/default-module-values';
import alertIcon from '../../assets/img/alert_icon.png';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import * as reiGraphData from '../../graph-services/rei-show-me';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import WalkThruTable from './wallk-thru/walkThruTable';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

const monthRange = [];
for (let t = 0; t <= 30; t += 1) {
  monthRange.push({ value: t, label: t });
}

const percentageInputs = ['down_payment_in_percentage', 'transfer_taxes_in_percentage', 'title_insurance', 'property_taxes_in_percentage', 'rate_of_mortgage_prcentage', 'points_of_mortgage', 'origination_fees', 'vacancy_rate_in_percentage', 'annual_income_growth_rate', 'yearly_maintanance_percantage', 'managemtnt_fee_in_percentage', 'home_owners_insurance_in_percentage', 'appreciation_in_percentage', 'selling_costs_in_percentage'];

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const REIWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedREIValues, setUpdatedREIValues] = useState(defaultValues.module14);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imagePopup, setImagePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = useState(true);
  const [yearRange, setYearRange] = React.useState([]);
  const [saleYear, setSaleYear] = useState([{ value: 0, label: 0 }]);
  const [openPopup, setOpenPopup] = React.useState('');
  const [openInputPopupStatus, setOpenInputPopupStatus] = React.useState({ rate_of_mortgage_prcentage: false });
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [showMeData, setShowMeData] = React.useState({});
  const [openWorkSheet, setOpenWorkSheet] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [total, setTotal] = useState(0);
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(8);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module14' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedREIValues(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
          setUpdatedREIValues(defaultValues.module14);
        } else {
          setActiveScenarioData({});
          setUpdatedREIValues(defaultValues.module14);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedREIValues(defaultValues.module14);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(() => {
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module14' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }
  }, [loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module14') {
      dropdownvalues.savedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 });
    }
  }, [myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(reiGraphData.reiShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top' });
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleImagePopup = (url) => {
    setImagePopup(!imagePopup);
    setImageURL(url);
  };

  const handleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
    if (field === 'rate_of_mortgage_prcentage') {
      let text = '';
      const popmin = 3;
      const popmax = 8;
      if (openInputPopupStatus[field] === false) {
        const inputPopupStatus = { ...openInputPopupStatus };
        inputPopupStatus[field] = true;
        setOpenInputPopupStatus(inputPopupStatus);
        text = 'Mortgage loan interest rates typically vary from 3 - 8%. Make sure you can support an interest rate outside these boundaries with research.';
      }
      const tempValue = updatedREIValues[field];
      if (tempValue > 0 && text !== '' && (tempValue < popmin || tempValue > popmax)) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.redoPopup}>
              <div className={classes.redoPopupNew}>
                <h3>Important Tip</h3>
                <div className={classes.NoFiDiv}>
                  <p className={classes.zeroLeftPadding}>{text}</p>
                </div>
              </div>
              <div className={classes.buttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button className={classes.hideBtn} type="button" onClick={onClose}>OK</button>
              </div>
            </div>
          ),
        });
      }
    }
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedREIValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if (valuesUpdated[field] > 100 && percentageInputs.indexOf(field) >= 0) {
        valuesUpdated[field] = 100;
      }
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      valuesUpdated[field] = e.value;
      if (field === 'year_of_purchase') {
        const year = [{ value: 0, label: 0 }];
        if (e.value > 0) {
          const count = parseInt(e.value, 10) + 30;
          for (let i = e.value; i <= count; i += 1) {
            year.push({ value: i, label: i });
          }
        }
        setSaleYear(year);
      }
    }
    setUpdatedREIValues(valuesUpdated);
    setTotal(valuesUpdated.closing_appraisal_fee + valuesUpdated.closing_credit_report_fee + valuesUpdated.closing_escrow_account + valuesUpdated.closing_flood_determination + valuesUpdated.closing_tax_service_fee + valuesUpdated.closing_recording_mortgage + valuesUpdated.closing_processing_fee + valuesUpdated.closing_underwriting_fee + valuesUpdated.closing_pest_Inspection + valuesUpdated.closing_home_owners_association_transfer_fees + valuesUpdated.closing_costs_others)
    moduleServices.setModule14Data(valuesUpdated);
  };

  const handleClosingCostReset = () => {
    const valuesUpdated = { ...updatedREIValues };
    valuesUpdated.closing_appraisal_fee = 0;
    valuesUpdated.closing_credit_report_fee = 0;
    valuesUpdated.closing_escrow_account = 0;
    valuesUpdated.closing_flood_determination = 0;
    valuesUpdated.closing_tax_service_fee = 0;
    valuesUpdated.closing_recording_mortgage = 0;
    valuesUpdated.closing_processing_fee = 0;
    valuesUpdated.closing_underwriting_fee = 0;
    valuesUpdated.closing_pest_Inspection = 0;
    valuesUpdated.closing_home_owners_association_transfer_fees = 0;
    valuesUpdated.closing_costs_others = 0;
    setUpdatedREIValues(valuesUpdated);
  }

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module14';
    objectParams.moduleNumber = 'module14Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module14";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module14', 'quick-save', activeScenarioData);
      finalObject['module14Data'] = pwiObject.getfilteredBuyingReiObject(moduleServices.module14Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -150, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(7);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  };

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -160, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(7);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 13) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };


  if (backgroundBlur) {
    document.body.classList.add('pwiAnimation');
  } else {
    document.body.classList.remove('pwiAnimation');
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >

      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 13 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.reiSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>

                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying an Investment Property
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your real estate investment details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>Enter My Start Year</span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying an Investment Property
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Explore Real Estate
                              </Typography>
                              <ul>
                                <li>Enter your property information</li>
                                <li>Estimate your down payment and mortgage</li>
                                <li>See how your investment pencils out</li>
                                <li>Adjust your numbers to reduce risk and maximize reward</li>
                                <li>Compare different scenarios to find your best option</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>GET STARTED</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module14" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/rei" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.reiWalkThru1) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Buying a Real Estate Investment Property</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_of_purchase', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedREIValues.year_of_purchase, value: updatedREIValues.year_of_purchase }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to buy a Real Estate Investment Property. Choose different years and see what kind of home you can afford by looking at your Total Cash Available and Other Liquid Assets Available. Check out Boost My Awareness section for important info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>Years</div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Sale Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_of_sale', 'select'); }}
                                              options={saleYear}
                                              value={{ label: updatedREIValues.year_of_sale, value: updatedREIValues.year_of_sale }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What type of Real Estate Investment Property (REI) do you want? Will there be 1, 2, or 3 units? The price of your REI should depend on what you can afford. Use the Boost My Awareness section to find how much your future REI might cost.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {updatedREIValues.year_of_purchase !== 0 && updatedREIValues.year_of_sale !== 0 ? updatedREIValues.year_of_sale - updatedREIValues.year_of_purchase + 1 : 0}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Price</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedREIValues.purchase_price} onValueChange={(e) => updateValue(e, 'purchase_price', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What type of Real Estate Investment Property (REI) do you want? Will there be 1, 2, or 3 units? The price of your REI should depend on what you can afford. Use the Boost My Awareness section to find how much your future REI might cost.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.down_payment_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'down_payment_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='down_payment_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='down_payment_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('down_payment_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('down_payment_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="To get approved for a mortgage on an REI Property, you typically have to put 20% down so this input must be greater than 20%. Down Payment is the cash portion of the Purchase Price that is paid at close. Tweak this input and see your returns change."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstatedownPaymentCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatedownPaymentCalcs} prefix={moduleServices.module14Data.realEstatedownPaymentCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatedownPaymentCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Financed Amount</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstateFinancedAmountVal !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateFinancedAmountVal} prefix={moduleServices.module14Data.realEstateFinancedAmountVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateFinancedAmountVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transfer Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.transfer_taxes_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'transfer_taxes_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='transfer_taxes_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='transfer_taxes_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('transfer_taxes_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('transfer_taxes_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Fee paid for the transfer of the title of real estate to states, counties, and even some municipalities. In other words, you get charged a percentage based fee for changing the ownership. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstateTransferTaxesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateTransferTaxesCalcs} prefix={moduleServices.module14Data.realEstateTransferTaxesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateTransferTaxesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Title Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.title_insurance}
                                            onValueChange={(e) => updateValue(e, 'title_insurance', 'number')}
                                            decimalScale = { isFocus!=='title_insurance'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='title_insurance'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('title_insurance'); }}
                                            onBlur={() => { setFixedDecimal('title_insurance'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee that protects you, as the buyer, and the lender from any problems with the title transfer process. You typically need two policies: owner's policy and lender's policy. Cost varies by state. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.reiTitleInsuranceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiTitleInsuranceCalcs} prefix={moduleServices.module14Data.reiTitleInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiTitleInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.property_taxes_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'property_taxes_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='property_taxes_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='property_taxes_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('property_taxes_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('property_taxes_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Each year you will pay property taxes as a percentage of your REI Property's Value. Taxes vary by location, but typically range between 1-2%. See how REI Property Value impacts taxes on the Income Statement. Use Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstatePropertyTaxesCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatePropertyTaxesCalcs} prefix={moduleServices.module14Data.realEstatePropertyTaxesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatePropertyTaxesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Fit Out Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedREIValues.external_and_internal_fit_out_costs} onValueChange={(e) => updateValue(e, 'external_and_internal_fit_out_costs', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you buy real estate, there are additional costs such as furnshing the home or finishes in the kitchen among many more. Expect Fit Out Costs to run anywhere between 5-15% of the purchase price. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Property Value</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstateFitOutCostsCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateFitOutCostsCalcs} prefix={moduleServices.module14Data.realEstateFitOutCostsCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateFitOutCostsCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as purchase price plus fit out costs. Fit out costs are assumed to be costs that add to your property's value.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Real Estate Investment Property Mortgage</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Mortgage</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.mortage !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.mortage} prefix={moduleServices.module14Data.mortage >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.mortage < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as purchase price plus fit out costs. Fit out costs are assumed to be costs that add to your property's value.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>

                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'term_of_mortgage', 'select'); }}
                                              options={monthRange}
                                              value={{ label: updatedREIValues.term_of_mortgage, value: updatedREIValues.term_of_mortgage }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The # of years for repayment, typically 30 years for Mortgages. A shorter term will result in higher payments and vice versa holding all else equal. Check your Cash Flow Statement and see how quickly you can repay your mortgage.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.rate_of_mortgage_prcentage}
                                            onValueChange={(e) => updateValue(e, 'rate_of_mortgage_prcentage', 'number')}
                                            decimalScale = { isFocus!=='rate_of_mortgage_prcentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='rate_of_mortgage_prcentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('rate_of_mortgage_prcentage'); }}
                                            onBlur={() => { setFixedDecimal('rate_of_mortgage_prcentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The interest rate you plan to get on your mortgage. Change the interest rate to see the effects on Total Interest Paid. Use the Boost My Awareness section for more details such as current interest rates and how interest works."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Loan Points</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.points_of_mortgage}
                                            onValueChange={(e) => updateValue(e, 'points_of_mortgage', 'number')}
                                            decimalScale = { isFocus!=='points_of_mortgage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='points_of_mortgage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('points_of_mortgage'); }}
                                            onBlur={() => { setFixedDecimal('points_of_mortgage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender in exchange for a lower interest rate. Loan Points are calculated as a percentage of the mortgage. See the Boost My Awareness section for an example of how paying points could lead to savings over the life of the loan."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.loanPointsCalculation !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.loanPointsCalculation} prefix={moduleServices.module14Data.loanPointsCalculation >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.loanPointsCalculation < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.origination_fees}
                                            onValueChange={(e) => updateValue(e, 'origination_fees', 'number')}
                                            decimalScale = { isFocus!=='origination_fees'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='origination_fees'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('origination_fees'); }}
                                            onBlur={() => { setFixedDecimal('origination_fees'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender for administrative costs associated with your mortgage. Origination Fees are calculated as a percentage of the mortgage and are typically required for all mortgages. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.loanPointsCalculation !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.originationFeesCalculation} prefix={moduleServices.module14Data.originationFeesCalculation >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.originationFeesCalculation < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Monthly Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstateMonthlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateMonthlyPayment} prefix={moduleServices.module14Data.realEstateMonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateMonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Annual Paid (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstateYearlyPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateYearlyPayment} prefix={moduleServices.module14Data.realEstateYearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateYearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.totalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.totalInterest} prefix={moduleServices.module14Data.totalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.totalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.totalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.totalPrincipalInterest} prefix={moduleServices.module14Data.totalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.totalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Rental Income</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Monthly Rental Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedREIValues.yearly_rental_income} onValueChange={(e) => updateValue(e, 'yearly_rental_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When trying to determine your Annual Rental Income, you should look at what similar properties like the one you are planning to buy rents for. Check out the Boost My Awareness section for helpful tips in determining Annual Rental Income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Rental Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.annualRentalIncomeCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.annualRentalIncomeCalcs} prefix={moduleServices.module14Data.annualRentalIncomeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.annualRentalIncomeCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Vacancy Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.vacancy_rate_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'vacancy_rate_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='vacancy_rate_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='vacancy_rate_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('vacancy_rate_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('vacancy_rate_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The % of the year that you expect your REI Property to be vacant, which means you do not have a renter for that time. When vacant, you will earn no rental income. Typically, you would expect the property to be vacant at least 3% of the year."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.vacancyRateCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.vacancyRateCalcs} prefix={moduleServices.module14Data.vacancyRateCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.vacancyRateCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Adjusted Annual Rental Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstateAdjustedRentalIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateAdjustedRentalIncome} prefix={moduleServices.module14Data.realEstateAdjustedRentalIncome >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateAdjustedRentalIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Rental Income Growth</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.annual_income_growth_rate}
                                            onValueChange={(e) => updateValue(e, 'annual_income_growth_rate', 'number')}
                                            decimalScale = { isFocus!=='annual_income_growth_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='annual_income_growth_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('annual_income_growth_rate'); }}
                                            onBlur={() => { setFixedDecimal('annual_income_growth_rate'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Every year, your rental income should grow. See the effects various income growths can have on your returns by changing this input and checking your summary tables below. Check out the Boost My Awareness section for resources on rental income growth."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Rental Income Year</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.annualRentalIncomeGrowthRateCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.annualRentalIncomeGrowthRateCalcs} prefix={moduleServices.module14Data.annualRentalIncomeGrowthRateCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.annualRentalIncomeGrowthRateCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Expenses While Renting</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText} />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedREIValues.yearly_association_fees} onValueChange={(e) => updateValue(e, 'yearly_association_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Some residential communities charge Annual Association Fees for the general upkeep of the neighborhood or community. A company takes care of the lawn, trash, and so forth. Fees could range from $2,400 to $4,800 a year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        <NumberFormat value={updatedREIValues.yearly_association_fees} displayType="text" thousandSeparator prefix="$" />
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.yearly_maintanance_percantage}
                                            onValueChange={(e) => updateValue(e, 'yearly_maintanance_percantage', 'number')}
                                            decimalScale = { isFocus!=='yearly_maintanance_percantage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='yearly_maintanance_percantage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('yearly_maintanance_percantage'); }}
                                            onBlur={() => { setFixedDecimal('yearly_maintanance_percantage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Each year your REI Property will have some wear and tear and needed improvements. Plan for these costs with an Annual Maintenance expense based on a percentage of your Purchase Price. See Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstateYearlyMaintenanceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstateYearlyMaintenanceCalcs} prefix={moduleServices.module14Data.realEstateYearlyMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstateYearlyMaintenanceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Management Fee</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.managemtnt_fee_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'managemtnt_fee_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='managemtnt_fee_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='managemtnt_fee_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('managemtnt_fee_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('managemtnt_fee_in_percentage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="If you don't plan on managing the general upkeep of the property, you can pay a management company to work with the tenants and take care of the lawn, trash, and so forth. Fees could range from 4% - 10% of your Annual Rental Income."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.managementFeeCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.managementFeeCalcs} prefix={moduleServices.module14Data.managementFeeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.managementFeeCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Monthly Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedREIValues.monthly_utilities} onValueChange={(e) => updateValue(e, 'monthly_utilities', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you own a rental property, you may pay a small amount each month on Utilities for things such as common area lighting. Although the tenant normally pays for all Utilities, this input can be used to account for additional utility costs.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        <NumberFormat value={parseInt(updatedREIValues.monthly_utilities, 10) * 12} displayType="text" thousandSeparator prefix="$" />
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.home_owners_insurance_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'home_owners_insurance_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='home_owners_insurance_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='home_owners_insurance_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('home_owners_insurance_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('home_owners_insurance_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Just like owning a car, you need to have insurance on your important assets just in case of emergency. What if a tree falls on your property? Or a fire strikes? Homeowner's Insurance is a fee paid annually to protect against emergencies."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstatehomeownerInsuranceCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatehomeownerInsuranceCalcs} prefix={moduleServices.module14Data.realEstatehomeownerInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatehomeownerInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>0.00% </span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="This value is taken from the input above. To change this value, scroll up and update your property taxes."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.realEstatepropertyTaxesRentingVal !== undefined ? (
                                          <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatepropertyTaxesRentingVal} prefix={moduleServices.module14Data.realEstatepropertyTaxesRentingVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatepropertyTaxesRentingVal < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.totalyearlyExpensesVal !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.totalyearlyExpensesVal} prefix={moduleServices.module14Data.totalyearlyExpensesVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.totalyearlyExpensesVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Closing Costs</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Closing Costs</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {total !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={total} prefix={total >= 0 ? '$' : '($'} suffix={total < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Closing costs are calculated based on some of the inputs above and other costs. See the Show Table feature in the Show Me section below to view a detailed breakdown of closing costs. Closing costs must be paid to complete the purchase."
                                            />
                                          </div>
                                          <Button className={classes.editButton} onClick={handleEditPopup}>EDIT</Button>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Real Estate Investment Property - Purchase Analysis</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiPurchasePrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiPurchasePrice} prefix={moduleServices.module14Data.reiPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiDownPayment !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiDownPayment} prefix={moduleServices.module14Data.reiDownPayment >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiDownPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Closing Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {total !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={total} prefix={total >= 0 ? '$' : '($'} suffix={total < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Fit Out Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiFitOutCosts !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiFitOutCosts} prefix={moduleServices.module14Data.reiFitOutCosts >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiFitOutCosts < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed at Purchase</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstatecashNeededAtPurchase !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatecashNeededAtPurchase} prefix={moduleServices.module14Data.realEstatecashNeededAtPurchase >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatecashNeededAtPurchase < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Cash Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstatetotalCashAvailable !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatetotalCashAvailable} prefix={moduleServices.module14Data.realEstatetotalCashAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatetotalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Other Liquid Assets Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.realEstatetotalLiquid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.realEstatetotalLiquid} prefix={moduleServices.module14Data.realEstatetotalLiquid >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.realEstatetotalLiquid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Debt-to-Income Ratio</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.inputRight, classes.cashFlowGreen)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiDebt !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiDebt} prefix={moduleServices.module14Data.reiDebt >= 0 ? '' : '('} suffix={moduleServices.module14Data.reiDebt < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated by taking Total Debt Payments divided by Total Gross Income in a given year. The lower the ratio, the better the debt management state of an individual. A healthy ratio is no more than 36%. See the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.cashFlowGreen}>
                                    <div>
                                      <span>Cash Flow Positive</span>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Sale Price</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Appreciation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classNames(classes.formInput, classes.newInput)}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.appreciation_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'appreciation_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='appreciation_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='appreciation_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('appreciation_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('appreciation_in_percentage'); }}
                                          />
                                          <span className={classNames(classes.percentSymbol, classes.percentSign)}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect your REI to appreciate each year. Real Estate typically rises in value. The FC uses Annual Appreciation to grow the value of your REI Property every year. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.appreciationCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.appreciationCalcs} prefix={moduleServices.module14Data.appreciationCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.appreciationCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Sale Price in Year</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.salesPrice !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.salesPrice} prefix={moduleServices.module14Data.salesPrice >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.salesPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Selling Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classNames(classes.formInput, classes.newInput)}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedREIValues.selling_costs_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'selling_costs_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='selling_costs_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='selling_costs_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('selling_costs_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('selling_costs_in_percentage'); }}
                                          />
                                          <span className={classNames(classes.percentSymbol, classes.percentSign)}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Please specify, or estimate, what your selling costs will be at final sale. If you do not have to use a real estate agent, typical costs are around 2-3%. If you do have to use a real estate agent, typical costs are around 7-9%."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module14Data.sellingCostCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.sellingCostCalcs} prefix={moduleServices.module14Data.sellingCostCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.sellingCostCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Balance Remaining at Sale</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.mortgageBalanceRemaining !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.mortgageBalanceRemaining} prefix={moduleServices.module14Data.mortgageBalanceRemaining >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.mortgageBalanceRemaining < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Cash Flow from Sale (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.netCashFlowFromSale !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.netCashFlowFromSale} prefix={moduleServices.module14Data.netCashFlowFromSale >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.netCashFlowFromSale < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Calculated as Sale Price less Selling Costs less Mortgage Balance Remaining. Check the Sale on Real Estate Investment Property summary table to see possible tax implications of the sale.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        Real Estate Investment Property - Investment Analysis
                                        <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details.">
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Rental Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.investmentSummaryTotalRental !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.investmentSummaryTotalRental} prefix={moduleServices.module14Data.investmentSummaryTotalRental >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.investmentSummaryTotalRental < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Operating Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.investmentSummaryTotalPropertyVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.investmentSummaryTotalPropertyVal} prefix={moduleServices.module14Data.investmentSummaryTotalPropertyVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.investmentSummaryTotalPropertyVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Financing Costs (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.investmentSummaryTotalFinancingExpensesVal !== undefined ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                thousandSeparator
                                                value={moduleServices.module14Data.investmentSummaryTotalFinancingExpensesVal}
                                                prefix={moduleServices.module14Data.investmentSummaryTotalFinancingExpensesVal >= 0 ? '$' : '($'}
                                                suffix={moduleServices.module14Data.investmentSummaryTotalFinancingExpensesVal < 0 && ')'}
                                              />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Net Cash Flow</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.investmentSummaryNetRentalVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.investmentSummaryNetRentalVal} prefix={moduleServices.module14Data.investmentSummaryNetRentalVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.investmentSummaryNetRentalVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Cash Invested</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.totalCashInvested !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.totalCashInvested} prefix={moduleServices.module14Data.totalCashInvested >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.totalCashInvested < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Cash Returned</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.totalCashReturnedVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.totalCashReturnedVal} prefix={moduleServices.module14Data.totalCashReturnedVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.totalCashReturnedVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Return on Investment (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.ROICalVal !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.ROICalVal} prefix={moduleServices.module14Data.ROICalVal >= 0 ? '' : '('} suffix={moduleServices.module14Data.ROICalVal < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Multiple on Money (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.MoMCalVal !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.MoMCalVal} prefix={moduleServices.module14Data.MoMCalVal >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.MoMCalVal < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash-on-Cash Return (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.cashOnCashReturn !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.cashOnCashReturn} prefix={moduleServices.module14Data.cashOnCashReturn >= 0 ? '' : '('} suffix={moduleServices.module14Data.cashOnCashReturn < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Internal Rate of Return (before taxes)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.IRRCalVal !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.IRRCalVal} prefix={moduleServices.module14Data.IRRCalVal >= 0 ? '' : '('} suffix={moduleServices.module14Data.IRRCalVal < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Real Estate Investment Property - Monthly Cash Flow Statement</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Adjusted Rental Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.adjustedRentalIncome !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.adjustedRentalIncome} prefix={moduleServices.module14Data.adjustedRentalIncome >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.adjustedRentalIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.mortgagepaymentInterest !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.mortgagepaymentInterest} prefix={moduleServices.module14Data.mortgagepaymentInterest >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.mortgagepaymentInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.propertytaxesValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.propertytaxesValue} prefix={moduleServices.module14Data.propertytaxesValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.propertytaxesValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.homeOwnersInsuranceValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.homeOwnersInsuranceValue} prefix={moduleServices.module14Data.homeOwnersInsuranceValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.homeOwnersInsuranceValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>PITI (Principal, Interest, Taxes, & Insurance)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiPitiValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiPitiValue} prefix={moduleServices.module14Data.reiPitiValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiPitiValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Management Fee</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.managementValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.managementValue} prefix={moduleServices.module14Data.managementValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.managementValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.maintenanceValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.maintenanceValue} prefix={moduleServices.module14Data.maintenanceValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.maintenanceValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.associationFeesValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.associationFeesValue} prefix={moduleServices.module14Data.associationFeesValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.associationFeesValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.utilitiesExpenseValue !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.utilitiesExpenseValue} prefix={moduleServices.module14Data.utilitiesExpenseValue >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.utilitiesExpenseValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.reiTotalMonthlyExpenses !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.reiTotalMonthlyExpenses} prefix={moduleServices.module14Data.reiTotalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.reiTotalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Net Cash Flow</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.monthlyNetIncomeExpenses !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.monthlyNetIncomeExpenses} prefix={moduleServices.module14Data.monthlyNetIncomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.monthlyNetIncomeExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Sale on Real Estate Investment Property</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREISellingPrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREISellingPrice} prefix={moduleServices.module14Data.moreREISellingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREISellingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Selling Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREISellingCost !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREISellingCost} prefix={moduleServices.module14Data.moreREISellingCost >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREISellingCost < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Net Selling Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREINetSellingPrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREINetSellingPrice} prefix={moduleServices.module14Data.moreREINetSellingPrice >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREINetSellingPrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Purchase Price</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREIPurchasePrice !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREIPurchasePrice} prefix={moduleServices.module14Data.moreREIPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREIPurchasePrice < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Closing Costs, Fit Out Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREIClosingCost !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREIClosingCost} prefix={moduleServices.module14Data.moreREIClosingCost >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREIClosingCost < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Gain (loss) on Sale</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREIGain !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREIGain} prefix={moduleServices.module14Data.moreREIGain >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREIGain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Tax on Gain (15%)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREITaxCapital !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREITaxCapital} prefix={moduleServices.module14Data.moreREITaxCapital >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREITaxCapital < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Tax on $0 of Depreciation (25%)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module14Data.moreREITaxAccumulated !== undefined ? (<NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module14Data.moreREITaxAccumulated} prefix={moduleServices.module14Data.moreREITaxAccumulated >= 0 ? '$' : '($'} suffix={moduleServices.module14Data.moreREITaxAccumulated < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>

                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>

            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.reiWalkThru2) : classNames(classes.showMe, classes.showSection) && slidecount === 7 ? classNames(classes.showMe, classes.showMeScroll, classes.reiWalkThru3) : classNames(classes.showMe, classes.showSection)} id="slide-five">

              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : '' || slidecount === 7 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruTable walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                  <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                )}
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (<Button className={classNames(classes.graphButton, classes.graphSpace)} onClick={() => { setOpenWorkSheet(true); }}>Summary Worksheet</Button>)}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton}>Refresh</Button>)}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classNames(classes.graphButton, classes.graphSpace)} onClick={() => { setOpenWorkSheet(true); }}>Summary Worksheet</Button>)}
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Real Estate Investment Property: Income & Expenses Summary</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Property Value and Mortgage Repayment</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph3 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      {showMeData.advValues === 0 ? <span></span> : <div className={classes.graphBlock}>
                        <h3>Upfront Costs of Buying a Real Estate Investment Property</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph3Data}
                        />
                      </div>}
                      <div className={classes.totalValues}>Total Upfront Costs: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.advValues} prefix='$' /></div>
                    </div>
                  )}
                  {showMeData.graph4 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      {showMeData.advValues === 0 ? <span></span> : <div className={classes.graphBlock}>
                        <h3>Closing Costs Breakdown</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph4Data}
                        />

                      </div>}
                      <div className={classes.totalValues}>Total Closing Costs: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.advValues} prefix='$' /></div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Buying a Real Estate Investment Property
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Why does the purchase happen in the year before I inputted?</h3>
                          <p>The FC assumes that you purchase the property on the last day of the year before the Purchase Year. That way, the Purchase Year will be the start to the investment period or the year when income and expenses begin. For example, if you input 2029 as the Purchase Year, you will find on the Cash Flow Statement the Down Payment, Closing Costs, and Fit Out Costs of the property in year 2028.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Why can&apos;t I input less than 20% for Down Payment?</h3>
                          <p>Most banks require 20% down when buying a REI Property in order to receive approval for a mortgage. The FC limits that input field to 20% or greater for that reason.</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.investopedia.com/terms/h/hoa.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Annual Association Fees</a></h3>
                          <p>
                            Monthly fee that is paid by owners of certain types of residential properties for the general upkeep, maintenance, and improvement such as lawn care, snow removal, trash removal, and any other maintenance activities of common areas. This fee varies based off size and amenities of the property as well as the type and location of the community. However, Annual Association Fees
                            {' '}
                            <a href="http://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>usually range</a>
                            {' '}
                            from $200 to $400 a month or $2,400 to $4,800 per year! These fees are tax deductible - see how they reduce Net Rental Income (Pre-Tax) in the Show Me section by clicking Show Table.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Maintenance</h3>
                          <p>
                            The costs incurred to keep a property in good condition and good working order. Maintenance includes
                            {' '}
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>general repairs</a>
                            {' '}
                            such as fixing doors, leaky faucets, monthly cleaning service, lawncare and snow removal, maintaining paint, and can also include some major repairs such as replacing a refrigerator or roof. A good
                            {' '}
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer" className={classes.noLink}>rule of thumb</a>
                            {' '}
                            is to take 1-2% of the Purchase Price as an estimate for Annual Maintenance or $1-$2 for every square foot. These fees are tax deductible - see how they reduce Net Rental Income (Pre-Tax) in the Show Me section by clicking Show Table.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Management Fee</h3>
                          <p>
                            Fee paid for management companies to deal directly with prospects and tenants, collect rent, handle maintenance and repair issues, respond to tenant complaints, and even pursue evictions when needed. A
                            {' '}
                            <a href="https://www.thebalancesmb.com/should-you-hire-a-property-manager-2124811" target="_blank" rel="noopener noreferrer" className={classes.noLink}>typical management fee</a>
                            {' '}
                            could be anywhere from 4-10% of your Annual Rental Income. These fees are tax deductible - see how they reduce Net Rental Income (Pre-Tax) in the Show Me section by clicking Show Table.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Rental Income</h3>
                          <p>
                            The amount of rent charged if the property was occupied for a complete year. For example, if you expect to charge $1,500 per month, then the Annual Rental Income would be $18,000. A good rule of thumb when determining what you could charge in rent is the
                            {' '}
                            <a href="https://www.investopedia.com/terms/o/one-percent-rule.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>1% rule</a>
                            {' '}
                            - monthly rent should be at least 1% of the Mortgage. If you have a $250,000 property and you put 20% down, your Mortgage will be $200,000. Using the 1% rule, your monthly rent should be at least $2,000 and your Annual Rental Income would total to $24,000. However, the best way to determine what your rent could be is to look at
                            {' '}
                            <a href="http://www.zilpy.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>similar properties</a>
                            {' '}
                            in the area that you are looking to invest.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Rental Income Growth</h3>
                          <p>
                            Each year, the FC grows your rental income by the Annual Rental Income Growth rate. For example, if in 2018 you expect to make $50,000 in rental income and have 5% annual rental income growth, the FC will grow your income to $52,500 in 2019. Rental income growth is very important to managing a profitable real estate investment property and can very depending on size, use, location, and other factors.
                            {' '}
                            <a href="https://docs.google.com/spreadsheets/d/1eqCtTK0T0pSmTihu_ETUMKdzLdLMH3yZ12OaD_3tSnQ/edit#gid=0" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Click here</a>
                            {' '}
                            to see historical rental income growth rates in cities all over the U.S. to find a number that works for your property. Or,
                            {' '}
                            <a href="https://www.apartmentlist.com/rentonomics/rents-growing-fastest/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>use this link</a>
                            {' '}
                            to see a summary of that data.
                          </p>
                          <p>See how different growth rates can impact your returns on this investment property.</p>

                        </div>

                        <div className={classes.panelBlock}>
                          <h3><a href="http://www.investopedia.com/terms/v/vacancy-rate.asp" target="_blank" rel="noopener noreferrer">Annual Vacancy Rate</a></h3>
                          <p>The percentage of all available units in a rental property, such as a rental home or an apartment complex, that are expected to be unoccupied throughout the year. For example, it could take roughly a month to find a new tenant and could take a few weeks to make the appropriate repairs for that tenant. That entire time would be considered vacant, which means the property or rental unit is not earning rental income.</p>
                          <p>
                            The
                            {' '}
                            <a href="https://ycharts.com/indicators/us_rental_vacancy_rate" target="_blank" rel="noopener noreferrer">long-term average U.S. rental vacancy rate is 7.37%</a>
                            {' '}
                            . High vacancy rates indicate that the property is not renting well; low vacancy rates point to strong rental sales. However, the best estimate of vacancy rate would be to analyze the vacancy rate in the specific area you are looking to buy your property and use a similar number.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Appreciation</h3>
                          <p>
                            The increase in a property&apos;s value over time. How much the property appreciates each year depends on the local real estate market and any improvements made to the property. For example, a property that is purchased for $200,000.00 and appreciates at 2.5% annually would be worth $327,723.29 after 20 years. The
                            {' '}
                            <a href="https://www.cnbc.com/2017/06/23/how-much-housing-prices-have-risen-since-1940.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>median home value</a>
                            {' '}
                            {' '}
                            in the U.S. has gone from
                            {' '}
                            <a href="https://www.census.gov/hhes/www/housing/census/historic/values.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>$2,938 in 1940 to $119,600 in 2000</a>
                            {' '}
                            to $199,200 in 2017! That would put historical appreciation from 1940 to 2017 at a staggering 5.63%. Now history is not always the best indicator of the future, but that does give you a reference as to the history of housing prices in the U.S.
                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Cash-on-Cash Return (CoC)</h3>
                          <p>
                            A performance metric, most often used in real estate transactions, that calculates the cash earned in a year compared to the cash invested in a property shown as a percentage. Cash-on-Cash Return (CoC) allows you to evaluate the profitability of an investment and compare the profitability of multiple investments. CoC is different than the other
                            performance metrics because it calculates the return on an annual basis where ROI and MoM analyze the entire investment, not just one year. It&apos;s important to note that there are many other aspects to consider when weighing the risk to reward of a property such as location, size of investment, time horizon, and much more.
                          </p>
                          <p>In the FC, CoC is calculated for the first year of the real estate investment property:</p>
                          <p>CoC = Net Income in Year 1 / Cost of Investment</p>
                          <p>For example, you buy a property worth $500,000, invest $100,000 of your own capital, and fund the rest of the purchase with a mortgage. If net income for that property is $10,000 in the first year, your CoC would be 10%. Net income can be found in the Show Me section by using the Show Table button.</p>
                          <p>CoC = $10,000 Net Income / $100,000 Cost of Investment = 10%</p>
                          <p>CoC is a great way to compare investments as the performance metric shows you how much cash you are receiving each year relative to your investment. In the above example, the CoC shows that for every $1 invested in that property, $0.20 is being generated each year.</p>
                          <p>This performance metric can be used to evaluate any investment, not just real estate.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/c/closingcosts.asp#ixzz4jL19p7o2" target="_blank" rel="noopener noreferrer">Closing Costs</a>
                            {' '}
                          </h3>
                          <p>
                            Fees paid to third parties that help facilitate the sale of a home and can vary widely by location. These fees are paid at closing in cash. As a rule, you can estimate 2% to 7% of the home&apos;s purchase price in closing costs that must be paid to complete the purchase of the property. Check out these links by
                            {' '}
                            <a href="https://www.zillow.com/mortgage-learning/closing-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Zillow</a>
                            {' '}
                            and
                            {' '}
                            <a href="http://www.homeclosing101.org/the-closing-process/closing-costs-explained/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Homeclosing101</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/d/dti.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Debt-to-Income Ratio</a>
                            {' '}
                          </h3>
                          <p>
                            Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year.
                            This ratio indicates the percentage of gross income being accounted for debt repayment and the percentage of gross income left over for other household expenses and savings. You can find the debt-to-income ratio on the dashboard or on the summary financials.
                            {' '}
                          </p>
                          <p>The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Depreciation</h3>
                          <p>
                            The loss in the value of a building due to wear and tear, deterioration and age, that happens over its useful life. The useful life of a Rental Property is
                            {' '}
                            <a href="https://www.thebalance.com/depreciation-of-a-rental-property-2866809" target="_blank" rel="noopener noreferrer" className={classes.noLink}>27.5 years</a>
                            {' '}
                            and the FC assumes that the
                            {' '}
                            <a href="http://www.nolo.com/legal-encyclopedia/how-depreciate-investment-property-reduce-taxable-income.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Purchase Price plus any Fit Out Costs is the value of the building.</a>
                          </p>
                          <p>For example, if you have a $500,000 Purchase Price and $25,000 of Fit Out Costs, the depreciable value is $525,000. That $525,000 is divided by 27.5 years, which equals $19,091 of Depreciation every year until the property is sold.</p>
                          <p>Depreciation is tax deductible - see how they reduce Federal Taxable Income in the Deductions section on the Income Statement. However, when you sell the Rental Property, the Depreciation that you have accumulated will be taxed at 25%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.zillow.com/mortgage-learning/down-payments/" target="_blank" rel="noopener noreferrer">Down Payment</a>
                            {' '}
                          </h3>
                          <p>The amount of money you pay upfront to the seller expressed as a percentage of the Purchase Price. A bank or other financial institution covers the remainder of the Purchase Price through a Mortgage. The higher your Down Payment, the lower your monthly mortgage payment will be. Typically, you will need to save 5 to 20% of the Purchase Price in cash in order to qualify for a Mortgage.</p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.thebalance.com/how-escrow-relates-to-your-real-estate-transaction-1798810" target="_blank" rel="noopener noreferrer">Escrow Account</a>
                            {' '}
                          </h3>
                          <p>
                            During a real estate transaction, some funds are placed into what&apos;s called an escrow account. The escrow account is typically money, but can also be important documents such as the property&apos;s title, held by a third party to be used at a later date for a specific purpose. Some expenses such as property taxes, insurance, and mortgage payments are placed into an escrow account. Click

                            {' '}
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/housing/home-buying-process/v/types-of-escrow-in-real-estate" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            to learn more.

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Fit Out Costs</h3>
                          <p>The amount of money that you intend to spend on making the property suitable for living, which includes furniture, artwork, electronics, bathroom needs, or repairs. Fit Out Costs could range anywhere from 5-15% of the Purchase Price depending on the size and quality of the property and quality of products you want in the property.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/h/homeowners-insurance.asp#ixzz4jL52gtFj" target="_blank" rel="noopener noreferrer">Homeowner&apos;s Insurance</a>
                            {' '}
                          </h3>
                          <p>Property insurance that covers losses and damages to your house and to assets in the property. Homeowner&apos;s Insurance also provides liability coverage against accidents in or on the property. According to the Federal Reserve Bureau, Homeowner&apos;s Insurance can be estimated by multiplying the Purchase Price by 0.35%. These fees are tax deductible - see how they reduce Net Rental Income (Pre-Tax) in the Show Me section by clicking Show Table.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.investopedia.com/personal-finance/understanding-mortgage-payment-structure/" target="_blank" rel="noopener noreferrer">Interest</a>
                            {' '}
                          </h3>
                          <p>Monthly loan payments are typically made up of four parts: interest, principal repayment, taxes, and insurance, also known as PITI. In the FC, the monthly payment is broken up into only two parts, interest and principal repayment, while taxes and insurance are shown separately.</p>
                          <p>
                            Interest is a loan expense calculated as a percentage of the outstanding Principal. Interest is the cost of borrowing the Principal. The other portion is the principal repayment. See an example of a typical loan amortization schedule, or in other words, loan repayment schedule
                            {' '}
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer">here.</a>
                          </p>
                          <p>Interest is calculated by multiplying the Outstanding Balance by the Interest Rate. For example, let&apos;s say we have a $600,000 Mortgage outstanding with a 5% annual Interest Rate that we pay on a monthly basis. The calculation for next month&apos;s interest payment would be as follows:</p>
                          <p>$600,000 Balance x 5% Interest Rate / 12 (to make the interest rate on a monthly basis) = $2,500</p>
                          <p>Don&apos;t forget, as you make monthly payments and start paying down your loan, your outstanding balance will decrease, which will decrease your interest portion of the payment.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest Rate</a>
                            {' '}
                          </h3>
                          <p>
                            The amount a lender charges you for using their money, typically expressed as an annual percentage of the principal – loan amount outstanding. Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank, it’s very similar to renting an apartment.
                          </p>
                          <p>Instead of borrowing a place to live, you are borrowing money usually from a bank. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank uses the interest rate in the formula below:</p>
                          <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</p>
                          <p>
                            To learn more about how mortgage interest works,
                            {' '}
                            <a href="https://www.investopedia.com/mortgage/mortgage-rates/how-it-works/" target="_blank" rel="noopener noreferrer">click here.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/i/irr.asp#ixzz4jLNukakt" target="_blank" rel="noopener noreferrer">Internal Rate of Return (IRR)</a>
                            {' '}
                          </h3>
                          <p>
                            This metric allows you to evaluate and compare the profitability of potential investments while taking the time an investment is held into account - higher the IRR, the better the investment. A good way to think about IRR is the rate of growth an investment is expected to generate. While the actual rate of return that an investment ends up generating will likely differ from its estimated IRR,
                            a project with a substantially higher IRR than an alternative investment would provide a much better chance of strong growth. It&apos;s important to note that if the IRR is lower than the Interest Rate on the Mortgage used to finance that property, then that is most likely
                            {' '}
                            <a href="http://www.investopedia.com/terms/i/internal-rate-of-return-rule.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>not a good investment.</a>
                            {' '}
                            If the IRR is lower than the Interest Rate on the Mortgage, that means you expect to spend more money repaying your debt than you expect to earn on your investment.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.bankrate.com/glossary/d/discount-point/" target="_blank" rel="noopener noreferrer">Loan Points</a>
                            {' '}
                          </h3>
                          <p>
                            One-time fee paid to the lender in exchange for a lower interest rate. Loan points are calculated as a percentage of the mortgage. If you can afford to pay loan points and plan to own your home for longer than the breakeven period (see below),
                            the upfront fee could lead to savings over the life of the loan. If you own your home for longer than the breakeven period, which is calculated as the cost of your points divided by monthly savings, each month after that will be savings since, after that period, you have recovered the cost of your points. Let&apos;s look at an example.
                          </p>
                          <div aria-hidden="true" className={classNames(classes.heImage, classes.reImage)} onClick={() => handleImagePopup(buyingHomeLoanPoints)}>
                            <img src={buyingHomeLoanPoints} alt="" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Multiple of Money (MoM)</h3>
                          <p>
                            This metric allows you to evaluate and compare the profitability of potential investments - higher the MoM, the better the investment. Important to note that MoM does not take into account the time an investment is held. For example, if you buy a piece of real estate
                            for $100,000 all cash and in 5 years it returns $150,000 in cash, your MoM would be 1.5x. If you bought a different piece of real estate for $100,000 all cash and in 2 years it returns $150,000 in cash, your MoM would still be 1.5x. However, the second piece of real estate would be the better investment
                            as it returns your money to you sooner. It&apos;s good to use MoM as a quick proxy for returns, but IRR, since it takes into account time, will be a better comparative tool when analyzing multiple real estate investments.
                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/m/mortgage.asp" target="_blank" rel="noopener noreferrer">Mortgage</a>
                            {' '}
                          </h3>
                          <p>A loan that a bank or mortgage lender gives you to help finance the purchase of real estate that bears an Interest Rate and requires repayment. The Mortgage amount is calculated by taking the Purchase Price less the Down Payment.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Origination Fees</a>
                            {' '}
                          </h3>
                          <p>One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are calculated as a percentage of the loan and are typically required for all loans. For mortgages, origination fees are typically between 0.5% and 1.0%.</p>
                          <p>For example, if you have a $250,000 mortgage and 1.0% origination fees, the fee calculation would be 1.0% x $250,000 = $2,500</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Other Liquid Assets Available</h3>
                          <p>Calculated as Short-Term Investments plus Long-Term Investments at the end of the year before the Purchase Year. For example, if you plan to buy in year 2025, Other Liquid Assets Available would show you Short-Term and Long-Term Investments available at the end of year 2024.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/passiveloss.asp" target="_blank" rel="noopener noreferrer">Passive Losses</a>
                            {' '}
                          </h3>
                          <p>
                            A Passive Loss is created when you take a financial loss on your REI Property in any given year. When the deductions from your REI Property exceed the income, calculated as Net Rental Income less Mortgage Interest, Loan Points, and Depreciation, Passive Losses are created as your deductions cannot exceed your income.
                            However, you do not lose those tax advantages - they are carried forward to future years or to when you sell the property. In the FC, we do not apply Passive Losses to other income - only income from the REI Property. You can find Passive Losses on the Income Statement and a detailed breakdown in the Show Table of the Show Me section above.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/propertytax.asp#ixzz4jL4PM7gn" target="_blank" rel="noopener noreferrer">Property Taxes</a>
                            {' '}
                          </h3>
                          <p>
                            The tax
                            {' '}
                            <a href="http://www.investopedia.com/articles/tax/09/calculate-property-tax.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>assessed</a>
                            {' '}
                            {' '}
                            on real estate each year based on the value of the property you own. Your local or municipal government will assess the value of your property and determine the amount owed. The average property tax in the U.S. is
                            {' '}
                            {' '}
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>approximately 1.31%</a>
                            {' '}
                            {' '}
                            of the property&apos;s value. These fees are tax deductible - see how they reduce Net Rental Income (Pre-Tax) in the Show Me section by clicking Show Table.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Purchase Price</h3>
                          <p>The price that is paid to buy real estate excluding Closing Costs or Fit Out Costs.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Purchase Year</h3>
                          <p>Refers to the year you expect to purchase a Real Estate Investment Property. The FC assumes that you purchase the property on the last day of the year before the Purchase Year. That way, the Purchase Year will be the start to the investment period or the year when income and expenses begin. For example, if you input 2029 as the Purchase Year, you will find on the Cash Flow Statement the Down Payment, Closing Costs, and Fit Out Costs of the property in year 2028.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/r/returnoninvestment.asp" target="_blank" rel="noopener noreferrer">Return on Investment (ROI)</a>
                            {' '}
                          </h3>
                          <p>
                            Measures the amount of return on an investment relative to the investment&apos;s cost. To calculate ROI, the return of an investment is divided by the cost of the investment, and the result is expressed as a percentage or a ratio. For example, if you pay $500,000 for real estate, earn $5,000 per year for 5 years, and then sell it after 5 years for $525,000, the return is calculated by taking the difference between $500,000 and $550,000,
                            and then dividing that number by the cost of the investment, or $500,000. The calculation is $50,000 divided by $500,000, or 10%.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.nerdwallet.com/blog/mortgages/how-much-does-it-cost-to-sell-a-house/" target="_blank" rel="noopener noreferrer">Selling Costs</a>
                            {' '}
                          </h3>
                          <p>Selling Costs are Closing Costs that the seller pays to third parties that help facilitate the sale of a home, and they vary widely by location. The main difference between Closing Costs when buying versus selling is that the seller pays for the Real Estate Agent&apos;s Commission. This commission typically is around 5-6% of the sale price. A good estimate of Selling Costs would be 5-8% of the sale price.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/t/termtomaturity.asp" target="_blank" rel="noopener noreferrer">Term of the Loan</a>
                            {' '}
                          </h3>
                          <p>The length of time in years that you have to repay your loan. Mortgages are typically 15 or 30 years in length, but you can repay your loans early if you can afford to. Change your term of the loan and see how that affects total interest repaid. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.realtor.com/advice/buy/how-much-does-title-insurance-cost/" target="_blank" rel="noopener noreferrer">Title Insurance</a>
                            {' '}
                          </h3>
                          <p>One-time fee that protects you, as the buyer, and the lender from any problems with the title transfer process. You typically need two policies: owner&apos;s policy and lender&apos;s policy. Why do you need title insurance?</p>
                          <p>The home&apos;s title is arguably the most important document in the buying process. The document states who actually owns the house. If the title is somehow found to be invalid or fraudulent, that could lead to big issues.</p>
                          <p>To protect against this possibility, both the lender and the buyer typically purchase an insurance policy to cover the lender and the buyer in case the title is later found to be invalid or fraudulent. The cost varies, but a good estimate would be 0.5% - 1.0%, or a few hundred dollars to a few thousand dollars.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Principal & Interest</h3>
                          <p>Calculated by taking the principal, or total sum of money borrowed, plus the total interest, which is the total cost of borrowing the principal. In other words, the total principal and interest is the amount of money you would pay over the term of the loan to fully repay your credit card, student loan, car loan, or mortgage.</p>
                          <p>See the definitions for principal and interest above.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer">Transfer Taxes</a>
                            {' '}
                          </h3>
                          <p>
                            Taxes imposed by states, counties and municipalities on the transfer of the title of real estate from one individual to another. Transfer Taxes are more often paid by the seller, but can also be split between or entirely paid for by the buyer - Transfer Taxes typically become a negotiation point at sale. Transfer Taxes vary by location, but between 1-2% is a reasonable benchmark. Use this link
                            {' '}
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer">here</a>
                            {' '}
                            to check out transfer taxes by state.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.nationwide.com/average-cost-of-utilities.jsp" target="_blank" rel="noopener noreferrer">Utilities</a>
                            {' '}
                          </h3>
                          <p>The total price you pay for water, electricity and gas, trash and recycling, home phone, and internet and cable. Some or all utilities can be included in your rent when renting a home or an apartment. If you own real estate, you will be responsible for paying all utilities.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/v/vacancy-rate.asp" target="_blank" rel="noopener noreferrer">Vacancy Rate</a>
                            {' '}
                          </h3>
                          <p>
                            The percentage of all available units in a rental property, such as an apartment complex, that are expected to be unoccupied throughout the year. For example, it takes roughly a month to find a new tenant and could take a few weeks to make the appropriate repairs for that tenant. The
                            {' '}
                            <a href="https://ycharts.com/indicators/us_rental_vacancy_rate" target="_blank" rel="noopener noreferrer" className={classes.noLink}>long-term average U.S. rental vacancy rate is 7.37%</a>
                            . High vacancy rates indicate that the property is not renting well; low vacancy rates point to strong rental sales. However, the best estimate of Vacancy Rate would be to analyze the vacancy rate in the specific area you are looking to buy your property and use a similar number.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Buying and Selling Real Estate</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.apartments.com/blog/a-guide-to-the-different-types-of-rental-housing" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Types of rental properties</a>
                            <a href="https://www.zillow.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Find your new investment property with Zillow</a>
                            <a href="https://www.trulia.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Or find your new property with Trulia</a>
                            <a href="https://www.investopedia.com/articles/mortgages-real-estate/08/flipping-flip-properties.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Pros and cons of different real estate investing strategies</a>
                            <a href="https://www.zillow.com/mortgage-learning/closing-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Closing costs</a>
                            <a href="http://www.homeclosing101.org/the-closing-process/closing-costs-explained/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Closing costs v2</a>
                            <a href="https://smartasset.com/mortgage/how-to-reduce-closing-costs" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Some tips for saving on closing costs</a>
                            <a href="http://www.bankrate.com/finance/real-estate/how-much-does-it-cost-to-sell-house.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Selling costs</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Down Payment & Mortgage</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://www.bankrate.com/finance/real-estate/5-tips-for-financing-investment-property-1.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Down payment guidance and other financing tips</a>
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a mortgage get repaid? What is amortization?</a>
                            <a href="https://www.thebalance.com/what-is-an-amortization-table-315520" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a mortgage get repaid? What is amortization? What does an example repayment schedule look like?</a>
                            <a href="http://www.bankrate.com/national-mortgage-rates/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Current mortgage rates by state</a>
                            <a href="https://www.moneyunder30.com/percentage-income-mortgage-payments" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much mortgage payments can you afford</a>
                            <a href="https://www.moneyunder30.com/get-approved-for-a-mortgage" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to get approved for your first mortgage</a>
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>6 tips for getting approved for a mortgage</a>
                            <a href="https://www.bankrate.com/glossary/d/discount-point/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Loan points</a>
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Origination fees</a>
                            <a href="https://smartasset.com/credit-cards/what-is-a-good-debt-to-income-ratio" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Debt-to-Income ratio: Why it&apos;s important for buying real estate and financial health</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Rental Income</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://www.zilpy.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Use zilpy to look at trends for rental income, vacancies and more</a>
                            <a href="http://www.investopedia.com/terms/o/one-percent-rule.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>1% rule when conservatively estimating rental income</a>
                            <a href="http://www.investopedia.com/terms/v/vacancy-rate.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is a vacancy rate?</a>
                            <a href="https://ycharts.com/indicators/us_rental_vacancy_rate" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Long-term average U.S. rental vacancy rate is 7.37%</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Appreciation & Depreciation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://resources.point.com/8-biggest-factors-affect-real-estate-prices/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>8 factors that affect real estate prices</a>
                            <a href="https://www.zillow.com/home-values/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Appreciation data - search by state, city, and more</a>
                            <a href="https://www.thebalance.com/straight-line-depreciation-of-real-property-2867364" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How real estate depreciates</a>
                            <a href="http://www.nolo.com/legal-encyclopedia/how-depreciate-investment-property-reduce-taxable-income.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How investment property depreciation can reduce taxable income</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Expenses of Owning a Real Estate Investment Property</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://wallethub.com/edu/states-with-the-highest-and-lowest-property-taxes/11585/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Property taxes by state</a>
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Transfer taxes by state</a>
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average monthly costs of owning a home</a>
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimating annual maintenance costs</a>
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Common DIY repair jobs and their costs</a>
                            <a href="https://www.nerdwallet.com/blog/insurance/understanding-homeowners-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Homeowner&apos;s Insurance in detail</a>
                            <a href="https://www.quickenloans.com/blog/what-do-hoa-fees-cover" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Association Fees (HOAs) and what they cover</a>
                            <a href="https://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Some things you need to know about HOAs</a>
                            <a href="http://www.nolo.com/legal-encyclopedia/landlord-hire-property-management-company-29885.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>When to hire a property manager and what the management fee could be</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Taxes (See Your Income Statement for Detail)</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/common-tax-deductions-for-landlords-2125238" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Common tax deductions for a rental property</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Returns</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.investopedia.com/articles/investing/062215/how-calculate-roi-rental-property.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Return on Investment (ROI)</a>
                            <a href="https://www.arborcrowd.com/education/how-to-use-equity-multiple-to-evaluate-real-estate-investment-returns/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Multiple on Money (MoM)</a>
                            <a href="https://www.investopedia.com/articles/investing/062215/how-calculate-roi-rental-property.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Internal Rate of Return (IRR)</a>
                            <a href="https://www.propertymetrics.com/blog/2014/06/09/what-is-irr/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Detailed explanation of IRR</a>
                            <a href="https://origininvestments.com/2016/05/06/real-estate-returns-the-difference-between-irr-and-equity-multiple/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Difference between MoM and IRR</a>
                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.reiWalkThru4) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Real Estate Investment" moduleSelected="module14" getModuleData={getModuleData} setUpdatedScenario={setUpdatedREIValues} />
              )}
              {/* {dropdownvalues.compareSavedNotes && (
                <div className={ classes.sectionContent }>
                  <div className={ classes.panelRow }>
                    <div className={ classes.panelInfo }>
                      <div className={ classes.panelHeading }>
                        <div className={ classes.rowBlock }>
                          <div className={ classes.scenarioNotes }>
                            <div className={ classes.notesLeft }>
                              <ul className={ classes.listUnstyled }>
                                <li />
                              </ul>
                            </div>
                            <div className={ classNames( classes.notesRight, classes.scenarioNotesRight ) }>
                              <ul className={ classes.listUnstyled }>
                                <li><Button className={ classNames( classes.loadButton, classes.btnWarning ) }>Load</Button></li>
                                <li><Button className={ classNames( classes.saveButton, classes.btnWarning ) }>Save</Button></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classNames( classes.panelBody, classes.scenarioBlock ) }>
                        <Grid container>
                          <Grid item sm={ 3 } className={ classes.gridPadding } />
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario A</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario B</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Differences</h6>
                            <div>
                              <select>
                                <option value="Scenario A - Scenario B">Scenario A - Scenario B</option>
                                <option value="Scenario B - Scenario A">Scenario B - Scenario A</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 12 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>

      </div>
      <div>
        <Dialog
          open={popupOpen}
          onClose={() => { setPopupOpen(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={modalType === 'summary' ? classNames(classes.reiPopup, classes.SavePopup) : classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedREIValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}
            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={imagePopup}
          onClose={handleImagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.maxModule}
        >
          <DialogContent className={classes.maxModal}>
            <div className={classes.maxHeader}>
              <div className={classes.modalClose} aria-hidden="true" onClick={handleImagePopup}>
                ×

              </div>
            </div>
            <div className={classes.maxModalBody}>
              <div>
                {/* <span onClick={ handleImagePopup } aria-hidden="true">close</span> */}
                <img src={imageURL} alt="" />
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleImagePopup}>Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={editPopup}
          onClose={handleEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.closingCostsModal}
        >
          <DialogContent className={classes.videoPopup}>
            <div className={classes.modalHeader}>
              <h2>Closing Costs</h2>
            </div>
            <div className={classes.modalBody}>
              <div className={classes.moduleRowNew}>
                <table className={classes.closingCosts}>
                  <tbody>
                    <tr>
                      <td>Appraisal Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_appraisal_fee} onValueChange={(e) => updateValue(e, 'closing_appraisal_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Report Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_credit_report_fee} onValueChange={(e) => updateValue(e, 'closing_credit_report_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Attorney Fee: Title Closing/Escrow Account</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_escrow_account} onValueChange={(e) => updateValue(e, 'closing_escrow_account', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Flood Determination</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_flood_determination} onValueChange={(e) => updateValue(e, 'closing_flood_determination', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Tax Service Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_tax_service_fee} onValueChange={(e) => updateValue(e, 'closing_tax_service_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Title Insurance</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Recording Mortgage</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_recording_mortgage} onValueChange={(e) => updateValue(e, 'closing_recording_mortgage', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Homeowner&apos;s Insurance (3 months prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Transfer Taxes</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_processing_fee} onValueChange={(e) => updateValue(e, 'closing_processing_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Underwriting Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_underwriting_fee} onValueChange={(e) => updateValue(e, 'closing_underwriting_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Loan Points</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Origination Fees</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Mortgage Interest (1 month prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Property Taxes (3 months prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Pest Inspection</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_pest_Inspection} onValueChange={(e) => updateValue(e, 'closing_pest_Inspection', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Home Owners Association Transfer Fees</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_home_owners_association_transfer_fees} onValueChange={(e) => updateValue(e, 'closing_home_owners_association_transfer_fees', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedREIValues.closing_costs_others} onValueChange={(e) => updateValue(e, 'closing_costs_others', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><b>Total Closing Costs</b></td>
                      <td>                        
                        <span className={classes.numberInput}>
                          <b>{total !== undefined ? (
                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={total} prefix={total >= 0 ? '$' : '($'} suffix={total < 0 && ')'} />
                          ) : ('$0')}</b>
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <div>
                <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={handleClosingCostReset}>Reset to default values</Button>
                <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleEditPopup}>Ok</Button>
                <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleEditPopup}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module14' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {openWorkSheet === true && <ReiWorksheetTable closeWorkSheet={setOpenWorkSheet} openWorkSheet={openWorkSheet} />}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

REIWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(REIWorksheet);
