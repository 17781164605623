const styles = ( ) => ( {
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    boxSizing: 'border-box',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
      '@media (max-width: 991px)': {
        padding: '0px 20px 5px',
        marginTop: '8px',
        marginBottom: '8px',
        fontSize: '16px',
      }
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
      '@media (max-width: 991px)': {
        margin: '-3px 0px 5px',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '0px 10px',
      }
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'block !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '@media (max-width: 991px)': {
      top: '50px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      '@media (max-width: 1199px)': {
        top: '-19px',
        right: '26px',
        borderWidth: '0 20px 20px',
      },
      '@media (max-width: 991px)': {
        top: '-14px',
        right: '28px',
        borderWidth: '0 15px 15px',
      }
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
      '@media (max-width: 1199px)': {
        top: '-22px',
        right: '26px',
        borderWidth: '0 20px 20px',
      },
      '@media (max-width: 991px)': {
        top: '-17px',
        borderWidth: '0 17px 17px',
      }
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width:479px)': {
      display: 'block',
    }
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '5px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width:479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
      justifyContent: 'initial',
    },
    '& button': {
      margin: '0px 2px !important',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px !important',
      userSelect: 'none',
      '@media (max-width: 991px)': {
        fontSize: '12px !important',
        lineHeight: '10px !important',
      },
      '& span':{
        marginBottom: '0',
      }
    },
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '7px 12px 5px !important',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px !important',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px !important',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '100px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '@media (max-width: 991px)': {
      bottom: '95px',
    },
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      '@media (max-width: 991px)': {
        right: '42px',
        bottom: '-15px!important',
        borderWidth: '15px 15px 0 15px',
      }
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      '@media (max-width: 991px)': {
        bottom: '-20px!important',
        borderWidth: '17px 17px 0 17px',
      }
    },
  },
  pwiNextWalkthru:{
   top: 'auto',
    bottom: '100px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '@media (max-width: 991px)': {
      bottom: '95px',
    },
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      '@media (max-width: 991px)': {
        right: '42px',
        bottom: '-15px!important',
        borderWidth: '15px 15px 0 15px',
      }
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      '@media (max-width: 991px)': {
        bottom: '-20px!important',
        borderWidth: '17px 17px 0 17px',
      }
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    //display: 'none',
    '@media (max-width: 1199px)': {
      left: '0px',
    },
    '@media (max-width: 991px)': {
      top: '65px',
    },
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      '@media (max-width: 991px)': {
        top: '-15px',
        left: '37px',
        borderWidth: '0 15px 15px',
      },
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      '@media (max-width: 991px)': {
        top: '-19px',
        borderWidth: '0 17px 17px',
      }
    },
  },
  pwiWalkthruFour: {
    top: '74px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '@media (max-width: 991px)': {
      top: '208px',
      left: '0px',
      margin: '0px 20px',
    },
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
      '@media (max-width: 991px)': {
        top: '-15px',
        left: '70px',
        transform: 'rotate(0deg)',
        height: 'auto',
        bottom: 'auto',
        borderWidth: '0 15px 15px',
      },
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
      '@media (max-width: 991px)': {
        top: '-19px',
        left: '68px',
        transform: 'rotate(0deg)',
        height: 'auto',
        bottom: 'auto',
        borderWidth: '0 17px 17px',
      },
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '@media (max-width: 991px)': {
      left: '0',
      bottom: '65px',
      margin: '0px 20px',
    },
    '&::before': {
      position: 'absolute',
      content: "",
      margin: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      borderWidth: '0 30px 30px',
      borderStyle: 'solid',
      width: '0',
      height: '0',
      left: '-37px',
      right: 'auto',
      transform: 'rotate(252deg)',
      bottom: '14px',
      top: 'auto',
      '@media (max-width: 991px)': {
        left: '62px',
        bottom: '-14px',
        transform: 'rotate(-180deg)',
        borderWidth: '0 15px 15px',
      },
    },
    '&::after': {
      position: 'absolute',
      content: "",
      width: '0',
      height: '0',
      margin: 'auto',
      left: '-46px',
      right: 'auto',
      transform: 'rotate(253deg)',
      bottom: '11px',
      top: 'auto',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #0069aa',
      borderWidth: '0 36px 35px',
      '@media (max-width: 991px)': {
        left: '60px',
        bottom: '-18px',
        transform: 'rotate(-180deg)',
        borderWidth: '0 17px 17px',
      },
    },
  },
  pwiWalkthruSix: {
    top: '128px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '@media (max-width: 991px)': {
      left: '0px',
      margin: '0px 20px',
      top: '100px',
    },
    '@media (min-width: 992px) and (max-width: 1299px)': {
      left: '0',
    },
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      '@media (max-width: 991px)': {
        top: '-15px',
        left: '37px',
        borderWidth: '0 15px 15px',
      }
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      '@media (max-width: 991px)': {
        top: '-19px',
        borderWidth: '0 17px 17px',
      }
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '@media (max-width: 991px)': {
      left: '0px',
      margin: '0px 20px',
      top: '45px',
    },
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
      '@media (max-width: 991px)': {
        left: '80px',
        bottom: '-15px',
        transform: 'rotate(-180deg)',
        borderWidth: '0 15px 15px',
      }
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
      '@media (max-width: 991px)': {
        left: '78px',
        bottom: '-19px',
        transform: 'rotate(-180deg)',
        borderWidth: '0 17px 17px',
      }
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '@media (max-width: 991px)': {
      top: '55px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      '@media (max-width: 1199px)': {
        top: '-19px',
        right: '26px',
        borderWidth: '0 20px 20px',
      },
      '@media (max-width: 991px)': {
        top:' -14px',
        right: '28px',
        borderWidth: '0 15px 15px',
      }
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
      '@media (max-width: 1199px)': {
        top: '-22px',
        right: '26px',
        borderWidth: '0 20px 20px',
      },
      '@media (max-width: 991px)': {
        top: '-18px',
        borderWidth: '0px 17px 17px',
      }
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  closeWalkThru: {
    backgroundColor: '#fff !important',
    borderColor: '#ccc !important',
    color: '#333 !important',
    padding: '6px 12px !important',
    borderRadius: '4px',
    fontSize: '14px !important',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  // pwiNextWalkthru: {
  //   top: 'auto',
  //   left: 'auto',
  //   right: '125px',
  //   bottom: '65px',
  //   display: 'none',
  //   '&::before': {
  //     right: '-44px',
  //     bottom: '0',
  //     borderWidth: '0 30px 30px',
  //     borderColor: 'transparent transparent #fff',
  //     zIndex: '9999',
  //     top: '71px',
  //     transform: 'rotate(90deg)',
  //   },
  //   '&::after': {
  //     top: '71px',
  //     bottom: '0',
  //     right: '-51px',
  //     borderWidth: '0 34px 34px',
  //     borderColor: 'transparent transparent #0069aa',
  //     zIndex: '9998',
  //     transform: 'rotate(90deg)',
  //   },
  // },
  pwiCpWalkThru: {
    top: 'auto',
    left: 'auto',
    right: '125px',
     bottom: '100px',
    // bottom: '-35px',
    display: 'none',
    '@media (max-width: 991px)': {
      bottom: '95px',
      right: '0px',
    },
    '&::before': {
      right: '-44px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      top: '71px',
      bottom: '0',
      transform: 'rotate(90deg)',
      '@media (max-width: 991px)': {
        right: '42px',
        bottom: '-15px!important',
        borderWidth: '15px 15px 0 15px',
        top: 'auto',
        left: 'auto',
        transform: 'none',
        borderColor: '#fff transparent transparent',
      }
    },
    '&::after': {
      top: '71px',
      right: '-51px',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      bottom: '0',
      transform: 'rotate(90deg)',
      '@media (max-width: 991px)': {
        bottom: '-20px!important',
        borderWidth: '17px 17px 0 17px',
        top: 'auto',
        left: 'auto',
        right: '40px',
        borderColor: '#0069aa transparent transparent',
        transform: 'none',
      }
    },
  },

} );

export default styles;
