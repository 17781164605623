export function getLocationKey() {
  let moduleName = '';
  switch ( window.location.pathname ) {
    case '/financial-independence':
    case '/plan-with-inkwiry/fi':
      moduleName = 'module1';
      break;
    case '/higher-education':
    case '/plan-with-inkwiry/he':
    case '/plan-with-inkwiry/he1':
      moduleName = 'module2';
      break;
    case '/career-path':
    case '/plan-with-inkwiry/cp':
      moduleName = 'module4';
      break;
    case '/career-advancement':
    case '/plan-with-inkwiry/ca':
      moduleName = 'module5';
      break;
    case '/more-higher-education':
    case '/plan-with-inkwiry/mhe':
      moduleName = 'module6';
      break;
    case '/additional-career-advancement':
    case '/plan-with-inkwiry/aca':
      moduleName = 'module8';
      break;
    case '/investments':
    case '/plan-with-inkwiry/investments':
      moduleName = 'module9';
      break;
    case '/family':
    case '/plan-with-inkwiry/family':
      moduleName = 'module10';
      break;
    case '/vacation':
    case '/plan-with-inkwiry/vacation':
      moduleName = 'module11';
      break;
    case '/buying-a-car':
    case '/plan-with-inkwiry/car':
      moduleName = 'module12';
      break;
    case '/buying-a-home':
    case '/plan-with-inkwiry/home':
      moduleName = 'module13';
      break;
    case '/rei':
    case '/plan-with-inkwiry/rei':
      moduleName = 'module14';
      break;
    case '/further-higher-education':
    case '/plan-with-inkwiry/fhe':
      moduleName = 'module15';
      break;
    case '/further-career-advancement':
    case '/plan-with-inkwiry/fca':
      moduleName = 'module17';
      break;
    case '/existing-assets-liabilities':
    case '/plan-with-inkwiry/existing':
      moduleName = 'module18';
      break;
    default:
      break;
  }
  return moduleName;
}

export default getLocationKey;
