import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import m1 from '../../assets/img/financial-configurator/marketing_img_1.jpg';
import m2 from '../../assets/img/financial-configurator/marketing_img_2.jpg';
import m3 from '../../assets/img/financial-configurator/marketing_img_3.jpg';

const FinancialConfigurator = ( props ) => {
  const { classes, history } = props;

  const redirectToLifeEventLibrary = () => {
    history.push( '/life-event-library' );
  };

  const redirectToCareerSketch = () => {
    history.push( '/career-sketch' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.configuratorPage }>
        <div className={ classes.financialBanner }>
          <div className={ classes.bannerCaption }>
            <div className={ classes.container }>
              <div className={ classes.financialBannerCnt }>
                <div className={ classes.financialTitle }>
                  <Typography variant="h2" component="h2">
                    <span>Your Data.</span>
                    {' '}
                    <span>Only Smarter.</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.marketingContent }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Tap into the power of Inkwiry&apos;s FC™</Typography>
            <h3>
              Run your data in the Financial Configurator:
              <span>intelligent financial planning software for young people.</span>
            </h3>
          </div>
        </div>
        <div className={ classes.innovateSection }>
          <div className={ classes.container }>
            <Grid container spacing={ 4 }>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">Speed of thought</Typography>
                <p>Inkwiry&apos;s FC is constantly crushing data at lightning speed. Change your assumptions and see the outcome in real-time. That&apos;s financial life planning at the speed of thought.</p>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">Easy to use</Typography>
                <p>No prior financial knowledge required! Just drag and drop life events to a timeline, leverage the FC&apos;s learning resources to enter your details, and the Simulator Engine does the rest.</p>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">Visual and intuitive</Typography>
                <p>The FC transforms your data into charts, graphs and visualizations that provide a deeper understanding of your scenarios and complete financial life plans.</p>
              </Grid>
            </Grid>
            <Grid container spacing={ 3 } className={ classes.gridSpace }>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">You&apos;re in charge</Typography>
                <p>Experience true financial agility. The FC allows you to take charge of your future as you create a dynamic financial plan, and adjust it yourself as needed.</p>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">Super affordable</Typography>
                <p>For the first time ever, financial life planning is super affordable. In the FC, you can build an unlimited number of life event scenarios and career sketches for as little as $8/ month.</p>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <Typography variant="h4" component="h4">Access and share</Typography>
                <p>Anytime, anywhere. Whenever you have questions to ask and ideas to test out, the FC is always at your fingertips. Easily collaborate with friends, family and trusted colleagues.</p>
              </Grid>
            </Grid>
            <div className={ classes.lifeEvents }>
              <span aria-hidden="true" onClick={ redirectToLifeEventLibrary }>
                VIEW LIFE EVENTS
              </span>
            </div>
          </div>
        </div>
        <div className={ classes.marketingBuild }>
          <div className={ classes.container }>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.thumbNail }>
                  <img src={ m1 } alt="" />
                  <Typography variant="h3" component="h3">Learn as you go</Typography>
                  <p>Inkwiry&apos;s FC teaches you personal ﬁnance via hands-on planning and builds ﬁnancial literacy. Explore different modules, create scenarios and learn the language of finance.</p>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.thumbNail }>
                  <img src={ m2 } alt="" />
                  <Typography variant="h3" component="h3">
                    Make and test
                    <br />
                    {' '}
                    your assumptions
                  </Typography>
                  <p>Financial life planning is all about making assumptions, testing outcomes, and creating a roadmap to the future that excites you.</p>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.thumbNail }>
                  <img src={ m3 } alt="" />
                  <Typography variant="h3" component="h3">Easily collaborate</Typography>
                  <p>The data is live so you can see your results in real time. Each life event is modularized, so you can decide exactly how much data you want others to be able to view.</p>
                </div>
              </Grid>
            </Grid>
            <div className={ classes.lifeEvents }>
              <span aria-hidden="true" onClick={ redirectToCareerSketch }>
                SKETCH MY CAREER
              </span>
            </div>
          </div>
        </div>
        <div className={ classes.testimonialBlock }>
          <div className={ classes.container }>
            <div className={ classes.testimonialRow }>
              <Typography variant="h4" component="h4">
                When my first plan turned out to be too costly, I changed one value and was amazed, a split second later, to see all the numbers reconfigure and show me a workable solution.”
                <span>– Matt P., Temple Engineering Master&apos;s Degree Student</span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

FinancialConfigurator.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( FinancialConfigurator );
