import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';

export function carShowMeGraphData() {
  let graphsData = {};
  const buyingacarGraphData = moduleServices.buyingaarGraphData();
  graphsData['advValues'] = 0;

  //Bar Graph1

  if (buyingacarGraphData['First Car Bar Graph'] !== undefined && buyingacarGraphData['First Car Bar Graph']['Years List'] !== undefined && buyingacarGraphData['First Car Bar Graph']['Years List'].length > 0) {
     // First Car Graph
     let carPaymentDataValue = Math.max.apply(null, buyingacarGraphData['First Car Bar Graph']['Payment']).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
     if (carPaymentDataValue === '-Infinity') {
         carPaymentDataValue = '-';
     }
     let buyingaCar1MonthlyPaymentDataValue = moduleServices.module12Data.monthlyPayment.toFixed(0);
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: 1,
          plotShadow: false,
          zoomType: false,
          events: {
            load() {
              const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(buyingaCar1MonthlyPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
            ).attr({
                zIndex: 5
            }).add(),
            box = text.getBBox();
  
        chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
            .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
            })
            .add();
            },
          },
          // height: moduleGraphHeight,
          // width: graphMainWidth
      },
      title: {
          text: ''
      },
      navigation: {
          menuItemStyle: {
              fontSize: '14px',
              textAlign: 'left'
          },
          menuItemHoverStyle: {
              background: '#f5f5f5',
              color: '#4c4c4c',
              fontSize: '14px'
          },
          buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              verticalAlign: 'bottom',
              _titleKey: 'y'
          }
      },
      exporting: {
          buttons: {
              contextButton: {
                  menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                          chart.exportChart();
                      },
                  }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/jpeg'
                          });
                      }
                  }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'application/pdf'
                          });
                      }
                  }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/svg+xml'
                          });
                      }
                  }]
              }
          }
      },
      credits: {
          enabled: false
      },
      xAxis: [{
          categories: buyingacarGraphData['First Car Bar Graph']['Years List'],
          crosshair: true,
          labels: {
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      yAxis: [{ // Primary yAxis
          labels: {
              formatter() {
                const chart = this;
                  if (chart.value < 0) {
                      return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                  } else {
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          title: {
              text: 'Principal and Interest',
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          opposite: true
      }, { // Secondary yAxis
          gridLineWidth: 0,
          title: {
              text: 'Car Loan Balance',
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          labels: {
              formatter() {
                const chart = this;
                  if (chart.value < 0) {
                      return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                  } else {
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      tooltip: {},
      legend: {
          itemStyle: {
              fontSize: '14px'
          }
      },
      series: [{
          name: 'Car Loan Balance',
          type: 'column',
          yAxis: 1,
          data: buyingacarGraphData['First Car Bar Graph']['Car Loan balance'],
          tooltip: {
              valuePrefix: '$'
          }
      }, {
          name: 'Payment',
          type: 'column',
          yAxis: 1,
          data: buyingacarGraphData['First Car Bar Graph']['Payment'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#ed7d31'
      }, {
          name: 'Principal Payback',
          type: 'spline',
          yAxis: 0,
          data: buyingacarGraphData['First Car Bar Graph']['Principal Payback'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#548235'
      }, {
          name: 'Interest',
          type: 'spline',
          yAxis: 0,
          data: buyingacarGraphData['First Car Bar Graph']['Interest'],
          marker: {
              enabled: false
          },
          tooltip: {
              valuePrefix: '$'
          },
          color: '#C5E0B4'
      }]
  }
  } else {
    graphsData['graph1'] = false;
  }

  if (buyingacarGraphData['Second Car Bar Graph'] !== undefined && buyingacarGraphData['Second Car Bar Graph']['Years List'] !== undefined && buyingacarGraphData['Second Car Bar Graph']['Years List'].length > 0) {
 //Second Graph
 let secondcarPaymentvalue = Math.max.apply(null, buyingacarGraphData['Second Car Bar Graph']['Payment']).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 if (secondcarPaymentvalue < 0) {
     secondcarPaymentvalue = 0;
 }
 let buyingaCar2MonthlyPaymentDataValue = moduleServices.module12Data.moreMonthlyPayment.toFixed(0);
    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: 1,
          plotShadow: false,
          zoomType: false,
          // height: moduleGraphHeight,
          // width: graphMainWidth
          events: {
            load() {
              const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: '+formatDollar(Math.round(buyingaCar2MonthlyPaymentDataValue))+'</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
                ).attr({
                zIndex: 5
            }).add(),
            box = text.getBBox();
        
            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
            .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
            })
            .add();
            },
          },
      },
      navigation: {
              menuItemStyle: {
                  fontSize: '14px',
                  textAlign: 'left'
              },
              menuItemHoverStyle: {
                  background: '#f5f5f5',
                  color: '#4c4c4c',
                  fontSize: '14px'
                },
              buttonOptions: {
                  height: 40,
                  width: 48,
                  symbolSize: 24,
                  symbolX: 24,
                  symbolY: 21,
                  symbolStrokeWidth: 2,
                  verticalAlign: 'bottom',
                  _titleKey: 'y'
              }
          },
          exporting: {
              buttons: {
                  contextButton: {
                    menuItems: [{
                          textKey: 'downloadPNG',
                          onclick() {
                            const chart = this;
                              chart.exportChart();
                          },
                      }, {
                          textKey: 'downloadJPEG',
                          onclick() {
                            const chart = this;
                              chart.exportChart({
                                  type: 'image/jpeg'
                              });
                          }
                      },{
                          textKey: 'downloadPDF',
                          onclick() {
                            const chart = this;
                              chart.exportChart({
                                  type: 'application/pdf'
                              });
                          }
                      }, {
                          textKey: 'downloadSVG',
                          onclick() {
                            const chart = this;
                              chart.exportChart({
                                  type: 'image/svg+xml'
                              });
                          }
                      }]
                  }
              }
            },
      credits: {
          enabled: false
      },
      title: {
          text: ''
      },
      xAxis: [{
          categories: buyingacarGraphData['Second Car Bar Graph']['Years List'],
          crosshair: true,
          labels: {
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      yAxis: [{ // Primary yAxis
          labels: {
              formatter() {
                const chart = this;
                if( chart.value < 0 ) {
                      return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                  } else { 
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          title: {
              text: 'Principal and Interest',
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          opposite: true
      }, { // Secondary yAxis
          gridLineWidth: 0,
          title: {
              text: 'Car Loan Balance',
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          labels: {
              formatter() {
                const chart = this;
                if( chart.value < 0 ) {
                      return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                  } else { 
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      tooltip: {},
      legend: {
          itemStyle: {
              fontSize: '14px'
          }
      },
      series: [{
          name: 'Car Loan Balance',
          type: 'column',
          yAxis: 1,
          data: buyingacarGraphData['Second Car Bar Graph']['Car Loan balance'],
          tooltip: {
              valuePrefix: '$'
          }
      },{
          name: 'Payment',
          type: 'column',
          yAxis: 1,
          data: buyingacarGraphData['Second Car Bar Graph']['Payment'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#ed7d31'
      },{
          name: 'Principal Payback',
          type: 'spline',
          yAxis: 0,
          data: buyingacarGraphData['Second Car Bar Graph']['Principal Payback'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#548235'
      }, {
          name: 'Interest',
          type: 'spline',
          yAxis: 0,
          data: buyingacarGraphData['Second Car Bar Graph']['Interest'],
          marker: {
              enabled: true
          },
          tooltip: {
              valuePrefix: '$'
          },
          color: '#C5E0B4'
      }]
  }
  } else {
    graphsData['graph2'] = false;
  }
  return graphsData;
}


