import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Dividend = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Dividend</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dividend.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Dividend
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dividend-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noBottomMargin}>
                          Before you invest in a publicly traded company, check
                          to see if the company has a history of paying
                          dividends or if it plans to pay a dividend in the
                          future. There are two ways to earn a return by
                          investing in a stock:
                        </p>
                        <ol className={classes.noTopMargin}>
                          <li>
                            The price goes up and you sell the stock at a higher
                            price that you bought it for, or
                          </li>
                          <li>The stock you own pays a dividend.</li>
                        </ol>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Typically, larger, more profitable companies that have
                          been in business for a longer period of time pay
                          dividends to their investors. Some companies will
                          distribute a portion of their earnings to shareholders
                          as a reward for investing in the company. Startups and
                          younger companies aggressively focused on growth and
                          spending their profits to grow usually will not pay a
                          dividend.
                        </p>
                        <p className={classes.noBottomMargin}>
                          As an investor, there are four important dates to
                          understand about dividend payments. These dates are
                          listed in chronological order:
                        </p>
                        <ol className={classes.noTopMargin}>
                          <li>
                            <strong>Announcement Date:</strong> as the name
                            suggests, this is the date the company first
                            announces a future dividend payment.
                          </li>
                          <li>
                            <strong>Ex-Dividend Date:</strong> this is the most
                            important date to remember. You must buy the stock
                            before this day to receive the dividend. If the
                            ex-dividend date is September 15 and you purchase
                            the stock on September 15, you will not receive the
                            future dividend payment. You have to purchase the
                            stock or be an owner of the stock at least one day
                            before this date to receive the dividend.
                          </li>
                          <li>
                            <strong>Record Date:</strong> usually the day after
                            the ex-dividend date, the company takes a roll call
                            to determine which shareholders will receive a
                            dividend.
                          </li>
                          <li>
                            <strong>Payment Date:</strong> last, but not least,
                            the payment date is the date the dividend will be
                            paid. On this day, investors will see that the
                            dividend has been paid in their account.
                          </li>
                        </ol>
                        <p>
                          Dividends typically are paid on a quarterly basis for
                          United States companies. Let&apos;s use an example.
                          John owns 100 shares of Microsoft stock and Microsoft
                          pays a quarterly dividend of $0.50 per share each
                          quarter and plans to increase that dividend payment by
                          $0.05 a quarter. If John stays invested in Microsoft
                          for one full year, he would receive:
                        </p>
                        <p className={classes.dividendRules}>
                          <span>
                            Quarter 1 dividend payment = 100 shares x $0.50
                            dividend = $50 payment
                          </span>
                          <span>
                            Quarter 2 dividend payment = 100 shares x $0.55
                            dividend = $55 payment
                          </span>
                          <span>
                            Quarter 3 dividend payment = 100 shares x $0.60
                            dividend = $60 payment
                          </span>
                          <span>
                            {' '}
                            Quarter 4 dividend payment = 100 shares x $0.65
                            dividend = $65 payment
                          </span>
                          <span>
                            John&apos;s total dividend payments = $50 + $55 +
                            $60 + $65 = $230
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Dividend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dividend);
