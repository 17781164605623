const styles = ( theme ) => ( {
  containerFluid: {
    maxWidth: '100%',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  img: {
    verticalAlign: 'middle',
  },
  div: {
    boxSizing: 'border-box',
  },
  pfBudgetPage: {
    textAlign: 'center',
    position: 'relative',
    maxWidth: '850px',
    margin: '0 auto',
    paddingLeft: '100px',
    paddingRight: '100px',
    display: 'table',
    fontFamily: '"MuseoSans-100"',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '650px',
      padding: '0 15px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '90%',      
    },
  },
  breadCrumbs: {
    position: 'relative',
    zIndex: '999',
    listStyle: 'none',
    display: 'flex',
    marginBottom: '0',
    padding: '10px 0px 15px 0',
    marginTop: '0px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    '& li': {
      listStyle: 'none',
      fontSize: '14px',
      fontFamily: '"MuseoSans-100"',
      color: '#333',
      '& a': {
        color: '#333',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& span': {
        padding: '0 5px',
        fontSize: '18px',
        lineHeight: '18px',
        color: '#000',
        boxSizing: 'border-box',
      },
    },
  },
  blueRow: {
    background: '#0069aa',
    paddingTop: '15px',
  },
  logoBlock: {
    background: '#fff',
    padding: '20px 0',
    '& img': {
      maxWidth: '140px',
      verticalAlign: 'middle',
      border: 'none',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '140px',
      },
    },
  },
  pfHeading: {
    '& h3': {
      margin: '0',
      padding: '35px 0',
      fontSize: '24px',
      color: '#0069aa',
      fontWeight: '500',
      fontFamily: '"MuseoSans-100"',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '1.4',
      },
      '& span': {
        display: 'inline-block',
        margin: '0 15px',
        textAlign: 'center',
      },
    },
  },
  bigWordNew: {
    fontSize: '32px !important',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '28px !important',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px !important',
    },
  },
  continueButton: {
    background: '#0069aa',
    borderColor: '#0069aa',
    marginTop: '10px',
    marginLeft: '0px',
    padding: '12px 22px',
    marginBottom: '15px',
    fontFamily: '"MuseoSans-100"',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    border: '2px solid #f89b22',
    lineHeight: 'inherit',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      border: '2px solid #d5d5d5',
      color: '#fff',
      background: '#0069aa',
    },
    '&:disabled': {
      cursor: 'no-drop',
      pointerEvents: 'all',
      background: '#d5d5d5',
      borderColor: '#d5d5d5',
      color: '#333333',
      opacity: 0.65,
      '&:hover': {
        background: '#d5d5d5',
        borderColor: '#d5d5d5',
      },
    },
  },

  gridRow: {
    height: '100%',
  },
  pfRow: {
    display: 'flex',
    textAlign: 'left',
    color: '#333',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',     
    },
  },

  pfImage: {
    paddingTop: '0px',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto 10px auto',
    },
  },
  pfSlideImage: {
    paddingTop: '8px',
    '& img': {
      width: 'auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto 10px auto',
    },
  },
  pfText: {
    paddingLeft: '20px',
    fontSize: '20px',
    lineHeight: '1.5',
    color: '#333',
    fontFamily: '"MuseoSans-100"',   
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      fontSize: '18px',
    },
    '& p': {
      '&:first-child': {
        marginTop: '0px',
      },
      '&:last-child': {
        [theme.breakpoints.down( 'xs' )]: {
         marginBottom: '0px',
        },
      },
      '& a': {
        textDecoration: 'underline',
        '&:hover': {
          color: '#f59a34',
        },
      },
    },
  },
  feText: {
    fontSize: '18px',
    lineHeight: '1.5',
    color: '#333',
    fontFamily: '"MuseoSans-100"',  
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '15px',
    },
    '& p': {
      marginTop: '17px',
      marginBottom: '18px',
      '& a': {
        textDecoration: 'underline',
        color: '#337ab7',
        '&:hover': {
          color: '#f59a34',
        },
      },
      '& strong': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
    },
    '& strong': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
  },
  pfContent: {
    boxSizing: 'border-box',
    '& > div': {
      boxSizing: 'border-box',
    },
  },
  applyLearned: {
    fontSize: '20px',
    color: '#000',
    margin: '0px 0px 10px 0px',
  },

  continueLink: {
    color: '#337ab7',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#f59a34',
    },
  },
  p: {
    '& b': {
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
    },
  },
  seperatorDots: {
    margin: '40px auto',
  },
  connectFinance: {
    '& p': {
      fontSize: '20px',
      color: '#333',
    },
    '& ul': {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '170px',
      margin: '20px auto 0',
      padding: '0',
      '& li': {
        '& a': {
          '& img': {
            verticalAlign: 'middle',
          },
        },
      },
    },
  },
  financialBuild: {
    marginBottom: '30px',
    '& h2': {
      fontSize: '30px',
      marginTop: '20px',
      marginBottom: '10px',
      lineHeight: '1.1',
      color: '#0069aa',
      fontWeight: '500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '26px',
      },
    },
    '& a': {
      fontSize: '18px',
      textDecoration: 'none',
      transition: 'all 0.5s ease 0s',
      '&:hover': {
        color: '#23527c',
      },
    },
  },
  federalTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '60%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& thead': {
      '& tr': {
        '& th': {
          padding: '1px 5px 1px 1px',
          color: '#000',
          fontSize: '20px',
          textAlign: 'center',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '18px',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          padding: '1px 5px 1px 1px',
          color: '#000',
          fontSize: '20px',
          textAlign: 'right',
          borderColor: '#000',
          border: '1px solid #ddd',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '18px',
          },
        },
      },
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
    '& ul': {
      paddingLeft: '20px',
    },
  },
  grayboxMargin: {
    margin: '17px 0px',
  },
  checkList: {
    listStyleType: 'none',
    paddingLeft: '18px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '10px',
    },
    '& li': {
      '& span': {
        display: 'inline-block',
      },
      '& strong': {
        paddingRight: '5px',
      },
    },
  },
  noBottomMargin: {
    marginBottom: '0px !important',
  },
  noTopMargin: {
    marginTop: '0px !important',
  },
  taxTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '15px',
    },    
    '& thead': {
      '& tr': {
        '& th': {
          fontFamily: '"MuseoSans-500"',
          fontWeight: '400',
          textAlign: 'center',
        },
      },
    },
    '& tbody': {
      borderTop: '2px solid #9cc2e5',
      '& tr': {
        '& td': {
          '&:first-child': {
            fontFamily: '"MuseoSans-500"',
            fontWeight: '400',
          },
          textAlign: 'center',
          borderRight: '1px solid #9cc2e5',
        },
        '&:nth-child(odd)': {
          background: '#deeaf6',
        },
      },
    },
  },
  fillingtaxTable: {
    margin: '30px 0px',
  },
  noMargin: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  dowCompanies: {
    paddingLeft: '20px',
    listStyleType: 'none',
  },
  tickList: {
    paddingLeft: '20px',
    listStyleType: 'none',
    marginTop: '0px',
  },
  circleList: {
    listStyleType: 'circle',
    marginTop: '5px',
    fontSize: '18px',
  },
  incomeTable: {
    marginBottom: '0px',
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          background: '#d2deef',
        },
        '& td': {
          fontSize: '16px',
          padding: '5px 8px',
          border: '1px solid #9cc2e5',
          '&:nth-child(1)': {
            borderLeft: '0px',
          },
          '&:last-child': {
            borderRight: '0px',
          },
        },
      },
    },
    '& thead': {
      '& tr': {
        '& th': {
          fontSize: '16px',
          padding: '5px 8px',
        },
      },
    },
  },
  blockDisplay: {
    display: 'inline-block',
  },
  paymentRow: {
    fontSize: '16.4px',
    marginBottom: '0',
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '10px',
    },
    '& span': {
      '&:nth-child(1)': {
        width: '195px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100% !important',
        },        
      },
      width: '400px',
      display: 'inline-block',
      lineHeight: '23px',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100% !important',
      },
      '@media (max-width: 768px)': {
        width: 'auto',
      },
    },
    '& a': {
      textDecoration: 'underline',
      '&:hover': {
        color: '#f59a34',
      },
    },
  },
  grayBoxNew: {
    padding: '20px 30px',
    background: '#f5f5f5',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 15px',
    },
  },
  guideLine: {
    fontSize: '16.4px',
    marginBottom: '0',
  },
  pfMargin: {
    margin: '30px 0px',
  },
  paymentBlock: {
    '& span': {
      width: '245px',
      display: 'inline-block',
      boxSizing: 'border-box',
      '&:first-child': {
        width: '245px',
      },
    },
  },
  netWorthLink: {
    background: '#f5f5f5',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '17px',
    },
    '& span': {
      display: 'inline-block',
    },
  },
  leftBlock: {
    width: '36%',
  },
  rightBlock: {
    width: '64%',
    display: 'inline-block',
    '& span': {
      display: 'block',
    },
  },
  monthsSpace: {
    paddingLeft: '20px',
  },
  diduKnow: {
    color: '#337ab7',
  },
  costTable: {
    width: '100%',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    fontSize: '16px',
    '& thead': {
      '& tr': {
        '& th': {
          color: '#000',
          padding: '8px',
          lineHeight: '1.42857143',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:first-child': {
          borderTop: '2px solid #9cc2e5',
        },
        '&:nth-child(odd)': {
          background: '#deeaf6',
        },
        '& td': {
          border: '1px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            fontWeight: 'normal',
            fontFamily: 'MuseoSans-500',
          },
        },
      },
    },
  },
  savePercentage: {
    fontSize: '25px',
    lineHeight: '27px',
    marginBottom: '10px',
    marginTop: '25px',
    color: '#0069aa',
    '@media (max-width: 320px)': {
      fontSize: '20px',
    },
    '& b': {
      fontSize: '30px',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  smallpfText: {
    fontSize: '18px',
    marginBottom: '0px',
  },
  smallList: {
    fontSize: '18px',
    marginTop: '0px',
  },
  linkText: {
    textDecoration: 'underline',
    '&:hover': {
      color: '#f59a34',
    },
  },
  insuranceTable: {
    width: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        '& th': {
          paddingTop: '15px',
          paddingBottom: '15px',
          fontSize: '15px',
          padding: '8px',
          lineHeight: '1.42857143',
        },
      },
    },
    '& tbody': {
      borderBottom: '3px solid #408fc0',
      '& tr': {
        '&:nth-child(odd)': {
          '& td': {
            background: '#dae3f3',
          },
        },
        '& td': {
          background: '#f2f2f2',
          fontSize: '15px',
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
        },
      },
    },
  },
  emptyBlueRow: {
    '& th': {
      background: '#408fc0',
      padding: '0 !important',
      border: '0',
      lineHeight: '1.42857143',
      height: '2px',
    },
  },
  smallText: {
    fontSize: '18px',
    '& b': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& strong': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& li': {
      fontSize: '18px',
    },
  },
  accountGraph: {
    marginTop: '50px',
  },
  blueBox: {
    border: '1px solid #0069aa',
    padding: '5px 10px',
    color: '#0069aa',
    '& p': {
      marginBottom: '0',
      marginTop: '0px',
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  greenBox: {
    border: '1px solid #39b54a',
    padding: '5px 10px',
    margin: '20px 0',
    '& p': {
      margin: '0',
      fontSize: '16px',
      textAlign: 'left',
      color: '#39b54a',
    },
  },
  redBox: {
    color: 'red',
    padding: '5px 10px',
    border: '1px solid red',
    '& p': {
      margin: '0',
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  retirementTable: {
    fontSize: '16px',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontWeight: '700',
          fontSize: '15px',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: '"MuseoSans-300"',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        backgroundColor: '#f2f2f2',
        '& td': {
          fontSize: '16px',
          borderTop: '1px solid #fff',
          verticalAlign: 'middle',
          textAlign: 'right',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            textAlign: 'left',
          },
        },
        '&:last-child': {
          '& td': {
            borderBottom: '2px solid #000',
            borderTop: '2px solid #fff',
          },
        },
      },
    },
  },
  table: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  maxContribution: {
    '& td': {
      paddingTop: '30px !important',
      paddingBottom: '20px !important',
      fontStyle: 'italic',
      '&:first-child': {
        paddingLeft: '30px',
      },
    },
  },
  noTopBorder: {
    '& td': {
      borderTop: 'none !important',
    },
  },
  employerMatch: {
    '& td': {
      fontStyle: 'italic',
      '&:first-child': {
        paddingLeft: '30px',
      },
    },
  },
  sandpTable: {
    marginBottom: '0',
    borderTop: '1px solid #fff',
    width: '80%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& thead': {
      '& tr': {
        '& th': {
          background: '#4472c4',
          color: '#fff',
          textAlign: 'center',
          verticalAlign: 'middle',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            background: '#fff',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          '&:first-child': {
            background: '#cfd5ea',
            textAlign: 'center',
          },
          border: '1px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          background: '#f2f2f2',
          verticalAlign: 'middle',
          textAlign: 'center',
        },
      },
    },
  },
  pfImageSpace: {
    paddingTop: '20px',    
  },
  bigWord: {
    fontSize: '29px !important',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down( 'sm' )]: {
      whiteSpace: 'pre-wrap',
    },
  },
  galleryImage: {
    margin: '30px 0px',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '10px 0px',
    },
    '& img': {
      verticalAlign: 'middle',
      cursor: 'pointer',
      width: '100%',
    },
  },
  loanImage: {
    justifyContent: 'center',
    display: 'flex',
    width: 'auto',
    '& img': {
      paddingTop: '20px',
    },
  },
  taxExample: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          textAlign: 'left',
          fontWeight: '700',
          fontSize: '16px',
          verticalAlign: 'bottom',
          padding: '8px',
          lineHeight: '1.42857143',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          backgroundColor: '#f2f2f2',
          padding: '8px',
          lineHeight: '1.42857143',
          borderTop: '1px solid #fff',
          verticalAlign: 'middle',
          textAlign: 'right',
          fontSize: '16px',
          '&:first-child': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  bottomBorder: {
    '& td': {
      borderBottom: '2px solid #000',
    },
  },
  textRed: {
    color: 'red !important',
  },
  breakdownTable: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& tr': {
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '18px',
        verticalAlign: 'bottom',
        padding: '8px',
        lineHeight: '1.42857143',
        fontFamily: '"MuseoSans-300"',
      },
      '& td': {
        borderTop: '1px solid #fff',
        verticalAlign: 'middle',
        textAlign: 'left',
        fontSize: '18px',
        padding: '8px',
        lineHeight: '1.42857143',
        backgroundColor: '#f2f2f2',
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
  },
  topPadding: {
    '& img': {
      paddingTop: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        paddingTop: '0px',
      },
    },
  },
  writeCheck: {
    paddingTop: '20px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingTop: '0px',
    },
    '& li': {
      padding: '10px 0',
    },
  },
  checkImage: {
    width: '100%',
    paddingTop: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingTop: '0px',
    },
  },
  fePadding: {
    paddingLeft: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
    },
  },
  bigText: {
    fontSize: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
    },
  },
  prosConsTable: {
    marginTop: '30px',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& thead': {
      '& tr': {
        background: '#4472c4',
        color: '#fff',
        '& th': {
          borderTop: '1px solid #4472c4',
          width: '50%',
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          textAlign: 'center',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          background: '#d9e2f3',
        },
        '& td': {
          border: '1px solid #8eaadb',
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
        },
      },
    },
  },
  creditTable: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '13px',
    },
    '& thead': {
      '& tr': {
        background: '#4472c4',
        color: '#fff',
        '& th': {
          textAlign: 'center',
          borderBottomWidth: '3px',
          border: '1px solid #fff',
          verticalAlign: 'bottom',
          padding: '8px',
          lineHeight: '1.42857143',
          borderLeft: '0',
          borderRight: '0',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          background: '#cfd5ea',
        },
        '&:nth-child(even)': {
          background: '#e9ebf5',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          border: '1px solid #fff',
        },
      },
    },
  },
  debtCard: {
    fontSize: '30px',
  },
  autoImage: {
    paddingTop: '15px',
    '& img': {
      width: 'auto',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  bottomMargin: {
    marginBottom: '18px',
  },
  compoundTable: {
    border: '2px solid #ddd',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    fontSize: '15px',
    '& thead': {
      '& tr': {
        '& th': {
          border: '2px solid #ddd',
          verticalAlign: 'middle',
          background: '#f2f2f2',
          textAlign: 'center',
          padding: '8px',
          lineHeight: '1.42857143',
          borderLeft: '0px',
          borderRight: '0px',
          '&:first-child': {
            borderTop: '2px solid transparent',
            background: 'transparent',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          borderRight: '2px solid #ddd',
          textAlign: 'right',
          border: '0 solid',
          verticalAlign: 'middle',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  investTable: {
    '& thead': {
      '& tr': {
        '& th': {
          '&:nth-child(even)': {
            background: '#70ad47',
          },
          '&:nth-child(odd)': {
            background: '#ffc000',
          },
          '&:first-child': {
            background: 'transparent',
          },
        },
      },
    },
  },
  compoundImage: {
    cursor: 'pointer',
    padding: '20px 0px',
    border: '1px solid #000',
  },
  graphText: {
    width: 'auto',
    margin: '0 30px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 10px',
   },
  },
  listedInvestments: {
    position: 'relative',
    '& ul': {
      width: '60%',
      paddingLeft: '0',
      marginBottom: '10px',
      marginTop: '0px',
      [theme.breakpoints.down( 'xs' )]: {
         width: '100%',
      },
    },
  },
  riskList: {
    border: '2px solid #4472c4',
    borderRadius: '0 6px 6px 0',
    borderLeft: '72px solid #4472c4',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '5px',
    padding: '10px 0 10px 30px',
    boxSizing: 'border-box',
    '& span': {
      position: 'absolute',
      left: '0',
      flexWrap: 'wrap',
      width: '72px',
      padding: '5px',
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: '18px',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
    '& li': {
      listStyleType: 'none',
      fontSize: '18px',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
      },
      '@media (max-width: 320px)': {
        fontSize: '14px',
      },
    },
  },
  rlBonds: {
    borderColor: '#006547',
  },
  rlMutual: {
    borderColor: '#fff200',
  },
  rlStocks: {
    borderColor: '#f15a29',
  },
  rlRisk: {
    borderColor: '#be1e2d',
  },
  liLeftPadding: {
    paddingLeft: '15px',
  },
  anchorText: {
    '& span': {
      display: 'block',
    },
  },
  leftSpace: {
    paddingLeft: '18px',
    '& p': {
      marginTop: '0px',
      marginBottom: '0px',
    },
  },
  companiesMarket: {
    paddingLeft: '20px',
    '& p': {
      marginTop: '0px',
      marginBottom: '0px',
      '& span': {
        paddingLeft: '20px',
        display: 'block',
        [theme.breakpoints.down( 'sm' )]: {
          paddingLeft: '10px',
          display: 'inline',
        },
      },
    },
  },
  sharePrice: {
    fontSize: '18px',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '0px',
    },
  },
  sourceText: {
    marginTop: '0px',
    fontSize: '14px',
    fontStyle: 'italic',
    lineHeight: '27px',
  },
  autoImageWidth: {
    '& img': {
      width: 'auto',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  italicStyle: {
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '14px',
    fontStyle: 'italic',
    lineHeight: '27px',
  },
  catText: {
    marginBottom: '5px !important',
  },
  dividendRules : {
    '& span': {
      display: 'block',
      [theme.breakpoints.down( 'sm' )]: {
        marginBottom: '5px',
      },
    },
  },
  jobMargin: {
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '0px',
    },
  },
  ocImage: {
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '-40px',
    },
  },
  spText: {
    '& span': {
      display: 'block',
      [theme.breakpoints.down( 'sm' )]: {
        marginBottom: '5px',
      },
    },
  },
  creditTax: {
    [theme.breakpoints.down( 'xs' )]: {
      width: '90%',
      overflowX: 'auto',
      margin: '0 auto',
    },
    '@media (max-width: 320px)': {
      maxWidth: '300px',
    },
  },
  pfMobile: {
    [theme.breakpoints.down( 'xs' )]: {
     paddingTop: '0px',
    },
  },
  blockTable: {
    [theme.breakpoints.down( 'xs' )]: {
     display: 'block',    
    },
  },
  pfBlockRow: {
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',    
    },
  },
  compoundBlock: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto', 
    },
  },
  feTextTitle:{
    '& h3':{
      fontFamily: '"MuseoSans-700"',
      fontWeight: 'normal',
      fontSize: '18px',
      margin: '0px',
      color: '#333',
      textAlign: 'left',
    },
    feTextGraph:{ 
      border:'2px solid black',
      text:{
         display:'none',
         opacity:0, 
         visibility: 'hidden'
      },
    },
  },
  imgFull:{
    width: '100%',
  },
  pfHeadingTitle:{
    '& h3':{
      fontSize: '24px',
      margin: '0',
      padding: '35px 0',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      color: '#0069aa',
      '& span':{
        display: 'inline-block',
        margin: '0 15px',
        textAlign: 'center',
      }
    }
  },
  pfHeadingTitleTwo:{
    '& h3':{
      fontSize: '19px',
    }
  },
  feTexttable:{
    '& table':{
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '30px',
      color: '#000',
      '& thead':{
        '& tr':{
         '&:first-child':{
            background: '#D9E1F2',
          },
          '& th':{
            textAlign: 'center',
            border: '1px solid #000',
            padding: '5px 3px',
            lineHeight: '18px',
            fontFamily: '"MuseoSans-700"',
            fontWeight: 'normal',
          }
        }
      },
      '& tbody':{
        '& tr':{
          '& td':{
            textAlign: 'center',
            border: '1px solid #000',
            padding: '7px 3px',
            lineHeight: '18px',
            fontFamily: '"MuseoSans-100"',
            fontSize: '13px',
            fontWeight: 'normal',
          },
          '&:last-child':{
            '& td':{
              fontFamily: '"MuseoSans-700"',
              fontWeight: 'normal',
            }
          }
        }
      }
    }
  },
  bdr0:{
    borderRight: '0px solid #000 !important',
  },
  bdl0:{
    borderLeft: '0px solid #000 !important',
  },
  paraTitle:{
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '0px',
    fontFamily: '"MuseoSans-500"',
    fontWeight: 'normal',
  },
  hitTapRow:{
    padding: '30px 0 5px',
    textAlign: 'left',
    fontSize: '18px',
    '& h3':{
      color: '#0069aa',
      fontSize: '28px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      margin: '0 0 30px',
    },
    '& ul':{
      '& li':{
        fontFamily: '"MuseoSans-100"',
        fontWeight: 'normal',
        '& a':{
          fontFamily: '"MuseoSans-100"',
          fontWeight: 'normal',
          textDecoration: 'underline',
          '&:hover':{
            color: '#f89b22',
          }
        }
      }
    },
    '& p':{
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '30px',
      color: '#337ab7',
      '& a':{
        textDecoration: 'underline',
        color: '#337ab7',
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
        transition: 'all .5s ease',
       
      }
    }
  },
  feTexttableTwo:{
    margin: '18px 0px 18px',
    '& table':{
      width: '100%',
      border: '1px solid #000',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& thead':{
        '& tr':{
          borderTop: '1px solid #000',
          borderBottom: '1px solid #000',
          '& th':{
            background: '#4472C4',
            textAlign: 'right',
            padding: '2px 8px',
            fontFamily: '"MuseoSans-500"',
            fontWeight: 'normal',
            color: '#fff',
            '&:first-child':{
              textAlign: 'center',
            }
          }
        }
      },
      '& tbody':{
        '& tr':{
          '&:nth-child(odd)':{
            background: '#D9D9D9',
          },
          '& td':{
            padding: '2px 8px',
            textAlign: 'right',
            fontFamily: '"MuseoSans-300"',
            fontWeight: 'normal',
            color: '#000',
            '&:first-child':{
              textAlign: 'left  ',
            }
          }
        }
      }
    }
  },
  feTexttableThree:{
    '& table':{
      width: '90%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      '& thead':{
        '& tr':{
          '& th':{
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'center',
            color: '#000',
            verticalAlign: 'middle',
            fontFamily: '"MuseoSans-500"',
            fontWeight: 'normal',
            border: '1px solid #000',
            '&:first-child':{
              border: '0px solid #000',
            }
          }
        }
      },
      '& tbody':{
        '& tr':{
          '& td':{
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'right',
            color: '#000',
            verticalAlign: 'middle',
            border: '1px solid #000',
            '&:first-child':{
              textAlign: 'left',
            }
          },
          '&:last-child':{
            '& td':{
              background: '#deebf7',
              fontFamily: '"MuseoSans-500"',
              fontWeight: 'normal',
              '&:first-child':{
                border: '0px',
                background: 'transparent',
              }
            }
          }
        },
      }
    }
  },
  feTexttableFour:{
    margin: '30px 0',
    '& table':{
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      color: '#333',
      '& thead':{
        '& tr':{
          '& th':{
            fontFamily: '"MuseoSans-500"',
            fontWeight: 'normal',
            textAlign: 'center',
          }
        }
      },
      '& tbody':{
        '& tr':{
          '& td':{
            borderRight: '1px solid #9cc2e5',
             textAlign: 'center',
             '&:first-child':{
              fontFamily: '"MuseoSans-500"',
              fontWeight: 'normal',
             },
            '&:last-child':{
              borderRight: '0px solid #9cc2e5',
            }
          },
          '&:first-child':{
            '& td':{
              background: '#deeaf6',
              borderRight: '1px solid #9cc2e5',
              '&:last-child':{
                borderRight: '0px solid #9cc2e5',
              }
            }
          }
        }
      }
    }
  },
  rowItalic:{
    fontStyle: 'italic',
  },
  padL20:{
    paddingLeft: '20px !important',
  },
  rowWhite:{
    background: '#fff !important',
    '& td':{
      fontFamily: '"MuseoSans-500" !important',
    }
  },
  rowBlue:{
    background: '#DDEBF7 !important',
  },
  bdTop:{
    borderTop: '1px solid #000',
  },
  bdBottom:{
    borderBottom: '1px solid #000',
  },
  feTextList:{
    listStyle: 'none',
    padding: '0',
    margin: '0',
    fontSize: '18px',
    '& li':{
      paddingTop: '10px',
      display: 'flex',
      '& figure':{
        margin: '0px',
        width: '12px',
        marginRight: '8px',
        position: 'relative',
        top: '2px',
        '& img':{
          width: '100%',
        }
      },
      '& span':{
        width: 'calc(100% - 20px)',
        display: 'inline-block',
      },
      '& ul':{
        paddingLeft: '25px',
        '& li':{
          display: 'list-item',
          paddingTop: '4px',
        }
      }
    }
  },
  graphBig:{
    margin: '0 -250px',
  },
  padL40:{
    paddingLeft: '40px',
  },
  pfRowGrayBox:{
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    margin: '18px 0px',
    '& p':{
      marginTop: '0',
      marginBottom: '0',
      display: 'flex',
    },
    '& span':{
      display: 'inline-block',
      width: '50%',
      '&:first-child':{
        maxWidth: '230px',
      }
    }
  },
  listStyleDecimal:{
    paddingLeft: '10px',
    margin: '0px',
    '& li':{
      listStyle: 'none',
    }
  },
  noteText:{
    fontStyle: 'italic',
    color: '#000',
    marginTop: '20px',
    marginLeft: '30px',
    fontFamily: '"MuseoSans-500"',
    fontWeight: 'normal',
  }
} );

export default styles;
