function incomeStatementSheetData(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module9Data, module10Data, module13Data,
  module14Data, module15Data, module17Data, module18Data, existingStudentLoanData, studentLoanTaxData, expenseCalculationsData, assumptionsData, inputTaxesData,
  investmentsData, depreciationSheduleData, existingCarLoanData, carLoan1Data, carLoan2Data, mortgage1Data, mortgage2Data, buyingAHomeData, invPropertyRoiData, databaseData,
  fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData,
  fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingCreditCardDebtData, existingHomeMortgageData,
  taxStatementData, spouseStudentLoanData, carsExistingVehicleDepreciationData) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;
  let effectiveTaxRateValue = 0;
  let socialSecurityTaxesValue = 0;
  let medicareTaxesValue = 0;

  year = parseInt(module1Data.start_year, 10);
  yearLimit = parseInt(module1Data.start_year, 10) + 15;

  let incomeEarnedDuring = 0;
  let dentistIncomeValue = 0;
  let bonusTipsCommissionValue = 0;
  let supplementaryIncomeValue = 0;
  let dentalHygienistIncomeValue = 0;
  let bonusTipsCommissionValueSecond = 0;
  let supplementaryIncomeValueSecond = 0;
  let dentistIncomeValueSecond = 0;
  let bonusTipsCommissionValueThird = 0;
  let supplementaryIncomeValueThird = 0;
  let dentistIncomeValueFourth = 0;
  let bonusTipsCommissionValueFourth = 0;
  let supplementaryIncomeValueFourth = 0;
  let spousesIncomeValue = 0;
  let spousesIncomeValue1 = 0;
  let spousesIncomeValue2 = 0;
  let invPropertyRentalIncomeValue;
  let collegeRoomBoardValue = 0;
  let offCampusFoodValue = 0;
  let grossIncomeTotal = 0;

  let retirementContributionsValue1 = 0;
  let retirementContributionsValue2 = 0;
  let retirementContributionsValue3 = 0;
  let retirementContributionsValue4 = 0;

  let disposableIncome = 0;

  let collegeTuitionValue = 0;

  let PetValue = 0;
  let ChildrenValue = 0;
  let adjustments401kValue = 0;
  let adjustments401kValue1 = 0;
  let adjustments401kValue2 = 0;
  let adjustments401kValue3 = 0;
  let adjustmentsStudentLoanInterestValue = 0;
  let selfEmployementTaxValue = 0;
  let selfEmployementTaxValue1 = 0;
  let selfEmployementTaxValue2 = 0;
  let adjustedGrossIncomeValue = 0;
  let itemizedmortgageValue = 0;
  /* const intemizedpointsmortgageValue = 0;
const intemizedpointsmortgageValue1 = 0;
const intemizedpointsmortgageValue2 = 0; */
  let intemizedPropertyDepreciationValue = 0;
  let intemizedHomePropertyTaxesValue = 0;
  let intemizedHomePropertyTaxesValue1 = 0;
  let deductionsHomePropertyTaxesValue = 0;
  let intemizedHomePropertyTaxesValue2 = 0;
  let intemizedHealthInsuranceValue = 0;
  let intemizedCharitableContributionsValue = 0;
  let totalItemizedDeductionsValue = 0;
  let standardDeductionsValue = 0;
  let totalDeductionValue = 0;
  let totalREIPropertyDeductions = 0;
  let sumOfitemizedProducts = 0;
  let federalTaxableIncomeValue = 0;

  let expenseCalculationsB7 = 0;
  let expenseCalculationsB8 = 0;
  if (module2Data.on_campus_start_year !== undefined) {
    expenseCalculationsB7 = module2Data.on_campus_start_year;
  }
  // Expense Calculation B8
  if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && module2Data.graduation_year > 0) {
    expenseCalculationsB8 = module2Data.graduation_year;
  } else if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year) {
    expenseCalculationsB8 = parseInt(module2Data.off_campus_start_year, 10) - 1;
  } else if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && module2Data.graduation_year > 0) {
    expenseCalculationsB8 = module2Data.graduation_year;
  } else {
    expenseCalculationsB8 = 0;
  }

  let expenseCalculationsB27 = 0;
  let expenseCalculationsB28 = 0;
  // Expense Calcualtion B24
  if (module2Data.on_campus_start_year !== undefined) {
    expenseCalculationsB27 = module2Data.off_campus_start_year;
  }
  if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && module2Data.graduation_year > 0) {
    expenseCalculationsB28 = module2Data.graduation_year;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year) {
    expenseCalculationsB28 = parseInt(module2Data.on_campus_start_year, 10) - 1;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && module2Data.graduation_year > 0) {
    expenseCalculationsB28 = module2Data.graduation_year;
  } else {
    expenseCalculationsB28 = 0;
  }

  let expenseCalculationsB48 = 0;
  let expenseCalculationsB49 = 0;
  if (module4Data.start_year !== undefined) {
    expenseCalculationsB48 = module4Data.start_year;
  }
  if (module4Data.end_year !== undefined) {
    expenseCalculationsB49 = module4Data.end_year;
  }

  let expenseCalculationsB69 = 0;
  let expenseCalculationsB70 = 0;
  if (module5Data.start_year !== undefined) {
    expenseCalculationsB69 = module5Data.start_year;
  }
  if (module5Data.end_year !== undefined) {
    expenseCalculationsB70 = module5Data.end_year;
  }

  let expenseCalculationsB90 = 0;
  let expenseCalculationsB91 = 0;
  // Expense Calculation B75
  if (module6Data.on_campus_start_year !== undefined) {
    expenseCalculationsB90 = module6Data.on_campus_start_year;
  }
  if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationsB91 = module6Data.graduation_year;
  } else if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year) {
    expenseCalculationsB91 = parseInt(module6Data.off_campus_start_year, 10) - 1;
  } else if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationsB91 = module6Data.graduation_year;
  } else {
    expenseCalculationsB91 = 0;
  }

  let expenseCalculationsB110 = 0;
  let expenseCalculationsB111 = 0;
  if (module6Data.off_campus_start_year !== undefined) {
    expenseCalculationsB110 = module6Data.off_campus_start_year;
  }
  // Expense Calculation B91
  if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationsB111 = module6Data.graduation_year;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year) {
    expenseCalculationsB111 = parseInt(module6Data.on_campus_start_year, 10) - 1;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationsB111 = module6Data.graduation_year;
  } else {
    expenseCalculationsB111 = 0;
  }

  let expenseCalculationsB131 = 0;
  let expenseCalculationsB132 = 0;
  if (module8Data.start_year !== undefined) {
    expenseCalculationsB131 = module8Data.start_year;
  }
  if (module8Data.end_year !== undefined) {
    expenseCalculationsB132 = module8Data.end_year;
  }

  let expenseCalculationsB152 = 0;
  let expenseCalculationsB153 = 0;
  if (module15Data.on_campus_start_year !== undefined) {
    expenseCalculationsB152 = module15Data.on_campus_start_year;
  }
  // Expense Calculations B125
  if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_food !== undefined && module15Data.off_campus_food === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationsB153 = module15Data.graduation_year;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_food !== undefined && module15Data.off_campus_food > 0 && module15Data.off_campus_food > module15Data.on_campus_start_year) {
    expenseCalculationsB153 = parseInt(module15Data.off_campus_food, 10) - 1;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_food !== undefined && module15Data.off_campus_food > 0 && module15Data.on_campus_start_year > module15Data.off_campus_food && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationsB153 = module15Data.graduation_year;
  } else {
    expenseCalculationsB153 = 0;
  }

  let expenseCalculationsB172 = 0;
  let expenseCalculationsB173 = 0;
  if (module15Data.off_campus_food !== undefined) {
    expenseCalculationsB172 = module15Data.off_campus_food;
  }
  // Expemse Calculations B141
  if (module15Data.off_campus_food !== undefined && module15Data.off_campus_food > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationsB173 = module15Data.graduation_year;
  } else if (module15Data.off_campus_food !== undefined && module15Data.off_campus_food > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_food) {
    expenseCalculationsB173 = parseInt(module15Data.on_campus_start_year, 10) - 1;
  } else if (module15Data.off_campus_food !== undefined && module15Data.off_campus_food > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_food > module15Data.on_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationsB173 = module15Data.graduation_year;
  } else {
    expenseCalculationsB173 = 0;
  }

  let expenseCalculationsB193 = 0;
  let expenseCalculationsB194 = 0;
  if (module17Data.start_year !== undefined) {
    expenseCalculationsB193 = module17Data.start_year;
  }
  if (expenseCalculationsB193 !== '' && module17Data.end_year !== undefined) {
    expenseCalculationsB194 = module17Data.end_year;
  }

  let rentValue = 0;
  let rentValue1 = 0;
  let rentValue2 = 0;
  let rentValue3 = 0;
  // Individual Values
  let rentValue10 = 0;
  let rentValue11 = 0;
  let rentValue12 = 0;
  let rentValue13 = 0;
  let rentValue14 = 0;
  let rentValue15 = 0;
  let rentValue16 = 0;

  let utilitiesValue = 0;
  let utilitiesValue1 = 0;
  let utilitiesValue2 = 0;
  let utilitiesValue3 = 0;
  // Individual values
  let utilitiesValue10 = 0;
  let utilitiesValue11 = 0;
  let utilitiesValue12 = 0;
  let utilitiesValue13 = 0;
  let utilitiesValue14 = 0;
  let utilitiesValue15 = 0;
  let utilitiesValue16 = 0;

  let FoodValue = 0;
  let FoodValue1 = 0;
  let FoodValue2 = 0;
  let FoodValue3 = 0;
  // Individual values
  let FoodValue10 = 0;
  let FoodValue11 = 0;
  let FoodValue12 = 0;
  let FoodValue13 = 0;
  let FoodValue14 = 0;
  let FoodValue15 = 0;
  let FoodValue16 = 0;

  let clothingValue = 0;
  let clothingValue1 = 0;
  let clothingValue2 = 0;
  let clothingValue3 = 0;
  // Individual Values
  let clothingValue10 = 0;
  let clothingValue11 = 0;
  let clothingValue12 = 0;
  let clothingValue13 = 0;
  let clothingValue14 = 0;
  let clothingValue15 = 0;
  let clothingValue16 = 0;
  let clothingValue17 = 0;
  let clothingValue18 = 0;
  let clothingValue19 = 0;

  let EntertainmentValue = 0;
  let EntertainmentValue1 = 0;
  let EntertainmentValue2 = 0;
  let EntertainmentValue3 = 0;
  // const EntertainmentValue5 = 0;
  // Individual Values
  let EntertainmentValue10 = 0;
  let EntertainmentValue11 = 0;
  let EntertainmentValue12 = 0;
  let EntertainmentValue13 = 0;
  let EntertainmentValue14 = 0;
  let EntertainmentValue15 = 0;
  let EntertainmentValue16 = 0;
  let EntertainmentValue17 = 0;
  let EntertainmentValue18 = 0;
  let EntertainmentValue19 = 0;

  let TechnologyValue = 0;
  let TechnologyValue1 = 0;
  let TechnologyValue2 = 0;
  let TechnologyValue3 = 0;
  // Individual Values
  let TechnologyValue10 = 0;
  let TechnologyValue11 = 0;
  let TechnologyValue12 = 0;
  let TechnologyValue13 = 0;
  let TechnologyValue14 = 0;
  let TechnologyValue15 = 0;
  let TechnologyValue16 = 0;
  let TechnologyValue17 = 0;
  let TechnologyValue18 = 0;
  let TechnologyValue19 = 0;

  let TransportationValue = 0;
  let TransportationValue1 = 0;
  let TransportationValue2 = 0;
  let TransportationValue3 = 0;
  // Individual Values
  let TransportationValue10 = 0;
  let TransportationValue11 = 0;
  let TransportationValue12 = 0;
  let TransportationValue13 = 0;
  let TransportationValue14 = 0;
  let TransportationValue15 = 0;
  let TransportationValue16 = 0;
  let TransportationValue17 = 0;
  let TransportationValue18 = 0;
  let TransportationValue19 = 0;

  let carPaymentValue = 0;
  let carPaymentValue1 = 0;
  let carPaymentValue2 = 0;
  let carPaymentValue3 = 0;
  let carPaymentValue4 = 0;
  let carPaymentValue5 = 0;
  let carPaymentValue6 = 0;

  let CarMaintenanceValue = 0;
  let CarMaintenanceValue1 = 0;
  let CarMaintenanceValue2 = 0;
  let CarMaintenanceValue3 = 0;
  let CarMaintenanceValue4 = 0;
  let CarMaintenanceValue5 = 0;
  let existingCarMaintenanceValue1 = 0;
  let existingCarMaintenanceValue2 = 0;
  let existingCarMaintenanceValue3 = 0;
  let existingCarMaintenanceValue4 = 0;
  // let existingCarMaintenanceValue5 = 0;
  // let existingCarMaintenanceValue6 = 0;
  // let existingCarMaintenanceValue7 = 0;
  // let existingCarMaintenanceValue8 = 0;

  let CarInsuranceValue = 0;
  let CarInsuranceValue1 = 0;
  let CarInsuranceValue2 = 0;

  let CarInsuranceValue4 = 0;
  let CarInsuranceValue5 = 0;
  let existingCarInsuranceValue1 = 0;
  let existingCarInsuranceValue2 = 0;
  let existingCarInsuranceValue3 = 0;
  let existingCarInsuranceValue4 = 0;

  let GasValue = 0;
  let GasValue1 = 0;
  let GasValue2 = 0;
  let GasValue3 = 0;
  let GasValue4 = 0;
  let GasValue5 = 0;
  let existingCarGasValue1 = 0;
  let existingCarGasValue2 = 0;
  let existingCarGasValue3 = 0;
  let existingCarGasValue4 = 0;

  let MiscellaneousValue = 0;
  let MiscellaneousValue1 = 0;
  let MiscellaneousValue2 = 0;
  let MiscellaneousValue3 = 0;
  // Individual values
  let MiscellaneousValue10 = 0;
  let MiscellaneousValue11 = 0;
  let MiscellaneousValue12 = 0;
  let MiscellaneousValue13 = 0;
  let MiscellaneousValue14 = 0;
  let MiscellaneousValue15 = 0;
  let MiscellaneousValue16 = 0;
  let MiscellaneousValue17 = 0;
  let MiscellaneousValue18 = 0;
  let MiscellaneousValue19 = 0;

  let healthInsuranceDeductibleValue = 0;
  let healthInsuranceDeductibleValue1 = 0;
  let healthInsuranceDeductibleValue2 = 0;
  let healthInsuranceDeductibleValue3 = 0;
  // Individual Values
  let healthInsuranceDeductibleValue10 = 0;
  let healthInsuranceDeductibleValue11 = 0;
  let healthInsuranceDeductibleValue12 = 0;
  let healthInsuranceDeductibleValue13 = 0;
  let healthInsuranceDeductibleValue14 = 0;
  let healthInsuranceDeductibleValue15 = 0;
  let healthInsuranceDeductibleValue16 = 0;
  let healthInsuranceDeductibleValue17 = 0;
  let healthInsuranceDeductibleValue18 = 0;
  let healthInsuranceDeductibleValue19 = 0;

  let healthInsurancePremiumValue = 0;
  let healthInsurancePremiumValue1 = 0;
  let healthInsurancePremiumValue2 = 0;
  let healthInsurancePremiumValue3 = 0;
  // Individual Values
  let healthInsurancePremiumValue10 = 0;
  let healthInsurancePremiumValue11 = 0;
  let healthInsurancePremiumValue12 = 0;
  let healthInsurancePremiumValue13 = 0;
  let healthInsurancePremiumValue14 = 0;
  let healthInsurancePremiumValue15 = 0;
  let healthInsurancePremiumValue16 = 0;
  let healthInsurancePremiumValue17 = 0;
  let healthInsurancePremiumValue18 = 0;
  let healthInsurancePremiumValue19 = 0;

  const incomeStatementCompleteData = {};

  const totalDisposableGraph = [];
  const totalLivingExpensesGraph = [];
  const totalCarExpensesGraph = [];
  const totalRealEstateExpensesGraph = [];
  const totalGrossIncomeGraph = [];
  const totalTaxesGraph = [];
  const incomeAfterTaxesGraph = [];
  const depreciationExpensesGraph = [];
  const totalInterestExpensesGraph = [];
  const totalExpensesGraph = [];
  const netIncomeGraph = [];

  let spousesTotalIncome = 0;
  for (year; year < yearLimit; year += 1) {
    incomeStatementCompleteData[year] = {};

    incomeStatementCompleteData[year]['Gross Income'] = {};
    incomeStatementCompleteData[year].deductionsBeforeFederalIncomeTax = {};
    incomeStatementCompleteData[year].livingExpenses = {};
    incomeStatementCompleteData[year].taxes = {};
    incomeStatementCompleteData[year]['Tax Credits'] = {};
    incomeStatementCompleteData[year].DepreciationExpenses = {};
    incomeStatementCompleteData[year].InterestExpenses = {};
    incomeStatementCompleteData[year].Adjustments = {};
    incomeStatementCompleteData[year]['Total Adjustments'] = {};
    incomeStatementCompleteData[year]['Adjusted Gross Income'] = {};
    incomeStatementCompleteData[year]['Itemized Deductions'] = {};
    incomeStatementCompleteData[year]['REI Property Deductions'] = {};
    incomeStatementCompleteData[year]['Standard Deduction'] = {};
    incomeStatementCompleteData[year]['Personal Exemptions'] = {};
    incomeStatementCompleteData[year]['Total Deductions'] = {};
    incomeStatementCompleteData[year]['Investment Property Sale'] = {};

    /** **************************************
** Gross Income and Total  Income **
**************************************** */
    // Income Earned during Higher Education
    let conditionCheck1 = 0;
    let conditionCheck2 = 0;
    let conditionCheck3 = 0;
    let higherEducation1 = 0;
    let higherEducation2 = 0;
    let higherEducation3 = 0;

    // let stateLocalTaxesValue = 0;
    // let stateLocalTaxesValue1 = 0;
    // let stateLocalTaxesValue2 = 0;
    let stateLocalTaxesValue3 = 0;
    let stateLocalTaxesValue4 = 0;
    let stateLocalTaxesValue5 = 0;
    incomeStatementCompleteData[year]['Gross Income']['Income Earned during Higher Education'] = 0;
    // Higher Education - Income
    let conditionCheck = 0;
    if (module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year !== undefined && module4Data.start_year !== '') {
      if (year === module4Data.start_year) {
        dentistIncomeValue = module4Data.yearly_income;
        conditionCheck = 1;
      } else if (module4Data.end_year !== undefined && module4Data.end_year !== '') {
        if (year >= module4Data.start_year && year <= module4Data.end_year) {
          if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            dentistIncomeValue *= ((assumptionsData[year].Income) / 100);
            conditionCheck = 2;
          } else {
            dentistIncomeValue = 0;
          }
        } else {
          dentistIncomeValue = 0;
        }
      } else {
        dentistIncomeValue = 0;
      }
    } else {
      dentistIncomeValue = 0;
    }
    incomeStatementCompleteData[year]['Gross Income'][module4Data.occupation_title] = dentistIncomeValue;
    incomeStatementCompleteData[year]['Income Condition'] = conditionCheck;

    // Bonus / Tips / Commission
    // let conditionCheck = 0;
    if (module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year !== undefined && module4Data.start_year !== '') {
      if (year === module4Data.start_year) {
        bonusTipsCommissionValue = module4Data.bonus_or_tips_or_commission;
        conditionCheck = 1;
      } else if (module4Data.end_year !== undefined && module4Data.end_year !== ''
        && assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        if (year >= module4Data.start_year && year <= module4Data.end_year) {
          bonusTipsCommissionValue *= ((assumptionsData[year].Income) / 100);
          conditionCheck = 2;
        } else {
          bonusTipsCommissionValue = 0;
        }
      } else {
        bonusTipsCommissionValue = 0;
      }
    } else {
      bonusTipsCommissionValue = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission'] = bonusTipsCommissionValue;
    incomeStatementCompleteData[year]['Bonus Tips Condition'] = conditionCheck;

    // Supplementary Income
    // let conditionCheck = 0;
    if (module4Data.second_yearly_income !== undefined && module4Data.second_yearly_income !== ''
      && module4Data.second_start_year !== undefined && module4Data.second_start_year !== '') {
      if (year === module4Data.second_start_year) {
        supplementaryIncomeValue = module4Data.second_yearly_income;
        conditionCheck = 1;
      } else if (module4Data.second_end_year !== undefined && module4Data.second_end_year !== ''
        && assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        if (year >= module4Data.second_start_year && year <= module4Data.second_end_year) {
          supplementaryIncomeValue *= ((assumptionsData[year].Income) / 100);
          conditionCheck = 2;
        } else {
          supplementaryIncomeValue = 0;
        }
      } else {
        supplementaryIncomeValue = 0;
      }
    } else {
      supplementaryIncomeValue = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Supplementary Income'] = supplementaryIncomeValue;
    incomeStatementCompleteData[year]['Supplementary Condition'] = conditionCheck;

    // Dental Hygienist - Income
    // let conditionCheck = 0;
    if (module5Data.yearly_income !== undefined && module5Data.yearly_income !== ''
      && module5Data.start_year !== undefined && module5Data.end_year !== '') {
      if (year === module5Data.start_year) {
        dentalHygienistIncomeValue = module5Data.yearly_income;
        conditionCheck = 1;
      } else if (year >= module5Data.start_year && year <= module5Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          dentalHygienistIncomeValue *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          dentalHygienistIncomeValue = 0;
        }
      } else {
        dentalHygienistIncomeValue = 0;
      }
    } else {
      dentalHygienistIncomeValue = 0;
    }
    incomeStatementCompleteData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] = dentalHygienistIncomeValue;
    incomeStatementCompleteData[year]['Income - Second Condition'] = conditionCheck;

    // Dental Bonus / Tips / Commission - 2
    // let conditionCheck = 0;
    if (module5Data.bonus_or_tips_or_commission !== undefined && module5Data.bonus_or_tips_or_commission !== ''
      && module5Data.start_year !== undefined && module5Data.end_year !== undefined) {
      if (year === module5Data.start_year) {
        bonusTipsCommissionValueSecond = module5Data.bonus_or_tips_or_commission;
        conditionCheck = 1;
      } else if (year >= module5Data.start_year && year <= module5Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          bonusTipsCommissionValueSecond *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          bonusTipsCommissionValueSecond = 0;
        }
      } else {
        bonusTipsCommissionValueSecond = 0;
      }
    } else {
      bonusTipsCommissionValueSecond = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Second'] = bonusTipsCommissionValueSecond;
    incomeStatementCompleteData[year]['Bonus / Tips / Commission - Second Condition'] = conditionCheck;

    // Supplementary Income - 2
    // let conditionCheck = 0;
    if (module5Data.second_yearly_income !== undefined && module5Data.second_yearly_income !== ''
      && module5Data.second_start_year !== undefined && module5Data.second_end_year !== undefined) {
      if (year === module5Data.second_start_year) {
        supplementaryIncomeValueSecond = module5Data.second_yearly_income;
        conditionCheck = 1;
      } else if (year >= module5Data.second_start_year && year <= module5Data.second_end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          supplementaryIncomeValueSecond *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          supplementaryIncomeValueSecond = 0;
        }
      } else {
        supplementaryIncomeValueSecond = 0;
      }
    } else {
      supplementaryIncomeValueSecond = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Second'] = supplementaryIncomeValueSecond;
    incomeStatementCompleteData[year]['Supplementary Income - Second Condition'] = conditionCheck;

    // More Higher Education - Income
    // let conditionCheck = 0;
    if (module8Data.yearly_income !== undefined && module8Data.yearly_income !== ''
      && module8Data.start_year !== undefined && module8Data.end_year !== undefined) {
      if (year === module8Data.start_year) {
        dentistIncomeValueSecond = module8Data.yearly_income;
        conditionCheck = 1;
      } else if (year >= module8Data.start_year && year <= module8Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          dentistIncomeValueSecond *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          dentistIncomeValueSecond = 0;
        }
      } else {
        dentistIncomeValueSecond = 0;
      }
    } else {
      dentistIncomeValueSecond = 0;
    }
    incomeStatementCompleteData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] = dentistIncomeValueSecond;
    incomeStatementCompleteData[year]['Income Second Condition'] = conditionCheck;

    // Calculate Bonus - Tips - Third in the Income Statement
    // let conditionCheck = 0;
    if (module8Data.bonus_or_tips_or_commission !== undefined && module8Data.bonus_or_tips_or_commission !== ''
      && module8Data.start_year !== undefined && module8Data.end_year !== undefined) {
      if (year === module8Data.start_year) {
        bonusTipsCommissionValueThird = module8Data.bonus_or_tips_or_commission;
        conditionCheck = 1;
      } else if (year >= module8Data.start_year && year <= module8Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          bonusTipsCommissionValueThird *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          bonusTipsCommissionValueThird = 0;
        }
      } else {
        bonusTipsCommissionValueThird = 0;
      }
    } else {
      bonusTipsCommissionValueThird = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Third'] = bonusTipsCommissionValueThird;
    incomeStatementCompleteData[year]['Bonus / Tips / Commission - Third Condition'] = conditionCheck;

    // Supplementary Income - Third
    // let conditionCheck = 0;
    if (module8Data.second_yearly_income !== undefined && module8Data.second_yearly_income !== '' && module8Data.second_start_year !== undefined && module8Data.second_start_year !== '') {
      if (year === module8Data.second_start_year) {
        supplementaryIncomeValueThird = module8Data.second_yearly_income;
        conditionCheck = 1;
      } else if (module8Data.second_start_year !== undefined && module8Data.second_end_year !== '') {
        if (year >= module8Data.second_start_year && year <= module8Data.second_end_year) {
          if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            supplementaryIncomeValueThird *= ((assumptionsData[year].Income) / 100);
            conditionCheck = 2;
          } else {
            supplementaryIncomeValueThird = 0;
          }
        } else {
          supplementaryIncomeValueThird = 0;
        }
      } else {
        supplementaryIncomeValueThird = 0;
      }
    } else {
      supplementaryIncomeValueThird = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Third'] = supplementaryIncomeValueThird;
    incomeStatementCompleteData[year]['Supplementary Income - Third Condition'] = conditionCheck;

    // Further Career Advancement - Income
    // let conditionCheck = 0;
    if (module17Data.yearly_income !== undefined && module17Data.yearly_income !== ''
      && module17Data.start_year !== undefined && module17Data.end_year !== undefined) {
      if (year === module17Data.start_year) {
        dentistIncomeValueFourth = module17Data.yearly_income;
        conditionCheck = 1;
      } else if (year >= module17Data.start_year && year <= module17Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          dentistIncomeValueFourth *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          dentistIncomeValueFourth = 0;
        }
      } else {
        dentistIncomeValueFourth = 0;
      }
    } else {
      dentistIncomeValueFourth = 0;
    }
    incomeStatementCompleteData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] = dentistIncomeValueFourth;
    incomeStatementCompleteData[year][`${module17Data.occupation_title} - Fourth Condition`] = conditionCheck;

    // Calculate Bonus - Tips - Third in the Income Statement
    // let conditionCheck = 0;
    if (module17Data.bonus_or_tips_or_commission !== undefined && module17Data.bonus_or_tips_or_commission !== ''
      && module17Data.start_year !== undefined && module17Data.end_year !== undefined) {
      if (year === module17Data.start_year) {
        bonusTipsCommissionValueFourth = module17Data.bonus_or_tips_or_commission;
        conditionCheck = 1;
      } else if (year >= module17Data.start_year && year <= module17Data.end_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Income !== undefined) {
          bonusTipsCommissionValueFourth *= (assumptionsData[year].Income / 100);
          conditionCheck = 2;
        } else {
          bonusTipsCommissionValueFourth = 0;
        }
      } else {
        bonusTipsCommissionValueFourth = 0;
      }
    } else {
      bonusTipsCommissionValueFourth = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] = bonusTipsCommissionValueFourth;
    incomeStatementCompleteData[year]['Bonus / Tips / Commission - Fourth Condition'] = conditionCheck;

    // Supplementary Income - Third
    // let conditionCheck = 0;
    if (module17Data.second_yearly_income !== undefined && module17Data.second_yearly_income !== '' && module17Data.second_start_year !== undefined && module17Data.second_start_year !== '') {
      if (year === module17Data.second_start_year) {
        supplementaryIncomeValueFourth = module17Data.second_yearly_income;
        conditionCheck = 1;
      } else if (module17Data.second_start_year !== undefined && module17Data.second_end_year !== '') {
        if (year >= module17Data.second_start_year && year <= module17Data.second_end_year) {
          if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            supplementaryIncomeValueFourth *= ((assumptionsData[year].Income) / 100);
            conditionCheck = 2;
          } else {
            supplementaryIncomeValueFourth = 0;
          }
        } else {
          supplementaryIncomeValueFourth = 0;
        }
      } else {
        supplementaryIncomeValueFourth = 0;
      }
    } else {
      supplementaryIncomeValueFourth = 0;
    }

    incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Fourth'] = supplementaryIncomeValueFourth;
    incomeStatementCompleteData[year]['Supplementary Income - Fourth Condition'] = conditionCheck;

    // Higher Education 1
    // Calculating sum of D8 to D19
    let sumOfHigherValues = 0;

    sumOfHigherValues = incomeStatementCompleteData[year]['Gross Income'][module4Data.occupation_title]
      + incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission']
      + incomeStatementCompleteData[year]['Gross Income']['Supplementary Income']
      + incomeStatementCompleteData[year]['Gross Income'][`${module5Data.occupation_title} - Second`]
      + incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Second']
      + incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Second']
      + incomeStatementCompleteData[year]['Gross Income'][`${module8Data.occupation_title} - Third`]
      + incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Third']
      + incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Third']
      + incomeStatementCompleteData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`]
      + incomeStatementCompleteData[year]['Gross Income']['Bonus / Tips / Commission - Fourth']
      + incomeStatementCompleteData[year]['Gross Income']['Supplementary Income - Fourth'];

    if (module2Data.income_start_year !== undefined && module2Data.income_start_year !== '') {
      if (year === module2Data.income_start_year && sumOfHigherValues === 0) {
        if (module2Data.yearly_income !== undefined && module2Data.yearly_income !== '') {
          higherEducation1 = module2Data.yearly_income;
          conditionCheck1 = 1;
        } else {
          higherEducation1 = 0;
        }
      } else if (year >= module2Data.income_start_year && year <= module2Data.income_end_year && sumOfHigherValues === 0) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          higherEducation1 = incomeEarnedDuring * ((assumptionsData[year].Income) / 100);
          conditionCheck1 = 2;
        } else {
          higherEducation1 = 0;
        }
      } else {
        higherEducation1 = 0;
      }
    }
    // Higher Education 2
    if (module6Data.income_start_year !== undefined && module6Data.income_start_year !== '') {
      if (year === module6Data.income_start_year && sumOfHigherValues === 0) {
        if (module6Data.yearly_income !== undefined && module6Data.yearly_income !== '') {
          higherEducation2 = module6Data.yearly_income;
          conditionCheck2 = 1;
        } else {
          higherEducation2 = 0;
        }
      } else if (year >= module6Data.income_start_year && year <= module6Data.income_end_year && sumOfHigherValues === 0) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          higherEducation2 = incomeEarnedDuring * ((assumptionsData[year].Income) / 100);
          conditionCheck2 = 2;
        } else {
          higherEducation2 = 0;
        }
      } else {
        higherEducation2 = 0;
      }
    }
    // Higher Education 3
    if (module15Data.income_start_year !== undefined && module15Data.income_start_year !== '') {
      if (year === module15Data.income_start_year && sumOfHigherValues === 0) {
        if (module15Data.yearly_income !== undefined && module15Data.yearly_income !== '') {
          higherEducation3 = module15Data.yearly_income;
          conditionCheck3 = 1;
        } else {
          higherEducation3 = 0;
        }
      } else if (year >= module15Data.income_start_year && year <= module15Data.income_end_year && sumOfHigherValues === 0) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          higherEducation3 = incomeEarnedDuring * ((assumptionsData[year].Income) / 100);
          conditionCheck3 = 2;
        } else {
          higherEducation3 = 0;
        }
      } else {
        higherEducation3 = 0;
      }
    }
    incomeEarnedDuring = higherEducation1 + higherEducation2 + higherEducation3;

    incomeStatementCompleteData[year]['Gross Income']['Income Earned during Higher Education'] = incomeEarnedDuring;
    incomeStatementCompleteData[year]['Income Earned Condition 1'] = conditionCheck1;
    incomeStatementCompleteData[year]['Income Earned Condition 2'] = conditionCheck2;
    incomeStatementCompleteData[year]['Income Earned Condition 3'] = conditionCheck3;

    // Spouse's Income
    // let conditionCheck = 0;
    if (module10Data.start_year !== undefined && module10Data.start_year !== ''
      && year === module10Data.start_year) {
      if (module10Data.preTaxExpectedIncome !== undefined && module10Data.preTaxExpectedIncome !== '') {
        spousesIncomeValue1 = module10Data.preTaxExpectedIncome;
        conditionCheck = 1;
      } else {
        spousesIncomeValue1 = 0;
      }
    } else if (module10Data.start_year !== undefined && module10Data.end_year !== ''
      && year >= module10Data.start_year && year <= module10Data.end_year) {
      if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        spousesIncomeValue1 = spousesIncomeValue * ((assumptionsData[year].Income) / 100);
        conditionCheck = 2;
      } else {
        spousesIncomeValue1 = 0;
      }
    } else {
      spousesIncomeValue1 = 0;
    }

    // let conditionCheck2 = 0;
    if (module10Data.second_start_year !== undefined && module10Data.second_start_year !== ''
      && year === module10Data.second_start_year) {
      if (module10Data.morePreTaxExpectedIncome !== undefined && module10Data.morePreTaxExpectedIncome !== '') {
        spousesIncomeValue2 = module10Data.morePreTaxExpectedIncome;
        conditionCheck2 = 1;
      } else {
        spousesIncomeValue2 = 0;
      }
    } else if (module10Data.second_start_year !== undefined && module10Data.second_end_year !== ''
      && year >= module10Data.second_start_year && year <= module10Data.second_end_year) {
      if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        spousesIncomeValue2 = spousesIncomeValue * ((assumptionsData[year].Income) / 100);
        conditionCheck2 = 2;
      } else {
        spousesIncomeValue2 = 0;
      }
    } else {
      spousesIncomeValue2 = 0;
    }

    spousesIncomeValue = spousesIncomeValue1 + spousesIncomeValue2;
    incomeStatementCompleteData[year]['Gross Income']['Spouses Income'] = spousesIncomeValue;
    incomeStatementCompleteData[year]['Spouses Income Condition'] = conditionCheck;
    incomeStatementCompleteData[year]['Spouses Income Second Condition'] = conditionCheck2;
    spousesTotalIncome += spousesIncomeValue;

    // Rental Income
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== ''
      && invPropertyRoiData.Data1 !== undefined
      && invPropertyRoiData.Data1[year] !== undefined
      && invPropertyRoiData.Data1[year]['Rental Income'] !== undefined) {
      invPropertyRentalIncomeValue = invPropertyRoiData.Data1[year]['Rental Income'];
    } else {
      invPropertyRentalIncomeValue = 0;
    }
    incomeStatementCompleteData[year]['Gross Income']['Rental Income'] = invPropertyRentalIncomeValue;

    // Total Income
    incomeStatementCompleteData[year]['Total Income'] = parseFloat(incomeEarnedDuring)
      + parseFloat(dentistIncomeValue)
      + parseFloat(bonusTipsCommissionValue)
      + parseFloat(supplementaryIncomeValue)
      + parseFloat(dentalHygienistIncomeValue)
      + parseFloat(bonusTipsCommissionValueSecond)
      + parseFloat(supplementaryIncomeValueSecond)
      /* parseFloat(incomeEarnedduringCareerSchool) +*/
      + parseFloat(dentistIncomeValueSecond)
      + parseFloat(bonusTipsCommissionValueThird)
      + parseFloat(supplementaryIncomeValueThird)
      + parseFloat(dentistIncomeValueFourth)
      + parseFloat(bonusTipsCommissionValueFourth)
      + parseFloat(supplementaryIncomeValueFourth)
      + parseFloat(spousesIncomeValue)
      + parseFloat(invPropertyRentalIncomeValue);
    totalGrossIncomeGraph.push(incomeStatementCompleteData[year]['Total Income']);

    // Total for new conditions based on career nd higher education expenses
    grossIncomeTotal = parseFloat(dentistIncomeValue)
      + parseFloat(bonusTipsCommissionValue)
      + parseFloat(supplementaryIncomeValue)
      + parseFloat(dentalHygienistIncomeValue)
      + parseFloat(bonusTipsCommissionValueSecond)
      + parseFloat(supplementaryIncomeValueSecond)
      + parseFloat(dentistIncomeValueSecond)
      + parseFloat(bonusTipsCommissionValueThird)
      + parseFloat(supplementaryIncomeValueThird)
      + parseFloat(dentistIncomeValueFourth)
      + parseFloat(bonusTipsCommissionValueFourth)
      + parseFloat(supplementaryIncomeValueFourth);

    /** End of Gross Income & Total Income * */

    /** **********************************************************
** Adjustments & Total Adjustments & Adjusted Gross Income **
************************************************************ */
    // Social Security
    let socialconditionCheck = 0;
    if (module4Data.start_year !== undefined
      && module4Data.end_year !== undefined
      && module4Data.self_employment !== undefined
      && year >= module4Data.start_year
      && year <= module4Data.end_year
      && module4Data.self_employment === 'Yes') {
      socialSecurityTaxesValue = (6.20 / 100) * 2;
      socialconditionCheck = 1;
    } else if (module5Data.start_year !== undefined
      && module5Data.end_year !== undefined
      && module5Data.self_employment !== undefined
      && year >= module5Data.start_year
      && year <= module5Data.end_year
      && module5Data.self_employment === 'Yes') {
      socialSecurityTaxesValue = (6.20 / 100) * 2;
      socialconditionCheck = 1;
    } else if (module8Data.start_year !== undefined
      && module8Data.end_year !== undefined
      && module8Data.self_employment !== undefined
      && year >= module8Data.start_year
      && year <= module8Data.end_year
      && module8Data.self_employment === 'Yes') {
      socialSecurityTaxesValue = (6.20 / 100) * 2;
      socialconditionCheck = 1;
    } else if (module17Data.start_year !== undefined
      && module17Data.end_year !== undefined
      && module17Data.self_employment !== undefined
      && year >= module17Data.start_year
      && year <= module17Data.end_year
      && module17Data.self_employment === 'Yes') {
      socialSecurityTaxesValue = (6.20 / 100) * 2;
      socialconditionCheck = 1;
    } else {
      socialSecurityTaxesValue = (6.20 / 100);
    }
    socialSecurityTaxesValue *= 100;

    // Medicare
    let medicareconditionCheck = 0;
    if (module4Data.start_year !== undefined
      && module4Data.end_year !== undefined
      && module4Data.self_employment !== undefined
      && year >= module4Data.start_year
      && year <= module4Data.end_year
      && module4Data.self_employment === 'Yes') {
      if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
        if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
          && inputTaxesData['Federal Income'][year] !== undefined
          && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '') {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
        && inputTaxesData['Federal Income'][year] !== undefined
        && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '') {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if (module5Data.start_year !== undefined
      && module5Data.end_year !== undefined
      && module5Data.self_employment !== undefined
      && year >= module5Data.start_year
      && year <= module5Data.end_year
      && module5Data.self_employment === 'Yes') {
      if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
        if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
          && inputTaxesData['Federal Income'][year] !== undefined
          && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '') {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
        && inputTaxesData['Federal Income'][year] !== undefined
        && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '') {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if (module8Data.start_year !== undefined
      && module8Data.end_year !== undefined
      && module8Data.self_employment !== undefined
      && year >= module8Data.start_year
      && year <= module8Data.end_year
      && module8Data.self_employment === 'Yes') {
      if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
        if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
          && inputTaxesData['Federal Income'][year] !== undefined
          && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '') {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
        && inputTaxesData['Federal Income'][year] !== undefined
        && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '') {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if (module17Data.start_year !== undefined
      && module17Data.end_year !== undefined
      && module17Data.self_employment !== undefined
      && year >= module17Data.start_year
      && year <= module17Data.end_year
      && module17Data.self_employment === 'Yes') {
      if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
        if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
          && inputTaxesData['Federal Income'][year] !== undefined
          && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '') {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
        && inputTaxesData['Federal Income'][year] !== undefined
        && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '') {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
      if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
        && inputTaxesData['Federal Income'][year] !== undefined
        && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '') {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'];
      } else {
        medicareTaxesValue = 0;
      }
    } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
      && inputTaxesData['Federal Income'][year] !== undefined
      && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '') {
      medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'];
    } else {
      medicareTaxesValue = 0;
    }

    // 401k Contributions
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts 401k'] !== undefined
      && investmentsData['Retirement Accounts 401k'][year] !== undefined
      && investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== undefined) {
      adjustments401kValue1 = investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];
    } else {
      adjustments401kValue1 = 0;
    }
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts Seph IRA'] !== undefined
      && investmentsData['Retirement Accounts Seph IRA'][year] !== undefined
      && investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] !== undefined) {
      adjustments401kValue2 = investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'];
    } else {
      adjustments401kValue2 = 0;
    }
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts Pension'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year] !== undefined
      && investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'] !== undefined) {
      adjustments401kValue3 = investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'];
    } else {
      adjustments401kValue3 = 0;
    }
    adjustments401kValue = adjustments401kValue1 + adjustments401kValue2 + adjustments401kValue3;
    incomeStatementCompleteData[year].Adjustments['401k Contributions'] = adjustments401kValue;

    // Higher Education Tuition
    // let conditionCheck = 0;
    let collegeTuitionValue1 = 0;
    if (module2Data.start_year !== undefined
      && year === module2Data.start_year) {
      if (module2Data.tuition !== undefined && module2Data.tuition !== '') {
        collegeTuitionValue1 = -module2Data.tuition;
        conditionCheck = 1;
      } else {
        collegeTuitionValue1 = 0;
      }

    } else {
      if (module2Data.start_year !== undefined && module2Data.start_year !== '' && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && year >= module2Data.start_year && year <= module2Data.graduation_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          collegeTuitionValue1 = collegeTuitionValue * ((assumptionsData[year]['Higher Education Growth Rate']) / 100);
          conditionCheck = 2;
        } else {
          collegeTuitionValue1 = 0;
        }
      }
    }



    // let conditionCheck2 = 0;
    let collegeTuitionValue2 = 0;
    if (module6Data.start_year !== undefined
      && year === module6Data.start_year) {
      if (module6Data.tuition !== undefined && module6Data.tuition !== '') {
        collegeTuitionValue2 = -module6Data.tuition;
        conditionCheck2 = 1;
      } else {
        collegeTuitionValue2 = 0;
      }
    } else {
      if (module6Data.start_year !== undefined && module6Data.start_year !== ''
        && module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
        && year >= module6Data.start_year && year <= module6Data.graduation_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          collegeTuitionValue2 = collegeTuitionValue * ((assumptionsData[year]['Higher Education Growth Rate']) / 100);
          conditionCheck2 = 2;
        } else {
          collegeTuitionValue2 = 0;
        }
      }
    }

    // let conditionCheck3 = 0;
    let collegeTuitionValue3 = 0;
    if (module15Data.start_year !== undefined
      && year === module15Data.start_year) {
      if (module15Data.tuition !== undefined && module15Data.tuition !== '') {
        collegeTuitionValue3 = -module15Data.tuition;
        conditionCheck3 = 1;
      } else {
        collegeTuitionValue3 = 0;
      }
    } else {
      if (module15Data.start_year !== undefined && module15Data.start_year !== ''
        && module15Data.graduation_year !== undefined && module15Data.graduation_year !== ''
        && year >= module15Data.start_year && year <= module15Data.graduation_year) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          collegeTuitionValue3 = collegeTuitionValue * ((assumptionsData[year]['Higher Education Growth Rate']) / 100);
          conditionCheck3 = 2;
        } else {
          collegeTuitionValue3 = 0;
        }
      }
    }

    collegeTuitionValue = collegeTuitionValue1 + collegeTuitionValue2 + collegeTuitionValue3;

    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value1'] = collegeTuitionValue1;
    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value2'] = collegeTuitionValue2;
    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value3'] = collegeTuitionValue3;

    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value1 Condition'] = conditionCheck;
    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value2 Condition'] = conditionCheck2;
    incomeStatementCompleteData[year]['Higher Education Tuition and Fees Value3 Condition'] = conditionCheck3;

    if (studentLoanTaxData !== undefined && studentLoanTaxData['Summation Data'] !== undefined && studentLoanTaxData['Summation Data'][year] !== undefined && studentLoanTaxData['Summation Data'][year] !== '') {
      adjustmentsStudentLoanInterestValue = studentLoanTaxData['Summation Data'][year];
    } else {
      adjustmentsStudentLoanInterestValue = 0;
    }
    incomeStatementCompleteData[year].Adjustments['Student Loan Interest'] = adjustmentsStudentLoanInterestValue;

    // Self Employment Tax
    selfEmployementTaxValue = 0;
    if (databaseData !== undefined && databaseData['Social Security Income Tax Limit'] !== undefined && databaseData['Social Security Income Tax Limit'][year] !== undefined
      && databaseData['Social Security Income Tax Limit'][year].Limit !== undefined && databaseData['Social Security Income Tax Limit'][year].Limit !== ''
      && incomeStatementCompleteData[year]['Total Income'] > databaseData['Social Security Income Tax Limit'][year].Limit) {
      selfEmployementTaxValue2 = (databaseData['Social Security Income Tax Limit'][year].Limit / 100) * socialSecurityTaxesValue;
    } else {
      selfEmployementTaxValue2 = (incomeStatementCompleteData[year]['Total Income'] / 100) * socialSecurityTaxesValue;
    }

    if (module4Data !== undefined && module4Data.start_year !== undefined
      && module4Data.end_year !== undefined
      && year >= module4Data.start_year && year <= module4Data.end_year
      && module4Data.self_employment !== undefined && module4Data.self_employment === 'Yes') {
      selfEmployementTaxValue1 = (incomeStatementCompleteData[year]['Total Income'] / 100) * medicareTaxesValue;

      selfEmployementTaxValue = (selfEmployementTaxValue1 + selfEmployementTaxValue2) / 2;
    } else if (module5Data !== undefined && module5Data.start_year !== undefined
      && module5Data.end_year !== undefined
      && year >= module5Data.start_year && year <= module5Data.end_year
      && module5Data.self_employment !== undefined && module5Data.self_employment === 'Yes') {
      selfEmployementTaxValue1 = (incomeStatementCompleteData[year]['Total Income'] / 100) * medicareTaxesValue;
      selfEmployementTaxValue = (selfEmployementTaxValue1 + selfEmployementTaxValue2) / 2;
    } else if (module8Data !== undefined && module8Data.start_year !== undefined && module8Data.end_year !== undefined
      && year >= module8Data.start_year && year <= module8Data.end_year
      && module8Data.self_employment !== undefined && module8Data.self_employment === 'Yes') {
      selfEmployementTaxValue1 = (incomeStatementCompleteData[year]['Total Income'] / 100) * medicareTaxesValue;
      selfEmployementTaxValue = (selfEmployementTaxValue1 + selfEmployementTaxValue2) / 2;
    } else if (module17Data !== undefined && module17Data.start_year !== undefined && module17Data.end_year !== undefined
      && year >= module17Data.start_year && year <= module17Data.end_year
      && module17Data.self_employment !== undefined && module17Data.self_employment === 'Yes') {
      selfEmployementTaxValue1 = (incomeStatementCompleteData[year]['Total Income'] / 100) * medicareTaxesValue;
      selfEmployementTaxValue = (selfEmployementTaxValue1 + selfEmployementTaxValue2) / 2;
    } else {
      selfEmployementTaxValue = 0;
    }
    incomeStatementCompleteData[year].Adjustments['Self-Employment Tax'] = selfEmployementTaxValue;

    // Total Adjustments
    incomeStatementCompleteData[year]['Total Adjustments'] = parseFloat(adjustments401kValue)
      + parseFloat(adjustmentsStudentLoanInterestValue)
      + parseFloat(selfEmployementTaxValue);

    // Adjusted Gross Income
    if (parseFloat(incomeStatementCompleteData[year]['Total Income']) - parseFloat(incomeStatementCompleteData[year]['Total Adjustments']) > 0) {
      adjustedGrossIncomeValue = parseFloat(incomeStatementCompleteData[year]['Total Income']) - parseFloat(incomeStatementCompleteData[year]['Total Adjustments']);
    } else {
      adjustedGrossIncomeValue = 0;
    }
    incomeStatementCompleteData[year]['Adjusted Gross Income'] = adjustedGrossIncomeValue;
    /** End of Adjustements & Total Adjustments & Adjusted Gross Income * */

    /** **********************
** Itemized Deductions **
************************ */
    // Home Mortgage Interest, Points, & PMI
    itemizedmortgageValue = 0;
    let itemizedmortgageValue1 = 0;
    let itemizedmortgageValue2 = 0;
    let itemizedmortgageValue3 = 0;
    // let itemizedmortgageValue4 = 0;
    let itemizedmortgageValue5 = 0;
    // const itemizedmortgageValue6 = 0;
    // const itemizedmortgageValue7 = 0;
    let itemizedmortgageValue8 = 0;
    let firstSectionval1 = 0;
    let firstSectionval2 = 0;
    let firstSectionval3 = 0;

    if (mortgage1Data !== undefined
      && mortgage1Data['Summation Data'] !== undefined
      && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year].BeginningBalance !== undefined && mortgage1Data['Summation Data'][year].EndingBalance !== undefined) {
      firstSectionval1 = (mortgage1Data['Summation Data'][year].BeginningBalance + mortgage1Data['Summation Data'][year].EndingBalance) / 2;
    }

    if (existingHomeMortgageData !== undefined
      && existingHomeMortgageData['Summation Data'] !== undefined
      && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].BeginningBalance !== undefined && existingHomeMortgageData['Summation Data'][year].EndingBalance !== undefined) {
      firstSectionval2 = (existingHomeMortgageData['Summation Data'][year].BeginningBalance + existingHomeMortgageData['Summation Data'][year].EndingBalance) / 2;
    }

    // Existing Home Mortgage
    if (existingHomeMortgageData !== undefined
      && existingHomeMortgageData !== ''
      && existingHomeMortgageData['Summation Data'] !== undefined
      && existingHomeMortgageData['Summation Data'][year] !== undefined
      && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== '') {
      itemizedmortgageValue2 = existingHomeMortgageData['Summation Data'][year].LaggingInterest;
    } else {
      itemizedmortgageValue2 = 0;
    }

    if (mortgage1Data !== undefined
      && mortgage1Data['Summation Data'] !== undefined
      && mortgage1Data['Summation Data'][year] !== undefined
      && buyingAHomeData !== undefined
      && buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined) {
      if (year === module13Data.year_of_purchase) {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest
          + buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
      } else {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest;
      }
    } else {
      itemizedmortgageValue1 = 0;
    }

    if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Mortgage Interest Cap'] !== undefined && databaseData.Data['Mortgage Interest Cap'] !== '' && (firstSectionval2 + firstSectionval1) > databaseData.Data['Mortgage Interest Cap']) {
      firstSectionval3 = databaseData.Data['Mortgage Interest Cap'] / (firstSectionval1 + firstSectionval2);
      itemizedmortgageValue8 = firstSectionval3 * (itemizedmortgageValue1 + itemizedmortgageValue2);
    } else {
      if (year === module13Data.year_of_purchase) {
        if (mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Summation Data'] !== undefined && mortgage1Data['Summation Data'] !== '' && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year] !== ''
          && buyingAHomeData !== undefined && buyingAHomeData !== '' && buyingAHomeData['Closing Cost'] !== undefined && buyingAHomeData['Closing Cost'] !== '' && buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined) {
          itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest
            + buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
        } else {
          itemizedmortgageValue1 = 0;
        }
      } else if (mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Summation Data'] !== undefined && mortgage1Data['Summation Data'] !== ''
        && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year] !== '' && mortgage1Data['Summation Data'][year].LaggingInterest !== undefined) {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest;
      } else {
        itemizedmortgageValue1 = 0;
      }
      itemizedmortgageValue8 = itemizedmortgageValue1 + itemizedmortgageValue2;
    }

    // Buying a Home D25
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year >= module10Data.marriege_year) {
      if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined
        && databaseData.Data['PMI Deduction Third'] !== undefined
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Third']
        && buyingAHomeData !== undefined && buyingAHomeData !== ''&& buyingAHomeData.Data1 !== undefined && buyingAHomeData.Data1[year] !== undefined
        && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined) {
        itemizedmortgageValue3 = buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance'];
      } else if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined
        && databaseData.Data['PMI Deduction'] !== undefined
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] > databaseData.Data['PMI Deduction Third']
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Fourth']
        && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined
        && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined) {
        itemizedmortgageValue3 = (Math.ceil(incomeStatementCompleteData[year]['Adjusted Gross Income'] - databaseData.Data['PMI Deduction Third'], 1000) / 10000) * (buyingAHomeData.Data1[year]['Private Mortgage Insurance'] * existingHomeData.Data1[year]['Private Mortgage Insurance']);
      } else if (databaseData !== undefined && databaseData !== ''
        && databaseData.Data !== undefined
        && databaseData.Data['PMI Deduction'] !== undefined
        && buyingAHomeData !== ''&& buyingAHomeData.Data1 !== undefined
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction']
        && buyingAHomeData !== undefined
        && buyingAHomeData.Data1[year] !== undefined
        && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
        && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined
        && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined) {
        itemizedmortgageValue3 = buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance'];
      } else if (databaseData !== undefined && databaseData !== ''
        && databaseData.Data !== undefined
        && databaseData.Data['PMI Deduction'] !== undefined
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] > databaseData.Data['PMI Deduction']
        && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Second']
        && buyingAHomeData !== ''&& buyingAHomeData.Data1 !== undefined
        && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined
        && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
        && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined
        && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined) {
        itemizedmortgageValue3 = (Math.ceil(incomeStatementCompleteData[year]['Adjusted Gross Income'] - databaseData.Data['PMI Deduction'], 500) / 5000) * (buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance']);
      } else {
        itemizedmortgageValue3 = 0;
      }
    } else {
      itemizedmortgageValue3 = 0;
    }
    // itemizedmortgageValue4 = itemizedmortgageValue1 + itemizedmortgageValue2 + itemizedmortgageValue3;

    // Second Section
    if (module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' && year === module13Data.year_of_purchase && buyingAHomeData !== undefined && buyingAHomeData['Closing Cost'] !== undefined && buyingAHomeData['Closing Cost']['Loan Acquisition Points'] !== undefined && buyingAHomeData['Closing Cost']['Origination Points'] !== undefined) {
      itemizedmortgageValue5 = buyingAHomeData['Closing Cost']['Loan Acquisition Points'] + buyingAHomeData['Closing Cost']['Origination Points'];
    } else {
      itemizedmortgageValue5 = 0;
    }

    // if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year < module10Data.marriege_year ){
    //  if(  databaseData !== undefined && databaseData !== '' &&  databaseData['Data'] !== undefined &&  databaseData['Data']['PMI Deduction'] !== undefined && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData['Data']['PMI Deduction']
    // &&  existingHomeData !== undefined &&  existingHomeData['Data1'][year] !== undefined &&  existingHomeData['Data1'][year]['Private Mortgage Insurance'] !== undefined){
    //      itemizedmortgageValue6 = existingHomeData['Data1'][year]['Private Mortgage Insurance'];
    //  } else{
    //      if(  databaseData !== undefined && databaseData !== '' &&  databaseData['Data'] !== undefined &&  databaseData['Data']['PMI Deduction'] !== undefined
    // && incomeStatementCompleteData[year]['Adjusted Gross Income'] > databaseData['Data']['PMI Deduction'] && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData['Data']['PMI Deduction Second']
    //  &&  existingHomeData !== undefined &&  existingHomeData['Data1'][year] !== undefined &&  existingHomeData['Data1'][year]['Private Mortgage Insurance'] !== undefined){
    //          itemizedmortgageValue6 = ( Math.ceil(incomeStatementCompleteData[year]['Adjusted Gross Income'] - databaseData['Data']['PMI Deduction'], 500) / 5000 ) * existingHomeData['Data1'][year]['Private Mortgage Insurance'];
    //      }
    //      else{
    //          if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year >= module10Data.marriege_year ){
    //              if(  databaseData !== undefined && databaseData !== '' &&  databaseData['Data'] !== undefined &&  databaseData['Data']['PMI Deduction Third'] !== undefined
    // && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData['Data']['PMI Deduction Third'] &&  existingHomeData !== undefined &&  existingHomeData['Data1'][year] !== undefined
    // &&  existingHomeData['Data1'][year]['Private Mortgage Insurance'] !== undefined ){
    //                  itemizedmortgageValue6 = existingHomeData['Data1'][year]['Private Mortgage Insurance'];
    //              }
    //              else{
    //                  if(  databaseData !== undefined && databaseData !== '' &&  databaseData['Data'] !== undefined &&  databaseData['Data']['PMI Deduction Third'] !== undefined
    // && incomeStatementCompleteData[year]['Adjusted Gross Income'] > databaseData['Data']['PMI Deduction Third'] && incomeStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData['Data']['PMI Deduction Fourth']
    // &&  existingHomeData !== undefined &&  existingHomeData['Data1'][year] !== undefined &&  existingHomeData['Data1'][year]['Private Mortgage Insurance'] !== undefined){
    //                      itemizedmortgageValue6 = ( Math.ceil(incomeStatementCompleteData[year]['Adjusted Gross Income'] - databaseData['Data']['PMI Deduction Third'], 1000) / 10000 ) * existingHomeData['Data1'][year]['Private Mortgage Insurance'];
    //                  }
    //                  else{
    //                      itemizedmortgageValue6 = 0;
    //                  }
    //              }
    //          }
    //          else{
    //              itemizedmortgageValue6 = 0;
    //          }
    //      }
    //  }
    // }
    // else{
    //  itemizedmortgageValue6 = 0;
    // }
    // itemizedmortgageValue7 = itemizedmortgageValue5 + itemizedmortgageValue6;
    itemizedmortgageValue = itemizedmortgageValue5 + itemizedmortgageValue8 + itemizedmortgageValue3;

    incomeStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'] = itemizedmortgageValue;

    /**

Redirect To State and Local Taxes in Taxes
*/
    // State Local Taxes
    if (inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== 0) {
      stateLocalTaxesValue3 = inputTaxesData['State Local Taxes'][year]['State tax'];
    }
    if (inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== 0) {
      stateLocalTaxesValue4 = inputTaxesData['State Local Taxes'][year]['City tax'];
    }
    stateLocalTaxesValue5 = (stateLocalTaxesValue3 + stateLocalTaxesValue4) * 100;

    // Home Property Taxes
    if (module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
      && year === module13Data.year_of_purchase) {
      if (module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '') {
        intemizedHomePropertyTaxesValue1 = module13Data.propertyTaxesCalcs;
      } else {
        intemizedHomePropertyTaxesValue1 = 0;
      }
    } else if (buyingAHomeData !== undefined && buyingAHomeData !== ''
      && buyingAHomeData['Real Estate'] !== undefined
      && buyingAHomeData['Real Estate'][year] !== undefined
      && buyingAHomeData['Real Estate'][year]['Property Tax'] !== undefined) {
      intemizedHomePropertyTaxesValue1 = buyingAHomeData['Real Estate'][year]['Property Tax'];
    } else {
      intemizedHomePropertyTaxesValue1 = 0;
    }
    if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData.Data1 !== undefined && existingHomeData.Data1 !== '' && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year] !== '' && existingHomeData.Data1[year]['Property Taxes'] !== undefined) {
      intemizedHomePropertyTaxesValue2 = existingHomeData.Data1[year]['Property Taxes'] + (parseFloat(adjustedGrossIncomeValue) * (stateLocalTaxesValue5 / 100));
    } else {
      intemizedHomePropertyTaxesValue2 = 0;
    }
    intemizedHomePropertyTaxesValue = intemizedHomePropertyTaxesValue1 + intemizedHomePropertyTaxesValue2;
    if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== ''
      && databaseData.Data['SALT Limit'] !== undefined && databaseData.Data['SALT Limit'] !== '' && intemizedHomePropertyTaxesValue > databaseData.Data['SALT Limit']) {
      deductionsHomePropertyTaxesValue = databaseData.Data['SALT Limit'];
    } else {
      deductionsHomePropertyTaxesValue = intemizedHomePropertyTaxesValue;
    }
    incomeStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'] = deductionsHomePropertyTaxesValue;

    // Health Insurance Costs outside Premium
    let healthInsuranceCheck = 0;
    if (year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (expenseCalculationsB7 && expenseCalculationsData['Higher Education - On-Campus Housing'] && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] && year === expenseCalculationsB7
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Costs outside Premium'];

        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue10 = healthInsuranceDeductibleValue * (assumptionsData[year].Expenses / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue10 = 0;
      }
    } else {
      healthInsuranceDeductibleValue10 = 0;
    }
    if (year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (expenseCalculationsB7 && expenseCalculationsData['Higher Education - On-Campus Housing'] && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] && year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue11 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue11 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue11 = 0;
      }
    } else {
      healthInsuranceDeductibleValue11 = 0;
    }
    if (year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (expenseCalculationsData && expenseCalculationsData['Career Path'] && expenseCalculationsData['Career Path'][expenseCalculationsB48] && year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue12 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue12 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue12 = 0;
      }
    } else {
      healthInsuranceDeductibleValue12 = 0;
    }
    if (year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (expenseCalculationsB69 && expenseCalculationsData['Career Advancement'] && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] && year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue13 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;


        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue13 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue13 = 0;
      }
    } else {
      healthInsuranceDeductibleValue13 = 0;
    }
    if (year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (expenseCalculationsB90 && year === expenseCalculationsB90 && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Costs outside Premium'];

        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue14 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue14 = 0;
      }
    } else {
      healthInsuranceDeductibleValue14 = 0;
    }
    if (year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (expenseCalculationsB110 && year === expenseCalculationsB110 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue15 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue15 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue15 = 0;
      }
    } else {
      healthInsuranceDeductibleValue15 = 0;
    }
    if (year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (expenseCalculationsB131 && year === expenseCalculationsB131 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue16 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;

        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue16 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue16 = 0;
      }
    } else {
      healthInsuranceDeductibleValue16 = 0;
    }
    if (year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (expenseCalculationsB152 && year === expenseCalculationsB152 && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Costs outside Premium'];
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue17 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        healthInsuranceDeductibleValue17 = 0;
      }
    } else {
      healthInsuranceDeductibleValue17 = 0;
    }
    if (year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (expenseCalculationsB172 && year === expenseCalculationsB172 && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue18 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue18 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue18 = 0;
      }
    } else {
      healthInsuranceDeductibleValue18 = 0;
    }
    if (year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (expenseCalculationsB193 && year === expenseCalculationsB193 && expenseCalculationsData['Further Career Advancement'] !== undefined && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Costs outside Premium'] !== undefined) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Costs outside Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue19 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;

        healthInsuranceCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsuranceDeductibleValue19 = healthInsuranceDeductibleValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue19 = 0;
      }
    } else {
      healthInsuranceDeductibleValue19 = 0;
    }
    healthInsuranceDeductibleValue = healthInsuranceDeductibleValue10 + healthInsuranceDeductibleValue11 + healthInsuranceDeductibleValue12 + healthInsuranceDeductibleValue13 + healthInsuranceDeductibleValue14 + healthInsuranceDeductibleValue15 + healthInsuranceDeductibleValue16 + healthInsuranceDeductibleValue17 + healthInsuranceDeductibleValue18 + healthInsuranceDeductibleValue19;

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
      healthInsuranceDeductibleValue3 = module10Data.health_insurance_costs_multiplier;
    } else {
      healthInsuranceDeductibleValue3 = 1;
    }
    healthInsuranceDeductibleValue *= healthInsuranceDeductibleValue3;

    // Health Insurance Premium
    let healthInsuranceCheck2 = 0;
    if (year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (expenseCalculationsB7 && year === expenseCalculationsB7 && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Premium'];

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue10 = healthInsurancePremiumValue * (assumptionsData[year].Expenses / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue10 = 0;
      }
    } else {
      healthInsurancePremiumValue10 = 0;
    }
    if (year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (expenseCalculationsB27 && year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue11 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue11 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue11 = 0;
      }
    } else {
      healthInsurancePremiumValue11 = 0;
    }
    if (year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (expenseCalculationsB48 && year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue12 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue12 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue12 = 0;
      }
    } else {
      healthInsurancePremiumValue12 = 0;
    }
    if (year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (expenseCalculationsB69 && year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue13 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue13 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue13 = 0;
      }
    } else {
      healthInsurancePremiumValue13 = 0;
    }
    if (year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (expenseCalculationsB90 && year === expenseCalculationsB90 && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Premium'];
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB90 > module10Data.marriege_year &&  module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ){
healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
}
else{
healthInsurancePremiumValue2  = 1;
}
healthInsurancePremiumValue = healthInsurancePremiumValue1 * healthInsurancePremiumValue2; */

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue14 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue14 = 0;
      }
    } else {
      healthInsurancePremiumValue14 = 0;
    }
    if (year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (expenseCalculationsB110 && year === expenseCalculationsB110 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue15 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue15 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue15 = 0;
      }
    } else {
      healthInsurancePremiumValue15 = 0;
    }
    if (year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (expenseCalculationsB131 && year === expenseCalculationsB131 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue16 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue16 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue16 = 0;
      }
    } else {
      healthInsurancePremiumValue16 = 0;
    }
    if (year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (expenseCalculationsB152 && year === expenseCalculationsB152 && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Premium'];
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue17 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        healthInsurancePremiumValue17 = 0;
      }
    } else {
      healthInsurancePremiumValue17 = 0;
    }

    if (year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (expenseCalculationsB172 && year === expenseCalculationsB172 && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue18 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        healthInsuranceCheck2 = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue18 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        healthInsuranceCheck2 = 2;
      } else {
        healthInsurancePremiumValue18 = 0;
      }
    } else {
      healthInsurancePremiumValue18 = 0;
    }
    if (year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (expenseCalculationsB193 && year === expenseCalculationsB193 && expenseCalculationsData['Further Career Advancement'] !== undefined && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Premium'] !== undefined) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Premium'];
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue19 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        healthInsurancePremiumValue19 = healthInsurancePremiumValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        healthInsurancePremiumValue19 = 0;
      }
    } else {
      healthInsurancePremiumValue19 = 0;
    }

    healthInsurancePremiumValue = healthInsurancePremiumValue10 + healthInsurancePremiumValue11 + healthInsurancePremiumValue12 + healthInsurancePremiumValue13 + healthInsurancePremiumValue14 + healthInsurancePremiumValue15 + healthInsurancePremiumValue16 + healthInsurancePremiumValue17 + healthInsurancePremiumValue18 + healthInsurancePremiumValue19;

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
      healthInsurancePremiumValue3 = module10Data.health_insurance_premium_multiplier;
    } else {
      healthInsurancePremiumValue3 = 1;
    }
    healthInsurancePremiumValue *= healthInsurancePremiumValue3;

    // Health Insurance Value
    // if( -( parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue) ) > ( ( parseFloat(adjustedGrossIncomeValue) * 10 ) / 100 ) ){
    //     intemizedHealthInsuranceValue = -( parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue) ) - ( ( parseFloat(adjustedGrossIncomeValue) * 10 ) / 100 ) ;
    // } else {
    //     intemizedHealthInsuranceValue = 0;
    // }

    // Health Insurance Value
    if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Health Insurance Itemized Deduct Year'] !== undefined && databaseData.Data['Health Insurance Itemized Deduct Year'] !== '') {
      if (year === databaseData.Data['Health Insurance Itemized Deduct Year']) {
        if (-(parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue)) > ((databaseData.Data['Health Insurance Itemized Deduct Rate'] / 100) * adjustedGrossIncomeValue)) {
          intemizedHealthInsuranceValue = -(parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue)) - ((databaseData.Data['Health Insurance Itemized Deduct Rate'] / 100) * adjustedGrossIncomeValue);
        } else {
          intemizedHealthInsuranceValue = 0;
        }
      } else if (-(parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue)) > ((databaseData.Data['Health Insurance Itemized Deduct Rate Second'] / 100) * adjustedGrossIncomeValue)) {
        intemizedHealthInsuranceValue = -(parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue)) - ((databaseData.Data['Health Insurance Itemized Deduct Rate Second'] / 100) * adjustedGrossIncomeValue);
      } else {
        intemizedHealthInsuranceValue = 0;
      }
    }
    incomeStatementCompleteData[year]['Itemized Deductions']['Health Insurance'] = intemizedHealthInsuranceValue;

    // Charitable Contributions Value
    if (module9Data !== undefined && module9Data !== '' && module9Data.charitable_start_year !== undefined && module9Data.charitable_end_year !== undefined && year >= module9Data.charitable_start_year && year <= module9Data.charitable_end_year) {
      // let charitableContri = (parseFloat(module9Data.charitable_yearly_contribution)/100);
      const charitableContri = module9Data.charitable_yearly_contribution;
      if (charitableContri > 50) {
        intemizedCharitableContributionsValue = (incomeStatementCompleteData[year]['Total Income'] / 100) * 50;
      } else {
        intemizedCharitableContributionsValue = (incomeStatementCompleteData[year]['Total Income'] / 100) * charitableContri;
      }
    } else {
      intemizedCharitableContributionsValue = 0;
    }
    incomeStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions'] = intemizedCharitableContributionsValue;
    incomeStatementCompleteData[year]['Itemized Deductions New'] = healthInsuranceCheck2;
    // State & Local Taxes

    // if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== 0 ) {
    //   stateLocalTaxesValue1 = inputTaxesData['State Local Taxes'][year]['State tax'];
    // }
    // if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== 0 ) {
    //   stateLocalTaxesValue2 = inputTaxesData['State Local Taxes'][year]['City tax'];
    // }
    // stateLocalTaxesValue = ( stateLocalTaxesValue1 + stateLocalTaxesValue2 ) * 100;

    // State and Local Taxes
    // incomeStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes'] = ( parseFloat(adjustedGrossIncomeValue) * stateLocalTaxesValue ) / 100 ;
    /** End of Itemized Deductions * */

    /** *********************
** Standard Deduction **
*********************** */
    if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
      if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Standard Deductions'] !== undefined && databaseData.Data['Standard Deductions'] !== '' && databaseData.Data['Standard Deductions'].Married !== undefined && databaseData.Data['Standard Deductions'].Married !== '') {
        standardDeductionsValue = databaseData.Data['Standard Deductions'].Married;
      } else {
        standardDeductionsValue = 0;
      }
    } else if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Standard Deductions'] !== undefined && databaseData.Data['Standard Deductions'] !== '' && databaseData.Data['Standard Deductions'].Single !== undefined && databaseData.Data['Standard Deductions'].Single !== '') {
      standardDeductionsValue = databaseData.Data['Standard Deductions'].Single;
    } else {
      standardDeductionsValue = 0;
    }
    incomeStatementCompleteData[year]['Standard Deduction'] = standardDeductionsValue;

    /** *********************
** Total Itemized Deductions **
*********************** */
    totalItemizedDeductionsValue = incomeStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI']
      + incomeStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)']
      + incomeStatementCompleteData[year]['Itemized Deductions']['Health Insurance']
      + incomeStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions'];
    // incomeStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes']

    /* if( totalItemizedDeductionsValue1 > standardDeductionsValue ){
totalItemizedDeductionsValue = totalItemizedDeductionsValue1 ;
}
else{
totalItemizedDeductionsValue = 0 ;
} */
    incomeStatementCompleteData[year]['Total Itemized Deductions'] = totalItemizedDeductionsValue;

    /** *************
** Exemptions **
*************** */
    // DELETED IN NEW TAXES UPDATES
    /* let newVal = 0;
if( databaseData !== undefined &&
databaseData['Personal Exemptions'] !== undefined &&
databaseData['Personal Exemptions'][year] !== undefined &&
databaseData['Personal Exemptions'][year]['Total Exemptions2'] !== undefined) {
newVal = databaseData['Personal Exemptions'][year]['Total Exemptions2'];
}
incomeStatementCompleteData[year]['Personal Exemptions'] = newVal;
incomeStatementCompleteData[year]['Dependent Exemptions'] = newVal; */
    /** End of Exemptions * */

    /** *******************
** Total Deductions **
********************* */
    sumOfitemizedProducts = parseFloat(incomeStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'])
      + parseFloat(incomeStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'])
      + parseFloat(incomeStatementCompleteData[year]['Itemized Deductions']['Health Insurance'])
      + parseFloat(incomeStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions']);
    // parseFloat(incomeStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes']);
    if (sumOfitemizedProducts > standardDeductionsValue) {
      totalDeductionValue = parseFloat(sumOfitemizedProducts);
      // + parseFloat(incomeStatementCompleteData[year]['Personal Exemptions'])
    } else {
      totalDeductionValue = parseFloat(standardDeductionsValue);
      // + parseFloat(incomeStatementCompleteData[year]['Personal Exemptions'])
    }
    incomeStatementCompleteData[year]['Total Deductions'] = totalDeductionValue;
    /** End of Total Deductions * */

    /** ***************************
** REI Property Deductions **
**************************** */
    // REI Property Mortgage Interest, Points, & Depreciation
    let intemizedpointsmortgageValue = 0;
    let intemizedpointsmortgageValue1 = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined
      && invPropertyRoiData['Closing Cost']['Loan Acquisition Points'] !== undefined
      && invPropertyRoiData['Closing Cost']['Origination Points'] !== undefined
      && module14Data !== undefined && module14Data !== ''
      && module14Data.year_of_purchase !== undefined
      && year === parseFloat(module14Data.year_of_purchase) - 1) {
      intemizedpointsmortgageValue1 = invPropertyRoiData['Closing Cost']['Loan Acquisition Points'] + invPropertyRoiData['Closing Cost']['Origination Points'];
    } else {
      intemizedpointsmortgageValue1 = 0;
    }
    let intemizedpointsmortgageValue2 = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined
      && invPropertyRoiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined
      && module14Data !== undefined && module14Data !== ''
      && module14Data.year_of_purchase !== undefined
      && mortgage2Data !== undefined
      && mortgage2Data['Summation Data'][year] !== undefined
      && year === parseFloat(module14Data.year_of_purchase)) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][year].LaggingInterest
        + invPropertyRoiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
    } else if (mortgage2Data !== undefined
      && mortgage2Data['Summation Data'] !== undefined
      && mortgage2Data['Summation Data'] !== ''
      && mortgage2Data['Summation Data'][year] !== undefined) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][year].LaggingInterest;
    } else {
      intemizedpointsmortgageValue2 = 0;
    }
    let intemizedpointsmortgageValue3 = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Real Estate'] !== undefined
      && invPropertyRoiData['Real Estate'][year] !== undefined
      && invPropertyRoiData['Real Estate'][year].Home !== undefined) {
      intemizedpointsmortgageValue3 = invPropertyRoiData['Real Estate'][year].Home;
    } else {
      intemizedpointsmortgageValue3 = 0;
    }
    intemizedpointsmortgageValue = parseFloat(intemizedpointsmortgageValue1)
      + parseFloat(intemizedpointsmortgageValue2)
      + parseFloat(intemizedpointsmortgageValue3);
    if (intemizedpointsmortgageValue > invPropertyRentalIncomeValue) {
      intemizedpointsmortgageValue = invPropertyRentalIncomeValue;
    }
    incomeStatementCompleteData[year]['REI Property Deductions']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'] = intemizedpointsmortgageValue;

    // Passive Losses
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== ''
      && invPropertyRoiData.Data3 !== undefined
      && invPropertyRoiData.Data3[year] !== undefined
      && invPropertyRoiData.Data3[year]['Passive Losses Taken'] !== undefined) {
      intemizedPropertyDepreciationValue = invPropertyRoiData.Data3[year]['Passive Losses Taken'];
    } else {
      intemizedPropertyDepreciationValue = 0;
    }
    incomeStatementCompleteData[year]['REI Property Deductions']['Passive Losses'] = intemizedPropertyDepreciationValue;

    // REI Property Deductions Total
    totalREIPropertyDeductions = incomeStatementCompleteData[year]['REI Property Deductions']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation']
      + incomeStatementCompleteData[year]['REI Property Deductions']['Passive Losses'];
    incomeStatementCompleteData[year]['REI Property Deductions Total'] = totalREIPropertyDeductions;

    /** End of REI Property Deductions * */

    /** *************************
** Federal Taxable Income **
*************************** */
    if ((adjustedGrossIncomeValue - totalDeductionValue - totalREIPropertyDeductions) < 0) {
      federalTaxableIncomeValue = 0;
    } else {
      federalTaxableIncomeValue = parseFloat(adjustedGrossIncomeValue)
        - parseFloat(totalDeductionValue)
        - parseFloat(totalREIPropertyDeductions);
    }
    incomeStatementCompleteData[year]['Federal Taxable Income'] = federalTaxableIncomeValue;
    /** End of Federal Taxable Income * */

    /** ***********************
** Taxes & Total Taxes **
************************ */
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
      if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined && inputTaxesData['Federal Income'][year] !== undefined && inputTaxesData['Federal Income'][year].Married !== undefined && inputTaxesData['Federal Income'][year].Married['Federal Income Tax'] !== undefined) {
        effectiveTaxRateValue = inputTaxesData['Federal Income'][year].Married['Federal Income Tax'];
      } else {
        effectiveTaxRateValue = 0;
      }
    } else if (inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
      && inputTaxesData['Federal Income'][year] !== undefined
      && inputTaxesData['Federal Income'][year].Single !== undefined
      && inputTaxesData['Federal Income'][year].Single['Federal Income Tax'] !== undefined) {
      effectiveTaxRateValue = inputTaxesData['Federal Income'][year].Single['Federal Income Tax'];
    } else {
      effectiveTaxRateValue = 0;
    }
    // Federal Income Tax
    incomeStatementCompleteData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] = effectiveTaxRateValue;

    // Social Security
    incomeStatementCompleteData[year].taxes['Social Security Tax (x Total Gross Income with limits)'] = socialSecurityTaxesValue;
    incomeStatementCompleteData[year]['Social Security Tax Condition'] = socialconditionCheck;

    // Medicare Taxes
    incomeStatementCompleteData[year].taxes['Medicare Tax (x Total Gross Income with limits)'] = medicareTaxesValue;
    incomeStatementCompleteData[year]['Medicare Tax Condition'] = medicareconditionCheck;

    // State and Local taxes (x Adjusted Gross Income)
    incomeStatementCompleteData[year].taxes['State & Local Taxes (x Adjusted Gross Income)'] = stateLocalTaxesValue5;

    // Total Taxes
    /* let value1 = 0;
if ( effectiveTaxRateValue !== 0 ) {
value1 = ( effectiveTaxRateValue / 100 ) * federalTaxableIncomeValue;
}
let value2 = 0;
if ( medicareTaxesValue !== 0 ) {
value2 = ( medicareTaxesValue / 100 ) * incomeStatementCompleteData[year]['Total Income'];
}

let value3 = 0;
if ( stateLocalTaxesValue !== 0 ) {
value3 = ( stateLocalTaxesValue / 100 ) * adjustedGrossIncomeValue;
}
if ( databaseData !== undefined && databaseData !== '' && databaseData['Social Security Income Tax Limit'] !== undefined
 && databaseData['Social Security Income Tax Limit'] !== '' && databaseData['Social Security Income Tax Limit'][year] !== undefined && databaseData['Social Security Income Tax Limit'][year] !== ''
 && databaseData['Social Security Income Tax Limit'][year].Limit !== undefined && databaseData['Social Security Income Tax Limit'][year].Limit !== '' && incomeStatementCompleteData[year]['Total Income'] > databaseData['Social Security Income Tax Limit'][year].Limit ) {
const lastValue = databaseData['Social Security Income Tax Limit'][year].Limit * ( socialSecurityTaxesValue / 100 );
} else {
const lastValue = ( socialSecurityTaxesValue / 100 ) * incomeStatementCompleteData[year]['Total Income'];
} */
    let totalTaxesIncomeStatementValue = 0;
    if (taxStatementData !== undefined && taxStatementData !== '' && taxStatementData[year] !== undefined) {
      totalTaxesIncomeStatementValue = taxStatementData[year]['Net Income'];
    } else {
      totalTaxesIncomeStatementValue = 0;
    }
    incomeStatementCompleteData[year]['Total Taxes'] = totalTaxesIncomeStatementValue; // - ( value1 + value2 + value3 + lastValue );
    totalTaxesGraph.push(incomeStatementCompleteData[year]['Total Taxes']);
    // Total Tax Rate
    if (incomeStatementCompleteData[year]['Total Taxes'] !== 0 && incomeStatementCompleteData[year]['Total Income'] !== 0) {
      incomeStatementCompleteData[year].taxes['Total Tax Rate'] = (-(incomeStatementCompleteData[year]['Total Taxes']) / (incomeStatementCompleteData[year]['Total Income'])) * 100;
    } else {
      incomeStatementCompleteData[year].taxes['Total Tax Rate'] = 0;
    }
    /** End of Taxes & Total Taxes * */

    /** *********************
** Tax Credits ********
*********************** */
    // American Opportunity
    let americanOpportunity = 0;
    if (databaseData !== undefined && databaseData['American Opportunity'] !== undefined && databaseData['American Opportunity'][year] !== undefined) {
      americanOpportunity = databaseData['American Opportunity'][year]['Tax Credit Applied'];
    }
    let lifeTimeLearning = 0;
    if (databaseData !== undefined && databaseData['Lifetime Learning'] !== undefined && databaseData['Lifetime Learning'][year] !== undefined) {
      lifeTimeLearning = databaseData['Lifetime Learning'][year]['Tax Credit Applied'];
    }
    incomeStatementCompleteData[year]['Tax Credits']['American Opportunity or Lifetime Learning'] = americanOpportunity + lifeTimeLearning;

    // Child Tax Credit
    let childTaxCredit = 0;
    if (databaseData !== undefined && databaseData['Child Tax Credit'] !== undefined && databaseData['Child Tax Credit'][year] !== undefined && databaseData['Child Tax Credit'][year]['Child Tax Credit']['Total Tax Credit Applied'] !== undefined) {
      childTaxCredit = databaseData['Child Tax Credit'][year]['Child Tax Credit']['Total Tax Credit Applied'];
    }
    incomeStatementCompleteData[year]['Tax Credits']['Child Tax Credit'] = childTaxCredit;

    // Total Tax Credits
    incomeStatementCompleteData[year]['Total Tax Credits'] = incomeStatementCompleteData[year]['Tax Credits']['American Opportunity or Lifetime Learning']
      + incomeStatementCompleteData[year]['Tax Credits']['Child Tax Credit'];
    /** End of Tax Credits * */

    /** *********************
** Income After Taxes **
*********************** */
    incomeStatementCompleteData[year]['Income After Taxes'] = incomeStatementCompleteData[year]['Total Taxes'] + incomeStatementCompleteData[year]['Total Income'];
    incomeAfterTaxesGraph.push(incomeStatementCompleteData[year]['Income After Taxes']);
    /** End of Income After Taxes * */

    /** ****************************
** Retirement Contributions **
***************************** */
    // let conditionCheck = 0;
    // let conditionCheck2 = 0;
    // let conditionCheck3 = 0;
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts 401k'] !== undefined
      && investmentsData['Retirement Accounts 401k'][year] !== undefined
      && investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== undefined) {
      retirementContributionsValue1 = investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue1 = 0;
    }
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts Roth IRA'] !== undefined
      && investmentsData['Retirement Accounts Roth IRA'][year] !== undefined
      && investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] !== undefined) {
      retirementContributionsValue2 = investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue2 = 0;
    }
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts Seph IRA'] !== undefined
      && investmentsData['Retirement Accounts Seph IRA'][year] !== undefined
      && investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] !== undefined) {
      retirementContributionsValue3 = investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue3 = 0;
    }
    if (investmentsData !== undefined
      && investmentsData['Retirement Accounts Pension'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year] !== undefined
      && investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== undefined) {
      retirementContributionsValue4 = investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue4 = 0;
    }
    incomeStatementCompleteData[year]['Retirement Contributions'] = -(retirementContributionsValue1 + retirementContributionsValue2 + retirementContributionsValue3 + retirementContributionsValue4);
    incomeStatementCompleteData[year]['Retirement Contribution 1'] = conditionCheck;
    incomeStatementCompleteData[year]['Retirement Contribution 2'] = conditionCheck2;
    /** End of Retirement Contributions * */

    /** ********************
** Disposable Income **
********************** */
    disposableIncome = incomeStatementCompleteData[year]['Income After Taxes'] + incomeStatementCompleteData[year]['Retirement Contributions'];
    incomeStatementCompleteData[year].disposableIncome = disposableIncome;
    totalDisposableGraph.push(incomeStatementCompleteData[year].disposableIncome);
    /** End of Disposable Income * */

    /** ******************
** Living Expenses **
******************** */
    // Higher Education Tuition
    incomeStatementCompleteData[year].livingExpenses['Higher Education Tuition and Fees'] = collegeTuitionValue;
    // Higher Education Room & Board
    // let conditionCheck = 0;
    if (year === 2022) {
    }
    if (expenseCalculationsB7 && year === expenseCalculationsB7 && grossIncomeTotal === 0) {
      if (expenseCalculationsData && expenseCalculationsData['Higher Education - On-Campus Housing'] && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Rent !== undefined) {
        collegeRoomBoardValue = -parseFloat(expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Rent);
        conditionCheck = 1;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else {
      if (module2Data.start_year !== undefined && module2Data.start_year !== '' && year >= module2Data.start_year && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
        if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
          collegeRoomBoardValue *= ((assumptionsData[year]['Expenses']) / 100);
          conditionCheck = 2;
        } else {
          collegeRoomBoardValue = 0;
        }
      } else {
        if (year === expenseCalculationsB90 && grossIncomeTotal === 0) {
          if (expenseCalculationsData && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
            && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Rent !== undefined) {
            collegeRoomBoardValue = -parseFloat(expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Rent);
            conditionCheck = 1;
          } else {
            collegeRoomBoardValue = 0;
          }
        } else {
          if (year >= module6Data.start_year && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
            if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
              collegeRoomBoardValue *= ((assumptionsData[year]['Expenses']) / 100);
              conditionCheck = 2;
            } else {
              collegeRoomBoardValue = 0;
            }
          } else {
            if (expenseCalculationsB152 && year === expenseCalculationsB152 && grossIncomeTotal === 0) {
              if (expenseCalculationsData['New Higher Education - On-Campus Housing'] !== undefined
                && expenseCalculationsData['New Higher Education - On-Campus Housing'] !== ''
                && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
                && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== ''
                && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Rent !== undefined) {
                collegeRoomBoardValue = -parseFloat(expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Rent);
                conditionCheck = 1;
              } else {
                collegeRoomBoardValue = 0;
              }
            } else {
              if (year >= module15Data.start_year && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
                if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
                  collegeRoomBoardValue *= ((assumptionsData[year]['Expenses']) / 100);
                  conditionCheck = 2;
                } else {
                  collegeRoomBoardValue = 0;
                }
              } else {
                collegeRoomBoardValue = 0;
              }
            }
          }
        }
      }
    }
    incomeStatementCompleteData[year].livingExpenses['Higher Education Room & Board'] = collegeRoomBoardValue;
    incomeStatementCompleteData[year]['Higher Education Room & Board Condition'] = conditionCheck;

    // Off Campus Food
    // let conditionCheck = 0;
    if (expenseCalculationsB7 && year === expenseCalculationsB7 && grossIncomeTotal === 0) {
      if (expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Food !== undefined) {
        offCampusFoodValue = -parseFloat(expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Food);
        conditionCheck = 1;
      } else {
        offCampusFoodValue = 0;
      }
    } else if (expenseCalculationsB7 && year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        offCampusFoodValue *= ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else if (expenseCalculationsB90 && year === expenseCalculationsB90 && grossIncomeTotal === 0) {
      if (expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Food !== undefined) {
        offCampusFoodValue = -parseFloat(expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Food);
        conditionCheck = 1;
      } else {
        offCampusFoodValue = 0;
      }
    } else if (year >= module6Data.start_year && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        offCampusFoodValue *= ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else if (expenseCalculationsB152 && year === expenseCalculationsB152 && grossIncomeTotal === 0) {
      if (expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Food !== undefined) {
        offCampusFoodValue = -parseFloat(expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Food);
        conditionCheck = 1;
      } else {
        offCampusFoodValue = 0;
      }
    } else if (year >= module15Data.start_year && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        offCampusFoodValue *= ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else {
      offCampusFoodValue = 0;
    }
    incomeStatementCompleteData[year].livingExpenses['Off Campus Food'] = offCampusFoodValue;
    incomeStatementCompleteData[year]['Off Campus Food Condition'] = conditionCheck;

    // Rent
    // let conditionCheck = 0;

    if (module13Data.year_of_purchase !== undefined) {
      if (year < module13Data.year_of_purchase || module13Data.year_of_purchase === '' || module13Data.year_of_purchase >= 0) {
        if (year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
          if (expenseCalculationsB27 && year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }

            rentValue10 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue10 = rentValue * ((assumptionsData[year].Expenses) / 100);

            conditionCheck = 2;
          } else {
            rentValue10 = 0;
          }
        } else {
          rentValue10 = 0;
        }

        if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
          if (year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue11 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue11 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue11 = 0;
          }
        } else {
          rentValue11 = 0;
        }
        if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
          if (year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue12 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue12 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue12 = 0;
          }
        } else {
          rentValue12 = 0;
        }
        if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
          if (year === expenseCalculationsB110
            && expenseCalculationsData['More Higher Education - Off-Campus Housing'] !== undefined
            && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
            && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue13 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue13 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue13 = 0;
          }
        } else {
          rentValue13 = 0;
        }
        if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
          if (year === expenseCalculationsB131
            && expenseCalculationsData['Additional Career Advancement'] !== undefined
            && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
            && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue14 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue14 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue14 = 0;
          }
        } else {
          rentValue14 = 0;
        }
        if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
          if (year === expenseCalculationsB172
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue15 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue15 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue15 = 0;
          }
        } else {
          rentValue15 = 0;
        }
        if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
          if (year === expenseCalculationsB193
            && expenseCalculationsData['Further Career Advancement'] !== undefined
            && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
            && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Rent !== undefined) {
            rentValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Rent;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue16 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            rentValue16 = rentValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            rentValue16 = 0;
          }
        } else {
          rentValue16 = 0;
        }

        rentValue = rentValue10 + rentValue11 + rentValue12 + rentValue13 + rentValue14 + rentValue15 + rentValue16;
      } else {
        rentValue = 0;
      }
    } else {
      rentValue = 0;
    }
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
      rentValue3 = module10Data.rent_multiplier;
    } else {
      rentValue3 = 1;
    }
    rentValue *= rentValue3;
    incomeStatementCompleteData[year].livingExpenses.Rent = rentValue;
    incomeStatementCompleteData[year]['Rent Condition'] = conditionCheck;

    // Utilities
    // let conditionCheck = 0;
    if (module13Data.year_of_purchase !== undefined) {
      if (year < module13Data.year_of_purchase || module13Data.year_of_purchase === '' || module13Data.year_of_purchase >= 0) {
        if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
          if (year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue10 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue10 = utilitiesValue * (assumptionsData[year].Expenses / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue10 = 0;
          }
        } else {
          utilitiesValue10 = 0;
        }
        if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
          if (year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue11 = utilitiesValue1 * utilitiesValue2;

            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue11 = utilitiesValue * (assumptionsData[year].Expenses / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue11 = 0;
          }
        } else {
          utilitiesValue11 = 0;
        }
        if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
          if (year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue12 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue12 = utilitiesValue * (assumptionsData[year].Expenses / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue12 = 0;
          }
        } else {
          utilitiesValue12 = 0;
        }
        if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
          if (year === expenseCalculationsB110 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue13 = utilitiesValue1 * utilitiesValue2;

            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue13 = utilitiesValue * (assumptionsData[year].Expenses / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue13 = 0;
          }
        } else {
          utilitiesValue13 = 0;
        }
        if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
          if (year === expenseCalculationsB131 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue14 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue14 = utilitiesValue * (assumptionsData[year].Expenses / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue14 = 0;
          }
        } else {
          utilitiesValue14 = 0;
        }
        if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
          if (year === expenseCalculationsB172
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
            && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue15 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue15 = utilitiesValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue15 = 0;
          }
        } else {
          utilitiesValue15 = 0;
        }
        if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
          if (year === expenseCalculationsB193
            && expenseCalculationsData['Further Career Advancement'] !== undefined
            && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
            && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Utilities !== undefined) {
            utilitiesValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Utilities;
            if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue16 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
            utilitiesValue16 = utilitiesValue * ((assumptionsData[year].Expenses) / 100);
            conditionCheck = 2;
          } else {
            utilitiesValue16 = 0;
          }
        } else {
          utilitiesValue16 = 0;
        }
        utilitiesValue = utilitiesValue10 + utilitiesValue11 + utilitiesValue12 + utilitiesValue13 + utilitiesValue14 + utilitiesValue15 + utilitiesValue16;
      } else {
        utilitiesValue = 0;
      }
    } else {
      utilitiesValue = 0;
    }

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
      utilitiesValue3 = module10Data.utilities_multiplier;
    } else {
      utilitiesValue3 = 1;
    }
    utilitiesValue *= utilitiesValue3;
    incomeStatementCompleteData[year].livingExpenses.Utilities = utilitiesValue;
    incomeStatementCompleteData[year]['Utilities Condition'] = conditionCheck;

    // Food
    // let conditionCheck = 0;
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue10 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue10 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue10 = 0;
      }
    } else {
      FoodValue10 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue11 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue11 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue11 = 0;
      }
    } else {
      FoodValue11 = 0;
    }
    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue12 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue12 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue12 = 0;
      }
    } else {
      FoodValue12 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue13 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue13 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue13 = 0;
      }
    } else {
      FoodValue13 = 0;
    }
    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue14 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue14 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue14 = 0;
      }
    } else {
      FoodValue14 = 0;
    }
    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue15 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue15 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue15 = 0;
      }
    } else {
      FoodValue15 = 0;
    }
    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Food !== undefined) {
        FoodValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Food;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;

        }
        FoodValue16 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        FoodValue16 = FoodValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        FoodValue16 = 0;
      }
    } else {
      FoodValue16 = 0;
    }
    FoodValue = FoodValue10 + FoodValue11 + FoodValue12 + FoodValue13 + FoodValue14 + FoodValue15 + FoodValue16;
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
      FoodValue3 = module10Data.food_multiplier;
    } else {
      FoodValue3 = 1;
    }
    FoodValue *= FoodValue3;

    incomeStatementCompleteData[year].livingExpenses.Food = FoodValue;
    incomeStatementCompleteData[year]['Food Condition'] = conditionCheck;

    // Clothing
    // let conditionCheck = 0;
    if (expenseCalculationsB7 && year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB7 && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Clothing !== undefined) {
        clothingValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Clothing;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue10 = clothingValue * (assumptionsData[year].Expenses / 100);
        conditionCheck = 2;
      } else {
        clothingValue10 = 0;
      }
    } else {
      clothingValue10 = 0;
    }
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue11 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue11 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue11 = 0;
      }
    } else {
      clothingValue11 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue12 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue12 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue12 = 0;
      }
    } else {
      clothingValue12 = 0;
    }

    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue13 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue13 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue13 = 0;
      }
    } else {
      clothingValue13 = 0;
    }
    if (expenseCalculationsB90 && year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB90 && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Clothing !== undefined) {
        clothingValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Clothing;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB90 > module10Data.marriege_year &&  module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ){
clothingValue2 = module10Data.clothing_multiplier;
}
else{
clothingValue2  = 1;
}
clothingValue14 = clothingValue1 * clothingValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue14 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue14 = 0;
      }
    } else {
      clothingValue14 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue15 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue15 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue15 = 0;
      }
    } else {
      clothingValue15 = 0;
    }

    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue16 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue16 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue16 = 0;
      }
    } else {
      clothingValue16 = 0;
    }

    if (expenseCalculationsB152 && year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB152
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Clothing !== undefined) {
        clothingValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Clothing;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue17 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue17 = 0;
      }
    } else {
      clothingValue17 = 0;
    }

    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Clothing !== undefined) {
        clothingValue18 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue18 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue18 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue18 = 0;
      }
    } else {
      clothingValue18 = 0;
    }
    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Clothing !== undefined) {
        clothingValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Clothing;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue19 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        clothingValue19 = clothingValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        clothingValue19 = 0;
      }
    } else {
      clothingValue19 = 0;
    }

    clothingValue = clothingValue10 + clothingValue11 + clothingValue12 + clothingValue13 + clothingValue14 + clothingValue15 + clothingValue16 + clothingValue17 + clothingValue18 + clothingValue19;
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
      clothingValue3 = module10Data.clothing_multiplier;
    } else {
      clothingValue3 = 1;
    }

    clothingValue *= clothingValue3;

    incomeStatementCompleteData[year].livingExpenses.Clothing = clothingValue;
    incomeStatementCompleteData[year]['Clothing Condition'] = conditionCheck;

    // Entertainment
    // let conditionCheck = 0;
    if (expenseCalculationsB7 && year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB7
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Entertainment !== undefined) {
        EntertainmentValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Entertainment;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB7 > module10Data.marriege_year &&  module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ){
EntertainmentValue2 = module10Data.entertainment_multiplier;
}
else{
EntertainmentValue2  = 1;
}
EntertainmentValue = EntertainmentValue1 * EntertainmentValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue10 = EntertainmentValue * (assumptionsData[year].Expenses / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue10 = 0;
      }
    } else {
      EntertainmentValue10 = 0;
    }
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue11 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue11 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue11 = 0;
      }
    } else {
      EntertainmentValue11 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48
        && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
        && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue12 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue12 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue12 = 0;
      }
    } else {
      EntertainmentValue12 = 0;
    }
    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue13 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue13 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue13 = 0;
      }
    } else {
      EntertainmentValue13 = 0;
    }
    if (expenseCalculationsB90 && year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB90
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Entertainment !== undefined) {
        EntertainmentValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Entertainment;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB90 > module10Data.marriege_year &&  module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ){
EntertainmentValue2 = module10Data.entertainment_multiplier;
}
else{
EntertainmentValue2  = 1;
}
EntertainmentValue = EntertainmentValue1 * EntertainmentValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue14 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue14 = 0;
      }
    } else {
      EntertainmentValue14 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue15 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue15 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue15 = 0;
      }
    } else {
      EntertainmentValue15 = 0;
    }
    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue16 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue16 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue16 = 0;
      }
    } else {
      EntertainmentValue16 = 0;
    }

    if (expenseCalculationsB152 && year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB152
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Entertainment !== undefined) {
        EntertainmentValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Entertainment;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue17 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue17 = 0;
      }
    } else {
      EntertainmentValue17 = 0;
    }

    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Entertainment !== undefined) {
        EntertainmentValue18 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue18 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue18 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue18 = 0;
      }
    } else {
      EntertainmentValue18 = 0;
    }

    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Entertainment !== undefined) {
        EntertainmentValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Entertainment;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue19 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        EntertainmentValue19 = EntertainmentValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        EntertainmentValue19 = 0;
      }
    } else {
      EntertainmentValue19 = 0;
    }

    EntertainmentValue = EntertainmentValue10 + EntertainmentValue11 + EntertainmentValue12 + EntertainmentValue13 + EntertainmentValue14 + EntertainmentValue15 + EntertainmentValue16 + EntertainmentValue17 + EntertainmentValue18 + EntertainmentValue19;

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
      EntertainmentValue3 = module10Data.entertainment_multiplier;
    } else {
      EntertainmentValue3 = 1;
    }
    EntertainmentValue *= EntertainmentValue3;
    incomeStatementCompleteData[year].livingExpenses.Entertainment = EntertainmentValue;
    incomeStatementCompleteData[year]['Entertainment Condition'] = conditionCheck;

    // Technology
    // let conditionCheck = 0;
    if (expenseCalculationsB7 && year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB7
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Technology !== undefined) {
        TechnologyValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Technology;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB7 > module10Data.marriege_year &&  module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ){
TechnologyValue2 = module10Data.technology_multiplier;
}
else{
TechnologyValue2  = 1;
}
TechnologyValue = TechnologyValue1 * TechnologyValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue10 = TechnologyValue * (assumptionsData[year].Expenses / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue10 = 0;
      }
    } else {
      TechnologyValue10 = 0;
    }
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue11 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue11 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue11 = 0;
      }
    } else {
      TechnologyValue11 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48
        && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
        && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue12 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue12 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue12 = 0;
      }
    } else {
      TechnologyValue12 = 0;
    }
    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue13 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue13 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue13 = 0;
      }
    } else {
      TechnologyValue13 = 0;
    }
    if (expenseCalculationsB90 && year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB90
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Technology !== undefined) {
        TechnologyValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Technology;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB90 > module10Data.marriege_year &&  module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ){
TechnologyValue2 = module10Data.technology_multiplier;
}
else{
TechnologyValue2  = 1;
}
TechnologyValue = TechnologyValue1 * TechnologyValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue14 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue14 = 0;
      }
    } else {
      TechnologyValue14 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue15 = TechnologyValue1 * TechnologyValue2;

        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue15 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue15 = 0;
      }
    } else {
      TechnologyValue15 = 0;
    }
    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue16 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue16 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue16 = 0;
      }
    } else {
      TechnologyValue16 = 0;
    }
    if (expenseCalculationsB152 && year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB152
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Technology !== undefined) {
        TechnologyValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Technology;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue17 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue17 = 0;
      }
    } else {
      TechnologyValue17 = 0;
    }

    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue18 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue18 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TechnologyValue18 = 0;
      }
    } else {
      TechnologyValue18 = 0;
    }
    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Technology !== undefined) {
        TechnologyValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Technology;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue19 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TechnologyValue19 = TechnologyValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {

        TechnologyValue19 = 0;
      }
    } else {
      TechnologyValue19 = 0;
    }
    TechnologyValue = TechnologyValue10 + TechnologyValue11 + TechnologyValue12 + TechnologyValue13 + TechnologyValue14 + TechnologyValue15 + TechnologyValue16 + TechnologyValue17 + TechnologyValue18 + TechnologyValue19;

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
      TechnologyValue3 = module10Data.technology_multiplier;
    } else {
      TechnologyValue3 = 1;
    }
    TechnologyValue *= TechnologyValue3;

    incomeStatementCompleteData[year].livingExpenses.Technology = TechnologyValue;
    incomeStatementCompleteData[year]['Technology Condition'] = conditionCheck;

    // Transportation
    // let conditionCheck = 0;
    if (year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (expenseCalculationsB7 && year === expenseCalculationsB7
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Transportation !== undefined) {
        TransportationValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Transportation;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB7 > module10Data.marriege_year &&  module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ){
TransportationValue2 = module10Data.transportation_multiplier;
}
else{
TransportationValue2  = 1;
}
TransportationValue = TransportationValue1 * TransportationValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue10 = TransportationValue * (assumptionsData[year].Expenses / 100);
        conditionCheck = 2;
      } else {
        TransportationValue10 = 0;
      }
    } else {
      TransportationValue10 = 0;
    }
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue11 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue11 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue11 = 0;
      }
    } else {
      TransportationValue11 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48
        && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
        && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue12 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue12 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue12 = 0;
      }
    } else {
      TransportationValue12 = 0;
    }
    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue13 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue13 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue13 = 0;
      }
    } else {
      TransportationValue13 = 0;
    }
    if (expenseCalculationsB90 && year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB90
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Transportation !== undefined) {
        TransportationValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Transportation;
        /* if(  module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB90 > module10Data.marriege_year &&  module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ){
TransportationValue2 = module10Data.transportation_multiplier;
}
else{
TransportationValue2  = 1;
}
TransportationValue = TransportationValue1 * TransportationValue2; */
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue14 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue14 = 0;
      }
    } else {
      TransportationValue14 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue15 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue15 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue15 = 0;
      }
    } else {
      TransportationValue15 = 0;
    }
    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue16 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue16 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue16 = 0;
      }
    } else {
      TransportationValue16 = 0;
    }
    if (expenseCalculationsB152 && year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB152
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation !== undefined) {
        TransportationValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue17 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue17 = 0;
      }
    } else {
      TransportationValue17 = 0;
    }

    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue18 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue18 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue18 = 0;
      }
    } else {
      TransportationValue18 = 0;
    }
    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Transportation !== undefined) {
        TransportationValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Transportation;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue19 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        TransportationValue19 = TransportationValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        TransportationValue19 = 0;
      }
    } else {
      TransportationValue19 = 0;
    }
    TransportationValue = TransportationValue10 + TransportationValue11 + TransportationValue12 + TransportationValue13 + TransportationValue14 + TransportationValue15 + TransportationValue16 + TransportationValue17 + TransportationValue18 + TransportationValue19;
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
      TransportationValue3 = module10Data.transportation_multiplier;
    } else {
      TransportationValue3 = 1;
    }
    TransportationValue *= TransportationValue3;

    incomeStatementCompleteData[year].livingExpenses.Transportation = TransportationValue;
    incomeStatementCompleteData[year]['Transportation Condition'] = conditionCheck;

    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] !== undefined) {
      carPaymentValue1 = depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'];
    } else {
      carPaymentValue1 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] !== undefined) {
      carPaymentValue2 = depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'];
    } else {
      carPaymentValue2 = 0;
    }
    if (carsExistingVehicleDepreciationData !== undefined && carsExistingVehicleDepreciationData !== '' && carsExistingVehicleDepreciationData[year] !== undefined && carsExistingVehicleDepreciationData[year] !== '' && carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Lease Payment'] !== undefined) {
      carPaymentValue3 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Lease Payment'];
    } else {
      carPaymentValue3 = 0;
    }
    if (carsExistingVehicleDepreciationData !== undefined && carsExistingVehicleDepreciationData !== '' && carsExistingVehicleDepreciationData[year] !== undefined && carsExistingVehicleDepreciationData[year] !== '' && carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Lease Payment'] !== undefined) {
      carPaymentValue4 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Lease Payment'];
    } else {
      carPaymentValue4 = 0;
    }
    if (carsExistingVehicleDepreciationData !== undefined && carsExistingVehicleDepreciationData !== '' && carsExistingVehicleDepreciationData[year] !== undefined && carsExistingVehicleDepreciationData[year] !== '' && carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Lease Payment'] !== undefined) {
      carPaymentValue5 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Lease Payment'];
    } else {
      carPaymentValue5 = 0;
    }
    if (carsExistingVehicleDepreciationData !== undefined && carsExistingVehicleDepreciationData !== '' && carsExistingVehicleDepreciationData[year] !== undefined && carsExistingVehicleDepreciationData[year] !== '' && carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Lease Payment'] !== undefined) {
      carPaymentValue6 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Lease Payment'];
    } else {
      carPaymentValue6 = 0;
    }
    carPaymentValue = -(carPaymentValue1 + carPaymentValue2 + carPaymentValue3 + carPaymentValue4 + carPaymentValue5 + carPaymentValue6);
    incomeStatementCompleteData[year].livingExpenses['Car Payment'] = carPaymentValue;
    incomeStatementCompleteData[year]['Car Payment Condition'] = conditionCheck;

    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Maintenance !== undefined) {
      CarMaintenanceValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Maintenance;
    } else {
      CarMaintenanceValue1 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Maintenance !== undefined) {
      CarMaintenanceValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Maintenance;
    } else {
      CarMaintenanceValue2 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Maintenance !== undefined) {
      CarMaintenanceValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Maintenance;
    } else {
      CarMaintenanceValue3 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Maintenance !== undefined) {
      CarMaintenanceValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Maintenance;
    } else {
      CarMaintenanceValue4 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Maintenance !== undefined) {
      CarMaintenanceValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Maintenance;
    } else {
      CarMaintenanceValue5 = 0;
    }

    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Maintenance'] !== 'undefined') {
      existingCarMaintenanceValue1 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Maintenance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['New Maintenance'];
    } else {
      existingCarMaintenanceValue1 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Maintenance'] !== 'undefined') {
      existingCarMaintenanceValue2 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Maintenance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['New Maintenance'];
    } else {
      existingCarMaintenanceValue2 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Maintenance'] !== 'undefined') {
      existingCarMaintenanceValue3 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Maintenance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['New Maintenance'];
    } else {
      existingCarMaintenanceValue3 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Maintenance'] !== 'undefined') {
      existingCarMaintenanceValue4 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Maintenance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['New Maintenance'];
    } else {
      existingCarMaintenanceValue4 = 0;
    }
    CarMaintenanceValue = -(CarMaintenanceValue1 + CarMaintenanceValue2 + CarMaintenanceValue4 + CarMaintenanceValue5 + existingCarMaintenanceValue1 + existingCarMaintenanceValue2 + existingCarMaintenanceValue3 + existingCarMaintenanceValue4);

    incomeStatementCompleteData[year].livingExpenses['Car Maintenance'] = CarMaintenanceValue;
    incomeStatementCompleteData[year]['Car Maintenance Condition'] = conditionCheck;
    let CarInsuranceValue3 = 0;
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Insurance !== undefined) {
      CarInsuranceValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Insurance;
    } else {
      CarInsuranceValue1 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Insurance !== undefined) {
      CarInsuranceValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Insurance;
    } else {
      CarInsuranceValue2 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Insurance !== undefined) {
      CarInsuranceValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Insurance;
    } else {
      CarInsuranceValue3 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Insurance !== undefined) {
      CarInsuranceValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Insurance;
    } else {
      CarInsuranceValue4 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Insurance !== undefined) {
      CarInsuranceValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Insurance;
    } else {
      CarInsuranceValue5 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Insurance'] !== 'undefined') {
      existingCarInsuranceValue1 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Insurance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['New Insurance'];
    } else {
      existingCarInsuranceValue1 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Insurance'] !== 'undefined') {
      existingCarInsuranceValue2 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Insurance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['New Insurance'];
    } else {
      existingCarInsuranceValue2 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Insurance'] !== 'undefined') {
      existingCarInsuranceValue3 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Insurance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['New Insurance'];
    } else {
      existingCarInsuranceValue3 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Insurance'] !== 'undefined') {
      existingCarInsuranceValue4 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Insurance'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['New Insurance'];
    } else {
      existingCarInsuranceValue4 = 0;
    }
    CarInsuranceValue = -(CarInsuranceValue1 + CarInsuranceValue2 + CarInsuranceValue4 + CarInsuranceValue5 + existingCarInsuranceValue1 + existingCarInsuranceValue2 + existingCarInsuranceValue3 + existingCarInsuranceValue4);

    incomeStatementCompleteData[year].livingExpenses['Car Insurance'] = CarInsuranceValue;
    incomeStatementCompleteData[year]['Car Insurance Condition'] = conditionCheck;

    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Gas !== undefined) {
      GasValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Gas;
    } else {
      GasValue1 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Gas !== undefined) {
      GasValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Gas;
    } else {
      GasValue2 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Gas !== undefined) {
      GasValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Gas;
    } else {
      GasValue3 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Gas !== undefined) {
      GasValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Gas;
    } else {
      GasValue4 = 0;
    }
    if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Gas !== undefined) {
      GasValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Gas;
    } else {
      GasValue5 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Gas'] !== 'undefined') {
      existingCarGasValue1 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Gas'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['New Gas'];
    } else {
      existingCarGasValue1 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Gas'] !== 'undefined') {
      existingCarGasValue2 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Gas'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['New Gas'];
    } else {
      existingCarGasValue2 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Gas'] !== 'undefined') {
      existingCarGasValue3 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Gas'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['New Gas'];
    } else {
      existingCarGasValue3 = 0;
    }
    if (typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Gas'] !== 'undefined') {
      existingCarGasValue4 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['Gas'] + carsExistingVehicleDepreciationData[year]['Existing Vehicle 4']['New Gas'];
    } else {
      existingCarGasValue4 = 0;
    }

    GasValue = -(GasValue1 + GasValue2 + GasValue4 + GasValue5 + existingCarGasValue1 + existingCarGasValue2 + existingCarGasValue3 + existingCarGasValue4);

    incomeStatementCompleteData[year].livingExpenses.Gas = GasValue;
    incomeStatementCompleteData[year]['Gas Condtion'] = conditionCheck;

    // Miscellaneous
    // let conditionCheck = 0;
    if (expenseCalculationsB7 && year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB7
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
        && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Miscellaneous;

        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue10 = MiscellaneousValue * (assumptionsData[year].Expenses / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue10 = 0;
      }
    } else {
      MiscellaneousValue10 = 0;
    }
    if (expenseCalculationsB27 && year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB27
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
        && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue11 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue11 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue11 = 0;
      }
    } else {
      MiscellaneousValue11 = 0;
    }
    if (expenseCalculationsB48 && year >= expenseCalculationsB48 && year <= expenseCalculationsB49) {
      if (year === expenseCalculationsB48
        && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
        && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue12 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue12 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue12 = 0;
      }
    } else {
      MiscellaneousValue12 = 0;
    }
    if (expenseCalculationsB69 && year >= expenseCalculationsB69 && year <= expenseCalculationsB70) {
      if (year === expenseCalculationsB69
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
        && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue13 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue13 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue13 = 0;
      }
    } else {
      MiscellaneousValue13 = 0;
    }
    if (expenseCalculationsB90 && year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB90
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
        && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Miscellaneous;

        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue14 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue14 = 0;
      }
    } else {
      MiscellaneousValue14 = 0;
    }
    if (expenseCalculationsB110 && year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB110
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
        && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue15 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue15 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue15 = 0;
      }
    } else {
      MiscellaneousValue15 = 0;
    }
    if (expenseCalculationsB131 && year >= expenseCalculationsB131 && year <= expenseCalculationsB132) {
      if (year === expenseCalculationsB131
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
        && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue16 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue16 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue16 = 0;
      }
    } else {
      MiscellaneousValue16 = 0;
    }
    if (expenseCalculationsB152 && year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB152
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
        && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation !== undefined) {
        MiscellaneousValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue17 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue17 = 0;
      }
    } else {
      MiscellaneousValue17 = 0;
    }

    if (expenseCalculationsB172 && year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0) {
      if (year === expenseCalculationsB172
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
        && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue18 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue18 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue18 = 0;
      }
    } else {
      MiscellaneousValue18 = 0;
    }
    if (expenseCalculationsB193 && year >= expenseCalculationsB193 && year <= expenseCalculationsB194) {
      if (year === expenseCalculationsB193
        && expenseCalculationsData['Further Career Advancement'] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
        && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Miscellaneous !== undefined) {
        MiscellaneousValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Miscellaneous;
        if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue19 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if (assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        MiscellaneousValue19 = MiscellaneousValue * ((assumptionsData[year].Expenses) / 100);
        conditionCheck = 2;
      } else {
        MiscellaneousValue19 = 0;
      }
    } else {
      MiscellaneousValue19 = 0;
    }
    MiscellaneousValue = MiscellaneousValue10 + MiscellaneousValue11 + MiscellaneousValue12 + MiscellaneousValue13 + MiscellaneousValue14 + MiscellaneousValue15 + MiscellaneousValue16 + MiscellaneousValue17 + MiscellaneousValue18 + MiscellaneousValue19;

    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
      MiscellaneousValue3 = module10Data.miscellaneous_multiplier;
    } else {
      MiscellaneousValue3 = 1;
    }
    MiscellaneousValue *= MiscellaneousValue3;

    incomeStatementCompleteData[year].livingExpenses.Miscellaneous = MiscellaneousValue;
    incomeStatementCompleteData[year]['Miscellaneous Condition'] = conditionCheck;

    // Health Insurance Deductible
    incomeStatementCompleteData[year].livingExpenses['Healthcare Costs other than Premium'] = healthInsuranceDeductibleValue;
    incomeStatementCompleteData[year]['Health Insurance Deductible Condition'] = healthInsuranceCheck;

    // Health Insurance Premium
    incomeStatementCompleteData[year].livingExpenses['Health Insurance Premium'] = healthInsurancePremiumValue;
    incomeStatementCompleteData[year]['Health Insurance Premium Condition'] = healthInsuranceCheck;

    // Children
    let ChildrenValue1 = 0;
    let ChildrenValue2 = 0;
    let ChildrenValue3 = 0;
    let ChildrenValue4 = 0;
    let ChildrenValue5 = 0;
    let ChildrenValue6 = 0;
    let ChildrenValue7 = 0;
    // Child 1
    if (module10Data.first_born !== undefined
      && module10Data.first_born > 0
      && year >= module10Data.first_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue1 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue1 = 0;
      }
    }
    // Child 2
    if (module10Data.second_born !== undefined
      && module10Data.second_born > 0
      && year >= module10Data.second_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue2 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue2 = 0;
      }
    }
    // Child 3
    if (module10Data.third_born !== undefined
      && module10Data.third_born > 0
      && year >= module10Data.third_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue3 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue3 = 0;
      }
    }
    // Child 4
    if (module10Data.fourth_born !== undefined
      && module10Data.fourth_born > 0
      && year >= module10Data.fourth_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue4 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue4 = 0;
      }
    }
    // Child 5
    if (module10Data.fifth_born !== undefined
      && module10Data.fifth_born > 0
      && year >= module10Data.fifth_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue5 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue5 = 0;
      }
    }
    // Child 6
    if (module10Data.sixth_born !== undefined
      && module10Data.sixth_born > 0
      && year >= module10Data.sixth_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue6 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue6 = 0;
      }
    }
    // Child 7
    if (module10Data.seventh_born !== undefined
      && module10Data.seventh_born > 0
      && year >= module10Data.seventh_born) {
      if (databaseData !== undefined && databaseData['Children Info'][year] !== undefined) {
        ChildrenValue7 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue7 = 0;
      }
    }

    ChildrenValue = parseFloat(ChildrenValue1)
      + parseFloat(ChildrenValue2)
      + parseFloat(ChildrenValue3)
      + parseFloat(ChildrenValue4)
      + parseFloat(ChildrenValue5)
      + parseFloat(ChildrenValue6)
      + parseFloat(ChildrenValue7);
    incomeStatementCompleteData[year].livingExpenses.Children = ChildrenValue;

    // Pet
    // let conditionCheck = 0;
    if (module10Data.year_of_purchase !== undefined && module10Data.year_of_purchase !== '' && module10Data.year_of_purchase > 0) {
      if (year >= module10Data.year_of_purchase) {
        if (expenseCalculationsData[year] !== undefined
          && expenseCalculationsData[year]['Yearly Costs'].Total !== undefined) {
          PetValue = -parseFloat(expenseCalculationsData[year]['Yearly Costs'].Total);
          conditionCheck = 1;
        } else {
          PetValue = 0;
        }
      } else {
        PetValue = 0;
      }
    } else {
      PetValue = 0;
    }
    incomeStatementCompleteData[year].livingExpenses.Pet = PetValue;
    incomeStatementCompleteData[year]['Pet Condition'] = conditionCheck;

    // Home Property Expenses
    let homePropertyExpensesValue = 0;
    let homePropertyExpensesValue1 = 0;
    let homePropertyExpensesValue2 = 0;
    if (buyingAHomeData !== undefined && buyingAHomeData !== ''
      && buyingAHomeData.Data1 !== undefined
      && buyingAHomeData.Data1[year] !== undefined
      && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
      && buyingAHomeData.Data1[year]['Association Fees'] !== undefined
      && buyingAHomeData.Data1[year]['Houseowners Insurance'] !== undefined
      && buyingAHomeData.Data1[year]['Property Maintenance'] !== undefined
      && buyingAHomeData.Data1[year].Utilities !== undefined) {
      homePropertyExpensesValue1 = -(buyingAHomeData.Data1[year]['Private Mortgage Insurance']
        + buyingAHomeData.Data1[year]['Association Fees']
        + buyingAHomeData.Data1[year]['Houseowners Insurance']
        + buyingAHomeData.Data1[year]['Property Maintenance']
        + buyingAHomeData.Data1[year].Utilities);
    } else {
      homePropertyExpensesValue1 = 0;
    }
    if (existingHomeData !== undefined && existingHomeData !== ''
      && existingHomeData.Data1 !== undefined
      && existingHomeData.Data1[year] !== undefined
      && existingHomeData.Data1[year]['Houseowners Insurance'] !== undefined
      && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
      && existingHomeData.Data1[year].Maintenance !== undefined
      && existingHomeData.Data1[year]['Association Fees'] !== undefined
      && existingHomeData.Data1[year].Utilities !== undefined) {
      homePropertyExpensesValue2 = -(existingHomeData.Data1[year]['Houseowners Insurance']
        + existingHomeData.Data1[year]['Private Mortgage Insurance']
        + existingHomeData.Data1[year].Maintenance
        + existingHomeData.Data1[year]['Association Fees']
        + existingHomeData.Data1[year].Utilities +
        existingHomeData['Data1'][year]['Houseowners Insurance2'] +
        existingHomeData['Data1'][year]['Private Mortgage Insurance2'] +
        existingHomeData.Data1[year].Maintenance2 +
        existingHomeData['Data1'][year]['Association Fees2'] +
        existingHomeData.Data1[year].Utilities2);
    } else {
      homePropertyExpensesValue2 = 0;
    }

    homePropertyExpensesValue = homePropertyExpensesValue1 + homePropertyExpensesValue2;
    incomeStatementCompleteData[year].livingExpenses['Home Property Expenses'] = homePropertyExpensesValue;

    // Property Taxes
    let propertyTaxeslivingValue = 0;
    let propertyTaxeslivingValue1 = 0;
    let propertyTaxeslivingValue2 = 0;
    // propertyTaxeslivingValue = incomeStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes'];
    // incomeStatementCompleteData[year]['livingExpenses']['Home Property Taxes'] = -(propertyTaxeslivingValue);
    if (module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
      && year === module13Data.year_of_purchase) {
      if (module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '') {
        propertyTaxeslivingValue1 = module13Data.propertyTaxesCalcs;
      } else {
        propertyTaxeslivingValue1 = 0;
      }
    } else if (buyingAHomeData !== undefined && buyingAHomeData !== ''
      && buyingAHomeData['Real Estate'] !== undefined
      && buyingAHomeData['Real Estate'][year] !== undefined
      && buyingAHomeData['Real Estate'][year]['Property Tax'] !== undefined) {
      propertyTaxeslivingValue1 = buyingAHomeData['Real Estate'][year]['Property Tax'];
    } else {
      propertyTaxeslivingValue1 = 0;
    }

    if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData.Data1 !== undefined && existingHomeData.Data1 !== ''
      && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year] !== '' && existingHomeData.Data1[year]['Property Taxes'] !== undefined && existingHomeData.Data1[year]['Property Taxes'] !== '') {
      propertyTaxeslivingValue2 = existingHomeData.Data1[year]['Property Taxes'] + existingHomeData.Data1[year]['Property Taxes2'];
    } else {
      propertyTaxeslivingValue2 = 0;
    }
    propertyTaxeslivingValue = propertyTaxeslivingValue1 + propertyTaxeslivingValue2;
    incomeStatementCompleteData[year].livingExpenses['Home Property Taxes'] = -(propertyTaxeslivingValue);

    // Rental Operating Expenses
    let RentalOperatingExpenses = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1 !== ''
      && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year] !== '') {
      if (invPropertyRoiData.Data1[year]['Association Fees'] !== undefined && invPropertyRoiData.Data1[year]['Association Fees'] !== '') {
        RentalOperatingExpenses += invPropertyRoiData.Data1[year]['Association Fees'];
      }
      if (invPropertyRoiData.Data1[year]['Management Fees'] !== undefined && invPropertyRoiData.Data1[year]['Management Fees'] !== '') {
        RentalOperatingExpenses += invPropertyRoiData.Data1[year]['Management Fees'];
      }
      if (invPropertyRoiData.Data1[year]['Houseowners Insurance'] !== undefined && invPropertyRoiData.Data1[year]['Houseowners Insurance'] !== '') {
        RentalOperatingExpenses += invPropertyRoiData.Data1[year]['Houseowners Insurance'];
      }
      if (invPropertyRoiData.Data1[year]['Property Maintenance'] !== undefined && invPropertyRoiData.Data1[year]['Property Maintenance'] !== '') {
        RentalOperatingExpenses += invPropertyRoiData.Data1[year]['Property Maintenance'];
      }
      if (invPropertyRoiData.Data1[year].Utilities !== undefined && invPropertyRoiData.Data1[year].Utilities !== '') {
        RentalOperatingExpenses += invPropertyRoiData.Data1[year].Utilities;
      }
    }

    incomeStatementCompleteData[year].livingExpenses['Rental Operating Expenses'] = -(RentalOperatingExpenses);

    // Rental Property Taxes
    let rentalPropertyTaxes = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1 !== ''
      && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year] !== '') {
      if (invPropertyRoiData.Data1[year]['Property Taxes'] !== undefined && invPropertyRoiData.Data1[year]['Property Taxes'] !== '') {
        rentalPropertyTaxes = invPropertyRoiData.Data1[year]['Property Taxes'];
      }
    }
    incomeStatementCompleteData[year].livingExpenses['Rental Property Taxes'] = -(rentalPropertyTaxes);

    // Total Living Expenses
    incomeStatementCompleteData[year]['Total Living Expenses'] = incomeStatementCompleteData[year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementCompleteData[year].livingExpenses['Higher Education Room & Board']
      + incomeStatementCompleteData[year].livingExpenses['Off Campus Food']
      + incomeStatementCompleteData[year].livingExpenses.Rent
      + incomeStatementCompleteData[year].livingExpenses.Utilities
      + incomeStatementCompleteData[year].livingExpenses.Food
      + incomeStatementCompleteData[year].livingExpenses.Clothing
      + incomeStatementCompleteData[year].livingExpenses.Entertainment
      + incomeStatementCompleteData[year].livingExpenses.Technology
      + incomeStatementCompleteData[year].livingExpenses.Transportation
      + incomeStatementCompleteData[year].livingExpenses.Miscellaneous
      + incomeStatementCompleteData[year].livingExpenses['Healthcare Costs other than Premium']
      + incomeStatementCompleteData[year].livingExpenses['Health Insurance Premium']
      + incomeStatementCompleteData[year].livingExpenses.Children
      + incomeStatementCompleteData[year].livingExpenses.Pet;

    incomeStatementCompleteData[year]['Total Car Expenses'] = incomeStatementCompleteData[year].livingExpenses['Car Payment']
      + incomeStatementCompleteData[year].livingExpenses['Car Maintenance']
      + incomeStatementCompleteData[year].livingExpenses['Car Insurance']
      + incomeStatementCompleteData[year].livingExpenses.Gas;

    incomeStatementCompleteData[year]['Total Real Estate Expenses'] = incomeStatementCompleteData[year].livingExpenses['Home Property Expenses']
      + incomeStatementCompleteData[year].livingExpenses['Home Property Taxes']
      + incomeStatementCompleteData[year].livingExpenses['Rental Operating Expenses']
      + incomeStatementCompleteData[year].livingExpenses['Rental Property Taxes'];

    totalLivingExpensesGraph.push(incomeStatementCompleteData[year]['Total Living Expenses']);
    totalCarExpensesGraph.push(incomeStatementCompleteData[year]['Total Car Expenses']);
    totalRealEstateExpensesGraph.push(incomeStatementCompleteData[year]['Total Real Estate Expenses']);
    /** End of Living Expenses & Total Living Expenses * */

    /** ***********************************************
** Interest Expenses  & Total Interest Expenses **
************************************************* */
    // Existing Credit Card Debt
    let existingCreditCardDebtValue = 0;
    if (existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Summation Data'] !== undefined && existingCreditCardDebtData['Summation Data'] !== '' && existingCreditCardDebtData['Summation Data'][year] !== undefined && existingCreditCardDebtData['Summation Data'][year] !== '' && existingCreditCardDebtData['Summation Data'][year].LaggingInterest !== undefined) {
      existingCreditCardDebtValue = -(existingCreditCardDebtData['Summation Data'][year].LaggingInterest + existingCreditCardDebtData['Summation Data'][year].LaggingPrincipal);
    }
    incomeStatementCompleteData[year].InterestExpenses['Existing Credit Card Debt'] = existingCreditCardDebtValue;

    //Existing Personal Loans
    let existingPersonalLoansValue = 0;
    if (typeof existingCarLoanData !== 'undefined' && existingCarLoanData !== '' && typeof existingCreditCardDebtData['Summation Data'] !== 'undefined' && existingCreditCardDebtData['Summation Data'] !== '' && typeof existingCarLoanData['Summation Data'][year] !== 'undefined' && existingCarLoanData['Summation Data'][year] !== '' && typeof existingCarLoanData['Summation Data'][year]['LaggingPrincipal5'] !== 'undefined' && typeof existingCarLoanData['Summation Data'][year]['LaggingInterest5'] !== 'undefined') {
      existingPersonalLoansValue = -(existingCarLoanData['Summation Data'][year]['LaggingPrincipal5'] + existingCarLoanData['Summation Data'][year]['LaggingInterest5']);
    }
    incomeStatementCompleteData[year].InterestExpenses['Existing Personal Loans'] = existingPersonalLoansValue;

    // Car Loans
    let carLoansValue = 0;
    let carLoansValue1 = 0;
    if (existingCarLoanData !== undefined
      && existingCarLoanData !== ''
      && existingCarLoanData['Summation Data'] !== undefined
      && existingCarLoanData['Summation Data'] !== ''
      && existingCarLoanData['Summation Data'][year] !== undefined
      && existingCarLoanData['Summation Data'][year] !== '') {
      carLoansValue1 = existingCarLoanData['Summation Data'][year].LaggingInterest + existingCarLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      carLoansValue1 = 0;
    }
    let carLoansValue4 = 0;
    if (typeof existingCarLoanData !== 'undefined' &&
      existingCarLoanData !== '' &&
      typeof existingCarLoanData['Summation Data'] !== 'undefined' &&
      existingCarLoanData['Summation Data'] !== '' &&
      typeof existingCarLoanData['Summation Data'][year] !== 'undefined' &&
      existingCarLoanData['Summation Data'][year] !== '') {
      carLoansValue4 = existingCarLoanData['Summation Data'][year].LaggingInterest2 + existingCarLoanData['Summation Data'][year].LaggingPrincipal2;
    } else {
      carLoansValue4 = 0;
    }
    let carLoansValue5 = 0;
    if (typeof existingCarLoanData !== 'undefined' &&
      existingCarLoanData !== '' &&
      typeof existingCarLoanData['Summation Data'] !== 'undefined' &&
      existingCarLoanData['Summation Data'] !== '' &&
      typeof existingCarLoanData['Summation Data'][year] !== 'undefined' &&
      existingCarLoanData['Summation Data'][year] !== '') {
      carLoansValue5 = existingCarLoanData['Summation Data'][year].LaggingInterest3 + existingCarLoanData['Summation Data'][year].LaggingPrincipal3;
    } else {
      carLoansValue5 = 0;
    }
    let carLoansValue6 = 0;
    if (typeof existingCarLoanData !== 'undefined' &&
      existingCarLoanData !== '' &&
      typeof existingCarLoanData['Summation Data'] !== 'undefined' &&
      existingCarLoanData['Summation Data'] !== '' &&
      typeof existingCarLoanData['Summation Data'][year] !== 'undefined' &&
      existingCarLoanData['Summation Data'][year] !== '') {
      carLoansValue6 = existingCarLoanData['Summation Data'][year].LaggingInterest4 + existingCarLoanData['Summation Data'][year].LaggingPrincipal4;
    } else {
      carLoansValue6 = 0;
    }
    let carLoansValue2 = 0;
    if (carLoan1Data !== undefined
      && carLoan1Data !== ''
      && carLoan1Data['Summation Data'] !== undefined
      && carLoan1Data['Summation Data'] !== ''
      && carLoan1Data['Summation Data'][year] !== undefined
      && carLoan1Data['Summation Data'][year] !== '') {
      carLoansValue2 = carLoan1Data['Summation Data'][year].LaggingInterest + carLoan1Data['Summation Data'][year].LaggingPrincipal;
    } else {
      carLoansValue2 = 0;
    }
    let carLoansValue3 = 0;
    if (carLoan2Data !== undefined
      && carLoan2Data !== ''
      && carLoan2Data['Summation Data'] !== undefined
      && carLoan2Data['Summation Data'] !== ''
      && carLoan2Data['Summation Data'][year] !== undefined
      && carLoan2Data['Summation Data'][year] !== '') {
      carLoansValue3 = carLoan2Data['Summation Data'][year].LaggingInterest + carLoan2Data['Summation Data'][year].LaggingPrincipal;
    } else {
      carLoansValue3 = 0;
    }
    if (year === 2022) {
    }
    carLoansValue = -(carLoansValue1 + carLoansValue2 + carLoansValue3 + carLoansValue4 + carLoansValue5 + carLoansValue6);

    incomeStatementCompleteData[year].InterestExpenses['Car Loans'] = carLoansValue;

    // Student Loans
    let existingfedSub = 0;
    let existingfedUnSub = 0;
    let existingprivate = 0;
    let fedSub = 0;
    let fedUnSub = 0;
    let privateHigher = 0;
    let fedSubMore = 0;
    let fedUnSubMore = 0;
    let privateHigherMore = 0;
    let fedSubNew = 0;
    let fedUnSubNew = 0;
    let privateHigherNew = 0;
    let spouseInterestValue = 0;
    if (fedSubExistingLoanData !== undefined
      && fedSubExistingLoanData['Summation Data'] !== undefined
      && fedSubExistingLoanData['Summation Data'][year] !== undefined) {
      existingfedSub = fedSubExistingLoanData['Summation Data'][year].LaggingInterest + fedSubExistingLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedUnSubExistingLoanData !== undefined
      && fedUnSubExistingLoanData['Summation Data'] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][year] !== undefined) {
      existingfedUnSub = fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest + fedUnSubExistingLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (privateExistingLoanData !== undefined
      && privateExistingLoanData['Summation Data'] !== undefined
      && privateExistingLoanData['Summation Data'][year] !== undefined) {
      existingprivate = privateExistingLoanData['Summation Data'][year].LaggingInterest + privateExistingLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedSubHigherEdLoanData !== undefined
      && fedSubHigherEdLoanData['Summation Data'] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedSub = fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest + fedSubHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedUnSubHigherEdLoanData !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedUnSub = fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (privateHigherEdLoanData !== undefined
      && privateHigherEdLoanData['Summation Data'] !== undefined
      && privateHigherEdLoanData['Summation Data'][year] !== undefined) {
      privateHigher = privateHigherEdLoanData['Summation Data'][year].LaggingInterest + privateHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedSubMoreHigherEdLoanData !== undefined
      && fedSubMoreHigherEdLoanData['Summation Data'] !== undefined
      && fedSubMoreHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedSubMore = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedUnSubMoreHigherEdLoanData !== undefined
      && fedUnSubMoreHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedUnSubMore = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (privateMoreHigherEdLoanData !== undefined
      && privateMoreHigherEdLoanData['Summation Data'] !== undefined
      && privateMoreHigherEdLoanData['Summation Data'][year] !== undefined) {
      privateHigherMore = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + privateMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedSubNewHigherEdLoanData !== undefined
      && fedSubNewHigherEdLoanData['Summation Data'] !== undefined
      && fedSubNewHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedSubNew = fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest + fedSubNewHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (fedUnSubNewHigherEdLoanData !== undefined
      && fedUnSubNewHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubNewHigherEdLoanData['Summation Data'][year] !== undefined) {
      fedUnSubNew = fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (privateNewHigherEdLoanData !== undefined
      && privateNewHigherEdLoanData['Summation Data'] !== undefined
      && privateNewHigherEdLoanData['Summation Data'][year] !== undefined) {
      privateHigherNew = privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest + privateNewHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    }
    if (spouseStudentLoanData !== undefined
      && spouseStudentLoanData['Summation Data'] !== undefined
      && spouseStudentLoanData['Summation Data'][year] !== undefined) {
      spouseInterestValue = spouseStudentLoanData['Summation Data'][year].LaggingInterest + spouseStudentLoanData['Summation Data'][year].LaggingPrincipal;
    }

    let StudentLoanValue = 0;
    StudentLoanValue = -parseFloat(
      parseFloat(existingfedSub)
      + parseFloat(existingfedUnSub)
      + parseFloat(existingprivate)
      + parseFloat(fedSub)
      + parseFloat(fedUnSub)
      + parseFloat(privateHigher)
      + parseFloat(fedSubMore)
      + parseFloat(fedUnSubMore)
      + parseFloat(privateHigherMore)
      + parseFloat(fedSubNew)
      + parseFloat(fedUnSubNew)
      + parseFloat(privateHigherNew)
      + parseFloat(spouseInterestValue),
    );
    incomeStatementCompleteData[year].InterestExpenses['Student Loans'] = StudentLoanValue;

    // Mortgages
    let MortgagesValue = 0;
    let MortgagesValue1 = 0;
    let MortgagesValue2 = 0;
    // const MortgagesValue3 = 0;

    if (mortgage1Data !== undefined
      && mortgage1Data['Summation Data'] !== undefined
      && mortgage1Data['Summation Data'][year] !== undefined
      && mortgage2Data !== undefined
      && mortgage2Data['Summation Data'][year] !== undefined) {
      MortgagesValue1 = (mortgage1Data['Summation Data'][year].LaggingInterest
        + mortgage2Data['Summation Data'][year].LaggingInterest
        + mortgage1Data['Summation Data'][year].LaggingPrincipal
        + mortgage2Data['Summation Data'][year].LaggingPrincipal);
    } else {
      MortgagesValue1 = 0;
    }
    if (existingHomeMortgageData !== undefined
      && existingHomeMortgageData !== ''
      && existingHomeMortgageData['Summation Data'] !== undefined
      && existingHomeMortgageData['Summation Data'][year] !== undefined
      && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== '') {
      MortgagesValue2 = existingHomeMortgageData['Summation Data'][year].LaggingInterest +
        existingHomeMortgageData['Summation Data'][year].LaggingPrincipal +
        existingHomeMortgageData['Summation Data'][year].LaggingInterest2 +
        existingHomeMortgageData['Summation Data'][year].LaggingPrincipal2;
    } else {
      MortgagesValue2 = 0;
    }

    MortgagesValue = -(MortgagesValue1 + MortgagesValue2);
    incomeStatementCompleteData[year].InterestExpenses.Mortgages = MortgagesValue;

    // Total Intereset Expenses
    // existingCreditCardDebtValue +
    incomeStatementCompleteData[year]['Total Interest Expenses'] = existingCreditCardDebtValue
      + carLoansValue
      + existingPersonalLoansValue
      + StudentLoanValue
      + MortgagesValue;
    totalInterestExpensesGraph.push(incomeStatementCompleteData[year]['Total Interest Expenses']);
    /** End of Interest Expenses & Total Interest Expenses * */

    /** *****************
** Total Expenses **
******************* */
    incomeStatementCompleteData[year]['Total Expenses'] = incomeStatementCompleteData[year]['Total Living Expenses']
      + incomeStatementCompleteData[year]['Total Car Expenses']
      + incomeStatementCompleteData[year]['Total Real Estate Expenses']
      + incomeStatementCompleteData[year]['Total Interest Expenses'];
    totalExpensesGraph.push(incomeStatementCompleteData[year]['Total Expenses']);
    /** End of Total Expenses * */

    /** ***************************
** Investment Property Sale **
***************************** */
    // Investment Property Sale
    let investmentPropertySale = 0;
    let investmentPropertySale1 = 0;
    let investmentPropertySale2 = 0;
    if (module14Data.moreREIGain !== undefined && module14Data.year_of_sale !== undefined
      && year === module14Data.year_of_sale) {
      // modulesServices.realEstateClosingCosts();
      // modulesServices.moreREIClosingCost();
      // modulesServices.moreREIGain();
      // modulesServices.moreREITaxCapital();
      // modulesServices.moreREITaxAccumulatedValue();
      // modulesServices.moreREITaxAccumulated();
      investmentPropertySale1 = module14Data.moreREIGain;
    }
    if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && year === module18Data.existing_home_sale_year) {
      if (module18Data.gainOnSale !== undefined && module18Data.gainOnSale !== '') {
        investmentPropertySale2 = module18Data.gainOnSale;
      } else {
        investmentPropertySale2 = 0;
      }
    }
    investmentPropertySale = investmentPropertySale1 + investmentPropertySale2;

    incomeStatementCompleteData[year]['Investment Property Sale'].Gain = investmentPropertySale;

    // Tax on Sale
    let taxOnSale = 0;
    let taxOnSale1 = 0;
    let taxOnSale2 = 0;
    // let conditionCheck = 0;
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== ''
      && module14Data.year_of_sale !== undefined
      && year === module14Data.year_of_sale) {
      const val1 = invPropertyRoiData['Total on Accumulated'];
      const val2 = invPropertyRoiData['Total on Gain'];
      taxOnSale1 = -(val1 + val2);
      conditionCheck = 1;
    }

    if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && year === module18Data.existing_home_sale_year) {
      if (module18Data.taxOnGain !== undefined && module18Data.taxOnGain !== '') {
        taxOnSale2 = -module18Data.taxOnGain;
      } else {
        taxOnSale2 = 0;
      }
    }
    taxOnSale = taxOnSale1 + taxOnSale2;

    incomeStatementCompleteData[year]['Investment Property Sale']['Tax on Sale'] = taxOnSale;
    incomeStatementCompleteData[year]['Tax on Sale Condition'] = conditionCheck;

    // Total Investment Property Sale
    incomeStatementCompleteData[year]['Total Investment Property Sale'] = investmentPropertySale + taxOnSale;

    incomeStatementCompleteData[year]['Net Income'] = parseFloat(incomeStatementCompleteData[year]['Total Expenses']) + parseFloat(incomeStatementCompleteData[year]['Income After Taxes']);
    netIncomeGraph.push(incomeStatementCompleteData[year]['Net Income']);
  }
  // To return Income Statement Sheet Complete Data
  function incomeStatementFullData() {
    incomeStatementCompleteData['Total Disposable Income Graph'] = totalDisposableGraph;
    incomeStatementCompleteData['Total Living Expenses Graph'] = totalLivingExpensesGraph;
    incomeStatementCompleteData['Total Gross Income Graph'] = totalGrossIncomeGraph;
    incomeStatementCompleteData['Total Taxes Graph'] = totalTaxesGraph;
    incomeStatementCompleteData['Income After Taxes Graph'] = incomeAfterTaxesGraph;
    incomeStatementCompleteData['Total Depreciation Expenses Graph'] = depreciationExpensesGraph;
    incomeStatementCompleteData['Total Interest Expenses Graph'] = totalInterestExpensesGraph;
    incomeStatementCompleteData['Total Expenses Graph'] = totalExpensesGraph;
    incomeStatementCompleteData['Net Income Graph'] = netIncomeGraph;
    incomeStatementCompleteData['Spouses Total Income'] = spousesTotalIncome;
    incomeStatementCompleteData['Total Car Expenses Graph'] = totalCarExpensesGraph;
    incomeStatementCompleteData['Total Real Estate Expenses Graph'] = totalRealEstateExpensesGraph;
    return incomeStatementCompleteData;
  }
  return incomeStatementFullData();
}
export default incomeStatementSheetData;