import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';
import * as moduleServices from '../../../calculations/modules-services';

const TotalMoneyNeeded = ( props ) => {
  const {
    classes, inputData, handleSlide
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>
        Other expenses can factor into paying for higher education. Check out the table below to see what other expenses need to be paid for during your higher education.
      </p>

      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ classes.higherEducationStepFour }>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className={ classes.trHead }>
                <td colSpan={ 2 } align="center">Money Needed</td>
              </tr>
              <tr aria-hidden="true" onClick={()=>handleSlide(6,'back')} className={classes.cursorPointer}>
                <td>Tuition & Fees</td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.totalTuitionAndFees } prefix={ moduleServices.module2Data.totalTuitionAndFees >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.totalTuitionAndFees < 0 && ')' } />
                  </div>
                </td>
              </tr>
              {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                <tr aria-hidden="true" onClick={()=>handleSlide(6,'back')} className={classes.cursorPointer}>
                  <td>Room & Board</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.totalRoomAndBoard } prefix={ moduleServices.module2Data.totalRoomAndBoard >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.totalRoomAndBoard < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                <tr aria-hidden="true" onClick={()=>handleSlide(8,'back')} className={classes.cursorPointer}>
                  <td>On-Campus Living Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherOnCampusLivingExpenses } prefix={ moduleServices.module2Data.higherOnCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherOnCampusLivingExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              <tr aria-hidden="true" onClick={()=>handleSlide(8,'back')} className={classes.cursorPointer}>
                <td>Off-Campus Living Expenses</td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherOffCampusLivingExpenses } prefix={ moduleServices.module2Data.higherOffCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherOffCampusLivingExpenses < 0 && ')' } />
                  </div>
                </td>
              </tr>
              {moduleServices.module2Data.totalStudentsLoanPayments > 0 && (
                <tr>
                  <td>Total Student Loan Payments during College</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.totalStudentsLoanPayments } prefix={ moduleServices.module2Data.totalStudentsLoanPayments >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.totalStudentsLoanPayments < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              {moduleServices.module2Data.otherLoanPaymentsonOtherDebt > 0 && (
                <tr>
                  <td>Other Interest Expenses on Other Debt</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.otherLoanPaymentsonOtherDebt } prefix={ moduleServices.module2Data.otherLoanPaymentsonOtherDebt >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.otherLoanPaymentsonOtherDebt < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              {moduleServices.module2Data.higherCarExpense > 0 && (
                <tr>
                  <td>Car Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherCarExpense } prefix={ moduleServices.module2Data.higherCarExpense >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherCarExpense < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {moduleServices.module2Data.higherRealEstateExpenses > 0 && (
                <tr>
                  <td>Real Estate Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherRealEstateExpenses } prefix={ moduleServices.module2Data.higherRealEstateExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherRealEstateExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {moduleServices.module2Data.higherChildrenAndPetExpenses > 0 && (
                <tr>
                  <td>Children and Pet Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.higherChildrenAndPetExpenses } prefix={ moduleServices.module2Data.higherChildrenAndPetExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.higherChildrenAndPetExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              <tr className={ classes.trFoot }>
                <td className={ classes.totalBoldText }>
                  Total Cost of Attendance
                </td>
                <td>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.educationTotalAttendance } prefix={ moduleServices.module2Data.educationTotalAttendance >= 0 ? '$' : '($' } suffix={ moduleServices.module2Data.educationTotalAttendance < 0 && ')' } />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

TotalMoneyNeeded.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleSlide: PropTypes.func.isRequired,
};

export default withStyles( styles )( TotalMoneyNeeded );
