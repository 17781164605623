import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import writingCheck1 from '../../../assets/img/discovery/writing_check1.png';
import writeCheckAns from '../../../assets/img/discovery/write_check_ans.png';
import poweUp101 from '../../../assets/img/pf101/challenge/powerup.png';
import GraphOne from '../../../assets/img/pf101/challenge/graph_1.png';
import dashboardLogo from '../../../assets/img/pf101/challenge/dashboard-logo.png';
import creditcardsDiscoveryNew from '../../../assets/img/pf101/challenge2/creditcards_discovery.png';
import caluculationsChallenge from '../../../assets/img/pf101/challenge2/caluculations_challenge.png';
import abImage from '../../../assets/img/pf101/challenge2/ab_image.png';
import CrditScoreCh2 from '../../../assets/img/pf101/challenge2/credit_score.png';
import extraLoan from '../../../assets/img/pf101/challenge2/extra_loan_repay.png';
import elanArrow from '../../../assets/img/pf101/challenge2/eloan_arrow.png';

import Pageloader from '../../../components/ui/pageloader';
import { xpChallengePoints, getTotalTime, getUserXPRank } from '../exercises/pf101-service';
import Pf101Popups from '../exercises/popups';

const GET_CHALLENGE = loader( '../../../graphql/schema/pf101/get-exercise-details.graphql' );
const SAVE_CHALLENGE = loader( '../../../graphql/schema/pf101/save-exercise.graphql' );

const startTime = new Date();

/* eslint react/prop-types: 0 */

const Pf101Challenge2 = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const correctAns = ['a', 'c', 'c', 'a', 'd', 'c', 'c', 'b', 'd', 'c', 'a'];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 10; i += 1 ) {
    const questionsObject = {
      id: i + 1, answer: '', correctAns: correctAns[i], scroll: defaultReference, question_attempts: 1, xp_earned: 0,
    };
    questionMainAns.push( questionsObject );
  }

  questionMainAns[3].sub = {};
  questionMainAns[3].sub[1] = { answer: '', correctAns: 'check_number' };
  questionMainAns[3].sub[2] = { answer: '', correctAns: 'today_date' };
  questionMainAns[3].sub[3] = { answer: '', correctAns: 'person_paying' };
  questionMainAns[3].sub[4] = { answer: '', correctAns: 'amount' };
  questionMainAns[3].sub[5] = { answer: '', correctAns: 'amount_words' };
  questionMainAns[3].sub[6] = { answer: '', correctAns: 'reason' };
  questionMainAns[3].sub[7] = { answer: '', correctAns: 'signature' };
  questionMainAns[3].sub[8] = { answer: '', correctAns: 'routing' };
  questionMainAns[3].sub[9] = { answer: '', correctAns: 'account_number' };
  questionMainAns[3].sub.check = false;

  const question3options = [{ name: 'Your signature', val: 'signature' },
    { name: 'Amount written in numbers', val: 'amount' },
    { name: 'Check number', val: 'check_number' },
    { name: 'Amount written in words', val: 'amount_words' },
    { name: 'Today’s date', val: 'today_date' },
    { name: 'Reason for check (not required)', val: 'reason' },
    { name: 'Bank account number', val: 'account_number' },
    { name: 'Person or entity you are paying', val: 'person_paying' },
    { name: 'Routing number', val: 'routing' }];

  let quesionSection = useRef( null );
  const [step, setStep] = React.useState( 0 );
  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [loading, setLoading] = React.useState( false );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [challengePoints, setChallengePoints] = React.useState( 0 );
  const [challengePercentage, setChallengePercentage] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  useQuery( GET_CHALLENGE, {
    variables: {
      level: 12,
      exercise: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questionData = [...questions];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questionData[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questionData[data.question_id - 1].xp_earned = xpChallengePoints( questions[data.question_id - 1].question_attempts );
          questionData[data.question_id - 1].status = 'C';
          questionData[data.question_id - 1].answer = questions[data.question_id - 1].correctAns;
          if ( data.question_id === 4 ) {
            questionData[3].sub[1] = { answer: 'check_number', correctAns: 'check_number' };
            questionData[3].sub[2] = { answer: 'today_date', correctAns: 'today_date' };
            questionData[3].sub[3] = { answer: 'person_paying', correctAns: 'person_paying' };
            questionData[3].sub[4] = { answer: 'amount', correctAns: 'amount' };
            questionData[3].sub[5] = { answer: 'amount_words', correctAns: 'amount_words' };
            questionData[3].sub[6] = { answer: 'reason', correctAns: 'reason' };
            questionData[3].sub[7] = { answer: 'signature', correctAns: 'signature' };
            questionData[3].sub[8] = { answer: 'routing', correctAns: 'routing' };
            questionData[3].sub[9] = { answer: 'account_number', correctAns: 'account_number' };
            questionData[3].sub.check = false;
          }
        } );
        setQuestions( questionData );
        setExerciseStatus( 'review' );
        setStep( 2 );
      } else {
        setStep( 1 );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveChallenge] = useMutation( SAVE_CHALLENGE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  useEffect( () => {
    const answerQuestions = _.filter( questions, ['status', 'C'] ).length;
    let progressBar = answerQuestions * ( 100 / 11 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );

    const challengePoint = _.sumBy( questions, 'xp_earned' );
    setChallengePoints( challengePoint );
    setChallengePercentage( ( challengePoint / 1100 ) * 100 );
  }, [questions] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    if ( newQuesions[index].status === 'W' ) {
      newQuesions[index].question_attempts += 1;
    } else {
      newQuesions[index].xp_earned = xpChallengePoints( newQuesions[index].question_attempts );
    }
    setQuestions( newQuesions );
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.reject( questions, ['status', 'C'] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'id' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      const totalTime = getTotalTime( startTime );
      const totalPoints = _.sumBy( questions, 'xp_earned' );
      const totalAttempts = _.sumBy( questions, 'question_attempts' );
      const questionData = questions.map( ( question ) => _.pick( question, ['id', 'question_attempts', 'xp_earned'] ) );

      const popupValue = {};
      popupValue.exercisePoints = totalPoints;
      popupValue.precision = false;
      popupValue.level = false;

      const presentRank = getUserXPRank( pf101Points );
      const nextRank = getUserXPRank( pf101Points + totalPoints );
      if ( presentRank !== nextRank ) {
        popupValue.rankChangeStatus = true;
        popupValue.rank = nextRank;
      } else {
        popupValue.rankChangeStatus = false;
        popupValue.rank = '';
      }
      popupValue.type = 'challenge';
      setPopupDetails( popupValue );
      saveChallenge( {
        variables: {
          data: {
            level: 12,
            exercise: 1,
            exercise_time: totalTime,
            points: totalPoints,
            total_attempts: totalAttempts,
            questionData,
          },
        },
      } );
    }
    return true;
  };

  const handleCheckAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[3].sub[index].answer = event.target.value;
    let subAns = Object.values( newQuesions[3].sub );
    subAns = _.filter( subAns, { answer: '' } );
    if ( subAns.length === 0 ) {
      newQuesions[3].sub.check = true;
    }

    if ( newQuesions[3].sub[index].answer === newQuesions[3].sub[index].correctAns ) {
      newQuesions[3].sub[index].status = 'C';
    } else {
      newQuesions[3].sub[index].status = 'W';
    }

    setQuestions( newQuesions );
  };

  const check7thAnswer = () => {
    const newQuesions = [...questions];
    const subAns = Object.values( newQuesions[3].sub );
    const ansQuesions = _.filter( subAns, { answer: '' } );
    if ( ansQuesions.length === 0 ) {
      const subCorrectAns = _.filter( subAns, { status: 'C' } );
      if ( subCorrectAns.length === 9 ) {
        newQuesions[3].answer = 'a';
      } else {
        newQuesions[3].answer = 'b';
      }
      newQuesions[3].status = questions[3].correctAns === newQuesions[3].answer ? 'C' : 'W';
    }
    if ( newQuesions[3].status === 'C' ) {
      newQuesions[3].xp_earned = xpChallengePoints( newQuesions[3].question_attempts );
    }
    setQuestions( newQuesions );
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( step === 2 ) {
        if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 ) {
          document.getElementById( 'awareness_title' ).style.position = 'fixed';
          document.getElementById( 'awareness_title_sub' ).style.display = 'block';
        } else {
          document.getElementById( 'awareness_title' ).style.position = '';
          document.getElementById( 'awareness_title_sub' ).style.display = 'none';
        }

        if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 ) {
          document.getElementById( 'sticky_numbers' ).style.left = '30px';
        } else {
          document.getElementById( 'sticky_numbers' ).style.left = '-11em';
        }

        for ( let i = 0; i < 11; i += 1 ) {
          const element = $( questionMainAns[i].scroll );
          let subElement = false;
          // if ( questions[i].status === 'C' ) {
          // }
          subElement = true;

          if ( ( elementInViewport( element ) === true || elementInViewport( element.find( '>div:first-child' ).find( 'select' ) ) )
            || ( subElement === true && ( elementInViewport( element.find( '>div:first-child' ) ) || elementInViewport( element.find( '>div:last-child' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'h3' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h2' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'div' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'ul' ) ) ) ) ) {
            element.css( 'opacity', '1' );
            element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
            $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
            if ( i <= 6 ) {
              $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
            } else {
              $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
            }
            break;
          }
        }

        if ( ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
          document.getElementById( 'progress-bar' ).style.display = 'none';
        } else {
          document.getElementById( 'progress-bar' ).style.display = 'block';
        }
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }

      {step === 1 && (
        <div className={ `${classes.textTop} ${classes.textTopMin}` }>
          <div className={ classes.container }>
            <div className={ classes.logoNew }>
              <Link href="/">
                <img src={ dashboardLogo } alt="" />
              </Link>
            </div>
            <div className={ classes.pfModuleImage }>
              <Link href="/pf101/dashboard">
                <img src={ poweUp101 } alt="" />
              </Link>
            </div>
            <div className={ `${classes.tableBox} ${classes.tableWelcomeChallenge}` }>
              <h2>Welcome to Challenge 02!</h2>
              <p>
                How well do you know Levels 04-06?
                <br />
                Put your skills to the test with a challenge.
                <br />
                Let&apos;s get started!
              </p>
              <Button onClick={ () => { setStep( 2 ); } }> Next </Button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          {exerciseStatus === 'submit' && (
            <div className={ classes.textTop }>
              <div className={ classes.container }>
                <div className={ classes.logoNew }>
                  <img src={ dashboardLogo } alt="" />
                </div>
                <div className={ classes.pfModuleImage }>
                  <img src={ poweUp101 } alt="" />
                </div>
                <div className={ classes.tableBox }>

                  <div className={ classes.textTopContent }>
                    <h4>Each question is worth 100 XP. If you get the question right on the first try, you will earn 100 XP for that question. If you get the question right on the second try, 80 XP. On the third try, 65 XP. Lastly, on the fourth try, 50 XP.</h4>
                    <p>After you get the correct answer, review the solution to that question and gain valuable financial life knowledge. Good luck!</p>
                    <Button onClick={ () => { scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } ); } }> TAKE THE CHALLENGE </Button>
                  </div>
                  <div className={ classes.imagesBlockNew }>
                    <div className={ classes.imageOne }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageTwo }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageThree }>
                      <img src={ GraphOne } alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={ classes.awarenessBody }>
            <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
              <div className={ classes.ChallengesQuizHead } id="awareness_title">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">PF101 Challenge 02</Typography>
                </div>
              </div>

              <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">&nbsp;</Typography>
                </div>
              </div>

              <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
                <ul>
                  {
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map( ( value ) => (
                        <li key={ value }>
                          <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].status === 'C' && classes.listNumRight ) ) }>
                            <span>
                              {value}
                              <i className="las la-check" />
                            </span>
                          </Button>
                        </li>
                      ) )
                    }
                </ul>
                <div className={ classes.stickyBottom }>
                  <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <i
                        className="las la-arrow-up"
                        data-for="returnStart"
                        data-tip="Return to start"
                      />
                    </span>
                    <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </Button>
                </div>
              </div>
              <div className={ classes.questionRow }>
                <div className={ classes.container }>
                  <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>1</span>
                      <p className={ classes.questionTitle }>Your parents gift you $5,000 for your 30th birthday. Which of the following options below would be the best use of that money?</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[0].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Pay off $5,000 credit card balance
                              <input type="radio" disabled={ questions[0].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Invest that money in your friend&apos;s new business
                              <input type="radio" disabled={ questions[0].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Repay $5,000 car loan balance
                              <input type="radio" disabled={ questions[0].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Repay $5,000 student loan balance
                              <input type="radio" disabled={ questions[0].answer === 'a' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[0].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox, classes.ansBox1 ) }>
                          <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                            <h3>Credit cards are always the most expensive debt</h3>
                            <img src={ creditcardsDiscoveryNew } alt="" />
                            <p>
                              Credit cards usually charge
                              {' '}
                              {' '}
                              <span className={ classes.spanColor }>interest rates between 15-30%!</span>
                              {' '}
                              Since credit cards charge the highest interest rates, they are the most expensive and should be repaid before repaying any other debt early.
                            </p>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[1].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>2</span>
                      <p className={ classes.questionTitle }>Which of the following is NOT a characteristic of a checking account? </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[1].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              A checking account is often used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks
                              <input type="radio" disabled={ questions[1].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              A checking account is a highly liquid bank account
                              <input type="radio" disabled={ questions[1].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              A bank usually pays you a high interest rate for money in a checking account
                              <input type="radio" disabled={ questions[1].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              You can use your debit card at an ATM to withdraw cash from your checking account
                              <input type="radio" disabled={ questions[1].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[1].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox, classes.ansBox3 ) }>
                          <Typography className={ classes.boxTitle } variant="h2" component="h2">What&apos;s a checking account?</Typography>
                          <p className={ classes.boxPara }>A bank account that provides easy access to your money and is used to write personal checks, withdraw cash from an ATM and pay bills and other day-to-day expenses.</p>
                          <ul className={ classes.arrowList }>
                            <li>A checking account is often used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks.</li>
                            <li>A checking account is a highly liquid bank account.</li>
                            <li>You can use your debit card at an ATM to withdraw cash from your checking account.</li>
                          </ul>
                          <p className={ classes.boxPara }>
                            But, a checking account usually
                            { ' ' }
                            <span className={ classes.spanColor }>does not pay you a high interest rate on your money.</span>
                            { ' ' }
                            Since checking accounts don’t pay high interest, you don’t want to keep too much money in a checking account. That money could earn a higher return in a high-yield savings account or money market fund without taking any additional risk.
                          </p>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[2].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>3</span>
                      <p className={ classes.questionTitle }>You use your credit card to buy a TV for $300. When your credit card balance comes due and you don&apos;t make your payment, what happens next?</p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[2].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              You can just pay back the $300 to the credit card company next month
                              <input type="radio" disabled={ questions[2].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Since it&apos;s only $300, the credit card company will allow you to pay it back over the next three months free of charge
                              <input type="radio" disabled={ questions[2].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Interest begins to accrue on your credit card balance the day after your payment due date and does not stop accruing until the $300 plus interest and late fees are paid off
                              <input type="radio" disabled={ questions[2].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              You can cancel the credit card immediately to avoid interest charges
                              <input type="radio" disabled={ questions[2].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[2].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox, classes.ansBox3 ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">What happens if you miss a credit card payment?</Typography>
                              <p>Your credit card company does not provide you with free money. When you miss a payment, you cannot cancel your credit card to avoid fees and interest. And, it does not matter how small or large the balance, the credit card company will charge fees and interest.</p>
                              <p>When you miss a credit card payment, a few things happen:</p>
                              <ul className={ classes.arrowList }>
                                <li>Your credit score might decrease</li>
                                <li>Your credit card company will immediately charge you a late fee of roughly $30 and add that amount to your balance</li>
                                <li>You will be charged high interest on your balance you did not repay</li>
                                <li>Your interest rate will most likely rise to the penalty rate of 30%</li>
                              </ul>
                              <p>
                                <span className={ classes.spanColor }>Rule of Thumb – don&apos;t make a purchase on your credit card if you don&apos;t have the money in your bank account to pay for that purchase.</span>
                              </p>

                            </Grid>

                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[3].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>4</span>
                      <p className={ classes.questionTitle }>Personal Finance 101: Do you know how to write a check?</p>
                      <figure className={ classes.quetionsFigure }>
                        <img src={ writingCheck1 } alt="" />
                      </figure>
                      <div className={ classes.writeCheckQ9 }>
                        <Grid container spacing={ 3 } className={ classes.gridRow }>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[1].answer === questions[3].sub[1].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[1].answer !== questions[3].sub[1].correctAns && classes.listWrong ) }>1</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[1].answer } onChange={ ( e ) => handleCheckAnswer( e, 1 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[2].answer === questions[3].sub[2].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[2].answer !== questions[3].sub[2].correctAns && classes.listWrong ) }>2</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[2].answer } onChange={ ( e ) => handleCheckAnswer( e, 2 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[3].answer === questions[3].sub[3].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[3].answer !== questions[3].sub[3].correctAns && classes.listWrong ) }>3</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[3].answer } onChange={ ( e ) => handleCheckAnswer( e, 3 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                        <Grid container spacing={ 3 } className={ classes.gridRow }>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[4].answer === questions[3].sub[4].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[4].answer !== questions[3].sub[4].correctAns && classes.listWrong ) }>4</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[4].answer } onChange={ ( e ) => handleCheckAnswer( e, 4 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[5].answer === questions[3].sub[5].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[5].answer !== questions[3].sub[5].correctAns && classes.listWrong ) }>5</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[5].answer } onChange={ ( e ) => handleCheckAnswer( e, 5 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[6].answer === questions[3].sub[6].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[6].answer !== questions[3].sub[6].correctAns && classes.listWrong ) }>6</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[6].answer } onChange={ ( e ) => handleCheckAnswer( e, 6 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                        <Grid container spacing={ 3 } className={ classes.gridRow }>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[7].answer === questions[3].sub[7].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[7].answer !== questions[3].sub[7].correctAns && classes.listWrong ) }>7</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[7].answer } onChange={ ( e ) => handleCheckAnswer( e, 7 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[8].answer === questions[3].sub[8].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[8].answer !== questions[3].sub[8].correctAns && classes.listWrong ) }>8</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[8].answer } onChange={ ( e ) => handleCheckAnswer( e, 8 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.selectList }>
                              <li className={ classes.listDropdown }>
                                <span className={ classNames( classes.listCount, questions[3].answer !== '' && questions[3].sub[9].answer === questions[3].sub[9].correctAns && classes.listWright, questions[3].answer !== '' && questions[3].sub[9].answer !== questions[3].sub[9].correctAns && classes.listWrong ) }>9</span>
                                <span className={ classes.listCountSelect }>
                                  <select value={ questions[3].sub[9].answer } onChange={ ( e ) => handleCheckAnswer( e, 9 ) } disabled={ questions[3].status === 'C' }>
                                    <option value="">Please Select</option>
                                    {
                                        question3options.map( ( options ) => (
                                          <option key={ options.val } value={ options.val }>{options.name}</option>
                                        ) )
                                      }
                                  </select>
                                </span>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                        <div className={ classes.checkScoreDiv }>
                          <Button className={ `${classes.checkScoreBtn} ` } onClick={ check7thAnswer } disabled={ questions[3].sub.check === false || questions[3].status === 'C' }>CHECK ANSWERS</Button>
                        </div>
                      </div>
                    </div>
                    {questions[3].status === 'C'
                        && (
                        <div className={ classes.ansRow }>
                          <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                          <div className={ classes.ansBox }>
                            <Typography className={ classes.boxTitle } variant="h2" component="h2">Money 101: Writing a check</Typography>
                            <div className={ classes.listDivNine }>
                              <Grid container spacing={ 2 }>
                                <Grid item xs={ 12 } sm={ 6 } md={ 5 }>
                                  <ul className={ classes.listDivNineUl }>
                                    <li>Check number</li>
                                    <li>Today’s date</li>
                                    <li>Person or entity you are paying</li>
                                    <li>Amount written in numbers</li>
                                    <li>Amount written in words</li>
                                    <li>Reason for check (not required)</li>
                                    <li>Your signature</li>
                                    <li>Your bank’s routing number</li>
                                    <li>Your personal bank account number</li>
                                  </ul>
                                </Grid>
                                <Grid item xs={ 12 } sm={ 6 } md={ 7 }>
                                  <div className={ classes.graphBox }>
                                    <figure className={ classes.figureGraphBox }>
                                      <img src={ writeCheckAns } alt="" />
                                    </figure>
                                  </div>
                                </Grid>
                              </Grid>
                              <p className={ classes.boxPara }>
                                Want more details on how to write a check? Access this
                                {' '}
                                <Link className={ classes.textBlueLink } to="/">link here.</Link>
                              </p>
                            </div>
                            {/* <div className={ classes.senseRatingCommentsNew }>
                              <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                            </div> */}
                          </div>
                          <div className={ classes.nextQuestion }>
                            <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[4].scroll ) }>next question v</Link>
                          </div>
                        </div>
                        )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>5</span>
                      <p className={ classes.questionTitle }>
                        What is the term for a three-digit number ranging from 300 – 850 (higher the better) that relates to how likely you are to repay debt.
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[4].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Debt Repayment Score
                              <input type="radio" disabled={ questions[4].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Credit Repayment Score
                              <input type="radio" disabled={ questions[4].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Financial Score
                              <input type="radio" disabled={ questions[4].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Credit Score
                              <input type="radio" disabled={ questions[4].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[4].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 7 }>
                              <Typography variant="h3">Credit Score</Typography>
                              <p className={ classes.paddingRight }>
                                <span className={ classes.spanColor }>Credit score is a three-digit number ranging from 300 – 850(higher the better) that relates to how likely you are to repay debt.</span>
                                {' '}
                                {' '}
                                A credit score above 700 is generally considered good.
                              </p>
                              <p>
                                Your credit score is very important to maintain and improve as banks and lenders will use your score when deciding whether to approve you for a credit card or loans such as auto loans, student loans, and mortgages. And, a higher credit score usually leads to lower interest rates on loans. Lower interest rates equals thousands of dollars saved.
                              </p>
                            </Grid>
                            <Grid sm={ 5 } className={ classes.ansBox5Sub }>
                              <div className={ classes.imageRight }>
                                <img src={ CrditScoreCh2 } alt="" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[5].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>6</span>
                      <p className={ classes.questionTitle }>
                        Which of the following is NOT a way to improve your credit score?
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[5].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Pay your bills on time
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Keep your credit card balance low and repay your balance in full every month
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Repay and cancel all of your credit cards and only pay with your debit card or cash
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Check your credit score and dispute any errors
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[5].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox, classes.ansBox3 ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Improving your credit score</Typography>
                              <p>There are many ways to improve your credit score:</p>
                              <ul className={ classes.arrowList }>
                                <li>Pay your regular monthly bills on time including rent, utilities, and phone service.</li>
                                <li>Keep your credit card balance low and repay your balance in full every month.</li>
                                <li>Repay your debts on time such as credit cards, student loans, car loans, and mortgages.</li>
                                <li>Apply for and open new credit accounts only when absolutely needed.</li>
                                <li>Check your credit score once a quarter and dispute any errors.</li>
                              </ul>
                              <p>
                                But, repaying and cancelling all of your credit cards and only paying with your debit card or cash will not improve your credit score.
                                Although closing your credit cards can be a smart money move if you are struggling to use them wisely,
                                closing your credit cards can actually hurt your credit score.
                              </p>
                              <p>
                                Want to see how a credit score is calculated? Click
                                {' '}
                                <a href="http://ficoscore.com/wp-content/uploads/Frequently-Asked-Questions-About-FICO-Scores.pdf" className={ classes.textBlueLink } rel="noopener noreferrer" target="_blank">here</a>
                                {' '}
                                {' '}
                                for a detailed breakdown and FAQs.
                              </p>
                            </Grid>

                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[6].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>7</span>
                      <p className={ classes.questionTitle }>
                        Student loan monthly payments are made up of two parts: ___________ and interest.
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[6].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Balance
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Capital
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Principal Repayment
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Amortization
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[6].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Principal Repayment and Interest (P&I)</Typography>
                              <p>
                                Student loan payments are made up of two parts:
                                <br />
                                <span className={ classes.spanColor }>principal repayment and interest</span>
                                {' '}
                                {' '}
                                (also known as &quot;P
                                &
                                I&quot;).
                              </p>
                              <p>
                                <span className={ classes.spanColor }>Principal</span>
                                {' '}
                                {' '}
                                is the total sum of money borrowed. A portion of each monthly payment goes toward interest and the rest is used to pay down your principal balance.
                              </p>
                              <p>
                                <span className={ classes.spanColor }>Amortization</span>
                                {' '}
                                {' '}
                                is the process of paying off your loans in regular payments over a period of time. Check out the example of a standard amortization table below.
                              </p>
                              <div className={ classes.flexConatinerNew }>
                                <div>
                                  <p><b><u>Assumptions</u></b></p>
                                  <p>
                                    Principal = $225,000
                                    <br />
                                    Term of the Loan (yrs) = 30
                                    <br />
                                    # of Months = 360
                                    <br />
                                    Annual Interest Rate = 4.00%
                                    <br />
                                    Monthly Payment = $1,074
                                  </p>
                                </div>
                                <div>
                                  <img src={ caluculationsChallenge } alt="" />
                                </div>
                              </div>
                            </Grid>

                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[7].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>8</span>
                      <p className={ classes.questionTitle }>
                        You take a $10,000 private student loan your first year of college. That loan has an annual interest rate of 8% and interest accrues on a monthly basis. In your first month of college, you don&apos;t make a payment. What happens next?
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[7].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              While you are in school, you don&apos;t have to make any payments
                              <input type="radio" disabled={ questions[7].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[7].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Your student loan balance increases by $67 because interest accrues every month you don&apos;t make a payment
                              <input type="radio" disabled={ questions[7].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[7].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Your student loan balance increases by $800 because interest accrues every month you don&apos;t make a payment
                              <input type="radio" disabled={ questions[7].answer === 'b' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[7].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              You will default on your loans because you missed a payment
                              <input type="radio" disabled={ questions[7].answer === 'b' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[7].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">How to calculate accrued interest</Typography>
                              <p>
                                <span className={ classes.spanColor }>$66.67 of accrued interest will be added to your balance, or capitalized, at the end of the month resulting in a new balance of $10,066.67</span>
                              </p>
                              <p>$10,000.00 x 8% / 12 = $66.67 of accrued interest</p>
                              <p>$10,000.00 + $66.67 of capitalized interest = $10,066.67 student loan balance next month</p>
                              <p>Remember, the 8% is an annual interest rate. Since interest accrues monthly, you have to divide that interest rate by 12 to calculate interest on a monthly basis.</p>
                              <p>
                                Private student loans start accruing interest the day you receive funds from your loan. If you forget to make a payment or can&apos;t make a payment, interest will accrue and be added to your balance, or capitalized, at the end of the month. This process repeats itself every month so be sure to stay on top of your payments!
                              </p>
                              <p>
                                <span className={ classes.spanColor }>Rule of Thumb – if you can afford to make minimum interest payments during school, do it. This can save you thousands of dollars in accrued interest.</span>
                              </p>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[8].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>9</span>
                      <p className={ classes.questionTitle }>
                        Which of the following is a good reason to get a credit card?
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[8].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Using a credit card will automatically increase my credit score
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              I need a credit card in case of financial emergencies
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              I need a credit card so that I can earn rewards on all of my purchases
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Credit cards offer more security than debit cards and cash
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[8].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox, classes.ansBox3 ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">A good reason to get a credit card is for security</Typography>
                              <p>Credit cards offer more security than debit cards and cash. For example, if your credit card is stolen and used to make purchases, you can call the credit card company and they will cancel those charges and freeze your card.</p>
                              <p>But, if your debit card is stolen and used, the cash is withdrawn from your account in 1-2 days and is much more difficult to get refunded. If cash is stolen, it&apos;s as good as gone!</p>
                              <p>These are not good reasons to get a credit card:</p>
                              <ul className={ classes.arrowList }>
                                <li>Improving your credit score: using a credit card is not guaranteed to increase your credit score.</li>
                                <li>For emergencies: setting up an emergency fund is a better money move. A good benchmark to hold in this emergency fund is three to six months of expenses.</li>
                                <li>For rewards: credit card companies offer rewards to entice you to open a new card. Why? Because they know that if you fall behind on your payments, fees and interest will be more profitable than the rewards they offer.</li>
                              </ul>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[9].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>10</span>
                      <p className={ classes.questionTitle }>
                        <p>
                          Click to open the
                          {' '}
                          {' '}
                          <Link href="/financial-calculators/extra-loan-payment" target="_blank" rel="noopener noreferrer" className={ classes.textBlueLink }>Extra Loan Payment Calculator</Link>
                          {' '}
                          {' '}
                          {' '}
                          and use the following details to calculate Mr. B&apos;s total interest paid on his mortgage.
                        </p>
                        <div className={ `${classes.queastionTable} ${classes.queastionTablespace}` }>
                          <table>
                            <thead>
                              <tr>
                                <th>Terms</th>
                                <th>Mortgage</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Loan Amount</td>
                                <td>$500,000.00</td>
                              </tr>
                              <tr>
                                <td>Interest Rate</td>
                                <td>4.00%</td>
                              </tr>
                              <tr>
                                <td>Term of the loan</td>
                                <td>30 years</td>
                              </tr>
                              <tr>
                                <td>Monthly Payment</td>
                                <td>$2,387.08</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p>What&apos;s Mr. B&apos;s total interest savings on his 30-yr mortgage if he makes an extra $300 payment? </p>
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[9].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $281,828.45
                              <input type="radio" disabled={ questions[9].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $359,347.53
                              <input type="radio" disabled={ questions[9].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $77,519.08
                              <input type="radio" disabled={ questions[9].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $3,600.00
                              <input type="radio" disabled={ questions[9].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[9].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Typography variant="h3">The Power of Making Extra Loan Payments</Typography>
                          <Grid container className={ classes.extraLoan }>
                            <Grid sm={ 10 }>
                              <div className={ classes.extraLoanLeft }>
                                <img className={ classes.extraLoanRightLeftArrow } src={ elanArrow } alt="" />
                                <img src={ extraLoan } alt="" />
                              </div>
                            </Grid>
                            <Grid sm={ 2 }>
                              <div className={ classes.extraLoanRight }>
                                <p>
                                  Mr. B can save over $77,500 on his mortgage by making an extra $300 payment each month.
                                  And, he repays his loan almost 6 years ahead of schedule!
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[10].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[10].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>11</span>
                      <p className={ classes.questionTitle }>
                        <p>
                          Click to open the
                          {' '}
                          {' '}
                          {' '}
                          <Link href="/financial-calculators/loan-repayment" target="_blank" rel="noopener noreferrer" className={ classes.textBlueLink }>Standard Loan Payment Calculator</Link>
                          {' '}
                          {' '}
                          {' '}
                          and use the details to calculate the loan repayment for each scenario below.
                        </p>
                        <ul className={ classes.listStyleNone }>
                          <li>1) Mr. B has a credit score of 800 and receives a $250,000 30-year mortgage with a 3.0% interest rate.</li>
                          <li>2) Mr. A has a credit score of 600 and receives a $250,000 30-year mortgage with a 5.0% interest rate.</li>
                        </ul>
                        <p>How much does Mr. B save on his loan when compared to Mr. A due to his higher credit score? Calculate each of the loan’s total interest paid.</p>
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[10].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $103,695.83
                              <input type="radio" disabled={ questions[10].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $129,443.63
                              <input type="radio" disabled={ questions[10].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $233,139.46
                              <input type="radio" disabled={ questions[10].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $483,139.46
                              <input type="radio" disabled={ questions[10].answer === 'a' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[10].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">A Higher Credit Score Saves You Money</Typography>
                              <div className={ classes.flexHigherCredit }>
                                <div className={ classes.flexHigherImage }>
                                  <img src={ abImage } alt="" />
                                </div>
                                <div>
                                  <p>A higher credit score saves you interest when you need to borrow money. Plus, if you have a lower credit score, you may not even be able to get a loan when you need one.</p>
                                  <p>Mr. B saves over $100,000 simply because he has a higher credit score than Mr. A. The best way to keep your credit score high is to make your loan payments on time. That includes paying your credit card bill in full every month!</p>
                                </div>
                              </div>

                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}

                  </div>

                  <div className={ classes.progressBarNew }>
                    <div className={ classes.progressBar } id="progress-bar">
                      <p>
                        {progress}
                        % complete
                      </p>
                      <div>
                        <span style={ { width: `${progress}%` } } />
                      </div>
                    </div>
                    <div className={ `${classes.progressBar} ${classes.progressBarRight}` } id="progress-bar">
                      <div>
                        <p>
                          <NumberFormat displayType="text" thousandSeparator value={ challengePoints } />
                          /1,100 XP earned
                        </p>
                        <div>
                          <span style={ { width: `${challengePercentage}%` } } />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={ classes.whatsMyScore } id="my-score-button">
                    {exerciseStatus === 'review' ? (
                      <Link href="/pf101/dashboard">BACK TO PF101 DASHBOARD</Link>
                    ) : (
                      <Button onClick={ handleMyScore }>Finish</Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Pf101Challenge2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Pf101Challenge2 );
