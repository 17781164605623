import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';

const Sense = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.senseBody }>
        <div className={ classes.senseBannerSec }>
          <div className={ classes.container }>
            <div className={ classes.bannerContent }>
              <Typography variant="h2" component="h2">Make Sense of Your World</Typography>
            </div>
          </div>
        </div>
        <div className={ classes.senseContentRow }>
          <div className={ classes.container }>
            <div className={ classes.senseContentTitle }>
              <Typography variant="h2" component="h2">Boost your awareness:</Typography>
              <Typography variant="h3" component="h3">Take the Inkwiry Awareness and Discovery Challenges.</Typography>
            </div>

            <Grid container spacing={ 2 } className={ classes.senseContent }>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.sensecontentLeft }>
                  <ul>
                    <li>
                      <Link className={ classes.avernessBtn } href="/awareness">
                        TAKE THE
                        {' '}
                        { ' ' }
                        <span>AWARENESS CHALLENGE</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 7 } md={ 7 }>
                <div className={ classes.sensecontentRight }>
                  <Typography variant="h3" component="h3">Test your awareness and benchmark your performance.</Typography>
                  <p>Before you make a single move, discover how investing 15 minutes in the Awareness Challenge can greatly improve your chances for success.</p>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } className={ classes.senseContent }>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.sensecontentLeft }>
                  <ul>
                    <li>
                      <Link className={ classes.discoverBtn } href="/discovery">
                        TAKE THE
                        { ' ' }
                        <span>DISCOVERY CHALLENGE</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 7 } md={ 7 }>
                <div className={ classes.sensecontentRight }>
                  <Typography variant="h3" component="h3">Test your financial knowledge.</Typography>
                  <p>How well do you know personal finance? Put your knowledge to the test in the Discovery Challenge.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Sense.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Sense );
