import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import styles from './styles';
import Pageloader from '../../components/ui/pageloader';
import AccountDetails from '../../components/my-account/account-details';
import BillingInfo from '../../components/my-account/billing-info';
import CollaborationSetting from '../../components/my-account/collaboration-setting';
import SecuritySetting from '../../components/my-account/security-setting';
import OtherSetting from '../../components/my-account/other-setting';

import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';

const MY_ACCOUNT = loader( '../../graphql/schema/auth/my-account.graphql' );
const MY_DETAILS = loader( '../../graphql/schema/auth/me.graphql' );
const SAVE_SETTINGS = loader( '../../graphql/schema/auth/save-settings.graphql' );
const LIST_OF_STATES = loader( '../../graphql/schema/auth/list-of-states.graphql' );

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MyAccount = ( props ) => {
  const { classes } = props;

  const [value, setValue] = useState( 0 );
  const [fileName, setFileName] = useState( null );
  const [imgData, setImgData] = useState( null );

  const { data: myDetails, loading } = useQuery( MY_DETAILS );

  const { data: listOfStates } = useQuery( LIST_OF_STATES );

  const [updateMyAccount] = useMutation( MY_ACCOUNT, {
    refetchQueries: [
      {
        query: MY_DETAILS,
        fetchPolicy: 'network-only',
      },
    ],
  } );

  const [updateSaveSettings] = useMutation( SAVE_SETTINGS, {
    refetchQueries: [
      {
        query: MY_DETAILS,
        fetchPolicy: 'network-only',
      },
    ],
  } );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  const updateProfileImage = ( e ) => {
    if ( e.target.files[0] ) {
      setFileName( e.target.files[0] );
      const reader = new FileReader();
      reader.addEventListener( 'load', () => {
        setImgData( reader.result );
        updateMyAccount( {
          variables: {
            data: {
              type: 'profile_pic',
              image: reader.result,
            },
          },
        } );
      } );
      reader.readAsDataURL( e.target.files[0] );
    }
  };

  const updateSaveSettingsData = ( name, fieldValue ) => {
    updateSaveSettings( {
      variables: {
        data: {
          setting_type: 'collaboration_setting',
          field_name: name,
          field_value: fieldValue === true ? '1' : '0',
        },
      },
    } );
  };

  const updateOtherSaveSettingsData = ( name, fieldValue ) => {
    updateSaveSettings( {
      variables: {
        data: {
          setting_type: 'other_setting',
          field_name: name,
          field_value: fieldValue === true ? '1' : '0',
        },
      },
    } );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.MyAccount }>
        <Container className={ classes.container }>
          <div className={ classes.rootOne }>
            <Grid container>
              <Grid sm={ 3 } xs={ 12 } className={ classes.myAccountLeft }>
                <div className={ classes.sticky }>
                  <Typography variant="h3">My Account</Typography>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={ value }
                    onChange={ handleChange }
                    aria-label="Vertical tabs example"
                    className={ classes.tabs }
                    indicatorColor="primary"
                  >

                    <Tab className={ value === 0 ? classes.activeTab : '' } label="Account Details" { ...a11yProps( 0, classes ) } />
                    <Tab className={ value === 1 ? classes.activeTab : '' } label="Billing Information" { ...a11yProps( 1, classes ) } />
                    <Tab className={ value === 2 ? classes.activeTab : '' } label="Security Settings" { ...a11yProps( 2 ) } />
                    <Tab className={ value === 3 ? classes.activeTab : '' } label="Collaboration Settings" { ...a11yProps( 3 ) } />
                    <Tab className={ value === 4 ? classes.activeTab : '' } label="Other Settings" { ...a11yProps( 4 ) } />

                  </Tabs>
                </div>
              </Grid>
              <Grid sm={ 9 } xs={ 12 }>
                <TabPanel value={ value } index={ 0 } className={ classes.tabPanelContent }>
                  <AccountDetails
                    updateProfileImage={ updateProfileImage }
                    fileName={ fileName }
                    imgData={ imgData }
                    updateMyAccount={ updateMyAccount }
                    myDetails={ myDetails }
                    listOfStates={ ( listOfStates && listOfStates.States.length > 0 ) ? listOfStates.States : [] }
                  />
                </TabPanel>
                <TabPanel value={ value } index={ 1 } className={ classes.billingContentNew }>
                  <BillingInfo />
                </TabPanel>
                <TabPanel value={ value } index={ 2 } className={ classNames( classes.billingContentNew, classes.billingContentNewHead ) }>
                  <SecuritySetting
                    myDetails={ myDetails && myDetails }
                    updateSaveSettings={ updateSaveSettings }
                  />
                </TabPanel>
                <TabPanel value={ value } index={ 3 } className={ classNames( classes.billingContentNew, classes.billingContentNewHead ) }>
                  <CollaborationSetting
                    myDetails={ myDetails }
                    updateSaveSettingsData={ updateSaveSettingsData }
                  />

                </TabPanel>
                <TabPanel value={ value } index={ 4 } className={ classNames( classes.billingContentNew, classes.billingContentNewHead ) }>
                  <OtherSetting
                    myDetails={ myDetails }
                    updateOtherSaveSettingsData={ updateOtherSaveSettingsData }
                  />

                </TabPanel>

              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

MyAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( MyAccount );
