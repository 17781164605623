import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import studentBanner from '../../assets/img/students-parents/students_banner.jpg';
import whereStartImg from '../../assets/img/students-parents/where_start_img.jpg';

const Student = (props) => {
  const { classes, history } = props;

  const [age, setAge] = React.useState('');
  const [studentReadMore, setStudentReadMore] = useState(true);
  const [parentsReadMore, setParentsReadMore] = useState(true);
  const [sectionclass1, setSectionclass1] = useState(false);
  const [sectionclass2, setSectionclass2] = useState(false);
  const [sectionclass3, setSectionclass3] = useState(false);
  const [sectionclass4, setSectionclass4] = useState(false);
  const [sectionclass5, setSectionclass5] = useState(false);
  const [sectionclass6, setSectionclass6] = useState(false);
  const [bottomReadMore, setBottomReadMore] = useState(false);
  const [leftMenuClass, setLeftmenuClass] = useState(false);
  const joinInk = bottomReadMore ? `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen} ${classes.studentBottomFixed}` : `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen}`;
  const ReadMoreCondition = parentsReadMore ? joinInk : classes.studentsPageReadmoreOpen;
  const studentRef = useRef(null);
  const [dropdown, setDropDown] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value === 10) {
        scroller.scrollTo('section1', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -45,
        });
    }
    if (event.target.value === 20) {
        scroller.scrollTo('section2', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -115,
        });
    }
    if (event.target.value === 30) {
        scroller.scrollTo('section3', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -40,
        });
    }
    if (event.target.value === 40) {
        scroller.scrollTo('section4', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -90,
        });
    }
    if (event.target.value === 50) {
        scroller.scrollTo('section5', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -45,
        });
    }
    if (event.target.value === 60) {
        scroller.scrollTo('section6', {
          duration: 600,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
    }
  };

  useScrollPosition(({ currPos }) => {
    if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top > 54) {
      setParentsReadMore(false);
      setSectionclass1(false);
    }
    if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < 54 && studentRef.current.getBoundingClientRect().top > -4780) {
      setParentsReadMore(true);
    }
    setLeftmenuClass(false);
    if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < 54 && studentRef.current.getBoundingClientRect().top > -232) {
      setSectionclass2(false);
      setSectionclass1(true);
      setLeftmenuClass(false);
    } else if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < -432 && studentRef.current.getBoundingClientRect().top > -829) {
      setSectionclass1(false);
      setSectionclass2(true);
      setSectionclass3(false);
      setLeftmenuClass(false);
    } else if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < -1029 && studentRef.current.getBoundingClientRect().top > -1258) {
      setSectionclass2(false);
      setSectionclass3(true);
      setSectionclass4(false);
      setLeftmenuClass(false);
    } else if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < -1458 && studentRef.current.getBoundingClientRect().top > -2541) {
      setSectionclass3(false);
      setSectionclass4(true);
      setSectionclass5(false);
      setLeftmenuClass(false);
    } else if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < -2741 && studentRef.current.getBoundingClientRect().top > -3741) {
      setSectionclass4(false);
      setSectionclass5(true);
      setSectionclass6(false);
      if (studentRef.current.getBoundingClientRect().width < 1000) {
        setLeftmenuClass(true);
      } else {
        setLeftmenuClass(false);
      }
    } else if (studentRef.current.getBoundingClientRect().top !== 0 && studentRef.current.getBoundingClientRect().top < -3980 && studentRef.current.getBoundingClientRect().top > -4780) {
      setSectionclass1(false);
      setSectionclass2(false);
      setSectionclass3(false);
      setSectionclass4(false);
      setSectionclass5(false);
      setSectionclass6(true);
      if (studentRef.current.getBoundingClientRect().width < 1000) {
        setLeftmenuClass(true);
      } else {
        setLeftmenuClass(false);
      }
    }

    if ( currPos.y > -1310 ) {
      setDropDown( false );
    }
    if ( currPos.y < -1310 ) {
      setDropDown( true );
    }

    if (currPos.y > -1500) {
      setParentsReadMore(true);
    }
    if (currPos.y < -6000 && currPos.y > -7000) {
      setBottomReadMore(true);
    } else {
      setBottomReadMore(false);
    }
  });

  const scrollToReadMore = () => {
    setTimeout(() => {
      scroller.scrollTo('scroll-to-element', {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -57,
      });
    }, 700);
    setParentsReadMore(true);
  };

  const handleReadMore = () => {
    setStudentReadMore(false);
    scrollToReadMore();
  };

  const redirectToHHigherEducation = () => {
    history.push('/plan-with-inkwiry/he');
  };

  const redirectToPricing = () => {
    history.push('/pricing');
  };

  return (
    <Typography variant="body1" component="div">
      <div className={classes.studentPage}>
        <div className={classes.parentstBanner}>
          <img src={studentBanner} alt="Students Banner" />
          <div className={classes.container}>
            <div className={classes.bannerCaption}>
              <div className={classes.bannerCaptionTitle}>
                <Typography variant="h3" component="h3">Inkwiry for Students</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.parentstContent}>
          <div className={classes.container}>
            <div className={classes.parentstContentRow}>
              <Typography variant="h3" component="h3">Seriously, what will your future look like?</Typography>
              <Typography variant="h4" component="h4">You have time to prepare, but there&apos;s no time to lose.</Typography>
              <p>If you&apos;re in high school or college, you may believe it&apos;s difficult to know – or even imagine – where you might be in 5, 10, 15 years.</p>
              <p>You could be thinking: “But I don&apos;t even know what school to attend. Or if I can afford it. Or what I want to do with my life.”</p>
              <p>
                Yet, at this critical stage, it&apos;s by far the
                {' '}
                <span className={classes.italic}>most important time</span>
                {' '}
                for you to work on designing a vision and building out your strategy for the future. The earlier you start, the more efficiently you can move forward, and the more you can avoid wasting money and time going in the wrong direction or falling into traps that steal your dreams.
              </p>
              <p>Are you ready to take control of your future? Inkwiry will help you do the personal and financial planning you never thought possible, and do it quickly, effectively and inexpensively.</p>
              <p>Jump right in and test drive our Higher Education module. Read and experience why planning with Inkwiry can help you live the life you want.</p>
              <div className={classes.parantMoreInfo}>
                <Button onClick={redirectToHHigherEducation} className={classes.testDriveBtn}>
                  <span name="testDrive">Take a Test Drive</span>
                  <i className="las la-greater-than" />
                </Button>
                <Button className={classes.moreInfoBtn} onClick={handleReadMore}>
                  Read More
                  <i className="las la-greater-than" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={!studentReadMore ? ReadMoreCondition : classes.studentsPageReadmore} name="scroll-to-element">
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <div className={leftMenuClass ? `${classes.readmoreLftCnt} ${classes.studentleftMenuClass}` : classes.readmoreLftCnt}>
                <Typography variant="h4" component="h4">Inkwiry for Students</Typography>
                <ul className={classes.inkwiryListTab}>
                  <li>
                    <Link
                      to="section1"
                      spy
                      delay={0}
                      smooth
                      duration={1000}
                      offset={-55}
                      className={sectionclass1 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>We get it. This time is different.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section2"
                      spy
                      delay={0}
                      smooth
                      offset={-128}
                      duration={1000}
                      className={sectionclass2 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>Inkwiry is revolutionary financial planning software.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section3"
                      spy
                      delay={0}
                      smooth
                      duration={1000}
                      offset={-55}
                      className={sectionclass3 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>The universal question.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section4"
                      spy
                      delay={0}
                      smooth
                      offset={-100}
                      duration={1000}
                      className={sectionclass4 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>But where do you start?</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section5"
                      spy
                      delay={0}
                      smooth
                      offset={-55}
                      duration={1000}
                      className={sectionclass5 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>Find your solutions.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section6"
                      spy
                      delay={0}
                      smooth
                      offset={-55}
                      duration={1000}
                      className={sectionclass6 ? classes.activeClass : classes.unActiveClass}
                    >
                      <span />
                      <p>Don&apos;t let life pass you by.</p>
                    </Link>
                  </li>
                </ul>
                <div className={ dropdown === true ? `${classes.selectDiv} ${classes.selectDivFixed}`:`${classes.selectDiv}` }>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty  
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem className={classes.listSelect} value={10}>We get it. This time is different.</MenuItem>
                      <MenuItem className={classes.listSelect} value={20}>Inkwiry is revolutionary financial planning software.</MenuItem>
                      <MenuItem className={classes.listSelect} value={30}>The universal question.</MenuItem>
                      <MenuItem className={classes.listSelect} value={40}>But where do you start?</MenuItem>
                      <MenuItem className={classes.listSelect} value={50}>Find your solutions.</MenuItem>
                      <MenuItem className={classes.listSelect} value={60}>Don&apos;t let life pass you by.</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className={classes.readmoreRightCnt} ref={studentRef}>
                <div className={classes.readmoreRightCntSec} id="section1">
                  <div className={classes.whereStartCnt}>
                    <Typography variant="h4" component="h4">We get it. This time is different.</Typography>
                    <p>You have every right to be worried about your future.</p>
                    <p>Today, there is unprecedented uncertainty as technology revolutionizes the workforce and globalization squeezes the job market. You need to find a career where you won&apos;t be replaced by a robot or by someone who will work for less. You need to strive to fulfill your dreams while earning enough money so you can thrive in the new economy and become financially independent.</p>
                    <p>To get there, you need a strategy and a plan. That&apos;s where Inkwiry comes in.</p>
                  </div>
                </div>
                <div className={classes.readmoreRightCntSec}>
                  <div className={`${classes.whereStartCnt} ${classes.titleFontsize}`} id="section2">
                    <Typography variant="h4" component="h4">Inkwiry is revolutionary financial planning software for students.</Typography>
                    <p>Plan your higher education, your career path and other life events using Inkwiry&apos;s intelligent software that builds your awareness, your skills and knowledge, and crushes the numbers to help you find your own best solutions.</p>
                    <p>Working through just two of the 15 modules – Higher Education and Career Path – can answer major questions about sources of funds, expenses to consider and the payback to expect from a variety of higher education choices.</p>
                    <p>And it&apos;s true that just writing down your goals makes it 42% more likely you will achieve them. Successful people everywhere have one thing in common: a strategy and a plan that works for them. Inkwiry can help you achieve more in your life.</p>
                  </div>
                </div>
                <div className={classes.testimonialSec} id="section3">
                  <Typography variant="h4" component="h4">The universal question:</Typography>
                  <Typography variant="h5" component="h5">
                    <span />
                    <p>How can I live my dream to do something I&apos;m passionate about that rewards me financially and personally and gives me the freedom to do everything else I want to do?”</p>
                  </Typography>
                </div>
                <div className={classes.readmoreRightCntSec}>
                  <div className={`${classes.whereStartCnt} ${classes.titleFontsize}`} id="section4">
                    <Typography variant="h4" component="h4">But where do you start?</Typography>
                    <p>Inkwiry offers advanced tools to make planning easy.</p>
                    <p>Begin by raising your awareness and discovering more about yourself so you can set and start working toward your goals. Inkwiry Sense helps you test your awareness and see what you need to consider as you build your plan. Take the free Awareness and Discovery Challenges to see how you measure up.</p>
                    <p>Then, dig into Inkwiry&apos;s free Career Sketch Stories to explore career sketches filled with valuable insights and powerful, actionable strategies you can apply to your own life. Load sketches that appeal to you in the Financial Configurator and learn how to build your own. You might find some eye-opening solutions or new ways to approach higher education and your career path.</p>
                    <p>Inkwiry does all the work for you. You just type in your information, aligned with your goals, to reveal as many different scenarios as you desire. Then compare and contrast to find your best options.</p>
                    <p>The cool thing is your plan is dynamic and can change as your circumstances evolve. Wondering if you&apos;d prefer a trade school education instead of a four-year degree? Did you receive a generous scholarship? Moving off campus? Just plug in new data and Inkwiry goes to work.</p>
                  </div>
                </div>
                <div className={classes.imgBanner}>
                  <img src={whereStartImg} alt="" />
                </div>
                <div className={classes.findSolutions} id="section5">
                  <Typography variant="h3" component="h3">Find your solutions.</Typography>
                  <Typography variant="h4" component="h4">Inkwiry can help you to:</Typography>
                  <ul>
                    <li>
                      Use Career Sketching
                      <span>TM</span>
                      {' '}
                      to plan your higher education and career.
                    </li>
                    <li>Compare various paths for higher education; if one college is too expensive, switch to another and Inkwiry instantly displays new results.</li>
                    <li>Evaluate possible sources of scholarships/grants/loans to pay for higher education.</li>
                    <li>Know exactly what expenses to anticipate during college and beyond.</li>
                    <li>See how completing your college degree in five years instead of four piles on surprising additional costs.</li>
                    <li>Estimate how long it might take to pay back your higher education investment.</li>
                    <li>Investigate career paths to make sure your chosen profession will provide enough money to pay your expenses, plus some.</li>
                    <li>Figure out if you&apos;ll need more skills and knowledge to truly master your career.</li>
                    <li>Develop a plan for your next 5, 10, 15 years.</li>
                  </ul>
                </div>
                <div className={classes.testimonialSecDark}>
                  <Typography variant="h5" component="h5">
                    <span />
                    <p>Inkwiry enabled me to confidently navigate getting my master&apos;s in engineering. That smart move led to a 55% increase in salary and unlocked a lifetime of potential.”</p>
                  </Typography>
                  <Typography variant="h6" component="h6">– Matt P., Master&apos;s in Civil Engineering Student, Temple University</Typography>
                </div>
                <div className={classes.readmoreRightCntSec} id="section6">
                  <div className={`${classes.whereStartCnt} ${classes.titleFontsize}`}>
                    <Typography variant="h4" component="h4">Don&apos;t let life pass you by.</Typography>
                    <p>Are you ready to take control of your future? Inkwiry will help you do the personal and financial planning you never thought possible, and do it quickly, effectively and inexpensively.</p>
                    <p>Just 20 minutes in Inkwiry can reveal eye-opening insights and solutions, leaving you hungry for more. Run scenarios and compare options. See exactly how your ideas and aspirations add up.</p>
                    <p>Design your dynamic plan for the next 5, 10, 15 years and make your next move with confidence. Over the long run, your $9 investment in Inkwiry can save you a great deal of money, time and uncertainty.</p>
                    <p>All in all, the power of knowing your best path and building a solid strategy can reward you with financial independence, confidence and peace of mind.</p>
                    <Typography variant="h6" component="h6">Now it&apos;s your turn. You&apos;re ready. Sign up today and join Inkwiry.</Typography>
                  </div>
                </div>
                <div className={classes.joinBtn}>
                  <Button onClick={redirectToPricing}>
                    JOIN INKWIRY
                    <i className="las la-greater-than" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
      <ScrollToTop />
    </Typography>
  );
};

Student.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Student);
