function shortTermTable(module1Data, module9Data, investmentsData, cashFlowData) {
  let shortTermData = {};
  let year = 0;
  let yearLimit = 0;

  const d = new Date();
  const currentYear = d.getFullYear();

  year = parseInt(module9Data.short_term_start_year !== undefined  && module9Data.short_term_start_year !== null ?  module9Data.short_term_start_year: currentYear );
  yearLimit = parseInt(module1Data.start_year) + 15;
  let shortTermLoopData = {};
  // Purchase Price
  shortTermData = {};
  let maxCashBalance = 0;
  let beginningShortBalance = 0;
  let maxShortTermInvestmentBalance = 0;
  let shortAnnualContribution = 0;
  let shortAnnualContributionGrowth = 0;
  let shortAnnualExpectedReturn = 0;
  //Beginning Balance
  if (typeof module9Data.short_term_begining_balance !== "undefined" && module9Data.short_term_begining_balance !== "" && module9Data.short_term_begining_balance > 0) {
    beginningShortBalance = module9Data.short_term_begining_balance;
  } else {
    beginningShortBalance = 0;
  }
  shortTermData["Beginning Balance"] = beginningShortBalance;

  //Max Cash Balance
//   if ( typeof module1Data.max_cash_balance !== "undefined" && module1Data.max_cash_balance !== "" && module1Data.max_cash_balance > 0) {
//     maxCashBalance = module1Data.max_cash_balance;
//   } else {
//     maxCashBalance = 0;
//   }
  shortTermData["Max Cash Balance"] = maxCashBalance;

  //Max Short-Term Investments Balance

  if (typeof module9Data.short_term_max_sti_balance_before_lt_investment !== "undefined" && module9Data.short_term_max_sti_balance_before_lt_investment !== "" && module9Data.short_term_max_sti_balance_before_lt_investment > 0
  ) {
    maxShortTermInvestmentBalance = module9Data.short_term_max_sti_balance_before_lt_investment;
  } else {
    maxShortTermInvestmentBalance = 0;
  }
  shortTermData["Max Short-Term Investments Balance"] = maxShortTermInvestmentBalance;

  //Max Short-Term Investments Balance
  if ( typeof module9Data.short_term_annual_contribution !== "undefined" && module9Data.short_term_annual_contribution !== "" && module9Data.short_term_annual_contribution > 0) {
    shortAnnualContribution = module9Data.short_term_annual_contribution;
  } else {
    shortAnnualContribution = 0;
  }
  shortTermData["Annual Contribution"] = shortAnnualContribution;

  //Annual Contribution growth
  if (typeof module9Data.short_term_annual_contribution_growth_in_percentage !== "undefined" && module9Data.short_term_annual_contribution_growth_in_percentage !== "" && module9Data.short_term_annual_contribution_growth_in_percentage > 0) {
    shortAnnualContributionGrowth = module9Data.short_term_annual_contribution_growth_in_percentage;
  } else {
    shortAnnualContributionGrowth = 0;
  }
  shortTermData["Annual Contribution Growth"] = shortAnnualContributionGrowth;

  //Annual Expected Pre-Tax Return
  if (typeof module9Data.short_term_annual_expected_return_in_percentage !== "undefined" && module9Data.short_term_annual_expected_return_in_percentage !== "" && module9Data.short_term_annual_expected_return_in_percentage > 0) {
    shortAnnualExpectedReturn = module9Data.short_term_annual_expected_return_in_percentage;
  } else {
    shortAnnualExpectedReturn = 0;
  }
  shortTermData["Annual Expected Pre-Tax Return"] = shortAnnualExpectedReturn;

  //Start Investment Summary
  let shortBeginningBalance = 0;
    shortAnnualContribution = 0;
  let shortEarnings = 0;
  let shortTransferToCash = 0;
  let shortContributionFromExcess = 0;
  let shortBalanceBeforeTransfer = 0;
  let shortMaxShortTermInvestmentsBalance = 0;
  let shortTransferToLongTermInvestments = 0;
  let shortEndingBalance = 0;
  let shortEndingCashBalanceBeforeTransfer = 0;
  let shortCashNeed = 0;
  let shortCashInjectionFromShortTerm = 0;
  let shortCashInjectionFromLongTerm = 0;
  let shortEndingCashBalanceAfterTransfer = 0;
  let shortEndingCashBalance = 0;
  let yearsList = [];

    for (year; year < yearLimit; year++) {
        shortTermLoopData[year] = {};
        shortTermLoopData[year]['Short-Term Investments Summary'] = {};
        yearsList.push(year);
        //Beginning Balance
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Beg Balance'] !== 'undefined'){
            shortBeginningBalance = investmentsData['Short-Term Investments'][year]['Beg Balance'];
        } else{
            shortBeginningBalance = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Beginning Balance'] = shortBeginningBalance;
        
        //Annual Contribution
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Cash Contibution'] !== 'undefined'){
            shortAnnualContribution = investmentsData['Short-Term Investments'][year]['Cash Contibution'];
        } else{
            shortAnnualContribution = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Annual Contribution'] = shortAnnualContribution;
        
        //Short Earnings
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Earnings'] !== 'undefined'){
            shortEarnings = investmentsData['Short-Term Investments'][year]['Earnings'];
        } else{
            shortEarnings = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Earnings'] = shortEarnings;
        
        //Short Transfer to Cash Account for Cash Need (see below)
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Cash Transfer'] !== 'undefined'){
            shortTransferToCash = -investmentsData['Short-Term Investments'][year]['Cash Transfer'];
        } else{
            shortTransferToCash = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Transfer to Cash Account for Cash Need (see below)'] = shortTransferToCash;
        
        //Short Contributions from Excess Cash (see below)
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Contribution'] !== 'undefined'){
            shortContributionFromExcess = investmentsData['Short-Term Investments'][year]['Contribution'];
        } else{
            shortContributionFromExcess = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'] = shortContributionFromExcess;
        
        //Balance before Transfer to Long-Term Investments
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Balance4'] !== 'undefined'){
            shortBalanceBeforeTransfer = investmentsData['Short-Term Investments'][year]['Balance4'];
        } else{
            shortBalanceBeforeTransfer = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Balance before Transfer to Long-Term Investments'] = shortBalanceBeforeTransfer;
        
        if( year !== '' ){
            shortMaxShortTermInvestmentsBalance = shortTermData['Max Short-Term Investments Balance'];
        } 
        shortTermLoopData[year]['Short-Term Investments Summary']['Max Short-Term Investments Balance'] = shortMaxShortTermInvestmentsBalance;
        
        //Transfer to Long-Term Investments
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Excess'] !== 'undefined'){
            shortTransferToLongTermInvestments = -investmentsData['Short-Term Investments'][year]['Excess'];
        } else{
            shortTransferToLongTermInvestments = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Transfer to Long-Term Investments'] = shortTransferToLongTermInvestments;
        
        //Ending Balance
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Ending Balance'] !== 'undefined'){
            shortEndingBalance = investmentsData['Short-Term Investments'][year]['Ending Balance'];
        } else{
            shortEndingBalance = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Ending Balance'] = shortEndingBalance;
        
        //Ending Cash Balance before Transfers
        if( typeof cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && cashFlowData[year] !== '' && typeof cashFlowData[year]['Ending Cash Surplus'] !== 'undefined'){
            shortEndingCashBalanceBeforeTransfer = cashFlowData[year]['Ending Cash Surplus'];
        } else{
            shortEndingCashBalanceBeforeTransfer = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance before Transfers'] = shortEndingCashBalanceBeforeTransfer;
        
        //Cash Need
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Cash Need'] !== 'undefined'){
            shortCashNeed = investmentsData['Short-Term Investments'][year]['Cash Need'];
        } else{
            shortCashNeed = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Cash Need'] = shortCashNeed;
        
        //Cash Injection from Short-Term Investments
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Short-Term Investments'][year] !== 'undefined' && investmentsData['Short-Term Investments'][year] !== '' && typeof investmentsData['Short-Term Investments'][year]['Cash Transfer'] !== 'undefined'){
            shortCashInjectionFromShortTerm = investmentsData['Short-Term Investments'][year]['Cash Transfer'];
        } else{
            shortCashInjectionFromShortTerm = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Cash Injection from Short-Term Investments'] = shortCashInjectionFromShortTerm;
        
        //Cash Injection from Long-Term Investments
        if( typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Long-Term Investments'][year] !== 'undefined' && investmentsData['Long-Term Investments'][year] !== '' && typeof investmentsData['Long-Term Investments'][year]['Cash Transfer'] !== 'undefined'){
            shortCashInjectionFromLongTerm = investmentsData['Long-Term Investments'][year]['Cash Transfer'];
        } else{
            shortCashInjectionFromLongTerm = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Cash Injection from Long-Term Investments'] = shortCashInjectionFromLongTerm;
        
        //Ending Cash Balance after Transfers
        if( typeof cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && cashFlowData[year] !== '' && typeof cashFlowData[year]['Ending Cash Balance after Transfers'] !== 'undefined'){
            shortEndingCashBalanceAfterTransfer = cashFlowData[year]['Ending Cash Balance after Transfers'];
        } else{
            shortEndingCashBalanceAfterTransfer = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance after Transfers'] = shortEndingCashBalanceAfterTransfer;
        
        //Max Cash Balance
        shortTermLoopData[year]['Short-Term Investments Summary']['Max Cash Balance'] = shortTermData['Max Cash Balance'];
        
        //Excess Cash Put into Short-Term Investments
        shortTermLoopData[year]['Short-Term Investments Summary']['Excess Cash Put into Short-Term Investments'] = -shortTermLoopData[year]['Short-Term Investments Summary']['Contributions from Excess Cash (see below)'];
        
        //Ending Cash Balance
        if( typeof cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && cashFlowData[year] !== '' && typeof cashFlowData[year]['Ending Cash Balance'] !== 'undefined'){
            shortEndingCashBalance = cashFlowData[year]['Ending Cash Balance'];
        } else{
            shortEndingCashBalance = 0;	
        }
        shortTermLoopData[year]['Short-Term Investments Summary']['Ending Cash Balance'] = shortEndingCashBalance;        
    }
    let investmentsTable = {};
    investmentsTable['Short Term Table']  = shortTermData;
    investmentsTable['Short Term Table Loop Data']  = shortTermLoopData;
    investmentsTable['Years List']  = yearsList;
    return investmentsTable;
}
export default shortTermTable;
