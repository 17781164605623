import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import { converyIntoThousandsOneDecimal, monthsFormatter, percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import * as moduleServices from '../../../calculations/modules-services';
import WeightedAverageInterestRatePopup from '../weighted-average-interest-rate';

const followersMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 10000,
    label: '$10k',
  },
  {
    value: 20000,
    label: '$20k',
  },
  {
    value: 30000,
    label: '$30k',
  },
  {
    value: 40000,
    label: '$40k',
  },
  {
    value: 50000,
    label: '$50k',
  },
  {
    value: 60000,
    label: '$60k',
  },
];

const monthMarks = [
  {
    value: 0,
    label: '0 mos',
  },
  {
    value: 40,
    label: '40 mos',
  },
  {
    value: 80,
    label: '80 mos',
  },
  {
    value: 120,
    label: '120 mos',
  },
  {
    value: 160,
    label: '160 mos',
  },
  {
    value: 200,
    label: '200 mos',
  },
  {
    value: 240,
    label: '240 mos',
  },
];

const interestMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 15,
    label: '15%',
  },
];


const SpouseStudentLoanDebt = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;

  const [popup, setPopup] = useState( false );
  const [studentLoanBalanceSlider, setStudentLoanBalanceSlider] = useState( 0 );
  const [monthSlider, setMonthSlider] = useState( 0 );
  const [interestRateInput, setInterestRateInput] = useState( 0 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(()=>{
    if(inputData.spouse_student_loan !== undefined && inputData.spouse_student_loan !== null && inputData.spouse_student_loan !== '') {
      setStudentLoanBalanceSlider(inputData.spouse_student_loan)
    }
    if(inputData.spouse_ideal_repayment_schedule !== undefined && inputData.spouse_ideal_repayment_schedule !== null && inputData.spouse_ideal_repayment_schedule !== '') {
      setMonthSlider(inputData.spouse_ideal_repayment_schedule)
    }
    if(inputData.spouses_interest_rate !== undefined && inputData.spouses_interest_rate !== null && inputData.spouses_interest_rate !== '') {
      setInterestRateInput(inputData.spouses_interest_rate)
    }
  },[]);


  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputData};
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;

    switch(field) {
      case 'spouse_student_loan': {
        setStudentLoanBalanceSlider(newvalue);
        break;
      }
      case 'spouse_ideal_repayment_schedule': {
        if(newvalue > 240) {
          newvalue = 240;
        }
        setMonthSlider(newvalue);
        break;
      }
      case 'spouses_interest_rate': {
        if(newvalue > 100) {
          newvalue = 100;
        }
          setInterestRateInput(newvalue);
        break;
      }
      default:
        break;
    }
    updateData( 'total_obj' ,inputValues );
  };

  const handlePopupOpen = () => {
    setPopup( true );
  };

  const handlePopupClose = (type, inputValue, calcutatedValues) => {
    setPopup( false );
    if(type === 'ok') {
      let inputDetails = {...inputData};
      inputDetails['weighted_loan_data'] = inputValue;
      inputDetails['spouse_student_loan'] = calcutatedValues['balance'];
      inputDetails['spouses_interest_rate'] = calcutatedValues['weight_total_interest'];
      setStudentLoanBalanceSlider(inputDetails['spouse_student_loan']);
      setInterestRateInput(inputDetails['spouses_interest_rate']);
      updateData( 'total_obj' ,inputDetails );
    }
  };

  const totalYear = ( parseInt( monthSlider, 10 ) / 12 ).toFixed( 2 );
  
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>When you get married, your spouse could have student loans to repay. Let&apos;s account for them in the budget. If your spouse will have no loans, just hit next to continue.</p>
      <p>Enter below an estimate of your spouse&apos;s student loan balance in your marriage year and make some assumptions about repayment.</p>
      <p>
        If your spouse will have more than one student loan with different interest rates,
        {' '}
        <span className={ classes.blueTextNew } aria-hidden="true" onClick={ handlePopupOpen }> click here</span>
        {' '}
        so that we can calculate your &nbsp;
        <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Weighted Average Interest Rate' ) }>weighted average interest rate.</span>
      </p>
      <div className={ classes.weightedAverage }>
        <div className={ classes.expensesGroup }>
          <div className={ classes.leftGroup }>
            <div className={ classes.intrestGroup }>
              <div>
                <p>Student Loan Balance When Married</p>
                <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                  <PwiSlider
                    value={ studentLoanBalanceSlider }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ converyIntoThousandsOneDecimal }
                    min={ 0 }
                    max={ 60000 }
                    step={ 100 }
                    marks={ followersMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ (e, value)=>updateValue( e, value, 'spouse_student_loan', 'slider' ) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ classes.inputRightBlock }>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ studentLoanBalanceSlider } onChange={ ( e ) => updateValue( e, '', 'spouse_student_loan', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.expensesGroup }>
          <div className={ classes.leftGroup }>
            <div className={ classes.intrestGroup }>
              <div>
                <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Ideal Repayment Schedule (in Months)' ) }>Ideal Repayment Schedule (in Months)</span></p>
                <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                  <PwiSlider
                    value={ monthSlider }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ monthsFormatter }
                    min={ 0 }
                    max={ 240 }
                    step={ 1 }
                    marks={ monthMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ (e, value)=>updateValue( e, value, 'spouse_ideal_repayment_schedule', 'slider' ) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.rightGroup }>
            <div className={ classes.intrestInput }>
              <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ monthSlider } onChange={ ( e ) => updateValue( e, '', 'spouse_ideal_repayment_schedule', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              <span className={ `${classes.monthsPosition} ${classes.monthPositionDebt}` }>Months</span>
            </div>
            <div className={ `${classes.intrestInput} ${classes.inputRightBoxNew} ${classes.inputRightBoxNewDebt}` }>
              <div>
                <NumberFormat thousandSeparator displayType="text" value={ totalYear } />
                <span>
                  {' '}
                  years
                </span>
              </div>
            </div>

          </div>
        </div>
        <div className={ classes.expensesGroup }>
          <div className={ classes.leftGroup }>
            <div className={ classes.intrestGroup }>
              <div>
                <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
                <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                  <PwiSlider
                    value={ interestRateInput }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    min={ 0 }
                    max={ 15 }
                    step={ 1 }
                    marks={ interestMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ (e, value)=>updateValue( e, value, 'spouses_interest_rate', 'slider' ) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ classes.intrestInput }>
                <NumberFormat
                  customInput={ TextField }
                  decimalScale={ isFocus!=='spouses_interest_rate'?2:5 }
                  fixedDecimalScale={isFocus!=='spouses_interest_rate'}
                  allowNegative={ false }
                  thousandSeparator
                  value={ interestRateInput }
                  onChange={ ( e ) => updateValue( e, '', 'spouses_interest_rate', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('spouses_interest_rate') }}
                  onBlur={(e) => { setInterestRateInput(parseFloat(e.target.value).toFixed(2)); }} />
                  %
              </div>
            </div>
          </div>
        </div>
        <div className={ `${classes.expensesGroup} ${classes.topGapDebt}` }>
          <div className={ classes.leftGroup }>
            <div className={ classes.intrestGroup }>
              <div>
                <div className={ classes.otherExpence }>
                  <h3>Monthly Payment (Principal & Interest)</h3>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ `${classes.expenseText} ${classes.boldText}` }>
                {moduleServices.module10Data.spouseMonthlyPaymentInterest !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={ moduleServices.module10Data.spouseMonthlyPaymentInterest } prefix={ moduleServices.module10Data.spouseMonthlyPaymentInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module10Data.spouseMonthlyPaymentInterest < 0 && ')' } />
                ) : ( '<span>$0</span>' )}
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.expensesGroup }>
          <div className={ classes.leftGroup }>
            <div className={ classes.intrestGroup }>
              <div>
                <div className={ classes.otherExpence }>
                  <h3>Total Repayment Amount (Principal & Interest)</h3>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ `${classes.expenseText} ${classes.boldText}` }>
                {moduleServices.module10Data.spouseTotalPrincipalInterest !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={ moduleServices.module10Data.spouseTotalPrincipalInterest } prefix={ moduleServices.module10Data.spouseTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module10Data.spouseTotalPrincipalInterest < 0 && ')' } />
                ) : ( '<span>$0</span>' )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup === true && (
        <WeightedAverageInterestRatePopup title={ "Spouse's Student Loans: Weighted Average Interest Rate" } openStatus={popup} closePopup={handlePopupClose} previousData={inputData.weighted_loan_data !== undefined ? inputData.weighted_loan_data : ''}/>
      )}
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

    </div>
  );
};

SpouseStudentLoanDebt.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles( styles )( SpouseStudentLoanDebt );
