import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruTwelve = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruTwelve)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Financial Independence Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the financial independence module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Existing Assets and Liabilities Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the existing assets and liabilities module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate existing assets and liabilities with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(13) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Career Path Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the career path module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate career path with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(13) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Career Advancement Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the career advancement module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate career advancement with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Additional Career Advancement Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the additional career advancement module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Further Career Advancement Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the further career advancement, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Investments Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the investments module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Real Estate Investment (REI) Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the real estate investment (rei) module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(13) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Higher Education Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the higher education module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>More Higher Education Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the more higher education module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Further Higher Education Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the Further Higher Education module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Family Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the family module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                <div>
                    <h4> Vacation Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the  vacation module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(11) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Buying a Car Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the buying a car module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(12) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Buying a Home Walk-Thru Complete!</h4>
                    <p>Remember, if you want a guided, step-by-step format for the buying a home module, switch to Plan With Inkwiry. Now, get started, build some scenarios, and navigate financial independence with confidence.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(13) }}>Back</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruTwelve.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruTwelve);