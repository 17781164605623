import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import ReiBottom from './rei-bottom';
import * as modulesServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi/rei/styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Summary = ( props ) => {
  const {
    classes, handleDefinationPopup, handleSlide, inputData, setOpenWorkSheet
  } = props;

  const [value, setValue] = React.useState( 0 );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  const reiOutputBackendData = modulesServices.reiOutputBackendGraphData();
  const reiOutputBarGraph1Data = reiOutputBackendData && reiOutputBackendData['Bar Graph2'];
  const reiBarGraph2Data = reiOutputBackendData && reiOutputBackendData['Bar Graph1'];
  
  return (
    <div className={ classes.contentBlock }>
      <p>Buying, owning, and renting a real estate property can be a great investment. With a plan to buy and manage your property, you can make that investment with confidence knowing that the cash flows and returns are well calculated.</p>
      <Button onClick={()=>{setOpenWorkSheet(true)}} className={ classNames( classes.worksheetButton, classes.worksheetButton1 ) }>SUMMARY WORKSHEET</Button>
      <Tabs
        value={ value }
        onChange={ handleChange }
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={ classes.tabButtons }
        aria-label="full width tabs example"
      >
        <Tab className={ value === 0 ? classes.activeTab : '' } label="Table" { ...a11yProps( 0 ) } />
        <Tab className={ value === 1 ? classes.activeTab : '' } label="Income & Expenses Summary" { ...a11yProps( 1 ) } />
        <Tab className={ value === 2 ? classes.activeTab : '' } label="Property Value & Mortgage Repayment" { ...a11yProps( 2 ) } />
      </Tabs>
      {
        value === 0 && (
          <ReiBottom handleDefinationPopup={ handleDefinationPopup } activeStep={ 17 } handleSlide={ handleSlide } inputData={ inputData } />
        )
      }
      {value === 1 && (
        <div className={ classes.summaryGraphs }>
          <HighchartsReact
            highcharts={ Highcharts }
            options={ {
              chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                type: 'column',
                height: 400,
              },
              navigation: {
                menuItemStyle: {
                  fontSize: '14px',
                  textAlign: 'left',
                },
                menuItemHoverStyle: {
                  background: '#f5f5f5',
                  color: '#4c4c4c',
                  fontSize: '14px',
                },
                buttonOptions: {
                  height: 40,
                  width: 48,
                  symbolSize: 24,
                  symbolX: 24,
                  symbolY: 21,
                  symbolStrokeWidth: 2,
                  verticalAlign: 'bottom',
                  _titleKey: 'y',
                },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                        chart.exportChart();
                      },
                    }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'image/jpeg',
                        } );
                      },
                    }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'application/pdf',
                        } );
                      },
                    }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'image/svg+xml',
                        } );
                      },
                    }],
                  },
                },
              },
              credits: {
                enabled: false,
              },
              title: {
                text: '',
              },
              xAxis: {
                categories: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Years List'],
                labels: {
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              },
              yAxis: [{ // Primary yAxis
                labels: {
                  formatter() {
                    const chart = this;
                    if ( chart.value < 0 ) {
                      return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                    }
                    return formatDollar( Math.round( chart.value ) );
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                title: {
                  text: '',
                  style: {
                    color: Highcharts.getOptions().colors[2],
                  },
                },

              }],
              tooltip: {
                formatter() {
                  const chart = this;
                  return `<b>${chart.x}</b><br/>${
                    chart.series.name}: $${chart.y.toFixed( 0 ).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' )}<br/>`
                        + `Total: $${chart.point.stackTotal.toFixed( 0 ).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' )}`;
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '14px',
                },
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                },
              },
              series: [{
                name: 'Rental Income',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Rental Income'],
                stack: 'male',
                color: '#5B9BD5',
              }, {
                name: 'Property Taxes',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Property Taxes'],
                stack: 'female',
                color: '#9E480E',
              }, {
                name: 'Utilities',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data.Utilities,
                stack: 'female',
                color: '#255E91',
              }, {
                name: 'Property Maintenance',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Property Maintenance'],
                stack: 'female',
                color: '#70AD47',
              }, {
                name: 'Homeowners Insurance',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Homeowners Insurance'],
                stack: 'female',
                color: '#4472C4',
              }, {
                name: 'Management Fee',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Management Fee'],
                stack: 'female',
                color: '#FFC000',
              }, {
                name: 'Association Fees',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Association Fees'],
                stack: 'female',
                color: '#A5A5A5',
              }, {
                name: 'Mortgage Interest',
                data: reiOutputBarGraph1Data && reiOutputBarGraph1Data['Mortgage Payment'],
                stack: 'female',
                color: '#ED7D31',
              }],
            } }
          />
        </div>
      )}
      {value === 2 && (
        <div className={ classes.summaryGraphs }>
          <HighchartsReact
            highcharts={ Highcharts }
            options={ {
              chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                zoomType: false,
                height: 400,
                events: {
                  load() {
                    const chart = this;
                    const text = chart.renderer.text(
                      `<strong>Monthly Payment: ${formatDollar( modulesServices.realEstateMonthlyPayment() )}</strong>`,
                      chart.plotLeft + 30,
                      chart.plotTop + 35,
                    ).attr( {
                      zIndex: 5,
                    } ).add();
                    const box = text.getBBox();

                    chart.renderer.rect( box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0 )
                      .attr( {
                        fill: '#FFFFFF',
                        stroke: '#2f75b5',
                        'stroke-width': 1,
                        zIndex: 4,
                      } )
                      .add();
                  },
                },
              },
              navigation: {
                menuItemStyle: {
                  fontSize: '14px',
                  textAlign: 'left',
                },
                menuItemHoverStyle: {
                  background: '#f5f5f5',
                  color: '#4c4c4c',
                  fontSize: '14px',
                },
                buttonOptions: {
                  height: 40,
                  width: 48,
                  symbolSize: 24,
                  symbolX: 24,
                  symbolY: 21,
                  symbolStrokeWidth: 2,
                  verticalAlign: 'bottom',
                  _titleKey: 'y',
                },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                        chart.exportChart();
                      },
                    }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'image/jpeg',
                        } );
                      },
                    }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'application/pdf',
                        } );
                      },
                    }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                        chart.exportChart( {
                          type: 'image/svg+xml',
                        } );
                      },
                    }],
                  },
                },
              },
              credits: {
                enabled: false,
              },
              title: {
                text: '',
              },
              xAxis: [{
                categories: reiBarGraph2Data && reiBarGraph2Data['Years List'],
                crosshair: true,
                labels: {
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              }],
              yAxis: [{ // Primary yAxis
                labels: {
                  formatter() {
                    const chart = this;
                    if ( chart.value < 0 ) {
                      return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                    }
                    return formatDollar( Math.round( chart.value ) );
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                title: {
                  text: 'Principal and Interest',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                opposite: true,
              }, { // Secondary yAxis
                gridLineWidth: 0,
                title: {
                  text: 'Property Value and Mortgage Balance',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                labels: {
                  formatter() {
                    const chart = this;
                    if ( chart.value < 0 ) {
                      return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                    }
                    return formatDollar( Math.round( chart.value ) );
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              }],
              tooltip: {
                // eslint-disable-next-line
                pointFormat: '{series.name} <b>${point.y:,.0f}</b>',
              },
              legend: {
                itemStyle: {
                  fontSize: '14px',
                },
              },
              series: [{
                name: 'Mortgage Balance',
                type: 'column',
                yAxis: 1,
                data: reiBarGraph2Data && reiBarGraph2Data['Mortgage Balance'],
                tooltip: {
                  valuePrefix: '$',
                },
                color: '#C00000',
              }, {
                name: 'Real Estate Value',
                type: 'column',
                yAxis: 1,
                data: reiBarGraph2Data && reiBarGraph2Data['Home Value'],
                tooltip: {
                  valuePrefix: '$',
                },
                color: '#0070C0',
              }, {
                name: 'Principal Payback',
                type: 'spline',
                yAxis: 0,
                data: reiBarGraph2Data && reiBarGraph2Data['Principal Payback'],
                tooltip: {
                  valuePrefix: '$',
                },
                color: '#A5A5A5',
              }, {
                name: 'Interest',
                type: 'spline',
                yAxis: 0,
                data: reiBarGraph2Data && reiBarGraph2Data.Interest,
                marker: {
                  enabled: true,
                },
                tooltip: {
                  valuePrefix: '$',
                },
                color: '#FFC000',
              }],
            } }
          />
        </div>
      )}
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />

    </div>
  );
};

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles( styles )( Summary );
