import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-existing/styles';
import TextField from '@material-ui/core/TextField';

const ExistingHomeSummary = ( props ) => {
  const { classes, home, handleUpdatedObject, handleAssestObject } = props;
  
  const updateValue = ( e, field ) => {
    const inputValues = {...handleAssestObject};
    inputValues[field] = e.floatValue !== undefined ? e.floatValue : 0;
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p>Enter your monthly home expenses below.</p>
        <div className={ `${classes.tableBox} ${classes.tableBoxPWINewHomeExpensive} ${classes.mbt30}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th colSpan={ 2 }>Home Expenses</th>
                <th colSpan={ 2 }>Monthly</th>
                <th colSpan={ 2 }>Annual</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={ 2 }>Property Taxes</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_property_taxes :  handleAssestObject[`existing_home_monthly_property_taxes_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_property_taxes' :  `existing_home_monthly_property_taxes_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_property_taxes !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_property_taxes * 12 :  handleAssestObject[`existing_home_monthly_property_taxes_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                    </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Homeowner&apos;s Insurance</td>
                <td colSpan={ 2 } >
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_homeowner_insurance :  handleAssestObject[`existing_home_monthly_homeowner_insurance_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_homeowner_insurance' :  `existing_home_monthly_homeowner_insurance_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_homeowner_insurance !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_homeowner_insurance * 12 :  handleAssestObject[`existing_home_monthly_homeowner_insurance_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Private Mortgage Insurance (PMI)</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_private_mortgage_insurance :  handleAssestObject[`existing_home_monthly_private_mortgage_insurance_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_private_mortgage_insurance' :  `existing_home_monthly_private_mortgage_insurance_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_private_mortgage_insurance !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_private_mortgage_insurance * 12 :  handleAssestObject[`existing_home_monthly_private_mortgage_insurance_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Maintenance</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_maintenance :  handleAssestObject[`existing_home_monthly_maintenance_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_maintenance' :  `existing_home_monthly_maintenance_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 } className={classes.annualInput}>
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_maintenance !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_maintenance * 12 :  handleAssestObject[`existing_home_monthly_maintenance_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Association Fees</td>
                <td colSpan={ 2 } className={classes.annualInput}>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_association_fees :  handleAssestObject[`existing_home_monthly_association_fees_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_association_fees' :  `existing_home_monthly_association_fees_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_association_fees !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_association_fees * 12 :  handleAssestObject[`existing_home_monthly_association_fees_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Utilities</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_utilities :  handleAssestObject[`existing_home_monthly_utilities_${home}`] } onValueChange={ ( e ) => updateValue( e, home === 1 ? 'existing_home_monthly_utilities' :  `existing_home_monthly_utilities_${home}`) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </td>
                <td colSpan={ 2 } >
                  <div className={ classes.amountDollar }>
                    {handleAssestObject.existing_home_monthly_utilities !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_utilities * 12 :  handleAssestObject[`existing_home_monthly_utilities_${home}`] * 12 } prefix="$" /> ) : ( '$0' )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={ 2 }>Mortgage Monthly Payment (Principal & Interest)</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      {moduleServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? moduleServices.module18Data.existingHomeMortgageMonthlyPayment : moduleServices.module18Data.existingHomeMortgageMonthlyPayment2} prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      {moduleServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? moduleServices.module18Data.existingHomeMortgageMonthlyPayment * 12 : moduleServices.module18Data.existingHomeMortgageMonthlyPayment2 * 12 } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
              </tr>
              <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                <td colSpan={ 2 }>Total Monthly Home Expenses</td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      {moduleServices.totalMonthlyHomeExpenses() !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator value={ home === 1 ? moduleServices.totalMonthlyHomeExpenses() : moduleServices.totalMonthlyHomeExpenses2()} prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
                <td colSpan={ 2 }>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      {moduleServices.totalMonthlyHomeExpenses2() !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator value={ home === 1 ? moduleServices.totalMonthlyHomeExpenses() * 12 : moduleServices.totalMonthlyHomeExpenses2()  * 12 } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
     
    </div>
  );
};

ExistingHomeSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ExistingHomeSummary );
