import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/financial-independence/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 10,
    label: '10%',
  },
];

const Inflation = (props) => {
  const {
    classes, handleDefinationPopup, incomeGrowthGraphData, inputData, updateData,
  } = props;

  const [inflation, setInflation] = React.useState(2.5);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(() => {
    if (inputData.inflation_in_percentage !== undefined) {
      setInflation(inputData.inflation_in_percentage);
    } else {
      updateData('inflation_in_percentage', 2.5);
    }
  }, [inputData.inflation_in_percentage]);

  const inflationGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[4];

  const updateValue = (e, value, field, type) => {
    let newvalue = 0;
    if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setInflation(newvalue);
    updateData(field, newvalue);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>Inflation is the increase in the general level of prices for goods and services.</p>
      <p>Choose a rate of annual inflation to allow the Financial Configurator to forecast your future expenses. For example, if you choose 2.0% annual inflation, and your rent in year 1 is $1,000, your rent expense will grow to $1,020 in year 2.”</p>
      <div className={classes.AnnualIncomeGrowth}>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Inflation')}>Annual Inflation</span></p>
          <div className={classes.pwisliderInputs}>
            <PwiSlider
              value={inflation}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={percentFormatWithTwoDecimal}
              min={0}
              max={10}
              step={0.1}
              marks={marks}
              ValueLabelComponent={ValueLabelComponent}
              onChange={(e, value) => updateValue(e, value, 'inflation_in_percentage', 'slider')}
            />
          </div>
          <div className={classes.sliderValues}>
            <NumberFormat
              allowNegative={false}
              customInput={TextField}
              value={inflation}
              decimalScale={isFocus !== 'inflation_in_percentage' ? 2 : 5}
              fixedDecimalScale={isFocus !== 'inflation_in_percentage'}
              onValueChange={(e) => updateValue(e, '', 'inflation_in_percentage', 'number')}
              onFocus={(e) => { e.target.select(); setIsFocus('inflation_in_percentage') }}
              onBlur={emptyIsFocus} />
            <span className={classes.dollarSymbol}>%</span>
          </div>
        </div>
      </div>
      <div className={classes.grayBox}>
        <p>If you wish to use a different percentage, check out the graph below for historical inflation rates and find a number that works for your plan. Or, stick with the default 2.5%.</p>
      </div>
      <div className={classes.graphInflection}>
        {/* <img src={ GraphInfle } alt="" /> */}
        <div className={classes.graphBox}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'spline',
                scrollablePlotArea: {
                  scrollPositionX: 0,
                },
              },
              navigation: {
                menuItemStyle: {
                  fontSize: '14px',
                  textAlign: 'left',
                },
                menuItemHoverStyle: {
                  background: '#f5f5f5',
                  color: '#4c4c4c',
                  fontSize: '14px',
                },
                buttonOptions: {
                  height: 40,
                  width: 48,
                  symbolSize: 24,
                  symbolX: 24,
                  symbolY: 21,
                  symbolStrokeWidth: 2,
                  verticalAlign: 'bottom',
                  _titleKey: 'y',
                },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                  },
                },
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Inflation Rate (12-month percentage change)',
              },
              xAxis: [{
                tickInterval: 24,
                categories: inflationGraphData.dates,
                labels: {
                  formatter() {
                    const chart = this;
                    return chart.value.split(' ')[1];
                  },
                  style: {
                    color: '#000000',
                    fontSize: '13px',
                  },
                },
              }],
              yAxis: [{ // Primary yAxis
                tickInterval: 1,
                labels: {
                  format: '{value:.1f}%',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                title: {
                  text: '',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              }],
              tooltip: {
                headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                crosshairs: true,
                shared: true,
                valueDecimals: 1,
                valueSuffix: '%',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '14px',
                },
              },
              plotOptions: {
                spline: {
                  lineWidth: 2,
                  states: {
                    hover: {
                      lineWidth: 2,
                    },
                  },
                  marker: {
                    enabled: false,
                  },

                },
              },
              series: inflationGraphData.details,
            }}
          />
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
    </div>
  );
};

Inflation.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  incomeGrowthGraphData: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles(styles)(Inflation);
