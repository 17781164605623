import financialBanner from '../../assets/img/financial-configurator/inner-banner_new.jpg';
import quoteSymbol from '../../assets/img/financial-configurator/quotes_img.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  financialBanner: {
    backgroundImage: `url(${financialBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    backgroundAttachment: 'fixed',
    backgroundSize: '100% auto',
    padding: '18% 0',
    marginBottom: '56px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '30px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      backgroundAttachment: 'inherit',
      backgroundSize: 'cover',
    },
    '&:before': {
      position: 'absolute',
      left: '0',
      top: '0',
      content: '""',
      background: 'rgba(51, 51, 51, 0.5)',
      width: '100%',
      height: '100%',
    },
  },
  bannerCaption: {
    position: 'absolute',
    top: '45%',
    transform: 'translateY(-45%)',
    mozTransform: 'translateY(-45%)',
    webkitTransform: 'translateY(-45%)',
    width: '100%',
    '& h2': {
      textShadow: 'none',
      fontSize: '54px',
      textTransform: 'capitalize',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
      '& span': {
        display: 'inline-block',
        width: '100%',
      },
    },
  },
  marketingContent: {
    '& h2': {
      fontSize: '48px',
      lineHeight: '48px',
      fontFamily: '"MuseoSans-100"',
      margin: '0 0 38px 0',
      color: theme.palette.common.blue,
      fontWeight: '300',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '37px',
        lineHeight: '40px',
        marginBottom: '20px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        lineHeight: '30px',
        marginBottom: '0',
      },
    },
    '& h3': {
      fontSize: '36px',
      lineHeight: '58px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
      fontWeight: '300',
      marginTop: '10px',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '27px',
        lineHeight: '38px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '28px',
      },
    },
    '& span': {
      display: 'block',
    },
  },
  innovateSection: {
    marginTop: '50px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '30px',
    },
    '& h4': {
      fontSize: '36px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-300"',
      marginBottom: '16px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '21px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '0px',
        fontSize: '22px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '34px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.black,
      marginRight: '5px',
      marginBottom: '25px',
      fontWeight: '300',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        marginTop: '8px',
        marginBottom: '0px',
      },
    },
  },
  gridSpace: {
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '10px',
    },
  },
  lifeEvents: {
    textAlign: 'center',
    boxSizing: 'border-box',
    '& span': {
      cursor: 'pointer',
      backgroundColor: '#fa9c22',
      borderColor: '#b9bdc3',
      color: theme.palette.common.white,
      minWidth: '322px',
      marginRight: '0',
      marginTop: '75px',
      fontSize: '28px',
      lineHeight: '28px',
      marginBottom: '60px',
      boxShadow: 'none',
      textDecoration: 'none',
      border: '1px solid #848a4d',
      background: 'none',
      borderRadius: '10px',
      padding: '12px 22px',
      display: 'inline-block',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: '#e4860c',
        borderColor: '#b9bdc3',
      },
      '@media screen and (min-width: 992px) and (max-width: 1024px)': {
        margin: '30px 0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '20px',
        fontSize: '20px',
        marginBottom: '30px',
        minWidth: '250px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: 'inherit',
      },
    },
  },
  thumbNail: {
    '& img': {
      width: '100%',
    },
    '& h3': {
      fontSize: '34px',
      color: theme.palette.common.blue,
      lineHeight: '36px',
      fontFamily: '"MuseoSans-300"',
      letterSpacing: '0',
      fontWeight: '500',
      margin: '25px 0',
      padding: '0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '25px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        marginTop: '8px',
        marginBottom: '10px',
        fontSize: '20px',
        lineHeight: '27px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '34px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.black,
      marginRight: '5px',
      marginBottom: '25px',
      fontWeight: '300',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '20px',
        marginTop: '0px',
      },
    },
  },
  testimonialBlock: {
    background: 'none',
    padding: '20px 0 80px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 0 80px 0',
    },
    '& h4': {
      fontSize: '24px',
      lineHeight: '30px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
      position: 'relative',
      padding: '0 0 0 130px',
      textAlign: 'left',
      zIndex: '99',
      fontWeight: '500',
      margin: '0px 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '24px',
        padding: '0 0 0 70px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
        lineHeight: '24px',
      },
      '&:after': {
        content: '""',
        backgroundImage: `url(${quoteSymbol})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '84px',
        height: '55px',
        position: 'absolute',
        top: '0',
        left: '0',
        [theme.breakpoints.down( 'sm' )]: {
          width: '55px',
          height: '35px',
          backgroundSize: 'cover',
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '40px',
          height: '26px',
          backgroundSize: 'cover',
        },
      },
      '& span': {
        fontSize: '24px',
        lineHeight: '24px',
        display: 'block',
        fontStyle: 'italic',
        textAlign: 'right',
        marginTop: '5px',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '20px',
          lineHeight: '20px',
        },
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '15px',
          lineHeight: '24px',
        },
      },
    },
  },
  testimonialRow: {
    width: '83%',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      margin: '0 auto',
    },
  },
} );

export default styles;
