import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as modulesServices from '../../calculations/modules-services';
import { formatDollar } from '../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
HighchartsMore( Highcharts );

const NetworthRangeGraph = ( props ) => {
  const {
    defaultValues, rangeValues, activeSlide, graphSlide,
  } = props;
  const [inputRangeValues, setInputRangeValues] = React.useState( {} );
  const [graphData, setGraphData] = React.useState( {} );

  const generateGraph = () => {
    const outputSheetCompleteData = modulesServices.outputSheetCompleteData();
    const graphValues = {
      years: outputSheetCompleteData.yearsList,
      series: [{
        name: 'Gross Income',
        yAxis: 0,
        data: outputSheetCompleteData.grossIncomeGraphData,
        stack: 'male',
        color: '#5B9BD5',
      }, {
        name: 'Total Expenses',
        yAxis: 0,
        data: outputSheetCompleteData.totalExpensesGraphData,
        stack: 'female',
        color: '#FF0000',
      }, {
        name: 'Estimated Taxes',
        yAxis: 0,
        data: outputSheetCompleteData.incomeAfterTaxesGraphData,
        stack: 'female',
        color: '#F4B183',
      }, {
        type: 'spline',
        name: 'Net Worth',
        yAxis: 1,
        data: outputSheetCompleteData.netWorthGraphData,
        color: '#00B050',
      }],
    };
    setGraphData( graphValues );
  };

  const generateGraphCalc =  ( income, expenses ) => {
    const { income_array } = defaultValues;
    ['module2', 'module4', 'module5', 'module6', 'module8', 'module10', 'module15', 'module17'].forEach( ( value ) => {
      const modulevalues = defaultValues[value];
      if ( modulevalues !== undefined ) {
        Object.keys( modulevalues ).forEach( ( inputName ) => {
          if ( income_array.indexOf( inputName ) >= 0 ) {
            modulevalues[inputName] += modulevalues[inputName] * ( income / 100 );
          } else {
            modulevalues[inputName] += modulevalues[inputName] * ( expenses / 100 );
          }
        } );
        if ( value === 'module2' ) {
          modulesServices.setModule2Data( modulevalues );
        } else if ( value === 'module4' ) {
          modulesServices.setModule4Data( modulevalues );
        } else if ( value === 'module5' ) {
          modulesServices.setModule5Data( modulevalues );
        } else if ( value === 'module6' ) {
          modulesServices.setModule6Data( modulevalues );
        } else if ( value === 'module8' ) {
          modulesServices.setModule8Data( modulevalues );
        } else if ( value === 'module10' ) {
          modulesServices.setModule10Data( modulevalues );
        } else if ( value === 'module15' ) {
          modulesServices.setModule15Data( modulevalues );
        } else if ( value === 'module17' ) {
          modulesServices.setModule17Data( modulevalues );
        }
        initializeExcelSheetsData();
        workSheetCalc();
      }
    } );
  } ;

  useEffect( () => {
    if ( inputRangeValues.income !== undefined ) {
      generateGraphCalc( inputRangeValues.income, inputRangeValues.expenses );
      setTimeout( () => {
        for ( let i = 0; i <= 20; i += 1 ) {
          initializeExcelSheetsData();
          workSheetCalc();
          if ( i === 20 ) {
            generateGraph();
          }
        }
      }, 500 );
    }
    // eslint-disable-next-line
  },[inputRangeValues])

  useEffect( () => {
    let income = 0;
    let expenses = 0;
    if ( activeSlide === graphSlide ) {
      income = parseFloat( rangeValues.income );
      expenses = parseFloat( rangeValues.expenses );
      if ( inputRangeValues.income !== income || inputRangeValues.expenses !== expenses ) {
        setInputRangeValues( { income, expenses } );
      }
    } else if ( parseFloat( rangeValues.income ) !== 0 || parseFloat( rangeValues.expenses ) !== 0 ) {
      income = 0;
      expenses = 0;
      generateGraphCalc( income, expenses );
    }

    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  }, [activeSlide, graphSlide, rangeValues.income, rangeValues.expenses, inputRangeValues.income, inputRangeValues.expenses, generateGraphCalc])

  return (
    <div>
      {graphData.years !== undefined && (
        <HighchartsReact
          highcharts={ Highcharts }
          options={ {
            chart: {
              backgroundColor: '#fbfdfd',
              plotBackgroundColor: '#fbfdfd',
              plotBorderColor: '#cccccc',
              plotBorderWidth: 1,
              plotShadow: false,
              type: 'column',
            },
            navigation: {
              menuItemStyle: {
                fontSize: '14px',
                textAlign: 'left',
              },
              menuItemHoverStyle: {
                background: '#f5f5f5',
                color: '#4c4c4c',
                fontSize: '14px',
              },
              buttonOptions: {
                height: 40,
                width: 48,
                symbolSize: 24,
                symbolX: 24,
                symbolY: 21,
                symbolStrokeWidth: 2,
                verticalAlign: 'bottom',
                _titleKey: 'y',
              },
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [{
                    textKey: 'downloadPNG',
                    onclick() {
                      this.exportChart();
                    },
                  }, {
                    textKey: 'downloadJPEG',
                    onclick() {
                      this.exportChart( {
                        type: 'image/jpeg',
                      } );
                    },
                  }, {
                    textKey: 'downloadPDF',
                    onclick() {
                      this.exportChart( {
                        type: 'application/pdf',
                      } );
                    },
                  }, {
                    textKey: 'downloadSVG',
                    onclick() {
                      this.exportChart( {
                        type: 'image/svg+xml',
                      } );
                    },
                  }],
                },
              },
            },
            title: {
              text: '',
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: graphData.years,
              labels: {
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            },
            yAxis: [{ // Primary yAxis
              min: 0,
              tickInterval: 10000,
              labels: {
                formatter() {
                  const chart = this;
                  if ( chart.value < 0 ) {
                    return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                  }
                  return formatDollar( Math.round( chart.value ) );
                },
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              title: {
                text: 'Income & Expenses',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            }, { // Tertiary yAxis
              title: {
                text: 'Net Worth',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              labels: {
                formatter() {
                  const chart = this;
                  if ( chart.value < 0 ) {
                    return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                  }
                  return formatDollar( Math.round( chart.value ) );
                },
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              opposite: true,
            }],
            tooltip: {
              formatter() {
                const chart = this;
                if ( chart.y < 0 ) {
                  return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar( -Math.round( chart.y ) )})</span>`;
                }
                return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar( Math.round( chart.y ) )}</b>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            legend: {
              itemStyle: {
                fontSize: '14px',
              },
            },
            series: graphData.series,
          } }
        />
      )}
    </div>
  );
};

NetworthRangeGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  rangeValues: PropTypes.object.isRequired,
  activeSlide: PropTypes.number.isRequired,
  graphSlide: PropTypes.number.isRequired,
};

export default NetworthRangeGraph;
