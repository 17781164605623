import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const Volatility = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Volatility</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/volatility.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Volatility
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/volatility-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          From an investor&apos;s point of view, volatility can
                          be a scary thing. Or it can be looked at as an
                          opportunity. What&apos;s volatility and how can it be
                          both scary and opportunistic?
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames(classes.pfRow, classes.pfBlockRow)}>
                      <div className={classes.feText}>
                        <p>
                          Volatility is simply the range in prices over a
                          specific period of time. Take, for example, Stock A
                          and Stock B. If Stock A starts the year at $100, falls
                          to $20, rises to $300, falls back to $50, and closes
                          the year at $500, you would call that a stock with
                          extremely high volatility. Stock B starts the year at
                          $100, falls to $95, rises to $100, falls to $97, and
                          closes the year at $105. In contrast to Stock A,
                          pretty smooth sailing and not a lot of movement for
                          Stock B. High volatility vs low volatility. Most
                          investors would prefer Stock B.
                        </p>

                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                scrollPositionX: 0,
                              },
                              marginTop: 40,
                            },
                            navigation: {
                              menuItemStyle: {
                                fontSize: '14px',
                                textAlign: 'left',
                              },
                              menuItemHoverStyle: {
                                background: '#f5f5f5',
                                color: '#4c4c4c',
                                fontSize: '14px',
                              },
                              buttonOptions: {
                                height: 40,
                                width: 48,
                                symbolSize: 24,
                                symbolX: 24,
                                symbolY: 21,
                                symbolStrokeWidth: 2,
                                verticalAlign: 'bottom',
                                _titleKey: 'y',
                              },
                            },
                            exporting: {
                              buttons: {
                                contextButton: {
                                  menuItems: [
                                    {
                                      textKey: 'downloadPNG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart();
                                      },
                                    },
                                    {
                                      textKey: 'downloadJPEG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/jpeg',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadPDF',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'application/pdf',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadSVG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/svg+xml',
                                        });
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: '',
                            },
                            xAxis: [
                              {
                                categories: [
                                  'Jan-20',
                                  'Mar-20',
                                  'Jun-20',
                                  'Sep-20',
                                  'Dec-20',
                                ],
                                labels: {
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                // Primary yAxis
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    if (chart.value < 0) {
                                      return `<span style="color:#bf0000;">(${formatDollar(
                                        -Math.round(chart.value),
                                      )})</span>`;
                                    }
                                    return formatDollar(
                                      Math.round(chart.value),
                                    );
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 0,
                              valuePrefix: '$',
                              style: {
                                color: '#000000',
                                fontSize: '13px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: false,
                                },
                              },
                            },
                            legend: {
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            series: [
                              {
                                name: 'Stock A',
                                data: [100, 20, 300, 50, 500],
                                color: '#0070c0',
                              },
                              {
                                name: 'Stock B',
                                data: [100, 95, 100, 97, 105],
                                color: '#ffc000',
                              },
                            ],
                          }}
                        />

                        <p>
                          High volatility can be scary because of how fast the
                          price changes. One day, the price of Stock A was $300
                          and then $50 shortly thereafter. When prices move that
                          fast, if you are an investor of this stock, it can be
                          scary to see your balance rise and drop rapidly in
                          short periods of time. But high volatility is also an
                          opportunity for a skilled investor.
                        </p>
                        <p>
                          As prices fall rapidly, the skilled investor might
                          look at that as a buying opportunity. Continuing with
                          our Stock A example, if you bought Stock A at $50 and
                          sold at $200, you would be extremely happy. In
                          reality, you rarely ever time an investment that
                          perfectly. But if you bought Stock A at $50 and sold
                          at $75, you would also be extremely happy. You made
                          50% on your investment!
                        </p>
                        <p>
                          Check out the volatility of the{' '}
                          <a
                            href="https://finance.yahoo.com/quote/%5Espx/?guccounter=1&guce_referrer=aHR0cHM6Ly9pbmt3aXJ5LmNvbS8&guce_referrer_sig=AQAAAKlKhB0OromsyadbPpzzBT6AsGr24z8FkYFj9ICH_MyHcWTUA-PneibEmsGN_LLOHdrEECe3XYn1WHJHF4cIoe-DG_-Hrw8Ym5yiDGFqdEDxWosIIhuMOvoUp6W_8OkfPRhzLVqN0AK2j3DMvZS3LCpgdF0lVdjU7LpvXOf0xIKH"
                            target="_blank"
                            rel="noopener noreferrer">
                            S&P 500 index over the last 30 years
                          </a>
                          . The{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P 500
                          </Link>{' '}
                          has mostly low volatility with a few short time
                          periods of high volatility.
                        </p>
                        <p>
                          When you hear on TV someone talking about high
                          volatility in the markets, they&apos;re most likely
                          referring to stock prices moving rapidly up and down.
                          What would cause a stock to experience high
                          volatility? What would cause the entire U.S. stock
                          market to experience high volatility?
                        </p>
                        <p>
                          The riskier the stock, the more volatility you can
                          expect.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Volatility.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Volatility);
