const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    overflow: 'hidden',
    padding: '0',
  },
  footer: {
    background: theme.palette.common.darkBlue,
    minHeight: '50px',
    padding: '2% 0',
  },
  footerTop: {
    '& ul': {
      listStyle: 'none',
      display: 'flex',
      padding: '0 0 10px',
      margin: '0',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'center',
      },
      '& li': {
        cursor: 'pointer',
        display: 'inline-block',
        fontFamily: 'MuseoSans-700',
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '15px',
        },

        '& span': {
          color: theme.palette.common.white,
          fontSize: '22px',
          textDecoration: 'none',
          padding: '0 15px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
            padding: '0 10px',
          },
          [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            padding: '0 8px',
          },
        },
      },
    },
  },
  footerBottom: {
    paddingTop: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
      '& > div > div': {
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
      },
      '& > div': {
        display: 'block',
      },
    },
    '& p': {
      margin: '0',
      fontSize: '20px',
      color: theme.palette.common.white,
      fontFamily: 'MuseoSans-100',
      '& img': {
        width: '95px',
        verticalAlign: 'middle',
        marginLeft: '10px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      textAlign: 'center',
      '& p': {
        fontSize: '16px',
      },
      '& div': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      '& p': {
        fontSize: '14px',
      },
    },
  },
  footerLinks: {
    '& ul': {
      listStyle: 'none',
      padding: '0',
      display: 'table',
      margin: '0 auto',
      '& li ': {
        display: 'inline-block',
        padding: '2px 0',
        '& a': {
          color: theme.palette.common.white,
          borderRight: '1px solid #fff',
          padding: '0px 10px',
          textDecoration: 'underline',
          fontSize: '16px',
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
          },
        },

      },
      '& :last-child': {
        '& a': {
          border: 'none',
        },

      },
    },
  },
  copyrights: {
    marginBottom: '0',
  },
  poweredBy: {
    marginTop: '0',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  /* new footer css */
  newFooterDark: {
    background: '#333333',
    paddingTop: '120px',
    paddingBottom: '0px',
    color: '#000000',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
    }
  },
  footerDesc: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      paddingLeft: '20px !important',
    }
  },
  buildBest: {
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      fontFamily: '"MuseoSans-300"',
      margin: '0',
      lineHeight: '45px',
      paddingTop: '40px',
      fontWeight: 'normal',
      '& span': {
        display: 'block',
        width: '100%',
        fontWeight: 'normal',
      }
    }
  },
  footerRightBlock: {
    color: '#fff',
    '& p': {
      fontSize: '13px',
      fontFamily: '"MuseoSans-300"',
      opacity: '0.45',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
      }
    }
  },
  appToolsMenu: {
    paddingLeft: '0px',
    '& li': {
      listStyleType: 'none',
      marginBottom: '5px',
      fontSize: '13px',
      fontFamily: '"MuseoSans-100"',
      lineHeight: '1.42857143',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
      },
      '& a': {
        color: '#fff',
        '&:hover': {
          textDecoration: 'underline',
          color: '#f89b22',
        }
      }
    }
  },
  clickHere: {
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#f89b22',
    }
  },
footerTopMargin: {
    marginTop: '28px',
  },
  socialLinks: {
    textAlign: 'center',
    width: '100%',
    padding: '50px 0px 40px 0px',
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      margin: '0',
      '& li': {
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '0 8px',
        '& img': {
          maxWidth: '35px',
          '&:hover': {
            opacity: '0.7',
          }
        }
      }
    }
  },
  footerTwo: {
    background: '#252525',
    padding: '30px 0px 30px 0px',
    textAlign: 'center',
  },
  copyRightLeft: {
    '& p': {
      fontSize: '12px',
      fontFamily: '"MuseoSans-100"',
      color: '#fff',
      marginBottom: '0px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
      },
      '& a': {
        fontSize: '12px',
        fontFamily: '"MuseoSans-100"',
        color: '#fff',
        marginBottom: '0px',
      }
    }
  },
  copyRightCenter: {
    '& p': {
      fontSize: '12px',
      fontFamily: '"MuseoSans-100"',
      color: '#fff',
      marginBottom: '0px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
      },
      '& span': {
        display: 'inline-block',
        margin: '0px 5px',
      },
      '& a': {
        fontSize: '12px',
        fontFamily: '"MuseoSans-100"',
        color: '#fff',
        marginBottom: '0px',
        transition: 'all 0.5s ease',
        '&:hover': {
          textDecoration: 'underline',
          color: '#f89b22',
        }
      }
    }
  },
  copyRightRight: {
    '& p': {
      fontSize: '12px',
      fontFamily: '"MuseoSans-100"',
      color: '#fff',
      marginBottom: '0px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
      },
    }
  },
  dialogVideo: {
    position: 'relative',
    '& > div > div': {
      width: '100%',
      height: '100%',
      maxWidth: '750px',
      maxHeight: '430px',
    },
    '& iframe': {
      width: '100%',
      height: '100%',
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    background: 'transparent',
    color: '#fa9c22',
    opacity: '0.6',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '0px',
    top: '5px',
    '&:hover': {
      opacity: '1',
    }
  }
});

export default styles;
