const styles = ( ) => ( {
  default: {
    display: 'flex',
    justifyContent: 'center',
  },
  fullHeight: {
    minHeight: '100vh',
    alignItems: 'center',
  },
} );

export default styles;
