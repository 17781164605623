import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { useQuery,useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import scrollToComponent from 'react-scroll-to-component';
import ReactTooltip from 'react-tooltip';
import Sidebar from './sidebar';
import { withStyles } from '@material-ui/core/styles';
import Pageloader from '../../components/ui/pageloader';
import ExploreTheWorld from '../../components/vacation/explore-the-world';
import SettingVacationGoals from '../../components/vacation/setting-vacation-goals';
import PlanningVacation from '../../components/vacation/planning-vacation';
import SpecialPurchaseTimelineOne from '../../components/vacation/special-purchase-timeline1';
import SpecialPurchaseTimelineTwo from '../../components/vacation/special-purchase-timeline2';
import VacationSummary from '../../components/vacation/summary';
import VacationBottom from '../../components/vacation/vacation-bottom';
import SpecialPurchaseCostOne from '../../components/vacation/special-purchase-cost1';
import SpecialPurchaseCostTwo from '../../components/vacation/special-purchase-cost2';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import styles from './styles';
import headerFI from '../../assets/img/pwi-fi/vocation.png';
import LogoImage from '../../assets/img/logo.png';
import Walk from '../../assets/img/pwi-fi/walk.png';
import * as moduleServices from '../../calculations/modules-services';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import ClearTime from '../../assets/img/pwi-investments/clear-timeline-blue.png';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import * as timeLineActions from '../../calculations/time-line';
import messages from '../../utilities/pwi-popup-messages';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';

const PWI_DEFINATION = loader( '../../graphql/schema/financial-independence/getPWiDefination.graphql' );
const SAVESCENARIO = loader( '../../graphql/schema/pwi/save.graphql' );
const MODULE_DATA = loader( '../../graphql/schema/fc/moduleData.graphql' );
const CHECK_MODULE_EXISTS = loader( '../../graphql/schema/fc/check-module-exists.graphql' );

const titleText = [
  "Explore the World",
  'Setting Vacation Goals',
  'Planning a Vacation',
  'Special Purchase #1 Timelines',
  'Special Purchase #1 Cost',
  'Special Purchase #2 Timelines',
  'Special Purchase #2 Cost',
  'Summary',
];

const VACATION = ( props ) => {
  const { classes, history } = props;
  let bodySection = useRef( null );

  const [menu, setMenu] = useState( false );
  const [mainMenu, setMainMenu] = useState( false );
  const [activeStep, setActiveStep] = useState( 0 );  
  const [progress, setProgress] = React.useState( 0 );
  const [titleDefination, setTitleDefination] = useState( '' );
  const [definationPopup, setDefinationPopup] = useState( false );
  const [completedSlides, setCompletedSlides] = useState( { 0: true } );
  const [vacationData, setvacationData] = useState( {} );
  const [vacationPopup, setVacationPopup] = useState( '' );
  const [vacationClose, setVacationClose] = useState( false );  
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState( 0 );
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState( { 0: false } );
  const [backgroundBlur, setBackgroundBlur] = useState( false );
  const [clearModule, setClearModule] = React.useState(false);
  const [activeScenarioData, setActiveScenarioData] = React.useState({});  
  const [loading, setLoading] = React.useState( true );
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({'popupStatus':false});
  const [openPopup, setOpenPopup] = React.useState( '' );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );  
  const [moduleObjectParams, setmoduleObjectParams] = useState( {} );
  const [loadValue, setLoadValue] = React.useState(false);

  const [getModuleData] = useLazyQuery( MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module11' : ''
    },
    onCompleted( response ) {
      setClearModule(false);
      timeLineActions.assignModulesData( response.getModuleData, ( data ) => {
        if ( data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 0; c <= 7; c+=1) {
              completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex( response.getModuleData.scenarios, { id: data.scenario_id } );
          if(index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setvacationData( data );
        } else if(moduleServices.module1Data.start_year <= 0){
          const popupdetails = messages('No Fi Module','Vacation');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'confirm',no_pwi_fi: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        } else {
          setvacationData( {} ); 
           setActiveScenarioData({});
        }
        setActiveStep(0);
        setLoading( false );
      } );
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData( '', ( data ) => {
        if(moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module','Vacation');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'no_pwi_fi',url: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        }
        setvacationData( {} );
        setActiveStep(0);
        setActiveScenarioData({});
        setLoading( false );
      } );
    },
  } );

  useQuery( CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module11',
      page: 'pwi' 
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if(response.checkModuleExists.status === true) {
        if(response.checkModuleExists.message === 'success') {
          setLoading( false );
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({...{
            popupStatus: true,
            type: 'default_clear',
          },...popupdetails})
        } else {
          getModuleData()
        }
      } else {
        if(response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  } ); 

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({'popupStatus':false})
  }
  const openBeforeLeavePopup = (url,moduleName, type='page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if(window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({...{popupStatus: true,type:type === 'page' ? 'confirm' : 'load',url: moduleName === 'Worksheet' ? '/vacation' : url,saveContinueBtn},...popupdetails})
  }

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
  } );

  const { data: definationResult } = useQuery( PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  } );

  /*
    **
      Clear scenario Start
    **
  */
 const clearEntireScenario = () => {
  setMenu(false);
  setMainMenu(false);
  const popupdetails = messages('clearScenario');
  setBeforeLeavePopup({...{
    popupStatus: true,
    type: 'clear',
  },...popupdetails})
}

const clearScenario = () => {
  setLoading( true ); 
  closeBeforeLeavePopup();
  setClearModule(true);
  getModuleData()
}
/*
  **
    Clear scenario End
  **
*/

const recallDataApi = ( type ) => {
  if ( type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear' ) {
    setLoading( true );
    getModuleData();
  }
}

  useEffect( () => {
    let progressBar = ( activeStep + 1 ) * ( 100 / 8 );
    progressBar = progressBar.toFixed( 1 );
    setProgress( progressBar );
  }, [activeStep] );

  const handleSlide = ( viewNumber, goType) => {
    const completedSlide = { ...completedSlides };
    switch(viewNumber) {
      case 0:{
         setActiveStep(viewNumber);
        break 
      }
      case 1:{
        setActiveStep(viewNumber);
        break 
      }
      case 2:{
         setActiveStep(viewNumber);
        break 
      }
      case 3:{
         setActiveStep(viewNumber);
        break 
      }
      case 4:{
        if(vacationData.second_start_year !== undefined && vacationData.second_start_year > 0) {
          setActiveStep(viewNumber);
        } else {
          if(goType === 'next') {
            handleSlide(5,'next')
            return false;    
          } else {
            handleSlide(3,'back')    
            return false;
          }
        }
        break 
      }
      case 5:{
        setActiveStep(viewNumber);
        break 
      }
      case 6:{
         if(vacationData.third_start_year !== undefined && vacationData.third_start_year > 0) {
          setActiveStep(viewNumber);
        } else {
          if(goType === 'next') {
            handleSlide(7,'next')
            return false;    
          } else {
            handleSlide(5,'back')    
            return false;
          }
        }
        break 
      }
      case 7:{
        setActiveStep(viewNumber);
        break 
      }
      default:
        break;
    }
    window.scrollTo( 0, 0 );
    completedSlide[viewNumber] = true;
    setCompletedSlides( completedSlide );
  } 

  const handleMenu = () => {
    setMenu( !menu );
  };

  const handleMainMenu = () => {
    setMainMenu( !mainMenu );
  };

  const handleDefinationPopup = ( def ) => {
    setTitleDefination( def );
    setDefinationPopup( true );
  };
  
  const updateInputValue = ( field, value ) => {
    let inputDetails = { ...vacationData };
    if(field === 'total_obj') {
      inputDetails = value;
    } else {
      inputDetails[field] = value;
    }
    setvacationData( inputDetails );
    moduleServices.setModule11Data(inputDetails);
  };

  const handleVacationPopupClose = () => {
    setVacationClose( false );
  };

  const handleVacationPopup = ( data ) => {
    const vacationType = vacationData;
    if ( data === 'save' ) {
      vacationType.type = 'save';
      vacationType.sketchName = 'Folder';
      vacationType.module = 'module11';
      vacationType.moduleNumber = 'module11Data';
      setmoduleObjectParams( vacationType );
    }
    setVacationClose( true );
    setVacationPopup( data );
  };

  const saveScenarioCallBack = () => {
    setMenu( false );
    setMainMenu( false );
    handleVacationPopup('save');
  }

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup( false );
  };

  const handleWalkThrus = ( slide ) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if ( slide === 1 ) {
      window.scrollTo( 0, bodySection.scrollHeight );
      scrollToComponent( bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      } );
    } else if ( slide === 3 || slide === 4 ) {
      setMenu( true );
    } else if ( slide === 5 ) {
      setMenu( false );
      scrollToComponent( bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      } );
    } else if ( slide === 6 ) {
      setMenu( false );
      setMainMenu( true );
      scrollToComponent( bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      } );
    } else {
      scrollToComponent( bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      } );
      setMenu( false );
      setMainMenu( false );
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide( slide + 1 );
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides( completedWalkSlides );
    setBackgroundBlur( true );
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides( completedWalkSlides );
    setActiveWalkThruSlide( 0 );
    setBackgroundBlur( false );
    setMenu( false );
    setMainMenu( false );
  };

  useEffect( () => {
    if ( backgroundBlur ) {
      document.body.classList.add( 'pwiAnimation' );
      document.body.classList.add( 'pwiAnimationActive' );
    } else {
      document.body.classList.remove( 'pwiAnimation' );
      document.body.classList.remove( 'pwiAnimationActive' );
    }
  }, [backgroundBlur] );

  const [saveScenarios] = useMutation( SAVESCENARIO, {
    onCompleted( {
      saveScenario: {
        id,
        status,
        message,
      },
    } ) {
      if ( status ) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError( errors ) {
      setLoading(false)
    },
  } );

  const handleQuickSave =()=>{
    if(activeScenarioData&&activeScenarioData.inkwiry_scenario === 0) {
        let finalObject = {};
        finalObject['folder_id']= activeScenarioData.directory_id;
        finalObject['id']= activeScenarioData.id;
        finalObject['module']= "module11";
        finalObject['relation_year']= moduleServices.module1Data.start_year;
        finalObject['scenario_name']= timeLineActions.generateScenarioName('module11', 'quick-save',activeScenarioData);
        finalObject['module11Data'] = pwiObject.getfilteredVacationObject( moduleServices.module11Data );
        setLoading(true)
        saveScenarios( {
          variables: {
            data: finalObject,
          },
        } );
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({...{
        popupStatus: true,
        type: 'save-inkwiry-scenario',
      },...popupdetails})
    }
  }
  let disabledNext = false;
  if(activeStep === 1) {
    if(!(vacationData.start_year !== undefined && vacationData.start_year !== '' && parseInt(vacationData.start_year, 10) > 0)) {
      disabledNext = true;
    }

  }

  return (
    <Typography variant="body1" component="div" className={ backgroundBlur ? classNames( classes.pwiAnimation, classes.pwiAnimationActive, classes.disablePointerevents ) : '' } ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ document.body.scrollHeight < 700 ? classes.screenHght : '' }>
      <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={ menu } handleSideMenu={ handleSlide } openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={ setMenu } completedSlides={ completedSlides } />
        <ReactTooltip id="worksheet" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
        {activeWalkThruSlide === 4 && ( <WalkThruFour handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
        {activeWalkThruSlide === 5 && ( <WalkThruFive handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
        <div className={ mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone }>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={ setMainMenu } />
        </div>
        {activeWalkThruSlide === 7 && ( <WalkThruSeven handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
        <div className={ classes.fiHeader }>
          <div className={ classes.container }>
            <div className={ classes.fiHeaderInner }>
              <span className={ activeWalkThruSlide === 6 ? classes.pwiActiveModule : '' }>
                <img src={ headerFI } alt="" aria-hidden="true" onClick={ handleMainMenu } />
                <h3>Vacation + Special Purchases</h3>
              </span>
              <Link href="/" onClick={(event)=>{event.preventDefault();openBeforeLeavePopup('/','Home');}} className={ classes.logoLink }>
                <img src={ LogoImage } alt="" />
              </Link>
              {activeWalkThruSlide === 6 && ( <WalkThruSix handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
            </div>
          </div>
        </div>
        <div className={ classes.fiContent }>
          <div className={ classes.stepOne }>
            <div className={ classes.fiContentHeader }>
              <div className={ activeWalkThruSlide === 3 ? classes.pwiWalkTitle : '' }>
                <div aria-hidden="true" onClick={ handleMenu } className={ classes.menuIcon }>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && ( <WalkThruThree handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
                <h3>{titleText[activeStep]}</h3>
              </div>
              <figure className={ backgroundBlur && ( activeWalkThruSlide === 1 || activeWalkThruSlide === 8 ) ? classes.pwiActiveBlock : '' }>
                <img src={ Walk } alt="" data-for="worksheet" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={ () => { handleWalkThrus( 0 ); } } />
              </figure>
              {activeWalkThruSlide === 1 && ( <WalkThruOne handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
              {activeWalkThruSlide === 8 && ( <WalkThruEight handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } /> )}
            </div>
            <div className={ classes.ProgressBar }>
              <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
            </div>
            {( activeStep === 0 ) && (
              <ExploreTheWorld/>
            )}

            {( activeStep === 1 ) && (
              <SettingVacationGoals
                inputData={ vacationData }
                updateData={ updateInputValue }
              />
            )}
            {( activeStep === 2 )
              && (
                <PlanningVacation inputData={ vacationData } updateData={ updateInputValue } />
            )}
            
            {( activeStep === 3 ) && (
              <SpecialPurchaseTimelineOne
                inputData={ vacationData }
                updateData={ updateInputValue }
                activeStep={ activeStep }
              />
            )}

            {( activeStep === 4 )
              && (
                <SpecialPurchaseCostOne inputData={ vacationData } updateData={ updateInputValue } handleDefinationPopup={ handleDefinationPopup } />
              )}
            {( activeStep === 5 ) && (
              <SpecialPurchaseTimelineTwo
                inputData={ vacationData }
                updateData={ updateInputValue }
                activeStep={ activeStep }
              />
            )}
            {( activeStep === 6 )
              && (
                <SpecialPurchaseCostTwo inputData={ vacationData } updateData={ updateInputValue }  />
              )}
            {( activeStep === 7 )
              && (
                <VacationSummary />
              )}
            {definationResult && definationResult.getPWiDefination
              && (
                <DefinationPopup
                  definationPopup={ definationPopup }
                  handleDefinationPopupClose={ handleDefinationPopupClose }
                  getPWiDefination={definationResult.getPWiDefination}
                />
              )}

            {( activeStep >= 1 ) && (
              <React.Fragment>
                {activeStep !== 7 && (
                  <div className={ classes.borderDivider } />
                )}
                <VacationBottom inputData={ vacationData } updateData={ updateInputValue } activeStep={ activeStep } />
              </React.Fragment>
            )}

          </div>
          <div className={ classes.btnGroup }>
            {activeWalkThruSlide === 2 && ( <WalkThruTwo handleWalkThrus={ handleWalkThrus } handleWalkThruClose={ handleWalkThruClose } completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } moduleName="vacation" /> )}
            {activeStep === 0 ? '' : <Button className={ classes.cancel } onClick={ ()=>{handleSlide(activeStep-1,'back')} }>Back</Button>}
            <Button className={ classes.clearBtn } onClick={clearScenario} aria-hidden="true" data-for="worksheet" data-tip="Clear your scenario and start fresh."><img src={ ClearTime } alt="" /></Button>
            
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <React.Fragment>
                <ReactTooltip id="quicksaveBtntoolTip" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.tooltipInfoTopSave ) }/>
                <Button onClick ={handleQuickSave} className = {classes.quickSave}><span><img src={ save } alt="" data-for="quicksaveBtntoolTip" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page."/></span></Button>
              </React.Fragment>
            )}
            
            {activeStep === 7 ? (
              <Button className={ activeWalkThruSlide === 2 ? classNames( classes.next, classes.pwiWalkThruButton ) : classes.next } onClick={ () => { handleVacationPopup( 'save' ); } }>Save</Button>
            ) : (
              <Button className={ activeWalkThruSlide === 2 ? classNames( classes.next, classes.pwiWalkThruButton ) : classes.next } disabled={ disabledNext } onClick={ () => { handleSlide( activeStep + 1, 'next' ); } }>Next</Button>
            )}
          </div>
        </div>
        <ReactTooltip id="worksheet" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
        <div className={ classes.videoModalPopup }>
          <Dialog
            open={ vacationClose }
            onClose={ handleVacationPopupClose }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={ classes.SavePopup }
          >
            <DialogContent className={ classes.visitPlaceModal }>
              <div className={ classes.popupClose }>
                <span aria-hidden="true" onClick={ handleVacationPopupClose }>
                  ×
                </span>
              </div>
              {vacationPopup === 'save' && (
                <PwiSave setPopupOpen={setVacationClose} moduleParams={ moduleObjectParams } getSaveData={ vacationData } setLoadValue={setLoadValue}/>
              )}
              <div />
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup}  closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup}/>
      {openPopup !== '' && ( <SimulatorPanel otherData={ {'module': 'module11'} } setOpenPopup={ setOpenPopup } popupStatus={ openPopup } recallDataApi={ recallDataApi } /> )}
      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}
    
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

VACATION.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( VACATION );
