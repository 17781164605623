import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core';
import classNames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';

import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Pageloader from '../../components/ui/pageloader';
import * as commonFunctions from '../../utilities/commonFunctions';
import IncomeGrowthGraphs from '../worksheet-modules/annual-income-growth-tabs-common';
import styles from './styles';
import financeExplainedValues from '../../utilities/finanace-explained-values';
import Inflation from '../worksheet-modules/inflation-graph';
import { definitions, links } from './constant';

const PUBLIC_QUICK_LINKS = loader(
  '../../graphql/schema/finanance-explained/public-quick-links.graphql',
);
const PUBLIC_DEFINATION = loader(
  '../../graphql/schema/finanance-explained/public-definations.graphql',
);
const INCOME_GROWTH_GRAPH_DATA = loader(
  '../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql',
);
const SUBSCRIPTION = loader(
  '../../graphql/schema/finanance-explained/subscription.graphql',
);

const FinanceExplained = (props) => {
  const { classes, location } = props;
  const { search } = location;
  const { data: publicDefinations } = useQuery(PUBLIC_DEFINATION);
  const { data: publicQuickLinks } = useQuery(PUBLIC_QUICK_LINKS);
  const { data: incomeGrowthGraphData } = useQuery(INCOME_GROWTH_GRAPH_DATA);

  const [searchInput, setSearchInput] = React.useState('');
  const [explainedPages, setExplainedPages] = React.useState(
    financeExplainedValues(),
  );
  const [mappedLinksList, setMappedLinksList] = React.useState();
  const [constantArray, setConstantArray] = React.useState();
  const [definationLinkData, setDefinationLinkData] = React.useState();
  const [subscriptionInput, setsubscriptionInput] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [currentTab, setCurrentTab] = React.useState(() => {
    const url = new URLSearchParams(props.location.search);
    if (url.get('id') && url.get('type')) {
      setTimeout(() => {
        goToQuickLinks(url.get('id'), url.get('type'));
      }, 1000);
      return url.get('type');
    } else if (url.get('type')) {
      return url.get('type');
    } else return 'financeexplained';
  });

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setCurrentTab('financeexplained');
    }
    if (newValue === 1) {
      setCurrentTab('definitions');
    }
    if (newValue === 2) {
      setCurrentTab('quicklinks');
    }
  };
  React.useEffect(() => {
    if (publicQuickLinks) {
      const sortedByCategeory = publicQuickLinks
        ? _.uniqBy(publicQuickLinks.PublicQuickLinks, (a) => a.category)
        : '';

      const updatedArr = publicQuickLinks
        ? _.groupBy(publicQuickLinks.PublicQuickLinks, 'category')
        : '';
      // eslint-disable-next-line
      const quickLinks = _.forEach(
        sortedByCategeory,
        (value) => (value.Links = updatedArr[value.category]),
      );
      setMappedLinksList(quickLinks);
      setConstantArray(quickLinks);
      setLoading(false);
    }
  }, [publicQuickLinks]);

  React.useEffect(() => {
    if (publicDefinations) {
      setDefinationLinkData(publicDefinations.PublicDefinations);
    }
  }, [publicDefinations]);

  const handleSearch = (e, val) => {
    setSearchInput(e.target.value);
    let data = [];
    const text = e.target.value;
    if (val === 'def') {
      data = publicDefinations.PublicDefinations.filter((obj) =>
        obj.title.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      setDefinationLinkData(data);
    } else if (val === 'links') {
      data = constantArray.filter((obj) =>
        obj.category.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      setMappedLinksList(data);
    } else {
      data = financeExplainedValues().filter((obj) =>
        obj.title.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      setExplainedPages(data);
    }
  };

  const goToQuickLinks = (id, type) => {
    if (id !== undefined && type !== undefined) {
      let linkType = '';
      linkType =
        type === 'definitions'
          ? (linkType = 'definition_')
          : (linkType = 'link_');
      scroller.scrollTo(linkType + id, {
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -57,
      });
    }
  };
  const openSubscriptionNote = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>That was a smart move for your money!</h3>
            <div className={classes.NoFiDiv}>
              <p>
                You&apos;re one step away from subscribing to Finance Explained
                by Inkwiry and getting smarter about your money. In a few
                minutes, you&apos;ll receive an email message asking you to
                verify your email address. To verify, all you need to do is
                click on the link provided. Then, check your email on Tuesday
                for your weekly Finance Explained by Inkwiry.
              </p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => {
                onClose();
                setsubscriptionInput('');
              }}>
              OK
            </button>
          </div>
        </div>
      ),
    });
  };

  const [subcriptionEmail] = useMutation(SUBSCRIPTION, {
    onCompleted({ Subscription: { status } }) {
      if (status) {
        openSubscriptionNote();
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setErrorMessage(formatedErrors);
    },
  });
  const handleSubcription = () => {
    if (subscriptionInput) {
      if (!commonFunctions.validateEmail(subscriptionInput)) {
        setErrorMessage('Please enter valid email.');
      } else {
        subcriptionEmail({
          variables: {
            data: {
              email: subscriptionInput,
            },
          },
        });
      }
    } else {
      setErrorMessage('Please enter your email.');
    }
  };

  const images = require.context('../../assets/img/finance-explained/', true);
  const mainUrl = '/finance-explained/';

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.bannerBlock}>
        <div className={classes.bannerImage} />
        <div className={classes.bannerCaption}>
          <Typography variant="h2" component="h2">
            Finance Explained by Inkwiry
          </Typography>
          <Typography variant="h4" component="h4">
            Get smarter about your money with just one email per week.
            <span>
              The best part? It&apos;s completely&nbsp;
              <i>free</i>
              &nbsp; to sign up.
            </span>
          </Typography>
          <div className={classes.subscribeBtn}>
            <TextField
              className={classes.subscriptionText}
              placeholder="Enter your email address"
              value={subscriptionInput}
              onChange={(e) => {
                setsubscriptionInput(e.target.value);
              }}
            />
            {/* <input type="text" placeholder="Enter your email address" /> */}
            <Button onClick={handleSubcription}>Subscribe</Button>
          </div>
          {errorMessage && (
            <span className={classes.noteError}>{errorMessage}</span>
          )}
        </div>
      </div>
      <div className={classes.tabsSection}>
        <div className={classes.mainTab}>
          <div className={classes.tutorialsBlock}>
            <div className={classes.tabsRow}>
              {/* <AppBar position="static"> */}
              <Tabs value={currentTab} onChange={handleChange} centered>
                <Tab
                  label="Finance Explained"
                  className={
                    currentTab === 'financeexplained'
                      ? classes.activeModule
                      : classes.unActiveModule
                  }>
                  Finance Explained
                </Tab>
                <Tab
                  label="Definitions"
                  className={
                    currentTab === 'definitions'
                      ? classes.activeModule
                      : classes.unActiveModule
                  }
                  onClick={handleChange}>
                  Definitions
                </Tab>
                <Tab
                  label="Quick Links"
                  className={
                    currentTab === 'quicklinks'
                      ? classes.activeModule
                      : classes.unActiveModule
                  }
                  onClick={handleChange}>
                  Quick Links
                </Tab>
              </Tabs>
            </div>
          </div>
          {/* </AppBar> */}
          {currentTab === 'financeexplained' && (
            <div
              className={classNames(classes.container, classes.tabContainer)}>
              <div className={classes.tabContent}>
                <div className={classes.searchInput}>
                  <div className={classes.inputGroup}>
                    <TextField
                      className={classes.searchTextField}
                      id="standard-basic"
                      placeholder="Search"
                      value={searchInput}
                      onChange={(e) => {
                        handleSearch(e, '');
                      }}
                    />
                    <div className={classes.inputGroupAddon}>
                      {' '}
                      <i className="fa fa-search" aria-hidden="true" />{' '}
                    </div>
                  </div>
                </div>
                <Grid container spacing={2}>
                  {explainedPages.length > 0 &&
                    explainedPages.map((val) => (
                      <Grid item sm={4}>
                        <div className={classes.galleryNews}>
                          <div className={classes.galleryNewsImg}>
                            <Link to={mainUrl + val.link}>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${val.img}`)}
                              />
                            </Link>
                          </div>
                          <div className={classes.galleryCaption}>
                            <h3>
                              <Link to={mainUrl + val.link}>{val.title}</Link>
                            </h3>
                            <p>{val.posted_date}</p>
                          </div>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          )}
          {currentTab === 'definitions' && (
            <div
              className={classNames(classes.container, classes.tabContainer)}>
              <div className={classes.tabContent}>
                <div className={classes.searchInput}>
                  <div className={classes.inputGroup}>
                    <TextField
                      className={classes.searchTextField}
                      id="standard-basic"
                      placeholder="Search"
                      value={searchInput}
                      onChange={(e) => {
                        handleSearch(e, 'def');
                      }}
                    />
                    <div className={classes.inputGroupAddon}>
                      {' '}
                      <i className="fa fa-search" aria-hidden="true" />{' '}
                    </div>
                  </div>
                </div>
                <div className={classes.financialDefinitions}>
                  <div className={classes.financialQuickLinks}>
                    {definitions.map((value) => (
                      <>
                        <p
                          aria-hidden="true"
                          className={value.id === 0 && classes.cursorNone}
                          onClick={() => {
                            goToQuickLinks(value.id, 'definitions');
                          }}>
                          {value.label}
                        </p>
                        <span> | </span>
                      </>
                    ))}
                  </div>
                  <div className={classes.financialContent}>
                    {definationLinkData &&
                      definationLinkData.map((val) => (
                        <div
                          className={classes.financialRow}
                          id={`definition_${val.id}`}>
                          {val.link !== '' && (
                            <h3>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={val.link}>
                                {val.title}
                              </a>
                            </h3>
                          )}
                          {val.link === '' && <h3>{val.title}</h3>}

                          <p
                            dangerouslySetInnerHTML={{
                              __html: val.description,
                            }}
                            className={classes.financialDesc}
                          />
                          {val.image === 'annual_income_growth' && (
                            <div className={classes.feGraphTabs}>
                              <IncomeGrowthGraphs />
                              <div className={classes.bottomText}>
                                <p className={classes.sourceBottomText}>
                                  Source:{' '}
                                  <a
                                    href="https://www.frbatlanta.org/chcs/wage-growth-tracker.aspx"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <u>Federal Reserve Bank of Atlanta,</u>
                                  </a>{' '}
                                  Current Population Survey, Bureau of Labor
                                  Statistics
                                </p>
                              </div>
                            </div>
                          )}
                          {val.image === 'annual_inflation' && (
                            <div>
                              <Inflation
                                incomeGrowthGraphData={incomeGrowthGraphData}
                              />
                              <div className={classes.bottomText}>
                                <p className={classes.sourceBottomText}>
                                  Source:{' '}
                                  <a
                                    href="https://www.bls.gov/cpi/"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <u>U.S. Bureau of Labor Statistics</u>
                                  </a>
                                </p>
                              </div>
                            </div>
                          )}
                          {val.image === 'accrued_interest' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'Accrued_Interest.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'capitalized' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Capitalized_Interest_Graph.PNG'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'capitalized' && (
                            <div className={classes.capitalImage}>
                              <img
                                alt=""
                                src={images(
                                  `./${'Capitalized_Interest_Table.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'compound_interest_table' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'Compound_Interest.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'rule_72' && (
                            <div className={classes.iraImage}>
                              <img
                                alt=""
                                src={images(`./${'Rule_of_72.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'roth_ira' && (
                            <div className={classes.iraImage}>
                              <img
                                alt=""
                                src={images(
                                  `./${'Roth_IRA Phaseouts_Single.png'}`,
                                )}
                              />
                              <img
                                alt=""
                                src={images(
                                  `./${'Roth_IRA_Phaseouts_Married.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'child_tax_credit' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'child_tax_credit.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'lease_buy' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'lease_vs_buy_prosandCons.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'compound_interest_1' && (
                            <div className={classes.graphText}>
                              <div className={classes.blueBox}>
                                <p>
                                  Molly saves $500 per month from age 21-60 and
                                  earns a 6% return.
                                </p>
                                <p>
                                  <b>
                                    Molly contributes a total of $240,000 and
                                    grows her savings to $1,000,724!
                                  </b>
                                </p>
                              </div>
                              <div className={classes.greenBox}>
                                <p>
                                  Mike saves $450 per month from age 21-32 and
                                  then stops. He earns a 6% return.
                                </p>
                                <p>
                                  Mike contributes a total of $64,800 and grows
                                  his savings to $507,814!
                                </p>
                                <p>
                                  <b>Mary never catches up to Mike.</b>
                                </p>
                              </div>
                              <div className={classes.redBox}>
                                <p>
                                  Mary saves $450 per month from age 32-60 and
                                  earns a 6% return.
                                </p>
                                <p>
                                  Mary contributes a total of $156,600 and grows
                                  her savings to $422,645!
                                </p>
                              </div>
                            </div>
                          )}
                          {val.image === 'compound_interest_2' && (
                            <div className={classes.graphText}>
                              <div className={classes.blueBox}>
                                <p>
                                  Molly saves $500 per month from age 21-60 and
                                  earns a 6% return.
                                </p>
                                <p>
                                  <b>
                                    Molly contributes a total of $240,000 and
                                    grows her savings to $1,000,724!
                                  </b>
                                </p>
                              </div>
                              <div className={classes.greenBox}>
                                <p>
                                  Mike saves $450 per month from age 21-32 and
                                  then stops. He earns a 6% return.
                                </p>
                                <p>
                                  Mike contributes a total of $64,800 and grows
                                  his savings to $507,814!
                                </p>
                                <p>
                                  <b>Mary never catches up to Mike.</b>
                                </p>
                              </div>
                              <div className={classes.redBox}>
                                <p>
                                  Mary saves $450 per month from age 32-60 and
                                  earns a 6% return.
                                </p>
                                <p>
                                  Mary contributes a total of $156,600 and grows
                                  her savings to $422,645!
                                </p>
                              </div>
                            </div>
                          )}
                          {val.image === 'networth_chat' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'net-worth-chart_2.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'vesting_example' && (
                            <div className={classes.iraImage}>
                              <img
                                alt=""
                                src={images(`./${'career_path_bill_img.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'loan_limits' && (
                            <div className={classes.underImage}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Dependent_Under_graduate_Student_Loan_Limits.png'}`,
                                )}
                              />
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Independent_Undergraduate_Student_Loan_Limits.png'}`,
                                )}
                              />
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Graduate_Career_Student_Loan_Limits.png'}`,
                                )}
                              />
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'All_student_loan_limits.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'cash_flow_moment' && (
                            <div>
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'Cash Flow Movement_1.png'}`)}
                              />
                              <img
                                alt=""
                                width="100%"
                                src={images(`./${'Cash Flow Movement_2.png'}`)}
                              />
                            </div>
                          )}
                          {val.image === 'long_term_investments' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Types_Of_Long_Term_Investments.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'short_term_investments' && (
                            <div className={classes.imageMargin}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Types_of_Short_Term_Investments.png'}`,
                                )}
                              />
                            </div>
                          )}
                          {val.image === 'risk_to_reward' && (
                            <div className={classes.longTermImage}>
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Types_of_Short_Term_Investments.png'}`,
                                )}
                              />
                              <img
                                alt=""
                                width="100%"
                                src={images(
                                  `./${'Types_Of_Long_Term_Investments.png'}`,
                                )}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentTab === 'quicklinks' && (
            <div
              className={classNames(classes.container, classes.tabContainer)}>
              <div className={classes.tabContent}>
                <div className={classes.searchInput}>
                  <div className={classes.inputGroup}>
                    <TextField
                      className={classes.searchTextField}
                      id="standard-basic"
                      placeholder="Search"
                      value={searchInput}
                      onChange={(e) => {
                        handleSearch(e, 'links');
                      }}
                    />
                    <div className={classes.inputGroupAddon}>
                      {' '}
                      <i className="fa fa-search" aria-hidden="true" />{' '}
                    </div>
                  </div>
                </div>
                <div className={classes.financialQuickLinks}>
                  {links.map((value) => (
                    <>
                      <p
                        aria-hidden="true"
                        className={value.id === 0 && classes.cursorNone}
                        onClick={() => {
                          goToQuickLinks(value.id, 'links');
                        }}>
                        {value.label}
                      </p>
                      <span> | </span>
                    </>
                  ))}
                </div>
                {mappedLinksList &&
                  mappedLinksList.map((val) => (
                    <div
                      className={classes.quickLinksRow}
                      id={`link_${val.search_char}`}>
                      <div>
                        <h3>{val.category}</h3>
                        {val &&
                          val.Links.map((link) => (
                            <p className={classes.financialDesc}>
                              <a
                                href={link.link}
                                target="_blank"
                                rel="noopener noreferrer">
                                {link.title}
                              </a>
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Typography>
  );
};

FinanceExplained.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(FinanceExplained));
