import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/further-higher-education/styles';

const StateTuitionFees = ( props ) => {
  const {
    classes, inputData, updateData, handleSlide
  } = props;

  const [stateType, setStateType] = React.useState('');


  useEffect(()=>{
    if(inputData.state_type !== undefined) {
      setStateType(inputData.state_type);
    }
  },[]);


  const handleStateType = ( type ) => {
    setStateType( type );
    let inputValues = {...inputData};
    inputValues['state_type'] = type;
    inputValues['tuition'] = inputData.school_details[type];
    inputValues['room_board'] = inputData.school_details['room_board'] !== undefined ? inputData.school_details['room_board'] : 0;    
    updateData('total_obj',inputValues);
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        Tuition and fees can change based on where you live. For the school you selected, are you an in-state or out-of-state student?
      </p>
      <p className={ classes.TopGapNew }>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          <br />
          Location of Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.location !== undefined && inputData.school_details.location}
        </i>
      </p>
      <div className={ `${classes.fourIconsBlock} ${classes.fourIconsBlockNew}` }>
        <ul>
          <li aria-hidden="true" className={ stateType === 'in_state_tuition_fee' ? classes.activeEducationType : '' } onClick={ () => handleStateType( 'in_state_tuition_fee' ) } onDoubleClick={ () => handleSlide( 5, 'next' ) }>
            <h2>
              In-State
              <br />
              {' '}
              Tuition Fees
            </h2>
            <h4 className="ng-binding">
              <NumberFormat value={ inputData.school_details.in_state_tuition_fee } decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
            </h4>
          </li>
          <li aria-hidden="true" className={ stateType === 'out_state_tuition_fee' ? classes.activeEducationType : '' }  onClick={ () => handleStateType( 'out_state_tuition_fee' ) } onDoubleClick={ () => handleSlide( 5, 'next' ) }>
            <h2>
              Out-of-State
              <br />
              {' '}
              Tuition Fees
            </h2>
            <h4 className="ng-binding">
              <NumberFormat value={ inputData.school_details.out_state_tuition_fee } decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
            </h4>
          </li>

        </ul>
      </div>
    </div>
  );
};

StateTuitionFees.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,
};

export default withStyles( styles )( StateTuitionFees );
