const styles = ( theme ) => ( {
  containerFluid: {
    maxWidth: '100%',
    padding: '30px',
    margin: '0px auto',
    background: '#014372',
    minHeight: '100vh',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 15px',
    },
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 5px',
    },
  },
  img: {
    verticalAlign: 'middle',
  },
  dashboardLogo: {
    width: '150px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto',
    },
    '& a': {
      display: 'flex',
      '& img': {
        width: '100%',
      },
    },
  },
  univestLogo:{
    width: '320px',
    position: 'relative',
    display: 'none',
    '& img':{
      maxWidth: '100%',
    }
  },
  inwiryLink:{
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    left: '0px',
  },
  univestLink:{
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    right: '0px',
  },
  powerUpLogo: {
    width: '500px',
    margin: '0 auto',
    paddingBottom: '25px',
    paddingTop: '62px',
    lineHeight: '1.42857143',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'auto',
      paddingTop: '0px',
    },
    '& img': {
      width: '100%',
    },
  },
  moduleRow: {
    padding: '0px',
  },
  financeModule: {
    border: '2px solid #fff',
    padding: '20px',
    borderRadius: '30px',
    display: 'flex',
    width: '130px',
    margin: '0 auto',
    height: '130px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative',
    '& img': {
      margin: '0 auto',
      width: '65px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
  },
  budgetModule: {
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '30px auto',
    }, 
    '@media screen and (min-width: 500px) and (max-width: 1024px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',     
    },       
    '& h3': {
      fontSize: '20px',
      marginTop: '15px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: '10px',
      lineHeight: '1.1',
      whiteSpace: 'nowrap',
    },
  },
  progressSec: {
    display: 'flex',
    '& > div': {
      height: '10px',
      position: 'relative',
      width: '130px',
      margin: '10px auto 0',
      border: '1px solid #fff',
      background: 'transparent',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 2px rgba(0,0,0,.1)',
      boxSizing: 'border-box',
      '& > div': {
        float: 'left',
        height: '100%',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#fff',
        textAlign: 'center',
        WebkitBoxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        WebkitTransition: 'width .6s ease',
        OTransition: 'width .6s ease',
        transition: 'width .6s ease',
        left: '0',
        width: '100%',
        position: 'absolute',
        marginLeft: '0',
        backgroundColor: '#84a84d',
        borderRadius: '4px',
      },
    },
  },
  progressValue: {
    position: 'absolute',
    left: '81%',
    display: 'flex',
    color: '#fff',
    fontSize: '20px',
    top: '0', 
    [theme.breakpoints.down( 'xs' )]: {
      left: '65%',
    },
    '@media (max-width: 420px)': {
      left: '80%',
    },       
  },
  progressBlock: {
    position: 'relative',
  },
  dashboardRow: {
    display: 'flex',
    marginBottom: '19px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
      display: 'block',
    },    
  },
  challengeRow: {
    marginTop: '50px',
    marginBottom: '70px',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '50px',
    },
  },
  challengeModule: {
    border: '2px solid #fff',
    padding: '20px',
    borderRadius: '30px',
    display: 'flex',
    width: '250px',
    margin: '0 auto',
    height: '130px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative',
    '& img': {
      margin: '0 auto',
      width: '100px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
  },
  taskComplete: {
    position: 'absolute',
    top: '-15px',
    right: '-20px',
    '& img': {
      width: '48px',
      height: '48px',
    },
  },
  panelHeader: {
    padding: '20px 15px',
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      margin: '0 5px 0 0',
      textAlign: 'left',
      fontWeight: 'normal',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 1199px)': {
        fontSize: '25px',
      }
    },
  },
  currentStats: {
    border: '2px solid #fff',
    borderRadius: '25px',
    padding: '0',
    position: 'relative',
    transition: 'all .8s ease',
  },
  fixed:{
    position: 'fixed',
    width: '310px',
    top: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '30px',
      position: 'initial',
    },
    '@media (min-width: 960px) and (max-width: 1199px)': {
      width: '250px',
    }
  },
  panelBody: {
    background: '#e1e1e1',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    padding: '25px 15px',
  },
  wellBlock: {
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '20px',
    position: 'relative',
    minHeight: '20px',
    marginBottom: '20px',
    border: '1px solid #e3e3e3',
    webkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
  },
  mb0:{
    marginBottom: '0px',
  },
  xpRank: {
    width: '55px',
    height: '55px',
    position: 'absolute',
    top: '-12px',
    right: '-7px',
    zIndex: '999',
    '& img': {
      width: '100%',
    },
  },
  xpGraphic: {
    width: '65px',
    height: '65px',
    position: 'absolute',
    top: '-12px',
    right: '-7px',
    zIndex: '999',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'md' )]: {
      width: '55px',
      height: '55px',
    },
  },
  divedeLine:{
    borderTop: '2px solid #959595',
    marginBottom: '10px',
    marginTop: '10px',
  },
  topFiveRow:{
    fontSize: '14px',
    display: 'flex',
  },
  countOne:{
    width: '9%',
  },
  countTwo:{
    width: '58%',
  },
  countThree:{
    width: '33%',
    textAlign: 'right',
  },
  blockTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '0px',
    lineHeight: '1.42857143',
    [theme.breakpoints.down( 'md' )]: {
      fontSize:'17px',
    },
  },
  blockDesc: {
    color: '#333',
    margin: '0',
    fontWeight: '500',
    fontSize: '30px',
    minHeight: '30px',
    textAlign: 'center',
    lineHeight: '1.1',
  },
  flexDisplay: {
    display: 'flex',
  },
  personNumber: {
    width: '9%',
    fontSize: '18px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  personName: {
    width: '58%',
    fontSize: '18px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  personXp: {
    width: '33%',
    textAlign: 'right',
    fontSize: '18px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  topTitle: {
    fontSize: '16px',
    marginBottom: '10px',
    marginTop: '0px',
  },
  topDivider: {
    marginBottom: '10px',
    marginTop: '10px',
    borderTop: '2px solid #959595',
  },
  memberName: {
    fontSize: '25px',
    color: '#fff',
    margin: '0',
    float: 'left',
    letterSpacing: '1px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down( 'md' )]: {
      fontSize: '15px',
    },
  },
  bellIcon: {
    position: 'relative',
    textAlign: 'right',
    padding: '0px 20px 0px 40px',
    '& a': {
      color: '#fff',
      fontSize: '24px',
      width: '24px',
      padding: '4px 10px',
      '& span': {
        background: '#f89b23',
        borderRadius: '50%',
        color: '#fff',
        content: '""',
        fontSize: '13px',
        height: '22px',
        lineHeight: '22px',
        padding: '0',
        position: 'absolute',
        right: '40px',
        textAlign: 'center',
        top: '-2px',
        width: '22px',
      },
    },
  },
  sideBlockHead: {
    position: 'relative',
    margin: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileIcon: {
    float: 'right',
    position: 'relative',
  },
  profileMenu: {
    cursor: 'pointer',
    '& img': {
      display: 'block',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      maxWidth: 'inherit',
      boxShadow: '0 0 0 2px rgba(255,255,255,.8)',
    },
  },
  incomePopup: {
    background: '#fff',
    zIndex: '999',
    flexWrap: 'wrap',
    position: 'absolute',
    left: '-60px',
    transform: 'translate(0,10px)',
    padding: '20px 10px',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    width: '400px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '1.2',
    marginTop: '15px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
     width: '300px',    
     marginTop: '0px',    
    },    
    [theme.breakpoints.down( 'xs' )]: {
      left: '120px',
      maxWidth: '100%',
      marginTop: '0px',
    },      
    '@media (max-width: 420px)': {
      width: '100%',
      left: 'auto',
    },
    '& h4': {
      fontSize: '20px',
      marginTop: '0',
      width: '100%',
      textAlign: 'center',
      marginBottom: '10px',
      color: '#333',
    },
    '& ul': {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
      '& li': {
        padding: '1px',
        fontSize: '18px',
        color: '#000',
        [theme.breakpoints.down( 'sm' )]: {         
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
        },   
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '15px',
          display: 'flex',
          alignItems: 'center',
        },       
        '& a': {
          color: '#000',
          '&:hover': {
            color: '#f59a34',
            textDecoration: 'underline',
          },
        },
      },
    },
    '&:before': {
      position: 'absolute',
      zIndex: '-1',
      content: '""',
      right: 'calc(50% - 10px)',
      top: '-18px',
      borderStyle: 'solid',
      borderWidth: '0 14px 18px',
      borderColor: 'transparent transparent #fff',
      transitionDuration: '.3s',
      transitionProperty: 'transform',
      [theme.breakpoints.down( 'sm' )]: {
        top: '-17px',
      },  
    },
  },
  greenCheckImg: {
    '& img': {
      width: '19px',
    },
  },
  greenCheckImgVisibleNon: {
    '& img': {
      width: '19px',
      visibility: 'hidden',
    },
  },
  incomeText: {
    paddingLeft: '10px',
  },
  downloadWorksheet: {
    '& a': {
      color: '#84a84d',
      textTransform: 'uppercase',
      fontSize: '13px',
      height: '100%',
      display: 'inline-block',
      cursor: 'pointer',
      padding: '8px 15px',
      WebkitTransition: 'all 0.5s ease 0s',
      border: '2px solid #84a84d',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: '#84a84d',
        color: '#fff',
      },
    },
  },
  popupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  goButton: {
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    '& button': {
      padding: '6px 30px',
      fontSize: '20px',
      marginRight: '10px',
      background: '#84a84d',
      borderRadius: '10px',
      color: '#fff',
      border: '2px solid #84a84d',
      lineHeight: 1.2,
      '&:hover': {
        border: '2px solid #d5d5d5',
        background: '#84a84d',
      },
    },
  },
  noDisplay: {
    display: 'none',
  },
  profileDropdown: {
    left: 'inherit',
    marginTop: '10px',
    right: '0',
    zIndex: '9999',
    maxWidth: '255px',
    top: '40px',
    background: '#fff',
    width: '160px',
    position: 'absolute',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    borderRadius: '4px',
    WebkitBoxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    '& li': {
      fontFamily: '"MuseoSans-500"',
      fontSize: '16px',
      '& a,& span': {
        cursor: 'pointer',
        padding: '7px 10px',
        display: 'block',
        lineHeight: '1.42857143',
        whiteSpace: 'nowrap',
        clear: 'both',
        fontWeight: '400',
        color: '#333',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#e5e5e5',
        },
      },
    },
    '&:before': {
      borderBottom: '11px solid #fff',
      left: '87%',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
  },
  notificationDropDown: {
    display: 'none',
    paddingTop: '0',
    border: '0',
    color: '#000',
    right: '1px',
    left: 'auto',
    top: '45px',
    background: '#fff',
    width: '380px',
    position: 'absolute',
    zIndex: '9999',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    borderRadius: '4px',
    WebkitBoxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    '&:before': {
      borderBottom: '11px solid #0069aa',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '90%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
  },
  notificationList: {
    background: ' #0069aa',
    color: '#fff',
    fontSize: '18px',
    padding: '8px 6px',
    '& span': {
      background: '#fff',
      borderRadius: '2px',
      color: '#0082c0',
      cursor: 'pointer',
      float: 'right',
      fontSize: '11px',
      marginTop: '3px',
      padding: '3px 5px',
      '&:last-child': {
        marginRight: '10px',
      },
    },
  },
  notificationDate: {
    display: 'block',
    backgroundColor: '#f1f3f7',
    color: '#93a0b2',
    padding: '14px 8px',
    fontSize: '12px',
    lineHeight: '12px',
  },
  notificationContent: {
    '& ul': {
      padding: '0px',
      height: '395px',
      maxHeight: 'none',
      overflowY: 'auto',
    },
  },
  notificationMessage: {
    padding: '15px 10px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  notifyProfile: {
    width: 'auto',
    height: 'auto',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& img': {
      width: '36px',
      height: '36px',
      cursor: 'default',
      borderRadius: '50%',
      display: 'inline-block',
      marginTop: '0',
      maxWidth: 'inherit',
    },
  },
  notificationDesc: {
    width: '88%',
    padding: '0 9px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  notificationTime: {
    display: 'inline-block',
    fontSize: '12px',
    marginRight: '5px',
    textAlign: 'right',
    position: 'absolute',
    width: 'auto',
    right: '10px',
    bottom: '0',
    color: '#f89b23',
  },
  inActiveModule: {
    opacity: '.5',
    cursor: 'not-allowed',
    '& div, & a': {
      pointerEvents: 'none',
    },
  },
  containerFlex:{
    display: 'flex',
    alignItems: 'flex-end',
    scrollBehavior: 'smooth',
  },
  leftSection: {
    flexGrow: '0',
    maxWidth: '73%',
    flexBasis: '73%',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  sideBlock: {
    padding: '0px !important',
    color: '#000',
    position: 'relative',
    flexGrow: '0',
    maxWidth: '27%',
    flexBasis: '27%',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '50%',
      flexBasis: '50%',
      margin: '0 auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  nextButton: {
    background: '#cea347',
    border: '2px solid #fff',
    color: '#fff',
    padding: '7px 45px',
    borderRadius: '6px',
    textTransform: 'uppercase',
    marginTop: '50px',
    fontSize: '20px',
    lineHeight: '1.42857143',
    '&:hover': {
      background: '#d09924',
      border: '2px solid #d09924',
    },
  },
  introScreen1: {
    textAlign: 'center',
    color: '#fff',
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      marginBottom: '30px',
      marginTop: '50px',
      fontWeight: 400,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        marginTop: '20px',
      },
    },
    '& p': {
      fontSize: '22px',
      marginBottom: '10px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
  },
  welcomeScreen: {
    width: '72%',
    margin: '0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '95%',
    },
  },
  mainLogo: {
    width: '50%',
    margin: '80px auto 30px auto',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      margin: '0px auto 0px auto',
      maxWidth: '400px',
      display: 'flex',     
    },
  },
  welcomePageTwo: {
    width: '78%',
    margin: '0 auto',
    position: 'relative',
    lineHeight: '1.42857143',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  titleOne: {
    fontSize: '30px',
    color: '#fff',
    marginBottom: '30px',
    marginTop: '50px',
    display: 'flex',
    position: 'relative',
    '@media (max-width: 767px)': {
      fontSize: '18px',
      marginTop: '30px',
    },
    '& p': {
      marginBottom: '10px',
      marginTop: '0px',
    },
  },
  graphImage: {
    marginRight: '100px',
    paddingLeft: '10px',
    '@media (max-width: 767px)': {
      marginRight: '0px',
    },
    '& img': {
      width: '80px',
      '@media (max-width: 767px)': {
        width: '60px',
      },
    },
  },
  graphSecond: {
    textAlign: 'right',
    position: 'absolute',
    right: '-60px',
    top: '110px',
    marginRight: '100px',
    paddingLeft: '10px',
    '@media (max-width: 767px)': {
      right: '-100px',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      top: '65px',
    },
    '& img': {
      width: '80px',
      '@media (max-width: 767px)': {
        width: '60px',
      },
    },
  },
  titleTwo: {
    fontSize: '25px',
    color: '#fff',
    marginBottom: '30px',
    fontFamily: '"MuseoSans-300"',
    display: 'flex',
    position: 'relative',
    paddingTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      marginBottom: '10px',
      marginTop: '0px',
    },
  },
  graphThree: {
    verticalAlign: 'bottom',
    paddingLeft: '10px',
    '& img': {
      marginLeft: '45px',
      marginRight: '50px',
      width: '120px',
      '@media (max-width: 767px)': {
        marginLeft: '0',
        marginRight: '0px',
        width: '80px',
      },
    },
  },
  inputUser: {
    fontFamily: '"MuseoSans-300"',
    fontSize: '25px',
    color: '#fff',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
  },
  formGroup: {
    '& div > div': {
      width: '100%',
      maxWidth: '400px',
    },
    '& input': {
      border: '1px solid #fff !important',
      borderRadius: '4px',
      fontSize: '20px',
      color: '#fff',
      outline: '0',
      width: '100%',
      height: '50px',
      boxSizing: 'border-box',
      padding: '6px 12px',
      lineHeight: '1.4',
      '&:hover': {
        border: '1px solid #fff !important',
      },
      '&:active': {
        border: '1px solid #fff !important',
      },
      '&:focus': {
        border: '1px solid #fff !important',
      },
    },
    '& fieldset': {
      borderColor: 'transparent !important',
    },
  },
  leftPadding: {
    paddingLeft: '16%',
    [theme.breakpoints.down( 'md' )]: {
      paddingLeft: '0%',
    },
  },
  inputBlock: {
    textAlign: 'center',
  },
  welcomePageThree: {
    width: '70%',
    margin: '0 auto',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      width: '80%',
    },
  },
  screenTitle: {
    textAlign: 'center',
    fontSize: '30px',
    color: '#fff',
    marginBottom: '30px',
    marginTop: '0px',
    fontWeight: 'normal',
  },
  certificateScreen: {
    display: 'flex',
    color: '#fff',
    fontSize: '22px',
    paddingLeft: '10%',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
      fontSize: '18px',
      justifyContent: 'center',
      
    },
    '@media (max-width: 992px)': {
      paddingLeft: '0px',
    },
    '& p': {
      margin: '0px auto 10px auto',
      '&:last-child': {
        marginBottom: '0px',
      },
    },
  },
  challengeImg: {
    marginRight: '55px',
    '@media (max-width: 767px)': {
      marginRight: '0px',
    },
    '& img': {
      height: '200px',
    },
  },
  challengeBtn: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      display: 'flex',
    },
  },
  aliasPopup: {
    '& > div > div ': {
      width: '40%',
      maxWidth: '100% !important',
    },
    '& > div > div > div': {
      borderBottom: '1px solid #e5e5e5',
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '30px',
      color: '#0069aa',
    },
    '& input': {
      display: 'block',
      width: '100%',
      height: '34px',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc !important',
      borderRadius: '4px',
      webkitBoxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      webkitTransition: 'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
      oTransition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
      '&:hover': {
        border: '1px solid #ccc !important',
      },
      '&:active': {
        border: '1px solid #ccc !important',
      },
      '&:focus': {
        border: '1px solid #66afe9 !important',
        webkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
      },
    },
    '& fieldset': {
      borderColor: 'transparent !important',
    },
  },
  aliasBtn: {
    float: 'right',
    color: '#fff',
    padding: '9px 20px',
    background: '#006aaa',
    borderColor: '#006aaa',
    textTransform: 'uppercase',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: 'normal',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    borderRadius: '6px',
    fontSize: '14px',
    margin: '0px 15px',
    '&:hover': {
      color: '#fff',
      background: '#1f96e0',
      borderColor: '#1f96e0',
    },
  },
  aliasInput: {
    marginBottom: '5px',
    '& > div > div': {
      maxWidth: '100% !important',
    },
    '& > div > div > div': {
      maxWidth: '100% !important',
    },
  },
  closeAlias: {
    float: 'right',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '1',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    filter: 'alpha(opacity=20)',
    opacity: '.2',
    padding: '0',
    cursor: 'pointer',
    background: 'transparent',
    border: '0',
    marginTop: '-2px',
    '&:hover': {
      opacity: '.5',
    },
  },  
  incomeBlock: {
    '@media screen and (min-width: 500px) and (max-width: 1024px)': {
      width: '100%',
      maxWidth: '100%',
      position: 'absolute',
      '& > div': {
        position: 'relative',
        left: 'auto',
        margin: '0 auto',
      },
    },    
  },
  progressMobile: {
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {
      left: '65%',
    },
  },
  univestSpan:{
    position: 'relative',
    display: 'block',
    width: '320px',
    '& img':{
      maxWidth: '100%',
      display: 'block',
      height: 'auto',
    }
  },
  // inwiryLink:{
  //   position: 'absolute',
  //   top: '0',
  //   width: '50%',
  //   height: '100%',
  //   left: '0px',
  // },
  // univestLink:{
  //   position: 'absolute',
  //   top: '0',
  //   width: '50%',
  //   height: '100%',
  //   right: '0px',
  // }
} );

export default styles;
