import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import Slider from 'react-slick';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import YouTube from 'react-youtube';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import carrerSketchBanner from '../../assets/img/career-sketch/carrer_sketch_banner_img.jpg';
import modulesSlider1 from '../../assets/img/career-sketch/modules_slider_img_1.png';
import modulesSlider2 from '../../assets/img/career-sketch/modules_slider_img_2.png';
import modulesSlider3 from '../../assets/img/career-sketch/modules_slider_img_3.png';
import modulesSlider4 from '../../assets/img/career-sketch/modules_slider_img_4.png';
import modulesSlider5 from '../../assets/img/career-sketch/modules_slider_img_5.png';
import modulesSlider6 from '../../assets/img/career-sketch/modules_slider_img_6.png';
import modulesSlider7 from '../../assets/img/career-sketch/modules_slider_img_7.png';
import modulesSlider8 from '../../assets/img/career-sketch/modules_slider_img_8.png';
import modulesSlider9 from '../../assets/img/career-sketch/modules_slider_img_9.png';
import modulesSlider10 from '../../assets/img/career-sketch/modules_slider_img_10.png';
import modulesSlider11 from '../../assets/img/career-sketch/modules_slider_img_11.png';
import modulesSlider12 from '../../assets/img/career-sketch/modules_slider_img_12.png';
import modulesSlider13 from '../../assets/img/career-sketch/modules_slider_img_13.png';
import modulesSlider14 from '../../assets/img/career-sketch/modules_slider_img_14.png';
import modulesSlider15 from '../../assets/img/career-sketch/modules_slider_img_15.png';
import timelineImgNew from '../../assets/img/career-sketch/timeline_img_new.png';
import CareerSketchCards from './cards';

const GET_AUTH_INFO = loader( '../../graphql/schema/auth/auth-info.graphql' );

const CareerSketch = ( props ) => {
  const { classes, history } = props;

  const apolloClient = useApolloClient();

  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      autoplay: 1,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  const [watchIntro, setWatchIntro] = React.useState( false );

  const { data } = useQuery( GET_AUTH_INFO, {
    onCompleted() {
      const token = localStorage.getItem( 'app-token' );
      if ( token ) {
        apolloClient.writeData( { data: { isLoggedIn: true } } );
      }
    },
  } );

  const linkToPricing = () => {
    if ( !data.isLoggedIn ) {
      history.push( '/pricing' );
    } else {
      history.push( '/timeline-sketcher' );
    }
  };

  const goToStories = ( storyLink ) => {
    if ( !data.isLoggedIn ) {
      // history.push( '/pricing' );
    } else {
      history.push( storyLink );
    }
  };

  const handleWatchIntro = () => {
    setWatchIntro( true );
  };

  const handleWatchIntroClose = () => {
    setWatchIntro( false );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.careerBody }>
        <div className={ classes.careerBannerSec }>
          <div className={ classes.careerBannerImg }>
            <figure>
              <img src={ carrerSketchBanner } alt="" />
            </figure>
          </div>
          <div className={ classes.careerBannerCnt }>
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">
                Career Sketching
                <span>TM</span>
              </Typography>
              <span className={ classes.seeItAction } role="button" aria-hidden="true" onClick={ handleWatchIntro }>
                SEE IT IN ACTION
                {' '}
                <i className="la la-greater-than" />
              </span>
            </div>
          </div>
          <div className={ `${classes.popupContent} ${watchIntro && `${classes.popupContent} ${classes.watchIntroPopup}`}` }>
            <div className={ classes.modalVideoInner }>
              <button type="button" onClick={ handleWatchIntroClose } className={ classes.closeIcon }><i className="la la-close" /></button>
              {watchIntro && <YouTube videoId="54vDPdwMRzE" opts={ youtubeOpts } />}
            </div>
          </div>
        </div>
        <div className={ classes.carrerSketchInfo }>
          <div className={ classes.container }>
            <Typography variant="h3" component="h3">Explore a collection of career sketches,</Typography>
            <p>developed to demonstrate how you can create a roadmap to the future.</p>
          </div>
        </div>

        <CareerSketchCards fromPage="career-sketch" goToStories={ goToStories } />

        <div className={ classes.careerSketchDiscover }>
          <div className={ classes.container }>
            <div className={ classes.discoverRow }>
              <Typography variant="h3" component="h3">
                Discover your best number with Career Sketching
                {' '}
                <span>TM</span>
              </Typography>
              <p className={ classes.discoverRowCnt }>
                <span>How much will my education cost?</span>
                <span>Will my chosen career provide enough income?</span>
                <span>Will I need additional higher education?</span>
                <span>What kind of home will I be able to afford and when?</span>
                <span>When will I be financially independent?</span>
              </p>
              <div className={ classes.discoverBestNumCnt }>
                <ul>
                  <li>
                    <p>Career Sketching can help you find answers to these and other pressing questions as it helps you create a roadmap for the next 5, 10, 15 years.</p>
                    <p><span>Just writing down your goals makes it 42% more likely that you will achieve them – planning with Inkwiry seriously improves your chances of success.</span></p>
                  </li>
                  <li>
                    <p>Career Sketching is the key to honing your goals and aspirations, organizing huge amounts of data, evaluating possible scenarios, exploring &quot;what-if&quot; possibilities and nailing down your best numbers.</p>
                    <p>Experience the confidence that comes with knowing you have a viable plan for navigating the future.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.buildCarrerSketch }>
          <div className={ classes.container }>
            <div className={ classes.buildCarrerSketchCnt }>
              <Typography variant="h3" component="h3">It&apos;s easy to build your own career sketch</Typography>
              <p>
                <span className={ classes.buildText }>Step 1:</span>
                {' '}
                Drag and drop life events to a timeline
              </p>
              <figure>
                <img src={ timelineImgNew } alt="" />
              </figure>
              <p>
                <span className={ classes.buildText }>Step 2:</span>
                {' '}
                Double-click on life events and enter your information in the corresponding modules
              </p>
              <div className={ classes.buildSlider }>
                <Slider { ...settings }>
                  <div>
                    <figure>
                      <img src={ modulesSlider1 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider2 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider3 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider4 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider5 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider6 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider7 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider8 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider9 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider10 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider11 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider12 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider13 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider14 } alt="" />
                    </figure>
                  </div>
                  <div>
                    <figure>
                      <img src={ modulesSlider15 } alt="" />
                    </figure>
                  </div>

                </Slider>
              </div>
              <p>
                <span className={ classes.buildText }>Step 3:</span>
                {' '}
                The Simulator Engine generates your career sketch.
              </p>
              <p>
                <span className={ classes.buildText }>Even easier:</span>
                {' '}
                If one of the sample career sketches is similar to your own goals and aspirations, you can load the sketch into your Inkwiry account, and then customize and make it your own.
              </p>
            </div>
            <div className={ classes.careerSearchDiv }>
              <span role="button" aria-hidden="true" className={ classes.careerSearchBtn } onClick={ linkToPricing }>
                BUILD MY CAREER SKETCH
                {' '}
                <i className="la la-greater-than" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.videoModalPopup } />
      <ScrollToTop />
    </Typography>
  );
};

CareerSketch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CareerSketch );
