import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';

export function homeShowMeGraphData() {
  let graphsData = {};
  const buyingaHomeOutputPieChartData = moduleServices.buyingaHomeOutputBackendGraphData();
  const buyingaHomeData = moduleServices.buyingAHomeCompleteData();
  // console.log("buyingaHomeOutputPieChartData",buyingaHomeOutputPieChartData,buyingaHomeData)

  //Bar Graph1

  if (buyingaHomeOutputPieChartData['Bar Graph']!== undefined && buyingaHomeOutputPieChartData['Bar Graph']['Years List'] !== undefined && buyingaHomeOutputPieChartData['Bar Graph']['Years List'].length > 0) {
    let homeMortPaymentDataValue = Math.max.apply(null, buyingaHomeOutputPieChartData['Bar Graph']['Payment']).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    if (homeMortPaymentDataValue === '-Infinity') {
        homeMortPaymentDataValue = '-';
    }
    let buyingaHomeMonthlyPaymentDataValue = moduleServices.module13Data.moreHighermonthlyPayment.toFixed(0);
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: 1,
          plotShadow: false,
          zoomType: false,events: {
            load() {
              const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(buyingaHomeMonthlyPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
            ).attr({
                zIndex: 5
            }).add(),
            box = text.getBBox();
  
        chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
            .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
            })
            .add();
            },
          },
          // height: moduleGraphHeight,
          // width: graphMainWidth
      },
      navigation: {
          menuItemStyle: {
              fontSize: '14px',
              textAlign: 'left'
          },
          menuItemHoverStyle: {
              background: '#f5f5f5',
              color: '#4c4c4c',
              fontSize: '14px'
          },
          buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              verticalAlign: 'bottom',
              _titleKey: 'y'
          }
      },
      exporting: {
          buttons: {
              contextButton: {
                  menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                          chart.exportChart();
                      },
                  }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/jpeg'
                          });
                      }
                  }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'application/pdf'
                          });
                      }
                  }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/svg+xml'
                          });
                      }
                  }]
              }
          }
      },
      credits: {
          enabled: false
      },
      title: {
          text: ''
      },
      xAxis: [{
          categories: buyingaHomeOutputPieChartData['Bar Graph']['Years List'],
          crosshair: true,
          labels: {
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      yAxis: [{ // Primary yAxis
          labels: {
              formatter() {
                const chart = this;
                  if (chart.value < 0) {
                      return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                  } else {
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          title: {
              text: 'Principal and Interest',
              style: {
                  color: '#000000',
                  fontSize: '14px;'
              }
          },
          opposite: true
      }, { // Secondary yAxis
          gridLineWidth: 0,
          title: {
              text: 'Home Value and Mortgage Balance',
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          },
          labels: {
              formatter() {
                const chart = this;
                if (chart.value < 0) {
                      return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                  } else {
                      return formatDollar(Math.round(chart.value));
                  }
              },
              style: {
                  color: '#000000',
                  fontSize: '14px'
              }
          }
      }],
      tooltip: {},
      legend: {
          itemStyle: {
              fontSize: '14px'
          }
      },
      series: [{
          name: 'Mortgage Balance',
          type: 'column',
          yAxis: 1,
          data: buyingaHomeOutputPieChartData['Bar Graph']['Mortgage Balance'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#C00000'
      }, {
          name: 'Home Value',
          type: 'column',
          yAxis: 1,
          data: buyingaHomeOutputPieChartData['Bar Graph']['Home Value'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#4472C4'
      }, {
          name: 'Principal Payback',
          type: 'spline',
          yAxis: 0,
          data: buyingaHomeOutputPieChartData['Bar Graph']['Principal Payback'],
          tooltip: {
              valuePrefix: '$'
          },
          color: '#548235'
      }, {
          name: 'Interest',
          type: 'spline',
          yAxis: 0,
          data: buyingaHomeOutputPieChartData['Bar Graph']['Interest'],
          marker: {
              enabled: true
          },
          tooltip: {
              valuePrefix: '$'
          },
          color: '#A9D18E'
      },]
  }
  } else {
    graphsData['graph1'] = false;
  }

  if (buyingaHomeOutputPieChartData['Pie Chart'] !== undefined) {

    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
          plotBackgroundColor: '#ffffff',
          plotShadow: false,
          type: 'pie',
          // height: moduleGraphHeight,
          // width: graphMainWidth
      },
      navigation: {
          menuItemStyle: {
              fontSize: '14px',
              textAlign: 'left'
          },
          menuItemHoverStyle: {
              background: '#f5f5f5',
              color: '#4c4c4c',
              fontSize: '14px'
          },
          buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: 'y'
          }
      },
      exporting: {
          buttons: {
              contextButton: {
                  menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                          chart.exportChart();
                      },
                  }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/jpeg'
                          });
                      }
                  }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'application/pdf'
                          });
                      }
                  }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/svg+xml'
                          });
                      }
                  }]
              }
          }
      },
      credits: {
          enabled: false
      },
      title: {
          text: ''
      },
      tooltip: {
          enabled: false
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
        // eslint-disable-next-line
        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                  style: {
                      color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                      fontSize: '14px'
                  }
              }
          }
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
          data: [
              ['Down Payment', buyingaHomeOutputPieChartData['Pie Chart']['Down Payment']],
              ['Closing Costs', buyingaHomeOutputPieChartData['Pie Chart']['Closing Costs']],
              ['Fit Out Costs', buyingaHomeOutputPieChartData['Pie Chart']['Fit Out Costs']]
          ].filter((d)=> {
              return d[1] > 0
          })
      }]
  }
  } else {
    graphsData['graph2'] = false;
  }
 

  if (buyingaHomeData['Closing Cost'] !== undefined) {

    graphsData['graph3'] = true;
    graphsData['graph3Data'] = {
      chart: {
          plotBackgroundColor: '#ffffff',
          plotShadow: false,
          type: 'pie',
          spacingTop: 0,
          // height: moduleGraphHeight,
          // width: graphMainWidth
      },
      navigation: {
          menuItemStyle: {
              fontSize: '14px',
              textAlign: 'left'
          },
          menuItemHoverStyle: {
              background: '#f5f5f5',
              color: '#4c4c4c',
              fontSize: '14px'
          },
          buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: 'y'
          }
      },
      exporting: {
          buttons: {
              contextButton: {
                  menuItems: [{
                      textKey: 'downloadPNG',
                      onclick() {
                        const chart = this;
                          chart.exportChart();
                      },
                  }, {
                      textKey: 'downloadJPEG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/jpeg'
                          });
                      }
                  }, {
                      textKey: 'downloadPDF',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'application/pdf'
                          });
                      }
                  }, {
                      textKey: 'downloadSVG',
                      onclick() {
                        const chart = this;
                          chart.exportChart({
                              type: 'image/svg+xml'
                          });
                      }
                  }]
              }
          }
      },
      credits: {
          enabled: false
      },
      title: {
          text: ''
      },
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
        // eslint-disable-next-line
                  format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                  style: {
                      color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                      fontSize: '14px',
                      width: '200px'
                  }
              },
              showInLegend: false
          }
      },
      legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 20
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
          data: [
              ['Appraisal Fee', buyingaHomeData['Closing Cost']['Appraisal Fee']],
              ['Credit Report Fee', buyingaHomeData['Closing Cost']['Credit Report Fee']],
              ['Title Closing/Escrow Fee', buyingaHomeData['Closing Cost']['Title Closing/Escrow Fee']],
              ['Lenders Title Insurance', buyingaHomeData['Closing Cost']['Lenders Title Insurance']],
              ['Flood Determination', buyingaHomeData['Closing Cost']['Flood Determination']],
              ['Tax Service Fee', buyingaHomeData['Closing Cost']['Tax Service Fee']],
              ['Owners Title Insurance', buyingaHomeData['Closing Cost']['Owners Title Insurance']],
              ['Recording Mortgage', buyingaHomeData['Closing Cost']['Recording Mortgage']],
              ['Homeowner`s Insurance (3 Months prepaid upfront)', buyingaHomeData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)']],
              ['Transfer Taxes', buyingaHomeData['Closing Cost']['Transfer Taxes']],
              ['Processing Fee', buyingaHomeData['Closing Cost']['Processing Fee']],
              ['Underwriting Fee', buyingaHomeData['Closing Cost']['Underwriting Fee']],
              ['Loan Points', buyingaHomeData['Closing Cost']['Loan Acquisition Points']],
              ['Origination Fees', buyingaHomeData['Closing Cost']['Origination Points']],
              ['Mortgage Interest(1month prepaid upfront)', buyingaHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)']],
              ['Property Taxes(3 months prepaid upfront)', buyingaHomeData['Closing Cost']['Property Taxes(3 months prepaid upfront)']],
              ['Wood Destroying Pest Inspection', buyingaHomeData['Closing Cost']['Wood Destroying Pest Inspection']],
              ['Home Owners Association Transfer Fees', buyingaHomeData['Closing Cost']['Home Owners Association Transfer Fees']],
              ['Other', buyingaHomeData['Closing Cost']['Others']]
          ].filter( (d)=> {
              return d[1] > 0
          })
      }]
  }
  } else {
    graphsData['graph3'] = false;
  }

  return graphsData;
}


