import React from "react";
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const NationEarning = (props) => {
    const { resultData } = props;
    const series = [];
    const data1 = [];
    const data2 = [];

        if (resultData.data1.career !== null ) {
            if (resultData['data1'] !== undefined && resultData['data1']['career'] !== undefined) {
                data1.push(parseInt(resultData['data1']['career']['national_low_earnings_10']));
                data1.push(parseInt(resultData['data1']['career']['national_low_earnings_25']));
                data1.push(parseInt(resultData['data1']['career']['national_median_earnings_50']));
                data1.push(parseInt(resultData['data1']['career']['national_high_earnings_25']));
                data1.push(parseInt(resultData['data1']['career']['national_high_earnings_10']));

                series.push({
                    type: 'spline',
                    name: resultData['data1']['state']['title'],
                    data: data1,
                    color: '#0070c0'
                })

            }
        }

        if (resultData.data2.career !== null) {
            if (resultData['data2'] !== undefined && resultData['data2']['career'] !== undefined) {
                data2.push(parseInt(resultData['data2']['career']['national_low_earnings_10']));
                data2.push(parseInt(resultData['data2']['career']['national_low_earnings_25']));
                data2.push(parseInt(resultData['data2']['career']['national_median_earnings_50']));
                data2.push(parseInt(resultData['data2']['career']['national_high_earnings_25']));
                data2.push(parseInt(resultData['data2']['career']['national_high_earnings_10']));
                series.push({
                    type: 'spline',
                    name: resultData['data2']['state']['title'],
                    data: data2,
                    color: '#ed7d31'
                })
            }
        }

    const formatDollar = (num) => {
         var p = num.toFixed(2).split(".");       
        return "$" + p[0].split("").reverse().reduce(function (acc, num, i, orig) {           
            return num === "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "");
    }

    // console.log(formatDollar);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            plotBackgroundColor: '#ffffff',
                            plotBorderColor: '#cccccc',
                            plotBorderWidth: 1,
                            plotShadow: false,
                            type: 'column',
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false
                        },
                        xAxis: {
                            categories: ['Low 10% Earnings', 'Low 25% Earnings', 'Median Earnings', 'Top 25% Earnings', 'Top 10% Earnings'],
                            labels: {
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            }
                        },
                        yAxis: [{ // Primary yAxis
                            min: 0,
                            labels: {
                                formatter() {
                                    const chart = this;                                   
                                    if (chart.value < 0) {
                                        console.log(chart.value);
                                        return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                                    } else {
                                        return formatDollar(Math.round(chart.value));
                                    }
                                },
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            }
                        }],
                        tooltip: {
                            headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                            crosshairs: true,
                            shared: true,
                            valueDecimals: 0,
                            valuePrefix: '$',
                            style: {
                                color: '#000000',
                                fontSize: '13px'
                            }
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal'
                            }
                        },
                        legend: {
                            itemStyle: {
                                fontSize: '14px'
                            }
                        },
                        series: series
                    }
                }
            />
        </div>
    )
}

NationEarning.propTypes = {
    resultData: PropTypes.func.isRequired,
};

export default NationEarning