const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  pwiPuzzle: {
    textAlign: 'center',
    marginTop: '56px',
    '@media (max-width: 767px)': {
      marginTop: '35px',
    },
  },
  puzzleImg: {
    maxWidth: '100px',
    paddingBottom: '20px',
    cursor: 'default',
    [theme.breakpoints.down( 'xs' )]: {
      paddingBottom: '0px',
    },
  },
  pwiTitle: {
    color: theme.palette.common.blue,
    lineHeight: '48px',
    margin: '0',
    padding: '0 0 28px 0',
    fontSize: '44px',
    textAlign: 'center',
    fontFamily: 'MuseoSans-100',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '33px',     
    },
    '@media (max-width: 767px)': {
      padding: '0 0 15px 0',
    },
  },
  pwiSubTitle: {
    color: theme.palette.common.blue,
    fontSize: '30px',
    lineHeight: '30px',
    margin: '0',
    padding: '0 0 55px 0',
    textAlign: 'center',
    fontFamily: 'MuseoSans-100',
    [theme.breakpoints.down( 'sm' )]: {
      lineHeight: '30px',
      fontSize: '25px',
    },
    '@media (max-width: 767px)': {
      lineHeight: '28px',
      fontSize: '20px',
      padding: '0 0 30px 0',
    },
  },
  whyInkwirySeeIf: {
    backgroundColor: '#84a84d',
    padding: '60px',
    maxWidth: '800px',
    margin: '0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px',
    },
    '& h2': {
      color: theme.palette.common.white,
      fontSize: '40px',
      padding: '0 0 28px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '35px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
        paddingBottom: '15px',
      },
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 55px 0',
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '24px',
      },
      '@media (max-width: 767px)': {
        paddingBottom: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {      
       fontSize: '22px',
      },     
    },
  },
  buildMyPlan: {
    textAlign: 'center',
    '& button': {
      backgroundColor: '#fd9840',
      fontSize: '30px',
      color: theme.palette.common.white,
      padding: '14px 45px',
      borderRadius: '5px',
      lineHeight: '24px',
      fontFamily: 'MuseoSans-300',
      textTransform: 'uppercase',
      textDecoration: 'none',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        padding: '14px 25px',
      },
      '&:hover': {
        backgroundColor: '#f28735',
      },
    },
  },
  whyInkwirySeeIfList: {
    listStyle: 'none',
    color: theme.palette.common.blue,
    fontSize: '18px',
    lineHeight: '36px',
    fontFamily: 'MuseoSans-300',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '800px',
    margin: '0 auto',
    justifyContent: 'center',
    padding: '0 0 50px',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      padding: '0 0 0px',
      textAlign: 'center',
    },
    '& li': {
      padding: '6px 26px',
      fontSize: '19px',
      [theme.breakpoints.down( 'sm' )]: {
        display: 'inline-block',
        padding: '3px 10px',
        fontSize: '16px',
      },
    },
  },
  whyInkwryDesCnt: {
    padding: '0 40px 0 60px',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 15px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px',
    },
  },
  whyInkwiryPowerTools: {
    maxWidth: '960px',
    margin: '0 auto',
    padding: '50px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '25px 0',
    },
  },
  powerToolsTitle: {
    fontFamily: 'MuseoSans-100',
    fontSize: '30px',
    lineHeight: '30px',
    margin: '0',
    padding: '0 0 27px 0',
    textAlign: 'center',
    color: theme.palette.common.blue,
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '25px',
    },
  },
  powerTools: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      paddingBottom: '5px',
      cursor: 'default',
    },
    '& p': {
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '27px',
      padding: '6px 0 0 0',
      fontFamily: 'MuseoSans-100',
      margin: '0 auto',
      color: theme.palette.common.black,
    },
  },
  powerToolsRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  inkwiryTogether: {
    padding: '70px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '40px 0',
    },
    '@media (max-width: 767px)': {
      padding: '0px'
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0',
    },
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '44px',
      lineHeight: '48px',
      margin: '0',
      padding: '0 0 28px 0',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '35px',
        lineHeight: '40px',
        padding: '0 0 15px 0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        lineHeight: '30px',
        padding: '0 0 15px 0',
      },
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 55px 0',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      '@media (max-width: 767px)': {
        padding: '0 0 30px 0',
      },      
    },
  },
  studentsParentsBtn: {
    fontSize: '36px',
    lineHeight: '50px',
    borderRadius: '5px',
    color: theme.palette.common.white,
    margin: ' 20px 0 0 0',
    padding: '20px 50px',
    backgroundColor: '#f48726',
    borderColor: '#f48726',
    textDecoration: 'none',
    display: 'inline-block',
    '& span': {
      width: '100%',
      display: 'block',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '26px',
      lineHeight: '35px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 30px',
      fontSize: '18px',
      lineHeight: '35px',
    },
    '&:hover': {
      backgroundColor: '#fd9840',
    },
  },
  benfitsBanner: {
    background: theme.palette.common.blue,
    padding: '65px 0',
    position: 'relative',
    zIndex: '9',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '45px 0px',
    },
    '&:before': {
      content: '""',
      background: 'rgba(51, 51, 51, 0.2)',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      zIndex: '-1',
    },
  },
  justyfyCenter: {
    justifyContent: 'center',
  },
  bannerLeft: {
    padding: '0px 35px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 20% 0px 20%',
    },
    '& img': {
      width: '100%',
    },
  },
  bannerRight: {
    padding: '0',
    '& h1': {
      lineHeight: '60px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',     
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '28px',
        lineHeight: '40px',
      },
      '@media (max-width: 767px)': {
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
  },
  whyInkwiryDesc: {
    padding: '0 0 58px 0',
  },
  powerToolsSpace: {
    padding: '0px 10px !important',
  },
} );

export default styles;
