const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  popupAnswerQuestionsBox: {
    position: 'relative',
    textAlign: 'center',
    '& > div':{
      display: 'inline-block',
    },
    '& > div > div':{
      margin: '0px auto',
      transform: 'scale(0.85)',
      marginTop: '40px',
      maxWidth: '640px',
      transformOrigin: 'top',
      display: 'inline-block',
      maxHeight: 'calc(100% - -200px)',
      background: 'transparent',
      '@media (max-width: 1366px)': {
        transform: 'scale(0.73)',
      },
      '@media (max-width: 1280px)': {
        transform: 'scale(0.70)',
      },
      '@media (min-width: 500px) and (max-width: 767px)': {
        maxHeight: 'calc(100% - -60px)',
      }
    }
  },
  popupAnswerQuestions:{
    padding: '0px !important',
    overflowY: 'initial',
  },
  popupHearfromyouHead:{
    border: '0',
    padding: '0',
    position: 'relative',
    lineHeight: '0px',
    '& h2':{
      fontSize: '45px',
      lineHeight: '48px',
      color: '#fff',
      fontFamily: '"MuseoSans-300"',
      position: 'absolute',
      top: '30%',
      left: '50px',
      marginTop: '20px',
      marginBottom: '10px',
      '@media only screen and (max-width: 767px)': {
        top: '0',
        fontSize: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        left: '0px',
        width: 'calc(100% - 40px)',
        padding: '0px 20px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: '0px',
        justifyContent: 'center',
      },
    },
    '& img':{
      width: '100%',
    }
  },
  btnClose:{
    position: 'absolute',
    right: '10px',
    color: '#fff',
    zIndex: '99',
    opacity: '1',
    top: '10px',
    fontWeight: '400',
    padding: '0px',
    '&:hover':{
      backgroundColor: 'transparent',
    }
  },
  popupHearfromyouBody:{
    padding: '30px 50px 20px',
    fontFamily: '"MuseoSans-100"',
    color: '#333333',
    textAlign: 'left',
    background: '#fff',
    '@media only screen and (max-width: 767px)': {
      padding: '10px 20px 10px',
    },
    '& h3':{
      fontFamily: '"MuseoSans-300"',
      fontSize: '30px',
      lineHeight: '45px',
      color: '#0069aa',
      margin: '10px 0',
      '@media only screen and (max-width: 767px)': {
        fontSize: '20px',
      }
    },
    '& p':{
      fontSize: '18px',
      lineHeight: '27px',
      padding: '5px 0 20px',
      margin: '0px',
      '@media only screen and (max-width: 767px)': {
        fontSize: '14px',
        lineHeight: '20px',
      }
    },
    '& ul':{
      listStyle: 'none',
      fontSize: '30px',
      lineHeight: '30px',
      padding: '0',
      margin: '0px',
      '& li':{
        fontSize: '30px',
        lineHeight: '27px',
        '@media only screen and (max-width: 767px)': {
          fontSize: '18px',
          display: 'flex',
          alignItems: 'start',
          lineHeight: '23px',
        },
        '& label':{
          marginLeft: '5px',
          marginRight: '5px',
          position: 'relative',
          top: '-1px',
           '& h5':{
             margin:'0',
             fontSize: '18px',
            fontWeight: 'normal',
            lineHeight: '27px',
           },
          '@media only screen and (max-width: 767px)': {
            // width: '18px',
            position: 'relative',
            top: '2px',
            marginRight: '10px',
          },
          '& span':{
            padding: '0px',
            '& svg':{
              width: '18px',
              height: '18px',
            },
            '& input':{
              marginRight: '10px',
            }
          },
          '& > span':{
            '&:first-child':{
              marginRight: '10px',
            }
          }
        },
        '& > span':{
          display: 'inline-block',
          width: 'calc(100% - 14px)',
        },
        // '& input[type=checkbox]':{
        //   marginRight: '10px',
        // }
      }
    },
    '& textarea':{
      height: '15px',
      overflow: 'hidden',
      width: '100%',
      maxWidth: 'calc(100% - 75px)',
      marginLeft: '24px',
      border: '1px solid gray',
      padding: '5px',
      marginTop: '13px',
      height: '85px !important',
      fontSize: '18px',
    },
    '& form':{
      margin: '0px 25px',
      '@media only screen and (max-width: 767px)': {
        margin: '0px',
      },
      '& div':{
        backgroundColor: '#fff !important',
        '&:hover':{
          backgroundColor: '#fff',
        },
        '&:before':{
          display: 'none',
        },
        '&:after':{
          display: 'none',
        }
      },
      '& input':{
        fontSize: '20px',
        height: '40px',
        lineHeight: '38px',
        border: '1px solid #0069aa',
        borderRadius: '10px !important',
        fontFamily: '"MuseoSans-100"',
        padding: '0px 20px',
        marginBottom: '15px',
      }
    }
  },
  errorMsg:{
    padding: '15px 0px 5px !important',
    color: '#bf0000',
    textAlign: 'center',
  },
  btnGroup:{
      width:'88%',
     display:'flex',
     justifyContent:'space-between',
     marginLeft: '5%',
  },
  forSignup:{
    width: '100%',
    margin:'0',
  },
  btnNext:{
    textAlign: 'center',
    '& button':{
      background: '#fd9840 none repeat scroll 0 0',
      color: '#fff',
      padding: '8px 25px',
      border: '0',
      borderRadius: '10px',
      fontFamily: '"MuseoSans-300"',
      margin: 'auto',
      display: 'table',
      fontSize: '30px',
      minWidth: '220px',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover':{
        background: '#fd9840 none repeat scroll 0 0',
      },
      '@media only screen and (max-width: 767px)': {
        minWidth: 'initial',
        fontSize: '20px',
        maxWidth: '150px',
      }
    }
  },
  btnBack:{
    textAlign: 'center',
    '& button':{
      // background: 'transparent none repeat scroll 0 0',
      background: '#0069AA none repeat scroll 0 0',
      // color: '#0069AA',
      color:'white',
      outline:'#0069AA',
      padding: '8px 25px',
      border: '0',
      borderRadius: '10px',
      fontFamily: '"MuseoSans-300"',
      margin: 'auto',
      display: 'table',
      fontSize: '30px',
      minWidth: '220px',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover':{
        color:'white',
        background: '#0969a5 none repeat scroll 0 0',
      },
      '@media only screen and (max-width: 767px)': {
        minWidth: 'initial',
        fontSize: '20px',
        maxWidth: '150px',
      }
    }
  },
  btnSignup:{
    textAlign: 'center',
    marginTop: '20px',
    display: 'inline-block',
    width: '100%',
    '& button':{
      background: '#fd9840 none repeat scroll 0 0',
      color: '#fff',
      padding: '5px 25px',
      border: '0',
      borderRadius: '10px',
      fontFamily: '"MuseoSans-300"',
      margin: 'auto',
      display: 'table',
      fontSize: '30px',
      minWidth: '220px',
      textTransform: 'uppercase',
      cursor: 'pointer',
      marginTop: '20px',
      '&:hover':{
        background: '#fd9840 none repeat scroll 0 0',
      },
      '@media only screen and (max-width: 767px)': {
        minWidth: 'initial',
        fontSize: '20px',
        maxWidth: '150px',
      }
    }
  },
  paraSecond:{
    lineHeight: '45px',

  },
  paraThird: {
    padding: '10px 0 70px !important',
  },
  halfOne: {
    width: '47%',
    display: 'inline-block',
    float: 'left',
  },
  halfTwo: {
    width: '47%',
    display: 'inline-block',
    float: 'right',
  },
  halfFull:{
    width: '100%',
    margin: '5px 0 30px',
    display: 'inline-block',
    '& div':{
      background: '#fff',
    }
  },
  textSmall:{
    fontSize: '12px !important',
    lineHeight: '18px !important',
    fontFamily: '"MuseoSans-100"',
    textAlign: 'center',
    paddingTop: '25px !important',
  },
  textareaTwo:{
    marginTop: '35px !important',
    marginBottom: '20px !important',
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      maxWidth: '640px',
      transform: 'scale(0.70) !important',
      transformOrigin: 'top',
      display: 'inline-block',
      textAlign: 'left',
      margin: '0px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      },
      '@media (max-width: 1366px)': {
        transform: 'scale(0.73)',
      },
      '@media (max-width: 1280px)': {
        transform: 'scale(0.70)',
      },
    },
    '& > div > div > div': {
      padding: '0px',
      paddingTop: '0px !important',
    }
  },
  popupHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    '& h4': {
      fontSize: '18px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      fontSize: '20px',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  closeBtn:{
    marginTop: '-2px',
    display: 'inline-block',
  },
  popupContent: {
    padding: '35px',
    '& p': {
      padding: '0 0 18px 0',
      fontSize: '22px',
      margin: '0',
      color: '#000',
      fontFamily: 'MuseoSans-300',
      lineHeight: '30px',
      textAlign: 'center',
    },
  },
  successicon:{
    display: 'block',
    width: '150px',
    height: '150px',
    background: '#fff',
    border: '4px solid #008000',
    borderRadius: '100%',
    position: 'relative',
    margin: '0 auto 30px auto',
    '&:before':{
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    }
  }
 } );

export default styles;
