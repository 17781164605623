import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import $ from 'jquery';
import _ from 'lodash';
import styles from './styles';
// import clearChange from '../../../../assets/img/build-career-sketch/clear-timeline-blue.png';
// import loadChange from '../../../../assets/img/build-career-sketch/icon-load-blue.png';
import * as modulesServices from '../../../../calculations/modules-services';
import { defaultValues } from '../../../../calculations/default-module-values';

const displayValues = {
  module1: {
    text: "Begin here! Just enter your 'Start Year'.",
    mainClass: 'timelineFi',
    title: 'Financial Independence',
    link: '/plan-with-inkwiry/fi',
  },
  module2: {
    text: 'Which form of education might be best for you?',
    mainClass: 'timelineHe',
    title: 'Higher Education',
    link: '/plan-with-inkwiry/he',
  },
  module4: {
    text: 'Will your chosen career provide enough income?',
    mainClass: 'timelineCp',
    title: 'Carrer Path',
    link: '/plan-with-inkwiry/cp',
  },
  module5: {
    text: 'What&apos;s your next step on the career ladder?',
    mainClass: 'timelineCa',
    title: 'Career Advancement',
    link: '/plan-with-inkwiry/ca',
  },
  module6: {
    text: 'Will additional education benefit your career?',
    mainClass: 'timelineMhe',
    title: 'More <br/> Higher Education',
    link: '/plan-with-inkwiry/mhe',
  },
  module8: {
    text: 'Is this your next big move? Risk/reward?',
    mainClass: 'timelineAca',
    title: 'Additional <br/> Career Advancement',
    link: '/plan-with-inkwiry/aca',
  },
  module9: {
    text: 'How might your savings work harder for you?',
    mainClass: 'timelineInvest',
    title: 'Investments',
    link: '/plan-with-inkwiry/investments',
  },
  module10: {
    text: 'See benefits and expenses of planning a family.',
    mainClass: 'timelineFamily',
    title: 'Family',
    link: '/plan-with-inkwiry/family',
  },
  module11: {
    text: 'Plan for trips and other things you dream about.',
    mainClass: 'timelineVacation',
    title: 'Vacation + <br/> Special Purchases',
    link: '/plan-with-inkwiry/vacation',
  },
  module12: {
    text: 'Analyze the expense and financing options.',
    mainClass: 'timelineCar',
    title: 'Buying A Car',
    link: '/plan-with-inkwiry/car',
  },
  module13: {
    text: 'Crunch the numbers and plan for home ownership.',
    mainClass: 'timelineHome',
    title: 'Buying A Home',
    link: '/plan-with-inkwiry/home',
  },
  module14: {
    text: 'Quickly evaluate real estate investments.',
    mainClass: 'timelineRei',
    title: 'REI',
    link: '/plan-with-inkwiry/rei',
  },
  module15: {
    text: 'Will additional education benefit your career?',
    mainClass: 'timelineFhe',
    title: 'Further <br/>Higher Education',
    link: '/plan-with-inkwiry/fhe',
  },
  module17: {
    text: 'Is this your next big move? Risk/reward?',
    mainClass: 'timelineFca',
    title: 'Further <br/>Career Advancement',
    link: '/plan-with-inkwiry/fca',
  },
  module18: {
    text: 'Existing Student Loans, Credit Card Debt, Car, and Home',
    mainClass: 'timelineExisting',
    title: 'Existing <br/>Assets & Liabilities',
    link: '/plan-with-inkwiry/existing',
  },
};

const TimeLineList = (props) => {
  const {
    classes, timeLineData,
  } = props;

  const [showDetails, setShowDetails] = React.useState({});

  const stayDetails = (type) => {
    if (type === 'stay') {
      $('#show_time_line_details').css('display', 'block');
    } else {
      $('#show_time_line_details').css('display', 'none');
    }
  };

  const currencyFormat = (num) => {
    if (num !== undefined) {
      return `$${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
    }
    return '$0';
  };

  const openModuleDetail = (moduleData, position) => {
    const showData = { display: true };
    if (moduleData.action === 'CS') {
      showData.display = false;
    }
    showData.module = moduleData.selected_module;
    showData.details = {};
    showData.details.value1 = '&nbsp;';
    showData.details.value2 = '&nbsp;';
    showData.details.value3 = '&nbsp;';
    showData.details.value4 = '&nbsp;';
    showData.details.value5 = '&nbsp;';
    switch (moduleData.selected_module) {
      case 'module1': {
        showData.details.value1 = `Income Growth: ${parseFloat(modulesServices.module1Data.long_term_growth_in_percentage).toFixed(2)}%`;
        showData.details.value2 = `Inflation: ${parseFloat(modulesServices.module1Data.inflation_in_percentage).toFixed(2)}%`;
        // showData.details.value3 = `Cash: ${currencyFormat(modulesServices.module1Data.current_cash_balance)}`;
        showData.details.value4 = '&nbsp;';
        showData.details.value5 = '&nbsp;';
        break;
      }
      case 'module2': {
        const educationTypes = defaultValues.typeOfEducation;
        const educationIndex = _.findIndex(educationTypes, { value: modulesServices.module2Data.type_of_higher_education });
        if (educationIndex > 0) {
          showData.details.value1 = educationTypes[educationIndex].label;
        }
        showData.details.value2 = modulesServices.module2Data.name_of_college;
        showData.details.value3 = `Tuition: ${currencyFormat(modulesServices.module2Data.tuition)}`;
        showData.details.value4 = `Total CoA: ${currencyFormat(modulesServices.totalCostOfAttendance())}`;
        const value5 = modulesServices.fedSubBeginningBalance() + modulesServices.fedUnSubPrincipal() + modulesServices.privatePrincipal();
        showData.details.value5 = `Student Loans: ${currencyFormat(value5)}`;
        break;
      }
      case 'module4': {
        showData.details.value1 = modulesServices.module4Data.occupation;
        showData.details.value2 = modulesServices.module4Data.city;
        const totalIncome = modulesServices.module4Data.yearly_income + modulesServices.module4Data.bonus_or_tips_or_commission;
        showData.details.value3 = `Income: ${currencyFormat(totalIncome)}`;
        showData.details.value4 = `Supp. Income: ${currencyFormat(modulesServices.module4Data.second_yearly_income)}`;
        showData.details.value5 = `Living Expenses: ${currencyFormat(modulesServices.totalMonthlyLivingExpenses())}`;
        break;
      }
      case 'module5': {
        showData.details.value1 = modulesServices.module5Data.occupation;
        showData.details.value2 = modulesServices.module5Data.city;
        const totalIncome = modulesServices.module5Data.yearly_income + modulesServices.module5Data.bonus_or_tips_or_commission;
        showData.details.value3 = `Income: ${currencyFormat(totalIncome)}`;
        showData.details.value4 = `Supp. Income: ${currencyFormat(modulesServices.module5Data.second_yearly_income)}`;
        showData.details.value5 = `Living Expenses: ${currencyFormat(modulesServices.advanceTotalMonthlyLivingExpenses())}`;
        break;
      }
      case 'module6': {
        const educationTypes = defaultValues.typeOfEducation;
        const educationIndex = _.findIndex(educationTypes, { value: modulesServices.module6Data.type_of_higher_education });
        if (educationIndex > 0) {
          showData.details.value1 = educationTypes[educationIndex].label;
        }
        showData.details.value2 = modulesServices.module6Data.name_of_college;
        showData.details.value3 = `Tuition: ${currencyFormat(modulesServices.module6Data.tuition)}`;
        showData.details.value4 = `Total CoA: ${currencyFormat(modulesServices.moreHighertTotalCostOfAttendance())}`;
        const value5 = modulesServices.moreFedSubBeginningBalance() + modulesServices.moreFedUnSubPrincipal() + modulesServices.morePrivatePrincipal();
        showData.details.value5 = `Student Loans: ${currencyFormat(value5)}`;
        break;
      }
      case 'module8': {
        showData.details.value1 = modulesServices.module8Data.occupation;
        showData.details.value2 = modulesServices.module8Data.city;
        const totalIncome = modulesServices.module8Data.yearly_income + modulesServices.module8Data.bonus_or_tips_or_commission;
        showData.details.value3 = `Income: ${currencyFormat(totalIncome)}`;
        showData.details.value4 = `Supp. Income: ${currencyFormat(modulesServices.module8Data.second_yearly_income)}`;
        showData.details.value5 = `Living Expenses: ${currencyFormat(modulesServices.livingTotalMonthlyLivingExpenses())}`;
        break;
      }
      case 'module9': {
        if (modulesServices.module9Data.short_term_start_year > 0) {
          showData.details.value1 = `Short-Term: ${modulesServices.module9Data.short_term_start_year}`;
        }
        if (modulesServices.module9Data.short_term_annual_contribution > 0) {
          showData.details.value2 = `ST Monthly Contribution: ${currencyFormat(modulesServices.module9Data.short_term_annual_contribution / 12)}`;
        }
        if (modulesServices.module9Data.long_term_start_year > 0) {
          showData.details.value3 = `Long-Term: ${modulesServices.module9Data.long_term_start_year}`;
        }
        if (modulesServices.module9Data.long_term_annual_contribution > 0) {
          showData.details.value4 = `LT Monthly Contribution: ${currencyFormat(modulesServices.module9Data.long_term_annual_contribution / 12)}`;
        }
        break;
      }
      case 'module10': {
        if (moduleData.startYear !== undefined && parseInt(moduleData.startYear, 10) > 0) {
          showData.details.value1 = `Income: ${currencyFormat(modulesServices.preTaxExpectedIncome())}`;
          showData.details.value2 = `Spouse's Loans: ${currencyFormat(modulesServices.module10Data.spouse_student_loan)}`;
          if (modulesServices.sumOfFamilyMultiplierCalc() > 0) {
            showData.details.value3 = `Living Expenses: ${currencyFormat(modulesServices.sumOfFamilyMultiplierCalc())}`;
          }
          showData.details.value4 = `# of Children: ${modulesServices.module10Data.number_of_children}`;
        }
        if (modulesServices.module10Data.year_of_purchase > 0 && modulesServices.module10Data.pet_name !== '') {
          showData.details.value5 = `${modulesServices.module10Data.pet_name}: ${currencyFormat(modulesServices.module10Data.purchase_price)}`;
        }
        break;
      }
      case 'module11': {
        if (modulesServices.module11Data.start_year > 0) {
          if (modulesServices.module11Data.yearly_cost > 0) {
            showData.details.value1 = `Vacation: ${currencyFormat(modulesServices.module11Data.yearly_cost)}`;
          }
          if (modulesServices.module11Data.second_yearly_cost > 0) {
            showData.details.value2 = `${modulesServices.module11Data.other_acquisition1}: ${currencyFormat(modulesServices.module11Data.second_yearly_cost)}`;
          }
          if (modulesServices.module11Data.third_yearly_cost > 0) {
            showData.details.value3 = `${modulesServices.module11Data.other_acquisition2}: ${currencyFormat(modulesServices.module11Data.third_yearly_cost)}`;
          }
          if (modulesServices.vacationTotalAmountSpent() > 0) {
            showData.details.value4 = `Total Spent: ${currencyFormat(modulesServices.vacationTotalAmountSpent())}`;
          }
        }
        break;
      }
      case 'module12': {
        if (modulesServices.totalPurchasePrice() > 0) {
          showData.details.value1 = `Car #1: ${modulesServices.module12Data.purchase_year}`;
          showData.details.value1 += `<br/>Net Price: ${currencyFormat(modulesServices.totalPurchasePrice())}`;
        } else {
          showData.details.value1 = '&nbsp;';
        }
        if (modulesServices.moreTotalPurchasePrice() > 0) {
          showData.details.value2 = `Car #2: ${modulesServices.module12Data.second_purchase_year}`;
          showData.details.value2 += `<br/>Net Price: ${currencyFormat(modulesServices.moreTotalPurchasePrice())}`;
        } else {
          showData.details.value2 = '&nbsp;';
        }
        if (modulesServices.module12Data.leasing_car_start_year > 0) {
          showData.details.value3 = `Lease #1:: ${modulesServices.module12Data.leasing_car_start_year}`;
          if (modulesServices.leasingCarTotalMonthlyExpenses() > 0) {
            showData.details.value3 += `<br />Monthly Expenses: ${currencyFormat(modulesServices.leasingCarTotalMonthlyExpenses())}`;
          }
        } else {
          showData.details.value3 = '&nbsp;';
        }
        if (modulesServices.module12Data.leasing_second_car_start_year > 0) {
          showData.details.value4 = `Lease #2:: ${modulesServices.module12Data.leasing_second_car_start_year}`;
          if (modulesServices.leasingSecondCarTotalMonthlyExpenses() > 0) {
            showData.details.value4 += `<br />Monthly Expenses: ${currencyFormat(modulesServices.leasingSecondCarTotalMonthlyExpenses())}`;
          }
        } else {
          showData.details.value4 = '&nbsp;';
        }
        break;
      }
      case 'module13': {
        showData.details.value1 = `Purchase Price: ${currencyFormat(modulesServices.module13Data.purchase_price)}`;
        showData.details.value2 = `Mortgage: ${currencyFormat(modulesServices.Mortage())}`;
        if (modulesServices.moreHighermonthlyPayment() > 0) {
          showData.details.value3 = `Mortgage P&I Pmt:: ${currencyFormat(modulesServices.moreHighermonthlyPayment())}`;
        } else {
          showData.details.value3 = '&nbsp;';
        }
        if (modulesServices.totalMonthlyExpenses() !== 0) {
          showData.details.value4 = `Total Monthly Expenses: ${currencyFormat(modulesServices.totalMonthlyExpenses())}`;
        } else {
          showData.details.value4 = '&nbsp;';
        }
        if (modulesServices.debtToIncomeRatio() !== 0) {
          showData.details.value5 = `Debt-to-Income: ${currencyFormat(modulesServices.debtToIncomeRatio())}`;
        } else {
          showData.details.value5 = '&nbsp;';
        }
        break;
      }
      case 'module14': {
        if (modulesServices.module14Data.year_of_purchase > 0) {
          showData.details.value1 = `Purchase Price: ${currencyFormat(modulesServices.module14Data.purchase_price)}`;
        }
        if (modulesServices.realEstateMortage() > 0) {
          showData.details.value2 = `Mortgage: ${currencyFormat(modulesServices.realEstateMortage())}`;
        }
        if (modulesServices.IRRCal() > 0) {
          showData.details.value5 = `IRR: ${parseFloat(modulesServices.IRRCal()).toFixed(1)}%`;
        }
        if (modulesServices.realEstateMonthlyPayment() !== 0) {
          showData.details.value3 = `Mortgage P&I Pmt: ${currencyFormat(modulesServices.realEstateMonthlyPayment())}`;
        }
        if (modulesServices.reiTotalMonthlyExpenses() !== 0) {
          showData.details.value4 = `Total Monthly Expenses: ${currencyFormat(modulesServices.reiTotalMonthlyExpenses())}`;
        }
        break;
      }
      case 'module15': {
        const educationTypes = defaultValues.typeOfEducation;
        const educationIndex = _.findIndex(educationTypes, { value: modulesServices.module15Data.type_of_higher_education });
        if (educationIndex > 0) {
          showData.details.value1 = educationTypes[educationIndex].label;
        }
        showData.details.value2 = modulesServices.module15Data.name_of_college;
        showData.details.value3 = `Tuition: ${currencyFormat(modulesServices.module15Data.tuition)}`;
        showData.details.value4 = `Total CoA: ${currencyFormat(modulesServices.newTotalCostOfAttendance())}`;
        const value5 = modulesServices.newFedSubBeginningBalance() + modulesServices.newFedUnSubPrincipal() + modulesServices.newPrivatePrincipal();
        showData.details.value5 = `Student Loans: ${currencyFormat(value5)}`;
        break;
      }
      case 'module17': {
        showData.details.value1 = modulesServices.module17Data.occupation;
        showData.details.value2 = modulesServices.module17Data.city;
        const totalIncome = modulesServices.module17Data.yearly_income + modulesServices.module17Data.bonus_or_tips_or_commission;
        showData.details.value3 = `Income: ${currencyFormat(totalIncome)}`;
        showData.details.value4 = `Supp. Income: ${currencyFormat(modulesServices.module17Data.second_yearly_income)}`;
        showData.details.value5 = `Living Expenses: ${currencyFormat(modulesServices.furtherlivingTotalMonthlyLivingExpenses())}`;
        break;
      }
      case 'module18': {
        if (modulesServices.existingStudentLoansSummaryTotalBeginningBalance() > 0) {
          showData.details.value1 = `Student Loans: ${currencyFormat(modulesServices.existingStudentLoansSummaryTotalBeginningBalance())}`;
        }
        if (modulesServices.module18Data.existing_credit_card_balance > 0) {
          showData.details.value2 = `Credit Card: ${currencyFormat(modulesServices.module18Data.existing_credit_card_balance)}`;
        }
        if (modulesServices.module18Data.existing_car_current_value > 0) {
          showData.details.value3 = `Car Value: ${currencyFormat(modulesServices.module18Data.existing_car_current_value)}`;
        }
        if (modulesServices.existingCarLoansTotalMonthlyExpenses() > 0) {
          showData.details.value3 += `<br/>Monthly Expenses =  ${currencyFormat(modulesServices.existingCarLoansTotalMonthlyExpenses())}`;
        }
        if (modulesServices.module18Data.existig_home_current_value > 0) {
          showData.details.value4 = `Home Value: ${currencyFormat(modulesServices.module18Data.existig_home_current_value)}`;
        }
        if (modulesServices.module18Data.existig_home_current_mortgage_balance > 0) {
          showData.details.value5 = `Mortgage: ${currencyFormat(modulesServices.module18Data.existig_home_current_mortgage_balance)}`;
        }
        if (modulesServices.existingHomeMortgageMonthlyPayment() > 0) {
          showData.details.value5 += `<br />Monthly Expenses: ${currencyFormat(modulesServices.existingHomeMortgageMonthlyPayment())}`;
        }
        break;
      }
      default:
        break;
    }
    if (moduleData.startYear !== undefined && parseInt(moduleData.startYear, 10) > 0 && moduleData.endYear !== undefined && parseInt(moduleData.endYear, 10) > 0) {
      showData.details.value6 = `${moduleData.startYear} - ${moduleData.endYear}`;
      showData.details.value7 = parseInt(moduleData.endYear, 10) - parseInt(moduleData.startYear, 10) + 1;
    } else if (moduleData.startYear !== undefined && parseInt(moduleData.startYear, 10) > 0) {
      showData.details.value6 = moduleData.startYear;
      showData.details.value7 = '&nbsp;';
    } else {
      showData.details.value6 = '&nbsp;';
      showData.details.value7 = '&nbsp;';
    }
    const ulPosition = $('#drop-area').position();
    const data = $(`#drop-area li:nth-child(${position + 1})`).position();

    let { left } = data;
    const index = $(`#drop-area li:nth-child(${position + 1})`).index('#drop-area li');
    left += ulPosition.left;
    if (index === 0) {
      left -= 50;
    } else {
      left -= 90;
    }
    setShowDetails(showData);
    $('#show_time_line_details').css({ display: 'block', left: (`${left}px`) });
  };


  const displayData = (moduleData, position) => {
    let yearText = '';
    if (moduleData.startYear !== '' && parseInt(moduleData.startYear, 10) > 0) {
      yearText = moduleData.startYear;
      if (moduleData.endYear !== '' && parseInt(moduleData.endYear, 10) > 0) {
        yearText += ` - ${moduleData.endYear}`;
      }
    }
    let fixedClass = false;
    if (parseInt(moduleData.scenario_id, 10) > 0 || moduleData.selected_module === 'module1') {
      fixedClass = true;
    }
    return (
      <li onMouseLeave={() => { stayDetails('leave'); }} onMouseEnter={() => { openModuleDetail(moduleData, position); }} data-actionfrom={moduleData.action} data-startYear={moduleData.startYear} data-endYear={moduleData.endYear} data-module={moduleData.selected_module} data-scenarioid={moduleData.scenario_id} className={classNames(classes[displayValues[moduleData.selected_module].mainClass], fixedClass === true && 'fixed')}>

        <span>{displayValues[moduleData.selected_module].text}</span>
        <div className={classes.timelineDetailsContent}>
          <div className={classes.timelineDetails}>
            <h2 dangerouslySetInnerHTML={{ __html: displayValues[moduleData.selected_module].title }} />
            <p>{yearText}</p>
          </div>
        </div>
      </li>
    );
  };
  return (
    <div className="career_sketch_timeline_in">
      <div style={{ display: 'none' }} id="show_time_line_details" className={classes.timelineHoverDeatils} onMouseEnter={() => { stayDetails('stay'); }} onMouseLeave={() => { stayDetails('leave'); }}>
        {showDetails.display !== undefined && showDetails.display === true ? (
          <div>
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value1 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value2 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value3 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value4 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value5 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value6 }} />
            <h6 dangerouslySetInnerHTML={{ __html: showDetails.details.value7 }} />
          </div>
        ) : (
          <h4>
            You can give the module details in the next levels.
          </h4>
        )}
      </div>
      <div className={classes.dropableArea}>
        <ul id="drop-area" className={classes.dropArea}>
          
          {timeLineData.map((data, key) => displayData(data, key))}
        </ul>
        {console.log("timeLineData",timeLineData)}
      </div>
    </div>
  );
};

TimeLineList.propTypes = {
  classes: PropTypes.object.isRequired,
  timeLineData: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  manageModule: PropTypes.func,
};

TimeLineList.defaultProps = {
  manageModule: null,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(TimeLineList);
