import React, { useState } from 'react'
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Button } from '@material-ui/core';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe
} from 'react-stripe-elements';
import ReactTooltip from 'react-tooltip';
import styles from './styles';
import LockIcon from '../../assets/img/register/lock_icon.png';
import trustedsite from '../../assets/img/register/trustedsite.png';
import CardOne from '../../assets/img/register/visa_img.png';
import CardTwo from '../../assets/img/register/master_img.png';
import CardThree from '../../assets/img/register/amex_img.png';
import CardFour from '../../assets/img/register/last_card_img.png';
import Pageloader from '../../components/ui/pageloader';
import infoIcon from '../../assets/img/info-icon.svg';

const STATES_DETAILS = loader('../../graphql/schema/auth/list-of-states.graphql');
const UPDATE_CARD = loader('../../graphql/schema/my-account/update-card.graphql');
const BILLINGINFO = loader('../../graphql/schema/my-account/billing-info.graphql');

const UpdateBillingInfo = (props) => {

  const { classes, stripe, billinginfo, setEditDetails, setOpen } = props;

  const { data: stateDetails } = useQuery(STATES_DETAILS);

  const { data: billinginfoData } = useQuery(BILLINGINFO, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      setCardData(billinginfoData && billinginfoData.getBillingDetails.card_details);
      setLoading(false);
    }
  });

  const [updateCard] = useMutation(UPDATE_CARD, {
    onCompleted() {
      setLoading(false);
      billinginfo();
      setEditDetails(false);
      setOpen(true);
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  const [haserror, setHaserror] = useState('');
  const [cardData, setCardData] = useState('');
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [cardType, setCardType] = useState(false);
  const [stripeTokenError, setStripeTokenError] = useState('');
  const [cardNumberStatus, setCardNumberStatus] = useState(false);
  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState('');
  const [cvvStatus, setCvvStatus] = useState(false);
  const [expiryStatus, setExpiryStatus] = useState(false);
  const [loading, setLoading] = useState(true);


  const validate = (values) => {
    const errors = {};

    if (!values.state) {
      if (submitClicked) {
        setStateError(true);
      }
      errors.state = 'State is Required';
    } else {
      setStateError(false);
    }
    if (!values.address1) {
      errors.address1 = 'street address is required.';
    }
    if (!values.address2) {
      errors.address2 = ' address is required.';
    }
    if (!values.city) {
      errors.city = 'city is required.';
    }
    if (!values.zip_code) {
      errors.zip_code = 'zipcode is required.';
    }
    if (cardNumberStatus === true && cvvStatus === true && expiryStatus === true) {
      if (!values.cardHolderName) {
        errors.cardHolderName = 'card holder name is required.';
      }
    }
    if (!values.country) {
      if (submitClicked) {
        setCountryError(true);
      }
      errors.country = 'Country is Required';
    } else {
      setCountryError(false);
    }

    return errors;
  };

  const handlenumber = (e) => {
    setCardType(e.brand);
    if (e.complete === true) {
      setCardNumberStatus(true);
      setCardNumberErrorMsg('');
    } else {
      setCardNumberStatus(false);
      if (e.error) {
        setCardNumberErrorMsg(e.error.message);
      }
    }
  };

  const handlecvv = (e) => {
    if (e.complete === true) {
      setCvvStatus(true);
    } else {
      setCvvStatus(false);
    }
  };

  const handleexpiry = (e) => {
    if (e.complete === true) {
      setExpiryStatus(true);
    } else {
      setExpiryStatus(false);
    }
  };

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const onSubmit = (values) => {
    setSubmitClicked(true);
    setLoading(true);
    if (cardNumberStatus === true && cvvStatus === true && expiryStatus === true) {
      stripe.createToken({ type: 'card', name: values.cardHolderName })
        .then((response) => {
          if (response.error) {
            setLoading(false);
            setStripeTokenError(response.error);
          } else {
            updateCard({
              variables: {
                data: {
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  state: values.state,
                  zip_code: values.zip_code,
                  country: values.country,
                  token_id: response.token.id
                },
              },
            });
          }
        });
    } else {
      updateCard({
        variables: {
          data: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zip_code: values.zip_code,
            country: values.country,
          },
        },
      });
    }

  }

  return (
    <div className={classes.modalBody}>
      {loading && <Pageloader loading={loading} />}
      {haserror && <p>{haserror}</p>}
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={{
          address1: cardData && cardData.address,
          address2: cardData && cardData.address2,
          city: cardData && cardData.city,
          state: cardData && cardData.state_name,
          zip_code: cardData && cardData.zip_code,
          country: 'US'
        }}
        render={({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
          >
            <div className={classes.paymentPageRight}>
              <div className={classes.paymentPageMain}>
                <h2>Payment Details</h2>
                <p>All transactions are secure and encrypted.</p>
              </div>
              <div className={classes.paymentPageInner}>
                <div className={classes.paymentPage}>
                  <div className={classes.paymentPageHeader} >
                    <h3>Credit Card</h3>
                    <ul>
                      <li className={cardType === 'visa' ? classes.selectedCard : ''}>
                        <img src={CardOne} alt="" />
                      </li>
                      <li className={cardType === 'mastercard' ? classes.selectedCard : ''}>
                        <img src={CardTwo} alt="" />
                      </li>
                      <li className={cardType === 'amex' ? classes.selectedCard : ''}>
                        <img src={CardThree} alt="" />
                      </li>
                      <li className={cardType === 'diners' ? classes.selectedCard : ''}>
                        <img src={CardFour} alt="" />
                      </li>
                      <li className={cardType !== 'diners' && cardType !== 'visa' && cardType !== 'mastercard' && cardType !== 'amex' && cardType !== '' && cardType !== 'unknown' ? classes.selectedCard : ''}>and more...</li>
                    </ul>
                  </div>
                  <fieldset className={classes.fielsSet}>
                    {
                      stripeTokenError && (<div>{stripeTokenError}</div>)
                    }
                    <ul>
                      <li className={classes.cardNumber}>
                        <CardNumberElement
                          onChange={handlenumber}
                          placeholder="Card Number"
                        />
                        <div className={classes.infoIcon}>
                          <img src={LockIcon} alt="" className={classes.lockIcon} />
                          {/* {cardEmpty === true && (<span>*</span>)} */}
                          {
                            (cardNumberErrorMsg !== '' && submitClicked) && (<div>{cardNumberErrorMsg}</div>)
                          }
                        </div>
                      </li>
                      <li className={classes.cardName}>
                        <Field
                          id="filled-password-input"
                          placeholder="Cardholder name"
                          type="text"
                          autoComplete="current-password"
                          variant="filled"
                          name="cardHolderName"
                          fullWidth
                          component={TextField}
                          required
                        />
                        {/* {(values.cardHolderName === undefined || values.cardHolderName === '') && (<span>*</span>)} */}
                      </li>
                      <li className={classes.cardMonth}>
                        <CardExpiryElement
                          placeholder="MM / YY"
                          onChange={handleexpiry}
                        />
                        {/* {cardDateEmpty === true && (<span>*</span>)} */}
                      </li>
                      <li className={classes.cvvNumber}>
                        <CardCvcElement
                          placeholder="CVV"
                          name="cardcvv"
                          onChange={handlecvv}
                        />
                        {/* {cardCvvEmpty === true && (<span>*</span>)} */}
                        <img data-tip="3-digit security code typically found on the back of the card. For American Express cards, the code is 4 digits and found on the front." data-for="cvvTooltip" src={infoIcon} alt="" />
                        <ReactTooltip id="cvvTooltip" place="top" type="info" effect="solid" />
                      </li>
                    </ul>
                  </fieldset>
                  <div className={classes.confirmBlock}>
                    <a href="/"><img src={trustedsite} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.existingCardContent}>
              <div className={classes.billingAddress}>
                <h3>Billing Address</h3>
                <div className={classes.billingFields}>
                  <div className={classNames(classes.inputHolder, classes.focusedInputReq)}>
                    <Field
                      id="filled-password-input"
                      label="Street Address"
                      type="text"
                      variant="filled"
                      name="address1"
                      fullWidth
                      required
                      component={TextField}
                      defaultValue={cardData && cardData.address}
                    />
                  </div>
                </div>
                <div className={classes.billingFields}>
                  <div className={classes.inputHolder}>
                    <Field
                      id="filled-password-input"
                      label="Apartment, suite, unit, floor, etc."
                      type="text"
                      variant="filled"
                      name="address2"
                      fullWidth
                      component={TextField}
                      defaultValue={cardData && cardData.address2}
                    />
                  </div>
                </div>
                <div className={classes.billingFields}>
                  <div className={classNames(classes.inputHolder, classes.focusedInputReq)}>
                    <Field
                      id="filled-password-input"
                      label="City"
                      type="text"
                      variant="filled"
                      name="city"
                      required
                      component={TextField}
                      defaultValue={cardData && cardData.city}
                    />
                  </div>
                </div>
                <div className={classes.billingFields}>
                  <div className={classes.billingLeft}>
                    <div className={classNames(classes.inputHolder, classes.focusedInput)}>
                      <span>Country</span>
                      <Field
                        id={countryError ? 'countryError' : 'country'}
                        name="country"
                        component="select"
                      >
                        <option value="">Select country</option>
                        <option value="US">United States</option>
                      </Field>
                    </div>
                  </div>
                  <div className={classes.billingLeft}>
                    <div className={classNames(classes.inputHolder, classes.focusedInput)}>
                      <span>State</span>
                      <div >
                        <Field
                          id={
                            stateError
                              ? 'stateError'
                              : 'state'
                          }
                          name="state"
                          component="select"
                        >
                          <option value="">Select State</option>
                          {stateDetails && stateDetails.States.map((value) => (
                            <option key={value.id} value={value.state_name}>{value.state_name}</option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className={classes.billingLeft}>
                    <div className={classNames(classes.inputHolder, classes.focusedInput, classes.focusedInputReq)}>
                      <Field
                        id="filled-password-input"
                        label="Zipcode"
                        type="text"
                        autoComplete="current-password"
                        variant="filled"
                        name="zip_code"
                        maxLength={5}
                        required
                        component={TextField}
                        defaultValue={cardData && cardData.zip_code}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.billingFields}>
                  <div className={classNames(classes.inputHolder, classes.focusedInput, classes.focusedInputReq)}>
                    <Field
                      // id="filled-password-input"
                      label="Phone (optional)"
                      type="text"
                      variant="filled"
                      name="address1"
                      fullWidth
                      required
                      component={TextField}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.updateBtn}>
                <Button
                  className={classes.paymentBtn}
                  onClick={handleSubmitClick}
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  )
}

UpdateBillingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  billinginfo: PropTypes.func.isRequired,
  setEditDetails: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default injectStripe(withStyles(styles)(UpdateBillingInfo));
