import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const GRAPH_DATE = loader('../../../graphql/schema/graph-data/data.graphql');

const UnemploymentRate = (props) => {
  const { classes } = props;

  const { data: unemploymentData } = useQuery(GRAPH_DATE, {
    variables: {
      from: ['FRED'],
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Unemployment Rate</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/unemployment-rate.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Unemployment Rate
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/unemployment-rate-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          At 8:30 am EST on the first Friday of every month, the
                          U.S. Bureau of Labor Statistics releases a report
                          officially titled &quot;The Employment Situation
                          Summary.&quot; Informally, this report is known as the
                          Jobs Report. The Jobs Report is one measurement used
                          to gauge the health of the U.S. economy at a point in
                          time.
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames(classes.pfRow, classes.pfBlockRow)}>
                      <div className={classes.feText}>
                        <p>
                          Each month, the report estimates the number of people
                          employed and unemployed broken down by industry, the
                          average number of hours being worked on a weekly basis
                          and the average hourly and weekly earnings. The Jobs
                          Report estimates an especially important data point
                          each month - the unemployment rate. Who qualifies as
                          unemployed may surprise you.
                        </p>
                        <p>
                          According to the U.S. Bureau of Labor Statistics,
                          individuals are considered unemployed if they:
                          <ul className={classes.tickList}>
                            <li>✓ Do not have a job</li>
                            <li>
                              ✓ Have actively looked for work in the prior four
                              weeks, and
                            </li>
                            <li>✓ Are currently available for work.</li>
                          </ul>
                        </p>
                        <p>
                          The unemployment rate also includes people who have
                          been temporarily laid off and are waiting to be called
                          back to work. The formula for unemployment rate is as
                          follows:
                        </p>
                        <p>
                          Unemployment Rate = ( Unemployed / Labor Force ) x 100
                        </p>
                        <p>
                          Labor force is the sum of employed and unemployed
                          individuals. So, who&apos;s excluded from
                          unemployment? The unemployment rate excludes
                          individuals if they are:
                          <ul className={classes.tickList}>
                            <li>✓ Students</li>
                            <li>✓ Retirees</li>
                            <li>
                              ✓ Individuals with disabilities who no longer can
                              work
                            </li>
                            <li>
                              ✓ Individuals who no longer can work due to family
                              responsibilities
                            </li>
                            <li>
                              ✓ Individuals who would like to work, but
                              aren&apos;t actively looking.
                            </li>
                          </ul>
                        </p>
                        <p>
                          The unemployment rate has been tracked since the
                          1940s. See the chart below for historical data. At the
                          time of this Finance Explained, April 2020 was the
                          highest unemployment rate in the history of tracking
                          the data.
                        </p>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                scrollPositionX: 0,
                              },
                            },
                            credits: {
                              enabled: false,
                            },
                            navigation: {
                              menuItemStyle: {
                                fontSize: '14px',
                                textAlign: 'left',
                              },
                              menuItemHoverStyle: {
                                background: '#f5f5f5',
                                color: '#4c4c4c',
                                fontSize: '14px',
                              },
                              buttonOptions: {
                                height: 40,
                                width: 48,
                                symbolSize: 24,
                                symbolX: 24,
                                symbolY: 21,
                                symbolStrokeWidth: 2,
                                verticalAlign: 'bottom',
                                _titleKey: 'y',
                              },
                            },
                            exporting: {
                              buttons: {
                                contextButton: {
                                  menuItems: [
                                    {
                                      textKey: 'downloadPNG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart();
                                      },
                                    },
                                    {
                                      textKey: 'downloadJPEG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/jpeg',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadPDF',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'application/pdf',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadSVG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/svg+xml',
                                        });
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                            title: {
                              text: 'Unemployment Rate',
                            },
                            xAxis: [
                              {
                                tickInterval: 24,
                                categories:
                                  unemploymentData !== undefined &&
                                  unemploymentData.getIncomeGrowthGraphData !==
                                    undefined
                                    ? unemploymentData.getIncomeGrowthGraphData
                                        .data[0].dates
                                    : [],
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    return chart.value;
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '13px',
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                // Primary yAxis
                                labels: {
                                  format: '{value:.1f}%',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 1,
                              valueSuffix: '%',
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                            legend: {
                              enabled: false,
                              lign: 'bottom',
                              verticalAlign: 'middle',
                              layout: 'vertical',
                              x: 300,
                              y: -100,
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: false,
                                },
                              },
                            },
                            series:
                              unemploymentData !== undefined &&
                              unemploymentData.getIncomeGrowthGraphData !==
                                undefined
                                ? unemploymentData.getIncomeGrowthGraphData
                                    .data[0].details
                                : [],
                          }}
                        />
                        <p>
                          The COVID-19 crisis has forced the global economy to
                          shut down. In the short term, this has caused a spike
                          in unemployment. As the economy reopens and people can
                          go back to work, do you think the unemployment rate
                          will quickly drop? Or will it be a more gradual drop
                          to healthier levels?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
UnemploymentRate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UnemploymentRate);
