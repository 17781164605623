import React from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import greenCheck from '../../assets/img/green_check.png';
import puzzleImage from '../../assets/img/puzzle-icon.png';
const styles = (  ) => ( {
    closeSaveBtn: {
        padding: '0',
        cursor: 'pointer',
        background: '0 0',
        border: '0',
        position: 'absolute',
        right: '10px',
        top: '10px',
        left: 'auto',
        float: 'right',
        color: '#fff',
        opacity: '.5',
        fontSize: '21px',
        lineHeight: '1',
        textShadow: '0 1px 0 #fff',
        fontWeight: '700',
        textTransform: 'none',
        fontFamily: 'MuseoSans-300',
        minWidth: 'auto',
        boxSizing: 'border-box',
        zIndex: '9',
        '@media (max-width: 420px)': {
          color:'#333',
        },
        '&:hover, &:focus': {
          textDecoration: 'none',
          cursor: 'pointer',
          filter: 'alpha(opacity=50)',
          opacity: '1',
        },
      },
      csSaveBody:{
        overflow: 'auto',
        paddingBottom: '0',
        display: 'flex',
        textAlign: 'center',
        position: 'relative',
        // height: 'calc(100vh - 200px)',
        alignItems: 'center',
        '@media (max-width: 420px)': {
          flexWrap: 'wrap',
        },
      },
      csSaveImage: {
        width: '45%',
        padding: '50px 50px 30px',
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        '@media (max-width: 767px)': {
          padding: '10px 20px 10px',
        },
        '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
          padding: '30px 20px 10px',
        },
        '@media (max-width: 420px)': {
          width: '100%',
          padding: '10px 50px 10px',
        },
        '& > div':{
          width: '100%',
        }
      },
      greenCircleImage: {
        '& img': {
          maxWidth: '200px',
          marginBottom: '95px',
          verticalAlign: 'middle',
          border: '0',
          '@media (max-width: 767px)': {
            maxWidth: '100px',
            marginBottom: '30px',
          },
          '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            maxWidth: '120px',  
            marginBottom: '70px',     
          }, 
          '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            maxWidth: '120px',  
            marginBottom: '50px',     
          },     
          '@media (max-width: 420px)': {
            maxWidth: '75px',
            marginBottom: '20px',
          },
        },
      },
      pwLogo: {
        '& img': {
          maxWidth: '160px',
          verticalAlign: 'middle',
          border: '0',
          '@media (max-width: 767px)': {
            maxWidth: '100px',       
          },
          '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            maxWidth: '100px',       
          },     
        },
      },
      puzzleText: {
        fontSize: '26px',
        color: '#0069aa',
        fontFamily: 'MuseoSans-100',
        paddingTop: '10px',
        margin: '0 0 10px',
        '@media (max-width: 767px)': {
          fontSize: '23px',   
        }, 
        '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
          fontSize: '23px', 
        },
        '@media (max-width: 420px)': {
          fontSize: '20px',
        },
      },
      csSaveMessage: {
        width: '55%',
        padding: '50px 50px 30px',
        background: '#0069aa',
        color: '#fff',
        boxSizing: 'border-box',
        position: 'relative',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        border: '1px solid #0069aa',
        '@media (max-width: 767px)': {
          padding: '30px 15px 30px',
        },
        '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
          paddingLeft: '10px',
          paddingRight: '10px',
        },
        '@media (max-width: 420px)': {
          width: '100%',
          padding: '10px 10px 10px',
        }, 
        '& > div':{
          height: '100%',
        }, 
        '& h1': {
          fontSize: '40px',
          paddingTop: '60px',
          marginBottom: '50px',
          color: '#fff',
          fontWeight: '400',
          marginTop: '20px',
          lineHeight: '1.1',
          fontFamily: 'MuseoSans-300',
          '@media (max-width: 767px)': {
            paddingTop: '0px',
            fontSize: '30px',
            marginTop: '0px',
            marginBottom: '10px',
          },
          '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            paddingTop: '0px',
            fontSize: '30px',
            marginTop: '50px',
            marginBottom: '20px',
          },
          '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            paddingTop: '0px',
            fontSize: '30px',
            marginTop: '20px',
            marginBottom: '20px',
          },
          '@media (max-width: 420px)': {
            paddingTop: '10px',
            marginBottom: '10px',
            fontSize: '30px',
          },    
        },
        '& h3': {
          fontSize: '27px',
          marginBottom: '20px',
          color: '#fff',
          fontWeight: '400',
          fontFamily: 'MuseoSans-300',
          marginTop: '20px',
          lineHeight: '1.1',
          '@media (max-width: 767px)': {
            paddingTop: '0px',
            fontSize: '22px',
            marginTop: '10px',
            marginBottom: '10px',
          },
          '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
            paddingTop: '0px',
            fontSize: '22px',
            marginTop: '20px',
            marginBottom: '20px',
          },
          '@media (max-width: 420px)': {
            margin: '15px auto',
            fontSize: '20px',
          },
        },   
      },
      goTimeline: {
        marginTop: '90px',
        color: '#fff',
        display: 'table',
        fontSize: '20px',
        fontFamily: 'MuseoSans-300',
        lineHeight: 'normal',
        margin: '25px auto',
        textTransform: 'uppercase',
        backgroundColor: '#84a84d',
        borderColor: '#84a84d',
        cursor: 'pointer',
        padding: '10px 20px',
        borderRadius: '10px',
        marginBottom: '5px', 
        '@media (max-width: 767px)': {
          marginTop: '20px',
          fontSize: '15px',
        },
        '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
          marginTop: '25px',
          fontSize: '15px',
        }, 
        '@media (max-width: 420px)': {
          marginTop: '20px',
        },
        '&:hover': {
          background: '#f69a34',
          borderColor: '#f69a34',
        },   
      },
      backToModule: {
        paddingTop: '5px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-300',
        cursor:'pointer',
        margin: '0 0 10px',
          color: '#fff',
          textTransform: 'uppercase',
          // fontSize: '14px',
          textDecoration: 'underline',
          // paddingTop: '0',
          '&:hover': {
            color: '#f69a34',
          },
      },
} );

const SavePopupSucess = ( props ) => {
  const { classes, setLoadValue } = props;

  return (
    <div className={classes.csSaveContent}>
    <div className={classes.csSaveBody}>
      <Button className={classes.closeSaveBtn} onClick={() => { setLoadValue(false) }}>×</Button>
      <div className={classes.csSaveImage}>
        <div>
          <div className={classes.greenCircleImage}>
            <img src={greenCheck} alt="check" />
          </div>
          <div className={classes.pwLogo}>
            <img src={puzzleImage} alt="puzzle" />
            <p className={classes.puzzleText}>Plan With Inkwiry</p>
          </div>
        </div>
      </div>
      <div className={classes.csSaveMessage}>
        <div>
          <h1>Congrats!</h1>
          <h3>You&apos;ve saved a scenario.</h3>
          <p className={classes.timeText}>At any time, you can now load this scenario from your timeline.</p>
          <a href="/timeline-sketcher" className={classes.goTimeline}>Go to my timeline</a>
          <p className={classes.backToModule} onClick={() => { setLoadValue(false) }}>BACK TO MODULE</p>
        </div>
      </div>
    </div>
    </div>
    
  );
};

SavePopupSucess.propTypes = {
  classes: PropTypes.object.isRequired,
  setLoadValue:PropTypes.bool.isRequired
};

export default withStyles( styles )( SavePopupSucess );
