import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import allOccupations from '../../../json_data/career_occupations';
import industryOccupations from '../../../json_data/career_industry_occupations';
import _ from 'lodash';

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const  CareerOccupationList = ( props ) => {
  const {
    classes
  } = props;

  const [valueTab, setValueTab] = React.useState( 0 );
  const [occupationOrder, setOccupationOrder] = React.useState({ name: 'occupation', order: 'ASC' })
  const [occupationSearchList, setOccupationSearchList] = React.useState([]);
  const [displayList,setDisplayList] = useState( [] );
  const [search, setSearch] = React.useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [openSort, setOpenSort] = React.useState('');

  const handleSort = (type) => {
    setOpenSort(openSort===type ? '' : type)
  }

  const handleSorting = (name, order) => {
    setOpenSort(false);
    setOccupationOrder({ name, order });
    const occupationData = _.orderBy(occupationSearchList,[name],[order]);
    setOccupationSearchList(occupationData);
    setDisplayList(occupationData.slice(0,15))
  }

  useEffect(()=>{
    const occupationData = _.orderBy(allOccupations,['occupation'],['asc']);
    setOccupationSearchList(occupationData);
    setDisplayList(occupationData.slice(0,15))
    // eslint-disable-next-line 
  },[]);

  const handleChange = (event, newValue) => {
     setOpenSort('');
    setValueTab( newValue );
    if(newValue === 0) {
       setOccupationOrder({ name: 'occupation', order: 'ASC' })
       const occupationData = _.orderBy(allOccupations,['occupation'],['asc']);
       setOccupationSearchList(occupationData);
       setDisplayList(occupationData.slice(0,15))
    } else {
       const occupationData = _.orderBy(industryOccupations,['industry'],['asc']);
       setOccupationSearchList(occupationData);
       setDisplayList(occupationData.slice(0,15))
       setOccupationOrder({ name: 'industry', order: 'ASC' })
    }
    setSearch('');
    setSelectedPage(0);
  }

   const handleSearch = ( e ) => {
    setSearch( e.target.value );
    let data = [];
    if(valueTab === 0) {
        data = allOccupations.filter( ( occupation ) => occupation.occupation.toLowerCase().search( e.target.value.toLowerCase() ) !== -1 );
    } else {
       data = industryOccupations.filter( ( occupation ) => occupation.industry.toLowerCase().search( e.target.value.toLowerCase() ) !== -1 ); 
    }
    setOccupationSearchList( data );
    setDisplayList(data.slice(0,15))
    setSelectedPage(0)
  };

  const handlePageClick = ( data ) => {
    const { selected } = data;
    setDisplayList(occupationSearchList.slice(selected,selected+15));
    setSelectedPage(selected)
  };

  const pageCount = occupationSearchList && Math.ceil( occupationSearchList.length / 15 );

  return (
     <React.Fragment>
       <div className={ classes.tabSection }>
          <Tabs
            value={ valueTab }
            onChange={ handleChange }
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={ classes.tabButtons }
            aria-label="full width tabs example"
          >
            <Tab className={ valueTab === 0 ? classes.activeTab : '' } label="All Occupations" { ...a11yProps( 0 ) } />
            <Tab className={ valueTab === 1 ? classes.activeTab : '' } label="By Industry" { ...a11yProps( 1 ) } />
          </Tabs>

          <div className={ classes.textFieldSearch }>
            {occupationSearchList && (
            <NumberFormat
              value={ occupationSearchList.length }
              displayType="text"
              thousandSeparator
            />
            )}
            {' '}
            occupations
            <TextField
              className={ classes.searchTextField }
              id="standard-basic"
              placeholder="Search"
              value={ search }
              onChange={ handleSearch }
            />
          </div>

          <div className={ classes.heigherEducationSearch }>
            <div className={ classes.ChooseSchoolTable }>
              {valueTab === 0 && (
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr className={ classes.headSection }>
                      <td>
                        Occupation
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'occupation' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'occupation' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'occupation' && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'occupation' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'occupation', 'asc' ) }
                            >
                              Sort A to Z
                            </li>
                            <li
                            className={occupationOrder.name === 'occupation' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'occupation', 'desc' ) }
                            >
                              Sort Z to A
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        # Employed
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'total_employed' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'total_employed' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'total_employed'  && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'total_employed' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'total_employed' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_mean' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_mean' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'annual_wage_mean' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'annual_wage_mean' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'annual_wage_mean' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_median' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_median' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'annual_wage_median' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'annual_wage_median' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'annual_wage_median' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Low End
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_lowend' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_lowend' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'annual_wage_lowend' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'annual_wage_lowend' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_lowend', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'annual_wage_lowend' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_lowend', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        High End
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_highend' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_highend' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'annual_wage_highend' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'annual_wage_highend' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_highend', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              className={occupationOrder.name === 'annual_wage_highend' && occupationOrder.order === 'asc' && classes.filterSelected}
                              onClick={ () => handleSorting( 'annual_wage_highend', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_mean' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_mean' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'hourly_wage_mean' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'annual_wage_highend' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'annual_wage_highend' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_median' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_median' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'hourly_wage_median' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'hourly_wage_median' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'hourly_wage_median' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Low End
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_lowend' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_lowend' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'hourly_wage_lowend' && (
                          <ul>
                            <li
                            className={occupationOrder.name === 'hourly_wage_lowend' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_lowend', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'hourly_wage_lowend' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_lowend', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        High End
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_highend' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_highend' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'hourly_wage_highend' && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'hourly_wage_highend' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_highend', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                            className={occupationOrder.name === 'hourly_wage_highend' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_highend', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                    </tr>
                    {displayList && displayList.length > 0 ? (
                      displayList.map( ( data ) => (
                        <tr>
                          <td>{data.occupation}</td>
                          <td>
                            <NumberFormat
                              value={ data.total_employed }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_lowend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_highend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_lowend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>

                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_highend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                        </tr>
                      ) )
                    ) : (
                      <tr className={ classes.noData }>
                        <td colSpan="5">No data found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              
              {valueTab === 1 && (
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td>
                        Industry
                        <i
                          aria-hidden="true"
                          onClick={ () => handleSort( 'industry' ) }
                          className={`fa fa-filter ${occupationOrder.name ===  'industry' && classes.activeOpenSort}`}
                        >
                          {openSort ===  'industry' && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'industry' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'industry', 'asc' ) }
                            >
                              Sort A to Z
                            </li>
                            <li
                              className={occupationOrder.name === 'industry' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'industry', 'desc' ) }
                            >
                              Sort Z to A
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        # Employed
                        <i
                          className={`fa fa-filter ${occupationOrder.name ===  'total_employed' && classes.activeOpenSort}`}
                          aria-hidden="true"
                          onClick={ () => handleSort( 'total_employed' ) }
                        >
                          {openSort ===  'total_employed'  && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'total_employed' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              className={occupationOrder.name === 'total_employed' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_mean' && classes.activeOpenSort}`}
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_mean' ) }
                        >
                          {openSort ===  'annual_wage_mean'  && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'annual_wage_mean' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              className={occupationOrder.name === 'annual_wage_mean' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className={`fa fa-filter ${occupationOrder.name ===  'annual_wage_median' && classes.activeOpenSort}`}
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_median' ) }
                        >
                          {openSort ===  'annual_wage_median'  && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'annual_wage_median' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              className={occupationOrder.name === 'annual_wage_median' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_mean' && classes.activeOpenSort}`}
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_mean' ) }
                        >
                          {openSort ===  'hourly_wage_mean'  && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'hourly_wage_mean' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              className={occupationOrder.name === 'hourly_wage_mean' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className={`fa fa-filter ${occupationOrder.name ===  'hourly_wage_median' && classes.activeOpenSort}`}
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_median' ) }
                        >
                          {openSort ===  'hourly_wage_median'  && (
                          <ul>
                            <li
                              className={occupationOrder.name === 'hourly_wage_median' && occupationOrder.order === 'desc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'desc' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              className={occupationOrder.name === 'hourly_wage_median' && occupationOrder.order === 'asc' && classes.filterSelected}
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'asc' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                    </tr>
                    {displayList && displayList.length > 0 ? (
                      displayList.map( ( data ) => (
                        <tr>
                          <td>{data.industry}</td>
                          <td>
                            <NumberFormat
                              value={ data.total_employed }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                        </tr>
                      ) )
                    ) : (
                      <tr className={ classes.noData }>
                        <td colSpan="5">No data found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              {occupationSearchList && occupationSearchList.length > 0 && (
              <div className={ classes.pagination }>
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={ pageCount }
                  forcePage={selectedPage}
                  marginPagesDisplayed={ 2 }
                  pageRangeDisplayed={ 5 }
                  onPageChange={ handlePageClick }
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
              )}
            </div>
          </div>
        </div>
     </React.Fragment>
  );
};

export default withStyles( styles )( CareerOccupationList );
