import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import styles from './styles';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import bookImage from '../../../../assets/img/mcs/icon-book-rev.png';
import tickMark from '../../../../assets/img/mcs/green-check-discovery.png';
import Timeline from '../level3-activity-timeline/level3-activity2-timeline';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const MY_DETAILS = loader('../../../../graphql/schema/auth/me.graphql');

const McsLevelThreeActivityTwo = (props) => {
    const { classes } = props;
    const { data: myDetails } = useQuery(MY_DETAILS);

    const [loading] = React.useState(false);
    const [bookSection, setBookSection]=React.useState(false);
    const [levelList, setLevelList] = React.useState([]);

    const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
        variables: {
            level_id: "3",
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);
        },
        onError() {
        },
      });
    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div> 
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level3">LEVEL 03</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Find your career path</span>
                                        </span>
                                        {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}                                        
                                    </div>
                                    </div>
                                    <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>                                       
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>2.</span>
                                            <span>Place your stepping stones</span>
                                        </span>
                                        {levelList[1] && levelList[1].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}    
                                    </div>
                                </div>
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div>
                <div className={classes.fiContent}>
                <div className={classes.mcsRow}>
                        <div className={ classes.backtoLevel }>
                            <Link to="/my-career-sketch/level3">
                                <span> {'<'} Back to level 03</span>
                            </Link>
                        </div>
                    </div>
                    <div className={classes.fiHeader}>                        
                        <div className={classes.fiHeaderInner}>
                            <span className={classes.pwiActiveModule}>
                                <h3>Level 03: Identify your stepping stones</h3>
                            </span>
                        </div>                        
                    </div>
                    <div className={classes.stepOne}>
                        <div className={classes.fiContentHeader}>
                            <div className={classes.pwiWalkTitle}>
                                <div aria-hidden="true" className={classes.menuIcon}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                                <h3>Place your stepping stones on your timeline</h3>
                            </div>
                        </div>
                        <div className={classes.ProgressBar}>
                            <div className={classes.ProgressBarInner} style={{ width: '100%' }} />
                        </div>
                        <div className={classes.contentBlock}>
                            {myDetails && myDetails.me.user_identity === "student" ?   
                             <div> 
                                <p className={classes.MarginTopZero}>
                                    Now that you’ve mapped out your stepping stones, let’s add them to your timeline so you can visualize the progression of your career path. To do this, we’ll work directly in Plan With Inkwiry, where life events are organized into modules. Your stepping stones are located in the Career and Higher Education modules. 
                                </p>                            
                                <p><i>On the timeline, you’ll see two modules already there – Financial Independence and Existing Assets + Liabilities – we’ll learn about them in the next lesson.</i></p>
                                <ul className={ classes.procesSteps }>
                                    <li>1. To create your first stepping stone, drag and drop the Career Path module icon to your timeline and fill in the basic information.</li>
                                    <li>2. Drag and drop your second stepping stone to the timeline – it could be Career Advancement or Higher Education.</li>
                                    <li>3. Add at least three education or career modules to the timeline to complete this activity. Keep in mind what these stepping stones mean for your path as you will be entering details in the next level.</li>
                                </ul>
                                <p>And there you have it! Your stepping stones are on your timeline.</p>
                                <p>Let’s move to the next lesson, where you’ll start to enter initial details.</p>
                             </div>:
                            <div> 
                                <p className={classes.MarginTopZero}>
                                Now that you’ve mapped out your stepping stones, let’s add them to your timeline so you can visualize the progression of your career path. To do this, we’ll work directly in Plan With Inkwiry, where life events are organized into modules. Your stepping stones are located in the Higher Education and Career modules.
                                </p>                            
                                <p><i>On the timeline, you’ll see two modules already there – Financial Independence and Existing Assets + Liabilities – we’ll learn about them in the next lesson.</i></p>
                                <ul className={ classes.procesSteps }>
                                    <li>1. To create your first stepping stone, drag and drop the Higher Education or Career Path module icon to your timeline and fill in the basic information.</li>
                                    <li>2. Drag and drop your second stepping stone to the timeline – it could be Career Path, Career Advancement or More Higher Education.</li>
                                    <li>3. Continue with your one or two additional stepping stones.</li>
                                </ul>
                                <p>And there you have it! Your stepping stones are on your timeline.</p>
                                <p>Let’s move to the next lesson, where you’ll start to enter initial details.</p>
                            </div>
                            }                           
                        </div>
                    </div>
                    {/* <div className={classes.Buttons}>
                        <div className={classes.startButton}>
                            <Link to="/my-career-sketch/level3">
                                <button type="button">Back To Level 03</button>
                            </Link>
                        </div>
                        <div className={classes.nextButton}>
                            <Link to="/my-career-sketch/level3/activity2/timeline">
                                <button type="button">Next</button>
                            </Link>
                        </div>
                    </div> */}
                    <Timeline/>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelThreeActivityTwo.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelThreeActivityTwo);