import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import styles from '../../screens/pwi-vacation/styles';
import * as modulesServices from '../../calculations/modules-services';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import { timeline, sortTimeLine } from '../../calculations/time-line';

const SettingVacationGoals = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [vacationStartYear, setVacationStartYear] = React.useState( (inputData.start_year === undefined && inputData.start_year !== null ) ? 0 : inputData.start_year );
  const [vacationEndYear, setVacationEndYear] = React.useState( (inputData.end_year === undefined && inputData.end_year !== null) ? 0 : inputData.end_year );

  const [yearStartOptions, SetYearStartOptions] = React.useState( [] );
  const [yearEndOptions, SetYearEndOptions] = React.useState( [] );

  const updateTimeLine = (startYearVal) => {
    const years = [];
    years.push(startYearVal);
    if(inputData.second_start_year !== undefined && inputData.second_start_year !== null && inputData.second_start_year !== '' && parseInt(inputData.second_start_year, 10) > 0) {
      years.push(inputData.second_start_year);
    }
    if(inputData.third_start_year !== undefined && inputData.third_start_year !== null && inputData.third_start_year !== '' && parseInt(inputData.third_start_year, 10) > 0) {
      years.push(inputData.third_start_year);
    }
    var start_year = '';
    if(years.length > 0) {
        start_year = Math.min.apply(Math,years);
    }
    let timelineData = [...timeline];
    const index = _.findIndex( timeline, { selectedModule: 'module11' } );
    if(index >= 0 && start_year !== '' && start_year > 0) {
      timelineData[index].startYear = start_year;
      timelineData[index].endYear = '';
      timelineData[index].imageSlug = 'active';
      timelineData[index].timelineOrder = start_year;
      sortTimeLine(timelineData);
    }
  }
    
  useEffect(()=>{
    let start_year = parseInt(modulesServices.module1Data.start_year);

    if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        start_year = vacationStartYear;
    }
    if(start_year <=  0) {
      start_year = new Date().getFullYear();
    }
    const vacationEndYearOptions = [0];
    for (let i = start_year; i < parseInt(modulesServices.module1Data.start_year)+15; i+=1) {
        vacationEndYearOptions.push(i);
    }
    let endYearValue = vacationEndYear;
    SetYearEndOptions(vacationEndYearOptions);
    if(vacationEndYear !== undefined && vacationEndYear !== '' && vacationEndYear > 0) {
      if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        const endIndex = vacationEndYearOptions.indexOf(parseInt(vacationEndYear, 10));
        if(endIndex === -1) {
          endYearValue = vacationEndYearOptions[1];
        }
      }
    } else {
      endYearValue = 0;
    }
    setVacationEndYear(endYearValue);

    let inputDataValues = {...inputData};
    inputDataValues['start_year'] = vacationStartYear;
    inputDataValues['end_year'] = endYearValue;
    updateData('total_obj',inputDataValues);

    updateTimeLine(vacationStartYear);
    // eslint-disable-next-line
  },[vacationStartYear])

  useEffect( () => {
    const vacationStartYearOptions = [0];
    for (let i = parseInt(modulesServices.module1Data.start_year, 10); i < parseInt(modulesServices.module1Data.start_year, 10)+15; i+=1) {
        vacationStartYearOptions.push(i);
    }
    SetYearStartOptions(vacationStartYearOptions);
    // eslint-disable-next-line
  }, [] );

  const handleVacationStartYear = ( event ) => {
    setVacationStartYear( parseInt(event.target.value, 10) );
  };

  const handleVacationEndYear = ( event ) => {
    let vacationEnd = parseInt(event.target.value, 10);
    if(vacationStartYear !== 'undefined' && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0 ) {
        if(vacationStartYear > 0 && vacationStartYear > vacationEnd) {
             vacationEnd = vacationStartYear;
        }
    } else {
      vacationEnd = 0;
    }
    updateTimeLine(vacationStartYear);
    setVacationEndYear(vacationEnd);
    updateData( 'end_year', vacationEnd );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>With a plan, you make things happen. An important aspect of planning is making sure you have time to enjoy yourself outside of your career. How do you plan to spend your time off? Will you go somewhere local, travel far, or maybe a &quot;staycation&quot; at home? </p>
      <p>This is why you build a plan – to make sure you have the time and money to do things like take an annual vacation. When do you plan to start taking a vacation? Make sure you have enough cash flow in the year that you choose.</p>
      <div className={ classNames( classes.grayBox, classes.vacationGrayBox ) }>
        <ul className={ classes.yearsList }>
          <li>
            <span>I plan to start taking a vacation in year&nbsp;</span>
            <select value={ vacationStartYear } onChange={ handleVacationStartYear }>
              {yearStartOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> )}
            </select>
            .
          </li>
          <li>
            <span>I plan to stop taking vacations in year&nbsp;</span>
            <select value={ vacationEndYear } onChange={ handleVacationEndYear }>
              { ( yearEndOptions.length > 0 && yearEndOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> ) )}
            </select>
            .
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module11"/>

    </div>
  );
};

SettingVacationGoals.propTypes = {
  classes: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  inputData: PropTypes.object.isRequired,
};

export default withStyles( styles )( SettingVacationGoals );
