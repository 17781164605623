function xpPoints( attempts ) {
  let outputval;
  switch ( attempts ) {
    case 1:
      outputval = 20;
      break;
    case 2:
      outputval = 15;
      break;
    case 3:
      outputval = 12;
      break;
    default:
      outputval = 10;
      break;
  }
  return outputval;
}

function xpChallengePoints( attempts ) {
  let outputval;
  switch ( attempts ) {
    case 1:
      outputval = 100;
      break;
    case 2:
      outputval = 80;
      break;
    case 3:
      outputval = 65;
      break;
    default:
      outputval = 50;
      break;
  }
  return outputval;
}

function getTotalTime( startTime ) {
  const endTime = new Date();
  let totalSeconds = 0;
  const res = Math.abs( endTime - startTime ) / 1000;
  // Days Calculation
  const days = Math.floor( res / 86400 );
  // Hours calculation
  const hours = Math.floor( res / 3600 ) % 24;
  // Minutes calculation
  const minutes = Math.floor( res / 60 ) % 60;
  // Seconds calculation
  const seconds = res % 60;

  totalSeconds = ( days * 86400 ) + ( hours * 3600 ) + ( minutes * 60 ) + seconds;
  return totalSeconds;
}

function getUserXPRank( points ) {
  let rank = '';
  if ( points >= 0 && points <= 1999 ) {
    rank = 'Beginner';
  } else if ( points >= 2000 && points <= 3999 ) {
    rank = 'Intermediate';
  } else if ( points >= 4000 && points <= 6499 ) {
    rank = 'Pro';
  } else if ( points >= 6500 && points <= 8999 ) {
    rank = 'Expert';
  } else {
    rank = 'Legend';
  }
  return rank;
}

const eachQuestionXp = 20;

export default eachQuestionXp;

export {
  xpPoints,
  getTotalTime,
  getUserXPRank,
  xpChallengePoints,
};
