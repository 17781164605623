import React from 'react';
import NumberFormat from 'react-number-format';

function converyIntoThousands( amount ) {
  let reading = '';
  let value = amount;
  if ( value <= 999 ) {
    reading = parseFloat( value ).toFixed( 0 );
  } else {
    value /= 1000;
    reading = `${parseFloat( value ).toFixed( 0 )}K`;
  }
  return `$${reading}`;
}

function converyIntoThousandsTwoDecimal( amount ) {
  let reading = '';
  let value = amount;
  if ( value <= 999 ) {
    reading = parseFloat( value ).toFixed( 2 );
  } else {
    value /= 1000;
    reading = `${parseFloat( value ).toFixed( 2 )}K`;
  }
  return `$${reading}`;
}

function converyIntoThousandsOneDecimal( amount ) {
  let reading = '';
  let value = amount;
  if ( value <= 999 ) {
    reading = parseFloat( value ).toFixed( 1 );
  } else {
    value /= 1000;
    reading = `${parseFloat( value ).toFixed( 1 )}K`;
  }
  return `$${reading}`;
}

function percentFormatWithTwoDecimal( amount ) {
  return `${parseFloat( amount ).toFixed( 2 )}%`;
}

function numFormatterHour( num ) {
  return `$${( num ).toFixed( 2 )}`;
}

function multiplerValue( num ) {
  return `${( num ).toFixed( 1 )}x`;
}

function percentFormatWithZeroDecimal( amount ) {
  return `${parseFloat( amount ).toFixed( 0 )}%`;
}

function yearFormatter( year ) {
  if ( year === 1 ) {
    return `${year} yr`;
  }
  return `${year} yrs`;
}

function monthsFormatter( months ) {
  return `${months} mos`;
}

function percentLimit( value ) {
  if ( value <= 0 ) {
    return 0;
  }
  if ( value >= 100 ) {
    return 100;
  }
  return value;
}

function numFormatter( num ) {
  return <NumberFormat value={ num } displayType="text" thousandSeparator />;
}

export default converyIntoThousands;
export {
  percentFormatWithTwoDecimal,
  percentFormatWithZeroDecimal,
  converyIntoThousandsTwoDecimal,
  converyIntoThousandsOneDecimal,
  yearFormatter,
  percentLimit,
  numFormatterHour,
  numFormatter,
  multiplerValue,
  monthsFormatter,
};
