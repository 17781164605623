function careerWhatIfBackend( module1Data, module4Data, inputData3 ,incomeTaxData ) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;
  let careerOutputBackend = {};
  let originalDataArray = {};
  const capitalInvestmentData = {};
  const origionalTaxesData = {};
  const whatIfTaxesData = {};
  let currentSavingsData = {};
  let newSavingsData = {};

  year = parseInt( module4Data.start_year, 10 );
  yearLimit = parseInt( module4Data.start_year, 10 ) + 30;

  careerOutputBackend = {};
  currentSavingsData = {};
  newSavingsData = {};

  capitalInvestmentData['Capital Investment Interest'] = {};
  const careerOutputb9 = inputData3.currentAnnualIncome;
  const careerOutputf26 = inputData3.addMoreIncome;
  const careerOutpute9 = inputData3.currentAnnualExpenses;
  const careerOutputd19 = inputData3.percentInvestedMonthly;
  const careerOutputi26 = inputData3.addSavedExpenses;
  const careerOutputj19 = inputData3.returnSavedCapital;

  // Percentage Array
   let percentageArray = [];
   if ( incomeTaxData !== undefined && incomeTaxData !== '' && incomeTaxData.percentageArray !== undefined && incomeTaxData.percentageArray !== '' ) {
     const percentObject = incomeTaxData.percentageArray;
     percentageArray = percentObject;
   }
   

   //Single Range Arrays
   let originalRangeArray = [];
   let originalRange2Array = [];
   if ( incomeTaxData !== undefined && incomeTaxData !== '' && incomeTaxData.singleRangeArray !== undefined && incomeTaxData.singleRangeArray !== '' ) {
     originalRangeArray = incomeTaxData.singleRangeArray;
   }
   if ( incomeTaxData !== undefined && incomeTaxData !== '' && incomeTaxData.singleRange2Array !== undefined && incomeTaxData.singleRange2Array !== '' ) {
     originalRange2Array = incomeTaxData.singleRange2Array;
   }

   

   originalDataArray = percentageArray.map( ( percentage, index ) => {
     const returnData = {};
     returnData.Percentage = percentage;
     returnData.Range = originalRangeArray[index];
     returnData.Range2 = originalRange2Array[index];
     if ( originalRange2Array[index] > 0 ) {
       returnData.Difference = originalRange2Array[index] - originalRangeArray[index];
       returnData.Tax = Math.round( returnData.Difference * ( percentage / 100 ) );
     } else {
       returnData.Difference = 0;
       returnData.Tax = 0;
     }
     return returnData;
   } );
   

  function originalCalculation( income ) {
    // Total Taxes
    let totalTaxes = 0;
      percentageArray.forEach( ( percentage, index ) => {
       let range = 0;
       if ( income >= originalDataArray[index].Range ) {
         range = originalDataArray[index].Range;
       } else {
         range = 0;
       }

      //  Range 2
       let range2 = 0;
       if ( index === 6 ) {
         if ( income >= originalDataArray[index].Range ) {
           range2 = income;
         } else {
           range2 = 0;
         }
       } else if ( income >= originalDataArray[index].Range2 ) {
         range2 = originalDataArray[index].Range2;
       } else if ( income <= originalDataArray[index].Range2
                                 && income >= originalDataArray[index].Range ) {
         range2 = income;
       } else {
         range2 = 0;
       }
      //  Taxes
      totalTaxes = totalTaxes+((range2 - range) * ( percentage / 100 ));
     } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // State & local
    const stateAndLocal = 5;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity + stateAndLocal;

    const taxInfo = {};
    taxInfo['Total Taxes'] = totalTaxes;
    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['State & Local'] = stateAndLocal;
    taxInfo['Federal Income Tax'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  function whatIfCalculation( income ) {
    // Total Taxes
    let totalTaxes = 0;

     percentageArray.forEach( ( percentage, index ) => {
      // Range 1
       let range = 0;
       if ( income >= originalDataArray[index].Range ) {
         range = originalDataArray[index].Range;
       } else {
         range = 0;
       }
       // Range 2
       let range2 = 0;
       if ( index === 6 ) {
         if ( income >= originalDataArray[index].Range ) {
           range2 = income;
         } else {
           range2 = 0;
         }
       } else if ( income >= originalDataArray[index].Range2 ) {
         range2 = originalDataArray[index].Range2;
       } else if ( income <= originalDataArray[index].Range2
                                 && income >= originalDataArray[index].Range ) {
         range2 = income;
       } else {
         range2 = 0;
       }
      //  Taxes
      totalTaxes = totalTaxes+((range2 - range) * ( percentage / 100 ));
     } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;

    // State & local
    const stateAndLocal = 5;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity + stateAndLocal;

    const taxInfo = {};
    taxInfo['Total Taxes'] = totalTaxes;
    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['State & Local'] = stateAndLocal;
    taxInfo['Federal Income Tax'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;
    return taxInfo;
  }

  let grossIncomeValue = 0;
  let newGrossIncomeValue = 0;
  let taxesValue = 0;
  let incomeAfterTaxesValue = 0;
  let taxRatesCurrentVal = 0;
  let taxRatesNewVal = 0;
  let expensesValue = 0;
  let netIncomeValue = 0;
  let contributionValue = 0;
  let newTaxesValue = 0;
  let newIncomeAfterTaxesValue = 0;
  let newExpensesValue = 0;
  let newNetIncomeValue = 0;
  let newContributionValue = 0;
  //   const cumulativeInvestmentsValue = 0;
  // Graph Data Arrays
  const yearsList = [];
  const currentNetIncome = [];
  const newNetIncome = [];
  const currentCumulativeInterest = [];
  const newCumulativeInterest = [];

  // let startYear = 0;
     //const Contribution = 0;
  // let newContribution = 0;
  // let Earnings = 0;
  // let newEarnings = 0;
   //const originalPayment = 0;
   //let Payment = originalPayment;
  // above line commented by chandrakala due to unavailable of this.originalPayment value for calculation I have added below line with same variable name intialised with 0.
  //   const Payment = 0;
     //let Interest = 0;
  // let begBalance = 0;
  // let newBegBalance = 0;
  // let endBalance = 0;
  // let newEndBalance = 0;
  let endBalanceMonth = 0;
  let newEndBalanceMonth = 0;
  //const annualInterestRate = 0;
  
   //if(annualInterestRate > 0) {
       //Interest = Contribution * ((annualInterestRate/100)/12);
   //}

   //above line commented by chandrakala due to unavailable of this.annualInterestRate value for calculation
     //const Principal = Payment - Interest;
     //const Equity = Principal;
     //const TotalInterest = Interest;
     //const TotalPayment = Equity + TotalInterest;

     //const totalInterestPaidArray = [];
     //const totalPaidArray = [];
  for ( year; year < yearLimit; year += 1 ) {
    careerOutputBackend[year] = {};
    careerOutputBackend[year]['Current Scenario'] = {};
    careerOutputBackend[year]['Cumulative Investments'] = {};
    careerOutputBackend[year]['New Scenario'] = {};
    careerOutputBackend[year]['Tax Rates'] = {};
    careerOutputBackend[year]['Cumulative Investments Second'] = {};
    origionalTaxesData[year] = {};
    whatIfTaxesData[year] = {};
    yearsList.push( year );
    // Calculate Gross Income value
    if ( year === module4Data.start_year ) {
      grossIncomeValue = careerOutputb9;
    } else {
      grossIncomeValue *= ( ( ( 100 / 100 ) + module1Data.long_term_growth_in_percentage / 100 ) );
    }
    careerOutputBackend[year]['Current Scenario']['Gross Income'] = grossIncomeValue;

    // Calculate New Scenario Gross Income
    if ( year === module4Data.start_year ) {
      newGrossIncomeValue = careerOutputb9 + ( careerOutputf26 * 12 );
    } else {
      newGrossIncomeValue *= ( ( ( 100 / 100 ) + module1Data.long_term_growth_in_percentage / 100 ) );
    }
    careerOutputBackend[year]['New Scenario']['Gross Income'] = newGrossIncomeValue;

    // To get the Income value for Original Taxes
    origionalTaxesData[year]['Original Income'] = careerOutputBackend[year]['Current Scenario']['Gross Income'];
    origionalTaxesData[year].Original = originalCalculation( Math.round( careerOutputBackend[year]['Current Scenario']['Gross Income'] ) );

    // To get the Income value for What If Taxes
    whatIfTaxesData[year]['What If Income'] = careerOutputBackend[year]['New Scenario']['Gross Income'];
    whatIfTaxesData[year]['What If'] = whatIfCalculation( Math.round( careerOutputBackend[year]['New Scenario']['Gross Income'] ) );

    // Calculate 'Current' in Tax Rates Section
    taxRatesCurrentVal = origionalTaxesData[year].Original['Total Tax'];
    careerOutputBackend[year]['Tax Rates'].Current = taxRatesCurrentVal;

    // Calculate 'New' in Tax Rates Section
    taxRatesNewVal = whatIfTaxesData[year]['What If']['Total Tax'];
    careerOutputBackend[year]['Tax Rates'].New = taxRatesNewVal;

    // Calculate Taxes Value
    taxesValue = ( careerOutputBackend[year]['Current Scenario']['Gross Income'] * careerOutputBackend[year]['Tax Rates'].Current ) / 100;
    careerOutputBackend[year]['Current Scenario'].Taxes = taxesValue;

    // Calculate Income After Taxes
    incomeAfterTaxesValue = careerOutputBackend[year]['Current Scenario']['Gross Income'] - careerOutputBackend[year]['Current Scenario'].Taxes;
    careerOutputBackend[year]['Current Scenario']['Income After Taxes'] = incomeAfterTaxesValue;

    // Calculate Expenses
    if ( year === module4Data.start_year ) {
      expensesValue = -careerOutpute9;
    } else {
      expensesValue *= ( ( ( 100 / 100 ) + module1Data.inflation_in_percentage / 100 ) );
    }
    careerOutputBackend[year]['Current Scenario'].Expenses = expensesValue;

    // Calculate Net Income Value
    netIncomeValue = careerOutputBackend[year]['Current Scenario']['Income After Taxes'] - careerOutputBackend[year]['Current Scenario'].Expenses;
    careerOutputBackend[year]['Current Scenario']['Net Income'] = netIncomeValue;

    // Calculate Contribution to Investments Value
    contributionValue = ( careerOutputBackend[year]['Current Scenario']['Net Income'] * careerOutputd19 ) / 100;
    careerOutputBackend[year]['Current Scenario']['Contribution to Investments'] = contributionValue;

    // Calculate Taxes Value in New Scenario
    newTaxesValue = ( careerOutputBackend[year]['New Scenario']['Gross Income'] * careerOutputBackend[year]['Tax Rates'].New ) / 100;
    careerOutputBackend[year]['New Scenario'].Taxes = newTaxesValue;

    // Calculate Income After Taxes in New Scenario
    newIncomeAfterTaxesValue = careerOutputBackend[year]['New Scenario']['Gross Income'] - careerOutputBackend[year]['New Scenario'].Taxes;
    careerOutputBackend[year]['New Scenario']['Income After Taxes'] = newIncomeAfterTaxesValue;

    // Calculate Expenses in New Scenario
    if ( year === module4Data.start_year ) {
      newExpensesValue = -( careerOutpute9 + ( careerOutputi26 * 12 ) );
    } else {
      newExpensesValue *= ( 1 + ( module1Data.inflation_in_percentage / 100 ) );
    }
    careerOutputBackend[year]['New Scenario'].Expenses = newExpensesValue;

    // Calculate Net Income Value in New Scenario
    newNetIncomeValue = parseFloat( careerOutputBackend[year]['New Scenario']['Income After Taxes'] ) - parseFloat( careerOutputBackend[year]['New Scenario'].Expenses );
    careerOutputBackend[year]['New Scenario']['Net Income'] = newNetIncomeValue;

    // Calculate Contribution to Investments Value in New Scenario
    newContributionValue = ( careerOutputBackend[year]['New Scenario']['Net Income'] * careerOutputd19 ) / 100;
    careerOutputBackend[year]['New Scenario']['Contribution to Investments'] = newContributionValue;

    // Calculate Current Savings Investment Interest
    // For Loop for 12 months
    currentSavingsData[year] = {};
    for ( let month = 1; month <= 12; month += 1 ) {
      currentSavingsData[year][month] = {};

      // Year Identifier
      currentSavingsData[year][month].YearIdentifier = year;

      // Beg Balance
      currentSavingsData[year][month]['Beg Balance'] = endBalanceMonth;

      // Contribution
      let tempContributions = 0;
      tempContributions = ( careerOutputBackend[year]['Current Scenario']['Contribution to Investments'] / 12 );
      currentSavingsData[year][month].Contribution = tempContributions;

      // Earnings
      currentSavingsData[year][month].Earnings = ( ( ( parseFloat( currentSavingsData[year][month]['Beg Balance'] ) + parseFloat( currentSavingsData[year][month].Contribution ) ) * ( parseFloat( careerOutputj19 ) / 12 ) ) / 100 );

      // End Balance
      endBalanceMonth = parseFloat( currentSavingsData[year][month]['Beg Balance'] )
                    + parseFloat( currentSavingsData[year][month].Contribution )
                    + parseFloat( currentSavingsData[year][month].Earnings );
      currentSavingsData[year][month]['End Balance'] = endBalanceMonth;
    }
    // Calculate Cumulative Investments Value in the current scenario
    careerOutputBackend[year]['Current Scenario']['Cumulative Investments'] = currentSavingsData[year][12]['End Balance'];

    // Calculate New Savings Investment Interest
    // For Loop for 12 months
    newSavingsData[year] = {};
    for ( let month = 1; month <= 12; month += 1 ) {
      newSavingsData[year][month] = {};

      // Year Identifier
      newSavingsData[year][month].YearIdentifier = year;

      // Beg Balance
      newSavingsData[year][month]['Beg Balance'] = newEndBalanceMonth;

      // Contribution
      let newTempContributions = 0;
      newTempContributions = ( careerOutputBackend[year]['New Scenario']['Contribution to Investments'] / 12 );
      newSavingsData[year][month].Contribution = newTempContributions;

      // Earnings
      newSavingsData[year][month].Earnings = ( ( ( parseFloat( newSavingsData[year][month]['Beg Balance'] ) + parseFloat( newSavingsData[year][month].Contribution ) ) * ( parseFloat( careerOutputj19 ) / 12 ) ) / 100 );

      // End Balance
      newEndBalanceMonth = parseFloat( newSavingsData[year][month]['Beg Balance'] )
                    + parseFloat( newSavingsData[year][month].Contribution )
                    + parseFloat( newSavingsData[year][month].Earnings );
      newSavingsData[year][month]['End Balance'] = newEndBalanceMonth;
    }

    // Calculate Cumulative Investments Value in the New scenario
    careerOutputBackend[year]['New Scenario']['Cumulative Investments'] = newSavingsData[year][12]['End Balance'];
	
	//Additional Earnings value
	careerOutputBackend[year]['New Scenario']['Additional Earnings'] = careerOutputBackend[year]['New Scenario']['Cumulative Investments'] - careerOutputBackend[year]['Current Scenario']['Cumulative Investments'];

    // Calculate Graphs Data
    currentNetIncome.push( Math.round( careerOutputBackend[year]['Current Scenario']['Net Income'] ) );
    newNetIncome.push( Math.round( careerOutputBackend[year]['New Scenario']['Net Income'] ) );
    currentCumulativeInterest.push( Math.round( careerOutputBackend[year]['Current Scenario']['Cumulative Investments'] ) );
    newCumulativeInterest.push( Math.round( careerOutputBackend[year]['New Scenario']['Cumulative Investments'] ) );
  }
  // To return Output backend Complete Data
  function careerWhatIfBackendData() {
    const graphvaluesData = {};
    graphvaluesData['Bar Graph'] = {};
    graphvaluesData['Career What If Output'] = {};
    graphvaluesData['Bar Graph'].yearsList = yearsList;
    graphvaluesData['Bar Graph']['Current Net Income'] = currentNetIncome;
    graphvaluesData['Bar Graph']['New Net Income'] = newNetIncome;
    graphvaluesData['Bar Graph']['Current Cumulative Investments'] = currentCumulativeInterest;
    graphvaluesData['Bar Graph']['New Cumulative Investments'] = newCumulativeInterest;
    graphvaluesData['Career What If Output'] = careerOutputBackend;
    //console.log("graphvaluesData",graphvaluesData);
    return graphvaluesData;
  }
  // added below function by chandrakala to console the output data
  return careerWhatIfBackendData();
}
export default careerWhatIfBackend;
