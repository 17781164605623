const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSans-300',
    fontSize: '16px',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  csBox: {
    background: '#fff',
    padding: '35px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  blueBorder: {
    border: '2px solid #0069aa',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    }, 
  },
  leftPanel: {
    background: '#f2f2f2!important',
    borderRight: '0!important',
    display: 'block',
    padding: '18px 20px 0 20px',
    verticalAlign: 'top',
    width: '25%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '40%',
      padding: '18px 10px 0 10px',
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      padding: '18px 10px 0 10px',
    }, 
    '& h3': {
      marginTop: '0',
      marginBottom: '10px',
      borderBottom: '2px solid #0070c0',
      paddingBottom: '10px',
      color: '#000',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-100',
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSPacing: '0px',
      borderCollapse: 'collpase',
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '17px',
            padding: '0px 10px 0px 0px',
            boxSizing: 'border-box',
            color: '#000',
            [theme.breakpoints.down( 'sm' )]: {
              fontSize: '15px',
            }, 
          },
        },
      },
    },
  },
  radioGroup: {
    display: 'inline-block',
    paddingRight: '5px',
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      marginBottom: '5px',
      position: 'relative',
      padding: '0 2px 15px 25px',
      fontWeight: '400',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {       
        padding: '0 2px 15px 20px',
      }, 
      '&::after': {
        background: '#fff',
        border: '1px solid #898d8f',
        content: '""',
        height: '15px',
        left: '0',
        marginTop: '-16px',
        position: 'absolute',
        top: '50%',
        width: '15px',
        boxSizing: 'border-box',
      },
    },
    '& input': {
      display: 'none',
      margin: '4px 0 0',
      lineHeight: 'normal',
      boxSizing: 'border-box',
      padding: '0',
      width: '100%',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      webkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      fontSize: '14px',
      color: '#555',
      fontFamily: 'inherit',
    },
  },
  checkedLabel: {
    '&::before': {
      borderColor: '#0069aa',
      borderImage: 'none',
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      content: '""',
      display: 'block',
      height: '11px',
      left: '5px',
      opacity: '1',
      position: 'absolute',
      top: '5px',
      transform: 'rotate(45deg)',
      visibility: 'visible',
      width: '5px',
      zIndex: '9',
      boxSizing: 'border-box',
    },
  },
  radioFederal: {
    display: 'flex',
  },
  clearFix: {
    clear: 'both',
  },
  dollarField: {
    background: '0 0',
    position: 'relative',
    fontSize: '17px',
    paddingRight: '10px!important',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    paddingLeft: '2px !important',
    boxSizing: 'border-box',
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
  },
  formInput: {
    width: '100%',
    boxSizing: 'border-box',
    '& input': {
      padding: '0 5px !important',
      width: '100%',
      border: 'none',
      fontFamily: 'MuseoSans-300',
      fontSize: '17px !important',
      lineHeight: '1.42857143',
      margin: '0',
      font: 'inherit',
      color: '#000',
      boxSizing: 'border-box',
      paddingLeft: '17px !important',
      background: '#fff',
      height: '45px',
    },
  },
  heightEight: {
    height: '18px',
  },
  noColor: {
    padding: '5px !important',
  },
  rightPanel: {
    width: '75%',
    padding: '0px 15px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '60%',
      padding: '18px 10px 0 10px',
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    }, 
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontWeight: '600',
      fontSize: '22px',
      textAlign: 'left',
      marginTop: '20px',
      lineHeight: '1.1',
      fontFamily: 'inherit',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
        marginTop: '0px',
      }, 
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
      }, 
    },
  },
  blueLine: {
    margin: '10px 0',
    paddingLeft: '10px',
    borderTop: '2px solid #2f75b5',
    boxSizing: 'content-box',
  },
  federalTable: {
    width: '100%',
    marginBottom: '15px',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    border: '1px solid #000',
    '& thead': {
      '& tr': {
        '& th': {
          padding: '0 8px',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'bold',
          color: '#000',
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '15px',
          }, 
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:first-child': {
          '& td': {
            borderTop: '1px solid #000',
            '&:first-child': {
              borderTop: 'none',
            },
          },
        },
        '&:last-child': {
          '& td': {
            borderBottom: '1px solid #000',
            '&:first-child': {
              borderBottom: 'none',
            },
          },
        },
        '& td': {
          fontWeight: '600',
          textAlign: 'right',
          borderRight: '1px solid #000',
          color: theme.palette.common.black,
          fontFamily: 'MuseoSans-300',
          padding: '0 8px',
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '15px',
          }, 
          '&:nth-child(3)': {
            borderRight: 'none',
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        '& td': {
          fontWeight: '600',
          textAlign: 'right',
          color: '#000',
          fontFamily: 'MuseoSans-500',
          padding: '0 8px',
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '15px',
          }, 
        },
      },
    },
  },
  tHeader: {
    background: '#0070c0',
    color: '#fff',
    borderBottom: '1px solid #000',
    '& th': {
      color: '#fff !important',
      '&:nth-child(2)': {
        textAlign: 'center',
      },
      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
  federalTaxIncome: {
    '& th': {
      textAlign: 'right',
    },
  },
  incomeRanges: {
    '& th': {
      fontFamily: 'MuseoSans-500',

      textalign: 'center',
      '&:nth-child(1)': {
        width: '4%',
      },
      '&:nth-child(2)': {
        width: '10%',
      },
      '&:nth-child(3)': {
        width: '30%',
      },
      '&:nth-child(4)': {
        width: '15%',
      },
      '&:nth-child(5)': {
        width: '15%',
      },
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
    minHeight: 'calc(100vh - 101px)',
  },
  dollarSymbol: {
    position: 'absolute',
    left: '9px',
    zIndex: '1',
  },
  federalFlow: {
    [theme.breakpoints.down( 'sm' )]: {
      overflow: 'auto',
    }, 
  },
} );

export default styles;
