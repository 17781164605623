import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import $ from 'jquery';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import * as modulesServices from '../../../calculations/modules-services';
import converyIntoThousands, { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const maintenanceMarks = [];
for ( let i = 0; i <= 5; i += 1 ) {
  maintenanceMarks.push( {
    value: i,
    label: i === 0 ? '0% of Home Value' : `${i}%`,
  } );
}
const associationFeesMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 100,
    label: '$100',
  },
  {
    value: 200,
    label: '$200',
  },
  {
    value: 300,
    label: '$300',
  },
  {
    value: 400,
    label: '$400',
  },
  {
    value: 500,
    label: '$500',
  },
];
const utilitiesFeesMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 200,
    label: '$200',
  },
  {
    value: 400,
    label: '$400',
  },
  {
    value: 600,
    label: '$600',
  },
  {
    value: 800,
    label: '$800',
  },
  {
    value: 1000,
    label: '$1k',
  },
];

const MaintenanceFeesUtilities = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;
  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;
  const [maintenance, setMaintenance] = React.useState( 0 );
  const [maintenanceValue, setMaintenanceValue] = React.useState( 0 );
  const [associationFees, setAssociationFees] = React.useState( 0 );
  const [utilities, setUtilities] = React.useState( 0 );

  useEffect(()=>{
    $( '#percentage1' ).css( 'height', `${percentageFirstVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageSecondVal}%` );
  },[percentageFirstVal, percentageSecondVal])

  useEffect( () => {
    let inputDetails = {...inputData}
    let maintenanceVal = 0;
    if ( inputData.yearly_maintanance_in_percentage !== undefined ) {
      maintenanceVal = inputData.yearly_maintanance_in_percentage;
      setMaintenance(inputData.yearly_maintanance_in_percentage);
      setMaintenanceValue( ( (purchasePrice / 100) * inputData.yearly_maintanance_in_percentage)/12 );
    } else {
      maintenanceVal = 1;
      setMaintenance( 1 );
      setMaintenanceValue( (purchasePrice / 100)/12 );
      inputDetails['yearly_maintanance_in_percentage'] = maintenanceVal;
    }

    if ( inputData.yearly_association_fees !== undefined ) {
      setAssociationFees( inputData.yearly_association_fees / 12 );
    } else {
      // updateData( 'yearly_association_fees', 0 );
      inputDetails['yearly_association_fees'] = 0;
    }

    if ( inputData.monthly_utilities !== undefined ) {
      setUtilities( inputData.monthly_utilities );
    } else {
      // updateData( 'monthly_utilities', 0 );
      inputDetails['monthly_utilities'] = 0;
    }
    updateData('total_obj',inputDetails)
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'maintenance': {
        setMaintenance( newvalue );
        setMaintenanceValue( (( purchasePrice / 100 ) * newvalue)/12 );
        updateData( mainField, newvalue );
        break;
      }
      case 'maintenance_input': {
        setMaintenanceValue( newvalue );
        let maintenanceVal = 0.0;
        if ( newvalue >= 0 && purchasePrice >= 0 ) {
          maintenanceVal = ( (newvalue*12) / purchasePrice ) * 100;
        }
        setMaintenance( maintenanceVal );
        updateData( mainField, maintenanceVal );
        break;
      }
      case 'association': {
        setAssociationFees( newvalue );
        updateData( mainField, ( newvalue * 12 ) );
        break;
      }
      case 'utilities': {
        setUtilities( newvalue );
        updateData( mainField, newvalue );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        Owning a home requires constant&nbsp;
        <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Real Estate Maintenance' ) }>maintenance</span>
        . If your home is newer, then you might expect less maintenance and vice versa. A good budgeting benchmark for maintenance is $1 per square foot per year.
      </p>
      <p>
        <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Association Fees' ) }>
          Homeowners association fees
        </span>
        , or HOA fees, are monthly fees sometimes paid by a homeowner for the general upkeep of a neighborhood such as snow removal, trash and landscape.
      </p>
      <p>Utilities are monthly expenses paid for electricity, gas, water, sewer, internet, phone, cable and security systems.</p>
      <div className={ classes.homeMainRowScroll }>
        <div className={ classes.homeMainRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Real Estate Maintenance' ) } className={ classes.dottedText }>Maintenance</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ maintenance }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 5 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'maintenance', 'yearly_maintanance_in_percentage', 'slider' ) }
                      marks={ maintenanceMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ maintenanceValue } onValueChange={ ( e ) => updateValue( e, '', 'maintenance_input', 'yearly_maintanance_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ maintenanceValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Association Fees' ) } className={ classes.dottedText }>Association Fees (HOA)</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ associationFees }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ converyIntoThousands }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 500 }
                      step={ 5 }
                      onChange={ ( e, value ) => updateValue( e, value, 'association', 'yearly_association_fees', 'slider' ) }
                      marks={ associationFeesMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ associationFees } onValueChange={ ( e ) => updateValue( e, '', 'association', 'yearly_association_fees', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ associationFees * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Utilities' ) } className={ classes.dottedText }>Utilities</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ utilities }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ converyIntoThousands }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 1000 }
                      step={ 5 }
                      onChange={ ( e, value ) => updateValue( e, value, 'utilities', 'monthly_utilities', 'slider' ) }
                      marks={ utilitiesFeesMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ utilities } onValueChange={ ( e ) => updateValue( e, '', 'utilities', 'monthly_utilities', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ utilities * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Other Home Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses - ( maintenanceValue + associationFees + utilities ) } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ ( modulesServices.module13Data.totalMonthlyExpenses - ( maintenanceValue + associationFees + utilities ) ) * 12 } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Total Home Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses * 12 } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classNames( classes.utilitiesChart, classes.utilitiesHome ) }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Home Expenses
                  <span className={ classes.chartSpan }>
                    {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses } prefix="$" /> ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classes.percentageBar } id="percentage1" />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {modulesServices.module13Data.totalIncomeYear !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalIncomeYear } prefix={ modulesServices.module13Data.totalIncomeYear >= 0 ? '$' : '($' } suffix={ modulesServices.module13Data.totalIncomeYear < 0 && ')' } />
                  ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

MaintenanceFeesUtilities.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,

};

export default withStyles( styles )( MaintenanceFeesUtilities );
