import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import * as moduleServices from '../../../calculations/modules-services';
import converyIntoThousands, { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const rateMarks = [];
for ( let i = 0; i <= 10; i += 2 ) {
  rateMarks.push( {
    value: i,
    label: i === 0 ? '0% of Rental Income' : `${i}%`,
  } );
}
const rentalIncomeMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 1000,
    label: '$1k',
  },
  {
    value: 2000,
    label: '$2k',
  },
  {
    value: 3000,
    label: '$3k',
  },
  {
    value: 4000,
    label: '$4k',
  },
  {
    value: 5000,
    label: '$5k',
  },
];

const RentalIncomeVacancyRate = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;

  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;

  const [rentalIncome, setRentalIncome] = React.useState( 0 );
  const [vacancyRate, setVacancyRate] = React.useState( 0 );
  const [vacancyRateValue, setVacancyRateValue] = React.useState( 0 );
  const [rentalIncomeGrowth, setRentalIncomeGrowth] = React.useState( 0 );
  const [rentalIncomeGrowthValue, setRentalIncomeGrowthValue] = React.useState( 0 );

  useEffect( () => {
    let inputDetails = {...inputData};
    let yearlyRentalIncome = 0;
    if ( inputData.yearly_rental_income !== undefined ) {
      yearlyRentalIncome = inputData.yearly_rental_income;
      setRentalIncome( yearlyRentalIncome );
    } else {
      yearlyRentalIncome = purchasePrice / 100;
      setRentalIncome( yearlyRentalIncome );
      inputDetails.yearly_rental_income = yearlyRentalIncome;
    }

    if ( inputData.vacancy_rate_in_percentage !== undefined ) {
      setVacancyRate( inputData.vacancy_rate_in_percentage );
      setVacancyRateValue( ( yearlyRentalIncome / 100 ) * inputData.vacancy_rate_in_percentage );
    } else {
      setVacancyRate( 5 );
      setVacancyRateValue( ( yearlyRentalIncome / 100 ) * 5 );
      inputDetails.vacancy_rate_in_percentage = 5;
    }

    if ( inputData.annual_income_growth_rate !== undefined ) {
      setRentalIncomeGrowth( inputData.annual_income_growth_rate );
      setRentalIncomeGrowthValue( ( yearlyRentalIncome / 100 ) * inputData.annual_income_growth_rate );
    } else {
      setRentalIncomeGrowth( 3 );
      setRentalIncomeGrowthValue( ( yearlyRentalIncome / 100 ) * 3 );
      inputDetails.annual_income_growth_rate = 3;
    }
    updateData( 'total_obj', inputDetails );
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let inputDetails = {...inputData};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    switch ( field ) {
      case 'yearly_rental_income': {
        setRentalIncome( newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'vacancy_rate': {
        setVacancyRate( newvalue );
        setVacancyRateValue( ( rentalIncome / 100 ) * newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'vacancy_value': {
        if ( newvalue > rentalIncome ) {
          newvalue = rentalIncome;
        }
        setVacancyRateValue( newvalue );
        let vacancyRateVal = 0;
        if ( rentalIncome > 0 ) {
          vacancyRateVal = ( newvalue / rentalIncome ) * 100;
        }
        setVacancyRate( vacancyRateVal );
        inputDetails[mainField] = vacancyRateVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'growth': {
        setRentalIncomeGrowth( newvalue );
        setRentalIncomeGrowthValue( ( rentalIncome / 100 ) * newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'growth_value': {
        if ( newvalue > rentalIncome ) {
          newvalue = rentalIncome;
        }
        setRentalIncomeGrowthValue( newvalue );
        let growthVal = 0;
        if ( rentalIncome > 0 ) {
          growthVal = ( newvalue / rentalIncome ) * 100;
        }
        setRentalIncomeGrowth( growthVal );
        inputDetails[mainField] = growthVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      default:
        break;
    }
  };

  const adjustedRentalIncome = (year, type) => {
      const tax_data  = moduleServices.taxStatementCompleteData();
      let rentalValue = 0;
      if(typeof tax_data !== 'undefined' && tax_data !== '' &&
          typeof tax_data[year] !== 'undefined' && tax_data[year] !== '' &&
          typeof tax_data[year]['REI Property'] !== 'undefined' && tax_data[year]['REI Property'] !== '' &&
          typeof tax_data[year]['REI Property'] !== 'undefined' && tax_data[year]['REI Property'] !== '' &&
          typeof tax_data[year]['REI Property']['Rental Income'] !== 'undefined' && tax_data[year]['REI Property']['Rental Income'] !== '') {
          rentalValue =  tax_data[year]['REI Property']['Rental Income'];
      }
      if(type === 'monthly') {
        rentalValue = rentalValue/12;
      }
      return (
        <NumberFormat decimalScale={ 0 } fixedDecimalScale  displayType="text" allowNegative={ false } thousandSeparator value={ rentalValue } prefix={ rentalValue >= 0 ? '$' : '($' } suffix={ rentalValue < 0 && ')' } />
      )
  }

  return (
    <div className={ classes.contentBlock }>
      <p>
        Rental income can vary widely based on type of property and location. Check your property and similar properties on
        {' '}
        <a className={ classes.anchorStyle } href="https://www.zillow.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Zillow</a>
        {' '}
        and
        {' '}
        <a className={ classes.anchorStyle } href="https://www.apartments.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Apartments.com</a>
        {' '}
        for historical rent figures.
      </p>
      <p>
        Vacancy rates also vary widely based on location. Use
        {' '}
        <a className={ classes.anchorStyle } href="https://fred.stlouisfed.org/series/RRVRUSQ156N" target="_blank" rel="budget-fy2020 noopener noreferrer">Federal Reserve Bank of St. Louis</a>
        {' '}
        to see the historical rental vacancy rates for the United States.
      </p>
      <p>
        Rental income typically does not stay the same every year. A good real estate investment’s rental income will grow most years. Check your location’s
        {' '}
        <a className={ classes.anchorStyle } href="https://www.apartmentlist.com/research/rents-growing-fastest" target="_blank" rel="budget-fy2020 noopener noreferrer">recent rental income growth rate</a>
        .
      </p>
      <div className={ classes.vacancyRowScroll }>
        <div className={ classes.vacancyRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Rental Income: Real Estate Investment Property' ) } className={ classes.dottedText }>Rental Income</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ rentalIncome }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ converyIntoThousands }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 5000 }
                      step={ 100 }
                      onChange={ ( e, value ) => updateValue( e, value, 'yearly_rental_income', 'yearly_rental_income', 'slider' ) }
                      marks={ rentalIncomeMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ rentalIncome } onValueChange={ ( e ) => updateValue( e, '', 'yearly_rental_income', 'yearly_rental_income', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ rentalIncome * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Vacancy Rate: Real Estate Investment Property' ) } className={ classes.dottedText }>Vacancy Rate</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ vacancyRate }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 10 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'vacancy_rate', 'vacancy_rate_in_percentage', 'slider' ) }
                      marks={ rateMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ vacancyRateValue } onValueChange={ ( e ) => updateValue( e, '', 'vacancy_value', 'vacancy_rate_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ vacancyRateValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p>
                      <b>
                        Adjusted Rental Income in&nbsp;
                        {( inputData.year_of_purchase + 1 )}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {adjustedRentalIncome(inputData.year_of_purchase + 1, 'monthly')}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {adjustedRentalIncome(inputData.year_of_purchase + 1, 'yearly')}
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Rental Income Growth: Real Estate Investment Property' ) } className={ classes.dottedText }>Annual Rental Income Growth</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ rentalIncomeGrowth }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 10 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'growth', 'annual_income_growth_rate', 'slider' ) }
                      marks={ rateMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ rentalIncomeGrowthValue } onValueChange={ ( e ) => updateValue( e, '', 'growth_value', 'annual_income_growth_rate', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ rentalIncomeGrowthValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p>
                      <b>
                        Adjusted Rental Income in&nbsp;
                        {( inputData.year_of_purchase + 2 )}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {adjustedRentalIncome(inputData.year_of_purchase + 2, 'monthly')}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {adjustedRentalIncome(inputData.year_of_purchase + 2, 'yearly')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classes.utilitiesChart }>
              <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Property Expenses
                    <span className={ classes.chartSpan }>
                      {moduleServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -moduleServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                    </span>
                  </div>
                <span className={ classes.percentageBar } style={{height: `calc(${percentageFirstVal}% - 72px)`}} />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Adjusted Rental Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {moduleServices.module14Data.adjustedRentalIncomeForGraph !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module14Data.adjustedRentalIncomeForGraph/12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } style={{height: `calc(${percentageSecondVal}% - 72px)`}} />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

RentalIncomeVacancyRate.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( RentalIncomeVacancyRate );
