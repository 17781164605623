import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import $ from 'jquery';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as modulesServices from '../../../calculations/modules-services';

const pmiMarks = [
  {
    value: 0,
    label: '0.00% of Mortgage',
  },
  {
    value: 0.5,
    label: '0.5%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 1.5,
    label: '1.5%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 2.5,
    label: '2.5%',
  },
  {
    value: 3,
    label: '3%',
  },
];

const PrivateMortgageInsurance = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;

  const mortgageAmount = ( inputData.purchase_price !== undefined && inputData.purchase_price > 0 && inputData.down_payment_in_percentage !== undefined ) ? ( ( inputData.purchase_price / 100 ) * ( 100 - inputData.down_payment_in_percentage ) ) : 0;

  const [pmi, setPmi] = React.useState( 0 );
  const [pmiValue, setPmiValue] = React.useState( 0 );

  useEffect(()=>{
    $( '#percentage1' ).css( 'height', `${percentageFirstVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageSecondVal}%` );
  },[percentageFirstVal, percentageSecondVal])

  useEffect( () => {
    let pmiVal = 0;
    if ( inputData.private_mortgage_insurance !== undefined && inputData.down_payment_in_percentage < 20 ) {
      pmiVal = inputData.private_mortgage_insurance;
    } else if ( inputData.down_payment_in_percentage < 20 ) {
      pmiVal = 0.5;
      updateData( 'private_mortgage_insurance', pmiVal );
    }
    setPmi( pmiVal );
    setPmiValue( (( mortgageAmount / 100 ) * pmiVal)/12 );

    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'pmi': {
        setPmi( newvalue );
        setPmiValue( (( mortgageAmount / 100 ) * newvalue)/12 );
        updateData( mainField, newvalue );
        break;
      }
      case 'pmi_input': {
        setPmiValue( newvalue );
        let pmiVal = 0.0;
        if ( newvalue >= 0 && mortgageAmount >= 0 ) {
          pmiVal = ( (newvalue*12) / mortgageAmount ) * 100;
        }
        setPmi( pmiVal );
        updateData( mainField, pmiVal );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        When you buy a home and your down payment is less than 20%, your mortgage lender will minimize its risk by requiring you to buy&nbsp;
        <span className={ classes.dottedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Private Mortgage Insurance (PMI)' ) }>private mortgage insurance (PMI)</span>
        {' '}
        before they give you the loan. Once your mortgage amount is less than 80% of your home value, you will no longer have to pay PMI. Inkwiry turns off your PMI payments once you own 20% or more of your home automatically.
      </p>
      <p>
        Private mortgage insurance typically ranges from
        {' '}
        <a className={ classes.anchorStyle } href="https://www.investopedia.com/mortgage/insurance/" target="_blank" rel="budget-fy2020 noopener noreferrer">0.2% to 1.0%</a>
        {' '}
        of the mortgage amount each year but depends on your down payment size and credit score.
      </p>
      <p>Since your down payment is greater than or equal to 20%, PMI is not required.</p>
      <div className={ classes.annualRowScroll }>
        <div className={ classes.annualRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Private Mortgage Insurance (PMI)' ) } className={ classes.dottedBorder }>Private Mortgage Insurance (PMI)</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ pmi }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 3 }
                      step={ 0.1 }
                      disabled={ inputData.down_payment_in_percentage >= 20 }
                      onChange={ ( e, value ) => updateValue( e, value, 'pmi', 'private_mortgage_insurance', 'slider' ) }
                      marks={ pmiMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale disabled={ inputData.down_payment_in_percentage >= 20 } allowNegative={ false } customInput={ TextField } thousandSeparator value={ pmiValue } onValueChange={ ( e ) => updateValue( e, '', 'pmi_input', 'private_mortgage_insurance', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ pmiValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Other Home Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses - pmiValue } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ ( modulesServices.module13Data.totalMonthlyExpenses - pmiValue ) * 12 } prefix="$" /> ) : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Total Home Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses } prefix={ modulesServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    <span>
                      {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses * 12 } prefix={ modulesServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classNames( classes.utilitiesChart, classes.utilitiesPMI ) }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Home Expenses
                  <span className={ classes.chartSpan }>
                    {modulesServices.module13Data.totalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalMonthlyExpenses } prefix={ modulesServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classes.percentageBar } id="percentage1" />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                      {modulesServices.module13Data.totalIncomeYear !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module13Data.totalIncomeYear } prefix={ modulesServices.module13Data.totalIncomeYear >= 0 ? '$' : '($' } suffix={ modulesServices.module13Data.totalIncomeYear < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

PrivateMortgageInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( PrivateMortgageInsurance );
