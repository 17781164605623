import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import _ from 'lodash';
import styles from '../../../screens/pwi/rei/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import * as moduleServices from '../../../calculations/modules-services';
import { timeline, sortTimeLine } from '../../../calculations/time-line';

const numberOfYearsList = [];
for ( let i = 1; i <= 30; i += 1 ) {
  numberOfYearsList.push( i );
}
const presentYear = new Date().getFullYear();
const years = [];
for ( let i = 0; i < 15; i += 1 ) {
  years.push( ( presentYear + i ) );
}

const InvestmentPeriod = ( props ) => {
  const { classes, inputData, updateData } = props;

  const [yearOfPurchase, setYearOfPurchase] = React.useState( '' );
  const [numberOfYears, setNumberOfYears] = React.useState( '' );

  const updateTimeLine = (startYearVal, endYearVal) => {
    if(startYearVal > 0) {
      let timelineData = [...timeline];
      const index = _.findIndex( timeline, { selectedModule: 'module14' } );
      if(index >= 0) {
        timelineData[index].startYear = startYearVal;
        timelineData[index].endYear = endYearVal;
        timelineData[index].imageSlug = 'active';
        timelineData[index].timelineOrder = startYearVal;
        sortTimeLine(timelineData);
      }
    }
  }

  useEffect( () => {
    let purchaseYear = '';
    let inputDetails = {...inputData};
    if ( inputData.year_of_purchase !== undefined && inputData.year_of_purchase !== '' && inputData.year_of_purchase >0 ) {
      purchaseYear = inputData.year_of_purchase;
    } else {
      purchaseYear = moduleServices.module1Data.start_year;
      inputDetails.year_of_purchase = purchaseYear;
    }
    setYearOfPurchase( purchaseYear );

    let saleYear = '';
    if ( inputData.year_of_sale !== undefined && inputData.year_of_sale !== '' && inputData.year_of_sale > 0 ) {
      saleYear = inputData.year_of_sale;
    } else {
      saleYear = purchaseYear + 5 -1;
      inputDetails.year_of_sale = saleYear;
    }
    inputDetails.year_diff = saleYear-purchaseYear+1
    setNumberOfYears( saleYear - purchaseYear + 1 );
    updateData( 'total_obj', inputDetails );
    updateTimeLine(inputDetails.year_of_purchase, inputDetails.year_of_sale);
    // eslint-disable-next-line
},[])

  

  

  const updateValue = ( e, field ) => {
    const value = parseInt( e.target.value, 10 );
    let inputDetails = {...inputData};
    switch ( field ) {
      case 'year_of_purchase': {
        setYearOfPurchase( value );
        inputDetails[field] = value;
        inputDetails['year_of_sale'] = value+numberOfYears-1;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'number_of_years': {
        setNumberOfYears( value );
        inputDetails[field] = value;
        inputDetails['year_of_sale'] = value+inputData['year_of_purchase']-1;
        updateData( 'total_obj', inputDetails );
        break;
      }
      default: {
        break;
      }
    }
    updateTimeLine(inputDetails['year_of_purchase'], inputDetails['year_of_sale']);
  };

  return (
    <div className={ classes.contentBlock }>
      <p>The first step in evaluating an investment property is determining your investment period. When are you going to buy the property and how long do you plan to hold that investment for? Enter your purchase year and hold period below. You&apos;ll be able to change your sale year later and see how that might affect your returns.</p>
      <CareerSketchTimeline moduleName="module14"/>
      <div className={ classes.grayBox }>
        <ul className={ classNames( classes.yearsList, classes.investList ) }>
          <li>
            I plan to buy the real estate investment property in year&nbsp;<select value={ yearOfPurchase } onChange={ ( e ) => updateValue( e, 'year_of_purchase' ) }>
              {
              years.map( ( data ) => (
                <option value={ data }>{data}</option>
              ) )
              }
            </select>.
          </li>
          <li>
            I plan to hold the property for&nbsp;<select value={ numberOfYears } onChange={ ( e ) => updateValue( e, 'number_of_years' ) }>
              {
              numberOfYearsList.map( ( data ) => (
                <option value={ data }>{data}</option>
              ) )
              }
            </select>&nbsp;years.
          </li>
          <li>
            The sale year for the real estate investment property is&nbsp;{inputData['year_of_sale'] !== undefined ? inputData['year_of_sale'] : 0}.
          </li>
        </ul>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

InvestmentPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,

};

export default withStyles( styles )( InvestmentPeriod );
