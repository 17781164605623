import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import logoImage from '../../../../../assets/img/pf101-dashboard/dashboard-logo.png';
import powerUpLogo from '../../../../../assets/img/pf101-dashboard/powerup.png';

const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );

/* eslint react/prop-types: 0 */

const PersonalFinancePurpose = ( props ) => {
  const { classes } = props;
  const [loading, setLoading] = React.useState( true );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 9,
      exercise: 2,
    },
    fetchPolicy: 'network-only',
    onCompleted( ) {
      setLoading( false );
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div>
        <div className={ classes.containerFluid }>
          <div className={ classNames( classes.container, classes.screenContainer ) }>
            <div className={ classes.dashboardLogo }>
              <Link to="/">
                <img src={ logoImage } alt="logo" />
              </Link>
            </div>
          </div>

          <div className={ classes.container }>
            <Grid container>
              <Grid item xs={ 12 } md={ 12 }>
                <div className={ classes.welcomeScreen }>
                  <div className={ classes.mainLogo }>
                    <Link to="/pf101/dashboard">
                      <img src={ powerUpLogo } alt="logo" />
                    </Link>
                  </div>
                  <div className={ classes.introScreen1 }>
                    <h3>Personal Finance With a Purpose!</h3>
                    <p>
                      A financial life plan is the key to living life on your own terms.
                      <br />
                      To complete this activity, review the Blueprint and discover what goes into a financial life plan.
                      <br />
                      Then, click on life events (circle icons) that will be a part of your 5, 10, 15 year plan such as
                      <br />
                      Higher Education and Career and watch your Career Sketch Timeline come alive!
                      <br />
                      Lastly, download the blueprint to complete this activity.
                    </p>
                    <Link className={ classes.nextButton } to="/pf101/blueprint">GO TO BLUEPRINT</Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </div>
      </div>
    </Typography>
  );
};

PersonalFinancePurpose.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PersonalFinancePurpose );
