import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import scrollToComponent from 'react-scroll-to-component';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import styles from './styles';
import BannerImage from '../../assets/img/blu-print/blueprint-header-new.png';
import financialGraphic from '../../assets/img/blu-print/financial-independence-graphic.png';
import financialInde from '../../assets/img/blu-print/financial-independence.png';
import existingAssets from '../../assets/img/blu-print/existing-collage.png';
import existingAssetsNew from '../../assets/img/blu-print/existing-assets-liabilities-graphic.png';
import existingIcon from '../../assets/img/blu-print/existing.png';
import heduBlocks from '../../assets/img/blu-print/hedu_blocks.png';
import educationGraphic from '../../assets/img/blu-print/education-graphic.png';
import investmentGraphic from '../../assets/img/blu-print/investment-graphic.png';
import familyGraphic from '../../assets/img/blu-print/family-graphic.png';
import vacayGraphic from '../../assets/img/blu-print/vacay-graphic.png';
import buyCarGraphic from '../../assets/img/blu-print/buy-car-graphic.png';
import reiGraphic from '../../assets/img/blu-print/rei-graphic.png';
import Career1 from '../../assets/img/blu-print/career-path-2.png';
import Career2 from '../../assets/img/blu-print/career-adv-2.png';
import Career3 from '../../assets/img/blu-print/addl-career-adv-2.png';
import Career4 from '../../assets/img/blu-print/further-career-adv-2.png';
import Higher1 from '../../assets/img/blu-print/higher-education-2.png';
import Higher2 from '../../assets/img/blu-print/more-higher-education-2.png';
import Higher3 from '../../assets/img/blu-print/further-higher-ed-2.png';

import investmentsNew from '../../assets/img/blu-print/investments-2.png';
import FamilyTwo from '../../assets/img/blu-print/family-2.png';
import VocationTwo from '../../assets/img/blu-print/vacation-2.png';
import BuyingCarNewIcon from '../../assets/img/blu-print/buy-car-2.png';
import BuyingHomeNewIcon from '../../assets/img/blu-print/buy-home-2.png';
import reiTwo from '../../assets/img/blu-print/rei-2.png';
import ArrowIcon from '../../assets/img/blu-print/arrow_icon.png';
import BuyingHome from '../../assets/img/blu-print/buying-a-home.png';
import closeImage from '../../assets/img/blu-print/close-img.png';
import WalkThruDotsAll from '../../screens/pwi/wallk-thru/walkThruDotsAll';
import greenTick from '../../assets/img/pf101-dashboard/circlearrow.png';
import Pageloader from '../ui/pageloader';
import { Dialog } from '@material-ui/core';
import CommonDialogCareer from '../../screens/life-event-library/common-dialog-career';

const TIME_LINE = loader('../../graphql/schema/fc/get-timeline.graphql');
const SAVE_TIMELINE = loader('../../graphql/schema/fc/save-timeline.graphql');

const totalWalkthru = [];
for (let i = 0; i <= 3; i += 1) {
  totalWalkthru.push(i);
}

const moduleTooltips = [];
moduleTooltips.module1 = { name: 'Financial Independence', img: 'financialInde' };
moduleTooltips.module2 = { name: 'Higher Education', img: 'heImage' };
moduleTooltips.module4 = { name: 'Career Path', img: 'cpImage' };
moduleTooltips.module5 = { name: 'Career Advancement', img: 'caImage' };
moduleTooltips.module6 = { name: 'More Higher Education', img: 'mheImage' };
moduleTooltips.module8 = { name: 'Additional Career Advancement', img: 'acaImage' };
moduleTooltips.module9 = { name: 'Investments', img: 'investImage' };
moduleTooltips.module10 = { name: 'Family', img: 'familyImage' };
moduleTooltips.module11 = { name: 'Vacation', img: 'vacationImage' };
moduleTooltips.module12 = { name: 'Buying a Car', img: 'carImage' };
moduleTooltips.module13 = { name: 'Buying a Home', img: 'homeImage' };
moduleTooltips.module14 = { name: 'Real Estate Investment Property', img: 'reiTwo' };
moduleTooltips.module15 = { name: 'Further Higher Education', img: 'fheImage' };
moduleTooltips.module17 = { name: 'Further Career Advancement', img: 'fcaImage' };
moduleTooltips.module18 = { name: 'Existing Assets + Liabilities', img: 'existingIcon' };

const BluePrint = (props) => {
  const { classes, history } = props;

  const [activeWalkThru, setActiveWalkThru] = React.useState('');
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [assignModules, setAssignModules] = React.useState({ module1: true });
  const [showTimeLine, setShowTimeLine] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [timeLineData, setTimeLineData] = React.useState([{
    action: 'CS', module_time_line_order: 0, selected_module: 'module1', time_line_order: 0, scenario_id: 0,
  }]);

  const [saveTimeLine] = useMutation(SAVE_TIMELINE, {
    onCompleted() {
      history.push('/timeline-sketcher');
    },
    onError() {
      setLoading(false);
      return false;
    },
  });

  useQuery(TIME_LINE, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const timeLine = [];
      const modules = { ...assignModules };
      if (response.getActiveTimeLine.length > 0) {
        response.getActiveTimeLine.forEach((data) => {
          if (data.action === 'CS' || (data.scenario_id !== '0' && data.action === 'FC')) {
            if (data.selected_module === 'module18') {
              timeLine.splice(1, 0, data);
            } else {
              timeLine.push(data);
            }
            modules[data.selected_module] = true;
          }
        });
        setTimeLineData(timeLine);
        setAssignModules(modules);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (activeWalkThru !== '') {
      const walkthrus = { ...completedWalkThruSlides };
      walkthrus[activeWalkThru] = true;
      setCompletedWalkThruSlides(walkthrus);
    }
    switch (activeWalkThru) {
      case 0:
        scrollToComponent(document.getElementById('blu-print-fi'), { offset: -100, align: 'top', duration: 500 });
        break;
      case 1:
        scrollToComponent(document.getElementById('blu-print-cp'), { offset: -50, align: 'top', duration: 500 });
        break;
      case 2:
        scrollToComponent(document.getElementById('blu-print-he'), { offset: -50, align: 'top', duration: 500 });
        break;
      case 3:
        scrollToComponent(document.getElementById('blu-print-investment'), { offset: -50, align: 'top', duration: 500 });
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [activeWalkThru]);

  useEffect(() => {
    document.getElementById('blu_print_time_line').style.display = 'none';
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const headingElement = document.getElementById('major_life_events_heading').getBoundingClientRect();
      const hT = headingElement.top;
      const hH = headingElement.height;
      const wH = window.innerHeight;
      const wS = window.pageYOffset;
      if (wS > (hT + hH - wH)) {
        document.getElementById('blu_print_time_line').style.display = 'block';
      } else {
        document.getElementById('blu_print_time_line').style.display = 'none';
      }
    };

    const onScroll1 = () => {
      document.getElementById('blu_print_time_line').style.display = 'none';
    };
    const debounced = _.debounce(onScroll, 1000);
    const debounced1 = _.debounce(onScroll1, 150, { leading: true, trailing: false });
    window.addEventListener('scroll', debounced);
    window.addEventListener('scroll', debounced1);
    return () => {
      debounced.cancel();
      debounced1.cancel();
      window.removeEventListener('scroll', debounced);
      window.removeEventListener('scroll', debounced1);
    };
  });

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThru(0);
    localStorage.removeItem('walkthruopen');
  }

  const singleClickEvent = (module) => {
    const modules = { ...assignModules };
    if (modules[module] !== undefined) {
      modules[module] = !modules[module];
    } else {
      modules[module] = true;
    }
    setAssignModules(modules);

    let timeLine = [...timeLineData];

    if (modules[module] === true) {
      const order = {
        action: 'CS', module_time_line_order: (timeLine.length), selected_module: module, time_line_order: (timeLine.length), scenario_id: 0,
      };
      if (module === 'module18') {
        timeLine.splice(1, 0, order);
      } else {
        timeLine.push(order);
      }
    } else {
      const scenarioIndex = _.findIndex(timeLine, { selected_module: module });
      if (scenarioIndex >= 0) {
        timeLine.splice(scenarioIndex, 1);
      }
    }
    timeLine = timeLine.map((value, index) => {
      const timeLineVal = value;
      timeLineVal.module_time_line_order = index;
      timeLineVal.time_line_order = index;
      return timeLineVal;
    });
    setTimeLineData(timeLine);
  };
  const doubleClickEvent = (link) => {
    history.push(link);
  };

  const updateTimeLine = () => {
    const token = localStorage.getItem('app-token');    
    if (token) {
      setLoading(true);
      const timeLineVal = timeLineData.map((data) => _.pick(data, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id']));
      saveTimeLine({
        variables: {
          data: {
            name: 'Active Selection',
            order: timeLineVal,
          },
        },
      });
    } else {
      setOpen(true);
    }


  };

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}

      {showTimeLine === false && (
        <div role="button" aria-hidden="true" data-for="timeline" data-tip="Show Timeline" onClick={() => { setShowTimeLine(true); }} className={classes.popupDivClick}>
          <ReactTooltip id="timeline" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.tooltipLeft)} />
          <img src={closeImage} alt="" />
        </div>
      )}

      <div className={classes.BluePrintBanner}>
        <img src={BannerImage} alt="" />
        <div className={classes.BluePrintBannerContent}>
          <h2>Your Career Sketching Blueprint</h2>
          <h3>to Build a Financial Life Plan</h3>
        </div>
      </div>
      <div className={classes.financialLife}>
        <div className={classes.container}>
          <h3>A financial life plan is the key to living life on your own terms</h3>
          <Grid container>
            <Grid sm={6} xs={12} className={classes.leftLife}>
              <p>
                <span>When you have a financial life plan in place,</span>
                {' '}
                you have the blueprint to living your dream – you’ve identified and planned for a higher education and career path you’re passionate about, one that rewards you financially, personally and gives you the freedom to pursue all the interests you value.
              </p>
            </Grid>
            <Grid sm={6} xs={12}>
              <p>To start building your financial life plan, click on the life events below that will be in your 5, 10, 15 year plan. Beginning with Financial Independence, Inkwiry will automatically build your career sketch timeline based on your selections and kickstart the planning process. Then, go to your career sketch timeline and plug in your details.</p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.financialLifeEvent}>
        <h3 id="major_life_events_heading">YOUR FINANCIAL LIFE EVENTS</h3>
        <div className={classes.container} id="blu-print-fi">
          <Grid container>
            <Grid sm={5} xs={12} className={classes.relativeBlock}>
              <div className={classes.begainBlock}>

                <div role="button" aria-hidden="true" onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/fi')} className={classNames(classes.financialBlockBox, assignModules.module1 === true && classes.financialBlockBoxActive)}>
                  <img src={financialInde} alt="" />
                  <h4>Financial Independence</h4>
                  {assignModules.module1 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>

                <h2>Begin your journey ... You&apos;ve got this!</h2>
              </div>
              {activeWalkThru === 0 && (
                <div className={classNames(classes.fcWalkthru, classes.fcWalkthruOne, classes.fcActiveWalkthru)}>
                  <h4>Get started with an Inkwiry Walk-Thru</h4>
                  <p>Plan With Inkwiry is as easy as 1, 2, 3. First, we build your timeline.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(1); }} className={classes.startWalkThru}>Start</Button>
                      <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid sm={5} xs={12}>
              <div className={classes.fancialGraphic}>
                <img src={financialGraphic} alt="" />
              </div>
            </Grid>
            <Grid sm={2} xs={12}>
              <div className={classes.financialBlockBoxRight}>
                <p>In this module, you will enter your start year and answer a few questions to set the Simulator Engine in motion.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.ExitingAssets}>
        <div className={classes.containerRow}>
          <Grid container>
            <Grid sm={4} xs={12}>
              <div className={classes.ExitingAssetsLeft}>
                <img src={existingAssets} alt="" />
              </div>
            </Grid>
            <Grid sm={5} xs={12}>
              <div className={classes.assestBoxMain}>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBox, assignModules.module18 === true && classes.assetsBoxActive)} onClick={() => singleClickEvent('module18')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/existing')}>
                  <img src={existingIcon} alt="" />
                  <h4>Existing Assets + Liabilities</h4>
                  {assignModules.module18 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>

                <h2>What you own, what you owe</h2>
                <p>Develop a plan to effectively manage your existing assets and liabilities such as outstanding student loans, credit card debt, a car or a home.</p>
              </div>
            </Grid>
            <Grid sm={3} xs={12}>
              <div className={classes.ExitingAssetsRight}>
                <img src={existingAssetsNew} alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.careerPath} id="blu-print-cp">
        <div className={classes.container}>
          <Grid container className={classes.careerPathBg}>
            <Grid sm={8} xs={12}>
              <div className={classes.careerPathLeft}>
                <h3>Plan your career with stepping stones to achieve your goals</h3>
                <ul>
                  <li>Consider your options for advanced positions, promotions, job changes</li>
                  <li>Forecast estimated income for each position </li>
                  <li>Know your ability to cover living expenses while paying off higher education debt</li>
                  <li>Manage your risks and maximize your rewards</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={4} xs={12}>
              <div className={classes.careerPathRight}>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBox, classes.assetsBoxOne, assignModules.module4 === true && classes.careerBoxActive)} onClick={() => singleClickEvent('module4')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/cp')}>
                  <img src={Career1} alt="" />
                  <h4>Career Path</h4>
                  {assignModules.module4 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBox, classes.assetsBoxTwo, assignModules.module5 === true && classes.careerAdvBoxActive)} onClick={() => singleClickEvent('module5')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/ca')}>
                  <img src={Career2} alt="" />
                  <h4>Career Advancement</h4>
                  {assignModules.module5 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBox, classes.assetsBoxThree, assignModules.module8 === true && classes.addCareerAdvBoxActive)} onClick={() => singleClickEvent('module8')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/aca')}>
                  <img src={Career3} alt="" />
                  <h4>
                    Additional
                    <br />
                    Career Advancement
                  </h4>
                  {assignModules.module8 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBox, classes.assetsBoxFour, assignModules.module17 === true && classes.furtherCareerAdvBoxActive)} onClick={() => singleClickEvent('module17')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/fca')}>
                  <img src={Career4} alt="" />
                  <h4>
                    Further
                    <br />
                    Career Advancement
                  </h4>
                  {assignModules.module17 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {activeWalkThru === 1 && (
            <div className={classNames(classes.fcWalkthru, classes.fcWalkthruTwo, classes.fcActiveWalkthru)}>
              <h4>Think career</h4>
              <p>There are many life events on the Career Sketching Blueprint. When thinking about your future, think career first. What career are you pursuing? Click on the Career Path module to the right to add the module to your timeline. You’re probably not going to stay in one career move for the next 5, 10, 15 years. Add the Career Advancement module too and plan that next move.</p>
              <div className={classes.walkthruBottom}>
                <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                <div className={classes.walkThruBtns}>
                  <Button onClick={() => { setActiveWalkThru(2); }} className={classes.startWalkThru}>Next</Button>
                  <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.HigherEducation} id="blu-print-he">
        <div className={classes.container}>
          <Grid container>
            <Grid sm={3} xs={12}>
              <div className={classes.HigherEducationLeft}>
                <img src={heduBlocks} alt="" />
              </div>
            </Grid>
            <Grid sm={4} xs={12}>
              <div className={classes.HigherEducationMiddle}>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationOne, assignModules.module2 === true && classes.HigherEducationOneActive)} onClick={() => singleClickEvent('module2')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/he')}>
                  <img src={Higher1} alt="" />
                  <h4>Higher Education</h4>
                  {assignModules.module2 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationTwo, assignModules.module6 === true && classes.HigherEducationTwoActive)} onClick={() => singleClickEvent('module6')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/mhe')}>
                  <img src={Higher2} alt="" />
                  <h4>
                    More
                    <br />
                    Higher Education
                  </h4>
                  {assignModules.module6 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationThree, assignModules.module15 === true && classes.HigherEducationThreeActive)} onClick={() => singleClickEvent('module15')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/fhe')}>
                  <img src={Higher3} alt="" />
                  <h4>
                    Further
                    <br />
                    Higher Education
                  </h4>
                  {assignModules.module15 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
            <Grid sm={5} xs={12}>
              <div className={classes.HigherEducationRight}>
                <h3>Acquire the skills and knowledge you need</h3>
                <ul>
                  <li>Choose your school</li>
                  <li>Identify sources of funding</li>
                  <li>Know your ability to repay student loans</li>
                  <li>Advance your career with additional skills, degrees, certifications</li>
                  <li>Expand your knowledge and open new horizons</li>
                </ul>
                <img src={educationGraphic} alt="" />
              </div>
              {activeWalkThru === 2 && (
                <div className={classNames(classes.fcWalkthru, classes.fcWalkthruThree, classes.fcActiveWalkthru)}>
                  <h4>Then, Think Education</h4>
                  <p>How do you acquire the education you need? Are you planning for college, career school, military school or some form of graduate school? Click on the Higher Education module to the left to add the module to your timeline if you are planning for some additional education.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(3); }} className={classes.startWalkThru}>Next</Button>
                      <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.investments} id="blu-print-investment">
        <div className={classes.container}>
          <Grid container className={classes.investTop}>
            <Grid sm={4} xs={12}>
              <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.assetsBoxx, classes.investBoxNew, assignModules.module9 === true && classes.investBoxNewActive)} onClick={() => singleClickEvent('module9')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/investments')}>
                <img src={investmentsNew} alt="" />
                <h4>Investments</h4>
                {assignModules.module9 === true && (
                  <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                )}
              </div>
            </Grid>
            <Grid sm={7} xs={12} className={classes.relativeBlock}>
              <h3>Start early to build more equity over your lifetime</h3>
              {activeWalkThru === 3 && (
                <div className={classNames(classes.fcWalkthru, classes.fcWalkthruFour, classes.fcActiveWalkthru)}>
                  <h4>Then, add the rest of your future life events</h4>
                  <p>Once you have your education and career modules on the timeline, fill in the rest of life’s pieces. Investments, buying a car, buying a home, and more. What are you trying to achieve? Add that module to the timeline, build your details, and you are on your way to achieving your goals. When you have all the modules on the timeline that you want to add to your plan, click to go to your timeline.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.investBottom}>
            <Grid sm={6} xs={12}>
              <ul>
                <li>See how much income you can regularly invest over the short and long term</li>
                <li>Maximize the value of compounding interest</li>
                <li>Learn about different type of investments</li>
                <li>Understand the inherent risks vs. rewards of investing</li>
              </ul>
            </Grid>
            <Grid sm={5} xs={12}>
              <img src={investmentGraphic} alt="" />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.familyGroup}>
        <div className={classes.container}>
          <Grid container className={classes.familyGroupNew}>
            <Grid sm={5} xs={12}>
              <img src={familyGraphic} alt="" />
            </Grid>
            <Grid sm={7} xs={12}>
              <div className={classes.familyGroupTop}>
                <h3>Plan and prepare</h3>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.familyBox, assignModules.module10 === true && classes.familyBoxActive)} onClick={() => singleClickEvent('module10')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/family')}>
                  <img src={FamilyTwo} alt="" />
                  <h4>Family</h4>
                  {assignModules.module10 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>

              </div>
              <div container className={`${classes.investBottomRight} ${classes.familyList}`}>
                <ul>
                  <li>How might a family change your financial situation?</li>
                  <li>Weigh the financial benefits and requirements</li>
                  <li>Know what to expect</li>
                  <li>See how envisioning your ideal scenario can help align your goals and make them reality.</li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.VocationGroup}>
        <div className={classes.container}>
          <Grid container className={classes.familyGroupGrid}>
            <Grid sm={6} xs={12} className={classes.familyGroupLeft}>
              <div className={classes.VocationGroupTop}>

                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.vocationTwo, assignModules.module11 === true && classes.VocationTwoActive)} onClick={() => singleClickEvent('module11')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/vacation')}>
                  <img src={VocationTwo} alt="" />
                  <h4>Vacation + Special Purchases</h4>
                  {assignModules.module11 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
              </div>
              <div container className={classes.VocationGroupGrid}>
                <h3>Plan for your relaxation and joy</h3>
                <ul>
                  <li>Budget for regular vacations, special trips and travel</li>
                  <li>If there&apos;s something special that you must have, create a plan to make it happen</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={6} xs={12}>
              <img src={vacayGraphic} alt="" />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.BuyingCar}>
        <div className={classes.container}>
          <Grid container className={classes.familyGroupGrid}>
            <Grid sm={6} xs={12} className={classes.familyGroupLeft}>
              <div container className={classes.VocationGroupGrid}>
                <h3>Buy or lease? Find your best deal</h3>
                <ul>
                  <li>Enter your purchase price, down payment and the value of any rebate, discount, trade-in</li>
                  <li>Specify your loan terms and rate, understand the details</li>
                  <li>Learn the difference between buying and leasing</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={6} xs={12}>
              <div className={classes.buyingCarIconBox}>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.BuyingCarNew, assignModules.module12 === true && classes.BuyingCarNewActive)} onClick={() => singleClickEvent('module12')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/car')}>
                  <img src={BuyingCarNewIcon} alt="" />
                  <h4>Buying a Car</h4>
                  {assignModules.module12 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
            <img src={buyCarGraphic} alt="" />
          </Grid>
        </div>
      </div>
      <div className={`${classes.financialLifeEvent} ${classes.BuyingHome}  ${classes.BuyingHomeNew}`}>
        <div className={classes.containerRow}>
          <Grid container>

            <Grid sm={5} xs={12}>
              <div className={`${classes.fancialGraphic} ${classes.BuyingHomeFirst}`}>
                <img src={BuyingHome} alt="" />
              </div>
            </Grid>
            <Grid sm={3} xs={12}>
              <div className={`${classes.begainBlock} ${classes.begainBlockBottom}`}>
                <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.BuyingHomeIcon, assignModules.module13 === true && classes.BuyingHomeIconActive)} onClick={() => singleClickEvent('module13')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/home')}>
                  <img src={BuyingHomeNewIcon} alt="" />
                  <h4>Buying a Home</h4>
                  {assignModules.module13 === true && (
                    <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                  )}
                </div>
                <h2>Know what you can afford and when</h2>
              </div>
            </Grid>
            <Grid sm={4} xs={12}>
              <ul>
                <li>Walk through the home buying process step by step</li>
                <li>Anticipate maintenance, repairs and/or improvements</li>
                <li>Learn the lingo, understand how mortgages work</li>
                <li>Employ competitive market analysis to assess value and compare pricing</li>
                <li>Perform diligent research so you know when you&apos;re getting a good deal.</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={`${classes.BuyingCar} ${classes.buyingInvset}`}>
        <div className={classes.container}>
          <Grid sm={6} xs={12}>
            <div role="button" aria-hidden="true" className={classNames(classes.financialBlockBox, classes.buyingInvsetIcon, assignModules.module14 === true && classes.buyingInvsetIconActive)} onClick={() => singleClickEvent('module14')} onDoubleClick={() => doubleClickEvent('/plan-with-inkwiry/rei')}>
              <img src={reiTwo} alt="" />
              <h4>Buying an Investment Property</h4>
              {assignModules.module14 === true && (
                <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
              )}
            </div>
          </Grid>
          <Grid container className={classes.familyGroupGrid}>

            <Grid sm={5} xs={12} className={classes.familyGroupLeft}>
              <div container className={classes.VocationGroupGrid}>
                <ul>
                  <li>Run a financial model in minutes</li>
                  <li>Forecast monthly and yearly cash flow</li>
                  <li>Adjust your numbers to reduce risk and maximize reward</li>
                  <li>Compare different scenarios to find your best option</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={7} xs={12}>
              <div className={classes.buyingInvsetRight}>
                <h3>Explore real estate</h3>
                <img src={reiGraphic} alt="" />
              </div>
            </Grid>

          </Grid>
        </div>
      </div>
      <div className={classes.bluePrintClose}>
        <div className={classes.container}>
          <Grid container>
            <Grid sm={6} xs={12}>
              <h4>Keep your financial life blueprint close at hand.</h4>
              <p>Download the PDF, share, and collaborate with family and friends.</p>
            </Grid>
            <Grid sm={2} xs={12}>
              <img src={ArrowIcon} alt="" />
            </Grid>
            <Grid sm={4} xs={12}>
              <Button To="/" className={classes.bluePrint}>Download BluePrint</Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classNames(classes.dragClick, showTimeLine === false && classes.addingLinkModules)} id="blu_print_time_line">
        <div className={classes.container}>
          <div className={classNames(classes.dragClickInner, showTimeLine === false && classes.timeCloseAnimation)}>
            <div className={classes.timeLineClose}>
              <span role="button" aria-hidden="true" onClick={() => { setShowTimeLine(false); }}>HIDE</span>
            </div>
            <div className={classes.eventListHeader}>
              <h3>Select and click on life event titles to start your plan</h3>
            </div>
            <div className={classes.eventPopup}>
              <ul className={classes.eventsList}>
                {
                  timeLineData.map((data) => (
                    // <li data-for="timelineevents" data-tip="Financial Independence"><img src={ moduleTooltips[data.selected_module].img } alt="" /></li>
                    <li data-for="timelineevents" data-tip="Financial Independence" className={classes[moduleTooltips[data.selected_module].img]}>
                      <span />
                    </li>
                  ))
                }
                <span className={classes.borderLine} />
              </ul>
            </div>
            <div className={classes.updateTimelineBtn}>
              <Button onClick={updateTimeLine}>I&apos;m done - go to my timeline</Button>
            </div>
            <ReactTooltip id="timelineevents" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        className={ classes.getStartedPopupDialog }
      >
        <div className={classes.btnClose}>
          <i className="la la-times" onClick={()=>{setOpen(false)}}/> 
        </div>
        <CommonDialogCareer />
      </Dialog>      
    </Typography>
    
  );
};

BluePrint.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BluePrint);
