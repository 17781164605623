import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import * as modulesServices from '../../../calculations/modules-services';

const MoneyNeeded = (props) => {
  const {
    classes, summery, handleSlide
  } = props;

  const [moneyNeededShow, setMoneyNeededShow] = useState(summery === true);

  const handleMoneyNeededShow = () => {
    setMoneyNeededShow(!moneyNeededShow);
  };

  return (
    <Grid sm={6}>
      <div className={classNames(classes.giftSources, classes.heSources)}>
        <table cellPadding="0" cellSpacing="0">
          {moneyNeededShow
            && (
              <thead>
                <th colSpan={3} align="center">Money Needed</th>
              </thead>
            )}
          <tbody>
            {moneyNeededShow && (
              <React.Fragment>
                <tr aria-hidden="true" onClick={() => handleSlide(6, 'back')} className={classes.cursorPointer}>
                  <td />
                  <td>Tuition & Fees</td>
                  <td>
                    <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newTotalTuitionAndFees} displayType="text" thousandSeparator prefix="$" />
                  </td>
                </tr>
                <tr aria-hidden="true" onClick={() => handleSlide(6, 'back')} className={classes.cursorPointer}>
                  <td />
                  <td>Room & Board</td>
                  <td>
                    <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newTotalRoomAndBoard} displayType="text" thousandSeparator prefix="$" />
                  </td>
                </tr>
                <tr aria-hidden="true" onClick={() => handleSlide(8, 'back')} className={classes.cursorPointer}>
                  <td />
                  <td>On-Campus Living Expenses</td>
                  <td>
                    <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newOnCampusLivingExpenses} displayType="text" thousandSeparator prefix="$" />
                  </td>
                </tr>
                <tr aria-hidden="true" onClick={() => handleSlide(8, 'back')} className={classes.cursorPointer}>
                  <td />
                  <td>Off-Campus Living Expenses</td>
                  <td>
                    <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newOffCampusLivingExpenses} displayType="text" thousandSeparator prefix="$" />
                  </td>
                </tr>
                {modulesServices.module15Data.newHigherTotalStudentsLoanPayments !== undefined && modulesServices.module15Data.newHigherTotalStudentsLoanPayments > 0 && (
                  <tr>
                    <td />
                    <td>Total Student Loan Payments during College</td>
                    <td>
                      <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newHigherTotalStudentsLoanPayments} displayType="text" thousandSeparator prefix="$" />
                    </td>
                  </tr>
                )}
                {modulesServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt !== undefined && modulesServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt > 0 && (
                  <tr>
                    <td />
                    <td>Other Interest Expenses on Other Debt</td>
                    <td>
                      <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt} displayType="text" thousandSeparator prefix="$" />
                    </td>
                  </tr>
                )}
                {modulesServices.module15Data.newHigherCarExpense !== undefined && modulesServices.module15Data.newHigherCarExpense > 0 && (
                  <tr>
                    <td />
                    <td>Car Expenses</td>
                    <td>
                      <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newHigherCarExpense} displayType="text" thousandSeparator prefix="$" />
                    </td>
                  </tr>
                )}
                {modulesServices.module15Data.newHigherRealEstateExpenses !== undefined && modulesServices.module15Data.newHigherRealEstateExpenses > 0 && (
                  <tr>
                    <td />
                    <td>Real Estate Expenses</td>
                    <td>
                      <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newHigherRealEstateExpenses} displayType="text" thousandSeparator prefix="$" />
                    </td>
                  </tr>
                )}
                {modulesServices.module15Data.newHigherChildrenAndPetExpenses !== undefined && modulesServices.module15Data.newHigherChildrenAndPetExpenses > 0 && (
                  <tr>
                    <td />
                    <td>Children and Pet Expenses</td>
                    <td>
                      <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.newHigherChildrenAndPetExpenses} displayType="text" thousandSeparator prefix="$" />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )}

            <tr className={classNames(classes.trFoot, classes.cursorPointer)} onClick={summery === true ? '' : handleMoneyNeededShow}>
              <td>{summery === true ? '' : <i className={!moneyNeededShow ? 'fa fa-plus' : 'fa fa-minus'} />}</td>
              <td>Total Money Needed</td>
              <td><NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module15Data.educationTotalAttendance} displayType="text" thousandSeparator prefix="$" /></td>
            </tr>

          </tbody>
        </table>
      </div>
    </Grid>
  );
};

MoneyNeeded.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSlide: PropTypes.func.isRequired,
  summery: PropTypes.bool,
};

MoneyNeeded.defaultProps = {
  summery: false
}

export default withStyles(styles)(MoneyNeeded);
