import React, {useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-car/styles';

const SecondcarLeaseSummary = ( props ) => {
  const { classes, handleBuyingCarObject } = props;

  const totalIncomeInYear = ( year ) => {
    const incomeData = moduleServices.incomeStatementCompleteData();
    if ( typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[year] !== 'undefined' && incomeData[year] !== '' && typeof incomeData[year]['Total Income'] !== 'undefined' && incomeData[year]['Total Income'] !== '' ) {
      return incomeData[year]['Total Income'];
    }
    return 0;
  };

  const totalIncomePercentageLease2 = ( year ) => {
    const totalIncomeInYr = totalIncomeInYear( year );
    if ( totalIncomeInYr > 0 ) {
      const expenses = moduleServices.leasingSecondCarTotalAnnualExpenses();
      return ( expenses / totalIncomeInYr ) * 100;
    }
    return 0;
  };

  useEffect(()=>{
    totalIncomeInYear();
    totalIncomePercentageLease2();
  })

  return (
    <div className={ classes.contentBlock }>
      <p>Remember, a car lease is a contract between you and a leasing company where the leasing company provides a vehicle to you in exchange for monthly payments. A down payment and other fees will be required to start the lease. Plus, ongoing monthly expenses.</p>
      <p>Leasing may be confusing, but with your new plan, you&apos;ll have confidence in leasing your next car!</p>
      <div className={ classes.summaryTables }>
        <div className={ classes.summaryLeftTable }>
          <table>
            <thead>
              <tr>
                <th>Lease Summary</th>
                <th>
                  Year
                  {' '}
                  {' '}
                  {handleBuyingCarObject.leasing_second_car_start_year}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cash Needed at Purchase</td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarDownPaymentSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarDownPaymentSummary } prefix={ moduleServices.module12Data.leasingSecondCarDownPaymentSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarDownPaymentSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Total Lease Payments</td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarTotalPaymentSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarTotalPaymentSummary } prefix={ moduleServices.module12Data.leasingSecondCarTotalPaymentSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarTotalPaymentSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Total Other Expenses</td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarTotalExpensesSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarTotalExpensesSummary } prefix={ moduleServices.module12Data.leasingSecondCarTotalExpensesSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarTotalExpensesSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.blueRow }>
                <td><b>Total Cost of Lease</b></td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarTotalCostsSummary !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarTotalCostsSummary } prefix={ moduleServices.module12Data.leasingSecondCarTotalCostsSummary >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarTotalCostsSummary < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classes.blueRow }>
                <td>
                  <b>
                    Cash Balance at {' '}
                    {parseInt( handleBuyingCarObject.leasing_second_car_start_year, 10 ) - 1}
                    {' '}
                    Year End
                  </b>
                </td>
                <td>
                  {console.log('event',handleBuyingCarObject.leasing_second_car_start_year,handleBuyingCarObject)}
                  <b>
                    {moduleServices.totalCashAvailableYear( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                      <NumberFormat
                        decimalScale={ 0 }
                        fixedDecimalScale
                        className={ classes.formInput }
                        displayType="text"
                        allowNegative={ false }
                        thousandSeparator
                        value={ moduleServices.totalCashAvailableYear( handleBuyingCarObject.leasing_second_car_start_year ) }
                        prefix={ moduleServices.totalCashAvailableYear( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '$' : '($' }
                        suffix={ moduleServices.totalCashAvailableYear( handleBuyingCarObject.leasing_second_car_start_year ) < 0 && ')' }
                      />
                    ) : ( '$0' )}
                  </b>
                </td>
              </tr>
              <tr className={ classes.blueRow }>
                <td>
                  <b>
                    Total Investments at {' '}
                    {parseInt( handleBuyingCarObject.leasing_second_car_start_year, 10 ) - 1}
                    {' '}
                    Year End
                  </b>
                </td>
                <td>
                  <b>
                    {moduleServices.totalLiquidYear( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.totalLiquidYear( handleBuyingCarObject.leasing_second_car_start_year ) } prefix={ moduleServices.totalLiquidYear( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '$' : '($' } suffix={ moduleServices.totalLiquidYear( handleBuyingCarObject.leasing_second_car_start_year ) < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={ classNames( classes.summaryLeftTable, classes.rightTable ) }>
          <table>
            <thead>
              <tr>
                <th>Monthly Expenses</th>
                <th>Monthly</th>
                <th>Annual</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lease Payment</td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_lease_payment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_lease_payment } prefix={ moduleServices.module12Data.leasing_second_car_lease_payment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_lease_payment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_lease_payment !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_lease_payment * 12 } prefix={ moduleServices.module12Data.leasing_second_car_lease_payment >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_lease_payment < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Maintenance</td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_maintenance !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_maintenance } prefix={ moduleServices.module12Data.leasing_second_car_maintenance >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_maintenance < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_maintenance !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_maintenance * 12 } prefix={ moduleServices.module12Data.leasing_second_car_maintenance >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_maintenance < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Car Insurance</td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_insurance !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_insurance } prefix={ moduleServices.module12Data.leasing_second_car_insurance >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_insurance < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_insurance !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_insurance * 12 } prefix={ moduleServices.module12Data.leasing_second_car_insurance >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_insurance < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Gas</td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_gas !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_gas } prefix={ moduleServices.module12Data.leasing_second_car_gas >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_gas < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.leasing_second_car_gas !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasing_second_car_gas * 12 } prefix={ moduleServices.module12Data.leasing_second_car_gas >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasing_second_car_gas < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classNames( classes.blueRow, classes.boldText ) }>
                <td>Total Expenses</td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses } prefix={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses * 12 } prefix={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module12Data.leasingSecondCarTotalMonthlyExpenses < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.blueRow }>
                <td className={ classes.leftPadding }><i>% of Total Income</i></td>
                <td>
                  {totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) / 12 } prefix={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </td>
                <td>
                  {totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) } prefix={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '' : '(' } suffix={ totalIncomePercentageLease2( handleBuyingCarObject.leasing_second_car_start_year ) < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </td>
              </tr>
              <tr className={ classNames( classes.blueRow, classes.boldText ) }>
                <td>
                  Total Income in Year {''}
                  {handleBuyingCarObject.leasing_second_car_start_year}
                </td>
                <td>
                  {totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) /12 } prefix={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) } prefix={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) >= 0 ? '$' : '($' } suffix={ totalIncomeInYear( handleBuyingCarObject.leasing_second_car_start_year ) < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

  );
};

SecondcarLeaseSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,

};

export default withStyles( styles )( SecondcarLeaseSummary );
