import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import {
  Container,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import * as commonFunctions from '../../utilities/commonFunctions';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import styles from './styles';
import Pageloader from '../../components/ui/pageloader';
import listImage from '../../assets/img/contact_check.png';

const CONTACTFORM = loader('../../graphql/schema/contact/contact.graphql');

let GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
const DELAY = 1500;


const Contact = (props) => {
  const { classes } = props;
  const recaptchaRef = React.createRef();

  const [errorMessage, setErrorMessage] = useState('');
  const [sucessPopup, setSucessPopup] = useState(false);
  const [load, setLoad] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [topicError, setTopicError] = useState(false);
  const [formValid, setFormValid] = useState(null);
  const [isverified, setIsVerified] = useState(false);
  const [captchamsg, setCaptchaMsg] = useState('');

  console.log('formValid-----', formValid)

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
  }, [load]);

  const handleSuccessPopupClose = () => {
    setSucessPopup(false);
  };

  const [submitContactForm, { loading }] = useMutation(CONTACTFORM, {
    onCompleted({
      contact: {
        status,
      },
    }) {
      if (status) {
        setSucessPopup(true);
        formValid.reset();
        formValid.resetFieldState('first_name');
        formValid.resetFieldState('last_name');
        formValid.resetFieldState('email');
        formValid.resetFieldState('topic');
        setSubmitClicked(false);
        formValid.resetFieldState('message');
        setFormValid(null);
        setTopicError(false);
        setIsVerified(false);
        // recaptchaRef.reset();
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setErrorMessage(formatedErrors);
    },
  });

  const handleSubmitClick = () => {
    setSubmitClicked(true);
    if (isverified === false) {
      setCaptchaMsg('Please resolve the captcha and submit');
    }
    if (isverified === true) {
      setCaptchaMsg('');
    }
  };

  const handleChange = (value) => {
    setIsVerified(true)
  };


  const onSubmit = (values, form) => {
    if (isverified) {
      submitContactForm({
        variables: {
          data: {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            company_name: values.company_name,
            zip_code: values.zip_code,
            country: values.country,
            topic: values.topic,
            message: values.message,
          },
        },
      });
      recaptchaRef.current.reset();
    }
    setFormValid(form);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = 'First Name is required';
    }
    if (!values.last_name) {
      errors.last_name = 'Last Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!commonFunctions.validateEmail(values.email)) {
      errors.email = 'Please enter valid email';
    }
    if (!values.topic) {
      if (submitClicked) {
        setTopicError(true);
      }
      errors.topic = 'Topic is required';
    } else {
      setTopicError(false);
    }
    if (!values.message) {
      errors.message = 'Please enter your Question/Comment';
    }
    return errors;
  };

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.contactPage}>
        <Container className={classes.container}>
          <div className={classes.contactRow}>
            <div className={classes.bannerBlock}>
              <Typography
                className={classes.bannerTitle}
                variant="h2"
                component="h2"
              >
                We want to hear from you.
              </Typography>
              <p className={classes.bannerIntro}>
                Have a question, a comment, or want to tell us something
                special? Fill out this short form and we’ll get back to you as
                soon as possible.
              </p>
            </div>
            <div className={classes.contactContnt}>
              <div className={classes.getInTouch}>
                <Typography variant="h3" component="h3">
                  Get in touch.
                </Typography>
                <p>
                  Please enter your contact information, select your topic and
                  send us your questions and comments.
                </p>
                {errorMessage && <span>{errorMessage}</span>}
                <Form
                  validate={validate}
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form
                      className={classes.formContact}
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className={classes.formGroup}>
                        <div className={classes.leftInput}>
                          <Field
                            id="first_name"
                            placeholder="First Name *"
                            type="text"
                            name="first_name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            component={TextField}
                          />
                        </div>
                        <div className={classes.rightInput}>
                          <Field
                            id="last_name"
                            placeholder="Last Name *"
                            type="text"
                            name="last_name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            component={TextField}
                          />
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <Field
                          id="email"
                          placeholder="Email Address *"
                          type="email"
                          name="email"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <Field
                          id="company_name"
                          placeholder="Company, School or Organization"
                          type="text"
                          name="company_name"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <div className={classes.leftInput}>
                          <Field
                            id="zip_code"
                            placeholder="Zip Code"
                            type="text"
                            name="zip_code"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            component={TextField}
                          />
                        </div>
                        <div className={classes.rightInput}>
                          <Field
                            name="country"
                            className={classes.formSelect}
                            component="select"
                          >
                            <option value="">Select</option>
                            <option value="United States">United States</option>
                          </Field>
                        </div>
                      </div>
                      <div className={classNames(classes.formGroup, classes.contactTopicformGroup)}>
                        <Field
                          id={topicError ? 'topicError' : 'topic'}
                          name="topic"
                          component="select"
                          variant="outlined"
                          className={classNames(classes.formSelect, classes.contactTopicSelect)}
                        >
                          <option value="">- Select Your Topic -</option>
                          <option value="Account Information">Account Information</option>
                          <option value="Career Sketching">Career Sketching</option>
                          <option value="Inkwiry Sense">Inkwiry Sense</option>
                          <option value="Inkwiry Spaces">Inkwiry Spaces</option>
                          <option value="Plan With Inkwiry">Plan With Inkwiry</option>
                          <option value="PowerUp Courses">PowerUp Courses</option>
                          <option value="Inkwiry + Businesses">Inkwiry + Businesses</option>
                          <option value="Inkwiry + Schools">Inkwiry + Schools</option>
                          <option value="Press + Media">Press + Media</option>
                          <option value="Request Sales Information">Request Sales Information</option>
                          <option value="Request Support">Request Support</option>
                          <option value="General Inkwiry">General Inquiry</option>
                        </Field>
                      </div>
                      <div className={classes.formTextArea}>
                        <div className={classes.supportMessage}>Message</div>
                        <Field
                          id="message"
                          name="message"
                          component={TextField}
                          multiline
                          rows={4}
                          placeholder="Your Question/Comment"
                          className={classes.formtextAreaIn}
                        />
                      </div>
                      <div className={classes.captcha}>
                        {load && (
                          <ReCAPTCHA
                            style={{ display: 'inline-block' }}
                            ref={recaptchaRef}
                            sitekey={GOOGLE_CAPTCHA}
                            onChange={handleChange}
                          />
                        )}
                      </div>
                      {isverified === false ?
                        <div className={classes.captchaError}>
                          <span>{captchamsg}</span>
                        </div>
                        :
                        ''
                      }
                      <Button
                        variant="contained"
                        className={classes.contactBtn}
                        onClick={handleSubmitClick}
                        type="submit"
                      >
                        Send
                      </Button>
                    </form>
                  )}
                />
              </div>
              <div className={classes.contactRightBlock}>
                <p>With Inkwiry, you can:</p>
                <ul>
                  <li>
                    <span className={classes.listImage}>
                      <img src={listImage} alt="Checkmark" />
                    </span>
                    Plan at the speed of thought
                  </li>
                  <li>
                    <span className={classes.listImage}>
                      <img src={listImage} alt="Checkmark" />
                    </span>
                    See the financial outcome of your decisions before you even
                    make them
                  </li>
                  <li>
                    <span className={classes.listImage}>
                      <img src={listImage} alt="Checkmark" />
                    </span>
                    Collaborate with family and friends
                  </li>
                  <li>
                    <span className={classes.listImage}>
                      <img src={listImage} alt="Checkmark" />
                    </span>
                    Reach for your dreams and build a roadmap to financial
                    freedom.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className={classes.mailingAddress}>
            MAILING ADDRESS: 20 Terry Drive, PO Box 1426, Newtown, PA 18940
          </p>
        </Container>
      </div>
      <Dialog
        open={sucessPopup}
        onClose={handleSuccessPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={handleSuccessPopupClose}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupCheck}>
              <i className="las la-check" />
            </span>
            <p>Your message has been sent successfully!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
