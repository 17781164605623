import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import styles from './styles';
import fh1 from '../../assets/img/life-event-library/further_highered_education.jpg';

const LifeEventImageFourteen = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventFourteenColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Further Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Select Your Program
                        </Typography>
                        <ul>
                          <li>You may need further higher education to advance your career</li>
                          <li>Identify a specific school</li>
                          <li>Fill in your sources of funding such as grants, scholarships, financial aid, and family contributions</li>
                          <li>See real-time results as you change and adjust your selected schools and funding sources.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ fh1 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageFourteen.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageFourteen );
