import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';

const LongTermInvestmentTypes = (props) => {
  const {
    classes, 
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>
        Long-term investments are investments made for the long-term, which can be defined as more than one year. When planning, think of your long-term investments as money set aside that will be used for future large purchases or to grow your wealth. Because you are investing for the long-term with money that you do not plan to need for years, you can take more risk in the hope of achieving a higher return.
      </p>
      <p>
        So what are some types of long-term investments? Explore the many tabs below and discover some common assets and their historical performance.
      </p>
      <div className={classes.shortTermTableScroll}> 
        <table className={classes.shortTermTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Company Stock</th>
              <th>Interest-Paying Corporate Bond</th>
              <th>Mutual Funds</th>
              <th>Exchange-Traded Funds (ETFs)</th>
              <th>Alternative Investments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Example</td>
              <td>Individual company like Boeing or Apple</td>
              <td>Individual company like Boeing&apos;s or Apple&apos;s bonds</td>
              <td>A mix of investments that can include stocks, bonds, money markets</td>
              <td>A mix of investments that can include stocks, bonds</td>
              <td>Commodities like gold, hedge funds, real estate</td>
            </tr>
            <tr>
              <td>Level of Risk</td>
              <td>High</td>
              <td>Low to medium</td>
              <td>Medium</td>
              <td>Medium</td>
              <td>High</td>
            </tr>
            <tr>
              <td>Level of Reward</td>
              <td>High</td>
              <td>Low to medium</td>
              <td>Medium to high</td>
              <td>Medium to high</td>
              <td>High</td>
            </tr>
            <tr>
              <td>Type of Reward</td>
              <td>Price appreciation and dividends</td>
              <td>Price appreciation and periodic interest payments</td>
              <td>Price appreciation and dividends</td>
              <td>Price appreciation and dividends</td>
              <td>Price appreciation and dividends</td>
            </tr>
            <tr>
              <td>Estimated Return* (Annual)</td>
              <td>5 - 10%</td>
              <td>1.5 - 7.5%</td>
              <td>5 - 10%</td>
              <td>5 - 10%</td>
              <td>0 - 15%</td>
            </tr>
            <tr>
              <td>Fees</td>
              <td>Low; trading fees</td>
              <td>Low; trading fees</td>
              <td>Low to medium; management fees</td>
              <td>Low</td>
              <td>Medium to high; management fees</td>
            </tr>
            <tr>
              <td>Liquidity</td>
              <td>Medium liquid</td>
              <td>Medium liquid</td>
              <td>Medium liquid</td>
              <td>Highly liquid</td>
              <td>Low to medium</td>
            </tr>
            <tr>
              <td>Taxes on Earnings</td>
              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income or at capital gains rate</td>
              <td>Price appreciation taxed at capital gains rate (∼10-25%);Interest is taxed as ordinary income or at capital gains rate</td>
              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income or at capital gains rate</td>
              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income or at capital gains rate</td>
              <td>Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income or at capital gains rate</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
    </div>
  );
};

LongTermInvestmentTypes.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles(styles)(LongTermInvestmentTypes);
