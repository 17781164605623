import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import * as moduleServices from '../../calculations/modules-services';
import NumberFormat from 'react-number-format';
// import classNames from 'classnames';

const NetWorth = ( props ) => {
  const { classes} = props;
  
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
       Your path to financial freedom starts by taking inventory of your current financial life. Review your assets and liabilities below.
      </p>
      <p>Net Worth = Assets (what you own) - Liabilities (what you owe)</p>
      <p>Knowing your net worth is important for two main reasons:
        <br/> 1) Net worth shows your current financial situation in one, clean number, and
      <br/> 2) Net worth provides a reference point for measuring your future financial progress. 
      </p>
      <p> Net worth allows you to track your financial progress over time. </p>
      <div className={ classes.studentAnalytics }>
          <table>
            <thead>
              <tr>
                <th>My Assets and Liabilities</th>
                <th>Asset</th>
                <th>Liability</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cash</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthCash !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthCash } prefix={ moduleServices.module18Data.currentNetWorthCash >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthCash < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Investments</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthInvestments !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthInvestments } prefix={ moduleServices.module18Data.currentNetWorthInvestments >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthInvestments < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Retirement Accounts</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthRetirementAccounts !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthRetirementAccounts } prefix={ moduleServices.module18Data.currentNetWorthRetirementAccounts >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthRetirementAccounts < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Life Insurance Cash Value</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthLifeInsuranceCash !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthLifeInsuranceCash } prefix={ moduleServices.module18Data.currentNetWorthLifeInsuranceCash >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthLifeInsuranceCash < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Other Valuables</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthOtherValuables !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthOtherValuables } prefix={ moduleServices.module18Data.currentNetWorthOtherValuables >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthOtherValuables < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthBusiness !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthBusiness } prefix={ moduleServices.module18Data.currentNetWorthBusiness >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthBusiness < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Vehicles</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthVehicles !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthVehicles } prefix={ moduleServices.module18Data.currentNetWorthVehicles >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthVehicles < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Homes</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthHomes !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthHomes } prefix={ moduleServices.module18Data.currentNetWorthHomes >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthHomes < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
                <td>--</td>
              </tr>
              <tr>
                <td>Credit Card Debt</td>
                <td>--</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthCreditCardDebt !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthCreditCardDebt } prefix={ moduleServices.module18Data.currentNetWorthCreditCardDebt >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthCreditCardDebt < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Personal Loans</td>
                <td>--</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthPersonalLoans !== undefined ? ( 
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthPersonalLoans } prefix={ moduleServices.module18Data.currentNetWorthPersonalLoans >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthPersonalLoans < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Student Loans</td>
                <td>--</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthStudentLoans !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthStudentLoans } prefix={ moduleServices.module18Data.currentNetWorthStudentLoans >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthStudentLoans < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Auto Loans</td>
                <td>--</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthAutoLoans !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthAutoLoans } prefix={ moduleServices.module18Data.currentNetWorthAutoLoans >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthAutoLoans < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Mortgages</td>
                <td>--</td>
                <td>
                  {moduleServices.module18Data.currentNetWorthMortgages !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthMortgages } prefix={ moduleServices.module18Data.currentNetWorthMortgages >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthMortgages < 0 && ')' } />
                    ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.blueTopBorder }>
                <th>Total Assets / Liabilities</th>
                <th>
                  {moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities } prefix={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities < 0 && ')' } />
                    ) : ( '$0' )}
                </th>
                <th>
                  {moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 } prefix={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthTotalAssetsLiabilities2 < 0 && ')' } />
                    ) : ( '$0' )}
                </th>
              </tr>
              <tr className={ classes.blueTopBorder }>
                <th>My Net Worth</th>
                <th>
                  {moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth } prefix={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth < 0 && ')' } />
                    ) : ( '$0' )}
                </th>
                <th>--</th>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

NetWorth.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleAssestObject: PropTypes.object.isRequired,
  // handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( NetWorth );
