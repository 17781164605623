import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const CompanyDropdownEl = styled.div`
  width: 55rem;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  h3 {
    margin:0 0 0;
  }
`;

const CompanyDropdown = ( props ) => {
  const { classes } = props;
  return (
    <CompanyDropdownEl className={ classes.marginLeftNewCompany }>
      <Grid container>
        <Grid sm={ 6 }>
          <Grid container className={ classes.megamenuContainer }>
            <Grid sm={ 12 }>
              <div className={ classes.LeftMegamenu }>
                <div className={ classes.paddingTopFisty } />
                <div className={ classes.listGroup }>

                  <ul>
                    <li><Link to="/why-inkwiry/benefits">Benefits</Link></li>
                    <li><Link to="/why-inkwiry/students">Inkwiry for Students</Link></li>
                    <li><Link to="/why-inkwiry/parents">Inkwiry for Parents</Link></li>
                    <li><Link to="/why-inkwiry/young-professionals">Inkwiry for Young Professionals</Link></li>
                    <li><Link to="/why-inkwiry/schools">Inkwiry for Schools</Link></li>

                  </ul>
                </div>
                <div className={ classes.paddingTopFisty80 } />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={ 6 } className={ classes.rightMegamenu }>
          <div className={ classes.blueBoxMain }>
            <div className={ classes.whyinkwryblueBox }>
              <p>An overwhelming 9 in 10 Americans (87%) agree that nothing makes them happier or more confident than feeling like their finances are in order.</p>
              <p><small>— Northwestern Mutual survey</small></p>
            </div>
          </div>
        </Grid>

      </Grid>
    </CompanyDropdownEl>
  );
};

CompanyDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( CompanyDropdown );
