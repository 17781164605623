import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BlueTick from '../../../assets/img/pwi-existing/blue_circle_arrow.png';
// import classNames from 'classnames';
import styles from '../../../screens/pwi-career-advancement/styles';

const Miscellaneous = ( props ) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;

  const updateValue = ( field ) => {
    const inputValues = {...handleCareerObject};
    switch (field) {
      case "contribute_401k":
        if( handleCareerObject[field] === "Yes" ) {
          inputValues.contribute_401k = "No";
          inputValues.roth_contribute_401k = "No";
        }else{
          inputValues.contribute_401k = "Yes";
          inputValues.roth_contribute_401k = "Yes";
        }
        break;
      case "roth_contribute": 
        if( handleCareerObject[field] === "Yes" ) {
          inputValues.roth_contribute = "No";
          inputValues.ira_roth_contribute = "No";
        }else{
          inputValues.roth_contribute = "Yes";
          inputValues.ira_roth_contribute = "Yes";
        }
        break;
      default:
        if( handleCareerObject[field] === "Yes" ) {
          inputValues[field] = "No";
        }else{
          inputValues[field] = "Yes";
        }
        break;
    }
    
    handleUpdatedObject(inputValues)
  }
  return (
    <div className={ classes.contentMainBlock }>
      <p>
        More than 30% of U.S. adults have no retirement savings. Let's work retirement contributions into your budget to make sure you are saving for your future.
      </p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          If you are self-employed, you can contribute to a Traditional or Roth IRA and a SEP IRA. If you are not self-employed, you can contribute to a 401(k) or Roth 401(k), a Traditional or Roth IRA, and Pension.
        </p>
      </div>
      <ul className = {classes.retirementList }>
        <li className={ handleCareerObject.self_employment === "No" ? (( handleCareerObject.contribute_401k === "Yes" ) ? classes.active : classes.unactiveBox) :  `${classes.unactiveBox} ${classes.clickNone}` } onClick = {()=> (handleCareerObject.self_employment === "No" ? updateValue('contribute_401k'): null)}>
            401(k) / Roth 401(k) / 403(b)
          <span>
            {handleCareerObject.contribute_401k === "Yes" &&  <img src={BlueTick} alt={`Home`} />}
          </span>
        </li>
        <li className={ ( handleCareerObject.roth_contribute === "Yes" ) ? classes.active : classes.unactiveBox } onClick = {()=> (updateValue('roth_contribute'))}>
            Traditional IRA / Roth IRA
          <span>
            {handleCareerObject.roth_contribute === "Yes" &&  <img src={BlueTick} alt={`Home`} />}
          </span>
        </li>
        <li className={ handleCareerObject.self_employment === "Yes" ? (( handleCareerObject.ira_contribute === "Yes" ) ? classes.active : classes.unactiveBox) :  `${classes.unactiveBox} ${classes.clickNone}` } onClick = {()=> (handleCareerObject.self_employment === "Yes" ? updateValue('ira_contribute'): null)}>
            SEP IRA
          <span>
            {handleCareerObject.ira_contribute === "Yes" && <img src={BlueTick} alt={`Home`} />  }
          </span>
        </li>
        <li className={ handleCareerObject.self_employment === "No" ? (( handleCareerObject.pension_contribute === "Yes" ) ? classes.active : classes.unactiveBox) :  `${classes.unactiveBox} ${classes.clickNone}` } onClick = {()=> (handleCareerObject.self_employment === "No" ? updateValue('pension_contribute'): null)}>
            Pension
          <span>
            {handleCareerObject.pension_contribute === "Yes" && <img src={BlueTick} alt={`Home`} />  }
          </span>
        </li>
      </ul>
    </div>
  );
};

Miscellaneous.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
};

export default withStyles( styles )( Miscellaneous );
