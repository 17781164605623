import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
// import ReactTooltip from 'react-tooltip';
// import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
// import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const marksTwo = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];

const LongTermMonthlyContribution = (props) => {
  const {
    classes, handleDefinationPopup, getInvestmentObject, handleInvestmentData,investmentsCat,setInvestmentsCat,totals,setTotals
  } = props;
  
  getInvestmentObject.currentSlide = 10;
  const [isFocus, setIsFocus] = React.useState('');
  const [sliderValue, setSliderValue] = React.useState(getInvestmentObject.long_term_annual_expected_return_in_percentage !== undefined ? getInvestmentObject.long_term_annual_expected_return_in_percentage : 7);
//   const [investmentsCat, setInvestmentsCat] = React.useState('');
//   const [totals, setTotals] = React.useState('');


//   const invObj ={
//     'individual_stocks': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'individual_bonds': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'etfs': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'mutual_funds': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'reits': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'managed_accounts': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     },
//     'other': {
//       allocation: 0,
//       expected_return: 0,
//       weighted_return: 0,
//     }
//   };

//   const invTotal = {
//     allocation: parseFloat(invObj['individual_stocks']['allocation']) + parseFloat(invObj['individual_bonds']['allocation']) +
//       parseFloat(invObj['etfs']['allocation']) + parseFloat(invObj['mutual_funds']['allocation']) +
//       parseFloat(invObj['reits']['allocation']) + parseFloat(invObj['managed_accounts']['allocation']) + parseFloat(invObj['other']['allocation']),
//     weighted_return: parseFloat(invObj['individual_stocks']['weighted_return']) + parseFloat(invObj['individual_bonds']['weighted_return']) +
//       parseFloat(invObj['etfs']['weighted_return']) + parseFloat(invObj['mutual_funds']['weighted_return']) +
//       parseFloat(invObj['reits']['weighted_return']) + parseFloat(invObj['managed_accounts']['weighted_return']) +
//       parseFloat(invObj['other']['weighted_return'])
//   }

//  useEffect(()=>{   
//     setInvestmentsCat(invObj);
//     setTotals(invTotal);
//  },[]);

  const updateValue = (e, value, field, type) => {    
    const beginingBalanceObj = { ...getInvestmentObject };
    const newValue = e.floatValue ? e.floatValue : 0;
    let ivalue = 0;
    if (newValue >= 100) {
      ivalue = 100;
    } else {
      ivalue = newValue;
    }
    if(type === 'slider'){
      setSliderValue(value);
      beginingBalanceObj[field] = value;
    }else{
      setSliderValue(ivalue);
      beginingBalanceObj[field] = ivalue;
    }        
    handleInvestmentData(beginingBalanceObj);
  };

  const updateInputValue = (e, field) => {
    const lvalues = { ...investmentsCat };
    const tvalues = {...totals};
    const newValue = e.floatValue ? e.floatValue : 0;
    let ivalue = 0;
    if (newValue >= 100) {
      ivalue = 100;
    } else {
      ivalue = newValue;
    }
    if (field === 'individual_stocks') {
      lvalues['individual_stocks']['allocation'] = ivalue;
    } else if (field === 'individual_e_stocks') {
      lvalues['individual_stocks']['expected_return'] = ivalue;
    }
    if (field === 'individual_bonds') {
      lvalues['individual_bonds']['allocation'] = ivalue;
    } else if (field === 'individual_e_bonds') {
      lvalues['individual_bonds']['expected_return'] = ivalue;
    }
    if (field === 'etfs') {
      lvalues['etfs']['allocation'] = ivalue;
    } else if (field === 'etfs_e') {
      lvalues['etfs']['expected_return'] = ivalue;
    }
    if (field === 'etfs') {
      lvalues['etfs']['allocation'] = ivalue;
    } else if (field === 'etfs_e') {
      lvalues['etfs']['expected_return'] = ivalue;
    }
    if (field === 'mutual_funds') {
      lvalues['mutual_funds']['allocation'] = ivalue;
    } else if (field === 'mutual_e_funds') {
      lvalues['mutual_funds']['expected_return'] = ivalue;
    }
    if (field === 'reits') {
      lvalues['reits']['allocation'] = ivalue;
    } else if (field === 'reits_e') {
      lvalues['reits']['expected_return'] = ivalue;
    }
    if (field === 'managed_accounts') {
      lvalues['managed_accounts']['allocation'] = ivalue;
    } else if (field === 'managed_e_accounts') {
      lvalues['managed_accounts']['expected_return'] = ivalue;
    }
    if (field === 'other') {
      lvalues['other']['allocation'] = ivalue;
    } else if (field === 'other_e') {
      lvalues['other']['expected_return'] = ivalue;
    }
    lvalues['individual_stocks']['weighted_return'] = (parseFloat(investmentsCat['individual_stocks']['allocation']) + parseFloat(investmentsCat['individual_stocks']['expected_return'])) / 100;
    lvalues['individual_bonds']['weighted_return'] = (parseFloat(investmentsCat['individual_bonds']['allocation']) + parseFloat(investmentsCat['individual_bonds']['expected_return'])) / 100;
    lvalues['etfs']['weighted_return'] = (parseFloat(investmentsCat['etfs']['allocation']) + parseFloat(investmentsCat['etfs']['expected_return'])) / 100;
    lvalues['mutual_funds']['weighted_return'] = (parseFloat(investmentsCat['mutual_funds']['allocation']) + parseFloat(investmentsCat['mutual_funds']['expected_return'])) / 100;
    lvalues['reits']['weighted_return'] = (parseFloat(investmentsCat['reits']['allocation']) + parseFloat(investmentsCat['reits']['expected_return'])) / 100;
    lvalues['managed_accounts']['weighted_return'] = (parseFloat(investmentsCat['managed_accounts']['allocation']) + parseFloat(investmentsCat['managed_accounts']['expected_return'])) / 100;
    lvalues['other']['weighted_return'] = (parseFloat(investmentsCat['other']['allocation']) + parseFloat(investmentsCat['other']['expected_return'])) / 100;
    tvalues['allocation'] =  parseFloat(investmentsCat['individual_stocks']['allocation']) + parseFloat(investmentsCat['individual_bonds']['allocation']) +
    parseFloat(investmentsCat['etfs']['allocation']) + parseFloat(investmentsCat['mutual_funds']['allocation']) +
    parseFloat(investmentsCat['reits']['allocation']) + parseFloat(investmentsCat['managed_accounts']['allocation']) + parseFloat(investmentsCat['other']['allocation']);
    tvalues['weighted_return'] = parseFloat(investmentsCat['individual_stocks']['weighted_return']) + parseFloat(investmentsCat['individual_bonds']['weighted_return']) +
    parseFloat(investmentsCat['etfs']['weighted_return']) + parseFloat(investmentsCat['mutual_funds']['weighted_return']) +
    parseFloat(investmentsCat['reits']['weighted_return']) + parseFloat(investmentsCat['managed_accounts']['weighted_return']) +
    parseFloat(investmentsCat['other']['weighted_return'])
    setInvestmentsCat(lvalues);
    setTotals(tvalues);
  }

  const emptyisFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>
        The first rule of investing is that all investments carry <a target="_blank" href="https://inkwiry.com/finance-explained/risk-tolerance" rel="noreferrer">risk</a>. The risk is that you lose some of or all the money you invested. As you consider different investments, always consider the risk in relation to the return you expect to receive. This is called <a target="_blank" href="https://inkwiry.com/finance-explained/risk-v-reward" rel="noreferrer">risk-to-reward</a>.
      </p>
      <p>There are many different long-term investments, and they all carry different risk-to-reward relationships. <a href="https://inkwiry.com/finance-explained/diversification" target="_blank" rel="noreferrer">Diversification</a> is a powerful investing strategy with the primary goal of reducing risk by spreading out your money to many different investments. Use the table below to think through your investing strategy and allocate your contributions to different types of investments. Make sure the allocation adds up to 100%.</p>
      <div className={classes.balanceBox}>
        Input your annual expected return below.
      </div>
      <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}, ${classes.innnerBorderNone}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthLongTerm} ${classes.annuvalGrowthScholorBT0}` }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return of Long-Term Investments</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      valueLabelFormat={percentFormatWithTwoDecimal}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={marksTwo}
                      min={0}
                      max={15}
                      step={0.1}
                      value={sliderValue}
                      onChange={(e, value) => { updateValue(e, value, 'long_term_annual_expected_return_in_percentage', 'slider'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={classes.annualInput}>
                      <NumberFormat
                        customInput={TextField}
                        decimalScale={isFocus!=='long_term_annual_expected_return_in_percentag'?2:5}
                        fixedDecimalScale={isFocus!=='long_term_annual_expected_return_in_percentag'}
                        value={sliderValue}
                        onValueChange={(e) => updateValue(e,'', 'long_term_annual_expected_return_in_percentage','number')}
                        onFocus={(e) => { e.target.select(); setIsFocus('long_term_annual_expected_return_in_percentag') }}
                        onBlur={emptyisFocus}
                      />
                      <span className={classes.percentSymbl}>%</span>
                    </div>
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <div className={ classes.inputRightBlock }>
                  &nbsp;
                  </div>
                </div>
              </div>
          </div>
        
        </div>
      </div>
      {/* <div className={classes.investSlider}>
        <div className={classes.lefBlock}>
          <div className={classes.lefSlider}>
            <div className={classes.sliderName}><span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return of Long-Term Investments</span></div>
            <div className={classes.rzSlider}>
              <PwiSlider
                ValueLabelComponent={ValueLabelComponent}
                valueLabelFormat={percentFormatWithTwoDecimal}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={marksTwo}
                min={0}
                max={10}
                step={0.1}
                value={sliderValue}
                onChange={(e, value) => { updateValue(e, value, 'long_term_annual_expected_return_in_percentage', 'slider'); }}
              />
            </div>
          </div>
        </div>
        <div className={classes.annualSlider}>
          <div className={classes.annualInput}>
            <NumberFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale={!isFocus}
              value={sliderValue !== null ? sliderValue : 0}
              onValueChange={(e) => updateValue(e,'', 'long_term_annual_expected_return_in_percentage','number')}
              onFocus={(e) => { e.target.select(); setIsFocus(true) }}
              onBlur={() => { setIsFocus(false) }}
            />
            <span className={classes.percentSymbl}>%</span>
          </div>
        </div>
      </div> */}
      {investmentsCat !== '' && totals !== '' && (
      <div className={classes.tableAllocationScroll}>
        <table className={classes.tableAllocation}>
          <thead>
            <tr>
              <th>Category</th>
              <th>Allocation (%)</th>
              <th>Expected Return (%)</th>
              <th>Weighted Return (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {console.log('investmentsCat', investmentsCat)}
              <td>Individual Stocks</td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='individual_stocks'?2:5}
                  fixedDecimalScale={isFocus!=='individual_stocks'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.individual_stocks.allocation}
                  onValueChange={(e) => updateInputValue(e, 'individual_stocks')}
                  onFocus={(e) => {e.target.select(); setIsFocus('individual_stocks') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='individual_e_stocks'?2:5}
                  fixedDecimalScale={isFocus!=='individual_e_stocks'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.individual_stocks.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'individual_e_stocks')}
                  onFocus={(e) => {e.target.select(); setIsFocus('individual_e_stocks') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.individual_stocks.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>Individual Bonds</td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='individual_bonds'?2:5}
                  fixedDecimalScale={isFocus!=='individual_bonds'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.individual_bonds.allocation}
                  onValueChange={(e) => updateInputValue(e, 'individual_bonds')}
                  onFocus={(e) => {e.target.select(); setIsFocus('individual_bonds') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='individual_e_bonds'?2:5}
                  fixedDecimalScale={isFocus!=='individual_e_bonds'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.individual_bonds.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'individual_e_bonds')}
                  onFocus={(e) => {e.target.select(); setIsFocus('individual_e_bonds') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.individual_bonds.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>Exchange Traded Funds (ETFs)</td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='etfs'?2:5}
                  fixedDecimalScale={isFocus!=='etfs'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.etfs.allocation}
                  onValueChange={(e) => updateInputValue(e, 'etfs')}
                  onFocus={(e) => {e.target.select(); setIsFocus('etfs') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='etfs_e'?2:5}
                  fixedDecimalScale={isFocus!=='etfs_e'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.etfs.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'etfs_e')}
                  onFocus={(e) => {e.target.select(); setIsFocus('etfs_e') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.etfs.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>Mutual Funds</td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='mutual_funds'?2:5}
                  fixedDecimalScale={isFocus!=='mutual_funds'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.mutual_funds.allocation}
                  onValueChange={(e) => updateInputValue(e, 'mutual_funds')}
                  onFocus={(e) => {e.target.select(); setIsFocus('mutual_funds') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='mutual_e_funds'?2:5}
                  fixedDecimalScale={isFocus!=='mutual_e_funds'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.mutual_funds.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'mutual_e_funds')}
                  onFocus={(e) => {e.target.select(); setIsFocus('mutual_e_funds') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.mutual_funds.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.investopedia.com/terms/r/reit.asp" target="_blank" rel="noreferrer">Real Estate Investment Trusts (REITs)</a>
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='reits'?2:5}
                  fixedDecimalScale={isFocus!=='reits'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.reits.allocation}
                  onValueChange={(e) => updateInputValue(e, 'reits')}
                  onFocus={(e) => {e.target.select(); setIsFocus('reits') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='reits_e'?2:5}
                  fixedDecimalScale={isFocus!=='reits_e'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.reits.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'reits_e')}
                  onFocus={(e) => {e.target.select(); setIsFocus('reits_e') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.reits.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td><a href="https://www.investopedia.com/terms/m/managedaccount.asp" target="_blank" rel="noreferrer">Managed Accounts</a></td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='managed_accounts'?2:5}
                  fixedDecimalScale={isFocus!=='managed_accounts'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.managed_accounts.allocation}
                  onValueChange={(e) => updateInputValue(e, 'managed_accounts')}
                  onFocus={(e) => {e.target.select(); setIsFocus('managed_accounts') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='managed_e_accounts'?2:5}
                  fixedDecimalScale={isFocus!=='managed_e_accounts'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.managed_accounts.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'managed_e_accounts')}
                  onFocus={(e) => {e.target.select(); setIsFocus('managed_e_accounts') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.managed_accounts.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>Other</td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='other'?2:5}
                  fixedDecimalScale={isFocus!=='other'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.other.allocation}
                  onValueChange={(e) => updateInputValue(e, 'other')}
                  onFocus={(e) => {e.target.select(); setIsFocus('other') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat
                  type="text"
                  decimalScale={isFocus!=='other_e'?2:5}
                  fixedDecimalScale={isFocus!=='other_e'}
                  customInput={TextField}
                  thousandSeparator
                  value={investmentsCat.other.expected_return}
                  onValueChange={(e) => updateInputValue(e, 'other_e')}
                  onFocus={(e) => {e.target.select(); setIsFocus('other_e') }}
                  onBlur={emptyisFocus} />%
              </td>
              <td>
                <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={investmentsCat.other.weighted_return} />%
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>
                <NumberFormat id="beginning" displayType={'text'}  decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={totals.allocation} />%
              </td>
              <td>--</td>
              <td>
                <NumberFormat id="beginning" displayType={'text'} decimalScale={2} fixedDecimalScale customInput={TextField} thousandSeparator value={totals.weighted_return} />%
              </td>              
            </tr>
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
};

LongTermMonthlyContribution.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
  investmentsCat: PropTypes.object.isRequired,
  setInvestmentsCat: PropTypes.func.isRequired,
  totals: PropTypes.object.isRequired,
  setTotals: PropTypes.func.isRequired,
};

export default withStyles(styles)(LongTermMonthlyContribution);
