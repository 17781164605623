import checkImg from '../../../../../assets/img/pf101/arrow-check.png';

const styles = ( theme ) => ( {
  container: {
    margin: ' 0px auto',
    padding: '0 15px',
    maxWidth: '1140px',
  },
  pfHeader: {
    position: 'relative',
  },
  pfHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #cfa448',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: '300',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
    },
    '& button': {
      margin: '0 0 0 auto',
      outline: 'none',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '0 auto',
      },
      '& span': {
        '& > img': {
          maxWidth: '400px',
          height: '50px',
          cursor: 'pointer',
        },
      },
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  pfContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 15px',
    },
  },
  pfContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 0px',
      fontWeight: 'normal',
      fontSize: '26px',
      color: '#000',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '19px',
      },
      '& span': {
        color: '#0069aa',
      },
    },
    '& a': {
      margin: '0 0 0 auto',
      '& figure': {
        margin: '0 0 0 auto',
        padding: '7px 15px',
        transition: 'all ease-in-out 0.3s',
        '&:hover': {
          background: '#333',
          borderRadius: '5px',
          cursor: 'pointer',
        },
        '& img': {
          maxWidth: '30px',
          height: 'auto',
          display: 'flex',
        },
      },
    },
  },
  tooltipInfo: {
    width: '200px',
    background: '#0069aa !important',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '7px',
    textAlign: 'initial',
    lineHeight: '20px',
    fontFamily: 'MuseoSans-300',
  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '& p': {
      margin: '0 0 10px 0',
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& font': {
      color: '#1674b1',
    },
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
      },
    },
  },
  breadcrumbTitle: {
    color: '#cfa448',
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      marginTop: '0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        marginBottom: '0px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  pfbudgetDetails: {
    '& ul': {
      paddingLeft: '21px',
      margin: '0px',
      '& li': {
        listStylePosition: 'outside',
        paddingLeft: '6px',
        fontSize: '21px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
        },
      },
    },
  },
  grayBoxList: {
    '& ul': {
      paddingLeft: '21px',
      margin: '0',
      '& li': {
        listStylePosition: 'outside',
        paddingLeft: '6px',
        fontSize: '21px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
          lineHeight: '30px',
        },
      },
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    lineHeight: '1.4',
    '& button': {
      borderRadius: '3px',
      padding: '8px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      lineHeight: '1.42857143',
      boxSizing: 'border-box',
      fontFamily: '"MuseoSans-100"',
      backgroundColor: '#0069aa',
      border: '2px solid #0069aa',
      color: '#fff',
      '&:hover': {
        border: '2px solid #d5d5d5',
        backgroundColor: '#0069aa',
      },
      '&:disabled': {
        backgroundColor: '#d5d5d5',
        borderColor: '#d5d5d5',
        cursor: 'not-allowed',
        filter: 'alpha(opacity=65)',
        webkitBoxShadow: 'none',
        boxShadow: 'none',
        opacity: '.65',
        color: theme.palette.common.black,
        pointerEvents: 'all',
        '&:hover': {
          backgroundColor: '#d5d5d5',
          border: '2px solid #d5d5d5',
        },
        '& span': {
          pointerEvents: 'none',
        },
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  pfbudgetTable: {
    width: '92%',
    margin: '0 auto',
    border: '1px solid #0069aa',
    borderBottom: '0px',
    padding: '10px',
    marginTop: '15px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      overflow: 'auto',
    },
    '& table': {
      fontSize: '21px',
      fontWeight: '300',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '800px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '17px',
        lineHeight: '25px',
      },  
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        fontSize: '13px',
      },   
      '& th': {
        padding: '5px',
        textAlign: 'center',
        width: '18%',
        wordBreak: 'break-word',
        whiteSpace: 'inherit',
        boxSizing: 'border-box',
        position: 'relative',
        '&:first-child': {
          width: '40%',
          textAlign: 'left',
        },
      },
      '& td': {
        padding: '5px',
        textAlign: 'right',
        wordBreak: 'break-word',
        whiteSpace: 'inherit',
        boxSizing: 'border-box',
        position: 'relative',
        '& > div > div': {
          '&:before': {
            border: 'none',
            content: 'none',
          },
          '&:after': {
            border: 'none',
          },
        },
        '& > div > div:hover': {
          '&:before': {
            border: 'none !important',
            content: 'none',
          },
        },
        '&:first-child': {
          width: '40%',
          textAlign: 'left',
          paddingLeft: '12px',
        },
        '&:last-child': {
          textAlign: 'center',
          fontStyle: 'italic',
        },
        '& input': {
          border: '2px solid #ccc',
          boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .075)',
          borderRadius: '0',
          fontSize: '21px',
          textAlign: 'right',
          marginLeft: '2px',
          outline: '0',
          lineHeight: '1.42857143',
          height: '34px',
          color: '#555',
          backgroundColor: '#fff',
          backgroundImage: 'none',
          transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
          width: '100%',
          padding: '4px',
          boxSizing: 'border-box',
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '17px',            
          },  
          '@media screen and (min-width: 600px) and (max-width: 767px)': {
            fontSize: '14px',
          },    
          '&:focus': {
            border: '2px solid #0069aa',
            outline: 'none',
          },
          '&:after': {
            border: 'none',
            outline: 'none',
          },
          '&:disabled': {
            background: '#ebebe4',
            cursor: 'no-drop',
          },
        },
      },
    },
  },
  tableRow: {
    '& td': {
      '&:first-child': {
        paddingLeft: '5px !important',
      },
    },
  },
  bottomAlign: {
    '& td': {
      '&:first-child': {
        verticalAlign: 'bottom !important',
      },
    },
  },
  pfFlexRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& dollarSymbol': {
      display: 'flex',
      alignItems: 'center',
      height: '34px',
    },
  },
  greenCheck: {
    width: '0vh',
    opacity: '1',
    position: 'absolute',
    right: '0',
    '& i': {
      color: 'green',
      transition: 'all .2s',
      marginLeft: '6px',
      [theme.breakpoints.down( 'sm' )]: {
        marginLeft: '0px',
      },
    },
  },
  greenTick: {
    width: '0vh',
    opacity: '1',
    position: 'relative',
    right: '0',
    '& i': {
      color: 'green',
      transition: 'all .2s',
      marginLeft: '6px',
    },
  },
  activeCheck: {
    opacity: '1',
  },
  redText: {
    color: '#f00',
    '& input': {
      color: '#f00 !important',
    },
  },
  topBorder: {
    borderTop: '2px solid #333',
  },
  boldText: {
    fontWeight: 'bold',
    '& td': {
      fontWeight: 'bold',
    },
  },
  totalBorder: {
    border: '1px solid #333',
  },
  blueText: {
    background: '#ddebf7',
  },
  lastBorder: {
    borderBottom: '1px solid #0069aa',
  },
  emptyRow: {
    height: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      height: '10px',
    },
  },
  pfSkills: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '35%',
    '& input': {
      width: '100%',
      fontSize: '21px',
      borderRadius: '0',
      textAlign: 'right',
      marginLeft: '2px',
      outline: '0',
      lineHeight: '1.42857143',
      height: '34px',
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '4px',
      maxWidth: '130px',
      boxSizing: 'border-box',
    },
    '& div': {
      '&:before': {
        border: 'none !important',
      },
      '&:after': {
        border: 'none !important',
        outline: 'none',
      },
      '& input': {
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
        '&:after': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
  },
  graphButton: {
    '& button': {
      backgroundColor: '#0069aa',
      color: '#fff',
      fontSize: '16px',
      borderWidth: '2px',
      outline: '0',
      boxShadow: 'inset 0 3px 5px rgba(0,0,0,.125)',
      border: '0px',
      padding: '6px 20px',
      borderRadius: '3px',
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '0',
      fontWeight: '400',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      marginLeft: '50px',
      '&:hover': {
        boxShadow: 'inset 0 3px 5px rgba(0,0,0,.125)',
        backgroundColor: '#0069aa',
      },
    },
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '30px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
      },
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
  },
  potentialInputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      width: '97%',
    },
  },
  inputGroup: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& span': {
      padding: '4px 6px',
      backgroundColor: 'white',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '1',
      color: '#555',
      textAlign: 'center',
      width: '1%',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  careerInput: {
    width: '170px',
    marginRight: '39px',
    marginTop: '30px',
    marginBottom: '30px',
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '17px',
      width: '120px',
    },
    '& label': {
      fontSize: '21px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '11px',
      },
    },
  },
  dolarSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  formInput: {
    fontSize: '20px',
    zIndex: '0',
    outline: '0',
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    width: '100%',
    padding: '4px',
    display: 'table-cell',
    position: 'relative',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeftWidth: '0px',
    height: 'auto',
  },
  returnInput: {
    borderLeftWidth: '1px',
    borderRightWidth: '0px',
    paddingLeft: '6px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    paddingRight: '0px',
  },
  percentSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderLeft: '0',
    paddingRight: '6px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderRightWidth: '1px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  calculateButton: {
    backgroundColor: '#fd9840',
    fontSize: '21px',
    color: '#ffffff',
    lineHeight: '19px',
    borderColor: '#fd9840',
    boxShadow: 'none',
    borderRadius: '3px',
    padding: '10px 20px',
    marginLeft: '0px',
    borderWidth: '2px',
    marginTop: '34px',
    outline: '0',
    '&:hover': {
      backgroundColor: '#f28735',
      borderColor: '#f28735',
      boxShadow: 'none',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '8px 15px',
      fontSize: '12px',
    },
  },
  graphImage: {
    '& img': {
      width: '100%',
    },
  },
  finish: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  redTimes: {
    width: '0',
    opacity: '1',
    '& i': {
      color: 'red',
      transition: 'all .2s',
      marginLeft: '6px',
    },
  },
  assetsOptions: {
    padding: '30px 0 0px 0',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  exerciseOptions: {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0 0px 25px 0px',
    padding: '0px 0 0 0',
    textAlign: 'center',
    fontFamily: '"MuseoSans-100"',
    '& li': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      textAlign: 'center',
      width: '25%',
      position: 'relative',
      [theme.breakpoints.down( 'sm' )]: {
        minHeight: '100px',
        width: '50%',
      },
      '& input': {
        position: 'absolute',
        left: '0',
        opacity: '0',
        width: '170px',
        height: '100px',
        top: '-52px',
        zIndex: '9',
        cursor: 'pointer',
      },
      '& [type="radio"]:not(:checked)+label:after': {
        opacity: '0',
        transform: 'scale(0)',
      },
      '& input[type="radio"]:checked+label:after': {
        opacity: '1',
        transform: 'scale(1)',
      },
    },
  },
  spanRadio: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '55px',
    fontSize: '24px',
    lineHeight: '24px',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.common.blue,
    fontFamily: '"museo-sans", sans-serif',
    display: 'inline-block',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '0',
      width: '40px',
      height: '40px',
      border: '2px solid #7cb1d3',
      background: theme.palette.common.white,
      borderRadius: '100%',
      marginLeft: '-20px',
      boxSizing: 'border-box',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '0',
      transition: 'all .2s',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textShadow: '0px 0px 2px #0069aa',
      backgroundSize: '24px',
      width: '30px',
      height: '30px',
      marginLeft: '-12px',
      marginTop: '11px',
      backgroundRepeat: 'no-repeat',
      opacity: '1',
      transform: 'scale(1)',
    },
  },
  ageChecked: {
    '&:after': {
      backgroundImage: `url(${checkImg})`,
    },
  },
  radioGreen: {
    '&:before': {
      border: '2px solid #39b54a',
    },
    '&:after': {
      background: '#39b54a',
      color: '#39b54a',
      fontSize: '0px',
      position: 'absolute',
      left: '50%',
      top: '-10px',
      transition: 'all .2s',
      marginLeft: '-20px',
      borderRadius: '100%',
      width: '40px',
      lineHeight: '40px',
      height: '40px',
      fontFamily: 'themify',
      speak: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textShadow: '0px 0px 2px #0069aa',
      boxSizing: 'border-box',
    },
  },
  radioRed: {
    '&:before': {
      border: '2px solid #ff0000',
    },
    '&:after': {
      background: theme.palette.common.red,
      textShadow: '0px 0px 2px #ff0000',
      position: 'absolute',
      left: '50%',
      top: '-10px',
      fontSize: '23px',
      color: '#0069aa',
      transition: 'all .2s',
      marginLeft: '-20px',
      borderRadius: '100%',
      width: '40px',
      lineHeight: '40px',
      height: '40px',
      fontFamily: 'themify',
      speak: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
  },
  buttonBlock: {
    width: '100%',
    display: 'inline-block',
    color: '#fff !important',
  },
  checkAnswer: {
    float: 'right',
    outline: '0',
    boxShadow: 'none',
    padding: '8px 20px',
    borderRadius: '3px',
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    fontSize: '18px',
    backgroundColor: '#d5d5d5',
    color: '#333',
    textTransform: 'uppercase',
    backgroundImage: 'none',
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      background: '#d5d5d5',
    },
  },
  activeButton: {
    backgroundColor: '#0069aa',
    color: '#fff !important',
    border: '2px solid #0069aa',
    '&:hover': {
      border: '2px solid #d5d5d5',
      background: '#0069aa',
    },
  },
  answerStatus: {
    position: 'relative',
    fontWeight: '300',
    fontFamily: '"MuseoSans-500"',
    fontSize: '21px',
    lineHeight: '30px',
    '& h2': {
      textAlign: 'center',
      fontSize: '21px',
      fontWeight: '300',
      lineHeight: '30px',
    },
  },
  correctAnswer: {
    color: '#008000',
  },
  wrongAnswer: {
    color: '#ff0000',
  },
  resetIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    width: '34px',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
      minWidth: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '10px',
        maxWidth: '10px',
      },
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  formControl: {
    '&:before': {
      border: 'none',
    },
  },
  flexNumber: {
    display: 'flex',
    justifyContent: 'flex-end',
    textALign: 'right',
  },
  nextButton: {
    marginLeft: 'auto',
  },
  checkMark: {
    width: '0vh',
    opacity: '1',
    position: 'relative',
    right: '0',
    '& i': {
      color: 'green',
      transition: 'all .2s',
      marginLeft: '6px',
    },
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '90%',
      margin: '0 auto',
    },
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      fontFamily: '"MuseoSans-500"',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0 -15px',
      paddingLeft: '15px',
      fontWeight: 'normal',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:last-child': {
        color: '#333',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  backButton: {
    backgroundColor: '#fff !important',
    border: '2px solid #d5d5d5 !important',
    color: '#333 !important',
    '&:hover': {
      backgroundColor: ' #d5d5d5 !important',
    },
  },
} );

export default styles;
