import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import dummyProfile from '../../assets/img/addressbook/user.png';
import styles from './styles';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');

const ContactList = (props) => {
  const {
    classes, displayContactList, searchText, setSearchSuccessMessage, searchSuccessMessage, setSearchText, setSearchIcon, tabs, handleDisplayUser, listID, placeholderText, searchIcon, searchContactList
  } = props;

  const [displayContact, setDisplayContact] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (displayContactList !== undefined && displayContactList.length > 0) {
      displayContactList.forEach((tag) => {
        if (tag.user_fname !== undefined) {
          tag.user_fname = tag.user_fname.toLowerCase();
        }
      });
      setDisplayContact(displayContactList);
    } else {
      setDisplayContact([]);
    }
    if (tabs === 'My Contact') {
      if (searchText !== '') {
        const searchData = displayContactList.filter((obj) => (obj.user_fname).search(searchText) !== -1 || obj.user_lname.toLowerCase().search(searchText) !== -1 || obj.email.toLowerCase().search(searchText) !== -1);
        setDisplayContact(searchData);
        if (searchData.length <= 0) {
          console.log('raj');
          setSearchSuccessMessage('Sorry, no results found.');
        }
      }
    }
  }, [displayContactList]);

  useEffect(() => {
    conatact();
  }, [displayContact]);

  const conatact = () => {
    const Data = _(displayContact && displayContact)
      .groupBy('user_fname[0]')
      .map((objs, key) => ({
        name: key,
        subname: objs,
      }))
      .value();
    setData(Data);
  }

  const onSubmit = (values) => {
    setSearchText(values.searchText);
    searchContactList();
  };
  const { data: myDetails } = useQuery(MY_DETAILS);


  const renderContactList = (result) => result.map((info) => (
    info.id !== myDetails.me.id &&
    <li
      className={listID === info.id ? classes.activeUser : ''}
      data-for="userList"
      data-tip="Click, Hold and Drag to add a contact to one of yours groups."
      onClick={() => handleDisplayUser(info)}
      aria-hidden="true"
    >
      <div className={classes.userImage}>
        {' '}
        <img src={(info.profile_image && IMAGE_PATH + info.profile_image) || dummyProfile} alt="profile" />
      </div>
      <p>
        {info.user_fname}
        {' '}
        {info.user_lname}
      </p>
    </li>
  ));

  return (
    <Grid item md={3} lg={4} className={classes.searchBlock}>
      <div className={classes.searchRow}>
        <div className={classes.searchBox}>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              searchText,
            }}
            validate={(values) => {
              const errors = {};
              setSearchIcon(!!((values.searchText && values.searchText.length > 1)));
              return errors;
            }}
            render={({ handleSubmit, submitting }) => (
              <form
                className={classes.formContact}
                onSubmit={handleSubmit}
                noValidate
              >
                <div className={classes.formGroup}>
                  <Field
                    placeholder={` Search ${placeholderText}`}
                    variant="outlined"
                    className="icon"
                    name="searchText"
                    id="searchText"
                    component={TextField}
                  />
                  {searchIcon && <Button className={classes.searchButton} disabled={submitting} variant="contained" type="submit"><i className="fa fa-search" /></Button>}
                </div>
              </form>
            )}
          />
        </div>
        {
          (data && data.length > 0)  ? data.map((name) => (
            <React.Fragment>
              <ul className={classes.searchKey}>
                <li>{name.name}</li>
              </ul>
              <ul className={classes.searchScroll}>
                {renderContactList(name.subname)}
              </ul>
            </React.Fragment>
          ))
            :
            <div>{searchSuccessMessage && searchSuccessMessage}</div>
        }

      </div>
      <ReactTooltip id="userList" place="bottom" type="light" effect="solid" />
    </Grid>
  );
};

ContactList.propTypes = {
  classes: PropTypes.object.isRequired,
  displayContactList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchText: PropTypes.func.isRequired,
  listID: PropTypes.string.isRequired,
  setSearchIcon: PropTypes.func.isRequired,
  handleDisplayUser: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  searchSuccessMessage: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  searchIcon: PropTypes.bool.isRequired,
  searchContactList: PropTypes.func.isRequired,
  tabs: PropTypes.string.isRequired,
  setSearchSuccessMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContactList);
