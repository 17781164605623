import inkwiryConnectBanner from '../../assets/img/connect/inkwiry_connect_banner.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  '*': {
    boxSizing: 'border-box',
  },
  connectBanner: {
    backgroundImage: `url(${inkwiryConnectBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'right bottom',
    backgroundPosition: 'center top',
    position: 'relative',
    alignItems: 'center',
    zIndex: '9',
    [theme.breakpoints.down( 'md' )]: {
      backgroundPosition: 'center center',
    },
    [theme.breakpoints.down( 'sm' )]: {
      backgroundPosition: 'left bottom',
    },
    [theme.breakpoints.down( 'xs' )]: {
      backgroundPosition: 'center left',
    },
  },
  connectBannerCnt: {
    display: 'flex',
    alignItems: 'center',
  },
  connectbannerTitle: {
    width: 'calc(100% - 395px)',
    textAlign: 'right',
    marginRight: '50px',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% - 150px)',
      textAlign: 'center',
      marginRight: '20px',
    },
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      fontSize: '54px',
      lineHeight: '60px',
      maxWidth: 'inherit',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
    },
  },
  connectbannerImg: {
    padding: '42px 0',
    width: '345px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '130px',
    },
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
      },
    },
  },
  connectCntSec: {
    padding: '65px 0 0px 0',
  },
  connectCntTitle: {
    padding: '0 0 75px 0',
    textAlign: 'center',
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      padding: '0 0 50px 0',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      margin: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '35px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: '"MuseoSans-100"',
      margin: '0px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '28px',
      },
      '& span': {
        display: 'block',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
    },
  },
  connectPowerup: {
    display: 'flex',
    padding: '0 0 80px 0',
    margin: '0 30px',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      padding: '0 0 40px 0',
    },
  },
  connectPowerupLeft: {
    width: '170px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '150px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px auto',
      width: '180px',
    },
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
      },
    },
  },
  connectPowerupRight: {
    width: 'calc(100% - 230px)',
    marginLeft: '50px',
    marginRight: '50px',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 180px)',
      marginLeft: '30px',
      marginRight: '30px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginLeft: '0',
      marginRight: '0px',
      marginTop: '20px',
    },
    '& h4': {
      fontSize: '24px',
      marginBottom: '10px',
      color: theme.palette.common.blue,
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      },
    },
    '& p': {
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '8px',
      marginTop: '0px',
      color: theme.palette.common.black,
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      '& a': {
        borderColor: theme.palette.common.mediumBlue,
        color: theme.palette.common.mediumBlue,
        padding: '10px 25px',
        cursor: 'pointer',
        borderRadius: '10px',
        borderStyle: 'solid',
        border: '1px',
        display: 'inline-block',
        transition: 'all 0.5s ease 0s',
        MozTransition: 'all 0.5s ease 0s',
        WebkitTransition: 'all 0.5s ease 0s',
        marginTop: '10px',
        '&:hover': {
          textDecoration: 'none',
          background: theme.palette.common.mediumBlue,
          color: theme.palette.common.white,
        },
        '& i': {
          marginLeft: '5px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '13px',
          padding: '10px 20px',
        },
      },
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: '600',
  },
} );

export default styles;
