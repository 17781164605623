const styles = ( theme ) => ( {
  impPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  impDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  impbuttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#337ab7',
        borderColor: '#337ab7',
        color: '#fff',
        marginRight: '6px',
        '&:hover': {
          background: '#285e8c',
          borderColor: '#285e8c',
        },
      },
      '&:last-child': {
        color: '#fff',
        background: '#d9534f',
        borderColor: '#d9534f',
        '&:hover': {
          background: '#c9302c',
          borderColor: '#c9302c',
        },
      },
    },
  },
  impbuttonOkDefault: {
    '& button': {
      '&:last-child': {
        color: '#333',
        background: '#fff',
        border: '2px solid #ccc',
        '&:hover': {
          backgroundColor: '#e6e6e6',
          borderColor: '#ccc',
        },
      },
    },
  },
  noPadding: {
    '& p': {
      padding: 0,
    },
  },
  hideButton: {
    display: 'none',
  },
  minPopupDiv: {
    minWidth: '500px',
  },
  activeList: {
    background: '#337ab7',
    color: '#fff',
  },
} );

export default styles;
