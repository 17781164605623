import empowerBg from '../../assets/img/about/empowering_bg.jpg';
import builtinkwiryBg from '../../assets/img/about/about_built_inkwiry_bg.jpg';
import beganQuestion from '../../assets/img/about/began_question_bg.jpg';
import advanceTechnology from '../../assets/img/about/advanced_technology_bg.jpg';
import ourIntrest from '../../assets/img/about/our_interest_bg.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  aboutBanner: {
    position: 'relative',
    height: '725px',
    [theme.breakpoints.down( 'md' )]: {
      height: '580px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      height: '400px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      height: '300px',
    },  
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'bottom right',
    },
  },
  aboutBannerCaption: {
    position: 'absolute',
    top: '0px',
    left: 'inherit',
    height: '100%',
    maxWidth: '1170px',
    '@media (max-width: 420px)': {
      left: '0',
      paddingTop: '10px',
      width: '100%',
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '71px',
      lineHeight: '100px',
      color: theme.palette.common.blue,
      margin: '0',
      padding: '0',
      maxWidth: '560px',
      width: '100%',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '60px',
        lineHeight: '90px',
        maxWidth: '500px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '32px',
        lineHeight: '45px',
        maxWidth: '295px',
      },    
      '@media screen and (min-width: 421px) and (max-width: 599px)': {
        fontSize: '30px',
        maxWidth: '248px',
      },      
      '@media (max-width: 420px)': {
        fontSize: '18px',
        lineHeight: '22px',
        maxWidth: '100%',
      },
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        maxWidth: '265px',
      },     
    },
  },
  bannerCaptionTitle: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 420px)': {
      alignItems: 'flex-start',
    },
  },
  smartApps: {
    padding: '80px 0 60px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '35px 0 35px 0',
    },
  },
  smartAppsRow: {
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      margin: '0 0 35px 0',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '40px',
        lineHeight: '42px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '26px',
        lineHeight: '28px',
        margin: '0 0px 15px 0px',
      },
    },
  },
  smartAppsLeft: {
    paddingLeft: '13px',
    paddingRight: '13px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      margin: '0 0 30px 0',
      maxWidth: '570px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 0 18px 0',
      },
    },
    '& p': {
      fontSize: '18px',
      lineHeight: '27px',
      margin: '0 0 30px 0',
      padding: '0',
      maxWidth: '580px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
        lineHeight: '20px',
        margin: '0 0 10px 0',
      },
    },
  },
  smartAppsRight: {
    '& figure': {
      display: 'block',
      padding: '0',
      margin: '-60px 0 0 0',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0 0 0px 0',
      },
      '& img': {
        [theme.breakpoints.down( 'md' )]: {
          width: '100%',
        },
        [theme.breakpoints.down( 'sm' )]: {
          maxWidth: '280px',
          width: '70%',
          margin: '0 auto',
          display: 'block',
        },
      },
    },
  },
  empoweringInnovation: {
    backgroundImage: `url(${empowerBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '70px 0 60px 0',
    color: theme.palette.common.white,
    boxSizing: ' border-box',
    backgroundPosition: 'center',
    [theme.breakpoints.down( 'md' )]: {
      backgroundPosition: '90%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '35px 0 35px 0',
    },
    [theme.breakpoints.down( 'xs' )]: {
      backgroundPosition: 'center',
    },
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '48px',
      lineHeight: '48px',
      margin: '0 0 60px 0',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '40px',
        lineHeight: '42px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '26px',
        lineHeight: '26px',
        margin: '0 0px 15px 0px',
      },
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      margin: '0 0 30px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 0 18px 0',
      },
    },
  },
  empowerCntnt: {
    maxWidth: '700px',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '450px',
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '20px',
      lineHeight: '30px',
      margin: '0 0 30px 0',
      boxSizing: ' border-box',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
        lineHeight: '20px',
        margin: '0 0 10px 0',
      },
    },
  },
  aswarenessBlock: {
    fontSize: '30px',
    lineHeight: '30px',
    [theme.breakpoints.down( 'md' )]: {
      fontSize: '23px',
      lineHeight: '23px',
    },
  },
  builtInkwiry: {
    backgroundImage: `url(${builtinkwiryBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '70px 0 60px 0',
    color: theme.palette.common.pureBlack,
    boxSizing: ' border-box',
    backgroundPosition: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '35px 0 35px 0',
    },
  },
  builtDesc: {
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '48px',
      lineHeight: '48px',
      margin: '0 0 60px 0',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '40px',
        lineHeight: '42px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '26px',
        lineHeight: '26px',
        margin: '0 0px 15px 0px',
      },
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      margin: '0 0 30px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 0 18px 0',
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-300',
      fontSize: '20px',
      lineHeight: '30px',
      margin: '0 0 30px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
        lineHeight: '20px',
        margin: '0 0 10px 0',
      },
    },
  },
  builtCntnt: {
    maxWidth: '810px',
  },
  beganQuestion: {
    backgroundImage: `url(${beganQuestion})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '90px 0',
    position: 'relative',
    color: theme.palette.common.white,
    zIindex: '1',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '35px 0 35px 0',
    },
  },
  bQuestion: {
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
  },
  beganQuestionLeft: {
    width: '44%',
    paddingLeft: '0px',
    [theme.breakpoints.down( 'md' )]: {
      paddingRight: '20px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  beganQuestionRight: {
    width: '56%',
    padding: '0',
    '& p': {
      paddingTop: '5px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  advanceTechnology: {
    backgroundImage: `url(${advanceTechnology})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '70px 0 60px 0',
    '& h2': {
      color: theme.palette.common.blue,
    },
    '& h3': {
      color: theme.palette.common.pureBlack,
    },
    '& p': {
      color: theme.palette.common.pureBlack,
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '35px 0 35px 0',
    },
  },
  advanceTechnologyList: {
    boxSizing: 'border-box',
    '& ul': {
      padding: '0',
      '& li': {
        margin: ' 0 15px 0 0',
        padding: '0 15px 0 0',
        display: 'inline-block',
        verticalAlign: 'middle',
        borderRight: '1px solid #333333',
        [theme.breakpoints.down( 'sm' )]: {
          margin: ' 0 10px 0 0',
          padding: '0 10px 0 0',
        },
        '&:last-child': {
          borderRight: 'none',
        },
        '& p': {
          margin: ' 0',
          fontSize: '28px',
          lineHeight: '28px',
          color: '#000',
          fontFamily: 'MuseoSans-100',
          [theme.breakpoints.down( 'md' )]: {
            fontSize: '23px',
            lineHeight: '26px',
          },
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '17px',
            lineHeight: '20px',
          },
        },
      },
    },
  },
  builtDescCntnt: {
    maxWidth: '810px',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '100%',
    },
  },
  ourIntrest: {
    backgroundImage: `url(${ourIntrest})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '70px 0 60px 0',
    color: theme.palette.common.white,
    position: 'relative',
    boxSizing: 'border-box',
    backgroundPosition: 'right center',
    [theme.breakpoints.down( 'md' )]: {
      padding: '35px 0 35px 0',
      backgroundImage: 'none',
      background: '#024387',
    },
  },
  whiteLogo: {
    display: 'none',
    textAlign: 'center',
    [theme.breakpoints.down( 'md' )]: {
      marginTop: '20px',
      display: 'block',
    },
  },
} );

export default styles;
