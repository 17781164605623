import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  // Dialog,
  Button,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import styles from '../../../screens/pwi/further-higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
// import graphImage from '../../../assets/img/pwi-he/federal_unsubsidized.png';
import { percentFormatWithTwoDecimal, yearFormatter } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';

const originationMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 5,
    label: '5%',
  },
];

const termOfLoanMarks = [
  {
    value: 0,
    label: '0 yrs',
  },
  {
    value: 3,
    label: '3 yrs',
  },
  {
    value: 6,
    label: '6 yrs',
  },
  {
    value: 9,
    label: '9 yrs',
  },
  {
    value: 12,
    label: '12 yrs',
  },
  {
    value: 15,
    label: '15 yrs',
  },
];

const interestRateMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 15,
    label: '15%',
  },
];

const FederalStudentLoansFirst = ( props ) => {
  const {
    classes, handleDefinationPopup, inputData, updateData, setStudentLoanType
  } = props;

  const [inputs, setInputs] = React.useState();
  const [maxSubLoanvalue, setMaxSubLoanvalue] = React.useState();
  const [maxUnLoanValue, setMaxUnLoanValue] = React.useState();
  const [displayMaxUnLoanAmount, setDisplayMaxUnLoanAmount] = React.useState();
  const [originationAmount,setOriginationAmount] = React.useState(0);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(()=>{
    if(inputs !== undefined) {
      const federal_amount = inputs.subsidized_loans !== undefined ? inputs.subsidized_loans : 0;
      const unfederal_amount = inputs.unsubsidized_loans !== undefined ? inputs.unsubsidized_loans : 0;
      const origination_fee =  inputs.fed_sub_origination_fee !== undefined ? inputs.fed_sub_origination_fee : 0;
      const originationFees = ((federal_amount+unfederal_amount)/100)*origination_fee;
      setOriginationAmount(originationFees)
    } else {
      setOriginationAmount(0)
    }
  },[inputs])


  const generateMaxLoans = (inputValues) => {
    const databaseData = moduleServices.databaseCompleteData();
    let maxLoanAmount = 0;
    if(typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Student Loans Limit'] !== 'undefined' && databaseData['Student Loans Limit'] !== ''
        && typeof databaseData['Student Loans Limit']['New Higher Education'] !== 'undefined' && databaseData['Student Loans Limit']['New Higher Education'] !== '' && typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'] !== 'undefined' && databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'] !== '') {
        let fedSubData = databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'];
        let startYear = parseInt(inputData.start_year)
        let subtotalLimit = fedSubData['Total Limit'];
        let totalExistingLoan = 0;
        for (let i = 0; i < parseInt(inputData.years_diff); i+=1) {
            let subLoopYear = startYear+i;
            if(typeof fedSubData[subLoopYear] !== 'undefined' && fedSubData[subLoopYear] !== '' && typeof fedSubData[subLoopYear]['Annual Limits'] !== 'undefined' && fedSubData[subLoopYear]['Annual Limits'] !== '') {
                maxLoanAmount = maxLoanAmount+fedSubData[subLoopYear]['Annual Limits'];
            }
        }
        if(typeof databaseData['Student Loans Limit']['Existing Student Loan'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan'] !== '' 
            && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub'] !== ''
            && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] !== '') {
            totalExistingLoan = databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'];
        }
        if(!(maxLoanAmount < (subtotalLimit-totalExistingLoan))){
            maxLoanAmount = subtotalLimit-totalExistingLoan;
        }
        if(maxLoanAmount < 0){
          maxLoanAmount = 0;
        }
    }
    setMaxSubLoanvalue(maxLoanAmount);

    let maxUnLoanAmount = 0;
    if(typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Student Loans Limit'] !== 'undefined' 
      && databaseData['Student Loans Limit'] !== '' && typeof databaseData['Student Loans Limit']['Higher Education'] !== 'undefined' 
      && databaseData['Student Loans Limit']['New Higher Education'] !== '' && typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'] !== 'undefined' 
      && databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'] !== '') {
      let fedUnSubData = databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'];
      let startYear = parseInt(inputData.start_year)
      let unsubtotalLimit = fedUnSubData['Total Limit'];
      let unsubexistingloanData = 0;
      if(typeof startYear !== 'undefined' && startYear !== '' ) {
          for (let i = 0; i < parseInt(inputData.years_diff); i+=1) {
              let subLoopYear = startYear+i;
              if(typeof fedUnSubData[subLoopYear] !== 'undefined' && fedUnSubData[subLoopYear] !== '' && typeof fedUnSubData[subLoopYear]['Annual Limits'] !== 'undefined' && fedUnSubData[subLoopYear]['Annual Limits'] !== '') {
                  maxUnLoanAmount = maxUnLoanAmount+fedUnSubData[subLoopYear]['Annual Limits'];
              }
          }

          if(typeof databaseData['Student Loans Limit']['Existing Student Loan'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan'] !== '' 
              && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub'] !== ''
              && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] !== '') {
              unsubexistingloanData = unsubexistingloanData+databaseData['Student Loans Limit']['Existing Student Loan']['Fed Sub']['Annual Loans Taken'];
          }
          if(typeof databaseData['Student Loans Limit']['Existing Student Loan'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan'] !== '' 
              && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Unsub'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Unsub'] !== ''
              && typeof databaseData['Student Loans Limit']['Existing Student Loan']['Fed Unsub']['Annual Loans Taken'] !== 'undefined' && databaseData['Student Loans Limit']['Existing Student Loan']['Fed Unsub']['Annual Loans Taken'] !== '') {
              unsubexistingloanData = unsubexistingloanData+databaseData['Student Loans Limit']['Existing Student Loan']['Fed Unsub']['Annual Loans Taken'];
          }
          if(!(maxUnLoanAmount < (unsubtotalLimit-unsubexistingloanData))) {
              maxUnLoanAmount = unsubtotalLimit-unsubexistingloanData;
          }
          if(maxUnLoanAmount < 0){
            maxUnLoanAmount = 0;
          }
      }

    }
    setMaxUnLoanValue(maxUnLoanAmount);
    if(maxUnLoanAmount > 0 && inputValues.subsidized_loans !== undefined && inputValues.subsidized_loans > 0) {
        maxUnLoanAmount = maxUnLoanAmount-inputValues.subsidized_loans;
    }
    setDisplayMaxUnLoanAmount(maxUnLoanAmount);
  }

  useEffect(()=>{
    let inputValues = {...inputs};
    let subsidizedLoans = 0;
    if(inputData.fed_sub_firstyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_firstyear;
    }
    if(inputData.fed_sub_secondyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_secondyear;
    }
    if(inputData.fed_sub_thirdyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_thirdyear;
    }
    if(inputData.fed_sub_fourthyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_fourthyear;
    }
    if(inputData.fed_sub_fifthyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_fifthyear;
    }
    if(inputData.fed_sub_sixthyear !== undefined) {
      subsidizedLoans += inputData.fed_sub_sixthyear;
    }
    inputValues['subsidized_loans'] = subsidizedLoans;

    let unsubsidizedLoans = 0;
    if(inputData.fed_unsub_firstyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_firstyear;
    }
    if(inputData.fed_unsub_secondyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_secondyear;
    }
    if(inputData.fed_unsub_thirdyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_thirdyear;
    }
    if(inputData.fed_unsub_fourthyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_fourthyear;
    }
    if(inputData.fed_unsub_fifthyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_fifthyear;
    }
    if(inputData.fed_unsub_sixthyear !== undefined) {
      unsubsidizedLoans += inputData.fed_unsub_sixthyear;
    }
    inputValues['unsubsidized_loans'] = unsubsidizedLoans;

    if(inputData.fed_sub_origination_fee !== undefined) {
      inputValues['fed_sub_origination_fee'] = inputData.fed_sub_origination_fee; 
      inputValues['fed_unsub_origination_fee'] = inputData.fed_sub_origination_fee; 
    } else {
      inputValues['fed_sub_origination_fee'] = 1; 
      inputValues['fed_unsub_origination_fee'] = 1; 
    }

    if(inputData.fed_sub_interest_rate !== undefined) {
      inputValues['fed_sub_interest_rate'] = inputData.fed_sub_interest_rate; 
      inputValues['fed_unsub_interest_rate'] = inputData.fed_sub_interest_rate; 
    } else {
      inputValues['fed_sub_interest_rate'] = 2.75; 
      inputValues['fed_unsub_interest_rate'] = 2.75; 
    }

    if(inputData.fed_sub_term !== undefined) {
      inputValues['fed_sub_term'] = inputData.fed_sub_term; 
      inputValues['fed_unsub_term'] = inputData.fed_sub_term; 
    } else {
      inputValues['fed_sub_term'] = 10; 
      inputValues['fed_unsub_term'] = 10; 
    }



    setInputs(inputValues);
    updateData({...inputData,...inputValues});
    generateMaxLoans(inputValues)
  },[])

  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputs};
    console.log('event',e.target.value,field,'',displayMaxUnLoanAmount);
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;

    if( field === 'subsidized_loans') {
      if(inputValues[field] > maxSubLoanvalue) {
        inputValues[field] = maxSubLoanvalue;
      }
      let maxunsubloan = maxUnLoanValue-inputValues[field];
      if(inputValues['unsubsidized_loans'] > maxunsubloan) {
        inputValues['unsubsidized_loans'] = maxunsubloan;
      }
      setDisplayMaxUnLoanAmount(maxunsubloan);
    } else if(field === 'unsubsidized_loans' ) {
      if(inputValues[field] > displayMaxUnLoanAmount) {
        inputValues[field] = displayMaxUnLoanAmount;
      }
    }
    if(field === 'subsidized_loans' || field === 'unsubsidized_loans'){
      let loadAmount = inputValues['subsidized_loans'];
      let unloanAmount = inputValues['unsubsidized_loans'];

      let loan_field_fedsub_maxlimit = {};
      let loan_field_fedunsub_maxlimit = {};

      const databaseData = moduleServices.databaseCompleteData();
      if (typeof databaseData !== 'undefined' &&
          typeof databaseData['Student Loans Limit']['New Higher Education'] !== 'undefined' &&
          typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'] !== 'undefined' &&
          typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'] !== 'undefined' &&
          typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'][inputData.start_year] !== 'undefined' &&
          typeof databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'][inputData.start_year] !== 'undefined') {
          for (let i = 0; i < 6; i+=1) {
              loan_field_fedsub_maxlimit[i+1] = databaseData['Student Loans Limit']['New Higher Education']['Fed Sub'][inputData.start_year + i]['Cummulative Limits'];
              loan_field_fedunsub_maxlimit[i+1] = databaseData['Student Loans Limit']['New Higher Education']['Fed Unsub'][inputData.start_year + i]['Cummulative Limits'];
          }
      }
      if(field === 'subsidized_loans') {
        if(inputData.years_diff >= 1 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
            if(loadAmount >= loan_field_fedsub_maxlimit[1]) {
              inputValues['fed_sub_firstyear'] = loan_field_fedsub_maxlimit[1];
              loadAmount = loadAmount-loan_field_fedsub_maxlimit[1];
            } else {
              inputValues['fed_sub_firstyear'] = loadAmount;
              loadAmount = 0;;
            }
        } else {
            inputValues['fed_sub_firstyear'] = 0;
        }

        if(inputData.years_diff >= 2 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
          if(loadAmount >= loan_field_fedsub_maxlimit[2]) {
            inputValues['fed_sub_secondyear'] = loan_field_fedsub_maxlimit[2];
            loadAmount = loadAmount-loan_field_fedsub_maxlimit[2];
          } else {
            inputValues['fed_sub_secondyear'] = loadAmount;
            loadAmount = 0;;
          }
        } else {
          inputValues['fed_sub_secondyear'] = 0;
        }

        if(inputData.years_diff >= 3 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
          if(loadAmount >= loan_field_fedsub_maxlimit[3]) {
            inputValues['fed_sub_thirdyear'] = loan_field_fedsub_maxlimit[3];
            loadAmount = loadAmount-loan_field_fedsub_maxlimit[3];
          } else {
            inputValues['fed_sub_thirdyear'] = loadAmount;
            loadAmount = 0;
          }
        } else {
          inputValues['fed_sub_thirdyear'] = 0;
        }

        if(inputData.years_diff >= 4 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
          if(loadAmount >= loan_field_fedsub_maxlimit[4]) {
            inputValues['fed_sub_fourthyear'] = loan_field_fedsub_maxlimit[4];
            loadAmount = loadAmount-loan_field_fedsub_maxlimit[4];
          } else {
            inputValues['fed_sub_fourthyear'] = loadAmount;
            loadAmount = 0;
          }
        } else {
          inputValues['fed_sub_fourthyear'] = 0;
        }

        if(inputData.years_diff >= 5 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
          if(loadAmount >= loan_field_fedsub_maxlimit[5]) {
            inputValues['fed_sub_fifthyear'] = loan_field_fedsub_maxlimit[5];
            loadAmount = loadAmount-loan_field_fedsub_maxlimit[5];
          } else {
            inputValues['fed_sub_fifthyear'] = loadAmount;
            loadAmount = 0;
          }
        } else {
          inputValues['fed_sub_fifthyear'] = 0;
        }

        if(inputData.years_diff >= 6 && loadAmount > 0 && inputData.type_of_higher_education !== 1) {
          if(loadAmount >= loan_field_fedsub_maxlimit[6]) {
            inputValues['fed_sub_sixthyear'] = loan_field_fedsub_maxlimit[6];
            loadAmount = loadAmount-loan_field_fedsub_maxlimit[6];
          } else {
            inputValues['fed_sub_sixthyear'] = loadAmount;
            loadAmount = 0;
          }
        } else {
          inputValues['fed_sub_sixthyear'] = 0;
        }
      }

      if(inputData.years_diff >= 1 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[1]) {
          inputValues['fed_unsub_firstyear'] = loan_field_fedunsub_maxlimit[1];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[1];
        } else {
          inputValues['fed_unsub_firstyear'] = unloanAmount;
          unloanAmount = 0;
        }
      } else {
          inputValues['fed_unsub_firstyear'] = unloanAmount;
      }

      if(inputData.years_diff >= 2 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[2]) {
          inputValues['fed_unsub_secondyear'] = loan_field_fedunsub_maxlimit[2];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[2];
        } else {
          inputValues['fed_unsub_secondyear'] = unloanAmount;
          unloanAmount = 0;
        }
      } else {
          inputValues['fed_unsub_secondyear'] = unloanAmount;
      }
      if(inputData.years_diff >= 3 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[3]) {
          inputValues['fed_unsub_thirdyear'] = loan_field_fedunsub_maxlimit[3];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[3];
        } else {
          inputValues['fed_unsub_thirdyear'] = unloanAmount;
          unloanAmount = 0;
        }
      } else {
          inputValues['fed_unsub_thirdyear'] = unloanAmount;
      }
      if(inputData.years_diff >= 4 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[4]) {
          inputValues['fed_unsub_fourthyear'] = loan_field_fedunsub_maxlimit[4];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[4];
        } else {
          inputValues['fed_unsub_fourthyear'] = unloanAmount;
          unloanAmount = 0;
        }
      } else {
          inputValues['fed_unsub_fourthyear'] = unloanAmount;
      }
      if(inputData.years_diff >= 5 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[5]) {
          inputValues['fed_unsub_fifthyear'] = loan_field_fedunsub_maxlimit[5];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[5];
        } else {
          inputValues['fed_unsub_fifthyear'] = unloanAmount;
          unloanAmount = 0;
        }
      } else {
          inputValues['fed_unsub_fifthyear'] = unloanAmount;
      }
      if(inputData.years_diff >= 6 && unloanAmount > 0) {
        if(unloanAmount >= loan_field_fedunsub_maxlimit[6]) {
          inputValues['fed_unsub_sixthyear'] = loan_field_fedunsub_maxlimit[6];
          unloanAmount = unloanAmount-loan_field_fedunsub_maxlimit[6];
        } else {
          inputValues['fed_unsub_sixthyear'] = unloanAmount;
        }
      } else {
          inputValues['fed_unsub_sixthyear'] = unloanAmount;
      }
    }
    
    setInputs(inputValues);
    updateData( 'total_obj' ,{...inputData,...inputValues} );
  };
  const emptyIsFocus = (field) => {
    const in_value = {...inputData}
    if(field=='fed_sub_interest_rate'){
    in_value['fed_sub_interest_rate'] = parseFloat(parseFloat(inputData.fed_sub_interest_rate).toFixed(2));
    setInputs(in_value);
    }
    setIsFocus('');
  };

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>
      How much do you plan to borrow in federal student loans? You can use the <a  href="https://studentaid.gov/aid-estimator/" target="_blank" rel="noreferrer">Federal Student Aid Estimator</a> to get a good estimate of the federal loans that will be available to you.
      </p>

      {
        (inputData.school !== 'new' && inputData.type_of_higher_education !== 3) && (
          <React.Fragment>
            <p className={ `${classes.marginTopZero} ${classes.marginTopMore}` }>
              <i>
                Selected School:
                {' '}
                {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
              </i>
            </p>
            <p className={ `${classes.marginTopZero} ${classes.bdNone}` }>
              <i>
                % of First-Time Students Receiving Federal Loans:
                {(inputData.school_details !== undefined && inputData.school_details.federal_loans_percentage !== undefined) ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.federal_loans_percentage } displayType="text" thousandSeparator suffix="%" className={ classes.bdNone }/>
                ):('N/A')}
              </i>
            </p>
            <p className={ `${classes.marginTopZero} ${classes.bdNone}` }>
              <i>
                Average Amount of Federal Loans per First-Time Student per Year:
                {
                  (inputData.school_details !== undefined && inputData.school_details.grant_aid_average_amount !== undefined) ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.grant_aid_average_amount } displayType="text" thousandSeparator prefix="$" className={ classes.bdNone }/>
                  ):('N/A')
                }
              </i>
            </p>    
          </React.Fragment>
        )
      }

      
      {inputs !== undefined  && (
      <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainWithoutBorder} ${classes.innnerFlowGroupMainWithoutBorderNew} ${classes.innnerFlowGroupMainWithoutBorderNewGapTwenty}` }>  
          <div className={ `${classes.borderGapFlexGroup} ${classes.borderGapFlexGroupLess}` }>
            {inputData.type_of_higher_education === 2 && (
              <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}  ${classes.annuvalGrowthScholorNewPrivate} ${classes.studentLoanFirst}` }>
                {inputs.subsidized_loans > 0 && (
                  <div className={ `${classes.fsLoans} ${classes.fsFirstLoan}` }>
                    <Button className={ classes.graphButtn } onClick={()=>{setStudentLoanType('subsidized-graph')}}>Repayment graph</Button>
                    <Button className={ classes.graphButtn } onClick={()=>{setStudentLoanType('subsidized-table')}}>Repayment table</Button>
                  </div>
                )}
                <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftScholorFederal}` }>
                  <h3>&nbsp;</h3>
                  <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}  ${classes.annuvalGrowthScholorFederal}` }>
                    <div>
                      <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Subsidized Student Loan' ) }>Subsidized Loans</span></p>
                    </div>
                  </div>
                </div>
                <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorFederal} ${classes.garphRightYer}` }>
                  <div>
                    <h3 className={ classes.totalFourYears }>
                      Total (
                      {inputData.years_diff}
                      {' '}
                      years)
                    </h3>
                    <div className={ `${classes.inputRightBlock} ${classes.inputRightBlockFlex}` }>
                      $
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ inputs.subsidized_loans } onChange={ ( e ) => updateValue( e, '', 'subsidized_loans', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                      <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Federal student loans have max limits depending on your student status, type of school you are attending, and more conditions. Go to Finance Explained and access the definition for student loan to read more about these limits." />
                    </div>
                  </div>
                  <div className={ classes.maxWidthBox }>
                    <h3 className={ classes.dottedTitle } aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Student Loan Limits' ) }>
                      <span className={ classes.dottedText }>Max Loan Limits</span>
                    </h3>
                    <div className={ `${classes.inputRightBlock} ${classes.inputRightBlok}` }>
                      <NumberFormat value={ maxSubLoanvalue !== undefined ? maxSubLoanvalue : 0 } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor} ${classes.annuvalGrowthScholorNewPrivate} ${inputData.type_of_higher_education === 2 && classes.studentLoanFirst}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftScholorFederal} ${classes.garphLeftScholorFederal}` }>
                {inputs.unsubsidized_loans > 0 && (
                  <div className={ classes.fsLoans }>
                    <Button className={ classes.graphButtn } onClick={()=>{setStudentLoanType('unsubsidized-graph')}}>Repayment graph</Button>
                    <Button className={ classes.graphButtn } onClick={()=>{setStudentLoanType('unsubsidized-table')}}>Repayment table</Button>
                  </div>
                )}
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.annuvalGrowthScholorFederal}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Unsubsidized Student Loan' ) }>Unsubsidized Loans</span></p>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorFederal} ${classes.garphRightScholorFederal}` }>
                <div>
                  <div className={ `${classes.inputRightBlock} ${classes.inputRightBlockFlex}` }>
                    $
                    <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ inputs.unsubsidized_loans } onChange={ ( e ) => updateValue( e, '', 'unsubsidized_loans', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Federal student loans have max limits depending on your student status, type of school you are attending, and more conditions. Go to Finance Explained and access the definition for student loan to read more about these limits." />
                  </div>
                </div>
                <div className={ `${classes.maxWidthBox} ${classes.maxWidthBoxNew}` }>
                  <div className={ `${classes.inputRightBlock} ${classes.inputRightBlok}` }>
                    <NumberFormat value={ displayMaxUnLoanAmount !== undefined ? displayMaxUnLoanAmount : 0 } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainWithoutBorder} ${classes.innnerFlowGroupMainWithoutBorderNew} ` }>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftScholorFederal}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Origination Fees' ) }>Origination Fees</span></p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.fed_sub_origination_fee }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithTwoDecimal }
                        min={ 0 }
                        max={ 5 }
                        step={ 0.1 }
                        marks={ originationMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'fed_sub_origination_fee', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorFederal} ${classes.garphRightFamily}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={ classes.numberCountPadding }>
                      { originationAmount !== undefined && originationAmount > 0 ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ originationAmount } displayType="text" thousandSeparator prefix="($" suffix=")" className={classes.dangerText}/>
                      ):(
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ originationAmount !== undefined ? originationAmount : 0 } displayType="text" thousandSeparator prefix="$" />
                      )}
                      
                    </div>
                  </div>
                </div>
                <div className={ `${classes.maxWidthBox} ${classes.opacityZero}` } />
              </div>
            </div>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftScholorFederal}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan' ) }>Term of the Loan</span></p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.fed_sub_term }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ yearFormatter }
                        min={ 0 }
                        max={ 15 }
                        step={ 1 }
                        marks={ termOfLoanMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'fed_sub_term', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorFederal} ${classes.garphRightFamily}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <font className={ `${classes.opacityZero} ${classes.bdNone}` }>$</font>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.fed_sub_term } onChange={ ( e ) => updateValue( e, '', 'fed_sub_term', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    <div className={ `${classes.flexContainerDollat} ${classes.flexContainerDollatNew}` }>
                      <font className={ classes.spanYrs}>yrs</font>
                      <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Federal student loans currently have a 10 year term of the loan." />
                    </div>
                  </div>
                </div>
                <div className={ `${classes.maxWidthBox} ${classes.opacityZero}` } />
              </div>
            </div>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftScholorFederal}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div>
                    <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.fed_sub_interest_rate }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithTwoDecimal }
                        min={ 0 }
                        max={ 15 }
                        step={ 0.1 }
                        marks={ interestRateMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'fed_sub_interest_rate', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorFederal} ${classes.garphRightFamily}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <font className={ classes.opacityZero }>$</font>
                    <NumberFormat
                      decimalScale={ isFocus!=='fed_sub_interest_rate'?2:5 }
                      fixedDecimalScale={isFocus!=='fed_sub_interest_rate'}
                      allowNegative={ false }
                      customInput={ TextField }
                      thousandSeparator
                      value={ inputs.fed_sub_interest_rate }
                      onChange={ ( e ) => updateValue( e, '', 'fed_sub_interest_rate', 'number' ) }
                      onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_sub_interest_rate') }}
                      onBlur={()=>emptyIsFocus('fed_sub_interest_rate')} 
                    />
                    <div className={ `${classes.flexContainerDollat} ${classes.flexContainerDollatNew}` }>
                      <font className={ classes.spanPerc}>%</font>
                      <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Federal student loans currently charge a 5.05% interest rate on student loans." />
                    </div>
                  </div>
                </div>
                <div className={ `${classes.maxWidthBox} ${classes.opacityZero}` } />
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTopNewText } />
        <ReactTooltip id="definationTitleNew" place="top" type="info" effect="solid" className={ classes.definitionTooltips } />
      </div>
      )}
      <div className={ `${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
        <span>Federal Student Loan Monthly Payment</span>
        {' '}
        <span>
          <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newFedSubMonthlyPayment !== undefined ? (moduleServices.module15Data.newFedSubMonthlyPayment+moduleServices.module15Data.newFedUnSubMonthlyPayment) : 0 } displayType="text" thousandSeparator prefix="$" />
        </span>
      </div>
      <div className={ `${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
        <span>Total Repayment Amount (Principal & Interest)</span>
        {' '}
        <span>
          <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newFedSubTotalPrincipalInterest !== undefined ? (moduleServices.module15Data.newFedSubTotalPrincipalInterest+moduleServices.module15Data.newFedUnSubTotalPrincipalInterest) : 0 } displayType="text" thousandSeparator prefix="$" />
        </span>
      </div>
    </div>
  );
};

FederalStudentLoansFirst.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  setStudentLoanType: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( FederalStudentLoansFirst );
