import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from './styles';

const FinancialCalculators = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.calculatorPage}>
        <div className={classes.financeBanner}>
          <div className={classes.heroImage} />
          <div className={classes.bannerText}>
            <h2>Financial Calculators</h2>
            <h4>Use Inkwiry&apos;s Financial Calculators for some quick decision-making.</h4>
          </div>
        </div>
        <div className={classes.moduleIcons}>
          <div className={classes.container}>
            <div className={classes.claculatorLinks}>
              <div className={classes.financialQuickLinks}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <h3><Link to="/financial-calculators/bond-profit">Bond Profit</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/budget">Build My Budget</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/car-affordability-calcs">Car Affordability</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/college-budget">College Budget</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/compare-careers">Compare Careers</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/compare-schools">Compare Schools</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/extra-loan-payment">Extra Loan Payment</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/federal-income-tax">Federal Income Taxes</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/mortgage-repayment">Mortgage Repayment</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/net-worth">Net Worth</Link></h3>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>


                    <div>
                      <h3><Link to="/financial-calculators/home-affordability-calcs">Real Estate Affordability</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/realestate-comparative-market-analysis-calcs">Real Estate Comparative Market Analysis (CMA)</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/savings-growth">Savings Growth</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/loan-repayment">Standard Loan Repayment</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/stock-profit">Stock Profit</Link></h3>
                    </div>
                    <div>
                      <h3><Link to="/financial-calculators/time-value-of-money">Time Value of Money</Link></h3>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

FinancialCalculators.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(FinancialCalculators);
