import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';

function summaryOutputGraph() {
  const outputSheetCompleteData = moduleServices.outputSheetCompleteData();
  const outputSheetStartYear = moduleServices.module1Data.start_year;
  let outputSheetCompletePicassoData = {};
  let yearsList = [];
  if(parseInt(outputSheetStartYear,10) > 0 && outputSheetCompleteData[outputSheetStartYear] !== undefined) {
    yearsList = outputSheetCompleteData['yearsList'];
    outputSheetCompletePicassoData = moduleServices.fcMasterCompleteData();
  }

  let showPieChart1 = true;
  const totalOutputTaxes =  outputSheetCompleteData['Tax Summary']['Total'];
  if(totalOutputTaxes === 0) {
    showPieChart1 = false;
  }

  let showPieChart2 = true;
  const totalOutputExpenses = outputSheetCompleteData['Average Calculations']['Total'];
  if(totalOutputExpenses === 0) {
    showPieChart2 = false;
  }

  let graphsData = {};
  if(yearsList.length > 0) {
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        zoomType: false,
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: yearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },                 
        yAxis: [{ // Primary yAxis
          min: 0,
          //tickInterval: 10000,
          labels: {
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            },
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          },
          title: {
            text: 'Income & Expenses',
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          }
        }, { // Tertiary yAxis
          title: {
            text: 'Net Worth',
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          },
          labels: {
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            },
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          },
          opposite: true
        }],
        tooltip: {
          formatter: function() {
            const chart = this;
            if( chart.y < 0 ){
              return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
            }
            else{ 
              return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
            }
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        legend: {
          itemStyle: {
            fontSize: '14px'
          },
          align: 'center'
        },
        series: [{
          name: 'Total Income',
          yAxis: 0,
          data: outputSheetCompleteData['grossIncomeGraphData'],
          stack: 'male',
          color: '#5B9BD5'
        }, {
          name: 'Total Expenses',
          yAxis: 0,
          data: outputSheetCompleteData['totalExpensesGraphData'],
          stack: 'female',
          color: '#FF0000'
        }, {
          name: 'Taxes',
          yAxis: 0,
          data: outputSheetCompleteData['incomeAfterTaxesGraphData'],
          stack: 'female',
          color: '#F4B183'
        }, {
          type: 'spline',
          name:'Net Worth',
          yAxis: 1,
          data: outputSheetCompleteData['netWorthGraphData'],
          color: '#00B050'
        }]
      }
    } else {
      graphsData['graph1'] = false;
    }

    if(outputSheetCompletePicassoData['YearsList'] !== undefined) {
      graphsData['graph2'] = true;
      graphsData['graph2Data'] = {
        chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: 1,
          plotShadow: false,
          type: 'area',
        },
        exporting: {
          enabled: false
        },
        title:{
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: outputSheetCompletePicassoData['YearsList'],
          tickmarkPlacement: 'on',
          title: {
            enabled: false
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return '';
            },
            style: {
              color: '#000000',
              fontSize: '14px'
            }

          }
        },
        tooltip: {
          formatter: function() {
            const chart = this;
            if( chart.y < 0 ){
              return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
            }
            else{ 
              return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
            }
          }
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666'
            }
          }
        },
        legend: {
          itemStyle: {
            fontSize: '14px'
          },
          align:'center',
          x:-50
        },
        series: [{
          name: 'Net Worth',
          data: outputSheetCompletePicassoData['Net Worth'],
          color: '#92D050'
        }, {
          name: 'Debt',
          data: outputSheetCompletePicassoData['Debt'],
          color: '#C9C9C9'
        }, {
          name: 'Cars + Real Estate',
          data: outputSheetCompletePicassoData['Cars RealEstate'],
          color: '#5DB7D3'
        }, {
          name: 'Cash + Investments',
          data: outputSheetCompletePicassoData['Cash Investments'],
          color: '#FFC000'
        }, {
          name: 'Gross Income',
          data: outputSheetCompletePicassoData['Gross Income'],
          color: '#4472C4'
        }, {
          name: 'Taxes',
          data: outputSheetCompletePicassoData['Taxes'],
          color: '#ED7D31'
        }, {
          name: 'Expenses',
          data: outputSheetCompletePicassoData['Expenses'],
          color: '#C00000'
        }]
      }
    } else{
      graphsData['graph2'] = false;
    }

    if(showPieChart1 === true) {
      graphsData['graph3'] = true;
      graphsData['graph3Value'] = totalOutputTaxes;
      graphsData['graph3Data'] = {
        chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          align:'center'
        },
        lang: {
          thousandsSep: ',',
        },
        exporting: {
          enabled: false
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
                        // eslint-disable-next-line
                        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                        style: {
                          color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                          fontSize: '14px'
                        }
                      }
                    }
                  },
                  series: [{
                    name: 'Expenses',
                    type: 'pie',      
                    data: [
                    ['Federal Income',    outputSheetCompleteData['Tax Summary']['Federal Income']],
                    ['Social Security',    outputSheetCompleteData['Tax Summary']['Social Security']],
                    ['Medicare',    outputSheetCompleteData['Tax Summary']['Medicare']],
                    ['State & Local',   outputSheetCompleteData['Tax Summary']['State & Local']],
                    ].filter(function(d) {
                      return d[1] > 0
                    })
                  }]
                }
              } else {
                graphsData['graph3'] = false;
              }

              if(showPieChart2 === true) {
                graphsData['graph4'] = true;
                graphsData['graph4Value'] = totalOutputExpenses;
                graphsData['graph4Data'] = {
                  chart: {
                    plotBackgroundColor: '#ffffff',
                    plotBorderColor: '#cccccc',
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    align:'center'
                  },
                  exporting: {
                    enabled: false
                  },
                  title: {
                    text: ''
                  },
                  credits: {
                    enabled: false
                  },
                  tooltip: {
              // eslint-disable-next-line
              pointFormat: '${point.y:,.0f} ({point.percentage:.0f}%)',
              fontSize: '14px'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                      // eslint-disable-next-line
                      format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                      style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                        fontSize: '14px'
                      }
                    }
                  }
                },
                series: [{
                  name: 'Expenses',
                  type: 'pie',
                  data: [
                  ['Tuition', outputSheetCompleteData['Average Calculations']['Tuition Average']],
                  ['Room & Board', outputSheetCompleteData['Average Calculations']['Room Board Average']],
                  ['Children', outputSheetCompleteData['Average Calculations']['Children Average']],
                  ['Entertainment', outputSheetCompleteData['Average Calculations']['Entertainment Average']],
                  ['Food', outputSheetCompleteData['Average Calculations']['Food Average']],
                  ['Rent', outputSheetCompleteData['Average Calculations']['Rent Average']],
                  ['Utilities', outputSheetCompleteData['Average Calculations']['Utilities Average']],
                  ['Health Insurance', outputSheetCompleteData['Average Calculations']['Insurance Average']],
                  ['Loans', outputSheetCompleteData['Average Calculations']['Loans Average']],
                  ['Miscellaneous', outputSheetCompleteData['Average Calculations']['Miscellaneous Average']],
                  ['Pet', outputSheetCompleteData['Average Calculations']['Pet Average']],
                  ['Transporation', outputSheetCompleteData['Average Calculations']['Transportation Average']],
                  ['Car', outputSheetCompleteData['Average Calculations']['Car Average']],
                  ['Technology', outputSheetCompleteData['Average Calculations']['Technology Average']],
                  ['Clothing & Personal Care', outputSheetCompleteData['Average Calculations']['Clothing Average']],
                  ['Home', outputSheetCompleteData['Average Calculations']['Home Average']],
                  ['Rental Property', outputSheetCompleteData['Average Calculations']['Rental Property Average']],
                  ].filter(function(d) {
                    return d[1] > 0
                  })
                }]
              };
            } else{
              graphsData['graph4'] = false;
            }

            if(yearsList.length > 0) {
              graphsData['graph5'] = true;
              graphsData['graph6'] = true;

              graphsData['graph5Data'] = {
                chart: {
                  plotBackgroundColor: '#ffffff',
                  plotBorderColor: '#cccccc',
                  plotBorderWidth: 1,
                  plotShadow: false,
                  zoomType: false,
                },
                title: {
                  text: ''
                },
                exporting: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                xAxis: [{
                  categories: yearsList,
                  crosshair: true,
                  labels: {
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }
                }],
        yAxis: [{ // Primary yAxis
          labels: {
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            },
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          },
          title: {
            text: 'Short-Term Investments',
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          },
          opposite: true
        }],
        tooltip: {
          formatter: function() {
            const chart = this;
            if( chart.y < 0 ){
              return chart.series.name +'<br/>'+chart.x +': <span style="color:#bf0000; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
            }
            else{ 
              return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
            }
          }
        },
        legend: {
          itemStyle: {
            fontSize: '14px'
          },
          align: 'center'
        },
        series: [{
          name: 'Short-Term Funds Flow',
          type: 'column',
          yAxis: 0,
          data: outputSheetCompleteData['investmentsShortTermFundsFlow'],
          tooltip: {
            valuePrefix: '$'
          },
          color: '#FFBE00',
          formatter: function() {
            const chart = this;
            if( chart.value < 0 ) {
              return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
            } else { 
              return formatDollar(Math.round(chart.value));
            }
          }
        },{
          name: 'Short-Term Investments Balance',
          type: 'spline',
          yAxis: 0,
          data: outputSheetCompleteData['investmentsShortTermBalance'],
          tooltip: {
            valuePrefix: '$'
          },
          color: '#FFBE50',
          formatter: function() {
            const chart = this;
            if( chart.value < 0 ) {
              return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
            } else { 
              return formatDollar(Math.round(chart.value));
            }
          }
        },{
          name: 'Cash Balance',
          type: 'spline',
          yAxis: 0,
          data: outputSheetCompleteData['investmentsCashBalance'],
          tooltip: {
            valuePrefix: '$'
          },
          color: '#00AC6C',
          formatter: function() {
            const chart = this;
            if( chart.value < 0 ) {
              return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
            } else { 
              return formatDollar(Math.round(chart.value));
            }
          }
        }]
      }

      graphsData['graph6Data'] = {
        chart: {
          plotBackgroundColor: '#ffffff',
          plotBorderColor: '#cccccc',
          plotBorderWidth: 1,
          plotShadow: false,
          zoomType: false,
        },
        title: {
          text: ''
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: [{
          categories: yearsList,
          crosshair: true,
          labels: {
            style: {
              color: '#000000',
              fontSize: '14px'
            }
          }
        }],
          yAxis: [{ // Primary yAxis
            labels: {
              formatter: function() {
                const chart = this;
                if( chart.value < 0 ) {
                  return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                } else { 
                  return formatDollar(Math.round(chart.value));
                }
              },
              style: {
                color: '#000000',
                fontSize: '14px'
              }
            },
            title: {
              text: 'Short-Term Investments',
              style: {
                color: '#000000',
                fontSize: '14px'
              }
            },
            opposite: true
          }],
          tooltip: {
            formatter: function() {
              const chart = this;
              if( chart.y < 0 ){
                return chart.series.name +'<br/>'+chart.x +': <span style="color:#bf0000; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
              }
              else{ 
                return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
              }
            }
          },
          legend: {
            itemStyle: {
              fontSize: '14px'
            },
            align: 'center'
          },
          series: [{
            name: 'Long-Term Funds Flow',
            type: 'column',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsLongTermFundsFlow'],
            tooltip: {
              valuePrefix: '$'
            },
            color: '#004E71',
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            }
          },{
            name: 'Long-Term Investments Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsLongTermBalance'],
            tooltip: {
              valuePrefix: '$'
            },
            color: '#416C83',
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            }
          },{
            name: 'Cash Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsCashBalance'],
            tooltip: {
              valuePrefix: '$'
            },
            color: '#00AC6C',
            formatter: function() {
              const chart = this;
              if( chart.value < 0 ) {
                return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
              } else { 
                return formatDollar(Math.round(chart.value));
              }
            }
          }]
        }
      } else {
        graphsData['graph5'] = false;
        graphsData['graph6'] = false;
      }

      if(outputSheetCompleteData['401k years List'] !== undefined && outputSheetCompleteData['401k years List'].length > 0) {
        graphsData['graph7'] = true;
        graphsData['graph7Data'] = {
                      chart: {
                          plotBackgroundColor: '#ffffff',
                          plotBorderColor: '#cccccc',
                          plotBorderWidth: 1,
                          plotShadow: false,
                          type: 'column',
                          zoomType: false,
                      },
                      title: {
                          text: ''
                      },
                      exporting: {
                        enabled: false
                      },
                      credits: {
                          enabled: false
                      },
                      xAxis: {
                          categories: yearsList,
                          labels: {
                              style: {
                                  color: '#000000',
                                  fontSize: '14px'
                              }
                          }
                      },                 
                      yAxis: [{ // Primary yAxis
                          // tickInterval: 10000,
                          labels: {
                              formatter: function() {
                                const chart = this;
                                if( chart.value < 0 ) {
                                    return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                                } else { 
                                    return formatDollar(Math.round(chart.value));
                                }
                              },
                              style: {
                                  color: '#000000',
                                  fontSize: '14px'
                              }
                          },
                          title: {
                              text: 'Total Annual Contribution & Earnings',
                              style: {
                                  color: '#000000',
                                  fontSize: '14px'
                              }
                          },
                          opposite: true
                      }, { // Tertiary yAxis
                          title: {
                              text: 'Ending Balance',
                              style: {
                                  color: '#000000',
                                  fontSize: '14px'
                              }
                          },
                          labels: {
                              formatter: function() {
                                const chart = this;
                                  if( chart.value < 0 ) {
                                      return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                                  } else { 
                                      return formatDollar(Math.round(chart.value));
                                  }
                              },
                              style: {
                                  color: '#000000',
                                  fontSize: '14px'
                              }
                          },
                          
                      }],
                      tooltip: {
                          formatter: function() {
                              const chart = this;
                              if( chart.y < 0 ){
                                  return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
                              }
                              else{ 
                                  return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
                              }
                          }
                      },
                      plotOptions: {
                          column: {
                              stacking: 'normal'
                          }
                      },
                      legend: {
                          itemStyle: {
                              fontSize: '14px'
                          }
                      },
                      series: [{
                          name: 'Employer Earnings',
                          yAxis: 0,
                          data: outputSheetCompleteData['Employer Earnings'],
                          stack: 'female',
                          color: '#C5E0B4'
                      }, {
                          name: 'Employer Contributions',
                          yAxis: 0,
                          data: outputSheetCompleteData['Employer Contributions'],
                          stack: 'female',
                          color: '#548235'
                      }, {
                          name: 'Personal Earnings',
                          yAxis: 0,
                          data: outputSheetCompleteData['Personal Earnings'],
                          stack: 'female',
                          color: '#BDD7EE'
                      }, {
                          name: 'Personal Contributions',
                          yAxis: 0,
                          data: outputSheetCompleteData['Personal Contributions'],
                          stack: 'female',
                          color: '#2E75B6'
                      }, {
                          name: 'Employer Vesting Reductions',
                          yAxis: 0,
                          data: outputSheetCompleteData['Employer Vesting Reductions'],
                          stack: 'female',
                          color: '#9E480E'
                      }, {
                          type: 'spline',
                          name: outputSheetCompleteData['Low Value'],
                          yAxis: 1,
                          data: outputSheetCompleteData['Low'],
                          color: '#70AD47'
                      }, {
                          type: 'spline',
                          name: outputSheetCompleteData['Middle Value'],
                          yAxis: 1,
                          data: outputSheetCompleteData['Actual Average'],
                          color: '#ED7D31'
                      }, {
                          type: 'spline',
                          name: outputSheetCompleteData['High Value'],
                          yAxis: 1,
                          data: outputSheetCompleteData['High'],
                          color: '#0974C3'
                      }]
                  };
      } else {
        graphsData['graph7'] = false;
      }

      if(outputSheetCompleteData['Roth IRA years List'] !== undefined && outputSheetCompleteData['Roth IRA years List'].length > 0) {
        graphsData['graph8'] = true;
        graphsData['graph8Data'] = {
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                type: 'column',
                zoomType: false,
            },
            title: {
                text: ''
            },
            exporting: {
              enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: yearsList,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                }
            },                 
            yAxis: [{ // Primary yAxis
                // tickInterval: 10000,
                labels: {
                    formatter: function() {
                      const chart = this;
                      if( chart.value < 0 ) {
                          return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                      } else { 
                          return formatDollar(Math.round(chart.value));
                      }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                title: {
                    text: 'Total Annual Contribution & Earnings',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                opposite: true
            }, { // Tertiary yAxis
                title: {
                    text: 'Ending Balance',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                labels: {
                    formatter: function() {
                      const chart = this;
                        if( chart.value < 0 ) {
                            return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                        } else { 
                            return formatDollar(Math.round(chart.value));
                        }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                
            }],
            tooltip: {
                formatter: function() {
                    const chart = this;
                    if( chart.y < 0 ){
                        return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
                    }
                    else{ 
                        return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                itemStyle: {
                    fontSize: '14px'
                }
            },
            series: [{
              name: 'Personal Earnings',
              yAxis: 0,
              data: outputSheetCompleteData['Roth IRA Personal Earnings'],
              stack: 'female',
              color: '#BDD7EE'
          }, {
              name: 'Personal Contributions',
              yAxis: 0,
              data: outputSheetCompleteData['Roth IRA Personal Contributions'],
              stack: 'female',
              color: '#2E75B6'
          }, {
              type: 'spline',
              name: outputSheetCompleteData['Roth IRA Low Value'],
              yAxis: 1,
              data: outputSheetCompleteData['Roth IRA Low'],
              color: '#70AD47'
          }, {
              type: 'spline',
              name: outputSheetCompleteData['Roth IRA Middle Value'],
              yAxis: 1,
              data: outputSheetCompleteData['Roth IRA Actual Average'],
              color: '#ED7D31'
          }, {
              type: 'spline',
              name: outputSheetCompleteData['Roth IRA High Value'],
              yAxis: 1,
              data: outputSheetCompleteData['Roth IRA High'],
              color: '#0974C3'
          }]
        };
      } else {
        graphsData['graph8'] = false;
      }

      if(outputSheetCompleteData['Seph IRA years List'] !== undefined && outputSheetCompleteData['Seph IRA years List'].length > 0) {
        graphsData['graph9'] = true;
        graphsData['graph9Data'] = {
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                type: 'column',
                zoomType: false,
            },
            title: {
                text: ''
            },
            exporting: {
              enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: yearsList,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                }
            },                 
            yAxis: [{ // Primary yAxis
                // tickInterval: 10000,
                labels: {
                    formatter: function() {
                      const chart = this;
                      if( chart.value < 0 ) {
                          return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                      } else { 
                          return formatDollar(Math.round(chart.value));
                      }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                title: {
                    text: 'Total Annual Contribution & Earnings',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                opposite: true
            }, { // Tertiary yAxis
                title: {
                    text: 'Ending Balance',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                labels: {
                    formatter: function() {
                      const chart = this;
                        if( chart.value < 0 ) {
                            return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                        } else { 
                            return formatDollar(Math.round(chart.value));
                        }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                
            }],
            tooltip: {
                formatter: function() {
                    const chart = this;
                    if( chart.y < 0 ){
                        return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
                    }
                    else{ 
                        return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                itemStyle: {
                    fontSize: '14px'
                }
            },
            series: [{
                name: 'Personal Earnings',
                yAxis: 0,
                data: outputSheetCompleteData['Seph IRA Personal Earnings'],
                stack: 'female',
                color: '#BDD7EE'
            }, {
                name: 'Personal Contributions',
                yAxis: 0,
                data: outputSheetCompleteData['Seph IRA Personal Contributions'],
                stack: 'female',
                color: '#2E75B6'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Seph IRA Low Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Seph IRA Low'],
                color: '#70AD47'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Seph IRA Middle Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Seph IRA Actual Average'],
                color: '#ED7D31'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Seph IRA High Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Seph IRA High'],
                color: '#0974C3'
            }]
        }
      } else {
        graphsData['graph9'] = false;
      }

      if(outputSheetCompleteData['Pension years List'] !== undefined && outputSheetCompleteData['Pension years List'].length > 0) {
        graphsData['graph10'] = true;
        graphsData['graph10Data'] = {
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                type: 'column',
                zoomType: false,
            },
            title: {
                text: ''
            },
            exporting: {
              enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: yearsList,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                }
            },                 
            yAxis: [{ // Primary yAxis
                // tickInterval: 10000,
                labels: {
                    formatter: function() {
                      const chart = this;
                      if( chart.value < 0 ) {
                          return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                      } else { 
                          return formatDollar(Math.round(chart.value));
                      }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                title: {
                    text: 'Total Annual Contribution & Earnings',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                opposite: true
            }, { // Tertiary yAxis
                title: {
                    text: 'Ending Balance',
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                labels: {
                    formatter: function() {
                      const chart = this;
                        if( chart.value < 0 ) {
                            return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                        } else { 
                            return formatDollar(Math.round(chart.value));
                        }
                    },
                    style: {
                        color: '#000000',
                        fontSize: '14px'
                    }
                },
                
            }],
            tooltip: {
                formatter: function() {
                    const chart = this;
                    if( chart.y < 0 ){
                        return chart.series.name +'<br/>'+chart.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
                    }
                    else{ 
                        return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                itemStyle: {
                    fontSize: '14px'
                }
            },
            series: [{
                name: 'Personal Earnings',
                yAxis: 0,
                data: outputSheetCompleteData['Pension Personal Earnings'],
                stack: 'female',
                color: '#BDD7EE'
            }, {
                name: 'Employer Contributions',
                yAxis: 0,
                data: outputSheetCompleteData['Pension Employer Contributions'],
                stack: 'female',
                color: '#548235'
            }, {
                name: 'Personal Contributions',
                yAxis: 0,
                data: outputSheetCompleteData['Pension Personal Contributions'],
                stack: 'female',
                color: '#2E75B6'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Pension Low Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Pension Low'],
                color: '#70AD47'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Pension Middle Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Pension Actual Average'],
                color: '#ED7D31'
            }, {
                type: 'spline',
                name: outputSheetCompleteData['Pension High Value'],
                yAxis: 1,
                data: outputSheetCompleteData['Pension High'],
                color: '#0974C3'
            }]
        };
      } else {
        graphsData['graph10'] = false;
      }
      return graphsData; 
    }
    export default summaryOutputGraph;
