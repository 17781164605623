import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import aboutBanner from '../../assets/img/about/about_us_banner.jpg';
import aboutNavigation from '../../assets/img/about/about_navigation_tools.png';
import whiteLogo from '../../assets/img/about/logo-w.png';

const About = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.aboutPage }>
        <div className={ classes.aboutBanner }>
          <img src={ aboutBanner } alt="About Banner" />
          <div className={ classes.container }>
            <div className={ classes.aboutBannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography className={ classes.bannerCaptionText } variant="h3" component="h3">
                  See and build your best tomorrows now.
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.smartApps }>
          <div className={ classes.container }>
            <div className={ classes.smartAppsRow }>
              <Typography variant="h2" component="h2">
                Smart apps to make smart moves
              </Typography>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 7 }>
                  <div className={ classes.smartAppsLeft }>
                    <Typography variant="h3" component="h3">Inkwiry brings business intelligence to personal and financial planning via integrated apps and tools so young people can make smart moves in life and reach their individual goals.</Typography>
                    <p>Successful people everywhere have one thing in common – a strategy and a plan that works for them. But designing a plan requires honing goals and aspirations, exploring and organizing overwhelming amounts of data, running scenarios and comparing options. Inkwiry provides a methodology to do exactly this, running data behind the scenes and easily simulating life&apos;s choices so students and young professionals can achieve financial independence.</p>
                    <p>Inkwiry builds software so young people everywhere can see and build their best tomorrows now.</p>
                  </div>
                </Grid>
                <Grid item xs={ 12 } sm={ 5 }>
                  <div className={ classes.smartAppsRight }>
                    <figure>
                      <img src={ aboutNavigation } alt="About navigation tools" />
                    </figure>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={ classes.empoweringInnovation }>
          <div className={ classes.container }>
            <div className={ classes.empowerRow }>
              <div className={ classes.empowerDesc }>
                <Typography variant="h2" component="h2">Empowering innovation</Typography>
                <div className={ classes.empowerCntnt }>
                  <Typography variant="h3" component="h3">We envision a better world where anyone, empowered by Inkwiry&apos;s business intelligence and methodology, can innovate and create transformative, win-win solutions to resolve the greater issues of today and tomorrow.</Typography>
                  <p>We believe in the power of Inkwiry to solve problems, large and small. We also believe that together, anything is possible. For instance, we need clean air, clean water, food and shelter for everyone. Things need to change and change can start with you.</p>
                  <p>And yes, it seems like we have an equation to explain just about anything. </p>
                </div>
                <p className={ classes.aswarenessBlock }>awareness + discovery + due diligence + strategies + financial modeling = solutions</p>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.builtInkwiry }>
          <div className={ classes.container }>
            <div className={ classes.builtRow }>
              <div className={ classes.builtDesc }>
                <Typography variant="h2" component="h2">Inkwiry built Inkwiry</Typography>
                <div className={ classes.builtCntnt }>
                  <Typography variant="h3" component="h3">First came a vision, followed by the process of awareness, discovery, research, planning, and financial modeling, over and over, rinse and repeat. The big picture was broken into granular details, and each detail was executed one by one. Next came checking in, every step of the way, to make sure everything was on track with the vision. Finally, there was testing, comparing, refining and building.</Typography>
                  <p>This is how Inkwiry was developed over a series of years, and this is how a strategy can be generated to transform your personal life and achieve your own version of financial independence. The only difference: you now have the benefit of Inkwiry&apos;s technology to streamline your process. </p>
                  <Typography variant="h3" component="h3">Inkwiry built Inkwiry. Now use it to do something amazing.</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.beganQuestion }>
          <div className={ classes.container }>
            <div className={ classes.builtRow }>
              <div className={ classes.builtDesc }>
                <Typography variant="h2" component="h2">It began with a question</Typography>
                <div className={ classes.bQuestion }>
                  <div className={ classes.beganQuestionLeft }>
                    <Typography variant="h3" component="h3">Five brothers. A scrum of tough competition, brutal criticism, mutual respect, close collaboration, everlasting support and unconditional love.</Typography>
                    <p>One day, Chris, a junior majoring in architecture and second oldest of the five, aired an observation about students: “We don&apos;t understand the world. We don&apos;t understand finance, and we have no idea how to connect our education to the real world.” This sparked the question that became a topic at many dinner table discussions: How can we fix this?</p>
                  </div>
                  <div className={ classes.beganQuestionRight }>
                    <p>Nick, the oldest, with boots on the ground helping homebuyers obtain mortgages, gained special insight into applicants&apos; financial details. His real world experience confirmed something was wrong – most young people lacked basic financial literacy and an actionable plan.</p>
                    <p>James, a finance major from Villanova&apos;s School of Business who worked as a Leveraged Finance Investment Banker in New York City, took the lead in developing the Financial Configurator. Ultimately, it required a team of more than a dozen people of diverse skills and backgrounds to strategize, design, code and bring the application to life, providing a platform to help those with a mindset for achievement and a desire for financial independence.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.advanceTechnology }>
          <div className={ classes.container }>
            <div className={ classes.builtRow }>
              <div className={ classes.builtDesc }>
                <Typography variant="h2" component="h2">Advanced technology, shaped by collaboration</Typography>
                <div className={ classes.builtDescCntnt }>
                  <Typography variant="h3" component="h3">At Inkwiry, we not only have groundbreaking ideas, we provide revolutionary technology, shaped by collaboration and crafted to deliver a unique experience to each individual.</Typography>
                  <p>Your experience is powered by Inkwiry&apos;s Simulator Engine, running an enormity of code to generate a matrix of data that reacts to your inputs at the speed of thought. This is the strength of an engine you will never see, yet it is working with you, side-by-side, to help you simulate and model your assumptions in real time to develop a viable financial life plan. Technically, this is now known as &quot;Dynamic Modular Finance with Active Data Output.&quot;</p>
                  <p>We are continuously pushing limits to create applications to help you find faster solutions:</p>
                </div>
              </div>
              <div className={ classes.advanceTechnologyList }>
                <ul>
                  <li><p>Inkwiry Financial Configurator (FC)</p></li>
                  <li><p>Inkwiry Sense</p></li>
                  <li><p>Inkwiry Spaces</p></li>
                  <li><p>Inkwiry Connect</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.ourIntrest }>
          <div className={ classes.container }>
            <div className={ classes.builtRow }>
              <div className={ classes.builtDesc }>
                <Typography variant="h2" component="h2">Our interest is in helping you succeed</Typography>
                <div className={ classes.builtDescCntnt }>
                  <Typography variant="h3" component="h3">Inkwiry is independent, so you can be. We are free of any conflicts of interest, not affiliated with any educational or financial institution, and not sponsored by any third party.</Typography>
                  <p>
                    We do not allow advertising on our site, and you will never find Inkwiry giving special preference to any institution. We have no vested interest in the outcome of the strategies and plans you build in Inkwiry,
                    as long as they place you and your priorities first. We believe that all information, costs and benefits should be evaluated fairly, and a true qualitative outlook requires an evenhanded assessment of all options. Our staff adheres to a clear policy prohibiting the presence or even the appearance of conflicts-of-interest in our procedures.
                    We respect and protect our clients&apos; privacy, and we will never sell private data or share any personal information with third parties.
                  </p>
                  <p>It&apos;s your best interest that we have in mind.</p>
                </div>
              </div>
              <div className={ classes.whiteLogo }>
                <img src={ whiteLogo } alt="Inkwiry Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( About );
