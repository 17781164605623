import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Typography, Dialog, Button, IconButton} from '@material-ui/core';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro'; 
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import styles from './styles';
import Pageloader from '../../../components/ui/pageloader';
import logoImage from '../../../assets/img/pf101-dashboard/dashboard-logo.png';
import uickTourVideoCover from '../../../assets/img/pf101-dashboard/Quick-Tour-video-cover.png';
import tourVideo from '../../../assets/img/pf101-dashboard/tour_video.png';
import planWithInkwiry from '../../../assets/img/pf101-dashboard/plan-with-inkwiry.png';
import myCareerSketch from '../../../assets/img/pf101-dashboard/my-career-sketch.png';
import powerup from '../../../assets/img/pf101-dashboard/powerup.png';

const UPDATE_USER = loader( '../../../graphql/schema/welcome/updateuser-identity.graphql' );
const UPDATE_SEQUENCE_USER = loader( '../../../graphql/schema/welcome/update-sequence-number.graphql' );
const UPDATE_BLUE_SEQUENCE = loader( '../../../graphql/schema/welcome/set-blue-print-sequence.graphql' );
const GET_USER_IDENTITY = loader( '../../../graphql/schema/welcome/get-user-identity.graphql' );

const Pf101NewMemberWelcome = ( props ) => {
  const { classes, history } = props;
  const [videoPlayer, setVideoPlayer] = useState( null );
  const [loading, setLoading] = useState( true );
  const [buttonDisabled, setButtonDisabled] = useState( true );
  const [showSection, setShowSection] =  useState(  );
  const [navigationUrl, setNavigationUrl] =  useState("");
  

  const [getUserIdentity] = useLazyQuery( GET_USER_IDENTITY, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
     if(response.getUserIdentity.status) {
      setShowSection(true);
     } else {
      setShowSection(false);
     }
     setLoading(false);
    },
    onError( ) { 
     setLoading(false);
      return false;
    },
  } );

  useEffect( () => {
    window.scrollTo({
      top: 0,
    });
    getUserIdentity();                        
  }, [])

  const handleClickOpen = ( tile ) => {
    setVideoPlayer( tile );
    setButtonDisabled( false );
  };

  const handleClose = () => {
    setVideoPlayer( null );
  };

  const [saveExercise] = useMutation( UPDATE_USER, {
    onCompleted( response ) {
    },
    onError( ) {
      return false;
    },
  } );

  const [updateSequence] = useMutation( UPDATE_SEQUENCE_USER, {
    onCompleted( {
      updateSequenceNumber: {
        status
      },
    } ) {
      if(status === true){
        setLoading(false);
        history.push(navigationUrl);
      }
    },
    onError( ) {
      setLoading(false);
      return false;
    },
  } );


  const [updateBlueSequence] = useMutation( UPDATE_BLUE_SEQUENCE, {
    onCompleted( {
      setBluePrintSequence: {
        status
      },
    } ) {
      if(status === true){
        updateSequence({variables: { sequence_status: 6}});
      }
    },
    onError( ) {
      setLoading(false);
      return false;
    },
  } );

  const printSequence = (url)=>{
    setNavigationUrl(url);
    setLoading(true);
    updateBlueSequence({variables: { steps: 1}});
  }

  const handleContinue = (data)=>{
    setShowSection( true );
    saveExercise( {
      variables: {
        mcs_video_status: data
      },
    } );
  }

  

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.dashboardPage }>
        <div className={ classes.containerFluidRow }>
          <div className={ classes.container }>
            <div className={ classes.dashboardLogo }>
              <Link to="/">
                <img src={ logoImage } alt="logo" />
              </Link>
            </div>
          </div>
          <div className={ classes.container }>
          {
            (showSection === false) && (

              <div className={ classes.phraseContent }>
                <Typography variant="h1" component="h1">First, take a quick tour:</Typography>
                <div className={ classes.quickTourCover }>
                  <img src={ uickTourVideoCover } alt="" />
                  <span className={ classes.tourVideoIcon }>
                    <span>
                      <img src={ tourVideo } alt="" onClick={ handleClickOpen }/>
                    </span>
                  </span>
                </div>
                {videoPlayer && (videoPlayer !== null) && (
                  <Dialog
                    open={(videoPlayer !== null)}
                    onClose={handleClose}
                    className={classes.dialogVideo}
                    maxWidth='lg'
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setVideoPlayer( null );
                      }}
                      className={classes.closeIcon}
                    >
                      <CancelRounded />
                    </IconButton>
                    <iframe
                      width="750"
                      height="430"
                      src="https://www.youtube.com/embed/9dscV917BEw?autoplay=1" 
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </Dialog>
                )}
                <div className={ classes.quickTourBtnRow }>
                  <Button className="quickTourBtn" onClick={() => { handleContinue(1) }
                    } 
                    disabled = {buttonDisabled}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            )
          }

            {
              (showSection === true) && (
                <div className={ classes.welcomeText }>
                  <div className={ classes.powerUpLogo }>
                    <Typography variant="h1" component="h1">Financial Freedom ... Faster</Typography>
                  </div>
                  <div className={ classes.certificateDesc }>
                    <div className={ classes.welcomeDesc }>
                      <p>How do you achieve financial freedom?</p>
                      <p>By growing your money skills and knowledge and by applying the power of planning. We’re not talking about that boring, archaic planning of yesterday. We’re talking about planning at the speed of thought – anywhere and at any time.</p>
                      <p>Inkwiry allows you to see the financial outcome of your decisions before you even make them. No one can predict the future. But you can plan for it.</p>
                      <p>Get started by selecting Personal Finance 101, My Career Sketch or Plan With Inkwiry.</p>
                    </div>
                    <div className={ classes.logoSec }>
                      <div className={ classes.logoRow }>
                        <div className={ classes.logoRowOne }>
                          <Link>
                            <img src={ powerup } alt="" onClick={()=>{printSequence("/pf101/dashboard")}}/>
                          </Link>
                        </div>
                        <div className={ classes.logoRowTwo }>
                          <Link>
                            <img src={ myCareerSketch } alt="" onClick={()=>{printSequence("/my-career-sketch/dashboard")}}/>
                          </Link>
                        </div>
                      </div>
                      <div className={ classes.logoRowFull }>
                        <div className={ classes.logoRowThree }>
                          <Link>
                            <img src={ planWithInkwiry } alt="" onClick={()=>{printSequence("/fc-blueprint")}}/>
                          </Link>
                          <div className={ classes.jumpDesktopBtn }>
                            <p>Or jump right to your Desktop:</p>
                            <Link onClick={()=>{printSequence("/desktop")}}> GO TO DESKTOP&nbsp;&nbsp;&nbsp;&#62;</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
         </div>
      </div>
    </Typography>
  );
};

Pf101NewMemberWelcome.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Pf101NewMemberWelcome );
