import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import * as moduleServices from '../../../calculations/modules-services';

const HomeBottom = ( props ) => {
  const {
    classes, handleDefinationPopup, handleSlide, activeStep, inputData, summaryYear, setSummaryYear, setOpenWorkSheet
  } = props;
  const [openPerchaseTable, setOpenPerchaseTable] = React.useState( false );
  const [openExpensesTable, setOpenExpensesTable] = React.useState( false );
  const [slideNumber, setSlideNumber] = React.useState();
  const [updatedYear, setUpdatedYear] = React.useState( new Date().getFullYear() );


  const individualLivingCalc = (label) => {
     const year = parseInt( updatedYear, 10 );
     let calculatedValue = 0;
     if(year > 0) {
         const incomeStatementData = moduleServices.incomeStatementCompleteData();
         if(incomeStatementData[year] !== undefined && incomeStatementData[year][label] !== undefined ) {
           calculatedValue = incomeStatementData[year][label];
         }
     } 
     return (
       <span className={calculatedValue < 0 && classes.redText}>
         <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ calculatedValue } prefix={ calculatedValue >= 0 ? '$' : '($' } suffix={ calculatedValue < 0 && ')' } />
       </span>
     )
  }

  // Generate Start year dropdown list
  // Start Years List (1 to 10)
  const startYearsArray = [];
  if ( moduleServices.module1Data.start_year !== undefined ) {
  		for ( let i = 0; i < 15; i+=1 ) {
      startYearsArray.push( parseInt( moduleServices.module1Data.start_year ) + i );
    }
  }
  if ( startYearsArray !== '' ) {
    startYearsArray.unshift( 0 );
  }

  useEffect(()=>{
    if(summaryYear === undefined || summaryYear === '') {
      if(inputData.year_of_purchase !== undefined && inputData.year_of_purchase > 0) {
        setUpdatedYear(inputData.year_of_purchase);
      } else {
         setUpdatedYear(new Date().getFullYear());  
      }
    } else {
       setUpdatedYear(summaryYear) 
    }
    // eslint-disable-next-line
  },[])

  useEffect( () => {
    if ( activeStep !== slideNumber ) {
      setSlideNumber( activeStep );
      if ( slideNumber === 16 ) {
        setOpenPerchaseTable( true );
        setOpenExpensesTable( true );
      } else {
        setOpenPerchaseTable( false );
        setOpenExpensesTable( false );
      }
    }
    // eslint-disable-next-line
  }, [activeStep, slideNumber]);

  const handleSummaryYear = ( event ) => {
    setUpdatedYear( event.target.value );
    setSummaryYear( event.target.value )
  };

  let totalIncomePercentage = 0;
  if(moduleServices.module13Data.totalIncomeYear !== undefined && moduleServices.module13Data.totalIncomeYear > 0 && moduleServices.module13Data.totalMonthlyExpenses !== undefined && moduleServices.module13Data.totalMonthlyExpenses > 0) {
    totalIncomePercentage = (moduleServices.module13Data.totalMonthlyExpenses/moduleServices.module13Data.totalIncomeYear)*100;
  }
  return (
    <div className={ classes.contentBlock }>
      <Button className={ classes.worksheetButton } onClick={()=>{setOpenWorkSheet(true)}}>SUMMARY WORKSHEET</Button>
      <div className={ classes.worksheetRow }>
        <div className={ classes.worksheetTable }>
          <table className={ classNames( classes.yearTable, activeStep === 16 && classes.hideFirstColumn ) }>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Purchase Year</th>
                <th>{inputData.year_of_purchase}</th>
              </tr>
            </thead>
            {( openPerchaseTable === true || activeStep === 16 ) && (
              <tbody>
                <tr onClick={ () => handleSlide( 4 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Purchase Price</td>
                  <td>
                    {moduleServices.module13Data.homePurchasePrice !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homePurchasePrice } prefix={ moduleServices.module13Data.homePurchasePrice >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homePurchasePrice < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>

                <tr onClick={ () => handleSlide( 4 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Down Payment</td>
                  <td>
                    {moduleServices.module13Data.homeDownPayment !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homeDownPayment } prefix={ moduleServices.module13Data.homeDownPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homeDownPayment < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 13 ) } className={ classes.cursorPointer }>
                  <td />
                  <td><span aria-hidden="true" data-for="definationTitleBottom1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Closing Costs' ) } className={ classes.dashedBorder }>Closing Costs</span></td>
                  <td>
                    {moduleServices.module13Data.homeClosingCosts !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homeClosingCosts } prefix={ moduleServices.module13Data.homeClosingCosts >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homeClosingCosts < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 14 ) } className={ classes.cursorPointer }>
                  <td />
                  <td><span aria-hidden="true" data-for="definationTitleBottom1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Fit Out Costs: Real Estate' ) } className={ classes.dashedBorder }>Fit Out Costs</span></td>
                  <td>
                    {moduleServices.module13Data.homeFitOutCosts !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homeFitOutCosts } prefix={ moduleServices.module13Data.homeFitOutCosts >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homeFitOutCosts < 0 && ')' } />
                    ) : ( '$0' )}
                    <ReactTooltip id="definationTitleBottom1" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
                  </td>
                </tr>
              </tbody>
            )}
            <tbody>
              <tr className={ classNames( classes.blueBottomBorder, classes.cursorPointer ) } onClick={ () => { setOpenPerchaseTable( !openPerchaseTable ); } }>
                <td>
                  {openPerchaseTable === true ? (
                    <span className="plus">
                      <i className="fa fa-minus" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="plus">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  )}
                </td>
                <td><b>Cash Needed at Purchase</b></td>
                <td>
                  <b>
                    {moduleServices.module13Data.cashNeedsPurchase !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.cashNeedsPurchase } prefix={ moduleServices.module13Data.cashNeedsPurchase >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.cashNeedsPurchase < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </td>
              </tr>
              <tr>
                <td />
                <td>Total Cash Available</td>
                <td>
                  {moduleServices.module13Data.moreHigherTotalCashAvailable !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.moreHigherTotalCashAvailable } prefix={ moduleServices.module13Data.moreHigherTotalCashAvailable >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.moreHigherTotalCashAvailable < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td />
                <td>Total Investments Available</td>
                <td>
                  {moduleServices.module13Data.moreHighermoreTotalLiquid !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.moreHighermoreTotalLiquid } prefix={ moduleServices.module13Data.moreHighermoreTotalLiquid >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.moreHighermoreTotalLiquid < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.blueBottomBorder }>
                <td />
                <td>
                  <span aria-hidden="true" data-for="definationTitleBottom" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Debt-to-Income Ratio (KPI)' ) } className={ classes.dashedBorder }>
                    <b>Debt-to-Income Ratio</b>
                  </span>
                </td>
                
                <td className={ `${moduleServices.module13Data.debtToIncomeRatio > 36 && classes.redData} ${moduleServices.module13Data.debtToIncomeRatio <= 36 && moduleServices.module13Data.debtToIncomeRatio !== 0  && classes.greenData} ${moduleServices.module13Data.debtToIncomeRatio === 0 && classes.grayData}` }>
                  <b>
                    {moduleServices.module13Data.debtToIncomeRatio !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module13Data.debtToIncomeRatio } prefix={ moduleServices.module13Data.debtToIncomeRatio >= 0 ? '' : '(' } suffix={ moduleServices.module13Data.debtToIncomeRatio < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </b>
                </td>
              </tr>
              <tr className={ classes.blueBottomBorder }>
                <td />
                <td><b>Cash Flow Check</b></td>
                <td className={ classNames(moduleServices.cashFlowCheckVal > 0 && classes.redData,  moduleServices.cashFlowCheckVal <= 0 && classes.greenData ) }>
                  <b>{moduleServices.cashFlowCheckVal <= 0 ? 'Cash Flow Positive' : moduleServices.cashFlowCheckVal}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={ classes.yearTable }>
            <thead>
              <tr>
                <th>Income Statement Summary</th>
                <th>
                  Year&nbsp;
                  <select value={ updatedYear } onChange={ ( e ) => { handleSummaryYear( e ); } }>
                    {startYearsArray.map( ( year ) => <option value={ year } key={ year }>{year}</option> )}
                  </select>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="">
                  Income After Taxes
                </td>
                <td>
                  {individualLivingCalc('Income After Taxes')}
                </td>
              </tr>
              <tr>
                <td className={ classes.leftSpace }>
                  Living Expenses
                </td>
                <td>
                  {individualLivingCalc('Total Living Expenses')}
                </td>
              </tr>
              <tr>
                <td className={ classes.leftSpace }>
                  Car Expenses
                </td>
                <td>
                  {individualLivingCalc('Total Car Expenses')}
                </td>
              </tr>
              <tr>
                <td className={ classes.leftSpace }>
                  Real Estate Expenses
                </td>
                <td>
                  {individualLivingCalc('Total Real Estate Expenses')}
                </td>
              </tr>
              <tr>
                <td className={ classes.leftSpace }>
                  Loan Payments
                </td>
                <td>
                  {individualLivingCalc('Total Interest Expenses')}
                </td>
              </tr>
              <tr>
                <td>
                  Total Expenses
                </td>
                <td>
                  {individualLivingCalc('Total Expenses')}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classes.blueBottomBorder }>
                <th>
                  Net Income
                </th>
                <th>
                  {individualLivingCalc('Net Income')}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={ classes.expensesTable }>
          <table className={ classNames( classes.annualTable, activeStep === 16 && classes.hideFirstColumn ) }>
            <tbody>
              <tr>
                <th />
                <th>Home Expenses</th>
                <th>Monthly</th>
                <th>Annual</th>
              </tr>
            </tbody>
            {( openExpensesTable === true || activeStep === 16 ) && (
              <tbody>
                <tr onClick={ () => handleSlide( 5 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Mortgage Payment (Principal & Interest)</td>
                  <td className={moduleServices.module13Data.mortgagePaymentExpenses !== undefined && moduleServices.module13Data.mortgagePaymentExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.mortgagePaymentExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.mortgagePaymentExpenses } prefix={ moduleServices.module13Data.mortgagePaymentExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.mortgagePaymentExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.mortgagePaymentExpenses !== undefined && moduleServices.module13Data.mortgagePaymentExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.mortgagePaymentExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.mortgagePaymentExpenses * 12 } prefix={ moduleServices.module13Data.mortgagePaymentExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.mortgagePaymentExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 10 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Property Taxes</td>
                  <td className={moduleServices.module13Data.propertyTaxesExpenses !== undefined && moduleServices.module13Data.propertyTaxesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.propertyTaxesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.propertyTaxesExpenses } prefix={ moduleServices.module13Data.propertyTaxesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.propertyTaxesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.propertyTaxesExpenses !== undefined && moduleServices.module13Data.propertyTaxesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.propertyTaxesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.propertyTaxesExpenses * 12 } prefix={ moduleServices.module13Data.propertyTaxesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.propertyTaxesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>

                <tr onClick={ () => handleSlide( 8 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Private Mortgage Insurance (PMI)</td>
                  <td className={moduleServices.module13Data.privateMortgageInsExpenses !== undefined && moduleServices.module13Data.privateMortgageInsExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.privateMortgageInsExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.privateMortgageInsExpenses } prefix={ moduleServices.module13Data.privateMortgageInsExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.privateMortgageInsExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.privateMortgageInsExpenses !== undefined && moduleServices.module13Data.privateMortgageInsExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.privateMortgageInsExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.privateMortgageInsExpenses * 12 } prefix={ moduleServices.module13Data.privateMortgageInsExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.privateMortgageInsExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 11 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Homeowner&apos;s Insurance</td>
                  <td className={moduleServices.module13Data.homeOwnersInsExpenses !== undefined && moduleServices.module13Data.homeOwnersInsExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.homeOwnersInsExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homeOwnersInsExpenses } prefix={ moduleServices.module13Data.homeOwnersInsExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homeOwnersInsExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.homeOwnersInsExpenses !== undefined && moduleServices.module13Data.homeOwnersInsExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.homeOwnersInsExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.homeOwnersInsExpenses * 12 } prefix={ moduleServices.module13Data.homeOwnersInsExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.homeOwnersInsExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr className={ classNames( classes.blueBottomBorder, classes.cursorPointer ) } onClick={ () => handleSlide( 9 ) }>
                  <td />
                  <td><b>PITI (Principal, Interest, Taxes, & Insurance)</b></td>
                  <td  className={moduleServices.module13Data.pitiExpenses !== undefined && moduleServices.module13Data.pitiExpenses < 0 && classes.redText}>
                    <b>
                      {' '}
                      {moduleServices.module13Data.pitiExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.pitiExpenses } prefix={ moduleServices.module13Data.pitiExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.pitiExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </b>
                  </td>
                  <td  className={moduleServices.module13Data.pitiExpenses !== undefined && moduleServices.module13Data.pitiExpenses < 0 && classes.redText}>
                    <b>
                      {' '}
                      {moduleServices.module13Data.pitiExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.pitiExpenses * 12 } prefix={ moduleServices.module13Data.pitiExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.pitiExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </b>
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 12 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Maintenance</td>
                  <td className={moduleServices.module13Data.maintenanceExpenses !== undefined && moduleServices.module13Data.maintenanceExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.maintenanceExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.maintenanceExpenses } prefix={ moduleServices.module13Data.maintenanceExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.maintenanceExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.maintenanceExpenses !== undefined && moduleServices.module13Data.maintenanceExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.maintenanceExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.maintenanceExpenses * 12 } prefix={ moduleServices.module13Data.maintenanceExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.maintenanceExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 12 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Association Fees</td>
                  <td className={moduleServices.module13Data.assiciationFeesExpenses !== undefined && moduleServices.module13Data.assiciationFeesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.assiciationFeesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.assiciationFeesExpenses } prefix={ moduleServices.module13Data.assiciationFeesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.assiciationFeesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.assiciationFeesExpenses !== undefined && moduleServices.module13Data.assiciationFeesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.assiciationFeesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.assiciationFeesExpenses * 12 } prefix={ moduleServices.module13Data.assiciationFeesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.assiciationFeesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
                <tr onClick={ () => handleSlide( 12 ) } className={ classes.cursorPointer }>
                  <td />
                  <td>Utilities</td>
                  <td className={moduleServices.module13Data.utilitiesExpenses !== undefined && moduleServices.module13Data.utilitiesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.utilitiesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.utilitiesExpenses } prefix={ moduleServices.module13Data.utilitiesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.utilitiesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                  <td className={moduleServices.module13Data.utilitiesExpenses !== undefined && moduleServices.module13Data.utilitiesExpenses < 0 && classes.redText}>
                    {moduleServices.module13Data.utilitiesExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.utilitiesExpenses * 12 } prefix={ moduleServices.module13Data.utilitiesExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.utilitiesExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </td>
                </tr>
              </tbody>
            )}
            <tbody>
              <tr className={ classNames( classes.blueBottomBorder, classes.cursorPointer ) } onClick={ () => { setOpenExpensesTable( !openExpensesTable ); } }>
                <td>
                  {openExpensesTable === true ? (
                    <span className="plus">
                      <i className="fa fa-minus" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="plus">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  )}
                </td>
                <td><b>Total Home Expenses</b></td>
                <td className={moduleServices.module13Data.totalMonthlyExpenses !== undefined && moduleServices.module13Data.totalMonthlyExpenses < 0 && classes.redText}>
                  <b>
                    {' '}
                    {moduleServices.module13Data.totalMonthlyExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalMonthlyExpenses } prefix={ moduleServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </td>
                <td className={moduleServices.module13Data.totalMonthlyExpenses !== undefined && moduleServices.module13Data.totalMonthlyExpenses < 0 && classes.redText}>
                  <b>
                    {' '}
                    {moduleServices.module13Data.totalMonthlyExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalMonthlyExpenses * 12 } prefix={ moduleServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalMonthlyExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </td>
              </tr>
              <tr>
                <td />
                <td className={ classes.incomeSpace }><i>% of Total Income</i></td>
                <td className={totalIncomePercentage !== undefined && totalIncomePercentage < 0 && classes.redText}>
                  <i>
                    {totalIncomePercentage !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage } prefix={ totalIncomePercentage >= 0 ? '' : '(' } suffix={ totalIncomePercentage < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </i>
                </td>
                <td className={totalIncomePercentage !== undefined && totalIncomePercentage < 0 && classes.redText}>
                  <i>
                    {totalIncomePercentage !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ totalIncomePercentage } prefix={ totalIncomePercentage >= 0 ? '' : '(' } suffix={ totalIncomePercentage < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </i>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classes.blueBottomBorder }>
                <th />
                <th>
                  Total Income in Year{' '}
                  {inputData.year_of_purchase}
                </th>
                <th className={moduleServices.module13Data.totalIncomeYear !== undefined && moduleServices.module13Data.totalIncomeYear < 0 && classes.redText}>
                  {moduleServices.module13Data.totalIncomeYear !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalIncomeYear } prefix={ moduleServices.module13Data.totalIncomeYear >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalIncomeYear < 0 && ')' } />
                  ) : ( '$0' )}
                </th>
                <th className={moduleServices.module13Data.totalIncomeYear !== undefined && moduleServices.module13Data.totalIncomeYear < 0 && classes.redText}>
                  {moduleServices.module13Data.totalIncomeYear !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module13Data.totalIncomeYear * 12 } prefix={ moduleServices.module13Data.totalIncomeYear >= 0 ? '$' : '($' } suffix={ moduleServices.module13Data.totalIncomeYear < 0 && ')' } />
                  ) : ( '$0' )}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div />
      <ReactTooltip id="definationTitleBottom" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

HomeBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
  activeStep: PropTypes.string.isRequired,
  inputData: PropTypes.object.isRequired,
  summaryYear: PropTypes.string.isRequired, 
  setSummaryYear:PropTypes.func.isRequired

};

export default withStyles( styles )( HomeBottom );
