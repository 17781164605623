import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';
import Link from '@material-ui/core/Link';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from './card-style';
import MonicaPopup1 from '../../../assets/img/career-sketch/carrer_sketch_profile_img1_monica.png';
// import Graph from '../../../assets/img/career-sketch/graph.png';
import LogoPopup from '../../../assets/img/career-sketch/logo.png';
import CircleOne from '../../../assets/img/career-sketch/s1.png';
import CircleTwo from '../../../assets/img/career-sketch/s2.png';
import CircleThree from '../../../assets/img/career-sketch/s3.png';
import CircleFour from '../../../assets/img/career-sketch/s4.png';
import CircleFive from '../../../assets/img/career-sketch/s5.png';
import ArrowPop from '../../../assets/img/career-sketch/arrow.png';
import one from '../../../assets/img/career-sketch/1.png';
import Two from '../../../assets/img/career-sketch/2.png';
import Three from '../../../assets/img/career-sketch/3.png';
import Four from '../../../assets/img/career-sketch/4.png';
import Five from '../../../assets/img/career-sketch/5.png';
import infoIcon from '../../../assets/img/info-icon.svg';
import * as NetworthServices from '../../../calculations/net-worth-service';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
const MonicaCard = ( props ) => {
  const { classes, history } = props;
  const [cardType, setCardType] = React.useState( 'card' );

  const closeCard = ( ) => {
    props.closeCard( '' );
  };

  const openAllSteepingStones = ( ) => {
    props.openAllSteepingStones( true );
  };

  const handleAllPopupClose = () => {
    props.closeCard( '' );
  };

  const redirectToFullStory = () => {
    history.push( '/career-sketch-story' );
  };

  return (
    <div>
      {cardType === 'card' ? (
        <div>
          <Button className={ classes.closeBtn } onClick={ closeCard }><i className="la la-close" /></Button>
          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ MonicaPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupMiddle }>
              <div className={ classes.careerMidTop }>
                <h3>Monica, Executive Chef</h3>
                <p>
                  Monica attends a one-year culinary program and focuses on becoming an Executive Chef. She keeps expenses down by living at home for a time, invests early, gets married and starts a family.
                </p>
              </div>
              <div className={ classes.careerMidGraph }>
                <h3>
                  15-Year Net Worth: $474,647
                  <span className={ classes.infoIconNew }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIcon"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Monica gets married, this figure includes her partner's income/expenses."
                    />
                    <ReactTooltip id="infoIcon" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />

                  </span>
                </h3>
                {/* <img src={ Graph } alt="" /> */}
                <HighchartsReact
                  highcharts={ Highcharts }
                  options={ NetworthServices.graphCommonObject }
                />
              </div>
              <div className={ classes.careerMidBottomFooter }>
                <img src={ LogoPopup } alt="logo" />
                <Link To="/" className={ classes.inkiryLink }>www.inkwiry.com</Link>
              </div>
            </div>
            <div className={ classes.careerPopupRight }>
              <h3>Monica&apos;s Life:</h3>
              <p>As of 2033 (32 years old)</p>
              <ul>
                <li>Cash Savings</li>
                <li>Investments  </li>
                <li>Education</li>
                <li>Work Experience</li>
                <li>A Career</li>
                <li>A Family</li>
                <li>A Cat</li>
                <li>A Home</li>
                <li>A Minivan</li>
                <li>Annual Vacations</li>
                <li>A Great Future</li>
              </ul>
              <div className={ classes.careerPopupRightBottom }>
                <h3>Monica&apos;s KPIs:</h3>
                <ul>
                  <li>Debt-to-Income: 14.6%</li>
                  <li>Current Ratio: 1.5x  </li>
                  <li>Savings Rate: 20.9%</li>
                </ul>
                <div className={ classes.btnGroupPopup }>
                  <Button className={ classes.viewCareerBtn } onClick={ () => { setCardType( 'steeping' ); } }>
                    VIEW CAREER STEPPING STONES
                    <i className="la la-greater-than" />
                  </Button>
                  <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                    READ HER FULL STORY
                    <i className="la la-greater-than" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Button className={ classes.backCard } onClick={ () => { setCardType( 'card' ); } }><i className="las la-long-arrow-alt-left" /></Button>
          <Button className={ `${classes.backCard} ${classes.backcardClose}` } onClick={ handleAllPopupClose }><i className="las la-times" /></Button>

          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ MonicaPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupRightTwo }>
              <div>
                <div className={ classes.careerMidTop }>
                  <h3>Monica&apos;s Stepping Stones to Executive Chef</h3>
                  <div className={ classes.circlePopup }>
                    <div className={ classes.popGroup }>
                      <div className={ classes.circle }>
                        <img src={ CircleOne } alt="" />
                        <font className={ classes.circleTittle }>
                          HIGHER
                          <br />
                          EDUCATION
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleTwo } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          PATH
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleThree } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleFour } alt="" />
                        <font className={ classes.circleTittle }>
                          ADD&apos;L CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleFive } alt="" />
                        <font className={ classes.circleTittle }>
                          FURTHER CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                    </div>
                    <div className={ classes.steppingStonesCard }>
                      <div className={ classes.steppingStoneOne }>
                        <h4>One-Year Specialized</h4>
                        <p>
                          Culinary Program
                          <br />
                          The Int&apos;l Culinary Center
                          <br />
                          {' '}
                          Tuition: $35,900
                          <br />
                          <span
                            data-for="spanTool"
                            data-tip="The total amount to attend school, which includes tuition, fees, room and board, and other living expenses."
                          >
                            Total CoA
                          </span>
                          : $44,900
                          <ReactTooltip id="spanTool" place="left" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.tooltipInfoBorderLeft}` } />
                          <br />
                          {' '}
                          Student Loans: $20,000
                          <br />
                          {' '}
                          1 year
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ one } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneTwo }>
                        <h4>Line Cook</h4>
                        <p>
                          San Francisco, CA
                          <br />
                          {' '}
                          Income: $37,000
                          <br />
                          Supp. Income: $10,000
                          <br />
                          {' '}
                          Living Exp: $1,225/mo
                          <br />
                          {' '}
                          5 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Two } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneThree }>
                        <h4>Junior Sous Chef</h4>
                        <p>
                          San Francisco, CA
                          <br />
                          {' '}
                          Income: $48,500
                          <br />
                          Supp. Income: $6,500
                          <br />
                          {' '}
                          Living Exp: $3,195/mo
                          <br />
                          {' '}
                          5 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Three } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFour }>
                        <h4>Sous Chef</h4>
                        <p>
                          San Francisco, CA
                          <br />
                          {' '}
                          Income: $75,000
                          <br />
                          Living Exp: $4,300/mo
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Four } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFive }>
                        <h4>Future Executive Chef</h4>
                        <div className={ classes.popupNumber }>
                          <img src={ Five } alt="" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className={ classes.careerMidBottomFooter }>
                  <img src={ LogoPopup } alt="logo" />
                  <p>See and build your best tomorrows now</p>
                </div>
              </div>
              <div className={ classes.btnGroupPopupNew }>
                <Button className={ classes.viewCareerBtn } onClick={ openAllSteepingStones }>
                  VIEW STEPPING STONES FOR ALL SKETCHES
                  <i className="la la-greater-than" />
                </Button>
                <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                  READ HER FULL STORY
                  <i className="la la-greater-than" />
                </Button>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MonicaCard.propTypes = {
  classes: PropTypes.object.isRequired,
  closeCard: PropTypes.func.isRequired,
  openAllSteepingStones: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( MonicaCard );
