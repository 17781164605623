import { FV } from './commonFunctions';

let value = 75000000;
const lumpSum = [75000000];
const monthspayment = [0];
let monthsVal = 0;

const months = [0];

let inflation1Val = 1000;
let inflation2Val = 1000;
let inflation4Val = 1000;
let inflation6Val = 1000;
let inflation8Val = 1000;
let inflation10Val = 1000;

const inflation1 = [1000];
const inflation2 = [1000];
const inflation4 = [1000];
const inflation6 = [1000];
const inflation8 = [1000];
const inflation10 = [1000];
const inflationYears = [0];

for ( let i = 1; i <= 360; i += 1 ) {
  if ( i <= 30 ) {
    inflationYears.push( i );

    inflation1Val += ( ( inflation1Val / 100 ) * 1 );
    inflation1.push( parseInt( inflation1Val, 10 ) );

    inflation2Val += ( ( inflation2Val / 100 ) * 2 );
    inflation2.push( parseInt( inflation2Val, 10 ) );

    inflation4Val += ( ( inflation4Val / 100 ) * 4 );
    inflation4.push( parseInt( inflation4Val, 10 ) );

    inflation6Val += ( ( inflation6Val / 100 ) * 6 );
    inflation6.push( parseInt( inflation6Val, 10 ) );

    inflation8Val += ( ( inflation8Val / 100 ) * 8 );
    inflation8.push( parseInt( inflation8Val, 10 ) );

    inflation10Val += ( ( inflation10Val / 100 ) * 10 );
    inflation10.push( parseInt( inflation10Val, 10 ) );
  }

  value = FV( 0.0041666666666667, i, 0, -( 75000000 ), 1 );
  const roundVal = parseInt( value, 10 );
  lumpSum.push( roundVal );
  months.push( i );
  let inserVal = 0;
  if ( i > 200 ) {
    monthsVal = FV( 0.0041666666666667, 1, 0, -( monthsVal ), 1 );
    inserVal = parseInt( monthsVal, 10 );
  } else {
    monthsVal = FV( 0.0041666666666667, i, -500000, 0, 1 );
    inserVal = parseInt( monthsVal, 10 );
  }
  monthspayment.push( inserVal );
}

const data = {
  months,
  monthspayment,
  inflation1,
  inflation2,
  inflation4,
  inflation6,
  inflation8,
  inflation10,
  lump_sum: lumpSum,
  inflation_years: inflationYears,
};

export default data;
