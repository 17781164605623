import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-fca/styles';
import carrerPicture from '../../../assets/img/career-path/picture1_1_careerpath.png';
import carrerPictureNew from '../../../assets/img/career-path/picture2_1_careerpath.png';

const ChoosePath = ( props ) => {
  const {
    classes, handleDoubleClickRole, handleCareerObject,handleUpdatedObject, setRole, role,
  } = props;

  useEffect(()=>{
    const updatedObj = { ...handleCareerObject };
    if(handleCareerObject.start_year !== undefined){
    if(handleCareerObject.start_year !== new Date().getFullYear()){
      setRole('futureMove');
      updatedObj.role = 'futureMove';
      handleUpdatedObject( updatedObj );
    }
    }
    // eslint-disable-next-line
  },[]);

  const handleRole = ( type ) => {
    const updatedObj = { ...handleCareerObject };
    setRole( type );
    updatedObj.role = type;
    handleUpdatedObject( updatedObj );
  };

  return (
    <div className={ classes.contentBlock }>
      <div>
          <p>The best planners are always thinking two or even three steps ahead. When mapping out your career, it&apos;s crucial to look beyond your next career move. Where will you be in 5, 10, or 15 years? What kind of life do you want to have? Will you be working at a desk, somewhere out in the field, or starting your own business? Develop a vision to your future and Plan With Inkwiry to develop your roadmap to your goals.</p>
          <p>
            Making your next career move starts with running the numbers and developing a good plan. The
            {' '}
            {' '}
            {' '}
            {' '}
            <font>Further Career Advancement</font>
            {' '}
            {' '}
            module will illuminate your path into the future.
          </p>
        </div>
      <div className={ `${`${classes.fourIconsBlock} ${classes.fourIconsBlockNewThree}`}` }>
        <ul>
          <li className={ ( handleCareerObject.role === 'currentRole' || role === 'currentRole' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'currentRole' ) } onDoubleClick={ () => handleDoubleClickRole( 'currentRole' ) }>
            <figure>
              <img src={ carrerPicture } alt="" />
            </figure>
            <span className={ classes.colorBlue }>Current Role</span>
          </li>
          <li className={ ( handleCareerObject.role === 'futureMove' || role === 'futureMove' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'futureMove' ) } onDoubleClick={ () => handleDoubleClickRole( 'futureMove' ) }>
            <img src={ carrerPictureNew } alt="" />
            <span>Future Career Move</span>
          </li>

        </ul>
      </div>
    </div>

  );
};

ChoosePath.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDoubleClickRole: PropTypes.func.isRequired,
  setRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,

};

export default withStyles( styles )( ChoosePath );
