import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter, Link } from 'react-router-dom';
import styles from './styles';
import PwiSave from '../../components/pwi-save/pwi-save';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/investments/investments-slide1.jpg';
import sliderImageThree from '../../assets/img/investments/investments-slide2.jpg';
import sliderImageFour from '../../assets/img/investments/investments-slide4.jpg';
import sliderImageFive from '../../assets/img/investments/investments-slide5.jpg';
import sliderImageSix from '../../assets/img/investments/investments-slide6.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import * as moduleServices from '../../calculations/modules-services';
import CashFlowMovement1 from '../../assets/img/investments/Cash_Flow_Movement_1.png';
import CashFlowMovement2 from '../../assets/img/investments/Cash_Flow_Movement_2.png';
import CompoundInterest from '../../assets/img/investments/Compound_Interest.png';
import CompoundingInterest from '../../assets/img/investments/Compounding_Interest.png';
import Ruleof72 from '../../assets/img/investments/Rule_of_72.png';
import TypesOfLongTermInvestments from '../../assets/img/investments/Types_Of_Long_Term_Investments.png';
import TypesOfShortTermInvestments from '../../assets/img/investments/Types_of_Short_Term_Investments.png';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import InvestmentWorksheetTable from '../../components/documents/worksheet-tables/investments-worksheet-table';
import { defaultValues } from '../../calculations/default-module-values';
import alertIcon from '../../assets/img/alert_icon.png';
import * as investmentsGraphData from '../../graph-services/investments-show-me';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

const InvestmentsWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedInvestmentValues, setUpdatedInvestmentValues] = useState(defaultValues.module9);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imagePopup, setImagePopup] = useState(false);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = React.useState('');
  const [openInputPopupStatus, setOpenInputPopupStatus] = React.useState({ short_term_annual_expected_return_in_percentage: false, long_term_annual_expected_return_in_percentage: false });
  const totalFinanceGiftYear = (updatedInvestmentValues.financial_gift_start_year !== 0 && updatedInvestmentValues.financial_gift_end_year !== 0 ? updatedInvestmentValues.financial_gift_end_year - updatedInvestmentValues.financial_gift_start_year + 1 : 0);
  const totalCharitableYear = (updatedInvestmentValues.charitable_start_year !== 0 && updatedInvestmentValues.charitable_end_year !== 0 ? updatedInvestmentValues.charitable_end_year - updatedInvestmentValues.charitable_start_year + 1 : 0);
  const [shortTermInitialCashBalanceLabel, setShortTermInitialCashBalanceLabel] = React.useState('Initial Cash Balance');
  const [shortTermEndingCashBalanceLabel, setShortTermEndingCashBalanceLabel] = React.useState('Cash Balance');
  // const [shortTermSummaryLabel1, setShortTermSummaryLabel1] = React.useState('Total Annual Contributions');
  const [shortTermSummaryLabel2, setShortTermSummaryLabel2] = React.useState('Excess Cash Moved into Short-Term Investments');
  // const [shortTermSummaryLabel4, setShortTermSummaryLabel4] = React.useState('Total Earnings');
  const [shortTermSummaryLabel5, setShortTermSummaryLabel5] = React.useState('Total Withdrawals for Cash Need');
  const [shortTermSummaryLabel6, setShortTermSummaryLabel6] = React.useState('Excess Short-Term Investments Moved into Long-Term');
  const [longTermInitialCashBalanceLabel, setLongTermInitialCashBalanceLabel] = React.useState('Initial Cash Balance');
  const [longTermEndingCashBalanceLabel, setLongTermEndingCashBalanceLabel] = React.useState('Cash Balance');
  // const [longTermSummaryLabel1, setLongTermSummaryLabel1] = React.useState('Total Annual Contributions');
  const [longTermSummaryLabel2, setLongTermSummaryLabel2] = React.useState('Excess Short-Term Investments Moved into Long-Term Investments');
  // const [longTermSummaryLabel4, setLongTermSummaryLabel4] = React.useState('Total Earnings');
  const [longTermSummaryLabel5, setLongTermSummaryLabel5] = React.useState('Total Withdrawals for Cash Need');
  const [charitableTotalContributionLabel, setCharitableTotalContributionLabel] = React.useState('Total Contributions');
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [showMeData, setShowMeData] = React.useState({});
  const [openWorkSheet, setOpenWorkSheet] = React.useState('');
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);


  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');
  useEffect(() => {
    setWalkThruNo(7);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [yearRange, setYearRange] = React.useState([]);

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module9' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedInvestmentValues(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedInvestmentValues(defaultValues.module9);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(() => {
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module9' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }
  }, [loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module9') {
      dropdownvalues.savedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 });
    }
  }, [myNotes]);

  const shortTermStartYearSelection = () => {
    // Label
    // const startDate = new Date().getFullYear();
    const module1StartYear = moduleServices.module1Data.start_year > 0 ? moduleServices.module1Data.start_year : 0;
    if (typeof updatedInvestmentValues.short_term_start_year !== undefined && updatedInvestmentValues.short_term_start_year !== '' && module1StartYear === updatedInvestmentValues.short_term_start_year) {
      setShortTermInitialCashBalanceLabel(`Initial Cash Balance at the Beginning of Year ${updatedInvestmentValues.short_term_start_year}`);
      setShortTermEndingCashBalanceLabel(`Cash Balance at the End of Year ${updatedInvestmentValues.short_term_start_year}`);
    } else if (typeof updatedInvestmentValues.short_term_start_year !== undefined && updatedInvestmentValues.short_term_start_year !== '') {
      setShortTermInitialCashBalanceLabel(`Cash Balance at the End of Year ${updatedInvestmentValues.short_term_start_year - 1}`);
      setShortTermEndingCashBalanceLabel(`Cash Balance at the End of Year ${updatedInvestmentValues.short_term_start_year}`);
    } else {
      setShortTermInitialCashBalanceLabel('Initial Cash Balance');
      setShortTermEndingCashBalanceLabel('Cash Balance');
    }

    if (typeof updatedInvestmentValues.short_term_start_year !== undefined && updatedInvestmentValues.short_term_start_year !== ''
      && module1StartYear > 0) {
      const yearsDiff = (((module1StartYear) + 14 - ((updatedInvestmentValues.short_term_start_year)) + 1));
      // setShortTermSummaryLabel1(`Total Annual Contributions for ${yearsDiff} Years`);
      setShortTermSummaryLabel2(`Excess Cash Moved into Short-Term Investments for ${yearsDiff} Years`);
      // setShortTermSummaryLabel4(`Total Earnings for ${yearsDiff} Years @ ${parseFloat(updatedInvestmentValues.short_term_annual_expected_return_in_percentage).toFixed(2)}% Return`);
      setShortTermSummaryLabel5(`Total Withdrawals for Cash Need for ${yearsDiff} Years`);
      setShortTermSummaryLabel6(`Excess Short-Term Investments Moved into Long-Term Investments for ${yearsDiff} Years`);
    } else {
      // setShortTermSummaryLabel1('Total Annual Contributions');
      setShortTermSummaryLabel2('Excess Cash Moved into Short-Term Investments');
      // setShortTermSummaryLabel4('Total Earnings');
      setShortTermSummaryLabel5('Total Withdrawals for Cash Need');
      setShortTermSummaryLabel6('Excess Short-Term Investments Moved into Long-Term');
    }
  };

  const longTermStartYearSelection = () => {
    // Label
    const startDate = new Date().getFullYear();
    if (typeof updatedInvestmentValues.long_term_start_year !== undefined && updatedInvestmentValues.long_term_start_year !== '' && moduleServices.module1Data.start_year === updatedInvestmentValues.long_term_start_year) {
      setLongTermInitialCashBalanceLabel(`Initial Cash Balance at the Beginning of Year ${updatedInvestmentValues.long_term_start_year}`);
      setLongTermEndingCashBalanceLabel(`Cash Balance at the End of Year ${updatedInvestmentValues.long_term_start_year}`);
    } else if (typeof updatedInvestmentValues.long_term_start_year !== undefined && updatedInvestmentValues.long_term_start_year !== '') {
      const year = updatedInvestmentValues.long_term_start_year - 1;
      setLongTermInitialCashBalanceLabel(`Cash Balance at the End of Year ${year}`);
      setLongTermEndingCashBalanceLabel(`Cash Balance at the End of Year ${updatedInvestmentValues.long_term_start_year}`);
    } else {
      setLongTermInitialCashBalanceLabel('Initial Cash Balance');
      setLongTermEndingCashBalanceLabel('Cash Balance');
    }

    const module1StartYear = moduleServices.module1Data.start_year ? moduleServices.module1Data.start_year : startDate;
    if (typeof updatedInvestmentValues.long_term_start_year !== undefined && updatedInvestmentValues.long_term_start_year !== ''
      && module1StartYear > 0) {
      const yearsDiff = ((module1StartYear + 14 - (updatedInvestmentValues.long_term_start_year)) + 1);
      // setLongTermSummaryLabel1(`Total Annual Contributions for ${yearsDiff} Years`);
      setLongTermSummaryLabel2(`Excess Short-Term Investments Moved into Long-Term Investments for ${yearsDiff} Years`);
      // setLongTermSummaryLabel4(`Total Earnings for ${yearsDiff} Years @ ${parseFloat(updatedInvestmentValues.long_term_annual_expected_return_in_percentage).toFixed(2)}% Return`);
      setLongTermSummaryLabel5(`Total Withdrawals for Cash Need for ${yearsDiff} Years`);
    } else {
      // setLongTermSummaryLabel1('Total Annual Contributions');
      setLongTermSummaryLabel2('Excess Short-Term Investments Moved into Long-Term Investments');
      // setLongTermSummaryLabel4('Total Earnings');
      setLongTermSummaryLabel5('Total Withdrawals for Cash Need');
    }
  };

  // Charitable Total Contribution
  const charitableTotalContribution = () => {
    if (typeof updatedInvestmentValues.charitable_start_year !== undefined && updatedInvestmentValues.charitable_start_year !== ''
      && typeof updatedInvestmentValues.charitable_end_year !== undefined && updatedInvestmentValues.charitable_end_year !== ''
      && updatedInvestmentValues.charitable_end_year > updatedInvestmentValues.charitable_start_year) {
      const yearsDiff = (updatedInvestmentValues.charitable_end_year - updatedInvestmentValues.charitable_start_year) + 1;
      setCharitableTotalContributionLabel(`Total Contributions After ${yearsDiff} Years`);
    } else {
      setCharitableTotalContributionLabel('Total Contributions');
    }

    return moduleServices.charitableTotalContribution();
  };

  useEffect(() => {
    shortTermStartYearSelection();
    longTermStartYearSelection();
    charitableTotalContribution();
  });

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(investmentsGraphData.investmentsShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top' });
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleImagePopup = (url) => {
    setImagePopup(!imagePopup);
    setImageURL(url);
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
    if (field === 'short_term_annual_contribution_growth_in_percentage' || field === 'short_term_annual_expected_return_in_percentage' || field === 'long_term_annual_expected_return_in_percentage') {
      let text = '';
      const popmin = 0;
      const popmax = 15;
      if (openInputPopupStatus[field] === false) {
        const inputPopupStatus = { ...openInputPopupStatus };
        inputPopupStatus[field] = true;
        setOpenInputPopupStatus(inputPopupStatus);
        text = 'Investments typically return somewhere between 0 - 15%. Make sure you can support an annual expected pre-tax return outside these boundaries with research.';
      }
      const tempValue = updatedInvestmentValues[field];
      if (tempValue > 0 && text !== '' && (tempValue < popmin || tempValue > popmax)) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.redoPopup}>
              <div className={classes.redoPopupNew}>
                <h3>Important Tip</h3>
                <div className={classes.NoFiDiv}>
                  <p className={classes.zeroLeftPadding}>{text}</p>
                </div>
              </div>
              <div className={classes.buttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button className={classes.hideBtn} type="button" onClick={onClose}>OK</button>
              </div>
            </div>
          ),
        });
      }
    }
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedInvestmentValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if (valuesUpdated[field] > 100 && (field === 'charitable_yearly_contribution' || field === 'long_term_annual_expected_return_in_percentage'
        || field === 'long_term_annual_contribution_in_percentage_growth' || field === 'short_term_annual_contribution_growth_in_percentage'
        || field === 'short_term_annual_expected_return_in_percentage' || field === 'plan_529_annual_contribution_percentage' || field === 'plan_529_annual_expected_pre_tax_return' || field === 'monthly_premium_percentage' || field === 'annual_expected_pre_tax_return_life_ins')) {
        valuesUpdated[field] = 100;
      }
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.floatValue;
    } else if (fieldType === 'select') {
      if (field === 'financial_gift_end_year') {
        if (updatedInvestmentValues.financial_gift_start_year <= e.value) {
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = updatedInvestmentValues.financial_gift_start_year;
        }
      } else if (field === 'financial_gift_start_year') {
        if (updatedInvestmentValues.financial_gift_end_year >= e.value) {
          valuesUpdated.financial_gift_end_year = updatedInvestmentValues.financial_gift_end_year;
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = e.value;
          valuesUpdated.financial_gift_end_year = updatedInvestmentValues.financial_gift_end_year !== 0 ? e.value : 0;
        }
      } else if (field === 'charitable_end_year') {
        if (updatedInvestmentValues.charitable_start_year <= e.value) {
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = updatedInvestmentValues.charitable_start_year;
        }
      } else if (field === 'charitable_start_year') {
        if (updatedInvestmentValues.charitable_end_year >= e.value) {
          valuesUpdated.charitable_end_year = updatedInvestmentValues.charitable_end_year;
          valuesUpdated[field] = e.value;
        } else {
          valuesUpdated[field] = e.value;
          valuesUpdated.charitable_end_year = updatedInvestmentValues.charitable_end_year !== 0 ? e.value : 0;
        }
      } else {
        valuesUpdated[field] = e.value;
      }
    }
    setUpdatedInvestmentValues(valuesUpdated);
    moduleServices.setModule9Data(valuesUpdated);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module9';
    objectParams.moduleNumber = 'module9Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module9";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module9', 'quick-save', activeScenarioData);
      finalObject['module9Data'] = pwiObject.getfilteredInvestObject(moduleServices.module9Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -130, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 450, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''}>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.investmentSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your investments details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonOrange)}> START WITH A WALK-THRU </span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                My Short-Term Investments
                              </Typography>
                              <ul>
                                <li>Invest early to build more equity over your lifetime</li>
                                <li>Estimate how much money you can contribute each year, and how your contribution might grow</li>
                                <li>Enter how much money you want to accumulate before investing long-term.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                My Long-Term Investments
                              </Typography>
                              <ul>
                                <li>Enter how much you might contribute annually to long-term investments</li>
                                <li>By what percentage could your contributions grow over time?</li>
                                <li>Compare outcomes and identify your own best plan for building financial resources.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                My One-Time Gift
                              </Typography>
                              <ul>
                                <li>Do you anticipate receiving a one-time gift, such as an inheritance?</li>
                                <li>Inkwiry allows you to factor any potential gifts into your financial plan</li>
                                <li>See how putting your gift to work might improve your financial position.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageFour} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Raise Your Awareness
                              </Typography>
                              <ul>
                                <li>Learn about different type of investments</li>
                                <li>Understand the inherent risks vs. rewards of investing</li>
                                <li>Check out QI to test your awareness about managing your money</li>
                                <li>Know the meaning of essential financial terms.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageFive} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Investments
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Check Your Spaces
                              </Typography>
                              <ul>
                                <li>How do your investment goals affect different areas of your life?</li>
                                <li>Where is your comfort zone concerning risk?</li>
                                <li>How might investing benefit your situation over the long term?</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageSix} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

              </Slider>
            </div>
          </div>
        </div>

        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module9" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/investments" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.investmentsDetails) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    {console.log('event', updatedInvestmentValues)}
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Short-Term Investments</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Start Year</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'short_term_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.short_term_start_year, value: updatedInvestmentValues.short_term_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year that you want to start contributing cash to your Short-Term Investments. If you already have Short-Term Investments, then make this year the Start Year. If this is anything other than this year, make your Beginning Balance $0.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Beginning Short-Term Investments Balance
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermBeginningBalance} prefix={moduleServices.module9Data.shortTermBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            After-Tax Income in Year
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.afterTaxIncomeInYearShortTerm !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.afterTaxIncomeInYearShortTerm} prefix={moduleServices.module9Data.afterTaxIncomeInYearShortTerm >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.afterTaxIncomeInYearShortTerm < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.leftGrayBox, classes.bigInputBox)}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Investment Contribution as a % of After-Tax Income</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.short_term_annual_contribution_growth_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'short_term_annual_contribution_growth_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='short_term_annual_contribution_growth_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='short_term_annual_contribution_growth_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('short_term_annual_contribution_growth_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('short_term_annual_contribution_growth_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="This amount tells the FC that once you have a certain amount of Short-Term Investments, to move the Excess amount into Long-Term Investments. See the Boost My Awareness section for a more detailed explanation.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Contribution in Year
                                            {' '}
                                            <span>{moduleServices.module9Data.short_term_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermAnnualContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermAnnualContribution} prefix={moduleServices.module9Data.shortTermAnnualContribution >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermAnnualContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Net Cash Flow for Savings and Investments in Year
                                            {' '}
                                            {updatedInvestmentValues.short_term_start_year}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortAnnualNetIncomeInYear !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortAnnualNetIncomeInYear} prefix={moduleServices.module9Data.shortAnnualNetIncomeInYear >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortAnnualNetIncomeInYear < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Expected Pre-Tax Return</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.short_term_annual_expected_return_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'short_term_annual_expected_return_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='short_term_annual_expected_return_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='short_term_annual_expected_return_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('short_term_annual_expected_return_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('short_term_annual_expected_return_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The percentage return you expect to receive each year from your short-term investments. A typical range would be between 2-4%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>{shortTermInitialCashBalanceLabel}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.initialCashBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.initialCashBalance} prefix={moduleServices.module9Data.initialCashBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.initialCashBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>{shortTermEndingCashBalanceLabel}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.currentYearCashBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.currentYearCashBalance} prefix={moduleServices.module9Data.currentYearCashBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.currentYearCashBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                      <h3>Short-Term Investments Summary </h3>
                                      <div><Button onClick={() => { setOpenWorkSheet('short') }} className={classes.showWorksheet}>SHOW WORKSHEET</Button></div>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Short-Term Investments Period</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.inputRight, classes.investText)}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.shortTermInvestmentsPeriod() !== undefined && updatedInvestmentValues.short_term_start_year > 0 ? moduleServices.shortTermInvestmentsPeriod() : 0}

                                            &nbsp;&nbsp;
                                          </span>
                                          <span className={classes.noInputTextNew}>
                                            {moduleServices.shortTermInvestmentsPeriodYears() !== undefined ? moduleServices.shortTermInvestmentsPeriodYears() : 0}
                                            {' '}
                                            Years
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Beginning Short-Term Investments Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryBeginningBalance} prefix={moduleServices.module9Data.shortTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryContributions} prefix={moduleServices.module9Data.shortTermSummaryContributions >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Earnings</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryEarnings} prefix={moduleServices.module9Data.shortTermSummaryEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="To see how earnings are calculated each year, click on the 'Show Table' button above to see each year in detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Short-Term Investments Balance before Transfers and Withdrawals</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermInvestmentsBeforeTransfers !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermInvestmentsBeforeTransfers} prefix={moduleServices.module9Data.shortTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermInvestmentsBeforeTransfers < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel2}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryExcessCash !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryExcessCash} prefix={moduleServices.module9Data.shortTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryExcessCash < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Once your cash balance is greater than the max cash balance that you inputted in the Financial Independence module, the FC will automatically move the excess cash to short-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of excess cash.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel5}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryCashWithDrawn !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryCashWithDrawn} prefix={moduleServices.module9Data.shortTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryCashWithDrawn < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="If your cash balance goes negative in any year, the FC will automatically withdraw cash from your short-term investments at the end of the year to try and bring you back to cash flow positive.
                                          Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of cash need. Click on the 'Show Table' button above to see each year in detail."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>{shortTermSummaryLabel6}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.shortTermSummaryCashDeposited !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryCashDeposited} prefix={moduleServices.module9Data.shortTermSummaryCashDeposited >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryCashDeposited < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Once your short-term investments are greater than the max short-term investments balance that you inputted above, the FC will automatically move the excess amount to long-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Ending Short-Term Investments Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module9Data.shortTermSummaryEndingBalance !== undefined && moduleServices.module9Data.shortTermSummaryEndingBalance <= 0) || (moduleServices.module9Data.shortTermSummaryEndingBalance > 0) ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.shortTermSummaryEndingBalance} prefix={moduleServices.module9Data.shortTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.shortTermSummaryEndingBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Long-Term Investments</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Start Year</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'long_term_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.long_term_start_year, value: updatedInvestmentValues.long_term_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year that you want to start contributing cash to your Long-Term Investments. If you already have Long-Term Investments, then make this year the Start Year. If this is anything other than this year, make your Beginning Balance $0.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Beginning Long-Term Investments Balance
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermBeginningBalance} prefix={moduleServices.module9Data.longTermBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The money you currently have in your Short-Term Investments. If you don't have any money in Short-Term Investments, you can take a portion of your Current Cash Balance and use that amount here - don't forget to lower your Current Cash Balance after.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            After-Tax Income in Year
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.afterTaxIncomeInYearLongTerm !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.afterTaxIncomeInYearLongTerm} prefix={moduleServices.module9Data.afterTaxIncomeInYearLongTerm >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.afterTaxIncomeInYearLongTerm < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Contribution Growth</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.long_term_annual_contribution_in_percentage_growth}
                                            onValueChange={(e) => updateValue(e, 'long_term_annual_contribution_in_percentage_growth', 'number')}
                                            decimalScale = { isFocus!=='long_term_annual_contribution_in_percentage_growth'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='long_term_annual_contribution_in_percentage_growth'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('long_term_annual_contribution_in_percentage_growth'); }}
                                            onBlur={() => { setFixedDecimal('long_term_annual_contribution_in_percentage_growth'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect to grow your Annual Contribution. For example, if you are contributing $1,000 this year and expect to contribute $1,100 next year, you would be growing your Annual Contribution by 10%. See how changing this affects your Net Worth."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Contribution in Year
                                            {' '}
                                            <span>{moduleServices.module9Data.long_term_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermAnnualContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermAnnualContribution} prefix={moduleServices.module9Data.longTermAnnualContribution >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermAnnualContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Net Cash Flow for Savings and Investments in Year
                                            {' '}
                                            <span>{moduleServices.module9Data.long_term_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longAnnualNetIncomeInYear !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longAnnualNetIncomeInYear} prefix={moduleServices.module9Data.longAnnualNetIncomeInYear >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longAnnualNetIncomeInYear < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Expected Pre-Tax Return</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.long_term_annual_expected_return_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'long_term_annual_expected_return_in_percentage', 'number')}
                                            decimalScale = { isFocus!=='long_term_annual_expected_return_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='long_term_annual_expected_return_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('long_term_annual_expected_return_in_percentage'); }}
                                            onBlur={() => { setFixedDecimal('long_term_annual_expected_return_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The percentage return you expect to receive each year from your Long-Term Investments. A typical range would be between 4-8%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>{longTermInitialCashBalanceLabel}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermInitialCashBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermInitialCashBalance} prefix={moduleServices.module9Data.longTermInitialCashBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermInitialCashBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>{longTermEndingCashBalanceLabel}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermCashBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermCashBalance} prefix={moduleServices.module9Data.longTermCashBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermCashBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                      <h3>Long-Term Investments Summary</h3>
                                      <div><Button onClick={() => { setOpenWorkSheet('long') }} className={classes.showWorksheet}>SHOW WORKSHEET</Button></div>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Long-Term Investments Period</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.inputRight, classes.investText)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermInvestmentsPeriod !== undefined && updatedInvestmentValues.long_term_start_year > 0 ? moduleServices.module9Data.longTermInvestmentsPeriod : 0}

                                            &nbsp;&nbsp;
                                          </span>
                                          <span className={classes.noInputTextNew}>
                                            {moduleServices.module9Data.longTermInvestmentsPeriodYears !== undefined ? moduleServices.module9Data.longTermInvestmentsPeriodYears : 0}
                                            {' '}
                                            Years
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Beginning Long-Term Investments Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermSummaryBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryBeginningBalance} prefix={moduleServices.module9Data.longTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermSummaryContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryContributions} prefix={moduleServices.module9Data.longTermSummaryContributions >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>Total Earnings</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermSummaryEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryEarnings} prefix={moduleServices.module9Data.longTermSummaryEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="To see how earnings are calculated each year, click on the 'Show Table' button above to see each year in detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Long-Term Investments Balance before Transfers and Withdrawals</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermInvestmentsBeforeTransfers !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermInvestmentsBeforeTransfers} prefix={moduleServices.module9Data.longTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermInvestmentsBeforeTransfers < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel2}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermSummaryExcessCash !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryExcessCash} prefix={moduleServices.module9Data.longTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryExcessCash < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Once your short-term investments are greater than the max short-term investments balance that you inputted above, the FC will automatically move the excess amount to long-term investments at the end of the year. Click on the 'Show Table' button above to see each year in detail.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftPadding)}>{longTermSummaryLabel5}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.longTermSummaryCashWithDrawn !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryCashWithDrawn} prefix={moduleServices.module9Data.longTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryCashWithDrawn < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="If your cash balance goes negative in any year, the FC will automatically withdraw cash from your
                                          long-term investments at the end of the year to try and bring you back to cash flow positive. Click on the 'Show Table' button above to see each year in detail. And, go to the Boost My Awareness for a definition of cash need. Click on the 'Show Table' button above to see each year in detail."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Ending Long-Term Investments Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module9Data.longTermSummaryEndingBalance !== undefined && moduleServices.module9Data.longTermSummaryEndingBalance <= 0) || (moduleServices.module9Data.longTermSummaryEndingBalance > 0) ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.longTermSummaryEndingBalance} prefix={moduleServices.module9Data.longTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.longTermSummaryEndingBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>529 Plan</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Start Year</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'plan_529_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.plan_529_start_year, value: updatedInvestmentValues.plan_529_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year that you want to start contributing cash to your Long-Term Investments. If you already have Long-Term Investments, then make this year the Start Year. If this is anything other than this year, make your Beginning Balance $0.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Beginning 529 Balance
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.beginning529Balance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.beginning529Balance} prefix={moduleServices.module9Data.beginning529Balance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.beginning529Balance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The money you currently have in your Short-Term Investments. If you don't have any money in Short-Term Investments, you can take a portion of your Current Cash Balance and use that amount here - don't forget to lower your Current Cash Balance after.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            After-Tax Income in Year
                                            <span>{moduleServices.module9Data.plan_529_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.afterTaxIncomeInYear529Balance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.afterTaxIncomeInYear529Balance} prefix={moduleServices.module9Data.afterTaxIncomeInYear529Balance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.afterTaxIncomeInYear529Balance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Investment Contribution as a % of After-Tax Income</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.plan_529_annual_contribution_percentage}
                                            onValueChange={(e) => updateValue(e, 'plan_529_annual_contribution_percentage', 'number')}
                                            decimalScale = { isFocus!=='plan_529_annual_contribution_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='plan_529_annual_contribution_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('plan_529_annual_contribution_percentage'); }}
                                            onBlur={() => { setFixedDecimal('plan_529_annual_contribution_percentage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect to grow your Annual Contribution. For example, if you are contributing $1,000 this year and expect to contribute $1,100 next year, you would be growing your Annual Contribution by 10%. See how changing this affects your Net Worth."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Contribution in Year
                                            {' '}
                                            <span>{moduleServices.module9Data.plan_529_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.annualContributionInYear529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.annualContributionInYear529} prefix={moduleServices.module9Data.annualContributionInYear529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.annualContributionInYear529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Net Cash Flow for Savings and Investments in Year
                                            {' '}
                                            <span>{moduleServices.module9Data.plan_529_start_year}</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.annualNetCashFlowDorSavingsAndInvestments529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.annualNetCashFlowDorSavingsAndInvestments529} prefix={moduleServices.module9Data.annualNetCashFlowDorSavingsAndInvestments529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.annualNetCashFlowDorSavingsAndInvestments529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Expected Pre-Tax Return</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.plan_529_annual_expected_pre_tax_return}
                                            onValueChange={(e) => updateValue(e, 'plan_529_annual_expected_pre_tax_return', 'number')}
                                            decimalScale = { isFocus!=='plan_529_annual_expected_pre_tax_return'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='plan_529_annual_expected_pre_tax_return'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('plan_529_annual_expected_pre_tax_return'); }}
                                            onBlur={() => { setFixedDecimal('plan_529_annual_expected_pre_tax_return'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The percentage return you expect to receive each year from your Long-Term Investments. A typical range would be between 4-8%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Cash Balance at the End of Year
                                            <span>{moduleServices.module9Data.plan_529_start_year - 1}</span></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.cashBalanceAtTheEndOfPreviourYear529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.cashBalanceAtTheEndOfPreviourYear529} prefix={moduleServices.module9Data.cashBalanceAtTheEndOfPreviourYear529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.cashBalanceAtTheEndOfPreviourYear529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Cash Balance at the End of Year
                                            <span>{moduleServices.module9Data.plan_529_start_year}</span></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.cashBalanceAtTheEndOfCurrentYear529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.cashBalanceAtTheEndOfCurrentYear529} prefix={moduleServices.module9Data.cashBalanceAtTheEndOfCurrentYear529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.cashBalanceAtTheEndOfCurrentYear529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                      <h3>529 Plan Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>529 Investments Period</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.inputRight, classes.investText)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.longTermInvestmentsPeriod529() !== undefined && moduleServices.module9Data.plan_529_start_year > 0 ? moduleServices.longTermInvestmentsPeriod529() : 0}
                                            &nbsp;&nbsp;
                                          </span>
                                          <span className={classes.noInputTextNew}>
                                            {moduleServices.longTermInvestmentsPeriodYears529() !== undefined && moduleServices.module9Data.plan_529_start_year > 0 ? moduleServices.longTermInvestmentsPeriodYears529() : 0}
                                            {' '}
                                            Years
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Beginning 529 Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module9Data.beginning529BalanceAnalysis !== undefined && moduleServices.module9Data.beginning529BalanceAnalysis >= 0) || (moduleServices.module9Data.beginning529BalanceAnalysis < 0) ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.beginning529BalanceAnalysis} prefix={moduleServices.module9Data.beginning529BalanceAnalysis >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.beginning529BalanceAnalysis < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.totalContribution529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.totalContribution529} prefix={moduleServices.module9Data.totalContribution529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.totalContribution529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Earnings</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.totalEarnings529 !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.totalEarnings529} prefix={moduleServices.module9Data.totalEarnings529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.totalEarnings529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Ending 529 Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module9Data.endingBalance529 !== undefined && moduleServices.module9Data.endingBalance529 < 0) || (moduleServices.module9Data.endingBalance529 >= 0) ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.endingBalance529} prefix={moduleServices.module9Data.endingBalance529 >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.endingBalance529 < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Life Insurance</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Start Year</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'life_insurance_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.life_insurance_start_year, value: updatedInvestmentValues.life_insurance_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year that you want to start contributing cash to your Long-Term Investments. If you already have Long-Term Investments, then make this year the Start Year. If this is anything other than this year, make your Beginning Balance $0.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Beginning Life Insurance Cash Balance
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.beginningLifeInsBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.beginningLifeInsBalance} prefix={moduleServices.module9Data.beginningLifeInsBalance >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.beginningLifeInsBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Monthly Premium</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            thousandSeparator
                                            decimalScale={0}
                                            value={updatedInvestmentValues.monthly_premium}
                                            onValueChange={(e) => updateValue(e, 'monthly_premium', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            onBlur={() => { setFixedDecimal('monthly_premium'); }}
                                          />

                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect to grow your Annual Contribution. For example, if you are contributing $1,000 this year and expect to contribute $1,100 next year, you would be growing your Annual Contribution by 10%. See how changing this affects your Net Worth."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>% of Monthly Premium for Cash Builder</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.monthly_premium_percentage}
                                            onValueChange={(e) => updateValue(e, 'monthly_premium_percentage', 'number')}
                                            decimalScale = { isFocus!=='monthly_premium_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='monthly_premium_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('monthly_premium_percentage'); }}
                                            onBlur={() => { setFixedDecimal('monthly_premium_percentage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect to grow your Annual Contribution. For example, if you are contributing $1,000 this year and expect to contribute $1,100 next year, you would be growing your Annual Contribution by 10%. See how changing this affects your Net Worth."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Monthly Contribution or Cash Builder
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.monthlyContributionCashBuilder !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.monthlyContributionCashBuilder} prefix={moduleServices.module9Data.monthlyContributionCashBuilder >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.monthlyContributionCashBuilder < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            Annual Net Cash Flow for Savings and Investments in Year 0
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classNames(classes.noSpace, classes.displayCenter)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.annualNetCashFlowForSavingsLifeIns !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.annualNetCashFlowForSavingsLifeIns} prefix={moduleServices.module9Data.annualNetCashFlowForSavingsLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.annualNetCashFlowForSavingsLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Expected Pre-Tax Return</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.annual_expected_pre_tax_return_life_ins}
                                            onValueChange={(e) => updateValue(e, 'annual_expected_pre_tax_return_life_ins', 'number')}
                                            decimalScale = { isFocus!=='annual_expected_pre_tax_return_life_ins'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='annual_expected_pre_tax_return_life_ins'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('annual_expected_pre_tax_return_life_ins'); }}
                                            onBlur={() => { setFixedDecimal('annual_expected_pre_tax_return_life_ins'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="leftTooltip"
                                              data-tip="The percentage return you expect to receive each year from your Long-Term Investments. A typical range would be between 4-8%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Cash Balance at the End of Year
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.cashBalanceAtTheEndYearLifeIns !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.cashBalanceAtTheEndYearLifeIns} prefix={moduleServices.module9Data.cashBalanceAtTheEndYearLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.cashBalanceAtTheEndYearLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Cash Balance at the End of Year
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.cashBalanceAtTheCurrentYearLifeIns !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.cashBalanceAtTheCurrentYearLifeIns} prefix={moduleServices.module9Data.cashBalanceAtTheCurrentYearLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.cashBalanceAtTheCurrentYearLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classNames(classes.analysisBlock, classes.headerButton)}>
                                      <h3>Life Insurance Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Life Insurance Period</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.inputRight, classes.investText)}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.longTermInvestmentsPeriodLife() !== undefined && updatedInvestmentValues.life_insurance_start_year > 0 ? moduleServices.longTermInvestmentsPeriodLife() : 0}
                                            &nbsp;&nbsp;
                                          </span>
                                          <span className={classes.noInputTextNew}>
                                            {moduleServices.longTermInvestmentsPeriodYearsLife() !== undefined && updatedInvestmentValues.life_insurance_start_year > 0 ? moduleServices.longTermInvestmentsPeriodYearsLife() : 0}
                                            {' '}
                                            Years
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Beginning Life Insurance Cash Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.beginningLifeInsBalanceAnalysis !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.beginningLifeInsBalanceAnalysis} prefix={moduleServices.module9Data.beginningLifeInsBalanceAnalysis >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.beginningLifeInsBalanceAnalysis < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.totalContributionLifeIns !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.totalContributionLifeIns} prefix={moduleServices.module9Data.totalContributionLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.totalContributionLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Earnings</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.totalEarningsLifeIns !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.totalEarningsLifeIns} prefix={moduleServices.module9Data.totalEarningsLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.totalEarningsLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Ending Life Insurance Cash Value</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module9Data.endingBalanceLifeIns !== undefined && moduleServices.module9Data.endingBalanceLifeIns < 0) || (moduleServices.module9Data.endingBalanceLifeIns >= 0) ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.endingBalanceLifeIns} prefix={moduleServices.module9Data.endingBalanceLifeIns >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.endingBalanceLifeIns < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsRightBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Financial Gift</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'financial_gift_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.financial_gift_start_year, value: updatedInvestmentValues.financial_gift_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to begin receiving a Financial Gift. See the Boost My Awareness section for more details on Financial Gifts.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'financial_gift_end_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.financial_gift_end_year, value: updatedInvestmentValues.financial_gift_end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to stop receiving a Financial Gift. If this is a one year gift, then input the same year as the Start Year. See the Boost My Awareness section for more details on Financial Gifts.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>{totalFinanceGiftYear}</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Financial Gift</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedInvestmentValues.yearly_financial_gift} onValueChange={(e) => updateValue(e, 'yearly_financial_gift', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classNames(classes.dollarSign, classes.dollarSignInput)}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much per year you expect to receive from this Financial Gift. See the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Financial Gift</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.totalFinancialGift !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.totalFinancialGift} prefix={moduleServices.module9Data.totalFinancialGift >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.totalFinancialGift < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Charitable Contributions</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'charitable_start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.charitable_start_year, value: updatedInvestmentValues.charitable_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to begin contributing to charity.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'charitable_end_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedInvestmentValues.charitable_end_year, value: updatedInvestmentValues.charitable_end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to stop contributing to charity.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>{totalCharitableYear}</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            {updatedInvestmentValues.charitable_start_year > 0 && (
                                              <strong>
                                                Total Income in Year
                                                {' '}
                                                {updatedInvestmentValues.charitable_start_year}
                                              </strong>
                                            )}
                                            {updatedInvestmentValues.charitable_start_year === 0 && (
                                              <strong>
                                                Total Income in Year
                                              </strong>
                                            )}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.charitableAnnualContributionTotalIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.charitableAnnualContributionTotalIncome} prefix={moduleServices.module9Data.charitableAnnualContributionTotalIncome >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.charitableAnnualContributionTotalIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.leftGrayBox}>
                                          <div className={classes.currencyCntnt}>
                                            <p><strong>Annual Contribution as a % of Total Income</strong></p>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedInvestmentValues.charitable_yearly_contribution}
                                            onValueChange={(e) => updateValue(e, 'charitable_yearly_contribution', 'number')}
                                            decimalScale = { isFocus!=='charitable_yearly_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='charitable_yearly_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('charitable_yearly_contribution'); }}
                                            onBlur={() => { setFixedDecimal('charitable_yearly_contribution'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The percentage of your Gross Income you would like to contribute to charity each year. Up to 50% of your Adjusted Gross Income is Tax Deductible. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Charitable Contributions Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedInvestmentValues.charitable_start_year > 0 && (
                                            <div className={classes.spanText}>
                                              Annual Contribution in the Year
                                              {' '}
                                              {updatedInvestmentValues.charitable_start_year}
                                            </div>
                                          )}
                                          {updatedInvestmentValues.charitable_start_year === 0 && (
                                            <div className={classes.spanText}>
                                              Annual Contribution in the Year
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.charitableAnnualContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.charitableAnnualContribution} prefix={moduleServices.module9Data.charitableAnnualContribution >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.charitableAnnualContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>{charitableTotalContributionLabel}</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.charitableTotalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.charitableTotalContribution} prefix={moduleServices.module9Data.charitableTotalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.charitableTotalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Estimated Tax Savings at 20% Tax Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module9Data.charitableTaxSavings !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module9Data.charitableTaxSavings} prefix={moduleServices.module9Data.charitableTaxSavings >= 0 ? '$' : '($'} suffix={moduleServices.module9Data.charitableTaxSavings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} spacing={0} xs={6} >
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>
            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.investmentsShowMe) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                  <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                )}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton}>Refresh</Button>)}
                {/* {dropdownvalues.showMe && (
                  <div>
                    <Button className={ classes.graphButton }>Show Graph</Button>
                  </div>
                )} */}
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && updatedInvestmentValues.short_term_start_year !== 0 && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Short-Term Investments</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && updatedInvestmentValues.short_term_start_year !== 0 && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Long-Term Investments</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.showDescriptionCntnt}>
                    <Grid container spacing={0}>
                      <Grid item sm={4} xs={12} spacing={0} className={classes.leftGraphBlock}>
                        <div className={classes.leftPanel}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h3 className={classes.inputsTitle}>Inputs</h3>
                                  <hr className={classes.blueLine} />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Beginning Investment Balance
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall, classes.symCurrency)}>
                                  <span className={classes.dollarSymbol}>$</span>
                                  <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} customInput={TextField} thousandSeparator value={0} onFocus={(e) => e.target.select()} />
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.heightField} />
                              </tr>
                              <tr>
                                <td>
                                  Annual Contribution
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall, classes.symCurrency)}>
                                  <span className={classes.dollarSymbol}>$</span>
                                  <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} customInput={TextField} thousandSeparator value={0} onFocus={(e) => e.target.select()} />
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.heightField} />
                              </tr>
                              <tr>
                                <td>
                                  Annual Contribution Growth
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall)}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={0} onFocus={(e) => e.target.select()} />
                                  <span className={classes.percentSymbolNew}>%</span>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.heightField} />
                              </tr>
                              <tr>
                                <td>
                                  Annual Expected Return
                                </td>
                              </tr>
                              <tr>
                                <td className={classNames(classes.whiteInputField, classes.whiteInputSmall)}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={0} onFocus={(e) => e.target.select()} />
                                  <span className={classes.percentSymbolNew}>%</span>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.heightField} />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                      <Grid item sm={8} xs={12} spacing={0} className={classes.leftGraphBlock}>
                        {/* <div className={ classes.showGraph }>
                          <img src={ showGraph } alt="networth" />
                        </div> */}
                        <div className={classes.rightPanel}>
                          <h3>What If Investment Analysis</h3>
                          <hr className={classes.blueLine} />
                          <div className={classes.containerRow}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={12} spacing={0}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td colSpan="2">
                                        <h3>Investment Breakdown</h3>
                                        <hr className={classes.blueLine} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <span className={classNames(classes.squareBox, classes.greenBox)}>&nsbp;</span>
                                        Beginning Balance
                                        {' '}
                                      </td>
                                      <td> $0 </td>
                                    </tr>
                                    <tr>
                                      <td className={classes.heightOneEight} />
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <span className={classNames(classes.squareBox, classes.blueBox)}>&nbsp;</span>
                                        Contributions
                                        {' '}
                                      </td>
                                      <td> $0 </td>
                                    </tr>
                                    <tr>
                                      <td className={classes.heightOneEight} />
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <span className={classNames(classes.squareBox, classes.yellowBox)}>&nbsp;</span>
                                        Earnings
                                        {' '}
                                      </td>
                                      <td> $0  </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2"><hr className={classes.blueLine} /></td>
                                    </tr>
                                    <tr>
                                      <td> Ending Investment Balance </td>
                                      <td> $0 </td>
                                    </tr>
                                    <tr>
                                      <td />
                                    </tr>
                                  </tbody>
                                </table>
                              </Grid>
                              <Grid item sm={6} spacing={0} />
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Investments
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Why / how does Cash get transferred to Investments?</h3>
                          <p>
                            Throughout your 5, 10, 15 year plan, when you are Cash Flow positive, your Cash account will grow. Rather than holding a large Cash balance that does not provide any return, you can tell the FC to make Investments as your Cash account reaches a certain amount, or, as we call it, Maximum Cash Balance before Investment. The FC automatically transfers your Excess Cash into Investments based on your inputs.
                            If your Cash account is above the Maximum Cash Balance before Investment, the Excess Cash will be invested into your Short-Term Investments.
                            The FC assesses your Excess Cash at the end of a given year and moves the Excess Cash to Short-Term Investments at the end of that year. The FC also assesses your Short-Term Investments at the end of a given year and moves the excess balance to Long-Term Investments at the end of that year. See the below image for an example of how the FC moves Cash into Investments.
                          </p>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement1} alt="" className={classes.pointerCursor} aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement1)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Why / how does Cash get withdrawn from Investments? What determines how much Cash is withdrawn?</h3>
                          <p>
                            Throughout your 5, 10, 15 year plan, you probably plan on making sizable investments such as going back to Graduate School, Buying a Car, or Buying a Home. These investments will use a large amount of Cash and could result in a Cash Flow negative year, which means you spent more Cash than you had at the beginning of that year. If you are short on Cash in any given year, the FC will use your Investments to fill that Cash Need and try to keep you Cash Flow positive.
                            Cash Need will be equal to the amount of Cash needed to bring your Cash account as close as
                            possible to your Maximum Cash Balance before Investment. If your Investments do not have enough funds to bring your Cash account greater than or equal to 0, that means you have spent more than you can and need to reassess. See the below image for an example of how the FC moves Investments to the Cash account.
                          </p>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement2} alt="" className={classes.pointerCursor} aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement2)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How do you calculate the return on Short-Term Investments and Long-Term Investments? What happens to my earnings?</h3>
                          <p>
                            The return on Short-Term Investments and Long-Term Investments is calculated by taking the average Investments balance in a given year multiplied by the Annual Expected Pre-Tax Return. The average balance is calculated by taking the Investments balance at the beginning of the year plus the Investments balance at the end of the year BEFORE any Cash is withdrawn from Investments and dividing by two. The Investments balance at the end of the year is calculated as beginning
                            balance plus the contribution to Investments. The earnings are then added to the Investments balance as the FC assumes that earnings are reinvested.
                          </p>
                        </div>

                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Annual Contribution</h3>
                          <p>The amount of cash contributed to an investment in a year. The FC assumes that your annual contribution is spread out over the entire year and not at one point in time.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Contribution Growth</h3>
                          <p>The rate at which your annual contributions will grow each year. For example, if your annual contribution is $1,000 and your annual contribution growth is 2%, in the next year, you will contribute $1,020. Growing your contributions each year is an effective way to maximize investments growth.</p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/a/annual-return.asp" target="_blank" rel="noopener noreferrer">Annual Expected Pre-Tax Return</a></h3>
                          <p>
                            The amount of profit an investment is
                            {' '}
                            <a href="https://www.investopedia.com/terms/e/expectedreturn.asp#ixzz4jFhmjzsQ" target="_blank" rel="noopener noreferrer" className={classes.noLink}>expected</a>
                            {' '}
                            to return each year on a pre-tax basis.
                            For example, if you have $10,000 invested and expect that investment to return 5% in a year,
                            you will earn $500 before taxes. For short-term investments, a realistic return can range
                            from 1-5% depending on the investment strategy. For long-term investments, 5-15%. The FC makes the
                            assumption that the return that you input will be
                            {' '}
                            <a href="https://www.investopedia.com/terms/c/compoundinterest.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>compounded</a>
                            {' '}
                            on an annual basis for investments.
                            Click on the &apos;Show Table&apos; buttons in the My Details section to see the details for your short
                            and long-term investments. The return each year for your short and long-term investments is
                            calculated with the below formula:
                            {' '}
                          </p>
                          <p>(Beginning Balance + (Beginning Balance + Annual Contribution)) / 2 * Annual Return</p>
                          <p>The &apos;What If Investments Calculator&apos; is slightly different. The What If Investments tool compounds interest on a monthly basis (see the compound interest definition below) over a 30 year period. Use the What If Investments tool to ask, &quot;What If?&quot; and see results instantly.</p>
                          <p>Compounding interest is a very powerful force and time plays a crucial factor. See the image below demonstrating the power of compounding interest and how the earlier you start, the better.</p>
                          <div className={classes.heImage}>
                            <img src={CompoundingInterest} alt="" aria-hidden="true" onClick={() => handleImagePopup(CompoundingInterest)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Beginning Balance</h3>
                          <p>The amount of money you currently have invested into short-term investments or long-term investments. If you have no money currently invested, that&apos;s ok! Learning about investments and developing a strategy to start investing is the perfect place to start.</p>
                          <p>If you have cash, which would be in the Financial Independence module, you can move a portion of that cash into short or long-term investments and start investing today. Simply follow the steps below to adjust your details:</p>

                          <p>
                            <span>1) Determine how much cash you want to invest from your &apos;Current Cash Balance&apos; into your short or long-term investments</span>
                            <span>2) Go to the Financial Independence module and decrease your &apos;Current Cash Balance&apos; by that amount and save that new scenario</span>
                            <span>3) Come back to the Investments module and enter the amount that you decreased your &apos;Current Cash Balance&apos; by in the beginning balance input for short and/or long-term investments</span>
                          </p>

                          <p>For example, if I have $5,000 cash today and I want to put $2,500 into my short-term investments as I start building my financial life plan, I would enter the following details:</p>
                          <p>
                            <span>$2,500 Current Cash Balance in the Financial Independence module</span>
                            <span>$2,5000 Beginning Balance for Short-Term Investments in the Investments module</span>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Cash Need</h3>
                          <p>
                            If your Cash account goes negative in any given year, Cash Need will be equal to the amount of cash needed to bring your Cash account as close as possible to your Maximum Cash Balance before Investment. For example, if your Maximum Cash Balance before Investment is $25,000, you are negative $25,000 in Cash at the end of a year,
                            and you have $100,000 in your Short-Term Investments at the end of the year, $50,000 will be pulled from your Short-Term Investments to bring your Cash account back to the $25,000 Maximum Cash Balance before Investment.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/articles/taxes/08/itemized-deductions-overview.asp" target="_blank" rel="noopener noreferrer">Charitable Contributions</a></h3>
                          <p>
                            A monetary gift given to a charitable organization in any given year. Charitable Contributions are an
                            {' '}
                            <a href="https://www.investopedia.com/articles/taxes/08/itemized-deductions-overview.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Itemized Tax Deduction </a>
                            {' '}
                            that you can find on your Tax Statement. Charitable Contributions not only help out some great causes, but they also can save you on taxes!
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.bankrate.com/banking/checking-vs-savings-accounts/" target="_blank" rel="noopener noreferrer">Checking Account</a></h3>
                          <p>
                            A bank account, also known as a transactional account, that provides easy access to your money and is most commonly used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks. A bank account is an arrangement to deposit money with a bank, which will keep that money
                            safe until withdrawn or spent. Since checking accounts typically pay very little to no interest on the money you keep with the bank, the FC treats checking account deposits as cash.
                          </p>
                          <p>
                            These accounts sometimes charge fees for services such as not meeting a minimum balance requirement or using another bank&apos;s ATM to withdraw cash and many more. Selecting a checking account can be tricky; check out this resource for
                            {' '}
                            <a href="https://www.thebalance.com/checking-accounts-2385969" target="_blank" rel="noopener noreferrer">more info on a checking account</a>
                            {' '}
                            and this resource for
                            {' '}
                            <a href="https://www.thebalance.com/best-banks-for-checking-accounts-4163073" target="_blank" rel="noopener noreferrer">some options.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/c/compoundinterest.asp" target="_blank" rel="noopener noreferrer">Compound Interest</a></h3>
                          <p>Also known as, &quot;earning interest on interest.&quot; Compound interest is interest calculated on the total amount invested plus all accumulated interest. Compound interest is easiest to understand in an example.</p>
                          <p>
                            For example, let&apos;s say you make a $10,000 investment at the beginning of this month that earns 6% interest annually and compounds on a monthly basis. At the end of the month, the $10,000 would be multiplied by 6% and divided by 12 (6% return is an annual number so divide by 12 to make that a monthly number) and get $50 of monthly earnings. Now,
                            at the end of that month, your beginning investment balance of $10,000 plus $50 of earnings equals an ending investment balance of $10,050. And, the cycle repeats itself with earnings growing each month. Review the formula and table below to understand how compounding interest works each month and over time.
                          </p>
                          <p>
                            <span className={classes.textUnderline}>If an investment compounds on a monthly basis and earns 6% annually:</span>
                            <span>Beginning Investment Balance x Annual Interest Rate / 12 = Monthly Earnings</span>
                            <span>$10,000 x 6% / 12 = $50.00 Monthly Earnings</span>
                          </p>
                          <p>
                            <span>Beginning Investment Balance + Monthly Earnings = Next Month Beginning Balance</span>
                            <span>$10,000.00 + $50.00 = $10,050.00 Next Month Beginning Balance</span>
                          </p>
                          <p>Take a look at the table below to see how compounding interest grows over time.</p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={CompoundInterest} alt="" aria-hidden="true" onClick={() => handleImagePopup(CompoundInterest)} />
                          </div>
                          <p>How does this add up over months or years? In 6 months, your balance has grown to $10,303; in 12 months, $10,616; and, in 36 months, the balance grows by almost $2,000 to nearly $12,000! Compounding interest only becomes more powerful over time. Save and invest as early to capitalize on the effects of compound interest over time.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Excess Cash</h3>
                          <p>
                            As you build your 5, 10, 15 year plan, we think you will plan on working, earning a good wage, and hopefully saving some money too. To forecast your savings, the FC will take your Excess Cash and invest the cash into your Short-Term Investments. What is your Excess Cash? Remember the &quot;Maximum Cash Balance before Investment&quot; input you set in the Financial Independence module? If you
                            have a cash balance at the end of the year that is greater than that Max Cash Balance input, you have excess cash. Let&apos;s take an example.
                          </p>
                          <p>Max Cash Balance is set at $20,000. At the end of 2022, you have an Ending Cash Balance of $50,000. Your Excess Cash would be $30,000 or $50,000 minus the $20,000 max limit. That $30,000 would then be moved into your Short-Term Investments at the end of the year.</p>
                          <p>The FC automatically does this at the end of the year and you can see this happen on the Cash Flow Statement.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/g/gift.asp#ixzz4jFjHZsjH" target="_blank" rel="noopener noreferrer">Financial Gift</a></h3>
                          <p>Money that is given as a gift in a given year. For example, if your grandparents give you $5,000 for graduation, your parents give you money each year on your birthday, or something similar to these, would be considered a financial gift.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/i/investment.asp" target="_blank" rel="noopener noreferrer">Investment</a></h3>
                          <p>
                            A monetary asset that is purchased with the hope that it will generate income or will appreciate in the future. An
                            {' '}
                            <a href="https://www.investopedia.com/terms/i/investment.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>investment</a>
                            {' '}
                            is a tool to create future wealth and help you achieve your financial life goals. Some examples of investments are high-yield savings accounts, certificate of deposits, stocks, bonds, and real estate.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Long-Term Funds Flow</h3>
                          <p>
                            Cash flow movement between your Long-Term Investments and your Cash account. If you are short on cash in any given year after you have used all of your Short-Term Investments, Long-Term Investments will be used to fill that Cash Need
                            to keep you cash flow positive. If your Short-Term Investments is above the Max Short-Term Investments Balance before Long-Term Investments, the excess amount will be invested into your Long-Term Investments from your Short-Term Investments. See the below images for examples of how the FC moves Cash into Investments and vice versa.
                          </p>
                          <img className={classes.fundsImage} src={CashFlowMovement1} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement1)} />
                          <img className={classes.fundsImage} src={CashFlowMovement2} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement2)} />
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/l/longterminvestments.asp" target="_blank" rel="noopener noreferrer">Long-Term Investments</a></h3>
                          <p>
                            An investment that is expected to be held for more than one year and will only be liquidated into cash for large purchases or in the case of emergency. Some types of long-term investments can be found
                            {' '}
                            <a href="https://www.gobankingrates.com/investing/strategy/best-long-term-investments/" target="_blank" rel="noopener noreferrer">here.</a>
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}><img src={TypesOfLongTermInvestments} alt="" aria-hidden="true" onClick={() => handleImagePopup(TypesOfLongTermInvestments)} /></div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/understanding-risk-3141268" target="_blank" rel="noopener noreferrer">Risk-to-Reward</a></h3>
                          <p>
                            Before you make any investments, it&apos;s essential to understand the relationship between risk and reward and your personal risk tolerance. When making investments, there is always going to be different levels of risk. Measure your risk tolerance by taking
                            {' '}
                            <a href="https://njaes.rutgers.edu/money/assessment-tools/investment-risk-tolerance-quiz.pdf" target="_blank" rel="noopener noreferrer" className={classes.noLink}>this quiz</a>
                            .
                          </p>
                          <p>
                            Let&apos;s define both of those terms. Risk is the chances of you losing some or all of your investment. On the other hand, reward is the chances of you making money on your investment. Risk-to-reward is looking at the relationship between the two. Are you risking a large potential loss for only a small chance of making money?
                            All investments require taking risk so before making any investment, you always want to consider the risk-to-reward. Check out the tables below to see profiles of different investments.
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={TypesOfShortTermInvestments} alt="" aria-hidden="true" onClick={() => handleImagePopup(TypesOfShortTermInvestments)} />
                          </div>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={TypesOfLongTermInvestments} alt="" aria-hidden="true" onClick={() => handleImagePopup(TypesOfLongTermInvestments)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Max Short-Term Investments Balance before Long-Term Investments</h3>
                          <p>
                            Max Short-Term Investments Balance before Long-Term Investments tells the FC that once you have built up a certain amount of Short-Term Investments, to start investing the extra amount into Long-Term Investments. For example, let&apos;s say Max Short-Term Investments Balance before Long-Term Investments is $30,000 and your Ending Short-Term
                            Investments Balance, after Excess Cash is transferred (see image below) in 2025 is $75,000. At the end of 2025, $45,000 would be transferred from your Short-Term Investments into your Long-Term Investments. See the below images for examples of how the FC moves Cash into Investments and vice versa.
                          </p>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement1} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement1)} />
                          </div>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement2} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement2)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/r/ruleof72.asp" target="_blank" rel="noopener noreferrer">Rule of 72</a></h3>
                          <p>
                            The rule of 72 is a quick way to calculate the number of years it will take for an investment to double in value given an annual rate of return or interest rate. This formula comes in handy for mental calculations and when thinking about an investment. It&apos;s important to note that the rule of 72 only applies to
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/interest-tutorial/compound-interest-tutorial/v/introduction-to-compound-interest" target="_blank" rel="noopener noreferrer" className={classes.noLink}>compounding interest</a>
                            {' '}
                            and not simple interest. Check out this link for the
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/interest-tutorial/compound-interest-tutorial/v/the-rule-of-72-for-compound-interest" target="_blank" rel="noopener noreferrer" className={classes.noLink}>math behind the rule of 72.</a>
                          </p>
                          <p># of years to double in value = 72 / annual rate of return (or interest rate)</p>
                          <p>For example, let&apos;s say we have two investments: a stock and a bond. The stock is expected to return 6% per year and the bond has an interest rate of 4%. The rule of 72 would show:</p>
                          <p>
                            <span>Stock&apos;s # of years to double in value = 72 / 6 = 12 years</span>
                            <span>Bond&apos;s # of years to double in value = 72 / 4 = 18 years</span>
                          </p>
                          <p>The rule of 72 is a quick way to calculate the # of years it will take for an investment to double in value, but is not exact and does not take into account all aspects of an investment. When considering making an investment, you have to look at the whole picture such as risk, expected return, investment time or maturity, and much more.</p>
                          <div className={classes.heImage}>
                            <img src={Ruleof72} alt="" aria-hidden="true" onClick={() => handleImagePopup(Ruleof72)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.bankrate.com/banking/checking-vs-savings-accounts/" target="_blank" rel="noopener noreferrer">Savings Account</a></h3>
                          <p>A bank account where you can store money that you don&apos;t need right away, but still provides easy access to your money. Savings accounts are typically used as a short-term investment for money that you don&apos;t intend to use for regular expenses. If you have a savings account, use the short-term investments section in the Investments module to account for that asset.</p>
                          <p>
                            Savings accounts almost always pay interest on the money you keep with the bank. Like checking accounts, these accounts sometimes charge fees for services such as not meeting a minimum balance requirement. Selecting a savings account requires some due diligence;
                            {' '}
                            <a href="https://www.investopedia.com/terms/s/savingsaccount.asp" target="_blank" rel="noopener noreferrer">watch this video</a>
                            {' '}
                            for more info. And, take a look at this resource
                            {' '}
                            <a href="https://www.bankrate.com/banking/savings/rates/" target="_blank" rel="noopener noreferrer">to compare the most competitive savings accounts today.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/s/sensitivityanalysis.asp#ixzz4jFkkQnp1" target="_blank" rel="noopener noreferrer">Sensitivity Analysis</a></h3>
                          <p>
                            A technique used to determine how different values of a specific variable can impact an analysis. FC uses this technique when looking at the annual expected return of investments and retirement accounts. We do this by looking at the future value of investments by adjusting the annual expected return to a higher and lower number. For example, if you think an investment will return 6.00%, we
                            analyze the future value of that investment at a 3.00% return (1/2 the expected return), 6.00% (the expected return), and 9.00% (1/2 the expected return + the expected return).
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Short-Term Funds Flow</h3>
                          <p>Cash flow movement between your Short-Term Investments and your Cash account. If you are short on cash in any given year, Short-Term Investments will be used to fill that Cash Need to keep you cash flow positive. If your Cash account is above the Maximum Cash Balance before Investment, the Excess Cash will be invested into your Short-Term Investments. See the below images for examples of how the FC moves Cash into Investments and vice versa.</p>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement1} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement1)} />
                          </div>
                          <div className={classes.heImage}>
                            <img src={CashFlowMovement2} alt="" aria-hidden="true" onClick={() => handleImagePopup(CashFlowMovement2)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.gobankingrates.com/investing/strategy/best-short-term-investments/" target="_blank" rel="noopener noreferrer">Short-Term Investments</a></h3>
                          <p>
                            An investment that can easily be converted into cash and is expected to be held for less than one year. Or, money that you plan to hold as a short-term
                            {' '}
                            <a href="https://www.investopedia.com/terms/e/emergency_fund.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>safety net</a>
                            {' '}
                            - funds that could be used in the case of emergency.
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={TypesOfShortTermInvestments} alt="" aria-hidden="true" onClick={() => handleImagePopup(TypesOfShortTermInvestments)} />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Start Year</h3>
                          <p>The year that you want to start investing your Excess Cash into Short-Term Investments and Long-Term Investments. This also applies to the year that you may receive a Financial Gift, or start contributing to charity.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Guide to Investing</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.investopedia.com/university/beginner/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Investopedia: Guide to Investing</a>
                            <a href="https://www.thebalance.com/investing-for-beginners-4074004" target="_blank" rel="noopener noreferrer" className={classes.noLink}>The Balance: Guide to Investing</a>
                            <a href="https://www.thebalance.com/stocks-4073971" target="_blank" rel="noopener noreferrer" className={classes.noLink}>The Balance: Stocks</a>
                            <a href="https://www.thebalance.com/how-to-invest-in-bonds-for-your-portfolio-357385" target="_blank" rel="noopener noreferrer" className={classes.noLink}>The Balance: Bonds</a>
                            <a href="https://www.thebalance.com/what-is-an-etf-1214813" target="_blank" rel="noopener noreferrer" className={classes.noLink}>The Balance: ETFs</a>
                            <a href="https://www.thebalance.com/mutual-funds-101-356319" target="_blank" rel="noopener noreferrer" className={classes.noLink}>The Balance: Mutual Funds</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Short and Long-Term Investments</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/the-50-30-20-rule-of-thumb-453922" target="_blank" rel="noopener noreferrer" className={classes.noLink}>50/30/20 rule of saving</a>
                            <a href="https://www.investopedia.com/personal-finance/how-to-build-emergency-fund/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Why you should build an emergency fund - watch the video</a>
                            <a href="https://www.thebalance.com/best-investment-apps-4154203" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Great investment apps to get investing with any amount</a>
                            <a href="https://www.thebalance.com/start-investing-with-little-money-4139795" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Get investing with little to no money</a>
                            <a href="https://www.gobankingrates.com/investing/7-best-short-term-investment-options/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>9 short-term investment options</a>
                            <a href="https://www.thebalance.com/best-banks-for-savings-accounts-4160384" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Best banks for savings accounts in 2018</a>
                            <a href="https://www.gobankingrates.com/investing/6-best-long-term-investments/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>6 long-term investment options</a>
                            <a href="https://www.nerdwallet.com/blog/investing/best-robo-advisors/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Low-cost option to investing: robo-advisors</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Returns</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/stock-market-returns-by-year-2388543" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average stock market returns over the past 30 years</a>
                            <a href="https://www.moneyunder30.com/power-of-compound-interest" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Power of compounding interest</a>
                            <a href="https://www.investopedia.com/terms/s/sensitivityanalysis.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Sensitivity Analysis and why it&apos;s important for looking at the future - watch the video example</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Charitable Contributions</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/tax-deduction-for-charity-donations-3192983" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax deductions for charitable contributions</a>
                            <a href="https://www.entrepreneur.com/article/274533#" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Charitable giving is an investment. Do the research before you give</a>

                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.careerAdvancementSavedScenareos) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Investments" moduleSelected="module9" getModuleData={getModuleData} setUpdatedScenario={setUpdatedInvestmentValues} />
              )}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>

      </div>
      <div>
        <Dialog
          open={popupOpen}
          onClose={() => { setPopupOpen(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedInvestmentValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}

            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={imagePopup}
          onClose={handleImagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.maxModule}
        >
          <DialogContent className={classes.maxModal}>
            <div className={classes.maxHeader}>
              <div className={classes.modalClose} aria-hidden="true" onClick={handleImagePopup}>
                ×

              </div>
            </div>
            <div className={classes.maxModalBody}>
              <div>
                {/* <span onClick={ handleImagePopup } aria-hidden="true">close</span> */}
                <img src={imageURL} alt="" />
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleImagePopup}>Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module9' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {openWorkSheet !== '' && <InvestmentWorksheetTable closeWorkSheet={setOpenWorkSheet} openWorkSheet={openWorkSheet} />}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

InvestmentsWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(InvestmentsWorksheet);
