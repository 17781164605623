import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {
  AppBar,
  Container,  
} from '@material-ui/core';
import Pageloader from '../pageloader';
import $ from 'jquery';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// eslint-disable-next-line
import AnimatedNavbar from './AnimatedNavbar';
import globalStyles from '../../layout/globalStyles';
import styles from './styles';
import { isExpired } from 'react-jwt';
import combineStyles from '../../../utilities/combineStyles';
import Logo from '../../../assets/img/logo.png';
import MainDropDown from '../../contact-dropdown/contact-dropdown';
import dummyProfile from '../../../assets/img/addressbook/user.png';
import walkthru from '../../../assets/img/build-career-sketch/walk.png';
import notificationsGif from '../../../assets/img/notifications/notifications.gif';
import shareEarnIcon from '../../../assets/img/share_earn-icon.png';
import univestLogo from '../../../assets/img/inkwiry-univest.png';
// import userIcon from '../../../assets/img/aaron-story/aaron_story_img_2.png';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const GET_AUTH_INFO = loader('../../../graphql/schema/auth/auth-info.graphql');
const MY_DETAILS = loader('../../../graphql/schema/auth/me.graphql');
const USER_NOTIFICATION = loader('../../../graphql/schema/notification/user-notification.graphql');
const ACCEPT_REJECT_FRIENDREQUEST = loader('../../../graphql/schema/notification/accept-reject.graphql');
const SAVE_UNIVEST_COUNT = loader('../../../graphql/schema/ui/save-univest-count.graphql');
const CHANGE_NOTIFICATION_STATUS = loader('../../../graphql/schema/notification/check-notification-status.graphql');
const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const Header = (props) => {
  const { classes, history, } = props;

  let bigHeader = true;
  if (history.location.pathname === '/') {
    bigHeader = true;
  }

  let walkthruicon = false;
  if (history.location.pathname === '/fc-blueprint') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/financial-independence') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/existing-assets-liabilities') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/career-path') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/career-advancement') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/additional-career-advancement') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/further-career-advancement') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/investments') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/rei') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/higher-education') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/more-higher-education') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/further-higher-education') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/family') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/vacation') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/buying-a-car') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/buying-a-home') {
    walkthruicon = true;
  }
  if (history.location.pathname === '/timeline-sketcher') {
    walkthruicon = true;
  }

  const apolloClient = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [bigHeaderStatus] = useState(bigHeader);
  const [walkthruiconStaus] = useState(walkthruicon);
  const [openMenu, setOpenMenu] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [myAccountPopOpen, setMyAccountPopOPen] = React.useState(false);
  const [openNotificationPopOpen, setOpenNotificationPopOPen] = React.useState(false);
  const [notificationClear, setNotificationClear] = useState(true);
  const [notificationData, setNotificationData] = useState('');
  const [limitTo] = useState('10');
  const [limitFrom] = useState('10');
  // const [acceptStatus, setAcceptStatus] = useState('');
  const [acceptPopUp, setAcceptPopUp] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [notificationcount, setNotificationCount] = useState(0)


  const { data } = useQuery(GET_AUTH_INFO, {
    onCompleted() {
      const token = localStorage.getItem('app-token');
      if (token) {
        const isMyTokenExpired = isExpired(token);
        if (!isMyTokenExpired) {
          apolloClient.writeData({ data: { isLoggedIn: true } });
        } else {
          apolloClient.writeData({ data: { isLoggedIn: false } });
          handleLogout();
        }
      }
    },
  });

  const { data: myDetails } = useQuery(MY_DETAILS);

  const [userNotification] = useLazyQuery(USER_NOTIFICATION, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        limitTo: limitTo,
        limitFrom: limitFrom
      },
    },
    onCompleted(response) {
      setLoading(false);
      setNotificationData(response.getUserNotifications.data !== null ? response.getUserNotifications.data : '');
      setNotificationCount(response && response.getUserNotifications.count);
    }
  });

  const [acceptrejectrequest] = useMutation(ACCEPT_REJECT_FRIENDREQUEST, {
    onCompleted({
      acceptFriendInvitation: {
        message
      }
    }) {
      if (message === 'accepted') {
        setLoading(false);
        setAcceptPopUp(true);
        userNotification();
        setNotificationCount(notificationcount - 1);
      } else if (message === 'rejected') {
        setLoading(false);
        setRejectPopUp(true);
        userNotification();
        setNotificationCount(notificationcount - 1);
      }else{
        setLoading(false);
        userNotification();
      }
    }
  });

  const [saveUnivestCount] = useMutation(SAVE_UNIVEST_COUNT, {
    onCompleted() {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'https://www.univest.net/';
      hiddenElement.target = '_blank';
      hiddenElement.click();
    }
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    $(window).on('beforeunload', () => {
      $(window).scrollTop(0);
    });
    userNotification();
    const token = localStorage.getItem('app-token');
    if (token) {
      apolloClient.writeData({ data: { isLoggedIn: true } });
    }
  }, []);

  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  const redirectToHome = () => {
    history.push('/');
  };

  const redirectToJoin = () => {
    history.push('/pricing');
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  const redirectToShare = () => {
    history.push('/share-and-earn');
    setMyAccountPopOPen(false);
    setOpenNotificationPopOPen(false);
  };


  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
    setOpenNotificationPopOPen(false);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const redirectToAddressBook = () => {
    history.push('/addressbook');
  };

  const redirectToDesktop = () => {
    history.push('/desktop');
  };

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  const redirectToMyAccount = () => {
    history.push('/my-account');
  };

  const openNotificationPopup = (event) => {
    setOpenMenu(null);
    setMyAccountPopOPen(false);
    setOpenNotification(event.currentTarget);
    setOpenNotificationPopOPen(!openNotificationPopOpen);
  };

  const closeNotificationPopup = () => {
    setOpenNotification(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('app-token');
    apolloClient.resetStore();
    history.push('/');
  };

  const openWalkThru = () => {
    localStorage.setItem('walkthruopen', true);
    if (history.location.pathname === '/fc-blueprint') {
      history.push('/fc-blueprint?walkthru=open');
    }
    if (history.location.pathname === '/financial-independence') {
      history.push('/financial-independence?walkthru=open');
    }
    if (history.location.pathname === '/existing-assets-liabilities') {
      history.push('/existing-assets-liabilities?walkthru=open');
    }
    if (history.location.pathname === '/career-path') {
      history.push('/career-path?walkthru=open');
    }
    if (history.location.pathname === '/career-advancement') {
      history.push('/career-advancement?walkthru=open');
    }
    if (history.location.pathname === '/additional-career-advancement') {
      history.push('/additional-career-advancement?walkthru=open');
    }
    if (history.location.pathname === '/further-career-advancement') {
      history.push('/further-career-advancement?walkthru=open');
    }
    if (history.location.pathname === '/investments') {
      history.push('/investments?walkthru=open');
    }
    if (history.location.pathname === '/rei') {
      history.push('/rei?walkthru=open');
    }
    if (history.location.pathname === '/higher-education') {
      history.push('/higher-education?walkthru=open');
    }
    if (history.location.pathname === '/more-higher-education') {
      history.push('/more-higher-education?walkthru=open');
    }
    if (history.location.pathname === '/further-higher-education') {
      history.push('/further-higher-education?walkthru=open');
    }
    if (history.location.pathname === '/family') {
      history.push('/family?walkthru=open');
    }
    if (history.location.pathname === '/vacation') {
      history.push('/vacation?walkthru=open');
    }
    if (history.location.pathname === '/buying-a-car') {
      history.push('/buying-a-car?walkthru=open');
    }
    if (history.location.pathname === '/buying-a-home') {
      history.push('/buying-a-home?walkthru=open');
    }
    if (history.location.pathname === '/timeline-sketcher') {
      history.push('/timeline-sketcher?walkthru=open');
    }
  };

  const [changenotificationstatus] = useMutation(CHANGE_NOTIFICATION_STATUS, {
    onCompleted() {
      userNotification();
      setLoading(false);
    }
  });

  const handleNotificationClear = () => {
    setLoading(true);
    setNotificationClear(false);
    setNotificationCount('');
    changenotificationstatus({
      variables: {
        data: {
          id: "",
          type: "clearall",
        },
      },
    })
  }

  const handleNo = (d) => {
    setLoading(true);
    acceptrejectrequest({
      variables: {
        data: {
          member_id: d.sent_user_id,
          notification_id: d.id,
          status: 'reject'
        }
      },
    });
  }

  const handleYes = (d) => {
    setLoading(true);
    acceptrejectrequest({
      variables: {
        data: {
          member_id: d.sent_user_id,
          notification_id: d.id,
          status: 'accept'
        }
      },
    });
  }

  const handleUnivest = () => {
    saveUnivestCount({
      variables: {
        school_id: myDetails && myDetails.me.institution_id
      },
    });
  }

  const handleScenario = (d) => {
    history.push('/timeline-sketcher');
    localStorage.setItem('scenario-data', JSON.stringify(d));
  }
  const handleSketch = (d) => {
    history.push('/timeline-sketcher');
    localStorage.setItem('sketch-data', JSON.stringify(d));
  }

  const handleRedirect = (d) => {
    setLoading(true);
    changenotificationstatus({
      variables: {
        data: {
          id: d.id,
          type: '',
        },
      },
    })
  }

  useEffect(()=>{
    if(acceptPopUp === true){
      setTimeout(() => {           
        setAcceptPopUp(false);               
      }, 10000);
    }
  },[acceptPopUp]);

  useEffect(()=>{
    if(rejectPopUp === true){
      setTimeout(() => {           
        setRejectPopUp(false);               
      }, 10000);
    }
  },[rejectPopUp]);

  return (
    <AppBar position="static" className={classNames(classes.header, classes.headerAuto, bigHeaderStatus === true && classes.bigHeader)}>
      {loading && <Pageloader loading={loading} />}
      <Container maxWidth="lg" className={classes.container}>        
        <div className={`${classes.headerInner} ${classes.headerInnerMegaMenu}`}>
          {/* <div className={classes.headerLeft}> */}
          {myDetails && myDetails.me.institution_id !== null ?
            <div className={classes.headerLeft}>
              <span aria-hidden="true" className={classes.univestSpan}>
                <img src={univestLogo} alt="inkwiry-logo-univest" />
                <Link to='/' className={classes.inwiryLink}></Link>
                <span onClick={handleUnivest} target="_blank" className={classes.univestLink}></span>
              </span>
              {/* <span aria-hidden="true" className={classes.inkwirySpan} onClick={redirectToHome}><img src={Logo} alt="inkwiry-logo" /></span> */}
              <span className={classes.menuToggle} aria-hidden="true" onClick={handleMenuToggle}><i className="las la-bars" /></span>
            </div>
            :
            <div className={classes.headerLeft}>
              <span aria-hidden="true" className={classes.inkwirySpan} onClick={redirectToHome}><img src={Logo} alt="inkwiry-logo" /></span>
              <span className={classes.menuToggle} aria-hidden="true" onClick={handleMenuToggle}><i className="las la-bars" /></span>
            </div>
          }
          {/* </div> */}
          <div className={classNames(classes.headerMiddle, "headerMiddleDiv", menuToggle && classes.menuToggleClass)}>
            <AnimatedNavbar duration={300} loginData={data} setOpen={setOpen} />
            {/* <ul className={ classes.navMenu }>
              <li>
                <span aria-hidden="true" onClick={ redirectToAppTools }>Apps + Tools</span>
              </li>
              <li>
                <span aria-hidden="true" onClick={ redirectToAbout }>About</span>
              </li>
              <li>
                <span aria-hidden="true" onClick={ redirectToWhyInnkwiry }>Why Inkwiry</span>
              </li>
              <li>
                <span aria-hidden="true" onClick={ redirectToNews }>News</span>
              </li>
              <li>
                <span aria-hidden="true" onClick={ redirectToJoin }>Pricing</span>
              </li>
              <li>
                <span aria-hidden="true" onClick={ redirectToContact }>Contact</span>
              </li>
            </ul> */}
          </div>

          {
            !data.isLoggedIn && (
              <div className={classNames(classes.headerRight, classes.headerRightNew)}>
                <ul>
                  <li className={classNames(classes.signIn, classes.signInLink)}><span aria-hidden="true" onClick={redirectToLogin} className={classes.signLink}>Sign In</span></li>
                  <li className={classes.joinLink}><span aria-hidden="true" onClick={redirectToJoin} className={classes.joinButton}>Join</span></li>
                </ul>
              </div>
            )
          }
          {
            data.isLoggedIn && (
              <div className={classNames(classes.headerRight, "header-right")} >
                <ul className={classes.headerRightList}>
                  {myDetails && myDetails.me.institution_id !== null ?
                    ''
                    :
                    <li className={classNames(classes.shareIconIn, classes.shareIcon)}><span aria-hidden="true" onClick={redirectToShare} className={classes.shareIconSpan}><span className={classes.shareIconImg}><img src={shareEarnIcon} alt="" /></span><em>Share + Earn</em></span></li>
                  }
                  <li className={classes.signIn} aria-hidden="true" onClick={handleClick} onMouseLeave={() => { setMyAccountPopOPen(false); }}>
                    <span className={ classes.profileLayer }>
                      <img aria-hidden="true" onClick={() => { setMyAccountPopOPen(!myAccountPopOpen); }} src={myDetails && myDetails.me.profile_image ? IMAGE_PATH + myDetails.me.profile_image : dummyProfile} alt="profile" />
 
                      {' '}
                      <span aria-hidden="true" onClick={() => { setMyAccountPopOPen(!myAccountPopOpen); }} >
                        {myDetails && myDetails.me.user_fname}                      
                      </span>
                    </span>
                    <ul
                      id="simple-menu"
                      anchorEl={openMenu}
                      keepMounted
                      open={Boolean(openMenu)}
                      onClose={handleClose}
                      onMouseLeave={() => { setMyAccountPopOPen(!myAccountPopOpen); }}
                      className={(myAccountPopOpen === true) ? classes.accountMenu : classes.accountMenuHide}
                    // className ={mousLevAccountPop === true ? classes.accountMenuHide : classes.accountMenu}
                    >
                      <li onClick={redirectToMyAccount} aria-hidden="true">
                        <span className={classes.accountIcon} />
                        My Account
                      </li>
                      <li onClick={redirectToAddressBook} aria-hidden="true">
                        <span className={classes.bookIcon} />
                        Address Book
                      </li>
                      <li onClick={redirectToDesktop} aria-hidden="true">
                        <span className={classes.desktopIcon} />
                        My Desktop
                      </li>
                      <li onClick={redirectToDashboard} aria-hidden="true">
                        <span className={classes.dashboardIcon} />
                        My Dashboard
                      </li>
                      <li onClick={handleLogout} aria-hidden="true">
                        <span className={classes.logoutIcon} />
                        Logout
                      </li>
                    </ul>

                  </li>

                  <li className={classes.join} aria-hidden="true" onClick={openNotificationPopup}
                  //  onMouseLeave ={ ()=> { setOpenNotificationPopOPen(false); } }
                  >
                    {notificationcount === 0 && !notificationcount ?
                      ''
                      :
                      <span className={classNames(classes.notificationCount, "notification-count-text")} >{notificationcount}</span>
                    }
                    <i className="fa fa-bell-o" aria-hidden="true" />
                    <ul
                      id="simple-menu"
                      anchorEl={openNotification}
                      keepMounted
                      open={Boolean(openNotification)}
                      onClose={closeNotificationPopup}
                      onMouseLeave={() => { setOpenNotificationPopOPen(false); }}
                      className={openNotificationPopOpen === true ? classes.notificationDropDownActive : classes.notificationDropDown}
                    >
                      {/* <MenuItem onClick={ redirectToMyAccount }>Notification</MenuItem> */}

                      <div className={classes.notifyTitle}>
                        Notifications
                        <span><Link to="/notifications" onClick={() => {  setLoading(true); changenotificationstatus({ variables: { data: { id: "", type: "viewall", }, }, }) }}>See All</Link></span>
                        {notificationcount === 0 && !notificationcount ?
                          ''
                          :
                          <span className={classes.resetNotifications} onClick={handleNotificationClear}>Clear All</span>
                        }
                      </div>
                      {notificationClear === true ?
                        <div>
                          <div className={classNames(classes.bodyContent, classes.bodyContentScroll)} >
                            {notificationData !== '' && notificationData.length > 0 ?
                              notificationData.map((d, index) => (
                                <div key={index} >
                                  {d.notification_type === 'FR' ?
                                    <div onClick={handleRedirect}>
                                      <div className={classes.notificationDate}>{d.date}</div>
                                      <div className={classes.notiPortion}>
                                        <div className={classes.profileImg}>
                                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="logo" />
                                        </div>
                                        <div className={classes.notificationMid}>
                                          <div className={classes.notificationSpan}>
                                            <div className={classes.notificationSpanCnt}>{d.content}</div>
                                            <div className={classes.notificationSpanButton}>
                                              <button onClick={() => { handleYes(d) }}>yes</button>
                                              <button onClick={() => { handleNo(d) }}>no</button>
                                            </div>
                                          </div>
                                          <div className={classes.noteDate}>{moment(d.created_at).fromNow()}</div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    ''
                                  }
                                  {d.notification_type === 'FRA' ?
                                    <div onClick={() => { handleRedirect(d) }}>
                                      <div className={classes.notificationDate}>{d.date}</div>
                                      <div className={classes.notiPortion}>
                                        <div className={classes.profileImg}>
                                          {/* <img src={d.profile_image ? d.profile_image : dummyProfile} alt="logo" /> */}
                                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="logo" />
                                        </div>
                                        <div className={classes.notificationMid}>
                                          <div className={classes.notificationSpan}>
                                            <div className={classes.notificationSpanCnt}>{d.content}</div>
                                          </div>
                                          <div className={classes.noteDate}>{moment(d.created_at).fromNow()}</div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    ''
                                  }
                                  {d.notification_type === 'S' ?
                                    <div onClick={() => { handleRedirect(d) }}>
                                      <div className={classes.notificationDate}>{d.date}</div>
                                      <div className={classes.notiPortion}>
                                        <div className={classes.profileImg}>
                                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="logo" />
                                        </div>
                                        <div className={classes.notificationMid}>
                                          <div className={classes.notificationSpan}>
                                            <div className={classes.notificationSpanCnt}>
                                              <div dangerouslySetInnerHTML={{ __html: d.content ? d.content : 'N/A' }} />.<span>Click&nbsp;<span className={classes.anchorSpan} onClick={() => { handleScenario(d) }}>here</span> to load the scenario(s)</span>
                                            </div>
                                          </div>
                                          <div className={classes.noteDate}>{moment(d.created_at).fromNow()}</div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    ''
                                  }
                                  {d.notification_type === 'CS' ?
                                    <div onClick={() => { handleRedirect(d) }}>
                                      <div className={classes.notificationDate}>{d.date}</div>
                                      <div className={classes.notiPortion}>
                                        <div className={classes.profileImg}>
                                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="logo" />
                                        </div>
                                        <div className={classes.notificationMid}>
                                          <div className={classes.notificationSpan}>
                                            <div className={classes.notificationSpanCnt}>
                                              <div dangerouslySetInnerHTML={{ __html: d.content ? d.content : 'N/A' }} />.<span>Click&nbsp;<span className={classes.anchorSpan} onClick={() => { handleSketch(d) }}>here</span> to load the scenario(s)</span>
                                            </div>
                                          </div>
                                          <div className={classes.noteDate}>{moment(d.created_at).fromNow()}</div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    ''
                                  }
                                </div>
                              ))
                              :
                              <div className={classes.noNotificationBox}>
                                <img alt="" src={notificationsGif}  />
                                <Typography variant="h3" component="h3">No Notifications</Typography>
                                <p>You currently have no notifications</p>
                              </div>
                            }
                          </div>

                        </div>
                        :
                        // 'No Notification Found'
                        <div className={classes.noNotificationBox}>
                          <img alt="" src={notificationsGif}  />
                          <Typography variant="h3" component="h3">No Notifications</Typography>
                          <p>You currently have no notifications</p>
                        </div>
                      }
                    </ul>
                  </li>
                  {walkthruiconStaus === true && (
                    <li className={classes.walkthru} aria-hidden="true" onClick={openWalkThru} alt="tooltip" data-for="walkThru" data-tip="Inkwiry Walk-Thru" >
                      <img alt="" src={walkthru} />
                    </li>
                  )}

                </ul>
              </div>
            )
          }
        </div>
        <ReactTooltip id="walkThru" place="bottom" type="info" effect="solid" />
        <MainDropDown open={open} setOpen={setOpen} />
      </Container >

      <Dialog
        open={acceptPopUp}
        onClose={() => { setAcceptPopUp(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={() => { setAcceptPopUp(false) }}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupCheck}>
              <i className="las la-check" />
            </span>
            <p>Invitation Accepted!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>

      <Dialog
        open={rejectPopUp}
        onClose={() => { setRejectPopUp(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={() => { setRejectPopUp(false) }}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupRejectCheck}>
              <i className="las la-check" />
            </span>
            <p>Invitation Rejected!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>

    </AppBar >
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(
  withStyles(combinedStyles),
  withRouter,
);

export default enhance(Header);
