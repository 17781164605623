import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import NumberFormat from 'react-number-format';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField, Button,
} from '@material-ui/core';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import styles from './styles';
import * as excelLibrary from '../../../utilities/excelLibrary';

const TimeValue = ( props ) => {
  const { classes } = props;

  const timeValueDefaultValues = {
    presentValue: 0,
    payments: 0,
    annualRate: 0,
    periods: 0,
    futureValue: 0,
    compounding: 'monthly',
    compoundDays: 12,
  };

  const [timeValueValues, setTimeValueValues] = useState( timeValueDefaultValues );

  const changeCompundInterest = ( e ) => {
    const timeValueValuesData = { ...timeValueValues };
    timeValueValuesData.compounding = e.target.value;

    if ( timeValueValuesData.compounding === 'daily' ) {
      timeValueValuesData.compoundDays = 365;
    } else if ( timeValueValuesData.compounding === 'monthly' ) {
      timeValueValuesData.compoundDays = 12;
    } else if ( timeValueValuesData.compounding === 'semi-annually' ) {
      timeValueValuesData.compoundDays = 2;
    } else {
      timeValueValuesData.compoundDays = 1;
    }
    setTimeValueValues( timeValueValuesData );
  };

  const updateValue = ( e, field ) => {
    const valuesUpdated = { ...timeValueValues };
    // valuesUpdated[field] = e.floatValue ? e.floatValue : 0;
    let newValue = 0;
    if ( field === 'annualRate' ) {
      newValue = e.target.value;
      if(newValue === ''){
        newValue = 0;
      }
    } else {
      newValue = e.floatValue ? e.floatValue : 0;
    }
    
    valuesUpdated[field] = newValue;
    setTimeValueValues( valuesUpdated );
  };

  const handleCalculatePresentValue = () => {
    const valuesUpdated = { ...timeValueValues };

    if ( timeValueValues.presentValue !== undefined && timeValueValues.payments !== undefined && timeValueValues.annualRate !== undefined
      && timeValueValues.periods !== undefined && timeValueValues.futureValue !== undefined && timeValueValues.compounding !== undefined ) {
      const annualRateSecond = ( timeValueValues.annualRate / timeValueValues.compoundDays ) / 100;
      const periodsSecond = timeValueValues.periods;
      const paymentsSecond = -parseInt( timeValueValues.payments, 10 );
      const futureValueSecond = parseInt( timeValueValues.futureValue, 10 );
      const presentValue = -excelLibrary.PV( annualRateSecond, periodsSecond, paymentsSecond, futureValueSecond );
      valuesUpdated.presentValue = presentValue;
    }
    setTimeValueValues( valuesUpdated );
  };

  const handleCalculatePayment = () => {
    const valuesUpdated = { ...timeValueValues };
    if ( timeValueValues.presentValue !== undefined && timeValueValues.payments !== undefined && timeValueValues.annualRate !== undefined
      && timeValueValues.periods !== undefined && timeValueValues.futureValue !== undefined && timeValueValues.compounding !== undefined ) {
      const annualRateSecond = ( timeValueValues.annualRate / timeValueValues.compoundDays ) / 100;
      const periodsSecond = parseInt( timeValueValues.periods, 10 );
      const presentSecond = parseInt( timeValueValues.presentValue, 10 );
      const futureValueSecond = -parseInt( timeValueValues.futureValue, 10 );
      
      const payments = excelLibrary.PMT( annualRateSecond, periodsSecond, -presentSecond, futureValueSecond, 0 );
      valuesUpdated.payments = payments;
    }
    setTimeValueValues( valuesUpdated );
  };

  const handleCalculateAnnualRate = () => {
    const valuesUpdated = { ...timeValueValues };

    if ( timeValueValues.presentValue !== undefined && timeValueValues.payments !== undefined && timeValueValues.annualRate !== undefined && timeValueValues.periods !== undefined
      && timeValueValues.futureValue !== undefined && timeValueValues.compounding !== undefined ) {
      const periodsSecond = parseInt( timeValueValues.periods, 10 );
      const paymentsSecond = parseInt( timeValueValues.payments, 10 );
      const presentSecond = -parseInt( timeValueValues.presentValue, 10 );
      const futureValueSecond = parseInt( timeValueValues.futureValue, 10 );
      valuesUpdated.annualRate = ( excelLibrary.RATE( periodsSecond, -paymentsSecond, -presentSecond, futureValueSecond ) * timeValueValues.compoundDays ) * 100;
      valuesUpdated.annualRate = parseFloat( valuesUpdated.annualRate ).toFixed( 2 );
    }
    setTimeValueValues( valuesUpdated );
  };

  const handleCalculateFutureValue = () => {
    const valuesUpdated = { ...timeValueValues };
    if ( timeValueValues.presentValue !== undefined && timeValueValues.payments !== undefined && timeValueValues.annualRate !== undefined && timeValueValues.periods !== undefined
      && timeValueValues.futureValue !== undefined && timeValueValues.compounding !== undefined ) {
      const annualRateSecond = ( timeValueValues.annualRate / timeValueValues.compoundDays ) / 100;
      const periodsSecond = parseInt( timeValueValues.periods, 10 );
      const paymentsSecond = -parseInt( timeValueValues.payments, 10 );
      const presentSecond = -parseInt( timeValueValues.presentValue, 10 );
      
      const futureValue = excelLibrary.FV( annualRateSecond, periodsSecond, -paymentsSecond, -presentSecond, 0 );
      valuesUpdated.futureValue = futureValue;
    }
    setTimeValueValues( valuesUpdated );
  };

  const setFixedDecimal = ( field ) => {
    const valuesUpdated = { ...timeValueValues };
    valuesUpdated[field] = parseFloat( valuesUpdated[field] ).toFixed( 2 );
    setTimeValueValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Time Value of Money</li>
            </ul>
            <div className={ classes.netWorthSection }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showPortion }>
                  <div className={ classes.nwSection }>
                    <div className={ classes.nwSectionRow }>
                      <div className={ classes.netWorthContent }>
                        <ReactTooltip id="tooltip" place="left" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                        <ReactTooltip id="infotooltip" place="left" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                        <div className={ classes.tableRow }>
                          <table>
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td colSpan="4" className={ classes.textCenter }>
                                  {' '}
                                  <i> Time Value of Money </i>
                                  {' '}
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp; </td>
                                <td className={ classes.moneyInput }>Inputs</td>
                                <td className={ classes.calculateBtn }>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="To calculate, enter all inputs except what you are trying to calculate. For example, trying to figure out what a $30,000 investment earning 6% per year compounding monthly will grow to in 10 years? Enter all details except the future value and then click the calculate button next to future value." />
                                  </div>
                                  Calculate
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="infotooltip" data-tip="The current value of an investment or loan. For example, if you are trying to figure out how much a $10,000 investment will be worth, the present value would be $10,000. If you are trying to calculate the payment for a $20,000 loan, the present value would be $20,000." />
                                  </div>
                                </td>
                                <td>Present Value</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ timeValueValues.presentValue } onValueChange={ ( e ) => updateValue( e, 'presentValue' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  <div>
                                    <Button onClick={ handleCalculatePresentValue }>Calculate</Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img
                                      src={ infoIcon }
                                      alt=""
                                      data-for="infotooltip"
                                      data-tip="The fixed payment needed to grow to a future value or repay a loan. For example, if you are trying to calculate the payment for a $20,000 loan that charges 5% interest over 10 years and the payment is monthly, enter those other details and then calculate the payment. Or,
                                    if you are trying to figure out how to grow your $10,000 investment to $20,000 in 5 years if you earn 7% per year, enter those details and then calculate the payment."
                                    />
                                  </div>
                                </td>
                                <td>Payments</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ timeValueValues.payments } onValueChange={ ( e ) => updateValue( e, 'payments' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  <div>
                                    <Button onClick={ handleCalculatePayment }>Calculate</Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="infotooltip" data-tip="Enter the annual percentage rate of return or interest rate on a loan. If you are working on an investment, this input would be the expected rate of return on that investment. If you are working on a loan, this would be the annual interest rate or annual percentage rate (APR)." />
                                  </div>
                                </td>
                                <td>Annual Rate %</td>
                                <td>
                                  <span className={ classes.percentSymbol }>%</span>
								  <NumberFormat
                                    className={classes.formInput}
                                    allowNegative={false}
                                    customInput={TextField}
                                    value={timeValueValues.annualRate}
                                    onChange={ ( e ) => updateValue( e, 'annualRate' ) }
                                    onBlur={ () => { setFixedDecimal( 'annualRate' ); } }
                                    onFocus={ ( e ) => e.target.select() }
                                  />
                                  
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  <div>
                                    <Button onClick={ handleCalculateAnnualRate }>Calculate</Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img
                                      src={ infoIcon }
                                      alt=""
                                      data-for="infotooltip"
                                      data-tip="The number of periods you are holding an investment for or the amount of time you have to repay a loan. If that investments pays you monthly, five years would equal 60 months or 60 periods. You would enter 60 and update the compounding to monthly. Same for a loan. If a loan requires a semi-annual payment over 10 years, you would enter 20 periods,
                                     or 10 years multiplied by 2 payments a year equals 20 periods. Then, you'd update the compounding to semi-annual."
                                    />
                                  </div>
                                </td>
                                <td>Periods</td>
                                <td>
                                  <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ timeValueValues.periods } onValueChange={ ( e ) => updateValue( e, 'periods' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  <div>
                                    <Button>Calculate</Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="infotooltip" data-tip="The future value of an investment or a loan. If you are trying to calculate what an investment might grow to, enter all other inputs and then calculate the future value. If you are working on a loan repayment, the future value for a loan will be $0, assuming that you repay that loan in full." />
                                  </div>
                                </td>
                                <td>Future Value</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat className={ classes.formInput } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ timeValueValues.futureValue } onValueChange={ ( e ) => updateValue( e, 'futureValue' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  <div>
                                    <Button onClick={ handleCalculateFutureValue }>Calculate</Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyRow } />
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img
                                      src={ infoIcon }
                                      alt=""
                                      data-for="infotooltip"
                                      data-tip="Selecting the right compounding period is essential for this calculator to run. To get this right, ask yourself, 'When do I make payments or when do I expect payments to be made to me?' For a loan, if you make payments every month, 1 period would equal 1 month. Therefore, 30 years would equal 360 months.
                                     For an investment, you need to know what the compounding schedule is for the investment. If you're not sure, just leave it as monthly."
                                    />
                                  </div>
                                </td>
                                <td>Compounding</td>
                                <td className={ classes.cpSelect }>
                                  <span>&nbsp;</span>
                                  <select className={ classes.compoundSelect } value={ timeValueValues.compounding } onChange={ changeCompundInterest }>
                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="semi-annually">Semi-annually</option>
                                    <option value="annually">Annually</option>
                                  </select>
                                </td>
                                <td className={ classes.timeValueMoney }>
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

TimeValue.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( TimeValue );
