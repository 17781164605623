import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as moduleServices from '../../calculations/modules-services';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-career-path/styles';

const inputElementArray = ['Rent', 'Utilities', 'Food', 'Clothing', 'Entertainment', 'Technology', 'Transportation', 'Miscellaneous', 'Healthcare Costs other than Premium', 'Health Insurance Premium'];

const CareerBottom = (props) => {
  const { classes, handleCareerObject } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState(false);
  const [otherExpenses, setOtherExpenses] = React.useState(false);
  const [tableObj, setTableObj] = React.useState({});

 
  const individualLivingExpensesCalc = function () {
    let incomeSheetData = moduleServices.incomeStatementCompleteData();
    let valueData = {};
    if (incomeSheetData !== undefined && incomeSheetData !== '') {
      inputElementArray.forEach(element => {
        if (incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== undefined && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== ''
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== '') {
          valueData[element] = incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element];
        } else {
          valueData[element] = 0
        }

        let yearsAmount = 0;
        let startYear = parseInt(handleCareerObject.start_year);
        let endYear = parseInt(handleCareerObject.end_year);
        for (let i = startYear; i <= endYear; i++) {
          if (incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i]['livingExpenses'] !== undefined && incomeSheetData[i]['livingExpenses'] !== ''
            && incomeSheetData[i]['livingExpenses'][element] !== undefined && incomeSheetData[i]['livingExpenses'][element] !== '') {
            yearsAmount = yearsAmount + incomeSheetData[i]['livingExpenses'][element];
            valueData[element + 'year'] = yearsAmount;
          }
        }
        valueData[element + 'year'] = yearsAmount;
      });

    }
    setTableObj(valueData);
  }

  const d = new Date();
  useEffect(() => {
    individualLivingExpensesCalc();
    // eslint-disable-next-line
  },[handleCareerObject]);


  return (

    <div className={classNames(classes.vacationTable, classes.budgetTable)}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>
            {parseInt(handleCareerObject.start_year) > 0 ? parseInt(handleCareerObject.start_year) : d.getFullYear()}
              {' '}
              -
              {' '}
              {parseInt(handleCareerObject.end_year) > 0 ? parseInt(handleCareerObject.end_year) : d.getFullYear()+(2-1) }
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.totalMonthlyIncomeCareerPath) && moduleServices.module4Data.totalMonthlyIncomeCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.totalMonthlyIncomeCareerPath} prefix={moduleServices.module4Data.totalMonthlyIncomeCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalMonthlyIncomeCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.totalIncomeCareerPath) && moduleServices.module4Data.totalIncomeCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.totalIncomeCareerPath} prefix={moduleServices.module4Data.totalIncomeCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalIncomeCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.incomeEarned) && moduleServices.module4Data.incomeEarned !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.incomeEarned} prefix={moduleServices.module4Data.incomeEarned >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.incomeEarned < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Total Taxes Paid
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.totalTaxesPaidCareerPath) && moduleServices.module4Data.totalTaxesPaidCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.totalTaxesPaidCareerPath} prefix={moduleServices.module4Data.totalTaxesPaidCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalTaxesPaidCareerPath < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.annualTotalTaxesPaidCareerPath) && moduleServices.module4Data.annualTotalTaxesPaidCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.annualTotalTaxesPaidCareerPath} prefix={moduleServices.module4Data.annualTotalTaxesPaidCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.annualTotalTaxesPaidCareerPath < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.estimatedTaxesPaid) && moduleServices.module4Data.estimatedTaxesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.estimatedTaxesPaid} prefix={moduleServices.module4Data.estimatedTaxesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.estimatedTaxesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classNames(classes.livingRow, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setExpandLivingExpenses(!expandLivingExpenses)}}>
            <td>
              <span>
                {!expandLivingExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {expandLivingExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Living Expenses</b>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.livingExpensesCareerPath) && moduleServices.module4Data.livingExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.livingExpensesCareerPath} prefix={moduleServices.module4Data.livingExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.livingExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.careerPathLivingExpenses) && moduleServices.module4Data.careerPathLivingExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathLivingExpenses} prefix={moduleServices.module4Data.careerPathLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathLivingExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.livingExpensesPaid) && moduleServices.module4Data.livingExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.livingExpensesPaid} prefix={moduleServices.module4Data.livingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.livingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Rent
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent / 12} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rentyear} prefix={tableObj.Rentyear >= 0 ? '$' : '($'} suffix={tableObj.Rentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Utilities
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities / 12} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilitiesyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilitiesyear} prefix={tableObj.Utilitiesyear >= 0 ? '$' : '($'} suffix={tableObj.Utilitiesyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Food
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food / 12} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Foodyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Foodyear} prefix={tableObj.Foodyear >= 0 ? '$' : '($'} suffix={tableObj.Foodyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Clothing & Personal Care
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing / 12} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothingyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothingyear} prefix={tableObj.Clothingyear >= 0 ? '$' : '($'} suffix={tableObj.Clothingyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Entertainment
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment / 12} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainmentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainmentyear} prefix={tableObj.Entertainmentyear >= 0 ? '$' : '($'} suffix={tableObj.Entertainmentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Technology
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology / 12} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technologyyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technologyyear} prefix={tableObj.Technologyyear >= 0 ? '$' : '($'} suffix={tableObj.Technologyyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Transportation
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation / 12} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportationyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportationyear} prefix={tableObj.Transportationyear >= 0 ? '$' : '($'} suffix={tableObj.Transportationyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Miscellaneous
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous / 12} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneousyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneousyear} prefix={tableObj.Miscellaneousyear >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneousyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Costs outside Premium
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium'] / 12} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium']} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premiumyear']} prefix={tableObj['Healthcare Costs other than Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Premium
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium'] / 12} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium']} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premiumyear']} prefix={tableObj['Health Insurance Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classNames(classes.otherExpenses, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setOtherExpenses(!otherExpenses)}}>
            <td>
              <span>
                {!otherExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {otherExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Other Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module4Data.careerOtherLivingExpensesCareerPath) && moduleServices.module4Data.careerOtherLivingExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module4Data.careerOtherLivingExpensesCareerPath}
                    prefix={moduleServices.module4Data.careerOtherLivingExpensesCareerPath >= 0 ? '$' : '($'}
                    suffix={moduleServices.module4Data.careerOtherLivingExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module4Data.careerOtherAnnaulLivingExpensesCareerPath) && moduleServices.module4Data.careerOtherAnnaulLivingExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module4Data.careerOtherAnnaulLivingExpensesCareerPath} prefix={moduleServices.module4Data.careerOtherAnnaulLivingExpensesCareerPath >= 0 ? '$' : '($'}
                    suffix={moduleServices.module4Data.careerOtherAnnaulLivingExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { !isNaN(moduleServices.module4Data.careerOtherEducationLivingExpensesPaid) && moduleServices.module4Data.careerOtherEducationLivingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerOtherEducationLivingExpensesPaid} prefix={moduleServices.module4Data.careerOtherEducationLivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerOtherEducationLivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Higher Education Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.higherEducationExpensesCareerPath) && moduleServices.module4Data.higherEducationExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.higherEducationExpensesCareerPath} prefix={moduleServices.module4Data.higherEducationExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.higherEducationExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.careerPathHigherEducationExpenses) && moduleServices.module4Data.careerPathHigherEducationExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathHigherEducationExpenses} prefix={moduleServices.module4Data.careerPathHigherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathHigherEducationExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.higherEducationExpensesPaid) && moduleServices.module4Data.higherEducationExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.higherEducationExpensesPaid} prefix={moduleServices.module4Data.higherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.higherEducationExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Children Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.childrenExpensesCareerPath) && moduleServices.module4Data.childrenExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.childrenExpensesCareerPath} prefix={moduleServices.module4Data.childrenExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.childrenExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.careerPathChildrenExpenses) && moduleServices.module4Data.careerPathChildrenExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathChildrenExpenses} prefix={moduleServices.module4Data.careerPathChildrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathChildrenExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.childrenExpensesPaid) && moduleServices.module4Data.childrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.childrenExpensesPaid} prefix={moduleServices.module4Data.childrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.childrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Pet Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.petExpensesCareerPath) && moduleServices.module4Data.petExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.petExpensesCareerPath} prefix={moduleServices.module4Data.petExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.petExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.careerPathPetExpenses) && moduleServices.module4Data.careerPathPetExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathPetExpenses} prefix={moduleServices.module4Data.careerPathPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathPetExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.petExpensesPaid) && moduleServices.module4Data.petExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.petExpensesPaid} prefix={moduleServices.module4Data.petExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.petExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Car Expenses
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.carExpensesCareerPath) && moduleServices.module4Data.carExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.carExpensesCareerPath} prefix={moduleServices.module4Data.carExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.carExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.careerPathCarExpenses) && moduleServices.module4Data.careerPathCarExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathCarExpenses} prefix={moduleServices.module4Data.careerPathCarExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathCarExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { !isNaN(moduleServices.module4Data.carExpensesPaid) && moduleServices.module4Data.carExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.carExpensesPaid} prefix={moduleServices.module4Data.carExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.carExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Real Estate Expenses
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module4Data.realEstateExpensesCareerPath) && moduleServices.module4Data.realEstateExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.realEstateExpensesCareerPath} prefix={moduleServices.module4Data.realEstateExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.realEstateExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module4Data.careerPathHomeExpenses) && moduleServices.module4Data.careerPathHomeExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathHomeExpenses} prefix={moduleServices.module4Data.careerPathHomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathHomeExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { !isNaN(moduleServices.module4Data.realEstateExpensesPaid) && moduleServices.module4Data.realEstateExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.realEstateExpensesPaid} prefix={moduleServices.module4Data.realEstateExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.realEstateExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Loan Payments
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.internetExpensesCareerPath) && moduleServices.module4Data.internetExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.internetExpensesCareerPath} prefix={moduleServices.module4Data.internetExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.internetExpensesCareerPath < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.careerPathFinancingExpenses) && moduleServices.module4Data.careerPathFinancingExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathFinancingExpenses} prefix={moduleServices.module4Data.careerPathFinancingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathFinancingExpenses < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { !isNaN(moduleServices.module4Data.financingExpensesPaid) && moduleServices.module4Data.financingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.financingExpensesPaid} prefix={moduleServices.module4Data.financingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.financingExpensesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Total Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.totalMonthlyExpensesCareerPath) && moduleServices.module4Data.totalMonthlyExpensesCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.totalMonthlyExpensesCareerPath} prefix={moduleServices.module4Data.totalMonthlyExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalMonthlyExpensesCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.careerPathTotalAnnualExpenses) && moduleServices.module4Data.careerPathTotalAnnualExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathTotalAnnualExpenses} prefix={moduleServices.module4Data.careerPathTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathTotalAnnualExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.totalExpensesPaid) && moduleServices.module4Data.totalExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.totalExpensesPaid} prefix={moduleServices.module4Data.totalExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.totalExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.monthlyNetIncomeCareerPath) && moduleServices.module4Data.monthlyNetIncomeCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.monthlyNetIncomeCareerPath} prefix={moduleServices.module4Data.monthlyNetIncomeCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.monthlyNetIncomeCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.careerPathAnnualNetIncome) && moduleServices.module4Data.careerPathAnnualNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathAnnualNetIncome} prefix={moduleServices.module4Data.careerPathAnnualNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathAnnualNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { !isNaN(moduleServices.module4Data.netIncomePaid) && moduleServices.module4Data.netIncomePaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.netIncomePaid} prefix={moduleServices.module4Data.netIncomePaid >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.netIncomePaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.retirementContributionsCareerPath) && moduleServices.module4Data.retirementContributionsCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.retirementContributionsCareerPath} prefix={moduleServices.module4Data.retirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.retirementContributionsCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.annualRetirementContributionsCareerPath) && moduleServices.module4Data.annualRetirementContributionsCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.annualRetirementContributionsCareerPath} prefix={moduleServices.module4Data.annualRetirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.annualRetirementContributionsCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.finalRetirementContributionsCareerPath) && moduleServices.module4Data.finalRetirementContributionsCareerPath !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.finalRetirementContributionsCareerPath} prefix={moduleServices.module4Data.finalRetirementContributionsCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.finalRetirementContributionsCareerPath < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>HSA Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.careerPathHSAContributions) && moduleServices.module4Data.careerPathHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathHSAContributions} prefix={moduleServices.module4Data.careerPathHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.careerPathHSAContributions) && moduleServices.module4Data.careerPathHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathHSAContributions} prefix={moduleServices.module4Data.careerPathHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { !isNaN(moduleServices.module4Data.finalCareerPathHSAContributions) && moduleServices.module4Data.finalCareerPathHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module4Data.finalCareerPathHSAContributions} prefix={moduleServices.module4Data.finalCareerPathHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.finalCareerPathHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>

          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i><b>% of Total Income</b></i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module4Data.totalMonthlyIncomeCareerPath) && moduleServices.module4Data.totalMonthlyIncomeCareerPath !== 0 && !isNaN(moduleServices.module4Data.monthlyNetIncomeCareerPath) && moduleServices.module4Data.monthlyNetIncomeCareerPath !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module4Data.totalMonthlyIncomeCareerPath / moduleServices.module4Data.monthlyNetIncomeCareerPath) * 100} prefix={moduleServices.module4Data.totalMonthlyIncomeCareerPath / moduleServices.module4Data.monthlyNetIncomeCareerPath >= 0 ? '' : '('} suffix={moduleServices.module4Data.totalMonthlyIncomeCareerPath / moduleServices.module4Data.monthlyNetIncomeCareerPath < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module4Data.totalIncomeCareerPath) && moduleServices.module4Data.totalIncomeCareerPath !== 0 && !isNaN(moduleServices.module4Data.careerPathAnnualNetIncome) && moduleServices.module4Data.careerPathAnnualNetIncome !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module4Data.totalIncomeCareerPath / moduleServices.module4Data.careerPathAnnualNetIncome) * 100} prefix={moduleServices.module4Data.totalIncomeCareerPath / moduleServices.module4Data.careerPathAnnualNetIncome >= 0 ? '' : '('} suffix={moduleServices.module4Data.totalIncomeCareerPath / moduleServices.module4Data.careerPathAnnualNetIncome < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( !isNaN(moduleServices.module4Data.incomeEarned) &&  !isNaN(moduleServices.module4Data.incomeEarned) && moduleServices.module4Data.incomeEarned !== 0 &&  !isNaN(moduleServices.module4Data.finalNetCashFlowCareerPath) &&  !isNaN(moduleServices.module4Data.finalNetCashFlowCareerPath) && moduleServices.module4Data.finalNetCashFlowCareerPath !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module4Data.finalNetCashFlowCareerPath / moduleServices.module4Data.incomeEarned) * 100} prefix={moduleServices.module4Data.finalNetCashFlowCareerPath / moduleServices.module4Data.incomeEarned >= 0 ? '' : '('} suffix={moduleServices.module4Data.incomeEarned / moduleServices.module4Data.netIncomePaid < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CareerBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};
export default withStyles(styles)(CareerBottom);
