import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import logo from '../../../assets/img/logo.png';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';


const HigherEducationIncome = ( props ) => {
  const {
    classes,setHigherEduIncm,higherEduIncm
  } = props;
  return (
    <Typography component="div">
       <Dialog
        open={ higherEduIncm }
        onClose={ ()=>{setHigherEduIncm(false)} }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.payBackModal }
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
      <div className={ classes.payBackModule }>
        <div className={ classes.modalHeader }>
          <div className={ classes.modalLogo }><img src={ logo } alt="logo" /></div>           
          <Button className={ classes.simulatorClose }>x</Button>
        </div>
        <div className={ classes.modalBody }>
            <div className={ classes.tableResponsive }>
              <p className={ classes.borderStrip }>Higher Education - Student Loan Payback Schedule</p>
              <div className={ classes.paybackTables }>
                <div className={ classes.paybackPopup }>
                  <table className={ classes.firstTable }>
                    <tbody>
                      <tr>
                        <td colSpan="9" className={ classes.tableHead }>Private Student Loan</td>
                      </tr>
                      <tr> <td colSpan="9">&nbsp;</td> </tr>
                      <tr className={ classes.loanDetailsTable }>
                        <td colSpan="4" className={ classes.valueOne }>
                          <table className={ classes.loansTaken }>
                              <tbody>
                                <tr>
                                  <td>Loans Taken ($)</td>
                                  <td><span>$16,000</span></td>
                                </tr>
                                <tr className={ classes.borderLine }>
                                  <td>Accrued Interest ($)</td>
                                  <td><span>$0</span></td>
                                </tr>
                                <tr>
                                  <td>Beginning Balance ($)</td>
                                  <td><span>$16,000</span></td>
                                </tr>
                                <tr>
                                  <td>Term in Years (#)</td>
                                  <td><span>10</span></td>
                                </tr>
                                <tr>
                                  <td>Annual Interest Rate (%)</td>
                                  <td><span>9.50%</span></td>
                                </tr>
                                <tr>
                                  <td>Monthly Payment ($)</td>
                                  <td><span>$207</span></td>
                                </tr>
                              </tbody>
                          </table>
                        </td>
                        <td colSpan="1" className={ classes.valueTwo }></td>
                        <td colSpan="4" className={ classes.valueThree }>
                          <table>
                            <tbody>
                              <tr>
                                <td>Total Interest Paid</td>
                                <td><span>$11,884</span></td>
                              </tr>
                               <tr>
                                <td>Total Paid</td>
                                <td><span>$11,884</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr> <td colSpan="9">&nbsp;</td> </tr>
                      <tr>
                        <td colSpan="12">
                          <i>Note: Student Loans allow for a Grace Period (typically 6 months) after Graduation / Leaving School before repayment begins. 
                            So, the first and last year of repayment only have 6 months.</i>
                        </td>
                      </tr>
                      <tr> <td colSpan="9">&nbsp;</td> </tr>
                    </tbody> 
                  </table>
                  <div className={ classes.fixedData }>
                    <table>
                      <thead>
                        <tr className={ classes.blueRow }>
                          <td colSpan="9">
                            <table>
                              <tbody>
                              <tr>                              
                                <td className={ classes.firstChild}>Year</td> 
                                <td className={ classes.secondChild}>Month</td>
                                <td className={ classes.thirdChild}>Beginning Balance</td>
                                <td className={ classes.commonChild}>Payment</td> 
                                <td className={ classes.commonChild}>Principal</td> 
                                <td className={ classes.fifthChild}>Interest</td> 
                                <td className={ classes.sixChild}>Total Principal</td> 
                                <td className={ classes.thirdChild}>Total Interest</td> 
                                <td className={ classes.commonChild}>Total Payments</td>
                              </tr>                              
                              </tbody>                               
                            </table>
                          </td>
                        </tr>
                        <tr className={ classes.blackBorder }> <td colSpan="9">&nbsp;</td> </tr>
                      </thead>
                      <tbody>
                        <tr className={ classes.valuesRow }>
                          <td colSpan="9">
                            <table calssName={ classes.repeatTable}>
                              <tbody>
                                <tr>
                                  <td>2021</td>
                                  <td>1</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>2</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>3</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>4</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                                <tr>
                                  <td>2022</td>
                                  <td>1</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>4</td>
                                  <td>$16,000</td>
                                  <td>$207</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$80</td>
                                  <td>$127</td>
                                  <td>$207</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>      
      </div>
      </DialogContent>
      <DialogActions>
          <div className={ classes.modalFooter }>            
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};

HigherEducationIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  setHigherEduIncm: PropTypes.object.isRequired,
  higherEduIncm: PropTypes.bool.isRequired,

};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( HigherEducationIncome );
