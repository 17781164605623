import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as moduleServices from '../../calculations/modules-services';
// import CareerBottomTabs from './career-bottom-tab-graphs';
import styles from '../../screens/pwi-career-path/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];
const PensionContributions = ( props ) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;
  const [personalValue, setPeronalValue] = React.useState( 7.00 );
  // const [macthingValue, setMatchingValue] = React.useState( 7 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.pension_annual_expected_pre_tax !== undefined ) {
      setPeronalValue( handleCareerObject.pension_annual_expected_pre_tax );
    } else {
      updatedValues['pension_annual_expected_pre_tax'] = 7.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    setPeronalValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };

  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>How much do you expect to earn on your pension contributions? Enter your annual return below.</p>
      <div className={ classes.livingExpencesRow }>
        <div className={ classes.livingExpencesDiv }>
          <div className={ classes.livingExpences }>
            <div className={ `${classes.livingExpencesRangeFlex} ${classes.livingExpencesRangeFlexNewPensionCont} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }>
              <div className={ classes.rentGraphBlock }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew} ` }>
                  <p><span>Annual Expected Pre-Tax Return</span></p>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValuesMatching }
                      min={ 0 }
                      max={ 15 }
                      step={ 0.1 }
                      value={ personalValue }
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( personalValue ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'pension_annual_expected_pre_tax', 'slider' ) }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      decimalScale = { isFocus!=='pension_annual_expected_pre_tax'?2:5 }
                      fixedDecimalScale={isFocus!=='pension_annual_expected_pre_tax'}
                      value={ personalValue }
                      onValueChange={ ( e ) => updateValue( e, '', 'pension_annual_expected_pre_tax', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('pension_annual_expected_pre_tax')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70New}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead class="">
              <tr className={ classes.trHead }>
                <td colspan="2">Pension Summary</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Beginning Pension Balance
                </td>
                <td>
                  {moduleServices.careerPathPensionBeginningBalance() !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.careerPathPensionBeginningBalance()} prefix={moduleServices.careerPathPensionBeginningBalance() >= 0 ? '$' : '($'} suffix={moduleServices.careerPathPensionBeginningBalance() < 0 && ')'} />
                  ) : ('$0')}
                </td>
              </tr>
              <tr>
                <td>
                  Total Personal Contributions
                </td>
                <td>
                  <span>
                    {moduleServices.careerPathPensionTotalPersonalContributions() !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.careerPathPensionTotalPersonalContributions()} prefix={moduleServices.careerPathPensionTotalPersonalContributions() >= 0 ? '$' : '($'} suffix={moduleServices.careerPathPensionTotalPersonalContributions() < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Employer Contributions
                </td>
                <td>
                  <span>
                    {moduleServices.careerPathPensionTotalEmployerContributions() !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.careerPathPensionTotalEmployerContributions()} prefix={moduleServices.careerPathPensionTotalEmployerContributions() >= 0 ? '$' : '($'} suffix={moduleServices.careerPathPensionTotalEmployerContributions() < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Earnings
                </td>
                <td>
                  <span>                    
                    {moduleServices.careerPathPensionTotalEarnings() !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.careerPathPensionTotalEarnings()} prefix={moduleServices.careerPathPensionTotalEarnings() >= 0 ? '$' : '($'} suffix={moduleServices.careerPathPensionTotalEarnings() < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                <td>
                  Ending Pension Balance
                </td>
                <td>
                  <span>                                            
                    {moduleServices.careerPathEndingPensionBalance() !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.careerPathEndingPensionBalance()} prefix={moduleServices.careerPathEndingPensionBalance() >= 0 ? '$' : '($'} suffix={moduleServices.careerPathEndingPensionBalance() < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

PensionContributions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( PensionContributions );
