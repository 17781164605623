import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from '../../../screens/pwi/more-higher-education/styles';
import * as moduleServices from '../../../calculations/modules-services';


Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
  colors: ['#5B9BD5', '#ED7D31', '#A5A5A5', '#FFC000', '#4472C4', '#70AD47', '#255E91', '#9E480E', '#264488', '#43682B', '#264478', '#5E9DD5', '#E9F2E9', '#6EA6D9', '#4F7239'],
} );
HCExporting( Highcharts );

const LivingExpenses = ( props ) => {
  const {
    classes, inputData
  } = props;


  const [value, setValue] = useState( 0 );
  const [graphData, setGraphData] = useState({});

  useEffect(()=>{
    
    moduleServices.moreHigherEducationOutputInitialize();

    const higherEducationOutputData = moduleServices.moreInvestmentOutputBackendCompleteData();

    const graphValues = {};

    let higherEducationOutputPieChartData   = {};
    let totalHigherEducationValues = 0;
    
    if(value === 0) {
      higherEducationOutputPieChartData       = higherEducationOutputData['Pie Chart'];

      const annualGraphValues = higherEducationOutputData['Annual Graph'][inputData.start_year];

      higherEducationOutputPieChartData['totalCollegeValue'] = annualGraphValues['Higher Education Room Board'];
      higherEducationOutputPieChartData['totalRentValue'] = annualGraphValues['Rent'];
      higherEducationOutputPieChartData['totalUtilitiesValue'] = annualGraphValues['Utilities'];
      higherEducationOutputPieChartData['totalFoodValue'] = annualGraphValues['Food'];
      higherEducationOutputPieChartData['totalClothingValue'] = annualGraphValues['Clothing'];
      higherEducationOutputPieChartData['totalEntertainmentValue'] = annualGraphValues['Entertainment'];
      higherEducationOutputPieChartData['totalTechnologyValue'] = annualGraphValues['Technology'];
      higherEducationOutputPieChartData['totalTransportationValue'] = annualGraphValues['Transportation'];
      higherEducationOutputPieChartData['totalMiscellaneousValue'] = annualGraphValues['Miscellaneous'];
      higherEducationOutputPieChartData['totalCarValue'] = annualGraphValues['Car'];
      higherEducationOutputPieChartData['totalGasValue'] = annualGraphValues['Gas'];
      higherEducationOutputPieChartData['totalHealthInsuranceValue'] = annualGraphValues['Health Insurance'];
      graphValues['total'] = annualGraphValues['Total']
    } else {
      higherEducationOutputPieChartData       = higherEducationOutputData['Pie Chart'];
      totalHigherEducationValues   = higherEducationOutputPieChartData['Total'];

      graphValues['total'] = totalHigherEducationValues;

      if(inputData.start_year !== undefined && inputData.start_year > 0 && inputData.years_diff !== undefined && inputData.years_diff > 0) {
        graphValues['years_point'] =  inputData.start_year+'-';
        graphValues['years_point'] += (inputData.start_year+inputData.years_diff-1)
      }
    }

    let educationTitle = '';
    switch(inputData.type_of_higher_education) {
      case 1:
        educationTitle = 'Career School';
        break;
      case 2:
        educationTitle = 'College';
        break;
      case 3:
        educationTitle = 'Graduate School';
        break;
      case 4:
        educationTitle = 'Military School';
        break;
       default:
         break
    }

    graphValues['options'] = {
        chart: {
            plotBackgroundColor: '#ffffff',
            plotShadow: false,
            type: 'pie',
            marginTop: 25
        },
        navigation: {
            menuItemStyle: {
              fontSize: '14px',
              textAlign: 'left'
            },
            menuItemHoverStyle: {
              background: '#f5f5f5',
              color: '#4c4c4c',
              fontSize: '14px'
              },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: 'y'
            }
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [{
                  textKey: 'downloadPNG',
                  onclick: function () {
                    this.exportChart();
                  },
                }, {
                  textKey: 'downloadJPEG',
                  onclick: function () {
                    this.exportChart({
                      type: 'image/jpeg'
                    });
                  }
                },{
                  textKey: 'downloadPDF',
                  onclick: function () {
                    this.exportChart({
                      type: 'application/pdf'
                    });
                  }
                }, {
                  textKey: 'downloadSVG',
                  onclick: function () {
                    this.exportChart({
                      type: 'image/svg+xml'
                    });
                  }
                }]
              }
            }
            },
            credits: {
                enabled: false
            },
            title: {
                text: '',
            },
            tooltip: {
                // eslint-disable-next-line
                pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        // eslint-disable-next-line
                        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                            fontSize: '14px'
                        }
                    }
                }
            },
            series: [{
                name: educationTitle,
                type: 'pie',      
                data: [
                ['Room & Board',   higherEducationOutputPieChartData['totalCollegeValue']],    
                ['Rent',   higherEducationOutputPieChartData['totalRentValue']],
                ['Utilities',   higherEducationOutputPieChartData['totalUtilitiesValue']],
                ['Food',   higherEducationOutputPieChartData['totalFoodValue']],
                ['Clothing & Personal Care',   higherEducationOutputPieChartData['totalClothingValue']],
                ['Entertainment',   higherEducationOutputPieChartData['totalEntertainmentValue']],
                ['Technology',   higherEducationOutputPieChartData['totalTechnologyValue']],
                ['Transportation',   higherEducationOutputPieChartData['totalTransportationValue']],
                ['Miscellaneous',   higherEducationOutputPieChartData['totalMiscellaneousValue']],
                ['Car',   higherEducationOutputPieChartData['totalCarValue']],
                ['Gas',   higherEducationOutputPieChartData['totalGasValue']],
                ['Health Insurance',   higherEducationOutputPieChartData['totalHealthInsuranceValue']],
                ].filter(function(d) {
                    return d[1] > 0
                })
            }]
        };
        setGraphData(graphValues)
  },[value])

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
      Take a look the pie chart below and make sure that your spending is in line with your priorities. If you need to make changes, go back to the previous slide. If all looks good, hit next to continue.
      </p>

      <div className={ classes.tabSection }>
        <Tabs
          value={ value }
          onChange={ handleChange }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={ classes.tabButtons }
          aria-label="full width tabs example"
        >
          <Tab className={ value === 0 ? classes.activeTab : '' } label="Annual" />
          <Tab className={ value === 1 ? classes.activeTab : '' } label="Total" />

        </Tabs>
        <div className={ `${classes.tabsContent} ${classes.graphBoxNew} ${classes.graphBoxNewGraph}` }>
          {value === 0 && (
            <div>
              <div className={ classes.graphBox }>
                <h3>Annual Living Expenses</h3>
                <HighchartsReact
                  highcharts={ Highcharts }
                  options={ graphData['options'] !== undefined && graphData['options'] }
                />
              </div>
              <h4 className={ classes.totalValues }>Annual Living Expenses:{' '} 
                <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ graphData['total'] } prefix={ graphData['total'] >= 0 ? '$' : '($' } suffix={ graphData['total'] < 0 && ')' } />
              </h4>
            </div>
          )}
          {
            value === 1 && (
              <div>
                <h3>Living Expenses During College</h3>
                <HighchartsReact
                  highcharts={ Highcharts }
                  options={ graphData['options'] !== undefined && graphData['options'] }
                />
                <h4 className={ classes.totalValues }>Total Living Expenses from {' '}{graphData['years_point'] !== undefined && graphData['years_point']}:{' '} 
                  <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ graphData['total'] } prefix={ graphData['total'] >= 0 ? '$' : '($' } suffix={ graphData['total'] < 0 && ')' } />
                </h4>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

LivingExpenses.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
};

export default withStyles( styles )( LivingExpenses );
