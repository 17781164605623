import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';
import Gallery from 'react-grid-gallery';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import InkwiryBanner from '../../assets/img/benfits/inkwiry-benefits-banner.jpg';
import Benfit1 from '../../assets/img/benfits/show_me_benefits_img_1_popup.jpg';
import Benfit2 from '../../assets/img/benfits/show_me_benefits_img_2_popup.jpg';
import Benfit3 from '../../assets/img/benfits/show_me_benefits_img_3_popup.jpg';
import Benfit4 from '../../assets/img/benfits/show_me_benefits_img_4_popup.jpg';
import Benfit5 from '../../assets/img/benfits/show_me_benefits_img_5_popup.jpg';
import Benfit6 from '../../assets/img/benfits/show_me_benefits_img_6_popup.jpg';
import Benfit7 from '../../assets/img/benfits/show_me_benefits_img_7_popup.jpg';
import Benfit8 from '../../assets/img/benfits/show_me_benefits_img_8_popup.jpg';
import Benfit9 from '../../assets/img/benfits/show_me_benefits_img_9_popup.jpg';
import Benfit10 from '../../assets/img/benfits/show_me_benefits_img_10_popup.jpg';
import Benfit11 from '../../assets/img/benfits/show_me_benefits_img_11_popup.jpg';
import Benfit12 from '../../assets/img/benfits/show_me_benefits_img_12_popup.jpg';
import Benfit13 from '../../assets/img/benfits/show_me_benefits_img_13_popup.jpg';
import Benfit14 from '../../assets/img/benfits/show_me_benefits_img_14_popup.jpg';
import Benfit15 from '../../assets/img/benfits/show_me_benefits_img_15_popup.jpg';
import Benfit16 from '../../assets/img/benfits/show_me_benefits_img_16_popup.jpg';
import Benfit17 from '../../assets/img/benfits/show_me_benefits_img_17_popup.jpg';
import Benfit18 from '../../assets/img/benfits/show_me_benefits_img_18_popup.jpg';
import Benfit19 from '../../assets/img/benfits/show_me_benefits_img_19_popup.jpg';
import Benfit20 from '../../assets/img/benfits/show_me_benefits_img_20_popup.jpg';

const IMAGES = [{
  src: Benfit1,
  thumbnail: Benfit1,
},
{
  src: Benfit2,
  thumbnail: Benfit2,
},

{
  src: Benfit3,
  thumbnail: Benfit3,
},
{
  src: Benfit4,
  thumbnail: Benfit4,
},
{
  src: Benfit5,
  thumbnail: Benfit5,
},
{
  src: Benfit6,
  thumbnail: Benfit6,
},

{
  src: Benfit7,
  thumbnail: Benfit7,
},
{
  src: Benfit8,
  thumbnail: Benfit8,
},
{
  src: Benfit9,
  thumbnail: Benfit9,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit10,
  thumbnail: Benfit10,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},

{
  src: Benfit11,
  thumbnail: Benfit11,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit12,
  thumbnail: Benfit12,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit13,
  thumbnail: Benfit13,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit14,
  thumbnail: Benfit14,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},

{
  src: Benfit15,
  thumbnail: Benfit15,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit16,
  thumbnail: Benfit16,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit17,
  thumbnail: Benfit17,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit18,
  thumbnail: Benfit18,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},

{
  src: Benfit19,
  thumbnail: Benfit19,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
},
{
  src: Benfit20,
  thumbnail: Benfit20,
  thumbnailWidth: 200,
  thumbnailHeight: 200,
}];

const InkwiryBenfits = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.inkwiryBenfitsBannerOne }>
        <img src={ InkwiryBanner } alt="Benfits" />
        <Typography variant="h3">Show Me the Benefits</Typography>
      </div>
      <div className={ classes.benfitsContent }>
        <Container classsname={ classes.container }>
          <Typography variant="h2">The Value of Inkwiry Lasts a Lifetime</Typography>
          <Typography variant="h3">
            Step by step, building critical thinking skills,
            <span>
              powerful strategies and inspiring self-learning.

            </span>
          </Typography>
        </Container>
      </div>
      <Container classsName={ classes.container }>
        <div className={ classes.galleryRow }>
          <Gallery
            images={ IMAGES }
            className={ classes.galleyPopupBox }
            backdropClosesModal
            showImageCount={ false }
            tagStyle={
            {
              padding: '0 19px 20px 19px',
              background: 'transparent',
            }
          }
            enableImageSelection={ false }
          />
        </div>
      </Container>
      <div className={ classes.showBenfit }>

        {/* <Container classsName={ classes.container }>
          <Grid container>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit1 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit2 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit3 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit4 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit5 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit6 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit7 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit8 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit9 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit10 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit11 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit12 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit13 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit14 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit15 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit16 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit17 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit18 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit19 } alt="" />
                </figure>
              </div>
            </Grid>
            <Grid item sm={ 4 }>
              <div className={ classes.benfitBox }>
                <figure>
                  <img src={ Benfit20 } alt="" />
                </figure>
              </div>
            </Grid>
          </Grid>
        </Container> */}
      </div>
      <ScrollToTop />
    </Typography>
  );
};

InkwiryBenfits.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( InkwiryBenfits );
