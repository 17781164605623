import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const InflationAndYourMoney = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Inflation and Your Money </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/inflation.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Inflation and Your Money
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/inflation-left.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Turn on a business channel and I can almost guarantee
                          within the first 60 seconds you'll hear the word
                          inflation. It&apos;s a hot topic to say the least. But
                          what is inflation actually and how does it impact you
                          personally and financially?{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Inflation is the rise in price of goods and services
                          expressed as a percentage over a specific period. What
                          goods? What services? What&apos;s a percentage? All
                          good questions.
                        </p>

                        <p>
                          Over the last 12 months ending in October 2021,
                          inflation was 6.2%, which is a 31-year record!
                          That&apos;s high (peep the chart below for some
                          historical data). There are eight categories that make
                          up inflation:
                        </p>

                        <p>
                          <ul>
                            <li>Food and drinks</li>
                            <li>
                              Rent and other housing costs (important to note,
                              this doesn&apos;t include the price of buying a
                              home since that&apos;s considered an investment){' '}
                            </li>
                            <li>Clothing, shoes, and other apparel</li>
                            <li>
                              New and used vehicles, maintenance and insurance
                              on vehicles, public transportation (planes,
                              trains, automobiles)
                            </li>
                            <li>
                              Cost of electricity, gas (the stuff that powers
                              cars), natural gas (that stuff that powers homes
                              and other buildings), and propane
                            </li>
                            <li>
                              Medical care such as doctor visits and hospital
                              services
                            </li>
                            <li> Recreation, education, and communication</li>
                            <li>Other goods and services</li>
                          </ul>
                        </p>

                        <p>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Inflation-graph.png "
                            alt=""
                            className={classes.imgFull}
                          />
                          <i>Source: Bureau of Labor Statistics (BLS)</i>
                        </p>

                        <p>
                          If you're looking at the chart, you might've noticed
                          that over the last 20 years, the price of goods and
                          services has been rising almost every month. Inflation
                          directly impacts how much you earn, spend, save and
                          invest. Does that make inflation bad? Great question
                          and the short answer is: only sometimes.
                        </p>
                        <p>
                          Inflation is good when it grows slowly, which is
                          usually defined as around 2% in the last 12 months.
                          When the price of goods and services are rising, it
                          means consumers (you and I) are buying goods and
                          services. That&apos;s a good thing! That means that we
                          have enough money to rent apartments, buy food, drink
                          drinks, drive cars, buy gas, pay for medical services,
                          and so on. When consumers are spending, companies
                          notice and they start producing more goods, offering
                          more services, hiring more people, investing in new
                          products, starting new businesses, and so on. This
                          cycle is an example of a healthy economy that is
                          growing.
                        </p>
                        <p>
                          When is inflation bad and how does it impact me
                          personally and financially? Imagine yourself preparing
                          to host Thanksgiving last year. You have $150 to make
                          dinner. The turkey cost $32 leaving $118 for some
                          delicious sides, turkey napkins, plasticware and
                          dessert of course. This year, you have $153 for
                          Thanksgiving after your 2% annual salary increase.
                          That same turkey cost $40, or 25% more than last year.
                          The sides and desserts all cost 10% more. That means
                          less money for turkey, delicious sides and desserts.
                        </p>
                        <p>
                          If inflation outpaces your wage growth, that&apos;s
                          bad because now you can afford less than the year
                          before. And I'm not talking about just less turkey for
                          Thanksgiving. That&apos;s fewer cars, phones,
                          healthcare, and, most importantly, less money for
                          savings and investments. When inflation continues to
                          outpace how much you earn, your standard of living
                          falls.
                        </p>
                        <p>
                          When consumers spend less on goods and services
                          because they cost more, companies start making fewer
                          goods, offering fewer services and hiring fewer people
                          (or worse, firing). They also stop investing in new
                          products, stop starting new businesses, and so on.
                          This cycle is an example of an unhealthy economy.
                        </p>
                        <p>
                          Side note, prices don&apos;t always go up
                          month-to-month. When they go down, it&apos;s called
                          deflation. When the economy isn&apos;t doing well and
                          less people are working, deflation can happen. Less
                          people working means less money for people to spend on
                          rent, cars, food, drink, and other fun stuff.
                          It&apos;s rare, but it does happen.
                        </p>
                        <p>
                          Pay attention to inflation. It&apos;s a huge factor in
                          your financial future. We'll explore that a little
                          deeper next week.
                        </p>
                      </div>
                    </div>
                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>
                      <ul>
                        <li>
                          {' '}
                          <a
                            href="https://www.bankrate.com/banking/capital-one-eliminates-overdraft-fees/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Are bank overdraft fees a thing of the past?
                          </a>{' '}
                          It looks that way
                        </li>
                        <li>
                          Which{' '}
                          <a
                            href="https://www.visualcapitalist.com/historical-returns-by-asset-class/"
                            target="_blank"
                            rel="noopener noreferrer">
                            investments have performed the best
                          </a>{' '}
                          historically after adjusting for inflation
                        </li>
                        <li>
                          Companies are planning the{' '}
                          <a
                            href="https://www.bankrate.com/banking/capital-one-eliminates-overdraft-fees/"
                            target="_blank"
                            rel="noopener noreferrer">
                            largest pay increases since 2008
                          </a>{' '}
                          with inflation being the most mentioned reason
                        </li>
                      </ul>
                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained">Finance Explained.</Link>
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
InflationAndYourMoney.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InflationAndYourMoney);
