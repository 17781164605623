const styles = ( theme ) => ( {

  careerSketch: {
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0px',
      '& li': {
        width: '14.285%',
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        '& figure': {
          width: '100%',
          display: 'flex',
          margin: '0',
          '& img': {
            width: '100%',
            height: '100%',
          },
        },
        '&:hover': {
          '& figure + div': {
            opacity: '1',
          },
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
    },
  },
  careerCaption: {
    position: 'absolute',
    top: '0',
    height: '100%',
    color: theme.palette.common.white,
    background: 'rgba(51, 51, 51, 0.65)',
    width: '100%',
    left: '0',
    textAlign: 'center',
    opacity: '0',
    display: 'flex',
    alignItems: 'center',
    transition: 'all ease-in-out 0.3s',
    '& > div': {
      padding: '15px',
      width: '100%',
    },
    '& h3': {
      fontSize: '24px',
      fontWeight: 'normal',
      lineHeight: '24px',
      margin: '0',
      '& span': {
        display: 'block',
        marginTop: '5px',
      },
    },
    '& span': {
      fontSize: '24px',
      marginTop: '15px',
      display: 'block',
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& h3': {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '20px',
        margin: '0',
      },
      '& span': {
        fontSize: '14px',
      },
    },

  },
  careerMiddle: {
    position: 'absolute',
    width: '42.9%',
    height: '50%',
    background: 'rgba(0, 153, 170, 0.9)',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '0',
    bottom: '0',
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '& sup': {
      fontSize: '11px',
    },
    '& > div': {
      padding: '30px 42px',
      textAlign: 'left',
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '40px',
      lineHeight: '50px',
      color: theme.palette.common.white,
      margin: '0',
      padding: '0 0 22px 0',
      fontWeight: '100',
    },
  },
  careerMiddlePara: {
    fontFamily: '"MuseoSans-100"',
    fontSize: '21px',
    lineHeight: '28px',
    color: theme.palette.common.white,
    margin: '0 0 28px 0',
  },
  careerMiddleParaLast: {
    margin: '0 0 0px 0',
  },
  buttonAction: {
    fontSize: '23.04px',
    lineHeight: '24px',
    padding: '14px 28px',
    color: theme.palette.common.white,
    display: 'inline-block',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    margin: '28px 0 0 0',
    fontFamily: '"MuseoSans-300"',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.greenlight,
      border: '2px solid #84a84d',
    },
    '& i': {
      fontSize: '18px',
      marginLeft: '10px',
    },
  },
  tooltipInfo: {
    width: '200px',
    background: '#0069aa !important',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '7px',
    textAlign: 'initial',
    lineHeight: '20px',
    fontFamily: 'MuseoSans-100',

  },
  tooltipInfoBorderLeft: {
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: '15px',
    fontFamily: 'MuseoSans-300',
    opacity: '1 !important',
    '&::after': {
      borderLeftColor: '#0069aa !important',
    },
  },
  infoIcon: {
    '& img': {
      filter: 'brightness(0) invert(1)',
    },
  },
  careerPopup: {
    display: 'flex',
    height: '610px',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      height: 'auto',
      '& > div': {
        width: '100%',
      },
    },
  },
  careerPopupCnt: {
    overflow: 'auto',
    '& > div': {
      alignItems: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        alignItems: 'flex-start',
      },
    },
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      padding: '0',
      maxHeight: 'inherit',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '10px',
      },
      '@media screen and (min-width: 600px) and (max-width: 991px)': {
        maxHeight: 'calc(100vh - 40px)',
      },
    },
    '& > div > div > div': {
      width: '100%',
      padding: '0 !important',
    },
  },
  careerPopupLeft: {
    width: '24.05%',
    minWidth: '24.05%',
    minHeight: '610px',
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: 'initial',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
  },
  careerPopupMiddle: {
    width: '49.1%',
    position: 'relative',
  },
  careerPopupRight: {
    width: 'calc(26.85% - 36px)',
    position: 'relative',
    background: '#84a84d',
    padding: '35px 18px 0 18px',
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'xs' )]: {
      padding: '35px 15px 100px 15px',
      width: 'calc(100% - 30px) !important',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {        
      width: 'calc(26.85% - 20px)',
      padding: '35px 10px 0 10px',
    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      lineHeight: '18px',
      color: theme.palette.common.white,
      margin: '0',
      fontWeight: 'normal',
      padding: '0 0 8px 0',
    },
    '& p': {
      padding: '0 0 23px 0',
      margin: '0',
      fontSize: '12px',
      fontFamily: 'MuseoSans-100',
    },
    '& ul': {
      padding: '0',
      listStyle: 'none',
      margin: '0',
      '& li': {
        fontFamily: 'MuseoSans-100',
        fontSize: '18px',
        lineHeight: '18px',
        padding: '0 0 7px 0',
        '@media screen and (min-width: 600px) and (max-width: 768px)': {        
          fontSize: '14px',
          lineHeight: '15px',
        },
      },
    },
  },
  careerMidGraph: {
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '24px',
      margin: '0',
      fontWeight: 'normal',
      padding: '0 0 0 60px',
      color: '#0069aa',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {        
        padding: '0 0 0 20px',
      },
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  careerPopupRightBottom: {
    marginTop: '43px',
    [theme.breakpoints.down( 'md' )]: {
      marginTop: '20px',
    },
  },
  viewCareerBtn: {
    background: '#a1a1a1',
    padding: '5px',
    fontSize: '16px',
    textTransform: 'inherit',
    width: '100%',
    color: '#fff',
    fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
    borderRadius: '0',
    [theme.breakpoints.down( 'md' )]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '15px',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {        
      fontSize: '8px',
    },
    '&:hover': {
      background: '#828282',
    },
  },
  viewHistoryBtn: {
    background: '#f69a34',
    padding: '12px 5px',
    fontSize: '16px',
    textTransform: 'inherit',
    width: '100%',
    color: '#fff',
    fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
    borderRadius: '0',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '15px',
    },
    '&:hover': {
      background: '#dc8422',
    },
  },
  btnGroupPopup: {
    margin: '15px -18px 0',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      margin: '15px -10px 0',
    },
    '& i': {
      marginLeft: '6px',
    },
  },
  careerMidTop: {
    padding: '0 60px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 30px',
    },
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {
      padding: '0 15px',
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      color: '#0069aa',
      fontSize: '30px',
      lineHeight: '30px',
      fontWeight: 'normal',
      margin: '0',
      padding: '40px 0 15px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {
        padding: '20px 0 15px 0',
        fontSize: '17px',
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      color: '#000000',
      fontSize: '18px',
      lineHeight: '24px',
      margin: '0',
      padding: '0 0 40px 0',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 20px 0',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        fontSize: '15px',
      },
    },
  },
  careerMidBottomFooter: {
    background: '#0069aa',
    padding: '8px 60px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 120px)',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% - 40px)',
      padding: '8px 20px',
      position: 'relative',
    },
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
      width: 'calc(100% - 40px)',
      padding: '8px 20px',
      flexWrap: 'wrap',
    },   
    '& a': {
      display: 'inline-block',
      margin: '0 0 0 auto',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '18px',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& p': {
      fontSize: '23px',
      color: '#fff',
      paddingRight: '290px',
      margin: '0 0 0 auto',
      '@media (min-width: 1400px)': {
        fontSize: '26px',
        paddingRight: '320px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        fontSize: '11px',
        paddingRight: '200px',
      },     
    },
    '& img': {
      dispaly: 'flex',
      maxWidth: '130px',
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        maxWidth: '80px',
      },      
    },
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    zIndex: '9',
    color: '#fd9840',
    fontSize: '20px',
    width: 'auto',
    minWidth: 'inherit',
    background: 'transparent',
    padding: '10px',
    '&:hover': {
      background: 'transparent',
      opacity: '0.9',
    },
  },
  careerPopupRightTwo: {
    width: '75.95%',
    position: 'relative',
  },
  circlePopup: {
    margin: '10px 0',

  },
  circle: {
    width: '14%',
    position: 'relative',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '48%',
      margin: '0 1%',
      display: 'inline-block',
    },
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
      width: '17%',
    },    
  },
  arrow: {
    width: '5%',
    margin: '0 .5%',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'none',
    },
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
      width: '3%',
    },   
  },
  popGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
  },
  backCard: {
    position: 'absolute',
    right: '0',
    zIndex: '9',
    color: '#999',
    fontSize: '30px',
    width: 'auto',
    minWidth: 'inherit',
    background: 'transparent',
    padding: '10px',
    '&:hover': {
      background: 'transparent',
    },
  },
  steppingStonesCard: {
    display: 'flex',
    marginTop: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      marginBottom: '100px',
      '& > div': {
        width: '100%',
        marginBottom: '50px',
      },
    },
    '& h4': {
      fontSize: '13px',
      fontWeight: 'normal',
      marginBottom: '0',
      color: '#000',
      '@media (min-width: 1400px)': {
        fontSize: '15px',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        marginBottom: '6px',
        marginTop: '10px',
      },      
    },
    '& p': {
      fontSize: '13px',
      fontWeight: 'normal',
      color: '#000',
      fontFamily: '"MuseoSans-300"',
      '@media (min-width: 1400px)': {
        fontSize: '15px',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        fontSize: '11px',
        lineHeight: '18px',
      },     
      '& span': {
        borderBottom: '1px dashed #0069aa',
        cursor: 'pointer',
      },
    },
  },
  steppingStoneOne: {
    minHeight: '210px',
    maxHeight: '210px',
    width: '20%',
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    padding: '4px',
    background: '#e5f0f6',
    borderTop: '3px solid #84a84d',
  },
  steppingStoneTwo: {
    minHeight: '210px',
    maxHeight: '210px',
    width: '20%',
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    padding: '4px',
    background: '#cce1ee',
    borderTop: '3px solid #0069aa',
    marginTop: '20px',
  },
  steppingStoneThree: {
    minHeight: '210px',
    maxHeight: '210px',
    width: '20%',
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    padding: '4px',
    background: '#e5f0f6',
    borderTop: '3px solid #1f96e0',
  },
  steppingStoneFour: {
    minHeight: '210px',
    maxHeight: '210px',
    width: '20%',
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    padding: '4px',
    background: '#cce1ee',
    borderTop: '3px solid #79c3da',
    marginTop: '20px',
  },
  steppingStoneFive: {
    minHeight: '210px',
    maxHeight: '210px',
    width: '20%',
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    padding: '4px',
    background: '#e5f0f6',
    borderTop: '3px solid #8790b8',
  },
  btnGroupPopupNew: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    maxWidth: '305px',
    '@media (min-width: 1400px)': {
      maxWidth: '340px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      position: 'relative',
      maxWidth: '100%',
    },
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
      maxWidth: '200px',
    },    
    '& button': {
      fontSize: '14px',
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 1480px)': {
        fontSize: '13px',
      },
      '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
        fontSize: '10px',
      },     
      '&:last-child': {
        paddingRight: '15px',
        minHeight: '51px',
      },
      '& i': {
        marginLeft: '6px',
      },
    },
  },
  popupNumber: {
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    right: '0',
    '& img': {
      width: '50px',
      height: '50px',
    },
  },
  careerPopupCntAll: {
    '& > div': {
      alignItems: 'flex-start',
      overflow: 'auto',
    },
    '& > div > div': {
      maxWidth: '75%',
      width: '75%',
      padding: '20px 0 20px',
      maxHeight: 'inherit',
      marginTop: '20px',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '90%',
        width: '90%',
        margin: '0',
      },
      '& > div:first-child': {
        paddingTop: '10px',
      },
    },
  },
  carrerSketchTable: {
    paddingLeft: '30px',
    paddingRight: '20px',
  },

  classescarreerSketchHead: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    minWidth: '800px',
    '& td': {
      verticalAlign: 'bottom',
      padding: '0',
      borderRight: '10px solid #fff',
      width: '14%',
    },
    '& img': {
      width: '70px',
      height: '70px',
      maxWidth: '100%',
      display: 'flex',
      margin: '0 auto',
    },
  },
  firstRow: {
    '& td': {
      width: '30%',
    },
  },
  tableTittle: {
    color: '#fff',
    padding: '42px 10px',
    borderRadius: '15px',
    fontSize: '35px',
    textTransform: 'uppercase',
    background: '#1a79b4',
    '@media (min-width: 1400px)': {
      fontSize: '35px',
    },
    '& p': {
      margin: '0',
      textAlign: 'center',
    },
  },
  tableStep: {
    background: '#1a79b4',
    textAlign: 'center',
    borderRadius: '20px',
    padding: '10px',
    position: 'relative',
  },
  careerSketchLsit: {
    '& ul': {
      dispaly: 'table',
      padding: '0',
      margin: '0',
      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      },
      '&:last-child': {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        '& li': {
          borderBottom: 'none',
        },
      },
      '&:nth-child(odd)': {
        background: '#a9d3e9',
      },
      '&:nth-child(even)': {
        background: '#bee0f1',
      },
      '& li': {
        display: 'table-cell',
        width: '14%',
        borderRight: '10px solid #e5f0f6',
        borderBottom: '5px solid #e5f0f6',
        fontSize: '14px',
        verticalAlign: 'middle',
        padding: '10px',
        color: '#231f20',
        '& img': {
          width: '70px',
          verticalAlign: 'middle',
          marginRight: '15px',
        },
        '&:first-child': {
          '& span': {
            display: 'flex',
            alignItems: 'center',
          },
          width: '30%',
          fontSize: '18px',
          '@media (min-width: 1400px)': {
            fontSize: '22px',
          },
        },
        '&:last-child': {
          borderRight: 'none',
        },

      },
    },
  },
  stepingStonesTopCenter: {
    textAlign: 'center',
    position: 'relative',
    '&::before': {
      content: "''",
      height: '2px',
      width: '80%',
      color: '#000',
      background: '#1a79b4',
      position: 'absolute',
      top: '15px',
      zIndex: '1',
      left: '10%',
    },
  },
  stepingStonesTop: {
    background: '#1a79b4',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '22px',
    padding: '5px 50px',
    borderRadius: '10px',
    width: '100%',
    zIndex: '99',
    position: 'relative',
  },
  tableStepFirst: {
    '&::before': {
      content: '""',
      height: '20px',
      width: '2px',
      color: '#000',
      background: '#1a79b4',
      position: 'absolute',
      top: '-28px',
      zIndex: '1',
      left: '6px',
      right: '0',
      margin: 'auto',
    },
  },
  tableStepLast: {
    '&::before': {
      content: '""',
      height: '20px',
      width: '2px',
      color: '#000',
      background: '#1a79b4',
      position: 'absolute',
      top: '-28px',
      zIndex: '1',
      left: '-10px',
      right: '0',
      margin: 'auto',
    },
  },
  infoIconNew: {
    verticalAlign: 'middle',
    paddingLeft: '5px',
    cursor: 'pointer',
  },
  circleTittle: {
    position: 'absolute',
    color: '#fff',
    bottom: '16px',
    width: '100%',
    textAlign: 'center',
    fontSize: '11px',
    padding: '0',
    left: '0',
    textTransform: 'uppercase',
    lineHeight: '14px',
    '@media screen and (min-width: 600px) and (max-width: 1024px)': {        
      fontSize: '7px', 
      lineHeight: '10px',
      bottom: '11px',
    },   
  },
  careerPopupRightBottomNew: {
    marginTop: '10px',
    '& h3': {
      marginTop: '0',
    },
  },
  circlCalvinPopup: {
    '& > div': {
      justifyContent: 'flex-start',
      '&:first-child': {
        paddingLeft: '4%',
      },
    },
  },
  backcardClose: {
    right: '50px',
    fontSize: '22px',
    top: '4px',
  },
  getStartedPopupDialog: {
    position: 'relative',
    '& > div > div': {
      width: '100%',
      maxWidth: '1110px',
      maxHeight: 'inherit',
      transform: 'scale(.85)',
      [theme.breakpoints.down( 'sm' )]: {
        maxHeight: 'calc(100% - 64px)',
      },
      '@media (min-width: 1500px)': {
        height: 'auto',
      },
    },
    '& > div > div > div': {
      padding: '0',
      '&:first-child': {
        paddingTop: '0',
      },
    },
  },
  btnClose:{
    position: 'absolute',
    cursor: 'pointer',
    top: '15px',
    right: '20px',
    color: '#fff',
    fontSize: '20px',
    opacity: '0.8',
    '&:hover':{
      opacity: '1',
    },
    [theme.breakpoints.down( 'sm' )]: {
      color: '#000',
    }
  }
} );

export default styles;
