const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  inkwiryBenfitsBannerOne: {
    position: 'relative',
    textAlign: 'center',
    padding: '0',
    '& img': {
      width: '100%',
      display: 'flex',
    },
    '& h3': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      margin: 'auto',
      left: '0',
      right: '0',
      zIndex: '999',
      letterSpacing: '1.3px',
      fontSize: '48px',
      color: '#333',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',

    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px',
      },
    },
  },

  grossarySection: {
    background: '#ffffff',
    padding: '80px 0 70px 0',
    '& h1': {
      color: '#0069aa',
      fontFamily: "'MuseoSans-100'",
      margin: '10px 0',
      padding: '0',
      fontSize: '48px',
      fontWeight: 'normal',
    },
  },
  total: {
    '& h3': {
      color: '#0069aa',
      fontSize: '26px',
      paddingTop: '30px',
      fontFamily: "'MuseoSans-300'",
      fontWeight: 'normal',
      margin: '0',
    },
    '& p': {
      fontSize: '18px',
      marginTop: '0',
      fontFamily: "'MuseoSans-100'",
    },
  },

} );

export default styles;
