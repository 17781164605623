import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Droppable } from 'react-drag-and-drop';
import styles from './styles';
import { Grid } from "@material-ui/core";



const BitCoinAnswerPopups = (props) => {
  const { classes, challengeIndex } = props;
  const dropZoneDetails = [
    {
      class: `${classes.risklist} ${classes.risklist1}`, text: '35%', dropText: 'Payment History', answer: '4', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist2}`, text: '30%', dropText: 'Utilization Rate', answer: '1', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist3}`, text: '15%', dropText: 'Length of Credit History', answer: '2', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist4}`, text: '10%', dropText: 'New Credit', answer: '3', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist5}`, text: '10%', dropText: 'Credit Mix', answer: '5', correct: false,
    },
  ];

  return (
    <Typography variant="body1" component="div">

      { /* bitcoin XP questions  Popup */}
      {challengeIndex === 1 && (
      <div>
          <div className={classes.completePopupChallengeTable}>
            <h3>FICO credit score factors</h3>             
          </div>
        
        <div className={ classNames( classes.riskLadder, classes.riskLadderCredit ) }>
            <div className={ classes.listedInvestments }>
            <Grid container spacing={2} className={ classes.gridRow }>
              <Grid item md={6} sm={6} xs={12} className={ classes.gridCol }>
                <div className={ classes.listedInvestmentsLeft }>
                  <ul className={ classes.riskList }>
                    { dropZoneDetails.map( ( data, index ) => (
                      <li className={ data.class }>
                        <Droppable
                          types={ ['appropriate'] }
                        >
                          <span className={classes.percText}>{data.text}</span>
                          <span className={classes.dragText}>{data.dropText}</span>
                        </Droppable>
                      </li>
                    ) )}
                  </ul>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12} className={ classes.gridCol }>
              <div className={classes.dragTextRight}>
                <p>The three major credit reporting agencies use the FICO formula in calculating their own proprietary credit scores. The exact details of how a FICO score is calculated is kept secret, but the general formula is made available to the public. Review the graph below to see the factors that go into calculating your credit score.</p>
                <p>
                  Now that you know the formula, you know the secrets to maintaining and improving your credit score!
                </p>
              </div>
              </Grid>
            </Grid>
              </div>
            </div>
      </div>
      )}
      {challengeIndex === 2 && (

     
      <div className={classes.completePopupChallengeTable}>
        <h3>Mary's next student loan payment</h3>
        <div className={classes.dragTextRight}>
          <p>A monthly loan payment is made up of two parts: 1) interest, and 2) principal repayment. When you borrow money, you typically repay the loan by making a monthly payment. The amount stays the same each month, but the interest and principal portion of the payment change over time.</p>
          <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</p>
          <p>$40,000 x 5% / 12 = $166.67 interest expense</p>
        </div>
      </div>

         
      
          )}
      {challengeIndex === 3 && (
      <div className={classes.completePopupChallengeTable}>
        
              {/* <h1>POWERUP CHALLENGE EXPLANATION</h1> */}
              <h3 >Mary's student loan balance</h3>
              <div className={classes.dragTextRight}>
              <p>A monthly loan payment is made up of two parts: 1) interest, and 2) principal repayment. When you borrow money, you typically repay the loan by making a monthly payment. The amount stays the same each month, but the interest and principal portion of the payment change over time.</p>
              <p>Mary has to calculate the interest portion of her payment to solve for her principal repayment. Subtract the principal repayment from the student loan balance to get the new balance.</p>
              <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest<br />
                $40,000 x 5% / 12 = $166.67 interest expense
              </p>
              <p>
                Monthly Payment – Monthly Interest = Principal Repayment<br />
                $424.26 – $166.67 = $257.59
              </p>
              <p>
                Outstanding Loan Balance – Principal Repayment = New Balance
                <br />$40,000.00 – $257.59 = $39,742.41
              </p>

            </div>
          </div>
       
       )}
    </Typography>
  );
};

BitCoinAnswerPopups.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinAnswerPopups);
