import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();
const MobileBankingApps = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>7 Tips to Safe Online Banking</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="bEmDTRQ6oZI" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/mobile-banking.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Mobile Banking</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/mobile-banking-left.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Managing your financial life has become even easier with technology. You can now manage your money from your cell phone, which is called mobile banking. Mobile banking includes person-to-person payments like Venmo and digital wallets like Apple Pay.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Person-to-Person (P2P) Payments</b>
                          <br />
                          Companies such as Venmo, PayPal, Zelle and Cash App allow people to send money to one another via mobile devices. People link their bank accounts, debit cards and credit cards to mobile apps and send money directly to one another, making it easy to split bills with friends or send gifts for special occasions. P2P payments are very popular as you can see by the graphic below:
                        </p>
                      </div>
                      <div className={ classes.maImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/mobile-banking-text.png" alt="template" />
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            {' '}
                            P2P payments are fast, but how fast? Payments usually take 1-3 business days before the money is moved.
                          </p>
                          <p>
                            {' '}
                            P2P payments are free, right? Sometimes. If you use a checking account, P2P payments are usually free. But, if you use a credit card, most providers will charge a fee of 2% to 3%.
                          </p>
                          <p>
                            {' '}
                            <b>Digital Wallets</b>
                            <br />
                            Digital wallets offer a different way to pay for day-to-day expenses. They store digital versions of your credit and debit cards on your phone so that you only need your phone to check out at, for example, the grocery store or local coffee shop. Apple Pay, Samsung Pay, and Google Pay are examples of digital wallets that allow you to make a transaction without cash or a credit card.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.maImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/mobile-banking-text1.png" alt="template" />
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            {' '}
                            Digital wallets are easy to use. Just hold your phone near the payment terminal to make a payment. Most digital wallets require what&apos;s called two-factor authentication. Meaning, it&apos;s not enough to simply wave your phone to check out. You also have to use your fingerprint, passcode, or some other security measure before completing the transaction.
                          </p>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level5/mobile-banking/exercise" startTime={ startTime } level={ 5 } exercise={ 2 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </Typography>
  );
};
MobileBankingApps.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( MobileBankingApps );
