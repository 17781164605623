import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NavbarEl = styled.nav`
  margin: auto;
`;

const NavbarList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
`;

const Navbar = ( props ) => {
  const { children, onMouseLeave } = props;
  return (
    <NavbarEl onMouseLeave={ onMouseLeave }>
      <NavbarList>{children}</NavbarList>
    </NavbarEl>
  );
};

Navbar.propTypes = {
  onMouseLeave: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Navbar;
