import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';
import incometaxes from '../../../../assets/img/pf101/income_taxes2.png';
import incometaxes2 from '../../../../assets/img/pf101/income_taxes1.png';
import incometaxes3 from '../../../../assets/img/pf101/income_taxes3.png';

const startTime = new Date();

const FederalIncomeTaxes = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Federal Income Taxes</h3>
                    </div>
                    <div className={ classNames( classes.pfVideo, classes.fitaxVideo ) }>
                      <YouTube videoId="SJL4UT4wAxc" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/federal-income-taxes.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Federal Income Taxes</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/federal-inc-taxes-left.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Federal income taxes are calculated by using progressive tax rates, which means that as your income increases,
                            you are taxed at higher rates. There are seven tax brackets ranging from 10% to 37%. It&apos;s important to note that portions
                            of your income are taxed at different rates -
                            {' '}
                            <b>your entire income is not taxed at one rate!</b>
                            {' '}
                            Federal income taxes are easiest to understand with an example. Let&apos;s take a look at two examples.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Example 1:</b>
                          <br />
                          John Smith is a single man who earns $50,000 and has no children or other people who rely on him for money. His federal income taxes are calculated below.
                        </p>
                      </div>
                      <div className={ classes.fiImage }>
                        <div className={ classNames( classes.lightGallery, classes.lightGalleryImage ) }>
                          <img src={ incometaxes2 } alt="table" />
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classNames( classes.creditText, classes.noMarginBottom ) }>
                          <b>How it works:</b>
                          <ul>
                            <li>
                              John&apos;s federal taxable income is $37,600.
                            </li>
                            <li>
                              That income is applied to each tax bracket shown above as Income Ranges. You can see that only the first two tax brackets apply for John.
                            </li>
                            <li>
                              The first $9,875 of John&apos;s federal taxable income is taxed at 10%, or $987.50.
                            </li>
                            <li>
                              Then, the remaining $27,725 of John&apos;s taxable income is taxed at 12%, or $3,327.
                            </li>
                            <li>
                              Add those taxes up and John owes $4,314.50 in total taxes.
                            </li>
                          </ul>
                          <p>
                            <b>Example 2:</b>
                            <br />
                            Jane Doe is a married woman who, including her spouse&apos;s income, earns $120,000 and has no children or other people who rely on her for money. Jane and her spouse&apos;s federal income taxes are calculated below.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.fiImage }>
                        <div className={ classNames( classes.lightGallery, classes.lightGalleryImage ) }>
                          <img src={ incometaxes } alt="table" />
                        </div>
                      </div>
                      <div className={ classes.fiRow }>
                        <p className={ classNames( classes.creditText, classes.noMarginBottom ) }>
                          <b>How it works:</b>
                        </p>
                        <ul>
                          <li>
                            Jane&apos;s total federal taxable income, including her spouse, is $95,200.
                          </li>
                          <li>
                            That income is applied to each tax bracket shown above as Income Ranges. You can see that only the first three tax brackets apply for Jane as $95,200 falls in the third bracket.
                          </li>
                          <li>
                            The first $19,750 of Jane&apos;s federal taxable income is taxed at 10%, or $1,975.
                          </li>
                          <li>
                            Then, the next $60,500 of Jane&apos;s taxable income is taxed at 12%, or $7,260.
                          </li>
                          <ul>
                            <li>
                              $80,250 - $19,750 = $60,500 of taxable income in the second tax bracket
                            </li>
                          </ul>
                          <li>
                            Lastly, the $14,950 of Jane&apos;s taxable income remaining is taxed at 22%, or $3,289.
                          </li>
                          <ul>
                            <li>
                              $95,200 - $80,250 = $14,950 of taxable income in the third tax bracket
                            </li>
                          </ul>
                          <li>
                            Add those taxes up and Jane owes $12,524 in total taxes.
                          </li>
                        </ul>
                        <p className={ classes.creditText }>The above examples show just how federal income taxes are calculated in simplified scenarios. Remember, the standard deduction is a portion of your income that is not subject to federal income taxes. The next table allows you to compare side-by-side to see the different tax brackets for single and married and the different standard deductions as well.</p>

                      </div>
                      <div className={ classes.fiImage }>
                        <div className={ classNames( classes.lightGallery, classes.lightGalleryImage ) }>
                          <img src={ incometaxes3 } alt="table" />
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level2/federal-income-taxes/exercise" startTime={ startTime } level={ 2 } exercise={ 3 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
FederalIncomeTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( FederalIncomeTaxes );
