function DepreciationShedule( module1Data, module12Data, module18Data, assumptionsData ) {
  /** ******************** Depreciation Sheet *********************** */
  let year = 0;
  let yearLimit = 0;
  let initialYear = 0;
  year = parseInt( module1Data.start_year, 10 );
  initialYear = year;
  yearLimit = parseInt( module1Data.start_year, 10 ) + 30;

  const depreciationSheduleData = {};
  let beginningValue = 0;
  let depreciationValue = 0;
  let endingValue = 0;
  let moreBeginningValue = 0;
  let moreDepreciationValue = 0;
  let moreEndingValue = 0;
  let moreBeginningValueSecond = 0;
  let moreDepreciationValueSecond = 0;
  let moreEndingValueSecond = 0;
  let maintenanceValue = 0;
  let insuranceValue = 0;
  let gasValue = 0;
  let secondCarMaintenanceValue = 0;
  let secondCarInsuranceValue = 0;
  let secondCarGasValue = 0;
  let thirdMaintenanceValue = 0;
  let thirdInsuranceValue = 0;
  let thirdGasValue = 0;
  // For Lease1 Expense
  let lease1DownPaymentValue = 0;
  let lease1DriveOffFees = 0;
  let lease1CarPaymentValue = 0;
  let lease1MaintenanceValue = 0;
  let lease1InsuranceValue = 0;
  let lease1GasValue = 0;
  // For Lease2 Expense
  let lease2DownPaymentValue = 0;
  let lease2DriveOffFees = 0;
  let lease2CarPaymentValue = 0;
  let lease2MaintenanceValue = 0;
  let lease2InsuranceValue = 0;
  let lease2GasValue = 0;

  let tradeInValue = 0;
  let secondTradeInValue = 0;
  let lease1Integer = 0;
  let lease1EndYear = 0;
  let lease1EndYearRound = 0;
  let lease2Integer = 0;
  let lease2EndYear = 0;
  let lease2EndYearRound = 0;
  if ( module12Data.moretradein_existing_car !== undefined && module12Data.moretradein_existing_car === 'Yes' && module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' ) {
    tradeInValue = module12Data.second_purchase_year;
  }
  // Trade In Year - Existing Car - Depreciation & Expenses
  if ( module12Data.tradein_existing_car !== undefined && module12Data.tradein_existing_car === 'Yes' && module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' ) {
    secondTradeInValue = module12Data.purchase_year;
  }

  // Leasing first car End Year
  if ( module12Data.leasing_car_number_of_years !== undefined && module12Data.leasing_car_number_of_years !== '' ) {
    lease1Integer = ( module12Data.leasing_car_number_of_years ) / 12;
  }
  if ( module12Data.leasing_car_number_of_years !== undefined && lease1Integer !== undefined && module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' ) {
    if ( module12Data.leasing_car_number_of_years > 0 && parseInt( lease1Integer, 10 ) === lease1Integer ) {
      lease1EndYear = module12Data.leasing_car_start_year + ( module12Data.leasing_car_number_of_years / 12 ) - 1;
    } else if ( module12Data.leasing_car_number_of_years > 0 ) {
      lease1EndYear = module12Data.leasing_car_start_year + ( module12Data.leasing_car_number_of_years / 12 );
    } else {
      lease1EndYear = 0;
    }
  }
  if ( lease1EndYear !== undefined && lease1EndYear !== '' ) {
    lease1EndYearRound = Math.floor( lease1EndYear );
  }

  // Leasing first car End Year
  if ( module12Data.leasing_second_car_number_of_years !== undefined && module12Data.leasing_second_car_number_of_years !== '' ) {
    lease2Integer = ( module12Data.leasing_second_car_number_of_years ) / 12;
  }
  if ( module12Data.leasing_second_car_number_of_years !== undefined && lease1Integer !== undefined && module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' ) {
    if ( module12Data.leasing_second_car_number_of_years > 0 && parseInt( lease2Integer, 10 ) === lease2Integer ) {
      lease2EndYear = module12Data.leasing_second_car_start_year + ( module12Data.leasing_second_car_number_of_years / 12 ) - 1;
    } else if ( module12Data.leasing_second_car_number_of_years > 0 ) {
      lease2EndYear = module12Data.leasing_second_car_start_year + ( module12Data.leasing_second_car_number_of_years / 12 );
    } else {
      lease2EndYear = 0;
    }
  }
  if ( lease2EndYear !== undefined && lease2EndYear !== '' ) {
    lease2EndYearRound = Math.floor( lease2EndYear );
  }

  let k = 0;
  for ( year; year < yearLimit; year += 1 ) {
    depreciationSheduleData[year] = {};
    depreciationSheduleData[year]['Understanding Depreciation'] = {};
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] = {};
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'] = {};
    depreciationSheduleData[year]['Lease1 Expenses'] = {};
    depreciationSheduleData[year]['Lease2 Expenses'] = {};

    // Beginning Value
    if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.moretradein_existing_car !== undefined && year === module12Data.second_purchase_year && module12Data.moretradein_existing_car === 'Yes' ) {
      beginningValue = 0;
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== ''
                        && year === module12Data.purchase_year && module12Data.netPurchasePrice !== undefined && module12Data.netPurchasePrice !== '' ) {
      beginningValue = module12Data.netPurchasePrice;
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== ''
                            && year < ( parseFloat( module12Data.purchase_year ) + 10 ) && depreciationSheduleData[year - 1] !== undefined && depreciationSheduleData[year - 1]['Understanding Depreciation']['Ending Value'] !== undefined && depreciationSheduleData[year - 1]['Understanding Depreciation']['Ending Value'] !== '' ) {
      beginningValue = endingValue;
    } else {
      beginningValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation']['Beginning Value'] = beginningValue;

    // Depreciation Value
    if ( depreciationSheduleData[year]['Understanding Depreciation']['Beginning Value'] > 0 ) {
      if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== ''
        && year === module12Data.purchase_year && module12Data.netPurchasePrice !== undefined && module12Data.netPurchasePrice !== '' ) {
        depreciationValue = ( module12Data.netPurchasePrice / 100 ) * 18;
      } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== ''
        && year === ( module12Data.purchase_year + 1 ) && module12Data.netPurchasePrice !== undefined && module12Data.netPurchasePrice !== '' ) {
        depreciationValue = ( module12Data.netPurchasePrice / 100 ) * 14;
      } else if ( module12Data.netPurchasePrice !== undefined && module12Data.netPurchasePrice !== '' ) {
        depreciationValue = ( ( module12Data.netPurchasePrice / 100 ) * 68 ) / 8;
      } else {
        depreciationValue = 0;
      }
    } else {
      depreciationValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation'].Depreciation = depreciationValue;

    // Ending Value
    if ( beginningValue > 0 ) {
      endingValue = parseFloat( beginningValue ) - parseFloat( depreciationValue );
    } else {
      endingValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation']['Ending Value'] = endingValue;
    // Maintenance Vale
    if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year === module12Data.purchase_year && module12Data.firstCarMaintenanceCalcs !== undefined && module12Data.firstCarMaintenanceCalcs !== '' ) {
      maintenanceValue = module12Data.firstCarMaintenanceCalcs;
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year && tradeInValue === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        maintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        maintenanceValue = 0;
      }
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year
      && year < tradeInValue && tradeInValue > 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        maintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        maintenanceValue = 0;
      }
    } else {
      maintenanceValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation'].Maintenance = maintenanceValue;

    // Insurance Value
    if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year === module12Data.purchase_year
      && module12Data.firstCarInsuranceCalcs !== undefined && module12Data.firstCarInsuranceCalcs !== '' ) {
      insuranceValue = module12Data.firstCarInsuranceCalcs;
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year && tradeInValue === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        insuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        insuranceValue = 0;
      }
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year
      && year < tradeInValue && tradeInValue > 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        insuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        insuranceValue = 0;
      }
    } else {
      insuranceValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation'].Insurance = insuranceValue;

    // Gas Value
    if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year === module12Data.purchase_year && module12Data.firstCarGasCalcs !== undefined && module12Data.firstCarGasCalcs !== '' ) {
      gasValue = module12Data.firstCarGasCalcs;
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year && tradeInValue === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        gasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        gasValue = 0;
      }
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year >= module12Data.purchase_year
      && year < tradeInValue && tradeInValue > 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        gasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        gasValue = 0;
      }
    } else {
      gasValue = 0;
    }
    depreciationSheduleData[year]['Understanding Depreciation'].Gas = gasValue;

    /** Automobile Straight-Line Depreciation * */
    // Beginning Value
    if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
                    && year === module12Data.second_purchase_year && module12Data.moreNetPurchasePrice !== undefined ) {
      moreBeginningValue = module12Data.moreNetPurchasePrice;
    } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
                        && year < ( module12Data.second_purchase_year + 10 ) ) {
      moreBeginningValue = moreEndingValue;
    } else {
      moreBeginningValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Beginning Value'] = moreBeginningValue;

    // Depreciation Value
    if ( depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Beginning Value'] > 0 ) {
      if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
        && year === module12Data.second_purchase_year && module12Data.moreNetPurchasePrice !== undefined ) {
        moreDepreciationValue = ( module12Data.moreNetPurchasePrice / 100 ) * 18;
      } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
        && year === ( module12Data.second_purchase_year + 1 ) && module12Data.moreNetPurchasePrice !== undefined ) {
        moreDepreciationValue = ( module12Data.moreNetPurchasePrice / 100 ) * 14;
      } else if ( module12Data.moreNetPurchasePrice !== undefined && module12Data.moreNetPurchasePrice !== '' ) {
        moreDepreciationValue = ( ( module12Data.moreNetPurchasePrice / 100 ) * 68 ) / 8;
      } else {
        moreDepreciationValue = 0;
      }
    } else {
      moreDepreciationValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Depreciation = moreDepreciationValue;

    // Ending Value
    if ( moreBeginningValue > 0 ) {
      moreEndingValue = parseFloat( moreBeginningValue ) - parseFloat( moreDepreciationValue );
    } else {
      moreEndingValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Ending Value'] = moreEndingValue;

    // Maintenance Vale
    if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year === module12Data.second_purchase_year && module12Data.secondCarMaintenanceCalcs !== undefined && module12Data.secondCarMaintenanceCalcs !== '' ) {
      secondCarMaintenanceValue = module12Data.secondCarMaintenanceCalcs;
    } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year >= module12Data.second_purchase_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        secondCarMaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        secondCarMaintenanceValue = 0;
      }
    } else {
      secondCarMaintenanceValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Maintenance = secondCarMaintenanceValue;

    // Insurance Vale
    if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year === module12Data.second_purchase_year && module12Data.secondCarInsuranceCalcs !== undefined && module12Data.secondCarInsuranceCalcs !== '' ) {
      secondCarInsuranceValue = module12Data.secondCarInsuranceCalcs;
    } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year >= module12Data.second_purchase_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        secondCarInsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        secondCarInsuranceValue = 0;
      }
    } else {
      secondCarInsuranceValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Insurance = secondCarInsuranceValue;

    // Gas Vale
    if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year === module12Data.second_purchase_year && module12Data.secondCarGasCalcs !== undefined && module12Data.secondCarGasCalcs !== '' ) {
      secondCarGasValue = module12Data.secondCarGasCalcs;
    } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && year >= module12Data.second_purchase_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        secondCarGasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        secondCarGasValue = 0;
      }
    } else {
      secondCarGasValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Gas = secondCarGasValue;

    /** Automobile Straight-Line Depreciation Second* */
    // Beginning Value
    if ( k === 0 ) {
      if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year === module12Data.purchase_year && module12Data.tradein_existing_car !== undefined && module12Data.tradein_existing_car === 'Yes' ) {
        moreBeginningValueSecond = 0;
      } else if ( module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value !== '' ) {
        moreBeginningValueSecond = module18Data.existing_car_current_value;
      } else {
        moreBeginningValueSecond = 0;
      }
    } else if ( module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && year === module12Data.purchase_year && module12Data.tradein_existing_car !== undefined && module12Data.tradein_existing_car === 'Yes' ) {
      moreBeginningValueSecond = 0;
    } else if ( year < ( parseFloat( initialYear ) + 10 ) ) {
      moreBeginningValueSecond = moreEndingValueSecond;
    } else {
      moreBeginningValueSecond = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Beginning Value'] = moreBeginningValueSecond;

    // Depreciation Value
    if ( moreBeginningValueSecond > 0 && module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value !== '' ) {
      moreDepreciationValueSecond = module18Data.existing_car_current_value / 10;
    } else {
      moreDepreciationValueSecond = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Depreciation = moreDepreciationValueSecond;

    // Ending Value
    if ( depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Beginning Value'] > 0
                     && module12Data.purchase_year !== undefined && year < parseInt( module12Data.purchase_year, 10 ) ) {
      moreEndingValueSecond = parseFloat( depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Beginning Value'] ) - parseFloat( depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Depreciation );
    } else {
      moreEndingValueSecond = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Ending Value'] = moreEndingValueSecond;

    // Maintenance Vale
    if ( k === 0 ) {
      if ( module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value > 0 && year !== secondTradeInValue && module18Data.existingCarLoansMaintenanceCalcs !== undefined && module18Data.existingCarLoansMaintenanceCalcs !== '' ) {
        thirdMaintenanceValue = module18Data.existingCarLoansMaintenanceCalcs;
      } else {
        thirdMaintenanceValue = 0;
      }
    } else if ( secondTradeInValue === 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdMaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else if ( year < secondTradeInValue && secondTradeInValue > 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdMaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else {
      thirdMaintenanceValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Maintenance = thirdMaintenanceValue;

    // Insurance Vale
    if ( k === 0 ) {
      if ( module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value > 0 && year !== secondTradeInValue && module18Data.existingCarLoansInsuranceCalcs !== undefined && module18Data.existingCarLoansInsuranceCalcs !== '' ) {
        thirdInsuranceValue = module18Data.existingCarLoansInsuranceCalcs;
      } else {
        thirdInsuranceValue = 0;
      }
    } else if ( secondTradeInValue === 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdInsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else if ( year < secondTradeInValue && secondTradeInValue > 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdInsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else {
      thirdInsuranceValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Insurance = thirdInsuranceValue;

    // Gas Vale
    if ( k === 0 ) {
      if ( module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value > 0 && year !== secondTradeInValue && module18Data.existingCarLoansGasCalcs !== undefined && module18Data.existingCarLoansGasCalcs !== '' ) {
        thirdGasValue = module18Data.existingCarLoansGasCalcs;
      } else {
        thirdGasValue = 0;
      }
    } else if ( secondTradeInValue === 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdGasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else if ( year < secondTradeInValue && secondTradeInValue > 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      thirdGasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else {
      thirdGasValue = 0;
    }
    depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Gas = thirdGasValue;

    // For Lease1 Expenses
    // Down Payment
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && year === module12Data.leasing_car_start_year && module12Data.leasing_car_down_payment !== undefined && module12Data.leasing_car_down_payment !== '' ) {
      lease1DownPaymentValue = module12Data.leasing_car_down_payment;
    } else {
      lease1DownPaymentValue = 0;
    }
    depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] = lease1DownPaymentValue;

    // Drive off fees
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && year === module12Data.leasing_car_start_year && module12Data.leasing_car_drive_off_fees !== undefined && module12Data.leasing_car_drive_off_fees !== '' ) {
      lease1DriveOffFees = module12Data.leasing_car_drive_off_fees;
    } else {
      lease1DriveOffFees = 0;
    }
    depreciationSheduleData[year]['Lease1 Expenses']['Drive-Off Fees'] = lease1DriveOffFees;

    // Car Payment
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasingCarLeasePaymentCalc !== undefined
      && module12Data.leasingCarLeasePaymentCalc !== '' ) {
      if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year && lease1EndYearRound === lease1EndYear ) {
        lease1CarPaymentValue = module12Data.leasingCarLeasePaymentCalc;
      } else if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year ) {
        lease1CarPaymentValue = module12Data.leasingCarLeasePaymentCalc * ( lease1EndYear - lease1EndYearRound );
      } else if ( year >= module12Data.leasing_car_start_year && year < lease1EndYearRound ) {
        lease1CarPaymentValue = module12Data.leasingCarLeasePaymentCalc;
      } else if ( year === lease1EndYearRound && lease1EndYear === lease1EndYearRound ) {
        lease1CarPaymentValue = module12Data.leasingCarLeasePaymentCalc;
      } else if ( year === lease1EndYearRound ) {
        lease1CarPaymentValue = module12Data.leasingCarLeasePaymentCalc * ( lease1EndYear - lease1EndYearRound );
      } else {
        lease1CarPaymentValue = 0;
      }
    } else {
      lease1CarPaymentValue = 0;
    }
    depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] = lease1CarPaymentValue;

    // Maintenance
    // && year === module12Data.leasing_car_start_year && module12Data.leasingCarMaintenanceCalc
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasingCarMaintenanceCalc !== undefined ) {
      if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year && lease1EndYear === lease1EndYearRound ) {
        lease1MaintenanceValue = module12Data.leasingCarMaintenanceCalc;
      } else if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year ) {
        lease1MaintenanceValue = module12Data.leasingCarMaintenanceCalc * ( lease1EndYear - lease1EndYearRound );
      } else if ( year === module12Data.leasing_car_start_year ) {
        lease1MaintenanceValue = module12Data.leasingCarMaintenanceCalc;
      } else if ( year >= module12Data.leasing_car_start_year && year < lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1MaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1MaintenanceValue = 0;
        }
      } else if ( year === lease1EndYearRound && lease1EndYear === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1MaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1MaintenanceValue = 0;
        }
      } else if ( year === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1MaintenanceValue = lease1MaintenanceValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease1MaintenanceValue = 0;
        }
      } else {
        lease1MaintenanceValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease1 Expenses'].Maintenance = lease1MaintenanceValue;

    // Insurance
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasingCarInsuranceCalc !== undefined ) {
      if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year && lease1EndYear === lease1EndYearRound ) {
        lease1InsuranceValue = module12Data.leasingCarInsuranceCalc;
      } else if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year ) {
        lease1InsuranceValue = module12Data.leasingCarInsuranceCalc * ( lease1EndYear - lease1EndYearRound );
      } else if ( year === module12Data.leasing_car_start_year ) {
        lease1InsuranceValue = module12Data.leasingCarInsuranceCalc;
      } else if ( year >= module12Data.leasing_car_start_year && year < lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1InsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1InsuranceValue = 0;
        }
      } else if ( year === lease1EndYearRound && lease1EndYear === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1InsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1InsuranceValue = 0;
        }
      } else if ( year === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1InsuranceValue = lease1InsuranceValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease1InsuranceValue = 0;
        }
      } else {
        lease1InsuranceValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease1 Expenses'].Insurance = lease1InsuranceValue;

    // Gas
    if ( module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasingCarGasCalc !== undefined ) {
      if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year && lease1EndYear === lease1EndYearRound ) {
        lease1GasValue = module12Data.leasingCarGasCalc;
      } else if ( year === module12Data.leasing_car_start_year && lease1EndYearRound === module12Data.leasing_car_start_year ) {
        lease1GasValue = module12Data.leasingCarGasCalc * ( lease1EndYear - lease1EndYearRound );
      } else if ( year === module12Data.leasing_car_start_year ) {
        lease1GasValue = module12Data.leasingCarGasCalc;
      } else if ( year >= module12Data.leasing_car_start_year && year < lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1GasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1GasValue = 0;
        }
      } else if ( year === lease1EndYearRound && lease1EndYear === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1GasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease1GasValue = 0;
        }
      } else if ( year === lease1EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease1GasValue = lease1GasValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease1GasValue = 0;
        }
      } else {
        lease1GasValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease1 Expenses'].Gas = lease1GasValue;

    // For Lease2 Car Expenses
    // Down Payment
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && year === module12Data.leasing_second_car_start_year && module12Data.leasing_second_car_down_payment !== undefined && module12Data.leasing_second_car_down_payment !== '' ) {
      lease2DownPaymentValue = module12Data.leasing_second_car_down_payment;
    } else {
      lease2DownPaymentValue = 0;
    }
    depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] = lease2DownPaymentValue;

    // Second Drive Off
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && year === module12Data.leasing_second_car_start_year && module12Data.leasing_second_car_drive_off_fees !== undefined && module12Data.leasing_second_car_drive_off_fees !== '' ) {
      lease2DriveOffFees = module12Data.leasing_second_car_drive_off_fees;
    } else {
      lease2DriveOffFees = 0;
    }
    depreciationSheduleData[year]['Lease2 Expenses']['Drive-Off Fees'] = lease2DriveOffFees;

    // Car Payment
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasingSecondCarLeasePaymentCalc !== undefined && module12Data.leasingSecondCarLeasePaymentCalc !== '' ) {
      if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year && lease2EndYearRound === lease2EndYear ) {
        lease2CarPaymentValue = module12Data.leasingSecondCarLeasePaymentCalc;
      } else if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year ) {
        lease2CarPaymentValue = module12Data.leasingSecondCarLeasePaymentCalc * ( lease2EndYear - lease2EndYearRound );
      } else if ( year >= module12Data.leasing_second_car_start_year && year < lease2EndYearRound ) {
        lease2CarPaymentValue = module12Data.leasingSecondCarLeasePaymentCalc;
      } else if ( year === lease2EndYearRound && lease2EndYear === lease2EndYearRound ) {
        lease2CarPaymentValue = module12Data.leasingSecondCarLeasePaymentCalc;
      } else if ( year === lease2EndYearRound ) {
        lease2CarPaymentValue = module12Data.leasingSecondCarLeasePaymentCalc * ( lease2EndYear - lease2EndYearRound );
      } else {
        lease2CarPaymentValue = 0;
      }
    } else {
      lease2CarPaymentValue = 0;
    }
    depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] = lease2CarPaymentValue;

    // Maintenance
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasingSecondCarMaintenanceCalc !== undefined ) {
      if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year && lease2EndYear === lease2EndYearRound ) {
        lease2MaintenanceValue = module12Data.leasingSecondCarMaintenanceCalc;
      } else if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year ) {
        lease2MaintenanceValue = module12Data.leasingSecondCarMaintenanceCalc * ( lease2EndYear - lease2EndYearRound );
      } else if ( year === module12Data.leasing_second_car_start_year ) {
        lease2MaintenanceValue = module12Data.leasingSecondCarMaintenanceCalc;
      } else if ( year >= module12Data.leasing_second_car_start_year && year < lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2MaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2MaintenanceValue = 0;
        }
      } else if ( year === lease2EndYearRound && lease2EndYear === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2MaintenanceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2MaintenanceValue = 0;
        }
      } else if ( year === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2MaintenanceValue = lease2MaintenanceValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease2MaintenanceValue = 0;
        }
      } else {
        lease2MaintenanceValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease2 Expenses'].Maintenance = lease2MaintenanceValue;

    // Insurance
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasingSecondCarInsuranceCalc !== undefined ) {
      if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year && lease2EndYear === lease2EndYearRound ) {
        lease2InsuranceValue = module12Data.leasingSecondCarInsuranceCalc;
      } else if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year ) {
        lease2InsuranceValue = module12Data.leasingSecondCarInsuranceCalc * ( lease2EndYear - lease2EndYearRound );
      } else if ( year === module12Data.leasing_second_car_start_year ) {
        lease2InsuranceValue = module12Data.leasingSecondCarInsuranceCalc;
      } else if ( year >= module12Data.leasing_second_car_start_year && year < lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2InsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2InsuranceValue = 0;
        }
      } else if ( year === lease2EndYearRound && lease2EndYear === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2InsuranceValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2InsuranceValue = 0;
        }
      } else if ( year === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2InsuranceValue = lease2InsuranceValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease2InsuranceValue = 0;
        }
      } else {
        lease2InsuranceValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease2 Expenses'].Insurance = lease2InsuranceValue;

    // Gas
    if ( module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasingSecondCarGasCalc !== undefined ) {
      if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year && lease2EndYear === lease2EndYearRound ) {
        lease2GasValue = module12Data.leasingSecondCarGasCalc;
      } else if ( year === module12Data.leasing_second_car_start_year && lease2EndYearRound === module12Data.leasing_second_car_start_year ) {
        lease2GasValue = module12Data.leasingSecondCarGasCalc * ( lease2EndYear - lease2EndYearRound );
      } else if ( year === module12Data.leasing_second_car_start_year ) {
        lease2GasValue = module12Data.leasingSecondCarGasCalc;
      } else if ( year >= module12Data.leasing_second_car_start_year && year < lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2GasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2GasValue = 0;
        }
      } else if ( year === lease2EndYearRound && lease2EndYear === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2GasValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          lease2GasValue = 0;
        }
      } else if ( year === lease2EndYearRound ) {
        if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
          lease2GasValue = lease2GasValue * ( ( assumptionsData[year].Expenses ) / 100 ) * ( lease1EndYear - lease1EndYearRound );
        } else {
          lease2GasValue = 0;
        }
      } else {
        lease2GasValue = 0;
      }
    }
    depreciationSheduleData[year]['Lease2 Expenses'].Gas = lease2GasValue;

    k += 1;
  }
  // To return Depreciation Schedule Sheet Complete Data
  function depreciationScheduleCompleteData() {
    depreciationSheduleData['lease1 Integer'] = lease1Integer;
    depreciationSheduleData['lease1 End Year'] = lease1EndYear;
    depreciationSheduleData['lease1 End Year Round'] = lease1EndYearRound;
    depreciationSheduleData['lease2 Integer'] = lease2Integer;
    depreciationSheduleData['lease2 End Year'] = lease2EndYear;
    depreciationSheduleData['lease2 End Year Round'] = lease2EndYearRound;
	//console.log('depreciationSheduleData', depreciationSheduleData);
	return depreciationSheduleData;
  }

  return depreciationScheduleCompleteData();
}
export default DepreciationShedule;
