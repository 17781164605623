import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
// import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
// import InvestmentBottom from './investments-bottom';
// import * as modulesServices from '../../calculations/modules-services';
// import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
// import converyIntoThousands, { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';
import SwipeableViews from 'react-swipeable-views';
import {  
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import $ from 'jquery';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function renderThumb({ style, ...props }) {
  const thumbStyle = {
    backgroundColor: '#eca041',
    borderRadius: '3px',
    width: '3px',
  };
  return (
    <div
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
}
renderThumb.propTypes = {
  style: PropTypes.object.isRequired,
};

const MonthlyContribution = (props) => {
  const {
    classes,
  } = props;

  const [value, setValue] = React.useState(0);
  const [year, setYear] = React.useState(2020);
  const [data, setData] = React.useState([]);
  const [asset, setAsset] = React.useState('REIT');
  const [assetData, setAssetData] = React.useState([]);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleyear = (e) => {
    setYear(e.target.value);
  }

  const handleAsset = (e) => {
    setAsset(e.target.value);
  }

  const graphData = {
    1985: {
      "US Large Cap Stocks": 26.4,
      "US Small Cap Stocks": 26.2,
      "Int'l Dev Stocks": 50.3,
      "Emerging Stocks": 22.9,
      "Cdn Stocks": 19.8,
      "All US Bonds": 17.6,
      "Hi-Yield US Bonds": 17.5,
      "Int'l Bonds": 7.0,
      "Cdn Bonds": 16.1,
      "Cash (T-Bill)": 3.8,
      "REIT": 14.6,
      "Gold": 1.7,
    },
    1986: {
      "US Large Cap Stocks": 16.8,
      "US Small Cap Stocks": 4.5,
      "Int'l Dev Stocks": 67.5,
      "Emerging Stocks": 10.4,
      "Cdn Stocks": 4.6,
      "All US Bonds": 13.9,
      "Hi-Yield US Bonds": 15.6,
      "Int'l Bonds": 10.1,
      "Cdn Bonds": 10.1,
      "Cash (T-Bill)": 5.0,
      "REIT": 17.7,
      "Gold": 17.9,
    },
    1987: {
      "US Large Cap Stocks": 0.3,
      "US Small Cap Stocks": -12.7,
      "Int'l Dev Stocks": 19.3,
      "Emerging Stocks": 9.3,
      "Cdn Stocks": 1.6,
      "All US Bonds": -2.8,
      "Hi-Yield US Bonds": -1.7,
      "Int'l Bonds": 4.5,
      "Cdn Bonds": -0.2,
      "Cash (T-Bill)": 1.3,
      "REIT": -7.8,
      "Gold": 19.0,
    },
    1988: {
      "US Large Cap Stocks": 11.3,
      "US Small Cap Stocks": 19.7,
      "Int'l Dev Stocks": 22.8,
      "Emerging Stocks": 33.9,
      "Cdn Stocks": 6.8,
      "All US Bonds": 2.8,
      "Hi-Yield US Bonds": -8.8,
      "Int'l Bonds": 4.4,
      "Cdn Bonds": 5.6,
      "Cash (T-Bill)": 2.1,
      "REIT": 8.6,
      "Gold": -19.6,
    },
    1989: {
      "US Large Cap Stocks": 25.5,
      "US Small Cap Stocks": 11.0,
      "Int'l Dev Stocks": 5.6,
      "Emerging Stocks": 56.9,
      "Cdn Stocks": 15.4,
      "All US Bonds": 8.6,
      "Hi-Yield US Bonds": -2.6,
      "Int'l Bonds": -0.6,
      "Cdn Bonds": -7.2,
      "Cash (T-Bill)": 3.7,
      "REIT": 3.9,
      "Gold": -6.8,
    },
    1990: {
      "US Large Cap Stocks": -8.9,
      "US Small Cap Stocks": -22.8,
      "Int'l Dev Stocks": -27.9,
      "Emerging Stocks": -16.1,
      "Cdn Stocks": -18.9,
      "All US Bonds": 2.4,
      "Hi-Yield US Bonds": -11.3,
      "Int'l Bonds": -2.7,
      "Cdn Bonds": 2.4,
      "Cash (T-Bill)": 1.6,
      "REIT": -20.3,
      "Gold": -8.3,
    },
    1991: {

      "US Large Cap Stocks": 26.3,
      "US Small Cap Stocks": 40.9,
      "Int'l Dev Stocks": 8.7,
      "Emerging Stocks": 54.5,
      "Cdn Stocks": 7.9,
      "All US Bonds": 11.8,
      "Hi-Yield US Bonds": 25.2,
      "Int'l Bonds": 7.5,
      "Cdn Bonds": 17.6,
      "Cash (T-Bill)": 2.5,
      "REIT": 31.5,
      "Gold": -12.5,
    },
    1992: {
      "US Large Cap Stocks": 4.4,
      "US Small Cap Stocks": 14.9,
      "Int'l Dev Stocks": -14.7,
      "Emerging Stocks": 7.8,
      "Cdn Stocks": -3.4,
      "All US Bonds": 4.1,
      "Hi-Yield US Bonds": 11.0,
      "Int'l Bonds": 3.3,
      "Cdn Bonds": 7.5,
      "Cash (T-Bill)": 0.6,
      "REIT": 11.2,
      "Gold": -8.7,
    },
    1993: {
      "US Large Cap Stocks": 7.0,
      "US Small Cap Stocks": 15.5,
      "Int'l Dev Stocks": 28.9,
      "Emerging Stocks": 69.4,
      "Cdn Stocks": 30.3,
      "All US Bonds": 6.7,
      "Hi-Yield US Bonds": 15.1,
      "Int'l Bonds": 10.7,
      "Cdn Bonds": 16.1,
      "Cash (T-Bill)": 0.2,
      "REIT": 16.3,
      "Gold": 13.9,
    },
    1994: {
      "US Large Cap Stocks": -1.5,
      "US Small Cap Stocks": -3.1,
      "Int'l Dev Stocks": 4.9,
      "Emerging Stocks": -10.1,
      "Cdn Stocks": -0.4,
      "All US Bonds": -5.2,
      "Hi-Yield US Bonds": -4.3,
      "Int'l Bonds": -7.3,
      "Cdn Bonds": -4.5,
      "Cash (T-Bill)": 1.3,
      "REIT": 0.4,
      "Gold": -4.9,
    },
    1995: {

      "US Large Cap Stocks": 34.0,
      "US Small Cap Stocks": 25.6,
      "Int'l Dev Stocks": 8.4,
      "Emerging Stocks": -1.9,
      "Cdn Stocks": 12.5,
      "All US Bonds": 15.3,
      "Hi-Yield US Bonds": 16.2,
      "Int'l Bonds": 14.3,
      "Cdn Bonds": 18.6,
      "Cash (T-Bill)": 3.1,
      "REIT": 10.0,
      "Gold": -1.7,
    },
    1996: {

      "US Large Cap Stocks": 18.9,
      "US Small Cap Stocks": 14.3,
      "Int'l Dev Stocks": 2.6,
      "Emerging Stocks": 12.1,
      "Cdn Stocks": 25.5,
      "All US Bonds": 0.3,
      "Hi-Yield US Bonds": 6.0,
      "Int'l Bonds": 8.3,
      "Cdn Bonds": 9.9,
      "Cash (T-Bill)": 1.9,
      "REIT": 31.4,
      "Gold": -7.7,
    },
    1997: {
      "US Large Cap Stocks": 31.0,
      "US Small Cap Stocks": 22.5,
      "Int'l Dev Stocks": 0.0,
      "Emerging Stocks": -18.2,
      "Cdn Stocks": 14.2,
      "All US Bonds": 7.6,
      "Hi-Yield US Bonds": 10.0,
      "Int'l Bonds": 8.9,
      "Cdn Bonds": 8.8,
      "Cash (T-Bill)": 3.5,
      "REIT": 16.8,
      "Gold": -23.2,
    },
    1998: {
      "US Large Cap Stocks": 26.6,
      "US Small Cap Stocks": -4.2,
      "Int'l Dev Stocks": 18.0,
      "Emerging Stocks": -19.4,
      "Cdn Stocks": -2.6,
      "All US Bonds": 6.9,
      "Hi-Yield US Bonds": 3.9,
      "Int'l Bonds": 10.2,
      "Cdn Bonds": 8.1,
      "Cash (T-Bill)": 3.5,
      "REIT": -17.7,
      "Gold": -2.4,
    },
    1999: {
      "US Large Cap Stocks": 17.9,
      "US Small Cap Stocks": 19.9,
      "Int'l Dev Stocks": 23.6,
      "Emerging Stocks": 57.3,
      "Cdn Stocks": 28.4,
      "All US Bonds": -3.4,
      "Hi-Yield US Bonds": -0.2,
      "Int'l Bonds": -0.6,
      "Cdn Bonds": -3.6,
      "Cash (T-Bill)": 2.0,
      "REIT": -6.5,
      "Gold": -1.7,
    },
    2000: {
      "US Large Cap Stocks": -12.0,
      "US Small Cap Stocks": -5.8,
      "Int'l Dev Stocks": -17.1,
      "Emerging Stocks": -29.9,
      "Cdn Stocks": 4.1,
      "All US Bonds": 7.7,
      "Hi-Yield US Bonds": -4.1,
      "Int'l Bonds": 5.4,
      "Cdn Bonds": 6.8,
      "Cash (T-Bill)": 2.5,
      "REIT": 22.2,
      "Gold": -9.6,
    },
    2001: {
      "US Large Cap Stocks": -13.3,
      "US Small Cap Stocks": 1.6,
      "Int'l Dev Stocks": -23.1,
      "Emerging Stocks": -4.4,
      "Cdn Stocks": -13.2,
      "All US Bonds": 6.8,
      "Hi-Yield US Bonds": 1.3,
      "Int'l Bonds": 4.6,
      "Cdn Bonds": 7.3,
      "Cash (T-Bill)": 2.6,
      "REIT": 10.7,
      "Gold": -0.4,
    },
    2002: {
      "US Large Cap Stocks": -23.9,
      "US Small Cap Stocks": -21.8,
      "Int'l Dev Stocks": -17.6,
      "Emerging Stocks": -9.6,
      "Cdn Stocks": -15.7,
      "All US Bonds": 5.8,
      "Hi-Yield US Bonds": -0.6,
      "Int'l Bonds": 4.2,
      "Cdn Bonds": 4.6,
      "Cash (T-Bill)": -0.7,
      "REIT": 1.3,
      "Gold": 20.8,
    },
    2003: {
      "US Large Cap Stocks": 26.2,
      "US Small Cap Stocks": 43.1,
      "Int'l Dev Stocks": 36.1,
      "Emerging Stocks": 54.7,
      "Cdn Stocks": 24.2,
      "All US Bonds": 2.1,
      "Hi-Yield US Bonds": 15.1,
      "Int'l Bonds": 0.4,
      "Cdn Bonds": 4.6,
      "Cash (T-Bill)": -0.9,
      "REIT": 33.3,
      "Gold": 19.2,
    },
    2004: {
      "US Large Cap Stocks": 7.3,
      "US Small Cap Stocks": 16.2,
      "Int'l Dev Stocks": 16.5,
      "Emerging Stocks": 22.1,
      "Cdn Stocks": 12.1,
      "All US Bonds": 1.0,
      "Hi-Yield US Bonds": 5.2,
      "Int'l Bonds": 1.8,
      "Cdn Bonds": 4.9,
      "Cash (T-Bill)": -2.0,
      "REIT": 26.7,
      "Gold": 1.4,
    },
    2005: {
      "US Large Cap Stocks": 1.4,
      "US Small Cap Stocks": 3.9,
      "Int'l Dev Stocks": 9.8,
      "Emerging Stocks": 27.7,
      "Cdn Stocks": 21.4,
      "All US Bonds": -0.9,
      "Hi-Yield US Bonds": -0.5,
      "Int'l Bonds": 1.8,
      "Cdn Bonds": 4.2,
      "Cash (T-Bill)": -0.5,
      "REIT": 8.3,
      "Gold": 13.0,
    },
    2006: {
      "US Large Cap Stocks": 12.9,
      "US Small Cap Stocks": 12.9,
      "Int'l Dev Stocks": 23.1,
      "Emerging Stocks": 26.3,
      "Cdn Stocks": 15.5,
      "All US Bonds": 1.8,
      "Hi-Yield US Bonds": 5.7,
      "Int'l Bonds": 0.5,
      "Cdn Bonds": 2.5,
      "Cash (T-Bill)": 2.1,
      "REIT": 31.8,
      "Gold": 19.3,
    },
    2007: {
      "US Large Cap Stocks": 1.3,
      "US Small Cap Stocks": -2.7,
      "Int'l Dev Stocks": 6.8,
      "Emerging Stocks": 33.6,
      "Cdn Stocks": 7.2,
      "All US Bonds": 2.8,
      "Hi-Yield US Bonds": -1.8,
      "Int'l Bonds": 0.1,
      "Cdn Bonds": 1.3,
      "Cash (T-Bill)": 0.7,
      "REIT": -19.7,
      "Gold": 25.8,
    },
    2008: {
      "US Large Cap Stocks": -37.0,
      "US Small Cap Stocks": -36.1,
      "Int'l Dev Stocks": -41.3,
      "Emerging Stocks": -52.8,
      "Cdn Stocks": -33.8,
      "All US Bonds": 5.1,
      "Hi-Yield US Bonds": -21.3,
      "Int'l Bonds": 5.5,
      "Cdn Bonds": 5.1,
      "Cash (T-Bill)": 2.0,
      "REIT": -37.0,
      "Gold": 5.4,
    },
    2009: {
      "US Large Cap Stocks": 23.3,
      "US Small Cap Stocks": 32.7,
      "Int'l Dev Stocks": 24.9,
      "Emerging Stocks": 71.5,
      "Cdn Stocks": 33.4,
      "All US Bonds": 3.2,
      "Hi-Yield US Bonds": 35.6,
      "Int'l Bonds": 1.6,
      "Cdn Bonds": 4.0,
      "Cash (T-Bill)": -2.4,
      "REIT": 26.3,
      "Gold": 20.2,
    },
    2010: {
      "US Large Cap Stocks": 13.4,
      "US Small Cap Stocks": 26.0,
      "Int'l Dev Stocks": 6.8,
      "Emerging Stocks": 17.2,
      "Cdn Stocks": 14.8,
      "All US Bonds": 5.0,
      "Hi-Yield US Bonds": 10.9,
      "Int'l Bonds": 1.7,
      "Cdn Bonds": 4.2,
      "Cash (T-Bill)": -1.5,
      "REIT": 26.6,
      "Gold": 26.0,
    },
    2011: {
      "US Large Cap Stocks": -0.9,
      "US Small Cap Stocks": -5.5,
      "Int'l Dev Stocks": -15.0,
      "Emerging Stocks": -21.0,
      "Cdn Stocks": -10.8,
      "All US Bonds": 4.6,
      "Hi-Yield US Bonds": 4.2,
      "Int'l Bonds": 0.8,
      "Cdn Bonds": 7.2,
      "Cash (T-Bill)": -2.9,
      "REIT": 5.5,
      "Gold": 5.5,
    },
    2012: {
      "US Large Cap Stocks": 14.0,
      "US Small Cap Stocks": 16.2,
      "Int'l Dev Stocks": 16.5,
      "Emerging Stocks": 16.8,
      "Cdn Stocks": 6.3,
      "All US Bonds": 2.4,
      "Hi-Yield US Bonds": 12.5,
      "Int'l Bonds": 4.5,
      "Cdn Bonds": 2.8,
      "Cash (T-Bill)": -1.7,
      "REIT": 15.7,
      "Gold": 6.5,
    },
    2013: {
      "US Large Cap Stocks": 30.4,
      "US Small Cap Stocks": 35.8,
      "Int'l Dev Stocks": 20.3,
      "Emerging Stocks": -6.4,
      "Cdn Stocks": 11.7,
      "All US Bonds": -3.6,
      "Hi-Yield US Bonds": 3.1,
      "Int'l Bonds": -0.4,
      "Cdn Bonds": -2.4,
      "Cash (T-Bill)": -1.5,
      "REIT": 0.9,
      "Gold": -29.0,
    },
    2014: {
      "US Large Cap Stocks": 12.8,
      "US Small Cap Stocks": 6.7,
      "Int'l Dev Stocks": -6.4,
      "Emerging Stocks": -0.2,
      "Cdn Stocks": 9.0,
      "All US Bonds": 5.1,
      "Hi-Yield US Bonds": 3.9,
      "Int'l Bonds": 8.0,
      "Cdn Bonds": 7.2,
      "Cash (T-Bill)": -0.7,
      "REIT": 29.3,
      "Gold": -1.2,
    },
    2015: {
      "US Large Cap Stocks": 0.6,
      "US Small Cap Stocks": -4.3,
      "Int'l Dev Stocks": -0.9,
      "Emerging Stocks": -16.0,
      "Cdn Stocks": -9.7,
      "All US Bonds": -0.3,
      "Hi-Yield US Bonds": -2.0,
      "Int'l Bonds": 0.3,
      "Cdn Bonds": 1.9,
      "Cash (T-Bill)": -0.7,
      "REIT": 1.6,
      "Gold": -12.3,
    },
    2016: {
      "US Large Cap Stocks": 9.7,
      "US Small Cap Stocks": 15.9,
      "Int'l Dev Stocks": 0.4,
      "Emerging Stocks": 9.5,
      "Cdn Stocks": 19.3,
      "All US Bonds": 0.5,
      "Hi-Yield US Bonds": 9.0,
      "Int'l Bonds": 2.5,
      "Cdn Bonds": 0.2,
      "Cash (T-Bill)": -1.8,
      "REIT": 6.3,
      "Gold": 6.6,
    },
    2017: {
      "US Large Cap Stocks": 19.3,
      "US Small Cap Stocks": 13.8,
      "Int'l Dev Stocks": 23.8,
      "Emerging Stocks": 28.7,
      "Cdn Stocks": 7.1,
      "All US Bonds": 1.4,
      "Hi-Yield US Bonds": 4.9,
      "Int'l Bonds": 0.3,
      "Cdn Bonds": 0.6,
      "Cash (T-Bill)": -1.3,
      "REIT": 2.8,
      "Gold": 9.3,
    },
    2018: {
      "US Large Cap Stocks": -6.2,
      "US Small Cap Stocks": -11.0,
      "Int'l Dev Stocks": -16.1,
      "Emerging Stocks": -16.2,
      "Cdn Stocks": -10.7,
      "All US Bonds": -1.9,
      "Hi-Yield US Bonds": -4.7,
      "Int'l Bonds": 1.0,
      "Cdn Bonds": -0.6,
      "Cash (T-Bill)": -0.1,
      "REIT": -7.7,
      "Gold": -3.2,
    },
    2019: {
      "US Large Cap Stocks": 28.5,
      "US Small Cap Stocks": 24.5,
      "Int'l Dev Stocks": 19.3,
      "Emerging Stocks": 17.6,
      "Cdn Stocks": 20.3,
      "All US Bonds": 6.3,
      "Hi-Yield US Bonds": 13.3,
      "Int'l Bonds": 5.5,
      "Cdn Bonds": 4.6,
      "Cash (T-Bill)": -0.1,
      "REIT": 26.1,
      "Gold": 15.9,
    },
    2020: {
      "US Large Cap Stocks": 17.0,
      "US Small Cap Stocks": 17.7,
      "Int'l Dev Stocks": 8.9,
      "Emerging Stocks": 13.9,
      "Cdn Stocks": 4.3,
      "All US Bonds": 6.3,
      "Hi-Yield US Bonds": 4.1,
      "Int'l Bonds": 3.3,
      "Cdn Bonds": 7.1,
      "Cash (T-Bill)": -0.7,
      "REIT": -5.8,
      "Gold": 23.6,
    }
  }

  const graphcolors = {
    "US Large Cap Stocks": {
      background: '#b4c6e7',
      text: 'US Large<br/>Cap Stocks',
      color: '#000',
    },
    "US Small Cap Stocks": {
      background: '#ddebf7',
      text: 'US Small<br/>Cap Stocks',
      color: '#000',
    },
    "Int'l Dev Stocks": {
      background: '#5b9bd5',
      text: "Int'l Dev<br/>Stocks",
      color: '#fff',
    },
    "Emerging Stocks": {
      background: '#7030a0',
      text: 'Emerging<br/>Stocks',
      color: '#fff',
    },
    "Cdn Stocks": {
      background: '#c9f',
      text: 'Cdn Stocks',
      color: '#000',
    },
    "All US Bonds": {
      background: '#ed7d31',
      text: 'All US<br/>Bonds',
      color: '#000',
    },
    "Hi-Yield US Bonds": {
      background: '#fce4d6',
      text: 'Hi-Yield US<br/>Bonds',
      color: '#000',
    },
    "Int'l Bonds": {
      background: '#f39',
      text: "Int'l Bonds",
      color: '#fff',
    },
    "Cdn Bonds": {
      background: '#c00000',
      text: 'Cdn Bonds',
      color: '#fff',
    },
    "Cash (T-Bill)": {
      background: '#548235',
      text: 'Cash<br/>(T-Bill)',
      color: '#fff',
    },
    "REIT": {
      background: '#806000',
      text: 'REIT',
      color: '#fff',
    },
    "Gold": {
      background: '#ffc000',
      text: 'Gold',
      color: '#000',
    },
  }

  const avgValues = [["Emerging Stocks", 8.7],
  ["US Large Cap Stocks", 8.7],
  ["US Small Cap Stocks", 8.2],
  ["REIT", 7.3],
  ["Cdn Stocks", 6.1],
  ["Int'l Dev Stocks", 6.0],
  ["Cdn Bonds", 5.4],
  ["Hi-Yield US Bonds", 5.2],
  ["All US Bonds", 4.0],
  ["Int'l Bonds", 3.6],
  ["Gold", 2.3],
  ["Cash (T-Bill)", 0.7]];

  const showTableData = [];
  const tableData = {};
  _.forEach(graphData, function (tableValue, tableIndex) {
    var sortable = [];
    for (var vehicle in tableValue) {
      sortable.push([vehicle, tableValue[vehicle]]);
    }
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    tableData[tableIndex] = sortable;
    showTableData[tableIndex] = sortable;
  });
  const tableYearsList = Object.keys(tableData);

  let dataObject = {};
  _.forEach(graphData, function (value) {
    _.forEach(value, function (value1, index1) {
      if (dataObject[index1] === undefined) {
        dataObject[index1] = {};
        dataObject[index1].data = [];
        dataObject[index1].color = graphcolors[index1]['background'];
      }
      dataObject[index1].name = index1;
      dataObject[index1].data.push(value1);
    })
  })


  React.useEffect(() => {
    let ydata = [];
    var yearData = graphData[year];
    _.forEach(yearData, function (value, keyIndex) {
      ydata.push(
        {
          name: keyIndex,
          y: value,
          drilldown: keyIndex,
          color: "#0069aa"
        }
      )
    })
    setData(ydata)
  }, [year]);

  React.useEffect(() => {
    let yassetData = [];
    _.forEach(graphData, function (value, keyIndex) {
      yassetData.push(
        {
          name: keyIndex,
          y: value[asset],
          drilldown: keyIndex,
          color: "#0069aa"
        }
      )
    })
    setAssetData(yassetData);
  }, [asset]);

  const yearsListOptions = Object.keys(graphData);
  const assetsListOptions = Object.keys(graphData[2019]);

  console.log('tableYearsList', tableYearsList, tableData);

  let windowHeight = $(window).height();
  if (windowHeight > 500) {
    windowHeight = 500;
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.contentBlock}>
      <p>
        Long-term investments are investments made for the long-term, which can be defined as more than one year. When planning, think of your long-term investments as money set aside that will be used for future large purchases or to grow your wealth. Because you are investing for the long-term with money that you do not plan to need for years, you can take more risk in the hope of achieving a higher return.
      </p>
      <p>So what are some types of long-term investments? Explore the many tabs below and discover some common assets and their historical performance.</p>
      <div className={classes.tabSection}>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="primary"
          textColor="primary"
          className={`${classes.tabButtons} ${classes.tabButtonsHistoryInvestments}`}
        >
          <Tab label="Historical Performance Block" {...a11yProps(0)} className={value === 0 ? classes.activeTab : ''} />
          <Tab label="Historical Performance Line" {...a11yProps(1)} className={value === 1 ? classes.activeTab : ''} />
          <Tab label="Historical Performance by Year" {...a11yProps(2)} className={value === 2 ? classes.activeTab : ''} />
          <Tab label="Historical Performance by Asset Class" {...a11yProps(3)} className={value === 3 ? classes.activeTab : ''} />
        </Tabs>
        <SwipeableViews
          className={`${classes.tabsContent} ${classes.tabContentBaseIncome}`}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Scrollbars renderThumbVertical={renderThumb} className={classes.tableSmoothScrool}>
              <div >
                <table className={classes.historicaYearTable}>
                  <thead>
                    <tr>
                      {tableYearsList && tableYearsList.map((index, l) =>
                        <th>{index}</th>
                      )}
                      <th>Average</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[0][0]].background, color: graphcolors[data[0][0]].color }}>
                          <span>{data[0][0]}</span><br />
                          <span>{data[0][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[0][0]].background, color: graphcolors[avgValues[0][0]].color }}>
                        <span>{avgValues[0][0]}</span><br />
                        <span>{avgValues[0][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[1][0]].background, color: graphcolors[data[1][0]].color }}>
                          <span>{data[1][0]}</span><br />
                          <span>{data[1][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[1][0]].background, color: graphcolors[avgValues[1][0]].color }}>
                        <span>{avgValues[1][0]}</span><br />
                        <span>{avgValues[1][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[2][0]].background, color: graphcolors[data[2][0]].color }}>
                          <span>{data[2][0]}</span><br />
                          <span>{data[2][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[2][0]].background, color: graphcolors[avgValues[2][0]].color }}>
                        <span>{avgValues[2][0]}</span><br />
                        <span>{avgValues[2][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[3][0]].background, color: graphcolors[data[3][0]].color }}>
                          <span>{data[3][0]}</span><br />
                          <span>{data[3][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[3][0]].background, color: graphcolors[avgValues[3][0]].color }}>
                        <span>{avgValues[3][0]}</span><br />
                        <span>{avgValues[3][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[4][0]].background, color: graphcolors[data[4][0]].color }}>
                          <span>{data[4][0]}</span><br />
                          <span>{data[4][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[4][0]].background, color: graphcolors[avgValues[4][0]].color }}>
                        <span>{avgValues[4][0]}</span><br />
                        <span>{avgValues[4][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[5][0]].background, color: graphcolors[data[5][0]].color }}>
                          <span>{data[5][0]}</span><br />
                          <span>{data[5][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[5][0]].background, color: graphcolors[avgValues[5][0]].color }}>
                        <span>{avgValues[5][0]}</span><br />
                        <span>{avgValues[5][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[6][0]].background, color: graphcolors[data[6][0]].color }}>
                          <span>{data[6][0]}</span><br />
                          <span>{data[6][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[6][0]].background, color: graphcolors[avgValues[6][0]].color }}>
                        <span>{avgValues[6][0]}</span><br />
                        <span>{avgValues[6][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[7][0]].background, color: graphcolors[data[7][0]].color }}>
                          <span>{data[7][0]}</span><br />
                          <span>{data[7][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[7][0]].background, color: graphcolors[avgValues[7][0]].color }}>
                        <span>{avgValues[7][0]}</span><br />
                        <span>{avgValues[7][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[8][0]].background, color: graphcolors[data[8][0]].color }}>
                          <span>{data[8][0]}</span><br />
                          <span>{data[8][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[8][0]].background, color: graphcolors[avgValues[8][0]].color }}>
                        <span>{avgValues[8][0]}</span><br />
                        <span>{avgValues[8][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[9][0]].background, color: graphcolors[data[9][0]].color }}>
                          <span>{data[9][0]}</span><br />
                          <span>{data[9][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[9][0]].background, color: graphcolors[avgValues[9][0]].color }}>
                        <span>{avgValues[9][0]}</span><br />
                        <span>{avgValues[9][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[10][0]].background, color: graphcolors[data[10][0]].color }}>
                          <span>{data[10][0]}</span><br />
                          <span>{data[10][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[10][0]].background, color: graphcolors[avgValues[10][0]].color }}>
                        <span>{avgValues[10][0]}</span><br />
                        <span>{avgValues[10][1]}</span>
                      </td>
                    </tr>
                    <tr>
                      {showTableData.map((data, index) => (
                        <td style={{ backgroundColor: graphcolors[data[11][0]].background, color: graphcolors[data[11][0]].color }}>
                          <span>{data[11][0]}</span><br />
                          <span>{data[11][1]}</span>
                        </td>
                      )
                      )}
                      <td style={{ backgroundColor: graphcolors[avgValues[11][0]].background, color: graphcolors[avgValues[11][0]].color }}>
                        <span>{avgValues[11][0]}</span><br />
                        <span>{avgValues[11][1]}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Scrollbars>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: 'line',
                  scrollablePlotArea: {
                    scrollPositionX: 0
                  },
                  height: (windowHeight),
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left'
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px'
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y'
                  }
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [{
                        textKey: 'downloadPNG',
                        onclick: function () {
                          this.exportChart();
                        },
                      }, {
                        textKey: 'downloadJPEG',
                        onclick: function () {
                          this.exportChart({
                            type: 'image/jpeg'
                          });
                        }
                      }, {
                        textKey: 'downloadPDF',
                        onclick: function () {
                          this.exportChart({
                            type: 'application/pdf'
                          });
                        }
                      }, {
                        textKey: 'downloadSVG',
                        onclick: function () {
                          this.exportChart({
                            type: 'image/svg+xml'
                          });
                        }
                      }]
                    }
                  }
                },
                credits: {
                  enabled: false
                },
                title: {
                  text: 'Historical Performance of Asset Classes'
                },
                xAxis: [{
                  // tickInterval: 27,
                  categories: Object.keys(graphData),
                  labels: {
                    // formatter: function() {
                    // this.value = this.value.split(" ");
                    // return parseInt(this.value[this.value.length-1]);
                    // },
                    style: {
                      color: '#000000',
                      fontSize: '13px'
                    }
                  }
                }],
                yAxis: [{ // Primary yAxis
                  tickInterval: 20,
                  min: -60,
                  max: 80,
                  labels: {
                    format: '{value:.1f}%',
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }
                }],
                tooltip: {
                  headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true,
                  valueDecimals: 1,
                  valueSuffix: '%',
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                },
                legend: {
                  itemStyle: {
                    fontSize: '14px'
                  }
                },
                plotOptions: {
                  spline: {
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2
                      }
                    },
                    marker: {
                      enabled: false
                    },

                  }
                },
                series: Object.values(dataObject)
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <div className={classes.selectYear}>
              <span>Year</span>
              <select onChange={handleyear} value={year}>
                {yearsListOptions.map((year) =>
                  <option value={year}>{year}</option>
                )}
              </select>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  // width: graphMainWidth,
                  type: 'column',
                  height: (windowHeight),
                },
                exporting: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                title: {
                  text: 'Historical Performance by Asset Class'
                },
                xAxis: {
                  type: 'category',
                  labels: {
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }
                },
                yAxis: {
                  title: {
                    text: 'Real Annual Returns (%)',
                  },
                  labels: {
                    formatter: function () {
                      if (this.value < 0) {
                        return this.value + '%';
                      } else {
                        return this.value + '%';
                      }
                    },
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }

                },
                legend: {
                  enabled: false
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: false,
                      format: '{point.y:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    }
                  }
                },

                tooltip: {
                  enabled: true,
                  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',

                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }

                },
                series: [
                  {
                    name: "",
                    colorByPoint: true,
                    data: data
                  }
                ]
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <div className={classes.selectYear}>
              <span>Assets</span>
              <select onChange={handleAsset} value={asset}>
                {assetsListOptions.map((asset) =>
                  <option value={asset} >{asset}</option>
                )}
              </select>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  // width: graphMainWidth,
                  type: 'column',
                  height: (windowHeight),
                },
                exporting: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                title: {
                  text: 'Historical Performance by Asset Class'
                },
                xAxis: {
                  type: 'category',
                  labels: {
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }
                },
                yAxis: {
                  title: {
                    text: 'Real Annual Returns (%)',
                  },
                  labels: {
                    formatter: function () {
                      if (this.value < 0) {
                        return this.value + '%';
                      } else {
                        return this.value + '%';
                      }
                    },
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  }

                },
                legend: {
                  enabled: false
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: false,
                      format: '{point.y:.1f}%',
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    }
                  }
                },

                tooltip: {
                  enabled: true,
                  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',

                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }

                },
                series: [
                  {
                    name: "",
                    colorByPoint: true,
                    data: assetData
                  }
                ]
              }}

            />
          </TabPanel>
        </SwipeableViews>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
    </div>
  );
};

MonthlyContribution.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
  // getInvestmentObject: PropTypes.object.isRequired,
  // handleInvestmentData: PropTypes.func.isRequired,
};

export default withStyles(styles)(MonthlyContribution);
