import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import styles from './styles';
import sliderImage from '../../assets/img/life-event-library/financial-slider_new.jpg';

const LifeEventImageOne = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={ `${classes.containerNew} ${classes.eventOneColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Begin Your Journey
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Financial Independence
                        </Typography>
                        <ul>
                          <li>Enter your start year to set the Simulator Engine in motion</li>
                          <li>Make assumptions about your annual income growth and inflation</li>
                          <li>Enter cash on hand and cash balance desired before investing</li>
                          <li>Change these details at any time – the Simulator Engine will instantly reconfigure your data.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>Enter My Start Year</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ sliderImage } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Begin Your Journey
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Build Your Plan
                        </Typography>
                        <ul>
                          <li>To minimize risk while maximizing reward</li>
                          <li>To test your assumptions, hone your strategies, find your best options</li>
                          <li>To forecast financial decisions before you even make them</li>
                          <li>To strive for financial independence.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>Enter My Start Year</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ sliderImage } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageOne.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageOne );
