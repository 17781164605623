const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #1f96e0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-700',
      fontSize: '36px',
      fontWeight: 'normal',
      color: '#0097e6',    
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      '& > img': {
        marginRight: '25px',
        height: '50px',
        cursor: 'pointer',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
          height: '40px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
    '@media (max-width: 767px)': {
      width: '20px',
      '& span': {
        height: '3px',
        width: '20px',
      }
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '20px',
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '0 auto 0',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      color: '#333',
      lineHeight: '25px',
    },    
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
  },
  storyBox: {
    borderRadius: '6px',
    padding: '40px',
    border: '1px solid #337ab7',
    textAlign: 'center',
    margin: '36px',
    cursor: 'pointer',
    color: '#19436e',
    fontSize: '24px',
    [theme.breakpoints.down('xs')]: {
      margin:'10px',
    },
  },
  startButton: {
    paddingTop: '20px',
    float: 'right',
    paddingBottom: '50px',
    '& button': {
      padding: '10px 20px',
      background: '#337ab7',
      textTransform: 'uppercase',
      border: '1px solid #337ab7',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '18px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',   
      fontFamily: 'MuseoSans-300', 
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },  
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsLogoImage: {   
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '200px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '180px',
        },
      },
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  mcsDevelopVision: {
    background:'#004375',
    padding: '10px 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px',
    },
  },
  backtoLevel: {
    '& a': {
      textDecoration: 'underline',
      color: '#000',
      fontFamily: 'MuseoSans-300',
      '&:hover':{
        color: '#f89b22 !important',
      },
    },
  },
  activityStatus: {
    '& h4': {
      fontSize:'22px',
      color: '#fff !important',
      margin: '0px',
      fontFamily: 'MuseoSans-300',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
      [theme.breakpoints.down('xs')]: {
        display:'block',
        fontSize:'16px',
      },
      '& a': {
        color: '#fff !important',
        borderBottom: '1px solid',
        lineHeight: 'normal',
        marginLeft: '5px',
        '&:hover':{
          color: '#f89b22 !important',
        },
      },
    },    
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap:'wrap',
      justifyContent: 'center',
    },
  },
  activityImage: {
    '& img': {
      width: '30px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  greenTick: {
    '& img': {
      width: '20px',
      verticalAlign: 'middle',
      marginright: '5px',
    },
  },
  reviewLevels: {   
    position: 'absolute',
    right: '0',
    minWidth: '300px',
    padding: '10px 0',
    background: '#fff',
    marginTop: '20px',
    zIndex: '9999',
    color: '#333',
    '&::before': {
      borderBottom: '11px solid #fff',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '92%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    '& i': {
      position: 'absolute',
      top: '3px',
      right: '3px',
      cursor: 'pointer',
    },
  },
  bookBlock: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
     marginLeft:'10px',
    },
  },
  panelDefault: {
    borderColor: '#0069aa',
    textAlign: 'left',
    background: '0 0',
    width: '90%',
    margin: '0 auto 3px',
    borderRadius: '0',
    border: '1px solid transparent',   
    webkitBoxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
    boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
  },
  panelBody: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  titleName: {
    width: '96%',
    display: 'flex',
  },
  mcsNumber: {
    paddingRight: '5px',
  },
  greentickMark: {
    width: '4%',
    '& img': {
      width: '10px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '12px !important',
    textAlign: 'center',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    maxWidth: '200px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },    
  },
  careerSketchCards: {
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0px',
      '& li': {
        width: '14.285%',
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        '& figure': {
          width: '100%',
          display: 'flex',
          margin: '0',
          '& img': {
            width: '100%',
          },
        },
        '&:hover': {
          '& figure + div': {
            opacity: '1',
          },
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
    },
  },
  careerCaption: {
    position: 'absolute',
    top: '0',
    height: '100%',
    color: theme.palette.common.white,
    background: 'rgba(51, 51, 51, 0.65)',
    width: '100%',
    left: '0',
    textAlign: 'center',
    opacity: '0',
    display: 'flex',
    alignItems: 'center',
    transition: 'all ease-in-out 0.3s',
    '& > div': {
      padding: '13px 8px',
      width: 'calc(100% - 16px)',
      lineHeight:'11px',
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '11px',
      margin: '0',
      '& span': {
        display: 'block',
        marginTop: '5px',
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    '& span': {
      fontSize: '18px',
      marginTop: '15px',
      display: 'block',
      lineHeight: '20px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& h3': {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '20px',
        margin: '0',
      },
      '& span': {
        fontSize: '14px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '15px',
        lineHeight: '18px',
      },
      '& span': {
        fontSize: '13px',
      },
    },
  }, 
 
  careerMiddleSecond: {
    position: 'absolute',
    width: '42.9%',
    height: '50%',
    background: 'rgba(0, 95, 153, 0.9)',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '0',
    bottom: '0',
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '& sup': {
      fontSize: '11px',
    },
    '& > div': {
      padding: '30px 42px',
    },
    '& h3': {
      fontSize: '2.12vw',
      lineHeight: '2.92vw',
      textAlign: 'left',
      margin: '15px 0 0',
    },
    '& p': {
      fontSize: '1.4vw',
      lineHeight: '2.2vw',
      textAlign: 'left',
      fontFamily: '"MuseoSans-100"',
    },
    [theme.breakpoints.down( 'sm' )]: {
      height: '20%',
      width: '100%',
      '& h3': {
        fontSize: '20px',
        lineHeight: '20px',
      },
      '& > div': {
        padding: '15px',
      },
      '& p': {
        fontSize: '11px',
        lineHeight: '17px',
        textAlign: 'left',
      },
    },
  },
  orderListData: {
    padding: '0 24px !important',
    '& h3': {
      padding: '10px 0 10px 0',
      color: '#ffffff',
      margin: '0',
      fontFamily: 'MuseoSans-500',
      fontSize: '22px',
      lineHeight: '1.4',
      fontWeight: '300',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '20px',
      }
    },
    '& ol': {
      paddingLeft: '15px',
      textAlign: 'left',
      marginTop: '0px',
      marginBottom: '10px',
      '& li': {
        color: '#fff',
        fontSize: '16px',
        fontFamily: 'MuseoSans-100',
        lineHeight: '24px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '13px',
          lineHeight: '20px',
        }
      },
    },
  },
  tooltipInfoNew: {
    width: '200px',
    background: '#0069aa !important',
    opacity: '1 !important',
    color: '#fff !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 5px !important',
    fontSize: '14px !important',
  },
  infoIcon: {
    '& img': {
      filter: 'brightness(0) invert(1)',
    },
  },
  seeButton:{
    color: theme.palette.common.white,
    border: '2px solid',
    BodrerColor: theme.palette.common.white,  
    borderRadius: '10px',   
    display: 'inline-block',
    cursor: 'pointer',
    margin: '9px 0 10px 0',
    textDecoration: 'none',
    fontSize: '15.04px',
    lineHeight: '1',
    padding: '10px 19px',
    '&:hover': {
      background: theme.palette.common.green,
      border: '2px solid #83a947',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '5px 15px',
      fontSize: '15px',
    },
  },
  
  popupContent: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    zIndex: '99',
    transition: ' height ease-in-out 0.5s',
    height: '0',
    overflow: 'hidden',
    background: '#000',
    '& > div': {
      height: '100%',
      width: '100%',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '0',
          transition: ' height ease-in-out 0.5s',
        },
      },

    },
  },
  watchIntroPopup: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    '& > div': {
      height: '100%',
      maxWidth: '80%',
      margin: '0 auto',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '100%',
        },
      },

    },
  },
  closeIcon: {
    right: '10px',
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    top: '10px',
    color: theme.palette.common.orange,
    fontSize: '30px',
    cursor: 'pointer',
    outLine: 'none',
  },
});

export default styles;
