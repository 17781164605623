import * as moduleServices from '../calculations/modules-services';


function incomeStatementGraph() {

  var incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
      let graphsData = {};
      //Total Income  
      graphsData[1] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {

                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Gross Income Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Total Taxes
      graphsData[2] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {

                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Taxes Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Income After Taxes  
      graphsData[3] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Income After Taxes Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Total Living Expenses  
      graphsData[4] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Living Expenses Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Total Car Expenses  
      graphsData[5] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Car Expenses Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Total Real Estate Expenses  
      graphsData[6] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Real Estate Expenses Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      } 

      //Total Loan Payments  
      graphsData[7] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },  
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Interest Expenses Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Total Expenses  
      graphsData[8] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Total Expenses Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      //Net Income  
      graphsData[9] = {
          chart: {
              renderTo: "container",
              defaultSeriesType: 'bar',
              type: 'column',
              width: 100,
              height: 30,
              spacingBottom: -8,
              spacingTop: -10,
              spacingLeft: 0,
              spacingRight: 0,
              verticalAlign: 'top',
              top: 0,
              plotBackgroundColor: '#DDDAD6'
          },
          exporting: {
            enabled: false
          },
          title: {
              text: null
          },  
          xAxis: {
              labels:
              {
                  enabled: false
              }
          },
          yAxis: {
              gridLineWidth: 0,
              title: {
                  text: null
              },
              labels:
              {
                  enabled: false
              }
          },
          
          legend: {
              enabled: false
          },
          credits: {
              verticalAlign: 'top',
              enabled: false
          },
          plotOptions: {
              allowPointSelect: false,
              series: {
                  negativeColor: '#BF0000'
              }
          },
          
          tooltip: {
              enabled: false
          },
          series: [{
              data: incomeStatementCompleteData['Net Income Graph'],
              color: '#2F75B5',
              pointWidth: 5
          }]
      }

      return graphsData; 
    }
    export default incomeStatementGraph;
