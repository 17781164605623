import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const BuyLeaseCostAnalysis = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Buy or Lease? A Cost Analysis</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/buy-vs-lease-cost-analysis.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Buy or Lease? A Cost Analysis
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/buy-lease-cost-analysis-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Let&apos;s walk through two scenarios: financing the
                          purchase of a $35,000 car versus leasing that same
                          car. In both examples, you have $5,000 for a down
                          payment. Do you think buying or leasing would be more
                          economical?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <Table className={classes.costTable}>
                          <thead>
                            <tr>
                              <th />
                              <th>Buy</th>
                              <th>Lease</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Price</td>
                              <td>$35,000.00</td>
                              <td>$35,000.00</td>
                            </tr>
                            <tr>
                              <td>Down Payment</td>
                              <td>$5,000.00</td>
                              <td>$5,000.00</td>
                            </tr>
                            <tr>
                              <td>Term</td>
                              <td>36 months</td>
                              <td>36 months</td>
                            </tr>
                            <tr>
                              <td>Monthly Payment</td>
                              <td>$885.72</td>
                              <td>$354.00</td>
                            </tr>
                            <tr>
                              <td>Buyback Price</td>
                              <td>--</td>
                              <td>$21,000.0</td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          When you finance the purchase of a car, you make 36
                          months of loan payments and then own the vehicle.
                        </p>
                        <p>
                          On the other hand, when you lease the vehicle, you
                          make 36 months of payments and have an option to give
                          the vehicle back or purchase the vehicle at
                          what&apos;s called the &quot;buyback price.&quot;
                        </p>
                        <p>
                          Let&apos;s say you want to purchase the vehicle at the
                          end of the lease for the $21,000 buyback price.
                        </p>
                        <Table className={classes.costTable}>
                          <thead>
                            <tr>
                              <th />
                              <th>Buy</th>
                              <th>Lease</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Down Payment</td>
                              <td>$5,000.00</td>
                              <td>$5,000.00</td>
                            </tr>
                            <tr>
                              <td>Monthly Paymentst</td>
                              <td>$31,885.00</td>
                              <td>$12,744.00</td>
                            </tr>
                            <tr>
                              <td>Buyback Price</td>
                              <td>3$0.00</td>
                              <td>$21,000.00</td>
                            </tr>
                            <tr>
                              <td>Total Cost to Own</td>
                              <td>
                                <b>$36,885.90</b>
                              </td>
                              <td>
                                <b>$38,744.00</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          As you can see in this scenario, when you buy a
                          $35,000 car with the above assumptions, the total cost
                          to own is more economical than leasing. Buying the car
                          would save you $1,858 in 36 months.
                        </p>
                        <p>
                          Now you have a quick way to compare buying vs leasing.
                          If you can afford the larger monthly payment,
                          financing the purchase of a car is typically more
                          economical than leasing.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
BuyLeaseCostAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BuyLeaseCostAnalysis);
