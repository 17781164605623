function InvestmentsOutput( module1Data, module9Data, investmentsSheetData ) {
  /** ******************** Investments Output Backend *********************** */
  let year = 0;
  let yearLimit = 0;
  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 30;

  const yearsList = [];
  const investmentsData1 = {};
  investmentsData1.MEDIUM = {};
  investmentsData1.LOW = {};
  investmentsData1.HIGH = {};

  /** Short-Term * */
  // Short-Term Data
  const mediumShortTermArr = [];
  const lowShortTermArr = [];
  const highShortTermArr = [];
  const annualContributionShortTermArr = [];

  // MEDIUM
  investmentsData1.MEDIUM['Short-Term Investments'] = {};

  // LOW
  investmentsData1.LOW['Short-Term Investments'] = {};

  // HIGH
  investmentsData1.HIGH['Short-Term Investments'] = {};

  /** 401K * */
  // 401k Data
  const medium401kArr = [];
  const low401kArr = [];
  const high401kArr = [];
  const annualContribution401kArr = [];

  // MEDIUM
  investmentsData1.MEDIUM['401k'] = {};

  // LOW
  investmentsData1.LOW['401k'] = {};

  // HIGH
  investmentsData1.HIGH['401k'] = {};

  /** Roth IRA * */
  // Roth IRA Graphs Data
  const mediumRothIraArr = [];
  const lowRothIraArr = [];
  const highRothIraArr = [];
  const annualContributionRothIraArr = [];

  // MEDIUM
  investmentsData1.MEDIUM['Roth IRA'] = {};

  // LOW
  investmentsData1.LOW['Roth IRA'] = {};

  // HIGH
  investmentsData1.HIGH['Roth IRA'] = {};

  /** Analysis Data * */
  // Short Term
  let mediumShort = 0;
  let lowShort = 0;
  let highShort = 0;

  if (
    module9Data !== undefined
    && module9Data.graph9 !== undefined
    && module9Data.graph9.annualExpectedReturn !== undefined
  ) {
    mediumShort = parseFloat( module9Data.graph9.annualExpectedReturn );
  }
  if ( mediumShort > 0 ) {
    lowShort = mediumShort - mediumShort / 2;
    highShort = mediumShort + mediumShort / 2;
  }

  // 401K
  let medium401k = 0;
  let low401k = 0;
  let high401k = 0;
  if (
    module9Data.annualPreTaxReturn !== undefined
    && module9Data.annualPreTaxReturn !== ''
  ) {
    medium401k = parseFloat( module9Data.annualPreTaxReturn );
  }
  if ( medium401k > 0 ) {
    low401k = medium401k - medium401k / 2;
    high401k = medium401k + medium401k / 2;
  }

  // Roth IRA
  let mediumIRA = 0;
  let lowIRA = 0;
  let highIRA = 0;
  if (
    module9Data.rothAnnualPreTaxReturn !== undefined
    && module9Data.rothAnnualPreTaxReturn !== ''
  ) {
    mediumIRA = parseFloat( module9Data.rothAnnualPreTaxReturn );
  }
  if ( mediumIRA > 0 ) {
    lowIRA = mediumIRA - mediumIRA / 2;
    highIRA = mediumIRA + mediumIRA / 2;
  }
  /** End of Analysis Data * */

  /** Short Term * */
  // MEDIUM
  let begBalance = 0;
  let begBalanceShortTerm = 0;
  if (
    module9Data !== undefined
    && module9Data.graph9 !== undefined
    && module9Data.graph9.beginningBalance !== undefined
  ) {
    begBalanceShortTerm = module9Data.graph9.beginningBalance;
    begBalance = begBalanceShortTerm;
  }

  let contributionShortTerm = 0;
  if (
    module9Data !== undefined
    && module9Data.graph9 !== undefined
    && module9Data.graph9.annualContribution !== undefined
  ) {
    contributionShortTerm = module9Data.graph9.annualContribution / 12;
  }
  let earningsShortTerm = 0;
  let endBalanceShortTerm = 0;
  // LOW
  let begBalanceShortTermLow = begBalanceShortTerm;
  // const contributionShortTermLow = contributionShortTerm;
  let earningsShortTermLow = 0;
  let endBalanceShortTermLow = 0;
  // HIGH
  let begBalanceShortTermHigh = begBalanceShortTerm;
  // const contributionShortTermHigh = contributionShortTerm;
  let earningsShortTermHigh = 0;
  let endBalanceShortTermHigh = 0;

  /** 401k * */
  // MEDIUM
  let begBalance401k = 0;
  if ( module9Data.beginningBalance401k !== undefined ) {
    begBalance401k = parseFloat( module9Data.beginningBalance401k );
  }
  let earnings401k = 0;
  let endBalance401k = 0;
  // LOW
  let begBalance401kLow = begBalance401k;
  let earnings401kLow = 0;
  let endBalance401kLow = 0;
  // HIGH
  let begBalance401kHigh = begBalance401k;
  let earnings401kHigh = 0;
  let endBalance401kHigh = 0;

  /** Roth IRA * */
  // MEDIUM
  let begBalanceRoth = 0;
  if ( module9Data.rothBeginningBalance !== undefined ) {
    begBalanceRoth = parseFloat( module9Data.rothBeginningBalance );
  }
  let earningsRoth = 0;
  let endBalanceRoth = 0;
  // LOW
  let begBalanceRothLow = begBalanceRoth;
  let earningsRothLow = 0;
  let endBalanceRothLow = 0;
  // HIGH
  let begBalanceRothHigh = begBalanceRoth;
  let earningsRothHigh = 0;
  let endBalanceRothHigh = 0;

  let k = 0;
  let contributionsTotal = 0;
  let earningsTotal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    yearsList.push( year );

    /** Short Term * */
    // Medium
    investmentsData1.MEDIUM['Short-Term Investments'][year] = {};
    // let begBalanceTotalShortTerm = 0;
    let contributionTotalShortTerm = 0;
    let earningsTotalShortTerm = 0;
    // Low
    investmentsData1.LOW['Short-Term Investments'][year] = {};
    // let begBalanceTotalShortTermLow = 0;
    let contributionTotalShortTermLow = 0;
    let earningsTotalShortTermLow = 0;
    // High
    investmentsData1.HIGH['Short-Term Investments'][year] = {};
    // let begBalanceTotalShortTermHigh = 0;
    let contributionTotalShortTermHigh = 0;
    let earningsTotalShortTermHigh = 0;

    /** 401k * */
    // Medium
    investmentsData1.MEDIUM['401k'][year] = {};
    let contributionTotal401k = 0;
    let earningsTotal401k = 0;
    // Low
    investmentsData1.LOW['401k'][year] = {};
    let contributionTotal401kLow = 0;
    let earningsTotal401kLow = 0;
    // High
    investmentsData1.HIGH['401k'][year] = {};
    let contributionTotal401kHigh = 0;
    let earningsTotal401kHigh = 0;

    /** ROTH IRA * */
    // Medium
    investmentsData1.MEDIUM['Roth IRA'][year] = {};
    let contributionTotalRoth = 0;
    let earningsTotalRoth = 0;
    // Low
    investmentsData1.LOW['Roth IRA'][year] = {};
    let contributionTotalRothLow = 0;
    let earningsTotalRothLow = 0;
    // High
    investmentsData1.HIGH['Roth IRA'][year] = {};
    let contributionTotalRothHigh = 0;
    let earningsTotalRothHigh = 0;

    let j = 0;
    for ( let month = 1; month <= 12; month += 1 ) {
      /** Short-Term * */

      /* Medium */
      investmentsData1.MEDIUM['Short-Term Investments'][year][month] = {};
      // Beg Balance
      investmentsData1.MEDIUM['Short-Term Investments'][year][month][
        'Beg Balance'
      ] = begBalanceShortTerm;

      // below line commented by chandrakala because value just assigned but not used
      // begBalanceTotalShortTerm
      //   += investmentsData1.MEDIUM['Short-Term Investments'][year][month][
      //     'Beg Balance'
      //   ];
      // Contribution
      if ( j === 0 ) {
        if ( k === 0 ) {
          investmentsData1.MEDIUM['Short-Term Investments'][year][
            month
          ].Contribution = contributionShortTerm;
        } else if (
          investmentsData1.MEDIUM['Short-Term Investments'][year - 1]
          !== undefined
        ) {
          let tempVal = 0;
          if (
            module9Data.graph9 !== undefined
            && module9Data.graph9.annualGrowth !== undefined
            && parseFloat( module9Data.graph9.annualGrowth ) > 0
          ) {
            tempVal = parseFloat( module9Data.graph9.annualGrowth ) / 100;
          }
          investmentsData1.MEDIUM['Short-Term Investments'][year][
            month
          ].Contribution = investmentsData1.MEDIUM['Short-Term Investments'][year - 1][month]
            .Contribution
            * ( 1 + tempVal );
        } else {
          investmentsData1.MEDIUM['Short-Term Investments'][year][
            month
          ].Contribution = 0;
        }
      } else if (
        investmentsData1.MEDIUM['Short-Term Investments'][year][month - 1]
        !== undefined
      ) {
        investmentsData1.MEDIUM['Short-Term Investments'][year][
          month
        ].Contribution = investmentsData1.MEDIUM['Short-Term Investments'][year][
          month - 1
        ].Contribution;
      } else {
        investmentsData1.MEDIUM['Short-Term Investments'][year][
          month
        ].Contribution = 0;
      }
      contributionTotalShortTerm
        += investmentsData1.MEDIUM['Short-Term Investments'][year][month]
          .Contribution;

      contributionsTotal
        += investmentsData1.MEDIUM['Short-Term Investments'][year][month]
          .Contribution;

      j += 1;

      // Earnings
      if ( mediumShort > 0 ) {
        earningsShortTerm = ( investmentsData1.MEDIUM['Short-Term Investments'][year][month][
          'Beg Balance'
        ]
            + investmentsData1.MEDIUM['Short-Term Investments'][year][month]
              .Contribution )
          * ( parseFloat( mediumShort ) / 100 / 12 );
      } else {
        earningsShortTerm = 0;
      }
      investmentsData1.MEDIUM['Short-Term Investments'][year][
        month
      ].Earnings = earningsShortTerm;
      earningsTotalShortTerm += earningsShortTerm;

      earningsTotal
        += investmentsData1.MEDIUM['Short-Term Investments'][year][month].Earnings;

      // End Balance
      endBalanceShortTerm = investmentsData1.MEDIUM['Short-Term Investments'][year][month][
        'Beg Balance'
      ]
        + investmentsData1.MEDIUM['Short-Term Investments'][year][month]
          .Contribution
        + investmentsData1.MEDIUM['Short-Term Investments'][year][month].Earnings;
      investmentsData1.MEDIUM['Short-Term Investments'][year][month][
        'End Balance'
      ] = endBalanceShortTerm;
      begBalanceShortTerm = endBalanceShortTerm;

      /* Low */
      investmentsData1.LOW['Short-Term Investments'][year][month] = {};
      // Beg Balance
      investmentsData1.LOW['Short-Term Investments'][year][month][
        'Beg Balance'
      ] = begBalanceShortTermLow;
      // begBalanceTotalShortTermLow
      //   += investmentsData1.LOW['Short-Term Investments'][year][month][
      //     'Beg Balance'
      //   ];
      // Contribution
      investmentsData1.LOW['Short-Term Investments'][year][month].Contribution = investmentsData1.MEDIUM['Short-Term Investments'][year][
        month
      ].Contribution;
      contributionTotalShortTermLow
        += investmentsData1.LOW['Short-Term Investments'][year][month]
          .Contribution;
      // Earnings
      if ( lowShort > 0 ) {
        earningsShortTermLow = ( investmentsData1.LOW['Short-Term Investments'][year][month][
          'Beg Balance'
        ]
            + investmentsData1.LOW['Short-Term Investments'][year][month]
              .Contribution )
          * ( parseFloat( lowShort ) / 100 / 12 );
      } else {
        earningsShortTermLow = 0;
      }
      investmentsData1.LOW['Short-Term Investments'][year][
        month
      ].Earnings = earningsShortTermLow;
      earningsTotalShortTermLow += earningsShortTermLow;
      // End Balance
      endBalanceShortTermLow = investmentsData1.LOW['Short-Term Investments'][year][month][
        'Beg Balance'
      ]
        + investmentsData1.LOW['Short-Term Investments'][year][month]
          .Contribution
        + investmentsData1.LOW['Short-Term Investments'][year][month].Earnings;
      investmentsData1.LOW['Short-Term Investments'][year][month][
        'End Balance'
      ] = endBalanceShortTermLow;
      begBalanceShortTermLow = endBalanceShortTermLow;

      /* High */
      investmentsData1.HIGH['Short-Term Investments'][year][month] = {};
      // Beg Balance
      investmentsData1.HIGH['Short-Term Investments'][year][month][
        'Beg Balance'
      ] = begBalanceShortTermHigh;
      // begBalanceTotalShortTermHigh
      //   += investmentsData1.HIGH['Short-Term Investments'][year][month][
      //     'Beg Balance'
      //   ];
      // Contribution
      investmentsData1.HIGH['Short-Term Investments'][year][
        month
      ].Contribution = investmentsData1.MEDIUM['Short-Term Investments'][year][
        month
      ].Contribution;
      contributionTotalShortTermHigh
        += investmentsData1.HIGH['Short-Term Investments'][year][month]
          .Contribution;
      // Earnings
      if ( highShort > 0 ) {
        earningsShortTermHigh = ( investmentsData1.HIGH['Short-Term Investments'][year][month][
          'Beg Balance'
        ]
            + investmentsData1.HIGH['Short-Term Investments'][year][month]
              .Contribution )
          * ( parseFloat( highShort ) / 100 / 12 );
      } else {
        earningsShortTermHigh = 0;
      }
      investmentsData1.HIGH['Short-Term Investments'][year][
        month
      ].Earnings = earningsShortTermHigh;
      earningsTotalShortTermHigh += earningsShortTermHigh;
      // End Balance
      endBalanceShortTermHigh = investmentsData1.HIGH['Short-Term Investments'][year][month][
        'Beg Balance'
      ]
        + investmentsData1.HIGH['Short-Term Investments'][year][month]
          .Contribution
        + investmentsData1.HIGH['Short-Term Investments'][year][month].Earnings;
      investmentsData1.HIGH['Short-Term Investments'][year][month][
        'End Balance'
      ] = endBalanceShortTermHigh;
      begBalanceShortTermHigh = endBalanceShortTermHigh;

      /** 401k * */

      /* Medium */
      investmentsData1.MEDIUM['401k'][year][month] = {};
      // Beg Balance
      investmentsData1.MEDIUM['401k'][year][month]['Beg Balance'] = investmentsSheetData['401k'][year][month]['Beg Balance'];
      // Contribution
      investmentsData1.MEDIUM['401k'][year][month].Contribution = investmentsSheetData['401k'][year][month].Contribution;
      contributionTotal401k
        += investmentsData1.MEDIUM['401k'][year][month].Contribution;
      // Earnings
      if ( medium401k > 0 ) {
        earnings401k = ( investmentsData1.MEDIUM['401k'][year][month]['Beg Balance']
            + investmentsData1.MEDIUM['401k'][year][month].Contribution )
          * ( parseFloat( medium401k ) / 100 / 12 );
      } else {
        earnings401k = 0;
      }
      investmentsData1.MEDIUM['401k'][year][month].Earnings = earnings401k;
      earningsTotal401k += earnings401k;
      // End Balance
      endBalance401k = investmentsData1.MEDIUM['401k'][year][month]['Beg Balance']
        + investmentsData1.MEDIUM['401k'][year][month].Contribution
        + investmentsData1.MEDIUM['401k'][year][month].Earnings;
      investmentsData1.MEDIUM['401k'][year][month][
        'End Balance'
      ] = endBalance401k;

      /* Low */
      investmentsData1.LOW['401k'][year][month] = {};
      // Beg Balance
      investmentsData1.LOW['401k'][year][month][
        'Beg Balance'
      ] = begBalance401kLow;
      // Contribution
      investmentsData1.LOW['401k'][year][month].Contribution = investmentsData1.MEDIUM['401k'][year][month].Contribution;
      contributionTotal401kLow
        += investmentsData1.LOW['401k'][year][month].Contribution;
      // Earnings
      if ( low401k > 0 ) {
        earnings401kLow = ( investmentsData1.LOW['401k'][year][month]['Beg Balance']
            + investmentsData1.LOW['401k'][year][month].Contribution )
          * ( parseFloat( low401k ) / 100 / 12 );
      } else {
        earnings401kLow = 0;
      }
      investmentsData1.LOW['401k'][year][month].Earnings = earnings401kLow;
      earningsTotal401kLow += earnings401kLow;
      // End Balance
      endBalance401kLow = investmentsData1.LOW['401k'][year][month]['Beg Balance']
        + investmentsData1.LOW['401k'][year][month].Contribution
        + investmentsData1.LOW['401k'][year][month].Earnings;
      investmentsData1.LOW['401k'][year][month][
        'End Balance'
      ] = endBalance401kLow;
      begBalance401kLow = endBalance401kLow;

      /* High */
      investmentsData1.HIGH['401k'][year][month] = {};
      // Beg Balance
      investmentsData1.HIGH['401k'][year][month][
        'Beg Balance'
      ] = begBalance401kHigh;
      // Contribution
      investmentsData1.HIGH['401k'][year][month].Contribution = investmentsData1.MEDIUM['401k'][year][month].Contribution;
      contributionTotal401kHigh
        += investmentsData1.HIGH['401k'][year][month].Contribution;
      // Earnings
      if ( high401k > 0 ) {
        earnings401kHigh = ( investmentsData1.HIGH['401k'][year][month]['Beg Balance']
            + investmentsData1.HIGH['401k'][year][month].Contribution )
          * ( parseFloat( high401k ) / 100 / 12 );
      } else {
        earnings401kHigh = 0;
      }
      investmentsData1.HIGH['401k'][year][month].Earnings = earnings401kHigh;
      earningsTotal401kHigh += earnings401kHigh;
      // End Balance
      endBalance401kHigh = investmentsData1.HIGH['401k'][year][month]['Beg Balance']
        + investmentsData1.HIGH['401k'][year][month].Contribution
        + investmentsData1.HIGH['401k'][year][month].Earnings;
      investmentsData1.HIGH['401k'][year][month][
        'End Balance'
      ] = endBalance401kHigh;
      begBalance401kHigh = endBalance401kHigh;

      /** Roth IRA * */
      /* Medium */
      investmentsData1.MEDIUM['Roth IRA'][year][month] = {};
      // Beg Balance
      investmentsData1.MEDIUM['Roth IRA'][year][month]['Beg Balance'] = investmentsSheetData['Roth IRA'][year][month]['Beg Balance'];
      // Contribution
      investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution = investmentsSheetData['Roth IRA'][year][month].Contribution;
      contributionTotalRoth
        += investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution;
      // Earnings
      if ( mediumIRA > 0 ) {
        earningsRoth = ( investmentsData1.MEDIUM['Roth IRA'][year][month]['Beg Balance']
            + investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution )
          * ( parseFloat( mediumIRA ) / 100 / 12 );
      } else {
        earningsRoth = 0;
      }
      investmentsData1.MEDIUM['Roth IRA'][year][month].Earnings = earningsRoth;
      earningsTotalRoth += earningsRoth;
      // End Balance
      endBalanceRoth = investmentsData1.MEDIUM['Roth IRA'][year][month]['Beg Balance']
        + investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution
        + investmentsData1.MEDIUM['Roth IRA'][year][month].Earnings;
      investmentsData1.MEDIUM['Roth IRA'][year][month][
        'End Balance'
      ] = endBalanceRoth;

      /* Low */
      investmentsData1.LOW['Roth IRA'][year][month] = {};
      // Beg Balance
      investmentsData1.LOW['Roth IRA'][year][month][
        'Beg Balance'
      ] = begBalanceRothLow;
      // Contribution
      investmentsData1.LOW['Roth IRA'][year][month].Contribution = investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution;
      contributionTotalRothLow
        += investmentsData1.LOW['Roth IRA'][year][month].Contribution;
      // Earnings
      if ( lowIRA > 0 ) {
        earningsRothLow = ( investmentsData1.LOW['Roth IRA'][year][month]['Beg Balance']
            + investmentsData1.LOW['Roth IRA'][year][month].Contribution )
          * ( parseFloat( lowIRA ) / 100 / 12 );
      } else {
        earningsRothLow = 0;
      }
      investmentsData1.LOW['Roth IRA'][year][month].Earnings = earningsRothLow;
      earningsTotalRothLow += earningsRothLow;
      // End Balance
      endBalanceRothLow = investmentsData1.LOW['Roth IRA'][year][month]['Beg Balance']
        + investmentsData1.LOW['Roth IRA'][year][month].Contribution
        + investmentsData1.LOW['Roth IRA'][year][month].Earnings;
      investmentsData1.LOW['Roth IRA'][year][month][
        'End Balance'
      ] = endBalanceRothLow;
      begBalanceRothLow = endBalanceRothLow;

      /* High */
      investmentsData1.HIGH['Roth IRA'][year][month] = {};
      // Beg Balance
      investmentsData1.HIGH['Roth IRA'][year][month][
        'Beg Balance'
      ] = begBalanceRothHigh;
      // Contribution
      investmentsData1.HIGH['Roth IRA'][year][month].Contribution = investmentsData1.MEDIUM['Roth IRA'][year][month].Contribution;
      contributionTotalRothHigh
        += investmentsData1.HIGH['Roth IRA'][year][month].Contribution;
      // Earnings
      if ( highIRA > 0 ) {
        earningsRothHigh = ( investmentsData1.HIGH['Roth IRA'][year][month]['Beg Balance']
            + investmentsData1.HIGH['Roth IRA'][year][month].Contribution )
          * ( parseFloat( highIRA ) / 100 / 12 );
      } else {
        earningsRothHigh = 0;
      }
      investmentsData1.HIGH['Roth IRA'][year][
        month
      ].Earnings = earningsRothHigh;
      earningsTotalRothHigh += earningsRothHigh;
      // End Balance
      endBalanceRothHigh = investmentsData1.HIGH['Roth IRA'][year][month]['Beg Balance']
        + investmentsData1.HIGH['Roth IRA'][year][month].Contribution
        + investmentsData1.HIGH['Roth IRA'][year][month].Earnings;
      investmentsData1.HIGH['Roth IRA'][year][month][
        'End Balance'
      ] = endBalanceRothHigh;
      begBalanceRothHigh = endBalanceRothHigh;
    }
    k += 1;

    /** Short Term Investments * */
    /** Medium * */
    // Beg balance  401k
    investmentsData1.MEDIUM['Short-Term Investments'][year]['Beg Balance'] = investmentsData1.MEDIUM['Short-Term Investments'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.MEDIUM['Short-Term Investments'][
      year
    ].Contribution = contributionTotalShortTerm;
    // Earnings Value  401k
    investmentsData1.MEDIUM['Short-Term Investments'][
      year
    ].Earnings = earningsTotalShortTerm;
    // End balance 401k
    investmentsData1.MEDIUM['Short-Term Investments'][year]['End Balance'] = parseFloat(
      investmentsData1.MEDIUM['Short-Term Investments'][year]['Beg Balance'],
    )
      + parseFloat(
        investmentsData1.MEDIUM['Short-Term Investments'][year].Contribution,
      )
      + parseFloat(
        investmentsData1.MEDIUM['Short-Term Investments'][year].Earnings,
      );

    /** LOW * */
    // Beg balance  401k
    investmentsData1.LOW['Short-Term Investments'][year]['Beg Balance'] = investmentsData1.LOW['Short-Term Investments'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.LOW['Short-Term Investments'][
      year
    ].Contribution = contributionTotalShortTermLow;
    // Earnings Value  401k
    investmentsData1.LOW['Short-Term Investments'][
      year
    ].Earnings = earningsTotalShortTermLow;
    // End balance 401k
    investmentsData1.LOW['Short-Term Investments'][year]['End Balance'] = parseFloat(
      investmentsData1.LOW['Short-Term Investments'][year]['Beg Balance'],
    )
      + parseFloat(
        investmentsData1.LOW['Short-Term Investments'][year].Contribution,
      )
      + parseFloat( investmentsData1.LOW['Short-Term Investments'][year].Earnings );

    /** HIGH * */
    // Beg balance  401k
    investmentsData1.HIGH['Short-Term Investments'][year]['Beg Balance'] = investmentsData1.HIGH['Short-Term Investments'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.HIGH['Short-Term Investments'][
      year
    ].Contribution = contributionTotalShortTermHigh;
    // Earnings Value  401k
    investmentsData1.HIGH['Short-Term Investments'][
      year
    ].Earnings = earningsTotalShortTermHigh;
    // End balance 401k
    investmentsData1.HIGH['Short-Term Investments'][year]['End Balance'] = parseFloat(
      investmentsData1.HIGH['Short-Term Investments'][year]['Beg Balance'],
    )
      + parseFloat(
        investmentsData1.HIGH['Short-Term Investments'][year].Contribution,
      )
      + parseFloat(
        investmentsData1.HIGH['Short-Term Investments'][year].Earnings,
      );

    /** 401k * */
    /** Medium * */
    // Beg balance  401k
    investmentsData1.MEDIUM['401k'][year]['Beg Balance'] = investmentsData1.MEDIUM['401k'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.MEDIUM['401k'][year].Contribution = contributionTotal401k;
    // Earnings Value  401k
    investmentsData1.MEDIUM['401k'][year].Earnings = earningsTotal401k;
    // End balance 401k
    investmentsData1.MEDIUM['401k'][year]['End Balance'] = parseFloat( investmentsData1.MEDIUM['401k'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.MEDIUM['401k'][year].Contribution )
      + parseFloat( investmentsData1.MEDIUM['401k'][year].Earnings );

    /** LOW * */
    // Beg balance  401k
    investmentsData1.LOW['401k'][year]['Beg Balance'] = investmentsData1.LOW['401k'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.LOW['401k'][year].Contribution = contributionTotal401kLow;
    // Earnings Value  401k
    investmentsData1.LOW['401k'][year].Earnings = earningsTotal401kLow;
    // End balance 401k
    investmentsData1.LOW['401k'][year]['End Balance'] = parseFloat( investmentsData1.LOW['401k'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.LOW['401k'][year].Contribution )
      + parseFloat( investmentsData1.LOW['401k'][year].Earnings );

    /** HIGH * */
    // Beg balance  401k
    investmentsData1.HIGH['401k'][year]['Beg Balance'] = investmentsData1.HIGH['401k'][year][1]['Beg Balance'];
    // Contribution Value 401k
    investmentsData1.HIGH['401k'][
      year
    ].Contribution = contributionTotal401kHigh;
    // Earnings Value  401k
    investmentsData1.HIGH['401k'][year].Earnings = earningsTotal401kHigh;
    // End balance 401k
    investmentsData1.HIGH['401k'][year]['End Balance'] = parseFloat( investmentsData1.HIGH['401k'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.HIGH['401k'][year].Contribution )
      + parseFloat( investmentsData1.HIGH['401k'][year].Earnings );

    /** Roth IRA * */
    /** Medium * */
    // Beg balance  Roth IRA
    investmentsData1.MEDIUM['Roth IRA'][year]['Beg Balance'] = investmentsData1.MEDIUM['Roth IRA'][year][1]['Beg Balance'];
    // Contribution Value Roth IRA
    investmentsData1.MEDIUM['Roth IRA'][
      year
    ].Contribution = contributionTotalRoth;
    // Earnings Value  Roth IRA
    investmentsData1.MEDIUM['Roth IRA'][year].Earnings = earningsTotalRoth;
    // End balance Roth IRA
    investmentsData1.MEDIUM['Roth IRA'][year]['End Balance'] = parseFloat( investmentsData1.MEDIUM['Roth IRA'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.MEDIUM['Roth IRA'][year].Contribution )
      + parseFloat( investmentsData1.MEDIUM['Roth IRA'][year].Earnings );

    /** LOW * */
    // Beg balance  Roth IRA
    investmentsData1.LOW['Roth IRA'][year]['Beg Balance'] = investmentsData1.LOW['Roth IRA'][year][1]['Beg Balance'];
    // Contribution Value Roth IRA
    investmentsData1.LOW['Roth IRA'][
      year
    ].Contribution = contributionTotalRothLow;
    // Earnings Value  Roth IRA
    investmentsData1.LOW['Roth IRA'][year].Earnings = earningsTotalRothLow;
    // End balance Roth IRA
    investmentsData1.LOW['Roth IRA'][year]['End Balance'] = parseFloat( investmentsData1.LOW['Roth IRA'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.LOW['Roth IRA'][year].Contribution )
      + parseFloat( investmentsData1.LOW['Roth IRA'][year].Earnings );

    /** HIGH * */
    // Beg balance  Roth IRA
    investmentsData1.HIGH['Roth IRA'][year]['Beg Balance'] = investmentsData1.HIGH['Roth IRA'][year][1]['Beg Balance'];
    // Contribution Value Roth IRA
    investmentsData1.HIGH['Roth IRA'][
      year
    ].Contribution = contributionTotalRothHigh;
    // Earnings Value  Roth IRA
    investmentsData1.HIGH['Roth IRA'][year].Earnings = earningsTotalRothHigh;
    // End balance Roth IRA
    investmentsData1.HIGH['Roth IRA'][year]['End Balance'] = parseFloat( investmentsData1.HIGH['Roth IRA'][year]['Beg Balance'] )
      + parseFloat( investmentsData1.HIGH['Roth IRA'][year].Contribution )
      + parseFloat( investmentsData1.HIGH['Roth IRA'][year].Earnings );

    /** *** Ananlysis Data * */
    // Short-Term Investments
    mediumShortTermArr.push(
      Math.round(
        investmentsData1.MEDIUM['Short-Term Investments'][year]['End Balance'],
      ),
    );
    lowShortTermArr.push(
      Math.round(
        investmentsData1.LOW['Short-Term Investments'][year]['End Balance'],
      ),
    );
    highShortTermArr.push(
      Math.round(
        investmentsData1.HIGH['Short-Term Investments'][year]['End Balance'],
      ),
    );
    annualContributionShortTermArr.push(
      Math.round(
        investmentsData1.MEDIUM['Short-Term Investments'][year].Contribution,
      ),
    );

    // 401K
    medium401kArr.push(
      Math.round( investmentsData1.MEDIUM['401k'][year]['End Balance'] ),
    );
    low401kArr.push(
      Math.round( investmentsData1.LOW['401k'][year]['End Balance'] ),
    );
    high401kArr.push(
      Math.round( investmentsData1.HIGH['401k'][year]['End Balance'] ),
    );
    annualContribution401kArr.push(
      Math.round( investmentsData1.MEDIUM['401k'][year].Contribution ),
    );

    // Roth IRA Data
    mediumRothIraArr.push(
      Math.round( investmentsData1.MEDIUM['Roth IRA'][year]['End Balance'] ),
    );
    lowRothIraArr.push(
      Math.round( investmentsData1.LOW['Roth IRA'][year]['End Balance'] ),
    );
    highRothIraArr.push(
      Math.round( investmentsData1.HIGH['Roth IRA'][year]['End Balance'] ),
    );
    annualContributionRothIraArr.push(
      Math.round( investmentsData1.MEDIUM['Roth IRA'][year].Contribution ),
    );
  }

  // To return Cash Flow Statement Complete Data
  function investmentsOutputBackendData() {
    const investmentsInfo = {};

    investmentsInfo['Years List'] = {};
    investmentsInfo['Years List'] = yearsList;

    investmentsInfo['Short-Term Sensitivity Analysis'] = {};
    investmentsInfo[
      'Short-Term Sensitivity Analysis'
    ].Medium = mediumShortTermArr;
    investmentsInfo['Short-Term Sensitivity Analysis'].Low = lowShortTermArr;
    investmentsInfo['Short-Term Sensitivity Analysis'].High = highShortTermArr;
    investmentsInfo['Short-Term Sensitivity Analysis'][
      'Annual Contribution'
    ] = annualContributionShortTermArr;

    investmentsInfo['Short-Term Sensitivity Analysis'].labels = {};
    investmentsInfo[
      'Short-Term Sensitivity Analysis'
    ].labels.Medium = mediumShort;
    investmentsInfo['Short-Term Sensitivity Analysis'].labels.Low = lowShort;
    investmentsInfo['Short-Term Sensitivity Analysis'].labels.High = highShort;

    investmentsInfo['401k Sensitivity Analysis'] = {};
    investmentsInfo['401k Sensitivity Analysis'].Medium = medium401kArr;
    investmentsInfo['401k Sensitivity Analysis'].Low = low401kArr;
    investmentsInfo['401k Sensitivity Analysis'].High = high401kArr;
    investmentsInfo['401k Sensitivity Analysis'][
      'Annual Contribution'
    ] = annualContribution401kArr;

    investmentsInfo['401k Sensitivity Analysis'].labels = {};
    investmentsInfo['401k Sensitivity Analysis'].labels.Medium = medium401k;
    investmentsInfo['401k Sensitivity Analysis'].labels.Low = low401k;
    investmentsInfo['401k Sensitivity Analysis'].labels.High = high401k;
    if ( medium401k === 0 && low401k === 0 && high401k === 0 ) {
      investmentsInfo['401k Sensitivity Analysis']['Graph Display'] = 'hide';
    } else {
      investmentsInfo['401k Sensitivity Analysis']['Graph Display'] = 'show';
    }

    investmentsInfo['Roth IRA Sensitivity Analysis'] = {};
    investmentsInfo['Roth IRA Sensitivity Analysis'].Medium = mediumRothIraArr;
    investmentsInfo['Roth IRA Sensitivity Analysis'].Low = lowRothIraArr;
    investmentsInfo['Roth IRA Sensitivity Analysis'].High = highRothIraArr;
    investmentsInfo['Roth IRA Sensitivity Analysis'][
      'High Min'
    ] = Math.min.apply( null, highRothIraArr );
    investmentsInfo['Roth IRA Sensitivity Analysis'][
      'High Max'
    ] = Math.max.apply( null, highRothIraArr );
    investmentsInfo['Roth IRA Sensitivity Analysis'][
      'Annual Contribution'
    ] = annualContributionRothIraArr;
    investmentsInfo['Roth IRA Sensitivity Analysis'][
      'Annual Contribution Min'
    ] = Math.min.apply( null, annualContributionRothIraArr );
    investmentsInfo['Roth IRA Sensitivity Analysis'][
      'Annual Contribution Max'
    ] = Math.max.apply( null, annualContributionRothIraArr );

    investmentsInfo['Roth IRA Sensitivity Analysis'].labels = {};
    investmentsInfo['Roth IRA Sensitivity Analysis'].labels.Medium = mediumIRA;
    investmentsInfo['Roth IRA Sensitivity Analysis'].labels.Low = lowIRA;
    investmentsInfo['Roth IRA Sensitivity Analysis'].labels.High = highIRA;
    if ( mediumIRA === 0 && lowIRA === 0 && lowIRA === 0 ) {
      investmentsInfo['Roth IRA Sensitivity Analysis']['Graph Display'] = 'hide';
    } else {
      investmentsInfo['Roth IRA Sensitivity Analysis']['Graph Display'] = 'show';
    }

    investmentsInfo['Graph Data'] = {};
    investmentsInfo['Graph Data']['Beg Balance'] = begBalance;
    investmentsInfo['Graph Data']['Contributions Total'] = contributionsTotal;
    investmentsInfo['Graph Data']['Earnings Total'] = earningsTotal;
    investmentsInfo['Investments Row Data'] = investmentsData1;
    // console.log("investmentsInfo",investmentsInfo);
    return investmentsInfo;
  }
  // below function added by chandrakala to read the values in the console
  return investmentsOutputBackendData();
}
export default InvestmentsOutput;
