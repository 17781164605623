import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import _ from 'lodash';
import { Draggable, Droppable } from 'react-drag-and-drop';
import compose from 'recompose/compose';
import styles from './styles';
import { Grid } from "@material-ui/core";


const dropTexts = ['Credit Mix', 'Length of Credit History', 'Payment History', 'New Credit', 'Utilization Rate'];

const BitCoinPopups = (props) => {

  const { classes, challengeIndex, level, setChallengeAnswer, challengeAnswer, btnDisabled, setSelectedAnswer, selectedAnswer, setDraggedKeys, draggedKeys } = props;
  const dropZoneDetails = [
    {
      class: `${classes.risklist} ${classes.risklist1}`, text: '35%', dropText: '', answer: '4', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist2}`, text: '30%', dropText: '', answer: '1', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist3}`, text: '15%', dropText: '', answer: '2', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist4}`, text: '10%', dropText: '', answer: '3', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist5}`, text: '10%', dropText: '', answer: '5', correct: false,
    },
  ];


  const [dropZoneData, setDropZoneData] = React.useState(dropZoneDetails);

  useEffect(() => {
    if (level === 6) {
      if (challengeIndex === 1) {
        setSelectedAnswer('1');
      } else if (challengeIndex === 2) {
        setSelectedAnswer('2');
      } else if (challengeIndex === 3) {
        setSelectedAnswer('1');
      }
    }

  }, [challengeIndex]);

  const answerQuestion = (val) => {
    setChallengeAnswer(val.target.value);
  }

  const onDrop = (data, key) => {
    const dropText = dropTexts[parseInt(data.appropriate, 10) - 1];
    const droppableData = [...dropZoneData];
    const draggableKeys = [...draggedKeys]

    droppableData[key].dropText = dropText;
    if (droppableData[key].answer === data.appropriate) {
      droppableData[key].correct = true;
    } else {
      droppableData[key].correct = false;
    }
    draggableKeys[key] = droppableData[key].correct;
    setDraggedKeys(draggableKeys);
    setDropZoneData(droppableData);
  };

  return (
    <Typography variant="body1" component="div">
      { /* bitcoin XP questions  Popup */}
      {challengeIndex === 1 && (
        <div>
         
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>A credit score is a three-digit number ranging from 300 – 850 (the higher, the better) that relates to how likely you are to repay debt. There are five factors that go into your score. </p>
            </div>
         

          <div className={classes.riskLadder}>
            <div className={classes.listedInvestments}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12} >
                  <div className={classes.listedInvestmentsLeft}>
                    <ul className={classes.riskList}>
                      {dropZoneData.map((data, index) => (
                        <li className={data.class}>
                          <Droppable
                            types={['appropriate']}
                            onDrop={(e) => { onDrop(e, index); }}
                          >
                            <span className={classes.percText}>{data.text}</span>
                            <span className={classes.dragText}>{data.dropText}</span>
                            {data.correct === true && data.dropText !== '' && btnDisabled && (<span className={classes.greenCheck}><i className="fa fa-check" /></span>)}
                            {data.correct === false && data.dropText !== '' && btnDisabled && (<span className={classes.redCross}><i className="fa fa-times" /></span>)}
                          </Droppable>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12} >
                  <ul className={classes.riskOptions}>
                    <Draggable type="appropriate" data="1"><li className={classes.listBonds}>Credit Mix</li></Draggable>
                    <Draggable type="appropriate" data="2"><li className={classes.fundsList}>Length of Credit History</li></Draggable>
                    <Draggable type="appropriate" data="3"><li className={classes.stockList}>Payment History</li></Draggable>
                    <Draggable type="appropriate" data="4"><li className={classes.investList}>New Credit</li></Draggable>
                    <Draggable type="appropriate" data="5"><li className={classes.moreRisk}>Utilization Rate</li></Draggable>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}


      {challengeIndex === 2 && (
        <div>
          
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>Mary wants to know how much of her student loan payment is principal repayment and how much is interest. If her current student loan balance is $40,000 with a 5% interest rate and 10 years to repay the loan, how much of her next monthly payment is interest?</p>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                        $2,000.00
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                        $166.67
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                        $200.00
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                        $1,508.00
                      </label>
                    </p>
                  </li>
                </ul>
              </div>

            </div>
         
        </div>
      )}
      {challengeIndex === 3 && (
        <div>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>Mary just made her first student loan payment and wants to know how much she still has to repay. If her student loan balance was $40,000 before the payment, the monthly payment was $424.26, and the interest rate was 5%, what is Mary’s new student loan balance?</p>

              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                        $39,742.41
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                        $39,575.74
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                        $39,833.33
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                        $40,000
                      </label>
                    </p>
                  </li>
                </ul>
              </div>

            </div>
         
        </div>
      )}
    </Typography>
  );
};

BitCoinPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  setChallengeAnswer: PropTypes.func.isRequired,
  challengeAnswer: PropTypes.string.isRequired,
  btnDisabled: PropTypes.bool.isRequired,
  setSelectedAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.string.isRequired,
  setDraggedKeys: PropTypes.func,
  draggedKeys: PropTypes.array

};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinPopups);
