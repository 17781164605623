import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();

const SmartGoals = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/smart-goals.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>What&apos;s a SMART Goal?</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/smart-goals-left.png" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          I will earn a promotion to supervisor by the end of this year. I will save $2,000 for a vacation next summer. I will graduate in four years with a 3.5 GPA or higher. These are all examples of SMART goals. Notice how they are specific, measurable, achievable, relevant and timely.
                        </p>

                        <p>
                          <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                          {' '}
                          You are 42% more likely to achieve your goals if you write them down on a regular basis.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        A goal is a result or outcome that you develop a plan for and consistently work toward. But what defines a good goal? What separates a goal from a dream from a fantasy? Use the SMART goal setting guidelines and increase your chances of success.
                        {' '}
                        <b>A SMART goal is specific, measurable, achievable, relevant and timely.</b>
                        <p>
                          <b className={ classes.didYouKnow }>SPECIFIC:</b>
                          {' '}
                          What exactly are you trying to achieve? Write out a clear and focused description of what you are trying to achieve. The more specific you can be, the more likely you will achieve your goal.
                        </p>
                        <ul>
                          <li>Example: I am going to improve my financial life so I can buy a home one day</li>
                          <li>SMART Example: I am going to save more money each month and invest the savings for a $50k down payment on a $250k home in the suburbs</li>
                        </ul>
                        <p>
                          <b className={ classes.didYouKnow }>MEASURABLE:</b>
                          {' '}
                          How do you track your progress? A goal without a measurable outcome is like a football game without an end zone. By adding the $500 figure into the goal below, you now have a measurement you can use to focus your efforts and track your progress toward your goal.
                        </p>
                        <ul>
                          <li>Example: I am going to save more money each month</li>
                          <li>SMART Example: I am going to save $500 each month by cutting some expenses, working a little more and invest my savings for a $50k down payment on a $250k home in the suburbs</li>
                        </ul>

                        <p>
                          <b className={ classes.didYouKnow }>ACHIEVABLE:</b>
                          {' '}
                          Arguably the most important aspect of creating a goal is setting an outcome that is actually achievable. This aspect is what separates a goal from a fantasy. A SMART goal is challenging, but achievable. Dream big while still being realistic! Adding achievable details to your goal will increase your chances of success.
                        </p>
                        <ul>
                          <li>Example: Instead of saving, I am going to get a new job that pays me $100,000 more</li>
                          <li>SMART Example: I am going to save $500 each month by making coffee at home, grocery shopping, cutting my cable bill and working an extra shift each month while investing my savings for a $50k down payment on a $250k home in the suburbs</li>
                        </ul>
                        <p>
                          <b className={ classes.didYouKnow }>RELEVANT:</b>
                          {' '}
                          Is this goal truly important to you and your financial life plan? Is this goal worth your time and effort? Setting goals that are important to you and will make you happy – such as exercising 3x a week or earning a promotion by the end of next year – will give you a better chance of achieving that goal.
                          If you don&apos;t like to read but set a goal to read 12 books a year, you most likely won&apos;t achieve that outcome because it doesn&apos;t fit your interests or your overall financial life plan.
                        </p>
                        <p>
                          <b className={ classes.didYouKnow }>TIMELY:</b>
                          {' '}
                          When will you achieve your goal? What milestones can you attach to your goal? A SMART goal must have a time aspect. Without an end date, what motivation will you have to work toward your goal? An end date or milestone achievements help you celebrate small successes along the way and keep you on track to reaching your targets.
                        </p>
                        <ul>
                          <li>Example: I am going to save $500 each month by making coffee at home, grocery shopping, cutting my cable bill and working an extra shift each month</li>
                          <li>SMART Example: I am going to save $500 each month by making coffee at home five days a week, grocery shopping once a week, cutting my cable bill for the year and working one extra shift each month while investing my savings for a $50k down payment on a $250k home in the suburbs</li>
                        </ul>
                        <p>Now that is a SMART goal! Goal setting is an essential aspect of your financial life plan. What goals are you trying to accomplish? Can you improve those goals by running them through the SMART goal setting framework? Challenge yourself to be more specific, add a measurable outcome, do a reality check, review your priorities and put a timeline to your goals.</p>
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level9/smart-goals/exercise" startTime={ startTime } level={ 9 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
SmartGoals.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SmartGoals );
