import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const RetirementSavings = ( props ) => {
  const { classes} = props;
  
  return (
    <div className={ classes.contentBlock }>
      <p>How do you compare to retirement savings of other Americans?</p>
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew, classes.mb0)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Total Retirement Savings</th>
          </thead>
          <tbody>
            <tr>
              <td>Total Retirement Savings</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {modulesServices.module18Data.existingTotalRetirementAccounts !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingTotalRetirementAccounts } prefix={ modulesServices.module18Data.existingTotalRetirementAccounts >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingTotalRetirementAccounts < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew, classes.tableHeadBg, classes.mbt30)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
              <tr>
                  <th>Retirement Savings by Age</th>
                  <th>Median</th>
                  <th>Average</th>
              </tr>
          </thead>
        <tbody>
          <tr>
            <td>
                &lt;35 years old
            </td>
            <td>$13,000</td>
            <td>$75,000</td>
          </tr>
          <tr>
            <td>
                35 to 44 years old
            </td>
            <td>$60,000</td>
            <td>$131,950</td>
          </tr>
          <tr>
            <td>
                45 to 54 years old
            </td>
            <td>$100,000</td>
            <td>$254,720</td>
          </tr>
          <tr>
            <td>
                55 to 64 years old
            </td>
            <td>$134,000</td>
            <td>$408,420</td>
          </tr>
            <tr>
            <td>
                65 to 74 years old
            </td>
            <td>$164,000</td>
            <td>$426,070</td>
          </tr>
        </tbody>
        </table>
      </div>
      <div className={classes.sourceText}>
        <a href="https://www.federalreserve.gov/econres/scf/dataviz/scf/chart/#series:Retirement_Accounts;demographic:agecl;population:1,2,3,4,5,6;units:median" target = "_blank" rel="noreferrer">Source: 2019 Survey of Consumer Finances</a>
      </div>
    </div>
  );
};

RetirementSavings.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleAssestObject: PropTypes.object.isRequired,
  // handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( RetirementSavings );
