import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import incometaxes from '../../../../assets/img/pf101/income_taxes.png';

const startTime = new Date();

const IncomeTaxes101 = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/personal-income-tax.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Personal Income Taxes 101</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/personal-income-taxes-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Common types of taxes in the US are personal income tax, corporate tax, sales tax, property tax, and estate tax.

                          Personal income taxes are the largest source of revenue for the federal government.

                          In 2020, the U.S. government&apos;s total revenue is estimated to be $3,643,000,000,000 or $3.643 trillion.

                          Personal income taxes are expected to total $3.117 trillion or
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.whitehouse.gov/wp-content/uploads/2019/03/budget-fy2020.pdf" target="_blank">86% of the government&apos;s total revenue.</a>

                          {' '}
                          Review the graphic below to see how the federal government spends our tax dollars.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.fiImage }>
                      <div className={ classes.lightGallery }>
                        <img className={ classes.widthAuto } src={ incometaxes } alt="table" />
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        Personal income taxes are a percentage of your annual earnings paid to the federal, state and local governments. Each state and city have a different tax system. Check out a simplified example below of how taxes work for a single person earning $100,000 this year.
                      </p>
                    </div>
                    <Table className={ classes.incomeExamples }>
                      <thead>
                        <tr>
                          <th>Personal Income Taxes Example</th>
                          <td />
                          <th>Year 2020</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Income</td>
                          <td />
                          <td>$100,000</td>
                        </tr>
                        <tr>
                          <td>(-) Adjustments</td>
                          <td />
                          <td className={ classes.redText }>($5,000)</td>
                        </tr>
                        <tr>
                          <td>(=) Adjusted Gross Income (AGI)</td>
                          <td />
                          <td>$95,000</td>
                        </tr>
                        <tr>
                          <td>(-) Deductions (Standard vs Itemized)</td>
                          <td />
                          <td className={ classes.redText }>($12,400)</td>
                        </tr>
                        <tr className={ classes.bottomblackBorder }>
                          <td>(=) Federal Taxable Income</td>
                          <td />
                          <td>$82,600</td>
                        </tr>
                        <tr>
                          <td>Social Security Taxes</td>
                          <td>6.20% x Total Income</td>
                          <td className={ classes.redText }>($6,200)</td>
                        </tr>
                        <tr>
                          <td>Medicare Taxes</td>
                          <td>1.45% x Total Income</td>
                          <td className={ classes.redText }>($1,450)</td>
                        </tr>
                        <tr>
                          <td>Federal Income Taxes</td>
                          <td>17.00% x Federal Taxable Income</td>
                          <td className={ classes.redText }>($13,962)</td>
                        </tr>
                        <tr>
                          <td>State Income Taxes</td>
                          <td>3.00% of Adjusted Gross Income</td>
                          <td className={ classes.redText }>($2,850)</td>
                        </tr>
                        <tr>
                          <td>Local Income Taxes</td>
                          <td>1.00% of Adjusted Gross Income</td>
                          <td className={ classes.redText }>($950)</td>
                        </tr>
                        <tr className={ classes.bottomblackBorder }>
                          <td><b>Total Income Taxes</b></td>
                          <td><b>25.53% of Total Income</b></td>
                          <td className={ classes.redText }><b>($25,412)</b></td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>That&apos;s how personal income taxes work without getting into the smaller details. Two key terms in the table above that need defining: adjustments and deductions.</p>
                        <ul>
                          <li>
                            <b>Adjustments:</b>
                            {' '}
                            Specific expenses that reduce your taxable income to arrive at Adjusted Gross Income (AGI). Some common adjustments are qualified student loan interest or pre-tax retirement contributions.
                          </li>
                          <li>
                            <b>Deductions:</b>
                            {' '}
                            a portion of your income that is not subject to federal income taxes. There are two types of deductions: standard deduction or itemized deduction. You choose each year whether to take the standard or itemized deduction based on which one is greater.
                          </li>
                          <ul>
                            <li>
                              <b>Standard deduction:</b>
                              {' '}
                              given to all taxpayers and is $12,400 for single filers and $24,800 for married couples.
                              {' '}
                              <b>Most taxpayers take the standard deduction.</b>
                            </li>
                            <li>
                              <b>Itemized deductions:</b>
                              {' '}
                              specific expenses that reduce your adjusted gross income to arrive at federal taxable income. Itemized deductions can get complex. Click
                              {' '}
                              <a className={ classes.anchorStyle } href="https://www.thebalance.com/itemized-deductions-3192880" target="_blank" rel="budget-fy2020 noopener noreferrer">here</a>
                              {' '}
                              if you&apos;re interested in learning more.
                            </li>
                          </ul>
                        </ul>
                        <p>You might be wondering how taxes can vary based on income, employment status, or even state and city. The table below shows you a breakdown of personal income tax ranges.</p>
                      </div>
                    </div>
                    <Table className={ classes.breakdownTax }>
                      <thead>
                        <tr>
                          <th colSpan="2">Personal Income Tax Rate Breakdown</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Federal Income Tax Rate</td>
                          <td>10.00% - 37.00%</td>
                        </tr>
                        <tr>
                          <td>Social Security Tax Rate</td>
                          <td>
                            6.20%
                            <span>(12.40% if self-employed)</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Medicare Tax Rate</td>
                          <td>
                            1.45%
                            <br />
                            {' '}
                            (2.90% if self-employed)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Additional Medicare Tax Rate
                            <br />
                            {' '}
                            (Single Income &gt; $200k)
                            <br />
                            {' '}
                            (Married Income &gt; $250k)
                          </td>
                          <td>0.90%</td>
                        </tr>
                        <tr>
                          <td>State Income Tax Rate</td>
                          <td>0.00% - 13.30%</td>
                        </tr>
                        <tr>
                          <td>Local (City) Income Tax Rate</td>
                          <td>0.00% - 3.88%</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        States and cities all charge different income taxes. The map below shows the highest tax bracket in all states. Note, states with a * next to them charge a flat tax rate, which means they tax your entire income at that rate. States with a ** next to them only tax interest and dividend income. Where you live and work has a big impact on your personal income taxes.
                      </p>
                    </div>
                    <div className={ classes.pfImage }>
                      <img className={ classes.pfImageBorder } src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/taxes-states.png" alt="template" />
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level2/income-taxes-101/exercise" startTime={ startTime } level={ 2 } exercise={ 2 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
IncomeTaxes101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( IncomeTaxes101 );
