import React from "react";
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TENeeded = (props) => {
    const { resultData } = props;
    const series = [];
    const data1 = [];
    const data2 = [];

    
        if (resultData.data1.career !== null ) {
            if(resultData['data1'] !== undefined && resultData['data1']['career'] !== undefined ) {
                data1.push(parseFloat(resultData['data1']['career']['less_than_high_school_diploma']));
                data1.push(parseFloat(resultData['data1']['career']['high_school_diploma_or_equivalent']));
                data1.push(parseFloat(resultData['data1']['career']['some_college_no_degree']));
                data1.push(parseFloat(resultData['data1']['career']['associates_degree']));
                data1.push(parseFloat(resultData['data1']['career']['bachelors_degree']));
                data1.push(parseFloat(resultData['data1']['career']['masters_degree']));
                data1.push(parseFloat(resultData['data1']['career']['doctoral_or_professional_degree']));

                series.push({
                    name:resultData['data1']['state']['title'],
                    data: data1.reverse(),
                    color: '#0070c0'
                })

            }
        }

        if (resultData.data2.career !== null ) {
            if(resultData['data2'] !== undefined && resultData['data2']['career'] !== undefined ) {
                data2.push(parseFloat(resultData['data2']['career']['less_than_high_school_diploma']));
                data2.push(parseFloat(resultData['data2']['career']['high_school_diploma_or_equivalent']));
                data2.push(parseFloat(resultData['data2']['career']['some_college_no_degree']));
                data2.push(parseFloat(resultData['data2']['career']['associates_degree']));
                data2.push(parseFloat(resultData['data2']['career']['bachelors_degree']));
                data2.push(parseFloat(resultData['data2']['career']['masters_degree']));
                data2.push(parseFloat(resultData['data2']['career']['doctoral_or_professional_degree']));
                series.push({
                    name:resultData['data2']['state']['title'],
                    data: data2.reverse(),
                    color: '#ed7d31'
                })
            }

        }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'bar'
                        },
                        exporting: {
                            enabled: false
                        },
                        title: {
                            text: ''
                        },
                        subtitle: {
                            text: ''
                        },
                        xAxis: {
                            categories: ["Less than high school diploma","High school diploma or equivalent","Some college, no degree",
                            "Associate's degree","Bachelor's degree","Master's degree","Doctoral or professional degree"].reverse(),
                            title: {
                                text: null
                            },
                            labels: {
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            }
                        },
                        yAxis: {
                            title: {
                                text: '',
                                align: 'high'
                            },
                            labels: {
                                format: '{value:.0f}%',
                                overflow: 'justify',
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            },
                        },
                        tooltip: {
                            enabled: false,
                            formatter: function () {
                                return '<b>' + this.series.name + ': ' + this.y +  + '</b>';
                            },
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        },
                        // plotOptions: {
                        //     bar: {
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     }
                        // },
                        plotOptions: { 
                            bar: {
                                dataLabels: {
                                    textOutline: false,
                                    enabled: true,
                                    color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'black',
                                    formatter: function(){
                                        return (this.y!== 0)?Math.ceil(this.y)+"%":"";
                                    },
                                    style: {
                                        textOutline: false,
                                        color: '#ffffff',
                                        fontSize: '12px'
                                    },
                                }
                            },
                            series: {
                                dataLabels: {
                                    align: 'right',
                                    enabled: true,
                                    x: 40
                                },
                                // stacking: 'normal',
                                style: {
                                    color: '#000000',
                                    fontSize: '12px'
                                }
                            }                
                        },
                        // legend: {
                        //     layout: 'vertical',
                        //     align: 'right',
                        //     verticalAlign: 'top',
                        //     x: -40,
                        //     y: 80,
                        //     floating: true,
                        //     borderWidth: 1,
                        //     backgroundColor:
                        //         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        //     shadow: true,
                        //     enabled: true,
                        //     style: {
                        //         color: '#000000',
                        //         fontSize: '14px'
                        //     }
                        // },
                        credits: {
                            enabled: false
                        },
                        series: series
                    }
                }
            />
        </div>
    )
}

TENeeded.propTypes = {
    resultData: PropTypes.func.isRequired,
};

export default TENeeded