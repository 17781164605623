import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
// import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';

const VehiclesLease = ( props ) => {
  const { classes, vehicle, handleAssestObject, handleUpdatedObject} = props;

  React.useEffect(()=>{
    const inputValues = {...handleAssestObject};
    if (vehicle === 1) {
      if(inputValues['existing_car_loans_lease_months_remaining'] > 300){
        inputValues['existing_car_loans_lease_months_remaining'] = 300;
      }
    }else{
      if(inputValues[`existing_car_loans_lease_months_remaining_${vehicle}`] > 300){
        inputValues[`existing_car_loans_lease_months_remaining_${vehicle}`] = 300
      } 
    }
    handleUpdatedObject(inputValues);
  },[])

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    if (vehicle === 1) {
      if(field === 'existing_car_loans_lease_months_remaining' && e.floatValue > 300){
        newvalue = 300
      }
    }else{
      if(field === `existing_car_loans_lease_months_remaining_${vehicle}` && e.floatValue > 300){
        newvalue = 300
      } 
    }
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>Enter the lease details about Vehicle {vehicle} below.</p>
      <div className={classes.section4TableScroll}>
        <div className={ `${classes.section4Table} ${classes.sliderBottomSpace}` }>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }><span>Months Remaining on Lease</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Months() }
                  min={ 0 }
                  max={ 300 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_months_remaining' :  `existing_car_loans_lease_months_remaining_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_months_remaining' :  `existing_car_loans_lease_months_remaining_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              <span className={classes.textMos}>mos </span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.section4TableRightTwo10}` }>
            <div className={classes.section4TableRightOneDiv}>
            <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining / 12 :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] / 12 } /> years
            </div>
          </div>
          <div className={ classes.sectionClear30 }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.sectionNoData }>
              <span>&nbsp;</span>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Lease Payment</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_payment' :  `existing_car_loans_lease_payment_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Maintenance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_maintenance' :  `existing_car_loans_lease_maintenance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Insurance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_insurance' :  `existing_car_loans_lease_insurance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Gas / Electricity</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_gas' :  `existing_car_loans_lease_gas_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Expenses</p>
            </div>
          
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneMonth }>
              <span>Monthly</span>
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_payment' : `existing_car_loans_lease_payment_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_maintenance' : `existing_car_loans_lease_maintenance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_insurance' : `existing_car_loans_lease_insurance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_gas' : `existing_car_loans_lease_gas_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              {
                vehicle === 1 ?
                (modulesServices.module18Data.existingCarLoansTotalLeaseExpenses !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses } prefix={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                ) : ( '$0' ))
                  :
                (modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] < 0 && ')' } />
                ) : ( '$0' ))
              }
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.section4TableRightTwo10}` }>
            <div className={ classes.section4TableRightOneMYear }>
              <span>Annual</span>
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment * 12 :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] * 12 } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text"  thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance * 12 :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] * 12 } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text"  thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance * 12 :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] * 12 } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text"  thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas * 12 :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] * 12 } />
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
            {
                vehicle === 1 ?
                (modulesServices.module18Data.existingCarLoansTotalLeaseExpenses !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses * 12 } prefix={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
                ) : ( '$0' ))
                  :
                (modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] * 12 } prefix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] < 0 && ')' } />
                ) : ( '$0' ))
              }        
            </div>
          </div>
        </div>
      </div>

      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                <div>
                  <p>Months Remaining on Loan</p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn2Months() }
                      min={ 0 }
                      max={ 300 }
                      step={ 1 }
                      value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.mosFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_months_remaining' :  `existing_car_loans_lease_months_remaining_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <h3>Monthly</h3>
                <div className={ classes.inputRightBlock }>
                  <span>
                    <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_months_remaining' :  `existing_car_loans_lease_months_remaining_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                    months
                  </span>
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <div>
                  <h3>Annual</h3>
                  <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                    <span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_months_remaining / 12 :  handleAssestObject[`existing_car_loans_lease_months_remaining_${vehicle}`] / 12 } />
                      years
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.marginTop}>
        <div className={ classes.innnerFlowGroupMainFull }>
          <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p>Lease Payment</p>
                    <div className={ classes.annuvalIncomeGrowthGraph }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.vehicleOwn1Expenses() }
                        min={ 0 }
                        max={ 1000 }
                        step={ 1 }
                        value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] }
                        valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] )}` }
                        onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_payment' :  `existing_car_loans_lease_payment_${vehicle}`, 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <h3>Monthly</h3>
                  <div className={ classes.inputRightBlock }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_payment' : `existing_car_loans_lease_payment_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                <h3>Annual</h3>
                  <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_payment * 12 :  handleAssestObject[`existing_car_loans_lease_payment_${vehicle}`] * 12 } />
                  </div>
                </div>
              </div>
            </div>

            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p>Maintenance</p>
                    <div className={ classes.annuvalIncomeGrowthGraph }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.vehicleOwn1Expenses() }
                        min={ 0 }
                        max={ 1000 }
                        step={ 1 }
                        value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] }
                        valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] )}` }
                        onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_maintenance' :  `existing_car_loans_lease_maintenance_${vehicle}`, 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_maintenance' : `existing_car_loans_lease_maintenance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" decimalScale={0} thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_maintenance * 12 :  handleAssestObject[`existing_car_loans_lease_maintenance_${vehicle}`] * 12 } />
                  </div>
                </div>
              </div>
            </div>

            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p>Insurance</p>
                    <div className={ classes.annuvalIncomeGrowthGraph }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.vehicleOwn1Expenses() }
                        min={ 0 }
                        max={ 1000 }
                        step={ 1 }
                        value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] }
                        valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] )}` }
                        onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_insurance' :  `existing_car_loans_lease_insurance_${vehicle}`, 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <span>$</span>
                    <span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_insurance' : `existing_car_loans_lease_insurance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                    </span>
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" decimalScale={0} thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_insurance * 12 :  handleAssestObject[`existing_car_loans_lease_insurance_${vehicle}`] * 12 } />
                  </div>
                </div>
              </div>
            </div>

            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p>Gas / Electricity</p>
                    <div className={ classes.annuvalIncomeGrowthGraph }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.vehicleOwn1Expenses() }
                        min={ 0 }
                        max={ 1000 }
                        step={ 1 }
                        value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] }
                        valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] )}` }
                        onChange={ ( e, value ) => updateValue( e, value, vehicle === 1 ? 'existing_car_loans_lease_gas' :  `existing_car_loans_lease_gas_${vehicle}`, 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <span>$</span>
                    <span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === 1 ? 'existing_car_loans_lease_gas' : `existing_car_loans_lease_gas_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                    </span>
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <div className={ `${classes.inputRightBlock} ${classes.totalCostNewFlex} ${classes.flexNewFederal}` }>
                    <span>$</span>
                      <NumberFormat allowNegative={false} decimalScale={0} fixedDecimalScale displayType="text" decimalScale={0} thousandSeparator value={ vehicle === 1 ? handleAssestObject.existing_car_loans_lease_gas * 12 :  handleAssestObject[`existing_car_loans_lease_gas_${vehicle}`] * 12 } />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
        <h3>
          <strong>Total Expenses</strong>
        </h3>
        <div>
          <div>
            {
              vehicle === 1 ?
              (modulesServices.module18Data.existingCarLoansTotalLeaseExpenses !== undefined ? (
                <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses } prefix={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
              ) : ( '$0' ))
                :
              (modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] !== undefined ? (
                <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] } prefix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] < 0 && ')' } />
              ) : ( '$0' ))
            }
          </div>
        </div>
        <div className={ classes.rightBoxNogap }>
          <div>
            {
              vehicle === 1 ?
              (modulesServices.module18Data.existingCarLoansTotalLeaseExpenses !== undefined ? (
                <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses * 12 } prefix={ modulesServices.module18Data.existingCarLoansTotalLeaseExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingCarLoansMonthlyPayment < 0 && ')' } />
              ) : ( '$0' ))
                :
              (modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] !== undefined ? (
                <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] * 12 } prefix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data[`existingCarLoansTotalLeaseExpenses${vehicle}`] < 0 && ')' } />
              ) : ( '$0' ))
            }          
          </div>
        </div>
      </div> */}

    </div>
  );
};

VehiclesLease.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( VehiclesLease );
