import TickMark from '../../assets/img/pwi-investments/tick_mark_blue.png';
import FilterIcon from '../../assets/img/pwi-he/filter-edit.png';
import RightArrow from '../../assets/img/blue-left.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #09a',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '5px auto 10px',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '30px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      display: 'inline-block',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& ul': {
      listStyle: 'none',
      '& li': {
        backgroundImage: `url(${TickMark})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '11px',
        backgroundPosition: 'left top 8px',
        display: 'flex',
        paddingLeft: '35px',
        alignItems: 'center',
        [theme.breakpoints.down( 'xs' )]: {
          flexWrap: 'wrap',
          display: 'inline-block',
          lineHeight: '22px',
        },
      },
      '& select': {
        background: '#fbfdfd',
        border: 'none',
        outline: '0',
        borderBottom: '2px solid #337ab7',
        color: '#337ab7',
        textAlign: 'center',
        padding: '0 20px',
        height: '29px',
        cursor: 'pointer',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-300',
        fontSize: '21px',
        minWidth: '120px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
          padding: '0 10px',
          minWidth: '90px',
        },
      },
    },

    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '50px',
    paddingBottom: '20px',
    position: 'relative',
    '& > div': {
      margin: '0 0 0 auto',
    },
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
      '&:disabled': {
        float: 'right',
        backgroundColor: '#d5d5d5',
        color: '#333',
        margin: '0 0 0 auto',
        marginRight: '20px',
        border: '2px solid #d5d5d5',
        [theme.breakpoints.down( 'xs' )]: {
          marginRight: '0px',
        }
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0px 0 0',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  skipBtn: {
    // float: 'right',
    backgroundColor: '#d5d5d5',
    color: '#333',
    margin: '0 0 0 auto',
    marginRight: '20px',
    border: '2px solid #d5d5d5',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '5px',
    }
  },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 50px',
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      maxWidth: '765px',
      margin: '0px auto 0px',
      '@media (max-width: 767px)': {
        fontSize: '20px',
        textAlign: 'center',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        background: 'transparent',
        padding: '0',
        display: 'inline-block',
        margin: '5px',
        '& img': {
          maxWidth: '45px',
        },
      },
    },
  },
  singleValue: {
    fontFamily: 'MuseoSans-300',
    '& th': {
      '&:nth-child(1)': {
        textAlign: 'right',
        paddingRight: '32px',
      },
    },
  },
  federalIncomeTable: {
    border: '1px solid #000',
    fontSize: '18px',
    width: '80%',
    margin: '40px auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    lineHeight: '1.42857143',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '10.5px',
    },
    '& th': {
      fontWeight: 'normal',
    },
    '& tbody': {
      '& td': {
        textAlign: 'right',
        padding: '0 8px',
        [theme.breakpoints.down( 'xs' )]: {
          padding: '0 5px',
        },
        '&:nth-child(2)': {
          borderRight: '1px solid #000',
        },
        '&:nth-child(4)': {
          borderRight: '1px dashed #000',
        },
      },
    },
  },
  trHeader: {
    background: '#0070c0',
    color: '#fff',
    borderBottom: '1px solid #000',
    '& th': {
      padding: '0px 8px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '700',
      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
  standarddeduction: {
    textAlign: 'right',
    paddingRight: '59px',
    fontFamily: 'MuseoSans-500',
    fontWeight: '300',
    '& span': {
      borderBottom: '1px solid',
    },
  },
  incomeRanges: {
    '& th': {
      fontFamily: 'MuseoSans-500',
      fontWeight: '700',
    },
  },
  topBorder: {
    '& td': {
      borderTop: '1px solid #000',
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '20px',
    marginBottom: '30px',
    position: 'relative',
    '& p': {
      margin: '0',
    },
  },
  yearsList: {
    listStyle: 'none',
    margin: '0px',
    padding: '0',
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 30px 40px',
      fontSize: '21px',
      '&:last-child': {
        paddingBottom: '0px',
      },
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 20px',
      height: '29px',
      cursor: 'pointer',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
      fontSize: '21px',
    },
  },
  occupationName: {
    paddingBottom: '30px',
    '& input': {
      outline: '0',
      padding: '0 10px',
      lineHeight: '1.42857143',
      marginLeft: '15px',
      color: '#555',
      backgroundColor: '#fff',
      display: 'inline-flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      verticalAlign: 'middle',
      width: '100%',
      maxWidth: '200px',
      fontSize: '14px',
      fontFamily: 'MuseoSans-300',
      height: '30px',
      '@media (max-width: 414px)': {
        marginLeft: '0px',
        marginTop: '5px',
      }
    },
  },
  incomeTaxesTable: {
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: '20px',
    fontSize: '17px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& tr': {
      '& th': {
        padding: '8px',
        lineHeight: '1.42857143',
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
        textAlign: 'left',
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          borderTop: '1px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:last-child': {
            textAlign: 'right',
          },
        },
        '&:nth-child(3)': {
          fontFamily: 'MuseoSans-500',
          borderBottom: '2px solid #5686da',
        },
        '&:nth-child(5)': {
          fontFamily: 'MuseoSans-500',
          borderBottom: '2px solid #5686da',
        },
      },
    },
  },
  incomeTaxesSec: {
    maxWidth: '60%',
    margin: '0 auto',
    padding: '40px 40px 26px',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',
      padding: '40px 0px 26px',
    },
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& > div:last-child':{
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
    '@media (max-width: 991px)': {
      overflow: 'auto',
    },
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: '300',
      fontFamily: '"MuseoSans-300"',
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  clearBtn: {
    margin: '0 15px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 5px',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  careerInput: {
    width: '170px',
    marginRight: '39px',
    marginTop: '30px',
    marginBottom: '30px',
    '& label': {
      fontSize: '21px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  potentialInputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  inputGroup: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& span': {
      padding: '6px 6px',
      backgroundColor: 'white',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      fontSize: '20px',
      paddingRight: '0px',
      fontWeight: '400',
      lineHeight: '1',
      color: '#555',
      textAlign: 'center',
      width: '1%',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  dollarSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    fontFamily: 'MuseoSans-300',
  },
  formInput: {
    fontSize: '20px',
    zIndex: '0',
    outline: '0',
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    width: '100%',
    padding: '4px',
    display: 'table-cell',
    position: 'relative',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeftWidth: '0px',
    height: 'auto',
    '& >div': {
      '&:before': {
        content: 'none',
      },
      '&:after': {
        content: 'none',
      },
    },
    '& input': {
      fontSize: '20px',
    },
  },
  returnInput: {
    borderLeftWidth: '1px',
    borderRightWidth: '0px',
    paddingLeft: '6px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    paddingRight: '0px',
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
        color: '#555',
      },
    },
  },
  annlInput: {
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
      },
    },
  },
  percentSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderLeft: '0',
    paddingRight: '6px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderRightWidth: '1px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  calculateButton: {
    backgroundColor: '#fd9840',
    fontSize: '21px',
    color: '#ffffff',
    lineHeight: '19px',
    borderColor: '#fd9840',
    boxShadow: 'none',
    borderRadius: '3px',
    padding: '10px 20px',
    marginLeft: '0px',
    borderWidth: '2px',
    marginTop: '34px',
    outline: '0',
    '&:hover': {
      backgroundColor: '#f28735',
      borderColor: '#f28735',
      boxShadow: 'none',
    },
  },
  netWorth: {
    margin: '37px 0 0 70px',
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      padding: '0',
      width: '100%',
      '& li': {
        display: 'block',
        padding: '0 0 8px',
        margin: '0',
        background: 'none',
        '&:last-child': {
          marginBottom: '0',
          padding: '0 0 0px',
        },
        '& p': {
          fontFamily: 'MuseoSans-300',
          fontSize: '18px',
          lineHeight: '18px',
          padding: '0',
          margin: '0',
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontFamily: 'MuseoSans-500',
            fontSize: '12px',
            lineHeight: '12px',
            padding: '4px 13px',
            margin: '0 14px 0 0',
            background: '#ebebeb',
            borderRadius: '12px',
            minWidth: '72px',
            textAlign: 'center',
            boxSizing: 'border-box',
          },
          '& font': {
            '& span': {
              width: 'auto',
              fontSize: '20px',
              display: 'inline-block',
              verticalAlign: 'middle',
              color: theme.palette.common.black,
            },
          },
        },
      },
    },
  },
  foodExpenses: {
    fontSize: '23px',
    fontWeight: '300',
    color: '#000',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    '@media (max-width: 767px)': {
      lineHeight: '24px',
      fontSize: '16px',
    },
    '& img': {
      width: '27px',
      margin: '0 10px',
    },
  },
  borderDivider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  planList: {
    paddingTop: '30px',
  },
  amountDollar: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  raisingChild: {
    padding: '50px 33px 26px',
    maxWidth: '58%',
    margin: '0 auto',
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '50px 0px 26px',
    },
    '& table': {
      width: '100%',
      maxWidth: '100%',
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
          padding: '8px',
          textAlign: 'left',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '22px',
          }
        },
      },
      '& tbody': {
        '& tr': {
          backgroundColor: '#f2f2f2',
          '& td': {
            borderTop: '1px solid #fff',
            fontSize: '20px',
            verticalAlign: 'middle',
            padding: '8px',
            '@media (max-width: 767px)': {
              fontSize: '16px',
            },
            '& input': {
              paddingTop: '1px',              
              paddingBottom: '5px',
              height: '32px',
              boxSizing: 'border-box',
              '&:focus': {
                borderBottom: '2px solid #f89b22',
              },
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            borderTop: '2px solid #0069aa',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
    '& div': {
      '&::before, &::after': {
        borderBottom: 'none !important',
      },
    },
  },
  inputBox: {
    display: 'table',
    position: 'relative',
    borderCollapse: 'seperate',
    paddingTop: '10px',
    marginBottom: '15px',
    '& input': {
      border: '1px solid #ccc',
      height: '30px',
      padding: '0 10px',
      minWidth: '280px',
      marginLeft: '10px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      outline: 'none',
      fontFamily: 'inherit',
      '&:focus': {
        border: '1px solid #0069aa',
      },
      '@media (max-width: 767px)': {
        marginLeft: '0px',
      }
    },
  },
  raisingPet: {
    display: 'flex',
    margin: '20px auto',
    '@media (max-width: 767px)': {
      display: 'block',
    },
    '& table': {
      width: '100%',
      maxWidth: '100%',
      lineHeight: '1.42857143',
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
          padding: '5px 8px',
          textAlign: 'left',
          '&:last-child': {
            textAlign: 'right',
          },
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
      },
      '& tbody': {
        '& tr': {
          backgroundColor: '#f2f2f2',
          '& td': {
            borderTop: '1px solid #fff',
            fontSize: '16px',
            verticalAlign: 'middle',
            padding: '2px 8px',
            '&:last-child': {
              textAlign: 'right',
            },
            '& > div': {
              '& input': {
                fontSize: '16px',
              },
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            borderBottom: '2px solid #0069aa',
            fontSize: '16px',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  buyingPet: {
    width: '50%',
    paddingRight: '30px',
    '@media (max-width: 767px)': {
      paddingRight: '0px',
      width: '100%',
      margin: '0px auto 20px',
    }
  },
  owningPet: {
    width: '50%',
    paddingLeft: '30px',
    '@media (max-width: 767px)': {
      paddingLeft: '0px',
      width: '100%',
      margin: '0px auto 20px',
    }
  },
  marriageSection: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '30px 0px',
  },
  marriageYear: {
    maxWidth: '170px',
    minWidth: '170px',
    paddingRight: '20px',
    '& p': {
      fontSize: '20px',
      marginBottom: '0px',
    },
  },
  yearNumber: {
    padding: '0px 30px',
    [theme.breakpoints.down( 'xs' )]: {
      position: 'absolute',
      right: '0px',
      top: '0px',
    },
    '& span': {
      fontSize: '18px',
    },
  },
  salaryBlock: {
    display: 'flex',
    marginLeft: '0',
    marginBottom: '10px',
    '& label': {
      paddingRight: '5px',
    },
    '& input': {
      width: '17px',
      height: '17px',
      marginRight: '8px',
    },
  },
  salaryHourly: {
    minWidth: '195px',
  },
  occupationTable: {
    border: '1px solid #ddd',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    fontSize: '14px',
    '& thead': {
      '& tr': {
        '& th': {
          padding: '10px 3px',
          lineHeight: '1.42857143',
          verticalAlign: 'bottom',
          '&:first-child': {
            width: '200px',
            textAlign: 'left',
          },
        },
        '&:nth-child(2)': {
          '& th': {
            border: '1px solid #ddd',
            textAlign: 'right',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          border: '1px solid #ddd',
          verticalAlign: 'middle',
          height: '38px',
          padding: '5px 8px',
          lineHeight: '1.2',
          textAlign: 'right',
          '&:first-child': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  filterIcon: {
    '& span': {
      position: 'relative',
      marginLeft: '2px',
      '& img': {
        maxWidth: '15px',
        cursor: 'pointer',
        verticalAlign: 'middle',
      },
    },
  },
  occupationTab: {
    margin: '50px 0 0',
  },
  paginationBlock: {
    justifyContent: 'center',
    margin: '20px auto',
    display: 'flex',
    '& nav': {
      '& ul': {
        '& li': {
          border: '1px solid #ddd',
          color: '#0069aa',
          display: 'flex',
          '& div': {
            height: '32px',
            padding: '2px 0px',
          },
          '& button': {
            margin: '0px',
            borderRadius: '0px',
            background: 'transparent',
            color: '#0069aa',
            '&:hover': {
              background: 'transparent',
            },
            '&:active': {
              background: 'transparent',
            },
            '&:focus': {
              background: 'transparent',
            },
          },
          '&:hover': {
            backgroundColor: '#eee',
          },
          '&:active': {
            backgroundColor: '#0069aa',
            color: '#fff',
          },
        },
      },
    },
  },
  pagination: {
    textAlign: 'center',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      fontSize: '14px',
      '& li': {
        display: 'inline-block !important',
        background: 'none !important',
        padding: '0 !important',
        cursor: 'pointer',
        '& a': {
          position: 'relative',
          float: 'left',
          padding: '6px 12px',
          marginLeft: '-1px',
          lineHeight: '1.42857143',
          color: '#337ab7',
          textDecoration: 'none',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          '&:hover': {
            textDecoration: 'underline',
            color: '#337ab7',
          },
        },
      },
    },
  },
  activePagination: {
    '& a': {
      background: '#337ab7 !important',
      color: '#fff !important',
      border: '1px solid #337ab7 !important',
    },

  },
  industryTable: {
    '& thead': {
      '& tr': {
        '& th': {
          '&:first-child': {
            width: '300px',
            textAlign: 'left',
          },
        },
      },
    },
  },
  combinedBudget: {
    padding: '20px 60px 26px',
  },
  combinedBudgetTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    marginBottom: '30px',
    width: '100%',
    maxWidth: '100%',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          textAlign: 'right',
          borderTop: '1px solid #fff',
          fontFamily: 'MuseoSans-100',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:nth-child(2)': {
            textAlign: 'left',
          },
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
        },
      },
    },
  },
  grayRow: {
    backgroundColor: '#f2f2f2',
    '&:hover': {
      background: '#eceaea',
    },
  },
  livingRow: {
    backgroundColor: '#fce4d6',
    '& td': {
      fontStyle: 'normal',
    },
    '&:hover': {
      backgroundColor: '#d5b6a4',
    },
  },
  otherExpenses: {
    backgroundColor: '#fff2cc',
    '& td': {
      fontStyle: 'normal',
    },
    '&:hover': {
      backgroundColor: '#e3d6af',
    },
  },
  blueRow: {
    backgroundColor: '#dae3f3',
    // borderTop: '2px solid #000 !important',
  },
  leftPadding: {
    paddingLeft: '40px !important',
  },
  leftSubPadding: {
    paddingLeft: '60px !important',
    fontStyle: 'italic',
  },
  textDanger: {
    color: '#bf0000',
  },
  petType: {
    width: '130px',
    fontSize: '20px',
    display: 'inline-block',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      display: 'block',
    }
  },
  purchaseSection: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  purchaseYear: {
    maxWidth: '170px',
    minWidth: '170px',
    paddingRight: '20px',
    '& p': {
      fontSize: '20px',
      marginBottom: '0px',
    },
  },
  innnerFlowGroupMain: {
    position: 'relative',
    '& > div':{
      [theme.breakpoints.down( 'xs' )]: {
        position: 'relative',
      }
    }
  },
  innnerFlowGroup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    }
  },
  AnnualIncomeGrowth: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        flexWrap: 'wrap',
      },
      '& p': {
        margin: '0',
        minWidth: '200px',
        maxWidth: '200px',
        fontSize: '21px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
          cursor: 'pointer',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
      },
    },
  },
  annuvalGrowthNew: {
    [theme.breakpoints.down( 'xs' )]: {
      position: 'relative',
    },
    '& > div': {
      margin: '0',
    },
  },
  annuvalIncomeGrowthGraphNew: {
    marginTop: '20px',
    '& img': {
      width: '100%',
    },
  },
  graphLeft: {
    width: '70%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      width: '80%',
    },
    '& h3': {
      marginBottom: '0',
    },
  },
  graphRight: {
    display: 'flex',
    width: '20%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '50%',
      marginTop: '30px',
    },
    '& h3': {
      marginBottom: '20px',
      fontSize: '20px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      marginTop: '0px',
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
        margin: '10px 0px 0px',
      },
    },
  },
  inputRightBlock: {
    display: 'flex',
    fontSize: '18px',
    alignItems: 'center',
    lineHeight: '25px',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
    },
  },
  p: {
    '& i': {
      color: '#0069aa',
    },
  },
  expensesGroup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minWidth: '950px',
    }
  },
  leftBlock: {
    width: '55%',
    '& h3': {
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      textAlign: 'right',
      fontSize: '20px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      }
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
    },
  },
  rightBlock: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '45%',
    '& h3': {
      marginBottom: '10px',
      marginTop: '0',
      fontSize: '18px',
      lineHeight: '23px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      minHeight: '70px',
    },
    '& > div': {
      width: '33.33%',
      margin: '0 25px',
    },
  },
  expensesGraphNew: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  livingExpenses: {
    position: 'relative',
    '@media (max-width: 991px)': {
      overflow: 'auto',
    },
    '& > div': {
      marginBottom: '25px',
      '&:first-child': {
        marginBottom: '0',
      },
      '& > div:nth-child(2)': {
        width: '50%',
        marginTop: '18px',
        '& h3': {
          maxWidth: '60px',
          marginLeft: 'auto',
          minHeight: 'inherit',
          lineHeight: 'inherit',
        },
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '270px',
      '@media (max-width: 991px)': {
        right: 'calc(100% - 675px)',
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '127px',
      top: '0',
      '@media (max-width: 991px)': {
        right: 'calc(100% - 825px)',
      }
    },
  },
  graphInputBlock: {
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'flex-end',
    '@media (max-width: 991px)': {
      fontSize: '16px',
    },
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
    },
  },
  expensesGrowth: {
    '& > div': {
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      '& p': {
        margin: '0',
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },
  },
  expensesGrowthNew: {
    '& > div': {
      margin: '0',
      '& p': {
        paddingLeft: '8px',
        lineHeight: '15px',
      },
    },
  },
  leftGroup: {
    width: '70%',
  },
  intrestGroup: {
    '& > div': {
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '10px',
      marginTop: '0px',
      display: 'flex',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '26px',
      },
      '& p': {
        margin: '0',
        paddingRight: '20px',
        minWidth: '200px',
        maxWidth: '200px',
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
          cursor: 'pointer',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '24px',
          }
        },
      },
      '& h3': {
        margin: '0px',
        textAlign: 'right',
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },
  },
  rightGroup: {
    display: 'flex',
    width: '30%',
    '& h3': {
      marginBottom: '20px',
      fontSize: '20px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      marginTop: '0px',
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
      maxWidth: '100px',
    },
  },
  intrestInput: {
    display: 'flex',
    fontSize: '18px',
    lineHeight: '25px',
    alignItems: 'center',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
    },
  },
  otherExpence: {
    width: '100%',
    '& h3': {
      width: '100%',
      margin: '0',
      textAlign: 'right',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      }
    },
  },
  expenseText: {
    display: 'flex',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'right',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    }
  },
  annuvalIncomeGrowthGraph: {
    width: '100%',
    margin: '10px 0 0',
    padding: '0 15px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  tabButtons: {
    paddingTop: '50px',
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      color: '#888',
      textTransform: 'inherit',
      minHeight: 'inherit',
      padding: '8px 25px',
      minWidth: '180px',
      fontSize: '16px',
      boxSizing: 'border-box',
      lineHeight: '40px',
      height: '40px',
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '150px',
      },
      '&:hover': {
        color: '#0069aa !important',
      },
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflow: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
    color: '#0069aa !important',
  },
  tabContentBaseIncome: {
    '& > div > div > div > div': {
      padding: '0',
    },
  },
  textFieldSearch: {
    fontSize: '14px',
    fontFamily: 'MuseoSans-700',
    padding: '15px 0 0 25px',
    '@media (max-width: 529px)': {
      padding: '15px 0 0 0px',
    }
  },
  searchTextField: {
    width: '70%',
    marginLeft: '15px',
    maxWidth: '400px',
    '@media (max-width: 529px)': {
      marginLeft: '0px',
      display: 'block',
    },
    '&::before': {
      content: '"\\f002"',
      display: 'inline-block',
      font: 'normal normal normal 14px/1 FontAwesome',
      fontSize: 'inherit',
      textRendering: 'auto',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      position: 'absolute',
      right: '10px',
      top: '8px',
      color: '#4a87c0',
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      border: '1px solid #ccc',
      borderRadius: '15px',
      padding: '7px 15px',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ChooseSchoolTable: {
    '@media (max-width: 767px)': {
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
      fontSize: '14px',
      marginTop: '15px',
      border: '1px solid #ddd',
      borderCollapse: 'collapse',
      '& tr': {
        borderBottom: '1px solid #ccc',
        cursor: 'pointer',
        '&:first-child': {
          cursor: 'inherit',
          '&:hover': {
            background: 'none',
          },
        },
        '&:hover': {
          background: '#f2f2f2',
        },
      },
      '& tr:first-child': {
        fontFamily: 'MuseoSans-700',
      },
      '& td': {
        verticalAlign: 'middle',
        color: '#000',
        padding: '5px 8px',
        lineHeight: '1.2',
        height: '38px',
        border: ' 1px solid #ddd',
        '& span': {
          display: 'block',
          textAlign: 'right',
        },
        '&:first-child': {
          width: '20%',
        },
        '@media (max-width: 991px)': {
          minWidth: '100px',
        },
        '& i': {
          display: 'inline-block',
          paddingLeft: '8px',
          position: 'relative',
          color: 'transparent',
          backgroundImage: `url(${FilterIcon})`,
          cursor: 'pointer',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 0px top 0px',
          '& ul': {
            position: 'absolute',
            padding: '0',
            background: '#e9e6e6',
            left: '-46px',
            top: '10px',
            '&::before': {
              content: "''",
              position: 'absolute',
              borderLeft: '9px solid transparent',
              borderRight: '9px solid transparent',
              width: '0',
              background: '0 0',
              borderBottom: '9px solid #e9e6e6',
              borderTop: '10px solid transparent',
              height: 'auto',
              left: '50%',
              top: '-19px',
              transform: 'translateX(-50%)',
            },
            '& li': {
              background: 'none',
              color: '#000',
              width: '80px',
              padding: '4px 20px',
              fontSize: '14px',
              lineHeight: '1.4',
              cursor: 'pointer',
              fontFamily: 'MuseoSans-300',
              margin: '0',
              '&:hover': {
                color: '#fff',
                background: '#0169aa',
              },
            },
          },
        },
      },
    },
  },
  headSection: {
    '& td': {
      paddingRight: '3px !important',
      paddingLeft: '3px !important',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
   SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  sliderIntruduction: {
    maxWidth: '60%',
    margin: '50px auto 0',
    position: 'relative',
    '& ul': {
      bottom: '-45px',
      '& li': {
        width: '12px',
        background: 'none',
        display: 'inline-block',
        padding: '0',
        height: '12px',
        marginTop: '10px',
        '& button': {
          borderRadius: '50%',
          border: '2px solid #0069aa',
          backgroundColor: '#0069aa',
          width: '5px',
          height: '5px',
          padding: '4.5px',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    '& img': {
      maxHeight: '350px',
      width: '100%',
      objectFit: 'cover',
    },
  },
  accorodianTotalCost: {
    display: 'block',
    margin: '0 !important',
    background: '#f2f2f2',
    borderBottom: '2px solid #fffffff5',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
    '& p': {
      margin: '0',
      fontSize: '20px',
      display: 'flex',
      color: '#000',
      paddingLeft: '25px',
      lineHeight: '0',
      '& span': {
        margin: '0 0 0 auto',
      },
      '& font': {
        color: '#000',
      },
    },
  },
  iconsTextLeft: {
    '& > div': {
      '& + div': {
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        margin: 'auto',
      },
    },
  },
  totalCotBox: {
    display: 'block',
    padding: '0',
    '& > div': {
      display: 'flex',
      background: '#fce4d6',
      borderBottom: '2px solid #fff',
      padding: '5px 18px 5px 43px',
      '& span': {
        width: '100px',
        margin: '0 0 0 auto',

      },
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '@media (max-width: 767px)': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 185px)',
    },
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '16px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        marginTop: '15px',
      },
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& button': {
          padding: 0,
          margin: 0,
          minWidth: 'auto',
          '& span': {
            marginRight: 0,
            paddingLeft: 0,
          },
        },
        '& img': {
          width: '3.8vh',
          display: 'flex',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  moduleToolTip: {
    padding: '3px 8px !important',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  totalIncomeBox: {
    display: 'flex',
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-500',
    marginTop: '10px',
    fontSize: '18px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '17px',
      lineHeight: '22px',
    },
    '& span': {
      width: '70%',
      textAlign: 'right',
      [theme.breakpoints.down( 'xs' )]: {
        textAlign: 'left',
      }
    },
    '& > div': {
      width: '100px',
      margin: '0 25px 0 20px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  vacationTable: {
    padding: '20px 33px 26px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0px 26px',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      maxWidth: '100%',
      margin: '0 auto',
      fontSize: '20px',
      width: '100%',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& tr': {
        borderTop: '1px solid #fff',
        cursor: 'pointer',
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: '500',
          fontFamily: 'MuseoSans-500',
          padding: '8px',
          lineHeight: '1.2',
          color: '#333',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            textAlign: 'left',
          },
          '& select': {
            border: 'none',
            background: 'transparent',
            fontSize: '18px',
            outline: 'none',
          },
          '&:last-child': {
            textAlign: 'right',
            '@media (max-width: 767px)': {
              minWidth: '110px',
            }
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:nth-child(4)': {
            textAlign: 'right',
          },
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.2',
          color:'#333',
          '&:last-child': {
            textAlign: 'right',
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:nth-child(4)': {
            textAlign: 'right',
          },
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
          '& i': {
            color: '#000',
          },
        },
      },
    },
  },
  budgetTable: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      maxWidth: '100%',
      thead: {
        '& tr': {
          '& th': {
            fontSize: '17px',
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '&:nth-child(5)': {
              textAlign: 'right',
            },
          },
        },
      },
      tbody: {
        '& tr': {
          '& td': {
            fontSize: '17px',
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '&:nth-child(5)': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  marrigeYear: {
    '& > div': {
      '& p': {
        minWidth: '170px',
        maxWidth: '170px',
      },
    },
  },
  marrigeGraph: {
    width: '60%',
    marginRight: '0',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    }
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  gayBoxHalf: {
    width: '75%',
    padding: '40px',
  },
  annuvalRightSpace: {
    fontSize: '18px',
    marginBottom: '15px',
    fontWeight: '700',
    textAlign: 'right',
    width: '81%',
    textDecoration: 'underline',
    [theme.breakpoints.down( 'xs' )]: {
      textAlign: 'left',
      position: 'absolute',
      bottom: '15px',
      left: '0px',
    }
  },
  underLineSpace: {
    cursor: 'pointer',
  },
  testimonialInner: {
    '&:focus': {
      outline: 'none',
    },
    '& > div': {
      alignItems: 'center',
      position: 'static',
      '& ul': {
        bottom: '10px',
        left: '0',
        [theme.breakpoints.down( 'xs' )]: {
          bottom: '20px',
        },
      },
      '& li': {
        top: '80px',
        margin: ' 0 5px',
        '& button': {
          background: '#0069aa',
          borderRadius: '50%',
          height: '13px',
          width: '13px',
          '&:hover, &:focus': {
            background: '#0069aa',
          },
          '&::before': {
            display: 'none',
          },
        },
      },
      '& button + div + button': {
        right: '-100px',
        left: 'inherit',
        backgroundImage: `url(${RightArrow})`,
        transform: 'none',
        [theme.breakpoints.down( 'xs' )]: {
          right: '-80px',
        },
        '&:hover, &:focus': {
          backgroundImage: `url(${RightArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      },
      '& button': {
        backgroundImage: `url(${RightArrow})`,
        height: '80px',
        width: '80px',
        left: '-100px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '-30px',
        backgroundSize: '40px',
        transform: 'rotate(180deg)',
        [theme.breakpoints.down( 'xs' )]: {
          left: '-80px',
          marginTop: '-45px',
          backgroundSize: '30px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${RightArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '-30px',
          backgroundSize: '40px',
          [theme.breakpoints.down( 'xs' )]: {
            marginTop: '-45px',
            backgroundSize: '30px',
          }
        },
        '&::before': {
          display: 'none',
        },
      },

    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      fontSize: '30px',
      position: 'relative',
      '& img': {
        display: 'inline-block',
        position: 'absolute',
        left: '-65px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '20px',
          left: '-30px',
        },
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      paddingBottom: '30px',
      marginBottom: '10px',
    },
    '& h5': {
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      textTransform: 'uppercase',
    },
    '& figure img': {
      width: '175px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& > div': {
        display: 'block',
      },
      '& li': {
        top: '11px',
      },
      '& h3': {
        fontSize: '20px',
      },
      '& p': {
        fontSize: '14px',
      },
      '& h5': {
        fontSize: '14px',
      },
      '& figure img': {
        margin: '0 auto',
      },
    },
  },
  marginTopGap: {
    marginTop: '35px',
  },
  grayBoxHalf: {
    width: '75%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  updatingExpenses: {
    marginBottom: '0 !important',
  },
  monthsPosition: {
    position: 'absolute',
    right: '94px',
    top: '18px',
    bottom: '0',
    margin: 'auto',
  },
  monthPositionDebt: {
    right: '60px',
  },
  inputRightBoxNew: {
    top: '0',
    right: '-60px',
    borderLeft: '3px solid #0069aa',
    position: 'absolute',
    paddingLeft: '14px',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderColor: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
  },
  weightedAverage: {
    maxWidth: '93%',
    '@media (max-width: 767px)': {
      maxWidth: '100%',
      overflow: 'auto',
    },
    '& > div': {
      '& > div:nth-child(2)': {
        marginBottom: '10px',
      },
    },
  },
  topGapDebt: {
    marginTop: '30px',
  },
  startingFamilyGroup: {
    marginTop: '20px',

  },
  textRight: {
    textAlign: 'right !important',
  },
  TwoGapsNew: {
    margin: '30px 0 !important',
  },
  blackBorder: {
    borderBottom: '2px solid #000',
  },
  marrigeYearMargin: {
    marginBottom: '30px',
  },
  blueTextNew: {
    color: '#337ab7',
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#f89b23',
    },
  },
  bottomText: {
    color: '#0069aa',
    fontSize: '16px',
    borderTop: '1px solid #ccc',
    paddingTop: '15px',
    '& p': {
      margin: '0 0 15px',
      fontStyle: 'italic',
    },
    '& a': {
      borderBottom: '1px solid #0069aa',
      '&:hover': {
        color: '#f89b23',
        borderBottom: '1px solid #f89b23',
      },
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  inputRightBoxNewDebt: {
    right: '-100px',
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  quickSave:{
    // position: 'absolute',
    // right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    marginRight: '20px !important',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  boldText: {
    fontFamily: 'MuseoSans-500',
  },
  resetIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    width: '34px',
    cursor: 'pointer',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  dangerText: {
    color: '#bf0000 !important'
  },
  activeMenu: {
    color: '#000 !important',
    '&:hover': {
      color: '#eca041 !important'
    }
  },
  disableActiveMenu: {
    color: 'gray !important',
    pointerEvents: 'none',
  },
  percentPadding: {
    paddingRight: '6px !important',
    fontFamily: 'MuseoSans-300',
  },
  aiInput: {
    marginTop: '5px',
  },
  dataFourZero: {
    paddingLeft: '40px !important',
  },
  dataSixZero: {
    paddingLeft: '60px !important',
  },
  familySummaryTable: {
    padding: '20px 60px 26px !important',
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
} );


export default styles;
