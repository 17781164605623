const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  topImage: {
    '& img': {
      width: '100%',
      position: 'absolute',
      zIndex: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        background: '#004375',
        height: '180px',
      },
    },
  },
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsLogoImage: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '300px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '200px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '300px',
        },
      },
    },
  },
  mcsLevelNum: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& h3': {
      color: '#fff',
      textTransform: 'uppercase',
      marginRight: '15px',
      marginTop: '0',
      marginBottom: '0',
      fontWeight: '500',
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
  },
  infoIcon: {
    marginRight: '5px',
    borderRadius: '4px',
    padding: '6px 10px',
    display: 'inline-flex',
    outline: '0',
    '&:hover': {
      backgroundColor: '#333',
    },
    '& img': {
      width: '25px',
      verticalAlign: 'middle',
    },
  },
  iconImage: {
    width: '80px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      width: '40px',
    },
  },
  leftBlock: {
    paddingRight: '115px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
  },
  mcsLevelTitle: {
    color: '#1f96e0',
    fontSize: '75px',
    fontFamily: 'MuseoSans-700',
    margin: '30px 0',
    fontWeight: '500',
    lineHeight: '1.1',
    [theme.breakpoints.down('md')]: {
      fontSize: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '70px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
    },
  },
  qPosition: {
    position: 'relative',
    paddingLeft: '15px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  questionBlock: {
    marginBottom: '20px',
    '& p': {
      fontSize: '16px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '25px',
      margin: '0 0 10px',
    },
  },
  noBottomMargin: {
    marginBottom: '0px !important',
  },
  blueColor: {
    color: '#0069aa',
  },
  rightBlock: {
    paddingTop: '150px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
      paddingLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingTop: '0px',
    },
  },
  activeRow: {
    width: '70%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  startButton: {
    paddingTop: '40px',
    '& button': {
      padding: '7px 40px',
      background: '#8ba659',
      textTransform: 'uppercase',
      border: '1px solid #8ba659',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '17px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',
      letterSpacing: '1px',
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },
  mcsBodyContent: {
    paddingBottom: '50px',
  },
  stonesImage: {
    margin: '150px 0px 95px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '50px 0px 50px 0px',
    },
    '& img': {
      width: '100%',
      filter: 'drop-shadow(0 0 30px #ccc)',
    },
    '& video': {
      width: '100%',
      display: 'inline-block',
      verticalAlign: 'baseline',
      boxShadow: '0 0 30px #ccc',
    },
  },
  activities: {
    '& h4': {
      fontSize: '16px',
      color: '#0069aa',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      marginBottom: '5px',
    },
    '& p': {
      marginBottom: '0px',
      fontSize: '16px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '25px',
      marginTop: '0px',
      '& span': {
        paddingLeft: '10px',
      },
    },
  },
  checkboxRadio: {
    margin: '3px 0px',
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      position: 'relative',
      fontWeight: '400',
      padding: '0 0 0 30px',
      margin: '0',
      color: '#000',
      fontSize: '16px',
      '&::after': {
        background: '#fff',
        border: '1px solid #898d8f',
        content: '""',
        height: '13px',
        left: '0',
        position: 'absolute',
        top: '5px',
        width: '13px',
        boxSizing: 'border-box',
      },
      '& input[type=checkbox]': {
        display: 'none',
      },
    },
  },
  checkedEmployment: {
    '&::before': {
      borderColor: '#0069aa',
      borderImage: 'none',
      borderStyle: 'solid',
      borderWidth: '0 1px 1px 0',
      content: '""',
      display: 'block',
      left: '4px',
      opacity: '1',
      position: 'absolute',
      top: '5px',
      transform: 'rotate(35deg)',
      visibility: 'visible',
      width: '5px',
      height: '11px',
      zIndex: '9',
      boxSizing: 'border-box',
    },
  },
  myLevelProgress: {
    '& h4': {
      color: '#0069aa',
      fontSize: '18px',
      fontWeight: '500',
      textTransform: 'uppercase',
      marginBottom: '0px',
    },
  },
  progressPuzzle: {
    width: '90%',
    alignItems: 'center',
    display: 'flex',
  },
  progressPercent: {
    marginBottom: '0px',
  },
  puzzleBar: {
    border: 'none',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    width: '100%',
    margin: '0px 0px 0px 10px',
    '& img': {
      height: '50px',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
});

export default styles;
