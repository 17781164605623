import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
// import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';

const HomesLoan = ( props ) => {
  const { classes, home, handleAssestObject, handleUpdatedObject, handleDefinationPopup} = props;

  const [isFocus, setIsFocus] = React.useState('');

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>Enter the mortgage details about your current home below. If you do not have a loan, skip the below and continue.</p>
      <div className={classes.section4TableScroll}>
        <div className={classes.section4Table}>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }>Current Mortgage Balance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_current_mortgage_balance' :  `existig_home_current_mortgage_balance_${home}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Intrest() }
                  min={ 0 }
                  max={ 15 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_interest_rate' :  `existig_home_mortgage_interest_rate_${home}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_current_mortgage_balance' : `existig_home_current_mortgage_balance_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <NumberFormat
                customInput={ TextField }
                thousandSeparator
                value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] }
                decimalScale={isFocus!=='existig_home_mortgage_interest_rate'?2:5}
                fixedDecimalScale={isFocus!=='existig_home_mortgage_interest_rate'}
                onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_interest_rate' : `existig_home_mortgage_interest_rate_${home}`, 'number' ) }
                onFocus={ ( e ) => { e.target.select(); setIsFocus('existig_home_mortgage_interest_rate') } }
                onBlur={emptyIsFocus} />
              <span className={classes.textPer}>%</span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
          <div className={ classes.sectionClear }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) }>Months Remaining on Loan</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.monthsToMaturity() }
                  min={ 0 }
                  max={ 360 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_months_to_maturity' :  `existig_home_mortgage_months_to_maturity_${home}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] }
                  decimalScale={isFocus!=='existig_home_mortgage_months_to_maturity'?2:5}
                  fixedDecimalScale={isFocus!=='existig_home_mortgage_months_to_maturity'}
                  onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_months_to_maturity' : `existig_home_mortgage_months_to_maturity_${home}`, 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('existig_home_mortgage_months_to_maturity') } }
                  onBlur={emptyIsFocus} />
                <span className={classes.textMonts}>months </span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.sectionLeft50}` }>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.sectionLRight30}` }>
            <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator value={ home === 1 ? parseInt(handleAssestObject.existig_home_mortgage_months_to_maturity/ 12) :  parseInt(handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] / 12) }/> years
            </div>
          </div>
          <div className={ classes.sectionClear }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Monthly Payment (Principal & Interest)</p>
            </div>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Repayment Amount (Principal & Interest)</p>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              { !isNaN(modulesServices.module18Data.existingHomeMortgageMonthlyPayment) && modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? ( 
                <NumberFormat decimalScale={ 0 } fixedDecimalScale fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment } prefix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment < 0 && ')' } />
              ) : ( '$0' )}
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              { !isNaN(modulesServices.module18Data.existingHomeMortgageMonthlyPayment) && modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? ( 
                <NumberFormat decimalScale={ 0 } fixedDecimalScale fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest } prefix={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest < 0 && ')' } />
              ) : ( '$0' )}
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ classes.sliderBottomSpaceNewOne }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Current Mortgage Balance</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_current_mortgage_balance' :  `existig_home_current_mortgage_balance_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_current_mortgage_balance' : `existig_home_current_mortgage_balance_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p onClick={ () => handleDefinationPopup( 'Interest Rate' ) }><span>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ sliderValues.vehicleOwn2Intrest() }
                    min={ 0 }
                    max={ 15 }
                    step={ 1 }
                    value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] }
                    valueLabelFormat={ `${commonFunctions.perFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] )}` }
                    onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_interest_rate' :  `existig_home_mortgage_interest_rate_${home}`, 'slider' ) }
                  />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_interest_rate' : `existig_home_mortgage_interest_rate_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                  %
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftMonts}` }>
            
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                  <div>
                    <p onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) } ><span className={ classes.definationTittle }>Months Remaining on Loan</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          ValueLabelComponent={ ValueLabelComponent }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          marks={ sliderValues.monthsToMaturity() }
                          min={ 0 }
                          max={ 360 }
                          step={ 1 }
                          value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] }
                          valueLabelFormat={ `${commonFunctions.mosFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] )}` }
                          onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_months_to_maturity' :  `existig_home_mortgage_months_to_maturity_${home}`, 'slider' ) }
                        />
                        </div>
                      </div>
                    </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightMonts}` }>
                <div>
                    <div className={ `${classes.inputRightBlock} ${classes.annualInputMonts}` }>
                      <div className={ classes.annualInput }>
                        <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_months_to_maturity' : `existig_home_mortgage_months_to_maturity_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } /> months
                      </div>
                    </div>
                  </div>
                  <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                      <div className={ `${classes.inputRightBlock} ${classes.inputRightBlockYears}` }>
                        <NumberFormat displayType="text" thousandSeparator value={ home === 1 ? parseInt(handleAssestObject.existig_home_mortgage_months_to_maturity/ 12) :  parseInt(handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] / 12) }/> years
                    </div>
                  </div>
              </div>
            </div>
           
          </div>
          <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne} ${classes.garphLeftMonts}` }>
                <p>Monthly Payment (Principal & Interest)</p>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP} ${classes.garphRightMonts}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo} ${classes.vehicleOwnTotalTwoNew}` }>
                        {modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? ( 
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment } prefix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment < 0 && ')' } />
                      ) : ( '$0' )}
                    </div>
                  </div>
                </div>
                <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                  <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne} ${classes.garphLeftMonts}` }>
                <p>Total Repayment Amount (Principal & Interest)</p>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP} ${classes.garphRightMonts}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo} ${classes.vehicleOwnTotalTwoNew}` }>
                      {modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? ( 
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment } prefix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment < 0 && ')' } />
                    ) : ( '$0' )}
                    </div>
                  </div>
                </div>
                <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                  <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
        </div> */}

      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ `${classes.sliderBottomSpace} ${classes.sliderBottomSpaceNewOne}` }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Current Mortgage Balance</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_current_mortgage_balance' :  `existig_home_current_mortgage_balance_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_current_mortgage_balance :  handleAssestObject[`existig_home_current_mortgage_balance_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_current_mortgage_balance' : `existig_home_current_mortgage_balance_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
            <div>
              <p onClick={ () => handleDefinationPopup( 'Interest Rate' ) }><span className={ classes.definationTittle }>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn2Intrest() }
                  min={ 0 }
                  max={ 15 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_interest_rate' :  `existig_home_mortgage_interest_rate_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_mortgage_interest_rate :  handleAssestObject[`existig_home_mortgage_interest_rate_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_interest_rate' : `existig_home_mortgage_interest_rate_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                %
              </div>
            </div>
            <div>
              <p onClick={ () => handleDefinationPopup( 'Term of the Loan: Car Loan' ) } ><span className={ classes.definationTittle }>Months Remaining on Loan</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.monthsToMaturity() }
                  min={ 0 }
                  max={ 360 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_mortgage_months_to_maturity' :  `existig_home_mortgage_months_to_maturity_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_mortgage_months_to_maturity :  handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_mortgage_months_to_maturity' : `existig_home_mortgage_months_to_maturity_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat displayType="text" thousandSeparator value={ home === 1 ? parseInt(handleAssestObject.existig_home_mortgage_months_to_maturity/ 12) :  parseInt(handleAssestObject[`existig_home_mortgage_months_to_maturity_${home}`] / 12) }/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.loanRow }>
        <div className={ classes.loanBalance }>
          <div className={ classes.loanText }>
            <p><span>&nbsp;</span></p>
          </div>
          <div className={ classes.principalIntrest }>
            <p><b>Monthly Payment (Principal & Interest)</b></p>
          </div>
          <div className={ classes.loanInput }>
            <p>
              <span className={ classes.loanNumber }>
                <b>
                  {modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? ( 
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment } prefix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageMonthlyPayment < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={ classes.loanRow }>
        <div className={ classes.loanBalance }>
          <div className={ classes.loanText }>
            <p><span>&nbsp;</span></p>
          </div>
          <div className={ classes.principalIntrest }>
            <p><b>Total Repayment Amount (Principal & Interest)</b></p>
          </div>
          <div className={ classes.loanInput }>
            <p>
              <span className={ classes.loanNumber }>
                <b>
                  {modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest } prefix={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageTotalPrincipalInterest < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

HomesLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( HomesLoan );
