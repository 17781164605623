export function PMT( ratePerPeriod, numberOfPayments, presentValue, futureValue, type ) {
  let finalpmt = 0;
  let finalpmt1 = 0;
  if ( ratePerPeriod !== 0.0 ) {
    // Interest rate exists
    const q = ( 1 + ratePerPeriod ) ** numberOfPayments;
    finalpmt = -( ratePerPeriod * ( futureValue + ( q * presentValue ) ) ) / ( ( -1 + q ) * ( 1 + ratePerPeriod * ( type ) ) );
    if ( Number.isNaN( finalpmt ) ) {
      finalpmt = 0;
    }
    return finalpmt;
  } if ( numberOfPayments !== 0.0 ) {
    // No interest rate, but number of payments exists
    finalpmt1 = -( futureValue + presentValue ) / numberOfPayments;
    if ( Number.isNaN( finalpmt1 ) ) {
      finalpmt1 = 0;
    }
    return finalpmt1;
  }
  return 0;
}

export function RATE( periods, payment, present, future = 0, type = 0, guess = 0.01 ) {
  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Set maximum number of iterations
  const iterMax = 10;

  // Implement Newton's method
  let y; let y0; let y1; let x0; let x1 = 0; let f = 0; let i = 0;
  let rate = guess;
  if ( Math.abs( rate ) < epsMax ) {
    y = present * ( 1 + periods * rate ) + payment * ( 1 + rate * type ) * periods + future;
  } else {
    f = Math.exp( periods * Math.log( 1 + rate ) );
    y = present * f + payment * ( 1 / rate + type ) * ( f - 1 ) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * ( 1 / rate + type ) * ( f - 1 ) + future;
  i = 0;
  x0 = 0;
  x1 = rate;
  while ( ( Math.abs( y0 - y1 ) > epsMax ) && ( i < iterMax ) ) {
    rate = ( y1 * x0 - y0 * x1 ) / ( y1 - y0 );
    x0 = x1;
    x1 = rate;
    if ( Math.abs( rate ) < epsMax ) {
      y = present * ( 1 + periods * rate ) + payment * ( 1 + rate * type ) * periods + future;
    } else {
      f = Math.exp( periods * Math.log( 1 + rate ) );
      y = present * f + payment * ( 1 / rate + type ) * ( f - 1 ) + future;
    }
    y0 = y1;
    y1 = y;
    i += 1;
  }
  return rate;
}

export function IRRRATE( paymentsPerYear, paymentAmount, presentValue, futureValue = 0, dueEndOrBeginning = 0, interest = 0.01 ) {
  const FINANCIAL_MAX_ITERATIONS = 128;// Bet accuracy with 128
  const FINANCIAL_PRECISION = 0.0000001;// 1.0e-8

  let y; let y0; let y1; let x0 = 0.0; let x1 = 0; let f = 0; let
    i = 0;
  let rate = interest;

  if ( Math.abs( rate ) < FINANCIAL_PRECISION ) {
    y = presentValue * ( 1 + paymentsPerYear * rate ) + paymentAmount * ( 1 + rate * dueEndOrBeginning ) * paymentsPerYear + futureValue;
  } else {
    f = Math.exp( paymentsPerYear * Math.log( 1 + rate ) );
    y = presentValue * f + paymentAmount * ( 1 / rate + dueEndOrBeginning ) * ( f - 1 ) + futureValue;
  }
  y0 = presentValue + paymentAmount * paymentsPerYear + futureValue;
  y1 = presentValue * f + paymentAmount * ( 1 / rate + dueEndOrBeginning ) * ( f - 1 ) + futureValue;

  x1 = rate;
  while ( ( Math.abs( y0 - y1 ) > FINANCIAL_PRECISION )
      && ( i < FINANCIAL_MAX_ITERATIONS ) ) {
    rate = ( y1 * x0 - y0 * x1 ) / ( y1 - y0 );
    x0 = x1;
    x1 = rate;

    if ( Math.abs( rate ) < FINANCIAL_PRECISION ) {
      y = presentValue * ( 1 + paymentsPerYear * rate ) + paymentAmount * ( 1 + rate * dueEndOrBeginning ) * paymentsPerYear + futureValue;
    } else {
      f = Math.exp( paymentsPerYear * Math.log( 1 + rate ) );
      y = presentValue * f + paymentAmount * ( 1 / rate + dueEndOrBeginning ) * ( f - 1 ) + futureValue;
    }

    y0 = y1;
    y1 = y;
    i += 1;
  }
  return rate;
}

export function FV( rate, nper, pmt, pv, type ) {
  const pow = ( 1 + rate ) ** ( nper );
  let fv;
  if ( rate ) {
    fv = ( ( pmt * ( 1 + rate * type ) * ( 1 - pow ) ) / rate ) - pv * pow;
  } else {
    fv = -1 * ( pv + pmt * nper );
  }
  return fv;
}

export function parseNumber( string ) {
  if ( string === undefined || string === '' ) {
    return 0;
  }
  if ( !Number.isNaN( string ) ) {
    return parseFloat( string );
  }
  return 0;
}

export function anyIsError() {
  const n = arguments.length;
  // eslint-disable-next-line
  const arg = arguments;

  for ( let i = 0; i < n; i += 1 ) {
    if ( arg[i] instanceof Error ) {
      return true;
    }
  }
  return false;

  // while ( n-- ) {
  //   if ( arguments[n] instanceof Error ) {
  //     return true;
  //   }
  // }
  // return false;
}

export function CUMIPMT( rate, periods, value, start, end, type ) {
  const rate1 = parseNumber( rate );
  const periods1 = parseNumber( periods );
  const value1 = parseNumber( value );
  let start1 = start;
  if ( anyIsError( rate1, periods1, value1 ) ) {
    return 0;
  }

  // Return error if either rate, periods, or value are lower than or equal to zero
  if ( rate1 <= 0 || periods1 <= 0 || value1 <= 0 ) {
    return 0;
  }

  // Return error if start < 1, end < 1, or start > end
  if ( start1 < 1 || end < 1 || start1 > end ) {
    return 0;
  }

  // Return error if type is neither 0 nor 1
  if ( type !== 0 && type !== 1 ) {
    return 0;
  }
  // Compute cumulative interest
  const payment = PMT( rate1, periods1, value1, 0, type );
  let interest = 0;

  if ( start1 === 1 ) {
    if ( type === 0 ) {
      interest = -value1;
      start1 += 1;
    }
  }

  for ( let i = start1; i <= end; i += 1 ) {
    if ( type === 1 ) {
      interest += FV( rate1, i - 2, payment, value1, 1 ) - payment;
    } else {
      interest += FV( rate1, i - 1, payment, value1, 0 );
    }
  }
  interest *= rate1;

  // Return cumulative interest
  return interest;
}

function convNumber( expr, decplaces ) {
  let str = `${Math.round( expr * ( 10 ** decplaces ) )}`;

  while ( str.length <= decplaces ) {
    str = `0${str}`;
  }
  const decpoint = str.length - decplaces;

  return ( `${str.substring( 0, decpoint )}.${str.substring( decpoint, str.length )}` );
}

export function PV( rate, nper, pmt, fv ) {
  const rate1 = parseFloat( rate );
  const nper1 = parseFloat( nper );
  const pmt1 = parseFloat( pmt );
  const fv1 = parseFloat( fv );
  if ( nper1 === 0 ) {
    return ( 0 );
  }
  let pvValue;
  if ( rate1 === 0 ) { // Interest rate is 0
    pvValue = -( fv1 + ( pmt1 * nper1 ) );
  } else {
    const x = ( 1 + rate1 ) ** ( -nper1 );
    const y = ( 1 + rate1 ) ** nper1;
    pvValue = -( x * ( fv1 * rate1 - pmt1 + y * pmt1 ) ) / rate1;
  }
  pvValue = convNumber( pvValue, 2 );
  return ( pvValue );
}

export function nper( ir, pmt, pv, fv ) {
  let nbperiods = 0;

  if ( ir !== 0 ) ir /= 100;

  nbperiods = Math.log( ( -fv * ir + pmt ) / ( pmt + ir * pv ) ) / Math.log( 1 + ir );
  return nbperiods;
}
