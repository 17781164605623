import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ( theme ) => ( {
  senseRatingCommentsUl: {
    width: '80%',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0px',
    padding: '0',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      padding: '0px',
    },
    '& li': {
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
      '&:nth-child(1)': {
        '& p': {
          color: theme.palette.common.blue,
          [theme.breakpoints.down( 'xs' )]: {
            display: 'block',
          },
        },
        '& span': {
          border: '2px solid #0069aa',
        },
      },
      '&:nth-child(2)': {
        '& span': {
          border: '2px solid #0069aa',
          width: '30px',
          height: '30px',
        },
      },
      '&:nth-child(3)': {
        '& span': {
          border: '2px solid #999999',
          width: '25px',
          height: '25px',
        },
      },
      '&:nth-child(4)': {
        '& span': {
          border: '2px solid rgba(153, 153, 153, 0.5)',
          width: '30px',
          height: '30px',
        },
      },
      '&:nth-child(5)': {
        '& span': {
          border: '2px solid rgba(153, 153, 153, 0.3)',
          [theme.breakpoints.down( 'xs' )]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
      },
    },
  },
  ratingList: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 6px',
    width: 'auto',
    fontSize: '19px',
    cursor: 'pointer',
    '&:focus': {
      outline: '0',
    },
    '& p': {
      display: 'inline-block',
      textTransform: 'uppercase',
      verticalAlign: 'middle',
      margin: '0 15px 12px 15px',
      fontFamily: '"MuseoSans-500"',
      fontSize: '12px',
      lineHeight: '14px',
      '& i': {
        display: 'block',
        fontStyle: 'normal',
      },
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '36px',
      height: '36px',
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '18px',
      padding: '0px 0',
      margin: '0 0 8px 0',
      color: theme.palette.common.blue,
      border: '2px solid #0069aa',
      borderRadius: '100%',
      animation: 'pulse-animation 2s infinite',
      '&:focus': {
        outline: '0',
      },
      outline: 'none',
    },
  },
  ActiveList: {
    '& span': {
      background: theme.palette.common.blue,
    },
  },
  ActiveListTwo: {
    '& span': {
      background: '#999999',
    },
  },
  ActiveListThree: {
    '& span': {
      background: '#999999',
      opacity: '0.5',
    },
  },
  ActiveListFour: {
    '& span': {
      background: '#999999',
      opacity: '0.3',
    },
  },
  colorChange: {
    color: 'rgba(153, 153, 153, 1)',
  },
  noAnimation: {
    '& span': {
      animation: 'none',
    },
  },
} );

const Rating = ( props ) => {
  const { classes } = props;

  const handleRating = ( index, rating ) => {
    props.handleRating( index, rating );
  };

  return (
    <ul className={ classes.senseRatingCommentsUl }>
      {[5, 4, 3, 2, 1].map( ( rate ) => (
        <li key={ rate } className={ classNames( classes.ratingList, props.value !== 0 && classes.noAnimation, props.value === rate && ( rate === 5 || rate === 4 ) && classes.ActiveList, props.value === rate && rate === 3 && classes.ActiveListTwo, props.value === rate && rate === 2 && classes.ActiveListThree, props.value === rate && rate === 1 && classes.ActiveListFour ) }>
          {rate === 5 && (
          <p>
            Very
            { ' ' }
            <i>Important</i>
          </p>
          )}
          <span role="button" tabIndex={ rate } aria-hidden="true" onClick={ ( ) => handleRating( props.questionIndex, rate ) } />
          {rate === 1 && (
          <p className={ classes.colorChange }>
            Not
            { ' ' }
            <i>Important</i>
          </p>
          )}
        </li>
      ) )}
    </ul>
  );
};

Rating.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRating: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default withStyles( styles )( Rating );
