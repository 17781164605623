 import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const OtherInvestment = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject } = props;
  const [OtherInvestments, setOtherInvestments] = React.useState({});

  const handleChanges = (e, name) => {
    const inputValues = {...handleAssestObject};
    const v = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[name] = v;
    handleUpdatedObject( inputValues );
    setOtherInvestments({...OtherInvestments, [name]: v})
  }
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        A Health Savings Account, or HSA, is a tax-advantaged savings account that lets you set aside pre-tax money for future medical expenses if you have a high deductible health plan.
      </p>
      <p className={ classes.existingSpace }>
        A 529 College Savings Plan, or 529 plan, is a tax-advantaged investment account that lets you invest pre-tax money for future education expenses.
      </p>
      <p className={ classes.existingSpace }>
        Enter the value for each of your accounts below.
      </p>      
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Other Investments</th>
          </thead>
          <tbody>
            <tr>
              <td>Health Savings Account (HSA)</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } fixedDecimalScale decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_total_value_of_hsa } name="existing_total_value_of_hsa" onValueChange={ (e)=> handleChanges(e, 'existing_total_value_of_hsa') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>529 Plan</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_529_plan } name="existing_529_plan" onValueChange={ (e)=> handleChanges(e, 'existing_529_plan') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Other Investments Balance</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {modulesServices.module18Data.existingTotalOtherInvestments !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingTotalOtherInvestments } prefix={ modulesServices.module18Data.existingTotalOtherInvestments >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingTotalOtherInvestments < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

OtherInvestment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( OtherInvestment );
