import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import ReactTooltip from 'react-tooltip';
import UpdateBillingInfo from '../my-account/UpdateBillingInfo';
import infoIcon from '../../assets/img/info-icon.svg';
import * as commonFunctions from '../../utilities/commonFunctions';
import { Button, DialogActions } from '@material-ui/core';
import Pageloader from '../../components/ui/pageloader';
import RadioGroup from '@material-ui/core/RadioGroup';

const BILLINGINFO = loader('../../graphql/schema/my-account/billing-info.graphql');
const UPGRADE_DOWNGRADE_PLAN = loader('../../graphql/schema/my-account/upgrade-downgrade.graphql');
const CANCEL_SUBSCRIPTION = loader('../../graphql/schema/my-account/cancel-subscription.graphql');
const RENEWAL_SUBSCRIPTION = loader('../../graphql/schema/my-account/renew-subscription.graphql');
const REACTIVATE_SUBSCRIPTION = loader('../../graphql/schema/my-account/reactivate.graphql');
const REACTIVE_SUBSCRIPTION = loader('../../graphql/schema/my-account/reactive.graphql');

const BillingInformation = (props) => {

  const { classes } = props;

  const [billinginfo] = useLazyQuery(BILLINGINFO, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setBillingAccountData(response && response.getBillingDetails.account_details)
      setCardData(response && response.getBillingDetails.card_details);
      setBillingHistoryData(response && response.getBillingDetails.billing_his);
      setLoading(false);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setHaserror(errorMsg);
      setLoading(false)
    }
  });

  const [upgradePlan] = useMutation(UPGRADE_DOWNGRADE_PLAN, {
    onCompleted() {
      setLoading(false);
      setUpgradeDetails(false);
      setUpgradeOpen(true);
      setUpgradePrice('60');
      billinginfo();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted(response) {
      setLoading(false);
      setcancelSubPopUp(true);
      setCancelSubStatus(response.cancelSubscription.status);
      billinginfo();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  const [renewalSubscription] = useLazyQuery(RENEWAL_SUBSCRIPTION, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setRenewelSubStatus(response.renewalSubscription.status);
      setRenewDetails(false);
      setLoading(false);
      setRenewelSubPopUp(true);
      billinginfo();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  const [reactivate] = useMutation(REACTIVATE_SUBSCRIPTION, {
    onCompleted(response) {
      setLoading(false);
      setReactiveteStatus(response.reactivateSubscription.status);
      setReactiveAccount(false);
      setReactivateSubPopUp(true);
      billinginfo();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  const [reactive] = useMutation(REACTIVE_SUBSCRIPTION, {
    onCompleted(response) {
      setReactiveStatus(response.activateCancelledSubscription.status);
      setReactiveSubPopUp(true);
      setLoading(false);
      billinginfo();
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setHaserror(formatedErrors);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    billinginfo();
  }, []);

  const [Upgradeplan, setUpgradePlan] = useState('year');
  const [editDetails, setEditDetails] = useState(false);
  const [upgradeDetails, setUpgradeDetails] = useState(false);
  const [renewDetails, setRenewDetails] = useState(false);
  const [billingAccountData, setBillingAccountData] = useState('');
  const [haserror, setHaserror] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [upgradePrice, setUpgradePrice] = useState('60');
  const [Upgradeopen, setUpgradeOpen] = useState(false);
  const [billingHistoryData, setBillingHistoryData] = useState('');
  const [cardData, setCardData] = useState('');
  const [cancelSubStatus, setCancelSubStatus] = useState('');
  const [cancelSubPopUp, setcancelSubPopUp] = useState(false);
  const [reactiveAccount, setReactiveAccount] = useState(false);
  const [reactivePrice, setReactivePrice] = useState('60');
  const [renewelSubStatus, setRenewelSubStatus] = useState('');
  const [renewelSubPopUp, setRenewelSubPopUp] = useState(false);
  const [reactivatePlan, setReactivatePlan] = useState('year');
  const [reactivateStatus, setReactiveteStatus] = useState('');
  const [reactivateSubPopUp, setReactivateSubPopUp] = useState(false);
  const [reactiveStatus, setReactiveStatus] = useState('');
  const [reactiveSubPopUp, setReactiveSubPopUp] = useState(false);

  const handleUpgradePrice = (e) => {
    setUpgradePrice(e.target.value);
    if (e.target.value === '42') {
      setUpgradePlan('semi_year');
    }
    if (e.target.value === '60') {
      setUpgradePlan('year');
    }
  }

  const handleCancel = () => {
    setUpgradeDetails(false);
    setUpgradePrice('60');
    setReactivePrice('60');
    setReactiveAccount(false);
  }

  const handleUpgrade = () => {
    setLoading(true);
    upgradePlan({
      variables: {
        plan: Upgradeplan
      }
    });
  }

  const handleCancelSubscription = () => {
    setLoading(true);
    cancelSubscription({
      variables: {
        plan_id: billingAccountData && billingAccountData.plan_id
      }
    });
  }

  const handleJoin = () => {
    setReactiveAccount(true);
  }

  const handleReactivePlan = () => {
    setLoading(true);
    reactivate({
      variables: {
        plan_id: reactivatePlan
      }
    })
  }

  const handleReactive = () => {
    setLoading(true);
    reactive({
      variables: {
        plan_id: billingAccountData && billingAccountData.plan_id
      },
    });
  }

  const handleReactivePrice = (e) => {
    setReactivePrice(e.target.value);
    if (e.target.value === '9') {
      setReactivatePlan('monthly')
    }
    if (e.target.value === '42') {
      setReactivatePlan('semi_year');
    }
    if (e.target.value === '60') {
      setReactivatePlan('year');
    }
  }

  const handleRenew = () => {
    setLoading(true);
    renewalSubscription();
  }

  return (
    <div className={classes.billingInformation}>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.billingHead}>
        <h3>Billing Information</h3>
      </div>
      {haserror && <p>{haserror}</p>}
      <div className={classes.billingContent}>
        <div className={classes.accountID}>
          <div className={classes.accountIDBox}>
            <h5>Account ID</h5>
            <span>{billingAccountData && billingAccountData.customer_id ? billingAccountData.customer_id : 'N/A'}</span>
            {/* <img data-tip="Account Id" data-for="customerId" src={infoIcon} alt="" /> */}
            <ReactTooltip id="customerId" place="top" type="info" effect="solid" />
          </div>
          <div className={classes.accountIDBox}>
            <h5>Membership Details <img data-tip="Your current" data-for="customerId" src={infoIcon} alt="" /></h5>            
            {billingAccountData && billingAccountData.plan_id === 'monthly' ?
              <div>
                <p>Plan With Inkwiry for 30 days</p>
                <p>$9 one-time payment</p>
              </div>
              :
              ''
            }
            {billingAccountData && billingAccountData.plan_id === 'price_1HPhnvIMI1Fep3cVGeFwRcZZ' ?
              <div>
                <p>GOLD member</p>
                {billingAccountData && billingAccountData.subscription_status === 'A' ?
                  <p>$42 per six months</p>
                  :
                  ''
                }
              </div>
              :
              ''
            }
            {billingAccountData && billingAccountData.plan_id === 'price_1HPhwPIMI1Fep3cVdwQNR1JM' ?
              <div>
                <p>PLATINUM member</p>
                {billingAccountData && billingAccountData.subscription_status === 'A' ?
                  <p>$60 per year</p>
                  :
                  ''
                }
              </div>
              :
              ''
            }
          </div>
          <div className={classes.accountIDBox}>
            {billingAccountData.plan_id === billingAccountData.current_plan && billingAccountData.subscription_status !== 'D' ?
              <div className={classes.accountID}>
                <h5>Manage Account</h5>
                {billingAccountData && billingAccountData.plan_id === 'monthly' ?
                  <div>
                    {billingAccountData && billingAccountData.plan_id !== 'price_1HPhwPIMI1Fep3cVdwQNR1JM' ?
                      <div>
                        <p>Save $4/month by upgrading to <br />PLATINUM</p>
                      </div>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.current_plan === billingAccountData.plan_id ?
                      <div className={classes.buttonsBlock}>
                        <button type="button" className={classNames(classes.editButton, classes.upgradeBtn)} onClick={() => { setUpgradeDetails(true) }}>Upgrade</button>
                        <button type="button" className={classes.editButton} onClick={() => { setRenewDetails(true) }}>Renew</button>
                        {billingAccountData && billingAccountData.current_plan === '' && billingAccountData.current_plan === null ?
                          <button type="button" className={classes.editButton} onClick={handleJoin}>REJOIN</button>
                          :
                          ''
                        }
                      </div>
                      :
                      ''
                    }
                  </div>
                  :
                  ''
                }
                {billingAccountData && billingAccountData.plan_id === 'price_1HPhnvIMI1Fep3cVGeFwRcZZ' ?
                  <div>
                    {billingAccountData && billingAccountData.plan_id !== 'price_1HPhwPIMI1Fep3cVdwQNR1JM' && billingAccountData.subscription_status === 'A' ?
                      <div>
                        <p>Save $2/month by upgrading to<br />PLATINUM</p>
                      </div>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.current_plan === billingAccountData.plan_id && billingAccountData.subscription_status === 'A' ?
                      <div className={classes.buttonsBlock}>
                        <button type="button" className={classNames(classes.editButton, classes.upgradeBtn)} onClick={() => { setUpgradeDetails(true) }}>Upgrade</button>
                        <button type="button" className={classes.editButton} onClick={() => { setRenewDetails(true) }}>Renew</button>
                        <button type="button" className={classNames(classes.editButton, classes.upgradeBtn)} onClick={handleCancelSubscription}>Cancel</button>

                      </div>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.current_plan === '' && billingAccountData.current_plan === null ?
                      <button type="button" className={classes.editButton} onClick={handleJoin}>REJOIN</button>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.subscription_status === 'C' ?
                      <button type="button" className={classes.editButton} onClick={handleReactive}>REACTIVATE</button>
                      :
                      ''
                    }
                  </div>
                  :
                  ''
                }
                {billingAccountData && billingAccountData.plan_id === 'price_1HPhwPIMI1Fep3cVdwQNR1JM' ?
                  <div>
                    {billingAccountData && billingAccountData.subscription_status === 'A' ?
                      <div className={classes.buttonsBlock}>
                        <button type="button" className={classNames(classes.editButton, classes.upgradeBtn)} onClick={handleCancelSubscription}>Cancel</button>
                      </div>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.current_plan === '' && billingAccountData.current_plan === null ?
                      <button type="button" className={classes.editButton} onClick={handleJoin}>REJOIN</button>
                      :
                      ''
                    }
                    {billingAccountData && billingAccountData.subscription_status === 'C' ?
                      <button type="button" className={classes.editButton} onClick={handleReactive}>REACTIVATE</button>
                      :
                      ''
                    }
                  </div>
                  :
                  ''
                }
              </div>
              :
              ''
            }
          </div>
        </div>
        <div className={classes.accountID}>
          <div className={classes.accountIDBox}>
            <h5>Current Billing Period</h5>
            <p>{billingAccountData && billingAccountData.start_date ? billingAccountData.start_date : 'N/A'} - {billingAccountData && billingAccountData.expire_date ? billingAccountData.expire_date : 'N/A'}</p>
          </div>
          <div className={classes.accountIDBox}>
            <h5>Next Payment Date</h5>
            <p>{billingAccountData && billingAccountData.next_payment ? billingAccountData.next_payment : 'N/A'}</p>
          </div>
          <div className={classes.accountIDBox}>
            <h5>Payment Reminder <img data-tip="We'll email you a payment reminder based on your selection below." data-for="customerId" src={infoIcon} alt="" /></h5>
            <p className={classes.paymentSelect}>
              <select>
                <option>None</option>
                <option value='3'>{billingAccountData && billingAccountData.reminder}</option>
                {/* <option value='7'>{billingAccountData && billingAccountData.reminder2}</option> */}
                <option value='1week'>1 week</option>
                {/* <option value='14'>{billingAccountData && billingAccountData.reminder3}</option> */}
              </select>
            </p>
          </div>
        </div>
        <div className={classes.paymentDetails}>
          <h5>Payment Details</h5>
          {cardData === '' ?
            <p>No Payment details found</p>
            :
            <div className={classes.paymentDetailspara}>
              <p>{cardData && cardData.brand ? cardData.brand : 'N/A'} ending in {cardData && cardData.last4 ? cardData.last4 : 'N/A'} (expires {cardData && cardData.exp_month ? cardData.exp_month : 'N/A'}/{cardData && cardData.exp_year ? cardData.exp_year : 'N/A'})</p>
              <p>{cardData && cardData.holder_name ? cardData.holder_name : 'N/A'} </p>
              <p>{cardData && cardData.address ? cardData.address : 'N/A'}</p>
              <p>{cardData && cardData.address2 ? cardData.address2 : 'N/A'}</p>
              <p>{cardData && cardData.city ? cardData.city : 'N/A'},{cardData && cardData.state_name ? cardData.state_name : 'N/A'},{cardData && cardData.country ? cardData.country : 'N/A'},{cardData && cardData.zip_code ? cardData.zip_code : 'N/A'}</p>
            </div>
          }
          <button type="button" className={classNames(classes.editButton, classes.editSmallBtn)} onClick={() => { setEditDetails(true) }}>Edit</button>
        </div>
      </div>
      <Dialog
        open={cancelSubPopUp}
        onClose={() => { setcancelSubPopUp(false) }}
      >
        <DialogContent>
          {cancelSubStatus && cancelSubStatus === true ?
            <div>
              <h4>Account Deactivation</h4>
              <p> Your account has been deactivated successfully. Remember, you will have access to Inkwiry until the current billing period ends. And, if you want to reactivate your account at any time, just login, go to the billing page, and click reactivate.</p>
            </div>
            :
            ''
          }
          {cancelSubStatus && cancelSubStatus === false ?
            <div>
              <h4>Account Deactivation</h4>
              <p>You already cancelled subscription.</p>
            </div>
            :
            ''
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setcancelSubPopUp(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <div className={`${classes.billingHistoryNew} ${classes.billingHistoryMargin}`}>
        <div className={classes.billingHead}>
          <h4>Billing History</h4>
          <div className={classes.billingTable}>
            <table>
              <thead>
                <th>Date</th>
                <th>Action</th>
                <th>Transaction #</th>
                <th>Amount</th>
                <th>Link to View</th>
              </thead>
              {billingHistoryData.length > 0 && billingHistoryData.map((value, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td className={classes.notFound}>{value.created}</td>
                      <td className={classes.notFound}>Payment</td>
                      <td className={classes.notFound}>{value.invoice_id !== null ? value.invoice_id : 'N/A'}</td>
                      <td className={classes.notFound}>${value.amount}</td>
                      <td className={classes.notFound}><a href={value.invoice_pdf} target="_blank" rel="noreferrer">View</a></td>
                    </tr>
                  </tbody>
                )
              })}
            </table>
          </div>
        </div>
      </div>
      <Dialog
        open={editDetails}
        onClose={() => { setEditDetails(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.editDetailsPopup}
      >
        <DialogContent className={classes.editModal}>
          <div className={classes.modalHeader}>
            <button type="button" onClick={() => { setEditDetails(false) }} className={classes.closeButton} >×</button>
            <h4>Edit Payment Details and Billing Address</h4>
          </div>
          <div className={classes.modalBody}>
            <UpdateBillingInfo billinginfo={billinginfo} setEditDetails={setEditDetails} setOpen={setOpen} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <DialogContent>
          <div>
            <p>Billing Information Updated Successfully</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Upgradeopen}
        onClose={() => { setUpgradeOpen(false) }}
      >
        <DialogContent>
          <div>
            <p>Your Plan Upgraded Successfully</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setUpgradeOpen(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={renewDetails}
        onClose={() => { setRenewDetails(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.renewPopup}
      >
        <DialogContent >
          <div className={classes.modalHeader}>
            <h4>Renew Your Account</h4>
          </div>
          <div className={classes.modalBodyNew}>
            {billingAccountData && billingAccountData.plan_id === 'monthly' ?
              <p>By clicking confirm below, you will be charged $9 (plus applicable sales tax) for another 30 days of access to all that Inkwiry has to offer.</p>
              :
              ''
            }
            {billingAccountData && billingAccountData.plan_id === 'price_1HPhnvIMI1Fep3cVGeFwRcZZ' ?
              <p>By clicking confirm below, you will be charged $42 for 6 months, $7/month for another 6 months of access to all that Inkwiry has to offer.</p>
              :
              ''
            }
            {billingAccountData && billingAccountData.plan_id === 'price_1HPhwPIMI1Fep3cVdwQNR1JM' ?
              <p>By clicking confirm below, you will be charged $60 for 1 year, $5/month for another 1 year of  unlimited access to all that Inkwiry has to offer.</p>
              :
              ''
            }
          </div>
          <div className={classes.modalFooter}>
            <button type="button" className={classNames(classes.confirmButton, classes.footerButton)} onClick={handleRenew}>Confirm</button>
            <button type="button" onClick={() => { setRenewDetails(false) }} className={classNames(classes.cancelButton, classes.footerButton)} >Cancel</button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={renewelSubPopUp}
        onClose={() => { setRenewelSubPopUp(false) }}
      >
        <DialogContent>
          {renewelSubStatus && renewelSubStatus === true ?
            <div>
              <h4>Account Renew</h4>
              <p> Your account has been Renew successfully. Remember, you will get the access to Inkwiry after the current billing period ends. </p>
            </div>
            :
            ''
          }
          {renewelSubStatus && renewelSubStatus === false ?
            <div>
              <h4>Account Renew</h4>
              <p>You already Renew Failed</p>
            </div>
            :
            ''
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setRenewelSubPopUp(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={upgradeDetails}
        onClose={() => { setUpgradeDetails(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.upgradePopup}
      >
        <DialogContent >
          <div className={classes.modalHeader}>
            <h4>Upgrade Membership & Save</h4>
          </div>
          <div className={classes.modalNew}>
            <p className={classes.btmMargin}>Are you sure you want to upgrade your membership to PLATINUM? You will get unrestricted access to Inkwiry and all new releases for $60 per year ($5 per month).</p>


            <div className={classes.detailsSlide}>
              <RadioGroup name="gender1" onChange={handleUpgradePrice} value={upgradePrice}>
                {billingAccountData && billingAccountData.plan_id === 'monthly' ?
                  <div className={classNames(classes.leftBlock, classes.leftData)}>
                    <div className={classes.controlGroup}>
                      <FormControlLabel className={classes.cotrolLabel} value="42" name="upgrade" control={<Radio />} />

                      <span className={classes.spanText}>
                        GOLD Member
                        <small>($42 for 6 months, $7/mo)</small>
                      </span>

                    </div>
                  </div>
                  :
                  ''
                }
                <div className={classes.flexDiv}>
                  <div className={classNames(classes.leftBlock, classes.leftData)}>
                    <div className={classes.controlGroup}>
                      <FormControlLabel className={classes.cotrolLabel} value="60" name="upgrade" control={<Radio />} />

                      <span className={classes.spanText}>
                        PLATINUM Member
                        <small>($60 for 1 year, $5/mo)</small>
                      </span>

                    </div>
                  </div>
                  <div className={classes.rightBlock}>
                    <p>${upgradePrice}.00</p>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className={classes.subDetails}>
              <div className={classes.leftBlock}>
                <p className={classes.boldFont}>Total Bill</p>
              </div>
              <div className={classes.rightBlock}>
                <p className={classes.boldFont}>${upgradePrice}.00</p>
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <button type="button" className={classNames(classes.confirmButton, classes.footerButton)} onClick={handleUpgrade}>Upgrade</button>
            <button type="button" onClick={handleCancel} className={classNames(classes.cancelButton, classes.footerButton)} >Cancel</button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={reactiveAccount}
        onClose={() => { setReactiveAccount(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.upgradePopup}
      >
        <DialogContent >
          <div className={classes.modalHeader}>
            <h4>Reactive Membership & Save</h4>
          </div>
          <div className={classes.modalNew}>
            <p className={classes.btmMargin}>Are you sure you want to reactive your membership? Please select below plans to active your account. Then you will get access to Inkwiry. </p>

            <div className={classes.detailsSlide}>
              <RadioGroup name="gender1" onChange={handleReactivePrice} value={reactivePrice}>
                <div className={classNames(classes.leftBlock, classes.leftData)}>
                  <div className={classes.controlGroup}>
                    <FormControlLabel className={classes.cotrolLabel} value="9" name="upgrade" control={<Radio />} />

                    <span className={classes.spanText}>
                      Monthly Member
                      <small>($9 for 1 month)</small>
                    </span>

                  </div>
                </div>
                <div className={classNames(classes.leftBlock, classes.leftData)}>
                  <div className={classes.controlGroup}>
                    <FormControlLabel className={classes.cotrolLabel} value="42" name="upgrade" control={<Radio />} />

                    <span className={classes.spanText}>
                      GOLD Member
                      <small>($42 for 6 months, $7/mo)</small>
                    </span>

                  </div>
                </div>
                <div className={classes.rightBlock}>

                </div>
                <div className={classes.flexDiv}>
                  <div className={classNames(classes.leftBlock, classes.leftData)}>
                    <div className={classes.controlGroup}>
                      <FormControlLabel className={classes.cotrolLabel} value="60" name="upgrade" control={<Radio />} />

                      <span className={classes.spanText}>
                        PLATINUM Member
                        <small>($60 for 1 year, $5/mo)</small>
                      </span>

                    </div>
                  </div>
                  <div className={classes.rightBlock}>
                    <p>${upgradePrice}.00</p>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className={classes.subDetails}>
              <div className={classes.leftBlock}>
                <p className={classes.boldFont}>Total Bill</p>
              </div>
              <div className={classes.rightBlock}>
                <p className={classes.boldFont}>${reactivePrice}.00</p>
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <button type="button" className={classNames(classes.confirmButton, classes.footerButton)} onClick={handleReactivePlan}>Upgrade</button>
            <button type="button" onClick={handleCancel} className={classNames(classes.cancelButton, classes.footerButton)} >Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={reactivateSubPopUp}
        onClose={() => { setReactivateSubPopUp(false) }}
      >
        <DialogContent>
          {reactivateStatus && reactivateStatus === true ?
            <div>
              <h4>Account ReJoin</h4>
              <p> Your account has been ReJoin successfully.</p>
            </div>
            :
            ''
          }
          {reactivateStatus && reactivateStatus === false ?
            <div>
              <h4>Account ReJoin</h4>
              <p>Your subscription ReJoin Faild.</p>
            </div>
            :
            ''
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setReactivateSubPopUp(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reactiveSubPopUp}
        onClose={() => { setReactiveSubPopUp(false) }}
      >
        <DialogContent>
          {reactiveStatus && reactiveStatus === true ?
            <div>
              <h4>Account Reactivate</h4>
              <p> Your account has been reactivated successfully.</p>
            </div>
            :
            ''
          }
          {reactiveStatus && reactiveStatus === false ?
            <div>
              <h4>Account Reactivate</h4>
              <p>Your subscription Reactivation Faild.</p>
            </div>
            :
            ''
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setReactiveSubPopUp(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BillingInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BillingInformation);
