import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Button,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import * as commonFunctions from '../../../utilities/commonFunctions';
import styles from '../../../screens/pwi-fca/styles';
import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import SideGraphDetails from './career-path-side-graph';

const arrayValues = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 400,
    scaledValue: 400,
    label: '$400',
  },
  {
    value: 800,
    scaledValue: 800,
    label: '$800',
  },
  {
    value: 1200,
    scaledValue: 1200,
    label: '$1.2k',
  },
  {
    value: 1600,
    scaledValue: 1600,
    label: '$1.6k',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '$2k',
  },
];

const Entertainment = ( props ) => {
  const {
    classes, handleCareerObject, handleUpdatedObject, sideGraphData,
  } = props;
  const [entertainment, setEntertainment] = React.useState( 0 );

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.entertainment !== undefined ) {
      setEntertainment( handleCareerObject.entertainment );
    } else {
      updatedValues['entertainment'] = 0;
    }
    handleUpdatedObject(updatedValues);
  // eslint-disable-next-line
  },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setEntertainment( newvalue );
    updatedValues[field] =newvalue;
    handleUpdatedObject( updatedValues );
    $( '#percentage1' ).css( 'height', `${sideGraphData.percentageValue}%` );
    $( '#percentage2' ).css( 'height', `${sideGraphData.percentageValue1}%` );
  };

  const handleBenchMarks = () => {
    const incomeSheetData = moduleServices.incomeStatementCompleteData();
    let incomeAfterTaxesValue = 0;
    if (incomeSheetData !== undefined && incomeSheetData !== '' && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== '' && incomeSheetData[handleCareerObject.start_year]['Income After Taxes'] !== undefined) {
      incomeAfterTaxesValue = incomeSheetData[handleCareerObject.start_year]['Income After Taxes'];
    } 
    setEntertainment( ((incomeAfterTaxesValue/12)/100)*3.5 );
    handleCareerObject.entertainment = ((incomeAfterTaxesValue/12)/100)*3.5;
  };

  return (
    <div className={ classes.contentMainBlock }>
      <p>
        How much do you spend on entertainment each month? That could be anything from going to the movies or buying books, golfing or painting, going out with friends or attending concerts, or just about anything you do for fun that costs money.
      </p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          <font>FINANCE EXPLAINED TIP:</font>
          {' '}
          As a benchmark, entertainment should be 3.5% or less of your income after taxes. If you're not sure what your expenses might be for this career move, click on 'Use Benchmark Value {'>'}' to automate your budget.
        </p>
        <Button onClick={ handleBenchMarks } className={ classes.BahanceBtn }>USE BENCHMARK VALUES {'>'} </Button>
      </div>
      <div className={ `${classes.livingExpences} ${classes.livingExpencesFlexEnd}` }>
        <div className={ classes.mainBlockRow }>
          <div className={ classes.mainBlock }>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                <h3 className={ classes.opacityZero }>Monthly</h3>
                <span>Entertainment</span>
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ arrayValues }
                    min={ 0 }
                    max={ 2000 }
                    step={ 10 }
                    value={ entertainment }
                    valueLabelFormat={ `${commonFunctions.numFormatter( entertainment )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'entertainment', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Monthly</span></h3>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    value={ entertainment }
                    decimalScale={0}
                    onValueChange={ ( e ) => updateValue( e, '', 'entertainment', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Annual</span></h3>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ entertainment * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockItalic}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>% of Income after Taxes</p>
              </div>
              <div className={ classes.livingExpencesMothly }>

                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( ( entertainment ) * 12 ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( entertainment ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>Total Living Expenses</p>
              </div>
              <div className={ classes.livingExpencesMothly }>

                <div className={ classes.annualInput }>
                <span>
                    <b>
                    {moduleServices.module17Data.livingExpensesFurCareerAdv !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module17Data.livingExpensesFurCareerAdv < 0 ? classes.textDanger : '' } allowNegative={ false } thousandSeparator value={ moduleServices.module17Data.livingExpensesFurCareerAdv } prefix={ moduleServices.module17Data.livingExpensesFurCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module17Data.livingExpensesFurCareerAdv < 0 && ')' } />
                    ) : ( '$0' )}
                  
                  </b>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                <span>
                    <b>
                    {moduleServices.module17Data.furCareerAdvLivingExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module17Data.furCareerAdvLivingExpenses < 0 ? classes.textDanger : '' }  allowNegative={ false } thousandSeparator value={ moduleServices.module17Data.furCareerAdvLivingExpenses} prefix={ moduleServices.module17Data.furCareerAdvLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module17Data.furCareerAdvLivingExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                    </b>
                  </span>
                </div>
              </div>
            </div>

          </div>
          <SideGraphDetails sideGraphData={ sideGraphData } />
        </div>
      </div>
      <div className={ classes.borderDevider } />
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />

    </div>
  );
};

Entertainment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  sideGraphData: PropTypes.object.isRequired,

};

export default withStyles( styles )( Entertainment );
