import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
import logo1 from '../../assets/img/logo-footer.png';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import styles from './styles';
import Slide1 from '../../assets/img/erica-story/erica_story_img_1.jpg';
import mikeStory3 from '../../assets/img/erica-story/erica_story_img_3.jpg';
import mikeStory3Icon from '../../assets/img/erica-story/financial_indep.png';
import higherEducationIcon from '../../assets/img/mike-story/calvin_m_green_icon.png';
import SlideErica from '../../assets/img/erica-story/erica_story_img_2.png';
import MoreHigherEducationIcon from '../../assets/img/erica-story/more_high_edu.png';
import MikeGreen from '../../assets/img/mike-story/calvin_m_yellow_icon.png';
import MikeBlue from '../../assets/img/mike-story/calvin_m_blue_icon.png';
import MikeBlue2 from '../../assets/img/mike-story/calvin_m_light_blue_icon.png';
import MikeSlide5 from '../../assets/img/erica-story/erica_story_img_5.jpg';
import mikeStory4 from '../../assets/img/erica-story/erica_story_img_4.jpg';
import MikeSlide6 from '../../assets/img/erica-story/erica_story_img_6.jpg';
import MikeSlide7 from '../../assets/img/erica-story/erica_story_img_7.jpg';
import MikeSlide6Inner from '../../assets/img/mike-story/calvin_m_light_blue_icon_cloud.png';
import MikeSlide7Inner2 from '../../assets/img/mike-story/calvin_m_red_icon.png';
import MikeSlide8 from '../../assets/img/erica-story/erica_story_img_8.jpg';
import MikeSlide8Inner2 from '../../assets/img/mike-story/calvin_m_yellow_car_icon.png';
import MikeSlide9 from '../../assets/img/erica-story/erica_story_img_9.jpg';
import MikeSlide10 from '../../assets/img/erica-story/erica_story_img_10.jpg';
import MikeSlide11 from '../../assets/img/erica-story/erica_story_img_11.jpg';
import MikeSlide12 from '../../assets/img/erica-story/erica_story_img_12.jpg';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Erica = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );
  // const [higherEducationPopup, setHigherEducationPopup] = useState( false );
  // const [careerPathPopup, setCareerPathPopup] = useState( false );
  // const [investmentsPopup, setInvestmentsPopup] = useState( false );
  // const [careerAdvancementPopup, setCareerAdvancementPopup] = useState( false );
  // const [additionalCareerAdvancementPopup, setAdditionalCareerAdvancementPopup] = useState( false );
  // const [vacationPopup, setVacationPopup] = useState( false );
  // const [buyingCarPopup, setBuyingCarPopup] = useState( false );
  // const [financeIndependencePopup, setFinanceIndependencePopup] = useState( false );
  // const [moreHigherEducationPopup, setMoreHigherEducationPopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );

  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'erica',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  // const handleFinanceIndependencePopup = () => {
  //   setFinanceIndependencePopup( !financeIndependencePopup );
  // };

  // const handleMoreHigherEducationPopup = () => {
  //   setMoreHigherEducationPopup( !moreHigherEducationPopup );
  // };

  const handleForwordPopup = () => {
     
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=erica`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };
  
  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  // const handleHigherEducationPopup = () => {
  //   setHigherEducationPopup( !higherEducationPopup );
  // };

  // const handleCareerPathPopup = () => {
  //   setCareerPathPopup( !careerPathPopup );
  // };

  // const handleInvestmentsPopup = () => {
  //   setInvestmentsPopup( !investmentsPopup );
  // };

  // const handleCareerAdvancementPopup = () => {
  //   setCareerAdvancementPopup( !careerAdvancementPopup );
  // };

  // const handleAdditionalCareerAdvancementPopup = () => {
  //   setAdditionalCareerAdvancementPopup( !additionalCareerAdvancementPopup );
  // };

  // const handleVacationPopup = () => {
  //   setVacationPopup( !vacationPopup );
  // };

  // const handleBuyingCarPopup = () => {
  //   setBuyingCarPopup( !buyingCarPopup );
  // };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryToolNew" data-place="right" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>ERICA</h2>
                    <h2>Neuroradiologist</h2>
                    <p>15-Year Net Worth: $58,150*</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities).
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src={ SlideErica } alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Erica&apos;s Life</h3>
                    <p>After completing eight years of schooling, five years of residency and a one-year fellowship, Erica achieves her goal of becoming a licensed physician practicing neuroradiology in Nashville, Tennessee.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Erica&apos;s Start Year – 2019</h3>
                  <h4>(18 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      {' '}
                      $3k in savings
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2022: Attends and graduates from Middle Tennessee State University, majoring in Biology</p>
                      </li>
                      <li>
                        <p>2023 – 2026: Attends and graduates from the School of Medicine at Vanderbilt University</p>
                      </li>
                      <li>
                        <p>2027 – 2031: Begins a five-year Radiology Residency program at Vanderbilt University Medical Center earning $65k; begins short and long-term investing, contributes to a Roth IRA</p>
                      </li>
                      <li>
                        <p>2027: Purchases a used car for $4k, starts taking short vacations</p>
                      </li>
                      <li>
                        <p>2029: Begins moonlighting as a temporary physician</p>
                      </li>
                      <li>
                        <p>2032: Receives one-year fellowship at Vanderbilt University Medical Center and a raise to $86k, increases retirement contributions, buys an electric vehicle</p>
                      </li>
                      <li>
                        <p>2033: Achieves position as full time neuroradiologist, earning $300k annually. Rewards herself with a vacation to the Meditteranean</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $58,150</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Erica</h3>
                    <p>
                      Erica always felt that she wanted to become a physician. Immersing herself in her high school studies in her hometown of Columbia, TN, she hones her pursuits toward biology and robotics, and she develops an appreciation for the ways advances in medical technology can help patients and save lives,
                      particularly the intensive work which happens behind the scenes. Fascinated with the technology surrounding radiology, Erica develops dreams of becoming a neuroradiologist specializing in the treatment of the head, brain, spine and neck.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Early exploration of academic subjects can reveal new personal interests, which illuminate potential career paths into the future.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica takes personal time to explore her academic and personal interests during high school. By the time she graduates, she has already identified a potential plan to pursue her personal dream.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica makes a plan</h3>
                    <p>
                      Erica realizes that medical school could put her close to $175k in debt. She weighs risks versus rewards and decides that by completing her schooling on time and focusing on a subspecialty,
                      she will be able to elevate her earnings potential and pay down that debt. In high school, Erica maintains a very high GPA, and she works summers as a camp counselor, where she participates in wilderness first-aid trainings and
                      experiential education. Erica graduates from high school with $3k in savings and her own personal dream for navigating medical school.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Financial Independence" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(1)} }>
                      <img alt="" src={ mikeStory3Icon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Calculating risk vs. reward can be tricky – it&apos;s important to be cautious and honest about any potential pitfalls, such as a debt-to-income ratio that risks becoming precarious.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica weighs the risk associated with a large debt load, and she decides that benefits, including a high income, a high degree of job satisfaction and job security, outweigh the risk.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica majors in biology</h3>
                    <p>
                      Erica enrolls as an undergraduate majoring in biology at Middle Tennessee State University. She capitalizes on the benefits of in-state tuition, and also receives $4k per year from a Presidential Scholarship
                      because she meets the criteria of having a high GPA and strong SAT scores. Erica avoids undergraduate student loans because she is aware that she will soon need to borrow much more to finance medical school. She saves on daily expenses, including by choosing cycling as a primary mode of transportation to and from campus.
                      Erica&apos;s family contributes $13k annually to her education, and she earns nearly $10k per year partially with flexible side work in tourism at local sites of historical and cultural interest and in paid internships. Above all, Erica prioritizes work related to her chosen field, including paid internships in clinical settings and volunteering to job shadow local physicians.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Higher Education" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(2)} }>
                      <img alt="" src={ higherEducationIcon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>A combination of high achievement and hard work can lead to additional opportunities.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica recognizes that internships support her professional growth, building authority in her resume. In total, she earns more than $10k per year from her internships and flexible side jobs in the community.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica enrolls in medical school</h3>
                    <p>
                      Upon receiving her undergraduate degree, Erica moves to Nashville to attend the School of Medicine at Vanderbilt University. Erica&apos;s parents contribute $17k per year to her graduate-level education,
                      and she signs up for a heavy load of $172k in federal unsubsidized and private student loans. She also receives a $7,500 annual scholarship for outstanding MCAT scores and class performance. When Erica is offered and accepts a summer internship
                      opportunity at Vanderbilt University Medical Center, she strives for excellence in her work and receives high marks for her efforts.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="More Higher Education" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(6)} }>
                      <img alt="" src={ MoreHigherEducationIcon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>When pursuing higher education, it&apos;s a smart money move to graduate on time and capitalize on opportunities to excel in your chosen field, such as internships.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica graduates on time, is accepted into a great program, and is financially rewarded for another round of high test scores.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica keeps expenses low</h3>
                    <p>
                      To keep her expenses as low as possible, and because she enjoys the exercise, Erica decides to continue bike commuting, occasionally borrowing a car when necessary. Erica also works to keep her housing expenses low by sharing what she considers to be a very nice house with two friendly housemates.
                      Her new neighborhood is not far from campus and close to a greenway where she enjoys trail riding. Erica continues to build clinical experience and earns supplementary income through paid summer internships in her field.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="More Higher Education" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(6)} }>
                      <img alt="" src={ MoreHigherEducationIcon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Keeping expenses low is essential for many people with big aspirations. Paid internships help build experience and can ease the burden of everyday living expenses.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica keeps expenses low by entering into a shared housing arrangement within biking distance of campus. In the summer, she capitalizes on internships to build experience and help defray living expenses.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A Radiology Resident</h3>
                    <p>
                      With acceptance into a five-year Radiology Residency at Vanderbilt University Medical Center, Erica begins earning an annual income of $65k. Understanding that her income will soon be too high to contribute to a Roth IRA, Erica contributes 5% to a Roth IRA during her residency and fellowship programs.
                      She also contributes $50 per month to investments and sets aside any excess cash savings to build a short-term emergency fund. Erica enjoys having her own modest apartment a short distance from the hospital. When time allows, she also begins taking a few short vacations.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Path" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(4)} }>
                      <img alt="" src={ MikeBlue } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Investments" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(9)} }>
                      <img alt="" src={ MikeGreen } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Career moves can sometimes require streamlining and simplifying aspects of life, including tending to personal needs when striving to reach mastery in your career.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica is thrilled about her new position, which is very intensive work, sometimes in high-stakes situations. Moving into her own place brings a greater sense of quiet and privacy, and allows her to decompress after many long days.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica buys a used car</h3>
                    <p>
                      In her first year of residency, Erica strives to keep her expenses low yet feels the need for a car to commute to the medical center, since her schedule now may require travel at all hours of the day or night.
                      As she starts to pay down her medical school loans, she shies away from adding to her monthly payments.
                      Erica weighs the risk of buying an inexpensive used car against leasing a new one for almost twice the price.
                      She enlists the aid of a friend, a mechanic, who helps her find the right used vehicle. For the short commute,
                      Erica purchases a used, sporty subcompact for $4k, spending an additional $500 on preventative maintenance.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Car" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(12)} }>
                      <img alt="" src={ MikeSlide8Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Staying on top of debt is crucial to financial independence.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica is reluctant to take on more debt, and after careful consideration and seeking a second opinion, she decides that the stress associated with higher monthly payments outweighs any risks associated with driving an inexpensive used vehicle for her short commute.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica begins moonlighting</h3>
                    <p>
                      In her third year of residency, Erica feels comfortable enough with her career demands to begin moonlighting and bring in the extra income she needs to help pay down her student loans. The medical center has strict rules for moonlighting, and Erica
                      finds that she can meet all the criteria. She earns $12k annually on the side for the next three years as a temporary physician. Erica is paid for being on-call at off hours, and she receives extra pay whenever she is called to return to the medical center during off-hours.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Path" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(4)} }>
                      <img alt="" src={ MikeBlue } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Everybody has different goals and strategies. Some people choose to enjoy taking a pause upon reaching a new career plateau, while others choose to step-it-up even more to accelerate freedom from debt.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Even after reaching full-employment status in her chosen field, Erica realizes that taking on occasional moonlighting work can help her climb out of debt more quickly, especially because it is very high-wage work.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide10 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Erica receives a fellowship</h3>
                    <p>
                      In 2032, Erica receives a one-year fellowship focusing on neuroradiology, also at Vanderbilt University Medical Center. Her income during rises to $86k and she continues contributing 5% to her retirement account. As Erica enters the program, she decides to sell her car and purchase an electric vehicle, finding an EV nearby for just over $40k that runs great, looks brand new, and is still under warranty.
                      Financing her purchase with 25% down,
                      she receives a lower loan rate than her college loans and decides that by financing the car, she can continue to repay her educational debts on schedule. Erica knows that when she completes the fellowship program, a number of career paths will be open to her.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(5)} }>
                      <img alt="" src={ MikeBlue2 } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Car" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(12)} }>
                      <img alt="" src={ MikeSlide8Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Staying focused on your big picture vision for career development can help to illuminate paths for advancement.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Ever since high school, Erica has been driven to help patients by developing a mastery of cutting edge medical technology. Now more than a decade later, the technology may have changed, yet Erica&apos;s intention remains the same as she begins her one-year fellowship.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide11 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A Full-Time Neuroradiologist</h3>
                    <p className={ classes.paddingBottomPara }>
                      Erica is hired as a full-time neuroradiologist at the same medical center. Her focus on becoming a subspecialist in radiology pays off as she now earns $300k annually. She rewards herself with a $5k vacation venturing along the Mediterranean coasts of Spain, France and Italy. Stepping back from her busy
                      life for a moment while on vacation, she pauses and begins to feel a sense of financial freedom, smiling to herself that her uphill journey has certainly been worth all of her effort.
                    </p>
                    <p>What happens next?</p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Additional Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(8)} }>
                      <img alt="" src={ MikeSlide6Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Vacation" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(11)} }>
                      <img alt="" src={ MikeSlide7Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Take personal time to reflect upon struggle and acknowledge success when the time is right.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Erica continues to live frugally and make smart money moves as she continues her path to debt repayment, already with almost half of her college loans paid off.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Erica&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Erica earns 10% more income in her plan?</li>
                        <li>What happens if she spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on her net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 11 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Erica&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>
                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Erica&apos;s financial life plan by module to see how she acheives her goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Erica - Neuroradiologist</h4>
                      <h4>Net Worth:  $58,150</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide12 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Erica&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(32 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Education</li>
                      <li>Medical Degree</li>
                      <li>License to Practice</li>
                      <li>On-the-Job Experience</li>
                      <li>Subspecialty Fellowship</li>
                      <li>A Career</li>
                      <li>An Electric Vehicle</li>
                      <li>Annual Vacations</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $58,150</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Erica&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 9.1%</li>
                      <li>Monthly Liquidity Ratio: 5.3x</li>
                      <li>Current Ratio: 1.4x</li>
                      <li>Savings Rate: 58.7%</li>
                      <li>Investment Assets to Total Assets: 79.5%</li>
                      <li>Total Assets to Total Debt: 1.5x</li>
                      <li>Solvency Ratio: 0.4x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>

      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}
    </Typography>
  );
};

Erica.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Erica );
