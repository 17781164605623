import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';
import { Button } from '@material-ui/core';
// import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
// import SwipeableViews from 'react-swipeable-views';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import classNames from 'classnames';
// import { Link } from 'react-scroll';
// import pricingBanner from '../../../assets/img/pricing/pricing_banner_img_new.png';
import triangleImage from '../../../assets/img/pricing/pricingcards_bg.png';
// import tickMark from '../../../assets/img/pricing/tick_mark.png';
// import powerUp from '../../../assets/img/pricing/print_power_up_cover.jpg';
// import pf101 from '../../../assets/img/pricing/powerup-101-gold-wlogo.png';
// import careerSketch from '../../../assets/img/pricing/my-career-sketch-blue.png';
// import pwiLogo from '../../../assets/img/pricing/puzzle-icon.png';

// import pwiLogo1 from '../../../assets/img/pricing/my-career-sketch-blue-new.png';
// import pwiLogo2 from '../../../assets/img/pricing/plan-wit.png';
// import pwiLogo3 from '../../../assets/img/pricing/powerup-101-gold.png';
// import downloadBrochure from '../../../assets/img/pricing/download-brochure.png';
// import NineByTen from '../../../assets/img/pricing/nine_by_ten.png';
// import quoteTwo from '../../../assets/img/pricing/quote2.png';


function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `full-width-tabpanel-${index}` }
      aria-labelledby={ `full-width-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

// function a11yProps( index ) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

const Pricing = ( props ) => {
  const { classes, history } = props;
  // const theme = useTheme();

  let referalId = history.location.pathname;
  referalId = referalId.split('/');
  referalId = referalId[2]; 

  // const [value, setValue] = React.useState( 0 );
  // const [expanded, setExpanded] = React.useState( false );
  // const frequentAskedQuestions = [
  //   {
  //     id: 1, scroll: useRef( null ),
  //   },
  //   {
  //     id: 2, scroll: useRef( null ),
  //   },
  //   {
  //     id: 3, scroll: useRef( null ),
  //   },
  //   {
  //     id: 4, scroll: useRef( null ),
  //   },
  //   {
  //     id: 5, scroll: useRef( null ),
  //   },
  // ];
  // const handleChange = ( event, newValue ) => {
  //   setValue( newValue );
  // };

  const buyNow = ( plan ) => {
    localStorage.setItem( 'inkwiry_pricing_plan', plan );
    localStorage.setItem('referal_key_id', referalId);
    history.push( '/join' );
  };

  // const handleChangeIndex = ( index ) => {
  //   setValue( index );
  // };

  // const handleAccordianChange = ( panel ) => ( event, isExpanded ) => {
  //   setExpanded( isExpanded ? panel : false );
  // };

  // const handleScrollToPoint = ( askedQutn ) => {
  //   scrollToComponent( askedQutn, {
  //     offset: -500,
  //     align: 'top',
  //     duration: 200,
  //     behavior: 'smooth',
  //   } );
  // };
  return (
    <Typography variant="body1" component="div">
      <div className={ classes.pricingPage }>
        <div className={ classes.pricingBanner }>
          <div className={ classes.container }>
            <div className={ classes.pricingContent }>
              {/*<div className={ classes.pricingImage }>
                <img src={ pricingBanner } alt="pricingBanner" />
              </div>*/}
              <Typography variant="h2" component="h2">A smart business strategy at an ultra affordable price</Typography>
              <Typography variant="h3" component="h3">Give employees the tools they need to cure financial stress on the job.</Typography>
              <div className={classes.studentsLifeGrid}>
                <Grid container spacing={ 3 } className={ classes.studentsLifeGridContainer }>
                  <Grid item xs={ 12 } md={ 4 }>
                    <div className={classes.studentsBox}>
                      <p>The price of  <b>lost productivity</b> and <b>greater staff turnover</b> equates to <b>11-14% of total salary cost</b> for an employer.</p>
                    </div>
                  </Grid>
                  <Grid item xs={ 12 } md={ 4 }>
                    <div className={classes.studentsBox}>
                        <div className={classes.studentsBoxFlex}>
                          <p>Employees with money worries are <b>8x more likely</b> to have sleepless nights and <b>6x more likely</b> to not finish daily tasks.
                          </p>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={ 12 } md={ 4 }>
                    <div className={classes.studentsBox}>
                        <p><b>48% of people have financial stress.</b> As a consequence, they each <b>lose 23-31 productive days</b> annually.</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
           
             
            </div>
          </div>
          {/* <div className={ classes.downTriangle }>
            <div />
          </div> */}
        </div>
        <div className={ classes.affordablePlanSec }>
          <div className={ classes.container }>
            <div className={ classes.affordablePlan }>
              <p className={classes.pb20}>Did you know employee stress over personal finances costs U.S. employers an estimated <b>$500 billion in lost wages</b> each year?  <span className={classes.smallText}>(Salary Finance Report, 2019)</span></p>             
              <p>With Inkwiry, you can offer a program that:</p>
              <ul className={ classes.affordablePlanListNew }>
                <li>empowers each employee build a <b>dynamic financial life plan</b> while growing their personal finance skills and knowledge</li>
                <li>mitigates lost wages</li>
                <li>boosts employee productivity and</li>
                <li>helps retain and attract talent to your business.</li>
              </ul>
            </div>
          </div>
          {/* <div className={ classes.downTriangle }>
            <div />
          </div>  */}
        </div>
  
        <div className={ classes.yourChoice }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Plan With Inkwiry is a smart move.</Typography>
            <p>Purchase Plan With Inkwiry for your workforce. Whether you have a handful of employees or more than 1,000, they’ll benefit from easy-to-use financial life planning tools. Plus, employees gain full access to our online courses that build a foundation of financial skills and knowledge, empowering them to plan and manage their financial lives. To top it off, we provide you with a secure, custom admin tool to streamline management of the financial wellness program.</p>
          </div>
        </div>
        <div className={ classes.pricingTabs }>
          <div className={ classes.container }>
            <div className={ classes.planPricesBoxFlex }>
              <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                <div className={ classes.pricingBlock }>
                  {/* <div className={ classes.pricingTitle }>
                    Plan With Inkwiry
                  </div> */}
                  <div className={ classes.pricingBody }>
                    <p className={ classes.planPrice }>$40</p>
                    <p className={ classes.planText }>
                    per user per year
                    </p>
                    <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                  </div>
                </div>
              </div>
              <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                <div className={ classes.pricingBlock }>
                  <div className={ classes.pricingBody }>
                    <p className={ classes.planPrice }>$30</p>
                    <p className={ classes.planText }>
                    per user per year
                    </p>
                    <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                  </div>
                </div>
              </div>
              <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                <div className={ classes.pricingBlock }>
                  <div className={ classes.pricingBody }>
                    <p className={ classes.planPrice }>$25</p>
                    <p className={ classes.planText }>
                    per user per year
                    </p>
                    <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                  </div>
                </div>
              </div>
              <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                <div className={ classes.pricingBlock }>
                  <div className={ classes.pricingBody }>
                    <p className={ classes.planPrice }>$20</p>
                    <p className={ classes.planText }>
                    per user per year
                    </p>
                    <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.triangleImg }>
            <img src={ triangleImage } alt="triangleImage" />
          </div>
          <div className={ classes.priceTabs }>            
            <div className={ classes.container }>
                  <div className={ classes.pricingTableOverflow }>
                    <Grid container spacing={ 4 } className={ classes.powerUpImagesContainer }>
                        <Grid item xs={ 12 } md={ 6 } className={ classes.powerUpImagesLeft }>
                            <div className={classes.tableAdmin}>
                              <table>
                                <thead>
                                    <th>As a business executive, you'll appreciate:</th>
                                </thead>
                                <tbody>
     <tr className={ classes.blueRowNew }>
        <td>Clear, measurable ROI:</td>
     </tr>
     <tr>
        <td>Mitigate lost wages as employees spend less time worrying about personal finances</td>
     </tr>
     <tr>
        <td>Generate increased productivity and focus</td>
     </tr>
     <tr>
        <td>Retain talent, reduce turnover and inspire loyalty</td>
     </tr>
     <tr>
        <td>Entice new talent with increased employee satisfaction and higher levels of trust</td>
     </tr>
     <tr>
        <td>Differentiate your brand with an innovative financial technology platform</td>
     </tr>
     <tr>
        <td>Instantly implement a fully digital financial wellness platform with no additional overhead</td>
     </tr>
     <tr>
        <td>As employees develop confidence in their futures, they exhibit more confidence at work</td>
     </tr>
     <tr className={ classes.blueRowNew }>
        <td>Efficient admin tools:</td>
     </tr>
     <tr>
        <td>Onboard and manage employee users in real time from branded custom dashboard</td>
     </tr>
     <tr>
        <td>Create accounts on demand</td>
     </tr>
     <tr>
        <td>Measure engagement with anonymous reporting on employee activity</td>
     </tr>
     <tr>
        <td>Review customized monthly reports</td>
     </tr>
  </tbody>
                              </table>
                            </div>
                         </Grid>

                         <Grid item xs={ 12 } md={ 6 } className={ classes.powerUpImagesRight }>
                            <div className={classes.tableAdmin}>
                              <table>
                                <thead>
                                    <th>Every employee user has access to:</th>
                                </thead>
                                <tbody>
   <tr>
      <td className={classes.pad8}>Plan With Inkwiry</td>
   </tr>
   <tr>
      <td className={classes.pad8}>Inkwiry Financial Configurator + Simulator Engine</td>
   </tr>
   <tr>
      <td className={classes.pad8}>Career Sketching</td>
   </tr>
   <tr>
      <td className={classes.pad8}>Career Sketch Stories</td>
   </tr>
   <tr>
      <td className={classes.pad8}>Advanced analytics, visualizations, reporting</td>
   </tr>
   <tr className={ classes.blueRowNew }>
      <td>Online Personal Finance Courses:</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>Personal Finance PowerUp 101</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>My Career Sketch</td>
   </tr>
   <tr className={ classes.blueRowNew }>
      <td>Plus:</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>Finance Explained</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>Walk-thrus to guide users</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>Sharing and collaboration tools</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>Tutorials: 2.5 hours and counting</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>300 quick links to resources</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>200 financial terms defined</td>
   </tr>
   <tr>
      <td className={ classes.leftPadding }>A growing library of financial calculators</td>
   </tr>
   <tr>
      <td className={classes.pad8}>Immediate access to new features as they roll out</td>
   </tr>
</tbody>
                              </table>
                            </div>
                         </Grid>
                         
                      </Grid>
                  </div>
                </div>

          </div>
        </div>
        <div className={ classes.planPricesSec }>
          <div className={ classes.container }>
            <div className={ classes.planPrices }>
              <div className={ classes.planPricesBoxFlex }>
                <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                    <div className={ classes.pricingBlock }>
                      <div className={ classes.pricingBody }>
                        <p className={ classes.planPrice }>$40</p>
                        <p className={ classes.planText }>                       
                          <span>per user per year</span>
                        </p>
                        <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                      </div>
                    </div>
                </div>
                <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$30</p>
                      <p className={ classes.planText }>
                      per user per year
                      </p>
                      <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                    </div>
                  </div>
                </div>
                <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$25</p>
                      <p className={ classes.planText }>
                      per user per year
                      </p>
                      <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                    </div>
                  </div>
                </div>
                <div className={classNames(classes.planPrices, classes.planPricesBox)}>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$20</p>
                      <p className={ classes.planText }>
                      per user per year
                      </p>
                      <Button onClick={ () => buyNow( 'monthly' ) }>Start Now</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Pricing );
