import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import * as modulesServices from '../../../calculations/modules-services';

const FundingSources = ( props ) => {
  const {
    classes, inputData, summery, handleSlide
  } = props;


  const [totalFundingShow, setTotalFundingShow] = useState( summery === true );

  const handleTotalFundingShow = () => {
    setTotalFundingShow( !totalFundingShow );
  };

  const scholarshipsTotal = ((inputData.yearly_scholarship_amount !== undefined ? inputData.yearly_scholarship_amount : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 )); 
  const grandTotal = ((inputData.yearly_grant_amount !== undefined ? inputData.yearly_grant_amount : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 ));

  let familyYearsDiff = 0;
  if(inputData.family_start_year !== undefined && inputData.family_start_year > 0 && inputData.family_end_year !== undefined && inputData.family_end_year > 0) {
    familyYearsDiff = inputData.family_end_year-inputData.family_start_year+1;
  }
  const totalFamily = ((inputData.yearly_contribution !== undefined && inputData.yearly_contribution > 0) ? inputData.yearly_contribution : 0)*familyYearsDiff;  
  const totalCash = ((inputData.cash_and_investments !== undefined ? inputData.cash_and_investments : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 ));
  const totalEstimated = modulesServices.module15Data.newHigherinvestmentAnalysisIncomeEarned;
  const federalLoan = (modulesServices.module15Data.newFedSubBeginningBalance !== undefined ? modulesServices.module15Data.newFedSubBeginningBalance : 0) + (modulesServices.module15Data.newFedSubBeginningBalance !== undefined ? modulesServices.module15Data.newFedSubBeginningBalance : 0);
  const privateLoan = modulesServices.module15Data.newPrivatePrincipal !== undefined ? modulesServices.module15Data.newPrivatePrincipal : 0;
  const originationFees = modulesServices.module15Data.newSummaryLoanPoints !== undefined ? modulesServices.module15Data.newSummaryLoanPoints : 0;
  const fundingTotal = scholarshipsTotal+grandTotal+totalFamily+totalCash+totalEstimated+federalLoan+privateLoan+originationFees;
  
  const remainingAmount = fundingTotal-modulesServices.module15Data.educationTotalAttendance;

  return (
    <Grid sm={ 6 }>
      <div className={ classNames( classes.giftSources, classes.heSourcesNew ) }>
        <table cellPadding="0" cellSpacing="0">
          {totalFundingShow && (
          <thead>
            <th colSpan={ 3 } align="center">
              {summery === true ? 'Total' : ''}
              {' '}
              Funding Sources
            </th>
          </thead>
          ) }
          <tbody>
            {totalFundingShow
          && (
          <React.Fragment>
            <tr aria-hidden="true" onClick={()=>handleSlide(15,'next')} className={classes.cursorPointer}>
              <td />
              <td>Scholarships</td>
              <td>
                {' '}
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ scholarshipsTotal } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(15,'next')} className={classes.cursorPointer}>
              <td />
              <td>Grants</td>
              <td>
                {' '}
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ grandTotal } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(16,'next')} className={classes.cursorPointer}>
              <td />
              <td>Family Contributions</td>
              <td><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ totalFamily } displayType="text" thousandSeparator prefix="$" /></td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(16,'next')} className={classes.cursorPointer}>
              <td />
              <td>Cash and Investments</td>
              <td><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ totalCash } displayType="text" thousandSeparator prefix="$" /></td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(17,'next')} className={classes.cursorPointer}>
              <td />
              <td>Estimated After-Tax Income</td>
              <td><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ totalEstimated } displayType="text" thousandSeparator prefix="$" /></td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(18,'next')} className={classes.cursorPointer}>
              <td />
              <td>Federal Student Loans</td>
              <td><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ federalLoan } displayType="text" thousandSeparator prefix="$" /></td>
            </tr>
            <tr aria-hidden="true" onClick={()=>handleSlide(22,'next')} className={classes.cursorPointer}>
              <td />
              <td>Private Student Loans</td>
              <td><NumberFormat decimalScale={ 0 } fixedDecimalScale value={ privateLoan } displayType="text" thousandSeparator prefix="$" /></td>
            </tr>
            <tr>
              <td />
              <td>Origination Fees</td>
              <td className={originationFees < 0 && classes.dangerText}>
                { originationFees >= 0 ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ originationFees } displayType="text" thousandSeparator prefix="$" />
                  ) : (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ -originationFees } displayType="text" thousandSeparator prefix="($" suffix=")"/>
                  )
                }
              </td>
            </tr>
          </React.Fragment>
          )}
            <tr className={ classNames( classes.trFoot, classes.cursorPointer ) } onClick={ summery === true ? '' : handleTotalFundingShow }>
              <td>{summery === true ? '' : <i className={ !totalFundingShow ? 'fa fa-plus' : 'fa fa-minus' } />}</td>
              <td>Total Funding Sources</td>
              <td>
                {' '}
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ fundingTotal } displayType="text" thousandSeparator prefix="$" />
                {' '}
              </td>
            </tr>
            <tr className={ classes.trFoot }>
            {(remainingAmount >= 0 || remainingAmount < 0 ) &&  <td></td>}
              {remainingAmount >= 0 &&  <td> Cash Left Over</td>}
              {remainingAmount < 0 && <td>Cash Needed</td>}
              {remainingAmount >= 0 && (
              <td>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ remainingAmount } displayType="text" thousandSeparator prefix="$" />
              </td>
              )}
              {remainingAmount < 0 && (
              <td className={ classes.dangerText }>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ -remainingAmount } displayType="text" thousandSeparator prefix="($" suffix=")" />
              </td>
              )}
            </tr>

          </tbody>
          <tfoot>
            <tr>
              {remainingAmount >= 0 && <td colSpan={ 3 } className={ classes.costPositive }>Success! You have budgeted enough money to pay for school!</td>}
              {remainingAmount < 0 && <td colSpan={ 3 } className={ classes.orangeBG }>Keep going! You need more money to pay for school.</td>}
            </tr>
          </tfoot>
        </table>
      </div>
    </Grid>
  );
};

FundingSources.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleSlide: PropTypes.func.isRequired,
  summery: PropTypes.bool,
};

FundingSources.defaultProps = {
  summery: false
}

export default withStyles( styles )( FundingSources );
