import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import Cash_Flow_Movement_1 from '../../assets/img/pwi-existing/Cash_Flow_Movement_1.png';

const MaxShortTermInvestmentsBalance = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject } = props;
  const [maxCash, setMaxCash] = React.useState(0);

  React.useEffect(() => {
    setMaxCash(modulesServices.module18Data.existing_max_short_term_before_long_term);
  }, [])

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    setMaxCash(newvalue);
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        As your cash bucket fills up and automatically moves to short-term investments, you build your emergency fund.
         But, just like cash, holding too much in short-term investments isn’t the best use of your money.
         High-yield savings accounts, money market funds and other short-term accounts won’t grow your wealth due to a low rate of return.
      </p>
      <p className={ classes.existingSpace }>
        You should hold enough cash in your short-term investments to pay three to six months of monthly living expenses. 
        The financial engine that allows you to plan – the Financial Configurator – will automatically invest your excess cash when it reaches the maximum balance you enter below.
      </p>
      
      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ classes.sliderBottomSpaceNewOne }> */}
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Max Short-Term Balance before Investment</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.maxCashArray() }
                  min={ 0 }
                  max={ 50000 }
                  step={ 1 }
                  value={ maxCash }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( maxCash )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'existing_max_short_term_before_long_term', 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale  thousandSeparator value={ maxCash } onValueChange={ ( e ) => updateValue( e, '', 'existing_max_short_term_before_long_term', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
      <p className={classes.mr40}>Review the graphic below to discover how the Financial Configurator automates investing with a max short-term investments balance.</p>
      <div class="image_fi"> 
        <div class="light_gallery_img"> 
          <div class="image_black_border"> 
            <div> 
              <img class="image_flow_moment" src={Cash_Flow_Movement_1} alt="Cash_Flow_Movement_1"/> 
            </div> 
          </div> 
        </div> 
      </div>
    </div>
  );
};

MaxShortTermInvestmentsBalance.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( MaxShortTermInvestmentsBalance );
