const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: theme.palette.common.black,
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  netWorthSection: {
    minHeight: 'calc(100vh - 101px)',
    width: '48%',
    margin: '0 auto',
    paddingBottom: '20px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      width: '60%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '85%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  showMeContent: {
    padding: '0 35px',
    background: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 15px',
    },
  },
  showPortion: {
    padding: '20px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0px',
    },
  },
  nwSectionRow: {
    margin: '0px -15px',
  },
  netWorthContent: {
    padding: '0px 25px',
  },
  tableRow: {
    border: '2px solid #0069aa',
    display: 'flex',
    padding: '10px',
    margin: '20px -15px',
    lineHeight: '1.42857143',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px -15px',
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      backgroundColor: 'transparent',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '21px',
            padding: '4px 0',
            paddingLeft: '15px',
            color: '#000',
            fontFamily: '"MuseoSans-300"',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '15px',
              paddingLeft: '0px',
            },
            '@media (max-width: 320px)': {
              fontSize: '13px',
            },      
            '&:nth-child(3)': {
              textAlign: 'right',
              maxWidth: '200px',
              boxSizing: 'border-box',
            },
            '& span': {
              paddingRight: '4px',
              wordBreak: 'break-all',
            },
            '& input': {
              width: '135px',
              textAlign: 'right',
              border: '1px solid #333',
              transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              padding: '1px 2px',
              backgroundImage: 'none',
              lineHeight: 'inherit',
              color: 'inherit',
              boxSizing: 'border-box',
              height: '36px',
              font: 'inherit',
              fontSize: '21px',
              [theme.breakpoints.down( 'xs' )]: {
                width: '100px',
                fontSize: '15px',
                height: '27px',
              },
              '@media (max-width: 320px)': {
                width: '70px',
                fontSize: '13px',
              }, 
              '&:focus': {
                outline: 'auto',
              },
            },
            '& div': {              
              [theme.breakpoints.down( 'xs' )]: {
                verticalAlign: 'middle',
              },
              '&::before': {
                border: 'none !important',
              },
              '&::after': {
                border: 'none !important',
              },
            },
          },
        },
      },
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  sectionTitle: {
    fontWeight: '700',
    paddingLeft: '0!important',
    textDecoration: 'underline',
  },
  totalAssets: {
    '& td': {
      borderTop: '1px solid #000',
      paddingLeft: '0px',
      fontWeight: '700',
      fontFamily: '"MuseoSans-500"',
    },
  },
  finalNetWorth: {
    marginTop: '20px',
    border: '2px solid #000',
    background: '#ddebf7',
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  emptyRow: {
    '& td': {
      lineHeight: '5px !important',
    },
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '18px',
    },
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      [theme.breakpoints.down( 'xs' )]: {
        width: '16px',
      },
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    zIndex: '99',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      [theme.breakpoints.down( 'xs' )]: {
        borderTopColor: 'transparent !important',
        borderRightColor: '#0069aa !important',
      },
    },
  },
  noUnderline: {
    textDecoration: 'none',
  },
  breakText: {
    '& span': {
      wordBreak: 'break-all',
    },
  },
} );

export default styles;
