import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const VehicleOwn1 = ( props ) => {
  const { classes, handleAssestObject, handleUpdatedObject, vehicle} = props;
  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p>The value of your vehicle is the price you would sell it for today. Not sure the current value? Check out the links below:</p>
      <p> 
        <a href="https://www.kbb.com/" target="_blank" rel="noreferrer">Kelly Blue Book</a>
        <br/>
        <a href="https://www.nadaguides.com/Boats" target="_blank" rel="noreferrer">Nada Guides for Boats</a> 
      </p>
      <p className={ classes.existingSpace }> Enter the ownership details about Vehicle {vehicle} below. </p>
      <div className={classes.section4TableScroll}>
        <div className={ `${classes.section4Table} ${classes.sliderBottomSpace}` }>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }>Vehicle Value</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Value() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 1 }
                  value={ vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_current_value' : `existing_car_current_value_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_current_value' : `existing_car_current_value_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
          <div className={ classes.sectionClear30 }></div>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.sectionNoData }>
              <span>&nbsp;</span>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Maintenance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Insurance</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.newFlexBox }>
              <span className={ classes.section4TableLeftTitle }>Gas / Electricity</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Expenses() }
                  min={ 0 }
                  max={ 1000 }
                  step={ 1 }
                  value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Car Expenses</p>
            </div>
          
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneMonth }>
              <span>Monthly</span>
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              {/* <span className={ classes.section4TableRightOneDoller }>$</span> */}
                {modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                ) : ('$0')}
            </div>
          
          </div>
          <div className={ classes.section4TableRightTwo }>
            <div className={ classes.section4TableRightOneMYear }>
              <span>Annual</span>
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              {/* <span className={ classes.section4TableRightOneDoller }>$</span> */}
              {modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] !== undefined ? (
                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] < 0 && ')'} />
              ) : ('$0')}
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              {/* <span className={ classes.section4TableRightOneDoller }>$</span> */}
              {modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] !== undefined ? (
                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] < 0 && ')'} />
              ) : ('$0')}
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              {/* <span className={ classes.section4TableRightOneDoller }>$</span> */}
              {modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] !== undefined ? (
                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] < 0 && ')'} />
              ) : ('$0')}
            </div>
            <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
              {/* <span className={ classes.section4TableRightOneDoller }>$</span> */}
                {
                  modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] * 12} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                  ) : ('$0')
                }
            </div>
        
          </div>
          
        </div>
      </div>
       {/* <div className={ classes.innnerFlowGroup }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Car Expenses</p>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo}` }>
                    <span className={classes.dollarSign}>$</span>
                      {modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                      ) : ('$0')}
                  </div>
                </div>
              </div>
              <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                  <span className={classes.dollarSign}>$</span>
                    {
                    modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] * 12} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                    ) : ('$0')
                  }
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ `${classes.sliderBottomSpace} ${classes.sliderBottomSpaceNewOne}, ${classes.sliderBottomSpace50}` }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Vehicle Value</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.vehicleOwn1Value() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 1 }
                  value={ vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_current_value' : `existing_car_current_value_${vehicle}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_current_value :  handleAssestObject[`existing_car_current_value_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_current_value' : `existing_car_current_value_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }> */}
          {/* <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}` }>
                  <div>
                    <p><span>Maintenance</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          ValueLabelComponent={ ValueLabelComponent }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          marks={ sliderValues.vehicleOwn1Expenses() }
                          min={ 0 }
                          max={ 1000 }
                          step={ 1 }
                          value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] }
                          valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] )}` }
                          onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'slider' ) }
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                    <div>
                      <h3>Monthly</h3>
                        <div className={ classes.inputRightBlock }>
                          <div className={classes.annualInput}>
                            <span className={classes.dollarSign}>$</span>
                            <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                          </div>
                        </div>
                      </div>
                      <div className={ classes.rightBoxNogap }>
                        <h3>
                          Annual
                        </h3>
                          <div className={ `${classes.inputRightBlock} ${classes.width70}` }>
                            <span className={classes.dollarInput}>$</span>
                           
                              {modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] !== undefined ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] < 0 && ')'} />
                              ) : ('$0')}
                         
                        </div>
                      </div>
                  </div>
            </div> */}
            {/* <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}` }>
              <div>
              <p><span>Insurance</span></p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ sliderValues.vehicleOwn1Expenses() }
                    min={ 0 }
                    max={ 1000 }
                    step={ 1 }
                    value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] }
                    valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] )}` }
                    onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'slider' ) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
            <div>
              <div className={ classes.inputRightBlock }>
                <div className={classes.annualInput}>
                  <span className={classes.dollarSign}>$</span>
                  <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                </div>
              </div>
            </div>
            <div className={ classes.rightBoxNogap }>
              <div className={ `${classes.inputRightBlock} ${classes.width70}` }>
                <span className={classes.dollarInput}>$</span>
                  {modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] < 0 && ')'} />
                  ) : ('$0')}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className={ classes.innnerFlowGroup }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}` }>
                <div>
                  <p><span>Gas / Electricity</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn1Expenses() }
                      min={ 0 }
                      max={ 1000 }
                      step={ 1 }
                      value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <div className={classes.annualInput}>
                    <span className={classes.dollarSign}>$</span>
                    <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                    
                  </div>
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <div className={ `${classes.inputRightBlock} ${classes.width70}` }>
                  <span className={classes.dollarSign}>$</span>
                    {modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] < 0 && ')'} />
                    ) : ('$0')}
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className={ classes.innnerFlowGroup }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.vehicleOwnTotalOne}` }>
              <p>Total Car Expenses</p>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <div className={ `${classes.annualInput} ${classes.vehicleOwnTotalTwo}` }>
                    <span className={classes.dollarSign}>$</span>
                      {modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                      ) : ('$0')}
                  </div>
                </div>
              </div>
              <div className={ `${classes.rightBoxNogap} ${classes.rightBoxNogapFlex}` }>
                <div className={ `${classes.inputRightBlock} ${classes.vehicleOwnTotalThree}` }>
                  <span className={classes.dollarSign}>$</span>
                    {
                    modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] * 12} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                    ) : ('$0')
                  }
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
            <h3>
              <strong>Total Car Expenses</strong>
            </h3>
            <div>
              <div>
                {modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                ) : ('$0')}
              </div>
            </div>
            <div className={ classes.rightBoxNogap }>
              <div>
                {
                  modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] * 12} prefix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansTotalMonthlyExpenses${vehicle}`] < 0 && ')'} />
                  ) : ('$0')
                }
              </div>
            </div>
          </div> */}
        {/* </div>
      </div> */}
      {/* <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                <div>
                  <p>Maintenance</p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn1Expenses() }
                      min={ 0 }
                      max={ 1000 }
                      step={ 1 }
                      value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <h3>Monthly</h3>
                <div className={ classes.inputRightBlock }>
                  <span className={classes.dollarSign}>$</span>
                  <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_maintenance :  handleAssestObject[`existing_car_loans_own_maintenance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_maintenance' : `existing_car_loans_own_maintenance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <h3>Annual</h3>
                <div className={ classes.inputRightBlock }>
                  {modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansMaintenanceCalcs${vehicle}`] < 0 && ')'} />
                  ) : ('$0')}
                </div>
              </div>
            </div>
          </div>

          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                <div>
                  <p>Insurance</p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn1Expenses() }
                      min={ 0 }
                      max={ 1000 }
                      step={ 1 }
                      value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <span className={classes.dollarSign}>$</span>
                  <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_insurance :  handleAssestObject[`existing_car_loans_own_insurance_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_insurance' : `existing_car_loans_own_insurance_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <div className={ classes.inputRightBlock }>
                  {modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansInsuranceCalcs${vehicle}`] < 0 && ')'} />
                  ) : ('$0')}
                </div>
              </div>
            </div>
          </div>

          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}` }>
                <div>
                  <p>Gas / Electricity</p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ sliderValues.vehicleOwn1Expenses() }
                      min={ 0 }
                      max={ 1000 }
                      step={ 1 }
                      value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] }
                      valueLabelFormat={ `${commonFunctions.thousandFormatter( vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] )}` }
                      onChange={ ( e, value ) => updateValue( e, value, vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
              <div>
                <div className={ classes.inputRightBlock }>
                  <span className={classes.dollarSign}>$</span>
                  <NumberFormat customInput={ TextField } allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator value={ vehicle === "" ? handleAssestObject.existing_car_loans_own_gas :  handleAssestObject[`existing_car_loans_own_gas_${vehicle}`] } onValueChange={ ( e ) => updateValue( e, '', vehicle === "" ? 'existing_car_loans_own_gas' : `existing_car_loans_own_gas_${vehicle}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                </div>
              </div>
              <div className={ classes.rightBoxNogap }>
                <div className={ classes.inputRightBlock }>
                  {modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`]} prefix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] >= 0 ? '$' : '($'} suffix={modulesServices.module18Data[`existingCarLoansGasCalcs${vehicle}`] < 0 && ')'} />
                  ) : ('$0')}
                </div>
              </div>
            </div>
          </div>

        
        </div>
      </div> */}
    </div>
  );
};

VehicleOwn1.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( VehicleOwn1 );
