import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import { formatDollar } from '../../../../utilities/chartCommonFunctions';

const startTime = new Date();
const Investing101 = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing-101.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Investing 101</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing-101-300px.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Investing is a powerful tool to build wealth and achieve financial independence. Investing is committing money today to buy assets – stocks, bonds, funds, or real estate – with the expectation of getting more money back in the future by selling those assets or earning income on those assets. Investing may seem confusing and complex but understanding the foundations will make you a confident investor.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>A dollar today is worth more than a dollar tomorrow</b>
                        <br />
                        You might&apos;ve heard this expression before, but what does it actually mean? A dollar today is worth more than a dollar tomorrow because you can invest the dollar you have today and earn a return on that dollar by tomorrow. This fundamental principle of finance is called
                        {' '}
                        <b>time value of money.</b>
                        {' '}
                        The sooner you receive money, the sooner you can invest that money and earn a return.
                        <p>
                          <b>Power of compound interest and time</b>
                          <br />
                          Investing small amounts of money consistently over time has the potential to grow to large sums of money due to the power of compounding interest, also known as earning interest on interest. Compounding interest is easiest to understand in an example. Let&apos;s say you invest in a mutual fund that earns 6% annually.
                        </p>
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <p>
                            If the investment compounds on a monthly basis and earns 6% annually:
                            <br />
                            Beginning Balance x Annual Return ÷ Months in a Year = Monthly Earnings
                            <br />
                            $10,000 x 6% / 12 = $50 Monthly Earnings
                          </p>
                          <p>
                            Beginning Balance + Monthly Earnings = Next Month Beginning Balance
                            <br />
                            $10,000.00 + $50.00 = $10,050 Next Month Beginning Balance
                          </p>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.investBalance }>
                      <Table>
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning Investment Balance</td>
                            <td>$10,000.00</td>
                            <td>$10,050.00</td>
                            <td>$10,100.25</td>
                            <td>$10,150.75</td>
                            <td>$10,201.51</td>
                            <td>$10,252.51</td>
                          </tr>
                          <tr>
                            <td>Monthly Earnings</td>
                            <td>$50.00</td>
                            <td>$50.25</td>
                            <td>$50.50</td>
                            <td>$50.75</td>
                            <td>$51.01</td>
                            <td>$51.26</td>
                          </tr>
                          <tr>
                            <td>Ending Investment Balance</td>
                            <td>$10,050.00</td>
                            <td>$10,100.25</td>
                            <td>$10,150.75</td>
                            <td>$10,201.51</td>
                            <td>$10,252.51</td>
                            <td>$10,303.78</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classNames( classes.investBalance, classes.beginingBalance ) }>
                      <Table>
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>12</th>
                            <th>24</th>
                            <th>36</th>
                            <th>48</th>
                            <th>60</th>
                            <th>72</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning Investment Balance</td>
                            <td>$10,563.96</td>
                            <td>$11,215.52</td>
                            <td>$11,907.27</td>
                            <td>$12,641.68</td>
                            <td>$13,421.39</td>
                            <td>$14,249.20</td>
                          </tr>
                          <tr>
                            <td>Monthly Earnings</td>
                            <td>$52.82</td>
                            <td>$56.08</td>
                            <td>$59.54</td>
                            <td>$63.21</td>
                            <td>$67.11</td>
                            <td>$71.25</td>
                          </tr>
                          <tr>
                            <td>Ending Investment Balance</td>
                            <td>$10,616.78</td>
                            <td>$11,271.60</td>
                            <td>$11,966.81</td>
                            <td>$12,704.89</td>
                            <td>$13,488.50</td>
                            <td>$14,320.44</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.compoundSec }>
                      <div className={ classes.creditText }>
                        <p>How powerful is compounding interest? Check out the graphic below.</p>
                        <div className={ classes.powerImage }>
                          <HighchartsReact
                            highcharts={ Highcharts }
                            options={ {
                              chart: {
                                type: 'spline',
                                scrollablePlotArea: {
                                  width: 600,
                                  scrollPositionX: 0,
                                  height: 1000,
                                },
                              },
                              credits: {
                                enabled: false,
                              },
                              title: {
                                text: '',
                                enabled: false,
                              },
                              exporting: {
                                enabled: false,
                              },
                              xAxis: [{
                                tickInterval: 1,
                                categories: [2021, 2222, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060],
                                labels: {
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              }],
                              yAxis: [{ // Primary yAxis
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    if ( chart.value < 0 ) {
                                      return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                    }
                                    return formatDollar( Math.round( chart.value ) );
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              }],
                              tooltip: {
                                headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                                crosshairs: true,
                                shared: true,
                                valueDecimals: 0,
                                valuePrefix: '$',
                                style: {
                                  color: '#000000',
                                  fontSize: '13px',
                                },
                              },
                              plotOptions: {
                                spline: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  marker: {
                                    enabled: false,
                                  },

                                },
                              },
                              legend: {
                                itemStyle: {
                                  fontSize: '14px',
                                },
                              },
                              series: [{
                                name: 'Molly',
                                data: [6199, 12780, 19766, 27184, 35059, 43420, 52297, 61721, 71727, 82349, 93627, 105600, 118312, 131808, 146136, 161348, 177499, 194645, 212849, 232176, 252694, 274478, 297606, 322161, 348229, 375906, 405290, 436486, 469606, 504769, 542100, 581735, 623813, 668487, 715917, 766272, 819732, 876490, 936749, 1000724],
                                color: '#0069aa',
                              }, {
                                name: 'Mike',
                                data: [5579, 11502, 17790, 24466, 31553, 39078, 47067, 55549, 64554, 74114, 84264, 95040, 100902, 107126, 113733, 120748, 128195, 136102, 144497, 153409, 162871, 172916, 183581, 194904, 206925, 219688, 233238, 247624, 262897, 279111, 296326, 314603, 334007, 354608, 376480, 399700, 424353, 450526, 478313, 507814],
                                color: '#39b54a',
                              }, {
                                name: 'Mary',
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5579, 11502, 17790, 24466, 31553, 39078, 47067, 55549, 64554, 74114, 84264, 95040, 106481, 118627, 131523, 145214, 159749, 175180, 191564, 208958, 227425, 247031, 267846, 289945, 313407, 338316, 364761, 392837, 422645],
                                color: '#ff0000',
                              }],
                            } }
                          />

                          <div className={ classes.graphText }>
                            <div className={ classes.blueBox }>
                              <p>Molly saves $500 per month from age 21–60 and earns a 6% return.</p>
                              <p><b>Molly contributes a total of $240,000 and grows her savings to $1,000,724!</b></p>
                            </div>
                            <div className={ classes.greenBox }>
                              <p>Mike saves $450 per month from age 21–32 and then stops. He earns a 6% return. Mike contributes a total of $64,800 and grows his savings to $507,814!</p>
                              <p><b>Mary never catches up to Mike.</b></p>
                            </div>
                            <div className={ classes.redBox }>
                              <p>Mary saves $450 per month from age 32–60 and earns a 6% return.</p>
                              <p>Mary contributes a total of $156,600 and grows her savings to $422,645!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>As you can see in the example above, Mike contributes $90,000 less than Mary. But, because he starts saving earlier and capitalizes on compounding interest and time, Mary never catches up to Mike! The earlier you start saving, the better.</p>
                        <i>It&apos;s important to understand that money does not grow in a straight line and investments do not always earn a return. Some years you will lose money and other years you will make money.</i>
                        <p>
                          <b>Relationship between risk and reward</b>
                          <br />
                          A good investing guideline is the higher the risk, the higher the potential reward, and the less likely to achieve that reward. There are always going to be risks in investing. Are you risking a lot of money with a small chance of making any money in return? That would not be a good investment to make!
                        </p>
                        <p>Risk is the chance of you losing some or all your investment. Reward is the amount of money you expect to make on your investment. All investments require taking risk so before making any investment, make sure that you can handle the risk and that the risk-to-reward makes sense.</p>
                        <p>
                          <b>Risk ladder</b>
                          <br />
                          Below are common types of investments listed from least risky to most risky.
                        </p>
                      </div>
                    </div>
                    <div>
                      <ul className={ classes.riskUl }>
                        <div className={ classes.riskList }>
                          <span>LESS RISK</span>
                          <li>Cash and Savings Accounts</li>
                          <li>Certificates of Deposits (CDs)</li>
                        </div>
                        <div className={ classNames( classes.riskList, classes.listBonds ) }>
                          <li>Bonds</li>
                        </div>
                        <div className={ classNames( classes.riskList, classes.fundsList ) }>
                          <li>Mutual Funds</li>
                          <li>Exchange Traded Funds (ETFs)</li>
                        </div>
                        <div className={ classNames( classes.riskList, classes.stockList ) }>
                          <li>Stocks</li>
                        </div>
                        <div className={ classNames( classes.riskList, classes.investList ) }>
                          <span>MORE RISK</span>
                          <li>Alternative Investments</li>
                          <li>(Hedge Funds, Real Estate, etc.)</li>
                        </div>
                      </ul>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level7/investing-101/exercise" startTime={ startTime } level={ 7 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Investing101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Investing101 );
