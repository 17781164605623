import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, Dialog } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as sliderValues from '../../utilities/existingsliders';
import styles from '../../screens/pwi-existing/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../calculations/modules-services';
import downArrow from '../../assets/img/pwi-existing/weighted-interest-rate-arrow.png'

const FederalUnsubStudentLoans = ( props ) => {
  const {
    classes, handleDefinationPopup, handleAssestObject, handleUpdatedObject,
  } = props;

  const [repaymentValue, setRepaymentValue] = React.useState( 12 );
  const [termLoanvalue, setTermLoanValue] = React.useState( 120 );

    const [weightedAverageRate, setWeightedAverageRate] = React.useState([
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
  ])

  const [ previousWeightedAverageRate, setPreviousWeightedAverageRate] = React.useState([
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
    { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
  ])

  const [totalBalanceInterest, setTotalBalanceInterest] = React.useState( 0 );
  const [totalWeight, setTotalWeight] = React.useState(6.00);

  const [ptotalBalanceInterest, setPTotalBalanceInterest] = React.useState( 0 );
  const [ptotalWeight, setPTotalWeight] = React.useState(6.00);

  const [creditCardInterestRate, setCreditCardInterestRate] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState('');

  const handleCancel = () =>{
    setWeightedAverageRate(previousWeightedAverageRate);
    setTotalBalanceInterest(ptotalBalanceInterest);
    setTotalWeight(parseFloat(ptotalWeight));
    setCreditCardInterestRate(!creditCardInterestRate)
  }

    const handleChange = (e, index, field) => {
    let newArr = [...weightedAverageRate];
    newArr[index][field] = e.floatValue !== undefined ? e.floatValue : 0;
    
    let totalAmount = 0;
    let totalInterest = 0;
    let totalWeightv = 0;

    for (let i = 0; i < 6; i++) {
      totalAmount += newArr[i].fed_unsub_loan_amount;
      if(i === 5 ){
        for (let j = 0; j < 6; j++) {
          let fed_unsub_loan_amount = newArr[j].fed_unsub_loan_amount
          let interest = newArr[j].fed_unsub_interest_rate;

          let interestVal = 0;
          if(fed_unsub_loan_amount > 0){
            interestVal = ( fed_unsub_loan_amount / totalAmount)*100;
            newArr[j].total_balance_rate = interestVal;
            totalInterest = totalInterest + interestVal;
          }
          if(field === 'fed_unsub_interest_rate' && interest > 0){
            let weight = (interestVal * interest)/100;
            newArr[j].total_interest_rate = weight;
            totalWeightv += parseFloat(weight).toFixed( 2 );
          }
        }
      }
    }
    setTotalBalanceInterest(totalInterest)
    setTotalWeight(parseFloat(totalWeightv))
    setTotalBalanceInterest(totalAmount)
    setWeightedAverageRate([...newArr])
    
  }
  useEffect( () => {
    const inputValues = {...handleAssestObject};
    if ( handleAssestObject.fed_unsub_loan_amount !== undefined ) {
      setTotalBalanceInterest( handleAssestObject.fed_unsub_loan_amount );
    } else {
      inputValues['fed_unsub_loan_amount'] = 0;
    }
    if ( handleAssestObject.fed_unsub_interest_rate !== undefined ) {
      setTotalWeight( handleAssestObject.fed_unsub_interest_rate );
    } else {
      inputValues['fed_unsub_interest_rate'] = 5.00;
    }
    if ( handleAssestObject.fed_unsub_months !== undefined ) {
      setRepaymentValue( handleAssestObject.fed_unsub_months );
    } else {
      inputValues['fed_unsub_months'] = 12;
    }
    if ( handleAssestObject.fed_unsub_term !== undefined ) {
      setTermLoanValue( handleAssestObject.fed_unsub_term );
    } else {
      inputValues['fed_unsub_term'] = 120;
    }
    handleUpdatedObject(inputValues);

    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value !== undefined ? e.target.value : 0;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'fed_unsub_loan_amount': {
        setTotalBalanceInterest( newvalue );
        inputValues[field] = newvalue;
        break;
      }
      case 'fed_unsub_interest_rate': {
        setTotalWeight( newvalue > 100 ? 100 : newvalue );
        inputValues[field] = newvalue;
        break;
      }
      case 'fed_unsub_months': {
        setRepaymentValue( newvalue );
        inputValues[field] = newvalue;
        break;
      }
      case 'fed_unsub_term': {
        setTermLoanValue( newvalue );
        inputValues[field] = newvalue;
        break;
      }
      default:
        break;
    }
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (

    <div className={ classes.contentBlock }>
      <p>Next up, unsubsidized student loans. If you have one unsubsidized student loan, enter your details below.</p>
      <p>If you have more than one unsubsidized student loan with different interest rates, <span className={classes.clickHere} onClick={ ()=> {
        setCreditCardInterestRate(!creditCardInterestRate)
      }}>click here</span> so that we can calculate your <span class="border go_to_explained" uib-tooltip="Click for a definition." tooltip-placement="top" ng-click="getDefinations('w','Weighted Average Interest Rate','Weighted Average Interest Rate')">weighted average interest rate.</span> </p>
      <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ `${classes.sliderBottomSpace} ${classes.sliderBottomSpaceNewOne}` }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Existing Student Loan Balance' ) }>Current Loan Balance</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.loanArray() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 200 }
                  value={ totalBalanceInterest }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( totalBalanceInterest )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'fed_unsub_loan_amount', 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ totalBalanceInterest } onValueChange={ ( e ) => updateValue( e, '', 'fed_unsub_loan_amount', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.rateArray() }
                  min={ 0 }
                  max={ 15 }
                  step={ 0.05 }
                  value={ totalWeight }
                  valueLabelFormat={ `${commonFunctions.perFormatter( totalWeight )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'fed_unsub_interest_rate', 'slider' ) }
                />
              </div>
              <div className={ classNames( classes.annuvalIncomeGrowthInput, classes.relativePosition ) }>
                <font className={ classes.opcityZero }>$</font>
                <NumberFormat
                  customInput={ TextField }
                  value={ totalWeight }
                  decimalScale={isFocus!=='fed_unsub_interest_rate'?2:5}
                  fixedDecimalScale={isFocus!=='fed_unsub_interest_rate'}
                  onValueChange={ ( e ) => updateValue( e, '', 'fed_unsub_interest_rate', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_interest_rate') } }
                  onBlur={emptyIsFocus} />
                <span className={ classes.perSentSymbol }>%</span>
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Months Until Repayment Begins: Student Loans' ) }>Months Until Repayment Begins</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.repaymentArray() }
                  min={ 0 }
                  max={ 78 }
                  step={ 1 }
                  value={ repaymentValue }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( repaymentValue )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'fed_unsub_months', 'slider' ) }
                />
              </div>
              <div className={ classes.loanInput }>
                <span className={ classes.inputDollar }>&nbsp;</span>
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  value={ repaymentValue }
                  decimalScale={isFocus!=='fed_unsub_months'?2:5}
                  fixedDecimalScale={isFocus!=='fed_unsub_months'}
                  onValueChange={ ( e ) => updateValue( e, '', 'fed_unsub_months', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_months') } }
                  onBlur={emptyIsFocus} />
                <span className={ classes.inputPercent }>
                  {' '}
                  {' ' }
                  months
                </span>
              </div>
              <div className={ `${classes.newInput} ${classes.newInputRightNew}` }>
                <span>
                  {( repaymentValue / 12 ).toFixed( 2 )}
                  {' '}
                  years
                </span>
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }><span>Term of the Loan (in months)</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.termLoanArray() }
                  min={ 0 }
                  max={ 180 }
                  step={ 1 }
                  value={ termLoanvalue }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( termLoanvalue )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'fed_unsub_term', 'slider' ) }
                />
              </div>
              <div className={ classes.loanInput }>
                <span className={ classes.inputDollar }>&nbsp;</span>
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  value={ termLoanvalue }
                  decimalScale={isFocus!=='fed_unsub_term'?2:5}
                  fixedDecimalScale={isFocus!=='fed_unsub_term'}
                  onValueChange={ ( e ) => updateValue( e, '', 'fed_unsub_term', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_term') } }
                  onBlur={emptyIsFocus} />
                <span className={ classes.inputPercent }>
                  {' '}
                  months
                </span>
              </div>
              <div className={ `${classes.newInput} ${classes.newInputRightNew}` }>
                <span>
                  {( termLoanvalue / 12 ).toFixed( 2 )}
                  {' '}
                  years
                </span>
              </div>
            </div>
          </div>
          <div className={ classes.loanRow }>
            <div className={ classes.principalValue }>
              <div className={ classes.principleGraph }>
                <p><b> Unsubsidized Student Loans: Monthly Payment (Principal & Interest)</b></p>
              </div>
              <div className={ classes.principleInput }>
                <p>
                  <b>
                    {moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment } prefix={ moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansFedUnSubMonthlyPayment < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </p>
              </div>
            </div>
            <div className={ classes.principalValue }>
              <div className={ classes.principleGraph }>
                <p><b> Total Repayment Amount (Principal & Interest)</b></p>
              </div>
              <div className={ classes.principleInput }>
                <p>
                  <b>
                    {moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest } prefix={ moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="popup-model"
        open={creditCardInterestRate}
        onClose={handleCancel}
        aria-labelledby="note-dialog-title"
        aria-describedby="note-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCancel}>X</button>
            <h3>Federal Unsubsidized Student Loans: Weighted Average Interest Rate</h3>
          </div>
          <div className={classes.mobileScroll}>
            <div className={ `${classes.moduleBodyScroll} "model-body"` }>
              <div className={classes.moduleBody}>
                <div className={classes.BoxOne}>
                  <h4>&nbsp;</h4>
                </div>
                <div className={classes.BoxTwo}>
                  <h4>Loan Balance</h4>
                </div>
                <div className={classes.BoxThree}>
                  <h4>% of Total</h4>
                </div>
                <div className={classes.BoxFour}>
                  <h4>Interest Rate</h4>
                </div>
                <div className={classes.BoxFive}>
                  <h4>Weighted Interest Rate</h4>
                </div>
              </div>
              
              { 
                weightedAverageRate.map((l, index) => (
                  <div className={classes.moduleBody}>
                    <div className={classes.BoxOne}>
                      <label>Loan {index+1}</label>
                    </div>
                    <div className={classes.BoxTwo}>
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ l.fed_unsub_loan_amount } onValueChange={ ( e ) => handleChange( e, index, 'fed_unsub_loan_amount' ) } onFocus={ ( e ) => { e.target.select(); } }/>
                      <span className={classes.dollerCount}>$</span>
                    </div>
                    <div>
                      <label>{<NumberFormat decimalScale={ 2 } fixedDecimalScale displayType="text" allowNegative={ false } value={ l.total_balance_rate } prefix={ l.total_balance_rate < 0 && '(' } suffix={ l.total_balance_rate >= 0 ? '%' : '%)' } />}</label>
                    </div>
                    <div>
                      <NumberFormat
                        customInput={ TextField }
                        value={ l.fed_unsub_interest_rate }
                        decimalScale={isFocus!=='fed_unsub_interest_rate'?2:5}
                        fixedDecimalScale={isFocus!=='fed_unsub_interest_rate'}
                        onValueChange={ ( e ) => handleChange( e, index, 'fed_unsub_interest_rate' ) }
                        onFocus={ ( e ) => { e.target.select(); setIsFocus('fed_unsub_interest_rate') } }
                        onBlur={emptyIsFocus} />
                      <span className={classes.dollerPercenatge}>%</span>
                    </div>
                    <div>
                      <label>{<NumberFormat decimalScale={ 2 } fixedDecimalScale displayType="text" allowNegative={ false } value={ l.total_interest_rate } prefix={ l.total_interest_rate < 0 && '(' } suffix={ l.total_interest_rate >= 0 ? '%' : '%)' } />}</label>
                    </div>
                  </div>
                ))
              }
              <div className={ `${classes.moduleBody} ${classes.modelBodyFooter}` }>
                <div className={ `${classes.BoxOne} ${classes.BoxOneFooter}` }>
                  <label>Total</label>
                </div>
                <div className={ `${classes.BoxTwo} ${classes.textRight}` }>
                  <lable>
                    {totalBalanceInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalBalanceInterest } prefix={ totalBalanceInterest >= 0 ? '$' : '($' } suffix={ totalBalanceInterest < 0 && ')' } />
                    ) : ( '$0' )}
                  </lable>
                </div>
                <div className={classes.BoxThree}>
                  <label>
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } value={ 100.00 } suffix={'%'} />
                  </label>
                </div>
                <div className={ `${classes.BoxFour} ${classes.textRight}` }>
                  <label>--</label>
                </div>
                <div className={classes.BoxFive}>
                  <img src={downArrow} alt="downArrow" />
                </div>
              </div>
              <div className={classes.moduleBody}>
                <span>
                  <p>Weighted Average Interest Rate
                    <span>
                      {totalWeight !== undefined ? ( <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } value={ totalWeight } prefix={ totalWeight < 0 && '(' } suffix={ totalWeight >= 0 ? '%' : '%)' } />
                      ) : ( '0.00%' )}
                    </span></p>
                </span>
              </div>
            </div>
          </div>
          <div className={classes.buttonFooter}>
            <button className={classes.clearDatbtn} onClick={() => {
              setWeightedAverageRate([
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
                { fed_unsub_loan_amount: 0, total_balance_rate: 0, fed_unsub_interest_rate: 0,  total_interest_rate: 0,},
              ])
              setTotalBalanceInterest(handleAssestObject.fed_unsub_loan_amount);
              setTotalWeight(handleAssestObject.fed_unsub_interest_rate)
            }}>Clear Data</button>
            <button className={classes.okbtn} onClick={()=> {
              setPreviousWeightedAverageRate(weightedAverageRate);
              setPTotalBalanceInterest(totalBalanceInterest);
              setPTotalWeight(totalWeight);
              setCreditCardInterestRate(!creditCardInterestRate)}
            }>Ok</button>
            <button className={classes.cancelbtn} onClick ={handleCancel}>Cancel</button>
          </div>
        </div>
      </Dialog>

      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
     
    </div>
  );
};

FederalUnsubStudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( FederalUnsubStudentLoans );
