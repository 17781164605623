import curveGray from '../../assets/img/students-parents/curve-gray.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  pageBody:{
    '@media (min-width: 2000px)': {
      maxWidth: '2200px',
      margin: '0px auto',
    }
  },
  HeaderRow:{
    background: '#0069AA',
    textAlign: 'center',
    height: '95px',
    display: 'flex',
    alignItems: 'center',
    '& img':{
      width: '100%',
      maxWidth: '200px',
    }
  },
  parentSectionOne:{
    padding: '100px 0px 0px',
    '@media (max-width: 767px)': {
      padding: '50px 0px 0px',
    }
  },
  sectionMax:{
    maxWidth: '500px',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: 'initial',
    }
  },
  parentSectionOneLeft:{
    marginTop: '-10px',
    '@media (max-width: 767px)': {
      marginTop: '0px',
    },
    [theme.breakpoints.down( 'md' )]: {
      marginBottom: '30px',
    },
    '& h3':{
      fontFamily: '"MuseoSans-500"',
      fontSize: '36px',
      lineHeight: '45px',
      margin: '0px 0px 50px',
      color: '#014372',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '40px',
        margin: '0px 0px 30px',
      }
    },
    '& p':{
      fontSize: '20px',
      color: '#333333',
      marginBottom: '30px',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '18px',
        lineHeight: '26px',
      }
    }
  },
  clickBtn:{
    maxWidth: '430px',
    marginTop: '20px',
    '& a':{
      backgroundColor: '#84A84D',
      fontSize: '30px',
      textTransform: 'uppercase',
      color: '#fff',
      border: '0px',
      padding: '15px 20px',
      width: '100%',
      borderRadius: '10px',
      transition: 'all 0.5s ease',
      letterSpacing: '-1px',
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
      display: 'block',
      textAlign: 'center',
      '&:hover':{
        backgroundColor: '#f69a34',
        textDecoration: 'none',
      },
      '@media (max-width: 767px)': {
        width: 'calc(100% - 40px)',
        fontSize: '25px',
        '@media (max-width: 767px)': {
          fontSize: '20px',
        }
      },
      '& span':{
        display: 'block',
        fontSize: '25px',
        textTransform: 'initial',
      }
    },
    '& p':{
      fontSize: '18px',
      textAlign: 'left',
      fontStyle: 'italic',
      maxWidth: '550px',
      margin: '10px 0px 0px',
      color: '#333333',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      }
    }
  },
  textStrong:{
    fontFamily: '"MuseoSans-500"',
    fontWeight: 'normal',
  },
  videoBox:{
    '& iframe':{
      width: '100%',
      maxHeight: '375px',
      border: '0px',
    }
  },
  videoBoxBlue:{
    marginTop: '80px',
    padding: '24px',
    background: '#00539F',
    '@media (max-width: 767px)': {
      marginTop: '30px',
    },
    '& p':{
      margin: '0px',
      color: '#fff',
      fontSize: '24px',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }
    }
  },
  parentSectionTwo:{
    padding: '60px 0px',
    background: '#E6E6E6',
    textAlign: 'center',
    margin: '40px 0px 60px',
    '@media (max-width: 767px)': {
      padding: '50px 0px',
    }
  },
  inkwriryHelpText:{
    maxWidth: '700px',
    margin: '0px auto',
    '& h3':{
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
      fontSize: '36px',
      lineHeight: '45px',
      margin: '0px 0px 10px',
      color: '#014372',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '40px',
      }
    },
    '& p':{
      fontSize: '21px',
      color: '#333333',
      marginBottom: '0',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '18px',
        lineHeight: '26px',
      }
    }
  },
  parentSectionThree:{
    position: 'relative',
    zIndex: '9',
  },
  guideBox:{
    '& figure':{
      margin: '0px',
      '& img':{
        maxWidth: '200px',
        maxHeight: 'initial',
        objectFit: 'cover',
        width: '100%',
      }
    }
  },
  boxCnt:{
    '& h5':{
      fontSize: '22px',
      color: '#014372',
      margin: '20px 0px 10px',
      borderLeft: '3px solid #0069AA',
      paddingLeft: '15px',
      minHeight: '48px',
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
    },
    '& ul':{
      padding: '0px 0px 0px 40px',
      marginTop: '0',
      marginBottom: '10px',
      '& li':{
        fontSize: '21px',
        color: '#333333',
        paddingLeft: '0',
        fontFamily: '"MuseoSans-100"',
        fontWeight: 'normal',
        marginBottom: '5px',
        '@media (max-width: 767px)': {
          fontSize: '18px',
          lineHeight: '26px',
        }
      }
    },
    '& p':{
      paddingLeft: '25px',
      fontSize: '21px',
      color: '#333333',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      margin: '0 0 10px',
      '@media (max-width: 767px)': {
        fontSize: '18px',
        lineHeight: '26px',
      }
    }
  },
  parentSectionFour:{
    padding: '30% 0px 50px',
    marginTop: '-320px',
    textAlign: 'center',
    position: 'relative',
    backgroundImage: `url(${curveGray})`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 959px)': {
      padding: '35% 0px 50px',
      marginTop: '0',
      backgroundPosition: 'top',
    },
    '@media (min-width: 960px) and (max-width: 1023px)': {
      padding: '40% 0px 50px',
    },
    '&::before':{
      content: '""',
      position: 'absolute',
      background: '#e6e6e6',
      width: '100%',
      height: '20%',
      left: '0px',
      bottom: '0px',
      '@media (max-width: 479px)': {
        height: '75%',
      },
      '@media (min-width: 480px) and (max-width: 599px)': {
        height: '68%',
      },
      '@media (min-width: 600px) and (max-width: 959px)': {
        height: '60%',
      },
      '@media (min-width: 960px) and (max-width: 1059px)': {
        height: '22%',
      },
    },
    '@media (min-width: 1200px) and (max-width: 1299px)': {
      padding: '35% 0px 50px',
    },
    '@media (min-width: 1024px) and (max-width: 1199px)': {
      padding: '45% 0px 50px',
      marginTop: '-355px',
    }
  },
  oneWordRow:{
    top: '-100px',
    marginLeft: '32%',
    position: 'relative',
    width: '75%',
    '@media (min-width: 960px) and (max-width: 1023px)': {
      top: '0px',
      marginLeft: '20%',
    },
    '@media (min-width: 1024px) and (max-width: 1299px)': {
      marginLeft: '25%',
    },
    '@media (max-width: 959px)': {
      top: '0px',
      marginLeft: '0%',
      width: '100%',
      paddingBottom: '50px',
    },
    '& h3':{
      fontSize: '36px',
      lineHeight: '45px',
      color: '#014372',
      margin: '0px 0px 50px',
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '40px',
      }
    }
  },
  oneWordBox:{
    '& h4':{
      fontSize: '36px',
      lineHeight: '45px',
      color: '#014372',
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '40px',
      }
    },
    '& p':{
      fontSize: '21px',
      color: '#014372',
      margin: '10px 0px 0px',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      '@media (max-width: 767px)': {
        fontSize: '18px',
        lineHeight: '26px',
      }
    }
  },
  footerRow:{
    zIndex: '9',
    position: 'relative',
    background: '#252525',
    padding: '2% 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '5% 0',
      textAlign: 'center',
    }
  },
  footerLeft:{
    margin: '0px',
    '& img':{
      width: '100%',
      maxWidth: '200px',
    }
  },
  footerRight:{
    fontSize: '18px',
    color: '#fff',
    textAlign: 'right',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      textAlign: 'center',
    },
    '& span':{
      opacity: '0.6',
    },
    '& a':{
      textDecoration: 'underline',
      cursor: 'pointer',
      opacity: '0.6',
      color: '#fff',
      '&:hover':{
        opacity: '1',
      }
    }
  },
  footerRightCol:{
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'end',
  }

} );

export default styles;
