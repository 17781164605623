import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';
import carrerPicture from '../../../assets/img/career-path/picture1_1_careerpath.png';
import carrerPictureNew from '../../../assets/img/career-path/picture2_1_careerpath.png';

const ChoosePath = ( props ) => {
  const {
    classes, handleDoubleClickRole, handleCareerObject,handleUpdatedObject, setRole, role,
  } = props;

  useEffect(()=>{
    const updatedObj = { ...handleCareerObject };
    if(handleCareerObject.start_year !== undefined){
    if(handleCareerObject.start_year !== new Date().getFullYear()){
      setRole('futureMove');
      updatedObj.role = 'futureMove';
      handleUpdatedObject( updatedObj );
    }
    }
    // eslint-disable-next-line
  },[]);

  const handleRole = ( type ) => {
    const updatedObj = { ...handleCareerObject };
    setRole( type );
    updatedObj.role = type;
    handleUpdatedObject( updatedObj );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        The
        {' '}
        {' '}
        <font>Career Advancement</font>
        {' '}
        module will illuminate your path into the future.
        Will this path financially support your goals?
        What is your best path to the future? Get your details into
        the Career Path module and find answers to these tough questions and many, many more.
        Let&apos;s build a financial plan for your career.
      </p>
      <p>
        Are you entering details about your current role or your new career move?
        Select current role if you are already working in your career.
      </p>
     


      <div className={ `${`${classes.fourIconsBlock} ${classes.fourIconsBlockNewThree}`}` }>
        <ul>
          <li className={ ( handleCareerObject.role === 'currentRole' || role === 'currentRole' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'currentRole' ) } onDoubleClick={ () => handleDoubleClickRole( 'currentRole' ) }>
            <figure>
              <img src={ carrerPicture } alt="" />
            </figure>
            <span className={ classes.colorBlue }>Current Role</span>
          </li>
          <li className={ ( handleCareerObject.role === 'futureMove' || role === 'futureMove' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'futureMove' ) } onDoubleClick={ () => handleDoubleClickRole( 'futureMove' ) }>
            <img src={ carrerPictureNew } alt="" />
            <span>Future Career Move</span>
          </li>

        </ul>
      </div>
    </div>

  );
};

ChoosePath.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDoubleClickRole: PropTypes.func.isRequired,
  setRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,

};

export default withStyles( styles )( ChoosePath );
