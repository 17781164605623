import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const FitOutCosts = (props) => {
  const { classes, inputData, updateData } = props;

  const [fitoutCost, setFitoutCost] = React.useState({});
  const [percentOfFitOut, setPercentOfFitOut] = React.useState(0);

  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;

  useEffect(()=>{
    let fittingcost = {};
    fittingcost['fitting_furniture'] = 0;
    fittingcost['fitting_window_treatments'] = 0;
    fittingcost['fitting_landscaping'] = 0;
    fittingcost['fitting_home_improvements'] = 0;
    fittingcost['fitting_other'] = inputData.external_and_internal_fit_out_costs;
    // fittingcost['external_and_internal_fit_out_costs'] = 0;
    setFitoutCost(fittingcost);
    // eslint-disable-next-line
  },[])

  useEffect( () => {
    let totalFitClost = 0;
    const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;

    if ( fitoutCost.fitting_furniture !== undefined ) {
      totalFitClost += fitoutCost.fitting_furniture;
    }
    if ( fitoutCost.fitting_window_treatments !== undefined ) {
      totalFitClost += fitoutCost.fitting_window_treatments;
    }
    if ( fitoutCost.fitting_landscaping !== undefined ) {
      totalFitClost += fitoutCost.fitting_landscaping;
    }
    if ( fitoutCost.fitting_home_improvements !== undefined ) {
      totalFitClost += fitoutCost.fitting_home_improvements;
    }
    if ( fitoutCost.fitting_other !== undefined ) {
      totalFitClost += fitoutCost.fitting_other;
    }

    updateData( 'total_obj', {...inputData,...fitoutCost,...{external_and_internal_fit_out_costs:totalFitClost}} );

    let percent = 0;
    if ( purchasePrice > 0 ) {
      percent = ( totalFitClost / purchasePrice ) * 100;
    }
      setPercentOfFitOut( percent );

    // eslint-disable-next-line
},[fitoutCost])

  const updateValue = (e, field) => {

    let newvalue = e.target.value;
    if (newvalue === '') {
      newvalue = 0;
    }
    newvalue = convertCurrencyToInteger(newvalue);

    const closing = { ...fitoutCost };
    closing[field] = newvalue;
    setFitoutCost(closing);
  };

  return (
    <div className={classes.contentBlock}>
      <p>When you buy an investment property, there are other upfront costs needed to make that property livable. You might need to furnish the property for renters, blinds for the windows, landscaping, and other property improvements such as new appliances in the kitchen or new floors.</p>
      <p>Fit out costs can vary depending on if you buy new or used real estate. You want to make the property livable without stressing your financial resources. Make sure you have the cash and investments to support these additional costs.</p>
      <div className={classNames(classes.closingCosts, classes.fitoutCosts)}>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Fit Out Costs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Furniture
              </td>
              <td>
                $
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={fitoutCost.fitting_furniture !== undefined ? fitoutCost.fitting_furniture : 0} onChange={(e) => updateValue(e, 'fitting_furniture')} onFocus={(e) => e.target.select()} />
              </td>
            </tr>
            <tr>
              <td>
                Window Treatments
              </td>
              <td>
                $
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={fitoutCost.fitting_window_treatments !== undefined ? fitoutCost.fitting_window_treatments : 0} onChange={(e) => updateValue(e, 'fitting_window_treatments')} onFocus={(e) => e.target.select()} />
              </td>
            </tr>
            <tr>
              <td>
                Landscaping
              </td>
              <td>
                $
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={fitoutCost.fitting_landscaping !== undefined ? fitoutCost.fitting_landscaping : 0} onChange={(e) => updateValue(e, 'fitting_landscaping')} onFocus={(e) => e.target.select()} />
              </td>
            </tr>
            <tr>
              <td>
                Home Improvements
              </td>
              <td>
                $
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={fitoutCost.fitting_home_improvements !== undefined ? fitoutCost.fitting_home_improvements : 0} onChange={(e) => updateValue(e, 'fitting_home_improvements')} onFocus={(e) => e.target.select()} />
              </td>
            </tr>
            <tr>
              <td>
                Other
              </td>
              <td>
                $
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={fitoutCost.fitting_other !== undefined ? fitoutCost.fitting_other : 0} onChange={(e) => updateValue(e, 'fitting_other')} onFocus={(e) => e.target.select()} />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total Fit Out Costs</th>
              <th><NumberFormat decimalScale={0} fixedDecimalScale value={inputData.external_and_internal_fit_out_costs} displayType="text" thousandSeparator prefix="$" /></th>
            </tr>
            <tr>
              <th><i>% of Purchase Price</i></th>
              <th>
                <i>
                  {parseFloat(percentOfFitOut).toFixed(2)}
                  %
                </i>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <ReactTooltip id="tooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
    </div>
  );
};

FitOutCosts.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles(styles)(FitOutCosts);
