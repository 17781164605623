import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import carIcon from '../../assets/img/pwi-car/buying_a_car.png';
import resetIcon from '../../assets/img/pwi-car/circle-arrow.png';
import infoIcon from '../../assets/img/pwi-car/info-icon.svg';
import * as sliderValues from '../../utilities/existingsliders';
import * as excelLibrary from '../../utilities/excelLibrary';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';
import ValueLabelComponent, { CarValueLabelComponent, PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const CarArray = [
  {
    value: 0,

    scaledValue: 0,
    label: '$0',
  },
  {
    value: 20000,
    scaledValue: 20000,
    label: '$20k',
  },
  {
    value: 40000,
    scaledValue: 40000,
    label: '$40k',
  },
  {
    value: 60000,
    scaledValue: 60000,
    label: '$60k',
  },
  {
    value: 80000,
    scaledValue: 80000,
    label: '$80k',
  },
  {
    value: 100000,
    scaledValue: 100000,
    label: '$100k',
  },
  {
    value: 120000,
    scaledValue: 120000,
    label: '$120k',
  },
  {
    value: 140000,
    scaledValue: 140000,
    label: '$140k',
  },
];

const AffordabilityCalculator = (props) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject,
  } = props;
  const [imageSliderValue, setImageSliderValue] = React.useState(0);
  const [interestRate, setInterestRate] = React.useState(0);
  const [incomeValue, setIncomeValue] = React.useState(0);
  const [affordValues, setAffordValues] = React.useState( {} );
  const [isFocus, setIsFocus] = React.useState('');

  const getAffordableCalcs = ()=> {
    var affordableValues = {};
    affordableValues['Property Value'] = imageSliderValue;

    //New Calcs
    affordableValues['Down Payment'] = (imageSliderValue / 100) * 20;

    //Closing Costs
    affordableValues['Sales Tax'] = (imageSliderValue / 100) * 5;

    //Registration & Documentation Fees
    affordableValues['Registration Fees'] = 500;

    //Cash Needed at Purchase
    affordableValues['Total Cash Needed'] = affordableValues['Down Payment'] + affordableValues['Sales Tax'] + affordableValues['Registration Fees'];

    //% of Home Value
    affordableValues['Of Car Value'] = (affordableValues['Total Cash Needed'] / imageSliderValue) * 100;

    //Mortgage
    affordableValues['Mortgage'] = imageSliderValue - affordableValues['Down Payment'];

    //Interest Rate
    affordableValues['Interest Rate'] = parseFloat(interestRate).toFixed(2);

    //Term of the Mortgage
    affordableValues['Term of the Mortgage'] = 4;


    affordableValues['Monthly Car Loan Payment'] = excelLibrary.PMT((parseFloat(affordableValues['Interest Rate']) / 100) / 12, affordableValues['Term of the Mortgage'] * 12, -(affordableValues['Mortgage']), 0, 0);

    //Property Taxes
    affordableValues['Maintenance'] = 100;
    affordableValues['Car Insurance'] = 150;
    affordableValues['Gas'] = 100;
    //Total Monthly Home Payment (PITI)
    affordableValues['Total Monthly Car Expenses'] = affordableValues['Monthly Car Loan Payment'] +
      affordableValues['Maintenance'] +
      affordableValues['Car Insurance'] +
      affordableValues['Gas'];
    //Debt-to-Income
    affordableValues['Debt-to-Income'] = parseFloat(incomeValue).toFixed(2);

    //Monthly
    affordableValues['Monthly Income Needed'] = (affordableValues['Total Monthly Car Expenses'] / affordableValues['Debt-to-Income']) * 100;
    setAffordValues( affordableValues );
  }

  useEffect(() => {
    const affordableDetails = { ...handleBuyingCarObject };
    if (handleBuyingCarObject.base_price !== undefined) {
      affordableDetails.car_image_value = handleBuyingCarObject.base_price;
      setImageSliderValue(handleBuyingCarObject.base_price);
    }else if (handleBuyingCarObject.car_image_value !== undefined) {
      affordableDetails.car_image_value = handleBuyingCarObject.car_image_value;
      setImageSliderValue(handleBuyingCarObject.car_image_value);
    }else {
      affordableDetails.car_image_value = 40000;
      setImageSliderValue(40000);
    }
    if (handleBuyingCarObject.aff_interest_rate === undefined) {
      affordableDetails.aff_interest_rate = 5;
      setInterestRate(5); 
    }
    else {
      setInterestRate(handleBuyingCarObject.aff_interest_rate);
    }
    if (handleBuyingCarObject.income_value === undefined) {
      affordableDetails.income_value = 10;
      setIncomeValue(10);
    }
    else {
      setIncomeValue(handleBuyingCarObject.income_value);
    }
    handleUpdatedObject(affordableDetails);
    getAffordableCalcs();
    // eslint-disable-next-line
  }, []);

  const updateValue = (e, value, field, type) => {
    const affordabilityCal = { ...handleBuyingCarObject };

    let newvalue = 0;
    if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    affordabilityCal[field] = newvalue;
    switch (field) {
      case 'car_image_value': {
        setImageSliderValue(newvalue === undefined ? 0 : newvalue);
        break;
      }
      case 'aff_interest_rate': {
        const updatedValue = newvalue === undefined ? 0 : newvalue;
        setInterestRate(parseFloat(updatedValue) > 100 ? 100 : updatedValue);
        break;
      }
      case 'income_value': {
        const updatedValue = newvalue === undefined ? 0 : newvalue;
        setIncomeValue(parseFloat(updatedValue) > 100 ? 100 : updatedValue);
        break;
      }
      default: {
        break;
      }
    }
    handleUpdatedObject(affordabilityCal);
    getAffordableCalcs();
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <div>
        <p>Knowing how much car you can afford is the first step to becoming a car owner. To buy a car, you&apos;ll need:</p>
        <ul className={classes.downPayment}>
          <li>Cash for the down payment, sales tax and other fees</li>
          <li>Income to support the loan payment and other monthly expenses</li>
        </ul>
        <p>Quickly see what&apos;s needed to buy a car by adjusting the car value below. The tables will estimate how much cash and income are needed to afford the vehicle.</p>
      </div>
      <div className={classes.anuuvalGroupGraphScroll}>
        <div className={classes.anuuvalGroupGraph}>
          <div className={classes.AnnualIncomeGrowth}>
            <div>
              <p><span><img src={carIcon} alt="car" /></span></p>
              <div className={classes.annuvalIncomeGrowthGraph}>
                <PwiSlider
                  aria-labelledby="discrete-slider-custom"
                  ValueLabelComponent={CarValueLabelComponent}
                  valueLabelDisplay="on"
                  marks={CarArray}
                  min={0}
                  max={140000}
                  step={100}
                  value={imageSliderValue}
                  valueLabelFormat={`Car Value $${new Intl.NumberFormat('en-IN').format(imageSliderValue)}`}
                  onChange={(e, value) => { updateValue(e, value, 'car_image_value', 'slider'); }}
                />
              </div>
              <div className={classes.annuvalIncomeGrowthInput}>
                $
                <NumberFormat
                customInput={TextField}
                thousandSeparator
                value={imageSliderValue === '' ? 0 : imageSliderValue}
                decimalScale={0}
                fixedDecimalScale
                onValueChange={(e) => updateValue(e, '', 'car_image_value', 'number')}
                onFocus={(e) => { e.target.select(); }} />
              </div>
            </div>
          </div>
          <div className={classes.AnnualIncomeGrowth}>
            <div>
              <p><span>Interest Rate</span></p>
              <div className={classes.annuvalIncomeGrowthGraph}>
                <PwiSlider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={sliderValues.interestCarRate()}
                  min={0}
                  max={10.5}
                  step={0.1}
                  value={interestRate}
                  valueLabelFormat={percentFormatWithTwoDecimal}
                  onChange={(e, value) => { updateValue(e, value, 'aff_interest_rate', 'slider'); }}
                />
              </div>
              <div className={classes.annuvalIncomeGrowthInput}>                
                <NumberFormat
                  customInput={TextField}
                  decimalScale={isFocus!=='aff_interest_rate'?2:5}
                  fixedDecimalScale={isFocus!=='aff_interest_rate'}
                  thousandSeparator
                  value={interestRate}
                  onValueChange={(e) => updateValue(e, '', 'aff_interest_rate', 'number')}
                  onFocus={(e) => { e.target.select(); setIsFocus('aff_interest_rate'); }}
                  onBlur={emptyIsFocus}
                  suffix='%'/>
                
                <span className={classes.resetIcon}>
                  {' '}
                  <img src={resetIcon} alt="" aria-hidden="true" onClick={() => { setInterestRate(5.00); }} data-for="definationTitle" data-tip="Reset to default values." />
                </span>
              </div>
            </div>
          </div>
          <div className={classes.AnnualIncomeGrowth}>
            <div>
              <p><span>% of Income</span></p>
              <div className={classes.annuvalIncomeGrowthGraph}>
                <PwiSlider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={sliderValues.interestCarIncome()}
                  min={0}
                  max={14}
                  step={0.1}
                  value={incomeValue}
                  valueLabelFormat={percentFormatWithTwoDecimal}
                  onChange={(e, value) => { updateValue(e, value, 'income_value', 'slider'); }}
                /> 
              </div>
              <div className={classes.annuvalIncomeGrowthInput}>                
                <NumberFormat
                  customInput={TextField}
                  decimalScale={isFocus!=='income_value'?2:5}
                  fixedDecimalScale={isFocus!=='income_value'}
                  thousandSeparator
                  value={incomeValue}
                  onValueChange={(e) => updateValue(e, '', 'income_value', 'number')}
                  onFocus={(e) => { e.target.select(); setIsFocus('income_value') }}
                  onBlur={emptyIsFocus}
                  suffix='%'/>
                <span className={classes.resetIcon}>
                  {' '}
                  <img src={resetIcon} alt="" aria-hidden="true" onClick={() => { setIncomeValue(10.00); }} data-for="definationTitle" data-tip="Reset to default values." />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.purchaseTable}>
        <div className={classNames(classes.cashTable, classes.firstTable)}>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Cash Needed to Purchase</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Down payments on a car typically range from 10-20% of the purchase price. This calculator assumes 20% as an estimate. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span className={classes.dottedText} data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={() => handleDefinationPopup('Car Down Payment: Buying')}>Down Payment</span></td>
                <td>
                  {affordValues['Down Payment'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Down Payment']} prefix ='$'/>
                  ) : ('$0')}
                </td>
              </tr>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Sales tax varies by state and ranges from 3-7% typically. This calculator assumes 5% as an estimate. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span className={classes.dottedText} data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={() => handleDefinationPopup('Sales Tax')}>Sales Tax</span></td>
                <td>
                  {affordValues['Sales Tax'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Sales Tax']} prefix ='$'/>
                  ) : ('$0')}
                </td>
              </tr>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Registration fees are charged by the state to register your car. Documentation fees are charged by the car dealer to complete and process your paperwork. These fees can vary from $0 to $1,000. This calculator assumes $500 as an estimate. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span className={classes.dottedText} data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={() => handleDefinationPopup('Documentation & Registration Fees: Buying a Car')}>Registration & Documentation Fees</span></td>
                <td>
                
                {affordValues['Registration Fees'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Registration Fees']} prefix ='$'/>
                  ) : ('$0')}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td><b>Total Cash Needed</b></td>
                <td>
                  <b>
                    {affordValues['Total Cash Needed'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Total Cash Needed']} prefix={'$'} />
                    ) : ('$0')}
                  </b>
                </td>
              </tr>
              <tr>
                <td />
                <td className={classes.smallLeftpadding}><b><i>% of Car Value</i></b></td>
                <td>
                  <b>
                    <i>
                      {affordValues['Of Car Value'] !== undefined ? (
                        <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={affordValues['Of Car Value']} suffix={'%'} />
                      ) : ('0.00%')}
                    </i>
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={classNames(classes.cashTable, classes.homeTable)}>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Income Needed</th>
                <th>Monthly</th>
                <th>Annual</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Car value less down payment equals loan value. This calculator assumes a 5 year, fixed rate car loan with a 5% interest rate. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td>Car Loan Payment (P&I)</td>
                <td> {affordValues['Monthly Car Loan Payment'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Monthly Car Loan Payment']} prefix={'$'} />
                    ) : ('$0')}</td>
                <td> {affordValues['Monthly Car Loan Payment'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Monthly Car Loan Payment'] * 12} prefix={'$'} />
                    ) : ('$0')}</td>
              </tr>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Owning a car requires constant maintenance from changing tires, detailing and washing, and regular service visits. This calculator assumes $100 per month in maintenance. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span className={classes.dottedText} data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={() => handleDefinationPopup('Car Maintenance')}>Maintenance</span></td>
                <td> {affordValues['Maintenance'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Maintenance']} prefix={'$'} />
                    ) : ('$0')}</td>
                <td> {affordValues['Maintenance'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Maintenance'] * 12} prefix={'$'} />
                    ) : ('$0')}</td>
              </tr>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Your state mandates car insurance in case of accidents and injuries. Insurance can range from $100 to a few hundred dollars a month. This calculator assumes $150 per month in car insurance. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span className={classes.dottedText} data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={() => handleDefinationPopup('Car Insurance')}>Car Insurance</span></td>
                <td> {affordValues['Car Insurance'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Car Insurance']} prefix={'$'} />
                    ) : ('$0')}</td>
                <td> {affordValues['Car Insurance'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Car Insurance'] * 12} prefix={'$'} />
                    ) : ('$0')}</td>
              </tr>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip="Gas expense varies depending on your lifestyle and location as gas costs on the west coast are different from the northeast. This calculator assumes $100 per month to be conservative. As you continue in the Buying a Car module, you can change this input." /></span></td>
                <td><span>Gas</span></td>

                <td> {affordValues['Gas'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Gas']} prefix={'$'} />
                    ) : ('$0')}</td>
                <td> {affordValues['Gas'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Gas'] * 12} prefix={'$'} />
                    ) : ('$0')}</td>
              </tr>
              <tr className={classes.boldText}>
                <td />
                <td><span>Total Monthly Car Expenses</span></td>
                <td> {affordValues['Total Monthly Car Expenses'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Total Monthly Car Expenses']} prefix={'$'} />
                    ) : ('$0')}</td>
                <td> {affordValues['Total Monthly Car Expenses'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Total Monthly Car Expenses'] * 12} prefix={'$'} />
                    ) : ('$0')}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><span className={classes.infoIcon}><img src={infoIcon} alt="info" data-for="definationTitle" data-tip=" As a planning benchmark, your monthly car expenses should be around 10% or less of your before-tax income. To make sure you can comfortably support buying a car while also paying for your other living expenses, other debt, saving, and investing, try and keep your car expenses less than 10% of your income." /></span></td>
                <td className={classes.smallLeftpadding}><i>% of Income</i></td>
                <td>
                  <i>
                    {affordValues['Debt-to-Income'] !== undefined ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={affordValues['Debt-to-Income']} prefix={affordValues['Debt-to-Income'] >= 0 ? '' : '('} suffix={affordValues['Debt-to-Income'] < 0 ? '%)' : '%'} />
                    ) : ('0%')}
                  </i>
                </td>
                <td>
                  <i>
                    {affordValues['Debt-to-Income'] !== undefined ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={affordValues['Debt-to-Income']} prefix={affordValues['Debt-to-Income'] >= 0 ? '' : '('} suffix={affordValues['Debt-to-Income'] < 0 ? '%)' : '%'} />
                    ) : ('0%')}
                  </i>
                </td>
              </tr>
              <tr>
                <td />
                <td><b>Income Needed</b></td>
                <td> 
                  <b>{affordValues['Monthly Income Needed'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Monthly Income Needed']} prefix={'$'} />
                    ) : ('$0')}
                    </b></td>
                <td>
                  <b>{affordValues['Monthly Income Needed'] !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={affordValues['Monthly Income Needed'] * 12} prefix={'$'} />
                    ) : ('$0')}
                    </b>
                    </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
    </div>
  );
};

AffordabilityCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles(styles)(AffordabilityCalculator);
