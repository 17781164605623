import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import { confirmAlert } from 'react-confirm-alert';
import styles from './styles';
import PwiSave from '../../components/pwi-save/pwi-save';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/family/vacation-slider1.jpg';
import sliderImageThree from '../../assets/img/family/vacation-slider2.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as moduleServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import SummaryDocs from '../../components/documents/documents';
import { defaultValues } from '../../calculations/default-module-values';
import alertIcon from '../../assets/img/alert_icon.png';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';

import scrollToComponent from 'react-scroll-to-component';


import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
//import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import IconBar from './IconBar';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

const VacationWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupHeaderText] = useState(false);
  const [updatedVacationValues, setUpdatedVacationValues] = useState(defaultValues.module11);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [yearRange, setYearRange] = React.useState([]);
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');

  useEffect(() => {
    setWalkThruNo(13);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module11' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const loadData = data;
          if (loadData.purchase_item !== undefined && loadData.purchase_item) {
            loadData.other_acquisition1 = loadData.purchase_item;
          } else {
            loadData.other_acquisition1 = 'Special Purchase 1';
          }
          if (loadData.second_purchase_item !== undefined && loadData.second_purchase_item) {
            loadData.other_acquisition2 = loadData.second_purchase_item;
          } else {
            loadData.other_acquisition2 = 'Special Purchase 2';
          }
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setUpdatedVacationValues(loadData);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedVacationValues(defaultValues.module11);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(()=>{
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module11' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }     
  },[loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(()=>{     
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module11' ) {
      dropdownvalues.savedNotes = true;    
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 }); 
    }
  },[myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedVacationValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      valuesUpdated[field] = e.value;
      if (field === 'start_year' || field === 'end_year') {
        if (valuesUpdated.start_year > 0) {
          if (valuesUpdated.end_year > 0 && valuesUpdated.start_year > valuesUpdated.end_year) {
            valuesUpdated.end_year = valuesUpdated.start_year;
          }
        } else {
          valuesUpdated.end_year = 0;
        }
      } else if (field === 'second_start_year' || field === 'second_end_year') {
        if (valuesUpdated.second_start_year > 0) {
          if (valuesUpdated.second_end_year > 0 && valuesUpdated.second_start_year > valuesUpdated.second_end_year) {
            valuesUpdated.second_end_year = valuesUpdated.second_start_year;
          }
        } else {
          valuesUpdated.second_end_year = 0;
        }
      } else if (field === 'third_start_year' || field === 'third_end_year') {
        if (valuesUpdated.third_start_year > 0) {
          if (valuesUpdated.third_end_year > 0 && valuesUpdated.third_start_year > valuesUpdated.third_end_year) {
            valuesUpdated.third_end_year = valuesUpdated.third_start_year;
          }
        } else {
          valuesUpdated.third_end_year = 0;
        }
      }
    }
    setUpdatedVacationValues(valuesUpdated);
    moduleServices.setModule11Data(valuesUpdated);
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      dropValues.showMe = !dropdownvalues.showMe;
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top'});
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module11';
    objectParams.moduleNumber = 'module11Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };


  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module11";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module11', 'quick-save', activeScenarioData);
      finalObject['module11Data'] = pwiObject.getfilteredVacationObject(moduleServices.module11Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }


  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -30, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -400, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -730, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet} >
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 11 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.vacationSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Vacation + Special Purchases
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your vacation + special purchases details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen, classes.buttonGreenNew)}>ENTER MY START YEAR</span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Vacation + Special Purchases
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Plan for Your Dreams
                              </Typography>
                              <ul>
                                <li>Plan for the types of vacation you envision</li>
                                <li>Budget for regular vacations, as well as special trips and travel</li>
                                <li>Is there something special that you must have?</li>
                                <li>Enter your desired purchase and start working toward it now.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>Get Started</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Vacation + Special Purchases
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Check Your Spaces
                              </Typography>
                              <ul>
                                <li>Consider your priorities for vacation and travel</li>
                                <li>Will you allocate resources to a hobby or recreational activity?</li>
                                <li>Do your personal or professional priorities require an investment in gear or equipment?</li>
                                <li>Balance your investment with other priorities.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>SEE MY SPACES</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <section className={classes.fiContentSection} id="slide-one">
          <Container className={classes.container}>
            <div className={classes.fiRow} id="slide-five">
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module11" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/vacation" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.vactionDetails) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes = {classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Vacation</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedVacationValues.start_year, value: updatedVacationValues.start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect to begin taking vacations. Every year between the Start and End Year, the FC will grow your Annual Cost by inflation. See when you can start taking vacations while balancing your savings by checking the Cash Flow Statement.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'end_year', 'select'); }}
                                              options={yearRange}
                                              defaultValue={updatedVacationValues.end_year}
                                              value={{ label: updatedVacationValues.end_year, value: updatedVacationValues.end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to stop taking vacations. We hope this year will be the last year in your 5, 10, 15 year plan, but, if that's not the case, then input the year you plan to stop vacationing.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Cost</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} decimalScale={0} fixedDecimalScale allowNegative={false} thousandSeparator customInput={TextField} value={updatedVacationValues.yearly_cost} onValueChange={(e) => updateValue(e, 'yearly_cost', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Maybe you take 1, 2, or even 3 vacations in a year. Think about weekend trips to the beach or mountain trips in the winter or that week off from work in the Caribbean. Input the total cost of taking those vacations in the year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  {updatedVacationValues.purchase_item !== '' ? updatedVacationValues.purchase_item : 'Special Purchase 1'}
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Special Purchase</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} value={updatedVacationValues.purchase_item} onChange={(e) => updateValue(e, 'purchase_item', 'input')} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="We all have things that we like to buy each year - expensive jewelry, watches, dresses, or suits. Plan for those expenses here and input the name of that item. Planning for these expensive items makes you more likely to achieve them.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>

                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_start_year', 'select'); }}
                                              options={yearRange}
                                              defaultValue={updatedVacationValues.second_start_year}
                                              value={{ label: updatedVacationValues.second_start_year, value: updatedVacationValues.second_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What year you want to plan for this item or recurring purchase is important. Will this purchase put financial strain on me? When is the appropriate time I can afford this? Use the Cash Flow Statement and different years to judge affordability.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_end_year', 'select'); }}
                                              options={yearRange}
                                              defaultValue={updatedVacationValues.second_end_year}
                                              value={{ label: updatedVacationValues.second_end_year, value: updatedVacationValues.second_end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Will you be buying this item every year such as a country club membership? Or is this a one-time purchase like buying a modern piano? If a one-time purchase, make the End Year the same as the Start Year. If not, set the timeline with the End Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Cost</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedVacationValues.second_yearly_cost} onValueChange={(e) => updateValue(e, 'second_yearly_cost', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Please specify what you expect to spend each year on one or multiple luxurious items.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  {updatedVacationValues.second_purchase_item !== '' ? updatedVacationValues.second_purchase_item : 'Special Purchase 2'}
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Special Purchase</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} value={updatedVacationValues.second_purchase_item} onChange={(e) => updateValue(e, 'second_purchase_item', 'input')} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Maybe there's one thing that we have always wanted such as a beautiful handbag or a luxurious watch, plan for that expense here and input the name of that item. Planning for these expensive items makes you more likely to achieve them.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'third_start_year', 'select'); }}
                                              options={yearRange}
                                              defaultValue={updatedVacationValues.third_start_year}
                                              value={{ label: updatedVacationValues.third_start_year, value: updatedVacationValues.third_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What year you want to plan for this item or recurring purchase is important. Will this purchase put financial strain on me? When is the appropriate time I can afford this? Use the Cash Flow Statement and different years to judge affordability.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'third_end_year', 'select'); }}
                                              options={yearRange}
                                              defaultValue={updatedVacationValues.third_end_year}
                                              value={{ label: updatedVacationValues.third_end_year, value: updatedVacationValues.third_end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Will you be buying this item every year such as a country club membership? Or is this a one-time purchase like buying a modern piano? If a one-time purchase, make the End Year the same as the Start Year. If not, set the timeline with the End Year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Cost</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedVacationValues.third_yearly_cost} onValueChange={(e) => updateValue(e, 'third_yearly_cost', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Please specify what you expect to spend each year on one or multiple luxurious items.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Vacation + Special Purchases Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Amount Spent</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={6}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module11Data.vacationTotalAmountSpent !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module11Data.vacationTotalAmountSpent} prefix={moduleServices.module11Data.vacationTotalAmountSpent >= 0 ? '$' : '($'} suffix={moduleServices.module11Data.vacationTotalAmountSpent < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>

            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} >

              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader} id="slide-six">
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Vacation
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC forecast Vacation and Purchase Items?</h3>
                          <p>
                            The FC treats Vacation and Purchase Items just like your other expenses. If you plan on spending $2,000 on Vacation in year 2024 and Annual Inflation is 3%, your Vacation will be $2,060 in year 2025. The FC will grow your Vacation and Purchase Items for the period that you indicate.
                          </p>

                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Purchase Item</h3>
                          <p>A special purchase item such as a fancy suit, jewelry, season tickets to your favorite sports team, or whatever special and lucrative item that you purchase annually.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from &quot;de Finibus Bonorum et Malorum&quot; by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.careerAdvancementSavedScenareos) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={ ( compareHoverActive && !dropdownvalues.compareSavedNotes ) ? classNames( classes.sectionHeader, classes.activeHover ) : classes.sectionHeader }>
                <div className={ classes.sectionTitle }>
                  <h1 className={slidecount === 5 ?  classes.opacityZero  : ''}>
                    <span aria-hidden="true" onClick={ () => { handleToggle( 'compareSavedNotes' ); } } onMouseEnter={ () => { setCompareHoverActive( true ); } } onMouseLeave={ () => { setCompareHoverActive( false ); } }>
                      <i className={ dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus' } />
                      <strong className={ ( compareHoverActive && !dropdownvalues.compareSavedNotes ) ? classNames( classes.showText, classes.activeHide ) : classes.showText }>Compare Saved Scenarios</strong>
                      <strong className={ ( compareHoverActive && !dropdownvalues.compareSavedNotes ) ? classNames( classes.hideText, classes.activeShow ) : classes.hideText }>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Vacation" moduleSelected="module11" getModuleData={getModuleData} setUpdatedScenario={setUpdatedVacationValues} />
              )}
              {/* {dropdownvalues.compareSavedNotes && (
                <div className={ classes.sectionContent }>
                  <div className={ classes.panelRow }>
                    <div className={ classes.panelInfo }>
                      <div className={ classes.panelHeading }>
                        <div className={ classes.rowBlock }>
                          <div className={ classes.scenarioNotes }>
                            <div className={ classes.notesLeft }>
                              <ul className={ classes.listUnstyled }>
                                <li />
                              </ul>
                            </div>
                            <div className={ classNames( classes.notesRight, classes.scenarioNotesRight ) }>
                              <ul className={ classes.listUnstyled }>
                                <li><Button className={ classNames( classes.loadButton, classes.btnWarning ) }>Load</Button></li>
                                <li><Button className={ classNames( classes.saveButton, classes.btnWarning ) }>Save</Button></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classNames( classes.panelBody, classes.scenarioBlock ) }>
                        <Grid container>
                          <Grid item sm={ 3 } className={ classes.gridPadding } />
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario A</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario B</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Differences</h6>
                            <div>
                              <select>
                                <option value="Scenario A - Scenario B">Scenario A - Scenario B</option>
                                <option value="Scenario B - Scenario A">Scenario B - Scenario A</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>
        {/* <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.editPopup}
          >
            <DialogContent className={classes.videoPopup}>
              <div className={classes.modalHeader}>
                <h3>{popupHeaderText}</h3>
              </div>
              <div className={classes.modalBody}>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Housing
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Food
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Transportation
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Clothing
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Health Care
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Child Care and Education
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.moduleRowNew}>
                  <Grid container>
                    <Grid item sm={12} xs={12} className={classes.inputBlock}>
                      <div className={classes.inputGroup}>
                        <Grid container spacing={4}>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classes.insideSpan}>
                              <div className={classes.spanText}>
                                Other
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={6} className={classes.noSpace}>
                            <div className={classNames(classes.inputRight, classes.fullInput)}>
                              <NumberFormat className={classes.modalInput} allowNegative={false} customInput={TextField} value={3680} onFocus={(e) => e.target.select()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <h3>Annual Cost of Raising a Child: $12,794</h3>
                <div>
                  <Button className={classNames(classes.resetButton, classes.footerButton)}>Reset to default values</Button>
                  <Button className={classNames(classes.okButton, classes.footerButton)}>Ok</Button>
                  <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { setPopupOpen(false); }}>Cancel</Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div> */}

        <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.SavePopup}
          >
            <DialogContent className={classes.videoPopup}>

              <div>
                {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedVacationValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg= {SetNotesSuccessMsg}/>)}

              </div>

              <div />
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module11' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={ classes.modelPopupRow}
      >
        <DialogContent className={ classes.modelPopup}>
          <div className={ classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={ classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={ classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

VacationWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(VacationWorksheet);
