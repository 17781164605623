import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import bannerImage from '../../assets/img/students-parents/students_parents_banner_left.png';
import pricingImg from '../../assets/img/students-parents/mega-menu-pricing_img.png';
import awarenessChallenge from '../../assets/img/students-parents/students_parents_awareness_challenge.png';
import discoveryChallenge from '../../assets/img/students-parents/students_parents_discovery_challenge.png';
import iconSquare from '../../assets/img/students-parents/PF101-icon-square.png';
import careerSketching from '../../assets/img/students-parents/students_parents_career_sketching.png';
import financeExplained from '../../assets/img/students-parents/students_parents_finance_explained.png';

// import CareerImageSeven from '../../assets/img/home_page/see_and_build_trns_img_7.png';
// import CareerImageEight from '../../assets/img/home_page/see_and_build_trns_img_8.png';

const StudentParent = ( props ) => {
  const { classes, history } = props;

  const redirectToHigherEducation = () => {
    history.push( '/plan-with-inkwiry/he' );
  };

  const redirectToStudent = () => {
    history.push( '/why-inkwiry/students' );
  };

  const redirectToParent = () => {    
    history.push( '/why-inkwiry/parents' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.benfitsBody }>
        <div className={ classes.benfitsBanner }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } className={ classes.justyfyCenter }>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <div className={ classes.bannerLeft }>
                  <img src={ bannerImage } alt="Parents Students Banner" />
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                <div className={ classes.bannerRight }>
                  <Typography variant="h1" component="h1">In 15 minutes, get a plan to pay for higher education and manage your student loan debt.</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.planInkwirySec }>
          <div className={ classes.container }>
            <div className={ classes.planInkwiryRow }>
              <div className={ classes.whyInkwryTitle }>
                <img src={ pricingImg } alt="students_parents_plan_img" />
                <Typography variant="h2" component="h2">Plan with Inkwiry</Typography>
                <Typography variant="h3" component="h3">You have time to prepare, but there&apos;s no time to lose.</Typography>
              </div>
              <div className={ classes.whyInkwrySeeif }>
                <Typography variant="h2" component="h2">See if your assumptions work.</Typography>
                <Typography variant="h3" component="h3">Know exactly where your money is going.</Typography>
                <Button onClick={ redirectToHigherEducation }>
                  BUILD MY PLAN
                  <i className="las la-greater-than" />
                </Button>
              </div>
              <ul className={ classes.whyInkwrySeeifList }>
                <li>INCOME</li>
                <li>EXPENSES</li>
                <li>LOANS</li>
                <li>BUDGET</li>
                <li>SAVINGS</li>
                <li>INVESTMENTS</li>
              </ul>
            </div>
            <div className={ classes.whyInkwryPowertools }>
              <Typography variant="h3" component="h3">Power Tools to accelerate your planning</Typography>
              <div className={ classes.powertoolsRow }>
                <ul>
                  <li>
                    <img src={ awarenessChallenge } alt="Awareness Challenge" />
                    <span>Awareness Challenge</span>
                  </li>
                  <li>
                    <img src={ discoveryChallenge } alt="Discovery Challenge" />
                    <span>Discovery Challenge</span>
                  </li>
                  <li>
                    <img src={ iconSquare } alt="PowerUp Courses" />
                    <span>PowerUp Courses</span>
                  </li>
                  <li>
                    <img src={ careerSketching } alt="Career Sketching" />
                    <span>Career Sketching</span>
                  </li>
                  <li>
                    <img src={ financeExplained } alt="Finance Explained" />
                    <span>Finance Explained</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={ classes.whyInkwryTogether }>
              <Typography variant="h2" component="h2">Together, we can find solutions.</Typography>
              <Typography variant="h3" component="h3">Take a deeper dive into how Inkwiry can help you plan:</Typography>
              <Button className={ classes.studentBtn } onClick={ redirectToStudent }>
                Inkwiry for Students
                {' '}
                {'>'}
              </Button>
              <Button className={ classes.parentstBtn } onClick={ redirectToParent }>
                Inkwiry for Parents
                {' '}
                {' '}
                {'>'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

StudentParent.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( StudentParent );
