import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Flipper } from 'react-flip-toolkit';
import Navbar from './Navbar';
import NavbarItem from './Navbar/NavbarItem';
import DropdownContainer from './DropdownContainer';
import CompanyDropdown from './DropdownContents/CompanyDropdown';
import DevelopersDropdown from './DropdownContents/DevelopersDropdown';
import ProductsDropdown from './DropdownContents/ProductsDropdown';
import NewsDropdown from './DropdownContents/NewsDropdown';
import PricingDropdown from './DropdownContents/PricingDropdown';
import ContactDropdown from './DropdownContents/ContactDropdown';

const navbarConfig = [
  { title: 'Apps + Tools', dropdown: ProductsDropdown },
  { title: 'About', dropdown: DevelopersDropdown },
  { title: 'Why Inkwiry', dropdown: CompanyDropdown },
  { title: 'News', dropdown: NewsDropdown },
  { title: 'Pricing', dropdown: PricingDropdown },
  { title: 'Contact', dropdown: ContactDropdown },
];

const styles = () => ( {

} );

class AnimatedNavbar extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      activeIndices: [],
    };
    this.onMouseEnter = this.onMouseEnter.bind( this );
    this.onMouseLeave = this.onMouseLeave.bind( this );
    this.resetDropdownState = this.resetDropdownState.bind( this );
  }

  componentWillUnmount() {
    if ( document.getElementById( 'menu_overlay' ) ) {
      const menuOverlay = document.getElementById( 'menu_overlay' );
      menuOverlay.remove();
    }
  }

  onMouseEnter( i ) {
    if ( !document.getElementById( 'menu_overlay' ) ) {
      window.document.body.insertAdjacentHTML( 'afterbegin', '<div id="menu_overlay" class="menu_overlay"> </div>' );
    }
    if ( this.animatingOutTimeout ) {
      clearTimeout( this.animatingOutTimeout );
      this.resetDropdownState( i );
      return;
    }
    if ( this.state.activeIndices[this.state.activeIndices.length - 1] === i ) { return; }

    this.setState( ( prevState ) => ( {
      activeIndices: prevState.activeIndices.concat( i ),
      animatingOut: false,
    } ) );
  }

  onMouseLeave() {
    if ( document.getElementById( 'menu_overlay' ) ) {
      const menuOverlay = document.getElementById( 'menu_overlay' );
      menuOverlay.remove();
    }
    this.setState( {
      animatingOut: true,
    } );
    this.animatingOutTimeout = setTimeout(
      this.resetDropdownState,
      this.props.duration,
    );
  }

  resetDropdownState( i ) {
    this.setState( {
      activeIndices: typeof i === 'number' ? [i] : [],
      animatingOut: false,
    } );
    delete this.animatingOutTimeout;
  }

  render() {
    const { duration, loginData} = this.props;
    let CurrentDropdown;
    let PrevDropdown;
    let direction;

    const currentIndex = this.state.activeIndices[
      this.state.activeIndices.length - 1
    ];
    const prevIndex = this.state.activeIndices.length > 1
      && this.state.activeIndices[this.state.activeIndices.length - 2];

    if ( typeof currentIndex === 'number' ) { CurrentDropdown = navbarConfig[currentIndex].dropdown; }
    if ( typeof prevIndex === 'number' ) {
      PrevDropdown = navbarConfig[prevIndex].dropdown;
      direction = currentIndex > prevIndex ? 'right' : 'left';
    }

    return (
      <Flipper
        flipKey={ currentIndex }
        spring={ duration === 300 ? 'noWobble' : { stiffness: 10, damping: 10 } }
      >
        <Navbar onMouseLeave={ this.onMouseLeave }>
          {navbarConfig.map( ( n, index ) => (
            <NavbarItem
              key={ n.title }
              title={ n.title }
              index={ index }
              onMouseEnter={ this.onMouseEnter }
            >
              {currentIndex === index && (
              <DropdownContainer
                direction={ direction }
                animatingOut={ this.state.animatingOut }
                duration={ duration }
              >
                <CurrentDropdown loginData={ loginData } setOpen={this.props.setOpen}/>
                {PrevDropdown && <PrevDropdown />}
              </DropdownContainer>
              )}
            </NavbarItem>
          ) )}
        </Navbar>
      </Flipper>
    );
  }
}

AnimatedNavbar.propTypes = {
  // classes: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( AnimatedNavbar );
