import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import classNames from 'classnames';
// import NumberFormat from 'react-number-format';
import styles from '../styles';
import Component from './UI/component';
import data from './constants/career-advancement.js'

const CompareCareerAdvancementPath = ( props ) => {
  const { classes, scenarioOne, scenarioTwo } = props;
  return (
    <div>
      { 
        data.map(parent => (
          <Component parent={parent} scenarioTwo={ scenarioTwo}  scenarioOne={ scenarioOne } classes= {classes }/>
        ))
      }
    </div>
  );
};

CompareCareerAdvancementPath.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired,
};

export default withStyles( styles )( CompareCareerAdvancementPath );
