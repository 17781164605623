import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { confirmAlert } from 'react-confirm-alert';
import { renderIcons, formatDollar } from '../../../utilities/chartCommonFunctions';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import Rating from '../../../components/common/rating';
import discoveryHighchartsData from '../../../utilities/discoveryHighchartsData';
import principalRepayment from '../../../assets/img/discovery/principal_repayment.png';
import creditScore from '../../../assets/img/discovery/credit_score.png';
import netWorth from '../../../assets/img/discovery/net_worth.png';
import creditcardsDiscovery from '../../../assets/img/discovery/creditcards_discovery.png';
import ruleofInvestments from '../../../assets/img/discovery/rule_of_investments.png';
import writingCheck1 from '../../../assets/img/discovery/writing_check1.png';
import writeCheckAns from '../../../assets/img/discovery/write_check_ans.png';
import feedbackIcon from '../../../assets/img/awareness/feedback-icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pageloader from '../../../components/ui/pageloader';

const SAVE_QUESTIONS = loader( '../../../graphql/schema/discovery/save-challenge.graphql' );
const GET_QUESTIONS = loader( '../../../graphql/schema/discovery/get-challenge.graphql' );
const REDO_QUESTIONS = loader( '../../../graphql/schema/discovery/redo-challenge.graphql' );

/* eslint react/prop-types: 0 */

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
HighchartsMore( Highcharts );
SolidGauge( Highcharts );

const Discovery = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  let scoreValSection = useRef( null );
  let ratingValSection = useRef( null );

  const correctAns = ['d', 'a', 'c', 'd', 'c', 'a', 'a', 'b', 'd', 'c', 'a', 'd', 'd', 'c'];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 13; i += 1 ) {
    const questionsObject = {
      qId: i + 1, answer: '', correctAns: correctAns[i], rating: 0, scroll: defaultReference, status: '',
    };
    questionMainAns.push( questionsObject );
  }

  questionMainAns[6].sub = {};
  questionMainAns[6].sub[1] = { answer: '', correctAns: 'check_number' };
  questionMainAns[6].sub[2] = { answer: '', correctAns: 'today_date' };
  questionMainAns[6].sub[3] = { answer: '', correctAns: 'person_paying' };
  questionMainAns[6].sub[4] = { answer: '', correctAns: 'amount' };
  questionMainAns[6].sub[5] = { answer: '', correctAns: 'amount_words' };
  questionMainAns[6].sub[6] = { answer: '', correctAns: 'reason' };
  questionMainAns[6].sub[7] = { answer: '', correctAns: 'signature' };
  questionMainAns[6].sub[8] = { answer: '', correctAns: 'routing' };
  questionMainAns[6].sub[9] = { answer: '', correctAns: 'account_number' };
  questionMainAns[6].sub.check = false;

  const question7options = [{ name: 'Your signature', val: 'signature' },
    { name: 'Amount written in numbers', val: 'amount' },
    { name: 'Check number', val: 'check_number' },
    { name: 'Amount written in words', val: 'amount_words' },
    { name: 'Today’s date', val: 'today_date' },
    { name: 'Reason for check (not required)', val: 'reason' },
    { name: 'Bank account number', val: 'account_number' },
    { name: 'Person or entity you are paying', val: 'person_paying' },
    { name: 'Routing number', val: 'routing' }];

  let quesionSection = useRef( null );
  let feedBackSection = useRef( null );

  const [name, setName] = React.useState( '' );
  const [age, setAge] = React.useState( '' );
  const [validation, setValidation] = React.useState( false );
  const [awarenessId, setAwarenessId] = React.useState( '' );
  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [myScoreContent, setMyscoreContent] = React.useState( false );
  const [questionContent, setQuestionContent] = React.useState( false );
  const [avgScore, setAvgScore] = React.useState( 0 );
  const [avgRating, setAvgRating] = React.useState( 0 );
  const [loading, setLoading] = React.useState( true );

  const [saveQuestion] = useMutation( SAVE_QUESTIONS, {
    onCompleted( response ) {
      if ( response.discoveryChallengeQuestion.status === true ) {
        setAwarenessId( response.discoveryChallengeQuestion.discoveryId );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const [redoQuestions] = useMutation( REDO_QUESTIONS, {
    onCompleted( response ) {
      if ( response.redoDiscoveryChallengeQuestion.status === true ) {
        setQuestions( questionMainAns );
        setMyscoreContent( false );
        setAwarenessId( '' );
        setLoading( false );
      }
    },
    onError( ) {
      setLoading( false );
    },
  } );

  useQuery( GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( response.getDiscoveryQuestion.status === true ) {
        setAge( response.getDiscoveryQuestion.age );
        setName( response.getDiscoveryQuestion.name );
        setAwarenessId( response.getDiscoveryQuestion.discoveryId );
      }
      let updateQuestions = [...questions];
      const questionsData = response.getDiscoveryQuestion.questions;
      if ( questionsData.length > 0 ) {
        updateQuestions = updateQuestions.map( ( question ) => {
          const data = question;
          const questionIndex = _.findIndex( questionsData, { question_id: data.qId } );
          if ( questionIndex >= 0 ) {
            data.answer = questionsData[questionIndex].answer;
            data.rating = questionsData[questionIndex].rating;

            if ( data.qId === 7 ) {
              data.sub = JSON.parse( questionsData[questionIndex].data );
            }

            if ( data.answer === data.correctAns ) {
              data.status = 'C';
            } else {
              data.status = 'W';
            }
          }
          return data;
        } );
        setQuestions( updateQuestions );
      }
      setLoading( false );
    },
    onError( ) {
      setLoading( false );
    },
  } );

  useEffect( () => {
    let answerQuestions = _.reject( questions, ['answer', ''] ).length;
    answerQuestions += _.reject( questions, ['rating', 0] ).length;
    let progressBar = answerQuestions * ( 100 / 28 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
  }, [questions] );

  useEffect( () => {
    if ( questionContent ) {
      scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } );
    }
  }, [questionContent] );

  useEffect( () => {
    if ( myScoreContent ) {
      scrollToComponent( feedBackSection, { offset: -56, align: 'top', duration: 500 } );
    }
  }, [myScoreContent] );

  const handleAgeChange = ( event ) => {
    setAge( event.target.value );
  };

  const handleNameChange = ( event ) => {
    setName( event.target.value );
  };

  const startChallenge = () => {
    setValidation( true );
    if ( name !== '' && age !== '' ) {
      setQuestionContent( true );
      if ( questionContent ) {
        scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } );
      }

      if ( awarenessId === '' ) {
        saveQuestion( {
          variables: {
            data: {
              name,
              age,
              type: 'details',
              discovery_analytics_id: awarenessId,
            },
          },
        } );
      }
    }
  };

  const redoChallengeDetails = ( ) => {
    if ( awarenessId !== '' ) {
      setLoading( true );
      redoQuestions( {
        variables: {
          id: awarenessId,
        },
      } );
    }
  };

  const redoChallenge = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Redo Challenge</h3>
            <p>Are you sure you want to redo the Discovery Challenge? This will clear all your answers, reset the feedback and start fresh.</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => {
                redoChallengeDetails();
                onClose();
              } }
            >
              YES
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    setQuestions( newQuesions );

    saveQuestion( {
      variables: {
        data: {
          name,
          age,
          type: 'answer',
          question_id: index + 1,
          answer: event.target.value,
          question_status: newQuesions[index].status,
          discovery_analytics_id: awarenessId,
        },
      },
    } );
  };

  const handleCheckAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[6].sub[index].answer = event.target.value;
    let subAns = Object.values( newQuesions[6].sub );
    subAns = _.filter( subAns, { answer: '' } );
    if ( subAns.length === 0 ) {
      newQuesions[6].sub.check = true;
    }
    if ( newQuesions[6].sub[index].answer === newQuesions[6].sub[index].correctAns ) {
      newQuesions[6].sub[index].status = 'C';
    } else {
      newQuesions[6].sub[index].status = 'W';
    }
    setQuestions( newQuesions );
  };

  const check7thAnswer = () => {
    const newQuesions = [...questions];
    const subAns = Object.values( newQuesions[6].sub );
    const ansQuesions = _.filter( subAns, { answer: '' } );
    if ( ansQuesions.length === 0 ) {
      const subCorrectAns = _.filter( subAns, { status: 'C' } );
      if ( subCorrectAns.length === 9 ) {
        newQuesions[6].answer = 'a';
      } else {
        newQuesions[6].answer = 'b';
      }
      newQuesions[6].status = questions[6].correctAns === newQuesions[6].answer ? 'C' : 'W';

      saveQuestion( {
        variables: {
          data: {
            name,
            age,
            type: 'answer',
            question_id: 7,
            answer: newQuesions[6].answer,
            question_status: newQuesions[6].status,
            data: JSON.stringify( newQuesions[6].sub ),
            discovery_analytics_id: awarenessId,
          },
        },
      } );
    }
    setQuestions( newQuesions );
  };

  const handleRating = ( index, rating ) => {
    const newQuesions = [...questions];
    newQuesions[index].rating = rating;
    setQuestions( newQuesions );

    saveQuestion( {
      variables: {
        data: {
          type: 'rating',
          question_id: index + 1,
          rating,
          discovery_analytics_id: awarenessId,
        },
      },
    } );
  };

  const handleMyScore = () => {
    let unAnswerQuestions = _.filter( questions, ['answer', ''] );
    unAnswerQuestions = _.filter( questions, ['rating', 0] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'qId' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      setMyscoreContent( true );
      const totalCorrectAns = _.filter( questions, ['status', 'C'] ).length;
      setAvgScore( totalCorrectAns * ( 100 / 14 ) );
      setAvgRating( _.meanBy( questions, 'rating' ) );
      if ( myScoreContent ) {
        scrollToComponent( feedBackSection, { offset: -56, align: 'top', duration: 500 } );
      }

      saveQuestion( {
        variables: {
          data: {
            type: 'score',
            score: totalCorrectAns * ( 100 / 14 ),
            avg_rating: _.meanBy( questions, 'rating' ),
            discovery_analytics_id: awarenessId,
          },
        },
      } );
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport2 = ( el ) => {
    let element = el;
    if ( element !== null && element.offsetTop !== undefined ) {
      let top = element.offsetTop;
      let left = element.offsetLeft;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      while ( element.offsetParent ) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top < ( window.pageYOffset + window.innerHeight )
        && left < ( window.pageXOffset + window.innerWidth )
        && ( top + height ) > window.pageYOffset
        && ( left + width ) > window.pageXOffset
      );
    }
    return false;
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( questionContent ) {
        let feedBackSectionCondition = true;
        if ( feedBackSection.current === undefined ) {
          if ( feedBackSection.getBoundingClientRect().top < ( window.innerHeight / 2 ) ) {
            feedBackSectionCondition = false;
          }
        }

        if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 && feedBackSectionCondition === true ) {
          document.getElementById( 'awareness_title' ).style.position = 'fixed';
          document.getElementById( 'awareness_title_sub' ).style.display = 'block';
        } else {
          document.getElementById( 'awareness_title' ).style.position = '';
          document.getElementById( 'awareness_title_sub' ).style.display = 'none';
        }

        if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 && feedBackSectionCondition === true ) {
          document.getElementById( 'sticky_numbers' ).style.left = '30px';
        } else {
          document.getElementById( 'sticky_numbers' ).style.left = '-11em';
        }

        for ( let i = 0; i < 14; i += 1 ) {
          const element = $( questionMainAns[i].scroll );
          let subElement = false;
          if ( questions[i].answer !== '' ) {
            subElement = true;
          }

          if ( ( elementInViewport( element ) === true || elementInViewport( element.find( '>div:first-child' ).find( 'select' ) ) )
            || ( subElement === true && ( elementInViewport( element.find( '>div:first-child' ) ) || elementInViewport( element.find( '>div:last-child' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'h3' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h2' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'div' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'ul' ) ) ) ) ) {
            element.css( 'opacity', '1' );
            element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
            $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
            if ( i <= 6 ) {
              $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
            } else {
              $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
            }
            break;
          }
        }

        if ( ( myScoreContent === true && elementInViewport2( feedBackSection ) ) || elementInViewport2( document.getElementById( 'footer' ) )
          || ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
          document.getElementById( 'progress-bar' ).style.display = 'none';
        } else {
          document.getElementById( 'progress-bar' ).style.display = 'block';
        }
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.awarenessBody }>
        <div className={ classes.awarenessBannerSec }>
          <div className={ classes.container }>
            <div className={ classes.bannerContent }>
              <Typography variant="h2" component="h2">Discovery Challenge</Typography>
            </div>
          </div>
        </div>
        <div className={ classes.awarenessContentRow }>
          <div className={ classes.container }>
            <div className={ classes.awarenessTitle }>
              <Typography variant="h2" component="h2">
                Test Your Financial Knowledge
              </Typography>
              <p>
                How well do you know personal finance? Put your knowledge to the test in the Discovery Challenge.
              </p>
            </div>
            <div className={ classes.awarenessForm }>
              <Typography variant="h1" component="h1">What&apos;s your first name?*</Typography>
              <form className={ classes.awernessForm } noValidate autoComplete="off">
                <div className={ classes.awareFormInput }>
                  <input className={ classes.awareInput } value={ name } onChange={ ( e ) => handleNameChange( e ) } />
                </div>
                {validation === true && name === '' ? ( <p className={ classes.errorText }>Please enter your first name</p> ) : ''}
                <div className={ classes.ageRange }>
                  <p className={ classes.ageRangeTitle }>What&apos;s your age range?*</p>
                  <ul className={ `${classes.optionList} ${classes.awernesssOptionList}` }>
                    <li>
                      <p>
                        <label className={ classNames( classes.spanRadio, age === '15years' && classes.ageChecked ) } htmlFor="15years">
                          15 or under
                          <input type="radio" name="age" value="15years" id="15years" onChange={ ( e ) => handleAgeChange( e, '15years' ) } checked={ age === '15years' } />
                        </label>
                      </p>
                    </li>
                    <li>
                      <p>
                        <label htmlFor="16to18" className={ classNames( classes.spanRadio, age === '16-18' && classes.ageChecked ) }>
                          16 - 18
                          <input type="radio" id="16to18" name="age" value="16-18" onChange={ ( e ) => handleAgeChange( e, '16-18' ) } checked={ age === '16-18' } />
                        </label>
                      </p>
                    </li>
                    <li>
                      <p>

                        <label htmlFor="19to24" className={ classNames( classes.spanRadio, age === '19-24' && classes.ageChecked ) }>
                          19 - 24
                          <input type="radio" id="19to24" name="age" value="19-24" onChange={ ( e ) => handleAgeChange( e, '19-24' ) } checked={ age === '19-24' } />
                        </label>
                      </p>
                    </li>
                    <li>
                      <p>
                        <label htmlFor="25to32" className={ classNames( classes.spanRadio, age === '25-32' && classes.ageChecked ) }>
                          25 - 32
                          <input type="radio" id="25to32" name="age" value="25-32" onChange={ ( e ) => handleAgeChange( e, '25-32' ) } checked={ age === '25-32' } />
                        </label>
                      </p>
                    </li>
                    <li>
                      <p>
                        <label htmlFor="33plus" className={ classNames( classes.spanRadio, age === '33+' && classes.ageChecked ) }>
                          33+
                          <input type="radio" id="33plus" name="age" value="33+" onChange={ ( e ) => handleAgeChange( e, '33+' ) } checked={ age === '33+' } />
                        </label>
                      </p>
                    </li>
                  </ul>
                  {validation === true && age === '' ? ( <p className={ classes.errorText }>Please select your age range</p> ) : ''}
                </div>
                <div className={ classes.scBtn }>
                  <Button onClick={ () => startChallenge() } className={ classNames( classes.resumeChallenge, progress > 0 && progress < 100 && classes.startChallenge, progress === 100 && classes.finishChallenge ) }>
                    {progress === 0 && (
                      <span>
                        START THE CHALLENGE
                      </span>
                    )}
                    {progress > 0 && progress < 100 && (
                      <span>
                        RESUME THE CHALLENGE
                      </span>
                    )}
                    {progress === 100 && (
                      <span>
                        REVIEW YOUR FEEDBACK
                      </span>
                    )}
                  </Button>
                  {awarenessId !== '' && questionContent === true && ( <span role="button" aria-hidden="true" onClick={ () => redoChallenge() } className={ classes.redoAwarness }>REDO</span> )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {questionContent === true && (
          <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
            <div className={ classes.ChallengesQuizHead } id="awareness_title">
              <div className={ classes.container }>
                <Typography variant="h3" component="h3">INKWIRY DISCOVERY CHALLENGE</Typography>
              </div>
            </div>

            <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
              <div className={ classes.container }>
                <Typography variant="h3" component="h3">&nbsp;</Typography>
              </div>
            </div>

            <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
              <ul>
                {
                  [1, 2, 3, 4, 5, 6, 7].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].rating !== 0 && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].rating !== 0 && questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
              </ul>
              <ul>
                {
                  [8, 9, 10, 11, 12, 13, 14].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].rating !== 0 && questions[value - 1].answer === questions[value - 1].correctAns ) ? classes.listNumRight : ( ( questions[value - 1].rating !== 0 && questions[value - 1].answer !== '' ) && classes.listNumWrong ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
              </ul>
              <div className={ classes.stickyBottom }>
                <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                  <span>
                    <i
                      className="las la-arrow-up"
                      data-for="returnStart"
                      data-tip="Return to start"
                    />
                  </span>
                  <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
                </Button>
              </div>
              {
              progress === 100 && (
                <div className={ classes.feedbackImg }>
                  <Button onClick={ handleMyScore } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <figure>
                        <img
                          src={ feedbackIcon }
                          alt="Feedback"
                          data-for="returnFeedback"
                          data-tip="Go to Feedback"
                        />
                        <ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={ `${classes.tooltipInfo} ${classes.feedbackTooltip}` } />
                      </figure>
                    </span>
                  </Button>
                </div>
              )
            }
            </div>
            <div className={ classes.questionRow }>
              <div className={ classes.container }>
                <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>1</span>
                    <p className={ classes.questionTitle }>Successful businesses, organizations, entrepreneurs, professional athletes, famous artists and military generals all have one thing in common: a strategy and a plan that works for them. Why do successful people develop strategies and make plans?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[0].answer } onChange={ ( e ) => handleAnswer( e, 0 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Developing a strategy and a plan provides a clear roadmap to achieving their goals" disabled={ questions[0].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Developing a strategy and a plan allows them to ask, &quot;What if I do this or that?&quot; and see what might happen in the future" disabled={ questions[0].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="Having a strategy and a plan better prepares them for the future" disabled={ questions[0].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[0].answer !== '' && classes.ansRight ) } value="d" control={ <Radio checked={ questions[0].answer !== '' } /> } label="All of the above" disabled={ questions[0].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {
                  questions[0].answer !== ''
                    && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, questions[0].answer === questions[0].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[0].answer === questions[0].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                        <div className={ classes.ansBox }>
                          <Grid container spacing={ 1 }>
                            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                              <div className={ classes.boxFifty }>
                                <Typography variant="h4" component="h4">With a strategy and a plan</Typography>
                                <div className={ classes.withStrategyContent }>
                                  <span>42%</span>
                                  <p>more likely to achieve your goals if you write them down on a regular basis</p>
                                </div>
                                <div className={ classes.withStrategyContent }>
                                  <span>44%</span>
                                  <p>of those who have a ﬁnancial plan in place exceed their savings goals each year</p>
                                </div>
                                <div className={ classes.withStrategyContent }>
                                  <span>250%</span>
                                  <p>Over a lifetime, having a plan equates to 250% more retirement savings</p>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                              <div className={ `${classes.boxFifty} ${classes.textBlack}` }>
                                <Typography variant="h4" component="h4">Without a strategy and a plan</Typography>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>78%</span>
                                  <p>of US workers live paycheck-to-paycheck</p>
                                </div>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>60%</span>
                                  <p>of Americans could not handle an unexpected $1,000 expense</p>
                                </div>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>42%</span>
                                  <p>of Americans will retire with less than $10,000 saved</p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <div className={ classes.senseRatingComments }>
                            <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                            <Rating value={ questions[0].rating } questionIndex={ 0 } handleRating={ handleRating } />
                          </div>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[1].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )
                  }
                </div>

                <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>2</span>
                    <p className={ classes.questionTitle }>Your parents gift you $5,000 for your 30th birthday. Which of the following options below would be the best use of that money?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[1].answer } onChange={ ( e ) => handleAnswer( e, 1 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer !== '' && classes.ansRight ) } value="a" control={ <Radio checked={ questions[1].answer !== '' } /> } label="Pay off $5,000 credit card balance" disabled={ questions[1].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Invest that money in your friend&apos;s new business" disabled={ questions[1].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="Repay $5,000 car loan balance" disabled={ questions[1].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[1].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="Repay $5,000 student loan balance" disabled={ questions[1].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[1].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[1].answer === questions[1].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[1].answer === questions[1].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Credit cards are always the most expensive debt</Typography>
                        <figure className={ classes.figureImg }>
                          <img src={ creditcardsDiscovery } alt="" />
                        </figure>
                        <p className={ classes.boxPara }>
                          Credit cards usually charge

                          <span className={ classes.spanColor }>
                            {' '}
                            interest rates between 15-30%!
                            {' '}
                            {' '}
                          </span>

                          Since credit cards charge the highest interest rates, they are the most expensive and should be repaid before repaying any other debt early.
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[1].rating } questionIndex={ 1 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[2].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>3</span>
                    <p className={ classes.questionTitle }>Which of the following is NOT a characteristic of a checking account?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[2].answer } onChange={ ( e ) => handleAnswer( e, 2 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="A checking account is often used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks" disabled={ questions[2].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="A checking account is a highly liquid bank account" disabled={ questions[2].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer !== '' && classes.ansRight ) } value="c" control={ <Radio checked={ questions[2].answer !== '' } /> } label="A bank usually pays you a high interest rate for money in a checking account" disabled={ questions[2].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[2].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="You can use your debit card at an ATM to withdraw cash from your checking account" disabled={ questions[2].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[2].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[2].answer === questions[2].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[2].answer === questions[2].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">What&apos;s a checking account?</Typography>
                        <p className={ classes.boxPara }>A bank account that provides easy access to your money and is used to write personal checks, withdraw cash from an ATM and pay bills and other day-to-day expenses.</p>
                        <ul className={ classes.arrowList }>
                          <li>A checking account is often used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks.</li>
                          <li>A checking account is a highly liquid bank account.</li>
                          <li>You can use your debit card at an ATM to withdraw cash from your checking account.</li>
                        </ul>
                        <p className={ classes.boxPara }>
                          But, a checking account usually
                          { ' ' }
                          <span className={ classes.spanColor }>does not pay you a high interest rate on your money.</span>
                          { ' ' }
                          Since checking accounts don’t pay high interest, you don’t want to keep too much money in a checking account. That money could earn a higher return in a high-yield savings account or money market fund without taking any additional risk.
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[2].rating } questionIndex={ 2 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[3].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>4</span>
                    <p className={ classes.questionTitle }>The expression, &quot;don&apos;t put all your eggs in one basket&quot; has great meaning. Which of the following best describe how that expression relates to investing?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[3].answer } onChange={ ( e ) => handleAnswer( e, 3 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="If you invest all your money in one company, you are taking a big risk because if that one company goes bankrupt, you will lose all your money." disabled={ questions[3].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="If you put all your eggs in one basket, that basket will overflow and your eggs will fall on the ground and break." disabled={ questions[3].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="If you diversify and invest in 100 companies and one company goes bankrupt, you lose only a little bit of money." disabled={ questions[3].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[3].answer !== '' && classes.ansRight ) } value="d" control={ <Radio checked={ questions[3].answer !== '' } /> } label="Both A and C" disabled={ questions[3].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[3].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[3].answer === questions[3].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[3].answer === questions[3].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">&quot;Don&apos;t put all your eggs in one basket.&quot;</Typography>
                        <div className={ classes.graphBlock }>
                          <p className={ classes.boxPara }>
                            <span className={ classes.spanColor }>Making an investment requires taking risk. The risk is that you will lose some or all of your money.</span>
                            {' '}
                            But, there are ways to lower your risk when making investments. That’s where the expression “don’t put all your eggs in one basket” comes into play. Let’s say you have $100,000 to invest and look at two examples:
                          </p>
                          <div className={ classes.boxParaDiv }>
                            <p className={ classes.boxPara }>Example A: You invest $100,000 in your friend’s new cryptocurrency business</p>
                            <p className={ classes.boxPara }>Example B: You invest $10,000 in your friend’s new cryptocurrency business, $60,000 in 10 different stocks, $20,000 in bonds, and $10,000 in a high-yield savings account</p>
                          </div>
                          <p className={ classes.boxPara }>What happens in both examples if your friend’s new business goes bankrupt?</p>
                          <div className={ classes.boxParaDiv }>
                            <p className={ classes.boxPara }>Example A: You lose all of your $100,000. Ouch!</p>
                            <p className={ classes.boxPara }>Example B: You only lose $10,000 and still have $90,000 invested elsewhere.</p>
                          </div>
                          <p className={ classes.boxPara }>
                            &quot;Don&apos;t put all your eggs in one basket&quot; is really good investing advice.
                            {' '}
                            <span className={ classes.spanColor }>Lower your risk by diversifying your investments.</span>
                          </p>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[3].rating } questionIndex={ 3 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[4].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>5</span>
                    <p className={ classes.questionTitle }>
                      You use your credit card to buy a TV for $300. When your credit card balance comes due and you
                      {' '}
                      <i>DON&apos;T</i>
                      {' '}
                      make your payment, what happens next?
                    </p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[4].answer } onChange={ ( e ) => handleAnswer( e, 4 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="You can just pay back the $300 to the credit card company next month" disabled={ questions[4].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Since it&apos;s only $300, the credit card company will allow you to pay it back over the next three months free of charge" disabled={ questions[4].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer !== '' && classes.ansRight ) } value="c" control={ <Radio checked={ questions[4].answer !== '' } /> } label="Interest begins to accrue on your credit card balance the day after your payment due date and does not stop accruing until the $300 plus interest and late fees are paid off" disabled={ questions[4].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[4].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="You can cancel the credit card immediately to avoid interest charges" disabled={ questions[4].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[4].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[4].answer === questions[4].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[4].answer === questions[4].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">What happens if you miss a credit card payment?</Typography>
                        <p className={ classes.boxPara }>Your credit card company does not provide you with free money. When you miss a payment, you cannot cancel your credit card to avoid fees and interest. And, it does not matter how small or large the balance, the credit card company will charge fees and interest.</p>
                        <p className={ classes.boxPara }>When you miss a credit card payment, a few things happen:</p>
                        <ul className={ classes.arrowList }>
                          <li>Your credit score might decrease</li>
                          <li>Your credit card company will immediately charge you a late fee of roughly $30 and add that amount to your balance</li>
                          <li>You will be charged high interest on your balance you did not repay</li>
                          <li>Your interest rate will most likely rise to the penalty rate of 30%</li>
                        </ul>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanColor }>Rule of Thumb – don’t make a purchase on your credit card if you don’t have the money in your bank account to pay for that purchase.</span>
                        </p>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[4].rating } questionIndex={ 4 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[5].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>6</span>
                    <p className={ classes.questionTitle }>
                      You hit the lottery – congratulations! Now, you have a choice to make.
                      {' '}
                      <span className={ classes.questionTitlespan }>
                        <span className={ classes.textBlock }>Option A: Receive $75,000,000 today in a lump sum payment</span>
                        <span className={ classes.textBlock }>Option B: Receive $500,000 per month for the next 200 months</span>
                      </span>
                      {' '}
                      Which option is a better money move? Both options are after taxes.
                    </p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[5].answer } onChange={ ( e ) => handleAnswer( e, 5 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer !== '' && classes.ansRight ) } value="a" control={ <Radio checked={ questions[5].answer !== '' } /> } label="Option A" disabled={ questions[5].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Option B" disabled={ questions[5].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[5].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="I don&apos;t care which option, just give me my money!" disabled={ questions[5].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[5].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[5].answer === questions[5].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[5].answer === questions[5].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Option A: Lump Sum Payment</Typography>
                        <p className={ classes.boxPara }>Are you surprised? Your logic would tell you to select Option B because the monthly payments equal $100,000,000 in total and $100,000,000 is greater than $75,000,000.</p>
                        <p className={ classes.boxPara }>Why would Option A be a better money move? Check out the graph below to see how both options grow over time. Assuming both options earn a 5% annual return that is compounded monthly, the answer becomes clear.</p>
                        <p className={ classes.boxPara }>
                          The lesson here is that
                          {' '}
                          <span className={ classes.spanColor }>a dollar today is worth more than a dollar tomorrow.</span>
                          {' '}
                          Why? Because I can invest that money today and earn a return that grows over time. This concept is called the
                          {' '}
                          <span className={ classes.spanColor }>time value of money.</span>
                        </p>
                        <div className={ classes.graphBlock }>
                          <Grid container spacing={ 2 } className={ classes.GridPad }>
                            <Grid item xs={ 12 }>
                              <div className={ `${classes.graphBox}` }>
                                <HighchartsReact
                                  highcharts={ Highcharts }
                                  options={ {
                                    chart: {
                                      type: 'spline',
                                      height: 550,
                                    },
                                    exporting: {
                                      enabled: false,
                                    },
                                    credits: {
                                      enabled: false,
                                    },
                                    title: {
                                      text: 'Lottery Winnings: Lump Sum vs. Monthly Payments',
                                      enabled: true,
                                    },
                                    xAxis: [{
                                      title: {
                                        text: 'Months',
                                        style: {
                                          color: '#898d8f',
                                          fontSize: '14px',
                                        },
                                      },
                                      tickInterval: 12,
                                      categories: discoveryHighchartsData.months,
                                      labels: {
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                    }],
                                    yAxis: [{ // Primary yAxis
                                      labels: {
                                        formatter() {
                                          const chart = this;
                                          if ( chart.value < 0 ) {
                                            return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                          }
                                          return formatDollar( Math.round( chart.value ) );
                                        },
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                      title: {
                                        text: '',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                    }],
                                    tooltip: {
                                      headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                                      crosshairs: true,
                                      shared: true,
                                      valueDecimals: 0,
                                      valuePrefix: '$',
                                      style: {
                                        color: '#000000',
                                        fontSize: '13px',
                                      },
                                    },
                                    plotOptions: {
                                      spline: {
                                        lineWidth: 2,
                                        states: {
                                          hover: {
                                            lineWidth: 2,
                                          },
                                        },
                                        marker: {
                                          enabled: false,
                                        },

                                      },
                                    },
                                    legend: {
                                      itemStyle: {
                                        fontSize: '14px',
                                      },
                                    },
                                    series: [{
                                      name: 'Option A: Lump Sum',
                                      data: discoveryHighchartsData.lump_sum,
                                      color: '#84aa47',
                                    }, {
                                      name: 'Option B: Monthly Payments',
                                      data: discoveryHighchartsData.monthspayment,
                                      color: '#898d8f',
                                    }],
                                  } }
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[5].rating } questionIndex={ 5 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[6].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>7</span>
                    <p className={ classes.questionTitle }>Personal Finance 101: Do you know how to write a check?</p>
                    <figure className={ classes.quetionsFigure }>
                      <img src={ writingCheck1 } alt="" />
                    </figure>
                    <div className={ classes.writeCheckQ9 }>
                      <Grid container spacing={ 3 } className={ classes.gridRow }>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[1].answer === questions[6].sub[1].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[1].answer !== questions[6].sub[1].correctAns && classes.listWrong ) }>1</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[1].answer } onChange={ ( e ) => handleCheckAnswer( e, 1 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[2].answer === questions[6].sub[2].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[2].answer !== questions[6].sub[2].correctAns && classes.listWrong ) }>2</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[2].answer } onChange={ ( e ) => handleCheckAnswer( e, 2 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[3].answer === questions[6].sub[3].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[3].answer !== questions[6].sub[3].correctAns && classes.listWrong ) }>3</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[3].answer } onChange={ ( e ) => handleCheckAnswer( e, 3 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                      <Grid container spacing={ 3 } className={ classes.gridRow }>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[4].answer === questions[6].sub[4].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[4].answer !== questions[6].sub[4].correctAns && classes.listWrong ) }>4</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[4].answer } onChange={ ( e ) => handleCheckAnswer( e, 4 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[5].answer === questions[6].sub[5].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[5].answer !== questions[6].sub[5].correctAns && classes.listWrong ) }>5</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[5].answer } onChange={ ( e ) => handleCheckAnswer( e, 5 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[6].answer === questions[6].sub[6].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[6].answer !== questions[6].sub[6].correctAns && classes.listWrong ) }>6</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[6].answer } onChange={ ( e ) => handleCheckAnswer( e, 6 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                      <Grid container spacing={ 3 } className={ classes.gridRow }>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[7].answer === questions[6].sub[7].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[7].answer !== questions[6].sub[7].correctAns && classes.listWrong ) }>7</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[7].answer } onChange={ ( e ) => handleCheckAnswer( e, 7 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[8].answer === questions[6].sub[8].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[8].answer !== questions[6].sub[8].correctAns && classes.listWrong ) }>8</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[8].answer } onChange={ ( e ) => handleCheckAnswer( e, 8 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                          <ul className={ classes.selectList }>
                            <li className={ classes.listDropdown }>
                              <span className={ classNames( classes.listCount, questions[6].answer !== '' && questions[6].sub[9].answer === questions[6].sub[9].correctAns && classes.listWright, questions[6].answer !== '' && questions[6].sub[9].answer !== questions[6].sub[9].correctAns && classes.listWrong ) }>9</span>
                              <span className={ classes.listCountSelect }>
                                <select value={ questions[6].sub[9].answer } onChange={ ( e ) => handleCheckAnswer( e, 9 ) } disabled={ questions[6].answer !== '' }>
                                  <option value="">Please Select</option>
                                  {
                                    question7options.map( ( options ) => (
                                      <option key={ options.val } value={ options.val }>{options.name}</option>
                                    ) )
                                  }
                                </select>
                              </span>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                      <div className={ classes.checkScoreDiv }>
                        <Button className={ `${classes.checkScoreBtn} ` } onClick={ check7thAnswer } disabled={ questions[6].sub.check === false || questions[6].answer !== '' }>CHECK ANSWERS</Button>
                      </div>
                    </div>
                  </div>
                  {questions[6].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[6].answer === questions[6].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[6].answer === questions[6].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Money 101: Writing a check</Typography>
                        <div className={ classes.listDivNine }>
                          <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sm={ 6 } md={ 5 }>
                              <ul className={ classes.listDivNineUl }>
                                <li>Check number</li>
                                <li>Today’s date</li>
                                <li>Person or entity you are paying</li>
                                <li>Amount written in numbers</li>
                                <li>Amount written in words</li>
                                <li>Reason for check (not required)</li>
                                <li>Your signature</li>
                                <li>Your bank’s routing number</li>
                                <li>Your personal bank account number</li>
                              </ul>
                            </Grid>
                            <Grid item xs={ 12 } sm={ 6 } md={ 7 }>
                              <div className={ classes.graphBox }>
                                <figure className={ classes.figureGraphBox }>
                                  <img src={ writeCheckAns } alt="" />
                                </figure>
                              </div>
                            </Grid>
                          </Grid>
                          <p className={ classes.boxPara }>
                            Want more details on how to write a check? Access this
                            {' '}
                            <Link className={ classes.textBlueLink } to="/">link here.</Link>
                          </p>
                        </div>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[6].rating } questionIndex={ 6 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[7].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>8</span>
                    <p className={ classes.questionTitle }>True or False: Since contributions to your 401(k) retirement account are deducted from your salary before taxes, when you go to withdraw that money in retirement, you won&apos;t have to pay taxes.</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[7].answer } onChange={ ( e ) => handleAnswer( e, 7 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="True" disabled={ questions[7].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[7].answer !== '' && classes.ansRight ) } value="b" control={ <Radio checked={ questions[7].answer !== '' } /> } label="False" disabled={ questions[7].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[7].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[7].answer === questions[7].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[7].answer === questions[7].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">401(k) Retirement Account</Typography>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanStrong }>False!</span>
                          {' '}
                          When you make contributions to a 401(k) retirement account, you invest a percentage of your paycheck
                          {' '}
                          <span className={ classes.spanColor }>before taxes are taken out.</span>
                          {' '}
                          But, when you go to withdraw from your 401(k) in retirement, your withdrawals are taxed as ordinary income.
                        </p>
                        <p className={ classes.boxPara }>The lesson here is that even though you are not paying taxes on the money you contribute to your 401(k) during your working years, you will pay taxes on that money later when you withdraw that money in retirement.</p>

                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[7].rating } questionIndex={ 7 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[8].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>9</span>
                    <p className={ classes.questionTitle }>What is the term for a three-digit number ranging from 300 – 850 (higher the better) that relates to how likely you are to repay debt.</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[8].answer } onChange={ ( e ) => handleAnswer( e, 8 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Debt Repayment Score" disabled={ questions[8].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Credit Repayment Score" disabled={ questions[8].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="Financial Score" disabled={ questions[8].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[8].answer !== '' && classes.ansRight ) } value="d" control={ <Radio checked={ questions[8].answer !== '' } /> } label="Credit Score" disabled={ questions[8].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[8].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[8].answer === questions[8].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[8].answer === questions[8].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Credit Score</Typography>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 12 } md={ 7 }>
                            <div className={ classes.creditDiv }>
                              <p className={ classes.boxPara }>
                                <span className={ classes.spanColor }>Credit score is a three-digit number ranging from 300 – 850 (higher the better) that relates to how likely you are to repay debt. </span>
                                {' '}
                                A credit score above 700 is generally considered good.
                              </p>
                              <p className={ classes.boxPara }>Your credit score is very important to maintain and improve as banks and lenders will use your score when deciding whether to approve you for a credit card or loans such as auto loans, student loans, and mortgages. And, a higher credit score usually leads to lower interest rates on loans. Lower interest rates equals thousands of dollars saved.</p>
                            </div>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 12 } md={ 5 }>
                            <div className={ `${classes.creditImg} ${classes.creditImgNine}` }>
                              <figure>
                                <img src={ creditScore } alt="" />
                              </figure>
                            </div>
                          </Grid>
                        </Grid>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[8].rating } questionIndex={ 8 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[9].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>10</span>
                    <p className={ classes.questionTitle }>Student loan monthly payments are made up of two parts: ___________ and interest.</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[9].answer } onChange={ ( e ) => handleAnswer( e, 9 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Balance" disabled={ questions[9].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Capital" disabled={ questions[9].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer !== '' && classes.ansRight ) } value="c" control={ <Radio checked={ questions[9].answer !== '' } /> } label="Principal Repayment" disabled={ questions[9].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[9].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="Amortization" disabled={ questions[9].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[9].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[9].answer === questions[9].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[9].answer === questions[9].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Principal Repayment and Interest (P&I)</Typography>
                        <p className={ classes.boxPara }>
                          Student loan payments are made up of two parts:
                          {' '}
                          <span className={ classes.textBlock }>
                            <span className={ classes.spanColor }>principal repayment and interest</span>
                            {' '}
                            (also known as &quot;P&I&quot;).
                          </span>
                        </p>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanColor }>Principal</span>
                          {' '}
                          is the total sum of money borrowed. A portion of each monthly payment goes toward interest and the rest is used to pay down your principal balance.
                        </p>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanColor }>Amortization</span>
                          {' '}
                          is the process of paying off your loans in regular payments over a period of time. Check out the example of a standard amortization table below.
                        </p>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                            <div className={ classes.AssumptionsDiv }>
                              <p className={ classes.boxParaBoldHead }>Assumptions</p>
                              <p className={ classes.boxPara }>
                                <span className={ classes.textBlock }>Principal = $225,000</span>
                                <span className={ classes.textBlock }>Term of the Loan (yrs) = 30</span>
                                <span className={ classes.textBlock }># of Months = 360</span>
                                <span className={ classes.textBlock }>Annual Interest Rate = 4.00%</span>
                                <span className={ classes.textBlock }>Monthly Payment = $1,074</span>
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
                            <div className={ classes.AssumptionsImg }>
                              <figure>
                                <img src={ principalRepayment } alt="" />
                              </figure>
                            </div>
                          </Grid>
                        </Grid>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[9].rating } questionIndex={ 9 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[10].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[10].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>11</span>
                    <p className={ classes.questionTitle }>Complete the following equation: Assets – Liabilities = ?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[10].answer } onChange={ ( e ) => handleAnswer( e, 10 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer !== '' && classes.ansRight ) } value="a" control={ <Radio checked={ questions[10].answer !== '' } /> } label="Net Worth" disabled={ questions[10].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Net Income" disabled={ questions[10].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="Adjusted Gross Income" disabled={ questions[10].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[10].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="Net Change in Cash" disabled={ questions[10].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[10].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[10].answer === questions[10].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[10].answer === questions[10].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Net Worth</Typography>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 12 } md={ 7 }>
                            <div className={ classes.AssumptionsDiv }>
                              <p className={ classes.boxPara }>
                                <span className={ classes.spanColor }>Net Worth = Assets – Liabilities.</span>
                              </p>
                              <p className={ classes.boxPara }>Net worth is the value of what you own (assets) minus what you owe (liabilities).</p>
                              <p className={ classes.boxPara }>The value of an asset is the price that you can sell it for today. The value of a liability is the outstanding amount on that loan that still needs to be repaid.</p>
                              <p className={ classes.boxPara }>Knowing your net worth is important for two main reasons:</p>
                              <ul className={ classes.numberList }>
                                <li>Net worth shows your current financial situation in one, clean number, and</li>
                                <li>Net worth provides a reference point for measuring your future financial progress.</li>
                              </ul>
                              <p className={ classes.boxPara }>Net worth allows you to track your financial progress over time. Are you making smart money moves that grow your net worth?</p>
                            </div>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 12 } md={ 5 }>
                            <div className={ classes.AssumptionsImg }>
                              <figure>
                                <img src={ netWorth } alt="" />
                              </figure>
                            </div>
                          </Grid>
                        </Grid>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[10].rating } questionIndex={ 10 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[11].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[11].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>12</span>
                    <p className={ classes.questionTitle }>A(n) _______ is a monetary asset that is purchased with the hope that it will generate income or will appreciate in the future.</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[11].answer } onChange={ ( e ) => handleAnswer( e, 11 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Car" disabled={ questions[11].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Liability" disabled={ questions[11].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="TV" disabled={ questions[11].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[11].answer !== '' && classes.ansRight ) } value="d" control={ <Radio checked={ questions[11].answer !== '' } /> } label="Investment" disabled={ questions[11].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[11].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[11].answer === questions[11].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[11].answer === questions[11].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">What is an investment?</Typography>
                        <p className={ classes.boxPara }>
                          <span className={ classes.spanColor }>An investment is a monetary asset that is purchased with the hope that it will generate income or will appreciate in the future; a tool to create future wealth.</span>
                          {' '}
                          An investment is a type of asset.
                        </p>
                        <p className={ classes.boxPara }>There are many types of investments, such as:</p>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.arrowList }>
                              <li>Stocks</li>
                              <li>Bonds</li>
                              <li>Investment Funds</li>
                              <li>Bank Products</li>
                              <li>Options</li>
                            </ul>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <ul className={ classes.arrowList }>
                              <li>Annuities</li>
                              <li>Retirement Accounts</li>
                              <li>College Savings Accounts (529, etc.)</li>
                              <li>Insurance</li>
                              <li>Alternative and Complex Products</li>
                            </ul>
                          </Grid>
                        </Grid>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[11].rating } questionIndex={ 11 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[12].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[12].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>13</span>
                    <p className={ classes.questionTitle }>Which of the following is a good reason to get a credit card?</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[12].answer } onChange={ ( e ) => handleAnswer( e, 12 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Using a credit card will automatically increase my credit score" disabled={ questions[12].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="I need a credit card in case of financial emergencies" disabled={ questions[12].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer === 'c' && classes.ansWrong ) } value="c" control={ <Radio /> } label="I need a credit card so that I can earn rewards on all of my purchases" disabled={ questions[12].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[12].answer !== '' && classes.ansRight ) } value="d" control={ <Radio checked={ questions[12].answer !== '' } /> } label="Credit cards offer more security than debit cards and cash" disabled={ questions[12].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[12].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[12].answer === questions[12].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[12].answer === questions[12].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">A good reason to get a credit card is for security</Typography>
                        <p className={ classes.boxPara }>Credit cards offer more security than debit cards and cash. For example, if your credit card is stolen and used to make purchases, you can call the credit card company and they will cancel those charges and freeze your card.</p>
                        <p className={ classes.boxPara }>But, if your debit card is stolen and used, the cash is withdrawn from your account in 1-2 days and is much more difficult to get refunded. If cash is stolen, it’s as good as gone!</p>

                        <ul className={ classes.arrowList }>
                          <p>These are not good reasons to get a credit card:</p>
                          <li>Improving your credit score: using a credit card is not guaranteed to increase your credit score.</li>
                          <li>For emergencies: setting up an emergency fund is a better money move. A good benchmark to hold in this emergency fund is three to six months of expenses.</li>
                          <li>For rewards: credit card companies offer rewards to entice you to open a new card. Why? Because they know that if you fall behind on your payments, fees and interest will be more profitable than the rewards they offer.</li>
                        </ul>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[12].rating } questionIndex={ 12 } handleRating={ handleRating } />
                        </div>
                      </div>
                      <div className={ classes.nextQuestion }>
                        <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[13].scroll ) }>next question v</Link>
                      </div>
                    </div>
                    )}
                </div>

                <div ref={ ( section ) => { questionMainAns[13].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                  <div className={ classes.question }>
                    <span className={ classes.questionNum }>14</span>
                    <p className={ classes.questionTitle }>The _______ is a simple formula used to determine how many years it will take for an investment to double in value.</p>
                    <ul className={ classes.optionList }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup aria-label="gender" className={ classNames( classes.radioGroup ) } name="questionOne" value={ questions[13].answer } onChange={ ( e ) => handleAnswer( e, 13 ) }>
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'a' && classes.ansWrong ) } value="a" control={ <Radio /> } label="Rule of Investment Returns" disabled={ questions[13].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'b' && classes.ansWrong ) } value="b" control={ <Radio /> } label="Rule of Thumbs" disabled={ questions[13].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer !== '' && classes.ansRight ) } value="c" control={ <Radio checked={ questions[13].answer !== '' } /> } label="Rule of 72" disabled={ questions[13].answer !== '' } />
                          <FormControlLabel className={ classNames( classes.cotrolLabel, questions[13].answer === 'd' && classes.ansWrong ) } value="d" control={ <Radio /> } label="Rule of Interest" disabled={ questions[13].answer !== '' } />
                        </RadioGroup>
                      </FormControl>
                    </ul>
                  </div>
                  {questions[13].answer !== ''
                    && (
                    <div className={ classes.ansRow }>
                      <Typography className={ classNames( classes.ansTitle, questions[13].answer === questions[13].correctAns ? classes.ansCurrect : classes.answrong ) } variant="h2" component="h2">{questions[13].answer === questions[13].correctAns ? 'Correct !' : 'Incorrect.'}</Typography>
                      <div className={ classes.ansBox }>
                        <Typography className={ classes.boxTitle } variant="h2" component="h2">Rule of 72 and investments</Typography>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <div className={ classes.AssumptionsDiv }>
                              <p className={ classes.boxPara }>
                                <span className={ classes.spanColor }>The Rule of 72 is a quick way to calculate the number of years it will take for an investment to double in value given an annual rate of return or interest rate. </span>
                                {' '}
                                This formula comes in handy for mental calculations and when thinking about an investment.
                              </p>
                              <p className={ classes.boxPara }>
                                # of years = 72 / annual rate of return
                                {' '}
                                <span className={ classes.textBlock }># of years = 72 / 4 = 18 years</span>
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <div className={ classes.AssumptionsImg }>
                              <figure>
                                <img src={ ruleofInvestments } alt="" />
                              </figure>
                            </div>
                          </Grid>
                        </Grid>
                        <div className={ classes.senseRatingComments }>
                          <Typography variant="h2" component="h2">How important to you is this information?</Typography>
                          <Rating value={ questions[13].rating } questionIndex={ 13 } handleRating={ handleRating } />
                        </div>
                      </div>
                    </div>
                    )}
                </div>

                <div className={ classes.progressBar } id="progress-bar">
                  <p>
                    {progress}
                    % complete
                  </p>
                  <div>
                    <span style={ { width: `${progress}%` } } />
                  </div>
                </div>
                <div className={ classes.whatsMyScore } id="my-score-button">
                  <Button onClick={ handleMyScore }>What&apos;s My Score?</Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {myScoreContent === true && (
          <div ref={ ( section ) => { feedBackSection = section; } }>
            <div className={ classes.whatmyScoreRow }>
              <div className={ classes.myScoreTitle }>
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">INKWIRY DISCOVERY FEEDBACK</Typography>
                </div>
              </div>
              <div className={ classes.graphScoreRow }>
                <div className={ classes.container }>
                  <div className={ classes.graphhRow }>
                    <div className={ classes.inkImportRank }>
                      <span>MY RANK OF IMPORTANCE</span>
                      <p>5</p>
                      <p>4</p>
                      <p>3</p>
                      <p>2</p>
                      <p>1</p>
                    </div>
                    <div className={ classes.inkAwaerenessScoreCntIn }>
                      <div className={ classes.inkAwaerenessCateCnt }>
                        {
                          questions.map( ( questionValue, index ) => (
                            <div key={ questionValue.qId } className={ classes.inkAwaerenessCateIn }>
                              <div role="button" tabIndex={ index } aria-hidden="true" onClick={ () => handleQuestionScroll( questionMainAns[index].scroll ) } className={ questionValue.status === 'C' ? classes.inkAwaerenessCateScoreBlue : classes.inkAwaerenessCateScoreRed } style={ { height: `${questionValue.rating * 20}%` } } />
                              <p>{questionValue.qId}</p>
                            </div>
                          ) )
                        }
                      </div>
                    </div>
                    <div className={ classes.inkScoreBottomSec }>
                      <p className={ classes.quetionsPara }>QUESTIONS</p>
                      <ul>
                        <li>
                          <p>
                            <span className={ classes.correctBox } />
                            { ' ' }
                            Correct
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className={ classes.wrongBox } />
                            { ' ' }
                            Incorrect
                          </p>
                        </li>
                      </ul>
                      <p className={ classes.quetionsParaTwo }>NOTE: Click on a number to toggle back to that question - then hit Feedback to return to this page.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={ classes.whatmyScoreRow }>
              <div className={ classes.myScoreTitle }>
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">
                    <span>WHAT&apos;S MY SCORE</span>
                  </Typography>
                </div>
              </div>
              <div className={ classes.container }>
                <div className={ classes.myScoreCnt }>
                  <p>Revisit each question and make sure you learn the financial life skills and knowledge in this challenge. Each answer contains valuable information that you need to know as you manage your financial life. Developing and improving your financial skills and knowledge will grow your wealth now and into the future. Inkwiry will continuously update this challenge to expand your financial life toolkit.</p>
                </div>
                <div className={ classes.awarenessGraph }>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={ {
                      chart: {
                        type: 'solidgauge',
                        height: 500,
                        events: {
                          render: renderIcons,
                        },
                      },
                      title: {
                        text: '',
                      },
                      exporting: {
                        enabled: false,
                      },
                      tooltip: {
                        enabled: false,
                      },
                      pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                          outerRadius: '112%',
                          innerRadius: '88%',
                          backgroundColor: Highcharts.Color( '#84aa47' )
                            .setOpacity( 0.3 )
                            .get(),
                          borderWidth: 0,
                        }, { // Track for Exercise
                          outerRadius: '87%',
                          innerRadius: '63%',
                          backgroundColor: Highcharts.Color( '#f79b22' )
                            .setOpacity( 0.3 )
                            .get(),
                          borderWidth: 0,
                        }],
                      },
                      credits: {
                        enabled: false,
                      },

                      yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                      },
                      plotOptions: {
                        series: {
                          cursor: 'pointer',
                        },
                        solidgauge: {
                          dataLabels: {
                            enabled: false,
                          },
                          linecap: 'round',
                          stickyTracking: false,
                          rounded: true,
                        },
                      },
                      series: [{
                        name: 'DISCOVERY',
                        id: 'discovery',
                        data: [{
                          color: '#84aa47',
                          radius: '112%',
                          innerRadius: '88%',
                          y: avgScore,
                        }],
                        events: {
                          mouseOver() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                          mouseOut() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                        },
                      }, {
                        name: 'IMPORTANCE',
                        id: 'importance',
                        data: [{
                          color: '#f79b22',
                          radius: '87%',
                          innerRadius: '63%',
                          y: avgRating * 20,
                        }],
                        events: {
                          mouseOver() {
                            scoreValSection.style.setProperty( 'display', 'none' );
                            ratingValSection.style.setProperty( 'display', 'flex' );
                          },
                          mouseOut() {
                            scoreValSection.style.setProperty( 'display', 'flex' );
                            ratingValSection.style.setProperty( 'display', 'none' );
                          },
                        },
                      }],
                    } }
                  />
                  <div className={ classNames( classes.scoreNumbers, classes.scoreDetails ) } ref={ ( section ) => { scoreValSection = section; } }>
                    <p>
                      CORRECT
                      {' '}
                      <font>
                        { avgScore.toFixed( 1 ) }
                        %
                      </font>
                    </p>
                  </div>
                  <div className={ classNames( classes.scoreNumbers, classes.ratingDetails ) } ref={ ( section ) => { ratingValSection = section; } }>
                    <p>
                      AVERAGE IMPORTANCE
                      {' '}
                      <font>{ avgRating.toFixed( 1 ) }</font>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Discovery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Discovery );
