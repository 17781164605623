import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import WalkThruDots from './walkThruDots';

const WalkThruOne = ( props ) => {
  const {
    classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,
  } = props;

  return (
    <div className={ classNames( classes.pwiWalkthru, classes.pwiWalkthruOne, classes.pwiActiveWalkthru ) }>
      <h4>Get Started in Plan With Inkwiry with an Inkwiry Walk-Thru</h4>
      <p>Welcome to Plan With Inkwiry, a step-by-step, guided financial life planning tool. This Walk-Thru will detail the many interface tools available to you as you build your best future. To get started, click through these quick tips to help you navigate Plan With Inkwiry with confidence.</p>
      <div className={ classes.walkthruBottom }>
        <WalkThruDots completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } handleWalkThrus={ handleWalkThrus } />

        <div className={ classes.walkThruBtns }>
          <Button className={ classes.startWalkThru } onClick={ () => { handleWalkThrus( 1 ); } }>Start</Button>
          <Button className={ classes.skipWalkthru } onClick={ handleWalkThruClose }>Skip walk-thru</Button>
        </div>
      </div>
    </div>
  );
};

WalkThruOne.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  handleWalkThruClose: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruOne );
