import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StockMarket = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Stock Market</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-market.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span> Stock Market
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-market-300.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The stock market is where investors connect to buy and
                          sell financial securities on any business day. The
                          stock market functions like an auction house bringing
                          buyers and sellers together to negotiate prices and
                          make trades.{' '}
                          <Link
                            to="/finance-explained/stocks-bonds"
                            target="_blank">
                            Stocks
                          </Link>{' '}
                          are most commonly traded, but{' '}
                          <Link
                            to="/finance-explained/stocks-bonds"
                            target="_blank">
                            bonds
                          </Link>
                          ,{' '}
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            exchange-traded funds
                          </Link>
                          ,{' '}
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            commodities
                          </Link>
                          , and other investments are also bought and sold in
                          the stock market daily.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Let&apos;s look at a simplified example of how the
                          stock market works:
                        </p>
                        <ol>
                          <li>
                            John Smith, Washington resident, owns 1 share of
                            Microsoft and wants to sell his share
                          </li>
                          <li>
                            Jane Thompson, Iowa resident, wants to buy 1 share
                            of Microsoft because she thinks the price of that
                            share will continue to grow in the near future
                          </li>
                          <li>
                            John sells his share using his online investment
                            account
                          </li>
                          <li>
                            Jane clicks to buy a share of Microsoft using her
                            online investment account
                          </li>
                          <li>
                            When John sells his share, the stock market
                            automatically connects John, the seller, with Jane,
                            the buyer, and makes the trade
                          </li>
                          <li>
                            John receives cash for the sale and Jane receives 1
                            share of Microsoft
                          </li>
                        </ol>
                        <p>
                          The stock market refers to the whole collection of
                          stock exchanges and other financial marketplaces. When
                          someone refers to the stock market, they are talking
                          about all exchanges and marketplaces where trading
                          takes place. But, where do investors actually make
                          these trades? Stock exchanges.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StockMarket.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StockMarket);
