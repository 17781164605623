import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StartedWithInvest = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Getting Started with Investing</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing-101-cream.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Getting Started with Investing
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing101-187px-cream.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Only 25% of Americans invest outside of their
                          workplace retirement plan. And 65% of Americans have
                          less than $10,000 in their workplace retirement plans.
                          Overall, we don't capitalize on the most powerful
                          wealth building tool at our disposal - investing.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Investing is a powerful tool that we can leverage at
                          all points in our life. What is investing? How do I
                          get started with investing? Let's start with the
                          fundamentals and, together, grow our skills and
                          knowledge so our money can go to work for us.
                        </p>
                        <p>
                          <strong>What is investing?</strong> <br />
                          Investing is buying an asset today with the
                          expectation of one of two things:
                          <ol>
                            <li>
                              Selling that asset at a later date for a higher
                              price than you originally paid earning you a
                              profit, and/or
                            </li>
                            <li>
                              Earning periodic cash flows from that asset.
                            </li>
                            How do you know if an asset such as a stock or real
                            estate will rise in value? How do you know if an
                            investment will be able to consistently make cash
                            payments to you? The simple answer is that you don't
                            know. This is the most important thing to understand
                            when it comes to making investments. No one has a
                            crystal ball that perfectly reveals the future,
                            especially when it comes to making investments.
                          </ol>
                        </p>
                        <p>
                          <strong>Risk-to-Reward</strong> <br />
                          Every investment that you will make in your life comes
                          with a varying level of risk. The risk in investing is
                          the chance of you losing some or all of your
                          investment. Understanding the risk in relation to the
                          potential reward of that investment and how that
                          relates to your risk tolerance is the most fundamental
                          principle of intelligent investing.
                        </p>
                        <p>
                          The difference between investing and gambling is when
                          you invest, you take calculated risk. When you gamble,
                          you're just hoping and wishing something happens. The
                          amount of risk you can handle is called risk
                          tolerance. For example, what would you do if your
                          investment lost 10% in one day or 30% of its value in
                          a few weeks? Check out Disney's stock price over the
                          last 20 years - could you handle the ups and downs if
                          you invested in Disney's stock? We'll get into more
                          detail on how to determine your risk tolerance and
                          what that could mean for your investing strategy.
                        </p>
                        <p>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing-101-cream-graph.png"
                            alt=""
                            className={classes.imgFull}
                          />
                        </p>
                        <p>
                          On the other hand, the reward is the chance of an
                          investment increasing in value allowing you to sell
                          that investment for a higher price than you paid, or
                          the periodic cash flow payments made to you for being
                          an investor.
                        </p>
                        <p>
                          Why would you risk your hard-earned cash if the risk -
                          the likelihood of you losing money - is high and the
                          chance of you earning a reward is low? Before you make
                          an investment, always determine the risk-to-reward of
                          that investment.
                        </p>
                        <p>
                          In a perfect world, all investments have low risk and
                          high potential reward. In reality, higher risk
                          typically means a higher potential reward. That
                          relationship makes sense if you take a step back and
                          think about it.
                        </p>
                        <p>
                          If you are going to take a major risk, wouldn't you
                          want to be rewarded for doing so? Otherwise, what's
                          the incentive for me - the investor - to take that
                          risk? That's how risk to reward works.
                        </p>
                        <p>
                          <strong>Investing 101</strong> <br />
                          Over the next few weeks, we will explore the world of
                          investing. To name a few highlights, we will cover:
                          <ul>
                            <li>
                              The fundamentals - you can't get in the game
                              without knowing the rules,
                            </li>
                            <li>
                              The investable universe - assets that are
                              available to purchase,
                            </li>
                            <li>
                              How to actually invest - the many different
                              avenues to invest and how that changes as your
                              life changes, and
                            </li>
                            <li>
                              Investing strategies - matching goals to the
                              appropriate investing strategy.
                            </li>
                          </ul>
                        </p>
                        <p>
                          My name is James DeLuca, the CEO and Co-Founder of
                          Inkwiry. I'll be your copilot on this journey into the
                          most powerful wealth building tool on the planet -
                          investing
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.hitTapRow}>
                    <h3>Just hit the tape</h3>
                    <ul>
                      <li>
                        Instagram goes back to an old favorite -{' '}
                        <a
                          href="https://www.theverge.com/2022/3/23/22991852/instagram-favorites-following-chronological-feed-return"
                          target="_blank"
                          rel="noopener noreferrer">
                          chronological feed
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a
                          href="https://www.marcus.com/us/en/media/blogs/how-are-americans-investing-today"
                          target="_blank"
                          rel="noopener noreferrer">
                          How are Americans investing today according to Goldman
                          Sachs?
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a
                          href="https://www.bloomberg.com/news/articles/2022-03-24/u-s-mortgage-rates-surge-to-4-42-highest-since-january-2019"
                          target="_blank"
                          rel="noopener noreferrer">
                          Mortgage rates and home prices on the rise
                        </a>{' '}
                      </li>
                    </ul>

                    <p>
                      Get smarter about your money. Explore more financial terms
                      on our website in{' '}
                      <Link to="/" rel="noopener noreferrer">
                        Finance Explained.
                      </Link>{' '}
                    </p>
                  </div>

                  <div className={classes.seperatorDotBlock}>
                    <div className={classes.seperatorDots}>
                      <img src={seperatordots} alt="logo" />
                    </div>
                    <div className={classes.connectFinance}>
                      <p>Connect with Inkwiry</p>
                      <ul>
                        <li>
                          <a
                            href="https://twitter.com/inkwiry"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={twitter} alt="logo" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/inkwiryfc/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={instagram} alt="logo" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="mailto:support@inkwiry.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={mail} alt="logo" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className={classes.seperatorDots}>
                      <img src={seperatordots} alt="logo" />
                    </div>
                    <div className={classes.financialBuild}>
                      <h2>See and build your best tomorrows now.</h2>
                      <Link to="/">www.inkwiry.com</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StartedWithInvest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StartedWithInvest);
