function moreHigherEducationSheetOutput( module6Data, moreHigherStudentLoanData, incomeStatementnetData, cashFlowData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, databaseData, incomeTaxData ) {
  let year = 0;
  let yearLimit = 0;
  let higherEdOutputData = {};

  // const estimatedTaxes = {};
  let estimatedTaxesData = {};
  let estimatedDataArray = {};
  let estimatedTaxesValue = 0;
  let incomeVal = 0;
  let studentLoanBalance = 0;
  // const paymentVal1 = 0;
  // const paymentVal2 = 0;
  let principalPaybackValue = 0;
  let interestValue = 0;
  let secondYear = 0;
  let collegeRoomBoardVal = 0;
  let rentVal = 0;
  let utilitiesVal = 0;
  let foodVal = 0;
  let clothingVal = 0;
  let entertainmentVal = 0;
  let technologyVal = 0;
  let transportationVal = 0;
  let carMaintenanceVal = 0;
  let carInsuranceVal = 0;
  let carPaymentVal = 0;
  let gasVal = 0;
  let carVal = 0;
  let miscellaneousVal = 0;
  let healthInsuranceDeductibleVal = 0;
  let healthInsurancePremiumVal = 0;
  let healthInsuranceVal = 0;
  let offCampusFoodVal = 0;
  let thirdYear = 0;
  // const sourcesVal = 0;
  // const usesVal = 0;
  let financialAidSoursesVal = 0;
  let grantsSoursesVal = 0;
  // const livingExpensesSourcesVal = 0;
  let livingExpensesUsesVal = 0;
  let incomeEarnedDuringCollegeval = 0;
  let familyContributionVal = 0;
  let tutionCostsVal = 0;
  let studentLoanVal = 0;
  let federalSubMoreStudentLoanVal = 0;
  let federalSubMoreStudentLoanVal1 = 0;
  let federalSubMoreStudentLoanVal2 = 0;
  let federalUnsubMoreStudentLoanVal = 0;
  let federalUnsubMoreStudentLoanVal1 = 0;
  let federalUnsubMoreStudentLoanVal2 = 0;
  let privateMoreStudentLoanVal = 0;
  let privateMoreStudentLoanVal1 = 0;
  let privateMoreStudentLoanVal2 = 0;

  year = parseInt( module6Data.start_year, 10 );
  yearLimit = parseInt( module6Data.start_year, 10 ) + 15;
  higherEdOutputData = {};
  const studentLoanBalanceData = [];
  const paymentData = [];
  const principalPaybackData = [];
  const interestData = [];
  const yearsListData = [];

  higherEdOutputData['Bar Graph1'] = {};
  higherEdOutputData['Bar Graph2'] = {};

  let totalCollegeValue = 0;
  let totalRentValue = 0;
  let totalUtilitiesValue = 0;
  let totalFoodValue = 0;
  let totalClothingValue = 0;
  let totalEntertainmentValue = 0;
  let totalTechnologyValue = 0;
  let totalTransportationValue = 0;
  let totalGasValue = 0;
  let totalMiscellaneousValue = 0;
  let totalCarValue = 0;
  let totalHealthValue = 0;
  const bar2GraphYearsList = [];

  const scholarshipValArr = [];
  const grantsArr = [];
  const financiAidValArr = [];
  const estimatedTaxesArr = [];
  const livingExpensesValArr = [];
  const incomeEarnedDuringValArr = [];
  const familyContributionValArr = [];
  const tutitionCostValArr = [];
  const studentLoanValArr = [];
  const federalMoreStudentLoanValArr = [];
  const federalUnMoresubStudentLoanValArr = [];
  const privateMoreStudentLoanValArr = [];

  const moreStudentLoanRepaymentValArr = [];
  const moreOtherLoansPaymentOnOtherDebtValArr = [];
  const moreTotalCarExpensesValArr = [];
  const moreRealEstateExpensesValArr = [];
  const moreChildrenAndPetExpensesValArr = [];

  const fedMoreStudentLoanBalArr = [];
  const fedMorePrincipalPaybackArr = [];
  const fedMoreInterestArr = [];
  const fedMorePaymentArr = [];
  const fedMoreYearsList = [];
  const fedMoreUnStudentLoanBalArr = [];
  const fedMoreUnPrincipalPaybackArr = [];
  const fedMoreUnInterestArr = [];
  const fedMoreUnPaymentArr = [];
  const fedMoreUnYearsList = [];
  const privateMoreStudentLoanBalArr = [];
  const privateMorePrincipalPaybackArr = [];
  const privateMoreInterestArr = [];
  const privateMorePaymentArr = [];
  const privateMoreYearsList = [];

  // let totalCollegeValue = 0;
  // let totalRentValue = 0;
  // let totalUtilitiesValue = 0;
  // let totalFoodValue = 0;
  // let totalClothingValue = 0;
  // let totalEntertainmentValue = 0;
  // let totalTechnologyValue = 0;
  // let totalTransportationValue = 0;
  // let totalGasValue = 0;
  // let totalMiscellaneousValue = 0;
  // let totalCarValue = 0;
  // let totalHealthValue = 0;
  let totalValues = 0;

  let studentLoanBalanceVal = 0;
  let principalPaybackVal = 0;
  let fedInterestVal = 0;
  let unsubStudentLoanBalanceVal = 0;
  let unsubPrincipalPaybackVal = 0;
  let unsubFedInterestVal = 0;
  let privateStudentLoanBalanceVal = 0;
  let privatePrincipalPaybackVal = 0;
  let privateFedInterestVal = 0;

  let moreStudentLoanPaymentVal = 0;
  let moreStudentLoanPaymentVal1 = 0;
  //  const moreStudentLoanPaymentVal2 = 0;
  let moreOtherLoansPaymentOnOtherDebtVal = 0;
  let moreOtherLoansPaymentOnOtherDebtVal1 = 0;
  // const moreOtherLoansPaymentOnOtherDebtVal2 = 0;
  // const moreOtherLoansPaymentOnOtherDebtVal3 = 0;
  // const moreOtherLoansPaymentOnOtherDebtVal4 = 0;
  let moreOtherLoansPaymentOnOtherDebtVal5 = 0;
  let moreTotalCarExpensesVal = 0;
  let moreRealEstateExpensesVal = 0;
  let moreChildrenAndPetExpensesVal1 = 0;
  let moreChildrenAndPetExpensesVal2 = 0;
  let moreChildrenAndPetExpensesVal = 0;

  let percentageArray1 = [];
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.percentageArray !== 'undefined' && incomeTaxData.percentageArray !== '' ) {
    percentageArray1 = incomeTaxData.percentageArray;
  }

  // Single Range Arrays
  let educationRangeArray = [];
  let educationRange2Array = [];
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRangeArray !== 'undefined' && incomeTaxData.singleRangeArray !== '' ) {
    educationRangeArray = incomeTaxData.singleRangeArray;
  }
  if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRange2Array !== 'undefined' && incomeTaxData.singleRange2Array !== '' ) {
    educationRange2Array = incomeTaxData.singleRange2Array;
  }

  estimatedDataArray = percentageArray1 && percentageArray1.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = educationRangeArray[index];
    returnData.Range2 = educationRange2Array[index];
    if ( index === 6 ) {
      returnData.Difference = 0;
      returnData.Tax = 0;
    } else {
      returnData.Difference = returnData.Range2 - returnData.Range;
      returnData.Tax = returnData.Difference * ( percentage / 100 );
    }
    return returnData;
  } );

  function estimatedTaxesCalculation( income, incomeEarned ) {
    // Total Taxes
    let totalTaxes = 0;
    const returnData = {};
    percentageArray1.forEach( ( percentage, index ) => {
      returnData[index] = {};
      returnData[index].Percentage = percentage;
      let range = 0;
      if ( income >= estimatedDataArray[index].Range ) {
        range = estimatedDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData[index].Range = range;
      let range2 = 0;
      if ( index === 6 ) {
        if ( income >= estimatedDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else if ( income >= estimatedDataArray[index].Range2 ) {
        range2 = estimatedDataArray[index].Range2;
      } else if ( income <= estimatedDataArray[index].Range2
        && income >= estimatedDataArray[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData[index].Range2 = range2;

      returnData[index].Difference = range2 - range;

      returnData[index].Tax = returnData[index].Difference * ( percentage / 100 );

      totalTaxes += returnData[index].Tax;
    } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / incomeEarned ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // State & local
    const stateAndLocal = 5;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity + stateAndLocal;

    const taxInfo = {};
    taxInfo['Total Taxes'] = totalTaxes;
    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['State & Local'] = stateAndLocal;
    taxInfo['Federal Income Tax'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  for ( year; year < yearLimit; year += 1 ) {
    higherEdOutputData[year] = {};
    higherEdOutputData[year].Year1 = {};
    higherEdOutputData[year].Year1.Sources = {};

    // Federal Subsidized Values
    higherEdOutputData[year]['Federal Subsidized'] = {};

    // Student Loan Balance Value
    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance !== 'undefined' ) {
      studentLoanBalanceVal = fedSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    } else {
      studentLoanBalanceVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] = studentLoanBalanceVal;
    // Principal Payback
    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal !== 'undefined' ) {
      principalPaybackVal = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      principalPaybackVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] = principalPaybackVal;
    // Interest Value
    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      fedInterestVal = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      fedInterestVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized'].Interest = fedInterestVal;
    // Payment Value
    higherEdOutputData[year]['Federal Subsidized'].Payment = principalPaybackVal + fedInterestVal;

    if ( higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] > 0 || higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] > 0 || higherEdOutputData[year]['Federal Subsidized'].Interest > 0 || higherEdOutputData[year]['Federal Subsidized'].Payment ) {
      fedMoreYearsList.push( year );
      fedMoreStudentLoanBalArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] ) );
      fedMorePrincipalPaybackArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] ) );
      fedMoreInterestArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized'].Interest ) );
      fedMorePaymentArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized'].Payment ) );
    }
    // Federal Subsidized Values Completed

    // Federal Unsubsidized Values
    higherEdOutputData[year]['Federal Unsubsidized'] = {};
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance !== 'undefined' ) {
      unsubStudentLoanBalanceVal = fedUnSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    } else {
      unsubStudentLoanBalanceVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] = unsubStudentLoanBalanceVal;
    // Principal Payback
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal !== 'undefined' ) {
      unsubPrincipalPaybackVal = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      unsubPrincipalPaybackVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] = unsubPrincipalPaybackVal;
    // Interest Value
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      unsubFedInterestVal = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      unsubFedInterestVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized'].Interest = unsubFedInterestVal;
    // Payment Value
    higherEdOutputData[year]['Federal Unsubsidized'].Payment = unsubPrincipalPaybackVal + unsubFedInterestVal;

    if ( higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] > 0 || higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] || higherEdOutputData[year]['Federal Unsubsidized'].Interest > 0 || higherEdOutputData[year]['Federal Unsubsidized'].Payment > 0 ) {
      fedMoreUnYearsList.push( year );
      fedMoreUnStudentLoanBalArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] ) );
      fedMoreUnPrincipalPaybackArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] ) );
      fedMoreUnInterestArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized'].Interest ) );
      fedMoreUnPaymentArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized'].Payment ) );
    }

    // Private Values
    higherEdOutputData[year].Private = {};
    if ( typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year].EndingBalance !== 'undefined' ) {
      privateStudentLoanBalanceVal = privateMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    } else {
      privateStudentLoanBalanceVal = 0;
    }
    higherEdOutputData[year].Private['Student Loan Balance'] = privateStudentLoanBalanceVal;
    // Principal Payback
    if ( typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal !== 'undefined' ) {
      privatePrincipalPaybackVal = privateMoreHigherEdLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      privatePrincipalPaybackVal = 0;
    }
    higherEdOutputData[year].Private['Principal Payback'] = privatePrincipalPaybackVal;
    // Interest Value
    if ( typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      privateFedInterestVal = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      privateFedInterestVal = 0;
    }
    higherEdOutputData[year].Private.Interest = privateFedInterestVal;

    // Payment Value
    higherEdOutputData[year].Private.Payment = privatePrincipalPaybackVal + privateFedInterestVal;
    if ( higherEdOutputData[year].Private['Student Loan Balance'] > 0 || higherEdOutputData[year].Private['Principal Payback'] > 0 || higherEdOutputData[year].Private.Interest > 0 || higherEdOutputData[year].Private.Payment > 0 ) {
      privateMoreYearsList.push( year );
      privateMoreStudentLoanBalArr.push( Math.round( higherEdOutputData[year].Private['Student Loan Balance'] ) );
      privateMorePrincipalPaybackArr.push( Math.round( higherEdOutputData[year].Private['Principal Payback'] ) );
      privateMoreInterestArr.push( Math.round( higherEdOutputData[year].Private.Interest ) );
      privateMorePaymentArr.push( Math.round( higherEdOutputData[year].Private.Payment ) );
    }
    // Private Values Completed

    // Student Loan Balance Value
    if ( typeof moreHigherStudentLoanData !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year].EndingBalance !== 'undefined' ) {
      studentLoanBalance = moreHigherStudentLoanData['Summation Data'][year].EndingBalance;
    } else {
      studentLoanBalance = 0;
    }

    higherEdOutputData[year]['Student Loan balance'] = studentLoanBalance;

    // Principal payback Value
    if ( typeof moreHigherStudentLoanData !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year].LaggingPrincipal !== 'undefined' ) {
      principalPaybackValue = moreHigherStudentLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      principalPaybackValue = 0;
    }
    higherEdOutputData[year]['Principal payback'] = principalPaybackValue;

    // Interest Value
    if ( typeof moreHigherStudentLoanData !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year] !== 'undefined'
      && typeof moreHigherStudentLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      interestValue = moreHigherStudentLoanData['Summation Data'][year].LaggingInterest;
    } else {
      interestValue = 0;
    }
    higherEdOutputData[year].Interest = interestValue;

    // Payment value
    higherEdOutputData[year].Payment = principalPaybackValue + interestValue;

    // Second Section
    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== '' ) {
      if ( year === module6Data.start_year ) {
        secondYear = module6Data.start_year;
      } else if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' ) {
        if ( ( parseFloat( secondYear ) + 1 ) <= module6Data.graduation_year ) {
          secondYear = parseFloat( secondYear ) + 1;
        } else {
          secondYear = '';
        }
      } else {
        secondYear = '';
      }
    } else {
      secondYear = '';
    }
    higherEdOutputData[year].Year = secondYear;
    // Calculation for Higher Education Room & Board
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'] !== '' ) {
        collegeRoomBoardVal = -incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'];
      } else {
        collegeRoomBoardVal = 0;
      }
    } else {
      collegeRoomBoardVal = '';
    }
    higherEdOutputData[year]['Higher Education Room Board'] = collegeRoomBoardVal;
    if ( collegeRoomBoardVal !== '' ) {
      totalCollegeValue += collegeRoomBoardVal;
    }
    // Calculation for Rent Value
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && typeof incomeStatementnetData[year].livingExpenses.Rent !== 'undefined' && incomeStatementnetData[year].livingExpenses.Rent !== '' ) {
        rentVal = -incomeStatementnetData[year].livingExpenses.Rent;
      } else {
        rentVal = 0;
      }
    } else {
      rentVal = '';
    }

    higherEdOutputData[year].Rent = rentVal;
    if ( rentVal !== '' ) {
      totalRentValue += rentVal;
    }
    // Calculation for Utilities
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Utilities !== '' ) {
        utilitiesVal = -incomeStatementnetData[year].livingExpenses.Utilities;
      } else {
        utilitiesVal = 0;
      }
    } else {
      utilitiesVal = '';
    }
    higherEdOutputData[year].Utilities = utilitiesVal;
    if ( utilitiesVal !== '' ) {
      totalUtilitiesValue += utilitiesVal;
    }

    // Calculation for Off Campus Food
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Off Campus Food'] !== '' ) {
        offCampusFoodVal = -incomeStatementnetData[year].livingExpenses['Off Campus Food'];
      } else {
        offCampusFoodVal = 0;
      }
    } else {
      offCampusFoodVal = '';
    }
    higherEdOutputData[year]['Off Campus Food'] = offCampusFoodVal;
    // Calculation for Food
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Food !== '' ) {
        foodVal = -incomeStatementnetData[year].livingExpenses.Food;
      } else {
        foodVal = 0;
      }
      if ( offCampusFoodVal !== '' ) {
        foodVal += offCampusFoodVal;
      }
    } else {
      foodVal = '';
    }
    higherEdOutputData[year].Food = foodVal;
    if ( foodVal !== '' ) {
      totalFoodValue += foodVal;
    }

    // Calculation for Clothing
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Clothing !== '' ) {
        clothingVal = -incomeStatementnetData[year].livingExpenses.Clothing;
      } else {
        clothingVal = 0;
      }
    } else {
      clothingVal = '';
    }
    higherEdOutputData[year].Clothing = clothingVal;
    if ( clothingVal !== '' ) {
      totalClothingValue += clothingVal;
    }
    // Calculation for Entertainment
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Entertainment !== '' ) {
        entertainmentVal = -incomeStatementnetData[year].livingExpenses.Entertainment;
      } else {
        entertainmentVal = 0;
      }
    } else {
      entertainmentVal = '';
    }
    higherEdOutputData[year].Entertainment = entertainmentVal;
    if ( entertainmentVal !== '' ) {
      totalEntertainmentValue += entertainmentVal;
    }

    // Calculation for Technology
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Technology !== '' ) {
        technologyVal = -incomeStatementnetData[year].livingExpenses.Technology;
      } else {
        technologyVal = 0;
      }
    } else {
      technologyVal = '';
    }
    higherEdOutputData[year].Technology = technologyVal;
    if ( technologyVal !== '' ) {
      totalTechnologyValue += technologyVal;
    }
    // Calculation for Transportation
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Transportation !== '' ) {
        transportationVal = -incomeStatementnetData[year].livingExpenses.Transportation;
      } else {
        transportationVal = 0;
      }
    } else {
      transportationVal = '';
    }
    higherEdOutputData[year].Transportation = transportationVal;
    if ( transportationVal !== '' ) {
      totalTransportationValue += transportationVal;
    }
    // Calculation for Car Maintenance
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Car Maintenance'] !== '' ) {
        carMaintenanceVal = -incomeStatementnetData[year].livingExpenses['Car Maintenance'];
      } else {
        carMaintenanceVal = 0;
      }
    } else {
      carMaintenanceVal = '';
    }
    higherEdOutputData[year]['Car Maintenance'] = carMaintenanceVal;

    // Calculation for Car Insurance
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Car Insurance'] !== '' ) {
        carInsuranceVal = -incomeStatementnetData[year].livingExpenses['Car Insurance'];
      } else {
        carInsuranceVal = 0;
      }
    } else {
      carInsuranceVal = '';
    }
    higherEdOutputData[year]['Car Insurance'] = carInsuranceVal;

    // Calculation for Car payment
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Car Payment'] !== '' ) {
        carPaymentVal = -incomeStatementnetData[year].livingExpenses['Car Payment'];
      } else {
        carPaymentVal = 0;
      }
    } else {
      carPaymentVal = '';
    }
    higherEdOutputData[year]['Car Payment'] = carPaymentVal;

    // Calculation for Car
    if ( higherEdOutputData[year].Year !== '' ) {
      carVal = higherEdOutputData[year]['Car Maintenance'] + higherEdOutputData[year]['Car Insurance'] + higherEdOutputData[year]['Car Payment'];
    } else {
      carVal = '';
    }
    higherEdOutputData[year].Car = carVal;
    if ( carVal !== '' ) {
      totalCarValue += carVal;
    }

    // Calculation for Gas
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Gas !== '' ) {
        gasVal = -incomeStatementnetData[year].livingExpenses.Gas;
      } else {
        gasVal = 0;
      }
    } else {
      gasVal = '';
    }
    higherEdOutputData[year].Gas = gasVal;
    if ( gasVal !== '' ) {
      totalGasValue += gasVal;
    }

    // Calculation for Miscellaneous
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Miscellaneous !== '' ) {
        miscellaneousVal = -incomeStatementnetData[year].livingExpenses.Miscellaneous;
      } else {
        miscellaneousVal = 0;
      }
    } else {
      miscellaneousVal = '';
    }
    higherEdOutputData[year].Miscellaneous = miscellaneousVal;
    if ( miscellaneousVal !== '' ) {
      totalMiscellaneousValue += miscellaneousVal;
    }

    // Calculation for Health Insurance Deductible
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'] !== '' ) {
        healthInsuranceDeductibleVal = -incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'];
      } else {
        healthInsuranceDeductibleVal = 0;
      }
    } else {
      healthInsuranceDeductibleVal = '';
    }
    higherEdOutputData[year]['Health Insurance Deductible'] = healthInsuranceDeductibleVal;

    // Calculation for Health Insurance Premium
    if ( higherEdOutputData[year].Year !== '' ) {
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Health Insurance Premium'] !== '' ) {
        healthInsurancePremiumVal = -incomeStatementnetData[year].livingExpenses['Health Insurance Premium'];
      } else {
        healthInsurancePremiumVal = 0;
      }
    } else {
      healthInsurancePremiumVal = '';
    }
    higherEdOutputData[year]['Health Insurance Premium'] = healthInsurancePremiumVal;

    // Calculation for Health Insurance
    healthInsuranceVal = 0;
    if ( higherEdOutputData[year].Year !== '' && healthInsuranceDeductibleVal !== '' && healthInsurancePremiumVal !== '' ) {
      healthInsuranceVal = higherEdOutputData[year]['Health Insurance Deductible'] + higherEdOutputData[year]['Health Insurance Premium'];
    } else {
      healthInsuranceVal = '';
    }
    higherEdOutputData[year]['Health Insurance'] = healthInsuranceVal;

    if ( healthInsuranceVal !== '' ) {
      totalHealthValue += healthInsuranceVal;
    }

    // Calcualtion Total value
    if ( higherEdOutputData[year].Year !== '' ) {
      totalValues = higherEdOutputData[year]['Higher Education Room Board']
        + higherEdOutputData[year].Rent
        + higherEdOutputData[year].Utilities
        + higherEdOutputData[year].Food
        + higherEdOutputData[year].Clothing
        + higherEdOutputData[year].Entertainment
        + higherEdOutputData[year].Technology
        + higherEdOutputData[year].Transportation
        + higherEdOutputData[year].Car
        + higherEdOutputData[year].Gas
        + higherEdOutputData[year].Miscellaneous
        + higherEdOutputData[year]['Health Insurance'];
    } else {
      totalValues = '';
    }
    higherEdOutputData[year].Total = totalValues;

    // Third Section
    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== '' ) {
      if ( year === module6Data.start_year ) {
        thirdYear = module6Data.start_year;
      } else if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' && typeof thirdYear !== 'undefined' && thirdYear > 0 ) {
        if ( ( parseFloat( thirdYear ) + 1 ) <= module6Data.graduation_year ) {
          thirdYear = parseFloat( thirdYear ) + 1;
        } else {
          thirdYear = '';
        }
      } else {
        thirdYear = '';
      }
    } else {
      thirdYear = '';
    }
    higherEdOutputData[year].Scholorships = {};
    higherEdOutputData[year].Grants = {};
    higherEdOutputData[year]['Financial Aid'] = {};
    higherEdOutputData[year]['Living Expenses'] = {};
    higherEdOutputData[year]['Income Earned during College'] = {};
    higherEdOutputData[year]['Family Contribution'] = {};
    higherEdOutputData[year]['Tuition Costs'] = {};
    higherEdOutputData[year]['Student Loan'] = {};

    if ( thirdYear !== '' ) {
      bar2GraphYearsList.push( year );

      // Calculate Scholarships
      let sourcesScholarshipVal = 0;
      if ( cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && typeof cashFlowData[year]['Cash from Financing Activities'].Scholarships !== 'undefined' && cashFlowData[year]['Cash from Financing Activities'].Scholarships !== '' ) {
        sourcesScholarshipVal = cashFlowData[year]['Cash from Financing Activities'].Scholarships;
      } else {
        sourcesScholarshipVal = 0;
      }
      scholarshipValArr.push( sourcesScholarshipVal );

      // Calculate Grants
      if ( cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && typeof cashFlowData[year]['Cash from Financing Activities'].Grants !== 'undefined' && cashFlowData[year]['Cash from Financing Activities'].Grants !== '' ) {
        grantsSoursesVal = cashFlowData[year]['Cash from Financing Activities'].Grants;
      } else {
        grantsSoursesVal = 0;
      }
      grantsArr.push( grantsSoursesVal );

      // Calculate Financial Aid
      if ( cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && typeof cashFlowData[year]['Cash from Financing Activities']['Other Financial Aid'] !== 'undefined' && cashFlowData[year]['Cash from Financing Activities']['Other Financial Aid'] !== '' ) {
        financialAidSoursesVal = cashFlowData[year]['Cash from Financing Activities']['Other Financial Aid'];
      } else {
        financialAidSoursesVal = 0;
      }
      financiAidValArr.push( financialAidSoursesVal );

      // Uses value for living expenses
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== '' ) {
        livingExpensesUsesVal = higherEdOutputData[year]['Higher Education Room Board']
          + higherEdOutputData[year].Rent
          + higherEdOutputData[year].Utilities
          + higherEdOutputData[year].Food
          + higherEdOutputData[year].Clothing
          + higherEdOutputData[year].Entertainment
          + higherEdOutputData[year].Technology
          + higherEdOutputData[year].Transportation
          + higherEdOutputData[year].Car
          + higherEdOutputData[year].Gas
          + higherEdOutputData[year].Miscellaneous
          + higherEdOutputData[year]['Health Insurance'];
      }
      livingExpensesValArr.push( livingExpensesUsesVal );

      // Sourses for Income Earned during college
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Gross Income'] !== '' ) {
        if ( typeof module6Data.type_of_higher_education !== 'undefined' && module6Data.type_of_higher_education !== '' ) incomeEarnedDuringCollegeval = incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'];
        else incomeEarnedDuringCollegeval = incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'];
      }
      incomeEarnedDuringValArr.push( incomeEarnedDuringCollegeval );

      // Estimated Taxes Array
      if ( typeof databaseData !== 'undefined' && typeof databaseData.Data !== 'undefined' && databaseData.Data !== '' && typeof databaseData.Data['Standard Deductions'] !== 'undefined' && typeof databaseData.Data['Standard Deductions'].Single !== 'undefined' ) {
        if ( parseFloat( incomeEarnedDuringCollegeval ) - parseFloat( databaseData.Data['Standard Deductions'].Single ) > 0 ) {
          incomeVal = parseFloat( incomeEarnedDuringCollegeval ) - parseFloat( databaseData.Data['Standard Deductions'].Single );
        } else {
          incomeVal = 0;
        }
      } else {
        incomeVal = 0;
      }

      estimatedTaxesData = estimatedTaxesCalculation( incomeVal, incomeEarnedDuringCollegeval );
      estimatedTaxesValue = ( incomeEarnedDuringCollegeval / 100 ) * estimatedTaxesData['Total Tax'];
      estimatedTaxesArr.push( estimatedTaxesValue );

      // Sourses value in Family Contribution
      if ( cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && typeof cashFlowData[year]['Cash from Financing Activities']['Family Contribution'] !== 'undefined' && cashFlowData[year]['Cash from Financing Activities']['Family Contribution'] !== '' ) {
        familyContributionVal = cashFlowData[year]['Cash from Financing Activities']['Family Contribution'];
      } else {
        familyContributionVal = 0;
      }
      familyContributionValArr.push( familyContributionVal );

      // Uses value in Tuition Costs
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== '' ) {
        tutionCostsVal = -incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'];
      } else {
        tutionCostsVal = 0;
      }
      tutitionCostValArr.push( tutionCostsVal );
      // Sourses value in Federal Subsidized Student Loan
      if ( fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year]['W/O Accrual'] !== 'undefined' ) {
        federalSubMoreStudentLoanVal1 = fedSubMoreHigherEdLoanData['Summation Data'][year]['W/O Accrual'];
      } else {
        federalSubMoreStudentLoanVal1 = 0;
      }
      if ( fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
        federalSubMoreStudentLoanVal2 = fedSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
      } else {
        federalSubMoreStudentLoanVal2 = 0;
      }
      federalSubMoreStudentLoanVal = federalSubMoreStudentLoanVal1 - federalSubMoreStudentLoanVal2;
      federalMoreStudentLoanValArr.push( federalSubMoreStudentLoanVal );

      // Sourses value in Federal Unsubsidized Student Loan
      if ( fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'] !== 'undefined' ) {
        federalUnsubMoreStudentLoanVal1 = fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'];
      } else {
        federalUnsubMoreStudentLoanVal1 = 0;
      }
      if ( fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
        federalUnsubMoreStudentLoanVal2 = fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
      } else {
        federalUnsubMoreStudentLoanVal2 = 0;
      }
      federalUnsubMoreStudentLoanVal = federalUnsubMoreStudentLoanVal1 - federalUnsubMoreStudentLoanVal2;
      federalUnMoresubStudentLoanValArr.push( federalUnsubMoreStudentLoanVal );

      // Private Student Loan
      if ( privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'] !== 'undefined' ) {
        privateMoreStudentLoanVal1 = privateMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'];
      } else {
        privateMoreStudentLoanVal1 = 0;
      }
      if ( privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
        privateMoreStudentLoanVal2 = privateMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
      } else {
        privateMoreStudentLoanVal2 = 0;
      }
      privateMoreStudentLoanVal = privateMoreStudentLoanVal1 - privateMoreStudentLoanVal2;
      privateMoreStudentLoanValArr.push( privateMoreStudentLoanVal );

      // Student Loan Payments during School
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].InterestExpenses['Student Loans'] !== '' ) {
        moreStudentLoanPaymentVal1 = incomeStatementnetData[year].InterestExpenses['Student Loans'];
      } else {
        moreStudentLoanPaymentVal1 = 0;
      }
      moreStudentLoanPaymentVal = -( moreStudentLoanPaymentVal1 );
      moreStudentLoanRepaymentValArr.push( moreStudentLoanPaymentVal );

      // Other Loan Payments on Other Debt
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Total Interest Expenses'] !== '' ) {
        moreOtherLoansPaymentOnOtherDebtVal1 = incomeStatementnetData[year]['Total Interest Expenses'];
      } else {
        moreOtherLoansPaymentOnOtherDebtVal1 = 0;
      }
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].InterestExpenses['Student Loans'] !== '' ) {
        moreOtherLoansPaymentOnOtherDebtVal5 = incomeStatementnetData[year].InterestExpenses['Student Loans'];
      } else {
        moreOtherLoansPaymentOnOtherDebtVal5 = 0;
      }
      moreOtherLoansPaymentOnOtherDebtVal = -( moreOtherLoansPaymentOnOtherDebtVal1 + moreOtherLoansPaymentOnOtherDebtVal5 );
      moreOtherLoansPaymentOnOtherDebtValArr.push( moreOtherLoansPaymentOnOtherDebtVal );

      // Car Expenses
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Total Car Expenses'] !== '' ) {
        moreTotalCarExpensesVal = -incomeStatementnetData[year]['Total Car Expenses'];
      } else {
        moreTotalCarExpensesVal = 0;
      }
      moreTotalCarExpensesValArr.push( moreTotalCarExpensesVal );

      // Real Estate Expenses
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Total Real Estate Expenses'] !== '' ) {
        moreRealEstateExpensesVal = -incomeStatementnetData[year]['Total Real Estate Expenses'];
      } else {
        moreRealEstateExpensesVal = 0;
      }
      moreRealEstateExpensesValArr.push( moreRealEstateExpensesVal );

      // Children and Pet Expenses
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Children !== '' ) {
        moreChildrenAndPetExpensesVal1 = incomeStatementnetData[year].livingExpenses.Children;
      } else {
        moreChildrenAndPetExpensesVal1 = 0;
      }
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses.Pet !== '' ) {
        moreChildrenAndPetExpensesVal2 = incomeStatementnetData[year].livingExpenses.Pet;
      } else {
        moreChildrenAndPetExpensesVal2 = 0;
      }
      moreChildrenAndPetExpensesVal = -( moreChildrenAndPetExpensesVal1 + moreChildrenAndPetExpensesVal2 );
      moreChildrenAndPetExpensesValArr.push( moreChildrenAndPetExpensesVal );

      // Sourses value in Student Loan
      if ( cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[year] !== 'undefined' && typeof cashFlowData[year]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'] !== 'undefined' && cashFlowData[year]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'] !== '' ) {
        studentLoanVal = cashFlowData[year]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'];
      } else {
        studentLoanVal = 0;
      }
      studentLoanValArr.push( studentLoanVal );
    }

    if ( higherEdOutputData[year].Interest > 0 || higherEdOutputData[year]['Student Loan balance'] > 0 || higherEdOutputData[year].Payment > 0 || higherEdOutputData[year]['Principal payback'] > 0 ) {
      yearsListData.push( year );
      studentLoanBalanceData.push( Math.round( higherEdOutputData[year]['Student Loan balance'] ) );
      paymentData.push( Math.round( higherEdOutputData[year].Payment ) );
      principalPaybackData.push( Math.round( higherEdOutputData[year]['Principal payback'] ) );
      interestData.push( Math.round( higherEdOutputData[year].Interest ) );
    }
    higherEdOutputData['Bar Graph1'].yearsList = yearsListData;
    higherEdOutputData['Bar Graph1'].studentLoanbalance = studentLoanBalanceData;
    higherEdOutputData['Bar Graph1'].Payment = paymentData;
    higherEdOutputData['Bar Graph1']['Principal payback'] = principalPaybackData;
    higherEdOutputData['Bar Graph1'].Interest = interestData;
  }

  function higherEdOutputBackendData() {
    year = parseInt( module6Data.start_year, 10 );
    yearLimit = parseInt( module6Data.start_year, 10 ) + 4;

    const graphvaluesData = {};
    graphvaluesData['Pie Chart'] = {};
    graphvaluesData['Bar Graph1'] = {};
    graphvaluesData['Bar Graph2'] = {};
    graphvaluesData['Annual Graph'] = {};
    graphvaluesData['Annual Graph'] = higherEdOutputData;
    graphvaluesData['Pie Chart'].totalCollegeValue = totalCollegeValue;
    graphvaluesData['Pie Chart'].totalRentValue = totalRentValue;
    graphvaluesData['Pie Chart'].totalUtilitiesValue = totalUtilitiesValue;
    graphvaluesData['Pie Chart'].totalFoodValue = totalFoodValue;
    graphvaluesData['Pie Chart'].totalClothingValue = totalClothingValue;
    graphvaluesData['Pie Chart'].totalEntertainmentValue = totalEntertainmentValue;
    graphvaluesData['Pie Chart'].totalTechnologyValue = totalTechnologyValue;
    graphvaluesData['Pie Chart'].totalTransportationValue = totalTransportationValue;
    graphvaluesData['Pie Chart'].totalCarValue = totalCarValue;
    graphvaluesData['Pie Chart'].totalGasValue = totalGasValue;
    graphvaluesData['Pie Chart'].totalMiscellaneousValue = totalMiscellaneousValue;
    graphvaluesData['Pie Chart'].totalHealthInsuranceValue = totalHealthValue;
    // Total Amount

    graphvaluesData['Pie Chart'].Total = parseFloat( totalCollegeValue )
      + parseFloat( totalRentValue )
      + parseFloat( totalUtilitiesValue )
      + parseFloat( totalFoodValue )
      + parseFloat( totalClothingValue )
      + parseFloat( totalEntertainmentValue )
      + parseFloat( totalTechnologyValue )
      + parseFloat( totalTransportationValue )
      + parseFloat( totalCarValue )
      + parseFloat( totalGasValue )
      + parseFloat( totalMiscellaneousValue )
      + parseFloat( totalHealthValue );

    graphvaluesData['Bar Graph1'] = higherEdOutputData['Bar Graph1'];
    graphvaluesData['Bar Graph2 YearsList'] = bar2GraphYearsList;

    graphvaluesData['Bar Graph2'].Scholarships = scholarshipValArr;
    graphvaluesData['Bar Graph2'].Grants = grantsArr;
    graphvaluesData['Bar Graph2']['Financial Aid'] = financiAidValArr;
    graphvaluesData['Bar Graph2']['Estimated Taxes'] = estimatedTaxesArr;
    graphvaluesData['Bar Graph2']['Living Expenses'] = livingExpensesValArr;
    graphvaluesData['Bar Graph2']['Income Earned During'] = incomeEarnedDuringValArr;
    graphvaluesData['Bar Graph2']['Family Contribution'] = familyContributionValArr;
    graphvaluesData['Bar Graph2']['Tuition Cost'] = tutitionCostValArr;
    graphvaluesData['Bar Graph2']['Federal Subsidized Student Loan'] = federalMoreStudentLoanValArr;
    graphvaluesData['Bar Graph2']['Federal Unsubsidized Student Loan'] = federalUnMoresubStudentLoanValArr;
    graphvaluesData['Bar Graph2']['Private Student Loan'] = privateMoreStudentLoanValArr;

    graphvaluesData['Bar Graph2']['Student Loan Payments during School'] = moreStudentLoanRepaymentValArr;
    graphvaluesData['Bar Graph2']['Other Loan Payments on Other Debt'] = moreOtherLoansPaymentOnOtherDebtValArr;
    graphvaluesData['Bar Graph2']['Car Expenses'] = moreTotalCarExpensesValArr;
    graphvaluesData['Bar Graph2']['Real Estate Expenses'] = moreRealEstateExpensesValArr;
    graphvaluesData['Bar Graph2']['Children and Pet Expenses'] = moreChildrenAndPetExpensesValArr;

    graphvaluesData['Federal Subsidized Graph'] = {};
    graphvaluesData['Federal Subsidized Graph'].yearsList = fedMoreYearsList;
    graphvaluesData['Federal Subsidized Graph']['Student Loan Balance'] = fedMoreStudentLoanBalArr;
    graphvaluesData['Federal Subsidized Graph']['Principal Payback'] = fedMorePrincipalPaybackArr;
    graphvaluesData['Federal Subsidized Graph'].Interest = fedMoreInterestArr;
    graphvaluesData['Federal Subsidized Graph'].Payment = fedMorePaymentArr;

    graphvaluesData['Federal Unsubsidized Graph'] = {};
    graphvaluesData['Federal Unsubsidized Graph'].yearsList = fedMoreUnYearsList;
    graphvaluesData['Federal Unsubsidized Graph']['Student Loan Balance'] = fedMoreUnStudentLoanBalArr;
    graphvaluesData['Federal Unsubsidized Graph']['Principal Payback'] = fedMoreUnPrincipalPaybackArr;
    graphvaluesData['Federal Unsubsidized Graph'].Interest = fedMoreUnInterestArr;
    graphvaluesData['Federal Unsubsidized Graph'].Payment = fedMoreUnPaymentArr;

    graphvaluesData['Private Graph'] = {};
    graphvaluesData['Private Graph'].yearsList = privateMoreYearsList;
    graphvaluesData['Private Graph']['Student Loan Balance'] = privateMoreStudentLoanBalArr;
    graphvaluesData['Private Graph']['Principal Payback'] = privateMorePrincipalPaybackArr;
    graphvaluesData['Private Graph'].Interest = privateMoreInterestArr;
    graphvaluesData['Private Graph'].Payment = privateMorePaymentArr;

    return graphvaluesData;
  }
  // console.log( 'estimatedDataArray----', higherEdOutputBackendData() );
  return higherEdOutputBackendData();
}
export default moreHigherEducationSheetOutput;
