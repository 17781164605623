import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';



const BitCoinAnswerPopups = (props) => {
  const { classes, challengeIndex } = props;

  return (
    <Typography variant="body1" component="div">

      { /* bitcoin XP questions  Popup */}
      <div className={classes.completeActivity}>
        <div className={classes.completePopup}>
          {challengeIndex === 1 && (
            <div className={classes.completePopupChallenge}>
              <h1 >Mary's federal income taxes</h1>
              <table className={classes.federal}>
                  <thead>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                      <td className={classes.textRight}><strong>Year 2020</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                      <td className={classes.textRight}>$112,500.00</td>
                    </tr>
                    <tr >
                      <td colspan="4">&nbsp;</td>
                      <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                      <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>($12,400.00)</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                      <td className={classes.textRight}><strong>$100,100.00</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textCenter}><strong>Rate</strong></td>
                      <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Single)</strong></td>
                      <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                      <td className={classes.textCenter}><strong>Taxes</strong></td>
                    </tr>
                  </thead>
                  <tbody className={classes.federalTbody}>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>10.00%</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$987.50</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>12.00%</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$40,125.00</td>
                      <td className={classes.textRight}>$30,250.00</td>
                      <td className={classes.textRight}>$3,630.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>22.00%</td>
                      <td className={classes.textRight}>$40,125.00</td>
                      <td className={classes.textRight}>$85,525.00</td>
                      <td className={classes.textRight}>$45,400.00</td>
                      <td className={classes.textRight}>$9,988.00</td>
                      <td></td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>24.00%</td>
                      <td className={classes.textRight}>$85,525.00</td>
                      <td className={classes.textRight}>$163,300.00</td>
                      <td className={classes.textRight}>$14,575.00</td>
                      <td className={classes.textRight}>$3,498.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>32.00%</td>
                      <td className={classes.textRight}>$163,300.00</td>
                      <td className={classes.textRight}>$207,350.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>35.00%</td>
                      <td className={classes.textRight}>$207,350.00</td>
                      <td className={classes.textRight}>$518,400.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>37.00%</td>
                      <td className={classes.textRight}>$518,400.00</td>
                      <td >&nbsp;</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">&nbsp;</td>
                      <td className={classes.textRight}><strong>$100,100.00</strong></td>
                      <td className={classes.textRight}><strong>$18,103.50</strong></td>
                    </tr>
                  </tfoot>
                </table>
            </div>
          )}

          {challengeIndex === 2 && (
            <div className={classes.completePopupChallenge}>
              <h1>Mary's federal income taxes</h1>
              <table className={classes.federal}>
                  <thead>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                      <td className={classes.textRight}><strong>Year 2020</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                      <td className={classes.textRight}>$80,000.00</td>
                    </tr>
                    <tr >
                      <td colspan="4">&nbsp;</td>
                      <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                      <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>($24,800.00)</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                      <td className={classes.textRight}><strong>$55,200.00</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textCenter}><strong>Rate</strong></td>
                      <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Married)</strong></td>
                      <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                      <td className={classes.textCenter}><strong>Taxes</strong></td>
                    </tr>
                  </thead>
                  <tbody className={classes.federalTbody}>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>10.00%</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$19,750.00</td>
                      <td className={classes.textRight}>$19,750.00</td>
                      <td className={classes.textRight}>$1,975.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>12.00%</td>
                      <td className={classes.textRight}>$19,750.00</td>
                      <td className={classes.textRight}>$80,250.00</td>
                      <td className={classes.textRight}>$35,450.00</td>
                      <td className={classes.textRight}>$4,254.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>22.00%</td>
                      <td className={classes.textRight}>$80,250.00</td>
                      <td className={classes.textRight}>$171,050.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td></td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>24.00%</td>
                      <td className={classes.textRight}>$171,050.00</td>
                      <td className={classes.textRight}>$326,600.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>32.00%</td>
                      <td className={classes.textRight}>$326,600.00</td>
                      <td className={classes.textRight}>$414,700.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>35.00%</td>
                      <td className={classes.textRight}>$414,700.00</td>
                      <td className={classes.textRight}>$622,050.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>37.00%</td>
                      <td className={classes.textRight}>$622,050.00</td>
                      <td >&nbsp;</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">&nbsp;</td>
                      <td className={classes.textRight}><strong>$55,200.00</strong></td>
                      <td className={classes.textRight}><strong>$6,229.00</strong></td>
                    </tr>
                  </tfoot>
                </table>

            </div>
          )}

          {challengeIndex === 3 && (
            <div className={classes.completePopupChallenge}>
              {/* <h1>POWERUP CHALLENGE EXPLANATION</h1> */}
              <h1 >Mary's federal income taxes</h1>
              <table className={classes.federal}>
                  <thead>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                      <td className={classes.textRight}><strong>Year 2020</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                      <td className={classes.textRight}>$150,000.00</td>
                    </tr>
                    <tr >
                      <td colspan="4">&nbsp;</td>
                      <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                      <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>($12,400.00)</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                      <td className={classes.textRight}><strong>$137,600.00</strong></td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textCenter}><strong>Rate</strong></td>
                      <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Single)</strong></td>
                      <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                      <td className={classes.textCenter}><strong>Taxes</strong></td>
                    </tr>
                  </thead>
                  <tbody className={classes.federalTbody}>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>10.00%</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$987.50</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>12.00%</td>
                      <td className={classes.textRight}>$9,875.00</td>
                      <td className={classes.textRight}>$40,125.00</td>
                      <td className={classes.textRight}>$30,250.00</td>
                      <td className={classes.textRight}>$3,630.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>22.00%</td>
                      <td className={classes.textRight}>$40,125.00</td>
                      <td className={classes.textRight}>$85,525.00</td>
                      <td className={classes.textRight}>$45,400.00</td>
                      <td className={classes.textRight}>$9,988.00</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>24.00%</td>
                      <td className={classes.textRight}>$85,525.00</td>
                      <td className={classes.textRight}>$163,300.00</td>
                      <td className={classes.textRight}>$52,075.00</td>
                      <td className={classes.textRight}>$12,498.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>32.00%</td>
                      <td className={classes.textRight}>$163,300.00</td>
                      <td className={classes.textRight}>$207,350.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>35.00%</td>
                      <td className={classes.textRight}>$207,350.00</td>
                      <td className={classes.textRight}>$518,400.00</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                    <tr >
                      <td>&nbsp;</td>
                      <td className={classes.textRight}>37.00%</td>
                      <td className={classes.textRight}>$518,400.00</td>
                      <td >&nbsp;</td>
                      <td className={classes.textRight}>$0.00</td>
                      <td className={classes.textRight}>$0.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">&nbsp;</td>
                      <td className={classes.textRight}><strong>$137,600.00</strong></td>
                      <td className={classes.textRight}><strong>$27,103.50</strong></td>
                    </tr>
                  </tfoot>
                </table>

            </div>
          )}
        </div>
      </div>
    </Typography>
  );
};

BitCoinAnswerPopups.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinAnswerPopups);
