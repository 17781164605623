import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import classNames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-vacation/styles';
import _ from 'lodash';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#005F89', '#B04400'],
});
HCExporting(Highcharts);

const PlanningVacation = (props) => {
  const {
    classes, inputData, updateData
  } = props;

  const [inputs, setInputs] = React.useState({});

  useEffect(()=>{
    let inputValues = {...inputs};
    if(inputData.flight !== undefined) {
      inputValues.flight = inputData.flight;
      inputValues.trains = inputData.trains;
      inputValues.other_transportation = inputData.other_transportation;
      inputValues.hotels = inputData.hotels;
      inputValues.food_drink = inputData.food_drink;
      inputValues.activities = inputData.activities;
      inputValues.gifts = inputData.gifts;
      inputValues.other = inputData.other;
    } else if(inputData.yearly_cost !== undefined) {
      inputValues.flight = 0;
      inputValues.trains = 0;
      inputValues.other_transportation = 0;
      inputValues.hotels  = 0;
      inputValues.food_drink = 0;
      inputValues.activities = 0;
      inputValues.gifts = 0;
      inputValues.other = inputData.yearly_cost;
    } else {
      inputValues.flight = 0;
      inputValues.trains = 0;
      inputValues.other_transportation = 0;
      inputValues.hotels  = 0;
      inputValues.food_drink = 0;
      inputValues.activities = 0;
      inputValues.gifts = 0;
      inputValues.other = 0;
    }
    inputValues['yearly_cost'] = 0;
    inputValues['yearly_cost'] = _.sum(Object.values(inputValues));
    updateData('total_obj', {...inputData, ...inputValues});
    setInputs(inputValues);
  },[])

 
  const handlePlanningVacation = (event, value) => {
    
    let newValue = 0;
    newValue = newValue !== undefined ? event.floatValue : 0;

    let inputDetails = {...inputs};
    inputDetails[value] = newValue;
    inputDetails['yearly_cost'] = 0;
    inputDetails['yearly_cost'] = _.sum(Object.values(inputDetails));
    setInputs(inputDetails);
    updateData('total_obj', {...inputData, ...inputDetails});    
  };
  return (
    <div className={classes.contentBlock}>
      <p>
        How much money will you allocate each year for your vacation? Have you
        planned for flights, hotels, food and more? Check the table below after
        completing your vacation expenses and make sure you have enough cash to
        travel and still hitting your savings goals.
      </p>
      <div className={classNames(classes.vacationCategories, classes.vacationTbl)}>
        <div className={classes.vacationCategoriesTbale}>
          <table>
            <thead>
              <tr>
                <th>Vacation Categories</th>
                <th>
                  Year
                  {' '}
                  {' '}
                  {inputData.start_year !== '0' && (
                    <span>{inputData.start_year}</span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Flights</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.flight} onValueChange={(e) => { handlePlanningVacation(e, 'flight'); }} onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Trains</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.trains} onValueChange={(e) => { handlePlanningVacation(e, 'trains'); }}  onFocus={(e) => { e.target.select(); }} />

                  </div>
                </td>
              </tr>
              <tr>
                <td>Other Transportation</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.other_transportation} onValueChange={(e) => { handlePlanningVacation(e, 'other_transportation'); }}  onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Hotels</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.hotels} onValueChange={(e) => { handlePlanningVacation(e, 'hotels'); }}  onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Food & Drink</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.food_drink} onValueChange={(e) => { handlePlanningVacation(e, 'food_drink'); }}  onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Activities</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.activities} onValueChange={(e) => { handlePlanningVacation(e, 'activities'); }} onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Souvenirs / Gifts</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.gifts} onValueChange={(e) => { handlePlanningVacation(e, 'gifts'); }}  onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Other</td>
                <td>
                  <div className={classNames(classes.amountDollar, classes.amountFlexDollar)}>
                    <span>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={TextField} thousandSeparator value={inputs.other} onValueChange={(e) => { handlePlanningVacation(e, 'other'); }}  onFocus={(e) => { e.target.select(); }} />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Annual Vacation Costs</th>
                <th>
                  $
                  <NumberFormat decimalScale={ 0 } displayType="text" fixedDecimalScale allowNegative={ false }  thousandSeparator value={inputs.yearly_cost}  />
                </th>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className={classes.vacationGraph}>
          { inputs.yearly_cost !== undefined && (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  plotBackgroundColor: '#ffffff',
                  plotBorderColor: '#cccccc',
                  plotBorderWidth: null,
                  plotShadow: false,
                  type: 'pie',
                  height: 450,
                  // width: graphMainWidth
                },
                navigation: {
                  menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left'
                  },
                  menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px'
                  },
                  buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    _titleKey: 'y'
                  }
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [{
                        textKey: 'downloadPNG',
                        onclick() {
                          const chart = this;
                          chart.exportChart();
                        },
                      }, {
                        textKey: 'downloadJPEG',
                        onclick() {
                          const chart = this;
                          chart.exportChart({
                            type: 'image/jpeg'
                          });
                        }
                      }, {
                        textKey: 'downloadPDF',
                        onclick() {
                          const chart = this;
                          chart.exportChart({
                            type: 'application/pdf'
                          });
                        }
                      }, {
                        textKey: 'downloadSVG',
                        onclick() {
                          const chart = this;
                          chart.exportChart({
                            type: 'image/svg+xml'
                          });
                        }
                      }]
                    }
                  }
                },
                credits: {
                  enabled: false
                },
                title: {
                  text: ''
                },
                tooltip: {
                  // eslint-disable-next-line
                  pointFormat: '${point.y:,.0f} ({point.percentage:.0f}%)'                
                },
                plotOptions: {
                  pie: {
                    cursor: 'pointer',
                    dataLabels: {
                      distance: -30,
                      enabled: true,
                      format: '{point.percentage:.0f}%',
                      style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                        fontSize: '14px',
                        textOutline: false
                      }
                    },
                    showInLegend: true
                  }
                },
                legend: {
                  backgroundColor: '#F5F5F5',
                  align: 'center'
                },
                series: [{
                  name: 'Expenses',
                  type: 'pie',
                  data: [
                    ['Flights', inputs.flight],
                    ['Trains', inputs.trains],
                    ['Other Transportation', inputs.other_transportation],
                    ['Hotels', inputs.hotels],
                    ['Food & Drink', inputs.food_drink],
                    ['Activities', inputs.activities],
                    ['Souvenirs / Gifts', inputs.gifts],
                    ['Other', inputs.other],
                  ].filter(function (d) {
                    return d[1] > 0
                  })
                }]
              }}
            />
          )}
        </div>

      </div>
    </div>
  );
};

PlanningVacation.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData:PropTypes.func.isRequired
};

export default withStyles(styles)(PlanningVacation);
