import Pf101Level1PersonalFinance101Exercise from './level1/personal-finance-101';
import Pf101Level2ThreeTypesOfIncomeExercise from './level2/3-types-of-income';
import Pf101Level2IncomeTaxes101Exercise from './level2/income-taxes-101';
import Pf101Level2FederalIncomeTaxesExercise from './level2/federal-income-taxes';
import Pf101Level2FilingTaxesExercise from './level2/filing-taxes';
import Pf101Level3Budgeting101Exercise from './level3/budgeting-101';
import Pf101Level3BudgetingGuideExercise from './level3/budgeting-guide';
import Pf101Level4CheckingSavingsExercise from './level4/checking-savings';
import Pf101Level4DebitCreditExercise from './level4/debit-credit';
import Pf101Level4BankResponsiblyExercise from './level4/bank-responsibly';
import Pf101Level5OnlineBankingExercise from './level5/online-banking';
import Pf101Level5MobileBankingExercise from './level5/mobile-banking';
import Pf101Level5SafeOnlineBankingTipsExercise from './level5/safe-online-banking-tips';
import Pf101Level6CreditScore101Exercise from './level6/credit-score-101';
import Pf101Level6DebunkingCreditScoreMythsExercise from './level6/debunking-credit-score-myths';
import Pf101Level6Loan101Exercise from './level6/loan-101';
import Pf101Level6StudentLoansExercise from './level6/student-loans';
import Pf101Level6LoanRepaymentExercise from './level6/loan-repayment';
import Pf101Level6LowerCostOfLoanExercise from './level6/lower-cost-of-loan';
import Pf101Level7Investing101Exercise from './level7/investing-101';
import Pf101Level7StockAndBoundsExercise from './level7/stock-and-bounds';
import Pf101Level7FundsAlternativeInvestmentsExercise from './level7/funds-alternative-investments';
import Pf101Level7RetirementAccountsExercise from './level7/retirement-accounts';
import Pf101Level7HowToStartInvestingExercise from './level7/how-to-start-investing';

import Pf101Level8HealthInsuranceExercise from './level8/health-insurance';
import Pf101Level8LifeInsuranceExercise from './level8/life-insurance';
import Pf101Level8AssetInsuranceExercise from './level8/asset-insurance';
import Pf101Level9SmartGoalsExercise from './level9/smart-goals';
import Pf101Level9PersonalFinancePurposeExercise from './level9/personal-finance-purpose';
import Pf101Level9Pf101BluePrintExercise from './level9/blue-print';
import Pf101Level10FinancialLifeToolkit from './level10/financial-life-toolkit';

export default Pf101Level1PersonalFinance101Exercise;
export {
  Pf101Level2ThreeTypesOfIncomeExercise,
  Pf101Level2IncomeTaxes101Exercise,
  Pf101Level2FederalIncomeTaxesExercise,
  Pf101Level2FilingTaxesExercise,
  Pf101Level3Budgeting101Exercise,
  Pf101Level3BudgetingGuideExercise,
  Pf101Level4CheckingSavingsExercise,
  Pf101Level4DebitCreditExercise,
  Pf101Level4BankResponsiblyExercise,
  Pf101Level5OnlineBankingExercise,
  Pf101Level5MobileBankingExercise,
  Pf101Level5SafeOnlineBankingTipsExercise,
  Pf101Level6CreditScore101Exercise,
  Pf101Level6DebunkingCreditScoreMythsExercise,
  Pf101Level6Loan101Exercise,
  Pf101Level6StudentLoansExercise,
  Pf101Level6LoanRepaymentExercise,
  Pf101Level6LowerCostOfLoanExercise,
  Pf101Level7Investing101Exercise,
  Pf101Level7StockAndBoundsExercise,
  Pf101Level7FundsAlternativeInvestmentsExercise,
  Pf101Level7RetirementAccountsExercise,
  Pf101Level7HowToStartInvestingExercise,
  Pf101Level8HealthInsuranceExercise,
  Pf101Level8LifeInsuranceExercise,
  Pf101Level8AssetInsuranceExercise,
  Pf101Level9SmartGoalsExercise,
  Pf101Level9PersonalFinancePurposeExercise,
  Pf101Level9Pf101BluePrintExercise,
  Pf101Level10FinancialLifeToolkit,
};
