import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import puzzle from '../../assets/img/young_pros/mega-menu-pricing.png';
import s1 from '../../assets/img/young_pros/students_parents_awareness_challenge.png';
import s2 from '../../assets/img/young_pros/students_parents_discovery_challenge.png';
import s3 from '../../assets/img/young_pros/PF101-icon-square.png';
import s4 from '../../assets/img/young_pros/students_parents_career_sketching.png';
import s5 from '../../assets/img/young_pros/students_parents_finance_explained.png';
import banner from '../../assets/img/young_pros/young_professionals_banner_left.png';

const YoungPros = ( props ) => {
  const { classes, history } = props;

  const redirectToCP = () => {
    history.push( '/plan-with-inkwiry/cp' );
  };

  const redirectToYoungProfessional = () => {
    history.push( '/why-inkwiry/young-professionals' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.benfitsBanner }>
        <div className={ classes.container }>
          <Grid container spacing={ 2 } className={ classes.justyfyCenter }>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <div className={ classes.bannerLeft }>
                <img src={ banner } alt="Young Pros Banner" />
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
              <div className={ classes.bannerRight }>
                <Typography variant="h1" component="h1">
                  In less than 15 minutes,
                  <br />
                  {' '}
                  let&apos;s build a financial plan
                  <br />
                  {' '}
                  for your career.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <section className={ classes.whyInkwiryDesc }>
        <Container className={ classes.container }>
          <div className={ classes.whyInkwryDesCnt }>
            <div className={ classes.pwiPuzzle }>
              <img className={ classes.puzzleImg } src={ puzzle } alt="puzzleicon" />
              <Typography className={ classes.pwiTitle } variant="h2" component="h2">Plan with Inkwiry</Typography>
              <Typography className={ classes.pwiSubTitle } variant="h3" component="h3">Identify your goals. Manage your debt. Invest in your future.</Typography>
            </div>
            <div className={ classes.whyInkwirySeeIf }>
              <Typography className={ classes.pwiTitle } variant="h2" component="h2">Get to know your money.</Typography>
              <Typography className={ classes.pwiSubTitle } variant="h3" component="h3">See if your career path supports your life plan.</Typography>
              <div className={ classes.buildMyPlan }>
                <Button onClick={ redirectToCP }>
                  Build My Plan
                  {' '}
                  {' '}
                  {'>'}
                </Button>
              </div>
            </div>
            <ul className={ classes.whyInkwirySeeIfList }>
              <li>INCOME</li>
              <li>EXPENSES</li>
              <li>LOANS</li>
              <li>BUDGET</li>
              <li>SAVINGS</li>
              <li>INVESTMENTS</li>
            </ul>
            <div className={ classes.whyInkwiryPowerTools }>
              <Typography className={ classes.powerToolsTitle } variant="h2" component="h2">Power Tools to accelerate your planning</Typography>
              <div className={ classes.powerTools }>
                <Grid container spacing={ 2 } className={ classes.powerToolsRow }>
                  <Grid item xs={ 12 } sm={ 2 } className={ classes.powerToolsSpace }>
                    <div className={ classes.toolsImg }>
                      <img src={ s1 } alt="Awareness Challenge" />
                    </div>
                    <p>Awareness Challenge</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 2 } className={ classes.powerToolsSpace }>
                    <div className={ classes.toolsImg }>
                      <img src={ s2 } alt="Discovery Challenge" />
                    </div>
                    <p>Discovery Challenge</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 2 } className={ classes.powerToolsSpace }>
                    <div className={ classes.toolsImg }>
                      <img src={ s3 } alt="PowerUp Courses" />
                    </div>
                    <p>PowerUp Courses</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 2 } className={ classes.powerToolsSpace }>
                    <div className={ classes.toolsImg }>
                      <img src={ s4 } alt="Career Sketching" />
                    </div>
                    <p>Career Sketching</p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 2 } className={ classes.powerToolsSpace }>
                    <div className={ classes.toolsImg }>
                      <img src={ s5 } alt="Finance Explained" />
                    </div>
                    <p>Finance Explained</p>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className={ classes.inkwiryTogether }>
              <Typography className={ classes.powerToolsTitle } variant="h2" component="h2">Together, we can find solutions.</Typography>
              <Typography className={ classes.powerToolsTitle } variant="h3" component="h3">Take a deeper dive into how Inkwiry can help you plan:</Typography>
              <Button onClick={ redirectToYoungProfessional } className={ classes.studentsParentsBtn }>
                Inkwiry for
                {' '}
                <span>Young Professionals&nbsp;&nbsp;&gt;</span>
              </Button>
            </div>

          </div>
        </Container>
      </section>
      <ScrollToTop />
    </Typography>
  );
};

YoungPros.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( YoungPros );
