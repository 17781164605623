import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import NumberFormat from 'react-number-format';
import { Form, Field } from 'react-final-form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from 'final-form-material-ui';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import styles from './styles';
import dummyUser from '../../assets/img/desktop/user.png';
import infoIcon from '../../assets/img/pwi-he/info-icon.svg';
import profilePlus from '../../assets/img/dashboard/add_career_sketch_icon.png';
import yearText from '../../assets/img/dashboard/5years.png';
import yearText2 from '../../assets/img/dashboard/10years.png';
import yearText3 from '../../assets/img/dashboard/15years.png';
import { formatDollar } from '../../utilities/chartCommonFunctions';
import * as moduleServices from '../../calculations/modules-services';
import * as graphs from '../../utilities/dashboardCharts';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Sight from '../../components/sight';
import Pageloader from '../../components/ui/pageloader';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import SummaryDocs from '../../components/documents/documents';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { useQuery } from '@apollo/react-hooks';


const DASHBOARD_GOALS = loader('../../graphql/schema/auth/dashboard-goals.graphql');
const LOAD_SKETCHS = loader('../../graphql/schema/dashboard/loadSketch.graphql');
const GOALFORM = loader('../../graphql/schema/auth/save-dashboard-goals.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const DELETE_CAREER_SKETCHS = loader('../../graphql/schema/dashboard/deleteCareerSketch.graphql');
const imageUrl = require.context('../../assets/img/career_sketches/career_sketches/', true)

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;


function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);
const colorCode = ['#F4B183', '#4B92D1', '#F27724', '#A8A8A8', '#F1BB00', '#CEDFFF', '#72AF4A', '#EF27C5', '#360028', '#CCE6A0'];




const Desktop = (props) => {
  const { classes } = props;
  const loadedCareerSketchList = [];
  // initialize all 8 sketchs default data 
  for (let i = 1; i < 8; i++) {
    let sketch = {};
    sketch['name'] = '';
    sketch['netWoth'] = '';
    sketch['image'] = '';
    sketch['status'] = '';
    sketch['subname'] = '';
    sketch['create_date'] = '';
    loadedCareerSketchList.push(sketch);
  }

  const fiIntialValues = {
    average_income: 0,
    income_range_min: 0,
    income_range_max: 0
  };

  const [value, setValue] = useState(0);
  const [valueTab, setValueTab] = useState(0);
  const [editGoals, setEditGoals] = useState(false);
  const [jobOutlook, setJobOutlook] = useState("bright");
  const [errorMessage, setErrorMessage] = useState('');
  const [updatedFIValues, setUpdatedFIValues] = React.useState(fiIntialValues);
  const [goalId, setGoalId] = useState('');
  const [goalsData, setGoalsData] = useState("");


  const [loadSketchList, setLoadSketchList] = useState();
  const [newWorthGrowth, setNewWorthGrowth] = useState([]);
  const [newExpensesGross, setNewExpensesGross] = useState([]);
  const [newWorthGross, setNewWorthGross] = useState([]);

  // const [careerSketches, setCareerSketches] = useState([]);
  const [sightData, setSightData] = React.useState({});
  const [openSightPopup, setOpenSightPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [openPopup, setOpenPopup] = React.useState('');
  const [count, setCount] = React.useState(0);
  const { data: myDetails } = useQuery(MY_DETAILS);


  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  useEffect(() => {
    getModuleData();
  }, []);

  const recallDataApi = (type) => {
    if (type === 'sketch-load') {
      setLoading(true);
      getModuleData();
    }
  };

  const [getGoalsData] = useLazyQuery(DASHBOARD_GOALS, {
    fetchPolicy: 'network-only',
    variables: {
    },
    onCompleted(response) {
      if (response) {
        setGoalsData(response.getDashboardGoals);
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const [submitGoalForm] = useMutation(GOALFORM, {
    onCompleted({
      saveDashboardGoals: {
        status,
      },
    }) {
      if (status) {
        setEditGoals(false);
        getGoalsData();
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
    },
  });

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
      });
      setLoading(false);
      setOpenSightPopup(false);
      if (count === 0) {
        setTimeout(function () {
          setCount(1);
        }, 4000);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const [DeleteSketchData] = useLazyQuery(DELETE_CAREER_SKETCHS, {
    fetchPolicy: 'network-only',
    onCompleted({
      deleteSketch: {
        status,
        message,
      },
    }) {
      if (status === true) {
        getLoadSketches();
        setLoading(false);

      } else {
        setLoading(false);
      }
    },
    onError() {
    },
  });

  const handleDelete = (id) => {
    setLoading(true);
    DeleteSketchData({ variables: { sketch_id: id } });
  }


  const [getLoadSketches] = useLazyQuery(LOAD_SKETCHS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response) {
        let careerSketche = [];
        // let ij = 0;
        let newWorthGrowthVal = [];
        let newWorthGrossVal = [];
        let newExpensesGrossVal = [];
        let loadedCareerSketchLisst = [];
        loadedCareerSketchLisst = loadedCareerSketchList;
        let dashboardNetWorthData = moduleServices.dashBoardCalcsCompleteData();
        if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
          && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
          && typeof dashboardNetWorthData['Data']['Net Worth Graph'] !== 'undefined' && dashboardNetWorthData['Data']['Net Worth Graph'] !== '') {
          var dashBoardOutputSheetCompleteData = dashboardNetWorthData['Data']['Net Worth Graph'];
        }

        let newWorthArray = dashBoardOutputSheetCompleteData['Net Worth'];
        let grossArray = dashBoardOutputSheetCompleteData['Gross Income'];
        let expensesArray = dashBoardOutputSheetCompleteData['Total Expenses'];

        response.loadSketches.forEach((item, index) => {
          // const returnData = {};

          newWorthGrowthVal[0] = {
            color: "#F4B183",
            data: newWorthArray ? newWorthArray : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Active Selection",
            showInLegend: true,
            type: "spline"
          }
          newWorthGrossVal[0] = {
            color: "#F4B183",
            data: grossArray ? grossArray : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Active Selection",
            showInLegend: true,
            type: "spline"
          }
          newExpensesGrossVal[0] = {
            color: "#F4B183",
            data: expensesArray ? expensesArray : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Active Selection",
            showInLegend: true,
            type: "spline"
          }
          newWorthGrowthVal[parseInt(index) + 1] = JSON.parse(item.networth);
          newWorthGrossVal[parseInt(index) + 1] = JSON.parse(item.gross);
          newExpensesGrossVal[parseInt(index) + 1] = JSON.parse(item.expenses);
          loadedCareerSketchLisst[index] = {};
          if (item.sketch.inkwiry_career_sketch === 1) {
            let splitName = item.sketch.name.split('-');

            loadedCareerSketchLisst[index]['subname'] = splitName[0];
            loadedCareerSketchLisst[index]['name'] = item.sketch.name;
            newWorthGrowthVal[parseInt(index) + 1].name = item.sketch.name;
            newWorthGrossVal[parseInt(index) + 1].name = item.sketch.name;
            newExpensesGrossVal[parseInt(index) + 1].name = item.sketch.name;
          } else {
            loadedCareerSketchLisst[index]['name'] = item.sketch.name;
            newWorthGrowthVal[parseInt(index) + 1].name = item.sketch.name;
            newWorthGrossVal[parseInt(index) + 1].name = item.sketch.name;
            newExpensesGrossVal[parseInt(index) + 1].name = item.sketch.name;
          }
          setNewWorthGrowth(newWorthGrowthVal);
          setNewWorthGross(newWorthGrossVal);
          setNewExpensesGross(newExpensesGrossVal);
          loadedCareerSketchLisst[index]['create_date'] = item.created_date;
          loadedCareerSketchLisst[index]['netWoth'] = JSON.parse(item.networth)['data'][14];
          loadedCareerSketchLisst[index]['image'] = item.sketch.sketch_image;
          loadedCareerSketchLisst[index]['status'] = item.sketch.inkwiry_career_sketch;
          loadedCareerSketchLisst[index]['sketch_id'] = item.sketch_id;
          careerSketche.push(item.sketch_id);
          // setCareerSketches(careerSketche);

        });
        setLoadSketchList(loadedCareerSketchLisst);
      }
      setLoading(false);

    },
    onError() {
      setLoading(false);

    },
  });

  useEffect(() => {
    if (goalsData) {
      setUpdatedFIValues({
        average_income: goalsData ? goalsData.average_income : 0,
        income_range_min: goalsData ? goalsData.low_income : 0,
        income_range_max: goalsData ? goalsData.high_income : 0,
      })
      setJobOutlook(goalsData ? goalsData.job_outlook : "bright");
      setGoalId(goalsData ? goalsData.id : "");
    }
    // eslint-disable-next-line
  }, [goalsData]);

  useEffect(() => {
    getGoalsData();
  }, [getGoalsData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      getLoadSketches();
    }
  };
  const getNetworthGraph = () => {
    return {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:'auto',
        events: {
          load() {
            const chart = this;
            if (chart.options.chart.forExport) {
              chart.renderer.image('https://s3.us-east-2.amazonaws.com/inkwiry-images/PowerUp/logo2.png', 150, 23, 120, 40)
                .add();
            }
          },
        },
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: newWorthGrowth,
    }

  }
  const grosssIncomeCompair = () => {
    return {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:'auto',
        events: {
          load() {
            const chart = this;
            if (chart.options.chart.forExport) {
              chart.renderer.image('https://s3.us-east-2.amazonaws.com/inkwiry-images/PowerUp/logo2.png', 150, 23, 120, 40)
                .add();
            }
          },
        },
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,

      }],
      tooltip: {
        // formatter() {
        //   const chart = this;
        //   if (chart.y < 0) {
        //     return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
        //   }

        //   return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        // },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: newWorthGross,
    }
  }

  const grosssExpensesCompair = () => {
    return {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:'auto',
        events: {
          load() {
            const chart = this;
            if (chart.options.chart.forExport) {
              chart.renderer.image('https://s3.us-east-2.amazonaws.com/inkwiry-images/PowerUp/logo2.png', 150, 23, 120, 40)
                .add();
            }
          },
        },
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,

      }],
      // tooltip: {
      //   formatter() {
      //     const chart = this;
      //     if (chart.y < 0) {
      //       return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
      //     }

      //     return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
      //   },
      // },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: newExpensesGross,
    }
  }

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const validate = (values) => {

    const errors = {};
    if (!values.choice) {
      errors.choice = 'Career Choice is required.';
    }
    if (!values.five_one_goal) {
      errors.five_one_goal = 'Five Years Goal is required.';
    }
    if (!values.five_two_goal) {
      errors.five_two_goal = 'Five Years Goal is required.';
    }
    if (!values.five_three_goal) {
      errors.five_three_goal = 'Five Years Goal is required.';
    }
    if (!values.ten_one_goal) {
      errors.ten_one_goal = 'Ten Years Goal is required.';
    }
    if (!values.ten_two_goal) {
      errors.ten_two_goal = 'Ten Years Goal is required.';
    }
    if (!values.ten_three_goal) {
      errors.ten_three_goal = 'Ten Years Goal is required.';
    }
    if (!values.fiften_one_goal) {
      errors.fiften_one_goal = 'Fiften Years Goal is required.';
    }
    if (!values.fiften_two_goal) {
      errors.fiften_two_goal = 'Fiften Years Goal is required.';
    }
    if (!values.fiften_three_goal) {
      errors.fiften_three_goal = 'Fiften Years Goal is required.';
    }

    return errors;
  };
  const handleJobOutlook = (event) => {
    setJobOutlook(event.target.value);
  };

  const updateValue = (e, field) => {
    const valuesUpdated = { ...updatedFIValues };
    let newvalue = 0;
    newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    valuesUpdated[field] = newvalue;
    setUpdatedFIValues(valuesUpdated);
  };

  const onSubmit = (values) => {
    submitGoalForm({
      variables: {
        data: {
          id: goalId ? goalId : "",
          career_choice: values.choice,
          average_income: updatedFIValues.average_income,
          low_income: updatedFIValues.income_range_min,
          high_income: updatedFIValues.income_range_max,
          job_outlook: jobOutlook,
          five_1_goal: values.five_one_goal,
          ten_1_goal: values.ten_one_goal,
          fifteen_1_goal: values.fiften_one_goal,
          five_2_goal: values.five_two_goal,
          ten_2_goal: values.ten_two_goal,
          fifteen_2_goal: values.fiften_two_goal,
          five_3_goal: values.five_three_goal,
          ten_3_goal: values.ten_three_goal,
          fifteen_3_goal: values.fiften_one_goal,
        },
      },
    });
  };

  const openSightPage = (module, type, scenarioId, forType, scenarioType, index, code) => {
    let sightPassData = {};
    if (scenarioType) {
      sightPassData = {
        module,
        type,
        scenarioId,
        forType,
        from: 'popup',
        scenarioType,
        fromType: 'dashboard',
        index,
        code
      };
    } else {
      sightPassData = {
        module,
        type,
        scenarioId,
        forType,
        from: 'popup',
      };
    }
    setSightData(sightPassData);
    setOpenSightPopup(true);
  };

  const year_5th = moduleServices.get5thYearNetworth();
  const year_10th = moduleServices.get10thYearNetworth();
  const year_15th = moduleServices.get15thYearNetworth();


  return (
    <Typography variant="body1" component="div" className={classes.bodyBgNew}>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.desktopPage}>
        <div className={classes.welcomeSection}>
          <div className={classes.container}>
            <div className={classes.welcomeContent}>
              <div className={classes.dashboardImage}>
                <div className={classes.userProfile}>
                  <img src={myDetails && myDetails.me.profile_image ? IMAGE_PATH + myDetails.me.profile_image : dummyUser} alt="" />
                </div>
                <Typography variant="h2" component="h2">Welcome, {' '}
                  {myDetails && myDetails.me.user_fname}
                  {' '}
                  {myDetails && myDetails.me.user_lname}
                  .</Typography>

              </div>
              <div className={classes.dashboardContent}>
                <Grid container spacing={3} className={classes.GridPad}>
                  <Grid item sm={4} className={classes.acSketch}>
                    <div className={classes.activeSketch}>
                      <Typography variant="h2" component="h2">Active Career Sketch:</Typography>
                      <p>Active Selection</p>
                    </div>
                  </Grid>
                  <Grid item sm={5} >
                    <div className={classes.dashboardNetWorth}>
                      <Typography variant="h2" component="h2">Net Worth:</Typography>
                      <ul>
                        <li>
                          <span className={classes.yearCount}>5 Years</span>
                          <span className={year_5th < 0 && classes.redText}>
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_5th} prefix={year_5th >= 0 ? '$' : '($'} suffix={year_5th < 0 && ')'} />
                          </span>
                        </li>
                        <li>
                          <span className={classes.yearCount}>10 Years</span>
                          <span className={year_10th < 0 && classes.redText}>
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_10th} prefix={year_10th >= 0 ? '$' : '($'} suffix={year_10th < 0 && ')'} />
                          </span>
                        </li>
                        <li>
                          <span className={classes.yearCount}>15 Years</span>
                          <span className={year_15th < 0 && classes.redText}>
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_15th} prefix={year_15th >= 0 ? '$' : '($'} suffix={year_15th < 0 && ')'} />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.currentSenarioBlock}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="#000"
            // indicatorColor="none"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Current Scenarios" {...a11yProps(0)} className={classNames(value === 0 && classes.classOrange)} />
            <Tab label="Career Sketches" {...a11yProps(1)} className={classNames(value === 1 && classes.classOrange)} />

          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={classes.tabContentBox}>
          <div className={classes.careerSketchTimeline}>
            <ReactTooltip id="career" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
            <ModuleNavBar />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabContentBox}>
          <ReactTooltip id="graphBig" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltipNew)} />
          <ReactTooltip id="graph" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltip)} />
          <ul className={classes.profileBox}>
            {loadSketchList && (
              loadSketchList.map((data, index) => (
                <li>
                  {data.name && (
                    <div>
                      <div className={classes.closeIconNew} onClick={() => { handleDelete(data.sketch_id) }}>
                        <i className="fa fa-close" />
                      </div>
                      <figure>
                        <img src={imageUrl(`./${data.image}`)} alt="" />

                      </figure>
                      <div className={classes.textCaption}>
                        <h3>
                          <span>{data.subname}</span>
                          <span>{data.name}</span>
                          <span>
                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={data.netWoth} prefix={'$'} />
                          </span>
                        </h3>
                      </div>
                    </div>
                  )}
                  {data.name === "" && (

                    <div className={classNames(`${classes.plusIcon}`, _.findIndex(loadSketchList, function (o) { return o.name === ''; }) === index && classes.bluePlus)} onClick={() => { openSightPage('', 'load', '', 'sketch', 'sketch', index + 1, colorCode[index + 1]) }}>
                      <img src={profilePlus} alt="" />
                    </div>
                  )}
                </li>
              ))
            )}
          </ul>
        </TabPanel>

      </div>
      <div className={classes.graphSection}>
        <ReactTooltip id="graphBig" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltipNew)} />
        <ReactTooltip id="graph" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltip)} />
        <Grid container>
          <Grid sm={3} xs={12} className={classes.goalsSection}>
            <div className={classes.myGoals}>
              <h3>My Goals
                <span aria-hidden="true" onClick={() => { setEditGoals(true) }}><i className="fa fa-pencil-square-o" aria-hidden="true" /></span>
              </h3>
              <div className={classes.dashboardGoals}>
                <p>
                  <span>CAREER CHOICE:</span>
                  <span>{goalsData.career_choice}</span>
                </p>
                <p>
                  <span>AVERAGE INCOME:</span>
                  <span><NumberFormat value={parseFloat(goalsData.average_income).toFixed(0)} displayType="text" thousandSeparator prefix="$" /></span>
                </p>
                <p>
                  <span>INCOME RANGE:</span>
                  <span><NumberFormat value={parseFloat(goalsData.low_income).toFixed(0)} displayType="text" thousandSeparator prefix="$" /> to <NumberFormat value={parseFloat(goalsData.high_income).toFixed(0)} displayType="text" thousandSeparator prefix="$" /></span>
                </p>
                <p>
                  <span>JOB OUTLOOK:</span>
                  <span className={classes.outlookTitle}>{goalsData.job_outlook}</span>
                </p>
                <div className={classes.yearText}>
                  <img src={yearText} alt=" " />
                  <ul>
                    <li>{goalsData.five_1_goal}</li>
                    <li>{goalsData.five_2_goal}</li>
                    <li>{goalsData.five_3_goal}</li>
                  </ul>
                </div>
                <div className={classes.yearText}>
                  <img src={yearText2} alt=" " />
                  <ul>
                    <li>{goalsData.ten_1_goal}</li>
                    <li>{goalsData.ten_2_goal}</li>
                    <li>{goalsData.ten_3_goal}</li>
                  </ul>
                </div>
                <div className={classes.yearText}>
                  <img src={yearText3} alt=" " />
                  <ul>
                    <li>{goalsData.fifteen_1_goal}</li>
                    <li>{goalsData.fifteen_2_goal}</li>
                    <li>{goalsData.fifteen_3_goal}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={classes.buttonSection}>
              <Button className={classes.buttonGreen} onClick={() => { openSightPage('', 'load', '', 'sketch'); }}>Load Career Sketch</Button>
              <Button className={classes.buttonBlue} onClick={() => { setOpenPopup("simulator") }}>Simulator Panel</Button>
              <Button className={classes.buttonDarkBlue} onClick={() => { setSummaryDocumentsOpen(!summaryDocumentsOpen); }}> Summary Documents </Button>
              <span className={classes.summeryDropdownDiv}>
                {summaryDocumentsOpen && (
                  <ul className={classes.summaryListMenu}>
                    <li aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-output'); setSummaryDocumentsOpen(false); }}><p>Summary Output</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('inputsheet-statement'); setSummaryDocumentsOpen(false); }}>Summary Financials</p></li>
                    <li aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-table'); setSummaryDocumentsOpen(false); }}><p>Summary Tables</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('income-statement'); setSummaryDocumentsOpen(false); }}>Income Statement</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('tax-statement'); setSummaryDocumentsOpen(false); }}>Tax Statement</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('balance-statement'); setSummaryDocumentsOpen(false); }}>Balance Sheet</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('cashflow-statement'); setSummaryDocumentsOpen(false); }}>Cash Flow Statement</p></li>
                    <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('total-download'); setSummaryDocumentsOpen(false); }}>Download PDF</p></li>
                  </ul>
                )}
              </span>
              {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module1' }} recallDataApi={recallDataApi} />)}
              {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
            </div>
          </Grid>

          <Grid sm={9} xs={12} className={classes.rightSection}>
            {value === 1 ? <div className={classNames(classes.graphTabSection, classes.tabContentBoxGraphBox, classes.mt0)}>
              <div className={classes.graphSectionTop}>
                <div className={classes.graphLeft}>
                  <h4>
                    Net Worth Comparison
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Net Worth from your Balance Sheet at the end of your 15 year period." />
                  </h4>
                </div>
              </div>
              <div className={classes.graphBlock}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getNetworthGraph()}
                />
                {/* <img src={networthGraph} alt="" /> */}
              </div>
            </div> :
              <div className={classes.graphTabSection}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueTab}
                    onChange={handleChangeTab}
                    indicatorColor="none"
                    textColor="white"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Net Worth" {...a11yProps(0)} className={classNames(valueTab === 0 && classes.activeClass)} />
                    <Tab label="Assets & Liabilities" {...a11yProps(1)} className={classNames(valueTab === 1 && classes.activeClass)} />
                    <Tab label="Retirement Accounts" {...a11yProps(2)} className={classNames(valueTab === 2 && classes.activeClass)} />

                  </Tabs>
                </AppBar>
                <TabPanel value={valueTab} index={0} className={classes.tabContentBoxGraph}>
                  <ReactTooltip id="graphBig" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltipNew)} />
                  <ReactTooltip id="graph" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltip)} />
                  <div className={classes.graphSectionTop}>
                    <div className={classes.graphLeft}>
                      <h4>
                        15-yr Net Worth
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Net Worth from your Balance Sheet at the end of your 15 year period." />
                      </h4>

                      <h3>

                        {graphs.networthGraph().chartValue !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.networthGraph().chartValue} prefix={graphs.networthGraph().chartValue >= 0 ? '$' : '($'} suffix={graphs.networthGraph().chartValue < 0 && ')'} />
                        ) : ('$0')}
                      </h3>
                    </div>
                    <div className={classes.graphRight}>
                      <h4>
                        Growth
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Compounded Annual Growth Rate (CAGR) of your Net Worth for your 15 year period. CAGR is the average annual growth rate over a period of time. In other words, CAGR is the rate at which an investment would have grown if it had grown at the same rate each year." />
                      </h4>
                      <h3>

                        {graphs.networthGraph().chartGrowthValue !== undefined && graphs.networthGraph().chartGrowthValue > 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} className={classNames(classes.formInput, classes.formInputFull, graphs.networthGraph().chartGrowthValue > 0 && classes.greenText, graphs.networthGraph().chartGrowthValue < 0 && classes.redText)} displayType="text" thousandSeparator value={graphs.networthGraph().chartGrowthValue} prefix={graphs.networthGraph().chartGrowthValue > 0 ? '+' : '('} suffix={graphs.networthGraph().chartGrowthValue < 0 ? '%)' : '%'} />
                        ) : ('0%')}
                      </h3>
                    </div>

                  </div>
                  <div className={classes.graphBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={graphs.networthGraph().chartData}
                    />
                    {/* <img src={networthGraph} alt="" /> */}
                  </div>
                </TabPanel>
                <TabPanel value={valueTab} index={1} className={classes.tabContentBoxGraph}>
                  <ReactTooltip id="graphBig" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltipNew)} />
                  <ReactTooltip id="graph" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltip)} />
                  <div className={classes.graphSectionTop}>
                    <div className={classes.graphLeft}>
                      <h4>
                        Assets
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Total Assets from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>
                        {graphs.assetsGraph().chartValue !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.assetsGraph().chartValue} prefix={graphs.assetsGraph().chartValue >= 0 ? '$' : '($'} suffix={graphs.assetsGraph().chartValue < 0 && ')'} />
                        ) : ('$0')}


                      </h3>
                    </div>
                    <div className={classes.graphRight}>
                      <h4>
                        Liabilites
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Total Liabilities from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>

                        {graphs.assetsGraph().chartLiabilities !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.assetsGraph().chartLiabilities} prefix={graphs.assetsGraph().chartLiabilities >= 0 ? '$' : '($'} suffix={graphs.assetsGraph().chartLiabilities < 0 && ')'} />
                        ) : ('$0')}
                      </h3>
                    </div>

                  </div>
                  <div className={classes.graphBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={valueTab === 1 ? graphs.assetsGraph().chartData : {}}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={valueTab} index={2} className={classes.tabContentBoxGraph}>
                  <ReactTooltip id="graphBig" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltipNew)} />
                  <ReactTooltip id="graph" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.graphTooltip)} />
                  <div className={classNames(classes.graphSectionTop, classes.graphFull)}>
                    <div className={classNames(classes.graphLeft, classes.grapTitle)}>
                      <h4>
                        401(k)
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="401(k) balance from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>

                        {graphs.retirementAccounts().chart401 !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={graphs.retirementAccounts().chart401} prefix={graphs.retirementAccounts().chart401 >= 0 ? '$' : '($'} suffix={graphs.retirementAccounts().chart401 < 0 && ')'} />
                        ) : ('$0')}</h3>
                    </div>
                    <div className={classNames(classes.graphLeft, classes.grapTitle)}>
                      <h4>
                        Roth IRA
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Roth IRA balance from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>

                        {graphs.retirementAccounts().chartroth !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.retirementAccounts().chartroth} prefix={graphs.retirementAccounts().chartroth >= 0 ? '$' : '($'} suffix={graphs.retirementAccounts().chartroth < 0 && ')'} />
                        ) : ('$0')}
                      </h3>
                    </div>
                    <div className={classNames(classes.graphLeft, classes.grapTitle)}>
                      <h4>SEP IRA
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="SEP IRA balance from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>

                        {graphs.retirementAccounts().chartsep !== undefined ? (
                          <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={graphs.retirementAccounts().chartsep} prefix={graphs.retirementAccounts().chartsep >= 0 ? '$' : '($'} suffix={graphs.retirementAccounts().chartsep < 0 && ')'} />
                        ) : ('$0')}
                      </h3>
                    </div>
                    <div className={classNames(classes.graphLeft, classes.grapTitle)}>
                      <h4>Pension
                        <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Pension balance from your Balance Sheet at the end of your 15 year period." />
                      </h4>
                      <h3>                      {graphs.retirementAccounts().chartpension !== undefined ? (
                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.retirementAccounts().chartpension} prefix={graphs.retirementAccounts().chartpension >= 0 ? '$' : '($'} suffix={graphs.retirementAccounts().chartpension < 0 && ')'} />
                      ) : ('$0')}
                      </h3>
                    </div>
                  </div>
                  <div className={classes.graphBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={valueTab === 2 ? graphs.retirementAccounts().chartData : []}
                    />
                    {/* <img src={networthGraph} alt="" /> */}
                  </div>
                </TabPanel>

              </div>
            }
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          {value === 0 ? (
            <Grid sm={6} xs={12}>
              <div className={classes.tabContentBoxGraphBox}>
                <div className={classes.graphSectionTop}>
                  <div className={classes.graphLeft}>
                    <h4>
                      Total Gross Income Breakdown
                      <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Cumulative income earned for 15 years broken down by category." />
                    </h4>
                    {/* <h3>$806,614</h3> */}
                  </div>
                  <div className={classes.graphRight}>
                    <h4>
                      Total 15-yr Income Earned
                      {/* <img src={infoIcon} alt="" /> */}
                    </h4>
                    <h3>

                      {graphs.totalGrossIncome().chartValue !== undefined ? (
                        <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.totalGrossIncome().chartValue} prefix={graphs.totalGrossIncome().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalGrossIncome().chartValue < 0 && ')'} />
                      ) : ('$0')}
                    </h3>

                  </div>
                </div>
                <div className={classes.graphBlock}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={graphs.totalGrossIncome().chartData}
                  />
                  {/* <img src={networthGraph} alt="" /> */}
                </div>
              </div>
            </Grid>) :
            (<Grid sm={6} xs={12}>
              <div className={classes.tabContentBoxGraphBox}>
                <div className={classes.graphSectionTop}>
                  <div className={classes.graphLeft}>
                    <h4>
                      Total Gross Income Breakdown
                      <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Cumulative income earned for 15 years broken down by category." />
                    </h4>
                  </div>
                </div>
                <div className={classes.graphBlock}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={grosssIncomeCompair()}
                  />
                </div>
              </div>
            </Grid>)
          }
          {value === 0 ? (<Grid sm={6} xs={12}>
            <div className={classes.tabContentBoxGraphBox}>
              <div className={classes.graphSectionTop}>
                <div className={classes.graphLeft}>
                  <h4>
                    Total Expenses Breakdown
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Cumulative expenses paid for 15 years broken down by category." />
                  </h4>
                </div>
                <div className={classes.graphRight}>
                  <h4>
                    Total 15-yr Expenses
                  </h4>
                  <h3>

                    {graphs.totalExpensesBreakdown().chartValue !== undefined ? (
                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.totalExpensesBreakdown().chartValue} prefix={graphs.totalExpensesBreakdown().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalExpensesBreakdown().chartValue < 0 && ')'} />
                    ) : ('$0')}
                  </h3>
                </div>

              </div>
              <div className={classes.graphBlock}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.totalExpensesBreakdown().chartData}
                />
              </div>
            </div>
          </Grid>
          ) : (<Grid sm={6} xs={12}>
            <div className={classes.tabContentBoxGraphBox}>
              <div className={classes.graphSectionTop}>
                <div className={classes.graphLeft}>
                  <h4>
                    Total Expenses Breakdown
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Cumulative expenses paid for 15 years broken down by category." />
                  </h4>
                </div>
              </div>
              <div className={classes.graphBlock}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={grosssExpensesCompair()}
                />
              </div>
            </div>
          </Grid>
          )}
        </Grid>
        {value === 0 && (<Grid container className={classes.gridContainerNew}>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Total Assets
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Total Assets from your Balance Sheet at the end of each year." />
                  </h4>
                  <h3>

                    {graphs.totalAssests().chartValue !== undefined ? (
                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.totalAssests().chartValue} prefix={graphs.totalAssests().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalAssests().chartValue < 0 && ')'} />
                    ) : ('$0')}
                  </h3>

                </div>
                <div className={classes.graphRight}>
                  <p>{`${formatDollar((Math.round(50000)) / 1000)}k`}</p>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.totalAssests().chartData}
                />
                {/* <img src={networthGraph} alt="" /> */}
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Total Liabilities
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Total Liabilities from your Balance Sheet at the end of each year." />
                  </h4>
                  <h3>{graphs.totalLiabilities().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.totalLiabilities().chartValue} prefix={graphs.totalLiabilities().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalLiabilities().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.totalLiabilities().chartData}
                />
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Taxes Breakdown
                    <img src={infoIcon} alt="tooltip" data-for="graph" data-tip="Cumulative taxes paid for 15 years broken down by category." />
                  </h4>
                  {/* <h3>{graphs.totalBreakdown().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={graphs.totalBreakdown().chartValue} prefix={graphs.totalBreakdown().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalBreakdown().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3> */}
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.totalBreakdown()}
                />
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Net Change in Cash
                    <img src={infoIcon} alt="tooltip" data-for="graphBig" data-tip="Net Change in Cash from your Cash Flow Statement for each year. 
                    This does not include any transfers to or withdrawals from your short and long-term investments. This shows you if cash is coming in or going out each year after all income, expenses, financing, 
                    and investing activities are taken into account. The number in the top left corner of this chart shows the ending cash balance in the final year from the balance sheet. In other words, how much cash you have at the end of the 15-year period."/>
                  </h4>
                  <h3>{graphs.netChangeCash().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.netChangeCash().chartValue} prefix={graphs.netChangeCash().chartValue >= 0 ? '$' : '($'} suffix={graphs.netChangeCash().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.netChangeCash().chartData}
                />
              </div>
            </div>
          </Grid>

        </Grid>
        )}
        {value === 0 && (<Grid container className={classes.gridContainerNew}>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Debt-to-Income Ratio
                    <img src={infoIcon} alt="tooltip" data-for="graphBig" data-tip="Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year. This ratio indicates the percentage of gross income being accounted for debt repayment and the percentage of gross income left over for other household expenses and savings. You can find the debt-to-income ratio on the dashboard or on the summary financials. The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%." />
                  </h4>
                  <h3>{graphs.debitRatio().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.debitRatio().chartValue} prefix={graphs.debitRatio().chartValue >= 0 ? '$' : '($'} suffix={graphs.debitRatio().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.debitRatio().chartData}
                />
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Current Ratio
                    <img src={infoIcon} alt="tooltip" data-for="graphBig" data-tip="Calculated by taking cash and short-term investments at the end of the year divided by total debt payments (principal and interest) in a given year. This ratio represents the ability to service short-term liabilities in case of a financial emergency. You can find the current ratio on the dashboard or on the summary financials. The higher the ratio, the better. Having at least a 1x current ratio means you have enough liquid assets to make your debt payments for the year." />
                  </h4>
                  <h3>{graphs.currentRatio().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.currentRatio().chartValue} prefix={graphs.currentRatio().chartValue >= 0 ? '$' : '($'} suffix={graphs.currentRatio().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.currentRatio().chartData}
                />
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Savings Rate
                    <img src={infoIcon} alt="tooltip" data-for="graphBig" data-tip="Calculated by taking total savings divided by after-tax income in a given year. Total savings is calculated as net change in cash plus contributions to investment accounts plus retirement contributions. You can find the savings rate on the dashboard or on the summary financials. A higher savings rate will grow your assets and lead to a higher net worth. A good benchmark to use for this KPI is between 12-15%." />
                  </h4>
                  <h3>{graphs.savingRate().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.savingRate().chartValue} prefix={graphs.savingRate().chartValue >= 0 ? '$' : '($'} suffix={graphs.savingRate().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.savingRate().chartData}
                />
              </div>
            </div>
          </Grid>
          <Grid sm={3} xs={12}>
            <div className={classNames(classes.tabContentBoxGraphBox, classes.linesBackground)}>
              <div className={classNames(classes.graphSectionTop, classes.linesTop)}>
                <div className={classes.graphLeft}>
                  <h4>
                    Total Assets to Total Debt
                    <img src={infoIcon} alt="tooltip" data-for="graphBig" data-tip="Calculated by taking total assets divided by total liabilities at the end of the year. This ratio compares total assets accumulated against the existing liabilities to analyze your financial health. You can find the total assets to total debt ratio on the dashboard or on the summary financials. Having at least a 1x ratio means you have enough assets to pay off your debt in case of a financial emergency." />
                  </h4>
                  <h3>{graphs.totalAssestsandDebit().chartValue !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classNames(classes.formInput, classes.formInputFull)} displayType="text" thousandSeparator value={graphs.totalAssestsandDebit().chartValue} prefix={graphs.totalAssestsandDebit().chartValue >= 0 ? '$' : '($'} suffix={graphs.totalAssestsandDebit().chartValue < 0 && ')'} />
                  ) : ('$0')}</h3>
                </div>
                {/* <div className={classes.graphRight}>
            <h4>Net Worth<img src={infoIcon} alt="" /></h4>
              <h3 className={classes.greenText}>+7%</h3>
            </div> */}

              </div>
              <div className={classNames(classes.graphBlock, classes.graphLines)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphs.totalAssestsandDebit().chartData}
                />
              </div>
            </div>
          </Grid>

        </Grid>
        )}
        <Dialog
          open={editGoals}
          onClose={() => { setEditGoals(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.editGoalsPopup}
        >
          <DialogContent>
            <div className={classes.modalContent}>
              <div className={classes.modalHeader}>
                <button type="button" onClick={() => { setEditGoals(false) }} className={classes.closeButton} >×</button>
                <h4>EDIT MY GOALS</h4>
              </div>
              <div className={classes.modalBody}>
                {errorMessage && <span>{errorMessage}</span>}
                <Form
                  validate={validate}
                  onSubmit={onSubmit}
                  initialValues={{
                    choice: goalsData && goalsData.career_choice,
                    five_one_goal: goalsData && goalsData.five_1_goal,
                    five_two_goal: goalsData && goalsData.five_2_goal,
                    five_three_goal: goalsData && goalsData.five_3_goal,
                    ten_one_goal: goalsData && goalsData.ten_1_goal,
                    ten_two_goal: goalsData && goalsData.ten_2_goal,
                    ten_three_goal: goalsData && goalsData.ten_3_goal,
                    fiften_one_goal: goalsData && goalsData.fifteen_1_goal,
                    fiften_two_goal: goalsData && goalsData.fifteen_2_goal,
                    fiften_three_goal: goalsData && goalsData.fifteen_3_goal,
                  }}
                  render={({ handleSubmit, submitting }) => (
                    <form
                      className={classes.formContact}
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className={classes.modalBody}>
                        <div className={classes.formSection}>
                          <div className={classes.formRow}>
                            <Grid container >
                              <Grid item sm={4} xs={12} className={classes.gridSpacing}>
                                <p>CAREER CHOICE</p>
                              </Grid>
                              <Grid item sm={8} xs={12} className={classes.gridSpacing}>
                                <Field
                                  className={classes.formControl}
                                  id="filled-choice-input"
                                  type="text"
                                  variant="filled"
                                  name="choice"
                                  fullWidth
                                  required
                                  component={TextField}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formRow}>
                            <Grid container >
                              <Grid item sm={4} xs={12} className={classes.gridSpacing}>
                                <p>AVERAGE INCOME</p>
                              </Grid>
                              <Grid item sm={8} xs={12} className={classes.gridSpacing}>
                                <div className={classes.formControl}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} thousandSeparator={true} value={updatedFIValues.average_income} onValueChange={(e) => updateValue(e, 'average_income')} onFocus={(e) => e.target.select()} />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formRow}>
                            <Grid container >
                              <Grid item sm={4} xs={12} className={classes.gridSpacing}>
                                <p>INCOME RANGE</p>
                              </Grid>
                              <Grid item sm={8} xs={12} className={classes.gridSpacing}>
                                <div className={classes.subRow}>
                                  <Grid item sm={5} xs={5}>
                                    <div className={classes.formControl}>
                                      <NumberFormat className={classes.formInput} allowNegative={false} thousandSeparator={true} value={updatedFIValues.income_range_min} onValueChange={(e) => updateValue(e, 'income_range_min')} onFocus={(e) => e.target.select()} />
                                    </div>
                                  </Grid>
                                  <Grid item sm={2} xs={2} className={classes.gridSpacing}>
                                    to
                                  </Grid>
                                  <Grid item sm={5} xs={5}>
                                    <div className={classes.formControl}>
                                      <NumberFormat className={classes.formInput} allowNegative={false} thousandSeparator={true} value={updatedFIValues.income_range_max} onValueChange={(e) => updateValue(e, 'income_range_max')} onFocus={(e) => e.target.select()} />
                                    </div>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classNames(classes.formRow, classes.baseDisplay)}>
                            <Grid container >
                              <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                <p>JOB OUTLOOK</p>
                              </Grid>
                              <Grid item sm={8} xs={8} className={classes.gridSpacing}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <RadioGroup aria-label="jobOutlook" className={classes.radioGroup} name="jobOutlook" value={jobOutlook} onChange={(e) => handleJobOutlook(e)}>
                                    <FormControlLabel className={classes.cotrolLabel} value="bright" control={<Radio />} label="Bright" />
                                    <FormControlLabel className={classes.cotrolLabel} value="average" control={<Radio />} label="Average" />
                                    <FormControlLabel className={classes.cotrolLabel} value="belowaverage" control={<Radio />} label="Below Average" />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formRow}>
                            <Grid container >
                              <Grid item sm={12} xs={12} className={classes.gridSpacing}>
                                <p>MY GOALS</p>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.dashboardWidth}>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>5 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="five_one_goal"
                                    name="five_one_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>5 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="five_two_goal"
                                    name="five_two_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classNames(classes.formRow, classes.bottomSpace)}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>5 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="five_three_goal"
                                    name="five_three_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>10 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="ten_one_goal"
                                    name="ten_one_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>10 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="ten_two_goal"
                                    name="ten_two_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classNames(classes.formRow, classes.bottomSpace)}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>10 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="ten_three_goal"
                                    name="ten_three_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>15 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="fiften_one_goal"
                                    name="fiften_one_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formRow}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>15 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="fiften_two_goal"
                                    name="fiften_two_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classNames(classes.formRow, classes.bottomSpace)}>
                              <Grid container >
                                <Grid item sm={4} xs={4} className={classes.gridSpacing}>
                                  <p>15 Years</p>
                                </Grid>
                                <Grid item sm={8} xs={8} className={classes.gridRightspacing}>
                                  <Field
                                    id="fiften_three_goal"
                                    name="fiften_three_goal"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    className={classes.formtextAreaIn}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          <div className={classes.footerRow}>
                            <div className={classes.footerModal}>
                              <Grid container >
                                <Grid item sm={9} className={classes.gridSpacing}>
                                  <p>Need information or inspiration? Explore <a href="/">Inkwiry&apos;s Career Sketch Stories</a></p>
                                </Grid>
                                <Grid item sm={3}>
                                  <p className={classes.textRight}>
                                    <Button disabled={submitting}
                                      className={classes.saveBtn} variant="contained" type="submit">
                                      Save
                                    </Button>
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSightPopup}
          onClose={() => { setOpenSightPopup(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={false}
        >
          <DialogTitle className={classes.compareHeader} >
            <div className={classes.sightHeader}>
              <button className={classes.sightButton} type="button" onClick={() => { setOpenSightPopup(false); }}>x</button>
            </div>
          </DialogTitle>
          <DialogContent className={classes.compareModalBlock}>
            <Sight passData={sightData} getLoadSketches={getLoadSketches} setOpenSightPopup={setOpenSightPopup} loadSketchList={loadSketchList} sightPathCallback={recallDataApi} />
          </DialogContent>
          <DialogActions className={classNames(classes.modalFooter, classes.compareFooter)}>
            <div>
              <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { setOpenSightPopup(false); }}>Close</Button>
            </div>
          </DialogActions>

        </Dialog>

      </div>

    </Typography>
  );
};

Desktop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Desktop);