import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StockExchange = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Stock Exchange</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-exchange-new.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span> Stock Exchange
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-exchange-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Without a stock exchange, owners of stock looking to
                          sell would have to go around to family, friends,
                          colleagues and neighbors, hoping to find someone who
                          wants to buy. Thanks to stock exchanges, we don&apos;t
                          have to hunt for buyers and sellers.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>Stock exchanges:</p>
                        <ol>
                          <li>
                            Match buyers with sellers around the world in
                            seconds
                          </li>
                          <li>
                            Make sure the trade is completed, which means the
                            seller receives cash and the buyer receives stock
                          </li>
                        </ol>
                        <p>
                          Even though stock exchanges have a physical location,
                          such as the New York Stock Exchange at 11 Wall Street,
                          New York, NY, most trading is done electronically.
                          Thanks to stock exchanges, we don&apos;t have to leave
                          the comfort of our homes to make trades.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <a
                            href="https://www.statista.com/statistics/270126/largest-stock-exchange-operators-by-market-capitalization-of-listed-companies/"
                            target="_blank"
                            rel="noopener noreferrer">
                            According to Statista
                          </a>
                          , the five largest stock exchanges around the world
                          are:
                        </p>
                        <ol className={classes.noTopMargin}>
                          <li>
                            The{' '}
                            <a
                              href="https://www.nyse.com/index"
                              target="_blank"
                              rel="noopener noreferrer">
                              <u>New York Stock Exchange</u>
                            </a>{' '}
                            (NYSE) located in New York City and a common tourist
                            spot
                          </li>
                          <li>
                            The{' '}
                            <a
                              href="https://www.nasdaq.com/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <u>NASDAQ</u>
                            </a>
                            , which is short for the National Association of
                            Securities Dealers Automated Quotation, also located
                            in New York City
                          </li>
                          <li>
                            <a
                              href="https://www.jpx.co.jp/english/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <u>Tokyo Stock Exchange</u>
                            </a>{' '}
                            (TSE) in Tokyo, Japan
                          </li>
                          <li>
                            <a
                              href="http://english.sse.com.cn/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <u>Shanghai Stock Exchange</u>
                            </a>{' '}
                            (SSE) in Shanghai, China
                          </li>
                          <li>
                            <a
                              href="https://www.hkex.com.hk/?sc_lang=en"
                              target="_blank"
                              rel="noopener noreferrer">
                              <u>Hong Kong Stock Exchange</u>
                            </a>{' '}
                            (HKEX) located in Hong Kong, Hong Kong
                          </li>
                        </ol>
                        <p>
                          The NYSE has a physical trading floor and has been in
                          the same location since 1865. Before electronic
                          trading, stock traders would go to the NYSE floor and
                          yell out orders while simultaneously giving orders via
                          hand signals. Take a trip to the NYSE back in the
                          1980s with{' '}
                          <a
                            href="https://www.wsj.com/video/end-of-era-trading-pits-close/1C73A831-DCCA-489C-99AE-087D8CFCBD11.html"
                            target="_blank"
                            rel="noopener noreferrer">
                            this video
                          </a>
                          !
                        </p>
                        <p>
                          Remember,{' '}
                          <a
                            href="https://inkwiry.com/finance-explained/stocks-bonds"
                            target="_blank"
                            rel="noopener noreferrer">
                            a stock
                          </a>{' '}
                          represents one share of ownership in a specific
                          company, such as Microsoft. Stock exchanges make it
                          possible to trade stocks globally in a matter of
                          seconds.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StockExchange.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StockExchange);
