import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import InkwiryBanner from '../../assets/img/kpi/pricing_page_banner.jpg';

const ReferenceGuide = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.inkwiryBenfitsBannerOne }>
        <img src={ InkwiryBanner } alt="Benfits" />
        <Typography variant="h3">Key Performance Indicators (KPIs)</Typography>
      </div>
      <div className={ classes.grossarySection }>
        <div className={ classes.container }>
          <h1>KPI Glossary</h1>
          <div className={ classes.total }>
            <h3>Debt-to-Income Ratio:</h3>
            <p>Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year. This ratio indicates the percentage of gross income being accounted for debt repayment and the percentage of gross income left over for other household expenses and savings. You can find the debt-to-income ratio on the dashboard or on the summary financials.</p>
            <p>The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%.</p>
          </div>
          <div className={ classes.total }>
            <h3>Monthly Liquidity Ratio:</h3>
            <p>Calculated by taking cash and short-term investments at the end of the year divided by 1 month of living expenses and interest expenses in that same year. This ratio represents the ability to meet committed expenses such as rent, utilities, food, transportation, debt payments, etc. when faced with an emergency. You can find the monthly liquidity ratio on the summary financials.</p>
            <p>The higher the ratio the better, but maintaining at least 3-6x is ideal. That means you have enough cash and short-term investments to pay 3-6 months of expenses.</p>
          </div>
          <div className={ classes.total }>
            <h3>Current Ratio:</h3>
            <p>Calculated by taking cash and short-term investments at the end of the year divided by total debt payments (principal and interest) in a given year. This ratio represents the ability to service short-term liabilities in case of a financial emergency. You can find the current ratio on the dashboard or on the summary financials.</p>
            <p>The higher the ratio, the better. Having at least a 1x current ratio means you have enough liquid assets to make your debt payments for the year.</p>
          </div>

          <div className={ classes.total }>
            <h3>Savings Rate:</h3>
            <p>Calculated by taking total savings divided by after-tax income in a given year. Total savings is calculated as net change in cash plus contributions to investment accounts plus retirement contributions. You can find the savings rate on the dashboard or on the summary financials.</p>
            <p>A higher savings rate will grow your assets and lead to a higher net worth. A good benchmark to use for this KPI is between 12-15%.</p>
          </div>
          <div className={ classes.total }>
            <h3>Investment Assets to Total Assets:</h3>
            <p>Calculated by taking cash, short-term investments, and long-term investments divided by total assets at the end of the year. This ratio compares liquid (see definition for liquidity) assets against total assets. Investments in cash, short-term investments, and long-term investments are liquid assets. Retirement accounts, cars, and real estate are illiquid assets. You can find the investment assets to total assets on the summary financials.</p>
            <p>A good benchmark for this ratio is 20%. That means 20% of your total assets is cash or can be easily converted into cash if needed.</p>
          </div>
          <div className={ classes.total }>
            <h3>Total Assets to Total Debt:</h3>
            <p>Calculated by taking total assets divided by total liabilities at the end of the year. This ratio compares total assets accumulated against the existing liabilities to analyze your financial health. You can find the total assets to total debt ratio on the dashboard or on the summary financials.</p>
            <p>Having at least a 1x ratio means you have enough assets to pay off your debt in case of a financial emergency.</p>
          </div>

          <div className={ classes.total }>
            <h3>Solvency Ratio:</h3>
            <p>Calculated by taking net worth, which is total assets minus total liabilities, divided by total assets at the end of the year. Solvency ratio compares net worth against total assets. Net worth is positive if total assets are worth more than total liabilities. You can find the solvency ratio on the summary financials.</p>
            <p>This ratio indicates the ability to repay existing debts using existing assets in case of unforeseen events. The closer this ratio is to 1x, the better. The closer to 1x means you have less debt. If this ratio is negative, that means total debt is greater than total assets or net worth is negative.</p>
          </div>
        </div>
      </div>

      <ScrollToTop />
    </Typography>
  );
};

ReferenceGuide.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ReferenceGuide );
