import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import tickMark from '../../../assets/img/finance-explained/email-templates/tick_mark.png';
import styles from './styles';

const StockMarketTurbulence = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Stock Market Turbulence </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src=" https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/market-turbulence.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeadingTitle}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Stock Market Turbulence
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/market-turbulence-187px.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          When turbulence comes for the stock market in the form
                          of price declines, it&apos;s easy to panic. When
                          making investments, it is essential to understand your
                          tolerance for risk. We can all say we're risk tolerant
                          when our investments are doing well. But will you
                          panic, sell, and abandon your investing strategy when
                          the market drops by 10%? The truth is, you won&apos;t
                          know until that happens.{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          On average, the U.S. stock market as measured by the{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank"
                            rel="noopener noreferrer">
                            S&P500
                          </Link>{' '}
                          has declined by almost 10% or more 2.5 times per year
                          on average over the last 30 years. The last decade
                          made it easy to forget this data point with only six
                          corrections. How long do stock market corrections
                          last? What typically happens next?
                        </p>
                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                10% declines proceed to 15% or greater declines
                                31% of the time (1 in 3), and
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                10% declines proceed to 20% or greater declines
                                16% of the time (1 in 6).
                              </span>
                            </li>
                          </ul>
                        </p>
                        <p>
                          Historically, the odds of the S&P500 reversing course
                          after a decline of 10% are 67%. The next question you
                          should be asking the historical data is how long does
                          the stock market take to reverse course and recover
                          those losses?
                        </p>
                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Since World War II, the average correction has
                                lasted four months,
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                It took another four months for the market to
                                recover, and
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                In the last decade, recoveries have taken
                                anywhere between 70 and 200 days.
                              </span>
                            </li>
                          </ul>
                        </p>
                        <p>
                          Sticking to your investing strategy in good times and
                          in bad is crucial as an investor. First and foremost,
                          if you invest in the stock market, you must be
                          prepared to tolerate swings of 10 to 20% or more.
                          Without understanding the risk of an investment, how
                          can you know if you're prepared to tolerate that risk?
                        </p>

                        <p>
                          If you have a sound investing strategy such as
                          contributing $500 per month on the 15th of the month
                          every month to long-term investments, only allocate
                          cash for the long-term or a minimum of five years and
                          understand the risk of the investments you are making,
                          you're prepared to weather market turbulence
                          confidently.
                        </p>
                        <p>
                          With a plan, you make things happen. Without a plan,
                          things happen to you.
                        </p>
                      </div>
                    </div>
                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>
                      <ul>
                        <li>
                          {' '}
                          <a
                            href="https://www.attomdata.com/news/market-trends/home-sales-prices/attom-2022-rental-affordability-report/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Home ownership more affordable than renting in
                            majority of US markets
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://www.npr.org/2022/01/11/1072095219/goldfish-driving-car-israel-study"
                            target="_blank"
                            rel="noopener noreferrer">
                            Israeli scientists have taught goldfish - yes,
                            goldfish - how to drive
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://www.visualcapitalist.com/3d-map-salary-buy-home-50-u-s-metro-areas/"
                            target="_blank"
                            rel="noopener noreferrer">
                            The salary needed to buy a home in 50 US metro areas
                          </a>{' '}
                        </li>
                      </ul>
                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained" rel="noopener noreferrer">
                          Finance Explained.
                        </Link>{' '}
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StockMarketTurbulence.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StockMarketTurbulence);
