import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CovidYourMoney = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>COVID-19 and Your Money</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/covid-19-money.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      COVID-19 and Your Money
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/covid-19-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p>
                          In times of crisis, there are three important aspects
                          of your life that you should actively plan for:
                          <br />
                          1) Your safety
                          <br />
                          2) Your health
                          <br />
                          3) Your wealth.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Making sure you put safety tips into practice such as
                          social distancing and washing your hands for 20
                          seconds with warm water and soap is crucial for you
                          and your family&apos;s well-being. Plus, focusing on
                          being as healthy as you can – drinking lots of water,
                          eating{' '}
                          <a
                            href="http://whfoods.com/index.php"
                            target="_blank"
                            rel="noopener noreferrer">
                            good foods
                          </a>{' '}
                          specifically fruits and vegetables, exercising
                          regularly, and getting plenty of sleep – is the best
                          defense against this virus. I&apos;ve never heard of
                          someone regretting taking extra precautions.
                          Overprepared beats underprepared every time.
                        </p>
                        <p>
                          But what about your money? What are some steps you can
                          take right now to better your financial life?
                        </p>
                        <p>
                          <strong>Update your monthly budget</strong>
                          <br />
                          Inkwiry offers a free, easy-to-use{' '}
                          <Link to="/financial-calculators/budget">
                            monthly budgeting tool
                          </Link>
                          . On your desktop, laptop or tablet, our budgeting
                          tool will help you quickly see where you need to
                          adjust your plan. Once you update your monthly budget,
                          you&apos;ll see exactly where your money is going.
                          During this time of uncertainty, get clarity to your
                          month-to-month financial picture and look for ways to
                          reduce expenses and save.
                        </p>
                        <p>
                          <strong>
                            Remove unnecessary expenses from your budget
                          </strong>
                          <br />
                          If you need cash, look first to your budget. Are there
                          expenses you can remove in the short-term? Think about
                          wants and needs. What wants are in your budget that
                          you could remove and save some extra cash? You should
                          aim to have 9-12 months of expenses in a high-yield
                          savings account before adding back in the wants to
                          your budget.
                        </p>
                        <p>
                          To put things in perspective, calculate what you earn
                          in one hour of work. For example, $50,000 a year
                          divided by 2,000 hours of work per year = $25 per
                          hour. Now, correlate your expenses with how many hours
                          of work are needed to pay for that expense. Is the
                          expense worth that amount of work? This is a great
                          money practice for any time, but especially during
                          crisis.
                        </p>
                        <p>
                          <strong>Cut back on extra debt payments</strong>
                          <br />
                          Are you making extra payments on your student loan,
                          car loan, or mortgage? To build extra savings during
                          this time of uncertainty, cut back on the extra debt
                          payments. To be clear, only do this if you are making
                          extra debt payments, which means you are paying more
                          money each month than you are required to pay for a
                          loan.
                        </p>
                        <p>
                          <strong>
                            Call and ask your credit card issuers or loan
                            servicer for help
                          </strong>
                          <br />
                          No one is immune to this economic crisis. It&apos;s
                          okay to ask for financial help when you need it. Some
                          credit card companies are not charging interest this
                          month. Other companies are working on a case-by-case
                          basis. Call your creditors and ask what options you
                          have available. If you don&apos;t call and ask, you
                          will never know there might be an affordable option
                          for you!
                        </p>
                        <p>
                          <strong>Keep investing for retirement</strong>
                          <br />
                          Check your monthly budget. Do you have enough savings
                          to keep investing for retirement? If so, stick to your
                          plan before the virus. With the stock market falling
                          fast and the global economy going into temporary
                          disarray, I know investing can seem scary. But, if you
                          can, stick to your retirement investing plan before
                          the virus. Look to trim unnecessary expenses before
                          you stop investing for your future.
                        </p>
                        <p>
                          The Novel Coronavirus is not the first crisis we have
                          been through and it will not be the last. We will
                          overcome COVID-19 by being smart and taking care of
                          ourselves, our families, and our communities where
                          possible. As the situation in our country continues to
                          change, don&apos;t stand still! Make sure you adapt
                          and adapt your plans, too.
                        </p>
                        <p>
                          The suddenness and unexpectedness of this virus
                          reminds me of an important lesson in life and why I
                          started Inkwiry.
                          <strong>
                            &nbsp;No one can predict the future.
                          </strong>{' '}
                          If anyone tells you they know what&apos;s going to
                          happen tomorrow, they&apos;re lying.
                          <strong>&nbsp;But you can plan for it.</strong>
                        </p>
                        <p>
                          When you have a plan in place and the unexpected
                          strikes, you are more prepared and will be less
                          stressed. Don&apos;t believe me? Get a solid plan for
                          your safety, health, and wealth and see how you feel.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CovidYourMoney.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CovidYourMoney);
