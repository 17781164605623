import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import classNames from 'classnames';
import styles from '../../../screens/pwi-career-advancement/styles';
import * as modulesServices from '../../../calculations/modules-services';

const SideGraphDetails = ( props ) => {
  const {
    classes, sideGraphData,
  } = props;
  const [barType, setBarType] = React.useState('');


  const barHeightCal = ()=>{
    setBarType('');
    if(sideGraphData.activeStep === 12){
      setBarType('three');
    } else if(sideGraphData.activeStep === 11 || sideGraphData.activeStep === 13 || sideGraphData.activeStep === 18) {
      setBarType('two');
    }

  }
  
  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${sideGraphData.percentageValue}%` );
    $( '#percentage2' ).css( 'height', `${sideGraphData.percentageValue1}%` );
    barHeightCal();
  }, [sideGraphData] );

 
  

  return (
    <div className={ classes.sliderChart }>
      <ul className={ classNames( classes.utilitiesPMI, barType === "three" && classes.utilitiesChart, barType === "two" && classes.utilitiesPMITwo) }>
        <li className={ classes.chartBar }>
          <div className={ classes.chartText }>
            Total Living Expenses
            <span className={ classes.chartSpan }>
              {modulesServices.module5Data.careerAdvLivingExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module5Data.careerAdvLivingExpenses } prefix="$" /> ) : ( '$0' )}
            </span>
          </div>
          <span className={ classes.percentageBar } id="percentage1" />
          <div className={ classes.graphPercentage }>
            {sideGraphData.percentageValue !== undefined ? (
              <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ sideGraphData.percentageValue } prefix={ sideGraphData.percentageValue >= 0 ? '' : '(' } suffix={ sideGraphData.percentageValue < 0 ? '%)' : '%' } />
            ) : ( '0.00%' )}
          </div>
        </li>
        <li className={ classes.chartBar }>
          <div className={ classes.chartText }>
            Income after Taxes
            <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
              {sideGraphData.incomeAfterTaxesValue !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ sideGraphData.incomeAfterTaxesValue } prefix="$" /> ) : ( '$0' )}
            </span>
          </div>
          <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
          <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
            {sideGraphData.percentageValue1 !== undefined ? (
              <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ sideGraphData.percentageValue1 } prefix={ sideGraphData.percentageValue1 >= 0 ? '' : '(' } suffix={ sideGraphData.percentageValue1 < 0 ? '%)' : '%' } />
            ) : ( '0.00%' )}
          </div>
        </li>
      </ul>
    </div>
  );
};
SideGraphDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  sideGraphData: PropTypes.object.isRequired,
};

export default withStyles( styles )( SideGraphDetails );
