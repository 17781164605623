import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';

const NavigatingFinancialAidContinueFirst = ( props ) => {
  const {
    classes, inputData, updateData
  } = props;


  const [inputs, setInputs] = React.useState({efc_caster: inputData.efc_caster !== undefined ? inputData.efc_caster : 0,'efc_cal': inputData.efc_cal !== undefined ? inputData.efc_cal : 0});

  const handleFAFSA4caster = ( fieldId, e ) => {
    let inputDataval = {...inputData};
    inputDataval[fieldId] = e.floatValue !== undefined ? e.floatValue : 0;
    let inputsVal = {...inputs};
    inputsVal[fieldId] = inputDataval[fieldId];
    updateData('total_obj',inputDataval);
    setInputs(inputsVal);
  };

  // let nfa_link = 'https://bigfuture.collegeboard.org/pay-for-college/paying-your-share/focus-on-net-price-not-sticker-price';
  // if(inputData.school_details !== undefined && inputData.school_details.npc !== undefined) {
  //   nfa_link = inputData.school_details.npc;
  //   if (!nfa_link.match(/^https?:\/\//i)) {
  //     nfa_link = 'http://' + nfa_link;
  //   }
  // }

  return (
    <div className={ classes.contentBlock }>
      <p>
      Free Application for Federal Student Aid, also known as the FAFSA, is the form to apply for financial aid such as scholarships, grants, work study, and loans from the government. Use the <a  href="https://studentaid.gov/aid-estimator/" target="_blank" rel="noreferrer">Federal Student Aid Estimator</a> to determine your Expected Family Contribution (EFC) and enter that below.
      </p>
      <div className={ classes.inputGroupMain }>
        <h4>EFC from the FAFSA4caster:</h4>
        <div className={ `${classes.inputGroup} ${classes.inputGroupSpan}` }>
          $
          <NumberFormat allowNegative={ false } decimalScale={ 0 } fixedDecimalScale thousandSeparator customInput={ TextField } value={ inputs.efc_caster } onValueChange={ (e)=>handleFAFSA4caster('efc_caster',e) } onFocus={ ( e ) => e.target.select() } />

        </div>
      </div>

      <p>
        Then, after you complete the FAFSA4caster, complete your school&apos;s
         <a rel="noopener noreferrer referal" href="https://www.acu.edu/campusoffices/sfs/calculator/freshman.html" target="_blank">Net Price Calculator</a>
        {' '}
        to discover what financial aid you can expect to receive from your school and what your school expects you to contribute. Is your expected family contribution different from the FAFSA?
      </p>

      <p>
        <font>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
        </font>

      </p>
      <div className={ classes.inputGroupMain }>
        <h4>EFC from the Net Price Calculator:</h4>
        <div className={ `${classes.inputGroup} ${classes.inputGroupSpan}` }>
          $<NumberFormat allowNegative={ false } decimalScale={ 0 } fixedDecimalScale thousandSeparator customInput={ TextField } value={ inputs.efc_cal } onValueChange={ (e)=>handleFAFSA4caster('efc_cal',e) } onFocus={ ( e ) => e.target.select() } />
        </div>
      </div>
      <p>Keep the results of your school’s NPC open as they will be important in the planning process.</p>

    </div>
  );
};

NavigatingFinancialAidContinueFirst.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( NavigatingFinancialAidContinueFirst );
