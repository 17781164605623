const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: theme.palette.common.pureBlack,
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  csBox: {
    background: '#fff',
    padding: '35px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
  },
  blueBorder: {
    border: '2px solid #0069aa',
    padding: '3px 20px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '3px 12px',
    },  
  },
  csBreadcrumbs: {
    borderBottom: '4px solid #84a84d',
    position: 'relative',
    marginTop: '0',
    marginBottom: '0',
    padding: '10px 2px',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    }, 
    '& div': {
      width: '50%',
      '&:first-child': {
        '@media (max-width: 767px)': {
          width: '60%',
        },
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
          marginBottom: '10px',
        }, 
      },
      '&:last-child': {
        '@media (max-width: 767px)': {
          width: '40%',
        },
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
        }, 
      },      
    },    
  },
  csTitle: {
    color: '#0069aa',
    fontSize: '26px',
    margin: '0',
    paddingBottom: '0',
    fontWeight: '300',
    lineHeight: '1.1',
    fontFamily: 'MuseoSans-300',
    '@media (max-width: 767px)': {
      fontSize: '18px',
    },
    '@media (max-width: 374px)': {
      fontSize: '16px',
    },
  },
  csLogo: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      float: 'none',
      justifyContent: 'center',
    }, 
    '& h3': {
      fontSize: '27px',
      fontWeight: '300',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      margin: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
      }, 
    },
    '& img': {
      height: '50px',
      verticalAlign: 'middle',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        height: '35px',
      }, 
    },
  },
  mainContainer: {
    maxWidth: '950px',
    margin: '0 auto',
    fontWeight: '300',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSans-300',
  },
  compareSchool: {
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      margin: '20px 0px 10px 0px',
      fontSize: '21px',
      [theme.breakpoints.down( 'xs' )]: {  
        fontSize: '16px',
        lineHeight: '23px',
      }, 
    },
  },
  typeOfHe: {
    padding: '30px 40px 0 0',
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    [theme.breakpoints.down( 'sm' )]: {     
      fontSize: '16px',
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
      paddingRight: '0px',
      fontSize: '15px',
    }, 
  },
  labelHe: {
    display: 'inline-block',
    maxWidth: '100%',
    marginBottom: '5px',
    fontSize: '20px',
    fontFamily: 'MuseoSans-300',
    fontWeight: '300',
    boxSizing: 'border-box',
    color: '#000',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
    }, 
  },
  radioWrapper: {
    marginLeft: '3%',
    width: '330px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    textAlign: 'center',
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      width: '280px',
    }, 
    [theme.breakpoints.down( 'xs' )]: {
      width: '250px',
    }, 
    '& div': {
      width: '50%',
      padding: '0px',
      '& a': {
        color: '#333',
        textDecoration: 'none',
        width: '100%',
        display: 'block',
      },
    },
  },
  activeClass: {
    backgroundColor: '#d9d9d9',
    fontWeight: '500',
  },
  selectSection: {
    margin: '25px 0px',
    display: 'flex',    
    '& input': {
      width: '100%',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      oTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      backgroundImage: 'none',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      display: 'block',
      fontFamily: 'inherit',
      boxSizing: 'border-box',
      '@media (max-width: 767px)': {
        padding: '6px 8px',
      },
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        webkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
    },
  },
  titleOne: {
    width: '34%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'auto',
      display: 'none',
    }, 
  },
  titleTwo: {
    width: '25%',
    paddingRight: '0',
    // paddingLeft: '25px',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '48%',
      paddingLeft: '0px',
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: '32%',
    }
  },
  titleThree: {
    width: '25%',
    boxSizing: 'border-box',
    marginLeft: '0',
    // paddingLeft: '25px',
    paddingRight: '0',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '48%',
      paddingLeft: '0px',
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: '32%',
    }
  },
  tableStriped: {
    marginBottom: '0',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '100%',
    tableLayout: 'fixed',
    backgroundColor: 'transparent',
    [theme.breakpoints.down( 'xs' )]: {
      minWidth: '700px',
     },  
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          backgroundColor: 'transparent',
        },
        '&:nth-child(even)': {
          backgroundColor: '#f2f2f2',
        },
        '&:nth-child(1)': {
          '& td': {
            verticalAlign: 'middle',
            borderTop: 'none',
          },
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          borderTop: '1px solid #ddd',
          fontSize: '16px',
          color: '#000',
          '@media (max-width: 767px)': {
            fontSize: '14px',
          }, 
          '&:nth-child(1)': {
            width: '30px',
            maxWidth: '30px',
            paddingRight: '0',
            '& img': {
              cursor: 'pointer',
              [theme.breakpoints.down( 'sm' )]: {
                maxWidth: '20px',
              }, 
            },
          },
          '&:nth-child(4)': {
            background: ' #d9e1f2',
            width: '16%',
            borderLeft: '2px solid #000',
            borderRight: '2px solid',
            borderColor: '#000',
            [theme.breakpoints.down( 'xs' )]: {
              width: '20%',
            }
          },
          '&:nth-child(2)': {
            width: '34%',
          },
          '&:nth-child(3)': {
            width: '25%',
          },
          '&:nth-child(5)': {
            width: '25%',
          },
        },
        '&:hover': {
          backgroundColor: '#ddebf7',
        },
      },
    },
  },
  leftPadding: {
    paddingLeft: '30px !important',
  },
  bottomText: {
    color: '#0069aa',
    fontStyle: 'italic',
    padding: '30px 0 10px 0px',
    borderTop: '1px solid #d5d5d5',
    fontSize: '16px',
    '& p': {
      margin: '0 0 10px',
      '& a': {
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    zIndex: '99',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      '@media (max-width: 768px)': {
        borderRightColor: '#0069aa !important',
        borderTopColor: 'transparent !important',
      },
    },
  },
  tableTopBorder: {
    '& td': {
      '&:nth-child(4)': {
        borderTop: '2px solid #000',
      },
    },
  },
  schoolName: {
    '& td': {
      '&:nth-child(4)': {
        background: 'transparent !important',
        border: 'none !important',
      },
    },
  },
  tableBottomBorder: {
    '& td': {
      '&:nth-child(4)': {
        borderBottom: '2px solid #000',
      },
    },
  },
  titleFour: {
    width: '16%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '4%',
      paddingLeft: '0px',
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: '2%',
    }
  },
  leftIndent: {
    paddingLeft: '30px !important',
    [theme.breakpoints.down( 'xs' )]: {     
      paddingLeft: '20px',
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down( 'sm' )]: {
      whiteSpace: 'pre-wrap',
    },
  },
  searchList: {
    background: '#fff',
    border: '1px solid #ccc',
    position: 'absolute',
    zIndex: '99',
    marginTop: '5px',
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
    // width: '240px',
    width: '100%',
    boxSizing: 'border-box',
    // '@media (max-width: 767px)': {
    //   width: '200px',
    // },
    '& ul': {
      padding: '0px',
      '& li': {
        padding: '2px 10px',
        cursor: 'pointer',
        borderBottom: '1px solid #f2f2f2',
        margin: '0 0 10px',
        fontFamily: 'MuseoSans-300',
        fontSize: '16px',
        color: '#000',
        fontWeight: '300',
        '@media (max-width: 767px)': {
          fontSize: '14px',         
        },
      },
    },
  },
  federalFlow: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
     },  
  },
  tableScrollHidden:{
    [theme.breakpoints.down( 'xs' )]: {
      overflowX: 'hidden',
      width: '100%',
    }
  }
} );

export default styles;
